import { getListOfEmailValidation, getEmailValidationPricing } from "../types/email-validation.types";

const initState = {
    data: {
        list: [],
        count: 0,
        loading: false,
        error: null,
    },
    pricing: {
        data: [],
        count: 0,
        loading: false,
    }
};

export default function emailValidationReducer(state = initState, action = {}) {
    switch (action.type) {
        case getListOfEmailValidation.REQUEST:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: true,
                    error: null,
                }
            };
        case getListOfEmailValidation.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: false,
                    list: action.payload.data,
                    count: action.payload.count,
                    error: null,
                }
            };
        case getListOfEmailValidation.FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: false,
                    error: action.payload
                }
            };
        case getEmailValidationPricing.REQUEST:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    loading: true,
                }
            };
        case getEmailValidationPricing.SUCCESS:
            return {
                ...state,

                pricing: {
                    ...state.pricing,
                    loading: false,
                    data: action.payload.data,
                    count: action.payload.count,
                }
            };
        case getEmailValidationPricing.FAILURE:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    loading: false,
                }
            };
        default:
            return state;
    }
}
