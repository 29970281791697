// import Icon from 'modules/common/components/Icon';
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragHandler, SortableWrapper, SortItem } from "../../styled";
import { reorder } from "../../utils";
// import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class SortableList extends React.Component {
  static defaultProps = {
    droppableId: "droppableId",
  };

  onDragEnd = (result) => {
    const { destination, source } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    this.props.onUpdateStageOrder({
      order: destination.index + 1,
      stageId: result.draggableId,
    });

    const { fields, onChangeFields } = this.props;
    const reorderedFields = reorder(fields, source.index, destination.index);

    onChangeFields(reorderedFields);
  };

  renderDragHandler() {
    const { showDragHandler = true } = this.props;

    if (!showDragHandler) {
      return null;
    }

    return (
      <DragHandler>
        <i className="fa fa-bars fa-md font-size-18px" />
      </DragHandler>
    );
  }

  onAddAnotherStageClick = () => {
    this.props.add();
  };

  render() {
    const {
      fields,
      child,
      isDragDisabled,
      // droppableId
    } = this.props;

    if (fields.length === 0) {
      return <div>No feilds</div>;
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="form-fields" type="ITEMS">
          {(provided) => (
            <SortableWrapper {...provided.droppableProps} ref={provided.innerRef}>
              <div className="d-flex flex-stack p-5px">
                <div className="w-35">Stage Name</div>
                <div className="w-25">
                  Stage Probability
                  <OverlayTrigger placement="right" overlay={<Tooltip>Stage Win Probability 0-100%</Tooltip>}>
                    <i className="bi bi-info-circle ml-2"></i>
                  </OverlayTrigger>
                </div>
                <div className="w-25">Stage status</div>
                <div className="w-18">Stage Color</div>
                <div>Actions</div>
              </div>
              <div className="w-100 overflow-y-auto h-300px-mx">
                {fields.map((field, index) => (
                  <Draggable
                    key={field._id || index}
                    draggableId={(field._id && field._id.toString()) || index.toString() || Math.random().toString()}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    className="position-inherit"
                  >
                    {(dragProvided, snapshot) => (
                      <SortItem ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
                        {child(field)}
                        {this.renderDragHandler()}
                      </SortItem>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </SortableWrapper>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default SortableList;
