import {
  EDIT_SYSTEM_FIELDS_REQUEST,
  EDIT_SYSTEM_FIELDS_SUCCESS,
  EDIT_SYSTEM_FIELDS_ERROR,
  GET_ADD_FORM_FIELDS_REQUEST,
  GET_ADD_FORM_FIELDS_SUCCESS,
  GET_ADD_FORM_FIELDS_ERROR,
  REORDER_ADD_FORM_FIELDS_REQUEST,
  REORDER_ADD_FORM_FIELDS_SUCCESS,
  REORDER_ADD_FORM_FIELDS_ERROR,
} from "../types/custom-fields.types";

export function editSystemFieldsRequest(req) {
  return {
    type: EDIT_SYSTEM_FIELDS_REQUEST,
    payload: req,
  };
}
export function editSystemFieldsSuccess(req) {
  return {
    type: EDIT_SYSTEM_FIELDS_SUCCESS,
    payload: req,
  };
}
export function editSystemFieldsError(req) {
  return {
    type: EDIT_SYSTEM_FIELDS_ERROR,
    payload: req,
  };
}

export function getAddFormFieldsRequest(req) {
  return {
    type: GET_ADD_FORM_FIELDS_REQUEST,
    payload: req,
  };
}
export function getAddFormFieldsSuccess(req) {
  return {
    type: GET_ADD_FORM_FIELDS_SUCCESS,
    payload: req,
  };
}
export function getAddFormFieldsError(req) {
  return {
    type: GET_ADD_FORM_FIELDS_ERROR,
    payload: req,
  };
}

export function reorderAddFormFieldsRequest(req) {
  return {
    type: REORDER_ADD_FORM_FIELDS_REQUEST,
    payload: req,
  };
}
export function reorderAddFormFieldsSuccess(req) {
  return {
    type: REORDER_ADD_FORM_FIELDS_SUCCESS,
    payload: req,
  };
}
export function reorderAddFormFieldsError(req) {
  return {
    type: REORDER_ADD_FORM_FIELDS_ERROR,
    payload: req,
  };
}
