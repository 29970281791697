const microServices = {
  AUTOMATION_API_V1: "/automation-api/v1",
  AUTOMATION_V1: "/automation/v1",
  API_CRM: "/api/crm/data-fields",
  MARKETING_API_CRM: "/api/crm/marketing-list",
  USER_SERVICE: "/user-service/api",
  MARKETING_TAGS: "/api/crm/tag",
  MARKETING_SAVED_FILTERS: "/api/crm/saved-filters",
  CAMPAIGN_EMAIL_SETTINGS: "/api/crm/campaign/settings/email",
  CAMPAIGN_SETTINGS: "/api/crm/campaign/settings",
  CAMPAIGN_CRM: "/api/crm/campaign",
};
export const endpoints = {
  //settings
  CAMPAIGN_LIST: `${microServices.CAMPAIGN_CRM}/list`,
  CANCEL_CAMPAIGN: `${microServices.CAMPAIGN_CRM}/list/cancel`,
  CANCEL_SPLIT_BATCH_CAMPAIGN: `${microServices.CAMPAIGN_CRM}/list/split-cancel`,
  RESCHEDULE_CAMPAIGN: `${microServices.CAMPAIGN_CRM}/list/reschedule`,
  SEND_TEST_EMAIL: `${microServices.CAMPAIGN_CRM}/list/email/test-email`,
  UPLOAD_EMAIL_ATTACHMENT: `${microServices.CAMPAIGN_CRM}/list/email/upload-attachment`,
  CHECK_AUDIENCE_COUNT: `${microServices.CAMPAIGN_CRM}/list/check-audience`,
  UPLOAD_TEMPORARY_FILE: `${microServices.CAMPAIGN_CRM}/list/temporary/file-upload`,

  // marketing data fields
  MARKETING_DATA_FIELDS: `${microServices.API_CRM}/get`,
  MARKETING_ADD_FORM: `${microServices.API_CRM}/add-form-fields`,
  MARKETING_TABLE_PRIORITY: `${microServices.API_CRM}/set-table-priority`,
  MARKETING_FIELD_SHOW_HIDE: `${microServices.API_CRM}/show-hide-fields`,

  //marketing
  GET_LIST_OF_MARKETING_LIST: `${microServices.MARKETING_API_CRM}/get`,
  EDIT_MARKETING_LIST: `${microServices.MARKETING_API_CRM}/edit`,
  ADD_MARKETING_LIST: `${microServices.MARKETING_API_CRM}/create`,
  DELETE_MARKETING_LIST: `${microServices.MARKETING_API_CRM}/delete`,
  BULK_DELETE_MARKETING_LIST: `${microServices.MARKETING_API_CRM}/bulk-delete`,
  GET_MARKETING_LOG: `${microServices.MARKETING_API_CRM}/log`,

  //marketing tags
  GET_LIST_OF_MARKETING_LIST_TAGS: `${microServices.MARKETING_TAGS}/get`,
  CREATE_MARKETING_LIST_TAGS: `${microServices.MARKETING_TAGS}/create`,
  UPDATE_MARKETING_LIST_TAGS: `${microServices.MARKETING_TAGS}/edit`,
  DELETE_MARKETING_LIST_TAGS: `${microServices.MARKETING_TAGS}/delete`,

  // marketing filters
  GET_SAVED_MARKETING_FILTERS: `${microServices.MARKETING_SAVED_FILTERS}/get`,
  CREATE_SAVED_MARKETING_FILTERS: `${microServices.MARKETING_SAVED_FILTERS}/create`,
  EDIT_SAVED_MARKETING_FILTERS: `${microServices.MARKETING_SAVED_FILTERS}/edit`,
  DELETE_SAVED_MARKETING_FILTERS: `${microServices.MARKETING_SAVED_FILTERS}/delete`,
  MARK_FAVORITE_MARKETING_FILTERS: `${microServices.MARKETING_SAVED_FILTERS}/mark-as-favorite`,

  // Campaign Email Settings
  GET_EMAIL_PROFILE_DETAILS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/profile`,
  UPDATE_EMAIL_PROFILE_SETTINGS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/profile`,
  GET_EMAIL_ACCOUNT_REPUTATIONS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/profile/account-reputation`,
  GET_EMAIL_ACCOUNT_STATUS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/profile/account-status`,
  GET_LIST_OF_EMAIL_DOMAIN_SETTINGS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/list`,
  ADD_DOMAIN_SETTINGS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/add`,
  DELETE_EMAIL_DOMAIN_SETTINGS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/delete`,
  ADD_EMAIL_TO_DOMAIN_SETTINGS: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/verify-default-email`,
  VERIFY_DKIM: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/verify-dkim`,
  VERIFY_DMARC: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/verify-dmarc`,
  VERIFY_TRACKING: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/verify-tracking`,
  VERIFY_SPF: `${microServices.CAMPAIGN_EMAIL_SETTINGS}/domain/verify-spf`,

  // camapign sms settinngs
  SMS_SENDER_ID: `${microServices.CAMPAIGN_SETTINGS}/sms/sender-id`,
  SMS_PRICING: `${microServices.CAMPAIGN_SETTINGS}/sms/pricing`,
  SMS_SENDERID_COUNTRY_LIST: `${microServices.USER_SERVICE}/campaign/sms/country-list-by-route`,

  // campaign short link
  SHORT_LINK: `${microServices.CAMPAIGN_CRM}/short-link`,
  SHORT_LINK_ANALYTICS: `${microServices.CAMPAIGN_CRM}/short-link/analytics`,
  SHORT_LINK_REPORTS: `${microServices.CAMPAIGN_CRM}/short-link/report`,

  // Templates
  CAMAPIGN_TEMPLATE: `${microServices.CAMPAIGN_CRM}/template`,
  CAMPAIGN_TEMPLATE_CATEGORY: `${microServices.CAMPAIGN_CRM}/template/category`,
  CAMAPIGN_TEMPLATE_TAGS: `${microServices.CAMPAIGN_CRM}/template/tags`,

  // settings
  PRICING: `${microServices.USER_SERVICE}/campaign/pricing`,

  // block conatcts
  BLOCK_CONTACTS: `${microServices.CAMPAIGN_CRM}/block-contact`,

  //reports
  EMAIL_DETAILED_ANALYTICS_REPORT: `${microServices.CAMPAIGN_CRM}/report/detailed-analytics`,
  EMAIL_DETAILED_REPORT: `${microServices.CAMPAIGN_CRM}/report/detailed-report`,

  EMAIL_SUMMARY_REPORT: `${microServices.CAMPAIGN_CRM}/report/summary`,
  CAMPAIGN_REPORT_ADVANCED_LOG: `${microServices.CAMPAIGN_CRM}/report/advance-logs`,

  DOWNLOAD_REPORT: `${microServices.CAMPAIGN_CRM}/report/download`,

  // whatsapp routes
  LIST_OF_WHATSAPP_ACCOUNTS: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/account`, // get
  CREATE_WHATSAPP_ACCOUNT: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/create-account`, // post
  DELETE_WHATSAPP_ACCOUNT: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/delete-account`, // delete with last id
  UPDATE_CREDENTIALS_WHATSAPP_ACCOUNT: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/update-credentials`, // patch
  WHATSAPP_PHONE_PROFILE: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/profile`, // get pathc
  UPLOAD_WHATSAPP_PROFILE_PHOTO: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/profile/photo`, // post
  WHATSAPP_TEMPLATE: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/template`, // get post delete
  SYNC_WHATSAPP_TEMPLATE: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/template/sync`, // post with id
  WHATSAPP_MEDIA: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/media`, // get post delete
  UPLOAD_WHATSAPP_MEDIA: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/media`, // get post delete
  UPLOAD_WHATSAPP_SESSION_MEDIA: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/session-media`, // get post delete
  WHATSAPP_DAY_LIMIT: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/day-limit`, // get pathc
  WHATSAPP_CONSUMPTION_COUNT: `${microServices.CAMPAIGN_SETTINGS}/whatsapp/account/consumption`
};
