import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BlueGradientButton } from "../../../../components/button";
import * as actions from "../../actions/integrations.actions";

export default function Integrations() {
  const [isIntegrationSelected, setIsIntegrationSelected] = useState(false);
  const [selectedIntegrationName, setSelectedIntegrationName] = useState("");
  // const [selectedIntegrationData, setSelectedIntegrationData] = useState({})

  const dispatch = useDispatch();
  const history = useHistory();
  const integrationsListData = useSelector((state) => state.Integrations.integrationsDetails);
  const accountInfo = useSelector((state) => state.Integrations.integrationAccountDetails);

  useEffect(() => {
    if (history.location.pathname.split("/")[history.location.pathname.split("/").length - 1] !== "integrations") {
      history.push(history.location.pathname);
      dispatch(actions.getIntegrationAccountDetailsRequest({ apptype: history.location.pathname.split("/")[history.location.pathname.split("/").length - 1] }));
      setIsIntegrationSelected(true);
      setSelectedIntegrationName(history.location.pathname.split("/")[history.location.pathname.split("/").length - 1]);
    } else {
      history.push("/settings/profile/integrations");
      setIsIntegrationSelected(false);
      setSelectedIntegrationName("");
    }
  }, [history, dispatch, setIsIntegrationSelected]);

  useEffect(() => {
    dispatch(actions.getIntegrationDetailsRequest());
  }, [dispatch]);

  useEffect(() => {}, []);

  const onIntegrationItemClick = (data) => {
    setIsIntegrationSelected(true);
    setSelectedIntegrationName(data.integrationName);
    dispatch(actions.getIntegrationAccountDetailsRequest({ apptype: data.integrationName }));
    history.push(`/settings/profile/integrations/${data.integrationName}`);
  };

  const onConnectButtonClick = () => {
    dispatch(
      actions.getIntegrationAuthUrlRequest({}, (data) => {
        if (data.status) {
          window.location.href = `${data.URL}`;
        }
      }),
    );
  };

  const onDisconnectButtonClick = (id, apptype) => {
    dispatch(actions.deleteIntegrationRequest({ id: id, apptype: apptype }));
    history.push(`/settings/profile/integrations/${apptype}`);
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10 h-vh-120px">
        <div
          className="d-flex align-items-center card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_connected_accounts"
          aria-expanded="true"
          aria-controls="kt_account_connected_accounts"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{isIntegrationSelected ? `${selectedIntegrationName} Integration` : "Integrations"}</h3>
          </div>
          {isIntegrationSelected ? (
            <div>
              <BlueGradientButton
                onClick={() => {
                  history.push("/settings/profile/integrations");
                  setIsIntegrationSelected(false);
                }}
              >
                Back
              </BlueGradientButton>
            </div>
          ) : null}
        </div>
        <div className="collapse show">
          <div className="card-body border-top p-9 overflow-y-auto h-vh-220px-mx">
            {!isIntegrationSelected ? (
              // <div className="container">
              <div className="row">
                {integrationsListData &&
                  !integrationsListData.loading &&
                  integrationsListData.data &&
                  integrationsListData.data.length > 0 &&
                  integrationsListData.data.map((data, key) => {
                    return (
                      <div key={key} className="col-3 mb-10px h-100px">
                        <div
                          key={key}
                          // style={{alignItems:'center'}}
                          className="text-009ef7 bg-ecf8ff border-color-009ef7 btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-row h-100 p-3 flex-center"
                          onClick={() => onIntegrationItemClick(data)}
                        >
                          {/* <i className="mb-16px" style={{ "fontSize": "3rem", "color": "cornflowerblue",  }} ></i> */}
                          <img alt="" src={data.logo} width="50" height="50" />
                          <div className="ml-20px">
                            <span className="text-dark d-block mb-2 fw-500 font-size-16px">{data.name}</span>
                            <div className="overflow-wrap-anywhere">
                              <span className="font-size-14px">{data.description}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : // {/* </div> */}
            accountInfo && accountInfo.hasOwnProperty("status") && !accountInfo.status ? (
              <div className="notice d-flex warn rounded border-dashed p-6 mt-35px mx-35px mb-0 border-style-dotted bg-ecf8ff border-color-009ef7">
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4 mt-4px">
                  {/* <img alt="" src={selectedIntegrationData.logo} width="50" height="50" /> */}
                  {/* <i className="bi bi-exclamation-triangle font-size-38px text-red" ></i> */}
                </span>
                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                  <div className="mb-3 mb-md-0 fw-bold">
                    <h4 className="text-gray-800 fw-bolder">Connect Your {selectedIntegrationName} Account</h4>
                    <div className="fs-6 text-gray-600 pe-7">For use this integration, you need to connect with your account.</div>
                  </div>
                  <div className="form-check form-switch form-check-custom form-check-solid flex-direction-column">
                    <button type="button" className="btn btn-primary" onClick={() => onConnectButtonClick()}>
                      {" "}
                      Connect
                    </button>
                    <label className="form-check-label ml-57px" for="favoriteSwitch"></label>
                  </div>
                </div>
              </div>
            ) : accountInfo && accountInfo.hasOwnProperty("status") && accountInfo.status ? (
              <>
                <div className="d-flex flex-column">
                  <div className="text-dark font-weight-bold font-size-h3">Account Information:</div>
                  <div className="d-flex align-items-center mb-7 p-6">
                    <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                      <div className="symbol symbol-circle symbol-lg-60">
                        <img src={accountInfo.data.profileData && accountInfo.data.profileData.avtar} alt="" />
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
                        {accountInfo.data.profileData && accountInfo.data.profileData.firstName} {accountInfo.data.profileData && accountInfo.data.profileData.lastName}
                      </div>
                      <span className="text-muted font-weight-bold">{accountInfo.data.profileData && accountInfo.data.profileData.email}</span>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex">
                                            <div className="flex-shrink-0 mr-7">
                                                <div className="symbol symbol-50 symbol-lg-120">
                                                    <img alt="" src={accountInfo.data.profileData && accountInfo.data.profileData.pic_url} />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                                    <div className="mr-3">
                                                        <div className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{accountInfo.data.profileData && accountInfo.data.profileData.first_name} {accountInfo.data.profileData && accountInfo.data.profileData.last_name}
                                                            <i className="flaticon2-correct text-success icon-md ml-2"></i></div>
                                                        <div className="d-flex flex-wrap my-2">
                                                            <div className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                                            <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"></path>
                                                                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"></circle>
                                                                        </g>
                                                                    </svg>
                                                                </span>{accountInfo.data.profileData && accountInfo.data.profileData.email}</div>
                                                            <div className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g></g>
                                                                            <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>{accountInfo.data.profileData && accountInfo.data.profileData.job_title}</div>
                                                            <div className="text-muted text-hover-primary font-weight-bold">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000"></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>{accountInfo.data.profileData && accountInfo.data.profileData.location}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                <div className="notice d-flex warn rounded border-dashed p-6 mt-35px my-35px mx-0 border-style-dotted bg-fff5f8 border-color-f1416c">
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4 mt-4px">
                    {/* <img alt="" src={selectedIntegrationData.logo} width="50" height="50" /> */}
                    {/* <i className="bi bi-exclamation-triangle font-size-38px text-red" ></i> */}
                  </span>
                  <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    <div className="mb-3 mb-md-0 fw-bold">
                      <h4 className="text-gray-800 fw-bolder">Disconnect Your {selectedIntegrationName} Account</h4>
                      <div className="fs-6 text-gray-600 pe-7">Confirm once before you click on disconnect</div>
                    </div>
                    <div className="form-check form-switch form-check-custom form-check-solid flex-direction-column">
                      <button type="button" className="btn btn-danger" onClick={() => onDisconnectButtonClick(accountInfo.data._id, accountInfo.data.appType)}>
                        {" "}
                        Disconnect
                      </button>
                      <label className="form-check-label ml-57px" for="favoriteSwitch"></label>
                    </div>
                  </div>
                </div>
                {/* <div>
                                                Disconnect account:
                                            <button type="button" className="btn btn-danger" onClick={() => onDisconnectButtonClick(accountInfo.data._id, accountInfo.data.appType)}> Disconnect</button>
                                            </div> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
