import React, { useState } from "react";
import { Dropdown, Form, Row, Col, ListGroup, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../style.css";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../../utils/toaster";
import CustomSwitch from "../../custom_form/switch";
import { InputLimit } from "../../../constants";
import ConditionDropdown from "./condition-dropdown";
import CustomFilterInput from "./custom-input";
import SavedViewsDropdown from "./saved-filter-dropdown";
import SidePannelSkeleton from "./side-pannel-skeleton";
import _ from "lodash";

export default function SmartViewFilter(props) {
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const productColumns = useSelector((state) => state.Products.productFields);
  const peopleColumns = useSelector((state) => state.People.peopleFields);
  const companyColumns = useSelector((state) => state.Companies.companyFields);
  const leadColumns = useSelector((state) => state.Leads.leadFields);
  const activityColumns = useSelector((store) => store.activity.dataField);
  const dealColumns = useSelector((state) => state.DealList.dealFields);
  const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields);

  const [saveFilterView, setSaveFilterView] = useState(false);
  const [showSmartView, setShowSmartView] = useState(false);
  const [filterPattern, setFilterPattern] = useState("");
  const [isFilterPatternEdit, setIsFilterPatternEdit] = useState(false);
  const [fieldSearchText, setFieldSearchText] = useState("");

  const handleSelect = (data) => {
    if (data["_id"] === props.selectedFilterId) {
      props.applyFilter("");
    } else {
      props.applyFilter(data["_id"]);
    }
    setShow(false);
  };

  const handleClose = () => {
    setSaveFilterView(false);
    setShow(false);
    setShowSmartView(false);
  };
  const handleAccept = () => {
    if (!props.selectedFilterId) {
      if (saveFilterView) {
        if (validateFilter()) {
          props.addFilter();
          props.applyVirtualFilter({ andMatchConditions: props.addFilterFormData.andMatchConditions, orMatchConditions: props.addFilterFormData.orMatchConditions });
          handleClose();
        }
      } else {
        if (props.addFilterFormData.andMatchConditions.length === 0) {
          dispatchSnackbarError("At least one condition must be present");
          return false;
        } else {
          var i;
          for (i = 0; i < props.addFilterFormData.andMatchConditions.length; i++) {
            if (_.isEmpty(props.addFilterFormData.andMatchConditions[i].type) || _.isEmpty(props.addFilterFormData.andMatchConditions[i].field) || _.isEmpty(props.addFilterFormData.andMatchConditions[i].condition)) {
              dispatchSnackbarError("Please fill all the fields!");
              return false;
            }
          }
          for (i = 0; i < props.addFilterFormData.orMatchConditions.length; i++) {
            if (props.addFilterFormData.orMatchConditions[i].type === "" || props.addFilterFormData.orMatchConditions[i].field === "" || props.addFilterFormData.orMatchConditions[i].condition === "") {
              dispatchSnackbarError("Please fill all the fields!");
              return false;
            }
          }
        }
        props.applyVirtualFilter({ andMatchConditions: props.addFilterFormData.andMatchConditions, orMatchConditions: props.addFilterFormData.orMatchConditions });
        setShowSmartView(false);
      }
    }
  };

  const handleClearFilter = () => {
    onClearFilter();
  };
  const handleEditFilterAccept = () => {
    if (validateFilter()) {
      props.editFilter();
      setShowEditModal(false);
      setSaveFilterView(false);
    }
  };
  const handleCancelEditFilter = () => {
    setShowEditModal(false);
    setSaveFilterView(false);
    props.setAddFilterFormData({
      filterModule: props.pageSub,
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };
  const handleFilterDelete = () => {
    props.deleteFilter(props.addFilterFormData["_id"]);
    setShowEditModal(false);
    setSaveFilterView(false);
  };
  const onClearFilter = () => {
    props.applyFilter("");
    setSaveFilterView(false);
    setShow(false);
    setShowSmartView(false);
    props.setAddFilterFormData({
      filterModule: props.pageSub,
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const isVirtualFilterApplied = () => {
    if (props.addFilterFormData.orMatchConditions.length === 0 && props.addFilterFormData.andMatchConditions.length === 0) {
      return false;
    } else if (!props.selectedFilterId && !showEditModal) {
      return true;
    }
    return false;
  };

  const validateFilter = () => {
    if (props.addFilterFormData["filterName"].trim() === "") {
      dispatchSnackbarError("Filter Name can not be blank!");
      return false;
    } else if (props.addFilterFormData["visibility"] === "") {
      dispatchSnackbarError("Please select visibility!");
      return false;
    } else if (props.addFilterFormData.andMatchConditions.length === 0) {
      dispatchSnackbarError("At least one condition must be present");
      return false;
    } else {
      var i;
      for (i = 0; i < props.addFilterFormData.andMatchConditions.length; i++) {
        if (_.isEmpty(props.addFilterFormData.andMatchConditions[i].type) || _.isEmpty(props.addFilterFormData.andMatchConditions[i].field) || _.isEmpty(props.addFilterFormData.andMatchConditions[i].condition)) {
          dispatchSnackbarError("Please fill all the fields!");
          return false;
        }
      }
      for (i = 0; i < props.addFilterFormData.orMatchConditions.length; i++) {
        if (props.addFilterFormData.orMatchConditions[i].type === "" || props.addFilterFormData.orMatchConditions[i].field === "" || props.addFilterFormData.orMatchConditions[i].condition === "") {
          dispatchSnackbarError("Please fill all the fields!");
          return false;
        }
      }
    }
    return true;
  };

  const getCustomTableFieldData = (type, field) => {
    if (type === "product") {
      return productColumns.list.find((each) => each.apiKeyName === field);
    } else if (type === "lead") {
      return leadColumns.list.find((each) => each.apiKeyName === field);
    } else if (type === "people") {
      return peopleColumns.list.find((each) => each.apiKeyName === field);
    } else if (type === "company") {
      return companyColumns.list.find((each) => each.apiKeyName === field);
    } else if (type === "deal") {
      return dealColumns.list.find((each) => each.apiKeyName === field);
    } else if (type === "activity") {
      return activityColumns.list.find((each) => each.apiKeyName === field);
    } else if (type === "marketing") {
      return marketingColumns.list.find((each) => each.apiKeyName === field);
    }
    return {};
  };

  const renderAddFilterTooltip = (props) => (
    <Tooltip id="unfavorite-tooltip" {...props}>
      Add Filter
    </Tooltip>
  );

  const renderRemoveFilterTooltip = (props) => (
    <Tooltip id="unfavorite-tooltip" {...props}>
      Clear Filter
    </Tooltip>
  );

  const updatePattern = (_andMatchConditions) => {
    let pattern = "";
    if (_andMatchConditions.length > 1)
      _andMatchConditions.forEach((each, index) => {
        if (index + 1 !== _andMatchConditions.length) {
          if (each.operator === "or") {
            pattern = `( ${pattern ? pattern : index + 1} or ${index + 2} )`;
          } else {
            pattern = `( ${pattern ? pattern : index + 1} and ${index + 2} )`;
          }
        }
      });

    return pattern;
  };

  const onEditPatternClick = () => {
    setIsFilterPatternEdit(true);
    setFilterPattern(props.addFilterFormData.pattern);
  };
  const onSavePatternClick = () => {
    const rege = new RegExp(/(\(|\)|and|or|\s|[0-9])/gi);

    if (filterPattern.replace(rege, "")) {
      return dispatchSnackbarError("Invalid pattern entered!!!");
    }
    const filterNumbers = filterPattern.replace(/\(|\)|and|or/gi, " ");
    const numberArray = filterNumbers.split(" ").filter((a) => a);

    for (let i = 0; i < numberArray.length; i++) {
      if ((i + 1).toString() !== numberArray[i].toString()) {
        return dispatchSnackbarError("Invalid number order of pattern entered!!!");
      }
    }

    setIsFilterPatternEdit(false);
    setFilterPattern("");
  };
  const onCancelPatternClick = () => {
    setIsFilterPatternEdit(false);
    setFilterPattern("");
  };

  const handleAddAndCondition = () => {
    const _andMatchConditions = props.addFilterFormData.andMatchConditions;
    _andMatchConditions.push({ type: props.pageSub, field: "", condition: "", value: "", operator: "and" });
    const pattern = updatePattern(_andMatchConditions);
    props.setAddFilterFormData({ ...props.addFilterFormData, andMatchConditions: _andMatchConditions, pattern });
  };
  const onOperatorChange = (index) => {
    const _andMatchConditions = props.addFilterFormData.andMatchConditions.map((each, ind) => {
      if (index === ind) {
        if (each.operator === "or") {
          each.operator = "and";
        } else {
          each.operator = "or";
        }
      }
      return each;
    });
    const pattern = updatePattern(_andMatchConditions);
    props.setAddFilterFormData({ ...props.addFilterFormData, andMatchConditions: _andMatchConditions, pattern });
  };
  const handleRemoveAndCondition = (index) => {
    const _andMatchConditions = props.addFilterFormData.andMatchConditions.filter((each, indx) => index !== indx);
    const pattern = updatePattern(_andMatchConditions);
    props.setAddFilterFormData({ ...props.addFilterFormData, andMatchConditions: _andMatchConditions, pattern });
  };

  const handleSecondAndDropdown = (apiKeyName, index, item) => {
    const temp = item;
    temp["field"] = apiKeyName;
    temp["condition"] = "";
    temp["value"] = "";
    const _andMatchConditions = props.addFilterFormData.andMatchConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setAddFilterFormData({ ...props.addFilterFormData, andMatchConditions: _andMatchConditions });
  };

  const handleThirdAndDropdown = (condition, index, item) => {
    const temp = item;
    temp["condition"] = condition;
    temp["value"] = "";
    const _andMatchConditions = props.addFilterFormData.andMatchConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setAddFilterFormData({ ...props.addFilterFormData, andMatchConditions: _andMatchConditions });
  };

  const handleThirdOrDropdown = (condition, index, item) => {
    const temp = item;
    temp["condition"] = condition;
    const _orMatchConditions = props.addFilterFormData.orMatchConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setAddFilterFormData({ ...props.addFilterFormData, orMatchConditions: _orMatchConditions });
  };

  const handleAndValue = (item, index, value) => {
    const temp = item;
    temp["value"] = value;
    const _andMatchConditions = props.addFilterFormData.andMatchConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setAddFilterFormData({ ...props.addFilterFormData, andMatchConditions: _andMatchConditions });
  };

  const getSecondDropdown = (firstDropdown, index, isAndCondition, item) => {
    var tempColumns = [];
    var loading = true;
    var col;

    const unwantedFieldData = {
      deal: ["MULTI_OWNER_SELECT", "DEAL_PRODUCT_INPUT"],
      company: [],
      people: ["lastActivityDate", "activitiesToDo", "doneActivities", "totalActivities"],
      lead: ["lastActivityDate", "activitiesToDo", "doneActivities", "totalActivities"],
      activity: [],
      product: [],
      marketing: [],
    };
    if (firstDropdown === "deal") {
      tempColumns = dealColumns.list.filter((each) => each.fieldInputType !== "MULTI_OWNER_SELECT" && each.fieldInputType !== "DEAL_PRODUCT_INPUT");
      loading = dealColumns.loading;
    } else if (firstDropdown === "company") {
      tempColumns = companyColumns.list;
      loading = companyColumns.loading;
    } else if (firstDropdown === "people") {
      tempColumns = peopleColumns.list.filter((each) => each.fieldInputType !== "ACTIVITY_SHOW" && !unwantedFieldData["people"].includes(each.apiKeyName));
      loading = peopleColumns.loading;
    } else if (firstDropdown === "activity") {
      tempColumns = activityColumns.list;
      loading = activityColumns.loading;
    } else if (firstDropdown === "lead") {
      tempColumns = leadColumns.list.filter((each) => each.fieldInputType !== "ACTIVITY_SHOW" && !unwantedFieldData["lead"].includes(each.apiKeyName));
      loading = leadColumns.loading;
    } else if (firstDropdown === "product") {
      loading = productColumns.loading;
      tempColumns = productColumns.list;
    } else if (firstDropdown === "marketing") {
      loading = marketingColumns.loading;
      tempColumns = marketingColumns.list;
    } else return;
    col =
      !props.addFilterFormData.andMatchConditions[index].field || props.addFilterFormData.andMatchConditions[index].field === ""
        ? ""
        : tempColumns.find((col) => col["apiKeyName"] === props.addFilterFormData.andMatchConditions[index].field);
    return (
      <Dropdown className="mt-0 w-100">
        <Dropdown.Toggle className="ellipsis w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {!col || col === "" ? "Select Field" : col.fieldName}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100-mn">
          {loading ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="navi navi-hover">
              {
                <li className="navi-item py-20px px-24px">
                  <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Field" value={fieldSearchText} onChange={(e) => setFieldSearchText(e.target.value)} />
                </li>
              }
              <Dropdown.Divider className="m-0" />
              <div className="navi navi-hover h-25vh-mx position-relative overflow-y-auto">
                {tempColumns.map((field, fieldIndex) => {
                  if (field.fieldName.toLowerCase().includes(fieldSearchText.toLowerCase()))
                    return (
                      <Dropdown.Item key={fieldIndex} className="dropdown-hover" onSelect={(e) => handleSecondAndDropdown(field.apiKeyName, index, item)}>
                        {field.fieldName}
                      </Dropdown.Item>
                    );
                  return <div key={fieldIndex}></div>;
                })}
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getThirdDropdown = (index, isAndCondition, item) => {
    if (item["field"] === "") return;
    return (
      <>
        <Col xs={3} className="py-0 px-6px">
          <ConditionDropdown
            index={index}
            item={item}
            isAndCondition={isAndCondition}
            value={props.addFilterFormData.andMatchConditions[index].condition}
            field={getCustomTableFieldData(item.type, item["field"])}
            handleThirdAndDropdown={handleThirdAndDropdown}
            handleThirdOrDropdown={handleThirdOrDropdown}
          />
        </Col>
        <Col xs={5} className="py-0 px-6px">
          <CustomFilterInput
            condition={props.addFilterFormData.andMatchConditions[index].condition}
            selectedPipelineId={props.selectedPipelineId}
            page={props.page}
            pageSub={props.pageSub}
            field={getCustomTableFieldData(item.type, item["field"])}
            value={props.addFilterFormData.andMatchConditions[index].value}
            allData={props.addFilterFormData.andMatchConditions[index]}
            setValue={(value) => handleAndValue(item, index, value)}
          />
        </Col>
      </>
    );
  };

  const getFilterForm = () => {
    return (
      <Form>
        <Row>
          <Col>
            <p>Show data that match these conditions.</p>
          </Col>
        </Row>
        <Col className="pb-25px">
          {props.addFilterFormData.andMatchConditions.map((item, index) => (
            <Row className="and-condition-row" key={index}>
              <ListGroup.Item className="p-5 w-100">
                <Row className="align-items-center1">
                  <Col xs={3} className="py-0 px-6px">
                    {getSecondDropdown(props.addFilterFormData.andMatchConditions[index].type, index, true, item)}
                  </Col>
                  {getThirdDropdown(index, true, item)}
                  <Col className="py-0 px-6px">
                    <span className="float-right">
                      <Button onClick={() => handleRemoveAndCondition(index)} className="p-0" variant="none">
                        <i className="fa fa-minus-circle remove-condition-icon"></i>
                      </Button>
                    </span>
                  </Col>
                  {props.addFilterFormData.andMatchConditions.length !== index + 1 && (
                    <span className="and-condition-list" onClick={() => onOperatorChange(index)}>
                      {item.operator ? item.operator : "and"}
                    </span>
                  )}
                </Row>
              </ListGroup.Item>
            </Row>
          ))}
          <Row>
            <ListGroup.Item className="w-100 pl-10px">
              <Button onClick={handleAddAndCondition} className="p-0 text-decoration-none" variant="link">
                + Add condition
              </Button>
            </ListGroup.Item>
          </Row>
        </Col>
        {props.addFilterFormData.andMatchConditions.length > 1 && (
          <Row className="pb-25px">
            <Col className="d-flex align-items-center gap-10px">
              {isFilterPatternEdit ? (
                <div className="d-flex align-items-center gap-10px">
                  <input className="border-radius-4px border-width-1px border-style-solid border-color-ebedf3" value={filterPattern} onChange={(e) => setFilterPattern(e.target.value)} />
                  <span className="cursor-pointer fw-500" onClick={onCancelPatternClick}>
                    Cancel
                  </span>
                  <span className="cursor-pointer fw-500 text-3699ff" onClick={onSavePatternClick}>
                    Save
                  </span>
                </div>
              ) : (
                <>
                  <div className="fw-500 text-3699ff" onInput={onEditPatternClick}>
                    {" "}
                    Pattern:{" "}
                  </div>
                  <div>{props.addFilterFormData.pattern} </div>
                </>
              )}
            </Col>
          </Row>
        )}
        <Row className="pb-25px">
          <Col>
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input h-20px w-35px"
                type="checkbox"
                checked={saveFilterView}
                onChange={(e) => {
                  setSaveFilterView(e.target.checked);
                }}
                id={"save-filter"}
              />
              <label className="form-check-label ml-42px" htmlFor={"save-filter"}>
                Save Smart View
              </label>
            </div>
          </Col>
        </Row>
        {saveFilterView && (
          <>
            <Row className="pb-10px">
              <Col>
                <Form.Group id="smart-view-filter-new-name">
                  <Form.Label aria-label="filter-name">Filter Name:</Form.Label>
                  <Form.Control
                    maxLength={InputLimit.TEXT}
                    value={props.addFilterFormData["filterName"]}
                    onChange={(e) =>
                      props.setAddFilterFormData({
                        ...props.addFilterFormData,
                        filterName: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Filter Title"
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex">
                <CustomSwitch
                  id="favoriteSwitch"
                  checked={props.addFilterFormData["isFavorite"]}
                  handleChange={(e) => props.setAddFilterFormData({ ...props.addFilterFormData, isFavorite: e.target.checked })}
                  label="Favorite"
                />
              </Col>
            </Row>
            <Row className="d-none">
              <Col>
                <Form.Group id="smart-view-filter-save-columns">
                  <label className="checkbox checkbox-md checkbox-single">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={props.addFilterFormData["isSelectedColumnsSave"]}
                      onChange={(e) => props.setAddFilterFormData({ ...props.addFilterFormData, isSelectedColumnsSave: e.target.checked })}
                    />
                    <span className="mr-5px"></span>
                    Save selected columns with the filter
                  </label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group id="smart-view-filter-visibility">
                  <Form.Label aria-label="visibility">Visibility</Form.Label>
                  <Dropdown className="mt-1 w-100">
                    <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
                      {props.addFilterFormData["visibility"] === "" ? "Select Visibility" : props.addFilterFormData["visibility"]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100-mn">
                      <Dropdown.Item className="dropdown-hover" onSelect={() => props.setAddFilterFormData({ ...props.addFilterFormData, visibility: "Public" })}>
                        Public
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-hover" onSelect={() => props.setAddFilterFormData({ ...props.addFilterFormData, visibility: "Private" })}>
                        Private
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-hover" onSelect={() => props.setAddFilterFormData({ ...props.addFilterFormData, visibility: "Team only" })}>
                        Team only
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  };

  return (
    <>
      <div className="d-flex">
        <SavedViewsDropdown
          show={show}
          isVirtualFilterApplied={isVirtualFilterApplied()}
          setShow={setShow}
          showSmartView={showSmartView}
          setShowSmartView={setShowSmartView}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          setSaveFilterView={setSaveFilterView}
          activeFilter={props.activeFilter}
          loading={props.loading}
          customFilters={props.customFilters}
          handleSelect={handleSelect}
          setAddFilterFormData={props.setAddFilterFormData}
          selectedFilterId={props.selectedFilterId}
          handleFavoriteFilter={(data) => props.favoriteFilter(data)}
          handleAddFilter={() => setShowSmartView(true)}
        />
        {props.selectedFilterId || isVirtualFilterApplied() ? (
          <div
            className="font-size-16px d-flex align-items-center justify-content-center custom-outline-button-wrapper btn btn-sm btn-secondary font-weight-bolder px-3 py-0 custom-secondory-cta"
            onClick={() => onClearFilter()}
          >
            {" "}
            <OverlayTrigger overlay={renderRemoveFilterTooltip}>
              <i className="bi bi-x-circle p-0"></i>
            </OverlayTrigger>{" "}
          </div>
        ) : (
          <div
            className="font-size-16px d-flex align-items-center justify-content-center custom-outline-button-wrapper btn btn-sm btn-secondary font-weight-bolder px-3 py-0 custom-secondory-cta"
            onClick={() => setShowSmartView(true)}
          >
            {" "}
            <OverlayTrigger overlay={renderAddFilterTooltip}>
              <i className="bi bi-plus-circle p-0"></i>
            </OverlayTrigger>
          </div>
        )}
      </div>

      {/* Add Filter */}
      <SidePannelSkeleton
        show={showSmartView}
        setShow={setShowSmartView}
        title={"Add Filter"}
        showButtons={true}
        showDeleteButton={true}
        cancelButtonName={"Cancel"}
        saveButtonName={"Apply"}
        deleteButtonText={"Clear"}
        handleClose={handleClose}
        handleAccept={handleAccept}
        handleDelete={handleClearFilter}
      >
        {getFilterForm()}
      </SidePannelSkeleton>

      {/* edit filter */}
      <SidePannelSkeleton
        show={showEditModal}
        setShow={setShowEditModal}
        title={"Edit Filter"}
        showButtons={true}
        showDeleteButton={true}
        cancelButtonName={"Cancel"}
        saveButtonName={"Edit"}
        deleteButtonText={"Delete"}
        handleClose={handleCancelEditFilter}
        handleAccept={handleEditFilterAccept}
        handleDelete={handleFilterDelete}
      >
        {getFilterForm()}
      </SidePannelSkeleton>
    </>
  );
}
