import { getUserProfile } from "../../types/profile-settings.types";

const initState = {
  profileDetails: {
    loading: false,
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
};

export default function profileOverviewReducer(state = initState, action = {}) {
  switch (action.type) {
    case getUserProfile.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        profileDetails: { ...state.profileDetails, loading: true },
      };
    case getUserProfile.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profileDetails: { loading: false, list: action.payload },
      };
    case getUserProfile.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        profileDetails: { ...state.profileDetails, loading: false },
        error: { err: true, errMsg: action.payload },
      };
    default:
      return state;
  }
}
