import { GET_SETTING_ACTIVITIES_REQUEST, GET_SETTING_ACTIVITIES_RESPONSE, GET_SETTING_ACTIVITIES_ERROR } from "../types/activity.types";

export function getSettingActivityRequest(req) {
  return {
    type: GET_SETTING_ACTIVITIES_REQUEST,
    payload: req,
  };
}
export function getSettingActivitySuccess(req) {
  return {
    type: GET_SETTING_ACTIVITIES_RESPONSE,
    payload: req,
  };
}
export function getSettingActivityError(req) {
  return {
    type: GET_SETTING_ACTIVITIES_ERROR,
    payload: req,
  };
}
