import { getListOfWhatsappTemplates, getListOfWhatsappTemplatesBySearch, createWhatsappTemplate, deleteWhatsappTemplate } from "../../types/campaign_whatsapp_template_types";

const initState = {
    list: [],
    searchList: [],
    count: 0,
    loading: false,
};

export default function campaignWhatsappTemplatesReducer(state = initState, action = {}) {
    switch (action.type) {
        case getListOfWhatsappTemplates.REQUEST:
            return {
                ...state,
                loading: true
            };
        case getListOfWhatsappTemplates.SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload.data,
                count: action.payload.count

            };
        case getListOfWhatsappTemplates.FAILURE:
            return {
                ...state,
                loading: false
            };
        case createWhatsappTemplate.REQUEST:
        case deleteWhatsappTemplate.REQUEST:
            return {
                ...state,
                loading: true
            }
        case createWhatsappTemplate.SUCCESS:
        case deleteWhatsappTemplate.SUCCESS:
        case createWhatsappTemplate.FAILURE:
        case deleteWhatsappTemplate.FAILURE:
            return {
                ...state,
                loading: false
            }

        case getListOfWhatsappTemplatesBySearch.REQUEST:
            return {
                ...state,
                loading: true
            };
        case getListOfWhatsappTemplatesBySearch.SUCCESS:
            return {
                ...state,
                loading: false,
                searchList: action.payload.data,
                count: action.payload.count
            };
        case getListOfWhatsappTemplatesBySearch.FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
