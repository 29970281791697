import { takeLatest, put, call, select } from "redux-saga/effects";
import * as leadActions from "../actions/actions";
//import required action types below
import {
  LEADS_REQUEST,
  LEADFIELDS_REQUEST,
  LEADTAGS_REQUEST,
  LEADTAGSADD_REQUEST,
  LEADTAGSEDIT_REQUEST,
  LEADTAGSDELETE_REQUEST,
  LEADCELLEDIT_REQUEST,
  LEADADD_REQUEST,
  LEADBULKEDIT_REQUEST,
  LEADDELETE_REQUEST,
  LEADFILTERS_REQUEST,
  LEADFILTERADD_REQUEST,
  LEADFILTEREDIT_REQUEST,
  LEADFILTERDELETE_REQUEST,
  LEADADDFIELDS_REQUEST,
  LEADS_UPDATE_REQUEST,
  LEAD_POPUP_REQUEST,
  LEAD_POPUP_EDIT_REQUEST,
  LEAD_POPUP_UPDATE_REQUEST,
  LEAD_CONVERT_TO_JUNK_REQUEST,
  LEAD_CONVERT_TO_ACTIVE_REQUEST,
  LEADSMERGE_REQUEST,
  LEADFILTERFAVORITE_REQUEST,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_SHOWHIDE_REQUEST,
  SIGNATURE_GET_REQUEST,
  SIGNATURE_ADD_REQUEST,
  SIGNATURE_EDIT_REQUEST,
  SIGNATURE_DELETE_REQUEST,
  NOTE_ADD_REQUEST,
  SEARCH_ALL_REQUEST,
  GET_LEADLOSTREASONS_REQUEST,
  LEAD_POPUP_REQUEST_AFTER,
  LEAD_CONVERT_TO_PEOPLE_REQUEST,
  generateLeadAIWFData,
} from "../types/types";
import { getDealPopupDetailsRequestAfter } from "../../../deals/dealPipeline/actions";
import { peoplePopupRequest, peoplePopupRequestAfter } from "../../../customers/store/actions/actions";
import api from "../../../../network/apis/api";
import { dispatchSnackbarError } from "../../../../utils/toaster";

function* leadsFunction(action) {
  try {
    var response;
    if (action.payload.filterType) {
      response = yield call(api.post, "/api/crm/saved-filters/apply", action.payload);
    } else {
      response = yield call(api.get, "/api/crm/lead/get", action.payload);
    }
    if (response.status === 200) {
      yield put(leadActions.leadsSuccess(response.data));
    } else {
      yield put(leadActions.leadsError({ message: "Could Not Get Leads" }));
    }
  } catch (e) {
    yield put(leadActions.leadsError({ message: "Could Not Get Leads" }));
  }
}

function* fieldUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/get", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadFieldsSuccess(response.data.data));
      yield put(leadActions.fieldUpdateSuccess(response.data.data));
    } else {
      yield put(leadActions.fieldUpdateError({ message: "Could Not Get lead Fields" }));
    }
  } catch (e) {
    yield put(leadActions.fieldUpdateError({ message: "Could Not Get lead Fields" }));
  }
}

function* fieldShowHideFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
    if (response.status === 200) {
      yield put(leadActions.fieldUpdateRequest({ fieldType: "LEAD" }));
    } else {
      yield put(leadActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(leadActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* fieldUpdatePriorityFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
    if (response.status === 200) {
      yield put(leadActions.fieldUpdateRequest({ fieldType: "LEAD" }));
    } else {
      yield put(leadActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(leadActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* leadPopupFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/lead/get-selected-details", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadPopupSuccess(response.data));
    } else {
      yield put(leadActions.leadPopupError({ message: "Could Not Get Lead Data" }));
    }
  } catch (e) {
    yield put(leadActions.leadPopupError({ message: "Could Not Get Lead Data" }));
  }
}

function* leadPopupEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/lead/update-popup-details", action.payload.editData);
    if (response.status === 200) {
      yield put(leadActions.leadPopupEditSuccess({ message: "Lead Edited Successfully" }));

      yield put(leadActions.leadPopupUpdateRequest({ loadingData: { id: action.payload.loadingData }, cellId: action.payload.cellId }));
      yield put(leadActions.leadsUpdateRequest({ loadingData: action.payload.tableLoadingData }));
      yield put(peoplePopupRequestAfter({ id: action.payload.loadingData }));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(leadActions.leadPopupEditError({ message: response.data.message }));
    } else {
      yield put(leadActions.leadPopupEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(leadActions.leadPopupEditError({ message: "Could Not Edit" }));
  }
}

function* leadPopupUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/lead/get-selected-details", action.payload.loadingData);
    if (response.status === 200) {
      yield put(leadActions.leadPopupUpdateSuccess(response.data));
    } else {
      yield put(leadActions.leadPopupUpdateError({ message: "Could Not Get Lead Data" }));
    }
  } catch (e) {
    yield put(leadActions.leadPopupUpdateError({ message: "Could Not Get Lead Data" }));
  }
}

function* leadsUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/lead/get", action.payload.loadingData);
    if (response.status === 200) {
      yield put(leadActions.leadsUpdateSuccess(response.data));
    } else {
      yield put(leadActions.leadsUpdateError({ message: "Could Not Get Leads" }));
    }
  } catch (e) {
    yield put(leadActions.leadsUpdateError({ message: "Could Not Get Leads" }));
  }
}

function* leadFieldsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Leads.leadFields.list) && store.Leads.leadFields.list.length > 0) {
      yield put(leadActions.leadFieldsSuccess(store.Leads.leadFields.list));
    } else {
      const response = yield call(api.get, "/api/crm/data-fields/get", action.payload.request);
      if (response.status === 200) {
        yield put(leadActions.leadFieldsSuccess(response.data.data));
      } else {
        yield put(leadActions.leadFieldsError({ message: "Could Not Get Lead Fields" }));
      }
    }
  } catch (e) {
    yield put(leadActions.leadFieldsError({ message: "Could Not Get Lead Fields" }));
  }
}

function* leadAddFieldsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/add-form-fields", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadAddFieldsSuccess(response.data.data));
    } else {
      yield put(leadActions.leadAddFieldsError({ message: "Could Not Get Lead Fields" }));
    }
  } catch (e) {
    yield put(leadActions.leadAddFieldsError({ message: "Could Not Get Lead Fields" }));
  }
}

function* leadAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/lead/create", action.payload.leadData);
    if (response.status === 200) {
      yield put(leadActions.leadAddSuccess({ message: response.data.message }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      // dispatchSnackbarError(response.data.message, "error")
      yield put(leadActions.leadAddError({ message: response.data.message }));
    } else {
      yield put(leadActions.leadAddError({ message: "Could Not Add Lead" }));
    }
  } catch (e) {
    yield put(leadActions.leadAddError({ status: false, message: "Could Not Add Lead" }));
  }
}

function* leadBulkEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/lead/bulk-edit", action.payload.leadData);
    if (response.status === 200) {
      yield put(leadActions.leadBulkEditSuccess({ message: "Leads Edited Successfully" }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(leadActions.leadBulkEditError({ message: response.data.message }));
    } else {
      yield put(leadActions.leadBulkEditError({ message: "Could Not Edit Leads" }));
    }
  } catch (e) {
    yield put(leadActions.leadBulkEditError({ message: "Could Not Edit Leads" }));
  }
}

function* leadDeleteFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/lead/delete", action.payload.leadIds);
    if (response.status === 200) {
      yield put(leadActions.leadDeleteSuccess({ message: `${action.payload.leadIds.ids.length > 1 ? "Leads" : "Lead"} Deleted Successfully` }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(leadActions.leadDeleteError({ message: response.data.message }));
    } else {
      yield put(leadActions.leadDeleteError({ message: "Could Not Delete Leads" }));
    }
  } catch (e) {
    yield put(leadActions.leadDeleteError({ message: "Could Not Delete Lead" }));
  }
}

function* leadConvertToJunkFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/lead/convert-active-to-junk", action.payload.id);
    if (response.status === 200) {
      yield put(leadActions.convertToPeopleSuccess({ message: "Lead Converted Successfully" }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(leadActions.convertToPeopleError({ message: response.data.message }));
    } else {
      yield put(leadActions.convertToPeopleError({ message: "Could Not Convert Lead" }));
    }
  } catch (e) {
    yield put(leadActions.convertToPeopleError({ message: "Could Not Convert Lead" }));
  }
}

function* leadConvertToPeopleFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/lead/convert-active-to-people", action.payload.id);
    if (response.status === 200) {
      yield put(leadActions.convertToJunkSuccess({ message: "Lead Converted Successfully" }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(leadActions.convertToJunkError({ message: response.data.message }));
    } else {
      yield put(leadActions.convertToJunkError({ message: "Could Not Convert Lead" }));
    }
  } catch (e) {
    yield put(leadActions.convertToJunkError({ message: "Could Not Convert Lead" }));
  }
}

function* leadConvertToActiveFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/lead/convert-junk-to-active", action.payload.id);
    if (response.status === 200) {
      yield put(leadActions.convertToActiveSuccess({ message: "Lead Converted Successfully" }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(leadActions.convertToActiveError({ message: response.data.message }));
    } else {
      yield put(leadActions.convertToActiveError({ message: "Could Not Convert Lead" }));
    }
  } catch (e) {
    yield put(leadActions.convertToActiveError({ message: "Could Not Convert Lead" }));
  }
}

function* leadTagsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Leads.leadTags.list) && store.Leads.leadTags.list.length > 0) {
      yield put(leadActions.leadTagsSuccess(store.Leads.leadTags.list));
    } else {
      const response = yield call(api.get, "/api/crm/tag/get", action.payload.request);
      if (response.status === 200) {
        yield put(leadActions.leadTagsSuccess(response.data.data));
      } else {
        yield put(leadActions.leadTagsError({ message: "Could Not Get Lead Tags" }));
      }
    }
  } catch (e) {
    yield put(leadActions.leadTagsError({ message: "Could Not Get Lead Tags" }));
  }
}

function* leadTagsAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/tag/create", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadTagsAddSuccess({ message: "Tag Added Successfully" }));
      yield put(leadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: false }));
    } else {
      yield put(leadActions.leadTagsError({ message: "Could Not Add Lead Tag" }));
    }
  } catch (e) {
    yield put(leadActions.leadTagsError({ status: false, message: "Could Not Add Lead Tag" }));
  }
}

function* leadTagsEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/edit", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadTagsEditSuccess({ message: "Tag Edited Successfully" }));
      yield put(leadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: false }));
    } else {
      yield put(leadActions.leadTagsError({ message: "Could Not Edit Tag" }));
    }
  } catch (e) {
    yield put(leadActions.leadTagsError({ message: "Could Not Edit Tag" }));
  }
}

function* leadTagsDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/tag/delete/" + action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadTagsDeleteSuccess({ message: "Tag Deleted Successfully" }));
      yield put(leadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: false }));
    } else {
      yield put(leadActions.leadTagsError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(leadActions.leadTagsError({ message: "Could Not Delete Tag" }));
  }
}

function* leadCellEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/lead/edit", action.payload.editData);
    if (response.status === 200) {
      yield put(leadActions.leadCellEditSuccess({ message: "Lead Edited Successfully" }));
      yield put(leadActions.leadsUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.cellId }));
      if (action.payload.editData.id) yield put(leadActions.leadPopupRequestAfter({ id: action.payload.editData.id }));
    } else if (response.status === 400) {
      yield put(leadActions.leadCellEditError({ message: response.data.message }));
    } else {
      yield put(leadActions.leadCellEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(leadActions.leadCellEditError({ message: "Could Not Edit" }));
  }
}


function* generateLeadAIWFDataFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/ai-wizard/ai-wizard-field/generate", action.payload.data);
    // const response = yield new Promise((resolve) => setTimeout(() => resolve({ status: 200, data: { message: "Successful" } }), 1000))
    if (response.status === 200) {
      yield put(leadActions.generateLeadAIWFDataSuccess({ message: response.data.message }));
      yield put(leadActions.leadsUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.data.moduleId }));
      if (action.payload.data.moduleId) yield put(leadActions.leadPopupRequestAfter({ id: action.payload.data.moduleId }));
    } else {
      yield put(leadActions.generateLeadAIWFDataError({ message: response.data.message }));
    }
  } catch (e) {
    yield put(leadActions.generateLeadAIWFDataError({ message: "Could Not Generate Data" }));
  }
}

function* leadFiltersFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/saved-filters/get?type=lead", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadFiltersSuccess(response.data));
    } else {
      yield put(leadActions.leadFiltersError({ message: "Could Not Get Filters" }));
    }
  } catch (e) {
    yield put(leadActions.leadFiltersError({ message: "Could Not Get Filters" }));
  }
}

function* leadFilterAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadFilterAddSuccess({ message: "Filter Added Successfully" }));
      yield put(leadActions.leadFiltersRequest());
      if (action.cb) yield action.cb(response.data);
    } else {
      yield put(leadActions.leadFiltersError({ message: "Could Not Add Filter" }));
      if (action.cb) yield action.cb(response.data);
    }
  } catch (e) {
    yield put(leadActions.leadFiltersError({ message: "Could Not Add Filter" }));
  }
}

function* leadFilterEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadFilterEditSuccess({ message: "Filter Edited Successfully" }));
      yield put(leadActions.leadFiltersRequest());
    } else {
      yield put(leadActions.leadFilterEditError({ message: "Could Not Edit Filter" }));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(leadActions.leadFilterEditError({ message: "Could Not Edit Filter" }));
  }
}

function* leadFilterFavoriteFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadFilterFavoriteSuccess({ message: "Filter Edited Successfully" }));
      yield put(leadActions.leadFiltersRequest());
    } else {
      yield put(leadActions.leadFilterFavoriteError({ message: "Could Not Favorite Filter" }));
    }
  } catch (e) {
    yield put(leadActions.leadFilterFavoriteError({ message: "Could Not Favorite Filter" }));
  }
}

function* leadFilterDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/saved-filters/delete/" + action.payload);
    if (response.status === 200) {
      yield put(leadActions.leadFilterDeleteSuccess({ message: "Filter Deleted Successfully" }));
      yield put(leadActions.leadFiltersRequest());
    } else {
      yield put(leadActions.leadFilterDeleteError({ message: "Could Not Delete Filter" }));
    }
  } catch (e) {
    yield put(leadActions.leadFilterDeleteError({ message: "Could Not Delete Filter" }));
  }
}

function* leadsMergeFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/lead/merge", action.payload.data);
    if (response.status === 200) {
      yield put(leadActions.leadsMergeSuccess({ message: "Leads Merged Successfully" }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(leadActions.leadsMergeError({ message: response.data.message }));
    } else {
      yield put(leadActions.leadsMergeError({ message: "Could Not Merge Leads" }));
    }
  } catch (e) {
    yield put(leadActions.leadsMergeError({ message: "Could Not Merge Leads" }));
  }
}

function* signatureFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Leads.signatureList.list) && store.Leads.signatureList.list.length > 0) {
      yield put(leadActions.signatureSuccess({ data: store.Leads.signatureList.list }));
    } else {
      const response = yield call(api.get, "/api/crm/signature");
      if (response.status === 200) {
        yield put(leadActions.signatureSuccess(response.data));
      } else {
        yield put(leadActions.signatureError({ message: "Could Not Get Signatures" }));
      }
    }
  } catch (e) {
    yield put(leadActions.signatureError({ message: "Could Not Get Signatures" }));
  }
}

function* signatureAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/signature", action.payload);
    if (response.status === 201) {
      yield put(leadActions.signatureAddSuccess({ message: "Signature Added Successfully" }));
      yield put(leadActions.signatureRequest({ skipIfDataPresent: false }));
    } else {
      yield put(leadActions.signatureAddError({ message: "Could Not Add Signature" }));
    }
  } catch (e) {
    yield put(leadActions.signatureAddError({ message: "Could Not Add Signature" }));
  }
}

function* signatureEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/signature/" + action.payload.id, action.payload.data);
    if (response.status === 200) {
      yield put(leadActions.signatureEditSuccess({ message: "Signature Edited Successfully" }));
      yield put(leadActions.signatureRequest({ skipIfDataPresent: false }));
    } else {
      yield put(leadActions.signatureEditError({ message: "Could Not Edit Signature" }));
    }
  } catch (e) {
    yield put(leadActions.signatureEditError({ message: "Could Not Edit Signature" }));
  }
}

function* signatureDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/signature/" + action.payload);
    if (response.status === 200) {
      yield put(leadActions.signatureDeleteSuccess({ message: "Signatures Deleted Successfully" }));
      yield put(leadActions.signatureRequest({ skipIfDataPresent: false }));
    } else {
      yield put(leadActions.signatureDeleteError({ message: "Could Not Delete Signatures" }));
    }
  } catch (e) {
    yield put(leadActions.signatureDeleteError({ message: "Could Not Delete Signatures" }));
  }
}

function* noteAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/activity/notes", action.payload.noteBody);
    if (response.status === 200) {
      yield put(leadActions.noteAddSuccess({ message: "Note Added Successfully" }));
      if (action.payload.type === "deal") {
        yield put(getDealPopupDetailsRequestAfter(action.payload.noteBody.dealId));
      } else if (action.payload.type === "lead") {
        yield put(leadActions.leadPopupRequest({ id: action.payload.noteBody.peopleId }));
      } else if (action.payload.type === "people") {
        yield put(peoplePopupRequest({ id: action.payload.noteBody.peopleId }));
      }
    } else if (response.status === 400) {
      yield put(leadActions.noteAddError({ message: response.data.message }));
    } else {
      yield put(leadActions.noteAddError({ message: "Could Not Add Note" }));
    }
  } catch (e) {
    yield put(leadActions.noteAddError({ message: "Could Not Add Note" }));
  }
}

function* globalSearchFunction(action) {
  try {
    const res = yield call(api.get, `/api/crm/lead/v2/search-all`, action.payload);
    if (res.status === 200) {
      yield put(leadActions.searchAllSuccess(res.data.data));
    }
  } catch (e) {
    yield put(leadActions.searchAllError(e));
  }
}

function* getLeadLostReasons(action) {
  try {
    const res = yield call(api.get, "/api/crm/lost-reason/lead", action.payload);
    if (res.status === 200) {
      yield put(leadActions.getLeadLostReasonsSuccess(res.data.data));
    }
  } catch (e) {
    yield put(leadActions.getLeadLostReasonsError(e));
  }
}

export default function* LeadSaga() {
  yield takeLatest(LEADS_REQUEST, leadsFunction);
  yield takeLatest(FIELD_UPDATE_REQUEST, fieldUpdateFunction);
  yield takeLatest(FIELD_SHOWHIDE_REQUEST, fieldShowHideFunction);
  yield takeLatest(FIELD_UPDATEPRIORITY_REQUEST, fieldUpdatePriorityFunction);
  yield takeLatest(LEAD_POPUP_REQUEST, leadPopupFunction);
  yield takeLatest(LEAD_POPUP_REQUEST_AFTER, leadPopupFunction);
  yield takeLatest(LEADS_UPDATE_REQUEST, leadsUpdateFunction);
  yield takeLatest(LEAD_POPUP_EDIT_REQUEST, leadPopupEditFunction);
  yield takeLatest(LEAD_POPUP_UPDATE_REQUEST, leadPopupUpdateFunction);
  yield takeLatest(LEADFIELDS_REQUEST, leadFieldsFunction);
  yield takeLatest(LEADADDFIELDS_REQUEST, leadAddFieldsFunction);
  yield takeLatest(LEADADD_REQUEST, leadAddFunction);
  yield takeLatest(LEADBULKEDIT_REQUEST, leadBulkEditFunction);
  yield takeLatest(LEADDELETE_REQUEST, leadDeleteFunction);
  yield takeLatest(LEAD_CONVERT_TO_JUNK_REQUEST, leadConvertToJunkFunction);
  yield takeLatest(LEAD_CONVERT_TO_PEOPLE_REQUEST, leadConvertToPeopleFunction);
  yield takeLatest(LEAD_CONVERT_TO_ACTIVE_REQUEST, leadConvertToActiveFunction);
  yield takeLatest(LEADTAGS_REQUEST, leadTagsFunction);
  yield takeLatest(LEADTAGSADD_REQUEST, leadTagsAddFunction);
  yield takeLatest(LEADTAGSEDIT_REQUEST, leadTagsEditFunction);
  yield takeLatest(LEADTAGSDELETE_REQUEST, leadTagsDeleteFunction);
  yield takeLatest(LEADCELLEDIT_REQUEST, leadCellEditFunction);
  yield takeLatest(LEADFILTERS_REQUEST, leadFiltersFunction);
  yield takeLatest(LEADFILTERADD_REQUEST, leadFilterAddFunction);
  yield takeLatest(LEADFILTEREDIT_REQUEST, leadFilterEditFunction);
  yield takeLatest(LEADFILTERFAVORITE_REQUEST, leadFilterFavoriteFunction);
  yield takeLatest(LEADFILTERDELETE_REQUEST, leadFilterDeleteFunction);
  yield takeLatest(LEADSMERGE_REQUEST, leadsMergeFunction);
  yield takeLatest(SIGNATURE_GET_REQUEST, signatureFunction);
  yield takeLatest(SIGNATURE_ADD_REQUEST, signatureAddFunction);
  yield takeLatest(SIGNATURE_EDIT_REQUEST, signatureEditFunction);
  yield takeLatest(SIGNATURE_DELETE_REQUEST, signatureDeleteFunction);
  yield takeLatest(NOTE_ADD_REQUEST, noteAddFunction);
  yield takeLatest(SEARCH_ALL_REQUEST, globalSearchFunction);
  yield takeLatest(GET_LEADLOSTREASONS_REQUEST, getLeadLostReasons);
  yield takeLatest(generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_REQUEST, generateLeadAIWFDataFunction);
}
