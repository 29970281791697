import { decreaseDate, getMonthTimeStamp, getWeekTimeStamp, decreaseMonth } from "./dayWiseFilter";
// import moment from 'moment';

export const daysFilter = (type) => {
  if (!type || typeof type !== "string") {
    console.warn("parameter type should be valid string");
    return;
  }

  let lineData = {};

  switch (type) {
    case "today": {
      lineData.start_time = new Date().setHours(0, 0, 0, 0);
      lineData.end_time = new Date().setHours(23, 59, 59, 999);
      lineData.filterType = "Today";
      return lineData;
    }
    case "this_week": {
      const tw = getWeekTimeStamp(new Date());
      tw.filterType = "This Week";
      // tw.end_time = moment().endOf('week').toDate().getTime();
      // tw.start_time = moment().startOf('week').toDate().getTime();
      // tw.end_time = new Date().getTime();
      return tw;
    }
    case "last_week": {
      const lw = getWeekTimeStamp(new Date(decreaseDate(7)));
      lw.filterType = "Last Week";
      return lw;
    }
    case "last_7_days": {
      lineData.start_time = decreaseDate(7);
      lineData.end_time = new Date().getTime();
      lineData.filterType = "Last 7 Days";
      return lineData;
    }
    case "this_month": {
      const d = getMonthTimeStamp(new Date().getMonth() + 1);
      d.filterType = "This Month";
      // d.end_time = new Date().getTime();
      return d;
    }
    case "last_month": {
      const dt = getMonthTimeStamp(new Date().getMonth());
      dt.filterType = "Last Month";
      return dt;
    }
    case "last_30_days": {
      lineData.start_time = decreaseDate(30);
      lineData.end_time = new Date().setHours(23, 59, 59, 999);
      lineData.filterType = "Last 30 Days";
      return lineData;
    }
    case "past_3_months": {
      lineData.start_time = decreaseMonth(3);
      lineData.end_time = new Date().getTime();
      lineData.filterType = "Last 3 Months";
      return lineData;
    }
    case "custom": {
      lineData.filterType = "Custom";
      return lineData;
    }
    default: {
      lineData.start_time = new Date().getTime();
      lineData.end_time = decreaseDate(30);
      return lineData;
    }
  }
};
