import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import IntegrationPage from "./containers/index";
import EmailValidationPage from "./containers/email-validation.page";
import EmailFinderPage from "./containers/email-finder.page";

export default function Integrations(props) {
  const { match } = props;

  return (
    <>
      <Switch>
        <Route exact path={`${match.url}`} component={IntegrationPage} />
        <Route exact path={`${match.url}/email-validation`} component={EmailValidationPage} />
        <Route exact path={`${match.url}/email-finder`} component={EmailFinderPage} />
        <Redirect to={`${match.url}`} />
      </Switch>
    </>
  );
}
