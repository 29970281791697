import { getVariableSupportedData } from "../types/types";

export const getVariableSupportedDataRequest = (payload, cb) => ({
    type: getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_REQUEST,
    payload,
    cb,
});
export const getVariableSupportedDataSuccess = (payload) => ({
    type: getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_SUCCESS,
    payload,
});
export const getVariableSupportedDataError = (payload) => ({
    type: getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_ERROR,
    payload,
});