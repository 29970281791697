import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Nav, Tab } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../utils/toaster";
import { CustomModal } from "../modal";

const _tabMenu = [
  {
    name: "Marketing List",
    keyname: "marketing",
  },
  {
    name: "Lead / People",
    keyname: "lead",
  },
  // {
  //   name: "People",
  //   keyname: "people",
  // },
  {
    name: "Company",
    keyname: "company",
  },
  {
    name: "User",
    keyname: "user",
  },
];

const userColumns = [
  {
    fieldName: "User Name",
    apiKeyName: "userName",
  },
  {
    fieldName: "User Email",
    apiKeyName: "userEmail",
  },
  {
    fieldName: "Company Name",
    apiKeyName: "userCompanyName",
  },
];

//
export default function VariableOptionsPro(props) {
  const [key, setKey] = useState("marketing");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [tabMenu, setTabMenu] = useState(_tabMenu);
  const [customKeyNames, setCustomKeyNames] = useState([]);

  const peopleColumns = useSelector((state) => state.People.peopleFields.list);
  const companyColumns = useSelector((state) => state.Companies.companyFields.list);
  const leadColumns = useSelector((state) => state.Leads.leadFields.list);
  // const dealColumns = useSelector((state) => state.DealList.dealFields.list);
  const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields.list);

  useEffect(() => {
    if (!_.isEmpty(props.customVariables)) {
      setTabMenu([
        {
          name: "File Upload",
          keyname: "file",
        },
        ..._tabMenu,
      ]);
      setKey("file");
      setCustomKeyNames(props.customVariables);
    }
  }, [props.customVariables]);

  const addVariable = (item, key, fieldGroup) => {
    if (fieldGroup === "CUSTOM") {
      setSelectedItem(`_CF_${item}`);
      setSelectedKey(key);
    }
    else {
      setSelectedItem(item);
      setSelectedKey(key);
    }

  };

  const handleFallbackTextEdit = (key, item, e, fieldGroup) => {
    if (key === "file") {
      props.setDefaultVariableValues({
        ...props.defaultVariableValues,
        [`${item}`]: e.target.value,
      });
    } else {
      if (fieldGroup === "CUSTOM") {
        props.setDefaultVariableValues({
          ...props.defaultVariableValues,
          [`${key}._CF_${item}`]: e.target.value,
        });
      } else {
        props.setDefaultVariableValues({
          ...props.defaultVariableValues,
          [`${key}.${item}`]: e.target.value,
        });
      }
    }
  };

  const handleSave = () => {
    if (!selectedItem || !selectedKey) {
      return dispatchSnackbarError("Please Select Variable");
    }
    if (selectedKey === "file") {
      props.addVariable(selectedItem, selectedKey, `{{${selectedItem}}}`);
    } else {
      props.addVariable(selectedItem, selectedKey, `{{${selectedKey}.${selectedItem}}}`);
    }
    handleCancel();
  };

  const handleCancel = () => {
    props.setShow(false);
    setSelectedItem("");
    setSelectedKey("");
  };

  const AddCadenceModalProps = {
    showButtons: true,
    show: props.show,
    title: "Insert Field Variables",
    loading: false,
    size: "lg",
    buttonText: "Insert",
    handleClose: handleCancel,
    handleAccept: handleSave,
  };

  return (
    <CustomModal modalProps={AddCadenceModalProps}>
      <Form.Group>
        <Form.Label>
          {`The field variables will substitute the actual value. If it is not available, then it will be replaced by the fallback text. For instance, if the message is Hi {Name} and the fallback text is "Sir" then the final text will be "Hi Sir". In the event that neither of these is available, the variable will be left blank.`}
        </Form.Label>
      </Form.Group>
      <Tab.Container defaultActiveKey={key}>
        <Nav as="ul" className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 justify-content-center px-1" onSelect={(_key) => setKey(_key)}>
          {tabMenu.map((each) => {
            return (
              <Nav.Item style={{ width: `${100 / tabMenu.length}%` }} className="nav-item" as="li">
                <Nav.Link eventKey={each.keyname} className={`nav-link show justify-content-center m-0 ${key === each.keyname ? "active" : ""}`}>
                  {each.name}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>

        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="marketing">
            <div className="overflow-y-auto h-420px-mx">
              {marketingColumns
                .map((item, index) => {
                  return (
                    <div
                      className={`dropdown-hover d-flex flex-stack p-4 ${(selectedItem === item.apiKeyName || selectedItem === `_CF_${item.apiKeyName}`) && selectedKey === "marketing" ? "bg-light-primary" : ""}`}
                      onClick={() => addVariable(item.apiKeyName, "marketing", item.fieldGroup)}
                      key={index}
                    >
                      {item.fieldName}
                      <Form.Control
                        className="w-50"
                        type="text"
                        placeholder="+ Fallback Text"
                        value={props.defaultVariableValues[`marketing.${item.apiKeyName}`]}
                        onChange={(e) => handleFallbackTextEdit("marketing", item.apiKeyName, e, item.fieldGroup)}
                      />
                    </div>
                  );
                })}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="lead">
            <div className="overflow-y-auto h-420px-mx">
              {leadColumns
                .map((item, index) => {
                  return (
                    <div
                      className={`dropdown-hover d-flex flex-stack p-4 ${(selectedItem === item.apiKeyName || selectedItem === `_CF_${item.apiKeyName}`) && selectedKey === "lead" ? "bg-light-primary" : ""}`}
                      onClick={() => addVariable(item.apiKeyName, "lead", item.fieldGroup)}
                      key={index}
                    >
                      {item.fieldName}
                      <Form.Control
                        className="w-50"
                        type="text"
                        placeholder="+ Fallback Text"
                        value={props.defaultVariableValues[`lead.${item.apiKeyName}`]}
                        onChange={(e) => handleFallbackTextEdit("lead", item.apiKeyName, e, item.fieldGroup)}
                      />
                    </div>
                  );
                })}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="people">
            <div className="overflow-y-auto h-420px-mx">
              {peopleColumns
                .map((item, index) => (
                  <div
                    className={`dropdown-hover d-flex flex-stack p-4 ${(selectedItem === item.apiKeyName || selectedItem === `_CF_${item.apiKeyName}`) && selectedKey === "lead" ? "bg-light-primary" : ""}`}
                    onClick={() => addVariable(item.apiKeyName, "people", item.fieldGroup)}
                    key={index}
                  >
                    {item.fieldName}
                    <Form.Control
                      className="w-50"
                      type="text"
                      placeholder="+ Fallback Text"
                      value={props.defaultVariableValues[`people.${item.apiKeyName}`]}
                      onChange={(e) => handleFallbackTextEdit("people", item.apiKeyName, e, item.fieldGroup)}
                    />
                  </div>
                ))}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="company">
            <div className="overflow-y-auto h-420px-mx">
              {companyColumns
                .map((item, index) => (
                  <div
                    className={`dropdown-hover d-flex flex-stack p-4 ${(selectedItem === item.apiKeyName || selectedItem === `_CF_${item.apiKeyName}`) && selectedKey === "lead" ? "bg-light-primary" : ""}`}
                    onClick={() => addVariable(item.apiKeyName, "company", item.fieldGroup)}
                    key={index}
                  >
                    {item.fieldName}
                    <Form.Control
                      className="w-50"
                      type="text"
                      placeholder="+ Fallback Text"
                      value={props.defaultVariableValues[`company.${item.apiKeyName}`]}
                      onChange={(e) => handleFallbackTextEdit("company", item.apiKeyName, e, item.fieldGroup)}
                    />
                  </div>
                ))}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="user">
            <div className="overflow-y-auto h-420px-mx">
              {userColumns.map((item, index) => (
                <div
                  className={`dropdown-hover d-flex flex-stack p-4 ${(selectedItem === item.apiKeyName || selectedItem === `_CF_${item.apiKeyName}`) && selectedKey === "lead" ? "bg-light-primary" : ""}`}
                  onClick={() => addVariable(item.apiKeyName, "user", item.fieldGroup)}
                  key={index}
                >
                  {item.fieldName}
                  <Form.Control
                    className="w-50"
                    type="text"
                    placeholder="+ Fallback Text"
                    value={props.defaultVariableValues[`user.${item.apiKeyName}`]}
                    onChange={(e) => handleFallbackTextEdit("user", item.apiKeyName, e, item.fieldGroup)}
                  />
                </div>
              ))}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="file">
            <div className="overflow-y-auto h-420px-mx">
              {customKeyNames.map((item, index) => (
                <div className={`dropdown-hover d-flex flex-stack p-4 ${selectedItem === item && selectedKey === "file" ? "bg-light-primary" : ""}`} onClick={() => addVariable(item, "file", "")} key={index}>
                  {item}
                  <Form.Control className="w-50" type="text" placeholder="+ Fallback Text" value={props.defaultVariableValues[`${item}`]} onChange={(e) => handleFallbackTextEdit("user", item, e, item.fieldGroup)} />
                </div>
              ))}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </CustomModal>
  );
}
