import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import SyncSettingsList from "./containers/sync-settings-list";
import SyncSettingsOverview from "./containers/sync-settings-overview";
import SyncSettingsCallback from "./containers/sync-settings-callback";

export default function SyncSettingsRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={SyncSettingsList} />
      <Route exact path={`${match.url}/oauth2callback`} component={SyncSettingsCallback} />
      <Route exact path={`${match.url}/:syncId/edit`} component={SyncSettingsOverview} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}
