import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Nav, Tab, Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { InputLimit } from "../../constants";
import "./style.css";
import InlineStyled from "../inline-styled/inline-styled";

export function ColumnFilter(props) {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [ignore, setIgnore] = useState(false);
  const [key, setKey] = useState("Activity");

  // Check or Uncheck column
  const editColumns = (colId) => {
    if (!props.showColumns.includes(colId)) {
      props.showHideField({ id: colId, isTableFieldEnable: true, fieldType: props.fieldType });
      props.setShowColumns([...props.showColumns, colId]);
    } else {
      props.showHideField({ id: colId, isTableFieldEnable: false, fieldType: props.fieldType });
      props.setShowColumns(props.showColumns.filter((col) => col !== colId));
    }
  };

  const editColumnsNew = (colId) => {
    if (!props.showColumns.includes(colId)) {
      props.showHideField({ id: colId, isTableFieldEnable: true, fieldType: props.fieldType });
      props.setShowColumns([...props.showColumns, colId]);
    } else {
      props.showHideField({ id: colId, isTableFieldEnable: false, fieldType: props.fieldType });
      props.setShowColumns(props.showColumns.filter((col) => col !== colId));
    }
  };

  // Reorder Array
  const handleDragEnd = (param) => {
    if (!param.destination) return;
    props.updateFieldPriority({ id: props.showColumns[param.source.index], priority: param.destination.index + 1 });
    const items = Array.from(props.showColumns);
    const [reorderedItem] = items.splice(param.source.index, 1);
    items.splice(param.destination.index, 0, reorderedItem);
    props.setShowColumns(items);
  };

  // Fix rootclose behavior when toggle is clicked
  const handleToggle = (n, b, e) => {
    if (e.source === "click" && ignore) {
      setShow(false);
      setIgnore(false);
    } else {
      setShow(!show);
    }
  };

  return (
    <Dropdown show={show} onToggle={handleToggle} className="dropdown-inline" drop="left">
      <Dropdown.Toggle
        onMouseDown={(e) => {
          if (show) setIgnore(true);
        }}
        className="btn  btn-sm font-weight-bolder dropdown-toggle py-8px pr-8px pl-3px"
        variant="transparent"
        id="dropdown-toggle-top"
      >
        <OverlayTrigger overlay={<Tooltip>Table View</Tooltip>}>
          <i className="bi bi-view-stacked"></i>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent="mousedown" className="dropdown-menu dropdown-menu-md w-320px overflow-auto" id="preventTranslate">
        <div className="scroll navi navi-hover h-500px-mx position-relative overflow-y-auto">
          <ul className="navi navi-hover hiddenScroll">
            <li className="navi-header pb-1">
              <span className="tshowColumnsext-primary text-uppercase font-weight-bold font-size-sm">Columns</span>
            </li>
            <li className="navi-item py-12px px-24px">
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Column" value={search} onChange={(e) => setSearch(e.target.value)} />
            </li>
            <Dropdown.Divider />
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable-1">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {props.showColumns.map((colId, index) => {
                      return props.columns[props.idToIndex[colId]].fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                        <Draggable isDragDisabled={search === "" ? false : true} key={index} draggableId={"draggable-" + index} index={index}>
                          {(provided, snapshot) => {
                            return (
                              <li
                                key={index}
                                className={`dropdown-hover dropdown-item bg-white ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{ ...provided.draggableProps.style }}
                              >
                                <div className="navi-link m-0">
                                  <div className="d-flex flex-row flex-stack w-100">
                                    <span className="navi-text flex-grow-1" onClick={() => editColumns(colId)}>
                                      <label className="checkbox checkbox-md">
                                        <input type="checkbox" disabled checked={true} />
                                        <span className="mr-5px"></span>
                                        {props.columns[props.columns.findIndex((item) => item["_id"] === colId)].fieldName}
                                      </label>
                                    </span>
                                    {search === "" ? (
                                      <div className="d-flex align-items-center">
                                        {props.showTabs ? (
                                          <InlineStyled as="p" className="mb-0 mr-12px font-size-11px text-darkgray" styles={{ fontFamily: "Poppins" }}>
                                            {" "}
                                            {props.columns[props.columns.findIndex((item) => item["_id"] === colId)].fieldType}
                                          </InlineStyled>
                                        ) : null}
                                        <i className="fa fa-bars fa-md" aria-hidden="true" {...provided.dragHandleProps}></i>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </li>
                            );
                          }}
                        </Draggable>
                      ) : null;
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Dropdown.Divider />
            {props.showTabs ? (
              <div>
                <Tab.Container defaultActiveKey={key}>
                  <Nav as="ul" className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 justify-content-center px-1" onSelect={(_key) => setKey(_key)}>
                    <Nav.Item className="nav-item w-20" as="li">
                      <Nav.Link eventKey="Activity" className={`nav-link show justify-content-center m-0 ${key === "Activity" ? "active" : ""}`}>
                        Activity
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item w-20" as="li">
                      <Nav.Link eventKey="Deal" className={`nav-link show justify-content-center m-0 ${key === "Deal" ? "active" : ""}`}>
                        Deal
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item w-20" as="li">
                      <Nav.Link eventKey="Person" className={`nav-link show justify-content-center m-0 ${key === "Person" ? "active" : ""}`}>
                        Person
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item w-20" as="li">
                      <Nav.Link eventKey="Lead" className={`nav-link show justify-content-center m-0 ${key === "Lead" ? "active" : ""}`}>
                        Lead
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item w-20" as="li">
                      <Nav.Link eventKey="Company" className={`nav-link show justify-content-center m-0 ${key === "Company" ? "active" : ""}`}>
                        Company
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Person">
                      {props.columns.map((col, index) => {
                        return props.personShowColumns.includes(col["_id"]) && col.fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                          <li key={index} className="dropdown-hover dropdown-item">
                            <p className="navi-link m-0" onClick={() => editColumnsNew(col["_id"])}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-md">
                                  <input type="checkbox" disabled checked={props.showColumns.includes(col["_id"])} />
                                  <span className="mr-5px"></span>
                                  {col.fieldName}
                                </label>
                              </span>
                            </p>
                          </li>
                        ) : null;
                      })}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Lead">
                      {props.columns.map((col, index) => {
                        return props.leadShowColumns.includes(col["_id"]) && col.fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                          <li key={index} className="dropdown-hover dropdown-item">
                            <p className="navi-link m-0" onClick={() => editColumnsNew(col["_id"])}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-md">
                                  <input type="checkbox" disabled checked={props.showColumns.includes(col["_id"])} />
                                  <span className="mr-5px"></span>
                                  {col.fieldName}
                                </label>
                              </span>
                            </p>
                          </li>
                        ) : null;
                      })}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Deal">
                      {props.columns.map((col, index) => {
                        return props.dealShowColumns.includes(col["_id"]) && col.fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                          <li key={index} className="dropdown-hover dropdown-item">
                            <p className="navi-link m-0" onClick={() => editColumnsNew(col["_id"])}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-md">
                                  <input type="checkbox" disabled checked={props.showColumns.includes(col["_id"])} />
                                  <span className="mr-5px"></span>
                                  {col.fieldName}
                                </label>
                              </span>
                            </p>
                          </li>
                        ) : null;
                      })}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Activity">
                      {props.columns.map((col, index) => {
                        return props.activityShowColumns.includes(col["_id"]) && col.fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                          <li key={index} className="dropdown-hover dropdown-item">
                            <p className="navi-link m-0" onClick={() => editColumnsNew(col["_id"])}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-md">
                                  <input type="checkbox" disabled checked={props.showColumns.includes(col["_id"])} />
                                  <span className="mr-5px"></span>
                                  {col.fieldName}
                                </label>
                              </span>
                            </p>
                          </li>
                        ) : null;
                      })}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Company">
                      {props.columns.map((col, index) => {
                        return props.companyShowColumns.includes(col["_id"]) && col.fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                          <li key={index} className="dropdown-hover dropdown-item">
                            <p className="navi-link m-0" onClick={() => editColumnsNew(col["_id"])}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-md">
                                  <input type="checkbox" disabled checked={props.showColumns.includes(col["_id"])} />
                                  <span className="mr-5px"></span>
                                  {col.fieldName}
                                </label>
                              </span>
                            </p>
                          </li>
                        ) : null;
                      })}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            ) : (
              <div>
                {props.columns.map((col, index) => {
                  return !props.showColumns.includes(col["_id"]) && col.fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                    <li key={index} className="dropdown-hover dropdown-item">
                      <p className="navi-link m-0" onClick={() => editColumns(col["_id"])}>
                        <span className="navi-text flex-grow-1">
                          <label className="checkbox checkbox-md">
                            <input type="checkbox" disabled checked={props.showColumns.includes(col["_id"])} />
                            <span className="mr-5px"></span>
                            {col.fieldName}
                          </label>
                        </span>
                      </p>
                    </li>
                  ) : null;
                })}
              </div>
            )}
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
