import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SketchPicker } from "react-color";
import InlineStyled from "../inline-styled/inline-styled";

const presetColors = [
  { color: "#f44336", title: "#f44336" },
  { color: "#e91e63", title: "#e91e63" },
  { color: "#9c27b0", title: "#9c27b0" },
  { color: "#673ab7", title: "#673ab7" },
  { color: "#3f51b5", title: "#3f51b5" },
  { color: "#2196f3", title: "#2196f3" },
  { color: "#03a9f4", title: "#03a9f4" },
  { color: "#00bcd4", title: "#00bcd4" },
  { color: "#009688", title: "#009688" },
  { color: "#4caf50", title: "#4caf50" },
  { color: "#8bc34a", title: "#8bc34a" },
  { color: "#cddc39", title: "#cddc39" },
  { color: "#ffeb3b", title: "#ffeb3b" },
  { color: "#ffc107", title: "#ffc107" },
  { color: "#ff9800", title: "#ff9800" },
  { color: "#ff5722", title: "#ff5722" },
  { color: "#795548", title: "#795548" },
  { color: "#607d8b", title: "#607d8b" },
  { color: "#f6a6c2", title: "#f6a6c2" },
  { color: "#ffe786", title: "#ffe786" },
  { color: "#99e8a7", title: "#99e8a7" },
  { color: "#8babff", title: "#8babff" },
  { color: "#cc8aff", title: "#cc8aff" },
  { color: "#E0FFE5", title: "#E0FFE5" },
  { color: "#DDF8FF", title: "#DDF8FF" },
  { color: "#ECE8FF", title: "#ECE8FF" },
  { color: "#FFE7DC", title: "#FFE7DC" },
  { color: "#FFEED3", title: "#FFEED3" },
  { color: "#E5EFFF", title: "#E5EFFF" },
  { color: "#FFDFFF", title: "#FFDFFF" },
  { color: "#9898FA", title: "#9898FA" },
  { color: "#CCD8FF", title: "#CCD8FF" },
  { color: "#FECCE6", title: "#FECCE6" },
  { color: "#D2FBF0", title: "#D2FBF0" },
  { color: "#FFCCB0", title: "#FFCCB0" },
  { color: "#FFE2CC", title: "#FFE2CC" },
  { color: "#FDD4D4", title: "#FDD4D4" },
  { color: "#F7E2FE", title: "#F7E2FE" },
  { color: "#C9F8B4", title: "#C9F8B4" },
  { color: "#FF8D7D", title: "#FF8D7D" },
  { color: "#9595FF", title: "#9595FF" },
  { color: "#99FF93", title: "#99FF93" },
  { color: "#FFE066", title: "#FFE06" },
];
export default function SketchColorPicker({ color, setColor }) {
  const handleChange = (color) => {
    setColor(color.hex);
  };

  const popoverForm = () => {
    return (
      <Popover id="popoverColorbutton" onClick={(e) => e.stopPropagation()}>
        <SketchPicker color={color} presetColors={presetColors} onChange={handleChange} />
      </Popover>
    );
  };
  return (
    <div className="w-100">
      <OverlayTrigger rootClose={true} onExit={() => {}} trigger="click" id="popoverColorbutton" placement="auto" overlay={popoverForm()}>
        <div className="d-inline-block w-100 rounded bg-whiite cursor-pointer p-10px box-shadow-2">
          <InlineStyled className="w-100 h-20px" styles={{ background: color }} />
        </div>
      </OverlayTrigger>
    </div>
  );
}
