import React, { useState } from "react";
import { Tab } from "react-bootstrap";

import SMSSenderIdContainer from "./sender-id";

import { SidebarTabs } from "../../../../../../components/side-pannel-detailed/custom-components/tabs";

export default function CampaignEmailSettingsRoutes(props) {
  const [selectedTab, setSelectedTab] = useState("Sender ID");
  const [categoryList] = useState(["Sender ID"]);

  return (
    <div>
      <Tab.Container defaultActiveKey={selectedTab}>
        <SidebarTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={categoryList} />
        <div>{selectedTab === "Sender ID" && <SMSSenderIdContainer />}</div>
      </Tab.Container>
    </div>
  );
}
