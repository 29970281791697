import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CampaignReports from "./index";

export default function CampaignReportRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        {/* <Route exact path={`${match.url}`} component={CampaignReports} /> */}
        <Route exact path={`${match.url}/:reportType`} component={CampaignReports} />
        <Redirect to={`${match.url}/summary-report`} />
      </Switch>
    </>
  );
}
