import _ from "lodash";

export const getStepEditedFormData = (payload) => {
  let _payload = _.pick(payload, [
    "name",
    "channelType",
    "isEnabled",
    "emailContent",
    "emailContent.emailSendType",
    "isAbTestingEnabled",
    "delay",
    "activityContent",
    "smsContent",
    "whatsappContent",
    "abTesting",
    "abTesting.condition",
    "abTesting.isAutomate",
    "abTesting.emailContent",
  ]);

  _payload = _.omit(_payload, ["abTesting.isFinished"]);

  if (_payload["abTesting"] && _payload["abTesting"].templates) {
    _payload.abTesting.templates = _payload.abTesting.templates.map((each) => ({ template: each.template }));
  }

  return _payload;
};
export const getStepAddFormData = (payload) => {
  const _payload = _.pick(payload, [
    "name",
    "isEnabled",
    "emailContent",
    "emailContent.emailSendType",
    "executionOrder",
    "channelType",
    "isAbTestingEnabled",
    "delay",
    "activityContent",
    "smsContent",
    "whatsappContent",
    "abTesting.condition",
    "abTesting.isAutomate",
    "abTesting.emailContent",
  ]);

  if (_payload["abTesting"] && _payload["abTesting"].templates) {
    _payload.abTesting.templates = _payload.abTesting.templates.map((each) => ({ template: each.template }));
  }

  return _payload;
};
