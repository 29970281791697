import { getCurrency, patchCurrency, currency } from "../../types/admin-settings.types";

export function getCurrencyRequest(cb) {
  return {
    type: getCurrency.GET_CURRENCY_REQUEST,
    cb,
  };
}
export function getCurrencySuccess(res) {
  return {
    type: getCurrency.GET_CURRENCY_SUCCESS,
    payload: res,
  };
}
export function getCurrencyError(err) {
  return {
    type: getCurrency.GET_CURRENCY_ERROR,
    payload: err,
  };
}

export function patchCurrencyRequest(req, paramId, cb) {
  return {
    type: patchCurrency.PATCH_CURRENCY_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}
export function patchCurrencySuccess(res) {
  return {
    type: patchCurrency.PATCH_CURRENCY_SUCCESS,
    payload: res,
  };
}
export function patchCurrencyError(err) {
  return {
    type: patchCurrency.PATCH_CURRENCY_ERROR,
    payload: err,
  };
}

export function currencyRequest(req) {
  return {
    type: currency.CURRENCY_REQUEST,
    payload: req,
  };
}
export function currencySuccess(res) {
  return {
    type: currency.CURRENCY_SUCCESS,
    payload: res,
  };
}
export function currencyError(err) {
  return {
    type: currency.CURRENCY_ERROR,
    payload: err,
  };
}
