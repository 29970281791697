import {
  EDIT_SYSTEM_FIELDS_REQUEST,
  EDIT_SYSTEM_FIELDS_SUCCESS,
  EDIT_SYSTEM_FIELDS_ERROR,
  GET_ADD_FORM_FIELDS_REQUEST,
  GET_ADD_FORM_FIELDS_SUCCESS,
  GET_ADD_FORM_FIELDS_ERROR,
  REORDER_ADD_FORM_FIELDS_REQUEST,
  REORDER_ADD_FORM_FIELDS_SUCCESS,
  REORDER_ADD_FORM_FIELDS_ERROR,
} from "../types/custom-fields.types";

const initialState = {
  addForm: {
    list: [],
    loading: false,
    error: "",
  },
};

export function customFieldReducer(state = initialState, action = {}) {
  switch (action.type) {
    case EDIT_SYSTEM_FIELDS_REQUEST:
      return {
        ...state,
      };
    case EDIT_SYSTEM_FIELDS_SUCCESS:
      return {
        ...state,
      };
    case EDIT_SYSTEM_FIELDS_ERROR:
      return {
        ...state,
      };
    case GET_ADD_FORM_FIELDS_REQUEST:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: true,
        },
      };
    case GET_ADD_FORM_FIELDS_SUCCESS:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: false,
          list: action.payload.data,
        },
      };
    case GET_ADD_FORM_FIELDS_ERROR:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: false,
          error: action.payload.message,
        },
      };
    case REORDER_ADD_FORM_FIELDS_REQUEST:
      return {
        ...state,
      };
    case REORDER_ADD_FORM_FIELDS_SUCCESS:
      return {
        ...state,
      };
    case REORDER_ADD_FORM_FIELDS_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
}
