import React from "react";
import { CustomSubHeader } from "../../components/subheader";
import ContentWrapper from "../Wrapper";
import { SubheaderNav } from "../../components/subheader/subheaderNav";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { CadenceSVG, WorkflowSVG } from "../../components/custom-svgs/sub-header-svg";

export default function ComingSoon() {
  const tabName = "Workflow Automation";
  const links = [
    { name: "Sales Cadence", to: "/automation/cadence/list", svg: CadenceSVG },
    { name: "Workflow Automation", to: "/automation/workflow/list", svg: WorkflowSVG },
  ];

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <SubheaderNav links={links} active={tabName} />
        </CustomSubHeader>
      }
    >
      <CustomEmptyPage page="no-access-workflow" />
    </ContentWrapper>
  );
}
