import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import TitleHead from "./title-head";
import { Spinner } from "react-bootstrap";

//left pannels
import { LeadLeftPannel, CompanyLeftPannel, DealLeftPannel, PeopleLeftPannel } from "./left-pannel";
import { MarketingLeftPannel } from "./left-pannel/marketing.left-pannel";

export default function ModuleDetails(props) {
  const [title, setTitle] = useState("");
  const [sidePanelType, setSidePanelType] = useState("");

  const dealSidepanelStore = useSelector((store) => store.DealPipeline.dealPopup);
  const leadPopupStore = useSelector((state) => state.Leads.leadPopup);
  const companyPopupStore = useSelector((state) => state.Companies.companyPopup);
  const peoplePopupStore = useSelector((state) => state.People.peoplePopup);
  const marketingDataPopupStore = useSelector((store) => store.campaign.marketing.marketingList.popup);

  useEffect(() => {
    if (!_.isEmpty(leadPopupStore.data.data) && props.moduleType === "lead") {
      setSidePanelType(leadPopupStore.data.data.state);
      setTitle(leadPopupStore.data.data?.name);
    } else if (!_.isEmpty(dealSidepanelStore.data) && props.moduleType === "deal") {
      setSidePanelType("deal");
      setTitle(dealSidepanelStore.data.deal.title);
    } else if (!_.isEmpty(peoplePopupStore.data) && props.moduleType === "people") {
      setSidePanelType("people");
      setTitle(peoplePopupStore.data.data?.name);
    } else if (!_.isEmpty(companyPopupStore.data) && props.moduleType === "company") {
      setSidePanelType("company");
      setTitle(companyPopupStore.data.data?.name);
    } else if (!_.isEmpty(marketingDataPopupStore.data) && props.moduleType === "marketing") {
      setSidePanelType("marketing");
      let name = "";
      if (marketingDataPopupStore.data.firstName) {
        name = marketingDataPopupStore.data.firstName;
      }
      if (marketingDataPopupStore.data.lastName) {
        if (name) {
          name = name + " " + marketingDataPopupStore.data.lastName;
        } else {
          name = marketingDataPopupStore.data.lastName;
        }
      }
      setTitle(name);
    }
  }, [props.moduleType, dealSidepanelStore.data, leadPopupStore.data.data, companyPopupStore.data, marketingDataPopupStore.data, peoplePopupStore.data]);

  const convertToJunk = () => {
    if (props.convertToJunk) props.convertToJunk();
  };
  const convertToDeal = () => {
    if (props.convertToDeal) props.convertToDeal();
  };
  const convertToLead = () => {
    if (props.convertToLead) props.convertToLead();
  };
  const convertToActive = () => {
    if (props.convertToActive) props.convertToActive();
  };
  const handleDelete = () => {
    if (props.handleDelete) props.handleDelete();
  };
  const handleArchiveDeal = () => {
    if (props.handleArchiveDeal) props.handleArchiveDeal();
  };
  const handleConvertToPeople = () => {
    if (props.handleConvertToPeople) props.handleConvertToPeople();
  };

  if (leadPopupStore.loading || companyPopupStore.loading || dealSidepanelStore.loading || peoplePopupStore.loading || marketingDataPopupStore.loading) {
    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <>
      <TitleHead
        moduleType={props.moduleType}
        pageForm={props.pageForm}
        title={title}
        basePath={props.basePath}
        sidePanelType={sidePanelType}
        loading={dealSidepanelStore.loading || leadPopupStore.loading || companyPopupStore.loading || peoplePopupStore.loading}
        sidePanelProps={dealSidepanelStore.data}
        hideMenu={props.hideMenu}
        convertToJunk={convertToJunk}
        convertToDeal={convertToDeal}
        convertToLead={convertToLead}
        convertToActive={convertToActive}
        handleDelete={handleDelete}
        handleArchiveDeal={handleArchiveDeal}
        handleConvertToPeople={handleConvertToPeople}
      />
      <div className="px-8 pb-8 m-0 position-relative">
        {props.moduleType === "lead" && (
          <LeadLeftPannel
            page={"Leads"}
            pageSub={"lead"}
            basePath={props.basePath}
            leadId={props.leadId}
            moduleType={props.moduleType}
            pageForm={props.pageForm}
            sidePanelProps={leadPopupStore.data}
            popupLoading={leadPopupStore.loading}
            popupCellLoading={leadPopupStore.cellLoading}
          />
        )}
        {props.moduleType === "company" && (
          <CompanyLeftPannel
            page={"Companies"}
            pageSub={"company"}
            basePath={props.basePath}
            companyId={props.companyId}
            moduleType={props.moduleType}
            pageForm={props.pageForm}
            sidePanelProps={companyPopupStore.data}
            popupLoading={companyPopupStore.loading}
            popupCellLoading={companyPopupStore.cellLoading}
          />
        )}
        {props.moduleType === "deal" && (
          <DealLeftPannel
            page={"Companies"}
            pageSub={"company"}
            basePath={props.basePath}
            dealId={props.dealId}
            moduleType={props.moduleType}
            pageForm={props.pageForm}
            sidePanelProps={dealSidepanelStore.data}
            popupLoading={dealSidepanelStore.loading}
            popupCellLoading={dealSidepanelStore.cellLoading}
          />
        )}
        {props.moduleType === "people" && (
          <PeopleLeftPannel
            page={"People"}
            pageSub={"people"}
            basePath={props.basePath}
            peopleId={props.peopleId}
            moduleType={props.moduleType}
            pageForm={props.pageForm}
            sidePanelProps={peoplePopupStore.data}
            popupLoading={peoplePopupStore.loading}
            popupCellLoading={peoplePopupStore.cellLoading}
          />
        )}
        {props.moduleType === "marketing" && (
          <MarketingLeftPannel
            page={"marketing"}
            pageSub={"marketing"}
            basePath={props.basePath}
            leadId={props.leadId}
            moduleType={props.moduleType}
            pageForm={props.pageForm}
            sidePanelProps={marketingDataPopupStore.data}
            popupLoading={marketingDataPopupStore.loading}
            popupCellLoading={marketingDataPopupStore.cellLoading}
          />
        )}
      </div>
    </>
  );
}
