import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCadenceListRequest } from "../../containers/Automation/cadence/store/actions/cadence_actions";
import SimpleDropdown from "../dropdowns/simple-dropdown";
import { CustomModal } from "../modal";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { addBulkLeadToCadenceRequest, bulkSubscribeToCadenceByQueryRequest } from "../../containers/Automation/cadence/store/actions/cadence_lead_actions";
import RadioGroupSelect from "../custom_form/radio";

export default function SubscribeToCadenceModal({ show, setShow, moduleName, moduleIds, onSuccess, isAllSelected, loadingStore }) {
    const [options, setOptions] = useState([]);
    const [selectedCadence, setSelectedCadence] = useState({});
    // conditions
    const [typeOfCondition, setTypeOfCondition] = useState("Only add new subscribers; ignore those who have already subscribed and finished.");
    const conditionOptions = [
        "Only add new subscribers; ignore those who have already subscribed and finished.",
        "If a subscriber is new, add them to the cadence. If a subscriber has already finished, restart the cadence for them.",
        "Restart the cadence only for subscribers who have already finished; ignore new contacts.",
    ];
    const dispatch = useDispatch();

    const cadenceList = useSelector((store) => store.cadence.list);

    useEffect(() => {
        dispatch(getCadenceListRequest({ limit: 100, page: 1, searchText: "" }));
    }, [dispatch]);

    useEffect(() => {
        if (cadenceList.data) {
            const _options = cadenceList.data.map((each) => ({ value: each._id, label: each.name }));
            setOptions(_options);
        }
    }, [cadenceList.data]);

    const onCancelAddCadence = (e) => {
        e.stopPropagation();
        setShow(false);
    };

    const getConditionType = () => {
        if (typeOfCondition === conditionOptions[0]) {
            return "only_new_subscribers";
        } else if (typeOfCondition === conditionOptions[1]) {
            return "any_subscriber";
        } else if (typeOfCondition === conditionOptions[2]) {
            return "only_finished_subscribers";
        } else return "none";
    };

    const handleSaveClick = (e) => {
        e.stopPropagation();
        if (_.isEmpty(selectedCadence)) {
            return dispatchSnackbarError("Please select cadence!");
        }
        if (!typeOfCondition) {
            return dispatchSnackbarError("Please select a condition type");
        }

        if (isAllSelected) {
            const leadType = _.upperCase(moduleName);
            const payload = {
                moduleName: leadType,
                cadenceId: selectedCadence.value,
                filterId: loadingStore.filterId || "",
                tags: loadingStore.tagId && loadingStore.tagId.length > 0 ? loadingStore.tagId[0] : "",
                filter: [],
                conditionType: getConditionType(),
            };
            if (loadingStore.filter) {
                try {
                    const filter = JSON.parse(loadingStore.filter);
                    if (filter.andMatchConditions) {
                        payload.filter = filter.andMatchConditions;
                    }
                } catch (err) {
                    console.log(err);
                    return dispatchSnackbarError("Error in filter");
                }
            }
            dispatch(
                bulkSubscribeToCadenceByQueryRequest(payload, (data) => {
                    if (data.status) {
                        dispatchSnackbarSuccess(data.message);
                    } else {
                        dispatchSnackbarError(data.message);
                    }
                    setShow(false);
                    onSuccess();
                }),
            );
        } else {
            if (_.isEmpty(moduleIds)) {
                return dispatchSnackbarError("Please select at least one lead!");
            }
            const leadType = _.upperCase(moduleName);
            if (moduleIds && leadType && selectedCadence) {
                const payload = {
                    moduleName: leadType,
                    moduleIds: moduleIds,
                    cadenceId: selectedCadence.value,
                    conditionType: getConditionType(),
                };
                dispatch(
                    addBulkLeadToCadenceRequest(payload, (data) => {
                        if (data.status) {
                            dispatchSnackbarSuccess(data.message);
                        } else {
                            dispatchSnackbarError(data.message);
                        }
                        setShow(false);
                        onSuccess();
                    }),
                );
            }
        }
    };
    const AddCadenceModalProps = {
        showButtons: true,
        show: show,
        title: "Subscribe To Cadence",
        loading: false,
        buttonText: "Subscribe To Cadence",
        handleClose: onCancelAddCadence,
        handleAccept: handleSaveClick,
    };
    return (
        <CustomModal modalProps={AddCadenceModalProps}>
            <Form.Group id="sequence-component-add-cadence-form-cadence-name">
                <Form.Label aria-label="cadence">Cadence</Form.Label>
                <SimpleDropdown showSearch={true} value={selectedCadence} label={"Select Cadence"} options={options} handleSelect={setSelectedCadence} />
            </Form.Group>
            <Form.Group className1="card p-5 mt-5 mb-0">
                <Form.Label>Conditions for bulk Operations</Form.Label>
                <RadioGroupSelect apiKeyName={"condition"} setValue={(value, apiKyName) => setTypeOfCondition(value)} value={typeOfCondition} options={conditionOptions} isRow={false} />
            </Form.Group>
        </CustomModal>
    );
}
