import { getListOfSyncAccounts, getSyncCalendarList } from "../../types/profile-settings/sync-settings.types";

const initState = {
  loading: false,
  data: [],
  calendarListLoading: false,
  calenderList: [],
};

export default function syncSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfSyncAccounts.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getListOfSyncAccounts.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case getListOfSyncAccounts.ERROR:
      return {
        ...state,
        loading: false,
      };
    case getSyncCalendarList.REQUEST:
      return {
        ...state,
        calendarListLoading: true,
      };
    case getSyncCalendarList.SUCCESS:
      return {
        ...state,
        calendarListLoading: false,
        calenderList: action.payload.data,
      };

    case getSyncCalendarList.ERROR:
      return {
        ...state,
        calendarListLoading: false,
      };
    default:
      return state;
  }
}
