import React from "react";
// import InitialLogo from "../../../../../images/dashboard/initial.png";
import "./styles.scss";

export default function AuthWrapper(props) {
  return (
    <div className="auth-wrapper">
      <div class="body-full-height">
        <div className="wrap">
          <div class="signup-section wf-section">
            <div class="signup-row">
              <div class="signup-col-form">
                <div class="signup-col-form-container">
                  <a href="https://www.salessimplify.com" class="form-logo w-inline-block">
                    <img src="https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6339977b1372650a6d07c9e6_Logo%20Rectangle%20black.svg" loading="lazy" width="130" alt="" />
                  </a>
                  <div class="signup-form">{props.children}</div>
                </div>
              </div>
              <div class="signup-col-promo">
                <div class="signup-col-promo-container">
                  <div>
                    <img
                      src="https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6346b5b3583ce31cb331e5a8_salessimplify%20dashboard.png"
                      loading="lazy"
                      height=""
                      srcset="https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6346b5b3583ce31cb331e5a8_salessimplify%20dashboard-p-500.png 500w, https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6346b5b3583ce31cb331e5a8_salessimplify%20dashboard-p-800.png 800w, https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6346b5b3583ce31cb331e5a8_salessimplify%20dashboard-p-1080.png 1080w, https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6346b5b3583ce31cb331e5a8_salessimplify%20dashboard-p-1600.png 1600w, https://uploads-ssl.webflow.com/6324b29bbb8724bf504d88d2/6346b5b3583ce31cb331e5a8_salessimplify%20dashboard.png 2375w"
                      sizes="(max-width: 767px) 100vw, (max-width: 991px) 56vw, 52vw"
                      alt=""
                      class="mb-60"
                    />
                    <h6 class="text-white mb-20">Loved by people all over the world :)</h6>
                    <div>
                      <div class="text-light-grey-2">Sales are now fun—not just for the salesperson, but for everyone.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
