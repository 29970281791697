import React from "react";

export const IndiaSVG = (
  <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16.5C0 17.2956 0.316071 18.0587 0.87868 18.6213C1.44129 19.1839 2.20435 19.5 3 19.5H24C24.7956 19.5 25.5587 19.1839 26.1213 18.6213C26.6839 18.0587 27 17.2956 27 16.5V12.75H0V16.5Z" fill="#138808" />
    <path d="M27 6.75V3C27 2.20435 26.6839 1.44129 26.1213 0.87868C25.5587 0.31607 24.7956 0 24 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 6.75H27Z" fill="#FF9933" />
    <path d="M0 6.50024H27V13.0005H0V6.50024Z" fill="#F7F7F7" />
    <path d="M13.5 12.75C15.1569 12.75 16.5 11.4069 16.5 9.75C16.5 8.09315 15.1569 6.75 13.5 6.75C11.8431 6.75 10.5 8.09315 10.5 9.75C10.5 11.4069 11.8431 12.75 13.5 12.75Z" fill="#000080" />
    <path d="M13.5 12.2812C14.898 12.2812 16.0312 11.148 16.0312 9.75C16.0312 8.35203 14.898 7.21875 13.5 7.21875C12.102 7.21875 10.9688 8.35203 10.9688 9.75C10.9688 11.148 12.102 12.2812 13.5 12.2812Z" fill="#F7F7F7" />
    <path
      d="M13.5749 8.81235L13.4999 9.29985L13.4249 8.81235L13.4999 7.34985L13.5749 8.81235ZM12.8789 7.4316L13.1849 8.86335L13.3836 9.31485L13.3296 8.8251L12.8789 7.4316ZM12.2999 7.6716L12.9659 8.9751L13.2749 9.35985L13.0964 8.9001L12.2999 7.6716ZM11.8026 8.0526L12.7836 9.1401L13.1819 9.43185L12.8901 9.0336L11.8026 8.0526ZM11.4216 8.54985L12.6501 9.34635L13.1099 9.52485L12.7251 9.21585L11.4216 8.54985ZM11.1816 9.12885L12.5751 9.5796L13.0649 9.6336L12.6134 9.43485L11.1816 9.12885ZM11.0999 9.74985L12.5624 9.82485L13.0499 9.74985L12.5624 9.67485L11.0999 9.74985ZM11.1816 10.3709L12.6134 10.0649L13.0649 9.8661L12.5751 9.9201L11.1816 10.3709ZM11.4216 10.9499L12.7251 10.2839L13.1099 9.97485L12.6501 10.1534L11.4216 10.9499ZM11.8026 11.4471L12.8901 10.4661L13.1819 10.0679L12.7836 10.3596L11.8026 11.4471ZM12.2999 11.8281L13.0964 10.5996L13.2749 10.1399L12.9659 10.5246L12.2999 11.8281ZM12.8789 12.0681L13.3296 10.6746L13.3836 10.1849L13.1849 10.6364L12.8789 12.0681ZM13.4999 12.1499L13.5749 10.6874L13.4999 10.1999L13.4249 10.6874L13.4999 12.1499ZM14.1209 12.0681L13.8149 10.6364L13.6161 10.1849L13.6701 10.6746L14.1209 12.0681ZM14.6999 11.8281L14.0339 10.5246L13.7249 10.1399L13.9034 10.5996L14.6999 11.8281ZM15.1971 11.4471L14.2161 10.3596L13.8179 10.0679L14.1096 10.4661L15.1971 11.4471ZM15.5781 10.9499L14.3496 10.1534L13.8899 9.97485L14.2746 10.2839L15.5781 10.9499ZM15.8181 10.3709L14.4246 9.9201L13.9349 9.8661L14.3864 10.0649L15.8181 10.3709ZM15.8999 9.74985L14.4374 9.67485L13.9499 9.74985L14.4374 9.82485L15.8999 9.74985ZM15.8181 9.12885L14.3864 9.43485L13.9349 9.6336L14.4246 9.5796L15.8181 9.12885ZM15.5781 8.54985L14.2746 9.21585L13.8899 9.52485L14.3496 9.34635L15.5781 8.54985ZM15.1971 8.0526L14.1096 9.0336L13.8179 9.43185L14.2161 9.1401L15.1971 8.0526ZM14.6999 7.6716L13.9034 8.9001L13.7249 9.35985L14.0339 8.9751L14.6999 7.6716ZM14.1209 7.4316L13.6701 8.8251L13.6161 9.31485L13.8149 8.86335L14.1209 7.4316Z"
      fill="#6666B3"
    />
    <path
      d="M13.1708 7.39424C13.2536 7.39424 13.3208 7.32708 13.3208 7.24424C13.3208 7.1614 13.2536 7.09424 13.1708 7.09424C13.0879 7.09424 13.0208 7.1614 13.0208 7.24424C13.0208 7.32708 13.0879 7.39424 13.1708 7.39424Z"
      fill="#000080"
    />
    <path
      d="M12.5328 7.56526C12.6157 7.56526 12.6828 7.4981 12.6828 7.41526C12.6828 7.33242 12.6157 7.26526 12.5328 7.26526C12.45 7.26526 12.3828 7.33242 12.3828 7.41526C12.3828 7.4981 12.45 7.56526 12.5328 7.56526Z"
      fill="#000080"
    />
    <path
      d="M11.9615 7.89448C12.0444 7.89448 12.1115 7.82732 12.1115 7.74448C12.1115 7.66164 12.0444 7.59448 11.9615 7.59448C11.8787 7.59448 11.8115 7.66164 11.8115 7.74448C11.8115 7.82732 11.8787 7.89448 11.9615 7.89448Z"
      fill="#000080"
    />
    <path
      d="M11.495 8.36104C11.5778 8.36104 11.645 8.29388 11.645 8.21104C11.645 8.12819 11.5778 8.06104 11.495 8.06104C11.4121 8.06104 11.345 8.12819 11.345 8.21104C11.345 8.29388 11.4121 8.36104 11.495 8.36104Z"
      fill="#000080"
    />
    <path
      d="M11.1654 8.93232C11.2482 8.93232 11.3154 8.86517 11.3154 8.78232C11.3154 8.69948 11.2482 8.63232 11.1654 8.63232C11.0825 8.63232 11.0154 8.69948 11.0154 8.78232C11.0154 8.86517 11.0825 8.93232 11.1654 8.93232Z"
      fill="#000080"
    />
    <path
      d="M10.9947 9.5699C11.0776 9.5699 11.1447 9.50274 11.1447 9.4199C11.1447 9.33705 11.0776 9.2699 10.9947 9.2699C10.9119 9.2699 10.8447 9.33705 10.8447 9.4199C10.8447 9.50274 10.9119 9.5699 10.9947 9.5699Z"
      fill="#000080"
    />
    <path
      d="M10.9947 10.2298C11.0776 10.2298 11.1447 10.1627 11.1447 10.0798C11.1447 9.99697 11.0776 9.92981 10.9947 9.92981C10.9119 9.92981 10.8447 9.99697 10.8447 10.0798C10.8447 10.1627 10.9119 10.2298 10.9947 10.2298Z"
      fill="#000080"
    />
    <path
      d="M11.1654 10.8674C11.2482 10.8674 11.3154 10.8002 11.3154 10.7174C11.3154 10.6345 11.2482 10.5674 11.1654 10.5674C11.0825 10.5674 11.0154 10.6345 11.0154 10.7174C11.0154 10.8002 11.0825 10.8674 11.1654 10.8674Z"
      fill="#000080"
    />
    <path
      d="M11.495 11.439C11.5778 11.439 11.645 11.3719 11.645 11.289C11.645 11.2062 11.5778 11.139 11.495 11.139C11.4121 11.139 11.345 11.2062 11.345 11.289C11.345 11.3719 11.4121 11.439 11.495 11.439Z"
      fill="#000080"
    />
    <path
      d="M11.9615 11.9056C12.0444 11.9056 12.1115 11.8384 12.1115 11.7556C12.1115 11.6727 12.0444 11.6056 11.9615 11.6056C11.8787 11.6056 11.8115 11.6727 11.8115 11.7556C11.8115 11.8384 11.8787 11.9056 11.9615 11.9056Z"
      fill="#000080"
    />
    <path
      d="M12.5328 12.2348C12.6157 12.2348 12.6828 12.1677 12.6828 12.0848C12.6828 12.002 12.6157 11.9348 12.5328 11.9348C12.45 11.9348 12.3828 12.002 12.3828 12.0848C12.3828 12.1677 12.45 12.2348 12.5328 12.2348Z"
      fill="#000080"
    />
    <path
      d="M13.1708 12.4058C13.2536 12.4058 13.3208 12.3387 13.3208 12.2558C13.3208 12.173 13.2536 12.1058 13.1708 12.1058C13.0879 12.1058 13.0208 12.173 13.0208 12.2558C13.0208 12.3387 13.0879 12.4058 13.1708 12.4058Z"
      fill="#000080"
    />
    <path
      d="M13.8307 12.4058C13.9135 12.4058 13.9807 12.3387 13.9807 12.2558C13.9807 12.173 13.9135 12.1058 13.8307 12.1058C13.7478 12.1058 13.6807 12.173 13.6807 12.2558C13.6807 12.3387 13.7478 12.4058 13.8307 12.4058Z"
      fill="#000080"
    />
    <path
      d="M14.4679 12.2348C14.5507 12.2348 14.6179 12.1677 14.6179 12.0848C14.6179 12.002 14.5507 11.9348 14.4679 11.9348C14.385 11.9348 14.3179 12.002 14.3179 12.0848C14.3179 12.1677 14.385 12.2348 14.4679 12.2348Z"
      fill="#000080"
    />
    <path
      d="M15.0392 11.9056C15.122 11.9056 15.1892 11.8384 15.1892 11.7556C15.1892 11.6727 15.122 11.6056 15.0392 11.6056C14.9563 11.6056 14.8892 11.6727 14.8892 11.7556C14.8892 11.8384 14.9563 11.9056 15.0392 11.9056Z"
      fill="#000080"
    />
    <path
      d="M15.5057 11.439C15.5886 11.439 15.6557 11.3719 15.6557 11.289C15.6557 11.2062 15.5886 11.139 15.5057 11.139C15.4229 11.139 15.3557 11.2062 15.3557 11.289C15.3557 11.3719 15.4229 11.439 15.5057 11.439Z"
      fill="#000080"
    />
    <path
      d="M15.8353 10.8674C15.9181 10.8674 15.9853 10.8002 15.9853 10.7174C15.9853 10.6345 15.9181 10.5674 15.8353 10.5674C15.7525 10.5674 15.6853 10.6345 15.6853 10.7174C15.6853 10.8002 15.7525 10.8674 15.8353 10.8674Z"
      fill="#000080"
    />
    <path
      d="M16.006 10.2298C16.0888 10.2298 16.156 10.1627 16.156 10.0798C16.156 9.99697 16.0888 9.92981 16.006 9.92981C15.9231 9.92981 15.856 9.99697 15.856 10.0798C15.856 10.1627 15.9231 10.2298 16.006 10.2298Z"
      fill="#000080"
    />
    <path
      d="M16.006 9.5699C16.0888 9.5699 16.156 9.50274 16.156 9.4199C16.156 9.33705 16.0888 9.2699 16.006 9.2699C15.9231 9.2699 15.856 9.33705 15.856 9.4199C15.856 9.50274 15.9231 9.5699 16.006 9.5699Z"
      fill="#000080"
    />
    <path
      d="M15.8353 8.93232C15.9181 8.93232 15.9853 8.86517 15.9853 8.78232C15.9853 8.69948 15.9181 8.63232 15.8353 8.63232C15.7525 8.63232 15.6853 8.69948 15.6853 8.78232C15.6853 8.86517 15.7525 8.93232 15.8353 8.93232Z"
      fill="#000080"
    />
    <path
      d="M15.5057 8.36104C15.5886 8.36104 15.6557 8.29388 15.6557 8.21104C15.6557 8.12819 15.5886 8.06104 15.5057 8.06104C15.4229 8.06104 15.3557 8.12819 15.3557 8.21104C15.3557 8.29388 15.4229 8.36104 15.5057 8.36104Z"
      fill="#000080"
    />
    <path
      d="M15.0392 7.89448C15.122 7.89448 15.1892 7.82732 15.1892 7.74448C15.1892 7.66164 15.122 7.59448 15.0392 7.59448C14.9563 7.59448 14.8892 7.66164 14.8892 7.74448C14.8892 7.82732 14.9563 7.89448 15.0392 7.89448Z"
      fill="#000080"
    />
    <path
      d="M14.4679 7.56526C14.5507 7.56526 14.6179 7.4981 14.6179 7.41526C14.6179 7.33242 14.5507 7.26526 14.4679 7.26526C14.385 7.26526 14.3179 7.33242 14.3179 7.41526C14.3179 7.4981 14.385 7.56526 14.4679 7.56526Z"
      fill="#000080"
    />
    <path
      d="M13.8307 7.39424C13.9135 7.39424 13.9807 7.32708 13.9807 7.24424C13.9807 7.1614 13.9135 7.09424 13.8307 7.09424C13.7478 7.09424 13.6807 7.1614 13.6807 7.24424C13.6807 7.32708 13.7478 7.39424 13.8307 7.39424Z"
      fill="#000080"
    />
    <path
      d="M13.5002 10.4251C13.873 10.4251 14.1752 10.1229 14.1752 9.75007C14.1752 9.37728 13.873 9.07507 13.5002 9.07507C13.1274 9.07507 12.8252 9.37728 12.8252 9.75007C12.8252 10.1229 13.1274 10.4251 13.5002 10.4251Z"
      fill="#000080"
    />
  </svg>
);

export const USSVG = (
  <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H9V9H0V0Z" fill="#4427F8" />
    <path d="M9 0H27V3H9V0Z" fill="#FF0000" />
    <path d="M9 3H27V6H9V3Z" fill="white" />
    <path d="M9 6H27V9H9V6Z" fill="#FF0000" />
    <path d="M0 9H27V12H0V9Z" fill="white" />
    <path d="M0 12H27V15H0V12Z" fill="#FF0000" />
    <path d="M0 15H27V18H0V15Z" fill="white" />
  </svg>
);
