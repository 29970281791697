import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import SimpleDropdown from "../../../../components/dropdowns/simple-dropdown";
import PopoverFilterWrapper from "../../../../components/filter/popover-filter";
import { campaignChannelsOptions2 } from "../../constants";
import { useSelector } from "react-redux";

export default function CampaignListFilter(props) {
  const [channel, setChannel] = useState({ value: "all", label: "All" });
  const [owner, setOwner] = useState({ value: "all", label: "All" });
  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(7, "days")
      .valueOf(),
  );
  const [toDate, setToDate] = useState(moment().valueOf());
  const [focusedInput, setFocusedInput] = useState("");
  const [userOptions, setUserOptions] = useState([]);

  const userRole = useSelector((store) => store.insight.userRole);
  useEffect(() => {
    if (userRole.list.users) {
      const _options = [];
      _options.push({ value: "all", label: "All" });
      userRole.list.users.forEach((each) => {
        _options.push({ value: each.id, label: each.name, email: each.email });
      });
      setUserOptions(_options);
    }
  }, [userRole.list.users]);

  useEffect(() => {
    if (!_.isEmpty(props.filterValue)) {
      const { channel, owner, fromDate, toDate } = props.filterValue;
      setFromDate(fromDate);
      setToDate(toDate);
      const ownerFind = userOptions.find((a) => a.value === owner);
      setOwner(ownerFind);
      const channelFind = campaignChannelsOptions2.find((a) => a.value === channel);
      setChannel(channelFind);
    }
  }, [props.filterValue, userOptions]);

  const handleApplyFilter = () => {
    const payload = {
      channel: channel.value,
      owner: owner.value,
      fromDate: fromDate,
      toDate: toDate,
    };
    props.handleApplyFilter(payload);
  };

  const isOutsideRange = (day) => {
    const _startDate = fromDate ? moment(fromDate) : moment();
    return focusedInput === "endDate" && (day.isBefore(_startDate) || day.isAfter(_startDate.clone().add(3, "months")));
  };
  const handleResetFilter = () => {
    const payload = {
      channel: "all",
      owner: "all",
      fromDate: moment()
        .subtract(3, "months")
        .startOf("days")
        .valueOf(),
      toDate: moment()
        .add(1, "day")
        .endOf("day")
        .valueOf(),
    };
    props.handleApplyFilter(payload);
  };
  return (
    <PopoverFilterWrapper
      show={props.show}
      setShow={props.setShow}
      label={"Filter"}
      onSaveClick={handleApplyFilter}
      onCancelClick={props.handleCancelFilter}
      showCancel={true}
      showSave={true}
      saveButtonText={"Apply"}
      cancelButtonText={"Cancel"}
      showReset={true}
      onResetClick={handleResetFilter}
      buttonType={"secondary"}
    >
      <Form className="w-350px">
        <Form.Group>
          <Form.Label className="fw-bold">Channel</Form.Label>
          <SimpleDropdown value={channel} label={"Channel"} options={campaignChannelsOptions2} handleSelect={setChannel} />
        </Form.Group>
        <Form.Group>
          <Form.Label className="fw-bold">Owner</Form.Label>
          <SimpleDropdown value={owner} label={"Owner"} options={userOptions} handleSelect={setOwner} />
        </Form.Group>
        <Form.Group>
          <Form.Label className="fw-bold">Duration</Form.Label>
          <DateRangePicker
            startDate={fromDate ? moment(fromDate) : null}
            startDateId="fromdate"
            endDate={toDate ? moment(toDate) : null}
            endDateId="enddate"
            onDatesChange={({ startDate, endDate }) => {
              const _startDate = startDate ? new Date(startDate).getTime() : null;
              const _endDate = endDate ? new Date(endDate).getTime() : null;
              setFromDate(_startDate);
              setToDate(_endDate);
            }}
            displayFormat={"YYYY-MM-DD"}
            small={true}
            isOutsideRange={isOutsideRange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => {
              setFocusedInput(focusedInput);
            }}
          />
        </Form.Group>
      </Form>
    </PopoverFilterWrapper>
  );
}
