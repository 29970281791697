import senderIdListSaga from "./cadence_analytics_stepwise_saga";
import cadenceAnalyticsSummarizeSaga from "./cadence_analytics_summarize_saga";
import CadenceLeadSaga from "./cadence_lead_saga";
import CadenceSaga from "./cadence_saga";
import CadenceStepSaga from "./cadence_step_saga";
import cadencePrebuiltTemplateSaga from "./cadence_prebuilt_templage_saga";
import cadenceCloneableTemplateSaga from "./cadence_cloneable_templage_saga";
import cadenceSubscriberOperationsSaga from "./cadence_subscriber_operations_saga";
import cadenceSettingsSaga from "./cadence_settings_saga";

export default [
  CadenceLeadSaga,
  CadenceSaga,
  CadenceStepSaga,
  cadencePrebuiltTemplateSaga,
  cadenceCloneableTemplateSaga,
  cadenceAnalyticsSummarizeSaga,
  senderIdListSaga,
  cadenceSubscriberOperationsSaga,
  cadenceSettingsSaga,
];
