import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { InputLimit } from "../../../../../constants";
// import { infoTextMessages } from "../constants";
import BufferTimeEventDropdown from "./time-dropdowns/buffer-time.dropdown";
import EventDurationDropdown from "./time-dropdowns/event-duration.dropdown";
import FutureTimeEventDropdown from "./time-dropdowns/future-time.dropdown";
import MinBookingEventDropdown from "./time-dropdowns/min-booking-time.dropdown";
import MinCancellationNoticeEventDropdown from "./time-dropdowns/min-cancellation-notice.dropdown";

export default function EventInfoForm(props) {
  const updateData = (parentKey, key, value) => {
    const data = {
      ...props.data,
      config: {
        ...props.data.config,
        [parentKey]: {
          ...props.data.config[parentKey],
          [key]: value,
        },
      },
    };
    props.setSelectedData(data);
  };

  const updateName = (value) => {
    const data = { ...props.data };
    data.name = value;
    data.config.event.title = value;
    props.setSelectedData(data);
  };
  return (
    <Form.Group onClick={(e) => e.stopPropagation()}>
      <Form.Group>
        <Form.Label>Event Name</Form.Label>
        <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.data.name} onChange={(e) => updateName(e.target.value)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Event Location (You can add video meeting URL here eg. google meet)</Form.Label>
        <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.data.config.event.location} onChange={(e) => updateData("event", "location", e.target.value)} />
      </Form.Group>
      <Row>
        <Col>
          <EventDurationDropdown value={props.data.config.event.duration} setValue={(value) => updateData("event", "duration", value)} />
        </Col>
        <Col>
          <MinBookingEventDropdown value={props.data.config.booking.min_booking_notice} setValue={(value) => updateData("booking", "min_booking_notice", value)} />
        </Col>
        <Col>
          <MinCancellationNoticeEventDropdown value={props.data.config.booking.min_cancellation_notice} setValue={(value) => updateData("booking", "min_cancellation_notice", value)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FutureTimeEventDropdown value={props.data.config.booking.available_days_in_future} setValue={(value) => updateData("booking", "available_days_in_future", value)} />
        </Col>
        <Col>
          <BufferTimeEventDropdown value={props.data.config.booking.min_buffer} setValue={(value) => updateData("booking", "min_buffer", value)} />
        </Col>
        <Col></Col>
      </Row>
      {/* <Form.Group>
        <Form.Label>
          Cancellation Policy
          <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.CANCELLATION_POLICY}</Tooltip>}>
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          as="textarea"
          maxLength={InputLimit.TEXTAREA}
          rows={3}
          value={props.data.config.booking.cancellation_policy}
          onChange={(e) => updateData("booking", "cancellation_policy", e.target.value)}
        ></Form.Control>
      </Form.Group> */}
    </Form.Group>
  );
}
