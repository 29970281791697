import React, { useEffect, useState } from "react";
import _ from "lodash";
import ContentWrapper from "../../Wrapper";
import { CustomSubHeader } from "../../../components/subheader";
import { BlueGradientButton } from "../../../components/button";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import * as emailFinderActions from "../store/actions/email-finder.actions";
import CustomSidePanel from "../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import { CustomTableWithoutAction } from "../../../components/custom-tables/table-without-actions";
import { NormalTable } from "../../../components/table/normalTable";
import { useHistory } from "react-router-dom";

const pricingTableFields = ["Plan Type", "Currency", "Per Email Price", "Status"];
const pricingTableMapping = {
    "Plan Type": "planType",
    Currency: "currency",
    "Per Email Price": "price",
    Status: "status",
};

export const pricingTableFieldDataType = {
    planType: "TEXT",
    currency: "LIGHT_TAG",
    emailPrice: "TEXT",
    status: "TAG",
};
export default function EmailFinderPage() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [showPricing, setShowPricing] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const emailFinderStore = useSelector(store => store.integrationApps.emailFinder.data);
    const emailFinderPricingStore = useSelector(store => store.integrationApps.emailFinder.pricing);

    useEffect(() => {
        const payload = { page, limit };
        dispatch(emailFinderActions.getListOfEmailFinderRequest(payload))
    }, [dispatch, page, limit])
    useEffect(() => {
        dispatch(emailFinderActions.getEmailFinderPricingRequest("email_finder"))
    }, [dispatch])

    const onPricingClick = () => {
        setShowPricing(true);
    }


    const columns = [
        { apiKeyName: "createdAt", fieldInputType: "DATE_TIME", fieldName: "Date" },
        { apiKeyName: "mode", fieldInputType: "INPUT", fieldName: "Module Name" },
        { apiKeyName: "type", fieldInputType: "INPUT", fieldName: "Request Type" },
        { apiKeyName: "count", fieldInputType: "INPUT", fieldName: "Total" },
        { apiKeyName: "valid", fieldInputType: "INPUT", fieldName: "Valid" },
        { apiKeyName: "invalid", fieldInputType: "INPUT", fieldName: "Invalid" },
        { apiKeyName: "percentage", fieldInputType: "INPUT", fieldName: "Percentage" },
        { apiKeyName: "owner", fieldInputType: "OWNER_SELECT", fieldName: "Owner" },
        { apiKeyName: "errorMessage", fieldInputType: "INPUT", fieldName: "Comment" },
        { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    ];

    const tableProps = {
        columns: columns,
        data: emailFinderStore.list.map((each) => {
            each.percentage = `${_.parseInt(each.percentage)}%`;
            return each;
        }),
        pagination: true,
        count: emailFinderStore.count,
        limit: limit,
        pageNo: page,
        setLimit: setLimit,
        setPageNo: setPage,
        setChecked: () => { },
        loading: emailFinderStore.loading,
    };

    return <ContentWrapper
        subHeader={
            <CustomSubHeader>
                <div className="d-flex w-100 flex-stack">
                    <div onClick={() => history.push("/settings/integrations")} className="d-flex align-items-center cursor-pointer" >
                        <i className="bi bi-arrow-left" style={{ color: "#333" }}></i>
                        <span className="ml-2"> Back to integrations</span>
                    </div>
                    <BlueGradientButton variant="success" size="sm" onClick={() => onPricingClick()}>
                        Pricing
                    </BlueGradientButton>
                </div>
            </CustomSubHeader>
        }
    >
        {emailFinderStore.loading ? (
            <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
                <Spinner animation="border" variant="primary" />
            </div>
        ) : emailFinderStore.list.length === 0 ? (
            <CustomEmptyPage page="emailFinder" />
        ) : (
            <div className={`card card-custom`}>
                <div className="card-body py-3 px-3">
                    <NormalTable props={tableProps} />
                </div>
            </div>
        )}
        <CustomSidePanel
            show={showPricing}
            moduleName={"Email Finder Pricing"}
            handleClose={() => setShowPricing(false)}
            showNextButton={false}
            showPreviosButton={false}
            customStyles={{
                width: "50vw",
                right: "51vw",
            }}
        >
            <div className="pricing-container">
                <div className="pricing-header d-flex flex-stack align-items-center">
                    <h3 className="fw-bolder text-dark">Email Finder Pricing</h3>
                </div>
                <div className="pricing-content-container p-8">
                    <div>
                        <div className="card">
                            <CustomTableWithoutAction
                                tablehead={pricingTableFields}
                                tablecontent={emailFinderPricingStore.data.map((each) => {
                                    each.planType = "Regular";
                                    each.currency = _.upperCase(each.currency);
                                    return each;
                                })}
                                mappingTable={pricingTableMapping}
                                dataType={pricingTableFieldDataType}
                                pageNo={1}
                                limit={limit}
                                count={1}
                                setPageNo={() => { }}
                                setLimit={() => { }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </CustomSidePanel>
    </ContentWrapper>
}