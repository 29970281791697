import React from "react";
import EmailPricing from "./email.pricing";
import SMSPricing from "./sms-pricing";
import "./styles.scss";
import WhatsappPricing from "./whatsapp.pricing";

export default function PricingModule(props) {
  return (
    <>
      {props.channel === "email" && <EmailPricing channel={props.channel} />}
      {props.channel === "sms" && <SMSPricing channel={props.channel} />}
      {props.channel === "whatsapp" && <WhatsappPricing channel={props.channel} />}
    </>
  );
}
