import { getEmailNotificationsByID, editEmailNotifications, createEmailNotifications } from "../types";

export const getEmailNotificationsByIdRequest = (payload, cb) => ({
  type: getEmailNotificationsByID.GET_EMAIL_NOTIFICATION_BY_ID_REQUEST,
  payload,
  cb,
});
export const getEmailNotificationsByIdSuccess = (payload) => ({
  type: getEmailNotificationsByID.GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS,
  payload,
});
export const getEmailNotificationsByIdError = (payload) => ({
  type: getEmailNotificationsByID.GET_EMAIL_NOTIFICATION_BY_ID_ERROR,
  payload,
});

export const editEmailNotificationsRequest = (payload, cb) => ({
  type: editEmailNotifications.EDIT_EMAIL_NOTIFICATION_REQUEST,
  payload,
  cb,
});
export const editEmailNotificationsSuccess = (payload) => ({
  type: editEmailNotifications.EDIT_EMAIL_NOTIFICATION_SUCCESS,
  payload,
});
export const editEmailNotificationsError = (payload) => ({
  type: editEmailNotifications.EDIT_EMAIL_NOTIFICATION_ERROR,
  payload,
});

export const createEmailNotificationsRequest = (payload, cb) => ({
  type: createEmailNotifications.CREATE_EMAIL_NOTIFICATION_REQUEST,
  payload,
  cb,
});
export const createEmailNotificationsSuccess = (payload) => ({
  type: createEmailNotifications.CREATE_EMAIL_NOTIFICATION_SUCCESS,
  payload,
});
export const createEmailNotificationsError = (payload) => ({
  type: createEmailNotifications.CREATE_EMAIL_NOTIFICATION_ERROR,
  payload,
});
