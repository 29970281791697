import React, { useEffect, useState } from "react";
import { isEmpty, sortBy } from "lodash";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";

export default function ChangeStepActionForm(props) {
  const [stepOptions, setStepOptions] = useState([]);

  const getSelectedCadenceStore = useSelector((store) => store.cadence.selected);

  useEffect(() => {
    if (!isEmpty(getSelectedCadenceStore.data)) {
      const steps = getSelectedCadenceStore.data.steps;
      const options = sortBy(steps, ["executionOrder"]).map((each) => ({
        value: each._id,
        label: `Step ${each.executionOrder} - ${each?.name} (${each.channelType})`,
      }));
      setStepOptions(options);
    }
  }, [getSelectedCadenceStore.data]);

  return (
    <Form.Group>
      <Form.Group>
        <Form.Label>Cadence Step</Form.Label>
        <SimpleDropdown value={props.selectedStep} label={"Select"} options={stepOptions} handleSelect={props.setSelectedStep} />
      </Form.Group>
    </Form.Group>
  );
}
