import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import ContactSales from "../components/contact-sales";
import PlanCard from "../components/plan-card";
import FreePlanSubscriptionModel from "../components/free-plan-subscription-modal";

const planFeatures = {
    free: [
        "All essential features",
        "25 AI-Wizard Credits",
        "200 Contacts",
        "Deals pipeline(1)",
        "Analytics dashboard(1)",
        "Custom fields(5)",
        "Multi-Currency & Roles ",
        "Product Catalog",
        "Branded Leadbot",
        "Campaigns - SMS & Email",
        "Unlimited Marketing Contacts",
        "Email Validation & Finder",
        "Chrome Linkedin Extension"
    ],
    startUpPlan: [
        "Everything in Free Plan",
        "Unlimited Cadences",
        "Unlimited Emails",
        "1 Mailbox",
        "100 Cadence Subscriber",
        "100 AI-Wizard Credits",
        "100,000 Contacts",
        "Deals pipeline(3)",
        "Custom fields(15)",
        "Analytics dashboards",
        "Workflow automation",
        "2-Way Email & Calendar",
        "Teams, Goals & Forecast"
    ],
    lifeTimeFree: [
        "Everything in Startup",
        "Unlimited Cadences",
        "1000 Cadence Subscriber",
        "500 AI-Wizard Credits",
        "Unlimited Contacts",
        "Unlimited Pipelines",
        "Unlimited Custom Fields",
        "Unlimited Analytics",
        "Meeting Scheduler",
        "Whitelabled Leadbot",
        "Priority Support"
    ],
    businessPlan: [
        "Everything in Startup",
        "Unlimited Cadences",
        "Unlimited Emails",
        "2 Mailbox",
        "1000 Cadence Subscriber",
        "500 AI-Wizard Credits",
        "Unlimited Contacts",
        "Unlimited Pipelines",
        "Unlimited Custom Fields",
        "Unlimited Analytics",
        "Meeting Scheduler",
        "Whitelabled Leadbot",
        "Priority Support"
    ],
    businessPlusPlan: [
        "Everything in Business",
        "Unlimited Cadences",
        "Unlimited Emails",
        "4 Mailbox",
        "10000 Cadence Subscriber",
        "2500 AI-Wizard Credits",
        "A/B Testing",
        "Account Based Cadence",
        "Unlimited Inbuilt CRM",
        "2-Way CRM Sync Upcoming",
        "Meeting Scheduler",
        "Whitelabled Leadbot",
        "Dedicated A/c Manager"
    ],
    enterprisePlan: [
        "Everything in Business",
        "Unlimited Cadences",
        "Unlimited Emails",
        "4 Mailbox",
        "10000 Cadence Subscriber",
        "2500 AI-Wizard Credits",
        "A/B Testing",
        "Account Based Cadence",
        "Unlimited Inbuilt CRM",
        "2-Way CRM Sync Upcoming",
        "Meeting Scheduler",
        "Whitelabled Leadbot",
        "Dedicated A/c Manager"
    ],
};
const planNames = {
    free: "Free",
    startUpPlan: "Startup",
    businessPlan: "Business",
    lifeTimeFree: "Lifetime Free",
    enterprisePlan: "Enterprise",
    businessPlusPlan: "Business Plus",

};
const planDesc = {
    free: "For individuals",
    startUpPlan: "For startups",
    businessPlan: "For businesses",
    lifeTimeFree: "For businesses",
    enterprisePlan: "For big companies",
    businessPlusPlan: "For big companies",
};
export default function Step1(props) {
    // state variables
    const [planList, setPlanList] = useState([]);
    const [showFreePlanModel, setShowFreePlanModel] = useState(false);

    // store
    const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
    const pricesStore = useSelector((state) => state.Billing.subscriptionv2.prices);

    useEffect(() => {
        if (_.isEmpty(accountBillingStore.data)) return;
        const { isSubscribedForLifeTimeFree } = accountBillingStore.data;
        if (isSubscribedForLifeTimeFree) {
            setPlanList(["lifeTimeFree"])
        }
        else {
            // setPlanList(["lifeTimeFree"])
            setPlanList(["free", "startUpPlan", "businessPlan", "businessPlusPlan"])
        }
    }, [accountBillingStore.data])

    const getPlanPrice = (amount, interval) => {
        if (interval === "month") {
            return parseInt(amount);
        } else {
            return parseInt(amount / 12);
        }
    };

    const onFreePlanConversionClick = () => {
        if (accountBillingStore.data?.selectedPlan?.toLowerCase() !== "free") {
            setShowFreePlanModel(true);
        }
    };
    const handleCovertToFreePlan = () => {
        setShowFreePlanModel(false);
        props.convertToFreePlan();
    };

    const handleSelectMainPlan = (each, data) => {
        if (each === "free") {

        } else {
            props.handleSelectMainPlan(data);
        }
    };


    return (
        <>
            {pricesStore.loading || accountBillingStore.loading ? <div className="d-flex w-100 h-100 flex-center">
                <Spinner animation="border" variant="primary" />
            </div> : <div className="subscription-step1-page-warpper">
                <div className="subscription-step1-part1-wrapper">
                    <div className="plan-cards-container gap-7">
                        {planList.map((plan, index) => (<PlanCard
                            key={index}
                            features={planFeatures[plan]}
                            planType={plan}
                            serviceType={accountBillingStore.data?.selectedPlan}
                            planName={planNames[plan]}
                            planDesc={planDesc[plan]}

                            interval={props.interval}
                            amount={
                                ["free"].includes(plan)
                                    ? `${props.currencySymbol} 0`
                                    : `${props.currencySymbol} ${props.planMapping[plan] && props.planMapping[plan].amount ? getPlanPrice(props.planMapping[plan].amount, props.interval) : 0}`
                            }
                            onPlanSelect={(e) => {
                                e.stopPropagation();
                                if (plan === "free") {
                                    onFreePlanConversionClick(plan, props.planMapping[plan]);
                                } else {
                                    handleSelectMainPlan(plan, props.planMapping[plan]);
                                }
                            }}
                        />))}
                    </div>
                    <ContactSales />
                </div>
                <FreePlanSubscriptionModel showFreePlanModal={showFreePlanModel} handleSubscription={handleCovertToFreePlan} setShowFreePlanModal={setShowFreePlanModel} />
            </div>}
        </>
    )
}