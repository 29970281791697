import React from "react";
import OTPInput from "../../../../../../components/otp-input";

export default function OtpVerification(props) {
  const { Back, onOtpChange, otpValue, resendOTP } = props;

  return (
    <>
      <div className="form w-100 mb-10" noValidate="novalidate" id="kt_sing_in_two_steps_form">
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3 fw-600">Great, now verify your email</h1>
          <div className="text-muted fw-bold fs-5 mb-5">Please enter the verification code we sent you on your email.</div>
        </div>
        <div className="mb-10 px-md-10">
          <div className="fw-bolder text-start text-dark fs-6 mb-1">Type your 6 digit security code</div>
          <OTPInput
            autoFocus
            length={6}
            value={otpValue}
            className="d-flex flex-wrap justify-content-between inputFormStyle"
            inputClassName="form-control form-control-solid fs-2qx text-center border-primary border-hover me-2 my-2 OtpScreenStyle"
            onChangeOTP={(otp) => onOtpChange(otp)}
          />
        </div>
        <div className="d-flex align-items-center justify-content-around">
          <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={Back}>
            <span className="indicator-label">Back</span>
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
        </div>
      </div>
      <div className="text-center fw-bold fs-5">
        <span className="text-muted me-1">Didn’t receive an email? </span>
        <span className="text-muted me-1">Check your spam folder</span>
        <span className="text-muted me-1">or</span>
        <span onClick={resendOTP} className="link-primary fw-bolder fs-5 cursor-pointer">
          resend email
        </span>
      </div>
    </>
  );
}
