export const getListOfBlockContacts = {
  GET_LIST_OF_BLOCK_CONTACTS_REQUEST: "GET_LIST_OF_BLOCK_CONTACTS_REQUEST",
  GET_LIST_OF_BLOCK_CONTACTS_SUCCESS: "GET_LIST_OF_BLOCK_CONTACTS_SUCCESS",
  GET_LIST_OF_BLOCK_CONTACTS_ERROR: "GET_LIST_OF_BLOCK_CONTACTS_ERROR",
};

export const createBlockContact = {
  CREATE_BLOCK_CONTACT_REQUEST: "CREATE_BLOCK_CONTACT_REQUEST",
  CREATE_BLOCK_CONTACT_SUCCESS: "CREATE_BLOCK_CONTACT_SUCCESS",
  CREATE_BLOCK_CONTACT_ERROR: "CREATE_BLOCK_CONTACT_ERROR",
};

export const editBlockContact = {
  EDIT_BLOCK_CONTACT_REQUEST: "EDIT_BLOCK_CONTACT_REQUEST",
  EDIT_BLOCK_CONTACT_SUCCESS: "EDIT_BLOCK_CONTACT_SUCCESS",
  EDIT_BLOCK_CONTACT_ERROR: "EDIT_BLOCK_CONTACT_ERROR",
};

export const deleteBlockContact = {
  DELETE_BLOCK_CONTACT_REQUEST: "DELETE_BLOCK_CONTACT_REQUEST",
  DELETE_BLOCK_CONTACT_SUCCESS: "DELETE_BLOCK_CONTACT_SUCCESS",
  DELETE_BLOCK_CONTACT_ERROR: "DELETE_BLOCK_CONTACT_ERROR",
};
