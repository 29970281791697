import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import StageList from "./stage";
import ScheduleActivity from "../../Activities/Activity-List/add-activity/index";
import "./styles.scss";
import { FooterMenuOption } from "./styled";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MoveDealToModel from "../components/move-deal-to.model";
import MarkAsLostModel from "../components/mark-as-lost.model";

export default function DealPipelineWrapper(props) {
  const [deletedItem, setDeletedItem] = useState({});
  const [showFooterMenu, setShowFooterMenu] = useState(false);
  const [openLossModal, setOpenLossModal] = useState(false);
  const [openMoveToModal, setOpenMoveToModal] = useState(false);
  const [stageIds, setStageIds] = useState([]);
  const [stageMap, setStageMap] = useState({});
  const [initialItemMap, setInitialItemMap] = useState({});
  const [openScheduleActivity, setOpenScheduleActivity] = useState(false);
  const [dealId, setDealId] = useState("");
  const [relatedAssociate, setRelatedAssociate] = useState({});
  const stageDataStore = useSelector((store) => store.DealPipeline.stagesData);
  const dealItemStore = useSelector((store) => store.DealPipeline.dealsDataResponse);

  const history = useHistory();

  useEffect(() => {
    if (stageDataStore && stageDataStore.data && stageDataStore.data.stages) {
      const result = stageDataStore.data.stages.map((stage) => stage._id);
      const itemMapArray = {};
      const stageMapArray = {};

      for (const stage of stageDataStore.data.stages) {
        itemMapArray[stage._id] = dealItemStore.filter((item) => stage._id === item.stageId);
        stageMapArray[stage._id] = stage;
      }
      setStageIds(result);
      setStageMap(stageMapArray);
      setInitialItemMap(itemMapArray);
    }
  }, [stageDataStore, dealItemStore]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      setShowFooterMenu(false);
      return;
    }

    //reordering stage
    if (result.type === "STAGE") {
      const _stageIds = reorder(stageIds, source.index, destination.index);
      setStageIds(_stageIds);
    }

    if (result.type !== "STAGE" && destination.droppableId !== "droppable-0" && destination.droppableId !== "droppable-1" && destination.droppableId !== "droppable-2" && destination.droppableId !== "droppable-3") {
      if (source.droppableId === destination.droppableId) {
        //need to change order for deal
        props.callMoveStagesApi({
          data: {
            order: destination.index + 1,
            dealId: result.draggableId,
          },
          type: "change-deal-order",
          pipelineId: props.selectedPipelineId,
        });
      } else {
        props.callMoveStagesApi({
          data: {
            pipelineId: props.selectedPipelineId,
            stageId: destination.droppableId,
            dealId: result.draggableId,
            order: destination.index + 1,
          },
          type: "move-deal-in-stages",
          pipelineId: props.selectedPipelineId,
        });
      }
    }

    //delete node
    if (destination.droppableId === "droppable-0") {
      props.callDealOperationApi({
        data: {
          dealId: result.draggableId,
        },
        type: "archive",
        pipelineId: props.selectedPipelineId,
      });

      setShowFooterMenu(false);
    }

    //Lost Node
    if (destination.droppableId === "droppable-1") {
      for (let i = 0; i < props.items.length; i++) {
        if (props.items[i].stageId === source.droppableId) {
          for (let j = 0; j < props.items[i].deals.length; j++) {
            if (props.items[i].deals[j]._id === result.draggableId) {
              let selectedDeal = props.items[i].deals[j];
              setDeletedItem(selectedDeal);
            }
          }
        }
      }
      setOpenLossModal(true);
      setShowFooterMenu(false);
    }

    //Won Node
    if (destination.droppableId === "droppable-2") {
      props.callDealOperationApi({
        data: {
          dealId: result.draggableId,
        },
        type: "mark-as-won",
        pipelineId: props.selectedPipelineId,
      });
      setShowFooterMenu(false);
    }

    //MoveTo node
    if (destination.droppableId === "droppable-3") {
      for (let i = 0; i < props.items.length; i++) {
        if (props.items[i].stageId === source.droppableId) {
          for (let j = 0; j < props.items[i].deals.length; j++) {
            if (props.items[i].deals[j]._id === result.draggableId) {
              let selectedDeal = props.items[i].deals[j];
              setDeletedItem(selectedDeal);
            }
          }
        }
      }

      setOpenMoveToModal(true);
      setShowFooterMenu(false);
    }

    setShowFooterMenu(false);
  };

  const handleDragStart = (result) => {
    setShowFooterMenu(true);
  };

  const onOpenScheduleActivity = (value, dealid, title) => {
    setOpenScheduleActivity(value);
    setDealId(dealid);
    document.body.click();
    setRelatedAssociate({
      _id: dealid,
      title: title,
      type: "deal",
    });
  };

  if (stageDataStore.data.stages && stageDataStore.data.stages.length === 0) {
    return (
      <div className="no-pipeline">
        <h2 className="no-pipeline-text">Getting Started with Sales Pipeline</h2>
      </div>
    );
  }
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd} onBeforeCapture={handleDragStart}>
        <Droppable droppableId="pipeline" type="STAGE" direction="horizontal">
          {(provided) => (
            <div className={stageIds.length > 6 ? "stages-division" : "stages-division width-100"} {...provided.droppableProps} ref={provided.innerRef}>
              {stageIds.map((stageId, index) => {
                const stage = stageMap && stageMap[stageId];
                if (!stage) {
                  return null;
                }
                return (
                  <StageList
                    stagesLength={stageIds.length}
                    stage={stage}
                    index={index}
                    key={stageId}
                    items={initialItemMap[stageId]}
                    openComposer={() => {}}
                    openScheduleActivity={(value, dealid, title) => onOpenScheduleActivity(value, dealid, title)}
                    pipelineswithstages={props.pipelineswithstages}
                    selectedPipeline={props.selectedPipeline}
                    selectedPipelineId={props.selectedPipelineId}
                    onAddDealModalSave={props.onAddDealModalSave}
                    activityLostReasons={props.activityLostReasons}
                    callActivityMADoneApi={props.callActivityMADoneApi}
                    loading={props.dealsLoading}
                    ownerId={props.ownerId}
                    pipelinedropdownOptions={props.pipelinedropdownOptions}
                    lostReasonStatus={props.lostReasonStatus}
                    history={history}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {showFooterMenu ? (
          <div className="footer-menu-options">
            {[{ name: "Archive" }, { name: "Lost" }, { name: "Won" }, { name: "Move To" }].map((item, index) => (
              <Droppable droppableId={`droppable-${index}`} key={index} index={index}>
                {(provided, dropSnapshot) => (
                  <FooterMenuOption isDraggingOver={dropSnapshot.isDraggingOver} item={item.name} ref={provided.innerRef} {...provided.droppableProps}>
                    {item.name}
                  </FooterMenuOption>
                )}
              </Droppable>
            ))}
          </div>
        ) : null}
        <MarkAsLostModel selectedDeal={deletedItem} show={openLossModal} selectedPipelineId={props.selectedPipelineId} setShow={setOpenLossModal} callDealOperationApi={props.callDealOperationApi} />
        <MoveDealToModel
          selectedDeal={deletedItem}
          show={openMoveToModal}
          setShow={setOpenMoveToModal}
          selectedPipelineId={props.selectedPipelineId}
          pipelinedropdownOptions={props.pipelinedropdownOptions}
          callMoveStagesApi={props.callMoveStagesApi}
          callDealOperationApi={props.callDealOperationApi}
        />
        <ScheduleActivity
          disabled
          relatedAssociate={relatedAssociate}
          openAddActivityModal={openScheduleActivity}
          onCreateActivity={(data) => props.callCreateActivityApi({ data: data, dealId: dealId })}
          closeScheduleActivity={(value) => setOpenScheduleActivity(value)}
          dealId={dealId}
          pipelineId={props.selectedPipelineId}
        />
      </DragDropContext>
    </>
  );
}
