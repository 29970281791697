import { getListOfWorkflows, getWorkflowDetailsByID } from "../types";
const initState = {
  list: {
    data: [],
    count: 0,
    isLoading: false,
    error: false,
  },
  details: {
    data: {},
    isLoading: false,
    error: false,
  },
};

export default function workflowReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfWorkflows.GET_LIST_OF_WORKFLOWS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
        },
      };
    case getListOfWorkflows.GET_LIST_OF_WORKFLOWS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.data.list,
          count: action.payload.data.pagination.totalDocuments,
        },
      };
    case getListOfWorkflows.GET_LIST_OF_WORKFLOWS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          error: action.payload,
        },
      };
    case getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: true,
        },
      };
    case getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_ERROR:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
