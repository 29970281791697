import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import BlankSidePanel from "../../blank-side-pannel";
import { ThreeDotHorizontalSVG } from "../../custom-svgs";

import "./styles.scss";
import InlineStyled from "../../inline-styled/inline-styled";

export default function EmailViewCard(props) {
  const [show, setShow] = useState(false);
  const [tagInfo, setTagInfo] = useState({});

  useEffect(() => {
    if (!_.isEmpty(props.data) && !_.isEmpty(props.tagsData)) {
      if (!_.isEmpty(props.data.tags)) {
        setTagInfo(props.tagsData[props.data.tags[0]] ? props.tagsData[props.data.tags[0]] : {});
      }
    }
  }, [props.data, props.tagsData]);

  return (
    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-10 pb-7">
      <div className="email-card-wrapper">
        <div className="email-content">
          <iframe title="Template view" srcDoc={`<div>${props.data.content}</div>`} width="100%" height="100%" frameBorder="0" scrolling="no" />
          <div className="content-button-wrapper">
            <div className="content-buttons">
              {props.showChoose && (
                <Button size="sm" variant="light" onClick={() => props.onCloseClick(props.data)}>
                  Choose
                </Button>
              )}
              <Button size="sm" variant="light" onClick={() => setShow(true)}>
                Preview
              </Button>
            </div>
          </div>
          {!_.isEmpty(tagInfo) && (
            <div className="position-absolute right-10px top-10px">
              <InlineStyled className="badge badge-dark m-0 bg-white" styles={{ border: `1px solid ${tagInfo.colorCode}`, color: tagInfo.colorCode }}>
                {tagInfo.name}
              </InlineStyled>
            </div>
          )}
        </div>
        <div className="template-name">
          <div className="template-text"> {props.data.name}</div>
          <div>
            <Dropdown className="dropdown-inline">
              <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle p-5px" variant="transparent" id="dropdown-toggle-top">
                {ThreeDotHorizontalSVG}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm overflow-y-auto h-50vh-mx">
                {props.viewType === "org" && (
                  <Dropdown.Item onSelect={() => props.onEditTemplateClick(props.data)} className="dropdown-hover">
                    {"Edit Template"}
                  </Dropdown.Item>
                )}
                <Dropdown.Item onSelect={() => props.handleTemplateClone(props.data._id)} className="dropdown-hover">
                  {"Clone"}
                </Dropdown.Item>
                {props.viewType === "org" && (
                  <Dropdown.Item onSelect={() => props.onDeleteTenplateClick(props.data._id)} className="dropdown-hover">
                    {"Delete"}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <BlankSidePanel show={show} title={"Template Preview"} handleClose={() => setShow(false)}>
        <iframe title="Template Preview" className=" border border-1 rounded-1" srcDoc={props.data.content} width="100%" height="100%" />
      </BlankSidePanel>
    </div>
  );
}
