export const getListOfSchedulingPages = {
  GET_LIST_OF_SCHEDULING_PAGES_REQUEST: "GET_LIST_OF_SCHEDULING_PAGES_REQUEST",
  GET_LIST_OF_SCHEDULING_PAGES_SUCCESS: "GET_LIST_OF_SCHEDULING_PAGES_SUCCESS",
  GET_LIST_OF_SCHEDULING_PAGES_ERROR: "GET_LIST_OF_SCHEDULING_PAGES_ERROR",
};

export const getSelectedSchedulingPage = {
  GET_SELECTED_SCHEDULING_PAGE_REQUEST: "GET_SELECTED_SCHEDULING_PAGE_REQUEST",
  GET_SELECTED_SCHEDULING_PAGE_SUCCESS: "GET_SELECTED_SCHEDULING_PAGE_SUCCESS",
  GET_SELECTED_SCHEDULING_PAGE_ERROR: "GET_SELECTED_SCHEDULING_PAGE_ERROR",
};

export const createSchedulingPage = {
  CREATE_SCHEDULING_PAGE_REQUEST: "CREATE_SCHEDULING_PAGE_REQUEST",
  CREATE_SCHEDULING_PAGE_SUCCESS: "CREATE_SCHEDULING_PAGE_SUCCESS",
  CREATE_SCHEDULING_PAGE_ERROR: "CREATE_SCHEDULING_PAGE_ERROR",
};

export const updatedSchedulingPage = {
  UPDATE_SCHEDULING_PAGE_REQUEST: "UPDATE_SCHEDULING_PAGE_REQUEST",
  UPDATE_SCHEDULING_PAGE_SUCCESS: "UPDATE_SCHEDULING_PAGE_SUCCESS",
  UPDATE_SCHEDULING_PAGE_ERROR: "UPDATE_SCHEDULING_PAGE_ERROR",
};

export const deleteSchedulingPage = {
  DELETE_SCHEDULING_PAGE_REQUEST: "DELETE_SCHEDULING_PAGE_REQUEST",
  DELETE_SCHEDULING_PAGE_SUCCESS: "DELETE_SCHEDULING_PAGE_SUCCESS",
  DELETE_SCHEDULING_PAGE_ERROR: "DELETE_SCHEDULING_PAGE_ERROR",
};

export const getAvailableCalendars = {
  GET_AVAILABLE_CALENDARS_REQUEST: "GET_AVAILABLE_CALENDARS_REQUEST",
  GET_AVAILABLE_CALENDARS_SUCCESS: "GET_AVAILABLE_CALENDARS_SUCCESS",
  GET_AVAILABLE_CALENDARS_ERROR: "GET_AVAILABLE_CALENDARS_ERROR",
};

export const getS3SignedUrlForUpload = {
  GET_S3_SIGNED_URL_FOR_UPLOAD_REQUEST: "GET_S3_SIGNED_URL_FOR_UPLOAD_REQUEST",
  GET_S3_SIGNED_URL_FOR_UPLOAD_SUCCESS: "GET_S3_SIGNED_URL_FOR_UPLOAD_SUCCESS",
  GET_S3_SIGNED_URL_FOR_UPLOAD_ERROR: "GET_S3_SIGNED_URL_FOR_UPLOAD_ERROR",
};
