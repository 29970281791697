import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CampaignCommonSettings from "./settings";

export default function CampaignBuilderRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}`} component={CampaignCommonSettings} />
        <Route exact path={`${match.url}/:channel/:route`} component={CampaignCommonSettings} />
        <Redirect to={`${match.url}`} />
      </Switch>
    </>
  );
}
