import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { dispatchSnackbarSuccess } from "../../../../../utils/toaster";

const integrate = (slug) => `<iframe src="https://meeting.salessimplify.com/${slug}" width="100%" height="650px" ></iframe>`;

export default function CopyEmbeddedCodeModel({ show, handleClose, slug }) {
  const tabsList = ["Embed within webpage"];
  const [activeTab, setActiveTab] = useState(0);

  const onCopyToClipBoard = (type) => {
    var textField = document.createElement("textarea");
    textField.innerText = integrate(slug);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    dispatchSnackbarSuccess("Copied to Clipboard!");
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>Grab your meeting scheduler code</Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          {tabsList.map((tabItem, index) => (
            <div key={index} className={`${tabItem === tabsList[activeTab] ? "border-bottom-width-3px border-bottom-style-solid border-bottom-color-000000" : ""}`}>
              <button className={`btn tab-font ${tabItem === tabsList[activeTab] ? "fw-700" : ""}`} onClick={() => setActiveTab(index)}>
                {tabItem}
              </button>
            </div>
          ))}
        </div>
        <div>
          <p className="p-4 " id="script">
            <p className="m-2"> {"Present your meeting scheduler seamlessly as part of your page."}</p>
            <div className="card m-2 script-container min-height-inherit bg-fafafc">
              <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="3" value={integrate(slug)} />
              <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("embed")}>
                Copy To Clipboard
              </div>
            </div>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
