import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as productOperationsActions from "../actions/product-operations.actions";
import * as productActions from "../actions/actions";
import * as productOperationTypes from "../types/product-operations.types";

const endpoints = {
  BULK_EDIT_V2: "/api/crm/product/v2/bulk-edit",
  BULK_DELETE_V2: "/api/crm/product/v2/delete",
};

function* bulkEditProductV2(action) {
  try {
    const res = yield call(api.post, endpoints.BULK_EDIT_V2, action.payload.productData);
    if (res.status === 200) {
      yield put(productOperationsActions.bulkEditProductV2Success({ message: res.data.message }));
      yield put(productActions.productsRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(productOperationsActions.bulkEditProductV2Error({ message: res.data.message }));
    } else {
      yield put(productOperationsActions.bulkEditProductV2Error({ message: "Could Not Edit Products" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(productOperationsActions.bulkEditProductV2Error({ message: "Could Not Edit Products" }));
  }
}

function* bulkDeleteProductV2(action) {
  try {
    const res = yield call(api.patch, endpoints.BULK_DELETE_V2, action.payload.productIds);
    if (res.status === 200) {
      yield put(productOperationsActions.bulkDeleteProductV2Success({ message: res.data.message }));
      yield put(productActions.productsRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(productOperationsActions.bulkDeleteProductV2Error({ message: res.data.message }));
    } else {
      yield put(productOperationsActions.bulkDeleteProductV2Error({ message: "Could Not Delete Products" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(productOperationsActions.bulkDeleteProductV2Error({ message: "Could Not Delete Products" }));
  }
}

export default function* productOperationSaga() {
  yield takeLatest(productOperationTypes.bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_REQUEST, bulkDeleteProductV2);
  yield takeLatest(productOperationTypes.bulkEditProductV2.BULK_EDIT_PRODUCT_V2_REQUEST, bulkEditProductV2);
}
