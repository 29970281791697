import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import TimeZoneSelect from "../../../../../components/custom_form/timezone";
import MaterialUiTimePicker from "../../../../../components/material-ui/time-picker";
import { infoTextMessages } from "../constants";

const days = [
  { day: "Sunday", value: "S" },
  { day: "Monday", value: "M" },
  { day: "Tuesday", value: "T" },
  { day: "Wednesday", value: "W" },
  { day: "Thusday", value: "R" },
  { day: "Friday", value: "F" },
  { day: "Saturday", value: "U" },
];

export default function OpeningHoursForm(props) {
  const handleWeekDaySelection = (checked, value) => {
    if (checked) {
      const a = [...props.data.config.booking.opening_hours[0].days, value];
      updateOpeningHoursData("days", a);
    } else {
      const a = props.data.config.booking.opening_hours[0].days.filter((a) => a !== value);
      updateOpeningHoursData("days", a);
    }
  };

  const updateOpeningHoursData = (key, value) => {
    const data = {
      ...props.data,
      config: {
        ...props.data.config,
        booking: {
          ...props.data.config.booking,
          opening_hours: props.data.config.booking.opening_hours.map((each) => {
            each[key] = value;
            return each;
          }),
        },
      },
    };
    props.setSelectedData(data);
  };
  const updateData = (parentKey, key, value) => {
    if (parentKey) {
      const data = {
        ...props.data,
        config: {
          ...props.data.config,
          [parentKey]: {
            ...props.data.config[parentKey],
            [key]: value,
          },
        },
      };
      props.setSelectedData(data);
    } else {
      const data = {
        ...props.data,
        config: {
          ...props.data.config,
          [key]: value,
        },
      };
      props.setSelectedData(data);
    }
  };

  return (
    <Form.Group>
      <Form.Label>When events can be booked?</Form.Label>
      <div>
        {days.map((item) => (
          <Form.Check
            inline
            label={item.day}
            id={item.day}
            onChange={(e) => handleWeekDaySelection(e.target.checked, item.value)}
            type="checkbox"
            key={item.day}
            size="sm"
            className="mt-12px mr-12px"
            name={item.day}
            checked={props.data.config.booking.opening_hours[0].days.includes(item.value)}
          />
        ))}
      </div>
      <Form.Group className="mb-0 mt-4">
        <Form.Group className="d-flex gap-4 align-items-center mb-0">
          <Form.Group className="mb-0 w-32">
            <MaterialUiTimePicker label="From Time" value={props.data.config.booking.opening_hours[0].start} setValue={(e) => updateOpeningHoursData("start", e)} />
          </Form.Group>
          <Form.Group className="mb-0 w-32">
            <MaterialUiTimePicker label="End Time" value={props.data.config.booking.opening_hours[0].end} setValue={(e) => updateOpeningHoursData("end", e)} />
          </Form.Group>
          <Form.Group className="mb-0 w-32">
            <Form.Label aria-label="timezone">
              Timezone
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.TIMEZONE}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <TimeZoneSelect
              clearable={false}
              field={{ apiKeyName: "timezone", fieldName: "Timezone" }}
              value={props.data.config.timezone}
              setValue={(value) => {
                updateData("", "timezone", value);
              }}
            />
          </Form.Group>
        </Form.Group>
      </Form.Group>
    </Form.Group>
  );
}
