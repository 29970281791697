/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import "./style.css";
import CustomDisplay from "./data";
import { useSelector } from "react-redux";
import { DeleteModal, ErrorLogModal } from "../modal";
import { DeleteIconButton } from "../button/icon-action-buttons";
import { CustomEmptyPage } from "../empty_page/custom-empty-page";
import TableActions from "./table-actions";
import InlineStyled from "../inline-styled/inline-styled";

const CustomPagination = (active, setActive, pages, setChecked, paginationNumber, setPaginationNumber, rows) => {
  var items = [];
  if (pages <= 7) {
    for (let number = 1; number <= pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => {
            setActive(number);
            setChecked([]);
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
  } else {
    items.push(
      <Pagination.First
        key={0}
        onClick={() => {
          setActive(1);
          setChecked([]);
        }}
      />,
    );
    items.push(
      <Pagination.Prev
        onClick={() => {
          if (active !== 1) setActive(active - 1);
        }}
      />,
    );
    if (active === 1 || active === 2) {
      items.push(
        <Pagination.Item
          key={1}
          active={active === 1}
          onClick={() => {
            setActive(1);
            setChecked([]);
          }}
        >
          1
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={2}
          active={active === 2}
          onClick={() => {
            setActive(2);
            setChecked([]);
          }}
        >
          2
        </Pagination.Item>,
      );
      // items.push(<Pagination.Item key={3} active={false} onClick={() => { setActive(3); setChecked([]) }}>3</Pagination.Item>)
      // items.push(<Pagination.Item key={4} active={false} onClick={() => { setActive(4); setChecked([]) }}>4</Pagination.Item>)
    } else {
      // items.push(<Pagination.Ellipsis />)
      if (active === pages) {
        // items.push(<Pagination.Item key={active - 3} active={false} onClick={() => { setActive(active - 3); setChecked([]) }}>{active - 3}</Pagination.Item>)
        items.push(
          <Pagination.Item
            key={active - 2}
            active={false}
            onClick={() => {
              setActive(active - 2);
              setChecked([]);
            }}
          >
            {active - 2}
          </Pagination.Item>,
        );
      } else if (active === pages - 1)
        items.push(
          <Pagination.Item
            key={active - 2}
            active={false}
            onClick={() => {
              setActive(active - 2);
              setChecked([]);
            }}
          >
            {active - 2}
          </Pagination.Item>,
        );
      items.push(
        <Pagination.Item
          key={active - 1}
          active={false}
          onClick={() => {
            setActive(active - 1);
            setChecked([]);
          }}
        >
          {active - 1}
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={active}
          active={true}
          onClick={() => {
            setActive(active);
            setChecked([]);
          }}
        >
          {active}
        </Pagination.Item>,
      );
      if (active !== pages) {
        items.push(
          <Pagination.Item
            key={active + 1}
            active={false}
            onClick={() => {
              setActive(active + 1);
              setChecked([]);
            }}
          >
            {active + 1}
          </Pagination.Item>,
        );
      }
    }
    if (active !== pages - 1 && active !== pages)
      // items.push(<Pagination.Ellipsis />)
      items.push(
        <Pagination.Next
          onClick={() => {
            if (active !== pages) setActive(active + 1);
          }}
        />,
      );
    items.push(
      <Pagination.Last
        key={pages + 1}
        onClick={() => {
          setActive(pages);
          setChecked([]);
        }}
      />,
    );
  }
  return (
    <div className="d-flex flex-row align-items-center gap-15px mt-5px">
      {pages === 1 ? null : (
        <ul id="table_pagination" className="d-flex flex-row pl-0 m-0 z-index-0 list-unstyled border-radius-7px">
          {items}
        </ul>
      )}
    </div>
  );
};

export function NormalTable({ props }) {
  const tempData = props.data;
  const [sortConfig, setSortConfig] = React.useState(null);
  const [sortedTemp, setSortedTemp] = useState([]);
  const ownerList = useSelector((state) => state.People.ownerList.list);
  const [deletedItem, setDeletedItem] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [logData, setLogData] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  // Sort by column
  useEffect(() => {
    let tempSort = [...tempData];
    if (sortConfig !== null) {
      var x, y;
      tempSort.sort((a, b) => {
        if (sortConfig.fieldInputType === "OWNER_SELECT") {
          x = ownerList.find((owner) => owner["_id"] === a[sortConfig.key]);
          y = ownerList.find((owner) => owner["_id"] === b[sortConfig.key]);
          if (x && y) {
            if (x.fullName < y.fullName) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (x.fullName > y.fullName) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedTemp(tempSort);
  }, [tempData, sortConfig, ownerList]);

  const requestSort = (key, fieldInputType) => {
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") direction = "descending";
    else if (sortConfig && sortConfig.key === key && sortConfig.direction === "descending") {
      setSortConfig(null);
      return;
    }
    setSortConfig({ key, direction, fieldInputType });
  };

  const getData = (col, data) => {
    return <CustomDisplay allData={data} data={data[col.apiKeyName]} showDownloadIcon={data.status === "Completed" ? true : false} field={col} type={col.fieldInputType} />;
  };

  const ondeleteModalAccept = () => {
    props.callDeleteImportDataApi(deletedItem);
    setShowDeleteModal(false);
  };
  const setMultipleLogs = (logs) => {
    setLogData(logs);
  };
  const onDownloadLogs = () => {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(logData.join("\n")));
    element.setAttribute("download", "error-transcript.txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  // check/uncheck row
  const editChecked = (rowId) => {
    if (props.checked && !props.checked.includes(rowId)) props.setChecked([...props.checked, rowId]);
    else if (props.setChecked) {
      props.setChecked(props.checked.filter((check) => check !== rowId));
      setAllChecked(false);
    }
  };
  const checkAll = () => {
    if (!props.checked) return;
    if (props.checked.length === tempData.length) {
      props.setChecked([]);
    } else {
      let temp = [];
      tempData.map((data) => temp.push(data["_id"]));
      props.setChecked(temp);
    }
  };
  const handleTableActions = (actionName, data) => {
    if (props.handleTableActions) {
      props.handleTableActions(actionName, data);
    }
  };

  return (
    <>
      {props.loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div className="table-responsive pl-3 pt-2 overflow-y-auto">
            <div className="scroll overflow-auto h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
              <table className="table align-middle table-row-dashed dataTable no-footer font-size-14px">
                <thead>
                  <tr className="fw-bolder text-muted">
                    {props.checkboxes ? (
                      <th className="opacity-0-point-8 text-black bg-white pt-0 pl-2 w-25px z-index-1 position-sticky top-0">
                        <label className="checkbox checkbox-lg checkbox-single">
                          <input className="form-check-input" type="checkbox" value="1" onChange={() => checkAll()} checked={props.checked && props.checked.length === tempData.length} />
                          <span></span>
                        </label>
                      </th>
                    ) : null}
                    {props.columns.map((col, index) => (
                      <InlineStyled
                        as="th"
                        onClick={() => requestSort(col["apiKeyName"], col["fieldInputType"])}
                        key={index}
                        styles={{
                          minWidth: `${100 / props.columns.length}%`,
                        }}
                        className="bg-white pt-0 cursor-pointer text-nowrap position-sticky top-0"
                      >
                        <div className="d-flex flex-row w-100 h-100 align-items-center gap-5px text-black">
                          {col.fieldName}{" "}
                          {sortConfig && col["apiKeyName"] === sortConfig.key ? (
                            sortConfig.direction === "ascending" ? (
                              <i className="fa fa-arrow-up fa-sm"></i>
                            ) : (
                              <i className="fa fa-arrow-down fa-sm"></i>
                            )
                          ) : (
                            <div className="h-100 w-10px"></div>
                          )}
                        </div>
                      </InlineStyled>
                    ))}
                    {props.showDeleteAction ? (
                      <InlineStyled
                        as="th"
                        styles={{
                          minWidth: `${100 / props.columns.length}%`,
                        }}
                        className="bg-white pt-0 cursor-pointer text-nowrap position-sticky top-0"
                      >
                        <div className="d-flex flex-row w-100 h-100 align-items-center gap-5px text-black">Actions</div>
                      </InlineStyled>
                    ) : null}
                    {props.tableActions && props.tableActions.length > 0 && (
                      <InlineStyled
                        as="th"
                        className="bg-white pt-0 cursor-pointer text-nowrap position-sticky top-0"
                        styles={{
                          minWidth: `${100 / props.columns.length}%`,
                        }}
                      >
                        <div className="d-flex flex-row w-100 h-100 align-items-center gap-5px text-black">Actions</div>
                      </InlineStyled>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedTemp.map((data, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {props.checkboxes ? (
                          <td className="pl-2 activity-status-color" onClick={(e) => e.stopPropagation()}>
                            <label className="checkbox checkbox-lg checkbox-single">
                              <input className="form-check-input" type="checkbox" value="1" onChange={(e) => editChecked(data["_id"])} checked={(props.checked && props.checked.includes(data["_id"])) || allChecked} />
                              <span></span>
                            </label>
                          </td>
                        ) : null}
                        {props.columns.map((col, index) => (
                          <td className="text-nowrap" key={index}>
                            <div className="d-flex flex-row flex-stack">{getData(col, data)}</div>
                          </td>
                        ))}
                        {props.showDeleteAction ? (
                          <td className="text-nowrap">
                            {props.showLogOptions && data.errorMsgs && data.errorMsgs.length > 0 ? (
                              <span className="import-icon-container">
                                <i
                                  className="bi bi-info-circle text-F64E60"
                                  onClick={() => {
                                    setMultipleLogs(data.errorMsgs);
                                    setShowLogModal(true);
                                  }}
                                ></i>
                              </span>
                            ) : (
                              <span className="empty-icon-container"></span>
                            )}
                            <DeleteIconButton
                              onClick={() => {
                                setShowDeleteModal(true);
                                setDeletedItem(data);
                              }}
                            />
                          </td>
                        ) : null}

                        {props.tableActions && props.tableActions.length > 0 && (
                          <td>
                            {props.tableActions.map((actionName, inindex) => {
                              return <TableActions handleTableActions={handleTableActions} data={data} actionName={actionName} key={inindex} />;
                            })}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                  {sortedTemp.length === 0 && (
                    <tr>
                      <th className="p-0 m-0 text-center" colSpan="100%">
                        {" "}
                        <CustomEmptyPage page="emptyTable" />
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-15px pt-7px">
            <p className="mt-5px mb-0 font-size-14px">
              Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
            </p>
            {props.pagination ? CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), props.setChecked, props.limit, props.setLimit, props.count) : null}
          </div>
        </>
      )}
      <DeleteModal
        modalProps={{
          show: showDeleteModal,
          title: "Delete Imported Data",
          handleClose: () => {
            setShowDeleteModal(false);
          },
          handleAccept: () => ondeleteModalAccept(),
        }}
      >
        After deleting history it will delete all imported data. Are you sure, You want to delete imported data.
      </DeleteModal>
      <ErrorLogModal
        modalProps={{
          show: showLogModal,
          title: "Import Error Logs",
          handleClose: () => {
            setShowLogModal(false);
          },
          onClickDownloadLog: () => {
            onDownloadLogs();
            setShowLogModal(false);
          },
        }}
      >
        <div className="overflow-auto h-60vh-mx">
          {logData.map((each, index) => {
            return (
              <div key={index} className="p-2px">
                <span className="text-019bec">{"> "}</span> {each}
              </div>
            );
          })}
        </div>
      </ErrorLogModal>
    </>
  );
}
