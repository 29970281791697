//packages
import React from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

//components
import { BlueGradientButton } from "../button";

//constants
import { constants, themes } from "./constants";

export function CustomEmptyPage(props) {
  const handleOutsideLinkClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <Card className={`${props.hideBorder ? "border-0" : ""} ${themes[constants[props.page].theme].heightClass} ${themes[constants[props.page].theme].background}`}>
      <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
        <div className="card-px text-center py-5 my-5">
          <h2 className="fs-2x fw-bolder mb-10 mt-5">{constants[props.page].heading}</h2>
          <div className="text-gray-700 fs-5 mb-8 w-50vw-mx">
            <p>{constants[props.page].description}</p>
          </div>
          <div className="d-flex gap-4 flex-center">
            {props.customButton ? (
              <div> {props.customButton}</div>
            ) : (
              constants[props.page].buttons.map((each, index) => {
                if (each.type === "event")
                  return (
                    <BlueGradientButton key={index} size="sm" onClick={() => props.setShowAdd(true)}>
                      {each.text}
                    </BlueGradientButton>
                  );
                else if (each.type === "link")
                  return (
                    <Button key={index} variant="secondary" size="sm" onClick={() => handleOutsideLinkClick(each.link)}>
                      {each.text}
                    </Button>
                  );
                else if (each.type === "internal-link") {
                  return (
                    <NavLink key={index} to={each.link}>
                      <Button variant="secondary" size="sm">
                        {each.text}
                      </Button>
                    </NavLink>
                  );
                } else if (each.type === "internal-link-primary") {
                  return (
                    <NavLink key={index} to={each.link}>
                      <BlueGradientButton size="sm">{each.text}</BlueGradientButton>
                    </NavLink>
                  );
                } else return <div key={index}></div>;
              })
            )}
          </div>
        </div>
        <img src={constants[props.page].image} className={`mx-auto ${themes[constants[props.page].theme].imageHeightClass}`} alt="add" />
      </Card.Body>
    </Card>
  );
}
