import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../style.css";
import InlineStyled from "../../inline-styled/inline-styled";

export default function TagDisplay({ value, sidePanel }) {
  const [tags, setTags] = useState([]);

  const companyTagStore = useSelector((state) => state.Companies.companyTags);
  const dealTagStore = useSelector((state) => state.DealPipeline.dealTags);
  const leadTagStore = useSelector((state) => state.Leads.leadTags);
  const peopleTagStore = useSelector((state) => state.People.peopleTags);
  const productTagStore = useSelector((state) => state.Products.productTags);
  const marketingTagStore = useSelector((state) => state.campaign.marketing.marketingList.tags);

  useEffect(() => {
    const _tags = [],
      tagIds = [];
    if (value && typeof value === "object") {
      if (companyTagStore && companyTagStore.list && companyTagStore.list.length > 0) {
        companyTagStore.list.forEach((each) => {
          value.forEach((_each) => {
            if (_each === each._id && tagIds.indexOf(each._id) === -1) {
              _tags.push(each);
              tagIds.push(each._id);
            }
          });
        });
      }
      if (dealTagStore && dealTagStore.list && dealTagStore.list.length > 0) {
        dealTagStore.list.forEach((each) => {
          value.forEach((_each) => {
            if (_each === each._id && tagIds.indexOf(each._id) === -1) {
              _tags.push(each);
              tagIds.push(each._id);
            }
          });
        });
      }
      if (leadTagStore && leadTagStore.list && leadTagStore.list.length > 0) {
        leadTagStore.list.forEach((each) => {
          value.forEach((_each) => {
            if (_each === each._id && tagIds.indexOf(each._id) === -1) {
              _tags.push(each);
              tagIds.push(each._id);
            }
          });
        });
      }
      if (peopleTagStore && peopleTagStore.list && peopleTagStore.list.length > 0) {
        peopleTagStore.list.forEach((each) => {
          value.forEach((_each) => {
            if (_each === each._id && tagIds.indexOf(each._id) === -1) {
              _tags.push(each);
              tagIds.push(each._id);
            }
          });
        });
      }
      if (productTagStore && productTagStore.list && productTagStore.list.length > 0) {
        productTagStore.list.forEach((each) => {
          value.forEach((_each) => {
            if (_each === each._id && tagIds.indexOf(each._id) === -1) {
              _tags.push(each);
              tagIds.push(each._id);
            }
          });
        });
      }
      if (marketingTagStore && marketingTagStore.list && marketingTagStore.list.length > 0) {
        marketingTagStore.list.forEach((each) => {
          value.forEach((_each) => {
            if (_each === each._id && tagIds.indexOf(each._id) === -1) {
              _tags.push(each);
              tagIds.push(each._id);
            }
          });
        });
      }
    }

    setTags(_tags);
  }, [companyTagStore, dealTagStore, leadTagStore, peopleTagStore, productTagStore, marketingTagStore, value]);

  return (
    <div className={sidePanel ? "data-sidepanel-flex-container" : "data-flex-container"}>
      {tags
        ? tags.map((tag, index) =>
            tag ? (
              <InlineStyled key={index} styles={{ backgroundColor: tag.colorCode }} title={tag.name} className="text-333 badge badge-dark tag-ellipsis rounded-pill m-0px">
                {tag.name}
                <br />
              </InlineStyled>
            ) : (
              ""
            ),
          )
        : null}
    </div>
  );
}
