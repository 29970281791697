import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { GreenGradientButton } from "../../../../../components/button";
import OwnerField from "../component/owner.field";

import * as ActivityActions from "../../actions/activity_action";
import * as activityListActions from "../../../../Activities/store/actions/activity_types_actions";

import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { activityTimeArray } from "../constants";
import { InputLimit } from "../../../../../constants";
import { CustomActivityButtonGroup } from "../../../../../components/button/custom-group-button";

export default function ScheduleActivity(props) {
  const [loading, setLoading] = useState(false);
  const [activityText, setActivityText] = useState("");
  const [activityType, setActivityType] = useState("");
  const [activeIcon, setActiveIcon] = useState("");
  const [inputIconFields, setInputIconFields] = useState([]);
  const [descriptionText, setDescriptionText] = useState("");
  const [owner, setOwner] = useState({ type: "automatic" });
  const [timeRange, setTimeRange] = useState("");
  const [timeButton, setTimeButton] = useState("");
  // const [guests, setGuests] = useState("")
  // const [emailError, setEmailError] = useState("");
  const [tags, setTags] = useState([]);

  const dispatch = useDispatch();

  const activityStore = useSelector((store) => store.activity.types.active.list);

  useEffect(() => {
    dispatch(activityListActions.getListOfActiveActivityTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    setInputIconFields(activityStore);
  }, [activityStore]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActionData) && props.selectedActionData.id) {
      setLoading(true);
      dispatch(
        ActivityActions.getActivityActionByIdRequest({ id: props.selectedActionData.id }, (data) => {
          if (data.success) {
            const activityData = data.data;
            setActivityText(activityData.activityName);
            setActivityType(activityData.activityType.id);
            setActiveIcon(activityData.activityType.name);
            setDescriptionText(activityData.description);
            setOwner(activityData.owner);
            // setGuests(!_.isEmpty(activityData.guests) ? activityData.guests.join(", ") : [])
            setTags(activityData.tags);
            const time = new Date(activityData.time).getTime();
            setTimeRange(time);
            activityTimeArray.forEach((each) => {
              if (each.duration === time) {
                setTimeButton(each.key);
              }
            });
          }
          setLoading(false);
        }),
      );
    }
  }, [dispatch, props.selectedActionData, props.isEdit]);

  const onIconFieldChange = (field) => {
    setActiveIcon(field.name);
    setActivityType(field._id);
    setActivityText(field.name);
  };

  const onDescriptionChange = (e) => {
    setDescriptionText(e.target.value);
  };

  const onActivitySaveClick = () => {
    if (!activityType || !activityText || !timeRange) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    } else if (_.isEmpty(owner) || !["automatic", "roundRobbin", "single"].includes(owner.type)) {
      return dispatchSnackbarError("Select Owner Data!");
    } else if (["roundRobbin", "single"].includes(owner.type) && _.isEmpty(owner.id)) {
      return dispatchSnackbarError("Select owner from owner dropdown!!!");
    }

    const data = {
      module: {
        name: props.moduleName,
      },
      activityName: activityText,
      description: descriptionText,
      activityType: {
        id: activityType,
        name: activeIcon,
      },
      time: timeRange,
      timeDisplayValue: timeButton,
      guests: [], // guests.split(",").map(each => each.trim()),
      tags: tags,
      owner: owner,
    };

    if (props.isEdit) props.handleEditSave(props.selectedActionData.id, data);
    else props.handleCreateSave(data);
  };

  const ontextChange = (e) => {
    setActivityText(e.target.value);
  };

  const onTimeSelect = (each) => {
    setTimeRange(each.duration);
    setTimeButton(each.key);
  };

  // const validateEmail = (email) => {
  //     const re = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
  //     return re.test(String(email).toLowerCase());
  // }

  // const onGuestsChange = (e) => {
  //     setGuests(e.target.value);
  //     let isValid = true
  //     const valueArray = e.target.value.split(",");
  //     valueArray.forEach(each => {
  //         if (!validateEmail(each.trim())) {
  //             isValid = false
  //         }
  //     })
  //     if (!isValid) setEmailError(true);
  //     else setEmailError(false);
  // }

  if (loading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center h-vh-162px">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <>
      <Form>
        <Form.Group id="automation-workflow-builder-activity-form-type">
          <Form.Label aria-label="activity-type">Activity Type*</Form.Label>
          <CustomActivityButtonGroup options={inputIconFields} handleClick={onIconFieldChange} active={activeIcon} />
        </Form.Group>
        <Form.Group id="automation-workflow-builder-activity-form-name">
          <Form.Label aria-label="activity-name">Activity Name*</Form.Label>
          <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={activeIcon} value={activityText} onChange={(e) => ontextChange(e)} />
        </Form.Group>
        <Form.Group id="automation-workflow-builder-activity-form-time">
          <Form.Label aria-label="time">Time*</Form.Label>
          <div className="d-flex flex-row gap-5px">
            {activityTimeArray.map((each) => {
              return (
                <Button
                  className={timeButton === each.key ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm border color-black"}
                  onClick={() => onTimeSelect(each)}
                >
                  {each.name}
                </Button>
              );
            })}
          </div>
        </Form.Group>

        <Form.Group id="automation-workflow-builder-activity-form-description">
          <Form.Label aria-label="description">Description</Form.Label>
          <textarea value={descriptionText} className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => onDescriptionChange(e)}></textarea>
        </Form.Group>
        {/* <Form.Group>
                    <Form.Label>Guests</Form.Label>
                    <Form.Control maxLength={InputLimit.EMAIL} type="email" placeholder="Enter email" onChange={(e) => onGuestsChange(e)} />
                    {emailError && <div className="text-red" >{"Please Enter valid email id!!!"}</div>}
                </Form.Group> */}
        <Form.Group id="automation-workflow-builder-activity-form-owner-field">
          <OwnerField value={owner} setValue={setOwner} />
        </Form.Group>
        <Form.Group id="automation-workflow-builder-activity-form-save" className="d-flex justify-content-start">
          <GreenGradientButton size="sm" onClick={onActivitySaveClick}>
            {"Save"}
          </GreenGradientButton>
        </Form.Group>
      </Form>
    </>
  );
}
