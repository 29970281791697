import React, { useMemo } from "react";
// import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import SainoLogo from "../../../../images/logo/sales-simplify-logo.svg";
import { useHistory } from "react-router-dom";
// import SainoLogo1 from '../../../../images/sales_s_icon_300.png';

export function Brand() {
  const uiService = useHtmlClassService();

  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  const handleBrandClick = () => {
    history.push("/activity/activity-list");
  };

  return (
    <>
      {/* begin::Brand */}
      <div className={`brand flex-column-auto aside-newtheme p-0 ${layoutProps.brandClasses}`} id="kt_brand">
        <div onClick={handleBrandClick} className="d-flex justify-content-center align-items-center cursor-pointer w-50px h-50px border-rounded p-4px">
          <img alt="saino" src={SainoLogo} className="h-45px mt-15px" />
        </div>
        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0 d-none" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
