import React from "react";
import { Spinner } from "react-bootstrap";

export function SubHeaderLoading() {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center h-100">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export function ContentWithCardLoading() {
  return (
    <div className={`card card-custom`}>
      <div className="card-body py-3 px-3">
        <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    </div>
  );
}

export const ContentLoadingWithoutCard = () => (
  <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
    <Spinner animation="border" variant="primary" />
  </div>
);

export function ContentWithCardLoadingForSettings() {
  return (
    <div className={`card card-custom`}>
      <div className="card-body py-3 px-3">
        <div className="w-100 d-flex justify-content-center align-items-center h-vh-300px-mn">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    </div>
  );
}
