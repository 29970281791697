import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PopoverFilterWrapper from "../../../../../components/filter/popover-filter";
import { DateRangePicker } from "react-dates";
import moment from "moment-timezone";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { useSelector } from "react-redux";

const lastResponseOptions = [
  { value: "ALL", label: "All" },
  { value: "CREATED", label: "Activity Created" },
  { value: "COMPLETED", label: "Activity Completed" },
  { value: "EMAIL_SENT", label: "Email Sent" },
  { value: "OPENED", label: "Email Opened" },
  { value: "CLICKED", label: "Email Clicked" },
  { value: "REPLIED", label: "Email Replied" },
  { value: "UNSUBSCRIBED", label: "Email Unsubscribed" },
  { value: "SMS_SENT", label: "SMS Sent" },
  { value: "DELIVERED", label: "SMS Delivered" },
  { value: "UNDELIVERED", label: "SMS Undelivered" },
  { value: "ERRORED", label: "Errored" },
];

const subsccriberStatusOptions = [
  { value: "ALL", label: "All" },
  { value: "PENDING", label: "Pending" },
  { value: "ACTIVE", label: "Active" },
  { value: "PAUSED", label: "Paused" },
  { value: "FAILED", label: "Failed" },
  { value: "FINISHED", label: "Finished" },
];

export default function CadenceSubscriberFilter(props) {
  const [addedDatefrom, setAddedDatefrom] = useState(null);
  const [addedDateto, setAddedDateto] = useState(null);
  const [addedDateFocusedInput, setAddedDateFocusedInput] = useState(null);
  const [lastActivityDatefrom, setLastActivityDatefrom] = useState(null);
  const [lastActivityDateto, setLastActivityDateto] = useState(null);
  const [lastActivityDateFocusedInput, setLastActivityDateFocusedInput] = useState(null);
  const [lastResponse, setLastResponse] = useState({ value: "ALL", label: "All" });
  const [nextStep, setNextStep] = useState({ value: "ALL", label: "All" });
  const [nextStepOptions, setNextStepOptions] = useState([]);
  const [status, setStatus] = useState({ value: "ALL", label: "All" });

  // selectors
  const getSelectedCadenceStore = useSelector((store) => store.cadence.selected);

  useEffect(() => {
    if (!_.isEmpty(getSelectedCadenceStore.data)) {
      const steps = getSelectedCadenceStore.data.steps;
      const options = _.sortBy(steps, ["executionOrder"]).map((each) => ({
        value: each._id,
        label: `Step ${each.executionOrder} - ${each.channelType}`,
      }));
      setNextStepOptions([{ value: "ALL", label: "All" }, ...options]);
    }
  }, [getSelectedCadenceStore.data]);

  useEffect(() => {
    if (!_.isEmpty(props.filterValue)) {
      if (props.filterValue.addedDatefrom) {
        setAddedDatefrom(
          moment(props.filterValue.addedDatefrom)
            .startOf("day")
            .valueOf(),
        );
      }
      if (props.filterValue.addedDateto) {
        setAddedDateto(
          moment(props.filterValue.addedDateto)
            .endOf("day")
            .valueOf(),
        );
      }
      if (props.filterValue.lastActivityDatefrom) {
        setLastActivityDatefrom(
          moment(props.filterValue.lastActivityDatefrom)
            .startOf("day")
            .valueOf(),
        );
      }
      if (props.filterValue.lastActivityDateto) {
        setLastActivityDateto(
          moment(props.filterValue.lastActivityDateto)
            .endOf("day")
            .valueOf(),
        );
      }
      if (props.filterValue.status) {
        setStatus(_.find(subsccriberStatusOptions, { value: props.filterValue.status }));
      }
      if (props.filterValue.lastResponse) {
        setLastResponse(_.find(lastResponseOptions, { value: props.filterValue.lastResponse }));
      }
      if (props.filterValue.nextStep) {
        setNextStep(_.find(nextStepOptions, { value: props.filterValue.nextStep }));
      }
    }
  }, [props.filterValue, nextStepOptions]);

  const handleApplyFilter = () => {
    const payload = {
      status: status.value,
      lastResponse: lastResponse.value,
      nextStep: nextStep.value,
      addedDatefrom: addedDatefrom
        ? moment(addedDatefrom)
            .startOf("day")
            .valueOf()
        : null,
      addedDateto: addedDateto
        ? moment(addedDateto)
            .endOf("day")
            .valueOf()
        : null,
      lastActivityDatefrom: lastActivityDatefrom
        ? moment(lastActivityDatefrom)
            .startOf("day")
            .valueOf()
        : null,
      lastActivityDateto: lastActivityDateto
        ? moment(lastActivityDateto)
            .endOf("day")
            .valueOf()
        : null,
    };
    props.handleApplyFilter(payload);
  };

  const handleResetFilter = () => {
    setAddedDatefrom(null);
    setAddedDateto(null);
    setAddedDateFocusedInput(null);
    setLastActivityDatefrom(null);
    setLastActivityDateto(null);
    setLastActivityDateFocusedInput(null);
    setLastResponse({ value: "ALL", label: "All" });
    setNextStep({ value: "ALL", label: "All" });
    setStatus({ value: "ALL", label: "All" });
  };
  return (
    <PopoverFilterWrapper
      show={props.show}
      setShow={props.setShow}
      label={"Filter"}
      onSaveClick={handleApplyFilter}
      onCancelClick={props.handleCancelFilter}
      showCancel={true}
      showSave={true}
      saveButtonText={"Apply"}
      cancelButtonText={"Cancel"}
      showReset={true}
      onResetClick={handleResetFilter}
      buttonType={"secondary"}
    >
      <Form className="w-350px">
        <Form.Group>
          <Form.Label>Last Response</Form.Label>
          <SimpleDropdown options={lastResponseOptions} value={lastResponse} handleSelect={setLastResponse} label={"Select Last Response"} showSearch={true} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Next Step</Form.Label>
          <SimpleDropdown options={nextStepOptions} value={nextStep} handleSelect={setNextStep} label={"Select Next Step"} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <SimpleDropdown options={subsccriberStatusOptions} value={status} handleSelect={setStatus} label={"Select Subscriber Status"} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Added Date (Optional)</Form.Label>
          <DateRangePicker
            startDate={addedDatefrom ? moment(addedDatefrom) : null}
            startDateId="addedDatefrom"
            endDate={addedDateto ? moment(addedDateto) : null}
            endDateId="addedDateto"
            onDatesChange={({ startDate, endDate }) => {
              const _startDate = startDate ? new Date(startDate).getTime() : null;
              const _endDate = endDate ? new Date(endDate).getTime() : null;
              setAddedDatefrom(_startDate);
              setAddedDateto(_endDate);
            }}
            displayFormat={"YYYY-MM-DD"}
            small={true}
            isOutsideRange={() => false}
            focusedInput={addedDateFocusedInput}
            onFocusChange={(focusedInput) => {
              setAddedDateFocusedInput(focusedInput);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Activity Date (Optional)</Form.Label>
          <DateRangePicker
            startDate={lastActivityDatefrom ? moment(lastActivityDatefrom) : null}
            startDateId="lastActivityDatefrom"
            endDate={lastActivityDateto ? moment(lastActivityDateto) : null}
            endDateId="lastActivityDateto"
            onDatesChange={({ startDate, endDate }) => {
              const _startDate = startDate ? new Date(startDate).getTime() : null;
              const _endDate = endDate ? new Date(endDate).getTime() : null;
              setLastActivityDatefrom(_startDate);
              setLastActivityDateto(_endDate);
            }}
            displayFormat={"YYYY-MM-DD"}
            small={true}
            isOutsideRange={() => false}
            focusedInput={lastActivityDateFocusedInput}
            onFocusChange={(focusedInput) => {
              setLastActivityDateFocusedInput(focusedInput);
            }}
          />
        </Form.Group>
      </Form>
    </PopoverFilterWrapper>
  );
}
