import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as overviewActions from "../../actions/profile-actions/overview-settings.actions";
import * as overviewTypes from "../../types/profile-settings.types";
import { endpoints } from "../../endpoint";

function* getProfileDetailsFunction(action) {
  try {
    const res = yield call(api.get, endpoints.GET_USER_PROFILE);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(overviewActions.getUserProfileSuccess(res.data));
    } else {
      yield put(overviewActions.getUserProfileError("error"));
    }
  } catch (e) {
    yield put(overviewActions.getUserProfileError(e));
  }
}

function* updateUserProfileFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.GET_USER_PROFILE, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(overviewActions.updateUserProfileSuccess(res.data));
    } else {
      yield put(overviewActions.updateUserProfileError("error"));
    }
  } catch (e) {
    //
    yield put(overviewActions.updateUserProfileError(e));
  }
}

function* AvatarPicFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.PROFILE_AVATAR, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(overviewActions.patchUserAvatarPicSuccess(res.data));
      yield put(overviewActions.getUserProfileRequest(() => {}));
    } else {
      yield put(overviewActions.patchUserAvatarPicError("error"));
    }
  } catch (e) {
    //
    yield put(overviewActions.patchUserAvatarPicError(e));
  }
}

export default function* overviewSaga() {
  yield takeLatest(overviewTypes.getUserProfile.GET_USER_PROFILE_REQUEST, getProfileDetailsFunction);
  yield takeLatest(overviewTypes.updateUserProfile.UPDATE_USER_PROFILE_REQUEST, updateUserProfileFunction);
  yield takeLatest(overviewTypes.patchUserAvatarPic.PATCH_USER_AVATAR_PIC_REQUEST, AvatarPicFunction);
}
