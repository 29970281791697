import _ from "lodash";
import React, { useEffect, useState } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customColorMapping } from "../../../../components/custom-tables/constants";
import OwnerDisplay from "../../../../components/table/displays/ownerDisplay";
import { DeleteModal } from "../../../../components/modal";
import * as CampaignListActions from "../../store/actions/campaign_details_actions";
import { useDispatch } from "react-redux";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export default function SplitDetailsComponent(props) {
  const [tablehead] = useState(["Batch No.", "Scheduled Time", "Audience", "Status"]);
  const [mappingTable] = useState({
    "Batch No.": "batchNo",
    "Scheduled Time": "scheduledOn",
    Audience: "audience",
    Status: "status",
  });
  const [dataType] = useState({
    batchNo: "TEXT",
    scheduledOn: "DATE",
    audience: "TEXT",
    status: "TAG",
  });
  const [tablecontent, setTableContent] = useState([]);
  const [analytics, setAnalytics] = useState({ total: 0, completed: 0, pending: 0, cancelled: 0 });
  const [showCancelCampaign, setShowCancelCampaign] = useState(false);
  const [selectedBatchNo, setSelectedBatchNo] = useState(0);
  const [formLoading, setFormLoading] = useState(false);

  const dispatch = useDispatch();

  const isCampaignCancellable = (scheduledDate, status) => {
    if (["completed", "cancelled"].includes(status)) return false;
    return (
      moment(scheduledDate).valueOf() >
      moment()
        .add(5, "minutes")
        .valueOf()
    );
  };

  const getAudienceCountObj = (totalAudience, batchSize, tasks) => {
    const batchObj = {};
    let remainingAudience = totalAudience;
    if (Array.isArray(tasks) && tasks.length > 0 && tasks[0].batchNo === 2) {
      batchObj[1] = totalAudience > batchSize ? batchSize : totalAudience;
      remainingAudience = remainingAudience - (totalAudience > batchSize ? batchSize : totalAudience);
    }

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].status === "cancelled") {
        batchObj[tasks[i].batchNo] = 0;
      } else {
        const currentSize = remainingAudience > batchSize ? batchSize : remainingAudience;
        batchObj[tasks[i].batchNo] = currentSize;
        remainingAudience = remainingAudience - currentSize;
      }
    }
    return batchObj;
  };

  useEffect(() => {
    const { effectiveDate, timezone, scheduledDate, isScheduled, validAudience } = props.campaignDetails;
    const _analytics = { total: 0, completed: 0, pending: 0, cancelled: 0 };

    if (!_.isEmpty(props.splitDetails)) {
      const options = [];
      _analytics.total = _analytics.total + 1;
      const audienceCountObj = getAudienceCountObj(validAudience, props.splitDetails.cantactPerBatch, props.splitDetails.tasks);
      if (!(timezone && scheduledDate && isScheduled)) {
        if (moment(effectiveDate).valueOf() > moment().valueOf()) {
          _analytics.pending = _analytics.pending + 1;
          options.push({
            batchNo: 1,
            scheduledOn: effectiveDate,
            audience: audienceCountObj[1],
            status: "scheduled",
            actions: isCampaignCancellable(effectiveDate) ? ["cancel"] : [],
          });
        } else {
          _analytics.completed = _analytics.completed + 1;
          options.push({
            batchNo: 1,
            scheduledOn: effectiveDate,
            audience: audienceCountObj[1],
            status: "completed",
            actions: isCampaignCancellable(effectiveDate) ? ["cancel"] : [],
          });
        }
      }

      props.splitDetails.tasks.forEach((each) => {
        _analytics.total = _analytics.total + 1;
        options.push({
          batchNo: each.batchNo,
          scheduledOn: each.scheduledOn,
          audience: audienceCountObj[each.batchNo],
          status: each.status,
          actions: isCampaignCancellable(each.scheduledOn, each.status) ? ["cancel"] : [],
        });

        if (each.status === "completed") {
          _analytics.completed = _analytics.completed + 1;
        } else if (each.status === "scheduled") {
          _analytics.pending = _analytics.pending + 1;
        } else if (each.status === "cancelled") {
          _analytics.cancelled = _analytics.cancelled + 1;
        }
      });

      setTableContent(options);
      setAnalytics(_analytics);
    } else {
      setTableContent([]);
      setAnalytics(_analytics);
    }
  }, [props.splitDetails, props.campaignDetails]);

  const getCustomTagClass = (value) => {
    if (typeof value !== "string") return "dark";
    if (customColorMapping[value.toLowerCase()]) return customColorMapping[value.toLowerCase()];
    return "dark";
  };

  const renderContent = (content, column) => {
    if (dataType[mappingTable[column]] === "LIGHT_TAG") {
      return <span className={`badge badge-light-${getCustomTagClass(content[mappingTable[column]])}`}>{content[mappingTable[column]]}</span>;
    } else if (dataType[mappingTable[column]] === "TAG") {
      return <span className={`badge badge-${getCustomTagClass(content[mappingTable[column]])}`}>{content[mappingTable[column]]}</span>;
    } else if (dataType[mappingTable[column]] === "OWNER") {
      return <OwnerDisplay value={content[mappingTable[column]]} />;
    } else if (dataType[mappingTable[column]] === "DATE") {
      return moment(content[mappingTable[column]]).format("MMM DD, h:mm a");
    } else {
      return content[mappingTable[column]];
    }
  };

  const handleCancelSplitCampaign = (batchNo) => {
    setShowCancelCampaign(true);
    setSelectedBatchNo(batchNo);
  };
  const handleCancelCamapignAccept = () => {
    setFormLoading(true);
    dispatch(
      CampaignListActions.cancelSplitBatchCampaignRequest(
        {
          body: { campaignId: props.campaignDetails._id, batchNo: selectedBatchNo },
          loadingData: { limit: props.limit, pageNo: props.pageNo },
        },
        () => {
          setFormLoading(false);
          handleCancelCamapignClose();
        },
      ),
    );
  };

  const handleCancelCamapignClose = () => {
    setSelectedBatchNo(0);
    setShowCancelCampaign(false);
  };

  const deleteTemplateModalProps = {
    show: showCancelCampaign,
    loading: formLoading,
    title: "Cancel Split Batch Campaign",
    deleteName: "Cancel",
    handleClose: () => handleCancelCamapignClose(),
    handleAccept: () => handleCancelCamapignAccept(),
  };
  return (
    <>
      {" "}
      <div className="card-header d-flex flex-stack align-items-center">
        <h3 className="card-title fw-bolder text-dark">{`Split Campaign Details`}</h3>
      </div>
      <div className="p-8 h-vh-70px overflow-auto">
        {!_.isEmpty(props.splitDetails) && (
          <>
            <div className="card card-custom border border-1">
              <div className="card-body py-3 px-3">
                <div className="table-responsive overflow-y-auto">
                  <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
                    <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
                      <thead>
                        <tr className="fw-bolder text-muted">
                          {tablehead.map((el, index) => (
                            <InlineStyled
                              as="th"
                              styles={{
                                minWidth: `${100 / tablehead.length}%`,
                              }}
                              className={`opacity-1 bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap ${index === 0 ? "pl-2" : ""}`}
                            >
                              <div className=" opacity-0-point-8 text-black d-flex flex-row align-items-center w-100 h-100 gap-5px text-nowrap">{el}</div>
                            </InlineStyled>
                          ))}
                          <InlineStyled
                            as="th"
                            styles={{
                              minWidth: `${100 / tablehead.length}%`,
                            }}
                            className="opacity-1 bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap"
                          >
                            <div className="opacity-0-point-8 text-black d-flex flex-row align-items-center w-100 h-100 gap-5px text-nowrap">Action</div>
                          </InlineStyled>
                        </tr>
                      </thead>
                      <tbody>
                        {tablecontent.map((content, index) => (
                          <tr key={index} className="cursor-pointer">
                            {tablehead.map((column, index) => (
                              <td className={`${index === 0 ? "pl-2" : ""} text-nowrap`}>
                                <div className="d-flex flex-row flex-stack">
                                  <span className="data-flex-container">{renderContent(content, column)}</span>
                                </div>
                              </td>
                            ))}
                            <td className="text-nowrap w-0px">
                              <div className="d-flex flex-row flex-stack">
                                <span className="data-flex-container">
                                  {content.actions.map((each, ind) => {
                                    if (each === "cancel") {
                                      return (
                                        <OverlayTrigger key={ind} overlay={<Tooltip>Cancel Split Campaign</Tooltip>}>
                                          <span onClick={() => handleCancelSplitCampaign(content.batchNo)} className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                              <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                                <i className="bi bi-x-circle"></i>
                                              </span>
                                            </span>
                                          </span>
                                        </OverlayTrigger>
                                      );
                                    }
                                    return <div key={ind}></div>;
                                  })}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3 mt-8">
              {Object.keys(analytics).map((each, index) => {
                return (
                  <div className="card mb-4 mr-4 p-4 w-150px">
                    <div>
                      <span className="fs-2tx">{_.round(analytics[each], 2)}</span>
                    </div>
                    <div className="fs-5"> {_.capitalize(each)}</div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to cancel split batch campaign?</DeleteModal>
      </div>
    </>
  );
}
