import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import _ from "lodash";
import moment from "moment-timezone";

import { TagInputBox, ToFieldTagInputBox } from "../functions";

import * as syncActions from "../../../containers/Settings/actions/sync.actions";
import { getVariableSupportedDataRequest } from "../../../containers/leads/store/actions/common.actions";

import { EmailSignatureDropdown } from "../../email-signature/email-signature-dropdown";
import VariableOptions from "../../side-pannel-detailed/custom-components/templateEditorVariables";
import * as syncSettingActions from "../../../containers/Settings/store/actions/profile-actions/sync-settings.actions";

//styles
import "./index.scss";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import RichTextEditor from "../../RichTextEditor/tinymce-editor";
import { UpgradePlanPage } from "../../empty_page";
import SubscriptionPlanModal from "../../../containers/Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import { InputLimit } from "../../../constants";
import { BlueGradientButton } from "../../button";
import { CustomEmptyPage } from "../../empty_page/custom-empty-page";
import TemplateManager from "../../templates";
import BuilderComposer from "./builder-composer-view";
import { IconButton } from "../../button/icon-action-buttons";
import AIWizardSidePanel from "../../ai-wizard/AIWizardSidePanel";
import { ContentLoadingWithoutCard } from "../../loading";
import NylasFromEmail from "../components/nylas-from-email";

export default function HybridEmailComposer(props) {
  const [fromEmail, setFromEmail] = useState([]);
  const [toEmail, setToEmail] = useState([]);
  const [ccEmail, setCcEmail] = useState([]);
  const [bccEmail, setBccEmail] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState(`<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature"></div></div>`);
  const [attachments, setAttachments] = useState([]);
  const [showTemplateManager, setShowTemplateManager] = useState(false);
  const [showAIWizardSidePanel, setShowAIWizardSidePanel] = useState(false);
  const [editorType, setEditorType] = useState("");
  const [builderBody, setBuilderBody] = useState("");
  const [syncEmailMapping, setSyncEmailMapping] = useState({});

  const [defaultValue, setDefaultValue] = useState(`<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature"></div></div>`);

  const [showcc, setShowcc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [showActivityDone, setShowActivityDone] = useState(false);
  const [cadenceEventId, setCadenceEventId] = useState("");
  const [cadenceActivityId, setCadenceActivityId] = useState("");

  //inputs
  const [toInput, setToInput] = useState("");
  const [ccInput, setCcInput] = useState("");
  const [bccInput, setBccInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);

  const [activeKey, setActiveKey] = useState("0");

  // threading
  const [showEmailThread, setShowEmailThread] = useState(false);
  const [threadContent, setThreadContent] = useState("");
  const [actionType, setActionType] = useState(null);
  const [threadToggle, setThreadToggle] = useState(false);

  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const signatureStore = useSelector((state) => state.Leads.signatureList);
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);
  const variableSupportedData = useSelector((state) => state.common.variableSupported);

  const [showPlans, setShowPlans] = useState(false);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (props.page === "lead" && props.leadId && props.leadId !== variableSupportedData.moduleId) {
      dispatch(getVariableSupportedDataRequest({ moduleId: props.leadId, moduleName: "LEAD" }));
    }
    else if (props.page === "people" && props.peopleId && props.peopleId !== variableSupportedData.moduleId) {
      dispatch(getVariableSupportedDataRequest({ moduleId: props.peopleId, moduleName: "PEOPLE" }));
    }
    else if (props.page === "company" && props.companyId && props.companyId !== variableSupportedData.moduleId) {
      dispatch(getVariableSupportedDataRequest({ moduleId: props.companyId, moduleName: "COMPANY" }));
    }
    else if (props.page === "deal" && props.dealId && props.dealId !== variableSupportedData.moduleId) {
      dispatch(getVariableSupportedDataRequest({ moduleId: props.dealId, moduleName: "DEAL" }));
    }
  }, [dispatch, props.page, props.dealId, props.leadId, props.peopleId, props.companyId, variableSupportedData])

  console.log(actionType)

  useEffect(() => {
    const find = syncSettingsStore.data.find((each) => each.isDefault);
    if (find) {
      setFromEmail([find.syncedEmail]);
    }
    const obj = {};
    syncSettingsStore.data.forEach((each) => {
      obj[each.syncedEmail] = each._id;
    });
    setSyncEmailMapping(obj);
  }, [syncSettingsStore.data]);

  const onBodyChange = useCallback(
    (content) => {
      if (new RegExp(/{{[a-zA-Z0-9]+\.[a-zA-Z0-9_]+}}/g).test(content)) {
        const newData = content.replace(/\{{(.*?)\}}/g, (match, key) => {
          const value = variableSupportedData.data[key.trim()] || match;
          return value.replace(/(?:\r\n|\r|\n)/g, "<br>");
        });
        setBody(newData);
      } else {
        setBody(content);
      }
    },
    [variableSupportedData.data],
  );
  const onSubjectChange = useCallback(
    (sub) => {
      if (new RegExp(/{{[a-zA-Z0-9]+\.[a-zA-Z0-9_]+}}/g).test(sub)) {
        const newData = sub.replace(/\{{(.*?)\}}/g, (match, key) => variableSupportedData.data[key.trim()] || match);
        setSubject(newData);
      } else {
        setSubject(sub);
      }
    },
    [variableSupportedData.data],
  );

  const onKeyDown = (event, type) => {
    if (event.key === "Enter") {
      if (type === "to" && validateEmail(toInput)) {
        setToEmail([...toEmail, toInput]);
        setToInput("");
      } else if (type === "cc" && validateEmail(ccInput)) {
        setCcEmail([...ccEmail, ccInput]);
        setCcInput("");
      } else if (type === "bcc" && validateEmail(bccInput)) {
        setBccEmail([...bccEmail, bccInput]);
        setBccInput("");
      }
    }
  };
  const onBlur = (type) => {
    if (type === "to") {
      if (validateEmail(toInput)) {
        setToEmail([...toEmail, toInput]);
      }
      setToInput("");
    } else if (type === "cc") {
      if (validateEmail(ccInput)) {
        setCcEmail([...ccEmail, ccInput]);
      }
      setCcInput("");
    } else if (type === "bcc") {
      if (validateEmail(bccInput)) {
        setBccEmail([...bccEmail, bccInput]);
      }
      setBccInput("");
    }
  };

  const validate = () => {
    if (toEmail.length === 0) {
      dispatchSnackbarError("To email field is mandatory field");
      return false;
    } else {
      for (let i = 0; i < toEmail.length; i++) {
        if (!validateEmail(toEmail[i])) {
          dispatchSnackbarError("Invalid email provided in 'To' field");
          return false;
        }
      }
    }
    if (ccEmail.length > 0) {
      for (let i = 0; i < ccEmail.length; i++) {
        if (!validateEmail(ccEmail[i])) {
          dispatchSnackbarError("Invalid email provided in 'CC' field");
          return false;
        }
      }
    }
    if (bccEmail.length > 0) {
      for (let i = 0; i < bccEmail.length; i++) {
        if (!validateEmail(bccEmail[i])) {
          dispatchSnackbarError("Invalid email provided in 'BCC' field");
          return false;
        }
      }
    }
    if (!subject) {
      dispatchSnackbarError("Subject field is mandatory field");
      return false;
    }
    if (editorType === "unlayer" && !builderBody) {
      dispatchSnackbarError("Email body can't be empty!");
      return false;
    }
    if (editorType !== "unlayer" && !body) {
      dispatchSnackbarError("Email body can't be empty!");
      return false;
    }
    return true;
  };

  const onSendClick = () => {
    if (loading) return;
    setLoading(true);
    const validation = validate();
    if (validation) {
      const sendBody = {
        subject: subject,
        to: toEmail,
        cc: ccEmail,
        bcc: bccEmail,
        from: [],
        reply_to: [],
        body: editorType === "unlayer" ? builderBody : body,
        file_ids: attachments,
        peopleId: props.peopleId,
        companyId: props.companyId,
        leadId: props.leadId,
        dealId: props.dealId,
        cadenceLogId: cadenceEventId,
        activityId: cadenceActivityId,
        syncId: syncEmailMapping[fromEmail[0]],
      };
      setTimeout(() => {
        dispatchSnackbarSuccess("Email Sent Successfully!");
        onResetClick();
        props.setEmailSentLaoding(true);
      }, 1000);

      dispatch(
        syncActions.sendNylasEmailRequest({ filter: props.filter, data: sendBody, pageForm: props.pageForm }, (data) => {
          setTimeout(() => {
            if (data.status) {
              // dispatchSnackbarSuccess("Email Sent Successfully  1!");
            } else {
              if (data.message.includes("Could not verify")) {
                const msg = "Invalid token. Please re-connect your email account under profile > Email Sync.";
                dispatchSnackbarError(msg);
              } else {
                dispatchSnackbarError(data.message);
              }
            }
            setLoading(false);
            props.setEmailSentLaoding(false);
          }, 1000);
        }),
      );
    } else {
      setLoading(false);
    }
  };

  const onRemoveAttachment = (id) => {
    const newAttachments = attachments.filter((a) => a.id !== id);
    setAttachments(newAttachments);
  };

  const handleUploadAttachment = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      e.preventDefault();
      setAttachmentLoading(true);
      const formData = new FormData();
      formData.append("attachment", e.target.files[0]);
      formData.append("syncId", syncEmailMapping[fromEmail[0]]);
      dispatch(
        syncActions.uploadAttachmentInNylasRequest(formData, (data) => {
          if (data.status) {
            const _attachments = [...attachments];
            _attachments.push(data.data);
            setAttachments(_attachments);
            dispatchSnackbarSuccess("Successfully uploaded attachment");
          } else {
            dispatchSnackbarError(data.message);
          }
          setAttachmentLoading(false);
        }),
      );
    }
  };

  useEffect(() => {
    if (props.autoUpdateActivityEvent && props.activityEvent && !_.isEmpty(props.activityEvent) && props.activityEvent.content) {
      const _template = props.activityEvent;
      if (!_.isEmpty(_template)) {
        onSubjectChange(_template.subject);
        if (_template.editorType === "tiny_cloud") {
          setEditorType(_template.editorType);
          const signatureBlock = `<div><div id="saino-body"><div>${_template.content}</div><br/></div><div id="saino-signature"></div></div>`;
          setDefaultValue(signatureBlock);
          onBodyChange(signatureBlock);
          setBuilderBody("");
        } else if (_template.editorType === "unlayer") {
          setBuilderBody(_template.content);
          setEditorType(_template.editorType);
        }
        setShowActivityDone(true);
        setCadenceEventId(_template.cadenceLogId);
        setCadenceActivityId(_template.activityId);
        // if (props.activityEvent.response) {
        //   if (props.activityEvent.response.emailId) {
        //     setShowActivityDone(false);
        //   } else {
        //     setShowActivityDone(true);
        //     setCadenceEventId(props.activityEvent._id);
        //   }
        // }
      } else {
        setShowActivityDone(false);
      }
    } else if (!_.isEmpty(signatureStore.list)) {
      for (const each of signatureStore.list) {
        if (each.isDefault) {
          const signatureBlock = `<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature">${each.signature}</div></div>`;
          setDefaultValue(signatureBlock);
          onBodyChange(signatureBlock);
          setShowActivityDone(false);
          break;
        }
      }
    }
  }, [signatureStore, onBodyChange, onSubjectChange, props.autoUpdateActivityEvent, props.activityEvent]);

  useEffect(() => {
    if (props.person && Array.isArray(props.person.email) && props.person.email.length > 0) {
      setToEmail(props.person.email);
    } else {
      setToEmail([]);
    }
  }, [props.person]);

  useEffect(() => {
    if (props.emailAction && props.emailActionData) {
      setShowEmailThread(true);
      setActionType(props.emailAction);
      const { content } = props.emailActionData;
      const fromName = content.from[0].name;
      const fromEmail = content.from[0].email;
      const lastMessageOn = moment(content.date)
        .format("ddd, MMM D, YYYY @ h:mm A")
        .replace("@", "at");
      const __updatedContent = `<div>
        <br/>
        <div class="gmail_quote">
          <div dir="ltr" class="gmail_attr">On ${lastMessageOn} ${fromName} &lt;<a href="mailto:${fromEmail}">${fromEmail}</a>&gt; wrote:<br></div>
          <blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
          ${content.body}
          </blockquote>
        </div>
      </div>`;
      setThreadContent(__updatedContent);
    } else {
      setShowEmailThread(false);
      setThreadContent("");
      setActionType(null);
    }
    console.log("props.emailActionData", props.emailActionData);
  }, [props.emailAction, props.emailActionData]);

  const removeEmail = (type, email) => {
    if (type === "to") {
      const newEmails = toEmail.filter((each) => each !== email);
      setToEmail(newEmails);
    }
    if (type === "cc") {
      const newEmails = ccEmail.filter((each) => each !== email);
      setCcEmail(newEmails);
    }
    if (type === "bcc") {
      const newEmails = bccEmail.filter((each) => each !== email);
      setBccEmail(newEmails);
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const addVariable = (item, key, variable) => {
    window.tinyMCE.execCommand("mceInsertContent", false, variable);
  };

  const onTemplateChoose = (data) => {
    if (data.editorType === "unlayer") {
      setBuilderBody(data.content);
    } else {
      if (props.signature && props.signature.signature) {
        const signatureBlock = `<div><div id="saino-body"><div>${data.content}</div><br/></div><div id="saino-signature">${props.signature.signature}</div></div>`;
        setDefaultValue(signatureBlock);
        onBodyChange(signatureBlock);
      } else {
        const signatureBlock = `<div><div id="saino-body"><div>${data.content}</div><br/></div><div id="saino-signature"></div></div>`;
        setDefaultValue(signatureBlock);
        onBodyChange(signatureBlock);
      }
      setBuilderBody("");
    }
    setEditorType(data.editorType);
    setShowTemplateManager(false);
  };

  const cancelSelectTemplate = () => {
    setShowTemplateManager(false);
  };

  const onResetClick = () => {
    setCcEmail([]);
    setBccEmail([]);
    setSubject("");
    setBody(`<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature"></div></div>`);
    setAttachments([]);
    setLoading(false);
    setDefaultValue(`<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature"></div></div>`);
    setEditorType("");
    setBuilderBody("");
    setShowTemplateManager(false);
    setShowEmailThread(false);
    setThreadContent("");
    setActionType(null);
    props.onResetClick();
  };

  const onSignatureSelect = (data) => {
    if (body.includes(`saino-body`)) {
      // create a new dov container
      const div = document.createElement("div");
      // assing your HTML to div's innerHTML
      div.innerHTML = body;
      // get all <a> elements from div
      const elements = div.querySelector("#saino-body");
      const signatureBlock = `<div><div id="saino-body"><div>${elements.innerHTML}</div></div><div id="saino-signature">${data.signature}</div></div>`;
      setDefaultValue(signatureBlock);
      onBodyChange(signatureBlock);
      setShowActivityDone(false);
    } else if (body && !body.includes("saino-signature") && !body.includes(`saino-body`)) {
      const signatureBlock = `<div><div id="saino-body"><div>${body}<br/></div></div><div id="saino-signature">${data.signature}</div></div>`;
      setDefaultValue(signatureBlock);
      onBodyChange(signatureBlock);
      setShowActivityDone(false);
    } else {
      const signatureBlock = `<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature">${data.signature}</div></div>`;
      setDefaultValue(signatureBlock);
      onBodyChange(signatureBlock);
      setShowActivityDone(false);
    }
  };

  if (syncSettingsStore.loading) {
    return <ContentLoadingWithoutCard />;
  }
  if (accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.EMAIL_TEMPLATES) {
    return (
      <>
        <UpgradePlanPage
          isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
          userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          onUpgradeClick={() => setShowPlans(true)}
          type={"Two-way Email Sync"}
          planName={"business plan"}
        />
        {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
      </>
    );
  } else if (_.isEmpty(syncSettingsStore.data)) {
    return <CustomEmptyPage page="connectEmail" />;
  } else {
    return (
      <Accordion activeKey={activeKey} className="mb-5 saino-email-composer-container">
        <Accordion.Toggle as={Card.Header} eventKey="0" className="card d-flex justify-content-center p-0 m-0">
          <div className="d-flex flex-stack composor-editor-header">
            <div>New Email</div>
            <div>
              <IconButton tooltip={"Reset"} onClick={onResetClick}>
                <i class="bi bi-arrow-repeat"></i>
              </IconButton>
              <IconButton tooltip={activeKey === "1" ? "Expand" : "Collapse"} onClick={() => (activeKey === "1" ? setActiveKey("0") : setActiveKey("1"))}>
                {activeKey === "1" ? <i class="bi bi-chevron-bar-expand"></i> : <i class="bi bi-chevron-bar-contract"></i>}
              </IconButton>
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <div className="d-block">
            <NylasFromEmail value={fromEmail} setValue={setFromEmail} typeOfSelection={"single"} isRequired={true} />
            <ToFieldTagInputBox
              title={"To"}
              type={"to"}
              validEmails={toEmail}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              inputValue={toInput}
              setInputChange={setToInput}
              showcc={showcc}
              showBcc={showBcc}
              setShowcc={setShowcc}
              setShowBcc={setShowBcc}
              removeEmail={removeEmail}
            />
            {showcc && (
              <TagInputBox
                title={"Cc"}
                type={"cc"}
                validEmails={ccEmail}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                inputValue={ccInput}
                setInputChange={setCcInput}
                removeEmail={removeEmail}
                onRemoveField={() => {
                  setShowcc(false);
                  setCcEmail([]);
                  setCcInput("");
                }}
              />
            )}
            {showBcc && (
              <TagInputBox
                title={"Bcc"}
                type={"bcc"}
                validEmails={bccEmail}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                inputValue={bccInput}
                setInputChange={setBccInput}
                removeEmail={removeEmail}
                onRemoveField={() => {
                  setShowBcc(false);
                  setBccEmail([]);
                  setBccInput("");
                }}
              />
            )}
            <div className="border-bottom">
              <input
                maxLength={InputLimit.TEXT}
                type={"text"}
                value={subject}
                onChange={(e) => onSubjectChange(e.target.value)}
                className="form-control border-0 px-8 min-h-45px"
                name="compose_subject"
                placeholder="Subject"
              />
            </div>
            <div className="h-50vh composor-editor-wrapper border-top-0 border-left-0 border-right-0">
              {editorType === "unlayer" ? (
                <BuilderComposer handleEmailBuilderEdit={() => setShowTemplateManager(true)} content={builderBody} />
              ) : (
                <RichTextEditor defaultValue={defaultValue} value={body} onChange={(c) => onBodyChange(c)} toolbarLocation={"bottom"} />
              )}
            </div>
            {showEmailThread && (
              <div className="p-5">
                <div className="d-flex flex-stack">
                  <div className="thread-icon-wrapper cursor-pointer" onClick={() => setThreadToggle(!threadToggle)}>
                    <i class="bi bi-three-dots"></i>
                  </div>
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => {
                      setShowEmailThread(false);
                      setThreadContent("");
                      setActionType("");
                      setThreadToggle(false);
                      props.onResetClick();
                    }}
                  >
                    Remove Thread
                  </Button>
                </div>
                {threadToggle && <div dangerouslySetInnerHTML={{ __html: threadContent || "" }}></div>}
              </div>
            )}

            {attachments.length > 0 || attachmentLoading ? (
              <div className="d-flex dropzone dropzone-multi px-8 py-4 flex flex-wrap bg-white">
                {attachments.map((each, index) => {
                  return (
                    <div className="dropzone-items mr-10px width-fit-content" key={index}>
                      <div className="dropzone-item dz-processing dz-image-preview dz-error dz-complete">
                        <div className="dropzone-file">
                          <div className="dropzone-filename" title={each.filename}>
                            <span data-dz-name="">{each.filename}</span>
                            <strong>
                              ({" "}
                              <span data-dz-size="">
                                <strong>{each.size}</strong> B
                              </span>
                              )
                            </strong>
                          </div>
                          {false && (
                            <div className="dropzone-error" data-dz-errormessage="">
                              Server responded with 0 code.
                            </div>
                          )}
                        </div>
                        <div className="dropzone-toolbar">
                          <span className="dropzone-delete" data-dz-remove="">
                            <i className="flaticon2-cross" onClick={() => onRemoveAttachment(each.id)}></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {attachmentLoading && (
                  <div className="dropzone-items mr-10px width-fit-content">
                    <div className="dropzone-item dz-processing dz-image-preview dz-error dz-complete">
                      <div className="dropzone-file">
                        <div className="dropzone-filename">
                          <Spinner color="white" animation="border" className="mr-10px w-15px h-15px" />
                          <span data-dz-name="">Attachment uploading...</span>
                        </div>
                      </div>
                      <div className="dropzone-toolbar">
                        <span className="dropzone-delete" data-dz-remove=""></span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            <div className="d-flex align-items-center justify-content-between py-2 px-4 border-top">
              <div className="d-flex align-items-center gap-1">
                <div className="btn-group">
                  <BlueGradientButton
                    onClick={() => {
                      onSendClick();
                    }}
                    size="sm"
                  >
                    <span className="font-weight-bold px-1 flex">
                      {loading && <Spinner color="white" animation="border" className="mr-10px w-15px h-15px text-white" />}
                      {showActivityDone ? "Send & Done Activity" : "Send"}
                    </span>
                  </BlueGradientButton>
                </div>
                <label htmlFor="filePicker" className="btn btn-icon btn-sm btn-clean dz-clickable m-0">
                  <OverlayTrigger overlay={<Tooltip>Upload Attachment</Tooltip>}>
                    <i className="flaticon2-clip-symbol"></i>
                  </OverlayTrigger>
                </label>
                <input id="filePicker" onChange={(e) => handleUploadAttachment(e)} className="invisible w-1px" type={"file"} />
                {editorType !== "unlayer" && <EmailSignatureDropdown setCurrentSignature={onSignatureSelect} />}
              </div>
              <div className="d-flex gap-20px align-self-flex-end">
                {editorType !== "unlayer" && <VariableOptions addVariable={addVariable} />}
                <div onClick={(e) => e.stopPropagation()}>
                  <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => setShowTemplateManager(true)}>
                    Template
                  </button>
                  <TemplateManager show={showTemplateManager} channel={"email"} category={"CRM"} showChoose={true} onCloseClick={(data) => onTemplateChoose(data)} handleClose={cancelSelectTemplate} />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <button
                    className="btn btn-color-primary btn-active-color-primary m-0 px-0"
                    variant="transparent"
                    onClick={() => {
                      setShowAIWizardSidePanel(true);
                    }}
                  >
                    AI Wizard
                  </button>
                  <AIWizardSidePanel
                    show={showAIWizardSidePanel}
                    handleClose={() => {
                      setShowAIWizardSidePanel(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    );
  }
}
