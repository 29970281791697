import React from "react";
import PriorityIndicator from "./priorityIndicator";
import { Amount } from "./styled.js";

export const renderAmount = (amount = {}) => {
  if (Object.keys(amount).length === 0) {
    return null;
  }

  return (
    <Amount>
      {Object.keys(amount).map((key) => (
        <li key={key}>
          {amount[key].toLocaleString()} <span>{key}</span>
        </li>
      ))}
    </Amount>
  );
};

export const renderTags = (tags) => {
  if (!tags) {
    return null;
  }
  if (tags.length > 0) {
    return null;
  }
  return tags.map((a) => {
    return <div>{a.name}</div>;
  });
};
export const getRandomNumber = (max = 10) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const renderPriority = (priority) => {
  if (!priority) {
    return null;
  }

  return <PriorityIndicator value={priority} />;
};

export const generateButtonClass = (closeDate, isComplete) => {
  let colorName = "";

  if (isComplete) {
    colorName = "green";
  } else if (closeDate) {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    if (new Date(closeDate).getTime() - now.getTime() < oneDay) {
      colorName = "yellow";
    }

    if (now > closeDate) {
      colorName = "red";
    }
  }

  return colorName;
};

export const invalidateCache = () => {
  localStorage.setItem("cacheInvalidated", "true");
};

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  let tempArray = [];
  for (let i = 0; i < list.length; i++) {
    if (i === startIndex) {
      list[i].order = endIndex + 1;
    } else if (i !== endIndex && i !== startIndex && i < endIndex && startIndex < endIndex) {
      if (i < startIndex) {
        list[i].order = i + 1;
      } else {
        list[i].order = i;
      }
    } else if (i !== endIndex && i !== startIndex && i < startIndex && startIndex > endIndex) {
      if (i > endIndex) {
        list[i].order = i + 2;
      } else {
        list[i].order = i + 1;
      }
    } else if (i === endIndex) {
      if (startIndex > endIndex) {
        list[i].order = i + 2;
      } else {
        list[i].order = endIndex;
      }
    } else {
      list[i].order = i + 1;
    }
    tempArray.push(list[i]);
  }

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderActions = (list, startIndex, endIndex) => {
  let tempArray = [];
  for (let i = 0; i < list.length; i++) {
    if (i === startIndex) {
      list[i].executionOrder = endIndex + 1;
    } else if (i !== endIndex && i !== startIndex && i < endIndex && startIndex < endIndex) {
      if (i < startIndex) {
        list[i].executionOrder = i + 1;
      } else {
        list[i].executionOrder = i;
      }
    } else if (i !== endIndex && i !== startIndex && i < startIndex && startIndex > endIndex) {
      if (i > endIndex) {
        list[i].executionOrder = i + 2;
      } else {
        list[i].executionOrder = i + 1;
      }
    } else if (i === endIndex) {
      if (startIndex > endIndex) {
        list[i].executionOrder = i + 2;
      } else {
        list[i].executionOrder = endIndex;
      }
    } else {
      list[i].executionOrder = i + 1;
    }
    tempArray.push(list[i]);
  }

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderItemMap = (itemMap, source, destination) => {
  const current = [...itemMap[source.droppableId]];
  const next = [...itemMap[destination.droppableId]];

  let target = current[source.index];

  if (!target && source.item) {
    target = source.item;
  }

  // target.modifiedAt = new Date();

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    // drag down, index -1
    const specInd = source.index < destination.index ? 0 : 1;

    const aboveItem = next[destination.index - specInd];

    if (source.index !== undefined) {
      current.splice(source.index, 1);
    }

    if (destination.index !== undefined) {
      current.splice(destination.index, 0, target);
    }

    const updateditemMap = {
      ...itemMap,
      [source.droppableId]: current,
    };

    return {
      itemMap: updateditemMap,
      aboveItem,
      target,
    };
  }

  // moving to different list

  // remove from original
  if (source.index !== undefined) {
    current.splice(source.index, 1);
  }

  // insert into next
  if (destination.index !== undefined && next.length >= destination.index) {
    next.splice(destination.index, 0, target);
  }

  const result = {
    ...itemMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  return {
    itemMap: result,
    aboveItem: next[destination.index - 1],
    target,
  };
};
