import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import validator from "validator";
import { IconButton2 } from "../../button/icon-action-buttons";
import { InternalLink } from "../../custom-svgs/action-svg";
import "../display.css";

export default function InputDisplay(props) {
  const [showIcon, setShowIcon] = useState(false);
  const history = useHistory();

  if (props.field && props.data && typeof props.data === "string" && typeof props.field === "object" && props.field.validation === "URL_VALIDATION" && validator.isURL(props.data)) {
    const validUrl = !props.data.includes("http") ? `https://${props.data}` : props.data;
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
        <div className={`data-container2`}>
          <span className="data-container-2-text">{props.data}</span>
          <a className="data-container-2-icon" rel="noopener noreferrer" href={validUrl} target={"_blank"} onClick={(e) => e.stopPropagation()}>
            <i className="bi bi-box-arrow-up-right cursor-pointer text-00acff font-size-12px" />
          </a>
        </div>
      </OverlayTrigger>
    );
  } else if (props.field && props.data && props.field.internalLink && typeof props.data === "string" && typeof props.field === "object") {
    return (
      <>
        <div className="d-flex justify-content-between w-100" onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
            <div className={`data-container2`}>
              <span className="data-container-2-text">{props.data}</span>
            </div>
          </OverlayTrigger>
          <div onClick={(e) => e.stopPropagation()}>
            {showIcon ? (
              <IconButton2
                className="btn btn-icon btn-light h-25px w-25px"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(props.field.internalLink);
                }}
                tooltip={`${props.field.internalTooltip}`}
              >
                {InternalLink}
              </IconButton2>
            ) : (
              <div className="justify-self-end w-25px h-25px"></div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
      <span className={`data-container overflow-hidden text-overflow-ellipsis w-250px-mx`}>{props.data}</span>
    </OverlayTrigger>
  );
}
