import React from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./styles.scss";

const addOnInfoMapping = {
    "emailSync": {
        name: "Email & Calendar Sync",
        description: "Engage more people through your email communication.",
        calculation: "Sync Account(s)",
        calculationValue: "sync account",
        max: 100,
        min: 0
    }
}
export default function CheckoutCard({ users, addOnQuantity, currencySymbol, selectedMainPlan, supportedAddons, planMapping, total, continueToPayDisabled, handleSubscription, isSubscriptionActive }) {
    const getIsSomethingSelected = () => {
        if (users > 0) return true;
        for (const key in addOnQuantity) {
            if (addOnQuantity[key] > 0) return true;
        }
        return false
    };
    return (
        <div className="checkout-card-wrapper">
            <div className="card-head-text">Bill Summary:</div>
            <div className="checkout-summary-wrapper">
                {getIsSomethingSelected() ? (
                    <>
                        {users > 0 && (
                            <div className="checkout-each-list">
                                <div className="list-text">Team member(s)</div>
                                <div className="list-operation">
                                    {currencySymbol}
                                    {selectedMainPlan.amount} x {users}(user)
                                </div>
                                <div className="list-total">
                                    {currencySymbol}
                                    {(selectedMainPlan.amount) * users}
                                </div>
                            </div>
                        )}
                        {
                            supportedAddons.map((each, index) => {
                                if (addOnQuantity[each] <= 0) return <div key={index}></div>
                                else return <div className="checkout-each-list">
                                    <div className="list-text">{addOnInfoMapping[each].name}</div>
                                    <div className="list-operation">
                                        {" "}
                                        {currencySymbol}
                                        {planMapping[each].amount} x {addOnQuantity[each]}
                                    </div>
                                    <div className="list-total">
                                        {currencySymbol}
                                        {(planMapping[each].amount) * addOnQuantity[each]}
                                    </div>
                                </div>

                            })
                        }
                    </>
                ) : (
                    <>
                        <div className="d-flex flex-center text-white fs-5">Please select plan members or add-on to checkout details.</div>
                    </>
                )}
            </div>
            <div className="checkout-footer-main-wrapper">
                <div className="checkout-footer">
                    <hr />
                    <div className="checkout-each-list">
                        <div className="list-text"></div>
                        <div className="list-operation">Total</div>
                        <div className="list-total">
                            {" "}
                            {currencySymbol}
                            {total}
                        </div>
                    </div>
                    <div className="pay-button-wrapper">
                        <Button variant="link" onClick={() => handleSubscription()} disabled={continueToPayDisabled || !getIsSomethingSelected()}>
                            {continueToPayDisabled && <Spinner animation="border" className="mr-5px w-15px h-15px text-white" />}
                            {isSubscriptionActive ? "Update Subscription" : "Pay Now"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
