import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import { GreenGradientButton } from "../../../../components/button";
import { CustomButtonGroup } from "../../../../components/button/custom-group-button";
import CompanySelect from "../../../../components/custom_form/organization";
import DateRangePickerTag from "../../../../components/date-range-picker-tag/DateRangePickerTag";
import { BootstrapDropdown } from "../../../../components/dropdowns/bootstrap-dropdown";
import { InputLimit } from "../../../../constants";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import { AssigneeOptions, durationOptions, forecastTypeOptions, frequencyIntervalOptions, salesTargetAndForecastMapping } from "../../constants";
import "./styles.scss";

export default function AddSalesForecastForm(props) {
  const [title, setTitle] = useState("");
  const [showTypeOfAssignee, setShowTypeOfAssignee] = useState([]);
  const [typeOfAssignee, setTypeOfAssignee] = useState({ value: "user", label: "User" });
  const [assignee, setAssignee] = useState({});
  const [forecastType, setForecastType] = useState({});
  const [pipelineId, setPipelineId] = useState({});
  const [target, setTarget] = useState(0);
  const [frequencyInterval, setFrequencyInterval] = useState({ value: "weekly", label: "Weekly" });
  const [duration, setDuration] = useState({ value: "automatic", label: "Automatically" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [focusedInput, setFocusedInput] = useState(null);
  const [pipelineStageId, setPipelineStageId] = useState({ value: "", label: "All Stages" });
  const [companyField, setCompanyField] = useState("");
  const [companyFieldLabel, setCompanyFieldLabel] = useState("");
  const [optionalFieldCheckbox, setOptionalFieldCheckbox] = useState([]);

  useEffect(() => {
    if (props.isSalesForecastEdit && !_.isEmpty(props.selectedSalesForecast)) {
      const data = props.selectedSalesForecast;
      setTitle(data.title);
      setTypeOfAssignee({ label: salesTargetAndForecastMapping[data.typeOfUser], value: data.typeOfUser });
      if (data.typeOfUser === "team") {
        const _assignee = props.teamOptions.find((each) => each.value === data.assignee);
        if (_assignee) setAssignee(_assignee);
      } else if (data.typeOfUser === "user") {
        const _assignee = props.userOptions.find((each) => each.value === data.assignee);
        if (_assignee) setAssignee(_assignee);
      }

      setForecastType({ label: salesTargetAndForecastMapping[data.forecastType], value: data.forecastType });
      setTarget(data.salesTarget);
      setFrequencyInterval({ label: salesTargetAndForecastMapping[data.interval], value: data.interval });

      if (data.duration.type === "automatic") {
        setDuration({ label: salesTargetAndForecastMapping[data.duration.type], value: data.duration.type });
        setStartDate(null);
        setEndDate(null);
      } else {
        setDuration({ label: salesTargetAndForecastMapping["customPeriod"], value: "customPeriod" });
        setStartDate(data.duration.start);
        setEndDate(data.duration.end);
      }

      if (data.pipelineId) {
        const _find = props.pipelineOptions.find((each) => each.value === data.pipelineId);
        if (_find) setPipelineId(_find);
        else setPipelineId({});
      } else {
        setPipelineId({ value: "", label: "All Pipelines" });
      }

      const _optionalFieldCheckbox = [];
      if (data.company) {
        _optionalFieldCheckbox.push("Company");
        setCompanyField(data.company._id);
        setCompanyFieldLabel(data.company.name);
      }
      if (data.pipelineStageId) {
        _optionalFieldCheckbox.push("Pipeline Stage");
        const stageOptions = props.pipelineStageOptions[data.pipelineId];
        if (stageOptions) {
          const _find = stageOptions.find((each) => each.value === data.pipelineStageId);
          if (_find) setPipelineStageId(_find);
        }
      }
      setOptionalFieldCheckbox(_optionalFieldCheckbox);
    }
  }, [props.isSalesForecastEdit, props.selectedSalesForecast, props.userOptions, props.teamOptions, props.pipelineOptions, props.pipelineStageOptions]);

  useEffect(() => {
    if (props.userType === "OWNER" || props.userType === "ADMIN") {
      setShowTypeOfAssignee(["company", "team", "user"]);
    } else if (props.userType === "AGENT" && props.teamOptions.length > 0) {
      setShowTypeOfAssignee(["team", "user"]);
    } else {
      setShowTypeOfAssignee(["user"]);
    }
  }, [props.userType, props.teamOptions, props.userOptions]);

  const handleAssigneeTypeChange = (option) => {
    setTypeOfAssignee(option);
    setAssignee({});
  };

  const handleDurationChange = (option) => {
    setDuration(option);
    if (option.value === "automatic") {
      setStartDate(null);
      setEndDate(null);
    } else if (option.value === "thisMonth") {
      const _startDate = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "nextMonth") {
      const _startDate = moment()
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "thisQuarter") {
      const _startDate = moment()
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .endOf("quarter")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "nextQuarter") {
      const _startDate = moment()
        .add(1, "quarter")
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .add(1, "quarter")
        .endOf("quarter")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "thisYear") {
      const _startDate = moment()
        .startOf("year")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .endOf("year")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "nextYear") {
      const _startDate = moment()
        .add(1, "year")
        .startOf("year")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .add(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "customPeriod") {
      setStartDate(moment());
      setEndDate(moment());
    }
  };

  const validate = () => {
    if (_.isEmpty(typeOfAssignee)) {
      return "Please select type of assignee";
    } else if (["team", "user"].includes(typeOfAssignee.value) && _.isEmpty(assignee)) {
      return "Please select assignee";
    } else if (_.isEmpty(forecastType)) {
      return "Please select goal type";
    } else if (_.isEmpty(frequencyInterval)) {
      return "Please select interval type";
    } else if (_.isEmpty(duration)) {
      return "Please select duration type";
    }
    return "";
  };

  const handleSave = () => {
    const _validate = validate();
    if (_validate) return dispatchSnackbarError(_validate);

    const data = {
      title: title,
      forecastType: forecastType.value,
      salesTarget: parseInt(target),
      typeOfUser: typeOfAssignee.value,
      assignee: !_.isEmpty(assignee) ? assignee.value : null,
      pipelineId: pipelineId.value ? pipelineId.value : null,
      pipelineStageId: pipelineStageId.value ? pipelineStageId.value : null,
      company: companyField ? companyField : null,
      interval: frequencyInterval.value,
      duration: {
        type: duration.value === "automatic" ? "automatic" : "custom",
        start: startDate,
        end: endDate,
      },
    };

    props.handleSave(data);
  };

  const maxSpan = useMemo(() => {
    if (frequencyInterval.value === "weekly") return { weeks: 6 };
    if (frequencyInterval.value === "monthly") return { months: 6 };
    if (frequencyInterval.value === "quarterly") return { quarters: 6 };
    if (frequencyInterval.value === "yearly") return { years: 4 };
    return false;
  }, [frequencyInterval.value]);

  const handleFrequnecyIntervalChange = (option) => {
    setFrequencyInterval(option);
    setStartDate(null);
    setEndDate(null);
    setDuration({ value: "automatic", label: "Automatically" });
  };

  const setCompanyFieldValue = (value, apiKeyName, label) => {
    setCompanyField(value);
    setCompanyFieldLabel(label);
  };

  const handleCheckboxClick = (key) => {
    if (optionalFieldCheckbox.includes(key)) {
      setOptionalFieldCheckbox(optionalFieldCheckbox.filter((a) => a !== key));
      if (key === "Company") {
        setCompanyField("");
        setCompanyFieldLabel("");
      } else if (key === "Pipeline Stage") {
        setPipelineStageId({ value: "", label: "All Stages" });
      }
    } else {
      const _optionalFieldCheckbox = [...optionalFieldCheckbox];
      _optionalFieldCheckbox.push(key);
      setOptionalFieldCheckbox(_optionalFieldCheckbox);
    }
  };

  return (
    <BlankSidePanel show={props.show} handleClose={props.handleClose} title={props.isSalesForecastEdit ? "Edit Sales Forecast" : "Add Sales Forecast"}>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Forecast Assignee</label>
          <CustomButtonGroup options={AssigneeOptions.filter((a) => showTypeOfAssignee.includes(a.value))} handleClick={handleAssigneeTypeChange} active={typeOfAssignee} />
        </Col>
        <Col className="form-group" xs={6}>
          {["team", "user"].includes(typeOfAssignee && typeOfAssignee.value) && (
            <>
              <label className="form-label text-gray-700">Select {typeOfAssignee.label}</label>
              <BootstrapDropdown label={"Select"} value={assignee} options={typeOfAssignee.value === "team" ? props.teamOptions : props.userOptions} onSelect={setAssignee} />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Forecast Title</label>
          <Form.Control type="text" max={InputLimit.TEXT} value={title} onChange={(e) => setTitle(e.target.value)} />
        </Col>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Forecast Type</label>
          <BootstrapDropdown
            label={"Select"}
            value={forecastType}
            options={forecastTypeOptions}
            onSelect={(option) => {
              setForecastType(option);
              setTarget(0);
            }}
          />
        </Col>
      </Row>
      <Row>
        {forecastType.value === "openAndWonDealsWithSalesTarget" && (
          <Col className="form-group" xs={12}>
            <label className="form-label text-gray-700">Target</label>
            <Form.Control maxLength={InputLimit.NUMBER} value={target} onChange={(e) => setTarget(e.target.value)} type="number" />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Pipeline</label>
          <BootstrapDropdown label={"Select"} value={pipelineId} options={props.pipelineOptions} onSelect={setPipelineId} />
        </Col>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Interval</label>
          <BootstrapDropdown label={"Select"} value={frequencyInterval} options={frequencyIntervalOptions} onSelect={handleFrequnecyIntervalChange} />
        </Col>
      </Row>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Duration</label>
          <CustomButtonGroup options={durationOptions} handleClick={handleDurationChange} active={duration} />
        </Col>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">{duration.value === "customPeriod" ? "Date Range" : ""}</label>
          {duration.value === "customPeriod" ? (
            <>
              <DateRangePickerTag
                defaultStartDate={startDate ? moment(startDate) : moment()}
                defaultEndDate={endDate ? moment(endDate) : moment()}
                onDateRangeChange={({ dates: { startDate, endDate } }) => {
                  setStartDate(
                    startDate
                      .clone()
                      .hour(12)
                      .minute(0)
                      .second(0),
                  );
                  setEndDate(
                    endDate
                      .clone()
                      .hour(12)
                      .minute(0)
                      .second(0),
                  );
                }}
                maxSpan={maxSpan}
                placement="top-end"
              />
              {/* <Example /> */}
            </>
          ) : (
            <>
              {startDate} - {endDate}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Optional Filters</label>
          <div key={`inline-checkbox`} className="d-flex align-items-center">
            {["Pipeline Stage", "Company"].map((each, index) => {
              if (!pipelineId.value && each === "Pipeline Stage") return <div key={index}></div>;
              return (
                <Form.Check inline key={index} className="mx-3" label={each} checked={optionalFieldCheckbox.includes(each)} type={"checkbox"} id={`inline-checkbox-${index}`} onClick={(e) => handleCheckboxClick(each)} />
              );
            })}
          </div>
        </Col>
      </Row>
      <Row>
        {optionalFieldCheckbox.includes("Company") && (
          <Col className="form-group" xs={6}>
            <label className="form-label text-gray-700">Company (Optional)</label>
            <CompanySelect field={"test"} value={companyField} valueLabel={companyFieldLabel} setValue={setCompanyFieldValue} dontShowAdd={true} />
          </Col>
        )}
        {pipelineId.value && optionalFieldCheckbox.includes("Pipeline Stage") && (
          <Col className="form-group" xs={6}>
            <label className="form-label text-gray-700">Pipeline Stage (Optional)</label>
            <BootstrapDropdown label={"Select"} value={pipelineStageId} options={props.pipelineStageOptions[pipelineId.value]} onSelect={setPipelineStageId} />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="form-group d-flex gap-3 mt-10" xs={6}>
          <Button size="sm" variant="secondary" onClick={props.handleClose}>
            {"Close"}
          </Button>
          <GreenGradientButton size="sm" onClick={handleSave}>
            {"Save"}
          </GreenGradientButton>
        </Col>
      </Row>
    </BlankSidePanel>
  );
}
