import React, { useState } from "react";
import _ from "lodash";
import SmartViewFilter from "../../../components/filter/smart-view-filter";
import OwnerFilter from "../../../components/filter/owner-filter";
import AddSelectPipelineDropdown from "./add-select-pipeline.dropdown";
import TagSelect from "../../../components/custom_form/tags";
import { BlueGradientButton } from "../../../components/button";
import { DeleteIconButton } from "../../../components/button/icon-action-buttons";
import { PrimarySimpleDropdown } from "../../../components/dropdowns/simple-dropdown";
import { IconBasedNav } from "../../../components/subheader/IconBasedNav";
import IconBasedSimpleDropdown from "../../../components/dropdowns/icons-based-dropdown";

export default function DealSubHeader(props) {
  const [showStatusIcon, setShowStatusIcon] = useState(true);

  const handleStatusChange = (value) => {
    if (value.value === "archive") props.onMarkAsArchive();
    else if (value.value === "won") props.onMarkAsWon();
    else if (value.value === "lost") props.onMarkAsLost();
    else if (value.value === "move") props.onMove();
  };

  const onPipelineStateFilterClick = (status) => {
    setShowStatusIcon(false);
    props.onPipelineStateFilterClick(status);
  };
  const handleDealStatusClose = () => {
    setShowStatusIcon(true);
    props.onPipelineStateFilterClick("Open");
  };

  return (
    <div className="sub-header-wrapper">
      <div className="d-flex align-items-center">
        <AddSelectPipelineDropdown
          show={props.show}
          setShow={props.setShow}
          selectedPipeline={props.selectedPipeline}
          options={props.options}
          onPipelineChange={props.onPipelineChange}
          setAsDefault={props.setAsDefault}
          onAddPipelineClick={props.onAddPipelineClick}
          onPipelineEditIconClick={props.onPipelineEditIconClick}
        />
      </div>

      {props.checked && props.checked.length > 0 ? (
        <div className="d-flex align-items-center flex-row gap-12px">
          {props.checked.length === 1 && (
            <PrimarySimpleDropdown
              value={{}}
              label={"Convert"}
              options={[
                { value: "won", label: "Mark As Won" },
                { value: "lost", label: "Mark As Lost" },
                { value: "archive", label: "Mark As Archive" },
                { value: "move", label: "Move To" },
              ]}
              handleSelect={handleStatusChange}
              disabled={false}
            />
          )}
          {props.checked.length > 0 && <DeleteIconButton onClick={props.onDelete} />}
        </div>
      ) : (
        <div className="d-flex align-items-center flex-row gap-12px">
          <IconBasedNav links={props.links} active={props.tabName} />
          <div>
            <BlueGradientButton onClick={() => props.handleAddDeal()} variant="success" size="sm">
              + Deal
            </BlueGradientButton>
          </div>
          <SmartViewFilter
            pageSub={props.pageSub}
            page={props.page}
            activeFilter={props.activeFilter}
            customFilters={props.customFilters}
            loading={props.loading}
            setAddFilterFormData={props.setAddFilterFormData}
            virtualFilter={props.virtualFilter}
            applyVirtualFilter={props.applyVirtualFilter}
            selectedFilterId={props.selectedFilterId}
            favoriteFilter={props.favoriteFilter}
            applyFilter={props.applyFilter}
            addFilterFormData={props.addFilterFormData}
            addFilter={props.addFilter}
            editFilter={props.editFilter}
            deleteFilter={props.deleteFilter}
            selectedPipelineId={props.selectedPipelineId}
          />
          <div>
            <IconBasedSimpleDropdown
              value={showStatusIcon ? "" : props.filterOption}
              label={"Deal Status Filter"}
              icon={"bi bi-subtract"}
              options={["All Deals", "Open", "Won", "Lost", "Archived"]}
              handleSelect={onPipelineStateFilterClick}
              handleClose={handleDealStatusClose}
              disabled={false}
            />
          </div>
          <div>
            <TagSelect
              page={"DealPipeline"}
              pageSub={props.pageSub}
              addTag={props.addTag}
              editTag={props.editTag}
              deleteTag={props.deleteTag}
              field={{ fieldName: "Tags", apiKeyName: "tags" }}
              value={_.isEmpty(props.tags) ? [""] : props.tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
              setValue={(value) => props.onTagFilterSelection(value)}
              hideAddText={true}
              isUsedAsFilter={true}
              iconBasedFilter={true}
            />
          </div>
          <OwnerFilter setValue={props.setOwner} value={props.owner} />
          {props.children}
        </div>
      )}
    </div>
  );
}
