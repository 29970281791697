export const userProfileApi = async (token) => {
  try {
    // Default options are marked with *
    const response = await fetch("/api/crm/profile", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    console.log(err);
    return {};
  }
};
