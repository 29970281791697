import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as cadenceActions from "../actions/cadence_actions";
import * as cadenceTypes from "../types/cadence.types";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* getListOfCadence(action) {
  try {
    const res = yield call(api.get, endpoints.CADENCE_LIST, action.payload);
    if (res.status === 200) {
      yield put(cadenceActions.getCadenceListSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.getCadenceListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.getCadenceListError(e));
  }
}

function* getCadenceById(action) {
  try {
    const res = yield call(api.get, `${endpoints.CADENCE_BY_ID}/${action.payload.id}`);
    if (res.status === 200) {
      yield put(cadenceActions.getCadenceByIdSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.getCadenceByIdError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.getCadenceByIdError(e));
  }
}

function* editCadence(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_CADENCE}/${action.payload.id}`, action.payload.updateData);
    if (res.status === 200) {
      yield put(cadenceActions.editCadenceSuccess(res.data));
      if (action.payload.from === "cadence-list") {
        yield put(cadenceActions.getCadenceListRequest(action.payload.loadingData));
      }
      if (action.payload.from === "cadence-builder") {
        yield put(cadenceActions.getCadenceByIdRequest({ id: action.payload.id }));
      }
      dispatchSnackbarSuccess("Successfully edited a cadence.");
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.editCadenceError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.editCadenceError(e));
  }
}

function* updateCadenceStatus(action) {
  try {
    const res = yield call(api.put, `${endpoints.CADENCE_UPDATE_STATUS}/${action.payload.id}`, action.payload.updateData);
    if (res.status === 200) {
      yield put(cadenceActions.updateCadenceStatusSuccess(res.data));
      yield put(cadenceActions.getCadenceListRequest(action.payload.loadingData));
      dispatchSnackbarSuccess("Successfully updated cadence status");
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.updateCadenceStatusError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.updateCadenceStatusError(e));
  }
}

function* createCadence(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_CADENCE, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Cadence added successfully. Now, let's add subscribers from the next tab.");
      yield put(cadenceActions.createCadenceSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.createCadenceError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.createCadenceError(e));
  }
}

function* deleteCadenceById(action) {
  try {
    const res = yield call(api.delete, `${endpoints.DELETE_CADENCE}/${action.payload.id}`);
    if (res.status === 200) {
      yield put(cadenceActions.createCadenceSuccess(res.data));
      if (action.payload.from === "cadence-list") {
        yield put(cadenceActions.getCadenceListRequest(action.payload.loadingData));
      }
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.createCadenceError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.createCadenceError(e));
  }
}

export default function* cadenceSaga() {
  yield takeLatest(cadenceTypes.getCadenceList.GET_CADENCE_LIST_REQUEST, getListOfCadence);
  yield takeLatest(cadenceTypes.getCadenceById.GET_CADENCE_BY_ID_REQUEST, getCadenceById);
  yield takeLatest(cadenceTypes.editCadence.EDIT_CADENCE_REQUEST, editCadence);

  yield takeLatest(cadenceTypes.updateCadenceStatus.UPDATE_CADENCE_STATUS_REQUEST, updateCadenceStatus);

  yield takeLatest(cadenceTypes.createCadence.CREATE_CADENCE_REQUEST, createCadence);
  yield takeLatest(cadenceTypes.deleteCadenceById.DELETE_CADENCE_BY_ID_REQUEST, deleteCadenceById);
}
