import React, { useEffect, useState } from "react";
import { Spinner, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { MarkAsDoneActivity } from "../../activity/mark_as_done";
import { QuickScheduler } from "../../quick_scheduler";

//actions
import { GetLinkedActivitiesRequest } from "../../../containers/Settings/actions/notes.actions";
import * as ActivityLossReasonActions from "../../../containers/Activities/store/actions/activity_loss_reasons_actions";
import * as ActivityOperationsActions from "../../../containers/Activities/store/actions/activity_operations_actions";
import * as ActivityListActions from "../../../containers/Activities/store/actions/activitity_list_actions";

//styles
import "./styles.scss";
import { moduleColors, TimelineDataWrapper } from "./timeline-v2.helper";
import { ScheduleADateDisplay } from "../../custom-table-data-display/due-date-display";
import { ConvertModal } from "../../modal";
import { renderLinkedData } from "./helper";
import InlineStyled from "../../inline-styled/inline-styled";

export function ActivityListLogs(props) {
  const [limit] = useState(100);
  const [page] = useState(1);
  const [userObject, setUserObject] = useState({});
  const [showMarkedAsUndone, setShowMarkAsUndone] = useState(false);
  const [markAsUnDoneId, setMarkAsUnDoneId] = useState("");
  const [selectedActivity, setSelectedActivity] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const activitiesData = useSelector((state) => state.notes.activities);
  const activityLossReasonStatus = useSelector((store) => store.activity.lossReasons.settings.enabled);
  const userRole = useSelector((store) => store.insight.userRole);

  useEffect(() => {
    if (userRole.list.allUsers) {
      const obj = {};
      userRole.list.allUsers.forEach((each) => {
        obj[each.id] = each;
      });
      setUserObject(obj);
    }
  }, [userRole.list.allUsers]);

  useEffect(() => {
    const queryMaker = {
      limit: 100,
      pageNo: 1,
    };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }
    dispatch(GetLinkedActivitiesRequest(queryMaker));
  }, [dispatch, props.peopleId, props.companyId, props.leadId, props.dealId, props.type]);

  useEffect(() => {
    dispatch(ActivityLossReasonActions.getActivityLossReasonsRequest({ skipIfDataPresent: true }));
    dispatch(ActivityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);
  const getActivitiesLogs = () => {
    const queryMaker = {
      limit: limit,
      pageNo: page,
    };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }
    dispatch(GetLinkedActivitiesRequest(queryMaker));
  };

  const onCancelMarkAsDone = () => {
    document.body.click();
  };
  const onMarkAsDoneActivity = (payload) => {
    callActivityMADoneApi(payload.data);
    document.body.click();
  };

  const callActivityMADoneApi = (data) => {
    dispatch(
      ActivityOperationsActions.markAsDoneRequest({ data: data, pageForm: props.pageForm, filter: props.filter }, (res) => {
        if (res.status) {
          getActivitiesLogs();
        }
      }),
    );
  };
  const onDeleteClick = (id) => {
    dispatch(
      ActivityListActions.deleteActivityRequest({ id, pageForm: props.pageForm, filter: props.filter }, (res) => {
        if (res.status) {
          getActivitiesLogs();
        }
      }),
    );
    getActivitiesLogs();
  };

  const onActivityDoneUndoneClick = (isCompleted, id) => {
    if (isCompleted) {
      setShowMarkAsUndone(true);
      setMarkAsUnDoneId(id);
    } else {
      const data = { id: id, reasonId: "", comment: "" };
      callActivityMADoneApi(data);
    }
  };

  const markAsUndoneAccept = () => {
    dispatch(
      ActivityOperationsActions.markAsUndoneRequest({ id: { id: markAsUnDoneId } }, (res) => {
        if (res.status) getActivitiesLogs();
        setMarkAsUnDoneId("");
        setShowMarkAsUndone(false);
      }),
    );
  };

  const markAsUndoneCancel = () => {
    setMarkAsUnDoneId("");
    setShowMarkAsUndone(false);
  };

  const showLossReasonPopup = (data) => {
    if (!data.isCompleted && activityLossReasonStatus && !_.isEmpty(data.associated)) {
      return true;
    }
    return false;
  };
  const getUserName = (item, userId) => {
    if (item && item.source && item.source === "Workflow") {
      return "Automation";
    }
    if (userObject[userId]) {
      if (userObject[userId]?.name) return userObject[userId]?.name;
      else if (userObject[userId].email) return userObject[userId].email;
      else return "Unknown";
    } else return "Unknown";
  };

  const convertModalProps = {
    show: showMarkedAsUndone,
    title: "Mark as undone",
    buttonText: "Mark as Undone",
    handleClose: markAsUndoneCancel,
    handleAccept: markAsUndoneAccept,
    buttonType: "green",
  };

  const onEditClick = (activity) => {
    setSelectedActivity(activity);
    setIsEdit(true);
  };

  return (
    <>
      <QuickScheduler
        personName={props.personName}
        peopleId={props.peopleId}
        companyId={props.companyId}
        leadId={props.leadId}
        dealId={props.dealId}
        type={props.type}
        onCreateNewActivity={() => getActivitiesLogs()}
        pipelineId={props.pipelineId}
        relatedAssociate={props.relatedAssociate}
        pageForm={props.pageForm}
        filter={props.filter}
        selectedActivity={selectedActivity}
        setSelectedActivity={setSelectedActivity}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <>
        <div>
          {activitiesData.loading ? (
            <div className="w-100 d-flex p-7 justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              <div className="note-header-text">Linked Activities</div>

              {activitiesData.list.length > 0 ? (
                <div>
                  {" "}
                  {activitiesData.list.map((note, index) => {
                    return (
                      <div key={index} className="mb-40px">
                        <TimelineDataWrapper moduleName={"Activity"} createdAt={note.createdAt} moduleColor={moduleColors["activity"]} createdText={`Added by ${getUserName(note, note.owner)}`}>
                          {note && (
                            <div className="d-flex">
                              <div className="w-30px mr-4 mt-2">
                                {showLossReasonPopup(note) ? (
                                  <OverlayTrigger
                                    rootClose={true}
                                    trigger="click"
                                    className="z-index-0"
                                    overlay={
                                      <InlineStyled
                                        as={Popover}
                                        id="popover-basic"
                                        onClick={(e) => e.stopPropagation()}
                                        className="z-index-9999 my-0 mx-20px w-430px w-290px-mx"
                                        styles={{ transform: "translate(173px, 136px)" }}
                                      >
                                        <Popover.Title>
                                          <div className="fw-500 font-size-15px text-26292c">Mark As Done</div>
                                        </Popover.Title>
                                        <Popover.Content className="p-0 m-0">
                                          <MarkAsDoneActivity activityId={note._id} showNextSchedule={false} handleCancel={onCancelMarkAsDone} handleSave={onMarkAsDoneActivity} />
                                        </Popover.Content>
                                      </InlineStyled>
                                    }
                                  >
                                    <input type="radio" className="w-16px h-16px" checked={note.isCompleted} />
                                  </OverlayTrigger>
                                ) : (
                                  <input type="radio" className="w-16px h-16px" checked={note.isCompleted} onClick={(e) => onActivityDoneUndoneClick(note.isCompleted, note._id)} />
                                )}
                              </div>
                              <div className="w-100-60px">
                                {renderLinkedData(note, props.basePath, history)}
                                <div className={`${note.isCompleted ? "text-decoration-line-through fs-6 fw-bold" : " fs-6 fw-bold"} text-008fbd`}>{note?.name}</div>
                                <div className="d-flex align-items-center justify-content-between mt-4">
                                  <div className="d-flex align-items-center gap-2 ">
                                    {note.activityType && <i className={`${note.activityType.symbName}`}></i>}
                                    <div>
                                      {" "}
                                      <ScheduleADateDisplay data={note.scheduleAt} />
                                    </div>
                                  </div>
                                  {note.isCompleted && (
                                    <div className="mr-minus-35px">
                                      <span className="badge badge-success bg-73ce89">Completed</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div className="d-flex ">
                                  {!note.isCompleted && (
                                    <button className="btn btn-icon btn-light ml-3 h-25px w-25px" onClick={() => onEditClick(note)}>
                                      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Edit</Tooltip>}>
                                        <i className="fa fa-sm fas fa-pen font-size-13px"></i>
                                      </OverlayTrigger>
                                    </button>
                                  )}
                                  <button className="btn btn-icon btn-light ml-3 h-25px w-25px" onClick={() => onDeleteClick(note._id)}>
                                    <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
                                      <i className="fa fa-sm fa-trash font-size-13px"></i>
                                    </OverlayTrigger>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </TimelineDataWrapper>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="note-no-data-text">This record doesn't have any activities.</div>
              )}
              {/* <div className='d-flex justify-content-center'>
                            <span className="btn btn-secondary py-7px px-20px h-34px"  onClick={() => { }}>
                                {true && <Spinner animation="border" variant="primary" className="mr-10px h-16px w-16px"></Spinner>}
                                Load More</span>
                        </div> */}
            </>
          )}
          <ConvertModal modalProps={convertModalProps}>Would you like to mark it as undone? You will lose all previous activity outcomes and time when you marked it as done.</ConvertModal>
        </div>
      </>
    </>
  );
}
