import {
  GET_LIST_OF_DASHBOARDS_REQUEST,
  GET_LIST_OF_DASHBOARDS_SUCCESS,
  GET_LIST_OF_DASHBOARDS_ERROR,
  GET_LIST_OF_DASHBOARDS_REQUEST_WITHOUT_LOADING,
  CREATE_DASHBOARD_REQUEST,
  CREATE_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_ERROR,
  EDIT_DASHBOARD_REQUEST,
  EDIT_DASHBOARD_SUCCESS,
  EDIT_DASHBOARD_ERROR,
  DELETE_DASHBOARD_REQUEST,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_ERROR,
  EDIT_DASHBOARD_LAYOUT_REQUEST,
  EDIT_DASHBOARD_LAYOUT_SUCCESS,
  EDIT_DASHBOARD_LAYOUT_ERROR,
  GET_SELECTED_DASHBOARD_REQUEST,
  GET_SELECTED_DASHBOARD_SUCCESS,
  GET_SELECTED_DASHBOARD_ERROR,
  CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST,
  CREATE_INSIGHT_DASHBOARD_REPORT_SUCCESS,
  CREATE_INSIGHT_DASHBOARD_REPORT_ERROR,
  EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST,
  EDIT_INSIGHT_DASHBOARD_REPORT_SUCCESS,
  EDIT_INSIGHT_DASHBOARD_REPORT_ERROR,
  DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST,
  DELETE_INSIGHT_DASHBOARD_REPORT_SUCCESS,
  DELETE_INSIGHT_DASHBOARD_REPORT_ERROR,
  GET_INSIGHTS_GRAPH_REPORT_REQUEST,
  GET_INSIGHTS_GRAPH_REPORT_SUCCESS,
  GET_INSIGHTS_GRAPH_REPORT_ERROR,
  GET_INSIGHT_GOAL_REQUEST,
  GET_INSIGHT_GOAL_SUCCESS,
  GET_INSIGHT_GOAL_ERROR,
  CREATE_INSIGHTS_GOAL_REQUEST,
  CREATE_INSIGHTS_GOAL_SUCCESS,
  CREATE_INSIGHTS_GOAL_ERROR,
  EDIT_INSIGHTS_GOAL_REQUEST,
  EDIT_INSIGHTS_GOAL_SUCCESS,
  EDIT_INSIGHTS_GOAL_ERROR,
  DELETE_INSIGHTS_GOAL_REQUEST,
  DELETE_INSIGHTS_GOAL_SUCCESS,
  DELETE_INSIGHTS_GOAL_ERROR,
  GET_INSIGHT_GRAPH_GOAL_REQUEST,
  GET_INSIGHT_GRAPH_GOAL_SUCCESS,
  GET_INSIGHT_GRAPH_GOAL_ERROR,
  GET_LIST_OF_PIPELINES_REQUEST,
  GET_LIST_OF_PIPELINES_SUCCESS,
  GET_LIST_OF_PIPELINES_ERROR,
  GET_LIST_OF_USERS_REQUEST,
  GET_LIST_OF_USERS_SUCCESS,
  GET_LIST_OF_USERS_ERROR,
  TYPE_OF_REPORT_REQUEST,
  TYPE_OF_REPORT_SUCCESS,
  TYPE_OF_REPORT_ERROR,
  USER_ROLE_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_ERROR,
} from "./types";

//get list of dashboard
export function getListOfDashboardsRequest(req) {
  return {
    type: GET_LIST_OF_DASHBOARDS_REQUEST,
    payload: req,
  };
}

export function getListOfDashboardsRequestWithoutLoading(req) {
  return {
    type: GET_LIST_OF_DASHBOARDS_REQUEST_WITHOUT_LOADING,
    payload: req,
  };
}
export function getListOfDashboardsSuccess(res) {
  return {
    type: GET_LIST_OF_DASHBOARDS_SUCCESS,
    payload: res,
  };
}
export function getListOfDashboardsError(err) {
  return {
    type: GET_LIST_OF_DASHBOARDS_ERROR,
    payload: err,
  };
}

//create dashboard
export function createDashboardRequest(req) {
  return {
    type: CREATE_DASHBOARD_REQUEST,
    payload: req,
  };
}
export function createDashboardSuccess(res) {
  return {
    type: CREATE_DASHBOARD_SUCCESS,
    payload: res,
  };
}
export function createDashboardError(err) {
  return {
    type: CREATE_DASHBOARD_ERROR,
    payload: err,
  };
}

//edit dashboard
export function editDashboardRequest(req) {
  return {
    type: EDIT_DASHBOARD_REQUEST,
    payload: req,
  };
}
export function editDashboardSuccess(res) {
  return {
    type: EDIT_DASHBOARD_SUCCESS,
    payload: res,
  };
}
export function editDashboardError(err) {
  return {
    type: EDIT_DASHBOARD_ERROR,
    payload: err,
  };
}

//delete dashboard
export function deleteDashboardRequest(req) {
  return {
    type: DELETE_DASHBOARD_REQUEST,
    payload: req,
  };
}
export function deleteDashboardSuccess(res) {
  return {
    type: DELETE_DASHBOARD_SUCCESS,
    payload: res,
  };
}
export function deleteDashboardError(err) {
  return {
    type: DELETE_DASHBOARD_ERROR,
    payload: err,
  };
}

//get selected dashboard
export function getSelectedDashboardRequest(req) {
  return {
    type: GET_SELECTED_DASHBOARD_REQUEST,
    payload: req,
  };
}
export function getSelectedDashboardSuccess(res) {
  return {
    type: GET_SELECTED_DASHBOARD_SUCCESS,
    payload: res,
  };
}
export function getSelectedDashboardError(err) {
  return {
    type: GET_SELECTED_DASHBOARD_ERROR,
    payload: err,
  };
}
//edit dashboard layout
export function editDashboardLayoutRequest(req) {
  return {
    type: EDIT_DASHBOARD_LAYOUT_REQUEST,
    payload: req,
  };
}
export function editDashboardLayoutSuccess(res) {
  return {
    type: EDIT_DASHBOARD_LAYOUT_SUCCESS,
    payload: res,
  };
}
export function editDashboardLayoutError(err) {
  return {
    type: EDIT_DASHBOARD_LAYOUT_ERROR,
    payload: err,
  };
}

//create insights report
export function createInsightDashboardReportRequest(req) {
  return {
    type: CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST,
    payload: req,
  };
}
export function createInsightDashboardReportSuccess(res) {
  return {
    type: CREATE_INSIGHT_DASHBOARD_REPORT_SUCCESS,
    payload: res,
  };
}
export function createInsightDashboardReportError(err) {
  return {
    type: CREATE_INSIGHT_DASHBOARD_REPORT_ERROR,
    payload: err,
  };
}

//edit insights report
export function editInsightDashboardReportRequest(req) {
  return {
    type: EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST,
    payload: req,
  };
}
export function editInsightDashboardReportSuccess(res) {
  return {
    type: EDIT_INSIGHT_DASHBOARD_REPORT_SUCCESS,
    payload: res,
  };
}
export function editInsightDashboardReportError(err) {
  return {
    type: EDIT_INSIGHT_DASHBOARD_REPORT_ERROR,
    payload: err,
  };
}

//delete insights report
export function deleteInsightDashboardReportRequest(req) {
  return {
    type: DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST,
    payload: req,
  };
}
export function deleteInsightDashboardReportSuccess(res) {
  return {
    type: DELETE_INSIGHT_DASHBOARD_REPORT_SUCCESS,
    payload: res,
  };
}
export function deleteInsightDashboardReportError(err) {
  return {
    type: DELETE_INSIGHT_DASHBOARD_REPORT_ERROR,
    payload: err,
  };
}

//get insights graph report
export function getInsightGraphReportRequest(req, cb) {
  return {
    type: GET_INSIGHTS_GRAPH_REPORT_REQUEST,
    payload: req,
    cb,
  };
}
export function getInsightGraphReportSuccess(res) {
  return {
    type: GET_INSIGHTS_GRAPH_REPORT_SUCCESS,
    payload: res,
  };
}
export function getInsightGraphReportError(err) {
  return {
    type: GET_INSIGHTS_GRAPH_REPORT_ERROR,
    payload: err,
  };
}

//get insights goal List
export function getInsightGoalListRequest(req) {
  return {
    type: GET_INSIGHT_GOAL_REQUEST,
    payload: req,
  };
}
export function getInsightGoalListSuccess(res) {
  return {
    type: GET_INSIGHT_GOAL_SUCCESS,
    payload: res,
  };
}
export function getInsightGoalListError(err) {
  return {
    type: GET_INSIGHT_GOAL_ERROR,
    payload: err,
  };
}

//create insights goal
export function createInsightGoalRequest(req) {
  return {
    type: CREATE_INSIGHTS_GOAL_REQUEST,
    payload: req,
  };
}
export function createInsightGoalSuccess(res) {
  return {
    type: CREATE_INSIGHTS_GOAL_SUCCESS,
    payload: res,
  };
}
export function createInsightGoalError(err) {
  return {
    type: CREATE_INSIGHTS_GOAL_ERROR,
    payload: err,
  };
}

//edit insights goal
export function editInsightGoalRequest(req) {
  return {
    type: EDIT_INSIGHTS_GOAL_REQUEST,
    payload: req,
  };
}
export function editInsightGoalSuccess(res) {
  return {
    type: EDIT_INSIGHTS_GOAL_SUCCESS,
    payload: res,
  };
}
export function editInsightGoalError(err) {
  return {
    type: EDIT_INSIGHTS_GOAL_ERROR,
    payload: err,
  };
}

//delete insights goal
export function deleteInsightGoalRequest(req) {
  return {
    type: DELETE_INSIGHTS_GOAL_REQUEST,
    payload: req,
  };
}
export function deleteInsightGoalSuccess(res) {
  return {
    type: DELETE_INSIGHTS_GOAL_SUCCESS,
    payload: res,
  };
}
export function deleteInsightGoalError(err) {
  return {
    type: DELETE_INSIGHTS_GOAL_ERROR,
    payload: err,
  };
}

//get insights goal graph
export function getInsightGraphGoalRequest(req) {
  return {
    type: GET_INSIGHT_GRAPH_GOAL_REQUEST,
    payload: req,
  };
}
export function getInsightGraphGoalSuccess(res) {
  return {
    type: GET_INSIGHT_GRAPH_GOAL_SUCCESS,
    payload: res,
  };
}
export function getInsightGraphGoalError(err) {
  return {
    type: GET_INSIGHT_GRAPH_GOAL_ERROR,
    payload: err,
  };
}

//pipelines
export function getListOfPipelinesRequest(req) {
  return {
    type: GET_LIST_OF_PIPELINES_REQUEST,
    payload: req,
  };
}
export function getListOfPipelinesSuccess(res) {
  return {
    type: GET_LIST_OF_PIPELINES_SUCCESS,
    payload: res,
  };
}
export function getListOfPipelinesError(err) {
  return {
    type: GET_LIST_OF_PIPELINES_ERROR,
    payload: err,
  };
}

//users
export function getListOfUsersRequest(req) {
  return {
    type: GET_LIST_OF_USERS_REQUEST,
    payload: req,
  };
}
export function getListOfUsersSuccess(res) {
  return {
    type: GET_LIST_OF_USERS_SUCCESS,
    payload: res,
  };
}
export function getListOfUsersError(err) {
  return {
    type: GET_LIST_OF_USERS_ERROR,
    payload: err,
  };
}

//type of report
export function getTypeOfReportRequest(req) {
  return {
    type: TYPE_OF_REPORT_REQUEST,
    payload: req,
  };
}
export function getTypeOfReportSuccess(res) {
  return {
    type: TYPE_OF_REPORT_SUCCESS,
    payload: res,
  };
}
export function getTypeOfReportError(err) {
  return {
    type: TYPE_OF_REPORT_ERROR,
    payload: err,
  };
}

//type of user role
export function getUserRoleRequest(req) {
  return {
    type: USER_ROLE_REQUEST,
    payload: req,
  };
}
export function getUserRoleSuccess(res) {
  return {
    type: USER_ROLE_SUCCESS,
    payload: res,
  };
}
export function getUserRoleError(err) {
  return {
    type: USER_ROLE_ERROR,
    payload: err,
  };
}
