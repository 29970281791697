import _ from "lodash";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { alphabetColorMapping } from "../../../constants";
import InlineStyled from "../../inline-styled/inline-styled";

export default function OwnerDisplay({ value, showOnlyName }) {
  const userRole = useSelector((store) => store.insight.userRole);
  const [owner, setOwner] = useState({});

  useEffect(() => {
    if (userRole.list.allUsers) {
      if (typeof value === "object" && value) {
        setOwner(userRole.list.allUsers.find((owner) => owner["id"] === value[0]));
      } else {
        setOwner(userRole.list.allUsers.find((owner) => owner["id"] === value));
      }
    }
  }, [userRole.list.allUsers, value]);

  const getAlphabetData = (owner) => {
    const text = owner.name ? owner.name : owner.email;
    const firstChar = text.charAt(0).toUpperCase();
    if (alphabetColorMapping[firstChar]) {
      return { colorData: alphabetColorMapping[firstChar], letter: firstChar };
    }
    return { colorData: alphabetColorMapping["0"], letter: firstChar };
  };

  if (userRole.loading) {
    return <span className="data-container"></span>;
  } else if (showOnlyName) {
    return <span className="data-container">{userRole.loading ? "" : !_.isEmpty(owner) ? (owner.name ? owner.name : owner.email) : ""}</span>;
  } else if (!_.isEmpty(owner) && owner.avatar) {
    return (
      <span className="data-container ml-10px">
        <OverlayTrigger overlay={<Tooltip>{owner.name ? owner.name : owner.email}</Tooltip>}>
          <img className="mr-2 w-22px h-22px border-radius-50-percent" src={owner.avatar} alt="profile" />
        </OverlayTrigger>
      </span>
    );
  } else if (!_.isEmpty(owner) && !owner.avatar) {
    const { colorData, letter } = getAlphabetData(owner);
    return (
      <span className="data-container ml-10px">
        <OverlayTrigger overlay={<Tooltip>{owner.name ? owner.name : owner.email}</Tooltip>}>
          <InlineStyled as='span'
            className="d-inline-block mr-2 w-22px h-22px border-radius-50-percent"
            styles={{
              background: colorData["background"],
              color: colorData["color"],
            }}
          >
            <span className="d-flex align-items-center justify-content-center">{letter}</span>
          </InlineStyled>
        </OverlayTrigger>
        {/* {owner.name ? owner.name : owner.email} */}
      </span>
    );
  } else return <span className="data-container">{userRole.loading ? "" : !_.isEmpty(owner) ? (owner.name ? owner.name : owner.email) : ""}</span>;
}

export function MultiOwnerDisplay({ value }) {
  // const ownerStore = useSelector(state=>state.People.ownerList)
  // const owner = ownerStore.list.find(owner=>owner["userId"]===value)

  const userRole = useSelector((store) => store.insight.userRole);
  const [owner, setOwner] = useState([]);

  useEffect(() => {
    if (userRole.list.allUsers && typeof value === "object") {
      const data = userRole.list.allUsers
        .filter((owner) => value.includes(owner["id"]))
        .map((each) => {
          if (each.name) {
            return each.name;
          } else return each.email;
        });
      setOwner(data);
    }
  }, [userRole.list.allUsers, value]);

  return <span className="data-container">{userRole.loading ? "" : owner && owner.length > 0 ? owner.join(", ") : ""}</span>;
}
