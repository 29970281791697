import React from "react";
import { Form } from "react-bootstrap";

export default function CheckboxGroupSelect({ apiKeyName, setValue, value, options }) {
  const setCheckboxData = (e) => {
    if (!Array.isArray(value)) {
      setValue(
        [e.target.name].filter((a) => a),
        apiKeyName,
      );
    } else if (value.includes(e.target.name)) {
      setValue(
        value.filter((each) => each !== e.target.name && each),
        apiKeyName,
      );
    } else {
      setValue(
        [...value, e.target.name].filter((a) => a),
        apiKeyName,
      );
    }
  };
  return (
    <>
      <Form.Group id="custom-form-checkbox-group-select" onClick={(e) => setCheckboxData(e)}>
        {options.map((each, index) => {
          return (
            <div className="p-2 m-2">
              <Form.Check type="checkbox" label={each} name={each} checked={Array.isArray(value) ? value.includes(each) : false} id={index} />
            </div>
          );
        })}
      </Form.Group>
    </>
  );
}
