import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ApiKey from "./apiKey";
import WebHook from "./webhook";

export default function DeveloperRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route path={`${match.url}/api-key`} component={ApiKey} />
        <Route path={`${match.url}/webhook`} component={WebHook} />
        <Redirect to={`${match.url}/api-key`} />
      </Switch>
    </>
  );
}
