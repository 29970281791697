import { bulkDeleteLeadV2, bulkEditLeadV2 } from "../types/types";

export const bulkDeleteLeadV2Request = (payload, cb) => ({
  type: bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_REQUEST,
  payload,
  cb,
});
export const bulkDeleteLeadV2Success = (payload) => ({
  type: bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_SUCCESS,
  payload,
});
export const bulkDeleteLeadV2Error = (payload) => ({
  type: bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_ERROR,
  payload,
});

export const bulkEditLeadV2Request = (payload, cb) => ({
  type: bulkEditLeadV2.BULK_EDIT_LEAD_V2_REQUEST,
  payload,
  cb,
});
export const bulkEditLeadV2Success = (payload) => ({
  type: bulkEditLeadV2.BULK_EDIT_LEAD_V2_SUCCESS,
  payload,
});
export const bulkEditLeadV2Error = (payload) => ({
  type: bulkEditLeadV2.BULK_EDIT_LEAD_V2_ERROR,
  payload,
});
