import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  EXPORTHISTORY_REQUEST,
  EXPORTHISTORY_SUCCESS,
  EXPORTHISTORY_ERROR,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  IMPORTHISTORY_REQUEST,
  IMPORTHISTORY_SUCCESS,
  IMPORTHISTORY_ERROR,
  IMPORT_REQUEST,
  IMPORT_SUCCESS,
  IMPORT_ERROR,
  SAMPLEFILE_REQUEST,
  SAMPLEFILE_SUCCESS,
  SAMPLEFILE_ERROR,
  DELETE_IMPORT_HISTORY_REQUEST,
  DELETE_IMPORT_HISTORY_SUCCESS,
  DELETE_IMPORT_HISTORY_ERROR,
  EXPORTHISTORY_REQUEST_WITHOUT_LOADING,
  IMPORTHISTORY_REQUEST_WITHOUT_LOADING,
} from "./types";

const initialState = {
  exportHistoryList: {
    loading: "",
    list: [],
    count: 0,
  },
  importHistoryList: {
    loading: "",
    list: [],
    count: 0,
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
};

export function importExportReducer(state = initialState, action = {}) {
  switch (action.type) {
    //delete import history
    case DELETE_IMPORT_HISTORY_REQUEST:
      return {
        ...state,
      };
    case DELETE_IMPORT_HISTORY_SUCCESS:
      return {
        ...state,
      };
    case DELETE_IMPORT_HISTORY_ERROR:
      return {
        ...state,
      };
    case EXPORTHISTORY_REQUEST:
      return {
        ...state,
        exportHistoryList: { ...state.exportHistoryList, loading: true },
      };
    case EXPORTHISTORY_SUCCESS:
      return {
        ...state,
        exportHistoryList: { loading: false, list: action.payload.data, count: action.payload.count },
      };
    case EXPORTHISTORY_ERROR:
      return {
        ...state,
        exportHistoryList: { ...state.exportHistoryList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };
    case EXPORTHISTORY_REQUEST_WITHOUT_LOADING:
      return {
        ...state,
        exportHistoryList: { ...state.exportHistoryList, loading: false },
      };

    // Export Data
    case EXPORT_REQUEST:
      return {
        ...state,
        exportHistoryList: { ...state.exportHistoryList, loading: true },
      };
    case EXPORT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case EXPORT_ERROR:
      return {
        ...state,
        exportHistoryList: { ...state.exportHistoryList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case IMPORTHISTORY_REQUEST:
      return {
        ...state,
        importHistoryList: { ...state.importHistoryList, loading: true },
      };
    case IMPORTHISTORY_SUCCESS:
      return {
        ...state,
        importHistoryList: { loading: false, list: action.payload.data, count: action.payload.count },
      };
    case IMPORTHISTORY_ERROR:
      return {
        ...state,
        importHistoryList: { ...state.importHistoryList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };
    case IMPORTHISTORY_REQUEST_WITHOUT_LOADING:
      return {
        ...state,
        importHistoryList: { ...state.importHistoryList, loading: false },
      };

    // Import Data
    case IMPORT_REQUEST:
      return {
        ...state,
        importHistoryList: { ...state.importHistoryList, loading: true },
      };
    case IMPORT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case IMPORT_ERROR:
      return {
        ...state,
        importHistoryList: { ...state.importHistoryList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case SAMPLEFILE_REQUEST:
      return {
        ...state,
      };
    case SAMPLEFILE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case SAMPLEFILE_ERROR:
      return {
        ...state,
        error: { err: true, errMsg: action.payload.message },
      };

    // Clear error or success
    case CLEAR_ERROR:
      return {
        ...state,
        error: { err: false, errMsg: "" },
      };

    case CLEAR_SUCCESS:
      return {
        ...state,
        success: { success: false, successMsg: "" },
      };
    default:
      return state;
  }
}
