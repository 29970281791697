import { addWebHook, getWebHook, deleteWebHook, patchWebHook, testWebHook, disableWebHook, postKey, getKey, patchKey, deleteKey } from "../../types/admin-settings.types";

export function addWebHookRequest(req, cb) {
  return {
    type: addWebHook.ADD_WEB_HOOK_REQUEST,
    payload: req,
    cb,
  };
}

export function addWebHookSuccess(res) {
  return {
    type: addWebHook.ADD_WEB_HOOK_SUCCESS,
    payload: res,
  };
}

export function addWebHookError(err) {
  return {
    type: addWebHook.ADD_WEB_HOOK_ERROR,
    payload: err,
  };
}

export function getWebHookRequest(cb) {
  return {
    type: getWebHook.GET_WEB_HOOK_REQUEST,
    cb,
  };
}

export function getWebHookSuccess(res) {
  return {
    type: getWebHook.GET_WEB_HOOK_SUCCESS,
    payload: res,
  };
}

export function getWebHookError(err) {
  return {
    type: getWebHook.GET_WEB_HOOK_ERROR,
    payload: err,
  };
}

export function patchWebHookRequest(req, paramId, cb) {
  return {
    type: patchWebHook.PATCH_WEB_HOOK_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchWebHookSuccess(res) {
  return {
    type: patchWebHook.PATCH_WEB_HOOK_SUCCESS,
    payload: res,
  };
}

export function patchWebHookError(err) {
  return {
    type: patchWebHook.PATCH_WEB_HOOK_ERROR,
    payload: err,
  };
}

export function deleteWebHookRequest(paramId, cb) {
  return {
    type: deleteWebHook.DELETE_WEB_HOOK_REQUEST,
    paramId,
    cb,
  };
}

export function deleteWebHookSuccess(res) {
  return {
    type: deleteWebHook.DELETE_WEB_HOOK_SUCCESS,
    payload: res,
  };
}

export function deleteWebHookError(err) {
  return {
    type: deleteWebHook.DELETE_WEB_HOOK_ERROR,
    payload: err,
  };
}

export function testWebHookRequest(req, cb) {
  return {
    type: testWebHook.TEST_WEB_HOOK_REQUEST,
    payload: req,
    cb,
  };
}

export function testWebHookSuccess(res) {
  return {
    type: testWebHook.TEST_WEB_HOOK_SUCCESS,
    payload: res,
  };
}

export function testWebHookError(err) {
  return {
    type: testWebHook.TEST_WEB_HOOK_ERROR,
    payload: err,
  };
}

export function disableWebHookRequest(req, cb) {
  return {
    type: disableWebHook.DISABLE_WEB_HOOK_REQUEST,
    payload: req,
    cb,
  };
}

export function disableWebHookSuccess(res) {
  return {
    type: disableWebHook.DISABLE_WEB_HOOK_SUCCESS,
    payload: res,
  };
}

export function disableWebHookError(err) {
  return {
    type: disableWebHook.DISABLE_WEB_HOOK_ERROR,
    payload: err,
  };
}

export function postKeyRequest(req, cb) {
  return {
    type: postKey.POST_KEY_REQUEST,
    payload: req,
    cb,
  };
}

export function postKeySuccess(res) {
  return {
    type: postKey.POST_KEY_SUCCESS,
    payload: res,
  };
}

export function postKeyError(err) {
  return {
    type: postKey.POST_KEY_ERROR,
    payload: err,
  };
}

export function getKeyRequest(cb) {
  return {
    type: getKey.GET_KEY_REQUEST,
    cb,
  };
}

export function getKeySuccess(res) {
  return {
    type: getKey.GET_KEY_SUCCESS,
    payload: res,
  };
}

export function getKeyError(err) {
  return {
    type: getKey.GET_KEY_ERROR,
    payload: err,
  };
}

export function patchKeyRequest(req, cb) {
  return {
    type: patchKey.PATCH_KEY_REQUEST,
    payload: req,
    cb,
  };
}

export function patchKeySuccess(res) {
  return {
    type: patchKey.PATCH_KEY_SUCCESS,
    payload: res,
  };
}

export function patchKeyError(err) {
  return {
    type: patchKey.PATCH_KEY_ERROR,
    payload: err,
  };
}

export function deleteKeyRequest(paramId, cb) {
  return {
    type: deleteKey.DELETE_KEY_REQUEST,
    payload: paramId,
    cb,
  };
}

export function deleteKeySuccess(res) {
  return {
    type: deleteKey.DELETE_KEY_SUCCESS,
    payload: res,
  };
}

export function deleteKeyError(err) {
  return {
    type: deleteKey.DELETE_KEY_ERROR,
    payload: err,
  };
}
