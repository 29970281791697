import { takeLatest, put, call, select } from "redux-saga/effects";
import * as productActions from "../actions/actions";
//import required action types below
import {
  PRODUCTS_REQUEST,
  PRODUCTFIELDS_REQUEST,
  PRODUCTCATEGORIES_REQUEST,
  PRODUCTCATEGORIESADD_REQUEST,
  PRODUCTCATEGORIESEDIT_REQUEST,
  PRODUCTCATEGORIESDELETE_REQUEST,
  PRODUCTTAGS_REQUEST,
  PRODUCTTAGSADD_REQUEST,
  PRODUCTTAGSEDIT_REQUEST,
  PRODUCTTAGSDELETE_REQUEST,
  PRODUCTCELLEDIT_REQUEST,
  PRODUCTADD_REQUEST,
  PRODUCTBULKEDIT_REQUEST,
  PRODUCTDELETE_REQUEST,
  PRODUCTFILTERS_REQUEST,
  PRODUCTFILTERADD_REQUEST,
  PRODUCTFILTEREDIT_REQUEST,
  PRODUCTFILTERDELETE_REQUEST,
  PRODUCTADDFIELDS_REQUEST,
  CURRENCY_REQUEST,
  PRODUCTS_UPDATE_REQUEST,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_SHOWHIDE_REQUEST,
  PRODUCTSMERGE_REQUEST,
  PRODUCTFILTERFAVORITE_REQUEST,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_DROPDOWN_ADD_REQUEST,
} from "../types/types";
import api from "../../../../network/apis/api";

function* productsFunction(action) {
  try {
    var response;
    if (action.payload.filterType) {
      response = yield call(api.post, "/api/crm/saved-filters/apply", action.payload);
    } else {
      response = yield call(api.get, "/api/crm/product/get", action.payload);
    }
    if (response.status === 200) {
      yield put(productActions.productsSuccess(response.data));
    } else {
      yield put(productActions.productsError({ message: "Could Not Get Products" }));
    }
  } catch (e) {
    yield put(productActions.productsError({ message: "Could Not Get Products" }));
  }
}

function* productsUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/product/get", action.payload.loadingData);
    if (response.status === 200) {
      yield put(productActions.productsUpdateSuccess(response.data));
    } else {
      yield put(productActions.productsUpdateError({ message: "Could Not Get Products" }));
    }
  } catch (e) {
    yield put(productActions.productsUpdateError({ message: "Could Not Get Products" }));
  }
}

function* fieldUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/get", action.payload);
    if (response.status === 200) {
      yield put(productActions.fieldUpdateSuccess(response.data.data));
      yield put(productActions.productFieldsSuccess(response.data.data));
    } else {
      yield put(productActions.fieldUpdateError({ message: "Could Not Get Product Fields" }));
    }
  } catch (e) {
    yield put(productActions.fieldUpdateError({ message: "Could Not Get Product Fields" }));
  }
}

function* fieldShowHideFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
    if (response.status === 200) {
      yield put(productActions.fieldUpdateRequest({ fieldType: "PRODUCT" }));
    } else {
      yield put(productActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(productActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* fieldUpdatePriorityFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
    if (response.status === 200) {
      yield put(productActions.fieldUpdateRequest({ fieldType: "PRODUCT" }));
    } else {
      yield put(productActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(productActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* productFieldsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/get", action.payload);
    if (response.status === 200) {
      yield put(productActions.productFieldsSuccess(response.data.data));
    } else {
      yield put(productActions.productFieldsError({ message: "Could Not Get Product Fields" }));
    }
  } catch (e) {
    yield put(productActions.productFieldsError({ message: "Could Not Get Product Fields" }));
  }
}

function* productAddFieldsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/add-form-fields", action.payload);
    if (response.status === 200) {
      yield put(productActions.productAddFieldsSuccess(response.data.data));
    } else {
      yield put(productActions.productAddFieldsError({ message: "Could Not Get Product Fields" }));
    }
  } catch (e) {
    yield put(productActions.productAddFieldsError({ message: "Could Not Get Product Fields" }));
  }
}

function* productAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/product/create", action.payload.productData);
    if (response.status === 200) {
      yield put(productActions.productAddSuccess({ message: response.data.message }));
      yield put(productActions.productsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(productActions.productAddError({ message: response.data.message }));
    } else {
      yield put(productActions.productAddError({ message: "Could Not Add Product" }));
    }
  } catch (e) {
    yield put(productActions.productAddError({ status: false, message: "Could Not Add Product" }));
  }
}

function* productBulkEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/product/bulk-edit", action.payload.productData);
    if (response.status === 200) {
      yield put(productActions.productBulkEditSuccess({ message: "Products Edited Successfully" }));
      yield put(productActions.productsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(productActions.productBulkEditError({ message: response.data.message }));
    } else {
      yield put(productActions.productBulkEditError({ message: "Could Not Edit Products" }));
    }
  } catch (e) {
    yield put(productActions.productBulkEditError({ message: "Could Not Edit Products" }));
  }
}

function* productDeleteFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/product/delete", action.payload.productIds);
    if (response.status === 200) {
      yield put(productActions.productDeleteSuccess({ message: "Products Deleted Successfully" }));
      yield put(productActions.productsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(productActions.productDeleteError({ message: response.data.message }));
    } else {
      yield put(productActions.productDeleteError({ message: "Could Not Delete Products" }));
    }
  } catch (e) {
    yield put(productActions.productDeleteError({ message: "Could Not Delete Product" }));
  }
}

function* productCategoriesFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/product/categories", action.payload);
    if (response.status === 200) {
      yield put(productActions.productCategoriesSuccess(response.data.data));
    } else {
      yield put(productActions.productCategoriesError({ message: "Could Not Get Product Categories" }));
    }
  } catch (e) {
    yield put(productActions.productCategoriesError({ message: "Could Not Get Product Categories" }));
  }
}

function* productCategoriesAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/product/category", action.payload);
    if (response.status === 200) {
      yield put(productActions.productCategoriesAddSuccess({ message: "Category Added Successfully" }));
      yield put(productActions.productCategoriesRequest());
    } else {
      yield put(productActions.productCategoriesError({ message: "Could Not Add Product Category" }));
    }
  } catch (e) {
    yield put(productActions.productCategoriesError({ message: "Could Not Add Product Category" }));
  }
}

function* productCategoriesEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/product/category", action.payload);
    if (response.status === 200) {
      yield put(productActions.productCategoriesEditSuccess({ message: "Product Edited Successfully" }));
      yield put(productActions.productCategoriesRequest());
    } else {
      yield put(productActions.productCategoriesError({ message: "Could Not Edit Product Category" }));
    }
  } catch (e) {
    yield put(productActions.productCategoriesError({ message: "Could Not Edit Product Category" }));
  }
}

function* productCategoriesDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/product/category/" + action.payload);
    if (response.status === 200) {
      yield put(productActions.productDeleteSuccess({ message: "Category Deleted Successfully" }));
      yield put(productActions.productCategoriesRequest());
    } else {
      yield put(productActions.productCategoriesError({ message: "Could Not Delete Product Category" }));
    }
  } catch (e) {
    yield put(productActions.productCategoriesError({ status: false, message: "Could Not Delete Product Categories" }));
  }
}

function* productTagsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/tag/get", action.payload);
    if (response.status === 200) {
      yield put(productActions.productTagsSuccess(response.data.data));
    } else {
      yield put(productActions.productTagsError({ message: "Could Not Get Product Tags" }));
    }
  } catch (e) {
    yield put(productActions.productTagsError({ message: "Could Not Get Product Tags" }));
  }
}

function* productTagsAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/tag/create", action.payload);
    if (response.status === 200) {
      yield put(productActions.productTagsAddSuccess({ message: "Tag Added Successfully" }));
      yield put(productActions.productTagsRequest({ type: "product" }));
    } else {
      yield put(productActions.productTagsError({ message: "Could Not Add Product Tag" }));
    }
  } catch (e) {
    yield put(productActions.productTagsError({ status: false, message: "Could Not Add Product Tag" }));
  }
}

function* productTagsEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/edit", action.payload);
    if (response.status === 200) {
      yield put(productActions.productTagsEditSuccess({ message: "Tag Edited Successfully" }));
      yield put(productActions.productTagsRequest({ type: "product" }));
    } else {
      yield put(productActions.productTagsError({ message: "Could Not Edit Tag" }));
    }
  } catch (e) {
    yield put(productActions.productTagsError({ message: "Could Not Edit Tag" }));
  }
}

function* productTagsDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/tag/delete/" + action.payload);
    if (response.status === 200) {
      yield put(productActions.productTagsDeleteSuccess({ message: "Tag Deleted Successfully" }));
      yield put(productActions.productTagsRequest({ type: "product" }));
    } else {
      yield put(productActions.productTagsError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(productActions.productTagsError({ message: "Could Not Delete Tag" }));
  }
}

function* productCellEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/product/edit", action.payload.editData);
    if (response.status === 200) {
      yield put(productActions.productCellEditSuccess({ message: "Product Edited Successfully" }));
      yield put(productActions.productsUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.cellId }));
    } else if (response.status === 400) {
      // dispatchSnackbarError(response.data.message, "error")
      yield put(productActions.productCellEditError({ message: response.data.message }));
    } else {
      yield put(productActions.productCellEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(productActions.productCellEditError({ message: "Could Not Edit" }));
  }
}

function* productFiltersFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/saved-filters/get?type=product", action.payload);
    if (response.status === 200) {
      yield put(productActions.productFiltersSuccess(response.data));
    } else {
      yield put(productActions.productFiltersError({ message: "Could Not Get Filters" }));
    }
  } catch (e) {
    yield put(productActions.productFiltersError({ message: "Could Not Get Filters" }));
  }
}

function* productFilterAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (response.status === 200) {
      yield put(productActions.productFilterAddSuccess({ message: "Filter Added Successfully" }));
      yield put(productActions.productFiltersRequest());
    } else {
      yield put(productActions.productFilterAddError({ message: "Could Not Add Filter" }));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(productActions.productFilterAddError({ message: "Could Not Add Filter" }));
  }
}

function* productFilterEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (response.status === 200) {
      yield put(productActions.productFilterEditSuccess({ message: "Filter Edited Successfully" }));
      yield put(productActions.productFiltersRequest());
    } else {
      yield put(productActions.productFilterEditError({ message: "Could Not Edit Filter" }));
    }
    if (action.cb) action.cb(response.data);
  } catch (e) {
    yield put(productActions.productFilterEditError({ message: "Could Not Edit Filter" }));
  }
}

function* productFilterFavoriteFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);
    if (response.status === 200) {
      yield put(productActions.productFilterFavoriteSuccess({ message: "Filter Edited Successfully" }));
      yield put(productActions.productFiltersRequest());
    } else {
      yield put(productActions.productFilterFavoriteError({ message: "Could Not Favorite Filter" }));
    }
  } catch (e) {
    yield put(productActions.productFilterFavoriteError({ message: "Could Not Favorite Filter" }));
  }
}

function* productFilterDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/saved-filters/delete/" + action.payload);
    if (response.status === 200) {
      yield put(productActions.productFilterDeleteSuccess({ message: "Filter Deleted Successfully" }));
      yield put(productActions.productFiltersRequest());
    } else {
      yield put(productActions.productFilterDeleteError({ message: "Could Not Delete Filter" }));
    }
  } catch (e) {
    yield put(productActions.productFilterDeleteError({ message: "Could Not Delete Filter" }));
  }
}

function* currencyFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Products.currencyList.list) && store.Products.currencyList.list.length > 0) {
      yield put(productActions.currencySuccess(store.Products.currencyList.list));
    } else {
      const response = yield call(api.get, "/api/crm/currency", action.payload);
      if (response.status === 200) {
        yield put(productActions.currencySuccess(response.data.data));
      } else {
        yield put(productActions.currencyError({ message: "Could Not Get Currency Data" }));
      }
    }
  } catch (e) {
    yield put(productActions.currencyError({ message: "Could Not Get Currency Data" }));
  }
}

function* productsMergeFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/product/merge", action.payload.data);
    if (response.status === 200) {
      yield put(productActions.productsMergeSuccess({ message: "Products Merged Successfully" }));
      yield put(productActions.productsRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(productActions.productsMergeError({ message: response.data.message }));
    } else {
      yield put(productActions.productsMergeError({ message: "Could Not Merge Products" }));
    }
  } catch (e) {
    yield put(productActions.productsMergeError({ message: "Could Not Merge Products" }));
  }
}

function* productSearchFunction(action) {
  try {
    const res = yield call(api.get, `/api/crm/product/search?searchText=${action.payload}`);
    if (res.status === 200) {
      yield put(productActions.productSearchSuccess(res.data));
    }
  } catch (e) {
    yield put(productActions.productSearchError(e));
  }
}

function* productDropdownAddFunction(action) {
  try {
    const res = yield call(api.post, "", action.payload);
    if (res.status === 200) {
      yield put(productActions.productDropdownAddSuccess({ message: "Company Added Successfully", id: res.data.id, name: action.payload.name }));
      yield put(productActions.productSearchRequest(action.payload.name));
    }
  } catch (e) {
    yield put(productActions.productDropdownAddError(e));
  }
}

export default function* ProductSaga() {
  yield takeLatest(PRODUCTS_REQUEST, productsFunction);
  yield takeLatest(PRODUCTS_UPDATE_REQUEST, productsUpdateFunction);
  yield takeLatest(FIELD_UPDATE_REQUEST, fieldUpdateFunction);
  yield takeLatest(FIELD_SHOWHIDE_REQUEST, fieldShowHideFunction);
  yield takeLatest(FIELD_UPDATEPRIORITY_REQUEST, fieldUpdatePriorityFunction);
  yield takeLatest(PRODUCTFIELDS_REQUEST, productFieldsFunction);
  yield takeLatest(PRODUCTADDFIELDS_REQUEST, productAddFieldsFunction);
  yield takeLatest(PRODUCTADD_REQUEST, productAddFunction);
  yield takeLatest(PRODUCTBULKEDIT_REQUEST, productBulkEditFunction);
  yield takeLatest(PRODUCTDELETE_REQUEST, productDeleteFunction);
  yield takeLatest(PRODUCTCATEGORIES_REQUEST, productCategoriesFunction);
  yield takeLatest(PRODUCTCATEGORIESADD_REQUEST, productCategoriesAddFunction);
  yield takeLatest(PRODUCTCATEGORIESEDIT_REQUEST, productCategoriesEditFunction);
  yield takeLatest(PRODUCTCATEGORIESDELETE_REQUEST, productCategoriesDeleteFunction);
  yield takeLatest(PRODUCTTAGS_REQUEST, productTagsFunction);
  yield takeLatest(PRODUCTTAGSADD_REQUEST, productTagsAddFunction);
  yield takeLatest(PRODUCTTAGSEDIT_REQUEST, productTagsEditFunction);
  yield takeLatest(PRODUCTTAGSDELETE_REQUEST, productTagsDeleteFunction);
  yield takeLatest(PRODUCTCELLEDIT_REQUEST, productCellEditFunction);
  yield takeLatest(PRODUCTFILTERS_REQUEST, productFiltersFunction);
  yield takeLatest(PRODUCTFILTERADD_REQUEST, productFilterAddFunction);
  yield takeLatest(PRODUCTFILTEREDIT_REQUEST, productFilterEditFunction);
  yield takeLatest(PRODUCTFILTERFAVORITE_REQUEST, productFilterFavoriteFunction);
  yield takeLatest(PRODUCTFILTERDELETE_REQUEST, productFilterDeleteFunction);
  yield takeLatest(CURRENCY_REQUEST, currencyFunction);
  yield takeLatest(PRODUCTSMERGE_REQUEST, productsMergeFunction);
  yield takeLatest(PRODUCT_SEARCH_REQUEST, productSearchFunction);
  yield takeLatest(PRODUCT_DROPDOWN_ADD_REQUEST, productDropdownAddFunction);
}
