import React from "react";
import "./styles.scss";

export function CustomButtonGroup({ options, handleClick, active }) {
  return (
    <div className="mb-0 d-flex flex-wrap gap-2">
      {options.map((each, index) => {
        return (
          <div
            key={index}
            onClick={() => handleClick(each)}
            className={`border h-35px px-4 py-2 d-flex align-items-center gap-2 rounded-pill cursor-pointer ${each.value === active.value ? "border-primary" : "bg-white"}`}
          >
            <span className={`${each.value === active.value ? "text-primary fw-bold" : ""}`}>{each.label}</span>
          </div>
        );
      })}
    </div>
  );
}

export function CustomActivityButtonGroup({ options, handleClick, active }) {
  return (
    <div className="mb-0 d-flex flex-wrap gap-2">
      {options.map((each, index) => {
        return (
          <div key={index} onClick={() => handleClick(each)} className={`border px-4 py-2 d-flex align-items-center gap-2 rounded-pill cursor-pointer ${each.name === active ? "border-primary" : "bg-white"}`}>
            <i className={`${each.symbName}  ${each.name === active ? "text-primary fw-bold" : ""}`}></i>
            <span className={`${each.name === active ? "text-primary fw-bold" : ""}`}>{each.name}</span>
          </div>
        );
      })}
    </div>
  );
}

export function CustomButtonGroupWithValue({ options, handleClick, active, isCenter }) {
  const _handleClick = (value) => {
    if (value !== active) {
      handleClick(value);
    }
  };
  return (
    <div className={`mb-0 d-flex flex-wrap gap-2 ${isCenter ? "flex-center" : ""}`}>
      {options.map((each, index) => {
        return (
          <div
            key={index}
            onClick={() => _handleClick(each.value)}
            className={`border h-35px px-4 py-2 d-flex align-items-center gap-2 custom-button-group-hover rounded-pill cursor-pointer ${each.value === active ? "border-primary" : "bg-white"}`}
          >
            <span className={`text-hover-primary ${each.value === active ? "text-primary fw-bold" : "text-gray-700"}`}>{each.label}</span>
          </div>
        );
      })}
    </div>
  );
}

export function CustomButtonGroupWithIcons({ options, handleClick, active }) {
  return (
    <div className="mb-0 d-flex flex-wrap gap-2">
      {options.map((each, index) => {
        return (
          <div key={index} onClick={() => handleClick(each)} className={`border px-4 py-2 d-flex align-items-center gap-2 rounded-2 cursor-pointer ${each.value === active.value ? "border-primary" : "bg-white"}`}>
            <span className={`bg-eaf2ff svg-icon d-flex flex-center rounded-circle w-40px h-40px ${each.value === active.value ? "text-primary fw-bold" : ""}`}> {each.icon}</span>
            <span className={`${each.value === active.value ? "text-primary fw-bold" : ""}`}>{each.label}</span>
          </div>
        );
      })}
    </div>
  );
}

export function CustomButtonGroupWithMultiValue({ options, handleClick, active, isCenter }) {
  const handleButtonClick = (_value) => {
    if (active.includes(_value)) {
      handleClick(active.filter((a) => a && a !== _value));
    } else {
      handleClick([...active, _value]);
    }
  };
  return (
    <div className={`mb-0 d-flex flex-wrap gap-2 ${isCenter ? "flex-center" : ""}`}>
      {options.map((each, index) => {
        return (
          <div
            key={index}
            onClick={() => handleButtonClick(each.value)}
            className={`border h-35px px-4 py-2 d-flex align-items-center gap-2 rounded-pill cursor-pointer ${active.includes(each.value) ? "border-primary" : "bg-white"}`}
          >
            <span className={`text-hover-primary ${active.includes(each.value) ? "text-primary fw-bold" : "text-gray-700"}`}>{each.label}</span>
          </div>
        );
      })}
    </div>
  );
}
