import {
  GET_PIPELINE_REQUEST,
  GET_PIPELINE_SUCCESS,
  GET_PIPELINE_ERROR,
  ADD_PIPELINE_REQUEST,
  ADD_PIPELINE_SUCCESS,
  ADD_PIPELINE_ERROR,
  GET_STAGES_REQUEST,
  GET_STAGES_SUCCESS,
  GET_STAGES_ERROR,
  GET_STAGES_REQUEST_AFTER,
  ADD_DEAL_REQUEST,
  ADD_DEAL_SUCCESS,
  ADD_DEAL_ERROR,
  GET_DEAL_REQUEST,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  GET_DEAL_REQUEST_AFTER,
  UPDATE_PIPELINE_REQUEST,
  UPDATE_PIPELINE_SUCCESS,
  UPDATE_PIPELINE_ERROR,
  DELETE_PIPELINE_REQUEST,
  DELETE_PIPELINE_SUCCESS,
  DELETE_PIPELINE_ERROR,
  MOVE_DEALINSTAGES_REQUEST,
  MOVE_DEALINSTAGE_SUCCESS,
  MOVE_DEALINSTAGES_ERROR,
  DEAL_OPERATION_REQUEST,
  DEAL_OPERATION_SUCCESS,
  DEAL_OPERATION_ERROR,
  ADD_STAGEINPIPELINE_REQUEST,
  ADD_SATGEINPIPELINE_SUCCESS,
  ADD_STAGEINPIPELINE_ERROR,
  UPDATE_STAGEINPIPELINE_REQUEST,
  UPDATE_SATGEINPIPELINE_SUCCESS,
  UPDATE_STAGEINPIPELINE_ERROR,
  UPDATE_STAGEORDERINPIPELINE_REQUEST,
  UPDATE_STAGEORDERINPIPELINE_SUCCESS,
  UPDATE_STAGEORDERINPIPELINE_ERROR,
  DELETE_PIPELINESTAGE_REQUEST,
  DELETE_PIPELINESTAGE_SUCCESS,
  DELETE_PIPELINESTAGE_ERROR,
  GET_LOSTREASONS_REQUEST,
  GET_LOSTREASONS_SUCCESS,
  GET_LOSTREASONS_ERROR,
  GET_SAVEDFILTERS_REQUEST,
  GET_SAVEDFILTERS_SUCCESS,
  GET_SAVEDFILTERS_ERROR,
  ADD_SAVEDFILTERS_REQUEST,
  ADD_SAVEDFILTERS_SUCCESS,
  ADD_SAVEDFILTERS_ERROR,
  EDIT_SAVEDFILTERS_REQUEST,
  EDIT_SAVEDFILTERS_SUCCESS,
  EDIT_SAVEDFILTERS_ERROR,
  DELETE_SAVEDFILTERS_REQUEST,
  DELETE_SAVEDFILTERS_SUCCESS,
  DELETE_SAVEDFILTERS_ERROR,
  FAVORITE_SAVEDFILTERS_REQUEST,
  FAVORITE_SAVEDFILTERS_SUCCESS,
  FAVORITE_SAVEDFILTERS_ERROR,
  APPLYFILTER,
  CLEARFILTER,

  //DEAL FILEDS
  GET_DEALFIELDS_REQUEST,
  GET_DEALFIELDS_SUCCESS,
  GET_DEALFIELDS_ERROR,

  //popup-details
  GET_DEALPOPUPDETAILS_REQUEST,
  GET_DEALPOPUPDETAILS_SUCCESS,
  GET_DEALPOPUPDETAILS_ERROR,
  GET_DEALPOPUPDETAILS_REQUEST_AFTER,
  UPDATE_DEALPOPUPDETAILS_REQUEST,
  UPDATE_DEALPOPUPDETAILS_SUCCESS,
  UPDATE_DEALPOPUPDETAILS_ERROR,

  //tags
  GET_DEAL_TAGS_REQUEST,
  GET_DEAL_TAGS_SUCCESS,
  GET_DEAL_TAGS_ERROR,
  DELETE_DEAL_TAGS_REQUEST,
  DELETE_DEAL_TAGS_SUCCESS,
  DELETE_DEAL_TAGS_ERROR,
  UPDATE_DEAL_TAGS_REQUEST,
  UPDATE_DEAL_TAGS_SUCCESS,
  UPDATE_DEAL_TAGS_ERROR,
  ADD_DEAL_TAGS_REQUEST,
  ADD_DEAL_TAGS_SUCCESS,
  ADD_DEAL_TAGS_ERROR,
  DEAL_SEARCH_REQUEST,
  DEAL_SEARCH_SUCCESS,
  DEAL_SEARCH_ERROR,
  CHANGE_DEALPERSON_REQUEST,
  CHANGE_DEALPERSON_SUCCESS,
  CHANGE_DEALPERSON_ERROR,
  CHANGE_DEALCOMPANY_REQUEST,
  CHANGE_DEALCOMPANY_SUCCESS,
  CHANGE_DEALCOMPANY_ERROR,
  CHANGE_DEALDETAILSINPOPUP_REQUEST,
  CHANGE_DEALDETAILSINPOPUP_SUCCESS,
  CHANGE_DEALDETAILSINPOPUP_ERROR,
  HIDE_WON_DEAL_ICON,
  MARKASDEFAULT_REQUEST,
  MARKASDEFAULT_SUCCESS,
  MARKASDEFAULT_ERROR,
  UPDATE_DEAL_PIPELINE_LOADING_DATA,
} from "./types";

const initialState = {
  pipelineOptionsResponse: [],
  loading: "",
  stagesData: {
    loading: false,
    data: {},
  },
  stagesLoading: true,
  dealsDataResponse: [],
  dealsLoading: true,
  lostReasons: [],
  dealFilters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  dealFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  dealmarkedAsDone: false,
  // dealpopupDetails: {}
  dealPopup: {
    loading: "",
    data: {},
    cellLoading: false,
    cellId: "",
  },
  updatePopupDetails: false,
  afterLoad: false,
  dealTags: {
    loading: "",
    list: [],
  },
  dealDropdownList: {
    loading: "",
    list: [],
  },
  AddPipelineData: {},
  pipelineOptionsLoading: true,
  markAsDefaultData: {},
  loadingData: {},
  // you can define required variables here
};

export function dealsReducer(state = initialState, action = {}) {
  switch (action.type) {
    //mark as default pipeline
    case MARKASDEFAULT_REQUEST:
      return {
        ...state,
      };
    case MARKASDEFAULT_SUCCESS:
      return {
        ...state,
        markAsDefaultData: action.payload,
      };
    case MARKASDEFAULT_ERROR:
      return {
        ...state,
      };
    case HIDE_WON_DEAL_ICON:
      return {
        ...state,
        dealmarkedAsDone: action.payload,
      };
    //Update loading data
    case UPDATE_DEAL_PIPELINE_LOADING_DATA:
      return {
        ...state,
        loadingData: action.payload,
      };

    //deal details change in popup
    case CHANGE_DEALDETAILSINPOPUP_REQUEST:
      return {
        ...state,
        dealPopup: {
          ...state.dealPopup,
          cellId: action.payload.cellId,
          cellLoading: action.payload.cellId,
        },
      };
    case CHANGE_DEALDETAILSINPOPUP_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DEALDETAILSINPOPUP_ERROR:
      return {
        ...state,
      };
    //person change
    case CHANGE_DEALPERSON_REQUEST:
      return {
        ...state,
      };
    case CHANGE_DEALPERSON_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DEALPERSON_ERROR:
      return {
        ...state,
      };
    case CHANGE_DEALCOMPANY_REQUEST:
      return {
        ...state,
      };
    case CHANGE_DEALCOMPANY_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DEALCOMPANY_ERROR:
      return {
        ...state,
      };
    //search
    case DEAL_SEARCH_REQUEST:
      return {
        ...state,
        dealDropdownList: { ...state.dealDropdownList, loading: true },
      };
    case DEAL_SEARCH_SUCCESS:
      return {
        ...state,
        dealDropdownList: { ...state.dealDropdownList, loading: false, list: action.payload.data },
      };
    case DEAL_SEARCH_ERROR:
      return {
        ...state,
        dealDropdownList: { ...state.dealDropdownList, loading: false },
      };
    //updateDeal popupdetails
    case UPDATE_DEALPOPUPDETAILS_REQUEST:
      return {
        ...state,
        dealPopup: {
          ...state.dealPopup,
          cellLoading: action.payload.cellId,
        },
      };
    case UPDATE_DEALPOPUPDETAILS_SUCCESS:
      return {
        ...state,
        updatePopupDetails: true,
      };
    case UPDATE_DEALPOPUPDETAILS_ERROR:
      return {
        ...state,
      };
    //popup details
    case GET_DEALPOPUPDETAILS_REQUEST:
      return {
        ...state,
        dealPopup: { ...state.dealPopup, loading: true, data: [] },
      };
    case GET_DEALPOPUPDETAILS_REQUEST_AFTER:
      return {
        ...state,
      };
    case GET_DEALPOPUPDETAILS_SUCCESS:
      return {
        ...state,
        dealPopup: { ...state.dealPopup, loading: false, data: action.payload, cellId: null, cellLoading: false },
      };
    case GET_DEALPOPUPDETAILS_ERROR:
      return {
        ...state,
        // dealPopup:{loading:false,data:{}},
      };
    case GET_DEALFIELDS_REQUEST:
      return {
        ...state,
        dealFields: { ...state.dealFields, loading: true },
      };
    case GET_DEALFIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        dealFields: { ...state.dealFields, loading: false, list: action.payload, idToIndex: tempObj },
      };
    case GET_DEALFIELDS_ERROR:
      return {
        ...state,
        dealFields: { ...state.dealFields, loading: false },
        // error:{err:true,errMsg:action.payload.message}
      };
    case APPLYFILTER:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, activeFilter: action.payload },
      };
    case CLEARFILTER:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, activeFilter: null },
      };
    case GET_SAVEDFILTERS_REQUEST:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: true },
      };
    case GET_SAVEDFILTERS_SUCCESS:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: false, list: action.payload.data },
      };
    case GET_SAVEDFILTERS_ERROR:
      return {
        ...state,
        dealFilters: { ...state.productList, loading: false },
        // error:{err:true,errMsg:action.payload.message}
      };
    case ADD_SAVEDFILTERS_REQUEST:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: true },
      };
    case ADD_SAVEDFILTERS_SUCCESS:
      return {
        ...state,
        // dealFilters:{...state.dealFilters,loading:false,list:action.payload.data},
      };
    case ADD_SAVEDFILTERS_ERROR:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: false },
      };

    case EDIT_SAVEDFILTERS_REQUEST:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: true },
      };
    case EDIT_SAVEDFILTERS_SUCCESS:
      return {
        ...state,
        // dealFilters:{...state.dealFilters,loading:false,list:action.payload.data},
      };
    case EDIT_SAVEDFILTERS_ERROR:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: false },
      };

    case DELETE_SAVEDFILTERS_REQUEST:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: true },
      };
    case DELETE_SAVEDFILTERS_SUCCESS:
      return {
        ...state,
        // dealFilters:{...state.dealFilters,loading:false,list:action.payload.data},
      };
    case DELETE_SAVEDFILTERS_ERROR:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: false },
      };

    case FAVORITE_SAVEDFILTERS_REQUEST:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: true },
      };
    case FAVORITE_SAVEDFILTERS_SUCCESS:
      return {
        ...state,
        // dealFilters:{...state.dealFilters,loading:false,list:action.payload.data},
      };
    case FAVORITE_SAVEDFILTERS_ERROR:
      return {
        ...state,
        dealFilters: { ...state.dealFilters, loading: false },
      };

    case GET_LOSTREASONS_REQUEST:
      return {
        ...state,
        lostReasons: [],
      };
    case GET_LOSTREASONS_SUCCESS:
      return {
        ...state,
        lostReasons: action.payload,
      };
    case GET_LOSTREASONS_ERROR:
      return {
        ...state,
        lostReasons: [],
      };
    //get pipeline reducer
    case GET_PIPELINE_REQUEST:
      return {
        ...state,
        pipelineOptionsResponse: [],
        loading: true,
        pipelineOptionsLoading: true,
      };
    case GET_PIPELINE_SUCCESS:
      return {
        ...state,
        pipelineOptionsResponse: action.payload,
        loading: false,
        pipelineOptionsLoading: false,
      };
    case GET_PIPELINE_ERROR:
      return {
        ...state,
        pipelineOptionsResponse: [],
        loading: true,
        pipelineOptionsLoading: false,
      };
    //add pipeline reducer
    case ADD_PIPELINE_REQUEST:
      return {
        ...state,
      };
    case ADD_PIPELINE_SUCCESS:
      return {
        ...state,
        AddPipelineData: action.payload,
      };
    case ADD_PIPELINE_ERROR:
      return {
        ...state,
      };
    case UPDATE_PIPELINE_REQUEST:
      return {
        ...state,
      };
    case UPDATE_PIPELINE_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_PIPELINE_ERROR:
      return {
        ...state,
      };
    case DELETE_PIPELINE_REQUEST:
      return {
        ...state,
      };
    case DELETE_PIPELINE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_PIPELINE_ERROR:
      return {
        ...state,
      };
    //get stages reducer
    case GET_STAGES_REQUEST:
      return {
        ...state,
        stagesLoading: true,
        stagesData: {
          ...state.stagesData,
          loading: true,
        },
      };
    case GET_STAGES_REQUEST_AFTER:
      return {
        ...state,
        stagesLoading: false,
        stagesData: {
          ...state.stagesData,
          loading: false,
        },
      };
    case GET_STAGES_SUCCESS:
      return {
        ...state,
        stagesLoading: false,
        stagesData: {
          ...state.stagesData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_STAGES_ERROR:
      return {
        ...state,
        stagesLoading: true,
        stagesData: {
          ...state.stagesData,
          loading: false,
        },
      };
    //add deal reducer
    case ADD_DEAL_REQUEST:
      return {
        ...state,
        // pipelineOptionsResponse: {},
      };
    case ADD_DEAL_SUCCESS:
      return {
        ...state,
        stagesData: {
          ...state.stagesData,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_DEAL_ERROR:
      return {
        ...state,
      };
    case GET_DEAL_REQUEST:
      return {
        ...state,
        dealsDataResponse: [],
        dealsLoading: true,
      };
    case GET_DEAL_REQUEST_AFTER:
      return {
        ...state,
        dealsLoading: true,
        stagesLoading: false,
        afterLoad: true,
        // dealsDataResponse: []
      };
    case GET_DEAL_SUCCESS:
      return {
        ...state,
        dealsDataResponse: action.payload,
        dealsLoading: false,
      };
    case GET_DEAL_ERROR:
      return {
        ...state,
        dealsDataResponse: action.payload,
        dealsLoading: true,
      };
    case MOVE_DEALINSTAGES_REQUEST:
      return {
        ...state,
        // dealsDataResponse:[],
        dealsLoading: true,
      };
    case MOVE_DEALINSTAGE_SUCCESS:
      return {
        ...state,
        dealsLoading: false,
      };
    case MOVE_DEALINSTAGES_ERROR:
      return {
        ...state,
        dealsLoading: true,
      };
    case DEAL_OPERATION_REQUEST:
      return {
        ...state,
      };
    case DEAL_OPERATION_SUCCESS:
      return {
        ...state,
        dealmarkedAsDone: true,
      };
    case DEAL_OPERATION_ERROR:
    case ADD_STAGEINPIPELINE_REQUEST:
    case ADD_SATGEINPIPELINE_SUCCESS:
    case ADD_STAGEINPIPELINE_ERROR:
    case UPDATE_STAGEINPIPELINE_REQUEST:
    case UPDATE_SATGEINPIPELINE_SUCCESS:
    case UPDATE_STAGEINPIPELINE_ERROR:
    case UPDATE_STAGEORDERINPIPELINE_REQUEST:
    case UPDATE_STAGEORDERINPIPELINE_SUCCESS:
    case UPDATE_STAGEORDERINPIPELINE_ERROR:
    case DELETE_PIPELINESTAGE_REQUEST:
    case DELETE_PIPELINESTAGE_SUCCESS:
    case DELETE_PIPELINESTAGE_ERROR:
      return {
        ...state,
      };

    // GET Tags
    case GET_DEAL_TAGS_REQUEST:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: true },
      };
    case GET_DEAL_TAGS_SUCCESS:
      return {
        ...state,
        dealTags: { loading: false, list: action.payload },
      };
    case GET_DEAL_TAGS_ERROR:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Tag
    case ADD_DEAL_TAGS_REQUEST:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: true },
      };
    case ADD_DEAL_TAGS_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case ADD_DEAL_TAGS_ERROR:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Tag
    case UPDATE_DEAL_TAGS_REQUEST:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: true },
      };
    case UPDATE_DEAL_TAGS_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case UPDATE_DEAL_TAGS_ERROR:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Tag
    case DELETE_DEAL_TAGS_REQUEST:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: true },
      };
    case DELETE_DEAL_TAGS_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case DELETE_DEAL_TAGS_ERROR:
      return {
        ...state,
        dealTags: { ...state.dealTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    default:
      return state;
  }
}
