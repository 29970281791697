import React, { useState } from "react";
import PaginationComponent from "./pagination";

export default function DynamicNormalTable(props) {
  const [heading] = useState(props.tableHeading);
  const [tableTitle] = useState(props.tableTitle);

  return (
    <>
      <div className="card mb-5 mb-xl-10 border-style-hidden">
        {/*begin::Card header*/}
        <div className={`card-header d-${props.ddsplay}`}>
          {/*begin::Heading*/}
          <div className="card-title">
            <h3 className="fw-bolder m-0">{tableTitle}</h3>
          </div>
          {/*end::Heading*/}
          {/*begin::Toolbar*/}
          <div className="card-toolbar">{props.buttonHead}</div>
          {/*end::Toolbar*/}
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            {/*begin::Table*/}
            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
              {/*begin::Thead className="min-w-200px"*/}
              <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  {heading.map((tableHead, i) => {
                    return <td key={i}>{tableHead}</td>;
                  })}
                </tr>
              </thead>
              {/*end::Thead*/}
              {/*begin::Tbody*/}
              <tbody className="fw-6 fw-bold text-gray-600">
                {props.data.map((eachData, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a href="/#" className="text-hover-primary text-gray-600">
                          USA(5)
                        </a>
                      </td>
                      <td>
                        <span className="badge badge-light-success fs-7 fw-bolder">OK</span>
                      </td>
                      <td>Chrome - Windows</td>
                      <td>236.125.56.78</td>
                      <td>2 mins ago</td>
                    </tr>
                  );
                })}
                {props.children}
              </tbody>
              {/*end::Tbody*/}
            </table>
            {/*end::Table*/}
          </div>
        </div>
        <div className="card-body p-0 d-flex justify-content-end">
          <PaginationComponent total={props.total} currentPage={props.currentPage} itemsPerPage={props.itemsPerPage} onPageChange={props.onPageChange} />
        </div>
      </div>
    </>
  );
}
