import React, { useState } from "react";
import LandingPagePreview from "./landing-page.preview";
import WidgetPreview from "./widget.preview";

export default function Preview(props) {
  const tabsList = ["Lead Chat Bot", "Lead Landing Page"];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="d-flex justify-content-center">
        {tabsList.map((tabItem, index) => (
          <div key={index} className={`${tabItem === tabsList[activeTab] ? "border-bottom-width-3px border-bottom-style-solid border-bottom-color-000000" : ""}`}>
            <button className={`btn tab-font ${tabItem === tabsList[activeTab] ? "fw-500" : ""}`} onClick={() => setActiveTab(index)}>
              {tabItem}
            </button>
          </div>
        ))}
      </div>
      <div className="card box-sizing-border-box h-vh-80px m-20px p-20px">
        {activeTab === 0 ? (
          <WidgetPreview customization={props.customization} thankYouScreen={props.thankYouScreen} welcomeScreen={props.welcomeScreen} />
        ) : (
          <LandingPagePreview customization={props.customization} thankYouScreen={props.thankYouScreen} welcomeScreen={props.welcomeScreen} />
        )}
      </div>
    </>
  );
}
