import React, { useEffect, useState, useCallback } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GreenGradientButton } from "../../../../components/button";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import ShareUserInput from "../../../../components/custom-inputs/share-user-input";

export default function ShareWorkflow(props) {
  const [loading, setLoading] = useState(false);
  const [sharing, setSharing] = useState([]);

  const dispatch = useDispatch();

  const userRole = useSelector((store) => store.insight.userRole);

  const getSharingInvertInfo = useCallback(
    (_sharedAll, _sharedTeams, _sharedUsers) => {
      if (_sharedAll) {
        return [{ id: "all", label: "All" }];
      } else {
        const a = [],
          _obj = {};
        if (Array.isArray(userRole.list.teams)) {
          userRole.list.teams.forEach((each) => {
            _obj[each.id] = { id: each.id, label: each.name };
          });
        }
        if (Array.isArray(userRole.list.allUsers)) {
          userRole.list.allUsers.forEach((each) => {
            _obj[each.id] = { id: each.id, label: each.name };
          });
        }
        _sharedUsers.forEach((each) => {
          if (_obj[each]) {
            a.push(_obj[each]);
          }
        });
        _sharedTeams.forEach((each) => {
          if (_obj[each]) {
            a.push(_obj[each]);
          }
        });

        return a;
      }
    },
    [userRole.list.allUsers, userRole.list.teams],
  );

  useEffect(() => {
    if (props.shareWorkflowData) {
      setLoading(true);
      setSharing(getSharingInvertInfo(props.shareWorkflowData.sharedAll || false, props.shareWorkflowData.sharedTeams || [], props.shareWorkflowData.sharedUsers || []));
      setLoading(false);
    }
  }, [dispatch, props.shareWorkflowData, getSharingInvertInfo]);

  const getSharingInfo = () => {
    const sharedUsers = [],
      sharedTeams = [];
    let sharedAll = false;

    const userIds = [],
      teamIds = [];

    if (Array.isArray(userRole.list.teams)) {
      userRole.list.teams.forEach((each) => {
        teamIds.push(each.id);
      });
    }
    if (Array.isArray(userRole.list.allUsers)) {
      userRole.list.allUsers.forEach((each) => {
        userIds.push(each.id);
      });
    }

    sharing.forEach((each) => {
      if (each.id === "all") {
        sharedAll = true;
      } else if (userIds.includes(each.id)) {
        sharedUsers.push(each.id);
      } else if (teamIds.includes(each.id)) {
        sharedTeams.push(each.id);
      }
    });

    return { sharedAll, sharedUsers, sharedTeams };
  };

  const handleShare = () => {
    props.handleShare(getSharingInfo());
  };

  return (
    <BlankSidePanel show={props.show} handleClose={props.handleClose} title={"Share Worflow"}>
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <Form.Group id="automation-workflowlist-share-workflow-model-share">
            <Form.Label aria-label="share">Share</Form.Label>
            <ShareUserInput defaultValue={sharing} setValue={setSharing} />
          </Form.Group>
          <Form.Group id="automation-workflowlist-share-workflow-model-save-button" className="d-flex justify-content-center">
            <GreenGradientButton size="sm" onClick={handleShare}>
              {"Save"}
            </GreenGradientButton>
          </Form.Group>
        </div>
      )}
    </BlankSidePanel>
  );
}
