import React from "react";
import { InputLimit } from "../../constants";
import EmailToRecipientField from "../../containers/Automation/workflow/workflow-builder/component/email-to-recipient.field";

export const ToFieldTagInputBox = ({ title, type, validEmails, onKeyDown, onBlur, inputValue, setInputChange, showcc, showBcc, setShowcc, setShowBcc, removeEmail }) => {
  return (
    <div className="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
      <div className="text-dark-50 mr-2">{title}:</div>
      <div className="d-flex align-items-center flex-grow-1 flex-wrap">
        {validEmails.length > 0 &&
          validEmails.map((each, index) => {
            return (
              <div key={index} className="sc-tag-wrapper">
                <div>
                  <span className="sc-tag-text">{each}</span>{" "}
                </div>
                <i onClick={() => removeEmail(type, each)} className="bi bi-x"></i>
              </div>
            );
          })}
        <input
          type="text"
          maxLength={InputLimit.TEXT}
          className="form-control border-0 width-fit-content"
          name="compose_to"
          value={inputValue}
          onKeyDown={(e) => onKeyDown(e, type)}
          onBlur={() => onBlur(type)}
          onChange={(e) => setInputChange(e.target.value)}
        />
      </div>
      <div className="ml-2">
        {!showcc && (
          <span className="text-muted font-weight-bold cursor-pointer text-hover-primary mr-2" onClick={() => setShowcc(true)} data-inbox="cc-show">
            Cc
          </span>
        )}
        {!showBcc && (
          <span className="text-muted font-weight-bold cursor-pointer text-hover-primary" onClick={() => setShowBcc(true)} data-inbox="bcc-show">
            Bcc
          </span>
        )}
      </div>
    </div>
  );
};

export const TagInputBox = ({ title, type, validEmails, onKeyDown, onBlur, inputValue, setInputChange, removeEmail, onRemoveField }) => {
  return (
    <div className="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
      <div className="text-dark-50 mr-2">{title}:</div>
      <div className="d-flex align-items-center flex-grow-1 flex-wrap">
        {validEmails.length > 0 &&
          validEmails.map((each, index) => {
            return (
              <div key={index} className="sc-tag-wrapper">
                <div>
                  <span className="sc-tag-text">{each}</span>{" "}
                </div>
                <i onClick={() => removeEmail(type, each)} className="bi bi-x"></i>
              </div>
            );
          })}
        <input
          type="text"
          maxLength={InputLimit.TEXT}
          className="form-control border-0 width-auto"
          name="compose_to"
          value={inputValue}
          onKeyDown={(e) => onKeyDown(e, type)}
          onBlur={() => onBlur(type)}
          onChange={(e) => setInputChange(e.target.value)}
        />
      </div>
      <i className="bi bi-x cursor-pointer" onClick={onRemoveField}></i>
    </div>
  );
};

export const TagInputBox2 = ({ type, validEmails, onKeyDown, onBlur, inputValue, setInputChange, removeEmail, onRemoveField }) => {
  return (
    <div className="d-flex align-items-center border-bottom inbox-to pr-8 min-h-45px form-control">
      <div className="d-flex align-items-center flex-grow-1 flex-wrap">
        {validEmails.length > 0 &&
          validEmails.map((each, index) => {
            return (
              <div key={index} className="sc-tag-wrapper">
                <div>
                  <span className="sc-tag-text">{each}</span>{" "}
                </div>
                <i onClick={() => removeEmail(type, each)} className="bi bi-x"></i>
              </div>
            );
          })}
        <input
          type="text"
          maxLength={InputLimit.TEXT}
          className="form-control border-0 width-auto"
          name="compose_to"
          value={inputValue}
          onKeyDown={(e) => onKeyDown(e, type)}
          onBlur={() => onBlur(type)}
          onChange={(e) => setInputChange(e.target.value)}
        />
      </div>
      <i className="bi bi-x cursor-pointer" onClick={onRemoveField}></i>
    </div>
  );
};

export const ToFieldTagInputBoxWithDropdown = ({ title, validEmails, setInputChange, showcc, showBcc, setShowcc, setShowBcc }) => {
  return (
    <div className="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
      <div className="text-dark-50 mr-2">{title}:</div>
      <div className="d-flex align-items-center flex-grow-1 flex-wrap">
        <EmailToRecipientField value={validEmails} setValue={setInputChange} />
      </div>
      <div className="ml-2">
        {!showcc && (
          <span className="text-muted font-weight-bold cursor-pointer text-hover-primary mr-2" onClick={() => setShowcc(true)} data-inbox="cc-show">
            Cc
          </span>
        )}
        {!showBcc && (
          <span className="text-muted font-weight-bold cursor-pointer text-hover-primary" onClick={() => setShowBcc(true)} data-inbox="bcc-show">
            Bcc
          </span>
        )}
      </div>
    </div>
  );
};
