import React from "react";
import { Dropdown } from "react-bootstrap";
import FormInput from "../../../../../components/Form-Field/index";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../../users/style.css";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function EditSystemFields({
  type,
  systemFieldEditPopupShow,
  handleSystemFieldCLoseModel,
  selectedSystemField,
  FieldTypeMapping,
  isSystemFieldRequiredInAddForm,
  handleIsSystemFieldRequiredInAddForm,
  handleSystemFieldSubmitModel,
}) {
  return (
    <Modal show={systemFieldEditPopupShow} onHide={handleSystemFieldCLoseModel}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {toTitleCase(type)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12 fv-row">
          <span className="fw-500 font-size-14px">Field Name*</span>
          <FormInput disabled={true} placeholder={"Enter field name"} type={"text"} name={"nameField"} value={selectedSystemField.fieldName} />
        </div>
        <br></br>
        <div className="col-lg-12 fv-row">
          <span className="fw-500 font-size-14px">Field Type*</span>
          <Dropdown drop="down">
            <Dropdown.Toggle disabled id="newSS" className="newDropDesign">
              {FieldTypeMapping[selectedSystemField.fieldInputType] ? FieldTypeMapping[selectedSystemField.fieldInputType] : selectedSystemField.fieldInputType}
            </Dropdown.Toggle>
          </Dropdown>
        </div>
        <br></br>

        <div className="d-flex justify-content-between w-100">
          <div className="col-lg-8 fv-row d-flex align-items-center">
            <label className="checkbox checkbox-primary fw-500 font-size-14px">
              <input type="checkbox" checked={isSystemFieldRequiredInAddForm} onChange={(e) => handleIsSystemFieldRequiredInAddForm(e)} name="Checkboxes5" />
              <span></span>
              &nbsp;&nbsp; {`Add field in ${toTitleCase(type)} form`}
            </label>
            <span className="ml-10px">
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{`it will add curent field in add ${toTitleCase(type)} form`}</Tooltip>}>
                <i className="bi bi-info-circle"></i>
              </OverlayTrigger>
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleSystemFieldCLoseModel}>
          Close
        </Button>
        <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={handleSystemFieldSubmitModel}>
          Edit Field
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
