import React from "react";
import {
  //  Dropdown, Spinner,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { InputLimit } from "../../../../constants";

export default function DashboardModel({ showDashboardModel, handleDashboardClose, isDashboardEdit, dashboardName, onDashboardNameChange, onAddDashboard }) {
  return (
    <Modal show={showDashboardModel} onHide={handleDashboardClose} dialogClassName="modal-50w">
      <Modal.Header closeButton>
        <Modal.Title>{isDashboardEdit ? "Edit" : "Add"} Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 mb-3">
          <Form.Control
            maxLength={InputLimit.TEXT}
            value={dashboardName}
            type="text"
            placeholder="Dashboard Name"
            onChange={(e) => {
              onDashboardNameChange(e);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={handleDashboardClose}>
          Close
        </Button>
        <Button className="saveCommponbutton btn btn-sm btn-primary my-1" variant="primary" onClick={() => onAddDashboard()}>
          {isDashboardEdit ? "Edit" : "Add"} Dashboard{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
