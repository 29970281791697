import { bulkEditCompanyV2, bulkDeleteCompanyV2 } from "../types/company-operation.types";

export const bulkEditCompanyV2Request = (payload, cb) => ({
  type: bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_REQUEST,
  payload,
  cb,
});
export const bulkEditCompanyV2Success = (payload) => ({
  type: bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_SUCCESS,
  payload,
});
export const bulkEditCompanyV2Error = (payload) => ({
  type: bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_ERROR,
  payload,
});

export const bulkDeleteCompanyV2Request = (payload, cb) => ({
  type: bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_REQUEST,
  payload,
  cb,
});
export const bulkDeleteCompanyV2Success = (payload) => ({
  type: bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_SUCCESS,
  payload,
});
export const bulkDeleteCompanyV2Error = (payload) => ({
  type: bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_ERROR,
  payload,
});
