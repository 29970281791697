import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Accordion, Card, Tooltip, OverlayTrigger, ListGroup, Spinner, Button, Popover, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { linkLeadDealPeopleWithCompanyRequest, loadMoreLinkedDataRequest, unlinkLeadDealPeopleFromCompanyRequest } from "../../../../containers/companies/store/actions/actions";
import { GreenGradientButton } from "../../../button";
import LeadDealPeopleSearchSelect from "../../../custom_form/leadDealPeopleSearch";
import { IconBasedSimpleDropdownMenu } from "../../../dropdowns/icons-based-dropdown";
import { isSameModuleAsUrl } from "../../components/helper";
import InlineStyled from "../../../inline-styled/inline-styled";

export function CompanyLeadLink(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchFieldItem, setSearchFieldItem] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.sidePanelProps.linkedLeads) {
      setData(props.sidePanelProps.linkedLeads);
    }
  }, [props.sidePanelProps.linkedLeads]);

  const onItemNameClick = (id) => {
    if (!isSameModuleAsUrl(props.basePath, "lead")) {
      history.push(`${props.basePath}/lead/${id}`);
    } else {
      history.push({ pathname: `/prospects/leads/active/${id}`, state: { id: id } });
    }
  };

  const onLoadMoreClick = () => {
    setLoading(true);
    dispatch(
      loadMoreLinkedDataRequest(
        {
          data: { module: "lead", limit: 10, pageNo: pageNo + 1 },
          id: props.companyId,
        },
        (data1) => {
          setTimeout(() => setLoading(false), 100);
          if (data1.status) {
            const oldData = [...data, ...data1.data];
            setData(oldData);
            setPageNo(pageNo + 1);
          }
        },
      ),
    );
  };

  const unlinkDatafromCompany = (data) => {
    let dataToSend = {
      data: {
        type: "lead",
        id: data._id,
      },
      companyId: props.companyId,
    };
    dispatch(unlinkLeadDealPeopleFromCompanyRequest(dataToSend));
  };

  const onLinkDataWithCompanySave = () => {
    let dataToSend = {
      data: {
        type: "lead",
        id: searchFieldItem,
      },
      companyId: props.companyId,
    };
    dispatch(linkLeadDealPeopleWithCompanyRequest(dataToSend));
    setSearchFieldItem("");
    document.body.click();
    setShow(false);
  };

  const onLinkDataWithCompanyCancel = () => {
    document.body.click();
    setShow(false);
    setSearchFieldItem("");
  };

  const linkWithCompany = (type) => {
    return (
      <Popover className="w-300px" id="another person and company" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">{"Link lead"} </Popover.Title>
        <Popover.Content>
          <Form.Group id="company-lead-link-with-company">
            <Form.Label aria-label="lead">Lead</Form.Label>
            <LeadDealPeopleSearchSelect
              field={"linkWithCompany"}
              value={searchFieldItem}
              setValue={(value) => {
                setSearchFieldItem(value);
              }}
              dontShowAdd={true}
              companyId={props.companyId}
              type={"lead"}
            />
          </Form.Group>
          <div className="d-flex flex-row justify-content-center gap-15px mt-15px">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onLinkDataWithCompanyCancel();
              }}
              size="sm"
            >
              Cancel
            </Button>
            <GreenGradientButton
              onClick={(e) => {
                e.stopPropagation();
                onLinkDataWithCompanySave();
              }}
              size="sm"
            >
              Add
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <Draggable draggableId={"company-lead-link"} index={props.index} key={props.index}>
      {(provided, snapshot) => (
        <Accordion
          as={Card}
          defaultActiveKey={isOpen ? "toggle-lead" : null}
          onSelect={() => setIsOpen(!isOpen)}
          className={`${props.isLast ? "mb-30" : "mb-4"} ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{ ...provided.draggableProps.style }}
        >
          <Accordion.Toggle eventKey={"toggle-lead"} className="cursor-pointer p-14px bg-white h-0px-mn" as={Card.Header}>
            <div className="d-flex flex-stack w-100">
              <div className="d-flex align-items-center gap-2">
                <i className="bi bi-bullseye side-pannel-card-header-color"></i>
                <span className="fs-6 m-0 side-pannel-card-header-color">Lead ({props.sidePanelProps.linkedLeadsCount || 0})</span>
                <i className={`side-panel-data-icon fas fa-sm fa-chevron-${isOpen ? "up" : "down"} side-pannel-card-header-color`}></i>
              </div>
              <div className="d-flex align-items-center gap-4">
                <IconBasedSimpleDropdownMenu show={show} setShow={setShow} label={"Link Lead"} icon={"bi bi-plus-square"}>
                  {linkWithCompany("lead")}
                </IconBasedSimpleDropdownMenu>
                <i className="fa fa-bars fa-md cursor-grab" aria-hidden="true" onClick={(e) => e.stopPropagation()} {...provided.dragHandleProps}></i>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={"toggle-lead"}>
            <InlineStyled as={Card.Body} onClick={(e) => e.stopPropagation()} $styles={{ padding: "1rem" }}>
              <ListGroup>
                {data.map((each, ind) => {
                  return (
                    <ListGroup.Item className="d-flex flex-stack border-0 px-0" key={ind}>
                      <span className="text-primary cursor-pointer" onClick={() => onItemNameClick(each._id)}>
                        {" "}
                        {each?.name}
                      </span>
                      <span>
                        <OverlayTrigger overlay={<Tooltip>Remove</Tooltip>}>
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              unlinkDatafromCompany(each);
                            }}
                            className="bi bi-dash-circle-fill cursor-pointer text-hover-danger"
                          ></i>
                        </OverlayTrigger>
                      </span>
                    </ListGroup.Item>
                  );
                })}

                {props.sidePanelProps.linkedLeadsCount > data.length && (
                  <ListGroup.Item className="d-flex flex-stack border-0 px-0 justify-content-center " key={-1}>
                    <span
                      className="cursor-pointer btn btn-secondary btn-sm d-flex justify-content-center align-items-center gap-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        onLoadMoreClick();
                      }}
                    >
                      {loading && <Spinner className="w-18px h-18px" animation="border" variant="primary" />}
                      Load More
                    </span>
                  </ListGroup.Item>
                )}
              </ListGroup>
              {data.length === 0 && (
                <div data-kt-search-element="empty" className="text-center">
                  <div className="pt-10 pb-10">
                    <span className="svg-icon svg-icon-4x opacity-50">
                      <i className="bi bi-bullseye font-size-30px"></i>
                    </span>
                  </div>
                  <div className="pb-15 fw-bold">
                    <h3 className="text-gray-600 fs-5 mb-2">There are no leads linked.</h3>
                    <div className="text-muted fs-7">All leads associated with the current company will be displayed here.</div>
                  </div>
                </div>
              )}
            </InlineStyled>
          </Accordion.Collapse>
        </Accordion>
      )}
    </Draggable>
  );
}
