export const PEOPLES_REQUEST = "PEOPLES_REQUEST";
export const PEOPLES_SUCCESS = "PEOPLES_SUCCESS";
export const PEOPLES_ERROR = "PEOPLES_ERROR";

export const PEOPLES_UPDATE_REQUEST = "PEOPLES_UPDATE_REQUEST";
export const PEOPLES_UPDATE_SUCCESS = "PEOPLES_UPDATE_SUCCESS";
export const PEOPLES_UPDATE_ERROR = "PEOPLES_UPDATE_ERROR";

export const LOADING_DATA_UPDATE = "PEOPLE_LOADING_DATA_UPDATE_REQUEST";

export const FIELD_UPDATE_REQUEST = "PEOPLE_FIELD_UPDATE_REQUEST";
export const FIELD_UPDATE_SUCCESS = "PEOPLE_FIELD_UPDATE_SUCCESS";
export const FIELD_UPDATE_ERROR = "PEOPLE_FIELD_UPDATE_ERROR";

export const FIELD_SHOWHIDE_REQUEST = "PEOPLE_FIELD_SHOWHIDE_REQUEST";
export const FIELD_SHOWHIDE_SUCCESS = "PEOPLE_FIELD_SHOWHIDE_SUCCESS";
export const FIELD_SHOWHIDE_ERROR = "PEOPLE_FIELD_SHOWHIDE_ERROR";

export const FIELD_UPDATEPRIORITY_REQUEST = "PEOPLE_FIELD_UPDATEPRIORITY_REQUEST";
export const FIELD_UPDATEPRIORITY_SUCCESS = "PEOPLE_FIELD_UPDATEPRIORITY_SUCCESS";
export const FIELD_UPDATEPRIORITY_ERROR = "PEOPLE_FIELD_UPDATEPRIORITY_ERROR";

export const PEOPLE_POPUP_REQUEST = "PEOPLE_POPUP_REQUEST";
export const PEOPLE_POPUP_SUCCESS = "PEOPLE_POPUP_SUCCESS";
export const PEOPLE_POPUP_ERROR = "PEOPLE_POPUP_ERROR";
export const PEOPLE_POPUP_REQUEST_AFTER = "PEOPLE_POPUP_REQUEST_AFTER";

export const PEOPLE_POPUP_EDIT_REQUEST = "PEOPLE_POPUP_EDIT_REQUEST";
export const PEOPLE_POPUP_EDIT_SUCCESS = "PEOPLE_POPUP_EDIT_SUCCESS";
export const PEOPLE_POPUP_EDIT_ERROR = "PEOPLE_POPUP_EDIT_ERROR";

export const PEOPLE_POPUP_UPDATE_REQUEST = "PEOPLE_POPUP_UPDATE_REQUEST";
export const PEOPLE_POPUP_UPDATE_SUCCESS = "PEOPLE_POPUP_UPDATE_SUCCESS";
export const PEOPLE_POPUP_UPDATE_ERROR = "PEOPLE_POPUP_UPDATE_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const PEOPLEDROPDOWN_REQUEST = "PEOPLEDROPDOWN_REQUEST";
export const PEOPLEDROPDOWN_SUCCESS = "PEOPLEDROPDOWN_SUCCESS";
export const PEOPLEDROPDOWN_ERROR = "PEOPLEDROPDOWN_ERROR";

export const PEOPLE_LEAD_DROPDOWN_REQUEST = "PEOPLE_LEAD_DROPDOWN_REQUEST";
export const PEOPLE_LEAD_DROPDOWN_SUCCESS = "PEOPLE_LEAD_DROPDOWN_SUCCESS";
export const PEOPLE_LEAD_DROPDOWN_ERROR = "PEOPLE_LEAD_DROPDOWN_ERROR";

export const PEOPLEDROPDOWNADD_REQUEST = "PEOPLEDROPDOWNADD_REQUEST";
export const PEOPLEDROPDOWNADD_SUCCESS = "PEOPLEDROPDOWNADD_SUCCESS";
export const PEOPLEDROPDOWNADD_ERROR = "PEOPLEDROPDOWNADD_ERROR";

export const OWNERS_REQUEST = "OWNERS_REQUEST";
export const OWNERS_SUCCESS = "OWNERS_SUCCESS";
export const OWNERS_ERROR = "OWNERS_ERROR";

export const PEOPLEADD_REQUEST = "PEOPLEADD_REQUEST";
export const PEOPLEADD_SUCCESS = "PEOPLEADD_SUCCESS";
export const PEOPLEADD_ERROR = "PEOPLEADD_ERROR";

export const PEOPLEBULKEDIT_REQUEST = "PEOPLEBULKEDIT_REQUEST";
export const PEOPLEBULKEDIT_SUCCESS = "PEOPLEBULKEDIT_SUCCESS";
export const PEOPLEBULKEDIT_ERROR = "PEOPLEBULKEDIT_ERROR";

export const PEOPLEDELETE_REQUEST = "PEOPLEDELETE_REQUEST";
export const PEOPLEDELETE_SUCCESS = "PEOPLEDELETE_SUCCESS";
export const PEOPLEDELETE_ERROR = "PEOPLEDELETE_ERROR";

export const PEOPLEFIELDS_REQUEST = "PEOPLEFIELDS_REQUEST";
export const PEOPLEFIELDS_SUCCESS = "PEOPLEFIELDS_SUCCESS";
export const PEOPLEFIELDS_ERROR = "PEOPLEFIELDS_ERROR";

export const PEOPLEADDFIELDS_REQUEST = "PEOPLEADDFIELDS_REQUEST";
export const PEOPLEADDFIELDS_SUCCESS = "PEOPLEADDFIELDS_SUCCESS";
export const PEOPLEADDFIELDS_ERROR = "PEOPLEADDFIELDS_ERROR";

export const PEOPLETAGS_REQUEST = "PEOPLETAGS_REQUEST";
export const PEOPLETAGS_SUCCESS = "PEOPLETAGS_SUCCESS";
export const PEOPLETAGS_ERROR = "PEOPLETAGS_ERROR";

export const PEOPLETAGSADD_REQUEST = "PEOPLETAGSADD_REQUEST";
export const PEOPLETAGSADD_SUCCESS = "PEOPLETAGSADD_SUCCESS";
export const PEOPLETAGSADD_ERROR = "PEOPLETAGSADD_ERROR";

export const PEOPLETAGSEDIT_REQUEST = "PEOPLETAGSEDIT_REQUEST";
export const PEOPLETAGSEDIT_SUCCESS = "PEOPLETAGSEDIT_SUCCESS";
export const PEOPLETAGSEDIT_ERROR = "PEOPLETAGSEDIT_ERROR";

export const PEOPLETAGSDELETE_REQUEST = "PEOPLETAGSDELETE_REQUEST";
export const PEOPLETAGSDELETE_SUCCESS = "PEOPLETAGSDELETE_SUCCESS";
export const PEOPLETAGSDELETE_ERROR = "PEOPLETAGSDELETE_ERROR";

export const PEOPLECELLEDIT_REQUEST = "PEOPLECELLEDIT_REQUEST";
export const PEOPLECELLEDIT_SUCCESS = "PEOPLECELLEDIT_SUCCESS";
export const PEOPLECELLEDIT_ERROR = "PEOPLECELLEDIT_ERROR";

export const PEOPLEFILTERS_REQUEST = "PEOPLEFILTERS_REQUEST";
export const PEOPLEFILTERS_SUCCESS = "PEOPLEFILTERS_SUCCESS";
export const PEOPLEFILTERS_ERROR = "PEOPLEFILTERS_ERROR";

export const PEOPLEFILTERADD_REQUEST = "PEOPLEFILTERADD_REQUEST";
export const PEOPLEFILTERADD_SUCCESS = "PEOPLEFILTERADD_SUCCESS";
export const PEOPLEFILTERADD_ERROR = "PEOPLEFILTERADD_ERROR";

export const PEOPLEFILTEREDIT_REQUEST = "PEOPLEFILTEREDIT_REQUEST";
export const PEOPLEFILTEREDIT_SUCCESS = "PEOPLEFILTEREDIT_SUCCESS";
export const PEOPLEFILTEREDIT_ERROR = "PEOPLEFILTEREDIT_ERROR";

export const PEOPLEFILTERFAVORITE_REQUEST = "PEOPLEFILTERFAVORITE_REQUEST";
export const PEOPLEFILTERFAVORITE_SUCCESS = "PEOPLEFILTERFAVORITE_SUCCESS";
export const PEOPLEFILTERFAVORITE_ERROR = "PEOPLEFILTERFAVORITE_ERROR";

export const PEOPLEFILTERDELETE_REQUEST = "PEOPLEFILTERDELETE_REQUEST";
export const PEOPLEFILTERDELETE_SUCCESS = "PEOPLEFILTERDELETE_SUCCESS";
export const PEOPLEFILTERDELETE_ERROR = "PEOPLEFILTERDELETE_ERROR";

export const PEOPLEMERGE_REQUEST = "PEOPLEMERGE_REQUEST";
export const PEOPLEMERGE_SUCCESS = "PEOPLEMERGE_SUCCESS";
export const PEOPLEMERGE_ERROR = "PEOPLEMERGE_ERROR";

export const APPLYFILTER = "APPLYPEOPLEFILTER";
export const CLEARFILTER = "CLEARPEOPLEFILTER";

export const CONVERT_PEOPLE_TO_LEAD_REQUEST = "CONVERT_PEOPLE_TO_LEAD_REQUEST";
export const CONVERT_PEOPLE_TO_LEAD_SUCCESS = "CONVERT_PEOPLE_TO_LEAD_SUCCESS";
export const CONVERT_PEOPLE_TO_LEAD_ERROR = "CONVERT_PEOPLE_TO_LEAD_ERROR";

export const generatePeopleAIWFData = {
    GENERATE_PEOPLE_AIWF_DATA_REQUEST: "GENERATE_PEOPLE_AIWF_DATA_REQUEST",
    GENERATE_PEOPLE_AIWF_DATA_SUCCESS: "GENERATE_PEOPLE_AIWF_DATA_SUCCESS",
    GENERATE_PEOPLE_AIWF_DATA_ERROR: "GENERATE_PEOPLE_AIWF_DATA_ERROR"
}