import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Billing from "./Billing";
import AccountSettings from "./Admin-Settings";
import ProfileSettings from "./Profile-Settings";
import AppStore from "./account/App-Store/index";
import CouponCode from "./coupon-code/index";
import Integrations from "../Integrations";

export default function ActivityPageRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route path={`${match.url}/profile`} component={ProfileSettings} />
        <Route path={`${match.url}/account`} component={AccountSettings} />
        <Route path={`${match.url}/billing`} component={Billing} />
        <Route path={`${match.url}/coupon-code`} component={CouponCode} />
        <Route path={`${match.url}/app-store`} component={AppStore} />
        <Route path={`${match.url}/integrations`} component={Integrations} />
        <Redirect to={`${match.url}/profile`} />
      </Switch>
    </>
  );
}
