import React, { useEffect, useState } from "react";
import _ from "lodash";
import Select from "react-select";
import { Form } from "react-bootstrap";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { useSelector } from "react-redux";
import { customStyles } from "../forms/constants";

export default function OwnerField(props) {
  const [ownerType, setOwnerType] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [multiValue, setMultiValue] = useState(null);

  const userRole = useSelector((store) => store.insight.userRole.list);

  useEffect(() => {
    if (userRole.users) {
      setUserOptions(
        userRole.users.map((each) => {
          return {
            label: each.email,
            value: each.id,
          };
        }),
      );
    }
  }, [userRole.users]);

  useEffect(() => {
    if (!_.isEmpty(props.value)) {
      if (props.value.type === "automatic") {
        setOwnerType({ value: "automatic", label: "Automatically" });
        setSelectedUser({});
        setMultiValue([]);
      } else if (props.value.type === "roundRobbin") {
        setOwnerType({ value: "roundRobbin", label: "Round Robbin" });
        const _userOption = userOptions.filter((each) => props.value.id.includes(each.value));
        if (!_.isEmpty(_userOption)) setMultiValue(_userOption);
        else setMultiValue([]);
        setSelectedUser({});
      } else if (props.value.type === "single") {
        setOwnerType({ value: "single", label: "Single Select" });
        const _userOption = userOptions.find((each) => each.value === props.value.id);
        if (!_.isEmpty(_userOption)) setSelectedUser(_userOption);
        else setSelectedUser({});
        setMultiValue([]);
      }
    }
  }, [props.value, userOptions]);

  const onUserSelect = (_selectedUser) => {
    const data = {
      id: _selectedUser.value,
      type: "single",
    };
    props.setValue(data);
    setSelectedUser(_selectedUser);
  };

  const handleOwnerTypeSelection = (_ownerType) => {
    if (_ownerType.value === "automatic") {
      const data = {
        type: "automatic",
      };
      props.setValue(data);
    } else if (_ownerType.value === "roundRobbin") {
      const data = {
        id: !_.isEmpty(multiValue) ? multiValue.map((each) => each.value) : [],
        type: "roundRobbin",
      };
      props.setValue(data);
    } else if (_ownerType.value === "single") {
      const data = {
        id: !_.isEmpty(selectedUser) ? selectedUser.value : "",
        type: "single",
      };
      props.setValue(data);
    }
    setOwnerType(_ownerType);
  };

  const handleMultiValueSelection = (_multiValue) => {
    const data = {
      id: _multiValue.map((each) => each.value),
      type: "roundRobbin",
    };
    props.setValue(data);
    setMultiValue(_multiValue);
  };
  return (
    <Form.Group id="automation-workflow-builder-owner-field-owner-name" className="mb-3">
      <Form.Label aria-label="owner">Owner</Form.Label>
      <Form.Group id="automation-workflow-builder-owner-field-dropdown" className="d-flex justify-content-between align-items-center mb-0">
        <Form.Group id="automation-workflow-builder-owner-field-deal-name-type" className="mb-0 w-49">
          <SimpleDropdown
            disabled
            value={ownerType}
            label={"Select Deal Name Type"}
            options={[
              { value: "automatic", label: "Automatically" },
              // { value: "roundRobbin", label: "Round Robbin" }, { value: "single", label: "Single Select" }
            ]}
            handleSelect={(a) => handleOwnerTypeSelection(a)}
          />
        </Form.Group>
        {ownerType.value === "single" && (
          <Form.Group id="automation-workflow-builder-owner-field-search-example" className="mb-0 w-49">
            <Select options={userOptions} styles={customStyles} aria-label="Default select example" onChange={(e) => onUserSelect(e)} value={selectedUser} />
          </Form.Group>
        )}
        {ownerType.value === "roundRobbin" && (
          <Form.Group id="automation-workflow-builder-owner-field-filters" className="mb-0 w-49">
            <Select name="filters" placeholder="Filters" value={multiValue} options={userOptions} onChange={(a) => handleMultiValueSelection(a)} isMulti />
          </Form.Group>
        )}
      </Form.Group>
    </Form.Group>
  );
}
