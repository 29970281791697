import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import * as campaignWhatsappTemplateTypes from "../../types/campaign_whatsapp_template_types";
import * as campaignWhatsappTemplateActions from "../../actions/campaign_whatsapp_template_actions";
import { endpoints } from "../../endpoints";
import api from "../../../../../network/apis/api";

function* getListOfWhatsappTemplates(action) {
    try {
        const res = yield call(api.get, `${endpoints.WHATSAPP_TEMPLATE}/${action.payload.integrationId}`, action.payload);
        if (res.status === 200) {
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesFailure(e));
    }
}

function* getListOfWhatsappTemplatesBySearch(action) {
    try {
        const res = yield call(api.get, `${endpoints.WHATSAPP_TEMPLATE}/${action.payload.integrationId}`, action.payload);
        if (res.status === 200) {
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesBySearchSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesBySearchFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesBySearchFailure(e));
    }
}
function* createWhatsappTemplate(action) {
    try {
        const res = yield call(api.post, endpoints.WHATSAPP_TEMPLATE, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappTemplateActions.createWhatsappTemplateSuccess(res.data));
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesRequest({ integrationId: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappTemplateActions.createWhatsappTemplateFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappTemplateActions.createWhatsappTemplateFailure(e));
    }
}
function* updateWhatsappTemplate(action) {
    try {
        const res = yield call(api.patch, endpoints.WHATSAPP_TEMPLATE, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappTemplateActions.updateWhatsappTemplateSuccess(res.data));
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesRequest({ integrationId: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappTemplateActions.updateWhatsappTemplateFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappTemplateActions.updateWhatsappTemplateFailure(e));
    }
}
function* deleteWhatsappTemplate(action) {
    try {
        const res = yield call(api.delete, `${endpoints.WHATSAPP_TEMPLATE}/${action.payload.integrationId}/${action.payload.templateId}`);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappTemplateActions.deleteWhatsappTemplateSuccess(res.data));
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesRequest({ integrationId: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappTemplateActions.deleteWhatsappTemplateFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappTemplateActions.deleteWhatsappTemplateFailure(e));
    }
}

function* syncWithFacebookWhatsappTemplates(action) {
    try {
        const res = yield call(api.post, `${endpoints.SYNC_WHATSAPP_TEMPLATE}/${action.payload.integrationId}`, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappTemplateActions.syncWithFacebookWhatsappTemplatesSuccess(res.data));
            yield put(campaignWhatsappTemplateActions.getListOfWhatsappTemplatesRequest({ integrationId: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappTemplateActions.syncWithFacebookWhatsappTemplatesFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappTemplateActions.syncWithFacebookWhatsappTemplatesFailure(e));
    }
}
export default function* campaignWhatsappTemplateSaga() {
    yield takeLatest(campaignWhatsappTemplateTypes.getListOfWhatsappTemplates.REQUEST, getListOfWhatsappTemplates);
    yield takeLatest(campaignWhatsappTemplateTypes.getListOfWhatsappTemplatesBySearch.REQUEST, getListOfWhatsappTemplatesBySearch);
    yield takeLatest(campaignWhatsappTemplateTypes.createWhatsappTemplate.REQUEST, createWhatsappTemplate);
    yield takeLatest(campaignWhatsappTemplateTypes.updateWhatsappTemplate.REQUEST, updateWhatsappTemplate);
    yield takeLatest(campaignWhatsappTemplateTypes.deleteWhatsappTemplate.REQUEST, deleteWhatsappTemplate);
    yield takeLatest(campaignWhatsappTemplateTypes.syncWithFacebookWhatsappTemplates.REQUEST, syncWithFacebookWhatsappTemplates);

}
