import { call, put, takeLatest } from "redux-saga/effects";
import { endpoints } from "../endpoints";
import * as cadenceAnalyticsStepWiseActions from "../actions/cadence_analytics_stepwise_actions";
import * as cadenceAnalyticsStepWiseTypes from "../types/cadence_analytics_stepwise_types";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError } from "../../../../../utils/toaster";

function* getCadenceAnalyticsStepWise(action) {
  try {
    const res = yield call(api.get, `${endpoints.CADENCE_ANALYTICS_STEPWISE}/${action.payload.id}`);
    if (res.status === 200) {
      yield put(cadenceAnalyticsStepWiseActions.getCadenceAnalyticsStepWiseSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceAnalyticsStepWiseActions.getCadenceAnalyticsStepWiseError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceAnalyticsStepWiseActions.getCadenceAnalyticsStepWiseError(e));
  }
}
export default function* cadenceAnalyticsStepWiseSaga() {
  yield takeLatest(cadenceAnalyticsStepWiseTypes.getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_REQUEST, getCadenceAnalyticsStepWise);
}
