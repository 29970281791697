import React, { useState } from "react";
import { Dropdown, Form, Popover, Button, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GreenGradientButton } from "../../components/button";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../utils/toaster";
import { InputLimit } from "../../constants";

export default function CategorySelect({ addCategory, editCategory, deleteCategory, field, value, setValue }) {
  const [search, setSearch] = useState("");
  const [newCategoryText, setNewCategoryText] = useState("");
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [hovering, setHovering] = useState();
  const [edit, setEdit] = useState({});
  const [show, setShow] = useState(false);
  const categoryStore = useSelector((state) => state.Products.productCategories);

  const handleClose = (e) => {
    setHovering();
    setEditing(false);
    setAdding(false);
  };

  const handleSave = (e) => {
    setHovering();
    if (newCategoryText.trim() === "") {
      dispatchSnackbarError("Category Name can not be blank!");
      return;
    }
    setShow(false);
    addCategory(newCategoryText);
    setNewCategoryText("");
    setEditing(false);
    setAdding(false);
    setSearch("");
  };

  const handleCloseEdit = (e) => {
    setHovering();
    setEditing(false);
    setEdit({});
  };

  const handleEdit = (e) => {
    setHovering();
    if (edit.categoryName.trim() === "") {
      dispatchSnackbarError("Category Name can not be blank!");
      return;
    }
    editCategory(edit["_id"], edit.categoryName);
    setEditing(false);
    setEdit({});
    setSearch("");
  };

  const handleDelete = () => {
    setHovering();
    deleteCategory(edit["_id"]);
    setEditing(false);
    setEdit({});
    setSearch("");
  };

  return (
    <Dropdown
      show={show}
      onToggle={() => {
        if (!show) {
          setEditing(false);
          setAdding(false);
          setEdit();
        }
        setShow(!show);
      }}
      className="dropdown-inline mt-1 w-100"
    >
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || value === "" || !categoryStore.list.find((cat) => cat["_id"] === (value["_id"] ? value["_id"] : value))
          ? "Select " + field.fieldName
          : categoryStore.list.find((cat) => cat["_id"] === (value["_id"] ? value["_id"] : value)).categoryName}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-xl p-0 w-100-mn">
        {categoryStore.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center h-30vh-mn">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : editing ? (
          <PerfectScrollbar className="scroll navi navi-hover h-45vh-mx position-absolute" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {adding ? (
              <div>
                <Popover.Title className="py-4" as="h3">
                  Add Category
                </Popover.Title>
                <Popover.Content>
                  <div className="w-100">
                    <Form.Group id="custom-form-add-category" className="mb-0">
                      <Form.Control maxLength={InputLimit.TEXT} type="text" value={newCategoryText} placeholder="Category Name" onChange={(e) => setNewCategoryText(e.target.value)}></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="d-flex flex-row justify-content-center gap-15px mt-15px">
                    <Button variant="secondary" onClick={(e) => handleClose(e)} size="sm">
                      Cancel
                    </Button>
                    <GreenGradientButton onClick={(e) => handleSave(e)} size="sm">
                      Save
                    </GreenGradientButton>
                  </div>
                </Popover.Content>
              </div>
            ) : (
              <div>
                <Popover.Title className="py-4" as="h3">
                  Edit Category
                </Popover.Title>
                <Popover.Content>
                  <div className="w-100">
                    <Form.Group id="custom-form-edit-category" className="mb-0">
                      <Form.Control maxLength={InputLimit.TEXT} type="text" value={edit.categoryName} placeholder="Category Name" onChange={(e) => setEdit({ ...edit, categoryName: e.target.value })}></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="d-flex flex-row flex-stack mt-15px">
                    <Button onClick={() => handleDelete()} variant="danger" size="sm">
                      Delete
                    </Button>
                    <div className="d-flex flex-row gap-15px">
                      <Button variant="secondary" onClick={(e) => handleCloseEdit(e)} size="sm">
                        Cancel
                      </Button>
                      <GreenGradientButton onClick={(e) => handleEdit(e)} size="sm">
                        Save
                      </GreenGradientButton>
                    </div>
                  </div>
                </Popover.Content>
              </div>
            )}
          </PerfectScrollbar>
        ) : (
          <div className="navi navi-hover">
            <li className="navi-item py-20px px-24px">
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Categories" value={search} onChange={(e) => setSearch(e.target.value)} />
            </li>
            <Dropdown.Divider className="m-0" />
            <PerfectScrollbar className="scroll h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {categoryStore.list.length === 0 ? (
                <div className="w-100 d-flex flex-center h-50px-mn">
                  <span>No Categories</span>
                </div>
              ) : (
                categoryStore.list.map((category, index) => {
                  return category.categoryName && category.categoryName.toLowerCase().includes(search.toLowerCase()) ? (
                    <Dropdown.Item
                      onMouseOver={() => setHovering(index)}
                      onMouseLeave={() => setHovering()}
                      className="dropdown-hover"
                      key={index}
                      onSelect={() => {
                        setValue(category["_id"], field.apiKeyName);
                      }}
                    >
                      <div className="w-100 d-flex flex-stack" s>
                        {category.categoryName}
                        {hovering === index ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditing(true);
                              setEdit(category);
                            }}
                            className="btn btn-icon btn-light h-25px w-25px"
                          >
                            <i className="fa fa-sm fa-pen font-size-13px"></i>
                          </button>
                        ) : (
                          <span className="h-25px w-25px"></span>
                        )}
                      </div>
                    </Dropdown.Item>
                  ) : null;
                })
              )}
            </PerfectScrollbar>
            <Dropdown.Divider className="m-0" />
            <div
              onClick={() => {
                setEditing(true);
                setAdding(true);
              }}
              className="dropdown-item dropdown-hover py-4 cursor-pointer"
            >
              + Add Category
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
