import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { SELECTED_STEP_TYPES } from "./index";

export default function SelectionStep1({ history, setSelectedStep }) {
  return (
    <Form>
      <div className="mb-4">Build automated or manual sequence of activities to drive deeper engagement with your contacts.</div>
      <Row xs={1} md={2} lg={3} xl={3}>
        <Col className=" mb-8">
          <div
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/automation/cadence/create`);
            }}
            className="card p-4 py-8 d-flex justify-content-center align-items-center add-workflow-card btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column  p-3"
          >
            <div className="pt-10 pb-6">
              <i className="bi bi-file-earmark-text fs-4x"></i>
            </div>
            <div className="text-center fs-3 font-weight-bold py-3">New</div>
            <div className="text-center">Create a new cadence from scratch</div>
          </div>
        </Col>
        <Col className=" mb-8">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSelectedStep(SELECTED_STEP_TYPES.PREBUILT);
            }}
            className="card p-4 py-8 d-flex justify-content-center align-items-center add-workflow-card btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column  p-3"
          >
            <div className="pt-10 pb-6">
              <i className="bi bi-journal-plus fs-4x"></i>
            </div>
            <div className="text-center fs-3 font-weight-bold py-3">Prebuilt</div>
            <div className="text-center">Start with one of our cadence templates.</div>
          </div>
        </Col>
        <Col className=" mb-8">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSelectedStep(SELECTED_STEP_TYPES.CLONE);
            }}
            className="card p-4 py-8 d-flex justify-content-center align-items-center add-workflow-card btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column  p-3"
          >
            <div className="pt-10 pb-6">
              <i className="bi bi-clipboard2-check fs-4x"></i>
            </div>
            <div className="text-center fs-3 font-weight-bold py-3">Clone</div>
            <div className="text-center">Make a copy of one of your existing cadences.</div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
