import React from "react";
import { CustomSubHeader } from "../../../../components/subheader";
import { NavLink, Link } from "react-router-dom";
import ContentWrapper from "../../../Wrapper";

export default function AppStore() {
  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex flex-row flex-stack w-100">
            <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
              <div className="menu-item me-lg-1">
                <NavLink className="menu-link py-3" to="/settings/account">
                  <span className="menu-title">Admin Settings</span>
                </NavLink>
              </div>
              <div className="menu-item me-lg-1">
                <Link className="menu-link py-3" to="/settings/billing/subscription">
                  <span className="menu-title">Billing</span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <NavLink className="menu-link py-3 active" to="/account-setting/app-store">
                  <span className="menu-title">App Store</span>
                </NavLink>
              </div>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      <h4>Comming Soon...</h4>
    </ContentWrapper>
  );
}
