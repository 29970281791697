import { getListOfCadencePrebuiltTemplate } from "../types/cadence-prebuilt-template.types";
const initState = {
  data: [],
  isLoading: false,
  error: false,
};

export default function cadencePrebuiltTemplateReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
