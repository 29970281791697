import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function FreePlanSubscriptionModel({ showFreePlanModal, handleSubscription, setShowFreePlanModal }) {
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const usageData = useSelector((store) => store.usage.v2);
  const [canDegradable, setCanDegradable] = useState(true);
  const [owners, setOwners] = useState({});
  const userRole = useSelector((store) => store.insight.userRole);

  useEffect(() => {
    if (userRole.list.allUsers) {
      const a = {};
      userRole.list.allUsers.forEach((each) => {
        a[each.id] = each;
      });
      setOwners(a);
    }
  }, [userRole.list.allUsers]);

  useEffect(() => {
    if (!_.isEmpty(usageData.data) && !_.isEmpty(accountBillingStore.data)) {
      const _tbody = [];
      _tbody.push({ module: "Contacts", consumed: usageData.data.records.consumed.contacts, available: usageData.data.records.assigned.contacts, isLock: false, extra: usageData.data.records.extra });
      _tbody.push({ module: "Custom Fields", consumed: usageData.data.records.consumed.fields, available: usageData.data.records.assigned.fields, isLock: false });
      _tbody.push({ module: "Pipeline", consumed: usageData.data.consumed.pipeline, available: usageData.data.records.extraLimit.pipeline, isLock: false });
      _tbody.push({
        module: "Dashboard",
        consumed: usageData.data.consumed.dashboardV2,
        available: usageData.data.records.extraLimit.dashboard,
        isLock: false,
      });
      _tbody.push({ module: "Leadbot", consumed: usageData.data.consumed.leadbot, available: usageData.data.records.extraLimit.leadbot, isLock: false });
      _tbody.push({ module: "Workflow", consumed: usageData.data.consumed.workflow, available: usageData.data.records.extraLimit.workflow, isLock: !usageData.data.assigned.isWorkflow });
      _tbody.push({ module: "Cadence", consumed: usageData.data.consumed.cadence, available: usageData.data.records.extraLimit.cadence, isLock: !usageData.data.assigned.isCadence });

      const { isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
      if (isSubscribedForLifeTimeFree) {
        setCanDegradable(false);
      }
      if (subscriptionStatus === "active") {
        setCanDegradable(false);
      }

      for (const a of _tbody) {
        if (a.module === "Dashboard") {
          for (const each of a.consumed) {
            if (owners[each._id] && each.count > a.available && !a.isLock) {
              setCanDegradable(false);
              break;
            }
          }
        } else if (a.module === "Workflow") {
          for (const each of a.consumed) {
            if (owners[each._id] && each.count > a.available && !a.isLock) {
              setCanDegradable(false);
              break;
            }
          }
        } else if (a.consumed > a.available && !a.isLock) {
          setCanDegradable(false);
          break;
        }
      }
    }
  }, [usageData.data, accountBillingStore.data, owners]);
  return (
    <Modal backdrop="static" show={showFreePlanModal} onHide={() => setShowFreePlanModal(false)} aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header>
        <Modal.Title>Free plan subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!canDegradable ? <div className="fs-5">You can't downgrade your plan; please delete the records. Check more at billing.</div> : <div className="fs-5">Are you sure, want to downgrade to free plan?</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => setShowFreePlanModal(false)}>
          Close
        </Button>
        {canDegradable && (
          <Button size="sm" variant="primary" onClick={() => handleSubscription()}>
            Continue
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
