import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";

import * as actions from "../../../../../containers/Settings/actions/settings.actions";
import { useDispatch } from "react-redux";
import { dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export default function SwitchCompany() {
  const [companyList, setcompanyList] = useState([]);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.companyListRequest((data) => {
        setcompanyList(data.data);
      }),
    );
  }, [dispatch]);

  const EnterCompany = (data) => {
    dispatchSnackbarSuccess("SuccessFully Enter In the Company", "success");
    localStorage.setItem("access_token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
    window.location.href = "/activity/activity-list";
    // window.location.reload();
  };

  if (!companyList || (Array.isArray(companyList) && companyList.length <= 1)) {
    return <></>;
  }
  return (
    <div>
      {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-actions-tooltip">Switch Company</Tooltip>}>
                <div className="topbar-item">
                    <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 svg-icon-div" id="kt_quick_actions_toggle">
                        <span className="header-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-nintendo-switch" viewBox="0 0 16 16">
                                <path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.136 4.136 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979Zm4.675.269a1.621 1.621 0 0 0-1.113-1.034 1.609 1.609 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.632 1.632 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053ZM3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256Z" />
                                <path d="M3.425.053a4.136 4.136 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.845 2.845 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z" />
                            </svg>
                        </span>
                    </div>
                </div>
            </OverlayTrigger> */}
      <Dropdown show={show} onToggle={() => setShow(!show)} drop="down" alignRight>
        <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_switch_company_panel_toggle">
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="swich-company-tooltip">Switch Company</Tooltip>}>
            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 svg-icon-div">
              <span className="header-svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-nintendo-switch" viewBox="0 0 16 16">
                  <path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.136 4.136 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979Zm4.675.269a1.621 1.621 0 0 0-1.113-1.034 1.609 1.609 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.632 1.632 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053ZM3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256Z" />
                  <path d="M3.425.053a4.136 4.136 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.845 2.845 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z" />
                </svg>
              </span>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg w-450px">
          <div className="d-flex flex-column align-items-center justify-content-center pt-6 pb-6 bgi-size-cover bgi-no-repeat rounded-top bg-121212">
            <h3 className="text-white font-weight-bold font-size-5 m-8">Switch Company</h3>
          </div>
          <div id="kt_account_connected_accounts" className="collapse show">
            <div className="card-body border-top p-5 h-30vh-mx overflow-y-auto">
              {companyList.map((data, key) => {
                return (
                  <div key={key}>
                    <div className="d-flex flex-stack">
                      <div className="d-flex">
                        <div className="d-flex flex-column">
                          <div>
                            <span>
                              <strong>Company: </strong>
                            </span>
                            <span>
                              {data.companyName} {data.website ? ` | ${data.website}` : ""}
                            </span>
                          </div>
                          <div>
                            <span>
                              <strong>User Type: </strong>
                            </span>
                            <span>{data.userType}</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end w-175px-mn">
                        {data.isCurrentAccount === true && <button className="text-009ef7 bg-f5f8facc saveCommponbutton btn btn-sm btn-primary my-1 w-140px fw-600">Current Account</button>}
                        {data.isCurrentAccount === false && (
                          <button className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => EnterCompany(data)}>
                            Switch to Company
                          </button>
                        )}
                      </div>
                    </div>

                    {key !== companyList.length - 1 && <div className="separator separator-dashed my-5" />}
                  </div>
                );
              })}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
