export const getWhatsappMedia = {
    REQUEST: "GET_WHATSAPP_MEDIA_REQUEST",
    SUCCESS: "GET_WHATSAPP_MEDIA_SUCCESS",
    FAILURE: "GET_WHATSAPP_MEDIA_FAILURE",
}

export const uploadWhatsappMedia = {
    REQUEST: "UPLOAD_WHATSAPP_MEDIA_REQUEST",
    SUCCESS: "UPLOAD_WHATSAPP_MEDIA_SUCCESS",
    FAILURE: "UPLOAD_WHATSAPP_MEDIA_FAILURE",
}

export const uploadWhatsappSessionMedia = {
    REQUEST: "UPLOAD_WHATSAPP_SESSION_MEDIA_REQUEST",
    SUCCESS: "UPLOAD_WHATSAPP_SESSION_MEDIA_SUCCESS",
    FAILURE: "UPLOAD_WHATSAPP_SESSION_MEDIA_FAILURE",
}


export const deleteWhatsappMedia = {
    REQUEST: "DELETE_WHATSAPP_MEDIA_REQUEST",
    SUCCESS: "DELETE_WHATSAPP_MEDIA_SUCCESS",
    FAILURE: "DELETE_WHATSAPP_MEDIA_FAILURE",
}