import React from "react";
import CustomSwitch from "../../custom_form/switch";
import CustomDisplay from "../../table/data";

export default function HandleAdvanceTableData(props) {
  if (props.type === "SWITCH_BUTTON") {
    return (
      <div className="d-flex flex-row flex-center h-100">
        <CustomSwitch label="" id={props.keyIndex} checked={props.value} handleChange={(e) => props.onStatusChange(e.target.checked)} />
      </div>
    );
  }
  return <CustomDisplay data={props.value} showDownloadIcon={props.showDownloadIcon} field={props.field} type={props.type} />;
}
