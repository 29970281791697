import React from "react";
import { Dropdown } from "react-bootstrap";
import SelectableContext from "react-bootstrap/SelectableContext";

export const BootstrapDropdown = (props) => {
  return (
    <SelectableContext.Provider value={false}>
      <Dropdown className="mt-0 w-100">
        <Dropdown.Toggle className="d-flex w-100 align-items-center text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {props.value && props.value.label ? <span className="fw-bold">{props.value.label}</span> : props.label}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100-mn h-300px-mx overflow-y-scroll">
          {props.options.map((option, index) => (
            <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => props.onSelect(option)}>
              <span className="overflow-hidden text-overflow-ellipsis">{option.label}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </SelectableContext.Provider>
  );
};
