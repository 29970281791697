import { getMarketingLogs } from "../types/campaign_marketing_list_types";

const initState = {
  data: [],
  loading: false,
  loadMoreLoading: false,
  count: 0,
};

export default function marketingLogReducer(state = initState, action = {}) {
  switch (action.type) {
    case getMarketingLogs.GET_MARKETING_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        loadMoreLoading: false,
      };
    case getMarketingLogs.GET_MARKETING_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadMoreLoading: false,
        data: action.payload.data,
        count: action.payload.count,
      };

    case getMarketingLogs.GET_MARKETING_LOGS_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadMoreLoading: true,
      };
    case getMarketingLogs.GET_MARKETING_LOGS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMoreLoading: false,
        data: [...state.data, ...action.payload.data],
        count: action.payload.count,
      };
    case getMarketingLogs.GET_MARKETING_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        loadMoreLoading: false,
      };
    default:
      return state;
  }
}
