import _ from "lodash";
import validator from "validator";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RadioSelectorWithTooltip } from "../../../../../../components/custom-inputs/radio-selector";
import { CustomEmptyPage } from "../../../../../../components/empty_page/custom-empty-page";
import TemplateManager from "../../../../../../components/templates";
import VariableOptionsPro from "../../../../../../components/variable-options";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { emailSendTypeOptions } from "../../../constants";
import CampaignEmailForm from "./multi-email-form/campaign-email.form";
import SyncEmailFrom from "./multi-email-form/sync-email.from";
import AIWizardSidePanel from "../../../../../../components/ai-wizard/AIWizardSidePanel";
import * as syncSettingActions from "../../../../../Settings/store/actions/profile-actions/sync-settings.actions";

export default function EmailNotificationForm(props) {
  const [name, setName] = useState("");
  const [selectedEmailSendType, setSelectedEmailSendType] = useState("EMAIL_SYNC");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [defaultBody, setDefaultBody] = useState("");
  const [fromName, setFormName] = useState("");
  const [isReplyTo, setIsReplyTo] = useState(false);
  const [replyToName, setReplyToName] = useState("");
  const [replyToEmail, setReplyToEmail] = useState("");
  const [fromEmailPrefix, setFromEmailPrefix] = useState("");
  const [fromEmailSufix, setFromEmailSufix] = useState("");

  const [showTemplateManager, setShowTemplateManager] = useState(false);
  const [editorType, setEditorType] = useState("tiny_cloud");
  const [builderBody, setBuilderBody] = useState("");
  const [templateId, setTemplateId] = useState("");

  const [isNewThread, setIsNewThread] = useState(true);
  const [showNewThreadButton, setShowNewThreadButton] = useState(false);

  const [showVariableOptions, setShowVariableOptions] = useState(false);
  const [defaultVariableValues, setDefaultVariableValues] = useState({});

  const [showAIWizardSidePanel, setShowAIWizardSidePanel] = useState(false);
  const [isCampaignPermission, setIsCampaignPermission] = useState(false);

  const dispatch = useDispatch();
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);

  const domainStore = useSelector((store) => store.campaign.settings.email.domain);

  const profileStore = useSelector((store) => store.ProfileSettings.getProfileDetails);

  useEffect(() => {
    if (profileStore.data) {
      const { userType, permissions } = profileStore.data;
      if (userType === "OWNER" || userType === "ADMIN") {
        setIsCampaignPermission(true);
      } else if (userType === "AGENT" && permissions.campaign) {
        setIsCampaignPermission(true);
      } else {
        setIsCampaignPermission(false);
      }
    }
  }, [profileStore]);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
  }, [dispatch]);

  const resetEmailForm = useCallback(() => {
    setName("");
    setSubject("");
    setBody("");
    setDefaultBody("");
    setShowTemplateManager(false);
    setEditorType("");
    setBuilderBody("");
    setTemplateId("");
    setIsNewThread(true);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(props.selectedStep)) {
      let templateData = {};
      let sendEmailType = "";
      if (props.isEdit && props.isAbVarient) {
        templateData = props.selectedStep.abTesting.emailContent[props.selectedSubIndex];
      } else if (!props.isEdit && props.isAbVarient) {
        templateData = {};
        sendEmailType = props.selectedStep.emailContent.emailSendType;
        setSelectedEmailSendType(props.selectedStep.emailContent.emailSendType);
      } else {
        templateData = props.selectedStep.emailContent;
      }
      if (_.isEmpty(templateData)) return resetEmailForm();
      setName(templateData.name);
      if (templateData.emailSendType) {
        sendEmailType = templateData.emailSendType;
        setSelectedEmailSendType(templateData.emailSendType);
      }
      setSubject(templateData.subject);
      setEditorType(templateData.editorType);
      setTemplateId(templateData.templateId);
      setIsNewThread(templateData.isNewThread);

      if (templateData.editorType === "unlayer") {
        setBuilderBody(templateData.content);
        setDefaultBody("");
        setBody("");
      } else {
        setBody(templateData.content);
        setBuilderBody("");
        setDefaultBody(templateData.content);
      }
      if (templateData.defaultVariableValues) setDefaultVariableValues(templateData.defaultVariableValues);

      if (sendEmailType === "CAMPAIGN_API") {
        const splitArray = templateData.from && templateData.from.split("@").length === 2 ? templateData.from.split("@") : [];
        setFormName(templateData.fromName || "");
        setIsReplyTo(templateData.replyToEmail && templateData.replyToName ? true : false);
        setReplyToName(templateData.replyToName || "");
        setReplyToEmail(templateData.replyToEmail || "");
        setFromEmailPrefix(splitArray[0] || "");
        // setFromEmailSufix("");
      } else {
        setFormName("");
        setIsReplyTo(false);
        setReplyToName("");
        setReplyToEmail("");
        setFromEmailPrefix("");
        // setFromEmailSufix("");
      }
    } else {
      resetEmailForm();
    }
  }, [props.selectedStep, props.isAbVarient, props.selectedSubIndex, props.isEdit, resetEmailForm]);

  useEffect(() => {
    // console.log("props.selectedIndex", props.selectedIndex);
    if (props.isAbVarient) {
      setShowNewThreadButton(false);
    } else if (props.selectedIndex === 0 || props.selectedIndex) {
      let alreadyPresent = false;
      props.steps &&
        props.steps.forEach((each, ind) => {
          if (props.selectedIndex !== -1 && ind < props.selectedIndex && each.channelType === "EMAIL") {
            alreadyPresent = true;
            // setSubject(each.emailContent?.subject ?? "");
          } else if (props.selectedIndex === -1 && each.channelType === "EMAIL") {
            alreadyPresent = true;
            // setSubject(each.emailContent?.subject ?? "");
          }
        });
      setShowNewThreadButton(alreadyPresent);
    } else {
      let alreadyPresent = false;
      props.steps &&
        props.steps.forEach((each, ind) => {
          if (each.channelType === "EMAIL") {
            alreadyPresent = true;
            // setSubject(each.emailContent.subject);
          }
        });
      setShowNewThreadButton(alreadyPresent);
    }
  }, [props.isAbVarient, props.steps, props.selectedIndex]);

  useEffect(() => {
    if (!_.isEmpty(domainStore.list)) {
      domainStore.list.forEach((domain) => {
        const _split = domain.domain.split(" ");
        if (_split.length === 2) {
          const domainEmail = _split[1].replace(/\(|\)/g, "");
          const domainSplit = domainEmail.split("@");
          setFromEmailPrefix(domainSplit[0]);
          setFromEmailSufix(domainSplit[1]);
        }
      });
    }
  }, [domainStore.list]);

  const validate = () => {
    if (_.isEmpty(name)) {
      return "Name cannot be empty!";
    } else if (editorType === "unlayer" && !builderBody) {
      return "Email body can't be empty!";
    } else if (editorType !== "unlayer" && !body) {
      return "Email body can't be empty!";
    }

    if (selectedEmailSendType === "CAMPAIGN_API") {
      if (!fromName) {
        return "From name cannot be empty!";
      }
      if (!fromEmailSufix || !fromEmailPrefix) {
        return "Invalid from email";
      }
      if (fromEmailSufix && fromEmailPrefix && !validator.isEmail(`${fromEmailPrefix}@${fromEmailSufix}`)) {
        return "Invalid from email";
      }
    } else {
    }

    return "";
  };
  const getTemplateFormedData = () => ({
    name: name,
    subject: subject,
    templateId: templateId ? templateId : null,
    content: editorType === "unlayer" ? builderBody : body,
    editorType: editorType ? editorType : "tiny_cloud",
    emailSendType: selectedEmailSendType,
    isNewThread,
    defaultVariableValues: defaultVariableValues,

    from: fromEmailSufix && fromEmailPrefix ? `${fromEmailPrefix}@${fromEmailSufix}` : null,
    fromName: fromName || null,
    replyToEmail: replyToEmail || null,
    replyToName: replyToName || null,
  });

  const handleSave = () => {
    const _validate = validate();
    if (_validate) {
      return dispatchSnackbarError(_validate);
    }

    if (props.isEdit) {
      const stepData = _.cloneDeep(props.selectedStep);

      if (props.isAbVarient) {
        stepData.abTesting.emailContent[props.selectedSubIndex] = getTemplateFormedData();
        if (props.selectedSubIndex === 0) {
          stepData.emailContent = getTemplateFormedData();
        }
        props.handleEdit(props.selectedIndex, stepData);
      } else {
        stepData.emailContent = getTemplateFormedData();
        props.handleEdit(props.selectedIndex, stepData);
      }
    } else {
      if (props.isAbVarient && props.selectedStep) {
        const stepData = _.cloneDeep(props.selectedStep);
        const abTestingEmailContent = { ...getTemplateFormedData(), isActive: true };
        if (stepData.abTesting && stepData.abTesting.emailContent.length > 0) {
          stepData.abTesting.emailContent.push(abTestingEmailContent);
        } else {
          stepData.abTesting = {
            isAutomate: true,
            condition: { stat: "SENT", type: "GREATER_THAN", value: 50 },
            emailContent: [{ ...stepData.emailContent, isActive: true }, abTestingEmailContent],
          };
        }

        props.handleEdit(props.selectedIndex, stepData);
      } else {
        const payload = {
          name: name,
          executionOrder: props.totalSteps + 1,
          channelType: "EMAIL",
          isEnabled: true,
          delay: props.totalSteps === 0 ? 300 : 86400,
          emailContent: getTemplateFormedData(),
        };
        props.handleAdd(payload);
      }
    }
  };

  const onSignitureSelection = (content) => {
    if (body.includes(`saino-body`)) {
      const div = document.createElement("div");
      div.innerHTML = body;
      const elements = div.querySelector("#saino-body");
      const signatureBlock = `<div><div id="saino-body"><div>${elements.innerHTML}</div></div><div id="saino-signature">${content.signature}</div></div>`;
      setDefaultBody(signatureBlock);
      setBody(signatureBlock);
    } else if (body && !body.includes("saino-signature") && !body.includes(`saino-body`)) {
      const signatureBlock = `<div><div id="saino-body"><div>${body}<br/></div></div><div id="saino-signature">${content.signature}</div></div>`;
      setDefaultBody(signatureBlock);
      setBody(signatureBlock);
    } else {
      const signatureBlock = `<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature">${content.signature}</div></div>`;
      setDefaultBody(signatureBlock);
      setBody(signatureBlock);
    }
  };
  const addVariable = (item, key, variable) => {
    window.tinyMCE.execCommand("mceInsertContent", false, variable);
  };

  const onTemplateChoose = (data) => {
    if (data.editorType === "unlayer") {
      setBuilderBody(data.content);
      setTemplateId(data._id);
    } else {
      setDefaultBody(data.content);
      setBody(data.content);
      setBuilderBody("");
      setTemplateId(data._id);
    }
    setEditorType(data.editorType);
    setShowTemplateManager(false);
  };

  const cancelSelectTemplate = () => {
    setShowTemplateManager(false);
  };

  const onUnsubsribeClick = () => {
    window.tinyMCE.execCommand("mceInsertContent", false, `<a href="{{customUnsubscribeLink}}" target="_blank" rel="noopener noreferrer">Unsubscribe</a>`);
  };

  return (
    <div>
      <div>
        <Form.Group>
          <Form.Label>Type of Email Execution</Form.Label>
          <RadioSelectorWithTooltip disabled={props.isAbVarient} setValue={setSelectedEmailSendType} value={selectedEmailSendType} options={emailSendTypeOptions} />
        </Form.Group>
        {selectedEmailSendType === "CAMPAIGN_API" ? (
          !isCampaignPermission ? (
            <CustomEmptyPage page="no-access-campaign" />
          ) : !fromEmailSufix ? (
            <CustomEmptyPage page="add-domain" hideBorder={true} />
          ) : (
            <CampaignEmailForm
              name={name}
              setName={setName}
              fromName={fromName}
              isReplyTo={isReplyTo}
              replyToName={replyToName}
              replyToEmail={replyToEmail}
              subject={subject}
              setFormName={setFormName}
              setIsReplyTo={setIsReplyTo}
              setReplyToName={setReplyToName}
              setReplyToEmail={setReplyToEmail}
              setSubject={setSubject}
              fromEmailPrefix={fromEmailPrefix}
              setFromEmailPrefix={setFromEmailPrefix}
              fromEmailSufix={fromEmailSufix}
              setFromEmailSufix={setFromEmailSufix}
              body={body}
              editorType={editorType}
              defaultBody={defaultBody}
              builderBody={builderBody}
              setBody={setBody}
              setShowTemplateManager={setShowTemplateManager}
              handleSave={handleSave}
              setShowVariableOptions={setShowVariableOptions}
              onSignitureSelection={onSignitureSelection}
              onUnsubsribeClick={onUnsubsribeClick}
              setShowAIWizardSidePanel={setShowAIWizardSidePanel}
            />
          )
        ) : null}

        {/* // {selectedEmailSendType === "CAMPAIGN_API" && fromEmailSufix && (<CampaignEmailForm
          //   name={name}
          //   setName={setName}
          //   fromName={fromName}
          //   isReplyTo={isReplyTo}
          //   replyToName={replyToName}
          //   replyToEmail={replyToEmail}
          //   subject={subject}
          //   setFormName={setFormName}
          //   setIsReplyTo={setIsReplyTo}
          //   setReplyToName={setReplyToName}
          //   setReplyToEmail={setReplyToEmail}
          //   setSubject={setSubject}
          //   fromEmailPrefix={fromEmailPrefix}
          //   setFromEmailPrefix={setFromEmailPrefix}
          //   fromEmailSufix={fromEmailSufix}
          //   setFromEmailSufix={setFromEmailSufix}
          //   body={body}
          //   editorType={editorType}
          //   defaultBody={defaultBody}
          //   builderBody={builderBody}
          //   setBody={setBody}
          //   setShowTemplateManager={setShowTemplateManager}
          //   handleSave={handleSave}
          //   setShowVariableOptions={setShowVariableOptions}
          //   onSignitureSelection={onSignitureSelection}
          //   onUnsubsribeClick={onUnsubsribeClick}
          //   setShowAIWizardSidePanel={setShowAIWizardSidePanel}
          // />)}
          // {selectedEmailSendType === "CAMPAIGN_API" && !fromEmailSufix && (
          //   <> <CustomEmptyPage page="add-domain" hideBorder={true} /></>
          // )} */}
        {selectedEmailSendType === "EMAIL_SYNC" && _.isEmpty(syncSettingsStore.data) && !syncSettingsStore.loading && <CustomEmptyPage page="connectEmail" />}
        {selectedEmailSendType === "EMAIL_SYNC" && !_.isEmpty(syncSettingsStore.data) && !syncSettingsStore.loading && (
          <SyncEmailFrom
            name={name}
            setName={setName}
            isNewThread={isNewThread}
            setIsNewThread={setIsNewThread}
            subject={subject}
            editorType={editorType}
            setSubject={setSubject}
            body={body}
            defaultBody={defaultBody}
            builderBody={builderBody}
            setBody={setBody}
            onUnsubsribeClick={onUnsubsribeClick}
            sendType={"EMAIL_SYNC"}
            handleSave={handleSave}
            showNewThreadButton={showNewThreadButton}
            onSignitureSelection={onSignitureSelection}
            setShowTemplateManager={setShowTemplateManager}
            setShowVariableOptions={setShowVariableOptions}
            setShowAIWizardSidePanel={setShowAIWizardSidePanel}
          />
        )}
        {selectedEmailSendType === "MANUAL" && (
          <SyncEmailFrom
            name={name}
            setName={setName}
            isNewThread={isNewThread}
            setIsNewThread={setIsNewThread}
            subject={subject}
            editorType={editorType}
            setSubject={setSubject}
            body={body}
            defaultBody={defaultBody}
            builderBody={builderBody}
            setBody={setBody}
            sendType={"MANUAL"}
            onUnsubsribeClick={onUnsubsribeClick}
            handleSave={handleSave}
            showNewThreadButton={showNewThreadButton}
            onSignitureSelection={onSignitureSelection}
            setShowTemplateManager={setShowTemplateManager}
            setShowVariableOptions={setShowVariableOptions}
            setShowAIWizardSidePanel={setShowAIWizardSidePanel}
          />
        )}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <TemplateManager show={showTemplateManager} channel={"email"} category={"CRM"} showChoose={true} onCloseClick={(data) => onTemplateChoose(data)} handleClose={cancelSelectTemplate} />
        <VariableOptionsPro show={showVariableOptions} setShow={setShowVariableOptions} addVariable={addVariable} defaultVariableValues={defaultVariableValues} setDefaultVariableValues={setDefaultVariableValues} />
        <AIWizardSidePanel
          show={showAIWizardSidePanel}
          handleClose={() => {
            setShowAIWizardSidePanel(false);
          }}
        />
      </div>
    </div>
  );
}
