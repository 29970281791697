import { markAsDone, markAsUndone, searchAllAssociations, snoozeActivity, getUserActivityLogForSidePannel } from "../types/activity.types";

export const markAsDoneRequest = (req, cb) => ({
  type: markAsDone.MARK_AS_DONE_ACTIVITY_REQUEST,
  payload: req,
  cb,
});
export const markAsDoneSuccess = (req) => ({
  type: markAsDone.MARK_AS_DONE_ACTIVITY_SUCCESS,
  payload: req,
});
export const markAsDoneError = (req) => ({
  type: markAsDone.MARK_AS_DONE_ACTIVITY_ERROR,
  payload: req,
});

export const markAsUndoneRequest = (req, cb) => ({
  type: markAsUndone.MARK_AS_UNDONE_ACTIVITY_REQUEST,
  payload: req,
  cb,
});
export const markAsUndoneSuccess = (req) => ({
  type: markAsUndone.MARK_AS_UNDONE_ACTIVITY_SUCCESS,
  payload: req,
});
export const markAsUndoneError = (req) => ({
  type: markAsUndone.MARK_AS_UNDONE_ACTIVITY_ERROR,
  payload: req,
});

export const searchAllAssociationsRequest = (req, cb) => ({
  type: searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_REQUEST,
  payload: req,
  cb,
});
export const searchAllAssociationsSuccess = (req) => ({
  type: searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_SUCCESS,
  payload: req,
});
export const searchAllAssociationsError = (req) => ({
  type: searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_ERROR,
  payload: req,
});

export const snoozeActivityRequest = (req, cb) => ({
  type: snoozeActivity.SNOOZE_ACTIVITY_REQUEST,
  payload: req,
  cb,
});
export const snoozeActivitySuccess = (req) => ({
  type: snoozeActivity.SNOOZE_ACTIVITY_SUCCESS,
  payload: req,
});
export const snoozeActivityError = (req) => ({
  type: snoozeActivity.SNOOZE_ACTIVITY_ERROR,
  payload: req,
});

export const getUserActivityLogForSidePannelRequest = (req, cb) => ({
  type: getUserActivityLogForSidePannel.GET_USER_ACTIVITY_LOGS_REQUEST_FOR_SIDEPANEL,
  payload: req,
  cb,
});
