import React, { useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function SalesForecastChart(props) {
  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll('[shape-rendering="auto"]').forEach((e) => e.remove());
    }, 1000);
  });

  useLayoutEffect(() => {
    const chart = am4core.create(props.chartId, am4charts.XYChart);

    // Add data
    chart.data = props.data;

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;

    chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    function createSeries(field, name) {
      // Set up series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "category";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = `{name}[/]\n{categoryX}: ${props.currency} {valueY}`;

      // Add label
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = `${props.currency} {valueY}`;
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }
    createSeries("won", "Won Deals");
    createSeries("active", "Active Deals");

    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = "Expected Outcome";
    lineSeries.dataFields.valueY = "target";
    lineSeries.dataFields.categoryX = "category";

    lineSeries.stroke = am4core.color("#fdd400");
    lineSeries.strokeWidth = 3;
    lineSeries.propertyFields.strokeDasharray = "lineDash";
    lineSeries.tooltip.label.textAlign = "middle";

    var bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{name} in {categoryX}: {valueY}";
    var circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;
  }, [props.data, props.interval, props.chartId, props.currency]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default SalesForecastChart;
