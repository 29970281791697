import styled from "styled-components";

// export const BoardContainer = styled(Contents)`
//   margin: 0;
//   position: unset;

//   > div {
//     padding-left: 20px;
//   }
// `;

// export const DealContent = styled(MainContent)`
//   margin: 0;
// `;

export const ScrolledContent = styled.div`
  padding: 4px 0 8px;
  margin: 6px 10px 4px 5px;
  flex: 1;
  will-change: contents;
  overflow: auto;
`;

export const FixedContent = styled.div`
  position: relative;
  padding-top: 10px;
  width: 80%;
  margin: 0 10%;
`;

export const Divider = styled.div`
  border-bottom: 1px dotted #dee4e7;
  padding-bottom: 15px;
  margin: 0 15px 15px 250px;

  @media (max-width: 1170px) {
    margin-left: 15px;
  }
`;

export const ViewDivider = styled(Divider)`
  margin: 15px 0 22.5px;
  padding: 0;
`;

export const BodyRow = styled.div`
  display: flex;
  background: #fff;

  > span {
    flex: 1;
    padding: 12px 15px;
    font-weight: 500;
    border-bottom: 1px solid #eee;

    label {
      color: #888;
      font-size: 85%;
    }
  }
`;

export const HeadRow = styled(BodyRow)`
  background: #ffffff;
  position: sticky;
  margin: 0;

  > span {
    text-transform: uppercase;
    color: #aaaeb3;
    font-weight: bold;
  }
`;

export const TableView = styled.div`
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  overflow: hidden;
  background: #fff;
`;

export const StageName = styled.span`
  transition: all ease 0.3s;
  border-right: 1px solid transparent;
  // font-weight: bold !important;
  // background: #F0F0F0;
  // border-color: #EEE;
  // border-top: 2px solid #63D2D6;
  // border-bottom: 2px solid transparent !important;

  > i {
    margin-left: 5px;
    color: #6569df;
    transition: all ease 0.3s;
    display: inline-block;
    transform: rotate(180deg);
  }
`;

export const CenterButton = styled.div`
  text-align: center;
  margin: 10px;
`;

export const Deals = styled.div`
  overflow: hidden;
  border-bottom: 3px solid #f0f0f0;
`;

export const SubHead = styled(HeadRow)`
  background: #f0f0f0;
  margin: 0;
  font-size: 12px;

  > span {
    padding: 10px 15px;
    font-weight: 500;
  }
`;

export const Result = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  color: #888;
`;

export const Content = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
`;

export const Lost = styled.span`
  color: #ea475d;
  margin-right: 10px;
`;

export const Name = styled.div`
  flex: 1;
  padding: 7.5px 15px;
  font-size: 14px;
  flex-shrink: 0;
`;

export const StageWrap = styled.div`
  overflow: hidden;
  padding-bottom: 6px;
`;

export const StageContainer = styled.div`
  min-width: 100%;
  font-size: 12px;
  justify-content: space-between;
  float: right;
`;

export const Stayed = styled.div`
  border-left: 1px solid #eee;
  padding: 9px 15px;
  font-weight: 600;
  font-size: 18px;
  background: #ffffff;
  color: #888;
  flex-shrink: 0;

  span {
    color: #373737;
  }
`;

export const Values = styled.div`
  text-align: right;
  padding: 6px 10px 0 10px;
  font-weight: bold;
  color: #3ccc38;
`;
