import {
  GET_PIPELINE_REQUEST,
  GET_PIPELINE_SUCCESS,
  GET_PIPELINE_ERROR,
  ADD_PIPELINE_REQUEST,
  ADD_PIPELINE_SUCCESS,
  ADD_PIPELINE_ERROR,
  GET_STAGES_REQUEST,
  GET_STAGES_SUCCESS,
  GET_STAGES_ERROR,
  GET_STAGES_REQUEST_AFTER,
  ADD_DEAL_REQUEST,
  ADD_DEAL_SUCCESS,
  ADD_DEAL_ERROR,
  GET_DEAL_REQUEST,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  GET_DEAL_REQUEST_AFTER,
  UPDATE_PIPELINE_REQUEST,
  UPDATE_PIPELINE_SUCCESS,
  UPDATE_PIPELINE_ERROR,
  DELETE_PIPELINE_REQUEST,
  DELETE_PIPELINE_SUCCESS,
  DELETE_PIPELINE_ERROR,
  MOVE_DEALINSTAGES_REQUEST,
  MOVE_DEALINSTAGE_SUCCESS,
  MOVE_DEALINSTAGES_ERROR,
  DEAL_OPERATION_REQUEST,
  DEAL_OPERATION_SUCCESS,
  DEAL_OPERATION_ERROR,
  ADD_STAGEINPIPELINE_REQUEST,
  ADD_SATGEINPIPELINE_SUCCESS,
  ADD_STAGEINPIPELINE_ERROR,
  UPDATE_STAGEINPIPELINE_REQUEST,
  UPDATE_SATGEINPIPELINE_SUCCESS,
  UPDATE_STAGEINPIPELINE_ERROR,
  UPDATE_STAGEORDERINPIPELINE_REQUEST,
  UPDATE_STAGEORDERINPIPELINE_SUCCESS,
  UPDATE_STAGEORDERINPIPELINE_ERROR,
  DELETE_PIPELINESTAGE_REQUEST,
  DELETE_PIPELINESTAGE_SUCCESS,
  DELETE_PIPELINESTAGE_ERROR,
  GET_LOSTREASONS_REQUEST,
  GET_LOSTREASONS_SUCCESS,
  GET_LOSTREASONS_ERROR,

  //filters
  GET_SAVEDFILTERS_REQUEST,
  GET_SAVEDFILTERS_SUCCESS,
  GET_SAVEDFILTERS_ERROR,
  ADD_SAVEDFILTERS_REQUEST,
  ADD_SAVEDFILTERS_SUCCESS,
  ADD_SAVEDFILTERS_ERROR,
  EDIT_SAVEDFILTERS_REQUEST,
  EDIT_SAVEDFILTERS_SUCCESS,
  EDIT_SAVEDFILTERS_ERROR,
  DELETE_SAVEDFILTERS_REQUEST,
  DELETE_SAVEDFILTERS_SUCCESS,
  DELETE_SAVEDFILTERS_ERROR,
  FAVORITE_SAVEDFILTERS_REQUEST,
  FAVORITE_SAVEDFILTERS_SUCCESS,
  FAVORITE_SAVEDFILTERS_ERROR,
  APPLYFILTER,
  CLEARFILTER,

  //deal-fields
  GET_DEALFIELDS_REQUEST,
  GET_DEALFIELDS_SUCCESS,
  GET_DEALFIELDS_ERROR,

  //popup-details
  GET_DEALPOPUPDETAILS_REQUEST,
  GET_DEALPOPUPDETAILS_SUCCESS,
  GET_DEALPOPUPDETAILS_ERROR,
  GET_DEALPOPUPDETAILS_REQUEST_AFTER,

  //update deal-popup details
  UPDATE_DEALPOPUPDETAILS_REQUEST,
  UPDATE_DEALPOPUPDETAILS_SUCCESS,
  UPDATE_DEALPOPUPDETAILS_ERROR,

  //tags
  GET_DEAL_TAGS_ERROR,
  GET_DEAL_TAGS_REQUEST,
  GET_DEAL_TAGS_SUCCESS,
  DELETE_DEAL_TAGS_REQUEST,
  DELETE_DEAL_TAGS_SUCCESS,
  DELETE_DEAL_TAGS_ERROR,
  UPDATE_DEAL_TAGS_REQUEST,
  UPDATE_DEAL_TAGS_SUCCESS,
  UPDATE_DEAL_TAGS_ERROR,
  ADD_DEAL_TAGS_REQUEST,
  ADD_DEAL_TAGS_SUCCESS,
  ADD_DEAL_TAGS_ERROR,

  //deal search
  DEAL_SEARCH_REQUEST,
  DEAL_SEARCH_SUCCESS,
  DEAL_SEARCH_ERROR,

  //change person
  CHANGE_DEALPERSON_REQUEST,
  CHANGE_DEALPERSON_SUCCESS,
  CHANGE_DEALPERSON_ERROR,
  CHANGE_DEALCOMPANY_REQUEST,
  CHANGE_DEALCOMPANY_SUCCESS,
  CHANGE_DEALCOMPANY_ERROR,
  CHANGE_DEALDETAILSINPOPUP_REQUEST,
  CHANGE_DEALDETAILSINPOPUP_SUCCESS,
  CHANGE_DEALDETAILSINPOPUP_ERROR,
  HIDE_WON_DEAL_ICON,
  MARKASDEFAULT_REQUEST,
  MARKASDEFAULT_SUCCESS,
  MARKASDEFAULT_ERROR,
  UPDATE_DEAL_PIPELINE_LOADING_DATA,
} from "./types";

//MARKAS DEFAULT

export function markAsDefaultRequest(req) {
  return {
    type: MARKASDEFAULT_REQUEST,
    payload: req,
  };
}
export function markAsDefaultSuccess(res) {
  return {
    type: MARKASDEFAULT_SUCCESS,
    payload: res,
  };
}
export function markAsDefaultError(err) {
  return {
    type: MARKASDEFAULT_ERROR,
    payload: err,
  };
}

//CHANGE DEAL DETAILS INSIDE POPUP

export function changeDealDetailsInPopupRequest(req) {
  return {
    type: CHANGE_DEALDETAILSINPOPUP_REQUEST,
    payload: req,
  };
}
export function changeDealDetailsInPopupSuccess(res) {
  return {
    type: CHANGE_DEALDETAILSINPOPUP_SUCCESS,
    payload: res,
  };
}
export function changeDealDetailsInPopupError(err) {
  return {
    type: CHANGE_DEALDETAILSINPOPUP_ERROR,
    payload: err,
  };
}

//CHANGE PERSON

export function changeDealPersonRequest(req) {
  return {
    type: CHANGE_DEALPERSON_REQUEST,
    payload: req,
  };
}
export function changeDealPersonSuccess(res) {
  return {
    type: CHANGE_DEALPERSON_SUCCESS,
    payload: res,
  };
}
export function changeDealPersonError(err) {
  return {
    type: CHANGE_DEALPERSON_ERROR,
    payload: err,
  };
}

//chnage company

export function changeDealCompanyRequest(req) {
  return {
    type: CHANGE_DEALCOMPANY_REQUEST,
    payload: req,
  };
}
export function changeDealCompanySuccess(res) {
  return {
    type: CHANGE_DEALCOMPANY_SUCCESS,
    payload: res,
  };
}
export function changeDealCompanyError(err) {
  return {
    type: CHANGE_DEALCOMPANY_ERROR,
    payload: err,
  };
}

//DEAL SEARCH

export function dealSearchRequest(req) {
  return {
    type: DEAL_SEARCH_REQUEST,
    payload: req,
  };
}
export function dealSearchSuccess(res) {
  return {
    type: DEAL_SEARCH_SUCCESS,
    payload: res,
  };
}
export function dealSearchError(err) {
  return {
    type: DEAL_SEARCH_ERROR,
    payload: err,
  };
}

//UPDATE DEAL POPUPDETAILS

export function updateDealpopupDetailsRequest(req) {
  return {
    type: UPDATE_DEALPOPUPDETAILS_REQUEST,
    payload: req,
  };
}
export function updateDealpopupDetailsSuccess(res) {
  return {
    type: UPDATE_DEALPOPUPDETAILS_SUCCESS,
    payload: res,
  };
}
export function updateDealpopupDetailsError(err) {
  return {
    type: UPDATE_DEALPOPUPDETAILS_ERROR,
    payload: err,
  };
}

//GET POPUP DETAILS
export function getDealPopupDetailsRequest(req, cb) {
  return {
    type: GET_DEALPOPUPDETAILS_REQUEST,
    payload: req,
    cb,
  };
}

export function getDealPopupDetailsRequestAfter(req) {
  return {
    type: GET_DEALPOPUPDETAILS_REQUEST_AFTER,
    payload: req,
  };
}

export function getDealPopupDetailsSuccess(res) {
  return {
    type: GET_DEALPOPUPDETAILS_SUCCESS,
    payload: res,
  };
}

export function getDealPopupDetailsError(err) {
  return {
    type: GET_DEALPOPUPDETAILS_ERROR,
    payload: err,
  };
}

// GET DEAL FIELDS

export function getDealFieldsRequest(req) {
  return {
    type: GET_DEALFIELDS_REQUEST,
    paylaod: req,
  };
}

export function getDealFieldsSuccess(req) {
  return {
    type: GET_DEALFIELDS_SUCCESS,
    paylaod: req,
  };
}

export function getDealFieldsError(req) {
  return {
    type: GET_DEALFIELDS_ERROR,
    paylaod: req,
  };
}

export function applyFilter(res) {
  return {
    type: APPLYFILTER,
    payload: res,
  };
}

export function clearFilter(res) {
  return {
    type: CLEARFILTER,
    payload: res,
  };
}

export function getSavedFiltersRequest(req) {
  return {
    type: GET_SAVEDFILTERS_REQUEST,
    payload: req,
  };
}

export function getSavedFiltersSuccess(res) {
  return {
    type: GET_SAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function getSavedFiltersError(err) {
  return {
    type: GET_SAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function addSavedFiltersRequest(req, cb) {
  return {
    type: ADD_SAVEDFILTERS_REQUEST,
    payload: req,
    cb,
  };
}

export function addSavedFiltersSuccess(res) {
  return {
    type: ADD_SAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function addSavedFiltersError(err) {
  return {
    type: ADD_SAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function editSavedFiltersRequest(req, cb) {
  return {
    type: EDIT_SAVEDFILTERS_REQUEST,
    payload: req,
    cb,
  };
}

export function editSavedFiltersSuccess(res) {
  return {
    type: EDIT_SAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function editSavedFiltersError(err) {
  return {
    type: EDIT_SAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function deleteSavedFiltersRequest(req) {
  return {
    type: DELETE_SAVEDFILTERS_REQUEST,
    payload: req,
  };
}

export function deleteSavedFiltersSuccess(res) {
  return {
    type: DELETE_SAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function deleteSavedFiltersError(err) {
  return {
    type: DELETE_SAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function favoriteSavedFiltersRequest(req) {
  return {
    type: FAVORITE_SAVEDFILTERS_REQUEST,
    payload: req,
  };
}

export function favoriteSavedFiltersSuccess(res) {
  return {
    type: FAVORITE_SAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function favoriteSavedFiltersError(err) {
  return {
    type: FAVORITE_SAVEDFILTERS_ERROR,
    payload: err,
  };
}

//get pipeline actions
export function getPipelineRequest(req) {
  return {
    type: GET_PIPELINE_REQUEST,
    payload: req,
  };
}

export function getPipelineSuccess(res) {
  return {
    type: GET_PIPELINE_SUCCESS,
    payload: res,
  };
}

export function getPipelineError(err) {
  return {
    type: GET_PIPELINE_ERROR,
    payload: err,
  };
}
//add pipeline actions
export function addPipelineRequest(req, cb) {
  return {
    type: ADD_PIPELINE_REQUEST,
    payload: req,
    cb,
  };
}
export function addPipelineSuccess(res) {
  return {
    type: ADD_PIPELINE_SUCCESS,
    payload: res,
  };
}
export function addPipelineError(err) {
  return {
    type: ADD_PIPELINE_ERROR,
    payload: err,
  };
}
//get stages actions
export function getStagesRequest(req) {
  return {
    type: GET_STAGES_REQUEST,
    payload: req,
  };
}
export function getStagesRequestAfter(req) {
  return {
    type: GET_STAGES_REQUEST_AFTER,
    payload: req,
  };
}
export function getStagesSuccess(res) {
  return {
    type: GET_STAGES_SUCCESS,
    payload: res,
  };
}
export function getStagesError(err) {
  return {
    type: GET_STAGES_ERROR,
    payload: err,
  };
}
//add deal actions
export function addDealRequest(req) {
  return {
    type: ADD_DEAL_REQUEST,
    payload: req,
  };
}
export function addDealSuccess(res) {
  return {
    type: ADD_DEAL_SUCCESS,
    payload: res,
  };
}
export function addDealError(err) {
  return {
    type: ADD_DEAL_ERROR,
    payload: err,
  };
}

export function getDealRequest(req) {
  return {
    type: GET_DEAL_REQUEST,
    payload: req,
  };
}
export function getDealRequestAfter(req) {
  return {
    type: GET_DEAL_REQUEST_AFTER,
    payload: req,
  };
}
export function getDealSuccess(res) {
  return {
    type: GET_DEAL_SUCCESS,
    payload: res,
  };
}
export function getDealError(err) {
  return {
    type: GET_DEAL_ERROR,
    payload: err,
  };
}

export function hideWonDealIcon(req) {
  return {
    type: HIDE_WON_DEAL_ICON,
    paylaod: req,
  };
}

export function moveDealInStagesRequest(req) {
  return {
    type: MOVE_DEALINSTAGES_REQUEST,
    payload: req,
  };
}
export function moveDealInStagesSuccess(res) {
  return {
    type: MOVE_DEALINSTAGE_SUCCESS,
    payload: res,
  };
}
export function moveDealInStagesError(err) {
  return {
    type: MOVE_DEALINSTAGES_ERROR,
    payload: err,
  };
}

export function updatePipelineRequest(req) {
  return {
    type: UPDATE_PIPELINE_REQUEST,
    payload: req,
  };
}
export function updatePipelineSuccess(res) {
  return {
    type: UPDATE_PIPELINE_SUCCESS,
    payload: res,
  };
}
export function updatePipelineError(err) {
  return {
    type: UPDATE_PIPELINE_ERROR,
    payload: err,
  };
}

export function deletePipelineRequest(req, cb) {
  return {
    type: DELETE_PIPELINE_REQUEST,
    payload: req,
    cb,
  };
}
export function deletePipelineSuccess(res) {
  return {
    type: DELETE_PIPELINE_SUCCESS,
    payload: res,
  };
}
export function deletePipelineError(err) {
  return {
    type: DELETE_PIPELINE_ERROR,
    payload: err,
  };
}

export function dealOperationRequest(req) {
  return {
    type: DEAL_OPERATION_REQUEST,
    payload: req,
  };
}
export function dealOperationSuccess(res) {
  return {
    type: DEAL_OPERATION_SUCCESS,
    payload: res,
  };
}
export function dealOperationError(err) {
  return {
    type: DEAL_OPERATION_ERROR,
    payload: err,
  };
}

export function addStageInpipelineRequest(req) {
  return {
    type: ADD_STAGEINPIPELINE_REQUEST,
    payload: req,
  };
}
export function addStageInpipelineSuccess(res) {
  return {
    type: ADD_SATGEINPIPELINE_SUCCESS,
    payload: res,
  };
}
export function addStageInpipelineError(err) {
  return {
    type: ADD_STAGEINPIPELINE_ERROR,
    payload: err,
  };
}

export function updateStageInpipelineRequest(req) {
  return {
    type: UPDATE_STAGEINPIPELINE_REQUEST,
    payload: req,
  };
}
export function updateStageInpipelineSuccess(res) {
  return {
    type: UPDATE_SATGEINPIPELINE_SUCCESS,
    payload: res,
  };
}
export function updateStageInpipelineError(err) {
  return {
    type: UPDATE_STAGEINPIPELINE_ERROR,
    payload: err,
  };
}

export function updateStageOrderInPipelineRequest(req) {
  return {
    type: UPDATE_STAGEORDERINPIPELINE_REQUEST,
    payload: req,
  };
}
export function updateStageOrderInPipelineSuccess(res) {
  return {
    type: UPDATE_STAGEORDERINPIPELINE_SUCCESS,
    payload: res,
  };
}
export function updateStageOrderInPipelineError(err) {
  return {
    type: UPDATE_STAGEORDERINPIPELINE_ERROR,
    payload: err,
  };
}

export function deletePipelineStageRequest(req) {
  return {
    type: DELETE_PIPELINESTAGE_REQUEST,
    payload: req,
  };
}
export function deletePipelineStageSuccess(res) {
  return {
    type: DELETE_PIPELINESTAGE_SUCCESS,
    payload: res,
  };
}
export function deletePipelineStageError(err) {
  return {
    type: DELETE_PIPELINESTAGE_ERROR,
    payload: err,
  };
}

export function getLostReasonsRequest(req) {
  return {
    type: GET_LOSTREASONS_REQUEST,
    payload: req,
  };
}
export function getLostReasonsSuccess(res) {
  return {
    type: GET_LOSTREASONS_SUCCESS,
    payload: res,
  };
}
export function getLostReasonsError(err) {
  return {
    type: GET_LOSTREASONS_ERROR,
    payload: err,
  };
}

//tags
export function getDealTagRequest(req) {
  return {
    type: GET_DEAL_TAGS_REQUEST,
    payload: req,
  };
}
export function getDealTagSuccess(res) {
  return {
    type: GET_DEAL_TAGS_SUCCESS,
    payload: res,
  };
}
export function getDealTagError(err) {
  return {
    type: GET_DEAL_TAGS_ERROR,
    payload: err,
  };
}

export function deleteDealTagRequest(req) {
  return {
    type: DELETE_DEAL_TAGS_REQUEST,
    payload: req,
  };
}
export function deleteDealTagSuccess(res) {
  return {
    type: DELETE_DEAL_TAGS_SUCCESS,
    payload: res,
  };
}
export function deleteDealTagError(err) {
  return {
    type: DELETE_DEAL_TAGS_ERROR,
    payload: err,
  };
}

export function addDealTagRequest(req) {
  return {
    type: ADD_DEAL_TAGS_REQUEST,
    payload: req,
  };
}
export function addDealTagSuccess(res) {
  return {
    type: ADD_DEAL_TAGS_SUCCESS,
    payload: res,
  };
}
export function addDealTagError(err) {
  return {
    type: ADD_DEAL_TAGS_ERROR,
    payload: err,
  };
}

export function updateDealTagRequest(req) {
  return {
    type: UPDATE_DEAL_TAGS_REQUEST,
    payload: req,
  };
}
export function updateDealTagSuccess(res) {
  return {
    type: UPDATE_DEAL_TAGS_SUCCESS,
    payload: res,
  };
}
export function updateDealTagError(err) {
  return {
    type: UPDATE_DEAL_TAGS_ERROR,
    payload: err,
  };
}

export function updateDealPipelineLoadingData(req) {
  return {
    type: UPDATE_DEAL_PIPELINE_LOADING_DATA,
    payload: req,
  };
}
