import React from "react";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import { InputLimit } from "../../../../../constants";

export default function EmailConfigForm({ config, setConfig }) {
  const handleChange = (keyName, value) => {
    setConfig({
      ...config,
      [keyName]: value,
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group id="campaign-builder-forms-email-config-form-sender-name">
            <Form.Label aria-label="from-name">From Name</Form.Label>
            <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter From Name" value={config.fromName || ""} onChange={(e) => handleChange("fromName", e.target.value)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>From Email</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control type="text" value={config.fromEmailPrefix} placeholder="slug" aria-label="slug" onChange={(e) => handleChange("fromEmailPrefix", `${e.target.value}`)} />
              <InputGroup.Text id="basic-addon3">@{config.fromEmailSufix}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group id="campaign-builder-forms-email-config-form-reply-method">
        <Form.Check className="px-10" type={"checkbox"} id={`replyTo`}>
          <Form.Check.Input className="custom-checkbox" checked={config.isReplyTo || false} onChange={() => handleChange("isReplyTo", !config.isReplyTo)} type={"checkbox"} varient="primary" />
          <Form.Check.Label className="mt-1">{"Use different Reply To"}</Form.Check.Label>
        </Form.Check>
      </Form.Group>
      {config.isReplyTo && (
        <Row>
          <Col>
            <Form.Group id="campaign-builder-forms-email-config-form-reply-to-name">
              <Form.Label aria-label="reply-to-name">Reply To (Name)</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter Reply To Name" value={config.replyToName || ""} onChange={(e) => handleChange("replyToName", e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-builder-forms-email-config-form-reply-to-email">
              <Form.Label aria-label="reply-to-email">Reply To (Email)</Form.Label>
              <Form.Control maxLength={InputLimit.EMAIL} type="text" placeholder="Enter Reply To Email" value={config.replyToEmail || ""} onChange={(e) => handleChange("replyToEmail", e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Form.Group id="campaign-builder-forms-email-config-form-subject">
          <Form.Label aria-label="subject">Subject</Form.Label>
          <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter Email Subject" value={config.subject || ""} onChange={(e) => handleChange("subject", e.target.value)} />
        </Form.Group>
      </Row>
    </>
  );
}
