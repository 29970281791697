import React, { useState } from "react";
import _ from "lodash";
import { Dropdown, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function SavedViewsDropdown(props) {
  const [show, setShow] = useState(false);

  //selectedFilterId
  const getFilterName = (id) => {
    if (props.customFilters && props.customFilters.favorite && props.customFilters.favorite.length > 0) {
      for (let i = 0; i < props.customFilters.favorite.length; i++) {
        if (props.customFilters.favorite[i]._id === id) {
          return props.customFilters.favorite[i].filterName;
        }
      }
    }
    if (props.customFilters && props.customFilters.custom && props.customFilters.custom.length > 0) {
      for (let i = 0; i < props.customFilters.custom.length; i++) {
        if (props.customFilters.custom[i]._id === id) {
          return props.customFilters.custom[i].filterName;
        }
      }
    }
    return "Smart Views";
  };

  const onToggle = () => {
    setShow(!show);
  };

  return (
    <Dropdown drop="down" alignRight onToggle={onToggle} show={show}>
      <Dropdown.Toggle className="d-flex align-items-center z-index-0 btn  btn-sm dropdown-toggle btn-secondary rounded-2 ellipsis-dropdown-toggle bg-f8f8f8 border-width-1px border-style-solid border-color-e4e6ef">
        {props.selectedFilterId ? (
          <div className="d-flex gap-2 align-items-center ellipsis">
            <span className="font-weight-bolder ellipsis w-100">{getFilterName(props.selectedFilterId)} </span>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                props.handleSelect({});
              }}
            ></i>
          </div>
        ) : (
          <span>Smart Views</span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 pt-2 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
        {props.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center h-35vh-mn">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="h-35vh-mx position-relative overflow-y-auto">
              {props.customFilters && props.customFilters.favorite && props.customFilters.favorite.length > 0 ? (
                <>
                  {/* <Dropdown.Header>Favorites</Dropdown.Header> */}
                  {props.customFilters.favorite.map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => props.handleSelect(item)}>
                      <div className="w-100 d-flex smart-view-container flex-stack">
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{_.capitalize(item.filterName)}</Tooltip>}>
                          <span className="overflow-hidden text-overflow-ellipsis text-nowrap w-2000px"> {_.capitalize(item.filterName)}</span>
                        </OverlayTrigger>
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider className="m-0" />
                </>
              ) : null}
              {props.customFilters && props.customFilters.custom && props.customFilters.custom.length > 0 ? (
                <>
                  {/* <Dropdown.Header>All Views</Dropdown.Header> */}
                  {props.customFilters.custom.map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => props.handleSelect(item)}>
                      <div className="w-100 d-flex smart-view-container flex-stack">
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{_.capitalize(item.filterName)}</Tooltip>}>
                          <span className="overflow-hidden text-overflow-ellipsis text-nowrap w-2000px">{_.capitalize(item.filterName)}</span>
                        </OverlayTrigger>
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider className="m-0" />
                </>
              ) : null}
            </div>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
