import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown, Spinner, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function OwnerSelect({ field, setValue, value, clearable = false }) {
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const userRole = useSelector((store) => store.insight.userRole);
  const [selected, setSelected] = useState({});
  const [showClear, setShowClear] = useState(false);

  useEffect(() => {
    if (clearable && field && !field.isRequired && field.apiKeyName !== "owner") {
      setShowClear(true);
    } else {
      setShowClear(false);
    }
  }, [field, clearable]);

  useEffect(() => {
    if (userRole.list.users) {
      if (field.apiKeyName === "followers") {
        setSelected(
          userRole.list.users.find((owner) => {
            if (_.isArray(value) && !_.isEmpty(value)) {
              return value.includes(owner["id"]);
            }
            return owner["id"] === value;
          }),
        );
      } else {
        if (!value) {
          setSelected(userRole.list.users.find((owner) => owner["id"] === userProfile.data.userId));
          setValue(userProfile.data.userId, "owner");
        } else {
          setSelected(userRole.list.users.find((owner) => owner["id"] === value));
        }
      }
    }
  }, [userRole.list.users, value, userProfile, setValue, field.apiKeyName]);

  const clearClick = () => {
    setSelected({});
    setValue(null, field.apiKeyName);
  };

  return (
    <div className="d-flex gap-2 flex-center">
      <Dropdown className="dropdown-inline mt-1 w-100">
        <Dropdown.Toggle className="bg-white w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {!selected ? "Select " + field.fieldName : selected.name ? selected.name : selected.email}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu w-100-mn">
          {userRole.loading ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {userRole.list.users &&
                userRole.list.users.map((eachUser, index) => {
                  return (
                    <Dropdown.Item
                      className="dropdown-hover"
                      key={index}
                      onSelect={() => {
                        setSelected(eachUser);
                        setValue(eachUser["id"], field.apiKeyName);
                      }}
                    >
                      {eachUser.name ? eachUser.name : eachUser.email}
                    </Dropdown.Item>
                  );
                })}
            </PerfectScrollbar>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {showClear && !_.isEmpty(selected) && (
        <div>
          <Button className="pl-3 pr-0" onClick={() => clearClick()} size="sm" variant="transparent">
            <i className="fa fa-minus-circle remove-condition-icon"></i>
          </Button>
        </div>
      )}
    </div>
  );
}
