import React from "react";
import { Spinner, Form, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import UploadCSVFile from "../../../components/file-upload/csv-upload";
import { InputLimit } from "../../../constants";

export default function ImportStep3({ showFields, hideFields, setShowFields, setHideFields, fields, search, setSearch, importType, setSampleFileFields, sampleFileFields, file, setFile }) {
  if (importType === "default") {
    const editFields = (colId) => {
      if (!showFields.includes(colId)) {
        setHideFields(hideFields.filter((col) => col !== colId));
        setShowFields([...showFields, colId]);
        const temp = fields.list.find((field) => field["_id"] === colId);
        if (temp.fieldGroup !== "CUSTOM") setSampleFileFields([...sampleFileFields, temp.apiKeyName]);
        else setSampleFileFields([...sampleFileFields, `customFields.${temp.apiKeyName}`]);
      } else {
        setShowFields(showFields.filter((col) => col !== colId));
        setHideFields([...hideFields, colId]);
        const temp = fields.list.find((field) => field["_id"] === colId);
        if (temp.fieldGroup !== "CUSTOM") setSampleFileFields(sampleFileFields.filter((fieldKeyName) => fieldKeyName !== temp.apiKeyName));
        else setSampleFileFields(sampleFileFields.filter((fieldKeyName) => fieldKeyName !== `customFields.${temp.apiKeyName}`));
      }
    };
    return (
      <div className="w-100 px-2">
        <div className="fv-row">
          <div className="row py-5">
            {fields.loading ? (
              <div className="w-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <PerfectScrollbar className="scroll position-relative h-55vh-mx" options={{ wheelSpeed: 0.4 }}>
                <div className="d-flex justify-content-center mb-8">
                  <div className="w-50">
                    <Form.Control maxLength={InputLimit.TEXT} placeholder="Search" type="text" value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control>
                  </div>
                </div>
                <div className="py-6 px-3">
                  <div className="d-flex ">
                    <div className="col-6 d-flex flex-column align-items-center">
                      <h4>Available Fields</h4>
                      <ListGroup className="w-100 mt-2">
                        {showFields.map((field, colIndex) => {
                          return fields.list[fields.idToIndex[field]].fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                            <ListGroup.Item key={colIndex} onClick={() => editFields(field)}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-sm">
                                  <input type="checkbox" disabled checked={false} />
                                  <span className="mr-10px"></span>
                                  {fields.list[fields.idToIndex[field]].fieldName}
                                </label>
                              </span>
                            </ListGroup.Item>
                          ) : null;
                        })}
                      </ListGroup>
                    </div>
                    <div className="col-6 d-flex flex-column align-items-center">
                      <h4>Selected Fields</h4>
                      <ListGroup className="w-100 mt-2">
                        {hideFields.map((field, colIndex) => {
                          return fields.list[fields.idToIndex[field]].fieldName.toLowerCase().includes(search.toLowerCase()) ? (
                            <ListGroup.Item key={colIndex} onClick={() => editFields(field)}>
                              <span className="navi-text flex-grow-1">
                                <label className="checkbox checkbox-sm">
                                  <input type="checkbox" disabled checked={true} />
                                  <span className="mr-10px"></span>
                                  {fields.list[fields.idToIndex[field]].fieldName}
                                </label>
                              </span>
                            </ListGroup.Item>
                          ) : null;
                        })}
                      </ListGroup>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <UploadCSVFile file={file} setFile={setFile} />;
  }
}
