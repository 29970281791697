import React, { useState } from "react";
import { BlueGradientButton } from "../../../../components/button";
import { DeleteIconButton } from "../../../../components/button/icon-action-buttons";
import { ColumnFilter } from "../../../../components/column_filter";
import SmartViewFilter from "../../../../components/filter/smart-view-filter";
import ActivityTypeMenu from "../../activity-menu";
import ActivityStatus from "./activity-status-dropdown";
import SnoozeDropdown from "./snooze-dropdown";
import ActivitySourceDropdown from "./source-dropdown";
import OwnerFilter from "../../../../components/filter/owner-filter";
import "./styles.scss";

export default function ActivitySubHeader(props) {
  const [showStatusIcon, setShowStatusIcon] = useState(true);

  const onStateFilterClick = (status) => {
    setShowStatusIcon(false);
    props.setStatus(status);
  };
  const handleStatusClose = () => {
    setShowStatusIcon(true);
    props.setStatus("Todo");
    props.setStartDate(null);
    props.setEndDate(null);
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-between activity-subheader-wrapper position-relative">
      <ActivityTypeMenu selectedActivityType={props.selectedActivityType} handleActivityTypeClick={props.handleActivityTypeClick} />
      <div className="activity-subheader-section-2">
        {props.checked.length !== 0 ? (
          <div className="d-flex flex-row align-items-center gap-12px" >
            {props.checked.length} selected
            <DeleteIconButton onClick={() => props.setShowDelete(true)} />
            <SnoozeDropdown setSnoozeTime={props.setSnoozeTime} />
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center gap-12px">
            <div>
              <BlueGradientButton variant="success" size="sm" onClick={() => props.onAddActivityClick()}>
                + Activity
              </BlueGradientButton>
            </div>

            <SmartViewFilter
              pageSub={props.pageSub}
              page={props.page}
              customFilters={props.customFilters}
              loading={props.loading}
              setAddFilterFormData={props.setAddFilterFormData}
              virtualFilter={props.virtualFilter}
              applyVirtualFilter={props.applyVirtualFilter}
              selectedFilterId={props.selectedFilterId}
              favoriteFilter={props.favoriteFilter}
              applyFilter={props.applyFilter}
              addFilterFormData={props.addFilterFormData}
              addFilter={props.addFilter}
              editFilter={props.editFilter}
              deleteFilter={props.deleteFilter}
            />
            <ActivityStatus
              status={showStatusIcon ? "" : props.status}
              setStatus={onStateFilterClick}
              handleClose={handleStatusClose}
              startDate={props.startDate}
              setStartDate={props.setStartDate}
              endDate={props.endDate}
              setEndDate={props.setEndDate}
            />

            {/* <ActivityTypeDropdown
                            selectedActivityType={props.selectedActivityType}
                            handleActivityTypeClick={props.handleActivityTypeClick}
                        /> */}
            <OwnerFilter setValue={props.setOwner} value={props.owner} />
            <ActivitySourceDropdown source={props.source} handleSourceClick={props.handleSourceClick} />
            <ColumnFilter
              fieldType={"ACTIVITY"}
              showHideField={props.showHideField}
              updateFieldPriority={props.updateFieldPriority}
              idToIndex={props.idToIndex}
              columns={props.columns}
              showColumns={props.showColumns}
              setShowColumns={props.setShowColumns}
            />
          </div>
        )}
      </div>
    </div>
  );
}
