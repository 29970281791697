// profile overview
export const getUserProfile = {
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_ERROR: "GET_USER_PROFILE_ERROR",
};
export const updateUserProfile = {
  UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",
};
export const patchUserAvatarPic = {
  PATCH_USER_AVATAR_PIC_REQUEST: "PATCH_USER_AVATAR_PIC_REQUEST",
  PATCH_USER_AVATAR_PIC_SUCCESS: "PATCH_USER_AVATAR_PIC_SUCCESS",
  PATCH_USER_AVATAR_PIC_ERROR: "PATCH_USER_AVATAR_PIC_ERROR",
};

// signin
export const Factor2Auth = {
  FACTOR2_AUTH_REQUEST: "FACTOR2_AUTH_REQUEST",
  FACTOR2_AUTH_SUCCESS: "FACTOR2_AUTH_SUCCESS",
  FACTOR2_AUTH_ERROR: "FACTOR2_AUTH_ERROR",
};
export const getProfile = {
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",
};
export const resetPassword = {
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
};
export const changeEmail = {
  CHANGE_EMAIL_REQUEST: "CHANGE_EMAIL_REQUEST",
  CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_ERROR: "CHANGE_EMAIL_ERROR",
};

// login sessions
export const getLoginSessionProfile = {
  GET_LOGIN_SESSION_PROFILE_REQUEST: "GET_LOGIN_SESSION_PROFILE_REQUEST",
  GET_LOGIN_SESSION_PROFILE_SUCCESS: "GET_LOGIN_SESSION_PROFILE_SUCCESS",
  GET_LOGIN_SESSION_PROFILE_ERROR: "GET_LOGIN_SESSION_PROFILE_ERROR",
};

// list of companies
export const companyList = {
  COMPANY_LIST_REQUEST: "COMPANY_LIST_REQUEST",
  COMPANY_LIST_SUCCESS: "COMPANY_LIST_SUCCESS",
  COMPANY_LIST_ERROR: "COMPANY_LIST_ERROR",
};

// email sync
export const emailGoogle = {
  EMAIL_GOOGLE_REQUEST: "EMAIL_GOOGLE_REQUEST",
  EMAIL_GOOGLE_SUCCESS: "EMAIL_GOOGLE_SUCCESS",
  EMAIL_GOOGLE_ERROR: "EMAIL_GOOGLE_ERROR",
};
export const getEmailSyncDetails = {
  GET_EMAIL_SYNC_DETAILS_REQUEST: "GET_EMAIL_SYNC_DETAILS_REQUEST",
  GET_EMAIL_SYNC_DETAILS_SUCCESS: "GET_EMAIL_SYNC_DETAILS_SUCCESS",
  GET_EMAIL_SYNC_DETAILS_ERROR: "GET_EMAIL_SYNC_DETAILS_ERROR",
};
export const updateEmailSyncDetails = {
  UPDATE_EMAIL_SYNC_DETAILS_REQUEST: "UPDATE_EMAIL_SYNC_DETAILS_REQUEST",
  UPDATE_EMAIL_SYNC_DETAILS_SUCCESS: "UPDATE_EMAIL_SYNC_DETAILS_SUCCESS",
  UPDATE_EMAIL_SYNC_DETAILS_ERROR: "UPDATE_EMAIL_SYNC_DETAILS_ERROR",
};
export const addEmailInIgnoreList = {
  ADD_EMAIL_IN_IGNORE_LIST_REQUEST: "ADD_EMAIL_IN_IGNORE_LIST_REQUEST",
  ADD_EMAIL_IN_IGNORE_LIST_SUCCESS: "ADD_EMAIL_IN_IGNORE_LIST_SUCCESS",
  ADD_EMAIL_IN_IGNORE_LIST_ERROR: "ADD_EMAIL_IN_IGNORE_LIST_ERROR",
};
export const removeEmailFromIgnoreList = {
  REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST: "REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST",
  REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS: "REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS",
  REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR: "REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR",
};
export const hostedEmailAuth = {
  HOSTED_EMAIL_AUTH_REQUEST: " HOSTED_EMAIL_AUTH_REQUEST",
  HOSTED_EMAIL_AUTH_SUCCESS: "HOSTED_EMAIL_AUTH_SUCCESS",
  HOSTED_EMAIL_AUTH_ERROR: "HOSTED_EMAIL_AUTH_ERROR",
};

// email sync callback
export const hostedEmailAuthCallback = {
  HOSTED_EMAIL_AUTH_CALLBACK_REQUEST: "HOSTED_EMAIL_AUTH_CALLBACK_REQUEST",
  HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS: "HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS",
  HOSTED_EMAIL_AUTH_CALLBACK_ERROR: "HOSTED_EMAIL_AUTH_CALLBACK_ERROR",
};
// calendar sync
export const getActivity = {
  GET_ACTIVITY_REQUEST: "GET_ACTIVITY_REQUEST",
  GET_ACTIVITY_SUCCESS: "GET_ACTIVITY_SUCCESS",
  GET_ACTIVITY_ERROR: "GET_ACTIVITY_ERROR",
};
export const getCalendarSyncDetails = {
  GET_CALENDAR_SYNC_DETAILS_REQUEST: "GET_CALENDAR_SYNC_DETAILS_REQUEST",
  GET_CALENDAR_SYNC_DETAILS_SUCCESS: "GET_CALENDAR_SYNC_DETAILS_SUCCESS",
  GET_CALENDAR_SYNC_DETAILS_ERROR: "GET_CALENDAR_SYNC_DETAILS_ERROR",
};
export const getListOfCalenders = {
  GET_LIST_OF_CALENDARS_REQUEST: "GET_LIST_OF_CALENDARS_REQUEST",
  GET_LIST_OF_CALENDARS_SUCCESS: "GET_LIST_OF_CALENDARS_SUCCESS",
  GET_LIST_OF_CALENDARS_ERROR: "GET_LIST_OF_CALENDARS_ERROR",
};
export const deleteCalendarSyncAccount = {
  DELETE_CALENDAR_SYNC_ACCOUNT_REQUEST: "DELETE_CALENDAR_SYNC_ACCOUNT_REQUEST",
  DELETE_CALENDAR_SYNC_ACCOUNT_SUCCESS: "DELETE_CALENDAR_SYNC_ACCOUNT_SUCCESS",
  DELETE_CALENDAR_SYNC_ACCOUNT_ERROR: "DELETE_CALENDAR_SYNC_ACCOUNT_ERROR",
};
export const disconnectCalendarSyncAccount = {
  DISCONNECT_CALENDAR_SYNC_ACCOUNT_REQUEST: "DISCONNECT_CALENDAR_SYNC_ACCOUNT_REQUEST",
  DISCONNECT_CALENDAR_SYNC_ACCOUNT_SUCCESS: "DISCONNECT_CALENDAR_SYNC_ACCOUNT_SUCCESS",
  DISCONNECT_CALENDAR_SYNC_ACCOUNT_ERROR: "DISCONNECT_CALENDAR_SYNC_ACCOUNT_ERROR",
};
export const reconnectCalendarSyncAccount = {
  RECONNECT_CALENDAR_SYNC_ACCOUNT_REQUEST: "RECONNECT_CALENDAR_SYNC_ACCOUNT_REQUEST",
  RECONNECT_CALENDAR_SYNC_ACCOUNT_SUCCESS: "RECONNECT_CALENDAR_SYNC_ACCOUNT_SUCCESS",
  RECONNECT_CALENDAR_SYNC_ACCOUNT_ERROR: "RECONNECT_CALENDAR_SYNC_ACCOUNT_ERROR",
};
export const updateCalendarSyncDetails = {
  UPDATE_CALENDAR_SYNC_ACCOUNT_REQUEST: "UPDATE_CALENDAR_SYNC_ACCOUNT_REQUEST",
  UPDATE_CALENDAR_SYNC_ACCOUNT_SUCCESS: "UPDATE_CALENDAR_SYNC_ACCOUNT_SUCCESS",
  UPDATE_CALENDAR_SYNC_ACCOUNT_ERROR: "UPDATE_CALENDAR_SYNC_ACCOUNT_ERROR",
};
export const hostedCalendarAuth = {
  HOSTED_CALENDAR_AUTH_REQUEST: "HOSTED_CALENDAR_AUTH_REQUEST",
  HOSTED_CALENDAR_AUTH_SUCCESS: "HOSTED_CALENDAR_AUTH_SUCCESS",
  HOSTED_CALENDAR_AUTH_ERROR: "HOSTED_CALENDAR_AUTH_ERROR",
};

//calendar sync callback
export const hostedCalendarAuthCallback = {
  HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST: "HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST",
  HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS: "HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS",
  HOSTED_CALENDAR_AUTH_CALLBACK_ERROR: "HOSTED_CALENDAR_AUTH_CALLBACK_ERROR",
};
// notification
export const getNotification = {
  GET_NOTIFICATION_REQUEST: "GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_ERROR: "GET_NOTIFICATION_ERROR",
};
export const postNotification = {
  POST_NOTIFICATION_REQUEST: "POST_NOTIFICATION_REQUEST",
  POST_NOTIFICATION_SUCCESS: "POST_NOTIFICATION_SUCCESS",
  POST_NOTIFICATION_ERROR: "POST_NOTIFICATION_ERROR",
};
