import { getListOfEmailValidation, createEmailValidation, getEmailValidationPricing } from "../types/email-validation.types";

export const getListOfEmailValidationRequest = (payload, cb) => ({
    type: getListOfEmailValidation.REQUEST,
    payload,
    cb
})
export const getListOfEmailValidationSuccess = (payload) => ({
    type: getListOfEmailValidation.SUCCESS,
    payload
})
export const getListOfEmailValidationFailure = (payload) => ({
    type: getListOfEmailValidation.FAILURE,
    payload
})

export const createEmailValidationRequest = (payload, cb) => ({
    type: createEmailValidation.REQUEST,
    payload,
    cb
})
export const createEmailValidationSuccess = (payload) => ({
    type: createEmailValidation.SUCCESS,
    payload
})
export const createEmailValidationFailure = (payload) => ({
    type: createEmailValidation.FAILURE,
    payload
})


export const getEmailValidationPricingRequest = (payload, cb) => ({
    type: getEmailValidationPricing.REQUEST,
    payload,
    cb
})
export const getEmailValidationPricingSuccess = (payload) => ({
    type: getEmailValidationPricing.SUCCESS,
    payload
})
export const getEmailValidationPricingFailure = (payload) => ({
    type: getEmailValidationPricing.FAILURE,
    payload
})


