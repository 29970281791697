import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./styles.scss";

function RichTextEditor({ defaultValue, value, onChange, toolbarLocation = "top" }) {
  const onChangeEditor = (content) => {
    onChange(content);
  };
  return (
    <Editor
      apiKey="wxyau9teu86o03l12cpypowcif1uwmtgvsm0ae8mbee3cl3s"
      theme={"basic"}
      theme_advanced_path={false}
      disabled={false}
      onInit={(evt, _editor) => {}}
      initialValue={defaultValue}
      value={value}
      init={{
        height: "100%",
        menubar: false,
        visual: false,
        toolbar_location: toolbarLocation,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "print",
          "preview",
          "anchor",
          "indent",
          "outdent",
          "numlist",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "paste",
          "code",
          "help",
          "wordcount",
          "UseTemplates",
        ],
        toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |table | code | UseTemplates",
        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
      onEditorChange={onChangeEditor}
    />
  );
}

export default RichTextEditor;
