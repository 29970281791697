import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { StateItem2 } from "../../containers/deals/common/dealModal/styled";

export function PipelineStageDisplay(props) {
  const [pipelineList, setPipelineList] = useState({});
  const [pipelineStageMapping, setPipelineStageMapping] = useState({});
  const [selectedStages, setSelectedStages] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  const pipelineStageStore = useSelector((store) => store.DealPipeline.pipelineOptionsResponse);

  useEffect(() => {
    if (pipelineStageStore) {
      const pipelineListObj = {},
        mappingObj = {};
      pipelineStageStore.forEach((each) => {
        pipelineListObj[each._id] = each.stages;
        each.stages.forEach((ech) => {
          mappingObj[ech._id] = each._id;
        });
      });
      setPipelineList(pipelineListObj);
      setPipelineStageMapping(mappingObj);
    }
  }, [pipelineStageStore]);

  useEffect(() => {
    if (!_.isEmpty(props.data)) {
      if (typeof props.data === "object") {
        const pipelineId = pipelineStageMapping[props.data._id];
        if (pipelineId) {
          const pipelineStages = pipelineList[pipelineId];
          if (pipelineStages) {
            setSelectedStages(pipelineStages);
            const selected = pipelineStages.find((each) => each._id === props.data);
            if (selected) setSelectedValue(selected);
            else {
              setSelectedValue(props.data);
            }
          }
        }
      } else if (typeof props.data === "string") {
        const pipelineId = pipelineStageMapping[props.data];
        if (pipelineId) {
          const pipelineStages = pipelineList[pipelineId];
          if (pipelineStages) {
            setSelectedStages(pipelineStages);
            const selected = pipelineStages.find((each) => each._id === props.data);
            if (selected) setSelectedValue(selected);
          }
        }
      }
    }
  }, [props.data, pipelineList, pipelineStageMapping]);

  return (
    <div className="data-container">
      <div className="d-flex width-fit-content">
        {selectedStages.map((eachStage, index) => {
          return (
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{eachStage.name}</Tooltip>} trigger={["hover", "focus"]}>
              <StateItem2 key={index} past={index < selectedValue.order} active={index + 1 === selectedValue.order} />
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
}
