import { getSelectedVideo } from "../types/video.types";

const initState = {
  loading: false,
  data: {},
  count: 0,
  related: [],
};

export default function selectedReducer(state = initState, action = {}) {
  switch (action.type) {
    case getSelectedVideo.GET_SELECTED_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
      };
    case getSelectedVideo.GET_SELECTED_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        related: action.payload.related,
      };
    case getSelectedVideo.GET_SELECTED_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
