import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as signInActions from "../../actions/profile-actions/signin-settings.actions";
import * as signInTypes from "../../types/profile-settings.types";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { endpoints } from "../../endpoint";

function* twoFactAuthFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.FACTOR_AUTH, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(signInActions.Factor2AuthSucess(res.data));
    } else {
      yield put(signInActions.Factor2AuthError("error"));
    }
  } catch (e) {
    yield put(signInActions.Factor2AuthError(e));
  }
}

function* getProfileDetailsFunction(action) {
  try {
    const res = yield call(api.get, endpoints.GET_USER_PROFILE);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(signInActions.getProfileSuccess(res.data));
    } else {
      yield put(signInActions.getProfileError("error"));
    }
  } catch (e) {
    yield put(signInActions.getProfileError(e));
  }
}

function* resetPasswordFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.RESET_PASSWORD, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(signInActions.passwordResetSuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
      yield put(signInActions.passwordResetError("error"));
    } else {
      yield put(signInActions.passwordResetError("error"));
    }
  } catch (e) {
    yield put(signInActions.passwordResetError(e));
  }
}

function* changeEmailFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.RESET_EMAIL, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(signInActions.changeEmailSuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
      yield put(signInActions.changeEmailError("error"));
    } else {
      yield put(signInActions.changeEmailError("error"));
    }
  } catch (e) {
    yield put(signInActions.changeEmailError(e));
  }
}

export default function* signInSaga() {
  yield takeLatest(signInTypes.Factor2Auth.FACTOR2_AUTH_REQUEST, twoFactAuthFunction);
  yield takeLatest(signInTypes.getProfile.GET_PROFILE_REQUEST, getProfileDetailsFunction);
  yield takeLatest(signInTypes.resetPassword.RESET_PASSWORD_REQUEST, resetPasswordFunction);
  yield takeLatest(signInTypes.changeEmail.CHANGE_EMAIL_REQUEST, changeEmailFunction);
}
