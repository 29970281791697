export const cadenceStatPercentage = (value1, value2) => {
  const multipliedValues = value1 === 0 && value2 === 0 ? 0 : value1 / value2;
  if (isNaN(multipliedValues) || !isFinite(multipliedValues)) {
    return 0;
  }
  return (multipliedValues * 100).toFixed(2);
};

const secondsToDhm = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return { d: d, h: h, m: m };
};

export const getDaysHoursMinutesOfStep = (steps, stepIndex) => {
  let seconds = 0;
  for (let i = 0; i < steps.length; i++) {
    if (i <= stepIndex) {
      seconds = seconds + steps[i].delay;
    }
  }
  return secondsToDhm(seconds);
};
