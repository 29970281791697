import React, { useState, useEffect } from "react";
import * as actions from "../../actions/settings.actions";
import { useDispatch } from "react-redux";
import {
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";

export default function CompanyList() {
  const dispatch = useDispatch();

  const [companyList, setcompanyList] = useState([]);

  useEffect(() => {
    dispatch(
      actions.companyListRequest((data) => {
        setcompanyList(data.data);
      }),
    );
  }, [dispatch]);

  const EnterCompany = (data) => {
    dispatchSnackbarSuccess("SuccessFully Enter In the Company", "success");
    localStorage.setItem("access_token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);

    window.location.href = "/activity/activity-list";
    window.location.reload();
  };

  return (
    <>
      <div className="card mb-5 mb-xl-10 h-vh-160px">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_connected_accounts" aria-expanded="true" aria-controls="kt_account_connected_accounts">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Connected Accounts</h3>
          </div>
        </div>

        <div id="kt_account_connected_accounts" className="collapse show">
          <div className="card-body border-top p-9 overflow-y-auto h-vh-300px-mx">
            <div className="py-2">
              {companyList.map((data, key) => {
                return (
                  <>
                    <div key={key}>
                      <div className="d-flex flex-stack">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <div>
                              <span>
                                <strong>User Name: </strong>
                              </span>
                              <span>{data.fullName}</span>
                            </div>
                            <div>
                              <span>
                                <strong>User Type: </strong>
                              </span>
                              <span>{data.userType}</span>
                            </div>
                            <div>
                              <span>
                                <strong>Company Name: </strong>
                              </span>
                              <span>
                                {data.companyName} {data.website ? ` | ${data.website}` : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          {data.isCurrentAccount === true && <button className="text-009ef7 w-140px bg-f5f8facc saveCommponbutton btn btn-sm btn-primary my-1 fw-600">Current Account</button>}
                          {data.isCurrentAccount === false && (
                            <button className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => EnterCompany(data)}>
                              Switch to Company
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="separator separator-dashed my-5" />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
