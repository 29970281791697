import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Switch, Route, Redirect } from "react-router-dom";
import WorkflowList from "./workflow-list";
import WorkflowBuilder from "./workflow-builder";
import { UpgradePlanPage } from "../../../components/empty_page";
import { useSelector } from "react-redux";
import SubscriptionPlanModal from "../../Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import ContentWrapper from "../../Wrapper";
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import { CadenceSVG, WorkflowSVG } from "../../../components/custom-svgs/sub-header-svg";
import NoAccess from "../no-access";
import { Spinner } from "react-bootstrap";

export default function Workflow(props) {
  const [showPlans, setShowPlans] = useState(false);
  const [workflowAccess, setWorkflowAccess] = useState("loading");
  const { match } = props;
  const tabName = "Workflow Automation";
  const links = [
    { name: "Sales Cadence", to: "/automation/cadence/list", svg: CadenceSVG },
    { name: "Workflow Automation", to: "/automation/workflow/list", svg: WorkflowSVG },
  ];

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    if (!_.isEmpty(userProfile.data)) {
      if (userProfile.data.userType === "OWNER") {
        setWorkflowAccess("access");
      } else if (userProfile.data.permissions && userProfile.data.permissions.workflow) {
        setWorkflowAccess("access");
      } else {
        setWorkflowAccess("no-access");
      }
    }
  }, [userProfile]);

  if (accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.WORKFLOW) {
    return (
      <ContentWrapper
        subHeader={
          <CustomSubHeader>
            {" "}
            <SubheaderNav links={links} active={tabName} />
          </CustomSubHeader>
        }
      >
        <UpgradePlanPage
          isEnterprise={accountBillingStore.data.selectedPlan==="enterprise"}
          userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          onUpgradeClick={() => setShowPlans(true)}
          type={"Workflow Automation"}
          planName={"free plan"}
        />
        {showPlans && <SubscriptionPlanModal page={""} showPlans={showPlans} setShowPlans={setShowPlans} />}
      </ContentWrapper>
    );
  }
  if (workflowAccess === "loading")
    return (
      <ContentWrapper
        subHeader={
          <CustomSubHeader>
            <div className="d-flex justify-content-between align-items-center w-100">
              <SubheaderNav links={links} active={tabName} />
            </div>
          </CustomSubHeader>
        }
      >
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      </ContentWrapper>
    );
  if (workflowAccess === "no-access") return <NoAccess />;
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/list`} component={WorkflowList} />
        <Route exact path={`${match.url}/create-workflow`} component={WorkflowBuilder} />
        <Route exact path={`${match.url}/edit/:workflowId`} component={WorkflowBuilder} />
        <Route exact path={`${match.url}/template/create/:templateId`} component={WorkflowBuilder} />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </>
  );
}
