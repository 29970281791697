import React from "react";
import _ from "lodash";
import SubscriptionUserList from "../components/step-2-components/subscription-user-list";
import SubscriptionAddOnList from "../components/step-2-components/add-on-list";
import CheckoutCard from "../components/step-2-components/checkout-card";
import { Spinner } from "react-bootstrap";

export default function Step2(props) {
    return (
        <div className="subscription-step2-page-warpper">
            {props.updateLoading ? (<>
                <div className="w-100 d-flex justify-content-center align-items-center h-vh-400px flex-column">
                    <Spinner animation="border" variant="primary" />
                    <div className="fs-3 mt-6">   Please wait, We are updating your subscription.</div>
                </div>

            </>) : (<>
                <div className="subscription-step2-part1-wrapper">
                    {!_.isEmpty(props.selectedMainPlan) && (
                        <SubscriptionUserList
                            currency={props.currency}
                            currencySymbol={props.currencySymbol}
                            planInterval={props.interval}
                            agents={props.users}
                            setAgents={props.setUsers}
                            selectedMainPlan={props.selectedMainPlan}
                        />
                    )}
                    <SubscriptionAddOnList
                        supportedAddons={props.supportedAddons}
                        addOnQuantity={props.addOnQuantity}
                        setAddOnQuantity={props.setAddOnQuantity}
                        currencySymbol={props.currencySymbol}
                        planMapping={props.planMapping}
                        interval={props.interval}
                    />
                </div>
                <div className="subscription-step2-part2-wrapper">
                    <CheckoutCard
                        users={props.users}
                        addOnQuantity={props.addOnQuantity}
                        currencySymbol={props.currencySymbol}
                        selectedMainPlan={props.selectedMainPlan}
                        supportedAddons={props.supportedAddons}
                        planMapping={props.planMapping}
                        total={props.total}
                        continueToPayDisabled={props.continueToPayDisabled}
                        handleSubscription={props.handleSubscription}
                        isSubscriptionActive={props.isSubscriptionActive}
                    />
                </div>
            </>)}

        </div>
    )
}