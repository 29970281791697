import React, { useState } from "react";
import {
  Dropdown,
  // Spinner, Button, Modal, Form
} from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import SVG from "react-inlinesvg";
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import { DeleteModal } from "../../components/modal/index";
import { dispatchSnackbarSuccess } from "../../utils/toaster";
// import { GraphSkeleton } from '../../components/skeleton/card-skeleton';

export default function EditableDropdown({ title, type, options, onEditClick, onAddNewClick, onSelectValue, showAddNew, showDelete, onDeleteClick }) {
  const [hovering, setHovering] = useState();
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const deleteTemplateModalProps = {
    show: showDeleteReport,
    title: "Delete Report",
    handleClose: () => onHandleClose(),
    handleAccept: () => onDeleteAccept(),
  };

  const onHandleClose = () => {
    setShowDeleteReport(false);
    setSelectedData({});
  };

  const onDeleteAccept = () => {
    setShowDeleteReport(false);
    onDeleteClick(selectedData.value);
    dispatchSnackbarSuccess("Report successfully deleted!");
  };
  const onDeleteButtonClick = (data) => {
    setShowDeleteReport(true);
    setSelectedData(data);
  };

  return (
    <div className="menu-item me-lg-1">
      <Dropdown className="pt-3">
        <Dropdown.Toggle className="menu-link" as="p">
          <span className="menu-title">{title}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((data, index) => {
            return (
              <Dropdown.Item
                onMouseOver={() => setHovering(index)}
                onMouseLeave={() => setHovering()}
                className="dropdown-hover"
                key={index}
                onSelect={() => {
                  onSelectValue(data.value);
                }}
              >
                <div className="w-100 d-flex flex-stack">
                  {data.label}
                  {hovering === index ? (
                    <div>
                      <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditClick(data);
                          }}
                          className="btn btn-icon btn-light mr-2 h-25px w-25px"
                        >
                          <i className="fa fa-sm fa-pen font-size-13px"></i>
                        </button>
                      </OverlayTrigger>
                      {showDelete && (
                        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Delete</Tooltip>}>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteButtonClick(data);
                            }}
                            className="btn btn-icon btn-light h-25px w-25px"
                          >
                            <i className="bi bi-trash font-size-13px"></i>
                          </button>
                        </OverlayTrigger>
                      )}
                      {/* <div >
                                            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>} >
                                                <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => onEditClick(data)} >
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                                                    </span>
                                                </span>
                                            </OverlayTrigger>

                                        </div>
                                        <div className="dropdown-inline dropdown">
                                            <DeleteModal modalProps={deleteTemplateModalProps}>
                                                Are you sure you want to delete the report?
                                            </DeleteModal>
                                            <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>} >
                                                <span className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={() => setShowDeleteReport(true)}>
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                    </span>
                                                </span>
                                            </OverlayTrigger>
                                        </div> */}
                      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete the dashboard?</DeleteModal>
                    </div>
                  ) : (
                    <span className="h-25px w-25px"></span>
                  )}
                </div>
              </Dropdown.Item>
            );
          })}
          {showAddNew && (
            <Dropdown.Item className="dropdown-hover" onClick={() => onAddNewClick()}>
              + Add New {type}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
