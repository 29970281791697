import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function UserSelect({ setValue, value, hideUserId }) {
  // const ownerStore = useSelector(state => state.People.ownerList)
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const userRole = useSelector((store) => store.insight.userRole);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (userRole.list.users && value) {
      setSelected(userRole.list.users.find((owner) => owner["id"] === value));
    }
  }, [userRole.list.users, value, userProfile, setValue]);

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!selected ? "Select User" : selected.name ? selected.name : selected.email}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {userRole.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {userRole.list.users &&
              userRole.list.users.map((eachUser, index) => {
                return (
                  <Dropdown.Item
                    disabled={eachUser.id === hideUserId}
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      setSelected(eachUser);
                      setValue(eachUser["id"]);
                    }}
                  >
                    {eachUser.name ? `${eachUser.name} (${eachUser.email})` : eachUser.email}
                  </Dropdown.Item>
                );
              })}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
