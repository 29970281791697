import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_QUESTION_FIELDS_REQUEST,
  GET_TAGS_REQUEST,
  GET_OWNER_REQUEST,
  EDIT_LEADBOT_DATA_REQUEST,
  GET_LEADBOTS_LIST_REQUEST,
  GET_LEADBOT_DATA_REQUEST,
  CREATE_LEADBOT_REQUEST,
  DELETE_LEADBOT_REQUEST,
  UPLOAD_LEADBOT_IMAGE_REQUEST,
} from "./types";

import api from "../../network/apis/api";

import * as LeadBotActions from "./actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";

function* getFields(action) {
  try {
    const response = yield call(api.get, `/api/crm/data-fields/get?fieldType=LEAD`, action.payload);
    if (response.status === 200) {
      yield put(LeadBotActions.getQuestionSuccess(response.data.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.getQuestionError(response));
    } else {
      yield put(LeadBotActions.getQuestionError("error"));
    }
  } catch (e) {
    yield put(LeadBotActions.getQuestionError(e));
  }
}

function* getTags(action) {
  try {
    const response = yield call(api.get, `/api/crm/tag/get?type=lead`, action.payload);
    if (response.status === 200) {
      yield put(LeadBotActions.getTagsSuccess(response.data.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.getTagsError(response));
    } else {
      yield put(LeadBotActions.getTagsError("error"));
    }
  } catch (e) {
    yield put(LeadBotActions.getTagsError(e));
  }
}

function* getOwner(action) {
  try {
    const response = yield call(api.get, `/api/crm/profile/roles-wise-user-list`, action.payload);
    if (response.status === 200) {
      yield put(LeadBotActions.getOwnerSuccess(response.data.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.getOwnerError(response));
    } else {
      yield put(LeadBotActions.getOwnerError("error"));
    }
  } catch (e) {
    yield put(LeadBotActions.getOwnerError(e));
  }
}

function* getLeadbotData(action) {
  try {
    const response = yield call(api.get, `/leadbot-api/leadbot/fetch?_id=${action.payload}`);
    if (action.cb) action.cb(response.data);
    if (response.status === 200) {
      yield put(LeadBotActions.getLeadBotDataSuccess(response.data.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.getLeadBotDataError(response.data));
    } else {
      yield put(LeadBotActions.getLeadBotDataError("error"));
    }
  } catch (e) {
    yield put(LeadBotActions.getLeadBotDataError(e));
  }
}
function* editLeadBotActionsData(action) {
  try {
    const response = yield call(api.patch, `/leadbot-api/leadbot/update`, action.payload);
    if (response.status === 200) {
      yield put(LeadBotActions.editLeadBotDataSuccess(response.data));
      dispatchSnackbarSuccess(response.data.message);
      if (action.cb) action.cb(response.data);
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.editLeadBotDataError(response));
      if (action.cb) action.cb(response.data);
    } else {
      yield put(LeadBotActions.editLeadBotDataError("error"));
      if (action.cb) action.cb(response.data);
    }
  } catch (e) {
    yield put(LeadBotActions.editLeadBotDataError(e));
  }
}

function* createLeadBot(action) {
  try {
    const response = yield call(api.post, `/leadbot-api/leadbot/create`, action.payload);
    if (response.status === 200) {
      yield put(LeadBotActions.createLeadBotSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.createLeadBotError(response));
    } else {
      yield put(LeadBotActions.createLeadBotError("error"));
    }
    if (action.cb) action.cb(response.data);
  } catch (e) {
    yield put(LeadBotActions.createLeadBotError(e));
  }
}

function* getLeadbotsList(action) {
  try {
    const response = yield call(api.get, `/leadbot-api/leadbot/list`, action.payload);
    if (response.status === 200) {
      yield put(LeadBotActions.getLeadbotsListSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.getLeadbotsListError(response));
    } else {
      yield put(LeadBotActions.getLeadbotsListError("error"));
    }
  } catch (e) {
    yield put(LeadBotActions.getLeadbotsListError(e));
  }
}

function* deleteLeadBot(action) {
  try {
    const response = yield call(api.delete, `/leadbot-api/leadbot/remove?_id=${action.payload.id}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Successfully Deleted");
      yield put(LeadBotActions.getLeadbotsListRequest({ page: action.payload.page, limit: action.payload.limit }));
      yield put(LeadBotActions.deleteLeadBotSuccess(response.data.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(LeadBotActions.deleteLeadBotError(response));
    } else {
      yield put(LeadBotActions.deleteLeadBotError("error"));
    }
  } catch (e) {
    yield put(LeadBotActions.deleteLeadBotError(e));
  }
}

function* uploadLeadbotImage(action) {
  try {
    const response = yield call(api.post, "/leadbot-api/leadbot/upload-file", action.payload);
    yield action.cb(response.data);
  } catch (e) {
    yield action.cb({ sttaus: false, message: e.message });
  }
}
export default function* LeadBotActionsSaga() {
  yield takeLatest(GET_QUESTION_FIELDS_REQUEST, getFields);
  yield takeLatest(GET_TAGS_REQUEST, getTags);
  yield takeLatest(GET_OWNER_REQUEST, getOwner);
  yield takeLatest(EDIT_LEADBOT_DATA_REQUEST, editLeadBotActionsData);
  yield takeLatest(GET_LEADBOTS_LIST_REQUEST, getLeadbotsList);
  yield takeLatest(GET_LEADBOT_DATA_REQUEST, getLeadbotData);
  yield takeLatest(CREATE_LEADBOT_REQUEST, createLeadBot);
  yield takeLatest(DELETE_LEADBOT_REQUEST, deleteLeadBot);
  yield takeLatest(UPLOAD_LEADBOT_IMAGE_REQUEST, uploadLeadbotImage);
}
