import { takeLatest, put, call } from "redux-saga/effects";
import * as types from "./types";
import api from "../../../network/apis/api";
import { endpoints } from "./endpoints";
import * as workflowActions from "./actions/workflow_actions";
import * as workflowtemplateActions from "./actions/workflow_template_action";
import * as ActivityActions from "./actions/activity_action";
import * as ConditionActions from "./actions/conditions_actions";
import * as ConvertToJunkActions from "./actions/convert_to_junk_action";
import * as DealActions from "./actions/deal_action";
import * as DealLostReasonAction from "./actions/deal_loss_reason_action";
import * as EmailNotificationAction from "./actions/email_notification_actions";
import * as UpdateFieldAction from "./actions/update_field_action";
import * as WhatsAppTemplateAction from "./actions/whatsapp_template_action";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";

function* getListOfWorkflow(action) {
  try {
    const res = yield call(api.get, endpoints.WORKFLOW_LIST, action.payload);
    if (res.status === 200) {
      yield put(workflowActions.getListOfWorkflowsSuccess(res.data));
    } else {
      yield put(workflowActions.getListOfWorkflowsSuccess(res.data));
    }
  } catch (e) {
    yield put(workflowActions.getListOfWorkflowsSuccess(e));
  }
}
function* getWorkflowDetailsById(action) {
  try {
    const res = yield call(api.get, `${endpoints.WORKFLOW_BY_ID}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(workflowActions.getListOfWorkflowsByIdSuccess(res.data));
    } else {
      yield put(workflowActions.getListOfWorkflowsByIdError(res.data));
    }
  } catch (e) {
    yield put(workflowActions.getListOfWorkflowsByIdError(e));
  }
}
function* createWorkflow(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_WORKFLOW, action.payload);
    if (res.status === 200) {
      yield put(workflowActions.createWorkflowSuccess(res.data));
    } else {
      yield put(workflowActions.createWorkflowError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(workflowActions.createWorkflowError(e));
  }
}
function* editWorkflow(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_WORKFLOW}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      if (!action.payload.isSoftUpdate) dispatchSnackbarSuccess(res.data.message);
      yield put(workflowActions.editWorkflowSuccess(res.data));
      if (action.payload.loadingData) yield put(workflowActions.getListOfWorkflowsRequest(action.payload.loadingData));
    } else {
      if (!action.payload.isSoftUpdate) dispatchSnackbarError(res.data.message);
      yield put(workflowActions.editWorkflowError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(workflowActions.editWorkflowError(e));
  }
}
function* deleteWorkflow(action) {
  try {
    const res = yield call(api.delete, `${endpoints.DELETE_WORKFLOW}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully Deleted");
      yield put(workflowActions.deleteWorkflowSuccess(res.data));
      yield put(workflowActions.getListOfWorkflowsRequest({ limit: 10, pageNo: 1 }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(workflowActions.deleteWorkflowError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(workflowActions.deleteWorkflowError(e));
  }
}
function* updateWorkflowStatus(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_WORKFLOW}/${action.payload.id}/status`, action.payload.data);
    if (res.status === 200) {
      if (!action.payload.isSoftUpdate) dispatchSnackbarSuccess(res.data.message);
      yield put(workflowActions.updateWorkflowStatusSuccess(res.data));
      if (action.payload.loadingData) yield put(workflowActions.getListOfWorkflowsRequest(action.payload.loadingData));
    } else {
      if (!action.payload.isSoftUpdate) dispatchSnackbarError(res.data.message);
      yield put(workflowActions.updateWorkflowStatusError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(workflowActions.updateWorkflowStatusError(e));
  }
}

//share
function* shareWorkflow(action) {
  try {
    const res = yield call(api.post, endpoints.SHARE_WORKFLOW, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(workflowActions.shareWorkflowSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(workflowActions.shareWorkflowError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(workflowActions.shareWorkflowError(e));
  }
}
function* getShareWorflowUserList(action) {
  try {
    const res = yield call(api.get, `${endpoints.SHARE_WORKFLOW_USER_LIST}/${action.payload.id}`, {});
    if (res.status === 200) {
      yield put(workflowActions.getShareWorkflowUserListSuccess(res.data));
    } else {
      yield put(workflowActions.getShareWorkflowUserListError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(workflowActions.getShareWorkflowUserListError(e));
  }
}

// workflow templates
function* getListOfWorkflowTemplates(action) {
  try {
    const res = yield call(api.get, endpoints.WORKFLOW_TEMPLATE_LIST, action.payload);
    if (res.status === 200) {
      yield put(workflowtemplateActions.getListOfWorkflowTemplatesSuccess(res.data));
    } else {
      yield put(workflowtemplateActions.getListOfWorkflowTemplatesError(res.data));
    }
  } catch (e) {
    yield put(workflowtemplateActions.getListOfWorkflowTemplatesError(e));
  }
}
function* getWorkflowTemplateById(action) {
  try {
    const res = yield call(api.get, `${endpoints.WORKFLOW_TEMPLATE_BY_ID}/${action.payload.id}`, {});
    if (res.status === 200) {
      yield put(workflowtemplateActions.getListOfWorkflowTemplateByIdSuccess(res.data));
    } else {
      yield put(workflowtemplateActions.getListOfWorkflowTemplatesError(res.data));
    }
  } catch (e) {
    yield put(workflowtemplateActions.getListOfWorkflowTemplatesError(e));
  }
}

//settings
function* getConditions(action) {
  try {
    const res = yield call(api.get, endpoints.CONDITION, action.payload);
    if (res.status === 200) {
      yield put(ConditionActions.getConditionSuccess(res.data));
    } else {
      yield put(ConditionActions.getConditionError(res.data));
    }
  } catch (e) {
    yield put(ConditionActions.getConditionError(e));
  }
}
function* getActionList(action) {
  try {
    const res = yield call(api.get, endpoints.ACTIONS, action.payload);
    if (res.status === 200) {
      yield put(ConditionActions.getActionsSuccess(res.data));
    } else {
      yield put(ConditionActions.getActionsError(res.data));
    }
  } catch (e) {
    yield put(ConditionActions.getActionsError(e));
  }
}

//email notfication
function* getEmailNotificationById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_EMAIL_NOTIFICATION}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(EmailNotificationAction.getEmailNotificationsByIdSuccess(res.data));
    } else {
      yield put(EmailNotificationAction.getEmailNotificationsByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(EmailNotificationAction.getEmailNotificationsByIdError(e));
  }
}
function* createEmailNotifications(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_EMAIL_NOTIFICATION, action.payload);
    if (res.status === 200) {
      yield put(EmailNotificationAction.createEmailNotificationsSuccess(res.data));
    } else {
      yield put(EmailNotificationAction.createEmailNotificationsError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(EmailNotificationAction.createEmailNotificationsError(e));
  }
}
function* editEmailNotifications(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_EMAIL_NOTIFICATION}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(EmailNotificationAction.editEmailNotificationsSuccess(res.data));
    } else {
      yield put(EmailNotificationAction.editEmailNotificationsError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(EmailNotificationAction.editEmailNotificationsError(e));
  }
}

//udpate fields
function* getUpdateFieldById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_FIELD_UPDATE}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(UpdateFieldAction.getFieldUpdateByIdSuccess(res.data));
    } else {
      yield put(UpdateFieldAction.getFieldUpdateByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(UpdateFieldAction.getFieldUpdateByIdError(e));
  }
}
function* createUpdateFields(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_FIELD_UPDATE, action.payload);
    if (res.status === 200) {
      yield put(UpdateFieldAction.createFieldUpdateSuccess(res.data));
    } else {
      yield put(UpdateFieldAction.createFieldUpdateError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(UpdateFieldAction.createFieldUpdateError(e));
  }
}
function* editUpdateFields(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_FIELD_UPDATE}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(UpdateFieldAction.editFieldUpdateSuccess(res.data));
    } else {
      yield put(UpdateFieldAction.editFieldUpdateError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(UpdateFieldAction.editFieldUpdateError(e));
  }
}

//create deal
function* getCreateDealById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_DEAL_ACTION}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(DealActions.getDealActionByIdSuccess(res.data));
    } else {
      yield put(DealActions.getDealActionByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(DealActions.getDealActionByIdError(e));
  }
}
function* createDealAction(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_DEAL_ACTION, action.payload);
    if (res.status === 200) {
      yield put(DealActions.createDealActionSuccess(res.data));
    } else {
      yield put(DealActions.createDealActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(DealActions.createDealActionError(e));
  }
}
function* editDealAction(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_DEAL_ACTION}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(DealActions.editDealActionSuccess(res.data));
    } else {
      yield put(DealActions.editDealActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(DealActions.editDealActionError(e));
  }
}

//lead to junk
function* getConvertToJunkById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_LEAD_TO_JUNK}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(ConvertToJunkActions.getConvertToJunkByIdSuccess(res.data));
    } else {
      yield put(ConvertToJunkActions.getConvertToJunkByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ConvertToJunkActions.getConvertToJunkByIdError(e));
  }
}
function* createConvertToJunk(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_LEAD_TO_JUNK, action.payload);
    if (res.status === 200) {
      yield put(ConvertToJunkActions.createConvertToJunkSuccess(res.data));
    } else {
      yield put(ConvertToJunkActions.createConvertToJunkError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ConvertToJunkActions.createConvertToJunkError(e));
  }
}
function* editConvertToJunk(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_LEAD_TO_JUNK}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(ConvertToJunkActions.editConvertToJunkSuccess(res.data));
    } else {
      yield put(ConvertToJunkActions.editConvertToJunkError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ConvertToJunkActions.editConvertToJunkError(e));
  }
}

//create Activity
function* getCreateActivityById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_ACTIVITY}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(ActivityActions.getActivityActionByIdSuccess(res.data));
    } else {
      yield put(ActivityActions.getActivityActionByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ActivityActions.getActivityActionByIdError(e));
  }
}
function* createActivityActions(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_ACTIVITY, action.payload);
    if (res.status === 200) {
      yield put(ActivityActions.createActivityActionSuccess(res.data));
    } else {
      yield put(ActivityActions.createActivityActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ActivityActions.createActivityActionError(e));
  }
}
function* editActivityActions(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_ACTIVITY}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(ActivityActions.editActivityActionSuccess(res.data));
    } else {
      yield put(ActivityActions.editActivityActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ActivityActions.editActivityActionError(e));
  }
}

//deal lost reason
function* getDealLostReasonById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_DEAL_LOST_REASON}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(DealLostReasonAction.getDealLossReasonActionByIdSuccess(res.data));
    } else {
      yield put(DealLostReasonAction.getDealLossReasonActionByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(DealLostReasonAction.getDealLossReasonActionByIdError(e));
  }
}
function* createDealLostReasons(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_DEAL_LOST_REASON, action.payload);
    if (res.status === 200) {
      yield put(DealLostReasonAction.createDealLossReasonActionSuccess(res.data));
    } else {
      yield put(DealLostReasonAction.createDealLossReasonActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(DealLostReasonAction.createDealLossReasonActionError(e));
  }
}
function* editDealLostReasons(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_DEAL_LOST_REASON}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(DealLostReasonAction.editDealLossReasonActionSuccess(res.data));
    } else {
      yield put(DealLostReasonAction.editDealLossReasonActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(DealLostReasonAction.editDealLossReasonActionError(e));
  }
}

//email notfication
function* getWhatsappTemplateById(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_WHATSAPP_TEMPLATE}/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      yield put(WhatsAppTemplateAction.getWhatsappTemplateActionByIdSuccess(res.data));
    } else {
      yield put(WhatsAppTemplateAction.getWhatsappTemplateActionByIdError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(WhatsAppTemplateAction.getWhatsappTemplateActionByIdError(e));
  }
}
function* createWhatsappTemplates(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_WHATSAPP_TEMPLATE, action.payload);
    if (res.status === 200) {
      yield put(WhatsAppTemplateAction.createWhatsappTemplateActionSuccess(res.data));
    } else {
      yield put(WhatsAppTemplateAction.createWhatsappTemplateActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(WhatsAppTemplateAction.createWhatsappTemplateActionError(e));
  }
}
function* editWhatsappTemplates(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_WHATSAPP_TEMPLATE}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(WhatsAppTemplateAction.editWhatsappTemplateActionSuccess(res.data));
    } else {
      yield put(WhatsAppTemplateAction.editWhatsappTemplateActionError(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(WhatsAppTemplateAction.editWhatsappTemplateActionError(e));
  }
}

export default function* workflowSaga() {
  yield takeLatest(types.getListOfWorkflows.GET_LIST_OF_WORKFLOWS_REQUEST, getListOfWorkflow);
  yield takeLatest(types.getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_REQUEST, getWorkflowDetailsById);
  yield takeLatest(types.createWorkflow.CREATE_WORKFLOW_REQUEST, createWorkflow);
  yield takeLatest(types.editWorkflow.EDIT_WORKFLOW_REQUEST, editWorkflow);
  yield takeLatest(types.deleteWorkflow.DELETE_WORKFLOW_REQUEST, deleteWorkflow);
  yield takeLatest(types.updateWorkflowStatus.UPDATE_WORKFLOW_STATUS_REQUEST, updateWorkflowStatus);
  yield takeLatest(types.shareWorkflow.SHARE_WORKFLOW_REQUEST, shareWorkflow);
  yield takeLatest(types.getSharedWorkflowUserList.GET_SHARED_WORKFLOW_USER_LIST_REQUEST, getShareWorflowUserList);

  yield takeLatest(types.getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_REQUEST, getListOfWorkflowTemplates);
  yield takeLatest(types.getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_REQUEST, getWorkflowTemplateById);

  yield takeLatest(types.getConditions.GET_LIST_OF_CONDITION_REQUEST, getConditions);
  yield takeLatest(types.getActionList.GET_LIST_OF_ACTIONS_REQUEST, getActionList);

  yield takeLatest(types.getEmailNotificationsByID.GET_EMAIL_NOTIFICATION_BY_ID_REQUEST, getEmailNotificationById);
  yield takeLatest(types.createEmailNotifications.CREATE_EMAIL_NOTIFICATION_REQUEST, createEmailNotifications);
  yield takeLatest(types.editEmailNotifications.EDIT_EMAIL_NOTIFICATION_REQUEST, editEmailNotifications);

  yield takeLatest(types.getFieldUpdateByID.GET_FIELD_UPDATE_BY_ID_ACTION_REQUEST, getUpdateFieldById);
  yield takeLatest(types.createFieldUpdate.CREATE_FIELD_UPDATE_ACTION_REQUEST, createUpdateFields);
  yield takeLatest(types.editFieldUpdate.EDIT_FIELD_UPDATE_ACTION_REQUEST, editUpdateFields);

  yield takeLatest(types.getDealActionById.GET_DEAL_ACTION_BY_ID_REQUEST, getCreateDealById);
  yield takeLatest(types.createDealAction.CREATE_DEAL_ACTION_REQUEST, createDealAction);
  yield takeLatest(types.editDealAction.EDIT_DEAL_ACTION_REQUEST, editDealAction);

  yield takeLatest(types.getConvertToJunkById.GET_CONVERT_TO_JUNK_ACTION_BY_ID_REQUEST, getConvertToJunkById);
  yield takeLatest(types.addConvertToJunk.ADD_CONVERT_TO_JUNK_ACTION_REQUEST, createConvertToJunk);
  yield takeLatest(types.editConvertToJunk.EDIT_CONVERT_TO_JUNK_ACTION_REQUEST, editConvertToJunk);

  yield takeLatest(types.getActivityActionById.GET_ACTIVITY_ACTION_BY_ID_REQUEST, getCreateActivityById);
  yield takeLatest(types.createActivityAction.CREATE_ACTIVITY_ACTION_REQUEST, createActivityActions);
  yield takeLatest(types.editActivityAction.EDIT_ACTIVITY_ACTION_REQUEST, editActivityActions);

  yield takeLatest(types.getDealLossReasonActionById.GET_DEAL_LOSS_REASON_BY_ID_ACTION_REQUEST, getDealLostReasonById);
  yield takeLatest(types.createDealLossReasonAction.CREATE_DEAL_LOSS_REASON_ACTION_REQUEST, createDealLostReasons);
  yield takeLatest(types.editDealLossReasonAction.EDIT_DEAL_LOSS_REASON_ACTION_REQUEST, editDealLostReasons);

  yield takeLatest(types.getWhatsappTemplateByIdAction.GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_REQUEST, getWhatsappTemplateById);
  yield takeLatest(types.createWhatsappTemplateAction.CREATE_WHATSAPP_TEMPLATE_ACTION_REQUEST, createWhatsappTemplates);
  yield takeLatest(types.editWhatsappTemplateAction.EDIT_WHATSAPP_TEMPLATE_ACTION_REQUEST, editWhatsappTemplates);
}
