import React from "react";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import { DeleteIconButton, EditIconButton } from "../button/icon-action-buttons";

export default function ActionColumn(props) {
  return (
    <>
      <div className="ml-minus-16px">
        <EditIconButton onClick={() => props.Edit()} />
        <DeleteIconButton onClick={() => props.Delete()} />
      </div>
    </>
  );
}

export function EditActionColumn(props) {
  return (
    <>
      <div className="ml-minus-22px">
        <EditIconButton onClick={() => props.Edit()} />
      </div>
    </>
  );
}
