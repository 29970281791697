import React from "react";
import RichTextEditor from "../../RichTextEditor/tinymce-editor";

export default function EmailTextEditor(props) {
  return (
    <div className="h-50vh composor-editor-wrapper border">
      <RichTextEditor defaultValue={props.defaultValue} value={props.value} onChange={(c) => props.onChange(c)} toolbarLocation="bottom" />
    </div>
  );
}
