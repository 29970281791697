import React, { useEffect } from "react";
import _ from "lodash";

const emailStats = [
  { name: "Clicked", key: "emailClicked" },
  { name: "Opened", key: "emailOpened" },
  { name: "Replied", key: "emailReplied" },
];

export default function CadenceStatsDisplay(props) {
  const [updatedValue, setUpdatedValue] = React.useState({});

  useEffect(() => {
    const stats = props.value;
    if (!_.isEmpty(stats)) {
      setUpdatedValue({
        activityCompleted: stats.activityCompleted,
        activityCreated: stats.activityCreated,
        emailClicked: stats.campaignEmailClicked + stats.syncEmailClicked,
        emailOpened: stats.campaignEmailOpened + stats.syncEmailOpened,
        emailReplied: stats.campaignEmailReplied + stats.syncEmailReplied,
        emailSent: stats.campaignEmailSent + stats.syncEmailSent,
        errored: stats.errored,
        smsDelivered: stats.smsDelivered,
        smsSent: stats.smsSent,
        smsUnDelivered: stats.smsUnDelivered,
        whatsappDelivered: stats.whatsappDelivered,
        whatsappFailed: stats.whatsappFailed,
        whatsappRead: stats.whatsappRead,
        whatsappSent: stats.whatsappSent,
      });
    }
  }, [props.value]);
  return (
    <span className={`data-container`}>
      <div className="d-flex gap-2 mr-10">
        {emailStats.map((each, ind) => {
          return (
            <div className={`d-flex flex-row flex-center badge badge-light-primary`} key={ind}>
              <span>{`${each.name} : ${updatedValue[each.key] ? updatedValue[each.key] : "0"}`}</span>
            </div>
          );
        })}
      </div>
    </span>
  );
}
