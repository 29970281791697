import { takeLatest, put, call } from "redux-saga/effects";
import * as calendarViewTypes from "../types/calendar-view.types";
import api from "../../../../network/apis/api";
import * as CalendarViewActions from "../actions/calendar_view_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";

function* getCalendarEventData(action) {
  try {
    const response = yield call(api.get, "/api/crm/activity-list/calendar-view", action.payload);
    if (response.status === 200) {
      yield put(CalendarViewActions.getCalendarEventSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(CalendarViewActions.getCalendarEventError(response));
    } else {
      yield put(CalendarViewActions.getCalendarEventError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(CalendarViewActions.getCalendarEventError(e));
  }
}

export default function* CalendarViewSaga() {
  yield takeLatest(calendarViewTypes.getCalendarEvent.GET_CALENDAR_EVENTS_REQUEST, getCalendarEventData);
}
