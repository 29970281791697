import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GreenGradientButton } from "../../../../../../components/button";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { InputLimit } from "../../../../../../constants";
import { getEmailProfileDetailsRequest, updateEmailProfileSettingsRequest } from "../../../../store/actions/campaign_email_settings_actions";

import { elasticEmailCountryData } from "./constants";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { ContentLoadingWithoutCard } from "../../../../../../components/loading";

export default function FooterSettings(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState({ label: "", value: "" });

  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const profileStore = useSelector((store) => store.campaign.settings.email.profile);

  useEffect(() => {
    dispatch(getEmailProfileDetailsRequest());
  }, [dispatch]);

  useEffect(() => {
    const updatedData = elasticEmailCountryData.map((each) => {
      return {
        value: each.CountryID,
        label: each.Name,
      };
    });
    setOptions(updatedData);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(profileStore.data) && !_.isEmpty(options)) {
      setFirstName(profileStore.data.firstName);
      setLastName(profileStore.data.lastName);
      setAddressLine1(profileStore.data.address1);
      setAddressLine2(profileStore.data.address2);
      setCity(profileStore.data.city);
      setState(profileStore.data.state);
      setPostalCode(profileStore.data.zip);

      const find = options.find((a) => a.value === profileStore.data.countryId);
      if (find) setCountry(find);
    }
  }, [profileStore.data, options]);

  const handleUpdateProfile = () => {
    if (_.isEmpty(firstName)) {
      return dispatchSnackbarError("First Name cannot be empty");
    } else if (_.isEmpty(lastName)) {
      return dispatchSnackbarError("Last Name cannot be empty");
    } else if (_.isEmpty(addressLine1)) {
      return dispatchSnackbarError("Address Line 1 cannot be empty");
    } else if (_.isEmpty(city)) {
      return dispatchSnackbarError("city cannot be empty");
    } else if (_.isEmpty(state)) {
      return dispatchSnackbarError("state cannot be empty");
    } else if (!postalCode) {
      return dispatchSnackbarError("postalCode cannot be empty");
    } else if (!country.value) {
      return dispatchSnackbarError("postalCode cannot be empty");
    }
    dispatch(
      updateEmailProfileSettingsRequest({
        firstName: firstName,
        lastName: lastName,
        address1: addressLine1,
        address2: addressLine2,
        city: city,
        state: state,
        zip: postalCode,
        countryId: country.value,
      }),
    );
  };

  if (profileStore.loading) {
    return <ContentLoadingWithoutCard />;
  }
  return (
    <div className="card mb-5 mb-xl-10 border-0 h-vh-120px-mx">
      <div className="card-body">
        <p className="text-black fs-5 mb-8">Having an address is mandatory by ISP. Set your physical address which will be shown in email footer.</p>
        <Row>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-first-name">
              <Form.Label aria-label="first-name">First Name *</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-last-name">
              <Form.Label aria-label="last-name">Last Name *</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-address-line1">
              <Form.Label aria-label="address-line1">Address Line 1 *</Form.Label>
              <Form.Control maxLength={InputLimit.TEXTAREA} type="text" placeholder="Enter Address Line 1" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-address-line2">
              <Form.Label aria-label="address-line2">Address Line 2</Form.Label>
              <Form.Control maxLength={InputLimit.TEXTAREA} type="text" placeholder="Enter Address Line 2" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-city">
              <Form.Label aria-label="city">City *</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter City" value={city} onChange={(e) => setCity(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-state">
              <Form.Label aria-label="state">State *</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter State" value={state} onChange={(e) => setState(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-postal-code">
              <Form.Label aria-label="postal-code">Zip/Postal Code *</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter Zip/Postal Code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-settings-manage-email-footer-settings-country">
              <Form.Label aria-label="country">Country *</Form.Label>
              <SimpleDropdown showSearch={true} value={country} label={"Select Country"} options={options} handleSelect={setCountry} />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group id="campaign-settings-manage-email-footer-settings-update-button" className="d-flex justify-content-start">
          <GreenGradientButton size="sm" onClick={handleUpdateProfile}>
            Update
          </GreenGradientButton>
        </Form.Group>
      </div>
    </div>
  );
}
