import React, { useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { GreenGradientButton } from "../../components/button";
import { useSelector } from "react-redux";

//props - modalProps(object)

export function CustomModal(props) {
  const showButtons = typeof props.modalProps.showButtons !== "undefined" ? props.modalProps.showButtons : true;
  return (
    <>
      <Modal backdrop="static" show={props.modalProps.show} onHide={props.modalProps.handleClose} size={props.modalProps.size ? props.modalProps.size : "md"}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalProps.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        {showButtons ? (
          <Modal.Footer>
            <Button variant="secondary" size="sm" onClick={props.modalProps.handleClose}>
              Close
            </Button>
            <GreenGradientButton disabled={props.modalProps.loading} size="sm" variant="primary" onClick={props.modalProps.handleAccept}>
              {props.modalProps.loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
              {props.modalProps.buttonText}
            </GreenGradientButton>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
}

export function DeleteModal(props) {
  return (
    <Modal backdrop="static" show={props.modalProps.show} onHide={props.modalProps.handleClose} size={props.modalProps.size ? props.modalProps.size : "md"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalProps.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={props.modalProps.handleClose}>
          Close
        </Button>
        <Button disabled={props.modalProps.loading} variant="danger" size="sm" onClick={props.modalProps.handleAccept}>
          {props.modalProps.loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
          {props.modalProps.deleteName ? props.modalProps.deleteName : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ConvertModal(props) {
  return (
    <Modal backdrop="static" show={props.modalProps.show} onHide={props.modalProps.handleClose} size={props.modalProps.size ? props.modalProps.size : "md"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalProps.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={props.modalProps.handleClose}>
          Close
        </Button>
        {props.modalProps.buttonType === "green" ? (
          <GreenGradientButton size="sm" onClick={props.modalProps.handleAccept}>
            {props.modalProps.buttonText ? props.modalProps.buttonText : "Convert"}
          </GreenGradientButton>
        ) : (
          <Button variant="warning" size="sm" onClick={props.modalProps.handleAccept}>
            {props.modalProps.buttonText ? props.modalProps.buttonText : "Convert"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export function CustomScrollableModal(props) {
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  useEffect(() => {
    const temp = `-${window.scrollY}px`;
    if (props.modalProps.show) {
      document.body.style.position = "fixed";
      document.body.style.top = temp;
      document.body.style.width = "100%";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [props.modalProps.show]);

  const nextButtonVal = props.modalProps.nextButtonVal;
  const showButtons = typeof props.modalProps.showButtons !== "undefined" ? props.modalProps.showButtons : true;
  const showCloseButton = typeof props.modalProps.showCloseButton !== "undefined" ? props.modalProps.showCloseButton : false;
  const showDeleteButton = typeof props.modalProps.showDeleteButton !== "undefined" ? props.modalProps.showDeleteButton : false;
  return (
    <>
      <Modal enforceFocus={false} backdrop="static" show={props.modalProps.show} onHide={props.modalProps.handleClose} size={props.modalProps.size ? props.modalProps.size : "md"}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalProps.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">{props.children}</Modal.Body>
        {showButtons ? (
          showDeleteButton ? (
            <Modal.Footer>
              <div className="d-flex flex-row flex-stack w-100">
                <div>
                  <Button size="sm" variant="danger" onClick={props.modalProps.handleDelete}>
                    Delete
                  </Button>
                </div>
                <div>
                  <Button size="sm" variant="secondary" onClick={props.modalProps.handleClose} className="mr-10px">
                    {props.modalProps.textReplacingWithCloseButton ? "Not using IMAP?" : "Close"}
                  </Button>
                  <GreenGradientButton size="sm" onClick={props.modalProps.handleAccept}>
                    {props.modalProps.buttonText}
                  </GreenGradientButton>
                </div>
              </div>
            </Modal.Footer>
          ) : props.modalProps.customMessageShow ? (
            <Modal.Footer>
              <div
                className={`d-flex flex-row flex-stack ${
                  (userProfile && userProfile.data && userProfile.data.userType === "OWNER") || (userProfile && userProfile.data && userProfile.data.userType === "ADMIN") ? "w-100" : ""
                }`}
              >
                {(userProfile && userProfile.data && userProfile.data.userType === "OWNER") || (userProfile && userProfile.data && userProfile.data.userType === "ADMIN") ? (
                  <div>
                    <a href="/settings/account/custom-field" className="d-flex align-items-center fw-500 font-size-14px">
                      <i className="bi bi-gear text-3699ff"></i>
                      <span className="ml-10px">{`Customize your ${props.modalProps.type} form`}</span>
                    </a>
                  </div>
                ) : null}
                <div>
                  <Button size="sm" variant="secondary" onClick={props.modalProps.handleClose} className="mr-10px">
                    {props.modalProps.textReplacingWithCloseButton ? "Not using IMAP?" : "Close"}
                  </Button>
                  <GreenGradientButton size="sm" onClick={props.modalProps.handleAccept}>
                    {props.modalProps.buttonText}
                  </GreenGradientButton>
                </div>
              </div>
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={props.modalProps.handleClose}>
                {props.modalProps.textReplacingWithCloseButton ? "Not using IMAP?" : "Close"}
              </Button>
              <GreenGradientButton size="sm" onClick={props.modalProps.handleAccept}>
                {props.modalProps.buttonText}
              </GreenGradientButton>
            </Modal.Footer>
          )
        ) : showCloseButton ? (
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={props.modalProps.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        ) : null}
        {nextButtonVal ? (
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={props.modalProps.handleClose}>
              Close
            </Button>
            <GreenGradientButton size="sm" onClick={props.modalProps.nextButton}>
              Next
            </GreenGradientButton>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
}

export function CustomScrollableEditModal(props) {
  useEffect(() => {
    const temp = `-${window.scrollY}px`;
    if (props.modalProps.show) {
      document.body.style.position = "fixed";
      document.body.style.top = temp;
      document.body.style.width = "100%";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [props.modalProps.show]);

  const showButtons = typeof props.modalProps.showButtons !== "undefined" ? props.modalProps.showButtons : true;
  return (
    <>
      <Modal backdrop="static" show={props.modalProps.show} onHide={props.modalProps.handleClose} size={props.modalProps.size ? props.modalProps.size : "md"}>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalProps.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        {showButtons ? (
          <Modal.Footer className="d-flex justify-content-between">
            <Button size="sm" variant="danger" onClick={props.modalProps.handleDelete}>
              Delete
            </Button>
            <div className="d-flex gap-10px">
              <Button size="sm" variant="secondary" onClick={props.modalProps.handleClose}>
                Close
              </Button>
              <GreenGradientButton size="sm" onClick={props.modalProps.handleAccept}>
                {props.modalProps.buttonText}
              </GreenGradientButton>
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
}

export function ErrorLogModal(props) {
  return (
    <Modal backdrop="static" show={props.modalProps.show} onHide={props.modalProps.handleClose} size={props.modalProps.size ? props.modalProps.size : "md"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalProps.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={props.modalProps.onClickDownloadLog}>
          Download Transcript
        </Button>
        <Button variant="secondary" size="sm" onClick={props.modalProps.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ConfirmationModal(props) {
  return (
    <Modal show={props.show} onHide={() => props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={props.onNoClick}>
          No
        </Button>
        <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={props.onYesClick}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
