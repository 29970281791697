import React from "react";
import "./style.css";

export function CustomSubHeader(props) {
  return (
    <>
      <div
        id="kt_subheader"
        className={`
        border-bottom-width-1px border-bottom-style-solid border-bottom-color-dcdddd
        border-top-width-1px border-top-style-solid border-top-color-523f6914
        bg-white h-55px header1 header-fixed px-5  d-flex align-items-center z-index-2 mb-15px`}
      >
        {props.children}
      </div>
    </>
  );
}
