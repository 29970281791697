import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import { BlueGradientButton } from "../../../../components/button";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { ContentWithCardLoading } from "../../../../components/loading";
import { DeleteModal } from "../../../../components/modal";
import AdavanceDataTable from "../../../../components/normal-table/advance-table";
import CustomSidePanel from "../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import ContentWrapper from "../../../Wrapper";
import CampaignSubHeader from "../../components/campaign-common-sub-header";

// actions
import * as shortLinkAction from "../../store/actions/campaign_short_link_actions";
import * as CampaignReportActions from "../../store/actions/campaign_report_actions";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ReloadSVG } from "../../../../components/custom-svgs/action-svg";
import { InputLimit } from "../../../../constants";
export default function CampaignShortLink(props) {
  const tabName = "Smart Shortlink";
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");

  // form fields
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");
  const [trackUser, setTrackUser] = useState(false);

  // reports
  const [showReports, setShowReports] = useState(false);

  // short link repprts
  const [analyticsPage, setAnalyticsPage] = useState(1);
  const [analyticsLimit, setAnalyticsLimit] = useState(10);

  const dispatch = useDispatch();
  const history = useHistory();

  const shortLinkStore = useSelector((store) => store.campaign.shortLink.list);
  const shortLinkReportStore = useSelector((store) => store.campaign.shortLink.reports);

  useEffect(() => {
    dispatch(shortLinkAction.getListOfShortLinksRequest({ limit: limit, page: page }));
  }, [dispatch, limit, page]);

  useEffect(() => {
    if (selectedId && analyticsLimit && analyticsPage) dispatch(shortLinkAction.getShortLinkReportsRequest({ id: selectedId, data: { limit: analyticsLimit, page: analyticsPage, searchText: search } }));
  }, [dispatch, selectedId, analyticsLimit, analyticsPage, search]);

  const handleDeleteClick = (data) => {
    setShowDelete(true);
    setSelectedId(data._id);
  };
  const handleDeleteAccept = () => {
    dispatch(shortLinkAction.deleteShortLinkRequest({ id: selectedId }));
    setShowDelete(false);
    setSelectedId(null);
  };
  const handleDeleteCancel = () => {
    setShowDelete(false);
    setSelectedId(null);
  };

  const deleteLeadBotModelProps = {
    show: showDelete,
    title: "Delete Short Link",
    handleClose: handleDeleteCancel,
    handleAccept: handleDeleteAccept,
  };

  const handleEditClick = (data) => {
    setShow(true);
    setIsEdit(true);
    setSelectedId(data._id);
    setTitle(data.title);
    setSlug(data.slug);
    setOriginalUrl(data.originalUrl);
    setTrackUser(data.trackUser);
  };

  const handleCancelClick = () => {
    setShow(false);
    setIsEdit(false);
    setSelectedId(null);
    setTitle("");
    setSlug("");
    setOriginalUrl("");
    setTrackUser(false);
  };

  const handleApplyClick = () => {
    if (_.isEmpty(title)) {
      return dispatchSnackbarError("Title cannot be empty!");
    }
    if (!isEdit && _.isEmpty(slug)) {
      return dispatchSnackbarError("Slug cannot be empty!");
    }
    if (!isEdit && _.isEmpty(originalUrl)) {
      return dispatchSnackbarError("Original URL cannot be empty!");
    }

    const payload = {
      data: {
        title: title,
        slug: slug,
        originalUrl: originalUrl,
        trackUser: trackUser ? true : false,
      },
      requestPayload: { limit: limit, page: page },
    };
    if (isEdit) {
      payload["id"] = selectedId;
      dispatch(
        shortLinkAction.editShortLinkRequest(payload, (data) => {
          if (data.status) {
            handleCancelClick();
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    } else {
      dispatch(
        shortLinkAction.createShortLinkRequest(payload, (data) => {
          if (data.status) {
            handleCancelClick();
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    }
  };

  const handleReport = (data) => {
    setSelectedId(data._id);
    setShowReports(true);
    // dispatch(shortLinkAction.getShortLinkReportsRequest({ id: data._id, data: { limit: analyticsLimit, page: analyticsPage, searchText: search } }));
  };
  const handleReportCancel = (data) => {
    setSelectedId(null);
    setShowReports(false);
  };
  const handleDownload = (data) => {
    const payload = { campaignId: data._id, channel: "short-link", type: "application" };
    dispatch(
      CampaignReportActions.downloadCampaignReportsRequest(payload, (data) => {
        if (data.status) {
          dispatchSnackbarSuccess("Succefully started download campaign.");
          history.push("/campaign/reports/download-report");
        }
      }),
    );
  };

  const handleReload = () => {
    dispatch(shortLinkAction.getShortLinkReportsRequest({ id: selectedId, data: { limit: analyticsLimit, page: analyticsPage, searchText: search } }));
  };
  return (
    <ContentWrapper
      subHeader={
        <CampaignSubHeader active={tabName}>
          <div className="d-flex align-items-center gap-4">
            <BlueGradientButton
              variant="success"
              size="sm"
              onClick={() => {
                setShow(true);
                setIsEdit(false);
              }}
            >
              + Short Link
            </BlueGradientButton>
          </div>
        </CampaignSubHeader>
      }
    >
      {shortLinkStore.loading ? (
        <ContentWithCardLoading />
      ) : shortLinkStore.data.length === 0 ? (
        <CustomEmptyPage
          page="shortLink"
          setShowAdd={() => {
            setShow(true);
            setIsEdit(false);
          }}
        />
      ) : (
        <div className="card card-custom">
          <div className="card-body py-4 px-4">
            <AdavanceDataTable
              columns={shortLinkStore.columns}
              data={shortLinkStore.data}
              limit={limit}
              pageNo={page}
              setLimit={setLimit}
              setPageNo={setPage}
              count={shortLinkStore.count}
              // actions
              handleDelete={handleDeleteClick}
              handleEdit={handleEditClick}
              handleReport={handleReport}
              handleDownload={handleDownload}
            />
          </div>
        </div>
      )}
      <BlankSidePanel show={show} handleClose={() => setShow(false)} title={isEdit ? "Edit Smart Short Link" : "Add Smart Shortlink"}>
        <Form.Group>
          <Form.Label>URL Title</Form.Label>
          <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Group>
        {!isEdit && (
          <Form.Group>
            <Form.Label>Original URL</Form.Label>
            <Form.Control type="text" value={originalUrl} onChange={(e) => setOriginalUrl(e.target.value)} />
          </Form.Group>
        )}

        {!isEdit && (
          <Form.Group>
            <Form.Label>URL Shortner</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">sm6.co/</InputGroup.Text>
              <Form.Control type="text" value={slug} placeholder="slug" aria-label="slug" onChange={(e) => setSlug(e.target.value)} />
            </InputGroup>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Mobile Tracking</Form.Label>
          <div className="form-check form-switch form-check-custom form-check-solid mr-5 pr-5">
            <input
              className="form-check-input h-20px w-35px"
              type="checkbox"
              checked={trackUser}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setTrackUser(e.target.checked);
              }}
              id="flexSwitch20x30"
            />
          </div>
        </Form.Group>
        <Form.Group className="d-flex justify-content-start">
          <Button className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button className="btn btn-sm btn-primary" onClick={handleApplyClick}>
            Apply
          </Button>
        </Form.Group>
      </BlankSidePanel>
      <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete a short link? you won't be able to recover data associated with short link.u</DeleteModal>
      <CustomSidePanel show={showReports} moduleName={`Detailed Report`} handleClose={handleReportCancel} customStyles={{ width: "80vw", right: "81vw" }}>
        <div className="card-header d-flex flex-stack align-items-center">
          <h3 className="card-title fw-bolder text-dark">{`Detailed Report`}</h3>
          <div>
            <div className="d-flex gap-3 align-items-center">
              <Form.Control className="350px" placeholder={"Search..."} max={InputLimit.TEXT} type="text" valye={search} onChange={(e) => setSearch(e.target.value)} />{" "}
              <IconButton onClick={handleReload} tooltip={"Reload"}>
                {ReloadSVG}
              </IconButton>{" "}
            </div>
          </div>
        </div>
        <div className="p-8">
          {shortLinkReportStore.loading ? (
            <ContentWithCardLoading />
          ) : shortLinkReportStore.data.length === 0 ? (
            <CustomEmptyPage page="shortLinkAnalytics" />
          ) : (
            <div className="card card-custom">
              <div className="card-body py-3 px-3 border border-1">
                <AdavanceDataTable
                  columns={shortLinkReportStore.columns}
                  data={shortLinkReportStore.data}
                  limit={analyticsLimit}
                  pageNo={analyticsPage}
                  setLimit={setAnalyticsLimit}
                  setPageNo={setAnalyticsPage}
                  count={shortLinkReportStore.count}
                />
              </div>
            </div>
          )}
        </div>
      </CustomSidePanel>
    </ContentWrapper>
  );
}
