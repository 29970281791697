import { combineReducers } from "redux";
import emailFinderValidation from "./email-finder.reducer";
import emailValidationReducer from "./email-validation.reducer";

const integrationsReducer = combineReducers({
    emailFinder: emailFinderValidation,
    emailValidation: emailValidationReducer,
});

export default integrationsReducer;
