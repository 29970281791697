import React, { useEffect, useState } from "react";
import _ from "lodash";
import FullScreenModal from "./components/fullscreen-modal";
import OnlyStepper from "../../../../../../components/stepper/only-stepper";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import CurrencyInterval from "./components/currency-interval";

import * as subscriptionV2Actions from "../../../store/actions/subscription_billing_v2_actions";
import { useDispatch, useSelector } from "react-redux";
import * as usageRecordActions from "../../../../actions/usage.actions";
import { dispatchSnackbarError, dispatchSnackbarWarning } from "../../../../../../utils/toaster";
import * as cadenceSettingsAction from "../../../../../Automation/cadence/store/actions/cadence_settings_actions";

export default function SubscriptionModal(props) {
    const [activeStep, setActiveStep] = useState(1);
    const [interval, setPlanInterval] = useState("month");
    const [currencySymbol, setCurrencySymbol] = useState("$");
    const [currency, setCurrency] = useState("usd");
    const [selectedMainPlan, setSelectedMainPlan] = useState({});// {planId, plan, amount, interval, currency, supportedAddons}
    const [users, setUsers] = useState(0);
    const [addOnQuantity, setAddOnQuantity] = useState({});
    const [supportedAddons, setSupportedAddons] = useState([]);
    const [planMapping, setPlanMapping] = useState({});
    const [total, setTotal] = useState(0);
    const [continueToPayDisabled, setContinueToPayDisabled] = useState(false);
    const [userUsage, setUserUsage] = useState({});// {records, customField, pipeline, dashboard, leadbot, workflow, cadence}
    const [owners, setOwners] = useState({});
    const [updateLoading, setUpdateLoading] = useState(false);

    const dispatch = useDispatch();
    const Paddle = window.Paddle;

    const pricesStore = useSelector((state) => state.Billing.subscriptionv2.prices);
    const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
    const usageDataV2 = useSelector((store) => store.usage.v2);
    const cadenceSettingsStore = useSelector((store) => store.cadence.settings.allUserLimit);
    const userRole = useSelector((store) => store.insight.userRole);


    useEffect(() => {
        if (window.location.host !== "app.salessimplify.com") {
            Paddle.Environment.set("sandbox");
            Paddle.Setup({ seller: 12540 });
        }
        else {
            Paddle.Setup({ seller: 170787 });
        }
    }, [Paddle]);


    useEffect(() => {
        dispatch(subscriptionV2Actions.getAccountBillingV2Request())
        dispatch(subscriptionV2Actions.getBillingPricesV2Request())
        dispatch(usageRecordActions.getUsageRecordReportV2Request());
        dispatch(cadenceSettingsAction.getCadenceSettingsRequest())
    }, [dispatch])

    useEffect(() => {
        if (_.isEmpty(pricesStore.data)) return;
        const { addOns, plans } = pricesStore.data;
        const obj = {};
        const planInterval = interval === "year" ? "YEARLY" : "MONTHLY";

        for (const addOnName in addOns) {
            const amount = addOns[addOnName][planInterval]["PRICES"][_.toUpper(currency)];
            obj[addOnName] = {
                plan: addOnName,
                planId: addOns[addOnName][planInterval]["PLAN_ID"],
                amount: amount,
                interval: planInterval,
                currency: addOns.currency,
                supportedIn: addOns[addOnName]["SUPPORTED_IN"],
            }
        }
        for (const plan in plans) {
            const amount = plans[plan][planInterval]["PRICES"][_.toUpper(currency)];
            obj[plan] = {
                plan: plan,
                planId: plans[plan][planInterval]["PLAN_ID"],
                amount: amount,
                interval: planInterval,
                currency: currency,
                supportedAddons: plans[plan]["SUPPORTED_ADDONS"],
            }
        }
        setPlanMapping(obj);
    }, [pricesStore.data, currency, interval]);


    useEffect(() => {
        let _price = 0;
        for (const each in addOnQuantity) {
            _price += addOnQuantity[each] * planMapping[each]?.amount;
        }
        _price += selectedMainPlan.amount * users;
        setTotal(_price);
    }, [planMapping, addOnQuantity, selectedMainPlan, users]);

    useEffect(() => {
        const { currency, interval, plan } = selectedMainPlan;
        const planMappingData = planMapping[plan];
        if (planMappingData) {
            if (planMappingData.currency !== currency || planMappingData.interval !== interval) {
                setSelectedMainPlan(planMappingData);
            }
        }
    }, [selectedMainPlan, currency, interval, planMapping]);


    useEffect(() => {
        if (!_.isEmpty(accountBillingStore.data) && !_.isEmpty(selectedMainPlan) && !_.isEmpty(pricesStore.data)) {
            const { plans } = accountBillingStore.data;

            if (_.isEmpty(plans)) return;
            else if (!Array.isArray(plans)) return;
            else if (plans.length === 0) return;

            for (const each of plans) {
                if (each.planId === selectedMainPlan.planId) {
                    setUsers(each.quantity);
                }
                else {
                    const { addOns } = pricesStore.data;
                    for (const addOnName in addOns) {
                        const monthPlanId = addOns[addOnName]["MONTHLY"]["PLAN_ID"];
                        const yearPlanId = addOns[addOnName]["YEARLY"]["PLAN_ID"];

                        if (each.planId === monthPlanId || each.planId === yearPlanId) {
                            const _quantity = {};
                            _quantity[addOnName] = each.quantity;
                            setAddOnQuantity((prev) => ({ ...prev, ..._quantity }));
                        }
                    }
                }
            }
        }
    }, [accountBillingStore.data, selectedMainPlan, pricesStore.data])

    // usage
    useEffect(() => {
        if (!_.isEmpty(usageDataV2.data)) {
            const obj = {};
            obj["records"] = { module: "Contacts", consumed: usageDataV2.data.records.consumed.contacts, available: usageDataV2.data.records.assigned.contacts, isLock: false, extra: usageDataV2.data.records.extra };
            obj["customField"] = { module: "Custom Fields", consumed: usageDataV2.data.records.consumed.fields, available: usageDataV2.data.records.assigned.fields, isLock: false };
            obj["pipeline"] = { module: "Pipeline", consumed: usageDataV2.data.consumed.pipeline, available: usageDataV2.data.records.extraLimit.pipeline, isLock: false };
            obj["dashboard"] = {
                module: "Dashboard",
                consumed: usageDataV2.data.consumed.dashboardV2,
                available: usageDataV2.data.records.extraLimit.dashboard,
                isLock: false,
            };
            obj["leadbot"] = { module: "Leadbot", consumed: usageDataV2.data.consumed.leadbot, available: usageDataV2.data.records.extraLimit.leadbot, isLock: false };
            obj["workflow"] = {
                module: "Workflow",
                consumed: usageDataV2.data.consumed.workflow.reduce((result, item) => item.count + result, 0),
                available: usageDataV2.data.records.extraLimit.workflow,
                isLock: !usageDataV2.data.assigned.isWorkflow,
                workflowExtra: usageDataV2.data.consumed.workflow,
            };
            obj["cadence"] = {
                module: "Cadence",
                consumed: usageDataV2.data.consumed.cadence.reduce((result, item) => item.count + result, 0),
                available: usageDataV2.data.records.extraLimit.cadence,
                isLock: !usageDataV2.data.assigned.isCadence,
            };
            setUserUsage(obj);
        }
    }, [usageDataV2.data]);
    useEffect(() => {
        if (userRole.list.allUsers) {
            const a = {};
            userRole.list.allUsers.forEach((each) => {
                a[each.id] = each;
            });
            setOwners(a);
        }
    }, [userRole.list.allUsers]);


    const handleSelectMainPlan = (plan) => {
        setSelectedMainPlan(plan);
        setActiveStep(2);
        if (plan.plan === "lifeTimeFree") {
            setUsers(1);
        }
        const _qunatity = {};
        plan.supportedAddons.forEach(each => {
            _qunatity[each] = addOnQuantity[each] || 0;
        });
        setAddOnQuantity(_qunatity);
        setSupportedAddons(plan.supportedAddons);
    }

    const validateRecordsAndCustomFields = () => {
        const { isSubscribedForLifeTimeFree, selectedPlan } = accountBillingStore.data;
        if (isSubscribedForLifeTimeFree) return true;
        const mapping = {
            businessPlan: "business",
            businessPlusPlan: "businessPlus",
            enterprisePlan: "enterprise",
            startUpPlan: "startup",
            free: "free",
            lifeTimeFree: "lifeTimeFree",
        }
        if (selectedPlan === mapping[selectedMainPlan.plan]) return true;

        if (selectedMainPlan.plan === "startupPlan" && !_.isEmpty(userUsage)) {
            if (userUsage.records.consumed > 100000) {
                dispatchSnackbarWarning(`You currently have ${userUsage.records.consumed} active records. In order to continue with your current subscription, please reduce the number of records to 100,000 or below.`);
                return false;
            }
            else if (userUsage.customField.consumed > 15) {
                dispatchSnackbarWarning(`You currently have ${userUsage.customField.consumed} custom fields. In order to continue with your current subscription, please reduce the number of custom fields to 15 or below.`);
                return false;
            }
            else if (userUsage.pipeline.consumed > 3) {
                dispatchSnackbarWarning(`You currently have ${userUsage.pipeline.consumed} pipelines. In order to continue with your current subscription, please reduce the number of pipelines to 3 or below.`);
                return false;
            }
            else if (userUsage.leadbot.consumed > 10) {
                dispatchSnackbarWarning(`You currently have ${userUsage.leadbot.consumed} leadbots. In order to continue with your current subscription, please reduce the number of leadbot to 10 or below.`);
                return false;
            }
            for (const each of userUsage.dashboard.consumed) {
                if (owners[each._id] && 1 < each.count) {
                    dispatchSnackbarWarning(`User email ${owners[each._id].email} currently have ${each.count} dashboards. Please reduce some of your dashboards to 1 in order to apply your current subscription`);
                    return false;
                }
            }

        }
        else if (selectedMainPlan.plan === "businessPlan" && !_.isEmpty(userUsage)) {
            if (userUsage.records.consumed > 1000000) {
                dispatchSnackbarWarning(`You currently have ${userUsage.records.consumed} active records. In order to continue with your current subscription, please reduce the number of records to 1,000,000 or below.`);
                return false;
            }
            else if (userUsage.leadbot.consumed > 20) {
                dispatchSnackbarWarning(`You currently have ${userUsage.leadbot.consumed} leadbots. In order to continue with your current subscription, please reduce the number of leadbot to 20 or below.`);
                return false;
            }

        }
        else if (selectedMainPlan.plan === "businessPlusPlan" && !_.isEmpty(userUsage)) {
            if (userUsage.records.consumed > 1000000) {
                dispatchSnackbarWarning(`You currently have ${userUsage.records.consumed} active records. In order to continue with your current subscription, please reduce the number of records to 1,000,000 or below.`);
                return false;
            }
            else if (userUsage.leadbot.consumed > 50) {
                dispatchSnackbarWarning(`You currently have ${userUsage.leadbot.consumed} leadbots. In order to continue with your current subscription, please reduce the number of leadbot to 50 or below.`);
                return false;
            }
        }

        return true;
    }
    const handleCadenceRestrictionOnPlanChange = (planName) => {
        for (const eachUser of cadenceSettingsStore.data) {
            if (planName === "freePlan" || planName === "free") {
                if (eachUser.activeSubscribers > 0) {
                    dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 0 in order to apply your current subscription`);
                    return false;
                }
            } else if (planName === "startUpPlan" || planName === "startup") {
                if (eachUser.activeSubscribers > 100) {
                    dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 100 in order to apply your current subscription`);
                    return false;
                }
            } else if (planName === "businessPlan" || planName === "business") {
                if (eachUser.activeSubscribers > 1000) {
                    dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 1000 in order to apply your current subscription`);
                    return false;
                }
            } else if (planName === "businessPlusPlan" || planName === "businessPlus") {
                if (eachUser.activeSubscribers > 10000) {
                    dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 10000 in order to apply your current subscription`);
                    return false;
                }
            }
        }
        return true;
    };

    const convertToFreePlan = () => {
        const check = handleCadenceRestrictionOnPlanChange("freePlan");
        if (!check) return;
        dispatch(
            subscriptionV2Actions.assignFreePlanV2Request({}, async (result) => {
                if (result.status) {
                    props.setShowPlans(false);
                    window.location.reload();
                } else {
                    dispatchSnackbarError(result.message);
                }
            }),
        );
    };

    const handleSubscription = () => {
        const { isSubscribedForLifeTimeFree, usersLimit, emailSyncCount } = accountBillingStore.data;

        if (isSubscribedForLifeTimeFree && selectedMainPlan.plan !== "lifeTimeFree") {
            return dispatchSnackbarWarning(`You have already enrolled in the lifetime free plan, and it is not possible to switch to a paid plan.`);
        }
        else if (!isSubscribedForLifeTimeFree && selectedMainPlan.plan === "lifeTimeFree") {
            return dispatchSnackbarWarning(`You have already enrolled in the paid plan, and it is not possible to switch to a lifetime free plan.`);
        }
        else if (selectedMainPlan.plan === "lifeTimeFree" && users !== 1) {
            return dispatchSnackbarWarning(`You can select only 1 user/agent in lifetime free plan.`);
        }
        else if (!isSubscribedForLifeTimeFree && users < usersLimit.value) {
            return dispatchSnackbarWarning(`Currently you having ${usersLimit.value} active users. please deactivate your remaining users to ${users} to select ${users} users/agents in subscription.`);
        }
        else if (isSubscribedForLifeTimeFree && addOnQuantity["emailSync"] < emailSyncCount.current) {
            return dispatchSnackbarWarning(`Currently you having ${emailSyncCount.current} active email sync accounts. please deactivate your remaining users to ${addOnQuantity["emailSync"]} to select ${addOnQuantity["emailSync"]} users/agents in subscription.`);
        }
        else if (_.isEmpty(selectedMainPlan)) {
            return dispatchSnackbarWarning("Please select a plan to continue");
        }

        if (!validateRecordsAndCustomFields()) return;
        if (!handleCadenceRestrictionOnPlanChange(selectedMainPlan.plan)) return;


        const planItems = [];
        const keyName = accountBillingStore.data.subscriptionId ? "planId" : "priceId"
        if (selectedMainPlan.planId) {
            planItems.push({
                [keyName]: selectedMainPlan.planId,
                quantity: users
            })
        }

        for (const keys in addOnQuantity) {
            const planData = planMapping[keys];
            if (addOnQuantity[keys] && planData) {
                planItems.push({
                    [keyName]: planData.planId,
                    quantity: addOnQuantity[keys]
                })
            }
        }

        if (planItems.length === 0) return dispatchSnackbarWarning("Please select atleast one plan to continue");
        for (const each of planItems) {
            if (each.quantity === 0) return dispatchSnackbarWarning("Please select atleast one plan to continue");
        }

        if (accountBillingStore.data.subscriptionId && accountBillingStore.data.subscriptionStatus !== "canceled") {
            const data = {
                planItems: planItems
            };
            setContinueToPayDisabled(true);
            setUpdateLoading(true);
            dispatch(
                subscriptionV2Actions.updateSubscriptionV2Request(data, (result) => {
                    if (result.status) {
                        props.setShowPlans(false);
                        setContinueToPayDisabled(false);
                        dispatch(subscriptionV2Actions.getBillingPricesV2Request(() => { }));
                        dispatch(usageRecordActions.getUsageRecordReportV2Request());
                    }
                    setUpdateLoading(false)
                }),
            );
        }
        else {
            const data = {
                settings: {
                    displayMode: "overlay",
                    theme: "light",
                    locale: "en",
                    allowLogout: false,
                    successUrl: window.location.href
                },
                items: planItems,
                customer: {
                    id: accountBillingStore?.data?.customerId,
                },
                customData: {
                    accountId: accountBillingStore?.data?.accountId,
                    userId: accountBillingStore?.data?.userId,
                }
            }
            Paddle.Checkout.open(data);
        }
    }

    return (
        <FullScreenModal show={props.showPlans} setShow={props.setShowPlans}>
            <div className="d-flex flex-stack">
                <div>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { activeStep === 2 ? setActiveStep(1) : props.setShowPlans(false); }}  >
                        <path d="M11.6719 24.3625L0.946875 13.6375C0.784375 13.475 0.669 13.299 0.60075 13.1094C0.533583 12.9198 0.5 12.7167 0.5 12.5C0.5 12.2833 0.533583 12.0802 0.60075 11.8906C0.669 11.7011 0.784375 11.525 0.946875 11.3625L11.6719 0.637512C11.9698 0.339595 12.3419 0.183596 12.7882 0.169512C13.2357 0.156512 13.6219 0.312512 13.9469 0.637512C14.2719 0.935428 14.4414 1.30755 14.4555 1.75389C14.4685 2.2013 14.3125 2.58751 13.9875 2.91251L6.025 10.875H24.1844C24.6448 10.875 25.031 11.0305 25.343 11.3414C25.6539 11.6534 25.8094 12.0396 25.8094 12.5C25.8094 12.9604 25.6539 13.3461 25.343 13.657C25.031 13.969 24.6448 14.125 24.1844 14.125H6.025L13.9875 22.0875C14.2854 22.3854 14.4414 22.7646 14.4555 23.225C14.4685 23.6854 14.3125 24.0646 13.9875 24.3625C13.6896 24.6875 13.3104 24.85 12.85 24.85C12.3896 24.85 11.9969 24.6875 11.6719 24.3625Z" fill="black" />
                    </svg>
                </div>
                <div className="fs-2 fw-bold">
                    Affordable pricing plans like never before
                </div>
                <div>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => props.setShowPlans(false)}>
                        <path d="M22.2494 22.2494L2.75 2.75" stroke="black" stroke-width="3.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.2495 2.75061L2.75009 22.25" stroke="black" stroke-width="3.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <OnlyStepper steps={["Plans", "Add-ons"]} activeStep={activeStep} />
            <CurrencyInterval
                interval={interval}
                currency={currency}
                setCurrency={setCurrency}
                setPlanInterval={setPlanInterval}
                setCurrencySymbol={setCurrencySymbol}
            />
            {
                activeStep === 1 ?
                    <Step1
                        interval={interval}
                        currency={currency}
                        currencySymbol={currencySymbol}
                        planMapping={planMapping}
                        handleSelectMainPlan={handleSelectMainPlan}
                        convertToFreePlan={convertToFreePlan}
                    /> :
                    <Step2
                        interval={interval}
                        currency={currency}
                        currencySymbol={currencySymbol}
                        selectedMainPlan={selectedMainPlan}
                        users={users}
                        setUsers={setUsers}
                        planMapping={planMapping}
                        addOnQuantity={addOnQuantity}
                        setAddOnQuantity={setAddOnQuantity}
                        supportedAddons={supportedAddons}
                        total={total}
                        continueToPayDisabled={continueToPayDisabled}
                        handleSubscription={handleSubscription}
                        updateLoading={updateLoading}
                        isSubscriptionActive={accountBillingStore?.data?.subscriptionStatus === "active" && accountBillingStore?.data?.subscriptionId}
                    />
            }

        </FullScreenModal>
    );
}