import {
  getCampaignList,
  repeatCampaign,
  createCampaign,
  sendTestEmail,
  cancelCampaign,
  rescheduledCamapign,
  uploadEmailAttachement,
  checkAudienceCount,
  cancelSplitBatchCampaign,
  uploadTemporaryFile,
} from "../types/campaign_types";

export const getCampaignListRequest = (payload) => ({
  type: getCampaignList.GET_CAMPAIGN_LIST_REQUEST,
  payload,
});
export const getCampaignListRequestAfter = (payload) => ({
  type: getCampaignList.GET_CAMPAIGN_LIST_REQUEST_AFTER,
  payload,
});
export const getCampaignListSuccess = (payload) => ({
  type: getCampaignList.GET_CAMPAIGN_LIST_SUCCESS,
  payload,
});
export const getCampaignListError = (payload) => ({
  type: getCampaignList.GET_CAMPAIGN_LIST_ERROR,
  payload,
});
export const repeatCampaignRequest = (payload) => ({
  type: repeatCampaign.REPEAT_CAMPAIGN_REQUEST,
  payload,
});
export const clearRepeatCampaignRequest = (payload) => ({
  type: repeatCampaign.CLEAR_REPEAT_CAMPAIGN_REQUEST,
  payload,
});

export const createCampaignRequest = (payload, cb) => ({
  type: createCampaign.CREATE_CAMPAIGN_REQUEST,
  payload,
  cb,
});
export const createCampaignSuccess = (payload) => ({
  type: createCampaign.CREATE_CAMPAIGN_SUCCESS,
  payload,
});
export const createCampaignError = (payload) => ({
  type: createCampaign.CREATE_CAMPAIGN_ERROR,
  payload,
});

export const sendTestEmailRequest = (payload, cb) => ({
  type: sendTestEmail.SEND_TEST_EMAIL_REQUEST,
  payload,
  cb,
});
export const sendTestEmailSuccess = (payload) => ({
  type: sendTestEmail.SEND_TEST_EMAIL_SUCCESS,
  payload,
});
export const sendTestEmailError = (payload) => ({
  type: sendTestEmail.SEND_TEST_EMAIL_ERROR,
  payload,
});

export const cancelCampaignRequest = (payload, cb) => ({
  type: cancelCampaign.CANCEL_CAMPAIGN_REQUEST,
  payload,
  cb,
});
export const cancelCampaignSuccess = (payload) => ({
  type: cancelCampaign.CANCEL_CAMPAIGN_SUCCESS,
  payload,
});
export const cancelCampaignError = (payload) => ({
  type: cancelCampaign.CANCEL_CAMPAIGN_ERROR,
  payload,
});

export const cancelSplitBatchCampaignRequest = (payload, cb) => ({
  type: cancelSplitBatchCampaign.CANCEL_SPLIT_BATCH_CAMPAIGN_REQUEST,
  payload,
  cb,
});
export const cancelSplitBatchCampaignSuccess = (payload) => ({
  type: cancelSplitBatchCampaign.CANCEL_SPLIT_BATCH_CAMPAIGN_SUCCESS,
  payload,
});
export const cancelSplitBatchCampaignError = (payload) => ({
  type: cancelSplitBatchCampaign.CANCEL_SPLIT_BATCH_CAMPAIGN_ERROR,
  payload,
});

export const rescheduledCamapignRequest = (payload, cb) => ({
  type: rescheduledCamapign.RESCHEDULED_CAMPAIGN_REQUEST,
  payload,
  cb,
});
export const rescheduledCamapignSuccess = (payload) => ({
  type: rescheduledCamapign.RESCHEDULED_CAMPAIGN_SUCCESS,
  payload,
});
export const rescheduledCamapignError = (payload) => ({
  type: rescheduledCamapign.RESCHEDULED_CAMPAIGN_ERROR,
  payload,
});

export const uploadEmailAttachementRequest = (payload, cb) => ({
  type: uploadEmailAttachement.UPLOAD_EMAIL_ATTACHMENT_REQUEST,
  payload,
  cb,
});
export const uploadEmailAttachementSuccess = (payload) => ({
  type: uploadEmailAttachement.UPLOAD_EMAIL_ATTACHMENT_SUCCESS,
  payload,
});
export const uploadEmailAttachementError = (payload) => ({
  type: uploadEmailAttachement.UPLOAD_EMAIL_ATTACHMENT_ERROR,
  payload,
});

export const checkAudienceCountRequest = (payload, cb) => ({
  type: checkAudienceCount.CHECK_AUDIENCE_COUNT_REQUEST,
  payload,
  cb,
});
export const checkAudienceCountSuccess = (payload) => ({
  type: checkAudienceCount.CHECK_AUDIENCE_COUNT_SUCCESS,
  payload,
});
export const checkAudienceCountError = (payload) => ({
  type: checkAudienceCount.CHECK_AUDIENCE_COUNT_ERROR,
  payload,
});

export const uploadTemporaryFileRequest = (payload, cb) => ({
  type: uploadTemporaryFile.UPLOAD_TEMPORARY_FILE_REQUEST,
  payload,
  cb,
});
export const uploadTemporaryFileSuccess = (payload) => ({
  type: uploadTemporaryFile.UPLOAD_TEMPORARY_FILE_SUCCESS,
  payload,
});
export const uploadTemporaryFileError = (payload) => ({
  type: uploadTemporaryFile.UPLOAD_TEMPORARY_FILE_ERROR,
  payload,
});
