import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card } from "react-bootstrap";
import ColumnChart from "../../../../../insights/components/amchart-graphs/column-chart";

const order = ["Sent", "Error/Failed", "Read", "Delivered", "Deleted"];
const colors = ["e5efff", "ffdfff", "9898fa", "ccd8ff", "ffccb0"];

export default function WhatsappDetailedAnalyticsReport(props) {
    const [analytics, setAnalytics] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            const obj = {},
                _data = [];
            props.data.forEach((each) => {
                obj[each.label] = each.value;
                if (["Sent", "Delivered", "Read", "Deleted", "Error/Failed"].includes(each.label)) {
                    _data.push(each);
                }
            });
            const _analytics = order.map((each) => ({ value: obj[each] ? obj[each] : 0, label: each }));
            setAnalytics(_analytics);
            setUpdatedData(_data);
        }
    }, [props.data]);

    return (
        <div className="w-100 p-6">
            <Card className="w-100 p-6 h-50vh">
                <ColumnChart xAxisName={""} yAxisName={""} won={updatedData} chartId={props.campaignId} values={"value"} category={"label"} />
            </Card>
            <div className="mt-8">
                <div className="row">
                    {analytics.map((each, index) => {
                        if (each.label === "Total Emails") return <></>;
                        return (
                            <div key={index} className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                                <div className={`border border-1 rounded-2 mb-4 p-6 box-shadow bg-${colors[index]}`}>
                                    <div className="fs-2tx">{each.value}</div>
                                    <div className="fs-5">{each.label}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
