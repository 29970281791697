import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import * as smsSettingsTypes from "../types/campaign_sms_settings_types";
import api from "../../../../network/apis/api";
import { endpoints } from "../endpoints";
import * as smsSettingsActions from "../actions/campaign_sms_settings_actions";

function* getListOfSMSSenderIds(action) {
  try {
    const res = yield call(api.get, endpoints.SMS_SENDER_ID, action.payload);
    if (res.status === 200) {
      yield put(smsSettingsActions.getListOfSMSSenderIdsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(smsSettingsActions.getListOfSMSSenderIdsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(smsSettingsActions.getListOfSMSSenderIdsError(e));
  }
}

function* createSMSSenderId(action) {
  try {
    const res = yield call(api.post, endpoints.SMS_SENDER_ID, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(smsSettingsActions.createSMSSenderIdSuccess(res.data));
      yield put(smsSettingsActions.getListOfSMSSenderIdsRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(smsSettingsActions.createSMSSenderIdError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(smsSettingsActions.createSMSSenderIdError(e));
  }
}
function* deleteSMSSenderId(action) {
  try {
    const res = yield call(api.delete, `${endpoints.SMS_SENDER_ID}/${action.payload}`);
    if (res.status === 200) {
      if (res.data.status)
        dispatchSnackbarSuccess(res.data.message);
      else
        dispatchSnackbarError(res.data.message);
      yield put(smsSettingsActions.deleteSMSSenderIdSuccess(res.data));
      yield put(smsSettingsActions.getListOfSMSSenderIdsRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(smsSettingsActions.deleteSMSSenderIdError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(smsSettingsActions.deleteSMSSenderIdError(e));
  }
}

function* getSMSCampaignPricing(action) {
  try {
    const res = yield call(api.get, endpoints.SMS_PRICING, action.payload);
    if (res.status === 200) {
      yield put(smsSettingsActions.getSMSCampaignPricingSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(smsSettingsActions.getSMSCampaignPricingError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(smsSettingsActions.getSMSCampaignPricingError(e));
  }
}

function* getSMSSenderIdCountryList(action) {
  try {
    const res = yield call(api.get, endpoints.SMS_SENDERID_COUNTRY_LIST, action.payload);
    if (res.status === 200) {
      yield put(smsSettingsActions.getSMSSenderIdCountryListSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(smsSettingsActions.getSMSSenderIdCountryListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(smsSettingsActions.getSMSSenderIdCountryListError(e));
  }
}

export default function* campaignSMSSettingsSaga() {
  yield takeLatest(smsSettingsTypes.getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_REQUEST, getListOfSMSSenderIds);
  yield takeLatest(smsSettingsTypes.createSMSSenderId.CREATE_SMS_SENDER_ID_REQUEST, createSMSSenderId);
  yield takeLatest(smsSettingsTypes.deleteSMSSenderId.DELETE_SMS_SENDER_ID_REQUEST, deleteSMSSenderId);
  yield takeLatest(smsSettingsTypes.getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_REQUEST, getSMSCampaignPricing);
  yield takeLatest(smsSettingsTypes.getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_REQUEST, getSMSSenderIdCountryList);
}
