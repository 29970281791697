import { getActivityLossReasons, getActivityLossReasonsSettings } from "../types/activity.types";

const initState = {
  settings: {
    loading: false,
    enabled: undefined,
  },
  reasons: {
    loading: false,
    list: [],
  },
};

export default function activityLossReasonReducer(state = initState, action = {}) {
  switch (action.type) {
    case getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_REQUEST:
      return {
        ...state,
        reasons: {
          ...state.reasons,
          loading: true,
        },
      };
    case getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_SUCCESS:
      return {
        ...state,
        reasons: {
          ...state.reasons,
          loading: false,
          list: action.payload.data,
        },
      };
    case getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_ERROR:
      return {
        ...state,
        reasons: {
          ...state.reasons,
          loading: false,
        },
      };
    case getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: true,
        },
      };
    case getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          enabled: action.payload.isActivityLostReasonEnabled,
        },
      };
    case getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
        },
      };
    default:
      return state;
  }
}
