import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { Accordion, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { ViewMore } from "../../custom-components/viewMore";

//actions
import * as dealActions from "../../../../containers/deals/dealPipeline/actions";
import InlineStyled from "../../../inline-styled/inline-styled";

export function DealDetails(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [cellEdit, setCellEdit] = useState({});
  const [showColumns, setShowColumns] = useState([]);
  const [viewLessColomns, setViewLessColomns] = useState([]);
  const [loadMoreFieldCount, setLoadMoreFieldCount] = useState(0);
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();

  const fieldStore = useSelector((state) => state.DealList.dealFields);
  const dealListCellLoading = useSelector((state) => state.DealList.dealList.cellLoading);
  const dealSidepanelStore = useSelector((store) => store.DealPipeline.dealPopup);
  const dealLoadingStore = useSelector((state) => state.DealPipeline.loadingData);
  const dealListloadingStore = useSelector((state) => state.DealList.loadingData);

  useEffect(() => {
    const _viewLess = [];
    const tempFieldStore = fieldStore.list
      .filter((a) => {
        if (a.apiKeyName === "title") {
          _viewLess[0] = a._id;
        } else if (a.apiKeyName === "status") {
          _viewLess[1] = a._id;
        } else if (a.apiKeyName === "stageId") {
          _viewLess[2] = a._id;
        } else if (a.apiKeyName === "dealCurrency") {
          _viewLess[3] = a._id;
        } else if (a.apiKeyName === "dealValue") {
          _viewLess[4] = a._id;
        } else if (a.apiKeyName === "expectedCloseDate") {
          _viewLess[5] = a._id;
        }
        return a.isEnabledForTable;
      })
      .map((each) => each._id);
    setShowColumns(tempFieldStore);
    setViewLessColomns(_viewLess);
    setLoadMoreFieldCount(tempFieldStore.length - _viewLess.length);
  }, [fieldStore]);

  const handleCellEdit = (cellId, data) => {
    const payload = {
      ...data,
      ...cellEdit,
    };

    dispatch(
      dealActions.updateDealpopupDetailsRequest({
        data: payload,
        filter: props.pageForm === "dealList" ? dealListloadingStore : dealLoadingStore,
        pageForm: props.pageForm,
        cellId: cellId,
        dealId: props.dealId,
      }),
    );
  };

  const addTag = (name, colorCode) => {
    dispatch(dealActions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(dealActions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(dealActions.deleteDealTagRequest(id));
  };

  const getPersonDetails = () => {
    if (props.moduleType === "deal") {
      return props.sidePanelProps.data || {};
    }
    return {};
  };

  return (
    <div key={props.index}>
      <Draggable draggableId={"deal-details"} index={props.index}>
        {(provided, snapshot) => (
          <Accordion
            as={Card}
            activeKey={isOpen || editing ? "toggle-deal" : null}
            onSelect={() => {
              if (!editing) setIsOpen(!isOpen);
            }}
            className={`mb-4 card-dashed1 ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style }}
          >
            <Accordion.Toggle eventKey={"toggle-deal"} className="cursor-pointer p-14px bg-white h-0px-mn" as={Card.Header}>
              <div className="d-flex flex-stack w-100">
                <div className="d-flex align-items-center gap-2">
                  <i className="bi bi-coin side-pannel-card-header-color"></i>
                  <span className="fs-6 m-0 side-pannel-card-header-color">Deal</span>
                  <i className={`side-panel-data-icon fas fa-sm fa-chevron-${isOpen ? "up" : "down"} ml-2 side-pannel-card-header-color`}></i>
                </div>
                <i onClick={(e) => e.stopPropagation()} className="fa fa-bars fa-md" aria-hidden="true" {...provided.dragHandleProps}></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"toggle-deal"}>
              <InlineStyled as={Card.Body} styles={{ padding: "1rem" }} onClick={(e) => e.stopPropagation()}>
                {!_.isEmpty(getPersonDetails()) ? (
                  <>
                    <ViewMore
                      cellEdit={cellEdit}
                      setCellEdit={setCellEdit}
                      handleCellEdit={handleCellEdit}
                      addTag={addTag}
                      editTag={editTag}
                      deleteTag={deleteTag}
                      setEditingStatus={setEditing}
                      page={"DealList"}
                      pageSub={"deal"}
                      showColumns={props.viewMore ? showColumns : viewLessColomns}
                      columns={fieldStore.list}
                      idToIndex={fieldStore.idToIndex}
                      viewing={props.sidePanelProps.data || {}}
                      cellLoading={dealSidepanelStore.cellLoading || dealListCellLoading}
                      loadingData={props.pageForm === "dealList" ? dealListloadingStore : dealLoadingStore}
                      pageForm={props.pageForm}
                    />
                    <div className="side-panel-data-item px-0">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setViewMore(!props.viewMore);
                        }}
                        variant="link"
                        className="p-0 pt-2 px-0 text-decoration-none"
                      >
                        {props.viewMore ? "View Less" : `See ${loadMoreFieldCount} More`}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div data-kt-search-element="empty" className="text-center">
                    <div className="pt-10 pb-10">
                      <span className="svg-icon svg-icon-4x opacity-50">
                        <i className="bi bi-bullseye font-size-30px"></i>
                      </span>
                    </div>
                    <div className="pb-15 fw-bold">
                      <h3 className="text-gray-600 fs-5 mb-2">There are no leads linked.</h3>
                      <div className="text-muted fs-7">leads associated with the current deal will be displayed here.</div>
                    </div>
                  </div>
                )}
              </InlineStyled>
            </Accordion.Collapse>
          </Accordion>
        )}
      </Draggable>
    </div>
  );
}
