import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
// import { CustomScrollableModal } from '../../../../components/modal';
import moment from "moment";
import { BlueGradientButton } from "../../../../components/button";
import { getAccountBillingV2Request } from "../../../Settings/Billing/store/actions/subscription_billing_v2_actions";
import * as comapnyActions from "../../store/actions/admin-actions/company-settings.actions";
import { useDispatch } from "react-redux";
import { dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ContentWrapper from "../../../Wrapper";
import { CustomSubHeader } from "../../../../components/subheader";
import { DeleteModal } from "../../../../components/modal";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export default function DeactiveCompany() {
  const [showDelete, setShowDelete] = useState(false);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  const dispatch = useDispatch();

  const onActiveButtonClick = () => {
    const ActiveDetails = { status: "ACTIVE" };
    dispatch(
      comapnyActions.activeDeactiveCompanyStatusRequest(ActiveDetails, (data) => {
        if (data["status"]) {
          dispatchSnackbarSuccess("Account Successfully Updated", "success");
          dispatch(getAccountBillingV2Request(() => { }));
        }
      }),
    );
  };

  const onDeleteNowAccept = () => {
    const ActiveDetails = {};
    dispatch(
      comapnyActions.deleteCompanyNowRequest(ActiveDetails, (data) => {
        if (data["status"]) {
          dispatchSnackbarSuccess("Account Successfully Deleted", "success");
          dispatch(getAccountBillingV2Request(() => { }));
        }
      }),
    );
    setShowDelete(false);
  };

  const deleteTemplateModalProps = {
    show: showDelete,
    title: "Delete Account",
    handleClose: () => setShowDelete(false),
    handleAccept: () => onDeleteNowAccept(),
  };

  return (
    <ContentWrapper subHeader={<CustomSubHeader />}>
      {accountBillingStore.loading ? (
        <div className="d-flex w-100 flex-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="d-flex flex-column flex-root h-vh-176px">
          <InlineStyled
            className=" d-flex flex-row-fluid bgi-size-cover bgi-position-center"
            styles={{
              backgroundImage: `url(${toAbsoluteUrl("/media/error/brokenwire.png")})`,
            }}
          >
            <div className="p-0 d-flex flex-column align-items-center pb-10 w-100">
              <div className="card-px text-center py-5 my-5">
                <h2 className="fs-2x fw-bolder mb-10 mt-10">{"Account Deactivated"}</h2>
                <div className="text-gray-600 fs-5 fw-bold mb-8">
                  <p>{"Your account will be deleted on the below specified dates and will not be recoverable afterwards.:"}</p>
                  {userProfile.data && userProfile.data.userType !== "OWNER" && <p className="fw-bolder">{"Contact the Account Owner for more information."}</p>}
                  <div className={`d-flex ${userProfile.data && userProfile.data.userType === "OWNER" ? "flex-stack" : "flex-center"} mt-12 w-100`}>
                    <table>
                      <tbody>
                        <tr>
                          <td align="left"> Account created at:</td>
                          <td className="ml-8" align="left">
                            {" "}
                            {moment(accountBillingStore.data.accountDataCreatedAt).format("l")}
                          </td>
                        </tr>
                        <tr>
                          <td align="left"> Account deactivated at:</td>
                          <td className="ml-8" align="left">
                            {" "}
                            {moment(accountBillingStore.data.accountDeactivatedDate).format("l")}
                          </td>
                        </tr>
                        <tr>
                          <td align="left"> Account will be delete on:</td>
                          <td className="ml-8" align="left">
                            {" "}
                            {moment(accountBillingStore.data.accountDelateDate).format("l")}
                          </td>
                        </tr>
                        <tr>
                          <td align="left"> Account status:</td>
                          <td className="ml-8" align="left">
                            {" "}
                            {accountBillingStore.data.accountStatus}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {userProfile.data && userProfile.data.userType === "OWNER" && (
                      <div className="d-flex gap-3 flex-column">
                        <BlueGradientButton size="sm" onClick={() => onActiveButtonClick()}>
                          Restore Account
                        </BlueGradientButton>
                        <Button variant="danger" size="sm" onClick={() => setShowDelete(true)}>
                          Delete Now
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </InlineStyled>
          <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete the account? After delete you won't able to recover it.</DeleteModal>
        </div>
      )}
    </ContentWrapper>
  );
}
