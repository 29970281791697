import { renderAmount } from "../../utils";
// import Tip from 'modules/common/components/Tip';
// import { getUserAvatar } from 'modules/common/utils';
// import { IDeal } from 'modules/deals/types';
import * as React from "react";
import { BodyRow } from "../styled";

export default class DealItem extends React.PureComponent {
  render() {
    const { deal } = this.props;
    const stageName = deal.stage ? deal.stage.name : "";

    return (
      <BodyRow>
        <span>{deal.name}</span>
        <span>{renderAmount(deal.amount) || 0}</span>
        <span>{stageName}</span>
        <span>
          {(deal.assignedUsers || []).map((user) => (
            <div key={user._id} text={user.details && (user.details.fullName || user.email)}>
              <img src={user} alt={user.details && (user.details.fullName || user.email)} width="22px" height="22px" className="ml-2px border-radius-11px" />
            </div>
          ))}
        </span>
      </BodyRow>
    );
  }
}
