import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ActivityCalendar from "./Activity-Calendar";
import ActivityList from "./Activity-List";

export default function ActivityPageRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/activity-list`} component={ActivityList} />
        <Route exact path={`${match.url}/activity-list/:moduleName/:linkedId`} component={ActivityList} />
        <Route exact path={`${match.url}/activity-list/:activityId/:moduleName/:linkedId`} component={ActivityList} />
        <Route exact path={`${match.url}/calendar-monthly-view`} component={ActivityCalendar} />
        <Redirect to={`${match.url}/activity-list`} />
      </Switch>
    </>
  );
}
