import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function CheckboxSelector({ setValue, value, options }) {
  return (
    <Form.Group id="custom-inputs-checkbox-selector" className={"d-flex gap-2"}>
      {options.map((each, index) => {
        return (
          <Form.Check className="px-10" type={"checkbox"} id={each + index}>
            <Form.Check.Input className="custom-checkbox" checked={each === value} onChange={() => setValue(each)} type={"checkbox"} varient="primary" />
            <Form.Check.Label className="mt-1">{each}</Form.Check.Label>
          </Form.Check>
        );
      })}
    </Form.Group>
  );
}

export const CustomCheckBox = ({ label, isChecked, onClick, info }) => {
  return (
    <Form.Check className="px-10" type={"checkbox"} id={label}>
      <Form.Check.Input className="custom-checkbox " checked={isChecked} onChange={onClick} type={"checkbox"} varient="primary" />
      <Form.Check.Label className="mt-2 ">{label}</Form.Check.Label>
      {info && (
        <OverlayTrigger overlay={<Tooltip>{info}</Tooltip>}>
          <i className="bi bi-info-circle ml-2"></i>
        </OverlayTrigger>
      )}
    </Form.Check>
  );
};
