import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";

const options = [
  { label: "Email Replied", value: { channelType: "EMAIL", channelRule: "REPLIED" } },
  { label: "Email Opened", value: { channelType: "EMAIL", channelRule: "OPENED" } },
  { label: "Email Errored", value: { channelType: "EMAIL", channelRule: "ERRORED" } },
  // { label: "Email Unsubscribed", value: { channelType: "EMAIL", channelRule: "UNSUBSCRIBED" } },
  { label: "Activity Completed", value: { channelType: "TASK", channelRule: "COMPLETED" } },
  { label: "SMS Delivered", value: { channelType: "SMS", channelRule: "DELIVERED" } },
  { label: "SMS Undelivered", value: { channelType: "SMS", channelRule: "UNDELIVERED" } },

  { label: "Whatsapp Delivered", value: { channelType: "WHATSAPP", channelRule: "DELIVERED" } },
  { label: "Whatsapp Read", value: { channelType: "WHATSAPP", channelRule: "READ" } },
  { label: "Whatsapp Failed", value: { channelType: "WHATSAPP", channelRule: "ERRORED" } },

  // {label:"Activity Created",value:{channelType:"TASK",channelRule:"ERRORED"}},
  // { label: "Email Sent", value: { channelType: "EMAIL", channelRule: "SENT" } },
  // { label: "SMS Sent", value: { channelType: "SMS", channelRule: "SENT" } },
];

export default function CadenceBreakupRuleStep4({ breakUpRule, setBreakUpRule }) {
  const handleButtonClick = (_value) => {
    const find = breakUpRule.find((each) => each.channelRule === _value.channelRule && each.channelType === _value.channelType);
    if (find) {
      setBreakUpRule(breakUpRule.filter((a) => a && !(a.channelRule === _value.channelRule && a.channelType === _value.channelType)));
    } else {
      setBreakUpRule([...breakUpRule, _value]);
    }
  };
  const isActive = (eachValue) => {
    const find = breakUpRule.find((each) => each.channelRule === eachValue.channelRule && each.channelType === eachValue.channelType);
    return find ? true : false;
  };
  return (
    <div className="cadence-steps-container card">
      <div className="d-flex align-items-center ">
        <label className="cadence-head-label">Cadence Break-up Rule (Optional)</label>
        <OverlayTrigger overlay={<Tooltip>Stop cadence for a subscriber when one of the selected conditions occurs.</Tooltip>}>
          <i className="bi bi-info-circle ml-2 mb-4"></i>
        </OverlayTrigger>
      </div>
      <div className={`mb-0 d-flex flex-wrap gap-2`}>
        {options.map((each, index) => {
          return (
            <div
              key={index}
              onClick={() => handleButtonClick(each.value)}
              className={`h-35px border px-4 py-2 d-flex align-items-center gap-2 rounded-pill cursor-pointer ${isActive(each.value) ? "border-primary" : "bg-white"}`}
            >
              <span className={`text-hover-primary ${isActive(each.value) ? "text-primary fw-bold" : "text-gray-700"}`}>{each.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
