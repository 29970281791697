import {
  PEOPLES_REQUEST,
  PEOPLES_SUCCESS,
  PEOPLES_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  PEOPLEDROPDOWN_REQUEST,
  PEOPLEDROPDOWN_SUCCESS,
  PEOPLEDROPDOWN_ERROR,
  OWNERS_REQUEST,
  OWNERS_SUCCESS,
  OWNERS_ERROR,
  PEOPLEFIELDS_REQUEST,
  PEOPLEFIELDS_SUCCESS,
  PEOPLEFIELDS_ERROR,
  PEOPLEADDFIELDS_REQUEST,
  PEOPLEADDFIELDS_SUCCESS,
  PEOPLEADDFIELDS_ERROR,
  PEOPLETAGS_REQUEST,
  PEOPLETAGS_SUCCESS,
  PEOPLETAGS_ERROR,
  PEOPLETAGSADD_REQUEST,
  PEOPLETAGSADD_SUCCESS,
  PEOPLETAGSADD_ERROR,
  PEOPLETAGSEDIT_REQUEST,
  PEOPLETAGSEDIT_SUCCESS,
  PEOPLETAGSEDIT_ERROR,
  PEOPLETAGSDELETE_REQUEST,
  PEOPLETAGSDELETE_SUCCESS,
  PEOPLETAGSDELETE_ERROR,
  PEOPLECELLEDIT_REQUEST,
  PEOPLECELLEDIT_SUCCESS,
  PEOPLECELLEDIT_ERROR,
  PEOPLEADD_REQUEST,
  PEOPLEADD_SUCCESS,
  PEOPLEADD_ERROR,
  PEOPLEBULKEDIT_REQUEST,
  PEOPLEBULKEDIT_SUCCESS,
  PEOPLEBULKEDIT_ERROR,
  PEOPLEDELETE_REQUEST,
  PEOPLEDELETE_SUCCESS,
  PEOPLEDELETE_ERROR,
  PEOPLEFILTERS_REQUEST,
  PEOPLEFILTERS_SUCCESS,
  PEOPLEFILTERS_ERROR,
  PEOPLEFILTERADD_REQUEST,
  PEOPLEFILTERADD_SUCCESS,
  PEOPLEFILTERADD_ERROR,
  PEOPLEFILTEREDIT_REQUEST,
  PEOPLEFILTEREDIT_SUCCESS,
  PEOPLEFILTEREDIT_ERROR,
  PEOPLEFILTERFAVORITE_REQUEST,
  PEOPLEFILTERFAVORITE_SUCCESS,
  PEOPLEFILTERFAVORITE_ERROR,
  PEOPLEFILTERDELETE_REQUEST,
  PEOPLEFILTERDELETE_SUCCESS,
  PEOPLEFILTERDELETE_ERROR,
  PEOPLEDROPDOWNADD_REQUEST,
  PEOPLEDROPDOWNADD_SUCCESS,
  PEOPLEDROPDOWNADD_ERROR,
  PEOPLES_UPDATE_REQUEST,
  PEOPLES_UPDATE_SUCCESS,
  PEOPLES_UPDATE_ERROR,
  PEOPLE_POPUP_REQUEST,
  PEOPLE_POPUP_SUCCESS,
  PEOPLE_POPUP_ERROR,
  PEOPLE_POPUP_UPDATE_REQUEST,
  PEOPLE_POPUP_UPDATE_SUCCESS,
  PEOPLE_POPUP_UPDATE_ERROR,
  PEOPLE_POPUP_EDIT_REQUEST,
  PEOPLE_POPUP_EDIT_SUCCESS,
  PEOPLE_POPUP_EDIT_ERROR,
  LOADING_DATA_UPDATE,
  PEOPLEMERGE_REQUEST,
  PEOPLEMERGE_SUCCESS,
  PEOPLEMERGE_ERROR,
  APPLYFILTER,
  CLEARFILTER,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  FIELD_SHOWHIDE_REQUEST,
  FIELD_SHOWHIDE_SUCCESS,
  FIELD_SHOWHIDE_ERROR,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_UPDATEPRIORITY_SUCCESS,
  FIELD_UPDATEPRIORITY_ERROR,
  PEOPLE_LEAD_DROPDOWN_REQUEST,
  PEOPLE_LEAD_DROPDOWN_SUCCESS,
  PEOPLE_LEAD_DROPDOWN_ERROR,
  CONVERT_PEOPLE_TO_LEAD_REQUEST,
  CONVERT_PEOPLE_TO_LEAD_SUCCESS,
  CONVERT_PEOPLE_TO_LEAD_ERROR,
  PEOPLE_POPUP_REQUEST_AFTER,
  generatePeopleAIWFData,
} from "../types/types";

// Getting people data
export function peoplesRequest(req) {
  return {
    type: PEOPLES_REQUEST,
    payload: req,
  };
}

export function peoplesSuccess(res) {
  return {
    type: PEOPLES_SUCCESS,
    payload: res,
  };
}

export function peoplesError(err) {
  return {
    type: PEOPLES_ERROR,
    payload: err,
  };
}

// Update loading data
export function loadingDataUpdateRequest(req) {
  return {
    type: LOADING_DATA_UPDATE,
    payload: req,
  };
}

// Getting people popup data
export function peoplePopupRequest(req) {
  return {
    type: PEOPLE_POPUP_REQUEST,
    payload: req,
  };
}

export function peoplePopupSuccess(res) {
  return {
    type: PEOPLE_POPUP_SUCCESS,
    payload: res,
  };
}

export function peoplePopupError(err) {
  return {
    type: PEOPLE_POPUP_ERROR,
    payload: err,
  };
}

export function peoplePopupRequestAfter(req) {
  return {
    type: PEOPLE_POPUP_REQUEST_AFTER,
    payload: req,
  };
}

// Editing people popup data
export function peoplePopupEditRequest(req) {
  return {
    type: PEOPLE_POPUP_EDIT_REQUEST,
    payload: req,
  };
}

export function peoplePopupEditSuccess(res) {
  return {
    type: PEOPLE_POPUP_EDIT_SUCCESS,
    payload: res,
  };
}

export function peoplePopupEditError(err) {
  return {
    type: PEOPLE_POPUP_EDIT_ERROR,
    payload: err,
  };
}

// Updating people popup data
export function peoplePopupUpdateRequest(req) {
  return {
    type: PEOPLE_POPUP_UPDATE_REQUEST,
    payload: req,
  };
}

export function peoplePopupUpdateSuccess(res) {
  return {
    type: PEOPLE_POPUP_UPDATE_SUCCESS,
    payload: res,
  };
}

export function peoplePopupUpdateError(err) {
  return {
    type: PEOPLE_POPUP_UPDATE_ERROR,
    payload: err,
  };
}

// Getting people data without loading
export function peoplesUpdateRequest(req) {
  return {
    type: PEOPLES_UPDATE_REQUEST,
    payload: req,
  };
}

export function peoplesUpdateSuccess(res) {
  return {
    type: PEOPLES_UPDATE_SUCCESS,
    payload: res,
  };
}

export function peoplesUpdateError(err) {
  return {
    type: PEOPLES_UPDATE_ERROR,
    payload: err,
  };
}

// Clearing error and success store
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  };
}

// Getting people dropdown data
export function peopleDropdownRequest(req) {
  return {
    type: PEOPLEDROPDOWN_REQUEST,
    payload: req,
  };
}

export function peopleDropdownSuccess(res) {
  return {
    type: PEOPLEDROPDOWN_SUCCESS,
    payload: res,
  };
}

export function peopleDropdownError(err) {
  return {
    type: PEOPLEDROPDOWN_ERROR,
    payload: err,
  };
}

//people and lead data getting
export function peopleLeadDropdownRequest(req) {
  return {
    type: PEOPLE_LEAD_DROPDOWN_REQUEST,
    payload: req,
  };
}

export function peopleLeadDropdownSuccess(res) {
  return {
    type: PEOPLE_LEAD_DROPDOWN_SUCCESS,
    payload: res,
  };
}

export function peopleLeadDropdownError(err) {
  return {
    type: PEOPLE_LEAD_DROPDOWN_ERROR,
    payload: err,
  };
}

// Getting owners data
export function ownersRequest(req) {
  return {
    type: OWNERS_REQUEST,
    payload: req,
  };
}

export function ownersSuccess(res) {
  return {
    type: OWNERS_SUCCESS,
    payload: res,
  };
}

export function ownersError(err) {
  return {
    type: OWNERS_ERROR,
    payload: err,
  };
}

// Getting column details
export function peopleFieldsRequest(req) {
  return {
    type: PEOPLEFIELDS_REQUEST,
    payload: req,
  };
}

export function peopleFieldsSuccess(res) {
  return {
    type: PEOPLEFIELDS_SUCCESS,
    payload: res,
  };
}

export function peopleFieldsError(err) {
  return {
    type: PEOPLEFIELDS_ERROR,
    payload: err,
  };
}

// Getting Add column details
export function peopleAddFieldsRequest(req) {
  return {
    type: PEOPLEADDFIELDS_REQUEST,
    payload: req,
  };
}

export function peopleAddFieldsSuccess(res) {
  return {
    type: PEOPLEADDFIELDS_SUCCESS,
    payload: res,
  };
}

export function peopleAddFieldsError(err) {
  return {
    type: PEOPLEADDFIELDS_ERROR,
    payload: err,
  };
}

// Adding people
export function peopleAddRequest(req, cb) {
  return {
    type: PEOPLEADD_REQUEST,
    payload: req,
    cb,
  };
}

export function peopleAddSuccess(res) {
  return {
    type: PEOPLEADD_SUCCESS,
    payload: res,
  };
}

export function peopleAddError(err) {
  return {
    type: PEOPLEADD_ERROR,
    payload: err,
  };
}

// Dropdown add people
export function peopleDropdownAddRequest(req) {
  return {
    type: PEOPLEDROPDOWNADD_REQUEST,
    payload: req,
  };
}

export function peopleDropdownAddSuccess(res) {
  return {
    type: PEOPLEDROPDOWNADD_SUCCESS,
    payload: res,
  };
}

export function peopleDropdownAddError(err) {
  return {
    type: PEOPLEDROPDOWNADD_ERROR,
    payload: err,
  };
}

// Bulk Editing people
export function peopleBulkEditRequest(req) {
  return {
    type: PEOPLEBULKEDIT_REQUEST,
    payload: req,
  };
}

export function peopleBulkEditSuccess(res) {
  return {
    type: PEOPLEBULKEDIT_SUCCESS,
    payload: res,
  };
}

export function peopleBulkEditError(err) {
  return {
    type: PEOPLEBULKEDIT_ERROR,
    payload: err,
  };
}

// Deleting people
export function peopleDeleteRequest(req) {
  return {
    type: PEOPLEDELETE_REQUEST,
    payload: req,
  };
}

export function peopleDeleteSuccess(res) {
  return {
    type: PEOPLEDELETE_SUCCESS,
    payload: res,
  };
}

export function peopleDeleteError(err) {
  return {
    type: PEOPLEDELETE_ERROR,
    payload: err,
  };
}

// Getting tag details
export function peopleTagsRequest(req) {
  return {
    type: PEOPLETAGS_REQUEST,
    payload: req,
  };
}

export function peopleTagsSuccess(res) {
  return {
    type: PEOPLETAGS_SUCCESS,
    payload: res,
  };
}

export function peopleTagsError(err) {
  return {
    type: PEOPLETAGS_ERROR,
    payload: err,
  };
}

// Adding Tag
export function peopleTagsAddRequest(req) {
  return {
    type: PEOPLETAGSADD_REQUEST,
    payload: req,
  };
}

export function peopleTagsAddSuccess(res) {
  return {
    type: PEOPLETAGSADD_SUCCESS,
    payload: res,
  };
}

export function peopleTagsAddError(err) {
  return {
    type: PEOPLETAGSADD_ERROR,
    payload: err,
  };
}

// Editing Tag
export function peopleTagsEditRequest(req) {
  return {
    type: PEOPLETAGSEDIT_REQUEST,
    payload: req,
  };
}

export function peopleTagsEditSuccess(res) {
  return {
    type: PEOPLETAGSEDIT_SUCCESS,
    payload: res,
  };
}

export function peopleTagsEditError(err) {
  return {
    type: PEOPLETAGSEDIT_ERROR,
    payload: err,
  };
}

// Deleting Tag
export function peopleTagsDeleteRequest(req) {
  return {
    type: PEOPLETAGSDELETE_REQUEST,
    payload: req,
  };
}

export function peopleTagsDeleteSuccess(res) {
  return {
    type: PEOPLETAGSDELETE_SUCCESS,
    payload: res,
  };
}

export function peopleTagsDeleteError(err) {
  return {
    type: PEOPLETAGSDELETE_ERROR,
    payload: err,
  };
}

// Editing Cell
export function peopleCellEditRequest(req) {
  return {
    type: PEOPLECELLEDIT_REQUEST,
    payload: req,
  };
}

export function peopleCellEditSuccess(res) {
  return {
    type: PEOPLECELLEDIT_SUCCESS,
    payload: res,
  };
}

export function peopleCellEditError(err) {
  return {
    type: PEOPLECELLEDIT_ERROR,
    payload: err,
  };
}

// Getting filter data
export function peopleFiltersRequest(req) {
  return {
    type: PEOPLEFILTERS_REQUEST,
    payload: req,
  };
}

export function peopleFiltersSuccess(res) {
  return {
    type: PEOPLEFILTERS_SUCCESS,
    payload: res,
  };
}

export function peopleFiltersError(err) {
  return {
    type: PEOPLEFILTERS_ERROR,
    payload: err,
  };
}

// Adding Filter
export function peopleFilterAddRequest(req, cb) {
  return {
    type: PEOPLEFILTERADD_REQUEST,
    payload: req,
    cb,
  };
}

export function peopleFilterAddSuccess(res) {
  return {
    type: PEOPLEFILTERADD_SUCCESS,
    payload: res,
  };
}

export function peopleFilterAddError(err) {
  return {
    type: PEOPLEFILTERADD_ERROR,
    payload: err,
  };
}

// Editing Filter
export function peopleFilterEditRequest(req, cb) {
  return {
    type: PEOPLEFILTEREDIT_REQUEST,
    payload: req,
    cb,
  };
}

export function peopleFilterEditSuccess(res) {
  return {
    type: PEOPLEFILTEREDIT_SUCCESS,
    payload: res,
  };
}

export function peopleFilterEditError(err) {
  return {
    type: PEOPLEFILTEREDIT_ERROR,
    payload: err,
  };
}

// Favoriting Filter
export function peopleFilterFavoriteRequest(req) {
  return {
    type: PEOPLEFILTERFAVORITE_REQUEST,
    payload: req,
  };
}

export function peopleFilterFavoriteSuccess(res) {
  return {
    type: PEOPLEFILTERFAVORITE_SUCCESS,
    payload: res,
  };
}

export function peopleFilterFavoriteError(err) {
  return {
    type: PEOPLEFILTERFAVORITE_ERROR,
    payload: err,
  };
}

// Deleting Filter
export function peopleFilterDeleteRequest(req) {
  return {
    type: PEOPLEFILTERDELETE_REQUEST,
    payload: req,
  };
}

export function peopleFilterDeleteSuccess(res) {
  return {
    type: PEOPLEFILTERDELETE_SUCCESS,
    payload: res,
  };
}

export function peopleFilterDeleteError(err) {
  return {
    type: PEOPLEFILTERDELETE_ERROR,
    payload: err,
  };
}

// Merging
export function peopleMergeRequest(req) {
  return {
    type: PEOPLEMERGE_REQUEST,
    payload: req,
  };
}

export function peopleMergeSuccess(res) {
  return {
    type: PEOPLEMERGE_SUCCESS,
    payload: res,
  };
}

export function peopleMergeError(err) {
  return {
    type: PEOPLEMERGE_ERROR,
    payload: err,
  };
}

export function applyFilter(res) {
  return {
    type: APPLYFILTER,
    payload: res,
  };
}

export function clearFilter(res) {
  return {
    type: CLEARFILTER,
    payload: res,
  };
}

// Update fields
export function fieldUpdateRequest(req) {
  return {
    type: FIELD_UPDATE_REQUEST,
    payload: req,
  };
}

export function fieldUpdateSuccess(res) {
  return {
    type: FIELD_UPDATE_SUCCESS,
    payload: res,
  };
}

export function fieldUpdateError(err) {
  return {
    type: FIELD_UPDATE_ERROR,
    payload: err,
  };
}

// show/hide field
export function fieldShowHideRequest(req) {
  return {
    type: FIELD_SHOWHIDE_REQUEST,
    payload: req,
  };
}

export function fieldShowHideSuccess(res) {
  return {
    type: FIELD_SHOWHIDE_SUCCESS,
    payload: res,
  };
}

export function fieldShowHideError(err) {
  return {
    type: FIELD_SHOWHIDE_ERROR,
    payload: err,
  };
}

// update field priority
export function fieldUpdatePriorityRequest(req) {
  return {
    type: FIELD_UPDATEPRIORITY_REQUEST,
    payload: req,
  };
}

export function fieldUpdatePrioritySuccess(res) {
  return {
    type: FIELD_UPDATEPRIORITY_SUCCESS,
    payload: res,
  };
}

export function fieldUpdatePriorityError(err) {
  return {
    type: FIELD_UPDATEPRIORITY_ERROR,
    payload: err,
  };
}

export function convertPeopleToLeadRequest(req, cb) {
  return {
    type: CONVERT_PEOPLE_TO_LEAD_REQUEST,
    payload: req,
    cb,
  };
}
export function convertPeopleToLeadSuccess(req) {
  return {
    type: CONVERT_PEOPLE_TO_LEAD_SUCCESS,
    payload: req,
  };
}
export function convertPeopleToLeadError(req) {
  return {
    type: CONVERT_PEOPLE_TO_LEAD_ERROR,
    payload: req,
  };
}


export const generatePeopleAIWFDataRequest = (req) => ({
  type: generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_REQUEST,
  payload: req,
});
export const generatePeopleAIWFDataSuccess = (res) => ({
  type: generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_SUCCESS,
  payload: res,
});
export const generatePeopleAIWFDataError = (err) => ({
  type: generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_ERROR,
  payload: err,
});