import React from "react";
import { useHistory } from "react-router-dom";

export function TabWithActions(props) {
  const history = useHistory();
  return (
    <div className="card flex-row mb-5 mb-xl-10 d-flex justify-content-between align-items-center">
      <div className=" card stepper stepper-links d-flex flex-column border-0">
        <div className="container">
          <div className="d-flex overflow-auto h-55px">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              {props.links.map((each, index) => {
                return (
                  <li key={index} className="nav-item cursor-pointer" onClick={() => history.push(each.to)}>
                    <div className={`nav-link text-active-primary me-6 ${each.name === props.active ? "active" : ""}`} id="tab1">
                      {each.name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mr-8">{props.actions}</div>
    </div>
  );
}
