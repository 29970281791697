import React from "react";
import UsersCard from "./usersCard";
import MultiTabs from "../../components/multi-tabs";
import PermissionSets from "./permissionSets";

export default function AdminPage(props) {
  const userDetails = [
    {
      userName: "Manglam",
      email: "manglam@saino.io",
      sets: "Admin User",
    },
    {
      userName: "test1",
      email: "test1@saino.io",
      sets: "Regular User",
    },
    {
      userName: "test2",
      email: "test2@saino.io",
      sets: "Manager User",
    },
    {
      userName: "test3",
      email: "test2@saino.io",
      sets: "Manager User",
    },
    {
      userName: "test4",
      email: "test2@saino.io",
      sets: "Manager User",
    },
  ];

  return (
    <>
      <MultiTabs TabName1={"Users"} TabName2={"Permission Sets"} TabName3={"Visibility Group"} TabName4={"Teamd"} content1={<UsersCard details={userDetails} />} content2={<PermissionSets />} />
    </>
  );
}
