import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { GreenGradientButton } from "../../../../../components/button";
import RichTextEditor from "../../../../../components/RichTextEditor/tinymce-editor";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { getEmailNotificationsByIdRequest } from "../../actions/email_notification_actions";
import { useDispatch, useSelector } from "react-redux";
import { UpgradePlanPage } from "../../../../../components/empty_page";
import SubscriptionPlanModal from "../../../../Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import { TagInputBox, ToFieldTagInputBoxWithDropdown } from "../../../../../components/saino-email-composer/functions";
import { InputLimit } from "../../../../../constants";
import { EmailSignatureDropdown } from "../../../../../components/email-signature/email-signature-dropdown";
import TemplateManager from "../../../../../components/templates";
import BuilderComposer from "../../../../../components/saino-email-composer/hybrid-email-composer/builder-composer-view";
import { RadioSelectorWithTooltip } from "../../../../../components/custom-inputs/radio-selector";
import VariableOptionsPro from "../../../../../components/variable-options";
import * as syncSettingActions from "../../../../Settings/store/actions/profile-actions/sync-settings.actions";
import NylasFromEmail from "../../../../../components/saino-email-composer/components/nylas-from-email";

const commTypeOptions = [
  {
    value: "internal",
    label: "Internal company users",
    info: "",
  },
  {
    value: "external",
    label: "Contacts (leads, people, deal associated)",
    info: "If deals are selected it will send a notification to all associated people",
  },
];

export default function EmailNotificationForm(props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [fromEmail, setFromEmail] = useState([]);
  const [toEmail, setToEmail] = React.useState([]);
  const [ccEmail, setCcEmail] = React.useState([]);
  const [bccEmail, setBccEmail] = React.useState([]);
  const [ccInput, setCcInput] = React.useState("");
  const [bccInput, setBccInput] = React.useState("");
  const [showcc, setShowcc] = React.useState(false);
  const [showBcc, setShowBcc] = React.useState(false);
  const [subject, setSubject] = React.useState();
  const [body, setBody] = React.useState("");
  const [defaultBody, setDefaultBody] = useState("");
  const [commType, setCommType] = useState("internal");
  const [showVariableOptions, setShowVariableOptions] = useState(false);
  const [defaultVariableValues, setDefaultVariableValues] = useState({});

  const [showTemplateManager, setShowTemplateManager] = useState(false);
  const [editorType, setEditorType] = useState("tiny_cloud");
  const [builderBody, setBuilderBody] = useState("");

  const dispatch = useDispatch();
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  const [showPlans, setShowPlans] = useState(false);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActionData) && props.selectedActionData.id) {
      setLoading(true);
      dispatch(
        getEmailNotificationsByIdRequest({ id: props.selectedActionData.id }, (data) => {
          if (data.success) {
            const emailData = data.data;
            setToEmail(emailData.recipients);
            setCcEmail(emailData.ccRecipients);
            setBccEmail(emailData.bccRecipients);
            setDefaultBody(emailData.content);
            if (emailData.editorType === "unlayer") {
              setBuilderBody(emailData.content);
            } else {
              setBody(emailData.content);
            }
            setEditorType(emailData.editorType);
            setSubject(emailData.subject);
            setName(emailData.name);
            setShowcc(!_.isEmpty(emailData.ccRecipients));
            setShowBcc(!_.isEmpty(emailData.bccRecipients));

            if (emailData.defaultVariableValues) setDefaultVariableValues(emailData.defaultVariableValues);

            if (emailData.communicationType) {
              setCommType(emailData.communicationType);
            } else {
              setCommType("internal");
            }

            if (emailData.fromEmail) {
              setFromEmail(emailData.fromEmail);
            } else {
              setFromEmail([]);
            }
          }
          setLoading(false);
        }),
      );
    }
  }, [dispatch, props.selectedActionData, props.isEdit]);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onKeyDown = (event, type) => {
    if (event.key === "Enter") {
      if (type === "cc" && validateEmail(ccInput)) {
        setCcEmail([...ccEmail, ccInput]);
        setCcInput("");
      } else if (type === "bcc" && validateEmail(bccInput)) {
        setBccEmail([...bccEmail, bccInput]);
        setBccInput("");
      }
    }
  };
  const onBlur = (type) => {
    if (type === "cc") {
      if (validateEmail(ccInput)) {
        setCcEmail([...ccEmail, ccInput]);
      }
      setCcInput("");
    } else if (type === "bcc") {
      if (validateEmail(bccInput)) {
        setBccEmail([...bccEmail, bccInput]);
      }
      setBccInput("");
    }
  };
  const removeEmail = (type, email) => {
    if (type === "cc") {
      const newEmails = ccEmail.filter((each) => each !== email);
      setCcEmail(newEmails);
    }
    if (type === "bcc") {
      const newEmails = bccEmail.filter((each) => each !== email);
      setBccEmail(newEmails);
    }
  };
  const validate = () => {
    if (_.isEmpty(name)) {
      return "Name cannot be empty!";
    } else if (_.isEmpty(fromEmail)) {
      return "From email cannot be empty!";
    } else if (commType !== "external" && _.isEmpty(toEmail)) {
      return "To recipient cannot be empty!";
    } else if (_.isEmpty(subject)) {
      return "Subject cannot be empty!";
    } else if (editorType === "unlayer" && !builderBody) {
      return "Email body can't be empty!";
    } else if (editorType !== "unlayer" && !body) {
      return "Email body can't be empty!";
    }
    return "";
  };

  const handleSave = () => {
    const _validate = validate();
    if (_validate) {
      return dispatchSnackbarError(_validate);
    }
    const data = {
      name: name,
      module: {
        name: props.moduleName,
      },
      subject: subject,
      content: editorType === "unlayer" ? builderBody : body,
      recipients: commType === "external" ? [] : toEmail,
      fromEmail: fromEmail,
      ccRecipients: ccEmail,
      bccRecipients: bccEmail,
      editorType: editorType,
      communicationType: commType,
      defaultVariableValues: defaultVariableValues,
    };

    if (props.isEdit) props.handleEditSave(props.selectedActionData.id, data);
    else props.handleCreateSave(data);
  };
  const onSignitureSelection = (content) => {
    if (body.includes(`saino-body`)) {
      const div = document.createElement("div");
      div.innerHTML = body;
      const elements = div.querySelector("#saino-body");
      const signatureBlock = `<div><div id="saino-body"><div>${elements.innerHTML}</div></div><div id="saino-signature">${content.signature}</div></div>`;
      setDefaultBody(signatureBlock);
      setBody(signatureBlock);
    } else if (body && !body.includes("saino-signature") && !body.includes(`saino-body`)) {
      const signatureBlock = `<div><div id="saino-body"><div>${body}<br/></div></div><div id="saino-signature">${content.signature}</div></div>`;
      setDefaultBody(signatureBlock);
      setBody(signatureBlock);
    } else {
      const signatureBlock = `<div><div id="saino-body"><div><br/><br/></div></div><div id="saino-signature">${content.signature}</div></div>`;
      setDefaultBody(signatureBlock);
      setBody(signatureBlock);
    }
  };
  const addVariable = (item, key, variable) => {
    window.tinyMCE.execCommand("mceInsertContent", false, variable);
  };

  const onTemplateChoose = (data) => {
    if (data.editorType === "unlayer") {
      setBuilderBody(data.content);
    } else {
      setDefaultBody(data.content);
      setBody(data.content);
      setBuilderBody("");
    }
    setEditorType(data.editorType);
    setShowTemplateManager(false);
  };

  const cancelSelectTemplate = () => {
    setShowTemplateManager(false);
  };

  if (loading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center h-vh-162px">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.EMAIL_TEMPLATES) {
    return (
      <>
        <UpgradePlanPage
          isEnterprise={accountBillingStore.data.selectedPlan==="enterprise"}
          userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          onUpgradeClick={() => setShowPlans(true)}
          type={"Two-way Email Sync"}
          planName={"business plan"}
        />
        {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
      </>
    );
  } else if (_.isEmpty(syncSettingsStore.data) && !syncSettingsStore.loading) {
    return <CustomEmptyPage page="connectEmail" />;
  }
  return (
    <Form.Group id="automation-forms-email-notification-form" className="mb-0">
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Choose who you want to send email notifications to.</Form.Label>
        <RadioSelectorWithTooltip options={commTypeOptions} value={commType} setValue={setCommType} />
      </Form.Group>
      <div className="saino-email-composer-container">
        <div className=" composor-editor-header">New Email</div>
        <div className="d-block">
          <div className="border-bottom">
            <input maxLength={InputLimit.TEXT} type={"text"} value={name} onChange={(e) => setName(e.target.value)} className="form-control border-0 px-8 min-h-45px" name="compose_name" placeholder="Email Name" />
          </div>
          <div>
            <NylasFromEmail value={fromEmail} setValue={setFromEmail} typeOfSelection={"single"} isRequired={true} />
          </div>
          {commType !== "external" && <ToFieldTagInputBoxWithDropdown title={"To"} validEmails={toEmail} setInputChange={setToEmail} showcc={showcc} showBcc={showBcc} setShowcc={setShowcc} setShowBcc={setShowBcc} />}
          {showcc && (
            <TagInputBox
              title={"Cc"}
              type={"cc"}
              validEmails={ccEmail}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              inputValue={ccInput}
              setInputChange={setCcInput}
              removeEmail={removeEmail}
              onRemoveField={() => {
                setShowcc(false);
                setCcEmail([]);
                setCcInput("");
              }}
            />
          )}
          {showBcc && (
            <TagInputBox
              title={"Bcc"}
              type={"bcc"}
              validEmails={bccEmail}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              inputValue={bccInput}
              setInputChange={setBccInput}
              removeEmail={removeEmail}
              onRemoveField={() => {
                setShowBcc(false);
                setBccEmail([]);
                setBccInput("");
              }}
            />
          )}
          <div className="border-bottom">
            <input maxLength={InputLimit.TEXT} type={"text"} value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control border-0 px-8 min-h-45px" name="compose_subject" placeholder="Subject" />
          </div>
          <div className="h-50vh composor-editor-wrapper border-top-0 border-left-0 border-right-0">
            {editorType === "unlayer" ? (
              <BuilderComposer handleEmailBuilderEdit={() => setShowTemplateManager(true)} content={builderBody} />
            ) : (
              <RichTextEditor defaultValue={defaultBody} value={body} onChange={(c) => setBody(c)} toolbarLocation={"bottom"} />
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between py-2 px-4 border-top">
            <div className="d-flex align-items-center gap-3">
              <GreenGradientButton size="sm" onClick={() => handleSave()}>
                {"Save"}
              </GreenGradientButton>
              {editorType !== "unlayer" && <EmailSignatureDropdown setCurrentSignature={onSignitureSelection} />}
            </div>
            <div className="d-flex gap-20px align-self-flex-end">
              {editorType !== "unlayer" && (
                <div className="btn btn-color-primary btn-active-color-primary m-0 px-0" onClick={() => setShowVariableOptions(true)}>
                  Variables
                </div>
              )}
              <div onClick={(e) => e.stopPropagation()}>
                <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => setShowTemplateManager(true)}>
                  Template
                </button>
                <TemplateManager show={showTemplateManager} channel={"email"} category={"CRM"} showChoose={true} onCloseClick={(data) => onTemplateChoose(data)} handleClose={cancelSelectTemplate} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <VariableOptionsPro show={showVariableOptions} setShow={setShowVariableOptions} addVariable={addVariable} defaultVariableValues={defaultVariableValues} setDefaultVariableValues={setDefaultVariableValues} />
      </div>
    </Form.Group>
  );
}
