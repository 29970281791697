import { getCadenceList, getCadenceById, editCadence, createCadence, deleteCadenceById, updateCadenceStatus } from "../types/cadence.types";

export const getCadenceListRequest = (payload, cb) => ({
  type: getCadenceList.GET_CADENCE_LIST_REQUEST,
  payload,
  cb,
});
export const getCadenceListSuccess = (payload) => ({
  type: getCadenceList.GET_CADENCE_LIST_SUCCESS,
  payload,
});
export const getCadenceListError = (payload) => ({
  type: getCadenceList.GET_CADENCE_LIST_ERROR,
  payload,
});

export const getCadenceByIdRequest = (payload, cb) => ({
  type: getCadenceById.GET_CADENCE_BY_ID_REQUEST,
  payload,
  cb,
});
export const getCadenceByIdSuccess = (payload) => ({
  type: getCadenceById.GET_CADENCE_BY_ID_SUCCESS,
  payload,
});
export const getCadenceByIdError = (payload) => ({
  type: getCadenceById.GET_CADENCE_BY_ID_ERROR,
  payload,
});
export const getCadenceByIdReset = (payload) => ({
  type: getCadenceById.GET_CADENCE_BY_ID_RESET,
  payload,
});

export const editCadenceRequest = (payload, cb) => ({
  type: editCadence.EDIT_CADENCE_REQUEST,
  payload,
  cb,
});
export const editCadenceSuccess = (payload) => ({
  type: editCadence.EDIT_CADENCE_SUCCESS,
  payload,
});
export const editCadenceError = (payload) => ({
  type: editCadence.EDIT_CADENCE_ERROR,
  payload,
});

export const updateCadenceStatusRequest = (payload, cb) => ({
  type: updateCadenceStatus.UPDATE_CADENCE_STATUS_REQUEST,
  payload,
  cb,
});
export const updateCadenceStatusSuccess = (payload) => ({
  type: updateCadenceStatus.UPDATE_CADENCE_STATUS_SUCCESS,
  payload,
});
export const updateCadenceStatusError = (payload) => ({
  type: updateCadenceStatus.UPDATE_CADENCE_STATUS_ERROR,
  payload,
});

export const createCadenceRequest = (payload, cb) => ({
  type: createCadence.CREATE_CADENCE_REQUEST,
  payload,
  cb,
});
export const createCadenceSuccess = (payload) => ({
  type: createCadence.CREATE_CADENCE_SUCCESS,
  payload,
});
export const createCadenceError = (payload) => ({
  type: createCadence.CREATE_CADENCE_ERROR,
  payload,
});

export const deleteCadenceByIdRequest = (payload, cb) => ({
  type: deleteCadenceById.DELETE_CADENCE_BY_ID_REQUEST,
  payload,
  cb,
});
export const deleteCadenceByIdSuccess = (payload) => ({
  type: deleteCadenceById.DELETE_CADENCE_BY_ID_SUCCESS,
  payload,
});
export const deleteCadenceByIdError = (payload) => ({
  type: deleteCadenceById.DELETE_CADENCE_BY_ID_ERROR,
  payload,
});
