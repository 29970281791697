import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CustomTableWithoutAction } from "../../../../../components/custom-tables/table-without-actions";
import { ContentLoadingWithoutCard } from "../../../../../components/loading";
import { getSMSCampaignPricingRequest } from "../../../store/actions/campaign_sms_settings_actions";
import { Form } from "react-bootstrap";

const campaingTableFields = ["Route", "Country", "Currency", "Per SMS Price"];
const campaignTableMapping = {
  Route: "routeName",
  Country: "countryName",
  Currency: "currency",
  "Per SMS Price": "price",
};

export const campaignTableFieldDataType = {
  routeName: "TEXT",
  countryName: "TEXT",
  currency: "LIGHT_TAG",
  price: "TEXT",
};
export default function SMSPricing(props) {
  const [limit, setLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [showData, setShowData] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  const smsPricingStore = useSelector((store) => store.campaign.settings.pricing.sms);

  useEffect(() => {
    if (search) {
      const filtered = smsPricingStore.data.filter((each) => each.countryName.toLowerCase().includes(search));
      const newData = filtered.slice((pageNo - 1) * limit, pageNo * limit);
      setTotal(filtered.length);
      setShowData(newData.map(handleDataUpdate));
    } else {
      const newData = smsPricingStore.data.slice((pageNo - 1) * limit, pageNo * limit);
      setShowData(newData.map(handleDataUpdate));
      setTotal(smsPricingStore.count);
    }
  }, [smsPricingStore.data, pageNo, limit, search, smsPricingStore.count]);

  useEffect(() => {
    dispatch(getSMSCampaignPricingRequest());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setLimit(15);
    setPageNo(1);
  };

  const handleDataUpdate = (data) => {
    if (data.routeName === "otp") {
      data.routeName = "OTP";
    } else {
      data.routeName = _.capitalize(data.routeName);
    }
    return data;
  };

  if (smsPricingStore.loading) {
    return <ContentLoadingWithoutCard />;
  }
  return (
    <div className="pricing-container">
      <div className="pricing-header d-flex flex-stack align-items-center">
        <div>
          <h3 className="fw-bolder text-dark">{props.channel === "sms" ? "SMS" : _.capitalize(props.channel)} Pricing</h3>
        </div>
        <div className="w-40">
          <Form.Control placeholder="Search Country..." value={search} onChange={handleSearch} />
        </div>
      </div>
      <div className="pricing-content-container p-8">
        <div>
          <div className="card">
            <CustomTableWithoutAction
              tablehead={campaingTableFields}
              tablecontent={showData.map((each) => {
                each.currency = _.upperCase(each.currency);
                each.routeName = each.routeName === "Otp" ? "OTP" : each.routeName;
                return each;
              })}
              mappingTable={campaignTableMapping}
              dataType={campaignTableFieldDataType}
              pageNo={pageNo}
              limit={limit}
              count={total}
              setPageNo={setPageNo}
              setLimit={setLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
