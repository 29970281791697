import React from "react";
import _ from "lodash";
import SMSEditor from "../editors/sms.editor";
import CampaignEmailEditor from "../editors/email.editor";
import WhatsappEditor from "../editors/whatsapp.editor";

export default function MessageStep3(props) {
  return (
    <div className="message-step3-wrapper">
      {props.channel === "email" && (
        <CampaignEmailEditor
          templateId={props.templateId}
          config={props.config}
          attachments={props.attachments}
          template={props.template}
          setIsTemplate={props.setIsTemplate}
          setTemplateId={props.setTemplateId}
          setTemplate={props.setTemplate}
          defaultVariableValues={props.defaultVariableValues}
          setDefaultVariableValues={props.setDefaultVariableValues}
          uploadedfileVariables={props.uploadedfileVariables}
        />
      )}
      {props.channel === "sms" && (
        <SMSEditor
          showAIWizard={true}
          templateId={props.templateId}
          config={props.config}
          setConfig={props.setConfig}
          attachments={props.attachments}
          template={props.template}
          setIsTemplate={props.setIsTemplate}
          setTemplateId={props.setTemplateId}
          setTemplate={props.setTemplate}
          defaultVariableValues={props.defaultVariableValues}
          setDefaultVariableValues={props.setDefaultVariableValues}
          uploadedfileVariables={props.uploadedfileVariables}
        />
      )}
      {props.channel === "whatsapp" && (
        <WhatsappEditor
          config={props.config}
          setConfig={props.setConfig}
          setTemplateId={props.setTemplateId}
          templateId={props.templateId}
          setIsTemplate={props.setIsTemplate}
          setTemplate={props.setTemplate}
          selectedTemplateData={props.selectedWhatsappTemplateData}
          setSelectedTemplateData={props.setSelectedWhatsappTemplateData}
          templateVariable={!_.isEmpty(props.defaultVariableValues) ? props.defaultVariableValues : { header: [], body: [], image: "", video: "", document: "", requiredType: "" }}
          setTemplateVariable={props.setDefaultVariableValues}
          customVariables={props.uploadedfileVariables}
        />
      )}
    </div>
  );
}
