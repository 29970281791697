import React from "react";
import moment from "moment-timezone";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { customColorMapping } from "../../../../components/custom-tables/constants";
import OwnerDisplay from "../../../../components/table/displays/ownerDisplay";
import CustomPagination from "../../../../components/pagination/custom-pagination";
import _ from "lodash";
import StatusDisplay from "../../../../components/table/displays/status-display";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

const channelIconWrapper = {
  rcs: "bi bi-google",
  whatsapp: "bi bi-whatsapp",
  sms: "bi bi-chat-right-text",
  voice: "",
  email: "bi bi-envelope",
};

export function CampaignListTable(props) {
  const { tablehead, tablecontent, mappingTable, dataType } = props;

  const getCustomTagClass = (value) => {
    if (typeof value !== "string") return "dark";
    if (customColorMapping[value.toLowerCase()]) return customColorMapping[value.toLowerCase()];
    return "dark";
  };

  const getCapitalizeContent = (content) => {
    if (content && typeof content === "string") return _.capitalize(content);
    return content;
  };

  const renderContent = (content, column) => {
    if (dataType[mappingTable[column]] === "LIGHT_TAG") {
      return <span className={`badge badge-light-${getCustomTagClass(content[mappingTable[column]])}`}>{getCapitalizeContent(content[mappingTable[column]])}</span>;
    } else if (dataType[mappingTable[column]] === "CHANNEL_LIGHT_TAG") {
      return (
        <div className={`d-flex flex-center gap-2 badge badge-light-${getCustomTagClass(content[mappingTable[column]])}`}>
          <i className={`text-${getCustomTagClass(content[mappingTable[column]])} ${channelIconWrapper[content[mappingTable[column]].toLowerCase()]}`}></i>
          {getCapitalizeContent(content[mappingTable[column]])}
        </div>
      );
    } else if (dataType[mappingTable[column]] === "TAG") {
      return <StatusDisplay data={getCapitalizeContent(content[mappingTable[column]])} />;
    } else if (dataType[mappingTable[column]] === "OWNER") {
      return <OwnerDisplay value={content[mappingTable[column]]} />;
    } else if (dataType[mappingTable[column]] === "DATE") {
      return moment(content[mappingTable[column]]).format("MMM DD, h:mm a");
    } else if (dataType[mappingTable[column]] === "DATE_WITH_TIMEZONE") {
      if (content.timezone) {
        return moment(content[mappingTable[column]])
          .tz(content.timezone)
          .format("MMM DD, h:mm a");
      }
      return moment(content[mappingTable[column]]).format("MMM DD, h:mm a");
    } else if (dataType[mappingTable[column]] === "COST") {
      let currency = "$";
      if (content.currency) {
        currency = content.currency === "usd" ? "$" : "₹";
      }
      return (
        <div className="d-flex gap-2">
          <OverlayTrigger
            overlay={
              <Tooltip>
                Deducted: {currency}
                {_.round(content[mappingTable[column]], 4)}
              </Tooltip>
            }
          >
            <span className="badge badge-light-danger">
              {" "}
              {currency}
              {_.round(content[mappingTable[column]], 4)}
            </span>
          </OverlayTrigger>
          {content.refunded ? (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Refunded: {currency}
                  {_.round(content.refunded, 4)}
                </Tooltip>
              }
            >
              <span className="badge badge-light-success">
                {" "}
                {currency}
                {_.round(content.refunded, 4)}
              </span>
            </OverlayTrigger>
          ) : null}
        </div>
      );
    } else {
      return content[mappingTable[column]];
    }
  };

  const getActionMenu = (content) => {
    const options = [];
    if (content.status === "cancelled") {
      return [];
    } else if (content.status === "failed") {
      options.push({ value: "error", label: "Error Log" });
    } else if (content.status !== "completed" && content.timezone && content.scheduledDate && content.isScheduled) {
      if (
        moment(content.scheduledDate)
          .tz(content.timezone)
          .valueOf() >
        moment()
          .add(5, "minutes")
          .valueOf()
      ) {
        options.push({ value: "report", label: "View Detailed Report" });
        options.push({ value: "scheduled", label: "Reschedule" });
        options.push({ value: "cancel", label: "Cancel Campaign" });
        if (content.channel !== "whatsapp") options.push({ value: "repeat-campaign", label: "Repeat Campaign" });
      } else {
        options.push({ value: "anaytics", label: "Detailed Analytics" });
        options.push({ value: "report", label: "View Detailed Report" });
        options.push({ value: "download", label: "Download Report" });
        if (content.channel !== "whatsapp") options.push({ value: "repeat-campaign", label: "Repeat Campaign" });
      }
    } else {
      options.push({ value: "anaytics", label: "Detailed Analytics" });
      options.push({ value: "report", label: "View Detailed Report" });
      options.push({ value: "download", label: "Download Report" });
      if (content.channel !== "whatsapp") options.push({ value: "repeat-campaign", label: "Repeat Campaign" });
    }

    if (content.splitCampaign) {
      options.push({ value: "split-details", label: "Split Campaign Details" });
    }

    return options;
  };

  return (
    <div className="card card-custom">
      <div className="card-body py-3 px-3">
        <div className="table-responsive overflow-y-auto">
          <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
            <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
              <thead>
                <tr className="fw-bolder text-muted">
                  {tablehead.map((el, index) => (
                    <InlineStyled
                      as="th"
                      key={index}
                      styles={{
                        minWidth: `${100 / tablehead.length}%`,
                      }}
                      className={`opacity-1 bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap ${index === 0 ? "pl-2" : ""}`}
                    >
                      <div className="opacity-0-point-8 text-black d-flex align-items-center flex-row w-100 h-100 gap-5px text-nowrap">{el}</div>
                    </InlineStyled>
                  ))}
                  <InlineStyled
                    as="th"
                    styles={{
                      minWidth: `${100 / tablehead.length}%`,
                    }}
                    className="opacity-1 bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap"
                  >
                    <div className="opacity-0-point-8 text-black d-flex justify-content-center flex-row w-100 h-100 gap-5px text-nowrap">Action</div>
                  </InlineStyled>
                </tr>
              </thead>
              <tbody>
                {tablecontent.map((content, index) => (
                  <tr key={index} className="cursor-pointer">
                    {tablehead.map((column, index1) => (
                      <td onClick={() => props.tableRowClick(content)} key={index1} className={`text-nowrap ${index1 === 0 ? "pl-2" : ""}`}>
                        <div className="d-flex flex-row flex-stack w-250px-mx">
                          <span className={`data-container`}>{renderContent(content, column)}</span>
                        </div>
                      </td>
                    ))}
                    <td className="text-nowrap w-0px">
                      <div className="d-flex flex-row align-items-end justify-content-between">
                        <span className="data-flex-container d-flex justify-content-end hide-dropdonw-icon">
                          {content.status !== "cancelled" && (
                            <OverlayTrigger
                              placement="bottom"
                              trigger={"click"}
                              rootClose={true}
                              overlay={
                                <Popover>
                                  {getActionMenu(content).map((each, ind) => {
                                    return (
                                      <Dropdown.Item
                                        key={ind}
                                        onClick={(e) => {
                                          document.body.click();
                                          props.handleMenuSectionClick(each.value, content);
                                        }}
                                      >
                                        {each.label}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Popover>
                              }
                            >
                              <i class="bi bi-three-dots-vertical"></i>
                            </OverlayTrigger>
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PerfectScrollbar>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center px-3 gap-15px pt-7px">
          <p className="mt-5px mb-0 font-size-14px">
            Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
          </p>
          {CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), () => { }, props.limit, props.setLimit, props.count)}
        </div>
      </div>
    </div>
  );
}
