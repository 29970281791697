import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

//components
import { CustomSubHeader } from "../../../../components/subheader";
import { BlueGradientButton } from "../../../../components/button";
import ContentWrapper from "../../../Wrapper";

//actions
import { getConditionRequest } from "../actions/conditions_actions";
import { leadFieldsRequest, getLeadLostReasonsRequest } from "../../../leads/store/actions/actions";
import { peopleFieldsRequest } from "../../../customers/store/actions/actions";
import { companyFieldsRequest } from "../../../companies/store/actions/actions";
import { getDealListFieldsRequest } from "../../../deals/dealList/actions";
import { getLostReasonsRequest } from "../../../deals/dealPipeline/actions";
import * as workflowActions from "../actions/workflow_actions";
import { getListOfWorkflowTemplatesRequest, getListOfWorkflowTemplateByIdRequest } from "../actions/workflow_template_action";
import * as LeadActions from "../../../leads/store/actions/actions";
import * as ProductActions from "../../../products/store/actions/actions";
import * as CompanyActions from "../../../companies/store/actions/actions";
import * as insightActions from "../../../insights/actions";
import { peopleTagsRequest } from "../../../customers/store/actions/actions";
import { getDealTagRequest } from "../../../deals/dealPipeline/actions";
import * as TemplateActions from "../../../campaign/store/actions/campaign_templates_actions";

//steps
import ModuleStep from "./steps/module.step1";
import TriggerStep from "./steps/trigger.step2";
import ConditionStep from "./steps/condition.step3";
import ActionStep from "./steps/action.step4";

//styles
import "./styles.scss";
import _ from "lodash";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { actionTypeMapping, workflowFlowData } from "./constants";
import NameStep from "./steps/name.setp0";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export default function WorkflowBuilder(props) {
  const [workflowName, setWorkflowName] = useState("");
  const [moduleName, setModuleName] = useState("lead");
  const [executeWhen, setExecuteWhen] = useState("");
  const [conditionType, setConditionType] = useState("");
  const [criteriaConditions, setCriteriaConditions] = useState([]);
  const [criteriaActions, setCriteriaActions] = useState([]);
  const [pattern, setPattern] = useState("");
  const [workflowId, setWorkflowId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [triggerBy, setTriggerBy] = useState({ label: "Trigger to my account only", value: "user" });
  const workflowParentDivRef = useRef(null);

  const conditionsStore = useSelector((store) => store.workflowReducer.conditions);
  const workflowDetailsStore = useSelector((state) => state.workflowReducer.workflow.details);
  const singleSelectedTemplate = useSelector((state) => state.workflowReducer.template.selected);

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConditionRequest());
    dispatch(getDealListFieldsRequest({ skipIfDataPresent: true }));
    dispatch(peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));
    dispatch(getLeadLostReasonsRequest());
    dispatch(getLostReasonsRequest());
    dispatch(getListOfWorkflowTemplatesRequest());

    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));

    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(LeadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));
    dispatch(getDealTagRequest({ request: { type: "deal" }, skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (params.workflowId) {
      setWorkflowId(params.workflowId);
      dispatch(workflowActions.getListOfWorkflowsByIdRequest({ id: params.workflowId }));
    }
  }, [dispatch, params.workflowId]);

  useEffect(() => {
    if (!_.isEmpty(singleSelectedTemplate.data) && templateId) {
      const data = singleSelectedTemplate.data;
      if (!_.isEmpty(data)) {
        setWorkflowName(data.name);
        setModuleName(data.module.name);
        setExecuteWhen(data.executeWhen.type);

        if (data.conditions.length > 0) {
          if (data.conditions[0].hasOwnProperty("criteria")) {
            setConditionType("match");
            setCriteriaConditions(getConditionsFromCriteria(data.conditions[0].criteria, data.module.name));
          } else {
            setConditionType("all");
            setCriteriaConditions([]);
          }
          setCriteriaActions(data.conditions[0].actions);
        }
      } else {
        history.push("/automation/workflow/list");
      }
    }
  }, [history, singleSelectedTemplate, templateId]);

  useEffect(() => {
    if (params.templateId) {
      dispatch(getListOfWorkflowTemplateByIdRequest({ id: params.templateId }));
      setTemplateId(params.templateId);
    }
  }, [dispatch, params.templateId]);

  useEffect(() => {
    if (workflowId && !_.isEmpty(workflowDetailsStore.data)) {
      setWorkflowName(workflowDetailsStore.data.name);
      setModuleName(workflowDetailsStore.data.module.name);
      setExecuteWhen(workflowDetailsStore.data.executeWhen.type);

      if (workflowDetailsStore.data.conditions.length > 0) {
        setConditionType(workflowDetailsStore.data.conditions[0].criteria.group.length === 0 ? "all" : "match");
        setCriteriaConditions(getConditionsFromCriteria(workflowDetailsStore.data.conditions[0].criteria));
        setCriteriaActions(workflowDetailsStore.data.conditions[0].actions);
      }

      if (workflowDetailsStore.data.triggerBy && workflowDetailsStore.data.triggerBy === "user") {
        setTriggerBy({ label: "Trigger to my account only", value: "user" });
      }
      if (workflowDetailsStore.data.triggerBy && workflowDetailsStore.data.triggerBy === "all") {
        setTriggerBy({ label: "Trigger to all users in the company", value: "all" });
      } else {
        setTriggerBy({ label: "Trigger to my account only", value: "user" });
      }
    }
  }, [workflowId, workflowDetailsStore.data]);

  const getCriteriaOfConditions = () => {
    if (conditionType === "all" || criteriaConditions.length === 0) return { groupOperator: "and", group: [] };
    let criteriaObj = {},
      noOfOrs = 0,
      noOfAnds = 0;

    criteriaConditions.forEach((each, index) => {
      if (index + 1 !== criteriaConditions.length) {
        if (each.operator === "or") noOfOrs = noOfOrs + 1;
        else noOfAnds = noOfAnds + 1;
      }
    });

    if (noOfOrs === 0) {
      return { groupOperator: "and", group: criteriaConditions };
    } else if (noOfAnds === 0) {
      return { groupOperator: "or", group: criteriaConditions };
    }

    for (let i = 0; i < criteriaConditions.length; i++) {
      if (i + 1 !== criteriaConditions.length) {
        if (_.isEmpty(criteriaObj)) {
          criteriaObj = {
            groupOperator: criteriaConditions[i].operator === "or" ? "or" : "and",
            group: [criteriaConditions[i], criteriaConditions[i + 1]],
          };
        } else {
          criteriaObj = {
            groupOperator: criteriaConditions[i].operator === "or" ? "or" : "and",
            group: [criteriaObj, criteriaConditions[i + 1]],
          };
        }
      }
    }
    return criteriaObj;
  };
  const getConditionsFromCriteria = (criteria) => {
    const condition = [];

    const getLastCondtions = (group) => {
      group.forEach((eachGroup) => {
        if (eachGroup.hasOwnProperty("groupOperator") && eachGroup.hasOwnProperty("group")) {
          getLastCondtions(eachGroup.group);
        } else {
          condition.push(eachGroup);
        }
      });
    };
    if (criteria.hasOwnProperty("groupOperator") && criteria.hasOwnProperty("group")) {
      getLastCondtions(criteria.group);
    } else if (criteria.hasOwnProperty("field")) {
      return [criteria];
    }
    return condition;
  };

  const scrollToBottom = () => {
    workflowParentDivRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const validate = () => {
    if (_.isEmpty(workflowName)) {
      return "Workflow name can't be empty!";
    }
    if (_.isEmpty(moduleName)) {
      return "Please select module!";
    }
    if (_.isEmpty(executeWhen)) {
      return "Please select trigger option!";
    }
    if (conditionType !== "all") {
      for (let i = 0; i < criteriaConditions.length; i++) {
        if (_.isEmpty(criteriaConditions[i].comparator) || _.isEmpty(criteriaConditions[i].field)) {
          return "Condition fields cannot be empty";
        }
      }
    }
    if (criteriaActions.length === 0) {
      return "Actions cannot be empty!";
    }
    if (criteriaActions.length > 0) {
      for (let i = 0; i < criteriaActions.length; i++) {
        if (actionTypeMapping.editNeeded.includes(criteriaActions[i].type) && !criteriaActions[i].hasOwnProperty(criteriaActions[i].type)) {
          return "Some of actions missing details. Please update actions with details.";
        }
      }
    }
    return "";
  };

  const handleSave = () => {
    const _validate = validate();
    if (_validate) {
      return dispatchSnackbarError(_validate);
    }
    const criteria = getCriteriaOfConditions();
    const data = {
      workflowType: "application",
      name: workflowName,
      description: "",
      module: { name: moduleName },
      executeWhen: { type: executeWhen },
      template: { fromTemplate: false },
      conditions: [{ criteria: criteria, actions: criteriaActions }],
      triggerBy: triggerBy.value,
    };
    if (workflowId) {
      dispatch(workflowActions.editWorkflowRequest({ id: workflowId, data, isSoftUpdate: false }));
    } else {
      dispatch(
        workflowActions.createWorkflowRequest(data, (data) => {
          if (data.success && data.data && data.data.id) {
            dispatchSnackbarSuccess(data.message);
            history.push("/automation/workflow/edit/" + data.data.id);
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    }
  };

  const onModuleNameChange = (name) => {
    if (criteriaActions.length > 0 || criteriaConditions.length > 0) {
      return dispatchSnackbarError("Delete actions and conditions to change module!!!");
    }
    setModuleName(name);
  };
  const softUpdateWorkflowData = () => {
    if (workflowId) {
      const _validate = validate();
      if (_validate) return;
      const criteria = getCriteriaOfConditions();
      const data = {
        workflowType: "application",
        name: workflowName,
        description: "",
        module: { name: moduleName },
        executeWhen: { type: executeWhen },
        template: { fromTemplate: false },
        conditions: [{ criteria: criteria, actions: criteriaActions }],
      };
      dispatch(workflowActions.editWorkflowRequest({ id: workflowId, data, isSoftUpdate: true }));
    }
  };

  const isDoneStage = (type) => {
    if (type === "name") {
      return true;
    }
    if (type === "module" && !_.isEmpty(moduleName)) {
      return true;
    }
    if (type === "trigger" && !_.isEmpty(executeWhen)) {
      return true;
    }
    if (type === "condition" && !_.isEmpty(conditionType)) {
      return true;
    }
    if (type === "action") {
      return false;
    }
    return false;
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div onClick={() => history.push("/automation/workflow/list")} className="d-flex align-items-center cursor-pointer">
              <i className="bi bi-arrow-left text-333"></i>
              <span className="ml-2"> Back to workflow list</span>
            </div>

            <div className="d-flex gap-10px">
              <Button onClick={handleCancel} variant="secondary" size="sm">
                Cancel
              </Button>
              <BlueGradientButton variant="success" size="sm" disabled={false} onClick={() => handleSave()}>
                Save
              </BlueGradientButton>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      {workflowDetailsStore.isLoading ? (
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="p-4 h-vh-161px overflow-auto">
          <div className="workflow-builder-container m-auto w-980px-mx">
            <div className="timeline">
              {workflowFlowData.map((each, index) => {
                return (
                  <div key={index} className="timeline-item">
                    <InlineStyled className="timeline-line w-30px custom-timeline-line" styles={{ bg: isDoneStage(each.type) ? each.color : "#EAECEE" }}></InlineStyled>
                    <div className="timeline-icon me-4">
                      <InlineStyled className="custom-symbol-label" styles={{ bg: each.background }}>
                        {each.icon}
                      </InlineStyled>
                    </div>
                    <div className="timeline-content mb-10">
                      {each.type === "name" && <NameStep workflowName={workflowName} setWorkflowName={setWorkflowName} triggerBy={triggerBy} setTriggerBy={setTriggerBy} />}
                      {each.type === "module" && <ModuleStep moduleName={moduleName} setModuleName={(name) => onModuleNameChange(name)} />}
                      {each.type === "trigger" && <TriggerStep executeWhen={executeWhen} setExecuteWhen={setExecuteWhen} />}
                      {each.type === "condition" && (
                        <ConditionStep
                          moduleName={moduleName}
                          conditionsStore={conditionsStore}
                          criteriaConditions={criteriaConditions}
                          setCriteriaConditions={setCriteriaConditions}
                          pattern={pattern}
                          setPattern={setPattern}
                          conditionType={conditionType}
                          setConditionType={setConditionType}
                          scrollToBottom={scrollToBottom}
                        />
                      )}
                      {each.type === "action" && <ActionStep moduleName={moduleName} criteriaActions={criteriaActions} setCriteriaActions={setCriteriaActions} softUpdateWorkflowData={softUpdateWorkflowData} />}
                    </div>
                  </div>
                );
              })}
            </div>
            <div ref={workflowParentDivRef} className="pb-20" />
          </div>
        </div>
      )}
    </ContentWrapper>
  );
}
