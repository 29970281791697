import React from "react";

const onSearchItemClick = (basePath, type, id, history) => {
  if (!history || !type || !id || !basePath) return;
  if (type === "company") {
    history.push({ pathname: `${basePath}/company/${id}`, state: { id: id } });
  } else if (type === "deal") {
    history.push({ pathname: `${basePath}/deal/${id}`, state: { id: id } });
  } else if (type === "people") {
    history.push({ pathname: `${basePath}/people/${id}`, state: { id: id } });
  } else {
    history.push({ pathname: `${basePath}/lead/${id}`, state: { id: id } });
  }
};

export const renderLinkedData = (item, basePath, history) => {
  if (item.lead && typeof item.lead === "object") {
    return (
      <div className="linked-data-timeline-wrpr font-weight-light" onClick={() => onSearchItemClick(basePath, "lead", item.lead._id, history)}>
        Lead - <span className="text-hover-primary">{item.lead.name}</span>
      </div>
    );
  } else if (item.people && typeof item.people === "object") {
    return (
      <div className="linked-data-timeline-wrpr font-weight-light" onClick={() => onSearchItemClick(basePath, "people", item.people._id, history)}>
        People - <span className="text-hover-primary"> {item.people.name}</span>
      </div>
    );
  } else if (item.deal && typeof item.deal === "object") {
    return (
      <div className="linked-data-timeline-wrpr font-weight-light" onClick={() => onSearchItemClick(basePath, "deal", item.deal._id, history)}>
        Deal - <span className="text-hover-primary"> {item.deal.title}</span>
      </div>
    );
  }
  return <></>;
};

export const renderLinkedData2 = (item, basePath, history) => {
  if (item.leadId && typeof item.leadId === "object" && item.dealId && typeof item.dealId === "object") {
    return (
      <>
        {" "}
        <span className="linked-data-timeline-wrpr" onClick={() => onSearchItemClick(basePath, "deal", item.dealId._id, history)}>
          <strong className="text-3f4254">Deal: </strong> {item.dealId.title}
        </span>
        <span className="linked-data-timeline-wrpr ml-2" onClick={() => onSearchItemClick(basePath, "lead", item.leadId._id, history)}>
          <strong className="text-3f4254">Lead: </strong> {item.leadId.name}
        </span>
      </>
    );
  }
  if (item.peopleId && typeof item.peopleId === "object" && item.dealId && typeof item.dealId === "object") {
    return (
      <>
        {" "}
        <span className="linked-data-timeline-wrpr" onClick={() => onSearchItemClick(basePath, "deal", item.dealId._id, history)}>
          <strong className="text-3f4254">Deal: </strong> {item.dealId.title}
        </span>
        <span className="linked-data-timeline-wrpr ml-2" onClick={() => onSearchItemClick(basePath, "people", item.peopleId._id, history)}>
          <strong className="text-3f4254">People: </strong> {item.peopleId.name}
        </span>
      </>
    );
  } else if (item.leadId && typeof item.leadId === "object") {
    return (
      <span className="linked-data-timeline-wrpr" onClick={() => onSearchItemClick(basePath, "lead", item.leadId._id, history)}>
        <strong className="text-3f4254">Lead: </strong> {item.leadId.name}
      </span>
    );
  } else if (item.peopleId && typeof item.peopleId === "object") {
    return (
      <span className="linked-data-timeline-wrpr" onClick={() => onSearchItemClick(basePath, "people", item.peopleId._id, history)}>
        <strong className="text-3f4254">People: </strong> {item.peopleId.name}
      </span>
    );
  } else if (item.dealId && typeof item.dealId === "object") {
    return (
      <span className="linked-data-timeline-wrpr" onClick={() => onSearchItemClick(basePath, "deal", item.dealId._id, history)}>
        <strong className="text-3f4254">Deal: </strong> {item.dealId.title}
      </span>
    );
  }
  return <></>;
};

export const renderLinkedData3 = (item, basePath, history) => {
  if (item.leadId && typeof item.leadId === "object") {
    return (
      <div className="linked-data-timeline-wrpr font-weight-light" onClick={() => onSearchItemClick(basePath, "lead", item.leadId._id, history)}>
        Lead - <span className="text-hover-primary"> {item.leadId.name}</span>
      </div>
    );
  } else if (item.peopleId && typeof item.peopleId === "object") {
    return (
      <div className="linked-data-timeline-wrpr font-weight-light" onClick={() => onSearchItemClick(basePath, "people", item.peopleId._id, history)}>
        People - <span className="text-hover-primary">{item.peopleId.name}</span>
      </div>
    );
  } else if (item.dealId && typeof item.dealId === "object") {
    return (
      <div className="linked-data-timeline-wrpr font-weight-light" onClick={() => onSearchItemClick(basePath, "deal", item.dealId._id, history)}>
        Deal - <span className="text-hover-primary"> {item.dealId.title}</span>
      </div>
    );
  }
  return <></>;
};

export const isSameModuleAsUrl = (basePath, moduleName) => {
  if (basePath === "/prospects/companies" && moduleName === "company") {
    return true;
  } else if (basePath === "/prospects/people" && moduleName === "people") {
    return true;
  } else if (basePath === "/prospects/leads/junk" && moduleName === "lead") {
    return true;
  } else if (basePath === "/prospects/leads/active" && moduleName === "lead") {
    return true;
  } else if (basePath.includes("/deal/pipeline") && moduleName === "deal") {
    return true;
  } else if (basePath.includes("/deal/deal-list") && moduleName === "deal") {
    return true;
  } else {
    return false;
  }
};
