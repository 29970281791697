import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "../../../../../components/button/icon-action-buttons";
import { CustomSubHeader } from "../../../../../components/subheader";
import { SubheaderNav } from "../../../../../components/subheader/subheaderNav";

export default function CadenceSubHeader(props) {
  const history = useHistory();
  const links = [
    { name: "Builder", to: `/automation/cadence/edit/${props.cadenceId}` },
    { name: "Subscribers", to: `/automation/cadence/subscribers/${props.cadenceId}` },
    { name: "Stepwise Analytics", to: `/automation/cadence/analytics-stepwise/${props.cadenceId}` },
    { name: "Summary", to: `/automation/cadence/summary/${props.cadenceId}` },
  ];

  return (
    <CustomSubHeader>
      <div className="d-flex justify-content-between align-items-center w-100">
        {props.cadenceId ? (
          <div className="d-flex align-items-center gap-2">
            <IconButton onClick={() => history.push("/automation/cadence/list")} tooltip={"Back To Cadence List"}>
              <i className="bi bi-arrow-left text-black" />
            </IconButton>
            <SubheaderNav links={links} active={props.tabName} />
          </div>
        ) : (
          <IconButton onClick={() => history.push("/automation/cadence/list")} tooltip={"Back To Cadence List"}>
            <i className="bi bi-arrow-left text-black" />
          </IconButton>
        )}
        <div className="d-flex align-items-center">{props.children}</div>
      </div>
    </CustomSubHeader>
  );
}
