import React from "react";
import _ from "lodash";
import { DeleteIconButton, EditIconButton } from "../../../../../../components/button/icon-action-buttons";

export default function TaskViewCard(props) {
  return (
    <div className="template-content-block w-100 d-flex flex-column">
      <div className={`card px-4 py-2 w-100 mt-4 ${props.step.isEnabled ? "" : "bg-light-light"}`}>
        <div className=" d-flex w-100 justify-content-between align-items-center">
          <div className="w-40">
            {_.capitalize(props.step.channelType)} - {_.capitalize(props.step.name)}
          </div>
          <div className="w-40 step-actions d-flex justify-content-end p-0 m-0">
            <EditIconButton onClick={() => props.onEditClick(props.index, props.step)} />
            <DeleteIconButton onClick={() => props.onDeleteClick(props.index)} />
          </div>
        </div>
      </div>
      <div className="d-flex w-100 gap-4 mt-2 mb-8"></div>
    </div>
  );
}
