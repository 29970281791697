import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BlueGradientButton } from "../../../../components/button";
import { CustomScrollableModal } from "../../../../components/modal";
import Select from "react-select";
import { Form, Spinner, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GoogleImage from "../../../../images/google.svg";
import MicrosoftImage from "../../../../images/microsoft-5.svg";
import GoogleSigninImage from "../../../../images/btn_google_signin_light_normal_web.png";

import * as syncActions from "../../actions/sync.actions";
import * as settingActions from "../../actions/settings.actions";
import { UpgradePlanPage } from "../../../../components/empty_page";
import SubscriptionPlanModal from "../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import { InputLimit } from "../../../../constants";
import "../../styles.scss";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import GoogleEmailConnect from "../../../../images/gif/google-email-connect.gif";

// const options = [
//     { value: 'call', label: 'Call' },
//     { value: 'meeting', label: 'Meeting' },
//     { value: 'task', label: 'Task' },
//     { value: 'deadline', label: 'Deadline' },
//     { value: 'email', label: 'Email' },
//     { value: 'lunch', label: 'Lunch' }
// ];

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

let timer;

export default function CalendarSync(props) {
  const [showCalendarSyncTypes, setShowCalendarSyncTypes] = useState(false);
  const [openNylasSyncModal, setOpenNylasSyncModal] = useState(false);
  const [userConnectEmail, setUserConnectEmail] = useState("");
  const [activities, setActivities] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModelTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [showPlans, setShowPlans] = useState(false);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  //redux store details
  const calendarStore = useSelector((store) => store.sync.calendar.data);
  const calendarList = useSelector((store) => store.sync.calendar.calenderList);
  const loading = useSelector((store) => store.sync.calendar.loading);
  const activityStore = useSelector((store) => store.ProfileSettings.getActivity);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(syncActions.getCalendarSyncDetailsRequest());
    dispatch(syncActions.getListOfCalendersRequest());
    dispatch(settingActions.getActivityRequest(() => { }));
  }, [dispatch]);

  useEffect(() => {
    if (calendarStore && !_.isEmpty(calendarStore) && calendarList.length === 0) {
      clearInterval(timer);
      timer = setInterval(() => dispatch(syncActions.getListOfCalendersRequest()), 2000);
    }
  }, [calendarStore, dispatch, calendarList]);

  useEffect(() => {
    if (calendarList.length > 0) {
      clearInterval(timer);
    }
  }, [calendarList]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (activityStore && activityStore.data && activityStore.data.length > 0) {
      setActivities(activityStore.data.filter((a) => !a.isDeactivated));
      setActivitiesOptions(
        activityStore.data
          .filter((a) => !a.isDeactivated)
          .map((each) => {
            return { value: each._id, label: each.name };
          }),
      );
    }
  }, [activityStore]);

  useEffect(() => {
    if (userProfile && userProfile.data && userProfile.data.email) {
      setUserConnectEmail(userProfile.data.email);
    }
  }, [userProfile]);

  const onDeleteButtonClick = () => {
    dispatch(syncActions.deleteCalendarSyncAccountRequest());
  };
  const onDisconnectButtonClick = () => {
    dispatch(syncActions.disconnectCalendarSyncAccountRequest());
  };
  const onReconnectButtonClick = () => {
    dispatch(syncActions.reconnectCalendarSyncAccountRequest());
  };
  const onSelectCalendarChange = (data) => {
    dispatch(
      syncActions.updateCalendarSyncDetailsRequest({
        _id: calendarStore._id,
        calendarId: data.value,
      }),
    );
  };
  const onSyncTypeClick = (value) => {
    dispatch(
      syncActions.updateCalendarSyncDetailsRequest({
        _id: calendarStore._id,
        syncType: value,
      }),
    );
  };
  const onCalendarEventChange = (e) => {
    dispatch(
      syncActions.updateCalendarSyncDetailsRequest({
        _id: calendarStore._id,
        saveEvent: e.value,
      }),
    );
  };
  // const onExternalCalendarSettings = (value) => {
  //     dispatch(syncActions.updateCalendarSyncDetailsRequest({
  //         _id: calendarStore._id,
  //         showInExtCalendar: value
  //     }))
  // };
  const onActivityChange = (e, type) => {
    const synced = calendarStore.savedSyncActivities;
    if (e.target.checked) {
      const ids = Array.isArray(synced) ? [...synced] : [];
      ids.push(type);
      dispatch(
        syncActions.updateCalendarSyncDetailsRequest({
          _id: calendarStore._id,
          savedSyncActivities: ids,
        }),
      );
    } else {
      const ids = Array.isArray(synced) ? [...synced] : [];
      dispatch(
        syncActions.updateCalendarSyncDetailsRequest({
          _id: calendarStore._id,
          savedSyncActivities: ids.filter((a) => a !== type),
        }),
      );
    }
  };

  const onNylasSyncClick = () => {
    setOpenNylasSyncModal(true);
  };
  const onNylasSyncModalClose = () => {
    setOpenNylasSyncModal(false);
  };
  const onNylasSyncModalSave = () => {
    setOpenNylasSyncModal(false);
    dispatch(
      syncActions.hostedCalendarAuthRequest({ email: userConnectEmail }, (data) => {
        if (typeof data === "string") {
          window.location.href = `${data}`;
        } else if (typeof data === "object") {
          dispatchSnackbarError(data.message);
        }
      }),
    );
  };

  const getSelectOptions = () => {
    return calendarList && calendarList.length > 0
      ? calendarList.map((a) => {
        return { value: a.id, label: a.name };
      })
      : [];
  };
  const getSelectValue = (id) => {
    return getSelectOptions().filter((a) => a.value === id);
  };

  // const onAddNewAccountClick = () => {
  //     setShowCalendarSyncTypes(true)
  // }
  const onNylasEmailChange = (e) => {
    setUserConnectEmail(e.target.value);
  };
  const onsyncOptionsModalClose = () => {
    setShowCalendarSyncTypes(false);
  };
  const onGoogleButtonClick = () => { };
  const deactivateAccountModelProps = {
    show: showModal,
    title: modalTitle,
    handleClose: () => modalHandleClose(),
    handleAccept: () => modalHandleAccept(),
  };

  const modalHandleAccept = () => {
    if (modalType === "disconnect") {
      onDisconnectButtonClick();
    } else if (modalType === "reconnect") {
      onReconnectButtonClick();
    } else if (modalType === "delete") {
      onDeleteButtonClick();
    }
    modalHandleClose();
  };

  const modalHandleClose = () => {
    setShowModal(false);
    setModelTitle("");
    setModalMessage("");
    setModalType("");
  };

  // const onDisconnectModalOpenClick = () => {
  //   setShowModal(true);
  //   setModelTitle("Disconnect Calendar Account");
  //   setModalMessage("Are you sure you want to disconnect calendar account?");
  //   setModalType("disconnect");
  // };
  const onReconnectModalOpenClick = () => {
    setShowModal(true);
    setModelTitle("Reconnect Calendar Account");
    setModalMessage("Are you sure you want to reconnect calendar account?");
    setModalType("reconnect");
  };
  const onDeleteModalOpenClick = () => {
    setShowModal(true);
    setModelTitle("Delete Calendar Account");
    setModalMessage("Are you sure you want to delete calendar account?");
    setModalType("delete");
  };

  return (
    <>
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.CALENDAR_SYNC ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Calendar sync"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : (
        <div>
          {_.isEmpty(calendarStore) ? (
            <CustomEmptyPage
              page={"calendarSync"}
              customButton={
                <div>
                  <span
                    onClick={() => {
                      onNylasSyncClick();
                      setIsGoogleSignIn(true);
                    }}
                    className="pr-2 cursor-pointer"
                  >
                    <img src={GoogleSigninImage} alt={"google signin"}></img>
                  </span>
                  <BlueGradientButton
                    onClick={() => {
                      onNylasSyncClick();
                      setIsGoogleSignIn(false);
                    }}
                    className="mr-8px"
                  >
                    + Other Account
                  </BlueGradientButton>
                </div>
              }
            />
          ) : (
            <div className="card mb-5 mb-xl-10 h-vh-160px">
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_connected_accounts"
                aria-expanded="true"
                aria-controls="kt_account_connected_accounts"
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Calendar Sync </h3>
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9 overflow-y-auto h-vh-220px-mx">
                  {loading ? (
                    <div className="w-100 d-flex justify-content-center align-items-center h-100">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : _.isEmpty(calendarStore) ? (
                    <div className="add-new-account-div">
                      <div className="add-new-account-wrapper">
                        <div className="add-new-account-text-div">
                          <div className="add-new-account-text">Add new account</div>
                          <div>The chosen email account will be synced to your calendar</div>
                        </div>
                        <div>
                          <span
                            onClick={() => {
                              onNylasSyncClick();
                              setIsGoogleSignIn(true);
                            }}
                            className="pr-2 cursor-pointer"
                          >
                            <img src={GoogleSigninImage} alt={"google signin"}></img>
                          </span>
                          <BlueGradientButton
                            onClick={() => {
                              onNylasSyncClick();
                              setIsGoogleSignIn(false);
                            }}
                            className="mr-8px"
                          >
                            {/* Nylas Sync */}+ Other Account
                          </BlueGradientButton>
                          {/* <BlueGradientButton onClick={() => onAddNewAccountClick()}>
                                                    Add new account
                                                </BlueGradientButton> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {!["running", "downloading"].includes(calendarStore.syncState) && (
                        <div className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                          We are encountering problems while synchronizing emails with your email service provider. Please delete your connection and re-authenticate the same to resolve.
                        </div>
                      )}
                      <div className="add-new-account-div">
                        <div className="add-new-account-wrapper">
                          <div className="d-flex align-items-center">
                            <img alt="Logo" src={calendarStore.nylasAccountData && calendarStore.nylasAccountData.provider === "gmail" ? GoogleImage : MicrosoftImage} className="h-30px me-4" />
                            <div className="add-new-account-text-div">
                              <div className="add-new-account-text">{calendarStore.syncedEmail}</div>
                              <div>{calendarStore.accountStatus === "active" ? "Activated" : calendarStore.accountStatus === "deactivated" ? "Deactivated" : calendarStore.accountStatus}</div>
                            </div>
                          </div>
                          {calendarStore.accountStatus === "active" ? (
                            <div>
                              {/* <button className="btn btn-outline btn-outline-dashed btn-outline-warning btn-active-light-warning" onClick={() => onDisconnectModalOpenClick()}>
                                Disconnect
                              </button> */}
                              <button className="btn btn-danger ml-2" onClick={() => onDeleteModalOpenClick()}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button className="btn btn-outline btn-outline-dashed btn-outline-info btn-active-light-info" onClick={() => onReconnectModalOpenClick()}>
                                Reconnect
                              </button>
                              <button className="btn btn-danger ml-2" onClick={() => onDeleteModalOpenClick()}>
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {calendarStore.accountStatus === "active" ? (
                        <div>
                          <Form>
                            <Form.Group id="settings-profile-platform-setting-calender-sync-date-selection">
                              <Form.Label aria-label="select-calender">Select calendar {calendarList.length === 0 && <span className="text-d99209">(Sync is on progress... wait until it get completed)</span>}</Form.Label>
                              <Select
                                options={getSelectOptions()}
                                value={calendarStore && calendarStore.calendarId ? getSelectValue(calendarStore.calendarId) : { value: "", label: "" }}
                                aria-label="Default select example"
                                styles={customStyles}
                                noOptionsMessage={() => null}
                                isValidNewOption={() => false}
                                onChange={(e) => onSelectCalendarChange(e)}
                              ></Select>
                            </Form.Group>
                            <Form.Group id="settings-profile-platform-setting-calender-sync-select-sync-type">
                              <Form.Label aria-label="select-sync-type" className="d-flex align-items-center gap-3">
                                Select sync type
                                <a href="https://salessimplify.com/help-center/calendar-sync" target={"_blank"} rel="noopener noreferrer" className="cursor-pointer text-hover-primary">
                                  <i class="bi bi-box-arrow-up-right"></i>
                                </a>
                              </Form.Label>
                              <label className="option">
                                <span className="option-control">
                                  <span className="radio">
                                    <input type="radio" name="m_option_1" value="two_way" checked={calendarStore && calendarStore.syncType === "two_way"} onClick={(e) => onSyncTypeClick("two_way")} />
                                    <span></span>
                                  </span>
                                </span>
                                <span className="option-label">
                                  <span className="option-head">
                                    <span className="option-title">Two-way Sync</span>
                                  </span>
                                  <span>
                                    <span className="option-body">SalesSimplify activities sync to calendar</span>
                                    <span className="option-body">Calendar events sync to SalesSimplify as activities, accessible to other users in your company</span>
                                  </span>
                                </span>
                              </label>
                              <label className="option">
                                <span className="option-control">
                                  <span className="radio">
                                    <input type="radio" name="m_option_1" value="one_way" checked={calendarStore && calendarStore.syncType === "one_way"} onClick={(e) => onSyncTypeClick("one_way")} />
                                    <span></span>
                                  </span>
                                </span>
                                <span className="option-label">
                                  <span className="option-head">
                                    <span className="option-title">One-way Sync</span>
                                  </span>
                                  <span>
                                    <span className="option-body">SalesSimplify activities sync to calendar</span>
                                    <span className="option-body">Only edits to SalesSimplify activities will sync back to SalesSimplify</span>
                                  </span>
                                </span>
                              </label>
                            </Form.Group>
                            <Form.Group id="settings-profile-platform-setting-calender-sync-calender-event-change">
                              <Form.Label aria-label="calender">{"All of your calendar events will synchronize to Sales Simplify with the name picked from here. Calendar > Sales Simplify."}</Form.Label>
                              <Select
                                options={activitiesOptions}
                                styles={customStyles}
                                noOptionsMessage={() => null}
                                isValidNewOption={() => false}
                                value={activitiesOptions.filter((a) => calendarStore && calendarStore.saveEvent === a.value)}
                                onChange={(e) => onCalendarEventChange(e)}
                              />
                            </Form.Group>
                            <Form.Group id="settings-profile-platform-setting-calender-sync-activity-checkbox">
                              <Form.Label aria-label="synchronize-the-event">{"All of your Sales Simplify events will synchronize to your calendar with the name picked from here. Sales Simplify > Calendar."}</Form.Label>
                              <div className="checkbox-list ml-20px">
                                {activities.map((each, index) => {
                                  return (
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        name="Checkboxes1"
                                        checked={calendarStore && calendarStore.savedSyncActivities && calendarStore.savedSyncActivities.includes(each._id)}
                                        onClick={(e) => onActivityChange(e, each._id)}
                                      />
                                      <span></span>
                                      <div className="calendar-activities-list">
                                        <i className={`bi ${each.symbName} calendar-activities-list-icon`}></i> {each.name}
                                      </div>
                                    </label>
                                  );
                                })}
                              </div>
                            </Form.Group>
                            {/* <Form.Group>
                                                            <Form.Label>Show contact and deal info in external calendar</Form.Label>
                                                            <div className="radio-list ml-20px" >
                                                                <label className="radio">
                                                                    <input type="radio" name="radios1" checked={calendarStore && !calendarStore.showInExtCalendar} onClick={(e) => onExternalCalendarSettings(false)} />
                                                                    <span></span>
                                                                    Don't show (recommended)
                                                                </label>
                                                                <label className="radio">
                                                                    <input type="radio" name="radios1" checked={calendarStore && calendarStore.showInExtCalendar} onClick={(e) => onExternalCalendarSettings(true)} />
                                                                    <span></span>
                                                                    Show contact and deal info
                                                                </label>
                                                            </div>
                                                        </Form.Group> */}
                          </Form>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <CustomScrollableModal
            modalProps={{
              show: openNylasSyncModal,
              handleClose: () => onNylasSyncModalClose(),
              title: "Connect Email Account",
              showButtons: true,
              buttonText: "Continue",
              showCloseButton: true,
              size: isGoogleSignIn ? "lg" : "md",
              handleAccept: () => onNylasSyncModalSave(),
            }}
          >
            <Form.Group className="px-4">
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control maxLength={InputLimit.EMAIL} type="email" value={userConnectEmail} placeholder="Enter your email address" onChange={(e) => onNylasEmailChange(e)} />
              </Form.Group>
              {isGoogleSignIn && (
                <Form.Group>
                  <Form.Label>
                    <strong>"Whitelist Sales Simplify app from Google Admin"</strong> In order to utilize Sales Simplify's 2-way synchronization feature, it is mandatory to whitelist the application from your Google
                    Admin Console, as per the guidelines recently set by Google.{" "}
                    <a target={"_blank"} rel="noopener noreferrer" href="https://salessimplify.com/help-center/authenticate-sales-simplify-for-email-calendar-sync">
                      Click here and whitelist
                    </a>
                  </Form.Label>
                  <img alt="" width={"100%"} src={GoogleEmailConnect} />
                </Form.Group>
              )}
            </Form.Group>
          </CustomScrollableModal>
          {showCalendarSyncTypes ? (
            <CustomScrollableModal
              modalProps={{
                show: showCalendarSyncTypes,
                handleClose: () => onsyncOptionsModalClose(),
                title: "Connect your account",
                showButtons: false,
                buttonText: "Save",
                showCloseButton: true,
                // handleAccept: () => onPipelineModalSave()
              }}
            >
              <div className="d-flex flex-column align-items-center">
                <button onClick={() => onGoogleButtonClick()} className="btn btn-light-primary btn-lg fw-bolder my-2 w-40">
                  <img alt="Logo" src={require("../../../../images/google.svg")} className="h-20px me-3" />
                  Google
                </button>
                <a href="https://saino.io" className="btn btn-light-primary btn-lg fw-bolder my-2 w-40">
                  <img alt="Logo" src={require("../../../../images/microsoft-5.svg")} className="h-20px me-3" />
                  Microsoft
                </a>
              </div>
            </CustomScrollableModal>
          ) : null}
          <Modal backdrop="static" show={deactivateAccountModelProps.show} onHide={deactivateAccountModelProps.handleClose} size={"md"}>
            <Modal.Header closeButton>
              <Modal.Title>{deactivateAccountModelProps.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={deactivateAccountModelProps.handleClose}>
                Close
              </Button>
              <Button variant={modalType === "disconnect" ? "warning" : modalType === "reconnect" ? "info" : "danger"} size="sm" onClick={deactivateAccountModelProps.handleAccept}>
                {modalType === "disconnect" ? "Disconnect" : modalType === "reconnect" ? "Reconnect" : "Delete"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}
