import { getDealActionById, editDealAction, createDealAction } from "../types";

export const getDealActionByIdRequest = (payload, cb) => ({
  type: getDealActionById.GET_DEAL_ACTION_BY_ID_REQUEST,
  payload,
  cb,
});
export const getDealActionByIdSuccess = (payload) => ({
  type: getDealActionById.GET_DEAL_ACTION_BY_ID_SUCCESS,
  payload,
});
export const getDealActionByIdError = (payload) => ({
  type: getDealActionById.GET_DEAL_ACTION_BY_ID_ERROR,
  payload,
});

export const editDealActionRequest = (payload, cb) => ({
  type: editDealAction.EDIT_DEAL_ACTION_REQUEST,
  payload,
  cb,
});
export const editDealActionSuccess = (payload) => ({
  type: editDealAction.EDIT_DEAL_ACTION_SUCCESS,
  payload,
});
export const editDealActionError = (payload) => ({
  type: editDealAction.EDIT_DEAL_ACTION_ERROR,
  payload,
});

export const createDealActionRequest = (payload, cb) => ({
  type: createDealAction.CREATE_DEAL_ACTION_REQUEST,
  payload,
  cb,
});
export const createDealActionSuccess = (payload) => ({
  type: createDealAction.CREATE_DEAL_ACTION_SUCCESS,
  payload,
});
export const createDealActionError = (payload) => ({
  type: createDealAction.CREATE_DEAL_ACTION_ERROR,
  payload,
});
