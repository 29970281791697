import React, { useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GreenGradientButton } from "../../../../../components/button";
import { dispatchSnackbarError } from "../../../../../utils/toaster";

export default function DealLossReasonForm(props) {
  const [selectedLostReason, setSelectedLostReason] = useState("");
  const [selectedLostReasonId, setSelectedLostReasonId] = useState("");
  const [lossDealComment, setLossDealComment] = useState("");

  const lostReasonStore = useSelector((store) => store.DealPipeline.lostReasons);

  const onLossDealSave = () => {
    if (!selectedLostReasonId) {
      dispatchSnackbarError("Please select lost reason", "error");
    } else if (selectedLostReasonId !== "") {
      const data = {
        module: {
          name: props.moduleName,
        },
        dealLostReasonId: selectedLostReasonId,
        comment: lossDealComment,
      };

      if (props.isEdit) props.handleEditSave(props.selectedActionData.id, data);
      else props.handleCreateSave(data);
    }
  };
  const onLostReasonChange = (reason) => {
    setSelectedLostReason(reason.reason);
    setSelectedLostReasonId(reason._id);
  };

  return (
    <>
      <Form.Group id="automation-workflow-builder-deal-loss-reason-form-lost-reason" required>
        <Form.Label aria-label="lost-reason">Lost Reason*</Form.Label>
        <Dropdown className="dropdown-inline mt-1 w-100">
          <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
            {selectedLostReason}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu w-100-mn">
            {lostReasonStore &&
              Array.isArray(lostReasonStore) &&
              lostReasonStore.map((lostReason) => (
                <Dropdown.Item className="dropdown-hover" onClick={() => onLostReasonChange(lostReason)}>
                  {lostReason.reason}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Form.Group id="automation-workflow-builder-deal-loss-reason-form-comments" className="mb-3">
        <label for="exampleFormControlTextarea1" className="form-label">
          Comments
        </label>
        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setLossDealComment(e.target.value)}></textarea>
      </Form.Group>
      <Form.Group id="automation-workflow-builder-deal-loss-reason-form-save-button" className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={() => onLossDealSave()} disabled={false}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </>
  );
}
