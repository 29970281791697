import { takeLatest, put, call, select } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as currencyActions from "../../actions/admin-actions/currency-settings.actions";
import * as currencyTypes from "../../types/admin-settings.types";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { endpoints } from "../../endpoint";

function* getCurrencyFunction(action) {
  try {
    const res = yield call(api.get, endpoints.GET_CURRENCY);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(currencyActions.getCurrencySuccess(res.data));
    } else {
      yield put(currencyActions.getCurrencyError("error"));
    }
  } catch (e) {
    yield put(currencyActions.getCurrencyError(e));
  }
}

function* patchCurrencyFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.GET_CURRENCY`${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(currencyActions.patchCurrencySuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message, "error");
    } else {
      yield put(currencyActions.patchCurrencyError("error"));
    }
  } catch (e) {
    yield put(currencyActions.patchCurrencyError(e));
  }
}

function* currencyFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Products.currencyList.list) && store.Products.currencyList.list.length > 0) {
      yield put(currencyActions.currencySuccess(store.Products.currencyList.list));
    } else {
      const res = yield call(api.get, endpoints.CURRENCY, action.payload);
      if (res.status === 200) {
        yield put(currencyActions.currencySuccess(res.data.data));
      } else {
        yield put(currencyActions.currencyError({ message: "Could Not Get Currency Data" }));
      }
    }
  } catch (e) {
    yield put(currencyActions.currencyError({ message: "Could Not Get Currency Data" }));
  }
}

export default function* currencySaga() {
  yield takeLatest(currencyTypes.getCurrency.GET_CURRENCY_REQUEST, getCurrencyFunction);
  yield takeLatest(currencyTypes.patchCurrency.PATCH_CURRENCY_REQUEST, patchCurrencyFunction);
  yield takeLatest(currencyTypes.currency.CURRENCY_REQUEST, currencyFunction);
}
