import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import InlineStyled from "../../inline-styled/inline-styled";

export default function CustomSidePanel({ show, moduleName, handleClose, children, showPreviosButton, showNextButton, onNextClick, onPreviousClick, customStyles }) {
  const [styles, setStyles] = useState({
    width: "80vw",
    right: "81vw",
  });

  useEffect(() => {
    if (!_.isEmpty(customStyles)) {
      if (customStyles.width && customStyles.right) {
        setStyles(customStyles);
      }
    }
  }, [customStyles]);

  const getTitleWidth = (moduleName) => {
    if (typeof moduleName === "string") {
      return `${moduleName.length * 10}px`;
    }
    return "76px";
  };
  return (
    <div className="custom-side-pannel-container">
      {show && <div className="custom-side-pannel-blank-bg" onClick={handleClose}></div>}
      {show && (
        <>
          {" "}
          <InlineStyled
            id="floatarrow"
            className={`px-1 p-1 gap-2 d-flex flex-column offcanvas offcanvas-right mt-2 csp-float-arrow-button shadow-none height-fit-content`}
            styles={{ right: show ? styles.right : "-10vw" }}
          >
            <div className="csp-button-wrapper">
              <span onClick={handleClose} className="btn btn-xs btn-icon cursor-pointer">
                <i className="fas fa-times float-color "></i>
              </span>
            </div>
            <div className=" csp-button-wrapper2">
              {(showPreviosButton || showNextButton) && (
                <span onClick={() => (showPreviosButton ? onPreviousClick(showPreviosButton) : {})} className={`btn btn-xs btn-icon ${showPreviosButton ? "cursor-pointer" : "cursor-not-allowed"} `}>
                  <i className={`fas fa-angle-up ${showPreviosButton ? "float-color" : ""}`}></i>
                </span>
              )}
              <InlineStyled as="span" className="csp-module-name" styles={{ height: getTitleWidth(moduleName) }}>
                {moduleName}
              </InlineStyled>
              {(showPreviosButton || showNextButton) && (
                <span onClick={() => (showNextButton ? onNextClick(showNextButton) : {})} className={`btn btn-xs btn-icon ${showNextButton ? "cursor-pointer" : "cursor-not-allowed"} `}>
                  <i className={`fas fa-angle-down ${showNextButton ? "float-color" : ""}`}></i>
                </span>
              )}
            </div>
          </InlineStyled>
          <InlineStyled
            id="sidepanel"
            styles={{
              right: show ? 0 : `-${styles.width}`,
              width: styles.width,
            }}
            className={"h-100vh offcanvas offcanvas-right pb-10 "}
          >
            <div className="card shadow-none rounded-0 h-100vh overflow-y-auto">{children}</div>
          </InlineStyled>
        </>
      )}
    </div>
  );
}
