import { Accordion, Card } from "react-bootstrap";
import React, { useState } from "react";

export default function AccordionComponent({ title, children, defaultActiveKey }) {
  const [activeKey, setActiveKey] = useState("0");
  const handleToggle = () => {
    if (activeKey === "0") {
      setActiveKey("1");
    } else {
      setActiveKey("0");
    }
  };
  return (
    <Accordion defaultActiveKey={defaultActiveKey} activeKey={activeKey} className="mb-5">
      <Card>
        <Accordion.Toggle onClick={() => handleToggle()} as={Card.Header} eventKey="0" className="bg-f1f1f1 card p-0 d-flex justify-content-center p-0 m-0">
          <div className="d-flex justify-content-between align-items-center px-5">
            <div>
              <span className="fw-500 font-size-15px">{title}</span>
            </div>
            <div></div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
