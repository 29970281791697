import React, { useState, useEffect, useRef } from "react";
import validator from "validator";
import "../../../../../../_metronic/_assets/css/style.bundle.css";
import "../../../../../../_metronic/_assets/plugins/global/plugins.bundle.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../../../utils/toaster";
import { getGeoLocation } from "../../utils/geoLocation";
import InlineStyled from "../../../../../../components/inline-styled/inline-styled";
const { detect } = require("detect-browser");
const browser = detect();

function Registration(props) {
  const dispatch = useDispatch();
  const [EmailText, setEmailText] = useState("");
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [Company, setCompany] = useState("");
  const [domain, setdomain] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [PhoneNum, setPhoneNum] = useState("");
  const [couponcode, setCouponcode] = useState("");
  const [couponcode2, setCouponcode2] = useState("");
  const [couponcode3, setCouponcode3] = useState("");
  const [step, setStep] = useState(1);
  const [otpValue, setOtpValue] = useState("");
  const values = {
    FullName,
    Email,
    Company,
    phoneNo,
    password,
    domain,
    PhoneNum,
    couponcode,
    couponcode2,
    couponcode3,
  };

  const geoLoc = useRef();

  useEffect(() => {
    (async () => {
      const data = await getGeoLocation();
      geoLoc.current = data;
    })();
  }, []);

  function next() {
    if (step === 1) {
      if (FullName === "") {
        dispatchSnackbarError("Please Enter you Full Name", "error");
      } else if (Email === "") {
        dispatchSnackbarError("Please Enter Email", "error");
      } else if (password === "") {
        dispatchSnackbarError("Please Enter Password", "error");
      } else if (EmailText === "Enter Valid Email") {
        dispatchSnackbarError("Please Enter a Valid Email", "error");
      } else if (password.length < 8) {
        dispatchSnackbarError("Please Enter Minimum 8 character Password", "error");
      } else {
        /*******
         * check email API
         *******/

        dispatch(
          actions.CheckEmailRequest({ email: Email }, (data) => {
            if (data["emailTaken"]) {
              dispatchSnackbarError(data["message"], "error");
            } else {
              setStep(step + 1);
              dispatchSnackbarSuccess("Enter an OTP", "success");

              /*******
               * send email otp API
               *******/
              dispatch(
                actions.EmailOTPRequest({ email: Email, caseType: "REGISTER" }, (data) => {
                  // setemailOTPid(data['otpId'])
                }),
              );
            }
          }),
        );
      }
    }

    if (step === 3) {
      let phone = localStorage.getItem("phone");
      let country = localStorage.getItem("country");
      if (Company === "" || Company === null) {
        dispatchSnackbarError("Please Enter Company Name", "error");
      } else if (country === "" || country === null) {
        dispatchSnackbarError("Please Enter Country", "error");
      } else {
        let phoneObj = {
          fullName: FullName,
          email: Email,
          password: password,
          subDomain: domain,
          cName: Company,
          country: country,
          phone: `${phone}`,
          location: `${geoLoc.current.city} ${geoLoc.current.region}`,
          device: browser.os,
          platform: browser.name,
          IPAddr: geoLoc.current.ip,
          isGoogleAttempt: false,
          rememberLogin: true,
          loginMethod: "PASSWORD",
          couponCode: couponcode,
          couponCode2: couponcode2,
          couponCode3: couponcode3,
        };

        dispatch(
          actions.PatchPhoneRequest(phoneObj, (data) => {
            if (data["success"]) {
              dispatchSnackbarSuccess("Account Successfully Created", "success");
              localStorage.setItem("access_token", data["token"]);
              setStep(step + 1);
            }
          }),
        );
      }
    }
  }

  const CloseButton = () => {
    // props.setIsAuthorized(true);
    localStorage.removeItem("typeOfComp");
    localStorage.removeItem("experience");
    localStorage.removeItem("size");
    localStorage.removeItem("country");
    localStorage.removeItem("phone");
    window.location.href = "/activity/activity-list";
  };

  const submit = () => {
    let country = localStorage.getItem("country");
    let size = localStorage.getItem("size");
    let typeOfComp = localStorage.getItem("typeOfComp");
    let experience = localStorage.getItem("experience");

    if ((size === "" || size === null) && (typeOfComp === "" || typeOfComp === null) && (experience === "" || experience === null)) {
      localStorage.removeItem("typeOfComp");
      localStorage.removeItem("experience");
      localStorage.removeItem("size");
      localStorage.removeItem("country");
      localStorage.removeItem("phone");
      // props.setIsAuthorized(true)
      window.location.href = "/activity/activity-list";
    } else if (size === "" || size === null) {
      dispatchSnackbarError("Please select Company size", "error");
    } else if (typeOfComp === "" || typeOfComp === null) {
      dispatchSnackbarError("Please select type of company", "error");
    } else if (experience === "" || experience === null) {
      dispatchSnackbarError("Please select Your Experience", "error");
    } else {
      let userDetails = {
        subDomain: domain,
        cName: Company,
        country: country,
        size: size,
        typeOfComp: typeOfComp,
        experience: experience,
      };

      dispatch(
        actions.UpdateAccountRequest(userDetails, "", (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Account Successfully Updated", "success");
            localStorage.removeItem("typeOfComp");
            localStorage.removeItem("experience");
            localStorage.removeItem("size");
            localStorage.removeItem("country");
            localStorage.removeItem("phone");
            // props.setIsAuthorized(true)
            window.location.href = "/activity/activity-list";
          }
        }),
      );
    }
  };

  function Back() {
    setStep(step - 1);
  }

  function handleChange(e) {
    if (e.target.name === "fUllName") {
      setFullName(e.target.value);
    } else if (e.target.name === "PhoneNum") {
      setPhoneNum(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
      //eslint-disable-next-line
      // const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      // var result = e.target.value.replace(/\s/g, "").split(/,|;/);
      // if (regEx.test(result[0])) {
      //   setEmailText("Email is Valid")
      // } else {
      //   setEmailText("Enter Valid Email")
      // }

      if (validator.isEmail(e.target.value.trim())) {
        setEmailText("Email is Valid");
      } else {
        setEmailText("Enter Valid Email");
      }
    } else if (e.target.name === "companyName") {
      setCompany(e.target.value);
      setdomain(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setPhoneNo(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "domainName") {
      setdomain(e.target.value);
    } else if (e.target.name === "couponcode") {
      setCouponcode(e.target.value);
    } else if (e.target.name === "couponcode2") {
      setCouponcode2(e.target.value);
    } else if (e.target.name === "couponcode3") {
      setCouponcode3(e.target.value);
    }
  }
  const validate = (otp) => {
    const infoObj = {
      email: Email,
      otp: otp,
      caseType: "REGISTER",
    };
    dispatch(
      actions.SignUpInfoRequest(infoObj, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("OTP Verified", "success");
          setStep(step + 1);
        }
      }),
    );
  };
  const onOtpChange = (otp) => {
    setOtpValue(otp);
    if (otp.length >= 6) {
      if (isNaN(otp)) {
        dispatchSnackbarError("Please enter valid otp number!");
      } else {
        try {
          validate(parseInt(otp));
        } catch (err) {
          validate(otp);
        }
      }
    }
  };

  const signUp = () => {
    dispatch(
      actions.GoogleAPIRequest("signup", (data) => {
        const newWindow = window.open(data.url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      }),
    );
  };

  const resendOTP = () => {
    dispatch(
      actions.EmailOTPRequest({ email: Email, caseType: "REGISTER" }, (data) => {
        dispatchSnackbarSuccess("OTP successfully Sent", "success");
      }),
    );
  };

  return (
    <div className="login-form login-signin AuthLoginForm d-block">
      <div className="d-flex flex-column flex-root">
        {/*begin::Authentication - Sign-up */}
        <InlineStyled
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        >
          {/*begin::Content*/}
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            {/*begin::Logo*/}
            <a href="index.html" className="mb-12">
              <img alt="Logo" src={require("../../../../../../images/logo/rectangle-logo/SVG/logo-rectangle.svg")} className="h-90px" />
            </a>
            {/*end::Logo*/}
            {/*begin::Wrapper*/}
            <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 AuthLoginCard">
              {/*begin::Form*/}
              <form className="form w-100" noValidate="novalidate" id="kt_sign_up_form">
                {/*begin::Heading*/}
                {step === 1 && (
                  <div className="mb-10 text-center">
                    {/*begin::Title*/}
                    <h1 className="text-dark mb-3">Create an Account</h1>
                    {/*end::Title*/}
                    {/*begin::Link*/}
                    <div className="text-gray-400 fw-bold fs-4">
                      Already have an account?
                      {/* <a href="authentication/flows/basic/sign-in.html" className="link-primary fw-bolder">Sign in here</a> */}
                      <Link to="/auth" className="link-primary fw-bolder" id="kt_login_signup">
                        &nbsp;Sign in here
                      </Link>
                    </div>
                    {/*end::Link*/}
                  </div>
                )}
                {/*end::Heading*/}

                {step === 1 ? (
                  <Step1
                    values={values}
                    next={next}
                    handleChange={handleChange}
                    signUp={signUp}
                    EmailText={EmailText}
                    setCouponcode={setCouponcode}
                    couponcode2={couponcode2}
                    setCouponcode2={setCouponcode2}
                    couponcode3={couponcode3}
                    setCouponcode3={setCouponcode3}
                  />
                ) : step === 2 ? (
                  <Step2 Back={Back} resendOTP={resendOTP} onOtpChange={(_otp) => onOtpChange(_otp)} otpValue={otpValue} />
                ) : step === 3 ? (
                  <Step3 values={values} next={next} handleChange={handleChange} />
                ) : step === 4 ? (
                  <Step4 Back={Back} submit={submit} CloseButton={CloseButton} />
                ) : (
                  ""
                )}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
          {/*begin::Footer*/}
          {/* <div className="d-flex flex-center flex-column-auto p-10"> */}
          {/*begin::Links*/}
          {/* <div className="d-flex align-items-center fw-bold fs-6">
        <a href="https://keenthemes.com/faqs" className="text-muted text-hover-primary px-2">About</a>
        <a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">Contact</a>
        <a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">Contact Us</a>
      </div> */}
          {/*end::Links*/}
          {/* </div> */}
          {/*end::Footer*/}
        </InlineStyled>
        {/*end::Authentication - Sign-up*/}
      </div>
    </div>
  );
}

export default connect(null, actions)(Registration);

// export default injectIntl(connect(null, auth.actions)(Registration));
