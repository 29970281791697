import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlueGradientButton } from "../../../../../../components/button";
import { CustomEmptyPage } from "../../../../../../components/empty_page/custom-empty-page";
import { CustomModal, DeleteModal } from "../../../../../../components/modal";
import AdavanceDataTable from "../../../../../../components/normal-table/advance-table";
// components
import { ContentLoadingWithoutCard } from "../../../../../../components/loading";

// Actions
import * as SMSSettingsActions from "../../../../store/actions/campaign_sms_settings_actions";
import { Form } from "react-bootstrap";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { smsRouteOptions } from "../../../../constants";
import SenderIdCountrySelect from "./sender-id-country-select";
import { FormHelperText } from "@material-ui/core";

function isValidSenderId(senderId) {
  // Check if the sender ID is empty
  if (senderId.length === 0) {
    return false;
  }

  // Check if the sender ID is all digits (for numeric sender IDs)
  if (/^\d+$/.test(senderId)) {
    // Check if the sender ID is within the allowed length for numeric sender IDs
    return senderId.length <= 15;
  }

  // Check if the sender ID is within the allowed length for alphanumeric sender IDs
  return senderId.length <= 11;
}
export default function SMSSenderIdContainer(props) {
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [senderId, setSenderId] = useState("");
  const [routeName, setRouteName] = useState({ value: "promotional", label: "Promotional" });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectCountry, setSelectedCountry] = useState([]);
  const [countryListOptions, setCountryListOptions] = useState([]);
  const [countryListMap, setCountryListMap] = useState({});
  const [remarkText, setRemarkText] = useState("");
  const [isAdminApprovalNeeded, setIsAdminApprovalNeeded] = useState(false);

  const dispatch = useDispatch();

  const senderIdStore = useSelector((store) => store.campaign.settings.sms.senderId);
  const senderCountryStore = useSelector((store) => store.campaign.settings.sms.senderCountryList);

  useEffect(() => {
    dispatch(SMSSettingsActions.getListOfSMSSenderIdsRequest());
    dispatch(SMSSettingsActions.getSMSSenderIdCountryListRequest({ limit: 1000, page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(senderIdStore.data)) {
      const _data = senderIdStore.data.slice((page - 1) * limit, page * limit);
      const final = _data.map((each) => {
        each.routeName = _.capitalize(each.routeName);
        each.status = each.status
          .split("_")
          .map(_.capitalize)
          .join(" ");
        each.countryISO = each.country && each.country.iso ? each.country.iso : [];
        return each;
      });
      setTableData(final);
    }
  }, [senderIdStore.data, page, limit]);

  useEffect(() => {
    if (!_.isEmpty(senderCountryStore.data)) {
      const a = senderCountryStore.data
        .filter((b) => b.routeName.toLowerCase() === routeName.value)
        .map((each) => {
          return {
            value: each.countryIso,
            label: each.countryName,
          };
        });
      setCountryListOptions(a);
    }
  }, [senderCountryStore.data, routeName]);

  useEffect(() => {
    if (!_.isEmpty(senderCountryStore.data)) {
      const _map = {};
      senderCountryStore.data.forEach((each) => {
        _map[`${each.routeName.toLowerCase()}_${each.countryIso}`] = each;
      });
      setCountryListMap(_map);
    }
  }, [senderCountryStore.data]);

  useEffect(() => {
    if (selectCountry.length === 1) {
      const data = countryListMap[`${routeName.value}_${selectCountry[0].value}`];
      if (data) {
        setRemarkText(data.remark);
        data.isUserApprovedSenderId ? setIsAdminApprovalNeeded(false) : setIsAdminApprovalNeeded(true);
      }
    } else if (selectCountry.length > 0) {
      for (let i = 0; i < selectCountry.length; i++) {
        const data = countryListMap[`${routeName.value}_${selectCountry[i].value}`];
        if (data && !data.isUserApprovedSenderId) {
          setIsAdminApprovalNeeded(true);
          break;
        }
      }
      setRemarkText("");
    } else {
      setRemarkText("");
    }
  }, [countryListMap, routeName, selectCountry]);

  const onAddSenderIdClick = () => {
    setShowAdd(true);
  };
  const handleAddCancel = () => {
    setShowAdd(false);
  };
  const handleAddAccept = () => {
    if (!isValidSenderId(senderId)) {
      return dispatchSnackbarError("Please provide valid sender id!");
    }
    if (_.isEmpty(selectCountry)) {
      return dispatchSnackbarError("Please select country!");
    }
    const payload = {
      senderId: senderId,
      routeName: routeName.value,
      country: selectCountry.map((each) => each.value),
      isAdminApprovalNeeded: isAdminApprovalNeeded,
    };
    dispatch(SMSSettingsActions.createSMSSenderIdRequest(payload));
    setShowAdd(false);
    setSelectedCountry([]);
    setSenderId("");
    setRouteName({ value: "promotional", label: "Promotional" });
  };

  const handleDeleteClick = (data) => {
    setShowDelete(true);
    setSelectedId(data._id);
  };

  const handleDeleteAccept = () => {
    dispatch(SMSSettingsActions.deleteSMSSenderIdRequest(selectedId));
    setShowDelete(false);
    setSelectedId(null);
  };

  const deleteDomainModalProps = {
    show: showDelete,
    title: "Delete Sender Id",
    handleClose: () => {
      setShowDelete(false);
      setSelectedId(null);
    },
    handleAccept: handleDeleteAccept,
  };
  const AddSenderIdModalProps = {
    showButtons: true,
    show: showAdd,
    title: "Add Sender Id",
    loading: false,
    buttonText: "Save",
    handleClose: handleAddCancel,
    handleAccept: handleAddAccept,
  };

  const columns = [
    { title: "Sender ID", apiKeyName: "senderId", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Route Name", apiKeyName: "routeName", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Country", apiKeyName: "countryISO", fieldInputType: "COUNTRY_ISO_DISPLAY", isAction: false, isFixed: false },
    { title: "Owner", apiKeyName: "owner", fieldInputType: "OWNER_SELECT", isAction: false, isFixed: false },
    { title: "Created Date", apiKeyName: "createdAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },
    { title: "Status", apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", isAction: false, isFixed: false },
    { title: "Actions", apiKeyName: "action", fieldInputType: "ACTION", isAction: true, isFixed: true, options: ["delete"] },
  ];

  return (
    <div className="card mb-5 border-0 mb-xl-10 h-vh-120px-mx">
      {senderIdStore.data.length !== 0 && (
        <div className="d-flex justify-content-end pt-9 px-9">
          <BlueGradientButton size="sm" onClick={() => onAddSenderIdClick()}>
            + Add Sender ID
          </BlueGradientButton>
        </div>
      )}
      {senderIdStore.loading ? (
        <ContentLoadingWithoutCard />
      ) : senderIdStore.data.length > 0 ? (
        <div className="m-8">
          <div className="card card-custom">
            <div className="card-body py-3 px-3 border border-1">
              <AdavanceDataTable
                columns={columns}
                data={tableData}
                limit={limit}
                pageNo={page}
                setLimit={setLimit}
                setPageNo={setPage}
                count={senderIdStore.data.length}
                // actions
                handleDelete={handleDeleteClick}
              />
            </div>
          </div>{" "}
        </div>
      ) : (
        <CustomEmptyPage page="sms-sender-id" hideBorder={false} setShowAdd={onAddSenderIdClick} />
      )}
      <DeleteModal modalProps={deleteDomainModalProps}>Are you sure want to delete sender id?.</DeleteModal>
      <CustomModal modalProps={AddSenderIdModalProps}>
        <Form>
          <Form.Group>
            <Form.Label>Sender ID</Form.Label>
            <Form.Control maxLength={15} value={senderId} onChange={(e) => setSenderId(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Route Name</Form.Label>
            <SimpleDropdown value={routeName} label={"Select Route Name"} options={smsRouteOptions} handleSelect={setRouteName} />
          </Form.Group>
          <SenderIdCountrySelect options={countryListOptions} selectedValue={selectCountry} setSelectedValue={setSelectedCountry} />
          <FormHelperText>{remarkText}</FormHelperText>
        </Form>
      </CustomModal>
    </div>
  );
}
