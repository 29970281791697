import React, { useEffect, useState } from "react";
import { Form, Dropdown, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { searchAllAssociationsRequest } from "../../containers/Activities/store/actions/activity_operations_actions";
import "./styles.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import _ from "lodash";
import InlineStyled from "../inline-styled/inline-styled";

const CustomItemList = ({ key, title, icon, id, handleItemClick, _module, item }) => {
  return (
    <div key={key} onClick={() => handleItemClick(id)} className="d-flex align-items-center p-3 rounded bg-state-light  mb-1">
      <div className="symbol symbol-30px me-4">
        <span className="symbol-label bg-light-primary">
          <span className="svg-icon svg-icon-2 svg-icon-primary">
            <i className={`${icon}`}></i>
          </span>
        </span>
      </div>
      <div className="d-flex flex-column">
        <span className="fs-6 text-gray-800">{title}</span>
        {["lead", "people"].includes(_module) && <span className="fs-7 text-00a6bc">{item.company && item.company.name}</span>}
      </div>
    </div>
  );
};

export default function Association(props) {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const headMenuOptions = ["All", "Deal", "Lead", "People", "Company"];

  const dispatch = useDispatch();

  const assocaistionStore = useSelector((store) => store.activity.search.association);

  useEffect(() => {
    dispatch(searchAllAssociationsRequest({ searchText: searchText, module: type.toLowerCase() }));
  }, [dispatch, type, searchText]);

  useEffect(() => {
    if (!_.isEmpty(props.value)) {
      if (props.value.name) setSelectedValue(props.value.name);
      if (props.value.title) setSelectedValue(props.value.title);
    }
  }, [props.value]);

  const handleItemClick = (type, id, title) => {
    setSelectedValue(title);
    setSearchText("");
    setShow(false);
    if (type === "lead") {
      props.setValue({ _id: id, name: title, type: type }, "associated");
    } else if (type === "company") {
      props.setValue({ _id: id, name: title, type: type }, "associated");
    } else if (type === "deal") {
      props.setValue({ _id: id, title: title, type: type }, "associated");
    } else if (type === "people") {
      props.setValue({ _id: id, name: title, type: type }, "associated");
    }
  };

  const isNoDataPresent = () => {
    if (assocaistionStore.data["deal"].length > 0) {
      return false;
    }
    if (assocaistionStore.data["people"].length > 0) {
      return false;
    }
    if (assocaistionStore.data["lead"].length > 0) {
      return false;
    }
    if (assocaistionStore.data["company"].length > 0) {
      return false;
    }
    return true;
  };

  return (
    <Dropdown
      alignRight
      show={show}
      drop="down"
      onToggle={() => {
        if (props.disabled) {
          setShow(false);
        } else {
          setShow(!show);
        }
      }}
      className="dropdown-inline w-100"
    >
      <InlineStyled
        as={Dropdown.Toggle}
        className="border-width-1px border-style-solid border-color-e4e6ef d-flex align-items-center w-100 btn btn-sm di dropdown-toggle bg-white custom-ellipsis text-left z-index-0 py-0-point-65rem px-1-point-1rem"
        styles={{
          customWidth: "100%",
        }}
        variant="transparent"
      >
        {selectedValue ? selectedValue : "Select Associated"}
      </InlineStyled>
      <Dropdown.Menu className="w-360px dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <div className="d-flex flex-column gap-2 align-content-center justify-content-center p-4">
          <div>
            <Form.Control
              placeholder="Search"
              type="text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setShow(true);
              }}
            />
          </div>
          <div className="d-flex overflow-auto position-relative">
            {headMenuOptions.map((each, index) => {
              return (
                <span key={index} className={`btn btn-light-primary rounded-pill ${type === each ? "active" : ""} me-1 py-2 px-4`} onClick={() => setType(each)}>
                  {each}
                </span>
              );
            })}
          </div>
        </div>
        <div className="separator border-gray-200 my-0"></div>
        {isNoDataPresent() ? (
          <div data-kt-search-element="empty" className="text-center">
            <div className="pt-10 pb-10">
              <span className="svg-icon svg-icon-4x opacity-50">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                  <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                  <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                  <path
                    d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </div>
            <div className="pb-15 fw-bold">
              <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
              <div className="text-muted fs-7">Please try again with a different query</div>
            </div>
          </div>
        ) : (
          <div data-kt-search-element="results">
            {assocaistionStore.loading ? (
              <>
                {" "}
                <div className="w-100 scroll-y pt-10 pb-10 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              </>
            ) : (
              <div className="scroll-y mh-200px mh-lg-300px my-2 me-n2 pe-2">
                {assocaistionStore.data["deal"] && assocaistionStore.data["deal"].filter((items) => !items.lock).length > 0 && (
                  <>
                    <Dropdown.Header>Deals</Dropdown.Header>
                    {assocaistionStore.data["deal"]
                      .filter((items) => !items.lock)
                      .map((eachList, ind) => (
                        <CustomItemList key={ind} item={eachList} _module={"deal"} handleItemClick={(id) => handleItemClick("deal", id, eachList.title)} title={eachList.title} icon={"bi bi-coin"} id={eachList._id} />
                      ))}
                  </>
                )}
                {assocaistionStore.data["lead"] && assocaistionStore.data["lead"].filter((items) => !items.lock).length > 0 && (
                  <>
                    <Dropdown.Header>Leads</Dropdown.Header>
                    {assocaistionStore.data["lead"]
                      .filter((items) => !items.lock)
                      .map((eachList, ind) => (
                        <CustomItemList key={ind} item={eachList} _module={"lead"} handleItemClick={(id) => handleItemClick("lead", id, eachList.name)} title={eachList.name} icon={"bi bi-bullseye"} id={eachList._id} />
                      ))}
                  </>
                )}
                {assocaistionStore.data["people"] && assocaistionStore.data["people"].filter((items) => !items.lock).length > 0 && (
                  <>
                    <Dropdown.Header>Peoples</Dropdown.Header>
                    {assocaistionStore.data["people"]
                      .filter((items) => !items.lock)
                      .map((eachList, ind) => (
                        <CustomItemList key={ind} item={eachList} _module={"people"} handleItemClick={(id) => handleItemClick("people", id, eachList.name)} title={eachList.name} icon={"bi bi-people"} id={eachList._id} />
                      ))}
                  </>
                )}
                {assocaistionStore.data["company"] && assocaistionStore.data["company"].filter((items) => !items.lock).length > 0 && (
                  <>
                    <Dropdown.Header>Companies</Dropdown.Header>
                    {assocaistionStore.data["company"]
                      .filter((items) => !items.lock)
                      .map((eachList, ind) => (
                        <CustomItemList
                          key={ind}
                          item={eachList}
                          _module={"company"}
                          handleItemClick={(id) => handleItemClick("company", id, eachList.name)}
                          title={eachList.name}
                          icon={"bi bi-building"}
                          id={eachList._id}
                        />
                      ))}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
