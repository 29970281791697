import { getSettingActivity, postActivity, getActivity, patchActivity, deleteActivity } from "../../types/admin-settings.types";

export function getSettingActivityRequest(req) {
  return {
    type: getSettingActivity.GET_SETTING_ACTIVITY_REQUEST,
    payload: req,
  };
}
export function getSettingActivitySuccess(res) {
  return {
    type: getSettingActivity.GET_SETTING_ACTIVITY_SUCCESS,
    payload: res,
  };
}
export function getSettingActivityError(err) {
  return {
    type: getSettingActivity.GET_SETTING_ACTIVITY_ERROR,
    payload: err,
  };
}

export function postActivityRequest(req, cb) {
  return {
    type: postActivity.POST_ACTIVITY_REQUEST,
    payload: req,
    cb,
  };
}
export function postActivitySuccess(res) {
  return {
    type: postActivity.POST_ACTIVITY_SUCCESS,
    payload: res,
  };
}
export function postActivityError(err) {
  return {
    type: postActivity.POST_ACTIVITY_ERROR,
    payload: err,
  };
}

export function getActivityRequest(cb) {
  return {
    type: getActivity.GET_ACTIVITY_REQUEST,
    cb,
  };
}
export function getActivitySuccess(res) {
  return {
    type: getActivity.GET_ACTIVITY_SUCCESS,
    payload: res,
  };
}
export function getActivityError(err) {
  return {
    type: getActivity.GET_ACTIVITY_ERROR,
    payload: err,
  };
}

export function patchActivityRequest(req, paramId, cb) {
  return {
    type: patchActivity.PATCH_ACTIVITY_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}
export function patchActivitySuccess(res) {
  return {
    type: patchActivity.PATCH_ACTIVITY_SUCCESS,
    payload: res,
  };
}
export function patchActivityError(err) {
  return {
    type: patchActivity.PATCH_ACTIVITY_ERROR,
    payload: err,
  };
}

export function deleteActivityRequest(paramId, cb) {
  return {
    type: deleteActivity.DELETE_ACTIVITY_REQUEST,
    paramId,
    cb,
  };
}
export function deleteActivitySuccess(res) {
  return {
    type: deleteActivity.DELETE_ACTIVITY_SUCCESS,
    payload: res,
  };
}
export function deleteActivityError(err) {
  return {
    type: deleteActivity.DELETE_ACTIVITY_ERROR,
    payload: err,
  };
}
