import { getEmailCampaignDetailedReport, getEmailCampaignAnalytics, getEmailCampaignSummaryReport, getCampaignReportLog, getListOfDownloadReports } from "../../types/campaign_reports_types";

const initState = {
  summary: {
    data: [],
    channel: "",
    loading: false,
  },
  analytics: {
    data: [],
    channel: "",
    loading: false,
  },
  detailedReport: {
    data: [],
    count: 0,
    channel: "",
    loading: false,
  },
  reportLog: {
    data: [],
    count: 0,
    channel: "",
    loading: false,
  },
  download: {
    data: [],
    count: 0,
    channel: "",
    loading: false,
  },
};

export default function campaignEmailReportReducer(state = initState, action = {}) {
  switch (action.type) {
    case getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_REQUEST:
      return {
        ...state,
        detailedReport: {
          ...state.detailedReport,
          loading: true,
        },
      };
    case getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_SUCCESS:
      return {
        ...state,
        detailedReport: {
          ...state.detailedReport,
          loading: false,
          data: action.payload.data,
          channel: action.payload.channel,
          count: action.payload.count,
        },
      };
    case getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_ERROR:
      return {
        ...state,
        detailedReport: {
          ...state.detailedReport,
          loading: false,
        },
      };

    case getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: true,
        },
      };
    case getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: false,
          data: action.payload.data,
          channel: action.payload.channel,
        },
      };
    case getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_ERROR:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: false,
        },
      };
    case getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: true,
        },
      };
    case getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: false,
          data: action.payload.data,
          channel: action.payload.channel,
        },
      };
    case getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_ERROR:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: false,
        },
      };
    case getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_REQUEST:
      return {
        ...state,
        reportLog: {
          ...state.reportLog,
          loading: true,
        },
      };
    case getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_SUCCESS:
      return {
        ...state,
        reportLog: {
          ...state.reportLog,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
          channel: action.payload.channel,
        },
      };
    case getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_ERROR:
      return {
        ...state,
        reportLog: {
          ...state.reportLog,
          loading: false,
        },
      };
    case getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST:
      return {
        ...state,
        download: {
          ...state.download,
          loading: true,
        },
      };
    case getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_SUCCESS:
      return {
        ...state,
        download: {
          ...state.download,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
          channel: action.payload.channel,
        },
      };
    case getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_ERROR:
      return {
        ...state,
        download: {
          ...state.download,
          loading: false,
        },
      };

    default:
      return state;
  }
}
