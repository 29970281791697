import React, { useEffect, useState } from "react";
import _ from "lodash";
import ContentWrapper from "../../../../Wrapper";
import CampaignSubHeader from "../../../components/campaign-common-sub-header";
import moment from "moment-timezone";
import DownloadReportFilter from "../components/download-report-filter";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { getListOfDownloadReportsRequest, downloadCampaignReportsRequest, getListOfDownloadReportsRequestAfter } from "../../../store/actions/campaign_report_actions";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import { NormalTable } from "../../../../../components/table/normalTable";

var timer;

export default function DownloadReport(props) {
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  // filter section
  const [initalFilterValue] = useState({
    channel: "email",
    type: "application",
    fromDate: moment()
      .subtract(7, "days")
      .valueOf(),
    toDate: moment().valueOf(),
  });

  const dispatch = useDispatch();

  const campaignDownloadReportStore = useSelector((store) => store.campaign.reports.email.download);

  useEffect(() => {
    const payload = {
      limit: limit,
      page: page,
    };
    dispatch(getListOfDownloadReportsRequest(payload));
    clearInterval(timer);
    timer = setInterval(() => dispatch(getListOfDownloadReportsRequestAfter(payload)), 3000);
  }, [dispatch, page, limit]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleApplyFilter = (data) => {
    const payload = {
      startDate: data.fromDate,
      endDate: data.toDate,
      channel: data.channel,
      type: data.type,
      campaignId: "",
    };
    dispatch(downloadCampaignReportsRequest(payload));
    handleCancelFilter();
  };

  const handleCancelFilter = () => {
    setShow(false);
  };

  const columns = [
    { apiKeyName: "createdAt", fieldInputType: "DATE", fieldName: "Date" },
    { apiKeyName: "channel", fieldInputType: "CHANNEL_LIGHT_TAG", fieldName: "Channel" },
    { apiKeyName: "type", fieldInputType: "INPUT", fieldName: "Type" },
    { apiKeyName: "noOfResults", fieldInputType: "INPUT", fieldName: "Total Results" },
    { apiKeyName: "owner", fieldInputType: "OWNER_SELECT", fieldName: "Owner" },
    { apiKeyName: "percentage", fieldInputType: "INPUT", fieldName: "Percentage" },
    { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    { apiKeyName: "downloadableLink", fieldInputType: "DOWNLOAD", fieldName: "Download" },
  ];

  const tableProps = {
    columns: columns,
    data: campaignDownloadReportStore.data.map((each) => {
      each.percentage = `${_.parseInt(each.percentage)}%`;
      each.status = _.capitalize(each.status);
      each.type = _.capitalize(each.type);
      return each;
    }),
    pagination: true,
    count: campaignDownloadReportStore.count,
    limit: limit,
    pageNo: page,
    setLimit: setLimit,
    setPageNo: setPage,
    setChecked: () => {},
    loading: campaignDownloadReportStore.loading,
  };

  return (
    <ContentWrapper
      subHeader={
        <CampaignSubHeader active={props.tabName}>
          <div className="d-flex gap-3 align-items-center">
            <DownloadReportFilter show={show} setShow={setShow} filterValue={initalFilterValue} handleApplyFilter={handleApplyFilter} handleCancelFilter={handleCancelFilter} />
          </div>
        </CampaignSubHeader>
      }
    >
      {campaignDownloadReportStore.loading ? (
        <ContentWithCardLoading />
      ) : campaignDownloadReportStore.data.length === 0 ? (
        <CustomEmptyPage page="downloadCampaignReport" setShowAdd={setShow} />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <NormalTable props={tableProps} />
          </div>
        </div>
      )}
    </ContentWrapper>
  );
}
