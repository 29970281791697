import { FormHelperText } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import validator from "validator";
import "../styles.scss";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { whatsappCategoryOptions, whatsappLanguageOptions } from "../constants";
import WhatsappAddTemplateHeader from "./header.component";
import WhatsappAddTemplateBody from "./body.component";
import WhatsappAddTemplateFooter from "./footer.component";
import WhatsappAddTemplateButton from "./button.component";
import WhatsappAddTemplatePreview from "./preview.component";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { isWhatsappTemplateName } from "../../../../../../utils/index.util";
import { getVariableKeys } from "../../../../../../utils/variables.util";


export default function AddWhatsappTemplate(props) {
    const [templateName, setTemplateName] = useState("");
    const [category, setCategory] = useState("MARKETING");
    const [language, setLanguage] = useState("");
    const [components, setComponents] = useState([]);

    // component variables
    const [header, setHeader] = useState({});
    const [headerVariables, setHeaderVariables] = useState([]);
    const [bodyContent, setBodyContent] = useState("");
    const [bodyContentVariables, setBodyContentVariables] = useState([]);
    const [footerText, setFooterText] = useState("Sent via Salessimplify");
    const [buttons, setButtons] = useState({});

    useEffect(() => {
        if (props.isEdit && _.isEmpty(props.selectedData)) {
            setTemplateName("");
            setCategory("MARKETING");
            setLanguage("");
            setBodyContent("");
            setBodyContentVariables([]);
            setFooterText("Sent via Salessimplify");
            setButtons({});
        }
        else if (props.isEdit && !_.isEmpty(props.selectedData)) {
            console.log(props.selectedData, "props.selectedData")
            setTemplateName(props.selectedData.name);
            setCategory(props.selectedData.category);
            setLanguage(props.selectedData.language);
            setBodyContent("");
            setBodyContentVariables([]);
            setFooterText("Sent via Salessimplify");
            setButtons({});
            const _components = props.selectedData.components;
            for (const component of _components) {
                if (component.type === "BODY") {
                    setBodyContent(component.text)
                }
                else if (component.type === "FOOTER") {
                    setFooterText(component.text)
                }
                else if (component.type === "BUTTONS") {
                    setButtons(component)
                }
                else if (component.type === "HEADER") {
                    setHeader(component)
                }
            }
            if (props.selectedData.defaultValues) {
                const { header, body } = props.selectedData.defaultValues;
                if (header) {
                    setHeaderVariables(header);
                }
                if (body) {
                    setBodyContentVariables(body);
                }
            }

        }

    }, [props.isEdit, props.selectedData])

    useEffect(() => {
        const _components = [];
        if (!_.isEmpty(header)) {
            _components.push(header);
        }
        if (!_.isEmpty(bodyContent)) {
            _components.push({
                "type": "BODY",
                "text": bodyContent
            });
        }
        if (!_.isEmpty(footerText)) {
            _components.push({ type: "FOOTER", text: footerText });
        }
        if (!_.isEmpty(buttons)) {
            _components.push(buttons);
        }
        setComponents(_components);
    }, [footerText, buttons, bodyContent, header]);

    const handleSave = () => {
        if (_.isEmpty(templateName)) {
            return dispatchSnackbarError("Template name is required.");
        }
        else if (!isWhatsappTemplateName(templateName)) {
            return dispatchSnackbarError("Only alphanumeric characters and underscore are allowed in template name.");
        }
        else if (_.isEmpty(category)) {
            return dispatchSnackbarError("Category is required.");
        }
        else if (_.isEmpty(language)) {
            return dispatchSnackbarError("Language is required.");
        }

        const payload = {
            "name": templateName,
            "category": category,
            "allow_category_change": false,
            "language": language,
            "components": [],
            "defaultValues": {
                body: bodyContentVariables,
                header: headerVariables
            }
        };

        // for header
        if (!_.isEmpty(header)) {
            for (const each of headerVariables) {
                if (_.isEmpty(each.value)) {
                    return dispatchSnackbarError("Header variable value is required.")
                }
                else if (_.isEmpty(each.mapField)) {
                    return dispatchSnackbarError("Header variable map field is required.")
                }
            }
            if (header.format === "TEXT") {
                if (_.isEmpty(header.text)) return dispatchSnackbarError("Header text is required.")
                payload.components.push({
                    "type": "HEADER",
                    "format": "TEXT",
                    "text": header.text,
                })
            }
            else if (header.format === "IMAGE" || header.format === "VIDEO" || header.format === "DOCUMENT") {
                if (_.isEmpty(header.example.header_handle)) return dispatchSnackbarError("Header media is required.")
                payload.components.push(header);
            }
        }

        // for body
        if (_.isEmpty(bodyContent)) {
            return dispatchSnackbarError("Body text is required.")
        }
        if (!_.isEmpty(bodyContent)) {
            const _bodyVariables = getVariableKeys(bodyContent);
            if (_bodyVariables.length > 0) {
                payload.components.push({
                    "type": "BODY",
                    "text": bodyContent,
                    "example": {
                        "body_text": [bodyContentVariables.map((variable) => variable.value)]
                    }
                })
            }
            else {
                payload.components.push({ "type": "BODY", "text": bodyContent })
            }
        }

        // for footer
        if (!_.isEmpty(footerText)) {
            const footerVariables = getVariableKeys(footerText);
            if (footerVariables.length > 0) return dispatchSnackbarError("Footer text cannot contain variables.")
            payload.components.push({
                type: "FOOTER",
                text: footerText
            });
        }

        // for buttons
        if (!_.isEmpty(buttons)) {
            for (const button of buttons.buttons) {
                if (button.type === "QUICK_REPLY") {
                    const quickReplyVariables = getVariableKeys(button.text);
                    if (quickReplyVariables.length > 0) return dispatchSnackbarError("Quick reply text cannot contain variables.")
                }
                else if (button.type === "URL" && button.text) {
                    if (!validator.isURL(button.url)) {
                        return dispatchSnackbarError("Please enter valid url invalid.")
                    }
                }
                else if (button.type === "PHONE_NUMBER" && button.text) {
                    if (_.isEmpty(button.phone_number)) {
                        return dispatchSnackbarError("Phone number is required.")
                    }
                    else if (!button.phone_number.includes("+")) {
                        return dispatchSnackbarError("Phone number must start with +")
                    }
                    else if (button.phone_number.length < 6) {
                        return dispatchSnackbarError("Phone number must be at least 6 characters long")
                    }
                }
            }
            const filtered = buttons.buttons.filter(button => button.text);
            if (filtered.length > 0) {
                payload.components.push({
                    type: "BUTTONS",
                    buttons: filtered
                });
            }
        }

        props.handleSave(payload)
    }

    return <div>
        <div className="card shadow-none rounded-0">
            <div className="card-header d-flex flex-stack align-items-center">
                <h3 className="card-title fw-bolder text-dark">Add Whatsapp Template</h3>
                <div>
                    <Button variant="primary" size="sm" onClick={handleSave} disabled={props.createEditLoading}>
                        {props.createEditLoading && <Spinner animation="border" className="mr-10px w-16px h-16px" />}
                        Save
                    </Button>
                </div>
            </div>
            <div className="whatsapp-add-template-wrapper">
                <div className="whatsapp-add-template-section1 p-8">
                    <div className="meta-fields">
                        <div className="general-fields">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Group>
                                        <Form.Label>Template name</Form.Label>
                                        <Form.Control type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} maxLength={512} />
                                        <FormHelperText className="fs-7">The message template name can only have lowercase letters and underscores.</FormHelperText>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <SimpleDropdown
                                            value={category}
                                            options={whatsappCategoryOptions}
                                            label={"Select template category"}
                                            handleSelect={(value) => setCategory(value.value)}
                                            showSearch
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Form.Label>Language</Form.Label>
                                        <SimpleDropdown
                                            value={language}
                                            options={whatsappLanguageOptions}
                                            label={"Select template language"}
                                            handleSelect={(value) => setLanguage(value.value)}
                                            showSearch
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <WhatsappAddTemplateHeader
                            header={header}
                            setHeader={setHeader}
                            headerVariables={headerVariables}
                            setHeaderVariables={setHeaderVariables}
                            integrationId={props.integrationId}
                        />
                        <br />
                        <WhatsappAddTemplateBody
                            bodyContent={bodyContent}
                            setBodyContent={setBodyContent}
                            bodyContentVariables={bodyContentVariables}
                            setBodyContentVariables={setBodyContentVariables} />
                        <br />
                        <WhatsappAddTemplateFooter footerText={footerText} setFooterText={setFooterText} />
                        <br />
                        <WhatsappAddTemplateButton buttons={buttons} setButtons={setButtons} />
                    </div>
                </div>
                <div className="whatsapp-add-template-section2 ">
                    <WhatsappAddTemplatePreview components={components} integrationId={props.integrationId} />
                </div>
            </div>
        </div>
    </div>
}