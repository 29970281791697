import { takeLatest, put, call, select } from "redux-saga/effects";
import _ from "lodash";
import * as dealListActions from "./actions";
import * as dealPipelineActions from "../dealPipeline/actions";
import {
  DEALLIST_FIELDS_REQUEST,
  DEALLIST_SHOWORHIDE_REQUEST,
  UPDATE_COLUMNFIELD_PRIORITY_REQUEST,
  GET_DEAL_LIST_REQUEST,
  DEALCELLEDIT_REQUEST,
  GET_DEAL_LIST_REQUEST_AFTER,
  BULK_DELETE_DEALS_REQUEST,
  getDealAddFormFields,
  generateDealAIWFData,
} from "./types";
import api from "../../../network/apis/api";
import { dispatchSnackbarSuccess } from "../../../utils/toaster";

function* getDealListFields(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.DealList.dealFields.list) && store.DealList.dealFields.list.length > 0) {
      yield put(dealListActions.getDealListFieldsSuccess(store.DealList.dealFields.list));
    } else {
      const res = yield call(api.get, `/api/crm/data-fields/get?fieldType=DEAL`);
      if (res.status === 200) {
        yield put(dealListActions.getDealListFieldsSuccess(res.data.data));
      }
    }
  } catch (err) {
    yield put(dealListActions.getDealListFieldsError(err));
  }
}

function* showOrHideColumnField(action) {
  try {
    yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
  } catch (e) {
    yield put(dealListActions.showOrHideDealListColumnFieldsError(e));
  }
}

function* updateColumnFieldPriority(action) {
  try {
    yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
  } catch (e) {
    yield put(dealListActions.updateColumnFieldsPriorityError(e));
  }
}

function* getDealList(action) {
  try {
    const response = yield call(api.get, "/api/crm/deals", action.payload);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message, "success")
      yield put(dealListActions.getDealListSuccess(response.data));
    } else {
      yield put(dealListActions.getDealListError({ message: "Could not get deals data " }));
    }
  } catch (e) {
    yield put(dealListActions.getDealListError({ message: "Could not get deals data" }));
  }
}

function* dealCellEdit(action) {
  try {
    const response = yield call(api.patch, "/api/crm/deals/edit", action.payload.editData);
    if (response.status === 200) {
      yield put(dealListActions.dealCellEditSuccess({ message: "Lead Edited Successfully" }));
      yield put(dealListActions.getDealListRequestAfter(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(dealListActions.dealCellEditError({ message: response.data.message }));
    } else {
      yield put(dealListActions.dealCellEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(dealListActions.dealCellEditError({ message: "Could Not Edit" }));
  }
}

function* generateDealAIWFDataFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/ai-wizard/ai-wizard-field/generate", action.payload.data);
    // const response = yield new Promise(resolve => setTimeout(() => resolve({ status: 200, data: { message: "Success" } }), 2000));
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(dealListActions.generateDealAIWFDataSuccess({ message: response.data.message }));
      yield put(dealPipelineActions.getDealPopupDetailsRequestAfter(action.payload.data.moduleId));
      if (action.payload.pageForm === "dealList" || _.isEmpty(action.payload.pageForm)) yield put(dealListActions.getDealListRequestAfter(action.payload.loadingData));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealPipelineActions.getDealRequestAfter(action.payload.loadingData));
    } else {
      yield put(dealListActions.generateDealAIWFDataError({ message: response.data.message }));
    }
  } catch (e) {
    yield put(dealListActions.generateDealAIWFDataError({ message: "Could Not Generate Data" }));
  }
}
function* bulkDeleteDeals() { }

function* getDealAddFormFieldsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.DealList.addForm.list) && store.DealList.addForm.list.length > 0) {
      yield put(dealListActions.getDealAddFormFieldsSuccess({ data: store.DealList.addForm.list }));
    } else {
      const response = yield call(api.get, "/api/crm/data-fields/add-form-fields", action.payload.request);
      if (response.status === 200) {
        // dispatchSnackbarSuccess(response.data.message)
        yield put(dealListActions.getDealAddFormFieldsSuccess(response.data));
      } else if (response.status === 400) {
        // dispatchSnackbarError(response.data.message)
        yield put(dealListActions.getDealAddFormFieldsError(response.data));
      } else {
        // dispatchSnackbarError("Error while reordering")
        yield put(dealListActions.getDealAddFormFieldsError("error"));
      }
    }
  } catch (e) {
    // dispatchSnackbarError("Error while reordering")
    yield put(dealListActions.getDealAddFormFieldsError(e));
  }
}

export default function* () {
  yield takeLatest(DEALLIST_FIELDS_REQUEST, getDealListFields);
  yield takeLatest(DEALLIST_SHOWORHIDE_REQUEST, showOrHideColumnField);
  yield takeLatest(UPDATE_COLUMNFIELD_PRIORITY_REQUEST, updateColumnFieldPriority);
  yield takeLatest(GET_DEAL_LIST_REQUEST, getDealList);
  yield takeLatest(GET_DEAL_LIST_REQUEST_AFTER, getDealList);
  yield takeLatest(DEALCELLEDIT_REQUEST, dealCellEdit);
  yield takeLatest(BULK_DELETE_DEALS_REQUEST, bulkDeleteDeals);
  yield takeLatest(getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_REQUEST, getDealAddFormFieldsFunction);
  yield takeLatest(generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_REQUEST, generateDealAIWFDataFunction);
}
