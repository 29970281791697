import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { getCadenceSubscriberAnalyticsRequest } from "../../store/actions/cadence_lead_actions";
import { getDaysHoursMinutesOfStep } from "../cadence-analytics/utils/functions";
import { OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import CustomSidePanel from "../../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import { SidebarTabs } from "../../../../../components/side-pannel-detailed/custom-components/tabs";

const statusToMessageMapping = {
  // task
  TASK_CREATED: "Activity Created",
  TASK_COMPLETED: "Activity Completed",
  TASK_ERRORED: "Activity Error",

  // email
  EMAIL_CREATED: "Email Activity Created",
  EMAIL_COMPLETED: "Email Activity Completed",
  EMAIL_SENT: "Email Sent",
  EMAIL_OPENED: "Email Opened",
  EMAIL_REPLIED: "Email Replied",
  EMAIL_CLICKED: "Email Clicked",
  EMAIL_ERRORED: "Email Error",
  EMAIL_UNSUBSCRIBED: "Email Unsubscribed",

  // sms
  SMS_SENT: "SMS Sent",
  SMS_DELIVERED: "SMS Delivered",
  SMS_UNDELIVERED: "SMS Undelivered",
  SMS_ERRORED: "SMS Error",

  // whatsapp
  WHATSAPP_SENT: "WhatsApp Sent",
  WHATSAPP_READ: "WhatsApp Read",
  WHATSAPP_DELIVERED: "WhatsApp Delivered",
  WHATSAPP_ERRORED: "WhatsApp Error",
};

const subscribeStatsGroup = [
  {
    headName: "Activity",
    stats: [
      {
        name: "Activity Created",
        multiName: "Activities Created",
        key: "activityCreated",
        color: "#00EC09",
      },
      {
        name: "Activity Completed",
        multiName: "Activities Completed",
        key: "activityCompleted",
        color: "#A3FFA6",
      },
    ],
  },
  {
    headName: "Business Email",
    stats: [
      {
        name: "Business Email Sent",
        multiName: "Business Emails Sent",
        key: "syncEmailSent",
        color: "#60B563",
      },
      {
        name: "Business Email Opened",
        multiName: "Business Emails Opened",
        key: "syncEmailOpened",
        color: "#252597",
      },
      {
        name: "Business Email Clicked",
        multiName: "Business Emails Clicked",
        key: "syncEmailClicked",
        color: "#6170A0",
      },
      {
        name: "Business Email Replied",
        multiName: "Business Emails Replied",
        key: "syncEmailReplied",
        color: "#FF94CC",
      },
      {
        name: "Business Email Unsubscribed",
        multiName: "Business Emails Unsubscribed",
        key: "syncEmailUnsubscribed",
        color: "#FF7373",
      },
    ],
  },
  {
    headName: "Campaign Email",
    stats: [
      {
        name: "Campaign Email Sent",
        multiName: "Campaign Emails Sent",
        key: "campaignEmailSent",
        color: "#D2FBF0",
      },
      {
        name: "Campaign Email Opened",
        multiName: "Campaign Emails Opened",
        key: "campaignEmailOpened",
        color: "#FFCCB0",
      },
      {
        name: "Campaign Email Clicked",
        multiName: "Campaign Emails Clicked",
        key: "campaignEmailClicked",
        color: "#C5AB82",
      },
      {
        name: "Campaign Email Replied",
        multiName: "Campaign Emails Replied",
        key: "campaignEmailReplied",
        color: "#E5EFFF",
      },
      {
        name: "Campaign Email Unsubscribed",
        multiName: "Campaign Emails Unsubscribed",
        key: "campaignEmailUnsubscribed",
        color: "#FF7373",
      },
    ],
  },
  {
    headName: "SMS",
    stats: [
      {
        name: "SMS Sent",
        multiName: "SMS Sent",
        key: "smsSent",
        color: "#FFDFFF",
      },
      {
        name: "SMS Delivered",
        multiName: "SMS Delivered",
        key: "smsDelivered",
        color: "#9898FA",
      },
      {
        name: "SMS Undelivered",
        multiName: "SMS Undelivered",
        key: "smsUnDelivered",
        color: "#CCD8FF",
      },
    ],
  },
  {
    headName: "Whatsapp",
    stats: [
      {
        name: "Whatsapp Sent",
        multiName: "Whatsapp Sent",
        key: "whatsappSent",
        color: "#FFDFFF",
      },
      {
        name: "Whatsapp Delivered",
        multiName: "Whatsapp Delivered",
        key: "whatsappDelivered",
        color: "#9898FA",
      },
      {
        name: "Whatsapp Read",
        multiName: "Whatsapp Read",
        key: "whatsappRead",
        color: "#FFCCB0",
      }
    ],
  },
  {
    headName: "Others",
    stats: [
      {
        name: "Step Execution Error",
        multiName: "Step Execution Errors",
        key: "errored",
        color: "#FF0000",
      },
    ],
  },
];

export default function CadenceSubscriberAnaytics(props) {
  const [selectedTab, setSelectedTab] = useState("Stepwise Execution");
  const [categoryList] = useState(["Stepwise Execution", "Subscriber Stats"]);
  const [stepData, setStepData] = useState([]);

  const dispatch = useDispatch();
  const subscriberStatsStore = useSelector((store) => store.cadence.subscriberStats);

  useEffect(() => {
    if (props.cadenceId && props.subscriberId) dispatch(getCadenceSubscriberAnalyticsRequest({ cadenceId: props.cadenceId, subscriberId: props.subscriberId }));
  }, [dispatch, props.cadenceId, props.subscriberId]);

  useEffect(() => {
    setStepData(_.sortBy(subscriberStatsStore.data, ["executionOrder"]));
  }, [subscriberStatsStore.data]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <CustomSidePanel show={props.show} moduleName={"Subscriber Analytics"} handleClose={() => props.setShow(false)} showNextButton={false} showPreviosButton={false} customStyles={{ right: "51vw" }}>
        <Tab.Container defaultActiveKey={selectedTab}>
          <SidebarTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={categoryList} />
          <div>
            {selectedTab === "Stepwise Execution" && (
              <div>
                {subscriberStatsStore.loading ? (
                  <ContentWithCardLoading />
                ) : subscriberStatsStore.data.length === 0 ? (
                  <div className="p-8">No steps</div>
                ) : (
                  <div className="p-8">
                    {stepData.map((step, index) => {
                      return (
                        <div className="w-100 my-4" key={index}>
                          <div className="d-flex">
                            <span className="step-number text-primary mr-2">{`${step.channelType}`} - </span>
                            <span>Day {getDaysHoursMinutesOfStep(subscriberStatsStore.data, index).d + 1}</span>
                          </div>
                          <div className="d-flex gap-2 w-100 flex-center my-2 align-items-start">
                            <div className="w-30px h-30px d-flex flex-center bg-light-primary rounded-pill">{index + 1}</div>
                            <div className="w-100">
                              <div className={`card px-4 py-2 w-100 bg-light-light`}>
                                <div className=" d-flex w-100 justify-content-between align-items-center">
                                  <div className="d-flex gap-3 w-30">{_.capitalize(step?.name)}</div>
                                </div>
                              </div>
                              <div className="w-100">
                                {step.responses.map((response, subIndex) => {
                                  return (
                                    <div className="card px-4 py-2 w-100 my-2" key={subIndex}>
                                      <div className=" d-flex w-100 justify-content-between align-items-center">
                                        <div className="d-flex w-100 gap-3 w-30">
                                          {statusToMessageMapping[`${step.channelType}_${response.status}`]}
                                          {response.status === "ERRORED" && response.response && <span className="text-danger">{response.response.error||response.response.remark}</span>}
                                        </div>
                                        <div className="d-flex gap-3 w-30">
                                          <OverlayTrigger overlay={<Tooltip>Subscribed At</Tooltip>}>
                                            <span>{moment(response.createdAt).format("MMM DD, h:mm a")}</span>
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {selectedTab === "Subscriber Stats" && (
              <div className="p-8">
                {subscribeStatsGroup.map((group, index) => {
                  return (
                    <div key={index} className="mb-8">
                      <div className="fs-4 fw-500 mb-3">{group.headName}</div>
                      <div className="d-flex flex-wrap gap-4">
                        {group.stats.map((stat, subIndex) => {
                          return (
                            <div
                              key={subIndex}
                              className={`d-flex flex-column w-150px p-5 rounded-3 border-width-2px border-style-solid ${(subscriberStatsStore.stats[stat.key] || 0) > 0 ? "border-color-0095F6" : "border-color-E1E1E1"}`}
                            >
                              <div className="fs-2tx">{subscriberStatsStore.stats[stat.key] || 0}</div>
                              <div className="fs-5">{(subscriberStatsStore.stats[stat.key] || 0) > 1 ? stat.multiName : stat?.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Tab.Container>
      </CustomSidePanel>
    </div>
  );
}
