import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import FormInput from "../../../../components/Form-Field/index";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/settings.actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import "./style.css";
import CustomAction from "../../../../components/ActionColumn/index";
import Select from "react-select/creatable";
import { Spinner } from "react-bootstrap";
import validator from "validator";
import { UpgradePlanPage } from "../../../../components/empty_page";
import SubscriptionPlanModal from "../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import { TabWithActions } from "./tab-with-actions";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import DynamicNormalTable from "../../../../components/normal-table/dynamic-normal-table";
import { TestUrlIconButton } from "../../../../components/button/icon-action-buttons";

// custom style for Country Select
const customStyles = {
  control: () => ({
    background: "#F3F6F9",
    // height: '45px',
    padding: "7px",
  }),
  indicatorsContainer: () => ({
    position: "absolute",
    top: "3px",
    right: 0,
    display: "flex",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
  placeholder: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
};

const options = [
  { value: "Lead Added", label: "Lead Added" },
  { value: "Lead Updated", label: "Lead Updated" },
  { value: "Lead Merged", label: "Lead Merged" },
  { value: "Lead Deleted", label: "Lead Deleted" },
  { value: "Deal Added", label: "Deal Added" },
  { value: "Deal Updated", label: "Deal Updated" },
  { value: "Deal Deleted", label: "Deal Deleted" },
  { value: "People Added", label: "People Added" },
  { value: "People Updated", label: "People Updated" },
  { value: "People Merged", label: "People Merged" },
  { value: "People Deleted", label: "People Deleted" },
  { value: "Company Added", label: "Company Added" },
  { value: "Company Updated", label: "Company Updated" },
  { value: "Company Merged", label: "Company Merged" },
  { value: "Company Deleted", label: "Company Deleted" },
  { value: "Product Added", label: "Product Added" },
  { value: "Product Updated", label: "Product Updated" },
  { value: "Product Merged", label: "Product Merged" },
  { value: "Product Deleted", label: "Product Deleted" },
  { value: "Activity Added", label: "Activity Added" },
];

function ButtonHeader(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  // const [action, setAction] = useState('');
  // const [permission, setPermission] = useState('');
  const [endPoint, setendPoint] = useState("");
  const [nameWebhook, setnameWebhook] = useState("");
  const [webhookAction, setwebhookAction] = useState("");
  // const [selectedActionOption,setselectedActionOption] =useState('Select Action')
  // const [selectedPermissionOption,setselectedPermissionOption] = useState('Select Permission')

  // const handleChangeAction = (e) => {
  //   setselectedActionOption(e)
  //   setAction(e)
  // }

  // const handleChangepermission = (e) => {
  //   setselectedPermissionOption(e)
  //   setPermission(e)
  // }

  const handleClose = () => {
    setShow(false);
    setnameWebhook("");
    setwebhookAction("");
    setendPoint("");
  };

  const handleEndPointChange = (e) => {
    setendPoint(e.target.value);
  };

  const handleNameChange = (e) => {
    setnameWebhook(e.target.value);
  };

  function dropChanges(selectedOption) {
    try {
      let newArr = [];
      selectedOption.map((data) => {
        newArr.push(data.value);
        return null;
      });
      setwebhookAction(newArr);
    } catch (e) { }

    if (selectedOption === null) {
      setwebhookAction([]);
    }
  }

  const AddWebHook = () => {
    if (!nameWebhook) {
      dispatchSnackbarError("Please enter webhook name!");
      setShow(true);
    } else if (!endPoint) {
      dispatchSnackbarError("Please provide endpoint url");
      setShow(true);
    } else if (!validator.isURL(endPoint)) {
      dispatchSnackbarError("Please provide valid endpoint url");
      setShow(true);
    }
    // else if(endPoint.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g) === null){
    //   dispatchSnackbarError("Please provide valid endpoint url")
    //   setShow(true)
    // }
    else if (!webhookAction) {
      dispatchSnackbarError("Please select webhook action");
      setShow(true);
    } else {
      let addWebhookObj = {
        name: nameWebhook,
        endpoint: endPoint,
        actions: webhookAction,
        // permission: permission
      };
      dispatch(
        actions.addWebHookRequest(addWebhookObj, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Webhook Successfully Added", "success");
            dispatch(
              actions.getWebHookRequest((data) => {
                props.set(data.data);
              }),
            );
            setShow(false);
            setnameWebhook("");
            setwebhookAction("");
            setendPoint("");
          }
        }),
      );
    }
  };

  return (
    <>
      <div id="kt_signin_password_button" className="ms-auto" onClick={handleShow}>
        <Button className="commonStyle btn btn-sm btn-primary my-1">+ Webhook</Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Webhooks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-14px">Name* </span>
            <FormInput placeholder={"Enter webhook name"} type={"text"} name={"name"} value={nameWebhook} onChange={(e) => handleNameChange(e)} />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-14px">Endpoint URL* </span>
            <FormInput placeholder={"Enter webhook endpoint url"} type={"text"} name={"endpoint"} value={endPoint} onChange={(e) => handleEndPointChange(e)} />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <label className="form-label fw-bolder text-dark fs-6 fw-300">Actions*</label>
            <Select
              styles={customStyles}
              placeholder=""
              // value={options[7]}
              onChange={dropChanges}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              noOptionsMessage={() => null}
              isValidNewOption={() => false}
              isMulti
            />
          </div>
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={() => AddWebHook()}>
            Add Webhook
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function WebHook() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [webhookTable, setwebhookTable] = useState([]);

  // const [action, setAction] = useState('');
  // const [permission, setPermission] = useState('');
  const [endPoint, setendPoint] = useState("");
  const [webhookId, setwebhookId] = useState("");
  const [nameWebhook, setnameWebhook] = useState("");
  const [selectedActionOption, setselectedActionOption] = useState();
  // const [selectedPermissionOption,setselectedPermissionOption] = useState();
  const [webhookAction, setwebhookAction] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const [showPlans, setShowPlans] = useState(false);
  const links = [
    { name: "API Keys", to: "/settings/account/developer/api-key" },
    { name: "Webhook", to: "/settings/account/developer/webhook" },
  ];
  const tabName = "Webhook";

  useEffect(() => {
    setLoader(true);
    dispatch(
      actions.getWebHookRequest((data) => {
        setwebhookTable(data.data);
        setLoader(false);
      }),
    );
  }, [dispatch]);

  /*******
   * delete webhook
   * ***************/

  const deleteWebhook = (data) => {
    dispatch(
      actions.deleteWebHookRequest(data._id, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Webhook Successfully Deleted", "success");
          dispatch(
            actions.getWebHookRequest((data) => {
              setwebhookTable(data.data);
            }),
          );
        }
      }),
    );
  };

  /**********
   * edit webhook
   * ***************/

  const EditWebhoook = (data) => {
    setShow(true);
    // setAction(data.action)
    setnameWebhook(data.name);
    // setPermission(data.permission)
    setendPoint(data.endpoint);
    setwebhookId(data._id);

    setwebhookAction(data.actions);

    let b = [];

    for (let i = 0; i < data.actions.length; i++) {
      let obj = {
        value: data.actions[i],
        label: data.actions[i],
      };
      b.push(obj);
    }

    // setselectedActionOption([{ value: "Lead Added", label: "Lead Added" }])

    setselectedActionOption(b);
    // setselectedPermissionOption(data.permission)
  };

  const handleEndPointChange = (e) => {
    setendPoint(e.target.value);
  };

  const handleNameChange = (e) => {
    setnameWebhook(e.target.value);
  };

  function dropChanges(selectedOption) {
    try {
      let newArr = [];
      selectedOption.map((data) => {
        newArr.push(data.value);
        return null;
      });
      var unique = newArr.filter((v, i, a) => a.indexOf(v) === i);
      setwebhookAction(unique);
    } catch (e) { }

    if (selectedOption === null) {
      setwebhookAction([]);
    }
  }

  const EditWebhook = () => {
    if (!nameWebhook) {
      dispatchSnackbarError("Please enter webhook name!");
      setShow(true);
    } else if (!endPoint) {
      dispatchSnackbarError("Please provide endpoint url");
      setShow(true);
    } else if (!validator.isURL(endPoint)) {
      dispatchSnackbarError("Please provide valid endpoint url");
      setShow(true);
    } else if (!webhookAction) {
      dispatchSnackbarError("Please select webhook action");
      setShow(true);
    } else {
      let editWebhookObj = {
        id: webhookId,
        name: nameWebhook,
        endpoint: endPoint,
        actions: webhookAction,
        // permission: permission
      };
      dispatch(
        actions.patchWebHookRequest(editWebhookObj, webhookId, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Webhook Successfully Updated", "success");

            dispatch(
              actions.getWebHookRequest((data) => {
                setwebhookTable(data.data);
              }),
            );
            // dispatch(actions.getWebHookRequest((data) => {
            // }))
            setShow(false);
          }
        }),
      );
    }
  };

  const testUrlFunction = (user) => {
    dispatch(
      actions.testWebhookRequest({ url: user.endpoint }, (data) => {
        if (data["status"]) {
          dispatchSnackbarSuccess("Successfully Test the Url", "success");
        } else {
          dispatchSnackbarError("Url testing failed", "error");
        }
      }),
    );
  };

  const ActDecAct = (user, e) => {
    let obj = {};

    if (!e.target.checked) {
      obj = {
        id: user._id,
        status: "disable",
      };
    } else {
      obj = {
        id: user._id,
        status: "enable",
      };
    }

    dispatch(
      actions.disableWebhookRequest(obj, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Webhook Successfully Updated", "success");
          dispatch(
            actions.getWebHookRequest((data) => {
              setwebhookTable(data.data);
            }),
          );
        }
      }),
    );
  };

  /***************************************************************************************/

  return (
    <>
      <TabWithActions
        links={links}
        active={tabName}
        actions={
          accountBillingStore.data.servicesOffered &&
          accountBillingStore.data.servicesOffered.API_AND_WEBHOOK && (
            <>
              <span className="mr-2 px-2">
                <a target={"_blank"} rel="noopener noreferrer" href="https://apidocs.salessimplify.com/#webhooks">
                  Webhook Documentation
                </a>
              </span>
              <ButtonHeader set={setwebhookTable} />
            </>
          )
        }
      />
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.API_AND_WEBHOOK ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Webhook"}
            planName={"free plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : webhookTable.length === 0 && loader === false ? (
        <CustomEmptyPage page="webhook" customButton={<ButtonHeader set={setwebhookTable} />} />
      ) : (
        <DynamicNormalTable applyWidth={false} tableHeading={["Name", "Endpoint URL", "Events", "Status", "Actions"]} tableTitle={<div>Webhook</div>} buttonHead={<></>}>
          {loader === true && (
            <tr>
              <td>
                <Spinner className="position-absolute mt-15-percent ml-44-percent" animation="border" variant="primary" />
              </td>
            </tr>
          )}

          {webhookTable.length === 0 && loader === false && (
            <tr>
              <td colSpan="4" className="text-center">
                <h6>No Data To Show</h6>
              </td>
            </tr>
          )}

          {webhookTable.map((user, id) => {
            return (
              <tr key={id}>
                <td align="start" className="text-break">
                  {user.name} <span className="text-primary">(version {user.version ? user.version : 0})</span> {!user.version && <span className="text-danger">(deprecate)</span>}
                </td>
                <td align="start" className="text-break">
                  {user.endpoint}
                </td>
                <td align="center" className="text-break">
                  {user.actions && user.actions.length > 0 && user.actions.join(" , ")}
                </td>
                <td align="center">
                  <div className="form-check form-switch form-check-custom form-check-solid d-flex justify-content-center">
                    <input
                      className="form-check-input h-20px w-35px"
                      type="checkbox"
                      onChange={(e) => {
                        ActDecAct(user, e);
                      }}
                      checked={user.status === "enable" ? true : false}
                      name="ActDeact"
                    />
                    <label className="form-check-label" htmlFor="favoriteSwitch"></label>
                  </div>
                </td>
                <td align="center" className="d-flex justify-content-center border-0 align-items-center ">
                  <TestUrlIconButton onClick={() => testUrlFunction(user)} />
                  <div className="ml-12px">
                    <CustomAction Edit={() => EditWebhoook(user)} Delete={() => deleteWebhook(user)} />
                  </div>
                </td>
              </tr>
            );
          })}
        </DynamicNormalTable>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Webhooks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-14px">Name* :</span>
            <FormInput placeholder={"Enter webhook name"} type={"text"} name={"name"} value={nameWebhook} onChange={(e) => handleNameChange(e)} />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-14px">Endpoint URL* :</span>
            <FormInput placeholder={"Enter webhook endpoint url"} type={"text"} name={"endpoint"} value={endPoint} onChange={(e) => handleEndPointChange(e)} />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <label className="form-label fw-bolder text-dark fs-6 fw-300">Actions*</label>
            <Select
              styles={customStyles}
              placeholder=""
              closeMenuOnSelect={false}
              defaultValue={selectedActionOption}
              onChange={dropChanges}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              noOptionsMessage={() => null}
              isValidNewOption={() => false}
              isMulti
            />
          </div>

          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={() => EditWebhook()}>
            Edit Webhook
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
