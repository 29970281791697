import React, { useState } from "react";
import InlineStyled from "../inline-styled/inline-styled";

export default function DynamicNormalTable(props) {
  const [heading] = useState(props.tableHeading);
  const [tableTitle] = useState(props.tableTitle);

  return (
    <>
      <div className="card mb-5 mb-xl-10 overflow-y-auto h-vh-200px-mx border-style-hidden">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="card-title">
            <h3 className="card-title fw-bolder">{tableTitle}</h3>
          </div>
          <div className="card-toolbar">{props.buttonHead}</div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
              <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  {heading.map((each, index) => {
                    return (
                      <InlineStyled as="td" styles={{ width: `${100 / heading.length}%` }} className={index !== 0 ? "text-center" : "text-left"} key={index}>
                        {each}
                      </InlineStyled>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="fw-6 fw-bold text-gray-600">{props.children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
