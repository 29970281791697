import { getCampaignPricing } from "../types/campaign_common_settings_type";

export const getCampaignPricingRequest = (payload) => ({
  type: getCampaignPricing.GET_CAMPIAGN_PRICING_REQUEST,
  payload,
});
export const getCampaignPricingSuccess = (payload) => ({
  type: getCampaignPricing.GET_CAMPIAGN_PRICING_SUCCESS,
  payload,
});
export const getCampaignPricingError = (payload) => ({
  type: getCampaignPricing.GET_CAMPIAGN_PRICING_ERROR,
  payload,
});
