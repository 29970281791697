import {
    getListOfWhatsappAccounts, createWhatsappAccount, deleteWhatsappAccount, getWhatsappNumberProfile, updateWhatsappNumberProfile,
    uploadWhatsappNumberProfilePhoto, updateWhatsappAccountCredentials, updateWhatsappPerDayLimit, getWhatsappConsumptionCount
} from "../types/campaign_whatsapp_settings_type";

export const getListOfWhatsappAccountsRequest = (payload, cb) => ({
    type: getListOfWhatsappAccounts.REQUEST,
    payload,
    cb
})
export const getListOfWhatsappAccountsSuccess = (payload) => ({
    type: getListOfWhatsappAccounts.SUCCESS,
    payload
})
export const getListOfWhatsappAccountsFailure = (payload) => ({
    type: getListOfWhatsappAccounts.FAILURE,
    payload
})

export const createWhatsappAccountRequest = (payload, cb) => ({
    type: createWhatsappAccount.REQUEST,
    payload,
    cb
})
export const createWhatsappAccountSuccess = (payload) => ({
    type: createWhatsappAccount.SUCCESS,
    payload
})
export const createWhatsappAccountFailure = (payload) => ({
    type: createWhatsappAccount.FAILURE,
    payload
})

export const deleteWhatsappAccountRequest = (payload, cb) => ({
    type: deleteWhatsappAccount.REQUEST,
    payload,
    cb
})
export const deleteWhatsappAccountSuccess = (payload) => ({
    type: deleteWhatsappAccount.SUCCESS,
    payload
})
export const deleteWhatsappAccountFailure = (payload) => ({
    type: deleteWhatsappAccount.FAILURE,
    payload
})

export const updateWhatsappAccountCredentialsRequest = (payload, cb) => ({
    type: updateWhatsappAccountCredentials.REQUEST,
    payload,
    cb
});
export const updateWhatsappAccountCredentialsSuccess = (payload) => ({
    type: updateWhatsappAccountCredentials.SUCCESS,
    payload
});
export const updateWhatsappAccountCredentialsFailure = (payload) => ({
    type: updateWhatsappAccountCredentials.FAILURE,
    payload
});

export const getWhatsappNumberProfileRequest = (payload, cb) => ({
    type: getWhatsappNumberProfile.REQUEST,
    payload,
    cb
})
export const getWhatsappNumberProfileSuccess = (payload) => ({
    type: getWhatsappNumberProfile.SUCCESS,
    payload
})
export const getWhatsappNumberProfileFailure = (payload) => ({
    type: getWhatsappNumberProfile.FAILURE,
    payload
})

export const updateWhatsappNumberProfileRequest = (payload, cb) => ({
    type: updateWhatsappNumberProfile.REQUEST,
    payload,
    cb
})
export const updateWhatsappNumberProfileSuccess = (payload) => ({
    type: updateWhatsappNumberProfile.SUCCESS,
    payload
})
export const updateWhatsappNumberProfileFailure = (payload) => ({
    type: updateWhatsappNumberProfile.FAILURE,
    payload
})

export const uploadWhatsappNumberProfilePhotoRequest = (payload, cb) => ({
    type: uploadWhatsappNumberProfilePhoto.REQUEST,
    payload,
    cb
})
export const uploadWhatsappNumberProfilePhotoSuccess = (payload) => ({
    type: uploadWhatsappNumberProfilePhoto.SUCCESS,
    payload
})
export const uploadWhatsappNumberProfilePhotoFailure = (payload) => ({
    type: uploadWhatsappNumberProfilePhoto.FAILURE,
    payload
})

export const updateWhatsappPerDayLimitRequest = (payload, cb) => ({
    type: updateWhatsappPerDayLimit.REQUEST,
    payload,
    cb
})
export const updateWhatsappPerDayLimitSuccess = (payload) => ({
    type: updateWhatsappPerDayLimit.SUCCESS,
    payload
})
export const updateWhatsappPerDayLimitFailure = (payload) => ({
    type: updateWhatsappPerDayLimit.FAILURE,
    payload
})

export const getWhatsappConsumptionCountRequest = (payload, cb) => ({
    type: getWhatsappConsumptionCount.REQUEST,
    payload,
    cb
})
export const getWhatsappConsumptionCountSuccess = (payload) => ({
    type: getWhatsappConsumptionCount.SUCCESS,
    payload
})
export const getWhatsappConsumptionCountFailure = (payload) => ({
    type: getWhatsappConsumptionCount.FAILURE,
    payload
})
