import React from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Form } from "react-bootstrap";
import "./styles.scss";

export default function SenderIdCountrySelect(props) {
  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      props.setSelectedValue(props.options);
    } else if (event.action === "select-option" && event.option.value === "***") {
      props.setSelectedValue([]);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      props.setSelectedValue([]);
    } else if (event.action === "deselect-option") {
      props.setSelectedValue(value.filter((o) => o.value !== "*"));
    } else if (value.length === props.options.length - 1) {
      props.setSelectedValue(props.options);
    } else {
      props.setSelectedValue(value);
    }
  }

  return (
    <Form.Group className="sender-id-selection">
      <Form.Label aria-label="select-senderId">Country Select</Form.Label>
      <ReactMultiSelectCheckboxes
        options={[
          // { label: "All", value: "*" }, { label: "Clear All", value: "***" },
          ...props.options,
        ]}
        value={props.selectedValue}
        onChange={onChange}
        setState={props.setSelectedValue}
        dropdownButton={{
          width: "100%",
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            padding: "10px",
            border: "1px solid rgb(228, 230, 239)",
          }),
        }}
      />
    </Form.Group>
  );
}
