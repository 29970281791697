import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { CustomScrollableModal } from "../../../../../components/modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../../../deals/utils";
import { DragHandler } from "../../../../deals/styled";
import "./styles.css";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function ReorderAddForm({ show, type, handleSubmit, handleClose, AddFormFields }) {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    if (AddFormFields.list.length > 0) {
      setItemList(AddFormFields.list);
    } else {
      setItemList([]);
    }
  }, [AddFormFields.list]);

  const onHandleSubmit = () => {
    const updatedData = itemList.map((item, index) => {
      return {
        _id: item._id,
        addFormPriority: index + 1,
      };
    });
    handleSubmit(updatedData);
  };

  const addFilterModalProps = {
    show: show,
    title: `Reorder ${toTitleCase(type)} Add Form`,
    showButtons: true,
    buttonText: "Save",
    handleClose: () => handleClose(),
    handleAccept: () => onHandleSubmit(),
    size: "lg",
  };
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(itemList, result.source.index, result.destination.index);
    setItemList(items);
  };
  const renderDragHandler = () => {
    return (
      <DragHandler>
        <i className="fa fa-bars fa-md font-size-18px" />
      </DragHandler>
    );
  };
  return (
    <CustomScrollableModal modalProps={addFilterModalProps}>
      {AddFormFields.loading ? (
        <div className="d-flex flex-center height-auto">
          {" "}
          <Spinner animation="border" variant="primary" />{" "}
        </div>
      ) : (
        <div className="reorder-add-form-wrapper">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div className="w-100">
                    <div className="head-field w-100">
                      <span className="w-40 text-center">Field Name</span>
                      <span className="w-40 text-center">Field Input Type</span>
                      <span className="w-20 text-center">Actions</span>
                    </div>
                    <div>
                      {itemList.map((item, index) => (
                        <Draggable key={item._id} draggableId={item._id} index={index}>
                          {(provided, snapshot) => (
                            <div className="field-row btn btn-outline btn-outline-dashed btn-outline-default p-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <span className="w-40 text-center p-5px">{item.fieldName}</span>
                              <span className="w-40 text-center">{item.fieldInputType}</span>
                              <span className="d-flex justify-content-center w-20">{renderDragHandler()}</span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </CustomScrollableModal>
  );
}
