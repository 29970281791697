import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Dropdown, Button, Form, Row, Col } from "react-bootstrap";
import RadioSelector from "../../../../../components/custom-inputs/radio-selector";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { InputLimit } from "../../../../../constants";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { CadenceConditionOptions, valueLabelMapping, winningPatternConditionOptions, winningPatternOptions } from "../../constants";
import "./styles.scss";

export default function ABVarientSettings(props) {
  const [show, setShow] = useState(false);

  const [winningPatternType, setWinningPatternType] = useState("Manually");
  const [winningCount, setWinningCount] = useState(50);
  const [condition, setCondition] = useState({});
  const [responseType, setResponseType] = useState({});

  useEffect(() => {
    if (!_.isEmpty(props.abTesting)) {
      if (props.abTesting.isAutomate) {
        setWinningPatternType("Automated");
        setWinningCount(props.abTesting.condition.value);
        setCondition({ value: props.abTesting.condition.type, label: valueLabelMapping[props.abTesting.condition.type] });
        setResponseType({ value: props.abTesting.condition.stat, label: valueLabelMapping[props.abTesting.condition.stat] });
      } else {
        setWinningPatternType("Manually");
        setWinningCount(50);
        setCondition({});
        setResponseType({});
      }
    }
  }, [props.abTesting]);

  const handleSave = () => {
    if (_.isEmpty(winningPatternType)) {
      return dispatchSnackbarError("Please select A/B test winning pattern");
    } else if (winningPatternType === "Automated") {
      if (_.isEmpty(responseType)) {
        return dispatchSnackbarError("Please select response type");
      }
      if (_.isEmpty(condition)) {
        return dispatchSnackbarError("Please select condition");
      }
      if (winningCount < 1) {
        return dispatchSnackbarError("Please select condition count");
      }
    }

    if (winningPatternType === "Automated") {
      const payload = {
        isAutomate: true,
        condition: {
          stat: responseType.value,
          type: condition.value,
          value: winningCount,
        },
      };
      props.handleSave(payload);
      setShow(false);
    } else {
      const payload = {
        isAutomate: false,
      };
      props.handleSave(payload);
      setShow(false);
    }
  };

  return (
    <Dropdown show={show} className="dropdown-inline hide-dropdonw-icon-inline days-popover">
      <Dropdown.Toggle as={Button} onClick={() => setShow(!show)} className="p-0 text-decoration-none" variant="link">
        + AB Settings
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-xl card w-100-mn width-fit-content">
        <Dropdown.Header className="p-4 border-bottom-1 fs-6 text-gray-900 bg-gray-3001 border-bottom-lg">AB Variant Settings</Dropdown.Header>
        <div className="day-dropdown-wrap" onClick={(e) => e.stopPropagation()} onSelect={(e) => e.stopPropagation()}>
          <Form.Group>
            <Form.Label>A/B Test Winning Pattern</Form.Label>
            <RadioSelector options={winningPatternOptions} value={winningPatternType} setValue={setWinningPatternType} />
          </Form.Group>
          <div className="mb-4">
            {winningPatternType === "Manually" && "Send the email variant that is enabled"}
            {winningPatternType === "Automated" && "Equal weightage to all variants until the following condition is fulfilled. Once a variant fulfils the condition, send that variant to the subscribers."}
          </div>
          {winningPatternType === "Automated" && (
            <Form.Group>
              <Form.Group>
                <Form.Label>Response Type</Form.Label>
                <SimpleDropdown value={responseType} label={"Select Response"} options={winningPatternConditionOptions} handleSelect={(a) => setResponseType(a)} />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Label>Condition</Form.Label>
                  <SimpleDropdown className="hide-dropdonw-icon" value={condition} label={"Select Priority"} options={CadenceConditionOptions} handleSelect={(a) => setCondition(a)} />
                </Col>
                <Col>
                  <Form.Label>Count</Form.Label>
                  <Form.Control maxLength={InputLimit.NUMBER} value={winningCount} onChange={(e) => setWinningCount(e.target.value)} type="number" />
                </Col>
              </Row>
            </Form.Group>
          )}
          <div className="footer d-flex justify-content-center mt-9 gap-3">
            <Button variant="secondary" size="sm" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" size="sm" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
