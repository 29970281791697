import React, { useState, useEffect } from "react";
import _ from "lodash";
import "../styles.css";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as leadBotActions from "../actions";
import { useParams, useHistory } from "react-router-dom";
import { CustomSubHeader } from "../../../components/subheader";
import { BlueGradientButton } from "../../../components/button";
import AccordionComponent from "../components/accordion-component";
import AddQuestionModel from "../components/add-question.model";
import * as leadActions from "../../leads/store/actions/actions";
import GeneralSettings from "../components/general-settings.builder";
import WelcomeMessage from "../components/welcome-message.builder";
import CustomisationSettings from "../components/customisation-settings.builder";
import ThankYouMessage from "../components/thank-you-message.builder";
import QuestionSettings from "../components/questions.builder";
import Preview from "../components/preview/preview";
import { defaultQuestions, defaultCustomisation, defaultThankYouScreen, defaultWelcomeScreen, defaultOneQuestion } from "./default.constant";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import CopyCodeModel from "../components/copy-code.model";
import ContentWrapper from "../../Wrapper";

export default function LeadBotBuilder() {
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [fieldMapping, setFieldMapping] = useState({});
  const { lead_bot_id } = useParams();
  const history = useHistory();
  const [editableQuestion, setEdiatbleQuestion] = useState(defaultOneQuestion);
  const [isChangesHappened, setIsChangesHappened] = useState(false);
  const [showScript, setShowScript] = useState(false);

  //general settings
  const [name, setName] = useState("");
  const [tags, setTags] = useState([]);
  const [assignee, setAssignee] = useState([]);

  //welcome message settings
  const [welcomeScreen, setWelcomeScreen] = useState(defaultWelcomeScreen);
  const [thankYouScreen, setThankYouScreen] = useState(defaultThankYouScreen);
  const [questions, setQuestions] = useState(defaultQuestions);
  const [customization, setCustomization] = useState(defaultCustomisation);
  const dispatch = useDispatch();

  //store
  const leadBot = useSelector((state) => state.leadBot.leadbot);
  const fieldStore = useSelector((state) => state.Leads.leadFields);

  useEffect(() => {
    if (lead_bot_id && lead_bot_id !== "new") {
      dispatch(leadBotActions.getLeadBotDataRequest(lead_bot_id));
    } else if (lead_bot_id && lead_bot_id === "new") {
      setIsChangesHappened(true);
      history.push("/lead-bot");
    }
  }, [dispatch, lead_bot_id, history]);

  useEffect(() => {
    if (leadBot.error) {
      history.push("/lead-bot");
    }
  }, [leadBot.error, history]);

  useEffect(() => {
    dispatch(leadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(leadBotActions.getTagsRequest());
    dispatch(leadBotActions.getOwnerRequest());
    dispatch(leadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(leadBot.data)) {
      const data = leadBot.data;
      setWelcomeScreen(data.welcomeScreen);
      setCustomization(data.customization);
      setQuestions(
        data.questionsScreen.map((each) => {
          if (_.isObject(each.fieldId)) each.fieldId = each.fieldId._id;
          return each;
        }),
      );
      setThankYouScreen(data.thankYouScreen);
      setName(data.name);
      setTags(data.tags);
      setAssignee(data.assignee);
    }
  }, [leadBot.data]);

  useEffect(() => {
    if (fieldStore.list.length > 0) {
      const _op = {};
      fieldStore.list.forEach((each) => {
        if (each.isEditable && each.fieldInputType !== "DATE_RANGE" && each.fieldInputType !== "DATE_TIME_RANGE") {
          _op[each._id] = each.fieldName;
        }
      });
      setFieldMapping(_op);
    }
  }, [fieldStore.list]);

  const handleQuestionNodeSave = (data) => {
    setShowAddQuestion(false);
    setIsEdit(false);
    setIsChangesHappened(true);
    if (data.type === "add") setQuestions([...questions, data.data]);
    else if (data.type === "edit") {
      const a = questions.map((each, i) => {
        if (i === editIndex) {
          return {
            ...each,
            ...data.data,
          };
        }
        return each;
      });
      setQuestions(a);
    }
  };
  const setValue = (oldData, setObjectName, keyName, value) => {
    setIsChangesHappened(true);
    setObjectName({
      ...oldData,
      [keyName]: value,
    });
  };

  const addQuestionModelProps = {
    show: showAddQuestion,
    isEdit: isEdit,
    editableQuestion: editableQuestion,
    fieldMapping: fieldMapping,
    handleClose: () => {
      setShowAddQuestion(false);
      setIsEdit(false);
    },
    handleSave: (data) => handleQuestionNodeSave(data),
  };

  const validate = () => {
    const urlRegex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
    if (_.isEmpty(name)) {
      return "Form name cannot be empty!";
    } else if (_.isEmpty(tags)) {
      return "Form tags cannot be empty!";
    } else if (_.isEmpty(assignee)) {
      return "Form owners cannot be empty!";
    }
    if (welcomeScreen.isEnabled) {
      if (welcomeScreen.mediaUrl && !welcomeScreen.mediaUrl.match(urlRegex)) {
        return "The url must end with image extension. Eg. png/svg/jpg/webp/gif/jpeg";
      } else if (_.isEmpty(welcomeScreen.descrption)) {
        return "Description in welcome screen cannot be empty!";
      } else if (_.isEmpty(welcomeScreen.buttonText)) {
        return "Button name in welcome screen cannot be empty!";
      }
    }
    if (thankYouScreen.isEnabled) {
      if (_.isEmpty(thankYouScreen.descrption)) {
        return "Description in thank you message screen cannot be empty!";
      }
    }
    if (_.isEmpty(questions)) {
      return "Kindly enter one question to save the Lead Bot.";
    }

    if (customization.showHeader) {
      if (_.isEmpty(customization.typeOfShowHeader)) {
        return "type of header in customisation cannot be empty!";
      }
      if (customization.typeOfShowHeader === "text" && _.isEmpty(customization.headerName)) {
        return "Header custome name in customisation cannot be empty!";
      }
      if (customization.typeOfShowHeader === "logo" && _.isEmpty(customization.headerLogo)) {
        return "Header custome logo in customisation cannot be empty!";
      }
      if (customization.typeOfShowHeader === "logo" && !customization.headerLogo.match(urlRegex)) {
        return "The url must end with image extension. Eg. png/svg/jpg/webp/gif/jpeg";
      }
    }
    return "";
  };

  const handleSaveLeadBot = () => {
    const validation = validate();
    if (validation) return dispatchSnackbarError(validation);

    if (lead_bot_id === "new") {
      dispatch(
        leadBotActions.createLeadBotRequest(
          {
            name: name,
            isEnabled: true,
            tags: tags,
            assignee: assignee,
            welcomeScreen: welcomeScreen,
            questionsScreen: questions,
            thankYouScreen: thankYouScreen,
            customization: customization,
          },
          (data) => {
            setIsChangesHappened(false);
            if (data.status === true) {
              dispatchSnackbarSuccess(data.message);
              history.push(`/lead-bot/builder/${data.data._id}`);
              setTimeout(() => setShowScript(true), 1000);
            } else if (data.message) {
              dispatchSnackbarError(data.message);
            }
          },
        ),
      );
    } else {
      dispatch(
        leadBotActions.editLeadBotDataRequest({
          _id: lead_bot_id,
          doc: {
            name: name,
            isEnabled: true,
            tags: tags,
            assignee: assignee,
            welcomeScreen: welcomeScreen,
            questionsScreen: questions,
            thankYouScreen: thankYouScreen,
            customization: customization,
          },
        }),
      );
      setIsChangesHappened(false);
      setTimeout(() => setShowScript(true), 1000);
    }
  };

  const onEditQuestionNode = (index) => {
    setIsChangesHappened(true);
    questions.forEach((each, i) => {
      if (i === index) {
        setEdiatbleQuestion(each);
        setShowAddQuestion(true);
        setIsEdit(true);
        setEditIndex(index);
      }
    });
  };

  // lead actions
  const addTag = (name, colorCode) => {
    dispatch(leadActions.leadTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(leadActions.leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(leadActions.leadTagsDeleteRequest(id));
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <>
              <div onClick={() => history.push("/lead-bot")} className="d-flex align-items-center cursor-pointer">
                <i className="bi bi-arrow-left text-333"></i>
                <span className="ml-2"> Back to leadbot list</span>
              </div>
            </>

            <>
              <BlueGradientButton variant="success" size="sm" disabled={!isChangesHappened} onClick={() => handleSaveLeadBot()}>
                {lead_bot_id === "new" ? "Publish" : "Publish"}
              </BlueGradientButton>
            </>
          </div>
        </CustomSubHeader>
      }
    >
      {leadBot.loading ? (
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="card scroll-y w-60">
            <div>
              <GeneralSettings
                name={name}
                setName={(a) => {
                  setName(a);
                  setIsChangesHappened(true);
                }}
                tags={tags}
                setTags={(a) => {
                  setTags(a);
                  setIsChangesHappened(true);
                }}
                assignee={assignee}
                setAssignee={(a) => {
                  setAssignee(a);
                  setIsChangesHappened(true);
                }}
              />
              {/* <AccordionComponent title="General Settings" body="General Settings">
                            <GeneralSettings
                                name={name}
                                setName={(a) => { setName(a); setIsChangesHappened(true) }}
                                tags={tags}
                                setTags={(a) => { setTags(a); setIsChangesHappened(true) }}
                                assignee={assignee}
                                setAssignee={(a) => { setAssignee(a); setIsChangesHappened(true) }}
                            /> */}
              {/* </AccordionComponent> */}
              <AccordionComponent
                title="Welcome Screen"
                isToggle={true}
                checked={welcomeScreen.isEnabled}
                onToggle={(status) => {
                  setWelcomeScreen({ ...welcomeScreen, isEnabled: status });
                  setIsChangesHappened(true);
                }}
              >
                <WelcomeMessage leadBotId={lead_bot_id} data={welcomeScreen} setValue={(key, value) => setValue(welcomeScreen, setWelcomeScreen, key, value)} />
              </AccordionComponent>
              <AccordionComponent
                title="Questions"
                isAdd={true}
                onAddButtonClick={() => {
                  setShowAddQuestion(true);
                  setIsEdit(false);
                }}
              >
                <QuestionSettings
                  onEdit={(index) => onEditQuestionNode(index)}
                  fieldMapping={fieldMapping}
                  questions={questions}
                  setQuestions={(a) => {
                    setQuestions(a);
                    setIsChangesHappened(true);
                  }}
                />
              </AccordionComponent>
              <AccordionComponent
                title="Thank You Screen"
                isToggle={true}
                checked={thankYouScreen.isEnabled}
                onToggle={(status) => {
                  setThankYouScreen({ ...thankYouScreen, isEnabled: status });
                  setIsChangesHappened(true);
                }}
              >
                <ThankYouMessage data={thankYouScreen} setValue={(key, value) => setValue(thankYouScreen, setThankYouScreen, key, value)} />
              </AccordionComponent>
              <AccordionComponent title="Settings" body="Settings">
                <CustomisationSettings
                  leadBotId={lead_bot_id}
                  tags={tags}
                  setTags={(a) => {
                    setTags(a);
                    setIsChangesHappened(true);
                  }}
                  assignee={assignee}
                  setAssignee={(a) => {
                    setAssignee(a);
                    setIsChangesHappened(true);
                  }}
                  data={customization}
                  setValue={(key, value) => setValue(customization, setCustomization, key, value)}
                  addTag={addTag}
                  editTag={editTag}
                  deleteTag={deleteTag}
                />
              </AccordionComponent>
            </div>
          </div>
          <div className="card h-vh-155px position-fixed right-20px w-40-60px">
            <Preview customization={customization} thankYouScreen={thankYouScreen} welcomeScreen={welcomeScreen} />
          </div>
        </div>
      )}
      <AddQuestionModel modalProps={addQuestionModelProps} questions={questions}></AddQuestionModel>
      <CopyCodeModel
        id={lead_bot_id}
        show={showScript}
        handleClose={() => {
          setShowScript(false);
        }}
      />
    </ContentWrapper>
  );
}
