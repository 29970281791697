import React, { useState } from "react";
import { InputGroup, Dropdown, Form, Popover, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GreenGradientButton } from "../../components/button";
import { CirclePicker } from "react-color";
import { useSelector } from "react-redux";
import SelectableContext from "react-bootstrap/SelectableContext";
import { dispatchSnackbarError } from "../../utils/toaster";
import { InputLimit } from "../../constants";
import InlineStyled from "../inline-styled/inline-styled";

const tagColors = [
  "#f6a6c2",
  "#ffe786",
  "#99e8a7",
  "#8babff",
  "#cc8aff",
  "#E0FFE5",
  "#DDF8FF",
  "#ECE8FF",
  "#FFE7DC",
  "#FFEED3",
  "#E5EFFF",
  "#FFDFFF",
  "#9898FA",
  "#CCD8FF",
  "#FECCE6",
  "#D2FBF0",
  "#FFCCB0",
  "#FFE2CC",
  "#FDD4D4",
  "#F7E2FE",
  "#C9F8B4",
  "#FF8D7D",
  "#9595FF",
  "#99FF93",
  "#FFE066",
];

const getTagStoreData = (store, page, pageSub, meta) => {
  if (page === "marketing") {
    return store.campaign.marketing.marketingList.tags;
  } else if (page === "template") {
    if (store.campaign.templates.tags.data[meta.channel] && store.campaign.templates.tags.data[meta.channel][meta.category]) {
      return {
        loading: store.campaign.templates.tags.loading,
        list: store.campaign.templates.tags.data[meta.channel][meta.category],
      };
    }
    return { loading: false, list: [] };
  } else if (page === "Deals" || page === "DealList") {
    return store["DealPipeline"][`dealTags`];
  } else {
    return store[page][`${pageSub}Tags`];
  }
};

export function TagToggle({ isUsedAsFilter, tag, field, tagStore, iconBasedFilter, setValue }) {
  if (iconBasedFilter) {
    return (
      <Dropdown.Toggle className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none" variant={"link"}>
        {!tag || tag === "" ? (
          <OverlayTrigger overlay={<Tooltip>Tag Filter</Tooltip>}>
            <span>
              <i class="bi bi-tags fs-2" />
            </span>
          </OverlayTrigger>
        ) : (
          <div className="d-flex gap-2 align-items-center pl-3 pr-1 py-2 border rounded-2">
            {/* <i class="bi bi-tags fs-2"></i> */}
            <InlineStyled
              as="span"
              styles={{
                background: tagStore.list.find((tagStore) => tagStore["_id"] === tag) ? tagStore.list.find((tagStore) => tagStore["_id"] === tag).colorCode : null,
              }}
              className="text-333 w-200px-mx badge badge-dark font-weight-bolder overflow-hidden text-overflow-ellipsis"
            >
              {tagStore.list.find((tagStore) => tagStore["_id"] === tag) ? tagStore.list.find((tagStore) => tagStore["_id"] === tag).name : null}
            </InlineStyled>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                setValue([], field.apiKeyName);
              }}
            ></i>
          </div>
        )}
      </Dropdown.Toggle>
    );
  } else if (isUsedAsFilter) {
    return (
      <Dropdown.Toggle className={"d-flex align-items-center btn btn-sm di dropdown-toggle btn-secondary z-index-0 bg-f8f8f8 border-width-1px border-style-solid border-color-e4e6ef"} variant={"secondary"}>
        {!tag || tag === "" ? (
          <span> {field.fieldName} </span>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <InlineStyled
              as="span"
              styles={{
                backgroundColor: tagStore.list.find((tagStore) => tagStore["_id"] === tag) ? tagStore.list.find((tagStore) => tagStore["_id"] === tag).colorCode : null,
              }}
              className="text-333 w-90-percent-mx badge badge-dark font-weight-bolder overflow-hidden text-overflow-ellipsis"
            >
              {tagStore.list.find((tagStore) => tagStore["_id"] === tag) ? tagStore.list.find((tagStore) => tagStore["_id"] === tag).name : null}
            </InlineStyled>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                setValue([], field.apiKeyName);
              }}
            ></i>
          </div>
        )}
      </Dropdown.Toggle>
    );
  } else {
    return (
      <Dropdown.Toggle className="d-flex align-items-center w-100 text-left z-index-0 py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant={"transparent"}>
        {!tag || tag === "" ? (
          <span> {`Select ${field.fieldName}`} </span>
        ) : (
          <InlineStyled
            as="span"
            styles={{
              backgroundColor: tagStore.list.find((tagStore) => tagStore["_id"] === tag) ? tagStore.list.find((tagStore) => tagStore["_id"] === tag).colorCode : null,
            }}
            className="text-333 w-90-percent-mx badge badge-dark font-weight-bolder overflow-hidden text-overflow-ellipsis"
          >
            {tagStore.list.find((tagStore) => tagStore["_id"] === tag) ? tagStore.list.find((tagStore) => tagStore["_id"] === tag).name : null}
          </InlineStyled>
        )}
      </Dropdown.Toggle>
    );
  }
}

export default function TagSelect({ page, pageSub, addTag, editTag, deleteTag, field, value, setValue, addTextHide, isUsedAsFilter, hideAddText, iconBasedFilter, meta }) {
  const [search, setSearch] = useState("");
  const [newTagText, setNewTagText] = useState("");
  const [newTagColorCode, setNewTagColorCode] = useState("");
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [hovering, setHovering] = useState();
  const [edit, setEdit] = useState({});
  const [show, setShow] = useState(value && value.length > 0 ? value.map((val) => false) : []);
  const tagStore = useSelector((state) => getTagStoreData(state, page, pageSub, meta));

  const handleAddTag = () => {
    if (value.length === 0) {
      dispatchSnackbarError("Fill all the previous values before adding!");
      return;
    }
    for (var i = 0; i < value.length; i++) {
      if (!value[i] || value[i] === "") {
        dispatchSnackbarError("Fill all the previous values before adding!");
        return;
      }
    }
    setSearch("");
    setValue([...value, ""], field.apiKeyName);
    setShow([...show, false]);
  };

  const handleDeleteTag = (tagIndex) => {
    setSearch("");
    setValue(
      value.filter((tag, index) => index !== tagIndex),
      field.apiKeyName,
    );
    setShow(show.filter((show, index) => index !== tagIndex));
  };

  const handleClose = (e) => {
    setHovering();
    setEditing(false);
    setAdding(false);
    setSearch("");
  };

  const handleSave = (e) => {
    setHovering();
    if (newTagText.trim() === "" || newTagColorCode === "") {
      dispatchSnackbarError("Tag Name and Color code can not be blank!");
      return;
    }
    setShow(show.map((x) => false));
    addTag(newTagText, newTagColorCode);
    setNewTagText("");
    setNewTagColorCode("");
    setEditing(false);
    setSearch("");
    setAdding(false);
  };

  const handleCloseEdit = (e) => {
    setHovering();
    setEditing(false);
    setSearch("");
    setEdit({});
  };

  const handleEdit = (e) => {
    setHovering();
    if (edit.name.trim() === "" || edit.colorCode === "") {
      dispatchSnackbarError("Tag Name and Color code can not be blank!");
      return;
    }
    editTag(edit["_id"], edit.name, edit.colorCode);
    setEditing(false);
    setSearch("");
    setEdit({});
  };

  const handleDelete = () => {
    setHovering();
    deleteTag(edit["_id"]);
    setEditing(false);
    setSearch("");
    setEdit({});
  };

  return (
    <>
      {value
        ? value.map((tag, index) => {
            return (
              <SelectableContext.Provider key={index} value={false}>
                <InputGroup>
                  <div className={"d-flex w-100 justify-content-between"}>
                    <Dropdown
                      onSelect={(e, ev) => ev.stopPropagation()}
                      show={show[index]}
                      onToggle={() => {
                        setSearch("");
                        setHovering();
                        if (!show[index]) {
                          setEditing(false);
                          setAdding(false);
                          setEdit();
                        }
                        setShow([...show.slice(0, index), !show[index], ...show.slice(index + 1)]);
                      }}
                      className={`dropdown-inline w-100 ${index !== 0 ? "mt-1" : "mt-0"}`}
                    >
                      <TagToggle isUsedAsFilter={isUsedAsFilter} iconBasedFilter={iconBasedFilter} tag={tag} field={field} tagStore={tagStore} setValue={setValue} />
                      <Dropdown.Menu className={`${isUsedAsFilter ? "w-300px" : "w-400px"} z-index-9999 dropdown-menu dropdown-menu-xl p-0 w-100-mn`}>
                        {tagStore.loading ? (
                          <div className="w-100 d-flex justify-content-center align-items-center h-30vh-mn">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : editing ? (
                          <>
                            {adding ? (
                              <div>
                                <Popover.Title className="py-4" as="h3">
                                  Create Tag
                                </Popover.Title>
                                <Popover.Content>
                                  <div className="w-100">
                                    <Form.Group id="custom-form-tags-new-tag" className="mb-6">
                                      <Form.Control maxLength={InputLimit.TEXT} value={newTagText} placeholder="Tag Name" onChange={(e) => setNewTagText(e.target.value)}></Form.Control>
                                    </Form.Group>
                                    <Form.Group id="custom-form-tags-new-color-tag" className="mb-0">
                                      <CirclePicker colors={tagColors} width="100%" color={newTagColorCode} onChange={(c, e) => setNewTagColorCode(c.hex)} />
                                    </Form.Group>
                                  </div>
                                  <div className="d-flex flex-row justify-content-center gap-15px mt-20px">
                                    <Button variant="secondary" onClick={(e) => handleClose(e)} size="sm">
                                      Cancel
                                    </Button>
                                    <GreenGradientButton onClick={(e) => handleSave(e)} size="sm">
                                      Save
                                    </GreenGradientButton>
                                  </div>
                                </Popover.Content>
                              </div>
                            ) : (
                              <div>
                                <Popover.Title className="py-4" as="h3">
                                  Edit Tag
                                </Popover.Title>
                                <Popover.Content>
                                  <div className="w-100">
                                    <Form.Group id="custom-form-tags-edit-tag" className="mb-6">
                                      <Form.Control maxLength={InputLimit.TEXT} value={edit.name} placeholder="Tag Name" onChange={(e) => setEdit({ ...edit, name: e.target.value })}></Form.Control>
                                    </Form.Group>
                                    <Form.Group id="custom-form-tags-edit-tag-color" className="mb-0">
                                      <CirclePicker colors={tagColors} width="100%" color={edit.colorCode} onChange={(c, e) => setEdit({ ...edit, colorCode: c.hex })} />
                                    </Form.Group>
                                  </div>
                                  <div className="d-flex flex-row flex-stack mt-20px">
                                    <Button onClick={() => handleDelete()} variant="danger" size="sm">
                                      Delete
                                    </Button>
                                    <div className="d-flex flex-row gap-15px">
                                      <Button variant="secondary" onClick={(e) => handleCloseEdit(e)} size="sm">
                                        Cancel
                                      </Button>
                                      <GreenGradientButton onClick={(e) => handleEdit(e)} size="sm">
                                        Save
                                      </GreenGradientButton>
                                    </div>
                                  </div>
                                </Popover.Content>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="navi navi-hover">
                            {isUsedAsFilter ? (
                              ""
                            ) : (
                              <li className="navi-item py-20px px-24px">
                                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Tags" value={search} onChange={(e) => setSearch(e.target.value)} />
                              </li>
                            )}
                            <Dropdown.Divider className="m-0" />
                            <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative mt-10px" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                              {tagStore.list.length === 0 ? (
                                <div className="w-100 d-flex flex-center h-50px-mn">
                                  <span>No Tags</span>
                                </div>
                              ) : (
                                <>
                                  {tagStore.list.map((storeTag, subindex) => {
                                    return storeTag.name && storeTag.name.toLowerCase().includes(search.toLowerCase()) && (!value.includes(storeTag["_id"]) || storeTag["_id"] === value[index]) ? (
                                      <Dropdown.Item
                                        onMouseOver={() => setHovering(subindex)}
                                        onMouseLeave={() => setHovering()}
                                        className="dropdown-hover"
                                        key={subindex}
                                        onSelect={() => {
                                          setValue([...value.slice(0, index), storeTag["_id"], ...value.slice(index + 1)], field.apiKeyName);
                                        }}
                                      >
                                        <div className="w-100 d-flex flex-stack">
                                          <InlineStyled as="span" styles={{ backgroundColor: storeTag.colorCode }} className="text-333 badge badge-dark overflow-hidden text-overflow-ellipsis">
                                            {storeTag.name}
                                          </InlineStyled>
                                          {hovering === subindex ? (
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditing(true);
                                                setEdit(storeTag);
                                              }}
                                              className="btn btn-icon btn-light h-25px w-25px"
                                            >
                                              <i className="fa fa-sm fa-pen font-size-13px"></i>
                                            </button>
                                          ) : (
                                            <span className="h-25px w-25px"></span>
                                          )}
                                        </div>
                                      </Dropdown.Item>
                                    ) : null;
                                  })}
                                </>
                              )}
                            </PerfectScrollbar>
                            <Dropdown.Divider className="m-0" />
                            <div
                              onClick={() => {
                                setEditing(true);
                                setAdding(true);
                              }}
                              className="dropdown-item dropdown-hover py-4 cursor-pointer"
                            >
                              + Create Tag
                            </div>
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {!isUsedAsFilter && value[0] ? (
                      <InputGroup.Append>
                        <Button className="pl-3 pr-0" onClick={() => handleDeleteTag(index)} size="sm" variant="transparent">
                          <i className="fa fa-minus-circle remove-condition-icon"></i>
                        </Button>
                      </InputGroup.Append>
                    ) : null}
                  </div>
                </InputGroup>
              </SelectableContext.Provider>
            );
          })
        : null}
      {hideAddText ? (
        ""
      ) : (
        <Button className="mt-2 p-0 text-decoration-none" onClick={handleAddTag} variant="link">
          + Add Tag
        </Button>
      )}
    </>
  );
}
