import { getCadenceAnalyticsStepWise } from "../types/cadence_analytics_stepwise_types";

export const getCadenceAnalyticsStepWiseRequest = (payload, cb) => ({
  type: getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_REQUEST,
  payload,
  cb,
});
export const getCadenceAnalyticsStepWiseSuccess = (payload) => ({
  type: getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_SUCCESS,
  payload,
});
export const getCadenceAnalyticsStepWiseError = (payload) => ({
  type: getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_ERROR,
  payload,
});
