import { getEmailCampaignAnalytics, getEmailCampaignDetailedReport, getEmailCampaignSummaryReport, getCampaignReportLog, getListOfDownloadReports, downloadCampaignReports } from "../types/campaign_reports_types";

export const getEmailCampaignAnalyticsRequest = (payload) => ({
  type: getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_REQUEST,
  payload,
});
export const getEmailCampaignAnalyticsSuccess = (payload) => ({
  type: getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_SUCCESS,
  payload,
});
export const getEmailCampaignAnalyticsError = (payload) => ({
  type: getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_ERROR,
  payload,
});

export const getEmailCampaignDetailedReportRequest = (payload, cb) => ({
  type: getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_REQUEST,
  payload,
  cb,
});
export const getEmailCampaignDetailedReportSuccess = (payload) => ({
  type: getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_SUCCESS,
  payload,
});
export const getEmailCampaignDetailedReportError = (payload) => ({
  type: getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_ERROR,
  payload,
});

export const getEmailCampaignSummaryReportRequest = (payload, cb) => ({
  type: getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_REQUEST,
  payload,
  cb,
});
export const getEmailCampaignSummaryReportSuccess = (payload) => ({
  type: getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_SUCCESS,
  payload,
});
export const getEmailCampaignSummaryReportError = (payload) => ({
  type: getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_ERROR,
  payload,
});

export const getCampaignReportLogRequest = (payload, cb) => ({
  type: getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_REQUEST,
  payload,
  cb,
});
export const getCampaignReportLogSuccess = (payload) => ({
  type: getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_SUCCESS,
  payload,
});
export const getCampaignReportLogError = (payload) => ({
  type: getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_ERROR,
  payload,
});

export const getListOfDownloadReportsRequest = (payload, cb) => ({
  type: getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST,
  payload,
  cb,
});

export const getListOfDownloadReportsRequestAfter = (payload) => ({
  type: getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST_AFTER,
  payload,
});
export const getListOfDownloadReportsSuccess = (payload) => ({
  type: getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_SUCCESS,
  payload,
});
export const getListOfDownloadReportsError = (payload) => ({
  type: getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_ERROR,
  payload,
});

export const downloadCampaignReportsRequest = (payload, cb) => ({
  type: downloadCampaignReports.DOWNLOAD_CAMPAIGN_REPORTS_REQUEST,
  payload,
  cb,
});
export const downloadCampaignReportsSuccess = (payload) => ({
  type: downloadCampaignReports.DOWNLOAD_CAMPAIGN_REPORTS_SUCCESS,
  payload,
});
export const downloadCampaignReportsError = (payload) => ({
  type: downloadCampaignReports.DOWNLOAD_CAMPAIGN_REPORTS_ERROR,
  payload,
});
