import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CHECKEMAIL_REQUEST,
  CHECKEMAIL_SUCCESS,
  CHECKEMAIL_ERROR,
  SIGNUPINFO_REQUEST,
  SIGNUPINFO_SUCCESS,
  SIGNUPINFO_ERROR,
  EMAILOTP_REQUEST,
  EMAILOTP_SUCCESS,
  EMAILOTP_ERROR,
  PATCHPHONE_REQUEST,
  PATCHPHONE_SUCCESS,
  PATCHPHONE_ERROR,
  UPDATEACCOUNT_REQUEST,
  UPDATEACCOUNT_SUCCESS,
  UPDATEACCOUNT_ERROR,
  GETGOOGLEAPI_REQUEST,
  GETGOOGLEAPI_SUCCESS,
  GETGOOGLEAPI_ERROR,
  POSTGOOGLEAPI_REQUEST,
  POSTGOOGLEAPI_SUCCESS,
  POSTGOOGLEAPI_ERROR,
  VERIFYMANAGEUSER_REQUEST,
  VERIFYMANAGEUSER_SUCCESS,
  VERIFYMANAGEUSER_ERROR,
  FORGOTPASSWORD_REQUEST,
  FORGOTPASSWORD_SUCCESS,
  FORGOTPASSWORD_ERROR,
  FORGOTPASSWORDPOST_REQUEST,
  FORGOTPASSWORDPOST_SUCCESS,
  FORGOTPASSWORDPOST_ERROR,
  VERIFY_COUPONCODE_REQUEST,
  VERIFY_COUPONCODE_SUCCESS,
  VERIFY_COUPONCODE_ERROR,
  getAccountCreatedStatus,
  createAccountV2,
  verifyPreAccount,
} from "./types";

//COUPONCODE VERIFY
export function verifyCouponCodeRequest(req, cb) {
  return {
    type: VERIFY_COUPONCODE_REQUEST,
    payload: req,
    cb,
  };
}
export function verifyCouponCodeSuccess(res) {
  return {
    type: VERIFY_COUPONCODE_SUCCESS,
    payload: res,
  };
}
export function verifyCouponCodeError(err) {
  return {
    type: VERIFY_COUPONCODE_ERROR,
    payload: err,
  };
}

export function loginRequest(req, cb) {
  return {
    type: LOGIN_REQUEST,
    payload: req,
    cb,
  };
}

export function loginSuccess(res) {
  return {
    type: LOGIN_SUCCESS,
    payload: res,
  };
}

export function loginError(err) {
  return {
    type: LOGIN_ERROR,
    payload: err,
  };
}

export function CheckEmailRequest(req, cb) {
  return {
    type: CHECKEMAIL_REQUEST,
    payload: req,
    cb,
  };
}

export function CheckEmailSuccess(res) {
  return {
    type: CHECKEMAIL_SUCCESS,
    payload: res,
  };
}

export function CheckEmailError(err) {
  return {
    type: CHECKEMAIL_ERROR,
    payload: err,
  };
}

export function SignUpInfoRequest(req, cb) {
  return {
    type: SIGNUPINFO_REQUEST,
    payload: req,
    cb,
  };
}

export function SignUpInfoSuccess(res) {
  return {
    type: SIGNUPINFO_SUCCESS,
    payload: res,
  };
}

export function SignUpInfoError(err) {
  return {
    type: SIGNUPINFO_ERROR,
    payload: err,
  };
}

export function EmailOTPRequest(req, cb) {
  return {
    type: EMAILOTP_REQUEST,
    payload: req,
    cb,
  };
}

export function EmailOTPSuccess(res) {
  return {
    type: EMAILOTP_SUCCESS,
    payload: res,
  };
}

export function EmailOTPError(err) {
  return {
    type: EMAILOTP_ERROR,
    payload: err,
  };
}

export function PatchPhoneRequest(req, cb) {
  return {
    type: PATCHPHONE_REQUEST,
    payload: req,
    cb,
  };
}

export function PatchPhoneSuccess(res) {
  return {
    type: PATCHPHONE_SUCCESS,
    payload: res,
  };
}

export function PatchPhoneError(err) {
  return {
    type: PATCHPHONE_ERROR,
    payload: err,
  };
}

export function UpdateAccountRequest(req, params, cb) {
  return {
    type: UPDATEACCOUNT_REQUEST,
    payload: req,
    params,
    cb,
  };
}

export function UpdateAccountSuccess(res) {
  return {
    type: UPDATEACCOUNT_SUCCESS,
    payload: res,
  };
}

export function UpdateAccountError(err) {
  return {
    type: UPDATEACCOUNT_ERROR,
    payload: err,
  };
}

export function GoogleAPIRequest(params, cb) {
  return {
    type: GETGOOGLEAPI_REQUEST,
    cb,
    params,
  };
}

export function GoogleAPISuccess(res) {
  return {
    type: GETGOOGLEAPI_SUCCESS,
    payload: res,
  };
}

export function GoogleAPIError(err) {
  return {
    type: GETGOOGLEAPI_ERROR,
    payload: err,
  };
}

export function PostGoogleAPIRequest(req, cb) {
  return {
    type: POSTGOOGLEAPI_REQUEST,
    payload: req,
    cb,
  };
}

export function PostGoogleAPISuccess(res) {
  return {
    type: POSTGOOGLEAPI_SUCCESS,
    payload: res,
  };
}

export function PostGoogleAPIError(err) {
  return {
    type: POSTGOOGLEAPI_ERROR,
    payload: err,
  };
}

export function ManagerInviteRequest(req, params, cb) {
  return {
    type: VERIFYMANAGEUSER_REQUEST,
    payload: req,
    params,
    cb,
  };
}

export function ManagerInviteSuccess(res) {
  return {
    type: VERIFYMANAGEUSER_SUCCESS,
    payload: res,
  };
}

export function ManagerInviteError(err) {
  return {
    type: VERIFYMANAGEUSER_ERROR,
    payload: err,
  };
}

export function ForgotPassRequest(params, cb) {
  return {
    type: FORGOTPASSWORD_REQUEST,
    cb,
    params,
  };
}

export function ForgotPassSuccess(res) {
  return {
    type: FORGOTPASSWORD_SUCCESS,
    payload: res,
  };
}

export function ForgotPassError(err) {
  return {
    type: FORGOTPASSWORD_ERROR,
    payload: err,
  };
}

export function forgetPassPostRequest(req, params, cb) {
  return {
    type: FORGOTPASSWORDPOST_REQUEST,
    payload: req,
    params,
    cb,
  };
}

export function forgetPassPostSuccess(res) {
  return {
    type: FORGOTPASSWORDPOST_SUCCESS,
    payload: res,
  };
}

export function forgetPassPostError(err) {
  return {
    type: FORGOTPASSWORDPOST_ERROR,
    payload: err,
  };
}

export const getAccountCreatedStatusRequest = (req, cb) => ({
  type: getAccountCreatedStatus.GET_ACCOUNT_CREATED_STATUS_REQUEST,
  payload: req,
  cb,
});
export const getAccountCreatedStatusSuccess = (res) => ({
  type: getAccountCreatedStatus.GET_ACCOUNT_CREATED_STATUS_SUCCESS,
  payload: res,
});
export const getAccountCreatedStatusError = (err) => ({
  type: getAccountCreatedStatus.GET_ACCOUNT_CREATED_STATUS_ERROR,
  payload: err,
});

export const createAccountV2Request = (req, cb) => ({
  type: createAccountV2.CREATE_ACCOUNT_V2_REQUEST,
  payload: req,
  cb,
});
export const createAccountV2Success = (res) => ({
  type: createAccountV2.CREATE_ACCOUNT_V2_SUCCESS,
  payload: res,
});
export const createAccountV2Error = (err) => ({
  type: createAccountV2.CREATE_ACCOUNT_V2_ERROR,
  payload: err,
});

export const verifyPreAccountRequest = (req, cb) => ({
  type: verifyPreAccount.VERIFY_PRE_ACCOUNT_REQUEST,
  payload: req,
  cb,
});
export const verifyPreAccountSuccess = (res) => ({
  type: verifyPreAccount.VERIFY_PRE_ACCOUNT_SUCCESS,
  payload: res,
});
export const verifyPreAccountError = (err) => ({
  type: verifyPreAccount.VERIFY_PRE_ACCOUNT_ERROR,
  payload: err,
});
