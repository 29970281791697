// cadence lead
export const getCadenceAllLead = {
  GET_CADENCE_ALL_LEAD_REQUEST: "GET_CADENCE_ALL_LEAD_REQUEST",
  GET_CADENCE_ALL_LEAD_SUCCESS: "GET_CADENCE_ALL_LEAD_SUCCESS",
  GET_CADENCE_ALL_LEAD_ERROR: "GET_CADENCE_ALL_LEAD_ERROR",
};
export const addLeadToCadence = {
  ADD_LEAD_TO_CADENCE_REQUEST: "ADD_LEAD_TO_CADENCE_REQUEST",
  ADD_LEAD_TO_CADENCE_SUCCESS: "ADD_LEAD_TO_CADENCE_SUCCESS",
  ADD_LEAD_TO_CADENCE_ERROR: "ADD_LEAD_TO_CADENCE_ERROR",
};
export const addBulkLeadToCadence = {
  ADD_BULK_LEAD_TO_CADENCE_REQUEST: "ADD_BULK_LEAD_TO_CADENCE_REQUEST",
  ADD_BULK_LEAD_TO_CADENCE_SUCCESS: "ADD_BULK_LEAD_TO_CADENCE_SUCCESS",
  ADD_BULK_LEAD_TO_CADENCE_ERROR: "ADD_BULK_LEAD_TO_CADENCE_ERROR",
};

export const getListOfCadenceByLeadId = {
  GET_LIST_OF_CADENCE_BY_LEAD_ID_REQUEST: "GET_LIST_OF_CADENCE_BY_LEAD_ID_REQUEST",
  GET_LIST_OF_CADENCE_BY_LEAD_ID_SUCCESS: "GET_LIST_OF_CADENCE_BY_LEAD_ID_SUCCESS",
  GET_LIST_OF_CADENCE_BY_LEAD_ID_ERROR: "GET_LIST_OF_CADENCE_BY_LEAD_ID_ERROR",
};

export const getCadenceSubscriberCount = {
  REQUEST: "GET_CADENCE_SUBSCRIBER_COUNT_REQUEST",
  SUCCESS: "GET_CADENCE_SUBSCRIBER_COUNT_SUCCESS",
  FAILURE: "GET_CADENCE_SUBSCRIBER_COUNT_FAILURE",
};
export const bulkSubscribeToCadenceByQuery = {
  REQUEST: "BULK_SUBSCRIBE_TO_CADENCE_BY_QUERY_REQUEST",
  SUCCESS: "BULK_SUBSCRIBE_TO_CADENCE_BY_QUERY_SUCCESS",
  FAILURE: "BULK_SUBSCRIBE_TO_CADENCE_BY_QUERY_FAILURE",
};
export const getCadenceSubscriberAnalytics = {
  REQUEST: "GET_CADENCE_SUBSCRIBER_ANALYTICS_REQUEST",
  SUCCESS: "GET_CADENCE_SUBSCRIBER_ANALYTICS_SUCCESS",
  FAILURE: "GET_CADENCE_SUBSCRIBER_ANALYTICS_FAILURE",
};
