import { getListOfSchedulingPages, getSelectedSchedulingPage, updatedSchedulingPage, getAvailableCalendars } from "../../types/profile-settings/schedular.types";

const initState = {
  list: {
    loading: false,
    data: [],
    err: null,
  },
  selected: {
    loading: false,
    data: {},
    err: null,
    updateLoading: false,
  },
  calendarList: {
    loading: false,
    data: [],
    err: null,
  },
};

export default function schedulingSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_REQUEST:
      return {
        ...state,
        list: { ...state.list, loading: true },
      };
    case getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_SUCCESS:
      return {
        ...state,
        list: { ...state.list, loading: false, data: action.payload.data },
      };
    case getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_ERROR:
      return {
        ...state,
        list: { ...state.list, loading: false },
        error: { err: true, errMsg: action.payload },
      };
    case getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        selected: { ...state.selected, loading: true },
      };
    case getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_SUCCESS:
      return {
        ...state,
        selected: { ...state.selected, loading: false, data: action.payload.data },
      };
    case getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_ERROR:
      return {
        ...state,
        selected: { ...state.selected, loading: false },
        error: { err: true, errMsg: action.payload },
      };
    case updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        selected: {
          ...state.selected,
          updateLoading: true,
        },
      };
    case updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_SUCCESS:
    case updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_ERROR:
      return {
        ...state,
        selected: {
          ...state.selected,
          updateLoading: false,
        },
      };
    case getAvailableCalendars.GET_AVAILABLE_CALENDARS_REQUEST:
      return {
        ...state,
        calendarList: { ...state.calendarList, loading: true },
      };
    case getAvailableCalendars.GET_AVAILABLE_CALENDARS_SUCCESS:
      return {
        ...state,
        calendarList: { ...state.calendarList, loading: false, data: action.payload.data },
      };
    case getAvailableCalendars.GET_AVAILABLE_CALENDARS_ERROR:
      return {
        ...state,
        calendarList: { ...state.calendarList, loading: false },
      };
    default:
      return state;
  }
}
