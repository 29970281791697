import {
  HOSTED_EMAIL_AUTH_REQUEST,
  HOSTED_EMAIL_AUTH_SUCCESS,
  HOSTED_EMAIL_AUTH_ERROR,
  HOSTED_EMAIL_AUTH_CALLBACK_REQUEST,
  HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS,
  HOSTED_EMAIL_AUTH_CALLBACK_ERROR,
  GET_EMAIL_SYNC_DETAILS_REQUEST,
  GET_EMAIL_SYNC_DETAILS_SUCCESS,
  GET_EMAIL_SYNC_DETAILS_ERROR,
  UPDATE_EMAIL_SYNC_DETAILS_REQUEST,
  UPDATE_EMAIL_SYNC_DETAILS_SUCCESS,
  UPDATE_EMAIL_SYNC_DETAILS_ERROR,
  GET_EMAIL_FOLDER_LABELS_REQUEST,
  GET_EMAIL_FOLDER_LABELS_SUCCESS,
  GET_EMAIL_FOLDER_LABELS_ERROR,
  ADD_EMAIL_IN_IGNORE_LIST_REQUEST,
  ADD_EMAIL_IN_IGNORE_LIST_SUCCESS,
  ADD_EMAIL_IN_IGNORE_LIST_ERROR,
  REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST,
  REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS,
  REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR,
  GET_COMPOSER_TOKENS_REQUEST,
  GET_COMPOSER_TOKENS_SUCCESS,
  GET_COMPOSER_TOKENS_ERROR,
  DELETE_EMAIL_ACCOUNT_REQUEST,
  DELETE_EMAIL_ACCOUNT_SUCCESS,
  DELETE_EMAIL_ACCOUNT_ERROR,
  DISCONNECT_EMAIL_ACCOUNT_REQUEST,
  DISCONNECT_EMAIL_ACCOUNT_SUCCESS,
  DISCONNECT_EMAIL_ACCOUNT_ERROR,
  RECONNECT_EMAIL_ACCOUNT_REQUEST,
  RECONNECT_EMAIL_ACCOUNT_SUCCESS,
  RECONNECT_EMAIL_ACCOUNT_ERROR,
  HOSTED_CALENDAR_AUTH_REQUEST,
  HOSTED_CALENDAR_AUTH_SUCCESS,
  HOSTED_CALENDAR_AUTH_ERROR,
  HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST,
  HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS,
  HOSTED_CALENDAR_AUTH_CALLBACK_ERROR,
  GET_CALENDAR_SYNC_DETAILS_REQUEST,
  GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER,
  GET_CALENDAR_SYNC_DETAILS_SUCCESS,
  GET_CALENDAR_SYNC_DETAILS_ERROR,
  UPDATE_CALENDAR_SYNC_DETAILS_REQUEST,
  UPDATE_CALENDAR_SYNC_DETAILS_SUCCESS,
  UPDATE_CALENDAR_SYNC_DETAILS_ERROR,
  GET_AGENDA_TOKENS_REQUEST,
  GET_AGENDA_TOKENS_SUCCESS,
  GET_AGENDA_TOKENS_ERROR,
  DELETE_CALENDAR_ACCOUNT_REQUEST,
  DELETE_CALENDAR_ACCOUNT_SUCCESS,
  DELETE_CALENDAR_ACCOUNT_ERROR,
  DISCONNECT_CALENDAR_ACCOUNT_REQUEST,
  DISCONNECT_CALENDAR_ACCOUNT_SUCCESS,
  DISCONNECT_CALENDAR_ACCOUNT_ERROR,
  RECONNECT_CALENDAR_ACCOUNT_REQUEST,
  RECONNECT_CALENDAR_ACCOUNT_SUCCESS,
  RECONNECT_CALENDAR_ACCOUNT_ERROR,
  GET_LIST_OF_CALENDARS_REQUEST,
  GET_LIST_OF_CALENDARS_SUCCESS,
  GET_LIST_OF_CALENDARS_ERROR,
} from "../types/sync.types";

const initialState = {
  email: {
    loading: false,
    data: {},
    canIntegrateNew: false,
    labels: [],
    error: "",
  },
  calendar: {
    loading: false,
    data: {},
    canIntegrateNew: false,
    error: "",
    calenderList: [],
  },
  composer: {
    loading: false,
    data: {},
    error: "",
  },
  agenda: {
    loading: false,
    data: {},
    error: "",
  },
};

export function syncReducer(state = initialState, action = {}) {
  switch (action.type) {
    case HOSTED_EMAIL_AUTH_REQUEST:
      return {
        ...state,
      };
    case HOSTED_EMAIL_AUTH_SUCCESS:
      return {
        ...state,
      };
    case HOSTED_EMAIL_AUTH_ERROR:
      return {
        ...state,
      };
    case HOSTED_EMAIL_AUTH_CALLBACK_REQUEST:
      return {
        ...state,
      };
    case HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS:
      return {
        ...state,
      };
    case HOSTED_EMAIL_AUTH_CALLBACK_ERROR:
      return {
        ...state,
      };
    case GET_EMAIL_SYNC_DETAILS_REQUEST:
      return {
        ...state,
        email: {
          ...state.email,
          loading: true,
        },
      };
    case GET_EMAIL_SYNC_DETAILS_SUCCESS:
      return {
        ...state,
        email: {
          ...state.email,
          loading: false,
          data: action.payload.data,
          canIntegrateNew: action.payload.canIntegrateNew ? action.payload.canIntegrateNew : false,
        },
      };
    case GET_EMAIL_SYNC_DETAILS_ERROR:
      return {
        ...state,
        email: {
          ...state.email,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_EMAIL_SYNC_DETAILS_REQUEST:
      return {
        ...state,
        email: {
          ...state.email,
          data: {
            ...state.email.data,
            ...action.payload,
          },
        },
      };
    case UPDATE_EMAIL_SYNC_DETAILS_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_EMAIL_SYNC_DETAILS_ERROR:
      return {
        ...state,
      };
    case GET_EMAIL_FOLDER_LABELS_REQUEST:
      return {
        ...state,
      };
    case GET_EMAIL_FOLDER_LABELS_SUCCESS:
      return {
        ...state,
        email: {
          ...state.email,
          labels: action.payload,
        },
      };
    case GET_EMAIL_FOLDER_LABELS_ERROR:
      return {
        ...state,
      };
    case ADD_EMAIL_IN_IGNORE_LIST_REQUEST:
      return {
        ...state,
      };
    case ADD_EMAIL_IN_IGNORE_LIST_SUCCESS:
      return {
        ...state,
      };
    case ADD_EMAIL_IN_IGNORE_LIST_ERROR:
      return {
        ...state,
      };
    case REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST:
      return {
        ...state,
      };
    case REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS:
      return {
        ...state,
      };
    case REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR:
      return {
        ...state,
      };
    case GET_COMPOSER_TOKENS_REQUEST:
      return {
        ...state,
        composer: {
          ...state.composer,
          loading: true,
        },
      };
    case GET_COMPOSER_TOKENS_SUCCESS:
      return {
        ...state,
        composer: {
          ...state.composer,
          loading: false,
          data: action.payload,
        },
      };
    case GET_COMPOSER_TOKENS_ERROR:
      return {
        ...state,
        composer: {
          ...state.composer,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_EMAIL_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case DELETE_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_EMAIL_ACCOUNT_ERROR:
      return {
        ...state,
      };
    case DISCONNECT_EMAIL_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case DISCONNECT_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case DISCONNECT_EMAIL_ACCOUNT_ERROR:
      return {
        ...state,
      };
    case RECONNECT_EMAIL_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case RECONNECT_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case RECONNECT_EMAIL_ACCOUNT_ERROR:
      return {
        ...state,
      };

    case HOSTED_CALENDAR_AUTH_REQUEST:
      return {
        ...state,
      };
    case HOSTED_CALENDAR_AUTH_SUCCESS:
      return {
        ...state,
      };
    case HOSTED_CALENDAR_AUTH_ERROR:
      return {
        ...state,
      };
    case HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST:
      return {
        ...state,
      };
    case HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS:
      return {
        ...state,
      };
    case HOSTED_CALENDAR_AUTH_CALLBACK_ERROR:
      return {
        ...state,
      };
    case GET_CALENDAR_SYNC_DETAILS_REQUEST:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: true,
        },
      };
    case GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER:
      return {
        ...state,
      };
    case GET_CALENDAR_SYNC_DETAILS_SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: false,
          data: action.payload.data,
          canIntegrateNew: action.payload.canIntegrateNew ? action.payload.canIntegrateNew : false,
        },
      };
    case GET_CALENDAR_SYNC_DETAILS_ERROR:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_CALENDAR_SYNC_DETAILS_REQUEST:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          data: {
            ...state.calendar.data,
            ...action.payload,
          },
        },
      };
    case UPDATE_CALENDAR_SYNC_DETAILS_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_CALENDAR_SYNC_DETAILS_ERROR:
      return {
        ...state,
      };
    case GET_AGENDA_TOKENS_REQUEST:
      return {
        ...state,
        agenda: {
          ...state.agenda,
          loading: true,
        },
      };
    case GET_AGENDA_TOKENS_SUCCESS:
      return {
        ...state,
        agenda: {
          ...state.agenda,
          loading: false,
          data: action.payload,
        },
      };
    case GET_AGENDA_TOKENS_ERROR:
      return {
        ...state,
        agenda: {
          ...state.agenda,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_CALENDAR_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case DELETE_CALENDAR_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_CALENDAR_ACCOUNT_ERROR:
      return {
        ...state,
      };
    case DISCONNECT_CALENDAR_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case DISCONNECT_CALENDAR_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case DISCONNECT_CALENDAR_ACCOUNT_ERROR:
      return {
        ...state,
      };
    case RECONNECT_CALENDAR_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case RECONNECT_CALENDAR_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case RECONNECT_CALENDAR_ACCOUNT_ERROR:
      return {
        ...state,
      };
    case GET_LIST_OF_CALENDARS_REQUEST:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: false,
        },
      };
    case GET_LIST_OF_CALENDARS_SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          calenderList: action.payload,
          loading: false,
        },
      };
    case GET_LIST_OF_CALENDARS_ERROR:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: false,
        },
      };
    //similarly need to write required cases
    default:
      return state;
  }
}
