import React from "react";
import { Dropdown } from "react-bootstrap";

export default function SharedOwnedDropdown(props) {
  return (
    <Dropdown className="dropdown-inline">
      <Dropdown.Toggle className={`btn btn-sm dropdown-toggle btn-secondary custom-secondory-cta`}>{props.value}</Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="scroll navi navi-hover overflow-y-auto h-500px-mx w-200px position-relative">
          {["All", "Owned", "Shared"].map((each, index) => {
            return (
              <Dropdown.Item className="d-flex justify-content-between g-3 align-items-center" key={index} onClick={() => props.handleClick(each)}>
                {each}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
