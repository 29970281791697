import React, { useCallback, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: "none", // Custom colour
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (styles) => ({
    ...styles,
    position: "relative",
    transform: "translateY(0%)",
  }),
  input: (styles) => ({
    ...styles,
  }),
};
export default function SearchSelect(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isSearchStarted, setIsSearchStarted] = useState(false);

  const search = useCallback(
    debounce((text) => {
      if (text && text.length > 0) {
        if (props.field === "linkWithCompany") {
          dispatch(
            props.request({
              searchtext: text,
              companyId: props.companyId,
              type: props.type,
            }),
          );
        } else {
          dispatch(props.request(text));
        }
      } else {
        return [];
      }
      setLoading(false);
    }, 500),
    [],
  );

  const handleInputChange = (e) => {
    if (e.length > 0) {
      setLoading(true);
      setInputValue(e);
    } else {
      setLoading(false);
    }
    props.setSearch(e);
    search(e);
  };

  const handleSelect = (e) => {
    if (e === null) {
      return props.setValue("", props.field.apiKeyName);
    }
    if (!e) return;
    setIsSearchStarted(false);
    props && props.hasOwnProperty("setIsNew") && props.setIsNew(false);
    if (e.value === "new") {
      props.handleSave();
    } else {
      props && props.hasOwnProperty("setIsNew") && props.setIsNew(false);
      if (props.customFormData && props.setCustomFormData) {
        var temp = { phone: e.phone, email: e.email, company: e.company, name: e.value, e };
        props.setCustomFormData({ ...props.customFormData, ...temp });
      } else props.setValue(e.value, props.field.apiKeyName);
    }
  };

  const onSearchBlur = () => {
    if (inputValue !== "" && isSearchStarted) {
      props && props.hasOwnProperty("newValue") && props.newValue(inputValue);
    }
    setInputValue("");
    setIsSearchStarted(false);
  };
  const onSearchFocus = () => {
    setIsSearchStarted(true);
    // props.setValue("", props.field.apiKeyName)
  };
  const onKeyDown = () => {
    // props.setValue("", props.field.apiKeyName)
  };

  return (
    <div className="position-relative">
      <Select
        className="bg-white"
        type="search"
        value={props.selected}
        onChange={(e) => handleSelect(e)}
        options={props.options}
        isSearchable={true}
        isClearable={true}
        styles={customStyles}
        components={{
          DropdownIndicator: () => null,
          DownChevron: () => null,
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => handleInputChange(e)}
        isLoading={props.loading || loading}
        maxMenuHeight={220}
        menuPlacement={props.field === "linkWithCompany" ? "top" : "auto"}
        menuPortalTarget={document.body}
        onBlur={() => onSearchBlur()}
        onFocus={() => onSearchFocus()}
        onKeyDown={onKeyDown}
        noOptionsMessage={() => props && props.dontShowOptionsDropdwon && null}
      />
      {props && props.hasOwnProperty("isNew") && props.isNew && props.selected.value ? <div className="new-tag">New</div> : null}
    </div>
  );
}
