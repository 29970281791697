import { getListOfCadenceCloneableTemplate } from "../types/cadence-cloneable-template.types";
const initState = {
  data: [],
  isLoading: false,
  count: 0,
  error: false,
};

export default function cadenceCloneableTemplateReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        count: action.payload.count,
      };
    case getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
