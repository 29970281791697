import React, { useState, useEffect } from "react";
// import {NavLink} from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as actions from "../../actions/settings.actions";
import {
  //  useSelector,
  connect,
} from "react-redux";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import FormInput from "../../../../components/Form-Field/index";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";
// import { Link } from 'react-router-dom';

function ProfileOverview() {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [Phone, setPhoneNo] = useState("");
  const [is2FAEnabled, setis2FAEnabled] = useState("");
  const [show, setShow] = useState(false);
  const [PassShow, setPassShow] = useState(false);
  const [changeEmail, setchangeEmail] = useState({ newEmail: "", password: "" });
  // const [resetPass, setresetPass] = useState({  pass: "", confPass: "", newPass: "" });
  const [pass, setpass] = useState("");
  const [confPass, setconfPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const ResetFunction = () => setPassShow(true);
  const ResetFunctionClose = () => setPassShow(false);

  // const store = useSelector(state => state.ProfileSettings)

  const dispatch = useDispatch();

  const Factor2Auth = (val) => {
    let objValue = {
      fullName: name,
      is2FAEnabled: val,
      phone: Phone,
    };
    dispatch(
      actions.Factor2Auth(objValue, (data) => {
        dispatchSnackbarSuccess("2FA successfully Updated", "success");
        if (data["success"]) {
          dispatch(
            actions.getProfileRequest((data) => {
              setis2FAEnabled(data.data.is2FAEnabled);
              // if (e.target.checked === false) {
              //   dispatchSnackbarSuccess("2FA successfully Enable", "success")
              // }
              // else {
              //   dispatchSnackbarSuccess("2FA successfully Disable", "success")
              // }
              setemail(data.data.email);
              setShow(false);
            }),
          );
        }
      }),
    );
  };

  /*****************
   * Reset Password
   * ************************************/

  const handleResetPassword = () => {
    if (confPass !== newPass) {
      dispatchSnackbarError("New Password and Confirm Password does not match ", "error");
    } else if (!/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(confPass)) {
      return dispatchSnackbarError("Password should contains minimum eight characters, at least one letter and one number");
    } else {
      let PassObj = {
        pass: pass,
        confPass: confPass,
        newPass: newPass,
      };

      dispatch(
        actions.patchPasswordResetRequest(PassObj, "", (data) => {
          dispatch(
            actions.getProfileRequest((data) => {
              dispatchSnackbarSuccess("Password Successfully Reset", "success");
              setemail(data.data.email);
              setPassShow(false);
            }),
          );
        }),
      );
    }
  };

  const ResetPassChange = (e) => {
    const { name, value } = e.target;
    // setresetPass(prevState => ({
    //   ...prevState,
    //   [name]: value
    // }));

    if (name === "currentpassword") {
      setpass(value);
    } else if (name === "newpassword") {
      setconfPass(value);
    } else if (name === "confirmpassword") {
      setnewPass(value);
    }
  };

  /*********************************************************************/

  useEffect(() => {
    dispatch(
      actions.getProfileRequest((data) => {
        setName(data.data.fullName);
        setemail(data.data.email);
        setPhoneNo(data.data.phone);
        setis2FAEnabled(data.data.is2FAEnabled);
      }),
    );
  }, [dispatch]);

  /**************************Change Email**************************/
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setchangeEmail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateEmail = () => {
    dispatch(
      actions.ChangeEmail(changeEmail, (data) => {
        if (data["success"]) {
          dispatch(
            actions.getProfileRequest((data) => {
              dispatchSnackbarSuccess("Email Successfully Updated", "success");
              setemail(data.data.email);
              setShow(false);
            }),
          );
        }
      }),
    );
  };

  /******************************************************************/

  return (
    <>
      <div className="card mb-5 mb-xl-10 h-vh-160px">
        {/*begin::Card header*/}
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Sign-in Method</h3>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_signin_method" className="collapse show">
          {/*begin::Card body*/}
          <div className="card-body border-top p-9 overflow-y-auto h-vh-300px-mx">
            {/*begin::Email Address*/}
            <div className="d-flex flex-wrap align-items-center">
              {/*begin::Label*/}
              <div id="kt_signin_email">
                <div className="fs-6 fw-bolder mb-1">Email Address</div>
                <div className="fw-bold text-gray-600">{email}</div>
              </div>
              {/*end::Label*/}

              {/*begin::Action*/}
              <div id="kt_signin_email_button" className="ms-auto">
                <button onClick={handleShow} className="btn btn-light btn-active-light-primary">
                  Change Email
                </button>
              </div>
              {/*end::Action*/}
            </div>
            {/*end::Email Address*/}
            {/*begin::Separator*/}
            <div className="separator separator-dashed my-6" />
            {/*end::Separator*/}
            {/*begin::Password*/}
            <div className="d-flex flex-wrap align-items-center mb-10">
              {/*begin::Label*/}
              <div id="kt_signin_password">
                <div className="fs-6 fw-bolder mb-1">Password</div>
                <div className="fw-bold text-gray-600">************</div>
              </div>
              {/*end::Label*/}

              {/*begin::Action*/}
              <div id="kt_signin_password_button" className="ms-auto">
                <button onClick={ResetFunction} className="btn btn-light btn-active-light-primary">
                  Reset Password
                </button>
              </div>
              {/*end::Action*/}
            </div>
            {/*end::Password*/}
            {/*begin::Notice*/}
            <div className="border-style-dotted notice d-flex bg-light-primary rounded border-primary border-dashed p-6">
              {/*begin::Icon*/}
              {/*begin::Svg Icon | path: icons/stockholm/General/Shield-check.svg*/}
              <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <rect x={0} y={0} width={24} height={24} />
                    <path
                      d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </span>
              {/*end::Svg Icon*/}
              {/*end::Icon*/}
              {/*begin::Wrapper*/}
              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-bold w-100-140px">
                  <h4 className="text-gray-800 fw-bolder">Secure Your Account</h4>
                  <div className="fs-6 text-gray-600 pe-7">Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code</div>
                </div>

                {is2FAEnabled === false && (
                  <Button className="h-40px w-120px commonStyle btn btn-sm btn-primary my-1 btn btn-primary" onClick={(e) => Factor2Auth(true)}>
                    2FA Enable
                  </Button>
                )}
                {is2FAEnabled === true && (
                  <Button className="w-120px" variant="danger" onClick={(e) => Factor2Auth(false)}>
                    2FA Disable
                  </Button>
                )}
                {/*end::Action*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Notice*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Content*/}
      </div>

      {/* ////////////////////////Email Address POP-UP///////////////////////////////// */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Edit*/}
          <div id="kt_signin_email_edit" className="flex-row-fluid">
            {/*begin::Form*/}
            <form id="kt_signin_change_email" className="form" noValidate="novalidate">
              <div className="row mb-6">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <div className="fv-row mb-0">
                    <label htmlFor="emailaddress" className="form-label fs-6 fw-bolder mb-3">
                      Enter New Email Address
                    </label>
                    {/* <input type="email" className="form-control form-control-lg form-control-solid" id="emailaddress" placeholder="Email Address" name="emailaddress"/> */}
                    <FormInput placeholder={"Enter email address"} type={"email"} name={"newEmail"} value={changeEmail.newEmail} onChange={(e) => handleEmailChange(e)} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="fv-row mb-0">
                    <label htmlFor="confirmemailpassword" className="form-label fs-6 fw-bolder mb-3">
                      Confirm Password
                    </label>
                    <FormInput placeholder={"Enter your password"} type={"password"} name={"password"} value={changeEmail.password} onChange={(e) => handleEmailChange(e)} />
                  </div>
                </div>
              </div>
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Edit*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton" onClick={handleUpdateEmail}>
            Update Email
          </Button>
        </Modal.Footer>
      </Modal>

      {/* *******************************Reset Password POP-UP******************************* */}

      <Modal show={PassShow} onHide={ResetFunctionClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Edit*/}
          <div id="kt_signin_password_edit" className="flex-row-fluid">
            {/*begin::Form*/}
            <form id="kt_signin_change_password" className="form" noValidate="novalidate">
              <div className="row mb-1">
                <div className="col-lg-12 mb-6">
                  <div className="fv-row mb-0">
                    <label htmlFor="currentpassword" className="form-label fs-6 fw-bolder">
                      Current Password
                    </label>
                    <FormInput placeholder={"Enter your current password"} type={"password"} name={"currentpassword"} value={pass} onChange={(e) => ResetPassChange(e)} />
                  </div>
                </div>
                <div className="col-lg-12 mb-6">
                  <div className="fv-row mb-0">
                    <label htmlFor="newpassword" className="form-label fs-6 fw-bolder">
                      New Password
                    </label>
                    <FormInput placeholder={"Enter new password"} type={"password"} name={"newpassword"} value={confPass} onChange={(e) => ResetPassChange(e)} />
                  </div>
                </div>
                <div className="col-lg-12 mb-6">
                  <div className="fv-row mb-0">
                    <label htmlFor="confirmpassword" className="form-label fs-6 fw-bolder">
                      Confirm New Password
                    </label>
                    <FormInput placeholder={"Enter confirm password"} type={"password"} name={"confirmpassword"} value={newPass} onChange={(e) => ResetPassChange(e)} />
                  </div>
                </div>
              </div>
              <div className="form-text mb-5">Minimum eight characters, at least one letter, one number and one special character</div>
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Edit*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ResetFunctionClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton" onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default connect(null, actions)(ProfileOverview);
