import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Papa from "papaparse";
import readXlsxFile from "read-excel-file";
import { BlueGradientButton } from "../../button";
import UploadCSVFile from "../../file-upload/csv-upload";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import { useDispatch } from "react-redux";
import * as TemplateActions from "../../../containers/campaign/store/actions/campaign_templates_actions";
import { getDataPresentFields } from "../../../containers/import/helper";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default function UploadSMSTemplatesModal({ show, setShow, filter, category }) {
  const validHeaderKeys = ["HEADER_SENDER_ID", "SALES_SIMPLIFY_ROUTE_NAME", "INDIA_TEMPLATE_ID", "INDIA_ENTITY_ID", "TEMPLATE_CONTENT"];
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [csvData] = React.useState([
    { HEADER_SENDER_ID: "SALSSS,SAIFSU", SALES_SIMPLIFY_ROUTE_NAME: "Transactional", INDIA_TEMPLATE_ID: 8976545789764, INDIA_ENTITY_ID: 8976545789764, TEMPLATE_CONTENT: "Hi This is a Test SMS from Sales Simplify" },
    { HEADER_SENDER_ID: 287383, SALES_SIMPLIFY_ROUTE_NAME: "Promotional", INDIA_TEMPLATE_ID: 8976545789764, INDIA_ENTITY_ID: 8976545789764, TEMPLATE_CONTENT: "Hi This is a Test SMS from Sales Simplify" },
    { HEADER_SENDER_ID: "SALESS", SALES_SIMPLIFY_ROUTE_NAME: "OTP", INDIA_TEMPLATE_ID: 8976545789764, INDIA_ENTITY_ID: 8976545789764, TEMPLATE_CONTENT: "Hi This is a Test SMS from Sales Simplify" },
  ]);
  const [fileName] = React.useState("Import SMS Templates");
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const dispatch = useDispatch();

  useEffect(() => {
    if (file) {
      if (file.name && file.name.substr(file.name.length - 3) === "csv") {
        Papa.parse(file, {
          error: () => {
            dispatchSnackbarError("Could Not Parse The File!");
            setFile();
          },
          complete: (data) => {
            if (data && data.data && Array.isArray(data.data) && data.data.length > 1) {
              const validKeys = getDataPresentFields(data.data);

              for (let i = 0; i < validHeaderKeys.length; i++) {
                if (validKeys.indexOf(validHeaderKeys[i]) === -1) {
                  dispatchSnackbarError("The uploaded file is missing the necessary header information!");
                  setFile();
                  break;
                }
              }
            } else {
              dispatchSnackbarError("There is no data in the uploaded file to process!");
              setFile();
            }
          },
        });
      } else if (file.name && file.name.substr(file.name.length - 4) === "xlsx") {
        readXlsxFile(file)
          .then((rows, index) => {
            const validKeys = rows[0].filter((each) => each);

            for (let i = 0; i < validHeaderKeys.length; i++) {
              if (validKeys.indexOf(validHeaderKeys[i]) === -1) {
                dispatchSnackbarError("The uploaded file is missing the necessary header information!");
                setFile();
                break;
              }
            }
          })
          .catch(() => {
            dispatchSnackbarError("Could Not Parse The File!");
            setFile();
          });
      } else {
        setFile();
        dispatchSnackbarError("Only .csv and .xlsx files are supported");
      }
    }
  }, [file, validHeaderKeys]);

  const handleSampleCSV = () => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleUpload = () => {
    if (!file) {
      dispatchSnackbarError("Please upload a file!");
      return;
    }
    // if (!file.name.substr(file.name.length - 3) === "csv") {
    //     dispatchSnackbarError("Only CSV files are supported");
    //     return;
    // }
    setLoading(true);

    const formData = new FormData();

    formData.append("category", category);
    formData.append("file", file);

    dispatch(
      TemplateActions.UploadTemplateRequest({ data: formData, filter: filter }, (data) => {
        if (data.status) {
          dispatchSnackbarSuccess("Template uploaded successfully.");
        } else {
          dispatchSnackbarError(data.message);
        }
        setShow(false);
        setLoading(false);
      }),
    );
  };

  return (
    <Modal backdrop="static" show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upload SMS Templates</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <UploadCSVFile file={file} setFile={setFile} acceptedFiles={".csv,.xlsx"} />;
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex flex-row justify-content-between">
          <div></div>
          <div className="d-flex flex-row justify-content-end gap-10px">
            <Button variant="secondary" size="sm" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="secondary" size="sm" onClick={() => handleSampleCSV()}>
              Download Sample File
            </Button>
            <BlueGradientButton size="sm" onClick={() => handleUpload()}>
              {loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
              Upload
            </BlueGradientButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
