import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { CustomSubHeader } from "../../../components/subheader";

export default function CampaignSubHeader(props) {
  const links = [
    { name: "Campaigns", to: "/campaign/list", type: "text" },
    { name: "Marketing List", to: "/campaign/marketing/list", type: "text" },
    { name: "Templates", to: "/campaign/templates", type: "text" },
    { name: "Settings", to: "/campaign/settings", type: "text" },
    {
      name: "Reports",
      to: "/campaign/reports/summary-report",
      type: "dropdown",
      options: [
        { name: "Summary Report", to: "/campaign/reports/summary-report", target: "_self" },
        { name: "Logs", to: "/campaign/reports/logs", target: "_self" },
        { name: "Download Report", to: "/campaign/reports/download-report", target: "_self" },
      ],
      subTabNames: ["Summary Report", "Logs", "Download Report"],
    },
    {
      name: "More",
      type: "dropdown",
      options: [
        { name: "Smart Shortlink", to: "/campaign/smart-short-link", target: "_self" },
        { name: "Block Contacts", to: "/campaign/block-contact", target: "_self" },
        { name: "Generate API", to: "/settings/account/developer/api-key", target: "_self" },
        { name: "API Documentation", to: "https://apidocs.salessimplify.com/#7178d973-3f9d-415e-b3c6-ba6f81e143f9", target: "_blank" },
      ],
      subTabNames: ["Generate API", "API Documentation", "Smart Shortlink", "Block Contacts"],
    },
  ];

  const handleNavLinkClick = (to, target) => {
    if (target === "_blank") {
      window.open(to, "_blank");
    }
  };
  return (
    <CustomSubHeader>
      <div className="d-flex flex-stack w-100">
        <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
          {links.map((link, index) => (
            <div key={index} className="menu-item me-lg-1">
              {link.type === "dropdown" ? (
                <Dropdown className="h-100">
                  <Dropdown.Toggle className={`text-decoration-none h-100 menu-link ${link.subTabNames.includes(props.active) ? "active" : ""}`} variant="link">
                    <span className="menu-title pl-3">{link.name}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {link.options.map((each, ind) => {
                      return (
                        <Dropdown.Item className="p-0 m-0" key={ind}>
                          <NavLink
                            onClick={() => handleNavLinkClick(each.to, each.target)}
                            className={`menu-link py-3 ${props.active === each.name ? "active" : ""}`}
                            to={each.to}
                            rel="noopener noreferrer"
                            target={each.target}
                          >
                            <span className="menu-title">{each.name}</span>
                          </NavLink>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <NavLink className={`menu-link py-3 ${props.active === link.name ? "active" : ""}`} to={link.to}>
                  <span className="menu-title">{link.name}</span>
                </NavLink>
              )}
            </div>
          ))}
        </div>
        <div>{props.children}</div>
      </div>
    </CustomSubHeader>
  );
}
