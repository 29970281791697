import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import CadenceTemplateStep2 from "./template.step2";
import SelectionStep1 from "./selection.step1";

export const SELECTED_STEP_TYPES = {
  NONE: "NONE",
  PREBUILT: "PREBUILT",
  CLONE: "CLONE",
};

export function AddNewCadenceModal(props) {
  const [selectedStep, setSelectedStep] = useState(SELECTED_STEP_TYPES.NONE);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const history = useHistory();

  const cadencePrebuiltTemplateStore = useSelector((store) => store.cadence.prebuiltTemplate);
  const cadenceCloneableTemplateStore = useSelector((store) => store.cadence.cloneableTemplate);

  const isTemplate = selectedStep === SELECTED_STEP_TYPES.PREBUILT || selectedStep === SELECTED_STEP_TYPES.CLONE;

  useEffect(() => {
    if (selectedStep === SELECTED_STEP_TYPES.PREBUILT && !_.isEmpty(cadencePrebuiltTemplateStore.data)) {
      setSelectedTemplate(cadencePrebuiltTemplateStore.data[0]);
    }
    if (selectedStep === SELECTED_STEP_TYPES.CLONE && !_.isEmpty(cadenceCloneableTemplateStore.data)) {
      setSelectedTemplate(cadenceCloneableTemplateStore.data[0]);
    }
  }, [cadencePrebuiltTemplateStore.data, cadenceCloneableTemplateStore.data, selectedStep]);

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)} size={isTemplate ? "xl" : "lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Add Cadence</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-0 p-0 overflow-y-auto h-70vh-mx">
        {!isTemplate ? (
          <div className="p-8">
            <SelectionStep1 history={history} {...{ setSelectedStep }} />
          </div>
        ) : (
          <CadenceTemplateStep2
            list={selectedStep === SELECTED_STEP_TYPES.PREBUILT ? cadencePrebuiltTemplateStore.data : selectedStep === SELECTED_STEP_TYPES.CLONE ? cadenceCloneableTemplateStore.data : []}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
      </Modal.Body>
      {isTemplate && (
        <Modal.Footer className="d-flex">
          <div className="d-flex gap-3">
            <Button variant="secondary" size="sm" onClick={() => setSelectedStep(SELECTED_STEP_TYPES.NONE)}>
              Back
            </Button>
            <Button disabled={!selectedTemplate || props.loading} size="sm" onClick={() => props.onCreateCadenceClick(selectedTemplate._id, selectedStep)}>
              {props.loading && <Spinner animation="border" className="mr-10px w-16px h-16px"></Spinner>}
              Create Cadence
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
