import { takeLatest, put, call } from "redux-saga/effects";
import * as loginActions from "./actions";
//import required action types below
import {
  LOGIN_REQUEST,
  CHECKEMAIL_REQUEST,
  SIGNUPINFO_REQUEST,
  EMAILOTP_REQUEST,
  PATCHPHONE_REQUEST,
  UPDATEACCOUNT_REQUEST,
  GETGOOGLEAPI_REQUEST,
  POSTGOOGLEAPI_REQUEST,
  VERIFYMANAGEUSER_REQUEST,
  FORGOTPASSWORD_REQUEST,
  FORGOTPASSWORDPOST_REQUEST,
  VERIFY_COUPONCODE_REQUEST,
  //other required types
  createAccountV2,
  verifyPreAccount,
  getAccountCreatedStatus,
} from "./types";
import api from "../../../network/apis/api";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../utils/toaster";

function* loginFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/login", action.payload);
    // localStorage.setItem('access_token', json['token']);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      // dispatchSnackbarSuccess("Login Successfully","success")
      // localStorage.setItem('access_token',response.data.token)
      yield put(loginActions.loginSuccess(response.data));
    } else if (response.status === 400) {
      // dispatchSnackbarError(response['data']['message'], 'error')
    } else if (response.status === 401) {
      // dispatchSnackbarError(response['data']['message'], 'error')
    } else {
      yield put(loginActions.loginError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(loginActions.loginError(e));
  }
}

function* CheckEmailFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/user", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.CheckEmailSuccess(response.data));
    } else {
      yield put(loginActions.CheckEmailError("error"));
    }
  } catch (e) {
    yield put(loginActions.CheckEmailError(e));
  }
}

function* SignUpInfoFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/email/verify-otp", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.SignUpInfoSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      dispatchSnackbarSuccess("Account Created", "success");
    } else if (response.status === 400) {
      dispatchSnackbarError("Invalid OTP", "error");
    } else {
      yield put(loginActions.SignUpInfoError("error"));
    }
  } catch (e) {
    yield put(loginActions.CheckEmailError(e));
  }
}

function* EmailOTPFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/email/send-otp", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.EmailOTPSuccess(response.data));
    } else {
      yield put(loginActions.EmailOTPError("error"));
    }
  } catch (e) {
    yield put(loginActions.EmailOTPError(e));
  }
}

function* PatchPhoneFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/account", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.PatchPhoneSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(loginActions.PatchPhoneSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["data"]["message"], "error");
    } else {
      dispatchSnackbarError("Something went wrong", "error");
      yield put(loginActions.PatchPhoneError("error"));
    }
  } catch (e) {
    yield put(loginActions.PatchPhoneError(e));
  }
}

function* UpdateAccountFunction(action) {
  try {
    let response;
    if (action.payload.status === "ACTIVE" || action.payload.status === "DEACTIVATED") {
      response = yield call(api.post, "/user-service/api/account/activate-deactivate", action.payload);
    } else {
      response = yield call(api.patch, `/user-service/api/account${"/" + action.params}`, action.payload);
    }
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.UpdateAccountSuccess(response.data));
    } else {
      dispatchSnackbarError(response.data.message, "error");
      yield put(loginActions.UpdateAccountError("error"));
    }
  } catch (e) {
    yield put(loginActions.UpdateAccountError(e));
  }
}

function* getGoogleAPIFunction(action) {
  try {
    const response = yield call(api.get, `/user-service/api/login/url/${action.params}`);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.GoogleAPISuccess(response.data));
    } else {
      yield put(loginActions.GoogleAPIError("error"));
    }
  } catch (e) {
    yield put(loginActions.GoogleAPIError(e));
  }
}

function* PostGoogleApiFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/login/google", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.PostGoogleAPISuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(loginActions.PostGoogleAPISuccess(response.data));
    } else {
      yield action.cb(response.data);
      yield put(loginActions.PostGoogleAPIError("error"));
    }
  } catch (e) {
    yield put(loginActions.PostGoogleAPIError(e));
  }
}

function* verifyManagerFunction(action) {
  try {
    const response = yield call(api.post, `/user-service/api/user/verify/${action.params}`, action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.ManagerInviteSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(loginActions.PostGoogleAPISuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message, "error");
    } else {
      yield put(loginActions.ManagerInviteError("error"));
    }
  } catch (e) {
    yield put(loginActions.ManagerInviteError(e));
  }
}

function* forgotPasswordFunction(action) {
  try {
    const response = yield call(api.get, `/user-service/api/forget-pass/${action.params}`, action.payload);
    if (response.status === 200) {
      yield put(loginActions.ForgotPassSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["data"]["message"], "error");
    } else {
      yield put(loginActions.ForgotPassError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(loginActions.ForgotPassError(e));
  }
}

function* forgotPasswordpostFunction(action) {
  try {
    const response = yield call(api.post, `/user-service/api/forget-pass`, action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(loginActions.forgetPassPostSuccess(response.data));
    } else if (response.status === 400) {
      if (typeof (response["data"]["message"] === "object")) {
        // dispatchSnackbarError("Your Token is Not Valid", 'error');
        // window.location.href = '/auth/login'
      } else {
        // dispatchSnackbarError(response['data']['message'], 'error')
      }
      yield put(loginActions.forgetPassPostError("error"));
    } else {
      yield put(loginActions.forgetPassPostError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(loginActions.forgetPassPostError(e));
  }
}

function* verifyCouponCode(action) {
  try {
    const res = yield call(api.post, "/user-service/api/coupon-code/verify", action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message, "success");
      yield action.cb(res.data);
    } else {
      dispatchSnackbarError(res.data.message, "error");
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(loginActions.verifyCouponCodeError(e));
  }
}

function* createAccountV2Func(action) {
  const res = yield call(api.post, "/user-service/api/account/v2/create", action.payload);
  yield action.cb(res.data);
}

function* verifyPreAccountFunc(action) {
  const res = yield call(api.post, "/user-service/api/account/v2/verify", action.payload);
  yield action.cb(res.data);
}

function* getAccountCreatedStatusFunc(action) {
  const res = yield call(api.get, "/user-service/api/account/v2/verify", action.payload);
  yield action.cb(res.data);
}

export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, loginFunction); // similarly add required functions
  yield takeLatest(CHECKEMAIL_REQUEST, CheckEmailFunction);
  yield takeLatest(SIGNUPINFO_REQUEST, SignUpInfoFunction);
  yield takeLatest(EMAILOTP_REQUEST, EmailOTPFunction);
  yield takeLatest(PATCHPHONE_REQUEST, PatchPhoneFunction);
  yield takeLatest(UPDATEACCOUNT_REQUEST, UpdateAccountFunction);
  yield takeLatest(GETGOOGLEAPI_REQUEST, getGoogleAPIFunction);
  yield takeLatest(POSTGOOGLEAPI_REQUEST, PostGoogleApiFunction);
  yield takeLatest(VERIFYMANAGEUSER_REQUEST, verifyManagerFunction);
  yield takeLatest(FORGOTPASSWORD_REQUEST, forgotPasswordFunction);
  yield takeLatest(FORGOTPASSWORDPOST_REQUEST, forgotPasswordpostFunction);
  yield takeLatest(VERIFY_COUPONCODE_REQUEST, verifyCouponCode);

  yield takeLatest(createAccountV2.CREATE_ACCOUNT_V2_REQUEST, createAccountV2Func);
  yield takeLatest(verifyPreAccount.VERIFY_PRE_ACCOUNT_REQUEST, verifyPreAccountFunc);
  yield takeLatest(getAccountCreatedStatus.GET_ACCOUNT_CREATED_STATUS_REQUEST, getAccountCreatedStatusFunc);
}
