import { takeLatest, put, call } from "redux-saga/effects";
import * as activityTypes from "../types/activity.types";
import api from "../../../../network/apis/api";
import * as ActivityOperationsActions from "../actions/activity_operations_actions";
import * as ActivityListActions from "../actions/activitity_list_actions";
import * as ActivityStaticticsActions from "../actions/staticstics_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import { getDealRequestAfter } from "../../../deals/dealPipeline/actions";
import { getDealListRequestAfter } from "../../../deals/dealList/actions";
import { leadsUpdateRequest } from "../../../leads/store/actions/actions";
import { peoplesUpdateRequest } from "../../../customers/store/actions/actions";

function* activityMarkAsDone(action) {
  try {
    const response = yield call(api.patch, "/api/crm/activity-list/mark-as-done", action.payload.data);
    if (response.status === 200) {
      yield put(ActivityOperationsActions.markAsDoneSuccess(response.data));
      if (action.payload.loadingData) {
        yield put(ActivityListActions.getListOfActivtiesRequest(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }
      if (!action.payload.havingFilter && action.payload.data.pipelineId) yield put(getDealRequestAfter({ pipelineId: action.payload.data.pipelineId }));
      else if (action.payload.pageForm === "dealList") yield put(getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(getDealRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "activeLead") yield put(leadsUpdateRequest({ loadingData: action.payload.filter }));
      else if (action.payload.pageForm === "people") yield put(peoplesUpdateRequest({ loadingData: action.payload.filter }));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityOperationsActions.markAsDoneError(response));
    } else {
      yield put(ActivityOperationsActions.markAsDoneError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityOperationsActions.markAsDoneError(e));
  }
}

function* activityMarkAsUnDone(action) {
  try {
    const response = yield call(api.patch, `/api/crm/activity-list/mark-as-un-done`, action.payload.id);
    if (response.status === 200) {
      yield put(ActivityOperationsActions.markAsUndoneSuccess(response.data));
      if (action.payload.loadingData) {
        yield put(ActivityListActions.getListOfActivtiesRequest(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityOperationsActions.markAsUndoneError(response));
    } else {
      yield put(ActivityOperationsActions.markAsUndoneError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityOperationsActions.markAsUndoneError(e));
  }
}

function* snoozeActivity(action) {
  try {
    const response = yield call(api.patch, `/api/crm/activity-list/snooze-activity`, action.payload.data);
    if (response.status === 200) {
      yield put(ActivityOperationsActions.snoozeActivitySuccess(response.data));
      if (action.payload.loadingData) {
        yield put(ActivityListActions.getListOfActivtiesRequest(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityOperationsActions.snoozeActivityError(response));
    } else {
      yield put(ActivityOperationsActions.snoozeActivityError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityOperationsActions.snoozeActivityError(e));
  }
}

function* searchAllAssociations(action) {
  try {
    const response = yield call(api.get, `/api/crm/lead/v2/search-all`, action.payload);
    if (response.status === 200) {
      yield put(ActivityOperationsActions.searchAllAssociationsSuccess(response.data));
    } else if (response.status === 400) {
      yield put(ActivityOperationsActions.searchAllAssociationsError(response));
    } else {
      yield put(ActivityOperationsActions.searchAllAssociationsError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityOperationsActions.searchAllAssociationsError(e));
  }
}

function* getUserActivityLogsForSidepanel(action) {
  try {
    const res = yield call(api.get, "/api/crm/activity/logs", action.payload);
    if (res.status === 200) {
      yield action.cb(res.data);
    } else {
      yield action.cb([]);
    }
  } catch (e) {
    yield action.cb([]);
  }
}

export default function* ActivitySaga() {
  //Activities
  yield takeLatest(activityTypes.markAsDone.MARK_AS_DONE_ACTIVITY_REQUEST, activityMarkAsDone);
  yield takeLatest(activityTypes.markAsUndone.MARK_AS_UNDONE_ACTIVITY_REQUEST, activityMarkAsUnDone);
  yield takeLatest(activityTypes.snoozeActivity.SNOOZE_ACTIVITY_REQUEST, snoozeActivity);
  yield takeLatest(activityTypes.searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_REQUEST, searchAllAssociations);
  yield takeLatest(activityTypes.getUserActivityLogForSidePannel.GET_USER_ACTIVITY_LOGS_REQUEST_FOR_SIDEPANEL, getUserActivityLogsForSidepanel);
}
