import React from "react";
import NormalTable from "../../../../components/normal-table/index";

export default function Referrals() {
  return (
    <>
      <NormalTable tableHeading={["Order ID", "User", "Date", "Bonus", "Profit"]} tableTitle={"Referred Users"} />
    </>
  );
}
