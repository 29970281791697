import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

//components
import { CustomSubHeader } from "../../../components/subheader";
import ContentWrapper from "../../Wrapper";
import SalesStaticstics from "./staticstics";

//actions
import * as ActivityListActions from "../store/actions/activitity_list_actions";
import * as ActivityDataFieldActions from "../store/actions/activity_data_field_actions";
import * as ActivitySavedFilterActions from "../store/actions/activity_saved_filters_actions";
import * as ActivityStaticticsActions from "../store/actions/staticstics_actions";
import * as ActivityOperationsActions from "../store/actions/activity_operations_actions";
import * as ActivityLossReasonActions from "../store/actions/activity_loss_reasons_actions";
import * as ActivityTypesActions from "../store/actions/activity_types_actions";
import * as CompanyActions from "../../companies/store/actions/actions";
import * as LeadActions from "../../leads/store/actions/actions";
import * as PeopleActions from "../../customers/store/actions/actions";
import * as DealListActions from "../../deals/dealList/actions";
import * as DealPipelineActions from "../../deals/dealPipeline/actions";
import * as ProductActions from "../../products/store/actions/actions";
import * as insightActions from "../../insights/actions";

//styles
import "./styles.scss";
import { ContentWithCardLoading } from "../../../components/loading";
import { EditableActivityTable } from "./table";
import ActivitySubHeader from "./activity-sub-header";
import AddActivityModel from "./add-activity";
import { DeleteModal } from "../../../components/modal";
import SidePannelDetailed from "../../../components/side-pannel-detailed";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import * as TemplateActions from "../../campaign/store/actions/campaign_templates_actions";
import InlineStyled from "../../../components/inline-styled/inline-styled";

export default function ActivityList(props) {
  //filters
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(30);
  const [status, setStatus] = useState("Todo");
  const [virtualFilter, setVirtualFilter] = useState({});
  const [filterId, setFilterId] = useState("");
  const [selectedActivityType, setSelectedAcivityType] = useState([]);
  const [source, setSource] = useState("");
  const [owner, setOwner] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //table related
  const [showColumns, setShowColumns] = useState([]);
  const [idToIndex, setIdToIndex] = useState({});
  const [radioChecked, setRadioChecked] = useState([]);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const [checked, setChecked] = useState([]);

  //header
  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "activity",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
  });
  const [showDelete, setShowDelete] = useState(false);

  //stats
  const [showStaticsStics, setShowStaticstics] = useState(false);

  //side pannel
  const [showPannel, setShowPannel] = useState(false);
  const [sidePannelModule, setSidePannelModule] = useState("");
  const [sidePannelId, setSidePannelId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [activityEvent, setActivityEvent] = useState({});

  //add activity
  const [openAddActivityModal, setOpenAddActivityModal] = useState(false);
  const [selectedAddActivity, setSelectedAddActivity] = useState({});

  const page = "Activity";
  const pageSub = "activity";

  const dispatch = useDispatch();
  const history = useHistory();
  const { linkedId, moduleName, activityId } = useParams();

  //store
  const activityListStore = useSelector((store) => store.activity.list);
  const loadingStore = useSelector((store) => store.activity.list.loadingData);
  const activityDataFieldStore = useSelector((store) => store.activity.dataField);
  const activitySavedFilterStore = useSelector((store) => store.activity.savedFilters);
  const activityStaticsticsStore = useSelector((store) => store.activity.staticstics);
  // const activityLossReasonStore = useSelector(store => store.activity.lossReasons);
  // const activityTypesStore = useSelector(store => store.activity.types);

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      limit: limit,
      filter: status,
      startDate: startDate ? startDate : 0,
      endDate: endDate ? endDate : 0,
      filterId: filterId,
      activityType: selectedActivityType.join(","),
      source: source,
      owner: owner,
    };
    if (!_.isEmpty(virtualFilter)) {
      payload["customFilter"] = JSON.stringify(virtualFilter);
    }

    dispatch(ActivityListActions.getListOfActivtiesRequest(payload));
    dispatch(ActivityListActions.updateActivityLoadingData(payload));
  }, [dispatch, status, limit, pageNo, virtualFilter, filterId, selectedActivityType, source, owner, startDate, endDate]);

  useEffect(() => {
    dispatch(ActivitySavedFilterActions.getListOfActivitySavedFiltersRequest());
    dispatch(ActivityDataFieldActions.getActivtyDataFieldsRequest({ skipIfDataPresent: true }));
    dispatch(ActivityLossReasonActions.getActivityLossReasonsRequest({ skipIfDataPresent: true }));
    dispatch(ActivityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: true }));
    dispatch(ActivityTypesActions.getListOfActiveActivityTypesRequest());
    dispatch(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());

    dispatch(LeadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));

    dispatch(PeopleActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(PeopleActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));

    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    dispatch(DealListActions.getDealListFieldsRequest({ skipIfDataPresent: true }));
    dispatch(DealPipelineActions.getLostReasonsRequest());

    //common api Calls
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    const tempCol = [];
    const mappingIds = {};
    const tempFieldStore = activityDataFieldStore.list;
    for (let i = 0; i < tempFieldStore.length; i++) {
      mappingIds[tempFieldStore[i]["_id"]] = i;
      if (tempFieldStore[i].isEnabledForTable) {
        tempCol.push(tempFieldStore[i]["_id"]);
      }
    }
    setShowColumns(tempCol);
    setIdToIndex(mappingIds);
  }, [activityDataFieldStore]);

  useEffect(() => {
    const status = localStorage.getItem("showStats");
    if (status === "false") {
      setShowStaticstics(false);
    } else if (status === "true") {
      setShowStaticstics(true);
    } else {
      setShowStaticstics(false);
    }
  }, []);

  useEffect(() => {
    if (moduleName && linkedId) {
      setShowPannel(true);
      setSidePannelModule(moduleName);
      setSidePannelId(linkedId);
    } else {
      setShowPannel(false);
      setSidePannelModule("");
      setSidePannelId("");
    }
  }, [linkedId, moduleName]);

  useEffect(() => {
    if (activityListStore.list) {
      const _data = activityListStore.list
        .map((each) => {
          if (!_.isEmpty(each.associated) && typeof each.associated === "object") {
            if (each.associated.type === "deal") {
              return { module: "deal", id: `${each._id}/deal/${each.associated._id}` };
            } else if (each.associated.type === "lead") {
              return { module: "lead", id: `${each._id}/lead/${each.associated._id}` };
            } else if (each.associated.type === "people") {
              return { module: "people", id: `${each._id}/people/${each.associated._id}` };
            } else if (each.associated.type === "company") {
              return { module: "company", id: `${each._id}/company/${each.associated._id}` };
            } else return null;
          }
          return null;
        })
        .filter((a) => a);
      setNextPreviousData(_data);
    }
  }, [activityListStore.list]);

  useEffect(() => {
    if (activityListStore.list && activityId) {
      const data = activityListStore.list.find((each) => each._id === activityId);
      if (data && data.cadenceLogId && _.isObject(data.cadenceLogId) && !_.isEmpty(data.cadenceLogId)) {
        setActivityEvent({ ...data.cadenceLogId, activityId });
      }
    }
  }, [activityListStore.list, activityId]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("activityListOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("activityListOwner", owner);
    else localStorage.removeItem("activityListOwner");
  }, [owner]);

  const handleEditProduct = (combinedId, data) => {
    dispatch(ActivityListActions.editActivityRequest({ editData: data, loadingData: loadingStore, type: "edit", cellId: combinedId, from: "activity-list" }));
  };

  const onAddActivityClick = () => {
    setOpenAddActivityModal(true);
  };

  //coloum filter actions
  const showHideField = (data) => {
    data.fieldType = "ACTIVITY";
    dispatch(ActivityDataFieldActions.showHideActivtyFieldRequest(data));
  };

  const updateFieldPriority = (data) => {
    dispatch(ActivityDataFieldActions.updateActivityFieldPriorityRequest({ ...data, fieldType: "ACTIVITY" }));
  };

  // saved filters
  const applyFilter = (data) => {
    setFilterId(data);
    setVirtualFilter({});
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
  };
  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      dispatch(
        ActivitySavedFilterActions.createActivitySavedFilterRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        ActivitySavedFilterActions.createActivitySavedFilterRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData({
      filterModule: "activity",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    dispatch(
      ActivitySavedFilterActions.editActivitySavedFilterRequest(temp, (data) => {
        if (data.status && temp.id === filterId) applyFilter(filterId);
      }),
    );
    setAddFilterFormData({
      filterModule: "activity",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const deleteFilter = (id) => {
    dispatch(ActivitySavedFilterActions.deleteActivitySavedFiltersRequest(id));
    setAddFilterFormData({
      filterModule: "activity",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const favoriteFilter = (data) => {
    dispatch(ActivitySavedFilterActions.markAsFavoriteActivitySaveFilterRequest(data));
  };

  const closeScheduleActivity = (value) => {
    setOpenAddActivityModal(value);
  };

  const onCreateActivity = (data) => {
    dispatch(ActivityListActions.createActivityRequest({ activityData: data, loadingData: loadingStore, from: "activity-list" }));
  };
  const callActivityMarkAsUndoneApi = (id) => {
    dispatch(ActivityOperationsActions.markAsUndoneRequest({ id: { id: id }, loadingData: loadingStore }));
  };

  const callActivityMADoneApi = (data) => {
    dispatch(ActivityOperationsActions.markAsDoneRequest({ data: data, loadingData: loadingStore }));
  };

  const scheduleNextActivity = (id) => {
    const _find = activityListStore.list.find((a) => a._id === id);
    if (_find) {
      setSelectedAddActivity(_find);
    }
    setOpenAddActivityModal(true);
  };

  const handleDeleteActivities = () => {
    dispatch(ActivityListActions.bulkDeleteActivityRequest({ activityIds: { ids: checked }, loadingData: loadingStore }));
    setChecked([]);
    setShowDelete(false);
  };

  const tableProps = {
    page: page,
    pageSub: pageSub,

    //activity data
    loading: activityListStore.loading,
    data: activityListStore.list,

    //column
    showColumns: showColumns,
    idToIndex: idToIndex,
    columns: activityDataFieldStore.list,

    //checked
    checked: checked,
    setChecked: setChecked,

    //table pagination
    count: activityListStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,

    radioChecked: radioChecked,
    setRadioChecked: setRadioChecked,

    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    handleCellEdit: handleEditProduct,

    cellLoading: activityListStore.cellLoading,
    callActivityMADoneApi: (data) => callActivityMADoneApi(data),
    callActivityMarkAsUndoneApi: (id) => callActivityMarkAsUndoneApi(id),
    scheduleNextActivity: scheduleNextActivity,
  };

  const deleteModalProps = {
    show: showDelete,
    title: `Delete ${checked.length > 1 ? "Activities" : "Activity"}`,
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeleteActivities,
  };

  const onCloseSidePannel = () => {
    // history.goBack();
    history.push("/activity/activity-list");
  };

  const setSnoozeTime = (time) => {
    dispatch(
      ActivityOperationsActions.snoozeActivityRequest({
        data: { ids: checked, time: time },
        loadingData: loadingStore,
      }),
    );
    setChecked([]);
  };

  const isEmptyPage = () => {
    if (activityListStore.list.length === 0 && _.isEmpty(virtualFilter) && _.isEmpty(source) && _.isEmpty(selectedActivityType) && _.isEmpty(filterId)) {
      return true;
    }
    return false;
  };

  const handleStatsShowHide = () => {
    setShowStaticstics(!showStaticsStics);
    localStorage.setItem("showStats", !showStaticsStics);
  };

  const onNextPreviousClick = (id) => {
    history.push(`/activity/activity-list/${id}`);
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          {/* {activityListStore.loading || activityStaticsticsStore.loading ? <SubHeaderLoading /> : */}
          <ActivitySubHeader
            onAddActivityClick={onAddActivityClick}
            isEmptyPage={isEmptyPage()}
            setShowDelete={() => setShowDelete(true)}
            checked={checked}
            setSnoozeTime={setSnoozeTime}
            //status
            status={status}
            setStatus={setStatus}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            //owner
            owner={owner}
            setOwner={setOwner}
            //activity Type
            selectedActivityType={selectedActivityType}
            handleActivityTypeClick={setSelectedAcivityType}
            //source
            source={source}
            handleSourceClick={setSource}
            //saved filter
            pageSub={pageSub}
            page={page}
            customFilters={activitySavedFilterStore.list}
            loading={activitySavedFilterStore.loading}
            setAddFilterFormData={setAddFilterFormData}
            virtualFilter={virtualFilter}
            applyVirtualFilter={applyVirtualFilter}
            selectedFilterId={filterId}
            favoriteFilter={favoriteFilter}
            applyFilter={applyFilter}
            addFilterFormData={addFilterFormData}
            addFilter={addFilter}
            editFilter={editFilter}
            deleteFilter={deleteFilter}
            //coloum filter
            showHideField={showHideField}
            updateFieldPriority={updateFieldPriority}
            idToIndex={idToIndex}
            columns={activityDataFieldStore.list}
            showColumns={showColumns}
            setShowColumns={setShowColumns}
          />
          {/* } */}
        </CustomSubHeader>
      }
    >
      <div className="activity-list-page-container">
        <InlineStyled className="activity-table-list-container" styles={{ customWidth2: showStaticsStics ? "calc(100% - 400px - 20px)" : "calc(100% - 20px - 60px)" }}>
          {activityListStore.loading && <ContentWithCardLoading />}
          {!activityListStore.loading && isEmptyPage() ? (
            <CustomEmptyPage page="activity" setShowAdd={onAddActivityClick} />
          ) : (
            <div className={`card card-custom`}>
              <div className="card-body py-3 px-3">
                <EditableActivityTable props={tableProps} />
              </div>
            </div>
          )}
        </InlineStyled>
        <SalesStaticstics loading={activityStaticsticsStore.loading} showStaticsStics={showStaticsStics} handleStatsShowHide={handleStatsShowHide} />
      </div>

      <AddActivityModel
        selectedAddActivity={selectedAddActivity}
        openAddActivityModal={openAddActivityModal}
        onCreateActivity={(data) => onCreateActivity(data)}
        closeScheduleActivity={(value) => closeScheduleActivity(value)}
      />
      <DeleteModal modalProps={deleteModalProps}>Are you sure you want to delete the {checked.length > 1 ? "Activities" : "Activity"} ?</DeleteModal>
      <SidePannelDetailed
        show={showPannel}
        basePath={"/activity/activity-list"}
        onClose={onCloseSidePannel}
        moduleName={sidePannelModule}
        moduleId={sidePannelId}
        parentId={activityId}
        nextPreviousData={nextPreviousData}
        pageForm={"activityList"}
        filter={loadingStore}
        // custom functionality
        autoUpdateActivityEvent={true}
        activityEvent={activityEvent}
        // handle next and previous
        isCustomNextPrevious={true}
        onNextClick={onNextPreviousClick}
        onPreviousClick={onNextPreviousClick}
      />
    </ContentWrapper>
  );
}
