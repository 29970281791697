import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import { getMarketingLogsRequest, getMarketingLogsLoadMoreRequest } from "../../../containers/campaign/store/actions/campaign_marketing_list_actions";

import { getCampaignEmailContent, getCampaignSMSContent, getCampaignWhatsappContent } from "./marketing-timeline.helper";
import { CustomEmptyPage } from "../../empty_page/custom-empty-page";

export default function MarketingTimeline(props) {
  const [pageNo, setPageNo] = useState(1);
  const [limit] = useState(10);

  const dispatch = useDispatch();

  const logStore = useSelector((store) => store.campaign.marketing.log);

  useEffect(() => {
    dispatch(getMarketingLogsRequest({ query: { limit: limit, pageNo: pageNo }, id: props.marketingId }));
  }, [dispatch, props.marketingId, limit, pageNo]);

  const getIconsClassName = (item) => {
    if (item && item.campaignId && item.campaignId.channel && item.campaignId.channel === "email") {
      return <i className={`bi bi-envelope font-size-20px text-02507a`}></i>;
    } else if (item && item.campaignId && item.campaignId.channel && item.campaignId.channel === "sms") {
      return <i className={`bi bi-chat-right-text font-size-16px text-02507a`}></i>;
    } else if (item && item.campaignId && item.campaignId.channel && item.campaignId.channel === "whatsapp") {
      return <i className={`bi bi-whatsapp font-size-16px text-02507a`}></i>;
    }
    return <i className={`bi bi-pen-fill text-02507a pt-3px`}></i>;
  };
  const renderEachLogs = (each) => {
    if (each && each.campaignId && each.campaignId.channel && each.campaignId.channel === "email") {
      return getCampaignEmailContent(each);
    } else if (each && each.campaignId && each.campaignId.channel && each.campaignId.channel === "sms") {
      return getCampaignSMSContent(each);
    } else if (each && each.campaignId && each.campaignId.channel && each.campaignId.channel === "whatsapp") {
      return getCampaignWhatsappContent(each);
    }
    else return "";
  };

  const onLoadMoreClick = () => {
    dispatch(getMarketingLogsLoadMoreRequest({ query: { limit: limit, pageNo: pageNo + 1 }, id: props.marketingId }));
    setPageNo(pageNo + 1);
  };
  return (
    <>
      {logStore.loading ? (
        <div className="d-flex h-100px flex-center">
          {" "}
          <Spinner animation="border" variant="primary" className="mr-10px w-26px h-26px"></Spinner>
        </div>
      ) : logStore.data.length === 0 ? (
        <CustomEmptyPage page="emptyTimeline" hideBorder={true} />
      ) : (
        <div className="timeline">
          {logStore.data.map((item, index) => {
            return (
              <div key={index} className="timeline-item">
                <div className="timeline-line w-30px"></div>
                <div className="timeline-icon symbol symbol-circle symbol-30px me-4 border-width-2px border-style-solid border-bottom-color-02507a">
                  <div className="symbol-label">{getIconsClassName(item)}</div>
                </div>
                <div className="timeline-content mb-10">{renderEachLogs(item)}</div>
              </div>
            );
          })}
          {logStore.count > logStore.data.length ? (
            <div className="d-flex justify-content-center">
              <span className="btn btn-secondary py-7px px-20px h-34px" onClick={() => onLoadMoreClick()}>
                {logStore.loadMoreLoading && <Spinner animation="border" variant="primary" className="mr-10px w-16px h-16px"></Spinner>}
                Load More
              </span>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
