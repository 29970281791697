import { bulkEditProductV2, bulkDeleteProductV2 } from "../types/product-operations.types";

export const bulkEditProductV2Request = (payload, cb) => ({
  type: bulkEditProductV2.BULK_EDIT_PRODUCT_V2_REQUEST,
  payload,
  cb,
});
export const bulkEditProductV2Success = (payload) => ({
  type: bulkEditProductV2.BULK_EDIT_PRODUCT_V2_SUCCESS,
  payload,
});
export const bulkEditProductV2Error = (payload) => ({
  type: bulkEditProductV2.BULK_EDIT_PRODUCT_V2_ERROR,
  payload,
});

export const bulkDeleteProductV2Request = (payload, cb) => ({
  type: bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_REQUEST,
  payload,
  cb,
});
export const bulkDeleteProductV2Success = (payload) => ({
  type: bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_SUCCESS,
  payload,
});
export const bulkDeleteProductV2Error = (payload) => ({
  type: bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_ERROR,
  payload,
});
