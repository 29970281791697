import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateWhatsappPerDayLimitRequest } from "../../../../../store/actions/campaign_whatsapp_setting_actions";

export default function WhatsappMessagingLimit(props) {
    const [limit, setLimit] = useState(0);
    const dispatch = useDispatch();

    const whatsappAccountsStore = useSelector(state => state.campaign.settings.whatsapp.data);

    useEffect(() => {
        if (_.isEmpty(whatsappAccountsStore.list)) return;
        const integration = whatsappAccountsStore.list.find(item => item._id === props.integrationId);
        if (integration) {
            setLimit(integration.dailyLimit || 0)
        }
    }, [whatsappAccountsStore.list, props.integrationId])

    const handleSave = () => {
        const payload = {
            "integrationId": props.integrationId,
            data: {
                "integrationId": props.integrationId,
                "dailyLimit": limit
            }
        }
        dispatch(updateWhatsappPerDayLimitRequest(payload))
    }
    return (<Form.Group className="p-8">
        <Form.Label>WhatsApp Message Limit (Per Day)</Form.Label>
        <Form.Control type="number" value={limit} onChange={e => setLimit(e.target.value)} />
        <p className="mt-2">WhatsApp has a limit of 24 hours for sending messages to customers. After 24 hours, you can only send messages to customers who have messaged you first.</p>
        <p>For more information, please refer to <a href="https://developers.facebook.com/docs/whatsapp/messaging-limits" rel="noopener noreferrer" target="_blank">WhatsApp Messaging Limits</a></p>
        <Button variant="primary" size="sm" onClick={handleSave}>Save</Button>
    </Form.Group>)
}