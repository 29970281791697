import React from "react";
import moment from "moment";

export default function DueDateDisplay(props) {
  const getDisplayValue = (date) => {
    const REFERENCE = moment();

    if (date.isSame(REFERENCE.clone().startOf("day"), "d")) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      date.isSame(
        REFERENCE.clone()
          .subtract(1, "days")
          .startOf("day"),
        "d",
      )
    ) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      date.isSame(
        REFERENCE.clone()
          .add(1, "days")
          .startOf("day"),
        "d",
      )
    ) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      moment(date)
        .startOf("day")
        .format() === moment(date).format()
    ) {
      return moment(date).format("D MMM");
    }
    return moment(date).format("D MMM, hh:mm A");
  };

  if (props.data && props.hasOwnProperty("dataStatus")) {
    return <span className={`data-container ${props.dataStatus === "overdue" ? "text-red" : props.dataStatus === "completed" ? "text-green" : "outline-none"}`}>{getDisplayValue(moment(new Date(props.data)))}</span>;
  } else if (props.data) {
    return <span className="data-container">{getDisplayValue(moment(new Date(props.data)))}</span>;
  } else return <span className="data-container"></span>;
}

export function ScheduleADateDisplay(props) {
  const getDisplayValue = (date) => {
    const REFERENCE = moment();

    if (date.isSame(REFERENCE.clone().startOf("day"), "d")) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      date.isSame(
        REFERENCE.clone()
          .subtract(1, "days")
          .startOf("day"),
        "d",
      )
    ) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      date.isSame(
        REFERENCE.clone()
          .add(1, "days")
          .startOf("day"),
        "d",
      )
    ) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      moment(date)
        .startOf("day")
        .format() === moment(date).format()
    ) {
      return moment(date).format("D MMM");
    }
    return moment(date).format("D MMM, hh:mm A");
  };

  if (props.data) {
    return <span className="data-container">{getDisplayValue(moment(new Date(props.data)))}</span>;
  } else return <span className="data-container"></span>;
}

export function ScheduleOnlyADateDisplay(props) {
  const getDisplayValue = (date) => {
    const REFERENCE = moment();

    if (date.isSame(REFERENCE.clone().startOf("day"), "d")) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      date.isSame(
        REFERENCE.clone()
          .subtract(1, "days")
          .startOf("day"),
        "d",
      )
    ) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      date.isSame(
        REFERENCE.clone()
          .add(1, "days")
          .startOf("day"),
        "d",
      )
    ) {
      return moment(date)
        .calendar()
        .replace(" at 12:00 AM", "")
        .replace(" at", ",");
    } else if (
      moment(date)
        .startOf("day")
        .format() === moment(date).format()
    ) {
      return moment(date).format("D MMM");
    }
    return moment(date).format("D MMM");
  };

  if (props.data) {
    return <span className="data-container">{getDisplayValue(moment(new Date(props.data)))}</span>;
  } else return <span className="data-container"></span>;
}
