import React from "react";
import moment from "moment";
import CategoryDisplay from "./displays/categoryDisplay";
import OwnerDisplay, { MultiOwnerDisplay } from "./displays/ownerDisplay";
import TagDisplay from "./displays/tagDisplay";
import ProductDisplay from "./displays/productDisplay";
import "./display.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import AssociationDisplay from "../custom-table-data-display/association-display";
import { PipelineStageDisplay } from "../custom-table-data-display/stage-display";
import DueDateDisplay from "../custom-table-data-display/due-date-display";
import InputDisplay from "./displays/inputDisplay";
import MultiOptOutDisplay, { MultiOptOutReasonDisplay } from "./displays/multiOptOutDisplay";
import SelectDisplay from "./displays/selectDisplay";
import LeadPopover from "./displays/lead-popover";
import CadenceStatsDisplay from "./displays/cadence-stats.display";
import StatusDisplay from "./displays/status-display";
import CampaignChannelDisplay from "./displays/campaign_channel.display";
import CountryISOListDisplay from "./displays/country-iso-list.display";
import SecondaryTagDisplay from "./displays/secondary-tag-display";
import ActivityShowDisplay from "./displays/activity-show.display";
import AIWizardFieldDisplay from "./displays/ai-wizard-field.display";

export default function CustomDisplay(props) {
  // console.log(props, "props")
  switch (props.type) {
    case "INPUT":
      return <InputDisplay field={props.field} data={props.data} showDownloadIcon={props.showDownloadIcon} />;
    case "NUMBER":
      return <span className="data-container overflow-hidden text-overflow-ellipsis w-250px-mx">{props.data}</span>;
    case "TEXTAREA":
      return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
          <span className="data-container overflow-hidden text-overflow-ellipsis w-250px-mx">{props.data}</span>
        </OverlayTrigger>
      );
    case "SELECT":
      return <SelectDisplay field={props.field} data={props.data} />;
    case "OWNER_SELECT":
      return <OwnerDisplay value={props.data} />;
    case "TOGGLE_BUTTON":
      return <span className="data-container">{props.data ? "Yes" : "No"}</span>;
    case "CHECKBOX":
      return <span className="data-container">{Array.isArray(props.data) ? props.data.join(",") : props.data}</span>;
    case "TAG_MULTI_SELECT":
      return <TagDisplay sidePanel={props.sidePanel} page={props.page} pageSub={props.pageSub} value={props.data ? props.data.map((data) => (typeof data === "string" ? data : data["_id"])) : undefined} />;
    case "CATEGORY_SELECT":
      return <CategoryDisplay page={props.page} pageSub={props.pageSub} value={props.data ? props.data["_id"] : undefined} />;
    case "CURRENCY_SELECT":
      return <span className="data-container">{props.data}</span>;
    case "PERSON_SELECT":
      return <span className="data-container">{props.data ? (typeof props.data === "object" ? props.data.name : props.data) : undefined}</span>;
    case "ORG_SELECT":
      return <span className="data-container">{props.data ? (typeof props.data === "string" ? props.data : props.data.name) : undefined}</span>;
    case "COUNTRY_SELECT":
      return <span className="data-container">{props.data}</span>;
    case "TIMEZONE_SELECT":
      return <span className="data-container">{props.data}</span>;
    case "PRICING_INPUT":
      if (props.data)
        return (
          <span className={props.sidePanel ? "data-sidepanel-flex-container" : "data-flex-container"}>
            {props.data.map((data, index) => (
              <span key={index}>
                {data.currency} {data.unitPrice}
              </span>
            ))}
          </span>
        );
      else return <span className="data-container"></span>;
    case "MULTI_PHONE_INPUT":
      if (props.data) return <div className={props.sidePanel ? "data-sidepanel-flex-container" : "data-flex-container"}>{props.data.join(", ")}</div>;
      else return <span className="data-container"></span>;
    case "MULTI_EMAIL_INPUT":
      if (props.data)
        return (
          <div className={props.sidePanel ? "data-sidepanel-flex-container" : "data-flex-container "}>
            {props.data.map((email, index) => (
              <OverlayTrigger placement="bottom" key={index} overlay={<Tooltip>{email}</Tooltip>} trigger={["hover", "focus"]}>
                <span className="overflow-hidden text-overflow-ellipsis w-250px-mx" key={index}>
                  {email}
                </span>
              </OverlayTrigger>
            ))}
          </div>
        );
      else return <span className="data-container"></span>;
    case "DATE":
      if (props.data) return <span className="data-container">{moment(new Date(props.data)).format("YYYY-MM-DD")}</span>;
      else return <span className="data-container"></span>;
    case "DATE_TIME":
      if (props.data) {
        return <span className="data-container">{moment(new Date(props.data)).format("MMM DD, h:mm a")}</span>;
      } else return <span className="data-container"></span>;
    case "DATE_RANGE":
      if (Array.isArray(props.data) && props.data.length === 2)
        return (
          <span className="data-container">
            {moment(new Date(props.data[0])).format("YYYY-MM-DD")} - {moment(new Date(props.data[1])).format("YYYY-MM-DD")}
          </span>
        );
      else return <span className="data-container"></span>;
    case "DATE_TIME_RANGE":
      if (Array.isArray(props.data) && props.data.length === 2)
        return (
          <span className="data-container">
            {moment(new Date(props.data[0])).format("MMM DD, h:mm a")} - {moment(new Date(props.data[1])).format("MMM DD, h:mm a")}
          </span>
        );
      else return <span className="data-container"></span>;
    case "DOWNLOAD":
      if (props && props.showDownloadIcon) {
        return (
          <a href={props.data} download>
            <i className="bi bi-cloud-arrow-down-fill cursor-pointer font-size-22px text-00acff" />
          </a>
        );
      } else return null;
    case "ACTIVITY_SHOW":
      return <ActivityShowDisplay {...props} />;
    case "RADIO":
      return <span className="data-container">{props.data}</span>;
    case "PIPELINE_SELECT":
      return <span className="data-container">{typeof props.data === "object" ? props.data.name : props.data}</span>;
    case "PIPELINE_STAGE_SELECT":
      return <PipelineStageDisplay {...props} />;
    case "MULTI_OWNER_SELECT":
      return <MultiOwnerDisplay value={props.data} />;
    case "DEAL_PRODUCT_INPUT":
      return <ProductDisplay value={props.data} />;
    case "ACTIVITY_TYPE_SELECT":
      if (props.data)
        return (
          <span className="data-container">
            <i className={`${props.data.symbName} mr-10px`} />
            {props.data.name}
          </span>
        );
      else return <span className="data-container"></span>;
    case "ACTIVITY_LOST_REASON_SELECT":
      if (!props.data) return <span className="data-container"></span>;
      return <span className="data-container">{props.data.reason}</span>;
    case "DEAL_LOST_REASON_SELECT":
      return <span className="data-container">{props.data}</span>;

    case "LEAD_LOST_REASON_SELECT":
      return <span className="data-container">{props.data}</span>;

    case "PEOPLE_SELECT":
      if (!props.data) return <span className="data-container"></span>;
      return <span className="data-container">{props.data.name}</span>;
    case "COMPANY_SELECT":
      if (!props.data) return <span className="data-container"></span>;
      return <span className="data-container">{props.data.name}</span>;
    case "LEAD_SELECT":
      if (!props.data) return <span className="data-container"></span>;
      return <span className="data-container">{props.data.name}</span>;
    case "DEAL_SELECT":
      if (!props.data) return <span className="data-container"></span>;
      return <span className="data-container">{props.data.title}</span>;
    case "ASSOCIATION":
      return <AssociationDisplay {...props} />;
    case "MEETING_DATE_TIME":
      return <DueDateDisplay {...props} />;
    case "MULTI_OPT_OUT_REASON":
      return <MultiOptOutReasonDisplay value={props.data} sidePanel={props.sidePanel} />;
    case "MULTI_OPT_OUT":
      return <MultiOptOutDisplay value={props.data} sidePanel={props.sidePanel} />;
    case "LEAD_POPOVER":
      return <LeadPopover value={props.data} field={props.field} />;
    case "CADENCE_STATS":
      return <CadenceStatsDisplay value={props.data} field={props.field} />;
    case "DATE_DD_MM_YY":
      if (props.data) return <span className="data-container">{moment(props.data, "DD-MM-YYYY").format("DD-MM-YYYY")}</span>;
      else return <span className="data-container"></span>;
    case "STATUS_DISPLAY":
      return <StatusDisplay field={props.field} data={props.data} showDownloadIcon={props.showDownloadIcon} />;
    case "CHANNEL_LIGHT_TAG":
      return <CampaignChannelDisplay data={props.data} />;
    case "COUNTRY_ISO_DISPLAY":
      return <CountryISOListDisplay data={props.data} />;
    case "TAG_DISPLAY": // used for all tag data inside display only
      return <SecondaryTagDisplay sidePanel={props.sidePanel} page={props.page} pageSub={props.pageSub} data={props.data} />;
    case "AI_WIZARD":
      return <AIWizardFieldDisplay data={props.data} allData={props.allData} hideAIWFGenaration={props.hideAIWFGenaration} field={props.field} page={props.page} loadingData={props.loadingData} combinedId={props.combinedId} pageForm={props.pageForm} />;
    default:
      return <>undefined {props.type}</>;
  }
}
