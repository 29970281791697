import React, {
  useEffect,
  Suspense,
  // useState
  // lazy
} from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import { LayoutSplashScreen } from "../_metronic/layout";
import Products from "./products/index";
import Inbox from "./inbox";
import Admin from "./Admin/index";
import Insights from "./insights/routes";
import Import from "./import";
import ExportHistory from "./import/exportHistory";
import Automation from "./Automation/index";
import MailBox from "./mailbox/index";
import Deals from "./deals/index";

//parent routes
import Leads from "./leads/index";
import LeadForm from "./leadbot/index";
import ActivityRoutes from "./Activities/index";
import SettingsRoutes from "./Settings";
import Campaign from "./campaign";
import Companies from "./companies/routes";
import Customers from "./customers/routes";

import { useDispatch, useSelector } from "react-redux";
import { getAccountBillingV2Request } from "./Settings/Billing/store/actions/subscription_billing_v2_actions";
// import { getPendoDataFormat } from "./helper";

//callbacks
// import CalendarSyncCallback from "./Settings/profile/platformSetting/calendar-sync-callback";
// import EmailSyncCallback from "./Settings/profile/platformSetting/email-sync-callback";
import LearnPageRoutes from "./learn";
import ProfileSettings from "./Settings/Profile-Settings";
import moment from "moment-timezone";

export default function BasePage() {
  // const [pendoInitialised, setPendoInitialised] = useState(false);
  const dispatch = useDispatch();

  const profileState = useSelector((state) => state.ProfileSettings.getProfileDetails.data);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    if (profileState.userId && accountBillingStore.data && accountBillingStore.data?.accountDataCreatedAt) {
      const accountCreatedAt = moment(accountBillingStore.data.accountDataCreatedAt);
      const todayDate = moment("2023-04-12").startOf("day");
      window.usetifulTags = {
        userId: profileState.userId,
        segment: window.location.host,
        status: accountCreatedAt.valueOf() > todayDate.valueOf() ? "trial" : "old",
      };

      const script = document.createElement("script");
      const a = document.getElementsByTagName("head")[0];
      script.async = true;
      script.src = "https://www.usetiful.com/dist/usetiful.js";
      script.integrity = "sha384-19Cw7CPHxy4TttQce8RnD8yvUKOUp8ZcxEW/3wAds7rzhuY4dB7iILC9ZZLkIYVF";
      script.crossorigin = "anonymous";
      script.type = "module";
      script.setAttribute("id", "usetifulScript");
      script.dataset.token = "29932d26ddead4cae2a9b5ac5d0d843b";
      a.appendChild(script);
      return () => {
        a.removeChild(script);
      };
    }
  }, [profileState, accountBillingStore.data]);

  // useEffect(() => {
  //   if (profileState.userId) {
  //     const accountCreatedAt = moment(billingState.accountDataCreatedAt);
  //     const todayDate = moment("2023-04-12").startOf("day");
  //     window.usetifulTags = {
  //       userId: profileState.userId,
  //       segment: window.location.host,
  //       status: accountCreatedAt.valueOf() > todayDate.valueOf() ? "trial" : "old",
  //     };
  //   }
  // }, [profileState, billingState]);

  useEffect(() => {
    dispatch(getAccountBillingV2Request());
  }, [dispatch]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/activity" />
        <Route path="/prospects/leads" component={Leads} />
        <Route path="/activity" component={ActivityRoutes} />
        <Route path="/automation" component={Automation} />

        <Route path="/prospects/companies" component={Companies} />

        <Route path="/prospects/people" component={Customers} />
        <Route path="/products" component={Products} />
        <Route path="/insights" component={Insights} />
        <Route path="/import/:type" component={Import} />
        <Route path="/export/:type" component={ExportHistory} />
        <Route path="/learn" component={LearnPageRoutes} />

        <Route path="/settings" component={SettingsRoutes} />

        <Route path="/email/mail-box" component={MailBox} />
        <Route path="/lead-bot" component={LeadForm} />
        <Route path="/inbox" component={Inbox} />
        <Route path="/campaign" component={Campaign} />
        <Route path="/deal" component={Deals} />
        <Route path="/admin" component={Admin} />

        {/* callback urls */}
        <Route path="/profile-settings" component={ProfileSettings} />

        {/* <Route path="/error" component={ErrorsPage} /> */}

        <Redirect to="/error/error-v6" />
      </Switch>
    </Suspense>
  );
}
