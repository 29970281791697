import _ from "lodash";
import { getCadenceById } from "../types/cadence.types";
const initState = {
  data: {},
  isLoading: false,
  error: false,
};

export default function selectedCadenceReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCadenceById.GET_CADENCE_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: {},
      };
    case getCadenceById.GET_CADENCE_BY_ID_RESET:
      return {
        ...state,
        data: {},
      };
    case getCadenceById.GET_CADENCE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...action.payload.data,
          steps: _.sortBy(action.payload.data.steps, ["executionOrder"]),
        },
      };
    case getCadenceById.GET_CADENCE_BY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
