const microServices = {
  AUTOMATION_API_V1: "/automation-api/v1",
  AUTOMATION_V1: "/automation/v1",
  API_CRM: "/api/crm",
  USER_SERVICE: "/user-service/api",
};
export const endpoints = {
  //settings
  CONDITION: `${microServices.AUTOMATION_API_V1}/settings/conditions`,
  ACTIONS: `${microServices.AUTOMATION_API_V1}/settings/actions`,

  CREATE_EMAIL_NOTIFICATION: `${microServices.AUTOMATION_API_V1}/settings/email-notifications`,
  EDIT_EMAIL_NOTIFICATION: `${microServices.AUTOMATION_API_V1}/settings/email-notifications`,
  GET_EMAIL_NOTIFICATION: `${microServices.AUTOMATION_API_V1}/settings/email-notifications`,

  CREATE_FIELD_UPDATE: `${microServices.AUTOMATION_API_V1}/settings/field-updates`,
  EDIT_FIELD_UPDATE: `${microServices.AUTOMATION_API_V1}/settings/field-updates`,
  GET_FIELD_UPDATE: `${microServices.AUTOMATION_API_V1}/settings/field-updates`,

  CREATE_DEAL_ACTION: `${microServices.AUTOMATION_API_V1}/settings/create-deal-actions`,
  EDIT_DEAL_ACTION: `${microServices.AUTOMATION_API_V1}/settings/create-deal-actions`,
  GET_DEAL_ACTION: `${microServices.AUTOMATION_API_V1}/settings/create-deal-actions`,

  CREATE_LEAD_TO_JUNK: `${microServices.AUTOMATION_API_V1}/settings/lead-to-junk-actions`,
  EDIT_LEAD_TO_JUNK: `${microServices.AUTOMATION_API_V1}/settings/lead-to-junk-actions`,
  GET_LEAD_TO_JUNK: `${microServices.AUTOMATION_API_V1}/settings/lead-to-junk-actions`,

  CREATE_ACTIVITY: `${microServices.AUTOMATION_API_V1}/settings/create-activity-actions`,
  EDIT_ACTIVITY: `${microServices.AUTOMATION_API_V1}/settings/create-activity-actions`,
  GET_ACTIVITY: `${microServices.AUTOMATION_API_V1}/settings/create-activity-actions`,

  CREATE_DEAL_LOST_REASON: `${microServices.AUTOMATION_API_V1}/settings/deal-lost-reason-actions`,
  EDIT_DEAL_LOST_REASON: `${microServices.AUTOMATION_API_V1}/settings/deal-lost-reason-actions`,
  GET_DEAL_LOST_REASON: `${microServices.AUTOMATION_API_V1}/settings/deal-lost-reason-actions`,

  CREATE_WHATSAPP_TEMPLATE: `${microServices.AUTOMATION_API_V1}/settings/whatsapp-templates`,
  EDIT_WHATSAPP_TEMPLATE: `${microServices.AUTOMATION_API_V1}/settings/whatsapp-templates`,
  GET_WHATSAPP_TEMPLATE: `${microServices.AUTOMATION_API_V1}/settings/whatsapp-templates`,

  // workflow
  WORKFLOW_LIST: `${microServices.AUTOMATION_API_V1}/workflow/rules`,
  WORKFLOW_BY_ID: `${microServices.AUTOMATION_API_V1}/workflow/view`,
  CREATE_WORKFLOW: `${microServices.AUTOMATION_API_V1}/workflow/rules`,
  EDIT_WORKFLOW: `${microServices.AUTOMATION_API_V1}/workflow/rules`,
  DELETE_WORKFLOW: `${microServices.AUTOMATION_API_V1}/workflow/rules`,
  SHARE_WORKFLOW: `${microServices.AUTOMATION_API_V1}/workflow/share`,
  SHARE_WORKFLOW_USER_LIST: `${microServices.AUTOMATION_API_V1}/workflow/shared-users`,

  //workflow templates
  WORKFLOW_TEMPLATE_LIST: `${microServices.AUTOMATION_API_V1}/templates/rules`,
  WORKFLOW_TEMPLATE_BY_ID: `${microServices.AUTOMATION_API_V1}/templates/view`,
  CREATE_WORKFLOW_TEMPLATE: `${microServices.AUTOMATION_API_V1}/templates/rules`,
  EDIT_WORKFLOW_TEMPLATE: `${microServices.AUTOMATION_API_V1}/templates/rules`,
};
