import {
  HOSTED_EMAIL_AUTH_REQUEST,
  HOSTED_EMAIL_AUTH_SUCCESS,
  HOSTED_EMAIL_AUTH_ERROR,
  HOSTED_EMAIL_AUTH_CALLBACK_REQUEST,
  HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS,
  HOSTED_EMAIL_AUTH_CALLBACK_ERROR,
  GET_EMAIL_SYNC_DETAILS_REQUEST,
  GET_EMAIL_SYNC_DETAILS_SUCCESS,
  GET_EMAIL_SYNC_DETAILS_ERROR,
  UPDATE_EMAIL_SYNC_DETAILS_REQUEST,
  UPDATE_EMAIL_SYNC_DETAILS_SUCCESS,
  UPDATE_EMAIL_SYNC_DETAILS_ERROR,
  GET_EMAIL_FOLDER_LABELS_REQUEST,
  GET_EMAIL_FOLDER_LABELS_SUCCESS,
  GET_EMAIL_FOLDER_LABELS_ERROR,
  ADD_EMAIL_IN_IGNORE_LIST_REQUEST,
  ADD_EMAIL_IN_IGNORE_LIST_SUCCESS,
  ADD_EMAIL_IN_IGNORE_LIST_ERROR,
  REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST,
  REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS,
  REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR,
  GET_COMPOSER_TOKENS_REQUEST,
  GET_COMPOSER_TOKENS_SUCCESS,
  GET_COMPOSER_TOKENS_ERROR,
  DELETE_EMAIL_ACCOUNT_REQUEST,
  DELETE_EMAIL_ACCOUNT_SUCCESS,
  DELETE_EMAIL_ACCOUNT_ERROR,
  DISCONNECT_EMAIL_ACCOUNT_REQUEST,
  DISCONNECT_EMAIL_ACCOUNT_SUCCESS,
  DISCONNECT_EMAIL_ACCOUNT_ERROR,
  RECONNECT_EMAIL_ACCOUNT_REQUEST,
  RECONNECT_EMAIL_ACCOUNT_SUCCESS,
  RECONNECT_EMAIL_ACCOUNT_ERROR,
  SEND_EMAIL_ID_TO_BACKEND_REQUEST,
  SEND_EMAIL_ID_TO_BACKEND_RESPONSE,
  SEND_EMAIL_ID_TO_BACKEND_ERROR,
  HOSTED_CALENDAR_AUTH_REQUEST,
  HOSTED_CALENDAR_AUTH_SUCCESS,
  HOSTED_CALENDAR_AUTH_ERROR,
  HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST,
  HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS,
  HOSTED_CALENDAR_AUTH_CALLBACK_ERROR,
  GET_CALENDAR_SYNC_DETAILS_REQUEST,
  GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER,
  GET_CALENDAR_SYNC_DETAILS_SUCCESS,
  GET_CALENDAR_SYNC_DETAILS_ERROR,
  UPDATE_CALENDAR_SYNC_DETAILS_REQUEST,
  UPDATE_CALENDAR_SYNC_DETAILS_SUCCESS,
  UPDATE_CALENDAR_SYNC_DETAILS_ERROR,
  GET_AGENDA_TOKENS_REQUEST,
  GET_AGENDA_TOKENS_SUCCESS,
  GET_AGENDA_TOKENS_ERROR,
  DELETE_CALENDAR_ACCOUNT_REQUEST,
  DELETE_CALENDAR_ACCOUNT_SUCCESS,
  DELETE_CALENDAR_ACCOUNT_ERROR,
  DISCONNECT_CALENDAR_ACCOUNT_REQUEST,
  DISCONNECT_CALENDAR_ACCOUNT_SUCCESS,
  DISCONNECT_CALENDAR_ACCOUNT_ERROR,
  RECONNECT_CALENDAR_ACCOUNT_REQUEST,
  RECONNECT_CALENDAR_ACCOUNT_SUCCESS,
  RECONNECT_CALENDAR_ACCOUNT_ERROR,
  GET_LIST_OF_CALENDARS_REQUEST,
  GET_LIST_OF_CALENDARS_SUCCESS,
  GET_LIST_OF_CALENDARS_ERROR,
  SEND_NYLAS_EMAIL_REQUEST,
  SEND_NYLAS_EMAIL_SUCCESS,
  SEND_NYLAS_EMAIL_ERROR,
  UPLOAD_ATTACHMENT_IN_NYLAS_REQUEST,
  UPLOAD_ATTACHMENT_IN_NYLAS_SUCCESS,
  UPLOAD_ATTACHMENT_IN_NYLAS_ERROR,
} from "../types/sync.types";

//new calender and email sync actions
export function hostedEmailAuthRequest(req, cb) {
  return {
    type: HOSTED_EMAIL_AUTH_REQUEST,
    payload: req,
    cb,
  };
}
export function hostedEmailAuthSuccess(req) {
  return {
    type: HOSTED_EMAIL_AUTH_SUCCESS,
    payload: req,
  };
}
export function hostedEmailAuthError(req) {
  return {
    type: HOSTED_EMAIL_AUTH_ERROR,
    payload: req,
  };
}

export function hostedEmailAuthCallbackRequest(req, cb) {
  return {
    type: HOSTED_EMAIL_AUTH_CALLBACK_REQUEST,
    payload: req,
    cb,
  };
}
export function hostedEmailAuthCallbackSuccess(req) {
  return {
    type: HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS,
    payload: req,
  };
}
export function hostedEmailAuthCallbackError(req) {
  return {
    type: HOSTED_EMAIL_AUTH_CALLBACK_ERROR,
    payload: req,
  };
}

export function getEmailSyncDetailsRequest(req) {
  return {
    type: GET_EMAIL_SYNC_DETAILS_REQUEST,
    payload: req,
  };
}
export function getEmailSyncDetailsSuccess(req) {
  return {
    type: GET_EMAIL_SYNC_DETAILS_SUCCESS,
    payload: req,
  };
}
export function getEmailSyncDetailsError(req) {
  return {
    type: GET_EMAIL_SYNC_DETAILS_ERROR,
    payload: req,
  };
}

export function updateEmailSyncDetailsRequest(req) {
  return {
    type: UPDATE_EMAIL_SYNC_DETAILS_REQUEST,
    payload: req,
  };
}
export function updateEmailSyncDetailsSuccess(req) {
  return {
    type: UPDATE_EMAIL_SYNC_DETAILS_SUCCESS,
    payload: req,
  };
}
export function updateEmailSyncDetailsError(req) {
  return {
    type: UPDATE_EMAIL_SYNC_DETAILS_ERROR,
    payload: req,
  };
}

export function getEmailFolderLabelRequest(req) {
  return {
    type: GET_EMAIL_FOLDER_LABELS_REQUEST,
    payload: req,
  };
}
export function getEmailFolderLabelSuccess(req) {
  return {
    type: GET_EMAIL_FOLDER_LABELS_SUCCESS,
    payload: req,
  };
}
export function getEmailFolderLabelError(req) {
  return {
    type: GET_EMAIL_FOLDER_LABELS_ERROR,
    payload: req,
  };
}

export function addEmailInIgnoreListRequest(req) {
  return {
    type: ADD_EMAIL_IN_IGNORE_LIST_REQUEST,
    payload: req,
  };
}
export function addEmailInIgnoreListSuccess(req) {
  return {
    type: ADD_EMAIL_IN_IGNORE_LIST_SUCCESS,
    payload: req,
  };
}
export function addEmailInIgnoreListError(req) {
  return {
    type: ADD_EMAIL_IN_IGNORE_LIST_ERROR,
    payload: req,
  };
}

export function removeEmailFromIgnoreListRequest(req) {
  return {
    type: REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST,
    payload: req,
  };
}
export function removeEmailFromIgnoreListSuccess(req) {
  return {
    type: REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS,
    payload: req,
  };
}
export function removeEmailFromIgnoreListError(req) {
  return {
    type: REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR,
    payload: req,
  };
}

export function getComposerTokenRequest(req) {
  return {
    type: GET_COMPOSER_TOKENS_REQUEST,
    payload: req,
  };
}
export function getComposerTokenSuccess(req) {
  return {
    type: GET_COMPOSER_TOKENS_SUCCESS,
    payload: req,
  };
}
export function getComposerTokenError(req) {
  return {
    type: GET_COMPOSER_TOKENS_ERROR,
    payload: req,
  };
}

export function deleteEmailSyncAccountRequest(req) {
  return {
    type: DELETE_EMAIL_ACCOUNT_REQUEST,
    payload: req,
  };
}
export function deleteEmailSyncAccountSuccess(req) {
  return {
    type: DELETE_EMAIL_ACCOUNT_SUCCESS,
    payload: req,
  };
}
export function deleteEmailSyncAccountError(req) {
  return {
    type: DELETE_EMAIL_ACCOUNT_ERROR,
    payload: req,
  };
}

export function reconnectEmailSyncAccountRequest(req) {
  return {
    type: RECONNECT_EMAIL_ACCOUNT_REQUEST,
    payload: req,
  };
}
export function reconnectEmailSyncAccountSuccess(req) {
  return {
    type: RECONNECT_EMAIL_ACCOUNT_SUCCESS,
    payload: req,
  };
}
export function reconnectEmailSyncAccountError(req) {
  return {
    type: RECONNECT_EMAIL_ACCOUNT_ERROR,
    payload: req,
  };
}

export function disconnectEmailSyncAccountRequest(req) {
  return {
    type: DISCONNECT_EMAIL_ACCOUNT_REQUEST,
    payload: req,
  };
}
export function disconnectEmailSyncAccountSuccess(req) {
  return {
    type: DISCONNECT_EMAIL_ACCOUNT_SUCCESS,
    payload: req,
  };
}
export function disconnectEmailSyncAccountError(req) {
  return {
    type: DISCONNECT_EMAIL_ACCOUNT_ERROR,
    payload: req,
  };
}

export function sendEmailIDtoBackendRequest(req) {
  return {
    type: SEND_EMAIL_ID_TO_BACKEND_REQUEST,
    payload: req,
  };
}
export function sendEmailIDtoBackendSuccess(req) {
  return {
    type: SEND_EMAIL_ID_TO_BACKEND_RESPONSE,
    payload: req,
  };
}
export function sendEmailIDtoBackendError(req) {
  return {
    type: SEND_EMAIL_ID_TO_BACKEND_ERROR,
    payload: req,
  };
}

//new calender and email sync actions
export function hostedCalendarAuthRequest(req, cb) {
  return {
    type: HOSTED_CALENDAR_AUTH_REQUEST,
    payload: req,
    cb,
  };
}
export function hostedCalendarAuthSuccess(req) {
  return {
    type: HOSTED_CALENDAR_AUTH_SUCCESS,
    payload: req,
  };
}
export function hostedCalendarAuthError(req) {
  return {
    type: HOSTED_CALENDAR_AUTH_ERROR,
    payload: req,
  };
}

export function hostedCalendarAuthCallbackRequest(req, cb) {
  return {
    type: HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST,
    payload: req,
    cb,
  };
}
export function hostedCalendarAuthCallbackSuccess(req) {
  return {
    type: HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS,
    payload: req,
  };
}
export function hostedCalendarAuthCallbackError(req) {
  return {
    type: HOSTED_CALENDAR_AUTH_CALLBACK_ERROR,
    payload: req,
  };
}

export function getCalendarSyncDetailsRequest(req) {
  return {
    type: GET_CALENDAR_SYNC_DETAILS_REQUEST,
    payload: req,
  };
}
export function getCalendarSyncDetailsRequestAfter(req) {
  return {
    type: GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER,
    payload: req,
  };
}
export function getCalendarSyncDetailsSuccess(req) {
  return {
    type: GET_CALENDAR_SYNC_DETAILS_SUCCESS,
    payload: req,
  };
}
export function getCalendarSyncDetailsError(req) {
  return {
    type: GET_CALENDAR_SYNC_DETAILS_ERROR,
    payload: req,
  };
}

export function updateCalendarSyncDetailsRequest(req) {
  return {
    type: UPDATE_CALENDAR_SYNC_DETAILS_REQUEST,
    payload: req,
  };
}
export function updateCalendarSyncDetailsSuccess(req) {
  return {
    type: UPDATE_CALENDAR_SYNC_DETAILS_SUCCESS,
    payload: req,
  };
}
export function updateCalendarSyncDetailsError(req) {
  return {
    type: UPDATE_CALENDAR_SYNC_DETAILS_ERROR,
    payload: req,
  };
}

export function getAgendaTokenRequest(req) {
  return {
    type: GET_AGENDA_TOKENS_REQUEST,
    payload: req,
  };
}
export function getAgendaTokenSuccess(req) {
  return {
    type: GET_AGENDA_TOKENS_SUCCESS,
    payload: req,
  };
}
export function getAgendaTokenError(req) {
  return {
    type: GET_AGENDA_TOKENS_ERROR,
    payload: req,
  };
}

export function deleteCalendarSyncAccountRequest(req) {
  return {
    type: DELETE_CALENDAR_ACCOUNT_REQUEST,
    payload: req,
  };
}
export function deleteCalendarSyncAccountSuccess(req) {
  return {
    type: DELETE_CALENDAR_ACCOUNT_SUCCESS,
    payload: req,
  };
}
export function deleteCalendarSyncAccountError(req) {
  return {
    type: DELETE_CALENDAR_ACCOUNT_ERROR,
    payload: req,
  };
}

export function reconnectCalendarSyncAccountRequest(req) {
  return {
    type: RECONNECT_CALENDAR_ACCOUNT_REQUEST,
    payload: req,
  };
}
export function reconnectCalendarSyncAccountSuccess(req) {
  return {
    type: RECONNECT_CALENDAR_ACCOUNT_SUCCESS,
    payload: req,
  };
}
export function reconnectCalendarSyncAccountError(req) {
  return {
    type: RECONNECT_CALENDAR_ACCOUNT_ERROR,
    payload: req,
  };
}

export function disconnectCalendarSyncAccountRequest(req) {
  return {
    type: DISCONNECT_CALENDAR_ACCOUNT_REQUEST,
    payload: req,
  };
}
export function disconnectCalendarSyncAccountSuccess(req) {
  return {
    type: DISCONNECT_CALENDAR_ACCOUNT_SUCCESS,
    payload: req,
  };
}
export function disconnectCalendarSyncAccountError(req) {
  return {
    type: DISCONNECT_CALENDAR_ACCOUNT_ERROR,
    payload: req,
  };
}

export function getListOfCalendersRequest(req) {
  return {
    type: GET_LIST_OF_CALENDARS_REQUEST,
    payload: req,
  };
}
export function getListOfCalendersSuccess(req) {
  return {
    type: GET_LIST_OF_CALENDARS_SUCCESS,
    payload: req,
  };
}
export function getListOfCalendersError(req) {
  return {
    type: GET_LIST_OF_CALENDARS_ERROR,
    payload: req,
  };
}

export function sendNylasEmailRequest(req, cb) {
  return {
    type: SEND_NYLAS_EMAIL_REQUEST,
    payload: req,
    cb,
  };
}
export function sendNylasEmailSuccess(res) {
  return {
    type: SEND_NYLAS_EMAIL_SUCCESS,
    payload: res,
  };
}
export function sendNylasEmailError(err) {
  return {
    type: SEND_NYLAS_EMAIL_ERROR,
    payload: err,
  };
}

export function uploadAttachmentInNylasRequest(req, cb) {
  return {
    type: UPLOAD_ATTACHMENT_IN_NYLAS_REQUEST,
    payload: req,
    cb,
  };
}
export function uploadAttachmentInNylasSuccess(res) {
  return {
    type: UPLOAD_ATTACHMENT_IN_NYLAS_SUCCESS,
    payload: res,
  };
}
export function uploadAttachmentInNylasError(err) {
  return {
    type: UPLOAD_ATTACHMENT_IN_NYLAS_ERROR,
    payload: err,
  };
}
