import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import ChannelSelector from "./channel-selection";
import { useDispatch } from "react-redux";
import { getEmailProfileDetailsRequest, getListOfEmailDomainSettingsRequest } from "../store/actions/campaign_email_settings_actions";
import { getListOfSMSSenderIdsRequest, getSMSCampaignPricingRequest } from "../store/actions/campaign_sms_settings_actions";

export function CreateCampaignModal(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getListOfEmailDomainSettingsRequest());
    dispatch(getEmailProfileDetailsRequest());
    dispatch(getListOfSMSSenderIdsRequest());
    dispatch(getSMSCampaignPricingRequest());
  }, [dispatch]);
  return (
    <>
      <Modal show={props.show} onHide={() => props.setShow(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Select Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChannelSelector setShow={props.setShow} type={"create-campaign"} basePath="/campaign/builder" isSettings={false} prerequisiteRequired={true} isPopupScreen={true} />
        </Modal.Body>
      </Modal>
    </>
  );
}
