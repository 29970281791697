import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import WalletBilling from "./containers/wallet-billing";
import SubscriptionBillingV2 from "./containers/subscription-billing-v2/containers/subscription";

export default function Campaign(props) {
  const { match } = props;

  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/subscription`} component={SubscriptionBillingV2} />
        <Route exact path={`${match.url}/wallet`} component={WalletBilling} />
        <Redirect to={`${match.url}/subscription`} />
      </Switch>
    </>
  );
}
