import { getListOfActiveActivityTypes } from "../types/activity.types";

const initState = {
  active: {
    list: [],
    loading: false,
  },
  all: {
    loading: false,
    list: [],
  },
};

export default function activityTypesReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_REQUEST:
      return {
        ...state,
        active: {
          ...state.active,
          loading: true,
        },
      };
    case getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          loading: false,
          list: action.payload.data,
        },
      };
    case getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_ERROR:
      return {
        ...state,
        active: {
          ...state.active,
          loading: false,
        },
      };
    default:
      return state;
  }
}
