import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as subscriptionV2Actions from "../../../../store/actions/subscription_billing_v2_actions";
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

export default function NextRenewalSection({ data }) {
  const [loading, setLoading] = useState(false);
  const [headTextmessage, setHeadTextmessage] = useState("");
  const [bodyTextmessage, setBodyTextmessage] = useState("");

  const dispatch = useDispatch()

  useEffect(() => {
    if (data.isFreeTrialPeriod) {
      const endDate = moment(data.freeTrialStartedAt).add(data.durationOfFreeTrial, "days").format("ll");
      setHeadTextmessage(`Your free trial ends on ${endDate}`);
      setBodyTextmessage("You can upgrade your plan anytime during the free trial period");
    }
    else if (data.selectedPlan === "free") {
      setHeadTextmessage("Your free plan is active");
      setBodyTextmessage("");
    }
    else if (!_.isEmpty(data.scheduledChange)) {
      setHeadTextmessage(`Scheduled ${data.scheduledChange.action}`)
      setBodyTextmessage(`This subscription is scheduled to be ${data.scheduledChange.action}d on ${moment(data.scheduledChange.effective_at).format("ll")}`)
    }
    else if (data.subscriptionStatus === "active") {
      if (data.subscriptionRenewedAt && moment(data.subscriptionRenewedAt).valueOf() > moment().valueOf()) {
        setHeadTextmessage(`Next Renewal on ${moment(data.subscriptionRenewedAt).format("ll")}`)
        setBodyTextmessage("Upon expiration of your plan you shall receive a notification")
      }
      else if (data.currentPeriodEnd && moment(data.currentPeriodEnd).valueOf() > moment().valueOf()) {
        setHeadTextmessage(`Next Renewal on ${moment(data.currentPeriodEnd).format("ll")}`)
        setBodyTextmessage("Upon expiration of your plan you shall receive a notification")
      }
      else {
        setHeadTextmessage(`Active until cancelled`)
        setBodyTextmessage("Upon expiration of your plan you shall receive a notification")
      }
    }
    else {
      setHeadTextmessage(`Active until cancelled`)
      setBodyTextmessage("Upon expiration of your plan you shall receive a notification")
    }

  }, [data])

  const handleResumeSubscription = () => {
    setLoading(true);
    dispatch(subscriptionV2Actions.resumeSubscriptionV2Request({}, async () => {
      delay(2000);
      setLoading(false);
    }))
  };
  const handleCancelSubscription = () => {
    setLoading(true);
    dispatch(subscriptionV2Actions.cancelSubscriptionV2Request({}, async () => {
      delay(2000);
      setLoading(false);
    }))
  };
  const dontCancelSubscription = () => {
    setLoading(true);
    dispatch(subscriptionV2Actions.dontCancelSubscriptionV2Request({}, async () => {
      delay(2000);
      setLoading(false);
    }))
  }

  return (
    <>
      <div>
        <h3 className="mb-2">{headTextmessage} </h3>
        <p className="fs-6 text-gray-600 fw-bold mb-6">{bodyTextmessage}</p>
      </div>
      <div className="d-flex gap-4 align-items-center">
        {data.scheduledChange && data.scheduledChange.action === "cancel" && <div className="btn btn-primary btn-active-light-primary me-2" onClick={(e) => dontCancelSubscription()}>
          {loading && <Spinner animation="border" className="mr-6px w-16px h-16px" />}
          Renew Subscription
        </div>}
        {data.scheduledChange && data.scheduledChange.action === "pause" && <div className="btn btn-primary btn-active-light-primary me-2" onClick={(e) => handleResumeSubscription()}>
          {loading && <Spinner animation="border" className="mr-6px w-16px h-16px" />}
          Resume Subscription
        </div>}
        {(_.isEmpty(data.scheduledChange) && data.subscriptionStatus === "active" && data.selectedPlan !== "free") && <div className="btn btn-danger btn-active-light-danger me-2" onClick={(e) => handleCancelSubscription()}>
          {loading && <Spinner animation="border" className="mr-6px w-16px h-16px" />}
          Cancel Subscription
        </div>}
      </div>
    </>
  );
}
