import React from "react";
import moment from "moment";

export default function DateSelect({ apiKeyName, setValue, value }) {
  return (
    <div className="w-100">
      <input className="form-control" value={value ? moment(value).format("YYYY-MM-DD") : null} type="date" name="fromDate" onChange={(e) => setValue(new Date(e.target.value).getTime(), apiKeyName)} />
    </div>
  );
}

export function DateTimeSelect({ apiKeyName, setValue, value, isFilter }) {
  if (isFilter) {
    return <DateSelect apiKeyName={apiKeyName} setValue={setValue} value={value} />;
  }
  return (
    <div className="w-100">
      <input className="form-control" value={value ? moment(value).format("YYYY-MM-DDTHH:mm") : null} type="datetime-local" name="fromDate" onChange={(e) => setValue(new Date(e.target.value).getTime(), apiKeyName)} />
    </div>
  );
}
