export const getAccountBillingV2 = {
    REQUEST: "GET_ACCOUNT_BILLING_V2_REQUEST",
    SUCCESS: "GET_ACCOUNT_BILLING_V2_SUCCESS",
    ERROR: "GET_ACCOUNT_BILLING_V2_ERROR",
    AFTER_REQUEST: "GET_ACCOUNT_BILLING_V2_AFTER_REQUEST",
}

export const getBillingPricesV2 = {
    REQUEST: "GET_BILLING_PRICES_V2_REQUEST",
    SUCCESS: "GET_BILLING_PRICES_V2_SUCCESS",
    ERROR: "GET_BILLING_PRICES_V2_ERROR",
}

export const getBillingInvoiceListV2 = {
    REQUEST: "GET_BILLING_INVOICE_LIST_V2_REQUEST",
    SUCCESS: "GET_BILLING_INVOICE_LIST_V2_SUCCESS",
    ERROR: "GET_BILLING_INVOICE_LIST_V2_ERROR",
}

export const assignFreePlanV2 = {
    REQUEST: "ASSIGN_FREE_PLAN_V2_REQUEST",
    SUCCESS: "ASSIGN_FREE_PLAN_V2_SUCCESS",
    ERROR: "ASSIGN_FREE_PLAN_V2_ERROR",
}

export const pauseSubscriptionV2 = {
    REQUEST: "PAUSE_SUBSCRIPTION_V2_REQUEST",
    SUCCESS: "PAUSE_SUBSCRIPTION_V2_SUCCESS",
    ERROR: "PAUSE_SUBSCRIPTION_V2_ERROR",
}

export const resumeSubscriptionV2 = {
    REQUEST: "RESUME_SUBSCRIPTION_V2_REQUEST",
    SUCCESS: "RESUME_SUBSCRIPTION_V2_SUCCESS",
    ERROR: "RESUME_SUBSCRIPTION_V2_ERROR",
}

export const cancelSubscriptionV2 = {
    REQUEST: "CANCEL_SUBSCRIPTION_V2_REQUEST",
    SUCCESS: "CANCEL_SUBSCRIPTION_V2_SUCCESS",
    ERROR: "CANCEL_SUBSCRIPTION_V2_ERROR",
}

export const updateSubscriptionV2 = {
    REQUEST: "UPDATE_SUBSCRIPTION_V2_REQUEST",
    SUCCESS: "UPDATE_SUBSCRIPTION_V2_SUCCESS",
    ERROR: "UPDATE_SUBSCRIPTION_V2_ERROR",
}

export const dontCancelSubscriptionV2 = {
    REQUEST: "DONT_CANCEL_SUBSCRIPTION_V2_REQUEST",
    SUCCESS: "DONT_CANCEL_SUBSCRIPTION_V2_SUCCESS",
    ERROR: "DONT_CANCEL_SUBSCRIPTION_V2_ERROR",
}