import {
  TESTING_REQUEST,
  TESTING_SUCCESS,
  TESTING_ERROR,
  FACTOR2_REQUEST,
  FACTOR2_SUCCESS,
  FACTOR2_ERROR,
  CHANGEEMAIL_REQUEST,
  CHANGEEMAIL_SUCCESS,
  CHANGEEMAIL_ERROR,
  CALENDAR_GOOGLE_REQUEST,
  CALENDAR_GOOGLE_SUCCESS,
  CALENDAR_GOOGLE_ERROR,
  EMAIL_GOOGLE_REQUEST,
  EMAIL_GOOGLE_SUCCESS,
  EMAIL_GOOGLE_ERROR,
  CALENDAR_START_SYNCING_REQUEST,
  CALENDAR_START_SYNCING_SUCCESS,
  CALENDAR_START_SYNCING_ERROR,
  CALENDAR_SYNC_UPDATE_REQUEST,
  CALENDAR_SYNC_UPDATE_SUCCESS,
  CALENDAR_SYNC_UPDATE_ERROR,
  GETPROFILE_REQUEST,
  GETPROFILE_SUCCESS,
  GETPROFILE_ERROR,
  UPDATEPROFILE_REQUEST,
  UPDATEPROFILE_SUCCESS,
  UPDATEPROFILE_ERROR,
  LOGINSESSION_REQUEST,
  LOGINSESSION_SUCCESS,
  LOGINSESSION_ERROR,
  LOGINSESSIONPROFILE_REQUEST,
  LOGINSESSIONPROFILE_SUCCESS,
  LOGINSESSIONPROFILE_ERROR,
  GETNOTIFICATION_REQUEST,
  GETNOTIFICATION_SUCCESS,
  GETNOTIFICATION_ERROR,
  POSTNOTIFICATION_REQUEST,
  POSTNOTIFICATION_SUCCESS,
  POSTNOTIFICATION_ERROR,
  ADDWEBHOOK_REQUEST,
  ADDWEBHOOK_SUCCESS,
  ADDWEBHOOK_ERROR,
  GETWEBHOOK_REQUEST,
  GETWEBHOOK_SUCCESS,
  GETWEBHOOK_ERROR,
  PATCHWEBHOOK_REQUEST,
  PATCHWEBHOOK_SUCCESS,
  PATCHWEBHOOK_ERROR,
  DELETEWEBHOOK_REQUEST,
  DELETEWEBHOOK_SUCCESS,
  DELETEWEBHOOK_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  POSTCURRENCY_REQUEST,
  POSTCURRENCY_SUCCESS,
  POSTCURRENCY_ERROR,
  GETCURRENCY_REQUEST,
  GETCURRENCY_SUCCESS,
  GETCURRENCY_ERROR,
  PATCHCURRENCY_REQUEST,
  PATCHCURRENCY_SUCCESS,
  PATCHCURRENCY_ERROR,
  POSTLOSTREASON_REQUEST,
  POSTLOSTREASON_SUCCESS,
  POSTLOSTREASON_ERROR,
  GETLOSTREASON_REQUEST,
  GETLOSTREASON_SUCCESS,
  GETLOSTREASON_ERROR,
  PATCHLOSTREASON_REQUEST,
  PATCHLOSTREASON_SUCCESS,
  PATCHLOSTREASON_ERROR,
  DELETELOSTREASON_REQUEST,
  DELETELOSTREASON_SUCCESS,
  DELETELOSTREASON_ERROR,
  GETMANAGEUSER_REQUEST,
  GETMANAGEUSER_SUCCESS,
  GETMANAGEUSER_ERROR,
  PATCHMANAGEUSER_REQUEST,
  PATCHMANAGEUSER_SUCCESS,
  PATCHMANAGEUSER_ERROR,
  DELETEMANAGEUSER_REQUEST,
  DELETEMANAGEUSER_SUCCESS,
  DELETEMANAGEUSER_ERROR,
  POSTMANAGEUSER_REQUEST,
  POSTMANAGEUSER_SUCCESS,
  POSTMANAGEUSER_ERROR,
  PATCHMANAGEROLE_REQUEST,
  PATCHMANAGEROLE_SUCCESS,
  PATCHMANAGEROLE_ERROR,
  GETTEAMSDETAILS_REQUEST,
  GETTEAMSDETAILS_SUCCESS,
  GETTEAMSDETAILS_ERROR,
  POSTTEAM_REQUEST,
  POSTTEAM_SUCCESS,
  POSTTEAM_ERROR,
  GETTEAM_REQUEST,
  GETTEAM_SUCCESS,
  GETTEAM_ERROR,
  DELETETEAM_REQUEST,
  DELETETEAM_SUCCESS,
  DELETETEAM_ERROR,
  PATCHTEAM_REQUEST,
  PATCHTEAM_SUCCESS,
  PATCHTEAM_ERROR,
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_ERROR,
  EMAIL_START_SYNCING_REQUEST,
  EMAIL_START_SYNCING_SUCCESS,
  EMAIL_START_SYNCING_ERROR,
  GOOGLE_CALENDAR_CALLBACK_REQUEST,
  GOOGLE_CALENDAR_CALLBACK_SUCCESS,
  GOOGLE_CALENDAR_CALLBACK_ERROR,
  EMAIL_SYNC_UPDATE_REQUEST,
  EMAIL_SYNC_UPDATE_SUCCESS,
  EMAIL_SYNC_UPDATE_ERROR,
  GOOGLE_EMAIL_CALLBACK_REQUEST,
  GOOGLE_EMAIL_CALLBACK_SUCCESS,
  GOOGLE_EMAIL_CALLBACK_ERROR,
  GET_CALENDARSYNC_ACCOUNTS_REQUEST,
  GET_CALENDARSYNC_ACCOUNTS_SUCCESS,
  GET_CALENDARSYNC_ACCOUNTS_ERROR,
  GET_EMAILSYNC_ACCOUNTS_REQUEST,
  GET_EMAILSYNC_ACCOUNTS_SUCCESS,
  GET_EMAILSYNC_ACCOUNTS_ERROR,
  GET_CALENDAR_USERINFO_REQUEST,
  GET_CALENDAR_USERINFO_SUCCESS,
  GET_CALENDAR_USERINFO_ERROR,
  GET_EMAILSYNC_SYNCACCOUNT_REQUEST,
  GET_EMAILSYNC_SYNCACCOUNT_SUCCESS,
  GET_EMAILSYNC_SYNCACCOUNT_ERROR,
  POSTACTIVITY_REQUEST,
  POSTACTIVITY_SUCCESS,
  POSTACTIVITY_ERROR,
  GETACTIVITY_REQUEST,
  GETACTIVITY_SUCCESS,
  GETACTIVITY_ERROR,
  PATCHACTIVITY_REQUEST,
  PATCHACTIVITY_SUCCESS,
  PATCHACTIVITY_ERROR,
  DELETEACTIVITY_REQUEST,
  DELETEACTIVITY_SUCCESS,
  DELETEACTIVITY_ERROR,
  NYLAS_SYNC_REQUEST,
  NYLAS_SYNC_SUCCESS,
  NYLAS_SYNC_ERROR,
  NYLAS_SYNC_CALLBACK_REQUEST,
  NYLAS_SYNC_CALLBACK_SUCCESS,
  NYLAS_SYNC_CALLBACK_ERROR,
  AVATARPIC_REQUEST,
  AVATARPIC_SUCCESS,
  AVATARPIC_ERROR,
  GETPROFILEDETAILS_REQUEST,
  GETPROFILEDETAILS_SUCCESS,
  GETPROFILEDETAILS_ERROR,
  PATCHCUSTOMFIELD_REQUEST,
  PATCHCUSTOMFIELD_SUCCESS,
  PATCHCUSTOMFIELD_ERROR,
  POSTCUSTOMFIELD_REQUEST,
  POSTCUSTOMFIELD_SUCCESS,
  POSTCUSTOMFIELD_ERROR,
  GETCUSTOMFIELD_REQUEST,
  GETCUSTOMFIELD_SUCCESS,
  GETCUSTOMFIELD_ERROR,
  DELETECUSTOMFIELD_REQUEST,
  DELETECUSTOMFIELD_SUCCESS,
  DELETECUSTOMFIELD_ERROR,
  COMPANYLIST_REQUEST,
  COMPANYLIST_SUCCESS,
  COMPANYLIST_ERROR,
  TESTWEBHOOK_REQUEST,
  TESTWEBHOOK_SUCCESS,
  TESTWEBHOOK_ERROR,
  DISABLEWEBHOOK_REQUEST,
  DISABLEWEBHOOK_SUCCESS,
  DISABLEWEBHOOK_ERROR,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_ERROR,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
} from "../types/settings.types";

const initialState = {
  disableWebhook: {},
  testWebhook: {},
  companyList: {},
  getCustomField: {},
  postCustomField: {},
  patchCustomField: {},
  testingResponse: {},
  loading: "",
  googleCalendar: {},
  getProfileDetails: {
    success: true,
    data: {},
  },
  googleEmail: {},
  clendarStartSync: {},
  profileDetails: {},
  loginSessionDetails: {},
  loginSessionProfileDetails: {},
  getNotification: {},
  postNotification: {},
  addWebhook: {},
  getWebhook: {},
  patchWebhook: {},
  postCurrency: {},
  getCurrency: {},
  patchCurrency: {},
  postLostReason: {},
  getLostReason: {
    data: [],
    message: "",
    success: false,
  },
  patchLostReason: {},
  getManageUser: {},
  patchManageUser: {},
  postManageUser: {},
  getTeamsDetails: {},
  postTeam: {},
  getTeam: {},
  patchTeam: {},
  resetPass: {},
  emailStartSync: {},
  googleCalendarCallbackResponse: {},
  emailSync: {},
  googleEmailCallbackResponse: {},
  calendarAccounts: {},
  calendarUserInfo: {},
  emailAccounts: {},
  syncedEmailAccountDetails: {},
  postActivity: {},
  getActivity: {},
  patchActivity: {},
  patchManageRole: {},
  nylasSyncResponse: {},
  nylasCallbackResponse: {},
  avatarPic: {},
  // you can define required variables here
};

export function profileReducer(state = initialState, action = {}) {
  switch (action.type) {
    //resend invitation
    case RESEND_INVITATION_REQUEST:
      return {
        ...state,
      };
    case RESEND_INVITATION_SUCCESS:
      return {
        ...state,
      };
    case RESEND_INVITATION_ERROR:
      return {
        ...state,
      };
    //update team
    case UPDATE_TEAM_REQUEST:
      return {
        ...state,
      };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_TEAM_ERROR:
      return {
        ...state,
      };
    case TESTING_REQUEST:
      return {
        ...state,
        testingResponse: {},
        loading: true,
      };
    case TESTING_SUCCESS:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case TESTING_ERROR:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case FACTOR2_REQUEST:
      return {
        ...state,
        testingResponse: action.payload,
        loading: true,
      };
    case FACTOR2_SUCCESS:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case FACTOR2_ERROR:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case CHANGEEMAIL_REQUEST:
      return {
        ...state,
        testingResponse: action.payload,
        loading: true,
      };
    case CHANGEEMAIL_SUCCESS:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case CHANGEEMAIL_ERROR:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case CALENDAR_GOOGLE_REQUEST:
      return {
        ...state,
        googleCalendar: action.payload,
        loading: true,
      };
    case CALENDAR_GOOGLE_SUCCESS:
      return {
        ...state,
        googleCalendar: action.payload,
        loading: false,
      };
    case CALENDAR_GOOGLE_ERROR:
      return {
        ...state,
        googleCalendar: action.payload,
        loading: true,
      };
    case EMAIL_GOOGLE_REQUEST:
      return {
        ...state,
        googleEmail: action.payload,
        loading: true,
      };
    case EMAIL_GOOGLE_SUCCESS:
      return {
        ...state,
        googleEmail: action.payload,
        loading: false,
      };
    case EMAIL_GOOGLE_ERROR:
      return {
        ...state,
        googleEmail: action.payload,
        loading: true,
      };
    case CALENDAR_START_SYNCING_REQUEST:
      return {
        ...state,
        clendarStartSync: action.payload,
        loading: true,
      };
    case CALENDAR_START_SYNCING_SUCCESS:
      return {
        ...state,
        clendarStartSync: action.payload,
        loading: false,
      };
    case CALENDAR_START_SYNCING_ERROR:
      return {
        ...state,
        clendarStartSync: action.payload,
        loading: true,
      };
    case CALENDAR_SYNC_UPDATE_REQUEST:
      return {
        ...state,
        clendarStartSync: action.payload,
        loading: true,
      };
    case CALENDAR_SYNC_UPDATE_SUCCESS:
      return {
        ...state,
        clendarStartSync: action.payload,
        loading: false,
      };
    case CALENDAR_SYNC_UPDATE_ERROR:
      return {
        ...state,
        clendarStartSync: action.payload,
        loading: true,
      };
    case GETPROFILE_REQUEST:
      return {
        ...state,
        profileDetails: action.payload,
        loading: true,
      };
    case GETPROFILE_SUCCESS:
      return {
        ...state,
        profileDetails: action.payload,
        loading: false,
      };
    case GETPROFILE_ERROR:
      return {
        ...state,
        profileDetails: action.payload,
        loading: false,
      };

    case UPDATEPROFILE_REQUEST:
      return {
        ...state,
        testingResponse: {},
        loading: true,
      };
    case UPDATEPROFILE_SUCCESS:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };
    case UPDATEPROFILE_ERROR:
      return {
        ...state,
        testingResponse: action.payload,
        loading: false,
      };

    case LOGINSESSION_REQUEST:
      return {
        ...state,
        loginSessionDetails: action.payload,
        loading: true,
      };
    case LOGINSESSION_SUCCESS:
      return {
        ...state,
        loginSessionDetails: action.payload,
        loading: false,
      };
    case LOGINSESSION_ERROR:
      return {
        ...state,
        loginSessionDetails: action.payload,
        loading: false,
      };

    case LOGINSESSIONPROFILE_REQUEST:
      return {
        ...state,
        loginSessionProfileDetails: action.payload,
        loading: true,
      };
    case LOGINSESSIONPROFILE_SUCCESS:
      return {
        ...state,
        loginSessionProfileDetails: action.payload,
        loading: false,
      };
    case LOGINSESSIONPROFILE_ERROR:
      return {
        ...state,
        loginSessionProfileDetails: action.payload,
        loading: false,
      };

    case GETNOTIFICATION_REQUEST:
      return {
        ...state,
        getNotification: action.payload,
        loading: true,
      };
    case GETNOTIFICATION_SUCCESS:
      return {
        ...state,
        getNotification: action.payload,
        loading: false,
      };
    case GETNOTIFICATION_ERROR:
      return {
        ...state,
        getNotification: action.payload,
        loading: false,
      };

    case POSTNOTIFICATION_REQUEST:
      return {
        ...state,
        postNotification: action.payload,
        loading: true,
      };
    case POSTNOTIFICATION_SUCCESS:
      return {
        ...state,
        postNotification: action.payload,
        loading: false,
      };
    case POSTNOTIFICATION_ERROR:
      return {
        ...state,
        postNotification: action.payload,
        loading: false,
      };

    case ADDWEBHOOK_REQUEST:
      return {
        ...state,
        addWebhook: action.payload,
        loading: true,
      };
    case ADDWEBHOOK_SUCCESS:
      return {
        ...state,
        addWebhook: action.payload,
        loading: false,
      };
    case ADDWEBHOOK_ERROR:
      return {
        ...state,
        addWebhook: action.payload,
        loading: false,
      };

    case GETWEBHOOK_REQUEST:
      return {
        ...state,
        getWebhook: action.payload,
        loading: true,
      };
    case GETWEBHOOK_SUCCESS:
      return {
        ...state,
        getWebhook: action.payload,
        loading: false,
      };
    case GETWEBHOOK_ERROR:
      return {
        ...state,
        getWebhook: action.payload,
        loading: false,
      };

    case PATCHWEBHOOK_REQUEST:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: true,
      };
    case PATCHWEBHOOK_SUCCESS:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };
    case PATCHWEBHOOK_ERROR:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };

    case DELETEWEBHOOK_REQUEST:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: true,
      };
    case DELETEWEBHOOK_SUCCESS:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };
    case DELETEWEBHOOK_ERROR:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };

    case POSTCURRENCY_REQUEST:
      return {
        ...state,
        postCurrency: action.payload,
        loading: true,
      };
    case POSTCURRENCY_SUCCESS:
      return {
        ...state,
        postCurrency: action.payload,
        loading: false,
      };
    case POSTCURRENCY_ERROR:
      return {
        ...state,
        postCurrency: action.payload,
        loading: false,
      };

    case GETCURRENCY_REQUEST:
      return {
        ...state,
        getCurrency: action.payload,
        loading: true,
      };
    case GETCURRENCY_SUCCESS:
      return {
        ...state,
        getCurrency: action.payload,
        loading: false,
      };
    case GETCURRENCY_ERROR:
      return {
        ...state,
        getCurrency: action.payload,
        loading: false,
      };

    case PATCHCURRENCY_REQUEST:
      return {
        ...state,
        patchCurrency: action.payload,
        loading: true,
      };
    case PATCHCURRENCY_SUCCESS:
      return {
        ...state,
        patchCurrency: action.payload,
        loading: false,
      };
    case PATCHCURRENCY_ERROR:
      return {
        ...state,
        patchCurrency: action.payload,
        loading: false,
      };

    case POSTLOSTREASON_REQUEST:
      return {
        ...state,
        postLostReason: action.payload,
        loading: true,
      };
    case POSTLOSTREASON_SUCCESS:
      return {
        ...state,
        postLostReason: action.payload,
        loading: false,
      };
    case POSTLOSTREASON_ERROR:
      return {
        ...state,
        postLostReason: action.payload,
        loading: false,
      };

    case GETLOSTREASON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETLOSTREASON_SUCCESS:
      return {
        ...state,
        getLostReason: action.payload,
        loading: false,
      };
    case GETLOSTREASON_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PATCHLOSTREASON_REQUEST:
      return {
        ...state,
        patchLostReason: action.payload,
        loading: true,
      };
    case PATCHLOSTREASON_SUCCESS:
      return {
        ...state,
        patchLostReason: action.payload,
        loading: false,
      };
    case PATCHLOSTREASON_ERROR:
      return {
        ...state,
        patchLostReason: action.payload,
        loading: false,
      };

    case DELETELOSTREASON_REQUEST:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: true,
      };
    case DELETELOSTREASON_SUCCESS:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };
    case DELETELOSTREASON_ERROR:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };

    case GETMANAGEUSER_REQUEST:
      return {
        ...state,
        getManageUser: action.payload,
        loading: true,
      };
    case GETMANAGEUSER_SUCCESS:
      return {
        ...state,
        getManageUser: action.payload,
        loading: false,
      };
    case GETMANAGEUSER_ERROR:
      return {
        ...state,
        getManageUser: action.payload,
        loading: false,
      };

    case PATCHMANAGEUSER_REQUEST:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: true,
      };
    case PATCHMANAGEUSER_SUCCESS:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };
    case PATCHMANAGEUSER_ERROR:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };

    case DELETEMANAGEUSER_REQUEST:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: true,
      };
    case DELETEMANAGEUSER_SUCCESS:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };
    case DELETEMANAGEUSER_ERROR:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };

    case POSTMANAGEUSER_REQUEST:
      return {
        ...state,
        postManageUser: action.payload,
        loading: true,
      };
    case POSTMANAGEUSER_SUCCESS:
      return {
        ...state,
        postManageUser: action.payload,
        loading: false,
      };
    case POSTMANAGEUSER_ERROR:
      return {
        ...state,
        postManageUser: action.payload,
        loading: false,
      };

    case GETTEAMSDETAILS_REQUEST:
      return {
        ...state,
        getTeamsDetails: action.payload,
        loading: true,
      };
    case GETTEAMSDETAILS_SUCCESS:
      return {
        ...state,
        getTeamsDetails: action.payload,
        loading: false,
      };
    case GETTEAMSDETAILS_ERROR:
      return {
        ...state,
        getTeamsDetails: action.payload,
        loading: false,
      };

    case POSTTEAM_REQUEST:
      return {
        ...state,
        postTeam: action.payload,
        loading: true,
      };
    case POSTTEAM_SUCCESS:
      return {
        ...state,
        postTeam: action.payload,
        loading: false,
      };
    case POSTTEAM_ERROR:
      return {
        ...state,
        postTeam: action.payload,
        loading: false,
      };

    case GETTEAM_REQUEST:
      return {
        ...state,
        getTeam: action.payload,
        loading: true,
      };
    case GETTEAM_SUCCESS:
      return {
        ...state,
        getTeam: action.payload,
        loading: false,
      };
    case GETTEAM_ERROR:
      return {
        ...state,
        getTeam: action.payload,
        loading: false,
      };

    case DELETETEAM_REQUEST:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: true,
      };
    case DELETETEAM_SUCCESS:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };
    case DELETETEAM_ERROR:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };

    case PATCHTEAM_REQUEST:
      return {
        ...state,
        patchTeam: action.payload,
        loading: true,
      };
    case PATCHTEAM_SUCCESS:
      return {
        ...state,
        patchTeam: action.payload,
        loading: false,
      };
    case PATCHTEAM_ERROR:
      return {
        ...state,
        patchTeam: action.payload,
        loading: false,
      };

    case RESETPASSWORD_REQUEST:
      return {
        ...state,
        resetPass: action.payload,
        loading: true,
      };
    case RESETPASSWORD_SUCCESS:
      return {
        ...state,
        resetPass: action.payload,
        loading: false,
      };
    case RESETPASSWORD_ERROR:
      return {
        ...state,
        resetPass: action.payload,
        loading: false,
      };
    case EMAIL_START_SYNCING_REQUEST:
      return {
        ...state,
        eamilStartSync: action.payload,
        loading: true,
      };
    case EMAIL_START_SYNCING_SUCCESS:
      return {
        ...state,
        eamilStartSync: action.payload,
        loading: false,
      };
    case EMAIL_START_SYNCING_ERROR:
      return {
        ...state,
        eamilStartSync: action.payload,
        loading: true,
      };
    case GOOGLE_CALENDAR_CALLBACK_REQUEST:
      return {
        ...state,
        googleCalendarCallbackResponse: action.payload,
        loading: true,
      };
    case GOOGLE_CALENDAR_CALLBACK_SUCCESS:
      return {
        ...state,
        googleCalendarCallbackResponse: action.payload,
        loading: false,
      };
    case GOOGLE_CALENDAR_CALLBACK_ERROR:
      return {
        ...state,
        googleCalendarCallbackResponse: action.payload,
        loading: true,
      };
    case GOOGLE_EMAIL_CALLBACK_REQUEST:
      return {
        ...state,
        googleEmailCallbackResponse: action.payload,
        loading: true,
      };
    case GOOGLE_EMAIL_CALLBACK_SUCCESS:
      return {
        ...state,
        googleEmailCallbackResponse: action.payload,
        loading: false,
      };
    case GOOGLE_EMAIL_CALLBACK_ERROR:
      return {
        ...state,
        googleEmailCallbackResponse: action.payload,
        loading: true,
      };
    case EMAIL_SYNC_UPDATE_REQUEST:
      return {
        ...state,
        emailSync: action.payload,
        loading: true,
      };
    case EMAIL_SYNC_UPDATE_SUCCESS:
      return {
        ...state,
        emailSync: action.payload,
        loading: false,
      };
    case EMAIL_SYNC_UPDATE_ERROR:
      return {
        ...state,
        emailSync: action.payload,
        loading: true,
      };
    case GET_CALENDARSYNC_ACCOUNTS_REQUEST:
      return {
        ...state,
        calendarAccounts: action.payload,
        loading: true,
      };
    case GET_CALENDARSYNC_ACCOUNTS_SUCCESS:
      return {
        ...state,
        calendarAccounts: action.payload,
        loading: false,
      };
    case GET_CALENDARSYNC_ACCOUNTS_ERROR:
      return {
        ...state,
        calendarAccounts: action.payload,
        loading: true,
      };
    case GET_EMAILSYNC_ACCOUNTS_REQUEST:
      return {
        ...state,
        emailAccounts: action.payload,
        loading: true,
      };
    case GET_EMAILSYNC_ACCOUNTS_SUCCESS:
      return {
        ...state,
        emailAccounts: action.payload,
        loading: false,
      };
    case GET_EMAILSYNC_ACCOUNTS_ERROR:
      return {
        ...state,
        emailAccounts: action.payload,
        loading: true,
      };
    case GET_CALENDAR_USERINFO_REQUEST:
      return {
        ...state,
        calendarUserInfo: action.payload,
        loading: true,
      };
    case GET_CALENDAR_USERINFO_SUCCESS:
      return {
        ...state,
        calendarUserInfo: action.payload,
        loading: false,
      };
    case GET_CALENDAR_USERINFO_ERROR:
      return {
        ...state,
        calendarUserInfo: action.payload,
        loading: true,
      };
    case GET_EMAILSYNC_SYNCACCOUNT_REQUEST:
      return {
        ...state,
        syncedEmailAccountDetails: action.payload,
        loading: true,
      };
    case GET_EMAILSYNC_SYNCACCOUNT_SUCCESS:
      return {
        ...state,
        syncedEmailAccountDetails: action.payload,
        loading: false,
      };
    case GET_EMAILSYNC_SYNCACCOUNT_ERROR:
      return {
        ...state,
        syncedEmailAccountDetails: action.payload,
        loading: true,
      };

    case POSTACTIVITY_REQUEST:
      return {
        ...state,
        postActivity: action.payload,
        loading: true,
      };
    case POSTACTIVITY_SUCCESS:
      return {
        ...state,
        postActivity: action.payload,
        loading: false,
      };
    case POSTACTIVITY_ERROR:
      return {
        ...state,
        postActivity: action.payload,
        loading: false,
      };

    case GETACTIVITY_REQUEST:
      return {
        ...state,
        getActivity: action.payload,
        loading: true,
      };
    case GETACTIVITY_SUCCESS:
      return {
        ...state,
        getActivity: action.payload,
        loading: false,
      };
    case GETACTIVITY_ERROR:
      return {
        ...state,
        getActivity: action.payload,
        loading: false,
      };

    case PATCHACTIVITY_REQUEST:
      return {
        ...state,
        patchActivity: action.payload,
        loading: true,
      };
    case PATCHACTIVITY_SUCCESS:
      return {
        ...state,
        patchActivity: action.payload,
        loading: false,
      };
    case PATCHACTIVITY_ERROR:
      return {
        ...state,
        patchActivity: action.payload,
        loading: false,
      };

    case DELETEACTIVITY_REQUEST:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: true,
      };
    case DELETEACTIVITY_SUCCESS:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };
    case DELETEACTIVITY_ERROR:
      return {
        ...state,
        patchManageUser: action.payload,
        loading: false,
      };
    case PATCHMANAGEROLE_REQUEST:
      return {
        ...state,
        patchManageRole: action.payload,
        loading: true,
      };
    case PATCHMANAGEROLE_SUCCESS:
      return {
        ...state,
        patchManageRole: action.payload,
        loading: false,
      };
    case PATCHMANAGEROLE_ERROR:
      return {
        ...state,
        patchManageRole: action.payload,
        loading: false,
      };
    case NYLAS_SYNC_REQUEST:
      return {
        ...state,
        nylasSyncResponse: action.payload,
        loading: true,
      };
    case NYLAS_SYNC_SUCCESS:
      return {
        ...state,
        nylasSyncResponse: action.payload,
        loading: false,
      };
    case NYLAS_SYNC_ERROR:
      return {
        ...state,
        nylasSyncResponse: action.payload,
        loading: false,
      };
    case NYLAS_SYNC_CALLBACK_REQUEST:
      return {
        ...state,
        nylasCallbackResponse: action.payload,
        loading: true,
      };
    case NYLAS_SYNC_CALLBACK_SUCCESS:
      return {
        ...state,
        nylasCallbackResponse: action.payload,
        loading: false,
      };
    case NYLAS_SYNC_CALLBACK_ERROR:
      return {
        ...state,
        nylasCallbackResponse: action.payload,
        loading: false,
      };
    case AVATARPIC_REQUEST:
      return {
        ...state,
        avatarPic: action.payload,
        loading: true,
      };
    case AVATARPIC_SUCCESS:
      return {
        ...state,
        avatarPic: action.payload,
        loading: false,
      };
    case AVATARPIC_ERROR:
      return {
        ...state,
        avatarPic: action.payload,
        loading: false,
      };

    case GETPROFILEDETAILS_REQUEST:
      return {
        ...state,
        // getProfileDetails: action.payload,
        loading: true,
      };
    case GETPROFILEDETAILS_SUCCESS:
      return {
        ...state,
        getProfileDetails: action.payload,
        loading: false,
      };
    case GETPROFILEDETAILS_ERROR:
      return {
        ...state,
        getProfileDetails: action.payload,
        loading: false,
      };

    case POSTCUSTOMFIELD_REQUEST:
      return {
        ...state,
        postCustomField: action.payload,
        loading: true,
      };
    case POSTCUSTOMFIELD_SUCCESS:
      return {
        ...state,
        postCustomField: action.payload,
        loading: false,
      };
    case POSTCUSTOMFIELD_ERROR:
      return {
        ...state,
        postCustomField: action.payload,
        loading: false,
      };

    case PATCHCUSTOMFIELD_REQUEST:
      return {
        ...state,
        patchCustomField: action.payload,
        loading: true,
      };
    case PATCHCUSTOMFIELD_SUCCESS:
      return {
        ...state,
        patchCustomField: action.payload,
        loading: false,
      };
    case PATCHCUSTOMFIELD_ERROR:
      return {
        ...state,
        patchCustomField: action.payload,
        loading: false,
      };

    case GETCUSTOMFIELD_REQUEST:
      return {
        ...state,
        getCustomField: action.payload,
        loading: true,
      };
    case GETCUSTOMFIELD_SUCCESS:
      return {
        ...state,
        getCustomField: action.payload,
        loading: false,
      };
    case GETCUSTOMFIELD_ERROR:
      return {
        ...state,
        getCustomField: action.payload,
        loading: false,
      };

    case DELETECUSTOMFIELD_REQUEST:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: true,
      };
    case DELETECUSTOMFIELD_SUCCESS:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };
    case DELETECUSTOMFIELD_ERROR:
      return {
        ...state,
        patchWebhook: action.payload,
        loading: false,
      };

    case COMPANYLIST_REQUEST:
      return {
        ...state,
        companyList: action.payload,
        loading: true,
      };

    case COMPANYLIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        loading: false,
      };

    case COMPANYLIST_ERROR:
      return {
        ...state,
        companyList: action.payload,
        loading: false,
      };

    case TESTWEBHOOK_REQUEST:
      return {
        ...state,
        testWebhook: action.payload,
        loading: true,
      };

    case TESTWEBHOOK_SUCCESS:
      return {
        ...state,
        testWebhook: action.payload,
        loading: false,
      };

    case TESTWEBHOOK_ERROR:
      return {
        ...state,
        testWebhook: action.payload,
        loading: false,
      };

    case DISABLEWEBHOOK_REQUEST:
      return {
        ...state,
        disableWebhook: action.payload,
        loading: true,
      };

    case DISABLEWEBHOOK_SUCCESS:
      return {
        ...state,
        disableWebhook: action.payload,
        loading: false,
      };

    case DISABLEWEBHOOK_ERROR:
      return {
        ...state,
        disableWebhook: action.payload,
        loading: false,
      };

    //similarly need to write required cases
    default:
      return state;
  }
}
