import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "../../../../../../components/button/icon-action-buttons";
import { ReloadSVG } from "../../../../../../components/custom-svgs/action-svg";
import { ContentLoadingWithoutCard } from "../../../../../../components/loading";
import { getEmailCampaignAnalyticsRequest } from "../../../../store/actions/campaign_report_actions";
import EmailDetailedAnalyticsReport from "./email.details-analytics-report";
import SMSDetailedAnalyticsReport from "./sms.details-analytics-report";
import WhatsappDetailedAnalyticsReport from "./whatsapp.details-analytics-report";

export default function DetailedAnalyticsReport(props) {
  const dispatch = useDispatch();

  const emailAnalyticsStore = useSelector((store) => store.campaign.reports.email.analytics);

  useEffect(() => {
    dispatch(getEmailCampaignAnalyticsRequest({ campaignId: props.campaignId, query: {} }));
  }, [dispatch, props.campaignId]);

  const handleReload = () => {
    dispatch(getEmailCampaignAnalyticsRequest({ campaignId: props.campaignId, query: {} }));
  };
  return (
    <>
      <div className="card-header d-flex flex-stack align-items-center">
        <h3 className="card-title fw-bolder text-dark">{`Detailed Analytics`}</h3>
        <div>
          {" "}
          <IconButton onClick={handleReload} tooltip={"Reload"}>
            {ReloadSVG}
          </IconButton>{" "}
        </div>
      </div>
      <div className="p-8 h-vh-70px overflow-auto">
        {emailAnalyticsStore.loading ? (
          <ContentLoadingWithoutCard />
        ) : emailAnalyticsStore.channel === "email" ? (
          <EmailDetailedAnalyticsReport data={emailAnalyticsStore.data} campaignId={props.campaignId} />
        ) : emailAnalyticsStore.channel === "sms" ? (
          <SMSDetailedAnalyticsReport data={emailAnalyticsStore.data} campaignId={props.campaignId} />
        ) : emailAnalyticsStore.channel === "whatsapp" ? (
          <WhatsappDetailedAnalyticsReport data={emailAnalyticsStore.data} campaignId={props.campaignId} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
