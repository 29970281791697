export const MeetingDetailsFields = [
  {
    _id: "meeting-guest",
    fieldName: "Guest",
    fieldInputType: "MULTI_EMAIL_INPUT",
    apiKeyName: "attendees",
    isEditable: true,
    fieldType: "ACTIVITY",
    fieldGroup: "SYSTEM",
    isRequired: false,
    validation: "EMAIL_VALIDATION",
  },
  {
    _id: "meeting-from-date",
    fieldName: "From Date",
    fieldInputType: "MEETING_DATE_TIME",
    apiKeyName: "scheduleAt",
    isEditable: true,
    fieldType: "ACTIVITY",
    fieldGroup: "SYSTEM",
    isRequired: false,
  },
  {
    _id: "meeting-end-date",
    fieldName: "To Date",
    fieldInputType: "MEETING_DATE_TIME",
    apiKeyName: "scheduleAtEnd",
    isEditable: true,
    fieldType: "ACTIVITY",
    fieldGroup: "SYSTEM",
    isRequired: false,
  },
  {
    _id: "meeting-timezone",
    fieldName: "Timezone",
    fieldInputType: "TIMEZONE_SELECT",
    apiKeyName: "timezone",
    isEditable: true,
    fieldType: "ACTIVITY",
    fieldGroup: "SYSTEM",
    isRequired: false,
  },
  {
    _id: "meeting-link",
    fieldName: "Meeting Link",
    fieldInputType: "INPUT",
    apiKeyName: "meetingLink",
    isEditable: true,
    fieldType: "ACTIVITY",
    fieldGroup: "SYSTEM",
    isRequired: false,
    validation: "URL_VALIDATION",
  },
  {
    _id: "meeting-location",
    fieldName: "Location",
    fieldInputType: "INPUT",
    apiKeyName: "location",
    isEditable: true,
    fieldType: "ACTIVITY",
    fieldGroup: "SYSTEM",
    isRequired: false,
  },
];

export const MeetingDetailsFieldsShowColoums = ["meeting-guest", "meeting-from-date", "meeting-end-date", "meeting-timezone", "meeting-link", "meeting-location"];
export const MeetingDetailsFieldsIdToIndex = {
  "meeting-guest": 0,
  "meeting-from-date": 1,
  "meeting-end-date": 2,
  "meeting-timezone": 3,
  "meeting-link": 4,
  "meeting-location": 5,
};
