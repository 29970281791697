import React, { useState, useEffect, useMemo } from "react";
import NormalTable from "../../../../components/normal-table/index";
import { useDispatch } from "react-redux";
import * as actions from "../../actions/settings.actions";
import Pagination from "../../../../components/normal-table/pagination";
import { Spinner } from "react-bootstrap";

function ButtonHeader() {
  return (
    <>
      {/* <div className="my-1 me-4">
      
        <select className="form-select form-select-sm form-select-solid w-125px" data-control="select2" data-placeholder="Select Hours" data-hide-search="true">
          <option value={1} selected="selected">1 Hours</option>
          <option value={2}>6 Hours</option>
          <option value={3}>12 Hours</option>
          <option value={4}>24 Hours</option>
        </select>
        
      </div>
      <a href="/#" className="btn btn-sm btn-primary my-1">View All</a> */}
    </>
  );
}

export default function LoginSessions() {
  const dispatch = useDispatch();

  // const [sessionTable,setsessionTable] = useState([])

  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    dispatch(
      actions.getloginSessionProfileRequest(currentPage, (data) => {
        // setsessionTable(data.data)
        setTotalItems(data.count);
        setComments(data.data);
      }),
    );
  }, [dispatch, currentPage]);

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // setTotalItems(computedComments.length)

    // current page slice
    return computedComments;
    // .slice(
    //   (currentPage - 1) * ITEMS_PER_PAGE,
    //   (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
    // );
  }, [comments]);

  return (
    <>
      <NormalTable tableHeading={["Time", "IP Address", "Device", "Platform", "Location"]} tableTitle={"Login Sessions"} buttonHead={<ButtonHeader />}>
        {commentsData.length === 0 && <Spinner className="position-relative mt-12px ml-205-percent" animation="border" variant="primary" />}

        {commentsData.map((user, id) => {
          return (
            <tr key={id}>
              <td className="pr-4">{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).format(new Date(user.timeToLogin))}</td>
              <td className="pr-4">{user.IPAddr}</td>
              <td className="pr-4">{user.device}</td>
              <td className="pr-4">{user.platform}</td>
              <td className="pr-4">{user.location}</td>
            </tr>
          );
        })}
      </NormalTable>

      <div className="position-absolute right-20px mt-minus-21px">
        <Pagination total={totalItems} itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
      </div>
    </>
  );
}
