import React, { useEffect, useState } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getVariableKeys } from "../../../utils/variables.util";

const tabMenu = [
  {
    name: "Deal",
    keyname: "deal",
  },
  {
    name: "Lead",
    keyname: "lead",
  },
  {
    name: "People",
    keyname: "people",
  },
  {
    name: "Company",
    keyname: "company",
  },

  {
    name: "User",
    keyname: "user",
  },
];

const userColumns = [
  {
    fieldName: "User Name",
    apiKeyName: "userName",
  },
  {
    fieldName: "User Email",
    apiKeyName: "userEmail",
  },
  {
    fieldName: "Company Name",
    apiKeyName: "userCompanyName",
  },
];

export const updateVariableData = (body, person, lead, company, user, ownerList) => {
  let newBody = body;
  const leadKeyNames = ["name", "firstName", "lastName", "email", "phone"];
  const peopleKeyNames = ["name", "firstName", "lastName", "email", "phone"];
  const companyKeyNames = ["name", "createdAddress"];
  const senderKeyName = ["userName", "userEmail", "userCompanyName"];

  const variables = getVariableKeys(body);
  if (variables.length === 0) return body;

  console.log(body, person, lead, company, user, ownerList, "body, person, lead, company, user, ownerList")



  leadKeyNames.forEach((each) => {
    if (each === "email" || each === "phone") {
      if (Array.isArray(lead[each]) && lead[each].length > 0) {
        newBody = newBody.replace(`{{lead.${each}}}`, lead[each].join(", "));
      } else {
        newBody = newBody.replace(`{{lead.${each}}}`, "");
      }
    } else if (each === "owner") {
      if (lead.owner && ownerList.hasOwnProperty(lead.owner)) {
        newBody = newBody.replace(`{{lead.${each}}}`, ownerList[lead.owner].name ? ownerList[lead.owner].name : ownerList[lead.owner].email);
      } else {
        newBody = newBody.replace(`{{lead.${each}}}`, "");
      }
    } else {
      newBody = newBody.replace(`{{lead.${each}}}`, lead[each] ? lead[each] : "");
    }
  });
  peopleKeyNames.forEach((each) => {
    if (each === "email" || each === "phone") {
      if (Array.isArray(person[each]) && person[each].length > 0) {
        newBody = newBody.replace(`{{people.${each}}}`, person[each].join(", "));
      } else {
        newBody = newBody.replace(`{{people.${each}}}`, "");
      }
    } else if (each === "owner") {
      if (person.owner && ownerList.hasOwnProperty(person.owner)) {
        newBody = newBody.replace(`{{people.${each}}}`, ownerList[person.owner].name ? ownerList[person.owner].name : ownerList[person.owner].email);
      } else {
        newBody = newBody.replace(`{{people.${each}}}`, "");
      }
    } else {
      newBody = newBody.replace(`{{people.${each}}}`, person[each] ? person[each] : "");
    }
  });
  companyKeyNames.forEach((each) => {
    if (each === "owner") {
      if (company.owner && ownerList.hasOwnProperty(company.owner)) {
        newBody = newBody.replace(`{{company.${each}}}`, ownerList[company.owner].name ? ownerList[company.owner].name : ownerList[company.owner].email);
      } else {
        newBody = newBody.replace(`{{company.${each}}}`, "");
      }
    } else {
      newBody = newBody.replace(`{{company.${each}}}`, company[each] ? company[each] : "");
    }
  });
  senderKeyName.forEach((each) => {
    if (each === "userName") {
      newBody = newBody.replace(`{{user.${each}}}`, user.fullName ? user.fullName : "");
    } else if (each === "userEmail") {
      newBody = newBody.replace(`{{user.${each}}}`, user.email ? user.email : "");
    } else if (each === "userCompanyName") {
      newBody = newBody.replace(`{{user.${each}}}`, user.company ? user.company : "");
    }
  });

  return newBody;
};

export default function VariableOptions(props) {
  const [key, setKey] = useState("lead");
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(["user"]);

  const peopleColumns = useSelector((state) => state.People.peopleFields.list);
  const companyColumns = useSelector((state) => state.Companies.companyFields.list);
  const leadColumns = useSelector((state) => state.Leads.leadFields.list);
  const dealColumns = useSelector((state) => state.DealList.dealFields.list);

  useEffect(() => {
    if (props.page === "lead" && ["activeLead", "junkLead", "activityList"].includes(props.pageForm)) {
      setActiveTab(["lead", "company", "user"])
      setKey("lead")
    }
    else if (props.page === "people" && props.pageForm === "people") {
      setActiveTab(["people", "company", "user"])
      setKey("people")
    }
    else if (props.page === "company" && props.pageForm === "company") {
      setActiveTab(["company", "user"])
      setKey("company")
    }
    else if (props.page === "deal" && ["dealPipeline", "dealList"].includes(props.pageForm)) {
      setActiveTab(["deal", "people", "company", "user"])
      setKey("deal")
    }
    else {
      setActiveTab(["user"]);
      setKey("user")
    }
  }, [props.page, props.pageForm])


  const addVariable = (item, key, fieldGroup) => {
    if (fieldGroup === "CUSTOM") {
      props.addVariable(item, key, `{{${key}._CF_${item}}}`);
    }
    else {
      props.addVariable(item, key, `{{${key}.${item}}}`);
    }

    setShow(false);
  };


  return (
    <Dropdown rootCloseEvent="click" show={show} onToggle={() => setShow(!show)} className="dropdown-inline m-0 px-0">
      <Dropdown.Toggle className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent">
        Variables
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 pt-2 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <Tab.Container defaultActiveKey={key}>
          <Nav as="ul" className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 justify-content-center px-1" onSelect={(_key) => setKey(_key)}>
            {tabMenu.map((each) => {
              if (!activeTab.includes(each.keyname)) return null;
              return (
                <Nav.Item className={`nav-item w-${parseInt(100 / activeTab.length)}`} as="li">
                  <Nav.Link eventKey={each.keyname} className={`nav-link justify-content-center m-0 show ${key === each.keyname ? "active" : ""}`}>
                    {each.name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>

          <Tab.Content className="tab-content">
            <Tab.Pane eventKey="lead">
              <div className="overflow-y-auto h-220px-mx">
                {leadColumns
                  .map((item, index) => {
                    return (
                      <Dropdown.Item className="dropdown-hover" onSelect={() => addVariable(item.apiKeyName, "lead", item.fieldGroup)} key={index}>
                        {item.fieldName}
                      </Dropdown.Item>
                    );
                  })}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="people">
              <div className="overflow-y-auto h-220px-mx">
                {peopleColumns
                  .map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" onSelect={() => addVariable(item.apiKeyName, "people", item.fieldGroup)} key={index}>
                      {item.fieldName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="company">
              <div className="overflow-y-auto h-220px-mx">
                {companyColumns
                  .map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" onSelect={() => addVariable(item.apiKeyName, "company", item.fieldGroup)} key={index}>
                      {item.fieldName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="deal">
              <div style={{ maxHeight: 220, overflowY: "auto" }}>
                {dealColumns
                  .map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" onSelect={() => addVariable(item.apiKeyName, "deal", item.fieldGroup)} key={index}>
                      {item.fieldName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="user">
              <div className="overflow-y-auto h-220px-mx">
                {userColumns.map((item, index) => (
                  <Dropdown.Item className="dropdown-hover" onSelect={() => addVariable(item.apiKeyName, "user", item.fieldGroup)} key={index}>
                    {item.fieldName}
                  </Dropdown.Item>
                ))}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Dropdown.Menu>
    </Dropdown>
  );
}
