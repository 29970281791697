import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Form, Spinner, InputGroup, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { dispatchSnackbarError } from "../../../utils/toaster";

import { verifyDKIMRequest, verifyDMARCRequest, verifySPFRequest, verifyTrackingRequest } from "../store/actions/campaign_email_settings_actions";

export default function DomainVerificationModal(props) {
  const [loading, setLoading] = useState(false);
  const [verifyText, setVerifyText] = useState("");
  const [verifyStatus, setVerifyStatus] = useState({
    spf: false,
    dkim: false,
    dmarc: false,
    tracking: false,
  });

  const { show, handleClose, handleSave, defaultName, setDefaultName, selectedDomain } = props.modalProps;

  const dispatch = useDispatch();

  const handleTrackingVerification = useCallback(
    async (domain) => {
      const spfStatus = { dkim: false, dmarc: false, tracking: false, spf: false };
      setVerifyStatus(spfStatus);
      setLoading(true);
      setVerifyText("Verifying SPF");
      const _verifySPF = await new Promise((resolve) => dispatch(verifySPFRequest({ domain: domain }, (data) => resolve(data))));
      if (!_verifySPF.status) {
        handleVerifyError(_verifySPF.message || _verifySPF.error || "Your domain can't be verified. Any change to DNS can take up to 48 hours. Please try again later.", "SPF");
      } else {
        spfStatus.spf = true;
        setVerifyStatus(spfStatus);
      }

      setVerifyText("Verifying DKIM");
      const _verifyDKIM = await new Promise((resolve) => dispatch(verifyDKIMRequest({ domain: domain }, (data) => resolve(data))));
      if (!_verifyDKIM.status) {
        handleVerifyError(_verifyDKIM.message || _verifyDKIM.error, "DKIM");
      } else {
        spfStatus.dkim = true;
        setVerifyStatus(spfStatus);
      }

      setVerifyText("Verifying Tracking");
      const _verifyTracking = await new Promise((resolve) => dispatch(verifyTrackingRequest({ domain: domain }, (data) => resolve(data))));
      if (!_verifyTracking.status) {
        handleVerifyError(_verifyTracking.message || _verifyTracking.error, "Tracking");
      } else {
        spfStatus.tracking = true;
        setVerifyStatus(spfStatus);
      }

      setVerifyText("Verifying DMARC");
      const _verifyDMARC = await new Promise((resolve) => dispatch(verifyDMARCRequest({ domain: domain }, (data) => resolve(data))));
      if (!_verifyDMARC.status) {
        handleVerifyError(_verifyDMARC.message || _verifyDMARC.error, "DMARC");
      } else {
        spfStatus.dmarc = true;
        setVerifyStatus(spfStatus);
      }

      setLoading(false);
      setVerifyText("Successfully verified!");
    },
    [dispatch],
  );

  useEffect(() => {
    if (selectedDomain) handleTrackingVerification(selectedDomain);
  }, [dispatch, selectedDomain, handleTrackingVerification]);

  const handleVerifyError = (message, record) => {
    dispatchSnackbarError(message);
    // setLoading(false);
    setVerifyText(`Failed ${record} verifcation`);
  };

  return (
    <Modal show={show} onHide={handleClose} size="md" className="d-flex flex-center">
      <Modal.Header closeButton>
        <Modal.Title>{"Verify Domain"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="contained-modal-title-vcenter">
        <Form.Group id="campaign-components-domain-verification-modal-popup-header" className="mb-3">
          <Row>
            <Col align="center">
              <Form.Label aria-label="verify-text">
                <strong>{verifyText}</strong>
              </Form.Label>
              {verifyStatus.spf && verifyStatus.dkim && verifyStatus.tracking && verifyStatus.dmarc && !loading && (
                <Form.File>
                  <img src={"https://app.saino.io/assets/media/icons/checkRight.png"} alt="green-tick" width="100" />
                </Form.File>
              )}
              {(!verifyStatus.spf || !verifyStatus.dkim || !verifyStatus.tracking || !verifyStatus.dmarc) && !loading && (
                <div>
                  <i class="bi bi-x-octagon-fill text-danger fs-1"></i>
                </div>
              )}
              {loading && (
                <div className="w-100px scroll-y pt-10 pb-10 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Col>
          </Row>
        </Form.Group>
        <Form.Group id="campaign-components-domain-verification-modal-popup-body" className="d-flex justify-content-center gap-4">
          <Form.Label aria-label="SPF">SPF {verifyStatus.spf ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</Form.Label>
          <Form.Label aria-label="DKIM">DKIM {verifyStatus.dkim ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</Form.Label>
          <Form.Label aria-label="TRACKING">TRACKING {verifyStatus.tracking ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</Form.Label>
          <Form.Label aria-label="DMARC">DMARC {verifyStatus.dmarc ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</Form.Label>
        </Form.Group>

        {verifyStatus.spf && verifyStatus.dkim && verifyStatus.tracking && verifyStatus.dmarc && !loading && (
          <>
            <Col className="d-flex justify-content-center" align="center">
              <Form.Label aria-label="enter-default-email-id">Enter Default Email ID:</Form.Label>
            </Col>
            <InputGroup className="d-flex justify-content-center mb-3">
              <Form.Control type="text" value={defaultName} aria-label="mailer" aria-describedby="basic-addon3" onChange={(e) => setDefaultName(e.target.value)} />
              <InputGroup.Text id="basic-addon3">@{selectedDomain}</InputGroup.Text>
            </InputGroup>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Form.Group id="campaign-components-domain-verification-modal-popup-footer" className="d-flex gap-2">
          {verifyStatus.spf && verifyStatus.dkim && verifyStatus.tracking && verifyStatus.dmarc && !loading && (
            <>
              <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary " onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" className="btn btn-sm btn-primary my-1 btn  btn-primary w-15px h-15px" onClick={handleSave}>
                {loading && <Spinner className="mr-2 fs-9 text-white" animation="border" variant="" />} Save
              </Button>
            </>
          )}
          {(!verifyStatus.spf || !verifyStatus.dkim || !verifyStatus.tracking || !verifyStatus.dmarc) && !loading && (
            <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
              Cancel
            </Button>
          )}
        </Form.Group>
      </Modal.Footer>
    </Modal>
  );
}
