import React from "react";
import _ from "lodash";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import * as leadActions from "../../../containers/leads/store/actions/actions";
import * as peopleActions from "../../../containers/customers/store/actions/actions";
import * as companyActions from "../../../containers/companies/store/actions/actions";
import * as marketingListActions from "../../../containers/campaign/store/actions/campaign_marketing_list_actions";
import * as dealListActions from "../../../containers/deals/dealList/actions";
import { useDispatch } from "react-redux";

export default function AIWizardFieldDisplay(props) {
    const dispatch = useDispatch();

    const handleGeneration = () => {
        if (!props.field) return console.log("field not found");
        if (_.isEmpty(props.allData)) return console.log("allData not found");
        if (_.isEmpty(props.loadingData)) return console.log("loadingData not found");
        if (_.isEmpty(props.combinedId)) return console.log("combinedId not found");
        const payload = {
            data: {
                moduleId: props.allData._id,
                moduleName: props.field.fieldType,
                fieldId: props.field._id,
            },
            pageForm: props.pageForm,
            loadingData: props.loadingData,
            cellId: props.combinedId
        }

        if (props.page === "Leads") {
            dispatch(leadActions.generateLeadAIWFDataRequest(payload))
        }
        else if (props.page === "People") {
            dispatch(peopleActions.generatePeopleAIWFDataRequest(payload))
        }
        else if (props.page === "Companies") {
            dispatch(companyActions.generateCompanyAIWFDataRequest(payload))
        }
        else if (props.page === "marketing") {
            dispatch(marketingListActions.generateMarketingAIWFDataRequest(payload))
        }
        else if (props.page === "Deals" || props.page === "DealList") {
            dispatch(dealListActions.generateDealAIWFDataRequest(payload))
        }


    }

    if (props.hideAIWFGenaration) {
        return <div className={`data-container2`}>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
                <span className="data-container-2-text">{props.data}</span>
            </OverlayTrigger>
        </div>
    }
    else if (props.data) {
        return <div className={`data-container2`}>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
                <span className="data-container-2-text">{props.data}</span>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Regenerate"}</Tooltip>} trigger={["hover", "focus"]}>
                <span className="data-container-2-icon" onClick={(e) => { e.stopPropagation(); handleGeneration() }}>
                    <i style={{ color: "#00acff", fontSize: "12px" }} className="bi bi-arrow-repeat cursor-pointer" />
                </span>
            </OverlayTrigger>
        </div>
    }
    else {
        return <Button size="sm" variant="secondary" onClick={(e) => { e.stopPropagation(); handleGeneration() }}>Generate</Button>
    }
}