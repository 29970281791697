import React, { useState } from "react";
import { Tab } from "react-bootstrap";
import MarketingTimeline from "../components/marketing-timeline.side-pannel";
import { SidebarTabs } from "../custom-components/tabs";

export default function MarketingSectionB(props) {
  const [selectedTab, setSelectedTab] = useState("Campaigns");

  return (
    <Tab.Container defaultActiveKey={selectedTab}>
      <SidebarTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={["Campaigns"]} />
      <div>
        {props.marketingId && (
          <div className="offcanvas-content pb-10">
            <div className="tab-content">
              <div role="tabpanel" className={`tab-pane fade py-7 px-5 pr-5 mr-n5 bg-white ${selectedTab === "Campaigns" ? "active show" : ""}`}>
                <MarketingTimeline marketingId={props.marketingId} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Tab.Container>
  );
}
