import moment from "moment-timezone";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CustomSwitch from "../../../../../components/custom_form/switch";
import IconBasedSimpleDropdown from "../../../../../components/dropdowns/icons-based-dropdown";
import OwnerDisplay from "../../../../../components/table/displays/ownerDisplay";
import "./styles.scss";

export default function CadenceListCard(props) {
  const history = useHistory();
  const actionOptions = [
    {
      label: "Edit Cadence",
      value: "edit",
    },
    {
      label: "Subscribers",
      value: "subscribers",
    },
    {
      label: "Stepwise Analytics",
      value: "analytics",
    },
    {
      label: "Summary",
      value: "summary",
    },
    {
      label: "Delete Cadence",
      value: "delete",
    },
  ];
  const handleSelectActions = (action, cadence) => {
    if (action.value === "edit") {
      props.handleEdit(cadence);
    } else if (action.value === "delete") {
      props.handleDelete(cadence);
    } else if (action.value === "subscribers") {
      history.push(`/automation/cadence/subscribers/${cadence._id}`);
    } else if (action.value === "analytics") {
      history.push(`/automation/cadence/analytics-stepwise/${cadence._id}`);
    } else if (action.value === "summary") {
      history.push(`/automation/cadence/summary/${cadence._id}`);
    }
  };

  return (
    <div className="card cadence-list-card-wrapper">
      <div className="card-section-1">
        <div className="card-section-1-left">
          <div className="card-section-1-left-icon">
            <i class="bi bi-send"></i>
          </div>
          <div onClick={() => props.handleEdit(props.data)} className="card-section-1-left-name cursor-pointer text-hover-primary text-hover-underline">
            {props.data.name}
          </div>
        </div>
        <div className="card-section-1-right">
          <div className="card-section-1-right-switch ">
            <CustomSwitch
              label=""
              id={props.data._id}
              checked={props.data.isEnabled}
              handleChange={(e) => {
                props.onStatusChange(props.data._id, e.target.checked);
              }}
            />
          </div>
          <div className="card-section-1-right-action">
            <IconBasedSimpleDropdown value={""} label={"Actions"} icon={"bi bi-three-dots"} options={actionOptions} handleSelect={(a) => handleSelectActions(a, props.data)} handleClose={() => {}} disabled={false} />
          </div>
        </div>
      </div>
      <div className="card-section-2">
        <div className="card-section-2-total-subscribers">
          <i class="bi bi-person"></i>
          <span>{props.data.total} prospects are currently enrolled</span>
        </div>
        <div className="card-section-2-hr" />
        <div className="card-section-2-cadence-stats">
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.pending}</div>
            <div className="name-wrapper"> Pending</div>
          </div>
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.active}</div>
            <div className="name-wrapper"> Active</div>
          </div>
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.paused}</div>
            <div className="name-wrapper"> Paused</div>
          </div>
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.finished}</div>
            <div className="name-wrapper"> Finished</div>
          </div>
        </div>
        <div className="card-section-2-hr" />
        <div className="card-section-2-cadence-stats">
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.sent}</div>
            <div className="name-wrapper"> Email Delivered </div>
          </div>
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.opened}</div>
            <div className="name-wrapper"> Email Opened </div>
          </div>
          <div className="count-name-wrapper">
            <div className="count-wrapper">{props.data.replied}</div>
            <div className="name-wrapper"> Email Replied </div>
          </div>
        </div>
        <div className="card-section-2-hr" />
        <div className="card-section-2-created-by">
          <div>
            <OverlayTrigger overlay={<Tooltip>Last Updated</Tooltip>}>
              <span>{moment(props.data.updatedAt).format("MMM DD, h:mm a")}</span>
            </OverlayTrigger>
          </div>
          <div>
            <OwnerDisplay value={props.data.owner} />
          </div>
        </div>
      </div>
    </div>
  );
}
