import { getListOfActivties, updateLoadingData, editActivity } from "../types/activity.types";

const initState = {
  loading: "",
  list: [],
  count: 0,
  activityTypeData: [],
  cellLoading: false,
  loadingData: {
    pageNo: 1,
    limit: 15,
    filter: "Todo",
    startDate: 0,
    endDate: 0,
    filterId: "",
    activityType: "",
    source: "",
  },
};

export default function activityListReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfActivties.GET_LIST_OF_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getListOfActivties.GET_LIST_OF_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        count: action.payload.count,
        activityTypeData: action.payload.activityTypeData,
      };
    case getListOfActivties.GET_LIST_OF_ACTIVITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case updateLoadingData.UPDATE_ACTIVITY_LOADING_DATA:
      return {
        ...state,
        loadingData: action.payload,
      };
    case editActivity.EDIT_ACTIVITY_REQUEST:
      return {
        ...state,
        cellLoading: action.payload.cellId,
      };
    case editActivity.EDIT_ACTIVITY_SUCCESS:
    case editActivity.EDIT_ACTIVITY_ERROR:
      return {
        ...state,
        cellLoading: false,
      };
    default:
      return state;
  }
}
