// import { IStage } from 'modules/boards/types';
// import Icon from 'modules/common/components/Icon';
import * as React from "react";
// import {
//   Content,
//   Lost,
//   Name,
//   StageContainer,
//   StageWrap,
//   Stayed,
//   Values
// } from '../styled';
import { Lost, Values } from "../styled";
import InlineStyled from "../../../../components/inline-styled/inline-styled";
// import styled from 'styled-components';

class Stage extends React.Component {
  renderLostInfo(info) {
    if (info.count || info.count >= 0) {
      const lost = info.count < 0 ? 0 : info.count;

      const percent = info.percent ? parseInt(info.percent, 10) : 0;

      return (
        <Values>
          <Lost>Lost: {lost}</Lost>
          <span>
            {percent}%{/* <Icon icon="angle-down" /> */}
          </span>
        </Values>
      );
    }
    return;
  }

  render() {
    const { stage, spacing } = this.props;

    return (
      <div className="overflow-hidden pb-6px">
        <InlineStyled
          className="float-right justify-content-between font-size-12px"
          styles={{
            minWidth: `${spacing}%`,
          }}
        >
          <div className="bg-white box-shadow-3 border-radius-3px d-flex flex-stack overflow-hidden">
            <div className="flex-1 flex-shrink-0 font-size-14px py-7-point-5px px-15px">{stage.name}</div>
            <div className="flex-shrink-0 border-left-width-1px border-left-style-dotted border-left-color-eeeeee fw-600 font-size-18px py-9px px-15px bg-white text-888">
              {stage.itemsTotalCount} / <span className="text-373737">{stage.initialDealsTotalCount}</span>
            </div>
          </div>
          {this.renderLostInfo(stage.compareNextStage)}
        </InlineStyled>
      </div>
    );
  }
}

export default Stage;
