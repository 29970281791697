import React from "react";
import "./styles.css";

export default function OnlyStepper({ steps, activeStep }) {
  return (
    <div className="stepper stepper-pills d-flex flex-column" id="kt_stepper_example_basic">
      <div className="stepper-nav py-1 d-flex justify-content-center" >
        {steps.map((each, index) => {
          return (
            <div className={`stepper-item mx-2 my-4 ${activeStep === index + 1 ? "current" : ""}`} data-kt-stepper-element="nav">
              <div className="stepper-line w-30px"></div>
              <div className="stepper-icon w-30px h-30px rounded-circle">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">{index + 1}</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">{each}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
