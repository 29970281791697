import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CustomTableWithoutAction } from "../../../../../components/custom-tables/table-without-actions";
import { ContentLoadingWithoutCard } from "../../../../../components/loading";
import { getCampaignPricingRequest } from "../../../store/actions/campaign_common_settings_actions";

const campaingTableFields = ["Plan Type", "Currency", "Per Whatsapp Price", "Status"];
const campaignTableMapping = {
    "Plan Type": "planType",
    Currency: "currency",
    "Per Whatsapp Price": "price",
    Status: "status",
};

export const campaignTableFieldDataType = {
    planType: "TEXT",
    currency: "LIGHT_TAG",
    price: "TEXT",
    status: "TAG",
};
export default function WhatsappPricing(props) {
    const [limit, setLimit] = useState(100);
    const [pageNo, setPageNo] = useState(1);

    const dispatch = useDispatch();

    const whatsappPricingStore = useSelector((store) => store.campaign.settings.pricing.email);

    useEffect(() => {
        dispatch(getCampaignPricingRequest("whatsapp"));
    }, [dispatch]);

    if (whatsappPricingStore.loading) {
        return <ContentLoadingWithoutCard />;
    }

    return (
        <div className="pricing-container">
            <div className="pricing-header d-flex flex-stack align-items-center">
                <h3 className="fw-bolder text-dark">{props.channel === "sms" ? "SMS" : _.capitalize(props.channel)} Pricing</h3>
            </div>
            <div className="pricing-content-container p-8">
                <div>
                    <div className="card">
                        <CustomTableWithoutAction
                            tablehead={campaingTableFields}
                            tablecontent={whatsappPricingStore.data.map((each) => {
                                each.planType = "Regular";
                                each.currency = _.upperCase(each.currency);
                                each.price = `${_.round(each.price, 4)}`;
                                return each;
                            })}
                            mappingTable={campaignTableMapping}
                            dataType={campaignTableFieldDataType}
                            pageNo={pageNo}
                            limit={limit}
                            count={whatsappPricingStore.count}
                            setPageNo={setPageNo}
                            setLimit={setLimit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
