import { getCadenceAnalyticsStepWise } from "../types/cadence_analytics_stepwise_types";

const initState = {
  data: [],
  loading: false,
  error: null,
};

export default function cadenceAnalyticsStepWiseReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getCadenceAnalyticsStepWise.GET_CADENCE_ANALYTICS_STEPWISE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
