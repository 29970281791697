import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

//containers
import LeadBotList from "./containers/list";
import LeadBotBuilder from "./containers/builder";

export default function LeadBot(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={LeadBotList} />
      <Route exact path={`${props.match.path}/builder/:lead_bot_id`} component={LeadBotBuilder} />
      <Redirect to={`${props.match.path}`} />
    </Switch>
  );
}
