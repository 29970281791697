export const campaingTableFields = ["Channel", "Campaign Name", "Created By", "Sent Date", "Total Audience", "Valid Audience", "Invalid Audience", "Cost", "Status"];

export const campaignTableActions = ["analytics", "detailed-report", "copy", "schedule"];

export const campaignTableMapping = {
  "Campaign Name": "title",
  Channel: "channel",
  "Created By": "owner",
  "Sent Date": "effectiveDate",
  "Valid Audience": "validAudience",
  Status: "status",
  Cost: "cost",
  "Total Audience": "totalAudience",
  "Invalid Audience": "invalidAudience",
};

export const campaignTableFieldDataType = {
  title: "TEXT",
  channel: "CHANNEL_LIGHT_TAG",
  owner: "OWNER",
  effectiveDate: "DATE_WITH_TIMEZONE",
  validAudience: "TEXT",
  status: "TAG",
  cost: "COST",
};

export const TimeIntervalOptions = [
  { label: "15 Min", value: 900 },
  { label: "30 Min", value: 1800 },
  { label: "45 Min", value: 2700 },
  { label: "60 Min", value: 3600 },
  { label: "120 Min", value: 7200 },
];

export const ContactPerBatcheOptions = [
  { label: 500, value: 500, max: 10000, min: 0 },
  { label: 1000, value: 1000, max: 20000, min: 0 },
  { label: 2000, value: 2000, max: 40000, min: 0 },
  { label: 3000, value: 3000, max: 60000, min: 0 },
  { label: 4000, value: 4000, max: 80000, min: 0 },
  { label: 5000, value: 5000, max: 100000, min: 0 },
  { label: 6000, value: 6000, max: 120000, min: 12000 },
  { label: 7000, value: 7000, max: 140000, min: 14000 },
  { label: 8000, value: 8000, max: 160000, min: 16000 },
  { label: 9000, value: 9000, max: 180000, min: 18000 },
  { label: 10000, value: 10000, max: 350000, min: 20000 },
  { label: 15000, value: 15000, max: 500000, min: 30000 },
  { label: 20000, value: 20000, max: 800000, min: 40000 },
  { label: 25000, value: 25000, max: 1000000, min: 50000 },
  { label: 50000, value: 50000, max: 1500000, min: 100000 },
  { label: 100000, value: 100000, max: 2000000, min: 200000 },
];

export const moduleMapping = {
  lead: {
    page: "Leads",
    pageSub: "lead",
    moduleName: "lead",
  },
  people: {
    page: "People",
    pageSub: "people",
    moduleName: "people",
  },
  marketing: {
    page: "marketing",
    pageSub: "marketing",
    moduleName: "marketing",
  },
  marketing_list: {
    page: "marketing",
    pageSub: "marketing",
    moduleName: "marketing",
  },
  file: {
    page: "",
    pageSub: "",
    moduleName: "",
  },
  copy_paste: {
    page: "",
    pageSub: "",
    moduleName: "",
  },
};

export const campaignTypeOptions = [
  { value: "all", label: "All" },
  { value: "api", label: "API Request" },
  { value: "application", label: "Campaigns" },
];

export const campaignChannelsOptions = [
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "whatsapp", label: "Whatsapp" },
  { value: "rcs", label: "Google RCS" },
];
export const campaignChannelsOptions2 = [
  { value: "all", label: "All" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "whatsapp", label: "Whatsapp" },
  { value: "rcs", label: "Google RCS" },
];

export const campaignOptionObj = {
  all: "All",
  api: "API Request",
  application: "Campaigns",
  email: "Email",
  sms: "SMS",
  whatsapp: "Whatsapp",
  rcs: "Google RCS",
};

export const smsRouteOptions = [
  { value: "transactional", label: "Transactional" },
  { value: "promotional", label: "Promotional" },
  { value: "otp", label: "OTP" },
];

export const campaignInfoTextMessages = {
  SELECT_ROUTE_INFO: "Select it based on the message you want to send",
  SELECT_SENDER_INFO: "Message will be delivered with selected senders based on routing. You can select multiple senders, Message will be sent based on the sender id order. For further details, click here",
};
