import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../styles.scss";
import FreePlanSubscriptionModel from "../../../../../components/free-plan-subscription-modal";
import ContactSales from "../../components/contact-sales";
import PlanCard from "../../components/plan-card";
import _ from "lodash";

const planFeatures = {
  free: [
    "All essential features",
    "25 AI-Wizard Credits",
    "200 Contacts",
    "Deals pipeline(1)",
    "Analytics dashboard(1)",
    "Custom fields(5)",
    "Multi-Currency & Roles ",
    "Product Catalog",
    "Branded Leadbot",
    "Campaigns - SMS & Email",
    "Unlimited Marketing Contacts",
    "Email Validation & Finder",
    "Chrome Linkedin Extension"
  ],
  startUpPlan: [
    "Everything in Free Plan",
    "Unlimited Cadences",
    "Unlimited Emails",
    "1 Mailbox",
    "100 Cadence Subscriber",
    "100 AI-Wizard Credits",
    "100,000 Contacts",
    "Deals pipeline(3)",
    "Custom fields(15)",
    "Analytics dashboards",
    "Workflow automation",
    "2-Way Email & Calendar",
    "Teams, Goals & Forecast"
  ],
  businessPlan: [
    "Everything in Startup",
    "Unlimited Cadences",
    "Unlimited Emails",
    "2 Mailbox",
    "1000 Cadence Subscriber",
    "500 AI-Wizard Credits",
    "Unlimited Contacts",
    "Unlimited Pipelines",
    "Unlimited Custom Fields",
    "Unlimited Analytics",
    "Meeting Scheduler",
    "Whitelabled Leadbot",
    "Priority Support"
  ],
  businessPlusPlan: [
    "Everything in Business",
    "Unlimited Cadences",
    "Unlimited Emails",
    "4 Mailbox",
    "10000 Cadence Subscriber",
    "2500 AI-Wizard Credits",
    "A/B Testing",
    "Account Based Cadence",
    "Unlimited Inbuilt CRM",
    "2-Way CRM Sync Upcoming",
    "Meeting Scheduler",
    "Whitelabled Leadbot",
    "Dedicated A/c Manager"
  ],
  enterprisePlan: [
    "Everything in Business",
    "Unlimited Cadences",
    "Unlimited Emails",
    "4 Mailbox",
    "10000 Cadence Subscriber",
    "2500 AI-Wizard Credits",
    "A/B Testing",
    "Account Based Cadence",
    "Unlimited Inbuilt CRM",
    "2-Way CRM Sync Upcoming",
    "Meeting Scheduler",
    "Whitelabled Leadbot",
    "Dedicated A/c Manager"
  ],
};
const planNames = {
  free: "Free",
  startUpPlan: "Startup",
  businessPlan: "Business",
  enterprisePlan: "Enterprise",
  businessPlusPlan: "Business Plus",
};

const planDesc = {
  free: "For individuals",
  startUpPlan: "For startups",
  businessPlan: "For businesses",
  enterprisePlan: "For big companies",
  businessPlusPlan: "For big companies",
};

export default function SubscriptionStep1(props) {
  const { setSelectedPlanByCard } = props;
  const [normalUserPlans] = useState(["free", "startUpPlan", "businessPlan", "businessPlusPlan"]);
  const [filteredPlanData, setFileteredData] = useState({});
  const [showFreePlanModel, setShowFreePlanModel] = useState(false);

  const billingInfo = useSelector((state) => state.Billing.subscription.accountWiseBilling);
  const plansData = useSelector((state) => state.Billing.subscription.billingPrices);
  const billingStore = useSelector((state) => state.Billing);

  useEffect(() => {
    if (plansData.plans) {
      const planObject = {};
      for (let keys in plansData.plans) {
        const addOnArray = plansData.plans[keys];
        const data = addOnArray.find((a) => a.interval === props.interval && a.currency === props.currency);
        planObject[keys] = data;
      }
      setFileteredData(planObject);
    }
  }, [plansData.plans, props.currency, props.interval]);

  useEffect(() => {
    if (!_.isEmpty(billingInfo.data)) {
      const { subscribedPlans } = billingInfo.data;
      const planArr = [];
      subscribedPlans.forEach((eachPlan) => {
        planArr.push(eachPlan.planId);
      });

      for (let keys in plansData.plans) {
        const addOnArray = plansData.plans[keys];
        addOnArray.forEach((each) => {
          if (planArr.indexOf(each.priceId) !== -1) {
            setSelectedPlanByCard(each);
          }
        });
      }
    }
  }, [billingInfo.data, plansData.plans, setSelectedPlanByCard]);

  const handleSelectMainPlan = (each, data) => {
    if (each === "free") {
      props.handleFreePlanSelected(data);
    } else {
      props.handleSelectMainPlan(data);
    }
  };

  const onFreePlanConversionClick = () => {
    if (billingInfo.data?.selectedPlan?.toLowerCase() !== "free") {
      setShowFreePlanModel(true);
    }
  };
  const handleCovertToFreePlan = () => {
    setShowFreePlanModel(false);
    props.convertToFreePlan();
  };

  const getPlanPrice = (amount, interval) => {
    if (interval === "month") {
      return parseInt(amount / 100);
    } else {
      return parseInt(amount / 12 / 100);
    }
  };
  return (
    <>
      {" "}
      {billingStore.billingPricesLoading ? (
        <div className="d-flex w-100 h-100 flex-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="subscription-step1-page-warpper">
          <div className="subscription-step1-part1-wrapper">
            <div className="plan-cards-container gap-7">
              {normalUserPlans.map((each, index) => {
                if (billingInfo.data && billingInfo.data.isLimitedDealClaimed && ["free", "startUpPlan", "businessPlusPlan"].includes(each)) return <span key={index}></span>;
                else {
                  return (
                    <PlanCard
                      planType={each}
                      serviceType={billingInfo.data?.selectedPlan}
                      planName={planNames[each]}
                      planDesc={planDesc[each]}
                      interval={props.interval}
                      amount={
                        each === "free"
                          ? `${props.currencySymbol} 0`
                          : `${props.currencySymbol} ${filteredPlanData[each] && filteredPlanData[each].amount ? getPlanPrice(filteredPlanData[each].amount, props.interval) : 0}`
                      }
                      features={planFeatures[each]}
                      onPlanSelect={(e) => {
                        e.stopPropagation();
                        if (each === "free") {
                          onFreePlanConversionClick(each, filteredPlanData[each]);
                        } else {
                          handleSelectMainPlan(each, filteredPlanData[each]);
                        }
                      }}
                    />
                  );
                }
              })}
            </div>
            <ContactSales />
          </div>
          <FreePlanSubscriptionModel showFreePlanModal={showFreePlanModel} handleSubscription={handleCovertToFreePlan} setShowFreePlanModal={setShowFreePlanModel} />
        </div>
      )}
    </>
  );
}
