import { getListOfWhatsappTemplates, getListOfWhatsappTemplatesBySearch, createWhatsappTemplate, deleteWhatsappTemplate, syncWithFacebookWhatsappTemplates, updateWhatsappTemplate } from "../types/campaign_whatsapp_template_types";

export const getListOfWhatsappTemplatesRequest = (payload, cb) => ({
    type: getListOfWhatsappTemplates.REQUEST,
    payload,
    cb
})
export const getListOfWhatsappTemplatesSuccess = (payload) => ({
    type: getListOfWhatsappTemplates.SUCCESS,
    payload
})
export const getListOfWhatsappTemplatesFailure = (payload) => ({
    type: getListOfWhatsappTemplates.FAILURE,
    payload
})

export const getListOfWhatsappTemplatesBySearchRequest = (payload, cb) => ({
    type: getListOfWhatsappTemplatesBySearch.REQUEST,
    payload,
    cb
})
export const getListOfWhatsappTemplatesBySearchSuccess = (payload) => ({
    type: getListOfWhatsappTemplatesBySearch.SUCCESS,
    payload
})
export const getListOfWhatsappTemplatesBySearchFailure = (payload) => ({
    type: getListOfWhatsappTemplatesBySearch.FAILURE,
    payload
})

export const createWhatsappTemplateRequest = (payload, cb) => ({
    type: createWhatsappTemplate.REQUEST,
    payload,
    cb
})
export const createWhatsappTemplateSuccess = (payload) => ({
    type: createWhatsappTemplate.SUCCESS,
    payload
})
export const createWhatsappTemplateFailure = (payload) => ({
    type: createWhatsappTemplate.FAILURE,
    payload
})

export const updateWhatsappTemplateRequest = (payload, cb) => ({
    type: updateWhatsappTemplate.REQUEST,
    payload,
    cb
})
export const updateWhatsappTemplateSuccess = (payload) => ({
    type: updateWhatsappTemplate.SUCCESS,
    payload
})
export const updateWhatsappTemplateFailure = (payload) => ({
    type: updateWhatsappTemplate.FAILURE,
    payload
})


export const deleteWhatsappTemplateRequest = (payload, cb) => ({
    type: deleteWhatsappTemplate.REQUEST,
    payload,
    cb
})
export const deleteWhatsappTemplateSuccess = (payload) => ({
    type: deleteWhatsappTemplate.SUCCESS,
    payload
})
export const deleteWhatsappTemplateFailure = (payload) => ({
    type: deleteWhatsappTemplate.FAILURE,
    payload
})

export const syncWithFacebookWhatsappTemplatesRequest = (payload, cb) => ({
    type: syncWithFacebookWhatsappTemplates.REQUEST,
    payload,
    cb
})
export const syncWithFacebookWhatsappTemplatesSuccess = (payload) => ({
    type: syncWithFacebookWhatsappTemplates.SUCCESS,
    payload
})
export const syncWithFacebookWhatsappTemplatesFailure = (payload) => ({
    type: syncWithFacebookWhatsappTemplates.FAILURE,
    payload
});