import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CHECKEMAIL_REQUEST,
  CHECKEMAIL_SUCCESS,
  CHECKEMAIL_ERROR,
  SIGNUPINFO_REQUEST,
  SIGNUPINFO_SUCCESS,
  SIGNUPINFO_ERROR,
  EMAILOTP_REQUEST,
  EMAILOTP_SUCCESS,
  EMAILOTP_ERROR,
  PATCHPHONE_REQUEST,
  PATCHPHONE_SUCCESS,
  PATCHPHONE_ERROR,
  UPDATEACCOUNT_REQUEST,
  UPDATEACCOUNT_SUCCESS,
  UPDATEACCOUNT_ERROR,
  GETGOOGLEAPI_REQUEST,
  GETGOOGLEAPI_SUCCESS,
  GETGOOGLEAPI_ERROR,
  POSTGOOGLEAPI_REQUEST,
  POSTGOOGLEAPI_SUCCESS,
  POSTGOOGLEAPI_ERROR,
  VERIFYMANAGEUSER_REQUEST,
  VERIFYMANAGEUSER_SUCCESS,
  VERIFYMANAGEUSER_ERROR,
  FORGOTPASSWORD_REQUEST,
  FORGOTPASSWORD_SUCCESS,
  FORGOTPASSWORD_ERROR,
  FORGOTPASSWORDPOST_REQUEST,
  FORGOTPASSWORDPOST_SUCCESS,
  FORGOTPASSWORDPOST_ERROR,
  VERIFY_COUPONCODE_REQUEST,
  VERIFY_COUPONCODE_SUCCESS,
  VERIFY_COUPONCODE_ERROR,
} from "./types";

const initialState = {
  checkEmail: {},
  loginResponse: {},
  phoneNo: {},
  updateAccount: {},
  getGoogleAPI: {},
  postGoogleAPI: {},
  verifyManager: {},
  forgotPass: {},
  loading: "",
  isAuthorized: false,
  // you can define required variables here
};

export function LoginReducer(state = initialState, action = {}) {
  switch (action.type) {
    //couponcode verification
    case VERIFY_COUPONCODE_REQUEST:
      return {
        ...state,
      };
    case VERIFY_COUPONCODE_SUCCESS:
      return {
        ...state,
      };
    case VERIFY_COUPONCODE_ERROR:
      return {
        ...state,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loginResponse: {},
        loading: true,
        isAuthorized: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginResponse: action.payload,
        loading: false,
        isAuthorized: true,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginResponse: action.payload,
        loading: false,
        isAuthorized: false,
      };

    case CHECKEMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHECKEMAIL_SUCCESS:
      return {
        ...state,
        checkEmail: action.payload,
        loading: false,
      };
    case CHECKEMAIL_ERROR:
      return {
        ...state,
        checkEmail: action.payload,
        loading: false,
      };

    case SIGNUPINFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SIGNUPINFO_SUCCESS:
      return {
        ...state,
        checkEmail: action.payload,
        loading: false,
      };
    case SIGNUPINFO_ERROR:
      return {
        ...state,
        checkEmail: action.payload,
        loading: false,
      };

    case EMAILOTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMAILOTP_SUCCESS:
      return {
        ...state,
        checkEmail: action.payload,
        loading: false,
      };
    case EMAILOTP_ERROR:
      return {
        ...state,
        checkEmail: action.payload,
        loading: false,
      };

    case PATCHPHONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCHPHONE_SUCCESS:
      return {
        ...state,
        phoneNo: action.payload,
        loading: false,
      };
    case PATCHPHONE_ERROR:
      return {
        ...state,
        phoneNo: action.payload,
        loading: false,
      };

    case UPDATEACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATEACCOUNT_SUCCESS:
      return {
        ...state,
        updateAccount: action.payload,
        loading: false,
      };
    case UPDATEACCOUNT_ERROR:
      return {
        ...state,
        updateAccount: action.payload,
        loading: false,
      };

    case GETGOOGLEAPI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETGOOGLEAPI_SUCCESS:
      return {
        ...state,
        getGoogleAPI: action.payload,
        loading: false,
      };
    case GETGOOGLEAPI_ERROR:
      return {
        ...state,
        getGoogleAPI: action.payload,
        loading: false,
      };

    case POSTGOOGLEAPI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POSTGOOGLEAPI_SUCCESS:
      return {
        ...state,
        postGoogleAPI: action.payload,
        loading: false,
      };
    case POSTGOOGLEAPI_ERROR:
      return {
        ...state,
        postGoogleAPI: action.payload,
        loading: false,
      };

    case VERIFYMANAGEUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFYMANAGEUSER_SUCCESS:
      return {
        ...state,
        verifyManager: action.payload,
        loading: false,
      };
    case VERIFYMANAGEUSER_ERROR:
      return {
        ...state,
        verifyManager: action.payload,
        loading: false,
      };

    case FORGOTPASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGOTPASSWORD_SUCCESS:
      return {
        ...state,
        forgotPass: action.payload,
        loading: false,
      };
    case FORGOTPASSWORD_ERROR:
      return {
        ...state,
        forgotPass: action.payload,
        loading: false,
      };

    case FORGOTPASSWORDPOST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGOTPASSWORDPOST_SUCCESS:
      return {
        ...state,
        forgotPass: action.payload,
        loading: false,
      };
    case FORGOTPASSWORDPOST_ERROR:
      return {
        ...state,
        forgotPass: action.payload,
        loading: false,
      };
    //similarly need to write required cases
    default:
      return state;
  }
}
