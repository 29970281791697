import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import SelectableContext from "react-bootstrap/SelectableContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../src/_metronic/_helpers";
import { DeleteModal } from "../../../../components/modal/index";
import TableViewModel from "../models/tableView";
// import { useDispatch, useSelector } from 'react-redux';

// import PieReport from '../graphs/pieReport';
import FunnelChart from "../graphs/funnel-chart";
// import TimelineActivityGraph from '../graphs/timeline-activity';
// import BarChart from '../graphs/barchart';
// import ReportsChart from '../graphs/reports'
// import ColumnChart from '../graphs/columnchart';
// import ProgressGraph from "../graphs/ProgressBarReport"

export default function GoalContainer(props) {
  const [selectedUser, setSelectedUser] = useState("");
  const [showDeleteGoal, setShowDeleteGoal] = useState(false);
  // const [showApplyFilterModel, setShowApplyFilterModel] = useState(false);
  const [showTableViewModel, setShowTableViewModel] = useState(false);
  // const [loading, setLoading] = useState(false);

  const deleteTemplateModalProps = {
    show: showDeleteGoal,
    title: "Delete Goal",
    handleClose: () => {
      setShowDeleteGoal(false);
    },
    handleAccept: () => onDeleteAccept(),
  };

  const onEditIconClick = () => {
    props.onEditGoalClick(props.goalId);
  };
  const onDeleteIconClick = () => {
    setShowDeleteGoal(true);
  };

  const onDeleteAccept = () => {
    setShowDeleteGoal(false);
    props.onDeleteGoal(props.GoalId);
  };

  // const onApplyFilterClick = () => {
  //     setShowApplyFilterModel(true)
  // }

  // const handleApplyFilterClose = () => {
  //     setShowApplyFilterModel(false)
  // }
  // const onApplyButtonClick = () => {
  //     setShowApplyFilterModel(false)
  // }

  const onTableViewButtonClick = () => {
    setShowTableViewModel(true);
  };
  const handleTableViewClose = () => {
    setShowTableViewModel(false);
  };

  // const renderGraph = (type, data, chartId) => {
  //     return <FunnelChart won={data} chartId={chartId} />;
  //     switch (type) {
  //         case "deal performance Goal":
  //             return <ReportsChart won={data} chartId={chartId} />;
  //         case "deal conversion Goal":
  //             return <FunnelChart won={data} chartId={chartId} />;
  //         case "deal progress Goal":
  //             return <ProgressGraph won={data} chartId={chartId} />;
  //         case "activity activity-performance Goal":
  //             return <TimelineActivityGraph won={data} chartId={chartId} />;
  //         default:
  //             return <div>No graph selected</div>
  //     }
  // }

  return (
    <div className="card card-custom width-inherit height-inherit" key={props.key}>
      <div className="card-header align-items-center border-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark d-inline-block text-truncate w-200px-mn">{props.title}</span>
        </h3>
        <div>
          {/* <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Pie Chart</Tooltip>}   >
                        <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={() => { }} >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <i className="bi bi-pie-chart-fill"></i>
                            </span>
                        </span>
                    </OverlayTrigger>

                    <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Bar Chart</Tooltip>} >
                        <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => { }} >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <i className="bi bi-file-bar-graph-fill"></i>
                            </span>
                        </span>
                    </OverlayTrigger> */}
        </div>
        <div className="card-toolbar">
          <div className="mr-0">
            <SelectableContext.Provider value={false}>
              <Dropdown className="mt-1 w-100">
                <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
                  {selectedUser === "" ? "Select User" : selectedUser}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100-mn">
                  <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                    {props.userOptions.map((option, index) => (
                      <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => setSelectedUser(option.label)}>
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </PerfectScrollbar>
                </Dropdown.Menu>
              </Dropdown>
            </SelectableContext.Provider>
          </div>
          <div>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Table View</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={() => onTableViewButtonClick()}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="bi bi-table"></i>
                </span>
              </span>
            </OverlayTrigger>
            <TableViewModel showTableViewModel={showTableViewModel} handleTableViewClose={handleTableViewClose} />
          </div>
          {/* <div >
                        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Apply Filter</Tooltip>}   >
                            <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => onApplyFilterClick()} >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <i className="bi bi-funnel"></i>
                                </span>
                            </span>

                        </OverlayTrigger>
                        <ApplyFilterModel
                            showApplyFilterModel={showApplyFilterModel}
                            handleApplyFilterClose={handleApplyFilterClose}
                            onApplyButtonClick={onApplyButtonClick}
                        />
                    </div> */}
          <div>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => onEditIconClick()}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                </span>
              </span>
            </OverlayTrigger>
          </div>
          <div className="dropdown-inline dropdown">
            <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete the Goal?</DeleteModal>
            <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={() => onDeleteIconClick(props.goalId)}>
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                </span>
              </span>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="card-body pt-4 w-100 h-100">
        <FunnelChart chartId={props.goalId} />;
        {/* {
                    !loading || graphData.length > 0 ?
                        <>
                            {renderGraph(graphData[0].title, graphData[0].graphDetails, props.goalId)}
                        </> :
                        <div><GraphSkeleton /></div>
                } */}
      </div>
    </div>
  );
}
