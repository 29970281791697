import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { BlueGradientButton, GreenGradientButton } from "../../../components/button";

const warningMessages = {
  LEAD: "",
  PEOPLE: "",
  COMPANY: "Company Name is mandatory field of Company Data Import!",
  PRODUCT: "Product Name is mandatory field of Product Data Import!",
  MARKETING: "Email or Phone is mandatory field of Marketing List Import",
};

export default function ImportFooter({ dataType, importType, currentStep, setCurrentStep, setShowImportModal, nextStepSmart, handleSampleCSV, handleImport, loading }) {
  if (currentStep === 1)
    return (
      <div className="d-flex flex-row justify-content-end">
        <Button variant="secondary" size="sm" onClick={() => setShowImportModal(false)} className="mr-12px">
          Close
        </Button>
        <GreenGradientButton size="sm" onClick={() => setCurrentStep(3)}>
          Continue
        </GreenGradientButton>
      </div>
    );
  else if (currentStep === 2)
    return (
      <div className="d-flex flex-row justify-content-end gap-10px">
        <Button variant="secondary" size="sm" onClick={() => setCurrentStep(1)}>
          Back
        </Button>
        <GreenGradientButton size="sm" onClick={() => setCurrentStep(3)}>
          Continue
        </GreenGradientButton>
      </div>
    );
  else if (currentStep === 3) {
    if (importType === "default") {
      return (
        <div className="w-100 d-flex flex-row justify-content-between">
          <div></div>
          <div className="d-flex flex-row justify-content-end gap-10px">
            <Button variant="light" size="sm" onClick={() => handleSampleCSV()}>
              Download Sample File
            </Button>
            <Button variant="secondary" size="sm" onClick={() => setCurrentStep(1)}>
              Back
            </Button>
            <GreenGradientButton size="sm" onClick={() => setCurrentStep(4)}>
              Continue
            </GreenGradientButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-100 d-flex flex-stack">
          <div>
            <Button variant="outline-secondary" size="sm" onClick={() => handleSampleCSV()}>
              Download CSV Format
            </Button>
          </div>
          <div className="d-flex flex-row justify-content-end gap-10px">
            <Button variant="secondary" size="sm" onClick={() => setCurrentStep(1)}>
              Back
            </Button>
            <GreenGradientButton size="sm" onClick={() => nextStepSmart()}>
              Continue
            </GreenGradientButton>
          </div>
        </div>
      );
    }
  } else
    return (
      <div className="d-flex flex-stack w-100">
        <div className="border-radius-5px bg-fff4de my-0 mx-30px font-size-14px p-5px p-10px text-ffa800">
          {warningMessages[dataType.toUpperCase()] && (
            <>
              <i className="bi bi-exclamation-triangle pr-10px text-color-inherit"></i>
              {warningMessages[dataType.toUpperCase()]}
            </>
          )}
        </div>
        <div className="d-flex flex-row gap-10px">
          <Button variant="secondary" size="sm" onClick={() => setCurrentStep(3)}>
            Back
          </Button>
          <BlueGradientButton size="sm" onClick={() => handleImport()}>
            {loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
            Upload
          </BlueGradientButton>
        </div>
      </div>
    );
}
