import { getListOfActivitySavedFilters } from "../types/activity.types";

const initState = {
  loading: false,
  list: [],
};

export default function activitySavedFiltersReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
      };
    case getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
