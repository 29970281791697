import React from "react";
import { NavLink } from "react-router-dom";

export function SubheaderNav(props) {
  const onClick = (name) => {
    if (props.onClick) {
      props.onClick(name);
    }
  };
  return (
    <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
      {props.links.map((link, index) => (
        <div key={index} className="menu-item me-lg-1">
          {link.to ? (
            <NavLink onClick={() => onClick(link.name)} className={`menu-link py-3 ${props.active === link.name ? "active" : ""}`} to={link.to}>
              {(link.icon || link.svg) && (
                <span className="menu-icon mr-2">
                  <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
                </span>
              )}
              <span className="menu-title">{link.name}</span>
            </NavLink>
          ) : (
            <div onClick={() => onClick(link.name)} className={`menu-link py-3 ${props.active === link.name ? "active" : ""}`}>
              {(link.icon || link.svg) && (
                <span className="menu-icon mr-2">
                  <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
                </span>
              )}
              <span className="menu-title">{link.name}</span>
            </div>
          )}
        </div>
      ))}
      {props.children}
    </div>
  );
}
