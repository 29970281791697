import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";

import * as videoCategoryTypes from "../types/category.types";
import * as videoTypes from "../types/video.types";

import * as videoCategoryActions from "../actions/category.action";
import * as videoActions from "../actions/video.action";

import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";

function* getListOfVideos(action) {
  try {
    const response = yield call(api.get, "/api/crm/learn/video", action.payload);
    if (response.status === 200) {
      yield put(videoActions.getListOfVideosSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoActions.getListOfVideosError(response));
    } else {
      yield put(videoActions.getListOfVideosError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoActions.getListOfVideosError(e));
  }
}

function* getSelectedVideo(action) {
  try {
    const response = yield call(api.get, `/api/crm/learn/video/${action.payload.type}/${action.payload.id}`);
    if (response.status === 200) {
      //   dispatchSnackbarSuccess("Video created successfully!");
      yield put(videoActions.getSelectedVideoSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoActions.getSelectedVideoError(response));
    } else {
      yield put(videoActions.getSelectedVideoError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoActions.getSelectedVideoError(e));
  }
}

function* createVideos(action) {
  try {
    const response = yield call(api.post, "/api/crm/learn/video", action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Video created successfully!");
      yield put(videoActions.createVideoSuccess(response.data));
      yield put(videoActions.getListOfVideosRequest(action.payload.filter));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoActions.createVideoError(response));
    } else {
      yield put(videoActions.createVideoError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoActions.createVideoError(e));
  }
}

function* editVideos(action) {
  try {
    const response = yield call(api.put, `/api/crm/learn/video/${action.payload.id}`, action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Video edited successfully!");
      yield put(videoActions.editVideoSuccess(response.data));
      yield put(videoActions.getListOfVideosRequest(action.payload.filter));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoActions.editVideoError(response));
    } else {
      yield put(videoActions.editVideoError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoActions.editVideoError(e));
  }
}

function* deleteVideos(action) {
  try {
    const response = yield call(api.delete, `/api/crm/learn/video/${action.payload.data.id}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Video deleted successfully!");
      yield put(videoActions.deleteVideoSuccess(response.data));
      yield put(videoActions.getListOfVideosRequest(action.payload.filter));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoActions.deleteVideoError(response));
    } else {
      yield put(videoActions.deleteVideoError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoActions.deleteVideoError(e));
  }
}

/**Categories */
function* getListOfVideoCategories(action) {
  try {
    const response = yield call(api.get, "/api/crm/learn/category", action.payload);
    if (response.status === 200) {
      yield put(videoCategoryActions.getListOfVideoCategoriesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoCategoryActions.getListOfVideoCategoriesError(response));
    } else {
      yield put(videoCategoryActions.getListOfVideoCategoriesError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoCategoryActions.getListOfVideoCategoriesError(e));
  }
}

function* createVideoCategory(action) {
  try {
    const response = yield call(api.post, "/api/crm/learn/category", action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Video category created successfully!");
      yield put(videoCategoryActions.createVideoCategorySuccess(response.data));
      yield put(videoCategoryActions.getListOfVideoCategoriesRequest(action.payload.filter));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoCategoryActions.createVideoCategoryError(response));
    } else {
      yield put(videoCategoryActions.createVideoCategoryError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoCategoryActions.createVideoCategoryError(e));
  }
}

function* editVideoCategory(action) {
  try {
    const response = yield call(api.put, `/api/crm/learn/category/${action.payload.id}`, action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Video category edited successfully!");
      yield put(videoCategoryActions.editVideoCategorySuccess(response.data));
      yield put(videoCategoryActions.getListOfVideoCategoriesRequest(action.payload.filter));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoCategoryActions.editVideoCategoryError(response));
    } else {
      yield put(videoCategoryActions.editVideoCategoryError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoCategoryActions.editVideoCategoryError(e));
  }
}

function* deleteVideoCategory(action) {
  try {
    const response = yield call(api.delete, `/api/crm/learn/category/${action.payload.data.id}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Video category deleted successfully!");
      yield put(videoCategoryActions.deleteVideoCategorySuccess(response.data));
      yield put(videoCategoryActions.getListOfVideoCategoriesRequest(action.payload.filter));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(videoCategoryActions.deleteVideoCategoryError(response));
    } else {
      yield put(videoCategoryActions.deleteVideoCategoryError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(videoCategoryActions.deleteVideoCategoryError(e));
  }
}

export default function* ActivityLossReasonSaga() {
  //categories
  yield takeLatest(videoCategoryTypes.getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_REQUEST, getListOfVideoCategories);
  yield takeLatest(videoCategoryTypes.createVideoCategory.CREATE_VIDEO_CATEGORY_REQUEST, createVideoCategory);
  yield takeLatest(videoCategoryTypes.editVideoCategory.EDIT_VIDEO_CATEGORY_REQUEST, editVideoCategory);
  yield takeLatest(videoCategoryTypes.deleteVideoCategory.DELETE_VIDEO_CATEGORY_REQUEST, deleteVideoCategory);

  //video
  yield takeLatest(videoTypes.getListOfVideos.GET_LIST_OF_VIDEOS_REQUEST, getListOfVideos);
  yield takeLatest(videoTypes.getSelectedVideo.GET_SELECTED_VIDEO_REQUEST, getSelectedVideo);
  yield takeLatest(videoTypes.createVideo.CREATE_VIDEO_REQUEST, createVideos);
  yield takeLatest(videoTypes.editVideo.EDIT_VIDEO_REQUEST, editVideos);
  yield takeLatest(videoTypes.deleteVideo.DELETE_VIDEO_REQUEST, deleteVideos);
}
