/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";
import { Pagination, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomSwitch from "../../../components/custom_form/switch";
import { CloneIconButton, CodeIconButton, DeleteIconButton, EditIconButton } from "../../../components/button/icon-action-buttons";
import InlineStyled from "../../../components/inline-styled/inline-styled";

const CustomPagination = (active, setActive, pages, setChecked, paginationNumber, setPaginationNumber, rows) => {
  var items = [];
  if (pages <= 7) {
    for (let number = 1; number <= pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => {
            setActive(number);
            setChecked([]);
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
  } else {
    items.push(
      <Pagination.First
        key={0}
        onClick={() => {
          setActive(1);
          setChecked([]);
        }}
      />,
    );
    items.push(
      <Pagination.Prev
        onClick={() => {
          if (active !== 1) setActive(active - 1);
        }}
      />,
    );
    if (active === 1 || active === 2) {
      items.push(
        <Pagination.Item
          key={1}
          active={active === 1}
          onClick={() => {
            setActive(1);
            setChecked([]);
          }}
        >
          1
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={2}
          active={active === 2}
          onClick={() => {
            setActive(2);
            setChecked([]);
          }}
        >
          2
        </Pagination.Item>,
      );
      // items.push(<Pagination.Item key={3} active={false} onClick={() => { setActive(3); setChecked([]) }}>3</Pagination.Item>)
      // items.push(<Pagination.Item key={4} active={false} onClick={() => { setActive(4); setChecked([]) }}>4</Pagination.Item>)
    } else {
      // items.push(<Pagination.Ellipsis />)
      if (active === pages) {
        // items.push(<Pagination.Item key={active - 3} active={false} onClick={() => { setActive(active - 3); setChecked([]) }}>{active - 3}</Pagination.Item>)
        items.push(
          <Pagination.Item
            key={active - 2}
            active={false}
            onClick={() => {
              setActive(active - 2);
              setChecked([]);
            }}
          >
            {active - 2}
          </Pagination.Item>,
        );
      } else if (active === pages - 1)
        items.push(
          <Pagination.Item
            key={active - 2}
            active={false}
            onClick={() => {
              setActive(active - 2);
              setChecked([]);
            }}
          >
            {active - 2}
          </Pagination.Item>,
        );
      items.push(
        <Pagination.Item
          key={active - 1}
          active={false}
          onClick={() => {
            setActive(active - 1);
            setChecked([]);
          }}
        >
          {active - 1}
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={active}
          active={true}
          onClick={() => {
            setActive(active);
            setChecked([]);
          }}
        >
          {active}
        </Pagination.Item>,
      );
      if (active !== pages) {
        items.push(
          <Pagination.Item
            key={active + 1}
            active={false}
            onClick={() => {
              setActive(active + 1);
              setChecked([]);
            }}
          >
            {active + 1}
          </Pagination.Item>,
        );
      }
    }
    if (active !== pages - 1 && active !== pages)
      // items.push(<Pagination.Ellipsis />)
      items.push(
        <Pagination.Next
          onClick={() => {
            if (active !== pages) setActive(active + 1);
          }}
        />,
      );
    items.push(
      <Pagination.Last
        key={pages + 1}
        onClick={() => {
          setActive(pages);
          setChecked([]);
        }}
      />,
    );
  }
  return (
    <div className="d-flex flex-row align-items-center gap-15px mt-5px">
      {pages === 1 ? null : (
        <ul id="table_pagination" className="d-flex flex-row pl-0 z-index-0 m-0 list-unstyled border-radius-7px">
          {items}
        </ul>
      )}
    </div>
  );
};

export default function NormalTable(props) {
  const getData = (data, key) => {
    if (key === "tags") {
      if (Array.isArray(data.tags)) {
        return data.tags.map((eachTag, index) => {
          return (
            <InlineStyled as="span" key={index} styles={{ backgroundColor: eachTag.colorCode }} className="text-333 badge badge-dark tag-ellipsis rounded-pill m-0 overflow-hidden text-overflow-ellipsis">
              {eachTag.name}
            </InlineStyled>
          );
        });
      } else {
        return <></>;
      }
    } else if (key === "status") {
      return (
        <CustomSwitch
          label=""
          id={data._id}
          checked={data.isEnabled}
          handleChange={(e) => {
            props.onStatusChange(data._id, e.target.checked);
          }}
        />
      );
    } else if (key === "createdAt") {
      if (data[key]) {
        return moment(data[key]).format("MMM DD, h:mm a");
      } else return data[key];
    } else return data[key];
  };

  return (
    <div className={`card card-custom`}>
      <div className="card-body py-3 px-3">
        {props.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="table-responsive pl-3 pt-2 overflow-y-auto">
              <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
                <table className="table align-middle table-row-dashed dataTable no-footer font-size-14px">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      {props.columns &&
                        props.columns.map((col, index) => (
                          <InlineStyled
                            as="th"
                            key={index}
                            className="text-black bg-white w-18 cursor-pointer text-nowrap position-sticky top-0 pt-0"
                            styles={{
                              minWidth: `${100 / (props.columns.length + 1)}%`,
                            }}
                          >
                            <div className="pt-0 d-flex flex-row w-100 h-100 align-items-center gap-5px">{col}</div>
                          </InlineStyled>
                        ))}
                      <InlineStyled
                        as="th"
                        className="pt-0 pr-10px text-black bg-white w-10 text-center cursor-pointer text-nowrap position-sticky top-0 text-black"
                        styles={{
                          minWidth: `${100 / (props.columns.length + 1)}%`,
                        }}
                      >
                        <div className="pt-0 pr-10px mr-2 w-100 h-100 gap-5px">Actions</div>
                      </InlineStyled>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data &&
                      props.data.map((data, rowIndex) => {
                        return (
                          <tr key={rowIndex}>
                            {props.dataHead.map((each, index) => {
                              return (
                                <td className="text-nowrap" key={index}>
                                  <div className="d-flex flex-row flex-stack">{getData(data, each)}</div>
                                </td>
                              );
                            })}
                            <td className="text-center text-nowrap">
                              <CloneIconButton onClick={() => props.onClone(data._id)} />
                              <CodeIconButton onClick={() => props.onCodeClick(data._id)} />
                              <EditIconButton onClick={() => props.onEditClick(data._id)} />
                              <DeleteIconButton onClick={() => props.onDeleteClick(data._id)} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </PerfectScrollbar>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-15px pt-7px">
              <p className="mt-5px mb-0 font-size-14px">
                Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
              </p>
              {props.pagination ? CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), props.setChecked, props.limit, props.setLimit, props.count) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
