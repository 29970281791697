export const modulesNamesAndPath = [
  {
    name: "People",
    includePath: "/prospects/people",
  },
  {
    name: "Companies",
    includePath: "/prospects/companies",
  },
  {
    name: "Products",
    includePath: "/products",
  },
  {
    name: "Dashboard",
    includePath: "/insights",
  },
  {
    name: "Leadbot",
    includePath: "/lead-bot",
  },
  {
    name: "Automation",
    includePath: "/automation",
  },
  {
    name: "Import",
    includePath: "/import",
  },
  {
    name: "Export",
    includePath: "/export",
  },
  {
    name: "Profile Settings",
    includePath: "/settings/profile",
  },
  {
    name: "Admin Settings",
    includePath: "/settings/account",
  },
  {
    name: "Billing",
    includePath: "/settings/billing/subscription",
  },
  {
    name: "Wallet",
    includePath: "/settings/billing/wallet",
  },
  {
    name: "Admin",
    includePath: "/admin",
  },
  {
    name: "Learn",
    includePath: "/learn/public",
  },
  {
    name: "Admin",
    includePath: "/admin",
  },
  {
    name: "Campaign - Summary Report",
    includePath: "/campaign/reports/summary-report",
  },
  {
    name: "Campaign - Logs",
    includePath: "/campaign/reports/logs",
  },
  {
    name: "Campaign - Download Report",
    includePath: "/campaign/reports/download-report",
  },
  {
    name: "Campaign - Smart Shortlink",
    includePath: "/campaign/smart-short-link",
  },
  {
    name: "Campaign - Block Contacts",
    includePath: "/campaign/block-contact",
  },
  {
    name: "Campaign",
    includePath: "/campaign",
  },
  {
    name: "Learn",
    includePath: "/learn/company",
  },
  {
    name: "Leads",
    includePath: "/prospects/leads",
  },
  {
    name: "Deals",
    includePath: "/deal",
  },
  {
    name: "Home",
    includePath: "/activity",
  },
  {
    name: "Email Validation",
    includePath: "/settings/integrations/email-validation",
  },
  {
    name: "Email Finder",
    includePath: "/settings/integrations/email-finder",
  },
  {
    name: "Integrations",
    includePath: "/settings/integrations",
  },
];
