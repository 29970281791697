import React from "react";
import { useSelector } from "react-redux";
// import { BlueGradientButton } from "../../../../../components/button";
import RadioSelector from "../../../../../components/custom-inputs/radio-selector";
import TagSelect from "../../../../../components/custom_form/tags";
import SavedViewsDropdown from "../../../../../components/filter/saved-view-dropdown";
import FilterForm from "../../../../../components/filter/smart-view-filter/filter-condition-form";
import { Spinner } from "react-bootstrap";
import { moduleMapping } from "../../../constants";
import { Form } from "react-bootstrap";

const options = ["Existing Filters", "Create New Segment"];

export default function AudienceFilter(props) {
  const leadFilterStore = useSelector((state) => state.Leads.leadFilters);
  const peopleFilterStore = useSelector((state) => state.People.peopleFilters);
  const marketingFiltersStore = useSelector((store) => store.campaign.marketing.marketingList.filters);

  const conditionsStore = useSelector((store) => store.workflowReducer.conditions);

  const handleSelect = (data) => {
    data["_id"] === props.filterId ? props.setFilterId("") : props.setFilterId(data["_id"]);
  };

  const setValue = (value) => {
    props.tags[0] === value[0] ? props.setTags([]) : props.setTags(value);
  };

  const getFilterList = () => {
    if (props.audienceType === "lead") {
      return leadFilterStore.list;
    } else if (props.audienceType === "people") {
      return peopleFilterStore.list;
    } else if (props.audienceType === "marketing_list") {
      return marketingFiltersStore.list;
    } else {
      return [];
    }
  };

  return (
    <div className="card mt-8 p-6">
      <Form.Group className="mb-0">
        <Form.Label>Select Target Audience: </Form.Label>
        <RadioSelector options={options} value={props.filterType} setValue={props.setFilterType} />
      </Form.Group>
      <div>
        {props.filterType === "Existing Filters" ? (
          <div className="d-flex gap-3 justify-content-start">
            <div>
              <TagSelect
                page={moduleMapping[props.audienceType].page}
                pageSub={moduleMapping[props.audienceType].pageSub}
                addTag={() => {}}
                editTag={() => {}}
                deleteTag={() => {}}
                field={{ fieldName: "Tags", apiKeyName: "tags" }}
                value={props.tags.length === 0 ? [""] : props.tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                setValue={(value) => setValue(value)}
                addTextHide={true}
                hideAddText={true}
                isUsedAsFilter={true}
              />
            </div>
            <SavedViewsDropdown
              loading={leadFilterStore.loading || peopleFilterStore.loading || marketingFiltersStore.loading}
              customFilters={getFilterList()}
              handleSelect={handleSelect}
              selectedFilterId={props.filterId}
            />
          </div>
        ) : (
          <div>
            <FilterForm
              moduleName={props.moduleName}
              conditionsStore={conditionsStore}
              criteriaConditions={props.criteriaConditions}
              setCriteriaConditions={props.setCriteriaConditions}
              pattern={props.pattern}
              setPattern={props.setPattern}
              scrollToBottom={() => {}}
            />
          </div>
        )}
      </div>

      <div className="d-flex flex-stack mt-4">
        <span>
          Total Audience:
          {props.audienceCountLoading ? <Spinner animation="border" variant="primary" className="ml-2 mr-10px w-16px h-16px"></Spinner> : <span className="ml-2">{props.audienceCount}</span>}
        </span>
      </div>
    </div>
  );
}
