import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productSearchRequest,
  // productDropdownAddRequest,productDropdownAddClear
  // companiesDropdownAddRequest, companiesDropdownAddClear
} from "../../containers/products/store/actions/actions";
import {
  dispatchSnackbarError,
  // dispatchSnackbarSuccess
} from "../../utils/toaster";
import SearchSelect from "./searchSelect";
// import { useDispatch } from 'react-redux';

export default function ProductSelect({ field, value, setValue, dontShowAdd, label }) {
  const [newProductName, setNewProductName] = useState("");
  const productStore = useSelector((state) => state.Products.productDropdownList);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productSearchRequest(""));
  }, [dispatch]);

  useEffect(() => {
    setSelected({ value: value, label: label });
  }, [value, label]);

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < productStore.list.length; i++) temp.push({ value: productStore.list[i]["_id"], label: productStore.list[i]["productName"] });
    setOptions(temp);
  }, [productStore, newProductName, dontShowAdd]);

  const handleSave = () => {
    // setIsNew(false)
    if (newProductName.trim() === "") {
      dispatchSnackbarError("Name can not be blank!");
      return;
    } else {
      setValue(newProductName, []);
    }
    setSelected({ label: newProductName, value: newProductName });
  };

  const setValueA = (a) => {
    for (var i = 0; i < productStore.list.length; i++) {
      if (productStore.list[i]._id === a) {
        setValue(a, productStore.list[i]);
      }
    }
  };

  return (
    <>
      <SearchSelect options={options} selected={selected} setValue={setValueA} handleSave={handleSave} setSearch={setNewProductName} field={field} loading={productStore.loading} request={productSearchRequest} />
    </>
  );
}
