export const GET_BILLING_PRICES_REQUEST = "GET_BILLING_PRICES_REQUEST";
export const GET_BILLING_PRICES_SUCCESS = "GET_BILLING_PRICES_SUCCESS";
export const GET_BILLING_PRICES_ERROR = "GET_BILLING_PRICES_ERROR";

export const BILLING_PLAN_CHECKOUT_REQUEST = "BILLING_PLAN_CHECKOUT_REQUEST";
export const BILLING_PLAN_CHECKOUT_SUCCESS = "BILLING_PLAN_CHECKOUT_SUCCESS";
export const BILLING_PLAN_CHECKOUT_ERROR = "BILLING_PLAN_CHECKOUT_ERROR";

export const BILLING_CREATE_CUSTOMER_REQUEST = "BILLING_CREATE_CUSTOMER_REQUEST";
export const BILLING_CREATE_CUSTOMER_SUCCESS = "BILLING_CREATE_CUSTOMER_SUCCESS";
export const BILLING_CREATE_CUSTOMER_ERROR = "BILLING_CREATE_CUSTOMER_ERROR";

export const BILLING_CUSTOMER_PORTAL_REQUEST = "BILLING_CUSTOMER_PORTAL_REQUEST";
export const BILLING_CUSTOMER_PORTAL_SUCCESS = "BILLING_CUSTOMER_PORTAL_SUCCESS";
export const BILLING_CUSTOMER_PORTAL_ERROR = "BILLING_CUSTOMER_PORTAL_ERROR";

export const GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST = "GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST";
export const GET_BILLING_SUBSCRIPTIONDETAILS_SUCCESS = "GET_BILLING_SUBSCRIPTIONDETAILS_SUCCESS";
export const GET_BILLING_SUBSCRIPTIONDETAILS_ERROR = "GET_BILLING_SUBSCRIPTIONDETAILS_ERROR";

export const GET_ACCOUNT_BILLING_REQUEST = "GET_ACCOUNT_BILLING_REQUEST";
export const GET_ACCOUNT_BILLING_SUCCESS = "GET_ACCOUNT_BILLING_SUCCESS";
export const GET_ACCOUNT_BILLING_ERROR = "GET_ACCOUNT_BILLING_ERROR";
export const GET_ACCOUNT_BILLING_REQUEST_AFTER = "GET_ACCOUNT_BILLING_REQUEST_AFTER";

export const GET_INVOICESLIST_REQUEST = "GET_INVOICESLIST_REQUEST";
export const GET_INVOICESLIST_SUCCESS = "GET_INVOICESLIST_SUCCESS";
export const GET_INVOICESLIST_ERROR = "GET_INVOICESLIST_ERROR";
export const GET_INVOICESLIST_REQUEST_AFTER = "GET_INVOICESLIST_REQUEST_AFTER";

export const CANCEL_RENEW_SUBSCRIPTION_REQUEST = "CANCEL_RENEW_SUBSCRIPTION_REQUEST";
export const CANCEL_RENEW_SUBSCRIPTION_SUCCESS = "CANCEL_RENEW_SUBSCRIPTION_SUCCESS";
export const CANCEL_RENEW_SUBSCRIPTION_ERROR = "CANCEL_RENEW_SUBSCRIPTION_ERROR";

export const asssignFreePlan = {
  ASSIGN_FREE_PLAN_REQUEST: "ASSIGN_FREE_PLAN_REQUEST",
  ASSIGN_FREE_PLAN_SUCCESS: "ASSIGN_FREE_PLAN_SUCCESS",
  ASSIGN_FREE_PLAN_ERROR: "ASSIGN_FREE_PLAN_ERROR",
};
