import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { EditableTable } from "../../components/table";
import { Spinner } from "react-bootstrap";
import { ColumnFilter } from "../../components/column_filter";
import { DeleteModal } from "../../components/modal";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton } from "../../components/button";
import * as actions from "./store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "../../components/custom_form";
import { NavLink, useParams } from "react-router-dom";
import BulkEditForm from "../../components/custom_form/bulkEdit";
import { ownersRequest } from "../customers/store/actions/actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { MergeModal } from "../../components/merge";
import { useHistory } from "react-router-dom";
import ContentWrapper from "../Wrapper";
import SmartViewFilter from "../../components/filter/smart-view-filter";
import OwnerFilter from "../../components/filter/owner-filter";
import TagSelect from "../../components/custom_form/tags";
import SidePannelDetailed from "../../components/side-pannel-detailed";

//actions
import * as LeadActions from "../leads/store/actions/actions";
import * as CompanyActions from "../companies/store/actions/actions";
import * as CompanyOperationActions from "./store/actions/company-operation.actions";
import * as ProductActions from "../products/store/actions/actions";
import * as insightActions from "../insights/actions";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { DeleteIconButton } from "../../components/button/icon-action-buttons";
import { SecondaryButton } from "../../components/button/common-buttons";
import * as TemplateActions from "../campaign/store/actions/campaign_templates_actions";
import OptionsDropdown from "../../components/filter/import-export-dropdown";
import SelectAll from "../../components/filter/select-all";
import AdvancedSubHeaderNav from "../../components/subheader/advancedSubHeaderNav";

export default function Companies() {
  const links = [
    {
      name: "Active Leads",
      to: "/prospects/leads/active",
      type: "split-dropdown",
      icon: "bi bi-bullseye",
      options: [
        { name: "Active Leads", to: "/prospects/leads/active", target: "_self", icon: "bi bi-bullseye" },
        { name: "Junk Leads", to: "/prospects/leads/junk", target: "_self", icon: "bi bi-recycle" },
      ],
      subTabNames: ["Active Leads", "Junk Leads"],
    },
    { name: "People", to: "/prospects/people", icon: "bi bi-person", type: "text" },
    { name: "Companies", to: "/prospects/companies", icon: "bi bi-building", type: "text" },
  ];
  const tabName = "Companies";
  const tabIcon = "bi bi-building";
  const [showColumns, setShowColumns] = useState([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const fieldStore = useSelector((state) => state.Companies.companyFields);
  const addFieldStore = useSelector((state) => state.Companies.companyAddFields);
  const filterStore = useSelector((state) => state.Companies.companyFilters);
  const activeFilterStore = useSelector((state) => state.Companies.companyFilters.activeFilter);
  const listStore = useSelector((state) => state.Companies.companyList);
  const errorStore = useSelector((state) => state.Companies.error);
  const successStore = useSelector((state) => state.Companies.success);
  const loadingStore = useSelector((state) => state.Companies.loadingData);
  const popupStore = useSelector((state) => state.Companies.companyPopup);
  const [addFormData, setAddFormData] = useState({});
  const [bulkEditData, setBulkEditData] = useState({});
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const page = "Companies";
  const pageSub = "company";
  const sidePanelType = "Company";
  const [showDelete, setShowDelete] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "company",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
  });
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);

  //sidepannel states
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePannelCompanyId, setSidePannelCompanyId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [linkedModuleId, setLinkedModuleId] = useState("");
  const [linkedModuleName, setLinkedModuleName] = useState("");

  const history = useHistory();
  const params = useParams();

  //filters
  const [virtualFilter, setVirtualFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");
  const [filterId, setFilterId] = useState("");

  // delete all
  const [isAllSelected, setIsAllSelected] = useState(false);

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      limit: limit,
      tagId: tags,
      owner: owner,
      filterId: filterId,
      ...sortKeys(),
    };
    if (!_.isEmpty(virtualFilter)) {
      payload["filter"] = JSON.stringify(virtualFilter);
    }
    dispatch(actions.companiesRequest(payload));
    dispatch(actions.loadingDataUpdateRequest(payload));
  }, [dispatch, pageNo, limit, tags, owner, virtualFilter, filterId, sortKeys]);

  useEffect(() => {
    dispatch(actions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(ownersRequest());
    dispatch(actions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));
    dispatch(actions.companyAddFieldsRequest({ fieldType: "COMPANY" }));
    dispatch(actions.companyFiltersRequest());

    //common api Calls
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));

    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (params.linkedId && params.moduleName) {
      setLinkedModuleId(params.linkedId);
      setLinkedModuleName(params.moduleName);
      setSidePannelCompanyId("");
      setOpenPanel(true);
    } else if (params.companyId) {
      setSidePannelCompanyId(params.companyId);
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(true);
    } else {
      setSidePannelCompanyId("");
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    if (!listStore.loading && listStore.list.length === 0 && listStore.count > 0) {
      setPageNo(Math.ceil(listStore.count / limit));
    }
  }, [listStore.loading, listStore.list, listStore.count, limit]);

  useEffect(() => {
    if (listStore.list) {
      const _data = listStore.list.map((each) => {
        return { module: "people", id: each._id };
      });
      setNextPreviousData(_data);
    }
  }, [listStore.list]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("companyOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("companyOwner", owner);
    else localStorage.removeItem("companyOwner");
  }, [owner]);

  const updateFieldPriority = (data) => {
    dispatch(actions.fieldUpdatePriorityRequest({ ...data, fieldType: "COMPANY" }));
  };

  const showHideField = (data) => {
    dispatch(actions.fieldShowHideRequest(data));
  };

  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["selectedColumns"] = showColumns;
      dispatch(
        actions.companyFilterAddRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        actions.companyFilterAddRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData({
      filterModule: "company",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
      temp["selectedColumns"] = showColumns;
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    if (temp["id"] && filterId === temp["id"]) dispatch(actions.clearFilter());
    dispatch(
      actions.companyFilterEditRequest(temp, (data) => {
        if (data.status && temp.id === filterId) applyFilter(filterId);
      }),
    );
    setAddFilterFormData({
      filterModule: "company",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const favoriteFilter = (data) => {
    dispatch(actions.companyFilterFavoriteRequest(data));
  };

  const deleteFilter = (id) => {
    if (activeFilterStore && filterId === id) dispatch(actions.clearFilter());
    dispatch(actions.companyFilterDeleteRequest(id));
    setAddFilterFormData({
      filterModule: "company",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
  };
  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
    dispatch(actions.clearFilter());
  };

  const addTag = (name, colorCode) => {
    dispatch(actions.companyTagsAddRequest({ name: name, colorCode: colorCode, type: "company" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(actions.companyTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(actions.companyTagsDeleteRequest(id));
  };

  const handleEditCompany = (combinedId, data) => {
    dispatch(actions.companyCellEditRequest({ editData: data, loadingData: loadingStore, cellId: combinedId }));
  };

  const handleAddCompany = (temp) => {
    dispatch(actions.companyAddRequest({ companyData: temp, loadingData: loadingStore }));
    setShowAdd(false);
    setAddFormData({});
  };

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  const handleBulkEdit = (data) => {
    if (isAllSelected) {
      const payload = {
        peopleData: {
          updateData: data,
          ids: checked,
          isAllSelected: true,
          filter: loadingStore,
        },
        loadingData: loadingStore,
      };
      dispatch(CompanyOperationActions.bulkEditCompanyV2Request(payload));
      setShowBulkEdit(false);
      setBulkEditData({});
      setChecked([]);
      setIsAllSelected(false);
    } else {
      dispatch(actions.companyBulkEditRequest({ companyData: { ...data, ids: checked }, loadingData: loadingStore }));
      setShowBulkEdit(false);
      setBulkEditData({});
    }
  };

  const handleDeleteCompanies = () => {
    if (params.companyId) {
      dispatch(actions.companyDeleteRequest({ companyIds: { ids: [params.companyId] }, loadingData: loadingStore }));
    } else if (isAllSelected && checked.length > 0) {
      dispatch(CompanyOperationActions.bulkDeleteCompanyV2Request({ companyIds: { ids: checked, filter: loadingStore, isAllSelected: true }, loadingData: loadingStore }));
      setChecked([]);
    } else {
      dispatch(actions.companyDeleteRequest({ companyIds: { ids: checked }, loadingData: loadingStore }));
      setChecked([]);
    }
    setShowDelete(false);
    setSidePannelCompanyId("");
    setOpenPanel(false);
  };

  const handleMerge = (merged) => {
    dispatch(actions.companiesMergeRequest({ data: merged, loadingData: loadingStore }));
    setChecked([]);
  };

  const tableProps = {
    columns: fieldStore.list,
    data: listStore.list,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: (a) => {
      setChecked(a);
      setIsAllSelected(false);
    },
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    idToIndex: fieldStore.idToIndex,
    handleCellEdit: handleEditCompany,
    hidePanel: false,
    page: page,
    pageSub: pageSub,
    cellLoading: listStore.cellLoading,
    loading: listStore.loading,

    //for side panel:
    sidePanelProps: popupStore.data,
    popupLoading: popupStore.loading,
    popupCellLoading: popupStore.cellLoading,
    sidePanelType: sidePanelType,
    handleDelete: () => setShowDelete(true),

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    filterPayload: loadingStore,

    //side pannel
    sidePannelSelectedId: sidePannelCompanyId,

    // selection
    isAllListSelected: isAllSelected,
  };

  const deleteModalProps = {
    show: showDelete,
    title: "Delete Company",
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeleteCompanies,
  };

  useEffect(() => {
    let tempCol = [];
    var tempFieldStore = fieldStore.list;
    tempFieldStore.sort(function(a, b) {
      return a.tablePriority - b.tablePriority;
    });
    for (let i = 0; i < tempFieldStore.length; i++) if (tempFieldStore[i].isEnabledForTable !== false) tempCol.push(tempFieldStore[i]["_id"]);
    setShowColumns(tempCol);
  }, [fieldStore]);

  const handlePanelClose = () => {
    history.push(`/prospects/companies`);
    setOpenPanel(false);
  };

  return (
    <>
      {fieldStore.loading || (listStore.loading && listStore.count === 0) ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <>
          <CustomForm
            show={showAdd}
            setShow={setShowAdd}
            handleAdd={handleAddCompany}
            addTag={addTag}
            editTag={editTag}
            deleteTag={deleteTag}
            page={page}
            pageSub={pageSub}
            fields={addFieldStore.list}
            customFormData={addFormData}
            setCustomFormData={setAddFormData}
            fieldsLoading={addFieldStore.loading}
            cacheCompany={false}
          />
          <DeleteModal modalProps={deleteModalProps}>
            Are you sure you want to delete the company?
            {isAllSelected && (
              <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be call or
                activated.
              </span>
            )}
          </DeleteModal>

          {!listStore.loading && listStore.count === 0 && _.isEmpty(tags) && _.isEmpty(owner) && _.isEmpty(virtualFilter) && _.isEmpty(filterId) ? (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row flex-stack w-100">
                    <div>
                      <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                    </div>
                    <div className="d-flex flex-row align-items-center gap-12px">
                      <NavLink to="/import/company">
                        <BlueGradientButton size="sm">Import Companies</BlueGradientButton>
                      </NavLink>
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <CustomEmptyPage page="company" setShowAdd={setShowAdd} />
            </ContentWrapper>
          ) : (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row flex-stack w-100">
                    <div>
                      <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                    </div>
                    <div>
                      {checked.length !== 0 ? (
                        <div className="d-flex flex-row align-items-center gap-12px">
                          <SelectAll
                            checked={checked}
                            limit={limit}
                            count={listStore.count}
                            isAllSelected={isAllSelected}
                            selectAllClick={() => {
                              setIsAllSelected(true);
                              setChecked(listStore.list.map((each) => each._id));
                            }}
                            clearAllClick={() => {
                              setIsAllSelected(false);
                              setChecked([]);
                            }}
                          />
                          {checked.length === 2 ? (
                            <BlueGradientButton size="sm" onClick={() => setShowMergeModal(true)}>
                              Merge
                            </BlueGradientButton>
                          ) : null}
                          <BulkEditForm
                            isAllListSelected={isAllSelected}
                            handleBulkEdit={handleBulkEdit}
                            noOfRows={isAllSelected ? listStore.count : checked.length}
                            showColumns={showColumns}
                            show={showBulkEdit}
                            setShow={setShowBulkEdit}
                            addTag={addTag}
                            editTag={editTag}
                            deleteTag={deleteTag}
                            page={page}
                            pageSub={pageSub}
                            fields={fieldStore.list}
                            customFormData={bulkEditData}
                            setCustomFormData={setBulkEditData}
                          />
                          <SecondaryButton onClick={() => setShowBulkEdit(true)}>Bulk Edit</SecondaryButton>
                          <DeleteIconButton onClick={() => setShowDelete(true)} />
                          <ColumnFilter
                            fieldType={"COMPANY"}
                            showHideField={showHideField}
                            updateFieldPriority={updateFieldPriority}
                            idToIndex={fieldStore.idToIndex}
                            columns={fieldStore.list}
                            showColumns={showColumns}
                            setShowColumns={setShowColumns}
                          />
                        </div>
                      ) : (
                        <div className="d-flex flex-row align-items-center gap-12px">
                          <BlueGradientButton onClick={() => setShowAdd(true)} variant="success" size="sm">
                            + Company
                          </BlueGradientButton>
                          <SmartViewFilter
                            pageSub={pageSub}
                            page={page}
                            activeFilter={filterStore.activeFilter}
                            customFilters={filterStore.list}
                            loading={filterStore.loading}
                            setAddFilterFormData={setAddFilterFormData}
                            virtualFilter={virtualFilter}
                            applyVirtualFilter={applyVirtualFilter}
                            selectedFilterId={filterId}
                            favoriteFilter={favoriteFilter}
                            applyFilter={applyFilter}
                            addFilterFormData={addFilterFormData}
                            addFilter={addFilter}
                            editFilter={editFilter}
                            deleteFilter={deleteFilter}
                          />
                          <div>
                            <TagSelect
                              page={page}
                              pageSub={pageSub}
                              addTag={addTag}
                              editTag={editTag}
                              deleteTag={deleteTag}
                              field={{ fieldName: "Tags", apiKeyName: "tags" }}
                              value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                              setValue={(value) => setValue(value)}
                              hideAddText={true}
                              isUsedAsFilter={true}
                              iconBasedFilter={true}
                            />
                          </div>
                          <OwnerFilter setValue={setOwner} value={owner} />
                          <OptionsDropdown
                            dataType={"company"}
                            importLabel={"Import Companies"}
                            exportLabel={"Export Companies"}
                            showColumns={showColumns}
                            showExportModal={showExportModal}
                            activeFilter={activeFilterStore}
                            appliedFilter={loadingStore}
                            setShowExportModal={setShowExportModal}
                          />
                          <ColumnFilter
                            fieldType={"COMPANY"}
                            showHideField={showHideField}
                            updateFieldPriority={updateFieldPriority}
                            idToIndex={fieldStore.idToIndex}
                            columns={fieldStore.list}
                            showColumns={showColumns}
                            setShowColumns={setShowColumns}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <div className={`card card-custom`}>
                <div className="card-body py-3 px-3">
                  <EditableTable props={tableProps} />
                </div>
              </div>
              <MergeModal
                show={showMergeModal}
                handleMerge={handleMerge}
                setShow={setShowMergeModal}
                type={page}
                pageSub={pageSub}
                typeSub="Company"
                fields={fieldStore.list}
                items={checked.map((item) => listStore.list.find((listItem) => listItem["_id"] === item))}
              />
              <SidePannelDetailed
                show={openPanel}
                basePath={`/prospects/companies`}
                onClose={handlePanelClose}
                moduleName={linkedModuleName ? linkedModuleName : "company"}
                moduleId={linkedModuleId ? linkedModuleId : sidePannelCompanyId}
                nextPreviousData={linkedModuleId ? [] : nextPreviousData}
                pageForm={"company"}
                filter={loadingStore}
                //actions
                handleDelete={() => setShowDelete(true)}
              />
            </ContentWrapper>
          )}
        </>
      )}
    </>
  );
}
