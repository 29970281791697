//packages
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Spinner, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

//components
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import { BlueGradientButton } from "../../../components/button";
import ContentWrapper from "../../Wrapper";

//forms
import AddSalesTargetForm from "../components/forms/addSalesTarget";

//actions
import * as insightActions from "../actions";
import * as salesTargetActions from "../store/actions/sales_target.actions";

//styles
import "../styles.css";
import "./styles.scss";
import SalesTargetFilter from "./sales-target-filter";
import SalesTargetChart from "../components/amchart-graphs/sales-target-chart";
import SalesTargetCard from "./card";
import SalesTargetSummary from "./summary";
import STDetailsCard from "./details-card";
import { DeleteModal } from "../../../components/modal";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import { DashboardSVG, SalesForcastSVG, SalesTargetSVG } from "../../../components/custom-svgs/sub-header-svg";
import SubscriptionPlanModal from "../../Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import { UpgradePlanPage } from "../../../components/empty_page";

export default function Insights(props) {
  const [showPlans, setShowPlans] = useState(false);
  const [openSalesTargetModal, setOpenSalesTargetModal] = useState(false);
  const [isSalesTargetEdit, setIsSalesTargetEdit] = useState(false);
  const [userRoleData, setUserRoleData] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedSalesTarget, setSelectedSalesTarget] = useState({});
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [filterData, setFilterData] = useState({ type: "all", id: "" });
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [limit] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [lastDeleted, setLastDeleted] = useState("");

  //variables
  const links = [
    { name: "Dashboard", to: "/insights/dashboard", icon: "bi bi-reception-4", svg: DashboardSVG },
    { name: "Sales Target", to: "/insights/sales-target", icon: "bi bi-geo", svg: SalesTargetSVG },
    { name: "Forecast", to: "/insights/forecast", icon: "bi bi-graph-up-arrow", svg: SalesForcastSVG },
  ];
  const [tabName] = useState("Sales Target");

  const dispatch = useDispatch();

  //store
  const listOfPipelines = useSelector((store) => store.insight.pipelines);
  const userRole = useSelector((store) => store.insight.userRole);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const salesTargetListStore = useSelector((store) => store.InsightReducer.salesTarget.list);
  const salesTargetGraphStore = useSelector((store) => store.InsightReducer.salesTarget.graph);

  useEffect(() => {
    dispatch(
      salesTargetActions.getListOfSalesTargetRequest({
        limit: limit,
        pageNo: 1,
        ...filterData,
      }),
    );
  }, [dispatch, limit, filterData]);

  useEffect(() => {
    dispatch(insightActions.getListOfPipelinesRequest());
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!salesTargetListStore.loading) {
      if (salesTargetListStore.data.length > 0 && _.isEmpty(selectedSalesTarget)) {
        if (salesTargetListStore.data[0]._id !== lastDeleted) {
          dispatch(salesTargetActions.getSalesTargetGraphRequest(salesTargetListStore.data[0]._id));
          setSelectedSalesTarget(salesTargetListStore.data[0]);
        }
      }
      if (salesTargetListStore.data.length > 0 && !_.isEmpty(selectedSalesTarget)) {
        const _find = salesTargetListStore.data.find((each) => each._id === selectedSalesTarget._id);
        if (_find && lastDeleted !== _find._id) {
          dispatch(salesTargetActions.getSalesTargetGraphRequest(_find._id));
          setSelectedSalesTarget(_find);
        }
      }
    }
  }, [dispatch, salesTargetListStore, selectedSalesTarget, lastDeleted]);

  useEffect(() => {
    setUserRoleData(userRole.list);
    if (userRole.list.users) {
      setUserOptions(
        userRole.list.users.map((each) => {
          return { label: each.name, value: each.id };
        }),
      );
    }
    if (userRole.list.teams) {
      setTeamOptions(
        userRole.list.teams.map((each) => {
          return { label: each.name, value: each.id };
        }),
      );
    }
  }, [userRole.list]);

  useEffect(() => {
    const options2 = [{ value: "", label: "All Pipelines" }];
    listOfPipelines.list.forEach((eachPipeline) => {
      options2.push({ value: eachPipeline._id, label: eachPipeline.name });
    });
    setPipelineOptions(options2);
  }, [listOfPipelines.list]);

  const onAddSalessTargetClick = () => {
    setOpenSalesTargetModal(true);
    setIsSalesTargetEdit(false);
  };
  const handleCloseSalesTargetModel = () => {
    setOpenSalesTargetModal(false);
    setIsSalesTargetEdit(false);
  };
  const handleSaveSalesTarget = (data) => {
    if (isSalesTargetEdit) {
      dispatch(salesTargetActions.editSalesTargetRequest({ data: { ...data, _id: selectedSalesTarget._id }, loadingData: filterData }));
    } else {
      dispatch(salesTargetActions.createSalesTargetRequest({ data: data, loadingData: filterData }));
    }
    // setSelectedSalesTarget({})
    setOpenSalesTargetModal(false);
    setIsSalesTargetEdit(false);
  };

  const onSalesTargetClick = (data) => {
    if (data._id === selectedSalesTarget._id) {
      return;
    }
    dispatch(salesTargetActions.getSalesTargetGraphRequest(data._id));
    setSelectedSalesTarget(data);
  };

  const handleEditClick = (each) => {
    setOpenSalesTargetModal(true);
    setIsSalesTargetEdit(true);
    setSelectedSalesTarget(each);
  };

  const handleDeleteClick = (each) => {
    setSelectedSalesTarget(each);
    setShowDeleteModel(true);
  };

  const onLoadMoreClick = () => {
    setPageNo(pageNo + 1);
    dispatch(
      salesTargetActions.getListOfSalesTargetLoadMoreRequest({
        limit: limit,
        pageNo: pageNo + 1,
        ...filterData,
      }),
    );
  };

  const onAccepetDelete = () => {
    dispatch(salesTargetActions.deleteSalesTargetRequest({ id: selectedSalesTarget._id }));
    setShowDeleteModel(false);
    setLastDeleted(selectedSalesTarget._id);
    setSelectedSalesTarget({});
  };
  const deleteLeadBotModelProps = {
    show: showDeleteModel,
    title: "Delete Sales Target ",
    handleClose: () => setShowDeleteModel(false),
    handleAccept: onAccepetDelete,
  };

  const showEmptyScreen = () => {
    if (filterData.type === "all" && salesTargetListStore.data.length === 0) {
      return true;
    } else return false;
  };

  if (accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.TEAM_GOALS) {
    return (
      <ContentWrapper
        subHeader={
          <CustomSubHeader>
            <SubheaderNav links={links} active={tabName} />
          </CustomSubHeader>
        }
      >
        <UpgradePlanPage
          isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
          userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          onUpgradeClick={() => setShowPlans(true)}
          type={"Sales Goal & Targets"}
          planName={"free plan"}
        />
        {showPlans && <SubscriptionPlanModal page={""} showPlans={showPlans} setShowPlans={setShowPlans} />}
      </ContentWrapper>
    );
  }
  return (
    <div className="sales-target-container">
      {salesTargetListStore.loading ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-195px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="d-flex flex-row flex-stack w-100">
                <div>
                  <SubheaderNav links={links} active={tabName} />
                </div>
                <div className="d-flex flex-stack gap-2">
                  <BlueGradientButton onClick={() => onAddSalessTargetClick()} variant="success" size="sm">
                    + Sales Target
                  </BlueGradientButton>
                </div>
              </div>
            </CustomSubHeader>
          }
        >
          {showEmptyScreen() ? (
            <CustomEmptyPage page="salesTarget" setShowAdd={onAddSalessTargetClick} />
          ) : (
            <div className="d-flex justify-content-between h-vh-163px">
              <Card className="sale-target-list-wrapper">
                <Card.Header>
                  <span> Sales Target List </span>
                  <SalesTargetFilter
                    userOptions={userOptions}
                    teamOptions={teamOptions}
                    filterData={filterData}
                    setFilterData={(data) => {
                      setFilterData(data);
                      setPageNo(1);
                    }}
                    userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
                  />
                </Card.Header>
                <Card.Body className="h-100 w-400px overflow-scroll">
                  {salesTargetListStore.data.length === 0 ? (
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
                        <div className="card-px text-center py-5 my-5">
                          <h2 className="fs-1 fw-bolder mb-5">No Data</h2>
                          <p className="text-gray-400 fs-5 fw-bold mb-8">
                            {"No sales target added for selected filter"} <br />
                            {"Click on below button to add one"}
                          </p>
                          <BlueGradientButton onClick={onAddSalessTargetClick}>Add Sales Target</BlueGradientButton>
                        </div>
                      </Card.Body>
                    </div>
                  ) : (
                    <div>
                      {salesTargetListStore.data &&
                        salesTargetListStore.data.map((each, index) => {
                          return (
                            <SalesTargetCard
                              data={each}
                              key={index}
                              userOptions={userOptions}
                              teamOptions={teamOptions}
                              selectedId={selectedSalesTarget._id}
                              userId={userProfile.data ? userProfile.data.userId : ""}
                              onSalesTargetClick={onSalesTargetClick}
                              handleEditClick={handleEditClick}
                              handleDeleteClick={handleDeleteClick}
                            />
                          );
                        })}
                      {salesTargetListStore.count > salesTargetListStore.data.length ? (
                        <div className="d-flex justify-content-center mt-3">
                          <span className="btn btn-secondary py-7px px-20px h-34px" onClick={() => onLoadMoreClick()}>
                            {salesTargetListStore.loadMoreLoading && <Spinner animation="border" variant="primary" className="mr-10px w-16px h-16px"></Spinner>}
                            Load More
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}
                </Card.Body>
              </Card>
              <div className="h-100 w-100-420px overflow-scroll">
                {salesTargetListStore.data.length === 0 ? (
                  <div className="card h-100 d-flex justify-content-center align-items-center">
                    <p className="text-gray-400 fs-5 fw-bold mb-8"> Sales Target Not Selected</p>
                  </div>
                ) : (
                  <>
                    {salesTargetGraphStore.loading ? (
                      <div className="w-100 d-flex justify-content-center align-items-center h-100">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <>
                        <STDetailsCard
                          userOptions={userOptions}
                          teamOptions={teamOptions}
                          selectedSalesTarget={selectedSalesTarget}
                          pipelineOptions={pipelineOptions}
                          onEditClick={handleEditClick}
                          onDeleteClick={handleDeleteClick}
                        />

                        <div className="card w-100 p-3 mb-3 h-500px">
                          <SalesTargetChart
                            chartId={"Chatr"}
                            trackingMetric={selectedSalesTarget.trackingMetric}
                            currency={userProfile.data && userProfile.data.currency ? userProfile.data.currency : "USD"}
                            data={salesTargetGraphStore.data}
                            interval={selectedSalesTarget.interval}
                          />
                        </div>
                        <SalesTargetSummary data={salesTargetGraphStore.data} selectedSalesTarget={selectedSalesTarget} />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {/**Add/Edit Goal */}
          <AddSalesTargetForm
            show={openSalesTargetModal}
            isSalesTargetEdit={isSalesTargetEdit}
            selectedSalesTarget={selectedSalesTarget}
            handleClose={handleCloseSalesTargetModel}
            handleSave={handleSaveSalesTarget}
            userRoleData={userRoleData}
            userOptions={userOptions}
            teamOptions={teamOptions}
            pipelineOptions={pipelineOptions}
            currency={userProfile.data && userProfile.data.currency ? userProfile.data.currency : "USD"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          />
          <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete sales target? After deleting sales target form you will not able to recover it.</DeleteModal>
        </ContentWrapper>
      )}
    </div>
  );
}
