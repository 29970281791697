import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as cadencePrebuiltTemplateActions from "../actions/cadence_prebuilt_template_actions";
import * as cadencePrebuiltTemplateTypes from "../types/cadence-prebuilt-template.types";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* getListOfCadencePrebuiltTemplate(action) {
  try {
    const res = yield call(api.get, endpoints.GET_CADENCE_PREBUILT_TEMPLATES, action.payload);
    if (res.status === 200) {
      yield put(cadencePrebuiltTemplateActions.getListOfCadencePrebuiltTemplateSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadencePrebuiltTemplateActions.getListOfCadencePrebuiltTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadencePrebuiltTemplateActions.getListOfCadencePrebuiltTemplateError(e));
  }
}

function* createCadenceByTemplate(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_CADENCE_BY_TEMPLATE, action.payload);
    if (res.status === 200) {
      yield put(cadencePrebuiltTemplateActions.createCadenceByTemplateSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadencePrebuiltTemplateActions.createCadenceByTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadencePrebuiltTemplateActions.createCadenceByTemplateError(e));
  }
}

export default function* cadencePrebuiltTemplateSaga() {
  yield takeLatest(cadencePrebuiltTemplateTypes.getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_REQUEST, getListOfCadencePrebuiltTemplate);
  yield takeLatest(cadencePrebuiltTemplateTypes.createCadenceByTemplate.CREATE_CADENCE_BY_TEMPLATE_REQUEST, createCadenceByTemplate);
}
