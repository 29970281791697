//dashboards
export const GET_LIST_OF_DASHBOARDS_REQUEST = "GET_LIST_OF_DASHBOARDS_REQUEST";
export const GET_LIST_OF_DASHBOARDS_SUCCESS = "GET_LIST_OF_DASHBOARDS_SUCCESS";
export const GET_LIST_OF_DASHBOARDS_ERROR = "GET_LIST_OF_DASHBOARDS_ERROR";

export const GET_LIST_OF_DASHBOARDS_REQUEST_WITHOUT_LOADING = "GET_LIST_OF_DASHBOARDS_REQUEST_WITHOUT_LOADING";

export const CREATE_DASHBOARD_REQUEST = "CREATE_DASHBOARD_REQUEST";
export const CREATE_DASHBOARD_SUCCESS = "CREATE_DASHBOARD_SUCCESS";
export const CREATE_DASHBOARD_ERROR = "CREATE_DASHBOARD_ERROR";

export const EDIT_DASHBOARD_REQUEST = "EDIT_DASHBOARD_REQUEST";
export const EDIT_DASHBOARD_SUCCESS = "EDIT_DASHBOARD_SUCCESS";
export const EDIT_DASHBOARD_ERROR = "EDIT_DASHBOARD_ERROR";

export const DELETE_DASHBOARD_REQUEST = "DELETE_DASHBOARD_REQUEST";
export const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS";
export const DELETE_DASHBOARD_ERROR = "DELETE_DASHBOARD_ERROR";

export const GET_SELECTED_DASHBOARD_REQUEST = "GET_SELECTED_DASHBOARD_REQUEST";
export const GET_SELECTED_DASHBOARD_SUCCESS = "GET_SELECTED_DASHBOARD_SUCCESS";
export const GET_SELECTED_DASHBOARD_ERROR = "GET_SELECTED_DASHBOARD_ERROR";

//layout
export const EDIT_DASHBOARD_LAYOUT_REQUEST = "EDIT_DASHBOARD_LAYOUT_REQUEST";
export const EDIT_DASHBOARD_LAYOUT_SUCCESS = "EDIT_DASHBOARD_LAYOUT_SUCCESS";
export const EDIT_DASHBOARD_LAYOUT_ERROR = "EDIT_DASHBOARD_LAYOUT_ERROR";

//reports
export const CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST = "CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST";
export const CREATE_INSIGHT_DASHBOARD_REPORT_SUCCESS = "CREATE_INSIGHT_DASHBOARD_REPORT_SUCCESS";
export const CREATE_INSIGHT_DASHBOARD_REPORT_ERROR = "CREATE_INSIGHT_DASHBOARD_REPORT_ERROR";

export const EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST = "EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST";
export const EDIT_INSIGHT_DASHBOARD_REPORT_SUCCESS = "EDIT_INSIGHT_DASHBOARD_REPORT_SUCCESS";
export const EDIT_INSIGHT_DASHBOARD_REPORT_ERROR = "EDIT_INSIGHT_DASHBOARD_REPORT_ERROR";

export const DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST = "DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST";
export const DELETE_INSIGHT_DASHBOARD_REPORT_SUCCESS = "DELETE_INSIGHT_DASHBOARD_REPORT_SUCCESS";
export const DELETE_INSIGHT_DASHBOARD_REPORT_ERROR = "DELETE_INSIGHT_DASHBOARD_REPORT_ERROR";

export const GET_INSIGHTS_GRAPH_REPORT_REQUEST = "GET_INSIGHTS_GRAPH_REPORT_REQUEST";
export const GET_INSIGHTS_GRAPH_REPORT_SUCCESS = "GET_INSIGHTS_GRAPH_REPORT_SUCCESS";
export const GET_INSIGHTS_GRAPH_REPORT_ERROR = "GET_INSIGHTS_GRAPH_REPORT_ERROR";

//goals
export const GET_INSIGHT_GOAL_REQUEST = "GET_INSIGHT_GOAL_REQUEST";
export const GET_INSIGHT_GOAL_SUCCESS = "GET_INSIGHT_GOAL_SUCCESS";
export const GET_INSIGHT_GOAL_ERROR = "GET_INSIGHT_GOAL_ERROR";

export const CREATE_INSIGHTS_GOAL_REQUEST = "CREATE_INSIGHTS_GOAL_REQUEST";
export const CREATE_INSIGHTS_GOAL_SUCCESS = "CREATE_INSIGHTS_GOAL_SUCCESS";
export const CREATE_INSIGHTS_GOAL_ERROR = "CREATE_INSIGHTS_GOAL_ERROR";

export const EDIT_INSIGHTS_GOAL_REQUEST = "EDIT_INSIGHTS_GOAL_REQUEST";
export const EDIT_INSIGHTS_GOAL_SUCCESS = "EDIT_INSIGHTS_GOAL_SUCCESS";
export const EDIT_INSIGHTS_GOAL_ERROR = "EDIT_INSIGHTS_GOAL_ERROR";

export const DELETE_INSIGHTS_GOAL_REQUEST = "DELETE_INSIGHTS_GOAL_REQUEST";
export const DELETE_INSIGHTS_GOAL_SUCCESS = "DELETE_INSIGHTS_GOAL_SUCCESS";
export const DELETE_INSIGHTS_GOAL_ERROR = "DELETE_INSIGHTS_GOAL_ERROR";

export const GET_INSIGHT_GRAPH_GOAL_REQUEST = "GET_INSIGHT_GRAPH_GOAL_REQUEST";
export const GET_INSIGHT_GRAPH_GOAL_SUCCESS = "GET_INSIGHT_GRAPH_GOAL_SUCCESS";
export const GET_INSIGHT_GRAPH_GOAL_ERROR = "GET_INSIGHT_GRAPH_GOAL_ERROR";

//users
export const GET_LIST_OF_USERS_REQUEST = "GET_LIST_OF_USERS_REQUEST";
export const GET_LIST_OF_USERS_SUCCESS = "GET_LIST_OF_USERS_SUCCESS";
export const GET_LIST_OF_USERS_ERROR = "GET_LIST_OF_USERS_ERROR";

//pipelines
export const GET_LIST_OF_PIPELINES_REQUEST = "GET_LIST_OF_PIPELINES_REQUEST";
export const GET_LIST_OF_PIPELINES_SUCCESS = "GET_LIST_OF_PIPELINES_SUCCESS";
export const GET_LIST_OF_PIPELINES_ERROR = "GET_LIST_OF_PIPELINES_ERROR";

//type of report
export const TYPE_OF_REPORT_REQUEST = "TYPE_OF_REPORT_REQUEST";
export const TYPE_OF_REPORT_SUCCESS = "TYPE_OF_REPORT_SUCCESS";
export const TYPE_OF_REPORT_ERROR = "TYPE_OF_REPORT_ERROR";

//user Role
export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_ERROR = "USER_ROLE_ERROR";
