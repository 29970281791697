import {
  getListOfSchedulingPages,
  getSelectedSchedulingPage,
  updatedSchedulingPage,
  deleteSchedulingPage,
  createSchedulingPage,
  getAvailableCalendars,
  getS3SignedUrlForUpload,
} from "../../types/profile-settings/schedular.types";

export const getListOfSchedulingPagesRequest = (req, cb) => ({
  type: getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_REQUEST,
  payload: req,
  cb,
});
export const getListOfSchedulingPagesSucess = (res) => ({
  type: getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_SUCCESS,
  payload: res,
});
export const getListOfSchedulingPagesError = (err) => ({
  type: getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_ERROR,
  payload: err,
});

export const getSelectedSchedulingPageRequest = (req, cb) => ({
  type: getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_REQUEST,
  payload: req,
  cb,
});
export const getSelectedSchedulingPageSucess = (res) => ({
  type: getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_SUCCESS,
  payload: res,
});
export const getSelectedSchedulingPageError = (err) => ({
  type: getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_ERROR,
  payload: err,
});

export const createSchedulingPageRequest = (req, cb) => ({
  type: createSchedulingPage.CREATE_SCHEDULING_PAGE_REQUEST,
  payload: req,
  cb,
});
export const createSchedulingPageSucess = (res) => ({
  type: createSchedulingPage.CREATE_SCHEDULING_PAGE_SUCCESS,
  payload: res,
});
export const createSchedulingPageError = (err) => ({
  type: createSchedulingPage.CREATE_SCHEDULING_PAGE_ERROR,
  payload: err,
});

export const updatedSchedulingPageRequest = (req, cb) => ({
  type: updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_REQUEST,
  payload: req,
  cb,
});
export const updatedSchedulingPageSucess = (res) => ({
  type: updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_SUCCESS,
  payload: res,
});
export const updatedSchedulingPageError = (err) => ({
  type: updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_ERROR,
  payload: err,
});

export const deleteSchedulingPageRequest = (req, cb) => ({
  type: deleteSchedulingPage.DELETE_SCHEDULING_PAGE_REQUEST,
  payload: req,
  cb,
});
export const deleteSchedulingPageSucess = (res) => ({
  type: deleteSchedulingPage.DELETE_SCHEDULING_PAGE_SUCCESS,
  payload: res,
});
export const deleteSchedulingPageError = (err) => ({
  type: deleteSchedulingPage.DELETE_SCHEDULING_PAGE_ERROR,
  payload: err,
});

export const getAvailableCalendarsRequest = (req, cb) => ({
  type: getAvailableCalendars.GET_AVAILABLE_CALENDARS_REQUEST,
  payload: req,
  cb,
});
export const getAvailableCalendarsSucess = (res) => ({
  type: getAvailableCalendars.GET_AVAILABLE_CALENDARS_SUCCESS,
  payload: res,
});
export const getAvailableCalendarsError = (err) => ({
  type: getAvailableCalendars.GET_AVAILABLE_CALENDARS_ERROR,
  payload: err,
});

export const getS3SignedUrlForUploadRequest = (req, cb) => ({
  type: getS3SignedUrlForUpload.GET_S3_SIGNED_URL_FOR_UPLOAD_REQUEST,
  payload: req,
  cb,
});
export const getS3SignedUrlForUploadSucess = (res) => ({
  type: getS3SignedUrlForUpload.GET_S3_SIGNED_URL_FOR_UPLOAD_SUCCESS,
  payload: res,
});
export const getS3SignedUrlForUploadError = (err) => ({
  type: getS3SignedUrlForUpload.GET_S3_SIGNED_URL_FOR_UPLOAD_ERROR,
  payload: err,
});
