import React from "react";
import { Button, Dropdown } from "react-bootstrap";

//show, setShow, label, children, onSaveClick, onCancelClick, saveButtonText, cancelButtonText, showCancel, showSave,
export default function PopoverFilterWrapper(props) {
  return (
    <Dropdown show={props.show} className="dropdown-inline custom-dropdown-toggle">
      <Dropdown.Toggle onClick={() => props.setShow(!props.show)} variant={props.buttonType || "primary"} className={`btn btn-sm `}>
        {props.label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="width-auto py-0 w-300px-mn">
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5">
          <div className="mb-4">{props.children}</div>
          <div className="d-flex flex-stack">
            <div>
              {props.showReset && (
                <Button className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={props.onResetClick}>
                  Reset
                </Button>
              )}
            </div>
            <div className="d-flex">
              {props.showCancel && (
                <Button className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={props.onCancelClick}>
                  {props.cancelButtonText}
                </Button>
              )}
              {props.showSave && (
                <Button className="btn btn-sm btn-primary" onClick={props.onSaveClick}>
                  {props.saveButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
