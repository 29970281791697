// import Button from 'modules/common/components/Button';
// import { __ } from 'modules/common/utils';
// import { IDeal } from 'modules/deals/types';
import * as React from "react";
import { CenterButton, Deals, SubHead } from "../styled";
import DealItem from "./dealItem";

export default class DealList extends React.Component {
  static defaultProps = {
    listId: "LIST",
  };

  render() {
    const { deals } = this.props;
    const contents = deals.map((deal, index) => <DealItem key={index} deal={deal} />);

    return (
      <Deals>
        <SubHead>
          <span>{"Deal"}</span>
          <span>{"Value"}</span>
          <span>{"Current Stage"}</span>
          <span>{"Assigned"}</span>
        </SubHead>
        {contents}
        {this.props.hasMore && (
          <CenterButton>
            {/* <Button
              size="small"
              btnStyle="default"
              icon="refresh"
              onClick={this.props.loadMore}
            >
              Load More
            </Button> */}
          </CenterButton>
        )}
      </Deals>
    );
  }
}
