import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
// import { requestPassword } from "../_redux/authCrud";
import "../../../../_metronic/_assets/css/style.bundle.css";
import "../../../../_metronic/_assets/plugins/global/plugins.bundle.css";
import * as actions from "../actions";
import { useDispatch } from "react-redux";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";
import { InputLimit } from "../../../../constants";
import LOGO from "../../../../images/logo/rectangle-logo/SVG/logo-rectangle.svg";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const search = useLocation().search;
  const codeParams = new URLSearchParams(search).get("token");
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [conformPassword, setconformPassword] = useState("");
  const { intl } = props;
  const [
    isRequested,
    // setIsRequested
  ] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      dispatch(
        actions.ForgotPassRequest(values.email, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Check Your Email For OTP", "success");
            window.location.href = "/auth/login";
          } else if (data === null) {
            dispatchSnackbarError("User Does not exist", "error");
          }
        }),
      );
    },
  });

  const handleChange = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "conformPassword") {
      setconformPassword(e.target.value);
    }
  };

  const Submit = () => {
    if (password === "" || password === null) {
      dispatchSnackbarError("Password Enter Password", "error");
    } else if (conformPassword === "" || conformPassword === null) {
      dispatchSnackbarError("Password Enter Confirm Password", "error");
    } else {
      if (password === conformPassword) {
        dispatch(
          actions.forgetPassPostRequest({ token: codeParams, password: password }, codeParams, (data) => {
            if (data["success"]) {
              window.location.href = "/auth/login";
              dispatchSnackbarSuccess("Password Successfully Updated", "success");
            }
          }),
        );
      } else {
        dispatchSnackbarError("Password and Confirm Password not Match", "error");
      }
    }
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {codeParams === null && !isRequested && (
        <div className="login-form login-forgot AuthLoginForm d-block">
          <div className="d-flex flex-column flex-root">
            {/*begin::Authentication - Password reset */}
            <InlineStyled
              className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"            >
              {/*begin::Content*/}
              <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                {/*begin::Logo*/}
                <a href="index.html" className="mb-12">
                  <img alt="Logo" src={LOGO} className="h-90px" />
                </a>
                {/*end::Logo*/}
                {/*begin::Wrapper*/}
                <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 AuthLoginCard">
                  {/*begin::Form*/}
                  <form className="form w-100" noValidate="novalidate" id="kt_password_reset_form" onSubmit={formik.handleSubmit}>
                    {/*begin::Heading*/}
                    <div className="text-center mb-10">
                      {/*begin::Title*/}
                      <h1 className="text-dark mb-3">Forgot Password ?</h1>
                      {/*end::Title*/}
                      {/*begin::Link*/}
                      <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
                      {/*end::Link*/}
                    </div>
                    {/*begin::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-10">
                      <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
                      <input
                        maxLength={InputLimit.EMAIL}
                        type="email"
                        name="email"
                        autoComplete="off"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                      ) : null}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                      <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        // disabled={formik.isSubmitting}
                      >
                        Submit
                      </button>
                      <Link to="/auth">
                        <button type="button" id="kt_login_forgot_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                          Cancel
                        </button>
                      </Link>
                    </div>
                    {/*end::Actions*/}
                  </form>
                  {/*end::Form*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Content*/}
              {/*begin::Footer*/}
              <div className="d-flex flex-center flex-column-auto p-10">
                {/*begin::Links*/}
                <div className="d-flex align-items-center fw-bold fs-6">
                  <a href="https://salessimplify.com/about-us/" className="text-muted text-hover-primary px-2">
                    About
                  </a>
                  <a href="mailto:support@salessimplify.com" className="text-muted text-hover-primary px-2">
                    Contact
                  </a>
                </div>
                {/*end::Links*/}
              </div>
              {/*end::Footer*/}
            </InlineStyled>
            {/*end::Authentication - Password reset*/}
          </div>
        </div>
      )}

      {codeParams !== null && (
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="index.html" className="mb-12">
            <img alt="Logo" src={LOGO} className="h-90px" />
          </a>

          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div className="form w-100 mb-10" noValidate="novalidate" id="kt_sing_in_two_steps_form">
              <div className="text-center mb-10">
                <div className="fw-bolder font-size-16px">Please Enter the Password</div>
              </div>
              <div className="mb-10 px-md-10">
                <div className="col-xl-12">
                  <label className="form-label fw-bolder text-dark fs-6">Password</label>
                  <input
                    maxLength={InputLimit.PASSWORD}
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                </div>

                <div className="col-xl-12">
                  <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                  <input
                    maxLength={InputLimit.PASSWORD}
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    name="conformPassword"
                    value={conformPassword}
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="d-flex flex-center justify-content-arround">
                <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={Submit}>
                  <span className="indicator-label">Verify</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
