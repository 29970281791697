import React from "react";
import AceEditor from "react-ace";
import { BlueGradientButton } from "../../../../../components/button";

export default function CopyPasteEditor(props) {
  return (
    <div className="card mt-8 p-6">
      <div className="p-2 rounded-3 bg-gray-100 border-width-1px border-style-solid border-color-e0e0e0">
        <AceEditor
          width="100%"
          height="350px"
          placeholder=""
          mode="javascript"
          theme="tomorrow"
          name="blah2"
          onLoad={() => {}}
          onChange={(a) => props.setCopyPastedData(a)}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          value={props.copyPastedData}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
      <div className="d-flex flex-stack mt-4">
        <span>Total Audience: {props.copyPastedCount}</span>
        <div className="d-flex gap-3">
          <BlueGradientButton size="sm" onClick={props.clearFunction}>
            Clear
          </BlueGradientButton>
          <BlueGradientButton size="sm" onClick={props.uniqueFunction}>
            Unique
          </BlueGradientButton>
        </div>
      </div>
    </div>
  );
}
