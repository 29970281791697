import {
  getListOfEmailDomainSettings,
  getEmailProfileDetails,
  updateEmailProfileSettings,
  getEmailAccountReputations,
  getEmailAccountStatus,
  deleteEmailDomainSettings,
  addEmailToDomainSettings,
  addDomainSettings,
} from "../types/campaign_email_settings_types";

const initState = {
  profile: {
    data: {},
    loading: false,
  },
  domain: {
    list: [],
    loading: false,
    error: "",
    count: 0,
  },
  accountReputation: {
    data: {
      abusepercent: 0,
      unknownuserspercent: 0,
      openedpercent: 0,
      clickedpercent: 0,
      averagespamscore: 10,
      failedspampercent: 10,
      reputation: 100,
    },
    loading: false,
  },
  accountStatus: {
    data: {},
    loading: false,
  },
};

export default function campaignEmailSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_REQUEST:
      return {
        ...state,
        domain: { ...state.domain, loading: true },
      };
    case getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_SUCCESS:
      return {
        ...state,
        domain: {
          ...state.domain,
          loading: false,
          list: action.payload.data ? action.payload.data : [],
        },
      };
    case getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_ERROR:
      return {
        ...state,
        domain: {
          ...state.domain,
          loading: false,
        },
      };
    case addDomainSettings.ADD_DOMAIN_SETTINGS_REQUEST: {
      return {
        ...state,
        domain: { ...state.domain, loading: true },
      };
    }
    case getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        profile: { ...state.profile, loading: true },
      };
    case getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          data: action.payload.data,
        },
      };
    case getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
        },
      };

    case updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_REQUEST:
      return {
        ...state,
        profile: { ...state.profile, loading: true },
      };
    case updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
      };
    case updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_ERROR:
      return {
        ...state,
      };

    case getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_REQUEST:
      return {
        ...state,
        accountReputation: {
          ...state.accountReputation,
          loading: true,
        },
      };
    case getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_SUCCESS:
      return {
        ...state,
        accountReputation: {
          ...state.accountReputation,
          loading: false,
          data: action.payload.data,
        },
      };
    case getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_ERROR:
      return {
        ...state,
        accountReputation: {
          ...state.accountReputation,
          loading: false,
        },
      };
    case getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_REQUEST:
      return {
        ...state,
        accountStatus: {
          ...state.accountStatus,
          loading: true,
        },
      };
    case getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        accountStatus: {
          ...state.accountStatus,
          loading: false,
          data: action.payload.data,
        },
      };
    case getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_ERROR:
      return {
        ...state,
        accountStatus: {
          ...state.accountStatus,
          loading: false,
          error: { err: true, errMsg: action.payload.message },
        },
      };

    case deleteEmailDomainSettings.DELETE_EMAIL_DOMAIN_SETTINGS_REQUEST:
      return {
        ...state,
        domain: { ...state.domain, loading: true },
      };

    case addEmailToDomainSettings.ADD_EMAIL_TO_DOMAIN_SETTINGS_REQUEST:
      return {
        ...state,
        domain: { ...state.domain, loading: true },
      };

    default:
      return {
        ...state,
      };
  }
}
