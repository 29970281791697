import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function PipelineSelect({ field, setValue, value }) {
  const [selected, setSelected] = useState("");

  const pipelineOptions = useSelector((state) => state.DealPipeline.pipelineOptionsResponse);
  const loading = useSelector((state) => state.DealPipeline.pipelineOptionsLoading);

  useEffect(() => {
    if (value && pipelineOptions.length > 0) {
      const _selected = pipelineOptions.find((each) => each._id === value);
      if (_selected) {
        setSelected(_selected.name);
      }
    }
  }, [value, pipelineOptions]);

  const _setSelected = (pipeline) => {
    setSelected(pipeline.name);
  };

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn ">
        {loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {pipelineOptions &&
              pipelineOptions.map((eachPipeline, index) => {
                return (
                  <Dropdown.Item
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      _setSelected(eachPipeline);
                      setValue(eachPipeline["_id"], field.apiKeyName);
                    }}
                  >
                    {eachPipeline.name}
                  </Dropdown.Item>
                );
              })}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function PipelineStageSelect({ field, setValue, value, pipelineId, allData }) {
  const [selected, setSelected] = useState("");
  const [stageOptions, setStageOptions] = useState([]);

  const pipelineOptions = useSelector((state) => state.DealPipeline.pipelineOptionsResponse);
  const loading = useSelector((state) => state.DealPipeline.pipelineOptionsLoading);

  useEffect(() => {
    if (pipelineOptions) {
      const _selected = pipelineOptions.find((each) => each._id === pipelineId);
      if (_selected) {
        setStageOptions(_selected.stages);
      }
    }
  }, [pipelineId, pipelineOptions]);

  useEffect(() => {
    if (pipelineOptions && allData && allData.pipelineId) {
      const _pipelineId = typeof allData.pipelineId === "object" ? allData.pipelineId._id : typeof allData.pipelineId === "string" ? allData.pipelineId : "";
      const _selected = pipelineOptions.find((each) => each._id === _pipelineId);
      if (_selected) {
        setStageOptions(_selected.stages);
      }
    }
  }, [allData, pipelineOptions]);

  useEffect(() => {
    if (value && stageOptions.length > 0) {
      const _selected = stageOptions.find((each) => each._id === value);
      if (_selected) {
        setSelected(_selected.name);
      }
    }
  }, [value, stageOptions]);

  const _setSelected = (pipeline) => {
    setSelected(pipeline.name);
  };

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {stageOptions &&
              stageOptions.map((eachPipeline, index) => {
                return (
                  <Dropdown.Item
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      _setSelected(eachPipeline);
                      setValue(eachPipeline["_id"], field.apiKeyName);
                    }}
                  >
                    {eachPipeline.name}
                  </Dropdown.Item>
                );
              })}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
