import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import {
  WidgetContainer,
  AppBackground,
  AppFrame,
  Conversation,
  ConversationPoweredBy,
  PoweredByWrapper,
  PoweredByA,
  RootMessageList,
  MessageListScroller,
  MessageListWrapper,
  ConversationMessageList,
  MessageContainer,
  QuestionWrapper,
  InnerQuestionWrapper,
  AnswerWrapper,
  AvatarWrapper,
  InnerAnswerWrapper,
  ThankYouScreenWrapper,
  TYSContentWrapper,
  TYSContentWrapper1,
  TYSContentWrapper2,
  ConversationFooter,
  UserInputContainer,
  UserInputWrapper,
  InputBox,
  InputWrapper,
  SkipButton,
  InputInner,
  Input,
  SendButton,
  WelcomeScreenWrapper,
  WSButtonWrapper,
  WSContentWrapper,
  WSImageContainer,
  WSImageContainer2,
  WSImage,
  WSContentWrapper1,
  WSContentWrapper2,
  WsButtonContainer,
  WsButton,
  WSDummyButton,
} from "./widget.styled";

const usrIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
  </svg>
);

export default function Widget({ showWelcomeScreen, showChatHistoryScreen, welcomeScreenData, thankYouScreenData, showThankYouScreen, customization, type }) {
  const [conversation] = useState([
    {
      _id: "2",
      message: "I need help!",
      messageType: "text-message",
      from: "user",
      options: [],
    },
    {
      _id: "1",
      message: "Got any questions? I'm happy to help",
      messageType: "text-message",
      from: "bot",
      options: [],
    },
  ]);
  const theme = {
    type: type,
    question: customization.question,
    answer: customization.answer,
    button: customization.button,
    background: customization.background,
    backgroundImage: customization.backgroundImage,
  };

  return (
    <WidgetContainer>
      <ThemeProvider theme={theme}>
        <Conversation>
          {showWelcomeScreen && (
            <WelcomeScreenWrapper>
              {welcomeScreenData.mediaUrl && (
                <WSImageContainer>
                  <WSImageContainer2>
                    <WSImage src={welcomeScreenData.mediaUrl} />
                  </WSImageContainer2>
                </WSImageContainer>
              )}
              <WSContentWrapper isImageContainer={Boolean(welcomeScreenData.mediaUrl)}>
                <WSContentWrapper1>
                  <WSContentWrapper2>
                    <span dangerouslySetInnerHTML={{ __html: welcomeScreenData.descrption }}></span>
                  </WSContentWrapper2>
                </WSContentWrapper1>
                <WSDummyButton />
                <WSButtonWrapper>
                  <WsButtonContainer>
                    <WsButton>{welcomeScreenData.buttonText}</WsButton>
                  </WsButtonContainer>
                </WSButtonWrapper>
              </WSContentWrapper>
            </WelcomeScreenWrapper>
          )}
          {showChatHistoryScreen && (
            <AppFrame>
              {customization.isBrandingEnabled && (
                <ConversationPoweredBy>
                  <PoweredByWrapper>
                    <PoweredByA href="https://www.salessimplify.com" target={"_blank"}>
                      Powered by Sales Simplify
                    </PoweredByA>
                  </PoweredByWrapper>
                </ConversationPoweredBy>
              )}
              <ConversationMessageList>
                <RootMessageList>
                  <MessageListScroller>
                    <MessageListWrapper>
                      {conversation.map((each, index) => {
                        return (
                          <MessageContainer key={index}>
                            {each.from === "bot" ? (
                              <>
                                <AvatarWrapper>{usrIcon}</AvatarWrapper>
                                <QuestionWrapper>
                                  <InnerQuestionWrapper>
                                    <div>{each.message}</div>
                                  </InnerQuestionWrapper>
                                </QuestionWrapper>
                              </>
                            ) : (
                              <AnswerWrapper>
                                <InnerAnswerWrapper>
                                  <div>{each.message}</div>
                                </InnerAnswerWrapper>
                              </AnswerWrapper>
                            )}
                          </MessageContainer>
                        );
                      })}
                    </MessageListWrapper>
                  </MessageListScroller>
                </RootMessageList>
              </ConversationMessageList>
              <ConversationFooter>
                <UserInputContainer>
                  <UserInputWrapper>
                    <InputBox>
                      <InputWrapper>
                        <SkipButton>Skip</SkipButton>
                        <InputInner>
                          <Input disabled value={""} type="text" placeholder="Send a message..." />
                        </InputInner>
                        <SendButton>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                          </svg>
                        </SendButton>
                      </InputWrapper>
                    </InputBox>
                  </UserInputWrapper>
                </UserInputContainer>
              </ConversationFooter>
            </AppFrame>
          )}
          {showThankYouScreen && (
            <ThankYouScreenWrapper>
              <TYSContentWrapper>
                <TYSContentWrapper1>
                  <TYSContentWrapper2>
                    <span dangerouslySetInnerHTML={{ __html: thankYouScreenData.descrption }}></span>
                  </TYSContentWrapper2>
                </TYSContentWrapper1>
              </TYSContentWrapper>
            </ThankYouScreenWrapper>
          )}
          <AppBackground />
        </Conversation>
      </ThemeProvider>
    </WidgetContainer>
  );
}
