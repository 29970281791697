import React from "react";
import { useSelector } from "react-redux";

export default function ImportStep1({ dataType, setDataType, setFields, setHideFields }) {
  const productColumns = useSelector((state) => state.Products.productFields);
  const peopleColumns = useSelector((state) => state.People.peopleFields);
  const companyColumns = useSelector((state) => state.Companies.companyFields);
  const leadColumns = useSelector((state) => state.Leads.leadFields);
  const marketingColums = useSelector((state) => state.campaign.marketing.dataField.fields);

  return (
    <div className="w-100 px-2">
      <div className="fv-row">
        <div className="row my-10">
          <div className="col-3">
            <input type="radio" className="btn-check" name="account_type" value="lead" checked={dataType === "lead"} />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              onClick={() => {
                setDataType("lead");
                setFields(leadColumns);
                setHideFields([]);
              }}
            >
              <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                <i className="bi bi-bullseye mb-2 font-size-30px"></i>
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-4">Leads</span>
                </span>
              </div>
            </label>
          </div>
          <div className="col-3">
            <input type="radio" className="btn-check" name="account_type" value="people" checked={dataType === "people"} />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              onClick={() => {
                setDataType("people");
                setFields(peopleColumns);
                setHideFields([]);
              }}
            >
              <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                <i className="bi bi-people mb-2 font-size-30px"></i>
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-4">People</span>
                </span>
              </div>
            </label>
          </div>
          <div className="col-3">
            <input type="radio" className="btn-check" name="account_type" value="company" checked={dataType === "company"} />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              onClick={() => {
                setDataType("company");
                setFields(companyColumns);
                setHideFields([]);
              }}
            >
              <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                <i className="bi bi-building mb-2 font-size-30px"></i>
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-4">Companies</span>
                </span>
              </div>
            </label>
          </div>
          <div className="col-3">
            <input type="radio" className="btn-check" name="account_type" value="product" checked={dataType === "product"} />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              onClick={() => {
                setDataType("product");
                setFields(productColumns);
                setHideFields([]);
              }}
            >
              <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                <i className="bi bi-basket2 mb-2 font-size-30px"></i>
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-4">Products</span>
                </span>
              </div>
            </label>
          </div>
          <div className="col-3">
            <input type="radio" className="btn-check" name="account_type" value="product" checked={dataType === "marketing"} />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
              onClick={() => {
                setDataType("marketing");
                setFields(marketingColums);
                setHideFields([]);
              }}
            >
              <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                <i className="bi bi-megaphone mb-2 font-size-30px"></i>
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-4">Marketing</span>
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
