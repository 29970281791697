import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { MarketingDetails } from "../components/marketing-details.component";
import { reorder } from "./helper";

export function MarketingLeftPannel(props) {
  const [order, setOrder] = useState(["marketing"]);

  useEffect(() => {
    const _order = localStorage.getItem("marketing-popup-order");
    if (_order) {
      const updated = _order.split(",");
      if (updated.length === 3) {
        setOrder(updated);
      } else {
        setOrder(["marketing"]);
      }
    } else {
      setOrder(["marketing"]);
    }
  }, []);

  const handleDragEnd = (param) => {
    const a = reorder(order, param.source.index, param.destination.index);
    setOrder(a);
    localStorage.setItem("marketing-popup-order", a.join(","));
  };

  const marketing = (index) => (
    <MarketingDetails
      index={index}
      showSwitchPerson={true}
      page={props.page}
      pageSub={props.pageSub}
      peopleId={props.peopleId}
      moduleType={props.moduleType}
      pageForm={props.pageForm}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      isSecondaryModule={true}
    />
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-side-panel">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {order.map((each, index) => {
              if (each === "marketing") return marketing(index);
              else return <div key={index}></div>;
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
