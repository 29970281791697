import { combineReducers } from "redux";
import * as auth from "../containers/modules/Auth/_redux/authRedux";
import * as Lead from "../containers/leads/store/reducers/reducer";
import * as Deal from "../containers/deals/dealPipeline/reducer";
import * as DealList from "../containers/deals/dealList/reducer";
import * as Activities from "../containers/myactivity/reducer";
import * as Peoples from "../containers/customers/store/reducers/reducer";
import * as Companies from "../containers/companies/store/reducers/reducer";
import * as ProfileSettings from "../containers/Settings/reducer/settings.reducers";
import * as LoginPage from "../containers/modules/Auth/reducers";
import snackbar from "./snackbarReducer";
import * as Products from "../containers/products/store/reducers/reducer";
import * as LeadBot from "../containers/leadbot/reducer";
import * as ImportExport from "../containers/import/reducer";
import * as Insight from "../containers/insights/store/reducers/dashboard.reducer";
import * as SyncSettings from "../containers/Settings/reducer/sync-reducer";
import * as ActivitySettings from "../containers/Settings/reducer/activity.reducer";
import * as CustomFieldSettings from "../containers/Settings/reducer/custom-fields.reducer";
import * as IntegrationSettings from "../containers/Settings/reducer/integrations.reducer";
import * as usageRecordReducer from "../containers/Settings/reducer/usage.reducer";
import * as AdminSettingsReducer from "../containers/Settings/reducer/admin-settings.reducer";
import * as NotesReducer from "../containers/Settings/reducer/notes.reducer";
import ActivityModuleReducer from "../containers/Activities/store/reducers";
import workflowReducer from "../containers/Automation/workflow/reducers";
import InsightReducer from "../containers/insights/store/reducers";
import LearnReducer from "../containers/learn/store/reducer";
import CampaignReducer from "../containers/campaign/store/reducers";
import BillingReducer from "../containers/Settings/Billing/store/reducers";
import SettingReducer from "../containers/Settings/store/reducers";
import CadenceReducer from "../containers/Automation/cadence/store/reducers";
import CommonReducer from "../containers/leads/store/reducers/common.reducer";
import integrationsAppsReducer from "../containers/Integrations/store/reducers";

export const rootReducer = combineReducers({
  snackbar,
  workflowReducer,
  InsightReducer,
  Learn: LearnReducer,
  campaign: CampaignReducer,
  cadence: CadenceReducer,
  auth: auth.reducer,
  Leads: Lead.leadsReducer,
  DealPipeline: Deal.dealsReducer,
  DealList: DealList.dealListReducer,
  activity: ActivityModuleReducer,
  Myactivity: Activities.activitiesReducer,
  People: Peoples.peopleReducer,
  Companies: Companies.companiesReducer,
  ProfileSettings: ProfileSettings.profileReducer,
  Products: Products.productsReducer,
  Login: LoginPage.LoginReducer,
  // Billing: Billings.billingReducer,
  Billing: BillingReducer,
  ImportExport: ImportExport.importExportReducer,
  sync: SyncSettings.syncReducer,
  activitySetting: ActivitySettings.settingActivityReducer,
  insight: Insight.InsightReducer,
  customFields: CustomFieldSettings.customFieldReducer,
  Integrations: IntegrationSettings.IntegrationSettingsReducer,
  usage: usageRecordReducer.usageRecordReducer,
  adminSettings: AdminSettingsReducer.adminSettingsReducer,
  leadBot: LeadBot.leadBotReducer,
  notes: NotesReducer.notesReducer,
  settings: SettingReducer,
  common: CommonReducer,
  integrationApps: integrationsAppsReducer
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
