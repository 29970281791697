import React from "react";
import { Form } from "react-bootstrap";
import { CustomButtonGroupWithValue } from "../../../../../components/button/custom-group-button";

//styles
import "./styles.scss";

export default function TriggerStep(props) {
  const options = [
    { label: "Created", value: "create" },
    { label: "Edited", value: "edit" },
    { label: "Created or Edited", value: "createOrEdit" },
  ];
  const mapping = {
    create: "Created",
    edit: "Edited",
    createOrEdit: "Created or Edited",
  };

  return (
    <div className="card trigger-wrapper">
      <div className="module-header-text">Set Trigger</div>
      <Form.Group id="automation-steps-trigger-step2" className="mb-0">
        <div className="trigger-label">Trigger this workflow when a event is {mapping[props.executeWhen] ? mapping[props.executeWhen] : ""}</div>
        <CustomButtonGroupWithValue options={options} handleClick={props.setExecuteWhen} active={props.executeWhen} />
      </Form.Group>
    </div>
  );
}
