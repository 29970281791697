import { takeLatest, put, call } from "redux-saga/effects";
// import { dispatchSnackbarSuccess, dispatchSnackbarError } from '../../../utils/toaster';

import api from "../../../network/apis/api";
import * as usageActions from "../actions/usage.actions";

import { GET_USAGE_RECORD_REPORTS_REQUEST, getUsageRecordsV2 } from "../types/usage.types";

function* getUsageRecordReports(action) {
  try {
    const response = yield call(api.get, "/api/crm/reports-usage/");
    if (response.status === 200) {
      yield put(usageActions.getUsageRecordReportSuccess(response.data.data));
    } else yield put(usageActions.getUsageRecordReportError("error"));
  } catch (e) {
    yield put(usageActions.getUsageRecordReportError(e));
  }
}
function* getUsageRecordsV2Fun(action) {
  try {
    const response = yield call(api.get, "/api/crm/reports-usage/v2/get");
    if (response.status === 200) {
      yield put(usageActions.getUsageRecordReportV2Success(response.data.data));
    } else yield put(usageActions.getUsageRecordReportV2Error("error"));
  } catch (e) {
    yield put(usageActions.getUsageRecordReportV2Error(e));
  }
}

export default function*() {
  //email sync saga
  yield takeLatest(GET_USAGE_RECORD_REPORTS_REQUEST, getUsageRecordReports);
  yield takeLatest(getUsageRecordsV2.GET_USAGE_RECORDS_V2_REQUEST, getUsageRecordsV2Fun);
}
