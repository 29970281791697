import _ from "lodash";
import React, { useEffect, useState } from "react";
import { count } from "sms-length";
import TemplateManager from "../../../../../components/templates";
import VariableOptionsPro from "../../../../../components/variable-options";
import ShortLinkManager from "../../campaign-short-link/short-link-modal";
import "./styles.scss";
import AIWizardSidePanel from "../../../../../components/ai-wizard/AIWizardSidePanel";

export default function SMSEditor(props) {
  const [showTemplateSelector, setShowTemplateSelectore] = useState(false);
  const [showShortLinkManager, setShowShortLinkManager] = useState(false);
  const [showVariableOptions, setShowVariableOptions] = useState(false);
  const [showAIWizardSidePanel, setShowAIWizardSidePanel] = useState(false);

  const [messageStats, setMessageStats] = useState({
    characterPerMessage: 160,
    encoding: "GSM_7BIT",
    inCurrentMessage: 0,
    length: 0,
    messages: 1,
    remaining: 160,
  });

  useEffect(() => {
    const a = count(props.template);
    setMessageStats(a);
  }, [props.template]);

  const onChooseClick = (data) => {
    props.setIsTemplate(true);
    props.setTemplateId(data._id);
    props.setTemplate(data.content);
    const a = count(data.content);
    props.setConfig({ ...props.config, isUnicode: a.encoding === "UTF16" ? true : false });
    setShowTemplateSelectore(false);
    if (!_.isEmpty(data.defaultVariableValues)) {
      props.setDefaultVariableValues({ ...props.defaultVariableValues, ...data.defaultVariableValues });
    }
  };

  const cancelSelectTemplate = () => {
    // props.setIsTemplate(false);
    // props.setTemplateId(null);
    // props.setTemplate("");
    // props.setConfig({ ...props.config, isUnicode: false });
    setShowTemplateSelectore(false);
  };

  const handleEmailBuilderEdit = () => {
    setShowTemplateSelectore(true);
  };

  const handleMessageChange = (value) => {
    props.setIsTemplate(false);
    props.setTemplateId(null);
    props.setTemplate(value);
    const a = count(value);
    props.setConfig({ ...props.config, isUnicode: a.encoding === "UTF16" ? true : false });
  };

  const handleShortLinkSelect = (shortLink) => {
    const content = `${props.template} ${shortLink}{advanced}`;
    props.setIsTemplate(false);
    props.setTemplateId(null);
    props.setTemplate(content);
    const a = count(content);
    props.setConfig({ ...props.config, isUnicode: a.encoding === "UTF16" ? true : false });
    setShowTemplateSelectore(false);
  };

  const addVariable = (item, key, variable) => {
    const content = `${props.template} ${variable}`;
    props.setIsTemplate(false);
    props.setTemplateId(null);
    props.setTemplate(content);
    const a = count(content);
    props.setConfig({ ...props.config, isUnicode: a.encoding === "UTF16" ? true : false });
    setShowTemplateSelectore(false);
  };

  return (
    <>
      <div className="sms-text-editor-wrapper">
        <textarea placeholder="Type your message here..." id="textarea" value={props.template} onChange={(e) => handleMessageChange(e.target.value)} className="sms-textarea" cols="50" rows="10" maxlength="4000" />
        <div className="sms-text-editor-options-wrapper">
          <div className="sms-template-insert-wraper">
            <div className="cursor-pointer" onClick={() => setShowShortLinkManager(true)}>
              <i class="bi bi-link"></i>
              <span>Insert</span>
            </div>
            <div className="cursor-pointer" onClick={handleEmailBuilderEdit}>
              <i class="bi bi-list-nested"></i>
              <span> Template</span>
            </div>
            <div className="cursor-pointer" onClick={() => setShowVariableOptions(true)}>
              <i class="bi bi-braces-asterisk"></i>
              <span> Variables</span>
            </div>
            {props.showAIWizard && (
              <div className="cursor-pointer" onClick={() => setShowAIWizardSidePanel(true)}>
                <i class="bi bi-filetype-ai"></i>
                <span> AI Wizard</span>
              </div>
            )}
          </div>
          <div className="sms-encoding-message-wrapper">
            <div>
              Message Type: <strong>{messageStats.encoding === "UTF16" ? "Unicode" : "English"}</strong>
            </div>
            <div>
              <strong>{messageStats.messages}</strong> msg
            </div>
            <div>
              <strong>{messageStats.length}</strong>/4000
            </div>
          </div>
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <TemplateManager
          smsFilter={{ senderIds: props.config.senderIds }}
          show={showTemplateSelector}
          channel={"sms"}
          category={"Marketing"}
          showChoose={true}
          onCloseClick={(data) => onChooseClick(data)}
          handleClose={cancelSelectTemplate}
        />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <ShortLinkManager handleShortLinkSelect={handleShortLinkSelect} show={showShortLinkManager} handleClose={() => setShowShortLinkManager(false)} />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <VariableOptionsPro
          show={showVariableOptions}
          setShow={setShowVariableOptions}
          addVariable={addVariable}
          defaultVariableValues={props.defaultVariableValues}
          setDefaultVariableValues={props.setDefaultVariableValues}
          customVariables={props.uploadedfileVariables}
        />
        <AIWizardSidePanel
          show={showAIWizardSidePanel}
          handleClose={() => {
            setShowAIWizardSidePanel(false);
          }}
        />
      </div>
    </>
  );
}
