import {
  ADD_NOTES_REQUEST,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_ERROR,
  EDIT_NOTES_REQUEST,
  EDIT_NOTES_SUCCESS,
  EDIT_NOTES_ERROR,
  DELETE_NOTES_REQUEST,
  DELETE_NOTES_SUCCESS,
  DELETE_NOTES_ERROR,
  GET_NOTES_REQUEST,
  GET_NOTES_SUCCESS,
  GET_NOTES_ERROR,
  GET_NOTES_BY_ID_REQUEST,
  GET_NOTES_BY_ID_SUCCESS,
  GET_NOTES_BY_ID_ERROR,
  GET_LINKED_EMAILS_REQUEST,
  GET_LINKED_EMAILS_SUCCESS,
  GET_LINKED_EMAILS_ERROR,
  GET_LINKED_ACTIVITIES_REQUEST,
  GET_LINKED_ACTIVITIES_SUCCESS,
  GET_LINKED_ACTIVITIES_ERROR,
  getUpcomingActivities,
} from "../types/notes.types";

export function AddNotesRequest(req) {
  return {
    type: ADD_NOTES_REQUEST,
    payload: req,
  };
}
export function AddNotesSuccess(req) {
  return {
    type: ADD_NOTES_SUCCESS,
    payload: req,
  };
}
export function AddNotesError(req) {
  return {
    type: ADD_NOTES_ERROR,
    payload: req,
  };
}

export function EditNotesRequest(req) {
  return {
    type: EDIT_NOTES_REQUEST,
    payload: req,
  };
}
export function EditNotesSuccess(req) {
  return {
    type: EDIT_NOTES_SUCCESS,
    payload: req,
  };
}
export function EditNotesError(req) {
  return {
    type: EDIT_NOTES_ERROR,
    payload: req,
  };
}

export function DeleteNotesRequest(req) {
  return {
    type: DELETE_NOTES_REQUEST,
    payload: req,
  };
}
export function DeleteNotesSuccess(req) {
  return {
    type: DELETE_NOTES_SUCCESS,
    payload: req,
  };
}
export function DeleteNotesError(req) {
  return {
    type: DELETE_NOTES_ERROR,
    payload: req,
  };
}

export function GetNotesRequest(req) {
  return {
    type: GET_NOTES_REQUEST,
    payload: req,
  };
}
export function GetNotesSuccess(req) {
  return {
    type: GET_NOTES_SUCCESS,
    payload: req,
  };
}
export function GetNotesError(req) {
  return {
    type: GET_NOTES_ERROR,
    payload: req,
  };
}

export function GetNotesByIdRequest(req) {
  return {
    type: GET_NOTES_BY_ID_REQUEST,
    payload: req,
  };
}
export function GetNotesByIdSuccess(req) {
  return {
    type: GET_NOTES_BY_ID_SUCCESS,
    payload: req,
  };
}
export function GetNotesByIdError(req) {
  return {
    type: GET_NOTES_BY_ID_ERROR,
    payload: req,
  };
}

export function GetLinkedEmailsRequest(req, cb) {
  return {
    type: GET_LINKED_EMAILS_REQUEST,
    payload: req,
    cb,
  };
}
export function GetLinkedEmailsSuccess(req) {
  return {
    type: GET_LINKED_EMAILS_SUCCESS,
    payload: req,
  };
}
export function GetLinkedEmailsError(req) {
  return {
    type: GET_LINKED_EMAILS_ERROR,
    payload: req,
  };
}

export function GetLinkedActivitiesRequest(req) {
  return {
    type: GET_LINKED_ACTIVITIES_REQUEST,
    payload: req,
  };
}
export function GetLinkedActivitiesSuccess(req) {
  return {
    type: GET_LINKED_ACTIVITIES_SUCCESS,
    payload: req,
  };
}
export function GetLinkedActivitiesError(req) {
  return {
    type: GET_LINKED_ACTIVITIES_ERROR,
    payload: req,
  };
}

export function GetUpcomingActivitiesRequest(req) {
  return {
    type: getUpcomingActivities.GET_UPCOMING_ACTIVITIES_REQUEST,
    payload: req,
  };
}
export function GetUpcomingActivitiesSuccess(req) {
  return {
    type: getUpcomingActivities.GET_UPCOMING_ACTIVITIES_SUCCESS,
    payload: req,
  };
}
export function GetUpcomingActivitiesError(req) {
  return {
    type: getUpcomingActivities.GET_UPCOMING_ACTIVITIES_ERROR,
    payload: req,
  };
}
