import React from "react";

export default function ContentWrapper(props) {
  return (
    <>
      <div>{props.subHeader}</div>
      <div className="mx-5">{props.children}</div>
    </>
  );
}

export function ContentWrapper2(props) {
  return (
    <>
      <div>{props.subHeader}</div>
      <div className="mx-0">{props.children}</div>
    </>
  );
}
