export const getListOfEmailValidation = {
    REQUEST: 'GET_LIST_OF_EMAIL_VALIDATION_REQUEST',
    SUCCESS: 'GET_LIST_OF_EMAIL_VALIDATION_SUCCESS',
    FAILURE: 'GET_LIST_OF_EMAIL_VALIDATION_FAILURE'
}

export const createEmailValidation = {
    REQUEST: 'CREATE_EMAIL_VALIDATION_REQUEST',
    SUCCESS: 'CREATE_EMAIL_VALIDATION_SUCCESS',
    FAILURE: 'CREATE_EMAIL_VALIDATION_FAILURE'
}


export const getEmailValidationPricing = {
    REQUEST: 'GET_EMAIL_VALIDATION_PRICING_REQUEST',
    SUCCESS: 'GET_EMAIL_VALIDATION_PRICING_SUCCESS',
    FAILURE: 'GET_EMAIL_VALIDATION_PRICING_FAILURE'
}