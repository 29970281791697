import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { login } from "../_redux/authCrud";
import { getGeoLocation } from "./utils/geoLocation";
import * as actions from "../actions";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import { useLocation } from "react-router-dom";
import { getAccountBillingV2Request } from "../../../Settings/Billing/store/actions/subscription_billing_v2_actions";
import DeactiveCompany from "../../../Settings/account/company-setting/deactivate-company";
import { InputLimit } from "../../../../constants";
import CommonWrapper from "./componets/common-wrapper";

const { detect } = require("detect-browser");

function Login(props) {
  const browser = useRef(detect());
  const history = useHistory();

  // Get Query Parameter
  const search = useLocation().search;
  const codeParams = new URLSearchParams(search).get("code");
  // /////////////////////////////////////////////////////////////
  // const { userIP, city, region } = useGeoLocation
  const Login = useSelector((state) => state.Login);

  // const [FullEmail, setFullEmail] = useState('');

  useEffect(() => {
    localStorage.removeItem("typeOfComp");
    localStorage.removeItem("experience");
    localStorage.removeItem("size");
    localStorage.removeItem("country");
    localStorage.removeItem("phone");
  }, [Login]);

  const [showIcon, setshowIcon] = useState(true);

  const showPassword = () => {
    setshowIcon(false);
    document.getElementById("loginPassword").type = "text";
  };
  const hidePassword = () => {
    setshowIcon(true);
    document.getElementById("loginPassword").type = "password";
  };

  const initialValues = {
    device: browser.current.os,
    platform: browser.current.name,
    rememberLogin: true,
    loginMethod: "PASSWORD",
    isGoogleAttempt: false,
    email: "",
    password: "",
  };

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const signInGoogle = () => {
    dispatch(
      actions.GoogleAPIRequest("login", (data) => {
        const newWindow = window.open(data.url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      }),
    );
  };

  useEffect(() => {
    const googlePostFunction = async () => {
      const geoLoc = await getGeoLocation();

      if (codeParams !== null) {
        const values = {
          location: `${geoLoc.city} ${geoLoc.region}`,
          device: browser.current.os,
          platform: browser.current.name,
          IPAddr: geoLoc.ip,
          rememberLogin: true,
          loginMethod: "PASSWORD",
          isGoogleAttempt: true,
        };

        dispatch(
          actions.PostGoogleAPIRequest({ code: codeParams, page: "login", ...values }, (data) => {
            if (data["success"]) {
              localStorage.setItem("access_token", data["token"]);
              dispatchSnackbarSuccess(data["message"], "success");
              localStorage.removeItem("GoogleSignIn");
              dispatch(
                getAccountBillingV2Request({}, (data) => {
                  const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = data;
                  if (!isFreeTrialPeriod && !isSubscribedForLifeTimeFree && !["active", "past_due", "trialing"].includes(subscriptionStatus)) {
                    window.location.href = "/settings/billing/subscription";
                  } else {
                    window.location.href = "/activity/activity-list";
                  }
                }),
              );
            }
          }),
        );
      }
    };
    googlePostFunction();
  }, [codeParams, dispatch]);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      enableLoading();
      const geoLoc = await getGeoLocation();
      values["IPAddr"] = geoLoc.ip;
      values["location"] = `${geoLoc.city} ${geoLoc.region}`;
      dispatch(
        actions.loginRequest(values, (data) => {
          if (data["success"]) {
            if (data["message"] === "OTP sent to registered email") {
              localStorage.setItem("loginValue", JSON.stringify(values));
              dispatchSnackbarSuccess("Check Your Email For OTP", "success");
              history.push("/auth/2FA");
            } else {
              localStorage.setItem("access_token", data["token"]);
              dispatch(
                getAccountBillingV2Request({}, (data) => {
                  const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = data;
                  if (!isFreeTrialPeriod && !isSubscribedForLifeTimeFree && !["active", "past_due", "trialing"].includes(subscriptionStatus)) {
                    window.location.href = "/settings/billing/subscription";
                  } else if (data.accountStatus === "DEACTIVATED") {
                    window.location.href = "/settings/account/company";
                    return <DeactiveCompany />;
                  } else {
                  }
                }),
              );
              dispatchSnackbarSuccess("Login Successfully", "success");
            }
          }
        }),
      );
      setLoading(false);
    },
  });

  return (
    <CommonWrapper>
      <form className="form w-100" onSubmit={formik.handleSubmit}>
        {/*begin::Heading*/}
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-dark mb-3">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h1>
          {/*end::Title*/}
          {/*begin::Link*/}
          <div className="text-gray-400 fw-bold fs-4">
            New Here?
            {/* <a href="authentication/flows/basic/sign-up.html" className="link-primary fw-bolder">Create an Account</a> */}
            <Link to="/auth/registration" className="link-primary fw-bolder" id="kt_login_signup">
              &nbsp;Create an Account
            </Link>
          </div>
          {/*end::Link*/}
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input maxLength={InputLimit.EMAIL} placeholder="Email" type="email" className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
            <Link to="/auth/forgot-password" className="link-primary fs-6 fw-bolder" id="kt_login_forgot">
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>
          <input
            maxLength={InputLimit.PASSWORD}
            id="loginPassword"
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
            name="password"
            {...formik.getFieldProps("password")}
          />

          {showIcon === false && <span onClick={() => hidePassword()} toggle="#password-field" className="bi bi-eye position-relative left-88-percent top-minus-39px font-size-18px"></span>}
          {showIcon === true && <span onClick={() => showPassword()} toggle="#password-field" className="bi bi-eye-slash position-relative left-88-percent top-minus-39px font-size-18px"></span>}

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="text-center">
          <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary fw-bolder me-3 my-2" disabled={formik.isSubmitting}>
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <span onClick={signInGoogle} className="btn btn-light-primary btn-lg fw-bolder my-2">
            <img alt="Logo" src={require("../../../../images/google.svg")} className="h-20px me-3" />
            Sign in with Google
          </span>
        </div>
      </form>
    </CommonWrapper>
  );
}

export default injectIntl(connect(null, actions)(Login));
