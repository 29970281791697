import React, { useEffect, useState } from "react";
import "../../../../../../_metronic/_assets/sass/pages/error/error-5.scss";
import { BlueGradientButton } from "../../../../../../components/button";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import subscriptionSvg from "../../../../../../images/illustrations/cart.svg";

export default function SubscriptionExpired(props) {
    const [isAgent, setIsAgent] = useState(false);
    const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

    useEffect(() => {
        if (userProfile && userProfile.data && userProfile.data.userType === "AGENT") {
            setIsAgent(true);
        } else {
            setIsAgent(false);
        }
    }, [userProfile]);

    return (
        <Card className="h-vh-161px">
            <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
                <div className="card-px text-center py-5 my-5">
                    <h2 className="fs-5x fw-bolder mb-10 mt-5">Oops!</h2>
                    <p className="text-gray-700 fs-4 mb-8 w-50vw">
                        <p>{isAgent ? "Your subscription expired! Please contact your admin to renew it and get back to using Sales Simplify." : "Your subscription has expired."}</p>
                    </p>
                    {!isAgent && (
                        <div className="d-flex gap-4 flex-center">
                            <BlueGradientButton onClick={() => props.setShowPlans(true)}>SUBSCRIBE</BlueGradientButton>
                        </div>
                    )}
                </div>
                <img src={subscriptionSvg} className="mx-auto h-50vh" alt="add" />
            </Card.Body>
        </Card>
    );
}