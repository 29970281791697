import React from "react";
import { DeleteIconButton, EditIconButton, IconButton } from "../../button/icon-action-buttons";
import { AnalyticsSVG, ChooseSVG, DownloadSVG } from "../../custom-svgs/action-svg";

export default function DataTableActions(props) {
  return (
    <div className="d-flex flex-row flex-center table-action-wrapper">
      {props.options.includes("choose") && (
        <IconButton tooltip={"Choose"} onClick={() => props.handleOnChoose(props.data)}>
          {ChooseSVG}
        </IconButton>
      )}
      {props.options.includes("report") && (
        <IconButton tooltip={"Reports"} onClick={() => props.handleReport(props.data)}>
          {AnalyticsSVG}
        </IconButton>
      )}
      {props.options.includes("download") && (
        <IconButton tooltip={"Download Report"} onClick={() => props.handleDownload(props.data)}>
          {DownloadSVG}
        </IconButton>
      )}
      {props.options.includes("edit") && <EditIconButton onClick={() => props.handleEdit(props.data)} />}
      {props.options.includes("delete") && <DeleteIconButton onClick={() => props.handleDelete(props.data)} />}
    </div>
  );
}
