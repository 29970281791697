import React, { useEffect, useState } from "react";
import _ from "lodash";
import CustomSidePanel from "../../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import AddWhatsappTemplate from "./add-whatsapp-template/index";
import * as WhatsappTemplateActions from "../../../store/actions/campaign_whatsapp_template_actions";
import * as WhatsappSettingsActions from "../../../store/actions/campaign_whatsapp_setting_actions";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import { useHistory } from "react-router-dom";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import AdavanceDataTable from "../../../../../components/normal-table/advance-table";
import { DeleteModal } from "../../../../../components/modal";

const whatsappTags = {
    "APPROVED": "#99e8a7",
    "REJECTED": "#FF8D7D",
    "PENDING": "#ffe786"
}

export default function WhatsappTemplateList(props) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [integrationId, setIntegrationId] = useState("");
    const [integrationOptions, setIntegrationOptions] = useState([]);

    const [showAddTemplate, setShowAddTemplate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [showDelete, setShowDelete] = useState(false);
    const [createEditLoading, setCreateEditLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const whatsappAccountStore = useSelector(state => state.campaign.settings.whatsapp.data);
    const whatsappTemplateStore = useSelector(state => state.campaign.whatsappTemplate);

    useEffect(() => {
        if (page && limit && integrationId) {
            const payload = { page, limit, integrationId }
            dispatch(WhatsappTemplateActions.getListOfWhatsappTemplatesRequest(payload))
        }
    }, [dispatch, page, limit, integrationId]);

    useEffect(() => {
        if (integrationId)
            dispatch(WhatsappSettingsActions.getWhatsappNumberProfileRequest({ id: integrationId }))
    }, [dispatch, integrationId])

    useEffect(() => {
        dispatch(WhatsappSettingsActions.getListOfWhatsappAccountsRequest())
    }, [dispatch])

    useEffect(() => {
        if (!_.isEmpty(props.filter)) {
            setIntegrationId(props.filter.integrationId)
        }
    }, [props.filter])

    useEffect(() => {
        if (whatsappAccountStore.list.length) {
            const options = whatsappAccountStore.list.map((item) => {
                return { value: item._id, label: item.displayPhoneNumber }
            })
            setIntegrationOptions(options)
            if (_.isEmpty(props.filter)) {
                setIntegrationId(whatsappAccountStore.list[0]._id)
            }
        }
    }, [whatsappAccountStore.list, props.filter])

    const handleAddAccount = () => {
        history.push("/campaign/settings/whatsapp/manage")
    }

    const handleSyncClick = () => {
        dispatch(WhatsappTemplateActions.syncWithFacebookWhatsappTemplatesRequest({ integrationId: integrationId }))
    }

    const whatsappTableColoumns = [
        { title: "Template Name", apiKeyName: "name", fieldInputType: "INPUT", isAction: false, isFixed: false },
        { title: "Category", apiKeyName: "category", fieldInputType: "INPUT", isAction: false, isFixed: false },
        { title: "Language", apiKeyName: "language", fieldInputType: "INPUT", isAction: false, isFixed: false },
        { title: "Status", apiKeyName: "status2", fieldInputType: "TAG_DISPLAY", isAction: false, isFixed: false },
        { title: "Created At", apiKeyName: "createdAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },
        { title: "Actions", apiKeyName: "action", fieldInputType: "ACTION", isAction: true, isFixed: true, options: props.showChoose ? ["choose", "delete"] : ["delete"] },
    ];

    const handleCloseAddEditTemplate = () => {
        setShowAddTemplate(false);
        setIsEdit(false);
        setSelectedData({});
    }

    const handleDeleteClick = (data) => {
        setShowDelete(true);
        setSelectedData(data);
    }
    const handleEditClick = (data) => {
        setShowAddTemplate(true)
        setIsEdit(true)
        setSelectedData(data)
    }
    const handleChooseClick = () => {

    }

    const handleSave = (payload) => {
        setCreateEditLoading(true)
        if (isEdit) {
            dispatch(WhatsappTemplateActions.updateWhatsappTemplateRequest({
                ...payload,
                integrationId: integrationId,
                _id: selectedData._id
            }, (data) => {
                if (data.status) {
                    handleCloseAddEditTemplate();
                }
                setCreateEditLoading(false)
            }))
        }
        else {
            dispatch(WhatsappTemplateActions.createWhatsappTemplateRequest({
                ...payload,
                channelAccountId: integrationId,
                integrationId: integrationId,
                "type": "cloud_api",
            }, (data) => {
                if (data.status) {
                    handleCloseAddEditTemplate();
                }
                setCreateEditLoading(false)
            }))
        }
    }

    const handleDeleteTemplateAccept = () => {
        dispatch(WhatsappTemplateActions.deleteWhatsappTemplateRequest({
            integrationId: integrationId,
            templateId: selectedData._id
        }))
        setShowDelete(false);
        setSelectedData({});
    }
    const handleDeleteTemplateCancel = () => {
        setShowDelete(false);
        setSelectedData({});
    }
    const deleteTemplateModalProps = {
        show: showDelete,
        title: "Delete Whatsapp Template",
        handleClose: handleDeleteTemplateCancel,
        handleAccept: handleDeleteTemplateAccept,
    };

    return <CustomSidePanel show={props.show} moduleName={`${props.channel === "sms" ? "SMS" : _.capitalize(props.channel)} Templates`} handleClose={props.handleClose}>
        <div className="whatsapp-template-wrapper">
            <div className="h-70px d-flex flex-stack align-items-center px-8 border-bottom align-items-lg-center">
                <h3 className="font-size-16px fw-bolder text-dark ">{`Whatsapp Templates`}</h3>
                <div className="w-480px d-flex gap-4">
                    {integrationId && <Button variant="link" size="sm" className="w-100" onClick={() => handleSyncClick(true)}>Sync With Facebook</Button>}
                    <Button variant="primary" size="sm" className="w-100" onClick={() => { setShowAddTemplate(true); setIsEdit(false) }}>Add Template</Button>
                    <SimpleDropdown disabled={false} value={integrationId} options={integrationOptions} label="Select Phone Number" handleSelect={(val) => setIntegrationId(val.value)} showSearch />
                </div>
            </div>
            <div>
                {
                    whatsappTemplateStore.loading || whatsappAccountStore.loading ? <div className="w-100 scroll-y pt-10 pb-10 d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="primary" />
                    </div> :
                        whatsappAccountStore.list.length === 0 ? <CustomEmptyPage page="whastapp-account" setShowAdd={() => handleAddAccount()} /> :
                            whatsappTemplateStore.list.length === 0 ? <CustomEmptyPage page="noTemplates" setShowAdd={() => { setShowAddTemplate(true); setIsEdit(false) }} />
                                : <div className=" px-8 pb-8 pt-6">
                                    <div className=" py-10px px-10px border-radius-4px border-width-1px border-style-solid border-color-e0e0e0">
                                        <AdavanceDataTable
                                            columns={whatsappTableColoumns}
                                            data={whatsappTemplateStore.list.map((each) => {
                                                return {
                                                    ...each,
                                                    status2: [{ colorCode: whatsappTags[each.status], name: each.status }]
                                                }
                                            })}
                                            limit={limit}
                                            pageNo={page}
                                            setLimit={setLimit}
                                            setPageNo={setPage}
                                            count={whatsappTemplateStore.count}

                                            //actions
                                            handleEdit={handleEditClick}
                                            handleDelete={handleDeleteClick}
                                            handleOnChoose={handleChooseClick}
                                        />
                                    </div>
                                </div>
                }
            </div>
            <CustomSidePanel show={showAddTemplate} moduleName={`Add Templates`} handleClose={handleCloseAddEditTemplate}>
                <AddWhatsappTemplate createEditLoading={createEditLoading} isEdit={isEdit} integrationId={integrationId} selectedData={selectedData} handleSave={handleSave} />
            </CustomSidePanel>
            <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete the template?</DeleteModal>

        </div>
    </CustomSidePanel>
}