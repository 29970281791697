import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import RadioSelector from "../../../components/custom-inputs/radio-selector";

const defaultValue = [
  {
    name: "SPF Record",
    type: "TXT",
    host_name: "@",
    value: "v=spf1 include:emails.salessimplify.com ~all",
  },
  {
    name: "DKIM Record",
    type: "TXT",
    host_name: "api._domainkey",
    value:
      "k=rsa;t=s;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbmGbQMzYeMvxwtNQoXN0waGYaciuKx8mtMh5czguT4EZlJXuCt6V+l56mmt3t68FEX5JJ0q4ijG71BGoFRkl87uJi7LrQt1ZZmZCvrEII0YO4mp8sDLXC8g1aUAoi8TJgxq2MJqCaMyj5kAm3Fdy2tzftPCV/lbdiJqmBnWKjtwIDAQAB",
  },
  {
    name: "DMRC Record",
    type: "TXT",
    host_name: "_dmarc",
    value: "v=DMARC1; p=quarantine;",
  },
  {
    name: "CNAME record",
    type: "CNAME",
    host_name: "tracking",
    value: "emails.salessimplify.com",
  },
];

export default function DomainDNSRecords(props) {
  const [records, setRecords] = useState([]);
  const [radioValue, setRadioValue] = useState("Add New SPF");
  const radioOptions = ["Add New SPF", "Already SPF in DNS"];

  useEffect(() => {
    if (props.domain) {
      const _records = JSON.parse(JSON.stringify(defaultValue));
      const domain = props.domain.split(" ")[0];
      const isSubdomain = domain.split(".").length > 2;
      if (isSubdomain) {
        const a = domain.split(".");
        const b = a.slice(0, a.length - 2);
        const subdomain = b.join(".");
        _records[0].host_name = subdomain;
        _records[1].host_name = `api._domainkey.${subdomain}`;
        _records[2].host_name = `_dmarc.${subdomain}`;
        _records[3].host_name = `tracking.${subdomain}`;
      }
      setRecords(_records);
    }
  }, [props.domain]);

  return (
    <Form.Group>
      <Form.Label className="fw-bold mt-4 text-black">DNS Settings</Form.Label>
      <div className="card p-0">
        <table className="w-100 border-0">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-secondary">Type</th>
              <th className="px-4 py-2 border border-secondary">Host/Name</th>
              <th className="px-4 py-2 border border-secondary">Value</th>
            </tr>
          </thead>
          <tbody>
            {records.map((each, index) => {
              return (
                <tr>
                  <td className="px-4 py-2 border border-secondary">{each.type}</td>
                  <td className="px-4 py-2 border border-secondary">{each.host_name}</td>
                  <td className="px-4 py-2 border border-secondary text-break">
                    {each.name === "SPF Record" ? (
                      <div>
                        <div>
                          <RadioSelector setValue={setRadioValue} value={radioValue} options={radioOptions} />
                        </div>
                        {radioValue === "Add New SPF" && <div className="p-2 bg-light rounded-2">{each.value}</div>}
                        {radioValue === "Already SPF in DNS" && (
                          <div>
                            <div className="mb-4">
                              Edit the <strong>TXT</strong> record and merge your existing <strong>Value</strong> with variable display below.
                            </div>
                            <div className="mb-4">
                              <div>For example, If your domain already has record:</div>
                              <div className="p-2 bg-light rounded-2">v=spf1 include:_spf.google.com ~all</div>
                            </div>
                            <div className="mb-4">
                              <div>You need to add this variable:</div>
                              <div className="p-2 bg-light rounded-2">include:emails.salessimplify.com</div>
                            </div>
                            <div className="mb-4">
                              <div>So, created variable will look like this:</div>
                              <div className="p-2 bg-light rounded-2">
                                v=spf1 <strong>include:emails.salessimplify.com</strong> include:_spf.google.com ~all
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="p-2 bg-light rounded-2">{each.value}</div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Form.Group>
  );
}
