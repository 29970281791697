import React, { useState } from "react";
import { InputGroup, Dropdown, Form, Popover, Button, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GreenGradientButton } from "../../components/button";
import { useSelector } from "react-redux";
import SelectableContext from "react-bootstrap/SelectableContext";
import { dispatchSnackbarError } from "../../utils/toaster";
import { InputLimit } from "../../constants";

export default function AdvancedDropdown({ storePath, storeSubPath, addAPI, editAPI, deleteAPI, field, value, setValue }) {
  const [search, setSearch] = useState("");
  const [newDataText, setNewDataText] = useState("");
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [hovering, setHovering] = useState();
  const [edit, setEdit] = useState({});
  const [show, setShow] = useState(false);
  const dataStore = useSelector((state) => state[storePath][storeSubPath]);

  const handleClose = (e) => {
    setHovering();
    setEditing(false);
    setAdding(false);
    setSearch("");
  };

  const handleSave = (e) => {
    setHovering();
    if (newDataText.trim() === "") {
      dispatchSnackbarError(`${field.fieldName} can not be blank!`);
      return;
    }
    setShow(true);
    addAPI(newDataText);
    setNewDataText("");
    setEditing(false);
    setSearch("");
    setAdding(false);
  };

  const handleCloseEdit = (e) => {
    setHovering();
    setEditing(false);
    setSearch("");
    setEdit({});
  };

  const handleEdit = (e) => {
    setHovering();
    if (edit.name.trim() === "") {
      dispatchSnackbarError(`${field.fieldName} can not be blank!`);
      return;
    }
    editAPI(edit["_id"], edit.name);
    setEditing(false);
    setSearch("");
    setEdit({});
  };

  const handleDelete = () => {
    setHovering();
    deleteAPI(edit["_id"]);
    setEditing(false);
    setSearch("");
    setEdit({});
  };

  return (
    <SelectableContext.Provider value={false}>
      <InputGroup>
        <div className={"d-flex w-100 justify-content-between"}>
          <Dropdown
            onSelect={(e, ev) => ev.stopPropagation()}
            show={show}
            onToggle={() => {
              setSearch("");
              setHovering();
              if (!show) {
                setEditing(false);
                setAdding(false);
                setEdit();
              }
              setShow(!show);
            }}
            className={`dropdown-inline mt-0 w-100`}
          >
            <Dropdown.Toggle className="d-flex align-items-center w-100 text-left z-index-0 py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant={"transparent"}>
              {!value || value === "" ? (
                <span> {`Select ${field.fieldName}`} </span>
              ) : (
                <span className="overflow-hidden text-overflow-ellipsis w-90-percent-mx text-333">
                  {dataStore.list.find((each) => each["_id"] === value) ? dataStore.list.find((each) => each["_id"] === value).name : null}
                </span>
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-xl p-0 w-100-mn w-400px z-index-9999">
              {dataStore.loading ? (
                <div className="w-100 d-flex justify-content-center align-items-center h-30vh-mn">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : editing ? (
                <>
                  {adding ? (
                    <div>
                      <Popover.Title className="py-4" as="h3">
                        Create {field.fieldName}
                      </Popover.Title>
                      <Popover.Content>
                        <div className="w-100">
                          <Form.Group id="advanced-dropdowns-create-data" className="mb-6">
                            <Form.Control maxLength={InputLimit.TEXT} value={newDataText} placeholder={field.fieldName} onChange={(e) => setNewDataText(e.target.value)}></Form.Control>
                          </Form.Group>
                        </div>
                        <div className="d-flex flex-row justify-content-center gap-15px mt-20px">
                          <Button variant="secondary" onClick={(e) => handleClose(e)} size="sm">
                            Cancel
                          </Button>
                          <GreenGradientButton onClick={(e) => handleSave(e)} size="sm">
                            Save
                          </GreenGradientButton>
                        </div>
                      </Popover.Content>
                    </div>
                  ) : (
                    <div>
                      <Popover.Title className="py-4" as="h3">
                        Edit {field.fieldName}{" "}
                      </Popover.Title>
                      <Popover.Content>
                        <div className="w-100">
                          <Form.Group id="advanced-dropdowns-edit-data" className="mb-6">
                            <Form.Control maxLength={InputLimit.TEXT} value={edit.name} placeholder={field.fieldName} onChange={(e) => setEdit({ ...edit, name: e.target.value })}></Form.Control>
                          </Form.Group>
                        </div>
                        <div className="d-flex flex-row flex-stack mt-20px">
                          <Button onClick={() => handleDelete()} variant="danger" size="sm">
                            Delete
                          </Button>
                          <div className="d-flex flex-row gap-15px">
                            <Button variant="secondary" onClick={(e) => handleCloseEdit(e)} size="sm">
                              Cancel
                            </Button>
                            <GreenGradientButton onClick={(e) => handleEdit(e)} size="sm">
                              Save
                            </GreenGradientButton>
                          </div>
                        </div>
                      </Popover.Content>
                    </div>
                  )}
                </>
              ) : (
                <div className="navi navi-hover">
                  <li className="navi-item py-20px px-24px">
                    <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={`Search ${field.fieldName}`} value={search} onChange={(e) => setSearch(e.target.value)} />
                  </li>
                  <Dropdown.Divider className="m-0" />
                  <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative mt-10px" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                    {dataStore.list.length === 0 ? (
                      <div className="w-100 d-flex flex-center h-50px-mn">
                        <span>{`No ${field.fieldName}s`}</span>
                      </div>
                    ) : (
                      <>
                        {dataStore.list.map((eachData, subindex) => {
                          return eachData.name && eachData.name.toLowerCase().includes(search.toLowerCase()) ? (
                            <Dropdown.Item
                              onMouseOver={() => setHovering(subindex)}
                              onMouseLeave={() => setHovering()}
                              className="dropdown-hover"
                              key={subindex}
                              onSelect={() => {
                                setValue(eachData["_id"], field.apiKeyName);
                              }}
                            >
                              <div className="w-100 d-flex flex-stack">
                                <span className="overflow-hidden text-overflow-ellipsis text-333">{eachData.name}</span>
                                {hovering === subindex ? (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditing(true);
                                      setEdit(eachData);
                                    }}
                                    className="btn btn-icon btn-light h-25px w-25px"
                                  >
                                    <i className="fa fa-sm fa-pen font-size-13px"></i>
                                  </button>
                                ) : (
                                  <span className="h-25px w-25px"></span>
                                )}
                              </div>
                            </Dropdown.Item>
                          ) : null;
                        })}
                      </>
                    )}
                  </PerfectScrollbar>
                  <Dropdown.Divider className="m-0" />
                  <div
                    onClick={() => {
                      setEditing(true);
                      setAdding(true);
                    }}
                    className="dropdown-item dropdown-hover py-4 cursor-pointer"
                  >
                    + Create {field.fieldName}
                  </div>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </InputGroup>
    </SelectableContext.Provider>
  );
}
