export const bulkEditProductV2 = {
  BULK_EDIT_PRODUCT_V2_REQUEST: "BULK_EDIT_PRODUCT_V2_REQUEST",
  BULK_EDIT_PRODUCT_V2_SUCCESS: "BULK_EDIT_PRODUCT_V2_SUCCESS",
  BULK_EDIT_PRODUCT_V2_ERROR: "BULK_EDIT_PRODUCT_V2_ERROR",
};

export const bulkDeleteProductV2 = {
  BULK_DELETE_PRODUCT_V2_REQUEST: "BULK_DELETE_PRODUCT_V2_REQUEST",
  BULK_DELETE_PRODUCT_V2_SUCCESS: "BULK_DELETE_PRODUCT_V2_SUCCESS",
  BULK_DELETE_PRODUCT_V2_ERROR: "BULK_DELETE_PRODUCT_V2_ERROR",
};
