// profile setting sagas
import overviewSaga from "./profile-settings-saga/overview-settings.saga";
import signInSaga from "./profile-settings-saga/signin-settings.saga";
import loginSessionSaga from "./profile-settings-saga/login-sessions-settings.saga";
import companyListSaga from "./profile-settings-saga/company-list-settings.saga";
// import emailSyncSaga from "./profile-settings-saga/email-sync-settings.saga";
// import calendarSyncSaga from "./profile-settings-saga/calendar-sync-settings.saga";
import notificationSaga from "./profile-settings-saga/notification-settings.saga";
import SchedularSettingsDaSaga from "./profile-settings-saga/scheduling-settings.saga";
import syncSettingsSaga from "./profile-settings-saga/sync-settings.saga";

// admin setting sagas
import companySettingsSaga from "./admin-settings-saga/company-settings.saga";
import currencySettingsSaga from "./admin-settings-saga/currency-settings.saga";
import activitySettingsSaga from "./admin-settings-saga/activity-settings.saga";
// import customFieldsSettingsSaga from "./admin-settings-saga/custom-fields-settings.saga";
import developerSettingsSaga from "./admin-settings-saga/developer-settings.saga";
import manageUsersSettingsSaga from "./admin-settings-saga/manage-users-settings.saga";
import outcomesSettingsSaga from "./admin-settings-saga/outcomes-settings.saga";
// import securitySettingsSaga from "./admin-settings-saga/security-settings.saga";
// import tagSettingsSaga from "./admin-settings-saga/tag-settings.saga";
// import teamsSettingsSaga from "./admin-settings-saga/teams-settings.saga";

export default [
  companySettingsSaga,
  currencySettingsSaga,
  activitySettingsSaga,
  // customFieldsSettingsSaga,
  developerSettingsSaga,
  manageUsersSettingsSaga,
  outcomesSettingsSaga,
  // securitySettingsSaga,
  // tagSettingsSaga,
  // teamsSettingsSaga,

  /* profile settings */
  overviewSaga,
  signInSaga,
  loginSessionSaga,
  companyListSaga,
  // emailSyncSaga,
  // calendarSyncSaga,
  notificationSaga,
  SchedularSettingsDaSaga,
  syncSettingsSaga,
];
