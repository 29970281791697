import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CustomScrollableModal, DeleteModal } from "../../../../components/modal";
import Stages from "../../stages";
import { Form } from "react-bootstrap";
// import Select from "react-select";
import { InputLimit } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
// import { customStyles, visibilityOptions } from "./constants";
import DeleteStageModal from "./delete-stage.modal";
import * as dealActions from "../../dealPipeline/actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { useHistory } from "react-router-dom";

/**
 * isEdit
editPipelineId
showAddPipelineModal
setIsEdit
setEditPipelineId
setShowAddPipelineModal
 */

export default function AddEditPipelineModelV2(props) {
  const [showDelete, setShowDelete] = useState(false);
  const [showDeletePipeline, setShowDeletePipeline] = useState(false);
  const [selectedStage, setSelectedStage] = useState({});

  //form
  const [pipelineName, setPipelineName] = useState("");
  const [stages, setStages] = useState([]);
  const [visibility, setVisibility] = useState({ value: "PUBLIC", label: "PUBLIC" });

  const dispatch = useDispatch();
  const history = useHistory();

  const pipelineOptionsStore = useSelector((store) => store.DealPipeline.pipelineOptionsResponse);

  useEffect(() => {
    if (props.isEdit && props.editPipelineId) {
      const _selectedPipeline = pipelineOptionsStore.find((a) => a._id === props.editPipelineId);
      if (_selectedPipeline) {
        setPipelineName(_selectedPipeline.name);
        setStages(_selectedPipeline.stages);
        setVisibility({ value: _selectedPipeline.visibility.toUpperCase(), label: _selectedPipeline.visibility.toUpperCase() });
      }
    } else {
      setPipelineName("");
      setStages([]);
      setVisibility({ value: "PUBLIC", label: "PUBLIC" });
    }
  }, [props.isEdit, props.editPipelineId, pipelineOptionsStore]);

  const onChangeStages = (a) => {
    setStages(a);
  };
  const onDeleteClick = (stageId) => {
    const removedStage = stages.find((stage) => stage._id === stageId);
    const remainedStages = stages.filter((stage) => stage._id !== stageId);
    if (stageId.includes(".")) {
      setStages(remainedStages);
    } else if (!_.isEmpty(removedStage)) {
      setSelectedStage(removedStage);
      setShowDelete(true);
    }
  };

  const onRemoveStage = (removedStage) => {
    setStages(stages.filter((a) => a._id !== removedStage.id));
    dispatch(
      dealActions.deletePipelineStageRequest({
        ...removedStage,
        pipelineId: props.editPipelineId,
      }),
    );
  };

  const onPipelineModalSave = () => {
    if (_.isEmpty(pipelineName)) return dispatchSnackbarError("Please enter pipeline Name!");
    if (stages && stages.length > 0) {
      for (let i = 0; i < stages.length; i++) {
        if (!stages[i].name) {
          return dispatchSnackbarError("Please enter Pipeline stage name");
        } else if (stages[i].probability === undefined) {
          return dispatchSnackbarError("Please enter Pipeline stage probability");
        } else if (stages[i].status === undefined) {
          return dispatchSnackbarError("Please enter Pipeline stage status");
        } else if (stages[i].color === undefined) {
          return dispatchSnackbarError("Please enter Pipeline stage color");
        }
      }
    }
    if (props.isEdit) {
      const editPayload = {
        name: pipelineName,
        stages: stages,
        visibility: visibility.label,
        bgColor: "#f7f7f7",
        pipelineId: props.editPipelineId,
        _id: props.editPipelineId,
      };
      dispatch(dealActions.updatePipelineRequest(editPayload));
      setStages([]);
      props.setIsEdit(false);
      props.setShowAddPipelineModal(false);
    } else {
      const addPayload = {
        name: pipelineName,
        stages: stages,
        visibility: visibility.label,
        bgColor: "#f7f7f7",
      };
      dispatch(
        dealActions.addPipelineRequest(addPayload, (data) => {
          if (data.status) {
            history.push(`/deal/${props.page}/${data.pipelineId}`);
            setStages([]);
            props.setIsEdit(false);
            props.setShowAddPipelineModal(false);
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    }
  };

  const onPipelineDeletePipeline = () => {
    dispatch(
      dealActions.deletePipelineRequest({ pipelineId: props.editPipelineId, newPipelineId: pipelineOptionsStore[0]._id }, (data) => {
        if (data.status) {
          dispatchSnackbarSuccess("Successfully deleted pipeline.");
          history.push(`/deal/${props.page}`);
        } else {
          dispatchSnackbarError(data.message);
        }
      }),
    );
    props.setIsEdit(false);
    props.setShowAddPipelineModal(false);
    setShowDeletePipeline(false);
  };

  const modelProps = {
    show: props.showAddPipelineModal,
    handleClose: () => props.setShowAddPipelineModal(false),
    title: props.isEdit ? "Edit Pipeline" : "Add Pipeline",
    showButtons: true,
    buttonText: "Save",
    showDeleteButton: props.isEdit ? true : false,
    handleAccept: onPipelineModalSave,
    handleDelete: () => setShowDeletePipeline(true),
    size: "lg",
  };
  return (
    <>
      <CustomScrollableModal modalProps={modelProps}>
        <div className="py-0 px-10px">
          <div className="d-flex align-items-center flex-row justify-content-between">
            <Form.Group className="w-100">
              <Form.Label aria-label="pipeline-name">Pipeline Name* </Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} type="text" value={pipelineName} onChange={(e) => setPipelineName(e.target.value)} />
            </Form.Group>
          </div>
          <Form.Label aria-label="stages">Stages*</Form.Label>
          <Stages
            type={"deal"}
            stages={stages}
            onChangeStages={onChangeStages}
            onDeleteClick={onDeleteClick}
            onRemoveStage={() => {}}
            onUpdateStageOrder={() => {}}
            updatePipelineStage={() => {}}
            onNewStageAdd={() => {}}
          />
        </div>
      </CustomScrollableModal>

      <DeleteStageModal showDelete={showDelete} setShowDelete={setShowDelete} selectedStage={selectedStage} setSelectedStage={setSelectedStage} stages={stages} onRemoveStage={onRemoveStage} />
      <DeleteModal
        modalProps={{
          show: showDeletePipeline,
          title: "Delete Pipeline",
          handleClose: () => {
            setShowDeletePipeline(false);
          },
          handleAccept: onPipelineDeletePipeline,
        }}
      >
        Are you sure you want to delete the pipeline?
      </DeleteModal>
    </>
  );
}
