import { takeLatest, put, call, select } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import * as companyActions from "../../actions/admin-actions/company-settings.actions";

import { getCompanySettings, updateCompanySettings, activeDeactiveCompanyStatus, getAccountBilling, currency, deleteCompanyNow } from "../../types/admin-settings.types";
import { endpoints } from "../../endpoint";

function* getCompanySettingsFunc(action) {
  try {
    const response = yield call(api.get, "/user-service/api/account");
    if (response.status === 200) {
      yield put(companyActions.getCompanySettingsSuccess(response.data));
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(companyActions.getCompanySettingsError(response.data));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(companyActions.getCompanySettingsError(e));
  }
}

function* updateCompanyDetailsFunc(action) {
  try {
    const response = yield call(api.patch, "/user-service/api/account", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Account Successfully Updated");
      yield put(companyActions.updateCompanySettingsSuccess(response.data));
      yield put(companyActions.getCompanySettingsRequest());
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(companyActions.updateCompanySettingsError(response.data));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(companyActions.updateCompanySettingsError(e));
  }
}

function* activeDeactiveCompanyStatusFunc(action) {
  try {
    const response = yield call(api.post, "/user-service/api/account/activate-deactivate", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Account Successfully Updated");
      yield put(companyActions.updateCompanySettingsSuccess(response.data));
      yield put(companyActions.getCompanySettingsRequest());
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(companyActions.updateCompanySettingsError(response.data));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(companyActions.updateCompanySettingsError(e));
  }
}

function* deleteCompanyNowFunc(action) {
  try {
    const response = yield call(api.post, "/user-service/api/account/delete-now-account", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Account Successfully Updated");
      localStorage.clear();
      yield put(companyActions.deleteCompanyNowSuccess(response.data));
      yield put(companyActions.getCompanySettingsRequest());
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(companyActions.deleteCompanyNowError(response.data));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(companyActions.deleteCompanyNowError(e));
  }
}

function* getAccountBillingFunction(action) {
  try {
    const res = yield call(api.get, endpoints.ACCOUNT_BILLING, action.payload);
    if (res.status === 200) {
      yield put(companyActions.getAccountBillingSuccess(res.data));

      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(companyActions.getAccountBillingError(e));
  }
}

function* currencyFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Products.currencyList.list) && store.Products.currencyList.list.length > 0) {
      yield put(companyActions.currencySuccess(store.Products.currencyList.list));
    } else {
      const res = yield call(api.get, endpoints.CURRENCY, action.payload);
      if (res.status === 200) {
        yield put(companyActions.currencySuccess(res.data.data));
      } else {
        yield put(companyActions.currencyError({ message: "Could Not Get Currency Data" }));
      }
    }
  } catch (e) {
    yield put(companyActions.currencyError({ message: "Could Not Get Currency Data" }));
  }
}

export default function* () {
  //email sync saga
  yield takeLatest(getCompanySettings.GET_COMPANY_SETTINGS_REQUEST, getCompanySettingsFunc);
  yield takeLatest(updateCompanySettings.UPDATE_COMPANY_SETTINGS_REQUEST, updateCompanyDetailsFunc);
  yield takeLatest(activeDeactiveCompanyStatus.ACTIVE_DEACTIVE_COMPANY_STATUS_REQUEST, activeDeactiveCompanyStatusFunc);
  yield takeLatest(deleteCompanyNow.DELETE_COMPANY_NOW_REQUEST, deleteCompanyNowFunc);
  yield takeLatest(getAccountBilling.GET_ACCOUNT_BILLING_REQUEST, getAccountBillingFunction);
  yield takeLatest(currency.CURRENCY_REQUEST, currencyFunction);
}
