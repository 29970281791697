import {
  getTemplateCategory,
  getTemplateTags,
  getTemplatetList,
  editTemplate,
  editTemplateTag,
  createTemplate,
  createTemplateTag,
  deleteTemaplateTag,
  deleteTemplate,
  cloneTemplate,
  UploadTemplate,
} from "../types/campaign_templates_types";

export function getTemplateTagsRequest(req) {
  return {
    type: getTemplateTags.GET_TEMPLATE_TAGS_REQUEST,
    payload: req,
  };
}
export function getTemplateTagsSuccess(res) {
  return {
    type: getTemplateTags.GET_TEMPLATE_TAGS_SUCCESS,
    payload: res,
  };
}
export function getTemplateTagsError(err) {
  return {
    type: getTemplateTags.GET_TEMPLATE_TAGS_ERROR,
    payload: err,
  };
}

export function createTemplateTagRequest(req) {
  return {
    type: createTemplateTag.CREATE_TEMPLATE_TAG_REQUEST,
    payload: req,
  };
}
export function createTemplateTagSuccess(res) {
  return {
    type: createTemplateTag.CREATE_TEMPLATE_TAG_SUCCESS,
    payload: res,
  };
}
export function createTemplateTagError(err) {
  return {
    type: createTemplateTag.CREATE_TEMPLATE_TAG_ERROR,
    payload: err,
  };
}

export function editTemplateTagRequest(req) {
  return {
    type: editTemplateTag.EDIT_TEMPLATE_TAG_REQUEST,
    payload: req,
  };
}
export function editTemplateTagSuccess(res) {
  return {
    type: editTemplateTag.EDIT_TEMPLATE_TAG_SUCCESS,
    payload: res,
  };
}
export function editTemplateTagError(err) {
  return {
    type: editTemplateTag.EDIT_TEMPLATE_TAG_ERROR,
    payload: err,
  };
}

export function deleteTemaplateTagRequest(req) {
  return {
    type: deleteTemaplateTag.DELETE_TEMPLATE_TAG_REQUEST,
    payload: req,
  };
}
export function deleteTemaplateTagSuccess(res) {
  return {
    type: deleteTemaplateTag.DELETE_TEMPLATE_TAG_SUCCESS,
    payload: res,
  };
}
export function deleteTemaplateTagError(err) {
  return {
    type: deleteTemaplateTag.DELETE_TEMPLATE_TAG_ERROR,
    payload: err,
  };
}

export function getTemplateCategoryRequest(req) {
  return {
    type: getTemplateCategory.GET_TEMPLATE_CATEGORY_REQUEST,
    payload: req,
  };
}
export function getTemplateCategorySuccess(res) {
  return {
    type: getTemplateCategory.GET_TEMPLATE_CATEGORY_SUCCESS,
    payload: res,
  };
}
export function getTemplateCategoryError(err) {
  return {
    type: getTemplateCategory.GET_TEMPLATE_CATEGORY_ERROR,
    payload: err,
  };
}

export function getTemplatetListRequest(req) {
  return {
    type: getTemplatetList.GET_TEMPLATE_LIST_REQUEST,
    payload: req,
  };
}
export function getTemplatetListSuccess(res) {
  return {
    type: getTemplatetList.GET_TEMPLATE_LIST_SUCCESS,
    payload: res,
  };
}
export function getTemplatetListError(err) {
  return {
    type: getTemplatetList.GET_TEMPLATE_LIST_ERROR,
    payload: err,
  };
}

export function createTemplateRequest(req) {
  return {
    type: createTemplate.CREATE_TEMPLATE_REQUEST,
    payload: req,
  };
}
export function createTemplateSuccess(res) {
  return {
    type: createTemplate.CREATE_TEMPLATE_SUCCESS,
    payload: res,
  };
}
export function createTemplateError(err) {
  return {
    type: createTemplate.CREATE_TEMPLATE_ERROR,
    payload: err,
  };
}

export function editTemplateRequest(req) {
  return {
    type: editTemplate.EDIT_TEMPLATE_REQUEST,
    payload: req,
  };
}
export function editTemplateSuccess(res) {
  return {
    type: editTemplate.EDIT_TEMPLATE_SUCCESS,
    payload: res,
  };
}
export function editTemplateError(err) {
  return {
    type: editTemplate.EDIT_TEMPLATE_ERROR,
    payload: err,
  };
}

export function deleteTemplateRequest(req) {
  return {
    type: deleteTemplate.DELETE_TEMPLATE_REQUEST,
    payload: req,
  };
}
export function deleteTemplateSuccess(res) {
  return {
    type: deleteTemplate.DELETE_TEMPLATE_SUCCESS,
    payload: res,
  };
}
export function deleteTemplateError(err) {
  return {
    type: deleteTemplate.DELETE_TEMPLATE_ERROR,
    payload: err,
  };
}

export function cloneTemplateRequest(req) {
  return {
    type: cloneTemplate.CLONE_TEMPLATE_REQUEST,
    payload: req,
  };
}
export function cloneTemplateSuccess(res) {
  return {
    type: cloneTemplate.CLONE_TEMPLATE_SUCCESS,
    payload: res,
  };
}
export function cloneTemplateError(err) {
  return {
    type: cloneTemplate.CLONE_TEMPLATE_ERROR,
    payload: err,
  };
}

export function UploadTemplateRequest(req, cb) {
  return {
    type: UploadTemplate.UPLOAD_TEMPLATE_REQUEST,
    payload: req,
    cb,
  };
}
export function UploadTemplateSuccess(res) {
  return {
    type: UploadTemplate.UPLOAD_TEMPLATE_SUCCESS,
    payload: res,
  };
}
export function UploadTemplateError(err) {
  return {
    type: UploadTemplate.UPLOAD_TEMPLATE_ERROR,
    payload: err,
  };
}
