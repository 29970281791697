import { getCadenceList } from "../types/cadence.types";
const initState = {
  data: [],
  count: 0,
  isLoading: false,
  error: false,
};

export default function cadenceListReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCadenceList.GET_CADENCE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getCadenceList.GET_CADENCE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        count: action.payload.count,
      };
    case getCadenceList.GET_CADENCE_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
