import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SourceFilterSVG } from "../../../../components/custom-svgs/filter-svg";

export default function ActivitySourceDropdown(props) {
  const handleSourceClick = (option) => {
    if (props.source === option) {
      props.handleSourceClick("");
    } else {
      props.handleSourceClick(option);
    }
  };
  return (
    <Dropdown className="dropdown-inline">
      {/* <Dropdown.Toggle className={`btn btn-sm dropdown-toggle btn-secondary custom-secondory-cta`}>
            {props.source ? props.source : "Source"}
        </Dropdown.Toggle> */}

      <Dropdown.Toggle className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none" variant={"link"}>
        {!props.source ? (
          <OverlayTrigger overlay={<Tooltip>Source Filter</Tooltip>}>{SourceFilterSVG}</OverlayTrigger>
        ) : (
          <div className="d-flex gap-2 align-items-center pl-3 pr-1 py-2 border rounded-2">
            <span>{props.source ? props.source : "Source"}</span>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                props.handleSourceClick("");
              }}
            ></i>
          </div>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="scroll navi navi-hover overflow-y-auto h-500px-mx w-200px position-relative">
          {["Manually created", "Cadence", "Workflow", "API", "Calendar", "Zapier", "Pabbly", "Meeting Scheduler"].map((each, index) => {
            return (
              <Dropdown.Item className="d-flex justify-content-between g-3 align-items-center" key={index} onClick={() => handleSourceClick(each)}>
                {each}
                {props.source === each && (
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove applied filter</Tooltip>}>
                    <i className="bi bi-x-circle-fill mr-6px mt-1px"></i>
                  </OverlayTrigger>
                )}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
