import { getLoginSession, getListOfUsers } from "../../types/admin-settings.types";

const initState = {
  error: {
    err: false,
    errMsg: "",
  },
  users: {
    list: [],
    loading: false,
  },
  loginSessionDetails: {
    list: [],
    loading: false,
  },
};

export default function securitySettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getLoginSession.GET_LOGIN_SESSION_REQUEST:
      return {
        ...state,
        loginSessionDetails: { ...state.loginSessionDetails, loading: true },
      };
    case getLoginSession.GET_LOGIN_SESSION_SUCCESS:
      return {
        ...state,
        loginSessionDetails: { loading: false, list: action.payload.data },
      };
    case getLoginSession.GET_LOGIN_SESSION_ERROR:
      return {
        ...state,
        loginSessionDetails: { ...state.loginSessionDetails, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case getListOfUsers.GET_LIST_OF_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case getListOfUsers.GET_LIST_OF_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          list: action.payload.data,
        },
      };
    case getListOfUsers.GET_LIST_OF_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: { err: true, errMsg: action.payload.message },
        },
      };
    default:
      return state;
  }
}
