import React, { useState, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function HorizontalFunnelChart(props) {
  const [graphData] = useState(props.won);

  useLayoutEffect(() => {
    let chart = am4core.create(props.chartId, am4charts.SlicedChart);

    chart.data = graphData;

    let series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.step = 2;
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    // series.alignLabels = true;
    series.orientation = "horizontal";
    series.bottomRatio = 1;
    series.labels.template.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
  }, [graphData, props.chartId, props.defaultCurrency]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default HorizontalFunnelChart;
