import querystring from "query-string";
import axios from "axios";
import { dispatchSnackbarError } from "../../utils/toaster";

function buildHeaders(url, headers) {
  if (url.includes("avatar")) {
    return {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      ...headers,
    };
  }
  if (url.includes("login")) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    };
  }
  if (url.includes("forgot_password")) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    };
  }
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    ...headers,
  };
}

const handleAPIError = (err, cb) => {
  if (err.hasOwnProperty("response") && err.response !== undefined && err.response.hasOwnProperty("status") && err.response.status === 401) {
    axios
      .post(
        `/user-service/api/refresh-token`,
        {},
        {
          headers: { "X-Auth-Token": localStorage.getItem("refreshToken") },
        },
      )
      .then(function(response) {
        localStorage.setItem("Token", response.headers["x-auth-token"]);
        localStorage.setItem("refreshToken", response.headers["x-auth-token"]);
        localStorage.setItem("access_token", response["data"]["token"]);
        cb(err.response);
      })
      .catch(function(err) {
        dispatchSnackbarError(err.response.data["message"], "error");
        localStorage.removeItem("access_token");
        localStorage.removeItem("leadOwner");
        localStorage.removeItem("junkLeadOwner");
        localStorage.removeItem("activityListOwner");
        localStorage.removeItem("productOwner");
        localStorage.removeItem("marketingListOwner");
        localStorage.removeItem("companyOwner");
        localStorage.removeItem("peopleOwner");
        localStorage.removeItem("dealOwner");
        window.location.reload();
        cb(err.response);
      });
  } else if (err.response.status === 400) {
    return cb(err.response);
  } else cb(err.response);
};

function request(props) {
  const { url, init, query, option } = props;
  let strQuery = query ? `?${querystring.stringify(query)}` : "",
    fetchUrl = `${url}${strQuery}`;
  if (init.method === "POST") {
    return axios
      .post(fetchUrl, option, { headers: buildHeaders(fetchUrl, init.headers) })
      .then((res) => {
        localStorage.setItem("refreshToken", res.headers["x-auth-token"]);
        return res;
      })
      .catch((err) => {
        return new Promise((resolve) => {
          handleAPIError(err, (cbData) => {
            resolve(cbData);
          });
        });
      });
  } else if (init.method === "DELETE") {
    return axios
      .delete(fetchUrl, { headers: buildHeaders(fetchUrl, init.headers) })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return new Promise((resolve) => {
          handleAPIError(err, (cbData) => {
            resolve(cbData);
          });
        });
      });
  } else if (init.method === "PATCH") {
    return axios
      .patch(fetchUrl, option, { headers: buildHeaders(fetchUrl, init.headers) })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return new Promise((resolve) => {
          handleAPIError(err, (cbData) => {
            resolve(cbData);
          });
        });
      });
  } else if (init.method === "PUT") {
    return axios
      .put(fetchUrl, option, { headers: buildHeaders(fetchUrl, init.headers) })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return new Promise((resolve) => {
          handleAPIError(err, (cbData) => {
            resolve(cbData);
          });
        });
      });
  }
  return axios
    .get(fetchUrl, { headers: buildHeaders(fetchUrl, init.headers) })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return new Promise((resolve) => {
        handleAPIError(err, (cbData) => {
          resolve(cbData);
        });
      });
    });
}

const Api = {
  get: (url, query) =>
    request({
      url,
      init: {
        method: "GET",
      },
      query,
    }),
  delete: (url, query) =>
    request({
      url,
      init: {
        method: "DELETE",
      },
      query,
    }),

  post: (url, option) =>
    request({
      url,
      init: {
        method: "POST",
      },
      option,
    }),
  patch: (url, option) =>
    request({
      url,
      init: {
        method: "PATCH",
      },
      option,
    }),
  put: (url, option) =>
    request({
      url,
      init: {
        method: "PUT",
      },
      option,
    }),
};

export default Api;
