import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import "./style.scss";

export default function StepDayPopover(props) {
  const [days, setDays] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [show, setShow] = useState(false);
  const [displayDay, setDisplayDay] = useState(1);

  useEffect(() => {
    if (props.step.delay > 0) {
      const { d, h, m } = secondsToDhm(props.step.delay);
      setDays(d);
      setMinutes(m);
      setHours(h);
    }
  }, [props.step.delay]);

  useEffect(() => {
    let seconds = 0;
    for (let i = 0; i < props.steps.length; i++) {
      if (i <= props.index) {
        seconds = seconds + props.steps[i].delay;
      }
    }
    const { d } = secondsToDhm(seconds);
    setDisplayDay(d + 1);
  }, [props.steps, props.index]);

  const secondsToDhm = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    return { d: d, h: h, m: m };
  };
  const dhmToSeconds = (d, h, m) => {
    const seconds = m * 60 + h * 60 * 60 + d * 24 * 60 * 60;
    return seconds;
  };

  const handleSave = () => {
    const seconds = dhmToSeconds(days, hours, minutes);
    if (seconds > 31556926 * 2) {
      return dispatchSnackbarError("Delay can not be more than 2 years!");
    }
    if (props.step.executionOrder > 1 && seconds < 300) {
      return dispatchSnackbarError("Delay can not be less than 5 min!");
    }
    const _step = { ...props.step };
    _step.delay = seconds;
    props.handleStepDelay(props.index, _step);
    setShow(false);
  };

  return (
    <Dropdown show={show} onToggle={() => setShow(!show)} className="dropdown-inline days-popover">
      <Dropdown.Toggle className="border-none p-0 d-flex align-items-center w-100 text-left" variant="transparent">
        Day {displayDay}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-lg card w-100-mn width-fit-content">
        <Dropdown.Header className="p-4 border-bottom-1 fs-6 text-gray-900 bg-gray-3001 border-bottom-lg">Select Delay</Dropdown.Header>
        <div className="day-dropdown-wrap">
          <div className="d-flex day-content align-items-center gap-3">
            <Form.Control value={days} type={"number"} className="w-70px" onChange={(e) => setDays(e.target.value ? parseInt(e.target.value) : "")} />
            <Form.Label aria-label="days" className="mb-0">
              Days
            </Form.Label>
            <Form.Control value={hours} type={"number"} className="w-70px" onChange={(e) => setHours(e.target.value ? parseInt(e.target.value) : "")} />
            <Form.Label aria-label="hours" className="mb-0">
              Hours
            </Form.Label>
            <Form.Control value={minutes} type={"number"} className="w-70px" onChange={(e) => setMinutes(e.target.value ? parseInt(e.target.value) : "")} />
            <Form.Label aria-label="minutes" className="mb-0">
              Minutes
            </Form.Label>
          </div>
          <div className="footer d-flex justify-content-center mt-9 gap-3">
            <Button variant="secondary" size="sm" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" size="sm" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export const createNumberArrayWithLength = (len) => {
  let arr = [];
  for (let i = 0; i < len; i++) arr.push(i);
  return arr;
};
