import React from "react";
import { Form, Row } from "react-bootstrap";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { useDispatch } from "react-redux";
import * as whatsappSettingsActions from "../../../store/actions/campaign_whatsapp_setting_actions";


export default function WhatsappConfigForm({ config, setConfig, whatsappPhoneOptions }) {
    const dispatch = useDispatch();

    const handlePhoneChange = (value) => {
        setConfig({
            ...config,
            phoneId: value.phoneId,
            integrationId: value.value,
            dayLimit: value.dayLimit,
        });
        if (value.value)
            dispatch(whatsappSettingsActions.getWhatsappConsumptionCountRequest({ integrationId: value.value }))
    }

    return (
        <>
            <Row>
                <Form.Group id="campaign-builder-forms-email-config-form-subject">
                    <Form.Label aria-label="subject">From Phone Number</Form.Label>
                    <SimpleDropdown
                        value={config.integrationId || ""}
                        label={"Select Phone Number"}
                        options={whatsappPhoneOptions}
                        handleSelect={handlePhoneChange}
                        showSearch
                    />
                </Form.Group>
            </Row>
        </>
    );
}
