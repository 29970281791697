import { takeLatest, put, call } from "redux-saga/effects";
import * as activityActions from "./actions";
//import required action types below
import {
  GET_ACTIVITIES_LIST_REQUEST,
  LEADS_DROPDOWN_REQUEST,
  GET_ACTIVITIES_LIST_REQUEST_WITHOUT_LOADING,
  LEADS_DROPDOWNADD_REQUEST,
  FIELD_SHOWHIDE_REQUEST,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATEPRIORITY_REQUEST,
  GET_MASAVEDFILTERS_REQUEST,
  ADD_MASAVEDFILTERS_REQUEST,
  EDIT_MASAVEDFILTERS_REQUEST,
  DELETE_MASAVEDFILTERS_REQUEST,
  FAVORITE_MASAVEDFILTERS_REQUEST,
  BULK_EDIT_REQUEST,
  BULK_DELETE_REQUEST,
  MARKAS_UNDONE_REQUEST,
  GET_USER_ACTIVITYLOGS_REQUEST,
} from "./types";
import api from "../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";

function* getActivitiesList(action) {
  try {
    let response;
    if (action.payload.filterType) {
      response = yield call(api.post, "/api/crm/saved-filters/apply", action.payload);
    } else {
      response = yield call(api.get, "/api/crm/activity-list/get", action.payload);
    }
    if (response.status === 200) {
      yield put(activityActions.getActivitiesListSuccess(response.data));
    } else {
      yield put(activityActions.getActivitiesListError("error"));
    }
  } catch (e) {
    yield put(activityActions.getActivitiesListError(e));
  }
}

function* leadDropdownFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/lead/search", { searchText: action.payload });
    if (response.status === 200) {
      yield put(activityActions.leadsDropdownSuccess(response.data));
    } else {
      yield put(activityActions.leadsDropdownError({ message: "Could Not Get companies" }));
    }
  } catch (e) {
    yield put(activityActions.leadsDropdownError({ message: "Could Not Get companies" }));
  }
}

function* leadsDropdownAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/company/create-by-name", action.payload);
    if (response.status === 200) {
      yield put(activityActions.leadsDropdownAddSuccess({ message: "Company Added Successfully", id: response.data.id, name: action.payload.name }));
      yield put(activityActions.leadsDropdownRequest(action.payload.name));
    } else {
      yield put(activityActions.leadsDropdownAddError({ message: "Could Not Add Company" }));
    }
  } catch (e) {
    yield put(activityActions.leadsDropdownAddError({ status: false, message: "Could Not Add Company" }));
  }
}

function* fieldShowHideFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
    if (response.status === 200) {
      yield put(activityActions.fieldUpdateRequest({ fieldType: "ACTIVITY" }));
    } else {
      yield put(activityActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(activityActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* fieldUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/get", action.payload);
    if (response.status === 200) {
      yield put(activityActions.fieldUpdateSuccess(response.data.data));
    } else {
      yield put(activityActions.fieldUpdateError({ message: "Could Not Get Activity Fields" }));
    }
  } catch (e) {
    yield put(activityActions.fieldUpdateError({ message: "Could Not Get Activity Fields" }));
  }
}

function* fieldUpdatePriorityFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
    if (response.status === 200) {
      yield put(activityActions.fieldUpdateRequest({ fieldType: "ACTIVITY" }));
    } else {
      yield put(activityActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(activityActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

//filters apis

function* getSavedFilters(action) {
  try {
    const res = yield call(api.get, "/api/crm/saved-filters/get?type=activity", action.payload);
    if (res.status === 200) {
      yield put(activityActions.getSavedFiltersSuccess(res.data));
    }
  } catch (e) {
    yield put(activityActions.getSavedFiltersError(e));
  }
}

function* addSavedFilters(action) {
  try {
    const res = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (res.status === 200) {
      yield put(activityActions.getSavedFiltersRequest());
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(activityActions.addSavedFiltersError(e));
  }
}

function* editSavedFilter(action) {
  try {
    const res = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (res.status === 200) {
      yield put(activityActions.getSavedFiltersRequest());
    }
  } catch (e) {
    yield put(activityActions.editSavedFiltersError(e));
  }
}

function* deleteSavedFilter(action) {
  try {
    const res = yield call(api.delete, `/api/crm/saved-filters/delete/${action.payload}`);
    if (res.status === 200) {
      yield put(activityActions.getSavedFiltersRequest());
    }
  } catch (e) {
    yield put(activityActions.deleteSavedFiltersError(e));
  }
}

function* favoriteSavedFilter(action) {
  try {
    const res = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);

    if (res.status === 200) {
      yield put(activityActions.getSavedFiltersRequest());
    }
  } catch (e) {
    yield put(activityActions.favoriteSavedFiltersError(e));
  }
}

function* bulkEditFunction(action) {
  try {
    const res = yield call(api.patch, `/api/crm/activity-list/${action.payload.type}`, action.payload.editData);
    if (res.status === 200) {
      yield put(activityActions.bulkEditSuccess(res.data));
      yield put(activityActions.getActivitiesListRequestWithoutLoading(action.payload.loadingData));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
      yield put(activityActions.bulkEditError());
    } else {
      yield put(activityActions.bulkEditError());
    }
  } catch (e) {
    yield put(activityActions.bulkEditError(e));
  }
}

function* bulkDeleteFunction(action) {
  try {
    const res = yield call(api.patch, "/api/crm/activity-list/bulk-delete", action.payload.activityIds);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Activities deleted successfully", "success");
      yield put(activityActions.getActivitiesListRequest(action.payload.loadingData));
      yield put(activityActions.bulkDeleteSuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
    }
  } catch (e) {
    yield put(activityActions.bulkDeleteError(e));
  }
}

function* markAsUndoneFunction(action) {
  try {
    const res = yield call(api.patch, `/api/crm/activity-list/mark-as-un-done`, action.payload.id);
    if (res.status === 200) {
      if (action.payload.loadingData) yield put(activityActions.getActivitiesListRequest(action.payload.loadingData));
      yield put(activityActions.markAsUndoneSuccess(res.data));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(activityActions.markAsUndoneError(e));
  }
}

function* getUserActivityLogs(action) {
  try {
    const res = yield call(api.get, "/api/crm/activity/logs", action.payload);
    if (res.status === 200) {
      yield put(activityActions.getUserActivityLogsSuccess(res.data.data));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(activityActions.getUserActivityLogsError(e));
  }
}

export default function*() {
  yield takeLatest(GET_ACTIVITIES_LIST_REQUEST, getActivitiesList); // similarly add required functions
  yield takeLatest(GET_ACTIVITIES_LIST_REQUEST_WITHOUT_LOADING, getActivitiesList);
  yield takeLatest(LEADS_DROPDOWN_REQUEST, leadDropdownFunction);
  yield takeLatest(LEADS_DROPDOWNADD_REQUEST, leadsDropdownAddFunction);
  yield takeLatest(FIELD_SHOWHIDE_REQUEST, fieldShowHideFunction);
  yield takeLatest(FIELD_UPDATE_REQUEST, fieldUpdateFunction);
  yield takeLatest(FIELD_UPDATEPRIORITY_REQUEST, fieldUpdatePriorityFunction);
  yield takeLatest(GET_MASAVEDFILTERS_REQUEST, getSavedFilters);
  yield takeLatest(ADD_MASAVEDFILTERS_REQUEST, addSavedFilters);
  yield takeLatest(EDIT_MASAVEDFILTERS_REQUEST, editSavedFilter);
  yield takeLatest(DELETE_MASAVEDFILTERS_REQUEST, deleteSavedFilter);
  yield takeLatest(FAVORITE_MASAVEDFILTERS_REQUEST, favoriteSavedFilter);
  yield takeLatest(BULK_EDIT_REQUEST, bulkEditFunction);
  yield takeLatest(BULK_DELETE_REQUEST, bulkDeleteFunction);
  yield takeLatest(MARKAS_UNDONE_REQUEST, markAsUndoneFunction);
  yield takeLatest(GET_USER_ACTIVITYLOGS_REQUEST, getUserActivityLogs);
}
