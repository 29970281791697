import { getWhatsappMedia, uploadWhatsappMedia, uploadWhatsappSessionMedia, deleteWhatsappMedia } from "../types/campaign_whatsapp_messaging_types";

export const getWhatsappMediaRequest = (payload, cb) => ({
    type: getWhatsappMedia.REQUEST,
    payload,
    cb
})
export const getWhatsappMediaSuccess = (payload) => ({
    type: getWhatsappMedia.SUCCESS,
    payload
})
export const getWhatsappMediaFailure = (payload) => ({
    type: getWhatsappMedia.FAILURE,
    payload
})

export const uploadWhatsappMediaRequest = (payload, cb) => ({
    type: uploadWhatsappMedia.REQUEST,
    payload,
    cb
})
export const uploadWhatsappMediaSuccess = (payload) => ({
    type: uploadWhatsappMedia.SUCCESS,
    payload
})
export const uploadWhatsappMediaFailure = (payload) => ({
    type: uploadWhatsappMedia.FAILURE,
    payload
})

export const uploadWhatsappSessionMediaRequest = (payload, cb) => ({
    type: uploadWhatsappSessionMedia.REQUEST,
    payload,
    cb
})
export const uploadWhatsappSessionMediaSuccess = (payload) => ({
    type: uploadWhatsappSessionMedia.SUCCESS,
    payload
})
export const uploadWhatsappSessionMediaFailure = (payload) => ({
    type: uploadWhatsappSessionMedia.FAILURE,
    payload
})

export const deleteWhatsappMediaRequest = (payload, cb) => ({
    type: deleteWhatsappMedia.REQUEST,
    payload,
    cb
})
export const deleteWhatsappMediaSuccess = (payload) => ({
    type: deleteWhatsappMedia.SUCCESS,
    payload
})
export const deleteWhatsappMediaFailure = (payload) => ({
    type: deleteWhatsappMedia.FAILURE,
    payload
})
