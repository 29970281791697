//packages
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//components
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import { BlueGradientButton } from "../../../components/button";
import { CustomScrollableModal } from "../../../components/modal";
import EditableDropdown from "../../../components/custom_form/editableDropdown";
import GridInsightLayout from "../../../components/grid-layout/index";

import ReportContainer from "../components/reports/reportContainer";
import GoalContainer from "../components/goals/goalsContainer";
import ContentWrapper from "../../Wrapper";

//charts
import FunnelChart from "../components/graphs/funnel-chart";

//forms
import AddGoalForm from "../components/forms/addGoal";
import AddReportForm from "../components/forms/addReport";

//models
import DashboardModel from "../components/models/dashboard";

//actions
import * as LeadActions from "../../leads/store/actions/actions";
import * as CompanyActions from "../../companies/store/actions/actions";
import * as ProductActions from "../../products/store/actions/actions";
import * as insightActions from "../../insights/actions";
import * as TemplateActions from "../../campaign/store/actions/campaign_templates_actions";

//styles
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../components/react-grid-layout.css";
import "../styles.css";
import { dispatchSnackbarError, dispatchSnackbarWarning } from "../../../utils/toaster";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import { DashboardSVG, SalesForcastSVG, SalesTargetSVG } from "../../../components/custom-svgs/sub-header-svg";

const options = [
  { value: "deal", label: "Deal" },
  { value: "activity", label: "Activity" },
  { value: "forecast", label: "Forecast" },
];
const intervalOptions = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "yearly", label: "Yearly" },
];

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

export default function Insights(props) {
  //variables
  const links = [
    { name: "Dashboard", to: "/insights/dashboard", icon: "bi bi-reception-4", svg: DashboardSVG },
    { name: "Sales Target", to: "/insights/sales-target", icon: "bi bi-geo", svg: SalesTargetSVG },
    { name: "Forecast", to: "/insights/forecast", icon: "bi bi-graph-up-arrow", svg: SalesForcastSVG },
  ];
  const [currentReportVal, setCurrentReportVal] = useState({});
  const [openGoalModal, setOpenGoalModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({ value: "deal", label: "Deal" });
  const [assignee, setAssignee] = useState([]);
  const [dealGoalType, setDealGoalType] = useState("");
  const [activityGoalType, setActivityGoalType] = useState("");
  const [forecastGoalType, setForecastGoalType] = useState("");
  const [interval, setInterval] = useState({ value: "monthly", label: "Monthly" });
  const [selectedPipeline, setSelectedPipeline] = useState({ value: "", label: "" });
  const [metricsValue, setMetricsValue] = useState("");
  const [ToDateVal, setToDateVal] = useState("");
  const [FromDateVal, setFromDateVal] = useState("");
  const [TargetVal, setTargetVal] = useState("");
  const [activeCard, setActiveCard] = useState("");
  const [activeUser, setActiveUser] = useState("");
  const [customUser, setCustomUser] = useState({ type: "", id: "" });

  const [showDashboardModel, setShowDashboardModel] = useState(false);
  const [dashboardName, setDashboardName] = useState("");
  const [visibleTo, setVisibleTo] = useState("");
  const [isDashboardEdit, setIsDashboardEdit] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [selectedDashboardEditId, setSelectedDashboardEditId] = useState("");

  const [isReportEdit, setIsReportEdit] = useState(false);
  const [selectedReportEditId, setSelectedReportEditId] = useState("");

  const [selectedGoal, setSelectedGoal] = useState("");
  const [isGoalEdit, setIsGoalEdit] = useState(false);
  const [selectedGoalEditId, setSelectedGoalEditId] = useState("");

  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [typeOfReportData, settypeOfReportData] = useState([]);
  const [userRoleData, setUserRoleData] = useState([]);

  //store
  const listOfPipelines = useSelector((store) => store.insight.pipelines);
  const listOfUsers = useSelector((store) => store.insight.users);
  const dashboardStore = useSelector((store) => store.insight.dashboard);
  const selectedDashboardStore = useSelector((store) => store.insight.selectedDashboard);
  const goalStore = useSelector((store) => store.insight.goal);
  const typeOfReport = useSelector((store) => store.insight.typeOfReport);
  const userRole = useSelector((store) => store.insight.userRole);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const dispatch = useDispatch();
  const history = useHistory();

  const getActiveTab = () => {
    if (props.location.pathname.match("/insights/goals")) {
      return "Goals";
    } else {
      return "Dashboard";
    }
  };
  const tabName = getActiveTab();

  useEffect(() => {
    dispatch(insightActions.getListOfDashboardsRequest());
    dispatch(insightActions.getInsightGoalListRequest());
    dispatch(insightActions.getListOfPipelinesRequest());
    dispatch(insightActions.getListOfUsersRequest());

    //common api Calls
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(insightActions.getTypeOfReportRequest());
  }, [dispatch]);

  useEffect(() => {
    if (props.location.pathname === "/insights/dashboard" && dashboardStore.list.length > 0) {
      const selected = dashboardStore.list[0];
      setSelectedDashboard(selected ? selected.label : "");
      history.push(`/insights/dashboard/${selected._id}`);
      dispatch(insightActions.getSelectedDashboardRequest(selected._id));
    }
  }, [dashboardStore.list, props.location.pathname, history, dispatch]);

  useEffect(() => {
    if (props.location.pathname === "/insights/goals" && goalStore.list.length > 0) {
      const selected = goalStore.list[0];
      setSelectedGoal(selected ? selected.title : "");
      history.push(`/insights/goals/${selected._id}`);
      // dispatch(insightActions.getSelectedDashboardRequest(selected._id))
    }
  }, [goalStore.list, props.location.pathname, history]);

  useEffect(() => {
    const options2 = [{ value: "allPipelines", label: "All Pipelines" }];
    listOfPipelines.list.forEach((eachPipeline) => {
      options2.push({ value: eachPipeline._id, label: eachPipeline.name });
    });
    setPipelineOptions(options2);
  }, [listOfPipelines.list]);

  useEffect(() => {
    const options1 = [];
    typeOfReport.list.forEach((eachPipeline) => {
      options1.push(eachPipeline);
    });
    settypeOfReportData(options1);
  }, [typeOfReport.list]);

  useEffect(() => {
    const options1 = [];
    options1.push(userRole.list);
    setUserRoleData(options1);
  }, [userRole.list]);

  useEffect(() => {
    const options1 = [];
    listOfUsers.list.forEach((eachPipeline) => {
      options1.push({ value: eachPipeline.userId, label: eachPipeline.email });
    });
    setUserOptions(options1);
  }, [listOfUsers.list]);

  useEffect(() => {
    if (props.match.params.dashboardId && dashboardStore.list.length > 0) {
      //call get dashboard api
      const selected = dashboardStore.list.find((a) => props.match.params.dashboardId === a._id);
      setSelectedDashboard(selected ? selected.name : dashboardStore.list[0].name);
      if (!selected) {
        history.push(`/insights/dashboard/${dashboardStore.list[0]._id}`);
        dispatch(insightActions.getSelectedDashboardRequest(dashboardStore.list[0]._id));
      } else {
        dispatch(insightActions.getSelectedDashboardRequest(selected._id));
      }
    }
  }, [dashboardStore.list, props.match.params.dashboardId, history, dispatch]);

  useEffect(() => {
    if (props.match.params.goalId && goalStore.list.length > 0) {
      //call get selected goal api
      const selected = goalStore.list.find((a) => props.match.params.goalId === a._id);
      setSelectedGoal(selected ? selected.title : goalStore.list[0].title);
      if (!selected) {
        history.push(`/insights/goals/${goalStore.list[0]._id}`);
      }
    }
  }, [goalStore.list, props.match.params.goalId, history]);

  const onMenuClick = (name) => {
    if (name === "Dashboard") {
      dispatch(insightActions.getListOfDashboardsRequest());
    } else {
      dispatch(insightActions.getInsightGoalListRequest());
      dispatch(insightActions.getListOfPipelinesRequest());
      dispatch(insightActions.getListOfUsersRequest());
    }
  };

  //model open/close handlers
  const onAddReportClick = () => setOpenReportModal(true);
  const onAddGoalClick = () => setOpenGoalModal(true);
  const handleAddNewReportClose = () => {
    setOpenReportModal(false);
    makeFieldsEmpty();
    settypeReportStep(1);
    setIsReportEdit(false);
  };
  const handleAddNewGoalClose = () => {
    setOpenGoalModal(false);
    makeFieldsEmpty();
  };

  const makeFieldsEmpty = () => {
    setSelectedEntity({ value: "deal", label: "Deal" });
    setAssignee([]);
    setDealGoalType("");
    setActivityGoalType("");
    setForecastGoalType("");
    setInterval({ value: "monthly", label: "Monthly" });
    setSelectedPipeline({ value: "allPipelines", label: "All Pipelines" });
    setMetricsValue("");
    setToDateVal("");
    setFromDateVal("");
    setTargetVal("");
  };

  //model functions
  const reportCardValue = (value) => {
    setCurrentReportVal(value);
  };
  const onEntitySelect = (e) => setSelectedEntity(e);
  const onChooseDealGoalType = (e) => setDealGoalType(e.target.value);
  const onChooseActivityGoalType = (e) => setActivityGoalType(e.target.value);
  const onChooseForecastGoalType = (e) => setForecastGoalType(e.target.value);
  const onAssigneeChange = (e) => setAssignee(e);
  const onIntervalChange = (e) => setInterval(e);
  const onPipelineChange = (e) => setSelectedPipeline(e);
  const toDateFun = (date) => setToDateVal(date);
  const fromDateFun = (date) => setFromDateVal(date);
  const targetFunction = (e) => setTargetVal(e.target.value);
  const trackingMetFunction = (e) => setMetricsValue(e.target.value);
  const onCustomUserSelect = (type, id, name) => {
    setActiveUser(name);
    setCustomUser({ type: type, id: id });
  };

  //model save functions
  const handleAddNewGoalSave = () => {
    if (isGoalEdit) {
      let reportType = "",
        _pipelineIds = [];
      if (selectedEntity.value === "deal") {
        reportType = dealGoalType;
      }
      if (selectedEntity.value === "activity") {
        reportType = activityGoalType;
      }
      if (selectedEntity.value === "forecast") {
        reportType = forecastGoalType;
      }

      if (selectedPipeline) {
        if (selectedPipeline.value === "allPipelines") {
          listOfPipelines.list.forEach((eachPipeline) => {
            _pipelineIds.push(eachPipeline._id);
          });
        } else {
          _pipelineIds.push(selectedPipeline.value);
        }
      }

      if (!reportType) {
        return dispatchSnackbarError("Pease select report type");
      }
      if (!assignee.value) {
        return dispatchSnackbarError("Pease select Assignee");
      }
      if (!metricsValue) {
        return dispatchSnackbarError("Pease select Tracking Metrics");
      }
      if (!interval.value) {
        return dispatchSnackbarError("Pease select Interval");
      }
      if (!ToDateVal || !FromDateVal) {
        return dispatchSnackbarError("Pease select duration");
      } else if (new Date(FromDateVal).getTime() > new Date(ToDateVal).getTime()) {
        return dispatchSnackbarError("start date should not greater than end date");
      }
      if (!TargetVal) {
        return dispatchSnackbarError("Pease select Target value");
      }

      const data = {
        _id: selectedGoalEditId,
        title: `${selectedEntity.value} ${reportType} Goal`,
        entity: selectedEntity.value,
        goalType: reportType,
        assignee: [assignee.value],
        pipelineIds: _pipelineIds,
        trackingMetrics: metricsValue,
        interval: interval.value,
        duration: {
          start: ToDateVal,
          end: FromDateVal,
        },
        target: TargetVal,
        visibleTo: "Public",
      };

      dispatch(insightActions.editInsightGoalRequest(data));
      setOpenGoalModal(false);
      setSelectedGoalEditId("");
      setIsGoalEdit(false);
      makeFieldsEmpty();
    } else {
      //value- quantity, count-sum
      let reportType = "",
        _pipelineIds = [];
      if (selectedEntity.value === "deal") {
        reportType = dealGoalType;
      }
      if (selectedEntity.value === "activity") {
        reportType = activityGoalType;
      }
      if (selectedEntity.value === "forecast") {
        reportType = forecastGoalType;
      }

      if (selectedPipeline) {
        if (selectedPipeline.value === "allPipelines") {
          listOfPipelines.list.forEach((eachPipeline) => {
            _pipelineIds.push(eachPipeline._id);
          });
        } else {
          _pipelineIds.push(selectedPipeline.value);
        }
      }

      if (!reportType) {
        return dispatchSnackbarError("Pease select report type");
      }
      if (!assignee.value) {
        return dispatchSnackbarError("Pease select Assignee");
      }
      if (!metricsValue) {
        return dispatchSnackbarError("Pease select Tracking Metrics");
      }
      if (!interval.value) {
        return dispatchSnackbarError("Pease select Interval");
      }
      if (!ToDateVal || !FromDateVal) {
        return dispatchSnackbarError("Pease select duration");
      } else if (new Date(FromDateVal).getTime() > new Date(ToDateVal).getTime()) {
        return dispatchSnackbarError("start date should not greater than end date");
      }
      if (!TargetVal) {
        return dispatchSnackbarError("Pease select Target value");
      }

      const data = {
        title: `${selectedEntity.value} ${reportType} Goal`,
        entity: selectedEntity.value,
        goalType: reportType,
        assignee: [assignee.value],
        pipelineIds: _pipelineIds,
        trackingMetrics: metricsValue,
        interval: interval.value,
        duration: {
          start: ToDateVal,
          end: FromDateVal,
        },
        target: TargetVal,
        visibleTo: "Public",
      };

      dispatch(insightActions.createInsightGoalRequest(data));
      setOpenGoalModal(false);
      makeFieldsEmpty();
    }
  };

  const [typeReportStep, settypeReportStep] = useState(1);
  const handleNextButton = () => {
    if (activeCard || activeCard === 0) {
      settypeReportStep(2);
    } else {
      dispatchSnackbarError("Please select report type");
    }
  };

  const handleAddNewReportSave = () => {
    if (isReportEdit) {
      const body = {
        _id: selectedReportEditId,
        title: currentReportVal.name,
        entity: currentReportVal.entity,
        reportType: currentReportVal.reportType,
        graphType: currentReportVal.graphType,

        typeOfUser: customUser.type,
        users: customUser.id,

        pipelineId: selectedPipeline.value,
        interval: interval.value,

        duration: {
          start: FromDateVal,
          end: ToDateVal,
        },
        dashboard: props.match.params.dashboardId,
      };
      const validate = validateAddReport(body);
      if (!validate.status) {
        return dispatchSnackbarError(validate.error);
      }
      dispatch(insightActions.editInsightDashboardReportRequest(body));
      setOpenReportModal(false);
      setSelectedReportEditId("");
      setIsReportEdit(false);
      makeFieldsEmpty();
      settypeReportStep(1);
    } else {
      const body = {
        title: currentReportVal.name,
        entity: currentReportVal.entity,
        reportType: currentReportVal.reportType,
        graphType: currentReportVal.graphType,

        typeOfUser: customUser.type,
        users: customUser.id,

        pipelineId: selectedPipeline.value,
        interval: interval.value,

        duration: {
          start: FromDateVal,
          end: ToDateVal,
        },
        dashboard: props.match.params.dashboardId,
      };

      const validate = validateAddReport(body);
      if (!validate.status) {
        return dispatchSnackbarError(validate.error);
      }
      dispatch(insightActions.createInsightDashboardReportRequest(body));
      setOpenReportModal(false);
      makeFieldsEmpty();
      settypeReportStep(1);
    }
  };
  const validateAddReport = (data) => {
    if (!data.title) {
      return { status: false, error: "Please select report title" };
    }
    if (!data.entity) {
      return { status: false, error: "Please select report entity" };
    }
    if (!data.reportType) {
      return { status: false, error: "Please select report type" };
    }
    if (!data.graphType) {
      return { status: false, error: "Please select graph type" };
    }
    if (!data.typeOfUser) {
      return { status: false, error: "Please select User/Team" };
    }
    if (data.typeOfUser !== "organization" && !data.users) {
      return { status: false, error: "Please select User/Team" };
    }
    if ((!data.pipelineId || data.pipelineId === "allPipelines") && data.entity !== "activity" && data.entity !== "lead") {
      return { status: false, error: "Please select pipeline" };
    }
    if (!data.interval) {
      return { status: false, error: "Please select interval" };
    }
    if (!data.duration.start || !data.duration.end) {
      return { status: false, error: "Please select duration" };
    } else if (new Date(data.duration.start).getTime() > new Date(data.duration.end).getTime()) {
      return { status: false, error: "start date should not greater than end date" };
    }

    return { status: true };
  };

  const onAddDashboardClick = () => {
    if (accountBillingStore.data.servicesOffered.ANALYTICS_DASHBOARD && accountBillingStore.data.servicesOffered.ANALYTICS_DASHBOARD !== "unlimited") {
      if (dashboardStore && dashboardStore.list && dashboardStore.list.length < accountBillingStore.data.servicesOffered.ANALYTICS_DASHBOARD) {
        setShowDashboardModel(true);
        setIsDashboardEdit(false);
      } else {
        dispatchSnackbarWarning("You have reached the max number of analytics dashboard limit. Please upgrade your plan to create more analytics dashboards.", "warning");
      }
    } else {
      setShowDashboardModel(true);
      setIsDashboardEdit(false);
    }
  };

  const onEditDashboardClick = (option) => {
    setShowDashboardModel(true);
    setDashboardName(option.label);
    setSelectedDashboardEditId(option.value);
    setIsDashboardEdit(true);
  };
  const handleDashboardClose = () => {
    setShowDashboardModel(false);
    setDashboardName("");
  };

  const onDashboardNameChange = (e) => {
    setDashboardName(e.target.value);
  };

  const onDashboardSelect = (data) => {
    const selected = dashboardStore.list.find((a) => data === a._id);
    setSelectedDashboard(selected ? selected.name : "");
    history.push(`/insights/dashboard/${data}`);
    dispatch(insightActions.getSelectedDashboardRequest(data));
  };

  const onAddDashboard = () => {
    setShowDashboardModel(false);
    if (isDashboardEdit) {
      dispatch(
        insightActions.editDashboardRequest({
          _id: selectedDashboardEditId,
          data: {
            name: dashboardName,
            visibleTo: "Public",
          },
        }),
      );
      setSelectedDashboardEditId("");
    } else {
      dispatch(
        insightActions.createDashboardRequest({
          name: dashboardName,
          reports: [],
          visibleTo: "Public",
        }),
      );
    }
    setDashboardName("");
  };

  const onEditReportClick = (reportId) => {
    const selectedReport = selectedDashboardStore.reportDetails.find((each) => each._id === reportId);
    if (selectedReport) {
      setIsReportEdit(true);
      setSelectedReportEditId(reportId);
      setOpenReportModal(true);
      settypeReportStep(2);

      setCurrentReportVal({
        name: selectedReport.title,
        entity: selectedReport.entity,
        reportType: selectedReport.reportType,
        graphType: selectedReport.graphType,
      });

      if (selectedReport.duration && selectedReport.duration.start) {
        setFromDateVal(selectedReport.duration.start);
      }

      if (selectedReport.duration && selectedReport.duration.end) {
        setToDateVal(selectedReport.duration.end);
      }

      setInterval(intervalOptions.find((each) => each.value === selectedReport.interval));

      if (selectedReport.pipelineId && selectedReport.pipelineId.length > 0) {
        const selectedPipeline = listOfPipelines.list.find((a) => a._id === selectedReport.pipelineId[0]);

        if (selectedPipeline) {
          setSelectedPipeline({ value: selectedPipeline._id, label: selectedPipeline.name });
        }
      }

      if (selectedReport.typeOfUser) {
        setCustomUser({ type: selectedReport.typeOfUser, id: selectedReport.users });

        if (selectedReport.typeOfUser === "organization") {
          setActiveUser("All Users");
        } else {
          if (userRole.list && userRole.list.users && userRole.list.teams) {
            userRole.list.users.forEach((each) => {
              if (each.id === selectedReport.users) {
                setActiveUser(each.email);
              }
            });
            userRole.list.teams.forEach((each) => {
              if (each.id === selectedReport.users) {
                setActiveUser(each.name);
              }
            });
          }
        }
      }
    }
  };

  const onEditGoalClick = (goalId) => {
    const selectedGoal = goalStore.list.find((each) => each._id === goalId);
    if (selectedGoal) {
      setIsGoalEdit(true);
      setSelectedGoalEditId(goalId);
      setOpenGoalModal(true);

      setSelectedEntity(options.find((each) => each.value === selectedGoal.entity));

      if (selectedGoal.entity === "deal") {
        setDealGoalType(selectedGoal.goalType);
      }
      if (selectedGoal.entity === "activity") {
        setActivityGoalType(selectedGoal.goalType);
      }
      if (selectedGoal.entity === "forecast") {
        setForecastGoalType(selectedGoal.goalType);
      }

      setInterval(intervalOptions.find((each) => each.value === selectedGoal.interval));

      if (selectedGoal.duration && selectedGoal.duration.start) {
        setFromDateVal(selectedGoal.duration.start);
      }

      if (selectedGoal.duration && selectedGoal.duration.end) {
        setToDateVal(selectedGoal.duration.end);
      }

      if (selectedGoal.assignee && selectedGoal.assignee.length > 0) {
        const selectedUser = listOfUsers.list.find((a) => a.userId === selectedGoal.assignee[0]);
        if (selectedUser) {
          setAssignee({ value: selectedUser.userId, label: selectedUser.email });
        }
      }

      if (selectedGoal.pipelineIds && selectedGoal.pipelineIds.length > 0) {
        if (selectedGoal.pipelineIds.length > 1) {
          setSelectedPipeline({ value: "allPipelines", label: "All Pipelines" });
        } else {
          const selectedPipeline = listOfPipelines.list.find((a) => a._id === selectedGoal.pipelineIds[0]);

          if (selectedPipeline) {
            setSelectedPipeline({ value: selectedPipeline._id, label: selectedPipeline.name });
          }
        }
      }

      if (selectedGoal.trackingMetrics) {
        setMetricsValue(selectedGoal.trackingMetrics);
      }
      if (selectedGoal.target) {
        setTargetVal(selectedGoal.target);
      }
    }
  };

  const onDeleteReportClick = (reportId) => {
    dispatch(insightActions.deleteInsightDashboardReportRequest(`${reportId}`));
  };

  const onGoalSelect = (data) => {
    const selected = goalStore.list.find((a) => data === a._id);
    setSelectedGoal(selected ? selected.title : "");
    history.push(`/insights/goals/${data}`);
  };

  const getHeightAndWidthForGraph = (item) => {
    if (item.reportType === "daily-activities-timelines") {
      return { w: 12, h: 15, minW: 12, minH: 15 };
    }
    return { w: 6, h: 10, minW: 4, minH: 10 };
  };

  const generateLayoutNew = (data) => {
    if (dashboardStore.list.length > 0) {
      const selected = dashboardStore.list.find((a) => a._id === props.match.params.dashboardId);
      if (selected && selected.layout.length === data.length) {
        for (let i = 0; i < selected.layout.length; i++) {
          if (selected.layout[i].w < 4 || selected.layout[i].h < 4) {
            return returnLayoutObject(data);
          }
        }
        return selected.layout;
      } else if (selected && selected.layout.length > 0) {
        const layoutObject = {};
        for (let i = 0; i < selected.layout.length; i++) {
          if (selected.layout[i].w < 4 || selected.layout[i].h < 4) {
            return returnLayoutObject(data);
          }
        }
        selected.layout.forEach((each) => {
          layoutObject[each.i] = each;
        });

        const layoutData = data.map((item, i) => {
          const { w, h, minW, minH } = getHeightAndWidthForGraph(item);
          if (layoutObject[item._id]) {
            return layoutObject[item._id];
          }
          let x = (i * 6) % 12,
            y = Math.floor(i / 2);
          return {
            i: item._id,
            x: x,
            y: y,
            w: w,
            h: h,
            minW: minW,
            minH: minH,
          };
        });
        return layoutData;
      }
    }
    return returnLayoutObject(data);
  };

  const returnLayoutObject = (data) => {
    const layoutData = data.map((item, i) => {
      const { w, h, minW, minH } = getHeightAndWidthForGraph(item);
      return {
        i: item._id,
        x: (i * 6) % 12,
        y: Math.floor(i / 2),
        w: w,
        h: h,
        minW: minW,
        minH: minH,
      };
    });
    return layoutData;
  };

  const onLayoutChange = (layout) => {
    dispatch(
      insightActions.editDashboardLayoutRequest({
        dashboardId: props.match.params.dashboardId,
        layout: layout.map((item) => {
          return {
            i: item.i,
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
            minW: item.minW,
            minH: item.minH,
          };
        }),
      }),
    );
  };

  return (
    <>
      {dashboardStore.loading || selectedDashboardStore.loading ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="d-flex flex-row flex-stack w-100">
                <div>
                  <SubheaderNav onClick={(name) => onMenuClick(name)} links={links} active={tabName} />
                </div>
                {tabName === "Dashboard" ? (
                  <div className="d-flex flex-row align-items-center">
                    <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                      <EditableDropdown
                        title={selectedDashboard ? `Dashboard - ${selectedDashboard}` : "Dashboard"}
                        type={"dashboard"}
                        options={dashboardStore.list.map((a) => {
                          return { label: a.name, value: a._id };
                        })}
                        showAddNew={true}
                        showDelete={true}
                        onDeleteClick={(id) => {
                          dispatch(insightActions.deleteDashboardRequest(id));
                        }}
                        onEditClick={(data) => onEditDashboardClick(data)}
                        onAddNewClick={() => onAddDashboardClick()}
                        onSelectValue={(data) => onDashboardSelect(data)}
                      />
                    </div>
                    {dashboardStore.list.length > 0 && (
                      <BlueGradientButton onClick={() => onAddReportClick()} variant="success" size="sm">
                        + Report
                      </BlueGradientButton>
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-row align-items-center gap-12px">
                    <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                      <EditableDropdown
                        title={selectedGoal ? `Goal - ${selectedGoal}` : "Goal"}
                        type={"goal"}
                        showAddNew={false}
                        // showDelete={true}
                        // onDeleteClick={(id) => { dispatch(insightActions.deleteInsightGoalRequest(id)) }}
                        options={goalStore.list.map((a) => {
                          return { label: a.title, value: a._id };
                        })}
                        onEditClick={(data) => onEditGoalClick(data.value)}
                        onSelectValue={(data) => onGoalSelect(data)}
                      />
                    </div>
                    <BlueGradientButton onClick={() => onAddGoalClick()} variant="success" size="sm">
                      + Goal
                    </BlueGradientButton>
                  </div>
                )}
              </div>
            </CustomSubHeader>
          }
        >
          <div className="h-vh-160px overflow-scroll">
            {tabName === "Dashboard" && dashboardStore.list.length === 0 ? ( //empty condition
              <CustomEmptyPage page="dashboard" setShowAdd={onAddDashboardClick} />
            ) : tabName === "Dashboard" && selectedDashboardStore.reportDetails.length === 0 ? (
              <CustomEmptyPage page="report" setShowAdd={onAddReportClick} />
            ) : tabName === "Goals" && goalStore.list.length === 0 ? (
              <CustomEmptyPage page="goal" setShowAdd={onAddGoalClick} />
            ) : tabName === "Dashboard" && dashboardStore.list.length > 0 ? (
              <GridInsightLayout className="layout" layout={generateLayoutNew(selectedDashboardStore.reportDetails || [])} cols={12} onLayoutChange={(a) => onLayoutChange(a)}>
                {selectedDashboardStore.reportDetails.map((eachReport) => {
                  return (
                    <div key={eachReport._id}>
                      <ReportContainer
                        key={eachReport._id}
                        title={eachReport.title}
                        reportId={eachReport._id}
                        onEditReportClick={onEditReportClick}
                        onDeleteReport={onDeleteReportClick}
                        userOptions={userOptions}
                        userRoleOptions={userRole}
                        pipelineOptions={pipelineOptions}
                        listOfPipelines={listOfPipelines}
                        reportType={eachReport.reportType}
                        entity={eachReport.entity}
                        interval={eachReport.interval}
                        graphType={eachReport.graphType}
                        typeOfUser={eachReport.typeOfUser}
                        users={eachReport.users}
                      >
                        <FunnelChart chartId={`funnelChart-${1}`} />
                      </ReportContainer>
                    </div>
                  );
                })}
              </GridInsightLayout>
            ) : (
              <div className="h-100">
                <GoalContainer
                  key={props.match.params.goalId}
                  title={selectedGoal}
                  goalId={props.match.params.goalId}
                  userOptions={userOptions}
                  onEditGoalClick={(goalId) => onEditGoalClick(goalId)}
                  onDeleteGoal={(goalId) => onEditGoalClick(goalId)}
                />
              </div>
            )}

            {/**Add/Edit Dashboard */}
            <DashboardModel
              showDashboardModel={showDashboardModel}
              handleDashboardClose={handleDashboardClose}
              isDashboardEdit={isDashboardEdit}
              dashboardName={dashboardName}
              onDashboardNameChange={onDashboardNameChange}
              visibleTo={visibleTo}
              setVisibleTo={setVisibleTo}
              onAddDashboard={onAddDashboard}
            />

            {/**Add/Edit Goal */}
            {openGoalModal ? (
              <CustomScrollableModal
                modalProps={{
                  show: openGoalModal,
                  handleClose: handleAddNewGoalClose,
                  title: isGoalEdit ? "Edit Goal" : "Add Goal",
                  showButtons: true,
                  buttonText: isGoalEdit ? "Edit" : "Save",
                  size: "lg",
                  handleAccept: handleAddNewGoalSave,
                }}
              >
                <AddGoalForm
                  selectedEntity={selectedEntity}
                  options={options}
                  customStyles={customStyles}
                  dealGoalType={dealGoalType}
                  activityGoalType={activityGoalType}
                  forecastGoalType={forecastGoalType}
                  assigneeOptions={userOptions}
                  pipelineOptions={pipelineOptions}
                  selectedPipeline={selectedPipeline}
                  interval={interval}
                  intervalOptions={intervalOptions}
                  fromDate={FromDateVal}
                  toDate={ToDateVal}
                  assignee={assignee}
                  metricsValue={metricsValue}
                  TargetVal={TargetVal}
                  onEntitySelect={onEntitySelect}
                  onChooseDealGoalType={onChooseDealGoalType}
                  onChooseActivityGoalType={onChooseActivityGoalType}
                  onChooseForecastGoalType={onChooseForecastGoalType}
                  onAssigneeChange={onAssigneeChange}
                  onPipelineChange={onPipelineChange}
                  trackingMetFunction={trackingMetFunction}
                  onIntervalChange={onIntervalChange}
                  toDateFun={toDateFun}
                  fromDateFun={fromDateFun}
                  targetFunction={targetFunction}
                />
              </CustomScrollableModal>
            ) : null}

            {/**Add/Edit Report */}
            {openReportModal ? (
              <CustomScrollableModal
                modalProps={{
                  show: openReportModal,
                  handleClose: handleAddNewReportClose,
                  title: isReportEdit ? "Filter Report" : "Add Report",
                  showButtons: typeReportStep === 2 ? true : false,
                  buttonText: isReportEdit ? "Edit" : "Save",
                  size: "xl",
                  nextButtonVal: typeReportStep === 1 ? true : false,
                  nextButton: handleNextButton,
                  handleAccept: handleAddNewReportSave,
                }}
              >
                <AddReportForm
                  userRoleData={userRoleData}
                  typeReportStep={typeReportStep}
                  typeOfReport={typeOfReportData}
                  selectedEntity={selectedEntity}
                  options={options}
                  customStyles={customStyles}
                  dealGoalType={dealGoalType}
                  forecastGoalType={forecastGoalType}
                  activityGoalType={activityGoalType}
                  fromDate={FromDateVal}
                  toDate={ToDateVal}
                  interval={interval}
                  intervalOptions={intervalOptions}
                  userRoleOptions={userRole}
                  activeCard={activeCard}
                  activeUser={activeUser}
                  pipelineOptions={pipelineOptions}
                  selectedPipeline={selectedPipeline}
                  stepFirstVal={currentReportVal}
                  toDateFun={toDateFun}
                  fromDateFun={fromDateFun}
                  onEntitySelect={onEntitySelect}
                  reportCardValue={reportCardValue}
                  onIntervalChange={onIntervalChange}
                  onChooseDealGoalType={onChooseDealGoalType}
                  onChooseActivityGoalType={onChooseActivityGoalType}
                  onChooseForecastGoalType={onChooseForecastGoalType}
                  setActiveCard={setActiveCard}
                  onCustomUserSelect={onCustomUserSelect}
                  onPipelineChange={onPipelineChange}
                />
              </CustomScrollableModal>
            ) : null}
          </div>
        </ContentWrapper>
      )}
    </>
  );
}
