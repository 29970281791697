export const getListOfActivties = {
  GET_LIST_OF_ACTIVITIES_REQUEST: "GET_LIST_OF_ACTIVITIES_REQUEST",
  GET_LIST_OF_ACTIVITIES_SUCCESS: "GET_LIST_OF_ACTIVITIES_SUCCESS",
  GET_LIST_OF_ACTIVITIES_ERROR: "GET_LIST_OF_ACTIVITIES_ERROR",
  GET_LIST_OF_ACTIVITIES_REQUEST_AFTER: "GET_LIST_OF_ACTIVITIES_REQUEST_AFTER",
};

export const updateLoadingData = {
  UPDATE_ACTIVITY_LOADING_DATA: "UPDATE_ACTIVITY_LOADING_DATA",
};

export const deleteActivities = {
  DELETE_ACTIVITIES_REQUEST: "DELETE_ACTIVITIES_REQUEST",
  DELETE_ACTIVITIES_SUCCESS: "DELETE_ACTIVITIES_SUCCESS",
  DELETE_ACTIVITIES_ERROR: "DELETE_ACTIVITIES_ERROR",
};

export const bulkDeleteActivities = {
  BULK_DELETE_ACTIVITES_REQUEST: "BULK_DELETE_ACTIVITES_REQUEST",
  BULK_DELETE_ACTIVITES_SUCCESS: "BULK_DELETE_ACTIVITES_SUCCESS",
  BULK_DELETE_ACTIVITES_ERROR: "BULK_DELETE_ACTIVITES_ERROR",
};

export const editActivity = {
  EDIT_ACTIVITY_REQUEST: "EDIT_ACTIVITY_REQUEST",
  EDIT_ACTIVITY_SUCCESS: "EDIT_ACTIVITY_SUCCESS ",
  EDIT_ACTIVITY_ERROR: "EDIT_ACTIVITY_ERROR ",
};

export const createActivity = {
  CREATE_ACTIVITY_REQUEST: "CREATE_ACTIVITY_REQUEST",
  CREATE_ACTIVITY_SUCCESS: "CREATE_ACTIVITY_SUCCESS ",
  CREATE_ACTIVITY_ERROR: "CREATE_ACTIVITY_ERROR ",
};

export const createShortActivity = {
  CREATE_SHORT_ACTIVITY_REQUEST: "CREATE_SHORT_ACTIVITY_REQUEST",
  CREATE_SHORT_ACTIVITY_SUCCESS: "CREATE_SHORT_ACTIVITY_SUCCESS ",
  CREATE_SHORT_ACTIVITY_ERROR: "CREATE_SHORT_ACTIVITY_ERROR ",
};

//activity operations
export const markAsDone = {
  MARK_AS_DONE_ACTIVITY_REQUEST: "MARK_AS_DONE_ACTIVITY_REQUEST",
  MARK_AS_DONE_ACTIVITY_SUCCESS: "MARK_AS_DONE_ACTIVITY_SUCCESS",
  MARK_AS_DONE_ACTIVITY_ERROR: "MARK_AS_DONE_ACTIVITY_ERROR",
};

export const markAsUndone = {
  MARK_AS_UNDONE_ACTIVITY_REQUEST: "MARK_AS_UNDONE_ACTIVITY_REQUEST",
  MARK_AS_UNDONE_ACTIVITY_SUCCESS: "MARK_AS_UNDONE_ACTIVITY_SUCCESS",
  MARK_AS_UNDONE_ACTIVITY_ERROR: "MARK_AS_UNDONE_ACTIVITY_ERROR",
};

export const snoozeActivity = {
  SNOOZE_ACTIVITY_REQUEST: "SNOOZE_ACTIVITY_REQUEST",
  SNOOZE_ACTIVITY_SUCCESS: "SNOOZE_ACTIVITY_SUCCESS",
  SNOOZE_ACTIVITY_ERROR: "SNOOZE_ACTIVITY_ERROR",
};

//activity data field
export const getActivityDataField = {
  GET_ACTIVITY_DATA_FIELD_REQUEST: "GET_ACTIVITY_DATA_FIELD_REQUEST",
  GET_ACTIVITY_DATA_FIELD_SUCCESS: "GET_ACTIVITY_DATA_FIELD_SUCCESS",
  GET_ACTIVITY_DATA_FIELD_ERROR: "GET_ACTIVITY_DATA_FIELD_ERROR",
};

export const showHideActivityFields = {
  SHOW_HIDE_ACTIVITY_FIELD_REQUEST: "SHOW_HIDE_ACTIVITY_FIELD_REQUEST",
  SHOW_HIDE_ACTIVITY_FIELD_SUCCESS: "SHOW_HIDE_ACTIVITY_FIELD_SUCCESS",
  SHOW_HIDE_ACTIVITY_FIELD_ERROR: "SHOW_HIDE_ACTIVITY_FIELD_ERROR",
};

export const updateActivityFieldPriority = {
  UPDATE_ACTIVITY_FIELD_PRIORITY_REQUEST: "UPDATE_ACTIVITY_FIELD_PRIORITY_REQUEST",
  UPDATE_ACTIVITY_FIELD_PRIORITY_SUCCESS: "UPDATE_ACTIVITY_FIELD_PRIORITY_SUCCESS",
  UPDATE_ACTIVITY_FIELD_PRIORITY_ERROR: "UPDATE_ACTIVITY_FIELD_PRIORITY_ERROR",
};

//activity saved filters
export const getListOfActivitySavedFilters = {
  GET_LIST_OF_ACTIVITY_SAVED_FILTERS_REQUEST: "GET_LIST_OF_ACTIVITY_SAVED_FILTERS_REQUEST",
  GET_LIST_OF_ACTIVITY_SAVED_FILTERS_SUCCESS: "GET_LIST_OF_ACTIVITY_SAVED_FILTERS_SUCCESS",
  GET_LIST_OF_ACTIVITY_SAVED_FILTERS_ERROR: "GET_LIST_OF_ACTIVITY_SAVED_FILTERS_ERROR",
};
export const createActivitySavedFilter = {
  CREATE_ACTIVITY_SAVED_FILTER_REQUEST: "CREATE_ACTIVITY_SAVED_FILTER_REQUEST",
  CREATE_ACTIVITY_SAVED_FILTER_SUCCESS: "CREATE_ACTIVITY_SAVED_FILTER_SUCCESS",
  CREATE_ACTIVITY_SAVED_FILTER_ERROR: "CREATE_ACTIVITY_SAVED_FILTER_ERROR",
};
export const editActivitySavedFilter = {
  EDIT_ACTIVITY_SAVED_FILTER_REQUEST: "EDIT_ACTIVITY_SAVED_FILTER_REQUEST",
  EDIT_ACTIVITY_SAVED_FILTER_SUCCESS: "EDIT_ACTIVITY_SAVED_FILTER_SUCCESS",
  EDIT_ACTIVITY_SAVED_FILTER_ERROR: "EDIT_ACTIVITY_SAVED_FILTER_ERROR",
};
export const deleteActivitySavedFilters = {
  DELETE_ACTIVITY_SAVED_FILTER_REQUEST: "DELETE_ACTIVITY_SAVED_FILTER_REQUEST",
  DELETE_ACTIVITY_SAVED_FILTER_SUCCESS: "DELETE_ACTIVITY_SAVED_FILTER_SUCCESS",
  DELETE_ACTIVITY_SAVED_FILTER_ERROR: "DELETE_ACTIVITY_SAVED_FILTER_ERROR",
};
export const markAsFavoriteActivitySaveFilter = {
  MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_REQUEST: "MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_REQUEST",
  MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_SUCCESS: "MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_SUCCESS",
  MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_ERROR: "MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_ERROR",
};

//activity Loss Reasons
export const getActivityLossReasons = {
  GET_ACTIVITY_LOSS_REASONS_REQUEST: "GET_ACTIVITY_LOSS_REASONS_REQUEST",
  GET_ACTIVITY_LOSS_REASONS_SUCCESS: "GET_ACTIVITY_LOSS_REASONS_SUCCESS",
  GET_ACTIVITY_LOSS_REASONS_ERROR: "GET_ACTIVITY_LOSS_REASONS_ERROR",
};

export const getActivityLossReasonsSettings = {
  GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST: "GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST",
  GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS: "GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS",
  GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR: "GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR",
};

export const updateActivityLossReasonsSettings = {
  UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST: "UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST",
  UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS: "UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS",
  UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR: "UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR",
};

//active activity types
export const getListOfActiveActivityTypes = {
  GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_REQUEST: "GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_REQUEST",
  GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_SUCCESS: "GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_SUCCESS",
  GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_ERROR: "GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_ERROR",
};

//assocaistion
export const searchAllAssociations = {
  SEARCH_ALL_ASSOCIATIONS_REQUEST: "SEARCH_ALL_ASSOCIATIONS_REQUEST",
  SEARCH_ALL_ASSOCIATIONS_SUCCESS: "SEARCH_ALL_ASSOCIATIONS_SUCCESS",
  SEARCH_ALL_ASSOCIATIONS_ERROR: "SEARCH_ALL_ASSOCIATIONS_ERROR",
};

//logs
export const getUserActivityLogForSidePannel = {
  GET_USER_ACTIVITY_LOGS_REQUEST_FOR_SIDEPANEL: "GET_USER_ACTIVITY_LOGS_REQUEST_FOR_SIDEPANEL",
};
