import { takeLatest, put, call, select } from "redux-saga/effects";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import {
  getTemplateCategory,
  getTemplateTags,
  getTemplatetList,
  editTemplate,
  editTemplateTag,
  createTemplate,
  createTemplateTag,
  deleteTemaplateTag,
  deleteTemplate,
  cloneTemplate,
  UploadTemplate,
} from "../types/campaign_templates_types";
import * as TemplateActions from "../actions/campaign_templates_actions";
import { endpoints } from "../endpoints";
import api from "../../../../network/apis/api";
import _ from "lodash";

function* getTemplateTagsList(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && !_.isEmpty(store.campaign.templates.tags.data)) {
      yield put(TemplateActions.getTemplateTagsSuccess({ data: store.campaign.templates.tags.data }));
      if (action.cb) action.cb({ data: store.campaign.templates.tags.data });
    } else {
      const res = yield call(api.get, endpoints.CAMAPIGN_TEMPLATE_TAGS);
      if (res.status === 200) {
        yield put(TemplateActions.getTemplateTagsSuccess(res.data));
      } else {
        dispatchSnackbarError(res.data.message);
        yield put(TemplateActions.getTemplateTagsError(res.data));
      }
      if (action.cb) action.cb(res.data);
    }
  } catch (e) {
    yield put(TemplateActions.getTemplateTagsError(e));
  }
}

function* createTemplateTagsList(action) {
  try {
    const res = yield call(api.post, endpoints.CAMAPIGN_TEMPLATE_TAGS, action.payload);
    if (res.status === 200) {
      yield put(TemplateActions.createTemplateTagSuccess(res.data));
      yield put(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: false }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.createTemplateTagError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.createTemplateTagError(e));
  }
}

function* editTemplateTagsList(action) {
  try {
    const res = yield call(api.patch, `${endpoints.CAMAPIGN_TEMPLATE_TAGS}/${action.payload.id}`, action.payload.body);
    if (res.status === 200) {
      yield put(TemplateActions.editTemplateTagSuccess(res.data));
      yield put(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: false }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.editTemplateTagError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.editTemplateTagError(e));
  }
}

function* deleteTemplateTagsList(action) {
  try {
    const res = yield call(api.delete, `${endpoints.CAMAPIGN_TEMPLATE_TAGS}/${action.payload.id}`, {});
    if (res.status === 200) {
      yield put(TemplateActions.deleteTemaplateTagSuccess(res.data));
      yield put(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: false }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.deleteTemaplateTagError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.deleteTemaplateTagError(e));
  }
}

function* getTemplateCategoryFun(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && !_.isEmpty(store.campaign.templates.categories.list)) {
      yield put(TemplateActions.getTemplateCategorySuccess({ data: store.campaign.templates.categories.list }));
      if (action.cb) action.cb({ data: store.campaign.templates.categories.list });
    } else {
      const res = yield call(api.get, `${endpoints.CAMPAIGN_TEMPLATE_CATEGORY}`);
      if (res.status === 200) {
        yield put(TemplateActions.getTemplateCategorySuccess(res.data));
      } else {
        dispatchSnackbarError(res.data.message);
        yield put(TemplateActions.getTemplateCategoryError(res.data));
      }
      if (action.cb) action.cb(res.data);
    }
  } catch (e) {
    yield put(TemplateActions.getTemplateCategoryError(e));
  }
}

function* getTemplateListFun(action) {
  try {
    const res = yield call(api.get, endpoints.CAMAPIGN_TEMPLATE, action.payload);
    if (res.status === 200) {
      yield put(TemplateActions.getTemplatetListSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.getTemplatetListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.getTemplatetListError(e));
  }
}

function* createTemplateFunc(action) {
  try {
    const res = yield call(api.post, endpoints.CAMAPIGN_TEMPLATE, action.payload.data);
    if (res.status === 200) {
      yield put(TemplateActions.createTemplateSuccess(res.data));
      yield put(TemplateActions.getTemplatetListRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.createTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.createTemplateError(e));
  }
}

function* editTemplateFunc(action) {
  try {
    const res = yield call(api.patch, `${endpoints.CAMAPIGN_TEMPLATE}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(TemplateActions.editTemplateSuccess(res.data));
      yield put(TemplateActions.getTemplatetListRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.editTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.editTemplateError(e));
  }
}

function* deleteTemplateFunc(action) {
  try {
    const res = yield call(api.delete, `${endpoints.CAMAPIGN_TEMPLATE}/${action.payload.id}`, {});
    if (res.status === 200) {
      yield put(TemplateActions.deleteTemplateSuccess(res.data));
      yield put(TemplateActions.getTemplatetListRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.deleteTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.deleteTemplateError(e));
  }
}

function* cloneTemplateFunc(action) {
  try {
    const res = yield call(api.patch, `${endpoints.CAMAPIGN_TEMPLATE}/clone/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(TemplateActions.cloneTemplateSuccess(res.data));
      yield put(TemplateActions.getTemplatetListRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.cloneTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.cloneTemplateError(e));
  }
}

function* uploadTemplateFunc(action) {
  try {
    const res = yield call(api.post, `${endpoints.CAMAPIGN_TEMPLATE}/upload-templates`, action.payload.data);
    if (res.status === 200) {
      yield put(TemplateActions.UploadTemplateSuccess(res.data));
      yield put(TemplateActions.getTemplatetListRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(TemplateActions.UploadTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(TemplateActions.UploadTemplateError(e));
  }
}

export default function* campaignTemplatesSaga() {
  yield takeLatest(getTemplateTags.GET_TEMPLATE_TAGS_REQUEST, getTemplateTagsList);
  yield takeLatest(createTemplateTag.CREATE_TEMPLATE_TAG_REQUEST, createTemplateTagsList);
  yield takeLatest(editTemplateTag.EDIT_TEMPLATE_TAG_REQUEST, editTemplateTagsList);
  yield takeLatest(deleteTemaplateTag.DELETE_TEMPLATE_TAG_REQUEST, deleteTemplateTagsList);

  yield takeLatest(getTemplateCategory.GET_TEMPLATE_CATEGORY_REQUEST, getTemplateCategoryFun);

  yield takeLatest(getTemplatetList.GET_TEMPLATE_LIST_REQUEST, getTemplateListFun);
  yield takeLatest(createTemplate.CREATE_TEMPLATE_REQUEST, createTemplateFunc);
  yield takeLatest(editTemplate.EDIT_TEMPLATE_REQUEST, editTemplateFunc);
  yield takeLatest(deleteTemplate.DELETE_TEMPLATE_REQUEST, deleteTemplateFunc);
  yield takeLatest(cloneTemplate.CLONE_TEMPLATE_REQUEST, cloneTemplateFunc);
  yield takeLatest(UploadTemplate.UPLOAD_TEMPLATE_REQUEST, uploadTemplateFunc);
}
