import moment from "moment-timezone";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { alphabetColorMapping } from "../../../constants";
import "./styles.scss";
import InlineStyled from "../../inline-styled/inline-styled";

const statusToColor = {
  ACTIVE: "#FFE5A3",
  PENDING: "#CEC6FF",
  PAUSED: "#A3E3FF",
  FINISHED: "#75EB4B",
  FAILED: "#FE8080",
};

const getAlphabetData = (owner) => {
  if (_.isEmpty(owner)) return { colorData: alphabetColorMapping["0"], letter: "0" };
  const text = owner.name ? owner.name : owner.email;
  const firstChar = text.charAt(0).toUpperCase();
  if (alphabetColorMapping[firstChar]) {
    return { colorData: alphabetColorMapping[firstChar], letter: firstChar };
  }
  return { colorData: alphabetColorMapping["0"], letter: firstChar };
};
export default function CadenceLeadListCard(props) {
  const [owner, setOwner] = useState({});
  const { colorData, letter } = getAlphabetData(owner);

  const history = useHistory();

  const userRole = useSelector((store) => store.insight.userRole);
  const userProfileStore = useSelector((state) => state.ProfileSettings.getProfileDetails.data);
  useEffect(() => {
    if (userRole.list.allUsers) {
      if (typeof props.data.addedBy === "object" && props.data.addedBy) {
        setOwner(userRole.list.allUsers.find((owner) => owner["id"] === props.data.addedBy[0]));
      } else {
        setOwner(userRole.list.allUsers.find((owner) => owner["id"] === props.data.addedBy));
      }
    }
  }, [userRole.list.allUsers, props.data.addedBy]);

  const onCadenceNameClick = () => {
    if (userProfileStore.userId === props.data.addedBy) history.push(`/automation/cadence/edit/${props.data.cadenceId}`);
  };

  return (
    <div className="cadence-lead-card-wrapper">
      <div className="cadence-lead-head-section">
        <div className="cadence-lead-head-section-1">
          <div className="user-icon-wraper">
            <span className="data-container ml-10px">
              <OverlayTrigger overlay={<Tooltip>{`Cadence Owner: ${owner ? (owner.name ? owner.name : owner.email) : ""}`}</Tooltip>}>
                {owner && owner.avatar ? (
                  <img className="mr-2 w-22px h-22px border-radius-50-percent" src={owner.avatar} alt="profile" />
                ) : (
                  <InlineStyled
                    as="span"
                    className="d-inline-block mr-2 w-22px h-22px border-radius-50-percent"
                    styles={{
                      background: colorData["background"],
                      color: colorData["color"],
                    }}
                  >
                    <span className="d-flex align-items-center justify-content-center fs-3">{letter}</span>
                  </InlineStyled>
                )}
              </OverlayTrigger>
            </span>
          </div>
          <div className="cadence-name-date-wraper">
            <div className="cadence-name-wraper text-hover-primary cursor-pointer" onClick={() => onCadenceNameClick()}>
              {props.data.cadenceName}
            </div>
            <div className="cadence-date-wraper">
              <OverlayTrigger overlay={<Tooltip>Subscribed At</Tooltip>}>
                <span>{moment(props.data.addedDate).format("MMM DD, h:mm a")}</span>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <div className="cadence-lead-head-section-2">
          <InlineStyled className="cadence-status-wrapper" styles={{ customColor: statusToColor[props.data.status] }}>
            <span>{props.data.status}</span>
          </InlineStyled>
          <div className="cadence-executed-step-wrapper">
            {props.data.executatedStepCount}/{props.data.totalSteps} step executed
          </div>
        </div>
      </div>
      <div className="cadence-lead-analytics-section">
        <div className="card-section-2-cadence-stats">
          <div className="head-name">Business Email</div>
          <table className="count-name-wrapper">
            <tbody>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.syncEmailSent}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Sent</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.syncEmailOpened}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Opened</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.syncEmailClicked}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Clicked</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.syncEmailReplied}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Replied</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-section-2-cadence-stats">
          <div className="head-name">Campaign Email</div>
          <table className="count-name-wrapper">
            <tbody>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.campaignEmailSent}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Sent</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.campaignEmailOpened}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Opened</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.campaignEmailClicked}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Clicked</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="count-wrapper">{props.data.stats.campaignEmailReplied}</div>
                </td>
                <td>
                  <div className="name-wrapper"> Replied</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-section-2-cadence-stats-sub-main">

          <div className="card-section-2-cadence-stats-sub">
            <div className="head-name">SMS</div>
            <table className="count-name-wrapper">
              <tbody>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.smsSent}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Sent</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.smsDelivered}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Delivered</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.smsUnDelivered}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Undelivered</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-section-2-cadence-stats-sub">
            <div className="head-name">Activity</div>
            <table className="count-name-wrapper">
              <tbody>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.activityCreated}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Created</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.activityCompleted}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Completed</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-section-2-cadence-stats-sub-main">
          <div className="card-section-2-cadence-stats-sub">
            <div className="head-name">Whatsapp</div>
            <table className="count-name-wrapper">
              <tbody>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.whatsappSent}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Sent</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.whatsappDelivered}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Delivered</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.whatsappRead}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Read</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-section-2-cadence-stats-sub h-100">
            <div className="head-name">Others</div>
            <table className="count-name-wrapper">
              <tbody>
                <tr>
                  <td>
                    <div className="count-wrapper">{props.data.stats.errored}</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> Errored</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="count-wrapper">{ }</div>
                  </td>
                  <td>
                    <div className="name-wrapper"> </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="cadence-lead-bottom-section">
        <div className="cadence-lead-bottom-section-left">
          <OverlayTrigger overlay={<Tooltip>Unsubscribe from cadence</Tooltip>}>
            <Button onClick={() => props.unsubscribe(props.data)} size="sm" variant="outline-primary" className="border border-primary">
              <i className="bi bi-dash-circle"></i>
              Unsubscribe
            </Button>
          </OverlayTrigger>
        </div>
        {props.data.nextStep ? (
          <div className="cadence-lead-bottom-section-right">
            <div className="d-flex gap-2">
              <div className="next-step-label">Next:</div>
              <div className="next-step-value">{props.data.nextStep}</div>
            </div>
            {props.data.automatedAt && <div className="automated-text">Automated at {moment(props.data.automatedAt).format("MMM DD, h:mm a")}</div>}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
