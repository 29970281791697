import { takeLatest, put, call } from "redux-saga/effects";
import * as couponCodeTypes from "../types/coupon_code_types";
import * as couponCodeActions from "../actions/coupon_code_actions";
import * as billingActions from "../actions/subscription_billing_v2_actions";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";

const endpoints = {
  APPLY_COUPON_CODE: "/user-service/api/coupon-code/redeem",
};

function* applyCouponCode(action) {
  try {
    const res = yield call(api.post, endpoints.APPLY_COUPON_CODE, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(billingActions.getAccountBillingV2Request(() => {}));
      yield put(couponCodeActions.applyCouponCodeSuccess(res.data));
    } else if (res.status === 429) {
      dispatchSnackbarError("Too Many Request Error. Please try again next day.");
      yield put(couponCodeActions.applyCouponCodeError(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(couponCodeActions.applyCouponCodeError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(couponCodeActions.applyCouponCodeError(e));
  }
}

export default function*() {
  yield takeLatest(couponCodeTypes.applyCouponCode.APPLY_COUPON_CODE_REQUEST, applyCouponCode);
}
