import React, { useState } from "react";
import CustomSidePanel from "../../../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as whatsappSettingsAction from "../../../../store/actions/campaign_whatsapp_setting_actions";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import WhatsappCloudApiHelpDoc from "./whatsapp-cloud-api-help-doc";

export default function AddWhatsappCloudAPI(props) {
    const [loading, setLoading] = useState(false);
    const [showHelpDoc, setShowHelpDoc] = useState(false);
    const [businessAccountId, setBusinessAccountId] = useState("");
    const [phoneNumberId, setPhoneNumberId] = useState("");
    const [accessToken, setAccessToken] = useState("");

    const dispatch = useDispatch();
    const handleSave = () => {
        if (!businessAccountId || !phoneNumberId || !accessToken) return dispatchSnackbarError("Please fill all the fields");
        const payload = {
            "type": "cloud_api",
            "clientId": businessAccountId,
            "channelId": [phoneNumberId],
            "accessToken": accessToken
        }
        setLoading(true);
        dispatch(whatsappSettingsAction.createWhatsappAccountRequest(payload, (cb) => {
            if (cb.status) props.setShow(false);
            setLoading(false);
        }))
    }
    return (
        <CustomSidePanel
            show={props.show}
            moduleName={"Add Account"}
            handleClose={() => props.setShow(false)}
            showNextButton={false}
            showPreviosButton={false}
            customStyles={{
                width: "50vw",
                right: "51vw",
            }}
        >
            <div className="card-header d-flex flex-stack align-items-center">
                <h3 className="card-title fw-bolder text-dark">{`Add Whatsapp Business Account`}</h3>
                {/* <Button variant="link">Generate API Credentials</Button> */}
            </div>
            <div class="modal-body">
                <Form.Group>
                    <Form.Label>Whatsapp Business Account ID</Form.Label>
                    <Form.Control type="text" value={businessAccountId} onChange={e => setBusinessAccountId(e.target.value)} maxLength={20} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Phone Number ID</Form.Label>
                    <Form.Control type="text" value={phoneNumberId} onChange={e => setPhoneNumberId(e.target.value)} maxLength={20} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Access Token</Form.Label>
                    <Form.Control rows={4} as="textarea" type="text" value={accessToken} onChange={e => setAccessToken(e.target.value)} maxLength={512} />
                </Form.Group>
                <Form.Group>
                    <Button variant="primary" size="sm" onClick={handleSave}>
                        {loading && <Spinner animation="border" className="mr-10px h-16px w-16px"></Spinner>}
                        Save & Validate
                    </Button>
                </Form.Group>

                <div class="d-flex flex-stack">
                    <div>
                        <h4>Don't have the API credentials?</h4>
                        <p>If you do not have the above mentioned API credentials, click on the button below to learn how to generate them.</p>
                        <Button variant="link" size="sm" onClick={() => setShowHelpDoc(true)}>Generate API credentials</Button>
                    </div>
                </div>
            </div>

            <WhatsappCloudApiHelpDoc show={showHelpDoc} setShow={setShowHelpDoc} />
        </CustomSidePanel>
    )
}