import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../network/apis/api";
import * as IntegrationActions from "../actions/integrations.actions";
// import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../../utils/toaster';
import { GET_INTEGRATIONS_DETAILS_REQUEST, DELETE_INTEGRATION_REQUEST, GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST, GET_INTEGRATION_AUTH_URL_REQUEST, GET_INTEGRATION_CALLBACK_REQUEST } from "../types/integrations.types";

function* getIntegrationDetails(action) {
  try {
    const res = yield call(api.get, "/user-service/api/integration/get-list-of-integrations", action.payload);
    if (res.status === 200) {
      yield put(IntegrationActions.getIntegrationDetailsSuccess(res.data.data));
    }
  } catch (e) {
    yield put(IntegrationActions.getIntegrationDetailsError(e));
  }
}

function* deleteIntegration(action) {
  try {
    const res = yield call(api.delete, `/user-service/api/integration/delete-integration/${action.payload.id}`);
    if (res.status === 200) {
      yield put(IntegrationActions.getIntegrationAccountDetailsRequest({ apptype: action.payload.apptype }));
    }
  } catch (e) {
    yield put(IntegrationActions.deleteIntegrationError(e));
  }
}

function* integrationAccountDetails(action) {
  try {
    const res = yield call(api.get, `/user-service/api/integration/integration-details?appType=${action.payload.apptype}`);
    if (res.status === 200) {
      yield put(IntegrationActions.getIntegrationAccountDetailsSuccess(res.data));
    } else if (res.status === 400) {
      yield put(IntegrationActions.getIntegrationAccountDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(IntegrationActions.getIntegrationAccountDetailsError(e));
  }
}

function* integrationUrl(action) {
  try {
    const res = yield call(api.get, `/user-service/api/integration/zoom-auth`);
    if (res.status === 200) {
      yield put(IntegrationActions.getIntegrationAuthUrlSuccess(res.data));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(IntegrationActions.getIntegrationAuthUrlError(e));
  }
}

function* integrationCallback(action) {
  try {
    const res = yield call(api.post, `/user-service/api/integration/zoom-auth-callback`, action.payload);
    if (res.status === 200) {
      yield put(IntegrationActions.getIntegrationCallbackSuccess(res.data));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(IntegrationActions.getIntegrationCallbackError(e));
  }
}

export default function*() {
  yield takeLatest(GET_INTEGRATIONS_DETAILS_REQUEST, getIntegrationDetails);
  yield takeLatest(DELETE_INTEGRATION_REQUEST, deleteIntegration);
  yield takeLatest(GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST, integrationAccountDetails);
  yield takeLatest(GET_INTEGRATION_AUTH_URL_REQUEST, integrationUrl);
  yield takeLatest(GET_INTEGRATION_CALLBACK_REQUEST, integrationCallback);
}
