import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { CustomScrollableModal } from "../modal";
import { useDispatch } from "react-redux";
import { exportRequest } from "../../containers/import/actions";
import { Redirect } from "react-router-dom";

export function Export(props) {
  const [fileType, setFileType] = useState("csv");
  const [dataExport, setDataExport] = useState(false);
  const [filterExport, setFilterExport] = useState(false);
  const filterData = props.activeFilter ? props.activeFilter : { filterId: "" };
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const handleExport = () => {
    const temp = {
      fileFormat: fileType,
      dataType: props.dataType,
      isAllFieldsData: dataExport,
      selectedFields: dataExport ? [] : props.showColumns,
      isFilteredData: filterExport,
      filter: props.appliedFilter,
      ...filterData,
    };
    dispatch(exportRequest(temp));
    setTimeout(() => {
      setRedirect(true);
    }, 250);
  };

  const modalProps = {
    size: "md",
    showButtons: true,
    buttonText: "Continue",
    title: "Export Data",
    handleAccept: () => handleExport(),
    handleClose: () => props.setShowExportModal(false),
    show: props.showExportModal,
  };
  return (
    <>
      {redirect ? (
        <Redirect to={`/export/${props.dataType}`} />
      ) : (
        <CustomScrollableModal modalProps={modalProps}>
          <Form.Label aria-label="select-fields-to-export" className="pt-3 pb-1">
            Select Fields To Export
          </Form.Label>
          <Form.Group id="export-fields-to-export">
            <Form.Check type="radio" custom label={`All Fields`} id={`all`} name="dataExport" checked={dataExport} onChange={() => setDataExport(true)} />
            <Form.Check type="radio" custom label={`Current Fields`} id={`current`} name="dataExport" checked={!dataExport} onChange={() => setDataExport(false)} />
          </Form.Group>
          {props.activeFilter ? (
            <>
              <Form.Label aria-label="select-data-to-export" className="pt-3 pb-1">
                Select Data to Export
              </Form.Label>
              <Form.Group id="export-data-to-export">
                <Form.Check type="radio" custom label={`All Rows`} id={`allrows`} name="filterExport" checked={!filterExport} onChange={() => setFilterExport(false)} />
                <Form.Check type="radio" custom label={`Filtered Rows`} id={`filtered`} name="filterExport" checked={filterExport} onChange={() => setFilterExport(true)} />
              </Form.Group>
            </>
          ) : null}
          <Form.Label aria-label="choose-file-format" className="pt-3 pb-1">
            Choose File Format
          </Form.Label>
          <Form.Group id="export-choose-file-format">
            <Form.Check custom type="radio" label={`CSV (Comma Separated Values)`} id={`CSV`} name="filetype" checked={fileType === "csv"} onChange={() => setFileType("csv")} />
          </Form.Group>
        </CustomScrollableModal>
      )}
    </>
  );
}
