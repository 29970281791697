import React, { useEffect, useState } from "react";
import validator from "validator";
import _ from "lodash";
import { Form, Spinner } from "react-bootstrap";
import SimpleDropdown from "../../../../../../../components/dropdowns/simple-dropdown";
import { Button } from "react-bootstrap";
import * as whatsappSettingsAction from "../../../../../store/actions/campaign_whatsapp_setting_actions";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../../../../../../utils/toaster";
import InlineStyled from "../../../../../../../components/inline-styled/inline-styled";
import "./styles.scss";

const categoryOptions = [
    { value: "NOT_A_BIZ", label: "Not a business" },
    { value: "AUTO", label: "Automotive" },
    { value: "BEAUTY", label: "Beauty, Spa & Salon" },
    { value: "APPAREL", label: "Clothing & Apparel" },
    { value: "EDU", label: "Education" },
    { value: "ENTERTAIN", label: "Entertainment" },
    { value: "EVENT_PLAN", label: "Event Planning & Service" },
    { value: "FINANCE", label: "Finance & Banking" },
    { value: "GROCERY", label: "Grocery & Supermarket" },
    { value: "GOVT", label: "Public & Government Service" },
    { value: "HOTEL", label: "Hotel & Lodging" },
    { value: "HEALTH", label: "Medical & Health" },
    { value: "NONPROFIT", label: "Non-profit" },
    { value: "PROF_SERVICES", label: "Professional Services" },
    { value: "RETAIL", label: "Shopping & Retail" },
    { value: "TRAVEL", label: "Travel & Transportation" },
    { value: "RESTAURANT", label: "Restaurant" },
    { value: "OTHER", label: "Other" },
]

export default function WhatsappProfile(props) {
    const [profileUrl, setProfileUrl] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const [category, setCategory] = useState("");
    const [website1, setWebsite1] = useState("");
    const [website2, setWebsite2] = useState("");
    const [email, setEmail] = useState("");
    const [about, setAbout] = useState("");
    const [categoryLabel, setCategoryLabel] = useState("");
    const [profileImageLoading, setProfileImageLoading] = useState(false);
    const [updatingDataLoading, setUpdatingDataLoading] = useState(false);
    const [displayName, setDisplayName] = useState("");

    const dispatch = useDispatch();

    const whatsappProfileStore = useSelector(state => state.campaign.settings.whatsapp.selected);
    const whatsappAccountStore = useSelector(state => state.campaign.settings.whatsapp.data);

    useEffect(() => {
        if (!_.isEmpty(whatsappProfileStore.data)) {
            setProfileUrl(whatsappProfileStore.data.profilePictureUrl)
            setDescription(whatsappProfileStore.data.description)
            setAddress(whatsappProfileStore.data.address)
            setCategory(whatsappProfileStore.data.vertical)
            setEmail(whatsappProfileStore.data.email)
            setAbout(whatsappProfileStore.data.about)

            if (whatsappProfileStore.data.websites.length === 1) {
                setWebsite1(whatsappProfileStore.data.websites[0])
                setWebsite2("")
            }
            else if (whatsappProfileStore.data.websites.length === 2) {
                setWebsite1(whatsappProfileStore.data.websites[0])
                setWebsite2(whatsappProfileStore.data.websites[1])
            }
            else {
                setWebsite1("")
                setWebsite2("")
            }
        }
    }, [whatsappProfileStore.data])

    useEffect(() => {
        const findAccount = whatsappAccountStore.list.find(item => item._id === props.integrationId);
        if (findAccount) {
            setDisplayName(findAccount.verifiedName)
        }
    }, [whatsappAccountStore.list, props.integrationId])

    useEffect(() => {
        const category1 = categoryOptions.find(item => item.value === category)
        if (category1) {
            setCategoryLabel(category1.label)
        }
    }, [category])

    const handleSave = () => {
        if (website1 && validator.isURL(website1) === false) {
            return dispatchSnackbarError("Please enter a valid website URL 1");
        }
        else if (website2 && validator.isURL(website2) === false) {
            return dispatchSnackbarError("Please enter a valid website URL 2");
        }
        else if (email && validator.isEmail(email) === false) {
            return dispatchSnackbarError("Please enter a valid email");
        }

        console.log(about)
        const payload = {
            data: {
                "id": props.integrationId,
                "data": {
                    "address": address,
                    "description": description,
                    "vertical": category,
                    // "about": about,
                    "email": email,
                    "websites": [website1, website2].filter(Boolean),
                }
            },
            integrationId: props.integrationId,
        }
        setUpdatingDataLoading(true)
        dispatch(whatsappSettingsAction.updateWhatsappNumberProfileRequest(payload, (data) => {
            setUpdatingDataLoading(false)
        }));
    }

    const handleProfilePhotoUplaod = (e) => {
        const formData = new FormData();
        formData.append("profile", e.target.files[0]);
        formData.append("id", props.integrationId)
        setProfileImageLoading(true)
        dispatch(whatsappSettingsAction.uploadWhatsappNumberProfilePhotoRequest({ data: formData, integrationId: props.integrationId }, (data) => {
            setProfileImageLoading(false)
        }));
    }

    if (whatsappProfileStore.loading) {
        return <div className="w-100 scroll-y pt-10 pb-10 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
        </div>
    }
    return (
        <div className="h-100">
            {profileImageLoading && <div className="p-4 text-primary text-center bg-light-primary"><Spinner animation="border" variant="primary" className="mr-10px h-16px w-16px"></Spinner> Uploading Image...</div>}
            {updatingDataLoading && <div className="p-4 text-primary text-center bg-light-primary"><Spinner animation="border" variant="primary" className="mr-10px h-16px w-16px"></Spinner> Uploading Data...</div>}
            <div className="d-flex flex-row flex-stack h-100" style={{ background: "#f0f2f5" }}>
                <div className="w-100-360px bg-white h-100">
                    <div className="p-8">
                        <p>Update your WhatsApp Business profile details. These details will be visible to contacts when they open your profile on their WhatsApp.</p>
                        <Form.Group>
                            <Form.Label className="w-100">WHATSAPP PROFILE PICTURE</Form.Label>
                            <InlineStyled className="image-input image-input-outline" data-kt-image-input="true" styles={{ backgroundImage: "url(assets/media/avatars/blank.png)" }}>
                                <InlineStyled className="image-input-wrapper w-125px h-125px" styles={{ backgroundImage: `url(${profileUrl})` }} />
                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                    <i className="bi bi-pencil-fill fs-7" />
                                    <input onChange={(e) => handleProfilePhotoUplaod(e)} type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                    <input type="hidden" name="avatar_remove" />
                                </label>
                            </InlineStyled>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as={"textarea"} value={description} onChange={e => setDescription(e.target.value)} type="text" maxLength={512} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control value={address} onChange={e => setAddress(e.target.value)} type="text" maxLength={256} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Category</Form.Label>
                            <SimpleDropdown value={category} options={categoryOptions} label="Select Category" handleSelect={(val) => setCategory(val.value)} showSearch />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email (OPTIONAL)</Form.Label>
                            <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="text" maxLength={128} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Website URL 1</Form.Label>
                            <Form.Control placeholder="https://" value={website1} onChange={e => setWebsite1(e.target.value)} type="text" maxLength={256} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Website URL 2</Form.Label>
                            <Form.Control placeholder="https://" value={website2} onChange={e => setWebsite2(e.target.value)} type="text" maxLength={256} />
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" size="sm" onClick={handleSave}>Save</Button>
                        </Form.Group>
                    </div>
                </div>
                <div className="w-350px fs-6 h-100 whatsapp-profile-wrapper1" style={{ background: "#f0f2f5" }}>
                    <div className="border rounded-0 mb-3 bg-white p-14px position-relative mt-8 mx-8 overflow-hidden">
                        <div className="fw-bold  text-black">Business profile preview</div>
                        <div class="h-200px" style={{ color: "#dadde1" }}>
                            <div className="w-100 h-100 d-flex flex-center">
                                <img src={profileUrl} alt="profile" className="h-100" />
                            </div>
                            <div className="h-70px position-absolute bottom-0 left-0 w-350px d-flex" style={{ background: "linear-gradient( to bottom, transparent 0%, rgba(0, 0, 0, .4) 100% )" }}>
                                <div className="fs-4 text-left text-white d-flex align-items-end p-4">{displayName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="h-50px border rounded-0 mb-3 bg-white p-14px mx-8">
                        <div className="text-black">Business account</div>
                    </div>
                    <div className="border rounded-0 mb-3 bg-white p-14px mx-8">
                        <div className="d-flex flex-row mb-4">
                            <div className="w-30px">
                                <i class="bi bi-shop">
                                </i>
                            </div>
                            <div className="w-100-30px">{description}</div>
                        </div>
                        <div className="d-flex flex-row mb-4">
                            <div className="w-30px">
                                <i class="bi bi-tag">
                                </i>
                            </div>
                            <div className="w-100-30px">{categoryLabel}</div>
                        </div>
                        <div className="d-flex flex-row mb-4">
                            <div className="w-30px">
                                <i class="bi bi-geo-alt">
                                </i>
                            </div>
                            <div className="w-100-30px color-128c7e">{address}</div>
                        </div>
                        <div className="d-flex flex-row mb-4">
                            <div className="w-30px">
                                <i class="bi bi-envelope">
                                </i>
                            </div>
                            <div className="w-100-30px color-128c7e">{email}</div>
                        </div>
                        <div className="d-flex flex-row mb-4">
                            <div className="w-30px">
                                <i class="bi bi-globe-americas">
                                </i>
                            </div>
                            <div className="w-100-30px color-128c7e">{website1}</div>
                        </div>
                        <div className="d-flex flex-row mb-4">
                            <div className="w-30px">
                                <i class="bi bi-globe-americas">
                                </i>
                            </div>
                            <div className="w-100-30px color-128c7e" >{website2}</div>
                        </div>
                    </div>
                    {/* <div className="whatsapp-gadget-bottom"></div> */}
                </div>
            </div>
        </div>
    )
}