import _ from "lodash";
import React from "react";
import { cadenceStatPercentage } from "../../../containers/cadence-analytics/utils/functions";

const createStatsArray = (arr) => {
  return arr.map((v) => ({
    stat: v[0],
    title: v[1],
  }));
};

export default function StepwiseAnalyticsCard({ step, prefix }) {
  return (
    <div className="d-flex gap-2 w-100 flex-center my-2">
      {prefix && <div className="w-30px h-30px d-flex flex-center bg-light-primary rounded-pill">{prefix}</div>}
      <div className={`card px-4 py-2 w-100 bg-light-light`}>
        <div className=" d-flex w-100 justify-content-between align-items-center">
          <div className="d-flex gap-3 w-30">{_.capitalize(step.name)}</div>
          <AnalyticsDataUI {...{ step }} />
        </div>
      </div>
    </div>
  );
}

const AnalyticsDataUI = ({ step }) => {
  const { channelType, subChannelType } = step;
  const statsArray = (() => {
    if ((channelType === "TASK" && subChannelType === "MANUAL") || (channelType === "EMAIL" && subChannelType === "MANUAL")) {
      const { total, activityCreated, activityCompleted, errored } = step.stats;
      return createStatsArray([
        [total, "Total"],
        [activityCreated, "Created"],
        [activityCompleted, "Completed"],
        [errored, "Errors"],
      ]);
    }
    if (channelType === "EMAIL" && subChannelType === "EMAIL_SYNC") {
      if (!_.isEmpty(step.abTestStats)) return [];

      const { total, syncEmailSent, syncEmailOpened, syncEmailReplied, syncEmailClicked, errored, syncEmailUnsubscribed } = step.stats;
      return createStatsArray([
        [total, "Total"],
        [syncEmailSent, "Sent"],
        [`${cadenceStatPercentage(syncEmailOpened, syncEmailSent)}%`, "Opened"],
        [`${cadenceStatPercentage(syncEmailReplied, syncEmailSent)}%`, "Replied"],
        [`${cadenceStatPercentage(syncEmailClicked, syncEmailSent)}%`, "Clicked"],
        [syncEmailUnsubscribed, "Unsubscribed"],
        [errored, "Errors"],
      ]);
    }
    if (channelType === "EMAIL" && subChannelType === "CAMPAIGN_API") {
      const { total, campaignEmailSent, campaignEmailOpened, campaignEmailReplied, campaignEmailClicked, errored, campaignEmailUnsubscribed } = step.stats;
      return createStatsArray([
        [total, "Total"],
        [campaignEmailSent, "Sent"],
        [`${cadenceStatPercentage(campaignEmailOpened, campaignEmailSent)}%`, "Opened"],
        [`${cadenceStatPercentage(campaignEmailReplied, campaignEmailSent)}%`, "Replied"],
        [`${cadenceStatPercentage(campaignEmailClicked, campaignEmailSent)}%`, "Clicked"],
        [campaignEmailUnsubscribed, "Unsubscribed"],
        [errored, "Errors"],
      ]);
    }
    if ((channelType === "SMS" && subChannelType === "MANUAL") || (channelType === "SMS" && subChannelType === "CAMPAIGN_API")) {
      const { total, smsSent, smsDelivered, smsUnDelivered, errored } = step.stats;
      return createStatsArray([
        [total, "Total"],
        [smsSent, "Sent"],
        [`${cadenceStatPercentage(smsDelivered, smsSent)}%`, "Delivered"],
        [`${cadenceStatPercentage(smsUnDelivered, smsSent)}%`, "Undelivered"],
        [errored, "Errors"],
      ]);
    }
    if ((channelType === "WHATSAPP" && subChannelType === "CAMPAIGN_API")) {
      const { total, whatsappSent, whatsappDelivered, whatsappRead, errored } = step.stats;
      return createStatsArray([
        [total, "Total"],
        [whatsappSent, "Sent"],
        [`${cadenceStatPercentage(whatsappDelivered, whatsappSent)}%`, "Delivered"],
        [`${cadenceStatPercentage(whatsappRead, whatsappSent)}%`, "Read"],
        [errored, "Errors"],
      ]);
    }
    return [];
  })();

  return (
    <div className="d-flex gap-3">
      <div className="d-flex gap-2 mr-10">
        {statsArray.map((v, i) => (
          <div className={`d-flex flex-column flex-center px-3 ${statsArray.length === i + 1 ? "border-0" : "border border-left-0 border-y-0 "}`} key={i}>
            <span>{v.stat}</span>
            <span className="text-black-50">{v.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
