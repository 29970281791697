export const ADD_NOTES_REQUEST = "ADD_NOTES_REQUEST";
export const ADD_NOTES_SUCCESS = "ADD_NOTES_SUCCESS";
export const ADD_NOTES_ERROR = "ADD_NOTES_ERROR";

export const EDIT_NOTES_REQUEST = "EDIT_NOTES_REQUEST";
export const EDIT_NOTES_SUCCESS = "EDIT_NOTES_SUCCESS";
export const EDIT_NOTES_ERROR = "EDIT_NOTES_ERROR";

export const DELETE_NOTES_REQUEST = "DELETE_NOTES_REQUEST";
export const DELETE_NOTES_SUCCESS = "DELETE_NOTES_SUCCESS";
export const DELETE_NOTES_ERROR = "DELETE_NOTES_ERROR";

export const GET_NOTES_REQUEST = "GET_NOTES_REQUEST";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_ERROR = "GET_NOTES_ERROR";

export const GET_NOTES_BY_ID_REQUEST = "GET_NOTES_BY_ID_REQUEST";
export const GET_NOTES_BY_ID_SUCCESS = "GET_NOTES_BY_ID_SUCCESS";
export const GET_NOTES_BY_ID_ERROR = "GET_NOTES_BY_ID_ERROR";

export const GET_LINKED_EMAILS_REQUEST = "GET_LINKED_EMAILS_REQUEST";
export const GET_LINKED_EMAILS_SUCCESS = "GET_LINKED_EMAILS_SUCCESS";
export const GET_LINKED_EMAILS_ERROR = "GET_LINKED_EMAILS_ERROR";

export const GET_LINKED_ACTIVITIES_REQUEST = "GET_LINKED_ACTIVITIES_REQUEST";
export const GET_LINKED_ACTIVITIES_SUCCESS = "GET_LINKED_ACTIVITIES_SUCCESS";
export const GET_LINKED_ACTIVITIES_ERROR = "GET_LINKED_ACTIVITIES_ERROR";

export const getUpcomingActivities = {
  GET_UPCOMING_ACTIVITIES_REQUEST: "GET_UPCOMING_ACTIVITIES_REQUEST",
  GET_UPCOMING_ACTIVITIES_SUCCESS: "GET_UPCOMING_ACTIVITIES_SUCCESS",
  GET_UPCOMING_ACTIVITIES_ERROR: "GET_UPCOMING_ACTIVITIES_ERROR",
};
