export const eventTimeMinutesOptions = [
  { value: 15, label: "15", duration: 15 },
  { value: 20, label: "20", duration: 20 },
  { value: 30, label: "30", duration: 30 },
  { value: 45, label: "45", duration: 45 },
  { value: 60, label: "60", duration: 60 },
  { value: 75, label: "75", duration: 75 },
  { value: 90, label: "90", duration: 90 },
];
export const eventTimeHoursOptions = [
  { value: 1, label: "1", duration: 60 },
  { value: 2, label: "2", duration: 120 },
  { value: 3, label: "3", duration: 180 },
  { value: 4, label: "4", duration: 240 },
  { value: 5, label: "5", duration: 300 },
  { value: 6, label: "6", duration: 360 },
  { value: 7, label: "7", duration: 420 },
  { value: 8, label: "8", duration: 480 },
  { value: 9, label: "9", duration: 540 },
  { value: 10, label: "10", duration: 600 },
  { value: 11, label: "11", duration: 660 },
  { value: 12, label: "12", duration: 720 },
  { value: 13, label: "13", duration: 780 },
  { value: 14, label: "14", duration: 840 },
  { value: 15, label: "15", duration: 900 },
  { value: 16, label: "16", duration: 960 },
  { value: 17, label: "17", duration: 1020 },
  { value: 18, label: "18", duration: 1080 },
  { value: 19, label: "19", duration: 1140 },
  { value: 20, label: "20", duration: 1200 },
  { value: 21, label: "21", duration: 1260 },
  { value: 22, label: "22", duration: 1320 },
  { value: 23, label: "23", duration: 1380 },
];

export const futuureEventDaysOptions = [
  { value: 1, label: "1", duration: 1440 },
  { value: 2, label: "2", duration: 2880 },
  { value: 3, label: "3", duration: 4320 },
  { value: 4, label: "4", duration: 5760 },
  { value: 5, label: "5", duration: 7200 },
  { value: 6, label: "6", duration: 8640 },
  { value: 7, label: "7", duration: 10080 },
  { value: 8, label: "8", duration: 11520 },
  { value: 9, label: "9", duration: 12960 },
  { value: 10, label: "10", duration: 14400 },
  { value: 11, label: "11", duration: 15840 },
  { value: 12, label: "12", duration: 17280 },
  { value: 13, label: "13", duration: 18720 },
  { value: 14, label: "14", duration: 20160 },
  { value: 15, label: "15", duration: 21600 },
  { value: 16, label: "16", duration: 23040 },
  { value: 17, label: "17", duration: 24480 },
  { value: 18, label: "18", duration: 25920 },
  { value: 19, label: "19", duration: 27360 },
  { value: 20, label: "20", duration: 28800 },
  { value: 21, label: "21", duration: 30240 },
  { value: 22, label: "22", duration: 31680 },
  { value: 23, label: "23", duration: 33120 },
  { value: 24, label: "24", duration: 34560 },
  { value: 25, label: "25", duration: 36000 },
  { value: 26, label: "26", duration: 37440 },
  { value: 27, label: "27", duration: 38880 },
  { value: 28, label: "28", duration: 40320 },
  { value: 29, label: "29", duration: 41760 },
  { value: 30, label: "30", duration: 43200 },
];

export const futuureEventWeekOptions = [
  { value: 1, label: "1", duration: 10080 },
  { value: 2, label: "2", duration: 20160 },
  { value: 3, label: "3", duration: 30240 },
  { value: 4, label: "4", duration: 40320 },
];
export const futureEventTimeMonthsOptions = [
  { value: 1, label: "1", duration: 43800 },
  { value: 2, label: "2", duration: 87600 },
];

export const eventTimeTypeOptions = [
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hours" },
];

export const minBookingNoticeTypeOptions = [
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
];

export const minCancelNoticeTypeOptions = [
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
];

export const futureEventTimeTypeOptions = [
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
];

export const eventBufferTimeMinutesOptions = [
  { value: 0, label: "0", duration: 0 },
  { value: 15, label: "15", duration: 15 },
  { value: 20, label: "20", duration: 20 },
  { value: 30, label: "30", duration: 30 },
  { value: 45, label: "45", duration: 45 },
  { value: 60, label: "60", duration: 60 },
  { value: 75, label: "75", duration: 75 },
  { value: 90, label: "90", duration: 90 },
];
export const eventBufferTimeHoursOptions = [
  { value: 0, label: "0", duration: 0 },
  { value: 1, label: "1", duration: 60 },
  { value: 2, label: "2", duration: 120 },
  { value: 3, label: "3", duration: 180 },
  { value: 4, label: "4", duration: 240 },
  { value: 5, label: "5", duration: 300 },
  { value: 6, label: "6", duration: 360 },
  { value: 7, label: "7", duration: 420 },
  { value: 8, label: "8", duration: 480 },
  { value: 9, label: "9", duration: 540 },
  { value: 10, label: "10", duration: 600 },
  { value: 11, label: "11", duration: 660 },
  { value: 12, label: "12", duration: 720 },
  { value: 13, label: "13", duration: 780 },
  { value: 14, label: "14", duration: 840 },
  { value: 15, label: "15", duration: 900 },
  { value: 16, label: "16", duration: 960 },
  { value: 17, label: "17", duration: 1020 },
  { value: 18, label: "18", duration: 1080 },
  { value: 19, label: "19", duration: 1140 },
  { value: 20, label: "20", duration: 1200 },
  { value: 21, label: "21", duration: 1260 },
  { value: 22, label: "22", duration: 1320 },
  { value: 23, label: "23", duration: 1380 },
];

export const eventBufferTimeDaysOptions = [
  { value: 0, label: "0", duration: 0 },
  { value: 1, label: "1", duration: 1440 },
  { value: 2, label: "2", duration: 2880 },
  { value: 3, label: "3", duration: 4320 },
  { value: 4, label: "4", duration: 5760 },
  { value: 5, label: "5", duration: 7200 },
  { value: 6, label: "6", duration: 8640 },
  { value: 7, label: "7", duration: 10080 },
  { value: 8, label: "8", duration: 11520 },
  { value: 9, label: "9", duration: 12960 },
  { value: 10, label: "10", duration: 14400 },
  { value: 11, label: "11", duration: 15840 },
  { value: 12, label: "12", duration: 17280 },
  { value: 13, label: "13", duration: 18720 },
  { value: 14, label: "14", duration: 20160 },
  { value: 15, label: "15", duration: 21600 },
  { value: 16, label: "16", duration: 23040 },
  { value: 17, label: "17", duration: 24480 },
  { value: 18, label: "18", duration: 25920 },
  { value: 19, label: "19", duration: 27360 },
  { value: 20, label: "20", duration: 28800 },
  { value: 21, label: "21", duration: 30240 },
  { value: 22, label: "22", duration: 31680 },
  { value: 23, label: "23", duration: 33120 },
  { value: 24, label: "24", duration: 34560 },
  { value: 25, label: "25", duration: 36000 },
  { value: 26, label: "26", duration: 37440 },
  { value: 27, label: "27", duration: 38880 },
  { value: 28, label: "28", duration: 40320 },
  { value: 29, label: "29", duration: 41760 },
  { value: 30, label: "30", duration: 43200 },
];

export const emailRecipientOptions = [
  { value: "customer", label: "Event guests" },
  { value: "owner", label: "Event host" },
  { value: "both", label: "Send to both" },
];

export const infoTextMessages = {
  // event info
  DURATION: "The time length of each calendar event.",
  MIN_BOOKING_NOTICE: "How far in advance events must be scheduled",
  MIN_CANCELLATION_NOTICE: "How far in advance events must be cancelled via the booking page.",
  FUTURE_LIMIT: "How are in advance events are allowed to be scheduled",
  BUFFER_TIME: "A Buffer Time between your calendar events.",
  CANCELLATION_POLICY: "Ask guest(s) to provide a brief reason for cancellation.",

  // opening hours
  TIMEZONE: "Time zone for availability and new events. Your invitees will see your availability in their local time zone.",

  // page styles
  COMPANY_NAME: "Your company name appears in the page title and is shown when the page is embedded on Facebook, Twitter, etc.",
  COMPANY_LOGO: "Your company logo appears in the header of booking page. for best results, choose an image larger than 300x150px.",
  PAGE_SLUG: "This changes the unique URL of your booking page, eg. https://meeting.salessimplify.com/slug",
  SUBMIT_BUTTON_TEXT: "The text of the large 'booking' button. You may want it to say 'Reserve Appointment'. For example, if you provide your visitors a service.",
  THEME_COLOR: "The accent color used on your page. You may want to match the color in your logo",
  THANK_YOU_MESSAGE: "The text that appears after visitors create bookings.",
};

export const data = {
  slug: "testing-salessimplify-180min",
  config: {
    calendar_ids: {},
    timezone: "Asia/Calcutta",
    event: {
      duration: 180,
      location: "Location TBD",
      title: "30-min Coffee Meeting - 1",
      capacity: 1,
      participants: [{ email: "testing.salessimplify@gmail.com", role: "organizer" }],
    },
    appearance: {
      color: "#0068D3",
      company_name: "",
      submit_text: "Submit",
      show_nylas_branding: false,
      logo: "",
    },
    reminders: [],
    booking: {
      additional_fields: [],
      available_days_in_future: 14,
      confirmation_method: "automatic",
      min_booking_notice: 120,
      min_buffer: 30,
      min_cancellation_notice: 180,
      opening_hours: [
        {
          days: ["M", "T", "W", "R", "F"],
          start: "09:00",
          end: "17:00",
        },
      ],
      scheduling_method: "round-robin-maximize-availability",
    },
    locale: "en",
    features: {
      collective_meetings: false,
      group_meetings: false,
    },
  },
  access_tokens: ["xF3A1S0nZaFXHLlnfCPSR5KFw6V4Wx"],
};

export const selectedDummyData = {
  id: 4495867,
  app_client_id: "8vbjclxjw0lh0i13lbmhsjln1",
  app_organization_id: 89609,
  config: {
    appearance: {
      color: "#0068D3",
      company_name: "Salessimplify",
      logo: "https://nylas-prod-us-west-2-scheduler.s3.amazonaws.com/nylas-scheduling/4530057/d0621042-7cdf-4c09-9586-898f984bb5ac.png",
      show_autoschedule: true,
      show_nylas_branding: false,
      show_timezone_options: true,
      show_week_view: true,
      submit_text: "Submit",
      thank_you_text: "okok",
    },
    booking: {
      additional_fields: [
        {
          label: "test",
          name: "user_defined_test",
          order: 1,
          required: false,
          type: "text",
        },
      ],
      additional_guests_hidden: false,
      available_days_in_future: 14,
      calendar_invite_to_guests: true,
      confirmation_emails_to_guests: true,
      confirmation_emails_to_host: true,
      confirmation_method: "automatic",
      interval_minutes: null,
      min_booking_notice: 120,
      min_buffer: 30,
      min_cancellation_notice: 180,
      name_field_hidden: false,
      opening_hours: [{ days: ["M", "T", "W", "R", "F"], end: "17:00", start: "09:00" }],
      scheduling_method: "round-robin-maximize-availability",
    },
    calendar_ids: {
      "1fhukhjq14trqac4xxwo0gdl8": {
        availability: ["84w0w9xuu663pqpz1uwy37o5s"],
        booking: "84w0w9xuu663pqpz1uwy37o5s",
      },
    },
    disable_emails: false,
    event: {
      capacity: 1,
      duration: 30,
      location: "Location TBD",
      participants: [
        {
          confirmation_method: "calendar",
          email: "testing.salessimplify@gmail.com",
          role: "organizer",
        },
      ],
      title: "30-min Coffee Meeting",
    },
    features: {
      collective_meetings: false,
      group_meetings: false,
    },
    locale: "en",
    page_hostname: "schedule.nylas.com",
    reminders: [],
    timezone: "Asia/Calcutta",
  },
  edit_token: "pLf9SqCvqqVUCi37bjCJjKm8s_WVj4yN",
  name: "30-min Coffee Meeting",
  slug: "testing-salessimplify-30min",
  created_at: "2022-11-22T00:00:00.000Z",
  modified_at: "2022-11-22T00:00:00.000Z",
};

export const customFieldTypeMapping = {
  ORG_SELECT: "text",
  PERSON_SELECT: "text",
  INPUT: "text",
  TEXTAREA: "text",
  MULTI_PHONE_INPUT: "phone",
  MULTI_EMAIL_INPUT: "email",
  TOGGLE_BUTTON: "checkbox",
  COUNTRY_SELECT: "dropdown",
  NUMBER: "number",
  TIMEZONE_SELECT: "dropdown",
  SELECT: "dropdown",
  CHECKBOX: "dropdown",
  RADIO: "dropdown",
  DATE: "date",
  DATE_RANGE: "date",
  DATE_TIME: "date",
  DATE_TIME_RANGE: "date",
};

export const countryOptions = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "AndorrA",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic Of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People'S Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People'S Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
export const timeZoneOptions = [
  "Pacific/Niue",
  "Pacific/Midway",
  "Pacific/Pago_Pago",
  "Pacific/Rarotonga",
  "America/Adak",
  "Pacific/Honolulu",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "America/Anchorage",
  "Pacific/Gambier",
  "America/Los_Angeles",
  "America/Tijuana",
  "America/Vancouver",
  "Pacific/Pitcairn",
  "America/Hermosillo",
  "America/Edmonton",
  "America/Ojinaga",
  "America/Denver",
  "America/Phoenix",
  "America/Whitehorse",
  "America/Belize",
  "America/Chicago",
  "America/Guatemala",
  "America/Managua",
  "America/Mexico_City",
  "America/Costa_Rica",
  "America/El_Salvador",
  "America/Regina",
  "America/Tegucigalpa",
  "America/Winnipeg",
  "Pacific/Easter",
  "Pacific/Galapagos",
  "America/Rio_Branco",
  "America/Bogota",
  "America/Havana",
  "America/Atikokan",
  "America/Cancun",
  "America/Grand_Turk",
  "America/Cayman",
  "America/Jamaica",
  "America/Nassau",
  "America/New_York",
  "America/Panama",
  "America/Port-au-Prince",
  "America/Toronto",
  "America/Guayaquil",
  "America/Lima",
  "America/Manaus",
  "America/St_Kitts",
  "America/Montserrat",
  "America/Barbados",
  "America/St_Lucia",
  "America/Port_of_Spain",
  "America/Martinique",
  "America/St_Barthelemy",
  "America/Halifax",
  "Atlantic/Bermuda",
  "America/St_Vincent",
  "America/Kralendijk",
  "America/Guadeloupe",
  "America/Blanc-Sablon",
  "America/Marigot",
  "America/Aruba",
  "America/Lower_Princes",
  "America/Tortola",
  "America/Dominica",
  "America/St_Thomas",
  "America/Grenada",
  "America/Antigua",
  "America/Puerto_Rico",
  "America/Santo_Domingo",
  "America/Anguilla",
  "America/Thule",
  "America/Curacao",
  "America/La_Paz",
  "America/Santiago",
  "America/Guyana",
  "America/Asuncion",
  "America/Caracas",
  "America/St_Johns",
  "America/Argentina/Buenos_Aires",
  "America/Sao_Paulo",
  "Antarctica/Palmer",
  "America/Punta_Arenas",
  "Atlantic/Stanley",
  "America/Cayenne",
  "America/Miquelon",
  "America/Paramaribo",
  "America/Montevideo",
  "America/Godthab",
  "America/Noronha",
  "Atlantic/South_Georgia",
  "Atlantic/Azores",
  "Atlantic/Cape_Verde",
  "America/Scoresbysund",
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Bamako",
  "Africa/Bissau",
  "Africa/Conakry",
  "Africa/Dakar",
  "America/Danmarkshavn",
  "Europe/Isle_of_Man",
  "Europe/Dublin",
  "Africa/Freetown",
  "Atlantic/St_Helena",
  "Africa/Lome",
  "Europe/London",
  "Africa/Monrovia",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Atlantic/Reykjavik",
  "Europe/Jersey",
  "Europe/Guernsey",
  "Africa/Banjul",
  "Africa/Sao_Tome",
  "Antarctica/Troll",
  "Africa/Casablanca",
  "Africa/El_Aaiun",
  "Atlantic/Canary",
  "Europe/Lisbon",
  "Atlantic/Faroe",
  "Africa/Windhoek",
  "Africa/Algiers",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Malta",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Budapest",
  "Europe/Copenhagen",
  "Europe/Gibraltar",
  "Europe/Ljubljana",
  "Arctic/Longyearbyen",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Monaco",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Rome",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Skopje",
  "Europe/Stockholm",
  "Europe/Tirane",
  "Africa/Tunis",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zurich",
  "Africa/Bangui",
  "Africa/Malabo",
  "Africa/Brazzaville",
  "Africa/Porto-Novo",
  "Africa/Douala",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Luanda",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Bujumbura",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Blantyre",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Maputo",
  "Africa/Juba",
  "Asia/Damascus",
  "Asia/Amman",
  "Europe/Athens",
  "Asia/Beirut",
  "Europe/Bucharest",
  "Africa/Cairo",
  "Europe/Chisinau",
  "Asia/Hebron",
  "Europe/Helsinki",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Mariehamn",
  "Asia/Nicosia",
  "Europe/Riga",
  "Europe/Sofia",
  "Europe/Tallinn",
  "Africa/Tripoli",
  "Europe/Vilnius",
  "Asia/Jerusalem",
  "Africa/Johannesburg",
  "Africa/Mbabane",
  "Africa/Maseru",
  "Asia/Kuwait",
  "Asia/Baghdad",
  "Asia/Qatar",
  "Asia/Bahrain",
  "Asia/Riyadh",
  "Asia/Aden",
  "Africa/Addis_Ababa",
  "Indian/Antananarivo",
  "Africa/Asmara",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Kampala",
  "Indian/Mayotte",
  "Africa/Mogadishu",
  "Indian/Comoro",
  "Africa/Nairobi",
  "Europe/Minsk",
  "Europe/Moscow",
  "Antarctica/Syowa",
  "Europe/Istanbul",
  "Asia/Tehran",
  "Asia/Yerevan",
  "Asia/Baku",
  "Asia/Tbilisi",
  "Asia/Dubai",
  "Asia/Muscat",
  "Indian/Mauritius",
  "Indian/Reunion",
  "Europe/Samara",
  "Indian/Mahe",
  "Asia/Kabul",
  "Indian/Kerguelen",
  "Indian/Maldives",
  "Antarctica/Mawson",
  "Asia/Karachi",
  "Asia/Dushanbe",
  "Asia/Ashgabat",
  "Asia/Tashkent",
  "Asia/Qyzylorda",
  "Asia/Yekaterinburg",
  "Asia/Colombo",
  "Asia/Kolkata",
  "Asia/Kathmandu",
  "Asia/Dhaka",
  "Asia/Thimphu",
  "Asia/Urumqi",
  "Asia/Almaty",
  "Indian/Chagos",
  "Asia/Bishkek",
  "Asia/Omsk",
  "Antarctica/Vostok",
  "Indian/Cocos",
  "Asia/Yangon",
  "Indian/Christmas",
  "Antarctica/Davis",
  "Asia/Hovd",
  "Asia/Bangkok",
  "Asia/Ho_Chi_Minh",
  "Asia/Phnom_Penh",
  "Asia/Vientiane",
  "Asia/Novosibirsk",
  "Asia/Jakarta",
  "Australia/Perth",
  "Asia/Brunei",
  "Asia/Makassar",
  "Asia/Macau",
  "Asia/Shanghai",
  "Asia/Hong_Kong",
  "Asia/Irkutsk",
  "Asia/Kuala_Lumpur",
  "Asia/Manila",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Ulaanbaatar",
  "Australia/Eucla",
  "Asia/Dili",
  "Asia/Jayapura",
  "Asia/Tokyo",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Pacific/Palau",
  "Asia/Chita",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Brisbane",
  "Australia/Sydney",
  "Pacific/Guam",
  "Pacific/Saipan",
  "Pacific/Chuuk",
  "Antarctica/DumontDUrville",
  "Pacific/Port_Moresby",
  "Asia/Vladivostok",
  "Australia/Lord_Howe",
  "Pacific/Bougainville",
  "Antarctica/Casey",
  "Pacific/Kosrae",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Asia/Sakhalin",
  "Pacific/Guadalcanal",
  "Pacific/Efate",
  "Pacific/Fiji",
  "Pacific/Tarawa",
  "Pacific/Majuro",
  "Pacific/Nauru",
  "Pacific/Auckland",
  "Antarctica/McMurdo",
  "Asia/Kamchatka",
  "Pacific/Funafuti",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Chatham",
  "Pacific/Apia",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Tongatapu",
  "Pacific/Kiritimati",
];
