import { takeLatest, put, call } from "redux-saga/effects";
import * as activityTypes from "../types/activity.types";
import api from "../../../../network/apis/api";
import * as ActivityListActions from "../actions/activitity_list_actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";

import { getDealPopupDetailsRequestAfter, getDealRequestAfter } from "../../../deals/dealPipeline/actions";
import { peoplePopupRequest, peoplesUpdateRequest } from "../../../customers/store/actions/actions";
import { leadPopupRequest, leadsRequest, leadsUpdateRequest } from "../../../leads/store/actions/actions";
import * as CompanyActions from "../../../companies/store/actions/actions";
import * as ActivityStaticticsActions from "../actions/staticstics_actions";

function* getListOfActivities(action) {
  try {
    const response = yield call(api.get, `/api/crm/activity-list/v3/get`, action.payload);
    if (response.status === 200) {
      yield put(ActivityListActions.getListOfActivtiesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityListActions.getListOfActivtiesError(response));
    } else {
      yield put(ActivityListActions.getListOfActivtiesError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityListActions.getListOfActivtiesError(e));
  }
}

function* editActivity(action) {
  try {
    const response = yield call(api.patch, `/api/crm/activity-list/edit`, action.payload.editData);
    if (response.status === 200) {
      dispatchSnackbarSuccess("The activity successfully edited." || response.data.message);

      yield put(ActivityListActions.editActivitySuccess(response.data));

      if (action.payload.from === "activity-list") {
        yield put(ActivityListActions.getListOfActivtiesRequestAfter(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }

      if (action.payload.from === "deal") {
        if (action.payload.dealId) yield put(getDealPopupDetailsRequestAfter(action.payload.dealId));
        if (action.payload.pipelineId) yield put(getDealRequestAfter({ pipelineId: action.payload.pipelineId }));
      } else if (action.payload.from === "lead") {
        yield put(leadPopupRequest({ id: action.payload.leadId }));
        yield put(leadsRequest({ pageNo: 1, limit: 15, type: "active_lead" }));
      } else if (action.payload.from === "people") {
        yield put(peoplePopupRequest({ id: action.payload.peopleId }));
      } else if (action.payload.from === "company") {
        yield put(CompanyActions.companyPopupRequest({ id: action.payload.companyId }));
      }
      if (action.payload.pageForm === "activeLead") yield put(leadsUpdateRequest({ loadingData: action.payload.filter }));
      else if (action.payload.pageForm === "people") yield put(peoplesUpdateRequest({ loadingData: action.payload.filter }));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityListActions.editActivityError(response));
    } else {
      yield put(ActivityListActions.editActivityError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityListActions.editActivityError(e));
  }
}

function* deleteActivity(action) {
  try {
    const res = yield call(api.delete, `/api/crm/activity-list/delete/${action.payload.id}`);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Activity Successfully deleted!");
      if (action.payload.loadingData) {
        yield put(ActivityListActions.getListOfActivtiesRequest(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }
      yield put(ActivityListActions.deleteActivitySuccess(res.data));
      if (action.payload.pageForm === "activeLead") yield put(leadsUpdateRequest({ loadingData: action.payload.filter }));
      else if (action.payload.pageForm === "people") yield put(peoplesUpdateRequest({ loadingData: action.payload.filter }));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
      yield put(ActivityListActions.deleteActivityError({ message: res.data.message }));
    } else {
      yield put(ActivityListActions.deleteActivityError({ message: "Error during deleting activity" }));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ActivityListActions.deleteActivityError(e));
  }
}

function* bulkDeleteActivities(action) {
  try {
    const response = yield call(api.patch, "/api/crm/activity-list/bulk-delete", action.payload.activityIds);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Activities deleted successfully", "success");
      yield put(ActivityListActions.getListOfActivtiesRequest(action.payload.loadingData));
      yield put(ActivityListActions.bulkDeleteActivitySuccess(response.data));
      yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityListActions.bulkDeleteActivityError(response));
    } else {
      yield put(ActivityListActions.bulkDeleteActivityError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityListActions.bulkDeleteActivityError(e));
  }
}

function* createActivity(action) {
  try {
    const response = yield call(api.post, "/api/crm/activity-list/create", action.payload.activityData);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);

      yield put(ActivityListActions.createActivitySuccess(response.data));
      if (action.payload.from === "activity-list") {
        yield put(ActivityListActions.getListOfActivtiesRequestAfter(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }
      if (action.payload.from === "deal-pipeline") {
        if (action.payload.dealId) yield put(getDealPopupDetailsRequestAfter(action.payload.dealId));
        if (action.payload.pipelineId) yield put(getDealRequestAfter({ pipelineId: action.payload.pipelineId }));
      } else if (action.payload.from === "deal") {
        if (action.payload.dealId) yield put(getDealPopupDetailsRequestAfter(action.payload.dealId));
        if (action.payload.pipelineId) yield put(getDealRequestAfter({ pipelineId: action.payload.pipelineId }));
      } else if (action.payload.from === "lead") {
        yield put(leadPopupRequest({ id: action.payload.leadId }));
        yield put(leadsRequest({ pageNo: 1, limit: 15, type: "active_lead" }));
      } else if (action.payload.from === "people") {
        yield put(peoplePopupRequest({ id: action.payload.peopleId }));
      } else if (action.payload.from === "company") {
        yield put(CompanyActions.companyPopupRequest({ id: action.payload.companyId }));
      }
      if (action.payload.pageForm === "activeLead") yield put(leadsUpdateRequest({ loadingData: action.payload.filter }));
      else if (action.payload.pageForm === "people") yield put(peoplesUpdateRequest({ loadingData: action.payload.filter }));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityListActions.createActivityError(response));
    } else {
      yield put(ActivityListActions.createActivityError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityListActions.createActivityError(e));
  }
}

function* createShortActivity(action) {
  try {
    const res = yield call(api.post, "/api/crm/activity-list/create-short-activity", action.payload.data);
    if (res.status === 200) {
      yield put(ActivityListActions.createShortActivitySuccess(res.data));

      if (action.payload.from === "activity-list") {
        yield put(ActivityListActions.getListOfActivtiesRequestAfter(action.payload.loadingData));
        yield put(ActivityStaticticsActions.getUserSalesTargetStaticsticsRequest());
      }
      if (action.payload.from === "deal") {
        if (action.payload.dealId) yield put(getDealPopupDetailsRequestAfter(action.payload.data.deal));
        if (action.payload.pipelineId) yield put(getDealRequestAfter({ pipelineId: action.payload.data.pipeline }));
      } else if (action.payload.from === "lead") {
        yield put(leadPopupRequest({ id: action.payload.data.lead }));
        yield put(leadsRequest({ pageNo: 1, limit: 15, type: "active_lead" }));
      } else if (action.payload.from === "people") {
        yield put(peoplePopupRequest({ id: action.payload.data.people }));
      } else if (action.payload.from === "company") {
        yield put(CompanyActions.companyPopupRequest({ id: action.payload.data.company }));
      }
      if (action.payload.pageForm === "activeLead") yield put(leadsUpdateRequest({ loadingData: action.payload.filter }));
      else if (action.payload.pageForm === "people") yield put(peoplesUpdateRequest({ loadingData: action.payload.filter }));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(ActivityListActions.createShortActivityError(e));
  }
}

export default function* ActivitySaga() {
  //Activities
  yield takeLatest(activityTypes.getListOfActivties.GET_LIST_OF_ACTIVITIES_REQUEST, getListOfActivities);
  yield takeLatest(activityTypes.getListOfActivties.GET_LIST_OF_ACTIVITIES_REQUEST_AFTER, getListOfActivities);
  yield takeLatest(activityTypes.editActivity.EDIT_ACTIVITY_REQUEST, editActivity);
  yield takeLatest(activityTypes.deleteActivities.DELETE_ACTIVITIES_REQUEST, deleteActivity);
  yield takeLatest(activityTypes.bulkDeleteActivities.BULK_DELETE_ACTIVITES_REQUEST, bulkDeleteActivities);
  yield takeLatest(activityTypes.createActivity.CREATE_ACTIVITY_REQUEST, createActivity);
  yield takeLatest(activityTypes.createShortActivity.CREATE_SHORT_ACTIVITY_REQUEST, createShortActivity);
}
