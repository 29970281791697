//packages
import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { Form, OverlayTrigger, Tooltip, Button, Col } from "react-bootstrap";

//components
import TimeZoneSelect from "../../../../components/custom_form/timezone";
import CustomEmail from "../../../../components/custom_form/email";
import SimpleDropdown from "../../../../components/dropdowns/simple-dropdown";
import Association from "../../../../components/custom_form/association";
import { CustomScrollableModal } from "../../../../components/modal";
import { ButtonGroup } from "../../../deals/dealGroup/styled";
import OwnerSelect from "../../../../components/custom_form/owners";

//actions
import { getListOfActiveActivityTypesRequest } from "../../store/actions/activity_types_actions";

//constants and utils
import { timeOptions } from "../../constants";
import { InputLimit } from "../../../../constants";
import { dispatchSnackbarError } from "../../../../utils/toaster";

export default function AddActivityModel(props) {
  const [activityText, setActivityText] = useState("");
  const [activityType, setActivityType] = useState("");
  const [activeIcon, setActiveIcon] = useState("");
  const [showCustomTime, setShowCustomTime] = useState(false);
  const [descriptionText, setDescriptionText] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  //custom time related
  const [timeButton, setTimeButton] = useState("today");
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
  const [fromTime, setFromTime] = useState({ label: "12:00 AM", value: 0 });
  const [toTime, setToTime] = useState({ label: "12:00 AM", value: 0 });

  //meeting related
  const [isMeeting, setIsMeeting] = useState(false);
  const [attendees, setAttendees] = useState([""]);
  const [location, setLocation] = useState("");
  const [timezone, setTimezone] = useState("");
  const [meetingLink, setMeetingLink] = useState("");

  //association related
  const [associated, setAssociated] = useState({});
  const [leadField, setLeadField] = useState("");
  const [peopleField, setPeopleField] = useState("");
  const [dealField, setDealField] = useState("");
  const [companyFiled, setCompanyFiled] = useState("");

  const dispatch = useDispatch();
  const activityActiveTypesStore = useSelector((store) => store.activity.types.active);

  const profiledata = useSelector((store) => store.ProfileSettings.getProfileDetails);

  useEffect(() => {
    dispatch(getListOfActiveActivityTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(props.selectedAddActivity)) {
      const data = props.selectedAddActivity;
      setActivityText(data.name);
      setActivityType(data.activityType._id);
      setActiveIcon(data.activityType.name);
      setDescriptionText(data.description);

      if (data.activityType.name === "Meeting") {
        setIsMeeting(true);
        setAttendees(data.attendees);
        setLocation(data.location);
        setTimezone(data.timezone);
        setMeetingLink(data.meetingLink);
      }

      if (!_.isEmpty(data.associated)) {
        setAssociated(data.associated);
        if (data.associated.type === "lead") {
          setLeadField(data.associated._id);
        } else if (data.associated.type === "people") {
          setPeopleField(data.associated._id);
        } else if (data.associated.type === "deal") {
          setDealField(data.associated._id);
        } else if (data.associated.type === "company") {
          setCompanyFiled(data.associated._id);
        }
      }
    }
  }, [props.selectedAddActivity]);

  useEffect(() => {
    if (!_.isEmpty(props.relatedAssociate)) {
      setAssociated(props.relatedAssociate);
      if (props.relatedAssociate.type === "lead") {
        setLeadField(props.relatedAssociate._id);
      } else if (props.relatedAssociate.type === "people") {
        setPeopleField(props.relatedAssociate._id);
      } else if (props.relatedAssociate.type === "deal") {
        setDealField(props.relatedAssociate._id);
      } else if (props.relatedAssociate.type === "company") {
        setCompanyFiled(props.relatedAssociate._id);
      }
    }
  }, [props.relatedAssociate]);

  useEffect(() => {
    if (profiledata && profiledata.success) {
      setSelectedUser(profiledata.data.userId);
    }
  }, [profiledata, setSelectedUser]);

  const onIconFieldChange = (field) => {
    setActiveIcon(field.name);
    setActivityType(field._id);
    setActivityText(field.name);

    if (field.name === "Meeting") {
      setIsMeeting(true);
    } else {
      setIsMeeting(false);
    }
  };

  const setEmptyData = () => {
    setActivityText("");
    setActivityType("");
    setActiveIcon("");
    setShowCustomTime(false);
    setDescriptionText("");
    setTimeButton("today");
    setFromDate(moment().format("yyyy-MM-DD"));
    setToDate(moment().format("yyyy-MM-DD"));
    setFromTime({ label: "12:00 AM", value: 0 });
    setToTime({ label: "12:00 AM", value: 0 });
    setIsMeeting(false);
    setAttendees([""]);
    setLocation("");
    setTimezone("");
    setMeetingLink("");
    setAssociated({});
    setLeadField("");
    setPeopleField("");
    setDealField("");
    setCompanyFiled("");
  };

  const onActivitySaveClick = () => {
    const startTime = moment(fromDate)
      .startOf("day")
      .add(fromTime.value, "second")
      .format();
    const endTime = moment(toDate)
      .startOf("day")
      .add(toTime.value, "second")
      .format();

    if (!activityType || !activityText || !selectedUser) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    } else if (!activityType) {
      return dispatchSnackbarError("Please select activity type!");
    } else if (!activityText) {
      return dispatchSnackbarError("Please enter activity text");
    } else if (!selectedUser) {
      return dispatchSnackbarError("Please select user to assign");
    } else if (isMeeting && startTime > endTime) {
      return dispatchSnackbarError("Start Date cannot be grether than end date");
    } else if (meetingLink && !validator.isURL(meetingLink)) {
      return dispatchSnackbarError("Please enter valid meeting url");
    }

    const newAttendies = attendees.filter((a) => a);
    for (let i = 0; i < newAttendies.length; i++) {
      if (!validator.isEmail(newAttendies[i])) {
        return dispatchSnackbarError("Please enter valid email address in Guest");
      }
    }

    const duration = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();

    const data = {
      activityType: activityType,
      name: activityText,
      scheduleAt: startTime,
      scheduleAtEnd: isMeeting ? endTime : startTime,
      duration: isMeeting ? duration : 0,
      attendees: newAttendies,
      description: descriptionText,
      assignToUser: selectedUser,
      deal: dealField,
      lead: leadField,
      company: companyFiled,
      people: peopleField,
      //meeting related
      location: location,
      timezone: timezone,
      meetingLink: meetingLink,
      associated: associated,
    };
    props.onCreateActivity(data);
    props.closeScheduleActivity(false);
    setEmptyData();
  };

  const onTimeSelect = (time) => {
    if (time === "today") {
      setShowCustomTime(false);
      setFromDate(moment().format("yyyy-MM-DD"));
      setToDate(moment().format("yyyy-MM-DD"));
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "tomorrow") {
      setShowCustomTime(false);
      setFromDate(
        moment()
          .add(1, "day")
          .format("yyyy-MM-DD"),
      );
      setToDate(
        moment()
          .add(1, "day")
          .format("yyyy-MM-DD"),
      );
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "3 days later") {
      setShowCustomTime(false);
      setFromDate(
        moment()
          .add(3, "days")
          .format("yyyy-MM-DD"),
      );
      setToDate(
        moment()
          .add(3, "days")
          .format("yyyy-MM-DD"),
      );
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "custom") {
      setShowCustomTime(true);
      setFromDate(moment().format("yyyy-MM-DD"));
      setToDate(moment().format("yyyy-MM-DD"));
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    }
    setTimeButton(time);
  };

  const handleAssociationChange = (data) => {
    setAssociated(data);
    if (data.type === "lead") {
      setDealField("");
      setLeadField(data._id);
      setPeopleField("");
      setCompanyFiled("");
    } else if (data.type === "company") {
      setDealField("");
      setLeadField("");
      setPeopleField("");
      setCompanyFiled(data._id);
    } else if (data.type === "deal") {
      setDealField(data._id);
      setLeadField("");
      setPeopleField("");
      setCompanyFiled("");
    } else if (data.type === "people") {
      setDealField("");
      setLeadField("");
      setPeopleField(data._id);
      setCompanyFiled("");
    }
  };

  return (
    <>
      <CustomScrollableModal
        modalProps={{
          show: props.openAddActivityModal,
          handleClose: () => {
            props.closeScheduleActivity(false);
            setEmptyData();
          },
          title: "Add activity",
          showButtons: true,
          buttonText: "Save",
          showCloseButton: true,
          size: "lg",
          handleAccept: () => onActivitySaveClick(),
        }}
      >
        <Form>
          <div className="d-flex flex-row flex-wrap">
            <Col className="pt-0 pr-18px pb-0 pl-7px" xs={6}>
              <Form.Group id="activity-list-add-activity-type">
                <Form.Label aria-label="activity-type">Activity Type*</Form.Label>
                <div className="d-flex flex-wrap">
                  {activityActiveTypesStore.list &&
                    activityActiveTypesStore.list.map((iconField, index) => (
                      <ButtonGroup key={index}>
                        <OverlayTrigger key={index} placement="bottom" overlay={<Tooltip>{iconField.name}</Tooltip>}>
                          <div onClick={() => onIconFieldChange(iconField)} className={iconField.name === activeIcon ? "active" : ""}>
                            <i className={iconField.symbName}></i>
                          </div>
                        </OverlayTrigger>
                      </ButtonGroup>
                    ))}
                </div>
              </Form.Group>
              <Form.Group id="activity-list-add-activity-name">
                <Form.Label aria-label="activity-name">Activity Name*</Form.Label>
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={activeIcon} value={activityText} onChange={(e) => setActivityText(e.target.value)} />
              </Form.Group>
              <Form.Group id="activity-list-add-activity-associated">
                <Form.Label aria-label="associated">Associated</Form.Label>
                <Association disabled={props.disabled} value={associated} setValue={handleAssociationChange} />
              </Form.Group>
              <Form.Group id="activity-list-add-activity-due-date">
                <Form.Label aria-label="due-date">Due Date*</Form.Label>
                <div className="d-flex flex-row gap-5px">
                  <Button
                    className={timeButton === "today" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                    onClick={() => onTimeSelect("today")}
                  >
                    Today
                  </Button>
                  <Button
                    className={timeButton === "tomorrow" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                    onClick={() => onTimeSelect("tomorrow")}
                  >
                    Tomorrow
                  </Button>
                  <Button
                    className={timeButton === "3 days later" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                    onClick={() => onTimeSelect("3 days later")}
                  >
                    3 days later
                  </Button>
                  <Button
                    className={timeButton === "custom" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                    onClick={() => onTimeSelect("custom")}
                  >
                    Custom
                  </Button>
                </div>
              </Form.Group>

              {showCustomTime && (
                <Form.Group id="activity-list-add-activity-time">
                  {isMeeting && <Form.Label aria-label="from-date&time">{"From Date & Time"}</Form.Label>}
                  <div className="d-flex justify-content-center gap-2">
                    <input className="form-control" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    <SimpleDropdown value={fromTime} label={"Select From Time"} options={timeOptions} handleSelect={(a) => setFromTime(a)} />
                  </div>
                </Form.Group>
              )}
              {isMeeting && showCustomTime && (
                <Form.Group id="activity-list-add-activity-date-and-time">
                  <Form.Label aria-label="to-date&time">{"To Date & Time"}</Form.Label>
                  <div className="d-flex justify-content-center gap-2">
                    <input className="form-control" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    <SimpleDropdown value={toTime} label={"Select To Time"} options={timeOptions} handleSelect={(a) => setToTime(a)} />
                  </div>
                </Form.Group>
              )}

              {isMeeting && (
                <Form.Group id="activity-list-add-activity-meeting-link">
                  <Form.Label aria-label="meeting-link">Meeting Link:</Form.Label>
                  <Form.Control
                    type="text"
                    value={meetingLink}
                    onChange={(e) => {
                      setMeetingLink(e.target.value);
                    }}
                  />
                </Form.Group>
              )}
              {isMeeting && (
                <Form.Group id="activity-list-add-activity-time-zone">
                  <Form.Label aria-label="timezone">Timezone</Form.Label>
                  <TimeZoneSelect clearable={true} field={{ apiKeyName: "timezone", fieldName: "Timezone" }} value={timezone} setValue={(value) => setTimezone(value)} />
                </Form.Group>
              )}
            </Col>
            <Col className="pt-0 pr-7px pb-0 pl-18px" xs={6}>
              <Form.Group id="activity-list-add-activity-assignee" className="mb-8px">
                <Form.Label aria-label="assignee">Owner*</Form.Label>
                <OwnerSelect field={{ fieldName: "Owner" }} value={selectedUser} setValue={(value) => setSelectedUser(value)} />
              </Form.Group>

              {isMeeting && (
                <Form.Group id="activity-list-add-activity-guest">
                  <Form.Label aria-label="guest">Guest:</Form.Label>
                  <CustomEmail value={attendees} apiKeyName={"attendees"} setValue={(value) => setAttendees(value)} />
                </Form.Group>
              )}

              {isMeeting && (
                <Form.Group id="activity-list-add-activity-location">
                  <Form.Label aria-label="location">Location:</Form.Label>
                  <Form.Control
                    type="text"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </Form.Group>
              )}

              <Form.Group id="activity-list-add-activity-description">
                <Form.Label aria-label="description">Description</Form.Label>
                <textarea value={descriptionText} className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescriptionText(e.target.value)}></textarea>
              </Form.Group>
            </Col>
          </div>
        </Form>
      </CustomScrollableModal>
    </>
  );
}
