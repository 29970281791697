export const bytesToSize = (bytes) => {
  if (bytes === 0) return "0";

  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};

export const isWhatsappTemplateName = (input) => {
  const pattern = /^[a-zA-Z0-9_]+$/;
  return pattern.test(input)
}
export function numberToShorthand(number) {
  // Define the shorthand representations
  const shorthand = [
    [1e9, 'B'],  // Billion
    [1e6, 'M'],  // Million
    [1e3, 'k']   // Thousand
  ];

  // Iterate through the shorthand representations
  for (let i = 0; i < shorthand.length; i++) {
    const [value, suffix] = shorthand[i];
    if (number >= value) {
      // Calculate the truncated value
      const truncated = number / value;
      const decimalPlaces = Math.floor(truncated) !== truncated ? 1 : 0;
      const formatted = truncated.toFixed(decimalPlaces);
      return formatted + suffix;
    }
  }

  // If the number is smaller than 1000, return it as is
  return number.toString();
}
