import { getListOfCadencePrebuiltTemplate, createCadenceByTemplate } from "../types/cadence-prebuilt-template.types";

// prebuilt
export const getListOfCadencePrebuiltTemplateRequest = (payload, cb) => ({
  type: getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_REQUEST,
  payload,
  cb,
});
export const getListOfCadencePrebuiltTemplateSuccess = (payload) => ({
  type: getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_SUCCESS,
  payload,
});
export const getListOfCadencePrebuiltTemplateError = (payload) => ({
  type: getListOfCadencePrebuiltTemplate.GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_ERROR,
  payload,
});

export const createCadenceByTemplateRequest = (payload, cb) => ({
  type: createCadenceByTemplate.CREATE_CADENCE_BY_TEMPLATE_REQUEST,
  payload,
  cb,
});
export const createCadenceByTemplateSuccess = (payload) => ({
  type: createCadenceByTemplate.CREATE_CADENCE_BY_TEMPLATE_SUCCESS,
  payload,
});
export const createCadenceByTemplateError = (payload) => ({
  type: createCadenceByTemplate.CREATE_CADENCE_BY_TEMPLATE_ERROR,
  payload,
});
