import React, { useState, useEffect, useMemo } from "react";
import validator from "validator";
import { Modal, Button, Spinner } from "react-bootstrap";
import StepEmail from "./manageuser-Step1";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/settings.actions";
import Pagination from "../../../../components/normal-table/pagination";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { DeleteModal } from "../../../../components/modal/index";

import ManageUserPermission from "./manage-user-permissions";
import UserSelect from "./userDropdown";
import InlineStyled from "../../../../components/inline-styled/inline-styled";
import { AccessControllIconButton, ConvertToAdminIconButton, ConvertToAgentIconButton, DeleteIconButton } from "../../../../components/button/icon-action-buttons";

function ButtonHeader(props) {
  const dispatch = useDispatch();

  const [step, setstep] = useState(1);
  const [show, setShow] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [editPermission, setEditPermission] = useState({
    permissions: {
      contact: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      deals: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      company: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      lead: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      products: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      activity: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      marketing: {
        communicate: "Owned Only",
        delete: "Owned Only",
        edit: "Owned Only",
        view: "Owned Only",
      },
      import: true,
      export: false,
      workflow: false,
      campaign: false,
    },
  });

  const resetPermissions = () => {
    setEditPermission({
      permissions: {
        contact: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        deals: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        company: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        lead: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        products: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        activity: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        marketing: {
          communicate: "Owned Only",
          delete: "Owned Only",
          edit: "Owned Only",
          view: "Owned Only",
        },
        import: true,
        export: false,
        workflow: false,
        campaign: false,
      },
    });
  };

  const handleClose = () => {
    setShow(false);
    setstep(1);
    resetPermissions();
  };
  const handleShow = () => setShow(true);

  const handleNext = () => {
    if (step === 1) {
      if (emailField) {
        //eslint-disable-next-line
        var result = emailField.replace(/\s/g, "").split(/,|;/);
        const objArray = [];

        for (var i = 0; i < result.length; i++) {
          if (validator.isEmail(result[i])) {
            try {
              const objData = {
                email: result[i].trim(),
                permissions: editPermission["permissions"],
              };
              objArray.push(objData);
            } catch (e) { }
          } else {
            dispatchSnackbarError("Enter Valid Email", "error");
            setstep(1);
          }
        }
        if (objArray.length > 0)
          dispatch(
            actions.postManageUserRequest({ dataArr: objArray }, (data) => {
              if (data["success"]) {
                dispatchSnackbarSuccess("Invite Successfully Sent.", "success");
                dispatch(
                  actions.getManageUserRequest((data) => {
                    props.set(data.data);
                    setShow(false);
                    setstep(1);
                    setEmailField("");
                    resetPermissions();
                  }),
                );
              }
            }),
          );
      } else {
        dispatchSnackbarError("Please Enter The Email For Invitation", "error");
      }
    } else if (step === 2) {
      const objArray = [];
      for (let i = 0; i < emailField.split(",").length; i++) {
        try {
          const objData = {
            email: emailField.split(",")[i].trim(),
            permissions: editPermission["permissions"],
          };
          objArray.push(objData);
        } catch (e) { }
      }
      if (objArray.length > 0)
        dispatch(
          actions.postManageUserRequest({ dataArr: objArray }, (data) => {
            if (data["success"]) {
              dispatchSnackbarSuccess("Invite Successfully Sent.", "success");
              dispatch(
                actions.getManageUserRequest((data) => {
                  props.set(data.data);
                  setShow(false);
                  setstep(1);
                  setEmailField("");
                  resetPermissions();
                }),
              );
            }
          }),
        );
    }
  };

  const handleBack = () => {
    setstep(step - 1);
  };

  return (
    <>
      <div id="kt_signin_password_button" className="ms-auto" onClick={handleShow}>
        <Button className="commonStyle btn btn-sm btn-primary my-1">Invite User</Button>
      </div>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1px">
          {step === 1 ? (
            <StepEmail emailField={emailField} setEmailField={setEmailField} />
          ) : step === 2 ? (
            <ManageUserPermission value={editPermission.permissions} setValue={(permissions) => setEditPermission({ permissions: permissions })} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {step === 2 && (
            <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={handleBack}>
              Back
            </Button>
          )}
          {(step === 1 || step === 3) && (
            <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={handleClose}>
              Close
            </Button>
          )}
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={handleNext}>
            {step === 2 ? "Next" : "Invite"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function ManageUser() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [loader, setLoader] = useState(true);

  const [editData, setEditData] = useState({});
  const [editPermission, setEditPermission] = useState({
    permissions: {
      contact: {
        communicate: "Everything",
        delete: "Everything",
        edit: "Everything",
        view: "Everything",
      },
      deals: {
        communicate: "Everything",
        delete: "Everything",
        edit: "Everything",
        view: "Everything",
      },
      company: {
        communicate: "Everything",
        delete: "Everything",
        edit: "Everything",
        view: "Everything",
      },
      lead: {
        communicate: "Everything",
        delete: "Everything",
        edit: "Everything",
        view: "Everything",
      },
      products: {
        communicate: "Everything",
        delete: "Everything",
        edit: "Everything",
        view: "Everything",
      },
      activity: {
        communicate: "Everything",
        delete: "Everything",
        edit: "Everything",
        view: "Everything",
      },
      import: false,
      export: false,
      workflow: false,
      campaign: false,
    },
  });

  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState({});

  const [deleteUserCheckbox, setDeleteUserCheckbox] = useState("");
  const [deleteAssignTo, setDeleteAssignTo] = useState("");

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  useEffect(() => {
    setLoader(true);
    dispatch(
      actions.getManageUserRequest((data) => {
        setLoader(false);
        setComments(data.data);
      }),
    );
  }, [dispatch]);

  const commentsData = useMemo(() => {
    let computedComments = comments;

    setTotalItems(computedComments.length);

    // current page slice
    return computedComments.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
  }, [comments, currentPage]);

  const ActDecAct = (data, e) => {
    // settrueVal(e.target.checked)
    let objData;
    if (e.target.checked) {
      objData = {
        isDeactivated: false,
        userId: data.userId,
      };
    } else {
      objData = {
        isDeactivated: true,
        userId: data.userId,
      };
    }
    dispatch(
      actions.patchManageUserRequest(objData, data.userId, (data) => {
        if (data["success"]) {
          dispatch(
            actions.getManageUserRequest((data) => {
              setComments(data.data);

              dispatchSnackbarSuccess("Account is Successfully Updated", "success");
            }),
          );
        } else {
          dispatchSnackbarError(data.message);
        }
      }),
    );
  };

  /******
   * Edit Api
   * ************/

  const handleClose = () => setShow(false);

  const EditManager = (data) => {
    setEditData(data);
    setShow(true);
    // localStorage.setItem("tokenss",JSON.stringify(data['permissions']))
    setEditPermission({
      permissions: {
        ...data["permissions"],
      },
    });
  };

  const EditTeams = () => {
    let DataObj = {
      from: "edit-teams",
      isDeactivated: editData.isDeactivated,
      permissions: editPermission["permissions"],
    };

    dispatch(
      actions.patchManageUserRequest(DataObj, editData._id, (data) => {
        if (data["success"]) {
          dispatch(
            actions.getManageUserRequest((data) => {
              setComments(data.data);
              dispatchSnackbarSuccess("Account is Successfully Updated", "success");
              setShow(false);
            }),
          );
        }
      }),
    );
  };

  /*********
   * Delete API
   * ***************/

  const DeleteManage = (data) => {
    setShowDeleteModal(true);
    setDeletedData(data);
  };

  const onDeleteAccept = () => {
    if (deletedData.userStatus === "INVITED") {
    } else {
      if (deleteUserCheckbox === "") {
        return dispatchSnackbarError("Please select option to delete user");
      } else if (deleteUserCheckbox === "permanently") {
      } else if (deleteUserCheckbox === "assign" && deleteAssignTo === "") {
        return dispatchSnackbarError("Please select user to transfer current user data.");
      }
    }
    const data = {
      id: deletedData._id,
      body: {
        userStatus: deletedData.userStatus,
        assignTo: deleteAssignTo,
        isPermanentDelete: deleteUserCheckbox === "permanently",
      },
    };
    dispatch(
      actions.deleteManageUserRequest(data, (data1) => {
        if (data1["success"]) {
          dispatchSnackbarSuccess("user Successfully Deleted", "success");
          dispatch(
            actions.getManageUserRequest((data2) => {
              setComments(data2.data);
            }),
          );
          setDeletedData({});
          setShowDeleteModal(false);
        }
      }),
    );
  };

  // Account Role Converting

  const ManagerRole = (data, role) => {
    let DataObj = {
      role: role,
    };
    dispatch(
      actions.patchManageRoleRequest(DataObj, data._id, (data) => {
        if (data["success"]) {
          dispatch(
            actions.getManageUserRequest((data) => {
              setComments(data.data);
              dispatchSnackbarSuccess("Account is Successfully Updated", "success");
              setShow(false);
            }),
          );
        }
      }),
    );
  };

  const handleConfirmationClose = (status, user) => {
    setConfirmationShow(status);
    if (user) {
      setSelectedUser(user);
    }
  };

  const handleConfirmationYes = () => {
    ManagerRole(selectedUser, selectedUser.userType === "ADMIN" ? "AGENT" : "ADMIN");
    setConfirmationShow(false);
    setSelectedUser({});
  };

  const deleteTemplateModalProps = {
    show: showDeleteModal,
    title: "Delete User",
    handleClose: () => {
      setShowDeleteModal(false);
      setDeletedData({});
    },
    handleAccept: () => onDeleteAccept(),
  };

  const resendInvitation = (data) => {
    if (data.email) dispatch(actions.resendInvitationRequest({ email: data.email }));
    else {
      dispatchSnackbarError("Invalid Email or Email not provided.", "error");
    }
  };

  return (
    <>
      <div className="card mb-5 mb-xl-10 overflow-y-auto h-vh-200px-mx border-style-hidden">
        <div className="card-header">
          <div className="card-title">
            <h3 className="fw-bolder m-0">{"Manage Users"}</h3>
          </div>
          <div className="card-toolbar">
            <ButtonHeader set={setComments} />
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
              <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  {["User Name", "User email", "User Type", "User Status", "Deactivate/Activate", "Action "].map((each, index) => {
                    return (
                      <td key={index} className="w-16-point-66-percent">
                        {each}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="fw-6 fw-bold text-gray-600">
                {loader === true && (
                  <tr>
                    <td>
                      <Spinner className="position-absolute mt-15-percent ml-44-percent" animation="border" variant="primary" />
                    </td>
                  </tr>
                )}

                {commentsData.length === 0 && loader === false && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <h6>No Data To Show</h6>
                    </td>
                  </tr>
                )}

                {commentsData.map((user, id) => {
                  return (
                    <tr key={id}>
                      <td className="pr-4">{user.fullName}</td>
                      <td className="pr-4">{user.email}</td>
                      <td className="pr-4">
                        {user.userType} &nbsp;&nbsp; {user.isManager ? <span className="badge badge-light-info fs-8">Team Manager</span> : null}
                      </td>

                      <td className="pr-4">
                        {user.userStatus === "ACTIVE" && <span className="badge badge-light-success fs-7 fw-bolder">{user.userStatus}</span>}
                        {user.userStatus !== "ACTIVE" && <span className="badge badge-light-warning fs-7 fw-bolder">{user.userStatus}</span>}
                        {user.userStatus !== "ACTIVE" && (
                          <span>
                            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Resend Invitation</Tooltip>}>
                              <span className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3" onClick={() => resendInvitation(user)}>
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <i className="bi bi-arrow-clockwise"></i>
                                </span>
                              </span>
                            </OverlayTrigger>
                          </span>
                        )}
                      </td>

                      <td className="pr-4">
                        {userProfile && userProfile.data && userProfile.data.userType === "OWNER" ? (
                          //yes
                          user.userType !== "OWNER" ? (
                            <div className="col-4 text-right ml-40px">
                              <div className="form-check form-switch form-check-custom form-check-solid">
                                <input
                                  className="form-check-input h-20px w-35px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    ActDecAct(user, e);
                                  }}
                                  checked={!user.isDeactivated}
                                  name="ActDeact"
                                />
                                <label className="form-check-label" htmlFor="favoriteSwitch">
                                  {/* Favorite */}
                                </label>
                              </div>{" "}
                            </div>
                          ) : null
                        ) : (
                          //no
                          user.userType !== "OWNER" &&
                          userProfile &&
                          userProfile.data &&
                          userProfile.data.userType === "ADMIN" &&
                          user.userType !== "ADMIN" && (
                            <div className="col-4 text-right ml-40px">
                              <div className="form-check form-switch form-check-custom form-check-solid">
                                <input
                                  className="form-check-input h-20px w-35px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    ActDecAct(user, e);
                                  }}
                                  checked={!user.isDeactivated}
                                  name="ActDeact"
                                />
                                <label className="form-check-label" htmlFor="favoriteSwitch">
                                  {/* Favorite */}
                                </label>
                              </div>
                            </div>
                          )
                        )}
                      </td>

                      <td>
                        <div className="d-flex align-items-center w-120px">
                          <span>
                            {userProfile && userProfile.data && userProfile.data.userType === "OWNER" ? (
                              //yes
                              user.userType !== "OWNER" ? (
                                <div style={{ marginLeft: "-16px" }}>
                                  <AccessControllIconButton onClick={() => EditManager(user)} />
                                  <DeleteIconButton onClick={() => DeleteManage(user)} />
                                </div>
                              ) : null
                            ) : //no
                              user.userType !== "OWNER" && userProfile && userProfile.data && userProfile.data.userType === "ADMIN" && user.userType !== "ADMIN" ? (
                                <div style={{ marginLeft: "-16px" }}>
                                  <AccessControllIconButton onClick={() => EditManager(user)} />
                                  <DeleteIconButton onClick={() => DeleteManage(user)} />
                                </div>
                              ) : null}
                          </span>
                          <span>
                            {user.userType === "AGENT" && user.userStatus !== "INVITED" && <ConvertToAdminIconButton onClick={() => handleConfirmationClose(true, user)} />}

                            {userProfile && userProfile.data && userProfile.data.userType !== "ADMIN" && user.userType === "ADMIN" && user.userStatus !== "INVITED" && (
                              <ConvertToAgentIconButton onClick={() => handleConfirmationClose(true, user)} />
                            )}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="position-absolute right-20px mt-minus-21px">
        <Pagination total={totalItems} itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
      </div>

      {/***************************************************************************/}
      <Modal show={confirmationShow} onHide={() => handleConfirmationClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineStyled as="span" className="font-size-14px" styles={{ fontFamily: `Poppins, Helvetica, "sans-serif"` }}>
            Are you sure want to convert "{selectedUser.email}" user role from {selectedUser.userType === "AGENT" ? "AGENT to ADMIN" : "ADMIN to AGENT"}?
          </InlineStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={() => handleConfirmationClose(false)}>
            No
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => handleConfirmationYes()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteModal modalProps={deleteTemplateModalProps}>
        {deletedData.userStatus === "INVITED" ? (
          <div>Are you sure you want to delete user permanently?</div>
        ) : (
          <div>
            <div>Select an option for user data.</div>
            <Form.Group
              id="settings-account-users-manage-user-delete-user-checkbox"
              className="m-0 d-flex"
              onClick={(e) => {
                setDeleteUserCheckbox(e.target.name);
              }}
            >
              <div className="p-2 m-2 radio-container">
                <Form.Check type="radio" label={"Transfer data"} name={"assign"} checked={deleteUserCheckbox === "assign"} id={1} />
              </div>
              <div className="p-2 m-2 radio-container">
                <Form.Check type="radio" label={"Delete Permanently"} name={"permanently"} checked={deleteUserCheckbox === "permanently"} id={2} />
              </div>
            </Form.Group>
            <div className="mb-4">
              {deleteUserCheckbox === "assign" && (
                <Form.Group id="settings-account-users-manage-user-delete-assigned-user">
                  <Form.Label aria-label="select-the-user">Select the user whom you want to transfer the data (for example, a manager)</Form.Label>
                  <UserSelect value={deleteAssignTo} hideUserId={deletedData.userId} setValue={(value) => setDeleteAssignTo(value)} />
                </Form.Group>
              )}
            </div>
            {deleteUserCheckbox && (
              <div className="p-10px bg-aliceblue border-left-width-6px border-left-style-solid border-left-color-0177c9">
                <strong>Note:</strong>
                <div>
                  <div>
                    {deleteUserCheckbox === "assign" ? (
                      <p>All your data, including leads, deals and contacts will be transferred. Any personal customisation data will not be transferred.</p>
                    ) : (
                      <p>This option will delete all user data permanently, including leads, deals and contacts. You will not be able to recover the deleted data again.</p>
                    )}
                    <p>If user is part of any team and role it will be removed automatically.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </DeleteModal>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Edit Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1px">
          <ManageUserPermission userType={editData.userType} isManager={editData.isManager ? true : false} value={editPermission.permissions} setValue={(permissions) => setEditPermission({ permissions: permissions })} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {" "}
            Close{" "}
          </Button>
          <Button variant="primary" className="saveCommponbutton" onClick={EditTeams}>
            {" "}
            Edit Permissions{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
