import { getListOfActivties, createActivity, createShortActivity, editActivity, deleteActivities, updateLoadingData, bulkDeleteActivities } from "../types/activity.types";

export const getListOfActivtiesRequest = (req) => ({
  type: getListOfActivties.GET_LIST_OF_ACTIVITIES_REQUEST,
  payload: req,
});
export const getListOfActivtiesSuccess = (req) => ({
  type: getListOfActivties.GET_LIST_OF_ACTIVITIES_SUCCESS,
  payload: req,
});
export const getListOfActivtiesError = (req) => ({
  type: getListOfActivties.GET_LIST_OF_ACTIVITIES_ERROR,
  payload: req,
});
export const getListOfActivtiesRequestAfter = (req) => ({
  type: getListOfActivties.GET_LIST_OF_ACTIVITIES_REQUEST_AFTER,
  payload: req,
});

export const updateActivityLoadingData = (req) => ({
  type: updateLoadingData.UPDATE_ACTIVITY_LOADING_DATA,
  payload: req,
});

export const createActivityRequest = (req, cb) => ({
  type: createActivity.CREATE_ACTIVITY_REQUEST,
  payload: req,
  cb,
});
export const createActivitySuccess = (req) => ({
  type: createActivity.CREATE_ACTIVITY_SUCCESS,
  payload: req,
});
export const createActivityError = (req) => ({
  type: createActivity.CREATE_ACTIVITY_ERROR,
  payload: req,
});

export const createShortActivityRequest = (req, cb) => ({
  type: createShortActivity.CREATE_SHORT_ACTIVITY_REQUEST,
  payload: req,
  cb,
});
export const createShortActivitySuccess = (req) => ({
  type: createShortActivity.CREATE_SHORT_ACTIVITY_SUCCESS,
  payload: req,
});
export const createShortActivityError = (req) => ({
  type: createShortActivity.CREATE_SHORT_ACTIVITY_ERROR,
  payload: req,
});

export const editActivityRequest = (req, cb) => ({
  type: editActivity.EDIT_ACTIVITY_REQUEST,
  payload: req,
  cb,
});
export const editActivitySuccess = (req) => ({
  type: editActivity.EDIT_ACTIVITY_SUCCESS,
  payload: req,
});
export const editActivityError = (req) => ({
  type: editActivity.EDIT_ACTIVITY_ERROR,
  payload: req,
});

export const deleteActivityRequest = (req, cb) => ({
  type: deleteActivities.DELETE_ACTIVITIES_REQUEST,
  payload: req,
  cb,
});
export const deleteActivitySuccess = (req) => ({
  type: deleteActivities.DELETE_ACTIVITIES_SUCCESS,
  payload: req,
});
export const deleteActivityError = (req) => ({
  type: deleteActivities.DELETE_ACTIVITIES_ERROR,
  payload: req,
});

export const bulkDeleteActivityRequest = (req) => ({
  type: bulkDeleteActivities.BULK_DELETE_ACTIVITES_REQUEST,
  payload: req,
});
export const bulkDeleteActivitySuccess = (req) => ({
  type: bulkDeleteActivities.BULK_DELETE_ACTIVITES_SUCCESS,
  payload: req,
});
export const bulkDeleteActivityError = (req) => ({
  type: bulkDeleteActivities.BULK_DELETE_ACTIVITES_ERROR,
  payload: req,
});
