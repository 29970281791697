export const getWeekTimeStamp = (date) => {
  const curr_date = date;
  const day = curr_date.getDay();
  const diff = curr_date.getDate() - day + (day === 0 ? -6 : 1); // 0 for sunday
  const start = curr_date.setDate(diff);
  const week_start = new Date(start).getFullYear() + "/" + (new Date(start).getMonth() + 1) + "/" + new Date(start).getDate() + " 00:00:00";
  const week_start_tstmp = new Date(week_start).setHours(23, 59, 59, 999);
  let week_end = new Date(week_start_tstmp); // first day of week
  week_end = week_end.setDate(week_end.getDate() + 6);
  const lineData = {
    start_time: new Date(week_start).getTime(),
    // start_time: week_start_tstmp,
    end_time: week_end,
  };
  return lineData;
};

export const getMonthTimeStamp = (month) => {
  const lastday = new Date(new Date().getFullYear(), month, 0);
  const firstday = new Date(lastday.getFullYear(), lastday.getMonth(), 1);
  const lineData = {
    start_time: firstday.getTime(),
    end_time: lastday.setHours(23, 59, 59, 999),
    type: "",
  };
  return lineData;
};

export const decreaseDate = (days) => {
  return new Date().setDate(new Date().getDate() - days);
};

export const decreaseMonth = (months) => {
  return new Date().setMonth(new Date().getMonth() - months);
};
