import React, { useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

function BarChart(props) {
  const [graphData] = useState(props.won);
  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll('[shape-rendering="auto"]').forEach((e) => e.remove());
    }, 3000);
    // const timer = setTimeout(() => {
    // document.querySelectorAll('[shape-rendering="auto"]').forEach(e => e.remove())
    // },500)
    // return () => {
    //     clearTimeout(timer)
    //   }
  });

  useLayoutEffect(() => {
    var chart = am4core.create(props.chartId, am4charts.XYChart3D);

    chart.data = graphData;
    chart.hideCredits = true;

    // chart.addLicense("ch-custom-attribution");

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = props.category;
    categoryAxis.renderer.labels.template.rotation = 0;
    categoryAxis.renderer.labels.template.hideOversized = false;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.horizontalCenter = "middle";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    // categoryAxis.tooltip.label.rotation = 0;
    // categoryAxis.tooltip.label.horizontalCenter = "right";
    // categoryAxis.tooltip.label.verticalCenter = "middle";
    categoryAxis.title.text =
      props.interval
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
        .substr(0, 1)
        .toUpperCase() + props.interval.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : "")).substr(1);

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text =
      props.graphType
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
        .substr(0, 1)
        .toUpperCase() + props.graphType.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : "")).substr(1);
    valueAxis.title.fontWeight = "bold";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = props.values;
    series.dataFields.categoryX = props.category;
    series.name = props.values;
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#FFFFFF");

    columnTemplate.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    columnTemplate.adapter.add("stroke", function(stroke, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineY.strokeOpacity = 0;
  }, [graphData, props.chartId, props.category, props.graphType, props.interval, props.values]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default BarChart;
