import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import { getCampaignPricing } from "../types/campaign_common_settings_type";
import * as campaignCommonSettingActions from "../actions/campaign_common_settings_actions";
import { endpoints } from "../endpoints";
import api from "../../../../network/apis/api";

function* getCampaignPricingFunction(action) {
  try {
    const res = yield call(api.get, `${endpoints.PRICING}/${action.payload}`);
    if (res.status === 200) {
      yield put(campaignCommonSettingActions.getCampaignPricingSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignCommonSettingActions.getCampaignPricingError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignCommonSettingActions.getCampaignPricingError(e));
  }
}
export default function* campaignCommonSettingsSaga() {
  yield takeLatest(getCampaignPricing.GET_CAMPIAGN_PRICING_REQUEST, getCampaignPricingFunction);
}
