/**
 * Pause  > It will pause the susbscriber
Resume  > If pause it will resume
Mark as finished > It will finish 
Change step > We will ask to choose the step selection and then move the user
Remove from cadence > It will remove
Mark as replied 
Retry - if failed it will do retry

 */

export const pauseCadenceSubscriber = {
  REQUEST: "PAUSE_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "PAUSE_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "PAUSE_CADENCE_SUBSCRIBER_FAILURE",
};

export const resumeCadenceSubscriber = {
  REQUEST: "RESUME_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "RESUME_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "RESUME_CADENCE_SUBSCRIBER_FAILURE",
};

export const markAsFinishedCadenceSubscriber = {
  REQUEST: "MARK_AS_FINISHED_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "MARK_AS_FINISHED_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "MARK_AS_FINISHED_CADENCE_SUBSCRIBER_FAILURE",
};

export const changeStepCadenceSubscriber = {
  REQUEST: "CHANGE_STEP_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "CHANGE_STEP_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "CHANGE_STEP_CADENCE_SUBSCRIBER_FAILURE",
};

export const removeFromCadenceSubscriber = {
  REQUEST: "REMOVE_FROM_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "REMOVE_FROM_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "REMOVE_FROM_CADENCE_SUBSCRIBER_FAILURE",
};

export const markAsRepliedCadenceSubscriber = {
  REQUEST: "MARK_AS_REPLIED_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "MARK_AS_REPLIED_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "MARK_AS_REPLIED_CADENCE_SUBSCRIBER_FAILURE",
};

export const retryCadenceSubscriber = {
  REQUEST: "RETRY_CADENCE_SUBSCRIBER_REQUEST",
  SUCCESS: "RETRY_CADENCE_SUBSCRIBER_SUCCESS",
  FAILURE: "RETRY_CADENCE_SUBSCRIBER_FAILURE",
};
