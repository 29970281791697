export const getListOfSalesTarget = {
  GET_LIST_OF_SALES_TARGET_REQUEST: "GET_LIST_OF_SALES_TARGET_REQUEST",
  GET_LIST_OF_SALES_TARGET_SUCCESS: "GET_LIST_OF_SALES_TARGET_SUCCESS",
  GET_LIST_OF_SALES_TARGET_ERROR: "GET_LIST_OF_SALES_TARGET_ERROR",
};

export const getListOfSalesTargetLoadMore = {
  GET_LIST_OF_SALES_TARGET_LOAD_MORE_REQUEST: "GET_LIST_OF_SALES_TARGET_LOAD_MORE_REQUEST",
  GET_LIST_OF_SALES_TARGET_LOAD_MORE_SUCCESS: "GET_LIST_OF_SALES_TARGET_LOAD_MORE_SUCCESS",
  GET_LIST_OF_SALES_TARGET_LOAD_MORE_ERROR: "GET_LIST_OF_SALES_TARGET_LOAD_MORE_ERROR",
};

export const createSalesTarget = {
  CREATE_SALES_TARGET_REQUEST: "CREATE_SALES_TARGET_REQUEST",
  CREATE_SALES_TARGET_SUCCESS: "CREATE_SALES_TARGET_SUCCESS",
  CREATE_SALES_TARGET_ERROR: "CREATE_SALES_TARGET_ERROR",
};

export const editSalesTarget = {
  EDIT_SALES_TARGET_REQUEST: "EDIT_SALES_TARGET_REQUEST",
  EDIT_SALES_TARGET_SUCCESS: "EDIT_SALES_TARGET_SUCCESS",
  EDIT_SALES_TARGET_ERROR: "EDIT_SALES_TARGET_ERROR",
};

export const deleteSalesTarget = {
  DELETE_SALES_TARGET_REQUEST: "DELETE_SALES_TARGET_REQUEST",
  DELETE_SALES_TARGET_SUCCESS: "DELETE_SALES_TARGET_SUCCESS",
  DELETE_SALES_TARGET_ERROR: "DELETE_SALES_TARGET_ERROR",
};

export const getSalesTargetGraph = {
  GET_SALES_TARGET_GRAPH_REQUEST: "GET_SALES_TARGET_GRAPH_REQUEST",
  GET_SALES_TARGET_GRAPH_SUCCESS: "GET_SALES_TARGET_GRAPH_SUCCESS",
  GET_SALES_TARGET_GRAPH_ERROR: "GET_SALES_TARGET_GRAPH_ERROR",
};
