import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomPagination from "../pagination/custom-pagination";
import { customColorMapping } from "./constants";
import OwnerDisplay from "../table/displays/ownerDisplay";
import { CustomEmptyPage } from "../empty_page/custom-empty-page";
import InlineStyled from "../inline-styled/inline-styled";

export function CustomTableWithoutAction(props) {
  const { tablehead, tablecontent, mappingTable, dataType } = props;

  const getCustomTagClass = (value) => {
    if (typeof value !== "string") return "dark";
    if (customColorMapping[value.toLowerCase()]) return customColorMapping[value.toLowerCase()];
    return "dark";
  };

  const renderContent = (content, column) => {
    if (dataType[mappingTable[column]] === "LIGHT_TAG") {
      return <span className={`badge badge-light-${getCustomTagClass(content[mappingTable[column]])}`}>{content[mappingTable[column]]}</span>;
    } else if (dataType[mappingTable[column]] === "TAG") {
      return <span className={`badge badge-${getCustomTagClass(content[mappingTable[column]])}`}>{content[mappingTable[column]]}</span>;
    } else if (dataType[mappingTable[column]] === "OWNER") {
      return <OwnerDisplay value={content[mappingTable[column]]} />;
    } else if (dataType[mappingTable[column]] === "DATE") {
      return moment(content[mappingTable[column]]).format("MMM DD, h:mm a");
    } else {
      return content[mappingTable[column]];
    }
  };

  return (
    <div className="card card-custom">
      <div className="card-body py-3 px-3">
        <div className="table-responsive overflow-y-auto">
          <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
            <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
              <thead>
                <tr className="fw-bolder text-muted">
                  {tablehead.map((el, index) => (
                    <InlineStyled
                      as="th"
                      styles={{
                        minWidth: `${100 / tablehead.length}%`,
                      }}
                      className={`opacity-1 bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap ${index === 0 ? "pl-2" : ""}`}
                    >
                      <div className="opacity-0-point-8 text-black d-flex flex-row align-items-center w-100 h-100 gap-5px text-nowrap">{el}</div>
                    </InlineStyled>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tablecontent.length === 0 && (
                  <tr>
                    <th className="p-0 m-0 text-center" colSpan="100%">
                      <CustomEmptyPage page="emptyTable" />
                    </th>
                  </tr>
                )}
                {tablecontent.map((content, ind) => (
                  <tr key={ind} className="cursor-pointer">
                    {tablehead.map((column, index) => (
                      <td className={`text-nowrap ${index === 0 ? "pl-2" : ""} ${props.isLastTotal && tablecontent.length === ind + 1 ? "fw-boldest" : ""}`}>
                        <div className="d-flex flex-row flex-stack">
                          <span className="data-flex-container">{renderContent(content, column)}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </PerfectScrollbar>
        </div>
        {!props.hidePagination && (
          <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-15px pt-7px">
            <p className="mt-5px mb-0 font-size-14px">
              Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
            </p>
            {CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), () => {}, props.limit, props.setLimit, props.count)}
          </div>
        )}
      </div>
    </div>
  );
}
