import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";

export default function IconBasedSimpleDropdown({ value, label, icon, options, handleSelect, disabled, handleClose }) {
  const _label = typeof value === "string" ? value : value.label ? value.label : label;
  return (
    <Dropdown className="mt-0 simple-dropdown-container w-100">
      <Dropdown.Toggle disabled={disabled || false} className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none" variant={"link"}>
        {!value ? (
          <OverlayTrigger overlay={<Tooltip>{label}</Tooltip>}>
            <i class={`${icon} fs-2`}></i>
          </OverlayTrigger>
        ) : (
          <div className="d-flex gap-2 align-items-center pl-3 pr-1 py-2 border rounded-2">
            <span> {_label}</span>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            ></i>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-auto" onClick={(e) => e.stopPropagation()}>
          {options.map((option, optionIndex) => {
            return (
              <Dropdown.Item
                key={optionIndex}
                className="dropdown-hover"
                onSelect={(e) => {
                  handleSelect(option, optionIndex);
                }}
              >
                {typeof option === "string" ? option : option.label}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function IconBasedSimpleDropdownMenu({ show, setShow, label, icon, children }) {
  return (
    <Dropdown rootClose={true} show={show} className="mt-0 simple-dropdown-container w-100">
      <Dropdown.Toggle
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
        className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none"
        variant={"link"}
      >
        <OverlayTrigger overlay={<Tooltip>{label}</Tooltip>}>
          <i class={`${icon}`}></i>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">{children}</Dropdown.Menu>
    </Dropdown>
  );
}
