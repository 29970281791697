import React from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { InputLimit, LimitInputNumber } from "../../../../../../../../constants";
import "./styles.scss";

const addOnInfoMapping = {
    "emailSync": {
        name: "Email & Calendar Sync",
        description: "Engage more people through your email communication.",
        calculation: "Sync Account(s)",
        calculationValue: "sync account",
        max: 100,
        min: 0
    }
}
export default function SubscriptionAddOnList({ supportedAddons, addOnQuantity, setAddOnQuantity, currencySymbol, planMapping, interval }) {
    const onAddClick = (type) => {
        const a = Number(addOnQuantity[type]);
        setAddOnQuantity({ ...addOnQuantity, [type]: (a + 1) || 1 });
    };
    const onMinusClick = (type) => {
        const a = Number(addOnQuantity[type]);
        if (a > 0) {
            setAddOnQuantity({ ...addOnQuantity, [type]: (a - 1) || 0 });
        }
    };


    const onInputChange = (type, value) => {
        const planData = addOnInfoMapping[type];
        if (planData) {
            if (value > planData.max) {
                setAddOnQuantity({ ...addOnQuantity, [type]: planData.max });
            }
            else if (value < planData.min) {
                setAddOnQuantity({ ...addOnQuantity, [type]: planData.min });
            }
            else {
                setAddOnQuantity({ ...addOnQuantity, [type]: value });
            }

        }
    };

    if (_.isEmpty(supportedAddons)) return null;
    return (
        <div className="subscription-add-list-wrapper">
            <div className="list-title">Add-ons</div>
            <div className="add-on-list-wrapper">
                {
                    supportedAddons.map((each, index) => {
                        return <div className="add-on-each-list" key={index}>
                            <div className="add-on-each-list-section-1">
                                <div className="add-on-list-name">{addOnInfoMapping[each].name}</div>
                                <div className="add-on-list-description">{addOnInfoMapping[each].description}</div>
                                <div className="add-on-calculation-input-wrapper">
                                    <div>{addOnInfoMapping[each].calculation}</div>
                                    <div>
                                        {currencySymbol}
                                        {planMapping[each].amount} x {addOnQuantity[each]}({addOnInfoMapping[each].calculationValue})
                                    </div>
                                    <div>
                                        <strong>
                                            {currencySymbol}
                                            {(planMapping[each].amount) * addOnQuantity[each]}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="add-on-each-list-section-2">
                                <div className="add-on-price-wrapper">
                                    <span className="add-on-price">
                                        {currencySymbol}
                                        {planMapping[each].amount}
                                    </span>
                                    <span className="add-on-interval"> per {interval}</span>
                                </div>
                                <div className="add-on-input-wrapper">
                                    <div className="add-on-input-button-wrapper">
                                        <button className="add-on-input-button" onClick={() => onMinusClick(each)}>
                                            -{" "}
                                        </button>
                                        <Form.Control
                                            maxLength={InputLimit.NUMBER}
                                            className="line-height-24px internal-input w-50px font-size-20px text-black"
                                            value={addOnQuantity[each]}
                                            onChange={(e) => onInputChange(each, LimitInputNumber(e.target.value))}
                                            type="number"
                                        />
                                        <button className="add-on-input-button" onClick={() => onAddClick(each)}>
                                            {" "}
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
}
