export const getDataPresentFields = (data) => {
  const headerKeys = data[0].filter((each) => each);
  const indexToKeyMapping = {},
    validKeys = [];
  let dummyKeys = [...headerKeys];
  headerKeys.forEach((each, index) => {
    indexToKeyMapping[index] = each;
  });

  for (let i = 1; i < data.length; i++) {
    const eachData = data[i];
    if (dummyKeys.length === 0) {
      break;
    }

    const __validKeys = [];
    for (let each of dummyKeys) {
      const index = headerKeys.indexOf(each);
      if (eachData[index]) {
        __validKeys.push(each);
      }
    }
    if (__validKeys.length > 0) {
      validKeys.push(...__validKeys);
      dummyKeys = dummyKeys.filter((each) => !__validKeys.includes(each));
    }
  }
  return validKeys;
};

export const getSystemIdBasedOnKeys = (fieldData, mapFields) => {
  const systemId = mapFields.map((each) => "");

  for (const eachList of fieldData.list) {
    const apiKeyIndex = mapFields.indexOf(eachList.apiKeyName);
    const fieldNameIndex = mapFields.indexOf(eachList.fieldName);
    if (apiKeyIndex !== -1) {
      systemId[apiKeyIndex] = eachList._id;
    } else if (fieldNameIndex !== -1) {
      systemId[fieldNameIndex] = eachList._id;
    }
  }
  return systemId;
};
