import React, { useEffect, useState } from "react";
import validator from "validator";
import _ from "lodash";
import { Button, Form } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import { BlueGradientButton, GreenGradientButton } from "../../../../components/button";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { ContentWithCardLoading } from "../../../../components/loading";
import { InputLimit } from "../../../../constants";
import ContentWrapper from "../../../Wrapper";
import CampaignSubHeader from "../../components/campaign-common-sub-header";
import { campaignChannelsOptions } from "../../constants";
import { country_code_map } from "../../../../_metronic/_assets/js/countryCode";

// actions
import * as blockContactAction from "../../store/actions/campaign_block_contact_actions";
import MultiSelectCheckboxDropdown from "../../../../components/multi-select/multi-select-checkbox";
import { blockContactFieldStore } from "./constants";
import AdavanceDataTable from "../../../../components/normal-table/advance-table";
import { DeleteModal } from "../../../../components/modal";
import { dispatchSnackbarError } from "../../../../utils/toaster";

export default function CampaignBlockContact(props) {
  const tabName = "Block Contacts";
  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [channel, setChannel] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [defaultCode, setDefaultCode] = useState("91");
  const [selecetdId, setSelectedId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();

  const blockContactStore = useSelector((store) => store.campaign.blockContact);
  const profile = useSelector((state) => state.ProfileSettings.getProfileDetails.data);

  useEffect(() => {
    dispatch(blockContactAction.getListOfBlockContactsRequest({ limit: limit, page: pageNo }));
  }, [dispatch, limit, pageNo]);

  useEffect(() => {
    if (profile.country) {
      const defaultData = country_code_map[profile.country.toLowerCase()];
      if (defaultData) {
        setDefaultCode(defaultData.code);
      }
    }
  }, [profile]);

  const handleAddEditClose = () => {
    setIsEdit(false);
    setShow(false);
    setChannel([]);
    setEmail("");
    setPhone("");
  };

  const handleAddEditSave = () => {
    if (_.isEmpty(channel)) {
      return dispatchSnackbarError("Please select channel!");
    }
    if (!email && !phone) {
      return dispatchSnackbarError("Please enter email or phone!");
    }
    if (email && !validator.isEmail(email)) {
      return dispatchSnackbarError("Invalid email provided");
    }
    if (phone && !validator.isNumeric(phone)) {
      return dispatchSnackbarError("Invalid phone provided");
    }
    const payload = {
      id: selecetdId,
      loadingData: { limit: limit, page: pageNo },
      data: {
        channel: channel.map((each) => each.value),
        email: email,
        phone: phone,
      },
    };
    if (isEdit) {
      dispatch(blockContactAction.editBlockContactRequest(payload));
    } else {
      dispatch(blockContactAction.createBlockContactRequest(payload));
    }
    handleAddEditClose();
  };

  const onEditTemplateClick = (data) => {
    setIsEdit(true);
    setShow(true);
    setEmail(data.email);
    setPhone(data.phone);
    setSelectedId(data._id);
    setChannel(campaignChannelsOptions.filter((a) => data.channel.includes(a.value)));
  };
  const onDeleteTenplateClick = (data) => {
    setSelectedId(data._id);
    setShowDelete(true);
  };
  const handleDeleteCancel = () => {
    setShowDelete(false);
    setSelectedId(null);
  };
  const handleDeleteAccept = () => {
    const payload = {
      id: selecetdId,
      loadingData: { limit: limit, page: pageNo },
    };
    dispatch(blockContactAction.deleteBlockContactRequest(payload));
    handleDeleteCancel();
  };

  const deleteCadenceModalProps = {
    show: showDelete,
    title: "Delete Block Contact",
    handleClose: handleDeleteCancel,
    handleAccept: handleDeleteAccept,
  };

  return (
    <ContentWrapper
      subHeader={
        <CampaignSubHeader active={tabName}>
          <div className="d-flex align-items-center gap-4">
            <BlueGradientButton
              variant="success"
              size="sm"
              onClick={() => {
                setShow(true);
                setIsEdit(false);
              }}
            >
              + Block Contact
            </BlueGradientButton>
          </div>
        </CampaignSubHeader>
      }
    >
      {blockContactStore.loading ? (
        <ContentWithCardLoading />
      ) : blockContactStore.data.length === 0 ? (
        <CustomEmptyPage
          page="blockContact"
          setShowAdd={() => {
            setShow(true);
            setIsEdit(false);
          }}
        />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <AdavanceDataTable
              columns={blockContactFieldStore.columns}
              data={blockContactStore.data}
              limit={limit}
              pageNo={pageNo}
              setLimit={setLimit}
              setPageNo={setPageNo}
              count={blockContactStore.count}
              //actions
              handleEdit={onEditTemplateClick}
              handleDelete={onDeleteTenplateClick}
            />
          </div>
        </div>
      )}

      <BlankSidePanel show={show} handleClose={() => setShow(false)} title={isEdit ? "Edit Block Contact" : "Add Block Contact"}>
        <Form.Group>
          <Form.Label className="fw-bold">Channel</Form.Label>
          <MultiSelectCheckboxDropdown options={campaignChannelsOptions} selectedValue={channel} setSelectedValue={setChannel} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control maxLength={InputLimit.EMAIL} type="email" value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <ReactPhoneInput
            value={phone ? phone.toString() : defaultCode}
            onChange={(e) => setPhone(e)}
            inputClass="w-100"
            inputStyle={{
              border: "1px solid rgb(228, 230, 239)",
            }}
            dropdownStyle={{ zIndex: 100 }}
            enableSearch={true}
          />
        </Form.Group>
        <Form.Group className="d-flex gap-3 mt-10">
          <Button size="sm" variant="secondary" onClick={handleAddEditClose}>
            {"Close"}
          </Button>
          <GreenGradientButton size="sm" onClick={handleAddEditSave}>
            {"Save"}
          </GreenGradientButton>
        </Form.Group>
      </BlankSidePanel>
      <DeleteModal modalProps={deleteCadenceModalProps}>Are you sure want to delete this contact?</DeleteModal>
    </ContentWrapper>
  );
}
