import React, { useState, useEffect } from "react";
import FormInput from "../../../../components/Form-Field/index";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import NormalTable from "../../../../components/normal-table/index";
import { useDispatch } from "react-redux";
import * as actions from "../../actions/settings.actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import CustomAction from "../../../../components/ActionColumn/index";
import { DeleteModal } from "../../../../components/modal/index";
import { TabWithActions } from "../../../../components/Tabs/tab-with-actions";
function ButtonHeader(props) {
  const dispatch = useDispatch();

  const [Reason, setReason] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setReason("");
  };
  const handleShow = () => setShow(true);

  function handleChange(event) {
    setReason(event.target.value);
  }

  const handleLostReason = () => {
    if (!Reason) {
      dispatchSnackbarError("Please enter lost reason!");
    } else {
      let addLostKeyObj = {
        type: "DEAL",
        reason: Reason,
      };
      dispatch(
        actions.postLostReasonRequest(addLostKeyObj, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Lost Reason Successfully Added", "success");
            dispatch(
              actions.getLostReasonRequest((data) => {
                let dummydata = [];
                for (let i = 0; i < data.data.length; i++) {
                  if (data.data[i].type === "DEAL") {
                    dummydata.push(data.data[i]);
                  }
                }
                // setlostReasonTable(dummydata)
                // props.set(data.data)
                props.set(dummydata);
              }),
            );
            setShow(false);
            setReason("");
          }
        }),
      );
    }
  };

  return (
    <>
      <div id="kt_signin_password_button" className="ms-auto" onClick={handleShow}>
        <button className="commonStyle btn btn-sm btn-primary my-1">+ Lost Reasons</button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Lost Reasons</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-16px">Lost Reason*</span>
            <FormInput placeholder={"Enter lost reason"} type={"text"} name={"lost"} value={Reason} onChange={(e) => handleChange(e)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={() => handleLostReason()}>
            Add Lost Reason
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function LostReason() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [Reason, setReason] = useState("");
  const [LostReasonId, setLostReasonId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState();
  const handleClose = () => setShow(false);

  const [lostReasonTable, setlostReasonTable] = useState([]);

  const links = [
    { name: "Activity", to: "/settings/account/lost-reason/activity" },
    { name: "Deal", to: "/settings/account/lost-reason/deal" },
    { name: "Lead", to: "/settings/account/lost-reason/lead" },
    { name: "Marketing", to: "/settings/account/lost-reason/marketing" },
  ];

  const tabName = "Deal";

  useEffect(() => {
    setLoader(true);
    dispatch(
      actions.getLostReasonRequest((data) => {
        let dummydata = [];
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].type === "DEAL") {
            dummydata.push(data.data[i]);
          }
        }
        setlostReasonTable(dummydata);
        // setlostReasonTable(data.data)
        setLoader(false);
      }),
    );
  }, [dispatch]);
  /*****
   * Edit Api
   * ************/
  const DeleteManage = (data) => {
    setShowDeleteModal(true);
    setDeletedData(data);
    // dispatch(actions.deleteManageUserRequest(data._id, (data) => {
    //   if (data['success']) {
    //     dispatchSnackbarSuccess("Manageuser Successfully Deleted", "success")
    //     dispatch(actions.getManageUserRequest((data) => {
    //       setComments(data.data)
    //     }))
    //   }
    // }))
  };
  const deleteTemplateModalProps = {
    show: showDeleteModal,
    title: "Delete Deal Lost Reason",
    handleClose: () => {
      setShowDeleteModal(false);
      setDeletedData({});
    },
    handleAccept: () => deleteLostReason(),
  };
  function handleChange(event) {
    setReason(event.target.value);
  }

  const EditLostReason = (user) => {
    setShow(true);
    setReason(user.reason);
    setLostReasonId(user._id);
  };

  const handleLostReason = () => {
    if (!Reason) {
      dispatchSnackbarError("Lost reason should not be empty!");
    } else {
      let editObj = {
        reason: Reason,
      };
      dispatch(
        actions.patchLostReasonRequest(editObj, LostReasonId, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Lost Reason Successfully Updated", "success");

            dispatch(
              actions.getLostReasonRequest((data) => {
                let dummydata = [];
                for (let i = 0; i < data.data.length; i++) {
                  if (data.data[i].type === "DEAL") {
                    dummydata.push(data.data[i]);
                  }
                }
                setlostReasonTable(dummydata);
                // setlostReasonTable(data.data)
              }),
            );

            setShow(false);
          }
        }),
      );
    }
  };

  /****
   * delete Api
   * ********/

  const deleteLostReason = () => {
    dispatch(
      actions.deleteLostReasonRequest(deletedData._id, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Lost Reason Successfully Deleted", "success");
          dispatch(
            actions.getLostReasonRequest((data) => {
              let dummydata = [];
              for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].type === "DEAL") {
                  dummydata.push(data.data[i]);
                }
              }
              setDeletedData({});
              setShowDeleteModal(false);
              setlostReasonTable(dummydata);
              // setlostReasonTable(data.data)
            }),
          );
        }
      }),
    );
  };

  const checkData = () => {
    let dummyarray = [];
    lostReasonTable.map((data) => (data.type === "DEAL" ? dummyarray.push(data) : []));
    if (dummyarray.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {/* <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Deal Lost Reasons</h3>
          </div>
        </div> */}

      {/* <div id="kt_account_signin_method" className="collapse show">
          
          <div className="card-body border-top p-9"> */}

      {/*begin::Password*/}

      {/* <p className="fw-500 font-size-16px">Here you can manage Lost reasons. When a deal is marked as lost, users can choose between these options</p> */}
      {/*begin::Action*/}

      <TabWithActions links={links} active={tabName} actions={<ButtonHeader set={setlostReasonTable} />} />

      <NormalTable
        tableHeading={["Type", "", "Lost Reasons", "", "Action "]}
        tableTitle={"Deal Lost Reasons"}
        description={"When a deal is lost, the below reasons will be displayed. This will help you to understand the cause and fix them to improve performance."}
      >
        {!loader ? (
          lostReasonTable.length > 0 ? (
            lostReasonTable.map((user, id) => {
              return (
                // user.type === 'DEAL' ?
                <tr key={id}>
                  <td>{user.type}</td>
                  <td></td>
                  <td>{user.reason}</td>
                  <td></td>
                  <td className="d-flex w-150px">
                    <CustomAction Edit={() => EditLostReason(user)} Delete={() => DeleteManage(user)} />
                  </td>
                </tr>
              );
            })
          ) : (
            checkData() && (
              <tr>
                <td colSpan="4" className="text-center">
                  <h6>No Data To Show</h6>
                </td>
              </tr>
            )
          )
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        )}
      </NormalTable>

      {/*end::Action*/}

      {/*end::Password*/}
      {/* </div> */}
      {/*end::Card body*/}
      {/* </div> */}
      {/*end::Content*/}
      {/* </div> */}

      {/* ******
       *Edit Lost Reason
       ****** */}
      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete Deal lost reason ?</DeleteModal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lost Reasons</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-16px">Lost Reason*</span>
            <FormInput placeholder={"Enter lost reason"} type={"text"} name={"lost"} value={Reason} onChange={(e) => handleChange(e)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => handleLostReason()}>
            Edit Lost Reason
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
