import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getEmailAccountReputationsRequest } from "../../../../store/actions/campaign_email_settings_actions";

export default function AccountReputation(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmailAccountReputationsRequest());
  }, [dispatch]);

  const accountReputationStore = useSelector((store) => store.campaign.settings.email.accountReputation);

  const RepuationList = [
    {
      name: "Reputation",
      percentKey: "reputation",
      key: "reputation",
    },
    {
      name: "Opened",
      percentKey: "openedpercent",
      key: "opened",
    },
    {
      name: "Clicked",
      percentKey: "clickedpercent",
      key: "clicked",
    },
    {
      name: "Abuse",
      percentKey: "abusepercent",
      key: "abuse",
    },
    {
      name: "Failed & Spam",
      percentKey: "failedspampercent",
      key: "failedspampercent",
    },
    {
      name: "Unknown Users",
      percentKey: "unknownuserspercent",
      key: "unknownusers",
    },
    {
      name: "Average Spam Score",
      percentKey: "averagespamscore",
      key: "averagespamscore",
    },
  ];
  const getRoundedValue = (value) => {
    return _.round(value, 2);
  };
  return (
    <div className="card border-0 mb-5 mb-xl-10 h-vh-120px-mx">
      <div className="text-black fs-5 mx-8 mt-8">
        Track your account performance and reputation from here. A good reputation ensures high deliverability. If it is poor, your account may be blocked from sending emails. Contact support in this case.
      </div>
      <div className="card-body d-flex flex-wrap">
        {RepuationList.map((each, index) => {
          return (
            <div className="w-200px card mb-4 mr-4 p-4">
              <div>
                <span className="fs-2tx">{getRoundedValue(accountReputationStore.data[each.percentKey])}%</span>
              </div>
              <div className="fs-5"> {each.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
