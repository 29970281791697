import React, { useEffect, useState } from "react";
import moment from "moment";

export default function DateRangeSelect({ apiKeyName, setValue, value }) {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    if (Array.isArray(value)) {
      setFromDate(value[0]);
      setToDate(value[1]);
    }
  }, [value]);

  const setToDateFunction = (e) => {
    setToDate(e.target.value);
    setValue([fromDate, e.target.value], apiKeyName);
  };
  const setFromDateFunction = (e) => {
    setFromDate(e.target.value);
    setValue([e.target.value, toDate], apiKeyName);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="w-47">
        <input className="form-control" value={fromDate ? moment(fromDate).format("YYYY-MM-DD") : null} type="date" name="fromDate" onChange={(e) => setFromDateFunction(e)} />
      </div>

      <div className="my-0 mx-2px"> - </div>
      <div className="w-50prc">
        <input className="form-control" value={toDate ? moment(toDate).format("YYYY-MM-DD") : null} type="date" name="fromDate" onChange={(e) => setToDateFunction(e)} />
      </div>
    </div>
  );
}

export function DateTimeRangeSelect({ apiKeyName, setValue, value }) {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    if (Array.isArray(value)) {
      setFromDate(value[0]);
      setToDate(value[1]);
    }
  }, [value]);

  const setToDateFunction = (e) => {
    setToDate(e.target.value);
    setValue([fromDate, e.target.value], apiKeyName);
  };
  const setFromDateFunction = (e) => {
    setFromDate(e.target.value);
    setValue([e.target.value, toDate], apiKeyName);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="w-47">
        <input className="form-control" value={fromDate ? moment(fromDate).format("YYYY-MM-DDTHH:mm") : null} type="date" name="fromDate" onChange={(e) => setFromDateFunction(e)} />
      </div>

      <div className="my-0 mx-2px"> - </div>
      <div className="w-50prc">
        <input className="form-control" value={toDate ? moment(toDate).format("YYYY-MM-DDTHH:mm") : null} type="date" name="fromDate" onChange={(e) => setToDateFunction(e)} />
      </div>
    </div>
  );
}
