import { combineReducers } from "redux";
import activityListReducer from "./activity_list_reducer";
import activityDataFieldReducer from "./activity_data_field_reducer";
import activitySavedFilterReducer from "./saved_filter_reducer";
import staticsticsReducer from "./staticstics_reducer";
import activityLossReasonReducer from "./activity_loss_reason_reducer";
import activityTypesReducer from "./activity_types_reducer";
import activityCalendarViewReducer from "./calendar_view_reducer";
import activitySearchReducer from "./activity_search_reducer";

const activityModuleReducer = combineReducers({
  list: activityListReducer,
  dataField: activityDataFieldReducer,
  savedFilters: activitySavedFilterReducer,
  staticstics: staticsticsReducer,
  lossReasons: activityLossReasonReducer,
  types: activityTypesReducer,
  calendar: activityCalendarViewReducer,
  search: activitySearchReducer,
});

export default activityModuleReducer;
