import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as usageActions from "../../actions/usage.actions";

export default function Usage() {
  const dispatch = useDispatch();
  const usageData = useSelector((store) => store.usage.data);

  const typeIcons = {
    leads: <i className="bi bi-bullseye text-primary font-size-24px" />,
    activities: <i className="bi bi-calendar-check text-primary font-size-24px" />,
    companies: <i className="bi bi-building text-primary font-size-24px" />,
    customFields: <i className="bi bi-ui-radios text-primary font-size-24px" />,
    customers: <i className="bi bi-people text-primary font-size-24px" />,
    deals: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-coin text-primary" viewBox="0 0 16 16">
        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
      </svg>
    ),
    notes: <i className="bi bi-journal-text text-primary font-size-24px" />,
    products: <i className="bi bi-basket2 text-primary font-size-24px" />,
  };
  useEffect(() => {
    dispatch(usageActions.getUsageRecordReportRequest());
  }, [dispatch]);

  const getCustomFieldCount = (data) => {
    try {
      return data.companies + data.contacts + data.leads + data.products + (data.deals || 0);
    } catch (err) {
      return 0;
    }
  };

  const getTotalRecords = (data) => {
    try {
      return data.companies + data.customers + data.leads;
    } catch (err) {
      return 0;
    }
  };

  return (
    <>
      <div className="card mb-5 mb-xl-10 overflow-y-auto h-vh-200px-mx border-style-hidden">
        <div className="card-header">
          <div className="card-title">
            <h3 className="fw-bolder m-0">
              <div>Usage</div>
            </h3>
          </div>
        </div>

        <div className="container p-9 bg-white">
          <div className="row mb-10">
            <h4 className="fw-500 text-dark">Custom Fields</h4>
            {Object.keys(usageData).map(
              (names, key) =>
                names === "customFields" && (
                  <div key={key} className="col-4 mb-10px">
                    <div className="text-009ef7 bg-f5f8fa99 border-color-e4e6ef d-flex align-items-center flex-lg-fill my-1 btn btn-outline btn-outline-dashed btn-outline flex-row h-100 p-3 text-left position-relative">
                      <span className="mr-7 ml-7">{typeIcons[names]}</span>
                      <div className="d-flex flex-column text-dark-75">
                        <span className="text-dark d-block mb-2 fw-500 font-size-16px text-capitalize">{names === "customFields" ? "Custom Fields" : names}</span>
                        <div className="font-weight-bolder font-size-h5 overflow-wrap-anywhere">
                          <span className="text-primary font-weight-bold">{getCustomFieldCount(usageData[names])}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
            )}
          </div>
          <div className="row">
            <h4 className="fw-500 text-dark">Records</h4>
            {Object.keys(usageData).map((names, key) => {
              if (names === "customFields") {
                return null;
              } else if (names === "recordLimit") {
                return null;
              } else {
                return (
                  <div key={key} className="col-4 mb-10px">
                    <div className="text-009ef7 bg-f5f8fa99 border-color-e4e6ef d-flex align-items-center flex-lg-fill my-1 btn btn-outline btn-outline-dashed btn-outline flex-row h-100 p-3 text-left position-relative">
                      <span className="mr-7 ml-7">{typeIcons[names]}</span>
                      <div className="d-flex flex-column text-dark-75">
                        <span className="text-dark d-block mb-2 fw-500 font-size-16px text-capitalize">{names === "customFields" ? "Custom Fields" : names}</span>
                        <div className="font-weight-bolder font-size-h5 overflow-wrap-anywhere">
                          <span className="text-primary font-weight-bold">{usageData[names]}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="d-flex flex-column w-100 me-2 pt-7">
            <div className="d-flex flex-stack mb-2">
              <span className="text-muted me-2 fs-7 fw-bold">Records</span>
            </div>
            <div className="d-flex flex-stack mb-2 position-absolute right-90px">
              <span className="text-muted me-2 fs-7 fw-bold">
                {getTotalRecords(usageData)} of {usageData["recordLimit"] ? usageData["recordLimit"] : 0}
              </span>
            </div>
            <div className="progress h-6px w-100">
              <div
                className={`progress-bar bg-primary w-${(getTotalRecords(usageData) / (usageData["recordLimit"] ? usageData["recordLimit"] : 0)) * 100}`}
                role="progressbar"
                style={{
                  ariaValuenow: "50",
                  ariaValuemin: "0",
                  ariaValuemax: "100",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
