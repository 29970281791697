import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SplitDropdownNav from "./channels/split-dropdown";

export default function AdvancedSubHeaderNav({ links, active, activeIcon }) {
  const handleNavLinkClick = (to, target) => {
    if (target === "_blank") {
      window.open(to, "_blank");
    }
  };
  return (
    <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
      {links.map((link, index) => (
        <div key={index} className="menu-item me-lg-1">
          {link.type === "dropdown" ? (
            <Dropdown className="h-100">
              <Dropdown.Toggle className={`text-decoration-none h-100 menu-link ${link.subTabNames.includes(active) ? "active" : ""}`} variant="link">
                {link.subTabNames.includes(active) && activeIcon ? (
                  <span className="menu-icon mr-0 ml-4">
                    <span className="svg-icon svg-icon-2">
                      {" "}
                      <i className={`${activeIcon}`}></i>
                    </span>
                  </span>
                ) : (
                  (link.icon || link.svg) && (
                    <span className="menu-icon mr-2">
                      <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
                    </span>
                  )
                )}
                <span className="menu-title pl--1">{link.subTabNames.includes(active) ? active : link.name}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {link.options.map((each, ind) => {
                  return (
                    <Dropdown.Item className="p-0 m-0" key={ind}>
                      <NavLink onClick={() => handleNavLinkClick(each.to, each.target)} className={`menu-link py-3 ${active === each.name ? "active" : ""}`} to={each.to} rel="noopener noreferrer" target={each.target}>
                        {(each.icon || each.svg) && (
                          <span className="menu-icon mr-2">
                            <span className="svg-icon svg-icon-2">{each.svg ? each.svg : <i className={`${each.icon}`}></i>}</span>
                          </span>
                        )}
                        <span className="menu-title">{each.name}</span>
                      </NavLink>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : link.type === "split-dropdown" ? (
            <SplitDropdownNav link={link} active={active} activeIcon={activeIcon} handleNavLinkClick={handleNavLinkClick} />
          ) : (
            <NavLink className={`menu-link py-3 ${active === link.name ? "active" : ""}`} to={link.to}>
              {(link.icon || link.svg) && (
                <span className="menu-icon mr-2">
                  <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
                </span>
              )}
              <span className="menu-title">{link.name}</span>
            </NavLink>
          )}
        </div>
      ))}
    </div>
  );
}
