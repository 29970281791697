import React from "react";
import CustomSidePanel from "../../../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";

export default function WhatsappCloudApiHelpDoc(props) {

    return (
        <CustomSidePanel
            show={props.show}
            moduleName={"Cloud API Credentials"}
            handleClose={() => props.setShow(false)}
            showNextButton={false}
            showPreviosButton={false}
            customStyles={{
                width: "80vw",
                right: "81vw",
            }}
        >
            <div className="card-header d-flex flex-stack align-items-center">
                <h3 className="card-title fw-bolder text-dark">{`How to Generate your WhatsApp Cloud API Credentials?`}</h3>
            </div>
            <div class="modal-body line-height-xl fs-6">
                <p>This is a one-time setup. Estimated setup time: 15 - 20 min.</p>
                <div class="mt-2 mb-4 alert alert-secondary">
                    <h4 class="mb-2">Requirements</h4>
                    <p class="mb-2">Following are <strong>four must-have requirements</strong> to setup the official WhatsApp Cloud APIs:</p>
                    <ol class="mb-2">
                        <li><strong>Phone Number</strong> that's not currently connected with WhatsApp.</li>
                        <li>Valid <strong>business registration document</strong> of your business.</li>
                        <li><strong>Website</strong> with a privacy policy page.</li>
                        <li><strong>Payment method</strong> like a credit or debit card.</li>
                    </ol>
                    <p class="mb-0">If you <b>do not have</b> any of these, you will not be able to complete the setup.</p>
                </div>

                <h4 className="mt-8">Step 1: Create Meta account and an app for WhatsApp Cloud API</h4>
                <ol>
                    <li>Create a free <a href="https://developers.facebook.com/async/registration/" target="_blank" rel="noopener noreferrer">Meta developer account</a>, if you don't already have one. Follow the steps shown on screen to complete creating your account if you're doing it for the first time.</li>
                    <li>Next, click on the green <a href="https://developers.facebook.com/apps/create/" target="_blank" rel="noopener noreferrer">Create App</a> button to create a new app.</li>
                    <li>On the next page, select the <b>Business</b> option and click on the <b>Next</b> button at the bottom.</li>
                    <li>On the next <b>Provide basic information</b> page, fill in the details and click on <b>Create App</b> when done.</li>
                    <li>You will be redirected to your <b>App</b> page where you need to <b>Add products to your app</b>. Scroll to the bottom to find "WhatsApp" product and click <b>Set up</b>.</li>
                    <li>On the next, <b>WhatsApp Business Platform API</b> screen, you will see the option to select an existing Business Manager (if you have one) or if you would like, the onboarding process will create one automatically for you. Make a selection and click <b>Continue</b>.</li>
                    <li>You will be redirected to the <b>Get Started</b> page that'll look like this:
                        <img class="img-fluid mt-2" alt={"getting-started"} src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/getting-started_isanpm.png" />
                    </li>
                </ol>

                <h4 className="mt-8">Step 2: Setup your phone number in WhatsApp Cloud API</h4>
                <ol>
                    <li>On this <b>Get Started</b> page, scroll down on the screen and click on <b>Add phone number</b> button.</li>
                    <li>Fill in your <b>WhatsApp Business profile</b> details and click <b>Next</b>. Do not enter random dummy details here.</li>
                    <li>Next, you'll need to add your phone number to use it as your <b>WhatsApp phone number</b>. Enter your phone number and select a verification method to verify the number.
                        <ul>
                            <li><b>NOTE:</b> Once a phone number is setup here it'll be <b>connected with WhatsApp Cloud API</b> and you will not be able to use it with the mobile app.</li>
                        </ul>
                    </li>
                    <li>Enter the verfication code and click <b>Next</b> to finish adding the number.</li>
                </ol>
                <h4 className="mt-8">Step 3: Configure webhooks</h4>
                <ol>
                    <li>From the left sidebar, click on <b>Whatsapp &gt; Configuration</b> link.</li>
                    <li>Click on the <b>Edit</b> button. That'll open a popup. Enter the following in the <b>Callback URL</b> field: <code>https://{window.location.host}/campaign-webhook-receiver/api/v1/whatsapp</code></li>
                    <li>In the <b>Verify token</b> field enter: <code>MG1svJTSpyEY3JXX</code> and click <b>Verify and save</b>.</li>
                    <li>After this, under <b>Webhook fields</b>, click on the <b>Manage</b> button. In the popup that opens, click on the <b>Subscribe</b> button in front of <b>all the fields</b>.
                        <ul>
                            <li>Subscribing to <b>all fields</b> is required for full integration with WANotifier.</li>
                        </ul>
                    </li>
                    <li>Click on <b>Done</b> to complete your Webhook setup. It should look something like this:
                        <img class="img-fluid mt-2" alt={"cloud-api-webhook"} src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/cloud-api-webhooks_n6d9fx.png" />
                    </li>
                </ol>
                <h4 className="mt-8">Step 4: Generate permanent access token</h4>
                <ol>
                    <li>Scroll to the top of the page and click on <b>App Dashboard</b> next to the MetaforDevelopers logo. Then open the <b>Business Settings</b> link in a new tab.<br />
                        <img class="img-fluid my-2 d-block mx-auto" width="400" alt={"business-settings-menu"} src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/business-settings-menu_dlkh1a.png" />
                    </li>
                    <li>On the Business Settings page, in left sidebar, click on <b>System users</b> under <b>Users</b>. Then click on <b>Add</b> to add a new user.</li>
                    <li>Click on <b>I Accept</b> button if you see non-discrimation policy popup.</li>
                    <li>Enter a name for your <b>System user name</b> (you can keep it <code>system</code>). Select <b>System user role</b> as <b>Admin</b> and click on <b>Create system user</b>.</li>
                    <li>Now click on the <b>Add Assets</b> button. Under <b>Select asset type</b> click on <b>Apps</b>, select your app from <b>Select assets</b> column and then enable <b>Full control &gt; Manage App</b>. Then click on <b>Save Changes</b>.
                        <img class="img-fluid my-2" alt="cloud-api-add-assets" src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/clpud-api-add-asset_sfq5yy.png" />
                    </li>
                    <li>Now click on <b>Generate New Token</b> button. Select your app from the dropdown and from the list of permissions under <b>Available Permissions:</b> select <em>whatsapp_business_messaging</em> and <em>whatsapp_business_management</em>. Then click on the <b>Generate Token</b> button.</li>
                    <li>Copy the <b>Access token</b>.<br />
                        <img class="img-fluid mt-2 d-block mx-auto" width="400" alt="permanent-access-token" src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/permanent-access-token_izilfj.png" />
                    </li>
                </ol>
                <h4 className="mt-8">Step 5: Add payment method</h4>
                <ol>
                    <li>Now from the left sidebar go to <b>Accounts &gt; WhatsApp Accounts</b> page, select your WhatsApp account from the list and click on the <b>Settings</b> tab.</li>
                    <li>Then click on the <b>Payment Settings</b> button. On the Payment Settings page, click on <b>Add payment method</b> button.</li>
                    <li>Select your <b>Country</b>, <b>Currency</b> and <b>Payment Method</b> and click <b>Next</b>.</li>
                    <li>Enter your payment details and hit <b>Save</b>. You'll see a screen like the following after your payment method is added successfully:
                        <img class="img-fluid mt-2" alt="payment-details" src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/payment-details_ca4yq0.png" />
                    </li>
                </ol>
                <h4 className="mt-8">Step 6: Change App Mode to Live</h4>
                <ol>
                    <li>Now go back to the <b>Getting Started</b> page which you opened earlier.</li>
                    <li>On the top you'll see <b>App Mode</b>. Change it from <b>Development</b> to <b>Live</b>.<br />
                        <img class="img-fluid my-2 d-block mx-auto" width="300" alt="change-app-mode" src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774008/app/whatsapp-integration/change-app-mode_i8ijmn.png" />
                    </li>
                    <li>Follow the instructions on screen if it asks you to provide additional information like Privacy Policy URL etc.</li>
                </ol>
                <h4 className="mt-8">Step 7: Save and validate your credentials</h4>
                <ol>
                    <li>From the <b>Get Started</b> page, open the dropdown under <b>Step 1: Send and receive messages</b> under the <b>From</b> label and select the phone number you added.</li>
                    <li>After selecting your phone number, the <b>Phone Number ID</b> and <b>WhatsApp Business Account ID</b> values will get updated below it. Copy those values, close this popup and paste in the form.
                        <img class="img-fluid mt-2" alt="select-cloud-api-credentials" src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1685774009/app/whatsapp-integration/select-cloud-api-credentials_fskkcb.png" />
                    </li>
                    <li>
                        Also copy the <b>Permanent Access Token</b> that you generated earlier and paste that in the respective field.
                    </li>
                </ol>
                <p>All done! Now click on the <b>Save and validate</b> button to complete the setup!</p>
            </div>
        </CustomSidePanel >
    )
}