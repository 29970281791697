import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Papa from "papaparse";
import readXlsxFile from "read-excel-file";
import { useDispatch, useSelector } from "react-redux";

// import steps
import ImportStep1 from "../import-steps/step1";
import ImportStep2 from "../import-steps/step2";
import ImportStep3 from "../import-steps/step3";
import ImportStep4 from "../import-steps/step4";
import ImportFooter from "./import-footer";

//actions
import * as actions from "../actions";

//helpers
import { getDataPresentFields, getSystemIdBasedOnKeys } from "../helper";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import _ from "lodash";

const requiredFieldsForMapping = {
  LEAD: [],
  PEOPLE: [],
  COMPANY: ["name"],
  PRODUCT: ["productName"],
  MARKETING: [],
  // "DEAL": ["owner"],
  // "ACTIVITY": ["owner"]
};

const warningMessages = {
  LEAD: "",
  PEOPLE: "",
  COMPANY: "Company Name is mandatory field of Company Data Import!",
  PRODUCT: "Product Name is mandatory field of Product Data Import!",
};

export default function ImportModal({ showImportModal, setShowImportModal, loadingData }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [file, setFile] = useState();
  const [search, setSearch] = useState("");
  const [systemId, setSystemId] = useState([]);
  const [fields, setFields] = useState([]);
  const [importType, setImportType] = useState("smart");
  const [dataType, setDataType] = useState("lead");
  const [showFields, setShowFields] = useState([]);
  const [hideFields, setHideFields] = useState([]);
  const [sampleFileFields, setSampleFileFields] = useState([]);
  const [allowDuplicate, setAllowDuplicate] = useState(true);
  const [totalAutoMapped, setTotalAutoMapped] = useState(0);
  const [isTagLinked, setIsTagLinked] = useState(false);
  const [options, setOptions] = useState([]);
  const [dataName, setDataName] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicationBasedOn, setDuplicationBasedOn] = useState("Email");
  const [duplicateOverride, setDuplicateOverride] = useState({ label: "Update fields & Update new phone number", value: "yes" });

  const leadColumns = useSelector((state) => state.Leads.leadFields);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showImportModal) {
      setCurrentStep(1);
      setFields(leadColumns);
      setDataType("lead");
      setHideFields([]);
      setTags([]);
      setAllowDuplicate(true);
      setIsTagLinked(false);
    }
  }, [showImportModal, leadColumns]);

  useEffect(() => setSearch(""), [currentStep]);
  useEffect(() => setFile(), [showImportModal]);
  useEffect(() => setFile(), [importType]);

  useEffect(() => {
    if (!file) {
      setDataName([]);
    } else {
      if (importType === "smart") {
        if (file.name && file.name.substr(file.name.length - 3) === "csv") {
          Papa.parse(file, {
            error: () => {
              dispatchSnackbarError("Could Not Parse The File!");
              setFile();
            },
            complete: (data) => {
              if (data && data.data && Array.isArray(data.data) && data.data.length > 1) {
                const validKeys = getDataPresentFields(data.data);
                setDataName(validKeys);
              } else {
                dispatchSnackbarError("There is no data in the uploaded file to process!");
                setFile();
              }
            },
          });
        } else if (file.name && file.name.substr(file.name.length - 4) === "xlsx" && false) {
          readXlsxFile(file)
            .then((rows, index) => {
              setDataName(rows[0].filter((each) => each));
            })
            .catch(() => {
              dispatchSnackbarError("Could Not Parse The File!");
              setFile();
            });
        } else {
          setFile();
          dispatchSnackbarError("Only .csv files are supported");
        }
      }
    }
  }, [file, importType]);

  useEffect(() => {
    if (dataName.length > 0) {
      const __systemIds = getSystemIdBasedOnKeys(fields, dataName);
      setSystemId(__systemIds);
      setTotalAutoMapped(
        __systemIds.reduce((accumulator, a) => {
          if (a) return accumulator + 1;
          return accumulator;
        }, 0),
      );
    } else {
      setSystemId(dataName.map(() => ""));
      setTotalAutoMapped(0);
    }
  }, [dataName, fields]);

  useEffect(() => {
    let tempCol = [];
    let tempCol1 = [];
    var temp = [];
    var temp2 = [];
    if (fields.list)
      for (let i = 0; i < fields.list.length; i++) {
        if (
          (fields.list[i].fieldInputType === "MULTI_OWNER_SELECT" || fields.list[i].fieldInputType === "OWNER_SELECT") &&
          !(fields.list[i].fieldGroup === "SYSTEM" && fields.list[i].fieldInputType === "OWNER_SELECT" && fields.list[i].fieldName === "Owner" && fields.list[i].apiKeyName === "owner")
        ) {
          continue;
        }
        if (fields.list[i].isEditable) {
          if (fields.list[i].isEnabledForTable) {
            tempCol1.push(fields.list[i]["_id"]);
          } else {
            tempCol.push(fields.list[i]["_id"]);
          }
          temp2.push({ value: fields.list[i]["_id"], label: fields.list[i]["fieldName"] });
        }
        if (fields.list[i].fieldGroup !== "CUSTOM") temp.push(fields.list[i].apiKeyName);
        else temp.push(`customFields.${fields.list[i].apiKeyName}`);
      }
    setShowFields(tempCol);
    setSampleFileFields(temp);
    setHideFields(tempCol1);
    setOptions(temp2);
  }, [fields]);

  const handleSelectField = (val, index) => {
    setSystemId([...systemId.slice(0, index), val, ...systemId.slice(index + 1)]);
  };

  const nextStepSmart = () => {
    if (!file) {
      dispatchSnackbarError("Please upload a file!");
      return;
    }
    if (!(file.name.substr(file.name.length - 3) === "csv")) {
      dispatchSnackbarError("Only CSV files are supported");
      return;
    } else setCurrentStep(4);
  };

  const handleSampleCSV = () => {
    const dataTypeMapping = {
      lead: "LEAD",
      people: "PERSON",
      company: "COMPANY",
      product: "PRODUCT",
      marketing: "MARKETING",
    };
    const tempStr = "importType=" + dataTypeMapping[dataType];
    dispatch(actions.sampleFileRequest(tempStr));
  };

  const validateRequiredFields = () => {
    const tableFields = fields.list;
    const requiredFieldsName = requiredFieldsForMapping[dataType.toUpperCase()];
    const requiredIds = tableFields
      .filter((a) => {
        if (requiredFieldsName.includes(a.apiKeyName)) {
          return true;
        }
        return false;
      })
      .map((a) => a._id);

    let flag = false;
    if (dataType.toUpperCase() === "LEAD" || dataType.toUpperCase() === "PEOPLE" || dataType.toUpperCase() === "MARKETING") {
      if (systemId.length > 0) flag = true;
      else {
        dispatchSnackbarError("Import map system fields cannot be empty. Please map with system fields.");
        return false;
      }
    } else {
      for (let i = 0; i < requiredIds.length; i++) {
        if (systemId.indexOf(requiredIds[i]) !== -1) {
          flag = true;
          continue;
        }
      }
    }

    if (!flag) {
      dispatchSnackbarError(warningMessages[dataType.toUpperCase()]);
      return false;
    }
    return true;
  };

  const handleImport = () => {
    if (!file) {
      dispatchSnackbarError("Please upload a file!");
      return;
    }
    if (!file.name.substr(file.name.length - 3) === "csv") {
      dispatchSnackbarError("Only CSV files are supported");
      return;
    }
    if (showFields.length === 0 && importType === "default") {
      dispatchSnackbarError("At Least One Field Must be Selected");
      setCurrentStep(2);
      return;
    }

    if (dataType === "marketing") {
      if (_.isEmpty(tags)) {
        return dispatchSnackbarError("Tags are mandatory in importing marketing list");
      }
    }
    setLoading(true);

    var formData = new FormData();
    if (importType === "default") {
      formData.append("selectedFieldsId", `${showFields}`);
      formData.append("dataType", dataType);
      formData.append("importType", "default");
      formData.append("mapFieldIds", "{}");
      formData.append("file", file);
      formData.append("preventDuplication", allowDuplicate);
      formData.append("tag", tags.length > 0 ? tags[0] : "");

      if (dataType === "marketing") {
        formData.append("duplication", JSON.stringify({ basedOn: duplicationBasedOn === "Email" ? "email" : "phone", override: duplicateOverride.value === "yes" ? true : false }));
      }

      dispatch(
        actions.importRequest({ data: formData, loadingData: loadingData }, (data) => {
          if (data.status) {
            dispatchSnackbarSuccess("Data imported successfully.");
          } else {
            dispatchSnackbarError(data.message);
          }
          setShowImportModal(false);
          setLoading(false);
        }),
      );
    } else {
      var temp = [];
      const checkingArray = systemId && Array.isArray(systemId) && systemId.filter((field) => field !== "");

      const check = validateRequiredFields();
      if (!check) {
        return;
      }

      for (var i = 0; i < systemId.length; i++) {
        temp.push({ systemId: systemId[i], dataName: dataName[i] });
      }

      if (checkingArray && checkingArray.length > 0) {
        formData.append("selectedFieldsId", `[]`);
        formData.append("dataType", dataType);
        formData.append("importType", "smart");
        formData.append("mapFieldIds", JSON.stringify(temp));
        formData.append("file", file);
        formData.append("preventDuplication", allowDuplicate);
        formData.append("tag", tags.length > 0 ? tags[0] : "");
        if (dataType === "marketing") {
          formData.append("duplication", JSON.stringify({ basedOn: duplicationBasedOn === "Email" ? "email" : "phone", override: duplicateOverride.value === "yes" ? true : false }));
        }
        dispatch(
          actions.importRequest({ data: formData, loadingData: loadingData }, (data) => {
            if (data.status) {
              dispatchSnackbarSuccess("Data imported successfully.");
            } else {
              dispatchSnackbarError(data.message);
            }
            setShowImportModal(false);
            setAllowDuplicate(false);
            setLoading(false);
          }),
        );
      } else {
        dispatchSnackbarError(`Atleast one field need to be mapped!`);
      }
    }
  };

  return (
    <Modal backdrop="static" show={showImportModal} onHide={() => setShowImportModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Import Data</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="stepper stepper-pills d-flex flex-column" id="kt_stepper_example_basic">
          <div className="stepper-nav pt-5 d-flex justify-content-center">
            <div className={`stepper-item mx-2 my-4 ${currentStep === 1 ? "current" : ""}`} data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">1</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">Data Type</h3>
              </div>
            </div>

            <div className={`stepper-item mx-2 my-4 ${currentStep === 3 ? "current" : ""}`} data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">2</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">Upload File</h3>
              </div>
            </div>

            <div className={`stepper-item mx-2 my-4 ${currentStep === 4 ? "current" : ""}`} data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">3</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">Map Fields</h3>
              </div>
            </div>
          </div>
          {currentStep === 1 ? (
            <ImportStep1 dataType={dataType} setDataType={setDataType} setFields={setFields} setHideFields={setHideFields} />
          ) : currentStep === 2 ? (
            <ImportStep2 importType={importType} setImportType={setImportType} />
          ) : currentStep === 3 ? (
            <ImportStep3
              fields={fields}
              search={search}
              file={file}
              importType={importType}
              showFields={showFields}
              hideFields={hideFields}
              sampleFileFields={sampleFileFields}
              setFile={setFile}
              setSearch={setSearch}
              setShowFields={setShowFields}
              setHideFields={setHideFields}
              setSampleFileFields={setSampleFileFields}
            />
          ) : (
            <ImportStep4
              file={file}
              totalAutoMapped={totalAutoMapped}
              setFile={setFile}
              importType={importType}
              fields={fields}
              systemId={systemId}
              allowDuplicate={allowDuplicate}
              setAllowDuplicate={setAllowDuplicate}
              dataType={dataType}
              isTagLinked={isTagLinked}
              setIsTagLinked={setIsTagLinked}
              search={search}
              handleSelectField={handleSelectField}
              dataName={dataName}
              options={options}
              tags={tags}
              setTags={setTags}
              duplicationBasedOn={duplicationBasedOn}
              setDuplicationBasedOn={setDuplicationBasedOn}
              duplicateOverride={duplicateOverride}
              setDuplicateOverride={setDuplicateOverride}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ImportFooter
          loading={loading}
          dataType={dataType}
          importType={importType}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setShowImportModal={setShowImportModal}
          nextStepSmart={nextStepSmart}
          handleSampleCSV={handleSampleCSV}
          handleImport={handleImport}
        />
      </Modal.Footer>
    </Modal>
  );
}
