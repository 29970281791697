import React, { useState } from "react";
import { Spinner, OverlayTrigger, Popover } from "react-bootstrap";
import { ContentLoadingWithoutCard } from "../../../../components/loading";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomPagination from "../../../../components/pagination/custom-pagination";
import CustomDisplay from "../../../../components/table/data";
import { ActivityPopoverEdit } from "../../../../components/popover_edit/activity-popover-edit";
import _ from "lodash";
import DueDateDisplay from "../../../../components/custom-table-data-display/due-date-display";
import ActivityTitleDisplay from "../../../../components/custom-table-data-display/activity-title-display";
import { useSelector } from "react-redux";
import { MarkAsDoneActivity } from "../../../../components/activity/mark_as_done";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { ConvertModal } from "../../../../components/modal";
import { useHistory } from "react-router-dom";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export function EditableActivityTable({ props }) {
  const [edit, setEdit] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [showMarkedAsUndone, setShowMarkAsUndone] = useState(false);
  const [markAsUnDoneId, setMarkAsUnDoneId] = useState("");

  const history = useHistory();

  const activityLossReasonStatus = useSelector((store) => store.activity.lossReasons.settings.enabled);

  const checkAll = () => {
    if (props.checked.length === props.data.length) {
      props.setChecked([]);
    } else {
      let temp = [];
      props.data.map((data) => temp.push(data["_id"]));
      props.setChecked(temp);
    }
  };
  const editChecked = (rowId) => {
    if (!props.checked.includes(rowId)) props.setChecked([...props.checked, rowId]);
    else {
      props.setChecked(props.checked.filter((check) => check !== rowId));
    }
  };

  const editRadioChecked = (rowId, isDirect, isCompleted) => {
    const checkedIds = [];
    setSelectedRadio(rowId);
    if (props.data && Array.isArray(props.data)) {
      props.data.forEach((each) => {
        if (each && each.isCompleted) {
          checkedIds.push(each._id);
        }
      });
    }

    if (isCompleted || props.radioChecked.includes(rowId)) {
      setShowMarkAsUndone(true);
      setMarkAsUnDoneId(rowId);
      // props.callActivityMarkAsUndoneApi(rowId)
      // props.setRadioChecked(props.radioChecked.filter(check => check !== rowId))
    } else if (isDirect) {
      let data = {
        id: rowId,
        reasonId: "",
        comment: "",
        note: "",
      };
      props.callActivityMADoneApi(data);
    } else {
      props.setRadioChecked([...checkedIds, rowId]);
    }
  };

  // Show edit button
  const setHovering = (combinedId, colId) => {
    const colIndex = props.idToIndex[colId];
    if (props.columns[colIndex].isEditable && !editing) {
      setEdit(combinedId);
    }
  };

  // Hide edit button if not editing
  const setNotHovering = () => {
    if (!editing) setEdit(-1);
  };

  const getColumn = (colId, index) => {
    const colIndex = props.idToIndex[colId];
    return (
      <InlineStyled
        as="th"
        key={index}
        className="bg-white pt-0 cursor-pointer text-nowrap position-sticky top-0"
        styles={{
          minWidth: `${100 / props.columns.length}%`,
        }}
      >
        <div className="opacity-1 d-flex flex-row w-100 h-100 align-items-center gap-5px text-nowrap text-black opacity-0-point-8">{props.columns[colIndex].fieldName}</div>
      </InlineStyled>
    );
  };
  const getData = (colId, data) => {
    const activityField = props.columns[props.idToIndex[colId]];
    if (activityField.apiKeyName === "name") {
      return <ActivityTitleDisplay allData={data} page={props.page} pageSub={props.pageSub} dataStatus={data.status} data={data[activityField.apiKeyName]} type={activityField.fieldInputType} />;
    } else if (activityField.apiKeyName === "scheduleAt") {
      return <DueDateDisplay allData={data} page={props.page} pageSub={props.pageSub} dataStatus={data.status} data={data[activityField.apiKeyName]} type={activityField.fieldInputType} />;
    }
    return <CustomDisplay allData={data} page={props.page} pageSub={props.pageSub} dataStatus={data.status} data={data[activityField.apiKeyName]} field={activityField} type={activityField.fieldInputType} />;
  };

  const onLossDealCancel = () => {
    document.body.click();
    props.setRadioChecked(props.radioChecked.filter((check) => check !== selectedRadio));
    setSelectedRadio("");
  };

  const onLossDealSave = (payload) => {
    props.callActivityMADoneApi(payload.data);
    document.body.click();
    if (payload.isNextSchedule) {
      props.scheduleNextActivity(selectedRadio);
    }
  };

  const showLossReasonPopup = (data) => {
    if (!data.isCompleted && activityLossReasonStatus && !_.isEmpty(data.associated) && typeof data.associated === "object") {
      return true;
    }
    return false;
  };

  const markAsUndoneAccept = () => {
    props.callActivityMarkAsUndoneApi(markAsUnDoneId);
    props.setRadioChecked(props.radioChecked.filter((check) => check !== markAsUnDoneId));
    setMarkAsUnDoneId("");
    setShowMarkAsUndone(false);
  };

  const markAsUndoneCancel = () => {
    setMarkAsUnDoneId("");
    setShowMarkAsUndone(false);
  };

  const convertModalProps = {
    show: showMarkedAsUndone,
    title: "Mark as undone",
    buttonText: "Mark as Undone",
    handleClose: markAsUndoneCancel,
    handleAccept: markAsUndoneAccept,
    buttonType: "green",
  };

  const popover = (
    <InlineStyled as={Popover} id="popover-basic" onClick={(e) => e.stopPropagation()} className="z-index-9999 w-400px w-400px-mx" styles={{ transform: "translate(173px, 136px)" }}>
      <Popover.Title>
        <div className="fw-500 font-size-15px text-26292c">Mark As Done</div>
      </Popover.Title>
      <Popover.Content className="p-0 m-0">
        <MarkAsDoneActivity activityId={selectedRadio} showNextSchedule={true} handleCancel={onLossDealCancel} handleSave={onLossDealSave} />
      </Popover.Content>
    </InlineStyled>
  );

  const onTableRowClick = (data) => {
    const { associated } = data;
    if (!_.isEmpty(associated) && typeof associated === "object") {
      if (associated.type === "deal") {
        history.push({ pathname: `/activity/activity-list/${data._id}/deal/${associated._id}`, state: { id: associated._id } });
      } else if (associated.type === "lead") {
        history.push({ pathname: `/activity/activity-list/${data._id}/lead/${associated._id}`, state: { id: associated._id } });
      } else if (associated.type === "people") {
        history.push({ pathname: `/activity/activity-list/${data._id}/people/${associated._id}`, state: { id: associated._id } });
      } else if (associated.type === "company") {
        history.push({ pathname: `/activity/activity-list/${data._id}/company/${associated._id}`, state: { id: associated._id } });
      }
    }
  };

  if (props.loading) return <ContentLoadingWithoutCard />;
  else if (props.showColumns.length === 0)
    return (
      <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px flex-column">
        <h5>No Columns Selected</h5>
        <p>Select columns to display from the column select dropdown</p>
      </div>
    );
  else
    return (
      <>
        <div className="table-responsive overflow-y-auto">
          <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
            <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="opacity-0-point-8 text-black bg-white pt-0 pl-2 w-25px z-index-1 position-sticky top-0">
                    <label className="checkbox checkbox-lg checkbox-single">
                      <input className="form-check-input" type="checkbox" value="1" onChange={() => checkAll()} checked={props.checked.length === props.data.length} />
                      <span></span>
                    </label>
                  </th>
                  <th className="opacity-0-point-8 text-black bg-white pt-0 pl-2 w-25px z-index-1 position-sticky top-0">Done</th>
                  {props.showColumns.map((colId, index) => {
                    return getColumn(colId, index);
                  })}
                </tr>
              </thead>
              <tbody>
                {props.data.map((data, rowIndex) => {
                  return (
                    <tr key={rowIndex} onClick={() => onTableRowClick(data)}>
                      <td className="pl-2 activity-status-color" onClick={(e) => e.stopPropagation()}>
                        <label className="checkbox checkbox-lg checkbox-single">
                          <input className="form-check-input" type="checkbox" value="1" onChange={(e) => editChecked(data["_id"])} checked={props.checked.includes(data["_id"])} />
                          <span></span>
                        </label>
                      </td>
                      <td className="pl-4 done-radio-button">
                        <label className="radio pl-1 mt-3px" onClick={(e) => e.stopPropagation()}>
                          {showLossReasonPopup(data) ? (
                            <OverlayTrigger rootClose={true} onExited={() => onLossDealCancel()} placement="right" trigger="click" className="z-index-0" overlay={popover}>
                              <input
                                className="form-check-input"
                                type="radio"
                                value="123"
                                onClick={() => editRadioChecked(data["_id"], false, data.isCompleted)}
                                checked={props.radioChecked.includes(data["_id"]) || data.isCompleted}
                              />
                            </OverlayTrigger>
                          ) : (
                            <input
                              className="form-check-input"
                              type="radio"
                              value="123"
                              onClick={() => editRadioChecked(data["_id"], true, data.isCompleted)}
                              checked={props.radioChecked.includes(data["_id"]) || data.isCompleted}
                            />
                          )}
                          <span></span>
                        </label>
                      </td>
                      {props.showColumns.map((colId, index) => (
                        <td className="text-nowrap" id={rowIndex.toString() + colId} onMouseOver={() => setHovering(rowIndex.toString() + colId, colId)} onMouseLeave={() => setNotHovering()} key={index}>
                          {props.cellLoading === rowIndex.toString() + colId ? (
                            <div className="w-100 d-flex justify-content-center align-items-center">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          ) : (
                            <div className="d-flex flex-row flex-stack">
                              {getData(colId, data)}
                              <ActivityPopoverEdit
                                edit={edit}
                                combinedId={rowIndex.toString() + colId}
                                cellId={data["_id"]}
                                editing={editing}
                                setEditing={setEditing}
                                setEdit={setEdit}
                                rowIndex={rowIndex}
                                field={props.columns[props.idToIndex[colId]]}
                                colId={colId}
                                initialVal={data[props.columns[props.idToIndex[colId]].apiKeyName]}
                                cellEdit={props.cellEdit}
                                setCellEdit={props.setCellEdit}
                                handleCellEdit={props.handleCellEdit}
                                page={props.page}
                                pageSub={props.pageSub}
                                allData={data}
                              />
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {props.data.length === 0 && (
                  <tr>
                    <th className="p-0 m-0 text-center" colSpan="100%">
                      <CustomEmptyPage page="emptyTable" />
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </PerfectScrollbar>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-15px pt-7px">
          <p className="mt-5px mb-0 font-size-14px">
            Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
          </p>
          {CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), props.setChecked, props.limit, props.setLimit, props.count)}
        </div>
        <ConvertModal modalProps={convertModalProps}>Would you like to mark it as undone? You will lose all previous activity outcomes and time when you marked it as done.</ConvertModal>
      </>
    );
}
