import { getListOfWorkflowTemplates, getWorkflowTemplateByID } from "../types";
const initState = {
  list: {
    data: [],
    count: 0,
    isLoading: false,
    error: false,
  },
  selected: {
    data: {},
    isLoading: false,
    error: false,
  },
};

export default function workflowTemplateReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
        },
      };
    case getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          error: action.payload,
        },
      };

    case getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_REQUEST:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: true,
        },
      };
    case getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_ERROR:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
