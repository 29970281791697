import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import ContentWrapper from "../../Wrapper";
import { appStoreConstants } from "../constants";
import { useHistory } from "react-router-dom";

export default function IntegrationPage() {
  const tabName = "Integrations";
  const links = [];
  const history = useHistory()

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <SubheaderNav links={links} active={tabName} />
        </CustomSubHeader>
      }
    >
      <div className="m-auto w-1080px-mx">
        <Row xs={3} md={3} lg={3} xl={3}>
          {appStoreConstants.map((each, index) => {
            return (
              <Col className="mb-8" key={index}>
                <Card className="p-6 h-100">
                  <div className="d-flex gap-5 align-items-center mb-4">
                    <div>{each.logo}</div>
                    <div className="fs-2 fw-bold ">{each.name}</div>
                  </div>
                  <div className="mb-4 webkit-box-4-line h-85px">{each.description}</div>
                  <div className="d-flex gap-2 align-items-center">
                    {each.isInternal ? <Button onClick={() => history.push(each.buttonURL)} variant={"primary"} size={"sm"}>
                      {each.buttonText}
                    </Button> :
                      <a rel="noopener noreferrer" target={each.isInternal ? "_self" : "_blank"} href={each.buttonURL}>
                        <Button variant={"primary"} size={"sm"}>
                          {each.buttonText}
                        </Button>
                      </a>}
                    {each.buttonText2 && each.buttonUrl2 && (
                      <a rel="noopener noreferrer" target={"_blank"} href={each.buttonUrl2}>
                        <Button variant={"link"} size={"sm"}>
                          {each.buttonText2}
                        </Button>
                      </a>
                    )}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        <div className="mt-10">
          <zapier-full-experience client-id="Z9GJsbBMiBCqzlyfLDqOhteHfaknuWiVIIJp0Bqb" theme="light" app-search-bar-display="show" />
        </div>
      </div>
    </ContentWrapper>
  );
}
