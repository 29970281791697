// Profile Settings
export const getEmailProfileDetails = {
  GET_EMAIL_PROFILE_DETAILS_REQUEST: "GET_EMAIL_PROFILE_DETAILS_REQUEST",
  GET_EMAIL_PROFILE_DETAILS_SUCCESS: "GET_EMAIL_PROFILE_DETAILS_SUCCESS",
  GET_EMAIL_PROFILE_DETAILS_ERROR: "GET_EMAIL_PROFILE_DETAILS_ERROR",
};

export const updateEmailProfileSettings = {
  UPDATE_EMAIL_PROFILE_SETTINGS_REQUEST: "UPDATE_EMAIL_PROFILE_SETTINGS_REQUEST",
  UPDATE_EMAIL_PROFILE_SETTINGS_SUCCESS: "UPDATE_EMAIL_PROFILE_SETTINGS_SUCCESS",
  UPDATE_EMAIL_PROFILE_SETTINGS_ERROR: "UPDATE_EMAIL_PROFILE_SETTINGS_ERROR",
};

export const getEmailAccountReputations = {
  GET_EMAIL_ACCOUNT_REPUTATIONS_REQUEST: "GET_EMAIL_ACCOUNT_REPUTATIONS_REQUEST",
  GET_EMAIL_ACCOUNT_REPUTATIONS_SUCCESS: "GET_EMAIL_ACCOUNT_REPUTATIONS_SUCCESS",
  GET_EMAIL_ACCOUNT_REPUTATIONS_ERROR: "GET_EMAIL_ACCOUNT_REPUTATIONS_ERROR",
};

export const getEmailAccountStatus = {
  GET_EMAIL_ACCOUNT_STATUS_REQUEST: "GET_EMAIL_ACCOUNT_STATUS_REQUEST",
  GET_EMAIL_ACCOUNT_STATUS_SUCCESS: "GET_EMAIL_ACCOUNT_STATUS_SUCCESS",
  GET_EMAIL_ACCOUNT_STATUS_ERROR: "GET_EMAIL_ACCOUNT_STATUS_ERROR",
};

// Domain Settings
export const getListOfEmailDomainSettings = {
  GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_REQUEST: "GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_REQUEST",
  GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_SUCCESS: "GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_SUCCESS",
  GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_ERROR: "GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_ERROR",
};

export const addDomainSettings = {
  ADD_DOMAIN_SETTINGS_REQUEST: "ADD_DOMAIN_SETTINGS_REQUEST",
  ADD_DOMAIN_SETTINGS_SUCCESS: "ADD_DOMAIN_SETTINGS_SUCCESS",
  ADD_DOMAIN_SETTINGS_ERROR: "ADD_DOMAIN_SETTINGS_ERROR",
};

export const deleteEmailDomainSettings = {
  DELETE_EMAIL_DOMAIN_SETTINGS_REQUEST: "DELETE_EMAIL_DOMAIN_SETTINGS_REQUEST",
  DELETE_EMAIL_DOMAIN_SETTINGS_SUCCESS: "DELETE_EMAIL_DOMAIN_SETTINGS_SUCCESS",
  DELETE_EMAIL_DOMAIN_SETTINGS_ERROR: "DELETE_EMAIL_DOMAIN_SETTINGS_ERROR",
};

export const addEmailToDomainSettings = {
  ADD_EMAIL_TO_DOMAIN_SETTINGS_REQUEST: "ADD_EMAIL_TO_DOMAIN_SETTINGS_REQUEST",
  ADD_EMAIL_TO_DOMAIN_SETTINGS_SUCCESS: "ADD_EMAIL_TO_DOMAIN_SETTINGS_SUCCESS",
  ADD_EMAIL_TO_DOMAIN_SETTINGS_ERROR: "ADD_EMAIL_TO_DOMAIN_SETTINGS_ERROR",
};

export const verifyDKIM = {
  VERIFY_DKIM_REQUEST: "VERIFY_DKIM_REQUEST",
  VERIFY_DKIM_SUCCESS: "VERIFY_DKIM_SUCCESS",
  VERIFY_DKIM_ERROR: "VERIFY_DKIM_ERROR",
};

export const verifyDMARC = {
  VERIFY_DMARC_REQUEST: "VERIFY_DMARC_REQUEST",
  VERIFY_DMARC_SUCCESS: "VERIFY_DMARC_SUCCESS",
  VERIFY_DMARC_ERROR: "VERIFY_DMARC_ERROR",
};

export const verifyTracking = {
  VERIFY_TRACKING_REQUEST: "VERIFY_TRACKING_REQUEST",
  VERIFY_TRACKING_SUCCESS: "VERIFY_TRACKING_SUCCESS",
  VERIFY_TRACKING_ERROR: "VERIFY_TRACKING_ERROR",
};

export const verifySPF = {
  VERIFY_SPF_REQUEST: "VERIFY_SPF_REQUEST",
  VERIFY_SPF_SUCCESS: "VERIFY_SPF_SUCCESS",
  VERIFY_SPF_ERROR: "VERIFY_SPF_ERROR",
};

export const LOADING_DATA_UPDATE = "LOADING_DATA_UPDATE_REQUEST";
