import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

//containers
import DealPipeline from "./dealPipeLine";
import DealForeCast from "./dealForeCast";
import DealList from "./dealList/dealList";
import DealConversion from "./dealConversion";

export default function LeadBot(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/pipeline`} component={DealPipeline} />
      <Route exact path={`${props.match.path}/pipeline/:pipelineId`} component={DealPipeline} />
      <Route exact path={`${props.match.path}/pipeline/:pipelineId/:dealId`} component={DealPipeline} />
      <Route exact path={`${props.match.path}/pipeline/:pipelineId/:moduleName/:linkedId`} component={DealPipeline} />

      <Route exact path={`${props.match.path}/deal-list`} component={DealList} />
      <Route exact path={`${props.match.path}/deal-list/:pipelineId`} component={DealList} />
      <Route exact path={`${props.match.path}/deal-list/:pipelineId/:dealId`} component={DealList} />
      <Route exact path={`${props.match.path}/deal-list/:pipelineId/:moduleName/:linkedId`} component={DealList} />

      <Route exact path={`${props.match.path}/fore-cast`} component={DealForeCast} />
      <Route exact path={`${props.match.path}/conversion`} component={DealConversion} />
      <Route exact path={`${props.match.path}/conversion/:id`} component={DealConversion} />
      <Redirect to={`${props.match.path}/pipeline`} />
    </Switch>
  );
}
