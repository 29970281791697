import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GreenGradientButton } from "../../../../../components/button";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { getConvertToJunkByIdRequest } from "../../actions/convert_to_junk_action";

export default function LeadToJunkForm(props) {
  const [loading, setLoading] = useState(false);
  const [lossReasonOptions, setLossReasonOptions] = useState([]);
  const [selectedLossReason, setSelectedLossReason] = useState({});
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const lostReasons = useSelector((state) => state.Leads.lostReasons);

  useEffect(() => {
    if (Array.isArray(lostReasons)) {
      const reasons = lostReasons.map((each) => {
        return { value: each._id, label: each.reason };
      });
      setLossReasonOptions(reasons);
    }
  }, [lostReasons]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActionData) && props.selectedActionData.id) {
      setLoading(true);
      dispatch(
        getConvertToJunkByIdRequest({ id: props.selectedActionData.id }, (data) => {
          if (data.success) {
            setComment(data.data.comment);
            lossReasonOptions.forEach((each) => {
              if (each.value === data.data.leadLostReasonId) setSelectedLossReason(each);
            });
          }
          setLoading(false);
        }),
      );
    }
  }, [dispatch, props.selectedActionData, props.isEdit, lossReasonOptions]);

  const handleSave = () => {
    if (_.isEmpty(selectedLossReason)) {
      return dispatchSnackbarError("Loss reason can't be empty");
    }
    const data = {
      module: {
        name: props.moduleName,
      },
      leadLostReasonId: selectedLossReason.value,
      comment: comment,
    };
    if (props.isEdit) props.handleEdit(props.selectedActionData.id, data);
    else props.handleSave(data);
  };

  if (loading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center h-vh-162px">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <Form.Group id="automation-forms-lead-to-junk-form">
      <Form.Group id="automation-forms-lead-to-junk-form-select-lost-reason" required>
        <Form.Label aria-label="select-lost-reason">Select Lost Reason *</Form.Label>
        <SimpleDropdown value={selectedLossReason} label={"Select Lost Reason"} options={lossReasonOptions} handleSelect={(a) => setSelectedLossReason(a)} />
      </Form.Group>
      <Form.Group id="automation-forms-lead-to-junk-form-comments" className="mb-3">
        <Form.Label aria-label="comments">Comments *</Form.Label>
        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
      </Form.Group>
      <Form.Group id="automation-forms-lead-to-junk-form-save-button" className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={() => handleSave()} disabled={false}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </Form.Group>
  );
}
