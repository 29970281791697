import { getNotification, postNotification } from "../../types/profile-settings.types";

export function getNotificationRequest(cb) {
  return {
    type: getNotification.GET_NOTIFICATION_REQUEST,
    cb,
  };
}
export function getNotificationSuccess(res) {
  return {
    type: getNotification.GET_NOTIFICATION_SUCCESS,
    payload: res,
  };
}
export function getNotificationError(err) {
  return {
    type: getNotification.GET_NOTIFICATION_ERROR,
    payload: err,
  };
}

export function postNotificationRequest(req, cb) {
  return {
    type: postNotification.POST_NOTIFICATION_REQUEST,
    payload: req,
    cb,
  };
}
export function postNotificationSuccess(res) {
  return {
    type: postNotification.POST_NOTIFICATION_SUCCESS,
    payload: res,
  };
}
export function postNotificationError(err) {
  return {
    type: postNotification.POST_NOTIFICATION_ERROR,
    payload: err,
  };
}
