import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import * as customFieldActions from "../actions/custom-fields.actions";
// import * as settingsActions from "../actions/settings.actions";
import { EDIT_SYSTEM_FIELDS_REQUEST, GET_ADD_FORM_FIELDS_REQUEST, REORDER_ADD_FORM_FIELDS_REQUEST } from "../types/custom-fields.types";
import * as dealListActions from "../../deals/dealList/actions";

function* editSystemFields(action) {
  try {
    const response = yield call(api.patch, "/api/crm/data-fields/system-edit", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(customFieldActions.editSystemFieldsSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(customFieldActions.editSystemFieldsError(response.data));
    } else {
      dispatchSnackbarError("Error while editing system fields");
      yield put(customFieldActions.editSystemFieldsError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while editing system fields");
    yield put(customFieldActions.editSystemFieldsError(e));
  }
}

function* getAddFormFields(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/add-form-fields", action.payload);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message)
      yield put(customFieldActions.getAddFormFieldsSuccess(response.data));
      if (action.payload.fieldType === "DEAL") {
        yield put(dealListActions.getDealAddFormFieldsRequest({ request: { fieldType: "DEAL" }, skipIfDataPresent: false }));
      }
    } else if (response.status === 400) {
      // dispatchSnackbarError(response.data.message)
      yield put(customFieldActions.getAddFormFieldsError(response.data));
    } else {
      // dispatchSnackbarError("Error while reordering")
      yield put(customFieldActions.getAddFormFieldsError("error"));
    }
  } catch (e) {
    // dispatchSnackbarError("Error while reordering")
    yield put(customFieldActions.getAddFormFieldsError(e));
  }
}

function* reorderAddFormFields(action) {
  try {
    const response = yield call(api.patch, "/api/crm/data-fields/reorder-add-form", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(customFieldActions.reorderAddFormFieldsSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(customFieldActions.reorderAddFormFieldsError(response.data));
    } else {
      dispatchSnackbarError("Error while reordering");
      yield put(customFieldActions.reorderAddFormFieldsError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while reordering");
    yield put(customFieldActions.reorderAddFormFieldsError(e));
  }
}

export default function*() {
  //custom fields new saga
  yield takeLatest(EDIT_SYSTEM_FIELDS_REQUEST, editSystemFields);
  yield takeLatest(GET_ADD_FORM_FIELDS_REQUEST, getAddFormFields);
  yield takeLatest(REORDER_ADD_FORM_FIELDS_REQUEST, reorderAddFormFields);
}
