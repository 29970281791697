import React, { useEffect, useState } from "react";
import moment from "moment";
import ContentWrapper from "../../../../Wrapper";
import CampaignSubHeader from "../../../components/campaign-common-sub-header";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignReportLogRequest } from "../../../store/actions/campaign_report_actions";
import AdvancedLogFilter from "../components/advanced-log-filter";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { campaignOptionObj } from "../../../constants";
import { NormalTable } from "../../../../../components/table/normalTable";

export default function AdvancedLogs(props) {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  // filter section
  const [channel, setChannel] = useState("email");
  const [type, setType] = useState("all");
  const [fromDate, setFromDate] = useState(
    moment()
      .startOf("days")
      .valueOf(),
  );
  const [toDate, setToDate] = useState(
    moment()
      .add(1, "day")
      .endOf("day")
      .valueOf(),
  );
  const [search, setSearch] = useState("");
  const [initalFilterValue, setInitialFilterValue] = useState({
    channel: "email",
    type: "all",
    fromDate: moment()
      .startOf("days")
      .valueOf(),
    toDate: moment()
      .add(1, "day")
      .endOf("day")
      .valueOf(),
    search: "",
  });

  const dispatch = useDispatch();

  const campaignReportLogStore = useSelector((store) => store.campaign.reports.email.reportLog);

  useEffect(() => {
    if (fromDate && toDate) {
      const payload = {
        startDate: moment(fromDate).valueOf(),
        endDate: moment(toDate).valueOf(),
        search: search,
        type: type,
        channel: channel,
        pageNo: page,
        limit: limit,
      };
      dispatch(getCampaignReportLogRequest(payload));
    }
  }, [dispatch, fromDate, toDate, search, type, channel, page, limit]);

  useEffect(() => {
    const campaignChannel = localStorage.getItem("campaign-channel");
    const campaignType = localStorage.getItem("campaign-type");

    if (campaignChannel && campaignType) {
      setChannel(campaignChannel);
      setType(campaignType);
      setInitialFilterValue({
        channel: campaignChannel,
        type: campaignType,
        fromDate: moment()
          .startOf("days")
          .valueOf(),
        toDate: moment()
          .add(1, "day")
          .endOf("day")
          .valueOf(),
        search: "",
      });
    }
  }, []);

  const handleApplyFilter = (data) => {
    setChannel(data.channel);
    setType(data.type);
    setFromDate(data.fromDate);
    setToDate(data.toDate);
    setSearch(data.search);
    setShow(false);
    setPage(1);
    setLimit(15);
    setInitialFilterValue(data);
    localStorage.setItem("campaign-channel", data.channel);
    localStorage.setItem("campaign-type", data.type);
  };

  const handleCancelFilter = () => {
    setShow(false);
  };
  const columns = {
    email: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "emailAddress", fieldInputType: "INPUT", fieldName: "Email" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    ],
    sms: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "route", fieldInputType: "INPUT", fieldName: "Route" },
      { apiKeyName: "senderId", fieldInputType: "INPUT", fieldName: "Sender ID" },
      { apiKeyName: "phone", fieldInputType: "INPUT", fieldName: "Phone" },
      { apiKeyName: "message", fieldInputType: "INPUT", fieldName: "Message" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "totalsms", fieldInputType: "INPUT", fieldName: "Total SMS" },
      { apiKeyName: "cost", fieldInputType: "INPUT", fieldName: "Cost" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
      { apiKeyName: "remark", fieldInputType: "INPUT", fieldName: "Remark" },
    ],
    whatsapp: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "phone", fieldInputType: "INPUT", fieldName: "Phone" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Last Response At" },
      { apiKeyName: "cost", fieldInputType: "INPUT", fieldName: "Cost" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
      { apiKeyName: "failedReason", fieldInputType: "INPUT", fieldName: "Remark" },
    ],
    rcs: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "phone", fieldInputType: "INPUT", fieldName: "Phone" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    ],
  };

  const tableProps = {
    columns: columns[channel],
    data: campaignReportLogStore.data,
    pagination: true,
    count: campaignReportLogStore.count,
    limit: limit,
    pageNo: page,
    setLimit: setLimit,
    setPageNo: setPage,
    loading: campaignReportLogStore.loading,
    setChecked: () => { },
  };

  return (
    <ContentWrapper
      subHeader={
        <CampaignSubHeader active={props.tabName}>
          <div className="d-flex gap-3 align-items-center">
            {type && <div className="badge badge-light-primary fs-7">{campaignOptionObj[type]}</div>}
            {channel && <div className="badge badge-light-primary fs-7">{campaignOptionObj[channel]}</div>}
            {fromDate && toDate && (
              <div className="badge badge-light-primary fs-7">
                {moment(fromDate).format("MMM DD")}
                {" - "}
                {moment(toDate).format("MMM DD")}
              </div>
            )}
            <AdvancedLogFilter show={show} setShow={setShow} filterValue={initalFilterValue} handleApplyFilter={handleApplyFilter} handleCancelFilter={handleCancelFilter} />
          </div>
        </CampaignSubHeader>
      }
    >
      {campaignReportLogStore.loading ? (
        <ContentWithCardLoading />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <NormalTable props={tableProps} />
          </div>
        </div>
      )}
    </ContentWrapper>
  );
}
