import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CustomSubHeader } from "../../components/subheader";
import { DeleteModal } from "../../components/modal";
import DealPipelineWrapper from "./dealPipeline/index";
import { AddDealModal } from "./common/dealModal/index";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as dealActions from "./dealPipeline/actions";
// import DealWonIcon from '../../images/won91.gif';
import "./styles.css";
import { dispatchSnackbarWarning } from "../../utils/toaster";
import * as ActivityLisActions from "../../containers/Activities/store/actions/activitity_list_actions";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

//actions
import { getActivtyDataFieldsRequest } from "../Activities/store/actions/activity_data_field_actions";
import * as ActivityOperationsActions from "../Activities/store/actions/activity_operations_actions";
import * as LeadActions from "../leads/store/actions/actions";
import * as PeopleActions from "../customers/store/actions/actions";
import * as CompanyActions from "../companies/store/actions/actions";
import * as ProductActions from "../products/store/actions/actions";
import * as insightActions from "../insights/actions";
import * as TemplateActions from "../campaign/store/actions/campaign_templates_actions";

import * as ActivityLossReasonActions from "../Activities/store/actions/activity_loss_reasons_actions";
import { getListOfActiveActivityTypesRequest } from "../Activities/store/actions/activity_types_actions";
import * as dealListActions from "./dealList/actions";
import { getIntegrationAccountDetailsRequest } from "../Settings/actions/integrations.actions";
import ContentWrapper from "../Wrapper";
import DealSubHeader from "./components/deal-common.subheader";
import { productSearchRequest } from "../products/store/actions/actions";
import SidePannelDetailed from "../../components/side-pannel-detailed";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { GridViewSVG, ListViewSVG } from "../../components/custom-svgs/sub-header-svg";
import AddEditPipelineModelV2 from "./components/add-pipeline";

export default function DealPipeline(props) {
  const [links, setLinks] = useState([
    { name: "Pipeline View", to: "/deal/pipeline", icon: "bi bi-distribute-horizontal", svg: GridViewSVG },
    { name: "List View", to: "/deal/deal-list", icon: "bi bi-list", svg: ListViewSVG },
  ]);
  const tabName = "Pipeline";
  const page = "Deals";
  const pageSub = "deal";
  const [openAddDealModal, setOpenAddDealModal] = useState(false);
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState("Choose pipeline");
  const [selectedPipelineId, setSelectedPipelineId] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "deal",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
  });
  const [sidePanelProps, setSidePanelProps] = useState([]);
  const [showWonDealIcon, setshowWonDealIcon] = useState(false);
  const [filterOption, setFilterOption] = useState("Open");
  const [dealDeleteShow, setDealDeleteShow] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePannelDealId, setSidePannelDealId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [linkedModuleId, setLinkedModuleId] = useState("");
  const [linkedModuleName, setLinkedModuleName] = useState("");

  // add and edit pipeline
  const [showAddPipelineModal, setShowAddPipelineModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editPipelineId, setEditPipelineId] = useState("");

  //filters
  const [virtualFilter, setVirtualFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");
  const [filterId, setFilterId] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { width, height } = useWindowSize();

  const filterStore = useSelector((state) => state.DealPipeline.dealFilters);
  const dealTagStore = useSelector((state) => state.DealPipeline.dealTags.list);
  const pipelineStore = useSelector((store) => store.DealPipeline);
  const dealSidepanelStore = useSelector((store) => store.DealPipeline.dealPopup);
  const loadingStore = useSelector((state) => state.DealPipeline.loadingData);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const zoomaccountInfo = useSelector((state) => state.Integrations.integrationAccountDetails);
  const activityLossReasonStatus = useSelector((store) => store.activity.lossReasons.settings.enabled);
  const activityLossReasonStore = useSelector((store) => store.activity.lossReasons.reasons.list);

  useEffect(() => {
    if (selectedPipelineId) {
      const payload = {
        pipelineId: selectedPipelineId,
        tags: tags,
        state: filterOption,
        owner: owner,
        filterId: filterId,
      };
      if (!_.isEmpty(virtualFilter)) {
        payload["customFilter"] = JSON.stringify(virtualFilter);
      }
      dispatch(dealActions.getDealRequest(payload));
      dispatch(dealActions.updateDealPipelineLoadingData(payload));
    }
  }, [dispatch, selectedPipelineId, virtualFilter, filterOption, tags, owner, filterId]);

  useEffect(() => {
    dispatch(ActivityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: true }));
    dispatch(ActivityLossReasonActions.getActivityLossReasonsRequest({ skipIfDataPresent: true }));
    dispatch(getListOfActiveActivityTypesRequest());
    dispatch(dealActions.getLostReasonsRequest());
    dispatch(getIntegrationAccountDetailsRequest({ apptype: "ZOOM-MEETING" }));
    dispatch(dealActions.getPipelineRequest());
    dispatch(dealActions.getSavedFiltersRequest());
    dispatch(dealActions.dealSearchRequest(""));
    dispatch(productSearchRequest(""));
    dispatch(getActivtyDataFieldsRequest({ skipIfDataPresent: true }));
    dispatch(dealListActions.getDealListFieldsRequest({ skipIfDataPresent: true }));

    //data field apis (Need to remove Redendent APIs)
    dispatch(LeadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));

    dispatch(PeopleActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(PeopleActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));

    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    // common apis for all module
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  //setting pipeline options
  useEffect(() => {
    setPipelineOptions(pipelineStore.pipelineOptionsResponse);
    setshowWonDealIcon(pipelineStore.dealmarkedAsDone);
  }, [pipelineStore, sidePanelProps]);

  useEffect(() => {
    if (params.linkedId && params.moduleName) {
      setLinkedModuleId(params.linkedId);
      setLinkedModuleName(params.moduleName);
      setSidePannelDealId("");
      setOpenPanel(true);
    } else if (params.pipelineId && params.dealId) {
      setSidePannelDealId(params.dealId);
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(true);
    } else {
      setSidePannelDealId("");
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    if (params.pipelineId) {
      setLinks([
        { name: "Pipeline View", to: `/deal/pipeline/${params.pipelineId}`, icon: "bi bi-distribute-horizontal", svg: GridViewSVG },
        { name: "List View", to: `/deal/deal-list/${params.pipelineId}`, icon: "bi bi-list", svg: ListViewSVG },
      ]);
    }
  }, [params.pipelineId]);

  useEffect(() => {
    if (pipelineStore.dealsDataResponse) {
      const _data = [];
      pipelineStore.dealsDataResponse.forEach((each) => {
        each.deals.forEach((_each) => {
          _data.push({ module: "deal", id: _each._id });
        });
      });
      setNextPreviousData(_data);
    }
  }, [pipelineStore.dealsDataResponse]);

  //setting won deal icon timeout
  useEffect(() => {
    let timer1;
    if (showWonDealIcon) {
      timer1 = setTimeout(() => {
        setshowWonDealIcon(false);
        dispatch(dealActions.hideWonDealIcon(false));
      }, 6 * 1000);
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [showWonDealIcon, dispatch]);

  useEffect(() => {
    if (params.pipelineId) {
      const _selectedPipeline = pipelineOptions.find((a) => a._id === params.pipelineId);
      if (_selectedPipeline) {
        setSelectedPipeline(_selectedPipeline.name);
        setSelectedPipelineId(_selectedPipeline._id);
        dispatch(dealActions.getStagesRequest({ pipelineId: _selectedPipeline._id }));
        dispatch(dealActions.getDealRequest({ pipelineId: _selectedPipeline._id }));
      }
    } else {
      const defaultPipeline = pipelineOptions.find((a) => a.isDefault);
      if (defaultPipeline) {
        history.push(`/deal/pipeline/${defaultPipeline._id}`);
      }
    }
  }, [dispatch, params, pipelineOptions, history]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("dealOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("dealOwner", owner);
    else localStorage.removeItem("dealOwner");
  }, [owner]);

  const handleAddDeal = () => {
    setOpenAddDealModal(true);
  };

  const onAddPipelineClick = () => {
    if (accountBillingStore.data.servicesOffered.PIPELINES && accountBillingStore.data.servicesOffered.PIPELINES !== "unlimited") {
      if (pipelineStore.pipelineOptionsResponse.length < accountBillingStore.data.servicesOffered.PIPELINES) {
        setShowAddPipelineModal(true);
        setIsEdit(false);
        setEditPipelineId("");
      } else {
        dispatchSnackbarWarning("You have reached the max number of pipeline limit. Please upgrade your plan to create more pipelines.", "warning");
      }
    } else {
      setShowAddPipelineModal(true);
      setIsEdit(false);
      setEditPipelineId("");
    }
  };

  const onPipelineChange = (pipeline, index) => {
    setSelectedPipeline(pipeline.name);
    setSelectedPipelineId(pipeline.id);
    setTags([]);
    history.push(`/deal/pipeline/${pipeline.id}`);

    dispatch(
      dealActions.getStagesRequest({
        pipelineId: pipeline.id,
      }),
    );
  };

  const onAddDealModalClose = () => {
    setOpenAddDealModal(false);
  };

  const onAddDealModalSave = (data) => {
    setFilterOption("Open");
    setTags([]);
    dispatch(dealActions.addDealRequest({ data, filter: loadingStore, pageForm: "dealPipeline" }));
    setOpenAddDealModal(false);
  };

  const callDealOperationApi = (data) => {
    dispatch(
      dealActions.dealOperationRequest({
        ...data,
        filter: loadingStore,
        pageForm: "dealPipeline",
      }),
    );
  };

  const callMoveStagesApi = (data) => {
    dispatch(
      dealActions.moveDealInStagesRequest({
        ...data,
        filter: loadingStore,
        pageForm: "dealPipeline",
      }),
    );
  };

  const callDealPopupDetailsApi = (id, value) => {
    dispatch(dealActions.getDealPopupDetailsRequest(id));
  };

  const callActivityMADoneApi = (data) => {
    dispatch(ActivityOperationsActions.markAsDoneRequest({ data: data, filter: loadingStore, pageForm: "dealPipeline" }));
  };

  const callCreateActivityApi = (data) => {
    dispatch(ActivityLisActions.createActivityRequest({ activityData: data.data, from: "deal-pipeline", pipelineId: selectedPipelineId, dealId: data.dealId }));
  };

  const callDealEditInSidepanelApi = (data) => {
    const payload = {
      data: data,
      filter: loadingStore,
      pageForm: "dealPipeline",
    };
    dispatch(dealActions.changeDealDetailsInPopupRequest(payload));
  };

  const callPersonOrCompanyChange = (data, type) => {
    if (type === "person") {
      dispatch(dealActions.changeDealPersonRequest(data));
    } else {
      dispatch(dealActions.changeDealCompanyRequest(data));
    }
  };

  const updateSidePanelProps = (data) => {
    setSidePanelProps(data);
  };

  const onPipelineDeletePipeline = () => {
    dispatch(
      dealActions.deletePipelineRequest({
        pipelineId: selectedPipelineId,
        newPipelineId: pipelineOptions[0]._id,
      }),
    );
    setSelectedPipeline(pipelineOptions[0].name);
    setSelectedPipelineId(pipelineOptions[0]._id);
    setShowDelete(false);
  };

  const getPipelineOptions = () => {
    let data = pipelineStore.pipelineOptionsResponse;
    let pipelineArray = [];
    data && data.length > 0 && data.map((pipeline) => pipelineArray.push({ name: pipeline.name, id: pipeline._id, isDefault: pipeline.isDefault }));
    return pipelineArray;
  };

  const onPipelineEditIconClick = (data) => {
    setShowAddPipelineModal(true);
    setIsEdit(true);
    setEditPipelineId(data.id);
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
    // setFilterOption("All Deals");
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
    // setFilterOption("All Deals");
  };

  const favoriteFilter = (data) => {
    dispatch(dealActions.favoriteSavedFiltersRequest(data));
  };

  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      dispatch(
        dealActions.addSavedFiltersRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        dealActions.addSavedFiltersRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData({
      filterModule: "deal",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    dispatch(
      dealActions.editSavedFiltersRequest(temp, (data) => {
        if (data.status && temp.id === filterId) applyFilter(filterId);
      }),
    );
    setAddFilterFormData({
      filterModule: "deal",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const deleteFilter = (id) => {
    if (filterId === id) dispatch(dealActions.clearFilter());
    dispatch(dealActions.deleteSavedFiltersRequest(id));
    setAddFilterFormData({
      filterModule: "deal",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const addDealTag = (name, colorCode) => {
    dispatch(dealActions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
  };

  const editDealTag = (id, name, colorCode) => {
    dispatch(dealActions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteDealTag = (id) => {
    dispatch(dealActions.deleteDealTagRequest(id));
  };

  const addPeopleTag = (name, colorCode) => {
    dispatch(PeopleActions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editPeopleTag = (id, name, colorCode) => {
    dispatch(PeopleActions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deletePeopleTag = (id) => {
    dispatch(PeopleActions.peopleTagsDeleteRequest(id));
  };

  const setAsDefault = (pipeline) => {
    dispatch(dealActions.markAsDefaultRequest({ id: pipeline.id }));
  };

  const onTagFilterSelection = (_tag) => {
    if (tags[0] === _tag[0]) {
      setTags([]);
    } else {
      setTags(_tag);
    }
  };

  const onPipelineStateFilterClick = (value) => {
    setFilterOption(value);
  };

  const handlePanelClose = (value) => {
    history.push(`/deal/pipeline/${selectedPipelineId}`);
    setOpenPanel(value);
  };
  const onMarkAsArchive = () => {
    const payload = {
      data: { dealId: params.dealId },
      type: "archive",
      pipelineId: selectedPipelineId,
      filter: loadingStore,
      pageForm: "dealList",
    };
    dispatch(dealActions.dealOperationRequest(payload));
    setOpenPanel(false);
    setSidePannelDealId("");
  };

  const onDelete = () => {
    setDealDeleteShow(true);
  };

  const onDeleteAcceptClick = () => {
    callDealOperationApi({
      data: { dealIds: params.dealId },
      type: "bulk-delete",
      pipelineId: selectedPipelineId,
    });
    setDealDeleteShow(false);
    setOpenPanel(false);
    setSidePannelDealId("");
  };
  const onConvertToLeadClick = () => {
    callDealOperationApi({
      data: { dealId: params.dealId },
      type: "deal-to-lead",
      pipelineId: selectedPipelineId,
      from: "deal",
    });
    setOpenPanel(false);
    setSidePannelDealId("");
  };

  return (
    <div>
      {!pipelineStore.loading && pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length === 0 ? (
        <ContentWrapper subHeader={<CustomSubHeader />}>
          <CustomEmptyPage page="pipeline" setShowAdd={onAddPipelineClick} />
        </ContentWrapper>
      ) : (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <DealSubHeader
                links={links}
                page={page}
                pageSub={pageSub}
                tabName={tabName}
                tags={tags}
                addTag={addDealTag}
                editTag={editDealTag}
                deleteTag={deleteDealTag}
                filterOption={filterOption}
                handleAddDeal={handleAddDeal}
                onTagFilterSelection={onTagFilterSelection}
                onPipelineStateFilterClick={onPipelineStateFilterClick}
                //owner dropdown
                owner={owner}
                setOwner={setOwner}
                //smart views
                activeFilter={filterStore.activeFilter}
                customFilters={filterStore.list}
                loading={filterStore.loading}
                setAddFilterFormData={setAddFilterFormData}
                virtualFilter={virtualFilter}
                applyVirtualFilter={applyVirtualFilter}
                selectedFilterId={filterId}
                favoriteFilter={favoriteFilter}
                applyFilter={applyFilter}
                addFilterFormData={addFilterFormData}
                addFilter={addFilter}
                editFilter={editFilter}
                deleteFilter={deleteFilter}
                selectedPipelineId={selectedPipelineId}
                //pipeline select
                show={show}
                setShow={setShow}
                selectedPipeline={selectedPipeline}
                options={getPipelineOptions()}
                onPipelineChange={onPipelineChange}
                setAsDefault={setAsDefault}
                onAddPipelineClick={onAddPipelineClick}
                onPipelineEditIconClick={onPipelineEditIconClick}
              />
            </CustomSubHeader>
          }
        >
          <div className="overflow-x-scroll bg-none box-shadow-none stages-wrapper w-100 h-100">
            {pipelineStore.stagesLoading ? (
              <div className="w-100 d-flex justify-content-center align-items-center h-100 mt-20-percent">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <DealPipelineWrapper
                pipeLineProps={props}
                pipelinedropdownOptions={getPipelineOptions()}
                pipelineswithstages={pipelineStore.pipelineOptionsResponse}
                selectedPipeline={
                  selectedPipeline !== "Choose pipeline" ? selectedPipeline : pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0].name : ""
                }
                selectedPipelineId={
                  selectedPipelineId !== "" ? selectedPipelineId : pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0]._id : ""
                }
                stages={pipelineStore.stagesData.data}
                items={pipelineStore.dealsDataResponse}
                afterLoad={pipelineStore.afterLoad}
                onAddDealModalSave={(data) => onAddDealModalSave(data)}
                callDealOperationApi={(data) => callDealOperationApi(data)}
                callMoveStagesApi={(data) => callMoveStagesApi(data)}
                callDealPopupDetailsApi={(value, id) => callDealPopupDetailsApi(value, id)}
                callActivityMADoneApi={(data) => callActivityMADoneApi(data)}
                callCreateActivityApi={(data) => callCreateActivityApi(data)}
                callDealEditInSidepanelApi={(data) => callDealEditInSidepanelApi(data)}
                callPersonOrCompanyChange={(data, type) => callPersonOrCompanyChange(data, type)}
                lostReasons={pipelineStore.lostReasons}
                dealPopupDetails={pipelineStore.dealpopupDetails}
                setSidePanelProps={updateSidePanelProps}
                activityLostReasons={activityLossReasonStore}
                sidePanelProps={dealSidepanelStore.data}
                loading={dealSidepanelStore.loading}
                cellLoading={dealSidepanelStore.cellLoading}
                dealsLoading={pipelineStore.dealsLoading}
                ownerId={userProfile && userProfile.data && userProfile.data.userId}
                addTag={addDealTag}
                editTag={editDealTag}
                deleteTag={deleteDealTag}
                addPeopleTag={addPeopleTag}
                editPeopleTag={editPeopleTag}
                deletePeopleTag={deletePeopleTag}
                lostReasonStatus={activityLossReasonStatus}
                zoomaccountInfo={zoomaccountInfo}
                filter={loadingStore}
              />
            )}
          </div>
          {showWonDealIcon ? (
            <div>
              <Confetti width={width} height={height} />
              <div className="position-fixed right-35-percent bottom-10-percent">
                <i onClick={() => setshowWonDealIcon(false)} className="bi bi-x-circle-fill position-absolute right-minus-18px top-minus-10px" aria-hidden="true"></i>
                {/* <img src={DealWonIcon} alt="deal-won" /> */}
              </div>
            </div>
          ) : null}
        </ContentWrapper>
      )}
      <AddEditPipelineModelV2
        page={"pipeline"}
        isEdit={isEdit}
        editPipelineId={editPipelineId}
        showAddPipelineModal={showAddPipelineModal}
        setIsEdit={setIsEdit}
        setEditPipelineId={setEditPipelineId}
        setShowAddPipelineModal={setShowAddPipelineModal}
      />
      <AddDealModal
        dealModalProps={{
          openAddDealModal: openAddDealModal,
          onAddDealModalClose: () => onAddDealModalClose(),
          onAddDealModalSave: (data) => onAddDealModalSave(data),
          pipelinedropdownOptions: getPipelineOptions(),
          pipelineswithstages: pipelineStore.pipelineOptionsResponse,
          selectedPipeline: selectedPipeline ? selectedPipeline : pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0].name : "",
          selectedPipelineId: selectedPipelineId ? selectedPipelineId : pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0]._id : "",
          ownerId: userProfile && userProfile.data && userProfile.data.userId,
          tags: dealTagStore,
        }}
      />
      <DeleteModal
        modalProps={{
          show: showDelete,
          title: "Delete Pipeline",
          handleClose: () => {
            setShowDelete(false);
          },
          handleAccept: onPipelineDeletePipeline,
        }}
      >
        Are you sure you want to delete the pipeline?
      </DeleteModal>
      <SidePannelDetailed
        show={openPanel}
        basePath={`/deal/pipeline/${selectedPipelineId}`}
        onClose={() => handlePanelClose(false)}
        moduleName={linkedModuleName ? linkedModuleName : "deal"}
        moduleId={linkedModuleId ? linkedModuleId : sidePannelDealId}
        nextPreviousData={linkedModuleId ? [] : nextPreviousData}
        pageForm={"dealPipeline"}
        filter={loadingStore}
        // actions
        convertToLead={onConvertToLeadClick}
        handleDelete={onDelete}
        handleArchiveDeal={onMarkAsArchive}
      />
      <DeleteModal
        modalProps={{
          show: dealDeleteShow,
          title: "Delete Deal",
          handleClose: () => {
            setDealDeleteShow(false);
          },
          handleAccept: onDeleteAcceptClick,
        }}
      >
        Do you want to delete selected deals? Once the deal is deleted, all of the information associated with it will be lost. That includes notes, activities, emails etc.
      </DeleteModal>
    </div>
  );
}
