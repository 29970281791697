import { getActivityDataField, showHideActivityFields, updateActivityFieldPriority } from "../types/activity.types";

export const getActivtyDataFieldsRequest = (req) => ({
  type: getActivityDataField.GET_ACTIVITY_DATA_FIELD_REQUEST,
  payload: req,
});
export const getActivtyDataFieldsSuccess = (req) => ({
  type: getActivityDataField.GET_ACTIVITY_DATA_FIELD_SUCCESS,
  payload: req,
});
export const getActivtyDataFieldsError = (req) => ({
  type: getActivityDataField.GET_ACTIVITY_DATA_FIELD_ERROR,
  payload: req,
});

export const showHideActivtyFieldRequest = (req) => ({
  type: showHideActivityFields.SHOW_HIDE_ACTIVITY_FIELD_REQUEST,
  payload: req,
});
export const showHideActivtyFieldSuccess = (req) => ({
  type: showHideActivityFields.SHOW_HIDE_ACTIVITY_FIELD_SUCCESS,
  payload: req,
});
export const showHideActivtyFieldError = (req) => ({
  type: showHideActivityFields.SHOW_HIDE_ACTIVITY_FIELD_ERROR,
  payload: req,
});

export const updateActivityFieldPriorityRequest = (req) => ({
  type: updateActivityFieldPriority.UPDATE_ACTIVITY_FIELD_PRIORITY_REQUEST,
  payload: req,
});
export const updateActivityFieldPrioritySuccess = (req) => ({
  type: updateActivityFieldPriority.UPDATE_ACTIVITY_FIELD_PRIORITY_SUCCESS,
  payload: req,
});
export const updateActivityFieldPriorityError = (req) => ({
  type: updateActivityFieldPriority.UPDATE_ACTIVITY_FIELD_PRIORITY_ERROR,
  payload: req,
});
