import React from "react";
import { CustomScrollableModal } from "../../../../../components/modal";
import { Form } from "react-bootstrap";
import { InputLimit } from "../../../../../constants";
import GoogleEmailConnect from "../../../../../images/gif/google-email-connect.gif";

export default function NylasSyncModal(props) {
  return (
    <CustomScrollableModal
      modalProps={{
        show: props.show,
        handleClose: props.handleClose,
        title: "Connect Email Account",
        showButtons: true,
        buttonText: "Continue",
        showCloseButton: true,
        size: props.isGoogleSignIn ? "lg" : "md",
        handleAccept: props.handleSave,
      }}
    >
      <Form.Group className="px-4">
        <Form.Group>
          <Form.Label>Email Address</Form.Label>
          <Form.Control maxLength={InputLimit.EMAIL} type="email" value={props.nylasEmail} placeholder="Enter email address" onChange={(e) => props.setNylasEmail(e.target.value)} />
        </Form.Group>
        {props.isGoogleSignIn && (
          <Form.Group>
            <Form.Label>
              <strong>"Whitelist Sales Simplify app from Google Admin"</strong> In order to utilize Sales Simplify's 2-way synchronization feature, it is mandatory to whitelist the application from your Google Admin
              Console, as per the guidelines recently set by Google.{" "}
              <a target={"_blank"} rel="noopener noreferrer" href="https://salessimplify.com/help-center/authenticate-sales-simplify-for-email-calendar-sync">
                Click here and whitelist
              </a>
            </Form.Label>
            <img alt="" width={"100%"} src={GoogleEmailConnect} />
          </Form.Group>
        )}
      </Form.Group>
    </CustomScrollableModal>
  );
}
