import React from "react";
import styled from "styled-components";
import {
  // getCurrentDate,
  getFullTitle,
  monthColumns,
  // nextMonth,
  // previousMonth
} from "./calendarUtils";
// import Button from './Button';

// const Header = styled.div`
//   display: inline-block;
// `;

// const HeaderWrapper = styled.div`
//   display: flex;
//   justify-content: center;
// `;

const Content = styled.div`
  flex: 1;
  overflow: hidden;
  background: #e5e8ec;
  margin: 0 5px;
  min-width: 280px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px 0px;
`;

const ContentHeader = styled.div`
  padding: 8px 16px 2px;
  font-weight: bold;
  font-size: 14px;
`;

// const Item = styled.div`
//   margin-left: 8px;

//   &:first-of-type {
//     margin: 0;
//   }
// `;

class Calendar extends React.Component {
  // state = { currentDate: getCurrentDate() };

  // onPreviousClick = () => {
  //   const currentDate = previousMonth(this.state.currentDate);

  //   this.setState({ currentDate });
  // };

  // onNextClick = () => {
  //   const currentDate = nextMonth(this.state.currentDate);

  //   this.setState({ currentDate });
  // };

  // setCurrentDate = () => {
  //   this.setState({ currentDate: getCurrentDate() });
  // };

  //   renderMiddleContent = () => {
  //     return (
  //       <Header>
  //         <HeaderWrapper>
  //           {renderButton({ icon: 'leftarrow', onClick: this.onPreviousClick })}
  //           {renderButton({ icon: 'rightarrow', onClick: this.onNextClick })}
  //           {renderButton({
  //             onClick: this.setCurrentDate,
  //             text: 'Today',
  //             btnStyle: 'primary'
  //           })}
  //         </HeaderWrapper>
  //       </Header>
  //     );
  //   };

  renderMonths = () => {
    const { currentDate } = this.props;
    const months = monthColumns(currentDate, 3);
    return months.map((date, index) => this.renderColumns(index, date));
  };

  renderColumns(index, date) {
    return (
      <Content key={index}>
        <ContentHeader>{getFullTitle(date)}</ContentHeader>
        {this.props.renderColumn(date)}
      </Content>
    );
  }

  render() {
    const { renderContent } = this.props;

    return <>{renderContent(this.renderMonths, this.renderMiddleContent)}</>;
  }
}

// function renderButton(props) {
//   const { text, ...buttonProps } = props;

//   return (
//     <Item>
//       <Button
//         btnStyle="simple"
//         ignoreTrans={true}
//         size="small"
//         {...buttonProps}
//       >
//         {text && (text)}
//       </Button>
//     </Item>
//   );
// }

export default Calendar;
