import { takeLatest, put, call } from "redux-saga/effects";

import api from "../../../network/apis/api";
import { dispatchSnackbarSuccess } from "../../../utils/toaster";
import * as adminSettingsActions from "../actions/admin-settings.actions";

import { GET_TAGS_IN_SETTINGS_REQUEST, ADD_TAGS_IN_SETTINGS_REQUEST, DELETE_TAGS_IN_SETTINGS_REQUEST, BULK_DELETE_TAGS_IN_SETTINGS_REQUEST, EDIT_TAGS_IN_SETTINGS_REQUEST } from "../types/admin-settings.types";

function* getTagFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/tag/settings/get", action.payload);
    if (response.status === 200) {
      yield put(adminSettingsActions.getTagsInSettingSuccess(response.data));
    } else {
      yield put(adminSettingsActions.getTagsInSettingError({ message: "Could Not Get Company Tags" }));
    }
  } catch (e) {
    yield put(adminSettingsActions.getTagsInSettingError({ message: "Could Not Get Company Tags" }));
  }
}

function* TagsAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/tag/create", action.payload);
    if (response.status === 200) {
      yield put(adminSettingsActions.addTagsInSettingSuccess({ message: "Tag Added Successfully" }));
      yield put(adminSettingsActions.getTagsInSettingRequest({ type: action.payload.module }));
      dispatchSnackbarSuccess("Tag Added Successfully");
    } else {
      yield put(adminSettingsActions.addTagsInSettingError({ message: "Could Not Add Company Tag" }));
    }
  } catch (e) {
    yield put(adminSettingsActions.addTagsInSettingError({ status: false, message: "Could Not Add Company Tag" }));
  }
}

function* TagsEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/edit", action.payload);
    if (response.status === 200) {
      yield put(adminSettingsActions.editTagsInSettingSuccess({ message: "Tag Edited Successfully" }));
      yield put(adminSettingsActions.getTagsInSettingRequest({ type: action.payload.module }));
      dispatchSnackbarSuccess("Tag Edited Successfully");
    } else {
      yield put(adminSettingsActions.editTagsInSettingError({ message: "Could Not Edit Tag" }));
    }
  } catch (e) {
    yield put(adminSettingsActions.editTagsInSettingError({ message: "Could Not Edit Tag" }));
  }
}

function* TagsDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/tag/delete/" + action.payload.id);
    if (response.status === 200) {
      yield put(adminSettingsActions.deleteTagsInSettingSuccess({ message: "Tag Deleted Successfully" }));
      yield put(adminSettingsActions.getTagsInSettingRequest({ type: action.payload.module }));
      dispatchSnackbarSuccess("Tag Deleted Successfully");
    } else {
      yield put(adminSettingsActions.deleteTagsInSettingError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(adminSettingsActions.deleteTagsInSettingError({ message: "Could Not Delete Tag" }));
  }
}

function* TagsBulkDeleteFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/settings/bulk-delete", action.payload);
    if (response.status === 200) {
      yield put(adminSettingsActions.bulkDeleteTagsInSettingSuccess({ message: "Tag Deleted Successfully" }));
      yield put(adminSettingsActions.getTagsInSettingRequest({ type: action.payload.module }));
      dispatchSnackbarSuccess("Tag Deleted Successfully");
    } else {
      yield put(adminSettingsActions.bulkDeleteTagsInSettingError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(adminSettingsActions.bulkDeleteTagsInSettingError({ message: "Could Not Delete Tag" }));
  }
}

export default function*() {
  yield takeLatest(GET_TAGS_IN_SETTINGS_REQUEST, getTagFunction);
  yield takeLatest(ADD_TAGS_IN_SETTINGS_REQUEST, TagsAddFunction);
  yield takeLatest(DELETE_TAGS_IN_SETTINGS_REQUEST, TagsDeleteFunction);
  yield takeLatest(BULK_DELETE_TAGS_IN_SETTINGS_REQUEST, TagsBulkDeleteFunction);
  yield takeLatest(EDIT_TAGS_IN_SETTINGS_REQUEST, TagsEditFunction);
}
