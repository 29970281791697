import {
  ADD_NOTES_REQUEST,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_ERROR,
  EDIT_NOTES_REQUEST,
  EDIT_NOTES_SUCCESS,
  EDIT_NOTES_ERROR,
  DELETE_NOTES_REQUEST,
  DELETE_NOTES_SUCCESS,
  DELETE_NOTES_ERROR,
  GET_NOTES_REQUEST,
  GET_NOTES_SUCCESS,
  GET_NOTES_ERROR,
  GET_NOTES_BY_ID_REQUEST,
  GET_NOTES_BY_ID_SUCCESS,
  GET_NOTES_BY_ID_ERROR,
  GET_LINKED_EMAILS_REQUEST,
  GET_LINKED_EMAILS_SUCCESS,
  GET_LINKED_EMAILS_ERROR,
  GET_LINKED_ACTIVITIES_REQUEST,
  GET_LINKED_ACTIVITIES_SUCCESS,
  GET_LINKED_ACTIVITIES_ERROR,
  getUpcomingActivities,
} from "../types/notes.types";

const initialState = {
  notes: {
    list: [],
    loading: false,
    error: "",
    count: 0,
  },
  activities: {
    list: [],
    loading: false,
    error: "",
    count: 0,
  },
  emails: {
    list: [],
    loading: false,
    error: "",
    count: 0,
  },
  upcomingActivity: {
    list: [],
    loading: false,
    error: "",
    count: 0,
    lastContacted: null,
  },
};

export function notesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case ADD_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case ADD_NOTES_ERROR:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case EDIT_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case EDIT_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case EDIT_NOTES_ERROR:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case DELETE_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case DELETE_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case DELETE_NOTES_ERROR:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case GET_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case GET_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
          list: action.payload.data,
        },
      };
    case GET_NOTES_ERROR:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case GET_NOTES_BY_ID_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case GET_NOTES_BY_ID_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };
    case GET_NOTES_BY_ID_ERROR:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: false,
        },
      };

    case GET_LINKED_ACTIVITIES_REQUEST:
      return {
        ...state,
        activities: {
          ...state.activities,
          loading: true,
        },
      };
    case GET_LINKED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          loading: false,
          list: [...action.payload.data],
          count: action.payload.count,
        },
      };
    case GET_LINKED_ACTIVITIES_ERROR:
      return {
        ...state,
        activities: {
          ...state.activities,
          loading: false,
        },
      };
    case GET_LINKED_EMAILS_REQUEST:
      return {
        ...state,
        emails: {
          ...state.emails,
          loading: true,
        },
      };
    case GET_LINKED_EMAILS_SUCCESS:
      return {
        ...state,
        emails: {
          ...state.emails,
          loading: false,
          list: action.payload.data,
          count: action.payload.count,
        },
      };
    case GET_LINKED_EMAILS_ERROR:
      return {
        ...state,
        emails: {
          ...state.emails,
          loading: false,
        },
      };

    case getUpcomingActivities.GET_UPCOMING_ACTIVITIES_REQUEST:
      return {
        ...state,
        upcomingActivity: {
          ...state.upcomingActivity,
          loading: true,
          lastContacted: null,
        },
      };
    case getUpcomingActivities.GET_UPCOMING_ACTIVITIES_SUCCESS:
      return {
        ...state,
        upcomingActivity: {
          ...state.upcomingActivity,
          loading: false,
          list: [...action.payload.data],
          count: action.payload.count,
          lastContacted: action.payload.lastContacted,
        },
      };
    case getUpcomingActivities.GET_UPCOMING_ACTIVITIES_ERROR:
      return {
        ...state,
        upcomingActivity: {
          ...state.upcomingActivity,
          loading: false,
          lastContacted: null,
        },
      };
    default:
      return state;
  }
}
