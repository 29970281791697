import { combineReducers } from "redux";
import workflowReducer from "./workflow_reducer";
import workflowTemplateReducer from "./workflow_template_reducer";
import actionsReducer from "./actions_reducer";
import conditionReducer from "./condition_reducer";

const workflowModuleReducer = combineReducers({
  workflow: workflowReducer,
  template: workflowTemplateReducer,
  actions: actionsReducer,
  conditions: conditionReducer,
});

export default workflowModuleReducer;
