import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { LeadStateWrapper, StateItem } from "../common/dealModal/styled";
import { CustomScrollableModal } from "../../../components/modal";
import { useSelector } from "react-redux";

export default function MoveDealToModel(props) {
  const [saveLocation, setSaveLocation] = useState("Deals");
  const [markAsLostSelectedPipeline, setMarkAsLostSelectedPipeline] = useState("");
  const [markAsLostSelectedPipelineId, setMarkAsLostSelectedPipelineId] = useState("");
  const [markAsLostStages, setMarkAsLostStages] = useState([]);
  const [markAsLostCurrentState, setMarkAsLostCurrentState] = useState("");

  const pipelineStagesStore = useSelector((store) => store.DealPipeline.pipelineOptionsResponse);

  useEffect(() => {
    if (pipelineStagesStore && props.selectedPipelineId) {
      for (let i = 0; i < pipelineStagesStore.length; i++) {
        if (pipelineStagesStore[i]._id === props.selectedPipelineId) {
          setMarkAsLostStages(pipelineStagesStore[i].stages);
          setMarkAsLostSelectedPipelineId(pipelineStagesStore[i]._id);
          setMarkAsLostSelectedPipeline(pipelineStagesStore[i].name);
          if (pipelineStagesStore[i].stages.length > 0) {
            setMarkAsLostCurrentState(pipelineStagesStore[i].stages[0]._id);
          }
        }
      }
    }
  }, [props.selectedPipelineId, pipelineStagesStore]);

  const onDealPipelineselect = (e) => {
    setMarkAsLostSelectedPipelineId(e.id);
    setMarkAsLostSelectedPipeline(e.name);
    for (let i = 0; i < pipelineStagesStore.length; i++) {
      if (pipelineStagesStore[i]._id === e.id) {
        setMarkAsLostStages(pipelineStagesStore[i].stages);
        if (pipelineStagesStore[i].stages.length > 0) {
          setMarkAsLostCurrentState(pipelineStagesStore[i].stages[0]._id);
        }
      }
    }
  };

  const findIndex = () => {
    let i = 0;
    markAsLostStages.forEach((value, index) => {
      if (value._id === markAsLostCurrentState) {
        i = index;
      }
    });
    return i;
  };

  const onMovetoSave = () => {
    if (saveLocation === "Deals") {
      props.callMoveStagesApi({
        data: {
          pipelineId: markAsLostSelectedPipelineId,
          stageId: markAsLostCurrentState,
          dealId: props.selectedDeal._id,
          order: 1,
        },
        type: "move-deal-in-stages",
        pipelineId: props.selectedPipelineId,
      });
    } else {
      props.callDealOperationApi({
        data: {
          dealId: props.selectedDeal._id,
        },
        type: "deal-to-lead",
        pipelineId: props.selectedPipelineId,
        from: "deal",
      });
    }
    props.setShow(false);
  };

  const moveDealToProps = {
    show: props.show,
    handleClose: () => props.setShow(false),
    title: "Move Deal",
    showButtons: true,
    buttonText: "Save",
    handleAccept: () => onMovetoSave(),
  };

  return (
    <CustomScrollableModal modalProps={moveDealToProps}>
      <>
        {!props.isMultiDeal && (
          <div className="loss-deal-item">
            <div className="mb-10px text-capitalize">
              <div className="fw-500 font-size-15px text-26292c">{props.selectedDeal.title}</div>
              <div className="font-size-13px text-747678">{props.selectedDeal && props.selectedDeal.company && props.selectedDeal.company.name}</div>
              <div className="font-size-13px text-747678">{props.selectedDeal && props.selectedDeal.people && props.selectedDeal.people.name}</div>
            </div>
            <div className="fw-500 font-size-13px p-2px text-747678 border-top-width-1px border-top-style-dotted border-top-color-eeeeee">
              <i className="bi bi-person-fill user-icon mr-4px text-747678" title={Array.isArray(props.selectedDeal.users) && props.selectedDeal.users.filter((a) => a).map((user) => (user ? user.name : ""))}></i>
              {props.selectedDeal.dealCurrency} {props.selectedDeal.dealValue}
            </div>
          </div>
        )}
        <div>
          <Form.Group id="deals-components-move-deal-to-model-move-to-deals-and-leads" className="mb-12px">
            <Form.Label aria-label="move-to">Move To</Form.Label>
            <Dropdown className="dropdown-inline mt-1 w-100">
              <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                {saveLocation}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu w-100-mn">
                <Dropdown.Item className="dropdown-hover" onClick={() => setSaveLocation("Deals")}>
                  Deals
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" onClick={() => setSaveLocation("Leads")}>
                  Leads
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </div>
        {saveLocation === "Deals" ? (
          <>
            <Form.Group id="deals-components-move-deal-to-model-pipeline" className="mb-12px">
              <Form.Label aria-label="pipeline">Pipeline</Form.Label>
              <Dropdown className="dropdown-inline mt-1 w-100">
                <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                  {markAsLostSelectedPipeline}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu w-100-mn">
                  <div className="overflow-y-auto h-200px-mx">
                    {props.pipelinedropdownOptions &&
                      props.pipelinedropdownOptions.length > 0 &&
                      props.pipelinedropdownOptions.map((pipeline, index) => (
                        <Dropdown.Item className="dropdown-hover" key={index} onClick={() => onDealPipelineselect(pipeline, index)}>
                          <span className="w-100">{pipeline.name}</span>
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Form.Group id="deals-components-move-deal-to-model-pipeline-stages" className="mb-8px">
              <Form.Label aria-label="pipeline-stages">Pipeline Stages</Form.Label>
              <LeadStateWrapper>
                {markAsLostStages &&
                  markAsLostStages.map((value, index) => {
                    const onClick = () => {
                      setMarkAsLostCurrentState(value._id);
                    };
                    const currentIndex = findIndex();
                    return (
                      <StateItem key={index} past={index < currentIndex} active={index === currentIndex} onClick={onClick} title={value.name}>
                        <div title={value.name}>
                          {index < currentIndex}
                          {/* {value} */}
                        </div>
                      </StateItem>
                    );
                  })}
              </LeadStateWrapper>
            </Form.Group>
          </>
        ) : null}
      </>
    </CustomScrollableModal>
  );
}
