import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { EditableTable } from "../../components/table";
import { Spinner } from "react-bootstrap";
import { ColumnFilter } from "../../components/column_filter";
import { DeleteModal } from "../../components/modal";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton } from "../../components/button";
import * as actions from "./store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "../../components/custom_form";
import { UpgradePlanPage } from "../../components/empty_page";
import { NavLink } from "react-router-dom";
import BulkEditForm from "../../components/custom_form/bulkEdit";
import { ownersRequest } from "../customers/store/actions/actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { MergeModal } from "../../components/merge";
import SubscriptionPlanModal from "../Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import ContentWrapper from "../Wrapper";
import SmartViewFilter from "../../components/filter/smart-view-filter";
import OwnerFilter from "../../components/filter/owner-filter";
import TagSelect from "../../components/custom_form/tags";

//actions
import * as LeadActions from "../leads/store/actions/actions";
import * as CompanyActions from "../companies/store/actions/actions";
import * as ProductActions from "../products/store/actions/actions";
import * as ProductOperationActions from "./store/actions/product-operations.actions";
import * as insightActions from "../insights/actions";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { DeleteIconButton } from "../../components/button/icon-action-buttons";
import { SecondaryButton } from "../../components/button/common-buttons";
import * as TemplateActions from "../campaign/store/actions/campaign_templates_actions";
import OptionsDropdown from "../../components/filter/import-export-dropdown";
import SelectAll from "../../components/filter/select-all";

export default function Products() {
  const [showPlans, setShowPlans] = useState(false);
  const [showColumns, setShowColumns] = useState([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const fieldStore = useSelector((state) => state.Products.productFields);
  const addFieldStore = useSelector((state) => state.Products.productAddFields);
  const filterStore = useSelector((state) => state.Products.productFilters);
  const activeFilterStore = useSelector((state) => state.Products.productFilters.activeFilter);
  const listStore = useSelector((state) => state.Products.productList);
  const errorStore = useSelector((state) => state.Products.error);
  const successStore = useSelector((state) => state.Products.success);
  const loadingStore = useSelector((state) => state.Products.loadingData);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  const [addFormData, setAddFormData] = useState({});
  const [bulkEditData, setBulkEditData] = useState({});
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const page = "Products";
  const pageSub = "product";
  const [showDelete, setShowDelete] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "product",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
  });
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);

  //filters
  const [virtualFilter, setVirtualFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");
  const [filterId, setFilterId] = useState("");

  // delete all
  const [isAllSelected, setIsAllSelected] = useState(false);

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  useEffect(() => {
    if (accountBillingStore.data && accountBillingStore.data.servicesOffered && accountBillingStore.data.servicesOffered.PRODUCT_CATALOG) {
      const payload = {
        pageNo: pageNo,
        limit: limit,
        tagId: tags,
        owner: owner,
        filterId: filterId,
        ...sortKeys(),
      };
      if (!_.isEmpty(virtualFilter)) {
        payload["filter"] = JSON.stringify(virtualFilter);
      }
      dispatch(actions.productsRequest(payload));
      dispatch(actions.loadingDataUpdateRequest(payload));
    }
  }, [dispatch, pageNo, limit, tags, owner, virtualFilter, filterId, accountBillingStore.data, sortKeys]);

  useEffect(() => {
    dispatch(actions.productCategoriesRequest());
    dispatch(actions.productTagsRequest({ type: "product" }));
    dispatch(ownersRequest());
    dispatch(actions.productFieldsRequest({ fieldType: "PRODUCT" }));
    dispatch(actions.productAddFieldsRequest({ fieldType: "PRODUCT" }));
    dispatch(actions.productFiltersRequest());

    //common api Calls
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!listStore.loading && listStore.list.length === 0 && listStore.count > 0) {
      setPageNo(1);
    }
  }, [listStore.loading, listStore.list, listStore.count, limit]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("productOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("productOwner", owner);
    else localStorage.removeItem("productOwner");
  }, [owner]);

  const updateFieldPriority = (data) => {
    dispatch(actions.fieldUpdatePriorityRequest({ ...data, fieldType: "PRODUCT" }));
  };

  const showHideField = (data) => {
    dispatch(actions.fieldShowHideRequest(data));
  };

  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["selectedColumns"] = showColumns;
      dispatch(
        actions.productFilterAddRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        actions.productFilterAddRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData({
      filterModule: "product",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
      temp["selectedColumns"] = showColumns;
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    if (temp["id"] && filterId === temp["id"]) dispatch(actions.clearFilter());
    dispatch(
      actions.productFilterEditRequest(temp, (data) => {
        if (data.status && temp.id === filterId) applyFilter(filterId);
      }),
    );
    setAddFilterFormData({
      filterModule: "product",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const favoriteFilter = (data) => {
    dispatch(actions.productFilterFavoriteRequest(data));
  };

  const deleteFilter = (id) => {
    if (activeFilterStore && filterId === id) dispatch(actions.clearFilter());
    dispatch(actions.productFilterDeleteRequest(id));
    setAddFilterFormData({
      filterModule: "product",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
  };
  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
    dispatch(actions.clearFilter());
  };

  const addCategory = (name) => {
    dispatch(actions.productCategoriesAddRequest({ name: name }));
  };

  const editCategory = (id, name) => {
    dispatch(actions.productCategoriesEditRequest({ id: id, name: name }));
  };

  const deleteCategory = (id) => {
    dispatch(actions.productCategoriesDeleteRequest(id));
  };

  const addTag = (name, colorCode) => {
    dispatch(actions.productTagsAddRequest({ name: name, colorCode: colorCode, type: "product" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(actions.productTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(actions.productTagsDeleteRequest(id));
  };

  const handleEditProduct = (combinedId, data) => {
    dispatch(actions.productCellEditRequest({ editData: data, loadingData: loadingStore, cellId: combinedId }));
  };

  const handleAddProduct = (temp) => {
    dispatch(actions.productAddRequest({ productData: temp, loadingData: loadingStore }));
    setShowAdd(false);
    setAddFormData({});
  };

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  const handleBulkEdit = (data) => {
    if (isAllSelected) {
      const payload = {
        productData: {
          updateData: data,
          ids: checked,
          isAllSelected: true,
          filter: loadingStore,
        },
        loadingData: loadingStore,
      };
      dispatch(ProductOperationActions.bulkEditProductV2Request(payload));
      setShowBulkEdit(false);
      setBulkEditData({});
      setChecked([]);
      setIsAllSelected(false);
    } else {
      dispatch(actions.productBulkEditRequest({ productData: { ...data, ids: checked }, loadingData: loadingStore }));
      setShowBulkEdit(false);
      setBulkEditData({});
    }
  };

  const handleDeleteProducts = () => {
    if (isAllSelected && checked.length > 0) {
      dispatch(ProductOperationActions.bulkDeleteProductV2Request({ productIds: { ids: checked, filter: loadingStore, isAllSelected: true }, loadingData: loadingStore }));
      setChecked([]);
      setShowDelete(false);
    } else {
      dispatch(actions.productDeleteRequest({ productIds: { ids: checked }, loadingData: loadingStore }));
      setChecked([]);
      setShowDelete(false);
    }
  };

  const handleMerge = (merged) => {
    dispatch(actions.productsMergeRequest({ data: merged, loadingData: loadingStore }));
    setChecked([]);
  };

  const tableProps = {
    columns: fieldStore.list,
    data: listStore.list,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: (a) => {
      setChecked(a);
      setIsAllSelected(false);
    },
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    addCategory: addCategory,
    editCategory: editCategory,
    deleteCategory: deleteCategory,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    idToIndex: fieldStore.idToIndex,
    handleCellEdit: handleEditProduct,
    hidePanel: true,
    page: page,
    pageSub: pageSub,
    cellLoading: listStore.cellLoading,
    loading: listStore.loading,

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    filterPayload: loadingStore,

    // selection
    isAllListSelected: isAllSelected,
  };

  const deleteModalProps = {
    show: showDelete,
    title: "Delete Products",
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeleteProducts,
  };

  useEffect(() => {
    let tempCol = [];
    var tempFieldStore = fieldStore.list;
    tempFieldStore.sort(function (a, b) {
      return a.tablePriority - b.tablePriority;
    });
    for (let i = 0; i < tempFieldStore.length; i++) if (tempFieldStore[i].isEnabledForTable !== false) tempCol.push(tempFieldStore[i]["_id"]);
    setShowColumns(tempCol);
  }, [fieldStore]);

  return (
    <>
      {fieldStore.loading || (listStore.loading && listStore.count === 0) || accountBillingStore.loading ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <>
          <CustomForm
            show={showAdd}
            setShow={setShowAdd}
            handleAdd={handleAddProduct}
            addTag={addTag}
            editTag={editTag}
            deleteTag={deleteTag}
            addCategory={addCategory}
            editCategory={editCategory}
            deleteCategory={deleteCategory}
            page={page}
            pageSub={pageSub}
            fields={addFieldStore.list}
            customFormData={addFormData}
            setCustomFormData={setAddFormData}
            fieldsLoading={addFieldStore.loading}
          />
          {accountBillingStore && accountBillingStore.data && accountBillingStore.data.servicesOffered && accountBillingStore.data.servicesOffered.PRODUCT_CATALOG === false ? (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row align-items-center w-100 justify-content-end">
                    <div className="d-flex flex-row align-items-center gap-12px"></div>
                  </div>
                </CustomSubHeader>
              }
            >
              <UpgradePlanPage
                isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
                userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
                activeFilter={activeFilterStore}
                onUpgradeClick={() => setShowPlans(true)}
                type={"Product Catelog"}
                planName={"startup plan"}
              />
              {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
            </ContentWrapper>
          ) : !listStore.loading && listStore.count === 0 && _.isEmpty(tags) && _.isEmpty(owner) && _.isEmpty(virtualFilter) && _.isEmpty(filterId) ? (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row align-items-center w-100 justify-content-end">
                    <div className="d-flex flex-row align-items-center gap-12px">
                      <NavLink to="/import/product">
                        <BlueGradientButton size="sm">Import Products</BlueGradientButton>
                      </NavLink>
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <CustomEmptyPage page="product" setShowAdd={setShowAdd} />
            </ContentWrapper>
          ) : (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row flex-stack w-100">
                    <div></div>
                    <div>
                      {checked.length !== 0 ? (
                        <div className="d-flex flex-row align-items-center gap-12px">
                          <SelectAll
                            checked={checked}
                            limit={limit}
                            count={listStore.count}
                            isAllSelected={isAllSelected}
                            selectAllClick={() => {
                              setIsAllSelected(true);
                              setChecked(listStore.list.map((each) => each._id));
                            }}
                            clearAllClick={() => {
                              setIsAllSelected(false);
                              setChecked([]);
                            }}
                          />
                          <BulkEditForm
                            isAllListSelected={isAllSelected}
                            handleBulkEdit={handleBulkEdit}
                            noOfRows={isAllSelected ? listStore.count : checked.length}
                            showColumns={showColumns}
                            show={showBulkEdit}
                            setShow={setShowBulkEdit}
                            addTag={addTag}
                            editTag={editTag}
                            deleteTag={deleteTag}
                            addCategory={addCategory}
                            editCategory={editCategory}
                            deleteCategory={deleteCategory}
                            page={page}
                            pageSub={pageSub}
                            fields={fieldStore.list}
                            customFormData={bulkEditData}
                            setCustomFormData={setBulkEditData}
                          />
                          {checked.length === 2 ? (
                            <BlueGradientButton size="sm" onClick={() => setShowMergeModal(true)}>
                              Merge
                            </BlueGradientButton>
                          ) : null}
                          <SecondaryButton onClick={() => setShowBulkEdit(true)}>Bulk Edit</SecondaryButton>
                          <DeleteIconButton onClick={() => setShowDelete(true)} />
                          <DeleteModal modalProps={deleteModalProps}>
                            Are you sure you want to delete the products?
                            {isAllSelected && (
                              <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                                In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be
                                call or activated.
                              </span>
                            )}
                          </DeleteModal>
                          <ColumnFilter
                            fieldType={"PRODUCT"}
                            showHideField={showHideField}
                            updateFieldPriority={updateFieldPriority}
                            idToIndex={fieldStore.idToIndex}
                            columns={fieldStore.list}
                            showColumns={showColumns}
                            setShowColumns={setShowColumns}
                          />
                        </div>
                      ) : (
                        <div className="d-flex flex-row align-items-center gap-12px">
                          <BlueGradientButton onClick={() => setShowAdd(true)} variant="success" size="sm">
                            + Product
                          </BlueGradientButton>

                          <SmartViewFilter
                            pageSub={pageSub}
                            page={page}
                            activeFilter={filterStore.activeFilter}
                            customFilters={filterStore.list}
                            loading={filterStore.loading}
                            setAddFilterFormData={setAddFilterFormData}
                            virtualFilter={virtualFilter}
                            applyVirtualFilter={applyVirtualFilter}
                            selectedFilterId={filterId}
                            favoriteFilter={favoriteFilter}
                            applyFilter={applyFilter}
                            addFilterFormData={addFilterFormData}
                            addFilter={addFilter}
                            editFilter={editFilter}
                            deleteFilter={deleteFilter}
                          />
                          <div>
                            <TagSelect
                              page={page}
                              pageSub={pageSub}
                              addTag={addTag}
                              editTag={editTag}
                              deleteTag={deleteTag}
                              field={{ fieldName: "Tags", apiKeyName: "tags" }}
                              value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                              setValue={(value) => setValue(value)}
                              hideAddText={true}
                              isUsedAsFilter={true}
                              iconBasedFilter={true}
                            />
                          </div>
                          <OwnerFilter setValue={setOwner} value={owner} />
                          <OptionsDropdown
                            dataType={"product"}
                            importLabel={"Import Products"}
                            exportLabel={"Export Products"}
                            showColumns={showColumns}
                            showExportModal={showExportModal}
                            activeFilter={activeFilterStore}
                            appliedFilter={loadingStore}
                            setShowExportModal={setShowExportModal}
                          />
                          <ColumnFilter
                            fieldType={"PRODUCT"}
                            showHideField={showHideField}
                            updateFieldPriority={updateFieldPriority}
                            idToIndex={fieldStore.idToIndex}
                            columns={fieldStore.list}
                            showColumns={showColumns}
                            setShowColumns={setShowColumns}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <div className={`card card-custom`}>
                <div className="card-body py-3 px-3">
                  <EditableTable props={tableProps} />
                </div>
              </div>
              <MergeModal
                show={showMergeModal}
                handleMerge={handleMerge}
                setShow={setShowMergeModal}
                type={page}
                pageSub={pageSub}
                typeSub="Product"
                fields={fieldStore.list}
                items={checked.map((item) => listStore.list.find((listItem) => listItem["_id"] === item))}
              />
            </ContentWrapper>
          )}
        </>
      )}
    </>
  );
}
