import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import * as actions from "../../actions/integrations.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function IntegrationCallback() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search !== "") {
      const code = history.location.search.split("code=")[1].split("&state=");
      dispatch(
        actions.getIntegrationCallbackRequest(
          {
            code: code[0],
            appType: code[1],
          },
          (data) => {
            setLoadingText(data.message, code[1]);
            dispatch(actions.getIntegrationAccountDetailsRequest({ apptype: code[1] }));
          },
        ),
      );
    }
  }, [history.location.search, dispatch]);

  const setLoadingText = (message, apptype) => {
    setLoading(false);
    setMessage(message);

    setTimeout(() => {
      window.location.href = `/settings/profile/integrations/${apptype}`;
    }, 5000);
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10 h-vh-120px">
        {loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center h-100 mt-20-percent">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 fs-20 mt-100px">
            <div>
              <h4>{message}</h4>
            </div>
            <div>Redirecting in 5 Seconds</div>
          </div>
        )}
      </div>
    </div>
  );
}
