export const getCampaignList = {
  GET_CAMPAIGN_LIST_REQUEST: "GET_CAMPAIGN_LIST_REQUEST",
  GET_CAMPAIGN_LIST_REQUEST_AFTER: "GET_CAMPAIGN_LIST_REQUEST_AFTER",
  GET_CAMPAIGN_LIST_SUCCESS: "GET_CAMPAIGN_LIST_SUCCESS",
  GET_CAMPAIGN_LIST_ERROR: "GET_CAMPAIGN_LIST_ERROR",
};

export const createCampaign = {
  CREATE_CAMPAIGN_REQUEST: "CREATE_CAMPAIGN_REQUEST",
  CREATE_CAMPAIGN_SUCCESS: "CREATE_CAMPAIGN_SUCCESS",
  CREATE_CAMPAIGN_ERROR: "CREATE_CAMPAIGN_ERROR",
};

export const deleteCampaign = {
  DELETE_CAMPAIGN_REQUEST: "DELETE_CAMPAIGN_REQUEST",
  DELETE_CAMPAIGN_SUCCESS: "DELETE_CAMPAIGN_SUCCESS",
  DELETE_CAMPAIGN_ERROR: "DELETE_CAMPAIGN_ERROR",
};

export const editCampaign = {
  EDIT_CAMPAIGN_REQUEST: "EDIT_CAMPAIGN_REQUEST",
  EDIT_CAMPAIGN_SUCCESS: "EDIT_CAMPAIGN_SUCCESS",
  EDIT_CAMPAIGN_ERROR: "EDIT_CAMPAIGN_ERROR",
};
export const repeatCampaign = {
  REPEAT_CAMPAIGN_REQUEST: "REPEAT_CAMPAIGN_REQUEST",
  CLEAR_REPEAT_CAMPAIGN_REQUEST: "CLEAR_REPEAT_CAMPAIGN_REQUEST",
};

export const rescheduledCamapign = {
  RESCHEDULED_CAMPAIGN_REQUEST: "RESCHEDULED_CAMPAIGN_REQUEST",
  RESCHEDULED_CAMPAIGN_SUCCESS: "RESCHEDULED_CAMPAIGN_SUCCESS",
  RESCHEDULED_CAMPAIGN_ERROR: "RESCHEDULED_CAMPAIGN_ERROR",
};

export const getCampaignDetailsById = {
  GET_CAMPAIGN_DETAILS_BY_ID_REQUEST: "GET_CAMPAIGN_DETAILS_BY_ID_REQUEST",
  GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS: "GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS",
  GET_CAMPAIGN_DETAILS_BY_ID_ERROR: "GET_CAMPAIGN_DETAILS_BY_ID_ERROR",
};

export const cancelCampaign = {
  CANCEL_CAMPAIGN_REQUEST: "CANCEL_CAMPAIGN_REQUEST",
  CANCEL_CAMPAIGN_SUCCESS: "CANCEL_CAMPAIGN_SUCCESS",
  CANCEL_CAMPAIGN_ERROR: "CANCEL_CAMPAIGN_ERROR",
};

export const cancelSplitBatchCampaign = {
  CANCEL_SPLIT_BATCH_CAMPAIGN_REQUEST: "CANCEL_SPLIT_BATCH_CAMPAIGN_REQUEST",
  CANCEL_SPLIT_BATCH_CAMPAIGN_SUCCESS: "CANCEL_SPLIT_BATCH_CAMPAIGN_SUCCESS",
  CANCEL_SPLIT_BATCH_CAMPAIGN_ERROR: "CANCEL_SPLIT_BATCH_CAMPAIGN_ERROR",
};

export const sendTestEmail = {
  SEND_TEST_EMAIL_REQUEST: "SEND_TEST_EMAIL_REQUEST",
  SEND_TEST_EMAIL_SUCCESS: "SEND_TEST_EMAIL_SUCCESS",
  SEND_TEST_EMAIL_ERROR: "SEND_TEST_EMAIL_ERROR",
};

export const uploadEmailAttachement = {
  UPLOAD_EMAIL_ATTACHMENT_REQUEST: "UPLOAD_EMAIL_ATTACHMENT_REQUEST",
  UPLOAD_EMAIL_ATTACHMENT_SUCCESS: "UPLOAD_EMAIL_ATTACHMENT_SUCCESS",
  UPLOAD_EMAIL_ATTACHMENT_ERROR: "UPLOAD_EMAIL_ATTACHMENT_ERROR",
};

export const checkAudienceCount = {
  CHECK_AUDIENCE_COUNT_REQUEST: "CHECK_AUDIENCE_COUNT_REQUEST",
  CHECK_AUDIENCE_COUNT_SUCCESS: "CHECK_AUDIENCE_COUNT_SUCCESS",
  CHECK_AUDIENCE_COUNT_ERROR: "CHECK_AUDIENCE_COUNT_ERROR",
};

export const uploadTemporaryFile = {
  UPLOAD_TEMPORARY_FILE_REQUEST: "UPLOAD_TEMPORARY_FILE_REQUEST",
  UPLOAD_TEMPORARY_FILE_SUCCESS: "UPLOAD_TEMPORARY_FILE_SUCCESS",
  UPLOAD_TEMPORARY_FILE_ERROR: "UPLOAD_TEMPORARY_FILE_ERROR",
};
