import React from "react";
import { EmailSignatureEditor } from "../side-pannel-detailed/components/email-signature-editor.side-pannel";
import CustomSidePanel from "../side-pannel-detailed/custom-components/custom-side-pannel";

export default function SignatureManager(props) {
  return (
    <CustomSidePanel show={props.show} moduleName={`Signatures`} handleClose={props.handleClose}>
      <EmailSignatureEditor isEdit={props.isEdit} editId={props.editId} />
    </CustomSidePanel>
  );
}
