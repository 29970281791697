import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { peopleLeadDropdownRequest } from "../../containers/customers/store/actions/actions";
import { dispatchSnackbarError } from "../../utils/toaster";
import SearchSelect from "./searchSelect";

export default function LeadPersonSelect({ field, value, setValue, customFormData, setCustomFormData, dontShowAdd, valueLabel }) {
  const [newPersonName, setNewPersonName] = useState("");
  const personStore = useSelector((state) => state.People.peopleLeadDropdownList);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    setSelected({
      label: dontShowAdd ? (personStore.list.find((person) => person["_id"] === value) ? personStore.list.find((company) => company["_id"] === value).name : valueLabel ? valueLabel : null) : value,
      // label: value,
      value: value,
    });
  }, [personStore, value, dontShowAdd, valueLabel]);

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < personStore.list.length; i++) {
      temp.push({
        value: personStore.list[i]["_id"],
        label: personStore.list[i]["name"],
        email: personStore.list[i]["email"],
        phone: personStore.list[i]["phone"],
        company: personStore.list[i]["company"],
      });
    }
    if (!dontShowAdd && newPersonName) {
      temp.push({ value: "new", label: '+ Add "' + newPersonName + `" as new ${field.fieldName.toLowerCase()}` });
    }
    setOptions(temp);
  }, [personStore, newPersonName, dontShowAdd, field.fieldName]);

  const newValue = (data) => {
    setSelected({ label: newPersonName, value: newPersonName });
    setValue("new", { value: newPersonName, key: field.apiKeyName });
    setIsNew(true);
  };

  const handleSave = () => {
    setIsNew(true);
    if (newPersonName.trim() === "") {
      dispatchSnackbarError("Name can not be blank!");
      return;
    }
    setSelected({ label: newPersonName, value: newPersonName });
    if (setCustomFormData) {
      const temp = { phone: undefined, company: undefined, email: undefined, name: newPersonName };
      setCustomFormData({ ...customFormData, ...temp });
    } else {
      setValue("new", { value: newPersonName, key: field.apiKeyName });
    }
  };

  const setValueA = (a) => {
    if (!a) {
      return setValue("", {});
    }
    for (var i = 0; i < personStore.list.length; i++) {
      if (personStore.list[i]._id === a) {
        setValue("old", personStore.list[i]);
      }
    }
  };

  return (
    <>
      <SearchSelect
        newValue={newValue}
        setIsNew={setIsNew}
        dontShowOptionsDropdwon={true}
        isNew={isNew}
        type={"person"}
        customFormData={customFormData}
        setCustomFormData={setCustomFormData}
        options={options}
        selected={selected}
        setValue={setValueA}
        handleSave={handleSave}
        setSearch={setNewPersonName}
        field={field}
        loading={personStore.loading}
        request={peopleLeadDropdownRequest}
      />
    </>
  );
}
