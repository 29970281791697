import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlankSidePanel from "../../../../../../components/blank-side-pannel";
import TimelineStep from "../../../components/timeline-step";
import { AddStepsMenu } from "../../../constants";
import ActivityStepForm from "../forms/activity-step.form";

import * as CadenceStepActions from "../../../store/actions/cadence_step_actions";

import "./styles.scss";
import { DeleteModal } from "../../../../../../components/modal";
import EmailNotificationForm from "../forms/email-notification.form";
import { getStepAddFormData, getStepEditedFormData } from "../helper";
import SmsStepForm from "../forms/sms-step.form";
import _ from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InlineStyled from "../../../../../../components/inline-styled/inline-styled";
import CadenceWhatsappForm from "../forms/whatsapp-step.form";

export default function CadenceActionsStep2(props) {
  const [channelType, setChannelType] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [deleteStepShow, setDeleteStepShow] = useState(false);
  const [selectedStep, setSelectedStep] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isAbVarient, setIsAbVarient] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSubIndex, setSelectedSubIndex] = useState(-1);
  const [activitySubMenu, setActivitySubMenu] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("");

  const dispatch = useDispatch();

  const activityStore = useSelector((store) => store.activity.types.active.list);

  useEffect(() => {
    if (activityStore) {
      const a = activityStore
        .map((each) => {
          return {
            _id: each._id,
            name: each.name,
            icon: each.symbName,
            color: "#02BC77",
            bg: "#D4F4E8",
            channelType: "TASK",
          };
        })
        .filter((a) => a.name.toLowerCase() !== "email");
      setActivitySubMenu(a);
    }
  }, [activityStore]);

  const handleCloseEditor = () => {
    setChannelType("");
    setShowEditor(false);
    setIsAbVarient(false);
    setSelectedStep({});
    setSelectedIndex(-1);
    setSelectedSubIndex(-1);
    setIsEdit(false);
    setSelectedActivity("");
  };

  const handleChannelTypeClick = (type, data) => {
    if (props.isEdit) return;
    setChannelType(type);
    setShowEditor(true);
    if (data._id && type === "TASK") setSelectedActivity(data._id);
    setSelectedIndex(-1);
    setIsEdit(false);
    setSelectedStep({});
    setIsAbVarient(false);
    setSelectedSubIndex(-1);
  };

  const setEditedStep = (index, payload) => {
    const stepData = props.steps[index];

    const autoModifiedPayload = () => {
      const payloadClone = _.cloneDeep(payload);
      if (payloadClone.channelType === "EMAIL") {
        if (payloadClone.abTesting?.emailContent?.length > 1) {
          payloadClone.isAbTestingEnabled = true;
        } else {
          payloadClone.isAbTestingEnabled = false;
        }
      }
      return payloadClone;
    };

    payload = autoModifiedPayload();

    if (props.cadenceId && stepData && stepData._id) {
      const data = {
        id: props.cadenceId,
        stepId: stepData._id,
        data: getStepEditedFormData(payload),
      };

      dispatch(CadenceStepActions.updateCadenceStepRequest(data));
      handleCloseEditor();
    } else {
      const updatedSteps = [...props.steps];
      updatedSteps[index] = payload;
      props.setSteps(updatedSteps);
      handleCloseEditor();
    }
  };

  const addStep = (payload) => {
    if (props.cadenceId) {
      dispatch(CadenceStepActions.AddStepInCadenceRequest({ id: props.cadenceId, data: getStepAddFormData(payload) }));
      handleCloseEditor();
    } else {
      props.addStep(payload);
      handleCloseEditor();
    }
  };

  const handleEditStepClick = (index, step) => {
    setSelectedIndex(index);
    setIsEdit(true);
    setChannelType(step.channelType);
    setShowEditor(true);
    setSelectedStep(step);
    setIsAbVarient(false);
    setSelectedSubIndex(-1);
  };
  const handleSubEditStepClick = (index, step, subIndex) => {
    setSelectedIndex(index);
    setIsEdit(true);
    setChannelType(step.channelType);
    setShowEditor(true);
    setSelectedStep(step);
    setIsAbVarient(true);
    setSelectedSubIndex(subIndex);
  };
  const handleDeleteStepClick = (index) => {
    setDeleteStepShow(true);
    setSelectedIndex(index);
    setIsAbVarient(false);
    setSelectedSubIndex(-1);
  };
  const handleSubStepDeleteClick = (index, subIndex) => {
    setDeleteStepShow(true);
    setSelectedIndex(index);
    setIsAbVarient(true);
    setSelectedSubIndex(subIndex);
  };
  const handleABVarientAddClick = (index, step) => {
    setChannelType(step.channelType);
    setShowEditor(true);
    setIsAbVarient(true);
    setSelectedStep(step);
    setSelectedIndex(index);
  };

  const deleteStepModalProps = {
    show: deleteStepShow,
    title: "Delete Step",
    handleClose: () => handleStepDeleteCancel(),
    handleAccept: () => handleStepDeleteAccept(),
  };

  const handleStepDeleteCancel = () => {
    setDeleteStepShow(false);
    setSelectedIndex(-1);
    setIsAbVarient(false);
    setSelectedSubIndex(-1);
  };

  const handleStepDeleteAccept = () => {
    if (isAbVarient) {
      const steps = _.cloneDeep(props.steps);
      const stepData = { ...steps[selectedIndex] };
      if (stepData) {
        stepData.abTesting.emailContent = stepData.abTesting.emailContent.filter((each, index) => index !== selectedSubIndex);
        if (stepData.abTesting.emailContent.length === 1) {
          stepData.emailContent = stepData.abTesting.emailContent[0];
          stepData.abTesting = null;
        }
        setEditedStep(selectedIndex, stepData);
      }
    } else {
      const stepData = props.steps[selectedIndex];
      if (stepData && stepData._id) {
        dispatch(CadenceStepActions.deleteCadenceStepRequest({ id: props.cadenceId, stepId: stepData._id }));
      } else {
        const updatedArray = props.steps.filter((each, index) => index !== selectedIndex);
        props.setSteps(updatedArray);
      }
    }
    handleStepDeleteCancel();
  };

  const handleStepOrderChange = (step, currentIndex, newIndex) => {
    if (step && step._id) {
      const payload = {
        id: props.cadenceId,
        data: {
          executionOrder: newIndex + 1,
          stepId: step._id,
        },
      };
      dispatch(CadenceStepActions.moveCadenceStepsRequest(payload));
    } else {
      const updatedSteps = [...props.steps];
      updatedSteps.splice(newIndex, 0, updatedSteps.splice(currentIndex, 1)[0]);
      props.setSteps(updatedSteps);
    }
  };

  return (
    <div className="cadence-steps-container card">
      <div className="cadence-head-label mb-2">
        Steps{" "}
        <OverlayTrigger placement="right" overlay={<Tooltip>Add one of more activity steps in the cadence. They will be triggered in sequence. You can add delays between steps.</Tooltip>}>
          <i className="ml-2 bi bi-info-circle"></i>
        </OverlayTrigger>
      </div>
      <div className="timeline">
        {props.steps.map((each, index) => {
          return (
            <TimelineStep
              step={each}
              steps={props.steps}
              index={index}
              key={index}
              isBusinessPlusUser={props.isBusinessPlusUser}
              // updated Actions
              onEditClick={handleEditStepClick}
              onSubStepEditClick={handleSubEditStepClick}
              onDeleteClick={handleDeleteStepClick}
              onSubStepDeleteClick={handleSubStepDeleteClick}
              handleStepUpdate={setEditedStep}
              handleStepDelay={(index, step) => setEditedStep(index, step)}
              handleABVarientAddClick={handleABVarientAddClick}
              handleStepOrderChange={handleStepOrderChange}
            />
          );
        })}
      </div>
      <div className="add-step-wrapper">
        <div className="add-step-label">Add Step</div>
        <div className="step-button-wrapper">
          {[...AddStepsMenu, ...activitySubMenu].map((each, index) => {
            return (
              <div className="add-step-button-wrapper" onClick={() => handleChannelTypeClick(each.channelType, each)} key={index}>
                <InlineStyled className="add-step-button-icon" styles={{ bg: each.bg, customColor: each.color }}>
                  <InlineStyled as="i" className={each.icon} styles={{ bg: each.bg, customColor: each.color }}></InlineStyled>
                </InlineStyled>
                <div className="add-step-button-name">{each.name}</div>
              </div>
            );
          })}
        </div>
      </div>

      <BlankSidePanel title={isEdit ? "Edit Step" : "Add Step"} show={showEditor} handleClose={() => handleCloseEditor()}>
        {channelType === "EMAIL" && (
          <EmailNotificationForm
            isEdit={isEdit}
            steps={props.steps}
            totalSteps={props.steps.length}
            selectedIndex={selectedIndex}
            isAbVarient={isAbVarient}
            selectedSubIndex={selectedSubIndex}
            selectedStep={selectedStep}
            handleAdd={addStep}
            handleEdit={setEditedStep}
          />
        )}
        {channelType === "SMS" && (
          <SmsStepForm isEdit={isEdit} steps={props.steps} totalSteps={props.steps.length} selectedIndex={selectedIndex} selectedStep={selectedStep} handleAdd={addStep} handleEdit={setEditedStep} />
        )}
        {channelType === "TASK" && (
          <ActivityStepForm totalSteps={props.steps.length} isEdit={isEdit} selectedIndex={selectedIndex} selectedStep={selectedStep} selectedActivity={selectedActivity} handleAdd={addStep} handleEdit={setEditedStep} />
        )}
        {channelType === "WHATSAPP" && (
          <CadenceWhatsappForm isEdit={isEdit} steps={props.steps} totalSteps={props.steps.length} selectedIndex={selectedIndex} selectedStep={selectedStep} handleAdd={addStep} handleEdit={setEditedStep} whatsappPhoneOptions={props.whatsappPhoneOptions} />
        )}
      </BlankSidePanel>
      <DeleteModal modalProps={deleteStepModalProps}>Are you sure you want to delete the step? You won't be able to get it back after deletion.</DeleteModal>
    </div>
  );
}
