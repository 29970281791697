import React from "react";
import { Form } from "react-bootstrap";

export default function RadioGroupSelect({ apiKeyName, setValue, value, options, isRow }) {
  return (
    <>
      <Form.Group id="custom-form-radio-group-select" className={isRow ? "d-flex justify-content-between align-items-center" : ""} onClick={(e) => setValue(e.target.name, apiKeyName)}>
        {options.map((each, index) => {
          return (
            <div className="p-2 m-2">
              <Form.Check type="radio" label={each} name={each} checked={value === each} id={index} />
            </div>
          );
        })}
      </Form.Group>
    </>
  );
}
