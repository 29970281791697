import { takeLatest, put, call } from "redux-saga/effects";
import { endpoints } from "../../endpoint";
import api from "../../../../../network/apis/api";
import * as activityActions from "../../actions/admin-actions/activity-settings.actions";
import { getSettingActivity, postActivity, getActivity, patchActivity, deleteActivity } from "../../types/admin-settings.types";
import { dispatchSnackbarError } from "../../../../../utils/toaster";

function* getSettingActivityFunction(action) {
  try {
    const res = yield call(api.get, endpoints.ACTIVITY, action.payload);
    if (res.status === 200) {
      yield put(activityActions.getSettingActivitySuccess(res.data.data));
    } else yield put(activityActions.getSettingActivityError("error"));
  } catch (e) {
    yield put(activityActions.getSettingActivityError(e));
  }
}

function* postActivityFunction(action) {
  try {
    const res = yield call(api.post, endpoints.ACTIVITY, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(activityActions.postActivitySuccess(res.data));
    } else if (res.status === 201) {
      yield action.cb(res.data);
      yield put(activityActions.postActivitySuccess(res.data));
    } else {
      yield put(activityActions.postActivityError("error"));
    }
  } catch (e) {
    yield put(activityActions.postActivityError(e));
  }
}

function* getActivityFunction(action) {
  try {
    const res = yield call(api.get, endpoints.ACTIVITY, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(activityActions.getActivitySuccess(res.data));
    } else if (res.status === 201) {
      yield action.cb(res.data);
      yield put(activityActions.getActivitySuccess(res.data));
    } else {
      yield put(activityActions.getActivityError("error"));
    }
  } catch (e) {
    yield put(activityActions.getActivityError(e));
  }
}

function* patchActivityFunction(action) {
  try {
    const res = yield call(api.patch, `/api/crm/activity/${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(activityActions.patchActivitySuccess(res.data));
    } else {
      yield put(activityActions.patchActivityError("error"));
    }
  } catch (e) {
    yield put(activityActions.patchActivityError(e));
  }
}

function* deleteActivityFunction(action) {
  try {
    const res = yield call(api.delete, `/api/crm/activity/${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(activityActions.deleteActivitySuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError("Activity can not be deleted", "error");
    } else {
      yield put(activityActions.deleteActivityError("error"));
    }
  } catch (e) {
    yield put(activityActions.deleteActivityError(e));
  }
}

export default function*() {
  //email sync saga
  yield takeLatest(getSettingActivity.GET_SETTING_ACTIVITY_REQUEST, getSettingActivityFunction);
  yield takeLatest(postActivity.POST_ACTIVITY_REQUEST, postActivityFunction);
  yield takeLatest(getActivity.GET_ACTIVITY_REQUEST, getActivityFunction);
  yield takeLatest(patchActivity.PATCH_ACTIVITY_REQUEST, patchActivityFunction);
  yield takeLatest(deleteActivity.DELETE_ACTIVITY_REQUEST, deleteActivityFunction);
}
