import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BlueGradientButton } from "../../../../../components/button";
import { IconButton2 } from "../../../../../components/button/icon-action-buttons";
import {
  CadenceSVG,
  // GridViewSVG, ListViewSVG,
  WorkflowSVG,
} from "../../../../../components/custom-svgs/sub-header-svg";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import { DeleteModal } from "../../../../../components/modal";
import AdavanceDataTable from "../../../../../components/normal-table/advance-table";
import CustomPagination from "../../../../../components/pagination/custom-pagination";
import { CustomSubHeader } from "../../../../../components/subheader";
// import { IconBasedNav } from "../../../../../components/subheader/IconBasedNav";
import { SubheaderNav } from "../../../../../components/subheader/subheaderNav";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import ContentWrapper from "../../../../Wrapper";
import { AddNewCadenceModal, SELECTED_STEP_TYPES } from "../../components/add-new-cadence-modal";

//actions
import { deleteCadenceByIdRequest, getCadenceByIdReset, getCadenceListRequest, updateCadenceStatusRequest } from "../../store/actions/cadence_actions";
import { getListOfCadenceCloneableTemplateRequest } from "../../store/actions/cadence_cloneable_template_actions";
import { createCadenceByTemplateRequest, getListOfCadencePrebuiltTemplateRequest } from "../../store/actions/cadence_prebuilt_template_actions";
import CadenceSettingsSidePannel from "../cadence-settings/cadence-settings.sidepannel";
import CadenceListCard from "./list-card";

export default function Cadence(props) {
  const tabName = "Sales Cadence";
  const links = [
    { name: "Sales Cadence", to: "/automation/cadence/list", svg: CadenceSVG },
    { name: "Workflow Automation", to: "/automation/workflow/list", svg: WorkflowSVG },
  ];
  // const [subLinks] = useState([
  //   { name: "Card View", icon: "bi bi-distribute-horizontal", svg: GridViewSVG },
  //   { name: "List View", icon: "bi bi-list", svg: ListViewSVG },
  // ]);
  const [subLinkName, setSubLinkName] = useState("Card View");
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(5);
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCadenceSettings, setShowCadenceSettings] = useState(false);

  //actions
  const [showDeleteCadence, setShowDeleteCadence] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const cadenceList = useSelector((store) => store.cadence.list);
  const cadenceSettings = useSelector((store) => store.cadence.settings);

  useEffect(() => {
    dispatch(getCadenceListRequest({ limit: limit, page: pageNo, searchText: "" }));
    dispatch(getCadenceByIdReset());
    dispatch(getListOfCadencePrebuiltTemplateRequest());
    dispatch(getListOfCadenceCloneableTemplateRequest());
  }, [dispatch, limit, pageNo]);

  useEffect(() => {
    const viewType = localStorage.getItem("cadence-view");
    if (viewType) setSubLinkName(viewType);
  }, []);

  const handleAddCadenceClick = () => {
    setShowAdd(true);
    // history.push(`/automation/cadence/create`);
  };

  const handleEdit = (cadence) => {
    history.push(`/automation/cadence/edit/${cadence._id}`);
  };

  const handleDelete = (cadence) => {
    setShowDeleteCadence(true);
    setSelectedId(cadence._id);
  };

  const onStatusChange = (id, status) => {
    const payload = {
      id: id,
      updateData: { isEnabled: status },
      loadingData: { limit: limit, page: pageNo, searchText: "" },
    };
    dispatch(updateCadenceStatusRequest(payload));
  };

  const handleDeleteCadenceAccept = () => {
    dispatch(deleteCadenceByIdRequest({ id: selectedId, from: "cadence-list", loadingData: { limit: limit, page: pageNo, searchText: "" } }));
    setShowDeleteCadence(false);
    setSelectedId(null);
  };
  const handleDeleteCadenceCancel = () => {
    setShowDeleteCadence(false);
    setSelectedId(null);
  };

  const deleteCadenceModalProps = {
    show: showDeleteCadence,
    title: "Delete Cadence",
    handleClose: handleDeleteCadenceCancel,
    handleAccept: handleDeleteCadenceAccept,
  };

  const onCreateCadenceClick = (templateId, step) => {
    setLoading(true);
    const payload = {
      type: step === SELECTED_STEP_TYPES.PREBUILT ? "template" : step === SELECTED_STEP_TYPES.CLONE ? "cadence" : "",
      cadenceId: templateId,
    };
    dispatch(
      createCadenceByTemplateRequest(payload, (data) => {
        if (data.status) {
          setLoading(false);
          history.push(`/automation/cadence/edit/${data.id}`);
        } else {
          setLoading(false);
          dispatchSnackbarError(data.message);
        }
      }),
    );
  };

  const columns = [
    { title: "Cadence Name", apiKeyName: "name", fieldInputType: "INPUT", isAction: false, isFixed: false },

    { title: "Total", apiKeyName: "total", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Pending", apiKeyName: "pending", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Active", apiKeyName: "active", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Paused", apiKeyName: "paused", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Finished", apiKeyName: "finished", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Email Delivered", apiKeyName: "campaignEmailSent", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Email Opened", apiKeyName: "campaignEmailOpened", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Email Replied", apiKeyName: "campaignEmailReplied", fieldInputType: "INPUT", isAction: false, isFixed: false },

    { title: "Status", apiKeyName: "isEnabled", fieldInputType: "SWITCH_BUTTON", isAction: false, isFixed: false },
    { title: "Owner", apiKeyName: "createdBy", fieldInputType: "OWNER_SELECT", isAction: false, isFixed: false },

    { title: "Last Updated", apiKeyName: "updatedAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },

    { title: "Actions", apiKeyName: "action", fieldInputType: "ACTION", isAction: true, isFixed: true, options: ["edit", "delete"] },
  ];

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <SubheaderNav links={links} active={tabName} />
            </div>
            <div className="d-flex align-items-center">
              {/* <IconBasedNav
                links={subLinks}
                active={subLinkName}
                onClick={(a) => {
                  setSubLinkName(a);
                  localStorage.setItem("cadence-view", a);
                }}
              /> */}
              <span className="fw-bold">{` Subscribers ${cadenceSettings.data.availableSubscribers}/${cadenceSettings.data.montlySubscriberLimit}`}</span>
              <div className="mr-1">
                <IconButton2 className="d-flex flex-stack import-icon-container text-hover-primary bg-hover-light-primary" tooltip="Cadence Settings" onClick={() => setShowCadenceSettings(true)}>
                  <i class="bi bi-gear"></i>
                </IconButton2>
              </div>
              <BlueGradientButton size="sm" onClick={handleAddCadenceClick}>
                + Cadence
              </BlueGradientButton>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      {cadenceList.isLoading ? (
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : cadenceList.data.length === 0 ? (
        <CustomEmptyPage page="cadence" setShowAdd={handleAddCadenceClick} />
      ) : (
        <div className="scroll overflow-auto h-vh-162px">
          {subLinkName === "List View" && false ? (
            <div className="card card-custom">
              <div className="card-body py-3 px-3">
                <AdavanceDataTable
                  columns={columns}
                  data={cadenceList.data.map((content) => {
                    return {
                      _id: content._id,
                      name: content.name,
                      createdBy: content.createdBy,
                      updatedAt: content.updatedAt,
                      // stats
                      total: content.subscriberStats.pending + content.subscriberStats.active + content.subscriberStats.paused + content.subscriberStats.finished,
                      pending: content.subscriberStats.pending,
                      active: content.subscriberStats.active,
                      paused: content.subscriberStats.paused,
                      finished: content.subscriberStats.finished,
                      campaignEmailSent: content.stats.campaignEmailSent,
                      campaignEmailOpened: content.stats.campaignEmailOpened,
                      campaignEmailReplied: content.stats.campaignEmailReplied,
                      isEnabled: content.isEnabled,
                    };
                  })}
                  limit={limit}
                  pageNo={pageNo}
                  setLimit={setLimit}
                  setPageNo={setPageNo}
                  count={cadenceList.count}
                  //actions
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  onStatusChange={onStatusChange}
                />
              </div>
            </div>
          ) : (
            <>
              {" "}
              <div>
                {cadenceList.data.map((content, index) => {
                  return (
                    <CadenceListCard
                      data={{
                        _id: content._id,
                        name: content.name,
                        createdBy: content.createdBy,
                        updatedAt: content.updatedAt,
                        owner: content.owner,
                        // stats
                        total: content.subscriberStats.total,
                        pending: content.subscriberStats.pending,
                        active: content.subscriberStats.active,
                        paused: content.subscriberStats.paused,
                        finished: content.subscriberStats.finished,

                        sent: content.stats.campaignEmailSent + content.stats.syncEmailSent,
                        opened: content.stats.campaignEmailOpened + content.stats.syncEmailOpened,
                        replied: content.stats.campaignEmailReplied + content.stats.syncEmailReplied,

                        isEnabled: content.isEnabled,
                      }}
                      key={index}
                      onStatusChange={onStatusChange}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  );
                })}
              </div>
              <div className="pt-7px d-flex flex-row align-items-center justify-content-end px-3 gap-15px">
                <p className="mt-5px mb-0 font-size-14px">
                  Showing {(pageNo - 1) * limit + 1}-{cadenceList.count > pageNo * limit ? pageNo * limit : cadenceList.count} of {cadenceList.count}
                </p>
                {CustomPagination(pageNo, setPageNo, Math.ceil(cadenceList.count / limit), () => {}, limit, setLimit, cadenceList.count)}
              </div>
            </>
          )}
        </div>
      )}
      <DeleteModal modalProps={deleteCadenceModalProps}>Are you sure want to delete this cadence?</DeleteModal>
      <AddNewCadenceModal loading={loading} show={showAdd} setShow={setShowAdd} onCreateCadenceClick={onCreateCadenceClick} />
      <CadenceSettingsSidePannel show={showCadenceSettings} setShow={setShowCadenceSettings} />
      {/* <CreateCadenceModal loading={loading} show={showAdd} setShow={setShowAdd} onCreateCadenceClick={onCreateCadenceClick} /> */}
    </ContentWrapper>
  );
}
