import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { ContentWithCardLoading } from "../../../../components/loading";
import AdavanceDataTable from "../../../../components/normal-table/advance-table";
import CustomSidePanel from "../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import { dispatchSnackbarError } from "../../../../utils/toaster";
//  actions
import * as shortLinkAction from "../../store/actions/campaign_short_link_actions";
import { DeleteModal } from "../../../../components/modal";
import { Button, Form, InputGroup } from "react-bootstrap";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import { BlueGradientButton } from "../../../../components/button";

export default function ShortLinkManager(props) {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // form fields
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");
  const [trackUser, setTrackUser] = useState(false);

  const dispatch = useDispatch();

  const shortLinkStore = useSelector((store) => store.campaign.shortLink.list);

  useEffect(() => {
    dispatch(shortLinkAction.getListOfShortLinksRequest({ limit: limit, page: page }));
  }, [dispatch, limit, page]);

  const handleDeleteClick = (data) => {
    setShowDelete(true);
    setSelectedId(data._id);
  };
  const handleDeleteAccept = () => {
    dispatch(shortLinkAction.deleteShortLinkRequest({ id: selectedId }));
    setShowDelete(false);
    setSelectedId(null);
  };
  const handleDeleteCancel = () => {
    setShowDelete(false);
    setSelectedId(null);
  };

  const deleteLeadBotModelProps = {
    show: showDelete,
    title: "Delete Short Link",
    handleClose: handleDeleteCancel,
    handleAccept: handleDeleteAccept,
  };

  const handleEditClick = (data) => {
    setShow(true);
    setIsEdit(true);
    setSelectedId(data._id);
    setTitle(data.title);
    setSlug(data.slug);
    setOriginalUrl(data.originalUrl);
    setTrackUser(data.trackUser);
  };

  const handleCancelClick = () => {
    setShow(false);
    setIsEdit(false);
    setSelectedId(null);
    setTitle("");
    setSlug("");
    setOriginalUrl("");
    setTrackUser(false);
  };

  const handleApplyClick = () => {
    if (_.isEmpty(title)) {
      return dispatchSnackbarError("Title cannot be empty!");
    }
    if (!isEdit && _.isEmpty(slug)) {
      return dispatchSnackbarError("Slug cannot be empty!");
    }
    if (!isEdit && _.isEmpty(originalUrl)) {
      return dispatchSnackbarError("Original URL cannot be empty!");
    }

    const payload = {
      data: {
        title: title,
        slug: slug,
        originalUrl: originalUrl,
        trackUser: trackUser ? true : false,
      },
      requestPayload: { limit: limit, page: page },
    };
    if (isEdit) {
      payload["id"] = selectedId;
      dispatch(
        shortLinkAction.editShortLinkRequest(payload, (data) => {
          if (data.status) {
            handleCancelClick();
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    } else {
      dispatch(
        shortLinkAction.createShortLinkRequest(payload, (data) => {
          if (data.status) {
            handleCancelClick();
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    }
  };

  const handleOnChoose = (data) => {
    props.handleShortLinkSelect(data.shortLink);
    props.handleClose(false);
  };

  return (
    <CustomSidePanel show={props.show} moduleName={`Short Link`} handleClose={props.handleClose}>
      <div className="card-header d-flex flex-stack align-items-center">
        <h3 className="card-title fw-bolder text-dark">Short Link</h3>
        <div>
          <BlueGradientButton
            variant="success"
            size="sm"
            onClick={() => {
              setShow(true);
              setIsEdit(false);
            }}
          >
            + Short Link
          </BlueGradientButton>
        </div>
      </div>
      <div className="w-100 p-8">
        {shortLinkStore.loading ? (
          <ContentWithCardLoading />
        ) : shortLinkStore.data.length === 0 ? (
          <CustomEmptyPage
            page="shortLink"
            setShowAdd={() => {
              setShow(true);
              setIsEdit(false);
            }}
          />
        ) : (
          <div className="card card-custom">
            <div className="card-body py-4 px-4 border border-1">
              <AdavanceDataTable
                columns={shortLinkStore.managerColumns}
                data={shortLinkStore.data}
                limit={limit}
                pageNo={page}
                setLimit={setLimit}
                setPageNo={setPage}
                count={shortLinkStore.count}
                // actions
                handleDelete={handleDeleteClick}
                handleEdit={handleEditClick}
                handleOnChoose={handleOnChoose}
              />
            </div>
          </div>
        )}
      </div>
      <BlankSidePanel show={show} handleClose={() => setShow(false)} title={isEdit ? "Edit Smart Short Link" : "Add Smart Shortlink"}>
        <Form.Group>
          <Form.Label>URL Title</Form.Label>
          <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Group>
        {!isEdit && (
          <Form.Group>
            <Form.Label>Original URL</Form.Label>
            <Form.Control type="text" value={originalUrl} onChange={(e) => setOriginalUrl(e.target.value)} />
          </Form.Group>
        )}

        {!isEdit && (
          <Form.Group>
            <Form.Label>URL Shortner</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">sm6.co/</InputGroup.Text>
              <Form.Control type="text" value={slug} placeholder="slug" aria-label="slug" onChange={(e) => setSlug(e.target.value)} />
            </InputGroup>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Mobile Tracking</Form.Label>
          <div className="form-check form-switch form-check-custom form-check-solid mr-5 pr-5">
            <input
              className="form-check-input h-20px w-35px"
              type="checkbox"
              checked={trackUser}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setTrackUser(e.target.checked);
              }}
              id="flexSwitch20x30"
            />
          </div>
        </Form.Group>
        <Form.Group className="d-flex justify-content-start">
          <Button className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button className="btn btn-sm btn-primary" onClick={handleApplyClick}>
            Apply
          </Button>
        </Form.Group>
      </BlankSidePanel>
      <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete a short link? you won't be able to recover data associated with short link.u</DeleteModal>
    </CustomSidePanel>
  );
}
