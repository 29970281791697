
import { getAccountBillingV2, getBillingInvoiceListV2, getBillingPricesV2 } from "../types/subscription_billing_v2_types";

const initialState = {
    billing: {
        loading: false,
        data: {}
    },
    invoice: {
        list: [],
        loading: false,
    },
    prices: {
        loading: false,
        data: {}
    }
};

export default function subscriptionBillingV2Reducer(state = initialState, action = {}) {
    switch (action.type) {
        case getAccountBillingV2.REQUEST:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    loading: true,
                },
            };
        case getAccountBillingV2.SUCCESS:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    loading: false,
                    data: action.payload,
                },
            };
        case getAccountBillingV2.ERROR:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    loading: false,
                },
            };

        case getBillingInvoiceListV2.REQUEST:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: true,
                },
            };
        case getBillingInvoiceListV2.SUCCESS:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: false,
                    list: action.payload.data,
                },
            };
        case getBillingInvoiceListV2.ERROR:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: false,
                },
            };

        case getBillingPricesV2.REQUEST:
            return {
                ...state,
                prices: {
                    ...state.prices,
                    loading: true,
                },
            };
        case getBillingPricesV2.SUCCESS:
            return {
                ...state,
                prices: {
                    ...state.prices,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case getBillingPricesV2.ERROR:
            return {
                ...state,
                prices: {
                    ...state.prices,
                    loading: false,
                },
            };
        default:
            return state;
    }
}
