import React, { useState, useEffect } from "react";
import _ from "lodash";
import NormalTable from "../../../../components/normal-table/index";
import { Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import Pagination from "../../../../components/normal-table/pagination";
import CustomAction from "../../../../components/ActionColumn/index";
import { UpgradePlanPage } from "../../../../components/empty_page";
import SubscriptionPlanModal from "../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import { TabWithActions } from "./tab-with-actions";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import * as developerSettingsActions from "../../store/actions/admin-actions/developer-settings.actions";
import { ContentWithCardLoadingForSettings } from "../../../../components/loading";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import { InputLimit } from "../../../../constants";
import AccordionComponent from "../../../../components/accordion/accordion-component";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import moment from "moment-timezone";
import { DeleteModal } from "../../../../components/modal";

const links = [
  { name: "API Keys", to: "/settings/account/developer/api-key" },
  { name: "Webhook", to: "/settings/account/developer/webhook" },
];

function isValidIP(value) {
  // Regular expression to match an IP address
  const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  // Regular expression to match a range of IP addresses
  const ipRangeRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/\d{1,2}$/;

  // Regular expression to match an IPv6 address
  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

  // if (value.includes("-")) {
  //   const [start, end] = value.split('-');

  //   // Test if the start and end IP addresses are valid
  //   return ipRegex.test(start) && ipRegex.test(end);
  // }

  // Test if the provided value is a valid IP address or a valid range of IP addresses
  return ipRegex.test(value) || ipRangeRegex.test(value) || ipv6Regex.test(value);
}
export default function APIKey() {
  const [tabName] = useState("API Keys");
  const [show, setShow] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [total, setTotal] = useState(0);
  const [listData, setListData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);

  //form
  const [name, setName] = useState("");
  const [allowedIPs, setAllowedIPs] = useState("");
  const [expiry, setExpiry] = useState(null);
  const [salesModules] = useState(["lead", "deal", "activity", "people", "company", "product"]);
  const [salesPermissions, setSalesPermissions] = useState({
    lead: "none",
    deal: "none",
    activity: "none",
    people: "none",
    company: "none",
    product: "none",
  });
  const [campaignPermissions, setCampaignPermissions] = useState({
    sendEmailAPI: false,
    sendSMSAPI: false,
  });

  const dispatch = useDispatch();

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const apiKeyStore = useSelector((store) => store.settings.admin.developer.getkey);

  useEffect(() => {
    dispatch(developerSettingsActions.getKeyRequest());
  }, [dispatch]);

  useEffect(() => {
    // current page slice
    const slicedData = apiKeyStore.list.slice((page - 1) * limit, (page - 1) * limit + limit);
    setListData(slicedData);
    setTotal(apiKeyStore.list.length);
  }, [limit, page, apiKeyStore.list]);

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    dispatchSnackbarSuccess("API Key Successfully Copied", "success");
  };
  const onEditClick = (data) => {
    setName(data.name);
    if (data.allowedIPs) setAllowedIPs(data.allowedIPs.join(", "));
    else setAllowedIPs("");
    if (data.expiry) setExpiry(moment(data.expiry).format("YYYY-MM-DD"));
    else setExpiry(null);
    if (data.permissions) {
      setCampaignPermissions(data.permissions.campaign);
      setSalesPermissions(data.permissions.sales);
    } else {
      setSalesPermissions({
        lead: "full",
        deal: "full",
        activity: "full",
        people: "full",
        company: "full",
        product: "full",
      });
      setCampaignPermissions({
        sendEmailAPI: false,
        sendSMSAPI: false,
      });
    }
    setSelectedId(data._id);
    setIsEdit(true);
    setShow(true);
  };
  const onDeleteClick = (data) => {
    setShowDelete(true);
    setSelectedId(data._id);
  };

  const handleAddAPIButtonClick = () => {
    setShow(true);
    setIsEdit(false);
  };

  const handleAddEditCloseClick = () => {
    setShow(false);
    setIsEdit(false);
    clearData();
  };

  const clearData = () => {
    setSelectedId("");

    //form
    setName("");
    setAllowedIPs("");
    setExpiry(null);
    setSalesPermissions({
      lead: "none",
      deal: "none",
      activity: "none",
      people: "none",
      company: "none",
      product: "none",
    });
    setCampaignPermissions({
      sendEmailAPI: false,
      sendSMSAPI: false,
    });
  };

  const handleSalesPermissionChecked = (_module, value) => {
    const _permissions = { ...salesPermissions };
    _permissions[_module] = value;
    setSalesPermissions(_permissions);
  };

  const handleOnSaveClick = () => {
    if (_.isEmpty(name)) {
      dispatchSnackbarError("Name field cannot be empty.");
    }
    if (allowedIPs) {
      const ipList = allowedIPs.split(",");
      for (const each of ipList) {
        if (!isValidIP(each.trim())) {
          return dispatchSnackbarError(`Please enter valid ip address: ${each.trim()}`);
        }
      }
    }
    const _ips = allowedIPs
      .split(",")
      .map((each) => each.trim())
      .filter((a) => a);

    if (isEdit) {
      const payload = {
        id: selectedId,
        data: {
          name: name,
          isIPRestricted: _ips.length > 0 ? true : false,
          allowedIPs: _ips,
          isExpiryEnabled: expiry ? true : false,
          expiry: expiry,
          permissions: {
            campaign: campaignPermissions,
            sales: salesPermissions,
          },
        },
      };
      setLoading(true);
      dispatch(
        developerSettingsActions.patchKeyRequest(payload, (data) => {
          if (data.success || data.status) {
            setShow(false);
            setSelectedId("");
            setIsEdit(false);
            clearData();
          } else {
            dispatchSnackbarError(data.message);
          }
          setLoading(false);
        }),
      );
    } else {
      const payload = {
        name: name,
        isIPRestricted: _ips.length > 0 ? true : false,
        allowedIPs: _ips,
        isExpiryEnabled: expiry ? true : false,
        expiry: expiry,
        permissions: {
          campaign: campaignPermissions,
          sales: salesPermissions,
        },
      };
      setLoading(true);
      dispatch(
        developerSettingsActions.postKeyRequest(payload, (data) => {
          if (data.success || data.status) {
            setShow(false);
            setIsEdit(false);
            clearData();
          } else {
            dispatchSnackbarError(data.message);
          }
          setLoading(false);
        }),
      );
    }
  };

  const handleDeleteAccept = () => {
    dispatch(developerSettingsActions.deleteKeyRequest({ id: selectedId }));
    setShowDelete(false);
    setSelectedId("");
  };
  const handleDeleteCancel = () => {
    setShowDelete(false);
    setSelectedId("");
  };

  const deleteAPIKeyModalProps = {
    show: showDelete,
    title: "Delete API Key",
    handleClose: handleDeleteCancel,
    handleAccept: handleDeleteAccept,
  };

  return (
    <div>
      <TabWithActions
        links={links}
        active={tabName}
        actions={
          accountBillingStore.data.servicesOffered &&
          accountBillingStore.data.servicesOffered.API_AND_WEBHOOK && (
            <>
              <span className="mr-2 px-2">
                <a target={"_blank"} rel="noopener noreferrer" href="https://apidocs.salessimplify.com">
                  API Documentation
                </a>
              </span>
              <div className="ms-auto" onClick={handleAddAPIButtonClick}>
                <Button className="commonStyle btn btn-sm btn-primary my-1">+ API</Button>
              </div>
            </>
          )
        }
      />
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.API_AND_WEBHOOK ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"API"}
            planName={"free plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : apiKeyStore.loading ? (
        <ContentWithCardLoadingForSettings />
      ) : listData.length === 0 ? (
        <CustomEmptyPage
          page="apiKey"
          customButton={
            <div className="ms-auto" onClick={handleAddAPIButtonClick}>
              <Button className="commonStyle btn btn-sm btn-primary my-1">+ API</Button>
            </div>
          }
        />
      ) : (
        <>
          <NormalTable tableHeading={["Api name", "", "Token", "", "Action "]} tableTitle={"API Keys"} buttonHead={<></>}>
            {listData.map((apiData, id) => {
              return (
                <tr key={id}>
                  <td>{apiData.name}</td>
                  <td></td>
                  <td>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{apiData.key}</Tooltip>}>
                      <span className="d-flex" key="transition-group-content">
                        <div className="overflow-hidden text-overflow-ellipsis text-nowrap w-400px">{apiData.key}</div>
                        <i className="bi bi-files cursor-pointer" onClick={() => copyToClipBoard(apiData.key)}></i>
                      </span>
                    </OverlayTrigger>
                  </td>
                  <td></td>
                  <td className="d-flex w-150px">
                    <CustomAction Edit={() => onEditClick(apiData)} Delete={() => onDeleteClick(apiData)} />
                  </td>
                </tr>
              );
            })}
          </NormalTable>

          <div className="position-absolute right-20px mt-minus-21px">
            <Pagination total={total} itemsPerPage={limit} currentPage={page} onPageChange={(page) => setPage(page)} />
          </div>
        </>
      )}
      <BlankSidePanel show={show} handleClose={handleAddEditCloseClick} title={isEdit ? "Edit API Key" : "Add API Key"}>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" maxLength={InputLimit.TEXT} value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Set access restrictions (optional)</Form.Label>
            <Form.Control type="text" maxLength={InputLimit.TEXTAREA} value={allowedIPs} onChange={(e) => setAllowedIPs(e.target.value)} />
            <Form.Text className="text-muted">
              To further secure your connection you can optionally limit access to a single IP or range of IPs. The following formats are supported and delimited by a comma for multiple IPs or IP Ranges: xxx.xxx.xxx.xxx,
              xxx.xxx.xxx.xxx/xx and IPv6.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Expiry Date (optional)</Form.Label>
            <Form.Group className="d-flex gap-3">
              <Form.Control type="date" value={expiry} onChange={(e) => setExpiry(e.target.value)} />
              {expiry && (
                <Button variant="secondary" size="sm" onClick={() => setExpiry(null)}>
                  Clear
                </Button>
              )}
            </Form.Group>
          </Form.Group>
          <Form.Label>Permissions</Form.Label>
          <AccordionComponent title="Campaign (Communication Channels)" defaultActiveKey="0">
            <Form.Group controlId="formBasicSwitch" className="d-flex gap-4 align-items-center">
              <Form.Label>Send Email via API</Form.Label>
              <div className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input h-20px w-35px border border-secondary"
                  type="checkbox"
                  checked={campaignPermissions.sendEmailAPI}
                  onChange={(e) => setCampaignPermissions({ ...campaignPermissions, sendEmailAPI: e.target.checked })}
                  id="flexSwitch20x30"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicSwitch" className="d-flex gap-4 align-items-center">
              <Form.Label>Send SMS via API</Form.Label>
              <div className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input h-20px w-35px border border-secondary"
                  type="checkbox"
                  checked={campaignPermissions.sendSMSAPI}
                  onChange={(e) => setCampaignPermissions({ ...campaignPermissions, sendSMSAPI: e.target.checked })}
                  id="flexSwitch20x30"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicSwitch" className="d-flex gap-4 align-items-center mb-0">
              <Form.Label>Send Whastapp Message via API</Form.Label>
              <div className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input h-20px w-35px border border-secondary"
                  type="checkbox"
                  checked={campaignPermissions.sendWhatsappAPI}
                  onChange={(e) => setCampaignPermissions({ ...campaignPermissions, sendWhatsappAPI: e.target.checked })}
                  id="flexSwitch20x30"
                />
              </div>
            </Form.Group>
          </AccordionComponent>
          <AccordionComponent title="Sales Platform" defaultActiveKey="0">
            <table className="w-100">
              <thead>
                <tr>
                  <th className="p-2 w-45">Module Name</th>
                  <th className="text-center p-2 w-15prc">None</th>
                  <th className="text-center p-2 w-15prc">View</th>
                  <th className="text-center p-2 w-15prc">Add</th>
                  <th className="w-25prc text-center p-2">Full Access</th>
                </tr>
              </thead>
              <tbody>
                {salesModules.map((each, index) => {
                  return (
                    <tr key={index} className="mb-2">
                      <td align="start" className="p-2">
                        {_.capitalize(each)}
                      </td>
                      <td align="center" className="text-center p-2">
                        <Form.Check className="pl-10" type={"radio"} id={each + index + "none"}>
                          <Form.Check.Input checked={salesPermissions[each] === "none"} className="custom-radio" onChange={(e) => handleSalesPermissionChecked(each, "none")} type={"radio"} varient="primary" />
                        </Form.Check>
                      </td>
                      <td align="center" className="text-center p-2">
                        <Form.Check className="pl-10" type={"radio"} id={each + index + "view"}>
                          <Form.Check.Input checked={salesPermissions[each] === "view"} className="custom-radio" onChange={(e) => handleSalesPermissionChecked(each, "view")} type={"radio"} varient="primary" />
                        </Form.Check>
                      </td>
                      <td align="center" className="text-center p-2">
                        <Form.Check className="pl-10" type={"radio"} id={each + index + "add"}>
                          <Form.Check.Input checked={salesPermissions[each] === "add"} className="custom-radio" onChange={(e) => handleSalesPermissionChecked(each, "add")} type={"radio"} varient="primary" />
                        </Form.Check>
                      </td>
                      <td align="center" className="text-center p-2">
                        <Form.Check className="pl-10" type={"radio"} id={each + index + "full"}>
                          <Form.Check.Input checked={salesPermissions[each] === "full"} className="custom-radio" onChange={(e) => handleSalesPermissionChecked(each, "full")} type={"radio"} varient="primary" />
                        </Form.Check>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </AccordionComponent>

          <Form.Group className="d-flex justify-content-start">
            {/* <Button className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={onCancelClick}>
              Cancel
            </Button> */}
            <Button className="btn btn-sm btn-primary" onClick={handleOnSaveClick}>
              {loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
              {isEdit ? "Update Details" : "Generate API"}
            </Button>
          </Form.Group>
        </Form>
      </BlankSidePanel>

      <DeleteModal modalProps={deleteAPIKeyModalProps}>Are you sure you want to delete the API key?</DeleteModal>
    </div>
  );
}
