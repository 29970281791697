import _ from "lodash";
import validator from "validator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Dropdown } from "react-bootstrap";
import { BlueGradientButton } from "../../../../../../components/button";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import AddDomainModal from "../../../../components/add-domain-modal";
import { DeleteModal } from "../../../../../../components/modal";
import DefaultSenderAddressModal from "../../../../components/default-sender-modal";
import DomainVerificationModal from "../../../../components/domain-verification";
import { getListOfEmailDomainSettingsRequest, addDomainSettingsRequest, deleteEmailDomainSettingsRequest, addEmailToDomainSettingsRequest } from "../../../../store/actions/campaign_email_settings_actions";
import { ContentWithCardLoading } from "../../../../../../components/loading";
import { CustomEmptyPage } from "../../../../../../components/empty_page/custom-empty-page";
import DomainDNSRecords from "../../../../components/domain-dns-records";

export default function DomainSettings(props) {
  const [showDomainVerificationPopup, setShowDomainVerificationPopup] = useState(false);
  const [showSenderAddressPopup, setShowSenderAddressPopup] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [senderName, setSenderName] = useState("");
  const [defaultName, setDefaultName] = useState("");
  const [showDeleteDomain, setShowDeleteDomain] = useState(false);
  const [showAddDomainPopup, setShowAddDomainPopup] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [loading] = useState(false);
  const [showAddDefultSenderEmail, setShowAddDefultSenderEmail] = useState(false);
  const [defaultSenderDomain, setDefaultSenderDomain] = useState("");

  const dispatch = useDispatch();

  const domainStore = useSelector((store) => store.campaign.settings.email.domain);

  useEffect(() => {
    dispatch(getListOfEmailDomainSettingsRequest());
  }, [dispatch]);

  useEffect(() => {
    for (const eachDomain of domainStore.list) {
      const _domainArray = eachDomain.domain.split(" ");
      if (_domainArray.length === 2) {
        const email = _domainArray[1].replace(/\(|\)/g, "");
        if (validator.isEmail(email)) {
          setShowAddDefultSenderEmail(false);
          setDefaultSenderDomain("");
          break;
        }
      } else if (!eachDomain.verify) {
        setShowAddDefultSenderEmail(true);
        setDefaultSenderDomain(eachDomain.domain);
      }
    }
  }, [domainStore.list]);

  const isValid = (each) => {
    return each.spf && each.dkim && each.trackingStatus === 0 && each.dmarc;
  };

  const onDomainVerificationClick = (domain) => {
    const splitData = domain.split(" ");
    if (splitData.length > 1) {
      setShowDomainVerificationPopup(true);
      setSelectedDomain(splitData[0]);
      setDefaultName(splitData[1].replace(/\(|\)/g, "").split("@")[0]);
    } else {
      setShowDomainVerificationPopup(true);
      setSelectedDomain(domain);
      setDefaultName("mailer");
    }
  };

  const handleDomainVerificationCancel = () => {
    setShowDomainVerificationPopup(false);
    setSelectedDomain("");
    setDefaultName("");
  };

  const handleDomainVerificationAccept = () => {
    if (_.isEmpty(defaultName)) {
      return dispatchSnackbarError("Default ID cannot be Empty");
    }
    dispatch(addEmailToDomainSettingsRequest({ email: `${defaultName}@${selectedDomain}` }));
    setSelectedDomain("");
    setShowDomainVerificationPopup(false);
  };

  const onSenderAddressClick = (domain) => {
    const splitData = domain.split(" ");
    if (splitData.length > 1) {
      setShowSenderAddressPopup(true);
      setSelectedDomain(splitData[0]);
      setSenderName(splitData[1].replace(/\(|\)/g, "").split("@")[0]);
    } else {
      setShowSenderAddressPopup(true);
      setSelectedDomain(domain);
      setSenderName("mailer");
    }
  };

  const handleSenderAddressCancel = () => {
    setShowSenderAddressPopup(false);
    setDomainName("");
  };

  const handleSenderAddressAccept = () => {
    if (_.isEmpty(senderName)) {
      return dispatchSnackbarError("Sender email cannot be empty!");
    }
    dispatch(
      addEmailToDomainSettingsRequest({
        email: `${senderName}@${selectedDomain}`,
      }),
    );
    setDomainName("");
    setSelectedDomain("");
    setShowSenderAddressPopup(false);
  };

  const onAddDomainClick = () => {
    setShowAddDomainPopup(true);
    setDomainName("");
  };

  const handleAddDomainCancel = () => {
    setShowAddDomainPopup(false);
    setDomainName("");
  };

  const handleAddDomainAccept = () => {
    if (!domainName) {
      return dispatchSnackbarError("Domain Name cannot be Empty");
    }
    if (domainName.split(".").length > 3) {
      return dispatchSnackbarError("We only allow single-level subdomains like emails.yourdomain.com.");
    }
    if (domainName.length < 5) {
      return dispatchSnackbarError("Please enter valid domain");
    }
    dispatch(addDomainSettingsRequest({ domain: domainName }));

    setShowAddDomainPopup(false);
    setDomainName("");
  };

  const handleOnDeleteClick = (domain) => {
    const splitData = domain.split(" ");
    if (splitData.length > 1) {
      setSelectedDomain(splitData[0]);
    } else {
      setSelectedDomain(domain);
    }
    setShowDeleteDomain(true);
  };
  const handleDeleteDomainCancel = () => {
    setSelectedDomain("");
    setShowDeleteDomain(false);
  };

  const handleDeleteDomainAccept = () => {
    dispatch(deleteEmailDomainSettingsRequest({ domain: selectedDomain }));
    setSelectedDomain("");
    setShowDeleteDomain(false);
  };

  const domainVerificationProps = {
    show: showDomainVerificationPopup,
    loading: loading,
    handleClose: () => handleDomainVerificationCancel(),
    handleSave: () => handleDomainVerificationAccept(),
    defaultName: defaultName,
    setDefaultName: setDefaultName,
    selectedDomain: selectedDomain,
  };

  const senderAddressProps = {
    show: showSenderAddressPopup,
    loading: loading,
    handleClose: () => handleSenderAddressCancel(),
    handleSave: () => handleSenderAddressAccept(),
    setDomainName: setDomainName,
    selectedDomain: selectedDomain,
    senderName: senderName,
    setSenderName: setSenderName,
  };

  const deleteDomainModalProps = {
    show: showDeleteDomain,
    title: "Delete Domain",
    handleClose: () => handleDeleteDomainCancel(),
    handleAccept: () => handleDeleteDomainAccept(),
  };

  const addDomainModalProps = {
    show: showAddDomainPopup,
    loading: loading,
    handleClose: () => handleAddDomainCancel(),
    handleSave: () => handleAddDomainAccept(),
    setDomainName: setDomainName,
    domainName: domainName,
  };

  if (domainStore.loading) {
    return <ContentWithCardLoading />;
  }
  return (
    <div className="card mb-5 border-0 mb-xl-10 h-vh-120px-mx">
      {domainStore.list.length !== 0 && (
        <div className="d-flex justify-content-end pt-9 px-9 gap-3 align-items-center">
          <BlueGradientButton size="sm" onClick={() => onAddDomainClick()}>
            + Add Domain
          </BlueGradientButton>
        </div>
      )}
      <div className="card-body">
        {domainStore.list.map((each, index) => {
          return (
            <Card className="p-5 mb-6 hide-dropdonw-icon" key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-4 align-items-center fw-bolder fs-6">
                  <i class="bi bi-hdd-rack"></i>
                  {each.domain}{" "}
                  {showAddDefultSenderEmail && each.domain === defaultSenderDomain ? (
                    <Button className="p-0 m-0" onClick={() => onSenderAddressClick(each.domain)} variant="link">
                      Add Default Sender
                    </Button>
                  ) : null}
                </div>
                <Dropdown className="dropdown-inline">
                  <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle p-5px" variant="transparent" id="dropdown-toggle-top">
                    <i class="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-sm overflow-y-auto h-50vh-mx">
                    <Dropdown.Item onSelect={() => onSenderAddressClick(each.domain)} className="dropdown-hover">
                      {"Edit Default Sender"}
                    </Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleOnDeleteClick(each.domain)} className="dropdown-hover">
                      {"Delete Domain"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <hr />
              <div className="d-flex">
                <div className="w-40px">{isValid(each) ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</div>
                <div className="w-100-40px">
                  <div className="fs-6 mb-5">Domain Verification:</div>
                  <div className="mb-4"> Add the below records to your Domain DNS setting. Once added, click the button Verify on the right.</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-6">
                      <span>SPF {each.spf ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</span>
                      <span>DKIM {each.dkim ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</span>
                      <span>TRACKING {each.trackingStatus === 0 ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</span>
                      <span>DMARC {each.dmarc ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-danger"></i>}</span>
                    </div>
                    <div>
                      <Button size={"sm"} className="border" variant="outline-primary" onClick={() => onDomainVerificationClick(each.domain)}>
                        {isValid(each) ? "Re-Verify" : "Verfiy"}
                      </Button>
                    </div>
                  </div>
                  <DomainDNSRecords domain={each.domain} index={index} />
                </div>
              </div>
            </Card>
          );
        })}
        {domainStore.list.length === 0 && <CustomEmptyPage page="email-domain" hideBorder={true} setShowAdd={onAddDomainClick} />}
      </div>
      <DefaultSenderAddressModal modalProps={senderAddressProps} />
      <DeleteModal modalProps={deleteDomainModalProps}>Are you sure want to delete domain? Once you delete you won't able to recover it.</DeleteModal>
      <AddDomainModal modalProps={addDomainModalProps} />
      {showDomainVerificationPopup && <DomainVerificationModal modalProps={domainVerificationProps} />}
    </div>
  );
}
