// Manage Users
export const postManageUser = {
  POST_MANAGE_USER_REQUEST: "POST_MANAGE_USER_REQUEST",
  POST_MANAGE_USER_SUCCESS: "POST_MANAGE_USER_SUCCESS",
  POST_MANAGE_USER_ERROR: "POST_MANAGE_USER_ERROR",
};
export const getManageUser = {
  GET_MANAGE_USER_REQUEST: "GET_MANAGE_USER_REQUEST",
  GET_MANAGE_USER_SUCCESS: "GET_MANAGE_USER_SUCCESS",
  GET_MANAGE_USER_ERROR: "GET_MANAGE_USER_ERROR",
};
export const patchManageUser = {
  PATCH_MANAGE_USER_REQUEST: "PATCH_MANAGE_USER_REQUEST",
  PATCH_MANAGE_USER_SUCCESS: "PATCH_MANAGE_USER_SUCCESS",
  PATCH_MANAGE_USER_ERROR: "PATCH_MANAGE_USER_ERROR",
};
export const deleteManageUser = {
  DELETE_MANAGE_USER_REQUEST: "DELETE_MANAGE_USER_REQUEST",
  DELETE_MANAGE_USER_SUCCESS: "DELETE_MANAGE_USER_SUCCESS",
  DELETE_MANAGE_USER_ERROR: "DELETE_MANAGE_USER_ERROR",
};
export const resendInvitation = {
  RESEND_INVITATION_REQUEST: "RESEND_INVITATION_REQUEST",
  RESEND_INVITATION_SUCCESS: "RESEND_INVITATION_SUCCESS",
  RESEND_INVITATION_ERROR: "RESEND_INVITATION_ERROR",
};
export const patchManageRole = {
  PATCH_MANAGE_ROLE_REQUEST: "PATCH_MANAGE_ROLE_REQUEST",
  PATCH_MANAGE_ROLE_SUCCESS: "PATCH_MANAGE_ROLE_SUCCESS",
  PATCH_MANAGE_ROLE_ERROR: "PATCH_MANAGE_ROLE_ERROR",
};
// Teams
export const getTeams = {
  GET_TEAMS_REQUEST: "GET_TEAMS_REQUEST",
  GET_TEAMS_SUCCESS: "GET_TEAMS_SUCCESS",
  GET_TEAMS_ERROR: "GET_TEAMS_ERROR",
};
export const postTeams = {
  POST_TEAMS_REQUEST: "POST_TEAMS_REQUEST",
  POST_TEAMS_SUCCESS: "POST_TEAMS_SUCCESS",
  POST_TEAMS_ERROR: "POST_TEAMS_ERROR",
};
export const deleteTeams = {
  DELETE_TEAMS_REQUEST: "DELETE_TEAMS_REQUEST",
  DELETE_TEAMS_SUCCESS: "DELETE_TEAMS_SUCCESS",
  DELETE_TEAMS_ERROR: "DELETE_TEAMS_ERROR",
};
export const updateTeams = {
  UPDATE_TEAMS_REQUEST: "UPDATE_TEAMS_REQUEST",
  UPDATE_TEAMS_SUCCESS: "UPDATE_TEAMS_SUCCESS",
  UPDATE_TEAMS_ERROR: "UPDATE_TEAMS_ERROR",
};
export const getListOfUsers = {
  GET_LIST_OF_USERS_REQUEST: "GET_LIST_OF_USERS_REQUEST",
  GET_LIST_OF_USERS_SUCCESS: "GET_LIST_OF_USERS_SUCCESS",
  GET_LIST_OF_USERS_ERROR: "GET_LIST_OF_USERS_ERROR",
};
export const getUserRole = {
  GET_USER_ROLE_REQUEST: "GET_USER_ROLE_REQUEST",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",
  GET_USER_ROLE_ERROR: "GET_USER_ROLE_ERROR",
};

// Company
export const getCompanySettings = {
  GET_COMPANY_SETTINGS_REQUEST: "GET_COMPANY_SETTINGS_REQUEST",
  GET_COMPANY_SETTINGS_SUCCESS: "GET_COMPANY_SETTINGS_SUCCESS",
  GET_COMPANY_SETTINGS_ERROR: "GET_COMPANY_SETTINGS_ERROR",
};
export const updateCompanySettings = {
  UPDATE_COMPANY_SETTINGS_REQUEST: "UPDATE_COMPANY_SETTINGS_REQUEST",
  UPDATE_COMPANY_SETTINGS_SUCCESS: "UPDATE_COMPANY_SETTINGS_SUCCESS",
  UPDATE_COMPANY_SETTINGS_ERROR: "UPDATE_COMPANY_SETTINGS_ERROR",
};
export const activeDeactiveCompanyStatus = {
  ACTIVE_DEACTIVE_COMPANY_STATUS_REQUEST: "ACTIVE_DEACTIVE_COMPANY_STATUS_REQUEST",
  ACTIVE_DEACTIVE_COMPANY_STATUS_SUCCESS: "ACTIVE_DEACTIVE_COMPANY_STATUS_SUCCESS",
  ACTIVE_DEACTIVE_COMPANY_STATUS_ERROR: "ACTIVE_DEACTIVE_COMPANY_STATUS_ERROR",
};
export const getAccountBilling = {
  GET_ACCOUNT_BILLING_REQUEST: "GET_ACCOUNT_BILLING_REQUEST",
  GET_ACCOUNT_BILLING_SUCCESS: "GET_ACCOUNT_BILLING_SUCCESS",
  GET_ACCOUNT_BILLING_ERROR: "GET_ACCOUNT_BILLING_ERROR",
};
export const currency = {
  CURRENCY_REQUEST: "CURRENCY_REQUEST",
  CURRENCY_SUCCESS: "CURRENCY_SUCCESS",
  CURRENCY_ERROR: " CURRENCY_ERROR",
};
export const deleteCompanyNow = {
  DELETE_COMPANY_NOW_REQUEST: "DELETE_COMPANY_NOW_REQUEST",
  DELETE_COMPANY_NOW_SUCCESS: "DELETE_COMPANY_NOW_SUCCESS",
  DELETE_COMPANY_NOW_ERROR: "DELETE_COMPANY_NOW_ERROR",
};

// Currency
export const getCurrency = {
  GET_CURRENCY_REQUEST: "GET_CURRENCY_REQUEST",
  GET_CURRENCY_SUCCESS: "GET_CURRENCY_SUCCESS",
  GET_CURRENCY_ERROR: "GET_CURRENCY_ERROR",
};
export const patchCurrency = {
  PATCH_CURRENCY_REQUEST: "PATCH_CURRENCY_REQUEST",
  PATCH_CURRENCY_SUCCESS: "PATCH_CURRENCY_SUCCESS",
  PATCH_CURRENCY_ERROR: "PATCH_CURRENCY_ERROR",
};

// Security
export const getLoginSession = {
  GET_LOGIN_SESSION_REQUEST: "GET_LOGIN_SESSION_REQUEST",
  GET_LOGIN_SESSION_SUCCESS: "GET_LOGIN_SESSION_SUCCESS",
  GET_LOGIN_SESSION_ERROR: "GET_LOGIN_SESSION_ERROR",
};

// Developers
export const addWebHook = {
  ADD_WEB_HOOK_REQUEST: "ADD_WEB_HOOK_REQUEST",
  ADD_WEB_HOOK_SUCCESS: "ADD_WEB_HOOK_SUCCESS",
  ADD_WEB_HOOK_ERROR: "ADD_WEB_HOOK_ERROR",
};
export const getWebHook = {
  GET_WEB_HOOK_REQUEST: "GET_WEB_HOOK_REQUEST",
  GET_WEB_HOOK_SUCCESS: "GET_WEB_HOOK_SUCCESS",
  GET_WEB_HOOK_ERROR: "GET_WEB_HOOK_ERROR",
};
export const deleteWebHook = {
  DELETE_WEB_HOOK_REQUEST: "DELETE_WEB_HOOK_REQUEST",
  DELETE_WEB_HOOK_SUCCESS: "DELETE_WEB_HOOK_SUCCESS",
  DELETE_WEB_HOOK_ERROR: "DELETE_WEB_HOOK_ERROR",
};
export const patchWebHook = {
  PATCH_WEB_HOOK_REQUEST: "PATCH_WEB_HOOK_REQUEST",
  PATCH_WEB_HOOK_SUCCESS: "PATCH_WEB_HOOK_SUCCESS",
  PATCH_WEB_HOOK_ERROR: "PATCH_WEB_HOOK_ERROR",
};
export const testWebHook = {
  TEST_WEB_HOOK_REQUEST: "TEST_WEB_HOOK_REQUEST",
  TEST_WEB_HOOK_SUCCESS: "TEST_WEB_HOOK_SUCCESS",
  TEST_WEB_HOOK_ERROR: "TEST_WEB_HOOK_ERROR",
};
export const disableWebHook = {
  DISABLE_WEB_HOOK_REQUEST: "DISABLE_WEB_HOOK_REQUEST",
  DISABLE_WEB_HOOK_SUCCESS: "DISABLE_WEB_HOOK_SUCCESS",
  DISABLE_WEB_HOOK_ERROR: "DISABLE_WEB_HOOK_ERROR",
};

export const postKey = {
  POST_KEY_REQUEST: "POST_KEY_REQUEST",
  POST_KEY_SUCCESS: "POST_KEY_SUCCESS",
  POST_KEY_ERROR: "POST_KEY_ERROR",
};
export const getKey = {
  GET_KEY_REQUEST: "GET_KEY_REQUEST",
  GET_KEY_SUCCESS: "GET_KEY_SUCCESS",
  GET_KEY_ERROR: "GET_KEY_ERROR",
};
export const patchKey = {
  PATCH_KEY_REQUEST: "PATCH_KEY_REQUEST",
  PATCH_KEY_SUCCESS: "PATCH_KEY_SUCCESS",
  PATCH_KEY_ERROR: "PATCH_KEY_ERROR",
};
export const deleteKey = {
  DELETE_KEY_REQUEST: "DELETE_KEY_REQUEST",
  DELETE_KEY_SUCCESS: "DELETE_KEY_SUCCESS",
  DELETE_KEY_ERROR: "DELETE_KEY_ERROR",
};

// custom fields
export const editSystemFields = {
  EDIT_SYSTEM_FIELDS_REQUEST: "EDIT_SYSTEM_FIELDS_REQUEST",
  EDIT_SYSTEM_FIELDS_SUCCESS: "EDIT_SYSTEM_FIELDS_SUCCESS",
  EDIT_SYSTEM_FIELDS_ERROR: "EDIT_SYSTEM_FIELDS_ERROR",
};
export const getAddFormFields = {
  GET_ADD_FORM_FIELDS_REQUEST: "GET_ADD_FORM_FIELDS_REQUEST",
  GET_ADD_FORM_FIELDS_SUCCESS: "GET_ADD_FORM_FIELDS_SUCCESS",
  GET_ADD_FORM_FIELDS_ERROR: "GET_ADD_FORM_FIELDS_ERROR",
};
export const reorderAddFormFields = {
  REORDER_ADD_FORM_FIELDS_REQUEST: "REORDER_ADD_FORM_FIELDS_REQUEST",
  REORDER_ADD_FORM_FIELDS_SUCCESS: "REORDER_ADD_FORM_FIELDS_SUCCESS",
  REORDER_ADD_FORM_FIELDS_ERROR: "REORDER_ADD_FORM_FIELDS_ERROR",
};
export const getUsageRecordReports = {
  GET_USAGE_RECORD_REPORTS_REQUEST: "GET_USAGE_RECORD_REPORTS_REQUEST",
  GET_USAGE_RECORD_REPORTS_SUCCESS: "GET_USAGE_RECORD_REPORTS_SUCCESS",
  GET_USAGE_RECORD_REPORTS_ERROR: "GET_USAGE_RECORD_REPORTS_ERROR",
};
export const patchCustomField = {
  PATCH_CUSTOM_FIELD_REQUEST: "PATCH_CUSTOM_FIELD_REQUEST",
  PATCH_CUSTOM_FIELD_SUCCESS: "PATCH_CUSTOM_FIELD_SUCCESS",
  PATCH_CUSTOM_FIELD_ERROR: "PATCH_CUSTOM_FIELD_ERROR",
};
export const getCustomField = {
  GET_CUSTOM_FIELD_REQUEST: "GET_CUSTOM_FIELD_REQUEST",
  GET_CUSTOM_FIELD_SUCCESS: "GET_CUSTOM_FIELD_SUCCESS",
  GET_CUSTOM_FIELD_ERROR: "GET_CUSTOM_FIELD_ERROR",
};
export const deleteCustomField = {
  DELETE_CUSTOM_FIELD_REQUEST: "DELETE_CUSTOM_FIELD_REQUEST",
  DELETE_CUSTOM_FIELD_SUCCESS: "DELETE_CUSTOM_FIELD_SUCCESS",
  DELETE_CUSTOM_FIELD_ERROR: "DELETE_CUSTOM_FIELD_ERROR",
};
export const postCustomField = {
  POST_CUSTOM_FIELD_REQUEST: "POST_CUSTOM_FIELD_REQUEST",
  POST_CUSTOM_FIELD_SUCCESS: "POST_CUSTOM_FIELD_SUCCESS",
  POST_CUSTOM_FIELD_ERROR: "POST_CUSTOM_FIELD_ERROR",
};
export const leadFields = {
  LEAD_FIELDS_REQUEST: "LEAD_FIELDS_REQUEST",
  LEAD_FIELDS_SUCCESS: "LEAD_FIELDS_SUCCESS",
  LEAD_FIELDS_ERROR: "LEAD_FIELDS_ERROR",
};
export const peopleFields = {
  PEOPLE_FIELDS_REQUEST: "PEOPLE_FIELDS_REQUEST",
  PEOPLE_FIELDS_SUCCESS: "PEOPLE_FIELDS_SUCCESS",
  PEOPLE_FIELDS_ERROR: "PEOPLE_FIELDS_ERROR",
};
export const getDealListFields = {
  GET_DEAL_LIST_FIELDS_REQUEST: "GET_DEAL_LIST_FIELDS_REQUEST",
  GET_DEAL_LIST_FIELDS_SUCCESS: "GET_DEAL_LIST_FIELDS_SUCCESS",
  GET_DEAL_LIST_FIELDS_ERROR: "GET_DEAL_LIST_FIELDS_ERROR",
};
export const companyFields = {
  COMPANY_FIELDS_REQUEST: "COMPANY_FIELDS_REQUEST",
  COMPANY_FIELDS_SUCCESS: "COMPANY_FIELDS_SUCCESS",
  COMPANY_FIELDS_ERROR: "COMPANY_FIELDS_ERROR",
};
export const getMarketingDataFields = {
  GET_MARKETING_DATA_FIELDS_REQUEST: "GET_MARKETING_DATA_FIELDS_REQUEST",
  GET_MARKETING_DATA_FIELDS_SUCCESS: "GET_MARKETING_DATA_FIELDS_SUCCESS",
  GET_MARKETING_DATA_FIELDS_ERROR: "GET_MARKETING_DATA_FIELDS_ERROR",
};

// outcome and lost reasons
export const postLostReason = {
  POST_LOST_REASON_REQUEST: "POST_LOST_REASON_REQUEST",
  POST_LOST_REASON_SUCCESS: "POST_LOST_REASON_SUCCESS",
  POST_LOST_REASON_ERROR: "POST_LOST_REASON_ERROR",
};
export const getLostReason = {
  GET_LOST_REASON_REQUEST: "GET_LOST_REASON_REQUEST",
  GET_LOST_REASON_SUCCESS: "GET_LOST_REASON_SUCCESS",
  GET_LOST_REASON_ERROR: "GET_LOST_REASON_ERROR",
};
export const patchLostReason = {
  PATCH_LOST_REASON_REQUEST: "PATCH_LOST_REASON_REQUEST",
  PATCH_LOST_REASON_SUCCESS: "PATCH_LOST_REASON_SUCCESS",
  PATCH_LOST_REASON_ERROR: "PATCH_LOST_REASON_ERROR",
};
export const deleteLostReason = {
  DELETE_LOST_REASON_REQUEST: "DELETE_LOST_REASON_REQUEST",
  DELETE_LOST_REASON_SUCCESS: "DELETE_LOST_REASON_SUCCESS",
  DELETE_LOST_REASON_ERROR: "DELETE_LOST_REASON_ERROR",
};
export const updateActivityLossReasonsSettings = {
  UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST: "UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST",
  UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS: "UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS",
  UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR: "UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR",
};
export const getActivityLossReasonsSettings = {
  GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST: "GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST",
  GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS: "GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS",
  GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR: "GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR",
};

// Activity
export const getSettingActivity = {
  GET_SETTING_ACTIVITY_REQUEST: "GET_SETTING_ACTIVITY_REQUEST",
  GET_SETTING_ACTIVITY_SUCCESS: "GET_SETTING_ACTIVITY_SUCCESS",
  GET_SETTING_ACTIVITY_ERROR: "GET_SETTING_ACTIVITY_ERROR",
};
export const postActivity = {
  POST_ACTIVITY_REQUEST: "POST_ACTIVITY_REQUEST",
  POST_ACTIVITY_SUCCESS: "POST_ACTIVITY_SUCCESS",
  POST_ACTIVITY_ERROR: "POST_ACTIVITY_ERROR",
};
export const getActivity = {
  GET_ACTIVITY_REQUEST: "GET_ACTIVITY_REQUEST",
  GET_ACTIVITY_SUCCESS: "GET_ACTIVITY_SUCCESS",
  GET_ACTIVITY_ERROR: "GET_ACTIVITY_ERROR",
};
export const patchActivity = {
  PATCH_ACTIVITY_REQUEST: "PATCH_ACTIVITY_REQUEST",
  PATCH_ACTIVITY_SUCCESS: "PATCH_ACTIVITY_SUCCESS",
  PATCH_ACTIVITY_ERROR: "PATCH_ACTIVITY_ERROR",
};
export const deleteActivity = {
  DELETE_ACTIVITY_REQUEST: "DELETE_ACTIVITY_REQUEST",
  DELETE_ACTIVITY_SUCCESS: "DELETE_ACTIVITY_SUCCESS",
  DELETE_ACTIVITY_ERROR: "DELETE_ACTIVITY_ERROR",
};

// Tag settings
export const getTagsInSetting = {
  GET_TAGS_IN_SETTINGS_REQUEST: "GET_TAGS_IN_SETTINGS_REQUEST",
  GET_TAGS_IN_SETTINGS_SUCCESS: "GET_TAGS_IN_SETTINGS_SUCCESS",
  GET_TAGS_IN_SETTINGS_ERROR: "GET_TAGS_IN_SETTINGS_ERROR",
};
export const deleteTagsInSetting = {
  DELETE_TAGS_IN_SETTINGS_REQUEST: "DELETE_TAGS_IN_SETTINGS_REQUEST",
  DELETE_TAGS_IN_SETTINGS_SUCCESS: "DELETE_TAGS_IN_SETTINGS_SUCCESS",
  DELETE_TAGS_IN_SETTINGS_ERROR: "DELETE_TAGS_IN_SETTINGS_ERROR",
};
export const bulkDeleteTagsInSetting = {
  BULK_DELETE_TAGS_IN_SETTINGS_REQUEST: "BULK_DELETE_TAGS_IN_SETTINGS_REQUEST",
  BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS: "BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS",
  BULK_DELETE_TAGS_IN_SETTINGS_ERROR: "BULK_DELETE_TAGS_IN_SETTINGS_ERROR",
};
export const addTagsInSetting = {
  ADD_TAGS_IN_SETTINGS_REQUEST: "ADD_TAGS_IN_SETTINGS_REQUEST",
  ADD_TAGS_IN_SETTINGS_SUCCESS: "ADD_TAGS_IN_SETTINGS_SUCCESS",
  ADD_TAGS_IN_SETTINGS_ERROR: "ADD_TAGS_IN_SETTINGS_ERROR",
};
export const editTagsInSetting = {
  EDIT_TAGS_IN_SETTINGS_REQUEST: "EDIT_TAGS_IN_SETTINGS_REQUEST",
  EDIT_TAGS_IN_SETTINGS_SUCCESS: "EDIT_TAGS_IN_SETTINGS_SUCCESS",
  EDIT_TAGS_IN_SETTINGS_ERROR: "EDIT_TAGS_IN_SETTINGS_ERROR",
};
