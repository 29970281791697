import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import * as campaignBlockTypes from "../types/campaign_block_contact_type";
import api from "../../../../network/apis/api";
import { endpoints } from "../endpoints";
import * as campaignBlockContactsActions from "../actions/campaign_block_contact_actions";

function* getListOfBlockContacts(action) {
  try {
    const res = yield call(api.get, endpoints.BLOCK_CONTACTS, action.payload);
    if (res.status === 200) {
      yield put(campaignBlockContactsActions.getListOfBlockContactsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignBlockContactsActions.getListOfBlockContactsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignBlockContactsActions.getListOfBlockContactsError(e));
  }
}

function* createBlockContact(action) {
  try {
    const res = yield call(api.post, endpoints.BLOCK_CONTACTS, action.payload.data);
    if (res.status === 200) {
      yield put(campaignBlockContactsActions.createBlockContactSuccess(res.data));
      yield put(campaignBlockContactsActions.getListOfBlockContactsRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignBlockContactsActions.createBlockContactError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignBlockContactsActions.createBlockContactError(e));
  }
}
function* editBlockContact(action) {
  try {
    const res = yield call(api.patch, `${endpoints.BLOCK_CONTACTS}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(campaignBlockContactsActions.editBlockContactSuccess(res.data));
      yield put(campaignBlockContactsActions.getListOfBlockContactsRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignBlockContactsActions.editBlockContactError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignBlockContactsActions.editBlockContactError(e));
  }
}
function* deleteBlockContact(action) {
  try {
    const res = yield call(api.delete, `${endpoints.BLOCK_CONTACTS}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(campaignBlockContactsActions.deleteBlockContactSuccess(res.data));
      yield put(campaignBlockContactsActions.getListOfBlockContactsRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignBlockContactsActions.deleteBlockContactError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignBlockContactsActions.deleteBlockContactError(e));
  }
}
export default function* campaignBlockNumberSaga() {
  yield takeLatest(campaignBlockTypes.getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_REQUEST, getListOfBlockContacts);
  yield takeLatest(campaignBlockTypes.createBlockContact.CREATE_BLOCK_CONTACT_REQUEST, createBlockContact);
  yield takeLatest(campaignBlockTypes.editBlockContact.EDIT_BLOCK_CONTACT_REQUEST, editBlockContact);
  yield takeLatest(campaignBlockTypes.deleteBlockContact.DELETE_BLOCK_CONTACT_REQUEST, deleteBlockContact);
}
