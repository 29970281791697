import React, { useEffect, useState } from "react";
import Gleap from "gleap";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

export function BugReport() {
  const [initialize, setInitialize] = useState(false);
  const profileState = useSelector((state) => state.ProfileSettings.getProfileDetails.data);

  Gleap.initialize("2W7chPAAwewGf4IEvrUuSKfFYdbzhWyi");
  mixpanel.init("e3f86a8f6253ce1379737486b3f5933c", { debug: true });

  useEffect(() => {
    if (profileState.userId && !initialize) {
      Gleap.identify(profileState.userId, {
        name: profileState.fullName,
        email: profileState.email,
        role: profileState.userType,
      });
      setInitialize(true);
      // mixpanel.track('Dashboard Loaded', {
      //   userId: profileState.userId,
      //   name: profileState.fullName,
      //   email: profileState.email,
      //   role: profileState.userType,
      // });
    }
  }, [profileState, initialize]);

  const handleClick = () => {
    Gleap.open();
  };

  return (
    <>
      <div>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-actions-tooltip">Help</Tooltip>}>
          <div className="topbar-item">
            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 svg-icon-div" id="kt_quick_actions_toggle" onClick={handleClick}>
              <span className="header-svg">
                {/* <i className="bi bi-bug"></i> */}
                <i className="bi bi-question-circle"></i>
              </span>
            </div>
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
}
