import {
  getEmailProfileDetails,
  updateEmailProfileSettings,
  getEmailAccountReputations,
  getEmailAccountStatus,
  getListOfEmailDomainSettings,
  addDomainSettings,
  addEmailToDomainSettings,
  deleteEmailDomainSettings,
  verifyDKIM,
  verifySPF,
  verifyDMARC,
  verifyTracking,
} from "../types/campaign_email_settings_types";

export function getEmailProfileDetailsRequest(req) {
  return {
    type: getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_REQUEST,
    payload: req,
  };
}
export function getEmailProfileDetailsSuccess(res) {
  return {
    type: getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_SUCCESS,
    payload: res,
  };
}
export function getEmailProfileDetailsError(err) {
  return {
    type: getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_ERROR,
    payload: err,
  };
}

export function updateEmailProfileSettingsRequest(req) {
  return {
    type: updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_REQUEST,
    payload: req,
  };
}
export function updateEmailProfileSettingsSuccess(res) {
  return {
    type: updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_SUCCESS,
    payload: res,
  };
}
export function updateEmailProfileSettingsError(err) {
  return {
    type: updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_ERROR,
    payload: err,
  };
}

export function getEmailAccountReputationsRequest(req) {
  return {
    type: getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_REQUEST,
    payload: req,
  };
}
export function getEmailAccountReputationsSuccess(res) {
  return {
    type: getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_SUCCESS,
    payload: res,
  };
}
export function getEmailAccountReputationsError(err) {
  return {
    type: getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_ERROR,
    payload: err,
  };
}

export function getEmailAccountStatusRequest(req) {
  return {
    type: getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_REQUEST,
    payload: req,
  };
}
export function getEmailAccountStatusSuccess(res) {
  return {
    type: getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_SUCCESS,
    payload: res,
  };
}
export function getEmailAccountStatusError(err) {
  return {
    type: getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_ERROR,
    payload: err,
  };
}

export function getListOfEmailDomainSettingsRequest(req) {
  return {
    type: getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function getListOfEmailDomainSettingsSuccess(res) {
  return {
    type: getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_SUCCESS,
    payload: res,
  };
}
export function getListOfEmailDomainSettingsError(err) {
  return {
    type: getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_ERROR,
    payload: err,
  };
}

export function addDomainSettingsRequest(req, cb) {
  return {
    type: addDomainSettings.ADD_DOMAIN_SETTINGS_REQUEST,
    payload: req,
    cb,
  };
}
export function addDomainSettingsSuccess(res) {
  return {
    type: addDomainSettings.ADD_DOMAIN_SETTINGS_SUCCESS,
    payload: res,
  };
}
export function addDomainSettingsError(err) {
  return {
    type: addDomainSettings.ADD_DOMAIN_SETTINGS_ERROR,
    payload: err,
  };
}

export function addEmailToDomainSettingsRequest(req) {
  return {
    type: addEmailToDomainSettings.ADD_EMAIL_TO_DOMAIN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function addEmailToDomainSettingsSuccess(res) {
  return {
    type: addEmailToDomainSettings.ADD_EMAIL_TO_DOMAIN_SETTINGS_SUCCESS,
    payload: res,
  };
}
export function addEmailToDomainSettingsError(err) {
  return {
    type: addEmailToDomainSettings.ADD_EMAIL_TO_DOMAIN_SETTINGS_ERROR,
    payload: err,
  };
}

export function deleteEmailDomainSettingsRequest(req) {
  return {
    type: deleteEmailDomainSettings.DELETE_EMAIL_DOMAIN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function deleteEmailDomainSettingsSuccess(res) {
  return {
    type: deleteEmailDomainSettings.DELETE_EMAIL_DOMAIN_SETTINGS_SUCCESS,
    payload: res,
  };
}
export function deleteEmailDomainSettingsError(err) {
  return {
    type: deleteEmailDomainSettings.DELETE_EMAIL_DOMAIN_SETTINGS_ERROR,
    payload: err,
  };
}

export function verifyDKIMRequest(req, cb) {
  return {
    type: verifyDKIM.VERIFY_DKIM_REQUEST,
    payload: req,
    cb,
  };
}
export function verifyDKIMSuccess(res) {
  return {
    type: verifyDKIM.VERIFY_DKIM_SUCCESS,
    payload: res,
  };
}
export function verifyDKIMError(err) {
  return {
    type: verifyDKIM.VERIFY_DKIM_ERROR,
    payload: err,
  };
}

export function verifySPFRequest(req, cb) {
  return {
    type: verifySPF.VERIFY_SPF_REQUEST,
    payload: req,
    cb,
  };
}
export function verifySPFSuccess(res) {
  return {
    type: verifySPF.VERIFY_SPF_SUCCESS,
    payload: res,
  };
}
export function verifySPFError(err) {
  return {
    type: verifySPF.VERIFY_SPF_ERROR,
    payload: err,
  };
}

export function verifyDMARCRequest(req, cb) {
  return {
    type: verifyDMARC.VERIFY_DMARC_REQUEST,
    payload: req,
    cb,
  };
}
export function verifyDMARCSuccess(res) {
  return {
    type: verifyDMARC.VERIFY_DMARC_SUCCESS,
    payload: res,
  };
}
export function verifyDMARCError(err) {
  return {
    type: verifyDMARC.VERIFY_DMARC_ERROR,
    payload: err,
  };
}

export function verifyTrackingRequest(req, cb) {
  return {
    type: verifyTracking.VERIFY_TRACKING_REQUEST,
    payload: req,
    cb,
  };
}
export function verifyTrackingSuccess(res) {
  return {
    type: verifyTracking.VERIFY_TRACKING_SUCCESS,
    payload: res,
  };
}
export function verifyTrackingError(err) {
  return {
    type: verifyTracking.VERIFY_TRACKING_ERROR,
    payload: err,
  };
}
