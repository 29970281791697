import React from "react";
// import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";

const ReactGridLayout = WidthProvider(RGL);

export default class GridInsightLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    items: 20,
    rowHeight: 30,
    // onLayoutChange: function () { },
    cols: 12,
  };

  onLayoutChange(layout) {
    // this.props.onLayoutChange(layout);
  }
  onDrop(layout, item, e) {}

  render() {
    return (
      <ReactGridLayout
        // layout={this.state.layout}
        // onLayoutChange={this.onLayoutChange}
        // onDrop={this.onDrop}
        {...this.props}
      >
        {this.props.children}
      </ReactGridLayout>
    );
  }
}
