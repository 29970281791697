import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as lostReasonsActions from "../../actions/admin-actions/outcomes-settings.actions";
// import * as ActivityLossReasonActions from "../../../../Activities/store/actions/activity_loss_reasons_actions"
import * as lostReasonsTypes from "../../types/admin-settings.types";
// import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { endpoints } from "../../endpoint";

function* postLostReasonFunction(action) {
  try {
    const res = yield call(api.post, endpoints.LOST_REASONS, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(lostReasonsActions.postLostReasonSuccess(res.data));
    } else if (res.status === 201) {
      yield action.cb(res.data);
      yield put(lostReasonsActions.postLostReasonSuccess(res.data));
    } else {
      yield put(lostReasonsActions.postLostReasonError("error"));
    }
  } catch (e) {
    yield put(lostReasonsActions.postLostReasonError(e));
  }
}

function* getLostReasonFunction(action) {
  try {
    const res = yield call(api.get, endpoints.LOST_REASONS);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(lostReasonsActions.getLostReasonSuccess(res.data));
    } else {
      yield put(lostReasonsActions.getLostReasonError("error"));
    }
  } catch (e) {
    yield put(lostReasonsActions.getLostReasonError(e));
  }
}

function* patchLostReasonFunction(action) {
  try {
    const res = yield call(api.patch, `/api/crm/lost-reason/${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(lostReasonsActions.patchLostReasonSuccess(res.data));
    } else {
      yield put(lostReasonsActions.patchLostReasonError("error"));
    }
  } catch (e) {
    yield put(lostReasonsActions.patchLostReasonError(e));
  }
}

function* deleteLostReasonFunction(action) {
  try {
    const res = yield call(api.delete, `/api/crm/lost-reason/${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(lostReasonsActions.deleteLostReasonSuccess(res.data));
    } else {
      yield put(lostReasonsActions.deleteLostReasonError("error"));
    }
  } catch (e) {
    yield put(lostReasonsActions.deleteLostReasonError(e));
  }
}

// function* getActivityLossReasonsSettingsFunction(action) {
//   try {
//     const res = yield call(api.get, endpoints.ACTIVITY_LOSS_REASONS, action.payload);
//     debugger
//     if (res.status === 200) {
//       yield put(lostReasonsActions.getActivityLossReasonsSettingsSuccess(res.data));
//     } else if (res.status === 400) {
//       dispatchSnackbarError(res.data["message"], "error");
//       yield put(lostReasonsActions.getActivityLossReasonsSettingsError(res));
//     } else {
//       yield put(lostReasonsActions.getActivityLossReasonsSettingsError("error"));
//     }
//     if (action.cb) yield action.cb(res.data);
//   } catch (e) {
//     yield put(lostReasonsActions.getActivityLossReasonsSettingsError(e));
//   }
// }

// function* updateActivityLossReasonsSettingsFunction(action) {
//   try {
//     const res = yield call(api.patch, endpoints.ACTIVITY_LOSS_REASONS, action.payload);
//     if (res.status === 200) {
//       yield put(lostReasonsActions.updateActivityLossReasonsSettingsSuccess(res.data));
//       yield put(lostReasonsActions.getActivityLossReasonsSettingsRequest());
//       yield put(ActivityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: false }));

//     } else if (res.status === 400) {
//       dispatchSnackbarError(res.data["message"], "error");
//       yield put(lostReasonsActions.updateActivityLossReasonsSettingsError(res));
//     } else {
//       yield put(lostReasonsActions.updateActivityLossReasonsSettingsError("error"));
//     }
//     if (action.cb) yield action.cb(res.data);
//   } catch (e) {
//     yield put(lostReasonsActions.updateActivityLossReasonsSettingsError(e));
//   }
// }

export default function* lostReasonsSaga() {
  yield takeLatest(lostReasonsTypes.postLostReason.POST_LOST_REASON_REQUEST, postLostReasonFunction);
  yield takeLatest(lostReasonsTypes.getLostReason.GET_LOST_REASON_REQUEST, getLostReasonFunction);
  yield takeLatest(lostReasonsTypes.patchLostReason.PATCH_LOST_REASON_REQUEST, patchLostReasonFunction);
  yield takeLatest(lostReasonsTypes.deleteLostReason.DELETE_LOST_REASON_REQUEST, deleteLostReasonFunction);
  // yield takeLatest(lostReasonsTypes.getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST, getActivityLossReasonsSettingsFunction);
  // yield takeLatest(lostReasonsTypes.updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST, updateActivityLossReasonsSettingsFunction);
}
