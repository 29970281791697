import React from "react";
import { IconButton2 } from "../../button/icon-action-buttons";

const tooltipLabelMapping = {
  SUBSCRIBER_ANALYTICS: "Subscriber Analytics",
};
export default function TableActions(props) {
  if (props.actionName === "SUBSCRIBER_ANALYTICS") {
    return (
      <IconButton2
        className="import-icon-container text-hover-primary text-primary bg-hover-light-primary"
        tooltip={tooltipLabelMapping[props.actionName]}
        onClick={() => props.handleTableActions(props.actionName, props.data)}
      >
        <i class="bi bi-clipboard-data text-primary"></i>
      </IconButton2>
    );
  } else {
    return <div onClick={() => props.handleTableActions(props.actionName, props.data)}>{props.actionName}</div>;
  }
}
