import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { BootstrapDropdown } from "../../../components/dropdowns/bootstrap-dropdown";
import { dispatchSnackbarError } from "../../../utils/toaster";

export default function SalesForecastFilter(props) {
  const [type, setType] = useState({});
  const [assignee, setAssignee] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [isOwner, setIsOwner] = useState(true);

  const ownerOptions = [
    { label: "All", value: "all" },
    { label: "Company", value: "company" },
    { label: "Team", value: "team" },
    { label: "User", value: "user" },
  ];
  const managerOptions = [
    { label: "All", value: "all" },
    { label: "Team", value: "team" },
    { label: "User", value: "user" },
  ];

  useEffect(() => {
    if (!_.isEmpty(props.filterData)) {
      setType({ label: _.capitalize(props.filterData.type), value: props.filterData.type });
      if (props.filterData.type === "team") {
        const _assignee = props.teamOptions.find((each) => each.value === props.filterData.id);
        if (_assignee) setAssignee(_assignee);
      } else if (props.filterData.type === "user") {
        const _assignee = props.userOptions.find((each) => each.value === props.filterData.id);
        if (_assignee) setAssignee(_assignee);
      }
    }
  }, [props.filterData, props.teamOptions, props.userOptions]);

  useEffect(() => {
    if (props.userType === "OWNER" || props.userType === "ADMIN") {
      setShowFilter(true);
      setIsOwner(true);
    } else if (props.userType === "AGENT" && props.teamOptions.length > 0) {
      setShowFilter(true);
      setIsOwner(false);
    } else {
      setShowFilter(false);
      setIsOwner(true);
    }
  }, [props.userType, props.teamOptions, props.userOptions]);

  const onApplyClick = () => {
    if (_.isEmpty(type)) {
      return dispatchSnackbarError("Please all Details");
    } else if (["team", "user"].includes(type.value) && _.isEmpty(assignee)) {
      return dispatchSnackbarError("Please all Details");
    }
    props.setFilterData({ type: type.value, id: assignee.value });
    document.body.click();
  };
  const onCancelClick = () => {
    props.setFilterData({ type: "all", id: "" });
    document.body.click();
  };

  if (!showFilter) return <></>;
  return (
    <Dropdown className="dropdown-inline custom-dropdown-toggle">
      <Dropdown.Toggle className={`btn btn-sm dropdown-toggle btn-secondary d-flex flex-stack `}>Filter</Dropdown.Toggle>
      <Dropdown.Menu className="w-300px">
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5">
          <div className="mb-7">
            <label className="form-label fw-bold">Type</label>
            <BootstrapDropdown
              label={"Select"}
              value={type}
              options={isOwner ? ownerOptions : managerOptions}
              onSelect={(option) => {
                setType(option);
                setAssignee({});
              }}
            />
          </div>
          {["team", "user"].includes(type.value) && (
            <div className="mb-7">
              <label className="form-label fw-bold">{type.value === "team" ? "Team" : "User"}</label>
              <BootstrapDropdown label={"Select"} value={assignee} options={type.value === "team" ? props.teamOptions : props.userOptions} onSelect={setAssignee} />
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button className="btn btn-sm btn-light btn-active-light-primary me-2" onClick={onCancelClick}>
              Cancel
            </Button>
            <Button className="btn btn-sm btn-primary" onClick={onApplyClick}>
              Apply
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
