import { getCalendarEvent } from "../types/calendar-view.types";

const initState = {
  data: [],
  loading: false,
  error: "",
};

export default function activityCalendarViewReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCalendarEvent.GET_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getCalendarEvent.GET_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case getCalendarEvent.GET_CALENDAR_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
