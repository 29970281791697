export const AssigneeOptions = [
  { value: "company", label: "Company" },
  { value: "team", label: "Team" },
  { value: "user", label: "User" },
];

export const goalTypeOptions = [
  { value: "activityCompleted", label: "Activities Completed" },
  { value: "activityAdded", label: "Activities Added" },
  { value: "dealAdded", label: "Deals added" },
  { value: "dealProgressed", label: "Deals progressed" },
  { value: "dealWon", label: "Deals won" },
];

export const forecastTypeOptions = [
  { value: "openDeals", label: "Open Deals" },
  { value: "openAndWonDeals", label: "Open Deals and Won Deals" },
  { value: "openAndWonDealsWithSalesTarget", label: "Open and Won Deals With Sales Target" },
];

export const moduleOptions = [
  { value: "all", label: "All" },
  { value: "lead", label: "Lead" },
  { value: "deal", label: "Deal" },
];
export const moduleOptions1 = [{ value: "deal", label: "Deal" }];

export const frequencyIntervalOptions = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "yearly", label: "Yearly" },
];

export const durationOptions = [
  { value: "automatic", label: "Automatically" },
  // { value: "thisMonth", label: "This Month" },
  // { value: "nextMonth", label: "Next Month" },
  // { value: "thisQuarter", label: "This Quarter" },
  // { value: "nextQuarter", label: "Next Quarter" },
  // { value: "thisYear", label: "This Year" },
  // { value: "nextYear", label: "Next Year" },
  { value: "customPeriod", label: "Custom Period" },
];

export const salesTargetAndForecastMapping = {
  company: "Company",
  team: "Team",
  user: "User",

  activityCompleted: "Activities Completed",
  activityAdded: "Activities Added",
  dealAdded: "Deals added",
  dealProgressed: "Deals progressed",
  dealWon: "Deals won",

  all: "All",
  lead: "Lead",
  deal: "Deal",

  weekly: "Weekly",
  monthly: "Monthly",
  quarterly: "Quarterly",
  yearly: "Yearly",

  automatic: "Automatically",
  thisMonth: "This Month",
  nextMonth: "Next Month",
  thisQuarter: "This Quarter",
  nextQuarter: "Next Qu,arter",
  thisYear: "This Year",
  nextYear: "Next Year",
  customPeriod: "Custom Period",

  openDeals: "Open Deals",
  openAndWonDeals: "Open Deals and Won Deals",
  openAndWonDealsWithSalesTarget: "Open and Won Deals With Sales Target",
};
