import React from "react";
import { Card } from "react-bootstrap";
import { BlueGradientButton } from "../button";

export function UpgradePlanPage(props) {
  return (
    <Card className="h-vh-180px bg-white">
      <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
        <div className="card-px text-center py-5 my-5">
          <h2 className="fs-2x fw-bolder mb-10 mt-5">{props.type}</h2>
          <p className="text-gray-700 fs-5 mb-8 w-50vw-mx">
            <p>To access {props.type.toLowerCase()} upgrade your plan</p>
          </p>
          <div className="d-flex gap-4 flex-center">
            {props.userType === "AGENT" || props.isEnterprise ? (
              <span className="btn btn-light btn-hover-secondary text-dark text-center disabled">To upgrade your plan, contact account manager or support team.</span>
            ) : (
              <BlueGradientButton size="sm" onClick={props.onUpgradeClick}>
                Upgrade Plan
              </BlueGradientButton>
            )}
          </div>
        </div>
        <img src={require("../../images/illustrations/cart.svg")} className="mx-auto h-vh-450px" alt="add" />
      </Card.Body>
    </Card>
  );
}
