import { getMarketingAddForm, getMarketingDataFields } from "../types/marketing_data_field_types";

const initState = {
  fields: {
    list: [],
    loading: false,
    idToIndex: {},
  },
  addForm: {
    loading: false,
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
};

export default function marketingDataFieldReducer(state = initState, action = {}) {
  switch (action.type) {
    // get data field
    case getMarketingDataFields.GET_MARKETING_DATA_FIELDS_REQUEST:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true,
        },
      };
    case getMarketingDataFields.GET_MARKETING_DATA_FIELDS_SUCCESS:
      const tempObj = {};
      for (let i = 0; i < action.payload.data.length; i++) {
        tempObj[action.payload.data[i]["_id"]] = i;
      }
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          list: action.payload.data,
          idToIndex: tempObj,
        },
      };
    case getMarketingDataFields.GET_MARKETING_DATA_FIELDS_ERROR:
      return {
        ...state,
        fields: { ...state.fields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //get add form
    case getMarketingAddForm.GET_MARKETING_ADD_FORM_REQUEST:
      return {
        ...state,
        addForm: { ...state.addForm, loading: true },
      };
    case getMarketingAddForm.GET_MARKETING_ADD_FORM_SUCCESS:
      return {
        ...state,
        addForm: { loading: false, list: action.payload.data },
      };
    case getMarketingAddForm.GET_MARKETING_ADD_FORM_ERROR:
      return {
        ...state,
        addForm: { ...state.addForm, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // table priority
    // case setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_REQUEST:
    //   return {
    //     ...state,
    //   };
    // case setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_SUCCESS:
    //   const tempUpdate = {};
    //   for (let j = 0; j < action.payload.length; j++) {
    //     tempUpdate[action.payload[j]["_id"]] = j;
    //   }
    //   return {
    //     ...state,
    //     fields: { ...state.fields, list: action.payload, idToIndex: tempUpdate },
    //   };
    // case setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_ERROR:
    //   return {
    //     ...state,
    //     error: { err: true, errMsg: action.payload.message },
    //   };

    // // show and hide field
    // case setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_REQUEST:
    //   return {
    //     ...state,
    //   };
    // case setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_SUCCESS:
    //   for (let j = 0; j < action.payload.length; j++) {
    //     tempUpdate[action.payload[j]["_id"]] = j;
    //   }
    //   return {
    //     ...state,
    //     fields: { ...state.fields, list: action.payload, idToIndex: tempUpdate },
    //   };
    // case setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_ERROR:
    //   return {
    //     ...state,
    //     error: { err: true, errMsg: action.payload.message },
    //   };

    default:
      return state;
  }
}
