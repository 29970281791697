import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlankSidePanel from "../../../components/blank-side-pannel";
import { GreenGradientButton } from "../../../components/button";
import ShareUserInput from "../../../components/custom-inputs/share-user-input";
import AdvancedDropdown from "../../../components/dropdowns/advanced-dropdown";
import RichTextEditor from "../../../components/RichTextEditor/tinymce-editor";
import { InputLimit } from "../../../constants";
import { dispatchSnackbarError } from "../../../utils/toaster";
import "./styles.scss";

export default function AddVideo(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [defaultDescription, setDefaultDescription] = useState("");
  const [author, setAuthor] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [category, setCategory] = useState("");
  const [sharing, setSharing] = useState([]);

  const userRole = useSelector((store) => store.insight.userRole);

  const getSharingInvertInfo = useCallback(
    (_sharedAll, _sharedTeams, _sharedUsers) => {
      if (_sharedAll) {
        return [{ id: "all", label: "All" }];
      } else {
        const a = [],
          _obj = {};
        if (Array.isArray(userRole.list.teams)) {
          userRole.list.teams.forEach((each) => {
            _obj[each.id] = { id: each.id, label: each.name };
          });
        }
        if (Array.isArray(userRole.list.allUsers)) {
          userRole.list.allUsers.forEach((each) => {
            _obj[each.id] = { id: each.id, label: each.name };
          });
        }
        _sharedUsers.forEach((each) => {
          if (_obj[each]) {
            a.push(_obj[each]);
          }
        });
        _sharedTeams.forEach((each) => {
          if (_obj[each]) {
            a.push(_obj[each]);
          }
        });

        return a;
      }
    },
    [userRole.list.allUsers, userRole.list.teams],
  );

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.ediableData)) {
      setTitle(props.ediableData.title);
      setDescription(props.ediableData.description || "");
      setDefaultDescription(props.ediableData.description || "");
      setAuthor(props.ediableData.author);
      setVideoUrl(getVideoId(props.ediableData.url));
      setCategory(props.ediableData.category);
      setSharing(getSharingInvertInfo(props.ediableData.sharedAll, props.ediableData.sharedTeams, props.ediableData.sharedUsers));
    }
  }, [props.isEdit, props.ediableData, getSharingInvertInfo]);

  const getVideoId = (url) => {
    const _array = url.split("/");
    return _array[_array.length - 1];
  };

  const getSharingInfo = () => {
    const sharedUsers = [],
      sharedTeams = [];
    let sharedAll = false;

    const userIds = [],
      teamIds = [];

    if (Array.isArray(userRole.list.teams)) {
      userRole.list.teams.forEach((each) => {
        teamIds.push(each.id);
      });
    }
    if (Array.isArray(userRole.list.allUsers)) {
      userRole.list.allUsers.forEach((each) => {
        userIds.push(each.id);
      });
    }

    sharing.forEach((each) => {
      if (each.id === "all") {
        sharedAll = true;
      } else if (userIds.includes(each.id)) {
        sharedUsers.push(each.id);
      } else if (teamIds.includes(each.id)) {
        sharedTeams.push(each.id);
      }
    });

    return { sharedAll, sharedUsers, sharedTeams };
  };

  const handleSave = () => {
    if (_.isEmpty(title)) {
      return dispatchSnackbarError("Video title cannot be empty");
    } else if (_.isEmpty(author)) {
      return dispatchSnackbarError("Video author cannot be empty");
    } else if (_.isEmpty(category)) {
      return dispatchSnackbarError("Video category cannot be empty");
    } else if (_.isEmpty(videoUrl)) {
      return dispatchSnackbarError("Video url cannot be empty");
    } else if (videoUrl.includes("/")) {
      return dispatchSnackbarError("Please enter valid url");
    }

    const data = {
      title: title,
      description: description,
      author: author,
      category: category,
      url: `https://www.youtube.com/embed/${videoUrl}`,
      ...getSharingInfo(),
    };
    props.handleSave(data);

    setTitle("");
    setDescription("");
    setAuthor("");
    setVideoUrl("");
    setCategory({});
    setDefaultDescription("");
  };

  return (
    <BlankSidePanel show={props.show} handleClose={props.handleClose} title={props.isEdit ? "Edit Video" : "Add Video"}>
      <div className="add-video-form">
        <Form.Group>
          <Form.Label>Title *</Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={title} onChange={(e) => setTitle(e.target.value)}></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <div className="note-editor-wrapper border h-220px">
            <RichTextEditor defaultValue={defaultDescription} value={description} onChange={(c) => setDescription(c)} />
          </div>
          {/* <Form.Control maxLength={InputLimit.TEXTAREA} value={description} onChange={e => setDescription(e.target.value)} as="textarea" rows={3} /> */}
        </Form.Group>

        <Form.Group>
          <Form.Label>Author *</Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={author} onChange={(e) => setAuthor(e.target.value)}></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>YouTube Embbed URL *</Form.Label>
          <InputGroup>
            <div className="add-video-embbed-link">https://www.youtube.com/embed/</div>
            <Form.Control placeholder="Enter Youtube Id" type={"text"} maxLength={InputLimit.URL} value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)}></Form.Control>
          </InputGroup>
          <Form.Text>Eg. https://www.youtube.com/embed/1UdGeQhb_UU</Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Category *</Form.Label>
          {/* <SimpleDropdown
                    value={category}
                    label={"Category"}
                    options={props.categoryList.map((each) => ({ label: each.name, value: each._id }))}
                    handleSelect={setCategory}
                /> */}
          <AdvancedDropdown
            storePath={"Learn"}
            storeSubPath={"category"}
            addAPI={props.addAPI}
            editAPI={props.editAPI}
            deleteAPI={props.deleteAPI}
            field={{ fieldName: "Category", apiKeyName: "category" }}
            value={category}
            setValue={setCategory}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Share</Form.Label>
          <ShareUserInput defaultValue={sharing} setValue={setSharing} />
        </Form.Group>
        <Form.Group className="d-flex gap-3 mt-10">
          <Button size="sm" variant="secondary" onClick={props.handleClose}>
            {"Close"}
          </Button>
          <GreenGradientButton size="sm" onClick={handleSave}>
            {"Save"}
          </GreenGradientButton>
        </Form.Group>
      </div>
    </BlankSidePanel>
  );
}
