import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { dispatchSnackbarSuccess } from "../../../utils/toaster";

const getHostURL = () => {
  if (window.location.host === "app.salessimplify.com") {
    return "https://bot.salessimplify.com";
  }
  return "https://leadbot-dev10.salessimplify.com";
};

const scr = (widgetKey) => `<script>
    window.salesSimplify = {
        leadbot: '${widgetKey}'
    };
    (function () {
        var script = document.createElement('script');
        script.src = '${getHostURL()}/leadbotWidget.bundle.js';
        script.async = true;
        var entry = document.getElementsByTagName('script')[0];
        entry.parentNode.insertBefore(script, entry);
    })();
</script>`;

const landingUrl = (widgetKey) => `${getHostURL()}/c/${widgetKey}`;
const normalFormUrl = (widgetKey) => `${getHostURL()}/n/${widgetKey}`;
const modernFormUrl = (widgetKey) => `${getHostURL()}/m/${widgetKey}`;

const integrate = (widgetKey) => `
<iframe src="${getHostURL()}/embed/${widgetKey}" width="500px" height="400px"></iframe>`;

const normateIntegrate = (widgetKey) => `
<iframe src="${getHostURL()}/n/${widgetKey}" width="500px" height="400px"></iframe>`;

const modernIntegrate = (widgetKey) => `
<iframe src="${getHostURL()}/m/${widgetKey}" width="500px" height="400px"></iframe>`;

export default function CopyCodeModel({ show, handleClose, id }) {
  const tabsList = ["Leadbot - Conversational", "Normal Form", "Modern Form"];
  const [activeTab, setActiveTab] = useState(0);

  const onCopyToClipBoard = (type) => {
    var textField = document.createElement("textarea");
    if (type === "chatbot") {
      textField.innerText = scr(id);
    } else if (type === "embed") {
      textField.innerText = integrate(id);
    } else if (type === "normal") {
      textField.innerText = normalFormUrl(id);
    } else if (type === "normal-embed") {
      textField.innerText = normateIntegrate(id);
    } else if (type === "modern") {
      textField.innerText = modernFormUrl(id);
    } else if (type === "modern-embed") {
      textField.innerText = modernIntegrate(id);
    } else {
      textField.innerText = landingUrl(id);
    }
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    dispatchSnackbarSuccess("Copied to Clipboard!");
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>Grab your code or share it.</Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          {tabsList.map((tabItem, index) => (
            <div key={index} className={`${tabItem === tabsList[activeTab] ? "border-bottom-width-3px border-bottom-style-solid border-bottom-color-000000" : ""}`}>
              <button className={`btn tab-font ${tabItem === tabsList[activeTab] ? "fw-700" : ""}`} onClick={() => setActiveTab(index)}>
                {tabItem}
              </button>
            </div>
          ))}
        </div>
        <div>
          {activeTab === 0 ? (
            <div className="p-5">
              <div>The form will be displayed in a conversational chatbot format where users will be prompted to fill out one question at a time, similar to a chat.</div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Landing Page link</div>
                <p> {"Share the following URL to view a full-page normal form."}</p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="1" value={landingUrl(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("landing")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Embed within webpage</div>
                <p>
                  {" "}
                  {"Present your chatbot seamlessly as part of your page. For example, you can use it contact us or similar pages to capture responses.You can change the width and height px as per your requirement."}
                </p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="3" value={integrate(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("embed")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Show it as a chatbot</div>
                <p> {"It will appear as a chatbot on the website. Place it in the HTML <head> of any web page where you wish to have it appear, or add code into the header to appear on the entire website."}</p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="12" value={scr(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("chatbot")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
            </div>
          ) : activeTab === 1 ? (
            <div className="p-5">
              <div>By default, the normal form will display all the questions on a single screen, and a submit button will be provided to close the form.</div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Landing Page link</div>
                <p> {"Share the following URL to view a full-page normal form."}</p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="1" value={normalFormUrl(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("normal")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Embed within webpage</div>
                <p> {"Show the form as part of your page. For example, you can use it contact us or similar pages to capture responses. You can change the width and height px as per your requirement."}</p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="3" value={normateIntegrate(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("normal-embed")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-5">
              <div>Each question will be displayed one at a time on a single screen, and the second question will be shown only after the first one is completed.</div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Landing Page link</div>
                <p> {"Share the following URL to view a full-page modern form."}</p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="1" value={modernFormUrl(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("modern")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
              <div>
                <div className="fs-6 mt-8 fw-bold">Embed within webpage</div>
                <p> {"Show the form as part of your page. For example, you can use it contact us or similar pages to capture responses. You can change the width and height px as per your requirement."}</p>
                <div className="card script-container min-height-inherit bg-fafafc">
                  <textarea className="w-100 font-size-14px p-20px bg-black border-radius-4px text-white border-none resize-none" rows="3" value={modernIntegrate(id)} />
                  <div className="copy-to-clipboard-wrapper" onClick={() => onCopyToClipBoard("modern-embed")}>
                    Copy To Clipboard
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
