import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { DeleteModal } from "../../../components/modal";
import "../styles.css";
import {
  // Link,
  useHistory,
} from "react-router-dom";
import { CustomSubHeader } from "../../../components/subheader";
import { BlueGradientButton } from "../../../components/button";
import { useDispatch, useSelector } from "react-redux";
import * as LeadBotActions from "../actions";
import Table from "../components/table";
import CopyCodeModel from "../components/copy-code.model";
import AddLeadBotModel from "../components/add-lead-bot.model";
import { defaultQuestions, defaultCustomisation, defaultThankYouScreen, defaultWelcomeScreen } from "./default.constant";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import ContentWrapper from "../../Wrapper";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";

export default function LeadBotList() {
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [showScript, setShowScript] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [botName, setBotName] = useState("");
  const [loading, setLoading] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [showAddLeadBotPopup, setShowAddLeadBotPopup] = useState(false);
  const tablehead = ["Bot Name", "Tag", "Lead Count", "Status", "Created At"];
  const dataHead = ["name", "tags", "count", "status", "createdAt"];

  const dispatch = useDispatch();
  const history = useHistory();

  const botsList = useSelector((store) => store.leadBot.leadbotsList);

  useEffect(() => {
    dispatch(LeadBotActions.getLeadbotsListRequest({ page: pageNo, limit: limit }));
  }, [dispatch, pageNo, limit]);

  const onDeleteClick = (id) => {
    setShowDeleteModel(true);
    setSelectedId(id);
  };
  const onAccepetDelete = () => {
    dispatch(LeadBotActions.deleteLeadBotRequest({ id: selectedId, page: pageNo, limit: limit }));
    setShowDeleteModel(false);
    setSelectedId("");
  };
  const onEditClick = (id) => {
    history.push(`/lead-bot/builder/${id}`);
  };

  const deleteLeadBotModelProps = {
    show: showDeleteModel,
    title: "Delete Lead Bot",
    handleClose: () => setShowDeleteModel(false),
    handleAccept: onAccepetDelete,
  };
  const addLeabotModelProps = {
    show: showAddLeadBotPopup,
    loading: loading,
    handleClose: () => handleAddLeadbotCancel(),
    handleSave: () => handleAddLeadbotAccept(),
    setBotName: setBotName,
    botName: botName,
  };
  const handleAddLeadbotCancel = () => {
    setShowAddLeadBotPopup(false);
    setBotName("");
  };
  const handleAddLeadbotAccept = () => {
    if (!botName.trim()) {
      return dispatchSnackbarError("Bot name cannot be empty!");
    }
    setLoading(true);

    dispatch(
      LeadBotActions.createLeadBotRequest(
        {
          name: botName,
          isEnabled: false,
          tags: [],
          assignee: [],
          welcomeScreen: defaultWelcomeScreen,
          questionsScreen: defaultQuestions,
          thankYouScreen: defaultThankYouScreen,
          customization: defaultCustomisation,
        },
        (data) => {
          setLoading(false);
          setShowAddLeadBotPopup(false);
          if (data.status === true) {
            history.push(`/lead-bot/builder/${data.data._id}`);
          } else if (data.message) {
            dispatchSnackbarError(data.message);
          } else dispatchSnackbarError("Error occured during creating leadbot");
        },
      ),
    );
  };

  const getLeadBotData = (id) => {
    return new Promise((resolve, reject) =>
      dispatch(
        LeadBotActions.getLeadBotDataRequest(id, (data) => {
          if (data.status === true) {
            resolve(data.data);
          } else resolve(null);
        }),
      ),
    );
  };
  const validate = (data) => {
    const { welcomeScreen, thankYouScreen, questionsScreen, customization } = data;
    const urlRegex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
    if (_.isEmpty(data.name)) {
      return "Form name cannot be empty!";
    } else if (_.isEmpty(data.tags)) {
      return "Form tags cannot be empty!";
    } else if (_.isEmpty(data.assignee)) {
      return "Form owners cannot be empty!";
    }
    if (welcomeScreen.isEnabled) {
      if (welcomeScreen.mediaUrl && !welcomeScreen.mediaUrl.match(urlRegex)) {
        return "The url must end with image extension. Eg. png/svg/jpg/webp/gif/jpeg";
      } else if (_.isEmpty(welcomeScreen.descrption)) {
        return "Description in welcome screen cannot be empty!";
      } else if (_.isEmpty(welcomeScreen.buttonText)) {
        return "Button name in welcome screen cannot be empty!";
      }
    }
    if (thankYouScreen.isEnabled) {
      if (_.isEmpty(thankYouScreen.descrption)) {
        return "Description in thank you message screen cannot be empty!";
      }
    }
    if (_.isEmpty(questionsScreen)) {
      return "Expect atleast one question to save lead bot.";
    }

    if (customization.showHeader) {
      if (_.isEmpty(customization.typeOfShowHeader)) {
        return "type of header in customisation cannot be empty!";
      }
      if (customization.typeOfShowHeader === "text" && _.isEmpty(customization.headerName)) {
        return "Header custome name in customisation cannot be empty!";
      }
      if (customization.typeOfShowHeader === "logo" && _.isEmpty(customization.headerLogo)) {
        return "Header custome logo in customisation cannot be empty!";
      }
      if (customization.typeOfShowHeader === "logo" && !customization.headerLogo.match(urlRegex)) {
        return "The url must end with image extension. Eg. png/svg/jpg/webp/gif/jpeg";
      }
    }
    return "";
  };

  const onAddLeadBotClick = () => {
    setShowAddLeadBotPopup(true);
    setBotName("");
  };

  const onStatusChange = async (id, status) => {
    if (status) {
      const data = await getLeadBotData(id);
      if (!data) return dispatchSnackbarError("Some error occured");

      const validation = validate(data);
      if (validation) return dispatchSnackbarError("There appears to be some missing data in your bot. Please edit the lead bot in order for it to be published.");
    }
    dispatch(
      LeadBotActions.editLeadBotDataRequest(
        {
          _id: id,
          doc: {
            isEnabled: status,
          },
        },
        () => {
          dispatch(LeadBotActions.getLeadbotsListRequest({ page: pageNo, limit: limit }));
        },
      ),
    );
  };

  const onClone = async (id) => {
    setCloneLoading(true);
    const data = await getLeadBotData(id);
    if (!data) return dispatchSnackbarError("There appears to be some error during copying leadbot.");

    dispatch(
      LeadBotActions.createLeadBotRequest(
        {
          name: `copy of ${data.name}`,
          isEnabled: false,
          tags: data.tags,
          assignee: data.assignee,
          welcomeScreen: data.welcomeScreen,
          questionsScreen: data.questionsScreen,
          thankYouScreen: data.thankYouScreen,
          customization: data.customization,
        },
        (data) => {
          setCloneLoading(false);
          if (data.status === true) {
            dispatchSnackbarSuccess("Successfully cloned");
          } else if (data.message) {
            dispatchSnackbarError(data.message);
          } else dispatchSnackbarError("Error occured during cloning leadbot");
          dispatch(LeadBotActions.getLeadbotsListRequest({ page: pageNo, limit: limit }));
        },
      ),
    );
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex w-100 justify-content-end">
            <BlueGradientButton variant="success" size="sm" onClick={() => onAddLeadBotClick()}>
              + Leadbot
            </BlueGradientButton>
          </div>
        </CustomSubHeader>
      }
    >
      {botsList.loading || cloneLoading ? (
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : botsList.data.length === 0 ? (
        <CustomEmptyPage page="leadbot" setShowAdd={onAddLeadBotClick} />
      ) : (
        <Table
          columns={tablehead}
          dataHead={dataHead}
          data={botsList.data}
          pagination={true}
          count={botsList.count}
          limit={limit}
          pageNo={pageNo}
          setLimit={setLimit}
          setPageNo={setPageNo}
          loading={botsList.loading}
          onStatusChange={onStatusChange}
          onDeleteClick={(id) => onDeleteClick(id)}
          onEditClick={(id) => onEditClick(id)}
          onClone={(id) => onClone(id)}
          onCodeClick={(id) => {
            setShowScript(true);
            setSelectedId(id);
          }}
        />
      )}
      <CopyCodeModel
        id={selectedId}
        show={showScript}
        handleClose={() => {
          setShowScript(false);
          selectedId("");
        }}
      />
      <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete lead form? After deleting lead form you will not able to recover it.</DeleteModal>
      <AddLeadBotModel modalProps={addLeabotModelProps} />
    </ContentWrapper>
  );
}
