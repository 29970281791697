import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomPagination from "../../pagination/custom-pagination";
import HandleAdvanceTableData from "./handle-data";
import "./styles.scss";
import DataTableActions from "./table-actions";
import InlineStyled from "../../inline-styled/inline-styled";

export default function AdavanceDataTable(props) {
  const handleRowClick = (index) => {
    if (props.expandable && props.handleRowClick) {
      props.handleRowClick(index);
    }
  };
  return (
    <div className="ss-advance-data-table">
      <div className="table-responsive overflow-y-auto">
        <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
          <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
            <thead>
              <tr className="fw-bolder text-muted">
                {props.columns.map((each, index) => {
                  return (
                    <InlineStyled as="th" key={index} className="cursor-pointer pt-0 bg-white color-black top-0 position-sticky text-nowrap z-index-1" styles={{ minWidth: `${100 / props.columns.length}%` }}>
                      <div className={`d-flex flex-row w-100 h-100 gap-3 align-items-center text-nowrap opacity-0-point-8 ${each.isFixed ? "table-action-wrapper" : ""} text-black`}>{each.title}</div>
                    </InlineStyled>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.data.map((data, rowIndex) => {
                return (
                  <>
                    <tr key={rowIndex} onClick={() => handleRowClick(rowIndex)}>
                      {props.columns.map((col, index) => (
                        <td className="text-nowrap" key={index}>
                          {col.isAction ? (
                            <DataTableActions
                              options={col.options}
                              data={data}
                              handleReport={props.handleReport}
                              handleEdit={props.handleEdit}
                              handleDelete={props.handleDelete}
                              handleOnChoose={props.handleOnChoose}
                              handleDownload={props.handleDownload}
                            />
                          ) : (
                            <div className="d-flex flex-row flex-stack">
                              <HandleAdvanceTableData
                                keyIndex={`${rowIndex}-${index}`}
                                data={data}
                                value={data[col.apiKeyName]}
                                showDownloadIcon={false}
                                field={col}
                                type={col.fieldInputType}
                                onStatusChange={(checked) => {
                                  if (props.onStatusChange) {
                                    props.onStatusChange(data._id, checked);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                    {props.expandable && props.expandableIndex === rowIndex && (
                      <tr>
                        <td className="bg-light-light" colSpan={props.columns.length}>
                          {props.expandableComponent}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </PerfectScrollbar>
      </div>
      <div className="pt-7px d-flex flex-row justify-content-end align-items-center px-3 gap-15px">
        <p className="mt-5px mb-0 font-size-14px">
          Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
        </p>
        {CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), () => {}, props.limit, props.setLimit)}
      </div>
    </div>
  );
}
