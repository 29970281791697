import { getListOfWhatsappAccounts, getWhatsappNumberProfile, getWhatsappConsumptionCount } from "../../types/campaign_whatsapp_settings_type";

const initState = {
    data: {
        list: [],
        loading: false,
        error: false,
    },
    selected: {
        data: {},
        apiData: {},
        loading: false,
        error: false,
    },
    limit: {
        count: 0,
        loading: false
    }
};

export default function campaignWhatsappSettingsReducer(state = initState, action = {}) {
    switch (action.type) {
        case getListOfWhatsappAccounts.REQUEST:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: true,
                }
            };
        case getListOfWhatsappAccounts.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: false,
                    list: action.payload.data,
                }
            };
        case getListOfWhatsappAccounts.FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: false,
                    error: true,
                }
            };
        case getWhatsappNumberProfile.REQUEST:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: true,
                }
            };
        case getWhatsappNumberProfile.SUCCESS:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: false,
                    data: action.payload.data,
                    apiData: action.payload.apiData,
                }
            };
        case getWhatsappNumberProfile.FAILURE:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: false,
                    error: true,
                }
            };
        case getWhatsappConsumptionCount.REQUEST:
            return {
                ...state,
                limit: {
                    ...state.limit,
                    loading: true,
                }
            };
        case getWhatsappConsumptionCount.SUCCESS:
            return {
                ...state,
                limit: {
                    ...state.limit,
                    loading: false,
                    count: action.payload.count,
                }
            };
        case getWhatsappConsumptionCount.FAILURE:
            return {
                ...state,
                limit: {
                    ...state.limit,
                    loading: false,
                    error: true,
                }
            };

        default:
            return state;
    }
}
