import _ from "lodash";
import React, { useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { DeleteIconButton, EditIconButton } from "../../../../../components/button/icon-action-buttons";
import { CustomCheckBox } from "../../../../../components/custom-inputs/checkbox-selector";
import { BackSVG } from "../../../../../components/custom-svgs";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { DeleteModal } from "../../../../../components/modal";
import { emailRecipientOptions, eventBufferTimeDaysOptions, eventBufferTimeMinutesOptions, eventTimeHoursOptions, minCancelNoticeTypeOptions } from "../constants";

export default function RemindersForm(props) {
  const [showAddReminderScreen, setShowAddReminderScreen] = useState(false);
  const [eventTimeType, setEventTimeType] = useState({ value: "hours", label: "Hours" });
  const [eventTime, setEventTime] = useState({ value: 0, label: "0", duration: 0 });
  const [emailRecipient, setEmailRecipient] = useState({ value: "both", label: "Send to both" });
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [subject, setSubject] = useState("Reminder for our upcoming meeting");

  const updateEventTypeValue = (value) => {
    if (value) {
      const data = eventTimeHoursOptions.find((each) => each.duration === value);
      if (data) {
        setEventTimeType({ value: "hours", label: "Hours" });
        setEventTime(data);
      } else {
        const data2 = eventBufferTimeDaysOptions.find((each) => each.duration === value);
        if (data2) {
          setEventTimeType({ value: "days", label: "Days" });
          setEventTime(data2);
        } else {
          const data3 = eventBufferTimeMinutesOptions.find((each) => each.duration === value);
          if (data3) {
            setEventTimeType({ value: "minutes", label: "Minutes" });
            setEventTime(data3);
          }
        }
      }
    }
  };

  const updateData = (parentKey, key, value) => {
    if (parentKey) {
      const data = {
        ...props.data,
        config: {
          ...props.data.config,
          [parentKey]: {
            ...props.data.config[parentKey],
            [key]: value,
          },
        },
      };
      props.setSelectedData(data);
    } else {
      const data = {
        ...props.data,
        config: {
          ...props.data.config,
          [key]: value,
        },
      };
      props.setSelectedData(data);
    }
  };

  const handleEventTypeChange = (data) => {
    setEventTimeType(data);
    setEventTime({ value: 0, label: "0", duration: 0 });
  };

  const deleteReminderModalProps = {
    show: showDeleteModal,
    title: "Delete Reminder",
    handleClose: () => onHandleClose(),
    handleAccept: () => onDeleteAccept(),
  };

  const onHandleClose = () => {
    setShowDeleteModal(false);
    setDeleteIndex(-1);
  };

  const onDeleteAccept = () => {
    const data = props.data.config.reminders.filter((e, i) => i !== deleteIndex);
    updateData("", "reminders", data);
    setShowDeleteModal(false);
    setDeleteIndex(-1);
  };

  const handleEditReminderClick = (data, index) => {
    setDeleteIndex(index);
    setSubject(data.email_subject);
    setEmailRecipient(emailRecipientOptions.find((each) => each.value === data.delivery_recipient));
    updateEventTypeValue(data.time_before_event);
    setShowAddReminderScreen(true);
    setIsEdit(true);
  };
  const handleBackClick = () => {
    setDeleteIndex(-1);
    setSubject("Reminder for our upcoming meeting");
    setShowAddReminderScreen(false);
    setIsEdit(false);
  };

  const handleReminderEditOrCreate = () => {
    if (isEdit) {
      const data = props.data.config.reminders.map((each, index) => {
        if (index === deleteIndex) {
          each.time_before_event = eventTime.duration;
          each.delivery_recipient = emailRecipient.value;
          each.email_subject = subject;
        }
        return each;
      });
      updateData("", "reminders", data);
      handleBackClick();
    } else {
      const newData = {
        delivery_method: "email",
        delivery_recipient: emailRecipient.value,
        email_subject: subject,
        time_before_event: eventTime.duration,
        webhook_url: "",
      };
      updateData("", "reminders", [...props.data.config.reminders, newData]);
      handleBackClick();
    }
  };

  return (
    <Form.Group>
      {!showAddReminderScreen ? (
        <>
          <Form.Group>
            <Form.Label>How should new bookings and cancellations be communicated?</Form.Label>
            <CustomCheckBox
              label={"Send me a confirmation email"}
              isChecked={props.data.config.booking.confirmation_emails_to_host}
              onClick={(e) => {
                updateData("booking", "confirmation_emails_to_host", e.target.checked);
              }}
            />
            <CustomCheckBox
              label={"Send guests a confirmation email"}
              isChecked={props.data.config.booking.confirmation_emails_to_guests}
              onClick={(e) => {
                updateData("booking", "confirmation_emails_to_guests", e.target.checked);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>How should everyone be reminded of upcoming bookings?</Form.Label>
            <ListGroup>
              {props.data.config.reminders.map((each, index) => {
                return (
                  <ListGroup.Item key={index} className="d-flex flex-stack align-items-center">
                    <div>
                      <div>
                        {_.capitalize(each.delivery_method)} {each.delivery_recipient === "customer" ? "guests" : each.delivery_recipient === "owner" ? "host" : "everyone"} with subject "{each.email_subject}"
                      </div>
                      <div>{each.time_before_event} min before the event</div>
                    </div>
                    <div className="d-flex gap-1">
                      <EditIconButton onClick={() => handleEditReminderClick(each, index)} />
                      <DeleteIconButton
                        onClick={() => {
                          setShowDeleteModal(true);
                          setDeleteIndex(index);
                        }}
                      />
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Form.Group>
          <Form.Group>
            <Button
              size={"sm"}
              onClick={() => {
                setShowAddReminderScreen(true);
                setIsEdit(false);
              }}
            >
              Add Reminder
            </Button>
          </Form.Group>
        </>
      ) : (
        <Form>
          <Form.Group>
            <Form.Label className="d-flex align-items-center gap-2 cursor-pointer text-hover-primary" onClick={handleBackClick}>
              {BackSVG} <span>Back</span>
            </Form.Label>
          </Form.Group>
          <Form.Group className="d-flex align-items-center gap-3 w-50prc">
            <SimpleDropdown
              value={eventTime}
              label={"Select"}
              options={eventTimeType.value === "hours" ? eventTimeHoursOptions : eventTimeType.value === "days" ? eventBufferTimeDaysOptions : eventBufferTimeMinutesOptions}
              handleSelect={(data) => setEventTime(data)}
            />
            <SimpleDropdown value={eventTimeType} label={"Select"} options={minCancelNoticeTypeOptions} handleSelect={(a) => handleEventTypeChange(a)} />
            <Form.Label className="w-340px">before the event:</Form.Label>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Email Recipients</Form.Label>
                <SimpleDropdown value={emailRecipient} label={"Select"} options={emailRecipientOptions} handleSelect={(a) => setEmailRecipient(a)} />
              </Col>
              <Col>
                <Form.Label>Email Subject</Form.Label>
                <Form.Control type="text" placeholder="Your meeting is coming up!" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Button size="sm" onClick={handleReminderEditOrCreate}>
              {!isEdit ? "Create" : "Edit"}
            </Button>
          </Form.Group>
        </Form>
      )}
      <DeleteModal modalProps={deleteReminderModalProps}>Are you sure you want to delete the reminder?</DeleteModal>
    </Form.Group>
  );
}
