/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react"; //{useState,useEffect}
import _ from "lodash";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useSelector } from "react-redux";
import DeactiveCompany from "./Settings/account/company-setting/deactivate-company";
import Billing from "./Settings/Billing";
import OauthRoutes from "../oauth.2";
import UnsubscribeCamapignService from "./unAuthScreens/unsubscribe";

// auth pages
import RegistrationV2 from "./modules/Auth/pages/containers/registration-v2";
import ForgotPassword from "./modules/Auth/pages/containers/forgot-password";
import Login from "./modules/Auth/pages/containers/login";
import InviteUser from "./modules/Auth/pages/containers/invite-user";

export function Routes() {
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const isBillingExpired = () => {
    // if (accountBillingStore.loading) return false;
    if (_.isEmpty(accountBillingStore.data)) return false;
    const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
    if (isFreeTrialPeriod || isSubscribedForLifeTimeFree) return false;
    else if (["active", "past_due", "trialing"].includes(subscriptionStatus)) return false;
    else if (["none", "canceled", "paused"].includes(subscriptionStatus)) return true;
    return true;
  };
  console.log("routes", isBillingExpired());
  return (
    <Switch>
      <Route path="/oauth" component={OauthRoutes} />
      <Route exact path="/unsubscribe/:moduleName/:accountId/:subscriberId/:stepId" component={UnsubscribeCamapignService} />
      <Route path="/unsubscribe/:accountId/:customerId" component={UnsubscribeCamapignService} />
      <Route path="/auth/manage-user/invite" component={InviteUser} />

      {!localStorage.getItem("access_token") ? (
        <Switch>
          <Route path="/auth/registration" component={RegistrationV2} />
          <Route path="/auth/google-signup" component={RegistrationV2} />
          <Route path="/auth/forgot-password" component={ForgotPassword} />
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/manage-user/invite" component={InviteUser} />
          <Route>
            <AuthPage />
          </Route>
        </Switch>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!localStorage.getItem("access_token") ? (
        <Redirect to="/auth/login" />
      ) : accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.accountStatus === "DEACTIVATED" ? (
        <Layout>
          <DeactiveCompany />
        </Layout>
      ) : isBillingExpired() ? (
        <Layout>
          <Route path="/settings/billing" component={Billing} />
          <Redirect to="/settings/billing/subscription" />
        </Layout>
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
