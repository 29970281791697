import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";
import { BlueGradientButton } from "../../button";
import { dispatchSnackbarError } from "../../../utils/toaster";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//actions
import * as NotesActions from "../../../containers/Settings/actions/notes.actions";

import RichTextEditor from "../../RichTextEditor/tinymce-editor";
import { moduleColors, TimelineDataWrapper } from "./timeline-v2.helper";
import { renderLinkedData3 } from "./helper";
import InlineStyled from "../../inline-styled/inline-styled";

const getCommonQuery = (props) => {
  const queryMaker = {};
  if (props.type === "people") {
    queryMaker["peopleId"] = props.peopleId;
  } else if (props.type === "lead") {
    queryMaker["leadId"] = props.leadId;
  } else if (props.type === "deal") {
    queryMaker["dealId"] = props.dealId;
  } else if (props.type === "company") {
    queryMaker["companyId"] = props.companyId ? props.companyId : props.leadId;
  } else {
    queryMaker["peopleId"] = props.peopleId;
    queryMaker["companyId"] = props.companyId;
    queryMaker["leadId"] = props.leadId;
    queryMaker["dealId"] = props.dealId;
  }
  return queryMaker;
};

export function Notes(props) {
  const myDivToFocus = useRef(null);

  const [userObject, setUserObject] = useState({});
  const [isNoteEdit, setIsNoteEdit] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState("");
  const [content, setContent] = useState("");
  const [defaultValue, setDefaultValue] = useState("");

  const notesData = useSelector((state) => state.notes.notes);
  const userRole = useSelector((store) => store.insight.userRole);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userRole.list.allUsers) {
      const obj = {};
      userRole.list.allUsers.forEach((each) => {
        obj[each.id] = each;
      });
      setUserObject(obj);
    }
  }, [userRole.list.allUsers]);

  useEffect(() => {
    const queryMaker = {};
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }
    dispatch(NotesActions.GetNotesRequest(queryMaker));
  }, [dispatch, props.leadId, props.peopleId, props.dealId, props.companyId, props.type]);

  const handleSaveNote = () => {
    if (!content) {
      dispatchSnackbarError("Note Body can not be blank!");
      return;
    }
    if (isNoteEdit) {
      const tempNoteBody = {
        id: selectedNoteId,
        content: content,
      };
      dispatch(NotesActions.EditNotesRequest({ body: tempNoteBody, request: getCommonQuery(props) }));
    } else {
      const tempNoteBody = {
        ...getCommonQuery(props),
        content: content,
      };
      dispatch(NotesActions.AddNotesRequest({ body: tempNoteBody, request: getCommonQuery(props) }));
    }
    handleCancelEdit();
    setDefaultValue("");
    setContent("");
  };

  const handleDelete = (id) => {
    dispatch(NotesActions.DeleteNotesRequest({ id: id, request: getCommonQuery(props) }));
  };
  const handleEdit = (note) => {
    setIsNoteEdit(true);
    setSelectedNoteId(note._id);
    setContent(note.content);
    setDefaultValue(note.content);
  };

  const handleCancelEdit = () => {
    setIsNoteEdit(false);
    setSelectedNoteId("");
    setContent("");
    setDefaultValue("");
  };
  const getUserName = (userId) => {
    if (userObject[userId]) {
      if (userObject[userId].name) return userObject[userId].name;
      else if (userObject[userId].email) return userObject[userId].email;
      else return "Unknown";
    } else return "Unknown";
  };

  return (
    <div className="note-side-pannel-container">
      <InlineStyled as={Card} id="editor" ref={myDivToFocus} styles={{ width: props.width ? props.width : "100%" }} className="d-flex flex-column panel-shadow">
        <div className="note-editor-header">{isNoteEdit ? "Edit Note" : "New Note"}</div>
        <div className="note-editor-wrapper h-220px">
          <RichTextEditor defaultValue={defaultValue} value={content} onChange={(c) => setContent(c)} />
        </div>
        <Card.Footer className="border-0 p-0">
          {isNoteEdit ? (
            <div className="action-buttons-wrapper">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  handleCancelEdit();
                }}
                className="mr-2"
              >
                {" "}
                Cancel{" "}
              </Button>
              <BlueGradientButton
                onClick={() => {
                  handleSaveNote();
                  setContent("");
                  setDefaultValue("");
                }}
                size="sm"
              >
                Edit
              </BlueGradientButton>
            </div>
          ) : (
            <div className="action-buttons-wrapper">
              <BlueGradientButton
                onClick={() => {
                  handleSaveNote();
                  setContent("");
                  setDefaultValue("");
                }}
                size="sm"
              >
                Save
              </BlueGradientButton>
            </div>
          )}
        </Card.Footer>
      </InlineStyled>
      <div>
        {notesData.loading ? (
          <div className="w-100 d-flex p-7 justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="note-header-text"> Linked Notes </div>

            {notesData && notesData.list && notesData.list.length > 0 ? (
              <div>
                {" "}
                {notesData.list.map((note, index) => {
                  return (
                    <div id={`note-${index}`} key={index} className="mb-40px">
                      {" "}
                      <TimelineDataWrapper moduleName={"Note"} createdAt={note.createdAt} moduleColor={moduleColors["notes"]} createdText={`Added by ${getUserName(note.createdBy)}`}>
                        {note.content && (
                          <div className="d-flex position-relative">
                            <div className="w-100-60px">
                              {renderLinkedData3(note, props.basePath, history)}
                              <div dangerouslySetInnerHTML={{ __html: note.content || "" }}></div>
                            </div>
                            <div className="w-60px">
                              <button className="btn btn-icon btn-light h-25px w-25px" onClick={() => handleEdit(note)}>
                                <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Edit</Tooltip>}>
                                  <a href="#editor">
                                    <i className="fa fa-sm fa-pen font-size-13px"></i>
                                  </a>
                                </OverlayTrigger>
                              </button>
                              <button className="btn btn-icon btn-light ml-2 h-25px w-25px" onClick={() => handleDelete(note._id)}>
                                <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
                                  <i className="fa fa-sm fa-trash font-size-13px"></i>
                                </OverlayTrigger>
                              </button>
                            </div>
                          </div>
                        )}
                      </TimelineDataWrapper>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="note-no-data-text">This record doesn't have any notes.</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
