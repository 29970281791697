import * as BillingActions from "../actions/subscription_billing_actions";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_BILLING_PRICES_REQUEST,
  BILLING_PLAN_CHECKOUT_REQUEST,
  asssignFreePlan,
  BILLING_CUSTOMER_PORTAL_REQUEST,
  GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST,
  GET_ACCOUNT_BILLING_REQUEST,
  GET_INVOICESLIST_REQUEST,
  GET_INVOICESLIST_REQUEST_AFTER,
  CANCEL_RENEW_SUBSCRIPTION_REQUEST,
  GET_ACCOUNT_BILLING_REQUEST_AFTER,
} from "../types/subscription_billing_types";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

//get biling plans
function* getbillingPrices(action) {
  try {
    const response = yield call(api.get, "/user-service/api/billing/billing-plan-pricing", action.payload);
    if (response.status === 200) {
      yield put(BillingActions.getBillingPricesSuccess(response.data.data));
    }
  } catch (e) {
    yield put(BillingActions.getBillingPricesError(e));
  }
}

//create checkout session
function* billingPlanCheckout(action) {
  try {
    const response = yield call(
      api.post,
      action.payload.type === "update"
        ? "/user-service/api/billing/update-subscription"
        : action.payload.type === "onetime"
        ? "/user-service/api/billing/create-payment-checkout-session"
        : "/user-service/api/billing/create-checkout-session",
      action.payload.data,
    );
    if (response.status === 200) {
      yield put(BillingActions.billingPlanCheckoutSuccess(response.data));
      if (action.payload.type === "create" || action.payload.type === "onetime" || action.payload.type === "update") {
        yield call(delay, 2000);
        yield action.cb(response.data);
      }
      if (response.data.status) {
        dispatchSnackbarSuccess(response.data.message);
      } else {
        dispatchSnackbarError(response.data.message);
      }
    } else {
      dispatchSnackbarError(response.data.message);
    }
  } catch (e) {
    yield put(BillingActions.billingPlanCheckoutError(e));
  }
}

//customer portal

function* billingCustomerPortal(action) {
  try {
    const response = yield call(api.get, "/user-service/api/billing/customer-portal");
    if (response.status === 200) {
      yield put(BillingActions.billingCustomerPortalSuccess(response.data));
      yield action.cb(response.data);
    } else {
      dispatchSnackbarError(response.data.nessage);
    }
  } catch (e) {
    yield put(BillingActions.billingCustomerPortalError(e));
  }
}

function* billingSubscrptionDetails(action) {
  try {
    const response = yield call(api.get, "/user-service/api/billing/customer-details", action.payload);
    if (response.status === 200) {
      yield put(BillingActions.getBillingSubscriptionDetailsSuccess(response.data));
      // yield action.cb(response.data)
    }
  } catch (e) {
    yield put(BillingActions.getBillingSubscriptionDetailsError(e));
  }
}

//get account billing

function* getAccountBillingFunction(action) {
  try {
    const res = yield call(api.get, "/user-service/api/billing/get-account-billing", action.payload);
    if (res.status === 200) {
      yield put(BillingActions.getAccountBillingSuccess(res.data));

      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(BillingActions.getAccountBillingError(e));
  }
}

//get list of invoices

function* getInvoiceList(action) {
  try {
    const res = yield call(api.get, "/user-service/api/billing/invoices?limit=10", action.payload);
    if (res.status === 200) {
      yield put(BillingActions.getInvoiceListSuccess(res.data));
    }
  } catch (e) {
    yield put(BillingActions.getInvoiceListError(e));
  }
}

function* getInvoiceListAfter(action) {
  try {
    const res = yield call(api.get, `/user-service/api/billing/invoices?limit=2&startingAfter=${action.payload.startingAfter}`);
    if (res.status === 200) {
      yield put(BillingActions.getInvoiceListSuccess(res.data));
    }
  } catch (e) {
    yield put(BillingActions.getInvoiceListError(e));
  }
}

function* cancelOrRenewSubscription(action) {
  try {
    const res = yield call(api.post, `/user-service/api/billing/${action.payload}-subscription`);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message, "success");
      yield put(BillingActions.getAccountBillingRequest(() => {}));
      yield call(delay, 1000);
      yield put(BillingActions.getAccountBillingRequestAfter(() => {}));
      yield call(delay, 1000);
      yield put(BillingActions.getAccountBillingRequestAfter(() => {}));
      yield call(delay, 1000);
      yield put(BillingActions.getAccountBillingRequestAfter(() => {}));
    }
  } catch (e) {
    yield put(BillingActions.cancelRenewSubscriptionError(e));
  }
}

function* assignFreePlanRequest(action) {
  try {
    const res = yield call(api.post, "/user-service/api/billing/assign-free-plan");
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully converted to free plan");
      yield put(BillingActions.asssignFreePlanSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(BillingActions.asssignFreePlanError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(BillingActions.asssignFreePlanError(e));
  }
}

export default function*() {
  yield takeLatest(GET_BILLING_PRICES_REQUEST, getbillingPrices);
  yield takeLatest(BILLING_PLAN_CHECKOUT_REQUEST, billingPlanCheckout);
  // yield takeLatest(BILLING_CREATE_CUSTOMER_REQUEST, billingCreateCustomer)
  yield takeLatest(BILLING_CUSTOMER_PORTAL_REQUEST, billingCustomerPortal);
  yield takeLatest(GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST, billingSubscrptionDetails);
  yield takeLatest(GET_ACCOUNT_BILLING_REQUEST, getAccountBillingFunction);
  yield takeLatest(GET_ACCOUNT_BILLING_REQUEST_AFTER, getAccountBillingFunction);
  yield takeLatest(GET_INVOICESLIST_REQUEST, getInvoiceList);
  yield takeLatest(GET_INVOICESLIST_REQUEST_AFTER, getInvoiceListAfter);
  yield takeLatest(CANCEL_RENEW_SUBSCRIPTION_REQUEST, cancelOrRenewSubscription);
  yield takeLatest(asssignFreePlan.ASSIGN_FREE_PLAN_REQUEST, assignFreePlanRequest);
}
