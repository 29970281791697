import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

export default function ActivityTypeMenu(props) {
  const [list, setList] = useState([]);
  const [showNextButton, setShowNextButton] = useState(false);
  // const [allCount, setAllCount] = useState(0);

  const tabRef = useRef();

  const activityActiveTypesStore = useSelector((store) => store.activity.types.active);
  const activityTypeDataStore = useSelector((store) => store.activity.list.activityTypeData);

  const scrollLeft = () => {
    tabRef.current.scrollLeft -= 100;
  };
  const scrollRight = () => {
    tabRef.current.scrollLeft += 100;
  };

  useEffect(() => {
    if (tabRef.current && list.length > 0) {
      if (tabRef.current.clientWidth < tabRef.current.scrollWidth) {
        setShowNextButton(true);
      } else {
        setShowNextButton(false);
      }
    }
  }, [list]);

  useEffect(() => {
    if (activityTypeDataStore.length > 0) {
      const obj = {},
        idList = [];
      // let allCount = 0;

      activityTypeDataStore.forEach((each) => {
        obj[each._id] = each.count;
        idList.push(each._id);
        // allCount = allCount + each.count;
      });

      const updatedList = activityActiveTypesStore.list
        .filter((each) => idList.includes(each._id))
        .map((each) => {
          each.count = obj[each._id];
          return each;
        });
      setList(updatedList);
      // setAllCount(allCount);
    } else {
      setList([]);
    }
  }, [activityTypeDataStore, activityActiveTypesStore.list]);

  const handleTypeClick = (id) => {
    if (props.selectedActivityType.includes(id)) {
      props.handleActivityTypeClick(props.selectedActivityType.filter((a) => a !== id));
    } else {
      props.handleActivityTypeClick([...props.selectedActivityType, id]);
    }
  };

  const handleSelectAll = () => {
    props.handleActivityTypeClick([]);
  };

  if (list.length === 0) return <div></div>;
  return (
    <div className="activity-subheader-section-1">
      <div className="inner-section">
        {showNextButton && (
          <div className="back-button" onClick={scrollLeft}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" fill="#B5B5C3" />
            </svg>
          </div>
        )}
        <div
          ref={tabRef}
          className="activity-type-inner-wrapper menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
        >
          <div className="menu-item me-lg-1">
            <div
              onClick={() => handleSelectAll()}
              className={`d-flex gap-2 cursor-pointer activity-pill-button rounded-pill py-2 px-4 ${props.selectedActivityType.length === 0 ? "activity-pill-button-active" : "bg-f5f8fa"}`}
            >
              <span className="ellipsis-150px"> {"All"}</span>
              {/* <span className="activity-type-count"> {allCount}</span> */}
            </div>
          </div>
          {list.map((each, index) => (
            <div key={index} className="menu-item me-lg-1">
              <div
                onClick={() => handleTypeClick(each._id)}
                className={`d-flex gap-2 cursor-pointer activity-pill-button rounded-pill py-2 px-4 ${props.selectedActivityType.includes(each._id) ? "activity-pill-button-active" : "bg-f5f8fa"}`}
              >
                {/* <span className="menu-icon m-0">
              <span className="svg-icon svg-icon-2">
                <i className={`${each.symbName}`}></i>
              </span>
            </span> */}
                <span className="ellipsis-150px"> {each.name}</span>
                {/* <span className="activity-type-count"> {each.count}</span> */}
              </div>
            </div>
          ))}
        </div>
        {showNextButton && (
          <div className="next-button" onClick={scrollRight}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" fill="#B5B5C3" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
