/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./containers/registration";
import GoogleSignUp from "./googleAPiSIgnup";
import TwoFactAuth from "./twoFA";
import ForgotPassword from "./ForgotPassword";
import ManageUserInvite from "./manageUserInvite";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import logo from "../../../../_metronic/_assets/svg/progress.svg";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export function AuthPage(props) {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
          {/*begin::Aside*/}
          <InlineStyled
            className="background-repeat-no-repeat background-position-y-bottom background-size-contain flex-1-0-auto flex-direction-column d-flex h-130 login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 authDesign w-100 position-fixed bottom-0"
            styles={{
              backgroundImage: `url(${logo})`,
              // backgroundAttachment: "fixed",

              // position: "absolute",
            }}
          ></InlineStyled>
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" exact>
                  <Login setIsAuthorized={props.set} />
                </ContentRoute>

                <ContentRoute path="/auth/v2/registration">
                  <Registration setIsAuthorized={props.set} />
                </ContentRoute>

                <ContentRoute path="/auth/google-signup">
                  <GoogleSignUp setIsAuthorized={props.set} />
                </ContentRoute>

                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />

                <ContentRoute path="/auth/manage-user/invite" component={ManageUserInvite} />

                <ContentRoute path="/auth/2FA">
                  <TwoFactAuth setIsAuthorized={props.set} />
                </ContentRoute>
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
