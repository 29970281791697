export const country_code = [
  {
    name: "Afghanistan",
    iso2: "AF",
    code: "93",
  },
  {
    name: "Albania",
    iso2: "AL",
    code: "355",
  },
  {
    name: "Algeria",
    iso2: "DZ",
    code: "213",
  },
  {
    name: "American Samoa",
    iso2: "AS",
    code: "1 684",
  },
  {
    name: "Andorra",
    iso2: "AD",
    code: "376",
  },
  {
    name: "Angola",
    iso2: "AO",
    code: "244",
  },
  {
    name: "Anguilla",
    iso2: "AI",
    code: "1 264",
  },
  {
    name: "Antarctica",
    iso2: "AQ",
    code: "672",
  },
  {
    name: "Antigua and Barbuda",
    iso2: "AG",
    code: "1 268",
  },
  {
    name: "Argentina",
    iso2: "AR",
    code: "54",
  },
  {
    name: "Armenia",
    iso2: "AM",
    code: "374",
  },
  {
    name: "Aruba",
    iso2: "AW",
    code: "297",
  },
  {
    name: "Ascension Island",
    iso2: "AC",
    code: "247",
  },
  {
    name: "Australia",
    iso2: "AU",
    code: "61",
  },
  {
    name: "Austria",
    iso2: "AT",
    code: "43",
  },
  {
    name: "Azerbaijan",
    iso2: "AZ",
    code: "994",
  },
  {
    name: "Bahamas",
    iso2: "BS",
    code: "1 242",
  },
  {
    name: "Bahrain",
    iso2: "BH",
    code: "973",
  },
  {
    name: "Bangladesh",
    iso2: "BD",
    code: "880",
  },
  {
    name: "Barbados",
    iso2: "BB",
    code: "1 246",
  },
  {
    name: "Belarus",
    iso2: "BY",
    code: "375",
  },
  {
    name: "Belgium",
    iso2: "BE",
    code: "32",
  },
  {
    name: "Belize",
    iso2: "BZ",
    code: "501",
  },
  {
    name: "Benin",
    iso2: "BJ",
    code: "229",
  },
  {
    name: "Bermuda",
    iso2: "BM",
    code: "1 441",
  },
  {
    name: "Bhutan",
    iso2: "BT",
    code: "975",
  },
  {
    name: "Bolivia, Plurinational State Of",
    iso2: "BO",
    code: "591",
  },
  {
    name: "Bonaire, Saint Eustatius And Saba",
    iso2: "BQ",
    code: "599",
  },
  {
    name: "Bosnia & Herzegovina",
    iso2: "BA",
    code: "387",
  },
  {
    name: "Botswana",
    iso2: "BW",
    code: "267",
  },
  {
    name: "Bouvet Island",
    iso2: "BV",
    code: "",
  },
  {
    name: "Brazil",
    iso2: "BR",
    code: "55",
  },
  {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    code: "246",
  },
  {
    name: "Brunei Darussalam",
    iso2: "BN",
    code: "673",
  },
  {
    name: "Bulgaria",
    iso2: "BG",
    code: "359",
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    code: "226",
  },
  {
    name: "Burundi",
    iso2: "BI",
    code: "257",
  },
  {
    name: "Cambodia",
    iso2: "KH",
    code: "855",
  },
  {
    name: "Cameroon",
    iso2: "CM",
    code: "237",
  },
  {
    name: "Canada",
    iso2: "CA",
    code: "1",
  },
  {
    name: "Cape Verde",
    iso2: "CV",
    code: "238",
  },
  {
    name: "Cayman Islands",
    iso2: "KY",
    code: "1 345",
  },
  {
    name: "Central African Republic",
    iso2: "CF",
    code: "236",
  },
  {
    name: "Ceuta, Mulilla",
    iso2: "EA",
    code: "",
  },
  {
    name: "Chad",
    iso2: "TD",
    code: "235",
  },
  {
    name: "Chile",
    iso2: "CL",
    code: "56",
  },
  {
    name: "China",
    iso2: "CN",
    code: "86",
  },
  {
    name: "Christmas Island",
    iso2: "CX",
    code: "61",
  },
  {
    name: "Clipperton Island",
    iso2: "Pf",
    code: "689",
  },
  {
    name: "Cocos (Keeling) Islands",
    iso2: "CC",
    code: "61",
  },
  {
    name: "Colombia",
    iso2: "CO",
    code: "57",
  },
  {
    name: "Comoros",
    iso2: "KM",
    code: "269",
  },
  {
    name: "Cook Islands",
    iso2: "CK",
    code: "682",
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    code: "506",
  },
  {
    name: "Cote d'Ivoire",
    iso2: "CI",
    code: "225",
  },
  {
    name: "Croatia",
    iso2: "HR",
    code: "385",
  },
  {
    name: "Cuba",
    iso2: "CU",
    code: "53",
  },
  {
    name: "Curacao",
    iso2: "CW",
    code: "599",
  },
  {
    name: "Cyprus",
    iso2: "CY",
    code: "357",
  },
  {
    name: "Czech Republic",
    iso2: "CZ",
    code: "420",
  },
  {
    name: "Democratic Republic Of Congo",
    iso2: "CD",
    code: "243",
  },
  {
    name: "Denmark",
    iso2: "DK",
    code: "45",
  },
  {
    name: "Diego Garcia",
    iso2: "DG",
    code: "246",
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    code: "253",
  },
  {
    name: "Dominica",
    iso2: "DM",
    code: "1 767",
  },
  {
    name: "Dominican Republic",
    iso2: "DO",
    code: "1 809",
  },
  {
    name: "East Timor",
    iso2: "TL",
    code: "670",
  },
  {
    name: "Ecuador",
    iso2: "EC",
    code: "593",
  },
  {
    name: "Egypt",
    iso2: "EG",
    code: "20",
  },
  {
    name: "El Salvador",
    iso2: "SV",
    code: "503",
  },
  {
    name: "Equatorial Guinea",
    iso2: "GQ",
    code: "240",
  },
  {
    name: "Eritrea",
    iso2: "ER",
    code: "291",
  },
  {
    name: "Estonia",
    iso2: "EE",
    code: "372",
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    code: "251",
  },
  {
    name: "European Union",
    iso2: "EU",
    code: "388",
  },
  {
    name: "Falkland Islands",
    iso2: "FK",
    code: "500",
  },
  {
    name: "Faroe Islands",
    iso2: "FO",
    code: "298",
  },
  {
    name: "Fiji",
    iso2: "FJ",
    code: "679",
  },
  {
    name: "Finland",
    iso2: "FI",
    code: "358",
  },
  {
    name: "France",
    iso2: "FR",
    code: "33",
  },
  {
    name: "France, Metropolitan",
    iso2: "FX",
    code: "241",
  },
  {
    name: "French Guiana",
    iso2: "GF",
    code: "44",
  },
  {
    name: "French Polynesia",
    iso2: "PF",
    code: "689",
  },
  {
    name: "French Southern and Antarctic Lands",
    iso2: "TF",
    code: "262",
  },
  {
    name: "Gabon",
    iso2: "GA",
    code: "44",
  },
  {
    name: "Gambia",
    iso2: "GM",
    code: "220",
  },
  {
    name: "Georgia",
    iso2: "GE",
    code: "594",
  },
  {
    name: "Germany",
    iso2: "DE",
    code: "49",
  },
  {
    name: "Ghana",
    iso2: "GH",
    code: "233",
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    code: "350",
  },
  {
    name: "Greece",
    iso2: "GR",
    code: "30",
  },
  {
    name: "Greenland",
    iso2: "GL",
    code: "299",
  },
  {
    name: "Grenada",
    iso2: "GD",
    code: "995",
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    code: "590",
  },
  {
    name: "Guam",
    iso2: "GU",
    code: "1 671",
  },
  {
    name: "Guatemala",
    iso2: "GT",
    code: "502",
  },
  {
    name: "Guernsey",
    iso2: "GG",
    code: "44",
  },
  {
    name: "Guinea",
    iso2: "GN",
    code: "224",
  },
  {
    name: "Guinea-Bissau",
    iso2: "GW",
    code: "245",
  },
  {
    name: "Guyana",
    iso2: "GY",
    code: "592",
  },
  {
    name: "Haiti",
    iso2: "HT",
    code: "509",
  },
  {
    name: "Heard Island and McDonald Islands",
    iso2: "HM",
    code: "",
  },
  {
    name: "Honduras",
    iso2: "HN",
    code: "504",
  },
  {
    name: "Hong Kong",
    iso2: "HK",
    code: "852",
  },
  {
    name: "Hungary",
    iso2: "HU",
    code: "36",
  },
  {
    name: "Iceland",
    iso2: "IS",
    code: "354",
  },
  {
    name: "India",
    iso2: "IN",
    code: "91",
  },
  {
    name: "Indonesia",
    iso2: "ID",
    code: "62",
  },
  {
    name: "Iran",
    iso2: "IR",
    code: "98",
  },
  {
    name: "Iraq",
    iso2: "IQ",
    code: "964",
  },
  {
    name: "Ireland",
    iso2: "IE",
    code: "353",
  },
  {
    name: "Isle Of Man",
    iso2: "IM",
    code: "44",
  },
  {
    name: "Israel",
    iso2: "IL",
    code: "972",
  },
  {
    name: "Italy",
    iso2: "IT",
    code: "39",
  },
  {
    name: "Jamaica",
    iso2: "JM",
    code: "1 876",
  },
  {
    name: "Japan",
    iso2: "JP",
    code: "81",
  },
  {
    name: "Jersey",
    iso2: "JE",
    code: "44",
  },
  {
    name: "Jordan",
    iso2: "JO",
    code: "962",
  },
  {
    name: "Kazakhstan",
    iso2: "KZ",
    code: "7",
  },
  {
    name: "Kenya",
    iso2: "KE",
    code: "254",
  },
  {
    name: "Kiribati",
    iso2: "KI",
    code: "686",
  },
  {
    name: "North Korea",
    iso2: "KP",
    code: "850",
  },
  {
    name: "South Korea",
    iso2: "KR",
    code: "82",
  },
  {
    name: "Kuwait",
    iso2: "KW",
    code: "965",
  },
  {
    name: "Kyrgyzstan",
    iso2: "KG",
    code: "996",
  },
  {
    name: "Laos",
    iso2: "LA",
    code: "856",
  },
  {
    name: "Latvia",
    iso2: "LV",
    code: "371",
  },
  {
    name: "Lebanon",
    iso2: "LB",
    code: "961",
  },
  {
    name: "Lesotho",
    iso2: "LS",
    code: "266",
  },
  {
    name: "Liberia",
    iso2: "LR",
    code: "231",
  },
  {
    name: "Libya",
    iso2: "LY",
    code: "218",
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    code: "423",
  },
  {
    name: "Lithuania",
    iso2: "LT",
    code: "370",
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    code: "352",
  },
  {
    name: "Macao",
    iso2: "MO",
    code: "853",
  },
  {
    name: "North Macedonia",
    iso2: "MK",
    code: "389",
  },
  {
    name: "Madagascar",
    iso2: "MG",
    code: "261",
  },
  {
    name: "Malawi",
    iso2: "MW",
    code: "265",
  },
  {
    name: "Malaysia",
    iso2: "MY",
    code: "60",
  },
  {
    name: "Maldives",
    iso2: "MV",
    code: "960",
  },
  {
    name: "Mali",
    iso2: "ML",
    code: "223",
  },
  {
    name: "Malta",
    iso2: "MT",
    code: "356",
  },
  {
    name: "Marshall Islands",
    iso2: "MH",
    code: "692",
  },
  {
    name: "Martinique",
    iso2: "MQ",
    code: "596",
  },
  {
    name: "Mauritania",
    iso2: "MR",
    code: "222",
  },
  {
    name: "Mauritius",
    iso2: "MU",
    code: "230",
  },
  {
    name: "Mayotte",
    iso2: "YT",
    code: "262",
  },
  {
    name: "Mexico",
    iso2: "MX",
    code: "52",
  },
  {
    name: "Federated States of Micronesia",
    iso2: "FM",
    code: "691",
  },
  {
    name: "Moldova",
    iso2: "MD",
    code: "373",
  },
  {
    name: "Monaco",
    iso2: "MC",
    code: "377",
  },
  {
    name: "Mongolia",
    iso2: "MN",
    code: "976",
  },
  {
    name: "Montenegro",
    iso2: "ME",
    code: "382",
  },
  {
    name: "Montserrat",
    iso2: "MS",
    code: "1 664",
  },
  {
    name: "Morocco",
    iso2: "MA",
    code: "212",
  },
  {
    name: "Mozambique",
    iso2: "MZ",
    code: "258",
  },
  {
    name: "Myanmar",
    iso2: "MM",
    code: "95",
  },
  {
    name: "Namibia",
    iso2: "NA",
    code: "264",
  },
  {
    name: "Nauru",
    iso2: "NR",
    code: "674",
  },
  {
    name: "Nepal",
    iso2: "NP",
    code: "977",
  },
  {
    name: "Netherlands",
    iso2: "NL",
    code: "31",
  },
  {
    name: "New Caledonia",
    iso2: "NC",
    code: "687",
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    code: "64",
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    code: "505",
  },
  {
    name: "Niger",
    iso2: "NE",
    code: "227",
  },
  {
    name: "Nigeria",
    iso2: "NG",
    code: "234",
  },
  {
    name: "Niue",
    iso2: "NU",
    code: "683",
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    code: "672",
  },
  {
    name: "Northern Mariana Islands",
    iso2: "MP",
    code: "1 670",
  },
  {
    name: "Norway",
    iso2: "NO",
    code: "47",
  },
  {
    name: "Oman",
    iso2: "OM",
    code: "968",
  },
  {
    name: "Pakistan",
    iso2: "PK",
    code: "92",
  },
  {
    name: "Palau",
    iso2: "PW",
    code: "680",
  },
  {
    name: "Palestinian",
    iso2: "PS",
    code: "970",
  },
  {
    name: "Panama",
    iso2: "PA",
    code: "507",
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    code: "675",
  },
  {
    name: "Paraguay",
    iso2: "PY",
    code: "595",
  },
  {
    name: "Peru",
    iso2: "PE",
    code: "51",
  },
  {
    name: "Philippines",
    iso2: "PH",
    code: "63",
  },
  {
    name: "Pitcairn",
    iso2: "PN",
    code: "",
  },
  {
    name: "Poland",
    iso2: "PL",
    code: "48",
  },
  {
    name: "Portugal",
    iso2: "PT",
    code: "351",
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    code: "1 787",
  },
  {
    name: "Qatar",
    iso2: "QA",
    code: "974",
  },
  {
    name: "Congo, Republic of the",
    iso2: "CG",
    code: "242",
  },
  {
    name: "Reunion",
    iso2: "RE",
    code: "262",
  },
  {
    name: "Romania",
    iso2: "RO",
    code: "40",
  },
  {
    name: "Russian Federation",
    iso2: "RU",
    code: "7",
  },
  {
    name: "Rwanda",
    iso2: "RW",
    code: "250",
  },
  {
    name: "Saint Barthelemy",
    iso2: "BL",
    code: "590",
  },
  {
    name: "Saint Helena",
    iso2: "SH",
    code: "290",
  },
  {
    name: "Saint Kitts and Nevis",
    iso2: "KN",
    code: "1 869",
  },
  {
    name: "Saint Lucia",
    iso2: "LC",
    code: "1 758",
  },
  {
    name: "Saint Martin",
    iso2: "MF",
    code: "590",
  },
  {
    name: "Saint Pierre and Miquelon",
    iso2: "PM",
    code: "508",
  },
  {
    name: "Saint Vincent and The Grenadines",
    iso2: "VC",
    code: "1 784",
  },
  {
    name: "Samoa",
    iso2: "WS",
    code: "685",
  },
  {
    name: "San Marino",
    iso2: "SM",
    code: "378",
  },
  {
    name: "Sao Tome and Principe",
    iso2: "ST",
    code: "239",
  },
  {
    name: "Saudi Arabia",
    iso2: "SA",
    code: "966",
  },
  {
    name: "Senegal",
    iso2: "SN",
    code: "221",
  },
  {
    name: "Serbia",
    iso2: "RS",
    code: "381",
  },
  {
    name: "Seychelles",
    iso2: "SC",
    code: "248",
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    code: "232",
  },
  {
    name: "Singapore",
    iso2: "SG",
    code: "65",
  },
  {
    name: "Sint Maarten",
    iso2: "SX",
    code: "599",
  },
  {
    name: "Slovakia",
    iso2: "SK",
    code: "421",
  },
  {
    name: "Slovenia",
    iso2: "SI",
    code: "386",
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    code: "677",
  },
  {
    name: "Somalia",
    iso2: "SO",
    code: "252",
  },
  {
    name: "South Africa",
    iso2: "ZA",
    code: "27",
  },
  {
    name: "South Georgia Island",
    iso2: "GS",
    code: "",
  },
  {
    name: "Spain",
    iso2: "ES",
    code: "34",
  },
  {
    name: "Sri Lanka",
    iso2: "LK",
    code: "94",
  },
  {
    name: "Sudan",
    iso2: "SD",
    code: "249",
  },
  {
    name: "Suriname",
    iso2: "SR",
    code: "597",
  },
  {
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    code: "47",
  },
  {
    name: "Swaziland",
    iso2: "SZ",
    code: "268",
  },
  {
    name: "Sweden",
    iso2: "SE",
    code: "46",
  },
  {
    name: "Switzerland",
    iso2: "CH",
    code: "41",
  },
  {
    name: "Syria",
    iso2: "SY",
    code: "963",
  },
  {
    name: "Taiwan",
    iso2: "TW",
    code: "886",
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    code: "992",
  },
  {
    name: "Tanzania",
    iso2: "TZ",
    code: "255",
  },
  {
    name: "Thailand",
    iso2: "TH",
    code: "66",
  },
  {
    name: "Togo",
    iso2: "TG",
    code: "228",
  },
  {
    name: "Tokelau",
    iso2: "TK",
    code: "690",
  },
  {
    name: "Tonga",
    iso2: "TO",
    code: "676",
  },
  {
    name: "Trinidad and Tobago",
    iso2: "TT",
    code: "1 868",
  },
  {
    name: "Tristan de Cunha",
    iso2: "TA",
    code: "290",
  },
  {
    name: "Tunisia",
    iso2: "TN",
    code: "216",
  },
  {
    name: "Turkey",
    iso2: "TR",
    code: "90",
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    code: "993",
  },
  {
    name: "Turks and Caicos Islands",
    iso2: "TC",
    code: "1 649",
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    code: "688",
  },
  {
    name: "Uganda",
    iso2: "UG",
    code: "256",
  },
  {
    name: "Ukraine",
    iso2: "UA",
    code: "380",
  },
  {
    name: "United Arab Emirates",
    iso2: "AE",
    code: "971",
  },
  {
    name: "United Kingdom",
    iso2: "GB",
    code: "44",
  },
  {
    name: "United States",
    iso2: "US",
    code: "1",
  },
  {
    name: "United States Minor Outlying Islands",
    iso2: "UM",
    code: "",
  },
  {
    name: "Uruguay",
    iso2: "UY",
    code: "598",
  },
  {
    name: "Uzbekistan",
    iso2: "UZ",
    code: "998",
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    code: "678",
  },
  {
    name: "Vatican City State",
    iso2: "VA",
    code: "379",
  },
  {
    name: "Venezuela",
    iso2: "VE",
    code: "58",
  },
  {
    name: "Vietnam",
    iso2: "VN",
    code: "84",
  },
  {
    name: "Virgin Islands (British)",
    iso2: "VG",
    code: "1 284",
  },
  {
    name: "Virgin Islands (US)",
    iso2: "VI",
    code: "1 340",
  },
  {
    name: "Wallis and Futuna",
    iso2: "WF",
    code: "681",
  },
  {
    name: "Western Sahara",
    iso2: "EH",
    code: "212",
  },
  {
    name: "Yemen",
    iso2: "YE",
    code: "967",
  },
  {
    name: "Zambia",
    iso2: "ZM",
    code: "260",
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    code: "263",
  },
];

export const country_code_map = {
  afghanistan: { name: "Afghanistan", iso2: "AF", code: "93" },
  albania: { name: "Albania", iso2: "AL", code: "355" },
  algeria: { name: "Algeria", iso2: "DZ", code: "213" },
  "american samoa": { name: "American Samoa", iso2: "AS", code: "1684" },
  andorra: { name: "Andorra", iso2: "AD", code: "376" },
  angola: { name: "Angola", iso2: "AO", code: "244" },
  anguilla: { name: "Anguilla", iso2: "AI", code: "1264" },
  antarctica: { name: "Antarctica", iso2: "AQ", code: "672" },
  "antigua and barbuda": { name: "Antigua and Barbuda", iso2: "AG", code: "1268" },
  argentina: { name: "Argentina", iso2: "AR", code: "54" },
  armenia: { name: "Armenia", iso2: "AM", code: "374" },
  aruba: { name: "Aruba", iso2: "AW", code: "297" },
  "ascension island": { name: "Ascension Island", iso2: "AC", code: "247" },
  australia: { name: "Australia", iso2: "AU", code: "61" },
  austria: { name: "Austria", iso2: "AT", code: "43" },
  azerbaijan: { name: "Azerbaijan", iso2: "AZ", code: "994" },
  bahamas: { name: "Bahamas", iso2: "BS", code: "1242" },
  bahrain: { name: "Bahrain", iso2: "BH", code: "973" },
  bangladesh: { name: "Bangladesh", iso2: "BD", code: "880" },
  barbados: { name: "Barbados", iso2: "BB", code: "1246" },
  belarus: { name: "Belarus", iso2: "BY", code: "375" },
  belgium: { name: "Belgium", iso2: "BE", code: "32" },
  belize: { name: "Belize", iso2: "BZ", code: "501" },
  benin: { name: "Benin", iso2: "BJ", code: "229" },
  bermuda: { name: "Bermuda", iso2: "BM", code: "1441" },
  bhutan: { name: "Bhutan", iso2: "BT", code: "975" },
  "bolivia, plurinational state of": { name: "Bolivia, Plurinational State Of", iso2: "BO", code: "591" },
  "bonaire, saint eustatius and saba": {
    name: "Bonaire, Saint Eustatius And Saba",
    iso2: "BQ",
    code: "599",
  },
  "bosnia & herzegovina": { name: "Bosnia & Herzegovina", iso2: "BA", code: "387" },
  botswana: { name: "Botswana", iso2: "BW", code: "267" },
  "bouvet island": { name: "Bouvet Island", iso2: "BV", code: "" },
  brazil: { name: "Brazil", iso2: "BR", code: "55" },
  "british indian ocean territory": { name: "British Indian Ocean Territory", iso2: "IO", code: "246" },
  "brunei darussalam": { name: "Brunei Darussalam", iso2: "BN", code: "673" },
  bulgaria: { name: "Bulgaria", iso2: "BG", code: "359" },
  "burkina faso": { name: "Burkina Faso", iso2: "BF", code: "226" },
  burundi: { name: "Burundi", iso2: "BI", code: "257" },
  cambodia: { name: "Cambodia", iso2: "KH", code: "855" },
  cameroon: { name: "Cameroon", iso2: "CM", code: "237" },
  canada: { name: "Canada", iso2: "CA", code: "1" },
  "cape verde": { name: "Cape Verde", iso2: "CV", code: "238" },
  "cayman islands": { name: "Cayman Islands", iso2: "KY", code: "1345" },
  "central african republic": { name: "Central African Republic", iso2: "CF", code: "236" },
  "ceuta, mulilla": { name: "Ceuta, Mulilla", iso2: "EA", code: "" },
  chad: { name: "Chad", iso2: "TD", code: "235" },
  chile: { name: "Chile", iso2: "CL", code: "56" },
  china: { name: "China", iso2: "CN", code: "86" },
  "christmas island": { name: "Christmas Island", iso2: "CX", code: "61" },
  "clipperton island": { name: "Clipperton Island", iso2: "Pf", code: "689" },
  "cocos (keeling) islands": { name: "Cocos (Keeling) Islands", iso2: "CC", code: "61" },
  colombia: { name: "Colombia", iso2: "CO", code: "57" },
  comoros: { name: "Comoros", iso2: "KM", code: "269" },
  "cook islands": { name: "Cook Islands", iso2: "CK", code: "682" },
  "costa rica": { name: "Costa Rica", iso2: "CR", code: "506" },
  "cote d'ivoire": { name: "Cote d'Ivoire", iso2: "CI", code: "225" },
  croatia: { name: "Croatia", iso2: "HR", code: "385" },
  cuba: { name: "Cuba", iso2: "CU", code: "53" },
  curacao: { name: "Curacao", iso2: "CW", code: "599" },
  cyprus: { name: "Cyprus", iso2: "CY", code: "357" },
  "czech republic": { name: "Czech Republic", iso2: "CZ", code: "420" },
  "democratic republic of congo": { name: "Democratic Republic Of Congo", iso2: "CD", code: "243" },
  denmark: { name: "Denmark", iso2: "DK", code: "45" },
  "diego garcia": { name: "Diego Garcia", iso2: "DG", code: "246" },
  djibouti: { name: "Djibouti", iso2: "DJ", code: "253" },
  dominica: { name: "Dominica", iso2: "DM", code: "1767" },
  "dominican republic": { name: "Dominican Republic", iso2: "DO", code: "1809" },
  "east timor": { name: "East Timor", iso2: "TL", code: "670" },
  ecuador: { name: "Ecuador", iso2: "EC", code: "593" },
  egypt: { name: "Egypt", iso2: "EG", code: "20" },
  "el salvador": { name: "El Salvador", iso2: "SV", code: "503" },
  "equatorial guinea": { name: "Equatorial Guinea", iso2: "GQ", code: "240" },
  eritrea: { name: "Eritrea", iso2: "ER", code: "291" },
  estonia: { name: "Estonia", iso2: "EE", code: "372" },
  ethiopia: { name: "Ethiopia", iso2: "ET", code: "251" },
  "european union": { name: "European Union", iso2: "EU", code: "388" },
  "falkland islands": { name: "Falkland Islands", iso2: "FK", code: "500" },
  "faroe islands": { name: "Faroe Islands", iso2: "FO", code: "298" },
  fiji: { name: "Fiji", iso2: "FJ", code: "679" },
  finland: { name: "Finland", iso2: "FI", code: "358" },
  france: { name: "France", iso2: "FR", code: "33" },
  "france, metropolitan": { name: "France, Metropolitan", iso2: "FX", code: "241" },
  "french guiana": { name: "French Guiana", iso2: "GF", code: "44" },
  "french polynesia": { name: "French Polynesia", iso2: "PF", code: "689" },
  "french southern and antarctic lands": {
    name: "French Southern and Antarctic Lands",
    iso2: "TF",
    code: "262",
  },
  gabon: { name: "Gabon", iso2: "GA", code: "44" },
  gambia: { name: "Gambia", iso2: "GM", code: "220" },
  georgia: { name: "Georgia", iso2: "GE", code: "594" },
  germany: { name: "Germany", iso2: "DE", code: "49" },
  ghana: { name: "Ghana", iso2: "GH", code: "233" },
  gibraltar: { name: "Gibraltar", iso2: "GI", code: "350" },
  greece: { name: "Greece", iso2: "GR", code: "30" },
  greenland: { name: "Greenland", iso2: "GL", code: "299" },
  grenada: { name: "Grenada", iso2: "GD", code: "995" },
  guadeloupe: { name: "Guadeloupe", iso2: "GP", code: "590" },
  guam: { name: "Guam", iso2: "GU", code: "1671" },
  guatemala: { name: "Guatemala", iso2: "GT", code: "502" },
  guernsey: { name: "Guernsey", iso2: "GG", code: "44" },
  guinea: { name: "Guinea", iso2: "GN", code: "224" },
  "guinea-bissau": { name: "Guinea-Bissau", iso2: "GW", code: "245" },
  guyana: { name: "Guyana", iso2: "GY", code: "592" },
  haiti: { name: "Haiti", iso2: "HT", code: "509" },
  "heard island and mcdonald islands": { name: "Heard Island and McDonald Islands", iso2: "HM", code: "" },
  honduras: { name: "Honduras", iso2: "HN", code: "504" },
  "hong kong": { name: "Hong Kong", iso2: "HK", code: "852" },
  hungary: { name: "Hungary", iso2: "HU", code: "36" },
  iceland: { name: "Iceland", iso2: "IS", code: "354" },
  india: { name: "India", iso2: "IN", code: "91" },
  indonesia: { name: "Indonesia", iso2: "ID", code: "62" },
  iran: { name: "Iran", iso2: "IR", code: "98" },
  iraq: { name: "Iraq", iso2: "IQ", code: "964" },
  ireland: { name: "Ireland", iso2: "IE", code: "353" },
  "isle of man": { name: "Isle Of Man", iso2: "IM", code: "44" },
  israel: { name: "Israel", iso2: "IL", code: "972" },
  italy: { name: "Italy", iso2: "IT", code: "39" },
  jamaica: { name: "Jamaica", iso2: "JM", code: "1876" },
  japan: { name: "Japan", iso2: "JP", code: "81" },
  jersey: { name: "Jersey", iso2: "JE", code: "44" },
  jordan: { name: "Jordan", iso2: "JO", code: "962" },
  kazakhstan: { name: "Kazakhstan", iso2: "KZ", code: "7" },
  kenya: { name: "Kenya", iso2: "KE", code: "254" },
  kiribati: { name: "Kiribati", iso2: "KI", code: "686" },
  "north korea": { name: "North Korea", iso2: "KP", code: "850" },
  "south korea": { name: "South Korea", iso2: "KR", code: "82" },
  kuwait: { name: "Kuwait", iso2: "KW", code: "965" },
  kyrgyzstan: { name: "Kyrgyzstan", iso2: "KG", code: "996" },
  laos: { name: "Laos", iso2: "LA", code: "856" },
  latvia: { name: "Latvia", iso2: "LV", code: "371" },
  lebanon: { name: "Lebanon", iso2: "LB", code: "961" },
  lesotho: { name: "Lesotho", iso2: "LS", code: "266" },
  liberia: { name: "Liberia", iso2: "LR", code: "231" },
  libya: { name: "Libya", iso2: "LY", code: "218" },
  liechtenstein: { name: "Liechtenstein", iso2: "LI", code: "423" },
  lithuania: { name: "Lithuania", iso2: "LT", code: "370" },
  luxembourg: { name: "Luxembourg", iso2: "LU", code: "352" },
  macao: { name: "Macao", iso2: "MO", code: "853" },
  "north macedonia": { name: "North Macedonia", iso2: "MK", code: "389" },
  madagascar: { name: "Madagascar", iso2: "MG", code: "261" },
  malawi: { name: "Malawi", iso2: "MW", code: "265" },
  malaysia: { name: "Malaysia", iso2: "MY", code: "60" },
  maldives: { name: "Maldives", iso2: "MV", code: "960" },
  mali: { name: "Mali", iso2: "ML", code: "223" },
  malta: { name: "Malta", iso2: "MT", code: "356" },
  "marshall islands": { name: "Marshall Islands", iso2: "MH", code: "692" },
  martinique: { name: "Martinique", iso2: "MQ", code: "596" },
  mauritania: { name: "Mauritania", iso2: "MR", code: "222" },
  mauritius: { name: "Mauritius", iso2: "MU", code: "230" },
  mayotte: { name: "Mayotte", iso2: "YT", code: "262" },
  mexico: { name: "Mexico", iso2: "MX", code: "52" },
  "federated states of micronesia": { name: "Federated States of Micronesia", iso2: "FM", code: "691" },
  moldova: { name: "Moldova", iso2: "MD", code: "373" },
  monaco: { name: "Monaco", iso2: "MC", code: "377" },
  mongolia: { name: "Mongolia", iso2: "MN", code: "976" },
  montenegro: { name: "Montenegro", iso2: "ME", code: "382" },
  montserrat: { name: "Montserrat", iso2: "MS", code: "1664" },
  morocco: { name: "Morocco", iso2: "MA", code: "212" },
  mozambique: { name: "Mozambique", iso2: "MZ", code: "258" },
  myanmar: { name: "Myanmar", iso2: "MM", code: "95" },
  namibia: { name: "Namibia", iso2: "NA", code: "264" },
  nauru: { name: "Nauru", iso2: "NR", code: "674" },
  nepal: { name: "Nepal", iso2: "NP", code: "977" },
  netherlands: { name: "Netherlands", iso2: "NL", code: "31" },
  "new caledonia": { name: "New Caledonia", iso2: "NC", code: "687" },
  "new zealand": { name: "New Zealand", iso2: "NZ", code: "64" },
  nicaragua: { name: "Nicaragua", iso2: "NI", code: "505" },
  niger: { name: "Niger", iso2: "NE", code: "227" },
  nigeria: { name: "Nigeria", iso2: "NG", code: "234" },
  niue: { name: "Niue", iso2: "NU", code: "683" },
  "norfolk island": { name: "Norfolk Island", iso2: "NF", code: "672" },
  "northern mariana islands": { name: "Northern Mariana Islands", iso2: "MP", code: "1670" },
  norway: { name: "Norway", iso2: "NO", code: "47" },
  oman: { name: "Oman", iso2: "OM", code: "968" },
  pakistan: { name: "Pakistan", iso2: "PK", code: "92" },
  palau: { name: "Palau", iso2: "PW", code: "680" },
  palestinian: { name: "Palestinian", iso2: "PS", code: "970" },
  panama: { name: "Panama", iso2: "PA", code: "507" },
  "papua new guinea": { name: "Papua New Guinea", iso2: "PG", code: "675" },
  paraguay: { name: "Paraguay", iso2: "PY", code: "595" },
  peru: { name: "Peru", iso2: "PE", code: "51" },
  philippines: { name: "Philippines", iso2: "PH", code: "63" },
  pitcairn: { name: "Pitcairn", iso2: "PN", code: "" },
  poland: { name: "Poland", iso2: "PL", code: "48" },
  portugal: { name: "Portugal", iso2: "PT", code: "351" },
  "puerto rico": { name: "Puerto Rico", iso2: "PR", code: "1787" },
  qatar: { name: "Qatar", iso2: "QA", code: "974" },
  "congo, republic of the": { name: "Congo, Republic of the", iso2: "CG", code: "242" },
  reunion: { name: "Reunion", iso2: "RE", code: "262" },
  romania: { name: "Romania", iso2: "RO", code: "40" },
  "russian federation": { name: "Russian Federation", iso2: "RU", code: "7" },
  rwanda: { name: "Rwanda", iso2: "RW", code: "250" },
  "saint barthelemy": { name: "Saint Barthelemy", iso2: "BL", code: "590" },
  "saint helena": { name: "Saint Helena", iso2: "SH", code: "290" },
  "saint kitts and nevis": { name: "Saint Kitts and Nevis", iso2: "KN", code: "1869" },
  "saint lucia": { name: "Saint Lucia", iso2: "LC", code: "1758" },
  "saint martin": { name: "Saint Martin", iso2: "MF", code: "590" },
  "saint pierre and miquelon": { name: "Saint Pierre and Miquelon", iso2: "PM", code: "508" },
  "saint vincent and the grenadines": {
    name: "Saint Vincent and The Grenadines",
    iso2: "VC",
    code: "1784",
  },
  samoa: { name: "Samoa", iso2: "WS", code: "685" },
  "san marino": { name: "San Marino", iso2: "SM", code: "378" },
  "sao tome and principe": { name: "Sao Tome and Principe", iso2: "ST", code: "239" },
  "saudi arabia": { name: "Saudi Arabia", iso2: "SA", code: "966" },
  senegal: { name: "Senegal", iso2: "SN", code: "221" },
  serbia: { name: "Serbia", iso2: "RS", code: "381" },
  seychelles: { name: "Seychelles", iso2: "SC", code: "248" },
  "sierra leone": { name: "Sierra Leone", iso2: "SL", code: "232" },
  singapore: { name: "Singapore", iso2: "SG", code: "65" },
  "sint maarten": { name: "Sint Maarten", iso2: "SX", code: "599" },
  slovakia: { name: "Slovakia", iso2: "SK", code: "421" },
  slovenia: { name: "Slovenia", iso2: "SI", code: "386" },
  "solomon islands": { name: "Solomon Islands", iso2: "SB", code: "677" },
  somalia: { name: "Somalia", iso2: "SO", code: "252" },
  "south africa": { name: "South Africa", iso2: "ZA", code: "27" },
  "south georgia island": { name: "South Georgia Island", iso2: "GS", code: "" },
  spain: { name: "Spain", iso2: "ES", code: "34" },
  "sri lanka": { name: "Sri Lanka", iso2: "LK", code: "94" },
  sudan: { name: "Sudan", iso2: "SD", code: "249" },
  suriname: { name: "Suriname", iso2: "SR", code: "597" },
  "svalbard and jan mayen": { name: "Svalbard and Jan Mayen", iso2: "SJ", code: "47" },
  swaziland: { name: "Swaziland", iso2: "SZ", code: "268" },
  sweden: { name: "Sweden", iso2: "SE", code: "46" },
  switzerland: { name: "Switzerland", iso2: "CH", code: "41" },
  syria: { name: "Syria", iso2: "SY", code: "963" },
  taiwan: { name: "Taiwan", iso2: "TW", code: "886" },
  tajikistan: { name: "Tajikistan", iso2: "TJ", code: "992" },
  tanzania: { name: "Tanzania", iso2: "TZ", code: "255" },
  thailand: { name: "Thailand", iso2: "TH", code: "66" },
  togo: { name: "Togo", iso2: "TG", code: "228" },
  tokelau: { name: "Tokelau", iso2: "TK", code: "690" },
  tonga: { name: "Tonga", iso2: "TO", code: "676" },
  "trinidad and tobago": { name: "Trinidad and Tobago", iso2: "TT", code: "1868" },
  "tristan de cunha": { name: "Tristan de Cunha", iso2: "TA", code: "290" },
  tunisia: { name: "Tunisia", iso2: "TN", code: "216" },
  turkey: { name: "Turkey", iso2: "TR", code: "90" },
  turkmenistan: { name: "Turkmenistan", iso2: "TM", code: "993" },
  "turks and caicos islands": { name: "Turks and Caicos Islands", iso2: "TC", code: "1649" },
  tuvalu: { name: "Tuvalu", iso2: "TV", code: "688" },
  uganda: { name: "Uganda", iso2: "UG", code: "256" },
  ukraine: { name: "Ukraine", iso2: "UA", code: "380" },
  "united arab emirates": { name: "United Arab Emirates", iso2: "AE", code: "971" },
  "united kingdom": { name: "United Kingdom", iso2: "GB", code: "44" },
  "united states": { name: "United States", iso2: "US", code: "1" },
  "united states minor outlying islands": {
    name: "United States Minor Outlying Islands",
    iso2: "UM",
    code: "",
  },
  uruguay: { name: "Uruguay", iso2: "UY", code: "598" },
  uzbekistan: { name: "Uzbekistan", iso2: "UZ", code: "998" },
  vanuatu: { name: "Vanuatu", iso2: "VU", code: "678" },
  "vatican city state": { name: "Vatican City State", iso2: "VA", code: "379" },
  venezuela: { name: "Venezuela", iso2: "VE", code: "58" },
  vietnam: { name: "Vietnam", iso2: "VN", code: "84" },
  "virgin islands (british)": { name: "Virgin Islands (British)", iso2: "VG", code: "1284" },
  "virgin islands (us)": { name: "Virgin Islands (US)", iso2: "VI", code: "1340" },
  "wallis and futuna": { name: "Wallis and Futuna", iso2: "WF", code: "681" },
  "western sahara": { name: "Western Sahara", iso2: "EH", code: "212" },
  yemen: { name: "Yemen", iso2: "YE", code: "967" },
  zambia: { name: "Zambia", iso2: "ZM", code: "260" },
  zimbabwe: { name: "Zimbabwe", iso2: "ZW", code: "263" },
};
export default {
  country_code,
};
