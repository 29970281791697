import { getFieldUpdateByID, createFieldUpdate, editFieldUpdate } from "../types";

export const getFieldUpdateByIdRequest = (payload, cb) => ({
  type: getFieldUpdateByID.GET_FIELD_UPDATE_BY_ID_ACTION_REQUEST,
  payload,
  cb,
});
export const getFieldUpdateByIdSuccess = (payload) => ({
  type: getFieldUpdateByID.GET_FIELD_UPDATE_BY_ID_ACTION_SUCCESS,
  payload,
});
export const getFieldUpdateByIdError = (payload) => ({
  type: getFieldUpdateByID.GET_FIELD_UPDATE_BY_ID_ACTION_ERROR,
  payload,
});

export const editFieldUpdateRequest = (payload, cb) => ({
  type: editFieldUpdate.EDIT_FIELD_UPDATE_ACTION_REQUEST,
  payload,
  cb,
});
export const editFieldUpdateSuccess = (payload) => ({
  type: editFieldUpdate.EDIT_FIELD_UPDATE_ACTION_SUCCESS,
  payload,
});
export const editFieldUpdateError = (payload) => ({
  type: editFieldUpdate.EDIT_FIELD_UPDATE_ACTION_ERROR,
  payload,
});

export const createFieldUpdateRequest = (payload, cb) => ({
  type: createFieldUpdate.CREATE_FIELD_UPDATE_ACTION_REQUEST,
  payload,
  cb,
});
export const createFieldUpdateSuccess = (payload) => ({
  type: createFieldUpdate.CREATE_FIELD_UPDATE_ACTION_SUCCESS,
  payload,
});
export const createFieldUpdateError = (payload) => ({
  type: createFieldUpdate.CREATE_FIELD_UPDATE_ACTION_ERROR,
  payload,
});
