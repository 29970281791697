import { getAddFormFields, getUsageRecordReports, getCustomField, getDealListFields, getMarketingDataFields } from "../../types/admin-settings.types";

const initState = {
  addForm: {
    list: [],
    loading: false,
  },
  error: {
    err: false,
    errMsg: "",
  },
  usageRecord: {
    data: {},
    loading: false,
  },
  getCustomField: {
    list: [],
    loading: false,
  },
  dealList: {
    loading: true,
    list: [],
    count: 0,
    cellLoading: false,
  },
  fields: {
    list: [],
    loading: false,
    idToIndex: {},
  },
};

export default function customFieldsSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getAddFormFields.GET_ADD_FORM_FIELDS_REQUEST:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: true,
        },
      };
    case getAddFormFields.GET_ADD_FORM_FIELDS_SUCCESS:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: false,
          list: action.payload.data,
        },
      };
    case getAddFormFields.GET_ADD_FORM_FIELDS_ERROR:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: false,
          error: action.payload.message,
        },
      };

    case getUsageRecordReports.GET_USAGE_RECORD_REPORTS_REQUEST:
      return {
        ...state,
        usageRecord: { ...state.usageRecord, loading: true },
      };
    case getUsageRecordReports.GET_USAGE_RECORD_REPORTS_SUCCESS:
      return {
        ...state,
        usageRecord: { loading: false, data: action.payload },
      };
    case getUsageRecordReports.GET_USAGE_RECORD_REPORTS_ERROR:
      return {
        ...state,
        usageRecord: { ...state.usageRecord, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case getCustomField.GET_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        getCustomField: { ...state.getCustomField, loading: true },
      };
    case getCustomField.GET_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        getCustomField: { loading: false, list: action.payload.data },
      };
    case getCustomField.GET_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        getCustomField: { ...state.getCustomField, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case getDealListFields.GET_DEAL_LIST_FIELDS_REQUEST:
      return {
        ...state,
        dealList: { ...state.dealList, loading: true },
      };
    case getDealListFields.GET_DEAL_LIST_FIELDS_SUCCESS:
      return {
        ...state,
        dealList: { loading: false, list: action.payload.data, count: action.payload.count, cellLoading: false },
      };
    case getDealListFields.GET_DEAL_LIST_FIELDS_ERROR:
      return {
        ...state,
        dealList: { ...state.dealList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    case getMarketingDataFields.GET_MARKETING_DATA_FIELDS_REQUEST:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true,
        },
      };
    case getMarketingDataFields.GET_MARKETING_DATA_FIELDS_SUCCESS:
      const tempObj = {};
      for (let i = 0; i < action.payload.data.length; i++) {
        tempObj[action.payload.data[i]["_id"]] = i;
      }
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          list: action.payload.data,
          idToIndex: tempObj,
        },
      };
    case getMarketingDataFields.GET_MARKETING_DATA_FIELDS_ERROR:
      return {
        ...state,
        fields: { ...state.fields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    default:
      return state;
  }
}
