import React, { useState } from "react";
import { CustomScrollableModal } from "../modal";
import { Col } from "react-bootstrap";
import CustomDisplay from "../table/data";
import { dispatchSnackbarError } from "../../utils/toaster";
import _ from "lodash";

export function MergeModal(props) {
  const [merged, setMerged] = useState({});

  const handleSave = () => {
    for (var i = 0; i < props.fields.length; i++) {
      if (
        props.fields[i].isEditable &&
        props.fields[i].isRequired &&
        !(Object.keys(merged).includes(props.fields[i].apiKeyName) || Object.keys(merged).includes("customFields." + props.fields[i].apiKeyName)) &&
        (Object.keys(props.items[0]).includes(props.fields[i].apiKeyName) || Object.keys(props.items[1]).includes(props.fields[i].apiKeyName))
      ) {
        dispatchSnackbarError(props.fields[i].fieldName + " is Required");
        return;
      }
    }
    props.handleMerge({ mergedIds: [props.items[0]["_id"], props.items[1]["_id"]], mergedData: merged });
    props.setShow(false);
    setMerged({});
  };

  const modalProps = {
    showButtons: true,
    title: `Merge ${props.type}`,
    show: props.show,
    buttonText: "Merge",
    size: "lg",
    handleClose: () => {
      props.setShow(false);
      setMerged({});
    },
    handleAccept: () => handleSave(),
  };

  const handleSelect = (field, item) => {
    var temp = merged;
    if (field.fieldGroup === "CUSTOM") {
      if (item.customFields && merged[`customFields.${field.apiKeyName}`] === item.customFields[field.apiKeyName]) {
        delete temp[`customFields.${field.apiKeyName}`];
        setMerged({ ...temp });
      } else setMerged({ ...merged, [`customFields.${field.apiKeyName}`]: item.customFields[field.apiKeyName] });
    } else {
      if (merged[field.apiKeyName] === item[field.apiKeyName]) {
        delete temp[field.apiKeyName];
        setMerged({ ...temp });
      } else setMerged({ ...merged, [field.apiKeyName]: item[field.apiKeyName] });
    }
  };

  const displayData = (index, field, item, type = 1) => {
    const bgColor =
      field.fieldGroup === "CUSTOM"
        ? item.customFields && item.customFields[field.apiKeyName] === merged[`customFields.${field.apiKeyName}`]
          ? true
          : false
        : item[field.apiKeyName] === merged[field.apiKeyName]
          ? true
          : false;
    const data =
      type === 1
        ? field.fieldGroup === "CUSTOM" && item.customFields
          ? item.customFields[field.apiKeyName]
          : item[field.apiKeyName]
        : field.fieldGroup === "CUSTOM"
          ? item[`customFields.${field.apiKeyName}`]
          : item[field.apiKeyName];
    return (
      <div
        key={index}
        className={`${type === 1 && bgColor ? "bg-ecf8ff text-009ef7 border-color-009ef7" : ""} w-95 btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column my-5 text-left`}
        onClick={() => handleSelect(field, item)}
      >
        <span className="text-dark d-block mb-2 fw-500">{field.fieldName}</span>
        <div className="overflow-wrap-anywhere">
          <CustomDisplay field={field} type={field.fieldInputType} data={data} sidePanel={true} page={props.type} pageSub={props.pageSub} hideAIWFGenaration={true} />
        </div>
      </div>
    );
  };

  const getRenderData = (field, item, index) => {
    if (!field.isEditable) return null;
    if (_.isEmpty(item)) return null;
    else if (field.fieldGroup === "CUSTOM" && item && item.customFields && typeof item.customFields[field.apiKeyName] === "undefined") {
      return null;
    } else if (field.fieldGroup === "SYSTEM" && item && typeof item[field.apiKeyName] === "undefined") {
      return null;
    } else if (field.fieldGroup === "CUSTOM" && item && item.customFields && item.customFields[field.apiKeyName]) {
      if (typeof item.customFields[field.apiKeyName] === "number" || !_.isEmpty(item.customFields[field.apiKeyName])) {
        return displayData(index, field, item);
      } else return null;
    } else if (field.fieldGroup === "SYSTEM" && item && item[field.apiKeyName]) {
      if (typeof item[field.apiKeyName] === "number" || !_.isEmpty(item[field.apiKeyName])) {
        return displayData(index, field, item);
      } else return null;
    }
    return null;
  };

  return (
    <CustomScrollableModal modalProps={modalProps}>
      <div className="d-flex w-100">
        <Col xs={4} className="px-5">
          <h5>{props.typeSub} 1</h5>
          {props.items[0] ? props.fields.map((field, index) => getRenderData(field, props.items[0], index)) : null}
        </Col>
        <Col xs={4} className="px-5">
          <h5>{props.typeSub} 2</h5>
          {props.items[1] ? props.fields.map((field, index) => getRenderData(field, props.items[1], index)) : null}
        </Col>
        <Col xs={4} className="px-5">
          <h5>Merged {props.typeSub}</h5>
          {merged
            ? props.fields.map((field, index) => (Object.keys(merged).includes(field.apiKeyName) || Object.keys(merged).includes(`customFields.${field.apiKeyName}`) ? displayData(index, field, merged, 0) : null))
            : null}
        </Col>
      </div>
    </CustomScrollableModal>
  );
}
