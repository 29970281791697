import React, { useState, useEffect } from "react";
import CustomAction from "../../../../components/ActionColumn/index";
import { Modal } from "react-bootstrap";
// import Pagination from '../../../../components/normal-table/pagination';
import { Button, Dropdown, Spinner } from "react-bootstrap";

import { DeleteModal } from "../../../../components/modal/index";
import FormInput from "../../../../components/Form-Field/index";
import { useDispatch, useSelector } from "react-redux";
import * as adminSettingsActions from "../../actions/admin-settings.actions";

import CustomPagination from "../../../../components/pagination/custom-pagination";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import { CirclePicker } from "react-color";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

const modulesNamesArray = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "lead",
    label: "Lead & People",
  },
  {
    value: "deal",
    label: "Deals",
  },
  // {
  //     value: "activity",
  //     label: "Activity"
  // },
  // {
  //     value: "customer",
  //     label: "People"
  // },
  {
    value: "company",
    label: "Company",
  },
  {
    value: "product",
    label: "Product",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
];

const modulesNamesOptions = [
  {
    value: "lead",
    label: "Lead & People",
  },
  {
    value: "deal",
    label: "Deals",
  },
  // {
  //     value: "activity",
  //     label: "Activity"
  // },
  // {
  //     value: "customer",
  //     label: "People"
  // },
  {
    value: "company",
    label: "Company",
  },
  {
    value: "product",
    label: "Product",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
];
const moduleObject = {
  all: "All",
  lead: "Lead & People",
  deal: "Deals",
  // "activity": "Activity",
  customer: "People",
  company: "Company",
  product: "Product",
  marketing: "Marketing",
};

const tagColors = [
  "#f6a6c2",
  "#ffe786",
  "#99e8a7",
  "#8babff",
  "#cc8aff",
  "#E0FFE5",
  "#DDF8FF",
  "#ECE8FF",
  "#FFE7DC",
  "#FFEED3",
  "#E5EFFF",
  "#FFDFFF",
  "#9898FA",
  "#CCD8FF",
  "#FECCE6",
  "#D2FBF0",
  "#FFCCB0",
  "#FFE2CC",
  "#FDD4D4",
  "#F7E2FE",
  "#C9F8B4",
  "#FF8D7D",
  "#9595FF",
  "#99FF93",
  "#FFE066",
];

export default function TagsSetting() {
  const [currentName, setCurrentName] = useState("All");
  const [currentModuleName, setCurrentModuleName] = useState("all");
  const [selectedModuleOption, setselectedModuleOption] = useState("Select Module");
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);
  const [isAllTagsSelected, setIsAllTagsSelected] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [showBulkDeleteModel, setShowBulkDeleteModel] = useState(false);
  const [show, setShow] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [delatableTagId, setDelatableTagId] = useState("");
  const [count, setCount] = useState(10);
  const [userListObject, setUserListObject] = useState({});

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();

  //state
  const tagSettingsData = useSelector((state) => state.adminSettings.tagsSettings);
  const userRole = useSelector((store) => store.insight.userRole);

  const tagsData = tagSettingsData.list;

  const [tagModelData, setTagModelData] = useState({
    _id: "",
    tagName: "",
    tagColor: "#f6a6c2",
    tagModule: "",
  });

  useEffect(() => {
    dispatch(adminSettingsActions.getTagsInSettingRequest({ type: currentModuleName, pageNo: pageNo, limit: limit }));
    setIsAllTagsSelected(false);
    setBulkSelectedIds([]);
  }, [dispatch, currentModuleName, pageNo, limit]);

  useEffect(() => {
    setCount(tagSettingsData.count);
  }, [tagSettingsData]);

  useEffect(() => {
    if (userRole.list.users) {
      const userObj = {};
      userRole.list.users.forEach((each) => {
        userObj[each.id] = each.name || "Not Available";
      });
      setUserListObject(userObj);
    }
  }, [userRole.list.users]);

  // useEffect(() => {
  //     setPageNo(1)
  //     // dispatch(adminSettingsActions.getTagsInSettingRequest({ type: currentModuleName, pageNo: 1, limit: limit }));
  // }, [limit]);

  // useEffect(() => {
  //     dispatch(adminSettingsActions.getTagsInSettingRequest({ type: currentModuleName, pageNo: pageNo, limit: limit }));
  // }, [dispatch, currentModuleName, pageNo]);

  const onEditTagClicked = (each) => {
    setShow(true);
    setIsAdd(false);
    setTagModelData({
      tagName: each.name,
      tagModule: each.type,
      tagColor: each.colorCode,
      _id: each._id,
    });
    setselectedModuleOption(each.type);
  };
  const DeleteManage = (each) => {
    setShowDeleteModel(true);
    setDelatableTagId(each._id);
  };

  const onFilterSelect = (value, label) => {
    setCurrentName(label);
    setCurrentModuleName(value);
    dispatch(adminSettingsActions.getTagsInSettingRequest({ type: value }));
  };

  const onAddTagClicked = () => {
    setShow(true);
    setIsAdd(true);
  };

  const onAllCheckBoxClicked = (e) => {
    if (e.target.checked) {
      const selectedIds = tagsData.map((each) => each._id);
      setBulkSelectedIds(selectedIds);
    } else {
      setBulkSelectedIds([]);
    }
    setIsAllTagsSelected(e.target.checked);
  };
  const onOneCheckBoxClicked = (e, id) => {
    if (e.target.checked) {
      const newSelectedIds = [...bulkSelectedIds];
      newSelectedIds.push(id);
      setBulkSelectedIds(newSelectedIds);
      if (newSelectedIds.length === tagsData.length) {
        setIsAllTagsSelected(true);
      }
    } else {
      const newSelectedIds = bulkSelectedIds.filter((each) => each !== id);
      setBulkSelectedIds(newSelectedIds);
      setIsAllTagsSelected(false);
    }
  };
  const handleCloseAddEditModel = () => {
    setShow(false);
    setTagModelData({
      tagColor: "#f6a6c2",
      tagModule: "",
      tagName: "",
    });
    setselectedModuleOption("Select Module");
  };
  const handleChangeAction = (e) => {
    setselectedModuleOption(e);
    const data = { ...tagModelData };
    data["tagModule"] = e;
    setTagModelData(data);
  };

  const handleFormChange = (label, value) => {
    const data = { ...tagModelData };
    data[label] = value;
    setTagModelData(data);
  };

  const deleteModelAccepetClicked = () => {
    if (delatableTagId) {
      dispatch(adminSettingsActions.deleteTagsInSettingRequest({ id: delatableTagId, module: currentModuleName }));
      setDelatableTagId("");
      setShowDeleteModel(false);
      setBulkSelectedIds([]);
    }
  };
  const bulkDeleteModelAccepetClicked = () => {
    if (bulkSelectedIds.length > 0) {
      dispatch(adminSettingsActions.bulkDeleteTagsInSettingRequest({ ids: bulkSelectedIds, module: currentModuleName }));
      setBulkSelectedIds([]);
      setShowBulkDeleteModel(false);
      setIsAllTagsSelected(false);
      setPageNo(1);
    }
  };

  const validate = () => {
    const { _id, tagName, tagModule, tagColor } = tagModelData;
    if (!tagName) {
      dispatchSnackbarError("Tag name is mandatory field!");
      return false;
    }
    if (!tagColor) {
      dispatchSnackbarError("Tag Colour is mandatory field!");
      return false;
    }
    if (isAdd && !tagModule) {
      dispatchSnackbarError("Tag Module is mandatory field!");
      return false;
    }
    if (!isAdd && !_id) {
      dispatchSnackbarError("Not able to edit current data. please reload!");
      return false;
    }
    return true;
  };

  const onSubmitAddOrEditForm = () => {
    const status = validate();
    if (!status) {
      return;
    }
    if (isAdd) {
      dispatch(
        adminSettingsActions.addTagsInSettingRequest({
          name: tagModelData.tagName,
          colorCode: tagModelData.tagColor,
          type: tagModelData.tagModule,
          module: currentModuleName,
        }),
      );
      setShow(false);
      setBulkSelectedIds([]);
      setTagModelData({
        _id: "",
        tagName: "",
        tagColor: "#3699FF",
        tagModule: "",
      });
      setselectedModuleOption("Select Module");
    } else {
      dispatch(
        adminSettingsActions.editTagsInSettingRequest({
          id: tagModelData._id,
          name: tagModelData.tagName,
          colorCode: tagModelData.tagColor,
          module: currentModuleName,
        }),
      );
      setShow(false);
      setBulkSelectedIds([]);
      setTagModelData({
        _id: "",
        tagName: "",
        tagColor: "#3699FF",
        tagModule: "",
      });
      setselectedModuleOption("Select Module");
    }
  };

  const deleteTemplateModalProps = {
    show: showDeleteModel,
    title: "Delete Tag",
    handleClose: () => {
      setShowDeleteModel(false);
    },
    handleAccept: () => deleteModelAccepetClicked(),
  };
  const bulkDeleteTemplateModalProps = {
    show: showBulkDeleteModel,
    title: "Delete Tags",
    handleClose: () => {
      setShowBulkDeleteModel(false);
    },
    handleAccept: () => bulkDeleteModelAccepetClicked(),
  };
  return (
    <div>
      <div className="card mb-3 mb-xl-3 overflow-y-auto h-vh-160px-mx border-style-hidden">
        <div className="card-header">
          <div className="card-title">
            <h3 className="fw-bolder m-0">
              {" "}
              <div> Tag Settings</div>
            </h3>
          </div>
          <div className="card-toolbar">
            {bulkSelectedIds.length > 1 && (
              <Button variant="danger" size="sm" onClick={() => setShowBulkDeleteModel(true)}>
                Bulk Delete
              </Button>
            )}
            <div className="pl-3">
              <button className="commonStyle btn btn-sm btn-primary my-1" onClick={() => onAddTagClicked()}>
                + Tag
              </button>
            </div>
            <div className="pl-3">
              <Dropdown drop="down">
                <Dropdown.Toggle className="h-38px btn btn-sm font-weight-bolder dropdown-toggle btn-secondary">{currentName}</Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
                  {modulesNamesArray.map((each, index) => {
                    return (
                      <Dropdown.Item key={index} className="dropdown-hover" onClick={() => onFilterSelect(each.value, each.label)} id={each.value}>
                        {each.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          {tagSettingsData.loading ? (
            <div className="d-flex justify-content-center align-items-center h-200px-mn">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9 w-100 h-200px-mn table-layout-fixed">
              <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  <th className="pl-4 w-50px mr-10">
                    <label className="checkbox checkbox-lg checkbox-single">
                      <input className="form-check-input" type="checkbox" value="1" onChange={(e) => onAllCheckBoxClicked(e)} checked={isAllTagsSelected} />
                      <span></span>
                    </label>
                  </th>
                  <th className="px-2 table-td">{"Tag Name"}</th>
                  <th className="px-2 table-td">{"Colour"}</th>
                  <th className="px-2 table-td">{"Tag Created By"}</th>
                  <th className="px-2 table-td">{"Module"}</th>
                  <th className="text-right w-100px">{"Actions"}</th>
                </tr>
              </thead>
              <tbody className="fw-6 fw-bold text-gray-600">
                {tagsData.map((each, index) => {
                  return (
                    <tr>
                      <td key={index} className="pl-4 w-50px mr-10">
                        <label className="checkbox checkbox-lg checkbox-single">
                          <input className="form-check-input" type="checkbox" value="1" onChange={(e) => onOneCheckBoxClicked(e, each._id)} checked={bulkSelectedIds.includes(each._id)} />
                          <span></span>
                        </label>
                      </td>
                      <td className="px-2 table-td">
                        <div>{each.name}</div>
                      </td>
                      <td className="px-2 table-td">
                        <InlineStyled as="span" className="h-25px w-25px border-radius-25px d-block" styles={{ background: each.colorCode }}></InlineStyled>
                      </td>
                      <td className="px-2 table-td">{userListObject[each.owner]}</td>
                      <td className="px-2 table-td">{moduleObject[each.type]}</td>
                      <td className="text-right">
                        <CustomAction Edit={() => onEditTagClicked(each)} Delete={() => DeleteManage(each)} />
                      </td>
                    </tr>
                  );
                })}
                {tagsData.length === 0 && (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <h6>No Data To Show</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="d-flex flex-row align-items-center p-3 justify-content-end gap-15px pt-7px">
          <p className="mt-5px mb-0 font-size-14px">
            Showing {(pageNo - 1) * limit + 1}-{count > pageNo * limit ? pageNo * limit : count} of {count}
          </p>
          {true
            ? CustomPagination(
                pageNo,
                setPageNo,
                Math.ceil(count / limit),
                () => {},
                limit,
                (e) => {
                  setLimit(e);
                  setPageNo(1);
                },
              )
            : null}
        </div>
      </div>

      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete selected tag?</DeleteModal>
      <DeleteModal modalProps={bulkDeleteTemplateModalProps}>Are you sure you want to bulk delete selected tags?</DeleteModal>
      <Modal show={show} onHide={handleCloseAddEditModel}>
        <Modal.Header closeButton>
          <Modal.Title>{isAdd ? "Add" : "Edit"} Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-14px">Tag Name </span>
            <FormInput placeholder={"Enter tag name"} type={"text"} name={"tagName"} value={tagModelData.tagName} onChange={(e) => handleFormChange("tagName", e.target.value)} />
          </div>
          <br />
          {isAdd && (
            <div className="col-lg-12 fv-row">
              <label className="form-label fw-bolder text-dark fs-6">Tag Module </label>
              <Dropdown drop="down" onSelect={(e) => handleChangeAction(e)}>
                <Dropdown.Toggle id="newSS" className="newDropDesign">
                  {moduleObject[selectedModuleOption]}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {modulesNamesOptions.map((each, index) => {
                    return (
                      <Dropdown.Item key={index} className="dropdown-hover" eventKey={each.value}>
                        {each.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <br />
          <div className="col-lg-12 fv-row">
            <label className="form-label fw-bolder text-dark fs-6">Tag Color </label>
            <CirclePicker colors={tagColors} width="100%" color={tagModelData.tagColor} onChange={(c, e) => handleFormChange("tagColor", c.hex)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={handleCloseAddEditModel}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => onSubmitAddOrEditForm()}>
            {isAdd ? "Add" : "Edit"} Tag
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
