import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

//containers
import ActiveLeads from "./activeLeads";
import JunkLeads from "./junkLeads";

export default function LeadBot(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/active`} component={ActiveLeads} />
      <Route exact path={`${props.match.path}/junk`} component={JunkLeads} />
      <Route exact path={`${props.match.path}/active/:leadId`} component={ActiveLeads} />
      <Route exact path={`${props.match.path}/active/:moduleName/:linkedId`} component={ActiveLeads} />
      <Route exact path={`${props.match.path}/active/:leadId/:moduleName/:linkedId`} component={ActiveLeads} />
      <Route exact path={`${props.match.path}/junk/:leadId`} component={JunkLeads} />
      <Route exact path={`${props.match.path}/junk/:moduleName/:linkedId`} component={JunkLeads} />
      <Route exact path={`${props.match.path}/junk/:leadId/:moduleName/:linkedId`} component={JunkLeads} />
      <Redirect to={`${props.match.path}`} />
    </Switch>
  );
}
