import React from "react";
import { renderAmount, renderPriority, renderTags } from "./utils";
import Details from "./details";
import Assignees from "./assignees";
import DueDateLabel from "./dueDateLabel";
import Labels from "./labels";
import { Content, ItemContainer, Right, Footer, PriceContainer } from "./styled.js";

class DealItem extends React.Component {
  renderStatus(stage) {
    if (!stage) {
      return null;
    }

    if (stage.probability === "Lost") {
      return this.renderStatusLabel("Lost", "red");
    }

    if (stage.probability === "Won") {
      return this.renderStatusLabel("Won", "green");
    }

    return this.renderStatusLabel("In Progress", "blue");
  }

  renderContent() {
    const { item } = this.props;

    const products = (item.products || []).filter((p) => p.tickUsed).map((p) => p.product);

    const exProducts = (item.products || []).filter((p) => !p.tickUsed).map((p) => p.product);

    const { customers, companies, closeDate, isComplete } = item;

    return (
      <>
        <h5>
          {renderPriority(item.priority)}
          {item.name}
        </h5>

        <Details color="#63D2D6" items={products} />
        <Details color="#b49cf1" items={exProducts} />
        <Details color="#F7CE53" items={customers || []} />
        <Details color="#EA475D" items={companies || []} />

        <PriceContainer>
          {renderAmount(item.amount)}
          {renderTags(item.tags)}

          <Right>
            <Assignees users={item.assignedUsers} />
          </Right>
        </PriceContainer>

        <DueDateLabel closeDate={closeDate} isComplete={isComplete} />

        <Footer>
          {item.isWatched
            ? "eye icon"
            : //   <Icon icon="eye" />
              "Last updated"}
          <Right>{this.renderDate(item.modifiedAt)}</Right>
        </Footer>
      </>
    );
  }

  // renderForm = () => {
  //     const { stageId, item, isFormVisible } = this.props;

  //     if (!isFormVisible) {
  //       return null;
  //     }

  //     return (
  //       <EditForm
  //         {...this.props}
  //         stageId={stageId || item.stageId}
  //         itemId={item._id}
  //         hideHeader={true}
  //         isPopupVisible={isFormVisible}
  //       />
  //     );
  // };

  render() {
    const { item, portable, onClick } = this.props;

    if (portable) {
      return (
        <>
          <ItemContainer onClick={onClick}>
            {this.renderStatus(item.stage)}
            <Content>{this.renderContent()}</Content>
          </ItemContainer>
          {/* {this.renderForm()} */}
        </>
      );
    }

    return (
      <>
        <Labels labels={item.labels} indicator={true} />
        <Content onClick={onClick}>{this.renderContent()}</Content>
        {/* {this.renderForm()} */}
      </>
    );
  }
}

export default DealItem;
