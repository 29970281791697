import React from "react";
import { Link } from "react-router-dom";
import { ButtonGroup } from "./styled";
import SubjectIcon from "@material-ui/icons/Subject";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function DealTypesGroup() {
  const viewType = window.location.href.includes("pipeline") ? "pipeline" : window.location.href.includes("fore-cast") ? "fore-cast" : window.location.href.includes("deal-list") ? "deal-list" : "conversion";

  const onFilterClick = (type) => {
    // const { currentBoard, currentPipeline } = props;

    // if (currentBoard && currentPipeline) {
    //     return `/deal/${type}?id=${currentBoard._id}&pipelineId=${currentPipeline._id}`;
    // }

    return `/deal/${type}`;
  };

  const boardLink = onFilterClick("pipeline");
  const foreCastLink = onFilterClick("fore-cast");
  const conversionlink = onFilterClick("conversion");
  const dealListLink = onFilterClick("deal-list");

  if (true) {
    return <></>;
  } else {
    return (
      <div>
        <ButtonGroup>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Pipeline</Tooltip>}>
            <Link to={boardLink} className={viewType === "pipeline" ? "active" : ""}>
              <SubjectIcon />
            </Link>
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip>Deal List</Tooltip>}>
            <Link disabled={true} to={dealListLink} className={viewType === "deal-list" ? "active" : ""}>
              <i className="bi bi-file-earmark-spreadsheet"></i>
            </Link>
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip>Fore cast</Tooltip>}>
            <Link to={foreCastLink} className={viewType === "fore-cast" ? "active" : ""}>
              <AttachMoneyIcon />
            </Link>
          </OverlayTrigger>

          <OverlayTrigger placement="bottom" overlay={<Tooltip>Conversion</Tooltip>}>
            <Link to={conversionlink} className={viewType === "conversion" ? "active" : ""}>
              <TrendingUpIcon />
            </Link>
          </OverlayTrigger>
        </ButtonGroup>
      </div>
    );
  }
}
