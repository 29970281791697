import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as usageActions from "../../../../actions/usage.actions";
import SubscriptionPlanModal from "../../../containers/subscription-billing/subscription-modal/subsciption-plan-model";
import { OverlayTrigger, Popover } from "react-bootstrap";
import DynamicNormalTable from "../../../../../../components/normal-table/dynamic-just-table";
import * as cadenceSettingsAction from "../../../../../Automation/cadence/store/actions/cadence_settings_actions";

function ShowUserWiseAnalytics({ data, owners, title, thead }) {
    return (
        <OverlayTrigger
            rootClose={true}
            trigger={"click"}
            overlay={
                <Popover className="overflow-y-auto h-500px-mx w-410px w-410px-mx" size="md" id="popover-basic">
                    <Popover.Title>{title}</Popover.Title>
                    <Popover.Content className="p-0">
                        <DynamicNormalTable tableHeading={thead}>
                            {data.map((each, index) => {
                                if (!owners[each._id]) return <tr key={index}>{/* <td className="w-175px d-flex gap-2 align-items-center border-0">Deleted User</td>
                    <td align="center">{each.count}</td> */}</tr>;
                                return (
                                    <tr key={index}>
                                        <td className="w-175px d-flex gap-2 align-items-center border-0">{`${owners[each._id].name} (${owners[each._id].email})`}</td>
                                        <td align="center">{each.count}</td>
                                    </tr>
                                );
                            })}
                        </DynamicNormalTable>
                    </Popover.Content>
                </Popover>
            }
        >
            <span className="cursor-pointer text-primary fs-7">More Details</span>
        </OverlayTrigger>
    );
}
export default function UsageDetailsV2(props) {
    const [showPlans, setShowPlans] = useState(false);
    const [theads] = useState(["Modules", "Consumed", "Available", "Unit"]);
    const [cadenceTheads] = useState(["User", "Consumed Monthly Subscriber", "Available Monthly Subscriber", "Total Monthly Subscriber"]);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const usageData = useSelector((store) => store.usage.v2);
    const userRole = useSelector((store) => store.insight.userRole);
    const cadenceSettingsStore = useSelector((store) => store.cadence.settings.allUserLimit);
    const [owners, setOwners] = useState({});

    // console.log(cadenceSettingsStore, "cadenceSettingsStore")

    useEffect(() => {
        dispatch(usageActions.getUsageRecordReportV2Request());
        dispatch(cadenceSettingsAction.getCadenceAllUsersLimitRequest());
    }, [dispatch]);

    useEffect(() => {
        if (userRole.list.allUsers) {
            const a = {};
            userRole.list.allUsers.forEach((each) => {
                a[each.id] = each;
            });
            setOwners(a);
        }
    }, [userRole.list.allUsers]);

    useEffect(() => {
        if (!_.isEmpty(usageData.data)) {
            const _tbody = [];
            _tbody.push({
                module: "Contacts",
                consumed: usageData.data.records.consumed.contacts,
                available: typeof usageData.data.records.assigned.contacts === "number" && usageData.data.records.assigned.contacts >= 1000000 ? "unlimited" : usageData.data.records.assigned.contacts,
                isLock: false,
                extra: usageData.data.records.extra,
                unit: "Per Organisation",
            });
            _tbody.push({ module: "Custom Fields", consumed: usageData.data.records.consumed.fields, available: usageData.data.records.assigned.fields, isLock: false, unit: "Per Organisation" });
            _tbody.push({ module: "Pipeline", consumed: usageData.data.consumed.pipeline, available: usageData.data.records.extraLimit.pipeline, isLock: false, unit: "Per Organisation" });

            _tbody.push({
                module: "Dashboard",
                consumed: usageData.data.consumed.dashboardV2.reduce((result, item) => {
                    if (!owners[item._id]) return result;
                    return item.count + result;
                }, 0),
                available: usageData.data.records.extraLimit.dashboard,
                isLock: false,
                workflowExtra: usageData.data.consumed.dashboardV2,
                title: "Dashboard Created By",
                thead: ["Users", "Dashboard Counts"],
                unit: "Per User",
            });

            _tbody.push({ module: "Leadbot", consumed: usageData.data.consumed.leadbot, available: usageData.data.records.extraLimit.leadbot, isLock: false, unit: "Per Organisation" });

            _tbody.push({
                module: "Workflow",
                consumed: usageData.data.consumed.workflow.reduce((result, item) => item.count + result, 0),
                available: usageData.data.records.extraLimit.workflow,
                isLock: !usageData.data.assigned.isWorkflow,
                workflowExtra: usageData.data.consumed.workflow,
                title: "Workflow Created By",
                thead: ["Users", "Active Worflow's"],
                unit: "Per User",
            });

            // _tbody.push({
            //   module: "Cadence",
            //   consumed: usageData.data.consumed.cadence.reduce((result, item) => item.count + result, 0),
            //   available: usageData.data.records.extraLimit.cadence,
            //   isLock: !usageData.data.assigned.isCadence,
            //   unit:"Per User"
            // });
            setTableData(_tbody);
        }
    }, [usageData.data, owners]);

    const renderData = (content, key) => {
        if (content.isLock) {
            return <span className="data-flex-container">Upgrade</span>;
        } else if (content.extra && key === "consumed") {
            return (
                <span className="data-flex-container">
                    {content[key]}
                    <span className="badge badge-light-primary">Lead: {content.extra.lead}</span>
                    <span className="badge badge-light-primary">People: {content.extra.people}</span>
                    <span className="badge badge-light-primary">Company: {content.extra.company}</span>
                </span>
            );
        } else if (content.workflowExtra && content.workflowExtra.length > 0 && key === "consumed") {
            return (
                <span className="data-flex-container">
                    {content[key]}
                    <ShowUserWiseAnalytics data={content.workflowExtra} owners={owners} title={content.title} thead={content.thead} />
                </span>
            );
        } else return <span className={`data-flex-container ${content.consumed > content.available ? "text-danger" : ""}`}>{content[key]}</span>;
    };

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header">
                    <div className="card-title">
                        <h4 className="fw-bolder m-0">
                            <div>Plan Details</div>
                        </h4>
                    </div>
                </div>
                <div className="p-9 bg-white">
                    <div className="card p-5 mb-5">
                        <div>
                            <div className="fs-5 mb-4">These are your plan limits. You can increase or decrease them according to your requirements.</div>
                        </div>
                        <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
                            <thead>
                                <tr className="fw-bolder">
                                    {theads.map((el, index) => {
                                        return (
                                            <th className={`w-25-mn position-sticky bg-gray-200 cursor-pointer opacity-100 top-0 text-nowrap ${index === 0 ? "pl-2" : ""}`}>
                                                <div className="d-flex flex-row w-100 text-black h-100 align-items-center opacity-80 text-nowrap gap-3">{el}</div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((content, ind) =>
                                    !content.isLock ? (
                                        <tr key={ind} className="cursor-pointer">
                                            {["module", "consumed", "available", "unit"].map((column, index) => (
                                                <td className={`text-nowrap ${index === 0 ? "pl-2" : ""}`}>
                                                    <div className="d-flex align-items-center justify-content-between flex-row">{renderData(content, column)}</div>
                                                </td>
                                            ))}
                                        </tr>
                                    ) : (
                                        <tr key={ind} className="cursor-pointer">
                                            <td className="text-nowrap" colSpan={"3"}>
                                                <div className="d-flex flex-center flex-row">
                                                    To see {content.module} details,{" "}
                                                    <span className="text-primary mx-2" onClick={() => setShowPlans(true)}>
                                                        Upgrade
                                                    </span>{" "}
                                                    Plan.
                                                </div>
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="card p-5">
                        <div>
                            <div className="fs-5 mb-4 fw-bold">Cadence User Usage</div>
                        </div>
                        <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
                            <thead>
                                <tr className="fw-bolder">
                                    {cadenceTheads.map((el, index) => {
                                        return (
                                            <th className={`w-25-mn position-sticky bg-gray-200 cursor-pointer opacity-100 top-0 text-nowrap ${index === 0 ? "pl-2" : ""}`}>
                                                <div className="d-flex flex-row w-100 text-black h-100 align-items-center opacity-80 text-nowrap gap-3">{el}</div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {cadenceSettingsStore.data.map((content, ind) => (
                                    <tr key={ind} className="cursor-pointer">
                                        {["email", "montlySubscriberUsage", "availableSubscribers", "montlySubscriberLimit"].map((column, index) => (
                                            <td className={`text-nowrap ${index === 0 ? "pl-2" : ""}`}>
                                                <div className="d-flex align-items-center justify-content-between flex-row">{renderData(content, column)}</div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {showPlans && <SubscriptionPlanModal page={""} showPlans={showPlans} setShowPlans={setShowPlans} />}
            </div>
        </>
    );
}
