import { getLoginSessionProfile } from "../../types/profile-settings.types";

export function getLoginSessionProfileRequest(currentPage, cb) {
  return {
    type: getLoginSessionProfile.GET_LOGIN_SESSION_PROFILE_REQUEST,
    cb,
    currentPage,
  };
}
export function getLoginSessionProfileSuccess(res) {
  return {
    type: getLoginSessionProfile.GET_LOGIN_SESSION_PROFILE_SUCCESS,
    payload: res,
  };
}
export function getLoginSessionProfileError(err) {
  return {
    type: getLoginSessionProfile.GET_LOGIN_SESSION_PROFILE_ERROR,
    payload: err,
  };
}
