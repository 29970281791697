import { takeLatest, put, call } from "redux-saga/effects";
// import { dispatchSnackbarSuccess, dispatchSnackbarError } from '../../../utils/toaster';

import api from "../../../network/apis/api";
import * as activityActions from "../actions/activity.actions";

import { GET_SETTING_ACTIVITIES_REQUEST } from "../types/activity.types";

function* getSettingActivities(action) {
  try {
    const response = yield call(api.get, "/api/crm/activity", action.payload);
    if (response.status === 200) {
      yield put(activityActions.getSettingActivitySuccess(response.data.data));
    } else yield put(activityActions.getSettingActivityError("error"));
  } catch (e) {
    yield put(activityActions.getSettingActivityError(e));
  }
}

export default function*() {
  //email sync saga
  yield takeLatest(GET_SETTING_ACTIVITIES_REQUEST, getSettingActivities);
}
