import { takeLatest, put, call } from "redux-saga/effects";
import * as salesForecastTypes from "../types/sales-forecast.types";
import api from "../../../../network/apis/api";
import * as SalesForecastActions from "../actions/sales_forecast.actions";

import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";

function* getInsightSalesForecastList(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/forecast`, action.payload);
    if (response.status === 200) {
      yield put(SalesForecastActions.getListOfSalesForecastSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesForecastActions.getListOfSalesForecastError(response));
    } else {
      yield put(SalesForecastActions.getListOfSalesForecastError("error"));
    }
  } catch (e) {
    yield put(SalesForecastActions.getListOfSalesForecastError(e));
  }
}
function* getInsightSalesForecastListLoadMore(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/forecast`, action.payload);
    if (response.status === 200) {
      yield put(SalesForecastActions.getListOfSalesForecastLoadMoreSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesForecastActions.getListOfSalesForecastLoadMoreError(response));
    } else {
      yield put(SalesForecastActions.getListOfSalesForecastLoadMoreError("error"));
    }
  } catch (e) {
    yield put(SalesForecastActions.getListOfSalesForecastLoadMoreError(e));
  }
}

function* createInsightSalesForecast(action) {
  try {
    const response = yield call(api.post, `/api/crm/insight/forecast`, action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(SalesForecastActions.createSalesForecastSuccess(response.data));
      yield put(SalesForecastActions.getListOfSalesForecastRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesForecastActions.createSalesForecastError(response));
    } else {
      yield put(SalesForecastActions.createSalesForecastError("error"));
    }
  } catch (e) {
    yield put(SalesForecastActions.createSalesForecastError(e));
  }
}

function* editInsightsSalesForecast(action) {
  try {
    const response = yield call(api.patch, `/api/crm/insight/forecast`, action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(SalesForecastActions.editSalesForecastSuccess(response.data));
      yield put(SalesForecastActions.getListOfSalesForecastRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesForecastActions.editSalesForecastError(response));
    } else {
      yield put(SalesForecastActions.editSalesForecastError("error"));
    }
  } catch (e) {
    yield put(SalesForecastActions.editSalesForecastError(e));
  }
}

function* deleteInsightsSalesForecast(action) {
  try {
    const response = yield call(api.delete, `/api/crm/insight/forecast/${action.payload.id}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(SalesForecastActions.getListOfSalesForecastRequest(action.payload.loadingData));
      yield put(SalesForecastActions.deleteSalesForecastSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesForecastActions.deleteSalesForecastError(response));
    } else {
      yield put(SalesForecastActions.deleteSalesForecastError("error"));
    }
  } catch (e) {
    yield put(SalesForecastActions.deleteSalesForecastError(e));
  }
}

function* getInsightGraphSalesForecast(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/forecast/graph/${action.payload}`);
    if (response.status === 200) {
      yield put(SalesForecastActions.getSalesForecastGraphSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesForecastActions.getSalesForecastGraphError(response));
    } else {
      yield put(SalesForecastActions.getSalesForecastGraphError("error"));
    }
  } catch (e) {
    yield put(SalesForecastActions.getSalesForecastGraphError(e));
  }
}

export default function* SalesForecastSaga() {
  //Sales Forecast
  yield takeLatest(salesForecastTypes.getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_REQUEST, getInsightSalesForecastList);
  yield takeLatest(salesForecastTypes.getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_REQUEST, getInsightSalesForecastListLoadMore);
  yield takeLatest(salesForecastTypes.createSalesForecast.CREATE_SALES_FORECAST_REQUEST, createInsightSalesForecast);
  yield takeLatest(salesForecastTypes.editSalesForecast.EDIT_SALES_FORECAST_REQUEST, editInsightsSalesForecast);
  yield takeLatest(salesForecastTypes.deleteSalesForecast.DELETE_SALES_FORECAST_REQUEST, deleteInsightsSalesForecast);
  yield takeLatest(salesForecastTypes.getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_REQUEST, getInsightGraphSalesForecast);
}
