import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { peopleDropdownRequest } from "../../containers/customers/store/actions/actions";
import SearchInputSelect from "../dropdowns/single-select-search-input-dropdown";

export default function PersonSelect({ field, value, setValue, customFormData, setCustomFormData }) {
  const personStore = useSelector((state) => state.People.peopleDropdownList);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const tempOptions = personStore.list.map((each) => {
      return {
        value: each["_id"],
        label: each["name"],
        email: each["email"],
        phone: each["phone"],
        company: each["company"] ? each["company"]["_id"] : undefined,
      };
    });
    setOptions(tempOptions);
  }, [personStore]);

  const _setCustomFormData = (data) => {
    setCustomFormData({ ...data, name: data.e.label, __name: data.e.value });
  };
  const setNewValue = (value) => {
    if (setCustomFormData) {
      const temp = {
        phone: customFormData.phone ? customFormData.phone : undefined,
        company: customFormData.company ? customFormData.company : undefined,
        email: customFormData.email ? customFormData.email : undefined,
        name: value,
      };
      setCustomFormData({ ...customFormData, ...temp });
    } else {
      setValue(value, field.apiKeyName);
    }
  };

  return (
    <>
      <SearchInputSelect
        type={"person"}
        field={field}
        options={options}
        value={value}
        loading={personStore.loading}
        request={peopleDropdownRequest}
        customFormData={customFormData}
        setCustomFormData={_setCustomFormData}
        setNewValue={setNewValue}
        setValue={setValue}
      />
    </>
  );
}
