import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { DealProductDetails } from "../components/deal-product-details.component";
import { PersonDetails } from "../components/person-details.component";
import { CompanyDetails } from "../components/company-details.component";
import { DealDetails } from "../components/deal-details.component";
import { reorder } from "./helper";
import { DealHeadDetails } from "../components/deal-head-details.components";
// import { SequenceDetails } from "../components/sequence.component";

export function DealLeftPannel(props) {
  const [order, setOrder] = useState(["deal", "people", "company", "product", "sequence"]);
  const [viewMoreOrder, setViewMoreOrder] = useState({ deal: false, people: false, company: false, product: false, sequence: false });

  useEffect(() => {
    const _order = localStorage.getItem("deal-popup-order");
    if (_order) {
      const updated = _order.split(",");
      if (updated.length === 5) {
        setOrder(updated);
      } else {
        setOrder(["deal", "people", "company", "product", "sequence"]);
      }
    } else {
      setOrder(["deal", "people", "company", "product", "sequence"]);
    }
  }, []);

  useEffect(() => {
    const _order = localStorage.getItem("deal-popup-view-more-order");
    if (_order) {
      const _parse = JSON.parse(_order);
      if (Object.keys(_parse).length === 5) {
        setViewMoreOrder(_parse);
      } else {
        setViewMoreOrder({ deal: false, people: false, company: false, product: false, sequence: false });
      }
    } else {
      setViewMoreOrder({ deal: false, people: false, company: false, product: false, sequence: false });
    }
  }, []);

  const handleDragEnd = (param) => {
    const a = reorder(order, param.source.index, param.destination.index);
    setOrder(a);
    localStorage.setItem("deal-popup-order", a.join(","));
  };

  const handleViewMoreChange = (module, status) => {
    const _viewMoreOrder = { ...viewMoreOrder };
    _viewMoreOrder[module] = status;
    setViewMoreOrder(_viewMoreOrder);
    localStorage.setItem("deal-popup-view-more-order", JSON.stringify(_viewMoreOrder));
  };

  const deal = (index) => (
    <DealDetails
      index={index}
      showSwitchPerson={true}
      page={props.page}
      pageSub={props.pageSub}
      dealId={props.dealId}
      moduleType={props.moduleType}
      pageForm={props.pageForm}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps || {}}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      viewMore={viewMoreOrder["deal"]}
      setViewMore={(status) => handleViewMoreChange("deal", status)}
    />
  );

  const people = (index) => (
    <PersonDetails
      index={index}
      showSwitchPerson={true}
      page={props.page}
      pageSub={props.pageSub}
      dealId={props.dealId}
      moduleType={props.moduleType}
      pageForm={props.pageForm}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      isSecondaryModule={true}
      viewMore={viewMoreOrder["people"]}
      setViewMore={(status) => handleViewMoreChange("people", status)}
    />
  );

  const company = (index) => (
    <CompanyDetails
      index={index}
      showSwitchPerson={true}
      page={props.page}
      pageSub={props.pageSub}
      dealId={props.dealId}
      moduleType={props.moduleType}
      pageForm={props.pageForm}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      isSecondaryModule={true}
      viewMore={viewMoreOrder["company"]}
      setViewMore={(status) => handleViewMoreChange("company", status)}
    />
  );

  const product = (index) => (
    <DealProductDetails
      index={index}
      page={props.page}
      pageSub={props.pageSub}
      dealId={props.dealId}
      pageForm={props.pageForm}
      moduleType={props.moduleType}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      viewMore={viewMoreOrder["product"]}
      setViewMore={(status) => handleViewMoreChange("product", status)}
    />
  );

  // const sequence = index => <SequenceDetails
  //     index={index}
  //     leadId={props.leadId}
  //     moduleType={props.moduleType}
  // />;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-side-panel">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <DealHeadDetails
              dealId={props.dealId}
              basePath={props.basePath}
              moduleType={props.moduleType}
              pageForm={props.pageForm}
              sidePanelProps={props.sidePanelProps}
              popupLoading={props.popupLoading}
              popupCellLoading={props.popupCellLoading}
            />

            {order.map((each, index) => {
              if (each === "deal") return deal(index);
              else if (each === "people") return people(index);
              else if (each === "company") return company(index);
              else if (each === "product") return product(index);
              // else if (each === "sequence") return sequence(index);
              else return <div key={index}></div>;
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
