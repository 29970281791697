import React, { useState } from "react";

import AccountReputation from "./account-reputation";
import FooterSettings from "./footer-setting";
import DomainSettings from "./domain-setting";
import { Tab } from "react-bootstrap";
import { SidebarTabs } from "../../../../../../components/side-pannel-detailed/custom-components/tabs";

export default function CampaignEmailSettingsRoutes(props) {
  const [selectedTab, setSelectedTab] = useState("Footer Address");
  const [categoryList] = useState(["Footer Address", "Verify Domain", "Account Reputation"]);

  return (
    <div>
      <Tab.Container defaultActiveKey={selectedTab}>
        <SidebarTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={categoryList} />
        <div>
          {selectedTab === "Footer Address" && <FooterSettings />}
          {selectedTab === "Verify Domain" && <DomainSettings />}
          {selectedTab === "Account Reputation" && <AccountReputation />}
        </div>
      </Tab.Container>
    </div>
  );
}
