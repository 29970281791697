import React, { useEffect, useState } from "react";
import { count } from "sms-length";
import ShortLinkManager from "../../../containers/campaign/containers/campaign-short-link/short-link-modal";
import VariableOptionsPro from "../../variable-options";

export default function SMSTextEditor(props) {
  const [showShortLinkManager, setShowShortLinkManager] = useState(false);
  const [showVariableOptions, setShowVariableOptions] = useState(false);

  const [messageStats, setMessageStats] = useState({
    characterPerMessage: 160,
    encoding: "GSM_7BIT",
    inCurrentMessage: 0,
    length: 0,
    messages: 1,
    remaining: 160,
  });

  useEffect(() => {
    const a = count(props.value);
    setMessageStats(a);
  }, [props.value]);

  const handleShortLinkSelect = (shortLink) => {
    const content = `${props.value} ${shortLink}{advanced}`;
    props.onChange(content);
  };
  const addVariable = (item, key, variable) => {
    const content = `${props.value} ${variable}`;
    props.onChange(content);
  };

  return (
    <>
      <div className="sms-text-editor-wrapper">
        <textarea id="textarea" value={props.value} onChange={(e) => props.onChange(e.target.value)} className="sms-textarea" cols="50" rows="7" maxlength="4000" />
        <div className="sms-text-editor-options-wrapper">
          <div className="sms-template-insert-wraper">
            <div className="cursor-pointer" onClick={() => setShowShortLinkManager(true)}>
              <i class="bi bi-link"></i>
              <span>Insert</span>
            </div>
            <div className="cursor-pointer" onClick={() => setShowVariableOptions(true)}>
              <i class="bi bi-braces-asterisk"></i>
              <span> Variables</span>
            </div>
          </div>
          <div className="sms-encoding-message-wrapper">
            <div className="d-flex gap-2">
              <strong>{messageStats.encoding}</strong> Encoding
            </div>
            <div className="d-flex gap-2">
              <strong>{messageStats.messages}</strong> msg
            </div>
            <div className="d-flex gap-2">
              Aa <strong>{messageStats.length}</strong>/4000
            </div>
          </div>
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <ShortLinkManager handleShortLinkSelect={handleShortLinkSelect} show={showShortLinkManager} handleClose={() => setShowShortLinkManager(false)} />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <VariableOptionsPro
          show={showVariableOptions}
          setShow={setShowVariableOptions}
          addVariable={addVariable}
          defaultVariableValues={props.defaultVariableValues}
          setDefaultVariableValues={props.setDefaultVariableValues}
        />
      </div>
    </>
  );
}
