import { takeLatest, put, call } from "redux-saga/effects";
import * as subscriptionBillingV2Types from "../types/subscription_billing_v2_types";
import * as subscriptionBillingV2Actions from "../actions/subscription_billing_v2_actions";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";

const endpoints = {
    GET_ACCOUNT_BILLING_V2: "/user-service/api/billing/v2/get-account-billing",
    GET_BILLING_PRICES_V2: "/user-service/api/billing/paddle/billing-plan-pricing",
    GET_BILLING_INVOICE_LIST_V2: "/account/billing/v2/invoices",
    ASSIGN_FREE_PLAN_V2: "/user-service/api/billing/v2/assign-free-plan",
    PAUSE_SUBSCRIPTION_V2: "/user-service/api/billing/v2/pause-subscription",
    RESUME_SUBSCRIPTION_V2: "/user-service/api/billing/v2/resume-subscription",
    CANCEL_SUBSCRIPTION_V2: "/user-service/api/billing/v2/cancel-subscription",
    UPDATE_SUBSCRIPTION_V2: "/user-service/api/billing/v2/update-subscription",
    DONT_CANCEL_SUBSCRIPTION_V2: "/user-service/api/billing/v2/dont-cancel-subscription",
};
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function* getAccountBillingV2(action) {
    try {
        const res = yield call(api.get, endpoints.GET_ACCOUNT_BILLING_V2, action.payload);
        if (res.status === 200) {
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Success(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.getAccountBillingV2Error(e));
    }
}
function* getBillingPricesV2(action) {
    try {
        const res = yield call(api.get, endpoints.GET_BILLING_PRICES_V2, action.payload);
        if (res.status === 200) {
            yield put(subscriptionBillingV2Actions.getBillingPricesV2Success(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.getBillingPricesV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.getBillingPricesV2Error(e));
    }
}
function* getBillingInvoiceListV2(action) {
    try {
        const res = yield call(api.get, endpoints.GET_BILLING_INVOICE_LIST_V2, action.payload);
        if (res.status === 200) {
            yield put(subscriptionBillingV2Actions.getBillingInvoiceListV2Success(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.getBillingInvoiceListV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.getBillingInvoiceListV2Error(e));
    }
}

function* assignFreePlanV2(action) {
    try {
        const res = yield call(api.post, endpoints.ASSIGN_FREE_PLAN_V2, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(subscriptionBillingV2Actions.assignFreePlanV2Success(res.data));
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Request())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.assignFreePlanV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.assignFreePlanV2Error(e));
    }
}

function* pauseSubscriptionV2(action) {
    try {
        const res = yield call(api.patch, endpoints.PAUSE_SUBSCRIPTION_V2, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(subscriptionBillingV2Actions.pauseSubscriptionV2Success(res.data));
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Request())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.pauseSubscriptionV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.pauseSubscriptionV2Error(e));
    }
}

function* resumeSubscriptionV2(action) {
    try {
        const res = yield call(api.patch, endpoints.RESUME_SUBSCRIPTION_V2, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(subscriptionBillingV2Actions.resumeSubscriptionV2Success(res.data));
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Request())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.resumeSubscriptionV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.resumeSubscriptionV2Error(e));
    }
}

function* cancelSubscriptionV2(action) {
    try {
        const res = yield call(api.patch, endpoints.CANCEL_SUBSCRIPTION_V2, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(subscriptionBillingV2Actions.cancelSubscriptionV2Success(res.data));
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Request())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.cancelSubscriptionV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.cancelSubscriptionV2Error(e));
    }
}

function* updateSubscriptionV2(action) {
    try {
        const res = yield call(api.patch, endpoints.UPDATE_SUBSCRIPTION_V2, action.payload);

        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(subscriptionBillingV2Actions.updateSubscriptionV2Success(res.data));
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Request())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.updateSubscriptionV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {

        yield put(subscriptionBillingV2Actions.updateSubscriptionV2Error(e));
    }
}


function* dontCancelSubscriptionV2(action) {
    try {
        const res = yield call(api.patch, endpoints.DONT_CANCEL_SUBSCRIPTION_V2, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(subscriptionBillingV2Actions.dontCancelSubscriptionV2Success(res.data));
            yield put(subscriptionBillingV2Actions.getAccountBillingV2Request())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
            yield call(delay, 2000);
            yield put(subscriptionBillingV2Actions.getAccountBillingV2AfterRequest())
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(subscriptionBillingV2Actions.dontCancelSubscriptionV2Error(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(subscriptionBillingV2Actions.dontCancelSubscriptionV2Error(e));
    }
}

export default function* () {
    yield takeLatest(subscriptionBillingV2Types.getAccountBillingV2.REQUEST, getAccountBillingV2);
    yield takeLatest(subscriptionBillingV2Types.getAccountBillingV2.AFTER_REQUEST, getAccountBillingV2);
    yield takeLatest(subscriptionBillingV2Types.getBillingPricesV2.REQUEST, getBillingPricesV2);
    yield takeLatest(subscriptionBillingV2Types.getBillingInvoiceListV2.REQUEST, getBillingInvoiceListV2);
    yield takeLatest(subscriptionBillingV2Types.assignFreePlanV2.REQUEST, assignFreePlanV2);
    yield takeLatest(subscriptionBillingV2Types.pauseSubscriptionV2.REQUEST, pauseSubscriptionV2);
    yield takeLatest(subscriptionBillingV2Types.resumeSubscriptionV2.REQUEST, resumeSubscriptionV2);
    yield takeLatest(subscriptionBillingV2Types.cancelSubscriptionV2.REQUEST, cancelSubscriptionV2);
    yield takeLatest(subscriptionBillingV2Types.updateSubscriptionV2.REQUEST, updateSubscriptionV2);
    yield takeLatest(subscriptionBillingV2Types.dontCancelSubscriptionV2.REQUEST, dontCancelSubscriptionV2);

}
