import React from "react";
import { Button } from "react-bootstrap";

export default function SelectAll(props) {
  const { checked, count, isAllSelected, limit } = props;
  const { selectAllClick, clearAllClick } = props;
  if (checked.length === 0) return;
  return (
    <>
      {isAllSelected ? count : checked.length} selected
      {!isAllSelected && count > limit && checked.length === limit && (
        <Button variant="link" onClick={selectAllClick}>
          Select All {count}
        </Button>
      )}
      {isAllSelected && count > limit && (
        <Button variant="link" onClick={clearAllClick}>
          Clear All {count}
        </Button>
      )}
    </>
  );
}
