import React, { useEffect, useState } from "react";
import { CustomSubHeader } from "../../../../components/subheader";
import ContentWrapper from "../../../Wrapper";
import Select from "react-select";
import splitter from "split-sms";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import CodeMirror from "react-codemirror";
import * as _ from "lodash";

export default function CampaignSMS(props) {
  const [msgNo, setmsgNo] = useState();
  const [msgLength, setmsgLength] = useState();
  const [showPanel, setShowPanel] = useState(false);
  const [shortLinkPanel, setShortLinkPanel] = useState(false);
  const [numbers, setNumbers] = useState("");
  const [numbersCount, setNumbersCount] = useState(0);
  const [fileMethod, setFileMethod] = useState("copy");
  const [scheduleStatus, setScheduleStatus] = useState(false);
  const [splitStatus, setSplitStatus] = useState(false);

  // custom style for Country Select
  const customStyles = {
    control: () => ({
      background: "#F3F6F9",
      // height: '45px',
      padding: "7px",
    }),
    indicatorsContainer: () => ({
      position: "absolute",
      top: "3px",
      right: 0,
      display: "flex",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
    placeholder: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
  };

  var options = {
    // lineNumbers: true,
  };

  const fileSelection = (e) => {
    setFileMethod(e.target.value);
  };

  const enterNumber = (e) => {
    setNumbers(e);
  };

  useEffect(() => {
    if (numbers === undefined || numbers.length === 0) {
      setNumbersCount(0);
      setNumbers("");
    }
    if (numbers !== undefined && numbers !== "") {
      try {
        let all_numbers = numbers
          .trim()
          .replace(/\n/g, ",")
          .replace(/,/g, "\n")
          .replace(/ /g, "")
          .split("\n")
          .filter((e) => e);
        setNumbersCount(all_numbers.length);
      } catch (e) {}
    } else {
      setNumbersCount(0);
      setNumbers("");
    }
  }, [numbers]);

  const messageFunction = (e) => {
    var ss = splitter.split(e.target.value);
    setmsgNo(ss.parts.length);
    setmsgLength(ss.bytes);
  };

  const clearFunction = () => {
    setNumbers("");
    setNumbersCount(0);
  };

  const uniqueFunction = () => {
    let unique_array = _.uniq(numbers);
    setNumbers(unique_array.toString().replace(/,/g, "\n"));
    setNumbersCount(unique_array.toString().replace(/,/g, "\n").length);
  };

  const selectSchedule = (e) => {
    setScheduleStatus(e.target.checked);
  };

  const selectSplit = (e) => {
    setSplitStatus(e.target.checked);
  };

  const onCustomFromDateSelect = (e) => {};

  return (
    <>
      <ContentWrapper subHeader={<CustomSubHeader></CustomSubHeader>}>
        <div className="bg-white p-5-percent pl-20-percent pr-20-percent">
          <div className="flex-column">
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-dark fs-6">Campaign Name</label>
              <input type="text" className="form-control form-control-solid" name="input1" placeholder defaultValue />
            </div>

            <div className="row mb-2" data-kt-buttons="true">
              <label className="form-label fw-bolder text-dark fs-6">Message Type</label>

              <div className="col">
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" name="account_team_size" defaultValue="1-1" />
                  <span className="fw-bolder fs-3">Promotional</span>
                </label>
              </div>

              <div className="col">
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" name="account_team_size" defaultValue="2-10" />
                  <span className="fw-bolder fs-3">Transactional</span>
                </label>
              </div>

              <div className="col">
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" name="account_team_size" defaultValue="10-50" />
                  <span className="fw-bolder fs-3">Promo</span>
                </label>
              </div>
            </div>

            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-dark fs-6">Header Name</label>
              <Select
                styles={customStyles}
                placeholder=""
                // defaultValue={agentId}
                // onChange={dropValueMethod}
                // options={teamDetails}
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                // isClearable
              />
            </div>
          </div>

          <div className="flex-column">
            <div>
              <label
                onClick={() => {
                  setShowPanel(true);
                }}
                className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10`}
                for="kt_create_account_form_account_type_personal"
              >
                <span className="svg-icon svg-icon-3x me-5">
                  <i className="bi bi-person mb-16px font-size-48px text-cornflowerblue"></i>
                </span>

                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-4 mb-2">Select Audience</span>
                  <span className="text-muted fw-bold fs-6">The message will be sent to unique numbers</span>
                </span>
              </label>
            </div>
          </div>

          {/* sidepopup */}

          <div
            id="floatarrow"
            onClick={() => {
              setShowPanel(false);
            }}
            className={`${
              showPanel ? "right-51vw" : "right-minus-10vw"
            } bg-white border-radius-5px width-auto h-30px d-flex flex-column justify-content-evenly align-items-center px-1 offcanvas offcanvas-right mt-2 position-fixed`}
          >
            <span className="btn btn-xs btn-icon btn-light bg-white">
              <i className="fas fa-times "></i>
            </span>
          </div>
          <div id="sidepanel" className={`${showPanel ? "right-0" : "right-minus-80vw"} w-50vw h-100vh offcanvas offcanvas-right pb-10 `}>
            <div className="card shadow-none rounded-0">
              <div className="card-header">
                <h3 className="card-title fw-bolder text-dark">Audience</h3>
              </div>
              <div className="h-vh-70px overflow-y-auto py-0 px-20px">
                <div className="d-flex justify-content-arround p-5-percent">
                  <div className="w-25prc">
                    <input className="h-32px w-30px" type="radio" id="html" name="fav_language" value="copy" defaultChecked onChange={(e) => fileSelection(e)} />
                    <label className="position-relative top-minus-7px font-size-16px" for="html">
                      <b>Copy-Paste</b>
                    </label>
                  </div>

                  <div className="w-25prc">
                    <input className="h-32px w-30px" type="radio" id="css" name="fav_language" value="upload" onChange={(e) => fileSelection(e)} />
                    <label className="position-relative top-minus-7px font-size-16px" for="css">
                      <b>Upload Files</b>
                    </label>
                  </div>
                </div>

                {fileMethod === "upload" && (
                  <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="dropzone dropzone1">
                          <div className="dz-message needsclick align-items-center p-15-percent">
                            <i className={`bi bi-file-check text-primary fs-3x`}></i>
                            <input {...getInputProps()} />
                            <div className="ms-4">
                              <h3 className="fs-5 fw-bolder text-gray-900 mb-1">{"Drop files here or click to upload."}</h3>
                              {<h7 className="fs-5 mb-1">Only .csv and .xlsx files are supported</h7>}
                              {/* {file && file.type && <div><h7 className="fs-6 mb-1">{file.type} </h7></div>}
                                                    {file && file.size && <div> <h7 className="fs-6 mb-1">{file.size / 1000} kB</h7></div>} */}
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}

                {/* value={this.state.code} onChange={this.updateCode} */}
                {fileMethod === "copy" && (
                  <>
                    <div className="m-auto w-90 border-width-1px border-style-solid">
                      <CodeMirror value={numbers} onChange={(e) => enterNumber(e)} options={options} />
                    </div>

                    <br></br>

                    <div className="d-flex justify-content-between m-auto w-90">
                      <b className="w-70">Count: {numbersCount}</b>
                      <button onClick={() => clearFunction()} type="button" className="h-36px btn btn-primary" data-kt-stepper-action="next">
                        Clear
                      </button>

                      <button onClick={() => uniqueFunction()} type="button" className="h-36px btn btn-primary" data-kt-stepper-action="next">
                        Unique
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="flex-column">
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-dark fs-6">Message</label>
              <textarea className="form-control form-control-solid border-width-1px border-style-solid" name="input1" onChange={(e) => messageFunction(e)} placeholder rows={10} cols={10} />
              <div className="d-flex h-40px mt-15px">
                <div>
                  <span className="btn btn-outline btn-outline-dashed btn-outline-default me-2 mb-2">
                    <i className="bi bi-clipboard-plus"></i>Insert
                  </span>
                </div>

                <div
                  onClick={() => {
                    setShortLinkPanel(true);
                  }}
                >
                  <span className="btn btn-outline btn-outline-dashed btn-outline-default me-2 mb-2">
                    <i className="bi bi-link-45deg"></i>Smart Link
                  </span>
                </div>

                <div className="position-absolute right-20-percent">
                  Message: {msgNo}/Characters: {msgLength}
                </div>
              </div>
            </div>
          </div>
          <div
            id="floatarrow"
            onClick={() => {
              setShortLinkPanel(false);
            }}
            className={`${
              shortLinkPanel ? "right-51vw" : "right-minus-10vw"
            } bg-white border-radius-5px width-auto h-30px px-1 offcanvas offcanvas-right mt-2 d-flex flex-column justify-content-evenly align-items-center position-fixed`}
          >
            <span className="btn btn-xs btn-icon btn-light bg-white">
              <i className="fas fa-times "></i>
            </span>
          </div>
          <div id="sidepanel" className={`${shortLinkPanel ? "right-0" : "right-minus-80vw"} w-50vw h-100vh offcanvas offcanvas-right pb-10 `}>
            <div className="card shadow-none rounded-0">
              <div className="card-header">
                <h3 className="card-title fw-bolder text-dark">Insert Link</h3>
              </div>
              <div className="h-vh-70px overflow-y-auto py-0 px-20px">
                <form className="mt-20px">
                  <div className="container">
                    <div className="row">
                      <InputGroup className="mb-3">
                        <FormControl placeholder="URL TITLE" aria-label="url" aria-describedby="basic-addon2" />
                      </InputGroup>
                    </div>
                    <br></br>
                    <div className="row">
                      <InputGroup className="mb-3">
                        <FormControl placeholder="url & upload the file" aria-label="url" aria-describedby="basic-addon2" />
                        <Button variant="outline-secondary" id="button-addon2">
                          <i className="bi bi-list-nested"></i>
                        </Button>
                      </InputGroup>
                    </div>
                    <br></br>
                    <div className="row">
                      <h4>URL Shortening (optional)</h4>

                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">https://example.com/users/</InputGroup.Text>
                        <FormControl id="basic-url" aria-describedby="basic-addon3" />
                      </InputGroup>
                    </div>
                    <br></br>
                    <div className="row">
                      <InputGroup className="mb-3">
                        <FormControl placeholder="Callback URL" aria-label="url" aria-describedby="basic-addon2" />
                      </InputGroup>
                    </div>
                    <br></br>
                    <div className="row">
                      <InputGroup className="mb-3">
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                        <b className="mt-8px text-skyblue">Track User</b>
                      </InputGroup>
                    </div>
                  </div>
                  <br></br>
                  <button type="button" className="btn btn-primary float-right" data-kt-stepper-action="next">
                    Insert
                  </button>
                </form>
              </div>
            </div>
          </div>
          {/* ************************************** */}

          <div className="d-flex justify-content-between w-35">
            <div className="d-flex form-check form-check-sm form-check-custom form-check-solid me-3">
              <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" onClick={(e) => selectSchedule(e)} value="1" />
              <b className="position-relative top-1px mr-33px">Schedule</b>
            </div>

            <div className="d-flex form-check form-check-sm form-check-custom form-check-solid me-3">
              <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" onClick={(e) => selectSplit(e)} value="2" />
              <b className="position-relative left-30px top-1px mr-33px">Split Screen</b>
            </div>
          </div>
          <br></br>

          {splitStatus && (
            <div className="flex-column">
              <div className="fv-row mb-10">
                <label className="form-label fw-bolder text-dark fs-6">Contacts Per Batch</label>
                <input type="number" className="form-control form-control-solid" name="input1" placeholder value="5000" />
              </div>

              <div className="fv-row mb-10">
                <label className="form-label fw-bolder text-dark fs-6">Time Interval</label>
                <Select
                  styles={customStyles}
                  placeholder=""
                  // defaultValue={agentId}
                  // onChange={dropValueMethod}
                  // options={teamDetails}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                  // isClearable
                />
              </div>
            </div>
          )}

          {scheduleStatus && (
            <>
              <div className="custom-calendar-fields">
                <div className="w-48">
                  <input
                    className="form-control"
                    type="datetime-local"
                    // value="2011-08-19T13:45:00"
                    id="example-datetime-local-input"
                    onChange={(e) => onCustomFromDateSelect(e)}
                  />
                </div>
              </div>

              <br></br>

              <div className="fv-row mb-10">
                <label className="form-label fw-bolder text-dark fs-6">Timezone</label>
                <Select
                  styles={customStyles}
                  placeholder=""
                  // defaultValue={agentId}
                  // onChange={dropValueMethod}
                  // options={teamDetails}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                  // isClearable
                />
              </div>
            </>
          )}
        </div>
      </ContentWrapper>
    </>
  );
}
