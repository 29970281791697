import { bulkEditPeopleV2, bulkDeletePeopleV2 } from "../types/people-operations.types";

export const bulkEditPeopleV2Request = (payload, cb) => ({
  type: bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_REQUEST,
  payload,
  cb,
});
export const bulkEditPeopleV2Success = (payload) => ({
  type: bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_SUCCESS,
  payload,
});
export const bulkEditPeopleV2Error = (payload) => ({
  type: bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_ERROR,
  payload,
});

export const bulkDeletePeopleV2Request = (payload, cb) => ({
  type: bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_REQUEST,
  payload,
  cb,
});
export const bulkDeletePeopleV2Success = (payload) => ({
  type: bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_SUCCESS,
  payload,
});
export const bulkDeletePeopleV2Error = (payload) => ({
  type: bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_ERROR,
  payload,
});
