export const getListOfEmailFinder = {
    REQUEST: 'GET_LIST_OF_EMAIL_FINDER_REQUEST',
    SUCCESS: 'GET_LIST_OF_EMAIL_FINDER_SUCCESS',
    FAILURE: 'GET_LIST_OF_EMAIL_FINDER_FAILURE'
}

export const createEmailFinder = {
    REQUEST: 'CREATE_EMAIL_FINDER_REQUEST',
    SUCCESS: 'CREATE_EMAIL_FINDER_SUCCESS',
    FAILURE: 'CREATE_EMAIL_FINDER_FAILURE'
}

export const getEmailFinderPricing = {
    REQUEST: 'GET_EMAIL_FINDER_PRICING_REQUEST',
    SUCCESS: 'GET_EMAIL_FINDER_PRICING_SUCCESS',
    FAILURE: 'GET_EMAIL_FINDER_PRICING_FAILURE'
}