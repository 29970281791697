import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  PRODUCTFIELDS_REQUEST,
  PRODUCTFIELDS_SUCCESS,
  PRODUCTFIELDS_ERROR,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  PRODUCTADDFIELDS_REQUEST,
  PRODUCTADDFIELDS_SUCCESS,
  PRODUCTADDFIELDS_ERROR,
  PRODUCTCATEGORIES_REQUEST,
  PRODUCTCATEGORIES_SUCCESS,
  PRODUCTCATEGORIES_ERROR,
  PRODUCTCATEGORIESADD_REQUEST,
  PRODUCTCATEGORIESADD_SUCCESS,
  PRODUCTCATEGORIESADD_ERROR,
  PRODUCTCATEGORIESEDIT_REQUEST,
  PRODUCTCATEGORIESEDIT_SUCCESS,
  PRODUCTCATEGORIESEDIT_ERROR,
  PRODUCTCATEGORIESDELETE_REQUEST,
  PRODUCTCATEGORIESDELETE_SUCCESS,
  PRODUCTCATEGORIESDELETE_ERROR,
  PRODUCTTAGS_REQUEST,
  PRODUCTTAGS_SUCCESS,
  PRODUCTTAGS_ERROR,
  PRODUCTTAGSADD_REQUEST,
  PRODUCTTAGSADD_SUCCESS,
  PRODUCTTAGSADD_ERROR,
  PRODUCTTAGSEDIT_REQUEST,
  PRODUCTTAGSEDIT_SUCCESS,
  PRODUCTTAGSEDIT_ERROR,
  PRODUCTTAGSDELETE_REQUEST,
  PRODUCTTAGSDELETE_SUCCESS,
  PRODUCTTAGSDELETE_ERROR,
  PRODUCTCELLEDIT_REQUEST,
  PRODUCTCELLEDIT_SUCCESS,
  PRODUCTCELLEDIT_ERROR,
  PRODUCTADD_REQUEST,
  PRODUCTADD_SUCCESS,
  PRODUCTADD_ERROR,
  PRODUCTBULKEDIT_REQUEST,
  PRODUCTBULKEDIT_SUCCESS,
  PRODUCTBULKEDIT_ERROR,
  PRODUCTDELETE_REQUEST,
  PRODUCTDELETE_SUCCESS,
  PRODUCTDELETE_ERROR,
  PRODUCTFILTERS_REQUEST,
  PRODUCTFILTERS_SUCCESS,
  PRODUCTFILTERS_ERROR,
  PRODUCTFILTERADD_REQUEST,
  PRODUCTFILTERADD_SUCCESS,
  PRODUCTFILTERADD_ERROR,
  PRODUCTFILTEREDIT_REQUEST,
  PRODUCTFILTEREDIT_SUCCESS,
  PRODUCTFILTEREDIT_ERROR,
  PRODUCTFILTERFAVORITE_REQUEST,
  PRODUCTFILTERFAVORITE_SUCCESS,
  PRODUCTFILTERFAVORITE_ERROR,
  PRODUCTFILTERDELETE_REQUEST,
  PRODUCTFILTERDELETE_SUCCESS,
  PRODUCTFILTERDELETE_ERROR,
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_ERROR,
  PRODUCTS_UPDATE_REQUEST,
  PRODUCTS_UPDATE_SUCCESS,
  PRODUCTS_UPDATE_ERROR,
  LOADING_DATA_UPDATE,
  PRODUCTSMERGE_REQUEST,
  PRODUCTSMERGE_SUCCESS,
  PRODUCTSMERGE_ERROR,
  APPLYFILTER,
  CLEARFILTER,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_ERROR,
  PRODUCT_DROPDOWN_ADD_REQUEST,
  PRODUCT_DROPDOWN_ADD_SUCCESS,
  PRODUCT_DROPDOWN_ADD_ERROR,
  PRODUCT_DROPDOWN_ADD_CLEAR,
} from "../types/types";

import { bulkDeleteProductV2, bulkEditProductV2 } from "../types/product-operations.types";

const initialState = {
  productList: {
    loading: "",
    list: [],
    count: 0,
    cellLoading: false,
  },
  productFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  productAddFields: {
    loading: "",
    list: [],
  },
  productCategories: {
    loading: "",
    list: [],
  },
  productTags: {
    loading: "",
    list: [],
  },
  productFilters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  currencyList: {
    loading: "",
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
  loadingData: {
    pageNo: 1,
    limit: 50,
  },
  productDropdownList: {
    loading: "",
    list: [],
  },
  newProduct: null,
  // you can define required variables here
};

export function productsReducer(state = initialState, action = {}) {
  switch (action.type) {
    //product search
    case PRODUCT_DROPDOWN_ADD_REQUEST:
      return {
        ...state,
        productDropdownList: { ...state.productDropdownList, loading: true },
      };
    case PRODUCT_DROPDOWN_ADD_SUCCESS:
      return {
        ...state,
        newProduct: { id: action.payload.id, name: action.payload.name },
        // productDropdownList:{loading:false,list:action.payload.data},
      };
    case PRODUCT_DROPDOWN_ADD_ERROR:
      return {
        ...state,
        productDropdownList: { ...state.productDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case PRODUCT_DROPDOWN_ADD_CLEAR:
      return {
        ...state,
        productDropdownList: { ...state.productDropdownList, loading: false },
        newCompany: null,
      };
    case PRODUCT_SEARCH_REQUEST:
      return {
        ...state,
        productDropdownList: { ...state.productDropdownList, loading: true },
      };
    case PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        productDropdownList: { loading: false, list: action.payload.data },
      };
    case PRODUCT_SEARCH_ERROR:
      return {
        ...state,
        productDropdownList: { ...state.productDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case PRODUCTS_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, loading: true },
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: { loading: false, list: action.payload.data, count: action.payload.count },
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        productList: { ...state.productList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    case PRODUCTS_UPDATE_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, cellLoading: action.payload.cellId },
      };
    case PRODUCTS_UPDATE_SUCCESS:
      return {
        ...state,
        productList: { loading: false, list: action.payload.data, count: action.payload.count, cellLoading: false },
      };
    case PRODUCTS_UPDATE_ERROR:
      return {
        ...state,
        productList: { ...state.productList, cellLoading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    //Update loading data
    case LOADING_DATA_UPDATE:
      return {
        ...state,
        loadingData: action.payload,
      };

    // Clear error or success
    case CLEAR_ERROR:
      return {
        ...state,
        error: { err: false, errMsg: "" },
      };

    case CLEAR_SUCCESS:
      return {
        ...state,
        success: { success: false, successMsg: "" },
      };

    // Fields
    case PRODUCTFIELDS_REQUEST:
      return {
        ...state,
        productFields: { ...state.productFields, loading: true },
      };
    case PRODUCTFIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        productFields: { loading: false, list: action.payload, idToIndex: tempObj },
      };
    case PRODUCTFIELDS_ERROR:
      return {
        ...state,
        productFields: { ...state.productFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Update fields
    case FIELD_UPDATE_REQUEST:
      return {
        ...state,
      };
    case FIELD_UPDATE_SUCCESS:
      var tempUpdate = {};
      for (var j = 0; j < action.payload.length; j++) {
        tempUpdate[action.payload[j]["_id"]] = j;
      }
      return {
        ...state,
        productFields: { list: action.payload, idToIndex: tempUpdate },
      };
    case FIELD_UPDATE_ERROR:
      return {
        ...state,
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Fields
    case PRODUCTADDFIELDS_REQUEST:
      return {
        ...state,
        productAddFields: { ...state.productAddFields, loading: true },
      };
    case PRODUCTADDFIELDS_SUCCESS:
      return {
        ...state,
        productAddFields: { loading: false, list: action.payload },
      };
    case PRODUCTADDFIELDS_ERROR:
      return {
        ...state,
        productAddFields: { ...state.productAddFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Product
    case PRODUCTADD_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, loading: true },
      };
    case PRODUCTADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTADD_ERROR:
      return {
        ...state,
        productList: { ...state.productList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Bulk Edit Product
    case PRODUCTBULKEDIT_REQUEST:
    case bulkEditProductV2.BULK_EDIT_PRODUCT_V2_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, loading: true },
      };
    case PRODUCTBULKEDIT_SUCCESS:
    case bulkEditProductV2.BULK_EDIT_PRODUCT_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTBULKEDIT_ERROR:
    case bulkEditProductV2.BULK_EDIT_PRODUCT_V2_ERROR:
      return {
        ...state,
        productList: { ...state.productList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Product
    case PRODUCTDELETE_REQUEST:
    case bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, loading: true },
      };
    case PRODUCTDELETE_SUCCESS:
    case bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTDELETE_ERROR:
    case bulkDeleteProductV2.BULK_DELETE_PRODUCT_V2_ERROR:
      return {
        ...state,
        productList: { ...state.productList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // GET Categories
    case PRODUCTCATEGORIES_REQUEST:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: true },
      };
    case PRODUCTCATEGORIES_SUCCESS:
      return {
        ...state,
        productCategories: { loading: false, list: action.payload },
      };
    case PRODUCTCATEGORIES_ERROR:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Category
    case PRODUCTCATEGORIESADD_REQUEST:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: true },
      };
    case PRODUCTCATEGORIESADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTCATEGORIESADD_ERROR:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Category
    case PRODUCTCATEGORIESEDIT_REQUEST:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: true },
      };
    case PRODUCTCATEGORIESEDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTCATEGORIESEDIT_ERROR:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Category
    case PRODUCTCATEGORIESDELETE_REQUEST:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: true },
      };
    case PRODUCTCATEGORIESDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTCATEGORIESDELETE_ERROR:
      return {
        ...state,
        productCategories: { ...state.productCategories, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // GET Tags
    case PRODUCTTAGS_REQUEST:
      return {
        ...state,
        productTags: { ...state.productTags, loading: true },
      };
    case PRODUCTTAGS_SUCCESS:
      return {
        ...state,
        productTags: { loading: false, list: action.payload },
      };
    case PRODUCTTAGS_ERROR:
      return {
        ...state,
        productTags: { ...state.productTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Tag
    case PRODUCTTAGSADD_REQUEST:
      return {
        ...state,
        productTags: { ...state.productTags, loading: true },
      };
    case PRODUCTTAGSADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTTAGSADD_ERROR:
      return {
        ...state,
        productTags: { ...state.productTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Tag
    case PRODUCTTAGSEDIT_REQUEST:
      return {
        ...state,
        productTags: { ...state.productTags, loading: true },
      };
    case PRODUCTTAGSEDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTTAGSEDIT_ERROR:
      return {
        ...state,
        productTags: { ...state.productTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Tag
    case PRODUCTTAGSDELETE_REQUEST:
      return {
        ...state,
        productTags: { ...state.productTags, loading: true },
      };
    case PRODUCTTAGSDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTTAGSDELETE_ERROR:
      return {
        ...state,
        productTags: { ...state.productTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Cell
    case PRODUCTCELLEDIT_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, loading: false, cellLoading: action.payload.cellId },
      };
    case PRODUCTCELLEDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTCELLEDIT_ERROR:
      return {
        ...state,
        productList: { ...state.productList, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Getting Filters
    case PRODUCTFILTERS_REQUEST:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: true },
      };
    case PRODUCTFILTERS_SUCCESS:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: false, list: action.payload.data },
      };
    case PRODUCTFILTERS_ERROR:
      return {
        ...state,
        productFilters: { ...state.productList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Adding Filter
    case PRODUCTFILTERADD_REQUEST:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: true },
      };
    case PRODUCTFILTERADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTFILTERADD_ERROR:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Editing Filter
    case PRODUCTFILTEREDIT_REQUEST:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: true },
      };
    case PRODUCTFILTEREDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTFILTEREDIT_ERROR:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Favoriting Filter
    case PRODUCTFILTERFAVORITE_REQUEST:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: true },
      };
    case PRODUCTFILTERFAVORITE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTFILTERFAVORITE_ERROR:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Deleting Filter
    case PRODUCTFILTERDELETE_REQUEST:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: true },
      };
    case PRODUCTFILTERDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTFILTERDELETE_ERROR:
      return {
        ...state,
        productFilters: { ...state.productFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case CURRENCY_REQUEST:
      return {
        ...state,
        currencyList: { ...state.currencyList, loading: true },
      };
    case CURRENCY_SUCCESS:
      return {
        ...state,
        currencyList: { loading: false, list: action.payload },
      };
    case CURRENCY_ERROR:
      return {
        ...state,
        currencyList: { ...state.currencyList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Merge
    case PRODUCTSMERGE_REQUEST:
      return {
        ...state,
        productList: { ...state.productList, loading: true },
      };
    case PRODUCTSMERGE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PRODUCTSMERGE_ERROR:
      return {
        ...state,
        productList: { ...state.productList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case APPLYFILTER:
      return {
        ...state,
        productFilters: { ...state.productFilters, activeFilter: action.payload },
      };
    case CLEARFILTER:
      return {
        ...state,
        productFilters: { ...state.productFilters, activeFilter: null },
      };
    default:
      return state;
  }
}
