import { getDealLossReasonActionById, editDealLossReasonAction, createDealLossReasonAction } from "../types";

export const getDealLossReasonActionByIdRequest = (payload, cb) => ({
  type: getDealLossReasonActionById.GET_DEAL_LOSS_REASON_BY_ID_ACTION_REQUEST,
  payload,
  cb,
});
export const getDealLossReasonActionByIdSuccess = (payload) => ({
  type: getDealLossReasonActionById.GET_DEAL_LOSS_REASON_BY_ID_ACTION_SUCCESS,
  payload,
});
export const getDealLossReasonActionByIdError = (payload) => ({
  type: getDealLossReasonActionById.GET_DEAL_LOSS_REASON_BY_ID_ACTION_ERROR,
  payload,
});

export const editDealLossReasonActionRequest = (payload, cb) => ({
  type: editDealLossReasonAction.EDIT_DEAL_LOSS_REASON_ACTION_REQUEST,
  payload,
  cb,
});
export const editDealLossReasonActionSuccess = (payload) => ({
  type: editDealLossReasonAction.EDIT_DEAL_LOSS_REASON_ACTION_SUCCESS,
  payload,
});
export const editDealLossReasonActionError = (payload) => ({
  type: editDealLossReasonAction.EDIT_DEAL_LOSS_REASON_ACTION_ERROR,
  payload,
});

export const createDealLossReasonActionRequest = (payload, cb) => ({
  type: createDealLossReasonAction.CREATE_DEAL_LOSS_REASON_ACTION_REQUEST,
  payload,
  cb,
});
export const createDealLossReasonActionSuccess = (payload) => ({
  type: createDealLossReasonAction.CREATE_DEAL_LOSS_REASON_ACTION_SUCCESS,
  payload,
});
export const createDealLossReasonActionError = (payload) => ({
  type: createDealLossReasonAction.CREATE_DEAL_LOSS_REASON_ACTION_ERROR,
  payload,
});
