import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ContentWrapper from "../../../Wrapper";

// components
import CampaignSubHeader from "../../components/campaign-common-sub-header";
import { BlueGradientButton } from "../../../../components/button";
import CustomForm from "../../../../components/custom_form";
import { ContentWithCardLoading } from "../../../../components/loading";
import { EditableTable } from "../../../../components/table";
import { ColumnFilter } from "../../../../components/column_filter";
import OwnerFilter from "../../../../components/filter/owner-filter";
import TagSelect from "../../../../components/custom_form/tags";
import OptionsDropdown from "../../../../components/filter/import-export-dropdown";
import SmartViewFilter from "../../../../components/filter/smart-view-filter";

// actions
import * as marketingListActions from "../../store/actions/campaign_marketing_list_actions";
import * as marketingDataFieldActions from "../../store/actions/marketing_data_field_actions";
import { useHistory, useParams } from "react-router-dom";
import SidePannelDetailed from "../../../../components/side-pannel-detailed";
import { DeleteModal } from "../../../../components/modal";
import { Button } from "react-bootstrap";
import SelectAll from "../../../../components/filter/select-all";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { SecondarySimpleDropdown } from "../../../../components/dropdowns/simple-dropdown";
import EmailValidationModel from "../../../../components/popup-forms/email-validation";

const filterEmptyData = {
  filterModule: "marketing",
  filterName: "",
  visibility: "Private",
  isSelectedColumnsSave: false,
  selectedColumns: [],
  isFavorite: false,
  andMatchConditions: [],
  orMatchConditions: [],
  pattern: "",
};

export default function CampaignMarketingList(props) {
  const tabName = "Marketing List";
  const page = "marketing";
  const pageSub = "marketing";
  const [showAddForm, setShowAddForm] = useState(false);
  const [addFormData, setAddFormData] = useState({});
  const [showExportModal, setShowExportModal] = useState(false);
  const [marketingId, setMarketingId] = useState("");
  const [openPanel, setOpenPanel] = useState(false);
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [addFromLoading, setAddFormLoading] = useState(false);

  // table part
  const [showColumns, setShowColumns] = useState([]);
  const [checked, setChecked] = useState([]);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const [sortConfig, setSortConfig] = useState(null);

  //filter
  const [addFilterFormData, setAddFilterFormData] = useState(filterEmptyData);

  // filter part
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(30);
  const [owner, setOwner] = useState("");
  const [tags, setTags] = useState([]);
  const [filterId, setFilterId] = useState("");
  const [virtualFilter, setVirtualFilter] = useState({});

  // delete all
  const [isAllSelected, setIsAllSelected] = useState(false);


  // email validation and email findr
  const [showEmailValidation, setShowEmailValidation] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const marketingDataFieldsStore = useSelector((state) => state.campaign.marketing.dataField.fields);
  const marketingAddFormStore = useSelector((state) => state.campaign.marketing.dataField.addForm);
  const marketingDataListStore = useSelector((store) => store.campaign.marketing.marketingList.list);
  const marketingLoadingDataStore = useSelector((store) => store.campaign.marketing.marketingList.loadingData);
  const filtersStore = useSelector((store) => store.campaign.marketing.marketingList.filters);

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      limit: limit,
      tagId: tags,
      owner: owner,
      filterId: filterId,
      ...sortKeys(),
    };
    if (!_.isEmpty(virtualFilter)) {
      payload["filter"] = JSON.stringify(virtualFilter);
    }

    dispatch(marketingListActions.getListOfMarketingListRequest(payload));
    dispatch(marketingListActions.loadingDataUpdateRequest(payload));
  }, [dispatch, pageNo, limit, sortKeys, filterId, virtualFilter, tags, owner]);

  useEffect(() => {
    dispatch(marketingDataFieldActions.getMarketingAddFormRequest({ fieldType: "MARKETING" }));
    dispatch(marketingDataFieldActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
    dispatch(marketingListActions.getSavedMarketingFiltersRequest({ type: "marketing" }));
    dispatch(marketingListActions.getListOfMarketingListTagsRequest({ type: "marketing" }));
  }, [dispatch]);

  useEffect(() => {
    const tempCol = marketingDataFieldsStore.list.filter((each) => each.isEnabledForTable).map((each) => each._id);
    setShowColumns(tempCol);
  }, [marketingDataFieldsStore.list]);

  useEffect(() => {
    if (Array.isArray(marketingDataListStore.data)) {
      const _data = marketingDataListStore.data.map((each) => {
        return { module: "marketing", id: each._id };
      });
      setNextPreviousData(_data);
    }
  }, [marketingDataListStore.data]);

  useEffect(() => {
    if (params && params.listId) {
      setMarketingId(params.listId);
      setOpenPanel(true);
    } else {
      setMarketingId("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("marketingListOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("marketingListOwner", owner);
    else localStorage.removeItem("marketingListOwner");
  }, [owner]);

  const handleAddClick = () => {
    setShowAddForm(true);
  };

  const handleAddMarktingList = (data) => {
    setAddFormLoading(true);
    dispatch(
      marketingListActions.addMarketingListRequest({ data: data, loadingData: marketingLoadingDataStore }, (data) => {
        if (data.status) {
          setShowAddForm(false);
          setAddFormData({});
          setAddFormLoading(false);
        } else {
          setAddFormLoading(false);
        }
      }),
    );
  };

  const addTag = (name, colorCode) => {
    dispatch(marketingListActions.createMarketingListTagsRequest({ name: name, colorCode: colorCode, type: "marketing" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(marketingListActions.updateMarketingListTagsRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(marketingListActions.deleteMarketingListTagsRequest(id));
  };

  const handleEditMarketingData = (combinedId, data) => {
    dispatch(
      marketingListActions.editMarketingListRequest({
        editData: data,
        loadingData: marketingLoadingDataStore,
        cellId: combinedId,
      }),
    );
  };

  // coloum filter actions
  const showHideField = (data) => {
    dispatch(marketingDataFieldActions.setMarketingFieldShowHideRequest(data));
  };

  const updateFieldPriority = (data) => {
    dispatch(marketingDataFieldActions.setMarketingTablePriorityRequest({ ...data, fieldType: "MARKETING" }));
  };

  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
  };

  const tableProps = {
    columns: marketingDataFieldsStore.list,
    data: marketingDataListStore.data,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: (a) => {
      setChecked(a);
      setIsAllSelected(false);
    },
    count: marketingDataListStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    idToIndex: marketingDataFieldsStore.idToIndex,
    handleCellEdit: handleEditMarketingData,
    page: page,
    pageSub: pageSub,
    cellLoading: marketingDataListStore.cellLoading,
    loading: marketingDataListStore.loading,

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    //for filter
    filterPayload: marketingLoadingDataStore,

    //side pannel
    sidePannelSelectedId: marketingId,

    // selection
    isAllListSelected: isAllSelected,
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
  };

  const addFilter = () => {
    if (addFilterFormData["isSelectedColumnsSave"]) {
      const temp = { ...addFilterFormData };
      temp["selectedColumns"] = showColumns;
      dispatch(
        marketingListActions.createSavedMarketingFiltersRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        marketingListActions.createSavedMarketingFiltersRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData(filterEmptyData);
  };

  const editFilter = () => {
    const temp = { ...addFilterFormData };
    temp["id"] = temp["_id"];

    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp["selectedColumns"] = showColumns;
    }
    dispatch(marketingListActions.editSavedMarketingFiltersRequest(temp));
    setAddFilterFormData(filterEmptyData);
  };

  const deleteFilter = (id) => {
    dispatch(marketingListActions.deleteSavedMarketingFiltersRequest(id));
    setAddFilterFormData(filterEmptyData);
  };

  const favoriteFilter = (data) => {
    dispatch(marketingListActions.markFavoriteMarketingFiltersRequest(data));
  };

  const handlePanelClose = () => {
    history.push(`/campaign/marketing/list`);
    setOpenPanel(false);
  };

  const handleDeleteLeads = () => {
    if (params.listId) {
      dispatch(
        marketingListActions.deleteMarketingListRequest({
          data: { ids: [params.listId] },
          loadingData: marketingLoadingDataStore,
        }),
      );
    } else if (isAllSelected && checked.length > 0) {
      dispatch(marketingListActions.bulkDeleteMarketingListRequest({ data: { ids: checked, filter: marketingLoadingDataStore, isAllSelected: true }, loadingData: marketingLoadingDataStore }));
      setChecked([]);
    } else {
      dispatch(
        marketingListActions.deleteMarketingListRequest({
          data: { ids: checked },
          loadingData: marketingLoadingDataStore,
        }),
      );
      setChecked([]);
    }

    setShowDelete(false);
    setOpenPanel(false);
    setMarketingId("");
  };

  const deleteModalProps = {
    show: showDelete,
    title: `Delete ${checked.length > 1 ? "leads" : "lead"}`,
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeleteLeads,
  };

  const isInitialEmpty = () => {
    if (!marketingDataListStore.loading && marketingDataListStore.count === 0 && _.isEmpty(tags) && _.isEmpty(owner) && _.isEmpty(virtualFilter) && _.isEmpty(filterId)) {
      return true;
    }
    return false;
  };

  const handleActionDropdownClick = (value) => {
    if (value.value === "emailValidation") {
      setShowEmailValidation(true);
    }
  }
  return (
    <ContentWrapper
      subHeader={
        <CampaignSubHeader active={tabName}>
          <div className="d-flex gap-4 align-items-center">
            {checked.length !== 0 ? (
              <div className="d-flex flex-center flex-row gap-4">
                <SelectAll
                  checked={checked}
                  count={marketingDataListStore.count}
                  limit={limit}
                  isAllSelected={isAllSelected}
                  selectAllClick={() => {
                    setIsAllSelected(true);
                    setChecked(marketingDataListStore.data.map((each) => each._id));
                  }}
                  clearAllClick={() => {
                    setIsAllSelected(false);
                    setChecked([]);
                  }}
                />
                <SecondarySimpleDropdown
                  value={{}}
                  label={"Actions"}
                  options={[
                    { value: "emailValidation", label: "Email Validation" },
                  ]}
                  handleSelect={handleActionDropdownClick}
                  disabled={false}
                />
                <Button variant="danger" size="sm" onClick={() => setShowDelete(true)}>
                  Delete
                </Button>
                <ColumnFilter
                  fieldType={"MARKETING"}
                  showHideField={showHideField}
                  updateFieldPriority={updateFieldPriority}
                  idToIndex={marketingDataFieldsStore.idToIndex}
                  columns={marketingDataFieldsStore.list}
                  showColumns={showColumns}
                  setShowColumns={setShowColumns}
                />
              </div>
            ) : isInitialEmpty() ? (
              <div className="d-flex flex-center flex-row gap-4">
                <BlueGradientButton variant="success" size="sm" onClick={() => handleAddClick()}>
                  + Marketing List
                </BlueGradientButton>
              </div>
            ) : (
              <div className="d-flex flex-center flex-row gap-4">
                <BlueGradientButton variant="success" size="sm" onClick={() => handleAddClick()}>
                  + Marketing List
                </BlueGradientButton>
                <SmartViewFilter
                  pageSub={pageSub}
                  page={page}
                  selectedFilterId={filterId}
                  addFilterFormData={addFilterFormData}
                  activeFilter={filtersStore.activeFilter}
                  customFilters={filtersStore.list}
                  loading={filtersStore.loading}
                  virtualFilter={virtualFilter}
                  setAddFilterFormData={setAddFilterFormData}
                  applyVirtualFilter={applyVirtualFilter}
                  favoriteFilter={favoriteFilter}
                  applyFilter={applyFilter}
                  addFilter={addFilter}
                  editFilter={editFilter}
                  deleteFilter={deleteFilter}
                />
                <div>
                  <TagSelect
                    page={page}
                    pageSub={pageSub}
                    addTag={addTag}
                    editTag={editTag}
                    deleteTag={deleteTag}
                    field={{
                      fieldName: "Tags",
                      apiKeyName: "tags",
                    }}
                    value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                    setValue={(value) => setValue(value)}
                    addTextHide={false}
                    hideAddText={true}
                    isUsedAsFilter={true}
                    iconBasedFilter={true}
                  />
                </div>
                <OwnerFilter setValue={setOwner} value={owner} />
                <OptionsDropdown
                  dataType={"marketing"}
                  importLabel={"Import Marketing"}
                  exportLabel={"Export Marketing"}
                  showColumns={showColumns}
                  showExportModal={showExportModal}
                  activeFilter={filtersStore.activeFilter}
                  appliedFilter={marketingLoadingDataStore}
                  setShowExportModal={setShowExportModal}
                />
                <ColumnFilter
                  fieldType={"MARKETING"}
                  showHideField={showHideField}
                  updateFieldPriority={updateFieldPriority}
                  idToIndex={marketingDataFieldsStore.idToIndex}
                  columns={marketingDataFieldsStore.list}
                  showColumns={showColumns}
                  setShowColumns={setShowColumns}
                />
              </div>
            )}
          </div>
        </CampaignSubHeader>
      }
    >
      {marketingDataListStore.loading ? (
        <ContentWithCardLoading />
      ) : isInitialEmpty() ? (
        <CustomEmptyPage page="marketingList" setShowAdd={handleAddClick} />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <EditableTable props={tableProps} />
          </div>
        </div>
      )}
      <CustomForm
        show={showAddForm}
        setShow={setShowAddForm}
        handleAdd={handleAddMarktingList}
        addTag={addTag}
        editTag={editTag}
        deleteTag={deleteTag}
        page={page}
        pageSub={pageSub}
        fields={marketingAddFormStore.list}
        customFormData={addFormData}
        setCustomFormData={setAddFormData}
        fieldsLoading={marketingAddFormStore.loading}
        propLoading={addFromLoading}
        dontClearData={true}
      />
      <SidePannelDetailed
        show={openPanel}
        basePath={`/campaign/marketing/list`}
        onClose={handlePanelClose}
        moduleName={"marketing"}
        moduleId={marketingId}
        nextPreviousData={nextPreviousData}
        pageForm={"marketing"}
        filter={marketingLoadingDataStore}
        handleDelete={() => setShowDelete(true)}
      />
      <DeleteModal modalProps={deleteModalProps}>
        Are you sure you want to delete the {checked.length > 1 ? "leads" : "lead"}?
        {isAllSelected && (
          <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
            In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be call or activated.
          </span>
        )}
      </DeleteModal>
      <EmailValidationModel
        show={showEmailValidation}
        setShow={setShowEmailValidation}
        isAllSelected={isAllSelected}
        moduleName="marketing_list"
        moduleIds={checked}
        loadingStore={marketingLoadingDataStore}
        onSuccess={() => {
          setChecked([]);
          setIsAllSelected(false);
        }}
      />
    </ContentWrapper>
  );
}
