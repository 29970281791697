export const customColorMapping = {
  // campaign channels
  sms: "info",
  whatsapp: "success",
  rcs: "danger",
  email: "primary",
  "short-link": "dark",

  // campaign status
  submitted: "info",
  submited: "info",
  failed: "danger",
  scheduled: "primary",
  draft: "dark",
  sent: "primary",
  completed: "success",
  running: "primary",
  proccessing: "primary",
  sending: "primary",

  // common
  active: "success",

  // email status
  opened: "info",
  bounce: "danger",
  clicked: "info",
  unsubscribed: "warning",
  complaint: "dark",

  inr: "info",
  usd: "info",

  Completed: "success",
  Failed: "danger",
  "In Queue": "info",

  "user approved": "success",
  approved: "success",
  pending: "info",
  rejected: "danger",

  //sms
  delivered: "success",
  undelivered: "warning",

  // cadence
  paused: "dark",
  finished: "primary",
};
