import { combineReducers } from "redux";
import campaignListReducer from "./campaign_list_reducer";
import campaignEmailSettingsReducer from "./campaign_email_settings_reducer";
import campaignTemplateReducer from "./campaign_templates_reducer";
import campaignDetailsByIdReducer from "./campaign_details_by_id_reducer";
import marketingDataFieldReducer from "./marketing_data_field_reducer";
import campaignMarketingReducer from "./campaign_marketing_list_reducer";
import campaignPricingReducer from "./campign_pricing_reducer";
import marketingLogReducer from "./marketing_log_reducer";
import campaignSMSSettingsReducer from "./campaign_sms_settings_reducer";
import campaignShortLinkReducer from "./campaign_short_link_reducer";
import campaignBlockContactReducer from "./campaign_block_contact_reducer";

// reports reducers
import campaignEmailReducer from "./reports/email_report_reducer";

// whastapp
import campaignWhatsappTemplateReducer from "./whatsapp/campaign_whatsapp_template_reducer";
import campaignWhatsappSettingsReducer from "./whatsapp/campaign_whatsapp_setting_reducer";

const marketingModuleReducer = combineReducers({
  marketingList: campaignMarketingReducer,
  dataField: marketingDataFieldReducer,
  log: marketingLogReducer,
});

const settingsModuleReducer = combineReducers({
  email: campaignEmailSettingsReducer,
  pricing: campaignPricingReducer,
  sms: campaignSMSSettingsReducer,
  whatsapp: campaignWhatsappSettingsReducer,
});

const reportModuleReducer = combineReducers({
  email: campaignEmailReducer,
});

const campaignModuleReducer = combineReducers({
  list: campaignListReducer,
  details: campaignDetailsByIdReducer,
  settings: settingsModuleReducer,
  templates: campaignTemplateReducer,
  marketing: marketingModuleReducer,
  reports: reportModuleReducer,
  shortLink: campaignShortLinkReducer,
  blockContact: campaignBlockContactReducer,
  whatsappTemplate: campaignWhatsappTemplateReducer
});

export default campaignModuleReducer;
