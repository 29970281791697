export const COMPANIES_REQUEST = "COMPANIES_REQUEST";
export const COMPANIES_SUCCESS = "COMPANIES_SUCCESS";
export const COMPANIES_ERROR = "COMPANIES_ERROR";

export const COMPANIES_UPDATE_REQUEST = "COMPANIES_UPDATE_REQUEST";
export const COMPANIES_UPDATE_SUCCESS = "COMPANIES_UPDATE_SUCCESS";
export const COMPANIES_UPDATE_ERROR = "COMPANIES_UPDATE_ERROR";

export const LOADING_DATA_UPDATE = "COMPANY_LOADING_DATA_UPDATE_REQUEST";

export const FIELD_UPDATE_REQUEST = "COMPANY_FIELD_UPDATE_REQUEST";
export const FIELD_UPDATE_SUCCESS = "COMPANY_FIELD_UPDATE_SUCCESS";
export const FIELD_UPDATE_ERROR = "COMPANY_FIELD_UPDATE_ERROR";

export const FIELD_SHOWHIDE_REQUEST = "COMPANY_FIELD_SHOWHIDE_REQUEST";
export const FIELD_SHOWHIDE_SUCCESS = "COMPANY_FIELD_SHOWHIDE_SUCCESS";
export const FIELD_SHOWHIDE_ERROR = "COMPANY_FIELD_SHOWHIDE_ERROR";

export const FIELD_UPDATEPRIORITY_REQUEST = "COMPANY_FIELD_UPDATEPRIORITY_REQUEST";
export const FIELD_UPDATEPRIORITY_SUCCESS = "COMPANY_FIELD_UPDATEPRIORITY_SUCCESS";
export const FIELD_UPDATEPRIORITY_ERROR = "COMPANY_FIELD_UPDATEPRIORITY_ERROR";

export const COMPANY_POPUP_REQUEST = "COMPANY_POPUP_REQUEST";
export const COMPANY_POPUP_SUCCESS = "COMPANY_POPUP_SUCCESS";
export const COMPANY_POPUP_ERROR = "COMPANY_POPUP_ERROR";

export const COMPANY_POPUP_EDIT_REQUEST = "COMPANY_POPUP_EDIT_REQUEST";
export const COMPANY_POPUP_EDIT_SUCCESS = "COMPANY_POPUP_EDIT_SUCCESS";
export const COMPANY_POPUP_EDIT_ERROR = "COMPANY_POPUP_EDIT_ERROR";

export const COMPANY_POPUP_UPDATE_REQUEST = "COMPANY_POPUP_UPDATE_REQUEST";
export const COMPANY_POPUP_UPDATE_SUCCESS = "COMPANY_POPUP_UPDATE_SUCCESS";
export const COMPANY_POPUP_UPDATE_ERROR = "COMPANY_POPUP_UPDATE_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const COMPANIESDROPDOWN_REQUEST = "COMPANIESDROPDOWN_REQUEST";
export const COMPANIESDROPDOWN_SUCCESS = "COMPANIESDROPDOWN_SUCCESS";
export const COMPANIESDROPDOWN_ERROR = "COMPANIESDROPDOWN_ERROR";

export const COMPANYADD_REQUEST = "COMPANYADD_REQUEST";
export const COMPANYADD_SUCCESS = "COMPANYADD_SUCCESS";
export const COMPANYADD_ERROR = "COMPANYADD_ERROR";

export const COMPANIESDROPDOWNADD_REQUEST = "COMPANIESDROPDOWNADD_REQUEST";
export const COMPANIESDROPDOWNADD_SUCCESS = "COMPANIESDROPDOWNADD_SUCCESS";
export const COMPANIESDROPDOWNADD_ERROR = "COMPANIESDROPDOWNADD_ERROR";
export const COMPANIESDROPDOWNADD_CLEAR = "COMPANIESDROPDOWNADD_CLEAR";

export const COMPANYBULKEDIT_REQUEST = "COMPANYBULKEDIT_REQUEST";
export const COMPANYBULKEDIT_SUCCESS = "COMPANYBULKEDIT_SUCCESS";
export const COMPANYBULKEDIT_ERROR = "COMPANYBULKEDIT_ERROR";

export const COMPANYDELETE_REQUEST = "COMPANYDELETE_REQUEST";
export const COMPANYDELETE_SUCCESS = "COMPANYDELETE_SUCCESS";
export const COMPANYDELETE_ERROR = "COMPANYDELETE_ERROR";

export const COMPANYFIELDS_REQUEST = "COMPANYFIELDS_REQUEST";
export const COMPANYFIELDS_SUCCESS = "COMPANYFIELDS_SUCCESS";
export const COMPANYFIELDS_ERROR = "COMPANYFIELDS_ERROR";

export const COMPANYADDFIELDS_REQUEST = "COMPANYADDFIELDS_REQUEST";
export const COMPANYADDFIELDS_SUCCESS = "COMPANYADDFIELDS_SUCCESS";
export const COMPANYADDFIELDS_ERROR = "COMPANYADDFIELDS_ERROR";

export const COMPANYTAGS_REQUEST = "COMPANYTAGS_REQUEST";
export const COMPANYTAGS_SUCCESS = "COMPANYTAGS_SUCCESS";
export const COMPANYTAGS_ERROR = "COMPANYTAGS_ERROR";

export const COMPANYTAGSADD_REQUEST = "COMPANYTAGSADD_REQUEST";
export const COMPANYTAGSADD_SUCCESS = "COMPANYTAGSADD_SUCCESS";
export const COMPANYTAGSADD_ERROR = "COMPANYTAGSADD_ERROR";

export const COMPANYTAGSEDIT_REQUEST = "COMPANYTAGSEDIT_REQUEST";
export const COMPANYTAGSEDIT_SUCCESS = "COMPANYTAGSEDIT_SUCCESS";
export const COMPANYTAGSEDIT_ERROR = "COMPANYTAGSEDIT_ERROR";

export const COMPANYTAGSDELETE_REQUEST = "COMPANYTAGSDELETE_REQUEST";
export const COMPANYTAGSDELETE_SUCCESS = "COMPANYTAGSDELETE_SUCCESS";
export const COMPANYTAGSDELETE_ERROR = "COMPANYTAGSDELETE_ERROR";

export const COMPANYCELLEDIT_REQUEST = "COMPANYCELLEDIT_REQUEST";
export const COMPANYCELLEDIT_SUCCESS = "COMPANYCELLEDIT_SUCCESS";
export const COMPANYCELLEDIT_ERROR = "COMPANYCELLEDIT_ERROR";

export const COMPANYFILTERS_REQUEST = "COMPANYFILTERS_REQUEST";
export const COMPANYFILTERS_SUCCESS = "COMPANYFILTERS_SUCCESS";
export const COMPANYFILTERS_ERROR = "COMPANYFILTERS_ERROR";

export const COMPANYFILTERADD_REQUEST = "COMPANYFILTERADD_REQUEST";
export const COMPANYFILTERADD_SUCCESS = "COMPANYFILTERADD_SUCCESS";
export const COMPANYFILTERADD_ERROR = "COMPANYFILTERADD_ERROR";

export const COMPANYFILTEREDIT_REQUEST = "COMPANYFILTEREDIT_REQUEST";
export const COMPANYFILTEREDIT_SUCCESS = "COMPANYFILTEREDIT_SUCCESS";
export const COMPANYFILTEREDIT_ERROR = "COMPANYFILTEREDIT_ERROR";

export const COMPANYFILTERFAVORITE_REQUEST = "COMPANYFILTERFAVORITE_REQUEST";
export const COMPANYFILTERFAVORITE_SUCCESS = "COMPANYFILTERFAVORITE_SUCCESS";
export const COMPANYFILTERFAVORITE_ERROR = "COMPANYFILTERFAVORITE_ERROR";

export const COMPANYFILTERDELETE_REQUEST = "COMPANYFILTERDELETE_REQUEST";
export const COMPANYFILTERDELETE_SUCCESS = "COMPANYFILTERDELETE_SUCCESS";
export const COMPANYFILTERDELETE_ERROR = "COMPANYFILTERDELETE_ERROR";

export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";
export const COUNTRIES_ERROR = "COUNTRIES_ERROR";

export const TIMEZONES_REQUEST = "TIMEZONES_REQUEST";
export const TIMEZONES_SUCCESS = "TIMEZONES_SUCCESS";
export const TIMEZONES_ERROR = "TIMEZONES_ERROR";

export const COMPANIESMERGE_REQUEST = "COMPANIESMERGE_REQUEST";
export const COMPANIESMERGE_SUCCESS = "COMPANIESMERGE_SUCCESS";
export const COMPANIESMERGE_ERROR = "COMPANIESMERGE_ERROR";

export const APPLYFILTER = "APPLYCOMPANYFILTER";
export const CLEARFILTER = "CLEARCOMPANYFILTER";

export const LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST = "LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST";
export const LOADMORELINKEDDATA_FORSELECTEDCOMPANY_SUCCESS = "LOADMORELINKEDDATA_FORSELECTEDCOMPANY_SUCCESS";
export const LOADMORELINKEDDATA_FORSELECTEDCOMPANY_ERROR = "LOADMORELINKEDDATA_FORSELECTEDCOMPANY_ERROR";

export const SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST = "SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST";
export const SEARCH_UNLINKEDDATA_FORCOMPANY_SUCCESS = "SEARCH_UNLINKEDDATA_FORCOMPANY_SUCCESS";
export const SEARCH_UNLINKEDDATA_FORCOMPANY_ERROR = "SEARCH_UNLINKEDDATA_FORCOMPANY_ERROR";

export const LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST = "LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST";
export const LINK_LEADDEALPEOPLE_WITHCOMPANY_SUCCESS = "LINK_LEADDEALPEOPLE_WITHCOMPANY_SUCCESS";
export const LINK_LEADDEALPEOPLE_WITHCOMPANY_ERROR = "LINK_LEADDEALPEOPLE_WITHCOMPANY_ERROR";

export const UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST = "UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST";
export const UNLINK_LEADDEALPEOPLE_FROMCOMPANY_SUCCESS = "UNLINK_LEADDEALPEOPLE_FROMCOMPANY_SUCCESS";
export const UNLINK_LEADDEALPEOPLE_FROMCOMPANY_ERROR = "UNLINK_LEADDEALPEOPLE_FROMCOMPANY_ERROR";

export const COMPANY_POPUP_REQUEST_AFTER = "COMPANY_POPUP_REQUEST_AFTER";


export const generateCompanyAIWFData = {
    GENERATE_COMPANY_AIWF_DATA_REQUEST: "GENERATE_COMPANY_AIWF_DATA_REQUEST",
    GENERATE_COMPANY_AIWF_DATA_SUCCESS: "GENERATE_COMPANY_AIWF_DATA_SUCCESS",
    GENERATE_COMPANY_AIWF_DATA_ERROR: "GENERATE_COMPANY_AIWF_DATA_ERROR"
}