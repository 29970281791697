import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../utils/toaster";

import api from "../../../network/apis/api";
import * as syncActions from "../actions/sync.actions";
import * as ActivityListActions from "../../Activities/store/actions/activitity_list_actions";

import {
  //email sync page
  HOSTED_EMAIL_AUTH_REQUEST,
  HOSTED_EMAIL_AUTH_CALLBACK_REQUEST,
  GET_EMAIL_SYNC_DETAILS_REQUEST,
  UPDATE_EMAIL_SYNC_DETAILS_REQUEST,
  GET_EMAIL_FOLDER_LABELS_REQUEST,
  ADD_EMAIL_IN_IGNORE_LIST_REQUEST,
  REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST,
  GET_COMPOSER_TOKENS_REQUEST,
  DELETE_EMAIL_ACCOUNT_REQUEST,
  DISCONNECT_EMAIL_ACCOUNT_REQUEST,
  RECONNECT_EMAIL_ACCOUNT_REQUEST,
  SEND_EMAIL_ID_TO_BACKEND_REQUEST,

  //calendar sync page
  HOSTED_CALENDAR_AUTH_REQUEST,
  HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST,
  GET_CALENDAR_SYNC_DETAILS_REQUEST,
  UPDATE_CALENDAR_SYNC_DETAILS_REQUEST,
  GET_AGENDA_TOKENS_REQUEST,
  DELETE_CALENDAR_ACCOUNT_REQUEST,
  DISCONNECT_CALENDAR_ACCOUNT_REQUEST,
  RECONNECT_CALENDAR_ACCOUNT_REQUEST,
  GET_LIST_OF_CALENDARS_REQUEST,

  //composer
  UPLOAD_ATTACHMENT_IN_NYLAS_REQUEST,
  SEND_NYLAS_EMAIL_REQUEST,
  GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER,
} from "../types/sync.types";

/********************************************* Email Sync Settings ***********************************************/

function* hostedEmailAuth(action) {
  try {
    const response = yield call(api.get, "/api/crm/email-sync-settings/hosted-auth", action.payload);
    if (response.status === 200) {
      yield put(syncActions.hostedEmailAuthSuccess(response.data));
    } else yield put(syncActions.hostedEmailAuthError("error"));
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(syncActions.hostedEmailAuthError(e));
  }
}

function* hostedEmailCallbackAuth(action) {
  try {
    const response = yield call(api.post, "/api/crm/email-sync-settings/hosted-auth-callback", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(syncActions.hostedEmailAuthCallbackSuccess(response.data));
    } else if (response.status === 400) {
      yield action.cb(response.data);
      yield put(syncActions.hostedEmailAuthCallbackSuccess(response.data));
    } else yield put(syncActions.hostedEmailAuthCallbackError("error"));
  } catch (e) {
    yield put(syncActions.hostedEmailAuthCallbackError(e));
  }
}

function* getEmailSyncDetails(action) {
  try {
    const response = yield call(api.get, "/api/crm/email-sync-settings/get-account-details", action.payload);
    if (response.status === 200) yield put(syncActions.getEmailSyncDetailsSuccess(response.data));
    else yield put(syncActions.getEmailSyncDetailsError("error"));
  } catch (e) {
    yield put(syncActions.getEmailSyncDetailsError(e));
  }
}

function* updateEmailSyncDetails(action) {
  try {
    const response = yield call(api.patch, "/api/crm/email-sync-settings/update-settings", action.payload);
    if (response.status === 200) {
      yield put(syncActions.updateEmailSyncDetailsSuccess(response.data));
      yield put(syncActions.getEmailSyncDetailsRequest());
    } else yield put(syncActions.updateEmailSyncDetailsError("error"));
  } catch (e) {
    yield put(syncActions.updateEmailSyncDetailsError(e));
  }
}

function* getEmailFolderLabels(action) {
  try {
    const response = yield call(api.get, "/api/crm/email-sync-settings/get-email-folder-labels", action.payload);
    if (response.status === 200) yield put(syncActions.getEmailFolderLabelSuccess(response.data.data));
    else yield put(syncActions.getEmailFolderLabelError("error"));
  } catch (e) {
    yield put(syncActions.getEmailFolderLabelError(e));
  }
}

function* addEmailInIgnoreList(action) {
  try {
    const response = yield call(api.patch, "/api/crm/email-sync-settings/add-ignore-address", action.payload);
    if (response.status === 200) {
      yield put(syncActions.addEmailInIgnoreListSuccess(response.data));
      yield put(syncActions.getEmailSyncDetailsRequest());
      dispatchSnackbarSuccess(response.data.message);
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
    } else {
      yield put(syncActions.addEmailInIgnoreListError("error"));
      dispatchSnackbarError(response.data);
    }
  } catch (e) {
    yield put(syncActions.addEmailInIgnoreListError(e));
  }
}

function* removeEmailFromIgnoreList(action) {
  try {
    const response = yield call(api.patch, "/api/crm/email-sync-settings/remove-ignore-address", action.payload);
    if (response.status === 200) {
      yield put(syncActions.removeEmailFromIgnoreListSuccess(response.data));
      yield put(syncActions.getEmailSyncDetailsRequest());
      dispatchSnackbarSuccess(response.data.message);
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
    } else {
      yield put(syncActions.removeEmailFromIgnoreListError("error"));
      dispatchSnackbarError(response.data.message);
    }
  } catch (e) {
    yield put(syncActions.removeEmailFromIgnoreListError(e));
  }
}

function* getComposerToken(action) {
  try {
    const response = yield call(api.get, "/api/crm/email-sync-settings/composer", action.payload);
    if (response.status === 200) yield put(syncActions.getComposerTokenSuccess(response.data));
    else yield put(syncActions.getComposerTokenError("error"));
  } catch (e) {
    yield put(syncActions.getComposerTokenError(e));
  }
}

function* deleteEmailAccount(action) {
  try {
    const response = yield call(api.delete, "/api/crm/email-sync-settings/delete-account", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(syncActions.deleteEmailSyncAccountSuccess(response.data));
      yield put(syncActions.getEmailSyncDetailsRequest());
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(syncActions.deleteEmailSyncAccountError("error"));
    }
  } catch (e) {
    yield put(syncActions.deleteEmailSyncAccountError(e));
  }
}

function* reconnectEmailAccount(action) {
  try {
    const response = yield call(api.patch, "/api/crm/email-sync-settings/reconnect", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(syncActions.reconnectEmailSyncAccountSuccess(response.data));
      yield put(syncActions.getEmailSyncDetailsRequest());
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(syncActions.reconnectEmailSyncAccountError("error"));
    }
  } catch (e) {
    yield put(syncActions.reconnectEmailSyncAccountError(e));
  }
}

function* disconnectEmailAccount(action) {
  try {
    const response = yield call(api.patch, "/api/crm/email-sync-settings/disconnect", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(syncActions.disconnectEmailSyncAccountSuccess(response.data));
      yield put(syncActions.getEmailSyncDetailsRequest());
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(syncActions.disconnectEmailSyncAccountError("error"));
    }
  } catch (e) {
    yield put(syncActions.disconnectEmailSyncAccountError(e));
  }
}

function* sendEmailIdToBackend(action) {
  try {
    const response = yield call(api.post, "/api/crm/email-sync-settings/send-email", action.payload);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message)
      yield put(syncActions.sendEmailIDtoBackendSuccess(response.data));
    } else {
      // dispatchSnackbarError(response.data.message)
      yield put(syncActions.sendEmailIDtoBackendError("error"));
    }
  } catch (e) {
    yield put(syncActions.sendEmailIDtoBackendError(e));
  }
}

function* sendNylasEmail(action) {
  try {
    const response = yield call(api.post, "/api/crm/sync-settings/nylas-send-email", action.payload.data);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(syncActions.sendNylasEmailSuccess(response.data));
      if (action.payload.pageForm === "activityList" && action.payload.data.cadenceLogId && action.payload.data.activityId) {
        yield put(ActivityListActions.getListOfActivtiesRequestAfter(action.payload.filter));
      }
    } else if (response.status === 400) {
      yield action.cb(response.data);
      yield put(syncActions.sendNylasEmailError("error"));
    } else {
      // dispatchSnackbarError(response.data.message)
      yield put(syncActions.sendNylasEmailError("error"));
    }
  } catch (e) {
    yield put(syncActions.sendNylasEmailError(e));
  }
}

function* uploadNylasEmailAttachment(action) {
  try {
    const response = yield call(api.post, "/api/crm/sync-settings/upload-nylas-attachement", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      // dispatchSnackbarSuccess(response.data.message)
      yield put(syncActions.uploadAttachmentInNylasSuccess(response.data));
    } else if (response.status === 400) {
      yield action.cb(response.data);
      yield put(syncActions.uploadAttachmentInNylasError("error"));
    } else {
      // dispatchSnackbarError(response.data.message)
      yield put(syncActions.uploadAttachmentInNylasError("error"));
    }
  } catch (e) {
    yield put(syncActions.uploadAttachmentInNylasError(e));
  }
}

/********************************************* Calendar Sync Settings ***********************************************/

function* hostedCalendarAuth(action) {
  try {
    const response = yield call(api.get, "/api/crm/calendar-sync-settings/hosted-auth", action.payload);
    if (response.status === 200) {
      yield put(syncActions.hostedCalendarAuthSuccess(response.data));
    } else yield put(syncActions.hostedCalendarAuthError("error"));
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(syncActions.hostedCalendarAuthError(e));
  }
}

function* hostedCalendarCallbackAuth(action) {
  try {
    const response = yield call(api.post, "/api/crm/calendar-sync-settings/hosted-auth-callback", action.payload);
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(syncActions.hostedCalendarAuthCallbackSuccess(response.data));
    } else yield put(syncActions.hostedCalendarAuthCallbackError("error"));
  } catch (e) {
    yield put(syncActions.hostedCalendarAuthCallbackError(e));
  }
}

function* getCalendarSyncDetails(action) {
  try {
    const response = yield call(api.get, "/api/crm/calendar-sync-settings/get-account-details", action.payload);
    if (response.status === 200) yield put(syncActions.getCalendarSyncDetailsSuccess(response.data));
    else yield put(syncActions.getCalendarSyncDetailsError("error"));
  } catch (e) {
    yield put(syncActions.getCalendarSyncDetailsError(e));
  }
}

function* updateCalendarSyncDetails(action) {
  try {
    const response = yield call(api.patch, "/api/crm/calendar-sync-settings/update-settings", action.payload);
    if (response.status === 200) {
      yield put(syncActions.getCalendarSyncDetailsRequestAfter());
      yield put(syncActions.updateCalendarSyncDetailsSuccess(response.data));
    } else yield put(syncActions.updateCalendarSyncDetailsError("error"));
  } catch (e) {
    yield put(syncActions.updateCalendarSyncDetailsError(e));
  }
}

function* getAgendaToken(action) {
  try {
    const response = yield call(api.get, "/api/crm/calendar-sync-settings/agenda", action.payload);
    if (response.status === 200) yield put(syncActions.getAgendaTokenSuccess(response.data));
    else yield put(syncActions.getAgendaTokenError("error"));
  } catch (e) {
    yield put(syncActions.getAgendaTokenError(e));
  }
}

function* deleteCalendarAccount(action) {
  try {
    const response = yield call(api.delete, "/api/crm/calendar-sync-settings/delete-account", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(syncActions.getCalendarSyncDetailsRequest());
      yield put(syncActions.deleteCalendarSyncAccountSuccess(response.data));
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(syncActions.deleteCalendarSyncAccountError("error"));
    }
  } catch (e) {
    yield put(syncActions.deleteCalendarSyncAccountError(e));
  }
}

function* reconnectCalendarAccount(action) {
  try {
    const response = yield call(api.patch, "/api/crm/calendar-sync-settings/reconnect", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(syncActions.getCalendarSyncDetailsRequest());
      yield put(syncActions.reconnectCalendarSyncAccountSuccess(response.data));
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(syncActions.reconnectCalendarSyncAccountError("error"));
    }
  } catch (e) {
    yield put(syncActions.reconnectCalendarSyncAccountError(e));
  }
}

function* disconnectCalendarAccount(action) {
  try {
    const response = yield call(api.patch, "/api/crm/calendar-sync-settings/disconnect", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(syncActions.getCalendarSyncDetailsRequest());
      yield put(syncActions.disconnectCalendarSyncAccountSuccess(response.data));
    } else {
      dispatchSnackbarError(response.data.message);
      yield put(syncActions.disconnectCalendarSyncAccountError("error"));
    }
  } catch (e) {
    yield put(syncActions.disconnectCalendarSyncAccountError(e));
  }
}

function* getListOfCalendars(action) {
  try {
    const response = yield call(api.get, "/api/crm/calendar-sync-settings/calender-list", action.payload);
    if (response.status === 200) yield put(syncActions.getListOfCalendersSuccess(response.data.data));
    else yield put(syncActions.getListOfCalendersError("error"));
  } catch (e) {
    yield put(syncActions.getListOfCalendersError(e));
  }
}

export default function*() {
  //email sync saga
  yield takeLatest(HOSTED_EMAIL_AUTH_REQUEST, hostedEmailAuth);
  yield takeLatest(HOSTED_EMAIL_AUTH_CALLBACK_REQUEST, hostedEmailCallbackAuth);
  yield takeLatest(GET_EMAIL_SYNC_DETAILS_REQUEST, getEmailSyncDetails);
  yield takeLatest(UPDATE_EMAIL_SYNC_DETAILS_REQUEST, updateEmailSyncDetails);
  yield takeLatest(GET_EMAIL_FOLDER_LABELS_REQUEST, getEmailFolderLabels);
  yield takeLatest(ADD_EMAIL_IN_IGNORE_LIST_REQUEST, addEmailInIgnoreList);
  yield takeLatest(REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST, removeEmailFromIgnoreList);
  yield takeLatest(GET_COMPOSER_TOKENS_REQUEST, getComposerToken);
  yield takeLatest(DELETE_EMAIL_ACCOUNT_REQUEST, deleteEmailAccount);
  yield takeLatest(DISCONNECT_EMAIL_ACCOUNT_REQUEST, disconnectEmailAccount);
  yield takeLatest(RECONNECT_EMAIL_ACCOUNT_REQUEST, reconnectEmailAccount);
  yield takeLatest(SEND_EMAIL_ID_TO_BACKEND_REQUEST, sendEmailIdToBackend);

  //calendar sync saga
  yield takeLatest(HOSTED_CALENDAR_AUTH_REQUEST, hostedCalendarAuth);
  yield takeLatest(HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST, hostedCalendarCallbackAuth);
  yield takeLatest(GET_CALENDAR_SYNC_DETAILS_REQUEST, getCalendarSyncDetails);
  yield takeLatest(GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER, getCalendarSyncDetails);
  yield takeLatest(UPDATE_CALENDAR_SYNC_DETAILS_REQUEST, updateCalendarSyncDetails);
  yield takeLatest(GET_AGENDA_TOKENS_REQUEST, getAgendaToken);
  yield takeLatest(DELETE_CALENDAR_ACCOUNT_REQUEST, deleteCalendarAccount);
  yield takeLatest(DISCONNECT_CALENDAR_ACCOUNT_REQUEST, disconnectCalendarAccount);
  yield takeLatest(RECONNECT_CALENDAR_ACCOUNT_REQUEST, reconnectCalendarAccount);
  yield takeLatest(GET_LIST_OF_CALENDARS_REQUEST, getListOfCalendars);

  //composar
  yield takeLatest(UPLOAD_ATTACHMENT_IN_NYLAS_REQUEST, uploadNylasEmailAttachment);
  yield takeLatest(SEND_NYLAS_EMAIL_REQUEST, sendNylasEmail);
}
