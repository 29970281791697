// import React, {useMemo} from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
// import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";

import React, { useMemo, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { modulesNamesAndPath } from "./constants";
import { HeaderCenterMenu } from "./header-menu/HeaderCenterMenu";
// import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";

export function Header() {
  const [title, setTitle] = useState("");
  const location = useLocation();

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      /*********************** For Sub-Header ****************************/
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),

      /*********************** For Header ****************************/

      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(uiService.config, "header.menu.self.display"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    for (let i = 0; i < modulesNamesAndPath.length; i++) {
      if (location.pathname.includes(modulesNamesAndPath[i].includePath)) {
        setTitle(modulesNamesAndPath[i].name);
        break;
      }
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <div className={`box-shadow-1 header ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          <AnimateLoading />

          <div className="d-flex align-items-center flex-wrap mr-1">
            {layoutProps.subheaderMobileToggle && (
              <button className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
                <span />
              </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5 width-max-content">
                <>{title}</>
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center w-100">
            <HeaderCenterMenu />
            {/* <QuickActionsDropdown /> */}
          </div>
          {/* {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />} */}
          <Topbar />
        </div>
      </div>
    </>
  );
}
