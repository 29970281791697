import {
  GET_ACTIVITIES_LIST_REQUEST,
  GET_ACTIVITIES_LIST_SUCCESS,
  GET_ACTIVITIES_LIST_ERROR,
  LEADS_DROPDOWN_REQUEST,
  LEADS_DROPDOWN_SUCCESS,
  LEADS_DROPDOWN_ERROR,
  LEADS_DROPDOWNADD_REQUEST,
  LEADS_DROPDOWNADD_SUCCESS,
  LEADS_DROPDOWNADD_ERROR,
  LEADS_DROPDOWNADD_CLEAR,
  FIELD_SHOWHIDE_REQUEST,
  FIELD_SHOWHIDE_SUCCESS,
  FIELD_SHOWHIDE_ERROR,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_UPDATEPRIORITY_SUCCESS,
  FIELD_UPDATEPRIORITY_ERROR,
  GET_MASAVEDFILTERS_REQUEST,
  GET_MASAVEDFILTERS_SUCCESS,
  GET_MASAVEDFILTERS_ERROR,
  ADD_MASAVEDFILTERS_REQUEST,
  ADD_MASAVEDFILTERS_SUCCESS,
  ADD_MASAVEDFILTERS_ERROR,
  EDIT_MASAVEDFILTERS_REQUEST,
  EDIT_MASAVEDFILTERS_SUCCESS,
  EDIT_MASAVEDFILTERS_ERROR,
  DELETE_MASAVEDFILTERS_REQUEST,
  DELETE_MASAVEDFILTERS_SUCCESS,
  DELETE_MASAVEDFILTERS_ERROR,
  FAVORITE_MASAVEDFILTERS_REQUEST,
  FAVORITE_MASAVEDFILTERS_SUCCESS,
  FAVORITE_MASAVEDFILTERS_ERROR,
  MA_APPLYFILTER,
  MA_CLEARFILTER,
  BULK_EDIT_REQUEST,
  BULK_EDIT_SUCCESS,
  BULK_EDIT_ERROR,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_ERROR,
  MARKAS_UNDONE_REQUEST,
  MARKAS_UNDONE_SUCCESS,
  MARKAS_UNDONE_ERROR,
  GET_ACTIVITIES_LIST_REQUEST_WITHOUT_LOADING,
  GET_USER_ACTIVITYLOGS_REQUEST,
  GET_USER_ACTIVITYLOGS_SUCCESS,
  GET_USER_ACTIVITYLOGS_ERROR,
  LOADING_ACTIVITY_DATA_UPDATE,
} from "./types";

//GET USER LOGS

export function getUserActivityLogsRequest(req, cb) {
  return {
    type: GET_USER_ACTIVITYLOGS_REQUEST,
    payload: req,
    cb,
  };
}

export function getUserActivityLogsSuccess(res) {
  return {
    type: GET_USER_ACTIVITYLOGS_SUCCESS,
    payload: res,
  };
}
export function getUserActivityLogsError(err) {
  return {
    type: GET_USER_ACTIVITYLOGS_ERROR,
    payload: err,
  };
}

//MARK ASUNDONE

export function markAsUndoneRequest(req, cb) {
  return {
    type: MARKAS_UNDONE_REQUEST,
    payload: req,
    cb,
  };
}
export function markAsUndoneSuccess(res) {
  return {
    type: MARKAS_UNDONE_SUCCESS,
    payload: res,
  };
}
export function markAsUndoneError(err) {
  return {
    type: MARKAS_UNDONE_ERROR,
    payload: err,
  };
}

//BULK_DELETE
export function bulkDeleteRequest(req) {
  return {
    type: BULK_DELETE_REQUEST,
    payload: req,
  };
}
export function bulkDeleteSuccess(res) {
  return {
    type: BULK_DELETE_SUCCESS,
    payload: res,
  };
}
export function bulkDeleteError(err) {
  return {
    type: BULK_DELETE_ERROR,
    payload: err,
  };
}

//BULK EDIT
export function bulkEditRequest(req) {
  return {
    type: BULK_EDIT_REQUEST,
    payload: req,
  };
}
export function bulkEditSuccess(res) {
  return {
    type: BULK_EDIT_SUCCESS,
    payload: res,
  };
}
export function bulkEditError(err) {
  return {
    type: BULK_EDIT_ERROR,
    payload: err,
  };
}

export function applyFilter(res) {
  return {
    type: MA_APPLYFILTER,
    payload: res,
  };
}

export function clearFilter(res) {
  return {
    type: MA_CLEARFILTER,
    payload: res,
  };
}

export function getSavedFiltersRequest(req) {
  return {
    type: GET_MASAVEDFILTERS_REQUEST,
    payload: req,
  };
}

export function getSavedFiltersSuccess(res) {
  return {
    type: GET_MASAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function getSavedFiltersError(err) {
  return {
    type: GET_MASAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function addSavedFiltersRequest(req, cb) {
  return {
    type: ADD_MASAVEDFILTERS_REQUEST,
    payload: req,
    cb,
  };
}

export function addSavedFiltersSuccess(res) {
  return {
    type: ADD_MASAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function addSavedFiltersError(err) {
  return {
    type: ADD_MASAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function editSavedFiltersRequest(req, cb) {
  return {
    type: EDIT_MASAVEDFILTERS_REQUEST,
    payload: req,
    cb,
  };
}

export function editSavedFiltersSuccess(res) {
  return {
    type: EDIT_MASAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function editSavedFiltersError(err) {
  return {
    type: EDIT_MASAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function deleteSavedFiltersRequest(req) {
  return {
    type: DELETE_MASAVEDFILTERS_REQUEST,
    payload: req,
  };
}

export function deleteSavedFiltersSuccess(res) {
  return {
    type: DELETE_MASAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function deleteSavedFiltersError(err) {
  return {
    type: DELETE_MASAVEDFILTERS_ERROR,
    payload: err,
  };
}

export function favoriteSavedFiltersRequest(req) {
  return {
    type: FAVORITE_MASAVEDFILTERS_REQUEST,
    payload: req,
  };
}

export function favoriteSavedFiltersSuccess(res) {
  return {
    type: FAVORITE_MASAVEDFILTERS_SUCCESS,
    payload: res,
  };
}

export function favoriteSavedFiltersError(err) {
  return {
    type: FAVORITE_MASAVEDFILTERS_ERROR,
    payload: err,
  };
}

// update field priority
export function fieldUpdatePriorityRequest(req) {
  return {
    type: FIELD_UPDATEPRIORITY_REQUEST,
    payload: req,
  };
}

export function fieldUpdatePrioritySuccess(res) {
  return {
    type: FIELD_UPDATEPRIORITY_SUCCESS,
    payload: res,
  };
}

export function fieldUpdatePriorityError(err) {
  return {
    type: FIELD_UPDATEPRIORITY_ERROR,
    payload: err,
  };
}

// Update fields
export function fieldUpdateRequest(req) {
  return {
    type: FIELD_UPDATE_REQUEST,
    payload: req,
  };
}

export function fieldUpdateSuccess(res) {
  return {
    type: FIELD_UPDATE_SUCCESS,
    payload: res,
  };
}

export function fieldUpdateError(err) {
  return {
    type: FIELD_UPDATE_ERROR,
    payload: err,
  };
}

export function fieldShowHideRequest(req) {
  return {
    type: FIELD_SHOWHIDE_REQUEST,
    payload: req,
  };
}

export function fieldShowHideSuccess(res) {
  return {
    type: FIELD_SHOWHIDE_SUCCESS,
    payload: res,
  };
}

export function fieldShowHideError(err) {
  return {
    type: FIELD_SHOWHIDE_ERROR,
    payload: err,
  };
}

export function leadsDropdownAddRequest(req) {
  return {
    type: LEADS_DROPDOWNADD_REQUEST,
    payload: req,
  };
}

export function leadsDropdownAddSuccess(res) {
  return {
    type: LEADS_DROPDOWNADD_SUCCESS,
    payload: res,
  };
}

export function leadsDropdownAddError(err) {
  return {
    type: LEADS_DROPDOWNADD_ERROR,
    payload: err,
  };
}

export function leadsDropdownAddClear(res) {
  return {
    type: LEADS_DROPDOWNADD_CLEAR,
    payload: res,
  };
}

export function leadsDropdownRequest(req) {
  return {
    type: LEADS_DROPDOWN_REQUEST,
    payload: req,
  };
}

export function leadsDropdownSuccess(res) {
  return {
    type: LEADS_DROPDOWN_SUCCESS,
    payload: res,
  };
}

export function leadsDropdownError(err) {
  return {
    type: LEADS_DROPDOWN_ERROR,
    payload: err,
  };
}

export function getActivitiesListRequest(req) {
  return {
    type: GET_ACTIVITIES_LIST_REQUEST,
    payload: req,
  };
}

export function getActivitiesListRequestWithoutLoading(req) {
  return {
    type: GET_ACTIVITIES_LIST_REQUEST_WITHOUT_LOADING,
    payload: req,
  };
}

export function getActivitiesListSuccess(res) {
  return {
    type: GET_ACTIVITIES_LIST_SUCCESS,
    payload: res,
  };
}

export function getActivitiesListError(err) {
  return {
    type: GET_ACTIVITIES_LIST_ERROR,
    payload: err,
  };
}

export function updateActivityLoadingData(req) {
  return {
    type: LOADING_ACTIVITY_DATA_UPDATE,
    payload: req,
  };
}
