import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as cadenceStepActions from "../actions/cadence_step_actions";
import * as cadenceActions from "../actions/cadence_actions";
import * as cadenceTypes from "../types/cadence.types";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* updateCadenceStep(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_CADENCE_STEP}/${action.payload.id}/step/${action.payload.stepId}`, action.payload.data);
    if (res.status === 200) {
      yield put(cadenceStepActions.updateCadenceStepSuccess(res.data));
      yield put(cadenceActions.getCadenceByIdRequest({ id: action.payload.id }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceStepActions.updateCadenceStepError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceStepActions.updateCadenceStepError(e));
  }
}

function* AddStepInCadence(action) {
  try {
    const res = yield call(api.post, `${endpoints.CREATE_CADENCE_STEP}/${action.payload.id}/step`, action.payload.data);
    if (res.status === 200) {
      yield put(cadenceStepActions.AddStepInCadenceSuccess(res.data));
      yield put(cadenceActions.getCadenceByIdRequest({ id: action.payload.id }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceStepActions.AddStepInCadenceError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceStepActions.AddStepInCadenceError(e));
  }
}

function* deleteCadenceStep(action) {
  try {
    const res = yield call(api.delete, `${endpoints.DELETE_CADENCE_STEP}/${action.payload.id}/step/${action.payload.stepId}`);
    if (res.status === 200) {
      yield put(cadenceStepActions.deleteCadenceStepSuccess(res.data));
      yield put(cadenceActions.getCadenceByIdRequest({ id: action.payload.id }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceStepActions.deleteCadenceStepError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceStepActions.deleteCadenceStepError(e));
  }
}

function* moveCadenceSteps(action) {
  try {
    const res = yield call(api.post, endpoints.MOVE_CADENCE_STEP.replace("cadenceId", action.payload.id), action.payload.data);
    if (res.status === 200) {
      yield put(cadenceStepActions.moveCadenceStepsSuccess(res.data));
      yield put(cadenceActions.getCadenceByIdRequest({ id: action.payload.id }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceStepActions.moveCadenceStepsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceStepActions.moveCadenceStepsError(e));
  }
}

function* swapCadenceSteps(action) {
  try {
    const res = yield call(api.post, endpoints.SWAP_CADENCE_STEP, action.payload);
    if (res.status === 200) {
      yield put(cadenceStepActions.swapCadenceStepsSuccess(res.data));
      yield put(cadenceActions.getCadenceByIdRequest({ id: action.payload.id }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceStepActions.swapCadenceStepsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceStepActions.swapCadenceStepsError(e));
  }
}

export default function* cadenceStepSaga() {
  yield takeLatest(cadenceTypes.updateCadenceStep.UPDATE_CADENCE_STEP_REQUEST, updateCadenceStep);
  yield takeLatest(cadenceTypes.AddStepInCadence.ADD_STEP_IN_CADENCE_REQUEST, AddStepInCadence);
  yield takeLatest(cadenceTypes.deleteCadenceStep.DELETE_CADENCE_STEP_REQUEST, deleteCadenceStep);

  yield takeLatest(cadenceTypes.moveCadenceSteps.MOVE_CADENCE_STEPS_REQUEST, moveCadenceSteps);
  yield takeLatest(cadenceTypes.swapCadenceSteps.SWAP_CADENCE_STEPS_REQUEST, swapCadenceSteps);
}
