import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//actions
import { getUserActivityLogForSidePannelRequest } from "../../../containers/Activities/store/actions/activity_operations_actions";
import { GetUpcomingActivitiesRequest } from "../../../containers/Settings/actions/notes.actions";
import { CustomEmptyPage } from "../../empty_page/custom-empty-page";

//styles
import "./styles.scss";

import { getActivityContent, getNoteContent, getDealStageContent, getTextContent, getConversionContent, getEmailContent } from "./timeline-v2.helper";
import { UpcomingActivities } from "./upcoming.side-pannel";

const getCommonQuery = (props) => {
  const queryMaker = {};
  if (props.type === "people") {
    queryMaker["peopleId"] = props.peopleId;
  } else if (props.type === "lead") {
    queryMaker["leadId"] = props.leadId;
  } else if (props.type === "deal") {
    queryMaker["dealId"] = props.dealId;
  } else if (props.type === "company") {
    queryMaker["companyId"] = props.companyId;
  } else {
    queryMaker["peopleId"] = props.peopleId;
    queryMaker["companyId"] = props.companyId;
    queryMaker["leadId"] = props.leadId;
    queryMaker["dealId"] = props.dealId;
  }
  return queryMaker;
};

const getIconsClassName = (item) => {
  if (item.action === "lead-create" || item.action === "company-create" || item.action === "people-create" || item.action === "deal-create") {
    return <i className={`bi bi-plus font-size-20px text-02507a`}></i>;
  } else if (item.action === "note-create" || item.action === "note-updated" || item.action === "note-deleted") {
    return <i className={`bi bi-sticky text-02507a pt-3px`}></i>;
  } else if (item.action === "activity-create" || item.action === "activity-updated" || item.action === "activity-deleted") {
    return <i className={`text-02507a pt-3px ${item?.content?.activityType ? item.content.activityType.symbName : "bi bi-list-task"}`}></i>;
  } else if (item.action === "deal-stage-change") {
    return <i className={`bi bi-arrows-move text-02507a pt-3px`}></i>;
  } else if (item.action === "junk-to-active") {
    return <i className={`bi bi-trash2 text-02507a pt-3px`}></i>;
  } else if (item.action === "active-to-junk") {
    return <i className={`bi bi-arrow-left-right text-02507a pt-3px`}></i>;
  } else if (item.action === "deal-to-lead") {
    return <i className={`bi bi-arrow-left-right text-02507a pt-3px`}></i>;
  } else if (item.action === "lead-to-deal") {
    return <i className={`bi bi-arrow-left-right text-02507a pt-3px`}></i>;
  } else if (item.contentType === "note") {
    return <i className={`bi bi-sticky text-02507a pt-3px`}></i>;
  } else if (item.contentType === "text") {
    return <i className={`bi bi-pen-fill text-02507a pt-3px`}></i>;
  } else if (item.contentType === "activity") {
    return <i className={"text-02507a pt-3px" + item.content && item.content.activityType && item.content.activityType.symbName}></i>;
  } else if (item.contentType === "email") {
    return <i className={`bi bi-envelope text-02507a pt-3px`}></i>;
  }
  return <i className={`bi bi-pen-fill text-02507a pt-3px`}></i>;
};

export function Timeline(props) {
  const [logArray, setLogArray] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const activitiesData = useSelector((state) => state.notes.upcomingActivity);

  useEffect(() => {
    setInitialLoading(true);
    const queryMaker = { limit: 10, pageNo: 1 };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }
    setLogArray([]);
    dispatch(
      getUserActivityLogForSidePannelRequest(queryMaker, (data) => {
        setLogArray(data.data);
        setTotalLogs(data.count);
        setInitialLoading(false);
      }),
    );
    dispatch(GetUpcomingActivitiesRequest(queryMaker));
  }, [dispatch, props.leadId, props.peopleId, props.dealId, props.companyId, props.type]);

  const onLoadMoreClick = () => {
    setLoading(true);
    dispatch(
      getUserActivityLogForSidePannelRequest({ ...getCommonQuery(props), limit: limit, pageNo: page + 1 }, (data) => {
        const _logs = [...logArray];
        _logs.push(...data.data);
        setPage(page + 1);
        setLogArray(_logs);
        setLoading(false);
        setTotalLogs(data.count);
      }),
    );
  };

  const renderEachLogs = (each) => {
    if (each.action === "lead-create" || each.action === "company-create" || each.action === "people-create" || each.action === "deal-create") {
      return getTextContent(each, props.basePath, history);
    } else if (each.action === "note-create" || each.action === "note-updated" || each.action === "note-deleted") {
      return getNoteContent(each, props.basePath, history);
    } else if (each.action === "activity-create" || each.action === "activity-updated" || each.action === "activity-deleted") {
      return getActivityContent(each, props.basePath, history);
    } else if (each.action === "deal-stage-change") {
      return getDealStageContent(each, props.basePath, history);
    } else if (each.action === "email-sent" || each.action === "email-received") {
      return getEmailContent(each, props.basePath, history);
    } else if (
      each.action === "junk-to-active" ||
      each.action === "active-to-junk" ||
      each.action === "deal-to-lead" ||
      each.action === "lead-to-deal" ||
      each.action === "people-to-lead" ||
      each.action === "lead-to-people"
    ) {
      return getConversionContent(each, props.basePath, history);
    } else if (each.contentType === "text") {
      return getTextContent(each, props.basePath, history);
    } else if (each.contentType === "note") {
      return getNoteContent(each, props.basePath, history);
    } else return "no";
  };

  return (
    <div className="card-body px-0 py-0 overflow-y-auto">
      {activitiesData.list.length > 0 && (
        <div className="mb-30px border-bottom-width-1px border-bottom-style-solid border-bottom-color-ebedf3">
          <div className="pb-4 font-size-14px text-9197b3">Upcoming</div>
          <UpcomingActivities
            peopleId={props.peopleId}
            companyId={props.companyId}
            leadId={props.leadId}
            dealId={props.dealId}
            type={props.pageSub}
            pageForm={props.pageForm}
            filter={props.filter}
            basePath={props.basePath}
          />
        </div>
      )}
      <div>
        {initialLoading ? (
          <div className="d-flex flex-center h-100px">
            {" "}
            <Spinner animation="border" variant="primary" className="mr-10px w-36px h-36px"></Spinner>
          </div>
        ) : (
          <>
            {activitiesData.list.length > 0 && <div className="pb-4 font-size-14px text-9197b3">All Logs</div>}
            <div className="timeline">
              {logArray.map((item, index) => {
                return (
                  <div key={index} className="timeline-item">
                    <div className="timeline-line w-30px"></div>
                    <div className="timeline-icon symbol symbol-circle symbol-30px me-4 border-width-2px border-style-solid border-bottom-color-02507a">
                      <div className="symbol-label">{getIconsClassName(item)}</div>
                    </div>
                    <div className="timeline-content mb-10">{renderEachLogs(item)}</div>
                  </div>
                );
              })}
            </div>
            {totalLogs > logArray.length ? (
              <div className="d-flex justify-content-center">
                <span className="btn btn-secondary py-7px px-20px h-34px" onClick={() => onLoadMoreClick()}>
                  {loading && <Spinner animation="border" variant="primary" className="mr-10px w-16px h-16px"></Spinner>}
                  Load More
                </span>
              </div>
            ) : null}
            {/* {logArray.length === 0 && <div className="note-no-data-text">This record doesn't have any timeline.</div>} */}
            {logArray.length === 0 && <CustomEmptyPage page="emptyTimeline" hideBorder={true} />}
          </>
        )}
      </div>
    </div>
  );
}
