import { combineReducers } from "redux";
import * as DashboardReducer from "./dashboard.reducer";
import * as SalesForecastReducer from "./sales-forecast.reducer";
import * as SalesTargetReducer from "./sales-target.reducer";

const InsightReducer = combineReducers({
  dashboardReducer: DashboardReducer.InsightReducer,
  salesTarget: SalesTargetReducer.SalesTargetReducer,
  salesForecast: SalesForecastReducer.SalesForecastReducer,
});

export default InsightReducer;
