import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../actions/sync.actions";
import { Spinner } from "react-bootstrap";
import _ from "lodash";

export default function EmailSyncCallback() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const setLoadingText = (message) => {
    setLoading(false);
    setMessage(message);

    setTimeout(() => {
      window.location.href = `/settings/profile/email-sync`;
    }, 5000);
  };

  useEffect(() => {
    if (history.location.search !== "") {
      const code = history.location.search.split("code=");
      dispatch(
        actions.hostedEmailAuthCallbackRequest(
          {
            code: code[1],
          },
          (data) => {
            setLoadingText(_.capitalize(data.message));
          },
        ),
      );
    }
  }, [history.location.search, dispatch]);

  return (
    <div className="card p-6 h-100 d-flex align-items-center">
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-100 mt-20-percent">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 fs-20">
          <div>
            <h4>{message}</h4>
          </div>
          <div className="fs-6">You will be able to send and receive emails within the Sales Simplify app. Please wait, we are connecting it for you!</div>
          <div className="h-100px d-flex align-items-center">
            {" "}
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      )}
    </div>
  );
}
