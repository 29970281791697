import { applyCouponCode } from "../types/coupon_code_types";

export const applyCouponCodeRequest = (req, cb) => ({
  type: applyCouponCode.APPLY_COUPON_CODE_REQUEST,
  payload: req,
  cb,
});

export const applyCouponCodeSuccess = (res) => ({
  type: applyCouponCode.APPLY_COUPON_CODE_SUCCESS,
  payload: res,
});

export const applyCouponCodeError = (err) => ({
  type: applyCouponCode.APPLY_COUPON_CODE_ERROR,
  payload: err,
});
