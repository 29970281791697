import React from "react";
import { Form } from "react-bootstrap";

export default function ViewTypeSelector({ value, onClick }) {
  const selectorOptions = [
    { label: "My Templates", value: "org" },
    { label: "Gallery", value: "global" },
  ];

  return (
    <Form.Group
      id="templates-view-type-selector-name"
      className="d-flex align-items-center m-0"
      onClick={(e) => {
        if (e.target.name) onClick(e.target.name);
      }}
    >
      {selectorOptions.map((each, index) => {
        return (
          <Form.Check name={each.value} className="px-10" type={"radio"} id={each.value} key={index}>
            <Form.Check.Input name={each.value} checked={each.value === value} className="custom-checkbox" type={"radio"} varient="primary" />
            <Form.Check.Label name={each.value} className="mt-1">
              {each.label}
            </Form.Check.Label>
          </Form.Check>
        );
      })}
    </Form.Group>
  );
}
