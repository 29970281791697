import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Spinner, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { MarkAsDoneActivity } from "../../activity/mark_as_done";

//actions
import { GetUpcomingActivitiesRequest } from "../../../containers/Settings/actions/notes.actions";
import * as ActivityOperationsActions from "../../../containers/Activities/store/actions/activity_operations_actions";
import * as ActivityListActions from "../../../containers/Activities/store/actions/activitity_list_actions";
import * as ActivityLossReasonActions from "../../../containers/Activities/store/actions/activity_loss_reasons_actions";

//styles
import "./styles.scss";
import { moduleColors, TimelineDataWrapper } from "./timeline-v2.helper";
import { ScheduleADateDisplay } from "../../custom-table-data-display/due-date-display";
import { renderLinkedData } from "./helper";

export function UpcomingActivities(props) {
  const [limit] = useState(100);
  const [page] = useState(1);
  const [userObject, setUserObject] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const activitiesData = useSelector((state) => state.notes.upcomingActivity);
  const activityLossReasonStatus = useSelector((store) => store.activity.lossReasons.settings.enabled);
  const userRole = useSelector((store) => store.insight.userRole);

  useEffect(() => {
    dispatch(ActivityLossReasonActions.getActivityLossReasonsRequest({ skipIfDataPresent: true }));
    dispatch(ActivityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (userRole.list.allUsers) {
      const obj = {};
      userRole.list.allUsers.forEach((each) => {
        obj[each.id] = each;
      });
      setUserObject(obj);
    }
  }, [userRole.list.allUsers]);

  const getActivitiesLogs = () => {
    const queryMaker = {
      limit: limit,
      pageNo: page,
    };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }
    dispatch(GetUpcomingActivitiesRequest(queryMaker));
  };

  const onCancelMarkAsDone = () => {
    document.body.click();
  };
  const onMarkAsDoneActivity = (payload) => {
    callActivityMADoneApi(payload.data);
    document.body.click();
  };

  const callActivityMADoneApi = (data) => {
    dispatch(
      ActivityOperationsActions.markAsDoneRequest({ data: data, pageForm: props.pageForm, filter: props.filter }, (res) => {
        if (res.status) {
          getActivitiesLogs();
        }
      }),
    );
  };
  const onDeleteClick = (id) => {
    dispatch(
      ActivityListActions.deleteActivityRequest({ id, pageForm: props.pageForm, filter: props.filter }, (res) => {
        if (res.status) {
          getActivitiesLogs();
        }
      }),
    );
    getActivitiesLogs();
  };

  const onActivityDoneUndoneClick = (isCompleted, id) => {
    if (isCompleted) {
      dispatch(
        ActivityOperationsActions.markAsUndoneRequest({ id: { id: id } }, (res) => {
          if (res.status) getActivitiesLogs();
        }),
      );
    } else {
      const data = { id: id, reasonId: "", comment: "" };
      callActivityMADoneApi(data);
    }
  };

  const showLossReasonPopup = (data) => {
    if (!data.isCompleted && activityLossReasonStatus && !_.isEmpty(data.associated)) {
      return true;
    }
    return false;
  };
  const getUserName = (item, userId) => {
    if (item && item.source && item.source === "Workflow") {
      return "Automation";
    } else if (userObject[userId]) {
      if (userObject[userId]?.name) return userObject[userId].name;
      else if (userObject[userId].email) return userObject[userId].email;
      else return "Unknown";
    } else return "Unknown";
  };
  return (
    <>
      <>
        <div>
          {activitiesData.loading ? (
            <div className="w-100 d-flex p-7 justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              <div>
                {" "}
                {activitiesData.list.map((note, index) => {
                  return (
                    <div key={index} className="mb-30px">
                      <TimelineDataWrapper moduleName={"Activity"} createdAt={note.createdAt} moduleColor={moduleColors["activity"]} createdText={`Added by ${getUserName(note, note.owner)}`}>
                        {note && (
                          <div className="d-flex">
                            <div className="w-30px mr-4 mt-2">
                              {showLossReasonPopup(note) ? (
                                <OverlayTrigger
                                  rootClose={true}
                                  trigger="click"
                                  className="z-index-0"
                                  placement={"bottom"}
                                  overlay={
                                    <Popover onClick={(e) => e.stopPropagation()} className="z-index-9999 my-0 mx-20px w-430px w-290px-mx">
                                      <Popover.Title>
                                        <div className="fw-500 font-size-15px text-26292c">Mark As Done</div>
                                      </Popover.Title>
                                      <Popover.Content className="p-0 m-0">
                                        <MarkAsDoneActivity activityId={note._id} showNextSchedule={false} handleCancel={onCancelMarkAsDone} handleSave={onMarkAsDoneActivity} />
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <input type="radio" className="w-16px h-16px" checked={note.isCompleted} />
                                </OverlayTrigger>
                              ) : (
                                <input type="radio" className="w-16px h-16px" checked={note.isCompleted} onClick={(e) => onActivityDoneUndoneClick(note.isCompleted, note._id)} />
                              )}
                            </div>
                            <div className="w-100-60px">
                              {renderLinkedData(note, props.basePath, history)}
                              <div className="fs-6 fw-bold text-008fbd">{note.name}</div>
                              <div className="d-flex align-items-center justify-content-between mt-4">
                                <div className="d-flex align-items-center gap-2 ">
                                  {note.activityType && <i className={`${note.activityType.symbName}`}></i>}
                                  <div>
                                    <ScheduleADateDisplay data={note.scheduleAt} />{" "}
                                  </div>
                                </div>
                                {note.isCompleted && (
                                  <div className="mr-minus-35px">
                                    <span className="badge badge-success bg-73ce89">Completed</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <div>
                                <button className="btn btn-icon btn-light ml-3 h-25px w-25px">
                                  <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
                                    <i className="fa fa-sm fa-trash font-size-13px" onClick={() => onDeleteClick(note._id)}></i>
                                  </OverlayTrigger>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </TimelineDataWrapper>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
}
