import {
  GET_BILLING_PRICES_REQUEST,
  GET_BILLING_PRICES_SUCCESS,
  GET_BILLING_PRICES_ERROR,
  BILLING_PLAN_CHECKOUT_REQUEST,
  BILLING_PLAN_CHECKOUT_SUCCESS,
  BILLING_PLAN_CHECKOUT_ERROR,
  BILLING_CREATE_CUSTOMER_REQUEST,
  BILLING_CREATE_CUSTOMER_SUCCESS,
  BILLING_CREATE_CUSTOMER_ERROR,
  BILLING_CUSTOMER_PORTAL_REQUEST,
  BILLING_CUSTOMER_PORTAL_SUCCESS,
  BILLING_CUSTOMER_PORTAL_ERROR,
  GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST,
  GET_BILLING_SUBSCRIPTIONDETAILS_SUCCESS,
  GET_BILLING_SUBSCRIPTIONDETAILS_ERROR,
  GET_ACCOUNT_BILLING_REQUEST,
  GET_ACCOUNT_BILLING_SUCCESS,
  GET_ACCOUNT_BILLING_ERROR,
  GET_INVOICESLIST_REQUEST,
  GET_INVOICESLIST_SUCCESS,
  GET_INVOICESLIST_ERROR,
  GET_INVOICESLIST_REQUEST_AFTER,
  CANCEL_RENEW_SUBSCRIPTION_REQUEST,
  CANCEL_RENEW_SUBSCRIPTION_SUCCESS,
  CANCEL_RENEW_SUBSCRIPTION_ERROR,
} from "../types/subscription_billing_types";

const initialState = {
  billingPrices: {},
  billingPricesLoading: true,
  billingPlanCheckout: {},
  billingCreateCustomer: {},
  billingCustomerPortal: {},
  billingSubscriptionDetails: {},
  loading: true,
  accountWiseBilling: { data: {}, loading: true },
  invoiceList: { loading: true, data: [] },
  invoiceListArray: [],
  currentSubscriptionStatus: {
    current: null,
    shouldBe: null,
  },
};

const removeDuplicateInvoices = (currentArray, newArray) => {
  const arrayObjecct = [],
    updatedArray = [];
  currentArray.forEach((each) => {
    arrayObjecct.push(each.id);
  });
  updatedArray.push(...currentArray);

  for (let i = 0; i < newArray.length; i++) {
    if (arrayObjecct.indexOf(newArray[i].id) === -1) {
      updatedArray.push(newArray[i]);
    }
  }
  return updatedArray;
};

export default function billingReducer(state = initialState, action = {}) {
  switch (action.type) {
    //cancel or renew subscription
    case CANCEL_RENEW_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        currentSubscriptionStatus: {
          ...state.currentSubscriptionStatus,
          shouldBe: action.payload === "renew" ? false : true,
        },
      };
    case CANCEL_RENEW_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
      };
    case CANCEL_RENEW_SUBSCRIPTION_ERROR:
      return {
        ...state,
      };
    //invoice
    case GET_INVOICESLIST_REQUEST:
      return {
        ...state,
        invoiceList: { loading: true },
      };
    case GET_INVOICESLIST_REQUEST_AFTER:
      return {
        ...state,
        invoiceList: { loading: false },
      };
    case GET_INVOICESLIST_SUCCESS:
      return {
        ...state,
        invoiceList: { ...state.invoiceList, data: action.payload, loading: false },
        invoiceListArray: removeDuplicateInvoices(state.invoiceListArray, action.payload.data),
      };
    case GET_INVOICESLIST_ERROR:
      return {
        ...state,
      };
    //account billing
    case GET_ACCOUNT_BILLING_REQUEST:
      return {
        ...state,
        accountWiseBilling: {
          ...state.accountWiseBilling,
          loading: true,
        },
      };
    case GET_ACCOUNT_BILLING_SUCCESS:
      return {
        ...state,
        accountWiseBilling: { ...state.accountWiseBilling, data: action.payload, loading: false },
        currentSubscriptionStatus: {
          ...state.currentSubscriptionStatus,
          current: action.payload.isSubscriptionPartialCancelled,
        },
      };
    case GET_ACCOUNT_BILLING_ERROR:
      return {
        ...state,
      };
    case GET_BILLING_PRICES_REQUEST:
      return {
        ...state,
        billingPrices: {},
        billingPricesLoading: true,
      };
    case GET_BILLING_PRICES_SUCCESS:
      return {
        ...state,
        billingPrices: action.payload,
        billingPricesLoading: false,
      };
    case GET_BILLING_PRICES_ERROR:
      return {
        ...state,
        billingPrices: action.payload,
        billingPricesLoading: true,
      };
    case BILLING_PLAN_CHECKOUT_REQUEST:
      return {
        ...state,
        billingPlanCheckout: {},
      };
    case BILLING_PLAN_CHECKOUT_SUCCESS:
      return {
        ...state,
        billingPlanCheckout: action.payload,
      };
    case BILLING_PLAN_CHECKOUT_ERROR:
      return {
        ...state,
        billingPlanCheckout: action.payload,
      };
    case BILLING_CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        billingCreateCustomer: {},
      };
    case BILLING_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        billingCreateCustomer: action.payload,
      };
    case BILLING_CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        billingCreateCustomer: action.payload,
      };
    case BILLING_CUSTOMER_PORTAL_REQUEST:
      return {
        ...state,
        billingCustomerPortal: {},
      };
    case BILLING_CUSTOMER_PORTAL_SUCCESS:
      return {
        ...state,
        billingCustomerPortal: action.payload,
      };
    case BILLING_CUSTOMER_PORTAL_ERROR:
      return {
        ...state,
        billingCustomerPortal: action.payload,
      };
    case GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST:
      return {
        ...state,
        billingSubscriptionDetails: {},
        loading: true,
      };
    case GET_BILLING_SUBSCRIPTIONDETAILS_SUCCESS:
      return {
        ...state,
        billingSubscriptionDetails: action.payload,
        loading: false,
      };
    case GET_BILLING_SUBSCRIPTIONDETAILS_ERROR:
      return {
        ...state,
        billingSubscriptionDetails: action.payload,
        loading: true,
      };
    default:
      return state;
  }
}
