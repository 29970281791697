import React, {
  // useRef,
  useState,
  useLayoutEffect,
} from "react";
// import logo from './logo.svg';
// import './App.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function ProgressBarGraph(props) {
  const [graphData] = useState(props.won);

  useLayoutEffect(() => {
    var chart = am4core.create(props.chartId, am4charts.XYChart);
    chart.data = graphData;

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.title.text =
      props.interval
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
        .substr(0, 1)
        .toUpperCase() + props.interval.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : "")).substr(1);
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text =
      props.graphType
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
        .substr(0, 1)
        .toUpperCase() + props.graphType.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? " " + c.toUpperCase() : "")).substr(1);

    props.legends.forEach((each) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = each;
      series.dataFields.categoryX = "category";
      series.name = each.toUpperCase();
      series.tooltipText = `{name}: [bold]{valueY} ${props.title === "Deal Progress by Value" ? props.defaultCurrency : ""} [/]`;
      series.stacked = true;
    });

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    return () => {};
  }, [graphData, props.chartId, props.graphType, props.interval, props.legends, props.defaultCurrency, props.title]);

  return <div id={props.chartId} className="w-100 h-100"></div>;
}
export default ProgressBarGraph;
