import React, { useState, useEffect, useMemo } from "react";
import NormalTable from "../../../../components/normal-table/index";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/settings.actions";
import Pagination from "../../../../components/normal-table/pagination";
import { Spinner } from "react-bootstrap";
import { getListOfUsersRequest } from "../../../insights/actions";
import { Dropdown } from "react-bootstrap";

export default function LoginSessions() {
  const [teamDetails, seTeamDetails] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [loader, setLoader] = useState(true);
  const [UserVal, setUserVal] = useState("All");
  const dispatch = useDispatch();
  const listOfUsers = useSelector((store) => store.insight.users);

  const [comments1, setComments1] = useState([]);
  const [totalItems1, setTotalItems1] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(1);
  const ITEMS_PER_PAGE1 = 10;

  useEffect(() => {
    dispatch(getListOfUsersRequest());

    dispatch(
      actions.getloginSessionRequest("all", currentPage1, selectUser, (data) => {
        setTotalItems1(data.count);
        setLoader(false);
        setComments1(data.data);
      }),
    );
  }, [dispatch, currentPage1, selectUser]);

  useEffect(() => {
    if (listOfUsers.list.length > 0 && listOfUsers.loading === false) {
      let arr = [];
      listOfUsers.list.map((data) => arr.push({ label: data.email, value: data.userId }));
      seTeamDetails(arr);
    }
  }, [listOfUsers]);

  function dropValueMethod(selectedOption) {
    setSelectUser(selectedOption.split(",")[0]);
    setUserVal(selectedOption.split(",")[1]);
  }

  const commentsData1 = useMemo(() => {
    let computedComments = comments1;
    return computedComments;
  }, [comments1]);

  return (
    <>
      <NormalTable
        tableHeading={["Time", "Email", "IP Address", "Platform", "Location"]}
        tableTitle={"Last Login Session"}
        buttonHead={
          <div className="position-relative right-20px w-215px">
            <Dropdown drop="down" onSelect={(e) => dropValueMethod(e)}>
              <Dropdown.Toggle id="newSS" className="newDropDesign">
                {UserVal}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {teamDetails.map((data, key) => {
                  return (
                    <Dropdown.Item key={key} className="dropdown-hover" eventKey={[data.value, data.label]}>
                      {data.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
        applyWidth={true}
      >
        {loader && (
          <tr>
            <td colSpan="5" className="text-center">
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        )}

        {commentsData1.length === 0 && loader === false && (
          <>
            <tr>
              <td colSpan="5" className="text-center position-relative top-70px h-300px">
                <h6>No Data To Show</h6>
              </td>
            </tr>
          </>
        )}

        {commentsData1.map((user, id) => {
          return (
            <tr key={id}>
              <td className="w-22 pr-4">
                {new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).format(new Date(user.timeToLogin))} <br />
                {user.timeToLogout !== undefined &&
                  new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).format(new Date(user.timeToLogout))}
              </td>
              <td className="pr-4 w-20">{user.userId.email}</td>
              <td className="pr-4 w-23">{user.IPAddr}</td>
              <td className="pr-4 w-20">{user.platform}</td>
              <td className="pr-4 w-15">{user.location}</td>
            </tr>
          );
        })}
      </NormalTable>

      {totalItems1 !== 0 && (
        <div className="position-absolute right-20px mt-minus-21px">
          <Pagination total={totalItems1} itemsPerPage={ITEMS_PER_PAGE1} currentPage={currentPage1} onPageChange={(page) => setCurrentPage1(page)} />
        </div>
      )}
    </>
  );
}
