import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CompanyDetails } from "../components/company-details.component";
import { PersonDetails } from "../components/person-details.component";
// import { SequenceDetails } from "../components/sequence.component";
import { reorder } from "./helper";

export function PeopleLeftPannel(props) {
  const [order, setOrder] = useState(["people", "company", "sequence"]);
  const [viewMoreOrder, setViewMoreOrder] = useState({ people: false, company: false, sequence: false });

  useEffect(() => {
    const _order = localStorage.getItem("people-popup-order");
    if (_order) {
      const updated = _order.split(",");
      if (updated.length === 3) {
        setOrder(updated);
      } else {
        setOrder(["people", "company", "sequence"]);
      }
    } else {
      setOrder(["people", "company", "sequence"]);
    }
  }, []);

  useEffect(() => {
    const _order = localStorage.getItem("people-popup-view-more-order");
    if (_order) {
      const _parse = JSON.parse(_order);
      if (Object.keys(_parse).length === 3) {
        setViewMoreOrder(_parse);
      } else {
        setViewMoreOrder({ people: false, company: false, sequence: false });
      }
    } else {
      setViewMoreOrder({ people: false, company: false, sequence: false });
    }
  }, []);

  const handleDragEnd = (param) => {
    const a = reorder(order, param.source.index, param.destination.index);
    setOrder(a);
    localStorage.setItem("people-popup-order", a.join(","));
  };

  const handleViewMoreChange = (module, status) => {
    const _viewMoreOrder = { ...viewMoreOrder };
    _viewMoreOrder[module] = status;
    setViewMoreOrder(_viewMoreOrder);
    localStorage.setItem("people-popup-view-more-order", JSON.stringify(_viewMoreOrder));
  };

  const people = (index) => (
    <PersonDetails
      index={index}
      page={props.page}
      pageSub={props.pageSub}
      peopleId={props.peopleId}
      moduleType={props.moduleType}
      pageForm={props.pageForm}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      viewMore={viewMoreOrder["people"]}
      setViewMore={(status) => handleViewMoreChange("people", status)}
    />
  );

  const company = (index) => (
    <CompanyDetails
      index={index}
      showSwitchPerson={true}
      page={props.page}
      pageSub={props.pageSub}
      peopleId={props.peopleId}
      moduleType={props.moduleType}
      pageForm={props.pageForm}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      isSecondaryModule={true}
      viewMore={viewMoreOrder["company"]}
      setViewMore={(status) => handleViewMoreChange("company", status)}
    />
  );

  // const sequence = index => <SequenceDetails
  //     index={index}
  //     peopleId={props.peopleId}
  //     moduleType={props.moduleType}
  // />;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-side-panel">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {order.map((each, index) => {
              if (each === "people") return people(index);
              else if (each === "company") return company(index);
              // else if (each === "sequence") return sequence(index);
              else return <div key={index}></div>;
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
