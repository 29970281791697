import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputLimit } from "../../constants";
import "./styles.scss";
import _ from "lodash";

export default function SimpleDropdown({ value, label, options, handleSelect, disabled, showSearch }) {
  const [search, setSearch] = useState("");
  const [dropdownLabel, setDropdownLabel] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (showSearch && search) {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [options, search, showSearch]);

  useEffect(() => {
    if (typeof value === "string" && value) {
      const filtered = options.find((option) => option.value === value);
      if (filtered) {
        setDropdownLabel(filtered.label);
      } else {
        setDropdownLabel(label);
      }
    } else if (typeof value === "object" && value.label) {
      setDropdownLabel(value.label);
    } else {
      setDropdownLabel(label);
    }
  }, [value, label, options]);

  return (
    <Dropdown className="mt-0 simple-dropdown-container w-100">
      <Dropdown.Toggle disabled={disabled || false} className="bg-white w-100 text-left py-0-point-65rem px-1-point-1rem bg-white border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {dropdownLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-y-auto" onClick={(e) => e.stopPropagation()}>
          {showSearch && (
            <div className="mx-4">
              <Form.Control
                max={InputLimit.TEXT}
                type="text"
                placeholder={"Search..."}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          )}
          {showSearch && <Dropdown.Divider />}
          {filteredOptions.map((option, optionIndex) => {
            return (
              <Dropdown.Item
                key={optionIndex}
                className="dropdown-hover"
                onSelect={(e) => {
                  handleSelect(option, optionIndex);
                  setSearch("");
                }}
              >
                {option.label}
              </Dropdown.Item>
            );
          })}
          {filteredOptions.length === 0 && <Form.Label className="w-100 text-center py-4">No results found</Form.Label>}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function PrimarySimpleDropdown({ value, label, options, handleSelect, disabled }) {
  return (
    <Dropdown className="mt-0">
      <Dropdown.Toggle variant="primary" className="btn-sm text-white white-toogle-arrow text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef overflow-hidden" disabled={disabled || false}>
        {value.label ? value.label : label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-y-auto" onClick={(e) => e.stopPropagation()}>
          {options.map((option, optionIndex) => {
            return (
              <Dropdown.Item
                key={optionIndex}
                className="dropdown-hover"
                onSelect={(e) => {
                  handleSelect(option, optionIndex);
                }}
              >
                {option.label}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function SecondarySimpleDropdown({ value, label, options, handleSelect }) {
  return (
    <Dropdown className="mt-0">
      <Dropdown.Toggle className="d-flex align-items-baseline z-index-0 btn  btn-sm dropdown-toggle btn-secondary rounded-2 ellipsis-dropdown-toggle" style={{ border: "1px solid #E4E6EF", background: "#F8F8F8" }}>
        {value.label ? value.label : label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div style={{ maxHeight: "25vh", position: "relative", overflowY: "auto" }} onClick={(e) => e.stopPropagation()}>
          {options.map((option, optionIndex) => {
            return (
              <Dropdown.Item
                key={optionIndex}
                className="dropdown-hover"
                onSelect={(e) => {
                  handleSelect(option, optionIndex);
                }}
              >
                {option.label}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function SimpleGroupDropdown({ value, label, options, handleSelect, disabled, showSearch }) {
  const [search, setSearch] = useState("");
  const [dropdownLabel, setDropdownLabel] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (showSearch && search) {
      const _filtered = {};
      for (const keys in options) {
        const filtered = options[keys].filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        if (!_.isEmpty(filtered)) {
          _filtered[keys] = filtered;
        }
      }
      setFilteredOptions(_filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [options, search, showSearch]);

  useEffect(() => {
    const finalOptions = [];
    for (const keys in options) {
      finalOptions.push(...options[keys]);
    }
    if (typeof value === "string" && value) {
      const filtered = finalOptions.find((option) => option.value === value);
      if (filtered) {
        setDropdownLabel(filtered.label);
      } else {
        setDropdownLabel(label);
      }
    } else if (typeof value === "object" && value.label) {
      setDropdownLabel(value.label);
    } else {
      setDropdownLabel(label);
    }
  }, [value, label, options]);

  return (
    <Dropdown className="mt-0 simple-dropdown-container w-100">
      <Dropdown.Toggle disabled={disabled || false} className="bg-white w-100 text-left py-0-point-65rem px-1-point-1rem bg-white border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {dropdownLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-y-auto" onClick={(e) => e.stopPropagation()}>
          {showSearch && (
            <div className="mx-4">
              <Form.Control
                max={InputLimit.TEXT}
                type="text"
                placeholder={"Search..."}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          )}
          {showSearch && <Dropdown.Divider />}
          {
            Object.keys(filteredOptions).map((key, index) => {
              return (
                <div key={index}>
                  <Dropdown.Header>{key}</Dropdown.Header>
                  {
                    filteredOptions[key].map((option, optionIndex) => {
                      return (
                        <Dropdown.Item
                          key={optionIndex}
                          className="dropdown-hover"
                          onSelect={(e) => {
                            handleSelect(option, optionIndex);
                            setSearch("");
                          }}
                        >
                          {option.label}
                        </Dropdown.Item>
                      );
                    })
                  }
                </div>
              )
            })
          }
          {Object.keys(filteredOptions).length === 0 && <Form.Label className="w-100 text-center py-4">No results found</Form.Label>}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function SearchSimpleDropdown({ search, setSearch, value, label, options, handleSelect, disabled, showSearch }) {
  const [dropdownLabel, setDropdownLabel] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (showSearch && search) {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [options, search, showSearch]);

  useEffect(() => {
    if (typeof value === "string" && value) {
      const filtered = options.find((option) => option.value === value);
      if (filtered) {
        setDropdownLabel(filtered.label);
      } else {
        setDropdownLabel(label);
      }
    } else if (typeof value === "object" && value.label) {
      setDropdownLabel(value.label);
    } else {
      setDropdownLabel(label);
    }
  }, [value, label, options]);

  return (
    <Dropdown className="mt-0 simple-dropdown-container w-100">
      <Dropdown.Toggle disabled={disabled || false} className="bg-white w-100 text-left py-0-point-65rem px-1-point-1rem bg-white border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {dropdownLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-y-auto" onClick={(e) => e.stopPropagation()}>
          {showSearch && (
            <div className="mx-4">
              <Form.Control
                max={InputLimit.TEXT}
                type="text"
                placeholder={"Search..."}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          )}
          {showSearch && <Dropdown.Divider />}
          {filteredOptions.map((option, optionIndex) => {
            return (
              <Dropdown.Item
                key={optionIndex}
                className="dropdown-hover"
                onSelect={(e) => {
                  handleSelect(option, optionIndex);
                  setSearch("");
                }}
              >
                {option.label}
              </Dropdown.Item>
            );
          })}
          {filteredOptions.length === 0 && <Form.Label className="w-100 text-center py-4">No results found</Form.Label>}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
