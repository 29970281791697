import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CustomTableWithoutAction } from "../../../../../components/custom-tables/table-without-actions";
import { ContentLoadingWithoutCard } from "../../../../../components/loading";
import { getCampaignPricingRequest } from "../../../store/actions/campaign_common_settings_actions";

const campaingTableFields = ["Plan Type", "Currency", "Per Email Price", "Attachment Cost (Cost/1GB)", "Status"];
const campaignTableMapping = {
  "Plan Type": "planType",
  Currency: "currency",
  "Per Email Price": "price",
  "Attachment Cost (Cost/1GB)": "attachmentPrice",
  Status: "status",
};

export const campaignTableFieldDataType = {
  planType: "TEXT",
  currency: "LIGHT_TAG",
  attachmentCost: "TEXT",
  emailPrice: "TEXT",
  status: "TAG",
};
export default function EmailPricing(props) {
  const [limit, setLimit] = useState(100);
  const [pageNo, setPageNo] = useState(1);

  const dispatch = useDispatch();

  const emailPricingStore = useSelector((store) => store.campaign.settings.pricing.email);

  useEffect(() => {
    dispatch(getCampaignPricingRequest("email"));
  }, [dispatch]);

  if (emailPricingStore.loading) {
    return <ContentLoadingWithoutCard />;
  }

  return (
    <div className="pricing-container">
      <div className="pricing-header d-flex flex-stack align-items-center">
        <h3 className="fw-bolder text-dark">{props.channel === "sms" ? "SMS" : _.capitalize(props.channel)} Pricing</h3>
      </div>
      <div className="pricing-content-container p-8">
        <div>
          <div className="card">
            <CustomTableWithoutAction
              tablehead={campaingTableFields}
              tablecontent={emailPricingStore.data.map((each) => {
                each.planType = "Regular";
                each.currency = _.upperCase(each.currency);
                return each;
              })}
              mappingTable={campaignTableMapping}
              dataType={campaignTableFieldDataType}
              pageNo={pageNo}
              limit={limit}
              count={emailPricingStore.count}
              setPageNo={setPageNo}
              setLimit={setLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
