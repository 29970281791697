import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./styles.scss";
// import { getEmailProfileDetailsRequest, getListOfEmailDomainSettingsRequest } from "../store/actions/campaign_email_settings_actions";
import { useSelector } from "react-redux";
import _ from "lodash";
import validator from "validator";
import { dispatchSnackbarError } from "../../../utils/toaster";
// import { getListOfSMSSenderIdsRequest, getSMSCampaignPricingRequest } from "../store/actions/campaign_sms_settings_actions";

const channels = [
  {
    icon: "bi bi-envelope",
    name: "Email",
    description: {
      "create-campaign": "Design targeted and impactful marketing, transactional, and OTP emails to engage customers at scale.",
      settings: "Control your account domains and footer display from here. Check message costs through pricing.",
      template: "Design stunning email templates using either a simple text editor or a drag-and-drop builder.",
    },
    path: "/email",
    lock: false,
    prerequisite: [
      {
        name: "Email Footer Settings",
        link: "/campaign/settings/email/manage",
        codeName: "emailSetting",
        errorMessage: `Please click "Email Footer Settings" to complete the necessary email campaign settings before creating an email campaign.`,
      },
      {
        name: "Email Verify Domain",
        link: "/campaign/settings/email/manage",
        codeName: "emailDomain",
        errorMessage: `Please click "Email Verify Domain" to complete the necessary email campaign settings before creating an email campaign.`,
      },
    ],
  },
  {
    icon: "bi bi-chat-right-text",
    name: "SMS",
    description: {
      "create-campaign": "Connect globally through text messaging. We support for promotional, transactional and OTP traffic.",
      settings: "Register your sender name and comply with regulations for each country. Check routes & pricing here.",
      template: "Maintain your all SMS templates here. While complying with regulations additional information may be required.",
    },
    path: "/sms",
    lock: false,
    prerequisite: [
      {
        name: "Sender Id",
        link: "/campaign/settings/sms/manage",
        codeName: "smsSenderId",
        errorMessage: `Please click "SMS Sender Id Settings" to complete the necessary sms campaign settings before creating an sms campaign.`,
      },
      {
        name: "SMS Pricing",
        link: "/campaign/settings/sms/pricing",
        codeName: "smsPricing",
        errorMessage: `SMS Pricing not defined for your account. Please contact support.`,
      },
    ],
  },
  {
    icon: "bi bi-whatsapp",
    name: "Whatsapp",
    description: {
      "create-campaign": "Send valuable notifications via the world's most popular instant messaging app, used by over 2 billion people.",
      settings: "Control your WhatsApp number and profile to align with business guidelines from here.",
      template: "Store all your WhatsApp message templates here and create new ones as needed.",
    },
    path: "/whatsapp",
    lock: false,
    prerequisite: [
      {
        name: "Whatsapp Accounts",
        link: "/campaign/settings/whatsapp/manage",
        codeName: "whatsappAccount",
        errorMessage: `Please click "Whatsapp Account Settings" to complete the necessary whataapp campaign settings before creating an whatsapp campaign.`,
      },
    ],
  },
  {
    icon: "bi bi-google",
    name: "Google RCS",
    description: {
      "create-campaign": "Branded communication through engaging multimedia messages. Leverage the power of RCS messaging.",
      settings: "Manage your RCS business number and profile to align with your brand from here.",
      template: "Manage all your RCS message templates here and create new ones as needed.",
    },
    path: "/rcs",
    lock: true,
    prerequisite: [],
  },
];

export default function ChannelSelector(props) {
  const [configStatus, setConfigStatus] = useState({ emailDomain: false, emailSetting: false, smsSenderId: false, smsPricing: false, whatsappAccount: false });
  const [loadingStatus, setLoadingStatus] = useState({ emailDomain: true, emailSetting: true, smsSenderId: true, smsPricing: true, whatsappAccount: true });

  const history = useHistory();
  // const dispatch = useDispatch();

  const profileStore = useSelector((store) => store.campaign.settings.email.profile);
  const domainStore = useSelector((store) => store.campaign.settings.email.domain);
  const senderIdStore = useSelector((store) => store.campaign.settings.sms.senderId);
  const smsPricingStore = useSelector((store) => store.campaign.settings.pricing.sms);
  const whatsappAccountsStore = useSelector(state => state.campaign.settings.whatsapp.data);

  // useEffect(() => {
  //   if (props.prerequisiteRequired) {
  //     dispatch(getListOfEmailDomainSettingsRequest());
  //     dispatch(getEmailProfileDetailsRequest());
  //     dispatch(getListOfSMSSenderIdsRequest());
  //     dispatch(getSMSCampaignPricingRequest());
  //   }
  // }, [dispatch, props.prerequisiteRequired]);

  useEffect(() => {
    if (!_.isEmpty(profileStore.data) && _.isObject(profileStore.data)) {
      setLoadingStatus((c) => ({ ...c, emailSetting: true }));
      const { address1, city, countryId, firstName, lastName, state } = profileStore.data;
      if (_.isEmpty(address1) || _.isEmpty(city) || _.isEmpty(countryId) || _.isEmpty(firstName) || _.isEmpty(lastName) || _.isEmpty(state)) {
        setConfigStatus((c) => ({ ...c, emailSetting: true }));
      } else {
        setConfigStatus((c) => ({ ...c, emailSetting: false }));
      }
      setLoadingStatus((c) => ({ ...c, emailSetting: false }));
    } else {
      setConfigStatus((c) => ({ ...c, emailSetting: false }));
      setLoadingStatus((c) => ({ ...c, emailSetting: false }));
    }
  }, [profileStore.data]);

  useEffect(() => {
    if (!_.isEmpty(domainStore.list) && _.isArray(domainStore.list)) {
      setLoadingStatus((c) => ({ ...c, emailDomain: true }));
      for (const domain of domainStore.list) {
        const _domainArray = domain.domain.split(" ");
        if (_domainArray.length === 2) {
          const email = _domainArray[1].replace(/\(|\)/g, "");
          if (validator.isEmail(email)) {
            setConfigStatus((c) => ({ ...c, emailDomain: true }));
            break;
          }
        }
      }
      setLoadingStatus((c) => ({ ...c, emailDomain: false }));
    } else {
      setLoadingStatus((c) => ({ ...c, emailDomain: false }));
      setConfigStatus((c) => ({ ...c, emailDomain: false }));
    }
  }, [domainStore.list]);

  useEffect(() => {
    if (!_.isEmpty(senderIdStore.data) && _.isArray(senderIdStore.data)) {
      setLoadingStatus((c) => ({ ...c, smsSenderId: false }));
      setConfigStatus((c) => ({ ...c, smsSenderId: true }));
    } else {
      setLoadingStatus((c) => ({ ...c, smsSenderId: false }));
      setConfigStatus((c) => ({ ...c, smsSenderId: false }));
    }
  }, [senderIdStore.data]);

  useEffect(() => {
    if (!_.isEmpty(smsPricingStore.data) && _.isArray(smsPricingStore.data)) {
      setLoadingStatus((c) => ({ ...c, smsPricing: false }));
      setConfigStatus((c) => ({ ...c, smsPricing: true }));
    } else {
      setLoadingStatus((c) => ({ ...c, smsPricing: false }));
      setConfigStatus((c) => ({ ...c, smsPricing: false }));
    }
  }, [smsPricingStore.data]);
  useEffect(() => {
    if (!_.isEmpty(whatsappAccountsStore.list) && _.isArray(whatsappAccountsStore.list)) {
      setLoadingStatus((c) => ({ ...c, whatsappAccount: false }));
      setConfigStatus((c) => ({ ...c, whatsappAccount: true }));
    } else {
      setLoadingStatus((c) => ({ ...c, whatsappAccount: false }));
      setConfigStatus((c) => ({ ...c, whatsappAccount: false }));
    }
  }, [whatsappAccountsStore.list]);



  const handleOnClick = (lock, path, prerequisite) => {
    if (lock) return;
    if (props.isSettings) return;
    if (props.prerequisiteRequired) {
      for (let _prerequisite of prerequisite) {
        if (!configStatus[_prerequisite.codeName]) return dispatchSnackbarError(_prerequisite.errorMessage);
      }
    }
    if (props.setShow) props.setShow(false);
    history.push(`${props.basePath}${path}`);
  };

  const handlePricingClick = (lock, path) => {
    if (lock) return;
    history.push(`${props.basePath}${path}/pricing`);
  };
  const handleSettingsClick = (lock, path) => {
    if (lock) return;
    history.push(`${props.basePath}${path}/manage`);
  };
  const handlePreRequisiteClick = (link, codeName) => {
    if (props.setShow) props.setShow(false);
    history.push(link);
  };
  const handleTemplateClick = (lock, path) => {
    if (lock) return;
    history.push(`${props.basePath}${path}`);
  };

  if (props.prerequisiteRequired && (profileStore.loading || domainStore.loading || senderIdStore.loading || smsPricingStore.loading)) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Row xs={1} md={1} lg={2} xl={props.isPopupScreen ? 2 : 4}>
      {channels.map((each, index) => {
        return (
          <Col key={index} className="channel-selector-wrapper mb-6">
            <div className="card h-100 position-relative">
              <div
                className={`p-6 ${each.lock ? "cursor-not-allowed" : "cursor-pointer"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClick(each.lock, each.path, each.prerequisite);
                }}
              >
                <div className="d-flex justify-content-center flex-row">
                  <div className="w-40px">
                    <i className={`${each.icon} mb-4 text-primary fs-1`}></i>
                  </div>
                  <div className="w-100-40px">
                    <div className="fs-4 text-primary fw-bold"> {each.name}</div>
                  </div>
                </div>
                <div className="text-gray-600 mt-4">{each.description[props.type]}</div>
                {each.lock && (
                  <div className="channel-lock-wrapper">
                    <div className="channel-lock-content">
                      <span>Coming Soon</span>
                    </div>
                  </div>
                )}
                {props.prerequisiteRequired && (
                  <div className="d-flex gap-3 flex-wrap mt-4">
                    {each.prerequisite.map((_each, ind) => {
                      if (configStatus[_each.codeName]) return <></>;
                      return (
                        <div className="d-flex align-items-center gap-2" key={ind}>
                          {loadingStatus[_each.codeName] ? (
                            <Spinner animation="border" variant="primary" className="w-16px h-16px" />
                          ) : configStatus[_each.codeName] ? (
                            <i class="bi bi-check2-circle text-success" />
                          ) : (
                            <i className="bi bi-x-circle text-danger" />
                          )}
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePreRequisiteClick(_each.link, _each.codeName);
                            }}
                            className={`text-gray-700 ${configStatus[_each.codeName] ? "text-success" : "text-danger cursor-pointer text-decoration-underline"}`}
                          >
                            {_each.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {props.isSettings && (
                <>
                  <div className="h-54px"></div>
                  <div className="border border-top-1 rounded-0 px-6 py-4 d-flex gap-3 position-absolute bottom-0 w-100">
                    <Button
                      className="border border-1"
                      variant="outline-primary"
                      size="sm"
                      disabled={each.lock}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSettingsClick(each.lock, each.path);
                      }}
                    >
                      {each.name === "SMS" ? "Sender ID" : "Manage"}
                    </Button>
                    <Button
                      className="border border-1"
                      variant="outline-primary"
                      size="sm"
                      disabled={each.lock}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePricingClick(each.lock, each.path);
                      }}
                    >
                      Pricing
                    </Button>
                  </div>
                </>
              )}
              {props.isTemplate && (
                <>
                  <div className="h-54px"></div>
                  <div className="border border-top-1 rounded-0 px-6 py-4 d-flex gap-3 position-absolute bottom-0 w-100">
                    <Button
                      className="border border-1"
                      variant="outline-primary"
                      size="sm"
                      disabled={each.lock}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTemplateClick(each.lock, each.path);
                      }}
                    >
                      View Templates
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Col>
        );
      })}
    </Row>
  );
}
