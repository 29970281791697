import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import TemplateManager from "../../../../components/templates";
import ContentWrapper from "../../../Wrapper";
import CampaignSubHeader from "../../components/campaign-common-sub-header";
import ChannelSelector from "../../components/channel-selection";

import * as TemplateActions from "../../store/actions/campaign_templates_actions";
import WhatsappTemplateList from "./whatsapp-template";

export default function CampaignTemplates(props) {
  const tabName = "Templates";
  const [showTemplateList, setShowTemplateList] = useState(false);
  const [showWhatsappTemplateList, setShowWhatsappTemplateList] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const { channel } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (channel === "whatsapp") {
      setShowWhatsappTemplateList(true);
      setSelectedChannel(channel);
    }
    else if (channel) {
      setShowTemplateList(true);
      setSelectedChannel(channel);
    } else {
      setShowTemplateList(false);
    }
  }, [channel]);

  const handleTemplateClose = () => {
    setShowTemplateList(false);
    setShowWhatsappTemplateList(false);
    history.push("/campaign/templates");
  };

  return (
    <ContentWrapper subHeader={<CampaignSubHeader active={tabName} />}>
      <ChannelSelector type={"template"} basePath="/campaign/templates" isTemplate={true} />
      <TemplateManager show={showTemplateList} channel={selectedChannel} category={"Marketing"} showChoose={false} onCloseClick={(data) => { }} handleClose={handleTemplateClose} />
      <WhatsappTemplateList show={showWhatsappTemplateList} channel={selectedChannel} showChoose={false} onCloseClick={(data) => { }} handleClose={handleTemplateClose} />
    </ContentWrapper>
  );
}
