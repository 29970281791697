export const getTemplateTags = {
  GET_TEMPLATE_TAGS_REQUEST: "GET_TEMPLATE_TAGS_REQUEST",
  GET_TEMPLATE_TAGS_SUCCESS: "GET_TEMPLATE_TAGS_SUCCESS",
  GET_TEMPLATE_TAGS_ERROR: "GET_TEMPLATE_TAGS_ERROR",
};
export const createTemplateTag = {
  CREATE_TEMPLATE_TAG_REQUEST: "CREATE_TEMPLATE_TAG_REQUEST",
  CREATE_TEMPLATE_TAG_SUCCESS: "CREATE_TEMPLATE_TAG_SUCCESS",
  CREATE_TEMPLATE_TAG_ERROR: "CREATE_TEMPLATE_TAG_ERROR",
};
export const deleteTemaplateTag = {
  DELETE_TEMPLATE_TAG_REQUEST: "DELETE_TEMPLATE_TAG_REQUEST",
  DELETE_TEMPLATE_TAG_SUCCESS: "DELETE_TEMPLATE_TAG_SUCCESS",
  DELETE_TEMPLATE_TAG_ERROR: "DELETE_TEMPLATE_TAG_ERROR",
};
export const editTemplateTag = {
  EDIT_TEMPLATE_TAG_REQUEST: "EDIT_TEMPLATE_TAG_REQUEST",
  EDIT_TEMPLATE_TAG_SUCCESS: "EDIT_TEMPLATE_TAG_SUCCESS",
  EDIT_TEMPLATE_TAG_ERROR: "EDIT_TEMPLATE_TAG_ERROR",
};

export const getTemplateCategory = {
  GET_TEMPLATE_CATEGORY_REQUEST: "GET_TEMPLATE_CATEGORY_REQUEST",
  GET_TEMPLATE_CATEGORY_SUCCESS: "GET_TEMPLATE_CATEGORY_SUCCESS",
  GET_TEMPLATE_CATEGORY_ERROR: "GET_TEMPLATE_CATEGORY_ERROR",
};

export const getTemplatetList = {
  GET_TEMPLATE_LIST_REQUEST: "GET_TEMPLATE_LIST_REQUEST",
  GET_TEMPLATE_LIST_SUCCESS: "GET_TEMPLATE_LIST_SUCCESS",
  GET_TEMPLATE_LIST_ERROR: "GET_TEMPLATE_LIST_ERROR",
};
export const createTemplate = {
  CREATE_TEMPLATE_REQUEST: "CREATE_TEMPLATE_REQUEST",
  CREATE_TEMPLATE_SUCCESS: "CREATE_TEMPLATE_SUCCESS",
  CREATE_TEMPLATE_ERROR: "CREATE_TEMPLATE_ERROR",
};
export const editTemplate = {
  EDIT_TEMPLATE_REQUEST: "EDIT_TEMPLATE_REQUEST",
  EDIT_TEMPLATE_SUCCESS: "EDIT_TEMPLATE_SUCCESS",
  EDIT_TEMPLATE_ERROR: "EDIT_TEMPLATE_ERROR",
};
export const deleteTemplate = {
  DELETE_TEMPLATE_REQUEST: "DELETE_TEMPLATE_REQUEST",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_ERROR: "DELETE_TEMPLATE_ERROR",
};

export const cloneTemplate = {
  CLONE_TEMPLATE_REQUEST: "CLONE_TEMPLATE_REQUEST",
  CLONE_TEMPLATE_SUCCESS: "CLONE_TEMPLATE_SUCCESS",
  CLONE_TEMPLATE_ERROR: "CLONE_TEMPLATE_ERROR",
};

export const UploadTemplate = {
  UPLOAD_TEMPLATE_REQUEST: "UPLOAD_TEMPLATE_REQUEST",
  UPLOAD_TEMPLATE_SUCCESS: "UPLOAD_TEMPLATE_SUCCESS",
  UPLOAD_TEMPLATE_ERROR: "UPLOAD_TEMPLATE_ERROR",
};
