import activityDataFieldSaga from "./activity_data_field_saga";
import activitySaga from "./activity_saga";
import statisticsSaga from "./statistics_saga";
import savedFiltersSaga from "./activity_saved_filters_saga";
import activityLossReasonSagsSaga from "./activity_loss_reasons_saga";
import activityTypesSaga from "./activity_type_saga";
import CalendarViewSaga from "./calendar_view_saga";
import ActivityOperationsSaga from "./activity_operations_saga";

export default [activitySaga, activityDataFieldSaga, statisticsSaga, savedFiltersSaga, activityLossReasonSagsSaga, activityTypesSaga, CalendarViewSaga, ActivityOperationsSaga];
