// packages
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";

// global components
import { ContentWithCardLoading } from "../../../../../components/loading";

// internal components
import CalendarSettings from "../components/calendar-settings";
import EmailSettings from "../components/email-settings";

// actions
import * as syncSettingActions from "../../../store/actions/profile-actions/sync-settings.actions";

// images
import noDataSvg from "../../../../../images/illustrations/__there-is-nothing-here.svg";
import { getListOfActiveActivityTypesRequest } from "../../../../Activities/store/actions/activity_types_actions";
import { DeleteModal } from "../../../../../components/modal";
import AccordionComponent from "../../../../../components/accordion/accordion-component";

export default function SyncSettingsOverview(props) {
  const [selectedSyncId, setSelectedSyncId] = useState(null);
  const [selectedSyncData, setSelectedSyncData] = useState(null);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);
  const activityActiveTypesStore = useSelector((store) => store.activity.types.active);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
    dispatch(getListOfActiveActivityTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSyncId) dispatch(syncSettingActions.getSyncCalendarListRequest({ syncId: selectedSyncId }));
  }, [dispatch, selectedSyncId]);

  useEffect(() => {
    const { syncId } = params;
    if (syncId) setSelectedSyncId(syncId);
    else history.push("/settings/profile/sync-settings");
  }, [params, history]);

  useEffect(() => {
    if (syncSettingsStore.data.length > 0 && selectedSyncId) {
      const find = syncSettingsStore.data.find((item) => item._id === selectedSyncId);
      if (find) {
        setSelectedSyncData(find);
      } else {
        history.push("/settings/profile/sync-settings");
      }
    }
  }, [syncSettingsStore.data, selectedSyncId, history]);

  useEffect(() => {
    if (activityActiveTypesStore.list && activityActiveTypesStore.list.length > 0) {
      setActivities(activityActiveTypesStore.list);
      setActivitiesOptions(
        activityActiveTypesStore.list.map((each) => {
          return { value: each._id, label: each.name };
        }),
      );
    }
  }, [activityActiveTypesStore.list]);

  const handleTrackingDetailsUpdate = (e, key, subKey) => {
    const updatePayload = {
      syncId: selectedSyncId,
      data: {
        tracking: {
          crm: { openTracking: true, linkTracking: true },
          workflow: { openTracking: true, linkTracking: true },
          cadence: { openTracking: true, linkTracking: true },
        },
      },
    };
    if (selectedSyncData && selectedSyncData.tracking) {
      updatePayload.data.tracking = selectedSyncData.tracking;
    }

    updatePayload.data.tracking[key][subKey] = e.target.checked;
    dispatch(syncSettingActions.updateSyncAccountDetailsRequest(updatePayload));
  };

  const onSyncTypeClick = (value) => {
    const updatePayload = {
      syncId: selectedSyncId,
      data: {
        syncType: value,
      },
    };
    dispatch(syncSettingActions.updateSyncAccountDetailsRequest(updatePayload));
  };
  const onActivityChange = (e, type) => {
    const synced = selectedSyncData.savedSyncActivities;
    if (e.target.checked) {
      const ids = Array.isArray(synced) ? [...synced] : [];
      ids.push(type);
      const updatePayload = {
        syncId: selectedSyncId,
        data: {
          savedSyncActivities: ids,
        },
      };
      dispatch(syncSettingActions.updateSyncAccountDetailsRequest(updatePayload));
    } else {
      const ids = Array.isArray(synced) ? [...synced] : [];
      const updatePayload = {
        syncId: selectedSyncId,
        data: {
          savedSyncActivities: ids.filter((a) => a !== type),
        },
      };
      dispatch(syncSettingActions.updateSyncAccountDetailsRequest(updatePayload));
    }
  };
  const onSelectCalendarChange = (data) => {
    const updatePayload = {
      syncId: selectedSyncId,
      data: {
        calendarId: data.value,
      },
    };
    dispatch(syncSettingActions.updateSyncAccountDetailsRequest(updatePayload));
  };
  const onCalendarEventChange = (e) => {
    const updatePayload = {
      syncId: selectedSyncId,
      data: {
        saveEvent: e.value,
      },
    };
    dispatch(syncSettingActions.updateSyncAccountDetailsRequest(updatePayload));
  };
  const onDeleteModalOpenClick = () => {
    setShowDeleteAccount(true);
  };
  const onHandleClose = () => {
    setShowDeleteAccount(false);
  };

  const onDeleteAccept = () => {
    setShowDeleteAccount(false);
    dispatch(syncSettingActions.deleteSyncAccountDetailsRequest({ syncId: selectedSyncId }));
    history.push("/settings/profile/sync-settings");
  };
  const deleteTemplateModalProps = {
    show: showDeleteAccount,
    title: "Delete Email Account",
    handleClose: () => onHandleClose(),
    handleAccept: () => onDeleteAccept(),
  };
  return (
    <div className="sync-settings-overview h-100">
      {syncSettingsStore.loading ? (
        <ContentWithCardLoading />
      ) : _.isEmpty(selectedSyncData) ? (
        <Card className={`${props.hideBorder ? "border-0" : ""} h-vh-160px bg-white`}>
          <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
            <div className="card-px text-center py-5 my-5">
              <h2 className="fs-2x fw-bolder mb-10 mt-5">{"Data Not Found"}</h2>
            </div>
            <img src={noDataSvg} className="mx-auto h-vh-450px" alt="add" />
          </Card.Body>
        </Card>
      ) : (
        <div className="card mb-5 mb-xl-10 h-vh-160px">
          <div className="card-header border-0 cursor-pointer">
            <div className="card-title m-0 d-flex flex-stack w-100">
              <div className="d-flex align-items-center gap-3">
                <div className="bg-hover-gray-300 rounded-1 w-30px h-30px d-flex flex-center">
                  <i className="bi bi-arrow-left-short fs-1 text-black" onClick={() => history.push("/settings/profile/sync-settings")}></i>
                </div>
                <h3 className="fw-bolder m-0">{selectedSyncData.syncedEmail}</h3>
              </div>
              <div>
                <Button size="sm" variant="danger" className="btn btn-danger ml-2" onClick={() => onDeleteModalOpenClick()}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
          <div className="collapse show">
            <div className="card-body border-top p-9 overflow-y-auto h-vh-220px-mx">
              <AccordionComponent defaultActiveKey={"0"} title="Email Settings" body="Email Settings">
                <EmailSettings selectedSyncData={selectedSyncData} handleTrackingDetailsUpdate={handleTrackingDetailsUpdate} />
              </AccordionComponent>
              <AccordionComponent defaultActiveKey={"0"} title="Calendar Settings" body="Calendar Settings">
                <CalendarSettings
                  selectedSyncData={selectedSyncData}
                  activitiesOptions={activitiesOptions}
                  calendarList={syncSettingsStore.calenderList}
                  onSelectCalendarChange={onSelectCalendarChange}
                  onCalendarEventChange={onCalendarEventChange}
                  onActivityChange={onActivityChange}
                  activities={activities}
                  onSyncTypeClick={onSyncTypeClick}
                />
              </AccordionComponent>
            </div>
          </div>
        </div>
      )}
      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete email account?</DeleteModal>
    </div>
  );
}
