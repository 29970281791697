import { KeyboardTimePicker } from "@material-ui/pickers";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

export default function MaterialUiTimePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  useEffect(() => {
    if (props.value) {
      const timeParts = props.value.split(":");
      const today = new Date();
      const todayWithTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), timeParts[0], timeParts[1], "00");
      setSelectedDate(todayWithTime);
    }
  }, [props.value]);

  const handleDateChange = (date) => {
    const now = new Date(date);
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    props.setValue(formattedTime);
  };

  return (
    <Form.Group className="mb-0">
      <Form.Label aria-label="from-time">{props.label}</Form.Label>
      <KeyboardTimePicker
        className="m-0 border px-2 py-1 rounded-2 border-secondary"
        margin="normal"
        id="time-picker"
        label=""
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Form.Group>
  );
}
