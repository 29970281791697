import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ total = 0, itemsPerPage = 10, currentPage = 1, onPageChange }) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }
    if (
      pages.length > 5 &&
      pages.length - 4 !== currentPage &&
      pages.length > 5 &&
      pages.length - 3 !== currentPage &&
      pages.length > 5 &&
      pages.length - 2 !== currentPage &&
      pages.length > 5 &&
      pages.length - 1 !== currentPage &&
      pages.length > 5 &&
      pages.length !== currentPage
    ) {
      return pages.slice(currentPage - 1, currentPage + 4);
    } else if (
      (pages.length > 5 && pages.length - 4 === currentPage) ||
      (pages.length > 5 && pages.length - 3 === currentPage) ||
      (pages.length > 5 && pages.length - 2 === currentPage) ||
      (pages.length > 5 && pages.length - 1 === currentPage) ||
      (pages.length > 5 && pages.length === currentPage)
    ) {
      return pages.slice(pages.length - 5, pages.length);
    } else if (pages.length <= 5) {
      return pages;
    }
  }, [totalPages, currentPage, onPageChange]);

  if (totalPages === 0) return null;

  return (
    <>
      <Pagination>
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
        {paginationItems}
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
      </Pagination>
    </>
  );
};

export default PaginationComponent;
