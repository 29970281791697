import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function EmailToRecipientField(props) {
  const [value, setValue] = useState([]);
  const [ids, setIds] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const userRole = useSelector((store) => store.insight.userRole.list);

  useState(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (userRole.users && Array.isArray(userRole.users) && userRole.users.length > 0) setUserOptions(userRole.users);
  }, [userRole.users]);

  const handleSelect = (each, type) => {
    const selectValue = {
      id: each.id,
      type: type,
      name: each.name,
      email: each.email,
      displayValue: `${each.name} (${each.email})`,
    };
    setValue([...value, selectValue]);
    props.setValue([...value, selectValue]);
    setIds([...ids, each.id]);
    setUserOptions(userOptions.filter((a) => a.id !== each.id));
  };

  const removeUserField = (each) => {
    setValue(value.filter((a) => a.id !== each.id));
    props.setValue(value.filter((a) => a.id !== each.id));
    setIds(ids.filter((a) => a !== each.id));
    setUserOptions(userRole.users.filter((a) => !ids.includes(a.id) || a.id === each.id));
  };

  return (
    <Dropdown className="mt-0 w-100">
      <Dropdown.Toggle className="d-flex flex-wrap gap-2 align-items-center w-100 text-left py-10-point-4px px-9-point-6px border-none" variant="transparent">
        {value.length > 0 ? (
          <>
            {value.map((each, ind) => {
              return (
                <div key={ind} className="badge badge-light-info d-flex gap-2 align-items-center">
                  {each.name} ({each.email})<i onClick={() => removeUserField(each)} className="bi bi-x"></i>
                </div>
              );
            })}
          </>
        ) : (
          "Select Recipients"
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-y-auto">
          {userOptions.length > 0 && <Dropdown.Header>Users</Dropdown.Header>}
          {userOptions.map((each, index) => {
            return (
              <Dropdown.Item key={index} className="dropdown-hover" onSelect={(e) => handleSelect(each, "users")}>
                {each.name} ({each.email})
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
