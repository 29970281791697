import { getActivityLossReasons, getActivityLossReasonsSettings, updateActivityLossReasonsSettings } from "../types/activity.types";

export const getActivityLossReasonsRequest = (req) => ({
  type: getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_REQUEST,
  payload: req,
});
export const getActivityLossReasonsSuccess = (req) => ({
  type: getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_SUCCESS,
  payload: req,
});
export const getActivityLossReasonsError = (req) => ({
  type: getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_ERROR,
  payload: req,
});

export const getActivityLossReasonsSettingsRequest = (req) => ({
  type: getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST,
  payload: req,
});
export const getActivityLossReasonsSettingsSuccess = (req) => ({
  type: getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS,
  payload: req,
});
export const getActivityLossReasonsSettingsError = (req) => ({
  type: getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR,
  payload: req,
});

export const updateActivityLossReasonsSettingsRequest = (req) => ({
  type: updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST,
  payload: req,
});
export const updateActivityLossReasonsSettingsSuccess = (req) => ({
  type: updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS,
  payload: req,
});
export const updateActivityLossReasonsSettingsError = (req) => ({
  type: updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR,
  payload: req,
});
