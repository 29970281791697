import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../deals/utils";
import { DeleteModal } from "../../../components/modal";
import { DeleteIconButton, EditIconButton } from "../../../components/button/icon-action-buttons";

export default function QuestionSettings(props) {
  const [deletableItem, setDeletableItem] = useState({});
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(props.questions, result.source.index, result.destination.index);
    props.setQuestions(items);
  };

  const onDelete = (item) => {
    setDeletableItem(item);
    setShowDeleteQuestion(true);
  };

  const onAccepetDelete = () => {
    setShowDeleteQuestion(false);
    props.setQuestions(props.questions.filter((a) => a !== deletableItem));
    setDeletableItem({});
  };

  const deleteLeadBotModelProps = {
    show: showDeleteQuestion,
    title: "Delete Question",
    handleClose: () => {
      setShowDeleteQuestion(false);
      setDeletableItem({});
    },
    handleAccept: onAccepetDelete,
  };

  const getDefaultAnswer = (answer, label) => {
    if (answer && label) return label;
    if (Array.isArray(answer)) return answer.join(", ");
    else if (typeof answer === "boolean") return answer === true ? "Yes" : "No";
    else return answer;
  };

  return (
    <div className="reorder-add-form-wrapper">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="w-100">
                <div className="head-field w-100">
                  <span className="w-17 text-center">Question</span>
                  <span className="w-17 text-center">Field Input Name</span>
                  <span className="w-17 text-center">Is Mandatory</span>
                  <span className="w-17 text-center">Is Hidden</span>
                  <span className="w-17 text-center">Default Answer</span>
                  <span className="w-15 text-center">Actions</span>
                </div>
                <div>
                  {props.questions.map((item, index) => (
                    <Draggable draggableId={index.toString()} index={index} key={index} type="TASK">
                      {(provided, snapshot) => (
                        <div className="field-row btn btn-outline btn-outline-dashed btn-outline-default p-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <span className="w-17 text-center p-5px">{item.question}</span>
                          <span className="w-17 text-center">{props.fieldMapping[item.fieldId] ? props.fieldMapping[item.fieldId] : ""}</span>
                          <span className="w-17 text-center p-5px">{item.isMandatory ? "Yes" : "No"}</span>
                          <span className="w-17 text-center">{item.isHidden ? "Yes" : "No"}</span>
                          <span className="w-17 text-center p-5px">{getDefaultAnswer(item.defaultAnswer, item.label)}</span>
                          <span className="d-flex justify-content-center w-15prc">
                            <EditIconButton onClick={() => props.onEdit(index)} />
                            <DeleteIconButton onClick={() => onDelete(item)} />
                            <span className="import-icon-container">
                              <i className="fa fa-bars fa-md font-size-18px" />
                            </span>
                          </span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete a question node?</DeleteModal>
    </div>
  );
}
