import {
  LEADS_REQUEST,
  LEADS_SUCCESS,
  LEADS_ERROR,
  LEAD_POPUP_REQUEST,
  LEAD_POPUP_SUCCESS,
  LEAD_POPUP_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  LEADFIELDS_REQUEST,
  LEADFIELDS_SUCCESS,
  LEADFIELDS_ERROR,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  LEADADDFIELDS_REQUEST,
  LEADADDFIELDS_SUCCESS,
  LEADADDFIELDS_ERROR,
  LEADTAGS_REQUEST,
  LEADTAGS_SUCCESS,
  LEADTAGS_ERROR,
  LEADTAGSADD_REQUEST,
  LEADTAGSADD_SUCCESS,
  LEADTAGSADD_ERROR,
  LEADTAGSEDIT_REQUEST,
  LEADTAGSEDIT_SUCCESS,
  LEADTAGSEDIT_ERROR,
  LEADTAGSDELETE_REQUEST,
  LEADTAGSDELETE_SUCCESS,
  LEADTAGSDELETE_ERROR,
  LEADCELLEDIT_REQUEST,
  LEADCELLEDIT_SUCCESS,
  LEADCELLEDIT_ERROR,
  LEADADD_REQUEST,
  LEADADD_SUCCESS,
  LEADADD_ERROR,
  LEADBULKEDIT_REQUEST,
  LEADBULKEDIT_SUCCESS,
  LEADBULKEDIT_ERROR,
  LEADDELETE_REQUEST,
  LEADDELETE_SUCCESS,
  LEADDELETE_ERROR,
  LEADFILTERS_REQUEST,
  LEADFILTERS_SUCCESS,
  LEADFILTERS_ERROR,
  LEADFILTERADD_REQUEST,
  LEADFILTERADD_SUCCESS,
  LEADFILTERADD_ERROR,
  LEADFILTEREDIT_REQUEST,
  LEADFILTEREDIT_SUCCESS,
  LEADFILTEREDIT_ERROR,
  LEADFILTERFAVORITE_REQUEST,
  LEADFILTERFAVORITE_SUCCESS,
  LEADFILTERFAVORITE_ERROR,
  LEADFILTERDELETE_REQUEST,
  LEADFILTERDELETE_SUCCESS,
  LEADFILTERDELETE_ERROR,
  LEADS_UPDATE_REQUEST,
  LEADS_UPDATE_SUCCESS,
  LEADS_UPDATE_ERROR,
  LEAD_POPUP_UPDATE_REQUEST,
  LEAD_POPUP_UPDATE_SUCCESS,
  LEAD_POPUP_UPDATE_ERROR,
  LEAD_POPUP_EDIT_REQUEST,
  LEAD_POPUP_EDIT_SUCCESS,
  LEAD_POPUP_EDIT_ERROR,
  LEAD_CONVERT_TO_JUNK_REQUEST,
  LEAD_CONVERT_TO_JUNK_SUCCESS,
  LEAD_CONVERT_TO_JUNK_ERROR,
  LEAD_CONVERT_TO_ACTIVE_ERROR,
  LEAD_CONVERT_TO_ACTIVE_SUCCESS,
  LEAD_CONVERT_TO_ACTIVE_REQUEST,
  LOADING_DATA_UPDATE,
  LEADSMERGE_REQUEST,
  LEADSMERGE_SUCCESS,
  LEADSMERGE_ERROR,
  APPLYFILTER,
  CLEARFILTER,
  SIGNATURE_GET_REQUEST,
  SIGNATURE_GET_SUCCESS,
  SIGNATURE_GET_ERROR,
  SIGNATURE_ADD_ERROR,
  SIGNATURE_ADD_SUCCESS,
  SIGNATURE_ADD_REQUEST,
  SIGNATURE_EDIT_REQUEST,
  SIGNATURE_EDIT_SUCCESS,
  SIGNATURE_EDIT_ERROR,
  SIGNATURE_DELETE_REQUEST,
  SIGNATURE_DELETE_SUCCESS,
  SIGNATURE_DELETE_ERROR,
  CLEAR_SIGNATURE_STATUS,
  NOTE_ADD_REQUEST,
  NOTE_ADD_SUCCESS,
  NOTE_ADD_ERROR,
  CLEAR_NOTE_STATUS,
  SEARCH_ALL_REQUEST,
  SEARCH_ALL_SUCCESS,
  SEARCH_ALL_ERROR,
  GET_LEADLOSTREASONS_REQUEST,
  GET_LEADLOSTREASONS_SUCCESS,
  GET_LEADLOSTREASONS_ERROR,
  LEAD_CONVERT_TO_PEOPLE_REQUEST,
  LEAD_CONVERT_TO_PEOPLE_SUCCESS,
  LEAD_CONVERT_TO_PEOPLE_ERROR,
  bulkDeleteLeadV2,
  bulkEditLeadV2,
  generateLeadAIWFData
} from "../types/types";

const initialState = {
  leadList: {
    loading: "",
    list: [],
    count: 0,
    cellLoading: false,
  },
  leadFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  leadPopup: {
    loading: "",
    data: {},
    cellLoading: false,
  },
  leadAddFields: {
    loading: "",
    list: [],
  },
  leadTags: {
    loading: "",
    list: [],
  },
  leadFilters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
  loadingData: {
    pageNo: 1,
    limit: 50,
    type: "active_lead",
  },
  signatureList: {
    loading: "",
    list: [],
    success: false,
    successMsg: "",
    err: false,
    errMsg: "",
    defaultSignature: null,
  },
  noteList: {
    loading: "",
    list: [],
    success: false,
    successMsg: "",
    err: false,
    errMsg: "",
  },
  searchAll: {
    loading: "",
    data: [],
  },
  lostReasons: [],
  // you can define required variables here
};

export function leadsReducer(state = initialState, action = {}) {
  switch (action.type) {
    //lost reasons
    case GET_LEADLOSTREASONS_REQUEST:
      return {
        ...state,
        lostReasons: [],
      };
    case GET_LEADLOSTREASONS_SUCCESS:
      return {
        ...state,
        lostReasons: action.payload,
      };
    case GET_LEADLOSTREASONS_ERROR:
      return {
        ...state,
        lostReasons: [],
      };
    case SEARCH_ALL_REQUEST:
      return {
        ...state,
        searchAll: { ...state.searchAll, loading: true },
      };
    case SEARCH_ALL_SUCCESS:
      return {
        ...state,
        searchAll: { ...state.searchAll, loading: false, data: action.payload },
      };
    case SEARCH_ALL_ERROR:
      return {
        ...state,
        searchAll: { ...state.searchAll, loading: false },
      };
    case LEADS_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
      };
    case LEADS_SUCCESS:
      return {
        ...state,
        leadList: {
          loading: false,
          list: action.payload.data,
          count: action.payload.count,
        },
      };
    case LEADS_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    //Update loading data
    case LOADING_DATA_UPDATE:
      return {
        ...state,
        loadingData: action.payload,
      };

    // Getting Lead Popup Data
    case LEAD_POPUP_REQUEST:
      return {
        ...state,
        leadPopup: { loading: true, data: {} },
      };
    case LEAD_POPUP_SUCCESS:
      return {
        ...state,
        leadPopup: { loading: false, data: action.payload.data },
      };
    case LEAD_POPUP_ERROR:
      return {
        ...state,
        leadPopup: { loading: false, data: {} },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Lead Popup Cell
    case LEAD_POPUP_EDIT_REQUEST:
      return {
        ...state,
        leadPopup: {
          ...state.leadPopup,
          loading: false,
          cellLoading: action.payload.cellId,
        },
      };
    case LEAD_POPUP_EDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEAD_POPUP_EDIT_ERROR:
      return {
        ...state,
        leadPopup: { ...state.leadPopup, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Updating Lead Popup Data
    case LEAD_POPUP_UPDATE_REQUEST:
      return {
        ...state,
        leadPopup: { ...state.leadPopup, cellLoading: action.payload.cellId },
      };
    case LEAD_POPUP_UPDATE_SUCCESS:
      return {
        ...state,
        leadPopup: {
          loading: false,
          data: action.payload.data,
          cellLoading: false,
        },
      };
    case LEAD_POPUP_UPDATE_ERROR:
      return {
        ...state,
        leadPopup: { ...state.leadPopup, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case LEADS_UPDATE_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, cellLoading: action.payload.cellId },
      };
    case LEADS_UPDATE_SUCCESS:
      return {
        ...state,
        leadList: {
          loading: false,
          list: action.payload.data,
          count: action.payload.count,
          cellLoading: false,
        },
      };
    case LEADS_UPDATE_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, cellLoading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    // Clear error or success
    case CLEAR_ERROR:
      return {
        ...state,
        error: { err: false, errMsg: "" },
      };

    case CLEAR_SUCCESS:
      return {
        ...state,
        success: { success: false, successMsg: "" },
      };

    // Fields
    case LEADFIELDS_REQUEST:
      return {
        ...state,
        leadFields: { ...state.leadFields, loading: action.payload.skipIfDataPresent ? false : true },
      };
    case LEADFIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        leadFields: {
          loading: false,
          list: action.payload,
          idToIndex: tempObj,
        },
      };

    // Update fields
    case FIELD_UPDATE_REQUEST:
      return {
        ...state,
      };
    case FIELD_UPDATE_SUCCESS:
      var tempUpdate = {};
      for (var j = 0; j < action.payload.length; j++) {
        tempUpdate[action.payload[j]["_id"]] = j;
      }
      return {
        ...state,
        productFields: { list: action.payload, idToIndex: tempUpdate },
      };
    case FIELD_UPDATE_ERROR:
      return {
        ...state,
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Fields
    case LEADADDFIELDS_REQUEST:
      return {
        ...state,
        leadAddFields: { ...state.leadAddFields, loading: true },
      };
    case LEADADDFIELDS_SUCCESS:
      return {
        ...state,
        leadAddFields: { loading: false, list: action.payload },
      };
    case LEADADDFIELDS_ERROR:
      return {
        ...state,
        leadAddFields: { ...state.leadAddFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case LEADFIELDS_ERROR:
      return {
        ...state,
        leadFields: { ...state.leadFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Lead
    case LEADADD_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
      };
    case LEADADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADADD_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Bulk Edit Lead
    case LEADBULKEDIT_REQUEST:
    case bulkEditLeadV2.BULK_EDIT_LEAD_V2_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
      };
    case LEADBULKEDIT_SUCCESS:
    case bulkEditLeadV2.BULK_EDIT_LEAD_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADBULKEDIT_ERROR:
    case bulkEditLeadV2.BULK_EDIT_LEAD_V2_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Lead
    case LEADDELETE_REQUEST:
    case bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
      };
    case LEADDELETE_SUCCESS:
    case bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADDELETE_ERROR:
    case bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Convert to Junk Lead
    case LEAD_CONVERT_TO_JUNK_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
        leadPopup: { ...state.leadPopup, loading: true },
      };
    case LEAD_CONVERT_TO_JUNK_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEAD_CONVERT_TO_JUNK_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Convert to People
    case LEAD_CONVERT_TO_PEOPLE_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
        leadPopup: { ...state.leadPopup, loading: true },
      };
    case LEAD_CONVERT_TO_PEOPLE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEAD_CONVERT_TO_PEOPLE_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Convert to Active Lead
    case LEAD_CONVERT_TO_ACTIVE_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
        leadPopup: { ...state.leadPopup, loading: true },
      };
    case LEAD_CONVERT_TO_ACTIVE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEAD_CONVERT_TO_ACTIVE_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // GET Tags
    case LEADTAGS_REQUEST:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: true },
      };
    case LEADTAGS_SUCCESS:
      return {
        ...state,
        leadTags: { loading: false, list: action.payload },
      };
    case LEADTAGS_ERROR:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Tag
    case LEADTAGSADD_REQUEST:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: true },
      };
    case LEADTAGSADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADTAGSADD_ERROR:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Tag
    case LEADTAGSEDIT_REQUEST:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: true },
      };
    case LEADTAGSEDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADTAGSEDIT_ERROR:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Tag
    case LEADTAGSDELETE_REQUEST:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: true },
      };
    case LEADTAGSDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADTAGSDELETE_ERROR:
      return {
        ...state,
        leadTags: { ...state.leadTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Cell
    case LEADCELLEDIT_REQUEST:
    case generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_REQUEST:
      return {
        ...state,
        leadList: {
          ...state.leadList,
          loading: false,
          cellLoading: action.payload.cellId,
        },
        leadPopup: { ...state.leadPopup, cellLoading: action.payload.cellId },
      };
    case LEADCELLEDIT_SUCCESS:
    case generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
        leadPopup: { ...state.leadPopup, cellLoading: false },
      };
    case LEADCELLEDIT_ERROR:
    case generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
        leadPopup: { ...state.leadPopup, cellLoading: false },
      };

    //Getting Filters
    case LEADFILTERS_REQUEST:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: true },
      };
    case LEADFILTERS_SUCCESS:
      return {
        ...state,
        leadFilters: {
          ...state.leadFilters,
          loading: false,
          list: action.payload.data,
        },
      };
    case LEADFILTERS_ERROR:
      return {
        ...state,
        leadFilters: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Adding Filter
    case LEADFILTERADD_REQUEST:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: true },
      };
    case LEADFILTERADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADFILTERADD_ERROR:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Editing Filter
    case LEADFILTEREDIT_REQUEST:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: true },
      };
    case LEADFILTEREDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADFILTEREDIT_ERROR:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Favoriting Filter
    case LEADFILTERFAVORITE_REQUEST:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: true },
      };
    case LEADFILTERFAVORITE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADFILTERFAVORITE_ERROR:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Deleting Filter
    case LEADFILTERDELETE_REQUEST:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: true },
      };
    case LEADFILTERDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADFILTERDELETE_ERROR:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Merge
    case LEADSMERGE_REQUEST:
      return {
        ...state,
        leadList: { ...state.leadList, loading: true },
      };
    case LEADSMERGE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case LEADSMERGE_ERROR:
      return {
        ...state,
        leadList: { ...state.leadList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case APPLYFILTER:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, activeFilter: action.payload },
      };
    case CLEARFILTER:
      return {
        ...state,
        leadFilters: { ...state.leadFilters, activeFilter: null },
      };
    case SIGNATURE_GET_REQUEST:
      return {
        ...state,
        signatureList: { ...state.signatureList, loading: true },
      };
    case SIGNATURE_GET_SUCCESS:
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: false,
          list: action.payload.data,
          defaultSignature: action.payload.data.find((sig) => sig.isDefault === true),
        },
      };
    case SIGNATURE_GET_ERROR: {
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: false,
          err: true,
          errMsg: action.payload.message,
        },
      };
    }
    case SIGNATURE_ADD_REQUEST:
      return {
        ...state,
        signatureList: { ...state.signatureList, loading: true },
      };
    case SIGNATURE_ADD_SUCCESS:
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: true,
          success: true,
          successMsg: action.payload.message,
        },
      };
    case SIGNATURE_ADD_ERROR: {
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: false,
          err: true,
          errMsg: action.payload.message,
        },
      };
    }
    case SIGNATURE_EDIT_REQUEST:
      return {
        ...state,
        signatureList: { ...state.signatureList, loading: true },
      };
    case SIGNATURE_EDIT_SUCCESS:
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: true,
          success: true,
          successMsg: action.payload.message,
        },
      };
    case SIGNATURE_EDIT_ERROR: {
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: false,
          err: true,
          errMsg: action.payload.message,
        },
      };
    }
    case SIGNATURE_DELETE_REQUEST:
      return {
        ...state,
        signatureList: { ...state.signatureList, loading: true },
      };
    case SIGNATURE_DELETE_SUCCESS:
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: true,
          success: true,
          successMsg: action.payload.message,
        },
      };
    case SIGNATURE_DELETE_ERROR: {
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          loading: false,
          err: true,
          errMsg: action.payload.message,
        },
      };
    }
    case CLEAR_SIGNATURE_STATUS: {
      return {
        ...state,
        signatureList: {
          ...state.signatureList,
          err: false,
          errMsg: "",
          success: false,
          successMsg: "",
        },
      };
    }
    case NOTE_ADD_REQUEST:
      return {
        ...state,
        noteList: { ...state.noteList, loading: true },
      };
    case NOTE_ADD_SUCCESS:
      return {
        ...state,
        noteList: {
          ...state.noteList,
          loading: false,
          success: true,
          successMsg: action.payload.message,
        },
      };
    case NOTE_ADD_ERROR: {
      return {
        ...state,
        noteList: {
          ...state.noteList,
          loading: false,
          err: true,
          errMsg: action.payload.message,
        },
      };
    }
    case CLEAR_NOTE_STATUS: {
      return {
        ...state,
        noteList: {
          ...state.noteList,
          err: false,
          errMsg: "",
          success: false,
          successMsg: "",
        },
      };
    }
    default:
      return state;
  }
}
