import React from "react";
import { Dropdown, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function AddSelectPipelineDropdown(props) {
  const pipelineStore = useSelector((store) => store.DealPipeline);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  return (
    <div className="d-flex">
      <Dropdown onToggle={() => props.setShow(!props.show)} show={props.show}>
        <Dropdown.Toggle variant="secondary" id="dropdown-autoclose-outside" className="pipeline-dropdown custom-secondory-cta-action h-35px">
          {props.selectedPipeline}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {pipelineStore.pipelineOptionsLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center h-200px">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="overflow-y-auto h-200px-mx w-240px">
              {props.options.map((pipeline, index) => (
                <Dropdown.Item
                  className="dropdown-hover d-flex justify-content-between"
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onPipelineChange(pipeline, index);
                    props.setShow(true);
                  }}
                >
                  {pipeline.name}

                  <span className="d-flex gap-2 align-items-center justify-content-end">
                    <OverlayTrigger overlay={<Tooltip>Mark as default</Tooltip>}>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setAsDefault(pipeline);
                        }}
                        className="btn btn-icon btn-light h-25px w-25px"
                      >
                        <i className={`fa fa-md fa-star font-size-13px ${pipeline.isDefault ? "text-f6c61b" : ""}`} aria-hidden="true"></i>
                      </button>
                    </OverlayTrigger>
                    {userProfile.data.userType && ["OWNER", "ADMIN"].includes(userProfile.data.userType) && (
                      <OverlayTrigger overlay={<Tooltip>Edit Pipeline</Tooltip>}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            props.onPipelineEditIconClick(pipeline);
                          }}
                          className="btn btn-icon btn-light h-25px w-25px"
                        >
                          <i className="bi bi-gear font-size-13px"></i>
                        </button>
                      </OverlayTrigger>
                    )}
                  </span>
                </Dropdown.Item>
              ))}
            </div>
          )}
          {userProfile.data.userType && ["OWNER", "ADMIN"].includes(userProfile.data.userType) && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item className="dropdown-hover" onClick={() => props.onAddPipelineClick()}>
                + Add Pipeline
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
