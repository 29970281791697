import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

// import { CustomActivityButtonGroup } from "../../../../../../components/button/custom-group-button";
import { InputLimit } from "../../../../../../constants";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { GreenGradientButton } from "../../../../../../components/button";
import { isEmpty } from "lodash";

export default function ActivityStepForm(props) {
  const [inputIconFields, setInputIconFields] = useState([]);
  const [activityText, setActivityText] = useState("");
  const [activityType, setActivityType] = useState("");
  const [activeIcon, setActiveIcon] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const activityStore = useSelector((store) => store.activity.types.active.list);

  useEffect(() => {
    setInputIconFields(activityStore);
  }, [activityStore]);

  useEffect(() => {
    if (props.selectedActivity && activityStore) {
      const field = activityStore.find((each) => each._id === props.selectedActivity);
      if (field) {
        setActiveIcon(field.name);
        setActivityType(field._id);
        setActivityText(field.name);
      }
    }
  }, [props.selectedActivity, activityStore]);

  useEffect(() => {
    if (props.isEdit && !isEmpty(props.selectedStep)) {
      setActivityText(props.selectedStep.activityContent.activityTitle);
      setActivityType(props.selectedStep.activityContent.activityType);
      const _activityType = inputIconFields.find((each) => each._id === props.selectedStep.activityContent.activityType);
      if (_activityType) {
        setActiveIcon(_activityType.name);
      }
      if (props.selectedStep.activityContent.description) setDescriptionText(props.selectedStep.activityContent.description);
    }
  }, [props.isEdit, props.selectedStep, inputIconFields]);

  // const onIconFieldChange = (field) => {
  //   setActiveIcon(field.name);
  //   setActivityType(field._id);
  //   setActivityText(field.name);
  // };

  const onActivitySaveClick = () => {
    if (!activityType || !activityText) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    }
    if (props.isEdit) {
      const payload = {
        ...props.selectedStep,
        name: activityText,
        activityContent: {
          activityType: activityType,
          description: descriptionText,
          activityTitle: activityText,
        },
      };
      props.handleEdit(props.selectedIndex, payload);
    } else {
      const payload = {
        name: activityText,
        executionOrder: props.totalSteps + 1,
        channelType: "TASK",
        delay: props.totalSteps === 0 ? 300 : 86400,
        isEnabled: true,
        activityContent: {
          activityType: activityType,
          description: descriptionText,
          activityTitle: activityText,
        },
      };
      props.handleAdd(payload);
    }
  };

  return (
    <Form.Group>
      {/* <Form.Group id="activity-type">
        <Form.Label aria-label="activity-type">Activity Type*</Form.Label>
        <CustomActivityButtonGroup options={inputIconFields} handleClick={onIconFieldChange} active={activeIcon} />
      </Form.Group> */}
      <Form.Group id="activity-form-name">
        <Form.Label aria-label="activity-name">Activity Name*</Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={activeIcon} value={activityText} onChange={(e) => setActivityText(e.target.value)} />
      </Form.Group>
      <Form.Group id="activity-form-description">
        <Form.Label aria-label="description">Description</Form.Label>
        <textarea value={descriptionText} className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescriptionText(e.target.value)}></textarea>
      </Form.Group>
      <Form.Group id="activity-form-save" className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={onActivitySaveClick}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </Form.Group>
  );
}
