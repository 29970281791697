// Path: src/containers/Settings/store/reducers/profile-settings/sync-settings.reducer.js

import {
  getHostedAuthLink,
  hostedAuthCallback,
  getListOfSyncAccounts,
  updateSyncAccountDetails,
  deleteSyncAccountDetails,
  getSyncCalendarList,
  sendNyalsSyncEmail,
  uploadSyncNylasAttchment,
  markAsDefaultSyncAccount,
} from "../../types/profile-settings/sync-settings.types";

export const getHostedAuthLinkRequest = (data, cb) => ({
  type: getHostedAuthLink.REQUEST,
  payload: data,
  cb,
});
export const getHostedAuthLinkSuccess = (data) => ({
  type: getHostedAuthLink.SUCCESS,
  payload: data,
});
export const getHostedAuthLinkError = (data) => ({
  type: getHostedAuthLink.ERROR,
  payload: data,
});

export const hostedAuthCallbackRequest = (data, cb) => ({
  type: hostedAuthCallback.REQUEST,
  payload: data,
  cb,
});
export const hostedAuthCallbackSuccess = (data) => ({
  type: hostedAuthCallback.SUCCESS,
  payload: data,
});
export const hostedAuthCallbackError = (data) => ({
  type: hostedAuthCallback.ERROR,
  payload: data,
});

export const getListOfSyncAccountsRequest = (data, cb) => ({
  type: getListOfSyncAccounts.REQUEST,
  payload: data,
  cb,
});
export const getListOfSyncAccountsSecondaryRequest = (data, cb) => ({
  type: getListOfSyncAccounts.SECONDARY_REQUEST,
  payload: data,
  cb,
});
export const getListOfSyncAccountsSuccess = (data) => ({
  type: getListOfSyncAccounts.SUCCESS,
  payload: data,
});
export const getListOfSyncAccountsError = (data) => ({
  type: getListOfSyncAccounts.ERROR,
  payload: data,
});

export const updateSyncAccountDetailsRequest = (data, cb) => ({
  type: updateSyncAccountDetails.REQUEST,
  payload: data,
  cb,
});
export const updateSyncAccountDetailsSuccess = (data) => ({
  type: updateSyncAccountDetails.SUCCESS,
  payload: data,
});
export const updateSyncAccountDetailsError = (data) => ({
  type: updateSyncAccountDetails.ERROR,
  payload: data,
});

export const deleteSyncAccountDetailsRequest = (data, cb) => ({
  type: deleteSyncAccountDetails.REQUEST,
  payload: data,
  cb,
});
export const deleteSyncAccountDetailsSuccess = (data) => ({
  type: deleteSyncAccountDetails.SUCCESS,
  payload: data,
});
export const deleteSyncAccountDetailsError = (data) => ({
  type: deleteSyncAccountDetails.ERROR,
  payload: data,
});

export const getSyncCalendarListRequest = (data, cb) => ({
  type: getSyncCalendarList.REQUEST,
  payload: data,
  cb,
});
export const getSyncCalendarListSuccess = (data) => ({
  type: getSyncCalendarList.SUCCESS,
  payload: data,
});
export const getSyncCalendarListError = (data) => ({
  type: getSyncCalendarList.ERROR,
  payload: data,
});

export const sendNyalsSyncEmailRequest = (data, cb) => ({
  type: sendNyalsSyncEmail.REQUEST,
  payload: data,
  cb,
});
export const sendNyalsSyncEmailSuccess = (data) => ({
  type: sendNyalsSyncEmail.SUCCESS,
  payload: data,
});
export const sendNyalsSyncEmailError = (data) => ({
  type: sendNyalsSyncEmail.ERROR,
  payload: data,
});

export const uploadSyncNylasAttchmentRequest = (data, cb) => ({
  type: uploadSyncNylasAttchment.REQUEST,
  payload: data,
  cb,
});
export const uploadSyncNylasAttchmentSuccess = (data) => ({
  type: uploadSyncNylasAttchment.SUCCESS,
  payload: data,
});
export const uploadSyncNylasAttchmentError = (data) => ({
  type: uploadSyncNylasAttchment.ERROR,
  payload: data,
});

export const markAsDefaultSyncAccountRequest = (data, cb) => ({
  type: markAsDefaultSyncAccount.REQUEST,
  payload: data,
  cb,
});
export const markAsDefaultSyncAccountSuccess = (data) => ({
  type: markAsDefaultSyncAccount.SUCCESS,
  payload: data,
});
export const markAsDefaultSyncAccountError = (data) => ({
  type: markAsDefaultSyncAccount.ERROR,
  payload: data,
});
