import React, { useState } from "react";
import InlineStyled from "../inline-styled/inline-styled";

export default function DynamicJustTable(props) {
  const [heading] = useState(props.tableHeading);

  return (
    <>
      <div className="card overflow-y-auto h-vh-200px-mx border-style-hidden">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
              <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  {heading.map((each, index) => {
                    return (
                      <InlineStyled as="td" className={index !== 0 ? "text-center" : "text-left"} styles={{ width: `${100 / heading.length}%` }} key={index}>
                        {each}
                      </InlineStyled>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="fw-6 fw-bold text-gray-600">{props.children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
