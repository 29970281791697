import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Workflow from "./workflow";
import Cadence from "./cadence";
// import ComingSoon from "./coming-soon";

export default function Sequences(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route path={`${match.url}/cadence`} component={Cadence} />
        <Route path={`${match.url}/workflow`} component={Workflow} />
        <Redirect to={`${match.url}/worlflow`} />
      </Switch>
    </>
  );
}
