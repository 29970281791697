const microServices = {
  CADENCE_V1: "/cadence-api/v1",
  CADENCE_V2: "/cadence-api/v2",
  CRM_BACKEND: "/api/crm",
};

export const endpoints = {
  CADENCE_LIST: `${microServices.CADENCE_V2}/cadence`, // GET
  CADENCE_BY_ID: `${microServices.CADENCE_V2}/cadence`, // GET with id in params
  EDIT_CADENCE: `${microServices.CADENCE_V2}/cadence`, // PUT
  CREATE_CADENCE: `${microServices.CADENCE_V2}/cadence`, // POST
  DELETE_CADENCE: `${microServices.CADENCE_V2}/cadence`, // DELETE with id in params

  // update status
  CADENCE_UPDATE_STATUS: `${microServices.CADENCE_V2}/cadence/status`,

  CREATE_CADENCE_STEP: `${microServices.CADENCE_V2}/cadence`, // PUT
  EDIT_CADENCE_STEP: `${microServices.CADENCE_V2}/cadence`, // PUT
  DELETE_CADENCE_STEP: `${microServices.CADENCE_V2}/cadence`, // PUT

  SWAP_CADENCE_STEP: `${microServices.CADENCE_V1}/cadence/swapsteps`, // POST
  MOVE_CADENCE_STEP: `${microServices.CADENCE_V2}/cadence/cadenceId/move-step`, // POST

  // cadence subscriber list
  GET_ALL_CADENCE_LEAD: `${microServices.CADENCE_V2}/cadence-subscriber/list`,
  GET_CADENCE_BY_LEAD_ID: `${microServices.CADENCE_V2}/cadence-subscriber/cadence-list`,
  ADD_LEAD_TO_CADENCE: `${microServices.CADENCE_V2}/cadence-subscriber/subscribe`,
  ADD_BULK_LEAD_TO_CADENCE: `${microServices.CADENCE_V2}/cadence-subscriber/bulk-subscribe`,
  BULK_SUBSCRIBE_TO_CADENCE_BY_QUERY: `${microServices.CADENCE_V2}/cadence-subscriber/bulk-subscribe-by-query`, // patch
  GET_CADENCE_SUBSCRIBER_COUNT: `${microServices.CRM_BACKEND}/cadence/check-subscriber`, // post
  CADENCE_SUBSCRIBER_ANAYTICS_STATS: `${microServices.CADENCE_V2}/cadence-analytics/subscriber`, // get

  // templates
  GET_CADENCE_PREBUILT_TEMPLATES: `${microServices.CADENCE_V2}/cadence-template?page=1&limit=100`, // GET
  GET_CADENCE_CLONEABLE_TEMPLATES: `${microServices.CADENCE_V2}/cadence/clonable/list?page=1&limit=100`, // GET

  CREATE_CADENCE_BY_TEMPLATE: `${microServices.CADENCE_V2}/cadence/clone`, // POST

  // analytics
  CADENCE_ANALYTICS_SUMMARIZE: `${microServices.CADENCE_V2}/cadence-analytics/summarize`,
  CADENCE_ANALYTICS_STEPWISE: `${microServices.CADENCE_V2}/cadence-analytics/step-wise`,

  // cadence subscriber actions
  PAUSE_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/pause`,
  RESUME_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/resume`,
  MARK_AS_FINISHED_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/mark-as-finished`,
  MARK_AS_REPLIED_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/mark-as-replied`,
  CHANGE_STEP_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/change-step`,
  REMOVE_FROM_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/remove`,
  RETRY_CADENCE_SUBSCRIBER: `${microServices.CADENCE_V2}/cadence-subscriber/action/retry`,

  // settings
  CADENCE_SETTINGS: `${microServices.CADENCE_V2}/settings`,
  CADENCE_ALL_USERS_LIMIT: `${microServices.CADENCE_V2}/settings/usage`,
};
