import { getCampaignDetailsById } from "../types/campaign_types";

const initState = {
  data: {},
  loading: false,
  error: null,
};

export default function campaignDetailsByIdReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCampaignDetailsById.GET_CAMPAIGN_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getCampaignDetailsById.GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getCampaignDetailsById.GET_CAMPAIGN_DETAILS_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
