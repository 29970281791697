import React from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

export default function RadioSelector({ setValue, value, options, isVertical }) {
  return (
    <Form.Group id="custom-inputs-radio-selector" className={`d-flex gap-2 ${isVertical ? "flex-column" : ""}`}>
      {options.map((each, index) => {
        return (
          <Form.Check className="px-10" type={"radio"} id={each + index}>
            <Form.Check.Input className="custom-radio" checked={each === value} onChange={() => setValue(each)} type={"radio"} varient="primary" />
            <Form.Check.Label className="mt-5px">{each}</Form.Check.Label>
          </Form.Check>
        );
      })}
    </Form.Group>
  );
}

export function RadioSelectorWithGrid({ setValue, value, options }) {
  return (
    <Row id="custom-inputs-radio-selector" className={`d-flex gap-4 `}>
      {options.map((each, index) => {
        return (
          <Col key={index}>
            <Form.Check className="px-10" type={"radio"} id={each + index}>
              <Form.Check.Input className="custom-radio" checked={each === value} onChange={() => setValue(each)} type={"radio"} varient="primary" />
              <Form.Check.Label className="mt-5px">{each}</Form.Check.Label>
            </Form.Check>
          </Col>
        );
      })}
    </Row>
  );
}

export function RadioSelectorWithTooltip({ setValue, value, options, isVertical, disabled }) {
  return (
    <Form.Group id="custom-inputs-radio-selector" className={`d-flex gap-2 ${isVertical ? "flex-column" : ""}`}>
      {options.map((each, index) => {
        return (
          <Form.Check className="px-10" type={"radio"} id={each.value + index}>
            <Form.Check.Input disabled={disabled} className="custom-radio" checked={each.value === value} onChange={() => setValue(each.value)} type={"radio"} varient="primary" />
            <Form.Check.Label className="mt-5px">{each.label}</Form.Check.Label>
            {each.info && (
              <OverlayTrigger placement="auto" overlay={<Tooltip>{each.info}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            )}
          </Form.Check>
        );
      })}
    </Form.Group>
  );
}
