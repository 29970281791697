import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import { CustomSubHeader } from "../../../components/subheader";

import ManageCustomer from "../account/users/manage-user";
import Teams from "../account/users/team";
import Security from "../account/company-setting/security";
import CustomField from "../account/Application-settings/custom-field";
import Activity from "../account/company-setting/activity";
import Usage from "../account/company-setting/usage";
import TagsSetting from "../account/Application-settings/tags-setting";
import { useSelector } from "react-redux";
import { ErrorPage3 } from "../../modules/ErrorsExamples/ErrorPage3";
import DeactiveCompany from "../account/company-setting/deactivate-company";
import ContentWrapper from "../../Wrapper";
import { AdminSettingRouteConstants } from "../constants";

// updated
import Developer from "./developers";
import OutcomeLostReason from "./outcomes-loss-reasons";
import Currency from "./currency";
import Company from "./company";
import SettingsSubHeader from "../components/settings-sub-header";

export default function AdminSettingsRoutes(props) {
  const { match } = props;

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  return (
    <>
      {userProfile && userProfile.data && userProfile.data.userType === "AGENT" ? (
        <ErrorPage3 />
      ) : accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.accountStatus === "DEACTIVATED" ? (
        <DeactiveCompany />
      ) : (
        <ContentWrapper subHeader={<SettingsSubHeader tabName={"admin-settings"} />}>
          <div className="d-flex flex-row h-vh-170px">
            <div className="sticky-sidebar-2 flex-row-auto offcanvas-mobile w-250px w-xxl-350px">
              <div className="card card-custom card-stretch h-vh-160px">
                <div className="card-body pt-4">
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    {AdminSettingRouteConstants.map((each, index) => {
                      return (
                        <div key={index} className="navi-item mb-2">
                          <NavLink to={each.path} className="navi-link py-4" activeClassName="active">
                            <span className="navi-icon mr-2">
                              {each.svg ? (
                                <span className="svg-icon pointer-events-none">
                                  <SVG src={toAbsoluteUrl(each.svg)}></SVG>{" "}
                                </span>
                              ) : (
                                <span className="svg-icon">
                                  <i className={each.icon}></i>{" "}
                                </span>
                              )}
                            </span>
                            <span className="navi-text font-size-lg">{each.Name}</span>
                          </NavLink>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* ************************************************************************* */}

            <div className="flex-row-fluid ml-lg-8">
              <Switch>
                <Route exact path={`${match.url}/manage-users`} component={ManageCustomer} />
                <Route exact path={`${match.url}/team`} component={Teams} />
                <Route exact path={`${match.url}/company`} component={Company} />
                <Route exact path={`${match.url}/currency`} component={Currency} />
                <Route exact path={`${match.url}/security`} component={Security} />
                <Route path={`${match.url}/developer`} component={Developer} />
                <Route exact path={`${match.url}/custom-field`} component={CustomField} />

                <Route path={`${match.url}/lost-reason`} component={OutcomeLostReason} />

                <Route exact path={`${match.url}/activity`} component={Activity} />
                <Route exact path={`${match.url}/usage`} component={Usage} />
                <Route exact path={`${match.url}/tags-settings`} component={TagsSetting} />

                {/* redirections */}
                <Redirect exact from={`${match.url}/api-key`} to={`${match.url}/developer/api-key`} />
                <Redirect exact from={`${match.url}/webhook`} to={`${match.url}/developer/webhook`} />

                <Route exact from={`${match.url}/activity-lost-reason`} path={`${match.url}/lost-reason/activity`} />
                <Route exact from={`${match.url}/deal-lost-reason`} path={`${match.url}/lost-reason/deal`} />
                <Route exact from={`${match.url}/lead-lost-reason`} path={`${match.url}/lost-reason/lead`} />

                <Redirect to={`${match.url}/manage-users`} />
              </Switch>
            </div>
          </div>
        </ContentWrapper>
      )}
    </>
  );
}
