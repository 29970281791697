import React from "react";
import { Button } from "react-bootstrap";
import "./styles.scss";
import InlineStyled from "../inline-styled/inline-styled";

export function CustomSolidButton({ props }) {
  return (
    <Button className="button-area" onClick={props.handleClick} variant={props.variant} size={props.size ? props.size : ""}>
      <div className="button-wrapper">
        <div className="button-icon">{props.icon ? props.icon : ""}</div>
        <div className="button-text">{props.buttonText}</div>
      </div>
    </Button>
  );
}

export function CustomBlueButton({ props }) {
  return (
    <Button className="button-area blue-gradient" onClick={props.handleClick} variant={props.variant} size={props.size ? props.size : ""}>
      <div className="button-wrapper">
        <div className="button-icon">{props.icon ? props.icon : ""}</div>
        <div className="button-text">{props.buttonText}</div>
      </div>
    </Button>
  );
}

export function CustomGreen({ props }) {
  return (
    <Button className="button-area green-gradient" onClick={props.handleClick} variant={props.variant} size={props.size ? props.size : ""}>
      <div className="button-wrapper">
        <div className="button-icon">{props.icon ? props.icon : ""}</div>
        <div className="button-text">{props.buttonText}</div>
      </div>
    </Button>
  );
}

export function CustomOutlineButton({ props }) {
  return (
    <Button onClick={props.handleClick} variant={"outline-" + props.variant}>
      {props.buttonText}
    </Button>
  );
}

export function BlueGradientButton(props) {
  return (
    <Button {...props} className={props.className ? `${props.className} blue-gradient` : "blue-gradient"}>
      {props.children}
    </Button>
  );
}

export function GreenGradientButton({ className, ...props }) {
  return (
    <Button {...props} className={`green-gradient pointer-cursor ${className}`}>
      {props.children}
    </Button>
  );
}

export function CustomButton(props) {
  return (
    <InlineStyled as={Button} {...props} styles={{ background: props.color }} className="blue-gradient pointer-cursor" size="sm">
      {props.children}
    </InlineStyled>
  );
}
