import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomSwitch from "../../../../../components/custom_form/switch";

const trackingOptions = [
  { name: "General", key: "crm" },
  { name: "Workflow Automation", key: "workflow" },
  { name: "Sales Cadence", key: "cadence" },
];

export default function EmailSettings({ selectedSyncData, handleTrackingDetailsUpdate }) {
  return (
    <div className="p-2">
      <div className="fw-600 font-size-12px pt-5px pb-8px">Email tracking</div>
      <p>Email tracking monitors email delivery and engagement, tracks when message is opened or clicked.</p>
      <table className="w-100 border rounded-2">
        <thead className="border-bottom">
          <tr>
            <th className="p-3 border-right w-50prc">Module Name</th>
            <th className=" p-3 border-right w-25prc">
              Open tracking
              <OverlayTrigger placement="auto" overlay={<Tooltip>{"Track when email messages have been opened by recipients."}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </th>
            <th className="w-25prc p-3">
              Link tracking
              <OverlayTrigger placement="auto" overlay={<Tooltip>{"Track when links in email messages have been clicked by recipients."}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {trackingOptions.map((each, index) => {
            return (
              <tr key={index} className="mb-2">
                <td align="start" className="p-3 border-right">
                  {each.name}
                </td>
                <td align="center" className="text-center p-3 border-right">
                  <CustomSwitch
                    className="justify-content-center"
                    id={each.key + index + "open"}
                    checked={selectedSyncData.tracking ? (selectedSyncData.tracking[each.key] && selectedSyncData.tracking[each.key].openTracking ? true : false) : true}
                    handleChange={(e) => handleTrackingDetailsUpdate(e, each.key, "openTracking")}
                  />
                </td>
                <td align="center" className="text-center p-3">
                  <CustomSwitch
                    id={each.key + index + "link"}
                    checked={selectedSyncData && selectedSyncData.tracking ? (selectedSyncData.tracking[each.key] && selectedSyncData.tracking[each.key].linkTracking ? true : false) : true}
                    handleChange={(e) => handleTrackingDetailsUpdate(e, each.key, "linkTracking")}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
