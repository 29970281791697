import { getSalesTargetGraph, getListOfSalesTarget, deleteSalesTarget, createSalesTarget, editSalesTarget, getListOfSalesTargetLoadMore } from "../types/sales-target.types";

//get list of sales target
export function getListOfSalesTargetRequest(req) {
  return {
    type: getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_REQUEST,
    payload: req,
  };
}
export function getListOfSalesTargetSuccess(res) {
  return {
    type: getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_SUCCESS,
    payload: res,
  };
}
export function getListOfSalesTargetError(err) {
  return {
    type: getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_ERROR,
    payload: err,
  };
}

//get list of sales target load more
export function getListOfSalesTargetLoadMoreRequest(req) {
  return {
    type: getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_REQUEST,
    payload: req,
  };
}
export function getListOfSalesTargetLoadMoreSuccess(res) {
  return {
    type: getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_SUCCESS,
    payload: res,
  };
}
export function getListOfSalesTargetLoadMoreError(err) {
  return {
    type: getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_ERROR,
    payload: err,
  };
}

//create
export function createSalesTargetRequest(req) {
  return {
    type: createSalesTarget.CREATE_SALES_TARGET_REQUEST,
    payload: req,
  };
}
export function createSalesTargetSuccess(res) {
  return {
    type: createSalesTarget.CREATE_SALES_TARGET_SUCCESS,
    payload: res,
  };
}
export function createSalesTargetError(err) {
  return {
    type: createSalesTarget.CREATE_SALES_TARGET_ERROR,
    payload: err,
  };
}

//edit
export function editSalesTargetRequest(req) {
  return {
    type: editSalesTarget.EDIT_SALES_TARGET_REQUEST,
    payload: req,
  };
}
export function editSalesTargetSuccess(res) {
  return {
    type: editSalesTarget.EDIT_SALES_TARGET_SUCCESS,
    payload: res,
  };
}
export function editSalesTargetError(err) {
  return {
    type: editSalesTarget.EDIT_SALES_TARGET_ERROR,
    payload: err,
  };
}

//delete
export function deleteSalesTargetRequest(req) {
  return {
    type: deleteSalesTarget.DELETE_SALES_TARGET_REQUEST,
    payload: req,
  };
}
export function deleteSalesTargetSuccess(res) {
  return {
    type: deleteSalesTarget.DELETE_SALES_TARGET_SUCCESS,
    payload: res,
  };
}
export function deleteSalesTargetError(err) {
  return {
    type: deleteSalesTarget.DELETE_SALES_TARGET_ERROR,
    payload: err,
  };
}

//get graph
export function getSalesTargetGraphRequest(req) {
  return {
    type: getSalesTargetGraph.GET_SALES_TARGET_GRAPH_REQUEST,
    payload: req,
  };
}
export function getSalesTargetGraphSuccess(res) {
  return {
    type: getSalesTargetGraph.GET_SALES_TARGET_GRAPH_SUCCESS,
    payload: res,
  };
}
export function getSalesTargetGraphError(err) {
  return {
    type: getSalesTargetGraph.GET_SALES_TARGET_GRAPH_ERROR,
    payload: err,
  };
}
