import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { getUsageRecordReportRequest } from "../../../../actions/usage.actions";
import * as billingActions from "../../../store/actions/subscription_billing_actions";

//containers
import SubscriptionStep1 from "./steps/v1/subscription-step1";
import SubscriptionStep2 from "./steps/v1/subscription-step2";

import FullScreeenModal from "./full-screen-modal";
import Stepper from "../../../../../../components/stepper/stepper-2";
import * as usageActions from "../../../../actions/usage.actions";

import { dispatchSnackbarError, dispatchSnackbarWarning } from "../../../../../../utils/toaster";
import CurrencyInterval from "./components/currency-interval";

const getStripeFrontendKey = () => {
  if (window.location.host === "app.salessimplify.com") {
    return "pk_live_51J8jyDJ3NSQmTBcP92mfLTueJm0tWVCdx0ZlDq0VZcrE1C6Ca4d7ZavdGrZJwlRymE0RpfcTyN0O74b0YfmdbiLo0086OrCwzf";
  } else {
    return "pk_test_51J8jyDJ3NSQmTBcPm9jZxZ3BrIF9oIitiACe9skYsABZQrH8BiVEuyrHPmdvhowHD8i3fbnK1KWaXtERQESk1pLG0079OcfoUg";
  }
};

export default function SubscriptionPlanModal(props) {
  const [activeStep, setActiveStep] = useState(1);
  const [continueToPayDisabled, setContinueToPayDisabled] = useState(false);
  const [interval, setPlanInterval] = useState("month");
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [currency, setCurrency] = useState("usd");
  const [totalCustomFields, setTotalCustomFields] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [disabledCurreny, setDisabledCurrency] = useState(false);
  const [disabledInterval, setDisabledInterval] = useState(false);
  const [selectedPlanByCard, setSelectedPlanByCard] = useState({});

  //main plan related
  const [agents, setAgents] = useState(0);
  const [selectedMainPlan, setSelectedMainPlan] = useState({});

  //records addon related
  const [recordCount, setRecordCount] = useState(0);
  const [selectedRecordPlan, setSelectedRecordPlan] = useState({});

  //email sync addon related
  const [emailSyncCount, setEmailSyncCount] = useState(0);
  const [selectedEmailSyncPlan, setSelectedEmailSyncPlan] = useState({});

  // usage
  const [userUsage, setUserUsage] = useState({});

  const billingInfo = useSelector((state) => state.Billing.subscription.accountWiseBilling);
  const plansData = useSelector((state) => state.Billing.subscription.billingPrices);
  const usageDataV2 = useSelector((store) => store.usage.v2);
  const cadenceSettingsStore = useSelector((store) => store.cadence.settings.allUserLimit);

  const dispatch = useDispatch();

  const [public_key] = useState(getStripeFrontendKey());
  const stripePromise = loadStripe(public_key);

  const [owners, setOwners] = useState({});
  const userRole = useSelector((store) => store.insight.userRole);

  useEffect(() => {
    if (userRole.list.allUsers) {
      const a = {};
      userRole.list.allUsers.forEach((each) => {
        a[each.id] = each;
      });
      setOwners(a);
    }
  }, [userRole.list.allUsers]);

  useEffect(() => {
    dispatch(billingActions.getBillingPricesRequest());
    dispatch(usageActions.getUsageRecordReportV2Request());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(plansData.addOns) && !_.isEmpty(billingInfo.data)) {
      for (let keys in plansData.addOns) {
        const addOnArray = plansData.addOns[keys];
        const data = addOnArray.find((a) => a.interval === interval && a.currency === currency);
        if (billingInfo.data.isLimitedDealClaimed) {
          if (data && data.serviceType === "records2" && billingInfo.data.ltdversion > 1) {
            setSelectedRecordPlan(data);
          } else if (data && data.serviceType === "records" && billingInfo.data.ltdversion <= 1) {
            setSelectedRecordPlan(data);
          }
        } else if (data && data.serviceType === "records2") {
          setSelectedRecordPlan(data);
        }
        if (data && data.serviceType === "emailSync") {
          setSelectedEmailSyncPlan(data);
        }
      }
    }
  }, [plansData.addOns, currency, interval, billingInfo]);

  useEffect(() => {
    if (plansData.plans && !_.isEmpty(selectedMainPlan)) {
      const addOnArray = plansData.plans[selectedMainPlan.serviceType];
      const data = addOnArray.find((a) => a.interval === interval && a.currency === currency);
      if (data) {
        setSelectedMainPlan(data);
      }
    }
  }, [plansData.plans, currency, interval, selectedMainPlan]);

  useEffect(() => {
    if (!_.isEmpty(usageDataV2.data)) {
      const obj = {};
      obj["records"] = { module: "Contacts", consumed: usageDataV2.data.records.consumed.contacts, available: usageDataV2.data.records.assigned.contacts, isLock: false, extra: usageDataV2.data.records.extra };
      obj["customField"] = { module: "Custom Fields", consumed: usageDataV2.data.records.consumed.fields, available: usageDataV2.data.records.assigned.fields, isLock: false };
      obj["pipeline"] = { module: "Pipeline", consumed: usageDataV2.data.consumed.pipeline, available: usageDataV2.data.records.extraLimit.pipeline, isLock: false };
      obj["dashboard"] = {
        module: "Dashboard",
        consumed: usageDataV2.data.consumed.dashboardV2,
        available: usageDataV2.data.records.extraLimit.dashboard,
        isLock: false,
      };
      obj["leadbot"] = { module: "Leadbot", consumed: usageDataV2.data.consumed.leadbot, available: usageDataV2.data.records.extraLimit.leadbot, isLock: false };
      obj["workflow"] = {
        module: "Workflow",
        consumed: usageDataV2.data.consumed.workflow.reduce((result, item) => item.count + result, 0),
        available: usageDataV2.data.records.extraLimit.workflow,
        isLock: !usageDataV2.data.assigned.isWorkflow,
        workflowExtra: usageDataV2.data.consumed.workflow,
      };
      obj["cadence"] = {
        module: "Cadence",
        consumed: usageDataV2.data.consumed.cadence.reduce((result, item) => item.count + result, 0),
        available: usageDataV2.data.records.extraLimit.cadence,
        isLock: !usageDataV2.data.assigned.isCadence,
      };
      setTotalRecords(obj["records"].consumed);
      setTotalCustomFields(obj["customField"].consumed);
      setUserUsage(obj);
    }
  }, [usageDataV2.data, owners]);

  useEffect(() => {
    if (!_.isEmpty(billingInfo.data) && billingInfo.data.planCurrency) {
      if (billingInfo.data.planCurrency.toLowerCase() === "usd") {
        setCurrencySymbol("$");
        setCurrency("usd");
        setDisabledCurrency(true);
      } else {
        setCurrencySymbol("₹");
        setCurrency("inr");
        setDisabledCurrency(true);
      }
    } else {
      setCurrencySymbol("$");
      setCurrency("usd");
      setDisabledCurrency(false);
    }
  }, [billingInfo.data]);

  useEffect(() => {
    if (!_.isEmpty(billingInfo.data) && billingInfo.data.planInterval) {
      if (billingInfo.data.planInterval === "month") {
        setPlanInterval("month");
        setDisabledInterval(true);
      } else {
        setPlanInterval("year");
        setDisabledInterval(true);
      }
    } else {
      setPlanInterval("year");
      setDisabledInterval(false);
    }
  }, [billingInfo.data]);

  useEffect(() => {
    if (!_.isEmpty(billingInfo.data)) {
      const { subscribedPlans } = billingInfo.data;
      subscribedPlans.forEach((eachPlan) => {
        if (eachPlan.planId === selectedMainPlan.priceId) {
          setAgents(eachPlan.quantity);
        } else if (eachPlan.isAddOn && selectedRecordPlan.priceId === eachPlan.planId) {
          setRecordCount(eachPlan.quantity);
        } else if (eachPlan.isAddOn && selectedEmailSyncPlan.priceId === eachPlan.planId) {
          setEmailSyncCount(eachPlan.quantity);
        }
      });
    }
  }, [billingInfo.data, selectedMainPlan, selectedRecordPlan, selectedEmailSyncPlan]);

  useEffect(() => {
    if (!_.isEmpty(billingInfo.data)) {
      const { subscribedPlans } = billingInfo.data;
      const planArr = [];
      subscribedPlans.forEach((eachPlan) => {
        planArr.push(eachPlan.planId);
      });

      for (let keys in plansData.plans) {
        const addOnArray = plansData.plans[keys];
        addOnArray.forEach((each) => {
          if (planArr.indexOf(each.planId) !== -1) {
            setSelectedMainPlan(each);
          }
        });
      }
    }
  }, [billingInfo.data, plansData.plans]);

  useEffect(() => {
    if (props.page === "addOn1" && billingInfo.data) {
      const { isLimitedDealClaimed, isSubscriptionActive, selectedPlan } = billingInfo.data;
      if (_.lowerCase(selectedPlan) === "free") {
        setActiveStep(1);
      } else if (isLimitedDealClaimed || (!isLimitedDealClaimed && isSubscriptionActive)) {
        setActiveStep(2);
        // getUserCurrentPlan();
      }
    }
  }, [props.page, billingInfo.data]);

  useEffect(() => {
    if (!_.isEmpty(billingInfo.data) && !_.isEmpty(plansData.plans)) {
      const { isLimitedDealClaimed } = billingInfo.data;
      if (isLimitedDealClaimed) {
        for (let keys in plansData.plans) {
          const addOnArray = plansData.plans[keys];
          const data = addOnArray.find((a) => a.interval === interval && a.currency === currency);
          if (data && keys === "businessPlan") {
            setActiveStep(2);
            setSelectedMainPlan(data);
          }
        }
      }
    }
  }, [billingInfo.data, plansData.plans, interval, currency]);

  const handleSelectMainPlan = (planData) => {
    setSelectedMainPlan(planData);
    setActiveStep(2);
  };

  const handleFreePlanSelected = (planData) => {
    setSelectedMainPlan(planData);
    setActiveStep(3);
  };

  const validateRecordsAndCustomFields = () => {
    let recordLimit = 0,
      customFieldLimit = 0;
    let pipeline = 0,
      dashboard = 0,
      leadbot = 0; //workflow = 0, cadence = 0;
    const { isLimitedDealClaimed } = billingInfo.data;

    if (isLimitedDealClaimed) {
      return true;
    } else if (selectedMainPlan.serviceType === "businessPlan" || selectedMainPlan.serviceType === "limitedBusinessPlan") {
      recordLimit = 1000000;
      customFieldLimit = 50;
      pipeline = 10;
      dashboard = 100000;
      leadbot = 20;
      // workflow = 0;
      // cadence = 0;
    } else if (selectedMainPlan.serviceType === "startUpPlan" || selectedMainPlan.serviceType === "limitedStartupPlan") {
      recordLimit = 100000;
      customFieldLimit = 15;
      pipeline = 3;
      dashboard = 1;
      leadbot = 10;
      // workflow = 0;
      // cadence = 0;
    } else if (selectedMainPlan.serviceType === "businessPlusPlan" || selectedMainPlan.serviceType === "businessPlus") {
      recordLimit = 1000000;
      customFieldLimit = 500;
      pipeline = 1000;
      dashboard = 1000;
      leadbot = 50;
    } else {
      recordLimit = 0;
      customFieldLimit = 0;
    }

    if (recordCount > 0) {
      recordLimit = recordLimit + (recordCount || 0) * 10000;
    }

    if (recordLimit < totalRecords) {
      dispatchSnackbarWarning(`Currently you having ${totalRecords} active records. please delete some of your records to ${recordLimit} to select ${recordLimit} records in subscription.`);
      return false;
    }

    if (customFieldLimit < totalCustomFields) {
      dispatchSnackbarWarning(
        `Currently you having ${totalCustomFields} active custom fields. please delete some of your custom fields to ${customFieldLimit} to select ${customFieldLimit} custom fields in subscription.`,
      );
      return false;
    }

    if (userUsage.pipeline && pipeline < userUsage.pipeline.consumed) {
      dispatchSnackbarWarning(`You currently have ${userUsage.pipeline} pipelines. Please reduce some of your cadences to ${pipeline} in order to apply your current subscription.`);
      return false;
    }
    if (dashboard < userUsage.dashboard.consumed) {
      for (const each of userUsage.dashboard.consumed) {
        if (owners[each._id] && dashboard < each.count) {
          dispatchSnackbarWarning(`User email ${owners[each._id].email} currently have ${each.count} dashboards. Please reduce some of your dashboards to ${dashboard} in order to apply your current subscription`);
          return false;
        }
      }
    }
    if (leadbot < userUsage.leadbot) {
      dispatchSnackbarWarning(`You currently have ${userUsage.leadbot} leadbots. Please reduce some of your leadbots to ${leadbot} in order to apply your current subscription`);
      return false;
    }
    // if (workflow < userUsage.workflow) {
    //   dispatchSnackbarWarning(
    //     `You currently have ${userUsage.workflow} workflows. Please reduce some of your workflows to ${workflow} in order to apply your current subscription`,
    //   );
    //   return false;
    // }
    // if (cadence < userUsage.cadence) {
    //   dispatchSnackbarWarning(
    //     `You currently have ${userUsage.cadence} cadences. Please reduce some of your cadences to ${cadence} in order to apply your current subscription`,
    //   );
    //   return false;
    // }
    const check = handleCadenceRestrictionOnPlanChange(selectedMainPlan.serviceType || "freePlan");
    if (!check) return false;
    return true;
  };

  const getUpdatedPlanItemsData = () => {
    const planItems = [];
    if (selectedMainPlan && selectedMainPlan.priceId && agents > 0) {
      planItems.push({
        isAddOn: false,
        planId: selectedMainPlan.priceId,
        quantity: agents,
      });
    }

    if (selectedRecordPlan && selectedRecordPlan.priceId && recordCount > 0) {
      planItems.push({
        isAddOn: true,
        planId: selectedRecordPlan.priceId,
        quantity: recordCount,
      });
    }
    if (selectedEmailSyncPlan && selectedEmailSyncPlan.priceId && emailSyncCount > 0) {
      planItems.push({
        isAddOn: true,
        planId: selectedEmailSyncPlan.priceId,
        quantity: emailSyncCount,
      });
    }
    return planItems;
  };

  const validateOnSelectedPlan = () => {
    const { isLimitedDealClaimed } = billingInfo.data;
    if (isLimitedDealClaimed) {
      if (!agents && !emailSyncCount) {
        dispatchSnackbarWarning(`Plaese select users/agent or addon to proceed.`);
        return false;
      }
      return true;
    } else {
      if (!agents && !recordCount) {
        dispatchSnackbarWarning(`Plaese select users/agent count to proceed.`);
        return false;
      }
      if (!(selectedMainPlan && selectedMainPlan.priceId && agents)) {
        dispatchSnackbarWarning(`User/Agent count cannot be 0 for selected plan.`);
        return false;
      }
      return true;
    }
  };

  const handleSubscription = async () => {
    const { isLimitedDealClaimed, usersLimit, isFreeAccount, isEnterpriseAccount, isSubscriptionActive, services } = billingInfo.data;
    const _emailSyncCount = billingInfo.data.emailSyncCount;

    if (isLimitedDealClaimed && agents + services.FREE_USERS < usersLimit.value) {
      return dispatchSnackbarWarning(`Currently you having ${usersLimit.value} active users. please deactivate your remaining users to ${agents + services.FREE_USERS} to select ${agents} users/agents in subscription.`);
    } else if (!isLimitedDealClaimed && agents < usersLimit.value) {
      return dispatchSnackbarWarning(`Currently you having ${usersLimit.value} active users. please deactivate your remaining users to ${agents} to select ${agents} users/agents in subscription.`);
    } else if (isLimitedDealClaimed && _emailSyncCount.current > emailSyncCount) {
      return dispatchSnackbarWarning(
        `Currently you having ${_emailSyncCount.current} active email sync accounts. please deactivate your remaining users to ${agents + services.FREE_USERS} to select ${agents} users/agents in subscription.`,
      );
    }
    if (!validateRecordsAndCustomFields()) return;
    if (!validateOnSelectedPlan()) return;

    setContinueToPayDisabled(true);
    if (isSubscriptionActive && !isEnterpriseAccount && !isFreeAccount) {
      const data = {
        data: { planItems: getUpdatedPlanItemsData() },
        type: "update",
      };
      dispatch(
        billingActions.billingPlanCheckoutRequest(data, (result) => {
          if (result.status) {
            props.setShowPlans(false);
            setContinueToPayDisabled(false);
            dispatch(billingActions.getAccountBillingRequest(() => {}));
            setTimeout(() => billingActions.getAccountBillingRequestAfter(() => {}), 2000);
            dispatch(getUsageRecordReportRequest());
          }
        }),
      );
    } else {
      const data = {
        data: { planItems: getUpdatedPlanItemsData() },
        type: "create",
      };
      const stripe = await stripePromise;

      dispatch(
        billingActions.billingPlanCheckoutRequest(data, async (result) => {
          await stripe.redirectToCheckout({
            sessionId: result.sessionId,
          });
        }),
      );
    }
  };

  const handleCadenceRestrictionOnPlanChange = (planName) => {
    for (const eachUser of cadenceSettingsStore.data) {
      if (planName === "freePlan" || planName === "free") {
        if (eachUser.activeSubscribers > 0) {
          dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 0 in order to apply your current subscription`);
          return false;
        }
      } else if (planName === "startUpPlan" || planName === "startup") {
        if (eachUser.activeSubscribers > 100) {
          dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 100 in order to apply your current subscription`);
          return false;
        }
      } else if (planName === "businessPlan" || planName === "business") {
        if (eachUser.activeSubscribers > 1000) {
          dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 1000 in order to apply your current subscription`);
          return false;
        }
      } else if (planName === "businessPlusPlan" || planName === "businessPlus") {
        if (eachUser.activeSubscribers > 10000) {
          dispatchSnackbarWarning(`You currently have ${eachUser.activeSubscribers} active subscribers in cadence. Please reduce some of your subscribers to 10000 in order to apply your current subscription`);
          return false;
        }
      }
    }
    return true;
  };

  const convertToFreePlan = () => {
    const check = handleCadenceRestrictionOnPlanChange("freePlan");
    if (!check) return;
    dispatch(
      billingActions.asssignFreePlanRequest({}, async (result) => {
        if (result.status) {
          props.setShowPlans(false);
          window.location.reload();
        } else {
          dispatchSnackbarError(result.message);
        }
      }),
    );
  };

  return (
    <FullScreeenModal
      showPlans={props.showPlans}
      setShowPlans={props.setShowPlans}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleSubscription={handleSubscription}
      continueToPayDisabled={continueToPayDisabled}
      selectedPlanByCard={selectedPlanByCard}
      handleSelectMainPlan={handleSelectMainPlan}
      isSubscriptionActive={billingInfo && billingInfo.data && billingInfo.data.isSubscriptionActive}
    >
      <div className="position-relative p-7">
        <Stepper title={"Affordable pricing plans like never before"} subText={"Choose the right plan for your team"} steps={["Plans", "Add-ons"]} activeStep={activeStep} />
        <CurrencyInterval
          interval={interval}
          currency={currency}
          setCurrency={setCurrency}
          setPlanInterval={setPlanInterval}
          disabledCurreny={disabledCurreny}
          disabledInterval={disabledInterval}
          setCurrencySymbol={setCurrencySymbol}
        />
        {activeStep === 1 ? (
          <SubscriptionStep1
            handleSelectMainPlan={handleSelectMainPlan}
            handleFreePlanSelected={handleFreePlanSelected}
            interval={interval}
            setPlanInterval={setPlanInterval}
            currencySymbol={currencySymbol}
            setCurrencySymbol={setCurrencySymbol}
            currency={currency}
            setCurrency={setCurrency}
            disabledCurreny={disabledCurreny}
            disabledInterval={disabledInterval}
            setSelectedPlanByCard={setSelectedPlanByCard}
            convertToFreePlan={convertToFreePlan}
          />
        ) : (
          <SubscriptionStep2
            interval={interval}
            setPlanInterval={setPlanInterval}
            currencySymbol={currencySymbol}
            setCurrencySymbol={setCurrencySymbol}
            currency={currency}
            setCurrency={setCurrency}
            disabledCurreny={disabledCurreny}
            disabledInterval={disabledInterval}
            agents={agents}
            recordCount={recordCount}
            emailSyncCount={emailSyncCount}
            setAgents={setAgents}
            setRecordCount={setRecordCount}
            setEmailSyncCount={setEmailSyncCount}
            selectedMainPlan={selectedMainPlan}
            selectedRecordPlan={selectedRecordPlan}
            selectedEmailSyncPlan={selectedEmailSyncPlan}
            handleSubscription={handleSubscription}
            continueToPayDisabled={continueToPayDisabled}
            isSubscriptionActive={billingInfo && billingInfo.data && billingInfo.data.isSubscriptionActive}
          />
        )}
      </div>
    </FullScreeenModal>
  );
}
