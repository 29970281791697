import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { InputGroup, Form, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../utils/toaster";
import { InputLimit, LimitInputNumber } from "../../constants";
import _ from "lodash";

export default function Pricing(props) {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [usedCurrency, setUsedCurrency] = useState([]);

  const currencyStore = useSelector((state) => state.Products.currencyList);

  useEffect(() => {
    if (!_.isEmpty(props.value)) {
      const currencies = props.value.map((each) => each.currency);
      setUsedCurrency(currencies);
    } else {
      setUsedCurrency([]);
    }
  }, [props.value]);

  useEffect(() => {
    if (Array.isArray(currencyStore.list)) {
      const newCurrency = currencyStore.list.filter((each) => !each.deactivated && !usedCurrency.includes(each.code));
      setCurrencyOptions(newCurrency);
    }
  }, [currencyStore.list, usedCurrency]);

  const handleAddUnit = () => {
    if (props.value.length === 0) {
      dispatchSnackbarError("Fill all the previous values before adding!");
      return;
    }
    for (var i = 0; i < props.value.length; i++) {
      if (props.value[i].currency === "" || props.value[i].unitPrice === "") {
        dispatchSnackbarError("Fill all the previous values before adding!");
        return;
      }
    }
    props.setValue([...props.value, { unitPrice: "", currency: "" }], props.apiKeyName);
  };

  const handleDeleteUnit = (unitIndex) => {
    props.setValue(
      props.value.filter((unit, index) => index !== unitIndex),
      props.apiKeyName,
    );
  };

  const getCurrencyName = (unit) => {
    const data = currencyOptions.find((a) => a.code === unit.currency);
    if (data) return data.name;
    return unit.currency;
  };

  return (
    <>
      {props.value
        ? props.value.map((unit, index) => {
            return (
              <InputGroup key={index} className="mb-2">
                <div className="d-flex w-100 gap-10px">
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle className="text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
                        {unit.currency === "" ? "Select Currency" : getCurrencyName(unit)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100-mn h-200px-mx overflow-y-auto">
                        {currencyStore.loading ? (
                          <div className="w-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : (
                          <>
                            {currencyOptions.map((currency, currencyIndex) => {
                              return (
                                <Dropdown.Item
                                  className="dropdown-hover"
                                  key={currencyIndex}
                                  onSelect={() => props.setValue([...props.value.slice(0, index), { unitPrice: props.value[index].unitPrice, currency: currency.code }, ...props.value.slice(index + 1)], props.apiKeyName)}
                                >
                                  {currency.code} - {currency.name}
                                </Dropdown.Item>
                              );
                            })}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      maxLength={InputLimit.NUMBER}
                      type="number"
                      value={unit.unitPrice}
                      onChange={(e) =>
                        props.setValue([...props.value.slice(0, index), { unitPrice: LimitInputNumber(e.target.value), currency: props.value[index].currency }, ...props.value.slice(index + 1)], props.apiKeyName)
                      }
                    ></Form.Control>
                  </div>
                  {props.value.length > 0 ? (
                    <InputGroup.Append>
                      <Button className="mx-0 px-0" onClick={() => handleDeleteUnit(index)} size="sm" variant="transparent">
                        <i className="fa fa-minus-circle remove-condition-icon"></i>
                      </Button>
                    </InputGroup.Append>
                  ) : null}
                </div>
              </InputGroup>
            );
          })
        : null}
      {currencyOptions.length > 0 && (
        <Button onClick={handleAddUnit} className="p-0 text-decoration-none" variant="link">
          + Add Unit
        </Button>
      )}
    </>
  );
}
