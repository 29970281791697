import React, { useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function SalesTargetChart(props) {
  const [graphData] = useState(props.data);

  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll('[shape-rendering="auto"]').forEach((e) => e.remove());
    }, 1000);
  });

  useLayoutEffect(() => {
    const chart = am4core.create(props.chartId, am4charts.XYChart);

    // Add data
    chart.data = graphData;

    /* Create axes */
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 30;

    /* Create value axis */
    chart.yAxes.push(new am4charts.ValueAxis());

    /* Create series */
    var columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.name = "Achieved Outcome";
    columnSeries.dataFields.valueY = "count";
    columnSeries.dataFields.categoryX = "category";

    columnSeries.columns.template.tooltipText = `{name} in {categoryX}: ${props.trackingMetric === "value" ? props.currency : ""} {valueY}`;
    columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    columnSeries.columns.template.propertyFields.stroke = "stroke";
    columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
    columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
    columnSeries.tooltip.label.textAlign = "middle";

    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = "Expected Outcome";
    lineSeries.dataFields.valueY = "target";
    lineSeries.dataFields.categoryX = "category";

    lineSeries.stroke = am4core.color("#fdd400");
    lineSeries.strokeWidth = 3;
    lineSeries.propertyFields.strokeDasharray = "lineDash";
    lineSeries.tooltip.label.textAlign = "middle";

    var bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = `{name} in {categoryX}: ${props.trackingMetric === "value" ? props.currency : ""} {valueY}`;
    var circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;
  }, [graphData, props.interval, props.chartId, props.trackingMetric, props.currency]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default SalesTargetChart;
