import React from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { GreenGradientButton } from "../button";
import CustomInput from "../custom_form/inputs";
import { dispatchSnackbarError } from "../../utils/toaster";
import validator from "validator";

export function ActivityPopoverEdit(props) {
  const setValue = (val, apiKeyName) => {
    props.setCellEdit({ ...props.cellEdit, [apiKeyName]: val });
  };

  const popoverForm = () => {
    return (
      <Popover className="w-310px w-310px-mx" size="md" id="popover-basic" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">{props.field.fieldName}</Popover.Title>
        <Popover.Content>
          <CustomInput clearable={true} dontShowAdd={true} page={props.page} pageSub={props.pageSub} field={props.field} value={props.cellEdit[props.field.apiKeyName]} setValue={setValue} allData={props.allData} />
          <div className="d-flex flex-row justify-content-center gap-15px mt-15px">
            <Button variant="secondary" onClick={(e) => handleClose(e)} size="sm">
              Cancel
            </Button>
            <GreenGradientButton onClick={(e) => handleSave(e)} size="sm">
              Save
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const handleClose = (e) => {
    e.stopPropagation();
    props.setEditing(false);
    props.setEdit(-1);
    props.setCellEdit({ id: "" });
  };
  const handleSave = (e) => {
    e.stopPropagation();
    var temp = props.cellEdit;
    for (const editKey in props.cellEdit) {
      if (props.cellEdit[editKey] === props.initialVal) {
        props.setEditing(false);
        props.setEdit(-1);
        return;
      }
      if (editKey === "id" || editKey === "type") continue;
      if (props.field.isRequired && props.field.fieldInputType !== "CHECKBOX" && props.field.fieldInputType !== "TOGGLE_BUTTON") {
        if (
          !props.cellEdit[editKey] ||
          (typeof props.cellEdit[editKey] === "string" && props.cellEdit[editKey].trim() === "") ||
          props.cellEdit[editKey] === [] ||
          (Array.isArray(props.cellEdit[editKey]) && props.cellEdit[editKey].includes(""))
        ) {
          dispatchSnackbarError(props.field.fieldName + " can not be empty");
          return;
        }
      }
      if (props.cellEdit[editKey] && props.cellEdit[editKey] !== "" && props.field.validation === "URL_VALIDATION" && editKey === props.field.apiKeyName) {
        if (props.cellEdit[editKey] && typeof props.cellEdit[editKey] === "string" && !validator.isURL(props.cellEdit[editKey])) {
          dispatchSnackbarError(props.cellEdit[editKey] + " is not a valid URL");
          return;
        }
      } else if (props.field.validation === "PIN_CODE_VALIDATION") {
        // let pinCodeRegex = /[1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3}/;
        if (!validator.isNumeric(props.cellEdit[editKey])) {
          dispatchSnackbarError(props.cellEdit[editKey] + "is not a valid pincode");
          return;
        }
      } else if (props.field.validation === "EMAIL_VALIDATION" && editKey === props.field.apiKeyName) {
        if (props.cellEdit[editKey] && props.cellEdit[editKey].length > 0) {
          if (props.field.fieldGroup === "CUSTOM") {
            if (!validator.isEmail(props.cellEdit[editKey])) {
              dispatchSnackbarError(props.cellEdit[editKey] + " is not a valid Email");
              return;
            }
          } else {
            for (var j = 0; j < props.cellEdit[editKey].length; j++) {
              if (!validator.isEmail(props.cellEdit[editKey][j])) {
                dispatchSnackbarError(props.cellEdit[editKey][j] + " is not a valid Email");
                return;
              }
            }
          }
        }
      } else if (props.field.validation === "PHONE_VALIDATION" && editKey === props.field.apiKeyName) {
        if (props.cellEdit[editKey] && props.cellEdit[editKey].length > 0) {
          if (Array.isArray(props.cellEdit[editKey]))
            for (var k = 0; k < props.cellEdit[editKey].length; k++) {
              if (!validator.isNumeric(props.cellEdit[editKey][k].toString())) {
                dispatchSnackbarError(props.cellEdit[editKey][k] + " is not a valid Phone Number");
                return;
              }
            }
          else if (!validator.isNumeric(props.cellEdit[editKey].toString())) {
            dispatchSnackbarError(props.cellEdit[editKey] + " is not a valid Phone Number");
            return;
          }
        }
      }
      if (props.field.fieldGroup === "CUSTOM" && editKey === props.field.apiKeyName) {
        temp = { id: props.cellEdit["id"], [`customFields.${editKey}`]: props.cellEdit[editKey] };
      }
    }
    props.setEditing(false);
    props.setEdit(-1);
    props.handleCellEdit(props.combinedId, temp);
  };

  return (
    <>
      {props.edit === props.rowIndex.toString() + props.colId ? (
        <OverlayTrigger
          rootClose={props.field.fieldInputType !== "PERSON_SELECT" && props.field.fieldInputType !== "ORG_SELECT"}
          onExit={() => {
            props.setEditing(false);
            props.setEdit(-1);
          }}
          trigger="click"
          id="popoverbutton"
          placement="bottom"
          overlay={popoverForm()}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (!props.editing) {
                props.setCellEdit({ id: props.cellId, [props.field.apiKeyName]: props.initialVal, type: props.field.fieldType });
                props.setEditing(true);
              } else props.setEditing(false);
            }}
            className="btn btn-icon btn-light h-25px w-25px"
          >
            <i className="fa fa-sm fa-pen font-size-13px"></i>
          </button>
        </OverlayTrigger>
      ) : (
        <div className="justify-self-end w-25px h-25px"></div>
      )}
    </>
  );
}
