import {
  GET_QUESTION_FIELDS_REQUEST,
  GET_QUESTION_FIELDS_SUCCESS,
  GET_QUESTION_FIELDS_ERROR,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_ERROR,
  GET_OWNER_REQUEST,
  GET_OWNER_SUCCESS,
  GET_OWNER_ERROR,
  GET_LEADBOT_DATA_REQUEST,
  GET_LEADBOT_DATA_SUCCESS,
  GET_LEADBOT_DATA_ERROR,
  EDIT_LEADBOT_DATA_REQUEST,
  EDIT_LEADBOT_DATA_SUCCESS,
  EDIT_LEADBOT_DATA_ERROR,
  CREATE_LEADBOT_REQUEST,
  CREATE_LEADBOT_SUCCESS,
  CREATE_LEADBOT_ERROR,
  DELETE_LEADBOT_REQUEST,
  DELETE_LEADBOT_SUCCESS,
  DELETE_LEADBOT_ERROR,
  GET_LEADBOTS_LIST_REQUEST,
  GET_LEADBOTS_LIST_SUCCESS,
  GET_LEADBOTS_LIST_ERROR,
  UPLOAD_LEADBOT_IMAGE_REQUEST,
} from "./types";

export function getQuestionsRequest(req) {
  return {
    type: GET_QUESTION_FIELDS_REQUEST,
    payload: req,
  };
}
export function getQuestionSuccess(res) {
  return {
    type: GET_QUESTION_FIELDS_SUCCESS,
    payload: res,
  };
}
export function getQuestionError(err) {
  return {
    type: GET_QUESTION_FIELDS_ERROR,
    payload: err,
  };
}

export function getTagsRequest(req) {
  return {
    type: GET_TAGS_REQUEST,
    payload: req,
  };
}
export function getTagsSuccess(res) {
  return {
    type: GET_TAGS_SUCCESS,
    payload: res,
  };
}
export function getTagsError(err) {
  return {
    type: GET_TAGS_ERROR,
    payload: err,
  };
}

export function getOwnerRequest(req) {
  return {
    type: GET_OWNER_REQUEST,
    payload: req,
  };
}
export function getOwnerSuccess(res) {
  return {
    type: GET_OWNER_SUCCESS,
    payload: res,
  };
}
export function getOwnerError(err) {
  return {
    type: GET_OWNER_ERROR,
    payload: err,
  };
}

export function getLeadBotDataRequest(req, cb) {
  return {
    type: GET_LEADBOT_DATA_REQUEST,
    payload: req,
    cb,
  };
}

export function getLeadBotDataSuccess(res) {
  return {
    type: GET_LEADBOT_DATA_SUCCESS,
    payload: res,
  };
}

export function getLeadBotDataError(err) {
  return {
    type: GET_LEADBOT_DATA_ERROR,
    payload: err,
  };
}

export function editLeadBotDataRequest(req, cb) {
  return {
    type: EDIT_LEADBOT_DATA_REQUEST,
    payload: req,
    cb,
  };
}

export function editLeadBotDataSuccess(res) {
  return {
    type: EDIT_LEADBOT_DATA_SUCCESS,
    payload: res,
  };
}

export function editLeadBotDataError(err) {
  return {
    type: EDIT_LEADBOT_DATA_ERROR,
    payload: err,
  };
}
export function createLeadBotRequest(req, cb) {
  return {
    type: CREATE_LEADBOT_REQUEST,
    payload: req,
    cb,
  };
}
export function createLeadBotSuccess(res) {
  return {
    type: CREATE_LEADBOT_SUCCESS,
    payload: res,
  };
}

export function createLeadBotError(err) {
  return {
    type: CREATE_LEADBOT_ERROR,
    payload: err,
  };
}

export function getLeadbotsListRequest(req) {
  return {
    type: GET_LEADBOTS_LIST_REQUEST,
    payload: req,
  };
}

export function getLeadbotsListSuccess(res) {
  return {
    type: GET_LEADBOTS_LIST_SUCCESS,
    payload: res,
  };
}

export function getLeadbotsListError(err) {
  return {
    type: GET_LEADBOTS_LIST_ERROR,
    payload: err,
  };
}

export function deleteLeadBotRequest(req) {
  return {
    type: DELETE_LEADBOT_REQUEST,
    payload: req,
  };
}

export function deleteLeadBotSuccess(res) {
  return {
    type: DELETE_LEADBOT_SUCCESS,
    payload: res,
  };
}

export function deleteLeadBotError(err) {
  return {
    type: DELETE_LEADBOT_ERROR,
    payload: err,
  };
}

export function uploadLeadbotImageRequest(err, cb) {
  return {
    type: UPLOAD_LEADBOT_IMAGE_REQUEST,
    payload: err,
    cb,
  };
}
