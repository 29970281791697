import { Form, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import _, { isArray } from "lodash";

//components
import { BlueGradientButton } from "../../../../components/button";
import { CustomScrollableModal } from "../../../../components/modal";
import CustomSwitch from "../../../../components/custom_form/switch";
import { UpgradePlanPage } from "../../../../components/empty_page";
import SubscriptionPlanModal from "../../Billing/containers/subscription-billing-v2/subscription-modal/index";

import { InputLimit } from "../../../../constants";

//actions
import * as actions from "../../actions/settings.actions";
import * as syncActions from "../../actions/sync.actions";

//styles
import "../../styles.scss";
import { dispatchSnackbarError } from "../../../../utils/toaster";

//images
import GoogleImage from "../../../../images/google.svg";
import MicrosoftImage from "../../../../images/microsoft-5.svg";
import GoogleSigninImage from "../../../../images/btn_google_signin_light_normal_web.png";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import GoogleEmailConnect from "../../../../images/gif/google-email-connect.gif";

const trackingOptions = [
  {
    name: "General",
    key: "crm",
  },
  {
    name: "Workflow Automation",
    key: "workflow",
  },
  {
    name: "Sales Cadence",
    key: "cadence",
  },
];

export default function CalendarSync(props) {
  //variables
  const [showEmailSyncTypes, setShowEmailSyncTypes] = useState(false);
  const [otherOptionSelected, setOtherOptionSelected] = useState(false);
  const [ignoredAddress, setIgnoredAddress] = useState("");
  const [syncEmailWith, setSyncEmailWith] = useState("1");
  const [syncStartingFrom, setSyncStartingFrom] = useState("2");
  const [syncOptionsFromTo, setSyncOptionsFromTo] = useState("2");
  const [openStartSyncEmailModal, setOpenStartSyncEmailModal] = useState(false);
  const [openNylasSyncModal, setOpenNylasSyncModal] = useState(false);
  const [nylasEmail, setNylasEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModelTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [showPlans, setShowPlans] = useState(false);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  //redux store details
  const emailStore = useSelector((store) => store.sync.email.data);
  const labelStore = useSelector((store) => store.sync.email.labels);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(syncActions.getEmailSyncDetailsRequest());
    dispatch(syncActions.getEmailFolderLabelRequest());
  }, [dispatch]);

  // const onAddNewAccountClick = () => {
  //     setShowEmailSyncTypes(true)
  // }

  useEffect(() => {
    if (userProfile && userProfile.data && userProfile.data.email) {
      setNylasEmail(userProfile.data.email);
    }
  }, [userProfile]);

  const onEmailsyncOptionsModalClose = () => {
    setShowEmailSyncTypes(false);
  };

  const onEmailsyncOptionsModalSave = () => {
    setShowEmailSyncTypes(false);
  };

  const onOtherOptionSelect = () => {
    setOtherOptionSelected(true);
    setShowEmailSyncTypes(false);
  };

  const onOtherOptionSelectedModalClose = () => {
    setOtherOptionSelected(false);
    setShowEmailSyncTypes(true);
  };

  const onOtherOptionSelectedModalSave = () => {
    setOtherOptionSelected(false);
  };

  const onGoogleButtonClick = () => {
    dispatch(
      actions.emailGoogleRequest((data) => {
        window.location.href = `${data}`;
      }),
    );
  };

  const onLinkEmailsChangeToLeadsDeals = (value) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        linkEmails: value,
      }),
    );
  };

  const onIgnoredAddressChange = (e) => {
    setIgnoredAddress(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onAddIgnoreAddress = () => {
    if (validateEmail(ignoredAddress)) {
      dispatch(
        syncActions.addEmailInIgnoreListRequest({
          _id: emailStore._id,
          email: ignoredAddress,
        }),
      );
      setIgnoredAddress("");
    } else {
      dispatchSnackbarError("Please enter valid email id");
    }
  };
  const onRemoveIgnoreAddress = (email) => {
    dispatch(
      syncActions.removeEmailFromIgnoreListRequest({
        _id: emailStore._id,
        email: email,
      }),
    );
  };

  const onEmailLinksChange = (emailLinks) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        emailLinks: emailLinks,
      }),
    );
  };

  const onOpenTrackingChange = (e) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        emailTracking: {
          openTracking: e.target.checked,
          linkTracking: emailStore && emailStore.emailTracking && emailStore.emailTracking.linkTracking,
          notifications: emailStore && emailStore.emailTracking && emailStore.emailTracking.notifications,
        },
      }),
    );
  };

  const onLinkTrackingChange = (e) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        emailTracking: {
          openTracking: emailStore && emailStore.emailTracking && emailStore.emailTracking.openTracking,
          linkTracking: e.target.checked,
          notifications: emailStore && emailStore.emailTracking && emailStore.emailTracking.notifications,
        },
      }),
    );
  };

  const onNotificationTrackingChange = (e) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        emailTracking: {
          openTracking: emailStore && emailStore.emailTracking && emailStore.emailTracking.openTracking,
          linkTracking: emailStore && emailStore.emailTracking && emailStore.emailTracking.linkTracking,
          notifications: e.target.checked,
        },
      }),
    );
  };

  const onArchiveEmailChange = (e) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        isArchiveFromClient: e.target.checked,
      }),
    );
  };

  const onDeleteEmailChange = (e) => {
    dispatch(
      syncActions.updateEmailSyncDetailsRequest({
        _id: emailStore._id,
        isDeleteFromClient: e.target.checked,
      }),
    );
  };

  const onAllEmailsChange = (e) => {
    if (e.target.value === "1") {
      dispatch(
        syncActions.updateEmailSyncDetailsRequest({
          _id: emailStore._id,
          syncAllLabels: true,
        }),
      );
    } else if (e.target.value === "2") {
      dispatch(
        syncActions.updateEmailSyncDetailsRequest({
          _id: emailStore._id,
          syncAllLabels: false,
        }),
      );
    }
  };

  const onCertainLabelsSelect = (e, type) => {
    if (e.target.checked) {
      emailStore.syncLabels.push(type);
      dispatch(
        syncActions.updateEmailSyncDetailsRequest({
          _id: emailStore._id,
          syncLabels: emailStore.syncLabels,
        }),
      );
    } else {
      const newLabels = emailStore.syncLabels.filter((l) => l !== type && l !== "2");
      dispatch(
        syncActions.updateEmailSyncDetailsRequest({
          _id: emailStore._id,
          syncLabels: newLabels,
        }),
      );
    }
  };

  const onSyncEmailWithChange = (e) => {
    setSyncEmailWith(e.target.value);
  };

  const onSyncStartingFromChange = (e) => {
    setSyncStartingFrom(e.target.value);
  };

  const onSyncOptionsFromToChange = (e) => {
    setSyncOptionsFromTo(e.target.value);
  };

  // const onConnectButtonClick = () => {
  //     setOpenStartSyncEmailModal(true)
  // }

  const onOpenStartSyncEmailModalClose = () => {
    setOpenStartSyncEmailModal(false);
  };

  const onOpenStartSyncEmailModalSave = () => {
    setOpenStartSyncEmailModal(false);
  };

  const onNylasSyncClick = () => {
    setOpenNylasSyncModal(true);
  };

  const onNylasSyncModalClose = () => {
    setOpenNylasSyncModal(false);
    setIsGoogleSignIn(false);
  };

  const onNylasSyncModalSave = () => {
    setOpenNylasSyncModal(false);
    dispatch(
      syncActions.hostedEmailAuthRequest({ email: nylasEmail }, (data) => {
        if (typeof data === "string") {
          window.location.href = `${data}`;
        } else if (typeof data === "object") {
          dispatchSnackbarError(data.message);
        }
      }),
    );
  };

  const onNylasEmailChange = (e) => {
    setNylasEmail(e.target.value);
  };
  const onDeleteButtonClick = () => {
    dispatch(syncActions.deleteEmailSyncAccountRequest());
  };
  const onDisconnectButtonClick = () => {
    dispatch(syncActions.disconnectEmailSyncAccountRequest());
  };
  const onReconnectButtonClick = () => {
    dispatch(syncActions.reconnectEmailSyncAccountRequest());
  };

  const deactivateAccountModelProps = {
    show: showModal,
    title: modalTitle,
    handleClose: () => modalHandleClose(),
    handleAccept: () => modalHandleAccept(),
  };

  const modalHandleAccept = () => {
    if (modalType === "disconnect") {
      onDisconnectButtonClick();
    } else if (modalType === "reconnect") {
      onReconnectButtonClick();
    } else if (modalType === "delete") {
      onDeleteButtonClick();
    }
    modalHandleClose();
  };

  const modalHandleClose = () => {
    setShowModal(false);
    setModelTitle("");
    setModalMessage("");
    setModalType("");
  };

  // const onDisconnectModalOpenClick = () => {
  //   setShowModal(true);
  //   setModelTitle("Disconnect Email Account");
  //   setModalMessage("Are you sure you want to disconnect email account?");
  //   setModalType("disconnect");
  // };
  const onReconnectModalOpenClick = () => {
    setShowModal(true);
    setModelTitle("Reconnect Email Account");
    setModalMessage("Are you sure you want to reconnect email account?");
    setModalType("reconnect");
  };
  const onDeleteModalOpenClick = () => {
    setShowModal(true);
    setModelTitle("Delete Email Account");
    setModalMessage("Are you sure you want to delete email account?");
    setModalType("delete");
  };

  const handleTrackingDetailsUpdate = (e, key, subKey) => {
    const updatePayload = {
      _id: emailStore._id,
      tracking: {
        crm: {
          openTracking: true,
          linkTracking: true,
        },
        workflow: {
          openTracking: true,
          linkTracking: true,
        },
        cadence: {
          openTracking: true,
          linkTracking: true,
        },
      },
    };
    if (emailStore && emailStore.tracking) {
      updatePayload.tracking = emailStore.tracking;
    }

    updatePayload.tracking[key][subKey] = e.target.checked;
    dispatch(syncActions.updateEmailSyncDetailsRequest(updatePayload));
  };
  return (
    <>
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.EMAIL_SYNC ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Email sync"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : (
        <div>
          {_.isEmpty(emailStore) ? (
            <CustomEmptyPage
              page="emailSync"
              customButton={
                <div>
                  <span
                    onClick={() => {
                      onNylasSyncClick();
                      setIsGoogleSignIn(true);
                    }}
                    className="pr-2 cursor-pointer"
                  >
                    <img src={GoogleSigninImage} alt={"google signin"}></img>
                  </span>
                  <BlueGradientButton
                    size="md"
                    onClick={() => {
                      onNylasSyncClick();
                      setIsGoogleSignIn(false);
                    }}
                    className="mr-8px"
                  >
                    + Other Account
                  </BlueGradientButton>
                </div>
              }
            />
          ) : (
            <div className="card mb-5 mb-xl-10 h-vh-160px">
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_connected_accounts"
                aria-expanded="true"
                aria-controls="kt_account_connected_accounts"
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Email Sync</h3>
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9 overflow-y-auto h-vh-220px-mx">
                  {_.isEmpty(emailStore) ? (
                    <div className="add-new-account-div">
                      <div className="add-new-account-wrapper">
                        <div className="add-new-account-text-div">
                          <div className="add-new-account-text">Add new account</div>
                          <div>Sync SalesSimplify emails with any major email provider</div>
                        </div>
                        <div>
                          <span
                            onClick={() => {
                              onNylasSyncClick();
                              setIsGoogleSignIn(true);
                            }}
                            className="pr-2 cursor-pointer"
                          >
                            <img src={GoogleSigninImage} alt={"google signin"}></img>
                          </span>
                          <BlueGradientButton
                            onClick={() => {
                              onNylasSyncClick();
                              setIsGoogleSignIn(false);
                            }}
                            className="mr-8px"
                          >
                            + Other Account
                          </BlueGradientButton>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {!["running", "downloading"].includes(emailStore.syncState) && (
                        <div className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                          We are encountering problems while synchronizing emails with your email service provider. Please delete your connection and re-authenticate the same to resolve.
                        </div>
                      )}
                      <div className="add-new-account-div">
                        <div className="add-new-account-wrapper">
                          <div className="d-flex align-items-center">
                            <img alt="Logo" src={emailStore.nylasAccountData && emailStore.nylasAccountData.provider === "gmail" ? GoogleImage : MicrosoftImage} className="h-30px me-4" />
                            <div className="add-new-account-text-div">
                              <div className="add-new-account-text">{emailStore.syncedEmail}</div>
                              <div>{emailStore.accountStatus === "active" ? "Activated" : emailStore.accountStatus === "deactivated" ? "Deactivated" : emailStore.accountStatus}</div>
                            </div>
                          </div>
                          {emailStore.accountStatus === "active" ? (
                            <div>
                              {/* <button className="btn btn-outline btn-outline-dashed btn-outline-warning btn-active-light-warning" onClick={() => onDisconnectModalOpenClick()}>
                                Disconnect
                              </button> */}
                              <button className="btn btn-danger ml-2" onClick={() => onDeleteModalOpenClick()}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button className="btn btn-outline btn-outline-dashed btn-outline-info btn-active-light-info" onClick={() => onReconnectModalOpenClick()}>
                                Reconnect
                              </button>
                              <button className="btn btn-danger ml-2" onClick={() => onDeleteModalOpenClick()}>
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {emailStore.accountStatus === "active" && (
                        <div>
                          <div className="fw-600 font-size-15px pb-8px pt-5px">Email tracking</div>
                          <p>Email tracking monitors email delivery and engagement, tracks when message is opened or clicked.</p>
                          <table className="w-100 border rounded-2">
                            <thead className="border-bottom">
                              <tr>
                                <th className="p-3 border-right w-50prc">Module Name</th>
                                <th className="w-25prc p-3 border-right">
                                  Open tracking
                                  <OverlayTrigger placement="auto" overlay={<Tooltip>{"Track when email messages have been opened by recipients."}</Tooltip>}>
                                    <i className="ml-2 bi bi-info-circle"></i>
                                  </OverlayTrigger>
                                </th>
                                <th className="w-25prc p-3">
                                  Link tracking
                                  <OverlayTrigger placement="auto" overlay={<Tooltip>{"Track when links in email messages have been clicked by recipients."}</Tooltip>}>
                                    <i className="ml-2 bi bi-info-circle"></i>
                                  </OverlayTrigger>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {trackingOptions.map((each, index) => {
                                return (
                                  <tr key={index} className="mb-2">
                                    <td align="start" className="p-3 border-right">
                                      {each.name}
                                    </td>
                                    <td align="center" className="text-center p-3 border-right">
                                      <CustomSwitch
                                        className="justify-content-center"
                                        id={each.key + index + "open"}
                                        checked={emailStore && emailStore.tracking ? (emailStore.tracking[each.key] && emailStore.tracking[each.key].openTracking ? true : false) : true}
                                        handleChange={(e) => handleTrackingDetailsUpdate(e, each.key, "openTracking")}
                                      />
                                    </td>
                                    <td align="center" className="text-center p-3">
                                      <CustomSwitch
                                        id={each.key + index + "link"}
                                        checked={emailStore && emailStore.tracking ? (emailStore.tracking[each.key] && emailStore.tracking[each.key].linkTracking ? true : false) : true}
                                        handleChange={(e) => handleTrackingDetailsUpdate(e, each.key, "linkTracking")}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {emailStore.accountStatus === "active" && false ? (
                        <div>
                          {/* labels wrapper */}
                          <div>
                            <div className="fw-600 font-size-15px pb-4px pt-16px">Labels</div>
                            <div className="mb-4px">
                              <div className="radio-list">
                                <label className="radio">
                                  <input type="radio" name="radios1234" value="1" checked={emailStore.syncAllLabels ? true : false} onChange={(e) => onAllEmailsChange(e)} />
                                  <span></span>
                                  All emails will be synced with SalesSimplify
                                </label>
                                <label className="radio">
                                  <input type="radio" name="radios1234" value="2" checked={emailStore.syncAllLabels ? false : true} onChange={(e) => onAllEmailsChange(e)} />
                                  <span></span>
                                  Only emails with certain labels will be synced with SalesSimplify
                                </label>
                              </div>
                              {!emailStore.syncAllLabels ? (
                                <div>
                                  <div className="email-label-options">
                                    <div className="checkbox-list">
                                      {labelStore &&
                                        labelStore.map((label, index) => {
                                          return (
                                            <label className="checkbox" key={index}>
                                              <input
                                                type="checkbox"
                                                checked={emailStore && isArray(emailStore.syncLabels) && emailStore.syncLabels.includes(label.name)}
                                                name="Checkboxes4"
                                                onChange={(e) => onCertainLabelsSelect(e, label.name)}
                                              />
                                              <span></span>
                                              {label.display_name}
                                            </label>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* archive and delete */}

                          <div className="mt-10px">
                            <div className="fw-600 font-size-15px pb-4px pt-16px">Archive and Delete</div>
                            <div className="p-2px">
                              <div className="archive-delete-options">
                                <div
                                  className="ml-6px font-size-14px"
                                  style={
                                    {
                                      //color:'#26292c'
                                    }
                                  }
                                >
                                  Archive in my email client when archiving in SalesSimplify
                                </div>
                                <span className="ml-50px">
                                  <CustomSwitch
                                    id="favoriteSwitch"
                                    // checked={props.addFilterFormData["isFavorite"]}
                                    checked={emailStore.isArchiveFromClient}
                                    handleChange={(e) => onArchiveEmailChange(e)}
                                  // handleChange={(e)=>props.setAddFilterFormData({...props.addFilterFormData,"isFavorite":e.target.checked})}
                                  // label="Favorite"
                                  />
                                  {/* <div className="col-3">
                                                <span className="switch switch-primary">
                                                    <label>
                                                        <input type="checkbox" checked={archiveEmail} name="select"  onChange={(e)=>onArchiveEmailChange(e)}/>
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </div> */}
                                </span>
                              </div>
                            </div>
                            <div className="p-2px">
                              <div className="archive-delete-options">
                                <div
                                  className="ml-6px font-size-14px"
                                  style={
                                    {
                                      //color:'#26292c'
                                    }
                                  }
                                >
                                  Delete from my email client when deleting from SalesSimplify
                                </div>
                                <span className="ml-21px">
                                  <CustomSwitch
                                    id="favoriteSwitch"
                                    // checked={props.addFilterFormData["isFavorite"]}
                                    checked={emailStore.isDeleteFromClient}
                                    handleChange={(e) => onDeleteEmailChange(e)}
                                  // handleChange={(e)=>props.setAddFilterFormData({...props.addFilterFormData,"isFavorite":e.target.checked})}
                                  // label="Favorite"
                                  />
                                  {/* <div className="col-3">
                                                    <span className="switch switch-primary">
                                                        <label>
                                                            <input type="checkbox" checked={deleteEmail} name="select" onChange={(e)=>onDeleteEmailChange(e)}/>
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div> */}
                                </span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0 font-size-12px text-747678">Archiving emails in your email client will not archive emails in SalesSimplify.</p>
                              <p className="font-size-12px text-747678">Deleting emails in your email client will always delete emails in SalesSimplify.</p>
                            </div>
                          </div>

                          {/* emial tracking  */}

                          <div className="mt-10px">
                            <div className="fw-600 font-size-15px pb-8px pt-16px">Email tracking</div>
                            <div className="ml-10px">
                              <div className="d-flex align-items-center justify-content-between w-35">
                                <div>
                                  <div className="fw-600 font-size-14px">Open tracking</div>
                                  <p className="text-747678">Track when email messages have been opened by recipients.</p>
                                </div>
                                <div className="ml-30px">
                                  <CustomSwitch
                                    id="favoriteSwitch"
                                    // checked={props.addFilterFormData["isFavorite"]}
                                    checked={emailStore && emailStore.emailTracking && emailStore.emailTracking.openTracking}
                                    handleChange={(e) => onOpenTrackingChange(e)}
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between w-35">
                                <div>
                                  <div className="fw-600 font-size-14px">Link tracking</div>
                                  <p className="text-747678">Track when links in email messages have been clicked by recipients.</p>
                                </div>
                                <div className="ml-30px">
                                  <CustomSwitch
                                    id="favoriteSwitch"
                                    // checked={props.addFilterFormData["isFavorite"]}
                                    checked={emailStore && emailStore.emailTracking && emailStore.emailTracking.linkTracking}
                                    handleChange={(e) => onLinkTrackingChange(e)}
                                  />
                                </div>
                              </div>
                              {emailStore && emailStore.emailTracking && emailStore.emailTracking.linkTracking ? (
                                <div className="d-flex align-items-center justify-content-between w-35">
                                  <div>
                                    <div className="fw-600 font-size-14px">Notifications for tracking activities</div>
                                    <p className="text-747678">Get in-app notifications about open and link tracking activities.</p>
                                  </div>
                                  <div className="ml-30px">
                                    <CustomSwitch id="favoriteSwitch" checked={emailStore && emailStore.emailTracking && emailStore.emailTracking.notifications} handleChange={(e) => onNotificationTrackingChange(e)} />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* email linkswrapper */}
                          <div className="mt-10px">
                            <div className="fw-600 font-size-15px pb-8px pt-16px">Email links</div>
                            <p>When you click an email link in SalesSimplify, you can either open it in SalesSimplify or use the default email app in your computer.</p>
                            <div className="mb-4px">
                              <div className="radio-list">
                                <label className="radio">
                                  <input type="radio" name="radios12" value="1" checked={emailStore && emailStore.emailLinks === "default"} onChange={() => onEmailLinksChange("default")} />
                                  <span></span>
                                  Open email links in SalesSimplify (recommended)
                                </label>
                                <label className="radio">
                                  <input type="radio" name="radios12" value="2" checked={emailStore && emailStore.emailLinks === "application"} onChange={() => onEmailLinksChange("application")} />
                                  <span></span>
                                  Use the default email app in my computer to open email links
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Ignored address */}
                          <div className="mt-10px">
                            <div className="fw-600 font-size-15px pt-16px pb-8px">Ignored addresses</div>
                            <div className="d-flex">
                              <Form.Group id="settings-profile-platform-setting-email-sync-address-change" className="w-20 mb-0">
                                <Form.Control maxLength={InputLimit.EMAIL} type="email" placeholder="Email address" value={ignoredAddress} onChange={(e) => onIgnoredAddressChange(e)} />
                              </Form.Group>
                              <span className="ml-20px">
                                <BlueGradientButton onClick={() => onAddIgnoreAddress()}>Add</BlueGradientButton>
                              </span>
                            </div>
                            {emailStore.ignoredAddresses && emailStore.ignoredAddresses.length > 0 && (
                              <div className="d-flex flex-wrap border rounded border-dotted p-5 color-red">
                                {emailStore.ignoredAddresses.map((eachEmail, index) => {
                                  return (
                                    <div>
                                      <span className="w-25px h-25px pr-2 mb-4">{eachEmail}</span>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow mr-4"
                                        data-kt-image-input-action="remove"
                                        data-bs-toggle="tooltip"
                                        title="Remove avatar"
                                      >
                                        <i className="bi bi-x fs-2" onClick={() => onRemoveIgnoreAddress(eachEmail)} />
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            <p className="mt-4px">
                              Add email addresses that you don’t want to receive emails from here. Need to block more than one address from the same company? Just enter the company’s domain to block all emails from all
                              addresses associated with it, e.g.,*@example.com
                            </p>
                          </div>

                          <div className="mt-10px">
                            <div className="fw-600 font-size-15px pt-16px pb-8px">Link emails to leads and deals</div>
                            <div className="mb-4px">
                              <div className="radio-list">
                                <label className="radio">
                                  <input type="radio" name="radios1" value="1" checked={emailStore && emailStore.linkEmails === "manually"} onChange={() => onLinkEmailsChangeToLeadsDeals("manually")} />
                                  <span></span>
                                  Link emails manually
                                </label>
                                <label className="radio">
                                  <input type="radio" name="radios1" value="2" checked={emailStore && emailStore.linkEmails === "auto"} onChange={() => onLinkEmailsChangeToLeadsDeals("auto")} />
                                  <span></span>
                                  Automatically link emails to leads or deals
                                </label>
                              </div>
                            </div>
                            <p className="font-size-12px text-747678">
                              SalesSimplify will look for an open lead or deal related to the contact person the email was sent to or received from. When only one open lead or deal is found that matches the contact
                              person, the email will be linked to it automatically.
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <CustomScrollableModal
            modalProps={{
              show: openNylasSyncModal,
              handleClose: () => onNylasSyncModalClose(),
              title: "Connect Email Account",
              showButtons: true,
              buttonText: "Continue",
              showCloseButton: true,
              size: isGoogleSignIn ? "lg" : "md",
              handleAccept: () => onNylasSyncModalSave(),
            }}
          >
            <Form.Group className="px-4">
              {/* <Form.Group>
                <Form.Label><strong>"Whitelist Sales Simplify app from Google Admin"</strong> In order to utilize Sales Simplify's 2-way synchronization feature, it is mandatory to whitelist the application from your Google Admin Console, as per the guidelines recently set by Google. <a target={"_blank"} rel="noopener noreferrer" href="https://salessimplify.com/help-center/authenticate-sales-simplify-for-email-calendar-sync">Click here and whitelist</a></Form.Label>
              </Form.Group> */}
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control maxLength={InputLimit.EMAIL} type="email" value={nylasEmail} placeholder="Enter email address" onChange={(e) => onNylasEmailChange(e)} />
              </Form.Group>
              {isGoogleSignIn && (
                <Form.Group>
                  <Form.Label>
                    <strong>"Whitelist Sales Simplify app from Google Admin"</strong> In order to utilize Sales Simplify's 2-way synchronization feature, it is mandatory to whitelist the application from your Google
                    Admin Console, as per the guidelines recently set by Google.{" "}
                    <a target={"_blank"} rel="noopener noreferrer" href="https://salessimplify.com/help-center/authenticate-sales-simplify-for-email-calendar-sync">
                      Click here and whitelist
                    </a>
                  </Form.Label>
                  <img alt="" width={"100%"} src={GoogleEmailConnect} />
                </Form.Group>
              )}
            </Form.Group>
          </CustomScrollableModal>
          {showEmailSyncTypes ? (
            <CustomScrollableModal
              modalProps={{
                show: showEmailSyncTypes,
                handleClose: () => onEmailsyncOptionsModalClose(),
                title: "Choose your email provider",
                showButtons: false,
                buttonText: "Save",
                showCloseButton: true,
                handleAccept: () => onEmailsyncOptionsModalSave(),
              }}
            >
              <div className="d-flex flex-column align-items-center">
                <button onClick={() => onGoogleButtonClick()} className="btn btn-light-primary btn-lg fw-bolder my-2 w-40">
                  <img alt="Logo" src={require("../../../../images/google.svg")} className="h-20px me-3" />
                  Google
                </button>
                <a href="https://saino.io" className="btn btn-light-primary btn-lg fw-bolder my-2 w-40">
                  <img alt="Logo" src={require("../../../../images/microsoft-5.svg")} className="h-20px me-3" />
                  Exchange
                </a>
                <a href="https://saino.io" className="btn btn-light-primary btn-lg fw-bolder my-2 w-40">
                  <img alt="Logo" src={require("../../../../images/microsoft-5.svg")} className="h-20px me-3" />
                  Office 365
                </a>
                <div className="btn btn-light-primary btn-lg fw-bolder my-2 w-40" onClick={() => onOtherOptionSelect()}>
                  Other
                </div>
              </div>
            </CustomScrollableModal>
          ) : null}
          {otherOptionSelected ? (
            <CustomScrollableModal
              modalProps={{
                show: otherOptionSelected,
                handleClose: () => onOtherOptionSelectedModalClose(),
                title: "Connect email account",
                showButtons: true,
                buttonText: "Sign in",
                handleAccept: () => onOtherOptionSelectedModalSave(),
                textReplacingWithCloseButton: true,
              }}
            >
              <div>
                <div className="text-center">
                  <div className="mb-12px fw-600 font-size-18px text-26292c">Sign in</div>
                  <div className="font-size-14px text-26292c">Enter your credentials to continue</div>
                </div>
                <Form className="p-20px">
                  <Form.Group id="settings-profile-platform-setting-email-sync-username" className="mb-8px">
                    <Form.Label aria-label="username">Username</Form.Label>
                    <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Username" />
                  </Form.Group>
                  <Form.Group id="settings-profile-platform-setting-email-sync-password" className="mb-8px">
                    <Form.Label aria-label="password">Password</Form.Label>
                    <Form.Control maxLength={InputLimit.PASSWORD} type="password" />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Group id="settings-profile-platform-setting-email-sync-incoming-server" className="w-60 mb-8px">
                      <Form.Label aria-label="incoming-server">Incoming server (IMAP)</Form.Label>
                      <Form.Control maxLength={InputLimit.EMAIL} type="email" placeholder="mail.example.com" />
                    </Form.Group>
                    <Form.Group id="settings-profile-platform-setting-email-sync-port" className="w-30 mb-8px">
                      <Form.Label aria-label="port">Port</Form.Label>
                      <Form.Control maxLength={InputLimit.NUMBER} type="number" placeholder="111" />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Form.Group id="settings-profile-platform-setting-email-sync-outgoing-server" className="w-60 mb-8px">
                      <Form.Label aria-label="outgoing-server">Outgoing server (SMTP)</Form.Label>
                      <Form.Control maxLength={InputLimit.EMAIL} type="email" placeholder="mail.example.com" />
                    </Form.Group>
                    <Form.Group id="settings-profile-platform-setting-email-sync-port-number" className="w-30 mb-8px">
                      <Form.Label aria-label="port">Port</Form.Label>
                      <Form.Control maxLength={InputLimit.NUMBER} type="number" placeholder="111" />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </CustomScrollableModal>
          ) : null}
          <CustomScrollableModal
            modalProps={{
              show: openStartSyncEmailModal,
              handleClose: () => onOpenStartSyncEmailModalClose(),
              title: "Which emails should be synced?",
              showButtons: true,
              buttonText: "Start syncing",
              handleAccept: () => onOpenStartSyncEmailModalSave(),
            }}
          >
            <div>
              <div>You're about to connect sowjanya@saino.io with SalesSimplify.</div>
              <div className="mt-10px">
                <div className="fw-600 font-size-15px pt-16px pb-8px">Sync emails with:</div>
                <div className="mb-4px">
                  <div className="radio-list">
                    <label className="radio">
                      <input type="radio" name="radios1091" value="1" checked={syncEmailWith === "1" ? true : false} onChange={(e) => onSyncEmailWithChange(e)} />
                      <span></span>
                      All emails will be synced with SalesSimplify
                    </label>
                    <label className="radio">
                      <input type="radio" name="radios1091" value="2" checked={syncEmailWith === "2" ? true : false} onChange={(e) => onSyncEmailWithChange(e)} />
                      <span></span>
                      Only emails with certain labels will be synced with SalesSimplify
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-10px">
                <div className="fw-600 font-size-15px pt-16px pb-8px">Sync starting from:</div>
                <div className="mb-4px">
                  <div className="radio-list">
                    <label className="radio">
                      <input type="radio" name="now" value="1" checked={syncStartingFrom === "1" ? true : false} onChange={(e) => onSyncStartingFromChange(e)} />
                      <span></span>
                      Now
                    </label>
                    <label className="radio">
                      <input type="radio" name="1monthago" value="2" checked={syncStartingFrom === "2" ? true : false} onChange={(e) => onSyncStartingFromChange(e)} />
                      <span></span>1 month ago
                    </label>
                    <label className="radio">
                      <input type="radio" name="3monthsago" value="3" checked={syncStartingFrom === "3" ? true : false} onChange={(e) => onSyncStartingFromChange(e)} />
                      <span></span>3 month ago
                    </label>
                    <label className="radio">
                      <input type="radio" name="6monthsago" value="4" checked={syncStartingFrom === "4" ? true : false} onChange={(e) => onSyncStartingFromChange(e)} />
                      <span></span>6 month ago
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-10px">
                <div className="fw-600 font-size-15px pt-16px pb-8px">Sync past emails sent to and received from:</div>
                <div className="mb-4px">
                  <div className="radio-list">
                    <label className="radio">
                      <input type="radio" name="salessimplifyContactsOnly" value="1" checked={syncOptionsFromTo === "1" ? true : false} onChange={(e) => onSyncOptionsFromToChange(e)} />
                      <span></span>
                      SalesSimplify contacts only
                    </label>
                    <label className="radio">
                      <input type="radio" name="allPeople" value="2" checked={syncOptionsFromTo === "2" ? true : false} onChange={(e) => onSyncOptionsFromToChange(e)} />
                      <span></span>
                      All people
                    </label>
                  </div>
                </div>
                <p className="mt-4px">This won't affect your future emails. All emails in the selected folders will be synced.</p>
              </div>
            </div>
          </CustomScrollableModal>
          <Modal backdrop="static" show={deactivateAccountModelProps.show} onHide={deactivateAccountModelProps.handleClose} size={"md"}>
            <Modal.Header closeButton>
              <Modal.Title>{deactivateAccountModelProps.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={deactivateAccountModelProps.handleClose}>
                Close
              </Button>
              <Button variant={modalType === "disconnect" ? "warning" : modalType === "reconnect" ? "info" : "danger"} size="sm" onClick={deactivateAccountModelProps.handleAccept}>
                {modalType === "disconnect" ? "Disconnect" : modalType === "reconnect" ? "Reconnect" : "Delete"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}
