import {
  GET_QUESTION_FIELDS_REQUEST,
  GET_QUESTION_FIELDS_SUCCESS,
  GET_QUESTION_FIELDS_ERROR,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_ERROR,
  GET_OWNER_REQUEST,
  GET_OWNER_SUCCESS,
  GET_OWNER_ERROR,
  GET_LEADBOT_DATA_REQUEST,
  GET_LEADBOT_DATA_SUCCESS,
  GET_LEADBOT_DATA_ERROR,
  EDIT_LEADBOT_DATA_REQUEST,
  EDIT_LEADBOT_DATA_SUCCESS,
  EDIT_LEADBOT_DATA_ERROR,
  CREATE_LEADBOT_REQUEST,
  CREATE_LEADBOT_SUCCESS,
  CREATE_LEADBOT_ERROR,
  DELETE_LEADBOT_REQUEST,
  DELETE_LEADBOT_SUCCESS,
  DELETE_LEADBOT_ERROR,
  GET_LEADBOTS_LIST_REQUEST,
  GET_LEADBOTS_LIST_SUCCESS,
  GET_LEADBOTS_LIST_ERROR,
} from "./types";

const initialState = {
  fields: {
    list: [],
    loading: false,
    error: "",
  },
  tags: {
    list: [],
    loading: false,
    error: "",
  },
  owner: {
    list: [],
    loading: false,
    error: "",
  },
  leadbot: {
    data: {},
    loading: false,
    error: "",
  },
  leadbotsList: {
    data: [],
    loading: false,
    error: "",
    count: 0,
  },
};

export function leadBotReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_LEADBOTS_LIST_REQUEST:
      return {
        ...state,
        leadbotsList: {
          ...state.leadbotsList,
          loading: true,
        },
        leadbot: {
          data: {},
          loading: false,
          error: "",
        },
      };
    case GET_LEADBOTS_LIST_SUCCESS:
      return {
        ...state,
        leadbotsList: {
          ...state.leadbotsList,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case GET_LEADBOTS_LIST_ERROR:
      return {
        ...state,
        leadbotsList: {
          ...state.leadbotsList,
          loading: false,
          error: action.payload,
        },
      };

    case GET_QUESTION_FIELDS_REQUEST:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true,
        },
      };

    case GET_QUESTION_FIELDS_SUCCESS:
      // console.log(action.payload)
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          list: action.payload,
        },
      };
    case GET_QUESTION_FIELDS_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false,
          error: action.payload,
        },
      };
    case GET_TAGS_REQUEST:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
        },
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
          list: action.payload,
        },
      };
    case GET_TAGS_ERROR:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
          error: action.payload,
        },
      };
    case GET_OWNER_REQUEST:
      return {
        ...state,
        owner: {
          ...state.owner,
          loading: true,
        },
      };
    case GET_OWNER_SUCCESS:
      return {
        ...state,
        owner: {
          ...state.owner,
          loading: false,
          list: action.payload,
        },
      };
    case GET_OWNER_ERROR:
      return {
        ...state,
        owner: {
          ...state.owner,
          loading: false,
          error: action.payload,
        },
      };
    case GET_LEADBOT_DATA_REQUEST:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: true,
          error: "",
        },
      };
    case GET_LEADBOT_DATA_SUCCESS:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case GET_LEADBOT_DATA_ERROR:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: false,
          error: action.payload,
        },
      };

    case EDIT_LEADBOT_DATA_REQUEST:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: true,
        },
      };
    case EDIT_LEADBOT_DATA_SUCCESS:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: false,
        },
      };
    case EDIT_LEADBOT_DATA_ERROR:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: false,
        },
      };
    case CREATE_LEADBOT_REQUEST:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: true,
        },
      };
    case CREATE_LEADBOT_SUCCESS:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: false,
        },
      };
    case CREATE_LEADBOT_ERROR:
      return {
        ...state,
        leadbot: {
          ...state.leadbot,
          loading: false,
        },
      };
    case DELETE_LEADBOT_REQUEST:
      return {
        ...state,
        leadbotsList: {
          ...state.leadbotsList,
          loading: true,
        },
      };
    case DELETE_LEADBOT_SUCCESS:
      return {
        ...state,
        leadbotsList: {
          ...state.leadbotsList,
          loading: false,
        },
      };
    case DELETE_LEADBOT_ERROR:
      return {
        ...state,
        leadbotsList: {
          ...state.leadbotsList,
          loading: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
}
