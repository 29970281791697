import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { UpgradePlanPage } from "../../../../../components/empty_page";
import SubscriptionPlanModal from "../../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import { selectedDummyData } from "../constants";

// actions
import * as schedulingSettingActions from "../../../store/actions/profile-actions/shedular-settings.actions";
import * as leadActions from "../../../../leads/store/actions/actions";
import * as syncSettingActions from "../../../store/actions/profile-actions/sync-settings.actions";

import { IconButton } from "../../../../../components/button/icon-action-buttons";
import { Button, Col, Dropdown, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import EditSchedulingPage from "../edit-scheduling";
import { CustomModal, DeleteModal } from "../../../../../components/modal";
import SchedulingPageAddForm from "../components/scheduling-page.add-form";
import CopyEmbeddedCodeModel from "../components/copy-code.model";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import { CloneIconSVG, CodeIconSVG, CopyPastIconSVG, DeleteIconSVG, EditIconSVG, EYESVG } from "../../../../../components/custom-svgs";

export default function SchedulingPageList() {
  const [showPlans, setShowPlans] = useState(false);
  const [selectedSyncId, setSelectedSyncId] = useState(null);
  const [selectedSyncData, setSelectedSyncData] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(selectedDummyData);
  const [activeStep, setActiveStep] = useState(1);
  const [createData, setCreateData] = useState({ duration: 60, futureTime: 15, slug: `60-minutes`, from: "09:30", to: "18:30", weekDays: ["M", "T", "W", "R", "F"] });
  const [fieldMapping, setFieldMapping] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmbeddedModal, setShowEmbeddedModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [suggestedSlug, setSuggestedSlug] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  //redux store details
  const schedularListStore = useSelector((store) => store.settings.profile.schedular.list);
  const schedularSelectedStore = useSelector((store) => store.settings.profile.schedular.selected);
  const fieldStore = useSelector((state) => state.Leads.leadFields);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
    dispatch(leadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedSyncId) dispatch(schedulingSettingActions.getListOfSchedulingPagesRequest({ syncId: selectedSyncId }));
  }, [dispatch, selectedSyncId]);

  useEffect(() => {
    setSelectedSyncId(params.calendarSyncId);
  }, [params]);

  useEffect(() => {
    if (syncSettingsStore.data.length > 0 && selectedSyncId) {
      const find = syncSettingsStore.data.find((item) => item._id === selectedSyncId);
      if (find) setSelectedSyncData(find);
      else history.push("/settings/profile/scheduler");
    }
  }, [syncSettingsStore.data, selectedSyncId, history]);

  useEffect(() => {
    if (!_.isEmpty(userProfile.data)) {
      const { fullName, company } = userProfile.data;
      let slug = "";
      if (!_.isEmpty(company)) {
        slug =
          slug +
          company
            .trim()
            .split(" ")
            .slice(0, 2)
            .join("-");
      }
      if (!_.isEmpty(fullName)) {
        slug =
          slug +
          "-" +
          fullName
            .trim()
            .split(" ")
            .slice(0, 2)
            .join("-");
      }
      setSuggestedSlug(slug.replace(/[^a-zA-Z\- ]/g, "").toLowerCase());
      setCreateData({ slug: `${slug}-60-minutes`, timezone: userProfile.data.timezone, duration: 60, futureTime: 15, from: "09:30", to: "18:30", weekDays: ["M", "T", "W", "R", "F"] });
    }
  }, [userProfile.data]);

  useEffect(() => {
    if (fieldStore.list.length > 0) {
      const _op = {};
      fieldStore.list.forEach((each) => {
        if (each.isEditable && each.fieldInputType !== "DATE_RANGE" && each.fieldInputType !== "DATE_TIME_RANGE") {
          if (each.fieldGroup === "CUSTOM") {
            _op[`customFields.${each.apiKeyName}`] = each;
          } else {
            _op[each.apiKeyName] = each;
          }
        }
      });
      setFieldMapping(_op);
    }
  }, [fieldStore.list]);

  const handleSchedularCardActions = (action, data) => {
    if (action === "copy-link") {
      const textField = document.createElement("textarea");
      textField.innerText = `https://meeting.salessimplify.com/${data.slug}`;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      dispatchSnackbarSuccess("Copied to Clipboard!");
    } else if (action === "view") {
      window.open(`https://meeting.salessimplify.com/${data.slug}`, "_blank", "noopener,noreferrer");
    } else if (action === "clone") {
      dispatch(
        schedulingSettingActions.createSchedulingPageRequest(
          {
            syncId: selectedSyncId,
            data: {
              slug: `${data.slug}-${new Date().getTime()}`,
              name: `${data.name}-copy`,
              config: { ...data.config },
            },
          },
          (data) => {
            if (data.status) {
              dispatchSnackbarSuccess("Successfully Created.");
              dispatch(schedulingSettingActions.getListOfSchedulingPagesRequest());
            }
          },
        ),
      );
    } else if (action === "edit") {
      setShowAddForm(false);
      setIsEdit(true);
      setSelectedData(data);
      dispatch(schedulingSettingActions.getAvailableCalendarsRequest({ id: data.id, syncId: selectedSyncId }));
    } else if (action === "delete") {
      setShowDeleteModal(true);
      setSelectedData(data);
    } else if (action === "embedded") {
      setSelectedData(data);
      setShowEmbeddedModal(true);
    }
  };

  const handleAddSchedularClick = () => {
    setShowAddForm(true);
    setIsEdit(false);
    setSelectedData({});
  };
  const handleCloseSchedulingForm = () => {
    if (activeStep === 1) {
      setActiveStep(1);
      setShowAddForm(false);
      setIsEdit(false);
      setSelectedData({});
      setCreateData({ slug: `${suggestedSlug}-60-minutes`, duration: 60, futureTime: 15, from: "09:30", to: "18:30", weekDays: ["M", "T", "W", "R", "F"] });
    } else if (activeStep === 2) {
      setActiveStep(1);
    }
  };
  const handleAcceptSchedulingForm = () => {
    if (activeStep === 1) {
      const { duration, name } = createData;
      if (_.isEmpty(name)) {
        return dispatchSnackbarError("Please enter event name.");
      } else if (!duration) {
        return dispatchSnackbarError("Please select event duration.");
      }
      setActiveStep(2);
    } else if (activeStep === 2) {
      const { duration, from, futureTime, location, name, slug, timezone, to, weekDays } = createData;
      if (_.isEmpty(name)) {
        return dispatchSnackbarError("Please enter event name.");
      } else if (!duration) {
        return dispatchSnackbarError("Please select event duration.");
      } else if (_.isEmpty(weekDays)) {
        return dispatchSnackbarError("Please select when can events be booked");
      } else if (!futureTime) {
        return dispatchSnackbarError("Please select future event duration.");
      } else if (_.isEmpty(from)) {
        return dispatchSnackbarError("Please select from time.");
      } else if (_.isEmpty(to)) {
        return dispatchSnackbarError("Please select to time.");
      } else if (_.isEmpty(timezone)) {
        return dispatchSnackbarError("Please select timezone.");
      } else if (_.isEmpty(slug)) {
        return dispatchSnackbarError("Please enter booking url.");
      }

      setCreateLoading(true);
      dispatch(
        schedulingSettingActions.createSchedulingPageRequest(
          {
            syncId: selectedSyncId,
            data: {
              slug: slug,
              name: name,
              config: {
                calendar_ids: {},
                timezone: timezone,
                event: {
                  duration: duration,
                  location: location,
                  title: name,
                  capacity: 1,
                },
                appearance: {
                  color: "#0095f6",
                  company_name: userProfile.data.company,
                  submit_text: "Submit",
                  show_nylas_branding: false,
                  logo: "",
                  thank_you_text: "Thank you. You will be receiving the meeting invite soon.",
                },
                reminders: [],
                booking: {
                  additional_fields: [],
                  available_days_in_future: futureTime,
                  confirmation_method: "automatic",
                  min_booking_notice: 120,
                  min_buffer: 30,
                  min_cancellation_notice: 180,
                  opening_hours: [
                    {
                      days: weekDays,
                      start: from,
                      end: to,
                    },
                  ],
                  scheduling_method: "round-robin-maximize-availability",
                },
                locale: "en",
                features: {
                  collective_meetings: false,
                  group_meetings: false,
                },
              },
            },
          },
          (data) => {
            setCreateLoading(false);
            if (data.status) {
              dispatchSnackbarSuccess("Successfully Created.");
              dispatch(schedulingSettingActions.getListOfSchedulingPagesRequest({ syncId: selectedSyncId }));
              setActiveStep(1);
              setShowAddForm(false);
              setIsEdit(false);
              setSelectedData({});
              setCreateData({ slug: `${suggestedSlug}-60-minutes`, duration: 60, futureTime: 15, from: "09:30", to: "18:30", weekDays: ["M", "T", "W", "R", "F"] });
            } else {
            }
          },
        ),
      );
    }
  };

  const addEditFormModalProps = {
    show: showAddForm,
    showButtons: true,
    size: "lg",
    title: isEdit ? "Edit Scheduling Page" : "Create a Scheduling Page",
    loading: createLoading,
    buttonText: activeStep === 1 ? "Next" : "Create",
    handleClose: handleCloseSchedulingForm,
    handleAccept: handleAcceptSchedulingForm,
  };

  const handleEditPageSave = () => {
    dispatch(
      schedulingSettingActions.updatedSchedulingPageRequest({
        id: selectedData.id,
        syncId: selectedSyncId,
        data: _.pick(selectedData, ["config", "name", "slug"]),
      }),
    );
  };

  const showWarning = () => {
    if (_.isEmpty(selectedSyncData)) {
      return true;
    } else if (_.isEmpty(selectedSyncData.calendarId)) {
      return true;
    } else if (selectedSyncData.syncType !== "two_way") {
      return true;
    } else if (_.isEmpty(selectedSyncData.saveEvent)) {
      return true;
    }
    return false;
  };

  const deleteSchedulingPageModalProps = {
    show: showDeleteModal,
    title: "Delete Reminder",
    handleClose: () => onHandleClose(),
    handleAccept: () => onDeleteAccept(),
  };

  const onHandleClose = () => {
    setShowDeleteModal(false);
    setSelectedData({});
  };

  const onDeleteAccept = () => {
    dispatch(schedulingSettingActions.deleteSchedulingPageRequest({ id: selectedData.id, syncId: selectedSyncId }));
    setShowDeleteModal(false);
    setSelectedData({});
  };

  return (
    <>
      {accountBillingStore.data.servicesOffered && (!accountBillingStore.data.servicesOffered.CALENDAR_SYNC || ["free", "startup"].includes(accountBillingStore.data.selectedPlan.toLowerCase())) ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Meeting Scheduler"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : (
        <div>
          {syncSettingsStore.loading || schedularListStore.loading || _.isEmpty(selectedSyncData) ? (
            <ContentWithCardLoading />
          ) : _.isEmpty(syncSettingsStore.data) ? (
            <CustomEmptyPage page={"schedularCalendarConnect"} />
          ) : _.isEmpty(schedularListStore.data) ? (
            <CustomEmptyPage page={"schedularEmpty"} setShowAdd={handleAddSchedularClick} />
          ) : (
            <div className="schedular-wrapper card mb-5 mb-xl-10 h-vh-160px">
              <div className="card-header border-0 cursor-pointer align-items-center" role="button">
                {!isEdit ? (
                  <div className="card-title m-0">
                    <div className="d-flex align-items-center gap-3">
                      <div className="bg-hover-gray-300 rounded-1 w-30px h-30px d-flex flex-center">
                        <i className="bi bi-arrow-left-short fs-1 text-black" onClick={() => history.push("/settings/profile/scheduler")}></i>
                      </div>
                      <h3 className="fw-bolder m-0">{selectedSyncData.syncedEmail}</h3>
                    </div>
                  </div>
                ) : (
                  <div className="card-title m-0 d-flex align-items-center gap-3">
                    <IconButton onClick={() => handleCloseSchedulingForm()} tooltip={"Back To Schduling List"}>
                      <i className="bi bi-arrow-left text-black" />
                    </IconButton>
                    <h3 className="fw-bolder m-0">{selectedData.name}</h3>
                  </div>
                )}
                <div>
                  {!isEdit ? (
                    <Button className="commonStyle btn btn-sm btn-primary my-1" onClick={() => handleAddSchedularClick()}>
                      + Scheduler
                    </Button>
                  ) : (
                    <Button disabled={schedularSelectedStore.updateLoading} className="commonStyle btn btn-sm btn-primary my-1" onClick={() => handleEditPageSave()}>
                      {schedularSelectedStore.updateLoading && <Spinner animation="border" className="mr-10px w-16px h-16px"></Spinner>}
                      Save
                    </Button>
                  )}
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9 h-vh-220px overflow-auto">
                  {showWarning() && (
                    <div class="d-flex align-items-center rounded py-5 px-5 bg-light-warning mb-8">
                      <span class="svg-icon svg-icon-3x svg-icon-warning me-5">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                          <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                          <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                        </svg>
                      </span>
                      <div class="text-gray-700 fw-bold fs-6">
                        Ensure that your calendar sync type is set to two-way sync and that all calendar sync settings are properly configured. To ensure that a new booking is immediately reflected in the activity,
                        generate an activity every time a new booking is established. Go To{" "}
                        <Button variant="link" onClick={() => history.push("/settings/profile/sync-settings")}>
                          Calendar Sync
                        </Button>
                      </div>
                    </div>
                  )}
                  {!isEdit ? (
                    <Row xl={3} xxl={3} md={2} lg={2}>
                      {schedularListStore.data.map((each, index) => {
                        return (
                          <Col className="mb-8">
                            <div className="card h-100 p-5 bg-light bg-hover-light-primary position-relative" key={index}>
                              <div className="fs-5 fw-bolder mb-3">{each.name}</div>
                              <div>
                                <strong>Duration: </strong>
                                {each.config.event.duration} Min
                              </div>
                              <div>
                                <strong>Event Location: </strong>
                                {each.config.event.location}
                              </div>
                              <div className="mb-9">
                                <strong>Created At: </strong>
                                {moment(each.created_at).format("LLL")}
                              </div>
                              <div className="bottom-0 d-flex justify-content-between mb-5 mt-4 position-absolute w-100-30px">
                                <div className="d-flex gap-6">
                                  <Button className="mb-0 pb-0 d-flex gap-2 align-items-center" onClick={() => handleSchedularCardActions("copy-link", each)} variant="link">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Copy Link"}</Tooltip>}>
                                      {CopyPastIconSVG}
                                    </OverlayTrigger>
                                  </Button>
                                  <Button className="mb-0 pb-0 d-flex gap-2 align-items-center" onClick={() => handleSchedularCardActions("view", each)} variant="link">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{"View"}</Tooltip>}>
                                      {EYESVG}
                                    </OverlayTrigger>
                                  </Button>
                                  <Button className="mb-0 pb-0 d-flex gap-2 align-items-center" onClick={() => handleSchedularCardActions("embedded", each)} variant="link">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Embed"}</Tooltip>}>
                                      {CodeIconSVG}
                                    </OverlayTrigger>
                                  </Button>
                                </div>
                                <div>
                                  <Button className="mb-0 pb-0 d-flex gap-2 align-items-center" onClick={() => handleSchedularCardActions("edit", each)} variant="link">
                                    {EditIconSVG}
                                    Customise
                                  </Button>
                                </div>
                              </div>
                              <div className="each-card-setting-wrapper">
                                <Dropdown className="dropdown-inline ">
                                  <Dropdown.Toggle className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none" variant={"link"}>
                                    <i class="bi bi-gear"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item className="d-flex gap-2" onSelect={() => handleSchedularCardActions("clone", each)}>
                                      {CloneIconSVG}
                                      Clone
                                    </Dropdown.Item>
                                    <Dropdown.Item className="d-flex gap-2" onSelect={() => handleSchedularCardActions("delete", each)}>
                                      {DeleteIconSVG}
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <EditSchedulingPage syncId={selectedSyncId} fieldMapping={fieldMapping} data={selectedData} setSelectedData={setSelectedData} />
                  )}
                </div>
              </div>
            </div>
          )}
          <CustomModal modalProps={addEditFormModalProps}>
            <SchedulingPageAddForm suggestedSlug={suggestedSlug} activeStep={activeStep} createData={createData} setCreateData={setCreateData} />
          </CustomModal>
          <DeleteModal modalProps={deleteSchedulingPageModalProps}>Are you sure you want to delete the scheduling page?</DeleteModal>
          <CopyEmbeddedCodeModel
            slug={selectedData.slug}
            show={showEmbeddedModal}
            handleClose={() => {
              setShowEmbeddedModal(false);
              setSelectedData({});
            }}
          />
        </div>
      )}
    </>
  );
}
