import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCadenceListRequest } from "../../../containers/Automation/cadence/store/actions/cadence_actions";
import { getListOfCadenceByLeadIdRequest, addLeadToCadenceRequest } from "../../../containers/Automation/cadence/store/actions/cadence_lead_actions";
import * as CadenceSubscriberOperationActions from "../../../containers/Automation/cadence/store/actions/cadence_subscriber_operation_actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import SimpleDropdown from "../../dropdowns/simple-dropdown";
import { CustomModal } from "../../modal";
import { CustomEmptyPage } from "../../empty_page/custom-empty-page";
import CustomPagination from "../../pagination/custom-pagination";
import CadenceLeadListCard from "../custom-components/cadence-lead-list-card";

export default function CadenceSidePannel(props) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [showSelectCadence, setShowSelectCadence] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCadence, setSelectedCadence] = useState({});

  const dispatch = useDispatch();

  const leadCadenceStore = useSelector((store) => store.cadence.leadCadence);
  const cadenceList = useSelector((store) => store.cadence.list);

  useEffect(() => {
    dispatch(getCadenceListRequest({ limit: 100, page: 1, searchText: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (cadenceList.data && leadCadenceStore.data) {
      const _options = cadenceList.data.map((each) => ({ value: each._id, label: each?.name }));
      const inProccessIds = leadCadenceStore.data.filter((each) => each.status !== "FINISHED").map((a) => a.cadenceId);
      const a = _options.filter((each) => !inProccessIds.includes(each.value));
      setOptions(a);
    }
  }, [cadenceList.data, leadCadenceStore.data]);

  useEffect(() => {
    const payload = {
      subscriberId: props.moduleId,
      page: page,
      limit: limit,
    };
    dispatch(getListOfCadenceByLeadIdRequest(payload));
  }, [dispatch, props.moduleId, page, limit]);

  const handleSaveClick = (e) => {
    e.stopPropagation();
    if (_.isEmpty(selectedCadence)) {
      return dispatchSnackbarError("Please select cadence!");
    }
    const moduleId = props.moduleId;
    const leadType = _.upperCase(props.moduleName);
    if (moduleId && leadType && selectedCadence) {
      const payload = {
        moduleName: leadType,
        moduleId: moduleId,
        cadenceId: selectedCadence.value,
      };
      dispatch(
        addLeadToCadenceRequest(payload, (data) => {
          if (data.status) {
            dispatchSnackbarSuccess(data.message);
          } else {
            dispatchSnackbarError(data.message);
          }
          setShowSelectCadence(false);
          const payload = {
            subscriberId: props.moduleId,
            page: page,
            limit: limit,
          };
          dispatch(getListOfCadenceByLeadIdRequest(payload));
        }),
      );
    }
  };
  const onCancelAddCadence = (e) => {
    e.stopPropagation();
    setShowSelectCadence(false);
  };

  const unsubscribeCadence = (each) => {
    const payload = {
      from: "module-list",
      data: {
        type: "SINGLE",
        cadenceId: each.cadenceId,
        cadenceSubscriberId: each._id,
      },
      filter: {
        subscriberId: props.moduleId,
        page: 1,
        limit: limit,
      },
    };
    dispatch(CadenceSubscriberOperationActions.removeFromCadenceSubscriberRequest(payload));
  };

  const AddCadenceModalProps = {
    showButtons: true,
    show: showSelectCadence,
    title: "Subscribe To Cadence",
    loading: false,
    buttonText: "Subscribe To Cadence",
    handleClose: onCancelAddCadence,
    handleAccept: handleSaveClick,
  };

  return (
    <div className="w-100">
      {leadCadenceStore.isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-580px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : leadCadenceStore.data.length === 0 ? (
        <CustomEmptyPage page="subscribeToCadence" setShowAdd={setShowSelectCadence} />
      ) : (
        <>
          <div className="w-100 d-flex justify-content-end">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setShowSelectCadence(true);
              }}
              size="sm"
            >
              Add To Cadence{" "}
            </Button>
          </div>
          <div className="mt-6">
            {leadCadenceStore.data.map((each, index) => {
              return <CadenceLeadListCard data={each} key={index} unsubscribe={unsubscribeCadence} />;
            })}
            {leadCadenceStore.count > 0 && (
              <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-15px pt-7px">
                <p className="mt-5px mb-0 font-size-14px">
                  Showing {(page - 1) * limit + 1}-{leadCadenceStore.count > page * limit ? page * limit : leadCadenceStore.count} of {leadCadenceStore.count}
                </p>
                {CustomPagination(page, setPage, Math.ceil(leadCadenceStore.count / limit), () => {}, limit, setLimit, leadCadenceStore.count)}
              </div>
            )}
          </div>
        </>
      )}
      <CustomModal modalProps={AddCadenceModalProps}>
        <Form.Group id="sequence-component-add-cadence-form-cadence-name">
          <Form.Label aria-label="cadence">Cadence</Form.Label>
          <SimpleDropdown showSearch={true} value={selectedCadence} label={"Select Cadence"} options={options} handleSelect={setSelectedCadence} />
        </Form.Group>
      </CustomModal>
    </div>
  );
}
