import React, { useEffect, useState } from "react";
import AdvancedAccordionComponent from "../../../../../../components/accordion/advanced-accordion-component";
import { Form } from "react-bootstrap";
import { whatsappButtonTypeOptions } from "../constants";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import _ from "lodash";

export default function WhatsappAddTemplateButton(props) {
    const [activeKey, setActiveKey] = useState("1");

    const [buttonType, setButtonType] = useState("none");

    // quick reply
    const [quickReplyText1, setQuickReplyText1] = useState("");
    const [quickReplyText2, setQuickReplyText2] = useState("");
    const [quickReplyText3, setQuickReplyText3] = useState("");

    // buttons
    const [urlButtonText, setUrlButtonText] = useState("");
    const [urlButtonUrl, setUrlButtonUrl] = useState("");
    const [phoneNumberButtonText, setPhoneNumberButtonText] = useState("");
    const [phoneNumberButtonPhoneNumber, setPhoneNumberButtonPhoneNumber] = useState("");

    useEffect(() => {
        if (_.isEmpty(props.buttons)) {
            setButtonType("none");
            setQuickReplyText1("");
            setQuickReplyText2("");
            setQuickReplyText3("");
            setUrlButtonText("");
            setUrlButtonUrl("");
            setPhoneNumberButtonText("");
            setPhoneNumberButtonPhoneNumber("");
        }
        else {
            const { buttons } = props.buttons;
            if (buttons.length === 0) {
                setQuickReplyText1("");
                setQuickReplyText2("");
                setQuickReplyText3("");
                setUrlButtonText("");
                setUrlButtonUrl("");
                setPhoneNumberButtonText("");
                setPhoneNumberButtonPhoneNumber("");
            }
            else {
                const type = buttons[0].type;
                if (type === "QUICK_REPLY") setButtonType("quick");
                else setButtonType("cta");

                if (type === "QUICK_REPLY") {
                    if (buttons[0]) setQuickReplyText1(buttons[0].text);
                    if (buttons[1]) setQuickReplyText2(buttons[1].text);
                    if (buttons[2]) setQuickReplyText3(buttons[2].text);
                }

                for (const button of buttons) {
                    if (button.type === "URL") {
                        setUrlButtonText(button.text);
                        setUrlButtonUrl(button.url);
                    }
                    else if (button.type === "PHONE_NUMBER") {
                        setPhoneNumberButtonText(button.text);
                        setPhoneNumberButtonPhoneNumber(button.phone_number);
                    }
                }
            }
        }
    }, [props.buttons]);

    const handleButtonTypeChange = (type) => {
        if (type === "none") {
            props.setButtons({})
        }
        else if (type === "quick") {
            props.setButtons({
                type: "BUTTONS",
                buttons: [
                    {
                        "type": "QUICK_REPLY",
                        "text": ""
                    },
                    {
                        "type": "QUICK_REPLY",
                        "text": ""
                    },
                    {
                        "type": "QUICK_REPLY",
                        "text": ""
                    }
                ]
            })
        }
        else if (type === "cta") {
            props.setButtons({
                type: "BUTTONS",
                buttons: [
                    {
                        "type": "PHONE_NUMBER",
                        "text": "Call",
                        "phone_number": ""
                    },
                    {
                        "type": "URL",
                        "text": "Website",
                        "url": ""
                    }
                ]
            })
        }

    }

    const handleQuickButtonTextChange = (key, value) => {
        props.setButtons({
            type: "BUTTONS",
            buttons: props.buttons.buttons.map((button, index) => {
                if (index === key) {
                    return {
                        ...button,
                        text: value
                    }
                }
                return button;
            })
        })
    }

    const handlePhoneAndUrlChange = (type, key, value) => {
        const final = {
            type: "BUTTONS",
            buttons: props.buttons.buttons.map((button) => {
                if (type === button.type) {
                    return {
                        ...button,
                        [key]: value
                    }
                }
                else if (type === button.type) {
                    return {
                        ...button,
                        [key]: value
                    }
                }
                return button;
            })
        };
        props.setButtons(final)
    }

    return <AdvancedAccordionComponent
        title="Button (Optional)"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        tooltipMessage={"Add a short line of text to the bottom of your message template."}
    >
        <div className="row">
            <Form.Group className="w-50">
                <Form.Label>Button Type</Form.Label>
                <SimpleDropdown
                    value={buttonType}
                    options={whatsappButtonTypeOptions}
                    label={"Select Button Type"}
                    handleSelect={(value) => handleButtonTypeChange(value.value)}
                />
            </Form.Group>
        </div>
        {buttonType === "cta" && <div className="row">
            <div className="row">
                <Form.Group className="col-md-6">
                    <Form.Label>Button Text</Form.Label>
                    <Form.Control type="text" maxLength={25} value={phoneNumberButtonText} onChange={e => handlePhoneAndUrlChange("PHONE_NUMBER", "text", e.target.value)} />
                </Form.Group>
                <Form.Group className="col-md-6">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control placeholder="+1" type="text" maxLength={25} value={phoneNumberButtonPhoneNumber} onChange={e => handlePhoneAndUrlChange("PHONE_NUMBER", "phone_number", e.target.value)} />
                </Form.Group>
            </div>

            <div className="row">
                <Form.Group className="col-md-6">
                    <Form.Label>Button Text</Form.Label>
                    <Form.Control type="text" maxLength={25} value={urlButtonText} onChange={e => handlePhoneAndUrlChange("URL", "text", e.target.value)} />
                </Form.Group>
                <Form.Group className="col-md-6">
                    <Form.Label>Website URL</Form.Label>
                    <Form.Control placeholder="http://" type="text" maxLength={2000} value={urlButtonUrl} onChange={e => handlePhoneAndUrlChange("URL", "url", e.target.value)} />
                </Form.Group>
            </div>
        </div>}
        {buttonType === "quick" && <div className="row">
            <Form.Group className="col-md-4">
                <Form.Label>Button 1</Form.Label>
                <Form.Control type="text" maxLength={25} value={quickReplyText1} onChange={e => handleQuickButtonTextChange(0, e.target.value)} />
            </Form.Group>
            <Form.Group className="col-md-4">
                <Form.Label>Button 2 (Optional)</Form.Label>
                <Form.Control type="text" maxLength={25} value={quickReplyText2} onChange={e => handleQuickButtonTextChange(1, e.target.value)} />
            </Form.Group>
            <Form.Group className="col-md-4">
                <Form.Label>Button 3 (Optional)</Form.Label>
                <Form.Control type="text" maxLength={25} value={quickReplyText3} onChange={e => handleQuickButtonTextChange(2, e.target.value)} />
            </Form.Group>
        </div>}
    </AdvancedAccordionComponent>
}