import React, { useState } from "react";
import moment from "moment";
import { Dropdown, OverlayTrigger, Popover, Button } from "react-bootstrap";
import { GreenGradientButton } from "../../../../components/button";

export default function SnoozeDropdown(props) {
  const [show, setShow] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [customTime, setCustomTime] = useState(moment().format("yyyy-MM-DDT00:00"));

  const handleOptionSelection = (type) => {
    if (type === "tomorrow") {
      setIsCustom(false);
      setShow(false);
      const startDate = moment()
        .startOf("day")
        .add(1, "day")
        .format();
      props.setSnoozeTime(startDate);
    } else if (type === "nextWeek") {
      setIsCustom(false);
      setShow(false);
      const startDate = moment()
        .startOf("day")
        .add(1, "week")
        .format();
      props.setSnoozeTime(startDate);
    }
  };

  const handleClose = () => {
    document.body.click();
  };

  const handleSave = () => {
    document.body.click();
    setShow(false);
    const startDate = moment(customTime).format();
    props.setSnoozeTime(startDate);
  };

  const popoverForm = () => {
    return (
      <Popover className="w-310px w-310px-mx" size="md" id="popover-basic" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">{"Custom Snooze Time"}</Popover.Title>
        <Popover.Content>
          <input type={"datetime-local"} value={customTime} onChange={(e) => setCustomTime(e.target.value)} className="form-control" />
          <div className="d-flex flex-row justify-content-center gap-15px mt-15px">
            <Button variant="secondary" onClick={(e) => handleClose(e)} size="sm">
              Cancel
            </Button>
            <GreenGradientButton onClick={(e) => handleSave(e)} size="sm">
              Save
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <Dropdown
      show={show}
      onToggle={() => {
        if (!isCustom) setShow(!show);
      }}
      className="dropdown-inline"
    >
      <Dropdown.Toggle className={`btn btn-sm dropdown-toggle btn-secondary custom-secondory-cta`}>
        <i className="bi bi-alarm"></i>
        Snooze
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="scroll navi navi-hover overflow-y-auto h-500px-mx w-200px position-relative">
          <div className="dropdown-item cursor-pointer d-flex flex-stack" onClick={() => handleOptionSelection("tomorrow")}>
            <div>Tomorrow</div>
          </div>
          <div className="dropdown-item cursor-pointer d-flex flex-stack" onClick={() => handleOptionSelection("nextWeek")}>
            <div>Next Week</div>
          </div>
          <OverlayTrigger rootClose={true} onExit={() => {}} onEnter={() => setIsCustom(true)} trigger="click" id="popoverbutton" placement="left" overlay={popoverForm()}>
            <div className="dropdown-item cursor-pointer d-flex flex-stack">
              <div>Custom</div>
            </div>
          </OverlayTrigger>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
