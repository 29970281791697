const credentials = {
    live: {
        stripe: "pk_live_51MGHiYSH9OgLqiNCK0betEW0IcW96a0stbyRkmtpML0A1ibQMfvHmqka37Ln8zfPjMVv9nOlSwiMunxz1QBcfRpX00xrxoPk2v",
        razorpay: "rzp_live_TDoL2CEjimRi3q"
    },
    test: {
        stripe: "pk_test_51MGHiYSH9OgLqiNCnpvqcIFeE04WSbUr4514bCJygNAuqrSfSjtvBthmAUUVMBF9LNuzzxanGzbqiZWFNf2f1kG600fpXeM5I6",
        razorpay: "rzp_test_1l3jmm2jT77bql"
    }
}

export const getVendorKey = (vendor) => {
    const mode = window.location.host === "app.salessimplify.com" ? "live" : "test";
    return credentials[mode][vendor];
}