import React, { useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { CustomScrollableModal } from "../../../components/modal";
import { dispatchSnackbarError } from "../../../utils/toaster";
import { useSelector } from "react-redux";

export default function MarkAsLostModel(props) {
  const [selectedLostReason, setSelectedLostReason] = useState("");
  const [selectedLostReasonId, setSelectedLostReasonId] = useState("");
  const [lossDealComment, setLossDealComment] = useState("");

  const lostReasonStore = useSelector((store) => store.DealPipeline.lostReasons);

  const onLossDealClose = () => {
    props.setShow(false);
    setSelectedLostReasonId("");
    setSelectedLostReason("");
    setLossDealComment("");
  };
  const onLossDealSave = () => {
    if (!selectedLostReasonId) {
      dispatchSnackbarError("Please select lost reason", "error");
    } else if (selectedLostReasonId !== "") {
      props.callDealOperationApi({
        data: {
          dealId: props.selectedDeal._id,
          comment: lossDealComment,
          reasonId: selectedLostReasonId,
        },
        type: "mark-as-lost",
        pipelineId: props.selectedPipelineId,
      });
      props.setShow(false);
      setLossDealComment("");
      setSelectedLostReasonId("");
    }
  };
  const onLostReasonChange = (reason) => {
    setSelectedLostReason(reason.reason);
    setSelectedLostReasonId(reason._id);
  };

  const markAsLostModelProps = {
    show: props.show,
    handleClose: () => onLossDealClose(),
    title: "Mark As Lost",
    showButtons: true,
    buttonText: "Mark As Lost",
    handleAccept: () => onLossDealSave(),
  };

  return (
    <CustomScrollableModal modalProps={markAsLostModelProps}>
      <>
        {!props.isMultiDeal && (
          <div className="loss-deal-item">
            <div className="mb-10px text-capitalize">
              <div className="fw-500 font-size-15px text-26292c">{props.selectedDeal && props.selectedDeal.title}</div>
              <div className="font-size-13px text-747678">{props.selectedDeal && props.selectedDeal.company && props.selectedDeal.company.name}</div>
              <div className="font-size-13px text-747678">{props.selectedDeal && props.selectedDeal.people && props.selectedDeal.people.name}</div>
            </div>
            <div className="fw-500 font-size-13px p-2px text-747678 border-top-width-1px border-top-style-dotted border-top-color-eeeeee">
              <i className="bi bi-person-fill user-icon mr-4px text-747678" title={Array.isArray(props.selectedDeal.users) && props.selectedDeal.users.filter((a) => a).map((user) => (user ? user.name : ""))}></i>
              {props.selectedDeal.dealCurrency} {props.selectedDeal.dealValue}
            </div>
          </div>
        )}
        <div>
          <Form.Group id="deals-components-mark-as-lost-model-select-lost-reason" required>
            <Form.Label aria-label="lost-reason">Lost Reason*</Form.Label>
            <Dropdown className="dropdown-inline mt-1 w-100">
              <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                {selectedLostReason}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu w-100-mn ">
                {lostReasonStore &&
                  Array.isArray(lostReasonStore) &&
                  lostReasonStore.map((lostReason) => (
                    <Dropdown.Item className="dropdown-hover" onClick={() => onLostReasonChange(lostReason)}>
                      {lostReason.reason}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Comments
            </label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setLossDealComment(e.target.value)}></textarea>
          </div>
        </div>
      </>
    </CustomScrollableModal>
  );
}
