//packages
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Spinner, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

//components
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import { BlueGradientButton } from "../../../components/button";
import ContentWrapper from "../../Wrapper";

//forms
import AddSalesForecastForm from "../components/forms/addSalesForecast";

//actions
import * as salesForecastActions from "../store/actions/sales_forecast.actions";
import * as LeadActions from "../../leads/store/actions/actions";
import * as CompanyActions from "../../companies/store/actions/actions";
import * as ProductActions from "../../products/store/actions/actions";
import * as insightActions from "../../insights/actions";
import * as TemplateActions from "../../campaign/store/actions/campaign_templates_actions";

//graphs
import OpenDealGraph from "./graphs/open-deal.gragh";
import OpenAndWonDealGraph from "./graphs/open-won-deal.graph";
import OpenWonAndTargetGraph from "./graphs/open-won-target.graph";

//styles
import "../styles.css";
import "./styles.scss";
import SalesForecastFilter from "./sales-forecast-filter";
import SalesForecastCard from "./card";
import SalesForecastSummary from "./summary";
import SFDetailsCard from "./details-card";
import { DeleteModal } from "../../../components/modal";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import { DashboardSVG, SalesForcastSVG, SalesTargetSVG } from "../../../components/custom-svgs/sub-header-svg";
import SubscriptionPlanModal from "../../Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import { UpgradePlanPage } from "../../../components/empty_page";

export default function SalesForecast(props) {
  const [showPlans, setShowPlans] = useState(false);
  const [openSalesForecastModal, setOpenSalesForecastModal] = useState(false);
  const [isSalesForecastEdit, setIsSalesForecastEdit] = useState(false);
  const [userRoleData, setUserRoleData] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedSalesForecast, setSelectedSalesForecast] = useState({});
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [pipelineStageOptions, setPipelineStageOptions] = useState({});
  const [filterData, setFilterData] = useState({ type: "all", id: "" });
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [limit] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [graphType, setGraphType] = useState("nonComulative");
  const [lastDeleted, setLastDeleted] = useState("");

  //variables
  const links = [
    { name: "Dashboard", to: "/insights/dashboard", icon: "bi bi-reception-4", svg: DashboardSVG },
    { name: "Sales Target", to: "/insights/sales-target", icon: "bi bi-geo", svg: SalesTargetSVG },
    { name: "Forecast", to: "/insights/forecast", icon: "bi bi-graph-up-arrow", svg: SalesForcastSVG },
  ];
  const [tabName] = useState("Forecast");

  const dispatch = useDispatch();

  //store
  const listOfPipelines = useSelector((store) => store.insight.pipelines);
  const userRole = useSelector((store) => store.insight.userRole);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const salesForecastListStore = useSelector((store) => store.InsightReducer.salesForecast.list);
  const salesForecastGraphStore = useSelector((store) => store.InsightReducer.salesForecast.graph);

  useEffect(() => {
    dispatch(
      salesForecastActions.getListOfSalesForecastRequest({
        limit: limit,
        pageNo: 1,
        ...filterData,
      }),
    );
  }, [dispatch, limit, filterData]);

  useEffect(() => {
    dispatch(insightActions.getListOfPipelinesRequest());
    //common api Calls
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!salesForecastListStore.loading) {
      if (salesForecastListStore.data.length > 0 && _.isEmpty(selectedSalesForecast) && salesForecastListStore.data[0]._id !== lastDeleted) {
        dispatch(salesForecastActions.getSalesForecastGraphRequest(salesForecastListStore.data[0]._id));
        setSelectedSalesForecast(salesForecastListStore.data[0]);
      }
      if (salesForecastListStore.data.length > 0 && !_.isEmpty(selectedSalesForecast)) {
        const _find = salesForecastListStore.data.find((each) => each._id === selectedSalesForecast._id);
        if (_find && lastDeleted !== _find.id) {
          dispatch(salesForecastActions.getSalesForecastGraphRequest(_find._id));
          setSelectedSalesForecast(_find);
        }
      }
    }
  }, [dispatch, salesForecastListStore, selectedSalesForecast, lastDeleted]);

  useEffect(() => {
    setUserRoleData(userRole.list);
    if (userRole.list.users) {
      setUserOptions(
        userRole.list.users.map((each) => {
          return { label: each.name, value: each.id };
        }),
      );
    }
    if (userRole.list.teams) {
      setTeamOptions(
        userRole.list.teams.map((each) => {
          return { label: each.name, value: each.id };
        }),
      );
    }
  }, [userRole.list]);

  useEffect(() => {
    const options2 = [{ value: "", label: "All Pipelines" }];
    const pipelineStageOptions = {};
    listOfPipelines.list.forEach((eachPipeline) => {
      options2.push({ value: eachPipeline._id, label: eachPipeline.name });
      const options = eachPipeline.stages.map((each) => ({ value: each._id, label: each.name }));
      pipelineStageOptions[eachPipeline._id] = [{ value: "", label: "All Stages" }, ...options];
    });
    setPipelineOptions(options2);
    setPipelineStageOptions(pipelineStageOptions);
  }, [listOfPipelines.list]);

  const onAddSalesForecastClick = () => {
    setOpenSalesForecastModal(true);
    setIsSalesForecastEdit(false);
  };
  const handleCloseSalesForecastModel = () => {
    setOpenSalesForecastModal(false);
    setIsSalesForecastEdit(false);
  };
  const handleSaveSalesForecast = (data) => {
    if (isSalesForecastEdit) {
      dispatch(salesForecastActions.editSalesForecastRequest({ data: { ...data, _id: selectedSalesForecast._id }, loadingData: filterData }));
    } else {
      dispatch(salesForecastActions.createSalesForecastRequest({ data: data, loadingData: filterData }));
    }
    // setSelectedSalesForecast({})
    setOpenSalesForecastModal(false);
    setIsSalesForecastEdit(false);
  };

  const onSalesForecastClick = (data) => {
    if (data._id === selectedSalesForecast._id) {
      return;
    }
    dispatch(salesForecastActions.getSalesForecastGraphRequest(data._id));
    setSelectedSalesForecast(data);
  };

  const handleEditClick = (each) => {
    setOpenSalesForecastModal(true);
    setIsSalesForecastEdit(true);
    setSelectedSalesForecast(each);
  };

  const handleDeleteClick = (each) => {
    setSelectedSalesForecast(each);
    setShowDeleteModel(true);
  };

  const onLoadMoreClick = () => {
    setPageNo(pageNo + 1);
    dispatch(
      salesForecastActions.getListOfSalesForecastLoadMoreRequest({
        limit: limit,
        pageNo: pageNo + 1,
        ...filterData,
      }),
    );
  };

  const onAccepetDelete = () => {
    dispatch(salesForecastActions.deleteSalesForecastRequest({ id: selectedSalesForecast._id }));
    setShowDeleteModel(false);
    setLastDeleted(selectedSalesForecast._id);
    setSelectedSalesForecast({});
  };
  const deleteLeadBotModelProps = {
    show: showDeleteModel,
    title: "Delete Sales Forecast ",
    handleClose: () => setShowDeleteModel(false),
    handleAccept: onAccepetDelete,
  };

  const showEmptyScreen = () => {
    if (filterData.type === "all" && salesForecastListStore.data.length === 0) {
      return true;
    } else return false;
  };

  if (accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.SALES_FORCASTING) {
    return (
      <ContentWrapper
        subHeader={
          <CustomSubHeader>
            <SubheaderNav links={links} active={tabName} />
          </CustomSubHeader>
        }
      >
        <UpgradePlanPage
          isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
          userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          onUpgradeClick={() => setShowPlans(true)}
          type={"Sales Forecast"}
          planName={"free plan"}
        />
        {showPlans && <SubscriptionPlanModal page={""} showPlans={showPlans} setShowPlans={setShowPlans} />}
      </ContentWrapper>
    );
  }
  return (
    <div className="sales-forecast-container">
      {salesForecastListStore.loading ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-195px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="d-flex flex-row flex-stack w-100">
                <div>
                  <SubheaderNav links={links} active={tabName} />
                </div>
                <div className="d-flex flex-stack gap-2">
                  <BlueGradientButton onClick={() => onAddSalesForecastClick()} variant="success" size="sm">
                    + Sales Forecast
                  </BlueGradientButton>
                </div>
              </div>
            </CustomSubHeader>
          }
        >
          {showEmptyScreen() ? (
            <CustomEmptyPage page="salesForecast" setShowAdd={onAddSalesForecastClick} />
          ) : (
            <div className="d-flex justify-content-between h-vh-163px">
              <Card className="sale-forecast-list-wrapper">
                <Card.Header>
                  <span> Sales Forecast List </span>
                  <SalesForecastFilter
                    userOptions={userOptions}
                    teamOptions={teamOptions}
                    filterData={filterData}
                    setFilterData={(data) => {
                      setFilterData(data);
                      setPageNo(1);
                    }}
                    userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
                  />
                </Card.Header>
                <Card.Body className="h-100 w-400px overflow-scroll">
                  {salesForecastListStore.data.length === 0 ? (
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
                        <div className="card-px text-center py-5 my-5">
                          <h2 className="fs-1 fw-bolder mb-5">No Data</h2>
                          <p className="text-gray-400 fs-5 fw-bold mb-8">
                            {"No sales forecast added for selected filter"} <br />
                            {"Click on below button to add one"}
                          </p>
                          <BlueGradientButton onClick={onAddSalesForecastClick}>Add Sales Forecast</BlueGradientButton>
                        </div>
                      </Card.Body>
                    </div>
                  ) : (
                    <div>
                      {salesForecastListStore.data &&
                        salesForecastListStore.data.map((each, index) => {
                          return (
                            <SalesForecastCard
                              data={each}
                              key={index}
                              userOptions={userOptions}
                              teamOptions={teamOptions}
                              selectedId={selectedSalesForecast._id}
                              userId={userProfile.data ? userProfile.data.userId : ""}
                              onSalesForecastClick={onSalesForecastClick}
                              handleEditClick={handleEditClick}
                              handleDeleteClick={handleDeleteClick}
                            />
                          );
                        })}
                      {salesForecastListStore.count > salesForecastListStore.data.length ? (
                        <div className="d-flex justify-content-center mt-3">
                          <span className="btn btn-secondary py-7px px-20px h-34px" onClick={() => onLoadMoreClick()}>
                            {salesForecastListStore.loadMoreLoading && <Spinner animation="border" variant="primary" className="mr-10px w-16px h-16px"></Spinner>}
                            Load More
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )}
                </Card.Body>
              </Card>
              <div className="h-100 overflow-scroll w-100-420px">
                {salesForecastListStore.data.length === 0 ? (
                  <div className="card h-100 d-flex justify-content-center align-items-center">
                    <p className="text-gray-400 fs-5 fw-bold mb-8"> Sales Forecast Not Selected</p>
                  </div>
                ) : (
                  <>
                    {salesForecastGraphStore.loading ? (
                      <div className="w-100 d-flex justify-content-center align-items-center h-100">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <>
                        <SFDetailsCard
                          userOptions={userOptions}
                          teamOptions={teamOptions}
                          selectedSalesForecast={selectedSalesForecast}
                          pipelineOptions={pipelineOptions}
                          pipelineStageOptions={pipelineStageOptions}
                          currency={userProfile.data && userProfile.data.currency ? userProfile.data.currency : "USD"}
                          onEditClick={handleEditClick}
                          onDeleteClick={handleDeleteClick}
                        />
                        {selectedSalesForecast.forecastType === "openDeals" && (
                          <div className="card w-100 p-3 mb-3 h-500px">
                            <OpenDealGraph
                              chartId={"Chatr1"}
                              currency={userProfile.data && userProfile.data.currency ? userProfile.data.currency : "USD"}
                              data={salesForecastGraphStore[graphType]}
                              interval={selectedSalesForecast.interval}
                            />
                            <GraphTypeButtons graphType={graphType} setGraphType={setGraphType} />
                          </div>
                        )}
                        {selectedSalesForecast.forecastType === "openAndWonDeals" && (
                          <div className="card w-100 p-3 mb-3 h-500px">
                            <OpenAndWonDealGraph
                              chartId={"Chatr2"}
                              currency={userProfile.data && userProfile.data.currency ? userProfile.data.currency : "USD"}
                              data={salesForecastGraphStore[graphType]}
                              interval={selectedSalesForecast.interval}
                            />
                            <GraphTypeButtons graphType={graphType} setGraphType={setGraphType} />
                          </div>
                        )}
                        {selectedSalesForecast.forecastType === "openAndWonDealsWithSalesTarget" && (
                          <div className="card w-100 p-3 mb-3 h-500px">
                            <OpenWonAndTargetGraph
                              chartId={"Chatr3"}
                              currency={userProfile.data && userProfile.data.currency ? userProfile.data.currency : "USD"}
                              data={salesForecastGraphStore[graphType]}
                              interval={selectedSalesForecast.interval}
                            />
                            <GraphTypeButtons graphType={graphType} setGraphType={setGraphType} />
                          </div>
                        )}
                        <SalesForecastSummary graphType={graphType} data={salesForecastGraphStore[graphType]} currency={salesForecastGraphStore.currency} selectedSalesForecast={selectedSalesForecast} />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {/**Add/Edit Goal */}
          <AddSalesForecastForm
            show={openSalesForecastModal}
            isSalesForecastEdit={isSalesForecastEdit}
            selectedSalesForecast={selectedSalesForecast}
            handleClose={handleCloseSalesForecastModel}
            handleSave={handleSaveSalesForecast}
            userRoleData={userRoleData}
            userOptions={userOptions}
            teamOptions={teamOptions}
            pipelineOptions={pipelineOptions}
            pipelineStageOptions={pipelineStageOptions}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
          />
          <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete sales forecast? After deleting sales forecast form you will not able to recover it.</DeleteModal>
        </ContentWrapper>
      )}
    </div>
  );
}

export const GraphTypeButtons = ({ graphType, setGraphType }) => (
  <Form.Group key={`inline-radio`} className="mb-3 d-flex align-items-center justify-content-center">
    <label className="form-label fw-bold m-0">Forecast View:</label>
    <Form.Check inline className="mx-3" label="Non-Cumulative" checked={graphType === "nonComulative"} name="nonComulative" type={"radio"} id={`inline-radio-1`} onClick={(e) => setGraphType("nonComulative")} />
    <Form.Check inline className="mx-3" label="Cumulative" checked={graphType === "comulative"} name="comulative" type={"radio"} id={`inline-radio-2`} onClick={(e) => setGraphType("comulative")} />
  </Form.Group>
);
