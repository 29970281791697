// import { IStage } from 'modules/boards/types';
// import { __ } from 'modules/common/utils';
import Stage from "./stage";
import * as React from "react";
import { TableView, HeadRow } from "../styled";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        { name: "test1", _id: "12we3", stageId: "12345" },
        { name: "test2", _id: "111we3", stageId: "12345" },
        { name: "test1", _id: "122we3", stageId: "44062" },
      ],
    };
  }
  render() {
    const {
      stages,
      // queryParams, pipelineId
    } = this.props;

    return (
      <TableView>
        <HeadRow>
          <span>{"Stage"}</span>
          <span>{"Stayed"}</span>
          <span>{"In progress"}</span>
          <span>{"Lost"}</span>
        </HeadRow>
        {stages.map((stage) => {
          const hasMore = stage.initialDealsTotalCount > this.state.items.length;
          return (
            <Stage
              hasMore={hasMore}
              // key={stage._id}
              stage={stage}
              deals={this.state.items}
              // queryParams={queryParams}
              // pipelineId={pipelineId}
            />
          );
        })}
      </TableView>
    );
  }
}

export default Table;
