import React, { useState } from "react";
import _ from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EditIconSVG, TemplateIconSVG } from "../../../../../components/custom-svgs";
import TemplateManager from "../../../../../components/templates";
import SendTestEmailPopOver from "../../../components/send-test-email-popover";

export default function CampaignEmailEditor(props) {
  const [showTemplateSelector, setShowTemplateSelectore] = useState(false);

  const onChooseClick = (data) => {
    props.setIsTemplate(true);
    props.setTemplateId(data._id);
    props.setTemplate(data.content);
    setShowTemplateSelectore(false);
    if (!_.isEmpty(data.defaultVariableValues)) {
      props.setDefaultVariableValues({ ...props.defaultVariableValues, ...data.defaultVariableValues });
    }
  };

  const cancelSelectTemplate = () => {
    props.setIsTemplate(false);
    props.setTemplateId(null);
    props.setTemplate("");
    setShowTemplateSelectore(false);
  };

  const handleEmailBuilderEdit = () => {
    setShowTemplateSelectore(true);
  };

  return (
    <>
      <div
        className="message-step3-inner-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          setShowTemplateSelectore(true);
        }}
      >
        <div className="d-flex flex-stack w-100">
          <div className="d-flex gap-4">
            <div className="message-icon-wrapper cursor-pointer">{TemplateIconSVG}</div>
            <div className="message-text-wrapper cursor-pointer">
              <div className="message-head">Select Message</div>
              <div className="message-content">Select ready to use template.</div>
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()}> {props.templateId && <SendTestEmailPopOver config={props.config} templateId={props.templateId} attachments={props.attachments} />}</div>
        </div>
        {!_.isEmpty(props.template) && (
          <div className="position-relative border border-1 rounded-1 mt-5 mb-4 bg-white h-500px">
            <iframe title="template" srcDoc={props.template} width="100%" height="500px" frameBorder="0" scrolling="0" />
            <OverlayTrigger overlay={<Tooltip>Edit Content</Tooltip>}>
              <div
                className="position-absolute top-0 right-0 p-2 bg-light-white bg-hover-light-light border rounded-1 m-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEmailBuilderEdit();
                }}
              >
                {EditIconSVG}
              </div>
            </OverlayTrigger>
          </div>
        )}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <TemplateManager show={showTemplateSelector} channel={"email"} category={"Marketing"} showChoose={true} onCloseClick={(data) => onChooseClick(data)} handleClose={cancelSelectTemplate} />
      </div>
    </>
  );
}
