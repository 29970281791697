export const GET_PIPELINE_REQUEST = "GET_PIPELINE_REQUEST";
export const GET_PIPELINE_SUCCESS = "GET_PIPELINE_SUCCESS";
export const GET_PIPELINE_ERROR = "GET_PIPELINE_ERROR";

export const ADD_PIPELINE_REQUEST = "ADD_PIPELINE_REQUEST";
export const ADD_PIPELINE_SUCCESS = "ADD_PIPELINE_SUCCESS";
export const ADD_PIPELINE_ERROR = "ADD_PIPELINE_ERROR";

export const GET_STAGES_REQUEST = "GET_STAGES_REQUEST";
export const GET_STAGES_SUCCESS = "GET_STAGES_SUCCESS";
export const GET_STAGES_ERROR = "GET_STAGES_ERROR";
export const GET_STAGES_REQUEST_AFTER = "GET_STAGES_REQUEST_AFTER";

export const ADD_DEAL_REQUEST = "ADD_DEAL_REQUEST";
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS";
export const ADD_DEAL_ERROR = "ADD_DEAL_ERROR";

export const UPDATE_PIPELINE_REQUEST = "UPDATE_PIPELINE_REQUEST";
export const UPDATE_PIPELINE_SUCCESS = "UPDATE_PIPELINE_SUCCESS";
export const UPDATE_PIPELINE_ERROR = "UPDATE_PIPELINE_ERROR";

export const DELETE_PIPELINE_REQUEST = "DELETE_PIPELINE_REQUEST";
export const DELETE_PIPELINE_SUCCESS = "DELETE_PIPELINE_SUCCESS";
export const DELETE_PIPELINE_ERROR = "DELETE_PIPELINE_ERROR";

export const GET_DEAL_REQUEST = "GET_DEAL_REQUEST";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";
export const GET_DEAL_ERROR = "GET_DEAL_ERROR";
export const GET_DEAL_REQUEST_AFTER = "GET_DEAL_REQUEST_AFTER";

export const MOVE_DEALINSTAGES_REQUEST = "MOVE_DEALINSTAGES_REQUEST";
export const MOVE_DEALINSTAGE_SUCCESS = "MOVE_DEALINSTAGE_SUCCESS";
export const MOVE_DEALINSTAGES_ERROR = "MOVE_DEALINSTAGES_ERROR";

export const DEAL_OPERATION_REQUEST = "DEAL_OPERATION_REQUEST";
export const DEAL_OPERATION_SUCCESS = "DEAL_OPERATION_SUCCESS";
export const DEAL_OPERATION_ERROR = "DEAL_OPERATION_ERROR";

export const ADD_STAGEINPIPELINE_REQUEST = "ADD_STAGEINPIPELINE_REQUEST";
export const ADD_SATGEINPIPELINE_SUCCESS = "ADD_SATGEINPIPELINE_SUCCESS";
export const ADD_STAGEINPIPELINE_ERROR = "ADD_STAGEINPIPELINE_ERROR";

export const UPDATE_STAGEINPIPELINE_REQUEST = "UPDATE_STAGEINPIPELINE_REQUEST";
export const UPDATE_SATGEINPIPELINE_SUCCESS = "UPDATE_SATGEINPIPELINE_SUCCESS";
export const UPDATE_STAGEINPIPELINE_ERROR = "UPDATE_STAGEINPIPELINE_ERROR";

export const UPDATE_STAGEORDERINPIPELINE_REQUEST = "UPDATE_STAGEORDERINPIPELINE_REQUEST";
export const UPDATE_STAGEORDERINPIPELINE_SUCCESS = "UPDATE_STAGEORDERINPIPELINE_SUCCESS";
export const UPDATE_STAGEORDERINPIPELINE_ERROR = "UPDATE_STAGEORDERINPIPELINE_ERROR";

export const DELETE_PIPELINESTAGE_REQUEST = "DELETE_PIPELINESTAGE_REQUEST";
export const DELETE_PIPELINESTAGE_SUCCESS = "DELETE_PIPELINESTAGE_SUCCESS";
export const DELETE_PIPELINESTAGE_ERROR = "DELETE_PIPELINESTAGE_ERROR";

export const GET_LOSTREASONS_REQUEST = "GET_LOSTREASONS_REQUEST";
export const GET_LOSTREASONS_SUCCESS = "GET_LOSTREASONS_SUCCESS";
export const GET_LOSTREASONS_ERROR = "GET_LOSTREASONS_ERROR";

export const GET_SAVEDFILTERS_REQUEST = "GET_SAVEDFILTERS_REQUEST";
export const GET_SAVEDFILTERS_SUCCESS = "GET_SAVEDFILTERS_SUCCESS";
export const GET_SAVEDFILTERS_ERROR = "GET_SAVEDFILTERS_ERROR";

export const ADD_SAVEDFILTERS_REQUEST = "ADD_SAVEDFILTERS_REQUEST";
export const ADD_SAVEDFILTERS_SUCCESS = "ADD_SAVEDFILTERS_SUCCESS";
export const ADD_SAVEDFILTERS_ERROR = "ADD_SAVEDFILTERS_ERROR";

export const EDIT_SAVEDFILTERS_REQUEST = "EDIT_SAVEDFILTERS_REQUEST";
export const EDIT_SAVEDFILTERS_SUCCESS = "EDIT_SAVEDFILTERS_SUCCESS";
export const EDIT_SAVEDFILTERS_ERROR = "EDIT_SAVEDFILTERS_ERROR";

export const DELETE_SAVEDFILTERS_REQUEST = "DELETE_SAVEDFILTERS_REQUEST";
export const DELETE_SAVEDFILTERS_SUCCESS = "DELETE_SAVEDFILTERS_SUCCESS";
export const DELETE_SAVEDFILTERS_ERROR = "DELETE_SAVEDFILTERS_ERROR";

export const FAVORITE_SAVEDFILTERS_REQUEST = "FAVORITE_SAVEDFILTERS_REQUEST";
export const FAVORITE_SAVEDFILTERS_SUCCESS = "FAVORITE_SAVEDFILTERS_SUCCESS";
export const FAVORITE_SAVEDFILTERS_ERROR = "FAVORITE_SAVEDFILTERS_ERROR";

export const GET_DEALFIELDS_REQUEST = "GET_DEALFIELDS_REQUEST";
export const GET_DEALFIELDS_SUCCESS = "GET_DEALFIELDS_SUCCESS";
export const GET_DEALFIELDS_ERROR = "GET_DEALFIELDS_ERROR";

export const APPLYFILTER = "APPLYFILTER";
export const CLEARFILTER = "CLEARFILTER";

export const GET_DEALPOPUPDETAILS_REQUEST = "GET_DEALPOPUPDETAILS_REQUEST";
export const GET_DEALPOPUPDETAILS_SUCCESS = "GET_DEALPOPUPDETAILS_SUCCESS";
export const GET_DEALPOPUPDETAILS_ERROR = "GET_DEALPOPUPDETAILS_ERROR";
export const GET_DEALPOPUPDETAILS_REQUEST_AFTER = "GET_DEALPOPUPDETAILS_REQUEST_AFTER";

export const UPDATE_DEALPOPUPDETAILS_REQUEST = "UPDATE_DEALPOPUPDETAILS_REQUEST";
export const UPDATE_DEALPOPUPDETAILS_SUCCESS = "UPDATE_DEALPOPUPDETAILS_SUCCESS";
export const UPDATE_DEALPOPUPDETAILS_ERROR = "UPDATE_DEALPOPUPDETAILS_ERROR";

export const GET_DEAL_TAGS_REQUEST = "GET_DEAL_TAGS_REQUEST";
export const GET_DEAL_TAGS_SUCCESS = "GET_DEAL_TAGS_SUCCESS";
export const GET_DEAL_TAGS_ERROR = "GET_DEAL_TAGS_ERROR";

export const DELETE_DEAL_TAGS_REQUEST = "DELETE_DEAL_TAGS_REQUEST";
export const DELETE_DEAL_TAGS_SUCCESS = "DELETE_DEAL_TAGS_SUCCESS";
export const DELETE_DEAL_TAGS_ERROR = "DELETE_DEAL_TAGS_ERROR";

export const UPDATE_DEAL_TAGS_REQUEST = "UPDATE_DEAL_TAGS_REQUEST";
export const UPDATE_DEAL_TAGS_SUCCESS = "UPDATE_DEAL_TAGS_SUCCESS";
export const UPDATE_DEAL_TAGS_ERROR = "UPDATE_DEAL_TAGS_ERROR";

export const ADD_DEAL_TAGS_REQUEST = "ADD_DEAL_TAGS_REQUEST";
export const ADD_DEAL_TAGS_SUCCESS = "ADD_DEAL_TAGS_SUCCESS";
export const ADD_DEAL_TAGS_ERROR = "ADD_DEAL_TAGS_ERROR";
export const DEAL_SEARCH_REQUEST = "DEAL_SEARCH_REQUEST";
export const DEAL_SEARCH_SUCCESS = "DEAL_SEARCH_SUCCESS";
export const DEAL_SEARCH_ERROR = "DEAL_SEARCH_ERROR";

export const CHANGE_DEALPERSON_REQUEST = "CHANGE_DEALPERSON_REQUEST";
export const CHANGE_DEALPERSON_SUCCESS = "CHANGE_DEALPERSON_SUCCESS";
export const CHANGE_DEALPERSON_ERROR = "CHANGE_DEALPERSON_ERROR";

export const CHANGE_DEALCOMPANY_REQUEST = "CHANGE_DEALCOMPANY_REQUEST";
export const CHANGE_DEALCOMPANY_SUCCESS = "CHANGE_DEALCOMPANY_SUCCESS";
export const CHANGE_DEALCOMPANY_ERROR = "CHANGE_DEALCOMPANY_ERROR";

export const CHANGE_DEALDETAILSINPOPUP_REQUEST = "CHANGE_DEALDETAILSINPOPUP_REQUEST";
export const CHANGE_DEALDETAILSINPOPUP_SUCCESS = "CHANGE_DEALDETAILSINPOPUP_SUCCESS";
export const CHANGE_DEALDETAILSINPOPUP_ERROR = "CHANGE_DEALDETAILSINPOPUP_ERROR";

export const HIDE_WON_DEAL_ICON = "HIDE_WON_DEAL_ICON";

export const MARKASDEFAULT_REQUEST = "MARKASDEFAULT_REQUEST";
export const MARKASDEFAULT_SUCCESS = "MARKASDEFAULT_SUCCESS";
export const MARKASDEFAULT_ERROR = "MARKASDEFAULT_ERROR";

export const UPDATE_DEAL_PIPELINE_LOADING_DATA = "UPDATE_DEAL_PIPELINE_LOADING_DATA";
