export let language = [
  {
    value: "Bahasa Indonesia - Indonesian",
    label: "Bahasa Indonesia - Indonesian",
  },
  {
    value: "Bahasa Melayu - Malay",
    label: "Bahasa Melayu - Malay",
  },
  {
    value: "Català - Catalan",
    label: "Català - Catalan",
  },
  {
    value: "Čeština - Czech",
    label: "Čeština - Czech",
  },
  {
    value: "Dansk - Danish",
    label: "Dansk - Danish",
  },
  {
    value: "Deutsch - German",
    label: "Deutsch - German",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "English UK - British English",
    label: "English UK - British English",
  },
  {
    value: "Español - Spanish",
    label: "Español - Spanish",
  },
  {
    value: "Filipino",
    label: "Filipino",
  },
  {
    value: "Français - French",
    label: "Français - French",
  },
  {
    value: "Gaeilge - Irish (beta)",
    label: "Gaeilge - Irish (beta)",
  },
  {
    value: "Galego - Galician (beta)",
    label: "Galego - Galician (beta)",
  },
  {
    value: "Hrvatski - Croatian",
    label: "Hrvatski - Croatian",
  },
  {
    value: "Italiano - Italian",
    label: "Italiano - Italian",
  },
  {
    value: "Magyar - Hungarian",
    label: "Magyar - Hungarian",
  },
  {
    value: "Nederlands - Dutch",
    label: "Nederlands - Dutch",
  },
  {
    value: "Norsk - Norwegian",
    label: "Norsk - Norwegian",
  },
  {
    value: "Polski - Polish",
    label: "Polski - Polish",
  },
  {
    value: "Português - Portuguese",
    label: "Português - Portuguese",
  },
  {
    value: "Română - Romanian",
    label: "Română - Romanian",
  },
  {
    value: "Slovenčina - Slovak",
    label: "Slovenčina - Slovak",
  },
  {
    value: "Suomi - Finnish",
    label: "Suomi - Finnish",
  },
  {
    value: "Svenska - Swedish",
    label: "Svenska - Swedish",
  },
  {
    value: "Tiếng Việt - Vietnamese",
    label: "Tiếng Việt - Vietnamese",
  },
  {
    value: "Türkçe - Turkish",
    label: "Türkçe - Turkish",
  },
  {
    value: "Ελληνικά - Greek",
    label: "Ελληνικά - Greek",
  },
  {
    value: "Български език - Bulgarian",
    label: "Български език - Bulgarian",
  },
  {
    value: "Русский - Russian",
    label: "Русский - Russian",
  },
  {
    value: "Српски - Serbian",
    label: "Српски - Serbian",
  },
  {
    value: "Українська мова - Ukrainian",
    label: "Українська мова - Ukrainian",
  },
  {
    value: "עִבְרִית - Hebrew",
    label: "עִבְרִית - Hebrew",
  },
  {
    value: "اردو - Urdu (beta)",
    label: "اردو - Urdu (beta)",
  },
  {
    value: "العربية - Arabic",
    label: "العربية - Arabic",
  },
  {
    value: "فارسی - Persian",
    label: "فارسی - Persian",
  },
  {
    value: "मराठी - Marathi",
    label: "मराठी - Marathi",
  },
  {
    value: "हिन्दी - Hindi",
    label: "हिन्दी - Hindi",
  },
  {
    value: "বাংলা - Bangla",
    label: "বাংলা - Bangla",
  },
  {
    value: "ગુજરાતી - Gujarati",
    label: "ગુજરાતી - Gujarati",
  },
  {
    value: "தமிழ் - Tamil",
    label: "தமிழ் - Tamil",
  },
  {
    value: "ಕನ್ನಡ - Kannada",
    label: "ಕನ್ನಡ - Kannada",
  },
  {
    value: "ภาษาไทย - Thai",
    label: "ภาษาไทย - Thai",
  },
  {
    value: "한국어 - Korean",
    label: "한국어 - Korean",
  },
  {
    value: "日本語 - Japanese",
    label: "日本語 - Japanese",
  },
  {
    value: "简体中文 - Simplified Chinese",
    label: "简体中文 - Simplified Chinese",
  },
  {
    value: "繁體中文 - Traditional Chinese",
    label: "繁體中文 - Traditional Chinese",
  },
];

export default {
  language,
};
