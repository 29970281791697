import React from "react";

export default function ImportStep2({ importType, setImportType }) {
  return (
    <div className="w-100 px-2">
      <div className="fv-row">
        <div className="row my-10">
          <div className="col-1" />
          <div className="col-5">
            <input type="radio" className="btn-check" name="account_type" value="smart" checked={importType === "smart"} />
            <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" onClick={() => setImportType("smart")}>
              <i className="bi bi-bezier2 mb-2 me-5 font-size-30px"></i>
              <span className="d-block fw-bold text-start">
                <span className="text-dark fw-bolder d-block fs-4">Smart</span>
                <span className="text-gray-400 fw-bold fs-6">Upload file and map fields</span>
              </span>
            </label>
          </div>
          <div className="col-5">
            <input type="radio" className="btn-check" name="account_type" value="default" checked={importType === "default"} />
            <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" onClick={() => setImportType("default")}>
              <i className="bi bi-check2-square mb-2 me-5 font-size-30px"></i>
              <span className="d-block fw-bold text-start">
                <span className="text-dark fw-bolder d-block fs-4">Default</span>
                <span className="text-gray-400 fw-bold fs-6">Select fields and upload file</span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
