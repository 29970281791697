import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { Dropdown, Spinner, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { InputLimit } from "../../constants";

export default function PersonSelectDropdown(props) {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownOptionSelected, setDropdownOptionSelected] = useState({});
  const [showNewTag, setShowNewTag] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setSearchText(props.value);
  }, [props.value]);

  const search = useCallback(
    debounce((text) => {
      if (text && text.length > 0) {
        dispatch(props.request(text));
      } else {
        return [];
      }
      setLoading(false);
    }, 500),
    [],
  );

  const onSearch = (text) => {
    if (text.length > 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    setSearchText(text);
    search(text);
    setShowNewTag(false);
    if (props.searchText) props.searchText(text);
  };

  const onOptionSelect = (each) => {
    setDropdownOptionSelected(each);
    setSearchText(each.label);
    setShowNewTag(false);

    //this condition used for lead name field
    if (props.customFormData && props.setCustomFormData) {
      const temp = { phone: each.phone, email: each.email, name: each.value, e: each };
      if (!props.customFormData.company && each.company) {
        temp["company"] = each.company;
      }
      if (!props.customFormData.phone && Array.isArray(each.phone) && each.phone.length > 0) {
        temp["phone"] = each.phone;
      }
      if (!props.customFormData.email && Array.isArray(each.email) && each.email.length > 0) {
        temp["email"] = each.email;
      }
      props.setCustomFormData({ ...props.customFormData, ...temp });
    } else props.setValue(each.value, props.field.apiKeyName, each.label);
  };

  const onBlur = () => {
    if (searchText && searchText !== dropdownOptionSelected.label) {
      props.setNewValue(searchText, props.field.apiKeyName);
      setShowNewTag(true);
    } else {
      setShowNewTag(false);
    }
  };

  return (
    <div className="lead-name-select-wrapper">
      <Dropdown className="dropdown-inline w-100">
        <Dropdown.Toggle className="w-100 text-left p-0 border-0" variant="transparent">
          <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={props.field.fieldName} value={searchText} onChange={(e) => onSearch(e.target.value)} onBlur={onBlur} />
          {loading || props.loading ? <Spinner className="input-loading" animation="border" variant="primary" /> : showNewTag ? <div className="new-tag">New</div> : null}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu w-100-mn">
          {false ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="navi navi-hover">
              <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                {props.options && props.options.length === 0
                  ? null
                  : props.options.map((each, index) => {
                      return (
                        <Dropdown.Item
                          className="dropdown-hover"
                          key={index}
                          onSelect={() => {
                            onOptionSelect(each);
                          }}
                        >
                          {each.label}
                        </Dropdown.Item>
                      );
                    })}
              </PerfectScrollbar>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
