import { takeLatest, put, call, select } from "redux-saga/effects";
import * as companyActions from "../actions/actions";
//import required action types below
import {
  COMPANIES_REQUEST,
  COMPANYFIELDS_REQUEST,
  COMPANYTAGS_REQUEST,
  COMPANYTAGSADD_REQUEST,
  COMPANYTAGSEDIT_REQUEST,
  COMPANYTAGSDELETE_REQUEST,
  COMPANYCELLEDIT_REQUEST,
  COMPANYADD_REQUEST,
  COMPANYBULKEDIT_REQUEST,
  COMPANYDELETE_REQUEST,
  COMPANYFILTERS_REQUEST,
  COMPANYFILTERADD_REQUEST,
  COMPANYFILTEREDIT_REQUEST,
  COMPANYFILTERDELETE_REQUEST,
  COMPANYADDFIELDS_REQUEST,
  COMPANIESDROPDOWN_REQUEST,
  COMPANIESDROPDOWNADD_REQUEST,
  COMPANIES_UPDATE_REQUEST,
  COMPANY_POPUP_REQUEST,
  COMPANY_POPUP_EDIT_REQUEST,
  COMPANY_POPUP_UPDATE_REQUEST,
  COUNTRIES_REQUEST,
  TIMEZONES_REQUEST,
  COMPANIESMERGE_REQUEST,
  COMPANYFILTERFAVORITE_REQUEST,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_SHOWHIDE_REQUEST,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST,
  SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST,
  COMPANY_POPUP_REQUEST_AFTER,
  generateCompanyAIWFData,
} from "../types/types";
import api from "../../../../network/apis/api";
import { dispatchSnackbarSuccess } from "../../../../utils/toaster";

function* companiesFunction(action) {
  try {
    var response;
    if (action.payload.filterType) {
      response = yield call(api.post, "/api/crm/saved-filters/apply", action.payload);
    } else {
      response = yield call(api.get, "/api/crm/company/get", action.payload);
    }
    if (response.status === 200) {
      yield put(companyActions.companiesSuccess(response.data));
    } else {
      yield put(companyActions.companiesError({ message: "Could Not Get companies" }));
    }
  } catch (e) {
    yield put(companyActions.companiesError({ message: "Could Not Get companies" }));
  }
}

function* fieldUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/get", action.payload);
    if (response.status === 200) {
      yield put(companyActions.fieldUpdateSuccess(response.data.data));
      yield put(companyActions.companyFieldsSuccess(response.data.data));
    } else {
      yield put(companyActions.fieldUpdateError({ message: "Could Not Get company Fields" }));
    }
  } catch (e) {
    yield put(companyActions.fieldUpdateError({ message: "Could Not Get company Fields" }));
  }
}

function* fieldShowHideFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
    if (response.status === 200) {
      yield put(companyActions.fieldUpdateRequest({ fieldType: "COMPANY" }));
    } else {
      yield put(companyActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(companyActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* fieldUpdatePriorityFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
    if (response.status === 200) {
      yield put(companyActions.fieldUpdateRequest({ fieldType: "COMPANY" }));
    } else {
      yield put(companyActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(companyActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* companyPopupFunction(action) {
  try {
    // const response = yield call(api.get, '/api/crm/company/get-selected-details', action.payload);
    const response = yield call(api.get, `/api/crm/company/details/${action.payload.id}`);
    if (response.status === 200) {
      yield put(companyActions.companyPopupSuccess(response.data));
    } else {
      yield put(companyActions.companyPopupError({ message: "Could Not Get Company Data" }));
    }
  } catch (e) {
    yield put(companyActions.companyPopupError({ message: "Could Not Get Company Data" }));
  }
}

function* companyPopupFunctionAfter(action) {
  try {
    // const response = yield call(api.get, '/api/crm/company/get-selected-details', action.payload);
    const response = yield call(api.get, `/api/crm/company/details/${action.payload.id}`);
    if (response.status === 200) {
      yield put(companyActions.companyPopupSuccess(response.data));
    } else {
      yield put(companyActions.companyPopupError({ message: "Could Not Get Company Data" }));
    }
  } catch (e) {
    yield put(companyActions.companyPopupError({ message: "Could Not Get Company Data" }));
  }
}

function* companyPopupEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/company/update-popup-details", action.payload.editData);
    if (response.status === 200) {
      yield put(companyActions.companyPopupEditSuccess({ message: "Company Edited Successfully" }));
      yield put(companyActions.companyPopupUpdateRequest({ loadingData: { id: action.payload.loadingData }, cellId: action.payload.cellId }));
      yield put(companyActions.companiesUpdateRequest({ loadingData: action.payload.tableLoadingData }));
    } else {
      yield put(companyActions.companyPopupEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(companyActions.companyPopupEditError({ message: "Could Not Edit" }));
  }
}

function* companyPopupUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/company/get-selected-details", action.payload.loadingData);
    if (response.status === 200) {
      yield put(companyActions.companyPopupUpdateSuccess(response.data));
    } else {
      yield put(companyActions.companyPopupUpdateError({ message: "Could Not Get Company Data" }));
    }
  } catch (e) {
    yield put(companyActions.companyPopupUpdateError({ message: "Could Not Get Company Data" }));
  }
}

function* companiesUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/company/get", action.payload.loadingData);
    if (response.status === 200) {
      yield put(companyActions.companiesUpdateSuccess(response.data));
    } else {
      yield put(companyActions.companiesUpdateError({ message: "Could Not Get companies" }));
    }
  } catch (e) {
    yield put(companyActions.companiesUpdateError({ message: "Could Not Get companies" }));
  }
}

function* companiesDropdownFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/company/search", { searchText: action.payload });
    if (response.status === 200) {
      yield put(companyActions.companiesDropdownSuccess(response.data));
    } else {
      yield put(companyActions.companiesDropdownError({ message: "Could Not Get companies" }));
    }
  } catch (e) {
    yield put(companyActions.companiesDropdownError({ message: "Could Not Get companies" }));
  }
}

function* companiesDropdownAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/company/create-by-name", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companiesDropdownAddSuccess({ message: "Company Added Successfully", id: response.data.id, name: action.payload.name }));
      yield put(companyActions.companiesDropdownRequest(action.payload.name));
    } else {
      yield put(companyActions.companiesDropdownAddError({ message: "Could Not Add Company" }));
    }
  } catch (e) {
    yield put(companyActions.companiesDropdownAddError({ status: false, message: "Could Not Add Company" }));
  }
}

function* companyFieldsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Companies.companyFields.list) && store.Companies.companyFields.list.length > 0) {
      yield put(companyActions.companyFieldsSuccess(store.Companies.companyFields.list));
    } else {
      const response = yield call(api.get, "/api/crm/data-fields/get", action.payload.request);
      if (response.status === 200) {
        yield put(companyActions.companyFieldsSuccess(response.data.data));
      } else {
        yield put(companyActions.companyFieldsError({ message: "Could Not Get Company Fields" }));
      }
    }
  } catch (e) {
    yield put(companyActions.companyFieldsError({ message: "Could Not Get Company Fields" }));
  }
}

function* companyAddFieldsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/add-form-fields", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyAddFieldsSuccess(response.data.data));
    } else {
      yield put(companyActions.companyAddFieldsError({ message: "Could Not Get Company Fields" }));
    }
  } catch (e) {
    yield put(companyActions.companyAddFieldsError({ message: "Could Not Get Company Fields" }));
  }
}

function* companyAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/company/create", action.payload.companyData);
    if (response.status === 200) {
      yield put(companyActions.companyAddSuccess({ message: response.data.message }));
      yield put(companyActions.companiesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(companyActions.companyAddError({ message: response.data.message }));
    } else {
      yield put(companyActions.companyAddError({ message: "Could Not Add Company" }));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(companyActions.companyAddError({ status: false, message: "Could Not Add Company" }));
  }
}

function* companyBulkEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/company/bulk-edit", action.payload.companyData);
    if (response.status === 200) {
      yield put(companyActions.companyBulkEditSuccess({ message: "companies Edited Successfully" }));
      yield put(companyActions.companiesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(companyActions.companyBulkEditError({ message: response.data.message }));
    } else {
      yield put(companyActions.companyBulkEditError({ message: "Could Not Edit companies" }));
    }
  } catch (e) {
    yield put(companyActions.companyBulkEditError({ message: "Could Not Edit companies" }));
  }
}

function* companyDeleteFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/company/delete", action.payload.companyIds);
    if (response.status === 200) {
      yield put(companyActions.companyDeleteSuccess({ message: "companies Deleted Successfully" }));
      yield put(companyActions.companiesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(companyActions.companyDeleteError({ message: response.data.message }));
    } else {
      yield put(companyActions.companyDeleteError({ message: "Could Not Delete companies" }));
    }
  } catch (e) {
    yield put(companyActions.companyDeleteError({ message: "Could Not Delete Company" }));
  }
}

function* companyTagsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Companies.companyTags.list) && store.Companies.companyTags.list.length > 0) {
      yield put(companyActions.companyTagsSuccess(store.Companies.companyTags.list));
    } else {
      const response = yield call(api.get, "/api/crm/tag/get", action.payload.request);
      if (response.status === 200) {
        yield put(companyActions.companyTagsSuccess(response.data.data));
      } else {
        yield put(companyActions.companyTagsError({ message: "Could Not Get Company Tags" }));
      }
    }
  } catch (e) {
    yield put(companyActions.companyTagsError({ message: "Could Not Get Company Tags" }));
  }
}

function* companyTagsAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/tag/create", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyTagsAddSuccess({ message: "Tag Added Successfully" }));
      yield put(companyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: false }));
    } else {
      yield put(companyActions.companyTagsError({ message: "Could Not Add Company Tag" }));
    }
  } catch (e) {
    yield put(companyActions.companyTagsError({ status: false, message: "Could Not Add Company Tag" }));
  }
}

function* companyTagsEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/edit", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyTagsEditSuccess({ message: "Tag Edited Successfully" }));
      yield put(companyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: false }));
    } else {
      yield put(companyActions.companyTagsError({ message: "Could Not Edit Tag" }));
    }
  } catch (e) {
    yield put(companyActions.companyTagsError({ message: "Could Not Edit Tag" }));
  }
}

function* companyTagsDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/tag/delete/" + action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyTagsDeleteSuccess({ message: "Tag Deleted Successfully" }));
      yield put(companyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: false }));
    } else {
      yield put(companyActions.companyTagsError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(companyActions.companyTagsError({ message: "Could Not Delete Tag" }));
  }
}

function* companyCellEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/company/edit", action.payload.editData);
    if (response.status === 200) {
      yield put(companyActions.companyCellEditSuccess({ message: "Company Edited Successfully" }));
      yield put(companyActions.companiesUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.cellId }));
      if (action.payload && action.payload.editData && action.payload.editData.id) yield put(companyActions.companyPopupRequestAfter({ id: action.payload.editData.id }));
    } else if (response.status === 400) {
      yield put(companyActions.companyCellEditError({ message: response.data.message }));
    } else {
      yield put(companyActions.companyCellEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(companyActions.companyCellEditError({ message: "Could Not Edit" }));
  }
}


function* generateCompanyAIWFDataFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/ai-wizard/ai-wizard-field/generate", action.payload.data);
    if (response.status === 200) {
      yield put(companyActions.generateCompanyAIWFDataSuccess({ message: response.data.message }));
      yield put(companyActions.companiesUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.data.moduleId }));
      if (action.payload.data.moduleId) yield put(companyActions.companyPopupRequestAfter({ id: action.payload.data.moduleId }));
    } else {
      yield put(companyActions.generateCompanyAIWFDataError({ message: response.data.message }));
    }
  } catch (e) {
    yield put(companyActions.generateCompanyAIWFDataError({ message: "Could Not Generate Data" }));
  }
}


function* companyFiltersFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/saved-filters/get?type=company", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyFiltersSuccess(response.data));
    } else {
      yield put(companyActions.companyFiltersError({ message: "Could Not Get Filters" }));
    }
  } catch (e) {
    yield put(companyActions.companyFiltersError({ message: "Could Not Get Filters" }));
  }
}

function* companyFilterAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyFilterAddSuccess({ message: "Filter Added Successfully" }));
      yield put(companyActions.companyFiltersRequest());
      if (action.cb) yield action.cb(response.data);
    } else {
      yield put(companyActions.companyFiltersError({ message: "Could Not Add Filter" }));
      if (action.cb) yield action.cb(response.data);
    }
  } catch (e) {
    yield put(companyActions.companyFiltersError({ message: "Could Not Add Filter" }));
  }
}

function* companyFilterEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyFilterEditSuccess({ message: "Filter Edited Successfully" }));
      yield put(companyActions.companyFiltersRequest());
    } else {
      yield put(companyActions.companyFilterEditError({ message: "Could Not Edit Filter" }));
    }
    if (action.cb) action.cb(response.data);
  } catch (e) {
    yield put(companyActions.companyFilterEditError({ message: "Could Not Edit Filter" }));
  }
}

function* companyFilterFavoriteFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyFilterFavoriteSuccess({ message: "Filter Edited Successfully" }));
      yield put(companyActions.companyFiltersRequest());
    } else {
      yield put(companyActions.companyFilterFavoriteError({ message: "Could Not Favorite Filter" }));
    }
  } catch (e) {
    yield put(companyActions.companyFilterFavoriteError({ message: "Could Not Favorite Filter" }));
  }
}

function* companyFilterDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/saved-filters/delete/" + action.payload);
    if (response.status === 200) {
      yield put(companyActions.companyFilterDeleteSuccess({ message: "Filter Deleted Successfully" }));
      yield put(companyActions.companyFiltersRequest());
    } else {
      yield put(companyActions.companyFilterDeleteError({ message: "Could Not Delete Filter" }));
    }
  } catch (e) {
    yield put(companyActions.companyFilterDeleteError({ message: "Could Not Delete Filter" }));
  }
}

function* countriesFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Companies.countries.list) && store.Companies.countries.list.length > 0) {
      yield put(companyActions.countriesSuccess({ data: store.Companies.countries.list }));
    } else {
      const response = yield call(api.get, "/api/crm/default-data/country-list", action.payload);
      if (response.status === 200) {
        yield put(companyActions.countriesSuccess(response.data));
      } else {
        yield put(companyActions.countriesError({ message: "Could Not Get countries" }));
      }
    }
  } catch (e) {
    yield put(companyActions.countriesError({ message: "Could Not Get countries" }));
  }
}

function* timezonesFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.Companies.timezones.list) && store.Companies.timezones.list.length > 0) {
      yield put(companyActions.timezonesSuccess({ data: store.Companies.timezones.list }));
    } else {
      const response = yield call(api.get, "/api/crm/default-data/timezone-list", action.payload);
      if (response.status === 200) {
        yield put(companyActions.timezonesSuccess(response.data));
      } else {
        yield put(companyActions.timezonesError({ message: "Could Not Get timezones" }));
      }
    }
  } catch (e) {
    yield put(companyActions.timezonesError({ message: "Could Not Get timezones" }));
  }
}

function* companiesMergeFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/company/merge", action.payload.data);
    if (response.status === 200) {
      yield put(companyActions.companiesMergeSuccess({ message: "Companies Merged Successfully" }));
      yield put(companyActions.companiesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(companyActions.companiesMergeError({ message: response.data.message }));
    } else {
      yield put(companyActions.companiesMergeError({ message: "Could Not Merge Companies" }));
    }
  } catch (e) {
    yield put(companyActions.companiesMergeError({ message: "Could Not Merge Companies" }));
  }
}

function* loadMoreLinkedData(action) {
  try {
    const res = yield call(api.get, `/api/crm/company/details/${action.payload.id}/load-more`, action.payload.data);
    if (res.status === 200) {
      yield put(companyActions.loadMoreLinkedDataSuccess(res.data));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(companyActions.loadMoreLinkedDataError(e));
  }
}

function* searchUnlikedDataForComapnySidePanel(action) {
  try {
    const res = yield call(api.get, `/api/crm/company/search-unlink-data`, action.payload);
    if (res.status === 200) {
      yield put(companyActions.searchUnlinkedDataForCompanySidepanelSuccess(res.data));
    }
  } catch (e) {
    yield put(companyActions.searchUnlinkedDataForCompanySidepanelError(e));
  }
}

function* linkLeadDealPeopleWithCompany(action) {
  try {
    const res = yield call(api.patch, `/api/crm/company/link/${action.payload.companyId}`, action.payload.data);
    if (res.status === 200) {
      yield put(companyActions.companyPopupRequestAfter({ id: action.payload.companyId }));
      yield put(companyActions.linkLeadDealPeopleWithCompanySuccess(res.data));
      dispatchSnackbarSuccess(res.data.message, "success");
    }
  } catch (e) {
    yield put(companyActions.linkLeadDealPeopleWithCompanyError(e));
  }
}

function* unlinkLeadDealPeopleFromCompany(action) {
  try {
    const res = yield call(api.patch, `/api/crm/company/un-link/${action.payload.companyId}`, action.payload.data);
    if (res.status === 200) {
      yield put(companyActions.companyPopupRequestAfter({ id: action.payload.companyId }));
      yield put(companyActions.unlinkLeadDealPeopleFromCompanySuccess(res.data));
      dispatchSnackbarSuccess("Successfully Unlinked", "success");
    }
  } catch (e) {
    yield put(companyActions.unlinkLeadDealPeopleFromCompanyError(e));
  }
}

export default function* CompanySaga() {
  yield takeLatest(COMPANIES_REQUEST, companiesFunction);
  yield takeLatest(FIELD_UPDATE_REQUEST, fieldUpdateFunction);
  yield takeLatest(FIELD_SHOWHIDE_REQUEST, fieldShowHideFunction);
  yield takeLatest(FIELD_UPDATEPRIORITY_REQUEST, fieldUpdatePriorityFunction);
  yield takeLatest(COMPANY_POPUP_REQUEST, companyPopupFunction);
  yield takeLatest(COMPANY_POPUP_REQUEST_AFTER, companyPopupFunctionAfter);
  yield takeLatest(COMPANY_POPUP_EDIT_REQUEST, companyPopupEditFunction);
  yield takeLatest(COMPANY_POPUP_UPDATE_REQUEST, companyPopupUpdateFunction);
  yield takeLatest(COMPANIES_UPDATE_REQUEST, companiesUpdateFunction);
  yield takeLatest(COMPANIESDROPDOWN_REQUEST, companiesDropdownFunction);
  yield takeLatest(COMPANIESDROPDOWNADD_REQUEST, companiesDropdownAddFunction);
  yield takeLatest(COMPANYFIELDS_REQUEST, companyFieldsFunction);
  yield takeLatest(COMPANYADDFIELDS_REQUEST, companyAddFieldsFunction);
  yield takeLatest(COMPANYADD_REQUEST, companyAddFunction);
  yield takeLatest(COMPANYBULKEDIT_REQUEST, companyBulkEditFunction);
  yield takeLatest(COMPANYDELETE_REQUEST, companyDeleteFunction);
  yield takeLatest(COMPANYTAGS_REQUEST, companyTagsFunction);
  yield takeLatest(COMPANYTAGSADD_REQUEST, companyTagsAddFunction);
  yield takeLatest(COMPANYTAGSEDIT_REQUEST, companyTagsEditFunction);
  yield takeLatest(COMPANYTAGSDELETE_REQUEST, companyTagsDeleteFunction);
  yield takeLatest(COMPANYCELLEDIT_REQUEST, companyCellEditFunction);
  yield takeLatest(COMPANYFILTERS_REQUEST, companyFiltersFunction);
  yield takeLatest(COMPANYFILTERADD_REQUEST, companyFilterAddFunction);
  yield takeLatest(COMPANYFILTEREDIT_REQUEST, companyFilterEditFunction);
  yield takeLatest(COMPANYFILTERFAVORITE_REQUEST, companyFilterFavoriteFunction);
  yield takeLatest(COMPANYFILTERDELETE_REQUEST, companyFilterDeleteFunction);
  yield takeLatest(COUNTRIES_REQUEST, countriesFunction);
  yield takeLatest(TIMEZONES_REQUEST, timezonesFunction);
  yield takeLatest(COMPANIESMERGE_REQUEST, companiesMergeFunction);
  yield takeLatest(LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST, loadMoreLinkedData);
  yield takeLatest(SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST, searchUnlikedDataForComapnySidePanel);
  yield takeLatest(LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST, linkLeadDealPeopleWithCompany);
  yield takeLatest(UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST, unlinkLeadDealPeopleFromCompany);
  yield takeLatest(generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_REQUEST, generateCompanyAIWFDataFunction)
}
