import React from "react";
import { Form } from "react-bootstrap";
import ProductSelect from "../../../../components/custom_form/product";
import { InputLimit, LimitInputNumber2 } from "../../../../constants";

export default function AddProductForm(props) {
  return (
    <Form.Group className="card m-2 p-4 shadow-xs bg-light-o-100">
      <div className="d-flex flex-stack">
        <div className="fw-500 font-size-14px">Products</div>
        <div className="cursor-pointer mt-4px" onClick={() => props.onAddProdctClick()}>
          <i className="text-primary bi bi-x-circle"></i>
        </div>
      </div>

      <table className="table table-responsive-md">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.productsArray.map((item, index) => (
            <tr key={index}>
              <td className="pt-3-half w-40">
                <ProductSelect field={{ fieldName: "Product" }} value={item.productId} label={item.itemName} setValue={(id, productInfo) => props.onProductChange(id, productInfo, index)} />
              </td>
              <td className="pt-3-half w-30">
                <Form.Control maxLength={InputLimit.NUMBER} type="number" value={item.unitPrice} onChange={(e) => props.onAddDealPrice(LimitInputNumber2(e), item.field)} />
              </td>
              <td className="pt-3-half w-30">
                <Form.Control maxLength={InputLimit.NUMBER} type="number" value={item.quantity} onChange={(e) => props.onAddDealquantity(LimitInputNumber2(e), item.field)} />
              </td>
              {props.productsArray.length >= 2 && (
                <td className="pt-3-half">
                  <i className="fa fa-minus-circle remove-condition-icon cursor-pointer ml-12px mt-8px font-size-20px" onClick={() => props.onProductItemRemove(item, index)}></i>
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>
              {" "}
              <div className="text-primary cursor-pointer mt-4px" onClick={() => props.onAddOneMore("products")}>
                + Add One More
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              {" "}
              <div>
                <strong> Total </strong>: <span>{props.totalDealValue}</span>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </Form.Group>
  );
}
