import { getCadenceAllLead, addBulkLeadToCadence, addLeadToCadence, getListOfCadenceByLeadId, bulkSubscribeToCadenceByQuery, getCadenceSubscriberCount, getCadenceSubscriberAnalytics } from "../types/cadence-lead.types";

export const getCadenceAllLeadRequest = (payload, cb) => ({
  type: getCadenceAllLead.GET_CADENCE_ALL_LEAD_REQUEST,
  payload,
  cb,
});
export const getCadenceAllLeadSuccess = (payload) => ({
  type: getCadenceAllLead.GET_CADENCE_ALL_LEAD_SUCCESS,
  payload,
});
export const getCadenceAllLeadError = (payload) => ({
  type: getCadenceAllLead.GET_CADENCE_ALL_LEAD_ERROR,
  payload,
});

export const addBulkLeadToCadenceRequest = (payload, cb) => ({
  type: addBulkLeadToCadence.ADD_BULK_LEAD_TO_CADENCE_REQUEST,
  payload,
  cb,
});
export const addBulkLeadToCadenceSuccess = (payload) => ({
  type: addBulkLeadToCadence.ADD_BULK_LEAD_TO_CADENCE_SUCCESS,
  payload,
});
export const addBulkLeadToCadenceError = (payload) => ({
  type: addBulkLeadToCadence.ADD_BULK_LEAD_TO_CADENCE_ERROR,
  payload,
});

export const addLeadToCadenceRequest = (payload, cb) => ({
  type: addLeadToCadence.ADD_LEAD_TO_CADENCE_REQUEST,
  payload,
  cb,
});
export const addLeadToCadenceSuccess = (payload) => ({
  type: addLeadToCadence.ADD_LEAD_TO_CADENCE_SUCCESS,
  payload,
});
export const addLeadToCadenceError = (payload) => ({
  type: addLeadToCadence.ADD_LEAD_TO_CADENCE_ERROR,
  payload,
});

export const getListOfCadenceByLeadIdRequest = (payload, cb) => ({
  type: getListOfCadenceByLeadId.GET_LIST_OF_CADENCE_BY_LEAD_ID_REQUEST,
  payload,
  cb,
});
export const getListOfCadenceByLeadIdSuccess = (payload) => ({
  type: getListOfCadenceByLeadId.GET_LIST_OF_CADENCE_BY_LEAD_ID_SUCCESS,
  payload,
});
export const getListOfCadenceByLeadIdError = (payload) => ({
  type: getListOfCadenceByLeadId.GET_LIST_OF_CADENCE_BY_LEAD_ID_ERROR,
  payload,
});

export const bulkSubscribeToCadenceByQueryRequest = (payload, cb) => ({
  type: bulkSubscribeToCadenceByQuery.REQUEST,
  payload,
  cb,
});
export const bulkSubscribeToCadenceByQuerySuccess = (payload) => ({
  type: bulkSubscribeToCadenceByQuery.SUCCESS,
  payload,
});
export const bulkSubscribeToCadenceByQueryError = (payload) => ({
  type: bulkSubscribeToCadenceByQuery.FAILURE,
  payload,
});

export const getCadenceSubscriberCountRequest = (payload, cb) => ({
  type: getCadenceSubscriberCount.REQUEST,
  payload,
  cb,
});
export const getCadenceSubscriberCountSuccess = (payload) => ({
  type: getCadenceSubscriberCount.SUCCESS,
  payload,
});
export const getCadenceSubscriberCountError = (payload) => ({
  type: getCadenceSubscriberCount.FAILURE,
  payload,
});

export const getCadenceSubscriberAnalyticsRequest = (payload, cb) => ({
  type: getCadenceSubscriberAnalytics.REQUEST,
  payload,
  cb,
});
export const getCadenceSubscriberAnalyticsSuccess = (payload) => ({
  type: getCadenceSubscriberAnalytics.SUCCESS,
  payload,
});
export const getCadenceSubscriberAnalyticsError = (payload) => ({
  type: getCadenceSubscriberAnalytics.FAILURE,
  payload,
});
