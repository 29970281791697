import { takeLatest, put, call } from "redux-saga/effects";
import * as salesTargetTypes from "../types/sales-target.types";
import api from "../../../../network/apis/api";
import * as SalesTargetActions from "../actions/sales_target.actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";

function* getInsightGoalList(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/goals`, action.payload);
    if (response.status === 200) {
      yield put(SalesTargetActions.getListOfSalesTargetSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesTargetActions.getListOfSalesTargetError(response));
    } else {
      yield put(SalesTargetActions.getListOfSalesTargetError("error"));
    }
  } catch (e) {
    yield put(SalesTargetActions.getListOfSalesTargetError(e));
  }
}
function* getInsightGoalListLoadMore(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/goals`, action.payload);
    if (response.status === 200) {
      yield put(SalesTargetActions.getListOfSalesTargetLoadMoreSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesTargetActions.getListOfSalesTargetLoadMoreError(response));
    } else {
      yield put(SalesTargetActions.getListOfSalesTargetLoadMoreError("error"));
    }
  } catch (e) {
    yield put(SalesTargetActions.getListOfSalesTargetLoadMoreError(e));
  }
}

function* createInsightGoal(action) {
  try {
    const response = yield call(api.post, `/api/crm/insight/add-goal`, action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(SalesTargetActions.createSalesTargetSuccess(response.data));
      yield put(SalesTargetActions.getListOfSalesTargetRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesTargetActions.createSalesTargetError(response));
    } else {
      yield put(SalesTargetActions.createSalesTargetError("error"));
    }
  } catch (e) {
    yield put(SalesTargetActions.createSalesTargetError(e));
  }
}

function* editInsightsGoal(action) {
  try {
    const response = yield call(api.patch, `/api/crm/insight/edit-goal`, action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(SalesTargetActions.editSalesTargetSuccess(response.data));
      yield put(SalesTargetActions.getListOfSalesTargetRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesTargetActions.editSalesTargetError(response));
    } else {
      yield put(SalesTargetActions.editSalesTargetError("error"));
    }
  } catch (e) {
    yield put(SalesTargetActions.editSalesTargetError(e));
  }
}

function* deleteInsightsGoal(action) {
  try {
    const response = yield call(api.delete, `/api/crm/insight/delete-goal/${action.payload.id}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(SalesTargetActions.deleteSalesTargetSuccess(response.data));
      yield put(SalesTargetActions.getListOfSalesTargetRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesTargetActions.deleteSalesTargetError(response));
    } else {
      yield put(SalesTargetActions.deleteSalesTargetError("error"));
    }
  } catch (e) {
    yield put(SalesTargetActions.deleteSalesTargetError(e));
  }
}

function* getInsightGraphGoal(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/goals/graph/${action.payload}`);
    if (response.status === 200) {
      yield put(SalesTargetActions.getSalesTargetGraphSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(SalesTargetActions.getSalesTargetGraphError(response));
    } else {
      yield put(SalesTargetActions.getSalesTargetGraphError("error"));
    }
  } catch (e) {
    yield put(SalesTargetActions.getSalesTargetGraphError(e));
  }
}

export default function* SalesTargetSaga() {
  //Goals
  yield takeLatest(salesTargetTypes.getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_REQUEST, getInsightGoalList);
  yield takeLatest(salesTargetTypes.getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_REQUEST, getInsightGoalListLoadMore);
  yield takeLatest(salesTargetTypes.createSalesTarget.CREATE_SALES_TARGET_REQUEST, createInsightGoal);
  yield takeLatest(salesTargetTypes.editSalesTarget.EDIT_SALES_TARGET_REQUEST, editInsightsGoal);
  yield takeLatest(salesTargetTypes.deleteSalesTarget.DELETE_SALES_TARGET_REQUEST, deleteInsightsGoal);
  yield takeLatest(salesTargetTypes.getSalesTargetGraph.GET_SALES_TARGET_GRAPH_REQUEST, getInsightGraphGoal);
}
