export const bulkEditCompanyV2 = {
  BULK_EDIT_COMPANY_V2_REQUEST: "BULK_EDIT_COMPANY_V2_REQUEST",
  BULK_EDIT_COMPANY_V2_SUCCESS: "BULK_EDIT_COMPANY_V2_SUCCESS",
  BULK_EDIT_COMPANY_V2_ERROR: "BULK_EDIT_COMPANY_V2_ERROR",
};

export const bulkDeleteCompanyV2 = {
  BULK_DELETE_COMPANY_V2_REQUEST: "BULK_DELETE_COMPANY_V2_REQUEST",
  BULK_DELETE_COMPANY_V2_SUCCESS: "BULK_DELETE_COMPANY_V2_SUCCESS",
  BULK_DELETE_COMPANY_V2_ERROR: "BULK_DELETE_COMPANY_V2_ERROR",
};
