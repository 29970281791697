import React, { useState } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CustomModal } from "../modal";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import RadioGroupSelect from "../custom_form/radio";
import * as emailValidationActions from "../../containers/Integrations/store/actions/email-validation.actions";

export default function EmailValidationModel({ show, setShow, moduleName, moduleIds, onSuccess, isAllSelected, loadingStore }) {
    const [typeOfCondition, setTypeOfCondition] = useState("Only new emails; ignore those who have already verified.");
    const conditionOptions = [
        "Only new emails; ignore those who have already verified.",
        "Verify all emails, It will validate new & verified emails again."
    ];
    const dispatch = useDispatch();

    const onCancelAddEmailValidation = (e) => {
        e.stopPropagation();
        setShow(false);
    };

    const getConditionType = () => {
        if (typeOfCondition === conditionOptions[0]) {
            return "ONLY_NEW";
        } else if (typeOfCondition === conditionOptions[1]) {
            return "ALL";
        } else return "ALL";
    };

    const handleSaveClick = (e) => {
        e.stopPropagation();
        if (!typeOfCondition) {
            return dispatchSnackbarError("Please select a condition type");
        }

        if (isAllSelected) {
            const payload = {
                "type": "QUERY",
                "mode": moduleName,
                "condition": getConditionType(),
                "filter": {
                    filterId: loadingStore.filterId || null,
                    filter: [],
                    tags: Array.isArray(loadingStore.tagId) ? loadingStore.tagId : loadingStore.tagId ? [loadingStore.tagId] : [],
                    userId: null
                }
            };
            if (loadingStore.filter) {
                try {
                    const filter = JSON.parse(loadingStore.filter);
                    if (filter.andMatchConditions) {
                        payload.filter.filter = filter.andMatchConditions;
                    }
                } catch (err) {
                    console.log(err);
                    return dispatchSnackbarError("Error in filter");
                }
            }
            const payload2 = {
                from: moduleName,
                data: payload,
                filter: loadingStore
            }
            dispatch(emailValidationActions.createEmailValidationRequest(payload2, (data) => {
                if (data.status) {
                    dispatchSnackbarSuccess(data.message);
                } else {
                    dispatchSnackbarError(data.message);
                }
                setShow(false);
                onSuccess();
            }));
        } else {
            if (_.isEmpty(moduleIds)) {
                return dispatchSnackbarError("Please select at least one lead!");
            }
            if (moduleIds && moduleName) {
                const payload = {
                    "type": moduleIds.length === 1 ? "SINGLE" : "BULK",
                    "mode": moduleName,
                    "selectedIds": moduleIds,
                    "condition": getConditionType()
                };
                const payload2 = {
                    from: moduleName,
                    data: payload,
                    filter: loadingStore
                }
                dispatch(emailValidationActions.createEmailValidationRequest(payload2, (data) => {
                    if (data.status) {
                        dispatchSnackbarSuccess(data.message);
                    } else {
                        dispatchSnackbarError(data.message);
                    }
                    setShow(false);
                    onSuccess();
                }));
            }
        }
    };
    const AddEValidationModalProps = {
        showButtons: true,
        show: show,
        title: "Email Validation",
        loading: false,
        buttonText: "Verify Emails",
        handleClose: onCancelAddEmailValidation,
        handleAccept: handleSaveClick,
    };
    return (
        <CustomModal modalProps={AddEValidationModalProps}>
            <Form.Group className1="card p-5 mt-5 mb-0">
                <Form.Label>Conditions for Operations</Form.Label>
                <RadioGroupSelect apiKeyName={"condition"} setValue={(value, apiKyName) => setTypeOfCondition(value)} value={typeOfCondition} options={conditionOptions} isRow={false} />
            </Form.Group>
        </CustomModal>
    );
}
