import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import CloudImage from "../../../images/cloud-backup-restore.png";
import { NavLink, useHistory } from "react-router-dom";
import { Export } from "../../export";
import { useSelector } from "react-redux";
import _ from "lodash";
import { dispatchSnackbarError } from "../../../utils/toaster";

export default function OptionsDropdown(props) {
  const [importAccess, setImportAccess] = useState(false);
  const [exportAccess, setExportAccess] = useState(false);

  const history = useHistory();

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  useEffect(() => {
    if (!_.isEmpty(userProfile.data)) {
      const { userType, permissions } = userProfile.data;
      if (userType === "OWNER") {
        setImportAccess(true);
        setExportAccess(true);
      } else {
        setImportAccess(permissions.import ? true : false);
        setExportAccess(permissions.export ? true : false);
      }
    } else {
      setImportAccess(false);
      setExportAccess(false);
    }
  }, [userProfile.data]);

  const handleImportClick = () => {
    if (importAccess) {
      history.push(`/import/${props.dataType}`);
    } else {
      dispatchSnackbarError(`You dont have access to import ${props.dataType}s. Please get in touch with admin to grant import access.`);
    }
  };

  const handleExportClick = () => {
    if (exportAccess) {
      props.setShowExportModal(true);
    } else {
      dispatchSnackbarError(`You dont have access to export ${props.dataType}s. Please get in touch with admin to grant export access.`);
    }
  };
  return (
    <Dropdown className="dropdown-inline">
      <Dropdown.Toggle variant="transparent" id="dropdown-toggle-top" className="w-100 text-left pt-8px pr-0 pb-8px pl-8px">
        <OverlayTrigger overlay={<Tooltip>Import / Export</Tooltip>}>
          <img width={"20px"} height={"20px"} src={CloudImage} alt={"Import / Export"} />
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm h-50vh-mx overflow-y-auto">
        {/* {props.dataType !== "deal" && <Dropdown.Item to={`/import/${props.dataType}` text-black} >
          <span className="dropdown-item dropdown-hover">{props.importLabel}</span>
        </Dropdown.Item>} */}

        {props.dataType !== "deal" && (
          <Dropdown.Item onSelect={handleImportClick} className="dropdown-hover" href="#">
            {props.importLabel}
          </Dropdown.Item>
        )}
        <Dropdown.Item onSelect={handleExportClick} className="dropdown-hover" href="#">
          {props.exportLabel}
        </Dropdown.Item>
        <NavLink to={`/import/${props.dataType}`} className="text-black">
          <span className="dropdown-item dropdown-hover">History</span>
        </NavLink>
        <Export
          activeFilter={props.activeFilter}
          appliedFilter={props.appliedFilter || {}}
          dataType={props.dataType}
          showColumns={props.showColumns}
          showExportModal={props.showExportModal}
          setShowExportModal={props.setShowExportModal}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
