import { postManageUser, getManageUser, patchManageUser, deleteManageUser, resendInvitation, patchManageRole } from "../../types/admin-settings.types";

export function getManageUserRequest(cb) {
  return {
    type: getManageUser.GET_MANAGE_USER_REQUEST,
    cb,
  };
}

export function getManageUserSuccess(res) {
  return {
    type: getManageUser.GET_MANAGE_USER_SUCCESS,
    payload: res,
  };
}

export function getManageUserError(err) {
  return {
    type: getManageUser.GET_MANAGE_USER_ERROR,
    payload: err,
  };
}

export function patchManageUserRequest(req, paramId, cb) {
  return {
    type: patchManageUser.PATCH_MANAGE_USER_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchManageUserSuccess(res) {
  return {
    type: patchManageUser.PATCH_MANAGE_USER_SUCCESS,
    payload: res,
  };
}

export function patchManageUserError(err) {
  return {
    type: patchManageUser.PATCH_MANAGE_USER_ERROR,
    payload: err,
  };
}

export function deleteManageUserRequest(payload, cb) {
  return {
    type: deleteManageUser.DELETE_MANAGE_USER_REQUEST,
    payload,
    cb,
  };
}

export function deleteManageUserSuccess(res) {
  return {
    type: deleteManageUser.DELETE_MANAGE_USER_SUCCESS,
    payload: res,
  };
}

export function deleteManageUserError(err) {
  return {
    type: deleteManageUser.DELETE_MANAGE_USER_ERROR,
    payload: err,
  };
}

export function postManageUserRequest(req, cb) {
  return {
    type: postManageUser.POST_MANAGE_USER_REQUEST,
    payload: req,
    cb,
  };
}

export function postManageUserSuccess(res) {
  return {
    type: postManageUser.POST_MANAGE_USER_SUCCESS,
    payload: res,
  };
}

export function postManageUserError(err) {
  return {
    type: postManageUser.POST_MANAGE_USER_ERROR,
    payload: err,
  };
}

export function resendInvitationRequest(req) {
  return {
    type: resendInvitation.RESEND_INVITATION_REQUEST,
    payload: req,
  };
}
export function resendInvitationSuccess(res) {
  return {
    type: resendInvitation.RESEND_INVITATION_SUCCESS,
    payload: res,
  };
}
export function resendInvitationError(err) {
  return {
    type: resendInvitation.RESEND_INVITATION_ERROR,
    payload: err,
  };
}

export function patchManageRoleRequest(req, paramId, cb) {
  return {
    type: patchManageRole.PATCH_MANAGE_ROLE_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}
export function patchManageRoleSuccess(res) {
  return {
    type: patchManageRole.PATCH_MANAGE_ROLE_SUCCESS,
    payload: res,
  };
}
export function patchManageRoleError(err) {
  return {
    type: patchManageRole.PATCH_MANAGE_ROLE_ERROR,
    payload: err,
  };
}
