import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Modal, Form, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../../utils/toaster";
import { InputLimit } from "../../../constants";
import CustomInput from "../../../components/custom_form/inputs";
import validator from "validator";

export default function AddQuestionModel(props) {
  const { show, handleClose, handleSave, isEdit } = props.modalProps;
  const [question, setQuestion] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [fieldLabel, setFieldLabel] = useState("");
  const [isMandatory, setIsMandatory] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [defaultAnswer, setDefaultAnswer] = useState("");
  const [fieldObject, setFieldObject] = useState({});

  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);

  const fieldStore = useSelector((state) => state.Leads.leadFields);

  useEffect(() => {
    if (fieldStore.list.length > 0) {
      const s_op = [],
        c_op = [],
        ob = {};
      const usedFields = props.questions.map((each) => each.fieldId);
      fieldStore.list.forEach((each) => {
        if (each.isEditable && !["DATE_RANGE", "DATE_TIME_RANGE", "OWNER_SELECT", "TAG_MULTI_SELECT"].includes(each.fieldInputType) && !usedFields.includes(each._id)) {
          if (each.fieldGroup === "SYSTEM") {
            s_op.push({
              value: each._id,
              label: each.fieldName,
            });
          } else {
            c_op.push({
              value: each._id,
              label: each.fieldName,
            });
          }
        }
        ob[each._id] = each;
      });
      setOptions(s_op);
      setOptions1(c_op);
      setFieldObject(ob);
    }
  }, [fieldStore.list, props.questions]);

  useEffect(() => {
    const { editableQuestion, fieldMapping } = props.modalProps;
    if (isEdit && !_.isEmpty(editableQuestion) && !_.isEmpty(fieldMapping)) {
      setQuestion(editableQuestion.question);
      if (editableQuestion.fieldId && typeof editableQuestion.fieldId === "string") {
        setFieldId(editableQuestion.fieldId);
      } else if (editableQuestion.fieldId && typeof editableQuestion.fieldId === "object") {
        setFieldId(editableQuestion.fieldId._id);
      }
      setFieldLabel(fieldMapping[editableQuestion.fieldId] ? fieldMapping[editableQuestion.fieldId] : "");
      setIsMandatory(editableQuestion.isMandatory);
      setIsHidden(editableQuestion.isHidden);
      setDefaultAnswer(editableQuestion.defaultAnswer);
    }
  }, [isEdit, props.modalProps]);

  const onOptionSelect = (option) => {
    setFieldId(option.value);
    setFieldLabel(option.label);
    setDefaultAnswer(null);
  };

  const onHandleSaveClick = () => {
    if (_.isEmpty(question)) {
      return dispatchSnackbarError("Question cannot be empty!");
    } else if (_.isEmpty(fieldId)) {
      return dispatchSnackbarError("Lead Field cannot be empty!");
    }

    const field = fieldObject[fieldId];
    if (isHidden && field.fieldInputType !== "TOGGLE_BUTTON" && typeof defaultAnswer !== "number" && _.isEmpty(defaultAnswer)) {
      return dispatchSnackbarError("Default answer for hidden field cannot be empty!");
    }
    if (defaultAnswer) {
      if (["INPUT", "TEXTAREA"].includes(field.fieldInputType)) {
      } else if (field.fieldInputType === "MULTI_EMAIL_INPUT" && Array.isArray(defaultAnswer)) {
        for (let j = 0; j < defaultAnswer.length; j++) {
          if (!validator.isEmail(defaultAnswer[j])) {
            return dispatchSnackbarError(defaultAnswer[j] + " is not a valid Email");
          }
        }
      } else if (field.fieldInputType === "MULTI_PHONE_INPUT" && Array.isArray(defaultAnswer)) {
        for (let k = 0; k < defaultAnswer.length; k++) {
          if (!validator.isNumeric(defaultAnswer[k].toString())) {
            return dispatchSnackbarError(defaultAnswer[k] + " is not a valid Phone Number");
          }
        }
      } else if (field.fieldInputType === "NUMBER") {
        if (!validator.isNumeric(defaultAnswer.toString())) {
          return dispatchSnackbarError(defaultAnswer + " is not a valid Number");
        }
      } else if (field.fieldInputType === "RADIO" && field.fieldInputType === "SELECT") {
        if (!field.options.includes(defaultAnswer)) {
          return dispatchSnackbarError("Invalid default answer provided");
        }
      } else if (field.fieldInputType === "CHECKBOX") {
        for (let k = 0; k < defaultAnswer.length; k++) {
          if (!field.options.includes(defaultAnswer[k])) {
            return dispatchSnackbarError("Invalid default answer provided");
          }
        }
      }
      if (field.validation === "URL_VALIDATION" && !validator.isURL(defaultAnswer)) {
        return dispatchSnackbarError("Default value is not a valid URL");
      } else if (field.validation === "PIN_CODE_VALIDATION") {
        if (!validator.isNumeric(defaultAnswer)) {
          return dispatchSnackbarError(" Default value is not a valid pincode");
        }
      } else if (field.validation === "EMAIL_VALIDATION") {
        if (field.fieldGroup === "CUSTOM") {
          if (!validator.isEmail(defaultAnswer)) {
            return dispatchSnackbarError(defaultAnswer + " is not a valid Email");
          }
        } else {
          for (var j = 0; j < defaultAnswer.length; j++) {
            if (!validator.isEmail(defaultAnswer[j])) {
              return dispatchSnackbarError(defaultAnswer[j] + " is not a valid Email");
            }
          }
        }
      } else if (field.validation === "PHONE_VALIDATION") {
        if (defaultAnswer && defaultAnswer.length > 0) {
          for (var k = 0; k < defaultAnswer.length; k++) {
            if (!validator.isNumeric(defaultAnswer[k].toString())) {
              return dispatchSnackbarError(defaultAnswer[k] + " is not a valid Phone Number");
            }
          }
        }
      }
    }

    handleSave({
      type: isEdit ? "edit" : "add",
      data: {
        question: question,
        fieldId: fieldId,
        isMandatory: isMandatory,
        isHidden: isHidden,
        defaultAnswer: isHidden ? (field.fieldInputType === "TOGGLE_BUTTON" ? !!defaultAnswer : defaultAnswer) : null,
      },
    });
    setQuestion("");
    setFieldId("");
    setFieldLabel("");
    setIsMandatory(false);
    setIsHidden(false);
    setDefaultAnswer("");
  };

  const onIsHiddenChange = (checked) => {
    setIsHidden(checked);
  };

  const onClose = () => {
    setQuestion("");
    setFieldId("");
    setFieldLabel("");
    setIsMandatory(false);
    setIsHidden(false);
    setDefaultAnswer("");
    handleClose();
  };
  return (
    <Modal show={show} onHide={onClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit Question" : "Add Question"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group id="leadbot-components-add-question-model-enter-question">
          <Form.Label aria-label="enter-question">Enter Question</Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXTAREA} value={question} onChange={(e) => setQuestion(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group id="leadbot-components-add-question-model-map-lead-field">
          <Form.Label aria-label="map-lead-field">
            Map Lead field
            <OverlayTrigger placement="right" overlay={<Tooltip>{'Map it with the lead field, where the submission data will appear under "Active Leads".'}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
          </Form.Label>

          <Dropdown className="mt-1 w-100">
            <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
              {fieldLabel ? fieldLabel : "Select Lead Field"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100-mn overflow-y-scroll h-300px">
              <Dropdown.Header>System Fields</Dropdown.Header>
              {options.map((option, index) => (
                <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => onOptionSelect(option)}>
                  {option.label}
                </Dropdown.Item>
              ))}
              {options1.length > 0 && (
                <>
                  {" "}
                  <Dropdown.Divider />
                  <Dropdown.Header>Custom Fields</Dropdown.Header>
                  {options1.map((option, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => onOptionSelect(option)}>
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group id="leadbot-components-add-question-model-fields" className="d-flex m-0">
          <Form.Group id="leadbot-components-add-question-model-mandatory-field" className="w-50 m-0">
            <Form.Label aria-label="is-mandatory">
              Is Mandatory
              <OverlayTrigger placement="right" overlay={<Tooltip>{"User must fill the field before continuing."}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <div className="form-check form-switch form-check-custom form-check-solid my-4">
              <input className="form-check-input h-20px w-35px" type="checkbox" checked={isMandatory} onChange={(e) => setIsMandatory(e.target.checked)} id="flexSwitch20x30" />
            </div>
          </Form.Group>
          <Form.Group id="leadbot-components-add-question-model-hidden-field" className="w-50 m-0">
            <Form.Label aria-label="is-hidden-field">
              Is Hidden Field
              <OverlayTrigger placement="right" overlay={<Tooltip>{"The selected question will not be visible to the user and the default value here will automatically show up under the lead field."}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <div className="form-check form-switch form-check-custom form-check-solid my-4">
              <input
                className="form-check-input h-20px w-35px"
                type="checkbox"
                checked={isHidden}
                onChange={(e) => {
                  onIsHiddenChange(e.target.checked);
                }}
                id="flexSwitch20x30"
              />
            </div>
          </Form.Group>
        </Form.Group>
        {isHidden && fieldObject[fieldId] && (
          <Form.Group id="leadbot-components-add-question-model-default-value">
            <Form.Label aria-label="default-value">Default Value</Form.Label>
            <CustomInput dontShowAdd={true} page={"Leads"} pageSub={"lead"} field={fieldObject[fieldId]} value={defaultAnswer} setValue={setDefaultAnswer} allData={{}} />
            {/* <Form.Control maxLength={InputLimit.TEXT} type="text" value={defaultAnswer} onChange={e => setDefaultAnswer(e.target.value)}></Form.Control> */}
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" className="btn btn-sm btn-primary my-1 btn  btn-primary" onClick={onHandleSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
