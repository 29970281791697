export const todaySummaryDummyData = [
  {
    type: "Task",
    overdue: 0,
    open: 0,
    completed: 0,
  },
  {
    type: "Follow Up",
    overdue: 0,
    open: 0,
    completed: 0,
  },
  {
    type: "Call Reminder",
    overdue: 0,
    open: 0,
    completed: 0,
  },
  {
    type: "Email Reminder",
    overdue: 0,
    open: 0,
    completed: 0,
  },
  {
    type: "Metting",
    overdue: 0,
    open: 0,
    completed: 0,
  },
];

export const goalStaticsticsDummyData = [
  {
    name: "Activity Goal",
    total: 10,
    completed: 3,
    percentage: 30,
  },
  {
    name: "Activity Goal 1",
    total: 50,
    completed: 50,
    percentage: 100,
  },
  {
    name: "Activity Goal 2",
    total: 100,
    completed: 70,
    percentage: 70,
  },
];

export const timeOptions = [
  { label: "Any Time", value: 0 },
  { label: "12:00 AM", value: 0 },
  { label: "12:15 AM", value: 900 },
  { label: "12:30 AM", value: 1800 },
  { label: "12:45 AM", value: 2700 },
  { label: "1:00 AM", value: 3600 },
  { label: "1:15 AM", value: 4500 },
  { label: "1:30 AM", value: 5400 },
  { label: "1:45 AM", value: 6300 },
  { label: "2:00 AM", value: 7200 },
  { label: "2:15 AM", value: 8100 },
  { label: "2:30 AM", value: 9000 },
  { label: "2:45 AM", value: 9900 },
  { label: "3:00 AM", value: 10800 },
  { label: "3:15 AM", value: 11700 },
  { label: "3:30 AM", value: 12600 },
  { label: "3:45 AM", value: 13500 },
  { label: "4:00 AM", value: 14400 },
  { label: "4:15 AM", value: 15300 },
  { label: "4:30 AM", value: 16200 },
  { label: "4:45 AM", value: 17100 },
  { label: "5:00 AM", value: 18000 },
  { label: "5:15 AM", value: 18900 },
  { label: "5:30 AM", value: 19800 },
  { label: "5:45 AM", value: 20700 },
  { label: "6:00 AM", value: 21600 },
  { label: "6:15 AM", value: 22500 },
  { label: "6:30 AM", value: 23400 },
  { label: "6:45 AM", value: 24300 },
  { label: "7:00 AM", value: 25200 },
  { label: "7:15 AM", value: 26100 },
  { label: "7:30 AM", value: 27000 },
  { label: "7:45 AM", value: 27900 },
  { label: "8:00 AM", value: 28800 },
  { label: "8:15 AM", value: 29700 },
  { label: "8:30 AM", value: 30600 },
  { label: "8:45 AM", value: 31500 },
  { label: "9:00 AM", value: 32400 },
  { label: "9:15 AM", value: 33300 },
  { label: "9:30 AM", value: 34200 },
  { label: "9:45 AM", value: 35100 },
  { label: "10:00 AM", value: 36000 },
  { label: "10:15 AM", value: 36900 },
  { label: "10:30 AM", value: 37800 },
  { label: "10:45 AM", value: 38700 },
  { label: "11:00 AM", value: 39600 },
  { label: "11:15 AM", value: 40500 },
  { label: "11:30 AM", value: 41400 },
  { label: "11:45 AM", value: 42300 },
  { label: "12:00 PM", value: 43200 },
  { label: "12:15 PM", value: 44100 },
  { label: "12:30 PM", value: 45000 },
  { label: "12:45 PM", value: 45900 },
  { label: "1:00 PM", value: 46800 },
  { label: "1:15 PM", value: 47700 },
  { label: "1:30 PM", value: 48600 },
  { label: "1:45 PM", value: 49500 },
  { label: "2:00 PM", value: 50400 },
  { label: "2:15 PM", value: 51300 },
  { label: "2:30 PM", value: 52200 },
  { label: "2:45 PM", value: 53100 },
  { label: "3:00 PM", value: 54000 },
  { label: "3:15 PM", value: 54900 },
  { label: "3:30 PM", value: 55800 },
  { label: "3:45 PM", value: 56700 },
  { label: "4:00 PM", value: 57600 },
  { label: "4:15 PM", value: 58500 },
  { label: "4:30 PM", value: 59400 },
  { label: "4:45 PM", value: 60300 },
  { label: "5:00 PM", value: 61200 },
  { label: "5:15 PM", value: 62100 },
  { label: "5:30 PM", value: 63000 },
  { label: "5:45 PM", value: 63900 },
  { label: "6:00 PM", value: 64800 },
  { label: "6:15 PM", value: 65700 },
  { label: "6:30 PM", value: 66600 },
  { label: "6:45 PM", value: 67500 },
  { label: "7:00 PM", value: 68400 },
  { label: "7:15 PM", value: 69300 },
  { label: "7:30 PM", value: 70200 },
  { label: "7:45 PM", value: 71100 },
  { label: "8:00 PM", value: 72000 },
  { label: "8:15 PM", value: 72900 },
  { label: "8:30 PM", value: 73800 },
  { label: "8:45 PM", value: 74700 },
  { label: "9:00 PM", value: 75600 },
  { label: "9:15 PM", value: 76500 },
  { label: "9:30 PM", value: 77400 },
  { label: "9:45 PM", value: 78300 },
  { label: "10:00 PM", value: 79200 },
  { label: "10:15 PM", value: 80100 },
  { label: "10:30 PM", value: 81000 },
  { label: "10:45 PM", value: 81900 },
  { label: "11:00 PM", value: 82800 },
  { label: "11:15 PM", value: 83700 },
  { label: "11:30 PM", value: 84600 },
  { label: "11:45 PM", value: 85500 },
];
