import { getListOfSalesForecast, getSalesForecastGraph, getListOfSalesForecastLoadMore } from "../../store/types/sales-forecast.types";

const initialState = {
  list: {
    data: [],
    count: 0,
    loading: false,
    error: null,
    loadMoreLoading: false,
  },
  graph: {
    data: [],
    comulative: [],
    nonComulative: [],
    currency: "INR",
    loading: false,
    error: null,
  },
};

export function SalesForecastReducer(state = initialState, action = {}) {
  switch (action.type) {
    case getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
          loadMoreLoading: false,
        },
      };
    case getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
          loadMoreLoading: false,
        },
      };
    case getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loadMoreLoading: true,
        },
      };
    case getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: [...state.list.data, ...action.payload.data],
          count: action.payload.count,
          loadMoreLoading: false,
        },
      };
    case getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
          loadMoreLoading: false,
        },
      };
    case getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_REQUEST:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: true,
        },
      };
    case getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_SUCCESS:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: false,
          comulative: action.payload.data.comulative,
          nonComulative: action.payload.data.nonComulative,
        },
      };
    case getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_ERROR:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
}
