import { getTeams, getListOfUsers, getUserRole } from "../../types/admin-settings.types";

const initState = {
  getTeamsDetails: {
    list: [],
    loading: false,
  },
  error: {
    err: false,
    errMsg: "",
  },
  users: {
    list: [],
    loading: false,
  },
  userRole: {
    list: {
      allUsers: [],
      teams: [],
      users: [],
    },
    loading: false,
  },
};

export default function tagSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getTeams.GET_TEAMS_REQUEST:
      return {
        ...state,
        getTeamsDetails: { ...state.getTeamsDetails, loading: true },
      };
    case getTeams.GET_TEAMS_SUCCESS:
      return {
        ...state,
        getTeamsDetails: { loading: false, list: action.payload.data },
      };
    case getTeams.GET_TEAMS_ERROR:
      return {
        ...state,
        getTeamsDetails: { ...state.getTeamsDetails, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case getListOfUsers.GET_LIST_OF_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case getListOfUsers.GET_LIST_OF_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          list: action.payload.data,
        },
      };
    case getListOfUsers.GET_LIST_OF_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: { err: true, errMsg: action.payload.message },
        },
      };

    case getUserRole.GET_USER_ROLE_REQUEST:
      return {
        ...state,
        userRole: {
          ...state.userRole,
          loading: true,
        },
      };
    case getUserRole.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: {
          ...state.userRole,
          loading: false,
          list: action.payload.data,
        },
      };
    case getUserRole.GET_USER_ROLE_ERROR:
      return {
        ...state,
        userRole: {
          ...state.userRole,
          loading: false,
          error: { err: true, errMsg: action.payload.message },
        },
      };

    default:
      return state;
  }
}
