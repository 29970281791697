import React from "react";
import AccordionComponent from "../../../../components/accordion/accordion-component";
import SchedulingCalendarForm from "./forms/calendars.form";
import SchedulingCustomFieldsForm from "./forms/custom-feilds.form";
import EventInfoForm from "./forms/event-info.form";
import OpeningHoursForm from "./forms/opening-hours.form";
import PageStylesForm from "./forms/page-styles.form";
import RemindersForm from "./forms/reminders.form";

export default function EditSchedulingPage(props) {
  return (
    <>
      <AccordionComponent defaultActiveKey={"0"} title="Event Info" body="Event Info">
        <EventInfoForm data={props.data} setSelectedData={props.setSelectedData} />
      </AccordionComponent>
      <AccordionComponent defaultActiveKey={"0"} title="Calendars" body="Calendars">
        <SchedulingCalendarForm data={props.data} setSelectedData={props.setSelectedData} />
      </AccordionComponent>
      <AccordionComponent defaultActiveKey={"0"} title="Availability" body="Availability">
        <OpeningHoursForm data={props.data} setSelectedData={props.setSelectedData} />
      </AccordionComponent>
      <AccordionComponent defaultActiveKey={"0"} title="Reminders" body="Reminders">
        <RemindersForm data={props.data} setSelectedData={props.setSelectedData} />
      </AccordionComponent>
      <AccordionComponent defaultActiveKey={"0"} title="Custom Fields" body="Custom Fields">
        <SchedulingCustomFieldsForm data={props.data} fieldMapping={props.fieldMapping} setSelectedData={props.setSelectedData} />
      </AccordionComponent>
      <AccordionComponent defaultActiveKey={"0"} title="Page Styles" body="Page Styles">
        <PageStylesForm syncId={props.syncId} data={props.data} setSelectedData={props.setSelectedData} />
      </AccordionComponent>
    </>
  );
}
