import Api from "../../network/apis/api";

const BASEURL = "/api/crm/ai-wizard";

export const generateEmail = (data) => {
  return Api.post(`${BASEURL}/generate-email`, { data: data });
};

export const rewriteText = (data) => {
  return Api.post(`${BASEURL}/rewrite-text`, data);
};

export const summarizeText = (data) => {
  return Api.post(`${BASEURL}/summarize-text`, data);
};

export const saveText = (data) => {
  return Api.post(`${BASEURL}/save-text`, data);
};

export const deleteSavedText = (data) => {
  return Api.post(`${BASEURL}/delete-saved-text`, data);
};

export const fetchSavedText = () => {
  return Api.post(`${BASEURL}/fetch-saved-text`);
};

export const fetchCreditsData = () => {
  return Api.post(`${BASEURL}/fetch-credits-data`);
};
