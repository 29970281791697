import React, { useState, useEffect } from "react";
import { Col, Button, Form, Card } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import "./style.css";
import { useSelector } from "react-redux";
import { SchedularComponent } from "./schedular";
import { createShortActivityRequest } from "../../containers/Activities/store/actions/activitity_list_actions";
import { useDispatch } from "react-redux";
import { leadsDropdownRequest } from "../../containers/myactivity/actions";
import { getListOfActiveActivityTypesRequest } from "../../containers/Activities/store/actions/activity_types_actions";
import { peopleDropdownRequest } from "../../containers/customers/store/actions/actions";
import { companiesDropdownRequest } from "../../containers/companies/store/actions/actions";
import { dealSearchRequest } from "../../containers/deals/dealPipeline/actions";
import { BlueGradientButton } from "../../components/button";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { getIntegrationAccountDetailsRequest } from "../../containers/Settings/actions/integrations.actions";
import { InputLimit } from "../../constants";
import { timeOptions } from "../../containers/Activities/constants";
import SimpleDropdown from "../dropdowns/simple-dropdown";
import { CustomActivityButtonGroup } from "../button/custom-group-button";

export function QuickScheduler(props) {
  const [actionType, setActionType] = useState("");
  const activityIcons = useSelector((store) => store.activity.types.active.list);
  const [activityText, setActivityText] = useState("");
  const [otherModal, setOpenOtherModal] = useState(false);
  const [activityType, setActivityType] = useState("");
  const dispatch = useDispatch();
  const [isMeeting, setIsMeeting] = useState(false);

  //custom time related
  const [timeButton, setTimeButton] = useState("today");
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
  const [fromTime, setFromTime] = useState({ label: "12:00 AM", value: 0 });
  const [toTime, setToTime] = useState({ label: "12:00 AM", value: 0 });

  useEffect(() => {
    dispatch(getListOfActiveActivityTypesRequest());
    dispatch(getIntegrationAccountDetailsRequest({ apptype: "ZOOM-MEETING" }));
  }, [dispatch]);

  useEffect(() => {
    if (activityIcons && activityIcons.length > 0) {
      setActionType(activityIcons[0].name);
      setActivityType(activityIcons[0]._id);

      if (!_.isEmpty(props.relatedAssociate)) {
        setActivityText(`${activityIcons[0].name} - ${props.relatedAssociate.name ? props.relatedAssociate.name : props.relatedAssociate.title}`);
      } else {
        setActivityText(activityIcons[0].name);
      }
    }
  }, [activityIcons, props.relatedAssociate]);

  useEffect(() => {
    dispatch(leadsDropdownRequest(""));
    dispatch(peopleDropdownRequest(""));
    dispatch(companiesDropdownRequest(""));
    dispatch(dealSearchRequest(""));
  }, [dispatch]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActivity)) {
      setOpenOtherModal(true);
    }
  }, [props.selectedActivity, props.isEdit]);

  const onActivityTextEnter = (e, personName) => {
    if (personName) setActivityText(`${e.target.value}`);
    else setActivityText(e.target.value);
  };

  const onTimeSelect = (time) => {
    if (time === "today") {
      setFromDate(moment().format("yyyy-MM-DD"));
      setToDate(moment().format("yyyy-MM-DD"));
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "tomorrow") {
      setFromDate(
        moment()
          .add(1, "day")
          .format("yyyy-MM-DD"),
      );
      setToDate(
        moment()
          .add(1, "day")
          .format("yyyy-MM-DD"),
      );
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "3 days later") {
      setFromDate(
        moment()
          .add(3, "days")
          .format("yyyy-MM-DD"),
      );
      setToDate(
        moment()
          .add(3, "days")
          .format("yyyy-MM-DD"),
      );
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "custom") {
      setFromDate(moment().format("yyyy-MM-DD"));
      setToDate(moment().format("yyyy-MM-DD"));
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    }
    setTimeButton(time);
  };

  const onOtherButtonClick = () => {
    setOpenOtherModal(true);
  };

  const closeScheduleActivity = () => {
    setOpenOtherModal(false);
    props.setIsEdit(false);
    props.setSelectedActivity({});
  };

  const onShortActivityClick = () => {
    const startTime = moment(fromDate)
      .startOf("day")
      .add(fromTime.value, "second")
      .format();
    const endTime = moment(toDate)
      .startOf("day")
      .add(toTime.value, "second")
      .format();

    if (!activityType || !activityText) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    } else if (!activityType) {
      return dispatchSnackbarError("Please select activity type!");
    } else if (!activityText) {
      return dispatchSnackbarError("Please enter activity text");
    } else if (isMeeting && startTime > endTime) {
      return dispatchSnackbarError("Start Date cannot be grether than end date");
    }

    const duration = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();

    const data = {
      name: activityText,
      activityType: activityType,
      scheduleAt: startTime,
      scheduleAtEnd: isMeeting ? endTime : startTime,
      duration: isMeeting ? duration : 0,
      people: props.peopleId ? props.peopleId : "",
      lead: props.leadId ? props.leadId : "",
      company: props.companyId ? props.companyId : "",
      deal: props.dealId ? props.dealId : "",
      pipelineId: props && props.hasOwnProperty("pipelineId") && props.pipelineId ? props.pipelineId : "",
      attendees: [],
      description: "",
      associated: props.relatedAssociate,
    };
    dispatch(
      createShortActivityRequest({ data: data, from: props.type, pageForm: props.pageForm, filter: props.filter }, (res) => {
        if (res.status) {
          dispatchSnackbarSuccess(res.message);
          props.onCreateNewActivity();
          setEmptyData();
        } else {
          dispatchSnackbarError(res.message);
        }
      }),
    );
  };

  const setEmptyData = () => {
    setTimeButton("today");
    setFromDate(moment().format("yyyy-MM-DD"));
    setToDate(moment().format("yyyy-MM-DD"));
    setFromTime({ label: "12:00 AM", value: 0 });
    setToTime({ label: "12:00 AM", value: 0 });
    setIsMeeting(false);
    setActivityText("");
    setActivityType("");

    props.setIsEdit(false);
    props.setSelectedActivity({});
  };

  const onIconFieldChange = (field) => {
    setActivityType(field._id);
    setActionType(field.name);
    if (props.personName) {
      setActivityText(`${field.name} - ${props.personName}`);
    } else {
      setActivityText(field.name);
    }
    if (field.name === "Meeting") {
      setIsMeeting(true);
    } else {
      setIsMeeting(false);
    }
  };

  return (
    <>
      {!otherModal ? (
        <Card className="panel-shadow shadow-sm bg-fcfcfc">
          <Col className="p-8">
            <Form.Group id="quick-scheduler-activity-type">
              <Form.Label aria-label="activity-type">Activity Type*</Form.Label>
              <CustomActivityButtonGroup options={activityIcons} handleClick={onIconFieldChange} active={actionType} />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group id="quick-scheduler-activity-name" className="w-48">
                <Form.Label aria-label="activity-name">Activity Name*</Form.Label>
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={actionType} value={activityText} onChange={(e) => onActivityTextEnter(e, props.personName)} />
              </Form.Group>
              <div className="w-48">
                <Form.Group id="quick-scheduler-activity-due-date">
                  <Form.Label aria-label="due-date">Due Date*</Form.Label>
                  <Form.Group id="quick-scheduler-activity-due-time" className="mb-2">
                    <div className="d-flex justify-content-center gap-2">
                      <input className="form-control" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                      <SimpleDropdown value={fromTime} label={"Select From Time"} options={timeOptions} handleSelect={(a) => setFromTime(a)} />
                    </div>
                  </Form.Group>
                  {isMeeting && (
                    <Form.Group id="quick-scheduler-activity-date-and-time" className="mb-2">
                      <Form.Label aria-label="to-date&time">{"To Date & Time"}</Form.Label>
                      <div className="d-flex justify-content-center gap-2">
                        <input className="form-control" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        <SimpleDropdown value={toTime} label={"Select To Time"} options={timeOptions} handleSelect={(a) => setToTime(a)} />
                      </div>
                    </Form.Group>
                  )}
                  <div className="d-flex flex-row gap-5px">
                    <Button
                      className={timeButton === "today1" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                      onClick={() => onTimeSelect("today")}
                    >
                      Today
                    </Button>
                    <Button
                      className={timeButton === "tomorrow1" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                      onClick={() => onTimeSelect("tomorrow")}
                    >
                      Tomorrow
                    </Button>
                    <Button
                      className={timeButton === "3 days later1" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                      onClick={() => onTimeSelect("3 days later")}
                    >
                      3 days later
                    </Button>
                    {/* <Button className={timeButton === "custom" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"} onClick={() => onTimeSelect("custom")}>Custom</Button> */}
                  </div>
                </Form.Group>
              </div>
            </div>
          </Col>
          <div className="px-8 pb-8">
            <Button className="btn btn-sm btn-primary my-1 mr-8px btn btn-secondary btn btn-secondary" variant="secondary" onClick={() => onOtherButtonClick()}>
              More
            </Button>
            <BlueGradientButton size="sm" onClick={() => onShortActivityClick()}>
              Create Activity
            </BlueGradientButton>
          </div>
        </Card>
      ) : (
        <SchedularComponent
          pipelineId={props.pipelineId}
          personName={props.personName}
          type={props.type}
          closeScheduleActivity={() => closeScheduleActivity()}
          onCreateNewActivity={props.onCreateNewActivity}
          peopleId={props.peopleId}
          dealId={props.dealId}
          leadId={props.leadId}
          companyId={props.companyId}
          pageForm={props.pageForm}
          filter={props.filter}
          relatedAssociate={props.relatedAssociate}
          selectedActivity={props.selectedActivity}
          setSelectedActivity={props.setSelectedActivity}
          isEdit={props.isEdit}
          setIsEdit={props.setIsEdit}
        />
      )}
    </>
  );
}
