import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Companies from "./index";

export default function ActivityPageRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}`} component={Companies} />
        <Route exact path={`${match.url}/:companyId`} component={Companies} />
        <Route exact path={`${match.url}/:moduleName/:linkedId`} component={Companies} />
        <Redirect to={`${match.url}`} />
      </Switch>
    </>
  );
}
