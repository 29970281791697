import React from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { InputLimit } from "../../../constants";

export default function AddLeadBotModel(props) {
  const { show, handleClose, handleSave, setBotName, botName, loading } = props.modalProps;

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{"Add Leadbot"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group id="leadbot-components-add-lead-bot-model-bot-name">
          <Form.Label aria-label="bot-name">Bot Name</Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXTAREA} value={botName} onChange={(e) => setBotName(e.target.value)}></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" className="btn btn-sm btn-primary my-1 btn  btn-primary" onClick={handleSave}>
          {loading && <Spinner className="mr-2 fs-9 w-15px h-15px text-white" animation="border" variant="" />} Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
