import {
  EXPORTHISTORY_REQUEST,
  EXPORTHISTORY_SUCCESS,
  EXPORTHISTORY_ERROR,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  IMPORTHISTORY_REQUEST,
  IMPORTHISTORY_SUCCESS,
  IMPORTHISTORY_ERROR,
  IMPORT_REQUEST,
  IMPORT_SUCCESS,
  IMPORT_ERROR,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  SAMPLEFILE_REQUEST,
  SAMPLEFILE_SUCCESS,
  SAMPLEFILE_ERROR,
  DELETE_IMPORT_HISTORY_REQUEST,
  DELETE_IMPORT_HISTORY_SUCCESS,
  DELETE_IMPORT_HISTORY_ERROR,
  EXPORTHISTORY_REQUEST_WITHOUT_LOADING,
  IMPORTHISTORY_REQUEST_WITHOUT_LOADING,
} from "./types";

//DELETE IMPORT HISTORY

export function deleteImportHistoryRequest(req) {
  return {
    type: DELETE_IMPORT_HISTORY_REQUEST,
    payload: req,
  };
}
export function deleteImportHistorySuccess(res) {
  return {
    type: DELETE_IMPORT_HISTORY_SUCCESS,
    payload: res,
  };
}
export function deleteImportHistoryError(err) {
  return {
    type: DELETE_IMPORT_HISTORY_ERROR,
    payload: err,
  };
}

// Getting export history data
export function exportHistoryRequest(req) {
  return {
    type: EXPORTHISTORY_REQUEST,
    payload: req,
  };
}

export function exportHistorySuccess(res) {
  return {
    type: EXPORTHISTORY_SUCCESS,
    payload: res,
  };
}

export function exportHistoryError(err) {
  return {
    type: EXPORTHISTORY_ERROR,
    payload: err,
  };
}

export function exportHistoryRequestWithLoading(req) {
  return {
    type: EXPORTHISTORY_REQUEST_WITHOUT_LOADING,
    payload: req,
  };
}

// export data
export function exportRequest(req) {
  return {
    type: EXPORT_REQUEST,
    payload: req,
  };
}

export function exportSuccess(res) {
  return {
    type: EXPORT_SUCCESS,
    payload: res,
  };
}

export function exportError(err) {
  return {
    type: EXPORT_ERROR,
    payload: err,
  };
}

// Getting import history data
export function importHistoryRequest(req) {
  return {
    type: IMPORTHISTORY_REQUEST,
    payload: req,
  };
}

export function importHistorySuccess(res) {
  return {
    type: IMPORTHISTORY_SUCCESS,
    payload: res,
  };
}

export function importHistoryError(err) {
  return {
    type: IMPORTHISTORY_ERROR,
    payload: err,
  };
}
export function importHistoryRequestWithoutLoading(req) {
  return {
    type: IMPORTHISTORY_REQUEST_WITHOUT_LOADING,
    payload: req,
  };
}

// import data
export function importRequest(req, cb) {
  return {
    type: IMPORT_REQUEST,
    payload: req,
    cb,
  };
}

export function importSuccess(res) {
  return {
    type: IMPORT_SUCCESS,
    payload: res,
  };
}

export function importError(err) {
  return {
    type: IMPORT_ERROR,
    payload: err,
  };
}

// Getting sample file
export function sampleFileRequest(req) {
  return {
    type: SAMPLEFILE_REQUEST,
    payload: req,
  };
}

export function sampleFileSuccess(res) {
  return {
    type: SAMPLEFILE_SUCCESS,
    payload: res,
  };
}

export function sampleFileError(err) {
  return {
    type: SAMPLEFILE_ERROR,
    payload: err,
  };
}

// Clearing error and success store
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  };
}
