import React from "react";
import { CustomSubHeader } from "../../components/subheader";
import ContentWrapper from "../Wrapper";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";

export default function CampaignNoAccessScreen() {
  return (
    <ContentWrapper subHeader={<CustomSubHeader></CustomSubHeader>}>
      <CustomEmptyPage page="no-access-campaign" />
    </ContentWrapper>
  );
}
