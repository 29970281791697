import React from "react";
import InlineStyled from "../../../../../../../components/inline-styled/inline-styled";

export default function UsersBarSection({ data }) {
  const getProgressbarPercentage = () => {
    if (data && data.isFreeTrialPeriod) {
      return "0%";
    } else if (typeof data.usersLimit.max === "number") {
      const percentage = (data.usersLimit.value / data.usersLimit.max) * 100;
      return `${percentage}%`;
    } else {
      return "0%";
    }
  };

  const getMaxNumberText = () => {
    if (data && data.isFreeTrialPeriod) {
      return <span>unlimited</span>;
    }
    return <span>{data && typeof data.usersLimit.max === "number" && data.usersLimit.max > 199 ? "unlimited" : data.usersLimit.max} </span>;
  };

  const getRemainingUsers = () => {
    if (data && data.selectedPlan === "free") {
      return "You can add unlimited users in your current plan";
    } else if (data && data.isFreeTrialPeriod) {
      return "You can add unlimited users in your free trial";
    } else {
      if (data && typeof data.usersLimit.max === "number") {
        if (data.usersLimit.max > 199) {
          return "You can add unlimited users in your current plan";
        }
        const difference = (data && data.usersLimit.max) - (data && data.usersLimit.value);
        return `${difference} Users remaining until your plan requires update`;
      } else {
        return "You can add unlimited users in your current plan";
      }
    }
  };
  return (
    <>
      <div className="d-flex text-muted fw-bolder fs-5 mb-3">
        <span className="flex-grow-1 text-gray-800">Users</span>
        <span className="text-gray-800">
          <span>{data && data.usersLimit.value} </span> of {getMaxNumberText()}
        </span>
      </div>
      <div className="progress h-8px bg-light-primary mb-2">
        <InlineStyled className="progress-bar bg-primary" role="progressbar" styles={{ width: getProgressbarPercentage() }} aria-valuenow={86} aria-valuemin={0} aria-valuemax={100} />
      </div>
      <div className="fs-6 text-gray-600 fw-bold mb-10 mb-lg-20">{getRemainingUsers()}</div>;
    </>
  );
}
