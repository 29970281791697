import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { eventTimeHoursOptions, eventTimeMinutesOptions, eventTimeTypeOptions, infoTextMessages } from "../../constants";

export default function EventDurationDropdown(props) {
  // duration
  const [eventTimeType, setEventTimeType] = useState({ value: "minutes", label: "Minutes" });
  const [eventTime, setEventTime] = useState({ value: 60, label: "60" });

  useEffect(() => {
    if (props.value) {
      const data = eventTimeMinutesOptions.find((each) => each.duration === props.value);
      if (data) {
        setEventTimeType({ value: "minutes", label: "Minutes" });
        setEventTime(data);
      } else {
        const data2 = eventTimeHoursOptions.find((each) => each.duration === props.value);
        if (data2) {
          setEventTimeType({ value: "hours", label: "Hours" });
          setEventTime(data2);
        }
      }
    }
  }, [props.value]);

  const handleEventTypeChange = (data) => {
    if (data.value === "hours") {
      setEventTimeType({ value: "hours", label: "Hours" });
      setEventTime({ value: 1, label: "1" });
      props.setValue(60);
    } else {
      setEventTimeType({ value: "minutes", label: "Minutes" });
      setEventTime({ value: 60, label: "60" });
      props.setValue(60);
    }
  };
  const handlEventTimeSelection = (data) => {
    props.setValue(data.duration);
  };
  return (
    <Form.Group>
      <Form.Label>
        Event Duration
        <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.DURATION}</Tooltip>}>
          <i className="ml-2 bi bi-info-circle"></i>
        </OverlayTrigger>
      </Form.Label>
      <Row>
        <Col>
          <SimpleDropdown value={eventTime} label={"Select"} options={eventTimeType.value === "hours" ? eventTimeHoursOptions : eventTimeMinutesOptions} handleSelect={(data) => handlEventTimeSelection(data)} />
        </Col>
        <Col>
          <SimpleDropdown value={eventTimeType} label={"Select"} options={eventTimeTypeOptions} handleSelect={(a) => handleEventTypeChange(a)} />
        </Col>
      </Row>
    </Form.Group>
  );
}
