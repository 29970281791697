import React from "react";
import { ItemIndicator } from "./styled";

class Details extends React.Component {
  renderItem(item, color, index) {
    return (
      <div key={index}>
        <ItemIndicator color={color} />
        {item.name || item.primaryName}
      </div>
    );
  }

  renderItems(items) {
    const { color } = this.props;

    return items.map((item, index) => this.renderItem(item, color, index));
  }

  render() {
    const { items } = this.props;
    const length = items.length;

    if (length === 0) {
      return null;
    }

    return <>{this.renderItems(items)}</>;
  }
}

export default Details;
