import { takeLatest, put, call, select } from "redux-saga/effects";
import * as activityTypes from "../types/activity.types";
import api from "../../../../network/apis/api";
import * as activityLossReasonActions from "../actions/activity_loss_reasons_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import _ from "lodash";

function* getActivityLossReasons(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.activity.lossReasons.reasons.list) && store.activity.lossReasons.reasons.list.length > 0) {
      const payload = { data: store.activity.lossReasons.reasons.list, status: true };
      yield put(activityLossReasonActions.getActivityLossReasonsSuccess(payload));
      if (action.cb) yield action.cb(payload);
    } else {
      const response = yield call(api.get, "/api/crm/lost-reason/activity", action.payload);
      if (response.status === 200) {
        yield put(activityLossReasonActions.getActivityLossReasonsSuccess(response.data));
      } else if (response.status === 400) {
        dispatchSnackbarError(response.data["message"], "error");
        yield put(activityLossReasonActions.getActivityLossReasonsError(response));
      } else {
        yield put(activityLossReasonActions.getActivityLossReasonsError("error"));
      }
      if (action.cb) yield action.cb(response.data);
    }
  } catch (e) {
    yield put(activityLossReasonActions.getActivityLossReasonsError(e));
  }
}

function* getActivityLossReasonsSettings(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && _.isBoolean(store.activity.lossReasons.settings.enabled)) {
      const payload = { isActivityLostReasonEnabled: store.activity.lossReasons.settings.enabled, status: true };
      yield put(activityLossReasonActions.getActivityLossReasonsSettingsSuccess(payload));
      if (action.cb) yield action.cb(payload);
    } else {
      const response = yield call(api.get, "/api/crm/account-settings/activity-lost-reason-settings", action.payload);
      if (response.status === 200) {
        yield put(activityLossReasonActions.getActivityLossReasonsSettingsSuccess(response.data));
      } else if (response.status === 400) {
        dispatchSnackbarError(response.data["message"], "error");
        yield put(activityLossReasonActions.getActivityLossReasonsSettingsError(response));
      } else {
        yield put(activityLossReasonActions.getActivityLossReasonsSettingsError("error"));
      }
      if (action.cb) yield action.cb(response.data);
    }
  } catch (e) {
    yield put(activityLossReasonActions.getActivityLossReasonsSettingsError(e));
  }
}

function* updateActivityLossReasonsSettings(action) {
  try {
    const response = yield call(api.patch, "/api/crm/account-settings/activity-lost-reason-settings", action.payload);
    if (response.status === 200) {
      yield put(activityLossReasonActions.updateActivityLossReasonsSettingsSuccess(response.data));
      yield put(activityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: false }));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(activityLossReasonActions.updateActivityLossReasonsSettingsError(response));
    } else {
      yield put(activityLossReasonActions.updateActivityLossReasonsSettingsError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(activityLossReasonActions.updateActivityLossReasonsSettingsError(e));
  }
}

export default function* ActivityLossReasonSaga() {
  yield takeLatest(activityTypes.getActivityLossReasons.GET_ACTIVITY_LOSS_REASONS_REQUEST, getActivityLossReasons);
  yield takeLatest(activityTypes.getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST, getActivityLossReasonsSettings);
  yield takeLatest(activityTypes.updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST, updateActivityLossReasonsSettings);
}
