import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

//containers
import Dashboard from "./containers/dashboard";
import Forecast from "./sales-forecast";
import SalesTarget from "./sales-target";

export default function InsightsRoutes(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/dashboard`} component={Dashboard} />
      <Route exact path={`${props.match.path}/dashboard/:dashboardId`} component={Dashboard} />
      <Route exact path={`${props.match.path}/sales-target`} component={SalesTarget} />
      <Route exact path={`${props.match.path}/forecast`} component={Forecast} />
      <Redirect to={`${props.match.path}/dashboard`} />
    </Switch>
  );
}
