import React from "react";
import styled from "styled-components";
import { AddNew } from "../styled";
import Deal from "../dealItem";
import AssignmentIcon from "@material-ui/icons/Assignment";

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const ContentBody = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 0 4px 90px;
  margin: 0 4px;
  overflow-y: auto;
`;

const Footer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 31px;
  left: 0;
  right: 0;
  text-align: center;
  background: gray;
`;

const Amount = styled.ul`
  list-style: none;
  overflow: hidden;
  margin: 0 0 5px;
  padding: 0 16px;

  li {
    padding-right: 5px;
    font-size: 12px;

    span {
      font-weight: bold;
      font-size: 10px;
    }

    &:after {
      margin-left: 5px;
    }

    &:last-child:after {
      content: "";
    }
  }
`;

class DealColumn extends React.Component {
  renderTotalAmount() {
    // const { dealTotalAmounts } = this.props;
    const totalForType = [];
    // const totalForType = dealTotalAmounts.totalForType || [];

    return (
      <Amount>
        {totalForType.map((type) => (
          <li key={type._id}>
            <span>{type.name}: </span>
            {this.renderAmount(type.currencies)}
          </li>
        ))}
      </Amount>
    );
  }

  renderContent() {
    const { deals, onUpdate, onRemove } = this.props;

    if (deals === undefined) {
      return (
        <div className="d-flex flex-column align-items-center mt-60-percent">
          <AssignmentIcon />
          <p>No Deals</p>
        </div>
      );
    }
    if (deals && deals.length === 0) {
      return (
        <div className="mt-60-percent">
          <AssignmentIcon />
          <p>No Deals</p>
        </div>
      );
      //   <EmptyState icon="piggy-bank" text="No Sales Pipelines" />;
    }

    const contents =
      deals && deals.length > 0
        ? deals.map((deal, index) => (
            <Deal
              // options={options}
              key={index}
              item={deal}
              onRemove={onRemove}
              onUpdate={onUpdate}
              portable={true}
            />
          ))
        : "";

    return <ContentBody>{contents}</ContentBody>;
  }

  renderFooter() {
    const {
      deals,
      // dealTotalAmounts
    } = this.props;
    const count = 0;
    // const count = dealTotalAmounts.dealCount;

    if ((deals && deals.length === count) || (deals && deals.length > count)) {
      return null;
    }

    return (
      <Footer>
        <AddNew onClick={this.onLoadMore}>
          refresh icon
          {/* <Icon icon="refresh" /> {('Load more')} */}
        </AddNew>
      </Footer>
    );
  }

  onLoadMore = () => {
    const { deals, onLoadMore } = this.props;
    onLoadMore(deals.length);
  };

  render() {
    return (
      <Container>
        {this.renderTotalAmount()}
        {this.renderContent()}
        {/* {this.renderFooter()} */}
      </Container>
    );
  }
}

export default DealColumn;
