import React from "react";
import { Button } from "react-bootstrap";
import { GreenGradientButton } from "../../button";

export default function SidePannelSkeleton(props) {
  return (
    <>
      {props.show ? (
        <div
          onClick={() => {
            props.setShow(false);
          }}
          className="bg-00000066 z-index-1001 position-fixed right-0 left-0 top-0 bottom-0"
        ></div>
      ) : null}
      {props.show && (
        <>
          {" "}
          <div
            id="floatarrow"
            onClick={props.handleClose}
            className={`${
              props.show ? "right-51vw" : "right-minus-10vw"
            } bg-white border-radius-5px width-auto h-30px d-flex flex-column align-items-center justify-content-evenly px-1 offcanvas offcanvas-right mt-2 position-fixed`}
          >
            <span className="btn btn-xs btn-icon btn-light bg-white">
              <i className="fas fa-times "></i>
            </span>
          </div>
          <div id="sidepanel" className={`${props.show ? "right-0" : "right-minus-80vw"} w-50vw h-100vh offcanvas offcanvas-right pb-10 `}>
            <div className="card shadow-none rounded-0">
              <div className="card-header">
                <h3 className="card-title fw-bolder text-dark">{props.title}</h3>
              </div>
              <div className="h-vh-73px overflow-y-auto p-20px">
                {props.children}
                {props.showButtons && (
                  <div className="d-flex justify-content-between py-10px px-0">
                    <div className="d-flex gap-10px">
                      {/* <Button size="sm" variant="secondary" onClick={props.handleClose}>
                                    {props.cancelButtonName}
                                </Button> */}
                      {props.showDeleteButton && (
                        <Button size="sm" variant="danger" onClick={props.handleDelete}>
                          {props.deleteButtonText}
                        </Button>
                      )}
                      <GreenGradientButton size="sm" onClick={props.handleAccept}>
                        {props.saveButtonName}
                      </GreenGradientButton>
                    </div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
