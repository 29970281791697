import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { GreenGradientButton } from "../../../../../../components/button";
import { InputLimit } from "../../../../../../constants";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import WhatsappEditor from "../../../../../campaign/containers/campaign-builder/editors/whatsapp.editor";
import { useSelector } from "react-redux";
import { getVariableKeys } from "../../../../../../utils/variables.util";

export default function CadenceWhatsappForm(props) {
    const [name, setName] = useState("");
    const [config, setConfig] = useState({ phoneId: "", integrationId: "" });
    const [templateId, setTemplateId] = useState("");
    const [selectedTemplateData, setSelectedTemplateData] = useState({});
    const [defaultVariableValues, setDefaultVariableValues] = useState({ header: [], body: [], image: "", video: "", document: "", requiredType: "" });

    const whatsappTemplateStore = useSelector(state => state.campaign.whatsappTemplate);

    const handlePhoneChange = (value) => {
        setConfig({
            ...config,
            phoneId: value.value,
            integrationId: value.integrationId
        });
    }

    useEffect(() => {
        if (props.isEdit && !_.isEmpty(props.selectedStep)) {
            const selectedStep = props.selectedStep;
            const selectedStepWhatsappContent = selectedStep.whatsappContent;
            setName(selectedStep.name);

            const _phoneId = selectedStepWhatsappContent.phoneId;
            const find = props.whatsappPhoneOptions.find((each) => each.value === _phoneId);
            if (find) {
                setConfig({ phoneId: find.value, integrationId: find.integrationId })
            }
            else {
                setConfig({ phoneId: "", integrationId: "" })
            }
            setTemplateId(selectedStepWhatsappContent.templateId);
            setDefaultVariableValues(selectedStepWhatsappContent.variables);

            if (whatsappTemplateStore.list.length > 0) {
                const options = whatsappTemplateStore.list.find((each) => each._id === selectedStepWhatsappContent.templateId)
                setSelectedTemplateData(options)
            }
        }
    }, [props.selectedStep, props.isEdit, props.whatsappPhoneOptions, whatsappTemplateStore.list]);

    useEffect(() => {
        if (!_.isEmpty(selectedTemplateData) && !props.isEdit) {
            const _bodyVariables = [];
            const _headerVariables = [];
            let _image = "", _video = "", _document = "";
            let _requiredType = ""
            for (const each of selectedTemplateData.components) {
                if (each.type === "BODY") {
                    const variables = getVariableKeys(each.text);
                    if (variables.length > 0) {
                        const obj = {};
                        if (selectedTemplateData.defaultValues && selectedTemplateData.defaultValues.body) {
                            const defaultValues = selectedTemplateData.defaultValues.body;
                            for (const eachVariable of defaultValues) {
                                obj[eachVariable.id] = [eachVariable.value, eachVariable.mapField];
                            }
                        }
                        for (const eachVariable of variables) {
                            const find = obj[eachVariable];
                            if (find) {
                                _bodyVariables.push({ id: eachVariable, value: find[0], mapField: find[1] })
                            }
                            else {
                                _bodyVariables.push({ id: eachVariable, value: "", mapField: "" })
                            }
                        }
                    }
                }
                if (each.type === "HEADER") {
                    if (each.format === "TEXT") {
                        const variables = getVariableKeys(each.text);
                        if (variables.length > 0) {
                            const obj = {};
                            if (selectedTemplateData.defaultValues && selectedTemplateData.defaultValues.header) {
                                const defaultValues = selectedTemplateData.defaultValues.header;
                                for (const eachVariable of defaultValues) {
                                    obj[eachVariable.id] = [eachVariable.value, eachVariable.mapField];
                                }
                            }
                            for (const eachVariable of variables) {
                                const find = obj[eachVariable];
                                if (find) {
                                    _headerVariables.push({ id: eachVariable, value: find[0], mapField: find[1] })
                                }
                                else {
                                    _headerVariables.push({ id: eachVariable, value: "", mapField: "" })
                                }
                            }
                        }
                    }
                    else if (each.format === "IMAGE") {
                        _requiredType = "image";
                        _image = "" // each.example.header_handle[0]
                        if (each.example && each.example.header_handle && each.example.header_handle[0] && !each.example.header_handle[0].includes("scontent.whatsapp.net")) {
                            _image = each.example.header_handle[0]
                        }
                    }
                    else if (each.format === "VIDEO") {
                        _requiredType = "video"
                        _video = "" // each.example.header_handle[0]
                        if (each.example && each.example.header_handle && each.example.header_handle[0] && !each.example.header_handle[0].includes("scontent.whatsapp.net")) {
                            _video = each.example.header_handle[0]
                        }
                    }
                    else if (each.format === "DOCUMENT") {
                        _requiredType = "document"
                        _document = "" //each.example.header_handle[0]
                        if (each.example && each.example.header_handle && each.example.header_handle[0] && !each.example.header_handle[0].includes("scontent.whatsapp.net")) {
                            _document = each.example.header_handle[0]
                        }
                    }
                }
            }
            setDefaultVariableValues({
                header: _headerVariables,
                body: _bodyVariables,
                image: _image,
                video: _video,
                document: _document,
                requiredType: _requiredType,
            })
        }
    }, [selectedTemplateData, props.isEdit])

    const onSmsStepSaveClick = () => {
        if (!name) {
            return dispatchSnackbarError("Please fill all the mandatory fields!");
        }
        else if (!config.phoneId) {
            return dispatchSnackbarError("Please select phone number!");
        }
        else if (!templateId) {
            return dispatchSnackbarError("Please select template!");
        }
        for (const each of defaultVariableValues.body) {
            if (!each.value) return dispatchSnackbarError(`Please add default value for {{${each.id}}} in body section.`)
            else if (!each.mapField) return dispatchSnackbarError(`Please add map field for {{${each.id}}} in body section.`)
        }
        for (const each of defaultVariableValues.header) {
            if (!each.value) return dispatchSnackbarError(`Please add default value for {{${each.id}}} in header section.`)
            else if (!each.mapField) return dispatchSnackbarError(`Please add map field for {{${each.id}}} in header section.`)
        }
        if (defaultVariableValues.requiredType === "image" && !defaultVariableValues.image) return dispatchSnackbarError("Please select image");
        if (defaultVariableValues.requiredType === "video" && !defaultVariableValues.video) return dispatchSnackbarError("Please select video");
        if (defaultVariableValues.requiredType === "document" && !defaultVariableValues.document) return dispatchSnackbarError("Please select document");


        if (props.isEdit) {
            const payload = {
                ...props.selectedStep,
                name,
                whatsappContent: {
                    phoneId: config.phoneId,
                    templateId: templateId,
                    variables: defaultVariableValues,
                    sendType: "CAMPAIGN_API"
                },
            };
            props.handleEdit(props.selectedIndex, payload);
            return;
        }

        const payload = {
            name,
            executionOrder: props.totalSteps + 1,
            channelType: "WHATSAPP",
            isEnabled: true,
            delay: props.totalSteps === 0 ? 300 : 86400,
            whatsappContent: {
                phoneId: config.phoneId,
                templateId: templateId,
                variables: defaultVariableValues,
                sendType: "CAMPAIGN_API"
            },
        };
        props.handleAdd(payload);
    };
    return <div>
        <Form.Group className="mb-0">
            <Form.Label className="mb-8">
                Whatsapp messages are sent from the campaign module. Please ensure that you have set up and tested your messages before adding them here. If you need assistance, please contact our support team.
            </Form.Label>
        </Form.Group>
        <Form.Group>
            <Form.Label>Whatsapp Message Name*</Form.Label>
            <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={"Whatsapp Message Name"} value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Group>
        <Form.Group >
            <Form.Label aria-label="subject">From Phone Number</Form.Label>
            <SimpleDropdown
                value={config.phoneId}
                label={"Select Phone Number"}
                options={props.whatsappPhoneOptions}
                handleSelect={(value) => handlePhoneChange(value)}
                showSearch
            />
        </Form.Group>
        <WhatsappEditor
            config={config}
            setConfig={setConfig}
            setTemplateId={setTemplateId}
            templateId={templateId}
            setIsTemplate={() => { }}
            setTemplate={() => { }}
            selectedTemplateData={selectedTemplateData}
            setSelectedTemplateData={setSelectedTemplateData}
            templateVariable={defaultVariableValues}
            setTemplateVariable={setDefaultVariableValues}
            customVariables={[]}
            smallScreen={true}
        />
        <Form.Group className="d-flex justify-content-start">
            <GreenGradientButton size="sm" onClick={onSmsStepSaveClick}>
                {"Save"}
            </GreenGradientButton>
        </Form.Group>
    </div>
}