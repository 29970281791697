import { getSalesForecastGraph, getListOfSalesForecast, deleteSalesForecast, createSalesForecast, editSalesForecast, getListOfSalesForecastLoadMore } from "../types/sales-forecast.types";

//get list of sales target
export function getListOfSalesForecastRequest(req) {
  return {
    type: getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_REQUEST,
    payload: req,
  };
}
export function getListOfSalesForecastSuccess(res) {
  return {
    type: getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_SUCCESS,
    payload: res,
  };
}
export function getListOfSalesForecastError(err) {
  return {
    type: getListOfSalesForecast.GET_LIST_OF_SALES_FORECAST_ERROR,
    payload: err,
  };
}

//get list of sales target load more
export function getListOfSalesForecastLoadMoreRequest(req) {
  return {
    type: getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_REQUEST,
    payload: req,
  };
}
export function getListOfSalesForecastLoadMoreSuccess(res) {
  return {
    type: getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_SUCCESS,
    payload: res,
  };
}
export function getListOfSalesForecastLoadMoreError(err) {
  return {
    type: getListOfSalesForecastLoadMore.GET_LIST_OF_SALES_FORECAST_LOAD_MORE_ERROR,
    payload: err,
  };
}

//create
export function createSalesForecastRequest(req) {
  return {
    type: createSalesForecast.CREATE_SALES_FORECAST_REQUEST,
    payload: req,
  };
}
export function createSalesForecastSuccess(res) {
  return {
    type: createSalesForecast.CREATE_SALES_FORECAST_SUCCESS,
    payload: res,
  };
}
export function createSalesForecastError(err) {
  return {
    type: createSalesForecast.CREATE_SALES_FORECAST_ERROR,
    payload: err,
  };
}

//edit
export function editSalesForecastRequest(req) {
  return {
    type: editSalesForecast.EDIT_SALES_FORECAST_REQUEST,
    payload: req,
  };
}
export function editSalesForecastSuccess(res) {
  return {
    type: editSalesForecast.EDIT_SALES_FORECAST_SUCCESS,
    payload: res,
  };
}
export function editSalesForecastError(err) {
  return {
    type: editSalesForecast.EDIT_SALES_FORECAST_ERROR,
    payload: err,
  };
}

//delete
export function deleteSalesForecastRequest(req) {
  return {
    type: deleteSalesForecast.DELETE_SALES_FORECAST_REQUEST,
    payload: req,
  };
}
export function deleteSalesForecastSuccess(res) {
  return {
    type: deleteSalesForecast.DELETE_SALES_FORECAST_SUCCESS,
    payload: res,
  };
}
export function deleteSalesForecastError(err) {
  return {
    type: deleteSalesForecast.DELETE_SALES_FORECAST_ERROR,
    payload: err,
  };
}

//get graph
export function getSalesForecastGraphRequest(req) {
  return {
    type: getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_REQUEST,
    payload: req,
  };
}
export function getSalesForecastGraphSuccess(res) {
  return {
    type: getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_SUCCESS,
    payload: res,
  };
}
export function getSalesForecastGraphError(err) {
  return {
    type: getSalesForecastGraph.GET_SALES_FORECAST_GRAPH_ERROR,
    payload: err,
  };
}
