import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function ActivityTypeSelect({ field, setValue, value, type }) {
  const [selected, setSelected] = useState("");
  const [activityTypeOptions, setActivityTypeOptions] = useState([]);

  const activityTypeState = useSelector((state) => state.ProfileSettings.getActivity.data);
  const loading = useSelector((state) => state.ProfileSettings.loading);

  useEffect(() => {
    if (activityTypeState) {
      setActivityTypeOptions(activityTypeState.filter((each) => !each.isDeactivated));
    }
  }, [activityTypeState]);

  useEffect(() => {
    if (value && activityTypeOptions.length > 0) {
      const _selected = activityTypeOptions.find((each) => each._id === value);
      if (_selected) {
        setSelected(_selected.name);
      }
    }
  }, [value, activityTypeOptions]);

  const _setSelected = (activityType) => {
    setSelected(activityType.name);
  };

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-300px-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {activityTypeOptions &&
              activityTypeOptions.map((eachActivity, index) => {
                return (
                  <Dropdown.Item
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      _setSelected(eachActivity);
                      setValue(eachActivity["_id"], field.apiKeyName);
                    }}
                  >
                    <i className={`${eachActivity.symbName} mr-10px`} />
                    {eachActivity.name}
                  </Dropdown.Item>
                );
              })}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
