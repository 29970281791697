import React from "react";
import { Button, Modal, Form, Spinner, InputGroup } from "react-bootstrap";

export default function DefaultSenderAddressModal(props) {
  const { show, loading, handleClose, handleSave, selectedDomain, senderName, setSenderName } = props.modalProps;

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{"Edit Default Sender"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <Form.Control type="text" value={senderName} placeholder="mailer" aria-label="mailer" aria-describedby="basic-addon3" onChange={(e) => setSenderName(e.target.value)} />
          <InputGroup.Text id="basic-addon3">@{selectedDomain}</InputGroup.Text>
        </InputGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" className="btn btn-sm btn-primary my-1 btn  btn-primary" onClick={handleSave}>
          {loading && <Spinner className="mr-2 fs-9 w-15px h-15px text-white" animation="border" variant="" />} Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
