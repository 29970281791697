import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "react-bootstrap";
import { BlueGradientButton } from "../../../../../components/button";
import poweredByStripeImage from "../../../../../images/powered_by_stripe.svg";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { getVendorKey } from "./constants";


function CheckoutForm(props) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `http://${window.location.host}/settings/billing/wallet`,
      },
    });

    if (result.error) {
      dispatchSnackbarError(result.error.message);
    } else {
      handleAutoRechargeCancel();
    }
    setLoading(false);
  };
  const handleAutoRechargeCancel = () => {
    props.setShow(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body className="bg-f7fafc">
        <div className="bg-gray-300 p-6 d-flex justify-content-between mb-8 rounded rounded-2">
          <div className="fs-6">
            <strong className="mr-2">Billing:</strong>One time
          </div>
          <div className="fs-6">{"|"}</div>
          <div className="fs-6">
            <strong className="mr-2">Total:</strong>
            {props.currency}
            {props.amount}
          </div>
        </div>
        <PaymentElement />
      </Modal.Body>
      <Modal.Footer className="d-flex flex-stack w-100 justify-content-between">
        <div>
          <img alt="stripe-logo" className="h-30px border-0" src={poweredByStripeImage} />
        </div>
        <div className="d-flex gap-3">
          <Button variant="secondary" size="sm" onClick={handleAutoRechargeCancel}>
            Close
          </Button>
          <BlueGradientButton disabled={!stripe} type="submit" size="sm" variant="primary">
            {loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
            {`Proceed to Pay ${props.currency} ${props.amount}`}
          </BlueGradientButton>
        </div>
      </Modal.Footer>
    </form>
  );
}

export default function AddBalanceModel(props) {
  const options = {
    clientSecret: props.clientSecret,
  };
  const handleAutoRechargeCancel = () => {
    props.setShow(false);
  };
  const stripePromise = loadStripe(getVendorKey("stripe"));
  return (
    <Modal backdrop="static" show={props.show} onHide={handleAutoRechargeCancel} size={"md"}>
      <Modal.Header closeButton>
        <Modal.Title>Add Balance</Modal.Title>
      </Modal.Header>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm setShow={props.setShow} currency={props.currency} amount={props.amount} />
      </Elements>
    </Modal>
  );
}
