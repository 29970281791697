import React from "react";


export default function WhatsappWebhookConfiguration(props) {
    return (
        <div className="p-8">
            <h3>WhatsApp Webhook Configuration</h3>
            <p>If you reconfigure your WhatsApp app for Cloud API in future, you can use the following webhook details.</p>
            <div class="row mb-2">
                <div class="col-3 fw-bold">
                    Callback URL
                </div>
                <div class="col-9">
                    <code>https://{window.location.host}/campaign-webhook-receiver/api/v1/whatsapp</code>
                </div>
            </div>
            <div class="row">
                <div class="col-3 fw-bold">Verify token</div>
                <div class="col-9"><code>MG1svJTSpyEY3JXX</code></div>
            </div>
        </div>
    )
}