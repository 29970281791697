import React from "react";
import InlineStyled from "../../inline-styled/inline-styled";

export default function SecondaryTagDisplay({ data, sidePanel }) {
  return (
    <div className={sidePanel ? "data-sidepanel-flex-container" : "data-flex-container"}>
      {data
        ? data.map((tag, index) =>
            tag ? (
              <InlineStyled key={index} styles={{ backgroundColor: tag.colorCode }} title={tag.name} className="text-333 badge badge-dark tag-ellipsis rounded-pill m-0px">
                {tag.name}
                <br />
              </InlineStyled>
            ) : (
              ""
            ),
          )
        : null}
    </div>
  );
}
