import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { InputLimit } from "../../../../constants";
import { AssigneeOptions, durationOptions, frequencyIntervalOptions, goalTypeOptions, moduleOptions, moduleOptions1, salesTargetAndForecastMapping } from "../../constants";
import { BootstrapDropdown } from "../../../../components/dropdowns/bootstrap-dropdown";
import "react-dates/lib/css/_datepicker.css";
import "./styles.scss";
import BlankSidePanel from "../../../../components/blank-side-pannel";
import { GreenGradientButton } from "../../../../components/button";
import _ from "lodash";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import "react-dates/initialize";
import { CustomButtonGroup } from "../../../../components/button/custom-group-button";

export default function AddSalesTargetForm(props) {
  const [showTypeOfAssignee, setShowTypeOfAssignee] = useState([]);
  const [typeOfAssignee, setTypeOfAssignee] = useState({ value: "user", label: "User" });
  const [assignee, setAssignee] = useState({});
  const [goalType, setGoalType] = useState({});
  const [moduleType, setModuleType] = useState({});
  const [pipelineId, setPipelineId] = useState({});
  const [target, setTarget] = useState(0);
  const [frequencyInterval, setFrequencyInterval] = useState({ value: "weekly", label: "Weekly" });
  const [duration, setDuration] = useState({ value: "automatic", label: "Automatically" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [trackingMetric, setTrackingMetric] = useState("Count");

  useEffect(() => {
    if (props.isSalesTargetEdit && !_.isEmpty(props.selectedSalesTarget)) {
      const data = props.selectedSalesTarget;
      setTypeOfAssignee({ label: salesTargetAndForecastMapping[data.typeOfUser], value: data.typeOfUser });
      if (data.typeOfUser === "team") {
        const _assignee = props.teamOptions.find((each) => each.value === data.assignee);
        if (_assignee) setAssignee(_assignee);
      } else if (data.typeOfUser === "user") {
        const _assignee = props.userOptions.find((each) => each.value === data.assignee);
        if (_assignee) setAssignee(_assignee);
      }

      setGoalType({ label: salesTargetAndForecastMapping[data.goalType], value: data.goalType });
      setModuleType({ label: salesTargetAndForecastMapping[data.module], value: data.module });
      setTarget(data.target);
      setFrequencyInterval({ label: salesTargetAndForecastMapping[data.interval], value: data.interval });

      if (data.duration.type === "automatic") {
        setDuration({ label: salesTargetAndForecastMapping[data.duration.type], value: data.duration.type });
        setStartDate(null);
        setEndDate(null);
      } else {
        setDuration({ label: salesTargetAndForecastMapping["customPeriod"], value: "customPeriod" });
        setStartDate(data.duration.start);
        setEndDate(data.duration.end);
      }

      if (data.module === "deal") {
        if (data.pipelineId) {
          const _find = props.pipelineOptions.find((each) => each.value === data.pipelineId);
          if (_find) setPipelineId(_find);
          else setPipelineId({});
        } else setPipelineId({ value: "", label: "All Pipelines" });
      }

      if (data.trackingMetric) {
        setTrackingMetric(_.capitalize(data.trackingMetric));
      } else setTrackingMetric("Count");
    }
  }, [props.isSalesTargetEdit, props.selectedSalesTarget, props.userOptions, props.teamOptions, props.pipelineOptions]);

  useEffect(() => {
    if (props.userType === "OWNER" || props.userType === "ADMIN") {
      setShowTypeOfAssignee(["company", "team", "user"]);
    } else if (props.userType === "AGENT" && props.teamOptions.length > 0) {
      setShowTypeOfAssignee(["team", "user"]);
    } else {
      setShowTypeOfAssignee(["user"]);
    }
  }, [props.userType, props.teamOptions, props.userOptions]);

  const handleAssigneeTypeChange = (option) => {
    setTypeOfAssignee(option);
    setAssignee({});
  };

  const handleDurationChange = (option) => {
    setDuration(option);
    if (option.value === "automatic") {
      setStartDate(null);
      setEndDate(null);
    } else if (option.value === "thisMonth") {
      const _startDate = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "nextMonth") {
      const _startDate = moment()
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "thisQuarter") {
      const _startDate = moment()
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .endOf("quarter")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "nextQuarter") {
      const _startDate = moment()
        .add(1, "quarter")
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .add(1, "quarter")
        .endOf("quarter")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "thisYear") {
      const _startDate = moment()
        .startOf("year")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .endOf("year")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "nextYear") {
      const _startDate = moment()
        .add(1, "year")
        .startOf("year")
        .format("YYYY-MM-DD");
      const _endDate = moment()
        .add(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
      setStartDate(_startDate);
      setEndDate(_endDate);
    } else if (option.value === "customPeriod") {
      setStartDate(moment());
      setEndDate(moment());
    }
  };

  const validate = () => {
    if (_.isEmpty(typeOfAssignee)) {
      return "Please select type of assignee";
    } else if (["team", "user"].includes(typeOfAssignee.value) && _.isEmpty(assignee)) {
      return `Please select ${typeOfAssignee.value}`;
    } else if (_.isEmpty(goalType)) {
      return "Please select goal type";
    } else if (_.isEmpty(moduleType)) {
      return "Please select module type";
    } else if (moduleType.value === "deal" && _.isEmpty(pipelineId)) {
      return "Please select pipeline option";
    } else if (_.isEmpty(frequencyInterval)) {
      return "Please select interval type";
    } else if (_.isEmpty(duration)) {
      return "Please select duration type";
    }
    return "";
  };

  const handleSave = () => {
    const _validate = validate();
    if (_validate) return dispatchSnackbarError(_validate);

    const data = {
      typeOfUser: typeOfAssignee.value,
      assignee: !_.isEmpty(assignee) ? assignee.value : null,
      goalType: goalType.value,
      module: moduleType.value,
      pipelineId: !_.isEmpty(pipelineId) ? (pipelineId.value ? pipelineId.value : null) : null,
      target: parseInt(target),
      interval: frequencyInterval.value,
      trackingMetric: trackingMetric.toLowerCase(),
      duration: {
        type: duration.value === "automatic" ? "automatic" : "custom",
        start: startDate,
        end: endDate,
      },
    };
    props.handleSave(data);
  };

  const isOutsideRange = (day) => {
    const _startDate = startDate ? moment(startDate) : moment();
    if (frequencyInterval.value === "weekly") {
      return focusedInput === "endDate" && (day.isBefore(_startDate) || day.isAfter(_startDate.clone().add(6, "weeks")));
    } else if (frequencyInterval.value === "monthly") {
      return focusedInput === "endDate" && (day.isBefore(_startDate) || day.isAfter(_startDate.clone().add(6, "months")));
    } else if (frequencyInterval.value === "quarterly") {
      return focusedInput === "endDate" && (day.isBefore(_startDate) || day.isAfter(_startDate.clone().add(6, "quarters")));
    } else if (frequencyInterval.value === "yearly") {
      return focusedInput === "endDate" && (day.isBefore(_startDate) || day.isAfter(_startDate.clone().add(4, "years")));
    }
    return false;
  };

  const handleFrequnecyIntervalChange = (option) => {
    setFrequencyInterval(option);
    setStartDate(null);
    setEndDate(null);
    setDuration({ value: "automatic", label: "Automatically" });
  };

  return (
    <BlankSidePanel show={props.show} handleClose={props.handleClose} title={props.isSalesTargetEdit ? "Edit Sales Target" : "Add Sales Target"}>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Goal Assignee</label>
          <CustomButtonGroup options={AssigneeOptions.filter((a) => showTypeOfAssignee.includes(a.value))} handleClick={handleAssigneeTypeChange} active={typeOfAssignee} />
        </Col>
        <Col className="form-group" xs={6}>
          {["team", "user"].includes(typeOfAssignee && typeOfAssignee.value) && (
            <>
              <label className="form-label text-gray-700">Select {typeOfAssignee.label}</label>
              <BootstrapDropdown label={"Select"} value={assignee} options={typeOfAssignee.value === "team" ? props.teamOptions : props.userOptions} onSelect={setAssignee} />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Goal Type</label>
          <BootstrapDropdown label={"Select"} value={goalType} options={goalTypeOptions} onSelect={setGoalType} />
        </Col>
        {!_.isEmpty(goalType) && (
          <Col className="form-group" xs={6}>
            <label className="form-label text-gray-700">Module</label>
            <CustomButtonGroup
              options={["activityCompleted", "activityAdded"].includes(goalType.value) ? moduleOptions : moduleOptions1}
              handleClick={(option) => {
                setModuleType(option);
                setTrackingMetric("Count");
              }}
              active={moduleType}
            />
          </Col>
        )}
      </Row>
      {moduleType.value === "deal" && (
        <Row>
          <Col className="form-group" xs={6}>
            <label className="form-label text-gray-700">Select Pipeline</label>
            <BootstrapDropdown label={"Select"} value={pipelineId} options={props.pipelineOptions} onSelect={setPipelineId} />
          </Col>
          {["dealAdded", "dealProgressed", "dealWon"].includes(goalType.value) && (
            <Col className="form-group" xs={6}>
              <label className="form-label text-gray-700">Tracking Metric</label>
              <div key={`inline-radio`} className="pt-3">
                {["Value", "Count"].map((each, index) => {
                  return <Form.Check inline key={index} className="mx-3" label={each} checked={trackingMetric === each} type={"radio"} id={`inline-radio-${index}`} onClick={(e) => setTrackingMetric(each)} />;
                })}
              </div>
            </Col>
          )}
        </Row>
      )}

      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Target {trackingMetric === "Value" ? `(${props.currency})` : ""}</label>
          <Form.Control maxLength={InputLimit.NUMBER} value={target} onChange={(e) => setTarget(e.target.value)} type="number" />
        </Col>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Interval</label>
          <BootstrapDropdown label={"Select"} value={frequencyInterval} options={frequencyIntervalOptions} onSelect={handleFrequnecyIntervalChange} />
        </Col>
      </Row>

      <Row>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">Duration</label>
          <CustomButtonGroup options={durationOptions} handleClick={handleDurationChange} active={duration} />
        </Col>
        <Col className="form-group" xs={6}>
          <label className="form-label text-gray-700">{duration.value === "customPeriod" ? "Date Range" : ""}</label>
          {duration.value === "customPeriod" ? (
            <>
              <DateRangePicker
                startDate={startDate ? moment(startDate) : moment()}
                startDateId="startDateId"
                endDate={endDate ? moment(endDate) : moment()}
                endDateId="endDateId"
                onDatesChange={(data) => {
                  if (data.endDate) setEndDate(data.endDate);
                  if (data.startDate) setStartDate(data.startDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => {
                  setFocusedInput(focusedInput);
                }}
                displayFormat={"YYYY-MM-DD"}
                small={true}
                isOutsideRange={isOutsideRange}
              />
            </>
          ) : (
            <>
              {startDate} - {endDate}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="form-group d-flex gap-3 mt-10" xs={6}>
          <Button size="sm" variant="secondary" onClick={props.handleClose}>
            {"Close"}
          </Button>
          <GreenGradientButton size="sm" onClick={handleSave}>
            {"Save"}
          </GreenGradientButton>
        </Col>
      </Row>
    </BlankSidePanel>
  );
}
