/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomSwitch from "../../../../components/custom_form/switch";
import CustomPagination from "../../../../components/pagination/custom-pagination";
import _ from "lodash";
import { DeleteIconButton, EditIconButton } from "../../../../components/button/icon-action-buttons";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export default function NormalTable(props) {
  const getData = (data, key) => {
    if (key === "tags") {
      if (Array.isArray(data.tags)) {
        return data.tags.map((eachTag, index) => {
          return (
            <InlineStyled as="span" key={index} styles={{ backgroundColor: eachTag.colorCode }} className="text-333 badge badge-dark tag-ellipsis rounded-pill m-0 overflow-hidden text-overflow-ellipsis">
              {eachTag.name}
            </InlineStyled>
          );
        });
      } else {
        return <></>;
      }
    } else if (key === "isActive") {
      return (
        <CustomSwitch
          label=""
          id={data.id}
          checked={data.isActive}
          handleChange={(e) => {
            props.onStatusChange(data.id, e.target.checked);
          }}
        />
      );
    } else if (key === "createdAt" || key === "modifiedOn") {
      if (data[key]) {
        return moment(data[key]).format("MMM DD, h:mm a");
      } else return data[key];
    } else if (key === "module") {
      return data[key] && data[key]["name"] ? data[key]["name"] : "";
    } else if (key === "modifiedBy") {
      return data[key] && data[key]["name"] ? data[key]["name"] : "";
    } else if (key === "name") {
      return (
        <div onClick={() => props.onEditClick(data.id)} className="text-hover-primary cursor-pointer">
          {_.capitalize(data[key])}
        </div>
      );
    } else return data[key] ? _.capitalize(data[key]) : "";
  };

  return (
    <div className={`card card-custom`}>
      <div className="card-body py-3 px-3">
        {props.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center h-vh-195px">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="table-responsive pl-3 pt-2 overflow-y-auto">
              <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
                <table className="table align-middle table-row-dashed dataTable no-footer font-size-14px">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      {props.columns &&
                        props.columns.map((col, index) => (
                          <InlineStyled
                            as="th"
                            key={index}
                            className="text-black bg-white w-18 pt-0 cursor-pointer text-nowrap position-sticky top-0"
                            styles={{
                              minWidth: `${100 / (props.columns.length + 1)}%`,
                            }}
                          >
                            <div className="d-flex flex-row align-items-center w-100 h-100 gap-5px">{col}</div>
                          </InlineStyled>
                        ))}
                      <InlineStyled
                        as="th"
                        className="text-black bg-white pt-0 pr-10px w-10 text-center cursor-pointer text-nowrap position-sticky top-0"
                        styles={{
                          minWidth: `${100 / (props.columns.length + 1)}%`,
                        }}
                      >
                        <div className="mr-2 w-100 h-100 gap-5px">Actions</div>
                      </InlineStyled>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data &&
                      props.data.map((data, rowIndex) => {
                        return (
                          <tr key={rowIndex}>
                            {props.dataHead.map((each, index) => {
                              return (
                                <td className="text-nowrap" key={index}>
                                  <div className="d-flex flex-row flex-stack">{getData(data, each)}</div>
                                </td>
                              );
                            })}
                            <td className="text-center text-nowrap">
                              {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Share"}</Tooltip>}>
                            <span className="import-icon-container">
                              <i className="bi bi-share-fill text-53617c"  onClick={() => props.handleShare(data)}></i>
                            </span>
                          </OverlayTrigger> */}
                              <EditIconButton onClick={() => props.onEditClick(data.id)} />
                              <DeleteIconButton onClick={() => props.onDeleteClick(data.id)} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </PerfectScrollbar>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-4">
              <p className="mt-5px mb-0 font-size-14px">
                Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
              </p>
              {props.pagination ? CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), props.setChecked, props.limit, props.setLimit) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
