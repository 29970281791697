import { combineReducers } from "redux";
import adminSettingsReducer from "./admin-reducers";
import profileSettingsReducer from "./profile-reducers";

const settingsReducer = combineReducers({
  admin: adminSettingsReducer,
  profile: profileSettingsReducer,
});

export default settingsReducer;
