import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Accordion, Card, Button, Popover, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//components
import { ViewMore } from "../../custom-components/viewMore";
import CompanySelect from "../../../custom_form/organization";
import { GreenGradientButton } from "../../../button";
import CustomForm from "../../../custom_form";

//actions
import * as companyAction from "../../../../containers/companies/store/actions/actions";
import * as dealActions from "../../../../containers/deals/dealPipeline/actions";
import * as leadAction from "../../../../containers/leads/store/actions/actions";
import * as peopleAction from "../../../../containers/customers/store/actions/actions";
import { DeleteModal } from "../../../modal";
import { IconBasedSimpleDropdownMenu } from "../../../dropdowns/icons-based-dropdown";
import InlineStyled from "../../../inline-styled/inline-styled";

export function CompanyDetails(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [cellEdit, setCellEdit] = useState({});
  const [showColumns, setShowColumns] = useState([]);
  const [viewLessColomns, setViewLessColomns] = useState([]);
  const [companyShow, setCompanyShow] = useState(false);
  const [companyFiled, setCompanyFiled] = useState("");
  const [loadMoreFieldCount, setLoadMoreFieldCount] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addFormData, setAddFormData] = useState({});
  const [editing, setEditing] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [fieldList, setFieldList] = useState([]);
  const [companyData, setCompanyData] = useState({});

  const dispatch = useDispatch();

  //loading data
  const loadingStore = useSelector((state) => state.Companies.loadingData);
  const dealLoadingStore = useSelector((state) => state.DealPipeline.loadingData);
  const peopleLoadingStore = useSelector((state) => state.People.loadingData);
  const leadLoadingStore = useSelector((state) => state.Leads.loadingData);
  const companyColumns = useSelector((state) => state.Companies.companyAddFields);

  //field data
  const fieldStore = useSelector((state) => state.Companies.companyFields);

  // cell loadidng
  const companyCellLoading = useSelector((state) => state.Companies.companyList.cellLoading);
  const dealCellLoading = useSelector((store) => store.DealPipeline.dealPopup.cellLoading);
  const leadCellLoading = useSelector((state) => state.Leads.leadPopup.cellLoading);

  useEffect(() => {
    const _viewLess = [];
    const tempFieldStore = fieldStore.list
      .filter((a) => {
        if (a.apiKeyName === "name") {
          _viewLess[0] = a._id;
        } else if (a.apiKeyName === "website") {
          _viewLess[1] = a._id;
        } else if (a.apiKeyName === "createdAddress") {
          _viewLess[2] = a._id;
        }
        return a.isEnabledForTable;
      })
      .map((each) => each._id);
    setShowColumns(tempFieldStore);
    setViewLessColomns(_viewLess);
    setLoadMoreFieldCount(tempFieldStore.length - _viewLess.length);
  }, [fieldStore]);

  useEffect(() => {
    if (!_.isEmpty(fieldStore.list)) {
      const updatedField = [];
      fieldStore.list.forEach((each) => {
        const newObj = { ...each };
        if (each.apiKeyName === "name" && props.isSecondaryModule) {
          if (companyData && companyData._id) {
            newObj.internalLink = `${props.basePath}/company/${companyData._id}`;
            newObj.internalTooltip = "Open Company";
          }
        }
        updatedField.push(newObj);
      });

      setFieldList(updatedField);
    } else {
      setFieldList([]);
    }
  }, [fieldStore.list, props.isSecondaryModule, props.basePath, companyData]);

  useEffect(() => {
    if (props.moduleType === "deal") {
      if (props.sidePanelProps.company && props.sidePanelProps.company.hasOwnProperty("name")) setCompanyData(props.sidePanelProps.company || {});
      else setCompanyData({});
    } else if (props.moduleType === "people") {
      setCompanyData(props.sidePanelProps.company || {});
    } else if (props.moduleType === "lead") {
      setCompanyData(props.sidePanelProps.company || {});
    } else if (props.moduleType === "company") {
      setCompanyData(props.sidePanelProps.data || {});
    } else setCompanyData({});
  }, [props.moduleType, props.sidePanelProps]);

  const handleCellEdit = (combinedId, data) => {
    if (props.moduleType === "deal") {
      const updateData = {
        data: { ...data, ...cellEdit },
        dealId: props.dealId,
        filter: dealLoadingStore,
        cellId: combinedId,
      };
      if (updateData.data.hasOwnProperty("name") || updateData.data.hasOwnProperty("firstName") || updateData.data.hasOwnProperty("lastName")) {
        updateData["pageForm"] = props.pageForm;
      }
      dispatch(dealActions.updateDealpopupDetailsRequest(updateData));
    } else if (props.moduleType === "company") {
      dispatch(companyAction.companyCellEditRequest({ editData: data, loadingData: loadingStore, cellId: combinedId }));
    } else if (props.moduleType === "lead") {
      const updateData = {
        tableLoadingData: leadLoadingStore,
        cellId: combinedId,
        loadingData: props.leadId,
        editData: {
          ...data,
          ...cellEdit,
        },
      };
      dispatch(leadAction.leadPopupEditRequest(updateData));
    } else if (props.moduleType === "people") {
      const updateData = {
        tableLoadingData: peopleLoadingStore,
        cellId: combinedId,
        loadingData: props.peopleId,
        editData: {
          ...data,
          ...cellEdit,
        },
      };
      dispatch(peopleAction.peoplePopupEditRequest(updateData));
    }
  };

  const addTag = (name, colorCode) => {
    dispatch(companyAction.companyTagsAddRequest({ name: name, colorCode: colorCode, type: "company" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(companyAction.companyTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(companyAction.companyTagsDeleteRequest(id));
  };

  const getCompanyDetails = () => {
    if (props.moduleType === "deal") {
      if (props.sidePanelProps.company && props.sidePanelProps.company.hasOwnProperty("name")) {
        return props.sidePanelProps.company || {};
      }
      return {};
    } else if (props.moduleType === "people") {
      return props.sidePanelProps.company || {};
    } else if (props.moduleType === "lead") {
      return props.sidePanelProps.company || {};
    } else if (props.moduleType === "company") {
      return props.sidePanelProps.data || {};
    }
    return {};
  };

  const onANotherPersonorCompanyCancel = () => {
    setCompanyShow(false);
    setCompanyFiled("");
    document.body.click();
  };

  const onanotherSelect = (id) => {
    if (props.moduleType === "deal") {
      let data = {
        dealId: props.dealId,
        companyId: id ? id : companyFiled,
      };
      dispatch(dealActions.changeDealCompanyRequest(data));
    } else if (props.moduleType === "lead") {
      const data = {
        tableLoadingData: leadLoadingStore,
        cellId: null,
        loadingData: props.leadId,
        editData: {
          id: props.leadId,
          type: "person",
          company: id ? id : companyFiled,
        },
      };
      dispatch(leadAction.leadPopupEditRequest(data));
    } else if (props.moduleType === "people") {
      const data = {
        tableLoadingData: peopleLoadingStore,
        cellId: null,
        loadingData: props.peopleId,
        editData: {
          id: props.peopleId,
          type: "person",
          company: id ? id : companyFiled,
        },
      };
      dispatch(peopleAction.peoplePopupEditRequest(data));
    }
    document.body.click();
    setCompanyShow(false);
    setCompanyFiled("");
  };

  const anotherPersonForm = (type) => {
    return (
      <Popover className="w-300px" id="another person and company" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">{"Link Company"}</Popover.Title>
        <Popover.Content>
          <Form.Group id="company-details-component-another-person-form">
            <Form.Label aria-label="company">Company</Form.Label>
            <CompanySelect field={{ fieldName: "Company", apiKeyName: "company" }} value={companyFiled} setValue={(value) => setCompanyFiled(value)} dontShowAdd={false} />
          </Form.Group>
          <div className="d-flex flex-row justify-content-center gap-15px mt-15px">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onANotherPersonorCompanyCancel(type);
              }}
              size="sm"
            >
              Cancel
            </Button>
            <GreenGradientButton
              onClick={(e) => {
                e.stopPropagation();
                onanotherSelect(companyFiled);
              }}
              size="sm"
            >
              Save
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const handleAdd = () => {
    setLoading(true);
    dispatch(
      companyAction.companyAddRequest({ companyData: addFormData, loadingData: loadingStore }, (data) => {
        if (data.id) {
          onanotherSelect(data.id);
        }
        setLoading(false);
        setShowAddForm(false);
      }),
    );
  };

  const handleCompanyUnlinkAccept = () => {
    if (props.moduleType === "deal") {
      let data = {
        dealId: props.dealId,
        companyId: null,
      };
      dispatch(dealActions.changeDealCompanyRequest(data));
    } else if (props.moduleType === "lead") {
      const data = {
        tableLoadingData: leadLoadingStore,
        cellId: null,
        loadingData: props.leadId,
        editData: {
          id: props.leadId,
          type: "person",
          company: null,
        },
      };
      dispatch(leadAction.leadPopupEditRequest(data));
    } else if (props.moduleType === "people") {
      const data = {
        tableLoadingData: peopleLoadingStore,
        cellId: null,
        loadingData: props.peopleId,
        editData: {
          id: props.peopleId,
          type: "person",
          company: null,
        },
      };
      dispatch(peopleAction.peoplePopupEditRequest(data));
    }
    setShowUnlinkModal(false);
  };

  const unlinkCompanyModalProps = {
    show: showUnlinkModal,
    title: "Unlink Company",
    deleteName: "Unlink",
    handleClose: () => setShowUnlinkModal(false),
    handleAccept: handleCompanyUnlinkAccept,
  };

  return (
    <div key={props.index}>
      <Draggable draggableId={"company-details"} index={props.index}>
        {(provided, snapshot) => (
          <Accordion
            activeKey={showAddForm || companyShow || isOpen || editing ? "toggle-company" : ""}
            as={Card}
            defaultActiveKey={isOpen ? "toggle-company" : null}
            onClick={(e) => {
              e.stopPropagation();
              if (!(showAddForm || companyShow || editing)) setIsOpen(!isOpen);
            }}
            className={`mb-4 card-dashed1 ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style }}
          >
            <Accordion.Toggle eventKey={"toggle-company"} className="cursor-pointer p-14px bg-white h-0px-mn" as={Card.Header}>
              <div className="d-flex flex-stack w-100">
                <div className="d-flex align-items-center gap-2">
                  <i className="bi bi-building side-pannel-card-header-color"></i>
                  <span className="fs-6 m-0 side-pannel-card-header-color">Company</span>
                  <i className={`side-panel-data-icon fas fa-sm fa-chevron-${isOpen ? "up" : "down"} ml-2 side-pannel-card-header-color`}></i>
                </div>
                <div className="d-flex flex-center gap-4" onClick={(e) => e.stopPropagation()}>
                  {props.isSecondaryModule && !_.isEmpty(getCompanyDetails()) && (
                    <OverlayTrigger overlay={<Tooltip>Unlink Company</Tooltip>}>
                      <i
                        className="bi bi-dash-circle text-hover-danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowUnlinkModal(true);
                        }}
                      ></i>
                    </OverlayTrigger>
                  )}
                  {props.isSecondaryModule && (
                    <IconBasedSimpleDropdownMenu show={companyShow} setShow={setCompanyShow} label={"Add Or Change Company"} icon={"bi bi-plus-circle"}>
                      {anotherPersonForm("company")}
                    </IconBasedSimpleDropdownMenu>
                  )}
                  <i onClick={(e) => e.stopPropagation()} className="fa fa-bars fa-md" aria-hidden="true" {...provided.dragHandleProps}></i>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"toggle-company"}>
              <InlineStyled as={Card.Body} onClick={(e) => e.stopPropagation()} styles={{ padding: "1rem" }}>
                {!_.isEmpty(getCompanyDetails()) ? (
                  <>
                    <ViewMore
                      cellEdit={cellEdit}
                      setCellEdit={setCellEdit}
                      handleCellEdit={handleCellEdit}
                      addTag={addTag}
                      editTag={editTag}
                      deleteTag={deleteTag}
                      setEditingStatus={setEditing}
                      page={"Companies"}
                      pageSub={"company"}
                      showColumns={props.viewMore ? showColumns : viewLessColomns}
                      columns={fieldList}
                      idToIndex={fieldStore.idToIndex}
                      viewing={getCompanyDetails()}
                      loadingData={loadingStore}
                      cellLoading={companyCellLoading || dealCellLoading || leadCellLoading}
                    />
                    <div className="side-panel-data-item px-0">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setViewMore(!props.viewMore);
                        }}
                        variant="link"
                        className="p-0 pt-2 px-0 text-decoration-none"
                      >
                        {props.viewMore ? "View Less" : `See ${loadMoreFieldCount} More`}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div data-kt-search-element="empty" className="text-center">
                    <div className="pt-10 pb-10">
                      <span className="svg-icon svg-icon-4x opacity-50">
                        <i className="bi bi-building font-size-30px"></i>
                      </span>
                    </div>
                    <div className="pb-15 fw-bold">
                      <h3 className="text-gray-600 fs-5 mb-2">There are no company linked.</h3>
                      <div className="text-muted fs-7">
                        Company associated with the current {props.moduleType} will be displayed here. To link a company to a {props.moduleType}, click on switch to another company or{" "}
                        <span
                          className="text-primary cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCompanyShow(true);
                          }}
                        >
                          here
                        </span>
                        .
                      </div>
                    </div>
                  </div>
                )}
              </InlineStyled>
            </Accordion.Collapse>
          </Accordion>
        )}
      </Draggable>

      <CustomForm
        show={showAddForm}
        setShow={setShowAddForm}
        handleAdd={handleAdd}
        addTag={addTag}
        editTag={editTag}
        deleteTag={deleteTag}
        page={"Companies"}
        pageSub={"company"}
        fields={companyColumns.list}
        customFormData={addFormData}
        setCustomFormData={setAddFormData}
        fieldsLoading={companyColumns.loading}
        propLoading={loading}
        cacheCompany={false}
      />
      <DeleteModal modalProps={unlinkCompanyModalProps}>Are you sure want to unlink company?</DeleteModal>
    </div>
  );
}
