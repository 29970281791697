import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { companiesDropdownRequest, companiesDropdownAddRequest, companiesDropdownAddClear } from "../../containers/companies/store/actions/actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import SearchSelect from "./searchSelect";
import { useDispatch } from "react-redux";
import { InputLimit } from "../../constants";
import { Dropdown, Spinner, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function CompanySelect({ field, value, setValue, dontShowAdd, valueLabel, cacheCompany }) {
  const dispatch = useDispatch();
  const [newCompanyName, setNewCompanyName] = useState("");
  const companyStore = useSelector((state) => state.Companies.companyDropdownList);
  const newCompanyStore = useSelector((state) => state.Companies.newCompany);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [isNew, setIsNew] = useState(false);
  const [customOptions, setCustomOptions] = useState({});

  useEffect(() => {
    if (!newCompanyStore) {
      setSelected({
        label: companyStore.list.find((company) => company["_id"] === value) ? companyStore.list.find((company) => company["_id"] === value).name : valueLabel ? valueLabel : null,
        value: value,
      });
    }
  }, [companyStore, value, newCompanyStore, valueLabel]);

  useEffect(() => {
    if (value) {
      if (customOptions.value === value) {
        return setSelected(customOptions);
      }
      const label = companyStore.list.find((company) => company["_id"] === value) ? companyStore.list.find((company) => company["_id"] === value).name : valueLabel ? valueLabel : null;
      setSelected({ label: label, value: value });
    }
  }, [companyStore, value, valueLabel, customOptions]);

  useEffect(() => {
    if (newCompanyStore) {
      setValue(newCompanyStore.id, field.apiKeyName, newCompanyStore.name);
      dispatchSnackbarSuccess("Company Added Successfuly");
      setSelected({
        label: newCompanyStore.name,
        value: newCompanyStore.id,
      });
      dispatch(companiesDropdownAddClear());
    }
  }, [newCompanyStore, dispatch, field, setValue]);

  useEffect(() => {
    const temp = companyStore.list.map((each) => ({ value: each["_id"], label: each["name"] }));
    if (!dontShowAdd && newCompanyName.length > 0) {
      temp.push({ value: "new", label: '+ Add "' + newCompanyName + `" as new ${field.apiKeyName}` });
    }
    setOptions(temp);
  }, [companyStore, newCompanyName, dontShowAdd, field.apiKeyName]);

  const handleSave = () => {
    setIsNew(true);
    if (newCompanyName.trim() === "") {
      dispatchSnackbarError("Name can not be blank!");
      return;
    }
    if (!dontShowAdd) {
      if (cacheCompany) {
        setValue(newCompanyName, field.apiKeyName, newCompanyName);
        setSelected({ label: newCompanyName, value: newCompanyName });
        setCustomOptions({ label: newCompanyName, value: newCompanyName });
        dispatch(companiesDropdownAddClear());
      } else {
        dispatch(companiesDropdownAddRequest({ name: newCompanyName }));
      }
    }
  };
  const setValueA = (a) => {
    if (!a) {
      return setValue("", field.apiKeyName, "");
    }
    for (var i = 0; i < companyStore.list.length; i++) {
      if (companyStore.list[i]._id === a) {
        setValue(a, field.apiKeyName, companyStore.list[i].name);
      }
    }
  };

  return (
    <>
      <SearchSelect
        setIsNew={setIsNew}
        isNew={isNew}
        options={options}
        selected={selected}
        setValue={setValueA}
        handleSave={handleSave}
        setSearch={setNewCompanyName}
        field={field}
        loading={companyStore.loading}
        request={companiesDropdownRequest}
      />
    </>
  );
}

export function CompanySelectDropdown({ field, setValue, value }) {
  const Store = useSelector((state) => state.Companies.companyDropdownList);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < Store.list.length; i++) temp.push({ _id: Store.list[i]["_id"], name: Store.list[i]["name"] });
    setOptions(temp);
  }, [Store]);

  const onSearch = (value) => {
    dispatch(companiesDropdownRequest(value));
    setSearch(value);
  };

  const [selected, setSelected] = useState(value);
  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected.name}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {false ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="navi navi-hover">
            {
              <li className="navi-item py-20px px-24px">
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Company" value={search} onChange={(e) => onSearch(e.target.value)} />
              </li>
            }
            <Dropdown.Divider className="m-0" />
            <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {options && options.length === 0 ? (
                <div className="w-100 d-flex flex-center h-50px-mn">
                  <span>No Company</span>
                </div>
              ) : (
                options.map((each, index) => {
                  return (
                    <Dropdown.Item
                      className="dropdown-hover"
                      key={index}
                      onSelect={() => {
                        setSelected(each);
                        setValue(each, field.apiKeyName);
                      }}
                    >
                      {each.name}
                    </Dropdown.Item>
                  );
                })
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
