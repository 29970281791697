import { updateCadenceStep, AddStepInCadence, deleteCadenceStep, swapCadenceSteps, moveCadenceSteps } from "../types/cadence.types";

export const updateCadenceStepRequest = (payload, cb) => ({
  type: updateCadenceStep.UPDATE_CADENCE_STEP_REQUEST,
  payload,
  cb,
});
export const updateCadenceStepSuccess = (payload) => ({
  type: updateCadenceStep.UPDATE_CADENCE_STEP_SUCCESS,
  payload,
});
export const updateCadenceStepError = (payload) => ({
  type: updateCadenceStep.UPDATE_CADENCE_STEP_ERROR,
  payload,
});

export const AddStepInCadenceRequest = (payload, cb) => ({
  type: AddStepInCadence.ADD_STEP_IN_CADENCE_REQUEST,
  payload,
  cb,
});
export const AddStepInCadenceSuccess = (payload) => ({
  type: AddStepInCadence.ADD_STEP_IN_CADENCE_SUCCESS,
  payload,
});
export const AddStepInCadenceError = (payload) => ({
  type: AddStepInCadence.ADD_STEP_IN_CADENCE_ERROR,
  payload,
});

export const deleteCadenceStepRequest = (payload, cb) => ({
  type: deleteCadenceStep.DELETE_CADENCE_STEP_REQUEST,
  payload,
  cb,
});
export const deleteCadenceStepSuccess = (payload) => ({
  type: deleteCadenceStep.DELETE_CADENCE_STEP_SUCCESS,
  payload,
});
export const deleteCadenceStepError = (payload) => ({
  type: deleteCadenceStep.DELETE_CADENCE_STEP_ERROR,
  payload,
});

export const swapCadenceStepsRequest = (payload, cb) => ({
  type: swapCadenceSteps.SWAP_CADENCE_STEPS_REQUEST,
  payload,
  cb,
});
export const swapCadenceStepsSuccess = (payload) => ({
  type: swapCadenceSteps.SWAP_CADENCE_STEPS_SUCCESS,
  payload,
});
export const swapCadenceStepsError = (payload) => ({
  type: swapCadenceSteps.SWAP_CADENCE_STEPS_ERROR,
  payload,
});

export const moveCadenceStepsRequest = (payload, cb) => ({
  type: moveCadenceSteps.MOVE_CADENCE_STEPS_REQUEST,
  payload,
  cb,
});
export const moveCadenceStepsSuccess = (payload) => ({
  type: moveCadenceSteps.MOVE_CADENCE_STEPS_SUCCESS,
  payload,
});
export const moveCadenceStepsError = (payload) => ({
  type: moveCadenceSteps.MOVE_CADENCE_STEPS_ERROR,
  payload,
});
