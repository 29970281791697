
export const getListOfWhatsappTemplates = {
    REQUEST: "GET_LIST_OF_WHATSAPP_TEMPLATES_REQUEST",
    SUCCESS: "GET_LIST_OF_WHATSAPP_TEMPLATES_SUCCESS",
    FAILURE: "GET_LIST_OF_WHATSAPP_TEMPLATES_FAILURE",
}

export const getListOfWhatsappTemplatesBySearch = {
    REQUEST: "GET_LIST_OF_WHATSAPP_TEMPLATES_SEARCH_REQUEST",
    SUCCESS: "GET_LIST_OF_WHATSAPP_TEMPLATES_SEARCH_SUCCESS",
    FAILURE: "GET_LIST_OF_WHATSAPP_TEMPLATES_SEARCH_FAILURE",
}

export const createWhatsappTemplate = {
    REQUEST: "CREATE_WHATSAPP_TEMPLATE_REQUEST",
    SUCCESS: "CREATE_WHATSAPP_TEMPLATE_SUCCESS",
    FAILURE: "CREATE_WHATSAPP_TEMPLATE_FAILURE",
}

export const updateWhatsappTemplate = {
    REQUEST: "UPDATE_WHATSAPP_TEMPLATE_REQUEST",
    SUCCESS: "UPDATE_WHATSAPP_TEMPLATE_SUCCESS",
    FAILURE: "UPDATE_WHATSAPP_TEMPLATE_FAILURE",
}

export const deleteWhatsappTemplate = {
    REQUEST: "DELETE_WHATSAPP_TEMPLATE_REQUEST",
    SUCCESS: "DELETE_WHATSAPP_TEMPLATE_SUCCESS",
    FAILURE: "DELETE_WHATSAPP_TEMPLATE_FAILURE",
}

export const syncWithFacebookWhatsappTemplates = {
    REQUEST: "SYNC_WITH_FACEBOOK_WHATSAPP_TEMPLATES_REQUEST",
    SUCCESS: "SYNC_WITH_FACEBOOK_WHATSAPP_TEMPLATES_SUCCESS",
    FAILURE: "SYNC_WITH_FACEBOOK_WHATSAPP_TEMPLATES_FAILURE",
}


