import { combineReducers } from "redux";
import companySettingsReducer from "./company-settings.reducer";
import currencySettingsReducer from "./currency-settings.reducer";
import activitySettingsReducer from "./activity-settings.reducer";
import customFieldsSettingsReducer from "./custom-fields-settings.reducer";
import developerSettingsReducer from "./developer-settings.reducer";
import manageUsersSettingsReducer from "./manage-users-settings.reducer";
import outcomesSettingsReducer from "./outcomes-settings.reducer";
import securitySettingsReducer from "./security-settings.reducer";
import tagSettingsReducer from "./tag-settings.reducer";
import teamsSettingsReducer from "./teams-settings.reducer";

const adminSettingsReducer = combineReducers({
  company: companySettingsReducer,
  currency: currencySettingsReducer,
  activity: activitySettingsReducer,
  customFields: customFieldsSettingsReducer,
  developer: developerSettingsReducer,
  manageUsers: manageUsersSettingsReducer,
  outcomes: outcomesSettingsReducer,
  security: securitySettingsReducer,
  tag: tagSettingsReducer,
  teams: teamsSettingsReducer,
});

export default adminSettingsReducer;
