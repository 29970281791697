import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SummaryReport from "./containers/summary-report";
import DownloadReport from "./containers/download-report";
import AdvancedLogs from "./containers/advanced-logs";

export default function CampaignReports(props) {
  const [selectedMenu, setSelectedMenu] = useState("Summary Report");

  const params = useParams();

  useEffect(() => {
    if (params.reportType === "download-report") {
      setSelectedMenu("Download Report");
    } else if (params.reportType === "logs") {
      setSelectedMenu("Logs");
    } else {
      setSelectedMenu("Summary Report");
    }
  }, [params]);

  return (
    <>
      {selectedMenu === "Summary Report" && <SummaryReport tabName={selectedMenu} />}
      {selectedMenu === "Download Report" && <DownloadReport tabName={selectedMenu} />}
      {selectedMenu === "Logs" && <AdvancedLogs tabName={selectedMenu} />}
    </>
  );
}
