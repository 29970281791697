import { combineReducers } from "redux";
import videoReducer from "./video.reducer";
import videoCategoryReducer from "./category.reducer";
import selectedReducer from "./selected.reducer";

const learnModuleReducer = combineReducers({
  video: videoReducer,
  selected: selectedReducer,
  category: videoCategoryReducer,
});

export default learnModuleReducer;
