export const whatsappInputMaxLength = {
    WHATSAPP_HEADER_TEXT: 60,
    WHATSAPP_BODY_TEXT: 1024,
    WHATSAPP_FOOTER_TEXT: 60,
}
export const whatsappCategoryOptions = [
    { value: "MARKETING", label: "Marketing" },
    { value: "UTILITY", label: "Utility / Transactional" },
]

export const whatsappLanguageOptions = [
    { value: "af", label: "Afrikaans" },
    { value: "sq", label: "Albanian" },
    { value: "ar", label: "Arabic" },
    { value: "az", label: "Azerbaijani" },
    { value: "bn", label: "Bengali" },
    { value: "bg", label: "Bulgarian" },
    { value: "ca", label: "Catalan" },
    { value: "zh_CN", label: "Chinese (CHN)" },
    { value: "zh_HK", label: "Chinese (HKG)" },
    { value: "zh_TW", label: "Chinese (TAI)" },
    { value: "hr", label: "Croatian" },
    { value: "cs", label: "Czech" },
    { value: "da", label: "Danish" },
    { value: "nl", label: "Dutch" },
    { value: "en", label: "English" },
    { value: "en_GB", label: "English (UK)" },
    { value: "en_US", label: "English (US)" },
    { value: "et", label: "Estonian" },
    { value: "fil", label: "Filipino" },
    { value: "fi", label: "Finnish" },
    { value: "fr", label: "French" },
    { value: "ka", label: "Georgian" },
    { value: "de", label: "German" },
    { value: "el", label: "Greek" },
    { value: "gu", label: "Gujarati" },
    { value: "ha", label: "Hausa" },
    { value: "he", label: "Hebrew" },
    { value: "hi", label: "Hindi" },
    { value: "hu", label: "Hungarian" },
    { value: "id", label: "Indonesian" },
    { value: "ga", label: "Irish" },
    { value: "it", label: "Italian" },
    { value: "ja", label: "Japanese" },
    { value: "kn", label: "Kannada" },
    { value: "kk", label: "Kazakh" },
    { value: "rw_RW", label: "Kinyarwanda" },
    { value: "ko", label: "Korean" },
    { value: "ky_KG", label: "Kyrgyz (Kyrgyzstan)" },
    { value: "lo", label: "Lao" },
    { value: "lv", label: "Latvian" },
    { value: "lt", label: "Lithuanian" },
    { value: "mk", label: "Macedonian" },
    { value: "ms", label: "Malay" },
    { value: "ml", label: "Malayalam" },
    { value: "mr", label: "Marathi" },
    { value: "nb", label: "Norwegian" },
    { value: "fa", label: "Persian" },
    { value: "pl", label: "Polish" },
    { value: "pt_BR", label: "Portuguese (BR)" },
    { value: "pt_PT", label: "Portuguese (POR)" },
    { value: "pa", label: "Punjabi" },
    { value: "ro", label: "Romanian" },
    { value: "ru", label: "Russian" },
    { value: "sr", label: "Serbian" },
    { value: "sk", label: "Slovak" },
    { value: "sl", label: "Slovenian" },
    { value: "es", label: "Spanish" },
    { value: "es_AR", label: "Spanish (ARG)" },
    { value: "es_ES", label: "Spanish (SPA)" },
    { value: "es_MX", label: "Spanish (MEX)" },
    { value: "sw", label: "Swahili" },
    { value: "sv", label: "Swedish" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
    { value: "th", label: "Thai" },
    { value: "tr", label: "Turkish" },
    { value: "uk", label: "Ukrainian" },
    { value: "ur", label: "Urdu" },
    { value: "uz", label: "Uzbek" },
    { value: "vi", label: "Vietnamese" },
    { value: "zu", label: "Zulu" },

]

export const whatsappHeaderTypeOptions = [
    { value: "none", label: "None" },
    { value: "text", label: "Text" },
    { value: "media", label: "Media" },
]
export const whatsappHeaderMediaOptions = [
    { value: "IMAGE", label: "Image" },
    { value: "VIDEO", label: "Video" },
    { value: "DOCUMENT", label: "Document" },
]

export const whatsappButtonTypeOptions = [
    { value: "none", label: "None" },
    { value: "cta", label: "Call To Action" },
    { value: "quick", label: "Quick Reply" },
]