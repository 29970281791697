import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LearnDescription } from "./description";
import { LearnList } from "./list";

export default function LearnPageRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/public`} component={LearnList} />
        <Route exact path={`${match.url}/company`} component={LearnList} />
        <Route exact path={`${match.url}/public/:videoId`} component={LearnDescription} />
        <Route exact path={`${match.url}/company/:videoId`} component={LearnDescription} />
        <Redirect to={`${match.url}/public`} />
      </Switch>
    </>
  );
}
