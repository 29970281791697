export const visibilityOptions = [
  { value: "public", label: "Public" },
  { value: "team", label: "Team Only" },
  { value: "private", label: "Private" },
];

export const emailTemplateOptions = [
  { label: "Email Text Editor", value: "tiny_cloud" },
  { label: "Drag & Drop Builder", value: "unlayer" },
];
