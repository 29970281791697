import React from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import "../../../../../../_metronic/_assets/sass/pages/error/error-3.scss";
import InlineStyled from "../../../../../../components/inline-styled/inline-styled";

export function AgentNotAllowed() {
    return (
        <div className="d-flex flex-column flex-root h-vh-170px">
            <InlineStyled
                className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                styles={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`,
                }}
            >
                <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                    <h1 className="error-title text-stroke text-transparent">404</h1>
                    <p className="display-4 font-weight-boldest text-white mb-12">How did you get here</p>
                    <p className="font-size-h1 font-weight-boldest text-dark-75">Sorry you are not having access to the page you're looking for.</p>
                    <p className="font-size-h4 line-height-md"></p>
                </div>
            </InlineStyled>
        </div>
    );
}
