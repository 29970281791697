import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { InputLimit, LimitInputNumber } from "../../../../../constants";
import "./styles.scss";
export default function SubscriptionAddOnList({ currencySymbol, planInterval, selectedRecordPlan, selectedEmailSyncPlan, recordCount, setRecordCount, emailSyncCount, setEmailSyncCount }) {
  const [isLimitedDealClaimed, setIsLimitedDealClaimed] = useState(false);
  const [showAdditionalRecordsAddon] = useState(false);

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    if (accountBillingStore.data && accountBillingStore.data.isSubscribedForLifeTimeFree) {
      setIsLimitedDealClaimed(true);
    } else {
      setIsLimitedDealClaimed(false);
    }
  }, [accountBillingStore.data]);

  const onAddClick = (type) => {
    if (type === "records") {
      setRecordCount(recordCount ? parseInt(recordCount) + 1 : 1);
    } else if (type === "emailSync" && emailSyncCount < 3) {
      setEmailSyncCount(emailSyncCount ? parseInt(emailSyncCount) + 1 : 1);
    }
  };
  const onMinusClick = (type) => {
    if (type === "records" && recordCount > 0) {
      setRecordCount(recordCount ? parseInt(recordCount) - 1 : 0);
    } else if (type === "emailSync" && emailSyncCount > 0) {
      setEmailSyncCount(emailSyncCount ? parseInt(emailSyncCount) - 1 : 0);
    }
  };

  const onRecordCountChange = (value) => {
    if (value >= 0) setRecordCount(value);
    else setRecordCount(0);
  };

  const onEmailSyncCountChange = (value) => {
    if (value >= 0 && value < 4) {
      setEmailSyncCount(value);
    } else setEmailSyncCount(0);
  };

  if (!isLimitedDealClaimed) return null;
  return (
    <div className="subscription-add-list-wrapper">
      <div className="list-title">Add-ons</div>
      <div className="add-on-list-wrapper">
        {showAdditionalRecordsAddon && (
          <div className="add-on-each-list">
            <div className="add-on-each-list-section-1">
              <div className="add-on-list-name">Additional Records</div>
              <div className="add-on-list-description">Increase your data size as your business grows.</div>
              <div className="add-on-calculation-input-wrapper">
                <div>Records</div>
                <div>
                  {currencySymbol}
                  {selectedRecordPlan.amount / 100} x {recordCount}(records)
                </div>
                <div>
                  <strong>
                    {currencySymbol}
                    {(selectedRecordPlan.amount / 100) * recordCount}
                  </strong>
                </div>
              </div>
            </div>
            <div className="add-on-each-list-section-2">
              <div className="add-on-price-wrapper">
                <span className="add-on-price">
                  {currencySymbol}
                  {selectedRecordPlan.amount / 100}
                </span>
                <span className="add-on-interval"> per {planInterval}</span>
              </div>
              <div className="add-on-input-wrapper">
                <div className="add-on-input-button-wrapper">
                  <button className="add-on-input-button" onClick={() => onMinusClick("records")}>
                    -{" "}
                  </button>
                  <Form.Control
                    maxLength={InputLimit.NUMBER}
                    className="line-height-24px internal-input w-50px font-size-20px text-black"
                    value={recordCount}
                    onChange={(e) => onRecordCountChange(LimitInputNumber(e.target.value))}
                    type="number"
                  />
                  <button className="add-on-input-button" onClick={() => onAddClick("records")}>
                    {" "}
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLimitedDealClaimed && (
          <div className="add-on-each-list">
            <div className="add-on-each-list-section-1">
              <div className="add-on-list-name">Email & Calendar Sync</div>
              <div className="add-on-list-description">Engage more people through your email communication.</div>
              <div className="add-on-calculation-input-wrapper">
                <div>Sync Account(s)</div>
                <div>
                  {currencySymbol}
                  {selectedEmailSyncPlan.amount / 100} x {emailSyncCount}(sync account)
                </div>
                <div>
                  <strong>
                    {currencySymbol}
                    {(selectedEmailSyncPlan.amount / 100) * emailSyncCount}
                  </strong>
                </div>
              </div>
            </div>
            <div className="add-on-each-list-section-2">
              <div className="add-on-price-wrapper">
                <span className="add-on-price">
                  {currencySymbol}
                  {selectedEmailSyncPlan.amount / 100}
                </span>
                <span className="add-on-interval"> per {planInterval}</span>
              </div>
              <div className="add-on-input-wrapper">
                <div className="add-on-input-button-wrapper">
                  <button className="add-on-input-button" onClick={() => onMinusClick("emailSync")}>
                    -{" "}
                  </button>
                  <Form.Control
                    maxLength={InputLimit.NUMBER}
                    className="line-height-24px internal-input w-50px font-size-20px text-black"
                    value={emailSyncCount}
                    onChange={(e) => onEmailSyncCountChange(LimitInputNumber(e.target.value))}
                    type="number"
                  />
                  <button className="add-on-input-button" onClick={() => onAddClick("emailSync")}>
                    {" "}
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
