const currency = [
  { label: "AED - United Arab Emirates Dirham", value: "AED" },
  { label: "AFN - Afghan Afghani", value: "AFN" },
  { label: "ALL - Albanian Lek", value: "ALL" },
  { label: "AMD - Armenian Dram", value: "AMD" },
  { label: "ANG - US Dollar", value: "ANG" },
  { label: "ANG - East Caribbean dollar", value: "ANG" },
  { label: "ARS - Argentine Peso", value: "ARS" },
  { label: "AUD - Australian Dollar", value: "AUD" },
  { label: "AZN - Azerbaijani Manat", value: "AZN" },
  { label: "BAM - Bosnia-Herzegovina Convertible Mark", value: "BAM" },
  { label: "BDT - Bangladeshi Taka", value: "BDT" },
  { label: "BGN - Bulgarian Lev", value: "BGN" },
  { label: "BHD - Bahraini Dinar", value: "BHD" },
  { label: "BIF - Burundian Franc", value: "BIF" },
  { label: "BND - Brunei Dollar", value: "BND" },
  { label: "BOB - Bolivian Boliviano", value: "BOB" },
  { label: "BRL - Brazilian Real", value: "BRL" },
  { label: "BWP - Botswanan Pula", value: "BWP" },
  { label: "BYN - Belarusian Ruble", value: "BYN" },
  { label: "BZD - Belize Dollar", value: "BZD" },
  { label: "CAD - Canadian Dollar", value: "CAD" },
  { label: "CDF - Congolese Franc", value: "CDF" },
  { label: "CHF - Swiss Franc", value: "CHF" },
  { label: "CLP - Chilean Peso", value: "CLP" },
  { label: "CNY - Chinese Yuan", value: "CNY" },
  { label: "COP - Colombian Peso", value: "COP" },
  { label: "CRC - Costa Rican Col√≥n", value: "CRC" },
  { label: "CVE - Cape Verdean Escudo", value: "CVE" },
  { label: "CZK - Czech Republic Koruna", value: "CZK" },
  { label: "DJF - Djiboutian Franc", value: "DJF" },
  { label: "DKK - Danish Krone", value: "DKK" },
  { label: "DOP - Dominican Peso", value: "DOP" },
  { label: "DZD - Algerian Dinar", value: "DZD" },
  { label: "EEK - Estonian Kroon", value: "EEK" },
  { label: "EGP - Egyptian Pound", value: "EGP" },
  { label: "ERN - Eritrean Nakfa", value: "ERN" },
  { label: "ETB - Ethiopian Birr", value: "ETB" },
  { label: "EUR - Euro", value: "EUR" },
  { label: "GBP - British Pound Sterling", value: "GBP" },
  { label: "GEL - Georgian Lari", value: "GEL" },
  { label: "GGP - Guernsey Pound", value: "GGP" },
  { label: "GHS - Ghanaian Cedi", value: "GHS" },
  { label: "GNF - Guinean Franc", value: "GNF" },
  { label: "GTQ - Guatemalan Quetzal", value: "GTQ" },
  { label: "HKD - Hong Kong Dollar", value: "HKD" },
  { label: "HNL - Honduran Lempira", value: "HNL" },
  { label: "HRK - Croatian Kuna", value: "HRK" },
  { label: "HUF - Hungarian Forint", value: "HUF" },
  { label: "IDR - Indonesian Rupiah", value: "IDR" },
  { label: "ILS - Israeli New Sheqel", value: "ILS" },
  { label: "IMP - Isle of Man Pound", value: "IMP" },
  { label: "INR - Indian Rupee", value: "INR" },
  { label: "IQD - Iraqi Dinar", value: "IQD" },
  { label: "IRR - Iranian Rial", value: "IRR" },
  { label: "ISK - Icelandic Kr√≥na", value: "ISK" },
  { label: "JEP - Jersey pound", value: "JEP" },
  { label: "JMD - Jamaican Dollar", value: "JMD" },
  { label: "JOD - Jordanian Dinar", value: "JOD" },
  { label: "JPY - Japanese Yen", value: "JPY" },
  { label: "KES - Kenyan Shilling", value: "KES" },
  { label: "KHR - Cambodian Riel", value: "KHR" },
  { label: "KMF - Comorian Franc", value: "KMF" },
  { label: "KPW - North Korean won", value: "KPW" },
  { label: "KRW - South Korean Won", value: "KRW" },
  { label: "KWD - Kuwaiti Dinar", value: "KWD" },
  { label: "KZT - Kazakhstani Tenge", value: "KZT" },
  { label: "LAK - Lao kip", value: "LAK" },
  { label: "LBP - Lebanese Pound", value: "LBP" },
  { label: "LKR - Sri Lankan Rupee", value: "LKR" },
  { label: "LTL - Lithuanian Litas", value: "LTL" },
  { label: "LVL - Latvian Lats", value: "LVL" },
  { label: "LYD - Libyan Dinar", value: "LYD" },
  { label: "MAD - Moroccan Dirham", value: "MAD" },
  { label: "MDL - Moldovan Leu", value: "MDL" },
  { label: "MGA - Malagasy Ariary", value: "MGA" },
  { label: "MKD - Macedonian Denar", value: "MKD" },
  { label: "MMK - Myanma Kyat", value: "MMK" },
  { label: "MOP - Macanese Pataca", value: "MOP" },
  { label: "MUR - Mauritian Rupee", value: "MUR" },
  { label: "MXN - Mexican Peso", value: "MXN" },
  { label: "MYR - Malaysian Ringgit", value: "MYR" },
  { label: "MZN - Mozambican Metical", value: "MZN" },
  { label: "NAD - Namibian Dollar", value: "NAD" },
  { label: "NGN - Nigerian Naira", value: "NGN" },
  { label: "NIO - Nicaraguan C√≥rdoba", value: "NIO" },
  { label: "NOK - Norwegian Krone", value: "NOK" },
  { label: "NPR - Nepalese Rupee", value: "NPR" },
  { label: "NZD - New Zealand Dollar", value: "NZD" },
  { label: "OMR - Omani Rial", value: "OMR" },
  { label: "PAB - Panamanian Balboa", value: "PAB" },
  { label: "PEN - Peruvian Nuevo Sol", value: "PEN" },
  { label: "PHP - Philippine Peso", value: "PHP" },
  { label: "PKR - Pakistani Rupee", value: "PKR" },
  { label: "PLN - Polish Zloty", value: "PLN" },
  { label: "PYG - Paraguayan Guarani", value: "PYG" },
  { label: "QAR - Qatari Rial", value: "QAR" },
  { label: "RON - Romanian Leu", value: "RON" },
  { label: "RSD - Serbian Dinar", value: "RSD" },
  { label: "RUB - Russian Ruble", value: "RUB" },
  { label: "RWF - Rwandan Franc", value: "RWF" },
  { label: "SAR - Saudi Riyal", value: "SAR" },
  { label: "SDG - Sudanese Pound", value: "SDG" },
  { label: "SEK - Swedish Krona", value: "SEK" },
  { label: "SGD - Singapore Dollar", value: "SGD" },
  { label: "SHP - Saint Helena pound", value: "SHP" },
  { label: "SOS - Somali Shilling", value: "SOS" },
  { label: "SYP - Syrian Pound", value: "SYP" },
  { label: "THB - Thai Baht", value: "THB" },
  { label: "TND - Tunisian Dinar", value: "TND" },
  { label: "TOP - Tongan Pa ªanga", value: "TOP" },
  { label: "TRY - Turkish Lira", value: "TRY" },
  { label: "TTD - Trinidad and Tobago Dollar", value: "TTD" },
  { label: "TWD - New Taiwan Dollar", value: "TWD" },
  { label: "TZS - Tanzanian Shilling", value: "TZS" },
  { label: "UAH - Ukrainian Hryvnia", value: "UAH" },
  { label: "UGX - Ugandan Shilling", value: "UGX" },
  { label: "USD - US Dollar", value: "USD" },
  { label: "UYU - Uruguayan Peso", value: "UYU" },
  { label: "UZS - Uzbekistan Som", value: "UZS" },
  { label: "VEF - Venezuelan Bol√≠var", value: "VEF" },
  { label: "VND - Vietlabelse Dong", value: "VND" },
  { label: "XAF - CFA Franc BEAC", value: "XAF" },
  { label: "XOF - CFA Franc BCEAO", value: "XOF" },
  { label: "YER - Yemeni Rial", value: "YER" },
  { label: "ZAR - South African Rand", value: "ZAR" },
  { label: "ZMK - Zambian Kwacha", value: "ZMK" },
  { label: "ZWL - Zimbabwean Dollar", value: "ZWL" },
];

export default {
  currency,
};
