import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GreenGradientButton } from "../../../components/button";
import SimpleDropdown from "../../../components/dropdowns/simple-dropdown";
import { InputLimit } from "../../../constants";
import { dispatchSnackbarError } from "../../../utils/toaster";
import validator from "validator";
import * as CampaignListActions from "../store/actions/campaign_details_actions";
import "./styles.scss";

export default function SendTestEmailPopOver(props) {
  const [fromEmailOptions, setFromEmailOptions] = useState([]);
  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");

  const dispatch = useDispatch();

  const domainStore = useSelector((store) => store.campaign.settings.email.domain);

  useEffect(() => {
    if (!isEmpty(domainStore.list)) {
      const options = [];
      domainStore.list.forEach((domain) => {
        const _split = domain.domain.split(" ");
        if (_split.length === 2) {
          const domainEmail = _split[1].replace(/\(|\)/g, "");
          options.push({ label: domainEmail, value: domainEmail });
        }
      });
      setFromEmailOptions(options);
    }
  }, [domainStore.list]);

  useEffect(() => {
    if (!isEmpty(props.config)) {
      if (props.config.fromName) {
        setFromName(props.config.fromName);
      }
      if (props.config.fromEmail) {
        setFromEmail(props.config.fromEmail);
      }
      if (props.config.subject) {
        setSubject(props.config.subject);
      }
    }
  }, [props.config]);

  const handleClose = () => {
    document.body.click();
  };

  const handleSend = () => {
    if (!fromName || !fromEmail || !to || !subject) {
      return dispatchSnackbarError("Please fill data in all provide fields");
    } else if (!validator.isEmail(to)) {
      return dispatchSnackbarError("Please enter valid email address");
    }

    const payload = {
      subject: subject,
      to: to,
      templateId: props.templateId,
      fromName: fromName,
      fromEmail: fromEmail,
      attachments: props.attachments && Array.isArray(props.attachments) ? props.attachments.map((each) => each.fileid) : [],
    };
    dispatch(CampaignListActions.sendTestEmailRequest(payload));
    document.body.click();
  };

  const popoverForm = (
    <Popover className="sent-test-email-popover z-index-1004 w-500px" size="lg">
      <Popover.Title className="d-flex flex-stack align-items-center" as="h3">
        <span>Send Test Email</span>
        <span title="close" className="cursor-pointer" onClick={handleClose}>
          <i className="bi bi-x color-black"></i>
        </span>
      </Popover.Title>
      <Popover.Content className="w-500px">
        <Form.Group id="campaign-components-send-test-email-popover-email-address">
          <Form.Label aria-label="from-email-address">From Email Address</Form.Label>
          <SimpleDropdown value={fromEmail ? { value: fromEmail, label: fromEmail } : {}} label={"Select From Email"} options={fromEmailOptions} handleSelect={(a) => setFromEmail(a.value)} />
        </Form.Group>
        <Form.Group id="campaign-components-send-test-email-popover-receipient-name">
          <Form.Label aria-label="to">To</Form.Label>
          <Form.Control maxLength={InputLimit.TEXT} value={to} onChange={(e) => setTo(e.target.value)} />
        </Form.Group>
        <Form.Group id="campaign-components-send-test-email-popover-sender-name">
          <Form.Label aria-label="from-name">From Name</Form.Label>
          <Form.Control maxLength={InputLimit.TEXT} value={fromName} onChange={(e) => setFromName(e.target.value)} />
        </Form.Group>
        <Form.Group id="campaign-components-send-test-email-popover-email-subject">
          <Form.Label aria-label="subject">Subject</Form.Label>
          <Form.Control maxLength={InputLimit.TEXT} value={subject} onChange={(e) => setSubject(e.target.value)} />
        </Form.Group>
        <Form.Group id="campaign-components-send-test-email-popover-button" className="d-flex gap-3 justify-content-end">
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <GreenGradientButton size="sm" onClick={handleSend}>
            Send
          </GreenGradientButton>
        </Form.Group>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={"click"} autoClose={true} placement="auto" overlay={popoverForm} rootClose={true}>
      {props.showAsButton ? (
        <Button size="sm" variant="secondary">
          Send Test Email
        </Button>
      ) : (
        <span className="text-primary cursor-pointer">Send Test Email</span>
      )}
    </OverlayTrigger>
  );
}
