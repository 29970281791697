import React, { useEffect, useState } from "react";
import { CompanyDealLink } from "../components/company-deal-link";
import { CompanyLeadLink } from "../components/company-lead-link";
import { CompanyPeopleLink } from "../components/company-people-link";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { CompanyDetails } from "../components/company-details.component";
import { reorder } from "./helper";

export function CompanyLeftPannel(props) {
  const [order, setOrder] = useState(["company", "company-lead-link", "company-deal-link", "company-people-link"]);
  const [viewMoreOrder, setViewMoreOrder] = useState({ company: false, lead: false, deal: false, people: false });

  useEffect(() => {
    const _order = localStorage.getItem("company-popup-order");
    if (_order) {
      setOrder(_order.split(","));
    } else {
      setOrder(["company", "company-lead-link", "company-deal-link", "company-people-link"]);
    }
  }, []);

  useEffect(() => {
    const _order = localStorage.getItem("company-popup-view-more-order");
    if (_order) {
      const _parse = JSON.parse(_order);
      if (Object.keys(_parse).length === 4) {
        setViewMoreOrder(_parse);
      } else {
        setViewMoreOrder({ company: false, lead: false, deal: false, people: false });
      }
    } else {
      setViewMoreOrder({ company: false, lead: false, deal: false, people: false });
    }
  }, []);

  const handleDragEnd = (param) => {
    const a = reorder(order, param.source.index, param.destination.index);
    setOrder(a);
    localStorage.setItem("company-popup-order", a.join(","));
  };

  const handleViewMoreChange = (module, status) => {
    const _viewMoreOrder = { ...viewMoreOrder };
    _viewMoreOrder[module] = status;
    setViewMoreOrder(_viewMoreOrder);
    localStorage.setItem("company-popup-view-more-order", JSON.stringify(_viewMoreOrder));
  };

  const company = (index, isLast) => (
    <CompanyDetails
      index={index}
      isLast={isLast}
      page={props.page}
      pageSub={props.pageSub}
      companyId={props.companyId}
      moduleType={props.moduleType}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      showSwitchPerson={false}
      pageForm={props.pageForm}
      viewMore={viewMoreOrder["company"]}
      setViewMore={(status) => handleViewMoreChange("company", status)}
    />
  );

  const companyLeadLink = (index, isLast) => (
    <CompanyLeadLink
      index={index}
      isLast={isLast}
      moduleType={props.moduleType}
      companyId={props.companyId}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      viewMore={viewMoreOrder["lead"]}
      setViewMore={(status) => handleViewMoreChange("lead", status)}
    />
  );
  const companyDealLink = (index, isLast) => (
    <CompanyDealLink
      index={index}
      isLast={isLast}
      moduleType={props.moduleType}
      companyId={props.companyId}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      viewMore={viewMoreOrder["deal"]}
      setViewMore={(status) => handleViewMoreChange("deal", status)}
    />
  );

  const companyPeopleLink = (index, isLast) => (
    <CompanyPeopleLink
      index={index}
      isLast={isLast}
      moduleType={props.moduleType}
      companyId={props.companyId}
      basePath={props.basePath}
      sidePanelProps={props.sidePanelProps}
      popupLoading={props.popupLoading}
      popupCellLoading={props.popupCellLoading}
      viewMore={viewMoreOrder["people"]}
      setViewMore={(status) => handleViewMoreChange("people", status)}
    />
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-side-panel">
        {(provided) => (
          <div className="w-100 h-100" ref={provided.innerRef} {...provided.droppableProps}>
            {order.map((each, index) => {
              if (each === "company") return company(index, order.length === index + 1);
              else if (each === "company-lead-link") return companyLeadLink(index, order.length === index + 1);
              else if (each === "company-deal-link") return companyDealLink(index, order.length === index + 1);
              else if (each === "company-people-link") return companyPeopleLink(index, order.length === index + 1);
              else return <div key={index}></div>;
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
