import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ContentWrapper from "../../../Wrapper";
import { CreateCampaignModal } from "../../components/create-campaign-modal";
import CampaignSubHeader from "../../components/campaign-common-sub-header";
import { BlueGradientButton } from "../../../../components/button";
import { campaignTableActions, campaignTableFieldDataType, campaignTableMapping, campaingTableFields } from "../../constants";

import { ContentWithCardLoading } from "../../../../components/loading";
import { CampaignListTable } from "./table";
import CustomSidePanel from "../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import DetailedAnalyticsReport from "../campaign-reports/containers/detailed-analytics-report/index";
import ViewEmailDetailedReport from "../campaign-reports/containers/view-detailed-report";
import { CustomModal, DeleteModal, ErrorLogModal } from "../../../../components/modal";
import { Form } from "react-bootstrap";
import TimeZoneSelect from "../../../../components/custom_form/timezone";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import SplitDetailsComponent from "./split-details";
import ErrorOutlineGIF from "../../../../images/gif/1140-error-outline.gif";
import EnvelopeOutlineGIF from "../../../../images/gif/177-envelope-mail-send-outline.gif";
import ScheduledGIF from "../../../../images/gif/schedule-gif.gif";

//actions
import * as walletActions from "../../../Settings/Billing/store/actions/wallet_billing_actions";
import * as CampaignListActions from "../../store/actions/campaign_details_actions";
import * as CampaignReportActions from "../../store/actions/campaign_report_actions";

import { IconButton2 } from "../../../../components/button/icon-action-buttons";
import { useHistory } from "react-router-dom";
import CampaignListFilter from "./camapign-list-filter";
import { getListOfWhatsappAccountsRequest } from "../../store/actions/campaign_whatsapp_setting_actions";

var timer;

export default function CampaignList(props) {
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [show, setShow] = useState(false);
  const [showDetailedAnalytics, setShowDetailedAnalytics] = useState(false);
  const [showDetailedReport, setShowDetailedReport] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [showLogModal, setShowLogModal] = useState(false);
  const [logData, setLogData] = useState("");
  const [showSplitDetails, setShowSplitDetails] = useState(false);
  const [splitDetails, setSplitDetails] = useState({});
  const [campaignId, setCampaignId] = useState("");
  const [showCancelCampaign, setShowCancelCampaign] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [selectedScheduledDate, setSelectedScheduledDate] = useState(null);

  //rescheduling
  const [showReschedule, setShowReschedule] = useState(false);
  const [rescheduledDate, setRescheduledDate] = useState(null);
  const [rescheduledTimezone, setRescheduledTimezone] = useState("");

  // wallet info
  const [balance, setBalance] = useState("0");
  const [currency, setCurrency] = useState("$");
  const [planCurrency, setPlanCurrency] = useState("");

  //timers
  const [timerSec, setTimerSec] = useState(1000);

  //filters
  const [showFilter, setShowFilter] = useState(false);
  const [initalFilterValue, setInitialFilterValue] = useState({
    channel: "all",
    owner: "all",
    fromDate: moment()
      .subtract(3, "months")
      .startOf("days")
      .valueOf(),
    toDate: moment()
      .add(1, "day")
      .endOf("day")
      .valueOf(),
  });

  const tabName = "Campaigns";

  const history = useHistory();
  const dispatch = useDispatch();

  const campaignListStore = useSelector((store) => store.campaign.list);
  const walletInfoStore = useSelector((store) => store.Billing.wallet.info);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    dispatch(walletActions.getWalletInfoRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getListOfWhatsappAccountsRequest())
  }, [dispatch])


  useEffect(() => {
    const newTimer = setTimeout(() => setTimerSec(4000), 5000);
    return () => {
      clearTimeout(newTimer);
    };
  }, []);

  useEffect(() => {
    const payload = {
      limit: limit,
      pageNo: pageNo,
      ...initalFilterValue,
    };
    clearInterval(timer);
    if (timerSec === 1000) {
      dispatch(CampaignListActions.getCampaignListRequest(payload));
    }
    timer = setInterval(() => {
      dispatch(walletActions.getWalletInfoRequest());
      dispatch(CampaignListActions.getCampaignListRequestAfter(payload));
    }, timerSec);
    dispatch(CampaignListActions.getCampaignListRequestAfter(payload));
  }, [dispatch, limit, pageNo, timerSec, initalFilterValue]);

  useEffect(() => {
    if (campaignListStore.data && showSplitDetails && !_.isEmpty(splitDetails)) {
      const find = campaignListStore.data.find((a) => a._id === splitDetails._id);
      if (find) {
        setSplitDetails(find);
      }
    }
  }, [campaignListStore, showSplitDetails, splitDetails]);

  useEffect(() => {
    if (!_.isEmpty(walletInfoStore.data)) {
      const data = walletInfoStore.data;
      try {
        setBalance(parseFloat(data.balance));
      } catch (err) {
        setBalance(data.balance);
      }
    }
  }, [walletInfoStore.data, planCurrency]);

  useEffect(() => {
    if (!_.isEmpty(accountBillingStore.data)) {
      if (accountBillingStore.data.walletCurrency) {
        setCurrency(accountBillingStore.data.walletCurrency.toLowerCase() === "inr" ? "₹" : "$");
        setPlanCurrency(accountBillingStore.data.walletCurrency.toLowerCase());
      } else {
        setCurrency("$");
        setPlanCurrency("usd");
      }
    } else {
      setCurrency("$");
      setPlanCurrency("usd");
    }
  }, [accountBillingStore.data]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onAddCampaignClick = () => {
    setShow(true);
  };

  const handleDetailedAnalytics = (id) => {
    setShowDetailedAnalytics(true);
    setSelectedId(id);
  };
  const handleDetailedAnalyticsErrorScreenClick = (errorType, ErrorLog) => {
    setShowErrorScreen(true);
    setErrorType(errorType);
    setLogData(ErrorLog);
  };
  const handleDetailedAnayticsClose = () => {
    setShowDetailedAnalytics(false);
    setSelectedId("");
  };
  const handleDetailedReportClick = (id) => {
    setSelectedId(id);
    setShowDetailedReport(true);
  };
  const handleDetailedReportClose = () => {
    setShowDetailedReport(false);
    setSelectedId("");
    setShowErrorScreen(false);
    setErrorType("");
    setLogData("");
  };

  const handleRescheduleClick = (id, content) => {
    setShowReschedule(true);
    setRescheduledDate(content.scheduledDate);
    setRescheduledTimezone(content.timezone);
    setCampaignId(content._id);
  };

  const handleRescheduleCancel = () => {
    setShowReschedule(false);
    setRescheduledDate(null);
    setRescheduledTimezone("");
    setCampaignId("");
  };

  const handleRescheduleAccept = () => {
    if (
      moment(rescheduledDate)
        .tz(rescheduledTimezone)
        .valueOf() >
      moment()
        .add(5, "minute")
        .valueOf() &&
      moment(rescheduledDate)
        .tz(rescheduledTimezone)
        .valueOf() <
      moment()
        .add(30, "days")
        .valueOf()
    ) {
      setFormLoading(true);
      dispatch(
        CampaignListActions.rescheduledCamapignRequest(
          {
            body: { scheduledDate: moment(rescheduledDate).tz(rescheduledTimezone), scheduledTimezone: rescheduledTimezone, campaignId: campaignId },
            loadingData: { limit: limit, pageNo: pageNo },
          },
          () => {
            setFormLoading(false);
            handleRescheduleCancel();
          },
        ),
      );
    } else {
      dispatchSnackbarError("Please select valid date.");
    }
  };

  const handleCancelCamapignAccept = () => {
    setFormLoading(true);
    dispatch(
      CampaignListActions.cancelCampaignRequest(
        {
          body: { campaignId: campaignId },
          loadingData: { limit: limit, pageNo: pageNo },
        },
        () => {
          setFormLoading(false);
          handleCancelCamapignClose();
        },
      ),
    );
  };

  const handleCancelCamapignClose = () => {
    setCampaignId("");
    setShowCancelCampaign(false);
  };

  const rescheduledModalProps = {
    showButtons: true,
    show: showReschedule,
    title: "Reschedule Campaign",
    loading: formLoading,
    buttonText: "Save",
    handleClose: handleRescheduleCancel,
    handleAccept: handleRescheduleAccept,
  };
  const deleteTemplateModalProps = {
    show: showCancelCampaign,
    loading: formLoading,
    title: "Cancel Campaign",
    deleteName: "Cancel",
    handleClose: () => handleCancelCamapignClose(),
    handleAccept: () => handleCancelCamapignAccept(),
  };

  const handleCancelCamapign = (id) => {
    setCampaignId(id);
    setShowCancelCampaign(true);
  };

  const handleMenuSectionClick = (action, content) => {
    switch (action) {
      case "scheduled":
        return handleRescheduleClick(content._id, content);
      case "cancel":
        return handleCancelCamapign(content._id);
      case "error":
        setShowLogModal(true);
        setLogData(content.error);
        break;
      case "anaytics":
        return handleDetailedAnalytics(content._id);
      case "report":
        return handleDetailedReportClick(content._id);
      case "split-details":
        setShowSplitDetails(true);
        setSplitDetails(content);
        break;
      case "download":
        const payload = { campaignId: content._id, channel: content.channel, type: content.type };
        dispatch(
          CampaignReportActions.downloadCampaignReportsRequest(payload, (data) => {
            if (data.status) {
              dispatchSnackbarSuccess("Succefully started download campaign.");
              history.push("/campaign/reports/download-report");
            }
          }),
        );
        break;
      case "repeat-campaign":
        const campaignDetailedData = campaignListStore.detailedData.find((a) => a._id === content._id);
        const campaignChannel = campaignDetailedData.channel;
        history.push(`/campaign/builder/${campaignChannel}`, { type: "repeat-campaign", data: campaignDetailedData });
        break;
      default:
        break;
    }
  };

  const tableRowClick = (content) => {
    if (content.status === "cancelled") {
      return handleDetailedAnalyticsErrorScreenClick("cancelled", content.error);
    } else if (content.status === "failed") {
      return handleDetailedAnalyticsErrorScreenClick("failed", content.error);
    } else if (content.status !== "completed" && content.timezone && content.scheduledDate && content.isScheduled) {
      if (
        moment(content.scheduledDate)
          .tz(content.timezone)
          .valueOf() >
        moment()
          .add("minutes")
          .valueOf()
      ) {
        setSelectedScheduledDate(content.scheduledDate);
        return handleDetailedAnalyticsErrorScreenClick("scheduled", "");
        // return handleDetailedAnalyticsErrorScreenClick("scheduled", content.error);
      } else {
        return handleDetailedAnalytics(content._id);
      }
    } else {
      return handleDetailedAnalytics(content._id);
    }
  };

  const onDownloadLogs = () => {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(logData));
    element.setAttribute("download", "error-transcript.txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleApplyFilter = (data) => {
    setShowFilter(false);
    setInitialFilterValue(data);
  };

  const handleCancelFilter = () => {
    setShowFilter(false);
  };

  return (
    <>
      <ContentWrapper
        subHeader={
          <CampaignSubHeader active={tabName}>
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-2">
                Wallet Balance:{" "}
                <span className="text-3699ff">
                  {currency}
                  {balance}
                </span>
                <IconButton2 className={"py-0 px-7px fs-2 text-secondary bg-hover-light-secondary rounded-circle cursor-pointer"} tooltip={"Add Wallet Balance"} onClick={() => history.push("/settings/billing/wallet")}>
                  +
                </IconButton2>
              </div>
              <BlueGradientButton variant="success" size="sm" onClick={() => onAddCampaignClick()}>
                + Campaign
              </BlueGradientButton>
              <CampaignListFilter show={showFilter} setShow={setShowFilter} filterValue={initalFilterValue} handleApplyFilter={handleApplyFilter} handleCancelFilter={handleCancelFilter} />
            </div>
          </CampaignSubHeader>
        }
      >
        {campaignListStore.loading ? (
          <ContentWithCardLoading />
        ) : campaignListStore.data.length === 0 ? (
          <CustomEmptyPage page="campaign" setShowAdd={() => onAddCampaignClick()} />
        ) : (
          <CampaignListTable
            tablehead={campaingTableFields}
            tablecontent={campaignListStore.data.map((each) => {
              each.status = each.status === "proccessing" || each.status === "processing" ? "sending" : each.status;
              return each;
            })}
            mappingTable={campaignTableMapping}
            actions={campaignTableActions}
            dataType={campaignTableFieldDataType}
            pageNo={pageNo}
            limit={limit}
            count={campaignListStore.count}
            setPageNo={setPageNo}
            setLimit={setLimit}
            handleMenuSectionClick={handleMenuSectionClick}
            tableRowClick={tableRowClick}
          />
        )}
      </ContentWrapper>

      <CreateCampaignModal show={show} setShow={setShow} />
      <CustomSidePanel show={showDetailedAnalytics} moduleName={`Detailed Analytics`} handleClose={handleDetailedAnayticsClose} customStyles={{ width: "50vw", right: "51vw" }}>
        <DetailedAnalyticsReport campaignId={selectedId} />
      </CustomSidePanel>
      <CustomSidePanel show={showDetailedReport} moduleName={`Detailed Report`} handleClose={handleDetailedReportClose} customStyles={{ width: "80vw", right: "81vw" }}>
        <ViewEmailDetailedReport campaignId={selectedId} />
      </CustomSidePanel>

      <CustomSidePanel show={showErrorScreen} moduleName={`Detailed Analytics`} handleClose={handleDetailedReportClose} customStyles={{ width: "50vw", right: "51vw" }}>
        <div className="w-100 h-100 d-flex flex-center">
          <div className="d-flex flex-column gap-8 flex-center mx-20 mt-0">
            <div>
              <img
                width={errorType === "scheduled" ? "260px" : "200px"}
                alt="gif"
                src={errorType === "cancelled" || errorType === "failed" ? ErrorOutlineGIF : errorType === "scheduled" ? ScheduledGIF : EnvelopeOutlineGIF}
              />
            </div>
            {logData && <div className="fw-500 text-active-gray-600 fs-5 text-center text-danger">{logData}</div>}
            <div className="fw-500 text-active-gray-600 fs-5 text-center">
              {errorType === "cancelled" || errorType === "failed"
                ? "Ohh, it seems your campaign has not been successful. Please review the error message for further details, or contact the support team for additional information."
                : errorType === "scheduled"
                  ? `Yippee! Your campaign has been scheduled on ${moment(selectedScheduledDate).format("MMM DD, h:mm a")}`
                  : "Sending your campaign... Time for a caffeine boost! 🙂"}
            </div>
          </div>
        </div>
      </CustomSidePanel>

      <CustomModal modalProps={rescheduledModalProps}>
        <Form.Group id="campaign-containers-campaign-list-rescheduled-date">
          <Form.Label aria-label="schedule-date&time">{"Schedule Date & Time"}</Form.Label>
          <Form.Control
            min={moment()
              .add(10, "minute")
              .format("YYYY-MM-DDTHH:ss")}
            max={moment()
              .add(30, "days")
              .format("YYYY-MM-DDTHH:ss")}
            type="datetime-local"
            value={rescheduledDate}
            onChange={(e) => {
              setRescheduledDate(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group id="campaign-containers-campaign-list-rescheduled-time" className="">
          <Form.Label aria-label="timezone">Timezone</Form.Label>
          <TimeZoneSelect field={{ apiKeyName: "timezone", fieldName: "Timezone" }} value={rescheduledTimezone} setValue={setRescheduledTimezone} />
        </Form.Group>
      </CustomModal>
      <ErrorLogModal
        modalProps={{
          show: showLogModal,
          title: "Error Logs",
          handleClose: () => {
            setShowLogModal(false);
          },
          onClickDownloadLog: () => {
            onDownloadLogs();
            setShowLogModal(false);
          },
        }}
      >
        <div className="overflow-auto h-60vh-mx">
          <div className="p-2px">
            <span className="text-019bec">{"> "}</span> {logData}
          </div>
        </div>
      </ErrorLogModal>
      <CustomSidePanel
        show={showSplitDetails}
        moduleName={`Split Campaign Details`}
        handleClose={() => {
          setShowSplitDetails(false);
          setSplitDetails({});
        }}
        customStyles={{ width: "50vw", right: "51vw" }}
      >
        <SplitDetailsComponent splitDetails={splitDetails.splitDetails} campaignDetails={splitDetails} limit={limit} pageNo={pageNo} />
      </CustomSidePanel>
      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to cancel campaign?</DeleteModal>
    </>
  );
}
