import { getCalendarEvent } from "../types/calendar-view.types";

export const getCalendarEventRequest = (req, cb) => ({
  type: getCalendarEvent.GET_CALENDAR_EVENTS_REQUEST,
  payload: req,
  cb,
});
export const getCalendarEventSuccess = (res) => ({
  type: getCalendarEvent.GET_CALENDAR_EVENTS_SUCCESS,
  payload: res,
});
export const getCalendarEventError = (err) => ({
  type: getCalendarEvent.GET_CALENDAR_EVENTS_ERROR,
  payload: err,
});
