import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BlueGradientButton } from "../../components/button";
import { userProfileApi } from "./api-helper";
import "./styles.scss";

export default function OauthConcentScreen(props) {
  // const [showLoginScreen, setShowLoginScreen] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");

    if (access_token) {
      // setShowLoginScreen(false);
      setAccessToken(access_token);
    } else {
      // setShowLoginScreen(true);
      setAccessToken("");
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      updateUserData(accessToken);
    }
  }, [accessToken]);

  const updateUserData = async (accessToken) => {
    const data = await userProfileApi(accessToken);
    if (!_.isEmpty(data) && data.success) {
      setUserData(data.data);
    } else {
      setUserData({});
    }
  };

  return (
    <div className="ss-oauth-container">
      <div className="sso-white-box">
        <div className="sso-content-wrapper">
          <h3 className="pb-10">zapier.com wants access to your salessimplify account</h3>
          <h7 className="pb-10">
            {userData.avatar && (
              <span>
                <img className="rounded-circle" src={userData.avatar} alt="avatar" width={"20px"} height="20px" />
              </span>
            )}
            <span> {userData.email}</span>
          </h7>
          <div className="d-flex gap-4 align-items-center justify-content-center py-6">
            <Button variant="secondary" size="sm" onClick={() => {}}>
              Cancel
            </Button>
            <BlueGradientButton className="cursor-pointer" variant="success" size="sm" onClick={() => {}}>
              Continue
            </BlueGradientButton>
          </div>
        </div>
      </div>
    </div>
  );
}
