import React from "react";
import { Form } from "react-bootstrap";
import { InputLimit, LimitInputNumber } from "../../../../../../../../constants";
import "./styles.scss";

export default function SubscriptionUserList({ currencySymbol, planInterval, agents, setAgents, selectedMainPlan }) {
  const onAddClick = (type) => {
    if (selectedMainPlan.plan === "lifeTimeFree") return;
    if (type === "users") {
      setAgents(agents ? parseInt(agents) + 1 : 1);
    }
  };
  const onMinusClick = (type) => {
    if (selectedMainPlan.plan === "lifeTimeFree") return;
    if (type === "users" && agents > 0) {
      setAgents(agents ? parseInt(agents) - 1 : 0);
    }
  };

  return (
    <div className="subscription-add-list-wrapper">
      <div className="list-title">Add Members</div>
      <div className="add-on-list-wrapper">
        <div className="add-on-each-list">
          <div className="add-on-each-list-section-1">
            <div className="add-on-list-name">Users/Agents</div>
            <div className="add-on-list-description">The below users count have been subscribed. You can Add more or Remove them from the subscription by adjusting your settings on this screen.</div>
            <div className="add-on-calculation-input-wrapper">
              <div>Team Member(s)</div>
              <div>
                {currencySymbol}
                {selectedMainPlan.amount} x {agents}(user)
              </div>
              <div>
                <strong>
                  {currencySymbol}
                  {(selectedMainPlan.amount) * agents}
                </strong>
              </div>
            </div>
          </div>
          <div className="add-on-each-list-section-2">
            <div className="add-on-price-wrapper">
              <span className="add-on-price">
                {currencySymbol}
                {selectedMainPlan.amount}
              </span>
              <span className="add-on-interval"> per {planInterval}</span>
            </div>
            <div className="add-on-input-wrapper">
              <div className="add-on-input-button-wrapper">
                <button className="add-on-input-button" onClick={() => onMinusClick("users")}>
                  -{" "}
                </button>
                <Form.Control
                  maxLength={InputLimit.NUMBER}
                  className="line-height-24px internal-input font-size-20px w-50px text-black"
                  value={agents}
                  onChange={(e) => setAgents(LimitInputNumber(e.target.value))}
                  type="number"
                />
                <button className="add-on-input-button" onClick={() => onAddClick("users")}>
                  {" "}
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
