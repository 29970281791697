import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { InputLimit } from "../../../../../constants";
import { infoTextMessages } from "../constants";
import { getS3SignedUrlForUploadRequest } from "../../../store/actions/profile-actions/shedular-settings.actions";
import { dispatchSnackbarError } from "../../../../../utils/toaster";

export default function PageStylesForm(props) {
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const updateData = (parentKey, key, value) => {
    const data = {
      ...props.data,
      config: {
        ...props.data.config,
        [parentKey]: {
          ...props.data.config[parentKey],
          [key]: value,
        },
      },
    };
    props.setSelectedData(data);
  };

  useEffect(() => {
    const urlRegex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
    if (props.data.config.appearance.logo.match(urlRegex)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [props.data.config.appearance.logo]);

  const handleFileUpload = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      id: props.data.id,
      syncId: props.syncId,
      contentType: e.target.files[0].type,
      objectName: e.target.files[0].name,
    };
    const file = e.target.files[0];
    dispatch(
      getS3SignedUrlForUploadRequest(payload, (data) => {
        if (data.status) {
          // data.data.publicUrl
          // data.data.signedUrl
          const config = {
            method: "put",
            url: data.data.signedUrl,
            headers: {
              "Content-Type": file.type,
            },
            data: file,
          };

          axios(config)
            .then((response) => {
              updateData("appearance", "logo", data.data.publicUrl);
              setLoading(false);
            })
            .catch((error) => {
              console.error("errr", error);
              dispatchSnackbarError("Error occured during upload");
              setLoading(false);
            });
        } else {
          dispatchSnackbarError(data.message);
          setLoading(false);
        }
      }),
    );
  };

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>
            Company Name
            <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.COMPANY_NAME}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.data.config.appearance.company_name} onChange={(e) => updateData("appearance", "company_name", e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Custom Page Slug
            <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.PAGE_SLUG}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.data.slug} onChange={(e) => props.setSelectedData({ ...props.data, slug: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Submit Button Label
            <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.SUBMIT_BUTTON_TEXT}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.data.config.appearance.submit_text} onChange={(e) => updateData("appearance", "submit_text", e.target.value)} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="d-flex flex-column">
          <Form.Label>
            Company Logo
            <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.COMPANY_LOGO}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
            {loading && (
              <span className="ml-3 text-419eff">
                <Spinner animation="border" size="sm" variant="primary" /> Uploading Image...
              </span>
            )}
          </Form.Label>
          {!isValid ? (
            <Form.Group className="d-flex justify-content-between">
              <Form.Control
                placeholder="Enter image link here or click upload"
                type={"text"}
                className="w-100-80px"
                maxLength={InputLimit.URL}
                value={props.data.config.appearance.logo}
                onChange={(e) => updateData("appearance", "logo", e.target.value)}
              ></Form.Control>
              <Form.File className="w-75px cursor-pointer" type="file" label="Upload" accept=".png,.jpg,.jpeg" onChange={handleFileUpload} custom />
            </Form.Group>
          ) : (
            <Form.Group className="leadbot-image-icon-wrapper image-input image-input-outline">
              <img className="image-input-wrapper ldd-image-wrapper h-auto" alt="as" src={props.data.config.appearance.logo} />
              <label
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                onClick={(e) => updateData("appearance", "logo", "")}
                title="Remove Background Image"
              >
                <i className="bi bi-x-circle fs-7"></i>
              </label>
            </Form.Group>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Theme Color
            <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.THEME_COLOR}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type={"color"} maxLength={InputLimit.TEXT} value={props.data.config.appearance.color} onChange={(e) => updateData("appearance", "color", e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Thank You Message
            <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.THANK_YOU_MESSAGE}</Tooltip>}>
              <i className="ml-2 bi bi-info-circle"></i>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.data.config.appearance.thank_you_text} onChange={(e) => updateData("appearance", "thank_you_text", e.target.value)} />
        </Form.Group>
      </Col>
    </Row>
  );
}
