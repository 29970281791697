import React from "react";
import { alphabetColorMapping } from "../../../constants";
import InlineStyled from "../../../components/inline-styled/inline-styled";

export default function AuthorView({ name }) {
  const getAlphabetData = (text) => {
    const firstChar = text.charAt(0).toUpperCase();
    if (alphabetColorMapping[firstChar]) {
      return { colorData: alphabetColorMapping[firstChar], letter: firstChar };
    }
    return { colorData: alphabetColorMapping["0"], letter: firstChar };
  };

  const { colorData, letter } = getAlphabetData(name);

  return (
    <div className="data-container">
      <InlineStyled
        className="d-inline-block mr-2 w-22px h-22px border-radius-50-percent"
        styles={{
          background: colorData["background"],
          color: colorData["color"],
        }}
      >
        <div className="d-flex align-items-center justify-content-center">{letter}</div>
      </InlineStyled>
      <span> {name}</span>
    </div>
  );
}
