import { editWhatsappTemplateAction, getWhatsappTemplateByIdAction, createWhatsappTemplateAction } from "../types";

export const getWhatsappTemplateActionByIdRequest = (payload, cb) => ({
  type: getWhatsappTemplateByIdAction.GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_REQUEST,
  payload,
  cb,
});
export const getWhatsappTemplateActionByIdSuccess = (payload) => ({
  type: getWhatsappTemplateByIdAction.GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_SUCCESS,
  payload,
});
export const getWhatsappTemplateActionByIdError = (payload) => ({
  type: getWhatsappTemplateByIdAction.GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_ERROR,
  payload,
});

export const editWhatsappTemplateActionRequest = (payload, cb) => ({
  type: editWhatsappTemplateAction.EDIT_WHATSAPP_TEMPLATE_ACTION_REQUEST,
  payload,
  cb,
});
export const editWhatsappTemplateActionSuccess = (payload) => ({
  type: editWhatsappTemplateAction.EDIT_WHATSAPP_TEMPLATE_ACTION_SUCCESS,
  payload,
});
export const editWhatsappTemplateActionError = (payload) => ({
  type: editWhatsappTemplateAction.EDIT_WHATSAPP_TEMPLATE_ACTION_ERROR,
  payload,
});

export const createWhatsappTemplateActionRequest = (payload, cb) => ({
  type: createWhatsappTemplateAction.CREATE_WHATSAPP_TEMPLATE_ACTION_REQUEST,
  payload,
  cb,
});
export const createWhatsappTemplateActionSuccess = (payload) => ({
  type: createWhatsappTemplateAction.CREATE_WHATSAPP_TEMPLATE_ACTION_SUCCESS,
  payload,
});
export const createWhatsappTemplateActionError = (payload) => ({
  type: createWhatsappTemplateAction.CREATE_WHATSAPP_TEMPLATE_ACTION_ERROR,
  payload,
});
