import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Switch, Route, Redirect } from "react-router-dom";
import CampaignList from "./containers/campaign-list";
import CampaignBuilder from "./containers/campaign-builder";
import CampaignSettings from "./containers/campaign-settings";
import CampaignSMS from "./containers/campaign-sms";
import MarketingList from "./containers/campaign-marketing-list";
import CampaignReports from "./containers/campaign-reports/routes";
import CampaignTemplates from "./containers/campaign-template";
import CampaignNoAccessScreen from "./no-access";
import { useSelector } from "react-redux";
import { ContentWithCardLoading } from "../../components/loading";
import ContentWrapper from "../Wrapper";
import { CustomSubHeader } from "../../components/subheader";
import CampaignShortLink from "./containers/campaign-short-link";
import CampaignBlockContact from "./containers/campaign-block-contact";

export default function Campaign(props) {
  const { match } = props;
  const [loading, setLoading] = useState(true);
  const [campaignAccess, setCamapignAccess] = useState(false);

  const userProfile = useSelector((store) => store.ProfileSettings.getProfileDetails.data);

  useEffect(() => {
    if (!_.isEmpty(userProfile)) {
      if (userProfile.userType === "OWNER") {
        setCamapignAccess(true);
        setLoading(false);
      } else if (userProfile.permissions && userProfile.permissions.campaign) {
        setCamapignAccess(true);
        setLoading(false);
      } else {
        setCamapignAccess(false);
        setLoading(false);
      }
    }
  }, [userProfile]);

  if (loading) {
    return (
      <ContentWrapper subHeader={<CustomSubHeader />}>
        <ContentWithCardLoading />
      </ContentWrapper>
    );
  } else if (!campaignAccess) {
    return (
      <>
        <Switch>
          <Route path={`${match.url}`} component={CampaignNoAccessScreen} />
        </Switch>
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/list`} component={CampaignList} />
        <Route path={`${match.url}/builder/:channel`} component={CampaignBuilder} />
        <Route path={`${match.url}/settings`} component={CampaignSettings} />
        <Route path={`${match.url}/templates`} component={CampaignTemplates} />
        <Route path={`${match.url}/reports`} component={CampaignReports} />
        <Route exact path={`${match.url}/smart-short-link`} component={CampaignShortLink} />
        <Route exact path={`${match.url}/marketing/list`} component={MarketingList} />
        <Route exact path={`${match.url}/marketing/list/:listId`} component={MarketingList} />
        <Route exact path={`${match.url}/sms`} component={CampaignSMS} />
        <Route exact path={`${match.url}/block-contact`} component={CampaignBlockContact} />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </>
  );
}
