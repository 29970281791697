import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";

export default function LeadPopover(props) {
  const history = useHistory();

  const handleLeadNameClick = () => {
    history.push(`${props.field.basePath}/${props.value.subscriberType === "PEOPLE" ? "people" : "lead"}/${props.value._id}`);
  };
  if (_.isEmpty(props.value)) return <span className={`data-container`}></span>;
  return (
    <span className={`data-container ellipsis link-primary cursor-pointer text-hover-underline`} onClick={handleLeadNameClick}>
      {props.value.name}
    </span>
  );
}
