import React, { useState } from "react";
import moment from "moment";
import { Popover, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

//images
import AttachmentImage from "../../../images/files/pdf.svg";
import CssImage from "../../../images/files/css.svg";
import CsvImage from "../../../images/files/csv.svg";
import DocImage from "../../../images/files/doc.svg";
import HtmlImage from "../../../images/files/html.svg";
import JavascriptImage from "../../../images/files/javascript.svg";
import JpgImage from "../../../images/files/jpg.svg";
import Mp4Image from "../../../images/files/mp4.svg";
import XmlImage from "../../../images/files/xml.svg";
import ZipImage from "../../../images/files/zip.svg";
import DefaultImage from "../../../images/default.jpg";
import _ from "lodash";
import { ScheduleADateDisplay } from "../../custom-table-data-display/due-date-display";
import { renderLinkedData3 } from "./helper";
import InlineStyled from "../../inline-styled/inline-styled";

export const moduleColors = {
  // "system_update": "#f6a6c2",  // pink
  // "notes": "#ffe786",  // yellow
  // "conversation": "#99e8a7",  // green
  // "activity": "#8babff",  // blue
  // "email": "#cc8aff"    // voilet

  system_update: "#8babff", // pink
  notes: "#ffe786", // yellow
  conversation: "#8babff", // green
  activity: "#8babff", // blue
  email: "#8babff", // voilet
};

const renderAttachments = (attachment, index) => {
  let icons = "";
  if (attachment.content_type === "application/pdf") {
    icons = AttachmentImage;
  } else if (["application/x-pointplus", "text/css"].includes(attachment.content_type)) {
    icons = CssImage;
  } else if (["text/plain", "text/csv", "text/plain"].includes(attachment.content_type)) {
    icons = CsvImage;
  } else if (["application/msword"].includes(attachment.content_type)) {
    icons = DocImage;
  } else if (["text/html", "text/html"].includes(attachment.content_type)) {
    icons = HtmlImage;
  } else if (["text/ecmascript", "text/javascript", "application/ecmascript", "application/javascript", "application/x-javascript"].includes(attachment.content_type)) {
    icons = JavascriptImage;
  } else if (["image/jpeg", "image/pjpeg", "image/pjpeg", "image/jpeg"].includes(attachment.content_type)) {
    icons = JpgImage;
  } else if (["video/mpeg4", "video/x-mpeg-4"].includes(attachment.content_type)) {
    icons = Mp4Image;
  } else if (["application/xml", "text/xml"].includes(attachment.content_type)) {
    icons = XmlImage;
  } else if (["application/x-compressed", "application/x-zip-compressed", "application/zip", "multipart/x-zip"].includes(attachment.content_type)) {
    icons = ZipImage;
  } else {
    icons = AttachmentImage;
  }
  return (
    <div key={index} className="d-flex flex-aligns-center pe-10 pe-lg-20">
      <img alt="" className="w-30px me-3" src={icons} />
      <div className="ms-1 fw-bold">
        <div className="fs-6 text-hover-primary fw-bolder">{attachment.filename}</div>
        <div className="text-gray-400">{attachment.size}</div>
      </div>
    </div>
  );
};

const emailPopover = (item) => {
  return (
    <Popover onClick={(e) => e.stopPropagation()}>
      <Popover.Content>
        <table>
          <tbody>
            <tr>
              <td className="text-muted min-w-75px py-2">From</td>
              <td>
                {item.content.from
                  .map((each) => {
                    return `${each.email}`;
                  })
                  .join(", ")}
              </td>
            </tr>
            <tr>
              <td className="text-muted min-w-75px py-2">To</td>
              <td>
                {item.content.to
                  .map((each) => {
                    return `${each.email}`;
                  })
                  .join(", ")}
              </td>
            </tr>
            <tr>
              <td className="text-muted py-2">Date:</td>
              <td>{new Date(item.content.date * 1000).toLocaleString()}</td>
            </tr>
            <tr>
              <td className="text-muted py-2">Subject:</td>
              <td>{item.content.subject}</td>
            </tr>
            <tr>
              <td className="text-muted py-2">Reply to:</td>
              <td>
                {item.content.reply_to
                  .map((each) => {
                    return `${each?.name} <${each.email}>`;
                  })
                  .join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  );
};

export function EmailCard({ item, showActions, handleActionClick }) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      {item?.content?.hasOwnProperty("subject") ? (
        <div className="border p-0 rounded position-relative border-0">
          <div className="position-absolute right-10px top-10px">
            <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">{expand ? "Collapse" : "Expand"}</Tooltip>}>
              <i className="bi bi-chevron-expand" onClick={() => setExpand(!expand)}></i>
            </OverlayTrigger>
          </div>
          {expand ? (
            <>
              <div className="d-flex align-items-center justify-content-between flex-wrap px-0 pt-0">
                <div className="d-flex align-items-center mr-2 py-2">
                  <div className="font-weight-bold font-size-h4 mr-3">{item.content.subject}</div>
                  {item.content.labels.map((each, index) => {
                    return (
                      <span key={index} className="label label-light-primary font-weight-bold label-inline mr-2">
                        {each?.name}
                      </span>
                    );
                  })}
                  {item.linkTracking && item.linkTracking.length > 0 && (
                    <OverlayTrigger rootClose={true} onExit={() => {}} trigger="click" placement="bottom" overlay={emailPopover(item)}>
                      <span key="linkTracking" className="label label-light-success font-weight-bold label-inline mr-2 w-100px-mn">
                        Link Clicked : {item.linkTracking.length}
                      </span>
                    </OverlayTrigger>
                  )}
                  {item.openTrackingCount ? (
                    <div className="max-popover-container">
                      <span key="openTrackingCount" className="label label-light-success font-weight-bold label-inline mr-2 w-80px-mn">
                        Opened : {item.openTrackingCount}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="cursor-pointer toggle-on" data-inbox="message">
                <div className="d-flex px-0 pt-0 flex-column flex-md-row flex-lg-column flex-xxl-row justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="symbol symbol-30 mr-4">
                      <InlineStyled as="span" className="symbol-label" styles={{ backgroundImage: `url(${DefaultImage})` }}></InlineStyled>
                    </span>
                    <div className="d-flex flex-column flex-grow-1 flex-wrap mr-2">
                      <div className="d-flex">
                        <div className="font-size-lg font-weight-bolder text-dark-75 text-hover-primary mr-2">
                          {item.content.from
                            .map((each) => {
                              return each?.name;
                            })
                            .join(", ")}
                        </div>
                        <div className="font-weight-bold text-muted">
                          <span className="label label-success label-dot mr-2"></span>
                          {moment(item.content.date * 1000).fromNow()}
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="toggle-off-item">
                          <OverlayTrigger rootClose={true} onExit={() => {}} trigger="click" placement="bottom" overlay={emailPopover(item)}>
                            <span className="font-weight-bold text-muted cursor-pointer" data-toggle="dropdown" aria-expanded="false">
                              to{" "}
                              {item.content.to
                                .map((each) => {
                                  return each.email;
                                })
                                .join(", ")}
                              <i className="flaticon2-down icon-xs ml-1 text-dark-50"></i>
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex my-2 my-xxl-0 align-items-md-end align-items-lg-start align-items-xxl-end flex-column flex-md-column flex-lg-column flex-xxl-column">
                    <div className="font-weight-bold text-muted mx-2">{new Date(item.content.date * 1000).toLocaleString()}</div>
                    {showActions && false && (
                      <div className="d-flex gap-4">
                        <Button variant="link" onClick={() => handleActionClick("reply", item)}>
                          Reply
                        </Button>
                        <Button variant="link" onClick={() => handleActionClick("reply-all", item)}>
                          Reply All
                        </Button>
                        <Button variant="link" onClick={() => handleActionClick("forward", item)}>
                          Forward
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className=" py-3 toggle-off-item">
                  <div dangerouslySetInnerHTML={{ __html: item.content.body }} />
                </div>
                {item.content && item.content.files && item.content.files.length > 0 && (
                  <div className="d-flex flex-wrap align-items-center border border-dashed border-gray-300 rounded p-0">
                    {item.content.files.map((file, index) => {
                      return renderAttachments(file, index);
                    })}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="p-0">
              <div className="d-flex align-items-center mr-2 py-2">
                <div className="font-weight-bold font-size-h7 mr-3">{item.content.subject}</div>
                {item.content.labels.map((each, index) => {
                  return (
                    <span key={index} className="label label-light-primary font-weight-bold label-inline mr-2">
                      {each?.name}
                    </span>
                  );
                })}
                {item.linkTracking && item.linkTracking.length > 0 && (
                  <OverlayTrigger rootClose={true} onExit={() => {}} trigger="click" placement="bottom" overlay={emailPopover(item)}>
                    <span key="linkTracking" className="label label-light-success font-weight-bold label-inline mr-2 w-100px-mn">
                      Link Clicked : {item.linkTracking.length}
                    </span>
                  </OverlayTrigger>
                )}
                {item.openTrackingCount ? (
                  <div className="max-popover-container">
                    <span key="openTrackingCount" className="label label-light-success font-weight-bold label-inline mr-2 w-80px-mn">
                      Opened : {item.openTrackingCount}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="d-flex flex-column flex-md-row flex-lg-column flex-xxl-row justify-content-between">
                <div className="pt-2">
                  <span className="pr-2">{item.content.from.map((each) => each.email).join(", ")}</span>
                  <i className="bi bi-arrow-right"></i>
                  <span className="pl-2">{item.content.to.map((each) => each.email).join(", ")}</span>
                </div>

                <div className="d-flex my-2 my-xxl-0 align-items-md-center align-items-lg-start align-items-xxl-center flex-column flex-md-row flex-lg-column flex-xxl-row">
                  <div className="font-weight-bold text-muted mx-2">{new Date(item.content.date * 1000).toLocaleString()}</div>
                </div>
              </div>
              <div className="font-weight-bold pt-2">{item.content.snippet}</div>
            </div>
          )}
        </div>
      ) : (
        <div className="card-body p-0 rounded">
          <div className="d-flex align-items-center justify-content-between flex-wrap p-0 py-0">
            <div className="fs-6">You don't have access to read this Email.</div>
          </div>
        </div>
      )}
    </>
  );
}

export function TimelineDataWrapper(props) {
  return (
    <div className="card p-0">
      {props.children && <div className="px-6 py-4 overflow-auto">{props.children}</div>}
      {props.children && <hr className="m-0 border-top-width-1px border-top-style-solid border-top-color-ebedf3" />}
      <div className="d-flex justify-content-between align-items-center px-6 py-2">
        <div className="fs-7 mb-1 text-gray-600 d-flex align-items-center gap-2">
          <InlineStyled className="badge badge-dark tag-ellipsis rounded-pill fs-7 m-0 text-333333" styles={{ backgroundColor: props.moduleColor }}>
            {props.moduleName}
          </InlineStyled>
          <div>{props.createdText}</div>
        </div>
        <div className="text-gray-600">{moment(props.createdAt).format("MMM DD, YYYY | HH:mm A")}</div>
      </div>
    </div>
  );
}

export const getActivityContent = (item, basePath, history) => {
  let logText = "",
    createdByText = "",
    moduleName = "Activity";
  if (item.action === "activity-create") logText = "Added by";
  else if (item.action === "activity-updated") logText = "Updated by";
  else if (item.action === "activity-deleted") logText = "Deleted by";

  if (item.action === "activity-create" && item && item.content && item.content.source && item.content.source === "Workflow") {
    createdByText = "Workflow Automation";
  } else if (item.trigger === "user") createdByText = item.createdBy.fullName;
  else if (item.trigger === "cadence") {
    createdByText = "Cadence Automation";
  } else if (item.trigger === "workflow") {
    createdByText = "Workflow Automation";
  } else createdByText = item.createdBy.fullName;

  return (
    <TimelineDataWrapper moduleName={moduleName} moduleColor={moduleColors["activity"]} createdAt={item.createdAt} createdText={`${logText} ${createdByText}`}>
      {item.content && (
        <>
          {renderLinkedData3(item, basePath, history)}
          <div className={`${item.content.isCompleted ? "text-decoration-line-through fs-6 fw-bold" : " fs-6 fw-bold"} text-008fbd`}>{item.content?.name}</div>
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="d-flex align-items-center gap-2 ">
              {item.content.activityType && <i className={`${item.content.activityType.symbName}`}></i>}
              <div>
                <ScheduleADateDisplay data={item.content.scheduleAt} />
              </div>
            </div>
            {item.content.isCompleted && (
              <div>
                <span className="badge badge-success bg-73ce89">Completed</span>
              </div>
            )}
          </div>
        </>
      )}
    </TimelineDataWrapper>
  );
};

export const getNoteContent = (item, basePath, history) => {
  let logText = "",
    createdByText = "";
  if (item.action === "note-create") logText = "Added by";
  else if (item.action === "note-updated") logText = "Updated by";
  else if (item.action === "note-deleted") logText = "Deleted by";
  else logText = "Added by";

  if (item.trigger === "user") {
    createdByText = item.createdBy.fullName;
  } else {
    createdByText = item.createdBy.fullName;
  }
  if (_.isEmpty(item.content)) {
    return <></>;
  }
  return (
    <TimelineDataWrapper moduleName={"Note"} moduleColor={moduleColors["notes"]} createdAt={item.createdAt} createdText={`${logText} ${createdByText}`}>
      {item.content && (
        <>
          {renderLinkedData3(item, basePath, history)}
          <div dangerouslySetInnerHTML={{ __html: item.content.content || "" }}></div>
        </>
      )}
    </TimelineDataWrapper>
  );
};

export const getDealStageContent = (item, onFieldClick) => {
  let logText = "Updated by",
    createdByText = "";

  if (item.trigger === "user") {
    createdByText = item.createdBy.fullName;
  } else if (item.trigger === "cadence") {
    createdByText = "Cadence Automation";
  } else if (item.trigger === "workflow") {
    createdByText = "Workflow Automation";
  } else {
    createdByText = item.createdBy.fullName;
  }
  return (
    <TimelineDataWrapper moduleName={"Deal"} moduleColor={moduleColors["system_update"]} createdAt={item.createdAt} createdText={`${logText} ${createdByText}`}>
      {item.content && (
        <>
          <div className="fs-6 fw-bold text-008fbd">Deal stage updated </div>
          <div className="fs-7 mt-4">
            {item.content.oldStage} → {item.content.newStage}
          </div>
        </>
      )}
    </TimelineDataWrapper>
  );
};

export const getTextContent = (item, onFieldClick) => {
  let logText = "",
    createdByText = "",
    moduleName = "";
  if (item.action === "lead-create") {
    logText = "Created by";
    moduleName = "Lead";
  } else if (item.action === "people-create") {
    logText = "Created by";
    moduleName = "People";
  } else if (item.action === "deal-create") {
    logText = "Created by";
    moduleName = "Deal";
  } else if (item.action === "company-create") {
    logText = "Created by";
    moduleName = "Company";
  } else {
    logText = item.content && item.content.text ? item.content.text : "";
  }
  if (item.trigger === "user") {
    createdByText = item.createdBy && item.createdBy.fullName ? item.createdBy.fullName : "";
  } else if (item.trigger === "leadbot") {
    logText = `${logText} ${item.content.name}`;
    createdByText = `(Leadbot)`;
  } else if (item.trigger === "cadence") {
    createdByText = "Cadence Automation";
  } else if (item.trigger === "workflow") {
    createdByText = "Workflow Automation";
  } else {
    logText = `${logText} by `;
    createdByText = `${item.createdBy.fullName}`;
  }

  return <TimelineDataWrapper moduleName={moduleName} moduleColor={moduleColors["system_update"]} createdAt={item.createdAt} createdText={`${logText} ${createdByText}`} />;
};

export const getConversionContent = (item, onFieldClick) => {
  let logText = "",
    createdByText = "",
    secondaryText = "",
    moduleName = "";

  if (item.action === "junk-to-active") {
    logText = "Updated by";
    secondaryText = "Junk Lead → Active Lead";
    moduleName = "Lead";
  } else if (item.action === "active-to-junk") {
    logText = "Updated by";
    secondaryText = "Active Lead → Junk Lead";
    moduleName = "Lead";
  } else if (item.action === "deal-to-lead") {
    logText = "Updated by";
    secondaryText = "Deal → Lead";
    moduleName = "Deal";
  } else if (item.action === "lead-to-deal") {
    logText = "Updated by";
    secondaryText = "Lead → Deal";
    moduleName = "Lead";
  } else if (item.action === "people-to-lead") {
    logText = "Updated by";
    secondaryText = "People → Lead";
    moduleName = "People";
  } else if (item.action === "lead-to-people") {
    logText = "Updated by";
    secondaryText = "Lead → People";
    moduleName = "Lead";
  }

  if (item.trigger === "user") {
    createdByText = item.createdBy.fullName;
  } else if (item.trigger === "cadence") {
    createdByText = "Cadence Automation";
  } else if (item.trigger === "workflow") {
    createdByText = "Workflow Automation";
  } else {
    createdByText = item.createdBy.fullName;
  }
  return (
    <TimelineDataWrapper moduleName={moduleName} createdAt={item.createdAt} moduleColor={moduleColors["conversation"]} createdText={`${logText} ${createdByText}`}>
      <div className="fs-6 fw-bold text-008fbd">Converted</div>
      <div className="fs-7 mt-4">{secondaryText}</div>
    </TimelineDataWrapper>
  );
};

export const getEmailContent = (item, basePath, history, showActions, handleActionClick = () => {}) => {
  let logText = "",
    createdByText = "";
  if (item.action === "email-sent") {
    logText = "Sent by";
  } else if (item.action === "email-received") {
    logText = "Received by";
  }

  if (item.trigger === "user") {
    createdByText = item.createdBy.fullName;
  } else if (item.trigger === "cadence") {
    createdByText = "Cadence Automation";
  } else if (item.trigger === "workflow") {
    createdByText = "Workflow Automation";
  } else {
    createdByText = item.createdBy.fullName;
  }
  return (
    <TimelineDataWrapper moduleName={"Email"} moduleColor={moduleColors["email"]} createdAt={item.createdAt} createdText={`${logText} ${createdByText}`}>
      {renderLinkedData3(item, basePath, history)}
      <EmailCard item={item} showActions={showActions} handleActionClick={handleActionClick} />
    </TimelineDataWrapper>
  );
};
