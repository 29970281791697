import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

export default function WhatsappAddTemplatePreview(props) {
    const [header, setHeader] = useState();
    const [body, setBody] = useState();
    const [footer, setFooter] = useState();
    const [buttons, setButtons] = useState();
    const [displayName, setDisplayName] = useState("");

    const whatsappProfileStore = useSelector(state => state.campaign.settings.whatsapp.selected);
    const whatsappAccountStore = useSelector(state => state.campaign.settings.whatsapp.data);

    useEffect(() => {
        const findAccount = whatsappAccountStore.list.find(item => item._id === props.integrationId);
        if (findAccount) {
            setDisplayName(findAccount.verifiedName)
        }
    }, [whatsappAccountStore.list, props.integrationId])

    useEffect(() => {
        if (_.isEmpty(props.components)) {
            setHeader({});
            setBody({});
            setFooter({});
            setButtons({});
        }
        else {
            setHeader({});
            setBody({});
            setFooter({});
            setButtons({});
            for (const component of props.components) {
                if (component.type === "HEADER") setHeader(component);
                else if (component.type === "BODY") setBody(component);
                else if (component.type === "FOOTER") setFooter(component);
                else if (component.type === "BUTTONS") setButtons(component);
            }
        }
    }, [props.components])

    return <div className="card whatsapp-preview-wrapper">
        <div className="template-preview-text">Template Preview</div>
        <div className="whatsapp-gadget-container">
            <div className="whatsapp-gadget-head">
                <div><img alt="w" src={whatsappProfileStore.data.profilePictureUrl} /></div>
                <div>{displayName}</div>
            </div>
            <div className="whatsapp-body">
                <div class="message-container">
                    <div class="message-content">
                        {!_.isEmpty(header) && header.format === "TEXT" && <div class="message-head message-head-text" >
                            {header.text}
                        </div>}
                        <div class="message-head message-head-media" >
                            <div class="message-head-media-inner"></div>
                            <div class="message-head-media-preview">
                                {!_.isEmpty(header) && header.format === "IMAGE" && header.example.header_handle && <img src={header.example.header_handle[0]} alt="preview" class="message-head-media-preview-image" />}
                                {!_.isEmpty(header) && header.format === "DOCUMENT" && header.example.header_handle && <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"} alt="preview" class="message-head-media-preview-document" />}
                                {!_.isEmpty(header) && header.format === "VIDEO" && header.example.header_handle && <video class="message-head-media-preview-video" controls width="100%">
                                    <source src={header.example.header_handle[0]} type="video/mp4" />
                                </video>}
                            </div>
                            <div class="message-body">{body?.text || ""}</div>
                            {!_.isEmpty(footer) && <div class="message-footer">{footer.text} </div>}
                            <div class="message-date">
                                10:00 AM
                            </div>
                        </div>
                        {!_.isEmpty(buttons) && !_.isEmpty(buttons.buttons.filter(each => ["URL", "PHONE_NUMBER"].includes(each.type))) && <div class="message-buttons" >
                            {buttons.buttons.filter(each => ["URL", "PHONE_NUMBER"].includes(each.type)).map((button, index) => {
                                if (button.type === "URL" && button.text) {
                                    return <div class="message-button-2" key={index}>
                                        <span class="message-button-img visit"><i class="bi bi-box-arrow-up-right"></i></span>
                                        <span class="message-button-text">{button.text}</span>
                                    </div>
                                }
                                else if (button.type === "PHONE_NUMBER" && button.text) {
                                    return <div class="message-button-1" key={index}>
                                        <span class="message-button-img call"><i class="bi bi-telephone-fill"></i></span>
                                        <span class="message-button-text">{button.text}</span>
                                    </div>
                                }
                                else return <div key={index}></div>
                            })}
                        </div>}
                    </div>
                </div>
                {!_.isEmpty(buttons) && <div className="message-quick-buttons">
                    {buttons.buttons.filter(each => each.type === "QUICK_REPLY" && each.text).map((button, index) => {
                        return <div key={index} className="message-quick-button">{button.text}</div>
                    })}
                </div>}
            </div>
            <div className="whatsapp-gadget-bottom"></div>
        </div>

    </div>
}