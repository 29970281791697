import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReloadSVG } from "../../../../../components/custom-svgs/action-svg";
import { CustomTableWithoutAction } from "../../../../../components/custom-tables/table-without-actions";
import { ContentLoadingWithoutCard } from "../../../../../components/loading";
import { NormalTable } from "../../../../../components/table/normalTable";
import { InputLimit } from "../../../../../constants";
import { getEmailCampaignDetailedReportRequest } from "../../../store/actions/campaign_report_actions";

export const campaingTableFields = ["Sr. No.", "Email", "Sent Date", "Delivered Date", "Status"];
export const campaignTableMapping = {
  "Sr. No.": "index",
  Email: "emailAddress",
  "Sent Date": "sentAt",
  "Delivered Date": "delivedAt",
  Status: "status",
};

export const campaignTableFieldDataType = {
  index: "TEXT",
  emailAddress: "TEXT",
  submitedAt: "DATE",
  sentAt: "DATE",
  delivedAt: "DATE",
  status: "TAG",
};

export default function ViewEmailDetailedReport(props) {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getEmailCampaignDetailedReportRequest({ campaignId: props.campaignId, query: { pageNo: pageNo, limit: limit, search: search } }));
  }, [dispatch, props.campaignId, pageNo, limit, search]);

  const emailDetailedReportStore = useSelector((store) => store.campaign.reports.email.detailedReport);

  const columns = {
    email: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "emailAddress", fieldInputType: "INPUT", fieldName: "Email" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    ],
    sms: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "route", fieldInputType: "INPUT", fieldName: "Route" },
      { apiKeyName: "senderId", fieldInputType: "INPUT", fieldName: "Sender ID" },
      { apiKeyName: "phone", fieldInputType: "INPUT", fieldName: "Phone" },
      { apiKeyName: "message", fieldInputType: "INPUT", fieldName: "Message" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "totalsms", fieldInputType: "INPUT", fieldName: "Total SMS" },
      { apiKeyName: "cost", fieldInputType: "INPUT", fieldName: "Cost" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
      { apiKeyName: "remark", fieldInputType: "INPUT", fieldName: "Remark" },
    ],
    whatsapp: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "phone", fieldInputType: "INPUT", fieldName: "Phone" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
      { apiKeyName: "failedReason", fieldInputType: "INPUT", fieldName: "Remark" },
    ],
    rcs: [
      { apiKeyName: "index", fieldInputType: "INPUT", fieldName: "Sr. No." },
      { apiKeyName: "phone", fieldInputType: "INPUT", fieldName: "Phone" },
      { apiKeyName: "sentAt", fieldInputType: "DATE_TIME", fieldName: "Sent Date" },
      { apiKeyName: "delivedAt", fieldInputType: "DATE_TIME", fieldName: "Delivered Date" },
      { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    ],
  };

  const tableProps = {
    columns: columns[emailDetailedReportStore.channel] || [],
    data: emailDetailedReportStore.data,
    pagination: true,
    count: emailDetailedReportStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    setChecked: () => { },
    loading: emailDetailedReportStore.loading,
  };

  const handleReload = () => {
    dispatch(getEmailCampaignDetailedReportRequest({ campaignId: props.campaignId, query: { pageNo: pageNo, limit: limit, search: search } }));
  };
  return (
    <>
      <div className="card-header d-flex flex-stack align-items-center">
        <h3 className="card-title fw-bolder text-dark">{`Detailed Report`}</h3>
        <div>
          <div className="d-flex gap-3 align-items-center">
            <Form.Control className="350px" placeholder={"Search..."} max={InputLimit.TEXT} type="text" valye={search} onChange={(e) => setSearch(e.target.value)} />{" "}
            <IconButton onClick={handleReload} tooltip={"Reload"}>
              {ReloadSVG}
            </IconButton>{" "}
          </div>
        </div>
      </div>
      <div className="p-8 h-vh-70px overflow-auto">
        <div className="w-100">
          {emailDetailedReportStore.loading ? (
            <ContentLoadingWithoutCard />
          ) : true ? (
            <div className={`card card-custom`}>
              <div className="card-body py-3 px-3">
                <NormalTable props={tableProps} />
              </div>
            </div>
          ) : (
            <div className="w-100 border border-1 rounded-2">
              <CustomTableWithoutAction
                tablehead={campaingTableFields}
                tablecontent={emailDetailedReportStore.data}
                mappingTable={campaignTableMapping}
                dataType={campaignTableFieldDataType}
                pageNo={pageNo}
                limit={limit}
                count={emailDetailedReportStore.count}
                setPageNo={setPageNo}
                setLimit={setLimit}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
