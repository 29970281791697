import _ from "lodash";
import validator from "validator";

export const errorMessages = {
  INVALID_MODE_SELECTED: "Please choose the audience option.",
  EMPTY_FILTER: "Please choose a filter to continue.",
  EMPTY_EMAIL_REPLY_TO_EMAIL: "Reply To Email cannot be blank!",
  EMPTY_EMAIL_REPLY_TO_NAME: "Reply To Name cannot be blank!",
  EMPTY_EMAIL_FROM_NAME: "From Name cannot be blank!",
  EMPTY_EMAIL_FROM_EMAIL: "From Email cannot be blank!",
  EMPTY_EMAIL_EMAIL_SUBJECT: "Email subject lines cannot be blank!",
  INVALID_FROM_EMAIL: "Provided from email is invalid",
  INVALID_REPLY_TO_EMAIL: "Provided reply to email is invalid",
  INCOMPLETE_FILTER: "Custom filter information is not complete. Please finish it to continue.",

  // cretae campaign error messages
  INVALID_TEMPLATE_ID: "Invalid template id provided!",
  EMPTY_TEMPLATE_MESSAGE: "Campaign message content cannot be empty!",
  INVALID_ATTCHMENT: "Invalid attchments provided",
  EMPTY_SCHEDULE_TIMEZONE: "Scheduled campaign timezone cannot be empty!",
  EMPTY_SCHEDULE_DATE: "Scheduled campaign date cannot be empty!",
  INVALID_SCHEDULE_DATE: "Invalid schedule date provided. scheduled date cannot be more than 30 days.",
  INVALID_SPLIT_INTERVAL: "Invalid split interval provided!",
  INVALID_SPLIT_DURATION: "Invalid split duration provided!",

  // sms
  EMPTY_ROUTE: "Route name cannot be empty!",
  INVALID_ROUTE_TYPE: "Invalid route name provided!",
  INVALID_IS_UNICODE_TYPE: "Invalid unicode field provided!",
  INVALID_IS_FLASH_TYPE: "Invalid is flash field provided!",
  INVALID_SENDER_IDS_TYPE: "Invalid sender type provided",
  EMPTY_SENDER_IDS: "Sender ID's field cannot be empty!",
};

export const campaignConfigValidation = (channel, config) => {
  if (channel === "email") {
    const { fromName, subject, replyToEmail, replyToName, isReplyTo } = config;
    const fromEmail = `${config.fromEmailPrefix}@${config.fromEmailSufix}`;
    if (_.isEmpty(fromName)) return errorMessages.EMPTY_EMAIL_FROM_NAME;
    else if (_.isEmpty(fromEmail)) return errorMessages.EMPTY_EMAIL_FROM_EMAIL;
    else if (!validator.isEmail(fromEmail)) return errorMessages.INVALID_FROM_EMAIL;
    else if (_.isEmpty(subject)) return errorMessages.EMPTY_EMAIL_EMAIL_SUBJECT;
    else if (isReplyTo && !replyToEmail) return errorMessages.EMPTY_EMAIL_REPLY_TO_EMAIL;
    else if (isReplyTo && !validator.isEmail(replyToEmail)) return errorMessages.INVALID_REPLY_TO_EMAIL;
    else if (isReplyTo && !replyToName) return errorMessages.EMPTY_EMAIL_REPLY_TO_NAME;
  } else if (channel === "sms") {
    const { route, senderIds, isFlash, isUnicode } = config;
    if (_.isEmpty(route)) return errorMessages.EMPTY_ROUTE;
    else if (!_.isArray(senderIds)) return errorMessages.INVALID_SENDER_IDS_TYPE;
    else if (_.isEmpty(senderIds)) return errorMessages.EMPTY_SENDER_IDS;
    else if (!_.isBoolean(isUnicode)) return errorMessages.INVALID_IS_UNICODE_TYPE;
    else if (!_.isBoolean(isFlash)) return errorMessages.INVALID_IS_FLASH_TYPE;
  }

  return "";
};

export const campaignAudienceValidation = (audience) => {
  if (_.isEmpty(audience)) {
    return "Please select audience";
  }
  const { mode, filterId, filterType, tags, filter } = audience;
  if (!["lead", "people", "marketing_list", "copy_paste", "file"].includes(mode)) {
    return errorMessages.INVALID_MODE_SELECTED;
  } else if (["lead", "people", "marketing_list"].includes(mode)) {
    if (filterType === "existing" && _.isEmpty(tags) && _.isEmpty(filterId)) {
      return errorMessages.EMPTY_FILTER;
    } else if (filterType === "custom" && _.isEmpty(filter)) {
      return errorMessages.EMPTY_FILTER;
    } else if (filterType === "custom") {
      for (let each of filter) {
        if (_.isEmpty(each.type) && _.isEmpty(each.operator) && _.isEmpty(each.field) && _.isEmpty(each.condition)) {
          return errorMessages.INCOMPLETE_FILTER;
        }
      }
    }
  }
  return "";
};

export const generateCampaignConfig = (channel, config, meta) => {
  if (channel === "email") {
    return {
      fromName: config.fromName,
      fromEmail: `${config.fromEmailPrefix}@${config.fromEmailSufix}`,
      subject: config.subject,
      replyToEmail: config.isReplyTo ? config.replyToEmail : "",
      replyToName: config.isReplyTo ? config.replyToName : "",
      openTrack: meta.trackOpen,
      clickTrack: meta.trackClick,
    };
  } else if (channel === "sms") {
    return {
      route: config.route.value,
      senderIds: config.senderIds.map((each) => each.value),
      isFlash: false,
      isUnicode: false,
    };
  } else if (channel === "whatsapp") {
    return {
      phoneId: config.phoneId,
    }
  }
  else return {};
};
