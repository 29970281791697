import React from "react";
import _ from "lodash";
import { Dropdown, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function SavedViewsDropdown(props) {
  const renderAddTooltip = (props) => (
    <Tooltip id="favorite-tooltip" {...props}>
      Add Favorite
    </Tooltip>
  );

  const renderEditTooltip = (props) => (
    <Tooltip id="favorite-tooltip" {...props}>
      Edit Filter
    </Tooltip>
  );

  const renderRemoveTooltip = (props) => (
    <Tooltip id="unfavorite-tooltip" {...props}>
      Remove Favorite
    </Tooltip>
  );

  //selectedFilterId
  const getFilterName = (id) => {
    if (props.customFilters && props.customFilters.favorite && props.customFilters.favorite.length > 0) {
      for (let i = 0; i < props.customFilters.favorite.length; i++) {
        if (props.customFilters.favorite[i]._id === id) {
          return props.customFilters.favorite[i].filterName;
        }
      }
    }
    if (props.customFilters && props.customFilters.custom && props.customFilters.custom.length > 0) {
      for (let i = 0; i < props.customFilters.custom.length; i++) {
        if (props.customFilters.custom[i]._id === id) {
          return props.customFilters.custom[i].filterName;
        }
      }
    }
    return "Smart Views";
  };

  const onToggle = () => {
    if (props.isVirtualFilterApplied) {
      props.setShowSmartView(true);
    } else {
      props.setShow(!props.show);
    }
  };

  return (
    <Dropdown drop="down" alignRight onToggle={onToggle} show={props.show}>
      <Dropdown.Toggle className="btn  btn-sm dropdown-toggle btn-secondary custom-left-angled-radius ellipsis-dropdown-toggle bg-f8f8f8 border-width-1px border-style-solid border-color-e4e6ef">
        {props.selectedFilterId ? (
          <span className="font-weight-bolder">{getFilterName(props.selectedFilterId)} </span>
        ) : props.isVirtualFilterApplied ? (
          <span className="font-weight-bolder">Custom View</span>
        ) : (
          <span>Smart Views</span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 pt-2 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
        {props.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center h-35vh-mn">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="h-35vh-mx position-relative overflow-y-auto">
              {props.customFilters && props.customFilters.favorite && props.customFilters.favorite.length > 0 ? (
                <>
                  {/* <Dropdown.Header>Favorites</Dropdown.Header> */}
                  {props.customFilters.favorite.map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => props.handleSelect(item)}>
                      <div className="w-100 d-flex smart-view-container flex-stack">
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{_.capitalize(item.filterName)}</Tooltip>}>
                          <span className="w-100-55px overflow-hidden text-overflow-ellipsis text-nowrap"> {_.capitalize(item.filterName)}</span>
                        </OverlayTrigger>

                        <div className="d-flex align-items-center justify-content-end gap-10px h-25px w-55px">
                          <div className="d-flex align-items-center">
                            {props.selectedFilterId === item["_id"] ? (
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove applied filter</Tooltip>}>
                                <i className="bi bi-x-circle-fill mr-6px mt-1px"></i>
                              </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger overlay={renderRemoveTooltip}>
                              <i
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.handleFavoriteFilter({ filterType: "custom", isFavorite: false, _id: item["_id"] });
                                }}
                                className="fa fa-md fa-star mr-6px text-00acff"
                              ></i>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={renderEditTooltip}>
                              <i
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.setAddFilterFormData(item);
                                  props.setShowEditModal(true);
                                  props.setSaveFilterView(true);
                                }}
                                className="fa fa-sm fa-pen font-size-13px"
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider className="m-0" />
                </>
              ) : null}
              {props.customFilters && props.customFilters.custom && props.customFilters.custom.length > 0 ? (
                <>
                  {/* <Dropdown.Header>All Views</Dropdown.Header> */}
                  {props.customFilters.custom.map((item, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => props.handleSelect(item)}>
                      <div className="w-100 d-flex smart-view-container flex-stack">
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{_.capitalize(item.filterName)}</Tooltip>}>
                          <span className="w-100-55px overflow-hidden text-overflow-ellipsis text-nowrap">{_.capitalize(item.filterName)}</span>
                        </OverlayTrigger>
                        <div className="d-flex align-items-center  justify-content-end gap-10px h-25px w-55px">
                          <div className="d-flex align-items-center">
                            {props.selectedFilterId === item["_id"] ? (
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove applied filter</Tooltip>}>
                                <i className="bi bi-x-circle-fill mr-6px mt-1px"></i>
                              </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger overlay={renderAddTooltip}>
                              <i
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.handleFavoriteFilter({ filterType: "custom", isFavorite: true, _id: item["_id"] });
                                }}
                                className="fa fa-md fa-star mr-6px"
                              ></i>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={renderEditTooltip}>
                              <i
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.setAddFilterFormData(item);
                                  props.setShowEditModal(true);
                                  props.setSaveFilterView(false);
                                }}
                                className="fa fa-sm fa-pen font-size-13px"
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider className="m-0" />
                </>
              ) : null}
              <Dropdown.Item className="dropdown-hover" onSelect={() => props.handleAddFilter()}>
                + Add Filter
              </Dropdown.Item>
            </div>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
