import styled from "styled-components";

export const RightMenuContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 126px;
  right: 0;
  bottom: 0;
  width: 300px;
  background: #f5f8fa;
  white-space: normal;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(9 30 66 / 25%) 0px 12px 24px -6px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const ScrolledContent = styled.div`
  padding: 4px 0 8px;
  margin: 6px 10px 4px 5px;
  flex: 1;
  will-change: contents;
  overflow: auto;
`;

export const AddNew = styled.a`
  display: block;
  color: red;
  padding: 8px 16px;
  position: relative;
  user-select: none;
  border-radius: 0 0 3px 3px;
  font-weight: 500;

  &:hover {
    background: green;
    cursor: pointer;
  }

  i {
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  h5 {
    margin: 0 20px 8px 0;
    word-break: break-word;
    line-height: 18px;
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  margin-bottom: 14px;
  background-color: rgb(255, 255, 255);
  // box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px 0px;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  // padding: 8px;
  outline: 0px;
  font-size: 16px;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-in-out 0s;
  -webkit-box-pack: justify;
  justify-content: space-between;
  will-change: transform;
  // border-left: ${(props) => (props.status === "overdue" ? "2px solid #e34e6c" : props.status === "upcoming" ? "2px solid #73ce89" : "2px solid #f6c61b")};
  // background: ${(props) => (props.dealStatus === "won" ? "#e8fff3" : props.dealStatus === "lost" ? "#fff5f8" : props.dealStatus === "archived" ? "#fff8dd" : "#fff")};
  // height:116px;
`;

export const Right = styled.div`
  float: right;
`;

export const Footer = styled.div`
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px dotted red;
  font-size: 11px;

  ul {
    float: left;
  }

  > i {
    padding: 3px;
  }
`;

export const Amount = styled.ul`
  list-style: none;
  margin: 5px 0 0;
  overflow: hidden;
  padding: 0 !important;
  max-width: 230px;
  font-size: 12px;
  display: inline-block;

  li {
    float: left;
    padding-right: 5px;
    line-height: 22px;

    span {
      font-weight: bold;
      font-size: 10px;
    }

    &:after {
      content: "/";
      margin-left: 5px;
    }

    &:last-child:after {
      content: "";
    }
  }
`;

export const ItemIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 6px 6px 0 0;
  background-color: white;
  word-break: break-word;
`;

export const PriceContainer = styled.div`
  overflow: hidden;

  ul {
    float: left;
  }
`;

export const Button = styled.div`
  padding: 5px 15px;
  background: red;
  color: #fff;
  border-radius: 16px;
  font-weight: 500;
  transition: background 0.3s ease;
  display: inline-block;
  text-align: center;
  padding-left: 40px;

  &:hover {
    background: yellow;
    cursor: pointer;
  }
`;

export const CloseDateLabel = styled(Button)`
  width: 72px;
  padding: 3px 0;
  line-height: 1em;
  margin-top: 4px;
`;

export const Label = styled.div`
  background-color: red;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  min-width: 40px;
  text-overflow: ellipsis;
  max-width: 600px;
  margin: 0 5px 5px 0;
  color: white;
  font-weight: 500;
  border-radius: 4px;
  line-height: 1.2em;
  padding: 4px 10px;

  &:last-child {
    margin: 0px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const LabelList = styled.div`
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;

export const DragDisabler = styled.div`
  position: absolute;
  height: 100%;
  width: ${({ width }) => width || "100%"};
  cursor: progress;
  z-index: 10;
`;

export const LinkButton = styled.a`
  cursor: pointer;
  z-index: 0;
  &:hover {
    text-decoration: underline;
  }
`;

export const StageList = styled.div`
  background: white;
  padding: 20px;
  // margin-top: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 8px;
  max-height: 400px;
  // position: relative;

  ${LinkButton} {
    margin: 20px 0 0 30px;
    display: block;
    cursor: pointer;
  }
`;

export const StageItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: white;
  padding: 0;
  align-items: center;

  > *:not(button) {
    margin-right: 10px;
  }

  button {
    padding: 3px;
    font-size: 16px;
  }

  button:hover {
    color: red;
  }
`;

export const TextArea = styled.div`
  transition: none;
  max-height: 30px;
  min-height: 80px;
  resize: none;
`;

const inputStyle = styled.input`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  cursor: pointer;

  &:focus {
    + span {
      &::before {
        box-shadow: 0 0 0 2px rgba(#333, 0.4) !important;
      }
    }
  }

  &:hover {
    + span {
      &::before {
        border-color: #aaa;
      }
    }
  }

  &:active {
    + span {
      &::before {
        transition-duration: 0;
      }
    }
  }

  + span {
    position: relative;
    padding: 0px;
    user-select: none;
    color: black;

    &:before {
      background-color: #fff;
      border: 2px solid black;
      box-sizing: content-box;
      content: "";
      color: #fff;
      margin-right: calc(15px * 0.25);
      top: 53%;
      left: 0;
      width: 15px;
      height: 15px;
      display: inline-block;
      vertical-align: text-top;
      border-radius: 2px;
      cursor: pointer;
    }

    &:after {
      box-sizing: content-box;
      content: "";
      background-color: #fff;
      position: absolute;
      top: 56%;
      left: calc(0px + 2px + 12px / 2);
      width: calc(15px - 12px);
      height: calc(15px - 12px);
      margin-top: calc((15px - 12px) / -2);
      transform: scale(0);
      transform-origin: 51%;
      transition: transform 200ms ease-out;
    }
  }

  + span:last-child:before {
    margin-right: 0px;
  }
`;

export const Checkbox = styled(inputStyle)`
  + span {
    &:after {
      background-color: transparent;
      top: 53%;
      left: calc(1px + 15px / 5);
      width: calc(15px / 2);
      height: calc(15px / 5);
      margin-top: calc(15px / -2 / 2 * 0.8);
      border-style: solid;
      border-color: #fff;
      border-width: 0 0 2px 2px;
      border-radius: 0;
      border-image: none;
      transform: rotate(-45deg) scale(0);
      transition: none;
    }
  }

  &:checked + span {
    &:before {
      animation: none;
      background-color: #6569df;
      border-color: transparent;
    }

    &:after {
      content: "";
      transform: rotate(-45deg) scale(1);
      transition: transform 200ms ease-out;
    }
  }
`;

export const FlexWrapper = styled.span`
  flex: 1;
  cursor: pointer;
`;

export const FormLabel = styled.label`
  position: relative;
  display: inline-block;
  font-weight: normal;

  span {
    cursor: pointer;
    display: inline-block;
  }
`;

export const Input = styled.input`
  display: block;
  border: none;
  width: 100%;
  height: 34px;
  padding: 7.5px 0;
  color: #444;
  border-bottom: 1px solid;
  border-color: #ddd;
  background: none;
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    border-color: #aaa;
  }

  &:focus {
    outline: none;
    border-color: #6569df;
  }

  ::placeholder {
    color: #aaa;
  }
`;

export const Radio = styled(inputStyle)`
  + span {
    &::before,
    &::after {
      border-radius: 50%;
    }
  }

  &:checked {
    &:active,
    &:focus {
      + span {
        &::before {
          animation: none;
          filter: none;
          transition: none;
        }
      }
    }

    + span {
      &:before {
        animation: none;
        background-color: #6569df;
        border-color: transparent;
      }

      &:after {
        transform: scale(1);
      }
    }
  }
`;

export const Select = styled(Input.withComponent("select"))`
  border: none;
  height: 34px;
  padding: 0;
  width: calc(100% + 15px);
  -webkit-appearance: none;
`;

export const SelectWrapper = styled.div`
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 34px;
  position: relative;
  cursor: pointer;

  // &:after {
  //   position: absolute;
  //   right: 5px;
  //   top: 12px;
  //   content: "\\e97c";
  //   font-size: 14px;
  //   display: inline-block;
  //   // font-family: 'erxes';
  //   // font-family: 'Poppins', Helvetica, sans-serif;
  //   speak: none;
  //   color: #888;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-variant: normal;
  //   text-transform: none;
  //   text-rendering: auto;
  //   line-height: 1;
  //   -webkit-font-smoothing: antialiased;
  // }
`;

export const DragHandler = styled.div`
  display: flex;
  width: 20px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  i {
    color: #aaa;
  }
`;

export const SortableWrapper = styled.div`
  width: 100%;
  flex: 1;
  // max-height: 300px;
  // min-height: 50px;
  // overflow-y: auto;
  label {
    margin: 0;
  }
`;

export const SortItem = styled.div`
  background: #fff;
  display: block;
  padding: 5px;
  margin-bottom: 10px;
  // z-index: 2000;
  position: inherit;
  display: flex;
  justify-content: space-between;
  border-left: 2px solid transparent;
  box-shadow: none;
  // left: 40px!important;

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0 2px 8px rgba(colorBlack, 0.08);
    // border-color: #6569DF;
    border-color: #3699ff;
  }
`;
