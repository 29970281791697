import { getConditions } from "../types";
const initState = {
  data: [],
  isLoading: false,
  error: false,
};

export default function conditionReducer(state = initState, action = {}) {
  switch (action.type) {
    case getConditions.GET_LIST_OF_CONDITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getConditions.GET_LIST_OF_CONDITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case getConditions.GET_LIST_OF_CONDITION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
