import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import BlankSidePanel from "../../../../../components/blank-side-pannel";
import { GreenGradientButton } from "../../../../../components/button";
import { CustomButtonGroupWithIcons } from "../../../../../components/button/custom-group-button";
import { LeadIconSVG, PeopleIconSVG } from "../../../../../components/custom-svgs";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { moduleMapping } from "../../../../campaign/constants";
import AudienceFilter from "../../../../campaign/containers/campaign-builder/components/audience-filter";
import * as cadenceSubscriberActions from "../../store/actions/cadence_lead_actions";
import RadioGroupSelect from "../../../../../components/custom_form/radio";

const NoInputNeededOptions = [
  "",
  "is empty",
  "is not empty",
  "empty",
  "not empty",
  "true",
  "false",
  "today",
  "tomorrow",
  "yesterday",
  "last month",
  "this month",
  "next month",
  "last week",
  "this week",
  "next week",
  "isEmpty",
  "isNotEmpty",
  "isTrue",
  "isFalse",
  "isToday",
  "isTomorrow",
  "isYesterday",
  "isLastMonth",
  "isNextMonth",
  "isLastWeek",
  "isThisWeek",
  "isNextWeek",
  "hasChanged",
  "hasNotChanged",
];
export default function AddSubscriberModal(props) {
  const [selectedMode, setSelectedMode] = useState({ label: "Leads", value: "lead" });
  const [audienceCount, setAudienceCount] = useState(0);
  const [audienceCountLoading, setAudienceCountLoading] = useState(false);
  const [audienceResponse, setAudidenceResponse] = useState({
    allowed: 0,
    count: 0,
    monthlySubscribers: 0,
    used: 0,
  });

  // filter based
  const [tags, setTags] = useState([]);
  const [filterType, setFilterType] = useState("Existing Filters");
  const [filterId, setFilterId] = useState("");
  const [pattern, setPattern] = useState("");
  const [criteriaConditions, setCriteriaConditions] = useState([]);
  const [moduleName, setModuleName] = useState("lead");
  const [typeOfAudience] = useState([
    { label: "Leads", value: "lead", icon: LeadIconSVG },
    { label: "People", value: "people", icon: PeopleIconSVG },
  ]);

  // conditions
  const [typeOfCondition, setTypeOfCondition] = useState("Only add new subscribers; ignore those who have already subscribed and finished.");
  const conditionOptions = [
    "Only add new subscribers; ignore those who have already subscribed and finished.",
    "If a subscriber is new, add them to the cadence. If a subscriber has already finished, restart the cadence for them.",
    "Restart the cadence only for subscribers who have already finished; ignore new contacts.",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    const audience = { mode: selectedMode.value, filter: [] };

    if (filterType === "Existing Filters") {
      if (_.isEmpty(tags) && _.isEmpty(filterId)) {
        return setAudienceCount(0);
      }
      audience["filterId"] = filterId ? filterId : null;
      audience["tags"] = tags;
      audience["filterType"] = "existing";
    } else {
      for (let i = 0; i < criteriaConditions.length; i++) {
        const each = criteriaConditions[i];
        if (_.isEmpty(each.field) || _.isEmpty(each.comparator)) {
          return setAudienceCount(0);
        }
        if (!NoInputNeededOptions.includes(each.comparator) && _.isEmpty(each.value)) {
          return setAudienceCount(0);
        }
        audience["filter"].push({
          type: moduleName,
          field: each.field.id,
          condition: each.comparator,
          value: each.value,
          operator: each.operator,
        });
      }
      audience["pattern"] = pattern;
      audience["filterType"] = "custom";

      if (_.isEmpty(audience["filter"])) {
        return setAudienceCount(0);
      }
    }
    const payload = { audienceData: audience };
    setAudienceCountLoading(true);
    dispatch(
      cadenceSubscriberActions.getCadenceSubscriberCountRequest(payload, (data) => {
        if (data.status) {
          setAudidenceResponse(data);
          setAudienceCount(data.count);
        } else {
          setAudienceCount(0);
        }
        setAudienceCountLoading(false);
      }),
    );
  }, [dispatch, moduleName, selectedMode, tags, filterType, filterId, criteriaConditions, pattern]);

  const handleModeChange = (data) => {
    setSelectedMode(data);
    setModuleName(moduleMapping[data.value].moduleName);
    setTags([]);
    setFilterType("Existing Filters");
    setFilterId("");
    setPattern("");
    setCriteriaConditions([]);
  };
  const getConditionType = () => {
    if (typeOfCondition === conditionOptions[0]) {
      return "only_new_subscribers";
    } else if (typeOfCondition === conditionOptions[1]) {
      return "any_subscriber";
    } else if (typeOfCondition === conditionOptions[2]) {
      return "only_finished_subscribers";
    } else return "none";
  };

  const handleSave = () => {
    if (audienceCount === 0) {
      return dispatchSnackbarError("Please check audience count");
    } else if (!typeOfCondition) {
      return dispatchSnackbarError("Please select a condition type");
    }
    const audience = {
      mode: selectedMode.value,
      cadenceId: props.cadenceId,
      moduleName: _.upperCase(moduleName),
      conditionType: getConditionType(),
    };

    if (filterType === "Existing Filters") {
      if (_.isEmpty(tags) && _.isEmpty(filterId)) {
        return dispatchSnackbarError("Please select a filter");
      }
      audience["filterId"] = filterId ? filterId : null;
      audience["tags"] = tags;
      audience["filterType"] = "existing";
    } else {
      audience["filter"] = criteriaConditions.map((each) => {
        return {
          type: moduleName,
          field: each.field.id,
          condition: each.comparator,
          value: each.value,
          operator: each.operator,
        };
      });
      audience["pattern"] = pattern;
      audience["filterType"] = "custom";

      if (_.isEmpty(audience["filter"])) {
        return dispatchSnackbarError("Please select a filter");
      }
    }
    dispatch(
      cadenceSubscriberActions.bulkSubscribeToCadenceByQueryRequest(audience, (data) => {
        if (data.status) {
          props.setShow(false);
          props.fetchSubscribers();
          resetForm();
        } else {
          dispatchSnackbarError(data.message);
        }
      }),
    );
  };

  const resetForm = () => {
    setSelectedMode({ label: "Leads", value: "lead" });
    setAudienceCount(0);
    setAudienceCountLoading(false);
    setAudidenceResponse({ allowed: 0, count: 0, monthlySubscribers: 0, used: 0 });
    setTags([]);
    setFilterType("Existing Filters");
    setFilterId("");
    setPattern("");
    setCriteriaConditions([]);
    setModuleName("lead");
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <BlankSidePanel
        show={props.show}
        title={"Audience"}
        handleClose={() => {
          props.setShow(false);
          resetForm();
        }}
      >
        <div className="d-flex flex-start">
          <CustomButtonGroupWithIcons options={typeOfAudience} handleClick={handleModeChange} active={selectedMode} />
        </div>
        <div>
          <AudienceFilter
            tags={tags}
            filterType={filterType}
            filterId={filterId}
            pattern={pattern}
            criteriaConditions={criteriaConditions}
            moduleName={moduleName}
            audienceType={selectedMode.value}
            audienceCount={audienceCount}
            audienceCountLoading={audienceCountLoading}
            setTags={setTags}
            setFilterType={setFilterType}
            setFilterId={setFilterId}
            setPattern={setPattern}
            setCriteriaConditions={setCriteriaConditions}
          />
        </div>
        <Form.Group className="card p-5 mt-5 mb-0">
          <Form.Label>Conditions for bulk Operations</Form.Label>
          <RadioGroupSelect apiKeyName={"condition"} setValue={(value, apiKyName) => setTypeOfCondition(value)} value={typeOfCondition} options={conditionOptions} isRow={false} />
        </Form.Group>
        {audienceResponse.allowed < audienceResponse.count && (
          <div className="border-warning rounded-2 mt-4 p-4 border text-black bg-light-warning">
            <strong>WARNING:</strong> Due to a monthly subscription limit of {audienceResponse.monthlySubscribers}, with {audienceResponse.used} already consumed, only {audienceResponse.allowed} out of{" "}
            {audienceResponse.count} customers can be subscribed this month. Any customers who are unable to subscribe can do so in the next month.
          </div>
        )}
        <Form.Group className="mt-6 d-flex align-items-center gap-3">
          {/* <div>
                    <Button size="sm" variant='secondary' onClick={checkAudienceCount}>Check Audience</Button>
                </div> */}
          <GreenGradientButton disabled={audienceCountLoading} size="sm" onClick={handleSave}>
            Save
          </GreenGradientButton>
        </Form.Group>
      </BlankSidePanel>
    </div>
  );
}
