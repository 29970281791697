import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../src/_metronic/_helpers";
import { DeleteModal } from "../../../../components/modal/index";
import { GraphSkeleton } from "../../../../components/skeleton/card-skeleton";
import TableViewModel from "../models/tableView";
// import * as insightActions from "../../actions";
// import { useDispatch, useSelector } from 'react-redux';

// import ReportsChart from '../graphs/reports'
// import ColumnChart from '../graphs/columnchart';
import CustomUserAndTeamDropdown from "../user-filter/index";
import axios from "axios";

//graphs
import FunnelChart from "../amchart-graphs/funnel-chart";
import TimelineActivityGraph from "../amchart-graphs/timeline-activity-chart";
import PieChart from "../amchart-graphs/pie-chart";
import BarChart from "../amchart-graphs/bar-chart";
import ProgressGraph from "../amchart-graphs/progress-bar-chart";
import HorizontalFunnelChart from "../amchart-graphs/horizontal-funnel-chart";

const getGraphReport = (reportId, setLoading, setGraphData, setDefaultCurrency) => {
  setLoading(true);
  fetch(`/api/crm/insight/get-report-graph/${reportId}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      setLoading(false);
      if (data.status) {
        setGraphData(data.data);
        setDefaultCurrency(data.defaultCurrency);
      } else {
        setGraphData([]);
      }
    })
    .catch((err) => {
      setLoading(false);
    });
};

const updateReport = (reportId, setLoading, typeOfUser, users) => {
  setLoading(true);
  return new Promise((resolve) => {
    const url = "/api/crm/insight/edit-report";
    const body = {
      _id: reportId,
      typeOfUser,
      users,
    };
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .patch(url, body, options)
      .then((res) => resolve(true))
      .catch((err) => resolve(true));
  });
};

export default function ReportContainer(props) {
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [showTableViewModel, setShowTableViewModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [, setGraphType] = useState("");
  const [isMultiGraph, setIsMultiGraph] = useState(false);
  const [typeOfUser, setTypeOfUser] = useState(props.typeOfUser);
  const [selectedUser, setSelectedUser] = useState(props.users);
  const [activeUser, setActiveUser] = useState("");

  useEffect(() => {
    getGraphReport(props.reportId, setLoading, setGraphData, setDefaultCurrency);
  }, [props.reportId]);

  useEffect(() => {
    if (props.reportType === "deal" && props.entity === "performance") {
      setIsMultiGraph(true);
      setGraphType("pieChart");
    } else {
      setIsMultiGraph(false);
      setGraphType("");
    }
  }, [props.reportType, props.entity, setGraphType]);

  useEffect(() => {
    if (typeOfUser === "organization") {
      setActiveUser("All Users");
    } else {
      if (props.userRoleOptions.list && props.userRoleOptions.list.users && props.userRoleOptions.list.teams) {
        props.userRoleOptions.list.users.forEach((each) => {
          if (each.id === selectedUser) {
            setActiveUser(each.email);
          }
        });
        props.userRoleOptions.list.teams.forEach((each) => {
          if (each.id === selectedUser) {
            setActiveUser(each.name);
          }
        });
      }
    }
  }, [typeOfUser, props.users, props.userRoleOptions, selectedUser]);

  const deleteTemplateModalProps = {
    show: showDeleteReport,
    title: "Delete Report",
    handleClose: () => {
      setShowDeleteReport(false);
    },
    handleAccept: () => onDeleteAccept(),
  };

  const onEditIconClick = () => {
    props.onEditReportClick(props.reportId);
  };
  const onDeleteIconClick = () => {
    setShowDeleteReport(true);
  };

  const onDeleteAccept = () => {
    setShowDeleteReport(false);
    props.onDeleteReport(props.reportId);
  };

  const onTableViewButtonClick = () => {
    setShowTableViewModel(true);
  };
  const handleTableViewClose = () => {
    setShowTableViewModel(false);
  };

  const onCustomUserSelect = (type, id, name) => {
    setActiveUser(name);
    setTypeOfUser(type);
    setSelectedUser(id);
    updateReport(props.reportId, setLoading, type, id)
      .then(() => {
        getGraphReport(props.reportId, setLoading, setGraphData, setDefaultCurrency);
      })
      .catch(console.error);
  };

  const renderDealGraphs = (reportType, data, chartId, graphType) => {
    switch (reportType) {
      case "progress":
        return <ProgressGraph won={data} title={props.title} defaultCurrency={defaultCurrency} chartId={chartId} graphType={props.graphType} interval={props.interval} legends={["active", "won", "lost", "deleted"]} />;
      case "conversation":
        if (graphType === "number-of-deals-vertical" || graphType === "deals-value-vertical") {
          return <FunnelChart title={props.title} defaultCurrency={defaultCurrency} won={data} chartId={chartId} />;
        } else if (graphType === "number-of-deals-horizontal" || graphType === "deals-value-horizontal") {
          return <HorizontalFunnelChart title={props.title} defaultCurrency={defaultCurrency} won={data} chartId={chartId} />;
        }
        return <div>No graph selected</div>;
      case "lost-reasons":
        return <PieChart won={data} chartId={chartId} value={"lostCount"} category={"category"} />;
      case "performance":
        return <BarChart won={data} defaultCurrency={defaultCurrency} chartId={chartId} category={"category"} interval={props.interval} graphType={props.graphType} values={props.graphType === "won" ? "won" : "lost"} />;
      default:
        return <div>No graph selected</div>;
    }
  };

  const renderActivityGraphs = (reportType, data, chartId) => {
    switch (reportType) {
      case "all-activities-timelines":
        return <ProgressGraph won={data} chartId={chartId} graphType={props.graphType} interval={props.interval} legends={["todo", "overdue", "upcoming"]} />;
      case "lost-reasons":
        return <PieChart won={data} chartId={chartId} value={"lostCount"} category={"category"} />;
      case "completed-activities-timelines":
        return <BarChart won={data} chartId={chartId} category={"category"} interval={props.interval} graphType={props.graphType} values={"completed"} />;
      case "daily-activities-timelines":
        return <TimelineActivityGraph won={data} chartId={chartId} />;
      default:
        return <div>No graph selected</div>;
    }
  };
  const renderLeadGraphs = (reportType, data, chartId) => {
    switch (reportType) {
      case "lead-count":
        return <ProgressGraph won={data} title={props.title} defaultCurrency={defaultCurrency} chartId={chartId} graphType={props.graphType} interval={props.interval} legends={["active", "junk"]} />;
      case "lost-reasons":
        return <PieChart won={data} chartId={chartId} value={"lostCount"} category={"category"} />;
      default:
        return <div>No graph selected</div>;
    }
  };

  const renderGraph = (entity, reportType, data, chartId, graphType, defaultCurrency) => {
    switch (entity) {
      case "deal":
        return renderDealGraphs(reportType, data, chartId, graphType, defaultCurrency);
      case "activity":
        return renderActivityGraphs(reportType, data, chartId);
      case "lead":
        return renderLeadGraphs(reportType, data, chartId);
      default:
        return <div>No graph selected</div>;
    }
  };

  return (
    <div className="card card-custom width-inherit height-inherit" key={props.key}>
      <div className="card-header align-items-center border-0">
        <h3 className="card-title align-items-start text-start">
          <span className="font-weight-bolder text-dark d-inline-block text-truncate w-200px-mn">{props.title}</span>
        </h3>
        {isMultiGraph && (
          <div>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Pie Chart</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 active" onClick={() => setGraphType("pieChart")}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="bi bi-pie-chart-fill"></i>
                </span>
              </span>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Bar Chart</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => setGraphType("barChart")}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="bi bi-file-bar-graph-fill"></i>
                </span>
              </span>
            </OverlayTrigger>
          </div>
        )}
        <div className="card-toolbar">
          <div className="mr-0  mx-3">
            <CustomUserAndTeamDropdown options={props.userRoleOptions} handleSelect={(type, id, name) => onCustomUserSelect(type, id, name)} activeUser={activeUser} />
          </div>
          <div className="d-none">
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Table View</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={() => onTableViewButtonClick()}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="bi bi-table"></i>
                </span>
              </span>
            </OverlayTrigger>
            <TableViewModel showTableViewModel={showTableViewModel} handleTableViewClose={handleTableViewClose} />
          </div>
          {/* <div >
                        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Apply Filter</Tooltip>}   >
                            <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => onApplyFilterClick()} >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <i className="bi bi-funnel"></i>
                                </span>
                            </span>

                        </OverlayTrigger>
                        <ApplyFilterModel
                            showApplyFilterModel={showApplyFilterModel}
                            handleApplyFilterClose={handleApplyFilterClose}
                            onApplyButtonClick={onApplyButtonClick}
                        />
                    </div> */}
          <div>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Filter</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" onClick={() => onEditIconClick()}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <i className="bi bi-funnel-fill"></i>
                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} /> */}
                </span>
              </span>
            </OverlayTrigger>
          </div>
          <div className="dropdown-inline dropdown">
            <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete the report?</DeleteModal>
            <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
              <span className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={() => onDeleteIconClick(props.reportId)}>
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                </span>
              </span>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="card-body pt-4 w-100 h-100">
        {loading ? (
          <div>
            <GraphSkeleton />
          </div>
        ) : graphData.length === 0 ? (
          <>
            <div className="card-px text-center py-5 my-5">
              <p className="font-size-16px text-gray-400 mb-8 fw-600">No data to show for this time frame.</p>{" "}
            </div>
            <img src={require("../../../../images/illustrations/extra/economic_schedule.svg")} className="h-150px mx-auto mt-minus-50px" alt="add" />
          </>
        ) : graphData.length > 0 ? (
          <> {renderGraph(props.entity, props.reportType, graphData, props.reportId, props.graphType, defaultCurrency)} </>
        ) : (
          <>Graph Not Available</>
        )}
      </div>
    </div>
  );
}
