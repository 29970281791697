import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryCode from "../../../../../../_metronic/_assets/js/countryCode";
import { getGeoLocation } from "../../utils/geoLocation";
import { InputLimit } from "../../../../../../constants";

export default function UserCountry(props) {
  // Props for component
  let { values, next, handleChange } = props;

  // custom style for Country Select
  const customStyles = {
    control: () => ({
      background: "#F3F6F9",
      height: "45px",
      padding: "7px",
    }),
    indicatorsContainer: () => ({
      position: "absolute",
      top: "3px",
      right: 0,
      display: "flex",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
    placeholder: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
  };

  // state for Country Code

  const [phoneCode, setPhoneCode] = useState("+91");
  const [CountryName, setCountryName] = useState({ name: "India", iso2: "IN", code: "91", label: "India", value: "91" });

  /************************************************/

  // event on handleOnPhoneChange

  function handleOnPhoneChange(value) {
    setPhoneCode(value);

    localStorage.setItem("phone", value);
  }

  /*************************************************/

  /************************************************/

  // state for country Select
  const [options, setOptions] = useState([]);
  // const [selectOption,setSelectOption] = useState();
  /**************************************************/

  const geoLocVal = useCallback(async (userIpVal) => {
    const geoLoc = await getGeoLocation();
    // setSelectOption(geoLoc['country_name'])

    userIpVal.filter((data, key) => {
      if (data.label === geoLoc["country_name"]) {
        setCountryName(data);
      }
      return null;
    });
  }, []);

  // useEffect for country Select
  useEffect(() => {
    // default value
    localStorage.setItem("phone", "91");
    localStorage.setItem("country", "India");
    /*********************************/
    let arr = countryCode.country_code;
    arr.filter((obj) => {
      obj.label = obj.name;
      obj.value = obj.code;
      return null;
    });
    setOptions(arr);
    geoLocVal(arr);
  }, [options, geoLocVal]);

  // useEffect(() => {
  //   if (phoneCode) {
  //    document.getElementById('CountryId').style.pointerEvents="none";
  //   }
  // }, [phoneCode])

  /**************************************************/

  function dropChanges(selectedOption) {
    try {
      // setSelectOption(selectedOption.label);
      setPhoneCode(`+${selectedOption.code}`);

      localStorage.setItem("country", selectedOption.label);
    } catch (e) {}
  }

  /***************************************************/

  return (
    <>
      {/* {props.value} */}

      <div className="text-center mb-10">
        {/*begin::Title*/}
        <h1 className="text-dark mb-3 fw-600">You're almost there!</h1>
        {/*end::Title*/}
        {/*begin::Sub-title*/}
        <div className="text-muted fw-bold fs-5 mb-5">Fill out a few details and you'll be on your way.</div>
        {/*end::Sub-title*/}
        {/*begin::Mobile no*/}
        {/* <div className="fw-bolder text-dark fs-3">On Your Email</div> */}
        {/*end::Mobile no*/}
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Company Name</label>
        <input maxLength={InputLimit.TEXT} className="form-control form-control-lg form-control-solid" type="text" name="companyName" value={values.Company} onChange={handleChange} autoComplete="off" />
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Country Name</label>
        <Select
          styles={customStyles}
          placeholder=""
          defaultValue={CountryName}
          // value={CountryName}
          onChange={dropChanges}
          options={options}
          isClearable
        />
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Phone Number</label>
        <ReactPhoneInput
          value={phoneCode}
          onChange={handleOnPhoneChange}
          inputClass="w-100"
          inputStyle={{
            height: "45px",
            fontSize: "13px",
            paddingLeft: "48px",
            borderRadius: "5px",
            backgroundColor: "#F3F6F9",
            borderColor: "#F3F6F9",
          }}
        />
      </div>
      <div className="text-center">
        <button type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary" onClick={next}>
          <span className="indicator-label">Next</span>
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        </button>
      </div>
    </>
  );
}
