import dayjs from "dayjs";
import * as React from "react";
import { generateButtonClass } from "./utils";
import { CloseDateLabel } from "./styled";

class DueDateLabel extends React.Component {
  render() {
    const { closeDate, isComplete } = this.props;

    if (!closeDate) {
      return null;
    }

    const day = dayjs(closeDate).format("MMM DD");

    return (
      <CloseDateLabel colorName={generateButtonClass(closeDate, isComplete)}>
        {/* <Icon icon="clock-eight" />  */}
        icon
        {day}
      </CloseDateLabel>
    );
  }
}

export default DueDateLabel;
