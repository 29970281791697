import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./index.css";

export default function CalendarComponent(props) {
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const handleClose = () => {
    setShow(false);
  };
  const handleEventClick = (clickInfo) => {
    setShow(true);
    setSelectedData({
      title: clickInfo.event.title,
      description: clickInfo.event.description,
      start: clickInfo.event.start,
      end: clickInfo.event.end,
    });
  };
  const getCalendarData = async (fetchInfo, successCallback) => {
    try {
      let start = new Date().getTime();
      let end = new Date().getTime();

      if (fetchInfo) {
        start = new Date(fetchInfo.start).getTime();
        end = new Date(fetchInfo.end).getTime();
      }

      const newFetchData = await new Promise((resolve) =>
        fetch(`/api/crm/activity-list/calendar-view?startDate=${start}&endDate=${end}`, {
          method: "get",
          headers: new Headers({
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          }),
        })
          .then((res) => {
            const jsonObject = res.json();
            resolve(jsonObject);
          })
          .catch((err) => {
            resolve({ data: [] });
          }),
      );

      return successCallback(newFetchData.data);
    } catch (error) {
      return successCallback([]);
    }
  };

  return (
    <div className="demo-app">
      <div className="demo-app-main">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          editable={false}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          initialEvents={[]}
          events={(fetchInfo, successCallback, failureCallback) => getCalendarData(fetchInfo, successCallback, failureCallback)}
          eventClick={handleEventClick}
          //am pm format
          eventTimeFormat={{ hour: "numeric", minute: "2-digit", meridiem: "short" }}
        />
        <Modal show={show} onHide={handleClose} size={"sm"}>
          <Modal.Header>
            <Modal.Title>{"Event Summary"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedData.title ? (
              <div className="text-100 fs-5 mb-2">
                {" "}
                <strong className="mr-1">Title:</strong>
                {`${selectedData.title}`}
              </div>
            ) : null}
            {selectedData.description ? (
              <div className="text-100 fs-5 mb-2">
                {" "}
                <strong className="mr-1">Description:</strong>
                {`${selectedData.description}`}
              </div>
            ) : null}
            {selectedData.start ? (
              <div className="text-100 fs-5 mb-2">
                {" "}
                <strong className="mr-1">Start:</strong>
                {`${new Date(selectedData.start).toLocaleString()}`}
              </div>
            ) : null}
            {selectedData.end ? (
              <div className="text-100 fs-5 mb-2">
                {" "}
                <strong className="mr-1">End:</strong>
                {`${new Date(selectedData.end).toLocaleString()}`}
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
