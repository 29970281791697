import React from "react";
import { Spinner, ListGroup, Row, Col } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CustomCheckBox } from "../../../components/custom-inputs/checkbox-selector";
import TagSelect from "../../../components/custom_form/tags";
import UploadCSVFile from "../../../components/file-upload/csv-upload";
import BasicSearchSelect from "../basicSearchSelect";

//actions
import * as companyAction from "../../../containers/companies/store/actions/actions";
import * as productActions from "../../../containers/products/store/actions/actions";
import * as PeopleActions from "../../../containers/customers/store/actions/actions";
import * as leadActions from "../../../containers/leads/store/actions/actions";
import * as marketingListActions from "../../campaign/store/actions/campaign_marketing_list_actions";

import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import RadioSelector from "../../../components/custom-inputs/radio-selector";
import SimpleDropdown from "../../../components/dropdowns/simple-dropdown";

const tooltipMessages = {
  LEAD: "To prevent duplicate entries in the system based on email and phone number provided.",
  PEOPLE: "To prevent duplicate entries in the system based on email and phone number provided.",
  COMPANY: "To prevent duplicate entries in the system based on company name provided.",
  PRODUCT: "To prevent duplicate entries in the system based on product name provided.",
  MARKETING: "To prevent duplicate entries in the system based on email and phone number provided.",
};

const mapping = {
  lead: { page: "Leads", pageSub: "lead" },
  people: { page: "People", pageSub: "people" },
  company: { page: "Companies", pageSub: "company" },
  product: { page: "Products", pageSub: "product" },
  marketing: { page: "marketing", pageSub: "marketing" },
};

const duplicateOverrideOptions = {
  Email: [
    { label: "Update fields & Update new phone number", value: "yes" },
    { label: "Update fields & Keep old phone number", value: "no" },
  ],
  "Phone Number": [
    { label: "Update fields & Update new email id", value: "yes" },
    { label: "Update fields & Keep old email id", value: "no" },
  ],
};

export default function ImportStep4({
  file,
  setFile,
  importType,
  fields,
  systemId,
  allowDuplicate,
  setAllowDuplicate,
  dataType,
  isTagLinked,
  setIsTagLinked,
  search,
  handleSelectField,
  dataName,
  options,
  tags,
  setTags,
  totalAutoMapped,
  duplicationBasedOn,
  setDuplicationBasedOn,
  duplicateOverride,
  setDuplicateOverride,
}) {
  const dispatch = useDispatch();

  const addTag = (name, colorCode) => {
    if (dataType === "lead") {
      dispatch(leadActions.leadTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    } else if (dataType === "people") {
      dispatch(PeopleActions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    } else if (dataType === "company") {
      dispatch(companyAction.companyTagsAddRequest({ name: name, colorCode: colorCode, type: "company" }));
    } else if (dataType === "product") {
      dispatch(productActions.productTagsAddRequest({ name: name, colorCode: colorCode, type: "product" }));
    } else if (dataType === "marketing") {
      dispatch(marketingListActions.createMarketingListTagsRequest({ name: name, colorCode: colorCode, type: "marketing" }));
    }
  };

  const editTag = (id, name, colorCode) => {
    if (dataType === "lead") {
      dispatch(leadActions.leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (dataType === "people") {
      dispatch(PeopleActions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (dataType === "company") {
      dispatch(companyAction.companyTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (dataType === "product") {
      dispatch(productActions.productTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (dataType === "marketing") {
      dispatch(marketingListActions.updateMarketingListTagsRequest({ id: id, name: name, colorCode: colorCode }));
    }
  };

  const deleteTag = (id) => {
    if (dataType === "lead") {
      dispatch(leadActions.leadTagsDeleteRequest(id));
    } else if (dataType === "people") {
      dispatch(PeopleActions.peopleTagsDeleteRequest(id));
    } else if (dataType === "company") {
      dispatch(companyAction.companyTagsDeleteRequest(id));
    } else if (dataType === "product") {
      dispatch(productActions.productTagsDeleteRequest(id));
    } else if (dataType === "marketing") {
      dispatch(marketingListActions.deleteMarketingListTagsRequest(id));
    }
  };

  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
  };

  if (importType === "default") {
    return <UploadCSVFile file={file} setFile={setFile} />;
  } else
    return (
      <div className="w-100 px-2">
        <div className="fv-row">
          <div className="row py-5">
            {fields.loading ? (
              <div className="w-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <PerfectScrollbar className="scroll position-relative h-55vh-mx" options={{ wheelSpeed: 0.4 }}>
                <div>
                  {dataType !== "marketing" && (
                    <div className="d-flex gap-2 px-6">
                      <CustomCheckBox label={"Prevent Duplication"} isChecked={allowDuplicate} onClick={() => setAllowDuplicate(!allowDuplicate)} info={tooltipMessages[dataType.toUpperCase()]} />
                      <CustomCheckBox
                        label={"Assign Tag"}
                        isChecked={isTagLinked}
                        onClick={() => {
                          setIsTagLinked(!isTagLinked);
                          setTags([]);
                        }}
                        info={
                          "These labels (Hot, Warm, Cold, Followup etc) can be used to identify your contacts. These tags will be added to all uploaded contacts here, and existing tags will not be deleted or replaced."
                        }
                      />
                      {isTagLinked && (
                        <Form.Group id="import-import-steps-step4-tags" className="ml-n8">
                          <TagSelect
                            page={mapping[dataType].page}
                            pageSub={mapping[dataType].pageSub}
                            addTag={addTag}
                            editTag={editTag}
                            deleteTag={deleteTag}
                            field={{ fieldName: "Tags", apiKeyName: "tags" }}
                            value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                            setValue={(value) => setValue(value)}
                            hideAddText={true}
                            isUsedAsFilter={true}
                            iconBasedFilter={false}
                          />
                        </Form.Group>
                      )}
                    </div>
                  )}
                  {dataType === "marketing" && (
                    <div className="px-7 pt-7 card mx-7 mt-7 pb-5 bg-light-light">
                      <div className="fs-6 text-gray-700 mb-3">How should we handle your contacts if found duplicates? Choose anyone as a primary.</div>
                      <Row>
                        <Col>
                          <RadioSelector
                            setValue={(value) => {
                              setDuplicationBasedOn(value);
                              setDuplicateOverride(duplicateOverrideOptions[value][0]);
                            }}
                            value={duplicationBasedOn}
                            options={["Email", "Phone Number"]}
                            isVertical={false}
                          />
                        </Col>
                        <Col>
                          <SimpleDropdown value={duplicateOverride} label={""} options={duplicateOverrideOptions[duplicationBasedOn]} handleSelect={setDuplicateOverride} disabled={false} showSearch={false} />
                        </Col>
                      </Row>
                      <Form.Group id="import-import-steps-step4-tags-selection" className="mb-p">
                        <Form.Label aria-label="select-tag">Select Tag</Form.Label>
                        <TagSelect
                          page={mapping[dataType].page}
                          pageSub={mapping[dataType].pageSub}
                          addTag={addTag}
                          editTag={editTag}
                          deleteTag={deleteTag}
                          field={{ fieldName: "Tags", apiKeyName: "tags" }}
                          value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                          setValue={(value) => setValue(value)}
                          addTextHide={false}
                          hideAddText={true}
                          isUsedAsFilter={true}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>

                <div className="py-6 px-3">
                  <div className="d-flex ">
                    <div className="col-12 d-flex flex-column align-items-center">
                      <ListGroup className="w-100 mt-2">
                        <ListGroup.Item>
                          <span className="d-flex justify-content-between flex-grow-1">
                            <h4 className="m-0">Uploaded File Field</h4>
                            <div className="w-40 text-center">
                              <h4 className="m-0">Map with system fields</h4>
                            </div>
                          </span>
                        </ListGroup.Item>
                        {totalAutoMapped && (
                          <ListGroup.Item className=" text-center fs-6">
                            {systemId.filter((a) => a).length === systemId.length ? (
                              <div className="text-success">All fields are mapped. Please verify and click 'upload'.</div>
                            ) : (
                              <div className="text-warning">{`${systemId.filter((a) => a).length} data fields are mapped out of ${systemId.length} data fields. Please map the remaining ones and click 'Upload'`}</div>
                            )}
                          </ListGroup.Item>
                        )}
                        {dataName.map((data, index) => {
                          return data.toLowerCase().includes(search.toLowerCase()) ? (
                            <ListGroup.Item key={index}>
                              <span className="d-flex flex-stack">
                                {data}
                                <div className="w-40">
                                  <BasicSearchSelect value={systemId[index]} index={index} handleSelect={handleSelectField} options={options} systemId={systemId} />
                                </div>
                              </span>
                            </ListGroup.Item>
                          ) : null;
                        })}
                      </ListGroup>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            )}
          </div>
        </div>
      </div>
    );
}
