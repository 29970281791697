import React from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";

export default function FullScreeenModal(props) {
  return (
    <Modal backdrop="static" show={props.showPlans} onHide={() => props.setShowPlans(false)} dialogClassName="modal-98w" aria-labelledby="example-custom-modal-styling-title">
      <Modal.Body className="h-vh-50px overflow-auto">
        <div className="full-screen-modal-close">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => props.setShowPlans(false)}>
            <path d="M22.2494 22.2494L2.75 2.75" stroke="black" stroke-width="3.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.2495 2.75061L2.75009 22.25" stroke="black" stroke-width="3.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div className="full-screen-modal-back">
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              props.activeStep === 2 ? props.setActiveStep(1) : props.setShowPlans(false);
            }}
          >
            <path
              d="M11.6719 24.3625L0.946875 13.6375C0.784375 13.475 0.669 13.299 0.60075 13.1094C0.533583 12.9198 0.5 12.7167 0.5 12.5C0.5 12.2833 0.533583 12.0802 0.60075 11.8906C0.669 11.7011 0.784375 11.525 0.946875 11.3625L11.6719 0.637512C11.9698 0.339595 12.3419 0.183596 12.7882 0.169512C13.2357 0.156512 13.6219 0.312512 13.9469 0.637512C14.2719 0.935428 14.4414 1.30755 14.4555 1.75389C14.4685 2.2013 14.3125 2.58751 13.9875 2.91251L6.025 10.875H24.1844C24.6448 10.875 25.031 11.0305 25.343 11.3414C25.6539 11.6534 25.8094 12.0396 25.8094 12.5C25.8094 12.9604 25.6539 13.3461 25.343 13.657C25.031 13.969 24.6448 14.125 24.1844 14.125H6.025L13.9875 22.0875C14.2854 22.3854 14.4414 22.7646 14.4555 23.225C14.4685 23.6854 14.3125 24.0646 13.9875 24.3625C13.6896 24.6875 13.3104 24.85 12.85 24.85C12.3896 24.85 11.9969 24.6875 11.6719 24.3625Z"
              fill="black"
            />
          </svg>
        </div>
        {props.children}
      </Modal.Body>
    </Modal>
  );
}
