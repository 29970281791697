import { getListOfShortLinks, getShortLinkReports } from "../types/campaign_short_link_types";

const initState = {
  list: {
    data: [],
    count: 0,
    loading: false,
    error: false,
    columns: [
      { title: "Title", apiKeyName: "title", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Short Link", apiKeyName: "shortLink", fieldInputType: "INPUT", isAction: false, isFixed: false, validation: "URL_VALIDATION" },
      { title: "Original Link", apiKeyName: "orginalLink", fieldInputType: "INPUT", isAction: false, isFixed: false, validation: "URL_VALIDATION" },
      { title: "Total Clicked", apiKeyName: "clicked", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Unique Clicked", apiKeyName: "uniqueClickd", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Total Tracked", apiKeyName: "tracked", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Unique Tracked", apiKeyName: "uniqueTracked", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Owner", apiKeyName: "owner", fieldInputType: "OWNER_SELECT", isAction: false, isFixed: false },
      { title: "Created Date", apiKeyName: "createdAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },
      { title: "Actions", apiKeyName: "action", fieldInputType: "ACTION", isAction: true, isFixed: true, options: ["report", "download", "edit", "delete"] },
    ],
    managerColumns: [
      { title: "Title", apiKeyName: "title", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Short Link", apiKeyName: "shortLink", fieldInputType: "INPUT", isAction: false, isFixed: false, validation: "URL_VALIDATION" },
      { title: "Original Link", apiKeyName: "orginalLink", fieldInputType: "INPUT", isAction: false, isFixed: false, validation: "URL_VALIDATION" },
      // { title: "Total Clicked", apiKeyName: "clicked", fieldInputType: "INPUT", isAction: false, isFixed: false },
      // { title: "Unique Clicked", apiKeyName: "uniqueClickd", fieldInputType: "INPUT", isAction: false, isFixed: false },
      // { title: "Total Tracked", apiKeyName: "tracked", fieldInputType: "INPUT", isAction: false, isFixed: false },
      // { title: "Unique Tracked", apiKeyName: "uniqueTracked", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Owner", apiKeyName: "owner", fieldInputType: "OWNER_SELECT", isAction: false, isFixed: false },
      { title: "Created Date", apiKeyName: "createdAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },
      { title: "Actions", apiKeyName: "action", fieldInputType: "ACTION", isAction: true, isFixed: true, options: ["choose", "edit", "delete"] },
    ],
  },
  reports: {
    data: [],
    count: 0,
    loading: false,
    error: false,
    columns: [
      { title: "Browser", apiKeyName: "browser", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Country", apiKeyName: "country", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Domain", apiKeyName: "domain", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "IP Address", apiKeyName: "ip", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Operating System", apiKeyName: "os", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Mobile Number", apiKeyName: "phone", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Referer", apiKeyName: "referer", fieldInputType: "INPUT", isAction: false, isFixed: false },
      { title: "Created Date", apiKeyName: "createdAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },
    ],
  },
};

export default function campaignShortLinkReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      };
    case getShortLinkReports.GET_SHORT_LINK_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      };
    case getShortLinkReports.GET_SHORT_LINK_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case getShortLinkReports.GET_SHORT_LINK_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
