export const TESTING_REQUEST = "TESTING_REQUEST";
export const TESTING_SUCCESS = "TESTING_SUCCESS";
export const TESTING_ERROR = "TESTING_ERROR";

export const FACTOR2_REQUEST = "FACTOR2_REQUEST";
export const FACTOR2_SUCCESS = "FACTOR2_SUCCESS";
export const FACTOR2_ERROR = "FACTOR2_ERROR";

export const CHANGEEMAIL_REQUEST = "CHANGEEMAIL_REQUEST";
export const CHANGEEMAIL_SUCCESS = "CHANGEEMAIL_SUCCESS";
export const CHANGEEMAIL_ERROR = "CHANGEEMAIL_ERROR";

export const CALENDAR_GOOGLE_REQUEST = "CALENDAR_GOOGLE_REQUEST";
export const CALENDAR_GOOGLE_SUCCESS = "CALENDAR_GOOGLE_SUCCESS";
export const CALENDAR_GOOGLE_ERROR = "CALENDAR_GOOGLE_ERROR";

export const EMAIL_GOOGLE_REQUEST = "EMAIL_GOOGLE_REQUEST";
export const EMAIL_GOOGLE_SUCCESS = "EMAIL_GOOGLE_SUCCESS";
export const EMAIL_GOOGLE_ERROR = "EMAIL_GOOGLE_ERROR";

export const CALENDAR_START_SYNCING_REQUEST = "CALENDAR_START_SYNCING_REQUEST";
export const CALENDAR_START_SYNCING_SUCCESS = "CALENDAR_START_SYNCING_SUCCESS";
export const CALENDAR_START_SYNCING_ERROR = "CALENDAR_START_SYNCING_ERROR";

export const CALENDAR_SYNC_UPDATE_REQUEST = "CALENDAR_SYNC_UPDATE_REQUEST";
export const CALENDAR_SYNC_UPDATE_SUCCESS = "CALENDAR_SYNC_UPDATE_SUCCESS";
export const CALENDAR_SYNC_UPDATE_ERROR = "CALENDAR_SYNC_UPDATE_ERROR";
export const GETPROFILE_REQUEST = "GETPROFILE_REQUEST";
export const GETPROFILE_SUCCESS = "GETPROFILE_SUCCESS";
export const GETPROFILE_ERROR = "GETPROFILE_ERROR";

export const UPDATEPROFILE_REQUEST = "UPDATEPROFILE_REQUEST";
export const UPDATEPROFILE_SUCCESS = "UPDATEPROFILE_SUCCESS";
export const UPDATEPROFILE_ERROR = "UPDATEPROFILE_ERROR";

export const LOGINSESSION_REQUEST = "LOGINSESSION_REQUEST";
export const LOGINSESSION_SUCCESS = "LOGINSESSION_SUCCESS";
export const LOGINSESSION_ERROR = "LOGINSESSION_ERROR";

export const LOGINSESSIONPROFILE_REQUEST = "LOGINSESSIONPROFILE_REQUEST";
export const LOGINSESSIONPROFILE_SUCCESS = "LOGINSESSIONPROFILE_SUCCESS";
export const LOGINSESSIONPROFILE_ERROR = "LOGINSESSIONPROFILE_ERROR";

export const GETNOTIFICATION_REQUEST = "GETNOTIFICATION_REQUEST";
export const GETNOTIFICATION_SUCCESS = "GETNOTIFICATION_SUCCESS";
export const GETNOTIFICATION_ERROR = "GETNOTIFICATION_ERROR";

export const POSTNOTIFICATION_REQUEST = "POSTNOTIFICATION_REQUEST";
export const POSTNOTIFICATION_SUCCESS = "POSTNOTIFICATION_SUCCESS";
export const POSTNOTIFICATION_ERROR = "POSTNOTIFICATION_ERROR";

export const ADDWEBHOOK_REQUEST = "ADDWEBHOOK_REQUEST";
export const ADDWEBHOOK_SUCCESS = "ADDWEBHOOK_SUCCESS";
export const ADDWEBHOOK_ERROR = "ADDWEBHOOK_ERROR";

export const GETWEBHOOK_REQUEST = "GETWEBHOOK_REQUEST";
export const GETWEBHOOK_SUCCESS = "GETWEBHOOK_SUCCESS";
export const GETWEBHOOK_ERROR = "GETWEBHOOK_ERROR";

export const PATCHWEBHOOK_REQUEST = "PATCHWEBHOOK_REQUEST";
export const PATCHWEBHOOK_SUCCESS = "PATCHWEBHOOK_SUCCESS";
export const PATCHWEBHOOK_ERROR = "PATCHWEBHOOK_ERROR";

export const DELETEWEBHOOK_REQUEST = "DELETEWEBHOOK_REQUEST";
export const DELETEWEBHOOK_SUCCESS = "DELETEWEBHOOK_SUCCESS";
export const DELETEWEBHOOK_ERROR = "DELETEWEBHOOK_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const POSTCURRENCY_REQUEST = "POSTCURRENCY_REQUEST";
export const POSTCURRENCY_SUCCESS = "POSTCURRENCY_SUCCESS";
export const POSTCURRENCY_ERROR = "POSTCURRENCY_ERROR";

export const GETCURRENCY_REQUEST = "GETCURRENCY_REQUEST";
export const GETCURRENCY_SUCCESS = "GETCURRENCY_SUCCESS";
export const GETCURRENCY_ERROR = "GETCURRENCY_ERROR";

export const PATCHCURRENCY_REQUEST = "PATCHCURRENCY_REQUEST";
export const PATCHCURRENCY_SUCCESS = "PATCHCURRENCY_SUCCESS";
export const PATCHCURRENCY_ERROR = "PATCHCURRENCY_ERROR";

/****************************************************************/

export const POSTLOSTREASON_REQUEST = "POSTLOSTREASON_REQUEST";
export const POSTLOSTREASON_SUCCESS = "POSTLOSTREASON_SUCCESS";
export const POSTLOSTREASON_ERROR = "POSTLOSTREASON_ERROR";

export const GETLOSTREASON_REQUEST = "GETLOSTREASON_REQUEST";
export const GETLOSTREASON_SUCCESS = "GETLOSTREASON_SUCCESS";
export const GETLOSTREASON_ERROR = "GETLOSTREASON_ERROR";

export const GETALLLOSTREASON_REQUEST = "GETALLLOSTREASON_REQUEST";
export const GETALLLOSTREASON_SUCCESS = "GETALLLOSTREASON_SUCCESS";
export const GETALLLOSTREASON_ERROR = "GETALLLOSTREASON_ERROR";

export const PATCHLOSTREASON_REQUEST = "PATCHLOSTREASON_REQUEST";
export const PATCHLOSTREASON_SUCCESS = "PATCHLOSTREASON_SUCCESS";
export const PATCHLOSTREASON_ERROR = "PATCHLOSTREASON_ERROR";

export const DELETELOSTREASON_REQUEST = "DELETELOSTREASON_REQUEST";
export const DELETELOSTREASON_SUCCESS = "DELETELOSTREASON_SUCCESS";
export const DELETELOSTREASON_ERROR = "DELETELOSTREASON_ERROR";

export const GETMANAGEUSER_REQUEST = "GETMANAGEUSER_REQUEST";
export const GETMANAGEUSER_SUCCESS = "GETMANAGEUSER_SUCCESS";
export const GETMANAGEUSER_ERROR = "GETMANAGEUSER_ERROR";

export const PATCHMANAGEUSER_REQUEST = "PATCHMANAGEUSER_REQUEST";
export const PATCHMANAGEUSER_SUCCESS = "PATCHMANAGEUSER_SUCCESS";
export const PATCHMANAGEUSER_ERROR = "PATCHMANAGEUSER_ERROR";

export const DELETEMANAGEUSER_REQUEST = "DELETEMANAGEUSER_REQUEST";
export const DELETEMANAGEUSER_SUCCESS = "DELETEMANAGEUSER_SUCCESS";
export const DELETEMANAGEUSER_ERROR = "DELETEMANAGEUSER_ERROR";

export const POSTMANAGEUSER_REQUEST = "POSTMANAGEUSER_REQUEST";
export const POSTMANAGEUSER_SUCCESS = "POSTMANAGEUSER_SUCCESS";
export const POSTMANAGEUSER_ERROR = "POSTMANAGEUSER_ERROR";

export const EMAIL_START_SYNCING_REQUEST = "EMAIL_START_SYNCING_REQUEST";
export const EMAIL_START_SYNCING_SUCCESS = "EMAIL_START_SYNCING_SUCCESS";
export const EMAIL_START_SYNCING_ERROR = "EMAIL_START_SYNCING_ERROR";

export const GOOGLE_CALENDAR_CALLBACK_REQUEST = "GOOGLE_CALENDAR_CALLBACK_REQUEST";
export const GOOGLE_CALENDAR_CALLBACK_SUCCESS = "GOOGLE_CALENDAR_CALLBACK_SUCCESS";
export const GOOGLE_CALENDAR_CALLBACK_ERROR = "GOOGLE_CALENDAR_CALLBACK_ERROR";

export const GETTEAMSDETAILS_REQUEST = "GETTEAMSDETAILS_REQUEST";
export const GETTEAMSDETAILS_SUCCESS = "GETTEAMSDETAILS_SUCCESS";
export const GETTEAMSDETAILS_ERROR = "GETTEAMSDETAILS_ERROR";

export const POSTTEAM_REQUEST = "POSTTEAM_REQUEST";
export const POSTTEAM_SUCCESS = "POSTTEAM_SUCCESS";
export const POSTTEAM_ERROR = "POSTTEAM_ERROR";

export const GETTEAM_REQUEST = "GETTEAM_REQUEST";
export const GETTEAM_SUCCESS = "GETTEAM_SUCCESS";
export const GETTEAM_ERROR = "GETTEAM_ERROR";

export const DELETETEAM_REQUEST = "DELETETEAM_REQUEST";
export const DELETETEAM_SUCCESS = "DELETETEAM_SUCCESS";
export const DELETETEAM_ERROR = "DELETETEAM_ERROR";

export const PATCHTEAM_REQUEST = "PATCHTEAM_REQUEST";
export const PATCHTEAM_SUCCESS = "PATCHTEAM_SUCCESS";
export const PATCHTEAM_ERROR = "PATCHTEAM_ERROR";

export const RESETPASSWORD_REQUEST = "RESETPASSWORD_REQUEST";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_ERROR = "RESETPASSWORD_ERROR";

export const EMAIL_SYNC_UPDATE_REQUEST = "EMAIL_SYNC_UPDATE_REQUEST";
export const EMAIL_SYNC_UPDATE_SUCCESS = "EMAIL_SYNC_UPDATE_SUCCESS";
export const EMAIL_SYNC_UPDATE_ERROR = "EMAIL_SYNC_UPDATE_ERROR";

export const GOOGLE_EMAIL_CALLBACK_REQUEST = "GOOGLE_EMAIL_CALLBACK_REQUEST";
export const GOOGLE_EMAIL_CALLBACK_SUCCESS = "GOOGLE_EMAIL_CALLBACK_SUCCESS";
export const GOOGLE_EMAIL_CALLBACK_ERROR = "GOOGLE_EMAIL_CALLBACK_ERROR";

export const GET_CALENDARSYNC_ACCOUNTS_REQUEST = "GET_CALENDARSYNC_ACCOUNTS_REQUEST";
export const GET_CALENDARSYNC_ACCOUNTS_SUCCESS = "GET_CALENDARSYNC_ACCOUNTS_SUCCESS";
export const GET_CALENDARSYNC_ACCOUNTS_ERROR = "GET_CALENDARSYNC_ACCOUNTS_ERROR";

export const GET_CALENDAR_USERINFO_REQUEST = "GET_CALENDAR_USERINFO_REQUEST";
export const GET_CALENDAR_USERINFO_SUCCESS = "GET_CALENDAR_USERINFO_SUCCESS";
export const GET_CALENDAR_USERINFO_ERROR = "GET_CALENDAR_USERINFO_ERROR";

export const GET_EMAILSYNC_ACCOUNTS_REQUEST = "GET_EMAILSYNC_ACCOUNTS_REQUEST";
export const GET_EMAILSYNC_ACCOUNTS_SUCCESS = "GET_EMAILSYNC_ACCOUNTS_SUCCESS";
export const GET_EMAILSYNC_ACCOUNTS_ERROR = "GET_EMAILSYNC_ACCOUNTS_ERROR";

export const GET_EMAILSYNC_SYNCACCOUNT_REQUEST = "GET_EMAILSYNC_SYNCACCOUNT_REQUEST";
export const GET_EMAILSYNC_SYNCACCOUNT_SUCCESS = "GET_EMAILSYNC_SYNCACCOUNT_SUCCESS";
export const GET_EMAILSYNC_SYNCACCOUNT_ERROR = "GET_EMAILSYNC_SYNCACCOUNT_ERROR";
export const POSTACTIVITY_REQUEST = "POSTACTIVITY_REQUEST";
export const POSTACTIVITY_SUCCESS = "POSTACTIVITY_SUCCESS";
export const POSTACTIVITY_ERROR = "POSTACTIVITY_ERROR";

export const GETACTIVITY_REQUEST = "GETACTIVITY_REQUEST";
export const GETACTIVITY_SUCCESS = "GETACTIVITY_SUCCESS";
export const GETACTIVITY_ERROR = "GETACTIVITY_ERROR";

export const PATCHACTIVITY_REQUEST = "PATCHACTIVITY_REQUEST";
export const PATCHACTIVITY_SUCCESS = "PATCHACTIVITY_SUCCESS";
export const PATCHACTIVITY_ERROR = "PATCHACTIVITY_ERROR";

export const DELETEACTIVITY_REQUEST = "DELETEACTIVITY_REQUEST";
export const DELETEACTIVITY_SUCCESS = "DELETEACTIVITY_SUCCESS";
export const DELETEACTIVITY_ERROR = "DELETEACTIVITY_ERROR";

export const PATCHMANAGEROLE_REQUEST = "PATCHMANAGEROLE_REQUEST";
export const PATCHMANAGEROLE_SUCCESS = "PATCHMANAGEROLE_SUCCESS";
export const PATCHMANAGEROLE_ERROR = "PATCHMANAGEROLE_ERROR";

export const NYLAS_SYNC_REQUEST = "NYLAS_SYNC_REQUEST";
export const NYLAS_SYNC_SUCCESS = "NYLAS_SYNC_SUCCESS";
export const NYLAS_SYNC_ERROR = "NYLAS_SYNC_ERROR";

export const NYLAS_SYNC_CALLBACK_REQUEST = "NYLAS_SYNC_CALLBACK_REQUEST";
export const NYLAS_SYNC_CALLBACK_SUCCESS = "NYLAS_SYNC_CALLBACK_SUCCESS";
export const NYLAS_SYNC_CALLBACK_ERROR = "NYLAS_SYNC_CALLBACK_ERROR";
export const AVATARPIC_REQUEST = "AVATARPIC_REQUEST";
export const AVATARPIC_SUCCESS = "AVATARPIC_SUCCESS";
export const AVATARPIC_ERROR = "AVATARPIC_ERROR";

export const GETPROFILEDETAILS_REQUEST = "GETPROFILEDETAILS_REQUEST";
export const GETPROFILEDETAILS_SUCCESS = "GETPROFILEDETAILS_SUCCESS";
export const GETPROFILEDETAILS_ERROR = "GETPROFILEDETAILS_ERROR";

export const PATCHCUSTOMFIELD_REQUEST = "PATCHCUSTOMFIELD_REQUEST";
export const PATCHCUSTOMFIELD_SUCCESS = "PATCHCUSTOMFIELD_SUCCESS";
export const PATCHCUSTOMFIELD_ERROR = "PATCHCUSTOMFIELD_ERROR";

export const POSTCUSTOMFIELD_REQUEST = "POSTCUSTOMFIELD_REQUEST";
export const POSTCUSTOMFIELD_SUCCESS = "POSTCUSTOMFIELD_SUCCESS";
export const POSTCUSTOMFIELD_ERROR = "POSTCUSTOMFIELD_ERROR";

export const GETCUSTOMFIELD_REQUEST = "GETCUSTOMFIELD_REQUEST";
export const GETCUSTOMFIELD_SUCCESS = "GETCUSTOMFIELD_SUCCESS";
export const GETCUSTOMFIELD_ERROR = "GETCUSTOMFIELD_ERROR";

export const DELETECUSTOMFIELD_REQUEST = "DELETECUSTOMFIELD_REQUEST";
export const DELETECUSTOMFIELD_SUCCESS = "DELETECUSTOMFIELD_SUCCESS";
export const DELETECUSTOMFIELD_ERROR = "DELETECUSTOMFIELD_ERROR";

export const COMPANYLIST_REQUEST = "COMPANYLIST_REQUEST";
export const COMPANYLIST_SUCCESS = "COMPANYLIST_SUCCESS";
export const COMPANYLIST_ERROR = "COMPANYLIST_ERROR";

export const TESTWEBHOOK_REQUEST = "TESTWEBHOOK_REQUEST";
export const TESTWEBHOOK_SUCCESS = "TESTWEBHOOK_SUCCESS";
export const TESTWEBHOOK_ERROR = "TESTWEBHOOK_ERROR";

export const DISABLEWEBHOOK_REQUEST = "DISABLEWEBHOOK_REQUEST";
export const DISABLEWEBHOOK_SUCCESS = "DISABLEWEBHOOK_SUCCESS";
export const DISABLEWEBHOOK_ERROR = "DISABLEWEBHOOK_ERROR";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";

export const RESEND_INVITATION_REQUEST = "RESEND_INVITATION_REQUEST";
export const RESEND_INVITATION_SUCCESS = "RESEND_INVITATION_SUCCESS";
export const RESEND_INVITATION_ERROR = "RESEND_INVITATION_ERROR";
