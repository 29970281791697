import React from "react";
import { Form } from "react-bootstrap";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";

export default function NameStep(props) {
  return (
    <div className="card name-step-wrapper d-flex justify-content-between flex-row align-items-end w-100">
      <div className="w-50-10px">
        <div className="module-header-text">Workflow Name*</div>
        <Form.Control type="text" className="w-500px-mx" value={props.workflowName} onChange={(e) => props.setWorkflowName(e.target.value)} />
      </div>
      <div className="w-50-10px">
        <div className="module-header-text">Trigger*</div>
        <SimpleDropdown
          value={props.triggerBy}
          label={"Trigger By"}
          options={[
            { label: "Trigger to my account only", value: "user" },
            { label: "Trigger to all users in the company", value: "all" },
          ]}
          handleSelect={props.setTriggerBy}
        />
      </div>
    </div>
  );
}
