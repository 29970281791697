import React from "react";
import Select from "react-select";
import { Form, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { campaignInfoTextMessages, smsRouteOptions } from "../../../constants";
import _ from "lodash";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
};
export default function SMSConfigForm({ config, setConfig, senderIdOptions, setIsTemplate, setTemplateId, setTemplate, senderIdDropdownProps }) {
  const handleChange = (keyName, value) => {
    const updatepayload = {
      ...config,
      [keyName]: value,
      isUnicode: false,
    };
    if (keyName === "route") {
      updatepayload["senderIds"] = [];
    }
    if (keyName === "senderIds" && updatepayload["senderIds"]) {
      updatepayload["senderIds"] = [updatepayload["senderIds"]].flat();
    }
    setConfig(updatepayload);
    // setIsTemplate(false);
    // setTemplateId(null);
    // setTemplate("");
  };
  return (
    <>
      <Row>
        <Col>
          <Form.Group id="campaign-builder-forms-sms-config-form-select-route">
            <Form.Label aria-label="select-route">
              Select Route*
              <OverlayTrigger placement="right" overlay={<Tooltip>{campaignInfoTextMessages.SELECT_ROUTE_INFO}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <SimpleDropdown value={config.route} label={"Select Route Name"} options={smsRouteOptions} handleSelect={(e) => handleChange("route", e)} />
          </Form.Group>
        </Col>
        <Col>
          {!_.isEmpty(config.route) && (
            <Form.Group id="campaign-builder-forms-sms-config-form-select-sender-Id">
              <Form.Label aria-label="select-senderId">
                Select Sender ID*
                <OverlayTrigger placement="right" overlay={<Tooltip>{campaignInfoTextMessages.SELECT_SENDER_INFO}</Tooltip>}>
                  <i className="ml-2 bi bi-info-circle"></i>
                </OverlayTrigger>
              </Form.Label>
              <Select
                isMulti
                name="senderId"
                value={config.senderIds}
                styles={customStyles}
                options={senderIdOptions}
                className="h-300px-mx  z-index-3"
                placeholder="Select Sender ID's"
                onChange={(e) => handleChange("senderIds", e)}
                {...senderIdDropdownProps}
              />
            </Form.Group>
          )}
        </Col>
      </Row>
    </>
  );
}
