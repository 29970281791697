import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import CustomSidePanel from "../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import ContentWrapper from "../../../Wrapper";
import CampaignSubHeader from "../../components/campaign-common-sub-header";
import ChannelSelector from "../../components/channel-selection";
import PricingModule from "./pricing";
import ManageModule from "./manage";

export default function CampaignCommonSettings(props) {
  const [show, setShow] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [channel, setChannel] = useState("");
  const [route, setRoute] = useState("");
  const tabName = "Settings";

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params.channel && params.route) {
      setShow(true);
      setModuleName(`${_.capitalize(params.channel)} ${_.capitalize(params.route)}`);
      setChannel(params.channel);
      setRoute(params.route);
    } else {
      setShow(false);
      setModuleName("");
      setChannel("");
      setRoute("");
    }
  }, [params]);

  const handleClose = () => {
    setShow(false);
    history.push("/campaign/settings");
  };

  return (
    <ContentWrapper subHeader={<CampaignSubHeader active={tabName} />}>
      <ChannelSelector type={"settings"} basePath="/campaign/settings" isSettings={true} />
      <CustomSidePanel
        show={show}
        moduleName={moduleName}
        handleClose={handleClose}
        showNextButton={false}
        showPreviosButton={false}
        customStyles={{
          width: "80vw",
          right: "81vw",
        }}
      >
        {route === "pricing" && <PricingModule channel={channel} />}
        {route === "manage" && <ManageModule channel={channel} />}
      </CustomSidePanel>
    </ContentWrapper>
  );
}
