import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  GET_LIST_OF_DASHBOARDS_REQUEST,
  CREATE_DASHBOARD_REQUEST,
  EDIT_DASHBOARD_REQUEST,
  DELETE_DASHBOARD_REQUEST,
  EDIT_DASHBOARD_LAYOUT_REQUEST,
  CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST,
  EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST,
  DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST,
  GET_INSIGHTS_GRAPH_REPORT_REQUEST,
  GET_SELECTED_DASHBOARD_REQUEST,
  GET_LIST_OF_PIPELINES_REQUEST,
  GET_LIST_OF_USERS_REQUEST,
  GET_LIST_OF_DASHBOARDS_REQUEST_WITHOUT_LOADING,
  TYPE_OF_REPORT_REQUEST,
  USER_ROLE_REQUEST,
} from "../../types";
import api from "../../../../network/apis/api";
import * as InsightActions from "../../actions";

import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";

function* getListOfDashboards(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/all-dashboard`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.getListOfDashboardsSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.getListOfDashboardsError(response));
    } else {
      yield put(InsightActions.getListOfDashboardsError("error"));
    }
  } catch (e) {
    yield put(InsightActions.getListOfDashboardsError(e));
  }
}

function* createDashboard(action) {
  try {
    const response = yield call(api.post, `/api/crm/insight/add-dashboard`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.createDashboardSuccess(response.data));
      yield put(InsightActions.getListOfDashboardsRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message, "error");
      yield put(InsightActions.createDashboardError(response));
    } else {
      yield put(InsightActions.createDashboardError("error"));
    }
  } catch (e) {
    yield put(InsightActions.createDashboardError(e));
  }
}

function* editDashboard(action) {
  try {
    const response = yield call(api.patch, `/api/crm/insight/edit-dashboard/${action.payload._id}`, action.payload.data);
    if (response.status === 200) {
      yield put(InsightActions.editDashboardSuccess(response.data));
      yield put(InsightActions.getListOfDashboardsRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.editDashboardError(response));
    } else {
      yield put(InsightActions.editDashboardError("error"));
    }
  } catch (e) {
    yield put(InsightActions.editDashboardError(e));
  }
}

function* deleteDashboard(action) {
  try {
    const response = yield call(api.delete, `/api/crm/insight/delete-dashboard/${action.payload}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Dashboard successfully deleted!");
      yield put(InsightActions.deleteDashboardSuccess(response.data));
      yield put(InsightActions.getListOfDashboardsRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.deleteDashboardError(response));
    } else {
      yield put(InsightActions.deleteDashboardError("error"));
    }
  } catch (e) {
    yield put(InsightActions.deleteDashboardError(e));
  }
}

function* getSelectedDashboard(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/selected-dashboard/${action.payload}`);
    if (response.status === 200) {
      yield put(InsightActions.getSelectedDashboardSuccess(response.data.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.getSelectedDashboardError(response));
    } else {
      yield put(InsightActions.getSelectedDashboardError("error"));
    }
  } catch (e) {
    yield put(InsightActions.getSelectedDashboardError(e));
  }
}
function* editDashboardLayout(action) {
  try {
    const response = yield call(api.post, `/api/crm/insight/layout`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.editDashboardLayoutSuccess(response.data));
      // yield put(InsightActions.getListOfDashboardsRequestWithoutLoading());
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.editDashboardLayoutError(response));
    } else {
      yield put(InsightActions.editDashboardLayoutError("error"));
    }
  } catch (e) {
    yield put(InsightActions.editDashboardLayoutError(e));
  }
}

function* createInsightDashboardReport(action) {
  try {
    const response = yield call(api.post, `/api/crm/insight/add-report`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.createInsightDashboardReportSuccess(response.data));
      yield put(InsightActions.getSelectedDashboardRequest(action.payload.dashboard));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.createInsightDashboardReportError(response));
    } else {
      yield put(InsightActions.createInsightDashboardReportError("error"));
    }
  } catch (e) {
    yield put(InsightActions.createInsightDashboardReportError(e));
  }
}

function* editInsightDashboardReport(action) {
  try {
    const response = yield call(api.patch, `/api/crm/insight/edit-report`, action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Report successfully updated!");
      yield put(InsightActions.editInsightDashboardReportSuccess(response.data));
      yield put(InsightActions.getSelectedDashboardRequest(action.payload.dashboard));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.editInsightDashboardReportError(response));
    } else {
      yield put(InsightActions.editInsightDashboardReportError("error"));
    }
  } catch (e) {
    yield put(InsightActions.editInsightDashboardReportError(e));
  }
}

function* deleteInsightDashboardReport(action) {
  try {
    const response = yield call(api.delete, `/api/crm/insight/delete-report/${action.payload}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Report successfully deleted!");
      yield put(InsightActions.deleteInsightDashboardReportSuccess(response.data));
      yield put(InsightActions.getListOfDashboardsRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.deleteInsightDashboardReportError(response));
    } else {
      yield put(InsightActions.deleteInsightDashboardReportError("error"));
    }
  } catch (e) {
    yield put(InsightActions.deleteInsightDashboardReportError(e));
  }
}

function* getInsightsGraphReport(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/report-graph/${action.payload}`);
    if (response.status === 200) {
      yield action.cb({ status: true, data: response.data });
      yield put(InsightActions.getInsightGraphReportSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield action.cb({ status: false, message: response["message"] });
      yield put(InsightActions.getInsightGraphReportError(response));
    } else {
      yield action.cb({ status: false, message: "There is something wrong" });
      yield put(InsightActions.getInsightGraphReportError("error"));
    }
  } catch (e) {
    yield action.cb({ status: false, message: "There is something wrong" });
    yield put(InsightActions.getInsightGraphReportError(e));
  }
}

function* geListOfPipelines(action) {
  try {
    const response = yield call(api.get, `/api/crm/pipeline`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.getListOfPipelinesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.getListOfPipelinesError(response));
    } else {
      yield put(InsightActions.getListOfPipelinesError("error"));
    }
  } catch (e) {
    yield put(InsightActions.getListOfPipelinesError(e));
  }
}
function* getListOfUsers(action) {
  try {
    const response = yield call(api.get, `/api/crm/profile/list`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.getListOfUsersSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.getListOfUsersError(response));
    } else {
      yield put(InsightActions.getListOfUsersError("error"));
    }
  } catch (e) {
    yield put(InsightActions.getListOfUsersError(e));
  }
}

function* getTypeOfReportUsers(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/type-of-reports`, action.payload);
    if (response.status === 200) {
      yield put(InsightActions.getTypeOfReportSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response["message"], "error");
      yield put(InsightActions.getTypeOfReportError(response));
    } else {
      yield put(InsightActions.getTypeOfReportError("error"));
    }
  } catch (e) {
    yield put(InsightActions.getTypeOfReportError(e));
  }
}
function* getTypeOfUserRoleUsers(action) {
  try {
    const store = yield select();
    // store.insight.userRole
    if (action.payload.skipIfDataPresent && Array.isArray(store.InsightReducer.dashboardReducer.userRole.list.allUsers) && store.InsightReducer.dashboardReducer.userRole.list.allUsers.length > 0) {
      yield put(InsightActions.getUserRoleSuccess({ data: store.InsightReducer.dashboardReducer.userRole.list }));
    } else {
      const response = yield call(api.get, `/api/crm/profile/roles-wise-user-list`, action.payload);
      if (response.status === 200) {
        yield put(InsightActions.getUserRoleSuccess(response.data));
      } else if (response.status === 400) {
        dispatchSnackbarError(response["message"], "error");
        yield put(InsightActions.getUserRoleError(response));
      } else {
        yield put(InsightActions.getUserRoleError("error"));
      }
    }
  } catch (e) {
    yield put(InsightActions.getUserRoleError(e));
  }
}

//********************************GOALS*********************************************** */

export default function* InsightSaga() {
  yield takeLatest(GET_LIST_OF_DASHBOARDS_REQUEST, getListOfDashboards);
  yield takeLatest(GET_LIST_OF_DASHBOARDS_REQUEST_WITHOUT_LOADING, getListOfDashboards);
  yield takeLatest(CREATE_DASHBOARD_REQUEST, createDashboard);
  yield takeLatest(EDIT_DASHBOARD_REQUEST, editDashboard);
  yield takeLatest(DELETE_DASHBOARD_REQUEST, deleteDashboard);
  yield takeLatest(GET_SELECTED_DASHBOARD_REQUEST, getSelectedDashboard);
  yield takeLatest(EDIT_DASHBOARD_LAYOUT_REQUEST, editDashboardLayout);
  yield takeLatest(CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST, createInsightDashboardReport);
  yield takeLatest(EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST, editInsightDashboardReport);
  yield takeLatest(DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST, deleteInsightDashboardReport);
  yield takeLatest(GET_INSIGHTS_GRAPH_REPORT_REQUEST, getInsightsGraphReport);

  yield takeLatest(GET_LIST_OF_PIPELINES_REQUEST, geListOfPipelines);
  yield takeLatest(GET_LIST_OF_USERS_REQUEST, getListOfUsers);
  yield takeLatest(TYPE_OF_REPORT_REQUEST, getTypeOfReportUsers);
  yield takeLatest(USER_ROLE_REQUEST, getTypeOfUserRoleUsers);
}
