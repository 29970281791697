import { getTemplateCategory, getTemplateTags, getTemplatetList } from "../types/campaign_templates_types";

const initState = {
  categories: {
    loading: false,
    list: [],
  },
  tags: {
    data: {},
    loading: false,
  },
  data: {
    list: [],
    count: 0,
    loading: false,
  },
};

export default function campaignTemplatesReducer(state = initState, action = {}) {
  switch (action.type) {
    case getTemplateTags.GET_TEMPLATE_TAGS_REQUEST:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
        },
      };
    case getTemplateTags.GET_TEMPLATE_TAGS_SUCCESS:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
          data: action.payload.data,
        },
      };
    case getTemplateTags.GET_TEMPLATE_TAGS_ERROR:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
        },
      };

    case getTemplateCategory.GET_TEMPLATE_CATEGORY_REQUEST:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
        },
      };
    case getTemplateCategory.GET_TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          list: action.payload.data,
        },
      };
    case getTemplateCategory.GET_TEMPLATE_CATEGORY_ERROR:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
        },
      };

    case getTemplatetList.GET_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          loading: true,
        },
      };
    case getTemplatetList.GET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          loading: false,
          list: action.payload.data,
          count: action.payload.count,
        },
      };
    case getTemplatetList.GET_TEMPLATE_LIST_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          loading: false,
        },
      };
    default:
      return state;
  }
}
