import { getListOfVideos } from "../types/video.types";

const initState = {
  loading: false,
  list: [],
  count: 0,
};

export default function videoReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfVideos.GET_LIST_OF_VIDEOS_REQUEST:
      return {
        ...state,
        loading: action.payload.limit > 16 ? false : true,
        list: action.payload.limit > 16 ? state.list : [],
      };
    case getListOfVideos.GET_LIST_OF_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        count: action.payload.count,
      };
    case getListOfVideos.GET_LIST_OF_VIDEOS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
