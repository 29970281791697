import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown, InputGroup, Button, Form } from "react-bootstrap";
import { customColorMapping } from "../custom-tables/constants";
import SelectableContext from "react-bootstrap/SelectableContext";
import { dispatchSnackbarError } from "../../utils/toaster";

const options = ["email", "whatsapp", "sms", "rcs", "voice"];

export default function MultiOptOut({ field, value, setValue }) {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [show, setShow] = useState(value && value.length > 0 ? value.map((val) => false) : []);

  useEffect(() => {
    const filtered = options.filter((each) => value.indexOf(each) === -1);
    setAvailableOptions(filtered);
  }, [value]);

  const handleDeleteTag = (index) => {
    const filtered = value.filter((each, ind) => ind !== index);
    setValue(filtered, field.apiKeyName);
  };
  const handleAddValue = () => {
    if (value.length === 0) {
      dispatchSnackbarError("Fill all the previous values before adding!");
      return;
    }
    for (var i = 0; i < value.length; i++) {
      if (!value[i] || value[i] === "") {
        dispatchSnackbarError("Fill all the previous values before adding!");
        return;
      }
    }
    setValue([...value, ""], field.apiKeyName);
    setShow([...show, false]);
  };
  const getCustomTagClass = (_value) => {
    if (customColorMapping[_value]) return customColorMapping[_value];
    return "dark";
  };

  return (
    <>
      {value.map((_value, ind) => {
        return (
          <SelectableContext.Provider key={ind} value={false}>
            <InputGroup>
              <div className={"d-flex w-100 justify-content-between"}>
                <Dropdown
                  onSelect={(e, ev) => ev.stopPropagation()}
                  show={show[ind]}
                  onToggle={() => {
                    setShow([...show.slice(0, ind), !show[ind], ...show.slice(ind + 1)]);
                  }}
                  className={`dropdown-inline ${ind !== 0 ? "mt-1" : "mt-0"} ${value.length > 1 ? "w-100-56px" : "w-100"}`}
                >
                  <Dropdown.Toggle className="d-flex w-100 align-items-center text-left z-index-0 py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant={"transparent"}>
                    {_value ? (
                      <div className={`m-0 tag-ellipsis rounded-pill badge badge-light-${getCustomTagClass(_value)}`}>
                        {_.capitalize(_value)}
                        <br />
                      </div>
                    ) : (
                      "Select"
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="z-index-9999 dropdown-menu dropdown-menu-xl p-0 w-200px w-100-mn">
                    <div className="navi navi-hover">
                      {availableOptions.map((each, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onSelect={() => {
                              setValue([...value.slice(0, ind), each, ...value.slice(ind + 1)], field.apiKeyName);
                            }}
                          >
                            <div className={`m-0 tag-ellipsis rounded-pill badge badge-light-${getCustomTagClass(each)}`}>
                              {_.capitalize(each)}
                              <br />
                            </div>
                          </Dropdown.Item>
                        );
                      })}
                      {availableOptions.length === 0 && (
                        <div className="w-100 d-flex flex-center h-50px-mn">
                          <span>No Options</span>
                        </div>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                {value.length > 1 ? (
                  <InputGroup.Append>
                    <Button onClick={() => handleDeleteTag(ind)} size="sm" variant="transparent">
                      <i className="fa fa-minus-circle remove-condition-icon"></i>
                    </Button>
                  </InputGroup.Append>
                ) : null}
              </div>
            </InputGroup>
          </SelectableContext.Provider>
        );
      })}
      {options.length > value.length && (
        <Button className="mt-2 p-0 text-decoration-none" onClick={handleAddValue} variant="link">
          + Add {field.fieldName}
        </Button>
      )}
    </>
  );
}

export function MultiOptOutReason({ value, setValue, field }) {
  const handleAddValue = () => {
    if (value.length === 0) {
      dispatchSnackbarError("Fill all the previous values before adding!");
      return;
    }
    for (var i = 0; i < value.length; i++) {
      if (!value[i] || value[i] === "") {
        dispatchSnackbarError("Fill all the previous values before adding!");
        return;
      }
    }
    setValue([...value, ""], field.apiKeyName);
  };
  const handleDeleteTag = (index) => {
    const filtered = value.filter((each, ind) => ind !== index);
    setValue(filtered, field.apiKeyName);
  };
  return (
    <>
      {value.map((_value, ind) => {
        return (
          <SelectableContext.Provider key={ind} value={false}>
            <InputGroup>
              <div className={"d-flex w-100 justify-content-between mt-1"}>
                <Form.Control value={_value} onChange={(e) => setValue([...value.slice(0, ind), e.target.value, ...value.slice(ind + 1)], field.apiKeyName)} />
                {value.length > 1 ? (
                  <InputGroup.Append>
                    <Button onClick={() => handleDeleteTag(ind)} size="sm" variant="transparent">
                      <i className="fa fa-minus-circle remove-condition-icon"></i>
                    </Button>
                  </InputGroup.Append>
                ) : null}
              </div>
            </InputGroup>
          </SelectableContext.Provider>
        );
      })}
      {
        <Button className="mt-2 p-0 text-decoration-none" onClick={handleAddValue} variant="link">
          + Add {field.fieldName}
        </Button>
      }
    </>
  );
}
