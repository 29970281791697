import { call, put, takeLatest } from "redux-saga/effects";
import { endpoints } from "../endpoints";
import * as cadenceAnalyticsSummarizeActions from "../actions/cadence_analytics_summarize_actions";
import * as cadenceAnalyticsSummarizeTypes from "../types/cadence_analytics_summarize_types";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError } from "../../../../../utils/toaster";

function* getCadenceAnalyticsSummarize(action) {
  try {
    const res = yield call(api.get, `${endpoints.CADENCE_ANALYTICS_SUMMARIZE}/${action.payload.id}`, action.payload.query);
    if (res.status === 200) {
      yield put(cadenceAnalyticsSummarizeActions.getCadenceAnalyticsSummarizeSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceAnalyticsSummarizeActions.getCadenceAnalyticsSummarizeError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceAnalyticsSummarizeActions.getCadenceAnalyticsSummarizeError(e));
  }
}

export default function* cadenceAnalyticsSummarizeSaga() {
  yield takeLatest(cadenceAnalyticsSummarizeTypes.getCadenceAnalyticsSummarize.GET_CADENCE_ANALYTICS_SUMMARIZE_REQUEST, getCadenceAnalyticsSummarize);
}
