export const getHostedAuthLink = {
  REQUEST: "GET_HOSTED_AUTH_LINK_REQUEST",
  SUCCESS: "GET_HOSTED_AUTH_LINK_SUCCESS",
  ERROR: "GET_HOSTED_AUTH_LINK_ERROR",
};

export const hostedAuthCallback = {
  REQUEST: "HOSTED_AUTH_CALLBACK_REQUEST",
  SUCCESS: "HOSTED_AUTH_CALLBACK_SUCCESS",
  ERROR: "HOSTED_AUTH_CALLBACK_ERROR",
};

export const getListOfSyncAccounts = {
  REQUEST: "GET_LIST_OF_SYNC_ACCOUNTS_REQUEST",
  SECONDARY_REQUEST: "GET_LIST_OF_SYNC_ACCOUNTS_SECONDARY_REQUEST",
  SUCCESS: "GET_LIST_OF_SYNC_ACCOUNTS_SUCCESS",
  ERROR: "GET_LIST_OF_SYNC_ACCOUNTS_ERROR",
};

export const updateSyncAccountDetails = {
  REQUEST: "UPDATE_SYNC_ACCOUNT_DETAILS_REQUEST",
  SUCCESS: "UPDATE_SYNC_ACCOUNT_DETAILS_SUCCESS",
  ERROR: "UPDATE_SYNC_ACCOUNT_DETAILS_ERROR",
};

export const deleteSyncAccountDetails = {
  REQUEST: "DELETE_SYNC_ACCOUNT_DETAILS_REQUEST",
  SUCCESS: "DELETE_SYNC_ACCOUNT_DETAILS_SUCCESS",
  ERROR: "DELETE_SYNC_ACCOUNT_DETAILS_ERROR",
};

export const getSyncCalendarList = {
  REQUEST: "GET_SYNC_CALENDAR_LIST_REQUEST",
  SUCCESS: "GET_SYNC_CALENDAR_LIST_SUCCESS",
  ERROR: "GET_SYNC_CALENDAR_LIST_ERROR",
};

export const sendNyalsSyncEmail = {
  REQUEST: "SEND_NYALS_SYNC_EMAIL_REQUEST",
  SUCCESS: "SEND_NYALS_SYNC_EMAIL_SUCCESS",
  ERROR: "SEND_NYALS_SYNC_EMAIL_ERROR",
};

export const uploadSyncNylasAttchment = {
  REQUEST: "UPLOAD_SYNC_NYLAS_ATTCHMENT_REQUEST",
  SUCCESS: "UPLOAD_SYNC_NYLAS_ATTCHMENT_SUCCESS",
  ERROR: "UPLOAD_SYNC_NYLAS_ATTCHMENT_ERROR",
};

export const markAsDefaultSyncAccount = {
  REQUEST: "MARK_AS_DEFAULT_SYNC_ACCOUNT_REQUEST",
  SUCCESS: "MARK_AS_DEFAULT_SYNC_ACCOUNT_SUCCESS",
  ERROR: "MARK_AS_DEFAULT_SYNC_ACCOUNT_ERROR",
};
