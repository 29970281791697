import styled, { css } from "styled-components";

export const LeadStateWrapper = styled.div`
  display: flex;
  //   padding: 0 15px;
  // margin-bottom: 20px;
  align-items: center;
  overflow-x: auto;

  > button {
    margin-left: 10px;
  }
`;

export const StageItemName = styled.div`
  font-size: 8px;
  position: absolute;
  color: #c5c5c5;
`;

export const StateItem = styled.div`
  flex: 1;
  padding: 5px 15px;
  line-height: 15px;
  position: relative;
  color: ${(props) => (props.active ? "#FFF" : "rgba(#444, 0.8)")};
  background: ${(props) => (props.active ? "#3B85F4" : "#e6e6e6")};
  border-right: none;
  margin-right: 7.5px;
  margin-left: 5px;
  font-weight: 500;
  height: 24px;
  transition: opacity 0.3s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }

  &:first-child {
    //   border-top-left-radius: 17px;
    //   border-bottom-left-radius: 17px;
    margin-left: 0;

    &:after,
    &:before {
      left: 7.5px;
    }
  }

  &:last-child {
    //   border-top-right-radius: 17px;
    //   border-bottom-right-radius: 17px;
    margin-right: 0;

    &:after,
    &:before {
      right: 15px;
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 50%;
    left: 0;
    right: -5px;
    left: -5px;
  }

  &:after {
    background: #e6e6e6;
    transform: skew(-30deg) translate3d(0, 0, 0);
    bottom: 0;
  }

  &:before {
    background: #e6e6e6;
    transform: skew(28deg) translate3d(0, 0, 0);
    top: 0;
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;
      background: #99e8a7;

      &:after {
        background: #99e8a7;
      }

      &:before {
        background: #99e8a7;
      }
    `};

  ${(props) =>
    props.past &&
    css`
      color: #fff;
      background: #99e8a7;

      &:after {
        background: #99e8a7;
      }

      &:before {
        background: #99e8a7;
      }
    `};

  > div {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      margin-right: 5px;
    }
  }

  span {
    opacity: 0.8;
    font-weight: normal;
    font-size: 90%;
    margin-left: 5px;
  }
`;

export const StateItem2 = styled.div`
  flex: 1;
  // padding: 5px 10px;
  line-height: 15px;
  position: relative;
  color: ${(props) => (props.active ? "#FFF" : "rgba(#444, 0.8)")};
  background: ${(props) => (props.active ? "#3B85F4" : "#e6e6e6")};
  border-right: none;
  margin-right: 7.5px;
  margin-left: 8px;
  font-weight: 500;
  height: 24px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }

  // &:first-child {
  //     margin-left: 0;

  //     &:after, &:before {
  //         left: 7.5px;
  //     }
  // }

  // &:last-child {
  //     margin-right: 0;

  //     &:after, &:before {
  //         right: 15px;
  //     }
  // }

  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 50%;
    left: 0;
    right: -5px;
    left: -5px;
  }

  &:after {
    background: #e6e6e6;
    transform: skew(-30deg) translate3d(0, 0, 0);
    bottom: 0;
  }

  &:before {
    background: #e6e6e6;
    transform: skew(28deg) translate3d(0, 0, 0);
    top: 0;
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;
      background: #99e8a7;

      &:after {
        background: #99e8a7;
      }

      &:before {
        background: #99e8a7;
      }
    `};

  ${(props) =>
    props.past &&
    css`
      color: #fff;
      background: #99e8a7;

      &:after {
        background: #99e8a7;
      }

      &:before {
        background: #99e8a7;
      }
    `};

  > div {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      margin-right: 5px;
    }
  }

  span {
    opacity: 0.8;
    font-weight: normal;
    font-size: 90%;
    margin-left: 5px;
  }
`;
