import { getListOfActivitySavedFilters, createActivitySavedFilter, editActivitySavedFilter, deleteActivitySavedFilters, markAsFavoriteActivitySaveFilter } from "../types/activity.types";

export const getListOfActivitySavedFiltersRequest = (req) => ({
  type: getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_REQUEST,
  payload: req,
});
export const getListOfActivitySavedFiltersSuccess = (req) => ({
  type: getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_SUCCESS,
  payload: req,
});
export const getListOfActivitySavedFiltersError = (req) => ({
  type: getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_ERROR,
  payload: req,
});

export const createActivitySavedFilterRequest = (req, cb) => ({
  type: createActivitySavedFilter.CREATE_ACTIVITY_SAVED_FILTER_REQUEST,
  payload: req,
  cb,
});
export const createActivitySavedFilterSuccess = (req) => ({
  type: createActivitySavedFilter.CREATE_ACTIVITY_SAVED_FILTER_SUCCESS,
  payload: req,
});
export const createActivitySavedFilterError = (req) => ({
  type: createActivitySavedFilter.CREATE_ACTIVITY_SAVED_FILTER_ERROR,
  payload: req,
});

export const editActivitySavedFilterRequest = (req, cb) => ({
  type: editActivitySavedFilter.EDIT_ACTIVITY_SAVED_FILTER_REQUEST,
  payload: req,
  cb,
});
export const editActivitySavedFilterSuccess = (req) => ({
  type: editActivitySavedFilter.EDIT_ACTIVITY_SAVED_FILTER_SUCCESS,
  payload: req,
});
export const editActivitySavedFilterError = (req) => ({
  type: editActivitySavedFilter.EDIT_ACTIVITY_SAVED_FILTER_ERROR,
  payload: req,
});

export const deleteActivitySavedFiltersRequest = (req) => ({
  type: deleteActivitySavedFilters.DELETE_ACTIVITY_SAVED_FILTER_REQUEST,
  payload: req,
});
export const deleteActivitySavedFiltersSuccess = (req) => ({
  type: deleteActivitySavedFilters.DELETE_ACTIVITY_SAVED_FILTER_SUCCESS,
  payload: req,
});
export const deleteActivitySavedFiltersError = (req) => ({
  type: deleteActivitySavedFilters.DELETE_ACTIVITY_SAVED_FILTER_ERROR,
  payload: req,
});

export const markAsFavoriteActivitySaveFilterRequest = (req) => ({
  type: markAsFavoriteActivitySaveFilter.MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_REQUEST,
  payload: req,
});
export const markAsFavoriteActivitySaveFilterSuccess = (req) => ({
  type: markAsFavoriteActivitySaveFilter.MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_SUCCESS,
  payload: req,
});
export const markAsFavoriteActivitySaveFilterError = (req) => ({
  type: markAsFavoriteActivitySaveFilter.MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_ERROR,
  payload: req,
});
