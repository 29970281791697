import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

//actions
import { GetLinkedEmailsRequest } from "../../../containers/Settings/actions/notes.actions";

//styles
import "./styles.scss";
import { getEmailContent } from "./timeline-v2.helper";

export function EmailsListLogs(props) {
  const [logArray, setLogArray] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [callLogsFromStart, setCallLogsFromStart] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const queryMaker = {
      limit: 10,
      pageNo: 1,
    };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }

    dispatch(
      GetLinkedEmailsRequest(queryMaker, (response) => {
        if (response.status) {
          setLogArray(response.data);
          setLoading(false);
          setTotalLogs(response.count);
        } else {
          setLoading(false);
        }
      }),
    );
  }, [dispatch, props.peopleId, props.companyId, props.leadId, props.dealId, props.type]);

  const onCallLogsFromStart = useCallback(() => {
    const queryMaker = {
      limit: limit,
      pageNo: 1,
    };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }

    dispatch(
      GetLinkedEmailsRequest(queryMaker, (response) => {
        if (response.status) {
          setLogArray(response.data);
          setLoading(false);
          setPage(1);
          setTotalLogs(response.count);
        } else {
          setLoading(false);
        }
      }),
    );
  }, [dispatch, props.peopleId, props.companyId, props.leadId, props.dealId, props.type, limit]);

  const onLoadMoreClick = () => {
    const queryMaker = {
      limit: limit,
      pageNo: page + 1,
    };
    if (props.type === "people") {
      queryMaker["peopleId"] = props.peopleId;
    } else if (props.type === "lead") {
      queryMaker["leadId"] = props.leadId;
    } else if (props.type === "deal") {
      queryMaker["dealId"] = props.dealId;
    } else if (props.type === "company") {
      queryMaker["companyId"] = props.companyId;
    } else {
      queryMaker["peopleId"] = props.peopleId;
      queryMaker["companyId"] = props.companyId;
      queryMaker["leadId"] = props.leadId;
      queryMaker["dealId"] = props.dealId;
    }
    setLoading(true);
    dispatch(
      GetLinkedEmailsRequest(queryMaker, (response) => {
        if (response.status) {
          const _logs = [...logArray];
          _logs.push(...response.data);
          setPage(page + 1);
          setLogArray(_logs);
          setLoading(false);
          setTotalLogs(response.count);
        } else {
          setLoading(false);
        }
      }),
    );
  };
  useEffect(() => {
    if (props.emailSentLoading !== callLogsFromStart && callLogsFromStart !== true) {
      setCallLogsFromStart(props.emailSentLoading);
    } else if (props.emailSentLoading === false && callLogsFromStart === false) {
      setCallLogsFromStart(props.emailSentLoading);
    } else if (props.emailSentLoading !== callLogsFromStart && callLogsFromStart === true) {
      setCallLogsFromStart(props.emailSentLoading);
      onCallLogsFromStart();
    }
  }, [props.emailSentLoading, onCallLogsFromStart, callLogsFromStart]);
  return (
    <>
      <div>
        {false ? (
          <div className="w-100 d-flex p-7 justify-content-center align-items-center">
            {" "}
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="note-header-text">Linked Emails</div>
            {logArray.length > 0 ? (
              <div>
                {" "}
                {logArray.map((item, index) => {
                  return (
                    <div className="mb-40px" key={index}>
                      {getEmailContent(item, () => {}, null, props.showActions, props.handleActionClick)}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="note-no-data-text">This record doesn't have any emails.</div>
            )}
            {totalLogs > logArray.length ? (
              <div className="d-flex justify-content-center">
                <span className="btn btn-secondary py-7px px-20px h-34px" onClick={() => onLoadMoreClick()}>
                  {loading && <Spinner animation="border" variant="primary" className="mr-10px w-16px h-16px"></Spinner>}
                  Load More
                </span>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}
