import React from "react";
import { Form, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";

const infoTextMessages = {
  CADENCE_NAME: "What would you like to name it?",
};

export default function CadenceNameStep1(props) {
  return (
    <div className="cadence-steps-container card">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              Cadence Name{" "}
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.CADENCE_NAME}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control id="cadence-name" type="text" placeholder="Please Enter Cadence Name" value={props.name} onChange={(e) => props.setName(e.target.value)} />
          </Form.Group>
        </Col>
        <Col>{/* <Form.Group>Cadence Stats</Form.Group> */}</Col>
      </Row>
    </div>
  );
}
