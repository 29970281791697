import React from "react";
import CustomSidePanel from "../side-pannel-detailed/custom-components/custom-side-pannel";
import AIWizardLayout from "./AIWizardLayout";

function AIWizardSidePanel({ show, handleClose }) {
  return (
    <CustomSidePanel show={show} moduleName={"AI Wizard"} handleClose={handleClose}>
      <AIWizardLayout />
    </CustomSidePanel>
  );
}

export default AIWizardSidePanel;
