import {
  DEALLIST_FIELDS_REQUEST,
  DEALLIST_FIELDS_SUCCESS,
  DEALLIST_FIELDS_ERROR,
  DEALLIST_SHOWORHIDE_REQUEST,
  DEALLIST_SHOWORHIDE_SUCCESS,
  DEALLIST_SHOWORHIDE_ERROR,
  UPDATE_COLUMNFIELD_PRIORITY_REQUEST,
  UPDATE_COLUMNFIELD_PRIORITY_SUCCESS,
  UPDATE_COLUMNFIELD_PRIORITY_ERROR,
  GET_DEAL_LIST_REQUEST,
  GET_DEAL_LIST_SUCCESS,
  GET_DEAL_LIST_ERROR,
  DEALCELLEDIT_REQUEST,
  DEALCELLEDIT_SUCCESS,
  DEALCELLEDIT_ERROR,
  UPDATE_DEAL_LIST_LOADING_DATA,
  GET_DEAL_LIST_REQUEST_AFTER,
  getDealAddFormFields,
  generateDealAIWFData
} from "./types";

// Editing Cell
export function dealCellEditRequest(req) {
  return {
    type: DEALCELLEDIT_REQUEST,
    payload: req,
  };
}

export function dealCellEditSuccess(res) {
  return {
    type: DEALCELLEDIT_SUCCESS,
    payload: res,
  };
}

export function dealCellEditError(err) {
  return {
    type: DEALCELLEDIT_ERROR,
    payload: err,
  };
}

//GET DEAL LIST API
export function getDealListRequest(req) {
  return {
    type: GET_DEAL_LIST_REQUEST,
    payload: req,
  };
}
export function getDealListRequestAfter(req) {
  return {
    type: GET_DEAL_LIST_REQUEST_AFTER,
    payload: req,
  };
}
export function getDealListSuccess(res) {
  return {
    type: GET_DEAL_LIST_SUCCESS,
    payload: res,
  };
}
export function getDealListError(err) {
  return {
    type: GET_DEAL_LIST_ERROR,
    payload: err,
  };
}

// Update fields
// export function updateColumnFieldsRequest(req) {
//     return {
//         type: COLUMN_FIELD_UPDATE_REQUEST,
//         payload: req
//     }
// };

// export function updateColumnFieldsSuccess(res) {
//     return {
//         type: COLUMN_FIELD_UPDATE_SUCCESS,
//         payload: res
//     }
// };

// export function updateColumnFieldsError(err) {
//     return {
//         type: COLUMN_FIELD_UPDATE_ERROR,
//         payload: err
//     }
// };

//Update column fields priority
export function updateColumnFieldsPriorityRequest(req) {
  return {
    type: UPDATE_COLUMNFIELD_PRIORITY_REQUEST,
    payload: req,
  };
}
export function updateColumnFieldsPrioritySuccess(res) {
  return {
    type: UPDATE_COLUMNFIELD_PRIORITY_SUCCESS,
    payload: res,
  };
}
export function updateColumnFieldsPriorityError(err) {
  return {
    type: UPDATE_COLUMNFIELD_PRIORITY_ERROR,
    payload: err,
  };
}

//SHOW OR HIDE DEAL LIST COLUMN FIELDS
export function showOrHideDealListColumnFieldsRequest(req) {
  return {
    type: DEALLIST_SHOWORHIDE_REQUEST,
    payload: req,
  };
}
export function showOrHideDealListColumnFieldsSuccess(res) {
  return {
    type: DEALLIST_SHOWORHIDE_SUCCESS,
    payload: res,
  };
}
export function showOrHideDealListColumnFieldsError(err) {
  return {
    type: DEALLIST_SHOWORHIDE_ERROR,
    payload: err,
  };
}

//GET DEAL LIST COLUMN FIELDS
export function getDealListFieldsRequest(req) {
  return {
    type: DEALLIST_FIELDS_REQUEST,
    payload: req,
  };
}
export function getDealListFieldsSuccess(res) {
  return {
    type: DEALLIST_FIELDS_SUCCESS,
    payload: res,
  };
}
export function getDealListFieldsError(err) {
  return {
    type: DEALLIST_FIELDS_ERROR,
    payload: err,
  };
}

export function updateDealListLoadingData(req) {
  return {
    type: UPDATE_DEAL_LIST_LOADING_DATA,
    payload: req,
  };
}

//deal custom fields
export function getDealAddFormFieldsRequest(req) {
  return {
    type: getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_REQUEST,
    payload: req,
  };
}
export function getDealAddFormFieldsSuccess(req) {
  return {
    type: getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_SUCCESS,
    payload: req,
  };
}
export function getDealAddFormFieldsError(req) {
  return {
    type: getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_ERROR,
    payload: req,
  };
}

export const generateDealAIWFDataRequest = (req) => ({
  type: generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_REQUEST,
  payload: req,
})
export const generateDealAIWFDataSuccess = (req) => ({
  type: generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_SUCCESS,
  payload: req,
})
export const generateDealAIWFDataError = (req) => ({
  type: generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_ERROR,
  payload: req,
})
