import React, { useEffect, useState } from "react";
import AdvancedAccordionComponent from "../../../../../../components/accordion/advanced-accordion-component";
import { Form, Row } from "react-bootstrap";
import { whatsappInputMaxLength } from "../constants";
import { getVariableKeys } from "../../../../../../utils/variables.util";
import { useSelector } from "react-redux";
import { SimpleGroupDropdown } from "../../../../../../components/dropdowns/simple-dropdown";

export default function WhatsappAddTemplateBody(props) {
    const [activeKey, setActiveKey] = useState("0");
    const [mapFieldOptions, setMapFieldOptions] = useState([]);
    const [showHelpText, setShowHelpText] = useState(false);

    const peopleColumns = useSelector((state) => state.People.peopleFields.list);
    const companyColumns = useSelector((state) => state.Companies.companyFields.list);
    const leadColumns = useSelector((state) => state.Leads.leadFields.list);
    const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields.list);

    useEffect(() => {
        const options = [];
        const options1 = [];
        const options2 = [];
        const options3 = [];

        peopleColumns.forEach((column) => {
            options.push({ label: `Peple - ${column.fieldName}`, value: `people.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        companyColumns.forEach((column) => {
            options1.push({ label: `Company - ${column.fieldName}`, value: `company.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        leadColumns.forEach((column) => {
            options2.push({ label: `Lead - ${column.fieldName}`, value: `lead.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        marketingColumns.forEach((column) => {
            options3.push({ label: `Marketing - ${column.fieldName}`, value: `marketing.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        setMapFieldOptions({
            "Lead Fields": options2,
            "People Fields": options,
            "Company Fields": options1,
            "Marketing Fields": options3
        });

    }, [peopleColumns, companyColumns, leadColumns, marketingColumns])


    const handleBodyContentAddVariable = () => {
        if (props.bodyContent.length < whatsappInputMaxLength.WHATSAPP_BODY_TEXT) {
            handleBodyContentChange(props.bodyContent + `{{${props.bodyContentVariables.length + 1}}}`);
        }
    }

    const handleBodyContentChange = (value) => {
        props.setBodyContent(value);
        const keys = getVariableKeys(value);
        const bodyVariables = {};
        props.bodyContentVariables.forEach((key) => {
            bodyVariables[key.id.toString()] = { value: key.value, mapField: key.mapField }
        });
        const updatedValues = [];
        keys.forEach((key) => {
            updatedValues.push({
                id: key.toString(),
                value: bodyVariables[key.toString()] ? bodyVariables[key.toString()].value : "",
                mapField: bodyVariables[key.toString()] ? bodyVariables[key.toString()].mapField : ""
            })
        })
        props.setBodyContentVariables(updatedValues);
    }

    return <AdvancedAccordionComponent
        title="Body"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        tooltipMessage={"Add a title that you want to show in message header."}
    >
        <div className="body-fields">
            <div className="row">
                <div className="col-md-12">
                    <Form.Group>
                        <Form.Label className="d-flex flex-stack">
                            <span>Body Content</span>
                            <span className={`cursor-pointer`} onClick={handleBodyContentAddVariable}>+ Add Variable</span>
                        </Form.Label>
                        <Form.Control type="text" as="textarea" rows={4} cols={20} maxLength={1024}
                            value={props.bodyContent} onChange={e => handleBodyContentChange(e.target.value)}
                        />
                        <p className="my-3" onClick={()=>setShowHelpText(!showHelpText)}>For text format <span className="cursor-pointer text-primary">click here</span></p>
                        {showHelpText && <p className="form-field mb-3 wa_notifier_body_text_field ">
                            <span className="description">Only text is allowed, HTML will not work, You can format the text using the following shorthands:<br />
                                <br />Bold: *text* will become <b>text</b>
                                <br />Italics: _text_ will become <em>text</em>
                                <br />Strikethrough: ~text~ will become <s>text</s>
                                <br />Monospace or code: ```text``` will become <code style={{ color: 'inherit' }}>text</code>
                            </span>
                        </p>}
                    </Form.Group>

                </div>
                {props.bodyContentVariables.length > 0 && <div className="card card-body bg-light mx-4">
                    <p className="description">Enter sample text for the variables you're adding to the template. WhatsApp reviews the content to check if it complies their guidelines before approving it.</p>
                    <div className="row">
                        {props.bodyContentVariables.map((variable, index) => {
                            return <Row>
                                <Form.Group className="col-md-6">
                                    <Form.Label>{`Sample text for variable ${variable.id}`}</Form.Label>
                                    <Form.Control type="text" maxLength={256} value={variable.value} onChange={(e) => {
                                        const updatedValues = [...props.bodyContentVariables];
                                        updatedValues[index].value = e.target.value;
                                        props.setBodyContentVariables(updatedValues);
                                    }} />
                                </Form.Group>
                                <Form.Group className="col-md-6">
                                    <Form.Label>{`System Maping Field ${variable.id}`}</Form.Label>
                                    <SimpleGroupDropdown
                                        value={props.bodyContentVariables[index].mapField}
                                        label={"Select System Map Field"}
                                        options={mapFieldOptions}
                                        handleSelect={(value) => {
                                            const updatedValues = [...props.bodyContentVariables];
                                            updatedValues[index].mapField = value.value;
                                            props.setBodyContentVariables(updatedValues);
                                        }}
                                        showSearch
                                    />
                                </Form.Group>
                            </Row>
                        })}
                    </div>
                </div>}
            </div>
        </div>
    </AdvancedAccordionComponent>
}