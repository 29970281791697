import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import { ContentWrapper2 } from "../../Wrapper";
import CategoryFilter from "./category-filter";

//actions
import * as VideoActions from "../store/actions/video.action";
import * as VideoCategoryActions from "../store/actions/category.action";
import * as insightActions from "../../insights/actions";

//styles
import "./styles.scss";
import { ContentLoadingWithoutCard } from "../../../components/loading";
import VideoCard from "../component/video-card";
import _ from "lodash";
import { BlueGradientButton } from "../../../components/button";
import AddVideo from "./add-video.form";
import { DeleteModal } from "../../../components/modal";
import SharedOwnedDropdown from "../component/share-owned.dropdown";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";

export function LearnList(props) {
  const links = [
    { name: "Public", to: "/learn/public", icon: "bi bi-globe" },
    { name: "Company", to: "/learn/company", icon: "bi bi-building" },
  ];

  const [secondTabName, setSecondTabName] = useState("All");
  const [tabName, setTabName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("all");
  const [selectedCategoryName, setSelectedCategoryName] = useState("All");
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [deletableId, setDelatableId] = useState("");
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ediableData, setEditableData] = useState({});
  const [pageNo] = useState(1);
  const [limit, setLimit] = useState(16);

  const history = useHistory();
  const dispatch = useDispatch();

  const categoryStore = useSelector((store) => store.Learn.category);
  const videoStore = useSelector((store) => store.Learn.video);

  useEffect(() => {
    if (history.location.pathname) {
      const url = history.location.pathname;
      if (url.includes("company")) {
        setLimit(16);
        setTabName("Company");
      } else {
        setLimit(16);
        setTabName("Public");
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (tabName) {
      dispatch(VideoCategoryActions.getListOfVideoCategoriesRequest({ type: tabName.toLowerCase() }));
      setSelectedCategoryId();
      setSelectedCategoryName();
    }
  }, [dispatch, tabName]);

  useEffect(() => {
    if (selectedCategoryId && secondTabName && tabName) {
      dispatch(
        VideoActions.getListOfVideosRequest({
          type: tabName.toLowerCase(),
          categoryId: selectedCategoryId,
          shareType: secondTabName.toLowerCase(),
          limit: limit,
          pageNo: pageNo,
        }),
      );
    }
  }, [dispatch, selectedCategoryId, tabName, secondTabName, pageNo, limit]);

  useEffect(() => {
    if (categoryStore.list.length > 0 && !selectedCategoryId) {
      setSelectedCategoryId("all");
      setSelectedCategoryName("All");
    }
  }, [categoryStore.list, selectedCategoryId]);

  const handleCategoryClick = (id, name) => {
    setSelectedCategoryId(id);
    setSelectedCategoryName(name);
    setLimit(16);
  };
  const handleVideoCardClick = (data) => {
    history.push(`/learn/${tabName.toLowerCase()}/${data._id}`);
  };
  const handleSave = (data) => {
    if (isEdit) {
      dispatch(
        VideoActions.editVideoRequest({
          id: ediableData._id,
          data: data,
          filter: {
            type: tabName.toLowerCase(),
            categoryId: selectedCategoryId,
            shareType: secondTabName.toLowerCase(),
            limit: limit,
            pageNo: pageNo,
          },
        }),
      );
      setIsEdit(false);
      setEditableData({});
      setShowAddVideo(false);
    } else {
      dispatch(
        VideoActions.createVideoRequest({
          data: data,
          filter: {
            type: tabName.toLowerCase(),
            categoryId: selectedCategoryId,
            shareType: secondTabName.toLowerCase(),
            limit: limit,
            pageNo: pageNo,
          },
        }),
      );
      setIsEdit(false);
      setEditableData({});
      setShowAddVideo(false);
    }
  };

  const onSecondTabCLick = (name) => {
    setSecondTabName(name);
  };

  const handleEditVideo = (data) => {
    setIsEdit(true);
    setEditableData(data);
    setShowAddVideo(true);
  };
  const handleDeleteVideo = (id) => {
    setDelatableId(id);
    setShowDeleteVideo(true);
  };

  const handleDeleteVideoAccept = () => {
    dispatch(
      VideoActions.deleteVideoRequest({
        data: { id: deletableId },
        filter: {
          type: tabName.toLowerCase(),
          categoryId: selectedCategoryId,
          shareType: secondTabName.toLowerCase(),
          limit: limit,
          pageNo: pageNo,
        },
      }),
    );
    setShowDeleteVideo(false);
    setDelatableId("");
  };

  const handleLoadMoreClick = () => {
    setLimit(limit + 16);
  };

  const addCategoryAPI = (categoryName) => {
    dispatch(
      VideoCategoryActions.createVideoCategoryRequest({
        data: { name: categoryName },
        filter: { type: tabName.toLowerCase() },
      }),
    );
  };
  const editCategoryAPI = (categoryId, categoryName) => {
    dispatch(
      VideoCategoryActions.editVideoCategoryRequest({
        id: categoryId,
        data: { name: categoryName },
        filter: { type: tabName.toLowerCase() },
      }),
    );
  };
  const deleteCategoryAPI = (categoryId) => {
    dispatch(
      VideoCategoryActions.deleteVideoCategoryRequest({
        data: { id: categoryId },
        filter: { type: tabName.toLowerCase() },
      }),
    );
  };

  const deleteModalProps = {
    show: showDeleteVideo,
    title: `Delete Video`,
    handleClose: () => {
      setShowDeleteVideo(false);
      setDelatableId("");
    },
    handleAccept: handleDeleteVideoAccept,
  };

  return (
    <ContentWrapper2
      subHeader={
        <CustomSubHeader>
          <div className="d-flex flex-row flex-stack w-100">
            <div>
              <SubheaderNav links={links} active={tabName} />
            </div>
            <div className="d-flex flex-row align-items-center gap-12px">
              {tabName === "Company" && <SharedOwnedDropdown value={secondTabName} handleClick={onSecondTabCLick} />
              // <SubheaderNav onClick={onSecondTabCLick} links={secondLinks} active={secondTabName} />
              }
              {tabName === "Company" && (
                <BlueGradientButton onClick={() => setShowAddVideo(true)} variant="success" size="sm">
                  + Video
                </BlueGradientButton>
              )}
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      <div className="learn-container">
        {_.isEmpty(categoryStore.list) && _.isEmpty(videoStore.list) && !videoStore.loading && !categoryStore.loading ? (
          <CustomEmptyPage page="learn" setShowAdd={() => setShowAddVideo(true)} />
        ) : (
          <>
            <CategoryFilter type={tabName} handleCategoryClick={handleCategoryClick} selectedCategoryId={selectedCategoryId} />
            <div className="learn-video-list-wrapper">
              {videoStore.loading || categoryStore.loading ? (
                <ContentLoadingWithoutCard />
              ) : _.isEmpty(videoStore.list) ? (
                secondTabName === "Shared" ? (
                  <Card className="h-vh-230px bg-transparent">
                    <Card.Body className="p-0 d-flex flex-column align-items-center pb-10">
                      <div className="card-px text-center py-5 my-5">
                        <h2 className="fs-2x fw-bolder mb-5">Shared Videos</h2>
                        <p className="text-gray-700 fs-5 mb-8">{"Your team hasn’t shared any videos with you."}</p>
                      </div>
                      <img src={require("../../../images/illustrations/__video.svg")} className="h-50vh mx-auto flex-grow-1" alt="add" />
                    </Card.Body>
                  </Card>
                ) : (
                  <CustomEmptyPage page="learn" setShowAdd={() => setShowAddVideo(true)} />
                )
              ) : (
                <div>
                  <div className="d-flex gap-4 pb-6 align-items-center">
                    <span className="category-head-border"></span>
                    <span className="category-head-title">{selectedCategoryName}</span>
                  </div>
                  <Row xs={2} md={3} lg={4}>
                    {videoStore.list.map((each, index) => {
                      return (
                        <Col>
                          {" "}
                          <VideoCard data={each} key={index} type={tabName} handleEditVideo={handleEditVideo} handleDeleteVideo={handleDeleteVideo} handleVideoCardClick={handleVideoCardClick} />
                        </Col>
                      );
                    })}
                  </Row>
                  {videoStore.list.length < videoStore.count && (
                    <div className="d-flex justify-content-center">
                      <span className="btn btn-secondary py-7px px-20px h-34px" onClick={() => handleLoadMoreClick()}>
                        {false && <Spinner animation="border" variant="primary" className="mr-10px w-16px h-16px"></Spinner>}
                        Load More
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <AddVideo
        show={showAddVideo}
        categoryList={categoryStore.list}
        isEdit={isEdit}
        ediableData={ediableData}
        handleClose={() => setShowAddVideo(false)}
        handleSave={handleSave}
        addAPI={addCategoryAPI}
        editAPI={editCategoryAPI}
        deleteAPI={deleteCategoryAPI}
      />
      <DeleteModal modalProps={deleteModalProps}>Are you sure you want to delete the video?</DeleteModal>
    </ContentWrapper2>
  );
}
