import React from "react";
import { Button } from "react-bootstrap";
import { BlueGradientButton } from "../../button";
import TagSelect from "../../custom_form/tags";

export default function TemplateMainActions(props) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <div>
        {" "}
        <TagSelect
          iconBasedFilter={true}
          page={"template"}
          pageSub={"template"}
          addTag={props.addTag}
          editTag={props.editTag}
          deleteTag={props.deleteTag}
          field={{ fieldName: "Tags", apiKeyName: "tags" }}
          value={!props.tags || props.tags === "" || props.tags.length === 0 ? [""] : props.tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
          setValue={(value) => props.setTags(value)}
          addTextHide={false}
          hideAddText={true}
          isUsedAsFilter={true}
          meta={{ channel: props.channel, category: props.category }}
        />
      </div>
      {props.channel === "sms" && (
        <Button onClick={props.onUploadClick} size="sm" variant="secondary">
          Upload File
        </Button>
      )}
      <BlueGradientButton size="sm" onClick={props.handleAddTemplate}>
        Add Template
      </BlueGradientButton>
    </div>
  );
}
