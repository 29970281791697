import { getListOfEmailFinder, getEmailFinderPricing } from "../types/email-finder.types";

const initState = {
    data: {
        list: [],
        count: 0,
        loading: false,
        error: null,
    },
    pricing: {
        data: [],
        loading: false,
    }

};

export default function emailFinderReducer(state = initState, action = {}) {
    switch (action.type) {
        case getListOfEmailFinder.REQUEST:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: true,
                    error: null,
                }
            };
        case getListOfEmailFinder.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: false,
                    list: action.payload.data,
                    count: action.payload.count,
                    error: null,
                }
            };
        case getListOfEmailFinder.FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: false,
                    error: action.payload
                }
            };
        case getEmailFinderPricing.REQUEST:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    loading: true,
                }
            };
        case getEmailFinderPricing.SUCCESS:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    loading: false,
                    data: action.payload.data,
                }
            };
        case getEmailFinderPricing.FAILURE:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    loading: false,
                }
            };

        default:
            return state;
    }
}
