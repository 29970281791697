import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import * as getMarketingListTypes from "../types/campaign_marketing_list_types";
import api from "../../../../network/apis/api";
import { endpoints } from "../endpoints";
import * as getMarketingListActions from "../actions/campaign_marketing_list_actions";

function* getListOfMarketingList(action) {
  try {
    const res = yield call(api.get, endpoints.GET_LIST_OF_MARKETING_LIST, action.payload);
    if (res.status === 200) {
      yield put(getMarketingListActions.getListOfMarketingListSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.getListOfMarketingListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.getListOfMarketingListError(e));
  }
}

function* getSelectedMarketingData(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_LIST_OF_MARKETING_LIST}/${action.payload}`, null);
    if (res.status === 200) {
      yield put(getMarketingListActions.getSelectedMarketingDataSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.getSelectedMarketingDataError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.getSelectedMarketingDataError(e));
  }
}

function* updateMarketingRequest(action) {
  try {
    const res = yield call(api.get, endpoints.GET_LIST_OF_MARKETING_LIST, action.payload.loadingData);
    if (res.status === 200) {
      yield put(getMarketingListActions.marketingUpdateSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.marketingUpdateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.marketingUpdateError(e));
  }
}

/**
 * editData
loadingData
cellId
 */
function* editMarketingList(action) {
  try {
    const res = yield call(api.put, `${endpoints.EDIT_MARKETING_LIST}/${action.payload.editData.id}`, action.payload.editData);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully updated.");
      yield put(
        getMarketingListActions.editMarketingListSuccess({
          message: "Data Edited Successfully",
        }),
      );
      yield put(
        getMarketingListActions.marketingUpdateRequest({
          loadingData: action.payload.loadingData,
          cellId: action.payload.cellId,
        }),
      );
      if (action.payload.pageForm === "sidepannel") {
        yield put(getMarketingListActions.getSelectedMarketingDataRequest(action.payload.editData.id));
      }
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.editMarketingListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.editMarketingListError(e));
  }
}



function* generateMarketingAIWFDataFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/ai-wizard/ai-wizard-field/generate", action.payload.data);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(getMarketingListActions.generateMarketingAIWFDataSuccess({ message: response.data.message }));
      yield put(getMarketingListActions.marketingUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.data.moduleId }));
      if (action.payload.data.moduleId) yield put(getMarketingListActions.getSelectedMarketingDataRequest(action.payload.data.moduleId));
      // if (action.payload.pageForm === "sidepannel") {
      //   yield put(getMarketingListActions.getSelectedMarketingDataRequest(action.payload.editData.id));
      // }
    } else {
      yield put(getMarketingListActions.generateMarketingAIWFDataError({ message: response.data.message }));
    }
  } catch (e) {
    yield put(getMarketingListActions.generateMarketingAIWFDataError({ message: "Could Not Generate Data" }));
  }
}



function* addMarketingList(action) {
  try {
    const res = yield call(api.post, endpoints.ADD_MARKETING_LIST, action.payload.data);
    if (res.status === 200) {
      yield put(
        getMarketingListActions.addMarketingListSuccess({
          message: res.data.message,
        }),
      );
      yield put(getMarketingListActions.getListOfMarketingListRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.addMarketingListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.addMarketingListError(e));
  }
}

function* deleteMarketingList(action) {
  try {
    const res = yield call(api.patch, endpoints.DELETE_MARKETING_LIST, action.payload.data);
    if (res.status === 200) {
      yield put(getMarketingListActions.deleteMarketingListSuccess(res.data));
      yield put(getMarketingListActions.getListOfMarketingListRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.deleteMarketingListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.deleteMarketingListError(e));
  }
}

function* bulkDeleteMarketingList(action) {
  try {
    const res = yield call(api.patch, endpoints.BULK_DELETE_MARKETING_LIST, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(getMarketingListActions.bulkDeleteMarketingListSuccess(res.data));
      yield put(getMarketingListActions.getListOfMarketingListRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.bulkDeleteMarketingListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.bulkDeleteMarketingListError(e));
  }
}

function* getListOfMarketingListTags(action) {
  try {
    const res = yield call(api.get, endpoints.GET_LIST_OF_MARKETING_LIST_TAGS, action.payload);
    if (res.status === 200) {
      yield put(getMarketingListActions.getListOfMarketingListTagsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.getListOfMarketingListTagsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.getListOfMarketingListTagsError(e));
  }
}

function* createMarketingListTags(action) {
  try {
    const response = yield call(api.post, endpoints.CREATE_MARKETING_LIST_TAGS, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.createMarketingListTagsSuccess({
          message: "Tag Added Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getListOfMarketingListTagsRequest({
          type: "marketing",
        }),
      );
    } else {
      yield put(
        getMarketingListActions.createMarketingListTagsError({
          message: "Could Not Add Marketing Tag",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.createMarketingListTagsError({
        status: false,
        message: "Could Not Add Marketing Tag",
      }),
    );
  }
}

function* updateMarketingListTags(action) {
  try {
    const response = yield call(api.patch, endpoints.UPDATE_MARKETING_LIST_TAGS, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.updateMarketingListTagsSuccess({
          message: "Marketing List Edited Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getListOfMarketingListTagsRequest({
          type: "marketing",
        }),
      );
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(
        getMarketingListActions.updateMarketingListTagsError({
          message: response.data.message,
        }),
      );
    } else {
      yield put(
        getMarketingListActions.updateMarketingListTagsError({
          message: "Could Not Edit",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.updateMarketingListTagsError({
        message: "Could Not Edit",
      }),
    );
  }
}

function* deleteMarketingListTags(action) {
  try {
    const response = yield call(api.delete, `${endpoints.DELETE_MARKETING_LIST_TAGS}/${action.payload}`, null);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.updateMarketingListTagsSuccess({
          message: "Tag Deleted Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getListOfMarketingListTagsRequest({
          type: "marketing",
        }),
      );
    } else {
      yield put(
        getMarketingListActions.updateMarketingListTagsError({
          message: "Could Not Delete Tag",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.updateMarketingListTagsError({
        message: "Could Not Delete Tag",
      }),
    );
  }
}

function* getSavedMarketingFilters(action) {
  try {
    const response = yield call(api.get, endpoints.GET_SAVED_MARKETING_FILTERS, action.payload);
    if (response.status === 200) {
      yield put(getMarketingListActions.getSavedMarketingFiltersSuccess(response.data));
    } else {
      yield put(
        getMarketingListActions.getSavedMarketingFiltersError({
          message: "Could Not Get Filters",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.getSavedMarketingFiltersError({
        message: "Could Not Get Filters",
      }),
    );
  }
}

function* createSavedMarketingFilters(action) {
  try {
    const response = yield call(api.post, endpoints.CREATE_SAVED_MARKETING_FILTERS, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.createSavedMarketingFiltersSuccess({
          message: "Filter Added Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getSavedMarketingFiltersRequest({
          type: "marketing",
        }),
      );
    } else {
      yield put(
        getMarketingListActions.createSavedMarketingFiltersError({
          message: "Could Not Add Filter",
        }),
      );
    }

    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(
      getMarketingListActions.createSavedMarketingFiltersError({
        message: "Could Not Add Filter",
      }),
    );
  }
}

function* editSavedMarketingFilters(action) {
  try {
    const response = yield call(api.patch, endpoints.EDIT_SAVED_MARKETING_FILTERS, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.editSavedMarketingFiltersSuccess({
          message: "Filter Edited Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getSavedMarketingFiltersRequest({
          type: "marketing",
        }),
      );
    } else {
      yield put(
        getMarketingListActions.editSavedMarketingFiltersError({
          message: "Could Not Edit Filter",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.editSavedMarketingFiltersError({
        message: "Could Not Edit Filter",
      }),
    );
  }
}

function* deleteSavedMarketingFilters(action) {
  try {
    const response = yield call(api.delete, `${endpoints.DELETE_SAVED_MARKETING_FILTERS}/${action.payload}`, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.deleteSavedMarketingFiltersSuccess({
          message: "Filter Deleted Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getSavedMarketingFiltersRequest({
          type: "marketing",
        }),
      );
    } else {
      yield put(
        getMarketingListActions.deleteSavedMarketingFiltersError({
          message: "Could Not Delete Filter",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.deleteSavedMarketingFiltersError({
        message: "Could Not Delete Filter",
      }),
    );
  }
}

function* markFavoriteMarketingFilters(action) {
  try {
    const response = yield call(api.post, endpoints.MARK_FAVORITE_MARKETING_FILTERS, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingListActions.markFavoriteMarketingFiltersSuccess({
          message: "Marked as Favorite Filter Successfully",
        }),
      );
      yield put(
        getMarketingListActions.getSavedMarketingFiltersRequest({
          type: "marketing",
        }),
      );
    } else {
      yield put(
        getMarketingListActions.markFavoriteMarketingFiltersError({
          message: "Could Not Favorite Filter",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingListActions.markFavoriteMarketingFiltersError({
        message: "Could Not Favorite Filter",
      }),
    );
  }
}

function* getListOfMarketingLogs(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_MARKETING_LOG}/${action.payload.id}`, action.payload.query);
    if (res.status === 200) {
      yield put(getMarketingListActions.getMarketingLogsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.getMarketingLogsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.getMarketingLogsError(e));
  }
}

function* getListOfMarketingLogsLoadMore(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_MARKETING_LOG}/${action.payload.id}`, action.payload.query);
    if (res.status === 200) {
      yield put(getMarketingListActions.getMarketingLogsLoadMoreSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingListActions.getMarketingLogsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingListActions.getMarketingLogsError(e));
  }
}

export default function* campaignMarketingSaga() {
  yield takeLatest(getMarketingListTypes.getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_REQUEST, getListOfMarketingList);
  yield takeLatest(getMarketingListTypes.getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_REQUEST, getSelectedMarketingData);
  yield takeLatest(getMarketingListTypes.marketingUpdate.MARKETIING_UPDATE_REQUEST, updateMarketingRequest);
  yield takeLatest(getMarketingListTypes.editMarketingList.EDIT_MARKETING_LIST_REQUEST, editMarketingList);
  yield takeLatest(getMarketingListTypes.addMarketingList.ADD_MARKETING_LIST_REQUEST, addMarketingList);
  yield takeLatest(getMarketingListTypes.deleteMarketingList.DELETE_MARKETING_LIST_REQUEST, deleteMarketingList);
  yield takeLatest(getMarketingListTypes.bulkDeleteMarketingList.BULK_DELETE_MARKETING_LIST_REQUEST, bulkDeleteMarketingList);
  yield takeLatest(getMarketingListTypes.getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_REQUEST, getListOfMarketingListTags);
  yield takeLatest(getMarketingListTypes.createMarketingListTags.CREATE_MARKETING_LIST_TAGS_REQUEST, createMarketingListTags);
  yield takeLatest(getMarketingListTypes.updateMarketingListTags.UPDATE_MARKETING_LIST_TAGS_REQUEST, updateMarketingListTags);
  yield takeLatest(getMarketingListTypes.deleteMarketingListTags.DELETE_MARKETING_LIST_TAGS_REQUEST, deleteMarketingListTags);
  yield takeLatest(getMarketingListTypes.getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_REQUEST, getSavedMarketingFilters);
  yield takeLatest(getMarketingListTypes.createSavedMarketingFilters.CREATE_SAVED_MARKETING_FILTERS_REQUEST, createSavedMarketingFilters);
  yield takeLatest(getMarketingListTypes.editSavedMarketingFilters.EDIT_SAVED_MARKETING_FILTERS_REQUEST, editSavedMarketingFilters);
  yield takeLatest(getMarketingListTypes.deleteSavedMarketingFilters.DELETE_SAVED_MARKETING_FILTERS_REQUEST, deleteSavedMarketingFilters);
  yield takeLatest(getMarketingListTypes.markFavoriteMarketingFilters.MARK_FAVORITE_MARKETING_FILTERS_REQUEST, markFavoriteMarketingFilters);
  yield takeLatest(getMarketingListTypes.getMarketingLogs.GET_MARKETING_LOGS_REQUEST, getListOfMarketingLogs);
  yield takeLatest(getMarketingListTypes.getMarketingLogs.GET_MARKETING_LOGS_LOAD_MORE_REQUEST, getListOfMarketingLogsLoadMore);
  yield takeLatest(getMarketingListTypes.generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_REQUEST, generateMarketingAIWFDataFunction)
}
