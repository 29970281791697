import React, { useEffect, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./styles.scss";

export default function MultiSelectCheckboxDropdown(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const _options = [];
    if (props.showAllOption) _options.push({ label: "All", value: "*" });
    if (props.showClearOption) _options.push({ label: "Clear All", value: "***" });
    _options.push(...props.options);
    setOptions(props.options);
  }, [props.options, props.showAllOption, props.showClearOption]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      props.setSelectedValue(props.options);
    } else if (event.action === "select-option" && event.option.value === "***") {
      props.setSelectedValue([]);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      props.setSelectedValue([]);
    } else if (event.action === "deselect-option") {
      props.setSelectedValue(value.filter((o) => o.value !== "*"));
    } else if (props.showAllOption && value.length === props.options.length - 1) {
      props.setSelectedValue(props.options);
    } else if (!props.showAllOption && value.length === props.options.length) {
      props.setSelectedValue(props.options);
    } else {
      props.setSelectedValue(value);
    }
  }

  return (
    <div className="multi-select-checkbox-selection">
      <ReactMultiSelectCheckboxes
        options={options}
        value={props.selectedValue}
        onChange={onChange}
        setState={props.setSelectedValue}
        dropdownButton={{
          width: "100%",
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            padding: "10px",
            border: "1px solid rgb(228, 230, 239)",
          }),
        }}
      />
    </div>
  );
}
