import { getActivityActionById, editActivityAction, createActivityAction } from "../types";

export const getActivityActionByIdRequest = (payload, cb) => ({
  type: getActivityActionById.GET_ACTIVITY_ACTION_BY_ID_REQUEST,
  payload,
  cb,
});
export const getActivityActionByIdSuccess = (payload) => ({
  type: getActivityActionById.GET_ACTIVITY_ACTION_BY_ID_SUCCESS,
  payload,
});
export const getActivityActionByIdError = (payload) => ({
  type: getActivityActionById.GET_ACTIVITY_ACTION_BY_ID_ERROR,
  payload,
});

export const editActivityActionRequest = (payload, cb) => ({
  type: editActivityAction.EDIT_ACTIVITY_ACTION_REQUEST,
  payload,
  cb,
});
export const editActivityActionSuccess = (payload) => ({
  type: editActivityAction.EDIT_ACTIVITY_ACTION_SUCCESS,
  payload,
});
export const editActivityActionError = (payload) => ({
  type: editActivityAction.EDIT_ACTIVITY_ACTION_ERROR,
  payload,
});

export const createActivityActionRequest = (payload, cb) => ({
  type: createActivityAction.CREATE_ACTIVITY_ACTION_REQUEST,
  payload,
  cb,
});
export const createActivityActionSuccess = (payload) => ({
  type: createActivityAction.CREATE_ACTIVITY_ACTION_SUCCESS,
  payload,
});
export const createActivityActionError = (payload) => ({
  type: createActivityAction.CREATE_ACTIVITY_ACTION_ERROR,
  payload,
});
