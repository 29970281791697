import React, { useState } from "react";
import "./style.css";
import { Button } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputLimit } from "../../constants";

export default function PermissionSets(props) {
  const [iconRegVal, seticonRegVal] = useState(false);
  const [iconManVal, seticonManVal] = useState(false);
  const [iconRight, seticonRight] = useState(true);
  const [currentId, setcurrentId] = useState("AdminList");

  function mouseEnter(id) {
    if (id === "regularList") {
      seticonRegVal(true);
    } else if (id === "managerList") {
      seticonManVal(true);
    }
  }

  function mouseLeave(id) {
    if (id === "regularList") {
      seticonRegVal(false);
    } else if (id === "managerList") {
      seticonManVal(false);
    }
  }

  function HandelClick(id) {
    let a = ["AdminList", "regularList", "managerList"];

    let b = a.filter((res) => res !== id);

    for (let i = 0; i < b.length; i++) {
      var element = document.getElementById(b[i]);
      element.classList.remove("activeSets");
    }

    setcurrentId(id);
    seticonRight(true);
    let ids = document.getElementById(id);
    ids.className += " activeSets";
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Rename Permission Sets</Popover.Title>
      <Popover.Content>
        <Form>
          <Form.Group id="admin-permission-sets-choose-new-name" controlId="formBasicEmail">
            <Form.Label aria-label="choose-new-name">Choose new Name</Form.Label>
            <Form.Control maxLength={InputLimit.EMAIL} type="email" placeholder="Enter email" />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <div className="card card-flush">
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">
              {/*begin::Head*/}
              <thead className="fs-7 text-gray-400 text-uppercase">
                <tr>
                  <th className="min-w-250px">Sets</th>
                  <th className="min-w-150px">Users</th>
                  <th className="min-w-90px">Permissions</th>
                </tr>
              </thead>
              <tbody className="fs-6">
                <tr>
                  <td>
                    <div onClick={() => HandelClick("AdminList")} id="AdminList" className="sets activeSets">
                      <div>Admin User </div>
                      {iconRight && currentId === "AdminList" && <i className="bi bi-check checkIcon"></i>}
                    </div>

                    <div onMouseEnter={() => mouseEnter("regularList")} onMouseLeave={() => mouseLeave("regularList")} id="regularList" className="sets">
                      <div className="roleList" onClick={() => HandelClick("regularList")}>
                        Regular User
                      </div>
                      {iconRight && currentId === "regularList" && <i className="bi bi-check checkIcon"></i>}
                      {iconRegVal && (
                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                          <i className="bi bi-pen-fill listIcon"></i>
                        </OverlayTrigger>
                      )}
                    </div>

                    <div onMouseEnter={() => mouseEnter("managerList")} onMouseLeave={() => mouseLeave("managerList")} id="managerList" className="sets">
                      <div className="roleList" onClick={() => HandelClick("managerList")}>
                        Manager
                      </div>
                      {iconRight && currentId === "managerList" && <i className="bi bi-check checkIcon"></i>}
                      {iconManVal && (
                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                          <i className="bi bi-pen-fill listIcon"></i>
                        </OverlayTrigger>
                      )}
                    </div>
                  </td>
                  <td>
                    {currentId === "AdminList" && <div>Admin</div>}
                    {currentId === "regularList" && <div>Regular</div>}
                    {currentId === "managerList" && <div>Manager</div>}
                  </td>
                  <td>
                    {currentId === "AdminList" && <div>Admin Permission</div>}
                    {currentId === "regularList" && <div>Regular Permission</div>}
                    {currentId === "managerList" && <div>Manager Permission</div>}
                  </td>
                </tr>
              </tbody>
              {/*end::Body*/}
            </table>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  );
}
