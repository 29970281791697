import React, { useState } from "react";
import { InputLimit } from "../../../../../../constants";
import { dispatchSnackbarSuccess } from "../../../../../../utils/toaster";
import AuthWrapper from "../../componets/auth-wrapper";
import * as actions from "../../../actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";

export default function InviteUser(props) {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const search = useLocation().search;
  const codeParams = new URLSearchParams(search).get("token");

  const Submit = () => {
    if (!fullName) {
      setErrorMessage("Please enter valid full name", "error");
    } else if (!/[0-9]{5,14}$/.test(phoneNumber)) {
      setErrorMessage("Please enter valid phone number", "error");
    } else if (!/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(password)) {
      return setErrorMessage("Password should contains minimum eight characters, at least one letter and one number");
    } else if (password === password1) {
      dispatch(
        actions.ManagerInviteRequest({ password: password, fullName: fullName, phoneNumber: phoneNumber }, codeParams, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Account Successfully Verified", "success");
            window.location.href = "/auth/login";
          }
        }),
      );
    } else {
      setErrorMessage("Password and Confirm Password not Match", "error");
    }
  };

  return (
    <AuthWrapper>
      <h5 class="text-black">Join The Team Now</h5>
      <div class="text-grey text-medium mb-30">To join the team, kindly complete the required information.</div>
      <div class="w-form">
        <div id="wf-form-Invite-Password-3" name="wf-form-Invite-Password-3" data-name="Reset Password" method="get" aria-label="Reset Password">
          <div class="form-input-group">
            <label for="Invite-Full-Name" class="form-label grey">
              Name
            </label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              class="input white bordered w-input"
              maxlength={InputLimit.TEXT}
              name="Invite-New-Password"
              data-name="Reset New Password"
              placeholder="Enter Full Name"
              id="Invite-Full-Name"
            />
          </div>
          <div class="form-input-group">
            <label for="Invite-Full-Name" class="form-label grey">
              Phone Number
            </label>
            <ReactPhoneInput
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e)}
              containerClass="....." //css class name
              inputClass="input white bordered w-input"
              buttonClass="....."
              dropdownClass="phone2-dropdownClass"
              searchClass="....."
              inputStyle={{
                paddingLeft: "48px",
              }}
            />
          </div>
          <div class="form-input-group">
            <label for="Invite-New-Password" class="form-label grey">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              class="input white bordered w-input"
              maxlength={InputLimit.PASSWORD}
              name="Invite-New-Password"
              data-name="Reset New Password"
              placeholder="Enter New Password"
              id="Invite-New-Password"
            />
          </div>
          <div class="form-input-group">
            <label for="Invite-Confirm-Password" class="form-label grey">
              Confirm Password
            </label>
            <input
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              class="input white bordered w-input"
              maxlength={InputLimit.PASSWORD}
              name="Invite-Password-Confirm"
              data-name="Reset Confirm Password"
              placeholder="Enter Confirm Password"
              id="Invite-Confirm-Password"
            />
          </div>

          {errorMessage && (
            <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
              <div>{errorMessage}.</div>
            </div>
          )}
          <button onClick={Submit} data-wait="Please wait..." class="button primary-invert medium block mb-40 _2 w-button">
            Submit
          </button>
        </div>
      </div>
    </AuthWrapper>
  );
}
