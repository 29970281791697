export const getListOfSMSSenderIds = {
  GET_LIST_OF_SMS_SENDER_ID_REQUEST: "GET_LIST_OF_SMS_SENDER_ID_REQUEST",
  GET_LIST_OF_SMS_SENDER_ID_SUCCESS: "GET_LIST_OF_SMS_SENDER_ID_SUCCESS",
  GET_LIST_OF_SMS_SENDER_ID_ERROR: "GET_LIST_OF_SMS_SENDER_ID_ERROR",
};

export const createSMSSenderId = {
  CREATE_SMS_SENDER_ID_REQUEST: "CREATE_SMS_SENDER_ID_REQUEST",
  CREATE_SMS_SENDER_ID_SUCCESS: "CREATE_SMS_SENDER_ID_SUCCESS",
  CREATE_SMS_SENDER_ID_ERROR: "CREATE_SMS_SENDER_ID_ERROR",
};

export const deleteSMSSenderId = {
  DELETE_SMS_SENDER_ID_REQUEST: "DELETE_SMS_SENDER_ID_REQUEST",
  DELETE_SMS_SENDER_ID_SUCCESS: "DELETE_SMS_SENDER_ID_SUCCESS",
  DELETE_SMS_SENDER_ID_ERROR: "DELETE_SMS_SENDER_ID_ERROR",
};

export const getSMSCampaignPricing = {
  GET_SMS_CAMPAIGN_PRICING_REQUEST: "GET_SMS_CAMPAIGN_PRICING_REQUEST",
  GET_SMS_CAMPAIGN_PRICING_SUCCESS: "GET_SMS_CAMPAIGN_PRICING_SUCCESS",
  GET_SMS_CAMPAIGN_PRICING_ERROR: "GET_SMS_CAMPAIGN_PRICING_ERROR",
};

export const getSMSSenderIdCountryList = {
  GET_SMS_SENDER_ID_COUNTRY_LIST_REQUEST: "GET_SMS_SENDER_ID_COUNTRY_LIST_REQUEST",
  GET_SMS_SENDER_ID_COUNTRY_LIST_SUCCESS: "GET_SMS_SENDER_ID_COUNTRY_LIST_SUCCESS",
  GET_SMS_SENDER_ID_COUNTRY_LIST_ERROR: "GET_SMS_SENDER_ID_COUNTRY_LIST_ERROR",
};
