import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as peopleOperationsActions from "../actions/people-operations.actions";
import * as peopleActions from "../actions/actions";
import * as peopleOperationTypes from "../types/people-operations.types";

const endpoints = {
  BULK_EDIT_V2: "/api/crm/people/v2/bulk-edit",
  BULK_DELETE_V2: "/api/crm/people/v2/delete",
};

function* bulkEditPeopleV2(action) {
  try {
    const res = yield call(api.post, endpoints.BULK_EDIT_V2, action.payload.peopleData);
    if (res.status === 200) {
      yield put(peopleOperationsActions.bulkEditPeopleV2Success({ message: res.data.message }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(peopleOperationsActions.bulkEditPeopleV2Error({ message: res.data.message }));
    } else {
      yield put(peopleOperationsActions.bulkEditPeopleV2Error({ message: "Could Not Edit Peoples" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(peopleOperationsActions.bulkEditPeopleV2Error({ message: "Could Not Edit Peoples" }));
  }
}

function* bulkDeletePeopleV2(action) {
  try {
    const res = yield call(api.patch, endpoints.BULK_DELETE_V2, action.payload.peopleIds);
    if (res.status === 200) {
      yield put(peopleOperationsActions.bulkDeletePeopleV2Success({ message: res.data.message }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(peopleOperationsActions.bulkDeletePeopleV2Error({ message: res.data.message }));
    } else {
      yield put(peopleOperationsActions.bulkDeletePeopleV2Error({ message: "Could Not Delete Peoples" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(peopleOperationsActions.bulkDeletePeopleV2Error({ message: "Could Not Delete Peoples" }));
  }
}

export default function* peopleOperationSaga() {
  yield takeLatest(peopleOperationTypes.bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_REQUEST, bulkDeletePeopleV2);
  yield takeLatest(peopleOperationTypes.bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_REQUEST, bulkEditPeopleV2);
}
