import React, { useEffect, useState } from "react";
import { Dropdown, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import _ from "lodash";

export default function OwnerSelect({ setValue, value }) {
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const userRole = useSelector((store) => store.insight.userRole);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (userRole.list.users) {
      const find = userRole.list.users.find((owner) => owner["id"] === value);
      setSelected(find);
    }
  }, [userRole.list.users, value, userProfile, setValue]);

  if (userRole.list.users && userRole.list.users.length <= 1) {
    return <></>;
  }
  return (
    <Dropdown className="dropdown-inline ">
      <Dropdown.Toggle
        className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none"
        variant={"link"}
        //  className={`btn btn-sm ${selected ? "font-weight-bolder" : ""} dropdown-toggle btn-secondary custom-secondory-cta`}
      >
        {!selected ? (
          <OverlayTrigger overlay={<Tooltip>Owner Filter</Tooltip>}>
            <i class="bi bi-person fs-2"></i>
          </OverlayTrigger>
        ) : (
          <div className="d-flex gap-2 align-items-center pl-3 pr-1 py-2 border rounded-2">
            {/* <i class="bi bi-person fs-2"></i> */}
            <span> {selected.name ? selected.name : selected.email}</span>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                setValue([]);
              }}
            ></i>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {userRole.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="h-25vh-mx position-relative overflow-auto">
            {userRole.list.users && userRole.list.users.length > 0 ? (
              userRole.list.users.map((eachUser, index) => {
                return (
                  <Dropdown.Item
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      setSelected(eachUser);
                      setValue(eachUser["id"] === value ? "" : eachUser["id"]);
                    }}
                  >
                    <div className="d-flex smart-view-container flex-stack w-200px-mx">
                      <span className="overflow-hidden text-overflow-ellipsis text-nowrap w-200px-mx"> {_.capitalize(eachUser.name ? eachUser.name : eachUser.email)}</span>
                      <div className="d-flex align-items-center gap-10px h-25px w-12px">
                        <div className="d-flex align-items-center">
                          {eachUser.id === value ? (
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove User filter</Tooltip>}>
                              <i className="bi bi-x-circle-fill mt-1px mr-6px"></i>
                            </OverlayTrigger>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Dropdown.Item>
                );
              })
            ) : (
              <div className="p-3 text-lg-center text-gray-700">Data Not Available</div>
            )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
