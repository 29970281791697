export const getListOfShortLinks = {
  GET_LIST_OF_SHORT_LINKS_REQUEST: "GET_LIST_OF_SHORT_LINKS_REQUEST",
  GET_LIST_OF_SHORT_LINKS_SUCCESS: "GET_LIST_OF_SHORT_LINKS_SUCCESS",
  GET_LIST_OF_SHORT_LINKS_ERROR: "GET_LIST_OF_SHORT_LINKS_ERROR",
};

export const createShortLink = {
  CREATE_SHORT_LINK_REQUEST: "CREATE_SHORT_LINK_REQUEST",
  CREATE_SHORT_LINK_SUCCESS: "CREATE_SHORT_LINK_SUCCESS",
  CREATE_SHORT_LINK_ERROR: "CREATE_SHORT_LINK_ERROR",
};

export const editShortLink = {
  EDIT_SHORT_LINK_REQUEST: "EDIT_SHORT_LINK_REQUEST",
  EDIT_SHORT_LINK_SUCCESS: "EDIT_SHORT_LINK_SUCCESS",
  EDIT_SHORT_LINK_ERROR: "EDIT_SHORT_LINK_ERROR",
};

export const deleteShortLink = {
  DELETE_SHORT_LINK_REQUEST: "DELETE_SHORT_LINK_REQUEST",
  DELETE_SHORT_LINK_SUCCESS: "DELETE_SHORT_LINK_SUCCESS",
  DELETE_SHORT_LINK_ERROR: "DELETE_SHORT_LINK_ERROR",
};

export const getShortLinkAnalytics = {
  GET_SHORT_LINK_ANALYTICS_REQUEST: "GET_SHORT_LINK_ANALYTICS_REQUEST",
  GET_SHORT_LINK_ANALYTICS_SUCCESS: "GET_SHORT_LINK_ANALYTICS_SUCCESS",
  GET_SHORT_LINK_ANALYTICS_ERROR: "GET_SHORT_LINK_ANALYTICS_ERROR",
};

export const getShortLinkReports = {
  GET_SHORT_LINK_REPORTS_REQUEST: "GET_SHORT_LINK_REPORTS_REQUEST",
  GET_SHORT_LINK_REPORTS_SUCCESS: "GET_SHORT_LINK_REPORTS_SUCCESS",
  GET_SHORT_LINK_REPORTS_ERROR: "GET_SHORT_LINK_REPORTS_ERROR",
};
