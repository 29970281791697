import React, { useState, useEffect } from "react";
import { Form, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RichTextEditor from "../../../components/RichTextEditor/tinymce-editor";
import { InputLimit } from "../../../constants";
import { uploadLeadbotImageRequest } from "../actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import InlineStyled from "../../../components/inline-styled/inline-styled";

export default function WelcomeMessage(props) {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();

  const handleFileUpload = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "welcome-screen");
    formData.append("leadBotId", props.leadBotId);
    dispatch(
      uploadLeadbotImageRequest(formData, (data) => {
        if (data.status) {
          if (data.url) {
            props.setValue("mediaUrl", data.url);
          }
          dispatchSnackbarSuccess("Successfully uploaded");
        } else {
          dispatchSnackbarError(data.message);
        }
        setLoading(false);
      }),
    );
  };

  useEffect(() => {
    const urlRegex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
    if (props.data.mediaUrl.match(urlRegex)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [props.data.mediaUrl]);

  return (
    <>
      <Form.Group id="leadbot-components-welcome-message-builder-add-image">
        <Form.Label aria-label="add-image" className="d-flex align-items-center">
          Add Image
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>{"This image will appear on the welcome screen. There is no size limitation but for the best viewing experience, Recommend size for embed and widget 1200*630px, Max 3Mb image."}</Tooltip>}
          >
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
          {loading && (
            <span className="ml-3 text-419eff">
              <Spinner animation="border" size="sm" variant="primary" /> Uploading Image...
            </span>
          )}
        </Form.Label>
        {!isValid ? (
          <Form.Group id="leadbot-components-welcome-message-builder-uploading image">
            <div className="d-flex justify-content-between mb-3">
              <Form.Control
                placeholder="Enter image link here or click upload"
                className="w-100-80px"
                maxLength={InputLimit.URL}
                type="text"
                value={props.data.mediaUrl}
                onChange={(e) => props.setValue("mediaUrl", e.target.value)}
              ></Form.Control>
              <Form.File className="w-75px cursor-pointer" type="file" label="Upload" accept=".png,.jpg,.jpeg,.webp,.svg" onChange={handleFileUpload} custom />
            </div>
          </Form.Group>
        ) : (
          <Form.Group id="leadbot-components-welcome-message-builder-remove-image" className="leadbot-image-icon-wrapper image-input image-input-outline">
            <InlineStyled className="image-input-wrapper ldd-image-wrapper" styles={{ backgroundImage: `url(${props.data.mediaUrl})` }} />
            <label
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              onClick={(e) => props.setValue("mediaUrl", "")}
              title="Remove Image"
            >
              <i className="bi bi-x-circle fs-7"></i>
            </label>
          </Form.Group>
        )}
      </Form.Group>
      <Form.Group id="leadbot-components-welcome-message-builder-description">
        <Form.Label aria-label="description">
          Description
          <OverlayTrigger placement="right" overlay={<Tooltip>{"This text will appear on the welcome screen. It will show below the image screen, On the landing page, it will appear on the left side."}</Tooltip>}>
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <div className="card h-300px">
          <RichTextEditor
            // defaultValue={defualtValue}
            value={props.data.descrption}
            onChange={(c) => {
              props.setValue("descrption", c);
            }}
          />
        </div>
      </Form.Group>
      <Form.Group id="leadbot-components-welcome-message-builder-button">
        <Form.Label aria-label="button" className="d-flex align-items-center">
          Button
          <OverlayTrigger placement="right" overlay={<Tooltip>{"Name the button as you like. You can change the colour of it in settings."}</Tooltip>}>
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type="text" value={props.data.buttonText} onChange={(e) => props.setValue("buttonText", e.target.value)}></Form.Control>
      </Form.Group>
    </>
  );
}
