/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Pagination, Spinner, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./style.css";
import { PopoverEdit } from "../popover_edit";
import CustomDisplay from "./data";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MarkAsDoneActivity } from "../activity/mark_as_done";
import { CustomEmptyPage } from "../empty_page/custom-empty-page";
import InlineStyled from "../inline-styled/inline-styled";

const CustomPagination = (active, setActive, pages, setChecked, paginationNumber, setPaginationNumber, rows) => {
  var items = [];
  if (pages <= 7) {
    for (let number = 1; number <= pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => {
            setActive(number);
            setChecked([]);
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
  } else {
    items.push(
      <Pagination.First
        key={0}
        onClick={() => {
          setActive(1);
          setChecked([]);
        }}
      />,
    );
    items.push(
      <Pagination.Prev
        onClick={() => {
          if (active !== 1) setActive(active - 1);
        }}
      />,
    );
    if (active === 1 || active === 2) {
      items.push(
        <Pagination.Item
          key={1}
          active={active === 1}
          onClick={() => {
            setActive(1);
            setChecked([]);
          }}
        >
          1
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={2}
          active={active === 2}
          onClick={() => {
            setActive(2);
            setChecked([]);
          }}
        >
          2
        </Pagination.Item>,
      );
      // items.push(<Pagination.Item key={3} active={false} onClick={() => { setActive(3); setChecked([]) }}>3</Pagination.Item>)
      // items.push(<Pagination.Item key={4} active={false} onClick={() => { setActive(4); setChecked([]) }}>4</Pagination.Item>)
    } else {
      // items.push(<Pagination.Ellipsis />)
      if (active === pages) {
        // items.push(<Pagination.Item key={active - 3} active={false} onClick={() => { setActive(active - 3); setChecked([]) }}>{active - 3}</Pagination.Item>)
        items.push(
          <Pagination.Item
            key={active - 2}
            active={false}
            onClick={() => {
              setActive(active - 2);
              setChecked([]);
            }}
          >
            {active - 2}
          </Pagination.Item>,
        );
      } else if (active === pages - 1)
        items.push(
          <Pagination.Item
            key={active - 2}
            active={false}
            onClick={() => {
              setActive(active - 2);
              setChecked([]);
            }}
          >
            {active - 2}
          </Pagination.Item>,
        );
      items.push(
        <Pagination.Item
          key={active - 1}
          active={false}
          onClick={() => {
            setActive(active - 1);
            setChecked([]);
          }}
        >
          {active - 1}
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={active}
          active={true}
          onClick={() => {
            setActive(active);
            setChecked([]);
          }}
        >
          {active}
        </Pagination.Item>,
      );
      if (active !== pages) {
        items.push(
          <Pagination.Item
            key={active + 1}
            active={false}
            onClick={() => {
              setActive(active + 1);
              setChecked([]);
            }}
          >
            {active + 1}
          </Pagination.Item>,
        );
      }
    }
    if (active !== pages - 1 && active !== pages)
      // items.push(<Pagination.Ellipsis />)
      items.push(
        <Pagination.Next
          onClick={() => {
            if (active !== pages) setActive(active + 1);
          }}
        />,
      );
    items.push(
      <Pagination.Last
        key={pages + 1}
        onClick={() => {
          setActive(pages);
          setChecked([]);
        }}
      />,
    );
  }
  return (
    <div className="d-flex flex-row align-items-center gap-15px mt-5px">
      {pages === 1 ? null : (
        <ul id="table_pagination" className="d-flex flex-row z-index-0 m-0 list-unstyled border-radius-7px">
          {items}
        </ul>
      )}
    </div>
  );
};

export function EditableTable({ props }) {
  const tempData = props.data;
  const [allChecked, setAllChecked] = useState(false);
  const [allRadioChecked, setAllRadioChecked] = useState(false);
  const [edit, setEdit] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [sortConfig, setSortConfig] = React.useState(null);
  const [sortedTemp, setSortedTemp] = useState([]);
  const ownerList = useSelector((state) => state.People.ownerList.list);
  const peopleList = useSelector((state) => state.People.peopleDropdownList.list);
  const orgList = useSelector((state) => state.Companies.companyDropdownList.list);
  const categoryList = useSelector((state) => state.Products.productCategories.list);
  const history = useHistory();

  // Sort by column
  useEffect(() => {
    let tempSort = [...tempData];
    if (sortConfig !== null) {
      var x, y;
      tempSort.sort((a, b) => {
        if (sortConfig.fieldInputType === "OWNER_SELECT") {
          x = ownerList.find((owner) => owner["_id"] === a[sortConfig.key]);
          y = ownerList.find((owner) => owner["_id"] === b[sortConfig.key]);
          if (x && y) {
            if (x.fullName < y.fullName) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (x.fullName > y.fullName) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
        } else if (sortConfig.fieldInputType === "CATEGORY_SELECT") {
          x = categoryList.find((cat) => cat["_id"] === a[sortConfig.key]);
          y = categoryList.find((cat) => cat["_id"] === b[sortConfig.key]);
          if (x && y) {
            if (x.categoryName < y.categoryName) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (x.categoryName > y.categoryName) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
        } else if (sortConfig.fieldInputType === "PERSON_SELECT") {
          x = peopleList.find((person) => person["_id"] === a[sortConfig.key]);
          y = peopleList.find((person) => person["_id"] === b[sortConfig.key]);
          if (x && y) {
            if (x.name < y.name) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (x.name > y.name) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
        } else if (sortConfig.fieldInputType === "ORG_SELECT") {
          x = orgList.find((org) => org["_id"] === a[sortConfig.key]);
          y = orgList.find((org) => org["_id"] === b[sortConfig.key]);
          if (x && y) {
            if (x.name < y.name) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (x.name > y.name) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedTemp(tempSort);
  }, [tempData, sortConfig, categoryList, orgList, ownerList, peopleList]);

  const requestSort = (key, fieldInputType) => {
    if (props.isApiSort) {
      let direction = "ascending";
      if (props.sortConfig && props.sortConfig.key === key && props.sortConfig.direction === "ascending") direction = "descending";
      else if (props.sortConfig && props.sortConfig.key === key && props.sortConfig.direction === "descending") {
        props.setSortConfig(null);
        return;
      }
      props.setSortConfig({ key, direction, fieldInputType });
    } else {
      let direction = "ascending";
      if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") direction = "descending";
      else if (sortConfig && sortConfig.key === key && sortConfig.direction === "descending") {
        setSortConfig(null);
        return;
      }
      setSortConfig({ key, direction, fieldInputType });
    }
  };

  // Show edit button
  const setHovering = (combinedId, colId) => {
    const colIndex = props.idToIndex[colId];
    if (props.columns[colIndex].isEditable && !editing) {
      setEdit(combinedId);
    }
  };

  // Hide edit button if not editing
  const setNotHovering = () => {
    if (!editing) setEdit(-1);
  };

  // check/uncheck row
  const editChecked = (rowId) => {
    if (!props.checked.includes(rowId)) props.setChecked([...props.checked, rowId]);
    else {
      props.setChecked(props.checked.filter((check) => check !== rowId));
      setAllChecked(false);
    }
  };

  const editRadioChecked = (e, rowId) => {
    const checkedIds = [];
    if (props.data && Array.isArray(props.data)) {
      props.data.forEach((each) => {
        if (each.activity && each.activity.isCompleted) {
          checkedIds.push(each.activity._id);
        }
      });
    }
    if (!props.radioChecked.includes(rowId)) props.setRadioChecked([...checkedIds, rowId]);
    else {
      props.callActivityMarkAsUndoneApi(rowId);
      props.setRadioChecked(props.radioChecked.filter((check) => check !== rowId));
      setAllRadioChecked(false);
    }
    if (props.lostReasonStatus && !props.lostReasonStatus.isActivityLostReasonEnabled) {
      let data = {
        id: rowId,
        reasonId: "",
        comment: "",
      };
      props.callActivityMADoneApi(data);
    }
  };

  const checkAll = () => {
    if (props.checked.length === tempData.length) {
      props.setChecked([]);
    } else {
      let temp = [];
      tempData.map((data) => temp.push(data["_id"]));
      props.setChecked(temp);
    }
  };

  const getData = (colId, data, combinedId) => {
    let activityAndForDeal = props.columns[props.idToIndex[colId]];
    const colIndex = props.idToIndex[colId];

    const getDataForCustomFieldOfActivity = () => {
      if (data[activityAndForDeal.fieldType.toLowerCase()] && data[activityAndForDeal.fieldType.toLowerCase()]["customFields"])
        return data[activityAndForDeal.fieldType.toLowerCase()]["customFields"][activityAndForDeal.apiKeyName];
      else return "";
    };

    if (activityAndForDeal.fieldGroup === "SYSTEM" && activityAndForDeal.fieldInputType === "SELECT" && activityAndForDeal.fieldName === "Status" && activityAndForDeal.fieldType === "DEAL") {
      if (data.status === "active") return <span className="data-container badge badge-light-primary text-capitalize">{"Open"}</span>;
      else if (data.status === "archived") return <span className="data-container badge badge-light-warning text-capitalize">{data.status}</span>;
      else if (data.status === "won") return <span className="data-container badge badge-light-success text-capitalize">{data.status}</span>;
      else if (data.status === "lost") return <span className="data-container badge badge-light-danger text-capitalize">{data.status}</span>;
      else if (data.status === "deleted") return <span className="data-container badge badge-light-dark text-capitalize">{data.status}</span>;
      return <span className="data-container">{data.status}</span>;
    } else if (props.fromActivityorDealList) {
      return props.columns[colIndex].fieldGroup === "CUSTOM" ? (
        <CustomDisplay
          allData={data}
          selectedStages={props.selectedStages}
          page={props.page}
          pageSub={props.pageSub}
          data={getDataForCustomFieldOfActivity()}
          type={activityAndForDeal.fieldInputType}
          field={activityAndForDeal}
          loadingData={props.filterPayload}
          combinedId={combinedId}
        />
      ) : (
        <CustomDisplay
          allData={data}
          selectedStages={props.selectedStages}
          page={props.page}
          pageSub={props.pageSub}
          dataStatus={data.status}
          data={data[activityAndForDeal.fieldType.toLowerCase()][activityAndForDeal.apiKeyName]}
          field={activityAndForDeal}
          type={activityAndForDeal.fieldInputType}
          loadingData={props.filterPayload}
          combinedId={combinedId}
        />
      );
    } else {
      return props.columns[colIndex].fieldGroup === "CUSTOM" && data.customFields ? (
        <CustomDisplay
          allData={data}
          selectedStages={props.selectedStages}
          page={props.page}
          pageSub={props.pageSub}
          data={data.customFields[props.columns[colIndex].apiKeyName]}
          type={props.columns[colIndex].fieldInputType}
          field={props.columns[colIndex]}
          loadingData={props.filterPayload}
          combinedId={combinedId}
        />
      ) : (
        <CustomDisplay
          allData={data}
          selectedStages={props.selectedStages}
          page={props.page}
          pageSub={props.pageSub}
          data={data[props.columns[colIndex].apiKeyName]}
          type={props.columns[colIndex].fieldInputType}
          field={props.columns[colIndex]}
          loadingData={props.filterPayload}
          combinedId={combinedId}
        />
      );
    }
  };

  const isSortApplicable = (fieldData) => {
    if (fieldData.fieldGroup === "CUSTOM") return false;
    else if (
      ["LEAD", "PERSON"].includes(fieldData.fieldType) &&
      ["owner", "activity", "followers", "company", "totalActivities", "doneActivities", "activitiesToDo", "lastActivityDate", "tags"].includes(fieldData.apiKeyName)
    ) {
      return false;
    } else if (fieldData.fieldType === "COMPANY" && ["owner", "followers", "tags"].includes(fieldData.apiKeyName)) {
      return false;
    } else if (fieldData.fieldType === "PRODUCT" && ["owner", "pricing", "tags", "category"].includes(fieldData.apiKeyName)) {
      return false;
    } else if (fieldData.fieldType === "DEAL" && ["stageId", "title", "status"].includes(fieldData.apiKeyName)) {
      return true;
    } else if (["LEAD", "PERSON", "PRODUCT", "COMPANY"].includes(fieldData.fieldType)) {
      return true;
    }
    return false;
  };

  const getColumn = (colId, index) => {
    const colIndex = props.idToIndex[colId];
    if (props.fromActivityorDealList) {
      let fieldType = props.columns[props.columns.findIndex((item) => item["_id"] === colId)].fieldType.toLowerCase();
      return (
        <InlineStyled
          as="th"
          key={index}
          styles={{
            minWidth: `${100 / props.columns.length}%`,
          }}
          className="bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap z-index-1"
        >
          <div className="d-flex flex-direction w-100 h-100 gap-5px text-nowrap text-black opacity-0-point-8 align-items-center">
            <i
              className={
                fieldType === "activity"
                  ? "bi bi-calendar4"
                  : fieldType === "deal"
                  ? "bi bi-funnel"
                  : fieldType === "lead"
                  ? "bi bi-bullseye"
                  : fieldType === "person"
                  ? "bi bi-people"
                  : fieldType === "company"
                  ? "bi bi-building"
                  : "bi bi-calendar4"
              }
            ></i>
            {props.columns[props.columns.findIndex((item) => item["_id"] === colId)].fieldName}
          </div>
        </InlineStyled>
      );
    } else {
      return (
        <InlineStyled
          as="th"
          onClick={() => {
            if (isSortApplicable(props.columns[colIndex])) requestSort(props.columns[colIndex]["apiKeyName"], props.columns[colIndex]["fieldInputType"]);
          }}
          key={index}
          styles={{
            minWidth: `${100 / props.columns.length}%`,
          }}
          className="opacity-1 bg-white pt-0 position-sticky top-0 cursor-pointer text-nowrap z-index-1"
        >
          <div className="d-flex flex-row w-100 h-100 align-items-center gap-5px text-nowrap text-black opacity-0-point-8">
            {props.columns[colIndex].fieldName}
            {props.isApiSort ? (
              props.sortConfig && props.columns[colIndex]["apiKeyName"] === props.sortConfig.key ? (
                props.sortConfig.direction === "ascending" ? (
                  <i className="fa fa-arrow-up fa-sm"></i>
                ) : (
                  <i className="fa fa-arrow-down fa-sm"></i>
                )
              ) : (
                <div className="h-100 w-10px"></div>
              )
            ) : sortConfig && props.columns[colIndex]["apiKeyName"] === sortConfig.key ? (
              sortConfig.direction === "ascending" ? (
                <i className="fa fa-arrow-up fa-sm"></i>
              ) : (
                <i className="fa fa-arrow-down fa-sm"></i>
              )
            ) : (
              <div className="h-100 w-10px"></div>
            )}
          </div>
        </InlineStyled>
      );
    }
  };

  const onLossDealCancel = (id) => {
    document.body.click();
  };

  const onLossDealSave = (payload) => {
    props.callActivityMADoneApi(payload.data);
    document.body.click();
    if (payload.isNextSchedule) {
      props.setOpenAddActivityModal(true);
    }
  };
  const handleCloseEditPopover = () => {
    setEditing(false);
    setEdit(-1);
    props.setCellEdit({ id: "" });
  };

  const onTableRowClick = (data) => {
    if (props.page === "Leads") {
      if (!props.hidePanel) {
        handleCloseEditPopover();
        history.push(`/prospects/leads/${props.leadType}/${data._id}`);
      }
    } else if (props.page === "People") {
      if (!props.hidePanel) {
        handleCloseEditPopover();
        history.push(`/prospects/people/${data._id}`);
      }
    } else if (props.page === "Companies") {
      if (!props.hidePanel) {
        handleCloseEditPopover();
        history.push(`/prospects/companies/${data._id}`);
      }
    } else if (props.page === "Deals") {
      if (!props.hidePanel) {
        handleCloseEditPopover();
        props.onDealListRowClick(data);
      }
    } else if (props.page === "marketing") {
      if (!props.hidePanel) {
        handleCloseEditPopover();
        history.push(`/campaign/marketing/list/${data._id}`);
      }
    }
  };

  return (
    <>
      {props.loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : props.showColumns.length === 0 ? (
        <div className="w-100 d-flex justify-content-center align-items-center flex-column h-vh-200px">
          <h5>No Columns Selected</h5>
          <p>Select columns to display from the column select dropdown</p>
        </div>
      ) : (
        <>
          {props.showColumns.length === 0}
          <div className="table-responsive overflow-y-auto">
            <PerfectScrollbar className="scroll h-vh-240px-mx position-relative" options={{ wheelSpeed: 0.4 }}>
              <table className="table table-hover align-middle table-row-dashed dataTable no-footer font-size-14px">
                <thead>
                  <tr className="fw-bolder text-muted">
                    {props.checkboxes ? (
                      <th className="opacity-0-point-8 text-black bg-white pt-0 pl-2 w-25px z-index-1 position-sticky top-0">
                        <label className="checkbox checkbox-lg checkbox-single">
                          <input className="form-check-input" type="checkbox" value="1" onChange={() => checkAll()} checked={props.checked.length === tempData.length} />
                          <span></span>
                        </label>
                      </th>
                    ) : null}
                    {props.radioButtos ? <th className="opacity-0-point-8 text-black bg-white pt-0 pl-2 w-25px z-index-1 position-sticky top-0">Done</th> : null}
                    {props.showColumns.map((colId, index) => {
                      return getColumn(colId, index);
                    })}
                    {props.fromtable ? (
                      <>
                        <InlineStyled
                          as="th"
                          styles={{
                            minWidth: `${100 / props.columns.length}%`,
                          }}
                          className="bg-white pt-0 position-sticky cursor-pointer text-nowrap z-index-1 top-0"
                        >
                          <div className="opacity-0-point-8 text-black d-flex flex-row align-items-center w-100 h-100 gap-5px text-nowrap mr-40px">Lost Reason</div>
                        </InlineStyled>
                        <InlineStyled
                          as="th"
                          styles={{
                            minWidth: `${100 / props.columns.length}%`,
                          }}
                          className="bg-white pt-0 position-sticky cursor-pointer text-nowrap z-index-1 top-0"
                        >
                          <div className="opacity-0-point-8 text-black d-flex flex-row align-items-center w-100 h-100 gap-5px text-nowrap mr-40px">Comments</div>
                        </InlineStyled>
                      </>
                    ) : null}
                  </tr>
                </thead>

                <tbody>
                  {sortedTemp.map((data, rowIndex) => {
                    return (
                      <tr onClick={() => onTableRowClick(data)} className={`${!props.hidePanel ? "cursor-pointer" : ""} ${props.sidePannelSelectedId === data["_id"] ? "bg-f5f8fa" : null}`} key={rowIndex}>
                        {props.checkboxes ? (
                          <td className="pl-2 activity-status-color" onClick={(e) => e.stopPropagation()}>
                            <label className="checkbox checkbox-lg checkbox-single">
                              <input
                                disabled={props.isAllListSelected}
                                className="form-check-input"
                                type="checkbox"
                                value="1"
                                onChange={(e) => editChecked(data["_id"])}
                                checked={props.checked.includes(data["_id"]) || allChecked}
                              />
                              <span></span>
                            </label>
                          </td>
                        ) : null}
                        {props.radioButtos ? (
                          <td className="pl-2">
                            <label className="radio mt-3px" onClick={(e) => e.stopPropagation()}>
                              {!data.activity.isCompleted && props.lostReasonStatus && props.lostReasonStatus.isActivityLostReasonEnabled ? (
                                <OverlayTrigger
                                  rootClose={true}
                                  placement="right"
                                  trigger="click"
                                  className="z-index-0"
                                  overlay={
                                    <InlineStyled
                                      as={Popover}
                                      id="popover-basic"
                                      onClick={(e) => e.stopPropagation()}
                                      className="z-index-9999 my-0 mx-20px w-430px w-290px-mx"
                                      styles={{ transform: "translate(173px, 136px)" }}
                                    >
                                      <Popover.Title>
                                        <div className="fw-500 font-size-15px text-26292c">Mark As Done</div>
                                      </Popover.Title>
                                      <Popover.Content className="p-0 m-0">
                                        <MarkAsDoneActivity activityId={data._id} showNextSchedule={true} handleCancel={onLossDealCancel} handleSave={onLossDealSave} />
                                      </Popover.Content>
                                    </InlineStyled>
                                  }
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="123"
                                    onClick={(e) => editRadioChecked(e, data["_id"])}
                                    checked={props.radioChecked.includes(data["_id"]) || allRadioChecked || data.activity.isCompleted}
                                  />
                                </OverlayTrigger>
                              ) : (
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="123"
                                  onClick={(e) => editRadioChecked(e, data["_id"])}
                                  checked={props.radioChecked.includes(data["_id"]) || allRadioChecked || data.activity.isCompleted}
                                />
                              )}
                              <span></span>
                            </label>
                          </td>
                        ) : null}
                        {props.showColumns.map((colId, index) => (
                          <td className="text-nowrap" id={rowIndex.toString() + colId} onMouseOver={() => setHovering(rowIndex.toString() + colId, colId)} onMouseLeave={() => setNotHovering()} key={index}>
                            {props.cellLoading === rowIndex.toString() + colId ? (
                              <div className="w-100 d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant="primary" />
                              </div>
                            ) : (
                              <div className="d-flex flex-row flex-stack">
                                {getData(colId, data, rowIndex.toString() + colId)}
                                <PopoverEdit
                                  edit={edit}
                                  combinedId={rowIndex.toString() + colId}
                                  categoryStore={props.categoryStore}
                                  tagStore={props.tagStore}
                                  cellId={data["_id"]}
                                  editing={editing}
                                  setEditing={setEditing}
                                  setEdit={setEdit}
                                  rowIndex={rowIndex}
                                  field={props.fromActivityorDealList ? props.columns[props.columns.findIndex((item) => item["_id"] === colId)] : props.columns[props.idToIndex[colId]]}
                                  colId={colId}
                                  initialVal={
                                    props.columns[props.idToIndex[colId]].fieldGroup === "CUSTOM" && data.customFields
                                      ? data.customFields[props.columns[props.idToIndex[colId]].apiKeyName]
                                      : props.fromActivityorDealList
                                      ? data[props.columns[props.columns.findIndex((item) => item["_id"] === colId)].fieldType.toLowerCase()][
                                          props.columns[props.columns.findIndex((item) => item["_id"] === colId)].apiKeyName
                                        ]
                                      : data[props.columns[props.idToIndex[colId]].apiKeyName]
                                  }
                                  cellEdit={props.cellEdit}
                                  stages={props.stages}
                                  currentState={props.currentState}
                                  setCurrentState={props.setCurrentState}
                                  setCellEdit={props.setCellEdit}
                                  addTag={props.addTag}
                                  editTag={props.editTag}
                                  deleteTag={props.deleteTag}
                                  addCategory={props.addCategory}
                                  editCategory={props.editCategory}
                                  deleteCategory={props.deleteCategory}
                                  handleCellEdit={props.handleCellEdit}
                                  page={props.page}
                                  pageSub={props.pageSub}
                                  fromActivityorDealList={props.fromActivityorDealList}
                                  allData={data}
                                />
                              </div>
                            )}
                          </td>
                        ))}
                        {props.fromtable && props.fromtable === "junkLead" ? (
                          <td className="text-nowrap">
                            <div className="data-flex-container">{data.leadLostReasonId}</div>
                          </td>
                        ) : null}
                        {props.fromtable && props.fromtable === "junkLead" ? (
                          <td className="text-nowrap">
                            <div className="data-flex-container">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    <span className="ellipsis">{data.leadLostReasonComment}</span>
                                  </Tooltip>
                                }
                              >
                                <span>{data.leadLostReasonComment}</span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                  {sortedTemp.length === 0 && (
                    <tr>
                      <th className="p-0 m-0 text-centertext-center" colSpan="100%">
                        {" "}
                        <CustomEmptyPage page="emptyTable" />
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </PerfectScrollbar>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end px-3 gap-15px pt-7px">
            <p className="mt-5px mb-0 font-size-14px">
              Showing {(props.pageNo - 1) * props.limit + 1}-{props.count > props.pageNo * props.limit ? props.pageNo * props.limit : props.count} of {props.count}
            </p>
            {props.pagination ? CustomPagination(props.pageNo, props.setPageNo, Math.ceil(props.count / props.limit), props.setChecked, props.limit, props.setLimit, props.count) : null}
          </div>
        </>
      )}
    </>
  );
}
