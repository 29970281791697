import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export function IconBasedNav(props) {
  const onClick = (name) => {
    if (props.onClick) {
      props.onClick(name);
    }
  };
  return (
    <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
      {props.links.map((link, index) => (
        <div key={index} className="menu-item me-lg-1">
          {link.to ? (
            <NavLink onClick={() => onClick(link.name)} className={`menu-link px-2 ${props.active === link.name ? "active" : ""}`} to={link.to}>
              <OverlayTrigger overlay={<Tooltip>{link.name}</Tooltip>}>
                <span className="menu-icon m-0">
                  <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
                </span>
              </OverlayTrigger>
            </NavLink>
          ) : (
            <div onClick={() => onClick(link.name)} className={`menu-link px-2 ${props.active === link.name ? "active" : ""}`}>
              <OverlayTrigger overlay={<Tooltip>{link.name}</Tooltip>}>
                <span className="menu-icon m-0">
                  <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>
      ))}
      {props.children}
    </div>
  );
}
