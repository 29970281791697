import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { CustomSubHeader } from "../../../../components/subheader";
import { SubheaderNav } from "../../../../components/subheader/subheaderNav";

export default function BillingSubHeader(props) {
  const links = [
    { name: "Subscription", to: "/settings/billing/subscription", icon: "bi bi-cash" },
    { name: "Wallet", to: "/settings/billing/wallet", icon: "bi bi-wallet2" },
  ];

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const isBillingExpired = () => {
    // if (accountBillingStore.loading) return false;
    if (_.isEmpty(accountBillingStore.data)) return false;
    const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
    if (isFreeTrialPeriod || isSubscribedForLifeTimeFree) return false;
    else if (["active", "past_due", "trialing"].includes(subscriptionStatus)) return false;
    else if (["none", "canceled", "paused"].includes(subscriptionStatus)) return true;
    return true;
  };

  return (
    <>
      {isBillingExpired() ? (
        <CustomSubHeader>
          <div className="menu-item me-lg-1">
            <NavLink className={`menu-link py-3 ${props.tabName === "subscription" ? "active" : ""}`} to="/settings/billing/subscription">
              <span className="menu-title">Subscription</span>
            </NavLink>
          </div>
        </CustomSubHeader>
      ) : (
        <CustomSubHeader>
          <div className="d-flex w-100 flex-stack align-items-center">
            <SubheaderNav links={links} active={props.tabName} />
            <div>{props.children}</div>
          </div>
        </CustomSubHeader>
      )}
    </>
  );
}
