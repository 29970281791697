import React, { useState } from "react";
import { Form, Dropdown, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../utils/toaster";
import AutoTextArea from "../auto-resize-textarea";

export function MarkAsDoneActivity(props) {
  const [selectedLostReasonId, setSelectedLostReasonId] = useState("");
  const [selectedLostReason, setSelectedLostReason] = useState("");
  const [doneActivityNote, setDoneActivityNote] = useState("");
  const [scheduleNextActivity, setScheduleNextActivity] = useState(false);

  const activityLossReasonStore = useSelector((store) => store.activity.lossReasons.reasons.list);

  const onLostReasonChange = (reason) => {
    setSelectedLostReason(reason.reason);
    setSelectedLostReasonId(reason._id);
  };

  const onLossDealCancel = () => {
    setSelectedLostReason("");
    setSelectedLostReasonId("");
    setDoneActivityNote("");
    setScheduleNextActivity(false);
    props.handleCancel();
  };

  const onLossDealSave = () => {
    if (!selectedLostReasonId) {
      dispatchSnackbarError("Please select activity outcome", "error");
    } else {
      let data = {
        id: props.activityId,
        reasonId: selectedLostReasonId,
        comment: "",
        note: doneActivityNote,
      };
      props.handleSave({ data: data, isNextSchedule: scheduleNextActivity });
      setSelectedLostReason("");
      setSelectedLostReasonId("");
      setDoneActivityNote("");
      setScheduleNextActivity(false);
    }
  };

  return (
    <>
      <div className="p-20px">
        <Form.Group id="activity-mark-as-done-outcome" className="mb-3">
          <Form.Label aria-label="activity-outcome">Activity Outcome</Form.Label>
          <Dropdown className="dropdown-inline mt-1 w-100">
            <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
              {selectedLostReason}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu w-100-mn">
              {activityLossReasonStore.map((lostReason, index) => (
                <Dropdown.Item key={index} className="dropdown-hover" onClick={() => onLostReasonChange(lostReason)}>
                  {lostReason.reason}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <Form.Group id="activity-mark-as-done-note" className="mb-3">
          <Form.Label aria-label="note">Note</Form.Label>
          <AutoTextArea onChange={(e) => setDoneActivityNote(e.target.value)} />
        </Form.Group>
        {props.showNextSchedule && (
          <div className="d-flex">
            <label className="checkbox checkbox-lg checkbox-single">
              <input className="form-check-input" type="checkbox" value="12" onChange={(e) => setScheduleNextActivity(e.target.checked)} checked={scheduleNextActivity} />
              <span></span>
            </label>
            <label className="col-8 col-form-label">Schedule next activity</label>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center gap-2 p-20px pt-10px">
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={(e) => onLossDealCancel()}>
          Cancel
        </Button>
        <Button className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={() => onLossDealSave()}>
          Mark As Done
        </Button>
      </div>
    </>
  );
}
