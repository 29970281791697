import { GET_USAGE_RECORD_REPORTS_REQUEST, GET_USAGE_RECORD_REPORTS_SUCCESS, GET_USAGE_RECORD_REPORTS_ERROR, getUsageRecordsV2 } from "../types/usage.types";

export function getUsageRecordReportRequest(req) {
  return {
    type: GET_USAGE_RECORD_REPORTS_REQUEST,
    payload: req,
  };
}
export function getUsageRecordReportSuccess(req) {
  return {
    type: GET_USAGE_RECORD_REPORTS_SUCCESS,
    payload: req,
  };
}
export function getUsageRecordReportError(req) {
  return {
    type: GET_USAGE_RECORD_REPORTS_ERROR,
    payload: req,
  };
}

export function getUsageRecordReportV2Request(req) {
  return {
    type: getUsageRecordsV2.GET_USAGE_RECORDS_V2_REQUEST,
    payload: req,
  };
}
export function getUsageRecordReportV2Success(req) {
  return {
    type: getUsageRecordsV2.GET_USAGE_RECORDS_V2_SUCCESS,
    payload: req,
  };
}
export function getUsageRecordReportV2Error(req) {
  return {
    type: getUsageRecordsV2.GET_USAGE_RECORDS_V2_ERROR,
    payload: req,
  };
}
