import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as companyListActions from "../../actions/profile-actions/company-list-settings.actions";
import * as companyListTypes from "../../types/profile-settings.types";
import { endpoints } from "../../endpoint";

function* companyListFunction(action) {
  try {
    const res = yield call(api.get, endpoints.COMPANY_LIST, {});

    yield put(companyListActions.companyListSuccess(res.data));
    yield action.cb(res.data);
  } catch (e) {
    yield put(companyListActions.companyListError(e));
  }
}

export default function* companyListSaga() {
  yield takeLatest(companyListTypes.companyList.COMPANY_LIST_REQUEST, companyListFunction);
}
