import React from "react";
import { Dropdown } from "react-bootstrap";

const headerKeys = ["", "View", "Edit", "Delete"];
const coloumKeys = [
  { key: "lead", value: "Lead" },
  { key: "deals", value: "Deal" },
  { key: "activity", value: "Activity" },
  { key: "contact", value: "Contact" },
  { key: "company", value: "Company" },
  { key: "products", value: "Product" },
  // { key: "marketing", value: "Marketing" },
  { key: "import", value: "Import" },
  { key: "export", value: "Export" },
  { key: "workflow", value: "Workflow" },
  { key: "campaign", value: "Campaign" },
];
const options = {
  contact: {
    type: "dropdown",
    default: "Owned Only",
  },
  deals: {
    type: "dropdown",
    default: "Owned Only",
  },
  company: {
    type: "dropdown",
    default: "Owned Only",
  },
  lead: {
    type: "dropdown",
    default: "Owned Only",
  },
  products: {
    type: "dropdown",
    default: "Owned Only",
  },
  activity: {
    type: "dropdown",
    default: "Owned Only",
  },
  // marketing: {
  //     type: "dropdown",
  //     default: "Owned Only"
  // },
  import: {
    type: "boolean",
    default: true,
    description: "Allow the user to import records.",
  },
  export: {
    type: "boolean",
    default: false,
    description: "Allow the user to export records.",
  },
  workflow: {
    type: "boolean",
    default: false,
    description: "Allow users to manage the workflow automation. Please note that the user will get all the access.",
  },
  campaign: {
    type: "boolean",
    default: false,
    description: "Allow users to manage the campaigns. Please note that the user will get all the access of campaign module.",
  },
};

const indexView = ["Everything", "Team Only", "Owned Only", "None"];

const optimisedValueData = (value) => {
  const data = {
    contact: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    deals: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    company: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    lead: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    products: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    activity: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    marketing: {
      communicate: "Owned Only",
      delete: "Owned Only",
      edit: "Owned Only",
      view: "Owned Only",
    },
    import: false,
    export: false,
    workflow: false,
    campaign: false,
  };

  if (!value.contact) {
    value.contact = data.contact;
  }
  if (!value.deals) {
    value.deals = data.deals;
  }
  if (!value.company) {
    value.company = data.company;
  }
  if (!value.contact) {
    value.contact = data.contact;
  }
  if (!value.lead) {
    value.lead = data.lead;
  }
  if (!value.products) {
    value.products = data.products;
  }
  if (!value.activity) {
    value.activity = data.activity;
  }
  if (!value.import) {
    value.import = data.import;
  }
  if (!value.export) {
    value.export = data.export;
  }
  if (!value.workflow) {
    value.workflow = data.workflow;
  }
  if (!value.marketing) {
    value.marketing = data.marketing;
  }
  if (!value.campaign) {
    value.campaign = data.campaign;
  }
  return value;
};
export default function ManageUserPermission(props) {
  const value = optimisedValueData(props.value);

  const onSelectPermission = (fieldKey, type, _value) => {
    const permissions = { ...value };

    permissions[fieldKey][type] = _value;

    if (type === "view") {
      if (indexView.indexOf(_value) > indexView.indexOf(permissions[fieldKey]["edit"])) {
        permissions[fieldKey]["edit"] = _value;
      }
      if (indexView.indexOf(_value) > indexView.indexOf(permissions[fieldKey]["delete"])) {
        permissions[fieldKey]["delete"] = _value;
      }
    }
    props.setValue(permissions);
  };

  const onToggleChange = (event, fieldKey) => {
    const permissions = { ...value };

    permissions[fieldKey] = event.target.checked;

    props.setValue(permissions);
  };

  const getOptions = (type, viewType) => {
    if (type === "view") {
      return ["Everything", "Team Only", "Owned Only"];
    } else {
      if (viewType === "Everything") return ["None", "Everything", "Team Only", "Owned Only"];
      else if (viewType === "Team Only") return ["None", "Team Only", "Owned Only"];
      else if (viewType === "Owned Only") return ["None", "Owned Only"];
      else return ["None"];
    }
  };

  const isViewDisabled = (option) => {
    const { userType, isManager } = props;
    if (!userType && !isManager) {
      return false;
    } else if (userType === "ADMIN") {
      if (option === "Everything") return false;
      return false; //true;
    } else if (isManager) {
      if (option === "Team Only") return false;
      return false; //true;
    } else if (userType === "AGENT") {
      if (option === "Owned Only") return false;
      return false; //true;
    }
    return false;
  };

  return (
    <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
      <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
        <tr>
          {headerKeys.map((each, index) => {
            return (
              <td className="w-25prc" align="center" key={index}>
                {each}
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {coloumKeys.map((each, index) => {
          return (
            <tr key={index}>
              <td key={index}>{each.value}</td>
              {options[each.key].type === "dropdown" ? (
                <>
                  <td>
                    <div className="my-1 me-4">
                      <Dropdown drop="down" onSelect={(e) => {}}>
                        <Dropdown.Toggle id="newSS" className="newDropDesign">
                          {value[each.key].view}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                          {getOptions("view", value[each.key].view).map((option, ind) => {
                            return (
                              <Dropdown.Item
                                disabled={isViewDisabled(option)}
                                key={ind}
                                className="dropdown-hover"
                                onSelect={(e) => {
                                  onSelectPermission(each.key, "view", option);
                                }}
                              >
                                {option}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                  <td className="my-1 me-4">
                    <div className="my-1 me-4">
                      <Dropdown drop="down" onSelect={(e) => {}}>
                        <Dropdown.Toggle id="newSS" className="newDropDesign">
                          {value[each.key].edit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                          {getOptions("edit", value[each.key].view).map((option, ind) => {
                            return (
                              <Dropdown.Item
                                disabled={false}
                                key={ind}
                                className="dropdown-hover"
                                onSelect={(e) => {
                                  onSelectPermission(each.key, "edit", option);
                                }}
                              >
                                {option}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                  <td className="my-1 me-4">
                    <div className="my-1 me-4">
                      <Dropdown drop="down" onSelect={(e) => {}}>
                        <Dropdown.Toggle id="newSS" className="newDropDesign">
                          {value[each.key].delete}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                          {getOptions("delete", value[each.key].view).map((option, ind) => {
                            return (
                              <Dropdown.Item
                                disabled={false}
                                key={ind}
                                className="dropdown-hover"
                                onSelect={(e) => {
                                  onSelectPermission(each.key, "delete", option);
                                }}
                              >
                                {option}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td colSpan={2}>
                    <div className="fw-bolder">{each.value}</div>
                    <div className="text-gray-600">{options[each.key].description}</div>
                  </td>
                  <td>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input
                        className="form-check-input h-20px w-35px"
                        type="checkbox"
                        onChange={(e) => {
                          onToggleChange(e, each.key);
                        }}
                        checked={value[each.key]}
                        name={each.value}
                      />
                      <label className="form-check-label" htmlFor="favoriteSwitch"></label>
                    </div>
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
