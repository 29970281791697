export const GET_ACTIVITIES_LIST_REQUEST = "GET_ACTIVITIES_LIST_REQUEST";
export const GET_ACTIVITIES_LIST_SUCCESS = "GET_ACTIVITIES_LIST_SUCCESS";
export const GET_ACTIVITIES_LIST_ERROR = "GET_ACTIVITIES_LIST_ERROR";
export const GET_ACTIVITIES_LIST_REQUEST_WITHOUT_LOADING = "GET_ACTIVITIES_LIST_REQUEST_WITHOUT_LOADING";

export const LEADS_DROPDOWN_REQUEST = "LEADS_DROPDOWN_REQUEST";
export const LEADS_DROPDOWN_SUCCESS = "LEADS_DROPDOWN_SUCCESS";
export const LEADS_DROPDOWN_ERROR = "LEADS_DROPDOWN_ERROR";

export const LEADS_DROPDOWNADD_REQUEST = "LEADS_DROPDOWNADD_REQUEST";
export const LEADS_DROPDOWNADD_SUCCESS = "LEADS_DROPDOWNADD_SUCCESS";
export const LEADS_DROPDOWNADD_ERROR = "LEADS_DROPDOWNADD_ERROR";

export const LEADS_DROPDOWNADD_CLEAR = "LEADS_DROPDOWNADD_CLEAR";

export const GET_ACTIVITYLISTFIELDS_REQUEST = "GET_ACTIVITYLISTFIELDS_REQUEST";
export const GET_ACTIVITYLISTFIELDS_SUCCESS = "GET_ACTIVITYLISTFIELDS_SUCCESS";
export const GET_ACTIVITYLISTFIELDS_ERROR = "GET_ACTIVITYLISTFIELDS_ERROR";

export const FIELD_UPDATE_REQUEST = "FIELD_UPDATE_REQUEST";
export const FIELD_UPDATE_SUCCESS = "FIELD_UPDATE_SUCCESS";
export const FIELD_UPDATE_ERROR = "FIELD_UPDATE_ERROR";

export const FIELD_SHOWHIDE_REQUEST = "FIELD_SHOWHIDE_REQUEST";
export const FIELD_SHOWHIDE_SUCCESS = "FIELD_SHOWHIDE_SUCCESS";
export const FIELD_SHOWHIDE_ERROR = "FIELD_SHOWHIDE_ERROR";

export const FIELD_UPDATEPRIORITY_REQUEST = "FIELD_UPDATEPRIORITY_REQUEST";
export const FIELD_UPDATEPRIORITY_SUCCESS = "FIELD_UPDATEPRIORITY_SUCCESS";
export const FIELD_UPDATEPRIORITY_ERROR = "FIELD_UPDATEPRIORITY_ERROR";

export const GET_MASAVEDFILTERS_REQUEST = "GET_MASAVEDFILTERS_REQUEST";
export const GET_MASAVEDFILTERS_SUCCESS = "GET_MASAVEDFILTERS_SUCCESS";
export const GET_MASAVEDFILTERS_ERROR = "GET_MASAVEDFILTERS_ERROR";

export const ADD_MASAVEDFILTERS_REQUEST = "ADD_MASAVEDFILTERS_REQUEST";
export const ADD_MASAVEDFILTERS_SUCCESS = "ADD_MASAVEDFILTERS_SUCCESS";
export const ADD_MASAVEDFILTERS_ERROR = "ADD_MASAVEDFILTERS_ERROR";

export const EDIT_MASAVEDFILTERS_REQUEST = "EDIT_MASAVEDFILTERS_REQUEST";
export const EDIT_MASAVEDFILTERS_SUCCESS = "EDIT_MASAVEDFILTERS_SUCCESS";
export const EDIT_MASAVEDFILTERS_ERROR = "EDIT_MASAVEDFILTERS_ERROR";

export const DELETE_MASAVEDFILTERS_REQUEST = "DELETE_MASAVEDFILTERS_REQUEST";
export const DELETE_MASAVEDFILTERS_SUCCESS = "DELETE_MASAVEDFILTERS_SUCCESS";
export const DELETE_MASAVEDFILTERS_ERROR = "DELETE_MASAVEDFILTERS_ERROR";

export const FAVORITE_MASAVEDFILTERS_REQUEST = "FAVORITE_MASAVEDFILTERS_REQUEST";
export const FAVORITE_MASAVEDFILTERS_SUCCESS = "FAVORITE_MASAVEDFILTERS_SUCCESS";
export const FAVORITE_MASAVEDFILTERS_ERROR = "FAVORITE_MASAVEDFILTERS_ERROR";

export const MA_APPLYFILTER = "MA_APPLYPRODUCTFILTER";
export const MA_CLEARFILTER = "MA_CLEARPRODUCTFILTER";

export const MARKASDONE_REQUEST = "MARKASDONE_REQUEST";
export const MARKASDONE_SUCCESS = "MARKASDONE_SUCCESS";
export const MARKASDONE_ERROR = "MARKASDONE_ERROR";

export const ACTIVITY_LOSTREASONS_REQUEST = "ACTIVITY_LOSTREASONS_REQUEST";
export const ACTIVITY_LOSTREASONS_SUCCESS = "ACTIVITY_LOSTREASONS_SUCCESS";
export const ACTIVITY_LOSTREASONS_ERROR = "ACTIVITY_LOSTREASONS_ERROR";

export const BULK_EDIT_REQUEST = "BULK_EDIT_REQUEST";
export const BULK_EDIT_SUCCESS = "BULK_EDIT_SUCCESS";
export const BULK_EDIT_ERROR = "BULK_EDIT_ERROR";

export const BULK_DELETE_REQUEST = "BULK_DELETE_REQUEST";
export const BULK_DELETE_SUCCESS = "BULK_DELETE_SUCCESS";
export const BULK_DELETE_ERROR = "BULK_DELETE_ERROR";

export const MARKAS_UNDONE_REQUEST = "MARKAS_UNDONE_REQUEST";
export const MARKAS_UNDONE_SUCCESS = "MARKAS_UNDONE_SUCCESS";
export const MARKAS_UNDONE_ERROR = "MARKAS_UNDONE_ERROR";

export const GET_ACTIVITY_LOSTREASON_SETTINGS_REQUEST = "GET_ACTIVITY_LOSTREASON_SETTINGS_REQUEST";
export const GET_ACTIVITY_LOSTREASON_SETTINGS_SUCCESS = "GET_ACTIVITY_LOSTREASON_SETTINGS_SUCCESS";
export const GET_ACTIVITY_LOSTREASON_SETTINGS_ERROR = "GET_ACTIVITY_LOSTREASON_SETTINGS_ERROR";

export const UPDATE_ACTIVITY_LOSTREASON_SETTINGS_REQUEST = "UPDATE_ACTIVITY_LOSTREASON_SETTINGS_REQUEST";
export const UPDATE_ACTIVITY_LOSTREASON_SETTINGS_SUCCESS = "UPDATE_ACTIVITY_LOSTREASON_SETTINGS_SUCCESS";
export const UPDATE_ACTIVITY_LOSTREASON_SETTINGS_ERROR = "UPDATE_ACTIVITY_LOSTREASON_SETTINGS_ERROR";

export const GET_USER_ACTIVITYLOGS_REQUEST = "GET_USER_ACTIVITYLOGS_REQUEST";
export const GET_USER_ACTIVITYLOGS_SUCCESS = "GET_USER_ACTIVITYLOGS_SUCCESS";
export const GET_USER_ACTIVITYLOGS_ERROR = "GET_USER_ACTIVITYLOGS_ERROR";

export const LOADING_ACTIVITY_DATA_UPDATE = "LOADING_ACTIVITY_DATA_UPDATE";
