import { getAccountBillingV2, getBillingInvoiceListV2, getBillingPricesV2, updateSubscriptionV2, cancelSubscriptionV2, resumeSubscriptionV2, pauseSubscriptionV2, assignFreePlanV2, dontCancelSubscriptionV2 } from "../types/subscription_billing_v2_types";

export const getAccountBillingV2Request = (payload, cb) => ({
    type: getAccountBillingV2.REQUEST,
    payload,
    cb
});
export const getAccountBillingV2Success = (payload) => ({
    type: getAccountBillingV2.SUCCESS,
    payload
});
export const getAccountBillingV2Error = (payload) => ({
    type: getAccountBillingV2.ERROR,
    payload
});
export const getAccountBillingV2AfterRequest = (payload) => ({
    type: getAccountBillingV2.AFTER_REQUEST,
    payload
});

export const getBillingInvoiceListV2Request = (payload, cb) => ({
    type: getBillingInvoiceListV2.REQUEST,
    payload,
    cb
});
export const getBillingInvoiceListV2Success = (payload) => ({
    type: getBillingInvoiceListV2.SUCCESS,
    payload
});
export const getBillingInvoiceListV2Error = (payload) => ({
    type: getBillingInvoiceListV2.ERROR,
    payload
});

export const getBillingPricesV2Request = (payload, cb) => ({
    type: getBillingPricesV2.REQUEST,
    payload,
    cb
});
export const getBillingPricesV2Success = (payload) => ({
    type: getBillingPricesV2.SUCCESS,
    payload
});
export const getBillingPricesV2Error = (payload) => ({
    type: getBillingPricesV2.ERROR,
    payload
});

export const updateSubscriptionV2Request = (payload, cb) => ({
    type: updateSubscriptionV2.REQUEST,
    payload,
    cb
});
export const updateSubscriptionV2Success = (payload) => ({
    type: updateSubscriptionV2.SUCCESS,
    payload
});
export const updateSubscriptionV2Error = (payload) => ({
    type: updateSubscriptionV2.ERROR,
    payload
});

export const cancelSubscriptionV2Request = (payload, cb) => ({
    type: cancelSubscriptionV2.REQUEST,
    payload,
    cb
});
export const cancelSubscriptionV2Success = (payload) => ({
    type: cancelSubscriptionV2.SUCCESS,
    payload
});
export const cancelSubscriptionV2Error = (payload) => ({
    type: cancelSubscriptionV2.ERROR,
    payload
});

export const resumeSubscriptionV2Request = (payload, cb) => ({
    type: resumeSubscriptionV2.REQUEST,
    payload,
    cb
});
export const resumeSubscriptionV2Success = (payload) => ({
    type: resumeSubscriptionV2.SUCCESS,
    payload
});
export const resumeSubscriptionV2Error = (payload) => ({
    type: resumeSubscriptionV2.ERROR,
    payload
});

export const pauseSubscriptionV2Request = (payload, cb) => ({
    type: pauseSubscriptionV2.REQUEST,
    payload,
    cb
});
export const pauseSubscriptionV2Success = (payload) => ({
    type: pauseSubscriptionV2.SUCCESS,
    payload
});
export const pauseSubscriptionV2Error = (payload) => ({
    type: pauseSubscriptionV2.ERROR,
    payload
});

export const assignFreePlanV2Request = (payload, cb) => ({
    type: assignFreePlanV2.REQUEST,
    payload,
    cb
});
export const assignFreePlanV2Success = (payload) => ({
    type: assignFreePlanV2.SUCCESS,
    payload
});
export const assignFreePlanV2Error = (payload) => ({
    type: assignFreePlanV2.ERROR,
    payload
});

export const dontCancelSubscriptionV2Request = (payload, cb) => ({
    type: dontCancelSubscriptionV2.REQUEST,
    payload,
    cb
});
export const dontCancelSubscriptionV2Success = (payload) => ({
    type: dontCancelSubscriptionV2.SUCCESS,
    payload
});
export const dontCancelSubscriptionV2Error = (payload) => ({
    type: dontCancelSubscriptionV2.ERROR,
    payload
});
