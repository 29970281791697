import React from "react";
import { Dropdown } from "react-bootstrap";
import { dataFieldToComparatorMapping } from "./constants";

export default function ConditionDropdown(props) {
  const textOptions = ["is", "is not", "is empty", "is not empty"];

  const handleThirdAndDropdown = (option, index, item) => {
    if (props.isAndCondition) {
      props.handleThirdAndDropdown(option, index, item);
    } else {
      props.handleThirdOrDropdown(option, index, item);
    }
  };

  const getOptions = (key) => {
    if (dataFieldToComparatorMapping[key]) return dataFieldToComparatorMapping[key];
    return textOptions;
  };

  return (
    <Dropdown className="mt-0 w-100">
      <Dropdown.Toggle className="ellipsis w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {props.value ? props.value : "Select Condition"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100-mn">
        <div className="h-25vh-mx position-relative overflow-y-auto">
          {getOptions(props.field && props.field.fieldInputType).map((option, optionIndex) => {
            return (
              <Dropdown.Item key={optionIndex} className="dropdown-hover" onSelect={(e) => handleThirdAndDropdown(option, props.index, props.item)}>
                {option}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
