import {
  GET_ACTIVITIES_LIST_REQUEST,
  GET_ACTIVITIES_LIST_SUCCESS,
  GET_ACTIVITIES_LIST_ERROR,
  LEADS_DROPDOWN_REQUEST,
  LEADS_DROPDOWN_SUCCESS,
  LEADS_DROPDOWN_ERROR,
  LEADS_DROPDOWNADD_REQUEST,
  LEADS_DROPDOWNADD_SUCCESS,
  LEADS_DROPDOWNADD_ERROR,
  LEADS_DROPDOWNADD_CLEAR,
  GET_ACTIVITYLISTFIELDS_REQUEST,
  GET_ACTIVITYLISTFIELDS_SUCCESS,
  GET_ACTIVITYLISTFIELDS_ERROR,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  GET_MASAVEDFILTERS_REQUEST,
  GET_MASAVEDFILTERS_SUCCESS,
  GET_MASAVEDFILTERS_ERROR,
  ADD_MASAVEDFILTERS_REQUEST,
  ADD_MASAVEDFILTERS_SUCCESS,
  ADD_MASAVEDFILTERS_ERROR,
  EDIT_MASAVEDFILTERS_REQUEST,
  EDIT_MASAVEDFILTERS_ERROR,
  DELETE_MASAVEDFILTERS_REQUEST,
  DELETE_MASAVEDFILTERS_ERROR,
  FAVORITE_MASAVEDFILTERS_REQUEST,
  FAVORITE_MASAVEDFILTERS_ERROR,
  MA_APPLYFILTER,
  MA_CLEARFILTER,
  ACTIVITY_LOSTREASONS_REQUEST,
  ACTIVITY_LOSTREASONS_SUCCESS,
  ACTIVITY_LOSTREASONS_ERROR,
  BULK_EDIT_REQUEST,
  BULK_EDIT_SUCCESS,
  BULK_EDIT_ERROR,
  GET_ACTIVITY_LOSTREASON_SETTINGS_REQUEST,
  GET_ACTIVITY_LOSTREASON_SETTINGS_SUCCESS,
  GET_ACTIVITY_LOSTREASON_SETTINGS_ERROR,
  GET_USER_ACTIVITYLOGS_REQUEST,
  GET_USER_ACTIVITYLOGS_SUCCESS,
  GET_USER_ACTIVITYLOGS_ERROR,
  LOADING_ACTIVITY_DATA_UPDATE,
} from "./types";

const initialState = {
  activityList: {
    loading: "",
    list: [],
    count: 0,
    cellLoading: false,
  },
  activityFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  loading: true,
  leadDropdownList: {
    loading: "",
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
  newLead: null,
  activityFilters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  activityLostReasons: [],
  loadingData: {
    pageNo: 1,
    limit: 50,
    filter: "To Do",
  },
  activityLostReasonSettingStatus: {},
  userActivityLogs: {
    loading: true,
    data: [],
    count: 0,
  },
  // you can define required variables here
};

export function activitiesReducer(state = initialState, action = {}) {
  switch (action.type) {
    //activity logs
    case GET_USER_ACTIVITYLOGS_REQUEST:
      return {
        ...state,
        userActivityLogs: { loading: true, data: [] },
      };
    case GET_USER_ACTIVITYLOGS_SUCCESS:
      return {
        ...state,
        userActivityLogs: {
          data: action.payload.data,
          count: action.payload.count,
          loading: false,
        },
      };
    case GET_USER_ACTIVITYLOGS_ERROR:
      return {
        ...state,
        loading: false,
      };

    //Update loading data
    case LOADING_ACTIVITY_DATA_UPDATE:
      return {
        ...state,
        loadingData: action.payload,
      };
    //activity lost reason settings
    case GET_ACTIVITY_LOSTREASON_SETTINGS_REQUEST:
      return {
        ...state,
        activityLostReasonSettingStatus: {},
      };
    case GET_ACTIVITY_LOSTREASON_SETTINGS_SUCCESS:
      return {
        ...state,
        activityLostReasonSettingStatus: action.payload,
      };
    case GET_ACTIVITY_LOSTREASON_SETTINGS_ERROR:
      return {
        ...state,
        activityLostReasonSettingStatus: {},
      };

    //bulkedit
    case BULK_EDIT_REQUEST:
      return {
        ...state,
        activityList: { ...state.activityList, loading: false, cellLoading: action.payload.cellId },
      };
    case BULK_EDIT_SUCCESS:
      return {
        ...state,
      };
    case BULK_EDIT_ERROR:
      return {
        ...state,
        activityList: { ...state.activityList, loading: false, cellLoading: false },
      };

    case ACTIVITY_LOSTREASONS_REQUEST:
      return {
        ...state,
        activityLostReasons: [],
      };
    case ACTIVITY_LOSTREASONS_SUCCESS:
      return {
        ...state,
        activityLostReasons: action.payload,
      };
    case ACTIVITY_LOSTREASONS_ERROR:
      return {
        ...state,
        activityLostReasons: [],
      };
    case MA_APPLYFILTER:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, activeFilter: action.payload },
      };
    case MA_CLEARFILTER:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, activeFilter: null },
      };
    case GET_MASAVEDFILTERS_REQUEST:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: true },
      };
    case GET_MASAVEDFILTERS_SUCCESS:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: false, list: action.payload.data },
      };
    case GET_MASAVEDFILTERS_ERROR:
      return {
        ...state,
        activityFilters: { ...state.productList, loading: false },
        // error:{err:true,errMsg:action.payload.message}
      };
    case ADD_MASAVEDFILTERS_REQUEST:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: true },
      };
    case ADD_MASAVEDFILTERS_SUCCESS:
      return {
        ...state,
        // activityFilters:{...state.activityFilters,loading:false,list:action.payload.data},
      };
    case ADD_MASAVEDFILTERS_ERROR:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: false },
      };

    case EDIT_MASAVEDFILTERS_REQUEST:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: true },
      };

    case EDIT_MASAVEDFILTERS_ERROR:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: false },
      };

    case DELETE_MASAVEDFILTERS_REQUEST:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: true },
      };

    case DELETE_MASAVEDFILTERS_ERROR:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: false },
      };

    case FAVORITE_MASAVEDFILTERS_REQUEST:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: true },
      };

    case FAVORITE_MASAVEDFILTERS_ERROR:
      return {
        ...state,
        activityFilters: { ...state.activityFilters, loading: false },
      };

    case FIELD_UPDATE_SUCCESS:
      var tempUpdate = {};
      for (var j = 0; j < action.payload.length; j++) {
        tempUpdate[action.payload[j]["_id"]] = j;
      }
      return {
        ...state,
        activityFields: { list: action.payload, idToIndex: tempUpdate },
      };
    case FIELD_UPDATE_ERROR:
      return {
        ...state,
        error: { err: true, errMsg: action.payload.message },
      };
    case LEADS_DROPDOWNADD_REQUEST:
      return {
        ...state,
        leadDropdownList: { ...state.leadDropdownList, loading: true },
      };
    case LEADS_DROPDOWNADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
        newLead: { id: action.payload.id, name: action.payload.name },
      };
    case LEADS_DROPDOWNADD_ERROR:
      return {
        ...state,
        leadDropdownList: { ...state.leadDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case LEADS_DROPDOWNADD_CLEAR:
      return {
        ...state,
        leadDropdownList: { ...state.leadDropdownList, loading: false },
        newLead: null,
      };
    case LEADS_DROPDOWN_REQUEST:
      return {
        ...state,
        leadDropdownList: { ...state.leadDropdownList, loading: true },
      };
    case LEADS_DROPDOWN_SUCCESS:
      return {
        ...state,
        leadDropdownList: { loading: false, list: action.payload.data },
      };
    case LEADS_DROPDOWN_ERROR:
      return {
        ...state,
        leadDropdownList: { ...state.leadDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case GET_ACTIVITIES_LIST_REQUEST:
      return {
        ...state,
        activityList: { ...state.activityList, loading: true },
        loading: true,
      };

    case GET_ACTIVITIES_LIST_SUCCESS:
      return {
        ...state,
        activityList: {
          loading: false,
          list: action.payload.data,
          count: action.payload.count,
        },
        loading: false,
      };
    case GET_ACTIVITIES_LIST_ERROR:
      return {
        ...state,
        activityList: { ...state.activityList, loading: false, list: [] },
        loading: true,
      };
    case GET_ACTIVITYLISTFIELDS_REQUEST:
      return {
        ...state,
        activityFields: { ...state.activityFields, loading: true },
      };
    case GET_ACTIVITYLISTFIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        activityFields: { loading: false, list: action.payload, idToIndex: tempObj },
      };
    case GET_ACTIVITYLISTFIELDS_ERROR:
      return {
        ...state,
        activityFields: { ...state.activityFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    default:
      return state;
  }
}
