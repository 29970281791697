import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

export default function SalesForecastSummary(props) {
  const [thead, setThead] = useState(["Time", "Target", "Result", "Difference"]);
  const [thKey, setThKey] = useState(["category", "won", "active", "target"]);

  useEffect(() => {
    if (props.selectedSalesForecast.forecastType) {
      if (props.selectedSalesForecast.forecastType === "openDeals") {
        setThead(["Time", "Open Deals"]);
        setThKey(["category", "active"]);
      } else if (props.selectedSalesForecast.forecastType === "openAndWonDeals") {
        setThead(["Time", "Won Deals", "Open Deals"]);
        setThKey(["category", "won", "active"]);
      } else if (props.selectedSalesForecast.forecastType === "openAndWonDealsWithSalesTarget") {
        setThead(["Time", "Won Deals", "Open Deals", "Target"]);
        setThKey(["category", "won", "active", "target"]);
      }
    }
  }, [props.selectedSalesForecast.forecastType]);

  if (props.data.length === 0) return <></>;
  return (
    <Card className="sales-forecast-summary-wrapper">
      <Card.Header>Summary</Card.Header>
      <Card.Body>
        <table className="table align-middle table-row-dashed dataTable no-footer font-size-14px">
          <thead>
            <tr className="fw-bolder text-muted">
              {thead.map((each, index) => (
                <th key={index} className="w-18 cursor-pointer text-nowrap position-sticky top-0 pt-0 bg-white text-black">
                  <div className="d-flex flex-row w-100 h-100 align-items-center">{each}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((each, index) => {
              return (
                <tr key={index}>
                  {thKey.map((_each, _index) => {
                    return (
                      <td key={_index} className="text-nowrap">
                        <div className="d-flex flex-row flex-stack">{["active", "won"].includes(_each) ? `${props.currency} ${each[_each]}` : each[_each]}</div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
}
