import React, { useEffect, useState } from "react";
import moment from "moment";
import { CustomSubHeader } from "../../../components/subheader";
import { ContentWrapper2 } from "../../Wrapper";
import { ContentLoadingWithoutCard } from "../../../components/loading";

//styles
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import * as insightActions from "../../insights/actions";
import * as videoActions from "../store/actions/video.action";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import VideoCard from "../component/video-card";
import AuthorView from "../component/author-preview";

export function LearnDescription(props) {
  const [tabName, setTabName] = useState("");

  const { videoId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedVideoStore = useSelector((store) => store.Learn.selected);

  useEffect(() => {
    if (history.location.pathname) {
      const url = history.location.pathname;
      if (url.includes("company")) {
        setTabName("Company");
      } else {
        setTabName("Public");
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (videoId && tabName) {
      dispatch(videoActions.getSelectedVideoRequest({ type: tabName.toLowerCase(), id: videoId }));
    }
  }, [dispatch, videoId, tabName]);

  useEffect(() => {
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  const handleVideoCardClick = (data) => {
    history.push(`/learn/${tabName.toLowerCase()}/${data._id}`);
  };

  return (
    <ContentWrapper2
      subHeader={
        <CustomSubHeader>
          <div onClick={() => history.goBack()} className="d-flex align-items-center cursor-pointer">
            <i className="bi bi-arrow-left text-333"></i>
            <span className="ml-2"> Back</span>
          </div>
        </CustomSubHeader>
      }
    >
      {selectedVideoStore.loading ? (
        <ContentLoadingWithoutCard />
      ) : (
        <div className="video-detailed-wrapper">
          <div className="video-payer-wrapper">
            <div className="video-player-container">
              <iframe
                className="border-radius-18px"
                width="inherit"
                height="inherit"
                src={selectedVideoStore.data.url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
            <div className="video-title-wrapper">{selectedVideoStore.data.title}</div>
            <div className="video-owner-time-wrapper d-flex flex-stack align-items-center">
              <AuthorView name={selectedVideoStore.data.author || ""} />
              <div className="video-date">{moment(selectedVideoStore.data.createdAt).fromNow()}</div>
            </div>
            <div className="video-hr"></div>
            <div className="video-description" dangerouslySetInnerHTML={{ __html: selectedVideoStore.data.description || "" }}></div>
            {/* <div className="video-description"> {selectedVideoStore.data.description} </div> */}
          </div>
          {Array.isArray(selectedVideoStore.related) && selectedVideoStore.related.length > 0 && (
            <div className="related-wrapper">
              <div>
                <div className="d-flex gap-4 pb-6 align-items-center">
                  <span className="category-head-border"></span>
                  <span className="category-head-title">{"Related"}</span>
                </div>
                <Row xs={2} md={3} lg={4}>
                  {selectedVideoStore.related.map((each, index) => {
                    if (index < 8)
                      return (
                        <Col>
                          {" "}
                          <VideoCard data={each} key={index} type={"Public"} handleVideoCardClick={() => handleVideoCardClick(each)} />
                        </Col>
                      );
                    else return <div key={index}></div>;
                  })}
                </Row>
              </div>
            </div>
          )}
        </div>
      )}
    </ContentWrapper2>
  );
}
