import React from "react";
import _ from "lodash";
import moment from "moment";
import { Card, Col } from "react-bootstrap";
import { salesTargetAndForecastMapping } from "../constants";
import { DeleteIconButton, EditIconButton } from "../../../components/button/icon-action-buttons";

export default function STDetailsCard(props) {
  const getAssignee = (data) => {
    if (data.typeOfUser === "company") {
      return "Company";
    } else if (data.typeOfUser === "team") {
      const _assignee = props.teamOptions.find((each) => each.value === data.assignee);
      if (_assignee) return `${_assignee.label} (Team)`;
    } else if (data.typeOfUser === "user") {
      const _assignee = props.userOptions.find((each) => each.value === data.assignee);
      if (_assignee) return `${_assignee.label} (User)`;
    }
    return "NA";
  };
  const getDuration = (data) => {
    if (data.duration) {
      if (data.duration.type === "automatic") {
        return "Automatic";
      } else if (data.duration.start && data.duration.end) {
        return `${moment(data.duration.start).format("L")} - ${moment(data.duration.end).format("L")}`;
      }
    }
    return "NA";
  };

  const getModuleName = (data) => {
    if (data.module === "all") {
      return "Deal, Lead";
    } else if (data.module === "deal" && data.pipelineId) {
      const _find = props.pipelineOptions.find((each) => each.value === data.pipelineId);
      if (_find) return `Deal (${_find.label})`;
      return `Deal (All Pipelines)`;
    }
    return salesTargetAndForecastMapping[data.module];
  };

  if (_.isEmpty(props.selectedSalesTarget)) return <></>;
  return (
    <Card className="sales-target-deatils-wrapper mb-3">
      <Card.Header>
        <span>Sales Target Details</span>
        <div>
          <EditIconButton onClick={() => props.onEditClick(props.selectedSalesTarget)} />
          <DeleteIconButton onClick={() => props.onDeleteClick(props.selectedSalesTarget)} />
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-row flex-wrap">
        <Col xs={6}>
          <div>
            <strong className="mr-2">Assignee:</strong> {getAssignee(props.selectedSalesTarget)}
          </div>
          <div>
            <strong className="mr-2">Goal Type:</strong>
            {salesTargetAndForecastMapping[props.selectedSalesTarget.goalType]}
          </div>
          <div>
            <strong className="mr-2">Module:</strong>
            {getModuleName(props.selectedSalesTarget)}
          </div>
        </Col>
        <Col xs={6}>
          <div>
            <strong className="mr-2">Interval:</strong>
            {salesTargetAndForecastMapping[props.selectedSalesTarget.interval]}
          </div>
          <div>
            <strong className="mr-2">Expected Outcome:</strong>
            {props.selectedSalesTarget.target}
          </div>
          <div>
            <strong className="mr-2">Duration:</strong>
            {getDuration(props.selectedSalesTarget)}
          </div>
        </Col>
      </Card.Body>
    </Card>
  );
}
