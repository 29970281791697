import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import ProfileOverview from "../profile/profileSetting/overview";
import SigninPassword from "../profile/profileSetting/signin";
import LoginSession from "../profile/profileSetting/login-session";
import Notification from "../profile/profileSetting/notification";
import Referrals from "../profile/profileSetting/referrals";
import CompanyList from "../profile/platformSetting/company-list";
import CalendarSync from "../profile/platformSetting/calendar-sync";
import EmailSync from "../profile/platformSetting/email-sync";
import CalendarSyncCallback from "../profile/platformSetting/calendar-sync-callback";
import EmailSyncCallback from "../profile/platformSetting/email-sync-callback";
import Integrations from "../profile/platformSetting/integrations";
import IntegrationCallback from "../profile/platformSetting/integrations-callback";
import ContentWrapper from "../../Wrapper";
import { ProfileSettingRouteConstants } from "../constants";
import Schedular from "./schedular/routes";
import SyncSettings from "./sync-settings";
import SettingsSubHeader from "../components/settings-sub-header";

export default function ProfileSettings() {
  return (
    <ContentWrapper subHeader={<SettingsSubHeader tabName={"profile-settings"} />}>
      <div className="d-flex flex-row h-vh-170px">
        <div className="sticky-sidebar-2 flex-row-auto offcanvas-mobile w-250px w-xxl-350px">
          <div className="card card-custom card-stretch h-vh-160px">
            <div className="card-body pt-4">
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                {ProfileSettingRouteConstants.map((each, index) => {
                  return (
                    <div className="navi-item mb-2" key={index}>
                      <NavLink to={each.path} className="navi-link py-4" activeClassName="active">
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <i className={each.icon}></i>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">{each.Name}</span>
                      </NavLink>
                    </div>
                  );
                })}
                <div className="navi-item mb-2">
                  <OverlayTrigger placement="right" overlay={<Tooltip>Coming soon...</Tooltip>}>
                    <div className="navi-link py-4">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <i className="bi bi-bell-fill"></i>{" "}
                        </span>
                      </span>
                      <span className="navi-text">Notification</span>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ************************************************************************* */}

        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Redirect from="/settings/profile" exact={true} to="/settings/profile/profile-overview" />
            <Route path="/settings/profile/profile-overview" component={ProfileOverview} />
            <Route path="/settings/profile/signin" component={SigninPassword} />
            <Route path="/settings/profile/login-session" component={LoginSession} />
            <Route path="/settings/profile/notification" component={Notification} />
            <Route path="/settings/profile/referrals" component={Referrals} />
            <Route path="/settings/profile/company-list" component={CompanyList} />
            <Route path="/settings/profile/email-sync" component={EmailSync} exact />
            <Route path="/settings/profile/calendar-sync" component={CalendarSync} exact={true} />
            <Route path="/settings/profile/calendar-sync/oauth2callback" component={CalendarSyncCallback} />
            <Route path="/settings/profile/email-sync/oauth2callback" component={EmailSyncCallback} />
            <Route path="/settings/profile/integrations/callback" component={IntegrationCallback} />
            <Route path="/settings/profile/integrations" component={Integrations} />
            <Route path="/settings/profile/scheduler" component={Schedular} />

            <Route path="/profile-settings/calendar-sync/oauth2callback" component={CalendarSyncCallback} />
            <Route path="/profile-settings/email-sync/oauth2callback" component={EmailSyncCallback} />

            {/*  v2 */}
            <Route path="/settings/profile/sync-settings" component={SyncSettings} />
          </Switch>
        </div>
      </div>
    </ContentWrapper>
  );
}
