import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Draggable } from "react-beautiful-dnd";
import { Accordion, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

//components
import ProductSelect from "../../../../components/custom_form/product";
import { CustomScrollableModal } from "../../../modal";

//actions
import { updateDealpopupDetailsRequest } from "../../../../containers/deals/dealPipeline/actions";

//constants & Utils
import { InputLimit, LimitInputNumber2 } from "../../../../constants";
import { dispatchSnackbarError } from "../../../../utils/toaster";

//styles
import "./styles.scss";
import InlineStyled from "../../../inline-styled/inline-styled";

export function DealProductDetails(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [openAddProductModal, setopenAddProductModal] = useState(false);
  const [editOptionClicked, setEditOptionClicked] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [newItemName, setNewItemName] = useState("");
  const [newQuantity, setNewQuantity] = useState(0);
  const [newUnitPrice, setNewUnitPrice] = useState(0);
  const [updatedProductList, setUpdatedProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState();
  const [currency, setCurrency] = useState("");
  // const [listOfCurrencies, setListOfCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState("");

  const dispatch = useDispatch();

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const dealLoadingStore = useSelector((state) => state.DealPipeline.loadingData);
  const dealListloadingStore = useSelector((state) => state.DealList.loadingData);

  useEffect(() => {
    if (props.sidePanelProps.products) {
      setUpdatedProductList(props.sidePanelProps.products);
    }
  }, [props.sidePanelProps.products]);

  useEffect(() => {
    if (props.sidePanelProps.deal && props.sidePanelProps.deal.defaultCurrency) {
      setDefaultCurrency(props.sidePanelProps.deal.defaultCurrency);
    }
  }, [props.sidePanelProps.deal]);

  const onRemoveProduct = (index) => {
    let tempArray = updatedProductList;
    tempArray.splice(index, 1);

    dispatch(
      updateDealpopupDetailsRequest({
        data: {
          id: props.dealId,
          type: "deal",
          products: tempArray,
        },
        dealId: props.dealId,
        filter: props.pageForm === "dealList" ? dealListloadingStore : dealLoadingStore,
        pageForm: props.pageForm,
        cellId: "",
      }),
    );
    setUpdatedProductList([...tempArray]);
  };
  const onEditProductsIconClicked = (product, index) => {
    setopenAddProductModal(true);
    setEditOptionClicked(true);
    setSelectedProductIndex(index);
    setNewItemName(product.itemName);
    setNewQuantity(product.quantity);
    setNewUnitPrice(product.unitPrice);
  };

  const onProductItemEdit = (e, index, product) => {
    if (e.target.name === "quantity") {
      setNewQuantity(e.target.value);
    } else if (e.target.name === "unitPrice") {
      setNewUnitPrice(e.target.value);
    } else {
      setNewItemName(e.target.value);
    }
  };

  const onProductItemUpdate = (e, index) => {
    let tempArray = updatedProductList;
    tempArray[selectedProductIndex][e.target.name] = e.target.value;
    if (e.target.name === "itemName") {
      setNewItemName(e.target.value ? e.target.value : newItemName);
    }
    if (e.target.name === "quantity") {
      tempArray[selectedProductIndex].amount = e.target.value * tempArray[selectedProductIndex].unitPrice;
      setNewQuantity(e.target.value);
    } else if (e.target.name === "unitPrice") {
      tempArray[selectedProductIndex].amount = e.target.value * tempArray[selectedProductIndex].quantity;
      setNewUnitPrice(e.target.value);
    }
    setUpdatedProductList([...tempArray]);
  };

  const onAddProductClick = (type) => {
    if (type === "add") {
      if (!newItemName) {
        dispatchSnackbarError("Please select the product name", "error");
      } else if (!newQuantity) {
        dispatchSnackbarError("Please enter the quantity", "error");
      } else if (!newUnitPrice && newUnitPrice !== 0) {
        dispatchSnackbarError("Please enter unit price", "error");
      } else {
        let tempArray = updatedProductList;
        tempArray.push({
          productId: selectedProductId,
          itemName: newItemName,
          quantity: newQuantity,
          unitPrice: newUnitPrice,
          amount: newQuantity * newUnitPrice,
          currency: currency || defaultCurrency,
        });
        dispatch(
          updateDealpopupDetailsRequest({
            data: {
              id: props.dealId,
              type: "deal",
              products: tempArray,
            },
            dealId: props.dealId,
            pageForm: props.pageForm,
            filter: props.pageForm === "dealList" ? dealListloadingStore : dealLoadingStore,
            cellId: "",
          }),
        );
        setNewItemName("");
        setNewQuantity();
        setNewUnitPrice();
        setopenAddProductModal(false);
        setEditOptionClicked(false);
      }
    } else {
      dispatch(
        updateDealpopupDetailsRequest({
          data: {
            id: props.dealId,
            type: "deal",
            products: updatedProductList,
          },
          dealId: props.dealId,
          pageForm: props.pageForm,
          filter: props.pageForm === "dealList" ? dealListloadingStore : dealLoadingStore,
          cellId: "",
        }),
      );
      setNewItemName("");
      setNewQuantity();
      setNewUnitPrice();
      setopenAddProductModal(false);
      setEditOptionClicked(false);
    }
  };

  const onEditProductItemUpdateSearch = (id, productInfo) => {
    let temp = updatedProductList;
    if (productInfo) {
      temp[selectedProductIndex].itemName = productInfo.productName;
      temp[selectedProductIndex].quantity = newQuantity;
      temp[selectedProductIndex].unitPrice = 0;
      temp[selectedProductIndex].amount = 0;
      temp[selectedProductIndex].currency = defaultCurrency;
      setNewUnitPrice(0);

      if (_.isArray(productInfo.pricing) && !_.isEmpty(productInfo.pricing) && props.sidePanelProps.deal && props.sidePanelProps.deal.dealCurrency) {
        const find = productInfo.pricing.find((each) => each.currency === props.sidePanelProps.deal.dealCurrency);
        if (find) {
          temp[selectedProductIndex].unitPrice = find.unitPrice;
          temp[selectedProductIndex].currency = find.currency;
          temp[selectedProductIndex].amount = find.unitPrice * newQuantity;
          setCurrency(find.currency);
          setNewUnitPrice(find.unitPrice);
        }
      }
      setNewItemName(productInfo.productName);
    }
    setUpdatedProductList(temp);
    setSelectedProductId(id);
  };

  const onProductItemEditFromSearch = (id, productInfo) => {
    if (productInfo) {
      setNewItemName(productInfo.productName);
      setNewQuantity(1);

      if (_.isArray(productInfo.pricing) && !_.isEmpty(productInfo.pricing) && props.sidePanelProps.deal && props.sidePanelProps.deal.dealCurrency) {
        const find = productInfo.pricing.find((each) => each.currency === props.sidePanelProps.deal.dealCurrency);
        if (find) {
          setNewUnitPrice(find.unitPrice);
          setCurrency(find.currency);
        } else {
          setCurrency("");
          setNewUnitPrice(0);
        }
      } else {
        setCurrency("");
        setNewUnitPrice(0);
      }
    }
    setSelectedProductId(id);
  };

  return (
    <div className="deal-product-details-wrapper" key={props.index}>
      <Draggable draggableId={"deal-product-details"} index={props.index}>
        {(provided, snapshot) => (
          <Accordion
            as={Card}
            defaultActiveKey={isOpen ? "toggle-company" : null}
            onSelect={() => setIsOpen(!isOpen)}
            className={`mb-4 card-dashed1 ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style }}
          >
            <Accordion.Toggle eventKey={"toggle-company"} className="cursor-pointer p-14px bg-white h-0px-mn" as={Card.Header}>
              <div className="d-flex flex-stack w-100">
                <div className="d-flex align-items-center gap-2">
                  <i className="bi bi-basket side-pannel-card-header-color"></i>
                  <span className="fs-6 m-0 side-pannel-card-header-color">Product</span>
                  <i className={`side-panel-data-icon fas fa-sm fa-chevron-${isOpen ? "up" : "down"} ml-2 side-pannel-card-header-color`}></i>
                </div>
                <div className="d-flex gap-4 align-items-center">
                  {accountBillingStore.data.servicesOffered && accountBillingStore.data.servicesOffered.PRODUCT_CATALOG && (
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        setopenAddProductModal(true);
                      }}
                      className="bi bi-plus-circle cursor-pointer"
                    />
                  )}
                  <i onClick={(e) => e.stopPropagation()} className="fa fa-bars fa-md" aria-hidden="true" {...provided.dragHandleProps}></i>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"toggle-company"}>
              <InlineStyled as={Card.Body} onClick={(e) => e.stopPropagation()} styles={{ padding: "1rem" }}>
                {accountBillingStore.data.servicesOffered && accountBillingStore.data.servicesOffered.PRODUCT_CATALOG ? (
                  Array.isArray(props.sidePanelProps.products) && props.sidePanelProps.products.length > 0 ? (
                    <table className="table p-0 m-0">
                      <thead>
                        <tr>
                          <th scope="col">Product Name</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.sidePanelProps.products.map((product, ind) => {
                          return (
                            <tr className="product-list-wrapper" key={ind}>
                              <td>{product.itemName}</td>
                              <td>
                                {" "}
                                {product.currency || defaultCurrency} {product.unitPrice} x {product.quantity} = {product.amount}
                              </td>
                              <span className="d-flex align-items-center product-list-actions">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onEditProductsIconClicked(product, ind);
                                  }}
                                  className="btn btn-icon btn-light h-25px w-25px"
                                >
                                  <i className="fa fa-sm fa-pen font-size-13px"></i>
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onRemoveProduct(ind);
                                  }}
                                  className="btn btn-icon btn-light ml-8px h-25px w-25px"
                                >
                                  <i className="bi bi-trash font-size-13px text-e34e6c"></i>
                                </button>
                              </span>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div data-kt-search-element="empty" className="text-center">
                      <div className="pt-10 pb-10">
                        <span className="svg-icon svg-icon-4x opacity-50">
                          <i className="bi bi-basket font-size-30px"></i>
                        </span>
                      </div>
                      <div className="pb-15 fw-bold">
                        <h3 className="text-gray-600 fs-5 mb-2">There are no products linked.</h3>
                        <div className="text-muted fs-7">All products associated with the current deal will be displayed here.</div>
                      </div>
                    </div>
                  )
                ) : (
                  <div data-kt-search-element="empty" className="text-center">
                    <div className="pt-10 pb-10">
                      <span className="svg-icon svg-icon-4x opacity-50">
                        <i className="bi bi-basket font-size-30px"></i>
                      </span>
                    </div>
                    <div className="pb-15 fw-bold">
                      <h3 className="text-gray-600 fs-5 mb-2">Products</h3>
                      <div className="text-muted fs-7">
                        To avail this feature you need to <a href="/settings/billing/subscription">UPGRADE</a> your plan.
                      </div>
                    </div>
                  </div>
                )}
              </InlineStyled>
            </Accordion.Collapse>
          </Accordion>
        )}
      </Draggable>

      <CustomScrollableModal
        modalProps={{
          show: openAddProductModal,
          handleClose: (e) => {
            setopenAddProductModal(false);
            setEditOptionClicked(false);
            setNewQuantity();
            setNewItemName("");
            setNewUnitPrice();
            setDefaultCurrency("");
            setCurrency("");
          },
          title: editOptionClicked ? "Edit Product" : "Add Product",
          showButtons: true,
          buttonText: editOptionClicked ? "Edit Product" : "Add Product",
          handleAccept: (e) => {
            e.stopPropagation();
            onAddProductClick(editOptionClicked ? "update" : "add");
          },
        }}
      >
        <Form>
          <div className="mb-8px">
            <Form.Label aria-label="product-name">Product Name</Form.Label>

            <ProductSelect
              field={{ fieldName: "Product" }}
              value={selectedProductId}
              label={newItemName}
              setValue={(id, productInfo) => (editOptionClicked ? onEditProductItemUpdateSearch(id, productInfo) : onProductItemEditFromSearch(id, productInfo))}
            />
          </div>
          <div className="mb-8px">
            <Form.Label aria-label="quantity">Quantity</Form.Label>
            <Form.Control
              maxLength={InputLimit.NUMBER}
              type="number"
              name="quantity"
              value={newQuantity}
              onChange={(e) => (editOptionClicked ? onProductItemUpdate(LimitInputNumber2(e)) : onProductItemEdit(LimitInputNumber2(e)))}
            />
          </div>
          <div className="mb-8px">
            <Form.Label aria-label="unit-price">Unit Price {currency ? `(${currency})` : defaultCurrency ? `(${defaultCurrency})` : ""}</Form.Label>
            <Form.Control
              maxLength={InputLimit.NUMBER}
              type="number"
              name="unitPrice"
              value={newUnitPrice}
              onChange={(e) => (editOptionClicked ? onProductItemUpdate(LimitInputNumber2(e)) : onProductItemEdit(LimitInputNumber2(e)))}
            />
          </div>
          <div className="mb-8px">
            <Form.Label aria-label="amount">Amount</Form.Label>
            <Form.Control
              maxLength={InputLimit.NUMBER}
              type="number"
              disabled
              name="amount"
              value={newQuantity * newUnitPrice}
              onChange={(e) => (editOptionClicked ? onProductItemUpdate(LimitInputNumber2(e)) : onProductItemEdit(LimitInputNumber2(e)))}
            />
          </div>
        </Form>
      </CustomScrollableModal>
    </div>
  );
}
