import * as walletTypes from "../types/wallet_billing_types";

const initialState = {
  info: {
    loading: false,
    data: {},
  },
};

export default function walletReducer(state = initialState, action = {}) {
  switch (action.type) {
    case walletTypes.getWalletInfo.GET_WALLET_INFO_REQUEST:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      };
    case walletTypes.getWalletInfo.GET_WALLET_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          data: action.payload.data,
        },
      };
    case walletTypes.getWalletInfo.GET_WALLET_INFO_ERROR:
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
        },
      };

    case walletTypes.updateWalletNotify.UPDATE_WALLET_NOTIFY_REQUEST:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      };
    case walletTypes.updateWalletNotify.UPDATE_WALLET_NOTIFY_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
          data: action.payload.data,
        },
      };
    case walletTypes.updateWalletNotify.UPDATE_WALLET_NOTIFY_ERROR:
      return {
        ...state,
        info: {
          ...state.info,
          loading: false,
        },
      };
    case walletTypes.createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_REQUEST:
    case walletTypes.createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_SUCCESS:
    case walletTypes.createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_ERROR:
      return state;

    default:
      return state;
  }
}
