import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import NormalTable from "../../../../components/normal-table/index";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/settings.actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { Dropdown, Form } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import Pagination from "../../../../components/normal-table/pagination";
import { InputLimit } from "../../../../constants";
import * as ProductActions from "../../../products/store/actions/actions";
import * as CompanySettingsActions from "../../store/actions/admin-actions/company-settings.actions";

export default function Currency() {
  const dispatch = useDispatch();
  const [currentName, setCurrentName] = useState("All");
  const [loader, setLoader] = useState(true);
  const [companyCurrency, setCompanyCurrency] = useState("");

  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [searchText, setSearchTexT] = useState("");

  const companyStore = useSelector((store) => store.settings.admin.company);

  useEffect(() => {
    dispatch(CompanySettingsActions.getCompanySettingsRequest());
    dispatch(
      actions.getCurrencyRequest((data) => {
        setLoader(false);
        setComments(data.data);
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(companyStore.data)) {
      setCompanyCurrency(companyStore.data.currency);
    }
  }, [companyStore.data]);

  const All = (name, id) => {
    setCurrentName(name);
  };

  const Activate = (name, id) => {
    setCurrentName(name);
    setCurrentPage(1);
    let a = ["active", "deactive"];

    let b = a.filter((res) => res !== id);

    for (let i = 0; i < b.length; i++) {
      var element = document.getElementById(b[i]);
      element.classList.remove("active");
    }

    var v = document.getElementById(id);
    v.className += " active";
  };

  const Deactivate = (name, id) => {
    setCurrentName(name);
    setCurrentPage(1);
    let a = ["active", "deactive"];

    let b = a.filter((res) => res !== id);

    for (let i = 0; i < b.length; i++) {
      var element = document.getElementById(b[i]);
      element.classList.remove("active");
    }

    var v = document.getElementById(id);
    v.className += " active";
  };

  const updateCurrencyAPI = (data, value) => {
    if (data.code === companyCurrency && value) {
      return dispatchSnackbarError("Since it is the default currency, the current currency cannot be disabled. Please adjust the company's default currency settings to turn off the current currency.");
    }
    const patchObj = {
      name: data.name,
      symbol: data.symbol,
      decPoints: data.decPoints,
      code: data.code,
      deactivated: value,
    };
    dispatch(
      actions.patchCurrencyRequest(patchObj, data._id, (data) => {
        if (data["success"]) {
          dispatch(ProductActions.currencyRequest({ skipIfDataPresent: false }));
          if (value === true) {
            dispatchSnackbarSuccess("Currency Successfully Deactivated", "success");
            dispatch(
              actions.getCurrencyRequest((data) => {
                setComments(data.data);
              }),
            );
          } else {
            dispatchSnackbarSuccess("Currency Successfully Activated", "success");
            dispatch(
              actions.getCurrencyRequest((data) => {
                setComments(data.data);
              }),
            );
          }
        }
      }),
    );
  };

  const commentsData = useMemo(() => {
    if (currentName === "Deactivated") {
      let freshdata = [];
      comments.forEach((comment) => {
        if (comment.deactivated) {
          if (searchText && (comment.name.match(new RegExp(searchText, "i")) || comment.symbol.match(new RegExp(searchText, "i")) || comment.code.match(new RegExp(searchText, "i")))) {
            freshdata.push(comment);
          } else if (!searchText) freshdata.push(comment);
        }
      });
      setTotalItems(freshdata.length);
      return freshdata.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
    } else if (currentName === "Activated") {
      let freshdata = [];
      comments.forEach((comment) => {
        if (!comment.deactivated) {
          if (searchText && (comment.name.match(new RegExp(searchText, "i")) || comment.symbol.match(new RegExp(searchText, "i")) || comment.code.match(new RegExp(searchText, "i")))) {
            freshdata.push(comment);
          } else if (!searchText) freshdata.push(comment);
        }
      });
      setTotalItems(freshdata.length);
      return freshdata.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
    } else if (currentName === "All") {
      let freshdata = [];
      comments.forEach((comment) => {
        if (searchText && (comment.name.match(new RegExp(searchText, "i")) || comment.symbol.match(new RegExp(searchText, "i")) || comment.code.match(new RegExp(searchText, "i")))) {
          freshdata.push(comment);
        } else if (!searchText) freshdata.push(comment);
      });
      setTotalItems(freshdata.length);
      return freshdata.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
    }
  }, [comments, currentPage, currentName, searchText]);

  return (
    <>
      <NormalTable tableHeading={["Full Name", "Symbol", "Decimal Point", "Code", "Deactivate/Activate"]} tableTitle={"Currency"} buttonHead={<></>}>
        {loader === true && (
          <tr>
            <td colSpan="5" className="text-center">
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        )}

        {comments.length === 0 && loader === false && (
          <tr>
            <td colSpan="5" className="text-center">
              <h6>No Data To Show</h6>
            </td>
          </tr>
        )}

        <div data-kt-buttons="true" className="position-absolute right-2-percent top-10px">
          <div className="d-flex align-items-center">
            <div>
              <Form.Control maxLength={InputLimit.TEXT} placeholder={"Type here to search"} value={searchText} onChange={(e) => setSearchTexT(e.target.value)}></Form.Control>
            </div>
            <div className="ml-2">
              <Dropdown drop="down">
                <Dropdown.Toggle className="h-38px btn btn-sm font-weight-bolder dropdown-toggle btn-secondary">{currentName}</Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
                  <Dropdown.Item className="dropdown-hover" onClick={() => All("All", "all")} id="all">
                    All
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" onClick={() => Activate("Activated", "active")} id="active">
                    Activated
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" onClick={() => Deactivate("Deactivated", "deactive")} id="deactive">
                    Deactivated
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {commentsData.map((user, id) => {
          return (
            <tr key={id}>
              <td>{user.name}</td>
              <td>{user.symbol}</td>
              <td>{user.decPoints}</td>
              <td>{user.code}</td>
              <td className="w-1px">
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input className="form-check-input h-20px w-35px" type="checkbox" onChange={() => updateCurrencyAPI(user, !user.deactivated)} checked={!user.deactivated} name="ActDeact" />
                  <label className="form-check-label" htmlFor="favoriteSwitch"></label>
                </div>
              </td>
            </tr>
          );
        })}
      </NormalTable>
      <div className="position-absolute right-20px mt-minus-21px">
        <Pagination total={totalItems} itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
      </div>
    </>
  );
}
