export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const CHECKEMAIL_REQUEST = "CHECKEMAIL_REQUEST";
export const CHECKEMAIL_SUCCESS = "CHECKEMAIL_SUCCESS";
export const CHECKEMAIL_ERROR = "CHECKEMAIL_ERROR";

export const SIGNUPINFO_REQUEST = "SIGNUPINFO_REQUEST";
export const SIGNUPINFO_SUCCESS = "SIGNUPINFO_SUCCESS";
export const SIGNUPINFO_ERROR = "SIGNUPINFO_ERROR";

export const EMAILOTP_REQUEST = "EMAILOTP_REQUEST";
export const EMAILOTP_SUCCESS = "EMAILOTP_SUCCESS";
export const EMAILOTP_ERROR = "EMAILOTP_ERROR";

export const PATCHPHONE_REQUEST = "PATCHPHONE_REQUEST";
export const PATCHPHONE_SUCCESS = "PATCHPHONE_SUCCESS";
export const PATCHPHONE_ERROR = "PATCHPHONE_ERROR";

export const UPDATEACCOUNT_REQUEST = "UPDATEACCOUNT_REQUEST";
export const UPDATEACCOUNT_SUCCESS = "UPDATEACCOUNT_SUCCESS";
export const UPDATEACCOUNT_ERROR = "UPDATEACCOUNT_ERROR";

export const GETGOOGLEAPI_REQUEST = "GETGOOGLEAPI_REQUEST";
export const GETGOOGLEAPI_SUCCESS = "GETGOOGLEAPI_SUCCESS";
export const GETGOOGLEAPI_ERROR = "GETGOOGLEAPI_ERROR";

export const POSTGOOGLEAPI_REQUEST = "POSTGOOGLEAPI_REQUEST";
export const POSTGOOGLEAPI_SUCCESS = "POSTGOOGLEAPI_SUCCESS";
export const POSTGOOGLEAPI_ERROR = "POSTGOOGLEAPI_ERROR";

export const VERIFYMANAGEUSER_REQUEST = "VERIFYMANAGEUSER_REQUEST";
export const VERIFYMANAGEUSER_SUCCESS = "VERIFYMANAGEUSER_SUCCESS";
export const VERIFYMANAGEUSER_ERROR = "VERIFYMANAGEUSER_ERROR";

export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_ERROR = "FORGOTPASSWORD_ERROR";

export const FORGOTPASSWORDPOST_REQUEST = "FORGOTPASSWORDPOST_REQUEST";
export const FORGOTPASSWORDPOST_SUCCESS = "FORGOTPASSWORDPOST_SUCCESS";
export const FORGOTPASSWORDPOST_ERROR = "FORGOTPASSWORDPOST_ERROR";

export const VERIFY_COUPONCODE_REQUEST = "VERIFY_COUPONCODE_REQUEST";
export const VERIFY_COUPONCODE_SUCCESS = "VERIFY_COUPONCODE_SUCCESS";
export const VERIFY_COUPONCODE_ERROR = "VERIFY_COUPONCODE_ERROR";

export const verifyPreAccount = {
  VERIFY_PRE_ACCOUNT_REQUEST: "VERIFY_PRE_ACCOUNT_REQUEST",
  VERIFY_PRE_ACCOUNT_SUCCESS: "VERIFY_PRE_ACCOUNT_SUCCESS",
  VERIFY_PRE_ACCOUNT_ERROR: "VERIFY_PRE_ACCOUNT_ERROR",
};

export const createAccountV2 = {
  CREATE_ACCOUNT_V2_REQUEST: "CREATE_ACCOUNT_V2_REQUEST",
  CREATE_ACCOUNT_V2_SUCCESS: "CREATE_ACCOUNT_V2_SUCCESS",
  CREATE_ACCOUNT_V2_ERROR: "CREATE_ACCOUNT_V2_ERROR",
};

export const getAccountCreatedStatus = {
  GET_ACCOUNT_CREATED_STATUS_REQUEST: "GET_ACCOUNT_CREATED_STATUS_REQUEST",
  GET_ACCOUNT_CREATED_STATUS_SUCCESS: "GET_ACCOUNT_CREATED_STATUS_SUCCESS",
  GET_ACCOUNT_CREATED_STATUS_ERROR: "GET_ACCOUNT_CREATED_STATUS_ERROR",
};
