import React from "react";
import { dispatchSnackbarError } from "../../../../utils/toaster";

export default function Users({ emailField, setEmailField }) {
  const onEmailEnter = (enteredEmails, currentValue) => {
    if (enteredEmails.split(",").length <= 100) setEmailField(currentValue);
    else {
      dispatchSnackbarError("Morethan 100 emails are not allowed", "error");
    }
  };

  return (
    <>
      <div className="overflow-auto m-auto mt-10px h-375px w-85">
        <div className="d-flex justify-content-between">
          <span className="fw-500 font-size-16px">Enter The Email Addresses of your Employees. They will be Sent Emails to Join Sales Simplify:</span>
        </div>
        <br></br>
        <textarea
          row="5"
          placeholder="emp1@example.com,  emp2@example.com,  emp3@example.com"
          value={emailField}
          name="emailVal"
          // onChange={(e) => setEmailField(e.target.value)}
          onChange={(e) => onEmailEnter(emailField, e.target.value)}
          className="h-290px form-control form-control-lg form-control-solid"
          required
        />
      </div>
    </>
  );
}
