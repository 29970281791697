import React from "react";
import { BlueGradientButton } from "../../../components/button";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import { CustomSubHeader } from "../../../components/subheader";
import { SubheaderNav } from "../../../components/subheader/subheaderNav";
import ContentWrapper from "../../Wrapper";

export default function CouponCodeList(props) {
  // const [show, setShow] = useState(false);

  const links = [
    { name: "Admin Settings", to: "/settings/account" },
    { name: "Billing", to: "/settings/billing/subscription" },
    { name: "Coupon Code", to: "/settings/coupon-code" },
  ];
  const tabName = "Coupon Code";

  const handleReedemCodeClick = () => {
    // setShow(true);
  };
  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex flex-stack w-100">
            <SubheaderNav links={links} active={tabName} />

            <div>
              <BlueGradientButton size="sm" onClick={() => handleReedemCodeClick()}>
                Redeem Coupon
              </BlueGradientButton>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      <CustomEmptyPage page="couponCode" setShowAdd={() => handleReedemCodeClick()} />
    </ContentWrapper>
  );
}
