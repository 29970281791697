import { editCadenceSettings, getCadenceSettings, getCadenceAllUsersLimit } from "../types/cadence_settings_types";

export const getCadenceSettingsRequest = (payload, cb) => ({
  type: getCadenceSettings.GET_CADENCE_SETTINGS_REQUEST,
  payload,
  cb,
});
export const getCadenceSettingsSuccess = (payload) => ({
  type: getCadenceSettings.GET_CADENCE_SETTINGS_SUCCESS,
  payload,
});
export const getCadenceSettingsError = (payload) => ({
  type: getCadenceSettings.GET_CADENCE_SETTINGS_ERROR,
  payload,
});

export const editCadenceSettingsRequest = (payload, cb) => ({
  type: editCadenceSettings.EDIT_CADENCE_SETTINGS_REQUEST,
  payload,
  cb,
});
export const editCadenceSettingsSuccess = (payload) => ({
  type: editCadenceSettings.EDIT_CADENCE_SETTINGS_SUCCESS,
  payload,
});
export const editCadenceSettingsError = (payload) => ({
  type: editCadenceSettings.EDIT_CADENCE_SETTINGS_ERROR,
  payload,
});

export const getCadenceAllUsersLimitRequest = (payload, cb) => ({
  type: getCadenceAllUsersLimit.REQUEST,
  payload,
  cb,
});
export const getCadenceAllUsersLimitSuccess = (payload) => ({
  type: getCadenceAllUsersLimit.SUCCESS,
  payload,
});
export const getCadenceAllUsersLimitFailure = (payload) => ({
  type: getCadenceAllUsersLimit.FAILURE,
  payload,
});
