export const getEmailCampaignSummaryReport = {
  GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_REQUEST: "GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_REQUEST",
  GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_SUCCESS: "GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_SUCCESS",
  GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_ERROR: "GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_ERROR",
};

export const getEmailCampaignAnalytics = {
  GET_EMAIL_CAMPAIGN_ANALYTICS_REQUEST: "GET_EMAIL_CAMPAIGN_ANALYTICS_REQUEST",
  GET_EMAIL_CAMPAIGN_ANALYTICS_SUCCESS: "GET_EMAIL_CAMPAIGN_ANALYTICS_SUCCESS",
  GET_EMAIL_CAMPAIGN_ANALYTICS_ERROR: "GET_EMAIL_CAMPAIGN_ANALYTICS_ERROR",
};

export const getEmailCampaignDetailedReport = {
  GET_EMAIL_CAMPAIGN_DETAILED_REPORT_REQUEST: "GET_EMAIL_CAMPAIGN_DETAILED_REPORT_REQUEST",
  GET_EMAIL_CAMPAIGN_DETAILED_REPORT_SUCCESS: "GET_EMAIL_CAMPAIGN_DETAILED_REPORT_SUCCESS",
  GET_EMAIL_CAMPAIGN_DETAILED_REPORT_ERROR: "GET_EMAIL_CAMPAIGN_DETAILED_REPORT_ERROR",
};

// global
export const getCampaignReportLog = {
  GET_CAMPAIGN_REPORT_LOG_REQUEST: "GET_CAMPAIGN_REPORT_LOG_REQUEST",
  GET_CAMPAIGN_REPORT_LOG_SUCCESS: "GET_CAMPAIGN_REPORT_LOG_SUCCESS",
  GET_CAMPAIGN_REPORT_LOG_ERROR: "GET_CAMPAIGN_REPORT_LOG_ERROR",
};
export const getListOfDownloadReports = {
  GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST: "GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST",
  GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST_AFTER: "GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST_AFTER",
  GET_LIST_OF_DOWNLOAD_REPORTS_SUCCESS: "GET_LIST_OF_DOWNLOAD_REPORTS_SUCCESS",
  GET_LIST_OF_DOWNLOAD_REPORTS_ERROR: "GET_LIST_OF_DOWNLOAD_REPORTS_ERROR",
};
export const downloadCampaignReports = {
  DOWNLOAD_CAMPAIGN_REPORTS_REQUEST: "DOWNLOAD_CAMPAIGN_REPORTS_REQUEST",
  DOWNLOAD_CAMPAIGN_REPORTS_SUCCESS: "DOWNLOAD_CAMPAIGN_REPORTS_SUCCESS",
  DOWNLOAD_CAMPAIGN_REPORTS_ERROR: "DOWNLOAD_CAMPAIGN_REPORTS_ERROR",
};
