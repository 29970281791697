export const EDIT_SYSTEM_FIELDS_REQUEST = "EDIT_SYSTEM_FIELDS_REQUEST";
export const EDIT_SYSTEM_FIELDS_SUCCESS = "EDIT_SYSTEM_FIELDS_SUCCESS";
export const EDIT_SYSTEM_FIELDS_ERROR = "EDIT_SYSTEM_FIELDS_ERROR";

export const GET_ADD_FORM_FIELDS_REQUEST = "GET_ADD_FORM_FIELDS_REQUEST";
export const GET_ADD_FORM_FIELDS_SUCCESS = "GET_ADD_FORM_FIELDS_SUCCESS";
export const GET_ADD_FORM_FIELDS_ERROR = "GET_ADD_FORM_FIELDS_ERROR";

export const REORDER_ADD_FORM_FIELDS_REQUEST = "REORDER_ADD_FORM_FIELDS_REQUEST";
export const REORDER_ADD_FORM_FIELDS_SUCCESS = "REORDER_ADD_FORM_FIELDS_SUCCESS";
export const REORDER_ADD_FORM_FIELDS_ERROR = "REORDER_ADD_FORM_FIELDS_ERROR";
