import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlankSidePanel from "../../../components/blank-side-pannel";
import DynamicJustTable from "../../../components/normal-table/dynamic-just-table";
import { BlueGradientButton, GreenGradientButton } from "../../../components/button";
import * as CategoryActions from "../store/actions/category.action";
import { dispatchSnackbarError } from "../../../utils/toaster";
import { DeleteModal } from "../../../components/modal";
import { Button, Form, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { InputLimit } from "../../../constants";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const AddCategoryForm = ({ categoryName, setCategoryName, handleClose, handleAccept }) => {
  return (
    <Form.Group>
      <Form.Group>
        <Form.Label>Category Name</Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type="text" value={categoryName} onChange={(e) => setCategoryName(e.target.value)}></Form.Control>
      </Form.Group>
      <Form.Group className="d-flex gap-4 flex-center">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          Close
        </Button>
        <GreenGradientButton size="sm" variant="primary" onClick={handleAccept}>
          Save
        </GreenGradientButton>
      </Form.Group>
    </Form.Group>
  );
};

export default function CategoryFilter(props) {
  const [showNextButton, setShowNextButton] = useState(false);
  const [showCategorySettings, setShowCategorySettings] = useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [deletableId, setDelatableId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const categoryStore = useSelector((store) => store.Learn.category);

  const tabRef = useRef();

  const scrollLeft = () => {
    tabRef.current.scrollLeft += 200;
  };

  useEffect(() => {
    if (tabRef.current && categoryStore.list.length > 0) {
      if (tabRef.current.clientWidth < tabRef.current.scrollWidth) {
        setShowNextButton(true);
      } else {
        setShowNextButton(false);
      }
    }
  }, [categoryStore.list]);

  const handleCategorySettings = () => {
    setShowCategorySettings(true);
  };

  const handleAddCategory = () => {
    setIsEdit(false);
    setCategoryName("");
    setCategoryId("");
    setShow(true);
  };

  const hanldeEdit = (data) => {
    setShow(true);
    setIsEdit(true);
    setCategoryName(data.name);
    setCategoryId(data._id);
  };
  const handleDelete = (data) => {
    if (data.videos > 0) {
      return dispatchSnackbarError("Category which link to more than 1 video cannot be deleted. Please delete videos before deleting category.");
    }
    setShowDeleteCategory(true);
    setDelatableId(data._id);
  };
  const handleDeleteCategoryAccept = () => {
    dispatch(
      CategoryActions.deleteVideoCategoryRequest({
        data: { id: deletableId },
        filter: { type: props.type.toLowerCase() },
      }),
    );
    setShowDeleteCategory(false);
    setDelatableId("");
  };

  const deleteModalProps = {
    show: showDeleteCategory,
    title: `Delete Category`,
    handleClose: () => {
      setShowDeleteCategory(false);
      setDelatableId("");
    },
    handleAccept: handleDeleteCategoryAccept,
  };

  const resetAddCategory = () => {
    setShow(false);
    setIsEdit(false);
    setCategoryName("");
    setCategoryId("");
    document.body.click();
  };

  const handleAccept = () => {
    if (isEdit) {
      dispatch(
        CategoryActions.editVideoCategoryRequest({
          id: categoryId,
          data: { name: categoryName },
          filter: { type: props.type.toLowerCase() },
        }),
      );
    } else {
      dispatch(
        CategoryActions.createVideoCategoryRequest({
          data: { name: categoryName },
          filter: { type: props.type.toLowerCase() },
        }),
      );
    }
    resetAddCategory();
  };

  const popoverForm = () => (
    <Popover className="w-300px">
      <Popover.Title className="d-flex flex-stack align-items-center" as="h3">
        <span>{isEdit ? "Edit Category" : "Add Category"}</span>
        <span title="close" className="cursor-pointer" onClick={() => resetAddCategory()}>
          <i className="bi bi-x color-black"></i>
        </span>
      </Popover.Title>
      <Popover.Content>
        <AddCategoryForm categoryName={categoryName} setCategoryName={setCategoryName} handleClose={resetAddCategory} handleAccept={handleAccept} />
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="category-wrapper">
      <div className="category-container" ref={tabRef}>
        <div onClick={() => props.handleCategoryClick("all", "All")} className={`category-btn  ${props.selectedCategoryId === "all" ? "selected" : ""} `}>
          <span className="category-text">All</span>
        </div>
        {categoryStore.list.map((each, index) => {
          return (
            <div onClick={() => props.handleCategoryClick(each._id, each.name)} className={`category-btn  ${props.selectedCategoryId === each._id ? "selected" : ""} `} key={index}>
              <span className="category-text">{each.name}</span>
            </div>
          );
        })}
        {props.type === "Company" && (
          <div onClick={() => handleCategorySettings()} className={`category-btn`}>
            <span className="category-text">
              <i className="bi bi-gear"></i>
            </span>
          </div>
        )}
      </div>
      {showNextButton && (
        <div className="next-button" onClick={scrollLeft}>
          <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.69995 6.3601L1.39995 12.6363L0.699951 11.9499L6.29995 6.45817L0.699951 0.966469L1.39995 0.280006L7.69995 6.3601Z" fill="black" />
          </svg>
        </div>
      )}
      <BlankSidePanel
        show={showCategorySettings}
        handleClose={() => setShowCategorySettings(false)}
        title="Category Settings"
        headButton={
          <div className="">
            <OverlayTrigger placement="bottom" overlay={popoverForm()} rootClose={true} onExit={() => {}} trigger="click" show={show} onToggle={() => setShow(!show)} id="popoverbutton">
              <BlueGradientButton size="sm" onClick={() => handleAddCategory()}>
                Add Category
              </BlueGradientButton>
            </OverlayTrigger>
          </div>
        }
      >
        <div>
          <div className="border">
            <DynamicJustTable tableHeading={["Category Name", "Video Count", "Actions"]}>
              {categoryStore.list.map((each, index) => (
                <tr key={index}>
                  <td>{each.name}</td>
                  <td align="center">{each.videos}</td>
                  <td align="center">
                    <div>
                      <OverlayTrigger placement="bottom" overlay={popoverForm()} rootClose={true} onExit={() => {}} trigger="click" show={show} onToggle={() => setShow(!show)} id={"popoverbutton" + index}>
                        <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={() => hanldeEdit(each)}>
                          <span className="svg-icon svg-icon-md svg-icon-primary pointer-events-none">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                          </span>
                        </span>
                      </OverlayTrigger>

                      <></>
                      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
                        <span className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={() => handleDelete(each)}>
                          <span className="svg-icon svg-icon-md svg-icon-danger pointer-events-none">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              ))}
            </DynamicJustTable>
          </div>
        </div>
      </BlankSidePanel>
      <DeleteModal modalProps={deleteModalProps}>Are you sure you want to delete the category?</DeleteModal>
    </div>
  );
}
