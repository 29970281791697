import React, { useState } from "react";
import { InputLimit } from "../../constants";

function FormInput(props) {
  const [inputType] = useState(props.type);
  const [placeValue] = useState(props.placeholder);
  const [name] = useState(props.name);
  const [disabled] = useState(props.disabled ? true : false);

  function handleTouch() {}

  if (["text", "password", "number", "email", "tel"].includes(inputType)) {
    return (
      <>
        <input
          maxLength={InputLimit[inputType.toUpperCase()]}
          type={inputType}
          placeholder={placeValue}
          value={props.value}
          name={name}
          onChange={props.onChange}
          className="form-control form-control-lg form-control-solid"
          onBlur={handleTouch}
          required
          disabled={disabled}
        />
      </>
    );
  } else {
    return (
      <>
        <input
          type={inputType}
          placeholder={placeValue}
          value={props.value}
          name={name}
          onChange={props.onChange}
          className="form-control form-control-lg form-control-solid"
          onBlur={handleTouch}
          required
          disabled={disabled}
        />
      </>
    );
  }
}

export default FormInput;
