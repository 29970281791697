import React from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { dispatchSnackbarError } from "../../utils/toaster";
import { InputLimit } from "../../constants";

export default function CustomEmail(props) {
  const handleAddEmail = () => {
    if (props.value.length === 0) {
      dispatchSnackbarError("Fill all the previous values before adding!");
      return;
    }
    for (var i = 0; i < props.value.length; i++) {
      if (props.value[i].trim() === "") {
        dispatchSnackbarError("Fill all the previous values before adding!");
        return;
      }
    }
    props.setValue([...props.value, ""], props.apiKeyName);
  };

  const handleDeleteEmail = (unitIndex) => {
    props.setValue(
      props.value.filter((unit, index) => index !== unitIndex),
      props.apiKeyName,
    );
  };

  return (
    <>
      {props.value && props.value.length > 0
        ? props.value.map((email, index) => {
            return (
              <InputGroup key={index} className="mb-2">
                <div className="d-flex w-100 gap-10px">
                  <div className="flex-grow-1">
                    <Form.Control
                      maxLength={InputLimit.EMAIL}
                      type="email"
                      value={email}
                      onChange={(e) => props.setValue([...props.value.slice(0, index), e.target.value, ...props.value.slice(index + 1)], props.apiKeyName)}
                    ></Form.Control>
                  </div>
                  {props.value.length > 0 && props.value[0] ? (
                    <InputGroup.Append>
                      <Button className="px-0" onClick={() => handleDeleteEmail(index)} size="sm" variant="transparent">
                        <i className="fa fa-minus-circle remove-condition-icon"></i>
                      </Button>
                    </InputGroup.Append>
                  ) : null}
                </div>
              </InputGroup>
            );
          })
        : null}

      <Button onClick={handleAddEmail} className="p-0 text-decoration-none" variant="link">
        + Add Email
      </Button>
    </>
  );
}
