import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Form, Dropdown, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InputLimit } from "../../../../../constants";
import CustomInput from "../../../../../components/custom_form/inputs";
import PipelineSelect, { PipelineStageSelect } from "../../../../../components/custom_form/pipeline";
import { pageMapping, optionsMapping } from "../constants";
import { GreenGradientButton } from "../../../../../components/button";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import * as UpdateFieldAction from "../../actions/update_field_action";

import * as companyAction from "../../../../companies/store/actions/actions";
import * as dealActions from "../../../../deals/dealPipeline/actions";
import * as PeopleActions from "../../../../customers/store/actions/actions";
import * as leadActions from "../../../../leads/store/actions/actions";

export default function FieldUpdateForm(props) {
  const [name, setName] = useState("");
  const [fieldModule, setFieldModule] = useState({});
  const [selectedField, setSelectedField] = useState({});
  const [inputValue, setInputValue] = useState(null);

  const [pipelineValue, setPipelineValue] = useState(null);
  const [pipelineStageValue, setPipelineStageValue] = useState(null);

  const [fieldSearchText, setFieldSearchText] = useState("");

  const [peopleDataField, setPeopleDataField] = useState([]);
  const [companyDataField, setCompanyDataField] = useState([]);
  const [leadDataField, setLeadDataField] = useState([]);
  const [dealDataField, setDealDataField] = useState([]);

  const [pipelineField, setPipelineField] = useState({});
  const [pipelineStageField, setPipelineStageField] = useState({});

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const peopleColumns = useSelector((state) => state.People.peopleFields);
  const companyColumns = useSelector((state) => state.Companies.companyFields);
  const leadColumns = useSelector((state) => state.Leads.leadFields);
  const dealColumns = useSelector((state) => state.DealList.dealFields);

  useEffect(() => {
    if (peopleColumns.list.length > 0) {
      const list = peopleColumns.list.filter((each) => each.isEditable);
      setPeopleDataField(list);
    }
  }, [peopleColumns.list]);

  useEffect(() => {
    if (companyColumns.list.length > 0) {
      const list = companyColumns.list.filter((each) => each.isEditable);
      setCompanyDataField(list);
    }
  }, [companyColumns.list]);

  useEffect(() => {
    if (leadColumns.list.length > 0) {
      const list = leadColumns.list.filter((each) => each.isEditable);
      setLeadDataField(list);
    }
  }, [leadColumns.list]);

  useEffect(() => {
    if (dealColumns.list.length > 0) {
      const list = dealColumns.list.filter((each) => {
        if (each.fieldInputType === "PIPELINE_SELECT") {
          setPipelineField(each);
          return true;
        }
        if (each.fieldInputType === "PIPELINE_STAGE_SELECT") {
          setPipelineStageField(each);
        }
        return each.fieldInputType !== "MULTI_OWNER_SELECT" && each.fieldInputType !== "DEAL_PRODUCT_INPUT" && each.isEditable;
      });
      setDealDataField(list);
    }
  }, [dealColumns.list]);

  const getFieldData = useCallback(
    (moduleName, id) => {
      if (moduleName === "lead") return leadDataField.find((each) => each._id === id);
      else if (moduleName === "person") return peopleDataField.find((each) => each._id === id);
      else if (moduleName === "company") return companyDataField.find((each) => each._id === id);
      else if (moduleName === "deal") return dealDataField.find((each) => each._id === id);
      return {};
    },
    [leadDataField, peopleDataField, companyDataField, dealDataField],
  );

  const getFieldOption = (moduleName) => {
    if (moduleName === "lead") return leadDataField;
    else if (moduleName === "person") return peopleDataField;
    else if (moduleName === "company") return companyDataField;
    else if (moduleName === "deal") return dealDataField;
    return [];
  };

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActionData) && props.selectedActionData.id) {
      setLoading(true);
      dispatch(
        UpdateFieldAction.getFieldUpdateByIdRequest({ id: props.selectedActionData.id }, (data) => {
          if (data.success) {
            setName(data.data.name);
            const updateData = data.data.updates[0];
            setFieldModule({ label: _.capitalize(updateData.moduleName), value: updateData.moduleName });
            const field = getFieldData(updateData.moduleName, updateData.field.id);
            setSelectedField(field);
            if (!_.isEmpty(field) && ["PIPELINE_SELECT", "PIPELINE_STAGE_SELECT"].includes(updateData.dataType)) {
              if (updateData.dataType === "PIPELINE_SELECT") {
                setPipelineStageValue(data.data.updates[1].value);
                setPipelineValue(updateData.value);
              } else {
                setPipelineStageValue(updateData.value);
                setPipelineValue(data.data.updates[1].value);
              }
            } else if (!_.isEmpty(field)) {
              setInputValue(updateData.value);
            }
          } else {
            dispatchSnackbarError(data.message);
          }
          setLoading(false);
        }),
      );
    }
  }, [dispatch, props.selectedActionData, props.isEdit, getFieldData]);

  const handleModuleChange = (value) => {
    setFieldModule(value);
    setSelectedField({});
    setInputValue(null);
    setPipelineValue(null);
    setPipelineStageValue(null);
  };

  const handleDataFieldSelect = (field) => {
    setSelectedField(field);
    setInputValue(null);
    setPipelineValue(null);
    setPipelineStageValue(null);
  };
  const onInputValueChange = (value) => {
    setInputValue(value);
  };

  const handleSave = () => {
    if (_.isEmpty(name)) {
      return dispatchSnackbarError("Update field name can't be empty!");
    } else if (_.isEmpty(fieldModule)) {
      return dispatchSnackbarError("Dield module can't be empty!");
    } else if (_.isEmpty(selectedField)) {
      return dispatchSnackbarError("Field can't be empty!");
    }
    if (["PIPELINE_SELECT", "PIPELINE_STAGE_SELECT"].includes(selectedField.fieldInputType)) {
      if (_.isEmpty(pipelineStageValue) || _.isEmpty(pipelineValue)) {
        return dispatchSnackbarError("Pipeline and Pipeline Stage can't be emapty!");
      }
    } else {
      if (_.isNumber(inputValue) && inputValue > 0) {
      } else if (_.isEmpty(inputValue)) {
        return dispatchSnackbarError("Input value can't be empty!");
      }
    }

    if (["PIPELINE_SELECT", "PIPELINE_STAGE_SELECT"].includes(selectedField.fieldInputType)) {
      const data = {
        name: name,
        module: { name: props.moduleName },
        updates: [
          {
            moduleName: fieldModule.value,
            dataType: pipelineField.fieldInputType,
            field: { id: pipelineField._id, name: pipelineField.fieldName },
            value: pipelineValue,
          },
          {
            moduleName: fieldModule.value,
            dataType: pipelineStageField.fieldInputType,
            field: { id: pipelineStageField._id, name: pipelineStageField.fieldName },
            value: pipelineStageValue,
          },
        ],
      };
      if (props.isEdit) props.handleEditSave(props.selectedActionData.id, data);
      else props.handleCreateSave(data);
    } else {
      const data = {
        name: name,
        module: { name: props.moduleName },
        updates: [
          {
            moduleName: fieldModule.value,
            dataType: selectedField.fieldInputType,
            field: { id: selectedField._id, name: selectedField.fieldName },
            value: inputValue,
          },
        ],
      };
      if (props.isEdit) props.handleEditSave(props.selectedActionData.id, data);
      else props.handleCreateSave(data);
    }
  };

  const addTag = (name, colorCode) => {
    if (pageMapping[fieldModule.value].pageSub === "lead") {
      dispatch(leadActions.leadTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    } else if (pageMapping[fieldModule.value].pageSub === "people") {
      dispatch(PeopleActions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    } else if (pageMapping[fieldModule.value].pageSub === "company") {
      dispatch(companyAction.companyTagsAddRequest({ name: name, colorCode: colorCode, type: "company" }));
    } else if (pageMapping[fieldModule.value].pageSub === "deal") {
      dispatch(dealActions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
    }
  };

  const editTag = (id, name, colorCode) => {
    if (pageMapping[fieldModule.value].pageSub === "lead") {
      dispatch(leadActions.leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (pageMapping[fieldModule.value].pageSub === "people") {
      dispatch(PeopleActions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (pageMapping[fieldModule.value].pageSub === "company") {
      dispatch(companyAction.companyTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (pageMapping[fieldModule.value].pageSub === "deal") {
      dispatch(dealActions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode }));
    }
  };

  const deleteTag = (id) => {
    if (pageMapping[fieldModule.value].pageSub === "lead") {
      dispatch(leadActions.leadTagsDeleteRequest(id));
    } else if (pageMapping[fieldModule.value].pageSub === "people") {
      dispatch(PeopleActions.peopleTagsDeleteRequest(id));
    } else if (pageMapping[fieldModule.value].pageSub === "company") {
      dispatch(companyAction.companyTagsDeleteRequest(id));
    } else if (pageMapping[fieldModule.value].pageSub === "deal") {
      dispatch(dealActions.deleteDealTagRequest(id));
    }
  };

  if (loading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center h-vh-162px">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <Form.Group id="automation-forms-field-update-form">
      <Form.Group id="automation-forms-field-update-form-name">
        <Form.Label aria-label="name">Name</Form.Label>
        <Form.Control max={InputLimit.TEXT} value={name} onChange={(e) => setName(e.target.value)} type={"text"} />
      </Form.Group>
      <Form.Group id="automation-forms-field-update-form-module">
        <Form.Label aria-label="module">Module</Form.Label>
        <SimpleDropdown value={fieldModule} label={"Select Module"} options={optionsMapping[props.moduleName]} handleSelect={(value, index) => handleModuleChange(value)} />
      </Form.Group>
      {!_.isEmpty(fieldModule) && (
        <Form.Group id="automation-forms-field-update-form-field-name">
          <Form.Label aria-label="field">Field</Form.Label>
          <Dropdown className="mt-0 w-100">
            <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
              {_.isEmpty(selectedField) ? "Select Field" : selectedField.fieldName}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100-mn">
              {peopleColumns.loading || companyColumns.loading || leadColumns.loading || dealColumns.loading ? (
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="navi navi-hover">
                  {
                    <li className="navi-item py-20px px-24px">
                      <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Field" value={fieldSearchText} onChange={(e) => setFieldSearchText(e.target.value)} />
                    </li>
                  }
                  <Dropdown.Divider className="m-0" />
                  <div className="navi navi-hover h-25vh-mx position-relative overflow-y-auto">
                    {getFieldOption(fieldModule.value).map((field, fieldIndex) => {
                      if (field.fieldName.toLowerCase().includes(fieldSearchText.toLowerCase()))
                        return (
                          <Dropdown.Item key={fieldIndex} className="dropdown-hover" onSelect={(e) => handleDataFieldSelect(field)}>
                            {field.fieldName}
                          </Dropdown.Item>
                        );
                      return <div key={fieldIndex}></div>;
                    })}
                  </div>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
      )}
      {!_.isEmpty(selectedField) && !_.isEmpty(fieldModule) && !["PIPELINE_SELECT", "PIPELINE_STAGE_SELECT"].includes(selectedField.fieldInputType) && (
        <Form.Group id="automation-forms-field-update-form-value">
          <Form.Label aria-label="value">Value</Form.Label>
          <CustomInput
            dontShowAdd={true}
            clearable={false}
            page={pageMapping[fieldModule.value].page}
            pageSub={pageMapping[fieldModule.value].pageSub}
            field={selectedField}
            value={inputValue}
            setValue={onInputValueChange}
            hideAddText={false}
            addTag={addTag}
            editTag={editTag}
            deleteTag={deleteTag}
          />
        </Form.Group>
      )}
      {!_.isEmpty(selectedField) && ["PIPELINE_SELECT", "PIPELINE_STAGE_SELECT"].includes(selectedField.fieldInputType) && (
        <Form.Group id="automation-forms-field-update-form-pipelines">
          <Form.Group id="automation-forms-field-update-form-select-pipeline">
            <Form.Label aria-label="select-pipeline">Select Pipeline</Form.Label>
            <PipelineSelect
              field={pipelineField}
              value={pipelineValue}
              setValue={(value) => {
                setPipelineValue(value);
                setPipelineStageValue(null);
              }}
            />
          </Form.Group>
          {pipelineValue && (
            <Form.Group id="automation-forms-field-update-form-select-pipeline-stage">
              <Form.Label aria-label="select-pipeline-stage">Select Pipeline Stage</Form.Label>
              <PipelineStageSelect field={pipelineStageField} value={pipelineStageValue} pipelineId={pipelineValue} setValue={setPipelineStageValue} />
            </Form.Group>
          )}
        </Form.Group>
      )}
      <Form.Group id="automation-forms-field-update-form-save-button" className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={() => handleSave()} disabled={!(inputValue || pipelineStageValue)}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </Form.Group>
  );
}
