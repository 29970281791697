import CampaignDeatilsSaga from "./campaign_details_saga";
import CampaignTemplatesSaga from "./campaign_templates_saga";
import MarketingDataFeildSaga from "./marketing_data_field_saga";
import CampaignMarketingSaga from "./campaign_marketing_list_saga";
import CampaignEmailSettingSaga from "./campaign_email_settings_saga";
import CampaignCommonSettingsSaga from "./campaign_common_settings_saga";
import CampaignReportSaga from "./campaign_report_saga";
import campaignSMSSettingsSaga from "./campaign_sms_settings_saga";
import campaignShortLinkSaga from "./campaign_short_link_saga";
import campaignBlockContactSaga from "./campaign_block_contact_saga";

// whatsapp
import campaignWhatsappMessaginSaga from "./whatsapp/campaign_whatsapp_messaging_saga";
import campaignWhatsappSettingsSaga from "./whatsapp/campaign_whatsapp_settings_saga";
import campaignWhatsappTemplateSaga from "./whatsapp/campaign_whatsapp_template_saga";

export default [
  CampaignDeatilsSaga,
  CampaignTemplatesSaga,
  MarketingDataFeildSaga,
  CampaignMarketingSaga,
  CampaignEmailSettingSaga,
  CampaignCommonSettingsSaga,
  CampaignReportSaga,
  campaignSMSSettingsSaga,
  campaignShortLinkSaga,
  campaignBlockContactSaga,
  campaignWhatsappMessaginSaga,
  campaignWhatsappSettingsSaga,
  campaignWhatsappTemplateSaga
];
