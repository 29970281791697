import { takeLatest, put, call, select } from "redux-saga/effects";
import * as peopleActions from "../actions/actions";
//import required action types below
import {
  PEOPLES_REQUEST,
  PEOPLEFIELDS_REQUEST,
  PEOPLETAGS_REQUEST,
  PEOPLETAGSADD_REQUEST,
  PEOPLETAGSEDIT_REQUEST,
  PEOPLETAGSDELETE_REQUEST,
  PEOPLECELLEDIT_REQUEST,
  PEOPLEADD_REQUEST,
  PEOPLEBULKEDIT_REQUEST,
  PEOPLEDELETE_REQUEST,
  PEOPLEFILTERS_REQUEST,
  PEOPLEFILTERADD_REQUEST,
  PEOPLEFILTEREDIT_REQUEST,
  PEOPLEFILTERDELETE_REQUEST,
  PEOPLEADDFIELDS_REQUEST,
  OWNERS_REQUEST,
  PEOPLEDROPDOWN_REQUEST,
  PEOPLEDROPDOWNADD_REQUEST,
  PEOPLES_UPDATE_REQUEST,
  PEOPLE_POPUP_REQUEST,
  PEOPLE_POPUP_EDIT_REQUEST,
  PEOPLE_POPUP_UPDATE_REQUEST,
  PEOPLEMERGE_REQUEST,
  PEOPLEFILTERFAVORITE_REQUEST,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_SHOWHIDE_REQUEST,
  PEOPLE_LEAD_DROPDOWN_REQUEST,
  CONVERT_PEOPLE_TO_LEAD_REQUEST,
  PEOPLE_POPUP_REQUEST_AFTER,
  generatePeopleAIWFData
} from "../types/types";
import api from "../../../../network/apis/api";
import { dispatchSnackbarSuccess } from "../../../../utils/toaster";

function* peoplesFunction(action) {
  try {
    var response;
    if (action.payload.filterType) {
      response = yield call(api.post, "/api/crm/saved-filters/apply", action.payload);
    } else {
      response = yield call(api.get, "/api/crm/people/get", action.payload);
    }
    if (response.status === 200) {
      yield put(peopleActions.peoplesSuccess(response.data));
    } else {
      yield put(peopleActions.peoplesError({ message: "Could Not Get peoples" }));
    }
  } catch (e) {
    yield put(peopleActions.peoplesError({ message: "Could Not Get peoples" }));
  }
}

function* fieldUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/get", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.fieldUpdateSuccess(response.data.data));
      yield put(peopleActions.peopleFieldsSuccess(response.data.data));
    } else {
      yield put(peopleActions.fieldUpdateError({ message: "Could Not Get people Fields" }));
    }
  } catch (e) {
    yield put(peopleActions.fieldUpdateError({ message: "Could Not Get people Fields" }));
  }
}

function* fieldShowHideFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.fieldUpdateRequest({ fieldType: "PERSON" }));
    } else {
      yield put(peopleActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(peopleActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* fieldUpdatePriorityFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.fieldUpdateRequest({ fieldType: "PERSON" }));
    } else {
      yield put(peopleActions.fieldUpdateError({ message: "Could Not Update Field" }));
    }
  } catch (e) {
    yield put(peopleActions.fieldUpdateError({ message: "Could Not Update Field" }));
  }
}

function* peoplePopupFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/people/get-selected-details", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peoplePopupSuccess(response.data));
    } else {
      yield put(peopleActions.peoplePopupError({ message: "Could Not Get People Data" }));
    }
  } catch (e) {
    yield put(peopleActions.peoplePopupError({ message: "Could Not Get People Data" }));
  }
}

function* peoplePopupEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/people/update-popup-details", action.payload.editData);
    if (response.status === 200) {
      yield put(peopleActions.peoplePopupEditSuccess({ message: "People Edited Successfully" }));
      yield put(peopleActions.peoplePopupUpdateRequest({ loadingData: { id: action.payload.loadingData }, cellId: action.payload.cellId }));
      yield put(peopleActions.peoplesUpdateRequest({ loadingData: action.payload.tableLoadingData }));
    } else if (response.status === 400) {
      yield put(peopleActions.peoplePopupEditError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peoplePopupEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(peopleActions.peoplePopupEditError({ message: "Could Not Edit" }));
  }
}

function* peoplePopupUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/people/get-selected-details", action.payload.loadingData);
    if (response.status === 200) {
      yield put(peopleActions.peoplePopupUpdateSuccess(response.data));
    } else {
      yield put(peopleActions.peoplePopupUpdateError({ message: "Could Not Get People Data" }));
    }
  } catch (e) {
    yield put(peopleActions.peoplePopupUpdateError({ message: "Could Not Get People Data" }));
  }
}

function* peoplesUpdateFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/people/get", action.payload.loadingData);
    if (response.status === 200) {
      yield put(peopleActions.peoplesUpdateSuccess(response.data));
    } else {
      yield put(peopleActions.peoplesUpdateError({ message: "Could Not Get peoples" }));
    }
  } catch (e) {
    yield put(peopleActions.peoplesUpdateError({ message: "Could Not Get peoples" }));
  }
}

function* peopleDropdownFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/people/search", { searchText: action.payload });
    if (response.status === 200) {
      yield put(peopleActions.peopleDropdownSuccess(response.data));
    } else {
      yield put(peopleActions.peopleDropdownError({ message: "Could Not Get people" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleDropdownError({ message: "Could Not Get people" }));
  }
}

function* peopleLeadDropdownFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/people/lead-person-search", { searchText: action.payload });
    if (response.status === 200) {
      yield put(peopleActions.peopleLeadDropdownSuccess(response.data));
    } else {
      yield put(peopleActions.peopleLeadDropdownError({ message: "Could Not Get people" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleLeadDropdownError({ message: "Could Not Get people" }));
  }
}

function* peopleDropdownAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/people/create", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleDropdownRequest({ message: response.data.message }));
    } else if (response.status === 400) {
      yield put(peopleActions.peopleDropdownAddError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peopleDropdownAddError({ message: "Could Not Add People" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleAddError({ status: false, message: "Could Not Add People" }));
  }
}

function* ownersFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/profile/all", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.ownersSuccess(response.data.data));
    } else {
      yield put(peopleActions.ownersError({ message: "Could Not Get Owners" }));
    }
  } catch (e) {
    yield put(peopleActions.ownersError({ message: "Could Not Get Owners" }));
  }
}

function* peopleFieldsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.People.peopleFields.list) && store.People.peopleFields.list.length > 0) {
      yield put(peopleActions.peopleFieldsSuccess(store.People.peopleFields.list));
    } else {
      const response = yield call(api.get, "/api/crm/data-fields/get", action.payload.request);
      if (response.status === 200) {
        yield put(peopleActions.peopleFieldsSuccess(response.data.data));
      } else {
        yield put(peopleActions.peopleFieldsError({ message: "Could Not Get People Fields" }));
      }
    }
  } catch (e) {
    yield put(peopleActions.peopleFieldsError({ message: "Could Not Get People Fields" }));
  }
}

function* peopleAddFieldsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/data-fields/add-form-fields", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleAddFieldsSuccess(response.data.data));
    } else {
      yield put(peopleActions.peopleAddFieldsError({ message: "Could Not Get People Fields" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleAddFieldsError({ message: "Could Not Get People Fields" }));
  }
}

function* peopleAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/people/create", action.payload.peopleData);
    if (response.status === 200) {
      yield put(peopleActions.peopleAddSuccess({ message: response.data.message }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(peopleActions.peopleAddError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peopleAddError({ message: "Could Not Add People" }));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(peopleActions.peopleAddError({ status: false, message: "Could Not Add People" }));
  }
}

function* peopleBulkEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/people/bulk-edit", action.payload.peopleData);
    if (response.status === 200) {
      yield put(peopleActions.peopleBulkEditSuccess({ message: "peoples Edited Successfully" }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(peopleActions.peopleBulkEditError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peopleBulkEditError({ message: "Could Not Edit peoples" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleBulkEditError({ message: "Could Not Edit peoples" }));
  }
}

function* peopleDeleteFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/people/delete", action.payload.peopleIds);
    if (response.status === 200) {
      yield put(peopleActions.peopleDeleteSuccess({ message: `${action.payload.peopleIds.ids.length > 1 ? "Peoples" : "People"} deleted successfully` }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(peopleActions.peopleDeleteError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peopleDeleteError({ message: "Could Not Delete peoples" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleDeleteError({ message: "Could Not Delete People" }));
  }
}

function* peopleTagsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.People.peopleTags.list) && store.People.peopleTags.list.length > 0) {
      yield put(peopleActions.peopleTagsSuccess(store.People.peopleTags.list));
    } else {
      const response = yield call(api.get, "/api/crm/tag/get", action.payload.request);
      if (response.status === 200) {
        yield put(peopleActions.peopleTagsSuccess(response.data.data));
      } else {
        yield put(peopleActions.peopleTagsError({ message: "Could Not Get People Tags" }));
      }
    }
  } catch (e) {
    yield put(peopleActions.peopleTagsError({ message: "Could Not Get People Tags" }));
  }
}

function* peopleTagsAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/tag/create", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleTagsAddSuccess({ message: "Tag Added Successfully" }));
      yield put(peopleActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: false }));
    } else {
      yield put(peopleActions.peopleTagsError({ message: "Could Not Add People Tag" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleTagsError({ status: false, message: "Could Not Add People Tag" }));
  }
}

function* peopleTagsEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/edit", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleTagsEditSuccess({ message: "Tag Edited Successfully" }));
      yield put(peopleActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: false }));
    } else {
      yield put(peopleActions.peopleTagsError({ message: "Could Not Edit Tag" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleTagsError({ message: "Could Not Edit Tag" }));
  }
}

function* peopleTagsDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/tag/delete/" + action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleTagsDeleteSuccess({ message: "Tag Deleted Successfully" }));
      yield put(peopleActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: false }));
    } else {
      yield put(peopleActions.peopleTagsError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleTagsError({ message: "Could Not Delete Tag" }));
  }
}

function* peopleCellEditFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/people/edit", action.payload.editData);
    if (response.status === 200) {
      yield put(peopleActions.peopleCellEditSuccess({ message: "People Edited Successfully" }));
      yield put(peopleActions.peoplesUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.cellId }));
      if (action.payload.editData.id) yield put(peopleActions.peoplePopupRequestAfter({ id: action.payload.editData.id }));
    } else if (response.status === 400) {
      yield put(peopleActions.peopleCellEditError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peopleCellEditError({ message: "Could Not Edit" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleCellEditError({ message: "Could Not Edit" }));
  }
}


function* generatePeopleAIWFDataFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/ai-wizard/ai-wizard-field/generate", action.payload.data);
    if (response.status === 200) {
      yield put(peopleActions.generatePeopleAIWFDataSuccess({ message: response.data.message }));
      yield put(peopleActions.peoplesUpdateRequest({ loadingData: action.payload.loadingData, cellId: action.payload.data.moduleId }));
      if (action.payload.data.moduleId) yield put(peopleActions.peoplePopupRequestAfter({ id: action.payload.data.moduleId }));
    } else {
      yield put(peopleActions.generatePeopleAIWFDataError({ message: response.data.message }));
    }
  } catch (e) {
    yield put(peopleActions.generatePeopleAIWFDataError({ message: "Could Not Generate Data" }));
  }
}


function* peopleFiltersFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/saved-filters/get?type=people", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleFiltersSuccess(response.data));
    } else {
      yield put(peopleActions.peopleFiltersError({ message: "Could Not Get Filters" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleFiltersError({ message: "Could Not Get Filters" }));
  }
}

function* peopleFilterAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleFilterAddSuccess({ message: "Filter Added Successfully" }));
      yield put(peopleActions.peopleFiltersRequest());
      if (action.cb) yield action.cb(response.data);
    } else {
      yield put(peopleActions.peopleFiltersError({ message: "Could Not Add Filter" }));
      if (action.cb) yield action.cb(response.data);
    }
  } catch (e) {
    yield put(peopleActions.peopleFiltersError({ message: "Could Not Add Filter" }));
  }
}

function* peopleFilterEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleFilterEditSuccess({ message: "Filter Edited Successfully" }));
      yield put(peopleActions.peopleFiltersRequest());
    } else {
      yield put(peopleActions.peopleFilterEditError({ message: "Could Not Edit Filter" }));
    }
    if (action.cb) action.cb(response.data);
  } catch (e) {
    yield put(peopleActions.peopleFilterEditError({ message: "Could Not Edit Filter" }));
  }
}

function* peopleFilterFavoriteFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleFilterFavoriteSuccess({ message: "Filter Edited Successfully" }));
      yield put(peopleActions.peopleFiltersRequest());
    } else {
      yield put(peopleActions.peopleFilterFavoriteError({ message: "Could Not Favorite Filter" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleFilterFavoriteError({ message: "Could Not Favorite Filter" }));
  }
}

function* peopleFilterDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/saved-filters/delete/" + action.payload);
    if (response.status === 200) {
      yield put(peopleActions.peopleFilterDeleteSuccess({ message: "Filter Deleted Successfully" }));
      yield put(peopleActions.peopleFiltersRequest());
    } else {
      yield put(peopleActions.peopleFilterDeleteError({ message: "Could Not Delete Filter" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleFilterDeleteError({ message: "Could Not Delete Filter" }));
  }
}

function* peopleMergeFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/people/merge", action.payload.data);
    if (response.status === 200) {
      yield put(peopleActions.peopleMergeSuccess({ message: "People Merged Successfully" }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(peopleActions.peopleMergeError({ message: response.data.message }));
    } else {
      yield put(peopleActions.peopleMergeError({ message: "Could Not Merge People" }));
    }
  } catch (e) {
    yield put(peopleActions.peopleMergeError({ message: "Could Not Merge People" }));
  }
}

function* convertPeopleToLeadeFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/people/convert-people-to-lead", action.payload.id);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Successfully converted to lead.");
      yield put(peopleActions.convertPeopleToLeadSuccess({ message: "Successfully Converted" }));
      yield put(peopleActions.peoplesRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(peopleActions.convertPeopleToLeadError({ message: response.data.message }));
    } else {
      yield put(peopleActions.convertPeopleToLeadError({ message: "Could Not Convert People To Lead" }));
    }
    if (action.cb) action.cb(response.data);
  } catch (e) {
    yield put(peopleActions.convertPeopleToLeadError({ message: "Could Not Convert People To Lead" }));
  }
}

export default function* PeopleSaga() {
  yield takeLatest(PEOPLES_REQUEST, peoplesFunction);
  yield takeLatest(FIELD_UPDATE_REQUEST, fieldUpdateFunction);
  yield takeLatest(FIELD_SHOWHIDE_REQUEST, fieldShowHideFunction);
  yield takeLatest(FIELD_UPDATEPRIORITY_REQUEST, fieldUpdatePriorityFunction);
  yield takeLatest(PEOPLE_POPUP_REQUEST, peoplePopupFunction);
  yield takeLatest(PEOPLE_POPUP_REQUEST_AFTER, peoplePopupFunction);
  yield takeLatest(PEOPLE_POPUP_EDIT_REQUEST, peoplePopupEditFunction);
  yield takeLatest(PEOPLE_POPUP_UPDATE_REQUEST, peoplePopupUpdateFunction);
  yield takeLatest(PEOPLES_UPDATE_REQUEST, peoplesUpdateFunction);
  yield takeLatest(PEOPLEDROPDOWN_REQUEST, peopleDropdownFunction);
  yield takeLatest(PEOPLE_LEAD_DROPDOWN_REQUEST, peopleLeadDropdownFunction);
  yield takeLatest(PEOPLEDROPDOWNADD_REQUEST, peopleDropdownAddFunction);
  yield takeLatest(OWNERS_REQUEST, ownersFunction);
  yield takeLatest(PEOPLEFIELDS_REQUEST, peopleFieldsFunction);
  yield takeLatest(PEOPLEADDFIELDS_REQUEST, peopleAddFieldsFunction);
  yield takeLatest(PEOPLEADD_REQUEST, peopleAddFunction);
  yield takeLatest(PEOPLEBULKEDIT_REQUEST, peopleBulkEditFunction);
  yield takeLatest(PEOPLEDELETE_REQUEST, peopleDeleteFunction);
  yield takeLatest(PEOPLETAGS_REQUEST, peopleTagsFunction);
  yield takeLatest(PEOPLETAGSADD_REQUEST, peopleTagsAddFunction);
  yield takeLatest(PEOPLETAGSEDIT_REQUEST, peopleTagsEditFunction);
  yield takeLatest(PEOPLETAGSDELETE_REQUEST, peopleTagsDeleteFunction);
  yield takeLatest(PEOPLECELLEDIT_REQUEST, peopleCellEditFunction);
  yield takeLatest(PEOPLEFILTERS_REQUEST, peopleFiltersFunction);
  yield takeLatest(PEOPLEFILTERADD_REQUEST, peopleFilterAddFunction);
  yield takeLatest(PEOPLEFILTEREDIT_REQUEST, peopleFilterEditFunction);
  yield takeLatest(PEOPLEFILTERFAVORITE_REQUEST, peopleFilterFavoriteFunction);
  yield takeLatest(PEOPLEFILTERDELETE_REQUEST, peopleFilterDeleteFunction);
  yield takeLatest(PEOPLEMERGE_REQUEST, peopleMergeFunction);
  yield takeLatest(CONVERT_PEOPLE_TO_LEAD_REQUEST, convertPeopleToLeadeFunction);
  yield takeLatest(generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_REQUEST, generatePeopleAIWFDataFunction);
}
