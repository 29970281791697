import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomSubHeader } from "../../../../components/subheader";
import ContentWrapper from "../../../Wrapper";
import SetupStep1 from "./Steps/email-setup.step1";
import ScheduleStep4 from "./Steps/schedule.step4";
import { campaignAudienceValidation, campaignConfigValidation, errorMessages, generateCampaignConfig } from "./helper";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "./styles.scss";

//actions
import { getConditionRequest } from "../../../Automation/workflow/actions/conditions_actions";
import { peopleFieldsRequest, peopleFiltersRequest, peopleTagsRequest } from "../../../customers/store/actions/actions";
import { leadFieldsRequest, leadFiltersRequest, leadTagsRequest } from "../../../leads/store/actions/actions";
import * as CampaignListActions from "../../store/actions/campaign_details_actions";
import * as EmailSettingsActions from "../../store/actions/campaign_email_settings_actions";
import * as marketingListActions from "../../store/actions/campaign_marketing_list_actions";
import * as SMSSettingsActions from "../../store/actions/campaign_sms_settings_actions";
import * as TemplateActions from "../../store/actions/campaign_templates_actions";
import * as marketingDataFieldActions from "../../store/actions/marketing_data_field_actions";
import * as WhatsappSettingsActions from "../../store/actions/campaign_whatsapp_setting_actions";
import * as whatsappSettingsActions from "../../store/actions/campaign_whatsapp_setting_actions";

import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { companyFieldsRequest } from "../../../companies/store/actions/actions";
import { ContactPerBatcheOptions, TimeIntervalOptions, smsRouteOptions } from "../../constants";
import { getVariableKeys } from "../../../../utils/variables.util";

export const useIsRepeatCampaignInfo = () => {
  const locationState = useLocation();

  const isRepeatCampaign = locationState?.state?.type === "repeat-campaign" || false;
  const repeatCampaignData = useRef(isRepeatCampaign ? locationState?.state?.data || {} : {}).current;

  return { isRepeatCampaign, repeatCampaignData };
};

export default function CampaignBuilder(props) {
  const [title, setTitle] = useState("");
  const [channel, setChannel] = useState("email");
  const [config, setConfig] = useState({ isFlash: false, isUnicode: false, fromEmailPrefix: "", fromEmailSufix: "" });
  const [loading, setLoading] = useState(false);

  // step 2
  const [audience, setAudience] = useState({});
  const [audienceCount, setAudienceCount] = useState(0);
  const [copyPasteData, setCopyPasteData] = useState([]);
  const [uploadedfileVariables, setUploadedFileVariables] = useState([]);

  // step 3
  const [isTemplate, setIsTemplate] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [template, setTemplate] = useState("");
  const [defaultVariableValues, setDefaultVariableValues] = useState({});

  // step 4
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTimezone, setScheduledTimezone] = useState("");

  const [trackOpen, setTrackOpen] = useState(true);
  const [trackClick, setTrackClick] = useState(true);

  const [isAttachment, setIsAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentLoading, setAttachmentLoading] = useState(false);

  const [isSplitCampaign, setIsSplitCampaign] = useState(false);
  const [splitTimeInterval, setSplitTimeInterval] = useState({ label: "15 Min", value: 900 });
  const [contactPerBatch, setContactPerBatch] = useState({ label: 1000, value: 1000 });
  const [splitOptions, setSplitOptions] = useState();

  //sms
  const [senderIdOptions, setSenderIdOptions] = useState([]);

  // whatsapp
  const [whatsappPhoneOptions, setWhatsappPhoneOptions] = useState([]);
  const [selectedWhatsappTemplateData, setSelectedWhatsappTemplateData] = useState({});


  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const profileStore = useSelector((store) => store.ProfileSettings.getProfileDetails.data);
  const senderIdStore = useSelector((store) => store.campaign.settings.sms.senderId);
  const domainStore = useSelector((store) => store.campaign.settings.email.domain);

  //whatsapp
  const whatsappAccountStore = useSelector(state => state.campaign.settings.whatsapp.data);


  useEffect(() => {
    if (params.channel) {
      setChannel(params.channel);
    }
  }, [params]);

  useEffect(() => {
    if (!_.isEmpty(profileStore) && profileStore.timezone) {
      setScheduledTimezone(profileStore.timezone);
    }
  }, [profileStore]);

  useEffect(() => {
    dispatch(getConditionRequest());
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));

    //tags
    dispatch(leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));
    dispatch(marketingListActions.getListOfMarketingListTagsRequest({ type: "marketing" }));

    //filters
    dispatch(leadFiltersRequest());
    dispatch(peopleFiltersRequest());
    dispatch(marketingListActions.getSavedMarketingFiltersRequest({ type: "marketing" }));

    dispatch(peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));
    dispatch(marketingDataFieldActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
  }, [dispatch]);

  useEffect(() => {
    if (channel === "sms") {
      dispatch(SMSSettingsActions.getListOfSMSSenderIdsRequest());
    } if (channel === "whatsapp") {
      dispatch(WhatsappSettingsActions.getListOfWhatsappAccountsRequest())
    } else {
      dispatch(EmailSettingsActions.getListOfEmailDomainSettingsRequest({}));
    }
  }, [dispatch, channel]);

  useEffect(() => {
    if (!_.isEmpty(config.route)) {
      setSenderIdOptions(senderIdStore.data.filter((a) => a.routeName === config.route.value && a.status !== "rejected" && a.status !== "pending").map((each) => ({ value: each._id, label: each.senderId })));
    }
  }, [config.route, senderIdStore.data]);

  useEffect(() => {
    const options = ContactPerBatcheOptions.filter((a) => a.max >= audienceCount && a.min <= audienceCount);
    setSplitOptions(options);
    setContactPerBatch((prev) => {
      const contactPerBatchObj = options.find((v) => v.value === prev.value);
      if (contactPerBatchObj) return contactPerBatchObj;
      if (options.length > 0) return options[0];
    });
  }, [audienceCount]);

  useEffect(() => {
    if (!_.isEmpty(domainStore.list) && channel === "email") {
      domainStore.list.forEach((domain) => {
        const _split = domain.domain.split(" ");
        if (_split.length === 2) {
          const domainEmail = _split[1].replace(/\(|\)/g, "");
          const domainSplit = domainEmail.split("@");
          setConfig((prev) => ({
            ...prev,
            ...{
              isFlash: false,
              isUnicode: false,
              fromEmailPrefix: domainSplit[0],
              fromEmailSufix: domainSplit[1],
            },
          }));
        }
      });
    }
  }, [domainStore.list, channel]);

  // repeat campaign -- start
  const { isRepeatCampaign, repeatCampaignData } = useIsRepeatCampaignInfo();

  useEffect(() => {
    if (!isRepeatCampaign) return;
    const { title, channel, config, audience, content, isScheduled, scheduledDate, scheduledTimezone, splitCampaign } = repeatCampaignData;
    const { isTemplate, templateId, message } = content || {};
    const { openTrack, clickTrack, route } = config;

    setTitle(title);
    setChannel(channel);
    const tempRoute = smsRouteOptions.find((v) => v.value === route);
    setConfig((prev) => ({ ...prev, ...config, isReplyTo: !!config.replyToEmail, route: tempRoute }));
    const tempAudience = { ...audience };
    delete tempAudience.count;
    delete tempAudience.valid;
    delete tempAudience.invalid;
    setAudience(tempAudience);
    setAudienceCount(audience.count);
    // setCopyPasteData()
    // setUploadedFileVariables();
    setIsTemplate(isTemplate);
    setTemplateId(templateId);
    setTemplate(message);
    // setDefaultVariableValues()
    // attachments
    setIsScheduled(isScheduled);
    setScheduledDate(new Date(scheduledDate).toISOString().slice(0, 16));
    setScheduledTimezone(scheduledTimezone);

    setIsSplitCampaign(splitCampaign.isEnabled);

    const contactPerBatchObj = ContactPerBatcheOptions.find((v) => v.value === splitCampaign.cantactPerBatch);
    contactPerBatchObj && setContactPerBatch(contactPerBatchObj);
    const splitTimeIntervalObj = TimeIntervalOptions.find((v) => v.value === splitCampaign.interval);
    splitTimeIntervalObj && setSplitTimeInterval(splitTimeIntervalObj);
    setTrackOpen(openTrack);
    setTrackClick(clickTrack);
  }, [isRepeatCampaign, repeatCampaignData]);

  useEffect(() => {
    if (!isRepeatCampaign || !senderIdOptions.length) return;
    const { senderIds } = repeatCampaignData.config;
    const senderIdsMappedWithLabels = senderIds.map((v) => {
      const temp = senderIdOptions.find((option) => option.value === v);
      return temp;
    });

    setConfig((prev) => ({ ...prev, senderIds: senderIdsMappedWithLabels }));
  }, [isRepeatCampaign, senderIdOptions, repeatCampaignData.config]);

  // repeat campaign -- end

  useEffect(() => {
    if (whatsappAccountStore.list.length && channel === "whatsapp") {
      const options = whatsappAccountStore.list.map((item) => {
        return { value: item._id, label: item.displayPhoneNumber, phoneId: item.phoneId, dailyLimit: item.dailyLimit }
      })
      setWhatsappPhoneOptions(options)
      setConfig((prev) => ({
        ...prev,
        phoneId: whatsappAccountStore.list[0].phoneId,
        integrationId: whatsappAccountStore.list[0]._id,
        dailyLimit: whatsappAccountStore.list[0].dailyLimit,
      }));
      dispatch(whatsappSettingsActions.getWhatsappConsumptionCountRequest({ integrationId: whatsappAccountStore.list[0]._id }))
    }
  }, [dispatch, whatsappAccountStore.list, channel]);
  useEffect(() => {
    if (channel === "whatsapp") {
      setDefaultVariableValues({ header: [], body: [], image: "", video: "", document: "", requiredType: "" })
    }
  }, [channel])

  useEffect(() => {
    if (!_.isEmpty(selectedWhatsappTemplateData)) {
      const _bodyVariables = [];
      const _headerVariables = [];
      let _image = "", _video = "", _document = "";
      let _requiredType = ""
      for (const each of selectedWhatsappTemplateData.components) {
        if (each.type === "BODY") {
          const variables = getVariableKeys(each.text);
          if (variables.length > 0) {
            const obj = {};
            if (selectedWhatsappTemplateData.defaultValues && selectedWhatsappTemplateData.defaultValues.body) {
              const defaultValues = selectedWhatsappTemplateData.defaultValues.body;
              for (const eachVariable of defaultValues) {
                obj[eachVariable.id] = [eachVariable.value, eachVariable.mapField];
              }
            }
            for (const eachVariable of variables) {
              const find = obj[eachVariable];
              if (find) {
                _bodyVariables.push({ id: eachVariable, value: find[0], mapField: find[1] })
              }
              else {
                _bodyVariables.push({ id: eachVariable, value: "", mapField: "" })
              }
            }
          }
        }
        if (each.type === "HEADER") {
          if (each.format === "TEXT") {
            const variables = getVariableKeys(each.text);
            if (variables.length > 0) {
              const obj = {};
              if (selectedWhatsappTemplateData.defaultValues && selectedWhatsappTemplateData.defaultValues.header) {
                const defaultValues = selectedWhatsappTemplateData.defaultValues.header;
                for (const eachVariable of defaultValues) {
                  obj[eachVariable.id] = [eachVariable.value, eachVariable.mapField];
                }
              }
              for (const eachVariable of variables) {
                const find = obj[eachVariable];
                if (find) {
                  _headerVariables.push({ id: eachVariable, value: find[0], mapField: find[1] })
                }
                else {
                  _headerVariables.push({ id: eachVariable, value: "", mapField: "" })
                }
              }
            }
          }
          else if (each.format === "IMAGE") {
            _requiredType = "image";
            _image = "" // each.example.header_handle[0]
            if (each.example && each.example.header_handle && each.example.header_handle[0] && !each.example.header_handle[0].includes("scontent.whatsapp.net")) {
              _image = each.example.header_handle[0]
            }
          }
          else if (each.format === "VIDEO") {
            _requiredType = "video"
            _video = "" // each.example.header_handle[0]
            if (each.example && each.example.header_handle && each.example.header_handle[0] && !each.example.header_handle[0].includes("scontent.whatsapp.net")) {
              _video = each.example.header_handle[0]
            }
          }
          else if (each.format === "DOCUMENT") {
            _requiredType = "document"
            _document = "" //each.example.header_handle[0]
            if (each.example && each.example.header_handle && each.example.header_handle[0] && !each.example.header_handle[0].includes("scontent.whatsapp.net")) {
              _document = each.example.header_handle[0]
            }
          }
        }
      }
      setDefaultVariableValues({
        header: _headerVariables,
        body: _bodyVariables,
        image: _image,
        video: _video,
        document: _document,
        requiredType: _requiredType,
      })
    }
  }, [selectedWhatsappTemplateData])

  const validation = (_channel) => {
    if (_.isEmpty(title)) {
      return "The campaign title cannot be blank!";
    }
    const _config = campaignConfigValidation(channel, config);
    if (_config) return _config;

    const _audience = campaignAudienceValidation(audience);
    if (_audience) return _audience;

    if (isTemplate && !templateId) return errorMessages.INVALID_TEMPLATE_ID;
    else if (!isTemplate && _.isEmpty(template)) return errorMessages.EMPTY_TEMPLATE_MESSAGE;
    else if (attachments && !Array.isArray(attachments)) return errorMessages.INVALID_ATTCHMENT;
    if (isScheduled && _.isEmpty(scheduledTimezone)) return errorMessages.EMPTY_SCHEDULE_TIMEZONE;
    else if (isScheduled && _.isEmpty(scheduledDate)) return errorMessages.EMPTY_SCHEDULE_DATE;
    else if (isSplitCampaign && splitTimeInterval.value <= 0) return errorMessages.INVALID_SPLIT_INTERVAL;
    else if (isSplitCampaign && contactPerBatch.value <= 0) return errorMessages.INVALID_SPLIT_DURATION;

    if (channel === "whatsapp") {
      for (const each of defaultVariableValues.body) {
        if (!each.value) return `Please add default value for {{${each.id}}} in body section.`
        else if (!each.mapField) return `Please add map field for {{${each.id}}} in body section.`
      }
      for (const each of defaultVariableValues.header) {
        if (!each.value) return `Please add default value for {{${each.id}}} in header section.`
        else if (!each.mapField) return `Please add map field for {{${each.id}}} in header section.`
      }

      if (defaultVariableValues.requiredType === "image" && !defaultVariableValues.image) return "Please select image";
      if (defaultVariableValues.requiredType === "video" && !defaultVariableValues.video) return "Please select video";
      if (defaultVariableValues.requiredType === "document" && !defaultVariableValues.document) return "Please select document";
    }
    return "";
  };

  const handleCreate = () => {
    const validate = validation();
    if (validate) return dispatchSnackbarError(validate);
    setLoading(true);
    const data = {
      title: title,
      channel: channel,
      type: "application",
      config: generateCampaignConfig(channel, config, { trackOpen, trackClick }),
      audience: audience,
      content: {
        isTemplate: isTemplate,
        templateId: templateId,
        message: template,
        attachments: attachments.map((each) => each.fileid),
        defaultVariableValues: defaultVariableValues,
      },
      isScheduled: isScheduled,
      scheduledDate: isScheduled ? moment(scheduledDate).tz(scheduledTimezone) : null,
      scheduledTimezone: isScheduled ? scheduledTimezone : "",
      splitCampaign: {
        isEnabled: isSplitCampaign,
        cantactPerBatch: isSplitCampaign ? contactPerBatch.value : 0,
        interval: isSplitCampaign ? splitTimeInterval.value : 0,
      },
      copyPasteData: copyPasteData,
    };

    console.log("data", data);
    dispatch(
      CampaignListActions.createCampaignRequest(data, (status) => {
        if (status.status) {
          dispatchSnackbarSuccess("Successfully submitted a campaign!");
          history.push("/campaign/list");
          setLoading(false);
        } else {
          dispatchSnackbarError(status.message);
          setLoading(false);
        }
      }),
    );
  };

  const validateAttachmentSize = (file) => {
    let size = file.size;
    attachments.forEach((each) => {
      size = size + each.size;
    });

    if (size / 1024 / 1024 > 9) {
      return "The overall size of an email campaign, including attachments, is limited to 10 MB.";
    }
    return "";
  };

  const handleAttachmentUpload = async (e) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const _validate = validateAttachmentSize(e.target.files[0]);
      if (_validate) return dispatchSnackbarError(_validate);
      setAttachmentLoading(true);
      const data = await new Promise((resolve) => dispatch(CampaignListActions.uploadEmailAttachementRequest(formData, (data) => resolve(data))));
      if (data.status) {
        setAttachments([...attachments, data.data]);
      } else {
        dispatchSnackbarError(data.message);
      }
      setAttachmentLoading(false);
    }
  };
  const handleRemoveAttachment = (index) => {
    setAttachments(attachments.filter((a, i) => i !== index));
  };


  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div onClick={() => history.goBack()} className="d-flex align-items-center cursor-pointer">
              <i className="bi bi-arrow-left text-333"></i>
              <span className="ml-2"> Back to campaign list</span>
            </div>
            <div></div>
          </div>
        </CustomSubHeader>
      }
    >
      <div className="email-campaign-wrapper">
        <div className="email-internal-wrapper">
          <SetupStep1
            title={title}
            setTitle={setTitle}
            channel={channel}
            config={config}
            setConfig={setConfig}
            audience={audience}
            setAudience={setAudience}
            setAudienceCount={setAudienceCount}
            copyPasteData={copyPasteData}
            setCopyPasteData={setCopyPasteData}
            setUploadedFileVariables={setUploadedFileVariables}
            // sms
            senderIdOptions={senderIdOptions}
            setIsTemplate={setIsTemplate}
            setTemplateId={setTemplateId}
            setTemplate={setTemplate}

            // whatsapp
            whatsappPhoneOptions={whatsappPhoneOptions}
          />
          <ScheduleStep4
            channel={channel}
            //messegae step3
            config={config}
            setConfig={setConfig}
            isTemplate={isTemplate}
            template={template}
            templateId={templateId}
            setIsTemplate={setIsTemplate}
            setTemplateId={setTemplateId}
            setTemplate={setTemplate}
            defaultVariableValues={defaultVariableValues}
            setDefaultVariableValues={setDefaultVariableValues}
            uploadedfileVariables={uploadedfileVariables}
            // whatsapp editor
            selectedWhatsappTemplateData={selectedWhatsappTemplateData}
            setSelectedWhatsappTemplateData={setSelectedWhatsappTemplateData}
            //scheduling section
            isScheduled={isScheduled}
            scheduledDate={scheduledDate}
            scheduledTimezone={scheduledTimezone}
            setIsScheduled={setIsScheduled}
            setScheduledDate={setScheduledDate}
            setScheduledTimezone={setScheduledTimezone}
            // split campaign
            splitOptions={splitOptions}
            isSplitCampaign={isSplitCampaign}
            setIsSplitCampaign={setIsSplitCampaign}
            splitTimeInterval={splitTimeInterval}
            setSplitTimeInterval={setSplitTimeInterval}
            contactPerBatch={contactPerBatch}
            setContactPerBatch={setContactPerBatch}
            // attachment section
            isAttachment={isAttachment}
            attachments={attachments}
            attachmentLoading={attachmentLoading}
            setAttachments={setAttachments}
            setIsAttachment={setIsAttachment}
            handleRemoveAttachment={handleRemoveAttachment}
            handleAttachmentUpload={handleAttachmentUpload}
            // email tracking ""
            trackClick={trackClick}
            trackOpen={trackOpen}
            setTrackOpen={setTrackOpen}
            setTrackClick={setTrackClick}
            loading={loading}
            handleSendCampaignClick={handleCreate}
            handleCancel={() => history.goBack()}
          />
        </div>
      </div>
    </ContentWrapper>
  );
}
