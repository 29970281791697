import { getListOfBlockContacts } from "../types/campaign_block_contact_type";

const initState = {
  data: [],
  count: 0,
  loading: false,
  error: null,
  cellLoading: null,
};

export default function campaignBlockNumberReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        count: action.payload.count,
        error: null,
      };
    case getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
