import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as developerActions from "../../actions/admin-actions/developer-settings.actions";
import * as developerTypes from "../../types/admin-settings.types";
import { endpoints } from "../../endpoint";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";

function* addWebhookFunction(action) {
  try {
    const res = yield call(api.post, endpoints.ADD_WEBHOOK, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(developerActions.addWebHookSuccess(res.data));
    } else if (res.status === 201) {
      yield action.cb(res.data);
      yield put(developerActions.addWebHookSuccess(res.data));
    } else {
      yield put(developerActions.addWebHookError("error"));
    }
  } catch (e) {
    yield put(developerActions.addWebHookError(e));
  }
}

function* getWebhookFunction(action) {
  try {
    const res = yield call(api.get, endpoints.ADD_WEBHOOK);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(developerActions.getWebHookSuccess(res.data));
    } else {
      yield put(developerActions.getWebHookError("error"));
    }
  } catch (e) {
    yield put(developerActions.getWebHookError(e));
  }
}

function* patchWebhookFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.ADD_WEBHOOK, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(developerActions.patchWebHookSuccess(res.data));
    } else {
      yield put(developerActions.patchWebHookError("error"));
    }
  } catch (e) {
    yield put(developerActions.patchWebHookError(e));
  }
}

function* deleteWebhookFunction(action) {
  try {
    const res = yield call(api.delete, endpoints.ADD_WEBHOOK`/${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(developerActions.deleteWebHookSuccess(res.data));
    } else {
      yield put(developerActions.deleteWebHookError("error"));
    }
  } catch (e) {
    yield put(developerActions.deleteWebHookError(e));
  }
}

function* testWebhookFunction(action) {
  try {
    const res = yield call(api.post, endpoints.TEST_WEBHOOK, action.payload);
    yield put(developerActions.testWebHookSuccess(res.data));
    yield action.cb(res.data);
  } catch (e) {
    yield put(developerActions.testWebHookError(e));
  }
}

function* disableWebhookFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.DISABLE_WEBHOOK, action.payload);
    yield put(developerActions.disableWebHookSuccess(res.data));
    yield action.cb(res.data);
  } catch (e) {
    yield put(developerActions.disableWebHookError(e));
  }
}

function* postKeyFunction(action) {
  try {
    const res = yield call(api.post, endpoints.DEVELOPER_KEY, action.payload);
    if (res.status === 200 || res.status === 201) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(developerActions.postKeySuccess(res.data));
      yield put(developerActions.getKeyRequest());
    } else {
      // dispatchSnackbarError(res.data.message);
      yield put(developerActions.postKeyError("error"));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(developerActions.postKeyError(e));
  }
}

function* getKeyFunction(action) {
  try {
    const res = yield call(api.get, endpoints.DEVELOPER_KEY);
    // some condition{}
    if (res.status === 200) {
      yield put(developerActions.getKeySuccess(res.data));
    } else {
      yield put(developerActions.getKeyError("error"));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(developerActions.getKeyError(e));
  }
}

function* patchKeyFunction(action) {
  try {
    const res = yield call(api.patch, `${endpoints.DEVELOPER_KEY}/${action.payload.id}`, action.payload.data);
    // some condition{}
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(developerActions.patchKeySuccess(res.data));
      yield put(developerActions.getKeyRequest());
    } else {
      // dispatchSnackbarError(res.data.message);
      yield put(developerActions.patchKeyError("error"));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(developerActions.patchKeyError(e));
  }
}

function* deleteKeyFunction(action) {
  try {
    const res = yield call(api.delete, `${endpoints.DEVELOPER_KEY}/${action.payload.id}`);
    // some condition{}
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(developerActions.deleteKeySuccess(res.data));
      yield put(developerActions.getKeyRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(developerActions.deleteKeyError("error"));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    console.log(e);
    yield put(developerActions.deleteKeyError(e));
  }
}
export default function* developerSaga() {
  yield takeLatest(developerTypes.addWebHook.ADD_WEB_HOOK_REQUEST, addWebhookFunction);
  yield takeLatest(developerTypes.getWebHook.GET_WEB_HOOK_REQUEST, getWebhookFunction);
  yield takeLatest(developerTypes.patchWebHook.PATCH_WEB_HOOK_REQUEST, patchWebhookFunction);
  yield takeLatest(developerTypes.deleteWebHook.DELETE_WEB_HOOK_REQUEST, deleteWebhookFunction);
  yield takeLatest(developerTypes.testWebHook.TEST_WEB_HOOK_REQUEST, testWebhookFunction);
  yield takeLatest(developerTypes.disableWebHook.DISABLE_WEB_HOOK_REQUEST, disableWebhookFunction);
  yield takeLatest(developerTypes.postKey.POST_KEY_REQUEST, postKeyFunction);
  yield takeLatest(developerTypes.getKey.GET_KEY_REQUEST, getKeyFunction);
  yield takeLatest(developerTypes.patchKey.PATCH_KEY_REQUEST, patchKeyFunction);
  yield takeLatest(developerTypes.deleteKey.DELETE_KEY_REQUEST, deleteKeyFunction);
}
