import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AccessControlSVG, CodeIconSVG, DeleteIconSVG, EditIconSVG, ExchangeIconSVG, TestUrlIconSVG } from "../custom-svgs";

export const EditIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Edit"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {EditIconSVG}
    </span>
  </OverlayTrigger>
);

export const DeleteIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Delete"}</Tooltip>}>
    <span className="import-icon-container text-danger bg-hover-light-danger" onClick={onClick}>
      {DeleteIconSVG}
    </span>
  </OverlayTrigger>
);

export const CloneIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Clone"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      <i className="far fa-clone text-secondary"></i>
    </span>
  </OverlayTrigger>
);

export const CodeIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Embed / Share"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {CodeIconSVG}
    </span>
  </OverlayTrigger>
);

export const ConvertToAdminIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Convert To Admin"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {ExchangeIconSVG}
    </span>
  </OverlayTrigger>
);

export const ConvertToAgentIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Convert To Agent"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {ExchangeIconSVG}
    </span>
  </OverlayTrigger>
);

export const TestUrlIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Test URL"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {TestUrlIconSVG}
    </span>
  </OverlayTrigger>
);

export const IconButton = ({ onClick, tooltip, children }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {children}
    </span>
  </OverlayTrigger>
);

export const IconButton2 = ({ onClick, tooltip, children, className, style }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
    <span className={className} style={style} onClick={onClick}>
      {children}
    </span>
  </OverlayTrigger>
);

export const TextIconButton = ({ onClick, children }) => (
  <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
    {children}
  </span>
);

export const AccessControllIconButton = ({ onClick }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Access Control"}</Tooltip>}>
    <span className="import-icon-container text-secondary bg-hover-light-secondary" onClick={onClick}>
      {AccessControlSVG}
    </span>
  </OverlayTrigger>
);