import React from "react";
import Table from "./table/table";
import List from "./list/list";

class DealStagesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stages: [
        {
          name: "one",
          _id: "12345",
          type: "Stage",
          initialDealsTotalCount: 0,
          inProcessDealsTotalCount: 1,
          stayedDealsTotalCount: 1,
          compareNextStage: {
            count: 2,
            percent: 0,
          },
        },
        {
          compareNextStage: {
            count: 0,
            percent: null,
          },
          name: "two",
          _id: "44562",
          type: "Stage",
          initialDealsTotalCount: 1,
          inProcessDealsTotalCount: 2,
          stayedDealsTotalCount: 1,
        },
        { compareNextStage: {}, name: "three", _id: "44062", type: "Stage", initialDealsTotalCount: 2, inProcessDealsTotalCount: 3, stayedDealsTotalCount: 1 },
      ],
    };
  }

  render() {
    const { type } = this.props;
    if (type === "more") {
      return <Table {...this.props} stages={this.state.stages} />;
    }
    return <List stages={this.state.stages} />;
  }
}

export default DealStagesContainer;
