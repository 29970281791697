import { GET_SETTING_ACTIVITIES_REQUEST, GET_SETTING_ACTIVITIES_RESPONSE, GET_SETTING_ACTIVITIES_ERROR } from "../types/activity.types";

const initialState = {
  list: [],
  status: false,
  error: "",
};

export function settingActivityReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SETTING_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTING_ACTIVITIES_RESPONSE:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_SETTING_ACTIVITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
