import { postLostReason, getLostReason, patchLostReason, deleteLostReason, getActivityLossReasonsSettings, updateActivityLossReasonsSettings } from "../../types/admin-settings.types";

export function postLostReasonRequest(req) {
  return {
    type: postLostReason.POST_LOST_REASON_REQUEST,
    payload: req,
  };
}

export function postLostReasonSuccess(res) {
  return {
    type: postLostReason.POST_LOST_REASON_SUCCESS,
    payload: res,
  };
}

export function postLostReasonError(err) {
  return {
    type: postLostReason.POST_LOST_REASON_ERROR,
    payload: err,
  };
}

export function getLostReasonRequest(req) {
  return {
    type: getLostReason.GET_LOST_REASON_REQUEST,
    payload: req,
  };
}

export function getLostReasonSuccess(res) {
  return {
    type: getLostReason.GET_LOST_REASON_SUCCESS,
    payload: res,
  };
}

export function getLostReasonError(err) {
  return {
    type: getLostReason.GET_LOST_REASON_ERROR,
    payload: err,
  };
}

export function patchLostReasonRequest(req) {
  return {
    type: patchLostReason.PATCH_LOST_REASON_REQUEST,
    payload: req,
  };
}

export function patchLostReasonSuccess(res) {
  return {
    type: patchLostReason.PATCH_LOST_REASON_SUCCESS,
    payload: res,
  };
}

export function patchLostReasonError(err) {
  return {
    type: patchLostReason.PATCH_LOST_REASON_ERROR,
    payload: err,
  };
}

export function deleteLostReasonRequest(req) {
  return {
    type: deleteLostReason.DELETE_LOST_REASON_REQUEST,
    payload: req,
  };
}

export function deleteLostReasonSuccess(res) {
  return {
    type: deleteLostReason.DELETE_LOST_REASON_SUCCESS,
    payload: res,
  };
}

export function deleteLostReasonError(err) {
  return {
    type: deleteLostReason.DELETE_LOST_REASON_ERROR,
    payload: err,
  };
}

export const getActivityLossReasonsSettingsRequest = (req) => ({
  type: getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST,
  payload: req,
});
export const getActivityLossReasonsSettingsSuccess = (req) => ({
  type: getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS,
  payload: req,
});
export const getActivityLossReasonsSettingsError = (req) => ({
  type: getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR,
  payload: req,
});

export const updateActivityLossReasonsSettingsRequest = (req) => ({
  type: updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST,
  payload: req,
});
export const updateActivityLossReasonsSettingsSuccess = (req) => ({
  type: updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS,
  payload: req,
});
export const updateActivityLossReasonsSettingsError = (req) => ({
  type: updateActivityLossReasonsSettings.UPDATE_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR,
  payload: req,
});
