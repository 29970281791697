import React, { useEffect, useState } from "react";
import { CustomSubHeader } from "../../../components/subheader";
import ActivityMenu from "../activity-menu";
import CalendarComponent from "../../../components/calendar/index";
import { useDispatch, useSelector } from "react-redux";
import * as syncActions from "../../Settings/actions/sync.actions";
import * as activityCalendarActions from "../store/actions/calendar_view_actions";
import { Spinner } from "react-bootstrap";
import { UpgradePlanPage } from "../../../components/empty_page";
import SubscriptionPlanModal from "../../Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import ContentWrapper from "../../Wrapper";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";

export default function ActivityCalendar(props) {
  const calendarViewStore = useSelector((store) => store.activity.calendar);

  const agendaStore = useSelector((store) => store.sync.agenda.data);
  const loading = useSelector((store) => store.sync.agenda.loading);
  const [showPlans, setShowPlans] = useState(false);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(syncActions.getAgendaTokenRequest());
  }, [dispatch]);

  const getDataForSelectedDate = (start, end, cb) => {
    dispatch(
      activityCalendarActions.getCalendarEventRequest(
        {
          startDate: start,
          endDate: end,
        },
        (data) => cb(data),
      ),
    );
  };

  return (
    <>
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.ACTIVITY_CALENDAR_VIEW ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              {" "}
              <ActivityMenu />{" "}
            </CustomSubHeader>
          }
        >
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Calendar view"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </ContentWrapper>
      ) : (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="sub-header-wrapper">
                <ActivityMenu />
              </div>
            </CustomSubHeader>
          }
        >
          <div>
            {loading ? (
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : agendaStore && agendaStore.nKey ? (
              <CalendarComponent data={calendarViewStore && calendarViewStore.data ? calendarViewStore.data : []} getDataForSelectedDate={getDataForSelectedDate} />
            ) : (
              <CustomEmptyPage page="connectCalendar" />
            )}
          </div>
        </ContentWrapper>
      )}
    </>
  );
}
