import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as CampaignWhatsappSettingsActions from "../../../../store/actions/campaign_whatsapp_setting_actions";
import AddWhatsappCloudAPI from "./add-whatsapp-cloud-api";
import { Button, Spinner } from "react-bootstrap";
import WhatsappCloudApiHelpDoc from "./whatsapp-cloud-api-help-doc";
import { DeleteIconButton } from "../../../../../../components/button/icon-action-buttons";
import "../styles.scss";
import { CustomEmptyPage } from "../../../../../../components/empty_page/custom-empty-page";
import OpenWhatsappCloudApiSettings from "./open-settings";
import { DeleteModal } from "../../../../../../components/modal";
import { numberToShorthand } from "../../../../../../utils/index.util";

export default function WhatsappSettings() {
    const [showAddWhatsapp, setShowAddWhatsapp] = useState(false);
    const [showHelpDoc, setShowHelpDoc] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
    const [showWhasappUpdateConfig, setShowWhasappUpdateConfig] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [delatableIntegrationId, setDelatableIntegrationId] = useState(null);
    const dispatch = useDispatch();

    const whatsappAccountsStore = useSelector(state => state.campaign.settings.whatsapp.data);


    useEffect(() => {
        dispatch(CampaignWhatsappSettingsActions.getListOfWhatsappAccountsRequest())
    }, [dispatch])

    const handleOpenSettingsClick = (data) => {
        setSelectedIntegration(data);
        setSelectedIntegrationId(data._id)
        setShowWhasappUpdateConfig(true)
    }
    const handleSettingsCancel = () => {
        setSelectedIntegration(null);
        setSelectedIntegrationId(null)
        setShowWhasappUpdateConfig(false)
    }

    const handleDeleteAccountClick = (data) => {
        setDelatableIntegrationId(data._id);
        setShowDeleteAccountModal(true)
    }

    const handleDeleteModelClose = () => {
        setDelatableIntegrationId(null);
        setShowDeleteAccountModal(false)
    }
    const handleDeleteModalAccept = () => {
        dispatch(CampaignWhatsappSettingsActions.deleteWhatsappAccountRequest({ integrationId: delatableIntegrationId }))
        setDelatableIntegrationId(null);
        setShowDeleteAccountModal(false)
    }


    const deleteAccountModalProps = {
        show: showDeleteAccountModal,
        title: "Delete Whatsapp Account",
        handleClose: handleDeleteModelClose,
        handleAccept: handleDeleteModalAccept,
    };


    return (
        <div className="whatsapp-settings-wrapper">
            <div className="h-70px d-flex flex-stack align-items-center px-8 border-bottom align-items-lg-center">
                <h3 className="font-size-16px fw-bolder text-dark ">{`Whatsapp Settings`}</h3>
                <div>
                    <Button size="sm" onClick={() => setShowAddWhatsapp(true)}>Add Whatsapp</Button>
                </div>
            </div>
            <div className="p-8 h-vh-73px overflow-auto">
                {whatsappAccountsStore.loading ? <div className="w-100 scroll-y pt-10 pb-10 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div> :
                    whatsappAccountsStore.list.length === 0 ? <CustomEmptyPage page="whastapp-account" setShowAdd={() => setShowAddWhatsapp(true)} />
                        : <div className="">
                            {whatsappAccountsStore.list.map((eachList, ind) => (
                                <div key={ind} className="d-flex justify-content-between align-items-center rounded-1 px-4 border-light-dark border py-4 mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50px me-5">
                                            <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/2044px-WhatsApp.svg.png"} alt="" />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="fw-bolder">{eachList.verifiedName}</span>
                                            <span className="text-muted">{eachList.displayPhoneNumber}</span>
                                        </div>

                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-column mr-10 justify-content-center">
                                            <span className={`fw-bolder ${_.lowerCase(eachList.qualityRating || "")}-dot ml-38px`}>{""}</span>
                                            <span className="text-muted">{"Quality Rating"}</span>
                                        </div>
                                        <div className="d-flex flex-column mr-10 justify-content-center">
                                            <span className="fw-bolder text-center">{eachList.codeVerificationStatus}</span>
                                            <span className="text-muted">{"Verification Status"}</span>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <span className="fw-bolder text-center">{numberToShorthand(eachList.dailyLimit || 0)}</span>
                                            <span className="text-muted">{"Daily Message Limit"}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-4">
                                        <Button size="sm" variant="link" onClick={() => handleOpenSettingsClick(eachList)}>Open Settings</Button>
                                        <DeleteIconButton onClick={() => handleDeleteAccountClick(eachList)} />
                                    </div>
                                </div>
                            ))}
                        </div>}

                <AddWhatsappCloudAPI show={showAddWhatsapp} setShow={setShowAddWhatsapp} />
                <WhatsappCloudApiHelpDoc show={showHelpDoc} setShow={setShowHelpDoc} />
                <OpenWhatsappCloudApiSettings show={showWhasappUpdateConfig} data={selectedIntegration} integrationId={selectedIntegrationId} setShow={handleSettingsCancel} />
                <DeleteModal modalProps={deleteAccountModalProps}>Are you sure you want to delete the account?</DeleteModal>
            </div>
        </div>
    )
}