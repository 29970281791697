import React, { useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import CustomUserAndTeamDropdown from "../user-filter/index";
import PerfectScrollbar from "react-perfect-scrollbar";
// import DateRangePickerTag from "../../../../components/date-range-picker-tag/DateRangePickerTag";
import DateRangePickerInput from "../../../../components/date-range-picker-tag/DateRangePickerInput";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

const entityBadgeColors = {
  deal: "#7239ea",
  forecast: "#50cd89",
  activity: "#009ef7",
};

const upperCase = (text) => {
  return text.toUpperCase();
};

export default function AddReportForm(props) {
  let myArray = [];
  let teamArray = [];
  useEffect(() => {
    props.userRoleData[0]["users"].filter((res) => {
      let obj = {
        value: res.id,
        label: res.email,
      };
      myArray.push(obj);
      return null;
    });

    props.userRoleData[0]["teams"].filter((res) => {
      let obj = {
        value: res.id,
        label: res.name,
      };
      teamArray.push(obj);
      return null;
    });
  }, [props.userRoleData, myArray, teamArray]);

  return (
    <Form>
      <div className="d-flex flex-row flex-wrap">
        <Col className="pt-0 pr-18px pb-0 pl-7px" xs={12}>
          <PerfectScrollbar className="scroll position-relative h-60vh-mx" options={{ wheelSpeed: 1, wheelPropagation: false }}>
            {props.typeReportStep === 1 && (
              <div className="container">
                <div className="row">
                  {props.typeOfReport.map((data, key) => {
                    return (
                      <div key={key} className="col-4 mb-10px">
                        <div
                          key={key}
                          className={`${
                            props.activeCard === key ? "bg-ecf8ff text-009ef7 border-color-009ef7" : ""
                          } position-relative btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column h-100 p-3 text-left`}
                          onClick={() => {
                            props.setActiveCard(key);
                            props.reportCardValue(data);
                          }}
                        >
                          <i className={`${data.icon} mb-16px font-size-48px text-cornflowerblue`}></i>
                          <span className="text-dark d-block mb-2 fw-500">{data.name}</span>
                          <div className="overflow-wrap-anywhere">
                            <span>{data.description}</span>
                          </div>
                          <div className="position-absolute right-10px">
                            <InlineStyled className="badge badge-dark m-0 bg-white" styles={{ border: `1px solid ${entityBadgeColors[data.entity]}`, color: entityBadgeColors[data.entity] }}>
                              {upperCase(data.entity)}
                            </InlineStyled>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {props.typeReportStep === 2 && (
              <>
                <Form.Group id="insights-components-forms-add-report-user-selection">
                  <CustomUserAndTeamDropdown options={props.userRoleOptions} handleSelect={(type, id, name) => props.onCustomUserSelect(type, id, name)} activeUser={props.activeUser} />
                </Form.Group>
                {props.stepFirstVal.entity !== "activity" && props.stepFirstVal.entity !== "lead" && (
                  <Form.Group id="insights-components-forms-add-report-pipeline-change">
                    <Form.Label aria-label="pipeline">Pipeline</Form.Label>
                    <Select
                      aria-label="Default select example"
                      value={props.selectedPipeline}
                      onChange={(e) => props.onPipelineChange(e)}
                      options={props.pipelineOptions && props.pipelineOptions.filter((a) => a.value !== "allPipelines")}
                      styles={props.customStyles}
                    />
                  </Form.Group>
                )}
                {props.stepFirstVal.reportType !== "conversation" && props.stepFirstVal.reportType !== "lost-reasons" && (
                  <Form.Group id="insights-components-forms-add-report-interval-change">
                    <Form.Label aria-label="interval">Interval</Form.Label>
                    <Select
                      // className="form-select"
                      aria-label="Default select example"
                      value={props.interval}
                      onChange={(e) => props.onIntervalChange(e)}
                      options={props.intervalOptions}
                      styles={props.customStyles}
                    />
                  </Form.Group>
                )}
                <Form.Group id="insights-components-forms-add-report-duration">
                  <Form.Label aria-label="duration">Duration</Form.Label>
                  <DateRangePickerInput
                    defaultStartDate={props.fromDate ? moment(props.fromDate) : undefined}
                    defaultEndDate={props.toDate ? moment(props.toDate) : undefined}
                    onDateRangeChange={({ dates: { startDate, endDate } }) => {
                      props.fromDateFun(startDate.format("YYYY-MM-DD"));
                      props.toDateFun(endDate.format("YYYY-MM-DD"));
                    }}
                  />
                </Form.Group>
              </>
            )}
          </PerfectScrollbar>
        </Col>
      </div>
    </Form>
  );
}
