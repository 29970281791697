import _ from "lodash";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContentWrapper from "../../../../Wrapper";
import CadenceSubHeader from "../../components/cadence-header";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DateRangePickerTag from "../../../../../components/date-range-picker-tag/DateRangePickerTag";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { timeIntervalOptions } from "../../constants";
import { getCadenceAnalyticsSummarizeRequest } from "../../store/actions/cadence_analytics_summarize_actions";
import { cadenceStatPercentage } from "./utils/functions";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

const dateRangePickerRanges = {
  today: { status: "Today", range: [moment().startOf("day"), moment().endOf("day")] },
  yesterday: {
    status: "Yesterday",
    range: [
      moment()
        .startOf("day")
        .subtract(1, "days"),
      moment()
        .endOf("day")
        .subtract(1, "days"),
    ],
  },
  last6Days: {
    status: "Last 6 days",
    range: [
      moment()
        .startOf("day")
        .subtract(5, "days"),
      moment().endOf("day"),
    ],
  },
  thisWeek: { status: "This Week", range: [moment().startOf("week"), moment().endOf("week")] },
  lastWeek: {
    status: "Last Week",
    range: [
      moment()
        .subtract(1, "week")
        .startOf("week"),
      moment()
        .subtract(1, "week")
        .endOf("week"),
    ],
  },
  sixWeeks: {
    status: "6 Weeks",
    range: [
      moment()
        .endOf("week")
        .subtract(6, "week"),
      moment().endOf("week"),
    ],
  },
  thisMonth: { status: "This Month", range: [moment().startOf("month"), moment().endOf("month")] },
  lastMonth: {
    status: "Last Month",
    range: [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month"),
    ],
  },
  threeMonths: {
    status: "3 Months",
    range: [
      moment()
        .endOf("month")
        .subtract(3, "month"),
      moment().endOf("month"),
    ],
  },
  sixMonths: {
    status: "6 Month",
    range: [
      moment()
        .endOf("month")
        .subtract(6, "month"),
      moment().endOf("month"),
    ],
  },
};

export default function CadenceAnalyticsSummarize() {
  const tabName = "Summary";

  const [selectedTimeInterval, setSelectedTimeInterval] = useState(timeIntervalOptions[0]);
  const [startDate, setStartDate] = useState(dateRangePickerRanges.last6Days.range[0]);
  const [endDate, setEndDate] = useState(dateRangePickerRanges.last6Days.range[1]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { cadenceId } = useParams();
  const cadenceAnalyticsSummarize = useSelector((state) => state.cadence.analyticsSummarize);

  useEffect(() => {
    if (!cadenceId) {
      history.push("/automation/cadence/list");
    }
  }, [cadenceId, history]);

  useLayoutEffect(() => {
    if (cadenceAnalyticsSummarize.loading) return;

    var chart = am4core.create("cadence-analytics-chart", am4charts.XYChart);

    // chart.colors.step = 2;
    chart.colors.list = [
      am4core.color("#FFCFFF"), // smsSent
      am4core.color("#9898FA"), // sms delivered
      am4core.color("#CFDAFF"), // sms undeivered
      am4core.color("#00EC09"), // activity created
      am4core.color("#A3FFA6"), // activity completed
      am4core.color("#60B563"), // business email sent
      am4core.color("#252597"), // business email opened
      am4core.color("#6170A0"), // business email clicked
      am4core.color("#FF94CC"), // business email replied
      am4core.color("#FF7373"), // business email unsubscribed
      // am4core.color("#D2FBF0"), // campaign email sent
      // am4core.color("#FFCCB0"), // campaign email opened
      // am4core.color("#C5AB82"), // campaign email clicked
      // am4core.color("#E5EFFF"), // campaign email replied
      // am4core.color("#FF7373"), // campaign email unsubscribed
      am4core.color("#FF0000"), // Errored
    ];

    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "day";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "day";
      series.name = name;
      series.columns.template.tooltipText = "{name}: {valueY}";

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      return series;
    }

    createSeries("smsSent", "SMS Sent");
    createSeries("smsDelivered", "SMS Delivered");
    createSeries("smsUnDelivered", "SMS Undelivered");
    createSeries("activityCreated", "Activity Created");
    createSeries("activityCompleted", "Activity Completed");
    createSeries("emailSent", "Email Sent");
    createSeries("emailOpened", "Email Opened");
    createSeries("emailReplied", "Email Replied");
    createSeries("emailClicked", "Email Clicked");
    createSeries("emailUnsubscribed", "Email Unsubscribed");
    createSeries("whatsappSent", "Whatsapp Sent");
    createSeries("whatsappDelivered", "Whatsapp Delivered");
    createSeries("whatsappRead", "Whatsapp Read");
    createSeries("errored", "Errored");

    function arrangeColumns() {
      var series = chart.series.getIndex(0);

      var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          });
        }
      }
    }

    chart.data = cadenceAnalyticsSummarize.data;
  }, [cadenceAnalyticsSummarize.data, cadenceAnalyticsSummarize.loading]);

  useEffect(() => {
    dispatch(
      getCadenceAnalyticsSummarizeRequest({
        id: cadenceId,
        query: {
          startDate: startDate
            .startOf("day")
            .valueOf()
            .toString(),
          endDate: endDate
            .endOf("day")
            .valueOf()
            .toString(),
          interval: selectedTimeInterval.value,
        },
      }),
    );
  }, [dispatch, cadenceId, selectedTimeInterval.value, startDate, endDate]);

  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll('[shape-rendering="auto"]').forEach((e) => e.remove());
    }, 1000);
  });

  return (
    <ContentWrapper
      subHeader={
        <CadenceSubHeader tabName={tabName} cadenceId={cadenceId}>
          <div className="mr-4">
            <SimpleDropdown
              value={selectedTimeInterval}
              label={"SMS Send Type"}
              options={timeIntervalOptions}
              handleSelect={(selected) => {
                const newStartAndEndDate = (() => {
                  if (selected.value === "daily") {
                    return [dateRangePickerRanges.last6Days.range[0], dateRangePickerRanges.last6Days.range[1]];
                  }
                  if (selected.value === "weekly") {
                    return [dateRangePickerRanges.sixWeeks.range[0], dateRangePickerRanges.sixWeeks.range[1]];
                  }
                  if (selected.value === "monthly") {
                    return [dateRangePickerRanges.sixMonths.range[0], dateRangePickerRanges.sixMonths.range[1]];
                  }
                })();

                setStartDate(newStartAndEndDate[0]);
                setEndDate(newStartAndEndDate[1]);
                setSelectedTimeInterval(selected);
              }}
            />
          </div>

          {/* TODO: need to refactor dateRangePicker so we'll be able to control the startDate, endDate, ranges from outside. */}
          {/* TODO: need to fix maxSpan. the below prop should have been maxSpan={{days:6}} */}
          {selectedTimeInterval.value === "daily" && (
            <DateRangePickerTag
              ranges={[dateRangePickerRanges.today, dateRangePickerRanges.yesterday, dateRangePickerRanges.last6Days]}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              onDateRangeChange={({ dates: { startDate, endDate } }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              maxSpan={{ days: 5 }}
              placement="bottom-end"
            />
          )}
          {selectedTimeInterval.value === "weekly" && (
            <DateRangePickerTag
              ranges={[dateRangePickerRanges.thisWeek, dateRangePickerRanges.lastWeek, dateRangePickerRanges.sixWeeks]}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              onDateRangeChange={({ dates: { startDate, endDate } }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              maxSpan={{ weeks: 6 }}
              placement="bottom-end"
            />
          )}
          {selectedTimeInterval.value === "monthly" && (
            <DateRangePickerTag
              ranges={[dateRangePickerRanges.thisMonth, dateRangePickerRanges.lastMonth, dateRangePickerRanges.threeMonths, dateRangePickerRanges.sixMonths]}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              onDateRangeChange={({ dates: { startDate, endDate } }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              maxSpan={{ months: 6 }}
              placement="bottom-end"
            />
          )}
        </CadenceSubHeader>
      }
    >
      <div className="card py-5 d-flex flex-column h-vh-180px">
        <div id="cadence-analytics-chart" className="flex-grow-1"></div>
        <CadenceRangeStats />
      </div>
    </ContentWrapper>
  );
}

const CadenceRangeStats = () => {
  const cadenceAnalyticsSummarize = useSelector((state) => state.cadence.analyticsSummarize);

  const smsSentTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "smsSent"));
  const smsDeliveredTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "smsDelivered"));
  const activityCreatedTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "activityCreated"));
  const activityCompletedTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "activityCompleted"));
  const emailSentTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "emailSent"));
  const emailOpenedTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "emailOpened"));
  const emailRepliedTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "emailReplied"));
  const emailClickedTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "emailClicked"));
  // const whatsappSentTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "whatsappSent"));
  // const whatsappDeliveredTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "whatsappDelivered"));
  // const whatsappReadTotal = _.sum(_.map(cadenceAnalyticsSummarize.data, "whatsappRead"));
  // const erroredTotal = _.sum(_.map(apiResults.data, "errored"));

  const cadenceRangeStatsData = [
    {
      percentage: cadenceStatPercentage(smsDeliveredTotal, smsSentTotal),
      description: `${smsDeliveredTotal} sms delivered`,
    },
    {
      percentage: cadenceStatPercentage(activityCompletedTotal, activityCreatedTotal),
      description: `${activityCompletedTotal} ${activityCompletedTotal > 1 ? "activities" : "Activity"} completed`,
    },
    {
      percentage: cadenceStatPercentage(emailOpenedTotal, emailSentTotal),
      description: `${emailOpenedTotal} ${emailOpenedTotal > 1 ? "emails" : "email"} opened`,
    },
    {
      percentage: cadenceStatPercentage(emailRepliedTotal, emailSentTotal),
      description: `${emailRepliedTotal} ${emailRepliedTotal > 1 ? "emails" : "email"} replied`,
    },
    {
      percentage: cadenceStatPercentage(emailClickedTotal, emailSentTotal),
      description: `${emailClickedTotal} ${emailClickedTotal > 1 ? "emails" : "email"} clicked`,
    },
  ];
  return (
    <div className="d-flex gap justify-content-center">
      {cadenceRangeStatsData.map((v, i) => {
        const isLastElement = i === cadenceRangeStatsData.length - 1;
        return <PercentageAndDescription key={i} percentage={v.percentage} description={v.description} className={isLastElement ? "" : "border-right border-secondary"} />;
      })}
    </div>
  );
};

const PercentageAndDescription = ({ percentage, description, className }) => {
  return (
    <div className={`percentage-and-description text-center px-7 ${className}`}>
      <div className="fs-1 fw-bold">{percentage}%</div>
      <div className="fs-6">{description}</div>
    </div>
  );
};
