import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as campaignDetailsActions from "../actions/campaign_details_actions";
import * as campaignTypes from "../types/campaign_types";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* getListOfCampaign(action) {
  try {
    const res = yield call(api.get, endpoints.CAMPAIGN_LIST, action.payload);
    if (res.status === 200) {
      yield put(campaignDetailsActions.getCampaignListSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignDetailsActions.getCampaignListError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.getCampaignListError(e));
  }
}

function* createCampaignFunc(action) {
  try {
    const res = yield call(api.post, endpoints.CAMPAIGN_LIST, action.payload);
    // const res = yield new Promise((resolve) => setTimeout(() => resolve({ status: 200, data: { status: true, message: "Created Successfully!" } }), 1000))
    if (res.status === 200) {
      yield put(campaignDetailsActions.createCampaignSuccess(res.data));
    } else {
      // dispatchSnackbarError(res.data.message)
      yield put(campaignDetailsActions.createCampaignError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.createCampaignError(e));
  }
}

function* rescheduledCamapign(action) {
  try {
    const res = yield call(api.patch, endpoints.RESCHEDULE_CAMPAIGN, action.payload.body);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully Rescheduled.");
      yield put(campaignDetailsActions.rescheduledCamapignSuccess(res.data));
      yield put(campaignDetailsActions.getCampaignListRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignDetailsActions.rescheduledCamapignError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.rescheduledCamapignError(e));
  }
}

function* cancelCampaign(action) {
  try {
    const res = yield call(api.patch, endpoints.CANCEL_CAMPAIGN, action.payload.body);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully Cancelled.");
      yield put(campaignDetailsActions.cancelCampaignSuccess(res.data));
      yield put(campaignDetailsActions.getCampaignListRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignDetailsActions.cancelCampaignError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.cancelCampaignError(e));
  }
}

function* cancelSplitBatchCampaign(action) {
  try {
    const res = yield call(api.patch, endpoints.CANCEL_SPLIT_BATCH_CAMPAIGN, action.payload.body);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully Cancelled.");
      yield put(campaignDetailsActions.cancelSplitBatchCampaignSuccess(res.data));
      yield put(campaignDetailsActions.getCampaignListRequest(action.payload.loadingData));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignDetailsActions.cancelSplitBatchCampaignError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.cancelSplitBatchCampaignError(e));
  }
}

function* sendTestEmail(action) {
  try {
    const res = yield call(api.post, endpoints.SEND_TEST_EMAIL, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully sent test email to provided email address.");
    } else {
      dispatchSnackbarError(res.data.message);
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.sendTestEmailError(e));
  }
}
function* uploadEmailAttachement(action) {
  try {
    const res = yield call(api.post, endpoints.UPLOAD_EMAIL_ATTACHMENT, action.payload);
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.uploadEmailAttachementError(e));
  }
}

function* checkAudienceCount(action) {
  try {
    const res = yield call(api.post, endpoints.CHECK_AUDIENCE_COUNT, action.payload);
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.uploadEmailAttachementError(e));
  }
}

function* uploadTemporaryFile(action) {
  try {
    const res = yield call(api.post, endpoints.UPLOAD_TEMPORARY_FILE, action.payload);
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignDetailsActions.uploadTemporaryFileError(e));
  }
}
export default function* campaignDetailsSaga() {
  yield takeLatest(campaignTypes.getCampaignList.GET_CAMPAIGN_LIST_REQUEST, getListOfCampaign);
  yield takeLatest(campaignTypes.getCampaignList.GET_CAMPAIGN_LIST_REQUEST_AFTER, getListOfCampaign);
  yield takeLatest(campaignTypes.createCampaign.CREATE_CAMPAIGN_REQUEST, createCampaignFunc);

  yield takeLatest(campaignTypes.checkAudienceCount.CHECK_AUDIENCE_COUNT_REQUEST, checkAudienceCount);

  yield takeLatest(campaignTypes.rescheduledCamapign.RESCHEDULED_CAMPAIGN_REQUEST, rescheduledCamapign);
  yield takeLatest(campaignTypes.cancelCampaign.CANCEL_CAMPAIGN_REQUEST, cancelCampaign);
  yield takeLatest(campaignTypes.cancelSplitBatchCampaign.CANCEL_SPLIT_BATCH_CAMPAIGN_REQUEST, cancelSplitBatchCampaign);

  yield takeLatest(campaignTypes.sendTestEmail.SEND_TEST_EMAIL_REQUEST, sendTestEmail);
  yield takeLatest(campaignTypes.uploadEmailAttachement.UPLOAD_EMAIL_ATTACHMENT_REQUEST, uploadEmailAttachement);

  yield takeLatest(campaignTypes.uploadTemporaryFile.UPLOAD_TEMPORARY_FILE_REQUEST, uploadTemporaryFile);
}
