import styled from "styled-components";

export const Header = styled.div`
  // padding: 12px 16px;
  padding: 10px 8px;
  position: relative;
  border-radius: 3px 3px 0 0;

  h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold;

    span {
      color: #6a818c;
      margin-left: 5px;
      font-size: 85%;
    }
  }
`;

export const StageTitle = styled.h4`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const StageFooter = styled.div`
  border-radius: 0 0 3px 3px;
`;

export const AddNew = styled.a`
  display: block;
  color: #6a818c5c;
  padding: 8px 2px;
  position: relative;
  user-select: none;
  border-radius: 0 0 3px 3px;
  font-weight: 500;
  font-size: 15px;
  // color: #747678;
  // font-size: 13px;
  // padding: 10px 14px;

  &:hover {
    background: rgb(10 45 65 / 4%);
    cursor: pointer;
    color: gray;
  }

  svg {
    margin-right: 8px;
  }
`;

export const Body = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  // margin: 2px 4px;

  &:hover {
    overflow-y: auto;
  }
`;

export const StageRoot = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  transition: box-shadow 0.3s ease;
  background: #fff;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 5px 6px 5px;
  width: 280px;
  height: 483px;
  // height: 580px;
  transition: background-color 0.3s ease;
`;

export const FooterMenuOption = styled.div`
  width: 25%;
  background: ${({ isDraggingOver, item }) => (isDraggingOver ? (item === "Lost" ? "#FF6B6B" : item === "Won" ? "#6BCB77" : item === "Archive" ? "#FFD93D" : "#4D96FF") : "rgb(228 230 239)")};
  border: 1px solid #b4bcc0;
  padding: 12px 10px;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  margin: 8px;
  color: #26292c;
`;

export const Wrapper = styled.div`
  background-color: ${({ isDraggingOver }) => isDraggingOver && "rgba(192,192,192,0.2)"};
  display: flex;
  flex-direction: column;
  // padding: 0 4px;
  // transition: background-color 0.1s ease, opacity 0.1s ease;
  user-select: none;
  height: ${({ isDraggingOver }) => (isDraggingOver ? "calc(100vh - 75px - 166px)" : "calc(100vh - 75px - 166px)")};
  // width:${({ isDraggingOver }) => isDraggingOver && "300px"};
  .add-deal-footer{
    display: ${({ isDraggingOver }) => isDraggingOver && "none"};
  }
`;

export const DropZone = styled.div`
  // max-height: 630px;
  will-change: height;
  margin-top: 10px;
  height: 100%;
  width: ${({ isDraggingOver }) => isDraggingOver && "300px"};
`;

export const ActionButton = styled.div`
  padding: 4px 5px;
  margin-top: -4px;
  margin-right: -5px;
  border-radius: 2px;
  font-size: 15px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const PopoverList = styled.ul`
  max-height: 275px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  padding-bottom: 10px;
  padding-top: 10px;

  li {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 5px 20px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 13px;
    padding-right: ${(props) => props.selectable && "30px"};

    i {
      margin-right: 3.75px;
    }

    &:hover {
      background: #ffffff;
      cursor: pointer;
    }

    &:before {
      // font-family: 'Poppins', Helvetica, sans-serif;
      font-family: "erxes";
      font-size: 12px;
      width: 15px;
      height: 15px;
      z-index: 30;
      text-align: center;
      position: absolute;
      color: #373737;
      top: 80%;
      right: 11.25px;
      margin-top: -9px;
    }

    &.all:before {
      content: "\\ea3f" !important;
    }

    &.some:before {
      content: "\\ebe8";
    }
  }
`;

export const ActionList = styled(PopoverList)`
  min-width: 160px;
  li a {
    color: #444;
    text-transform: capitalize;
  }
`;
