import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Card, Button, OverlayTrigger, Popover, Form, Dropdown, Tooltip } from "react-bootstrap";
import { GreenGradientButton } from "../../../button";
import { LeadStateWrapper, StateItem } from "../../../../containers/deals/common/dealModal/styled";
import { InputLimit } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import * as dealActions from "../../../../containers/deals/dealPipeline/actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";

export function DealHeadDetails(props) {
  const [expectedDate, setExpectedDate] = useState(null);
  const [comments, setComments] = useState("");
  const [selectedLostReason, setSelectedLostReason] = useState("");
  const [selectedLostReasonId, setSelectedLostReasonId] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [selectedPipelineStages, setSelectedPipelineStage] = useState([]);

  const dispatch = useDispatch();

  const pipelineStageStore = useSelector((store) => store.DealPipeline.pipelineOptionsResponse);
  const dealLoadingStore = useSelector((state) => state.DealPipeline.loadingData);
  const dealListLoadingStore = useSelector((state) => state.DealList.loadingData);
  const pipelineStore = useSelector((state) => state.DealPipeline);

  useEffect(() => {
    if (pipelineStageStore && props.sidePanelProps.deal) {
      pipelineStageStore.forEach((each) => {
        if (each._id === props.sidePanelProps.deal.pipelineId) {
          setSelectedPipelineStage(each.stages);
        }
      });
    }
  }, [pipelineStageStore, props.sidePanelProps.deal]);

  useEffect(() => {
    if (props.sidePanelProps.deal && props.sidePanelProps.deal.stageId) {
      setCurrentState(props.sidePanelProps.deal.stageId);
    }
  }, [props.sidePanelProps.deal, selectedPipelineStages]);

  const onDatePopoverSave = () => {
    const data = {
      id: props.sidePanelProps && props.sidePanelProps.deal._id,
      title: props.sidePanelProps && props.sidePanelProps.deal.title,
      dealValue: props.sidePanelProps && props.sidePanelProps.deal.dealValue,
      expectedCloseDate: new Date(expectedDate).getTime(),
      pipelineId: props.sidePanelProps.deal.pipelineId,
    };
    const payload = {
      data: data,
      filter: props.pageForm === "dealList" ? dealListLoadingStore : dealLoadingStore,
      pageForm: props.pageForm,
    };
    dispatch(dealActions.changeDealDetailsInPopupRequest(payload));
    document.body.click();
  };

  const onLostReasonChange = (reason) => {
    setSelectedLostReason(reason.reason);
    setSelectedLostReasonId(reason._id);
  };

  const findIndex = () => {
    let i = 0;
    selectedPipelineStages.forEach((value, index) => {
      if (value._id === currentState) {
        i = index;
      }
    });
    return i;
  };

  const callStagesApi = (stageId) => {
    dispatch(
      dealActions.moveDealInStagesRequest({
        data: {
          pipelineId: props.sidePanelProps.deal.pipelineId,
          stageId: stageId,
          dealId: props.sidePanelProps.deal._id,
          order: 1,
        },
        type: "move-deal-in-stages",
        from: "sidepanel",
        pipelineId: props.sidePanelProps.deal.pipelineId,
        filter: props.pageForm === "dealList" ? dealListLoadingStore : dealLoadingStore,
        pageForm: props.pageForm,
      }),
    );
  };

  const onWonClick = (dealData) => {
    dispatch(
      dealActions.dealOperationRequest({
        data: {
          dealId: dealData._id,
        },
        type: "mark-as-won",
        from: "sidepanel",
        pipelineId: props.sidePanelProps.deal.pipelineId,
        filter: props.pageForm === "dealList" ? dealListLoadingStore : dealLoadingStore,
        pageForm: props.pageForm,
      }),
    );
  };

  const onLossDealSave = (dealId) => {
    if (!selectedLostReasonId) {
      dispatchSnackbarError("Please select lost reason!");
    }
    if (selectedLostReasonId !== "") {
      dispatch(
        dealActions.dealOperationRequest({
          data: {
            dealId: dealId,
            comment: comments,
            reasonId: selectedLostReasonId,
          },
          type: "mark-as-lost",
          from: "sidepanel",
          pipelineId: props.sidePanelProps.deal.pipelineId,
          filter: props.pageForm === "dealList" ? dealListLoadingStore : dealLoadingStore,
          pageForm: props.pageForm,
        }),
      );
      document.body.click();
    }
  };

  const datePopoverForm = () => {
    return (
      <Popover id="popover-basic" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">Expected Date</Popover.Title>
        <Popover.Content>
          <Form.Group id="deal-head-details-components-date-popover-form">
            <Form.Control type="date" value={expectedDate} onChange={(e) => setExpectedDate(e.target.value)}></Form.Control>
          </Form.Group>
          <div className="d-flex justify-content-center flex-row gap-15px mt-15px">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                document.body.click();
              }}
              size="sm"
            >
              Cancel
            </Button>
            <GreenGradientButton
              onClick={(e) => {
                e.stopPropagation();
                onDatePopoverSave(e);
              }}
              size="sm"
            >
              Save
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const popoverForm = (dealId) => {
    return (
      <Popover id="popover-basic" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">Lost Deal</Popover.Title>
        <Popover.Content>
          <Form.Group id="deal-head-details-components-popover-form-lost-reason" required>
            <Form.Label aria-label="lost-reason">Lost Reason</Form.Label>
            <Dropdown className="dropdown-inline mt-1 w-100">
              <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                {selectedLostReason}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu w-100-mn">
                {pipelineStore.lostReasons &&
                  pipelineStore.lostReasons.length > 0 &&
                  pipelineStore.lostReasons.map((lostReason) => (
                    <Dropdown.Item
                      className="dropdown-hover"
                      onClick={(e) => {
                        e.stopPropagation();
                        onLostReasonChange(lostReason);
                      }}
                    >
                      {lostReason.reason}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group id="deal-head-details-components-popover-form-comments" controlId="exampleForm.ControlTextarea1">
            <Form.Label aria-label="comments">Comments</Form.Label>
            <Form.Control maxLength={InputLimit.TEXTAREA} value={comments} onChange={(e) => setComments(e.target.value)} as="textarea" rows={3} />
          </Form.Group>
          <div className="d-flex justify-content-center flex-row gap-15px mt-15px">
            <Button
              variant="secondary"
              className="btn btn-sm btn-primary my-1 btn btn-secondary"
              onClick={(e) => {
                e.stopPropagation();
                document.body.click();
              }}
              size="sm"
            >
              Cancel
            </Button>
            <Button
              className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary"
              onClick={(e) => {
                e.stopPropagation();
                onLossDealSave(dealId);
              }}
              size="sm"
            >
              Mark as Lost
            </Button>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  if (_.isEmpty(props.sidePanelProps.deal)) return <></>;
  return (
    <Card className="panel-shadow mb-4">
      <Card.Body className="p-6">
        <LeadStateWrapper>
          {selectedPipelineStages.map((value, index) => {
            const onClick = () => {
              setCurrentState(value._id);
              callStagesApi(value._id);
            };
            const currentIndex = findIndex();
            return (
              <OverlayTrigger overlay={<Tooltip> {value?.name} </Tooltip>}>
                <StateItem key={index} past={index < currentIndex} active={index === currentIndex} onClick={onClick}>
                  <div> {index < currentIndex} </div>
                </StateItem>
              </OverlayTrigger>
            );
          })}
        </LeadStateWrapper>
      </Card.Body>
      <Card.Footer className="d-flex flex-stack px-6 py-3">
        <OverlayTrigger rootClose={true} trigger="click" id="expecteddatebutton" placement="bottom" overlay={datePopoverForm()}>
          <Button variant="link text-start" className="p-0 text-decoration-none">
            {props.sidePanelProps.deal.expectedCloseDate ? (
              <div className="d-flex align-items-center gap-2">
                <i className="text-primary bi bi-clock"></i>
                {moment(props.sidePanelProps.deal.expectedCloseDate).format("MMM Do YY")}
              </div>
            ) : (
              "Expected close date"
            )}
          </Button>
        </OverlayTrigger>
        <div className="d-flex gap-10px">
          {props.sidePanelProps.deal.status === "active" ? (
            <>
              <GreenGradientButton
                className="py-6px px-12px"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onWonClick(props.sidePanelProps.deal);
                }}
              >
                Won
              </GreenGradientButton>
              <OverlayTrigger rootClose={true} trigger="click" id="lostreasonbutton" placement="bottom" overlay={popoverForm(props.sidePanelProps && props.sidePanelProps.deal._id)}>
                <Button className="py-6px px-12px" variant="danger" size="sm">
                  Lost
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            <div className="d-flex align-items-center gap-2">
              Deal marked as <span className={`d-flex align-items-center gap-2 fw-bold ${props.sidePanelProps.deal.status === "won" ? "text-18944a" : "text-e34e6c"}`}>{props.sidePanelProps.deal.status}</span>
            </div>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
}
