import { getCadenceAllLead } from "../types/cadence-lead.types";
const initState = {
  data: [],
  count: 0,
  isLoading: false,
  error: false,
};

export default function subscriberListReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCadenceAllLead.GET_CADENCE_ALL_LEAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getCadenceAllLead.GET_CADENCE_ALL_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        count: action.payload.count,
      };
    case getCadenceAllLead.GET_CADENCE_ALL_LEAD_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
