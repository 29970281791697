import React from "react";
import { Dropdown } from "react-bootstrap";
import { IndiaSVG, USSVG } from "../../../../../../../components/custom-svgs/flag-svg";
import "./styles.scss";

export default function CurrencyInterval(props) {
  return (
    <div className="currency-interval-container">
      <div className="interval-wrapper">
        <div className={props.interval === "month" ? "selected-text" : "normal-text"}>Billed Monthly</div>
        <div className="form-check form-switch form-check-custom form-check-solid mr-5 pr-10">
          <input
            className="form-check-input h-30px w-50px bg-primary"
            type="checkbox"
            disabled={props.disabledInterval}
            checked={props.interval === "year"}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              if (e.target.checked) props.setPlanInterval("year");
              else props.setPlanInterval("month");
            }}
            id="flexSwitch20x30"
          />
        </div>
        <div className={props.interval === "year" ? "selected-text" : "normal-text"}>Billed Yearly (save 15%)</div>
      </div>
      <div className="currency-wrapper">
        <div className="normal-text">Choose Your Currecny</div>
        <div>
          <Dropdown className="mt-0">
            <Dropdown.Toggle variant="light" className="custom-dropdown-toggle" disabled={props.disabledCurreny} s>
              {props.currency === "inr" ? <span>{IndiaSVG} INR </span> : <span>{USSVG} USD</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100-mn">
              <div className="h-25vh-mx position-relative overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                <Dropdown.Item
                  className="dropdown-hover custom-dropdown-item"
                  onSelect={(e) => {
                    props.setCurrency("inr");
                    props.setCurrencySymbol("₹");
                  }}
                >
                  {IndiaSVG} INR{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-hover custom-dropdown-item"
                  onSelect={(e) => {
                    props.setCurrency("usd");
                    props.setCurrencySymbol("$");
                  }}
                >
                  {USSVG} USD{" "}
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
