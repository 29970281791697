export const getListOfSalesForecast = {
  GET_LIST_OF_SALES_FORECAST_REQUEST: "GET_LIST_OF_SALES_FORECAST_REQUEST",
  GET_LIST_OF_SALES_FORECAST_SUCCESS: "GET_LIST_OF_SALES_FORECAST_SUCCESS",
  GET_LIST_OF_SALES_FORECAST_ERROR: "GET_LIST_OF_SALES_FORECAST_ERROR",
};

export const getListOfSalesForecastLoadMore = {
  GET_LIST_OF_SALES_FORECAST_LOAD_MORE_REQUEST: "GET_LIST_OF_SALES_FORECAST_LOAD_MORE_REQUEST",
  GET_LIST_OF_SALES_FORECAST_LOAD_MORE_SUCCESS: "GET_LIST_OF_SALES_FORECAST_LOAD_MORE_SUCCESS",
  GET_LIST_OF_SALES_FORECAST_LOAD_MORE_ERROR: "GET_LIST_OF_SALES_FORECAST_LOAD_MORE_ERROR",
};

export const createSalesForecast = {
  CREATE_SALES_FORECAST_REQUEST: "CREATE_SALES_FORECAST_REQUEST",
  CREATE_SALES_FORECAST_SUCCESS: "CREATE_SALES_FORECAST_SUCCESS",
  CREATE_SALES_FORECAST_ERROR: "CREATE_SALES_FORECAST_ERROR",
};

export const editSalesForecast = {
  EDIT_SALES_FORECAST_REQUEST: "EDIT_SALES_FORECAST_REQUEST",
  EDIT_SALES_FORECAST_SUCCESS: "EDIT_SALES_FORECAST_SUCCESS",
  EDIT_SALES_FORECAST_ERROR: "EDIT_SALES_FORECAST_ERROR",
};

export const deleteSalesForecast = {
  DELETE_SALES_FORECAST_REQUEST: "DELETE_SALES_FORECAST_REQUEST",
  DELETE_SALES_FORECAST_SUCCESS: "DELETE_SALES_FORECAST_SUCCESS",
  DELETE_SALES_FORECAST_ERROR: "DELETE_SALES_FORECAST_ERROR",
};

export const getSalesForecastGraph = {
  GET_SALES_FORECAST_GRAPH_REQUEST: "GET_SALES_FORECAST_GRAPH_REQUEST",
  GET_SALES_FORECAST_GRAPH_SUCCESS: "GET_SALES_FORECAST_GRAPH_SUCCESS",
  GET_SALES_FORECAST_GRAPH_ERROR: "GET_SALES_FORECAST_GRAPH_ERROR",
};
