import {
  getListOfMarketingList,
  marketingUpdate,
  editMarketingList,
  addMarketingList,
  deleteMarketingList,
  LOADING_DATA_UPDATE,
  getListOfMarketingListTags,
  createMarketingListTags,
  updateMarketingListTags,
  deleteMarketingListTags,
  getSavedMarketingFilters,
  createSavedMarketingFilters,
  editSavedMarketingFilters,
  deleteSavedMarketingFilters,
  markFavoriteMarketingFilters,
  getSelectedMarketingData,
  getMarketingLogs,
  bulkDeleteMarketingList,
  generateMarketingAIWFData
} from "../types/campaign_marketing_list_types";

export function getListOfMarketingListRequest(req) {
  return {
    type: getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_REQUEST,
    payload: req,
  };
}
export function getListOfMarketingListSuccess(res) {
  return {
    type: getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_SUCCESS,
    payload: res,
  };
}
export function getListOfMarketingListError(err) {
  return {
    type: getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_ERROR,
    payload: err,
  };
}
export function getListOfMarketingListRequestAfter(req) {
  return {
    type: getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_REQUEST_AFTER,
    payload: req,
  };
}

// MARKTING UPDATE
export function marketingUpdateRequest(req) {
  return {
    type: marketingUpdate.MARKETIING_UPDATE_REQUEST,
    payload: req,
  };
}
export function marketingUpdateSuccess(res) {
  return {
    type: marketingUpdate.MARKETIING_UPDATE_SUCCESS,
    payload: res,
  };
}
export function marketingUpdateError(err) {
  return {
    type: marketingUpdate.MARKETIING_UPDATE_ERROR,
    payload: err,
  };
}

export function getSelectedMarketingDataRequest(req) {
  return {
    type: getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_REQUEST,
    payload: req,
  };
}
export function getSelectedMarketingDataSuccess(res) {
  return {
    type: getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_SUCCESS,
    payload: res,
  };
}
export function getSelectedMarketingDataError(err) {
  return {
    type: getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_ERROR,
    payload: err,
  };
}

export function editMarketingListRequest(req) {
  return {
    type: editMarketingList.EDIT_MARKETING_LIST_REQUEST,
    payload: req,
  };
}
export function editMarketingListSuccess(res) {
  return {
    type: editMarketingList.EDIT_MARKETING_LIST_SUCCESS,
    payload: res,
  };
}
export function editMarketingListError(err) {
  return {
    type: editMarketingList.EDIT_MARKETING_LIST_ERROR,
    payload: err,
  };
}

export function addMarketingListRequest(req, cb) {
  return {
    type: addMarketingList.ADD_MARKETING_LIST_REQUEST,
    payload: req,
    cb,
  };
}
export function addMarketingListSuccess(res) {
  return {
    type: addMarketingList.ADD_MARKETING_LIST_SUCCESS,
    payload: res,
  };
}
export function addMarketingListError(err) {
  return {
    type: addMarketingList.ADD_MARKETING_LIST_ERROR,
    payload: err,
  };
}

export function deleteMarketingListRequest(req) {
  return {
    type: deleteMarketingList.DELETE_MARKETING_LIST_REQUEST,
    payload: req,
  };
}
export function deleteMarketingListSuccess(res) {
  return {
    type: deleteMarketingList.DELETE_MARKETING_LIST_SUCCESS,
    payload: res,
  };
}
export function deleteMarketingListError(err) {
  return {
    type: deleteMarketingList.DELETE_MARKETING_LIST_ERROR,
    payload: err,
  };
}

export function bulkDeleteMarketingListRequest(req) {
  return {
    type: bulkDeleteMarketingList.BULK_DELETE_MARKETING_LIST_REQUEST,
    payload: req,
  };
}
export function bulkDeleteMarketingListSuccess(res) {
  return {
    type: bulkDeleteMarketingList.BULK_DELETE_MARKETING_LIST_SUCCESS,
    payload: res,
  };
}
export function bulkDeleteMarketingListError(err) {
  return {
    type: bulkDeleteMarketingList.BULK_DELETE_MARKETING_LIST_ERROR,
    payload: err,
  };
}

export function loadingDataUpdateRequest(req) {
  return {
    type: LOADING_DATA_UPDATE,
    payload: req,
  };
}

// Tags
export function getListOfMarketingListTagsRequest(req) {
  return {
    type: getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_REQUEST,
    payload: req,
  };
}
export function getListOfMarketingListTagsSuccess(res) {
  return {
    type: getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_SUCCESS,
    payload: res,
  };
}
export function getListOfMarketingListTagsError(err) {
  return {
    type: getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_ERROR,
    payload: err,
  };
}

export function createMarketingListTagsRequest(req) {
  return {
    type: createMarketingListTags.CREATE_MARKETING_LIST_TAGS_REQUEST,
    payload: req,
  };
}
export function createMarketingListTagsSuccess(res) {
  return {
    type: createMarketingListTags.CREATE_MARKETING_LIST_TAGS_SUCCESS,
    payload: res,
  };
}
export function createMarketingListTagsError(err) {
  return {
    type: createMarketingListTags.CREATE_MARKETING_LIST_TAGS_ERROR,
    payload: err,
  };
}

export function updateMarketingListTagsRequest(req) {
  return {
    type: updateMarketingListTags.UPDATE_MARKETING_LIST_TAGS_REQUEST,
    payload: req,
  };
}
export function updateMarketingListTagsSuccess(res) {
  return {
    type: updateMarketingListTags.UPDATE_MARKETING_LIST_TAGS_SUCCESS,
    payload: res,
  };
}
export function updateMarketingListTagsError(err) {
  return {
    type: updateMarketingListTags.UPDATE_MARKETING_LIST_TAGS_ERROR,
    payload: err,
  };
}

export function deleteMarketingListTagsRequest(req) {
  return {
    type: deleteMarketingListTags.DELETE_MARKETING_LIST_TAGS_REQUEST,
    payload: req,
  };
}
export function deleteMarketingListTagsSuccess(res) {
  return {
    type: deleteMarketingListTags.DELETE_MARKETING_LIST_TAGS_SUCCESS,
    payload: res,
  };
}
export function deleteMarketingListTagsError(err) {
  return {
    type: deleteMarketingListTags.DELETE_MARKETING_LIST_TAGS_ERROR,
    payload: err,
  };
}

// Filters
export function getSavedMarketingFiltersRequest(req) {
  return {
    type: getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_REQUEST,
    payload: req,
  };
}
export function getSavedMarketingFiltersSuccess(res) {
  return {
    type: getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_SUCCESS,
    payload: res,
  };
}
export function getSavedMarketingFiltersError(err) {
  return {
    type: getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_ERROR,
    payload: err,
  };
}

export function createSavedMarketingFiltersRequest(req) {
  return {
    type: createSavedMarketingFilters.CREATE_SAVED_MARKETING_FILTERS_REQUEST,
    payload: req,
  };
}
export function createSavedMarketingFiltersSuccess(res) {
  return {
    type: createSavedMarketingFilters.CREATE_SAVED_MARKETING_FILTERS_SUCCESS,
    payload: res,
  };
}
export function createSavedMarketingFiltersError(err) {
  return {
    type: createSavedMarketingFilters.CREATE_SAVED_MARKETING_FILTERS_ERROR,
    payload: err,
  };
}

export function editSavedMarketingFiltersRequest(req) {
  return {
    type: editSavedMarketingFilters.EDIT_SAVED_MARKETING_FILTERS_REQUEST,
    payload: req,
  };
}
export function editSavedMarketingFiltersSuccess(res) {
  return {
    type: editSavedMarketingFilters.EDIT_SAVED_MARKETING_FILTERS_SUCCESS,
    payload: res,
  };
}
export function editSavedMarketingFiltersError(err) {
  return {
    type: editSavedMarketingFilters.EDIT_SAVED_MARKETING_FILTERS_ERROR,
    payload: err,
  };
}

export function deleteSavedMarketingFiltersRequest(req) {
  return {
    type: deleteSavedMarketingFilters.DELETE_SAVED_MARKETING_FILTERS_REQUEST,
    payload: req,
  };
}
export function deleteSavedMarketingFiltersSuccess(res) {
  return {
    type: deleteSavedMarketingFilters.DELETE_SAVED_MARKETING_FILTERS_SUCCESS,
    payload: res,
  };
}
export function deleteSavedMarketingFiltersError(err) {
  return {
    type: deleteSavedMarketingFilters.DELETE_SAVED_MARKETING_FILTERS_REQUEST,
    payload: err,
  };
}

export function markFavoriteMarketingFiltersRequest(req) {
  return {
    type: markFavoriteMarketingFilters.MARK_FAVORITE_MARKETING_FILTERS_REQUEST,
    payload: req,
  };
}
export function markFavoriteMarketingFiltersSuccess(res) {
  return {
    type: markFavoriteMarketingFilters.MARK_FAVORITE_MARKETING_FILTERS_SUCCESS,
    payload: res,
  };
}
export function markFavoriteMarketingFiltersError(err) {
  return {
    type: markFavoriteMarketingFilters.MARK_FAVORITE_MARKETING_FILTERS_ERROR,
    payload: err,
  };
}

export function getMarketingLogsRequest(req) {
  return {
    type: getMarketingLogs.GET_MARKETING_LOGS_REQUEST,
    payload: req,
  };
}
export function getMarketingLogsSuccess(res) {
  return {
    type: getMarketingLogs.GET_MARKETING_LOGS_SUCCESS,
    payload: res,
  };
}
export function getMarketingLogsLoadMoreRequest(req) {
  return {
    type: getMarketingLogs.GET_MARKETING_LOGS_LOAD_MORE_REQUEST,
    payload: req,
  };
}
export function getMarketingLogsLoadMoreSuccess(res) {
  return {
    type: getMarketingLogs.GET_MARKETING_LOGS_LOAD_MORE_SUCCESS,
    payload: res,
  };
}
export function getMarketingLogsError(err) {
  return {
    type: getMarketingLogs.GET_MARKETING_LOGS_ERROR,
    payload: err,
  };
}


export const generateMarketingAIWFDataRequest = (req) => ({
  type: generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_REQUEST,
  payload: req,
});
export const generateMarketingAIWFDataSuccess = (res) => ({
  type: generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_SUCCESS,
  payload: res,
});
export const generateMarketingAIWFDataError = (err) => ({
  type: generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_ERROR,
  payload: err,
});