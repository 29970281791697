import {
  // tags settings page
  GET_TAGS_IN_SETTINGS_REQUEST,
  GET_TAGS_IN_SETTINGS_SUCCESS,
  GET_TAGS_IN_SETTINGS_ERROR,
  // ADD_TAGS_IN_SETTINGS_REQUEST,
  // ADD_TAGS_IN_SETTINGS_SUCCESS,
  // ADD_TAGS_IN_SETTINGS_ERROR,
  // DELETE_TAGS_IN_SETTINGS_REQUEST,
  // DELETE_TAGS_IN_SETTINGS_SUCCESS,
  // DELETE_TAGS_IN_SETTINGS_ERROR,
  // BULK_DELETE_TAGS_IN_SETTINGS_REQUEST,
  // BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS,
  // BULK_DELETE_TAGS_IN_SETTINGS_ERROR,
  // EDIT_TAGS_IN_SETTINGS_REQUEST,
  // EDIT_TAGS_IN_SETTINGS_SUCCESS,
  // EDIT_TAGS_IN_SETTINGS_ERROR
} from "../types/admin-settings.types";

const initialState = {
  tagsSettings: {
    list: [],
    loading: false,
    error: "",
    count: 0,
  },
};

export function adminSettingsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TAGS_IN_SETTINGS_REQUEST:
      return {
        ...state,
        tagsSettings: {
          ...state.tagsSettings,
          loading: true,
        },
      };
    case GET_TAGS_IN_SETTINGS_SUCCESS:
      return {
        ...state,
        tagsSettings: {
          ...state.tagsSettings,
          loading: false,
          list: action.payload.data,
          count: action.payload.count,
        },
      };
    case GET_TAGS_IN_SETTINGS_ERROR:
      return {
        ...state,
        tagsSettings: {
          ...state.tagsSettings,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
