import React from "react";
import { Form } from "react-bootstrap";
import RichTextEditor from "../../../components/RichTextEditor/tinymce-editor";

export default function ThankYouMessage(props) {
  return (
    <>
      {/* <Form.Group >
            <Form.Label >Link of Image</Form.Label>
            <Form.Control value={props.data.mediaUrl} onChange={e => props.setValue("mediaUrl", e.target.value)}></Form.Control>
        </Form.Group> */}
      <Form.Group id="leadbot-components-thank-you-message-builder-enter-text">
        <Form.Label aria-label="enter-text">Enter Text</Form.Label>
        <div className="card h-300px">
          <RichTextEditor
            // defaultValue={defualtValue}
            value={props.data.descrption}
            onChange={(c) => {
              props.setValue("descrption", c);
            }}
          />
        </div>
      </Form.Group>
    </>
  );
}
