import React from "react";
import { ButtonGroup, Dropdown, Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

export default function SplitDropdownNav({ link, active, activeIcon, handleNavLinkClick }) {
  const history = useHistory();

  const handleButtonOnClick = (e, to) => {
    e.preventDefault();
    e.stopPropagation();
    if (!link.subTabNames.includes(active)) history.push(to);
  };

  return (
    <Dropdown className="h-100" as={ButtonGroup}>
      <Button onClick={(e) => handleButtonOnClick(e, link.to)} className={`text-decoration-none h-100 menu-link ${link.subTabNames.includes(active) ? "active" : ""}`} variant="link">
        {link.subTabNames.includes(active) && activeIcon ? (
          <span className="menu-icon mr-0 ml-4">
            <span className="svg-icon svg-icon-2">
              {" "}
              <i className={`${activeIcon}`}></i>
            </span>
          </span>
        ) : (
          (link.icon || link.svg) && (
            <span className="menu-icon mr-0 ml-4">
              <span className="svg-icon svg-icon-2">{link.svg ? link.svg : <i className={`${link.icon}`}></i>}</span>
            </span>
          )
        )}
        <span className="menu-title pl--1 mr-2">{link.subTabNames.includes(active) ? active : link.name}</span>
      </Button>
      <Dropdown.Toggle split className={`text-decoration-none d-flex flex-center w-30px h-100 menu-link ${link.subTabNames.includes(active) ? "active" : ""}`} variant="link" />
      <Dropdown.Menu>
        {link.options.map((each, ind) => {
          return (
            <Dropdown.Item className="p-0 m-0" key={ind}>
              <NavLink onClick={() => handleNavLinkClick(each.to, each.target)} className={`menu-link py-3 ${active === each.name ? "active" : ""}`} to={each.to} rel="noopener noreferrer" target={each.target}>
                {(each.icon || each.svg) && (
                  <span className="menu-icon mr-2">
                    <span className="svg-icon svg-icon-2">{each.svg ? each.svg : <i className={`${each.icon}`}></i>}</span>
                  </span>
                )}
                <span className="menu-title">{each.name}</span>
              </NavLink>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
