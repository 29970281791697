import React, { useState } from "react";

export default function NormalTable(props) {
  const [heading] = useState(props.tableHeading);
  const [tableTitle] = useState(props.tableTitle);

  return (
    <>
      <div className="card mb-5 mb-xl-10 overflow-y-auto h-vh-200px-mx border-style-hidden">
        {props.description ? (
          <div className="flex-column px-8 py-6 border-bottom">
            <div className="card-title pb-0 mb-2">{props.customTitle ? tableTitle : <h3 className="fw-bolder m-0">{tableTitle}</h3>}</div>
            <div className="card-toolbar">{props.description}</div>
          </div>
        ) : (
          <div className={`card-header d-${props.ddsplay}`}>
            <div className="card-title">{props.customTitle ? tableTitle : <h3 className="fw-bolder m-0">{tableTitle}</h3>}</div>
            <div className="card-toolbar">{props.buttonHead}</div>
          </div>
        )}
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
              <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  <td className={`${props.applyWidth ? "" : "w-25"}`}>{heading[0]}</td>
                  <td className={`${props.applyWidth ? "" : "w-25"}`}>{heading[1]}</td>
                  <td className={`${props.applyWidth ? "" : "w-25"}`}>{heading[2]}</td>
                  <td className={`${props.applyWidth ? "" : "w-25"}`}>{heading[3]}</td>
                  <td className={`${props.applyWidth ? "" : "w-25"}`}>{heading[4]}</td>
                </tr>
              </thead>
              <tbody className="fw-6 fw-bold text-gray-600">{props.children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
