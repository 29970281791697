export const GET_TAGS_IN_SETTINGS_REQUEST = "GET_TAGS_IN_SETTINGS_REQUEST";
export const GET_TAGS_IN_SETTINGS_SUCCESS = "GET_TAGS_IN_SETTINGS_SUCCESS";
export const GET_TAGS_IN_SETTINGS_ERROR = "GET_TAGS_IN_SETTINGS_ERROR";

export const ADD_TAGS_IN_SETTINGS_REQUEST = "ADD_TAGS_IN_SETTINGS_REQUEST";
export const ADD_TAGS_IN_SETTINGS_SUCCESS = "ADD_TAGS_IN_SETTINGS_SUCCESS";
export const ADD_TAGS_IN_SETTINGS_ERROR = "ADD_TAGS_IN_SETTINGS_ERROR";

export const DELETE_TAGS_IN_SETTINGS_REQUEST = "DELETE_TAGS_IN_SETTINGS_REQUEST";
export const DELETE_TAGS_IN_SETTINGS_SUCCESS = "DELETE_TAGS_IN_SETTINGS_SUCCESS";
export const DELETE_TAGS_IN_SETTINGS_ERROR = "DELETE_TAGS_IN_SETTINGS_ERROR";

export const BULK_DELETE_TAGS_IN_SETTINGS_REQUEST = "BULK_DELETE_TAGS_IN_SETTINGS_REQUEST";
export const BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS = "BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS";
export const BULK_DELETE_TAGS_IN_SETTINGS_ERROR = "BULK_DELETE_TAGS_IN_SETTINGS_ERROR";

export const EDIT_TAGS_IN_SETTINGS_REQUEST = "EDIT_TAGS_IN_SETTINGS_REQUEST";
export const EDIT_TAGS_IN_SETTINGS_SUCCESS = "EDIT_TAGS_IN_SETTINGS_SUCCESS";
export const EDIT_TAGS_IN_SETTINGS_ERROR = "EDIT_TAGS_IN_SETTINGS_ERROR";
