import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { companiesDropdownRequest, companiesDropdownAddRequest, companiesDropdownAddClear } from '../../containers/companies/store/actions/actions';
import { dispatchSnackbarError } from "../../utils/toaster";
import SearchSelect from "./searchSelect";
// import { useDispatch } from 'react-redux';
import { searchUnlinkedDataForCompanySidepanelRequest } from "../../containers/companies/store/actions/actions";

export default function LeadDealPeopleSearchSelect({ field, value, setValue, dontShowAdd, valueLabel, companyId, type }) {
  // const dispatch = useDispatch()
  const [newSelectedLinkedName, setNewSelectedLinkedName] = useState("");
  const linkedDataStore = useSelector((state) => state.Companies.searchLinkedDataList);
  // const newLinkedDataStore = useSelector(state => state.Companies.newCompany)
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    //   if (!newLinkedDataStore)
    setSelected({
      label: linkedDataStore.list.find((company) => company["id"] === value) ? linkedDataStore.list.find((company) => company["id"] === value).name : valueLabel ? valueLabel : null,
      value: value,
    });
  }, [linkedDataStore, value, valueLabel]);

  useEffect(() => {
    if (value) {
      setSelected({
        label: linkedDataStore.list.find((company) => company["id"] === value) ? linkedDataStore.list.find((company) => company["id"] === value).name : valueLabel ? valueLabel : null,
        value: value,
      });
    }
  }, [linkedDataStore, value, valueLabel]);

  // useEffect(() => {
  //   if (newLinkedDataStore) {
  //     setValue(newLinkedDataStore.id, field.apiKeyName, newLinkedDataStore.name)
  //     dispatchSnackbarSuccess("Company Added Successfuly")
  //     setSelected({
  //       label: newLinkedDataStore.name,
  //       value: newLinkedDataStore.id
  //     })
  //     // dispatch(companiesDropdownAddClear())
  //   }
  // }, [newLinkedDataStore, dispatch, field, setValue])

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < linkedDataStore.list.length; i++) temp.push({ value: linkedDataStore.list[i]["id"], label: linkedDataStore.list[i]["name"] });
    //   !dontShowAdd && temp.push({ value: "new", label: "+ Add " + newCompanyName + ` as new ${field.apiKeyName}` })
    setOptions(temp);
  }, [linkedDataStore, newSelectedLinkedName, dontShowAdd, field.apiKeyName]);

  const handleSave = () => {
    setIsNew(true);
    if (newSelectedLinkedName.trim() === "") {
      dispatchSnackbarError("Name can not be blank!");
      return;
    }
    //   dontShowAdd ? dispatch(null) : dispatch(companiesDropdownAddRequest({ name: newCompanyName }))
  };
  const setValueA = (a) => {
    if (!a) {
      return setValue("", field.apiKeyName, "");
    }
    for (var i = 0; i < linkedDataStore.list.length; i++) {
      if (linkedDataStore.list[i].id === a) {
        setValue(a, field.apiKeyName, linkedDataStore.list[i].name);
      }
    }
  };

  return (
    <>
      <SearchSelect
        setIsNew={setIsNew}
        isNew={isNew}
        options={options}
        selected={selected}
        setValue={setValueA}
        handleSave={handleSave}
        setSearch={setNewSelectedLinkedName}
        field={field}
        type={type}
        companyId={companyId}
        loading={linkedDataStore.loading}
        request={searchUnlinkedDataForCompanySidepanelRequest}
      />
    </>
  );
}
