import SortableList from "./common/sortableList/sortableList";
import React from "react";
import { StageList, LinkButton } from "./styled";
import StageItem from "./stageItem";
import AddIcon from "@material-ui/icons/Add";

class Stages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: this.props.stages && this.props.stages.length,
      newStageAdded: false,
    };
  }

  componentDidMount() {
    if (this.props.stages.length === 0) {
      this.add();
    }
  }

  onChange = (stageId, name, value) => {
    const { stages, onChangeStages } = this.props;

    const stage = stages.find((s) => s._id === stageId);
    stage[name] = value;
    if (this.state.newStageAdded) {
      if (stage !== undefined) {
        if (stage.name !== undefined && stage.probability !== undefined && stage.status !== undefined) {
          this.props.onNewStageAdd(stage);
          this.setState({ newStageAdded: false });
        }
      }
    } else {
      this.props.updatePipelineStage(stage);
    }

    onChangeStages(stages);
  };

  add = () => {
    const { stages, onChangeStages, type } = this.props;
    this.props.stages.push({
      _id: Math.random().toString(),
      name: "",
      type,
      status: "active",
      probability: 100,
      order: this.state.count + 1,
      color: this.getColor(),
    });
    onChangeStages(stages);

    this.setState({ count: this.state.count + 1 });
    this.setState({ newStageAdded: true });
  };

  getColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  remove = (stageId) => {
    return this.props.onDeleteClick(stageId);
    // const { stages, onChangeStages, onRemoveStage } = this.props;

    // const removedStage = stages.filter(stage => stage._id === stageId)

    // const remainedStages = stages.filter(stage => stage._id !== stageId);

    // onChangeStages(remainedStages);
    // if (stageId.includes(".")) {
    //   return null
    // }
    // else {
    //   onRemoveStage(removedStage);
    // }
    // this.setState({ newStageAdded: false })
  };

  onStageInputKeyPress = (e) => {
    if (e.key === "Enter") {
      this.add();
      e.preventDefault();
    }
  };

  render() {
    const { options, type } = this.props;
    const Item = options ? options.StageItem : StageItem;

    const child = (stage) => <Item stage={stage} type={type} onChange={this.onChange} remove={this.remove} onKeyPress={this.onStageInputKeyPress} stages={this.props.stages} />;

    return (
      <StageList>
        <SortableList fields={this.props.stages} child={child} onChangeFields={this.props.onChangeStages} isModal={true} droppableId="stages" onUpdateStageOrder={this.props.onUpdateStageOrder} />

        <LinkButton onClick={() => this.add()}>
          <AddIcon className="font-size-18px" /> {"Add another stage"}
        </LinkButton>
      </StageList>
    );
  }
}

export default Stages;
