import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTableWithoutAction } from "../../../../../components/custom-tables/table-without-actions";
import { ContentLoadingWithoutCard } from "../../../../../components/loading";
import { getEmailCampaignSummaryReportRequest } from "../../../store/actions/campaign_report_actions";
import ContentWrapper from "../../../../Wrapper";
import CampaignSubHeader from "../../../components/campaign-common-sub-header";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import SummaryReportFilter from "../components/summary-report-filter";
import { campaignOptionObj } from "../../../constants";
import { CSVLink } from "react-csv";

export const campaingTableFields = {
  email: ["Date", "Total Sent", "Opened", "Clicked", "Bounce", "Complaint", "Unsubscribed", "Failed", "Cost"],
  // sms: ["Date", "Total Sent", "Delivered", "Undelivered", "Delivered", "Undelivered", "Cost"],
  sms: ["Date", "Total Audience", "Total SMS Sent", "Delivered", "Submitted", "Undelivered", "Cost"],


  whatsapp: ["Date", "Total Audience", "Audience Delivered", "Read", "Failed", "Deleted", "Total", "Cost"],
  rcs: ["Date", "Sent", "Total", "Cost"],
};
export const campaignTableMapping = {
  Date: "date",
  "Total Sent": "sent",
  Opened: "opened",
  Clicked: "clicked",
  Bounce: "bounce",
  Complaint: "complaint",
  Unsubscribed: "unsubscribed",
  Total: "total",
  Failed: "failed",
  Cost: "cost",
  "Total Audience": "sent",
  "Audience Delivered": "delivered",
  "Audience Submitted": "submitted",
  "Audience Undelivered": "undelivered",
  "Total SMS Sent": "smsSent",
  "Read": "read",
  "Faioled": "failed",
  "Deleted": "deleted",
  Delivered: "smsDelivered",
  Submitted: "smsSubmitted",
  Undelivered: "smsUndelivered",
};

export const campaignTableFieldDataType = {
  date: "TEXT",
  sent: "TEXT",
  opened: "TEXT",
  clicked: "TEXT",
  bounce: "TEXT",
  complaint: "TEXT",
  unsubscribed: "TEXT",
  total: "TEXT",
  failed: "TEXT",
  cost: "TEXT",
  delivered: "TEXT",
  undelivered: "TEXT",
  smsSent: "TEXT",
  smsDelivered: "TEXT",
  smsUndelivered: "TEXT",
};

export default function SummaryReport(props) {
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [channel, setChannel] = useState("email");
  const [type, setType] = useState("all");
  const [updatedData, setUpdatedData] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment()
      .startOf("days")
      .valueOf(),
  );
  const [toDate, setToDate] = useState(
    moment()
      .add(1, "day")
      .endOf("day")
      .valueOf(),
  );
  const [initalFilterValue, setInitialFilterValue] = useState({
    channel: "email",
    type: "all",
    fromDate: moment()
      .startOf("days")
      .valueOf(),
    toDate: moment()
      .add(1, "day")
      .endOf("day")
      .valueOf(),
  });

  const dispatch = useDispatch();

  const emailSummaryReportStore = useSelector((store) => store.campaign.reports.email.summary);

  useEffect(() => {
    if (fromDate && toDate) {
      dispatch(getEmailCampaignSummaryReportRequest({ startDate: fromDate, endDate: toDate, channel: channel, type: type }));
    }
  }, [dispatch, fromDate, toDate, channel, type]);

  useEffect(() => {
    const campaignChannel = localStorage.getItem("campaign-channel");
    const campaignType = localStorage.getItem("campaign-type");
    if (campaignChannel && campaignType) {
      setChannel(campaignChannel);
      setType(campaignType);
      setInitialFilterValue({
        channel: campaignChannel,
        type: campaignType,
        fromDate: moment()
          .startOf("days")
          .valueOf(),
        toDate: moment()
          .add(1, "day")
          .endOf("day")
          .valueOf(),
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(emailSummaryReportStore.data)) {
      const options = [],
        total = {
          sent: 0,
          opened: 0,
          clicked: 0,
          bounce: 0,
          complaint: 0,
          unsubscribed: 0,
          total: 0,
          failed: 0,
          cost: 0,
          smsSent: 0,
          smsSent2: 0,
          smsDelivered: 0,
          smsSubmitted: 0,
          smsUndelivered: 0,
          read: 0,
          deleted: 0,
          delivered: 0,
        };
      emailSummaryReportStore.data
        .map((each) => ({ ...each, smsSent2: each.smsSent }))
        .forEach((each) => {
          options.push(each);
          for (let key in each) {
            if (total.hasOwnProperty(key)) {
              total[key] = _.round(total[key] + each[key], 4);
            }
            if (key === "opened" || key === "clicked" || key === "bounce" || key === "complaint" || key === "unsubscribed" || key === "read" || key === "deleted" || key === "failed") {
              each[key] = `${each[key]} (${Math.round((each[key] / each.sent) * 100)}%)`;
            } else if (key === "smsSent" || key === "smsDelivered" || key === "smsSubmitted" || key === "smsUndelivered") {
              each[key] = `${each[key]} (${Math.round((each[key] / each.smsSent2) * 100)}%)`;
            }
          }
        });
      for (let key in total) {
        if (key === "opened" || key === "clicked" || key === "bounce" || key === "complaint" || key === "unsubscribed" || key === "read" || key === "deleted" || key === "failed") {
          total[key] = `${total[key]} (${Math.round((total[key] / total.sent) * 100)}%)`;
        } else if (key === "smsSent" || key === "smsDelivered" || key === "smsSubmitted" || key === "smsUndelivered") {
          total[key] = `${total[key]} (${Math.round((total[key] / total.smsSent2) * 100)}%)`;
        }
      }
      options.push(total);
      setUpdatedData(options);
    }
  }, [emailSummaryReportStore.data]);

  const handleApplyFilter = (data) => {
    setChannel(data.channel);
    setType(data.type);
    setFromDate(data.fromDate);
    setToDate(data.toDate);
    setShow(false);
    setPageNo(1);
    setLimit(15);
    setInitialFilterValue(data);
    localStorage.setItem("campaign-channel", data.channel);
    localStorage.setItem("campaign-type", data.type);
  };

  const handleCancelFilter = () => {
    setShow(false);
  };
  const headers = {
    email: [
      { label: "Date", key: "date" },
      { label: "Total Sent", key: "sent" },
      { label: "Opened", key: "opened" },
      { label: "Clicked", key: "clicked" },
      { label: "Bounce", key: "bounce" },
      { label: "Complaint", key: "complaint" },
      { label: "Unsubscribed", key: "unsubscribed" },
      { label: "Failed", key: "failed" },
      { label: "Cost", key: "cost" },
    ],
    sms: [
      { label: "Date", key: "date" },
      { label: "Total Audience", key: "sent" },
      { label: "Total SMS Sent", key: "smsSent" },
      { label: "Delivered", key: "smsDelivered" },
      { label: "Submitted", key: "smsSubmitted" },
      { label: "Undelivered", key: "smsUndelivered" },
      { label: "Cost", key: "cost" },
    ],
    whatsapp: [
      { label: "Date", key: "date" },
      { label: "Sent", key: "sent" },
      { label: "Total", key: "total" },
      { label: "Cost", key: "cost" },
    ],
    rcs: [
      { label: "Date", key: "date" },
      { label: "Sent", key: "sent" },
      { label: "Total", key: "total" },
      { label: "Cost", key: "cost" },
    ],
  };

  const csvReport = {
    data: updatedData,
    headers: headers[channel],
    filename: "summary_report.csv",
  };
  return (
    <ContentWrapper
      subHeader={
        <CampaignSubHeader active={props.tabName}>
          <div className="d-flex gap-3 align-items-center">
            {type && <div className="badge badge-light-primary fs-7">{campaignOptionObj[type]}</div>}
            {channel && <div className="badge badge-light-primary fs-7">{campaignOptionObj[channel]}</div>}
            {fromDate && toDate && (
              <div className="badge badge-light-primary fs-7">
                {moment(fromDate).format("MMM DD")}
                {" - "}
                {moment(toDate).format("MMM DD")}
              </div>
            )}
            <SummaryReportFilter show={show} setShow={setShow} filterValue={initalFilterValue} handleApplyFilter={handleApplyFilter} handleCancelFilter={handleCancelFilter} />
            <CSVLink className="btn btn-sm btn-secondary" {...csvReport}>
              Export to CSV
            </CSVLink>
          </div>
        </CampaignSubHeader>
      }
    >
      {emailSummaryReportStore.loading ? (
        <ContentLoadingWithoutCard />
      ) : updatedData.length === 0 ? (
        <CustomEmptyPage page="no-report" />
      ) : (
        <div className="w-100 border border-1 rounded-2">
          <CustomTableWithoutAction
            tablehead={campaingTableFields[channel]}
            tablecontent={updatedData}
            mappingTable={campaignTableMapping}
            dataType={campaignTableFieldDataType}
            pageNo={pageNo}
            limit={limit}
            count={emailSummaryReportStore.count}
            setPageNo={setPageNo}
            setLimit={setLimit}
            hidePagination={true}
            isLastTotal={true}
          />
        </div>
      )}
    </ContentWrapper>
  );
}
