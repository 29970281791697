import { getUserProfile, updateUserProfile, patchUserAvatarPic } from "../../types/profile-settings.types";

export function getUserProfileRequest(cb) {
  return {
    type: getUserProfile.GET_USER_PROFILE_REQUEST,
    cb,
  };
}
export function getUserProfileSuccess(res) {
  return {
    type: getUserProfile.GET_USER_PROFILE_SUCCESS,
    payload: res,
  };
}
export function getUserProfileError(err) {
  return {
    type: getUserProfile.GET_USER_PROFILE_ERROR,
    payload: err,
  };
}

export function updateUserProfileRequest(req, cb) {
  return {
    type: updateUserProfile.UPDATE_USER_PROFILE_REQUEST,
    payload: req,
    cb,
  };
}
export function updateUserProfileSuccess(res) {
  return {
    type: updateUserProfile.UPDATE_USER_PROFILE_SUCCESS,
    payload: res,
  };
}

export function updateUserProfileError(err) {
  return {
    type: updateUserProfile.UPDATE_USER_PROFILE_ERROR,
    payload: err,
  };
}

export function patchUserAvatarPicRequest(req, cb) {
  return {
    type: patchUserAvatarPic.PATCH_USER_AVATAR_PIC_REQUEST,
    payload: req,
    // paramId,
    cb,
  };
}
export function patchUserAvatarPicSuccess(res) {
  return {
    type: patchUserAvatarPic.PATCH_USER_AVATAR_PIC_SUCCESS,
    payload: res,
  };
}
export function patchUserAvatarPicError(err) {
  return {
    type: patchUserAvatarPic.PATCH_USER_AVATAR_PIC_ERROR,
    payload: err,
  };
}
