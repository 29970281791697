import RadioSelector from "../custom-inputs/radio-selector.js";
import "./style.scss";
import React from "react";
import SimpleDropdown from "../dropdowns/simple-dropdown";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row, Spinner, Col } from "react-bootstrap";
import { BlueGradientButton } from "../button/index.js";
import { TimelineDataWrapper } from "../side-pannel-detailed/components/timeline-v2.helper";
import { rewriteText, summarizeText, generateEmail, saveText, deleteSavedText, fetchSavedText, fetchCreditsData } from "./service";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../utils/toaster";

const TONE = [
  { label: "None" },
  { label: "Funny" },
  { label: "Witty" },
  { label: "Dramatic" },
  { label: "Sarcastic" },
  { label: "Engaging" },
  { label: "Creative" },
  { label: "Professional" },
  { label: "Excited" },
  { label: "Encouraging" },
];

function GenerateEmailForm({ disabled, state, setState, onSubmit }) {
  return (
    <Form.Group>
      <Form.Group>
        <Form.Label>Type of Email</Form.Label>
        <Form.Control disabled={disabled} value={state.type} onChange={(e) => setState({ ...state, type: e.target.value })} type="text" placeholder="e.g - Promotional" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Target Audience</Form.Label>
        <Form.Control disabled={disabled} value={state.audience} onChange={(e) => setState({ ...state, audience: e.target.value })} type="text" placeholder="e.g - Engineers" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Objective</Form.Label>
        <Form.Control disabled={disabled} value={state.objective} onChange={(e) => setState({ ...state, objective: e.target.value })} type="text" placeholder="e.g - To promote job placement service" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Call To Action</Form.Label>
        <Form.Control disabled={disabled} value={state.cta} onChange={(e) => setState({ ...state, cta: e.target.value })} type="text" placeholder="e.g - Buy job placement service" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          disabled={disabled}
          value={state.description}
          onChange={(e) => setState({ ...state, description: e.target.value })}
          as={"textarea"}
          rows={4}
          placeholder="e.g - placement assistance, coding skills"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Tone</Form.Label>
        <SimpleDropdown disabled={disabled} value={{ label: state.tone }} options={TONE} label="Select tone" handleSelect={(val) => setState({ ...state, tone: val.label })} showSearch />
      </Form.Group>
      <Form.Group>
        <BlueGradientButton disabled={disabled} className="w-100" onClick={onSubmit}>
          {disabled ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Generate"}
        </BlueGradientButton>
      </Form.Group>
    </Form.Group>
  );
}

function RewriteTextForm({ disabled, state, setState, onSubmit }) {
  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>Text</Form.Label>
        <Form.Control disabled={disabled} value={state.text} onChange={(e) => setState({ ...state, text: e.target.value })} as={"textarea"} rows={15} placeholder="e.g - placement assistance, coding skills" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Tone</Form.Label>
        <SimpleDropdown disabled={disabled} value={{ label: state.tone }} options={TONE} label="Select tone" handleSelect={(val) => setState({ ...state, tone: val.label })} showSearch />
      </Form.Group>

      <BlueGradientButton disabled={disabled} className="w-100" onClick={onSubmit}>
        {disabled ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Rewrite"}
      </BlueGradientButton>
    </React.Fragment>
  );
}

function SummarizeTextForm({ disabled, state, setState, onSubmit }) {
  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>Text</Form.Label>
        <Form.Control disabled={disabled} value={state.text} onChange={(e) => setState({ ...state, text: e.target.value })} as={"textarea"} rows={15} placeholder="e.g - placement assistance, coding skills" />
      </Form.Group>
      <Form.Group>
        <Form.Label>Tone</Form.Label>
        <SimpleDropdown disabled={disabled} value={{ label: state.tone }} options={TONE} label="Select tone" handleSelect={(val) => setState({ ...state, tone: val.label })} showSearch />
      </Form.Group>

      <BlueGradientButton disabled={disabled} className="w-100" onClick={onSubmit}>
        {disabled ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Summarize"}
      </BlueGradientButton>
    </React.Fragment>
  );
}

function ResultForm({ disabled, state, setState, onSave }) {
  return (
    <>
      <Form.Group>
        <Form.Control
          disabled={disabled}
          value={state}
          onChange={(e) => setState(e.target.value)}
          as={"textarea"}
          rows={15}
          children={<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        />
      </Form.Group>
      <BlueGradientButton disabled={disabled} size="sm" onClick={onSave}>
        Save
      </BlueGradientButton>{" "}
      <BlueGradientButton
        disabled={disabled}
        onClick={() => {
          state.trim() !== "" && navigator.clipboard.writeText(state) && dispatchSnackbarSuccess("Copied to clipboard");
        }}
        size="sm"
      >
        Copy to clipboard
      </BlueGradientButton>{" "}
    </>
  );
}

const SavedTexts = ({ text, module, _id, index, onDelete, createdAt }) => {
  return (
    <div>
      <TimelineDataWrapper moduleName={module} moduleColor={"lightgrey"} createdAt={createdAt}>
        <div className="d-flex position-relative">
          <div className="w-100-60px">
            <div className="white-space-pre-line" dangerouslySetInnerHTML={{ __html: text || "" }}></div>
          </div>
          <div className="w-60px">
            <button
              className="btn btn-icon btn-light h-25px w-25px"
              onClick={() => {
                text.trim() !== "" && navigator.clipboard.writeText(text) && dispatchSnackbarSuccess("Copied to clipboard");
              }}
            >
              <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Copy</Tooltip>}>
                <a href="#editor">
                  <i className="fa fa-sm fa-solid fa-copy font-size-13px"></i>
                </a>
              </OverlayTrigger>
            </button>
            <button
              className="btn btn-icon btn-light ml-2 h-25px w-25px"
              onClick={async () => {
                onDelete(_id, index);
              }}
            >
              <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}>
                <i className="fa fa-sm fa-trash font-size-13px"></i>
              </OverlayTrigger>
            </button>
          </div>
        </div>
      </TimelineDataWrapper>
      <br />
    </div>
  );
};

function AIWizardLayout() {
  const radioOptions = ["Generate Email", "Content Rewrite", "Text Summarizer"];
  const [radioValue, setRadioValue] = React.useState(radioOptions[0]);
  const [resultValue, setResultValue] = React.useState("");
  const [loadingResult, setLoadingResult] = React.useState(false);

  const [generateEmailFormState, setGenerateEmailFormState] = React.useState({});
  const [rewriteTextFormState, setRewriteTextFormState] = React.useState({});
  const [summarizeTextFormState, setSummarizeTextFormState] = React.useState({});
  const [savedTexts, setSavedTexts] = React.useState([]);
  const [creditsData, setCreditsData] = React.useState({ consumed: 0, limit: 0, available: 0 });

  const handleOnResultSave = async () => {
    try {
      let r = await saveText({ text: resultValue, module: radioValue, createdAt: Date.now() });
      let t = [...savedTexts];
      t.unshift(r.data.data);
      setSavedTexts(t);
      dispatchSnackbarSuccess("Saved");
      console.log(r);
      return r;
    } catch (error) {
      dispatchSnackbarError(error.response.data.message);
    }
  };

  const handleOnSavedResultDelete = async (id, index) => {
    try {
      let r = await deleteSavedText({ id: id });
      let t = [...savedTexts];
      t.splice(index, 1);
      setSavedTexts(t);
      r.data.data.deletedCount && dispatchSnackbarSuccess("Deleted");
      console.log(r);
      return r;
    } catch (error) {
      dispatchSnackbarError(error.response.data.message);
    }
  };

  const fetchSavedTexts = async () => {
    try {
      let r = await fetchSavedText();
      setSavedTexts(r.data.data);
      console.log(r);
    } catch (error) {
      dispatchSnackbarError(error.response.data.message);
    }
  };

  const fetchCredits = async () => {
    try {
      let r = await fetchCreditsData();
      setCreditsData(r.data.data);
      console.log(r);
    } catch (error) {
      dispatchSnackbarError(error.response.data.message);
    }
  };

  React.useEffect(() => {
    fetchSavedTexts();
    fetchCredits();
  }, []);

  React.useEffect(() => {
    fetchCredits();
  }, [resultValue]);

  const handleOnSubmit = async () => {
    try {
      setLoadingResult(true);
      let text;
      switch (radioValue) {
        case radioOptions[0]:
          console.log("generateEmailData", generateEmailFormState);
          text = await generateEmail(generateEmailFormState);
          console.log("generateEmailData", text.data);
          break;
        case radioOptions[1]:
          console.log("rewriteTextData", rewriteTextFormState);
          text = await rewriteText(rewriteTextFormState);
          console.log("rewriteTextResult", text.data);
          break;
        case radioOptions[2]:
          console.log("summarizeTextData", summarizeTextFormState);
          text = await summarizeText(summarizeTextFormState);
          console.log("summarizeTextResult", text.data);
          break;
        default:
          break;
      }
      setResultValue(text.data.data);
      setLoadingResult(false);
    } catch (error) {
      console.log(error);
      setLoadingResult(false);
      dispatchSnackbarError(error.response.data.message);
    }
  };

  let renderForm;

  switch (radioValue) {
    case radioOptions[0]:
      renderForm = <GenerateEmailForm disabled={loadingResult} onSubmit={handleOnSubmit} state={generateEmailFormState} setState={setGenerateEmailFormState} />;
      break;
    case radioOptions[1]:
      renderForm = <RewriteTextForm disabled={loadingResult} onSubmit={handleOnSubmit} state={rewriteTextFormState} setState={setRewriteTextFormState} />;
      break;
    case radioOptions[2]:
      renderForm = <SummarizeTextForm disabled={loadingResult} onSubmit={handleOnSubmit} state={summarizeTextFormState} setState={setSummarizeTextFormState} />;
      break;
    default:
      break;
  }

  return (
    <div className="h-100">
      <Row className="h-100">
        <Col className="h-100 border-right-width-1px border-right-style-solid border-right-color-lightgrey overflow-auto">
          <div className="p-30px">
            <RadioSelector setValue={setRadioValue} value={radioValue} options={radioOptions} isVertical />
            {renderForm}
          </div>
        </Col>
        <Col sm={8} className="h-100 overflow-y-scroll">
          <div className="p-30px">
            <h3>
              <span>{radioValue}</span>{" "}
              <span className="float-right">
                <OverlayTrigger placement={"auto"} transition={true} overlay={<Tooltip id="available-credits-tooltip">Credits Available</Tooltip>}>
                  <span>
                    <i className="fa fa-sm fa-coins font-size-18px text-e09a16"></i> {creditsData.available}
                  </span>
                </OverlayTrigger>
              </span>
            </h3>
            <br />
            <ResultForm state={resultValue} setState={setResultValue} disabled={loadingResult} onSave={handleOnResultSave} />
            <div className="note-header-text">Saved</div>
            {savedTexts.map((d, index) => (
              <SavedTexts index={index} text={d.text} module={d.module} _id={d._id} onDelete={handleOnSavedResultDelete} createdAt={d.createdAt} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AIWizardLayout;
