import React from "react";
import { StageItemContainer } from "./styled";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { InputLimit } from "../../constants";
import SketchColorPicker from "../../components/color-picker/sketch.color-picker";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
    zIndex: 999999,
    // width:'30%'
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: () => ({
    maxHeight: "200px !important",
    overflowY: "auto !important",
    zIndex: 99999,
    // position: "fixed"
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

const stageStatus = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

class StageItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      probability: {},
      status: { value: "active", label: "Active" },
      color: "",
    };
  }

  componentDidMount() {
    this.setState({ probability: this.props.stage.probability ? this.props.stage.probability : 0 });
    this.setState({
      status: {
        value: this.props.stage.status ? this.props.stage.status : "",
        label: this.props.stage.status ? this.props.stage.status.charAt(0).toUpperCase() + this.props.stage.status.slice(1) : "",
      },
    });
    this.setState({ color: this.props.stage.color });
  }

  handleChange = (color, stageId) => {
    this.setState({ color: color });
    this.props.onChange(stageId, "color", color);
  };

  render() {
    const { stage, onChange, onKeyPress, remove } = this.props;

    const onChangeName = (stageId, e) => onChange(stageId, "name", e.target.value);

    const onChangeProbability = (stageId, e) => {
      if (e.target.value >= 0 && e.target.value <= 100) {
        this.setState({ probability: e.target.value });
        onChange(stageId, "probability", e.target.value);
      }
    };

    const onChangeStatus = (stageId, e) => {
      this.setState({ status: e });
      onChange(stageId, "status", e.value);
    };

    return (
      <StageItemContainer key={stage._id} className="w-100">
        <Form.Group id="deals-stage-item-stage-name" className="w-35 mb-0">
          <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Stage name" onKeyPress={onKeyPress} autoFocus={true} defaultValue={stage.name} onChange={onChangeName.bind(this, stage._id)} />
        </Form.Group>
        <Form.Group id="deals-stage-item-probability" className="w-25prc mb-0">
          <Form.Control
            maxLength={InputLimit.NUMBER}
            type="number"
            placeholder="Probability"
            onKeyPress={onKeyPress}
            autoFocus={true}
            value={this.state.probability}
            defaultValue={this.state.probability}
            onChange={onChangeProbability.bind(this, stage._id)}
          />
        </Form.Group>
        <Form.Group id="deals-stage-item-status" className="w-25prc mb-0">
          <Select
            // className="form-select"
            menuPortalTarget={document.body}
            aria-label="Default select example"
            options={stageStatus}
            styles={customStyles}
            defaultValue={this.state.status}
            value={this.state.status}
            onChange={onChangeStatus.bind(this, stage._id)}
            name="status"
          />
        </Form.Group>
        <Form.Group id="deals-stage-item-handle-change" className="w-15prc mb-0">
          <SketchColorPicker color={this.state.color} setColor={(e) => this.handleChange(e, stage._id)} />
        </Form.Group>

        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
          <span className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={remove.bind(this, stage._id)}>
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </span>
        </OverlayTrigger>
      </StageItemContainer>
    );
  }
}

export default StageItem;
