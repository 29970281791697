import React, { useEffect, useState } from "react";
import _ from "lodash";
import * as actions from "../../../actions";
import { useDispatch } from "react-redux";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { useHistory, useLocation } from "react-router-dom";
import RegistrationWrapper from "../../componets/auth-wrapper";
import Select from "react-select";
import { getGeoLocation } from "../../utils/geoLocation";
import { SplashScreen } from "../../../../../../_metronic/_partials/controls";
import { InputLimit } from "../../../../../../constants";
import ReactPhoneInput from "react-phone-input-2";
import { detect } from "detect-browser";
import LoadingIcon from "../../../../../../images/gif/DoubleRing-1s-200px(1).svg";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
    zIndex: 999999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: () => ({
    maxHeight: "200px !important",
    overflowY: "auto !important",
    zIndex: 99999,
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    // position: "relative",
    boxSizing: "border-box",
    width: "100%",
    height: "53px",
    padding: "8px 12px",

    fontSize: "14px",
    lineHeight: "1.42857143",
    color: "#333333",
    verticalAlign: "middle",
    backgroundColor: "#ffffff",
    border: "1px solid #cccccc",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

const companyIndustry = [
  { value: "Software and App Developement", label: "Software and App Developement" },
  { value: "Health", label: "Health" },
  { value: "Tech Startup", label: "Tech Startup" },
  { value: "Education and Training", label: "Education and Training" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Creative Agency (Web , Advertising , Video)", label: "Creative Agency (Web , Advertising , Video)" },
  { value: "Financial and Credit Services", label: "Financial and Credit Services" },
  { value: "News , Media and Publication", label: "News , Media and Publication" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "It Services", label: "It Services" },
  { value: "Consulting", label: "Consulting" },
  { value: "Construction", label: "Construction" },
  { value: "Trade (Retail and Wholesale)", label: "Trade (Retail and Wholesale)" },
  { value: "Others", label: "Others" },
];

export default function RegistrationV2(props) {
  const [size, setSize] = useState("");
  const [typeOfComp, setTypeOfComp] = useState("");
  const [code, setCode] = useState("");
  const [token, setToken] = useState("");
  const [companySizeOption] = useState(["1-3", "3-10", "10-50", "50+"]);
  const [geoLocation, setGeoLocation] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [preId, setPreId] = useState("");
  const [currency, setCurrency] = useState("usd");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState(null);
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [password1, setPassword1] = useState("");

  const [loading, setLoading] = useState(true);

  const [errorMessage1, setErrorMessage1] = useState("");
  const [invalidGoogleSignup, setInvalidGoogleSignup] = useState(false);

  const [isAccountSetupActive, setIsAccountSetupActive] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const browser = detect();

  useEffect(() => {
    const data = new URLSearchParams(location.search);
    const _token = data.get("token");
    const _code = data.get("code");
    if (!_code && !_token) {
      history.push("/auth/login");
    } else {
      setToken(_token);
      setCode(_code);
    }
  }, [location.search, history]);

  useEffect(() => {
    (async () => {
      const data = await getGeoLocation();
      setGeoLocation(data);
    })();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(geoLocation)) {
      if (geoLocation.country_name && geoLocation.country_name.toLowerCase() === "india") {
        setCurrency("INR");
      } else {
        setCurrency("USD");
      }
    }
  }, [geoLocation]);

  useEffect(() => {
    if (code) {
      dispatch(
        actions.PostGoogleAPIRequest({ code: code, page: "signup" }, (data) => {
          if (data.email) {
            setName(data.name);
            setEmail(data.email);

            dispatch(
              actions.CheckEmailRequest({ email: data.email }, (data1) => {
                if (data1["emailTaken"]) {
                  setErrorMessage1(data["message"]);
                  setInvalidGoogleSignup(true);
                  setLoading(false);
                } else {
                  setLoading(false);
                }
              }),
            );
          } else {
            setErrorMessage1("We are not getting enough permission from your G-Suite account. Please follow the regular Sign Up process.");
            setLoading(false);
            setInvalidGoogleSignup(true);
          }
        }),
      );
    }
  }, [code, dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(
        actions.verifyPreAccountRequest({ code: "", token: token }, (data) => {
          if (data.status) {
            if (data.data._id) {
              setName(data.data.name);
              setPhone(data.data.phone);
              setCompany(data.data.company);
              setEmail(data.data.email);
              setPreId(data.data._id);
            }
            setLoading(false);
          } else {
            setErrorMessage1("Your token got expired. Please go to signup page to register again.");
            setLoading(false);
            setInvalidGoogleSignup(true);
          }
        }),
      );
    }
  }, [dispatch, token]);

  const submit = () => {
    if (token) {
      if (password === "") {
        return setErrorMessage1("Please Enter Password");
      } else if (!/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(password)) {
        return setErrorMessage1("Password should contains minimum eight characters, at least one letter and one number");
      }
      // else if (password !== password1) {
      //   return setErrorMessage1("Password and Confirm Password are not Match");
      // }
    } else if (code) {
      if (company === "") {
        return setErrorMessage1("Please Enter Company Name");
      } else if (!/[0-9]{5,14}$/.test(phoneNumber)) {
        return setErrorMessage1("Please enter valid phone number");
      }
    }
    if (_.isEmpty(size)) {
      return setErrorMessage1("Please select Company size", "error");
    } else if (_.isEmpty(typeOfComp)) {
      return setErrorMessage1("Please select type of company", "error");
    } else {
      const paylaod = {
        fullName: name,
        email: email,
        subDomain: company,
        cName: company,
        country: geoLocation.country_name,
        phone: phone,
        location: `${geoLocation.city} ${geoLocation.region}`,
        device: browser.os,
        platform: browser.name,
        IPAddr: geoLocation.ip,
        isGoogleAttempt: false,
        rememberLogin: true,
        loginMethod: "PASSWORD",
        size: size,
        typeOfComp: typeOfComp,
        experience: 1,
        currency: currency,
      };

      if (token) {
        paylaod["preId"] = preId;
        paylaod["password"] = password;
        paylaod["isGoogleAttempt"] = false;
      } else if (code) {
        paylaod["isGoogleAttempt"] = true;
        paylaod["phone"] = phoneNumber;
      }
      dispatch(
        actions.createAccountV2Request(paylaod, (data) => {
          if (data["status"]) {
            localStorage.setItem("userLoginStatus", "trial");
            localStorage.setItem("access_token", data["token"]);
            checkAccountStatus();
            // dispatchSnackbarSuccess("Account Successfully Updated", "success");
            // window.location.href = '/activity/activity-list'
          } else {
            dispatchSnackbarError(data.message);
          }
        }),
      );
    }
  };

  const checkAccountStatus = () => {
    setIsAccountSetupActive(true);
    setTimeout(() => {
      window.location.href = "/activity/activity-list";
    }, 3000);
  };

  return (
    <RegistrationWrapper>
      {loading ? (
        <div className="d-flex flex-center">
          <SplashScreen />
        </div>
      ) : isAccountSetupActive ? (
        <div class="form-success light w-form-done mb-7" role="region" aria-label="Reset Password success">
          <div>
            <img src={LoadingIcon} alt="" className="w-100px h-100px" />
          </div>
          <h6>Creating Your Account!</h6>
          We’re just adding some finishing touches.
        </div>
      ) : invalidGoogleSignup ? (
        <>
          <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
            <div>{errorMessage1}.</div>
          </div>
          <a href="https://www.salessimplify.com/signup" class="button primary-invert medium block mb-40 _2 w-button">
            &lt; Back to Sign Up
          </a>
        </>
      ) : (
        <>
          <h5 class="text-black">Welcome Onboard!</h5>
          <div class="text-grey text-medium mb-30"></div>
          <div class="w-form">
            <div id="wf-form-Registration-Password-3" name="wf-form-Registration-Password-3" data-name="Registration Password" method="get" aria-label="Registration Password">
              {token && (
                <>
                  <div class="form-input-group">
                    <label for="Registration-New-Password" class="form-label grey font-size-12px">
                      Password
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      class="input white bordered w-input"
                      maxlength={InputLimit.PASSWORD}
                      name="Registration-New-Password"
                      data-name="Registration New Password"
                      placeholder="Enter New Password"
                      id="Registration-New-Password"
                    />
                  </div>
                </>
              )}
              {code && (
                <>
                  {" "}
                  <div class="form-input-group">
                    <label for="Invite-Company-Name" class="form-label grey font-size-12px">
                      Company Name
                    </label>
                    <input
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      class="input white bordered w-input"
                      maxlength={InputLimit.TEXT}
                      name="Invite Company Name"
                      data-name="Reset Company Name"
                      placeholder="Enter Company Name"
                      id="Invite-Company-Name"
                    />
                  </div>
                  <div class="form-input-group">
                    <label for="Invite-Phone-Number" class="form-label grey font-size-12px">
                      Phone Number
                    </label>
                    <ReactPhoneInput
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e)}
                      containerClass="....." //css class name
                      inputClass="input white bordered w-input"
                      buttonClass="....."
                      dropdownClass="phone2-dropdownClass"
                      searchClass="....."
                      inputStyle={{
                        paddingLeft: "48px",
                      }}
                    />
                  </div>
                </>
              )}
              <div class="form-input-group">
                <label for="Registration-Size" class="form-label grey font-size-12px">
                  Select billing currency, you won't be able to change later on.
                </label>
                <div className="row mb-2" data-kt-buttons="true">
                  {[
                    { label: "USD", symbol: "$", value: "USD" },
                    { label: "INR", symbol: "₹", value: "INR" },
                  ].map((each, index) => {
                    return (
                      <div key={index} className="col">
                        <label className={`btn btn-outline btn-outline-dashed bordered btn-outline-default auth-radio-button w-100 p-4 ${each.value === currency ? "active" : ""}`}>
                          <input type="radio" className="btn-check" onChange={(e) => setCurrency(each.value)} name="account_team_size" defaultValue={each.value} />
                          <span className="fw-bolder fs-3">
                            {each.symbol} {each.label}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="form-input-group">
                <label for="Registration-Size" class="form-label grey font-size-12px">
                  How many people in the company will use Sales Simplify?
                </label>
                <div className="row mb-2" data-kt-buttons="true">
                  {companySizeOption.map((each, index) => {
                    return (
                      <div key={index} className="col">
                        <label className={`btn btn-outline btn-outline-dashed bordered btn-outline-default auth-radio-button w-100 p-4 ${each === size ? "active" : ""}`}>
                          <input type="radio" className="btn-check" onChange={(e) => setSize(each)} name="account_team_size" defaultValue={each} />
                          <span className="fw-bolder fs-3">{each}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="form-input-group">
                <label for="Registration-Confirm-Password" class="form-label grey font-size-12px">
                  Company Industry
                </label>
                <Select menuPortalTarget={document.body} aria-label="Default select example" options={companyIndustry} styles={customStyles} onChange={(e) => setTypeOfComp(e.value)} />
              </div>
              {errorMessage1 && (
                <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
                  <div>{errorMessage1}.</div>
                </div>
              )}
              <button onClick={submit} data-wait="Please wait..." class="button primary-invert medium block mb-40 _2 w-button">
                Submit
              </button>

              <div class="text-black text-small">
                <a href="https://www.salessimplify.com/signup" class="link-primary-dark text-semi _1">
                  &lt; Back to Sign Up
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </RegistrationWrapper>
  );
}
