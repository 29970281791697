import React from "react";
import Skeleton from "react-loading-skeleton";

export const CardSkeleton = () => {
  return <Skeleton count={5} />;
};

export const GraphSkeleton = () => {
  return <Skeleton count={15} />;
};
