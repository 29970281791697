import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import * as schedulingActions from "../../actions/profile-actions/shedular-settings.actions";
import * as schedulingTypes from "../../types/profile-settings/schedular.types";

const endpoints = {
  GET_LIST_OF_SCHEDULING_PAGES: "/api/crm/sync-settings/schedular/list",
  GET_SELECTED_SCHEDULING_PAGE: "/api/crm/sync-settings/schedular/selected",
  CREATE_SCHEDULING_PAGE: "/api/crm/sync-settings/schedular/create",
  UPDATE_SCHEDULING_PAGE: "/api/crm/sync-settings/schedular/selected",
  DELETE_SCHEDULING_PAGE: "/api/crm/sync-settings/schedular/selected",
  GET_CALENDARS_SCHEDULING_PAGE: "/api/crm/sync-settings/schedular/available-calendars",
  UPLOAD_IMAGE_SCHEDULING_PAGE: "/api/crm/sync-settings/schedular/upload-image",
};

function* getListOfSchedulingPages(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_LIST_OF_SCHEDULING_PAGES}/${action.payload.syncId}`, action.payload);
    if (res.status === 200) {
      yield put(schedulingActions.getListOfSchedulingPagesSucess(res.data));
    } else {
      // dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.getListOfSchedulingPagesError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.getListOfSchedulingPagesError(e));
  }
}

function* getSelectedSchedulingPage(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_SELECTED_SCHEDULING_PAGE}/${action.payload.syncId}/${action.payload.id}`);
    if (res.status === 200) {
      yield put(schedulingActions.getSelectedSchedulingPageSucess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.getSelectedSchedulingPageError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.getSelectedSchedulingPageError(e));
  }
}

function* createSchedulingPage(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_SCHEDULING_PAGE, action.payload);
    if (res.status === 200) {
      yield put(schedulingActions.createSchedulingPageSucess(res.data));
      // yield put(schedulingActions.getListOfSchedulingPagesRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.createSchedulingPageError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.createSchedulingPageError(e));
  }
}

function* updatedSchedulingPage(action) {
  try {
    const res = yield call(api.patch, `${endpoints.UPDATE_SCHEDULING_PAGE}/${action.payload.syncId}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Scheduling page data updated successfully.");
      yield put(schedulingActions.updatedSchedulingPageSucess(res.data));
      yield put(schedulingActions.getListOfSchedulingPagesRequest({ syncId: action.payload.syncId }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.updatedSchedulingPageError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.updatedSchedulingPageError(e));
  }
}

function* deleteSchedulingPage(action) {
  try {
    const res = yield call(api.delete, `${endpoints.DELETE_SCHEDULING_PAGE}/${action.payload.syncId}/${action.payload.id}`);
    if (res.status === 200) {
      yield put(schedulingActions.deleteSchedulingPageSucess(res.data));
      yield put(schedulingActions.getListOfSchedulingPagesRequest({ syncId: action.payload.syncId }));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.deleteSchedulingPageError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.deleteSchedulingPageError(e));
  }
}

function* getAvailableCalendars(action) {
  try {
    const res = yield call(api.get, `${endpoints.GET_CALENDARS_SCHEDULING_PAGE}/${action.payload.syncId}/${action.payload.id}`);
    if (res.status === 200) {
      // dispatchSnackbarSuccess("Scheduling page data updated successfully.");
      yield put(schedulingActions.getAvailableCalendarsSucess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.getAvailableCalendarsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.getAvailableCalendarsError(e));
  }
}

function* getS3SignedUrlForUpload(action) {
  try {
    const res = yield call(api.put, `${endpoints.UPLOAD_IMAGE_SCHEDULING_PAGE}`, action.payload);
    if (res.status === 200) {
      yield put(schedulingActions.getS3SignedUrlForUploadSucess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(schedulingActions.getS3SignedUrlForUploadError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(schedulingActions.getS3SignedUrlForUploadError(e));
  }
}

export default function* schedulingSettingsSaga() {
  yield takeLatest(schedulingTypes.getListOfSchedulingPages.GET_LIST_OF_SCHEDULING_PAGES_REQUEST, getListOfSchedulingPages);
  yield takeLatest(schedulingTypes.getSelectedSchedulingPage.GET_SELECTED_SCHEDULING_PAGE_REQUEST, getSelectedSchedulingPage);
  yield takeLatest(schedulingTypes.createSchedulingPage.CREATE_SCHEDULING_PAGE_REQUEST, createSchedulingPage);
  yield takeLatest(schedulingTypes.updatedSchedulingPage.UPDATE_SCHEDULING_PAGE_REQUEST, updatedSchedulingPage);
  yield takeLatest(schedulingTypes.deleteSchedulingPage.DELETE_SCHEDULING_PAGE_REQUEST, deleteSchedulingPage);
  yield takeLatest(schedulingTypes.getAvailableCalendars.GET_AVAILABLE_CALENDARS_REQUEST, getAvailableCalendars);
  yield takeLatest(schedulingTypes.getS3SignedUrlForUpload.GET_S3_SIGNED_URL_FOR_UPLOAD_REQUEST, getS3SignedUrlForUpload);
}
