import React from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./styles.scss";

export default function CheckoutCard(props) {
  const getIsSomethingSelected = () => {
    return props.agents > 0 || props.recordCount > 0 || props.emailSyncCount > 0;
  };
  return (
    <div className="checkout-card-wrapper">
      <div className="card-head-text">Bill Summary:</div>
      <div className="checkout-summary-wrapper">
        {getIsSomethingSelected() ? (
          <>
            {props.agents > 0 && (
              <div className="checkout-each-list">
                <div className="list-text">Team member(s)</div>
                <div className="list-operation">
                  {props.currencySymbol}
                  {props.selectedMainPlan.amount / 100} x {props.agents}(user)
                </div>
                <div className="list-total">
                  {props.currencySymbol}
                  {(props.selectedMainPlan.amount / 100) * props.agents}
                </div>
              </div>
            )}
            {props.recordCount > 0 && (
              <div className="checkout-each-list">
                <div className="list-text">Records</div>
                <div className="list-operation">
                  {" "}
                  {props.currencySymbol}
                  {props.selectedRecordPlan.amount / 100} x {props.recordCount}(10,000)
                </div>
                <div className="list-total">
                  {props.currencySymbol}
                  {(props.selectedRecordPlan.amount / 100) * props.recordCount}
                </div>
              </div>
            )}
            {props.emailSyncCount > 0 && (
              <div className="checkout-each-list">
                <div className="list-text">Email & Calendar Sync</div>
                <div className="list-operation">
                  {props.currencySymbol}
                  {props.selectedEmailSyncPlan.amount / 100} x {props.emailSyncCount}(sync account)
                </div>
                <div className="list-total">
                  {" "}
                  {props.currencySymbol} {(props.selectedEmailSyncPlan.amount / 100) * props.emailSyncCount}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="d-flex flex-center text-white fs-5">Please select plan members or add-on to checkout details.</div>
          </>
        )}
      </div>
      <div className="checkout-footer-main-wrapper">
        <div className="checkout-footer">
          <hr />
          <div className="checkout-each-list">
            <div className="list-text"></div>
            <div className="list-operation">Total</div>
            <div className="list-total">
              {" "}
              {props.currencySymbol}
              {props.total}
            </div>
          </div>
          <div className="pay-button-wrapper">
            <Button variant="link" onClick={() => props.handleSubscription()} disabled={props.continueToPayDisabled || !getIsSomethingSelected()}>
              {props.continueToPayDisabled && <Spinner animation="border" className="mr-5px w-15px h-15px text-white" />}
              {props.isSubscriptionActive ? "Update Subscription" : "Pay Now"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
