import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../actions";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";
import ReactPhoneInput from "react-phone-input-2";
import { InputLimit } from "../../../../constants";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

export default function ManageUserInvite() {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const codeParams = new URLSearchParams(search).get("token");

  const [password, setPassword] = useState("");
  const [conformPassword, setconformPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+91");

  useEffect(() => {}, []);

  const handleChange = (e) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "conformPassword") {
      setconformPassword(e.target.value);
    }
  };

  const Submit = () => {
    if (!fullName) {
      dispatchSnackbarError("Please enter valid full name", "error");
    } else if (!/[0-9]{5,14}$/.test(phoneNumber)) {
      dispatchSnackbarError("Please enter valid phone number", "error");
    } else if (!/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(password)) {
      return dispatchSnackbarError("Password should contains minimum eight characters, at least one letter and one number");
    } else if (password === conformPassword) {
      dispatch(
        actions.ManagerInviteRequest({ password: password, fullName: fullName, phoneNumber: phoneNumber }, codeParams, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Account Successfully Verified", "success");
            window.location.href = "/auth/login";
          }
        }),
      );
    } else {
      dispatchSnackbarError("Password and Confirm Password not Match", "error");
    }
  };

  /**
   * http://localhost:3005/auth/manage-user/invite?token=ok
   */
  return (
    <>
      <div className="login-form login-forgot d-block">
        <div className="d-flex flex-column flex-root">
          {/*begin::Authentication - Password reset */}
          <InlineStyled
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
          >
            {/*begin::Content*/}
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
              {/*begin::Logo*/}
              <a href="index.html" className="mb-12">
                <img alt="Logo" src={require("../../../../images/logo/rectangle-logo/SVG/logo-rectangle.svg")} className="h-90px" />
              </a>
              <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                <div className="form w-100 mb-10" noValidate="novalidate" id="kt_sing_in_two_steps_form">
                  <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Join the team now</h1>
                    {/* <div className="text-gray-400 fw-bold fs-4">Enter your Password To Continue.</div> */}
                  </div>
                  <div className="mb-10">
                    <div className="fv-row mb-7">
                      <label className="form-label fw-bolder text-dark fs-6 field-required">Full Name</label>
                      <input
                        maxLength={InputLimit.TEXT}
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="fUllName"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="fv-row mb-7">
                      <label className="form-label fw-bolder text-dark fs-6 field-required">Phone Number</label>
                      <ReactPhoneInput
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e)}
                        inputClass="w-100"
                        inputStyle={{
                          height: "45px",
                          fontSize: "13px",
                          paddingLeft: "48px",
                          borderRadius: "5px",
                          backgroundColor: "#F3F6F9",
                          borderColor: "#F3F6F9",
                        }}
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="form-label fw-bolder text-dark fs-6 field-required">Password</label>
                      <input
                        maxLength={InputLimit.PASSWORD}
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => handleChange(e)}
                        autoComplete="off"
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="form-label fw-bolder text-dark fs-6 field-required">Confirm Password</label>
                      <input
                        maxLength={InputLimit.PASSWORD}
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        name="conformPassword"
                        value={conformPassword}
                        onChange={(e) => handleChange(e)}
                        autoComplete="off"
                      />
                      <div className="form-text mb-5">Minimum eight characters, at least one letter, one number and one special character</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-around">
                    <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={Submit}>
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </InlineStyled>
        </div>
      </div>
    </>
  );
}
