import { takeLatest, put, call } from "redux-saga/effects";
import * as emailFinderTypes from "../types/email-finder.types";
import * as emailFinderActions from "../actions/email-finder.actions";
import * as leadActions from "../../../leads/store/actions/actions";
import * as peopleActions from "../../../customers/store/actions/actions";
import * as marketingListActions from "../../../campaign/store/actions/campaign_marketing_list_actions";

import api from "../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";

const endpoints = {
    GET_LIST_EMAIL_FINDER: "/api/crm/tools/email-finder",
    GET_EMAIL_FINDER_PRICING: "/user-service/api/campaign/pricing",
    CREATE_EMAIL_FINDER: "/api/crm/tools/email-finder"
}

function* getListOfEmailFinder(action) {
    try {
        const res = yield call(api.get, `${endpoints.GET_LIST_EMAIL_FINDER}`, action.payload);
        if (res.status === 200 && res.data.status) {
            yield put(emailFinderActions.getListOfEmailFinderSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(emailFinderActions.getListOfEmailFinderFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(emailFinderActions.getListOfEmailFinderFailure(e));
    }
}

function* getEmailFinderPricing(action) {
    try {
        const res = yield call(api.get, `${endpoints.GET_EMAIL_FINDER_PRICING}/${action.payload}`);
        if (res.status === 200 && res.data.status) {
            yield put(emailFinderActions.getEmailFinderPricingSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(emailFinderActions.getEmailFinderPricingFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(emailFinderActions.getEmailFinderPricingFailure(e));
    }
}

function* createEmailFinder(action) {
    try {
        const res = yield call(api.post, `${endpoints.CREATE_EMAIL_FINDER}`, action.payload.data);
        // const res = yield new Promise((resolve) => setTimeout(() => resolve({ status: 200, data: { status: true, message: "Successfully Generated" } }), 1000))
        if (action.cb) action.cb(res.data);
        if (res.status === 200 && res.data.status) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(emailFinderActions.createEmailFinderSuccess(res.data));
            if (action.payload.from === "lead") {
                yield new Promise((resolve) => setTimeout(() => resolve(), 2000))
                yield put(leadActions.leadsUpdateRequest({ loadingData: action.payload.filter, cellId: null }));
            }
            else if (action.payload.from === "people") {
                yield new Promise((resolve) => setTimeout(() => resolve(), 2000))
                yield put(peopleActions.peoplesUpdateRequest({ loadingData: action.payload.filter, cellId: null }));
            }
            else if (action.payload.from === "marketing_list") {
                yield new Promise((resolve) => setTimeout(() => resolve(), 2000))
                yield put(marketingListActions.marketingUpdateRequest({ loadingData: action.payload.filter, cellId: null }));
            }
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(emailFinderActions.createEmailFinderFailure(res.data));
        }
    } catch (e) {
        yield put(emailFinderActions.createEmailFinderFailure(e));
    }
}

export default function* emailFinderSaga() {
    yield takeLatest(emailFinderTypes.getListOfEmailFinder.REQUEST, getListOfEmailFinder);
    yield takeLatest(emailFinderTypes.getEmailFinderPricing.REQUEST, getEmailFinderPricing);
    yield takeLatest(emailFinderTypes.createEmailFinder.REQUEST, createEmailFinder);

}
