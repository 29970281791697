import React, { useState, useEffect } from "react";
import _ from "lodash";
import { CustomSubHeader } from "../../components/subheader";
import { NavLink } from "react-router-dom";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import * as actions from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { NormalTable } from "../../components/table/normalTable";
import { ownersRequest } from "../customers/store/actions/actions";
import { Dropdown } from "react-bootstrap";
import "../../components/filter/style.css";
import ContentWrapper from "../Wrapper";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
var timer;

export default function ExportHistory(props) {
  const [type, setType] = useState("all");
  const errorStore = useSelector((state) => state.ImportExport.error);
  const successStore = useSelector((state) => state.ImportExport.success);
  const listStore = useSelector((state) => state.ImportExport.exportHistoryList);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    clearInterval(timer);
    setType(["product", "lead", "people", "company", "marketing", "deal"].includes(props.match.params.type) ? props.match.params.type : "all");
  }, [props.match.params.type]);

  useEffect(() => {
    dispatch(ownersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (type) {
      dispatch(actions.exportHistoryRequest({ pageNo: pageNo, limit: limit, type: type }));
    }
  }, [type, pageNo, limit, dispatch]);

  useEffect(() => {
    if (type) {
      clearInterval(timer);
      timer = setInterval(() => dispatch(actions.exportHistoryRequestWithLoading({ pageNo: pageNo, limit: limit, type: type })), 3000);
    }
  }, [type, pageNo, limit, dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  const columns = [
    { apiKeyName: "createdAt", fieldInputType: "DATE", fieldName: "Date" },
    { apiKeyName: "noOfResults", fieldInputType: "INPUT", fieldName: "Results" },
    { apiKeyName: "dataType", fieldInputType: "INPUT", fieldName: "Data" },
    { apiKeyName: "fileFormat", fieldInputType: "INPUT", fieldName: "File Format" },
    { apiKeyName: "ownerId", fieldInputType: "OWNER_SELECT", fieldName: "Owner" },
    { apiKeyName: "percentage", fieldInputType: "INPUT", fieldName: "Percentage" },
    { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
    { apiKeyName: "downloadableLink", fieldInputType: "DOWNLOAD", fieldName: "Download" },
  ];

  const tableProps = {
    columns: columns,
    data: listStore.list.map((each) => {
      each.percentage = `${_.parseInt(each.percentage)}%`;
      return each;
    }),
    pagination: true,
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    loading: listStore.loading,
  };

  const getToggleText = () => {
    if (type === "product") return <>Products</>;
    else if (type === "lead") return <>Leads</>;
    else if (type === "company") return <>Companies</>;
    else if (type === "people") return <>People</>;
    else if (type === "marketing") return <>Marketing</>;
    else if (type === "deal") return <>Deal</>;
    else return <>All</>;
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex flex-row flex-stack w-100 ">
            <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
              <div className="menu-item me-lg-1">
                <NavLink className="menu-link py-3" to={`/import/${type}`}>
                  <span className="menu-title">Import History</span>
                </NavLink>
              </div>
              <div className="menu-item me-lg-1">
                <NavLink className="menu-link py-3 active" to={`/export/${type}`}>
                  <span className="menu-title">Export History</span>
                </NavLink>
              </div>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle btn-secondary custom-secondory-cta">{getToggleText()}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <NavLink to="/export/lead">
                    <span className="dropdown-item dropdown-hover">Leads</span>
                  </NavLink>
                  <NavLink to="/export/people">
                    <span className="dropdown-item dropdown-hover">People</span>
                  </NavLink>
                  <NavLink to="/export/company">
                    <span className="dropdown-item dropdown-hover">Companies</span>
                  </NavLink>
                  <NavLink to="/export/product">
                    <span className="dropdown-item dropdown-hover">Products</span>
                  </NavLink>
                  <NavLink to="/export/marketing">
                    <span className="dropdown-item dropdown-hover">Marketing</span>
                  </NavLink>
                  <NavLink to="/export/deal">
                    <span className="dropdown-item dropdown-hover">Deals</span>
                  </NavLink>
                  <NavLink to="/export/all">
                    <span className="dropdown-item dropdown-hover">All</span>
                  </NavLink>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      {!listStore.loading && listStore.count === 0 ? (
        <CustomEmptyPage page="export" />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <NormalTable props={tableProps} />
          </div>
        </div>
      )}
    </ContentWrapper>
  );
}
