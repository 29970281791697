import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EditIconSVG } from "../../custom-svgs";

export default function BuilderComposer(props) {
  return (
    <div className="position-relative border border-1 rounded-1 bg-white h-100">
      <iframe title="template" srcDoc={props.content} width="100%" height="100%" frameBorder="0" scrolling="0" />
      <OverlayTrigger overlay={<Tooltip>Edit Content</Tooltip>}>
        <div
          className="position-absolute top-0 right-0 p-2 bg-light-white bg-hover-light-light border rounded-1 m-1"
          onClick={(e) => {
            e.stopPropagation();
            props.handleEmailBuilderEdit();
          }}
        >
          {EditIconSVG}
        </div>
      </OverlayTrigger>
    </div>
  );
}
