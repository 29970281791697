import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import validator from "validator";
import { detect } from "detect-browser";
import { InputLimit } from "../../../../../../constants";
import AuthWrapper from "../../componets/auth-wrapper";
import * as actions from "../../../actions";
import GoogleIcon from "../../../../../../images/google.svg";
import { getGeoLocation } from "../../utils/geoLocation";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../../utils/toaster";
import { useHistory, useLocation } from "react-router-dom";
import { getAccountBillingV2Request } from "../../../../../Settings/Billing/store/actions/subscription_billing_v2_actions";
import OTPInput from "../../../../../../components/otp-input";
import { LayoutSplashScreen } from "../../../../../../_metronic/layout";

var timer;
var timer1;

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [valuePayload, setValuePayload] = useState({});

  const [code, setCode] = useState("");
  const [codeLoading, setCodeLoading] = useState(false);

  // 2fa
  const [show2FAScreen, setShow2FAScreen] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isEnableResendOTP, setIsEnableResendOTP] = useState(false);
  const [timerCount, setTimerCount] = useState(0);
  const [currentResendLimit, setCurrentResendLimit] = useState(1);

  const dispatch = useDispatch();
  const browser = useRef(detect());
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const codeParams = new URLSearchParams(location.search).get("code");
      if (codeParams) {
        setCode(codeParams);
      }
    }
  }, [location]);

  const resendTimerFunction = useCallback(() => {
    const timerCountSec = 60;
    let currentLimit = 0;
    setTimerCount(timerCountSec);
    setIsEnableResendOTP(false);
    clearInterval(timer);
    clearInterval(timer1);
    timer = setTimeout(() => {
      setIsEnableResendOTP(true);
      setTimerCount(0);
      clearInterval(timer);
      clearInterval(timer1);
    }, 60000);
    timer1 = setInterval(() => {
      currentLimit++;
      if (currentLimit < 60) setTimerCount(timerCountSec - currentLimit);
      else clearInterval(timer1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (show2FAScreen) {
      resendTimerFunction();
    }
  }, [show2FAScreen, resendTimerFunction]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
      clearInterval(timer1);
    };
  }, []);

  const goInsideDashboard = useCallback(() => {
    dispatch(
      getAccountBillingV2Request((data) => {
        const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = data;
        if (!isFreeTrialPeriod && !isSubscribedForLifeTimeFree && !["active", "past_due", "trialing"].includes(subscriptionStatus)) {
          history.push("/settings/billing");
        } else if (data.accountStatus === "DEACTIVATED") {
          history.push("/settings/account/company");
        } else {
          history.push("/activity/activity-list");
        }
      }),
    );
  }, [dispatch, history]);

  useEffect(() => {
    const googlePostFunction = async () => {
      const geoLoc = await getGeoLocation();

      const values = {
        location: `${geoLoc.city} ${geoLoc.region}`,
        device: browser.current.os,
        platform: browser.current.name,
        IPAddr: geoLoc.ip,
        rememberLogin: true,
        loginMethod: "PASSWORD",
        isGoogleAttempt: true,
      };

      dispatch(
        actions.PostGoogleAPIRequest({ code: code, page: "login", ...values }, (data) => {
          if (data["success"]) {
            localStorage.setItem("access_token", data["token"]);
            dispatchSnackbarSuccess(data["message"], "success");
            goInsideDashboard();
          }
          setCodeLoading(false);
        }),
      );
    };
    if (code) {
      setCodeLoading(true);
      googlePostFunction();
    }
  }, [code, dispatch, goInsideDashboard]);

  const signInGoogle = () => {
    dispatch(
      actions.GoogleAPIRequest("login", (data) => {
        const newWindow = window.open(data.url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      }),
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSignInClick();
    }
  };

  const onSignInClick = async () => {
    if (!validator.isEmail(email)) {
      return setErrorMessage("Please enter valid email address");
    }
    if (!password) {
      return setErrorMessage("Please Enter Password");
    }
    setLoading(true);
    const geoLoc = await getGeoLocation();

    const payload = {
      device: browser.current.os,
      platform: browser.current.name,
      rememberLogin: true,
      loginMethod: "PASSWORD",
      isGoogleAttempt: false,
      email: email,
      password: password,
      IPAddr: geoLoc.ip,
      location: `${geoLoc.city} ${geoLoc.region}`,
    };

    setValuePayload(payload);
    const data = await new Promise((resolve) => dispatch(actions.loginRequest(payload, (cb) => resolve(cb))));
    if (data.success) {
      if (data["message"] === "OTP sent to registered email") {
        setErrorMessage("");
        setSuccessMessage("Check Your Email For OTP");
        setShow2FAScreen(true);
        setLoading(false);
      } else {
        localStorage.setItem("access_token", data["token"]);
        dispatchSnackbarSuccess("Login Successfully");
        goInsideDashboard();
        setLoading(false);
      }
    } else {
      setErrorMessage(data.message);
      setLoading(false);
    }
  };

  //2fa section
  const onOtpChange = (otp) => {
    setOtpValue(otp);
    if (otp.length >= 6) {
      if (isNaN(otp)) {
        dispatchSnackbarError("Please enter valid otp number!");
      } else {
        SignInOTP(otp);
      }
    }
  };

  const SignInOTP = (otp = otpValue) => {
    const newVal = { ...valuePayload };
    try {
      newVal["otp"] = parseInt(otp);
    } catch (err) {
      newVal["otp"] = otp;
    }
    dispatch(
      actions.loginRequest(newVal, (data) => {
        if (data["success"]) {
          localStorage.setItem("access_token", data["token"]);
          dispatchSnackbarSuccess("Login Successfully");
          goInsideDashboard();
        } else {
          setErrorMessage("Invalid OTP");
        }
      }),
    );
  };

  const resendOTP = () => {
    if (isEnableResendOTP && currentResendLimit <= 4) {
      setOtpValue("");
      resendTimerFunction();
      setCurrentResendLimit(currentResendLimit + 1);
      dispatch(
        actions.EmailOTPRequest({ email: valuePayload.email, caseType: "LOGIN" }, (data) => {
          dispatchSnackbarSuccess("OTP successfully Sent", "success");
        }),
      );
    }
  };

  if (codeLoading) {
    return <LayoutSplashScreen />;
  }

  return (
    <AuthWrapper>
      {!show2FAScreen ? (
        <>
          <h5 class="text-black">Log in</h5>
          <div class="text-grey text-medium mb-30 pt-0">Welcome back! Please enter your details.</div>
          <div class="w-form">
            <div id="wf-form-Reset-Password-2" name="wf-form-Reset-Password-2" data-name="Reset Password" method="get" aria-label="Reset Password">
              <div onClick={signInGoogle} class="google-signup w-inline-block cursor-pointer">
                <img loading="lazy" width="26" src={GoogleIcon} alt="" class="google-signuo-logo" />
                <div>Continue with Google</div>
              </div>
              <div class="divider"></div>
              <div class="form-input-group">
                <label for="Reset-Password-Email" class="form-label grey">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onKeyDown={(e) => handleKeyPress(e)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  class="input white bordered w-input"
                  maxlength={InputLimit.EMAIL}
                  name="Reset-Password-Email"
                  data-name="Reset Password Email"
                  placeholder="Enter your email"
                  id="Reset-Password-Email"
                />
              </div>
              <div class="form-input-group">
                <div className="d-flex flex-stack">
                  <label for="Reset-New-Password" class="form-label grey">
                    Password
                  </label>
                  <a href="/auth/forgot-password" className="link-primary-dark text-semi _1">
                    Forgot Password
                  </a>
                </div>
                <input
                  type="password"
                  onKeyDown={(e) => handleKeyPress(e)}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  class="input white bordered w-input"
                  maxlength={InputLimit.PASSWORD}
                  name="Reset-New-Password"
                  data-name="Reset New Password"
                  placeholder="Enter New Password"
                  id="Reset-New-Password"
                />
              </div>
              {errorMessage && (
                <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
                  {errorMessage === "Invalid credentials" ? (
                    <div>
                      <span>{"Invalid credentials, reset your password "}</span>
                      <a href="/auth/forgot-password" className="link-primary-dark text-semi _1">
                        click here
                      </a>
                    </div>
                  ) : (
                    <div>{errorMessage}.</div>
                  )}
                </div>
              )}
              {successMessage && (
                <div class="form-success light w-form-done mb-7" role="region" aria-label="Reset Password success">
                  <div>{successMessage}</div>
                </div>
              )}
              <button onClick={onSignInClick} data-wait="Please wait..." class="button primary-invert medium block mb-40 _2 w-button">
                {loading && <span className="mr-9 spinner spinner-white"></span>}
                Sign In
              </button>
              <div class="text-black text-small">
                <a href={`https://www.salessimplify.com/signup`} class="link-primary-dark text-semi _1">
                  Create an Account
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5 class="text-black">Two-Factor Authentication</h5>
          <div class="text-grey text-medium mb-30">Please enter the verification code we sent you on your email.</div>
          <div class="w-form">
            <div id="wf-form-Reset-Password-2" name="wf-form-Reset-Password-2" data-name="Reset Password" method="get" aria-label="Reset Password">
              <div className="mb-10">
                <OTPInput
                  autoFocus
                  length={6}
                  value={otpValue}
                  className="d-flex flex-wrap justify-content-between inputFormStyle"
                  inputClassName="form-control form-control-solid fs-2qx text-center border-primary me-2 my-2 OtpScreenStyle"
                  onChangeOTP={(otp) => onOtpChange(otp)}
                />
              </div>
              {errorMessage && (
                <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
                  <div>{errorMessage}.</div>
                </div>
              )}
              {successMessage && (
                <div class="form-success light w-form-done mb-7" role="region" aria-label="Reset Password success">
                  <div>{successMessage}</div>
                </div>
              )}
              <button onClick={SignInOTP} data-wait="Please wait..." class="button primary-invert medium block mb-40 _2 w-button">
                {loading && <span className="mr-9 spinner spinner-white"></span>}
                Sign In
              </button>
              <div class="text-small">
                <span className="text-black text-muted">Didn’t receive an email? Check your spam folder or </span>
                <span
                  disabled={true}
                  onClick={() => resendOTP()}
                  class={`link-primary-dark text-semi _1 ${isEnableResendOTP && currentResendLimit <= 3 ? "cursor-pointer" : "cursor-not-allowed text-gray-800"}`}
                >
                  Resend OTP
                </span>
                {timerCount > 0 && currentResendLimit <= 4 && <span class="link-primary-dark bg-light-primary px-2 ml-2 rounded-pill _1">{timerCount}</span>}
              </div>
            </div>
          </div>
        </>
      )}
    </AuthWrapper>
  );
}
