import { getCompanySettings, updateCompanySettings, activeDeactiveCompanyStatus, getAccountBilling, currency, deleteCompanyNow } from "../../types/admin-settings.types";

export const getCompanySettingsRequest = (payload, cb) => ({
  type: getCompanySettings.GET_COMPANY_SETTINGS_REQUEST,
  payload,
  cb,
});
export const getCompanySettingsSuccess = (payload) => ({
  type: getCompanySettings.GET_COMPANY_SETTINGS_SUCCESS,
  payload,
});
export const getCompanySettingsError = (payload) => ({
  type: getCompanySettings.GET_COMPANY_SETTINGS_ERROR,
  payload,
});

export const updateCompanySettingsRequest = (payload, cb) => ({
  type: updateCompanySettings.UPDATE_COMPANY_SETTINGS_REQUEST,
  payload,
  cb,
});
export const updateCompanySettingsSuccess = (payload) => ({
  type: updateCompanySettings.UPDATE_COMPANY_SETTINGS_SUCCESS,
  payload,
});
export const updateCompanySettingsError = (payload) => ({
  type: updateCompanySettings.UPDATE_COMPANY_SETTINGS_ERROR,
  payload,
});

export const activeDeactiveCompanyStatusRequest = (payload, cb) => ({
  type: activeDeactiveCompanyStatus.ACTIVE_DEACTIVE_COMPANY_STATUS_REQUEST,
  payload,
  cb,
});
export const activeDeactiveCompanyStatusSuccess = (payload) => ({
  type: activeDeactiveCompanyStatus.ACTIVE_DEACTIVE_COMPANY_STATUS_SUCCESS,
  payload,
});
export const activeDeactiveCompanyStatusError = (payload) => ({
  type: activeDeactiveCompanyStatus.ACTIVE_DEACTIVE_COMPANY_STATUS_ERROR,
  payload,
});

export const deleteCompanyNowRequest = (payload, cb) => ({
  type: deleteCompanyNow.DELETE_COMPANY_NOW_REQUEST,
  payload,
  cb,
});
export const deleteCompanyNowSuccess = (payload) => ({
  type: deleteCompanyNow.DELETE_COMPANY_NOW_SUCCESS,
  payload,
});
export const deleteCompanyNowError = (payload) => ({
  type: deleteCompanyNow.DELETE_COMPANY_NOW_ERROR,
  payload,
});

export function getAccountBillingRequest(cb) {
  return {
    type: getAccountBilling.GET_ACCOUNT_BILLING_REQUEST,
    // payload: req
    cb,
  };
}
export function getAccountBillingSuccess(res) {
  return {
    type: getAccountBilling.GET_ACCOUNT_BILLING_SUCCESS,
    payload: res,
  };
}
export function getAccountBillingError(err) {
  return {
    type: getAccountBilling.GET_ACCOUNT_BILLING_ERROR,
    payload: err,
  };
}

export function currencyRequest(req) {
  return {
    type: currency.CURRENCY_REQUEST,
    payload: req,
  };
}
export function currencySuccess(res) {
  return {
    type: currency.CURRENCY_SUCCESS,
    payload: res,
  };
}
export function currencyError(err) {
  return {
    type: currency.CURRENCY_ERROR,
    payload: err,
  };
}
