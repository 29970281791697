import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { GreenGradientButton } from "../../../../../../components/button";
import { InputLimit } from "../../../../../../constants";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import SMSConfigForm from "../../../../../campaign/containers/campaign-builder/Forms/sms-config.form";
import { CustomCheckBox } from "../../../../../../components/custom-inputs/checkbox-selector";
// import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import SMSEditor from "../../../../../campaign/containers/campaign-builder/editors/sms.editor";
import * as SMSSettingsActions from "../../../../../campaign/store/actions/campaign_sms_settings_actions";
// import { smsSendTypeOptions } from "../../../constants";

export default function SmsStepForm(props) {
  const [senderIdOptions, setSenderIdOptions] = useState([]);
  const [name, setName] = useState("");
  const [selectedSmsSendType, setSelectedSmsSendType] = useState({ value: "CAMPAIGN_API", label: "Campaign api" });
  const [config, setConfig] = useState({ isFlash: false, isUnicode: false });
  const [, setIsTemplate] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [template, setTemplate] = useState("");
  const [defaultVariableValues, setDefaultVariableValues] = useState({});

  const senderIdStore = useSelector((store) => store.campaign.settings.sms.senderId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SMSSettingsActions.getListOfSMSSenderIdsRequest());
  }, [dispatch]);
  useEffect(() => {
    if (!_.isEmpty(config.route)) {
      const configRouteValue = typeof config.route === "string" ? config.route : config.route.value;
      setSenderIdOptions(senderIdStore.data.filter((a) => a.routeName === configRouteValue).map((each) => ({ value: each._id, label: each.senderId })));
    }
  }, [config.route, senderIdStore.data]);

  useEffect(() => {
    if (props.isEdit && !isEmpty(props.selectedStep)) {
      const selectedStep = props.selectedStep;
      const selectedStepSmsContent = selectedStep.smsContent;
      setName(selectedStep.name);
      setConfig({ senderIds: [{ label: selectedStepSmsContent.senderId }], route: selectedStepSmsContent.route, isFlash: selectedStepSmsContent.isFlash });
      setTemplateId(selectedStepSmsContent.templateId);
      setTemplate(selectedStepSmsContent.message);
      setSelectedSmsSendType(selectedStepSmsContent.smsSendType);
    }
  }, [props.selectedStep, props.isEdit]);

  const onSmsStepSaveClick = () => {
    if (!name || !template.trim() || !config.senderIds?.[0]?.label || !config.route || !selectedSmsSendType) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    }

    if (props.isEdit) {
      const payload = {
        ...props.selectedStep,
        name,
        smsContent: {
          senderId: config.senderIds?.[0]?.label,
          route: typeof config.route === "string" ? config.route : config.route.value,
          templateId,
          message: template,
          smsSendType: typeof selectedSmsSendType === "string" ? selectedSmsSendType : selectedSmsSendType.value,
          defaultVariableValues: {},
          isFlash: config.isFlash,
        },
      };
      props.handleEdit(props.selectedIndex, payload);
      return;
    }

    const payload = {
      name,
      executionOrder: props.totalSteps + 1,
      channelType: "SMS",
      isEnabled: true,
      delay: props.totalSteps === 0 ? 300 : 86400,
      smsContent: {
        senderId: config.senderIds?.[0]?.label,
        route: typeof config.route === "string" ? config.route : config.route.value,
        templateId,
        message: template,
        smsSendType: typeof selectedSmsSendType === "string" ? selectedSmsSendType : selectedSmsSendType.value,
        defaultVariableValues: {},
        isFlash: config.isFlash,
      },
    };
    props.handleAdd(payload);
  };

  return (
    <>
      <Form.Group className="mb-0">
        <Form.Label className="mb-8">
          SMS messages are sent from the campaign module. Please ensure that you have set up and tested your messages before adding them here. Please note that the sending of SMS messages depends on the regulations of
          the respective country. If you need assistance, please contact our support team.
        </Form.Label>
      </Form.Group>
      <Form.Group>
        <Form.Label>SMS Name*</Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={"SMS name"} value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Group>
      <SMSConfigForm {...{ config, setConfig, senderIdOptions, setIsTemplate, setTemplateId, setTemplate }} senderIdDropdownProps={{ isMulti: false }} />
      {/* <Form.Group>
        <Form.Label>SMS Send Type*</Form.Label>
        <SimpleDropdown value={selectedSmsSendType} label={"SMS Send Type"} options={smsSendTypeOptions} handleSelect={(a) => setSelectedSmsSendType(a)} />
      </Form.Group> */}
      <Form.Group>
        <SMSEditor {...{ config, setConfig, templateId, template, setIsTemplate, setTemplateId, setTemplate, defaultVariableValues, setDefaultVariableValues }} />
      </Form.Group>
      <Form.Group>
        <CustomCheckBox label={"Send Flash"} isChecked={config.isFlash} onClick={() => setConfig({ ...config, isFlash: !config.isFlash })} info={"Send message as flash message."} />
      </Form.Group>
      <Form.Group className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={onSmsStepSaveClick}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </>
  );
}
