import { takeLatest, put, call, select } from "redux-saga/effects";
import * as activityTypes from "../types/activity.types";
import api from "../../../../network/apis/api";
import * as ActivityDataFieldActions from "../actions/activity_data_field_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";

function* getActivityListFields(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.activity.dataField.list) && store.activity.dataField.list.length > 0) {
      yield put(ActivityDataFieldActions.getActivtyDataFieldsSuccess({ data: store.activity.dataField.list }));
      if (action.cb) yield action.cb(store.activity.dataField.list);
    } else {
      const response = yield call(api.get, "/api/crm/data-fields/get", { fieldType: "ACTIVITY" });
      if (response.status === 200) {
        yield put(ActivityDataFieldActions.getActivtyDataFieldsSuccess(response.data));
      } else if (response.status === 400) {
        dispatchSnackbarError(response.data["message"], "error");
        yield put(ActivityDataFieldActions.getActivtyDataFieldsError(response));
      } else {
        yield put(ActivityDataFieldActions.getActivtyDataFieldsError("error"));
      }
      if (action.cb) yield action.cb(response.data);
    }
  } catch (e) {
    yield put(ActivityDataFieldActions.getActivtyDataFieldsError(e));
  }
}

function* fieldShowHideFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/show-hide-fields", action.payload);
    if (response.status === 200) {
      yield put(ActivityDataFieldActions.showHideActivtyFieldSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityDataFieldActions.showHideActivtyFieldError(response));
    } else {
      yield put(ActivityDataFieldActions.showHideActivtyFieldError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityDataFieldActions.showHideActivtyFieldError({ message: "Could Not Update Field" }));
  }
}

function* fieldUpdatePriorityFunction(action) {
  try {
    const response = yield call(api.put, "/api/crm/data-fields/set-table-priority", action.payload);
    if (response.status === 200) {
      yield put(ActivityDataFieldActions.updateActivityFieldPrioritySuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivityDataFieldActions.updateActivityFieldPriorityError(response));
    } else {
      yield put(ActivityDataFieldActions.updateActivityFieldPriorityError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivityDataFieldActions.updateActivityFieldPriorityError({ message: "Could Not Update Field" }));
  }
}

export default function* ActivityDataFieldSaga() {
  //Activities
  yield takeLatest(activityTypes.getActivityDataField.GET_ACTIVITY_DATA_FIELD_REQUEST, getActivityListFields);
  yield takeLatest(activityTypes.showHideActivityFields.SHOW_HIDE_ACTIVITY_FIELD_REQUEST, fieldShowHideFunction);
  yield takeLatest(activityTypes.updateActivityFieldPriority.UPDATE_ACTIVITY_FIELD_PRIORITY_REQUEST, fieldUpdatePriorityFunction);
}
