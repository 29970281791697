import { getConditions, getActionList } from "../types";

export const getConditionRequest = (payload) => ({
  type: getConditions.GET_LIST_OF_CONDITION_REQUEST,
  payload,
});
export const getConditionSuccess = (payload) => ({
  type: getConditions.GET_LIST_OF_CONDITION_SUCCESS,
  payload,
});
export const getConditionError = (payload) => ({
  type: getConditions.GET_LIST_OF_CONDITION_ERROR,
  payload,
});

export const getActionsRequest = (payload) => ({
  type: getActionList.GET_LIST_OF_ACTIONS_REQUEST,
  payload,
});
export const getActionsSuccess = (payload) => ({
  type: getActionList.GET_LIST_OF_ACTIONS_SUCCESS,
  payload,
});
export const getActionsError = (payload) => ({
  type: getActionList.GET_LIST_OF_ACTIONS_ERROR,
  payload,
});
