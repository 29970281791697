import { fork, all } from "redux-saga/effects";
import { map, unary } from "lodash/fp";
import LeadSaga from "../containers/leads/store/saga/index";
import dealSaga from "../containers/deals/dealPipeline/saga";
import dealListSaga from "../containers/deals/dealList/saga";
import myActivitySaga from "../containers/myactivity/saga";
import PeopleSaga from "../containers/customers/store/saga/index";
import CompanySaga from "../containers/companies/store/saga/index";
import ProfileSettingsSaga from "../containers/Settings/saga/settings.saga";
import LoginSaga from "../containers/modules/Auth/saga";
import ProductSaga from "../containers/products/store/saga/index";
import BillingSaga from "../containers/Settings/Billing/store/saga";

import InsightSaga from "../containers/insights/store/saga/index";
import ImportExportSaga from "../containers/import/saga";
import * as auth from "../containers/modules/Auth/_redux/authRedux";
import SyncSaga from "../containers/Settings/saga/sync.saga";
import ActivitySettingsSaga from "../containers/Settings/saga/activity.saga";
import CustomFieldSaga from "../containers/Settings/saga/custom-fields.saga";
import IntegrationSaga from "../containers/Settings/saga/integrations.saga";
import UsageSaga from "../containers/Settings/saga/usage.saga";
import AdminSettingsSaga from "../containers/Settings/saga/admin-settings.saga";
import LeadBotSaga from "../containers/leadbot/saga";
import NotesSaga from "../containers/Settings/saga/notes.saga";
import ActivitySaga from "../containers/Activities/store/sagas";
import workflowSaga from "../containers/Automation/workflow/saga";
import LearnSaga from "../containers/learn/store/saga/learn.saga";
import CampaignSaga from "../containers/campaign/store/sagas";
import SettingsSaga from "../containers/Settings/store/saga";
import CadenceSaga from "../containers/Automation/cadence/store/saga";
import integrationAppsSaga from "../containers/Integrations/store/sagas";

export function* rootSaga() {
  const _sagas = [
    ...InsightSaga,
    ...ActivitySaga,
    ...CampaignSaga,
    ...BillingSaga,
    ...SettingsSaga,
    ...CadenceSaga,
    ...LeadSaga,
    ...PeopleSaga,
    ...CompanySaga,
    ...ProductSaga,
    ...integrationAppsSaga,
    LearnSaga,
    workflowSaga,
    dealSaga,
    myActivitySaga,
    auth.saga,
    ProfileSettingsSaga,
    LoginSaga,
    ImportExportSaga,
    LeadBotSaga,
    SyncSaga,
    ActivitySettingsSaga,
    CustomFieldSaga,
    IntegrationSaga,
    UsageSaga,
    AdminSettingsSaga,
    NotesSaga,
    dealListSaga,
  ];

  yield all(map(unary(fork), _sagas));
}
