import styled from "styled-components";

const hexToLuma = (colour) => {
  if (!colour) return colour;
  const hex = colour.replace(/#/, "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const value = [0.299 * r, 0.587 * g, 0.114 * b].reduce((a, b) => a + b);

  if (value > 186) return "#000000";
  else return "#ffffff";
};

const setOpacity = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 0.1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const WidgetContainer = styled.div`
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%;
`;

export const Conversation = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const AppFrame = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: 0px auto;
  padding-top: 16px;
  color: rgb(76, 76, 76);
  background: transparent;
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  z-index: 1;
`;

export const AppBackground = styled.div`
  display: inline-block;
  line-height: 0;
  opacity: 1;
  background-color: ${(props) => props.theme.background};
  background-image: url(${(props) => props.theme.backgroundImage});
  background-position: center top;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
`;

export const ConversationMessageList = styled.div`
  position: relative;
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 0px 16px 16px;
  box-sizing: inherit;
`;

export const RootMessageList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MessageListScroller = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const MessageListWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column-reverse;
  min-height: 100%;
  overflow: hidden;
`;

export const MessageContainer = styled.div`
  position: relative;
  opacity: 1;
  transition: opacity 450ms ease-out 0s;
  box-sizing: inherit;
`;

export const AvatarWrapper = styled.figure`
  position: absolute;
  bottom: 5px;
  margin: 0px;
  opacity: 1;
  box-sizing: inherit;
  color: ${(props) => props.theme.question};
`;

export const QuestionWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0%;
  padding: 4px 0px 4px 48px;
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
  box-sizing: inherit;
`;
export const InnerQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  font-size: 14px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: 260px;
  background: ${(props) => props.theme.question};
  color: ${(props) => hexToLuma(props.theme.question)};
  padding: 12px 16px;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  transform-origin: right bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
`;
export const InnerAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 4px;
  font-size: 14px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: 260px;
  margin: 32px 24px 32px 0px;
  padding: 12px 16px;
  color: ${(props) => props.theme.question};
  border: 1px solid ${(props) => props.theme.question};
`;

export const ErrorMessageWrapper = styled.div`
  padding: 12px;
`;

export const ErrorMessage = styled.div`
  padding: 0px 12px 0px 12px;
  line-height: 40px;
  font-size: 13px;
  background: rgb(244, 244, 244);
`;

export const ConversationPoweredBy = styled.div`
  padding: 0px 16px;
  box-sizing: inherit;
`;

export const PoweredByWrapper = styled.div`
  position: relative;
  font-size: 12px;
  text-align: left;
  background: transparent;
  padding: 0px 0px 8px;
  border-bottom: 1px solid rgba(61, 61, 61, 0.1);
  box-sizing: inherit;
`;

export const PoweredByA = styled.a`
  color: ${(props) => props.theme.question};
  text-decoration: none;
  transition: opacity 0.3s ease 0s;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: inherit;
  &:hover {
    opacity: 0.7;
    color: ${(props) => props.theme.question};
  }
`;

export const ConversationFooter = styled.div`
  box-sizing: inherit;
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  position: relative;
  overflow: inherit;
  transition: max-height 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, bottom 400ms cubic-bezier(0.19, 1, 0.22, 1) 200ms, opacity 400ms ease-out 180ms;
  bottom: 0px;
  opacity: 1;
  max-height: 216px; //74px;//var(--max-height);
`;

export const UserInputContainer = styled.div`
  width: 100%;
  box-sizing: inherit;
`;

export const UserInputWrapper = styled.div`
  padding: 0px 16px 16px;
  box-sizing: inherit;
`;
export const InputBox = styled.div`
  box-sizing: inherit;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
`;

export const InputWrapper = styled.form`
  display: flex;
  box-sizing: inherit;
`;

export const SkipButton = styled.button`
  display: none;
  // display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  transition: opacity 0.2s ease 0s;
  border: 1px solid transparent;
  padding: 0px 10px;
  margin-right: 8px;
  color: ${(props) => props.theme.answer};
  background: ${(props) => setOpacity(props.theme.answer, 0.1)};
`;
export const SendButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  padding: 0px;
  transition: opacity 0.2s ease 0s;
  width: 40px;
  margin-left: 8px;
  background: ${(props) => props.theme.button};
  border: 1px solid ${(props) => props.theme.button};
  color: ${(props) => hexToLuma(props.theme.button)};
`;
export const Input = styled.input`
  background: transparent;
  color: ${(props) => props.theme.answer};
  font-size: 16px;
  font-family: inherit;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0px 12px;
  box-shadow: ${(props) => props.theme.answer} 0px 0px 0px 1px inset;
  border: none;
  border-radius: 4px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
`;
export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

export const ChoiceOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
`;

export const ListScroller = styled.div`
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 24px;
  scroll-padding-right: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ListRootColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
`;
export const ListRootRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
`;
export const ListButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 8px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  color: ${(props) => props.theme.answer};
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow: ${(props) => props.theme.answer} 0px 0px 0px 1px inset;
  background: ${(props) => setOpacity(props.theme.answer, 0.1)};
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  &:hover {
    background: ${(props) => setOpacity(props.theme.answer, 0.15)};
  }
`;

export const ListRowButtons = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 0px 0px 8px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  color: ${(props) => props.theme.answer};
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow: ${(props) => props.theme.answer} 0px 0px 0px 1px inset;
  background: ${(props) => setOpacity(props.theme.answer)};
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  flex: 0 0 auto;
`;
export const ThankYouScreenWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

export const TYSContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

export const TYSContentWrapper1 = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0%;
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
`;
export const TYSContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 24px 4px;
  font-size: 14px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: calc(100%);
  color: ${(props) => props.theme.question};
`;

export const WelcomeScreenWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 0px;
  z-index: 1;
  display: ${(props) => (props.theme.type === "chatwidget" ? "table-cell" : "flex")};
  flex-direction: ${(props) => (props.theme.type === "chatwidget" ? "column" : "row")};
`;

export const WSButtonWrapper = styled.div`
  // position: relative;
  width: 100%;
  height: 70px;
  border-radius: 4px;
  background: ${(props) => setOpacity(props.theme.question, 0.1)};
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
  margin: 10px;
`;

export const WSContentWrapper = styled.div`
  position: relative;
  width: 50%;
  overflow-y: auto;
  height: 100%;
  padding:10px;
  display: flex;
  justify-content : center;
  align-items:center;
  flex-direction: column;
  width:${(props) => (props.theme.type === "chatwidget" ? "100%" : "50%")};
  // height:${(props) => (props.theme.type === "chatwidget" ? "70%" : "100%")};
  height: ${(props) => (props.theme.type === "chatwidget" && props.isImageContainer ? "70%" : "100%")};

`;

export const WSImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  height: 100%;
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
  width: ${(props) => (props.theme.type === "chatwidget" ? "100%" : "50%")};
  height: ${(props) => (props.theme.type === "chatwidget" ? "30%" : "100%")};
`;

export const WSImageContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  width: 100%;
  height: 100%;
  background: transparent;
  color: ${(props) => hexToLuma(props.theme.question)};
`;
export const WSImage = styled.img`
  display: block;
  width: inherit;
  height: inherit;
  background: transparent;
`;

export const WSContentWrapper1 = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100% - 70px);
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
  padding: ${(props) => (props.theme.type === "chatwidget" ? "0px" : "20px")};
`;
export const WSContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 24px 4px;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: calc(100%);
  color: ${(props) => props.theme.question};
  padding: 12px 16px;
`;

export const WsButtonContainer = styled.div`
  margin: 0px 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WSDummyButton = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 4px;
`;

export const WsButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 18px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px 0px;
  transition: all 0.2s ease 0s;
  text-align: center;
  cursor: pointer;
  background: ${(props) => props.theme.button};
  border: 1px solid ${(props) => props.theme.button};
  color: ${(props) => hexToLuma(props.theme.button)};
  outline: 0px;

  &:hover {
    opacity: 0.75;
  }
`;
