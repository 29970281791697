import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DeleteIconButton, EditIconButton } from "../../../../../components/button/icon-action-buttons";
import { CustomCheckBox } from "../../../../../components/custom-inputs/checkbox-selector";
import { BackSVG } from "../../../../../components/custom-svgs";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { DeleteModal } from "../../../../../components/modal";
import { InputLimit } from "../../../../../constants";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { reorder } from "../../../../deals/utils";
import { countryOptions, customFieldTypeMapping, timeZoneOptions } from "../constants";

export default function SchedulingCustomFieldsForm(props) {
  const [showAddFieldScreen, setShowAddFieldScreen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // fields
  const [label, setLabel] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [selectedType, setSelectedType] = useState({});
  const [fieldOptions, setFieldOptions] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});

  const fieldStore = useSelector((state) => state.Leads.leadFields);

  useEffect(() => {
    if (fieldStore.list.length > 0) {
      const _op = {};
      const options = [];
      const additionFieldNames = props.data.config.booking.additional_fields.map((each) => each.name);
      fieldStore.list.forEach((each) => {
        if (each.isEditable && !["DATE_RANGE", "DATE_TIME_RANGE", "OWNER_SELECT", "TAG_MULTI_SELECT"].includes(each.fieldInputType)) {
          if (each.fieldGroup === "CUSTOM") {
            _op[`customFields.${each.apiKeyName}`] = each;

            if (!additionFieldNames.includes(`customFields.${each.apiKeyName}`)) options.push({ value: `customFields.${each.apiKeyName}`, label: each.fieldName });
          } else {
            _op[each.apiKeyName] = each;
            if (!additionFieldNames.includes(`${each.apiKeyName}`)) options.push({ value: `${each.apiKeyName}`, label: each.fieldName });
          }
        }
      });
      setFieldMapping(_op);
      setFieldOptions(options);
    }
  }, [fieldStore.list, props.data.config.booking.additional_fields]);

  const updateData = (parentKey, key, value) => {
    const data = {
      ...props.data,
      config: {
        ...props.data.config,
        [parentKey]: {
          ...props.data.config[parentKey],
          [key]: value,
        },
      },
    };
    props.setSelectedData(data);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(props.data.config.booking.additional_fields, result.source.index, result.destination.index);
    updateData("booking", "additional_fields", items);
  };

  const handleBackClick = () => {
    setShowAddFieldScreen(false);
    setIsEdit(false);
    setSelectedIndex(-1);
    setLabel("");
    setSelectedType({});
    setIsRequired(false);
  };

  const getTypeAndDropdownValue = (apiKeyName) => {
    if (fieldMapping[apiKeyName]) {
      const field = fieldMapping[apiKeyName];
      if (field && customFieldTypeMapping[field.fieldInputType]) {
        const type = customFieldTypeMapping[field.fieldInputType];
        if (field.fieldInputType === "COUNTRY_SELECT") {
          return { type: type, dropdown_options: countryOptions };
        } else if (field.fieldInputType === "TIMEZONE_SELECT") {
          return { type: type, dropdown_options: timeZoneOptions };
        } else if (type === "dropdown") {
          return { type: type, dropdown_options: field.options || [] };
        }
        return { type: type, dropdown_options: [] };
      }
      return { type: "text", dropdown_options: [] };
    }
    return { type: "text", dropdown_options: [] };
  };

  const handleCustomFieldEditOrCreate = () => {
    if (_.isEmpty(label)) {
      return dispatchSnackbarError("Please enter question label");
    } else if (_.isEmpty(selectedType)) {
      return dispatchSnackbarError("Please enter question type");
    }
    if (isEdit) {
      const data = props.data.config.booking.additional_fields.map((each, index) => {
        if (index === selectedIndex) {
          const { type, dropdown_options } = getTypeAndDropdownValue(selectedType.value);
          each.label = label;
          each.name = selectedType.value;
          each.required = isRequired;
          each.type = type;
          each.dropdown_options = dropdown_options;
        }
        return each;
      });
      updateData("booking", "additional_fields", data);
      handleBackClick();
    } else {
      const { type, dropdown_options } = getTypeAndDropdownValue(selectedType.value);
      const newData = {
        label: label,
        name: selectedType.value,
        order: props.data.config.booking.additional_fields.length + 1,
        required: isRequired,
        type: type,
        dropdown_options: dropdown_options,
      };
      updateData("booking", "additional_fields", [...props.data.config.booking.additional_fields, newData]);
      handleBackClick();
    }
  };

  const deleteCustomFieldModalProps = {
    show: showDeleteModal,
    title: "Delete Reminder",
    handleClose: () => onHandleClose(),
    handleAccept: () => onDeleteAccept(),
  };

  const onHandleClose = () => {
    setShowDeleteModal(false);
    setSelectedIndex(-1);
  };

  const onDeleteAccept = () => {
    const data = props.data.config.booking.additional_fields.filter((e, i) => i !== selectedIndex);
    updateData("booking", "additional_fields", data);
    setShowDeleteModal(false);
    setSelectedIndex(-1);
  };
  const handleEditCustomFieldClick = (data, index) => {
    const a = fieldMapping[data.name];
    if (a) {
      setSelectedType({ value: a.fieldGroup === "CUSTOM" ? `customFields.${a.apiKeyName}` : a.apiKeyName, label: a.fieldName });
    }
    setSelectedIndex(index);
    setLabel(data.label);
    setIsRequired(data.required);
    setShowAddFieldScreen(true);
    setIsEdit(true);
  };

  return (
    <Form.Group>
      {!showAddFieldScreen ? (
        <Form.Group className="reorder-add-form-wrapper">
          <Form.Label className="d-flex flex-stack mb-8">
            <span>What info should guests provide?</span>
            <Button
              size={"sm"}
              onClick={() => {
                setShowAddFieldScreen(true);
                setIsEdit(false);
              }}
            >
              Add Question{" "}
            </Button>
          </Form.Label>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <div className="w-100">
                    <div className="head-field w-100">
                      <span className="w-17 text-center">Enter Question</span>
                      <span className="w-17 text-center">Map Lead field</span>
                      <span className="w-15 text-center">Actions</span>
                    </div>
                    <div>
                      <div className="field-row btn btn-outline btn-outline-dashed btn-outline-default p-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <span className="w-17 text-center p-5px">Name (Requied)</span>
                        <span className="w-17 text-center">Name</span>
                        <span className="d-flex w-15 justify-content-center">
                          <i class="bi bi-lock-fill"></i>
                        </span>
                      </div>
                      <div className="field-row btn btn-outline btn-outline-dashed btn-outline-default p-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <span className="w-17 text-center p-5px">Email (Requied)</span>
                        <span className="w-17 text-center">Email</span>
                        <span className="d-flex w-15 justify-content-center">
                          <i class="bi bi-lock-fill"></i>
                        </span>
                      </div>
                      {props.data.config.booking.additional_fields.map((item, index) => (
                        <Draggable draggableId={index.toString()} index={index} key={index} type="TASK">
                          {(provided, snapshot) => (
                            <div className="field-row btn btn-outline btn-outline-dashed btn-outline-default p-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <span className="w-17 text-center p-5px">{item.label}</span>
                              <span className="w-17 text-center">{fieldMapping[item.name] ? fieldMapping[item.name].fieldName : ""}</span>
                              <span className="w-15 d-flex justify-content-center">
                                <EditIconButton onClick={() => handleEditCustomFieldClick(item, index)} />
                                <DeleteIconButton
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setSelectedIndex(index);
                                  }}
                                />
                                <span className="import-icon-container">
                                  <i className="fa fa-bars fa-md font-size-18px" />
                                </span>
                              </span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Form.Group>
      ) : (
        <Form>
          <Form.Group>
            <Form.Label className="d-flex align-items-center gap-2 cursor-pointer text-hover-primary" onClick={handleBackClick}>
              {BackSVG} <span>Back</span>
            </Form.Label>
          </Form.Group>
          <Form.Group className="w-50prc">
            <Form.Group>
              <Form.Label>Field Name (Label)</Form.Label>
              <Form.Control type="text" maxLength={InputLimit.TEXT} value={label} onChange={(e) => setLabel(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {" "}
                Map Lead field
                <OverlayTrigger placement="right" overlay={<Tooltip>{'Map it with the lead field, where the submission data will appear under "Active Leads".'}</Tooltip>}>
                  <i className="ml-2 bi bi-info-circle"></i>
                </OverlayTrigger>
              </Form.Label>
              <SimpleDropdown value={selectedType} label={"Select"} options={fieldOptions} handleSelect={setSelectedType} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Requied</Form.Label>
              <CustomCheckBox label={"Required"} isChecked={isRequired} onClick={(e) => setIsRequired(e.target.checked)} />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Button size="sm" onClick={handleCustomFieldEditOrCreate}>
              {!isEdit ? "Create" : "Edit"}
            </Button>
          </Form.Group>
        </Form>
      )}
      <DeleteModal modalProps={deleteCustomFieldModalProps}>Are you sure you want to delete the question?</DeleteModal>
    </Form.Group>
  );
}
