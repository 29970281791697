import { getWalletInfo, createWalletPaymentSession, updateWalletNotify } from "../types/wallet_billing_types";

export const getWalletInfoRequest = (req, cb) => ({
  type: getWalletInfo.GET_WALLET_INFO_REQUEST,
  payload: req,
  cb,
});

export const getWalletInfoSuccess = (res) => ({
  type: getWalletInfo.GET_WALLET_INFO_SUCCESS,
  payload: res,
});

export const getWalletInfoError = (err) => ({
  type: getWalletInfo.GET_WALLET_INFO_ERROR,
  payload: err,
});

export const createWalletPaymentSessionRequest = (req, cb) => ({
  type: createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_REQUEST,
  payload: req,
  cb,
});

export const createWalletPaymentSessionSuccess = (res) => ({
  type: createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_SUCCESS,
  payload: res,
});

export const createWalletPaymentSessionError = (err) => ({
  type: createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_ERROR,
  payload: err,
});

export const updateWalletNotifyRequest = (req, cb) => ({
  type: updateWalletNotify.UPDATE_WALLET_NOTIFY_REQUEST,
  payload: req,
  cb,
});

export const updateWalletNotifySuccess = (res) => ({
  type: updateWalletNotify.UPDATE_WALLET_NOTIFY_SUCCESS,
  payload: res,
});

export const updateWalletNotifyError = (err) => ({
  type: updateWalletNotify.UPDATE_WALLET_NOTIFY_ERROR,
  payload: err,
});
