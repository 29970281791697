import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import OauthConcentScreen from "./screens/concent-screen";

export default function OauthRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/v2/zapier/concent`} component={OauthConcentScreen} />
        <Redirect to={`${match.url}/v2/zapier/concent`} />
      </Switch>
    </>
  );
}
