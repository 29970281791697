import { getActivityDataField } from "../types/activity.types";

const initState = {
  loading: "",
  list: [],
  idToIndex: {},
};

export default function activityDataFieldReducer(state = initState, action = {}) {
  switch (action.type) {
    case getActivityDataField.GET_ACTIVITY_DATA_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getActivityDataField.GET_ACTIVITY_DATA_FIELD_SUCCESS:
      const tempObj = {};
      for (var i = 0; i < action.payload.data.length; i++) {
        tempObj[action.payload.data[i]["_id"]] = i;
      }
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        idToIndex: tempObj,
      };
    case getActivityDataField.GET_ACTIVITY_DATA_FIELD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
