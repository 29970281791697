import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { eventBufferTimeDaysOptions, eventBufferTimeHoursOptions, eventBufferTimeMinutesOptions, infoTextMessages, minCancelNoticeTypeOptions } from "../../constants";

export default function MinCancellationNoticeEventDropdown(props) {
  // duration
  const [eventTimeType, setEventTimeType] = useState({ value: "hours", label: "Hours" });
  const [eventTime, setEventTime] = useState({ value: 0, label: "0" });

  useEffect(() => {
    if (props.value) {
      const data = eventBufferTimeHoursOptions.find((each) => each.duration === props.value);
      if (data) {
        setEventTimeType({ value: "hours", label: "Hours" });
        setEventTime(data);
      } else {
        const data2 = eventBufferTimeDaysOptions.find((each) => each.duration === props.value);
        if (data2) {
          setEventTimeType({ value: "days", label: "Days" });
          setEventTime(data2);
        } else {
          const data3 = eventBufferTimeMinutesOptions.find((each) => each.duration === props.value);
          if (data3) {
            setEventTimeType({ value: "minutes", label: "Minutes" });
            setEventTime(data3);
          }
        }
      }
    }
  }, [props.value]);

  const handleEventTypeChange = (data) => {
    setEventTimeType(data);
    setEventTime({ value: 0, label: "0" });
    props.setValue(0);
  };

  const handlEventTimeSelection = (data) => {
    props.setValue(data.duration);
  };

  return (
    <Form.Group>
      <Form.Label>
        Min. Cancel Notice
        <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.MIN_CANCELLATION_NOTICE}</Tooltip>}>
          <i className="ml-2 bi bi-info-circle"></i>
        </OverlayTrigger>
      </Form.Label>
      <Row>
        <Col>
          <SimpleDropdown
            value={eventTime}
            label={"Select"}
            options={eventTimeType.value === "hours" ? eventBufferTimeHoursOptions : eventTimeType.value === "days" ? eventBufferTimeDaysOptions : eventBufferTimeMinutesOptions}
            handleSelect={(data) => handlEventTimeSelection(data)}
          />
        </Col>
        <Col>
          <SimpleDropdown value={eventTimeType} label={"Select"} options={minCancelNoticeTypeOptions} handleSelect={(a) => handleEventTypeChange(a)} />
        </Col>
      </Row>
    </Form.Group>
  );
}
