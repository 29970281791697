import React from "react";
import "../../styles.css";
import _ from "lodash";

import AddOnList from "../../../../../components/subscription-step2/subscription-addon-list";
import SubscriptionUserList from "../../../../../components/subscription-step2/subscription-user-list";
import CheckoutCard from "../../../../../components/subscription-step2/checkout-card";

export default function SubscriptionStep2(props) {
  const getTotalAmount = () => {
    let cost = 0;
    if (props.agents > 0) {
      cost = cost + (props.selectedMainPlan.amount / 100) * props.agents;
    }
    if (props.recordCount > 0) {
      cost = cost + (props.selectedRecordPlan.amount / 100) * props.recordCount;
    }
    if (props.emailSyncCount > 0) {
      cost = cost + (props.selectedEmailSyncPlan.amount / 100) * props.emailSyncCount;
    }
    return cost;
  };

  return (
    <div className="subscription-step2-page-warpper">
      <div className="subscription-step2-part1-wrapper">
        {!_.isEmpty(props.selectedMainPlan) && (
          <SubscriptionUserList currency={props.currency} currencySymbol={props.currencySymbol} planInterval={props.interval} agents={props.agents} setAgents={props.setAgents} selectedMainPlan={props.selectedMainPlan} />
        )}
        <AddOnList
          currency={props.currency}
          currencySymbol={props.currencySymbol}
          planInterval={props.interval}
          selectedRecordPlan={props.selectedRecordPlan}
          selectedEmailSyncPlan={props.selectedEmailSyncPlan}
          recordCount={props.recordCount}
          setRecordCount={props.setRecordCount}
          emailSyncCount={props.emailSyncCount}
          setEmailSyncCount={props.setEmailSyncCount}
        />
      </div>
      <div className="subscription-step2-part2-wrapper">
        <CheckoutCard
          agents={props.agents}
          recordCount={props.recordCount}
          currencySymbol={props.currencySymbol}
          selectedMainPlan={props.selectedMainPlan}
          selectedRecordPlan={props.selectedRecordPlan}
          selectedEmailSyncPlan={props.selectedEmailSyncPlan}
          emailSyncCount={props.emailSyncCount}
          total={getTotalAmount()}
          continueToPayDisabled={props.continueToPayDisabled}
          handleSubscription={props.handleSubscription}
          isSubscriptionActive={props.isSubscriptionActive}
        />
      </div>
    </div>
  );
}
