import styled from "styled-components";

const hasValue = (value) => (value !== undefined ? true : false);

const InlineStyled = styled.div`
  ${({ styles }) => hasValue(styles?.background) && `background: ${styles?.background} !important;`}
  ${({ styles }) => hasValue(styles?.backgroundColor) && `background-color: ${styles?.backgroundColor} !important;`}
  ${({ styles }) => hasValue(styles?.backgroundImage) && `background-image: ${styles?.backgroundImage} !important;`}

  ${({ styles }) => hasValue(styles?.border) && `border: ${styles?.border} !important;`}
  ${({ styles }) => hasValue(styles?.borderTop) && `border-top: ${styles?.borderTop} !important;`}

  ${({ styles }) => hasValue(styles?.color) && `color: ${styles?.color} !important;`}

  ${({ styles }) => hasValue(styles?.fontFamily) && `font-family: ${styles?.fontFamily} !important;`}

  ${({ styles }) => hasValue(styles?.height) && `height: ${styles?.height} !important;`}

  ${({ styles }) => hasValue(styles?.minHeight) && `min-height: ${styles?.minHeight} !important;`}
  ${({ $styles }) => hasValue($styles?.minWidth) && `min-width: ${$styles?.minWidth} !important;`}

  ${({ styles }) => hasValue(styles?.padding) && `padding: ${styles?.padding} !important;`}
  ${({ styles }) => hasValue(styles?.right) && `right: ${styles?.right} !important;`}

  ${({ styles }) => hasValue(styles?.width) && `width: ${styles?.width} !important;`}

  ${({ styles }) => hasValue(styles?.transform) && `transform: ${styles?.transform} !important;`}

  ${({ styles }) => hasValue(styles?.customColor) && `--color: ${styles?.customColor} !important;`}
  ${({ styles }) => hasValue(styles?.customWidth) && `--custom-width: ${styles?.customWidth} !important;`}
  ${({ styles }) => hasValue(styles?.botColor) && `--bot-color: ${styles?.botColor} !important;`}
  ${({ styles }) => hasValue(styles?.headerBackground) && `--header-background: ${styles?.headerBackground} !important;`}
  ${({ styles }) => hasValue(styles?.bg) && `--bg: ${styles?.bg} !important;`}
  ${({ styles }) => hasValue(styles?.customWidth2) && `--width: ${styles?.customWidth2} !important;`}
  ${({ styles }) => hasValue(styles?.customEllipsis) && `--custom-ellipsis: ${styles?.customEllipsis} !important;`}
`;

export default InlineStyled;
