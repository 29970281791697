import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { verifyCouponCodeRequest } from "../../../actions";
import { InputLimit } from "../../../../../../constants";

export default function UserDetails(props) {
  const [nameTouch, setNameTouch] = useState(false);
  const [emailTouch, setemailTouch] = useState(false);
  const [passwordTouch, setpasswordTouch] = useState(false);

  const dispatch = useDispatch();

  const [showIcon, setshowIcon] = useState(true);
  const history = useHistory();
  const referrer = new URLSearchParams(history.location.search).get("referrer");

  /*************************************************/

  // Props for component
  let { values, next, handleChange, signUp, EmailText } = props;
  /***************************************************/

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const email = query.get("email");
    //eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && re.test(email)) {
      handleChange({ target: { name: "email", value: email } });
    }
  }, [history.location.search, handleChange]);

  const showPassword = () => {
    setshowIcon(false);
    document.getElementById("loginPassword").type = "text";
  };
  const hidePassword = () => {
    setshowIcon(true);
    document.getElementById("loginPassword").type = "password";
  };

  const TouchFunction = (e) => {
    if (e.target.name === "fUllName") {
      setNameTouch(true);
    } else if (e.target.name === "email") {
      setemailTouch(true);
    } else if (e.target.name === "password") {
      setpasswordTouch(true);
    }
  };

  const onCouponCodeBlur = (value) => {
    dispatch(
      verifyCouponCodeRequest({ coupon_code: value }, (data) => {
        if (!data.status) {
          props.setCouponcode("");
        }
      }),
    );
  };

  const [onCheckVal, setonCheckVal] = useState(true);

  const onCheckHandel = (e) => {
    if (e.target.checked === true) {
      setonCheckVal(true);
    } else {
      setonCheckVal(false);
    }
  };

  return (
    <>
      {/*begin::Action*/}
      {referrer === "LimitedTimeDeal" || referrer === "AppSumoDeal" ? null : (
        <>
          <button type="button" onClick={signUp} className="btn btn-light-primary fw-bolder w-100 mb-10">
            <img alt="Logo" src={require("../../../../../../images/google.svg")} className="h-20px me-3" />
            Sign up with Google
          </button>
          {/*end::Action*/}
          {/*begin::Separator*/}
          <div className="d-flex align-items-center mb-10">
            <div className="border-bottom border-gray-300 mw-50 w-100" />
            <span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
            <div className="border-bottom border-gray-300 mw-50 w-100" />
          </div>
        </>
      )}
      {/*end::Separator*/}

      {/* {props.value} */}
      {/*begin::Input group*/}
      <div className="row fv-row mb-7">
        {/*begin::Col*/}
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-12">
          <label className="form-label fw-bolder text-dark fs-6">Full Name</label>
          <input
            maxLength={InputLimit.TEXT}
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="fUllName"
            value={values.FullName}
            onChange={handleChange}
            onClick={(e) => TouchFunction(e)}
            required
            autoComplete="off"
          />
          {values.FullName === "" && nameTouch && (
            <div>
              <span className="text-danger">First Name is Required</span>
            </div>
          )}
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Input group*/}
      {/*begin::Input group*/}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Work Email</label>
        <input
          maxLength={InputLimit.EMAIL}
          className="form-control form-control-lg form-control-solid"
          type="email"
          name="email"
          value={values.Email}
          onChange={handleChange}
          onClick={(e) => TouchFunction(e)}
          required
          autoComplete="off"
        />

        {EmailText === "Email is Valid" && values.Email.length > 0 && (
          <div>
            <span className="text-green">Email is Valid</span>
          </div>
        )}

        {EmailText === "Enter Valid Email" && values.Email.length > 0 && (
          <div>
            <span className="text-danger text-green">The value is not a valid email address</span>
          </div>
        )}

        {values.Email === "" && emailTouch && (
          <div>
            <span className="text-danger">Email is Required</span>
          </div>
        )}
      </div>

      {/*end::Input group*/}
      {/*begin::Input group*/}
      <div className={`mb-${referrer === "LimitedTimeDeal" || referrer === "AppSumoDeal" ? "1" : "10"} fv-row`} data-kt-password-meter="true">
        {/*begin::Wrapper*/}
        <div className="mb-1">
          {/*begin::Label*/}
          <label className="form-label fw-bolder text-dark fs-6">Password</label>
          {/*end::Label*/}
          {/*begin::Input wrapper*/}
          <div className="position-relative mb-3">
            <input
              maxLength={InputLimit.PASSWORD}
              className="form-control form-control-lg form-control-solid"
              type="password"
              id="loginPassword"
              onChange={handleChange}
              name="password"
              value={values.password}
              onClick={(e) => TouchFunction(e)}
              required
              autoComplete="off"
            />

            {showIcon === false && <span onClick={() => hidePassword()} toggle="#password-field" className="bi bi-eye position-relative left-minus-90-percent top-minus-35px font-size-18px"></span>}
            {showIcon === true && <span onClick={() => showPassword()} toggle="#password-field" className="bi bi-eye-slash position-relative left-minus-90-percent top-minus-35px font-size-18px"></span>}

            {values.password === "" && passwordTouch && (
              <div className="position-relative top-minus-20px">
                <span className="text-danger">Password is Required</span>
              </div>
            )}
          </div>
        </div>
        {/*end::Wrapper*/}
        {/*begin::Hint*/}
        <div className="text-muted position-relative top-minus-20px">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
        {/*end::Hint*/}
      </div>
      {/*end::Input group=*/}
      {referrer === "LimitedTimeDeal" || referrer === "AppSumoDeal" ? (
        <>
          {" "}
          <div className="row fv-row mb-10">
            <div className="col-xl-12">
              <label className="form-label fw-bolder text-dark fs-6">Redeem Coupon Code 1</label>
              <input
                maxLength={InputLimit.TEXT}
                className="form-control form-control-lg form-control-solid"
                type="text"
                name="couponcode"
                value={values.couponcode}
                onChange={handleChange}
                onBlur={() => onCouponCodeBlur(values.couponcode)}
              />
            </div>
          </div>
          <div className="row fv-row mb-10">
            <div className="col-xl-12">
              <label className="form-label fw-bolder text-dark fs-6">Redeem Coupon Code 2</label>
              <input
                maxLength={InputLimit.TEXT}
                className="form-control form-control-lg form-control-solid"
                type="text"
                name="couponcode2"
                value={values.couponcode2}
                onChange={handleChange}
                onBlur={() => onCouponCodeBlur(values.couponcode2)}
              />
            </div>
          </div>
          <div className="row fv-row mb-10">
            <div className="col-xl-12">
              <label className="form-label fw-bolder text-dark fs-6">Redeem Coupon Code 3</label>
              <input
                maxLength={InputLimit.TEXT}
                className="form-control form-control-lg form-control-solid"
                type="text"
                name="couponcode3"
                value={values.couponcode3}
                onChange={handleChange}
                onBlur={() => onCouponCodeBlur(values.couponcode3)}
              />
            </div>
          </div>
        </>
      ) : null}
      {/*begin::Input group*/}
      {/* <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
        <input maxLength={InputLimit.PASSWORD} className="form-control form-control-lg form-control-solid"
          type="password"
          onChange={handleChange} name="confirmPassword"
          value={values.confirmPassword} autoComplete="off" />
      </div> */}
      {/*end::Input group*/}
      {/*begin::Input group*/}
      <div className="fv-row mb-10 position-relative top-0">
        <label className="form-check form-check-custom form-check-solid mb-5">
          <input className="form-check-input" type="checkbox" name="toc" onChange={(e) => onCheckHandel(e)} checked={onCheckVal} defaultValue={1} />
          <span className="form-check-label fw-bold text-gray-700 fs-6 ml-57px">
            I agree with
            <a target="_blank" rel="noopener noreferrer" href="https://salessimplify.com/terms-of-service/" className="ms-1 link-primary">
              terms
            </a>{" "}
            &{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://salessimplify.com/privacy-policy/" className="ms-1 link-primary">
              privacy policy
            </a>
          </span>
        </label>
      </div>
      {/*end::Input group*/}
      {/*begin::Actions*/}
      <div className="text-center">
        <button disabled={!onCheckVal} type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary" onClick={() => next()}>
          <span className="indicator-label">Next</span>
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        </button>
      </div>
      {/*end::Actions*/}
    </>
  );
}
