import React from "react";
import ManageEmail from "./email-settings";
import ManageSMS from "./sms-settings";
import ManageWhatsapp from "./whatsapp-settings";
import "./styles.scss";

export default function ManageModule(props) {
  const renderContainers = (channel) => {
    switch (channel) {
      case "email":
        return <ManageEmail />;
      case "sms":
        return <ManageSMS />;
      case "whatsapp":
        return <ManageWhatsapp />;
      default:
        return <></>;
    }
  };
  return <div className="manage-container">{renderContainers(props.channel)}</div>;
}
