import React from "react";

export default function CustomSwitch(props) {
  return (
    <div className="form-check form-switch form-check-custom form-check-solid">
      <input className="form-check-input h-20px w-35px" type="checkbox" checked={props.checked} onChange={props.handleChange} id={props.id} />
      <label className="form-check-label ml-42px" htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  );
}
