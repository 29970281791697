/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import _ from "lodash";
import {
  Link,
  // useHistory
} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
// import { useLang } from "../../../../i18n";
// import LanguageSelectorDropdown from "./LanguageSelectorDropdown";
import * as actions from "../../../../../containers/Settings/actions/settings.actions";
import { useDispatch } from "react-redux";
import "./styles.css";
import {
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../../utils/toaster";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import SubscriptionPlanModal from "../../../../../containers/Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";

export function UserProfileDropdown() {
  //states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ProfilePic, setProfilePic] = useState("");
  const [showPlans, setShowPlans] = useState(false);

  const dispatch = useDispatch();

  //selectors
  const { user } = useSelector((state) => state.auth);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const usageData = useSelector((store) => store.usage.data);

  useEffect(() => {
    dispatch(
      actions.getUserProfileRequest((data) => {
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("fullName", data.data.fullName);
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (userProfile.data) {
      setProfilePic(userProfile.data.avatar);
      setName(userProfile.data.fullName);
      setEmail(userProfile.data.email);
    }
  }, [userProfile.data]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
      viewLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
    };
  }, [uiService]);

  const Signout = () => {
    let logoutObj = {
      logoutMethod: "PASSWORD",
    };
    dispatch(
      actions.LogoutRequest(logoutObj, (data) => {
        if (data["success"]) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("leadOwner");
          localStorage.removeItem("junkLeadOwner");
          localStorage.removeItem("activityListOwner");
          localStorage.removeItem("productOwner");
          localStorage.removeItem("marketingListOwner");
          localStorage.removeItem("companyOwner");
          localStorage.removeItem("peopleOwner");
          localStorage.removeItem("dealOwner");
          window.location.href = "/auth/login";
          dispatchSnackbarSuccess("Successfully Logout", "success");
        }
      }),
    );
  };

  const getPlanEndDays = () => {
    const freetrailenddate = moment(accountBillingStore.data.freeTrialStartedAt).add(accountBillingStore.data.durationOfFreeTrial, "days");
    const difference = freetrailenddate.diff(new Date(), "days");
    return `Trial ${difference} days. `;
  };

  const getTotalRecords = (data) => {
    try {
      return data.companies + data.customers + data.leads;
    } catch (err) {
      return 0;
    }
  };
  const getUserTrialText = () => {
    if (!accountBillingStore) return null;
    else if (accountBillingStore.loading) return null;
    else if (accountBillingStore.data && accountBillingStore.data.isFreeTrialPeriod) {
      return (
        <>
          {" "}
          <div className="border-bottom-width-1px border-bottom-style-solid border-bottom-color-ebedf3"></div>
          <div className="w-100 mr-1 d-flex cursor-pointer">
            <div className="w-100 px-6 py-3 d-flex align-items-center bg-fff5f8 border-radius-6px text-f1416c">
              <div className="ml-6 me-6">
                {getPlanEndDays()}
                {userProfile && userProfile.data && userProfile.data.userType !== "AGENT" && (
                  <span className="ml-2 text-decoration-underline" onClick={() => setShowPlans(true)}>
                    Upgrade
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else if (getTotalRecords(usageData) >= (usageData["recordLimit"] || 0)) {
      return (
        <>
          {" "}
          <div className="border-bottom-width-1px border-bottom-style-solid border-bottom-color-ebedf3"></div>
          <div className="mr-1 w-100 d-flex cursor-pointer">
            <div className="w-100 px-6 py-3 d-flex align-items-center bg-fff5f8 border-radius-6px text-f1416c">
              <div className="ml-6 me-6">
                Limit exceed.
                {userProfile && userProfile.data && userProfile.data.userType !== "AGENT" && (
                  <span className="ml-1">
                    <span className="ml-1 text-decoration-underline" onClick={() => setShowPlans(true)}>
                      Upgrade
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const isBillingExpired = () => {
    // if (accountBillingStore.loading) return false;
    if (_.isEmpty(accountBillingStore.data)) return false;
    const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
    if (isFreeTrialPeriod || isSubscribedForLifeTimeFree) return false;
    else if (["active", "past_due", "trialing"].includes(subscriptionStatus)) return false;
    else if (["none", "canceled", "paused"].includes(subscriptionStatus)) return true;
    return true;
  };
  console.log("u-p-d", isBillingExpired())
  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
          <div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
            <span
              className="symbol symbol-35"
            // className="symbol symbol-35 symbol-light-success"
            >
              <span className={`symbol-label font-size-h5 font-weight-bold ${ProfilePic === undefined ? "bg-121212" : ""}`}>
                {ProfilePic !== undefined && <img alt={name} src={ProfilePic} width="40" height="40" className="border-radius-7px" />}
                {ProfilePic === undefined && <i className="bi bi-person-fill text-white"></i>}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg w-290px">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center p-8 rounded-top mr-top-8">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {user.firstname} {user.lastname}
                  </div>
                  <span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span>
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}

            {!layoutProps.light && (
              <div className="d-flex align-items-center flex-wrap bgi-size-cover bgi-no-repeat rounded-top py-16px px-32px">
                <div className="symbol bg-white-o-15 mr-3">
                  <span className={`symbol-label text-success font-weight-bold font-size-h4 ${ProfilePic === undefined ? "bg-121212" : ""}`}>
                    {ProfilePic !== undefined && <img alt={name} src={ProfilePic} width="50" height="50" className="border-radius-7px" />}
                    {ProfilePic === undefined && <i className="bi bi-person-fill text-white"></i>}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{name}</Tooltip>}>
                    <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5 w-150px overflow-hidden text-overflow-ellipsis text-nowrap fw-600 font-size-18-point-4px">{name}</div>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{email}</Tooltip>}>
                    <div className="w-150px overflow-hidden text-overflow-ellipsis text-nowrap fw-500 font-size-15-point-2px text-a1a5b7">{email}</div>
                  </OverlayTrigger>
                </div>
              </div>
            )}
          </>
          {getUserTrialText()}
          <div className="border-bottom-width-1px border-bottom-style-solid border-bottom-color-ebedf3"></div>
          {(accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.accountStatus === "DEACTIVATED") ||
            isBillingExpired() ? (
            <>
              <div className="navi-footer px-8 py-5">
                <div
                  // to="/auth/login"
                  onClick={() => Signout()}
                  className="btn btn-light-primary font-weight-bold"
                >
                  Sign Out
                </div>
              </div>
            </>
          ) : (
            <div className="navi navi-spacer-x-0 pt-5">
              <Link to="/settings/profile" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                <div className="navi-link profile-item-div">
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer profile-item-name">Profile Settings</div>
                  </div>
                </div>
              </Link>
              <Link to="/settings/profile/login-session" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                <div className="navi-link profile-item-div">
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer profile-item-name">Login Sessions</div>
                  </div>
                </div>
              </Link>
              <Link to="/settings/profile/sync-settings" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                <div className="navi-link profile-item-div">
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer profile-item-name">Email Calendar Sync</div>
                  </div>
                </div>
              </Link>
              <div className="navi-separator mt-3"></div>
              {(userProfile && userProfile.data && userProfile.data.userType === "ADMIN") || (userProfile && userProfile.data && userProfile.data.userType === "OWNER") ? (
                <>
                  <Link to="/settings/account" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125 navi navi-spacer-x-0 pt-5">
                    <div className="navi-link profile-item-div">
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer profile-item-name">Admin Settings</div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/settings/account/team" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                    <div className="navi-link profile-item-div">
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer profile-item-name">Teams</div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/settings/billing/subscription" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                    <div className="navi-link profile-item-div">
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer profile-item-name">
                          Billing
                          {accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.isFreeTrialPeriod && (
                            <span className="ml-3">
                              <div className="beta-container">Free Trial</div>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/settings/integrations" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                    <div className="navi-link profile-item-div">
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer profile-item-name">
                          Integrations
                          <span className="ml-3">
                            <div className="badge badge-light-primary">New</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/settings/account/security" className="navi-item px-8 cursor-pointer bg-hover pr-125 pl-125">
                    {/* // className={userProfile&& userProfile.data && userProfile.data.userType === "AGENT" ? "navi-item px-8 cursor-pointer bg-hover pr-125 pl-125 navi navi-spacer-x-0 pt-5" :"navi-item px-8 cursor-pointer bg-hover pr-125 pl-125"} > */}
                    <div className="navi-link profile-item-div">
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer profile-item-name">Security</div>
                      </div>
                    </div>
                  </Link>{" "}
                </>
              ) : null}

              {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

              <div className="navi-separator mt-3"></div>

              <div className="navi-footer  px-8 py-5 pr-20px pl-20px">
                <div
                  // to="/auth/login"
                  onClick={() => Signout()}
                  className="btn btn-light-primary font-weight-bold"
                >
                  Sign Out
                </div>
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
    </>
  );
}
