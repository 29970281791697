import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BlankSidePanel from "../../../../../components/blank-side-pannel";
import { GreenGradientButton } from "../../../../../components/button";
import { CustomButtonGroupWithIcons } from "../../../../../components/button/custom-group-button";
import { AudienceIconSVG, CopyPastIconSVG, FileUpdateIcinSVG, LeadIconSVG, MarketingListIconSVG, PeopleIconSVG } from "../../../../../components/custom-svgs";
import { useConditionMapping, useFirstDropdownOptions } from "../../../../../components/filter/smart-view-filter/filter-condition-form";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { moduleMapping } from "../../../constants";
import { checkAudienceCountRequest } from "../../../store/actions/campaign_details_actions";
import CopyPasteEditor from "../components/audience-copy-paste";
import AudienceFileUpload from "../components/audience-file-upload";
import AudienceFilter from "../components/audience-filter";
import "./styles.scss";
import { useIsRepeatCampaignInfo } from "..";

const typeOfAudience = [
  { label: "Marketing List", value: "marketing_list", icon: MarketingListIconSVG },
  { label: "Leads", value: "lead", icon: LeadIconSVG },
  { label: "People", value: "people", icon: PeopleIconSVG },
  { label: "Copy paste", value: "copy_paste", icon: CopyPastIconSVG },
  { label: "File upload", value: "file", icon: FileUpdateIcinSVG },
];

export default function AudienceStep2(props) {
  const [show, setShow] = useState(false);
  const [selectedMode, setSelectedMode] = useState({ label: "Marketing List", value: "marketing_list" });
  const [copyPastedData, setCopyPastedData] = useState("");
  const [copyPastDefaultValue, setCopyPasteDefaultValue] = useState("11");
  const [isAudenceSelected, setIsAudienceSelected] = useState(false);
  const [audienceCount, setAudienceCount] = useState(0);
  const [audienceCountLoading, setAudienceCountLoading] = useState(false);

  // filter based
  const [tags, setTags] = useState([]);
  const [filterType, setFilterType] = useState("Existing Filters");
  const [filterId, setFilterId] = useState("");
  const [pattern, setPattern] = useState("");
  const [criteriaConditions, setCriteriaConditions] = useState([]);
  const [moduleName, setModuleName] = useState("lead");

  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const dispatch = useDispatch();

  const whatsappConsumptionStore = useSelector(state => state.campaign.settings.whatsapp.limit);

  useEffect(() => {
    if (!_.isEmpty(selectedMode) && selectedMode.value) {
      setModuleName(moduleMapping[selectedMode.value].moduleName);
      //     setTags([]);
      //     setFilterType("Existing Filters");
      //     setFilterId("");
      //     setPattern("");
      //     setCriteriaConditions([]);
    }
  }, [selectedMode]);

  useEffect(() => {
    if (selectedMode.value === "copy_paste") {
      if (copyPastedData) {
        setAudienceCount(copyPastedData.split("\n").length);
      } else {
        setAudienceCount(0);
      }
    }
  }, [copyPastedData, selectedMode.value]);


  useEffect(() => {
    if (["lead", "people", "marketing_list"].includes(selectedMode.value)) {
      const audience = { mode: selectedMode.value };

      if (filterType === "Existing Filters") {
        if (_.isEmpty(tags) && _.isEmpty(filterId)) {
          return setAudienceCount(0);
        }
        audience["filterId"] = filterId ? filterId : null;
        audience["tags"] = tags;
        audience["filterType"] = "existing";
      } else {
        audience["filter"] = criteriaConditions.map((each) => {
          return {
            type: moduleName,
            field: each.field.id,
            condition: each.comparator,
            value: each.value,
            operator: each.operator,
          };
        });
        audience["pattern"] = pattern;
        audience["filterType"] = "custom";

        if (_.isEmpty(audience["filter"])) {
          return setAudienceCount(0);
        }
      }
      const payload = {
        audienceData: audience,
        type: props.channel === "email" ? "email" : "phone",
      };
      setAudienceCountLoading(true);
      dispatch(
        checkAudienceCountRequest(payload, (data) => {
          if (data.status) {
            setAudienceCount(data.count);
          } else {
            setAudienceCount(0);
          }
          setAudienceCountLoading(false);
        }),
      );
    } else if (selectedMode.value === "copy_paste") {
      setCopyPastedData(props.copyPasteData.join("\n"));
      setAudienceCount(props.copyPasteData.length);
    }
  }, [dispatch, tags, filterType, filterId, selectedMode, criteriaConditions, moduleName, pattern, props.channel, props.copyPasteData]);

  // repeat campaign - start
  const { isRepeatCampaign, repeatCampaignData } = useIsRepeatCampaignInfo();
  useEffect(() => {
    if (!isRepeatCampaign) return;
    const { mode, count, tags, filterType, filterId, pattern } = repeatCampaignData.audience;
    setIsAudienceSelected(true);
    setAudienceCount(count);
    setSelectedMode((prev) => {
      const modeObj = typeOfAudience.find((v) => v.value === mode);
      const areModeAndSelectedModeSame = _.isEqual(modeObj, prev);
      if (!areModeAndSelectedModeSame && modeObj) return modeObj;
      return prev;
    });
    const filterTypeMapper = {
      existing: "Existing Filters",
      custom: "Create New Segment",
    };
    setFilterType(filterTypeMapper[filterType]);
    setTags(tags);
    setFilterId(filterId);
    setPattern(pattern);

    // turn
    // {
    //     "type": "lead",
    //     "field": "63d0e0b4ece0607238018224",
    //     "condition": "is",
    //     "value": "sandip",
    //     "operator": "or"
    //   },

    // to
  }, [isRepeatCampaign, repeatCampaignData.audience]);

  const conditionMapping = useConditionMapping();
  const repeatCampaignDataMode = repeatCampaignData?.audience?.mode;
  const { columns } = useFirstDropdownOptions(moduleMapping[repeatCampaignDataMode]?.moduleName) || [];
  useEffect(() => {
    if (!isRepeatCampaign) return;
    const { filter: audienceFilter } = repeatCampaignData.audience;
    // const { columns } = getFirstDropdownOptions({ moduleName: "lead", dealColumns, companyColumns, peopleColumns, leadColumns, marketingColumns });
    if (!columns) return;
    if (columns && columns.length === 0) return;

    const criteriaConditions = audienceFilter.map((filterItem, i) => {
      const column = columns.find((column) => {
        return column._id === filterItem.field;
      });

      if (!column) return undefined;
      const comparatorOptions = conditionMapping[column.fieldInputType];
      const comparatorLabel = comparatorOptions.find((v) => v.comparator === filterItem.condition).comparatorLabel;

      return {
        comparator: filterItem.condition,
        comparatorLabel,
        index: i + 1,
        field: {
          id: column._id,
          name: column.fieldName,
          fieldType: column.fieldInputType,
          apiKeyName: column.apiKeyName,
          fieldGroup: column.fieldGroup,
        },
        value: filterItem.value,
        valueLabel: filterItem.value,
        operator: filterItem.operator,
      };
    });
    const filteredCriteriaConditions = criteriaConditions.filter((v) => !!v);
    setCriteriaConditions(filteredCriteriaConditions);
    // const moduleName = audience.mode;
    // const mappedAudience = audience.map(v => v.type === moduleName)
    // setAudience();

    // ==== repeat campaign data
    //   "audience": {
    //   "filterId": null,
    //   "fileUrl": null,
    //   "count": 4,
    //   "valid": 4,
    //   "invalid": 0,
    //   "tags": [
    //     "63d0e0b5ece0607238018492"
    //   ],
    //   "mode": "lead",
    //   "filterType": "existing",
    //   "filter": []
    // },

    // === repeat content data - filter array
    // condition: "is";
    // field: "63d0e0b4ece0607238018224";
    // operator: "or";
    // type: "lead";
    // value: "sandip";

    // I need to create
    //   {
    //   "comparator": "is",
    //   "comparatorLabel": "is",
    //   "index": 1,
    //   "field": {
    //     "id": "63d0e0b4ece0607238018224",
    //     "name": "First Name",
    //     "fieldType": "INPUT",
    //     "apiKeyName": "firstName",
    //     "fieldGroup": "SYSTEM"
    //   },
    //   "value": "sandip",
    //   "valueLabel": "sandip",
    //   "operator": "or"
    // },

    // === columns
    // _id
    // addFormPriority: 0;
    // apiKeyName: "firstName";
    // createdAt: "2023-01-25T07:56:36.173Z";
    // createdBy: "63d0e0b2eaa35b73f3b2344e";
    // description: "";
    // fieldGroup: "SYSTEM";
    // fieldInputType: "INPUT";
    // fieldName: "First Name";
    // fieldType: "LEAD";
    // isEditable: true;
    // isEnable: false;
    // isEnabledForTable: true;
    // isRequired: false;
    // isRequiredInAddForm: false;
    // options: [];
    // priority: 6;
    // tablePriority: 1;
    // updatedAt: "2023-01-25T07:56:36.173Z";
    // validation: "";
  }, [isRepeatCampaign, repeatCampaignData.audience, conditionMapping, columns]);

  // // filter based

  // repeat campaign - end

  const clearFunction = () => {
    setCopyPastedData("");
    setAudienceCount(0);
    setCopyPasteDefaultValue("");
  };

  const uniqueFunction = () => {
    const copyPastedDataArray = copyPastedData.split(/,|\s|\n/);
    const uniqueValues = _.filter(_.uniq(_.map(copyPastedDataArray, _.trim)), (o) => o);
    const valueToString = uniqueValues.join("\n");
    setCopyPastedData(valueToString);
    setCopyPasteDefaultValue(valueToString);
    setAudienceCount(uniqueValues.length);
  };

  const handleSave = () => {
    if (_.isEmpty(selectedMode)) {
      return dispatchSnackbarError("Type of audience cannot be empty");
    }

    if (["lead", "people", "marketing_list"].includes(selectedMode.value)) {
      const audience = { mode: selectedMode.value };

      if (filterType === "Existing Filters") {
        if (_.isEmpty(tags) && _.isEmpty(filterId)) {
          return dispatchSnackbarError("Please select the filter!");
        }
        audience["filterId"] = filterId ? filterId : null;
        audience["tags"] = tags;
        audience["filterType"] = "existing";
      } else {
        audience["filter"] = criteriaConditions.map((each) => {
          return {
            type: moduleName,
            field: each.field.id,
            condition: each.comparator,
            value: each.value,
            operator: each.operator,
          };
        });
        audience["pattern"] = pattern;
        audience["filterType"] = "custom";

        if (_.isEmpty(audience["filter"])) {
          return dispatchSnackbarError("Please select the filter!");
        }
      }

      props.setAudience(audience);
      props.setAudienceCount(audienceCount);
      props.setCopyPasteData([]);
      setShow(false);
      setIsAudienceSelected(true);
    } else if (selectedMode.value === "copy_paste") {
      const copyPastedDataArray = copyPastedData.split(/,|\s|\n/);
      const uniqueValues = _.filter(_.uniq(_.map(copyPastedDataArray, _.trim)), (o) => o);
      const audience = { mode: selectedMode.value };
      props.setAudience(audience);
      props.setAudienceCount(uniqueValues.length);
      props.setCopyPasteData(uniqueValues);
      setShow(false);
      setIsAudienceSelected(true);
    } else if (selectedMode.value === "file") {
      if (!uploadedFileUrl) return dispatchSnackbarError("Please provide a CSV file that is valid for upload.");
      const audience = { mode: selectedMode.value, fileUrl: uploadedFileUrl };
      props.setAudience(audience);
      props.setAudienceCount(audienceCount);
      props.setCopyPasteData([]);
      setShow(false);
      setIsAudienceSelected(true);
    }
  };

  const handleModeChange = (data) => {
    setSelectedMode(data);
    setModuleName(moduleMapping[data.value].moduleName);
    setTags([]);
    setFilterType("Existing Filters");
    setFilterId("");
    setPattern("");
    setCriteriaConditions([]);
  };

  return (
    <div className="audience-step2-wrapper">
      <div
        className="audience-step2-inner-wrapper w-100"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <div className="audience-icon-wrapper cursor-pointer text-primary bg-white">{isAudenceSelected ? <i className="bi bi-check text-success fs-xl-2qx"></i> : AudienceIconSVG}</div>
        {isAudenceSelected ? (
          <div className="audience-text-wrapper cursor-pointer w-100">
            <div className="audience-head">Selected Mode: {_.capitalize(selectedMode.value)}</div>
            <div className="audience-content">Total Audience: {audienceCount}</div>
          </div>
        ) : (
          <div className="audience-text-wrapper cursor-pointer w-100">
            <div className="audience-head">Select Audience</div>
            <div className="audience-content">The message will be sent to unique numbers</div>
          </div>
        )}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <BlankSidePanel show={show} title={"Audience"} handleClose={() => setShow(false)}>
          <div className="d-flex flex-start">
            <CustomButtonGroupWithIcons options={typeOfAudience} handleClick={handleModeChange} active={selectedMode} />
          </div>

          <div>
            {selectedMode.value === "file" && (
              <>
                <AudienceFileUpload
                  file={file}
                  totalAudience={audienceCount}
                  type={props.channel === "email" ? "email" : "phone"}
                  setFile={setFile}
                  setAudienceCount={setAudienceCount}
                  setUploadedFileUrl={setUploadedFileUrl}
                  fileUploadLoading={fileUploadLoading}
                  setFileUploadLoading={setFileUploadLoading}
                  setUploadedFileVariables={props.setUploadedFileVariables}
                />
              </>
            )}
            {selectedMode.value === "copy_paste" && (
              <>
                <CopyPasteEditor
                  copyPastDefaultValue={copyPastDefaultValue}
                  copyPastedData={copyPastedData}
                  copyPastedCount={audienceCount}
                  clearFunction={clearFunction}
                  uniqueFunction={uniqueFunction}
                  setCopyPastedData={setCopyPastedData}
                />
              </>
            )}
            {["marketing_list", "lead", "people"].includes(selectedMode.value) && (
              <AudienceFilter
                tags={tags}
                filterType={filterType}
                filterId={filterId}
                pattern={pattern}
                criteriaConditions={criteriaConditions}
                moduleName={moduleName}
                audienceType={selectedMode.value}
                audienceCount={audienceCount}
                audienceCountLoading={audienceCountLoading}
                setTags={setTags}
                setFilterType={setFilterType}
                setFilterId={setFilterId}
                setPattern={setPattern}
                setCriteriaConditions={setCriteriaConditions}
              />
            )}
          </div>
          <Form.Group id="campaign-builder-steps-audience-step2-save-button" className="mt-6">
            <GreenGradientButton disabled={audienceCountLoading || fileUploadLoading} size="sm" onClick={handleSave}>
              Save
            </GreenGradientButton>
          </Form.Group>

          {props.channel==="whatsapp" && !whatsappConsumptionStore.loading && <Form.Group className="mt-2 mb-4 alert alert-secondary">
            Having already sent {whatsappConsumptionStore.count} messages within the daily limit of {props.config.dailyLimit}, you now have the opportunity to reach {(props.config.dailyLimit || 0) - whatsappConsumptionStore.count} recipients via WhatsApp. The campaign will include the initial {(props.config.dailyLimit || 0) - whatsappConsumptionStore.count} recipients from the selected audience, while any additional recipients will not be included and will be skipped
          </Form.Group>}
        </BlankSidePanel>
      </div>
    </div>
  );
}
