import { getListOfWorkflowTemplates, getWorkflowTemplateByID, createWorkflowTemplate, editWorkflowTemplate } from "../types";

export const getListOfWorkflowTemplatesRequest = (payload) => ({
  type: getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_REQUEST,
  payload,
});
export const getListOfWorkflowTemplatesSuccess = (payload) => ({
  type: getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_SUCCESS,
  payload,
});
export const getListOfWorkflowTemplatesError = (payload) => ({
  type: getListOfWorkflowTemplates.GET_LIST_OF_WORKFLOW_TEMPLATES_ERROR,
  payload,
});

export const getListOfWorkflowTemplateByIdRequest = (payload) => ({
  type: getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_REQUEST,
  payload,
});
export const getListOfWorkflowTemplateByIdSuccess = (payload) => ({
  type: getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_SUCCESS,
  payload,
});
export const getListOfWorkflowsByIdError = (payload) => ({
  type: getWorkflowTemplateByID.GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_ERROR,
  payload,
});

export const createWorkfloTemplateRequest = (payload) => ({
  type: createWorkflowTemplate.CREATE_WORKFLOW_TEMPLATE_REQUEST,
  payload,
});
export const createWorkflowTemplateSuccess = (payload) => ({
  type: createWorkflowTemplate.CREATE_WORKFLOW_TEMPLATE_SUCCESS,
  payload,
});
export const createWorkflowTemplateError = (payload) => ({
  type: createWorkflowTemplate.CREATE_WORKFLOW_TEMPLATE_ERROR,
  payload,
});

export const editWorkflowTemplateRequest = (payload) => ({
  type: editWorkflowTemplate.EDIT_WORKFLOW_TEMPLATE_REQUEST,
  payload,
});
export const editWorkflowTemplateSuccess = (payload) => ({
  type: editWorkflowTemplate.EDIT_WORKFLOW_TEMPLATE_SUCCESS,
  payload,
});
export const editWorkflowTemplateError = (payload) => ({
  type: editWorkflowTemplate.EDIT_WORKFLOW_TEMPLATE_ERROR,
  payload,
});
