import React from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { InputLimit, LimitInputNumber2 } from "../../../../constants";

export default function AddGoalForm(props) {
  return (
    <Form>
      <div className="d-flex flex-row flex-wrap">
        <Col className="pt-0 pr-18px pb-0 pl-7px" xs={6}>
          <Form.Group id="insights-components-forms-add-goal-entity-selection">
            <Form.Label aria-label="choose-entity">Choose Entity</Form.Label>
            <Select
              // className="form-select"
              aria-label="Default select example"
              value={props.selectedEntity}
              onChange={(e) => props.onEntitySelect(e)}
              options={props.options}
              styles={props.customStyles}
            />
          </Form.Group>
          {props.selectedEntity.value === "deal" ? (
            <Form.Group id="insights-components-forms-add-goal-choose-goal-type">
              <Form.Label aria-label="choose-goal-type">Choose Goal Type</Form.Label>
              <label className="option">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" name="m_option_1" value="added" checked={props.dealGoalType === "added" ? true : false} onChange={(e) => props.onChooseDealGoalType(e)} />
                    <span></span>
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Added</span>
                  </span>
                  <span>
                    {" "}
                    <span className="option-body"> Based on the number or value of new deals </span>{" "}
                  </span>
                </span>
              </label>
              <label className="option">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" name="m_option_1" value="progressed" checked={props.dealGoalType === "progressed" ? true : false} onChange={(e) => props.onChooseDealGoalType(e)} />
                    <span></span>
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Progressed</span>
                  </span>
                  <span>
                    <span className="option-body">Based on the number or value of deals entering a certain stage</span>
                  </span>
                </span>
              </label>
              <label className="option">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" name="m_option_1" value="won" checked={props.dealGoalType === "won" ? true : false} onChange={(e) => props.onChooseDealGoalType(e)} />
                    <span></span>
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Won</span>
                  </span>
                  <span>
                    <span className="option-body">Based on the number or value of won deals</span>
                  </span>
                </span>
              </label>
            </Form.Group>
          ) : props.selectedEntity.value === "activity" ? (
            <Form.Group id="insights-components-forms-add-goal-goal-type-selection">
              <Form.Label aria-label="choose-goal-type">Choose Goal Type</Form.Label>
              <label className="option">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" name="m_option_12" value="1" checked={props.activityGoalType === "1" ? true : false} onChange={(e) => props.onChooseActivityGoalType(e)} />
                    <span></span>
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Added</span>
                  </span>
                  <span>
                    <span className="option-body">Based on the number of new activities</span>
                  </span>
                </span>
              </label>
              <label className="option">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" name="m_option_12" value="2" checked={props.activityGoalType === "2" ? true : false} onChange={(e) => props.onChooseActivityGoalType(e)} />
                    <span></span>
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Completed</span>
                  </span>
                  <span>
                    <span className="option-body">Based on the number of activities marked as done</span>
                  </span>
                </span>
              </label>
            </Form.Group>
          ) : props.selectedEntity.value === "forecast" ? (
            <Form.Group id="insights-components-forms-add-goal-choosing-goal-type">
              <Form.Label aria-label="choose-goal-type">Choose Goal Type</Form.Label>
              <label className="option">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" name="m_option_1123" value="1" checked={props.forecastGoalType === "1" ? true : false} onChange={(e) => props.onChooseForecastGoalType(e)} />
                    <span></span>
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Revenueforecast</span>
                  </span>
                  <span>
                    <span className="option-body">Based on weighted value of open and won deals</span>
                  </span>
                </span>
              </label>
            </Form.Group>
          ) : null}
          <Form.Group id="insights-components-forms-add-goal-assignee">
            <Form.Label aria-label="assignee">Assignee</Form.Label>
            <Select aria-label="Default select example1" onChange={(e) => props.onAssigneeChange(e)} options={props.assigneeOptions} styles={props.customStyles} value={props.assignee} />
          </Form.Group>
        </Col>
        <Col className="pt-0 pr-18px pb-0 pl-7px" xs={6}>
          <Form.Group id="insights-components-forms-add-goal-pipeline">
            <Form.Label aria-label="pipeline">Pipeline</Form.Label>
            <Select aria-label="Default select example" value={props.selectedPipeline} onChange={(e) => props.onPipelineChange(e)} options={props.pipelineOptions} styles={props.customStyles} />
          </Form.Group>
          <Form.Group id="insights-components-forms-add-goal-tracking-metric">
            <Form.Label aria-label="tracking-metric">Tracking Metric</Form.Label>
            <div className="radio-list ml-20px">
              <label className="radio">
                <input type="radio" name="radios1" value="sum" checked={props.metricsValue === "sum"} onChange={(e) => props.trackingMetFunction(e)} />
                <span></span>
                Value
              </label>
              <label className="radio">
                <input type="radio" name="radios1" value="quantity" checked={props.metricsValue === "quantity"} onChange={(e) => props.trackingMetFunction(e)} />
                <span></span>
                Count
              </label>
            </div>
          </Form.Group>
          <Form.Group id="insights-components-forms-add-goal-interval-change">
            <Form.Label aria-label="interval">Interval</Form.Label>
            <Select aria-label="Default select example" value={props.interval} onChange={(e) => props.onIntervalChange(e)} options={props.intervalOptions} styles={props.customStyles} />
          </Form.Group>
          <Form.Group id="insights-components-forms-add-goal-duration">
            <Form.Label aria-label="duration">Duration</Form.Label>
            <div className="d-flex flex-center ">
              <div className="w-47">
                <input className="form-control" value={props.fromDate ? moment(props.fromDate).format("YYYY-MM-DD") : null} type="date" name="fromDate" onChange={(e) => props.fromDateFun(e)} />
              </div>

              <div className="my-0 mx-2px"> - </div>
              <div className="w-50prc">
                <input className="form-control" value={props.toDate ? moment(props.toDate).format("YYYY-MM-DD") : null} type="date" name="toDate" onChange={(e) => props.toDateFun(e)} />
              </div>
            </div>
          </Form.Group>
          <Form.Group id="insights-components-forms-add-goal-target-value" className="mb-8px">
            <Form.Label aria-label="target-value">Target Value</Form.Label>
            <Form.Control value={props.TargetVal} maxLength={InputLimit.NUMBER} type="number" name="targetName" onChange={(e) => props.targetFunction(LimitInputNumber2(e))} />
          </Form.Group>
        </Col>
      </div>
    </Form>
  );
}
