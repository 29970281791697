import React, { useEffect, useLayoutEffect, useState } from "react";
import _ from "lodash";
import { Card } from "react-bootstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const order = ["Sent", "Delivered", "Undelivered"];
const colors = ["ffcfff", "00C720", "FF6565", "0095F6"];

function PieChart(props) {
  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll('[shape-rendering="auto"]').forEach((e) => e.remove());
    }, 1000);
  });

  useLayoutEffect(() => {
    let chart = am4core.create(props.chartId, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    chart.legend = new am4charts.Legend();

    chart.data = props.won;

    chart.innerRadius = 70;

    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = props.value;
    series.dataFields.category = props.category;
    series.colors.list = ["#00C720", "#FF6565", "#0095F6"].map((each) => am4core.color(each));

    // returned function will be called on component unmount
    return () => {
      chart.dispose();
    };
  }, [props.category, props.value, props.won, props.chartId]);

  return <div id={props.chartId} style={{ width: "inherit", height: "inherit" }}></div>;
}
export default function SMSDetailedAnalyticsReport(props) {
  const [analytics, setAnalytics] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(props.data)) {
      const obj = {};
      props.data.forEach((each) => {
        obj[each.label] = each.value;
      });
      const _analytics = order.map((each) => ({ value: obj[each] ? obj[each] : 0, label: each }));
      _analytics.push({ label: "Submitted", value: (obj["Sent"] || 0) - (obj["Delivered"] || 0) - (obj["Undelivered"] || 0) });
      setAnalytics(_analytics);

      setGraphData([
        { label: "Delivered", value: obj["Delivered"] || 0, color: "#A3FFA6" },
        { label: "Undelivered", value: obj["Undelivered"] || 0, color: "#FF94CC" },
        { label: "Submitted", value: (obj["Sent"] || 0) - (obj["Delivered"] || 0) - (obj["Undelivered"] || 0), color: "#CFDAFF" },
      ]);
    } else {
      setAnalytics([]);
      setGraphData([]);
    }
  }, [props.data]);

  return (
    <div className="w-100 p-6">
      <Card className="w-100 p-6 h-50vh">
        <PieChart won={graphData} chartId={props.campaignId} value={"value"} category={"label"} colorList={[]} />
      </Card>
      <div className="mt-8">
        <div className="row">
          {analytics.map((each, index) => {
            if (each.label === "Total Emails") return <></>;
            return (
              <div key={index} className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className={`border border-1 rounded-2 mb-4 p-6 box-shadow bg-${colors[index]}`}>
                  <div className="fs-2tx">{each.value}</div>
                  <div className="fs-5">{each.label}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
