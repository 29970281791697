import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function NylasFromEmail({ value, setValue, typeOfSelection, isRequired }) {
  const [emailOptions, setEmailOptions] = useState([]);

  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);

  useEffect(() => {
    setEmailOptions(syncSettingsStore.data.map((each) => each.syncedEmail));
  }, [syncSettingsStore.data]);

  const handleSelect = (each) => {
    const updatedValues = [];
    if (typeOfSelection === "single") {
      updatedValues.push(each);
    } else if (typeOfSelection === "multiple") {
      updatedValues.push(...value);
      updatedValues.push(each);
    }
    setEmailOptions(syncSettingsStore.data.map((each) => each.syncedEmail).filter((each) => !updatedValues.includes(each)));
    setValue(updatedValues);
  };
  const removeUserField = (each) => {
    const updatedValues = value.filter((a) => a !== each);
    setEmailOptions(syncSettingsStore.data.map((each) => each.syncedEmail).filter((each) => !updatedValues.includes(each)));
    setValue(updatedValues);
  };

  return (
    <div className="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
      <div className="text-dark-50 mr-2">{"From"}:</div>
      <div className="d-flex align-items-center flex-grow-1 flex-wrap">
        <Dropdown className="mt-0 w-100">
          <Dropdown.Toggle className="d-flex flex-wrap gap-2 align-items-center w-100 b-0 text-left py-10-point-4px px-9-point-6px" variant="transparent">
            {value.length > 0 ? (
              <div className="d-flex gap-2 align-items-center">
                {value.map((each, ind) => {
                  return (
                    <div key={ind} className="badge badge-light-info d-flex gap-2 align-items-center">
                      {each}
                      {!(typeOfSelection === "single" && isRequired) && <i onClick={() => removeUserField(each)} className="bi bi-x"></i>}
                    </div>
                  );
                })}
              </div>
            ) : (
              "Select From Email"
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100-mn">
            <div className="h-25vh-mx position-relative overflow-y-auto">
              {emailOptions.length === 0 && <Dropdown.Header>No Sync Email Found</Dropdown.Header>}
              {emailOptions.map((each, index) => {
                return (
                  <Dropdown.Item key={index} className="dropdown-hover" onSelect={(e) => handleSelect(each)}>
                    {each}
                  </Dropdown.Item>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
