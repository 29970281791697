import {
  LEADS_REQUEST,
  LEADS_SUCCESS,
  LEADS_ERROR,
  LEAD_POPUP_REQUEST,
  LEAD_POPUP_SUCCESS,
  LEAD_POPUP_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  LEADFIELDS_REQUEST,
  LEADFIELDS_SUCCESS,
  LEADFIELDS_ERROR,
  LEADADDFIELDS_REQUEST,
  LEADADDFIELDS_SUCCESS,
  LEADADDFIELDS_ERROR,
  LEADTAGS_REQUEST,
  LEADTAGS_SUCCESS,
  LEADTAGS_ERROR,
  LEADTAGSADD_REQUEST,
  LEADTAGSADD_SUCCESS,
  LEADTAGSADD_ERROR,
  LEADTAGSEDIT_REQUEST,
  LEADTAGSEDIT_SUCCESS,
  LEADTAGSEDIT_ERROR,
  LEADTAGSDELETE_REQUEST,
  LEADTAGSDELETE_SUCCESS,
  LEADTAGSDELETE_ERROR,
  LEADCELLEDIT_REQUEST,
  LEADCELLEDIT_SUCCESS,
  LEADCELLEDIT_ERROR,
  LEADADD_REQUEST,
  LEADADD_SUCCESS,
  LEADADD_ERROR,
  LEADBULKEDIT_REQUEST,
  LEADBULKEDIT_SUCCESS,
  LEADBULKEDIT_ERROR,
  LEADDELETE_REQUEST,
  LEADDELETE_SUCCESS,
  LEADDELETE_ERROR,
  LEADFILTERS_REQUEST,
  LEADFILTERS_SUCCESS,
  LEADFILTERS_ERROR,
  LEADFILTERADD_REQUEST,
  LEADFILTERADD_SUCCESS,
  LEADFILTERADD_ERROR,
  LEADFILTEREDIT_REQUEST,
  LEADFILTEREDIT_SUCCESS,
  LEADFILTEREDIT_ERROR,
  LEADFILTERFAVORITE_REQUEST,
  LEADFILTERFAVORITE_SUCCESS,
  LEADFILTERFAVORITE_ERROR,
  LEADFILTERDELETE_REQUEST,
  LEADFILTERDELETE_SUCCESS,
  LEADFILTERDELETE_ERROR,
  LEADS_UPDATE_REQUEST,
  LEADS_UPDATE_SUCCESS,
  LEADS_UPDATE_ERROR,
  LEAD_POPUP_UPDATE_REQUEST,
  LEAD_POPUP_UPDATE_SUCCESS,
  LEAD_POPUP_UPDATE_ERROR,
  LEAD_POPUP_EDIT_REQUEST,
  LEAD_POPUP_EDIT_SUCCESS,
  LEAD_POPUP_EDIT_ERROR,
  LEAD_CONVERT_TO_JUNK_REQUEST,
  LEAD_CONVERT_TO_JUNK_SUCCESS,
  LEAD_CONVERT_TO_JUNK_ERROR,
  LEAD_CONVERT_TO_ACTIVE_ERROR,
  LEAD_CONVERT_TO_ACTIVE_SUCCESS,
  LEAD_CONVERT_TO_ACTIVE_REQUEST,
  LOADING_DATA_UPDATE,
  LEADSMERGE_REQUEST,
  LEADSMERGE_SUCCESS,
  LEADSMERGE_ERROR,
  APPLYFILTER,
  CLEARFILTER,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  FIELD_SHOWHIDE_REQUEST,
  FIELD_SHOWHIDE_SUCCESS,
  FIELD_SHOWHIDE_ERROR,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_UPDATEPRIORITY_SUCCESS,
  FIELD_UPDATEPRIORITY_ERROR,
  SIGNATURE_GET_REQUEST,
  SIGNATURE_GET_SUCCESS,
  SIGNATURE_GET_ERROR,
  SIGNATURE_ADD_ERROR,
  SIGNATURE_ADD_SUCCESS,
  SIGNATURE_ADD_REQUEST,
  SIGNATURE_EDIT_REQUEST,
  SIGNATURE_EDIT_SUCCESS,
  SIGNATURE_EDIT_ERROR,
  SIGNATURE_DELETE_REQUEST,
  SIGNATURE_DELETE_SUCCESS,
  SIGNATURE_DELETE_ERROR,
  CLEAR_SIGNATURE_STATUS,
  NOTE_ADD_REQUEST,
  NOTE_ADD_SUCCESS,
  NOTE_ADD_ERROR,
  CLEAR_NOTE_STATUS,
  SEARCH_ALL_REQUEST,
  SEARCH_ALL_SUCCESS,
  SEARCH_ALL_ERROR,
  GET_LEADLOSTREASONS_REQUEST,
  GET_LEADLOSTREASONS_SUCCESS,
  GET_LEADLOSTREASONS_ERROR,
  LEAD_POPUP_REQUEST_AFTER,
  LEAD_CONVERT_TO_PEOPLE_SUCCESS,
  LEAD_CONVERT_TO_PEOPLE_REQUEST,
  LEAD_CONVERT_TO_PEOPLE_ERROR,
  generateLeadAIWFData
} from "../types/types";

//LOST REASONS
export function getLeadLostReasonsRequest(req) {
  return {
    type: GET_LEADLOSTREASONS_REQUEST,
    payload: req,
  };
}
export function getLeadLostReasonsSuccess(res) {
  return {
    type: GET_LEADLOSTREASONS_SUCCESS,
    payload: res,
  };
}
export function getLeadLostReasonsError(err) {
  return {
    type: GET_LEADLOSTREASONS_ERROR,
    payload: err,
  };
}

//SEARCH ALL

export function searchAllRequest(req) {
  return {
    type: SEARCH_ALL_REQUEST,
    payload: req,
  };
}
export function searchAllSuccess(res) {
  return {
    type: SEARCH_ALL_SUCCESS,
    payload: res,
  };
}
export function searchAllError(err) {
  return {
    type: SEARCH_ALL_ERROR,
    payload: err,
  };
}

// Getting lead data
export function leadsRequest(req) {
  return {
    type: LEADS_REQUEST,
    payload: req,
  };
}

export function leadsSuccess(res) {
  return {
    type: LEADS_SUCCESS,
    payload: res,
  };
}

export function leadsError(err) {
  return {
    type: LEADS_ERROR,
    payload: err,
  };
}

// Update loading data
export function loadingDataUpdateRequest(req) {
  return {
    type: LOADING_DATA_UPDATE,
    payload: req,
  };
}

// Getting lead popup data
export function leadPopupRequest(req) {
  return {
    type: LEAD_POPUP_REQUEST,
    payload: req,
  };
}
export function leadPopupRequestAfter(req) {
  return {
    type: LEAD_POPUP_REQUEST_AFTER,
    payload: req,
  };
}

export function leadPopupSuccess(res) {
  return {
    type: LEAD_POPUP_SUCCESS,
    payload: res,
  };
}

export function leadPopupError(err) {
  return {
    type: LEAD_POPUP_ERROR,
    payload: err,
  };
}

// Editing lead popup data
export function leadPopupEditRequest(req) {
  return {
    type: LEAD_POPUP_EDIT_REQUEST,
    payload: req,
  };
}

export function leadPopupEditSuccess(res) {
  return {
    type: LEAD_POPUP_EDIT_SUCCESS,
    payload: res,
  };
}

export function leadPopupEditError(err) {
  return {
    type: LEAD_POPUP_EDIT_ERROR,
    payload: err,
  };
}

// Updating lead popup data
export function leadPopupUpdateRequest(req) {
  return {
    type: LEAD_POPUP_UPDATE_REQUEST,
    payload: req,
  };
}

export function leadPopupUpdateSuccess(res) {
  return {
    type: LEAD_POPUP_UPDATE_SUCCESS,
    payload: res,
  };
}

export function leadPopupUpdateError(err) {
  return {
    type: LEAD_POPUP_UPDATE_ERROR,
    payload: err,
  };
}

// Getting lead data without loading
export function leadsUpdateRequest(req) {
  return {
    type: LEADS_UPDATE_REQUEST,
    payload: req,
  };
}

export function leadsUpdateSuccess(res) {
  return {
    type: LEADS_UPDATE_SUCCESS,
    payload: res,
  };
}

export function leadsUpdateError(err) {
  return {
    type: LEADS_UPDATE_ERROR,
    payload: err,
  };
}

// Clearing error and success store
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  };
}

// Getting column details
export function leadFieldsRequest(req) {
  return {
    type: LEADFIELDS_REQUEST,
    payload: req,
  };
}

export function leadFieldsSuccess(res) {
  return {
    type: LEADFIELDS_SUCCESS,
    payload: res,
  };
}

export function leadFieldsError(err) {
  return {
    type: LEADFIELDS_ERROR,
    payload: err,
  };
}

// Getting Add column details
export function leadAddFieldsRequest(req) {
  return {
    type: LEADADDFIELDS_REQUEST,
    payload: req,
  };
}

export function leadAddFieldsSuccess(res) {
  return {
    type: LEADADDFIELDS_SUCCESS,
    payload: res,
  };
}

export function leadAddFieldsError(err) {
  return {
    type: LEADADDFIELDS_ERROR,
    payload: err,
  };
}

// Adding lead
export function leadAddRequest(req) {
  return {
    type: LEADADD_REQUEST,
    payload: req,
  };
}

export function leadAddSuccess(res) {
  return {
    type: LEADADD_SUCCESS,
    payload: res,
  };
}

export function leadAddError(err) {
  return {
    type: LEADADD_ERROR,
    payload: err,
  };
}

// Bulk Editing lead
export function leadBulkEditRequest(req) {
  return {
    type: LEADBULKEDIT_REQUEST,
    payload: req,
  };
}

export function leadBulkEditSuccess(res) {
  return {
    type: LEADBULKEDIT_SUCCESS,
    payload: res,
  };
}

export function leadBulkEditError(err) {
  return {
    type: LEADBULKEDIT_ERROR,
    payload: err,
  };
}

// Deleting lead
export function leadDeleteRequest(req) {
  return {
    type: LEADDELETE_REQUEST,
    payload: req,
  };
}

export function leadDeleteSuccess(res) {
  return {
    type: LEADDELETE_SUCCESS,
    payload: res,
  };
}

export function leadDeleteError(err) {
  return {
    type: LEADDELETE_ERROR,
    payload: err,
  };
}

// Converting to junk lead
export function convertToJunkRequest(req) {
  return {
    type: LEAD_CONVERT_TO_JUNK_REQUEST,
    payload: req,
  };
}

export function convertToJunkSuccess(res) {
  return {
    type: LEAD_CONVERT_TO_JUNK_SUCCESS,
    payload: res,
  };
}

export function convertToJunkError(err) {
  return {
    type: LEAD_CONVERT_TO_JUNK_ERROR,
    payload: err,
  };
}

// converting to people
export function convertToPeopleRequest(req) {
  return {
    type: LEAD_CONVERT_TO_PEOPLE_REQUEST,
    payload: req,
  };
}

export function convertToPeopleSuccess(res) {
  return {
    type: LEAD_CONVERT_TO_PEOPLE_SUCCESS,
    payload: res,
  };
}

export function convertToPeopleError(err) {
  return {
    type: LEAD_CONVERT_TO_PEOPLE_ERROR,
    payload: err,
  };
}

// Converting to active lead
export function convertToActiveRequest(req) {
  return {
    type: LEAD_CONVERT_TO_ACTIVE_REQUEST,
    payload: req,
  };
}

export function convertToActiveSuccess(res) {
  return {
    type: LEAD_CONVERT_TO_ACTIVE_SUCCESS,
    payload: res,
  };
}

export function convertToActiveError(err) {
  return {
    type: LEAD_CONVERT_TO_ACTIVE_ERROR,
    payload: err,
  };
}

// Getting tag details
export function leadTagsRequest(req) {
  return {
    type: LEADTAGS_REQUEST,
    payload: req,
  };
}

export function leadTagsSuccess(res) {
  return {
    type: LEADTAGS_SUCCESS,
    payload: res,
  };
}

export function leadTagsError(err) {
  return {
    type: LEADTAGS_ERROR,
    payload: err,
  };
}

// Adding Tag
export function leadTagsAddRequest(req) {
  return {
    type: LEADTAGSADD_REQUEST,
    payload: req,
  };
}

export function leadTagsAddSuccess(res) {
  return {
    type: LEADTAGSADD_SUCCESS,
    payload: res,
  };
}

export function leadTagsAddError(err) {
  return {
    type: LEADTAGSADD_ERROR,
    payload: err,
  };
}

// Editing Tag
export function leadTagsEditRequest(req) {
  return {
    type: LEADTAGSEDIT_REQUEST,
    payload: req,
  };
}

export function leadTagsEditSuccess(res) {
  return {
    type: LEADTAGSEDIT_SUCCESS,
    payload: res,
  };
}

export function leadTagsEditError(err) {
  return {
    type: LEADTAGSEDIT_ERROR,
    payload: err,
  };
}

// Deleting Tag
export function leadTagsDeleteRequest(req) {
  return {
    type: LEADTAGSDELETE_REQUEST,
    payload: req,
  };
}

export function leadTagsDeleteSuccess(res) {
  return {
    type: LEADTAGSDELETE_SUCCESS,
    payload: res,
  };
}

export function leadTagsDeleteError(err) {
  return {
    type: LEADTAGSDELETE_ERROR,
    payload: err,
  };
}

// Editing Cell
export function leadCellEditRequest(req) {
  return {
    type: LEADCELLEDIT_REQUEST,
    payload: req,
  };
}

export function leadCellEditSuccess(res) {
  return {
    type: LEADCELLEDIT_SUCCESS,
    payload: res,
  };
}

export function leadCellEditError(err) {
  return {
    type: LEADCELLEDIT_ERROR,
    payload: err,
  };
}


// Getting filter data
export function leadFiltersRequest(req) {
  return {
    type: LEADFILTERS_REQUEST,
    payload: req,
  };
}

export function leadFiltersSuccess(res) {
  return {
    type: LEADFILTERS_SUCCESS,
    payload: res,
  };
}

export function leadFiltersError(err) {
  return {
    type: LEADFILTERS_ERROR,
    payload: err,
  };
}

// Adding Filter
export function leadFilterAddRequest(req, cb) {
  return {
    type: LEADFILTERADD_REQUEST,
    payload: req,
    cb,
  };
}

export function leadFilterAddSuccess(res) {
  return {
    type: LEADFILTERADD_SUCCESS,
    payload: res,
  };
}

export function leadFilterAddError(err) {
  return {
    type: LEADFILTERADD_ERROR,
    payload: err,
  };
}

// Editing Filter
export function leadFilterEditRequest(req, cb) {
  return {
    type: LEADFILTEREDIT_REQUEST,
    payload: req,
    cb,
  };
}

export function leadFilterEditSuccess(res) {
  return {
    type: LEADFILTEREDIT_SUCCESS,
    payload: res,
  };
}

export function leadFilterEditError(err) {
  return {
    type: LEADFILTEREDIT_ERROR,
    payload: err,
  };
}

// Favoriting Filter
export function leadFilterFavoriteRequest(req) {
  return {
    type: LEADFILTERFAVORITE_REQUEST,
    payload: req,
  };
}

export function leadFilterFavoriteSuccess(res) {
  return {
    type: LEADFILTERFAVORITE_SUCCESS,
    payload: res,
  };
}

export function leadFilterFavoriteError(err) {
  return {
    type: LEADFILTERFAVORITE_ERROR,
    payload: err,
  };
}

// Deleting Filter
export function leadFilterDeleteRequest(req) {
  return {
    type: LEADFILTERDELETE_REQUEST,
    payload: req,
  };
}

export function leadFilterDeleteSuccess(res) {
  return {
    type: LEADFILTERDELETE_SUCCESS,
    payload: res,
  };
}

export function leadFilterDeleteError(err) {
  return {
    type: LEADFILTERDELETE_ERROR,
    payload: err,
  };
}

// Merging
export function leadsMergeRequest(req) {
  return {
    type: LEADSMERGE_REQUEST,
    payload: req,
  };
}

export function leadsMergeSuccess(res) {
  return {
    type: LEADSMERGE_SUCCESS,
    payload: res,
  };
}

export function leadsMergeError(err) {
  return {
    type: LEADSMERGE_ERROR,
    payload: err,
  };
}

export function applyFilter(res) {
  return {
    type: APPLYFILTER,
    payload: res,
  };
}

export function clearFilter(res) {
  return {
    type: CLEARFILTER,
    payload: res,
  };
}

// Update fields
export function fieldUpdateRequest(req) {
  return {
    type: FIELD_UPDATE_REQUEST,
    payload: req,
  };
}

export function fieldUpdateSuccess(res) {
  return {
    type: FIELD_UPDATE_SUCCESS,
    payload: res,
  };
}

export function fieldUpdateError(err) {
  return {
    type: FIELD_UPDATE_ERROR,
    payload: err,
  };
}

// show/hide field
export function fieldShowHideRequest(req) {
  return {
    type: FIELD_SHOWHIDE_REQUEST,
    payload: req,
  };
}

export function fieldShowHideSuccess(res) {
  return {
    type: FIELD_SHOWHIDE_SUCCESS,
    payload: res,
  };
}

export function fieldShowHideError(err) {
  return {
    type: FIELD_SHOWHIDE_ERROR,
    payload: err,
  };
}

// update field priority
export function fieldUpdatePriorityRequest(req) {
  return {
    type: FIELD_UPDATEPRIORITY_REQUEST,
    payload: req,
  };
}

export function fieldUpdatePrioritySuccess(res) {
  return {
    type: FIELD_UPDATEPRIORITY_SUCCESS,
    payload: res,
  };
}

export function fieldUpdatePriorityError(err) {
  return {
    type: FIELD_UPDATEPRIORITY_ERROR,
    payload: err,
  };
}

// Get Signature
export function signatureRequest(req) {
  return {
    type: SIGNATURE_GET_REQUEST,
    payload: req,
  };
}

export function signatureSuccess(res) {
  return {
    type: SIGNATURE_GET_SUCCESS,
    payload: res,
  };
}

export function signatureError(err) {
  return {
    type: SIGNATURE_GET_ERROR,
    payload: err,
  };
}

// Add Signature
export function signatureAddRequest(req) {
  return {
    type: SIGNATURE_ADD_REQUEST,
    payload: req,
  };
}

export function signatureAddSuccess(res) {
  return {
    type: SIGNATURE_ADD_SUCCESS,
    payload: res,
  };
}

export function signatureAddError(err) {
  return {
    type: SIGNATURE_ADD_ERROR,
    payload: err,
  };
}

// Edit Signature
export function signatureEditRequest(req) {
  return {
    type: SIGNATURE_EDIT_REQUEST,
    payload: req,
  };
}

export function signatureEditSuccess(res) {
  return {
    type: SIGNATURE_EDIT_SUCCESS,
    payload: res,
  };
}

export function signatureEditError(err) {
  return {
    type: SIGNATURE_EDIT_ERROR,
    payload: err,
  };
}

// Delete Signature
export function signatureDeleteRequest(req) {
  return {
    type: SIGNATURE_DELETE_REQUEST,
    payload: req,
  };
}

export function signatureDeleteSuccess(res) {
  return {
    type: SIGNATURE_DELETE_SUCCESS,
    payload: res,
  };
}

export function signatureDeleteError(err) {
  return {
    type: SIGNATURE_DELETE_ERROR,
    payload: err,
  };
}

export function clearSignatureStatus() {
  return {
    type: CLEAR_SIGNATURE_STATUS,
  };
}

// Add Note
export function noteAddRequest(req) {
  return {
    type: NOTE_ADD_REQUEST,
    payload: req,
  };
}

export function noteAddSuccess(res) {
  return {
    type: NOTE_ADD_SUCCESS,
    payload: res,
  };
}

export function noteAddError(err) {
  return {
    type: NOTE_ADD_ERROR,
    payload: err,
  };
}

export function clearNoteStatus() {
  return {
    type: CLEAR_NOTE_STATUS,
  };
}


// generateLeadAIWFData
export function generateLeadAIWFDataRequest(req) {
  return {
    type: generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_REQUEST,
    payload: req,
  };
}

export function generateLeadAIWFDataSuccess(res) {
  return {
    type: generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_SUCCESS,
    payload: res,
  };
}

export function generateLeadAIWFDataError(err) {
  return {
    type: generateLeadAIWFData.GENERATE_LEAD_AIWF_DATA_ERROR,
    payload: err,
  };
}
