import React, { useState, useEffect, useMemo } from "react";
import NormalTable from "../../../../components/normal-table/index";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
import FormInput from "../../../../components/Form-Field/index";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/settings.actions";
import Pagination from "../../../../components/normal-table/pagination";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import { Spinner } from "react-bootstrap";
import {
  //  Dropdown,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import CustomAction from "../../../../components/ActionColumn/index";
import { getListOfUsersRequest } from "../../../insights/actions";
import { DeleteModal } from "../../../../components/modal/index";
import { Redirect } from "react-router";
import { UpgradePlanPage } from "../../../../components/empty_page";
import SubscriptionPlanModal from "../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import * as insightActions from "../../../insights/actions";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";

// custom style for Country Select
const customStyles = {
  control: () => ({
    background: "#F3F6F9",
    // height: '45px',
    padding: "7px",
  }),
  indicatorsContainer: () => ({
    position: "absolute",
    top: "3px",
    right: 0,
    display: "flex",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
  placeholder: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
};

function ButtonHeader(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [teamName, setteamName] = useState("");
  const [teamDesc, setteamDesc] = useState("");
  const [teamDetails, seTeamDetails] = useState([]);
  const [teamManager, setteamManager] = useState([]);
  const [teamMember, setteamMember] = useState([]);
  // const [selectedOption, setselectedOption] = useState('Select Manager')

  const handleClose = () => {
    setShow(false);
    setteamName("");
    setteamDesc("");
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "teamName") {
      setteamName(value);
    } else if (name === "teamDesc") {
      setteamDesc(value);
    }
  };

  function dropChanges(selectedOption) {
    try {
      let newArr = [];
      selectedOption.map((data) => {
        newArr.push(data.value);
        return null;
      });
      setteamManager(newArr);
    } catch (e) { }

    if (selectedOption === null) {
      setteamManager([]);
    }
  }

  function dropChangesMember(selectedOpt) {
    try {
      let newArr = [];
      selectedOpt.map((data) => {
        newArr.push(data.value);
        return null;
      });
      setteamMember(newArr);
    } catch (e) { }

    if (selectedOpt === null) {
      setteamMember([]);
    }
  }

  useEffect(() => {
    seTeamDetails(props.teamDetails);
  }, [props.teamDetails]);

  const AddTeams = () => {
    if (!teamName) {
      dispatchSnackbarError("please enter team name");
      setShow(true);
    } else if (teamManager.length === 0) {
      dispatchSnackbarError("please select team manager");
      setShow(true);
    } else if (!teamDesc) {
      dispatchSnackbarError("please enter team description");
      setShow(true);
    } else if (teamMember.length === 0) {
      dispatchSnackbarError("please select team member");
      setShow(true);
    } else {
      let addTeamObj = {
        name: teamName,
        description: teamDesc,
        manager: teamManager,
        agents: teamMember,
      };
      dispatch(
        actions.postTeamRequest(addTeamObj, (data) => {
          if (data["success"]) {
            try {
              dispatchSnackbarSuccess("Teams Successfully Added", "success");
              dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: false }));
              dispatch(
                actions.getTeamsRequest((data) => {
                  props.set(data.data);
                }),
              );
              setShow(false);
              setteamDesc("");
              setteamName("");
            } catch (e) { }
          }
        }),
      );
    }
  };

  return (
    <>
      <div id="kt_signin_password_button" className="ms-auto" onClick={handleShow}>
        <Button className="commonStyle btn btn-sm btn-primary my-1">+ Team</Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <label >Team Name </label>
            <FormInput placeholder={"Enter team name"} type={"text"} name={"teamName"} value={teamName} onChange={(e) => handleChange(e)} />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <div className="d-flex align-items-center gap-4px">
              <label>Team Manager</label>
              <OverlayTrigger overlay={<Tooltip>{"The team manager will be granted access to the contacts of this team members."}</Tooltip>}>
                <i className="bi bi-info-circle ml-2"></i>
              </OverlayTrigger>
            </div>
            <Select
              styles={customStyles}
              placeholder=""
              // value={options[7]}
              onChange={dropChanges}
              options={teamDetails}
              className="basic-multi-select"
              classNamePrefix="select"
              isMulti
              noOptionsMessage={() => null}
              isValidNewOption={() => false}
            // isClearable
            />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <div className="d-flex align-items-center gap-4px">

              <label >Team Member</label>
              <OverlayTrigger overlay={<Tooltip>{"Contact access for users in this selection will be granted to the team managers selected above."}</Tooltip>}>
                <i className="bi bi-info-circle ml-2"></i>
              </OverlayTrigger>
            </div>
            <Select
              styles={customStyles}
              placeholder=""
              // value={options[7]}
              onChange={dropChangesMember}
              options={teamDetails}
              className="basic-multi-select"
              classNamePrefix="select"
              isMulti
              noOptionsMessage={() => null}
              isValidNewOption={() => false}
            // isClearable
            />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <label>Team Description</label>

            <textarea rows="4" cols="50" name="teamDesc" value={teamDesc} placeholder="Enter team description" className="form-control form-control-lg form-control-solid" onChange={(e) => handleChange(e)}></textarea>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={AddTeams}>
            Add Team
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function TeamsUser() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [teamName, setteamName] = useState("");
  const [teamDesc, setteamDesc] = useState("");
  const [teamDetails, seTeamDetails] = useState([]);
  const [teamManager, setteamManager] = useState("");
  // const [teamMember, setteamMember] = useState([]);
  // const [ApiKeyId, setApiKeyId] = useState('');
  const [agentId, setAgentId] = useState([]);
  // const [checkBoxValue, setCheckBoxValue] = useState(false)
  /***********************Table*******************************/
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const listOfUsers = useSelector((store) => store.insight.users);
  const [selectedEditedTeam, setSelectedEditedTeam] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeletedItem] = useState();

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const [showPlans, setShowPlans] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(
      actions.getTeamsRequest((data) => {
        setLoader(false);
        setComments(data.data);
      }),
    );
    dispatch(getListOfUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (userProfile && userProfile.data && userProfile.data.userType === "AGENT") {
      return <Redirect to="error/error-v6" />;
    } else {
      if (listOfUsers.list.length > 0 && listOfUsers.loading === false) {
        let arr = [];
        listOfUsers.list.map((data) => arr.push({ label: data.email, value: data.userId }));
        seTeamDetails(arr);
      }
    }
  }, [listOfUsers, userProfile]);

  // useEffect(()=>{
  //    /*************Team Details*****************/

  //    dispatch(actions.getManageUserRequest((data) => {

  //     let arr = data.data
  //     arr.filter(obj => {
  //       obj.label = obj.email;
  //       obj.value = obj._id;
  //       return null;
  //     })

  //     seTeamDetails(arr)
  //   }))
  // },[dispatch])

  const commentsData = useMemo(() => {
    let computedComments = comments;
    setTotalItems(computedComments.length);
    // current page slice
    return computedComments.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
  }, [comments, currentPage]);

  /******
   * Delete API
   * ********/

  const deleteAPI = (data) => {
    setShowDeleteModal(true);
    setSelectedDeletedItem(data);
  };

  const onDeleteAccept = () => {
    dispatch(
      actions.deleteTeamsRequest(selectedDeleteItem._id, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Teams Successfully Deleted", "success");
          dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: false }));
          dispatch(
            actions.getTeamsRequest((data) => {
              setComments(data.data);
            }),
          );
          setSelectedDeletedItem();
          setShowDeleteModal(false);
        }
      }),
    );
  };

  /******
   * Edit API
   * *********/
  const handleClose = () => setShow(false);

  const EditAPi = (data) => {
    let arr = data.agents;
    arr.filter((obj) => {
      obj.label = obj.email;
      obj.value = obj._id;
      return null;
    });
    setteamManager(arr);

    setShow(true);
    // setApiKeyId(data._id)
    setteamName(data.name);
    setteamDesc(data.description);

    let arr1 = data.manager;
    arr1.filter((obj) => {
      obj.label = obj.email;
      obj.value = obj._id;
      return null;
    });
    setAgentId(arr1);

    setSelectedEditedTeam(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "teamName") {
      setteamName(value);
    } else if (name === "teamDesc") {
      setteamDesc(value);
    }
  };

  // const dropValueMethod = (e) => {
  //   setteamManager(e.target.value)
  // }

  function dropValueMethod(selectedOption) {
    try {
      let newArr = [];
      selectedOption.map((data) => {
        newArr.push({ label: data.label, value: data.value });
        return null;
      });
      setAgentId(newArr);
    } catch (e) { }

    if (selectedOption === null) {
      setAgentId([]);
    }
  }

  // const teamMemberMethod = (e, index) => {
  // const { id, checked } = e.target;
  // setAgentId([...agentId, id]);
  // if (!checked) {
  //   setAgentId(agentId.filter(item => item !== id));
  // }

  // }

  function teamMemberMethod(selectedOpt) {
    try {
      let newArr = [];
      selectedOpt.map((data) => {
        newArr.push({ label: data.label, value: data.value });
        return null;
      });
      setteamManager(newArr);
    } catch (e) { }

    if (selectedOpt === null) {
      setteamManager([]);
    }
  }

  const EditTeams = () => {
    if (!teamName) {
      dispatchSnackbarError("please enter team name");
      setShow(true);
    } else if (teamManager.length === 0) {
      dispatchSnackbarError("please select team member");
      setShow(true);
    }
    else if (!teamDesc) {
      dispatchSnackbarError("please enter team description");
      setShow(true);
    }
    else if (agentId.length === 0) {
      dispatchSnackbarError("please select team manager");
      setShow(true);
    } else {
      let agents = [],
        managers = [];
      agentId.map((agent) => agents.push(agent.value));
      teamManager.map((manager) => managers.push(manager.value));
      let data = {
        id: selectedEditedTeam._id,
        agents: managers,
        name: teamName,
        description: teamDesc,
        manager: agents,
      };
      dispatch(
        actions.updateTeamRequest(data, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Team Successfully updated", "success");
            dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: false }));
            dispatch(
              actions.getTeamsRequest((data) => {
                setComments(data.data);
              }),
            );
            setShow(false);
          }
        }),
      );
    }
  };

  const deleteTeamModalProps = {
    show: showDeleteModal,
    title: "Delete Team",
    handleClose: () => {
      setShowDeleteModal(false);
      setSelectedDeletedItem();
    },
    handleAccept: () => onDeleteAccept(),
  };

  return (
    <>
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.TEAM_MANAGEMENT ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan==="enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Teams"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : (
        <>
          {commentsData.length === 0 && loader === false ? (
            <CustomEmptyPage page="team" setShowAdd={() => { }} customButton={<ButtonHeader set={setComments} teamDetails={teamDetails} />} />
          ) : (
            <>
              <NormalTable tableHeading={["Team Name", "Team Manager", "Team Members", "Team Description", "Action "]} tableTitle={"Teams"} buttonHead={<ButtonHeader set={setComments} teamDetails={teamDetails} />}>
                {loader === true && (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                )}

                {commentsData.length === 0 && loader === false && (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <h6>No Data To Show</h6>
                    </td>
                  </tr>
                )}
                {commentsData.map((user, id) => {
                  return (
                    <tr key={id}>
                      <td>{user.name}</td>

                      <td>
                        {user.manager.length > 0 ? (
                          user.manager.map((user, id) => {
                            return (
                              <>
                                <span key={id}>{user.email}</span>
                                <br></br>
                              </>
                            );
                          })
                        ) : (
                          <span>{""}</span>
                        )}
                      </td>
                      <td>
                        {user.agents.length > 0 ? (
                          user.agents.map((user, id) => {
                            return (
                              <>
                                <span key={id}>{user.email}</span>
                                <br></br>
                              </>
                            );
                          })
                        ) : (
                          <span>{""}</span>
                        )}
                      </td>

                      <td>{user.description}</td>

                      <td>
                        <div className="d-flex w-150px">
                          <CustomAction Edit={() => EditAPi(user)} Delete={() => deleteAPI(user)} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </NormalTable>

              <div className="position-absolute right-20px mt-minus-21px">
                <Pagination total={totalItems} itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
              </div>
            </>
          )}

          <DeleteModal modalProps={deleteTeamModalProps}>Are you sure you want to delete Team?</DeleteModal>

          {/* ************************************************************ */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Teams</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-lg-12 fv-row">
                <label>Team Name</label>
                <FormInput placeholder={"Enter team name"} type={"text"} name={"teamName"} value={teamName} onChange={(e) => handleChange(e)} />
              </div>

              <br></br>

              <div className="col-lg-12 fv-row">
                <div className="d-flex align-items-center gap-4px">
                  <label>Team Manager</label>
                  <OverlayTrigger overlay={<Tooltip>{"The team manager will be granted access to the contacts of this team members."}</Tooltip>}>
                    <i className="bi bi-info-circle ml-2"></i>
                  </OverlayTrigger>
                </div>
                <Select
                  styles={customStyles}
                  placeholder=""
                  defaultValue={agentId}
                  onChange={dropValueMethod}
                  options={teamDetails}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                  noOptionsMessage={() => null}
                  isValidNewOption={() => false}
                // isClearable
                />
              </div>
              <br></br>

              <div className="col-lg-12 fv-row">
                <div className="d-flex align-items-center gap-4px">
                  <label >Team Member</label>
                  <OverlayTrigger overlay={<Tooltip>{"Contact access for users in this selection will be granted to the team managers selected above."}</Tooltip>}>
                    <i className="bi bi-info-circle ml-2"></i>
                  </OverlayTrigger>
                </div>                <Select
                  styles={customStyles}
                  placeholder=""
                  defaultValue={teamManager}
                  onChange={teamMemberMethod}
                  options={teamDetails}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isMulti
                  noOptionsMessage={() => null}
                  isValidNewOption={() => false}
                // isClearable
                />
              </div>
              <br></br>

              <div className="col-lg-12 fv-row ">
                <label>Team Description</label>

                <textarea rows="4" cols="50" name="teamDesc" value={teamDesc} placeholder="Enter team description" className="form-control form-control-lg form-control-solid" onChange={(e) => handleChange(e)}></textarea>
              </div>


            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="btn btn-sm btn-primary my-1" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={EditTeams}>
                Edit Teams
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}
