//package
import { takeLatest, put, call } from "redux-saga/effects";

//api & toasters
import api from "../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";

//actions & types
import * as notesActions from "../actions/notes.actions";
import {
  ADD_NOTES_REQUEST,
  EDIT_NOTES_REQUEST,
  DELETE_NOTES_REQUEST,
  GET_NOTES_REQUEST,
  GET_NOTES_BY_ID_REQUEST,
  GET_LINKED_ACTIVITIES_REQUEST,
  GET_LINKED_EMAILS_REQUEST,
  getUpcomingActivities,
} from "../types/notes.types";

function* getNotesFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/activity/notes", action.payload);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.GetNotesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.GetNotesError(response.data));
    } else {
      dispatchSnackbarError("Error while getting notes");
      yield put(notesActions.GetNotesError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while getting notes");
    yield put(notesActions.GetNotesError(e));
  }
}

function* addNotesFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/activity/notes", action.payload.body);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.AddNotesSuccess(response.data));
      yield put(notesActions.GetNotesRequest(action.payload.request));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.AddNotesError(response.data));
    } else {
      dispatchSnackbarError("Error while adding note");
      yield put(notesActions.AddNotesError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while adding note");
    yield put(notesActions.AddNotesError(e));
  }
}
function* editNotesFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/activity/notes/update", action.payload.body);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.EditNotesSuccess(response.data));
      yield put(notesActions.GetNotesRequest(action.payload.request));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.EditNotesError(response.data));
    } else {
      dispatchSnackbarError("Error while editing note");
      yield put(notesActions.EditNotesError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while editing note");
    yield put(notesActions.EditNotesError(e));
  }
}
function* deleteNotesFunction(action) {
  try {
    const response = yield call(api.delete, `/api/crm/activity/notes/${action.payload.id}`);
    if (response.status === 200) {
      dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.DeleteNotesSuccess(response.data));
      yield put(notesActions.GetNotesRequest(action.payload.request));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.DeleteNotesError(response.data));
    } else {
      dispatchSnackbarError("Error while deleting note");
      yield put(notesActions.DeleteNotesError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while deleting note");
    yield put(notesActions.DeleteNotesError(e));
  }
}
function* getNotesByIdFunction(action) {
  try {
    const response = yield call(api.get, `/api/crm/activity/notes/${action.payload.id}`);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.GetNotesByIdSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.GetNotesByIdError(response.data));
    } else {
      dispatchSnackbarError("Error while getting note");
      yield put(notesActions.GetNotesByIdError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while getting note");
    yield put(notesActions.GetNotesByIdError(e));
  }
}

function* getLinkedEmailFunction(action) {
  try {
    const response = yield call(api.get, `/api/crm/activity/email`, action.payload);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.GetLinkedEmailsSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.GetLinkedEmailsError(response.data));
    } else {
      dispatchSnackbarError("Error while getting note");
      yield put(notesActions.GetLinkedEmailsError("error"));
    }

    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    dispatchSnackbarError("Error while getting note");
    yield put(notesActions.GetLinkedEmailsError(e));
  }
}

function* getLinkedActivitiesFunction(action) {
  try {
    const response = yield call(api.get, `/api/crm/activity-list/linked-activities`, action.payload);
    if (response.status === 200) {
      // dispatchSnackbarSuccess(response.data.message);
      yield put(notesActions.GetLinkedActivitiesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.GetLinkedActivitiesError(response.data));
    } else {
      dispatchSnackbarError("Error while getting note");
      yield put(notesActions.GetLinkedActivitiesError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while getting note");
    yield put(notesActions.GetLinkedActivitiesError(e));
  }
}

function* getUpcomingActivitiesFunction(action) {
  try {
    const response = yield call(api.get, `/api/crm/activity-list/upcoming-activities`, action.payload);
    if (response.status === 200) {
      yield put(notesActions.GetUpcomingActivitiesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(notesActions.GetUpcomingActivitiesError(response.data));
    } else {
      dispatchSnackbarError("Error while getting note");
      yield put(notesActions.GetUpcomingActivitiesError("error"));
    }
  } catch (e) {
    dispatchSnackbarError("Error while getting note");
    yield put(notesActions.GetUpcomingActivitiesError(e));
  }
}

export default function*() {
  yield takeLatest(ADD_NOTES_REQUEST, addNotesFunction);
  yield takeLatest(EDIT_NOTES_REQUEST, editNotesFunction);
  yield takeLatest(DELETE_NOTES_REQUEST, deleteNotesFunction);
  yield takeLatest(GET_NOTES_REQUEST, getNotesFunction);
  yield takeLatest(GET_NOTES_BY_ID_REQUEST, getNotesByIdFunction);

  yield takeLatest(GET_LINKED_EMAILS_REQUEST, getLinkedEmailFunction);
  yield takeLatest(GET_LINKED_ACTIVITIES_REQUEST, getLinkedActivitiesFunction);
  yield takeLatest(getUpcomingActivities.GET_UPCOMING_ACTIVITIES_REQUEST, getUpcomingActivitiesFunction);
}
