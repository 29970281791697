import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { UpgradePlanPage } from "../../../../../components/empty_page";
import SubscriptionPlanModal from "../../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import { Button } from "react-bootstrap";
import GoogleImage from "../../../../../images/google.svg";
import MicrosoftImage from "../../../../../images/microsoft-5.svg";
import * as syncSettingActions from "../../../store/actions/profile-actions/sync-settings.actions";
import { useHistory } from "react-router-dom";

export default function SchedulingCalendarList() {
  const [showPlans, setShowPlans] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
  }, [dispatch]);

  const handleOpenSettingClick = (syncId) => {
    history.push(`/settings/profile/scheduler/${syncId}`);
  };
  return (
    <>
      {accountBillingStore.data.servicesOffered && (!accountBillingStore.data.servicesOffered.CALENDAR_SYNC || ["free", "startup"].includes(accountBillingStore.data.selectedPlan.toLowerCase())) ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Meeting Scheduler"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : (
        <div>
          {syncSettingsStore.loading ? (
            <ContentWithCardLoading />
          ) : _.isEmpty(syncSettingsStore.data) ? (
            <CustomEmptyPage page={"schedularCalendarConnect"} />
          ) : (
            <div className="card mb-5 mb-xl-10 h-vh-160px">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0 d-flex flex-stack w-100">
                  <h3 className="fw-bolder m-0">Meeting Scheduler</h3>
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9 overflow-y-auto h-vh-220px-mx">
                  {syncSettingsStore.data.map((each, index) => {
                    return (
                      <div className="add-new-account-div" key={index}>
                        <div className="add-new-account-wrapper">
                          <div className="d-flex align-items-center">
                            <img alt="Logo" src={each.nylasAccountData && each.nylasAccountData.provider === "gmail" ? GoogleImage : MicrosoftImage} className="h-30px me-4" />
                            <div className="add-new-account-text-div">
                              <div className="add-new-account-text text-hover-primary cursor-pointer" onClick={() => handleOpenSettingClick(each._id)}>
                                {each.syncedEmail}
                              </div>
                              <div>{_.capitalize(each.syncState)}</div>
                            </div>
                          </div>
                          <div>
                            <Button size="sm" variant="link" className="mr-4" onClick={() => handleOpenSettingClick(each._id)}>
                              Open Scheduling Pages
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
