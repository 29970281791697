import React, { useState, useEffect } from "react";
import { CustomScrollableModal } from "../../../../components/modal";
import OwnerSelect from "../../../../components/custom_form/owners";
import CompanySelect from "../../../../components/custom_form/organization";
import LeadPersonSelect from "../../../../components/custom_form/leadPerson";
import { Form, Col, Dropdown } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import { LeadStateWrapper, StateItem } from "./styled";
import TagSelect from "../../../../components/custom_form/tags";
import { useDispatch } from "react-redux";
import * as actions from "../../dealPipeline/actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import CurrencySelect from "../../../../components/custom_form/currency";
import { useSelector } from "react-redux";
import { country_code_map } from "../../../../_metronic/_assets/js/countryCode";
import { InputLimit } from "../../../../constants";
import _ from "lodash";
import CustomInput from "../../../../components/custom_form/inputs";
import * as dealListActions from "../../dealList/actions";
import AddProductForm from "./AddProductForm";

export function AddDealModal(props) {
  const [dealName, setDealName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLabel, setCompanyLabel] = useState("");
  const [leadName, setLeadName] = useState(props.leadId ? props.leadId : "");
  const [leadLabel, setLeadLabel] = useState("");
  const [leadData, setLeadData] = useState({});
  const [phoneNumArray, setPhoneNumArray] = useState([{ phone: "", field: 0 }]);
  const [defaultCode, setDefaultCode] = useState("91");
  const [emailArray, setEmailArray] = useState([{ email: "", field: 0 }]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [totalDealValue, setTotalDealValue] = useState(0);
  const [addProducts, setAddProducts] = useState(false);
  const [isEmailPhoneDisable, setIsEmailPhoneDisable] = useState(false);
  const [productsArray, setProductsArray] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [selectedPipelineId, setSelectedPipelineId] = useState("");
  const [stages, setStages] = useState([]);
  const [currentState, setCurrentState] = useState(props && props.dealModalProps && props.dealModalProps.stageId ? props.stageId : stages && stages[0] && stages[0]._id);
  const [expectedCloseDate, setExpectedCloseDate] = useState("");
  const [emailError, setEmailError] = useState("");
  const [tags, setTags] = useState([]);
  const [customFormData, setCustomFormData] = useState({});
  const dispatch = useDispatch();
  const [expectedDate, setExpectedDate] = useState("");
  const [defaultProfileCurrency, setDefaultProfileCurrency] = useState("INR");
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const profile = useSelector((state) => state.ProfileSettings.getProfileDetails.data);
  const dealAddFormFields = useSelector((state) => state.DealList.addForm.list);
  const profileStore = useSelector((store) => store.ProfileSettings.getProfileDetails.data);

  useEffect(() => {
    dispatch(dealListActions.getDealAddFormFieldsRequest({ request: { fieldType: "DEAL" }, skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (profile.country) {
      const defaultData = country_code_map[profile.country.toLowerCase()];
      if (defaultData) {
        setDefaultCode(defaultData.code);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (props.dealModalProps.selectedPipelineId) {
      setSelectedPipeline(props.dealModalProps.selectedPipeline);
      setSelectedPipelineId(props.dealModalProps.selectedPipelineId);
    }
  }, [props.dealModalProps.selectedPipeline, props.dealModalProps.selectedPipelineId]);

  useEffect(() => {
    const pipelineswithstages = props.dealModalProps.pipelineswithstages;
    if (pipelineswithstages && pipelineswithstages.length > 0) {
      for (const pipelineStages of pipelineswithstages) {
        if (pipelineStages._id === props.dealModalProps.selectedPipelineId) {
          setStages(pipelineStages.stages);
          break;
        }
      }
    }
  }, [props.dealModalProps.pipelineswithstages, props.dealModalProps.selectedPipelineId]);

  useEffect(() => {
    const _selectedCurrentState = props.dealModalProps.selectedCurrentState;
    const _stageId = props.dealModalProps.stageId;
    const _currentStage = _selectedCurrentState ? _selectedCurrentState : _stageId ? _stageId : stages && stages[0] && stages[0]._id;
    setCurrentState(_currentStage);
  }, [props.dealModalProps.selectedCurrentState, stages, selectedPipeline, props.dealModalProps.stageId]);

  useEffect(() => {
    if (!selectedOwner) {
      setSelectedOwner(props.dealModalProps.ownerId);
    }
  }, [props.dealModalProps.ownerId, selectedOwner]);

  useEffect(() => {
    if (!_.isEmpty(profileStore) && profileStore.currency) {
      setSelectedCurrency(profileStore.currency.toUpperCase());
      setDefaultProfileCurrency(profileStore.currency.toUpperCase());
    }
  }, [profileStore]);

  const onAddDealModalClose = () => {
    props.dealModalProps.onAddDealModalClose();
    setDealName("");
    setCompanyName("");
    setPhoneNumArray([{ phone: "", field: 0 }]);
    setEmailArray([{ email: "", field: 0 }]);
    setTotalDealValue(0);
    setProductsArray([]);
    setExpectedCloseDate("");
    setLeadName(props.leadId ? props.leadId : "");
    setTags([]);
    setSelectedCurrency(defaultProfileCurrency);
    setAddProducts(false);
    setCustomFormData({});
  };
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const addTag = (name, colorCode) => {
    dispatch(actions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(actions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode, type: "deal" }));
  };

  const deleteTag = (id) => {
    dispatch(actions.deleteDealTagRequest(id, "deal"));
  };
  const setValue = (value) => {
    setTags(value);
  };

  const onAddDealModalSave = () => {
    if (!dealName || !selectedOwner || !selectedPipelineId || !currentState) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    }
    if (!dealName) {
      return dispatchSnackbarError("Please enter deal Name!");
    }
    // if (!companyName && !leadData.type) {
    //     return dispatchSnackbarError("Please select either Person or Company")
    // }
    if (!selectedOwner) {
      return dispatchSnackbarError("Please select Owner");
    }
    if (!selectedPipelineId) {
      return dispatchSnackbarError("Please select Pipeline");
    }
    if (!currentState) {
      return dispatchSnackbarError("Please select Pipeline stage");
    }
    const values = {
      title: dealName,
      company: companyName,
      phone: phoneNumArray,
      email: emailArray,
      owner: selectedOwner,
      dealValue: totalDealValue,
      products: productsArray,
      pipelineId: selectedPipelineId,
      stageId: currentState,
      expectedCloseDate: expectedCloseDate,
      // "label": currentVal,
      tags: tags,
      visibleTo: "Public",
      dealCurrency: selectedCurrency,
      leadData: leadData,
      from: props.dealModalProps.from,
      customFields: customFormData,
    };
    props.dealModalProps.onAddDealModalSave(values);
    setDealName("");
    setCompanyName("");
    setCompanyLabel("");
    setPhoneNumArray([{ phone: "", field: 0 }]);
    setEmailArray([{ email: "", field: 0 }]);
    setTotalDealValue(0);
    setProductsArray([]);
    setExpectedCloseDate("");
    setLeadName(props.leadId ? props.leadId : "");
    setTags([]);
    setCustomFormData({});
    setSelectedCurrency(defaultProfileCurrency);
  };

  const onAddDealName = (e) => {
    setDealName(e.target.value);
    // props.dealModalProps.onAddDealName(e.target.value)
  };

  const onAddOrganization = (value, keyName, name) => {
    setCompanyName(value);
    setDealName(`${name} Deal`);
    setCompanyLabel("");
    // props.dealModalProps.onAddOrganization(e.target.value)
  };
  const onAddLead = (type, value) => {
    setLeadData({ ...value, type });
    setLeadLabel("");
    if (type === "") {
      setLeadName("");
      setIsEmailPhoneDisable(false);
      if (!dealName) {
        setDealName(` Deal`);
      }
    } else if (type === "new") {
      setLeadName(value.value);
      setIsEmailPhoneDisable(false);
      if (!dealName) {
        setDealName(`${value.value} Deal`);
      }
    } else if (type === "old") {
      setLeadName(value.name);
      setIsEmailPhoneDisable(true);
      if (value.email.length > 0) {
        const newArray = [];
        value.email.forEach((each, index) => {
          newArray.push({ email: each, field: index });
        });
        if (newArray.length > 0) setEmailArray(newArray);
      }
      if (!dealName) {
        setDealName(`${value.name} Deal`);
      }
      if (value.phone.length > 0) {
        const newArray1 = [];
        value.phone.forEach((each, index) => {
          newArray1.push({ phone: each.toString(), field: index });
        });
        if (newArray1.length > 0) setPhoneNumArray(newArray1);
      }
      if (!_.isEmpty(value.company) && typeof value.company === "object" && value.company.name) {
        setCompanyName(value.company._id);
        setDealName(`${value.company.name} Deal`);
        setCompanyLabel(value.company.name);
      }
    }
  };

  const onPhoneNumberAdd = (e, field) => {
    const updatedArray = phoneNumArray;
    updatedArray[field].phone = e;
    setPhoneNumArray(updatedArray);
  };

  const onPhoneNUmerFieldDelete = (deleteIndex) => {
    const newArray = phoneNumArray.filter((item) => item.field !== deleteIndex);
    setPhoneNumArray(newArray);
  };

  const onAddOneMore = (field) => {
    if (field === "phone" && !isEmailPhoneDisable) {
      setPhoneNumArray([...phoneNumArray, { phone: "", field: phoneNumArray.length }]);
    } else if (field === "email" && !isEmailPhoneDisable) {
      setEmailArray([...emailArray, { email: "", field: emailArray.length }]);
    } else if (field === "products") {
      // setSelectedProduct("")
      setProductsArray([...productsArray, { productId: "", itemName: "", unitPrice: 0, quantity: 1, amount: 0, field: productsArray.length, currency: "" }]);
    }
  };

  const onEmailFieldAdd = (e, field) => {
    const newArray = emailArray;
    newArray[field].email = e.target.value;
    setEmailArray(newArray);

    let isValid = true;
    newArray.forEach((each) => {
      if (!validateEmail(each.email)) {
        isValid = false;
      }
    });
    if (!isValid) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const onEmailFieldDelete = (deleteIndex) => {
    const newArray = emailArray.filter((item) => item.field !== deleteIndex);
    setEmailArray(newArray);
  };

  const onOwnerChange = (value) => {
    setSelectedOwner(value);
  };

  const onCurrencySelect = (value) => {
    setSelectedCurrency(value);
    if (addProducts) {
      setProductsArray([{ productId: "", itemName: "", unitPrice: 0, quantity: 1, amount: 0, field: productsArray.length, currency: "" }]);
      setTotalDealValue(0);
    }
  };

  const onProductChange = (id, productInfo, index) => {
    // setSelectedProduct(value)
    const newArray = productsArray;
    if (productInfo) {
      newArray[index].productId = id;
      newArray[index].itemName = productInfo.productName;
      newArray[index].quantity = 1;
      newArray[index].unitPrice = 0;
      newArray[index].amount = 0;
      newArray[index].currency = selectedCurrency;

      if (_.isArray(productInfo.pricing) && !_.isEmpty(productInfo.pricing)) {
        const find = productInfo.pricing.find((each) => each.currency === selectedCurrency);
        if (find) {
          newArray[index].unitPrice = find.unitPrice;
          newArray[index].amount = find.unitPrice * newArray[index].quantity;
          newArray[index].currency = find.currency;
        }
      }
    }
    setProductsArray([...newArray]);
    getTotal();
  };

  const onAddDealValue = (e) => {
    setTotalDealValue(e.target.value);
    // props.dealModalProps.onAddDealValue(e.target.value)
  };

  const onAddProdctClick = () => {
    // setSelectedProduct("")
    let temp = addProducts ? productsArray : [];
    temp.push({ productId: "", itemName: "", unitPrice: 0, quantity: 1, amount: 0, field: productsArray.length, currency: "" });
    setProductsArray(temp);

    setAddProducts(!addProducts);
  };

  // const onAddDealItem = (e, field) => {
  //     const newArray = productsArray
  //     newArray[field].itemName = e.target.value
  //     setProductsArray(newArray)
  // }

  const onAddDealPrice = (e, field) => {
    const newArray = productsArray;
    newArray[field].unitPrice = e.target.value;
    newArray[field].amount = e.target.value * newArray[field].quantity;
    setProductsArray(newArray);
    getTotal();
  };

  const onAddDealquantity = (e, field) => {
    const newArray = productsArray;
    newArray[field].quantity = e.target.value;
    newArray[field].amount = newArray[field].unitPrice * e.target.value;
    setProductsArray(newArray);
    getTotal();
  };

  const onProductItemRemove = (data, index) => {
    let temp = productsArray;
    temp.splice(index, 1);
    setProductsArray([...temp]);
    // productsArray.splice(index,1)
  };

  const getTotal = () => {
    let total = 0;
    for (let i = 0; i < productsArray.length; i++) {
      total = total + productsArray[i].amount;
    }
    setTotalDealValue(total);
  };

  const onDealPipelineselect = (e) => {
    setSelectedPipeline(e.name);
    setSelectedPipelineId(e.id);
    props.dealModalProps.pipelineswithstages.map((pipelineStages) => {
      if (pipelineStages._id === e.id) {
        setStages(pipelineStages.stages);
        if (pipelineStages.stages.length) {
          setCurrentState(pipelineStages.stages[0]._id);
        }
      }
      return "";
    });
    // props.dealModalProps.onDealPipelineselect(e.target.value)
  };

  const findIndex = () => {
    let i = 0;
    stages.forEach((value, index) => {
      if (value._id === currentState) {
        i = index;
      }
    });
    return i;
  };

  const onExpectedCloseDateSelect = (e) => {
    var date = new Date(e.target.value); // some mock date
    // if(moment().format('l') <= moment(new Date(e.target.value)).format('l')){
    var milliseconds = date.getTime();
    setExpectedCloseDate(milliseconds);
    setExpectedDate(e.target.value);
    // }
    // else{
    //     dispatchSnackbarError("Expected close date can't be less than today's date","error")
    // }
    // props.dealModalProps.onExpectedCloseDateSelect(e.target.value)
  };

  useEffect(() => {
    if (props.dealModalProps.isPopupLeadToDeal && props.dealModalProps.popupStore) {
      const items = props.dealModalProps.popupStore;

      //setting company name
      if (items.company && items.company._id) {
        setCompanyName(items.company._id);
        setCompanyLabel(items.company.name);
      }

      //setting deal name
      if (items.company && items.company.name) {
        setDealName(`${items.company.name} Deal`);
      } else if (items.person && items.person.name) {
        setDealName(`${items.person.name} Deal`);
      }

      // //setting owner
      // if (items.owner) {
      //     setSelectedOwner(items.owner)
      // }

      //updating email
      if (items.person && items.person.email && items.person.email.length > 0) {
        const newArray = [];
        items.person.email.forEach((each, index) => {
          if (each) newArray.push({ email: each, field: index });
        });
        if (newArray.length > 0) {
          setIsEmailPhoneDisable(true);
          setEmailArray(newArray);
        }
      }

      //updating phone
      if (items.person && items.person.phone && items.person.phone.length > 0) {
        const newArray1 = [];
        items.person.phone.forEach((each, index) => {
          if (each) newArray1.push({ phone: each.toString(), field: index });
        });
        if (newArray1.length > 0) {
          setIsEmailPhoneDisable(true);
          setPhoneNumArray(newArray1);
        }
      }

      // updating lead props
      if (items.person && items.person.id) {
        setLeadData({ type: "old", _id: items.person.id });
        setLeadLabel(items.person.id);
        if (items.person.name) {
          setLeadName(items.person.name);
        } else if (items.person.firstName && items.person.lastName) {
          setLeadName(`${items.person.firstName} ${items.person.lastName}`);
        } else if (!items.person.firstName && items.person.lastName) {
          setLeadName(`${items.person.lastName}`);
        } else if (items.person.firstName && !items.person.lastName) {
          setLeadName(`${items.person.firstName}`);
        } else {
          setLeadName(`NA`);
        }
      }
    } else if (props.dealModalProps.items) {
      const items = props.dealModalProps.items;

      //setting company name
      if (items.company && items.company._id) {
        setCompanyName(items.company._id);
        setCompanyLabel(items.company.name);
      }

      //setting deal name
      if (items.company && items.company.name) {
        setDealName(`${items.company.name} Deal`);
      } else if (items.name) {
        setDealName(`${items.name} Deal`);
      }

      //setting owner
      if (items.owner) {
        setSelectedOwner(items.owner);
      }

      //updating email
      if (items.email && items.email.length > 0) {
        const newArray = [];
        items.email.forEach((each, index) => {
          if (each) newArray.push({ email: each, field: index });
        });
        if (newArray.length > 0) {
          setIsEmailPhoneDisable(true);
          setEmailArray(newArray);
        }
      }

      //updating phone
      if (items.phone && items.phone.length > 0) {
        const newArray1 = [];
        items.phone.forEach((each, index) => {
          if (each) newArray1.push({ phone: each.toString(), field: index });
        });
        if (newArray1.length > 0) {
          setIsEmailPhoneDisable(true);
          setPhoneNumArray(newArray1);
        }
      }

      // updating lead props
      if (items && items._id) {
        setLeadData({ type: "old", _id: items._id });
        setLeadLabel(items._id);
        if (items.name) {
          setLeadName(items.name);
        } else if (items.firstName && items.lastName) {
          setLeadName(`${items.firstName} ${items.lastName}`);
        } else if (!items.firstName && items.lastName) {
          setLeadName(`${items.lastName}`);
        } else if (items.firstName && !items.lastName) {
          setLeadName(`${items.firstName}`);
        } else {
          setLeadName(`NA`);
        }
      }
    }
  }, [props.dealModalProps.items, props.dealModalProps.popupStore, props.dealModalProps.isPopupLeadToDeal]);

  const setValue1 = (val, apiKeyName) => {
    setCustomFormData({ ...customFormData, [apiKeyName]: val });
  };

  return (
    <CustomScrollableModal
      modalProps={{
        show: props.dealModalProps.openAddDealModal,
        handleClose: () => onAddDealModalClose(),
        title: "Add Deal",
        showButtons: true,
        buttonText: "Save",
        size: "lg",
        customMessageShow: true,
        type: "deal",
        handleAccept: () => onAddDealModalSave(),
      }}
    >
      <Form>
        <div className="d-flex flex-row flex-wrap">
          <Col className="pt-0 pr-18px pb-0 pl-7px" xs={6}>
            <Form.Group id="deals-common-deal-modal-person-name" className="mb-8px">
              <Form.Label aria-label="person-name">Person Name</Form.Label>
              <LeadPersonSelect field={{ apiKeyName: "person", fieldName: "Person Name" }} valueLabel={leadLabel} value={leadName} setValue={(type, value) => onAddLead(type, value)} />
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-company-name" className="mb-8px">
              <Form.Label aria-label="company-name">Company Name</Form.Label>
              <CompanySelect field={{ apiKeyName: "company name" }} valueLabel={companyLabel} value={companyName} setValue={(value, keyName, name) => onAddOrganization(value, keyName, name)} cacheCompany={true} />
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-deal-name" className="mb-8px">
              <Form.Label aria-label="deal-name">Deal Name*</Form.Label>
              <Form.Control maxLength={InputLimit.TEXT} value={dealName} type="text" onChange={(e) => onAddDealName(e)} />
            </Form.Group>

            <Form.Group id="deals-common-deal-modal-phone-number" className="mb-8px">
              <Form.Label aria-label="phone-number">Phone Number</Form.Label>
              {phoneNumArray.map((item, index) => (
                <div className={`${index > 0 ? "mt-10px" : "mt-0"}`} key={index}>
                  <div className="d-flex align-items-center">
                    <ReactPhoneInput value={item.phone ? item.phone : defaultCode} disabled={isEmailPhoneDisable} onChange={(e) => onPhoneNumberAdd(e, item.field)} inputClass="w-100" />
                    {phoneNumArray.length > 1 ? (
                      <span>
                        <i onClick={() => onPhoneNUmerFieldDelete(item.field)} className="fa fa-minus-circle remove-condition-icon cursor-pointer ml-12px font-size-20px"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              ))}
              <div className="text-primary cursor-pointer mt-4px" onClick={() => onAddOneMore("phone")}>
                + add one more
              </div>
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-email" className="mb-8px">
              <Form.Label aria-label="email">Email</Form.Label>
              {emailArray.map((item, index) => (
                <div className={`${index > 0 ? "mt-10px" : "mt-0"}`} key={index}>
                  <div className="d-flex align-items-center">
                    {isEmailPhoneDisable ? (
                      <Form.Control maxLength={InputLimit.EMAIL} disabled={isEmailPhoneDisable} type="email" value={item.email} />
                    ) : (
                      <Form.Control maxLength={InputLimit.EMAIL} disabled={isEmailPhoneDisable} type="email" onChange={(e) => onEmailFieldAdd(e, item.field)} />
                    )}
                    {emailArray.length > 1 ? (
                      <span>
                        <i onClick={() => onEmailFieldDelete(item.field)} className="fa fa-minus-circle remove-condition-icon cursor-pointer ml-12px font-size-20px"></i>
                      </span>
                    ) : null}
                  </div>
                  {/* {item.email && !validateEmail(item.email) && <div className="text-red" >{"Please Enter valid email id!!!"}</div>} */}
                </div>
              ))}
              {emailError && <div className="text-red">{"Please Enter valid email id!!!"}</div>}
              <div className="text-primary cursor-pointer mt-4px" onClick={() => onAddOneMore("email")}>
                + add one more
              </div>
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-owner" className="mb-8px">
              <Form.Label aria-label="owner">Owner</Form.Label>
              <OwnerSelect field={{ fieldName: "Owner" }} value={selectedOwner} setValue={(value) => onOwnerChange(value)} />
            </Form.Group>
            {dealAddFormFields.map((field, index) => {
              return (
                index % 2 === 0 && (
                  <Form.Group key={index} id="deals-common-deal-modal-add-custom-form-data">
                    <Form.Label aria-label="set-custom-form-data" className={field.isRequired ? "custom-form-required" : ""}>
                      {field.fieldName}
                    </Form.Label>
                    <CustomInput
                      isCustomPersonSelect={false}
                      page={"Deals"}
                      pageSub={"deal"}
                      field={field}
                      value={customFormData[field.apiKeyName]}
                      setCustomFormData={setCustomFormData}
                      customFormData={customFormData}
                      setValue={setValue1}
                      allData={{}}
                    />
                  </Form.Group>
                )
              );
            })}
          </Col>
          <Col className="pt-0 pr-18px pb-0 pl-7px" xs={6}>
            <Form.Group id="deals-common-deal-modal-pipeline" className="mb-12px">
              <Form.Label aria-label="pipeline">Pipeline*</Form.Label>
              <Dropdown className="dropdown-inline mt-1 w-100">
                <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
                  {selectedPipeline}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu w-100-mn">
                  <div className="overflow-y-auto h-200px-mx">
                    {props.dealModalProps.pipelinedropdownOptions &&
                      props.dealModalProps.pipelinedropdownOptions.length > 0 &&
                      props.dealModalProps.pipelinedropdownOptions.map((pipeline, index) => (
                        <Dropdown.Item className="dropdown-hover" key={index} onClick={() => onDealPipelineselect(pipeline, index)}>
                          <span className="w-100">{pipeline.name}</span>
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-pipeline-stages" className="mb-8px">
              <Form.Label aria-label="pipeline-stages">Pipeline Stages*</Form.Label>
              <LeadStateWrapper>
                {stages &&
                  stages.map((value, index) => {
                    const onClick = () => {
                      setCurrentState(value._id);
                    };
                    const currentIndex = findIndex();
                    return (
                      <StateItem key={index} past={index < currentIndex} active={index === currentIndex} onClick={onClick} title={value.name}>
                        <div title={value.name}>
                          {index < currentIndex}
                          {/* {value} */}
                        </div>
                      </StateItem>
                    );
                  })}
              </LeadStateWrapper>
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-expected-close-date">
              <Form.Label aria-label="expected-close-date">Expected Close Date</Form.Label>
              <Form.Control type="date" name="dob" placeholder="Date of Birth" value={expectedDate} onChange={(e) => onExpectedCloseDateSelect(e)} />
            </Form.Group>

            <Form.Group id="deals-common-deal-modal-tags">
              <Form.Label aria-label="tag">Tag</Form.Label>
              <TagSelect
                page={"DealPipeline"}
                pageSub={"deal"}
                addTag={addTag}
                editTag={editTag}
                deleteTag={deleteTag}
                field={{
                  fieldName: "Tags",
                  apiKeyName: "tags",
                }}
                value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                setValue={setValue}
              />
            </Form.Group>
            <Form.Group id="deals-common-deal-modal-deal-value" className="mb-8px">
              <Form.Label aria-label="deal-value">Deal Value</Form.Label>
              <div className="d-flex flex-stack w-100">
                <div className="w-40">
                  <CurrencySelect field={{ fieldName: "Currency" }} value={selectedCurrency} setValue={(value) => onCurrencySelect(value)} />
                </div>
                <Form.Control maxLength={InputLimit.EMAIL} className="w-55" type="number" disabled={addProducts} value={totalDealValue} onChange={(e) => onAddDealValue(e)} />
              </div>
              <div className="text-primary cursor-pointer mt-8px" onClick={() => onAddProdctClick()}>
                {accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.servicesOffered && accountBillingStore.data.servicesOffered.PRODUCT_CATALOG ? (!addProducts ? "Add Products" : null) : null}
                {/* {!addProducts ? "Add Products" : "Don't add products"} */}
              </div>
            </Form.Group>

            {dealAddFormFields.map((field, index) => {
              return (
                index % 2 === 1 && (
                  <Form.Group key={index} id="deals-common-deal-modal-form-data">
                    <Form.Label aria-label="custom-form-data" className={field.isRequired ? "custom-form-required" : ""}>
                      {field.fieldName}
                    </Form.Label>
                    <CustomInput
                      clearable={true}
                      isCustomPersonSelect={false}
                      page={"Deals"}
                      pageSub={"deal"}
                      field={field}
                      value={customFormData[field.apiKeyName]}
                      setCustomFormData={setCustomFormData}
                      customFormData={customFormData}
                      setValue={setValue1}
                      allData={{}}
                    />
                  </Form.Group>
                )
              );
            })}
          </Col>
        </div>
        {addProducts && (
          <AddProductForm
            productsArray={productsArray}
            onProductChange={onProductChange}
            onAddDealPrice={onAddDealPrice}
            onAddDealquantity={onAddDealquantity}
            onProductItemRemove={onProductItemRemove}
            onAddOneMore={onAddOneMore}
            totalDealValue={totalDealValue}
            onAddProdctClick={onAddProdctClick}
          />
        )}
      </Form>
    </CustomScrollableModal>
  );
}
