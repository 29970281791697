export const getWalletInfo = {
  GET_WALLET_INFO_REQUEST: "GET_WALLET_INFO_REQUEST",
  GET_WALLET_INFO_SUCCESS: "GET_WALLET_INFO_SUCCESS",
  GET_WALLET_INFO_ERROR: "GET_WALLET_INFO_ERROR",
};

export const updateWalletNotify = {
  UPDATE_WALLET_NOTIFY_REQUEST: "UPDATE_WALLET_NOTIFY_REQUEST",
  UPDATE_WALLET_NOTIFY_SUCCESS: "UPDATE_WALLET_NOTIFY_SUCCESS",
  UPDATE_WALLET_NOTIFY_ERROR: "UPDATE_WALLET_NOTIFY_ERROR",
};

export const createWalletPaymentSession = {
  CREATE_WALLET_PAYMENT_SESSION_REQUEST: "CREATE_WALLET_PAYMENT_SESSION_REQUEST",
  CREATE_WALLET_PAYMENT_SESSION_SUCCESS: "CREATE_WALLET_PAYMENT_SESSION_SUCCESS",
  CREATE_WALLET_PAYMENT_SESSION_ERROR: "CREATE_WALLET_PAYMENT_SESSION_ERROR",
};
