import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import * as actions from "../actions";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";
import OTPInput from "../../../../components/otp-input";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

function TwoFactAuth(props) {
  const dispatch = useDispatch();
  const [otpValue, setOtpValue] = useState("");

  const onOtpChange = (otp) => {
    setOtpValue(otp);
    if (otp.length >= 6) {
      if (isNaN(otp)) {
        dispatchSnackbarError("Please enter valid otp number!");
      } else {
        SignInOTP(otp);
      }
    }
  };

  const SignInOTP = (otp = otpValue) => {
    let useValue = localStorage.getItem("loginValue");
    let newVal = JSON.parse(useValue);
    try {
      newVal["otp"] = parseInt(otp);
    } catch (err) {
      newVal["otp"] = otp;
    }
    // otp
    dispatch(
      actions.loginRequest(newVal, (data) => {
        if (data["success"]) {
          localStorage.setItem("access_token", data["token"]);
          // props.setIsAuthorized(true)
          dispatchSnackbarSuccess("Login Successfully", "success");
          localStorage.removeItem("loginValue");
          window.location.href = "/activity/activity-list";
        } else {
          dispatchSnackbarError("Invalid OTP", "error");
        }
      }),
    );
  };

  const resendOTP = () => {
    setOtpValue("");
    let useValue = localStorage.getItem("loginValue");
    let newVal = JSON.parse(useValue);
    dispatch(
      actions.EmailOTPRequest({ email: newVal.email, caseType: "LOGIN" }, (data) => {
        dispatchSnackbarSuccess("OTP successfully Sent", "success");
      }),
    );
  };

  return (
    <>
      <div className="login-form login-signin AuthLoginForm d-block">
        <div className="d-flex flex-column flex-root">
          <InlineStyled
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
          >
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
              <a href="index.html" className="mb-12">
                <img alt="Logo" src={require("../../../../images/logo/rectangle-logo/SVG/logo-rectangle.svg")} className="h-90px" />
              </a>
              <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 AuthLoginCard">
                <>
                  <div className="form w-100 mb-10" noValidate="novalidate" id="kt_sing_in_two_steps_form">
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3 fw-600">Two-Factor Authentication</h1>
                      <div className="text-muted fw-bold fs-5 mb-5">Please enter the verification code we sent you on your email.</div>
                    </div>
                    <div className="mb-10 px-md-10">
                      <div className="fw-bolder text-start text-dark fs-6 mb-1">Type your 6 digit security code</div>
                      <OTPInput
                        autoFocus
                        length={6}
                        value={otpValue}
                        className="d-flex flex-wrap justify-content-between inputFormStyle"
                        inputClassName="form-control form-control-solid fs-2qx text-center border-primary border-hover me-2 my-2 OtpScreenStyle"
                        onChangeOTP={(otp) => onOtpChange(otp)}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-around">
                      <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={() => SignInOTP()}>
                        <span className="indicator-label">SignIn</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="text-center fw-bold fs-5 mt-5">
                    <span className="text-muted me-1">Didn’t receive an email? </span>
                    <span className="text-muted me-1">Check your spam folder</span>
                    <span className="text-muted me-1">or</span>
                    <span onClick={() => resendOTP()} className="link-primary fw-bolder fs-5 cursor-pointer">
                      resend email
                    </span>
                  </div>
                </>
              </div>
            </div>
          </InlineStyled>
        </div>
      </div>
    </>
  );
}

export default connect(null, actions)(TwoFactAuth);
// export default TwoFactAuth
