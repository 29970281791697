import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CustomCheckBox } from "../../../../../components/custom-inputs/checkbox-selector";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { SubHeaderLoading } from "../../../../../components/loading";

export default function SchedulingCalendarForm(props) {
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [allCalendarOptions, setAllCalendarOptions] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const [checkedIds, setCheckedIds] = useState([]);
  const schedularCalendarListStore = useSelector((store) => store.settings.profile.schedular.calendarList);

  useEffect(() => {
    if (!_.isEmpty(schedularCalendarListStore.data)) {
      const { email, calendars, id } = schedularCalendarListStore.data[0];
      setCalendarOptions(schedularCalendarListStore.data.map((each) => ({ label: each.email, value: each.id })));
      setAllCalendarOptions(calendars);
      setSelectedCalendar({ value: id, label: email });
    } else {
      setCalendarOptions([]);
      setAllCalendarOptions([]);
      setSelectedCalendar({});
    }
  }, [schedularCalendarListStore.data]);

  useEffect(() => {
    if (!_.isEmpty(props.data) && !_.isEmpty(selectedCalendar.value)) {
      const calendarData = props.data.config.calendar_ids[selectedCalendar.value];
      if (!_.isEmpty(calendarData)) {
        setCheckedIds(calendarData.availability);
      }
    }
  }, [props.data, selectedCalendar]);

  const handleCheckBoxSelection = (mainId, subId, value) => {
    const copyCalendarIds = { ...props.data.config.calendar_ids };
    if (copyCalendarIds[mainId]) {
      if (value) {
        copyCalendarIds[mainId].availability.push(subId);
      } else {
        copyCalendarIds[mainId].availability = copyCalendarIds[mainId].availability.filter((e) => e !== subId);
      }
      const data = {
        ...props.data,
        config: {
          ...props.data.config,
          calendar_ids: copyCalendarIds,
        },
      };
      props.setSelectedData(data);
    }
  };

  if (schedularCalendarListStore.loading) return <SubHeaderLoading />;
  return (
    <Form.Group>
      <Form.Group>
        <Form.Label>Book Testing Salessimplify's meetings on:</Form.Label>
        <SimpleDropdown value={selectedCalendar} label={"Select"} options={calendarOptions} handleSelect={setSelectedCalendar} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Only schedule meetings when I'm available on:</Form.Label>
        {allCalendarOptions.map((each, index) => {
          return (
            <CustomCheckBox
              key={index}
              label={each.name}
              isChecked={checkedIds.includes(each.id)}
              onClick={(e) => {
                handleCheckBoxSelection(selectedCalendar.value, each.id, e.target.checked);
              }}
            />
          );
        })}
      </Form.Group>
    </Form.Group>
  );
}
