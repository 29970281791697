//new email sync settings types
export const HOSTED_EMAIL_AUTH_REQUEST = "HOSTED_EMAIL_AUTH_REQUEST";
export const HOSTED_EMAIL_AUTH_SUCCESS = "HOSTED_EMAIL_AUTH_SUCCESS";
export const HOSTED_EMAIL_AUTH_ERROR = "HOSTED_EMAIL_AUTH_ERROR";

export const HOSTED_EMAIL_AUTH_CALLBACK_REQUEST = "HOSTED_EMAIL_AUTH_CALLBACK_REQUEST";
export const HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS = "HOSTED_EMAIL_AUTH_CALLBACK_SUCCESS";
export const HOSTED_EMAIL_AUTH_CALLBACK_ERROR = "HOSTED_EMAIL_AUTH_CALLBACK_ERROR";

export const GET_EMAIL_SYNC_DETAILS_REQUEST = "GET_EMAIL_SYNC_DETAILS_REQUEST";
export const GET_EMAIL_SYNC_DETAILS_SUCCESS = "GET_EMAIL_SYNC_DETAILS_SUCCESS";
export const GET_EMAIL_SYNC_DETAILS_ERROR = "GET_EMAIL_SYNC_DETAILS_ERROR";

export const UPDATE_EMAIL_SYNC_DETAILS_REQUEST = "UPDATE_EMAIL_SYNC_DETAILS_REQUEST";
export const UPDATE_EMAIL_SYNC_DETAILS_SUCCESS = "UPDATE_EMAIL_SYNC_DETAILS_SUCCESS";
export const UPDATE_EMAIL_SYNC_DETAILS_ERROR = "UPDATE_EMAIL_SYNC_DETAILS_ERROR";

export const GET_EMAIL_FOLDER_LABELS_REQUEST = "GET_EMAIL_FOLDER_LABELS_REQUEST";
export const GET_EMAIL_FOLDER_LABELS_SUCCESS = "GET_EMAIL_FOLDER_LABELS_SUCCESS";
export const GET_EMAIL_FOLDER_LABELS_ERROR = "GET_EMAIL_FOLDER_LABELS_ERROR";

export const ADD_EMAIL_IN_IGNORE_LIST_REQUEST = "ADD_EMAIL_IN_IGNORE_LIST_REQUEST";
export const ADD_EMAIL_IN_IGNORE_LIST_SUCCESS = "ADD_EMAIL_IN_IGNORE_LIST_SUCCESS";
export const ADD_EMAIL_IN_IGNORE_LIST_ERROR = "ADD_EMAIL_IN_IGNORE_LIST_ERROR";

export const REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST = "REMOVE_EMAIL_FROM_IGNORE_LIST_REQUEST";
export const REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS = "REMOVE_EMAIL_FROM_IGNORE_LIST_SUCCESS";
export const REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR = "REMOVE_EMAIL_FROM_IGNORE_LIST_ERROR";

export const GET_COMPOSER_TOKENS_REQUEST = "GET_COMPOSER_TOKENS_REQUEST";
export const GET_COMPOSER_TOKENS_SUCCESS = "GET_COMPOSER_TOKENS_SUCCESS";
export const GET_COMPOSER_TOKENS_ERROR = "GET_COMPOSER_TOKENS_ERROR";

export const DELETE_EMAIL_ACCOUNT_REQUEST = "DELETE_EMAIL_ACCOUNT_REQUEST";
export const DELETE_EMAIL_ACCOUNT_SUCCESS = "DELETE_EMAIL_ACCOUNT_SUCCESS";
export const DELETE_EMAIL_ACCOUNT_ERROR = "DELETE_EMAIL_ACCOUNT_ERROR";

export const DISCONNECT_EMAIL_ACCOUNT_REQUEST = "DISCONNECT_EMAIL_ACCOUNT_REQUEST";
export const DISCONNECT_EMAIL_ACCOUNT_SUCCESS = "DISCONNECT_EMAIL_ACCOUNT_SUCCESS";
export const DISCONNECT_EMAIL_ACCOUNT_ERROR = "DISCONNECT_EMAIL_ACCOUNT_ERROR";

export const RECONNECT_EMAIL_ACCOUNT_REQUEST = "RECONNECT_EMAIL_ACCOUNT_REQUEST";
export const RECONNECT_EMAIL_ACCOUNT_SUCCESS = "RECONNECT_EMAIL_ACCOUNT_SUCCESS";
export const RECONNECT_EMAIL_ACCOUNT_ERROR = "RECONNECT_EMAIL_ACCOUNT_ERROR";

// new calendar sync types
export const HOSTED_CALENDAR_AUTH_REQUEST = "HOSTED_CALENDAR_AUTH_REQUEST";
export const HOSTED_CALENDAR_AUTH_SUCCESS = "HOSTED_CALENDAR_AUTH_SUCCESS";
export const HOSTED_CALENDAR_AUTH_ERROR = "HOSTED_CALENDAR_AUTH_ERROR";

export const HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST = "HOSTED_CALENDAR_AUTH_CALLBACK_REQUEST";
export const HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS = "HOSTED_CALENDAR_AUTH_CALLBACK_SUCCESS";
export const HOSTED_CALENDAR_AUTH_CALLBACK_ERROR = "HOSTED_CALENDAR_AUTH_CALLBACK_ERROR";

export const GET_CALENDAR_SYNC_DETAILS_REQUEST = "GET_CALENDAR_SYNC_DETAILS_REQUEST";
export const GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER = "GET_CALENDAR_SYNC_DETAILS_REQUEST_AFTER";
export const GET_CALENDAR_SYNC_DETAILS_SUCCESS = "GET_CALENDAR_SYNC_DETAILS_SUCCESS";
export const GET_CALENDAR_SYNC_DETAILS_ERROR = "GET_CALENDAR_SYNC_DETAILS_ERROR";

export const UPDATE_CALENDAR_SYNC_DETAILS_REQUEST = "UPDATE_CALENDAR_SYNC_DETAILS_REQUEST";
export const UPDATE_CALENDAR_SYNC_DETAILS_SUCCESS = "UPDATE_CALENDAR_SYNC_DETAILS_SUCCESS";
export const UPDATE_CALENDAR_SYNC_DETAILS_ERROR = "UPDATE_CALENDAR_SYNC_DETAILS_ERROR";

export const GET_AGENDA_TOKENS_REQUEST = "GET_AGENDA_TOKENS_REQUEST";
export const GET_AGENDA_TOKENS_SUCCESS = "GET_AGENDA_TOKENS_SUCCESS";
export const GET_AGENDA_TOKENS_ERROR = "GET_AGENDA_TOKENS_ERROR";

export const DELETE_CALENDAR_ACCOUNT_REQUEST = "DELETE_CALENDAR_ACCOUNT_REQUEST";
export const DELETE_CALENDAR_ACCOUNT_SUCCESS = "DELETE_CALENDAR_ACCOUNT_SUCCESS";
export const DELETE_CALENDAR_ACCOUNT_ERROR = "DELETE_CALENDAR_ACCOUNT_ERROR";

export const DISCONNECT_CALENDAR_ACCOUNT_REQUEST = "DISCONNECT_CALENDAR_ACCOUNT_REQUEST";
export const DISCONNECT_CALENDAR_ACCOUNT_SUCCESS = "DISCONNECT_CALENDAR_ACCOUNT_SUCCESS";
export const DISCONNECT_CALENDAR_ACCOUNT_ERROR = "DISCONNECT_CALENDAR_ACCOUNT_ERROR";

export const RECONNECT_CALENDAR_ACCOUNT_REQUEST = "RECONNECT_CALENDAR_ACCOUNT_REQUEST";
export const RECONNECT_CALENDAR_ACCOUNT_SUCCESS = "RECONNECT_CALENDAR_ACCOUNT_SUCCESS";
export const RECONNECT_CALENDAR_ACCOUNT_ERROR = "RECONNECT_CALENDAR_ACCOUNT_ERROR";

export const GET_LIST_OF_CALENDARS_REQUEST = "GET_LIST_OF_CALENDARS_REQUEST";
export const GET_LIST_OF_CALENDARS_SUCCESS = "GET_LIST_OF_CALENDARS_SUCCESS";
export const GET_LIST_OF_CALENDARS_ERROR = "GET_LIST_OF_CALENDARS_ERROR";

export const SEND_EMAIL_ID_TO_BACKEND_REQUEST = "SEND_EMAIL_ID_TO_BACKEND_REQUEST";
export const SEND_EMAIL_ID_TO_BACKEND_RESPONSE = "SEND_EMAIL_ID_TO_BACKEND_RESPONSE";
export const SEND_EMAIL_ID_TO_BACKEND_ERROR = "SEND_EMAIL_ID_TO_BACKEND_ERROR";

//saino composer
export const SEND_NYLAS_EMAIL_REQUEST = "SEND_NYLAS_EMAIL_REQUEST";
export const SEND_NYLAS_EMAIL_SUCCESS = "SEND_NYLAS_EMAIL_SUCCESS";
export const SEND_NYLAS_EMAIL_ERROR = "SEND_NYLAS_EMAIL_ERROR";

export const UPLOAD_ATTACHMENT_IN_NYLAS_REQUEST = "UPLOAD_ATTACHMENT_IN_NYLAS_REQUEST";
export const UPLOAD_ATTACHMENT_IN_NYLAS_SUCCESS = "UPLOAD_ATTACHMENT_IN_NYLAS_SUCCESS";
export const UPLOAD_ATTACHMENT_IN_NYLAS_ERROR = "UPLOAD_ATTACHMENT_IN_NYLAS_ERROR";
