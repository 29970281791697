//packages
import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import validator from "validator";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Card } from "react-bootstrap";

//components
import TimeZoneSelect from "../custom_form/timezone";
import CustomEmail from "../custom_form/email";
import SimpleDropdown from "../dropdowns/simple-dropdown";
import Association from "../custom_form/association";
import { BlueGradientButton } from "../button";

//actions
import { getListOfActiveActivityTypesRequest } from "../../containers/Activities/store/actions/activity_types_actions";
import * as ActivityListActions from "../../containers/Activities/store/actions/activitity_list_actions";

//constants and utils
import { timeOptions } from "../../containers/Activities/constants";
import { InputLimit } from "../../constants";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { CustomActivityButtonGroup } from "../button/custom-group-button";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
    background: "#fff",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

export function SchedularComponent(props) {
  const [activityText, setActivityText] = useState("");
  const [activityType, setActivityType] = useState("");
  const [activeIcon, setActiveIcon] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  //custom time related
  const [timeButton, setTimeButton] = useState("today");
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
  const [fromTime, setFromTime] = useState({ label: "12:00 AM", value: 0 });
  const [toTime, setToTime] = useState({ label: "12:00 AM", value: 0 });

  //meeting related
  const [isMeeting, setIsMeeting] = useState(false);
  const [attendees, setAttendees] = useState([""]);
  const [location, setLocation] = useState("");
  const [timezone, setTimezone] = useState("");
  const [meetingLink, setMeetingLink] = useState("");

  //association related
  const [associated, setAssociated] = useState({});
  const [leadField, setLeadField] = useState("");
  const [peopleField, setPeopleField] = useState("");
  const [dealField, setDealField] = useState("");
  const [companyFiled, setCompanyFiled] = useState("");

  const dispatch = useDispatch();
  const activityActiveTypesStore = useSelector((store) => store.activity.types.active);

  const userRole = useSelector((store) => store.insight.userRole.list);
  const profiledata = useSelector((store) => store.ProfileSettings.getProfileDetails);

  useEffect(() => {
    dispatch(getListOfActiveActivityTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(props.relatedAssociate)) {
      setAssociated(props.relatedAssociate);
    }
  }, [props.relatedAssociate]);

  useEffect(() => {
    if (!_.isEmpty(props.selectedAddActivity)) {
      const data = props.selectedAddActivity;
      setActivityText(data.name);
      setActivityType(data.activityType._id);
      setActiveIcon(data.activityType.name);
      setDescriptionText(data.description);

      if (data.activityType.name === "Meeting") {
        setIsMeeting(true);
        setAttendees(data.attendees);
        setLocation(data.location);
        setTimezone(data.timezone);
        setMeetingLink(data.meetingLink);
      }

      if (!_.isEmpty(data.associated)) {
        setAssociated(data.associated);
        if (data.associated.type === "lead") {
          setLeadField(data.associated._id);
        } else if (data.associated.type === "people") {
          setPeopleField(data.associated._id);
        } else if (data.associated.type === "deal") {
          setDealField(data.associated._id);
        } else if (data.associated.type === "company") {
          setCompanyFiled(data.associated._id);
        }
      }
    }
  }, [props.selectedAddActivity]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActivity)) {
      const data = props.selectedActivity;
      setActivityText(data.name);
      setActivityType(data.activityType._id);
      setActiveIcon(data.activityType.name);
      setDescriptionText(data.description);

      if (data.activityType.name === "Meeting") {
        setIsMeeting(true);
        setAttendees(data.attendees || [""]);
        setLocation(data.location || "");
        setTimezone(data.timezone || "");
        setMeetingLink(data.meetingLink);
      }

      if (data.scheduleAt) {
        setFromDate(moment(data.scheduleAt).format("yyyy-MM-DD"));
        const valueDiff = moment(data.scheduleAt).valueOf() - moment(moment(data.scheduleAt).format("yyyy-MM-DD")).valueOf();
        const diff = valueDiff === 0 ? 0 : valueDiff / 1000;
        timeOptions.forEach((each) => {
          if (each.value === diff) setFromTime(each);
        });
      }
      if (data.scheduleAtEnd) {
        setFromDate(moment(data.scheduleAtEnd).format("yyyy-MM-DD"));
        const valueDiff = moment(data.scheduleAtEnd).valueOf() - moment(moment(data.scheduleAtEnd).format("yyyy-MM-DD")).valueOf();
        const diff = valueDiff === 0 ? 0 : valueDiff / 1000;
        timeOptions.forEach((each) => {
          if (each.value === diff) setToTime(each);
        });
      }
    }
  }, [props.selectedActivity, props.isEdit]);

  useEffect(() => {
    if (userRole.users) {
      setUserOptions(
        userRole.users.map((each) => ({
          label: each.email,
          value: each.id,
        })),
      );
    }
  }, [userRole.users]);

  useEffect(() => {
    if (profiledata && profiledata.success) {
      setSelectedUser({ value: profiledata.data.userId, label: profiledata.data.email });
    }
  }, [profiledata, setSelectedUser]);

  const onIconFieldChange = (field) => {
    setActiveIcon(field.name);
    setActivityType(field._id);
    setActivityText(field.name);

    if (field.name === "Meeting") {
      setIsMeeting(true);
    } else {
      setIsMeeting(false);
    }
  };

  const setEmptyData = () => {
    setActivityText("");
    setActivityType("");
    setActiveIcon("");
    setDescriptionText("");
    setTimeButton("today");
    setFromDate(moment().format("yyyy-MM-DD"));
    setToDate(moment().format("yyyy-MM-DD"));
    setFromTime({ label: "12:00 AM", value: 0 });
    setToTime({ label: "12:00 AM", value: 0 });
    setIsMeeting(false);
    setAttendees([""]);
    setLocation("");
    setTimezone("");
    setMeetingLink("");
    setAssociated({});
    setLeadField("");
    setPeopleField("");
    setDealField("");
    setCompanyFiled("");
  };

  const onActivitySaveClick = () => {
    const startTime = moment(fromDate)
      .startOf("day")
      .add(fromTime.value, "second")
      .format();
    const endTime = moment(toDate)
      .startOf("day")
      .add(toTime.value, "second")
      .format();

    if (!activityType || !activityText || !selectedUser.value) {
      return dispatchSnackbarError("Please fill all the mandatory fields!");
    } else if (!activityType) {
      return dispatchSnackbarError("Please select activity type!");
    } else if (!activityText) {
      return dispatchSnackbarError("Please enter activity text");
    } else if (!selectedUser.value) {
      return dispatchSnackbarError("Please select user to assign");
    } else if (isMeeting && startTime > endTime) {
      return dispatchSnackbarError("Start Date cannot be grether than end date");
    } else if (meetingLink && !validator.isURL(meetingLink)) {
      return dispatchSnackbarError("Please enter valid meeting url");
    }

    const newAttendies = attendees.filter((a) => a);
    for (let i = 0; i < newAttendies.length; i++) {
      if (!validator.isEmail(newAttendies[i])) {
        return dispatchSnackbarError("Please enter valid email address in Guest");
      }
    }

    const duration = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();

    const data = {
      activityType: activityType,
      name: activityText,
      scheduleAt: startTime,
      scheduleAtEnd: isMeeting ? endTime : startTime,
      duration: isMeeting ? duration : 0,
      attendees: newAttendies,
      description: descriptionText,
      assignToUser: selectedUser.value,
      deal: dealField || props.dealId,
      lead: leadField || props.leadId,
      company: companyFiled || props.companyId,
      people: peopleField || props.peopleId,
      //meeting related
      location: location,
      timezone: timezone,
      meetingLink: meetingLink,
      associated: associated,
    };

    if (props.isEdit) {
      dispatch(
        ActivityListActions.editActivityRequest(
          {
            editData: { ...data, id: props.selectedActivity._id, type: "ACTIVITY" },
            from: props.type,
            peopleId: props.peopleId,
            dealId: props.dealId,
            leadId: props.leadId,
            companyId: props.companyId,
            pipelineId: props.pipelineId,
            pageForm: props.pageForm,
            filter: props.filter,
          },
          (res) => {
            if (res.status) {
              dispatchSnackbarSuccess(res.message);
              props.onCreateNewActivity();
            }
          },
        ),
      );
    } else {
      dispatch(
        ActivityListActions.createActivityRequest(
          {
            activityData: data,
            from: props.type,
            peopleId: props.peopleId,
            dealId: props.dealId,
            leadId: props.leadId,
            companyId: props.companyId,
            pipelineId: props.pipelineId,
            pageForm: props.pageForm,
            filter: props.filter,
          },
          (res) => {
            if (res.status) {
              dispatchSnackbarSuccess(res.message);
              props.onCreateNewActivity();
            }
          },
        ),
      );
    }

    props.closeScheduleActivity(false);
    setEmptyData();
  };

  const onTimeSelect = (time) => {
    if (time === "today") {
      setFromDate(moment().format("yyyy-MM-DD"));
      setToDate(moment().format("yyyy-MM-DD"));
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "tomorrow") {
      setFromDate(
        moment()
          .add(1, "day")
          .format("yyyy-MM-DD"),
      );
      setToDate(
        moment()
          .add(1, "day")
          .format("yyyy-MM-DD"),
      );
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "3 days later") {
      setFromDate(
        moment()
          .add(3, "days")
          .format("yyyy-MM-DD"),
      );
      setToDate(
        moment()
          .add(3, "days")
          .format("yyyy-MM-DD"),
      );
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    } else if (time === "custom") {
      setFromDate(moment().format("yyyy-MM-DD"));
      setToDate(moment().format("yyyy-MM-DD"));
      setFromTime({ label: "12:00 AM", value: 0 });
      setToTime({ label: "12:00 AM", value: 0 });
    }
    setTimeButton(time);
  };

  const handleAssociationChange = (data) => {
    setAssociated(data);
    if (data.type === "lead") {
      setDealField("");
      setLeadField(data._id);
      setPeopleField("");
      setCompanyFiled("");
    } else if (data.type === "company") {
      setDealField("");
      setLeadField("");
      setPeopleField("");
      setCompanyFiled(data._id);
    } else if (data.type === "deal") {
      setDealField(data._id);
      setLeadField("");
      setPeopleField("");
      setCompanyFiled("");
    } else if (data.type === "people") {
      setDealField("");
      setLeadField("");
      setPeopleField(data._id);
      setCompanyFiled("");
    }
  };

  return (
    <>
      <Card className="panel-shadow p-5 shadow-sm">
        <Form>
          <div className="d-flex flex-row flex-wrap">
            <Form.Group id="schedular-activity-type">
              <Form.Label aria-label="activity-type">Activity Type*</Form.Label>
              <CustomActivityButtonGroup options={activityActiveTypesStore.list} handleClick={onIconFieldChange} active={activeIcon} />
            </Form.Group>
            <Form.Group id="schedular-activity-name-time" className="d-flex justify-content-between w-100">
              <Form.Group id="schedular-activity-name" className="w-48">
                <Form.Label aria-label="activity-type">Activity Name*</Form.Label>
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder={activeIcon} value={activityText} onChange={(e) => setActivityText(e.target.value)} />
              </Form.Group>
              <div className="w-48">
                <Form.Group id="schedular-due-date" className="mb-2">
                  <Form.Label aria-label="due-date">Due Date*</Form.Label>
                  <Form.Group id="schedular-due-time" className="mb-2">
                    <div className="d-flex justify-content-center gap-2">
                      <input className="form-control" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                      <SimpleDropdown value={fromTime} label={"Select From Time"} options={timeOptions} handleSelect={(a) => setFromTime(a)} />
                    </div>
                  </Form.Group>
                  {isMeeting && (
                    <Form.Group id="schedular-date-time" className="mb-2">
                      <Form.Label aria-label="to-date&time">{"To Date & Time"}</Form.Label>
                      <div className="d-flex justify-content-center gap-2">
                        <input className="form-control" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        <SimpleDropdown value={toTime} label={"Select To Time"} options={timeOptions} handleSelect={(a) => setToTime(a)} />
                      </div>
                    </Form.Group>
                  )}
                  <div className="d-flex flex-row gap-5px">
                    <Button
                      className={timeButton === "today1" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                      onClick={() => onTimeSelect("today")}
                    >
                      Today
                    </Button>
                    <Button
                      className={timeButton === "tomorrow1" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                      onClick={() => onTimeSelect("tomorrow")}
                    >
                      Tomorrow
                    </Button>
                    <Button
                      className={timeButton === "3 days later1" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"}
                      onClick={() => onTimeSelect("3 days later")}
                    >
                      3 days later
                    </Button>
                    {/* <Button className={timeButton === "custom" ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm color-black border"} onClick={() => onTimeSelect("custom")}>Custom</Button> */}
                  </div>
                </Form.Group>
              </div>
            </Form.Group>
            <Form.Group id="schedular-meeting-details" className="d-flex justify-content-between w-100">
              <Form.Group id="schedular-meeting" className="w-48">
                <Form.Group id="schedular-associated">
                  <Form.Label aria-label="associated">Associated</Form.Label>
                  <Association disabled value={associated} setValue={handleAssociationChange} />
                </Form.Group>
                <Form.Group id="schedular-assignee">
                  <Form.Label aria-label="assignee">Assignee*</Form.Label>
                  <Select options={userOptions} styles={customStyles} aria-label="Default select example" onChange={(e) => setSelectedUser(e)} value={selectedUser} />
                </Form.Group>

                {isMeeting && (
                  <Form.Group id="schedular-meeting-link">
                    <Form.Label aria-label="meeting-link">Meeting Link:</Form.Label>
                    <Form.Control
                      type="text"
                      value={meetingLink}
                      onChange={(e) => {
                        setMeetingLink(e.target.value);
                      }}
                    />
                  </Form.Group>
                )}
                {isMeeting && (
                  <Form.Group id="schedular-timezone">
                    <Form.Label aria-label="timezone">Timezone</Form.Label>
                    <TimeZoneSelect field={{ apiKeyName: "timezone", fieldName: "Timezone" }} value={timezone} setValue={(value) => setTimezone(value)} />
                  </Form.Group>
                )}
              </Form.Group>
              <Form.Group id="schedular-guest-details" className="w-48">
                {isMeeting && (
                  <Form.Group id="schedular-guest">
                    <Form.Label aria-label="guest">Guest:</Form.Label>
                    <CustomEmail value={attendees} apiKeyName={"attendees"} setValue={(value) => setAttendees(value)} />
                  </Form.Group>
                )}

                {isMeeting && (
                  <Form.Group id="schedular-location">
                    <Form.Label aria-label="location">Location:</Form.Label>
                    <Form.Control
                      type="text"
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                    />
                  </Form.Group>
                )}

                <Form.Group id="schedular-description">
                  <Form.Label aria-label="description">Description</Form.Label>
                  <textarea value={descriptionText} className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescriptionText(e.target.value)}></textarea>
                </Form.Group>
              </Form.Group>
            </Form.Group>
          </div>
        </Form>
        <div>
          <Button size="sm" variant="secondary" className="mr-10px" onClick={() => props.closeScheduleActivity()}>
            Cancel
          </Button>
          <BlueGradientButton size="sm" onClick={() => onActivitySaveClick()}>
            {props.isEdit ? "Edit Activity" : "Create Activity"}
          </BlueGradientButton>
        </div>
      </Card>
    </>
  );
}
