import React, { useEffect, useState } from "react";
import _ from "lodash";
import AdvancedAccordionComponent from "../../../../../../components/accordion/advanced-accordion-component";
import { Form, Row, Spinner } from "react-bootstrap";
import SimpleDropdown, { SimpleGroupDropdown } from "../../../../../../components/dropdowns/simple-dropdown";
import { FormHelperText } from "@material-ui/core";
import { whatsappHeaderMediaOptions, whatsappHeaderTypeOptions, whatsappInputMaxLength } from "../constants";
import { uploadWhatsappSessionMediaRequest } from "../../../../store/actions/campaign_whatsapp_messaging_actions";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { getVariableKeys } from "../../../../../../utils/variables.util";

export default function WhatsappAddTemplateHeader(props) {
    const [activeKey, setActiveKey] = useState("1");
    const [mapFieldOptions, setMapFieldOptions] = useState([])
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    const [headerType, setHeaderType] = useState("none");
    const [headerText, setHeaderText] = useState("");
    const [headerMediaType, setHeaderMediaType] = useState("IMAGE");
    // const [mediaUrl, setMediaUrl] = useState("")

    const peopleColumns = useSelector((state) => state.People.peopleFields.list);
    const companyColumns = useSelector((state) => state.Companies.companyFields.list);
    const leadColumns = useSelector((state) => state.Leads.leadFields.list);
    const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields.list);


    const dispatch = useDispatch();

    useEffect(() => {
        if (_.isEmpty(props.header)) {
            setHeaderType("none");
            setHeaderText("");
            setHeaderMediaType("IMAGE");
            // setMediaUrl("")
        }
        else {
            if (props.header.format === "TEXT") {
                setHeaderText(props.header.text);
                setHeaderType("text")
                setHeaderMediaType("IMAGE");
                // setMediaUrl("")
            }
            else if (props.header.format === "IMAGE") {
                // setMediaUrl(props.header.example.header_handle[0] || "");
                setHeaderType("media")
                setHeaderText("")
                setHeaderMediaType("IMAGE");
            }
            else if (props.header.format === "VIDEO") {
                // setMediaUrl(props.header.example.header_handle[0] || "");
                setHeaderType("media")
                setHeaderText("")
                setHeaderMediaType("VIDEO");
            }
            else if (props.header.format === "DOCUMENT") {
                // setMediaUrl(props.header.example.header_handle[0] || "");
                setHeaderType("media")
                setHeaderText("")
                setHeaderMediaType("DOCUMENT");
            }
        }
    }, [props.header])

    useEffect(() => {
        const options = [];
        const options1 = [];
        const options2 = [];
        const options3 = [];

        peopleColumns.forEach((column) => {
            options.push({ label: `Peple - ${column.fieldName}`, value: `people.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        companyColumns.forEach((column) => {
            options1.push({ label: `Company - ${column.fieldName}`, value: `company.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        leadColumns.forEach((column) => {
            options2.push({ label: `Lead - ${column.fieldName}`, value: `lead.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        marketingColumns.forEach((column) => {
            options3.push({ label: `Marketing - ${column.fieldName}`, value: `marketing.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        setMapFieldOptions({
            "Lead Fields": options2,
            "People Fields": options,
            "Company Fields": options1,
            "Marketing Fields": options3
        });

    }, [peopleColumns, companyColumns, leadColumns, marketingColumns])


    const handleHeaderTextAddVariable = () => {
        if (headerText.length < whatsappInputMaxLength.WHATSAPP_HEADER_TEXT) {
            if (!headerText.includes(`{{1}}`)) {
                handleHeaderTextChange(headerText + "{{1}}");
            }
        }
    }

    const handleUploadFile = (e, type) => {
        if (_.isEmpty(e.target.files)) return;
        const file = e.target.files[0];
        const size = file.size;
        const defaultSize = 52428800;
        if (size > defaultSize) {
            dispatchSnackbarError("File size must be less than 50mb");
            return;
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", props.integrationId);
        setFileUploadLoading(true)
        dispatch(uploadWhatsappSessionMediaRequest(formData, (data) => {
            if (data.status) {
                props.setHeader({
                    ...props.header,
                    example: {
                        header_handle: [data.url]
                    }
                })
            }
            else dispatchSnackbarError(data.message);
            setFileUploadLoading(false);
        }));
    }

    const handleHeaderTypeChange = (value) => {
        if (value.value === "none") {
            props.setHeader({})
        }
        else if (value.value === "text") {
            props.setHeader({
                "type": "HEADER",
                "format": "TEXT",
                "text": ""
            })
        }
        else if (value.value === "media") {
            props.setHeader({
                type: "HEADER",
                format: "IMAGE",
                example: {
                    header_handle: []
                }
            })
        }
    }

    const handleHeaderTextChange = (value) => {
        props.setHeader({
            ...props.header,
            "text": value
        })
        handleHeaderContentChange(value);
    }

    const handleMediaTypeChange = (value) => {
        props.setHeader({
            type: "HEADER",
            format: value,
            example: {
                header_handle: []
            }
        })
    }

    const handleHeaderContentChange = (value) => {
        const keys = getVariableKeys(value);
        const headerVariables = {};
        props.headerVariables.forEach((key) => {
            headerVariables[key.id.toString()] = { value: key.value, mapField: key.mapField }
        });
        const updatedValues = [];
        keys.forEach((key) => {
            updatedValues.push({
                id: key.toString(),
                value: headerVariables[key.toString()] ? headerVariables[key.toString()].value : "",
                mapField: headerVariables[key.toString()] ? headerVariables[key.toString()].mapField : ""
            })
        })
        props.setHeaderVariables(updatedValues);
    }


    return <AdvancedAccordionComponent
        title="Header (Optional)"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        tooltipMessage={"Add a title that you want to show in message header."}
    >
        <div className="row">
            <div className="col-md-6">
                <Form.Group>
                    <Form.Label>Header Type</Form.Label>
                    <SimpleDropdown
                        value={headerType}
                        options={whatsappHeaderTypeOptions}
                        label={"Select the header type"}
                        handleSelect={(value) => handleHeaderTypeChange(value)}
                        showSearch={false}
                    />
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className={`${headerType !== "text" ? "d-none" : ""}`}>
                    <Form.Label className="d-flex flex-stack">
                        <span>Header Text</span>
                        <span className={`${props.headerVariables.length > 0 ? "text-muted cursor-not-allowed" : "cursor-pointer"}`} onClick={handleHeaderTextAddVariable}>+ Add Variable</span>
                    </Form.Label>
                    <Form.Control type="text" value={headerText} onChange={(e) => handleHeaderTextChange(e.target.value)} maxLength={whatsappInputMaxLength.WHATSAPP_HEADER_TEXT} />
                </Form.Group>
                <Form.Group className={`${headerType !== "media" ? "d-none" : ""}`}>
                    <Form.Label>Media Type</Form.Label>
                    <SimpleDropdown
                        value={headerMediaType}
                        options={whatsappHeaderMediaOptions}
                        label={"Select the header media type"}
                        handleSelect={(value) => handleMediaTypeChange(value.value)}
                        showSearch={false}
                    />
                </Form.Group>
            </div>
            <div className={`col-md-12 ${headerType !== "media" ? "d-none" : ""}`}>
                <Form.Group controlId="formFileSm1" className={`${headerMediaType !== "IMAGE" ? "d-none" : ""}`}>
                    <Form.Label className="d-flex align-items-center gap-2">Upload example image
                        {fileUploadLoading && (
                            <span className="ml-3 text-419eff">
                                <Spinner animation="border" size="sm" variant="primary" /> Uploading Image...
                            </span>
                        )}
                    </Form.Label>
                    <Form.Control type="file" size="sm" accept="image/jpeg,image/jpg,image/png" onChange={(e) => handleUploadFile(e, "img")} />
                    <FormHelperText>Upload an example image for WhatsApp to review the type of image you'll be sending using this template. They will check if the image meets their guidelines before approving the template for use. Supported formats: <b>JPEG and PNG</b>.</FormHelperText>
                </Form.Group>
                <Form.Group controlId="formFileSm2" className={`${headerMediaType !== "VIDEO" ? "d-none" : ""}`}>
                    <Form.Label className="d-flex align-items-center gap-2">Upload example video
                        {fileUploadLoading && (
                            <span className="ml-3 text-419eff">
                                <Spinner animation="border" size="sm" variant="primary" /> Uploading Video...
                            </span>
                        )}
                    </Form.Label>
                    <Form.Control type="file" size="sm" accept=".mp4" onChange={(e) => handleUploadFile(e, "video")} />
                    <FormHelperText>Upload an example video for WhatsApp to review the type of video you'll be sending using this template. They will check if the video meets their guidelines before approving the template for use. Supported format: <b>MP4</b></FormHelperText>
                </Form.Group>
                <Form.Group controlId="formFileSm3" className={`${headerMediaType !== "DOCUMENT" ? "d-none" : ""}`}>
                    <Form.Label className="d-flex align-items-center gap-2">Upload example document
                        {fileUploadLoading && (
                            <span className="ml-3 text-419eff">
                                <Spinner animation="border" size="sm" variant="primary" /> Uploading File...
                            </span>
                        )}
                    </Form.Label>
                    <Form.Control type="file" size="sm" accept="application/pdf" onChange={(e) => handleUploadFile(e, "pdf")} />
                    <FormHelperText>Upload an example document for WhatsApp to review the type of document you'll be sending using this template. They will check if the document meets their guidelines before approving the template for use. Supported format: <b>PDF</b></FormHelperText>
                </Form.Group>
            </div>
            {props.headerVariables.length > 0 && <div className="card card-body bg-light mx-4">
                <p className="description">Enter sample text for the variables you're adding to the template. WhatsApp reviews the content to check if it complies their guidelines before approving it.</p>
                <div className="row">
                    {props.headerVariables.map((variable, index) => {
                        return <Row>
                            <Form.Group className="col-md-6">
                                <Form.Label>{`Sample text for variable ${variable.id}`}</Form.Label>
                                <Form.Control type="text" maxLength={256} value={variable.value} onChange={(e) => {
                                    const updatedValues = [...props.headerVariables];
                                    updatedValues[index].value = e.target.value;
                                    props.setHeaderVariables(updatedValues);
                                }} />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <Form.Label>{`System Maping Field ${variable.id}`}</Form.Label>
                                <SimpleGroupDropdown
                                    value={props.headerVariables[index].mapField}
                                    label={"Select System Map Field"}
                                    options={mapFieldOptions}
                                    handleSelect={(value) => {
                                        const updatedValues = [...props.headerVariables];
                                        updatedValues[index].mapField = value.value;
                                        props.setHeaderVariables(updatedValues);
                                    }}
                                    showSearch
                                />
                            </Form.Group>
                        </Row>
                    })}
                </div>
            </div>}
        </div>

    </AdvancedAccordionComponent>
}