import { getListOfSMSSenderIds, createSMSSenderId, deleteSMSSenderId, getSMSCampaignPricing, getSMSSenderIdCountryList } from "../types/campaign_sms_settings_types";

export function getListOfSMSSenderIdsRequest(req) {
  return {
    type: getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_REQUEST,
    payload: req,
  };
}
export function getListOfSMSSenderIdsSuccess(res) {
  return {
    type: getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_SUCCESS,
    payload: res,
  };
}
export function getListOfSMSSenderIdsError(err) {
  return {
    type: getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_ERROR,
    payload: err,
  };
}

export function createSMSSenderIdRequest(req) {
  return {
    type: createSMSSenderId.CREATE_SMS_SENDER_ID_REQUEST,
    payload: req,
  };
}
export function createSMSSenderIdSuccess(res) {
  return {
    type: createSMSSenderId.CREATE_SMS_SENDER_ID_SUCCESS,
    payload: res,
  };
}
export function createSMSSenderIdError(err) {
  return {
    type: createSMSSenderId.CREATE_SMS_SENDER_ID_ERROR,
    payload: err,
  };
}

export function deleteSMSSenderIdRequest(req) {
  return {
    type: deleteSMSSenderId.DELETE_SMS_SENDER_ID_REQUEST,
    payload: req,
  };
}
export function deleteSMSSenderIdSuccess(res) {
  return {
    type: deleteSMSSenderId.DELETE_SMS_SENDER_ID_SUCCESS,
    payload: res,
  };
}
export function deleteSMSSenderIdError(err) {
  return {
    type: deleteSMSSenderId.DELETE_SMS_SENDER_ID_ERROR,
    payload: err,
  };
}

export function getSMSCampaignPricingRequest(req) {
  return {
    type: getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_REQUEST,
    payload: req,
  };
}
export function getSMSCampaignPricingSuccess(res) {
  return {
    type: getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_SUCCESS,
    payload: res,
  };
}
export function getSMSCampaignPricingError(err) {
  return {
    type: getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_ERROR,
    payload: err,
  };
}

export function getSMSSenderIdCountryListRequest(req) {
  return {
    type: getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_REQUEST,
    payload: req,
  };
}
export function getSMSSenderIdCountryListSuccess(res) {
  return {
    type: getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_SUCCESS,
    payload: res,
  };
}
export function getSMSSenderIdCountryListError(err) {
  return {
    type: getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_ERROR,
    payload: err,
  };
}
