import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as cadenceCloneableTemplateActions from "../actions/cadence_cloneable_template_actions";
import * as cadenceCloneableTemplateTypes from "../types/cadence-cloneable-template.types";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* getListOfCadenceCloneableTemplate(action) {
  try {
    const res = yield call(api.get, endpoints.GET_CADENCE_CLONEABLE_TEMPLATES, action.payload);
    if (res.status === 200) {
      yield put(cadenceCloneableTemplateActions.getListOfCadenceCloneableTemplateSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceCloneableTemplateActions.getListOfCadenceCloneableTemplateError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceCloneableTemplateActions.getListOfCadenceCloneableTemplateError(e));
  }
}

export default function* cadenceCloneableTemplateSaga() {
  yield takeLatest(cadenceCloneableTemplateTypes.getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_REQUEST, getListOfCadenceCloneableTemplate);
}
