import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { SubheaderNav } from "../../../../components/subheader/subheaderNav";
import { CustomSubHeader } from "../../../../components/subheader";
import { BlueGradientButton } from "../../../../components/button";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import Table from "./table";
import { DeleteModal } from "../../../../components/modal";
import ContentWrapper from "../../../Wrapper";
import AddWorkflowModel from "./add-workflow.model";

//actions
import * as workflowAction from "../actions/workflow_actions";
import * as workflowTemplateAction from "../actions/workflow_template_action";
import { useHistory } from "react-router-dom";
import ShareWorkflow from "./share-workflow.model";
import { CustomEmptyPage } from "../../../../components/empty_page/custom-empty-page";
import { CadenceSVG, WorkflowSVG } from "../../../../components/custom-svgs/sub-header-svg";
import { useLayoutEffect } from "react";

export default function WorkflowList() {
  const tabName = "Workflow Automation";
  const links = [
    { name: "Sales Cadence", to: "/automation/cadence/list", svg: CadenceSVG },
    { name: "Workflow Automation", to: "/automation/workflow/list", svg: WorkflowSVG },
  ];
  // const secondLinks = [{ name: "Owned" }, { name: "Shared" }]
  const tablehead = ["Workflow Name", "Module", "Created By", "Status", "Last Updated"];
  const dataHead = ["name", "module", "modifiedBy", "isActive", "modifiedOn"];

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  // const [secondTabName, setSecondTabName] = useState("Owned");
  // const [userType, setUserType] = useState("owned")
  const [deleteModelShow, setDeleteModelShow] = useState(false);
  const [deleteFlowId, setDeleteFlowId] = useState("");
  const [showAddWorkflowModel, setShowAddWorkflowModel] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareWorkflowData, setShareWorkflowData] = useState({});

  //selector
  const workflowList = useSelector((state) => state.workflowReducer.workflow.list);
  const templateList = useSelector((state) => state.workflowReducer.template.list);

  const dispatch = useDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    const payload = {
      limit: limit,
      page: pageNo,
      // userType: userType
    };
    dispatch(workflowAction.getListOfWorkflowsRequest(payload));
  }, [dispatch, limit, pageNo]);

  useEffect(() => {
    dispatch(workflowTemplateAction.getListOfWorkflowTemplatesRequest());
  }, [dispatch]);

  // const onSecondTabCLick = (name) => {
  //     setSecondTabName(name)
  //     setUserType(name === "Shared" ? "shared" : "owned")
  //     setPageNo(1);
  // }
  const onAddWorkflowClick = () => {
    setShowAddWorkflowModel(true);
  };
  const onCreateByScratchClick = () => {
    history.push("/automation/workflow/create-workflow");
  };
  const onStatusChange = (id, status) => {
    dispatch(workflowAction.updateWorkflowStatusRequest({ id: id, data: { status: status }, loadingData: { limit: limit, page: pageNo } }));
  };

  const onDeleteClick = (id) => {
    setDeleteModelShow(true);
    setDeleteFlowId(id);
  };
  const onEditClick = (id) => {
    history.push(`/automation/workflow/edit/${id}`);
  };

  const handleDeleteCancel = () => {
    setDeleteModelShow(false);
    setDeleteFlowId("");
  };
  const onAccepetDelete = () => {
    dispatch(workflowAction.deleteWorkflowRequest({ id: deleteFlowId }));
    setDeleteModelShow(false);
    setDeleteFlowId("");
  };

  const deleteLeadBotModelProps = {
    show: deleteModelShow,
    title: "Delete Workflow",
    handleClose: handleDeleteCancel,
    handleAccept: onAccepetDelete,
  };

  const onShareClick = (data) => {
    setShowShare(true);
    setShareWorkflowData(data);
  };

  const handleShareSave = (sharedData) => {
    dispatch(
      workflowAction.shareWorkflowRequest({
        workflowId: shareWorkflowData.id,
        ...sharedData,
      }),
    );
    setShowShare(false);
    setShareWorkflowData({});
  };
  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <SubheaderNav links={links} active={tabName} />
            <div className="d-flex align-items-center">
              {/* <SubheaderNav onClick={onSecondTabCLick} links={secondLinks} active={secondTabName} /> */}
              <BlueGradientButton variant="success" size="sm" onClick={() => onAddWorkflowClick()}>
                + Workflow
              </BlueGradientButton>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      {workflowList.isLoading ? (
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : workflowList.data.length === 0 ? (
        <CustomEmptyPage page="workflow" setShowAdd={onAddWorkflowClick} />
      ) : (
        <Table
          columns={tablehead}
          dataHead={dataHead}
          data={workflowList.data}
          pagination={true}
          count={workflowList.count}
          limit={limit}
          pageNo={pageNo}
          setLimit={setLimit}
          setPageNo={setPageNo}
          setChecked={() => {}}
          loading={workflowList.isLoading}
          onStatusChange={onStatusChange}
          handleShare={(data) => onShareClick(data)}
          onDeleteClick={(id) => onDeleteClick(id)}
          onEditClick={(id) => onEditClick(id)}
        />
      )}
      <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete automation workflow? After deleting workflow you will not able to recover it.</DeleteModal>
      <AddWorkflowModel show={showAddWorkflowModel} templateList={templateList} onCreateByScratchClick={onCreateByScratchClick} onClose={() => setShowAddWorkflowModel(false)} />
      <ShareWorkflow
        show={showShare}
        shareWorkflowData={shareWorkflowData}
        handleShare={handleShareSave}
        handleClose={() => {
          setShowShare(false);
          setShareWorkflowData({});
        }}
      />
    </ContentWrapper>
  );
}
