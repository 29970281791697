import { combineReducers } from "redux";
import cadenceListReducer from "./cadence_list_reducer";
import LeadLinkedCadenceReducer from "./lead_linked_cadence_reducer";
import selectedCadenceReducer from "./selected_cadence_reducer";
import subscriberListReducer from "./subscriber_list_reducer";
import cadencePrebuiltTemplateReducer from "./cadence_prebuilt_template_reducer";
import cadenceAnalyticsSummarizeReducer from "./cadence_analytics_summarize_reducer";
import cadenceAnalyticsStepWiseReducer from "./cadence_analytics_stepwise_reducer";
import cadenceCloneableTemplateReducer from "./cadence_cloneable_template_reducer";
import cadenceSettingsReducer from "./cadence_settings_reducer";
import subscriberStatsAnalyticsReducer from "./cadence_subscriber_stats_analytics_reduder";

const cadenceModuleReducer = combineReducers({
  list: cadenceListReducer,
  selected: selectedCadenceReducer,
  subscribers: subscriberListReducer,
  leadCadence: LeadLinkedCadenceReducer,
  prebuiltTemplate: cadencePrebuiltTemplateReducer,
  cloneableTemplate: cadenceCloneableTemplateReducer,
  analyticsSummarize: cadenceAnalyticsSummarizeReducer,
  analyticsStepWise: cadenceAnalyticsStepWiseReducer,
  settings: cadenceSettingsReducer,
  subscriberStats: subscriberStatsAnalyticsReducer,
});

export default cadenceModuleReducer;
