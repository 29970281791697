import { takeLatest, put, call } from "redux-saga/effects";
import * as activityTypes from "../types/activity.types";
import api from "../../../../network/apis/api";
import * as activityTypesActions from "../actions/activity_types_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";

function* getListOfActiveActivityTypes(action) {
  try {
    const response = yield call(api.get, "/api/crm/activity/active", action.payload);
    if (response.status === 200) {
      yield put(activityTypesActions.getListOfActiveActivityTypesSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(activityTypesActions.getListOfActiveActivityTypesError(response));
    } else {
      yield put(activityTypesActions.getListOfActiveActivityTypesError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(activityTypesActions.getListOfActiveActivityTypesError(e));
  }
}

export default function* ActivityLossReasonSaga() {
  yield takeLatest(activityTypes.getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_REQUEST, getListOfActiveActivityTypes);
}
