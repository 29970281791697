import { takeLatest, put, call, select } from "redux-saga/effects";
import * as dealsActions from "./actions";
import * as dealListActions from "../dealList/actions";
import {
  GET_PIPELINE_REQUEST,
  ADD_PIPELINE_REQUEST,
  GET_STAGES_REQUEST,
  ADD_DEAL_REQUEST,
  UPDATE_PIPELINE_REQUEST,
  DELETE_PIPELINE_REQUEST,
  GET_DEAL_REQUEST,
  MOVE_DEALINSTAGES_REQUEST,
  DEAL_OPERATION_REQUEST,
  ADD_STAGEINPIPELINE_REQUEST,
  UPDATE_STAGEINPIPELINE_REQUEST,
  UPDATE_STAGEORDERINPIPELINE_REQUEST,
  DELETE_PIPELINESTAGE_REQUEST,
  GET_STAGES_REQUEST_AFTER,
  GET_DEAL_REQUEST_AFTER,
  GET_LOSTREASONS_REQUEST,
  //filter
  GET_SAVEDFILTERS_REQUEST,
  ADD_SAVEDFILTERS_REQUEST,
  EDIT_SAVEDFILTERS_REQUEST,
  DELETE_SAVEDFILTERS_REQUEST,
  FAVORITE_SAVEDFILTERS_REQUEST,
  //deal fields
  GET_DEALFIELDS_REQUEST,
  GET_DEALPOPUPDETAILS_REQUEST,
  GET_DEALPOPUPDETAILS_REQUEST_AFTER,
  UPDATE_DEALPOPUPDETAILS_REQUEST,
  GET_DEAL_TAGS_REQUEST,
  DELETE_DEAL_TAGS_REQUEST,
  UPDATE_DEAL_TAGS_REQUEST,
  ADD_DEAL_TAGS_REQUEST,
  DEAL_SEARCH_REQUEST,
  CHANGE_DEALPERSON_REQUEST,
  CHANGE_DEALCOMPANY_REQUEST,
  CHANGE_DEALDETAILSINPOPUP_REQUEST,
  MARKASDEFAULT_REQUEST,
} from "./types";
import api from "../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import { leadsRequest } from "../../leads/store/actions/actions";

function* getPipelineFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/pipeline");
    if (response.status === 200) {
      yield put(dealsActions.getPipelineSuccess(response.data.data));
      // let data = response.data.data;
      // if (action.payload && action.payload.needToCallStagesApis) return;
      // else {
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].isDefault === true) {
      //       yield put(dealsActions.getStagesRequest({ pipelineId: data[i]._id }));
      //       yield put(dealsActions.getDealRequest({ pipelineId: data[i]._id }));
      //     }
      //   }
      // }
    }
  } catch (e) {
    console.error(e);
  }
}

function* addPipeline(action) {
  try {
    const res = yield call(api.post, "/api/crm/pipeline", action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Pipeline added successfully.", "success");
      yield put(dealsActions.addPipelineSuccess(res.data));
      yield put(
        dealsActions.getPipelineRequest({
          needToCallStagesApis: true,
        }),
      );
      yield put(
        dealsActions.getStagesRequest({
          pipelineId: res.data.pipelineId,
        }),
      );
      yield put(
        dealsActions.getDealRequest({
          pipelineId: res.data.pipelineId,
        }),
      );
    } else {
      dispatchSnackbarError(res.data.message);
    }
    if (action.cb) yield action.cb(res.data);
  } catch (err) {
    yield put(dealsActions.addPipelineError(err));
  }
}

function* getStages(action) {
  try {
    const res = yield call(api.get, `/api/crm/pipeline/${action.payload.pipelineId}`, action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getStagesSuccess(res.data.data));
    }
  } catch (err) {
    yield put(dealsActions.getStagesError(err));
  }
}

function* getStagesAfter(action) {
  try {
    const res = yield call(api.get, `/api/crm/pipeline/${action.payload.pipelineId}`, action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getStagesSuccess(res.data.data));
    }
  } catch (err) {
    yield put(dealsActions.getStagesError(err));
  }
}

function* updatePipelineRequest(action) {
  try {
    const res = yield call(api.patch, "/api/crm/pipeline", action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(dealsActions.getPipelineRequest({ needToCallStagesApis: false }));
    }
  } catch (err) {
    yield put(dealsActions.updatePipelineError(err));
  }
}

function* deletePipeline(action) {
  try {
    const res = yield call(api.delete, `/api/crm/pipeline/${action.payload.pipelineId}`);
    if (res.status === 200) {
      yield put(dealsActions.getPipelineRequest());
      yield put(dealsActions.getStagesRequest({ pipelineId: action.payload.newPipelineId }));
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(dealsActions.deletePipelineError(e));
  }
}

function* addDeal(action) {
  try {
    const res = yield call(api.post, "/api/crm/deals", action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message, "success");
      // yield put(dealsActions.getDealRequestAfter({
      //     pipelineId: action.payload.data.pipelineId
      // }))
      if (action.payload.data.from === "leads") {
        yield put(leadsRequest({ pageNo: 1, limit: 25, type: "active_lead" }));
      }

      if (action.payload.pageForm === "dealList") yield put(dealListActions.getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealsActions.getDealRequestAfter(action.payload.filter));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message, "error");
    }
  } catch (err) {
    yield put(dealsActions.addDealError(err));
  }
}

function* getdeals(action) {
  try {
    const res = yield call(api.get, `/api/crm/pipeline/stage/deals`, action.payload);
    if (res.status === 200 && res.data.status) {
      yield put(dealsActions.getDealSuccess(res.data.data));
    }
  } catch (e) {
    yield put(dealsActions.getDealError(e));
  }
}

function* getdealsAfter(action) {
  try {
    const res = yield call(api.get, `/api/crm/pipeline/stage/deals`, action.payload);
    if (res.status === 200 && res.data.status) {
      yield put(dealsActions.getDealSuccess(res.data.data));
    }
  } catch (e) {
    yield put(dealsActions.getDealError(e));
  }
}

function* moveDealInStages(action) {
  try {
    const res = yield call(api.patch, `/api/crm/pipeline/stage/${action.payload.type}`, action.payload.data);
    if (res.status === 200) {
      if (action.payload.from === "sidepanel") {
        yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.data.dealId));
      }
      if (action.payload.pageForm === "dealList") yield put(dealListActions.getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealsActions.getDealRequestAfter(action.payload.filter));
      dispatchSnackbarSuccess(res.data.message);
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
      if (action.payload.from === "sidepanel") {
        yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.data.dealId));
      }
      if (action.payload.pageForm === "dealList") yield put(dealListActions.getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealsActions.getDealRequestAfter(action.payload.filter));
    }
  } catch (e) {
    yield put(dealsActions.moveDealInStagesError(e));
  }
}

function* dealOperations(action) {
  try {
    let res;
    if (action.payload.from === "deal") {
      res = yield call(api.post, `/api/crm/pipeline/deal/deal-to-lead`, action.payload.data);
    } else {
      res = yield call(api.patch, `/api/crm/deals/${action.payload.type}`, action.payload.data);
    }
    if (res.status === 200) {
      if (action.payload.type === "mark-as-won") {
        if (action.payload.from === "sidepanel") {
          yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.data.dealId));
        } else yield put(dealsActions.dealOperationSuccess(res.data));
      } else if (action.payload.from === "sidepanel") yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.data.dealId));

      if (action.payload.pageForm === "dealList") yield put(dealListActions.getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealsActions.getDealRequestAfter(action.payload.filter));
      dispatchSnackbarSuccess(res.data.message);
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
    }
  } catch (e) {
    yield put(dealsActions.dealOperationError(e));
  }
}

function* addStageInPipeline(action) {
  try {
    const res = yield call(api.post, "/api/crm/pipeline/add-stage", action.payload);
    if (res.status === 200) {
      yield put(
        dealsActions.getStagesRequest({
          pipelineId: action.payload.pipelineId,
        }),
      );
    }
  } catch (e) {
    yield put(dealsActions.addStageInpipelineError(e));
  }
}

function* updateStageInPipeline(action) {
  try {
    const res = yield call(api.patch, "/api/crm/pipeline/stage", action.payload.stage);
    if (res.status === 200) {
      yield put(
        dealsActions.getStagesRequestAfter({
          pipelineId: action.payload.pipelineId,
        }),
      );
    }
  } catch (e) {
    yield put(dealsActions.updateStageInpipelineError(e));
  }
}

function* updateStageOrderInPipeline(action) {
  try {
    const res = yield call(api.post, "/api/crm/pipeline/stage/change-order", action.payload.data.updatedData);
    if (res.status === 200) {
      yield put(
        dealsActions.getStagesRequest({
          pipelineId: action.payload.pipelineId,
        }),
      );
    }
  } catch (e) {
    yield put(dealsActions.updateStageOrderInPipelineError(e));
  }
}

function* deletePipelineStage(action) {
  try {
    const res = yield call(api.delete, `/api/crm/pipeline/stage/${action.payload.id}`, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Stage successfully deleted!");
      yield put(dealsActions.getPipelineRequest());
    }
  } catch (e) {
    yield put(dealsActions.deletePipelineStageError(e));
  }
}

function* getLoastReasons(action) {
  try {
    const res = yield call(api.get, "/api/crm/lost-reason/deal", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getLostReasonsSuccess(res.data.data));
    }
  } catch (e) {
    yield put(dealsActions.getLostReasonsError(e));
  }
}

//filters apis

function* getSavedFilters(action) {
  try {
    const res = yield call(api.get, "/api/crm/saved-filters/get?type=deal", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getSavedFiltersSuccess(res.data));
    }
  } catch (e) {
    yield put(dealsActions.getSavedFiltersError(e));
  }
}

function* addSavedFilters(action) {
  try {
    const res = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getSavedFiltersRequest());
    }
    if (action.cb) yield action.cb(res.data);
  } catch (e) {
    yield put(dealsActions.addSavedFiltersError(e));
  }
}

function* editSavedFilter(action) {
  try {
    const res = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getSavedFiltersRequest());
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(dealsActions.editSavedFiltersError(e));
  }
}

function* deleteSavedFilter(action) {
  try {
    const res = yield call(api.delete, `/api/crm/saved-filters/delete/${action.payload}`);
    if (res.status === 200) {
      yield put(dealsActions.getSavedFiltersRequest());
    }
  } catch (e) {
    yield put(dealsActions.deleteSavedFiltersError(e));
  }
}

function* favoriteSavedFilter(action) {
  try {
    const res = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);

    if (res.status === 200) {
      yield put(dealsActions.getSavedFiltersRequest());
    }
  } catch (e) {
    yield put(dealsActions.favoriteSavedFiltersError(e));
  }
}

function* getDealFields(action) {
  try {
    const res = yield call(api.get, "/api/crm/data-fields/get?fieldType=DEAL", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.getDealFieldsSuccess(res.data.data));
    }
  } catch (e) {
    yield put(dealsActions.getDealFieldsError(e));
  }
}

function* getPopupDetails(action) {
  try {
    const res = yield call(api.get, `/api/crm/deals/selected-deal-details?id=${action.payload}`);
    if (res.status === 200) {
      yield put(dealsActions.getDealPopupDetailsSuccess(res.data.data));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(dealsActions.getDealPopupDetailsError(e));
  }
}

function* getPopupDetailsAfter(action) {
  try {
    const res = yield call(api.get, `/api/crm/deals/selected-deal-details?id=${action.payload}`);
    if (res.status === 200) {
      yield put(dealsActions.getDealPopupDetailsSuccess(res.data.data));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(dealsActions.getDealPopupDetailsError(e));
  }
}

function* updatePopupDetails(action) {
  try {
    const res = yield call(api.patch, `/api/crm/deals/update-popup-details?id=${action.payload.data.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.dealId));
      yield put(dealsActions.updateDealpopupDetailsSuccess(res.data));
      dispatchSnackbarSuccess(res.data.message);
      if (action.payload.pageForm === "dealList") yield put(dealListActions.getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealsActions.getDealRequestAfter(action.payload.filter));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
    }
  } catch (e) {
    yield put(dealsActions.updateDealpopupDetailsError(e));
  }
}

function* dealTagsFunction(action) {
  try {
    const store = yield select();
    if (action.payload.skipIfDataPresent && Array.isArray(store.DealPipeline.dealTags.list) && store.DealPipeline.dealTags.list.length > 0) {
      yield put(dealsActions.getDealTagSuccess(store.DealPipeline.dealTags.list));
    } else {
      const response = yield call(api.get, "/api/crm/tag/get", action.payload.request);
      if (response.status === 200) {
        yield put(dealsActions.getDealTagSuccess(response.data.data));
      } else {
        yield put(dealsActions.getDealTagError({ message: "Could Not Get Product Tags" }));
      }
    }
  } catch (e) {
    yield put(dealsActions.getDealTagError({ message: "Could Not Get Product Tags" }));
  }
}

function* dealTagsAddFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/tag/create", action.payload);
    if (response.status === 200) {
      dispatchSnackbarSuccess("Tag Added Successfully");
      yield put(dealsActions.addDealTagSuccess({ message: "Tag Added Successfully" }));
      yield put(dealsActions.getDealTagRequest({ request: { type: "deal" }, skipIfDataPresent: false }));
    } else {
      yield put(dealsActions.addDealTagError({ message: "Could Not Add Lead Tag" }));
    }
  } catch (e) {
    yield put(dealsActions.addDealTagError({ status: false, message: "Could Not Add Lead Tag" }));
  }
}

function* dealTagsEditFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/tag/edit", action.payload);
    if (response.status === 200) {
      yield put(dealsActions.updateDealTagSuccess({ message: "Tag Edited Successfully" }));
      yield put(dealsActions.getDealTagRequest({ request: { type: "deal" }, skipIfDataPresent: false }));
    } else {
      yield put(dealsActions.updateDealTagError({ message: "Could Not Edit Tag" }));
    }
  } catch (e) {
    yield put(dealsActions.updateDealTagError({ message: "Could Not Edit Tag" }));
  }
}

function* dealTagsDeleteFunction(action) {
  try {
    const response = yield call(api.delete, "/api/crm/tag/delete/" + action.payload);
    if (response.status === 200) {
      yield put(dealsActions.deleteDealTagSuccess({ message: "Tag Deleted Successfully" }));
      yield put(dealsActions.getDealTagRequest({ request: { type: "deal" }, skipIfDataPresent: false }));
    } else {
      yield put(dealsActions.deleteDealTagError({ message: "Could Not Delete Tag" }));
    }
  } catch (e) {
    yield put(dealsActions.deleteDealTagError({ message: "Could Not Delete Tag" }));
  }
}

function* dealSearchFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/deals/search", { searchText: action.payload });
    if (response.status === 200) {
      yield put(dealsActions.dealSearchSuccess(response.data));
    }
  } catch (e) {
    yield put(dealsActions.dealSearchError(e));
  }
}

function* changeDealPerson(action) {
  try {
    const res = yield call(api.patch, "/api/crm/deals/change-person", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.changeDealPersonSuccess(res.data));
      yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.dealId));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
    }
  } catch (e) {
    yield put(dealsActions.changeDealPersonError(e));
  }
}

function* changeDealCompany(action) {
  try {
    const res = yield call(api.patch, "/api/crm/deals/change-company", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.changeDealCompanySuccess(res.data));
      yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.dealId));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
    }
  } catch (e) {
    yield put(dealsActions.changeDealCompanyError(e));
  }
}

function* changeDealDetailsInPopup(action) {
  try {
    const res = yield call(api.patch, "/api/crm/deals/update-deal-popup", action.payload.data);
    if (res.status === 200) {
      yield put(dealsActions.changeDealDetailsInPopupSuccess(res.data));
      yield put(dealsActions.getDealPopupDetailsRequestAfter(action.payload.data.id));
      if (action.payload.pageForm === "dealList") yield put(dealListActions.getDealListRequestAfter(action.payload.filter));
      else if (action.payload.pageForm === "dealPipeline") yield put(dealsActions.getDealRequestAfter(action.payload.filter));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message);
    }
  } catch (e) {
    yield put(dealsActions.changeDealDetailsInPopupError(e));
  }
}

function* markAsDefaultFunction(action) {
  try {
    const res = yield call(api.patch, "/api/crm/pipeline/mark-as-default", action.payload);
    if (res.status === 200) {
      yield put(dealsActions.markAsDefaultSuccess(res.data));
      dispatchSnackbarSuccess("Successfully marked as default", "success");
      yield put(dealsActions.getPipelineRequest());
    }
  } catch (e) {
    yield put(dealsActions.markAsDefaultError(e));
  }
}

export default function*() {
  yield takeLatest(GET_PIPELINE_REQUEST, getPipelineFunction); // similarly add required functions
  yield takeLatest(ADD_PIPELINE_REQUEST, addPipeline);
  yield takeLatest(GET_STAGES_REQUEST, getStages);
  yield takeLatest(GET_STAGES_REQUEST_AFTER, getStagesAfter);
  yield takeLatest(ADD_DEAL_REQUEST, addDeal);
  yield takeLatest(UPDATE_PIPELINE_REQUEST, updatePipelineRequest);
  yield takeLatest(DELETE_PIPELINE_REQUEST, deletePipeline);
  yield takeLatest(GET_DEAL_REQUEST, getdeals);
  yield takeLatest(GET_DEAL_REQUEST_AFTER, getdealsAfter);
  yield takeLatest(MOVE_DEALINSTAGES_REQUEST, moveDealInStages);
  yield takeLatest(DEAL_OPERATION_REQUEST, dealOperations);
  yield takeLatest(ADD_STAGEINPIPELINE_REQUEST, addStageInPipeline);
  yield takeLatest(UPDATE_STAGEINPIPELINE_REQUEST, updateStageInPipeline);
  yield takeLatest(UPDATE_STAGEORDERINPIPELINE_REQUEST, updateStageOrderInPipeline);
  yield takeLatest(DELETE_PIPELINESTAGE_REQUEST, deletePipelineStage);
  yield takeLatest(GET_LOSTREASONS_REQUEST, getLoastReasons);
  yield takeLatest(GET_SAVEDFILTERS_REQUEST, getSavedFilters);
  yield takeLatest(ADD_SAVEDFILTERS_REQUEST, addSavedFilters);
  yield takeLatest(EDIT_SAVEDFILTERS_REQUEST, editSavedFilter);
  yield takeLatest(DELETE_SAVEDFILTERS_REQUEST, deleteSavedFilter);
  yield takeLatest(FAVORITE_SAVEDFILTERS_REQUEST, favoriteSavedFilter);
  yield takeLatest(GET_DEALFIELDS_REQUEST, getDealFields);
  yield takeLatest(GET_DEALPOPUPDETAILS_REQUEST, getPopupDetails);
  yield takeLatest(GET_DEALPOPUPDETAILS_REQUEST_AFTER, getPopupDetailsAfter);
  yield takeLatest(UPDATE_DEALPOPUPDETAILS_REQUEST, updatePopupDetails);
  yield takeLatest(GET_DEAL_TAGS_REQUEST, dealTagsFunction);
  yield takeLatest(ADD_DEAL_TAGS_REQUEST, dealTagsAddFunction);
  yield takeLatest(UPDATE_DEAL_TAGS_REQUEST, dealTagsEditFunction);
  yield takeLatest(DELETE_DEAL_TAGS_REQUEST, dealTagsDeleteFunction);
  yield takeLatest(DEAL_SEARCH_REQUEST, dealSearchFunction);
  yield takeLatest(CHANGE_DEALPERSON_REQUEST, changeDealPerson);
  yield takeLatest(CHANGE_DEALCOMPANY_REQUEST, changeDealCompany);
  yield takeLatest(CHANGE_DEALDETAILSINPOPUP_REQUEST, changeDealDetailsInPopup);
  yield takeLatest(MARKASDEFAULT_REQUEST, markAsDefaultFunction);
}
