export const ProfileSettingRouteConstants = [
  {
    Name: "Profile Overview",
    path: "/settings/profile/profile-overview",
    icon: "bi bi-file-person-fill",
  },
  {
    Name: "Sign in & Password",
    path: "/settings/profile/signin",
    icon: "bi bi-file-lock2",
  },
  {
    Name: "Login Sessions",
    path: "/settings/profile/login-session",
    icon: "bi bi-clock-history",
  },
  {
    Name: "List of Companies",
    path: "/settings/profile/company-list",
    icon: "bi bi-building",
  },
  {
    Name: "Email Calendar Sync",
    path: "/settings/profile/sync-settings",
    icon: "bi bi-envelope-fill",
  },
  // {
  //   Name: "Email Sync",
  //   path: "/settings/profile/email-sync",
  //   icon: "bi bi-envelope-fill",
  // },
  // {
  //   Name: "Calendar Sync",
  //   path: "/settings/profile/calendar-sync",
  //   icon: "bi bi-calendar3-range",
  // },
  {
    Name: "Meeting Scheduler",
    path: "/settings/profile/scheduler",
    icon: "bi bi-calendar3",
  },
];
export const AdminSettingRouteConstants = [
  {
    Name: "Manage Users",
    path: "/settings/account/manage-users",
    icon: "bi bi-person-plus-fill",
  },
  {
    Name: "Teams",
    path: "/settings/account/team",
    icon: "bi bi-people-fill",
  },
  {
    Name: "Company",
    path: "/settings/account/company",
    icon: "bi bi-building",
  },
  {
    Name: "Currency",
    path: "/settings/account/currency",
    icon: "bi bi-currency-exchange",
  },
  {
    Name: "Security",
    path: "/settings/account/security",
    icon: "bi bi-shield-shaded",
  },
  {
    Name: "Developer",
    path: "/settings/account/developer/api-key",
    icon: "bi bi-file-code-fill",
  },
  {
    Name: "Custom Fields",
    path: "/settings/account/custom-field",
    icon: "bi bi-ui-radios",
  },
  {
    Name: "Outcomes & Lost Reasons",
    path: "/settings/account/lost-reason/activity",
    svg: "/media/svg/icons/Text/Article.svg",
  },
  {
    Name: "Activity",
    path: "/settings/account/activity",
    icon: "bi bi-calendar-check",
  },
  {
    Name: "Tag Settings",
    path: "/settings/account/tags-settings",
    icon: "bi bi-tags-fill",
  },
];
