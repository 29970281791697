import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as campaignReportActions from "../actions/campaign_report_actions";
import * as campaignReportTypes from "../types/campaign_reports_types";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* getEmailDetailedAnalytics(action) {
  try {
    const res = yield call(api.get, `${endpoints.EMAIL_DETAILED_ANALYTICS_REPORT}/${action.payload.campaignId}`, action.payload.query);
    if (res.status === 200) {
      yield put(campaignReportActions.getEmailCampaignAnalyticsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignReportActions.getEmailCampaignAnalyticsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignReportActions.getEmailCampaignAnalyticsError(e));
  }
}

function* getEmailDetailedReport(action) {
  try {
    const res = yield call(api.get, `${endpoints.EMAIL_DETAILED_REPORT}/${action.payload.campaignId}`, action.payload.query);
    if (res.status === 200) {
      yield put(campaignReportActions.getEmailCampaignDetailedReportSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignReportActions.getEmailCampaignDetailedReportError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignReportActions.getEmailCampaignDetailedReportError(e));
  }
}

function* getEmailSummaryReport(action) {
  try {
    const res = yield call(api.get, endpoints.EMAIL_SUMMARY_REPORT, action.payload);
    if (res.status === 200) {
      yield put(campaignReportActions.getEmailCampaignSummaryReportSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignReportActions.getEmailCampaignSummaryReportError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignReportActions.getEmailCampaignSummaryReportError(e));
  }
}

function* getCampaignReportLog(action) {
  try {
    const res = yield call(api.get, endpoints.CAMPAIGN_REPORT_ADVANCED_LOG, action.payload);
    if (res.status === 200) {
      yield put(campaignReportActions.getCampaignReportLogSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignReportActions.getCampaignReportLogError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignReportActions.getCampaignReportLogError(e));
  }
}

function* getListOfDownloadReports(action) {
  try {
    const res = yield call(api.get, endpoints.DOWNLOAD_REPORT, action.payload);
    if (res.status === 200) {
      yield put(campaignReportActions.getListOfDownloadReportsSuccess(res.data));
    } else if (res.status === 404) {
      dispatchSnackbarError("404: Not Found");
      yield put(campaignReportActions.getListOfDownloadReportsError(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignReportActions.getListOfDownloadReportsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignReportActions.getListOfDownloadReportsError(e));
  }
}

function* downloadCampaignReports(action) {
  try {
    const res = yield call(api.post, endpoints.DOWNLOAD_REPORT, action.payload);
    if (res.status === 200) {
      yield put(campaignReportActions.downloadCampaignReportsSuccess(res.data));
      yield put(campaignReportActions.getListOfDownloadReportsRequest());
    } else if (res.status === 404) {
      dispatchSnackbarError("404: Not Found");
      yield put(campaignReportActions.downloadCampaignReportsError(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(campaignReportActions.downloadCampaignReportsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(campaignReportActions.downloadCampaignReportsError(e));
  }
}

export default function* campaignDetailsSaga() {
  yield takeLatest(campaignReportTypes.getEmailCampaignAnalytics.GET_EMAIL_CAMPAIGN_ANALYTICS_REQUEST, getEmailDetailedAnalytics);
  yield takeLatest(campaignReportTypes.getEmailCampaignSummaryReport.GET_EMAIL_CAMPAIGN_SUMMARY_REPORT_REQUEST, getEmailSummaryReport);
  yield takeLatest(campaignReportTypes.getEmailCampaignDetailedReport.GET_EMAIL_CAMPAIGN_DETAILED_REPORT_REQUEST, getEmailDetailedReport);
  yield takeLatest(campaignReportTypes.getCampaignReportLog.GET_CAMPAIGN_REPORT_LOG_REQUEST, getCampaignReportLog);
  yield takeLatest(campaignReportTypes.getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST, getListOfDownloadReports);
  yield takeLatest(campaignReportTypes.getListOfDownloadReports.GET_LIST_OF_DOWNLOAD_REPORTS_REQUEST_AFTER, getListOfDownloadReports);
  yield takeLatest(campaignReportTypes.downloadCampaignReports.DOWNLOAD_CAMPAIGN_REPORTS_REQUEST, downloadCampaignReports);
}
