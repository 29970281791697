import { getListOfCadenceCloneableTemplate } from "../types/cadence-cloneable-template.types";

// cloneable
export const getListOfCadenceCloneableTemplateRequest = (payload, cb) => ({
  type: getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_REQUEST,
  payload,
  cb,
});
export const getListOfCadenceCloneableTemplateSuccess = (payload) => ({
  type: getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_SUCCESS,
  payload,
});
export const getListOfCadenceCloneableTemplateError = (payload) => ({
  type: getListOfCadenceCloneableTemplate.GET_LIST_OF_CADENCE_CLONEABLE_TEMPLATE_ERROR,
  payload,
});
