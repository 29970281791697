import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Accordion, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//components
import { ViewMore } from "../../custom-components/viewMore";

//actions
import * as marketingListActions from "../../../../containers/campaign/store/actions/campaign_marketing_list_actions";
import InlineStyled from "../../../inline-styled/inline-styled";

export function MarketingDetails(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [cellEdit, setCellEdit] = useState({});
  const [showColumns, setShowColumns] = useState([]);
  const [isViewMore, setIsViewMore] = useState(true);
  const [viewLessColomns, setViewLessColomns] = useState([]);
  const [loadMoreFieldCount, setLoadMoreFieldCount] = useState(0);

  const dispatch = useDispatch();

  const fieldStore = useSelector((state) => state.campaign.marketing.dataField.fields);
  const marketingDataListStore = useSelector((store) => store.campaign.marketing.marketingList.list);
  const marketingDataPopupStore = useSelector((store) => store.campaign.marketing.marketingList.popup);
  const marketingLoadingDataStore = useSelector((store) => store.campaign.marketing.marketingList.loadingData);

  useEffect(() => {
    const _viewLess = [];
    const tempFieldStore = fieldStore.list
      .filter((a) => {
        if (a.apiKeyName === "firstName") {
          _viewLess[0] = a._id;
        } else if (a.apiKeyName === "lastName") {
          _viewLess[1] = a._id;
        } else if (a.apiKeyName === "email") {
          _viewLess[2] = a._id;
        } else if (a.apiKeyName === "phone") {
          _viewLess[3] = a._id;
        }
        return a.isEnabledForTable;
      })
      .map((each) => each._id);
    setShowColumns(tempFieldStore);
    setViewLessColomns(_viewLess);
    setLoadMoreFieldCount(tempFieldStore.length - _viewLess.length);
  }, [fieldStore]);

  const handleCellEdit = (combinedId, data) => {
    dispatch(marketingListActions.editMarketingListRequest({ editData: data, loadingData: marketingLoadingDataStore, cellId: combinedId, pageForm: "sidepannel" }));
  };

  const addTag = (name, colorCode) => {
    dispatch(marketingListActions.createMarketingListTagsRequest({ name: name, colorCode: colorCode, type: "marketing" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(marketingListActions.updateMarketingListTagsRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(marketingListActions.deleteMarketingListTagsRequest(id));
  };

  const getCompanyDetails = () => {
    if (props.moduleType === "marketing") {
      return props.sidePanelProps || {};
    }
    return {};
  };

  return (
    <div key={props.index}>
      <Draggable draggableId={"company-details"} index={props.index}>
        {(provided, snapshot) => (
          <Accordion
            as={Card}
            defaultActiveKey={isOpen ? "toggle-company" : null}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={`mb-4 card-dashed1 ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style }}
          >
            <Accordion.Toggle eventKey={"toggle-company"} className="cursor-pointer p-14px bg-white h-0px-mn" as={Card.Header}>
              <div className="d-flex flex-stack w-100">
                <div className="d-flex align-items-center gap-2">
                  <i className="bi bi-building side-pannel-card-header-color"></i>
                  <span className="fs-6 m-0 side-pannel-card-header-color">Marketing Data</span>
                  <i className={`side-panel-data-icon fas fa-sm fa-chevron-${isOpen ? "up" : "down"} ml-2 side-pannel-card-header-color`}></i>
                </div>
                <div className="d-flex flex-center gap-4" onClick={(e) => e.stopPropagation()}>
                  <i onClick={(e) => e.stopPropagation()} className="fa fa-bars fa-md" aria-hidden="true" {...provided.dragHandleProps}></i>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"toggle-company"}>
              <InlineStyled as={Card.Body} styles={{ padding: "1rem" }}>
                {!_.isEmpty(getCompanyDetails()) ? (
                  <>
                    <ViewMore
                      cellEdit={cellEdit}
                      setCellEdit={setCellEdit}
                      handleCellEdit={handleCellEdit}
                      addTag={addTag}
                      editTag={editTag}
                      deleteTag={deleteTag}
                      page={"marketing"}
                      pageSub={"marketing"}
                      showColumns={isViewMore ? showColumns : viewLessColomns}
                      columns={fieldStore.list}
                      idToIndex={fieldStore.idToIndex}
                      viewing={getCompanyDetails()}
                      cellLoading={marketingDataListStore.cellLoading || marketingDataPopupStore.cellLoading}
                      loadingData={marketingLoadingDataStore}
                    />
                    {false && (
                      <div className="side-panel-data-item px-0">
                        <Button onClick={() => setIsViewMore(!isViewMore)} variant="link" className="p-0 pt-2 px-0 text-decoration-none">
                          {isViewMore ? "View Less" : `See ${loadMoreFieldCount} More`}
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div data-kt-search-element="empty" className="text-center">
                    <div className="pt-10 pb-10">
                      <span className="svg-icon svg-icon-4x opacity-50">
                        <i className="bi bi-building font-size-30px"></i>
                      </span>
                    </div>
                    <div className="pb-15 fw-bold">
                      <h3 className="text-gray-600 fs-5 mb-2">There are no data available.</h3>
                      <div className="text-muted fs-7">Marketing list data not avliable.</div>
                    </div>
                  </div>
                )}
              </InlineStyled>
            </Accordion.Collapse>
          </Accordion>
        )}
      </Draggable>
    </div>
  );
}
