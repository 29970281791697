import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as userActions from "../../actions/admin-actions/manage-users-settings.actions";
import * as userTypes from "../../types/admin-settings.types";
import { endpoints } from "../../endpoint";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";

function* getManageUserFunction(action) {
  try {
    const res = yield call(api.get, endpoints.GET_USER);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(userActions.getManageUserSuccess(res.data));
    } else {
      yield put(userActions.getManageUserError("error"));
    }
  } catch (e) {
    yield put(userActions.getManageUserError(e));
  }
}

function* patchManageUserFunction(action) {
  try {
    // const res = yield call(api.patch, `/api/crm/user/${action.paramId}`, action.payload);
    const res = yield call(api.patch, action.payload.from === "edit-teams" ? `/api/crm/user/${action.paramId}` : "/user-service/api/user/activate-deactivate", action.payload);
    // some condition{}
    if (res.status === 200) {
      yield put(userActions.patchManageUserSuccess(res.data));
      yield action.cb(res.data);
    } else {
      yield put(userActions.patchManageUserError("error"));
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(userActions.patchManageUserError(e));
  }
}

function* deleteManageUserFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.DELETE_USER`${action.payload.id}`, action.payload.body);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(userActions.deleteManageUserSuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError("User can not be deleted", "error");
    } else {
      yield put(userActions.deleteManageUserError("error"));
    }
  } catch (e) {
    yield put(userActions.deleteManageUserError(e));
  }
}

function* postManageUserFunction(action) {
  try {
    const res = yield call(api.post, endpoints.POST_USER, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(userActions.postManageUserSuccess(res.data));
    } else if (res.status === 201) {
      yield action.cb(res.data);
      yield put(userActions.postManageUserSuccess(res.data));
    } else if (res.status === 400) {
      dispatchSnackbarError(res.data.message, "error");
    } else {
      yield put(userActions.postManageUserError("error"));
    }
  } catch (e) {
    yield put(userActions.postManageUserError(e));
  }
}

function* resendInvitationFunction(action) {
  try {
    const res = yield call(api.post, endpoints.RESEND_INVITATION, action.payload);
    if (res.status === 200) {
      yield put(userActions.resendInvitationSuccess(res.data));
      dispatchSnackbarSuccess(res.data.message, "success");
    }
  } catch (e) {
    yield put(userActions.resendInvitationError(e));
  }
}

function* patchManageRoleFunction(action) {
  try {
    const res = yield call(api.patch, endpoints.PATCH_USER_ROLE`${action.paramId}`, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(userActions.patchManageRoleSuccess(res.data));
    } else {
      yield put(userActions.patchManageRoleError("error"));
    }
  } catch (e) {
    //
    yield put(userActions.patchManageRoleError(e));
  }
}

export default function* manageUsersSaga() {
  yield takeLatest(userTypes.getUserRole.GET_USER_ROLE_REQUEST, getManageUserFunction);
  yield takeLatest(userTypes.patchManageUser.PATCH_MANAGE_USER_REQUEST, patchManageUserFunction);
  yield takeLatest(userTypes.patchManageRole.PATCH_MANAGE_ROLE_REQUEST, patchManageRoleFunction);
  yield takeLatest(userTypes.deleteManageUser.DELETE_MANAGE_USER_REQUEST, deleteManageUserFunction);
  yield takeLatest(userTypes.postManageUser.POST_MANAGE_USER_REQUEST, postManageUserFunction);
  yield takeLatest(userTypes.resendInvitation.RESEND_INVITATION_REQUEST, resendInvitationFunction);
}
