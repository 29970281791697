import React, { useEffect, useState } from "react";
import { Card, Form, Spinner, Button, OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import { GreenGradientButton, BlueGradientButton } from "../../button";
import { dispatchSnackbarError } from "../../../utils/toaster";
import { useDispatch, useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DeleteModal } from "../../modal";
import { clearSignatureStatus, signatureAddRequest, signatureDeleteRequest, signatureEditRequest } from "../../../containers/leads/store/actions/actions";
import { dispatchSnackbarSuccess } from "../../../utils/toaster";
import RichTextEditor from "../../RichTextEditor/tinymce-editor";
import { InputLimit } from "../../../constants";

export function EmailSignatureEditor(props) {
  const [signatureList, setsignatureList] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState({ name: "", signature: "", isImported: false });
  const [showDeleteSignatures, setShowDeleteSignatures] = useState(false);
  const [content, setContent] = useState("");

  const dispatch = useDispatch();

  const signatureStore = useSelector((state) => state.Leads.signatureList);

  useEffect(() => {
    if (signatureStore.list) {
      setsignatureList(signatureStore.list);
    }
  }, [signatureStore.list]);

  useEffect(() => {
    if (props.isEdit && props.editId) {
      const find = signatureList.find((a) => a._id === props.editId);
      if (find) {
        onSelectsignature(find);
      }
    }
  }, [props.isEdit, props.editId, signatureList]);

  useEffect(() => {
    if (signatureStore.err) {
      dispatchSnackbarError(signatureStore.errMsg);
      dispatch(clearSignatureStatus());
    } else if (signatureStore.success) {
      dispatchSnackbarSuccess(signatureStore.successMsg);
      dispatch(clearSignatureStatus());
    }
  }, [signatureStore, dispatch]);

  const deleteSignatureModalProps = {
    show: showDeleteSignatures,
    title: "Delete Signature",
    handleClose: () => {
      setShowDeleteSignatures(false);
    },
    handleAccept: () => {
      handleDeleteSignature();
    },
  };
  const renderDefaultTooltip = (props) => (
    <Tooltip id="favorite-tooltip" {...props}>
      Set As Default
    </Tooltip>
  );

  const renderRemoveDefaultTooltip = (props) => (
    <Tooltip id="favorite-tooltip" {...props}>
      Remove Default
    </Tooltip>
  );

  const removeDefault = (id) => {
    dispatch(signatureEditRequest({ id: id, data: { isDefault: false } }));
  };

  const setDefault = (id) => {
    dispatch(signatureEditRequest({ id: id, data: { isDefault: true } }));
  };

  const handleDeleteSignature = () => {
    resetSignature();
    dispatch(signatureDeleteRequest(selectedSignature["_id"]));
    setShowDeleteSignatures(false);
  };

  const handleSaveSignature = () => {
    if (selectedSignature.name.trim() === "") {
      dispatchSnackbarError("Signature Name can not be blank!");
      return;
    }
    if (!content) {
      dispatchSnackbarError("Signature Body can not be blank!");
      return;
    }

    const tempSignatureBody = { name: selectedSignature.name, signature: content, isImported: false };
    dispatch(signatureAddRequest(tempSignatureBody));
    resetSignature();
  };
  const handleEditSignature = () => {
    if (selectedSignature.name.trim() === "") {
      dispatchSnackbarError("Signature Name can not be blank!");
      return;
    }

    if (!content) {
      dispatchSnackbarError("Signature Body can not be blank!");
      return;
    }
    setSelectedSignature({ ...selectedSignature, signature: content });
    dispatch(signatureEditRequest({ id: selectedSignature["_id"], data: { name: selectedSignature.name, signature: content } }));
  };

  const onSelectsignature = (signature) => {
    setSelectedSignature(signature);
    setContent(signature.signature);
  };

  const resetSignature = () => {
    setContent("");
    setSelectedSignature({ name: "", signature: "", isImported: false });
  };

  return (
    <>
      {signatureStore.loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-100vh-mn">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className={"d-flex h-100"}>
          <div className="w-35 h-100">
            <div className="d-flex pt-5 px-5 pb-5 flex-stack">
              <h3 className="m-0px line-height-1-point-15">Signatures</h3>
              <Button size="sm" onClick={() => resetSignature()}>
                + Signature
              </Button>
              {/* <IconButton size="sm" tooltip={"Add New Signature"} onClick={() => resetSignature()}> <i className="fa fa-plus" /></IconButton> */}
            </div>
            <PerfectScrollbar className="scroll px-5 py-5 position-relative h-95-percent-mx" options={{ wheelSpeed: 0.4 }}>
              <Card className="mb-8 border-0">
                <Card.Body className="p-0 border-0">
                  {signatureList.map((signature, index) => (
                    <ListGroup.Item
                      onClick={() => onSelectsignature(signature)}
                      className={`d-flex flex-stack dropdown-hover pl-6 cursor-pointer gap-10px ${selectedSignature["_id"] === signature["_id"] ? "dropdown-selected" : null}`}
                      key={index}
                    >
                      <span>{signature.name}</span>
                      {signature.isDefault ? (
                        <OverlayTrigger overlay={renderRemoveDefaultTooltip}>
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              removeDefault(signature["_id"]);
                            }}
                            className="fa fa-md fa-star text-primary"
                          ></i>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger overlay={renderDefaultTooltip}>
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              setDefault(signature["_id"]);
                            }}
                            className="fa fa-md fa-star"
                          ></i>
                        </OverlayTrigger>
                      )}
                    </ListGroup.Item>
                  ))}
                </Card.Body>
              </Card>
            </PerfectScrollbar>
          </div>
          <div className="h-100 w-65">
            <div className="d-flex pt-5 px-5 pb-5 flex-stack">
              <h3 className="m-0px line-height-1-point-15">{selectedSignature.name || "New Signature"}</h3>
              {selectedSignature["_id"] ? (
                <div className="d-flex gap-10px">
                  <BlueGradientButton size="sm" onClick={() => handleEditSignature()}>
                    Save Changes
                  </BlueGradientButton>
                  <Button
                    onClick={() => {
                      setShowDeleteSignatures(true);
                    }}
                    size="sm"
                    variant="danger"
                  >
                    Delete
                  </Button>
                </div>
              ) : (
                <GreenGradientButton size="sm" onClick={() => handleSaveSignature()}>
                  Save
                </GreenGradientButton>
              )}
            </div>
            <PerfectScrollbar className="scroll px-5 py-5 position-relative h-95" options={{ wheelSpeed: 0.4 }}>
              <Form className="h-95 d-flex flex-column">
                <Form.Group id="email-signature-editor-side-pannel-selected-name">
                  <Form.Label aria-label="name">Name</Form.Label>
                  <Form.Control
                    maxLength={InputLimit.TEXT}
                    type="text"
                    value={selectedSignature.name}
                    onChange={(e) =>
                      setSelectedSignature({
                        ...selectedSignature,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group id="email-signature-editor-side-pannel-selected-body" className="flex-grow-1">
                  <Form.Label aria-label="body" className="d-flex w-100 justify-content-between">
                    <span>Body</span>
                  </Form.Label>
                  <Card className="h-50vh panel-shadow d-flex flex-column w-100">
                    <RichTextEditor defaultValue={selectedSignature.signature} value={content} onChange={(c) => setContent(c)} toolbarLocation={"bottom"} />
                  </Card>
                </Form.Group>
              </Form>
            </PerfectScrollbar>
          </div>
          <DeleteModal modalProps={deleteSignatureModalProps}>Are you sure you want to delete the signature?</DeleteModal>
        </div>
      )}
    </>
  );
}
