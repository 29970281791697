import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { InputLimit } from "../../../../../constants";
import _ from "lodash";

const promptExamples = {
    "LEAD": [
        {
            "label": "Write an Outbound Email",
            "value": "Write up to 100 word introduction for an outbound email to {{lead.name}} who is currently working at {{lead.company}} as {{lead.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a SMS",
            "value": "Write up to 30 words a SMS message to {{lead.name}} who is currently working at {{lead.company}} as {{lead.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a LinkedIn DM",
            "value": "Write  up to 30 words a LinkedIn DM to {{lead.name}} who is currently working at {{lead.company}} as {{lead.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a follow-up LinkedIn DM",
            "value": "Write  up to 30 words a follow-up LinkedIn DM to {{lead.name}} who is currently working at {{lead.company}} as {{lead.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a follow-up email",
            "value": "Write up to 30 words a follow-up email to {{lead.name}} who is currently working at {{lead.company}} as {{lead.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Generate a company description",
            "value": "Write up to 50 words a description for {{lead.company}} whose domain name is {{company.website}}."
        },
        {
            "label": "Write an icebreaker for an email",
            "value": "Write up to 100 words an icebreaker for an email to {{lead.name}} who is currently working at {{lead.company}} as {{lead.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Find company hq",
            "value": "Where is the HQ of {{lead.company}} whose domain name is {{company.website}}?\nOnly respond with the location. If not available publicly, respond with Not available. Don't add a full stop at the end."
        },
        {
            "label": "Find competitors",
            "value": "Can you find the main competitors of {{lead.company}} ({{company.website}})?\nReply only with the name of the competitor, no full stop."
        },
        {
            "label": "Find company domain",
            "value": "What is the domain name of {{lead.company}}?\nAnswer only with the domain name, no full stop."
        },
        {
            "label": "Find company industry",
            "value": "In which industry is this {{lead.company}} operating?\nReply only with the industry, no full stop."
        },
        {
            "label": "Find company email domain",
            "value": "What's the email domain of {{lead.company}}?\nReply only with the email domain, no full stop."
        }
    ],
    "PEOPLE": [
        {
            "label": "Write an Outbound Email",
            "value": "Write up to 100 words an introduction for an outbound email to {{people.name}} who is currently working at {{people.company}} as {{people.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a SMS",
            "value": "Write up to 30 words a SMS message to {{people.name}} who is currently working at {{people.company}} as {{people.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a LinkedIn DM",
            "value": "Write up to 100 words a LinkedIn DM to {{people.name}} who is currently working at {{people.company}} as {{people.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a follow-up email",
            "value": "Write up to 100 words a follow-up email to {{people.name}} who is currently working at {{people.company}} as {{people.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Generate a company description",
            "value": "Write up to 50 words a description for {{people.company}} whose domain name is {{company.website}}."
        },
        {
            "label": "Write an icebreaker for an email",
            "value": "Write up to 100 words an icebreaker for an email to {{people.name}} who is currently working at {{people.company}} as {{people.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Find company hq",
            "value": "Where is the HQ of {{people.company}} whose domain name is {{company.website}}?\nOnly respond with the location. If not available publicly, respond with Not available. Don't add a full stop at the end."
        },
        {
            "label": "Find competitors",
            "value": "Can you find the main competitors of {{people.company}} ({{company.website}})?\nReply only with the name of the competitor, no full stop."
        },
        {
            "label": "Find company domain",
            "value": "What is the domain name of {{people.company}}?\nAnswer only with the domain name, no full stop."
        },
        {
            "label": "Find company industry",
            "value": "In which industry is this {{people.company}} operating?\nReply only with the industry, no full stop."
        },
        {
            "label": "Find company email domain",
            "value": "What's the email domain of {{people.company}}?\nReply only with the email domain, no full stop."
        }
    ],
    "COMPANY": [

        {
            "label": "Generate a company description",
            "value": "Write up to 50 words a description for {{company.name}} whose domain name is {{company.website}}."
        },
        {
            "label": "Find company hq",
            "value": "Where is the HQ of {{company.name}} whose domain name is {{company.website}}?\nOnly respond with the location. If not available publicly, respond with Not available. Don't add a full stop at the end.",
        },
        {
            "label": "Find competitors",
            "value": "Can you find the main competitors of {{company.name}} ({{company.website}})?\nReply only with the name of the competitor, no full stop.",
        },
        {
            "label": "Find company domain ",
            "value": "What is the domain name of {{company.name}}?\nAnswer only with the domain name, no full stop.",
        },
        {
            "label": "Find company industry",
            "value": "In which industry is this {{company.name}} operating?\nReply only with the industry, no full stop.",
        },
        {
            "label": "Find company email domain ",
            "value": "What's the email domain of {{company.name}}?\nReply only with the email domain, no full stop.",
        }
    ],
    "MARKETING": [
        {
            "label": "Write an Outbound Email",
            "value": "Write up to 100 words an introduction for an outbound email to {{marketing.firstName}} {{marketing.lastName}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a SMS",
            "value": "Write up to 30 words a SMS message to {{marketing.firstName}} {{marketing.lastName}} following up on the call and if they were interested in moving forward.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write a follow-up email",
            "value": "Write up to 100 words a follow-up email to {{marketing.firstName}} {{marketing.lastName}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write an icebreaker for an email",
            "value": "Write up to 100 words an icebreaker for an email to {{marketing.firstName}} {{marketing.lastName}}.\nMake it feel professional but relaxed."
        }
    ],
    "DEAL": [
        {
            "label": "Write an Outbound Email",
            "value": "Write up to 100 words an introduction for an outbound email to {{people.name}} who is currently working at {{company.name}} as {{people.designation}}.\nMake it feel professional but relaxed.",
        },
        {
            "label": "Write a follow-up email ",
            "value": "Write up to 100 words a follow-up email to {{people.name}} who is currently working at {{company.name}} as {{people.designation}}.\nMake it feel professional but relaxed."
        },
        {
            "label": "Write an icebreaker for an email",
            "value": "Write up to 100 words an icebreaker for an email to {{people.name}} who is currently working at {{company.name}} as {{people.designation}}.\nMake it feel professional but relaxed."
        }
    ]
}

export default function AIWizardPromptField(props) {
    const [variableOptions, setVariableOptions] = useState([]);

    useEffect(() => {
        if (_.isEmpty(props.fieldsData)) return setVariableOptions([]);

        const systemFields = props.fieldsData.system;
        const customFields = props.fieldsData.custom;

        const systemFieldsOptions = systemFields.map((field) => {
            return {
                label: field.fieldName,
                value: `{{${_.toLower(field.fieldType)}.${field.apiKeyName}}}`
            };
        });

        const customFieldsOptions = customFields.map((field) => {
            return {
                label: field.fieldName,
                value: `{{${_.toLower(field.fieldType)}._CF_${field.apiKeyName}}}`
            };
        });

        setVariableOptions([...systemFieldsOptions, ...customFieldsOptions]);
    }, [props.fieldsData])


    return (<Form.Group className="mb-0">
        <div className="sms-text-editor-wrapper">
            <textarea
                placeholder="Describe what you need using {{variables}}"
                maxLength={InputLimit.TEXTAREA}
                id="textarea"
                value={props.prompt}
                onChange={(e) => props.setPrompt(e.target.value)}
                className="sms-textarea"
                cols="50"
                rows="10"
                maxlength="4000"
            />
            <div className="sms-text-editor-options-wrapper">
                <div className="border-right">
                    <Dropdown className="dropdown-inline mt-1">
                        <Dropdown.Toggle
                            className="text-left border-0 "
                            variant="transparent"
                        >
                            <i class="bi bi-braces-asterisk"></i>Variables
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu w-100-mn h-400px-mx overflow-auto">
                            {variableOptions.map((variable) => (
                                <Dropdown.Item className="dropdown-hover" onClick={() => props.setPrompt(`${props.prompt} ${variable.value}`)}>
                                    {variable.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="border-left px-10px d-flex flex-center cursor-pointer">

                    <Dropdown className="dropdown-inline mt-1">
                        <Dropdown.Toggle
                            className="text-left border-0 "
                            variant="transparent"
                        >
                            Prompt Examples
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu w-100-mn h-400px-mx overflow-auto">
                            {(promptExamples[_.toUpper(props.fieldType)] || []).map((variable) => (
                                <Dropdown.Item className="dropdown-hover" onClick={() => props.setPrompt(variable.value)}>
                                    {variable.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    </Form.Group>)
}