import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as companyOperationsActions from "../actions/company-operation.actions";
import * as companyActions from "../actions/actions";
import * as companyOperationTypes from "../types/company-operation.types";

const endpoints = {
  BULK_EDIT_V2: "/api/crm/company/v2/bulk-edit",
  BULK_DELETE_V2: "/api/crm/company/v2/delete",
};

function* bulkEditCompanyV2(action) {
  try {
    const res = yield call(api.post, endpoints.BULK_EDIT_V2, action.payload.companyData);
    if (res.status === 200) {
      yield put(companyOperationsActions.bulkEditCompanyV2Success({ message: res.data.message }));
      yield put(companyActions.companiesRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(companyOperationsActions.bulkEditCompanyV2Error({ message: res.data.message }));
    } else {
      yield put(companyOperationsActions.bulkEditCompanyV2Error({ message: "Could Not Edit Companies" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(companyOperationsActions.bulkEditCompanyV2Error({ message: "Could Not Edit Companies" }));
  }
}

function* bulkDeleteCompanyV2(action) {
  try {
    const res = yield call(api.patch, endpoints.BULK_DELETE_V2, action.payload.companyIds);
    if (res.status === 200) {
      yield put(companyOperationsActions.bulkDeleteCompanyV2Success({ message: res.data.message }));
      yield put(companyActions.companiesRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(companyOperationsActions.bulkDeleteCompanyV2Error({ message: res.data.message }));
    } else {
      yield put(companyOperationsActions.bulkDeleteCompanyV2Error({ message: "Could Not Delete Companies" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(companyOperationsActions.bulkDeleteCompanyV2Error({ message: "Could Not Delete Companies" }));
  }
}

export default function* companyOperationSaga() {
  yield takeLatest(companyOperationTypes.bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_REQUEST, bulkDeleteCompanyV2);
  yield takeLatest(companyOperationTypes.bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_REQUEST, bulkEditCompanyV2);
}
