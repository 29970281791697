import { combineReducers } from "redux";
import subscriptionReducer from "./subscription_billing_reducer";
import walletReducer from "./wallet_billing_reducer";
import subscriptionBillingV2Reducer from "./subscription_billing_v2_reducer";

const billingModuleReducer = combineReducers({
  subscription: subscriptionReducer,
  subscriptionv2: subscriptionBillingV2Reducer,
  wallet: walletReducer,
});

export default billingModuleReducer;
