import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import _ from "lodash";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
// import {QuickActionsDropdown1} from "../extras/dropdowns/QuickActionsDropdown1"
// import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import  LanguageSelectorDropdown  from "../extras/dropdowns/LanguageSelectorDropdown";
import { UserProfileDropdown } from "../extras/dropdowns/UserProfileDropdown";
// import { QuickUserToggler } from "../extras/QuiclUserToggler";
import "./styles.scss";
import { useSelector } from "react-redux";
import ActivityLogs from "../extras/offcanvas/activityLogs";
import SwitchCompany from "../extras/offcanvas/SwitchCompany";
import SubscriptionPlanModal from "../../../../containers/Settings/Billing/containers/subscription-billing-v2/subscription-modal/index";
import { getUsageRecordReportRequest } from "../../../../containers/Settings/actions/usage.actions";
// import moment from 'moment';
import { useDispatch } from "react-redux";
// import Updates from "./header-menu/Updates";
import { BugReport } from "./header-menu/bug";

export function Topbar() {
  const [showPlans, setShowPlans] = useState(false);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  // const userProfile = useSelector(state => state.ProfileSettings.getProfileDetails);
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      viewNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      viewQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      // viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      viewLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      viewActivityLogs: objectPath.get(uiService.config, "extras.activity-logs.display"),
    };
  }, [uiService]);

  useEffect(() => {
    dispatch(getUsageRecordReportRequest());
  }, [dispatch]);

  const isBillingExpired = () => {
    // if (accountBillingStore.loading) return false;
    if (_.isEmpty(accountBillingStore.data)) return false;
    const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
    if (isFreeTrialPeriod || isSubscribedForLifeTimeFree) return false;
    else if (["active", "past_due", "trialing"].includes(subscriptionStatus)) return false;
    else if (["none", "canceled", "paused"].includes(subscriptionStatus)) return true;
    return true;
  };
  console.log("topbar", isBillingExpired());
  return (
    <>
      {(accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.accountStatus === "DEACTIVATED") ||
        isBillingExpired() ? (
        <div className="topbar">
          <SwitchCompany />

          {layoutProps.viewUserDisplay && <UserProfileDropdown />}
        </div>
      ) : (
        <div className="topbar z-index-100">
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
          {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}
          {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}
          <BugReport />
          {/* <Updates /> */}
          <ActivityLogs />
          <SwitchCompany />
          {layoutProps.viewUserDisplay && <UserProfileDropdown />}
        </div>
      )}
    </>
  );
}
