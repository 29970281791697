import React, { useState, useEffect } from "react";
import * as actions from "../../actions/settings.actions";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const notificationOptions = [
  {
    heading: "Send emails about deals I follow",
    subHeading: "Get notification summaries to your email. These settings do not affect getting notifications inside Salessimplify app.",
    parentKey: "emailsAbout",
    key: "deals",
  },
  {
    heading: "Send emails about organizations I follow",
    subHeading: "Get notification summaries to your email. These settings do not affect getting notifications inside Salessimplify app.",
    parentKey: "emailsAbout",
    key: "org",
  },
  {
    heading: "Send emails about people I follow",
    subHeading: "Get notification summaries to your email. These settings do not affect getting notifications inside Salessimplify app.",
    parentKey: "emailsAbout",
    key: "people",
  },
  {
    heading: "Remind me about My activities",
    subHeading: "Get reminders for upcoming activities to your email.",
    parentKey: "activityEmails",
    key: "remind",
  },
  {
    heading: "Separate emails about each activity",
    subHeading: "Separate emails about each activity.",
    parentKey: "activityEmails",
    key: "seperateEmail",
  },
  {
    heading: "Send an email immediately if a new activity is scheduled",
    subHeading: "Send an email immediately if a new activity is scheduled and there will be no regular reminders before activity",
    parentKey: "activityEmails",
    key: "newEmail",
  },
];
export default function Notification() {
  const dispatch = useDispatch();
  const [notificationSettingObject, setNotificationSettingObject] = useState({
    deals: false,
    org: false,
    people: false,
    remind: false,
    seperateEmail: false,
    newEmail: false,
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.getNotificationRequest((data) => {
        setLoading(false);
        if (data["data"] !== null) {
          try {
            setNotificationSettingObject({
              deals: data["data"]["emailsAbout"]["deals"],
              org: data["data"]["emailsAbout"]["org"],
              people: data["data"]["emailsAbout"]["people"],
              remind: data["data"]["activityEmails"]["remind"],
              seperateEmail: data["data"]["activityEmails"]["seperateEmail"],
              newEmail: data["data"]["activityEmails"]["newEmail"],
            });
          } catch (err) {}
        }
      }),
    );
  }, [dispatch]);

  const updateNotificationData = (parentKey, key, value) => {
    setLoading(false);
    const data = {
      emailsAbout: {
        deals: notificationSettingObject.deals,
        time: 0,
        org: notificationSettingObject.org,
        people: notificationSettingObject.people,
      },
      activityEmails: {
        remind: notificationSettingObject.remind,
        time: 0,
        seperateEmail: notificationSettingObject.seperateEmail,
        newEmail: notificationSettingObject.newEmail,
      },
    };
    data[parentKey][key] = value;

    const updateData = {
      deals: notificationSettingObject.deals,
      org: notificationSettingObject.org,
      people: notificationSettingObject.people,
      remind: notificationSettingObject.remind,
      seperateEmail: notificationSettingObject.seperateEmail,
      newEmail: notificationSettingObject.newEmail,
    };
    updateData[key] = value;
    setNotificationSettingObject(updateData);

    dispatch(
      actions.postNotificationRequest(data, () => {
        setLoading(false);
      }),
    );
  };

  return (
    <>
      {loading ? (
        <>
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="card mb-5 mb-xl-10 h-vh-160px overflow-y-auto">
          {/*begin::Card header*/}
          <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">
                Notification <sup className="beta-container">BETA</sup>
              </h3>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Content*/}
          <div id="kt_account_signin_method" className="collapse show">
            {/*begin::Card body*/}
            <div className="card-body border-top p-9 overflow-y-auto h-vh-300px-mx">
              <form className="form">
                {notificationOptions.map((each, index) => {
                  return (
                    <div key={index} onClick={(e) => e.stopPropagation()}>
                      <div className="d-flex" onClick={(e) => e.stopPropagation()}>
                        <div className="form-check form-check-custom form-check-solid" onClick={(e) => e.stopPropagation()}>
                          <input
                            className="form-check-input me-3"
                            name={each.key}
                            type="checkbox"
                            id={`kt_modal_update_email_notification_0-${index}`}
                            checked={notificationSettingObject[each.key]}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateNotificationData(each.parentKey, each.key, !notificationSettingObject[each.key]);
                            }}
                          />
                          <label onClick={(e) => e.stopPropagation()} className="form-check-label ml-12" for="kt_modal_update_email_notification_0">
                            <div className="fw-bolder">{each.heading}</div>
                            <div className="text-gray-600">{each.subHeading}</div>
                          </label>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-5"></div>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
