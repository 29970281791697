// import { IStage } from 'modules/boards/types';
import * as React from "react";
import { Result } from "../styled";
import Stage from "./stage";

class List extends React.Component {
  calcSpace = (lenght, index) => {
    return 100 - (index * (100 / lenght)) / 1.5;
  };

  renderFooter() {
    const { stages } = this.props;

    if (stages.length === 0) {
      return;
    }

    const firstStage = stages[0] || {};
    const lastStage = stages.slice(-1)[0] || {};

    const firstStageInitialDealsTotalCount = firstStage.initialDealsTotalCount || 1;

    const lastStageItemsTotalCount = lastStage.itemsTotalCount || 0;

    const avarage = (lastStageItemsTotalCount * 100) / firstStageInitialDealsTotalCount;

    return <Result>Winrate: {parseInt(`${avarage}`, 10)}%</Result>;
  }

  render() {
    const { stages } = this.props;

    const contents = stages.map((stage, index) => <Stage spacing={this.calcSpace(stages.length, index)} key={index} stage={stage} />);

    return (
      <>
        {contents}
        {this.renderFooter()}
      </>
    );
  }
}

export default List;
