export const bulkEditPeopleV2 = {
  BULK_EDIT_PEOPLE_V2_REQUEST: "BULK_EDIT_PEOPLE_V2_REQUEST",
  BULK_EDIT_PEOPLE_V2_SUCCESS: "BULK_EDIT_PEOPLE_V2_SUCCESS",
  BULK_EDIT_PEOPLE_V2_ERROR: "BULK_EDIT_PEOPLE_V2_ERROR",
};

export const bulkDeletePeopleV2 = {
  BULK_DELETE_PEOPLE_V2_REQUEST: "BULK_DELETE_PEOPLE_V2_REQUEST",
  BULK_DELETE_PEOPLE_V2_SUCCESS: "BULK_DELETE_PEOPLE_V2_SUCCESS",
  BULK_DELETE_PEOPLE_V2_ERROR: "BULK_DELETE_PEOPLE_V2_ERROR",
};
