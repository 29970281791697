import {
  pauseCadenceSubscriber,
  removeFromCadenceSubscriber,
  markAsFinishedCadenceSubscriber,
  markAsRepliedCadenceSubscriber,
  retryCadenceSubscriber,
  resumeCadenceSubscriber,
  changeStepCadenceSubscriber,
} from "../types/cadence_subscriber_operation_types";

export const pauseCadenceSubscriberRequest = (payload, cb) => ({
  type: pauseCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const pauseCadenceSubscriberSuccess = (payload) => ({
  type: pauseCadenceSubscriber.SUCCESS,
  payload,
});
export const pauseCadenceSubscriberError = (payload) => ({
  type: pauseCadenceSubscriber.FAILURE,
  payload,
});

export const resumeCadenceSubscriberRequest = (payload, cb) => ({
  type: resumeCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const resumeCadenceSubscriberSuccess = (payload) => ({
  type: resumeCadenceSubscriber.SUCCESS,
  payload,
});
export const resumeCadenceSubscriberError = (payload) => ({
  type: resumeCadenceSubscriber.FAILURE,
  payload,
});

export const markAsFinishedCadenceSubscriberRequest = (payload, cb) => ({
  type: markAsFinishedCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const markAsFinishedCadenceSubscriberSuccess = (payload) => ({
  type: markAsFinishedCadenceSubscriber.SUCCESS,
  payload,
});
export const markAsFinishedCadenceSubscriberError = (payload) => ({
  type: markAsFinishedCadenceSubscriber.FAILURE,
  payload,
});

export const changeStepCadenceSubscriberRequest = (payload, cb) => ({
  type: changeStepCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const changeStepCadenceSubscriberSuccess = (payload) => ({
  type: changeStepCadenceSubscriber.SUCCESS,
  payload,
});
export const changeStepCadenceSubscriberError = (payload) => ({
  type: changeStepCadenceSubscriber.FAILURE,
  payload,
});

export const removeFromCadenceSubscriberRequest = (payload, cb) => ({
  type: removeFromCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const removeFromCadenceSubscriberSuccess = (payload) => ({
  type: removeFromCadenceSubscriber.SUCCESS,
  payload,
});
export const removeFromCadenceSubscriberError = (payload) => ({
  type: removeFromCadenceSubscriber.FAILURE,
  payload,
});

export const markAsRepliedCadenceSubscriberRequest = (payload, cb) => ({
  type: markAsRepliedCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const markAsRepliedCadenceSubscriberSuccess = (payload) => ({
  type: markAsRepliedCadenceSubscriber.SUCCESS,
  payload,
});
export const markAsRepliedCadenceSubscriberError = (payload) => ({
  type: markAsRepliedCadenceSubscriber.FAILURE,
  payload,
});

export const retryCadenceSubscriberRequest = (payload, cb) => ({
  type: retryCadenceSubscriber.REQUEST,
  payload,
  cb,
});
export const retryCadenceSubscriberSuccess = (payload) => ({
  type: retryCadenceSubscriber.SUCCESS,
  payload,
});
export const retryCadenceSubscriberError = (payload) => ({
  type: retryCadenceSubscriber.FAILURE,
  payload,
});

// Path: src/containers/Automation/cadence/store/reducers/cadence_subscriber_operation_reducer.js
// Compare this snippet from src/containers/Automation/cadence/store/reducers/cadence_subscriber_operation_reducer.js:
