import React, { useState } from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customColorMapping } from "../../custom-tables/constants";
import OwnerDisplay from "../../table/displays/ownerDisplay";

export const moduleColors = {
  sms: "info",
  whatsapp: "success",
  rcs: "danger",
  email: "primary",
};

const getCustomTagClass = (value) => {
  if (typeof value !== "string") return "dark";
  if (customColorMapping[value.toLowerCase()]) return customColorMapping[value.toLowerCase()];
  return "dark";
};
export function TimelineDataWrapper(props) {
  return (
    <div className="card p-0">
      {props.children && <div className="px-6 py-4">{props.children}</div>}
      {props.children && <hr className="m-0 border-top-width-1px border-top-style-solid border-top-color-ebedf3" />}
      <div className="d-flex justify-content-between align-items-center px-6 py-2">
        <div className="fs-7 mb-1 text-gray-600 d-flex align-items-center gap-2">
          <div className={`tag-ellipsis m-0 rounded-pill fs-7 badge badge-${props.moduleColor}`}>{props.moduleName}</div>
          <div>Created by {props.createdText}</div>
        </div>
        <div className="text-gray-600">{moment(props.createdAt).format("MMM DD, YYYY | HH:mm A")}</div>
      </div>
    </div>
  );
}

export function EmailCard({ item }) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <div className="border p-0 rounded position-relative border-0">
        <div className="position-absolute right-10px top-10px">
          <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">{expand ? "Collapse" : "Expand"}</Tooltip>}>
            <i className="bi bi-chevron-expand" onClick={() => setExpand(!expand)}></i>
          </OverlayTrigger>
        </div>
        <div>
          <div className="mb-2">
            <span className="text-gray-900 mr-2 ">Campaign Title:</span>
            <span className="text-gray-700">{item.campaignId.title}</span>
          </div>
          {item.customerId && (
            <div>
              {item.customerId.sentAt && (
                <div className="mb-2">
                  <span className="text-gray-900 mr-2">Email Sent At:</span>
                  <span className="text-gray-700">{moment(item.customerId.sentAt).format("MMM DD, YYYY | HH:mm A")}</span>
                </div>
              )}
              <div className="mb-2">
                <span className="text-gray-900 mr-2">Email Status:</span> <span className={`badge badge-light-${getCustomTagClass(item.customerId.status)}`}>{item.customerId.status}</span>
              </div>
              {item.customerId.failedReason && (
                <div className="mb-2">
                  <span className="text-gray-900 mr-2">Email Failed Reason:</span> <span className="text-gray-700">{item.customerId.failedReason}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {expand && (
          <div className="toggle-off-item border border-1 p-3 rounded-2">
            <div dangerouslySetInnerHTML={{ __html: item.campaignId.content.message }} />
          </div>
        )}
      </div>
    </>
  );
}
export function SMSCard({ item }) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <div className="border p-0 rounded position-relative border-0">
        <div className="position-absolute right-10px top-10px">
          <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip">{expand ? "Collapse" : "Expand"}</Tooltip>}>
            <i className="bi bi-chevron-expand" onClick={() => setExpand(!expand)}></i>
          </OverlayTrigger>
        </div>
        <div>
          <div className="mb-2">
            <span className="text-gray-900 mr-2 ">Campaign Title:</span>
            <span className="text-gray-700">{item.campaignId.title}</span>
          </div>
          {item.customerId && (
            <div>
              {item.customerId.sentAt && (
                <div className="mb-2">
                  <span className="text-gray-900 mr-2">SMS Sent At:</span>
                  <span className="text-gray-700">{moment(item.customerId.sentAt).format("MMM DD, YYYY | HH:mm A")}</span>
                </div>
              )}
              <div className="mb-2">
                <span className="text-gray-900 mr-2">SMS Status:</span> <span className={`badge badge-light-${getCustomTagClass(item.customerId.status)}`}>{item.customerId.status}</span>
              </div>
              {item.customerId.failedReason && (
                <div className="mb-2">
                  <span className="text-gray-900 mr-2">SMS Failed Reason:</span> <span className="text-gray-700">{item.customerId.failedReason}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {expand && (
          <div className="toggle-off-item border border-1 p-3 rounded-2">
            <div dangerouslySetInnerHTML={{ __html: item.campaignId.content.message }} />
          </div>
        )}
      </div>
    </>
  );
}

export function WhatsappCard({ item }) {
  return (
    <>
      <div className="border p-0 rounded position-relative border-0">
        <div className="position-absolute right-10px top-10px">
        </div>
        <div>
          <div className="mb-2">
            <span className="text-gray-900 mr-2 ">Campaign Title:</span>
            <span className="text-gray-700">{item.campaignId.title}</span>
          </div>
          {item.customerId && (
            <div>
              {item.customerId.sentAt && (
                <div className="mb-2">
                  <span className="text-gray-900 mr-2">Whastapp Sent At:</span>
                  <span className="text-gray-700">{moment(item.customerId.sentAt).format("MMM DD, YYYY | HH:mm A")}</span>
                </div>
              )}
              <div className="mb-2">
                <span className="text-gray-900 mr-2">Whstapp Status:</span> <span className={`badge badge-light-${getCustomTagClass(item.customerId.status)}`}>{item.customerId.status}</span>
              </div>
              {item.customerId.failedReason && (
                <div className="mb-2">
                  <span className="text-gray-900 mr-2">Whstapp Failed Reason:</span> <span className="text-gray-700">{item.customerId.failedReason}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const getCampaignEmailContent = (item) => {
  const ownerName = item.campaignId.owner;
  return (
    <TimelineDataWrapper moduleName={"Email"} moduleColor={moduleColors["email"]} createdAt={item.campaignId.createdAt} createdText={<OwnerDisplay showOnlyName={true} value={ownerName} />}>
      <EmailCard item={item} />
    </TimelineDataWrapper>
  );
};

export const getCampaignSMSContent = (item) => {
  const ownerName = item.campaignId.owner;
  return (
    <TimelineDataWrapper moduleName={"SMS"} moduleColor={moduleColors["sms"]} createdAt={item.campaignId.createdAt} createdText={<OwnerDisplay showOnlyName={true} value={ownerName} />}>
      <SMSCard item={item} />
    </TimelineDataWrapper>
  );
};

export const getCampaignWhatsappContent = (item) => {
  const ownerName = item.campaignId.owner;
  return (
    <TimelineDataWrapper moduleName={"Whatsapp"} moduleColor={moduleColors["whatsapp"]} createdAt={item.campaignId.createdAt} createdText={<OwnerDisplay showOnlyName={true} value={ownerName} />}>
      <WhatsappCard item={item} />
    </TimelineDataWrapper>
  );
};