import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import HybridEmailComposer from "../../saino-email-composer/hybrid-email-composer";
import { ActivityListLogs } from "../components/activity.side-pannel";
import CadenceSidePannel from "../components/cadence.side-pannel";
import { EmailsListLogs } from "../components/email.side-pannel";
import { Notes } from "../components/notes.side-panel";
import { Timeline } from "../components/timeline.side-pannel";
import { SidebarTabs } from "../custom-components/tabs";

export default function MainSectionB(props) {
  const [options, setOptions] = useState(["Timeline", "Notes", "Activity", "Email"]);
  const [selectedTab, setSelectedTab] = useState("Timeline");
  const [emailAction, setEmailAction] = useState(null);
  const [emailActionData, setEmailActionData] = useState(null);

  //email section
  const [emailSentLoading, setEmailSentLaoding] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    if (props.pageSub === "lead" || props.pageSub === "people") {
      setOptions(["Timeline", "Notes", "Activity", "Email", "Cadence"]);
    } else {
      setOptions(["Timeline", "Notes", "Activity", "Email"]);
    }
  }, [props.pageSub]);

  useEffect(() => {
    if (props.autoUpdateActivityEvent && props.activityEvent && !_.isEmpty(props.activityEvent)) {
      if (props.activityEvent.content) {
        setSelectedTab("Email");
      }
    }
  }, [props.autoUpdateActivityEvent, props.activityEvent]);

  useEffect(() => {
    if (search) {
      const tab = new URLSearchParams(search).get("tab");
      setSelectedTab(tab);
    }
  }, [search]);

  const handleActionClick = (action, data) => {
    setEmailAction(action);
    setEmailActionData(data);
  };
  const onResetClick = () => {
    setEmailAction(null);
    setEmailActionData(null);
  };

  return (
    <Tab.Container defaultActiveKey={selectedTab}>
      <SidebarTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={options} />
      <div>
        {props.peopleId || props.companyId || props.leadId || props.dealId ? (
          <div className="offcanvas-content pb-10">
            <div className="tab-content">
              <div role="tabpanel" className={`tab-pane fade py-7 px-5 pr-5 mr-n5 bg-white ${selectedTab === "Timeline" ? "active show" : ""}`}>
                {selectedTab === "Timeline" && (
                  <Timeline
                    peopleId={props.peopleId}
                    companyId={props.companyId}
                    leadId={props.leadId}
                    dealId={props.dealId}
                    type={props.pageSub}
                    pageForm={props.pageForm}
                    filter={props.filter}
                    basePath={props.basePath}
                    moduleType={props.moduleName}
                  />
                )}
              </div>
              <div role="tabpanel" className={`tab-pane fade py-3 px-5 pr-5 mr-n5 pt-7 bg-white ${selectedTab === "Notes" ? "active show" : ""}`}>
                {selectedTab === "Notes" && (
                  <Notes peopleId={props.peopleId} companyId={props.companyId} leadId={props.leadId} dealId={props.dealId} type={props.pageSub} basePath={props.basePath} moduleType={props.moduleName} />
                )}
              </div>
              <div role="tabpanel" className={`tab-pane fade py-3 px-5 pr-5 mr-n5 pt-7 bg-white ${selectedTab === "Activity" ? "active show" : ""}`}>
                {selectedTab === "Activity" && (
                  <ActivityListLogs
                    personName={""}
                    peopleId={props.peopleId}
                    companyId={props.companyId}
                    leadId={props.leadId}
                    dealId={props.dealId}
                    type={props.pageSub}
                    relatedAssociate={props.relatedAssociate}
                    pageForm={props.pageForm}
                    filter={props.filter}
                    basePath={props.basePath}
                    moduleType={props.moduleName}
                  />
                )}
              </div>
              <div role="tabpanel" className={`tab-pane fade py-3 px-5 mr-n5 pt-7 bg-white ${selectedTab === "Email" ? "active show" : ""}`}>
                {selectedTab === "Email" && (
                  <div className="d-flex flex-column">
                    <HybridEmailComposer
                      page={props.pageSub}
                      person={props.personData}
                      company={props.companyData}
                      peopleId={props.peopleId}
                      companyId={props.companyId}
                      leadId={props.leadId}
                      dealId={props.dealId}
                      basePath={props.basePath}
                      filter={props.filter}
                      pageForm={props.pageForm}
                      moduleType={props.moduleName}
                      setEmailSentLaoding={setEmailSentLaoding}
                      onResetClick={onResetClick}
                      emailAction={emailAction}
                      emailActionData={emailActionData}
                      autoUpdateActivityEvent={props.autoUpdateActivityEvent}
                      activityEvent={props.activityEvent}
                    />
                    <EmailsListLogs
                      showActions={true}
                      handleActionClick={handleActionClick}
                      peopleId={props.peopleId}
                      companyId={props.companyId}
                      leadId={props.leadId}
                      dealId={props.dealId}
                      type={props.pageSub}
                      emailSentLoading={emailSentLoading}
                    />
                  </div>
                )}
              </div>
              <div role="tabpanel" className={`tab-pane fade py-3 px-5 mr-n5 pt-7 bg-white ${selectedTab === "Cadence" ? "active show" : ""}`}>
                <CadenceSidePannel moduleName={props.moduleName} moduleId={props.moduleId} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Tab.Container>
  );
}
