import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as loginActions from "../../actions/profile-actions/login-session-settings.actions";
import * as loginTypes from "../../types/profile-settings.types";

function* getLoginSessionProfileFunction(action) {
  try {
    const res = yield call(api.get, `/user-service/api/session-details?limit=10&page=${action.currentPage}`);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(loginActions.getLoginSessionProfileSuccess(res.data));
    } else {
      yield put(loginActions.getLoginSessionProfileError("error"));
    }
  } catch (e) {
    yield put(loginActions.getLoginSessionProfileError(e));
  }
}
export default function* loginSessionSaga() {
  yield takeLatest(loginTypes.getLoginSessionProfile.GET_LOGIN_SESSION_PROFILE_REQUEST, getLoginSessionProfileFunction);
}
