import React, { useState } from "react";
import "./style.css";
// import Overview from '../../containers/Settings/profileSetting/overview'
// import Setting from '../../containers/Settings/profileSetting/setting'

export default function MultiTabs(props) {
  const [Tab1] = useState(props.TabName1);
  const [Tab2] = useState(props.TabName2);
  const [Tab3] = useState(props.TabName3);
  const [Tab4] = useState(props.TabName4);
  const [Tab5] = useState(props.TabName5);
  const [Tab6] = useState(props.TabName6);
  const [Tab7] = useState(props.TabName7);
  const [content1] = useState(props.content1);
  const [content2] = useState(props.content2);
  const [content3] = useState(props.content3);
  const [content4] = useState(props.content4);
  const [content5] = useState(props.content5);
  const [content6] = useState(props.content6);
  const [content7] = useState(props.content7);

  const [currentTab, setcurrentTab] = useState("Overview");

  function HandleActive(id) {
    setcurrentTab(id);

    let a = ["Overview", "Settings", "Security", "Billing", "Statements", "Referrals", "Api"];

    let b = a.filter((res) => res !== id);

    for (let i = 0; i < b.length; i++) {
      var element = document.getElementById(b[i]);
      element.classList.remove("active");
    }

    var v = document.getElementById(id);
    v.className += " active";
  }

  return (
    <>
      {/*begin::Post*/}
      <div className="post fs-base d-flex flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div id="kt_content_container" className="container">
          {/*begin::Navbar*/}
          <div className="card mb-5 mb-xl-10">
            {/* <div className="card-body pt-9 pb-0"> */}
            {/*begin::Details*/}
            {/*<div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            
            
             <div className="flex-grow-1">
              
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                
                <div className="d-flex flex-column">
                
                  <div className="d-flex align-items-center mb-2">
                    <a href="/#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">Max Smith</a>
                    <a href="/#">

                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                          <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                        </svg>
                      </span>

                    </a>
                    <a href="/#" className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Upgrade to Pro</a>
                  </div>

                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a href="/#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                      
                      <span className="svg-icon svg-icon-4 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                      </span>
                      Developer</a>
                    <a href="/#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">

                      <span className="svg-icon svg-icon-4 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                      </span>
                      SF, Bay Area</a>
                    
                  </div>
                
                </div>

                <div className="d-flex my-4">
                  <a href="/#" className="btn btn-sm btn-bg-light btn-active-color-primary me-3">Follow</a>
                  <a href="/#" className="btn btn-sm btn-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">Hire Me</a>
                  
                  <div className="me-0">
                    <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                      <i className="bi bi-three-dots fs-3" />
                    </button>
                   
                  </div>
              
                </div>
                
              </div>
              
              
            </div>
          
          </div> */}
            {/*end::Details*/}
            {/*begin::Navs*/}
            <div className="d-flex overflow-auto h-55px pl-25px bg-ccf3ff">
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Overview")}>
                  <div className="nav-link text-active-primary me-6 active" id="Overview">
                    {Tab1}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Settings")}>
                  <div className="nav-link text-active-primary me-6" id="Settings">
                    {Tab2}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Security")}>
                  <div className="nav-link text-active-primary me-6" id="Security">
                    {Tab3}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Billing")}>
                  <div className="nav-link text-active-primary me-6" id="Billing">
                    {Tab4}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Statements")}>
                  <div className="nav-link text-active-primary me-6" id="Statements">
                    {Tab5}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Referrals")}>
                  <div className="nav-link text-active-primary me-6" id="Referrals">
                    {Tab6}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("Api")}>
                  <div className="nav-link text-active-primary me-6" id="Api">
                    {Tab7}
                  </div>
                </li>
                {/*end::Nav item*/}
              </ul>
            </div>
            {/*begin::Navs*/}

            {/* </div> */}
          </div>
          {/*end::Navbar*/}

          {currentTab === "Overview" ? content1 : ""}
          {currentTab === "Settings" ? content2 : ""}
          {currentTab === "Security" ? content3 : ""}
          {currentTab === "Billing" ? content4 : ""}
          {currentTab === "Statements" ? content5 : ""}
          {currentTab === "Referrals" ? content6 : ""}
          {currentTab === "Api" ? content7 : ""}
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Row*/}

      {/*end::Container*/}
      {/*end::Post*/}
    </>
  );
}
