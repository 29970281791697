import React from "react";

export default function PlanNameSection({ data }) {
  let _planName = "";
  if (data.isSubscribedForLifeTimeFree) {
    _planName = `${data.ltdPlanType} (SPECIAL LIFETIME PLAN)`;
  } else if (data.isFreeTrialPeriod) {
    _planName = "Free Trial of Business Plan";
  } else if (data.selectedPlan.includes("free")) {
    _planName = "FREE PLAN";
  } else if (data.selectedPlan.includes("startup")) {
    _planName = "STARTUP PLAN";
  } else if (data.selectedPlan.includes("businessPlus")) {
    _planName = "BUSINESS PLUS PLAN";
  } else if (data.selectedPlan.includes("business")) {
    _planName = "BUSINESS PLAN";
  } else if (data.selectedPlan.includes("enterprise")) {
    _planName = "ENTERPRISE PLAN";
  }
  return (
    <div className="d-flex flex-stack flex-grow-1">
      <div className="fw-bold">
        <h4 className="text-gray-900 fw-bolder">
          You are currently on <span className="text-3699ff">{_planName}</span>
        </h4>
      </div>
    </div>
  );
}
