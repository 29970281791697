import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { PopoverEdit } from "../../popover_edit";
import CustomDisplay from "../../table/data";
import InlineStyled from "../../inline-styled/inline-styled";

export function ViewMore(props) {
  const [edit, setEdit] = useState(-1);
  const [editing, setEditing] = useState(false);
  const [tempViewing, setTempViewing] = useState(props.viewing);

  useEffect(() => {
    if (props.setEditingStatus) {
      props.setEditingStatus(editing);
    }
  }, [editing, props]);

  useEffect(() => {
    if (props.viewing) setTempViewing(props.viewing);
  }, [props.viewing]);

  const setHovering = (combinedId, colId) => {
    const colIndex = props.idToIndex[colId];
    if (props.columns[colIndex].isEditable && !editing) {
      setEdit(combinedId);
    }
  };

  // Hide edit button on mouse leave only if not editing
  const setNotHovering = () => {
    if (!editing) setEdit(-1);
  };

  const getData = (colId, data, combinedId) => {
    const colIndex = props.idToIndex[colId];

    let activityAndForDeal = props.columns[props.idToIndex[colId]];

    if (activityAndForDeal.fieldGroup === "SYSTEM" && activityAndForDeal.fieldInputType === "SELECT" && activityAndForDeal.fieldName === "Status" && activityAndForDeal.fieldType === "DEAL") {
      if (data.status === "active") return <span className="data-container badge badge-light-primary text-capitalize">{"Open"}</span>;
      else if (data.status === "archived") return <span className="data-container badge badge-light-warning text-capitalize">{data.status}</span>;
      else if (data.status === "won") return <span className="data-container badge badge-light-success text-capitalize">{data.status}</span>;
      else if (data.status === "lost") return <span className="data-container badge badge-light-danger text-capitalize">{data.status}</span>;
      else if (data.status === "deleted") return <span className="data-container badge badge-light-dark text-capitalize">{data.status}</span>;
      return <span className="data-container">{data.status}</span>;
    }
    return props.columns[colIndex].fieldGroup === "CUSTOM" && data.customFields ? (
      <CustomDisplay
        allData={data}
        sidePanel={true}
        page={props.page}
        pageSub={props.pageSub}
        data={data.customFields[props.columns[colIndex].apiKeyName]}
        type={props.columns[colIndex].fieldInputType}
        field={props.columns[colIndex]}
        loadingData={props.loadingData}
        combinedId={combinedId}
        pageForm={props.pageForm}
      />
    ) : (
      <CustomDisplay
        allData={data}
        sidePanel={true}
        page={props.page}
        pageSub={props.pageSub}
        data={data ? data[props.columns[colIndex].apiKeyName] : null}
        type={props.columns[colIndex].fieldInputType}
        field={props.columns[colIndex]}
        loadingData={props.loadingData}
        combinedId={combinedId}
        pageForm={props.pageForm}
      />
    );
  };

  return (
    <>
      {props.showColumns.map((colId, index) =>
        props.cellLoading === index.toString() + colId + "side" ? (
          <div key={index} className="side-panel-data-item">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div key={index} className="side-panel-data-item px-0 py-1" onMouseOver={() => setHovering(index.toString() + colId + "side", colId)} onMouseLeave={() => setNotHovering()}>
            <div className="d-flex align-items-center w-100-30px gap-5px">
              <span className="w-110px text-9197b3 word-break-initial">{props.columns[props.idToIndex[colId]].fieldName}: </span>
              <InlineStyled as="span" className="custom-ellipsis w-100-110px" styles={{ customWidth: "calc(100% - 110px)" }}>
                {getData(colId, tempViewing, index.toString() + colId + "side")}
              </InlineStyled>
            </div>
            <PopoverEdit
              edit={edit}
              combinedId={index.toString() + colId + "side"}
              cellId={tempViewing["_id"]}
              editing={editing}
              setEditing={setEditing}
              setEdit={setEdit}
              rowIndex={index}
              field={props.columns[props.idToIndex[colId]]}
              colId={colId + "side"}
              initialVal={
                props.columns[props.idToIndex[colId]].fieldGroup === "CUSTOM" && tempViewing.customFields
                  ? tempViewing.customFields[props.columns[props.idToIndex[colId]].apiKeyName]
                  : tempViewing[props.columns[props.idToIndex[colId]].apiKeyName]
              }
              cellEdit={props.cellEdit}
              setCellEdit={props.setCellEdit}
              addTag={props.addTag}
              editTag={props.editTag}
              deleteTag={props.deleteTag}
              handleCellEdit={props.handleCellEdit}
              page={props.page}
              pageSub={props.pageSub}
              allData={tempViewing}
            />
          </div>
        ),
      )}
    </>
  );
}
