// cadence
export const getCadenceList = {
  GET_CADENCE_LIST_REQUEST: "GET_CADENCE_LIST_REQUEST",
  GET_CADENCE_LIST_SUCCESS: "GET_CADENCE_LIST_SUCCESS",
  GET_CADENCE_LIST_ERROR: "GET_CADENCE_LIST_ERROR",
};
export const getCadenceById = {
  GET_CADENCE_BY_ID_REQUEST: "GET_CADENCE_BY_ID_REQUEST",
  GET_CADENCE_BY_ID_SUCCESS: "GET_CADENCE_BY_ID_SUCCESS",
  GET_CADENCE_BY_ID_ERROR: "GET_CADENCE_BY_ID_ERROR",
  GET_CADENCE_BY_ID_RESET: "GET_CADENCE_BY_ID_RESET",
};
export const deleteCadenceById = {
  DELETE_CADENCE_BY_ID_REQUEST: "DELETE_CADENCE_BY_ID_REQUEST",
  DELETE_CADENCE_BY_ID_SUCCESS: "DELETE_CADENCE_BY_ID_SUCCESS",
  DELETE_CADENCE_BY_ID_ERROR: "DELETE_CADENCE_BY_ID_ERROR",
};
export const createCadence = {
  CREATE_CADENCE_REQUEST: "CREATE_CADENCE_REQUEST",
  CREATE_CADENCE_SUCCESS: "CREATE_CADENCE_SUCCESS",
  CREATE_CADENCE_ERROR: "CREATE_CADENCE_ERROR",
};
export const editCadence = {
  EDIT_CADENCE_REQUEST: "EDIT_CADENCE_REQUEST",
  EDIT_CADENCE_SUCCESS: "EDIT_CADENCE_SUCCESS",
  EDIT_CADENCE_ERROR: "EDIT_CADENCE_ERROR",
};

export const updateCadenceStatus = {
  UPDATE_CADENCE_STATUS_REQUEST: "UPDATE_CADENCE_STATUS_REQUEST",
  UPDATE_CADENCE_STATUS_SUCCESS: "UPDATE_CADENCE_STATUS_SUCCESS",
  UPDATE_CADENCE_STATUS_ERROR: "UPDATE_CADENCE_STATUS_ERROR",
};

// step
export const updateCadenceStep = {
  UPDATE_CADENCE_STEP_REQUEST: "UPDATE_CADENCE_STEP_REQUEST",
  UPDATE_CADENCE_STEP_SUCCESS: "UPDATE_CADENCE_STEP_SUCCESS",
  UPDATE_CADENCE_STEP_ERROR: "UPDATE_CADENCE_STEP_ERROR",
};
export const AddStepInCadence = {
  ADD_STEP_IN_CADENCE_REQUEST: "ADD_STEP_IN_CADENCE_REQUEST",
  ADD_STEP_IN_CADENCE_SUCCESS: "ADD_STEP_IN_CADENCE_SUCCESS",
  ADD_STEP_IN_CADENCE_ERROR: "ADD_STEP_IN_CADENCE_ERROR",
};
export const deleteCadenceStep = {
  DELETE_CADENCE_STEP_REQUEST: "DELETE_CADENCE_STEP_REQUEST",
  DELETE_CADENCE_STEP_SUCCESS: "DELETE_CADENCE_STEP_SUCCESS",
  DELETE_CADENCE_STEP_ERROR: "DELETE_CADENCE_STEP_ERROR",
};

// new apis
export const swapCadenceSteps = {
  SWAP_CADENCE_STEPS_REQUEST: "SWAP_CADENCE_STEPS_REQUEST",
  SWAP_CADENCE_STEPS_SUCCESS: "SWAP_CADENCE_STEPS_SUCCESS",
  SWAP_CADENCE_STEPS_ERROR: "SWAP_CADENCE_STEPS_ERROR",
};

export const moveCadenceSteps = {
  MOVE_CADENCE_STEPS_REQUEST: "MOVE_CADENCE_STEPS_REQUEST",
  MOVE_CADENCE_STEPS_SUCCESS: "MOVE_CADENCE_STEPS_SUCCESS",
  MOVE_CADENCE_STEPS_ERROR: "MOVE_CADENCE_STEPS_ERROR",
};
