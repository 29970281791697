import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CustomSubHeader } from "../../../components/subheader";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SettingsSubHeader({ tabName }) {
  const [userType, setUserType] = useState("AGENT");
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  useEffect(() => {
    if (!_.isEmpty(userProfile.data)) setUserType(userProfile.data.userType);
    else setUserType("AGENT");
  }, [userProfile.data]);

  return (
    <CustomSubHeader>
      <div className="d-flex flex-row flex-stack w-100">
        <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
          <div className="menu-item me-lg-1">
            <NavLink className={`menu-link py-3 ${tabName === "profile-settings" ? "active" : ""}`} to="/settings/profile/profile-overview">
              <span className="menu-title">Profile Settings</span>
            </NavLink>
          </div>
          {userType !== "AGENT" && (
            <div className="menu-item me-lg-1">
              <NavLink className={`menu-link py-3 ${tabName === "admin-settings" ? "active" : ""}`} to="/settings/account">
                <span className="menu-title">Admin Settings</span>
              </NavLink>
            </div>
          )}
          {userType !== "AGENT" && (
            <div className="menu-item me-lg-1">
              <NavLink className={`d-flex gap-3 menu-link py-3 ${tabName === "billing" ? "active" : ""}`} to="/settings/billing/subscription">
                <span className="menu-title">Billing</span>
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.8 7.30128V8.4002C10.8 9.72653 9.72623 10.8002 8.39997 10.8002L3.59999 10.8003C2.27365 10.8003 1.19999 9.72656 1.19999 8.40029L1.19991 3.61294C1.19991 2.2866 2.27363 1.21295 3.5999 1.21295H4.68621C4.64832 1.01082 4.61043 0.821302 4.61043 0.606566C4.61043 0.391841 4.63572 0.202312 4.68621 0.000274658H3.59999C1.61679 0.000274658 0 1.61711 0 3.61289V8.40024C0 10.396 1.61684 12.0002 3.59999 12.0002H8.38734C10.3831 12.0002 11.9873 10.3834 11.9873 8.40024V7.31393C11.8105 7.35191 11.6084 7.3898 11.3936 7.3898C11.1789 7.3898 10.9894 7.35191 10.8 7.30133L10.8 7.30128Z"
                    fill="#5e6278"
                  />
                  <path
                    d="M11.3936 2.25654e-05H6.87155C6.54315 2.25654e-05 6.26526 0.26533 6.26526 0.606314C6.26526 0.947388 6.53057 1.2126 6.87155 1.2126H9.94101L3.80209 7.3389C3.56206 7.57892 3.56206 7.95787 3.80209 8.18521C3.91575 8.29887 4.06739 8.36205 4.23154 8.36205C4.38309 8.36205 4.53472 8.29887 4.66099 8.18521L10.7999 2.04629V5.11575C10.7999 5.44415 11.0652 5.72204 11.4062 5.72204C11.7473 5.72204 12.0125 5.45673 12.0125 5.11575V0.606291C11.9999 0.265307 11.7346 0 11.3936 0L11.3936 2.25654e-05Z"
                    fill="#5e6278"
                  />
                </svg>
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </CustomSubHeader>
  );
}
