import React, { useState, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function FunnelChart(props) {
  const [graphData] = useState(props.won);

  useLayoutEffect(() => {
    let chart = am4core.create(props.chartId, am4charts.SlicedChart);

    chart.data = graphData;

    // let series1 = chart.series.push(new am4charts.FunnelSeries());
    // series1.dataFields.value = "value";
    // series1.dataFields.category = "name";
    // series1.labels.template.disabled = true;

    let series = chart.series.push(new am4charts.FunnelSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "name";

    let fillModifier = new am4core.LinearGradientModifier();
    fillModifier.brightnesses = [-0.5, 1, -0.5];
    fillModifier.offsets = [0, 0.5, 1];
    series.slices.template.fillModifier = fillModifier;
    series.alignLabels = true;

    series.labels.template.text = `{category}: [bold]{value} ${props.title === "Sales Funnel - Value (V-Graph)" ? props.defaultCurrency : ""}[/]`;

    // let series2 = chart.series.push(new am4charts.PyramidSeries());
    // series2.dataFields.value = "value";
    // series2.dataFields.category = "name";
    // series2.labels.template.disabled = true;
  }, [graphData, props.chartId, props.defaultCurrency, props.title]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default FunnelChart;
