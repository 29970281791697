import React from "react";
import { Button } from "react-bootstrap";

export default function PlanCard(props) {
  const isActive = () => {
    if (props.serviceType) {
      if (props.planType === "free" && props.serviceType === "free") {
        return true;
      } else if (["startUpPlan", "startup"].includes(props.planType) && ["startUp", "startup"].includes(props.serviceType)) {
        return true;
      } else if (["businessPlan", "business"].includes(props.planType) && ["business"].includes(props.serviceType)) {
        return true;
      } else if (["businessPlusPlan", "businessPlus"].includes(props.planType) && ["businessPlus", "businessplus"].includes(props.serviceType)) {
        return true;
      }
      if (props.planType === props.serviceType) return true;
      return false;
    }
    return false;
  };
  return (
    <div className={`plan-card-container ${isActive() ? "active" : ""}`}>
      <div className="plan-name-wrapper">
        <div className="plan-name-container">
          <div className="plan-desc">{props.planDesc}</div>
          <div className="plan-name">{props.planName}</div>
        </div>
      </div>
      <div className="plan-pricing-wrapper">
        <div className="plan-price">{props.amount}</div>
        <div className="plan-interval">/user /month</div>
      </div>
      <div className="plan-button-wrapper">
        <Button className="button-class" onClick={props.onPlanSelect}>
          {isActive() ? "Selected" : "Select"}
        </Button>
      </div>
      <div className="plan-feature-wrapper">
        {props.features && props.features.map((each, index) => {
          if (index === 0)
            return (
              <div className="head-feature-line" key={index}>
                {each}
              </div>
            );
          return (
            <div className="subhead-feature-line" key={index}>
              <div className="subhead-icon">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <div className="subhead-text">{each}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
