import React from "react";
import "./styles.scss";
export default function BlankSidePanel({ show, handleClose, children, title, headButton }) {
  return (
    <div className="blank-side-pannel-container">
      {show && <div className="blank-side-pannel-blank-bg" onClick={handleClose}></div>}
      {show && (
        <>
          {" "}
          <div
            id="floatarrow"
            className={`px-1 p-1 gap-2 d-flex flex-column offcanvas offcanvas-right mt-2 csp-float-arrow-button shadow-none height-fit-content
           right-${show ? "51vw" : "minus-10vw"}`}
          >
            <div className="csp-button-wrapper">
              <span onClick={handleClose} className="btn btn-xs btn-icon cursor-pointer">
                <i className="fas fa-times float-color "></i>
              </span>
            </div>
          </div>
          <div id="sidepanel" className={`${show ? "right-0" : "right-minus-80vw"} offcanvas offcanvas-right pb-10 w-50vw h-100vh`}>
            <div className="card shadow-none rounded-0">
              <div className="card-header d-flex flex-stack align-items-center">
                <h3 className="card-title fw-bolder text-dark">{title}</h3>
                {headButton}
              </div>
              <div className="p-8 h-vh-70px overflow-auto">{children}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
