import {
  PEOPLES_REQUEST,
  PEOPLES_SUCCESS,
  PEOPLES_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  PEOPLEDROPDOWN_REQUEST,
  PEOPLEDROPDOWN_SUCCESS,
  PEOPLEDROPDOWN_ERROR,
  OWNERS_REQUEST,
  OWNERS_SUCCESS,
  OWNERS_ERROR,
  PEOPLEFIELDS_REQUEST,
  PEOPLEFIELDS_SUCCESS,
  PEOPLEFIELDS_ERROR,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  PEOPLEADDFIELDS_REQUEST,
  PEOPLEADDFIELDS_SUCCESS,
  PEOPLEADDFIELDS_ERROR,
  PEOPLETAGS_REQUEST,
  PEOPLETAGS_SUCCESS,
  PEOPLETAGS_ERROR,
  PEOPLETAGSADD_REQUEST,
  PEOPLETAGSADD_SUCCESS,
  PEOPLETAGSADD_ERROR,
  PEOPLETAGSEDIT_REQUEST,
  PEOPLETAGSEDIT_SUCCESS,
  PEOPLETAGSEDIT_ERROR,
  PEOPLETAGSDELETE_REQUEST,
  PEOPLETAGSDELETE_SUCCESS,
  PEOPLETAGSDELETE_ERROR,
  PEOPLECELLEDIT_REQUEST,
  PEOPLECELLEDIT_SUCCESS,
  PEOPLECELLEDIT_ERROR,
  PEOPLEADD_REQUEST,
  PEOPLEADD_SUCCESS,
  PEOPLEADD_ERROR,
  PEOPLEBULKEDIT_REQUEST,
  PEOPLEBULKEDIT_SUCCESS,
  PEOPLEBULKEDIT_ERROR,
  PEOPLEDELETE_REQUEST,
  PEOPLEDELETE_SUCCESS,
  PEOPLEDELETE_ERROR,
  PEOPLEFILTERS_REQUEST,
  PEOPLEFILTERS_SUCCESS,
  PEOPLEFILTERS_ERROR,
  PEOPLEFILTERADD_REQUEST,
  PEOPLEFILTERADD_SUCCESS,
  PEOPLEFILTERADD_ERROR,
  PEOPLEFILTEREDIT_REQUEST,
  PEOPLEFILTEREDIT_SUCCESS,
  PEOPLEFILTEREDIT_ERROR,
  PEOPLEFILTERFAVORITE_REQUEST,
  PEOPLEFILTERFAVORITE_SUCCESS,
  PEOPLEFILTERFAVORITE_ERROR,
  PEOPLEFILTERDELETE_REQUEST,
  PEOPLEFILTERDELETE_SUCCESS,
  PEOPLEFILTERDELETE_ERROR,
  PEOPLEDROPDOWNADD_REQUEST,
  PEOPLEDROPDOWNADD_SUCCESS,
  PEOPLEDROPDOWNADD_ERROR,
  PEOPLES_UPDATE_REQUEST,
  PEOPLES_UPDATE_SUCCESS,
  PEOPLES_UPDATE_ERROR,
  PEOPLE_POPUP_REQUEST,
  PEOPLE_POPUP_SUCCESS,
  PEOPLE_POPUP_ERROR,
  PEOPLE_POPUP_UPDATE_REQUEST,
  PEOPLE_POPUP_UPDATE_SUCCESS,
  PEOPLE_POPUP_UPDATE_ERROR,
  PEOPLE_POPUP_EDIT_REQUEST,
  PEOPLE_POPUP_EDIT_SUCCESS,
  PEOPLE_POPUP_EDIT_ERROR,
  LOADING_DATA_UPDATE,
  PEOPLEMERGE_REQUEST,
  PEOPLEMERGE_SUCCESS,
  PEOPLEMERGE_ERROR,
  APPLYFILTER,
  CLEARFILTER,
  PEOPLE_LEAD_DROPDOWN_REQUEST,
  PEOPLE_LEAD_DROPDOWN_SUCCESS,
  PEOPLE_LEAD_DROPDOWN_ERROR,
  generatePeopleAIWFData
} from "../types/types";

import { bulkDeletePeopleV2, bulkEditPeopleV2 } from "../types/people-operations.types";

const initialState = {
  peopleList: {
    loading: "",
    list: [],
    count: 0,
    cellLoading: false,
  },
  peoplePopup: {
    loading: "",
    data: {},
    cellLoading: false,
  },
  peopleDropdownList: {
    loading: "",
    list: [],
  },
  peopleLeadDropdownList: {
    loading: "",
    list: [],
  },
  ownerList: {
    loading: "",
    list: [],
  },
  peopleFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  peopleAddFields: {
    loading: "",
    list: [],
  },
  peopleTags: {
    loading: "",
    list: [],
  },
  peopleFilters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
  loadingData: {
    pageNo: 1,
    limit: 50,
    type: "people",
  },
  // you can define required variables here
};

export function peopleReducer(state = initialState, action = {}) {
  switch (action.type) {
    case PEOPLES_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: true },
      };
    case PEOPLES_SUCCESS:
      return {
        ...state,
        peopleList: { loading: false, list: action.payload.data, count: action.payload.count },
      };
    case PEOPLES_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    //Update loading data
    case LOADING_DATA_UPDATE:
      return {
        ...state,
        loadingData: action.payload,
      };

    // Getting People Popup Data
    case PEOPLE_POPUP_REQUEST:
      return {
        ...state,
        peoplePopup: { loading: true, data: {} },
      };
    case PEOPLE_POPUP_SUCCESS:
      return {
        ...state,
        peoplePopup: { loading: false, data: action.payload.data },
      };
    case PEOPLE_POPUP_ERROR:
      return {
        ...state,
        peoplePopup: { loading: false, data: {} },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit People Popup Cell
    case PEOPLE_POPUP_EDIT_REQUEST:
      return {
        ...state,
        peoplePopup: { ...state.peoplePopup, loading: false, cellLoading: action.payload.cellId },
      };
    case PEOPLE_POPUP_EDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLE_POPUP_EDIT_ERROR:
      return {
        ...state,
        peoplePopup: { ...state.peoplePopup, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Updating People Popup Data
    case PEOPLE_POPUP_UPDATE_REQUEST:
      return {
        ...state,
        peoplePopup: { ...state.peoplePopup, cellLoading: action.payload.cellId },
      };
    case PEOPLE_POPUP_UPDATE_SUCCESS:
      return {
        ...state,
        peoplePopup: { loading: false, data: action.payload.data, cellLoading: false },
      };
    case PEOPLE_POPUP_UPDATE_ERROR:
      return {
        ...state,
        peoplePopup: { ...state.peoplePopup, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case PEOPLES_UPDATE_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, cellLoading: action.payload.cellId },
      };
    case PEOPLES_UPDATE_SUCCESS:
      return {
        ...state,
        peopleList: { loading: false, list: action.payload.data, count: action.payload.count, cellLoading: false },
      };
    case PEOPLES_UPDATE_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, cellLoading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    // Clear error or success
    case CLEAR_ERROR:
      return {
        ...state,
        error: { err: false, errMsg: "" },
      };

    case CLEAR_SUCCESS:
      return {
        ...state,
        success: { success: false, successMsg: "" },
      };

    case PEOPLEDROPDOWN_REQUEST:
      return {
        ...state,
        peopleDropdownList: { ...state.peopleDropdownList, loading: true },
      };
    case PEOPLEDROPDOWN_SUCCESS:
      return {
        ...state,
        peopleDropdownList: { loading: false, list: action.payload.data },
      };
    case PEOPLEDROPDOWN_ERROR:
      return {
        ...state,
        peopleDropdownList: { ...state.peopleDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case PEOPLE_LEAD_DROPDOWN_REQUEST:
      return {
        ...state,
        peopleLeadDropdownList: { ...state.peopleDropdownList, loading: true },
      };
    case PEOPLE_LEAD_DROPDOWN_SUCCESS:
      return {
        ...state,
        peopleLeadDropdownList: { loading: false, list: action.payload.data },
      };
    case PEOPLE_LEAD_DROPDOWN_ERROR:
      return {
        ...state,
        peopleLeadDropdownList: { ...state.peopleDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add People in dropdown
    case PEOPLEDROPDOWNADD_REQUEST:
      return {
        ...state,
        peopleDropdownList: { ...state.peopleDropdownList, loading: true },
      };
    case PEOPLEDROPDOWNADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEDROPDOWNADD_ERROR:
      return {
        ...state,
        peopleDropdownList: { ...state.peopleDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case OWNERS_REQUEST:
      return {
        ...state,
        ownerList: { ...state.ownerList, loading: true },
      };
    case OWNERS_SUCCESS:
      return {
        ...state,
        ownerList: { loading: false, list: action.payload },
      };
    case OWNERS_ERROR:
      return {
        ...state,
        ownerList: { ...state.ownerList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Fields
    case PEOPLEFIELDS_REQUEST:
      return {
        ...state,
        peopleFields: { ...state.peopleFields, loading: action.payload.skipIfDataPresent ? false : true },
      };
    case PEOPLEFIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        peopleFields: { loading: false, list: action.payload, idToIndex: tempObj },
      };
    case PEOPLEFIELDS_ERROR:
      return {
        ...state,
        peopleFields: { ...state.peopleFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Update fields
    case FIELD_UPDATE_REQUEST:
      return {
        ...state,
      };
    case FIELD_UPDATE_SUCCESS:
      var tempUpdate = {};
      for (var j = 0; j < action.payload.length; j++) {
        tempUpdate[action.payload[j]["_id"]] = j;
      }
      return {
        ...state,
        productFields: { list: action.payload, idToIndex: tempUpdate },
      };
    case FIELD_UPDATE_ERROR:
      return {
        ...state,
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Fields
    case PEOPLEADDFIELDS_REQUEST:
      return {
        ...state,
        peopleAddFields: { ...state.peopleAddFields, loading: true },
      };
    case PEOPLEADDFIELDS_SUCCESS:
      return {
        ...state,
        peopleAddFields: { loading: false, list: action.payload },
      };
    case PEOPLEADDFIELDS_ERROR:
      return {
        ...state,
        peopleAddFields: { ...state.peopleAddFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add People
    case PEOPLEADD_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: true },
      };
    case PEOPLEADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEADD_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Bulk Edit People
    case PEOPLEBULKEDIT_REQUEST:
    case bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: true },
      };
    case PEOPLEBULKEDIT_SUCCESS:
    case bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEBULKEDIT_ERROR:
    case bulkEditPeopleV2.BULK_EDIT_PEOPLE_V2_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete People
    case PEOPLEDELETE_REQUEST:
    case bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: true },
      };
    case PEOPLEDELETE_SUCCESS:
    case bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEDELETE_ERROR:
    case bulkDeletePeopleV2.BULK_DELETE_PEOPLE_V2_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // GET Tags
    case PEOPLETAGS_REQUEST:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: true },
      };
    case PEOPLETAGS_SUCCESS:
      return {
        ...state,
        peopleTags: { loading: false, list: action.payload },
      };
    case PEOPLETAGS_ERROR:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Tag
    case PEOPLETAGSADD_REQUEST:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: true },
      };
    case PEOPLETAGSADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLETAGSADD_ERROR:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Tag
    case PEOPLETAGSEDIT_REQUEST:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: true },
      };
    case PEOPLETAGSEDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLETAGSEDIT_ERROR:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Tag
    case PEOPLETAGSDELETE_REQUEST:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: true },
      };
    case PEOPLETAGSDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLETAGSDELETE_ERROR:
      return {
        ...state,
        peopleTags: { ...state.peopleTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Cell
    case PEOPLECELLEDIT_REQUEST:
    case generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false, cellLoading: action.payload.cellId },
        peoplePopup: { ...state.peoplePopup, cellLoading: action.payload.cellId },
      };
    case PEOPLECELLEDIT_SUCCESS:
    case generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_SUCCESS:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false, cellLoading: false },
        peoplePopup: { ...state.peoplePopup, cellLoading: false },
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLECELLEDIT_ERROR:
    case generatePeopleAIWFData.GENERATE_PEOPLE_AIWF_DATA_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false, cellLoading: false },
        peoplePopup: { ...state.peoplePopup, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Getting Filters
    case PEOPLEFILTERS_REQUEST:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: true },
      };
    case PEOPLEFILTERS_SUCCESS:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: false, list: action.payload.data },
      };
    case PEOPLEFILTERS_ERROR:
      return {
        ...state,
        peopleFilters: { ...state.peopleList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Adding Filter
    case PEOPLEFILTERADD_REQUEST:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: true },
      };
    case PEOPLEFILTERADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEFILTERADD_ERROR:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Editing Filter
    case PEOPLEFILTEREDIT_REQUEST:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: true },
      };
    case PEOPLEFILTEREDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEFILTEREDIT_ERROR:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Favoriting Filter
    case PEOPLEFILTERFAVORITE_REQUEST:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: true },
      };
    case PEOPLEFILTERFAVORITE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEFILTERFAVORITE_ERROR:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Deleting Filter
    case PEOPLEFILTERDELETE_REQUEST:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: true },
      };
    case PEOPLEFILTERDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEFILTERDELETE_ERROR:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Merge
    case PEOPLEMERGE_REQUEST:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: true },
      };
    case PEOPLEMERGE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case PEOPLEMERGE_ERROR:
      return {
        ...state,
        peopleList: { ...state.peopleList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case APPLYFILTER:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, activeFilter: action.payload },
      };
    case CLEARFILTER:
      return {
        ...state,
        peopleFilters: { ...state.peopleFilters, activeFilter: null },
      };
    default:
      return state;
  }
}
