import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dealSearchRequest } from "../../containers/deals/dealPipeline/actions";
import { dispatchSnackbarError } from "../../utils/toaster";
import SearchSelect from "./searchSelect";

import { Dropdown, Spinner, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { InputLimit } from "../../constants";

export default function DealSelect({ field, value, setValue }) {
  const [newPeopleName, setNewPeopleName] = useState("");
  const Store = useSelector((state) => state.DealPipeline.dealDropdownList);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected({
      label: Store.list.find((company) => company["_id"] === value) ? Store.list.find((company) => company["_id"] === value).title : null,
      value: value,
    });
  }, [Store, value]);

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < Store.list.length; i++) temp.push({ value: Store.list[i]["_id"], label: Store.list[i]["title"] });
    setOptions(temp);
  }, [Store, newPeopleName]);

  const handleSave = () => {
    if (newPeopleName.trim() === "") {
      dispatchSnackbarError("Name can not be blank!");
      return;
    }
  };

  return <SearchSelect options={options} selected={selected} setValue={setValue} handleSave={handleSave} setSearch={setNewPeopleName} field={field} loading={Store.loading} request={dealSearchRequest} />;
}

export function DealSelectDropdown({ field, setValue, value }) {
  const Store = useSelector((state) => state.DealPipeline.dealDropdownList);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < Store.list.length; i++) temp.push({ _id: Store.list[i]["_id"], title: Store.list[i]["title"] });
    setOptions(temp);
  }, [Store]);

  const onSearch = (value) => {
    dispatch(dealSearchRequest(value));
    setSearch(value);
  };

  const [selected, setSelected] = useState(value);
  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected.title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu  w-100-mn">
        {false ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="navi navi-hover">
            {
              <li className="navi-item py-20px px-24px">
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Deal" value={search} onChange={(e) => onSearch(e.target.value)} />
              </li>
            }
            <Dropdown.Divider className="m-0" />
            <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {options && options.length === 0 ? (
                <div className="w-100 d-flex flex-center h-50px-mn">
                  <span>No Deal</span>
                </div>
              ) : (
                options.map((eachUser, index) => {
                  return (
                    <Dropdown.Item
                      className="dropdown-hover"
                      key={index}
                      onSelect={() => {
                        setSelected(eachUser);
                        setValue(eachUser, field.apiKeyName);
                      }}
                    >
                      {eachUser.title}
                    </Dropdown.Item>
                  );
                })
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
