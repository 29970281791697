const textOptions = ["is", "is not", "is empty", "is not empty", "contains", "does not contain", "start with", "end with"];
const toggleOptions = ["true", "false"];
const numericalOptions = ["is", "is not", "is empty", "is not empty", "is equal to", "greater than", "less than", "greater than or is equal to", "less than or is equal to"];
const dateOptions = [
  "is",
  "is not",
  "is before",
  "is after",
  "between",
  "not between",
  "today",
  "tomorrow",
  "yesterday",
  "last month",
  "this month",
  "next month",
  "last week",
  "this week",
  "next week",
  "is empty",
  "is not empty",
];
const dateRangeOptions = ["between"];
const customSelectOptions = ["is", "is not", "is empty", "is not empty"];

export const dataFieldToComparatorMapping = {
  INPUT: textOptions,
  TEXTAREA: textOptions,
  CHECKBOX: textOptions,
  SELECT: textOptions,
  RADIO: textOptions,
  MULTI_EMAIL_INPUT: textOptions,
  TIMEZONE_SELECT: textOptions,

  MULTI_PHONE_INPUT: numericalOptions,
  NUMBER: numericalOptions,

  TOGGLE_BUTTON: toggleOptions,

  DATE: dateOptions,
  DATE_RANGE: dateRangeOptions,
  DATE_TIME: dateOptions,
  DATE_TIME_RANGE: dateRangeOptions,

  CURRENCY_SELECT: customSelectOptions,
  COUNTRY_SELECT: customSelectOptions,
  OWNER_SELECT: customSelectOptions,
  ORG_SELECT: customSelectOptions,
  TAG_MULTI_SELECT: customSelectOptions,
  CATEGORY_SELECT: customSelectOptions,
  PERSON_SELECT: customSelectOptions,
  PIPELINE_STAGE_SELECT: customSelectOptions,
  PIPELINE_SELECT: customSelectOptions,
  MULTI_OWNER_SELECT: customSelectOptions,
  ACTIVITY_TYPE_SELECT: customSelectOptions,
  ACTIVITY_LOST_REASON_SELECT: customSelectOptions,
  DEAL_SELECT: customSelectOptions,
  LEAD_SELECT: customSelectOptions,
  PEOPLE_SELECT: customSelectOptions,
  COMPANY_SELECT: customSelectOptions,
  ASSOCIATION: customSelectOptions,
  DEAL_LOST_REASON_SELECT: customSelectOptions,
  LEAD_LOST_REASON_SELECT: customSelectOptions,

  PRICING_INPUT: customSelectOptions,
  ACTIVITY_SHOW: customSelectOptions,
  DEAL_PRODUCT_INPUT: customSelectOptions,
  MULTI_OPT_OUT: customSelectOptions,
  MULTI_OPT_OUT_REASON: textOptions,
};
