export const getListOfMarketingList = {
  GET_LIST_OF_MARKETING_LIST_REQUEST: "GET_LIST_OF_MARKETING_LIST_REQUEST",
  GET_LIST_OF_MARKETING_LIST_SUCCESS: "GET_LIST_OF_MARKETING_LIST_SUCCESS",
  GET_LIST_OF_MARKETING_LIST_ERROR: "GET_LIST_OF_MARKETING_LIST_ERROR",
  GET_LIST_OF_MARKETING_LIST_REQUEST_AFTER: "GET_LIST_OF_MARKETING_LIST_REQUEST_AFTER",
};

export const marketingUpdate = {
  MARKETIING_UPDATE_REQUEST: "MARKETIING_UPDATE_REQUEST",
  MARKETIING_UPDATE_SUCCESS: "MARKETIING_UPDATE_SUCCESS",
  MARKETIING_UPDATE_ERROR: "MARKETIING_UPDATE_ERROR",
};

export const editMarketingList = {
  EDIT_MARKETING_LIST_REQUEST: "EDIT_MARKETING_LIST_REQUEST",
  EDIT_MARKETING_LIST_SUCCESS: "EDIT_MARKETING_LIST_SUCCESS",
  EDIT_MARKETING_LIST_ERROR: "EDIT_MARKETING_LIST_ERROR",
};

export const addMarketingList = {
  ADD_MARKETING_LIST_REQUEST: "ADD_MARKETING_LIST_REQUEST",
  ADD_MARKETING_LIST_SUCCESS: "ADD_MARKETING_LIST_SUCCESS",
  ADD_MARKETING_LIST_ERROR: "ADD_MARKETING_LIST_ERROR",
};

export const deleteMarketingList = {
  DELETE_MARKETING_LIST_REQUEST: "DELETE_MARKETING_LIST_REQUEST",
  DELETE_MARKETING_LIST_SUCCESS: "DELETE_MARKETING_LIST_SUCCESS",
  DELETE_MARKETING_LIST_ERROR: "DELETE_MARKETING_LIST_ERROR",
};

export const bulkDeleteMarketingList = {
  BULK_DELETE_MARKETING_LIST_REQUEST: "BULK_DELETE_MARKETING_LIST_REQUEST",
  BULK_DELETE_MARKETING_LIST_SUCCESS: "BULK_DELETE_MARKETING_LIST_SUCCESS",
  BULK_DELETE_MARKETING_LIST_ERROR: "BULK_DELETE_MARKETING_LIST_ERROR",
};

export const getSelectedMarketingData = {
  GET_SELECTE_MARKETING_DATA_REQUEST: "GET_SELECTE_MARKETING_DATA_REQUEST",
  GET_SELECTE_MARKETING_DATA_SUCCESS: "GET_SELECTE_MARKETING_DATA_SUCCESS",
  GET_SELECTE_MARKETING_DATA_ERROR: "GET_SELECTE_MARKETING_DATA_ERROR",
};

// Tags
export const getListOfMarketingListTags = {
  GET_LIST_OF_MARKETING_LIST_TAGS_REQUEST: "GET_LIST_OF_MARKETING_LIST_TAGS_REQUEST",
  GET_LIST_OF_MARKETING_LIST_TAGS_SUCCESS: "GET_LIST_OF_MARKETING_LIST_TAGS_SUCCESS",
  GET_LIST_OF_MARKETING_LIST_TAGS_ERROR: "GET_LIST_OF_MARKETING_LIST_TAGS_ERROR",
};

export const createMarketingListTags = {
  CREATE_MARKETING_LIST_TAGS_REQUEST: "CREATE_MARKETING_LIST_TAGS_REQUEST",
  CREATE_MARKETING_LIST_TAGS_SUCCESS: "CREATE_MARKETING_LIST_TAGS_SUCCESS",
  CREATE_MARKETING_LIST_TAGS_ERROR: "CREATE_MARKETING_LIST_TAGS_ERROR",
};

export const updateMarketingListTags = {
  UPDATE_MARKETING_LIST_TAGS_REQUEST: "UPDATE_MARKETING_LIST_TAGS_REQUEST",
  UPDATE_MARKETING_LIST_TAGS_SUCCESS: "UPDATE_MARKETING_LIST_TAGS_SUCCESS",
  UPDATE_MARKETING_LIST_TAGS_ERROR: "UPDATE_MARKETING_LIST_TAGS_ERROR",
};

export const deleteMarketingListTags = {
  DELETE_MARKETING_LIST_TAGS_REQUEST: "DELETE_MARKETING_LIST_TAGS_REQUEST",
  DELETE_MARKETING_LIST_TAGS_SUCCESS: "DELETE_MARKETING_LIST_TAGS_SUCCESS",
  DELETE_MARKETING_LIST_TAGS_ERROR: "DELETE_MARKETING_LIST_TAGS_ERROR",
};

// Filters
export const getSavedMarketingFilters = {
  GET_SAVED_MARKETING_FILTERS_REQUEST: "GET_SAVED_MARKETING_FILTERS_REQUEST",
  GET_SAVED_MARKETING_FILTERS_SUCCESS: "GET_SAVED_MARKETING_FILTERS_SUCCESS",
  GET_SAVED_MARKETING_FILTERS_ERROR: "GET_SAVED_MARKETING_FILTERS_ERROR",
};

export const createSavedMarketingFilters = {
  CREATE_SAVED_MARKETING_FILTERS_REQUEST: "CREATE_SAVED_MARKETING_FILTERS_REQUEST",
  CREATE_SAVED_MARKETING_FILTERS_SUCCESS: "CREATE_SAVED_MARKETING_FILTERS_SUCCESS",
  CREATE_SAVED_MARKETING_FILTERS_ERROR: "CREATE_SAVED_MARKETING_FILTERS_ERROR",
};

export const editSavedMarketingFilters = {
  EDIT_SAVED_MARKETING_FILTERS_REQUEST: "EDIT_SAVED_MARKETING_FILTERS_REQUEST",
  EDIT_SAVED_MARKETING_FILTERS_SUCCESS: "EDIT_SAVED_MARKETING_FILTERS_SUCCESS",
  EDIT_SAVED_MARKETING_FILTERS_ERROR: "EDIT_SAVED_MARKETING_FILTERS_ERROR",
};

export const deleteSavedMarketingFilters = {
  DELETE_SAVED_MARKETING_FILTERS_REQUEST: "DELETE_SAVED_MARKETING_FILTERS_REQUEST",
  DELETE_SAVED_MARKETING_FILTERS_SUCCESS: "DELETE_SAVED_MARKETING_FILTERS_SUCCESS",
  DELETE_SAVED_MARKETING_FILTERS_ERROR: "DELETE_SAVED_MARKETING_FILTERS_ERROR",
};

export const markFavoriteMarketingFilters = {
  MARK_FAVORITE_MARKETING_FILTERS_REQUEST: "MARK_FAVORITE_MARKETING_FILTERS_REQUEST",
  MARK_FAVORITE_MARKETING_FILTERS_SUCCESS: "MARK_FAVORITE_MARKETING_FILTERS_SUCCESS",
  MARK_FAVORITE_MARKETING_FILTERS_ERROR: "MARK_FAVORITE_MARKETING_FILTERS_ERROR",
};

export const LOADING_DATA_UPDATE = "LOADING_DATA_UPDATE_REQUEST";

export const getMarketingLogs = {
  GET_MARKETING_LOGS_REQUEST: "GET_MARKETING_LOGS_REQUEST",
  GET_MARKETING_LOGS_LOAD_MORE_REQUEST: "GET_MARKETING_LOGS_LOAD_MORE_REQUEST",
  GET_MARKETING_LOGS_SUCCESS: "GET_MARKETING_LOGS_SUCCESS",
  GET_MARKETING_LOGS_LOAD_MORE_SUCCESS: "GET_MARKETING_LOGS_LOAD_MORE_SUCCESS",
  GET_MARKETING_LOGS_ERROR: "GET_MARKETING_LOGS_ERROR",
};


export const generateMarketingAIWFData = {
  GENERATE_MARKETING_AIWF_DATA_REQUEST: "GENERATE_MARKETING_AIWF_DATA_REQUEST",
  GENERATE_MARKETING_AIWF_DATA_SUCCESS: "GENERATE_MARKETING_AIWF_DATA_SUCCESS",
  GENERATE_MARKETING_AIWF_DATA_ERROR: "GENERATE_MARKETING_AIWF_DATA_ERROR"
}