import _ from "lodash";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customColorMapping } from "../../custom-tables/constants";
import "../style.css";

export default function MultiOptOutDisplay({ value, sidePanel }) {
  const getCustomTagClass = (_value) => {
    if (customColorMapping[_value]) return customColorMapping[_value];
    return "dark";
  };
  if (!Array.isArray(value)) {
    return <div className="data-flex-container"></div>;
  }

  return (
    <div className={sidePanel ? "data-sidepanel-flex-container" : "data-flex-container"}>
      {value.map((tag, index) => {
        return tag ? (
          <OverlayTrigger overlay={<Tooltip>{_.capitalize(tag)}</Tooltip>}>
            <div key={index} title={tag} className={`m-0 tag-ellipsis rounded-pill badge badge-light-${getCustomTagClass(tag)}`}>
              {_.capitalize(tag)}
              <br />
            </div>
          </OverlayTrigger>
        ) : (
          <div key={index}></div>
        );
      })}
    </div>
  );
}

export function MultiOptOutReasonDisplay({ value, sidePanel }) {
  const channels = ["email", "sms", "whatsapp", "rcs", "voice"];
  const getCustomTagClass = (_value) => {
    for (const channel of channels) {
      if (_value.toLowerCase().includes(channel) && customColorMapping[channel]) return customColorMapping[channel];
    }
    return "dark";
  };
  if (!Array.isArray(value)) {
    return <div className="data-flex-container"></div>;
  }

  return (
    <div className={sidePanel ? "data-sidepanel-flex-container" : "data-flex-container"}>
      {value.map((tag, index) => {
        return tag ? (
          <OverlayTrigger overlay={<Tooltip>{tag}</Tooltip>}>
            <div key={index} className={`m-0 tag-ellipsis rounded-pill badge badge-light-${getCustomTagClass(tag)}`}>
              {tag}
              <br />
            </div>
          </OverlayTrigger>
        ) : (
          <div key={index}></div>
        );
      })}
    </div>
  );
}
