import React, { useState } from "react";
import { Dropdown, Spinner, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import SignatureManager from ".";
import { InputLimit } from "../../constants";
import { DeleteModal } from "../modal";
import { signatureDeleteRequest } from "../../containers/leads/store/actions/actions";
import InlineStyled from "../inline-styled/inline-styled";

export function EmailSignatureDropdown(props) {
  const [show, setShow] = useState(false);
  const [showSignatureManager, setShowSignatureManager] = useState(false);
  const [showDeleteSignatures, setShowDeleteSignatures] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState({});
  const [signatureSearch, setSignatureSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const dispatch = useDispatch();

  const signatureStore = useSelector((state) => state.Leads.signatureList);

  const onEditClick = (signature) => {
    setShowSignatureManager(true);
    setIsEdit(true);
    setEditId(signature._id);
  };

  const handleDeleteSignature = () => {
    dispatch(signatureDeleteRequest(selectedSignature["_id"]));
    setSelectedSignature({});
    setShowDeleteSignatures(false);
  };
  const deleteSignatureModalProps = {
    show: showDeleteSignatures,
    title: "Delete Signature",
    handleClose: () => {
      setShowDeleteSignatures(false);
    },
    handleAccept: handleDeleteSignature,
  };

  return (
    <div>
      <Dropdown show={show} onToggle={() => setShow(!show)} className="dropdown-inline signature-wrapper hide-dropdonw-icon ">
        <Dropdown.Toggle className="btn btn-icon btn-sm btn-clean dz-clickable w-40px hide-dropdonw-icon" variant="transparent" onClick={() => setShow(!show)}>
          <OverlayTrigger overlay={<Tooltip>Signature</Tooltip>}>{<i class="bi bi-pen"></i>}</OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-lg w-100-mn">
          {signatureStore.loading ? (
            <div className="w-100 d-flex justify-content-center align-items-center h-30vh-mn">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="navi navi-hover">
              <li className="navi-item py-20px px-24px">
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Signatures" value={signatureSearch} onChange={(e) => setSignatureSearch(e.target.value)} />
              </li>
              <Dropdown.Divider className="m-0" />
              <PerfectScrollbar className="scroll h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                {signatureStore.list.length === 0 ? (
                  <div className="w-100 d-flex flex-center h-50px-mn">
                    <span>No Signatures</span>
                  </div>
                ) : (
                  signatureStore.list.map((signature, index) => {
                    return signature.name && signature.name.toLowerCase().includes(signatureSearch.toLowerCase()) ? (
                      <Dropdown.Item
                        className="dropdown-hover"
                        key={index}
                        onSelect={() => {
                          props.setCurrentSignature(signature);
                          setShow(false);
                        }}
                      >
                        <div className="w-100 d-flex flex-stack align-items-center">
                          <InlineStyled className="custom-ellipsis" styles={{ customEllipsis: "100px" }}>
                            {" "}
                            {signature.name}
                          </InlineStyled>
                          <div className="w-20 step-actions d-flex justify-content-end p-0 m-0 gap-3">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                onEditClick(signature);
                                setShow(false);
                              }}
                              className="btn btn-icon btn-light h-25px w-25px"
                            >
                              <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                <i className="fa fa-sm fa-pen font-size-13px"></i>
                              </OverlayTrigger>
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedSignature(signature);
                                setShowDeleteSignatures(true);
                              }}
                              className="btn btn-icon btn-light h-25px w-25px"
                            >
                              <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                <i className="fa fa-sm fa-trash font-size-13px"></i>
                              </OverlayTrigger>
                            </button>
                          </div>
                        </div>
                      </Dropdown.Item>
                    ) : null;
                  })
                )}
              </PerfectScrollbar>
              <Dropdown.Divider className="m-0" />
              <div
                onClick={() => {
                  setShowSignatureManager(true);
                  setShow(false);
                }}
                className="dropdown-item dropdown-hover py-4 cursor-pointer"
              >
                + Add Signature
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <DeleteModal modalProps={deleteSignatureModalProps}>Are you sure you want to delete the signature?</DeleteModal>
      <SignatureManager
        show={showSignatureManager}
        isEdit={isEdit}
        editId={editId}
        handleClose={() => {
          setShowSignatureManager(false);
          setEditId("");
          setIsEdit(false);
        }}
      />
    </div>
  );
}
