import React, { useState, useEffect } from "react";
import { Form, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { InputLimit } from "../../../constants";
import { uploadLeadbotImageRequest } from "../actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../utils/toaster";
import { CustomCheckBox } from "../../../components/custom-inputs/checkbox-selector";
import CustomInput from "../../../components/custom_form/inputs";
import InlineStyled from "../../../components/inline-styled/inline-styled";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
};

export default function CustomisationSettings(props) {
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [ownersList, setOwnersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const dispatch = useDispatch();

  const owners = useSelector((store) => store.leadBot.owner.list.users);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  useEffect(() => {
    if (owners) setOwnersList(owners.map((item) => ({ value: item.id, label: item.name })));
  }, [owners]);

  useEffect(() => {
    if (props.assignee && Array.isArray(props.assignee) && props.assignee.length > 0) {
      const selected = ownersList.filter((a) => props.assignee.includes(a.value));
      if (selected) {
        setSelectedOwner(selected);
      }
    }
  }, [props.assignee, ownersList]);

  useEffect(() => {
    const urlRegex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
    if (props.data.backgroundImage.match(urlRegex)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [props.data.backgroundImage]);

  const onSelectOwner = (option) => {
    props.setAssignee(option.map((each) => each.value));
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "background");
    formData.append("leadBotId", props.leadBotId);
    dispatch(
      uploadLeadbotImageRequest(formData, (data) => {
        if (data.status) {
          if (data.url) {
            props.setValue("backgroundImage", data.url);
          }
          dispatchSnackbarSuccess("Successfully uploaded");
        } else {
          dispatchSnackbarError(data.message);
        }
        setLoading(false);
      }),
    );
  };

  return (
    <>
      <Form.Group>
        <Form.Label aria-label="select-tag">
          Select Tag*
          <OverlayTrigger placement="right" overlay={<Tooltip>{"This tag is coming from the lead module. This tag will be assigned automatically when the submission comes in."}</Tooltip>}>
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <CustomInput
          fromActivityorDealList={false}
          page={"Leads"}
          pageSub={"lead"}
          addTag={props.addTag}
          editTag={props.editTag}
          deleteTag={props.deleteTag}
          field={{ apiKeyName: "tags", fieldInputType: "TAG_MULTI_SELECT", fieldName: "Tags" }}
          value={props.tags}
          setValue={(value, apiKeyName) => props.setTags(value)}
        />
      </Form.Group>
      <Form.Group id="leadbot-components-customisation-settings-builder-owner-selection">
        <Form.Label aria-label="select-owner">
          Select Owner*
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {
                  "Your submission will be assigned to the team member you select automatically. If you select more than one team member, it'll be assigned on a sequence basis- for example: first to Team Member A, then second to Team Member B."
                }
              </Tooltip>
            }
          >
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <Select
          isMulti
          name="colors"
          value={selectedOwner}
          styles={customStyles}
          options={ownersList}
          className="h-300px-mx z-index-3"
          classNamePrefix="select Owners"
          onChange={(e) => onSelectOwner(e)}
        />
      </Form.Group>
      <Form.Group id="leadbot-components-customisation-settings-builder-shout-message">
        <Form.Label aria-label="shout-message">
          Shout Message
          <OverlayTrigger placement="right" overlay={<Tooltip>{"It will appear only in the chat widget."}</Tooltip>}>
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type={"text"} value={props.data.introBubble} onChange={(e) => props.setValue("introBubble", e.target.value)}></Form.Control>
      </Form.Group>
      <Form.Group id="leadbot-components-customisation-settings-builder-background-image">
        <Form.Label aria-label="background-image" className="d-flex align-items-center">
          Background Image
          {loading && (
            <span className="ml-3 text-419eff">
              <Spinner animation="border" size="sm" variant="primary" /> Uploading Image...
            </span>
          )}
        </Form.Label>
        {!isValid ? (
          <Form.Group id="leadbot-components-customisation-settings-builder-image-upload-link" className="d-flex justify-content-between">
            <Form.Control
              placeholder="Enter image link here or click upload"
              type={"text"}
              className="w-100-80px"
              maxLength={InputLimit.URL}
              value={props.data.backgroundImage}
              onChange={(e) => props.setValue("backgroundImage", e.target.value)}
            ></Form.Control>
            <Form.File className="cursor-pointer w-75px" type="file" label="Upload" accept=".png,.jpg,.jpeg,.webp,.svg" onChange={handleFileUpload} custom />
          </Form.Group>
        ) : (
          <Form.Group id="leadbot-components-customisation-settings-builder-remove-background-image" className="leadbot-image-icon-wrapper image-input image-input-outline">
            <InlineStyled className="image-input-wrapper ldd-image-wrapper" styles={{ backgroundImage: `url(${props.data.backgroundImage})` }} />
            <label
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              onClick={(e) => props.setValue("backgroundImage", "")}
              title="Remove Background Image"
            >
              <i className="bi bi-x-circle fs-7"></i>
            </label>
          </Form.Group>
        )}
      </Form.Group>
      <Form.Group id="leadbot-components-customisation-settings-builder-color-settings" className="mb-0">
        <Form.Label aria-label="colour-settings">
          Colour Settings
          <OverlayTrigger placement="right" overlay={<Tooltip>{"You can change this color to match your brand."}</Tooltip>}>
            <i className="ml-2 bi bi-info-circle"></i>
          </OverlayTrigger>
        </Form.Label>
        <Form.Group id="leadbot-components-customisation-settings-builder-security-qa" className="d-flex flex-wrap justify-content-between mb-0">
          <Form.Group id="leadbot-components-customisation-settings-builder-background" className="mr-2 w-24">
            <Form.Label aria-label="background">Background</Form.Label>
            <Form.Control type="color" value={props.data.background} onChange={(e) => props.setValue("background", e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group id="leadbot-components-customisation-settings-builder-question" className="mr-2 w-24">
            <Form.Label aria-label="question">Question</Form.Label>
            <Form.Control type="color" value={props.data.question} onChange={(e) => props.setValue("question", e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group id="leadbot-components-customisation-settings-builder-answer" className="mr-2 w-24">
            <Form.Label aria-label="answer">Answer</Form.Label>
            <Form.Control type="color" value={props.data.answer} onChange={(e) => props.setValue("answer", e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group id="leadbot-components-customisation-settings-builder-button" className="mr-2 w-24">
            <Form.Label aria-label="button">Button</Form.Label>
            <Form.Control type="color" value={props.data.button} onChange={(e) => props.setValue("button", e.target.value)}></Form.Control>
          </Form.Group>
        </Form.Group>
      </Form.Group>
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.LEADBOT_BRANDING && (
        <Form.Group id="branding">
          <Form.Label>Branding Settings</Form.Label>
          <CustomCheckBox label="Hide Branding" isChecked={!props.data.isBrandingEnabled} onClick={() => props.setValue("isBrandingEnabled", !props.data.isBrandingEnabled)} info={""} />
        </Form.Group>
      )}
    </>
  );
}
