import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function LostReasonSelect({ field, setValue, value, type }) {
  const [selected, setSelected] = useState("");
  const [lostReasonOptions, setLostReasonOptions] = useState([]);

  const lostReasonState = useSelector((state) => state.ProfileSettings.getLostReason.data);
  const loading = useSelector((state) => state.ProfileSettings.loading);
  const activityLossReasonStore = useSelector((store) => store.activity.lossReasons.reasons.list);

  useEffect(() => {
    if (type === "ACTIVITY") {
      setLostReasonOptions(activityLossReasonStore);
    }
  }, [activityLossReasonStore, type]);

  useEffect(() => {
    if (lostReasonState && (type === "DEAL" || type === "LEAD")) {
      setLostReasonOptions(lostReasonState.filter((each) => each.type === type));
    }
  }, [lostReasonState, type]);

  useEffect(() => {
    if (value && lostReasonOptions.length > 0) {
      const _selected = lostReasonOptions.find((each) => each._id === value);
      if (_selected) {
        setSelected(_selected.reason);
      }
    }
  }, [value, lostReasonOptions]);

  const _setSelected = (lostReason) => {
    setSelected(lostReason.reason);
  };
  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {lostReasonOptions &&
              lostReasonOptions.map((eachReason, index) => {
                return (
                  <Dropdown.Item
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      _setSelected(eachReason);
                      setValue(eachReason["_id"], field.apiKeyName);
                    }}
                  >
                    {eachReason.reason}
                  </Dropdown.Item>
                );
              })}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
