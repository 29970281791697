/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { Nav } from "react-bootstrap";

export function SidebarTabs(props) {
  return (
    <div className="pt-20px bg-white position-sticky offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between pb-0 z-index-1000 top-0">
      <Nav onSelect={(tabName) => props.setSelectedTab(tabName)} as="ul" role="tablist" className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-4x nav-tabs-primary flex-grow-1 ">
        {props.options.map((item, index) => (
          <Nav.Item as="li" className="py-0 px-20px width-auto" key={index}>
            <Nav.Link eventKey={item} className={`nav-link side-panel-links justify-content-center m-0 ${props.selectedTab === item ? "active" : ""}`}>
              {item}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
}
