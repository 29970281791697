import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError } from "../../../../utils/toaster";
import * as marketingTypes from "../types/marketing_data_field_types";
import api from "../../../../network/apis/api";
import { endpoints } from "../endpoints";
import * as getMarketingActions from "../actions/marketing_data_field_actions";

function* getMarketingDataFields(action) {
  try {
    const res = yield call(api.get, endpoints.MARKETING_DATA_FIELDS, action.payload);
    if (res.status === 200) {
      yield put(getMarketingActions.getMarketingDataFieldsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingActions.getMarketingDataFieldsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingActions.getMarketingDataFieldsError(e));
  }
}

function* getMarketingAddForm(action) {
  try {
    const res = yield call(api.get, endpoints.MARKETING_ADD_FORM, action.payload);
    if (res.status === 200) {
      yield put(getMarketingActions.getMarketingAddFormSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(getMarketingActions.getMarketingAddFormError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(getMarketingActions.getMarketingAddFormError(e));
  }
}

function* setMarketingTablePriority(action) {
  try {
    const response = yield call(api.put, endpoints.MARKETING_TABLE_PRIORITY, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingActions.setMarketingTablePrioritySuccess({
          fieldType: "MARKETING",
        }),
      );
      yield put(getMarketingActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
    } else {
      yield put(
        getMarketingActions.setMarketingTablePriorityError({
          message: "Could Not Update Field",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingActions.setMarketingTablePriorityError({
        message: "Could Not Update Field",
      }),
    );
  }
}

function* setMarketingFieldShowHide(action) {
  try {
    const response = yield call(api.put, endpoints.MARKETING_FIELD_SHOW_HIDE, action.payload);
    if (response.status === 200) {
      yield put(
        getMarketingActions.setMarketingFieldShowHideSuccess({
          fieldType: "MARKETING",
        }),
      );
      yield put(getMarketingActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
    } else {
      yield put(
        getMarketingActions.setMarketingFieldShowHideError({
          message: "Could Not Update Field",
        }),
      );
    }
  } catch (e) {
    yield put(
      getMarketingActions.setMarketingFieldShowHideError({
        message: "Could Not Update Field",
      }),
    );
  }
}

export default function* MarketingDataFeildSaga() {
  yield takeLatest(marketingTypes.getMarketingDataFields.GET_MARKETING_DATA_FIELDS_REQUEST, getMarketingDataFields);
  yield takeLatest(marketingTypes.getMarketingAddForm.GET_MARKETING_ADD_FORM_REQUEST, getMarketingAddForm);
  yield takeLatest(marketingTypes.setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_REQUEST, setMarketingTablePriority);
  yield takeLatest(marketingTypes.setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_REQUEST, setMarketingFieldShowHide);
}
