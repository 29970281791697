import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../utils/toaster";
import { country_code_map } from "../../_metronic/_assets/js/countryCode";

export default function CustomPhone(props) {
  const [defaultCode, setDefaultCode] = useState("91");

  const profile = useSelector((state) => state.ProfileSettings.getProfileDetails.data);

  useEffect(() => {
    if (profile.country) {
      const defaultData = country_code_map[profile.country.toLowerCase()];
      if (defaultData) {
        setDefaultCode(defaultData.code);
      }
    }
  }, [profile]);

  const handleAddPhone = () => {
    if (props.value.length === 0) {
      dispatchSnackbarError("Fill all the previous values before adding!");
      return;
    }
    for (var i = 0; i < props.value.length; i++) {
      if (!props.value[i] || props.value[i].toString() === "") {
        dispatchSnackbarError("Fill all the previous values before adding!");
        return;
      }
    }
    props.setValue([...props.value, defaultCode], props.apiKeyName);
  };

  const handleDeletePhone = (unitIndex) => {
    props.setValue(
      props.value.filter((unit, index) => index !== unitIndex),
      props.apiKeyName,
    );
  };

  return (
    <>
      {props.value && props.value.length > 0
        ? props.value.map((phone, index) => {
            return (
              <div key={index} className="d-flex justify-content-between mb-2">
                <ReactPhoneInput
                  value={phone ? phone.toString() : defaultCode}
                  onChange={(e) => props.setValue([...props.value.slice(0, index), e, ...props.value.slice(index + 1)], props.apiKeyName)}
                  inputStyle={{
                    border: "1px solid rgb(228, 230, 239)",
                  }}
                  inputClass="w-100"
                  dropdownStyle={{ zIndex: 100 }}
                  enableSearch={true}
                />
                {props.value.length > 0 && props.value[0] ? (
                  <Button className="pr-0" onClick={() => handleDeletePhone(index)} size="sm" variant="transparent">
                    <i className="fa fa-minus-circle remove-condition-icon"></i>
                  </Button>
                ) : null}
              </div>
            );
          })
        : null}
      <Button onClick={handleAddPhone} className="p-0 text-decoration-none" variant="link">
        + Add Phone
      </Button>
    </>
  );
}
