import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import * as syncSettingActions from "../../../store/actions/profile-actions/sync-settings.actions";
import { UpgradePlanPage } from "../../../../../components/empty_page";
import SubscriptionPlanModal from "../../../Billing/containers/subscription-billing-v2/subscription-modal/index";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";

//images
import GoogleImage from "../../../../../images/google.svg";
import MicrosoftImage from "../../../../../images/microsoft-5.svg";
import GoogleSigninImage from "../../../../../images/btn_google_signin_light_normal_web.png";
// import GoogleEmailConnect from "../../../../images/gif/google-email-connect.gif";
import { BlueGradientButton } from "../../../../../components/button";
import NylasSyncModal from "../components/nylas-sync-modal";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { Button } from "react-bootstrap";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { DeleteModal } from "../../../../../components/modal";
import { useHistory } from "react-router-dom";

export default function SyncSettingsList(props) {
  const [nylasEmail, setNylasEmail] = useState("");
  const [showPlans, setShowPlans] = useState(false);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  const [openNylasSyncModal, setOpenNylasSyncModal] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [selectedSyncId, setSelectedSyncId] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);

  console.log("syncSettingsStore", syncSettingsStore);

  useEffect(() => {
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
  }, [dispatch]);

  const onNylasSyncClick = (isGoogle) => {
    setOpenNylasSyncModal(true);
    setIsGoogleSignIn(isGoogle);
  };
  const onNylasSyncModalClose = () => {
    setOpenNylasSyncModal(false);
    setIsGoogleSignIn(false);
  };
  const onNylasSyncModalSave = () => {
    setOpenNylasSyncModal(false);
    dispatch(
      syncSettingActions.getHostedAuthLinkRequest({ email: nylasEmail }, (data) => {
        if (typeof data === "string") {
          window.location.href = `${data}`;
        } else if (typeof data === "object") {
          dispatchSnackbarError(data.message);
        }
      }),
    );
  };

  const onDeleteModalOpenClick = (id) => {
    setShowDeleteAccount(true);
    setSelectedSyncId(id);
  };
  const onHandleClose = () => {
    setShowDeleteAccount(false);
    setSelectedSyncId(null);
  };

  const onDeleteAccept = () => {
    setShowDeleteAccount(false);
    dispatch(syncSettingActions.deleteSyncAccountDetailsRequest({ syncId: selectedSyncId }));
    setSelectedSyncId(null);
  };

  const deleteTemplateModalProps = {
    show: showDeleteAccount,
    title: "Delete Email Account",
    handleClose: () => onHandleClose(),
    handleAccept: () => onDeleteAccept(),
  };

  const handleOpenSettingClick = (syncId) => {
    history.push(`/settings/profile/sync-settings/${syncId}/edit`);
  };

  const handleMakrAsDefault = (syncId) => {
    dispatch(syncSettingActions.markAsDefaultSyncAccountRequest({ syncId: syncId }));
  };

  return (
    <div>
      {accountBillingStore.data.servicesOffered && !accountBillingStore.data.servicesOffered.EMAIL_SYNC ? (
        <>
          <UpgradePlanPage
            isEnterprise={accountBillingStore.data.selectedPlan === "enterprise"}
            userType={userProfile.data && userProfile.data.userType ? userProfile.data.userType : "AGENT"}
            onUpgradeClick={() => setShowPlans(true)}
            type={"Email sync"}
            planName={"business plan"}
          />
          {showPlans && <SubscriptionPlanModal page={"addOn"} showPlans={showPlans} setShowPlans={setShowPlans} />}
        </>
      ) : syncSettingsStore.loading ? (
        <>
          <ContentWithCardLoading />
        </>
      ) : (
        <div>
          {_.isEmpty(syncSettingsStore.data) ? (
            <CustomEmptyPage
              page="emailSync"
              customButton={
                <div>
                  <span onClick={() => onNylasSyncClick(true)} className="cursor-pointer pr-2">
                    <img src={GoogleSigninImage} alt={"google signin"}></img>
                  </span>
                  <BlueGradientButton size="md" onClick={() => onNylasSyncClick(false)} className="mr-4">
                    + Account
                  </BlueGradientButton>
                </div>
              }
            />
          ) : (
            <div className="card mb-5 mb-xl-10 h-vh-160px">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0 d-flex flex-stack w-100">
                  <h3 className="fw-bolder m-0">Email Calendar Sync</h3>
                  <BlueGradientButton size="sm" onClick={() => onNylasSyncClick(false)} className="mr-4">
                    + Account
                  </BlueGradientButton>
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9 overflow-y-auto h-vh-220px-mx">
                  {syncSettingsStore.data.map((each, index) => {
                    return (
                      <div className="add-new-account-div" key={index}>
                        <div className="add-new-account-wrapper">
                          <div className="d-flex align-items-center">
                            <img alt="Logo" src={each.nylasAccountData && each.nylasAccountData.provider === "gmail" ? GoogleImage : MicrosoftImage} className="h-30px me-4" />
                            <div className="add-new-account-text-div">
                              <div className="add-new-account-text text-hover-primary cursor-pointer" onClick={() => handleOpenSettingClick(each._id)}>
                                {each.syncedEmail}
                              </div>
                              <div>{_.capitalize(each.syncState)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <div>
                              {each.isDefault ? (
                                <div className="text-success mr-4">
                                  <i class="bi bi-check-circle text-success"></i> Default
                                </div>
                              ) : (
                                <Button size="sm" variant="link" className="mr-4" onClick={() => handleMakrAsDefault(each._id)}>
                                  Mark As Default
                                </Button>
                              )}
                            </div>
                            <Button size="sm" variant="link" className="mr-4" onClick={() => handleOpenSettingClick(each._id)}>
                              Open Settings
                            </Button>
                            <Button size="sm" variant="danger" className="btn btn-danger ml-2" onClick={() => onDeleteModalOpenClick(each._id)}>
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <NylasSyncModal nylasEmail={nylasEmail} setNylasEmail={setNylasEmail} show={openNylasSyncModal} handleClose={onNylasSyncModalClose} isGoogleSignIn={isGoogleSignIn} handleSave={onNylasSyncModalSave} />
      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete email account?</DeleteModal>
    </div>
  );
}
