import React from "react";
import _ from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customColorMapping } from "../../custom-tables/constants";

export default function SelectDisplay(props) {
  const getCustomTagClass = (_value) => {
    if (customColorMapping[_value]) return customColorMapping[_value];
    return "dark";
  };

  if (props.field && props.field.apiKeyName === "lastEngagedChannel" && props.data) {
    return (
      <div className={"data-flex-container"}>
        <OverlayTrigger overlay={<Tooltip>{_.capitalize(props.data)}</Tooltip>}>
          <div title={props.data} className={`m-0 tag-ellipsis rounded-pill badge badge-light-${getCustomTagClass(props.data)}`}>
            {_.capitalize(props.data)}
            <br />
          </div>
        </OverlayTrigger>
      </div>
    );
  }

  return <span className="data-container">{props.data}</span>;
}
