import {
  DEALLIST_FIELDS_REQUEST,
  DEALLIST_FIELDS_SUCCESS,
  DEALLIST_FIELDS_ERROR,
  GET_DEAL_LIST_REQUEST,
  GET_DEAL_LIST_SUCCESS,
  GET_DEAL_LIST_ERROR,
  DEALCELLEDIT_REQUEST,
  DEALCELLEDIT_SUCCESS,
  DEALCELLEDIT_ERROR,
  UPDATE_DEAL_LIST_LOADING_DATA,
  getDealAddFormFields,
  generateDealAIWFData
} from "./types";

const initialState = {
  dealFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  dealList: {
    loading: true,
    list: [],
    count: 0,
    cellLoading: false,
  },
  error: {
    err: false,
    errMsg: "",
  },
  loadingData: {},
  addForm: {
    list: [],
    loading: false,
    error: "",
  },
};

export function dealListReducer(state = initialState, action = {}) {
  switch (action.type) {
    // Edit Cell
    case DEALCELLEDIT_REQUEST:
    case generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_REQUEST:
      return {
        ...state,
        dealList: { ...state.dealList, loading: false, cellLoading: action.payload.cellId },
      };
    case DEALCELLEDIT_SUCCESS:
    case generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
        dealList: { ...state.dealList, loading: false, cellLoading: false },
      };
    case DEALCELLEDIT_ERROR:
    case generateDealAIWFData.GENERATE_DEAL_AIWF_DATA_ERROR:
      return {
        ...state,
        dealList: { ...state.dealList, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //get deal list
    case GET_DEAL_LIST_REQUEST:
      return {
        ...state,
        dealList: { ...state.dealList, loading: true },
      };
    case GET_DEAL_LIST_SUCCESS:
      return {
        ...state,
        dealList: { loading: false, list: action.payload.data, count: action.payload.count, cellLoading: false },
      };
    case GET_DEAL_LIST_ERROR:
      return {
        ...state,
        dealList: { ...state.dealList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };
    case DEALLIST_FIELDS_REQUEST:
      return {
        ...state,
        dealFields: { ...state.dealFields, loading: true },
      };
    case DEALLIST_FIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        dealFields: { loading: false, list: action.payload, idToIndex: tempObj },
      };
    case DEALLIST_FIELDS_ERROR:
      return {
        ...state,
        dealFields: { ...state.dealFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case UPDATE_DEAL_LIST_LOADING_DATA:
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          ...action.payload,
        },
      };
    case getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_REQUEST:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: true,
        },
      };
    case getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_SUCCESS:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: false,
          list: action.payload.data,
        },
      };
    case getDealAddFormFields.GET_DEAL_ADD_FORM_FIELDS_ERROR:
      return {
        ...state,
        addForm: {
          ...state.addForm,
          loading: false,
          error: action.payload.message,
        },
      };
    default:
      return state;
  }
}
