export const DEALLIST_FIELDS_REQUEST = "DEALLIST_FIELDS_REQUEST";
export const DEALLIST_FIELDS_SUCCESS = "DEALLIST_FIELDS_SUCCESS";
export const DEALLIST_FIELDS_ERROR = "DEALLIST_FIELDS_ERROR";

export const DEALLIST_SHOWORHIDE_REQUEST = "DEALLIST_SHOWORHIDE_REQUEST";
export const DEALLIST_SHOWORHIDE_SUCCESS = "DEALLIST_SHOWORHIDE_SUCCESS";
export const DEALLIST_SHOWORHIDE_ERROR = "DEALLIST_SHOWORHIDE_ERROR";

export const UPDATE_COLUMNFIELD_PRIORITY_REQUEST = "UPDATE_COLUMNFIELD_PRIORITY_REQUEST";
export const UPDATE_COLUMNFIELD_PRIORITY_SUCCESS = "UPDATE_COLUMNFIELD_PRIORITY_SUCCESS";
export const UPDATE_COLUMNFIELD_PRIORITY_ERROR = "UPDATE_COLUMNFIELD_PRIORITY_ERROR";

// export const COLUMN_FIELD_UPDATE_REQUEST = "COLUMN_FIELD_UPDATE_REQUEST";
// export const COLUMN_FIELD_UPDATE_SUCCESS = "COLUMN_FIELD_UPDATE_SUCCESS";
// export const COLUMN_FIELD_UPDATE_ERROR = "COLUMN_FIELD_UPDATE_ERROR";

export const GET_DEAL_LIST_REQUEST = "GET_DEAL_LIST_REQUEST";
export const GET_DEAL_LIST_REQUEST_AFTER = "GET_DEAL_LIST_REQUEST_AFTER";
export const GET_DEAL_LIST_SUCCESS = "GET_DEAL_LIST_SUCCESS";
export const GET_DEAL_LIST_ERROR = "GET_DEAL_LIST_ERROR";

export const DEALCELLEDIT_REQUEST = "DEALCELLEDIT_REQUEST";
export const DEALCELLEDIT_SUCCESS = "DEALCELLEDIT_SUCCESS";
export const DEALCELLEDIT_ERROR = "DEALCELLEDIT_ERROR";

export const UPDATE_DEAL_LIST_LOADING_DATA = "UPDATE_DEAL_LIST_LOADING_DATA";

export const BULK_DELETE_DEALS_REQUEST = "BULK_DELETE_DEALS_REQUEST";
export const BULK_DELETE_DEALS_SUCCESS = "BULK_DELETE_DEALS_SUCCESS";
export const BULK_DELETE_DEALS_ERROR = "BULK_DELETE_DEALS_ERROR";

//deal custom fields

export const getDealAddFormFields = {
  GET_DEAL_ADD_FORM_FIELDS_REQUEST: "GET_DEAL_ADD_FORM_FIELDS_REQUEST",
  GET_DEAL_ADD_FORM_FIELDS_SUCCESS: "GET_DEAL_ADD_FORM_FIELDS_SUCCESS",
  GET_DEAL_ADD_FORM_FIELDS_ERROR: "GET_DEAL_ADD_FORM_FIELDS_ERROR",
};

export const generateDealAIWFData = {
  GENERATE_DEAL_AIWF_DATA_REQUEST: "GENERATE_DEAL_AIWF_DATA_REQUEST",
  GENERATE_DEAL_AIWF_DATA_SUCCESS: "GENERATE_DEAL_AIWF_DATA_SUCCESS",
  GENERATE_DEAL_AIWF_DATA_ERROR: "GENERATE_DEAL_AIWF_DATA_ERROR"
}