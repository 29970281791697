import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ProductDisplay({ value }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      setProducts(value.map((each) => each.itemName).filter((each) => each));
    }
  }, [value]);

  return (
    <OverlayTrigger
      show={true}
      rootClose={true}
      className="product-overlay-tooltip w-500px"
      placement="bottom"
      overlay={
        <Tooltip>
          <> {products.join(", ")}</>
        </Tooltip>
      }
      trigger={["hover", "focus"]}
    >
      <span className="data-container">{products.join(", ")}</span>
    </OverlayTrigger>
  );
}
