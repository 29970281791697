export const getCadenceSettings = {
  GET_CADENCE_SETTINGS_REQUEST: "GET_CADENCE_SETTINGS_REQUEST",
  GET_CADENCE_SETTINGS_SUCCESS: "GET_CADENCE_SETTINGS_SUCCESS",
  GET_CADENCE_SETTINGS_ERROR: "GET_CADENCE_SETTINGS_ERROR",
};

export const editCadenceSettings = {
  EDIT_CADENCE_SETTINGS_REQUEST: "EDIT_CADENCE_SETTINGS_REQUEST",
  EDIT_CADENCE_SETTINGS_SUCCESS: "EDIT_CADENCE_SETTINGS_SUCCESS",
  EDIT_CADENCE_SETTINGS_ERROR: "EDIT_CADENCE_SETTINGS_ERROR",
};

export const getCadenceAllUsersLimit = {
  REQUEST: "GET_CADENCE_ALL_USERS_LIMIT_REQUEST",
  SUCCESS: "GET_CADENCE_ALL_USERS_LIMIT_SUCCESS",
  FAILURE: "GET_CADENCE_ALL_USERS_LIMIT_FAILURE",
};
