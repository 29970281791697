import React, { useState } from "react";
import "@nylas/components-mailbox";
import "@nylas/components-composer";
import { CustomSubHeader } from "../../components/subheader";
import { SubheaderNav } from "../../components/subheader/subheaderNav";
import { BlueGradientButton } from "../../components/button";
import ContentWrapper from "../Wrapper";
import "./styles.css";

export default function MailBox(props) {
  const links = [{ name: "Mailbox", to: "/email/mailbox" }];
  const tabName = "Mailbox";

  const [showComposer, setShowComposer] = useState(false);
  const cssUrl = `${window.location.origin}/css/nylas-composer.css`;

  const onNewMessage = () => {
    setShowComposer(true);
    const composer = document.getElementById("4ca26d1e-f3f1-4697-ac67-55300ea27805");

    // to show the composer
    composer.open();
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex flex-row flex-stack w-100">
            <div>
              <SubheaderNav links={links} active={tabName} />
            </div>
            <div className="d-flex flex-row aligm-items-center gap-12px">
              <BlueGradientButton onClick={() => onNewMessage()} variant="success" size="sm">
                + New Mail
              </BlueGradientButton>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      <div className={`card card-custom`}>
        <div className="card-body py-3 px-3">
          <nylas-mailbox id="8616c0d6-b35f-431a-9fba-33e2eb016b81"> </nylas-mailbox>
        </div>
      </div>
      {showComposer && (
        <div className="custom-composer">
          <div>
            <nylas-composer
              id="4ca26d1e-f3f1-4697-ac67-55300ea27805"
              access_token="NEMleNvIckIXmCgiTo1EFwUQxVY5hc"
              theme={cssUrl}
              // show_close_button={true}
              show_minimize_button={true}
              show_header={true}
              show_from={true}
              show_subject={true}
              show_cc_button={true}
              show_bcc_button={true}
              show_attachment_button={true}
              show_editor_toolbar={true}
            >
              {" "}
            </nylas-composer>
          </div>
        </div>
      )}
    </ContentWrapper>
  );
}
