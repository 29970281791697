import React from "react";
import _ from "lodash";
import { customColorMapping } from "../../custom-tables/constants";

const channelIconWrapper = {
  rcs: "bi bi-google",
  whatsapp: "bi bi-whatsapp",
  sms: "bi bi-chat-right-text",
  voice: "",
  email: "bi bi-envelope",
  "short-link": "bi bi-link-45deg",
};
export default function CampaignChannelDisplay(props) {
  const getCustomTagClass = (value) => {
    if (typeof value !== "string") return "dark";
    if (customColorMapping[value.toLowerCase()]) return customColorMapping[value.toLowerCase()];
    return "dark";
  };

  const getCapitalizeContent = (content) => {
    if (content && typeof content === "string") return _.capitalize(content);
    return content;
  };

  return (
    <span className="data-flex-container w-100">
      <div className={`d-flex flex-center gap-2 badge badge-light-${getCustomTagClass(props.data)}`}>
        <i className={`text-${getCustomTagClass(props.data)} ${channelIconWrapper[props.data.toLowerCase()]}`}></i>
        {getCapitalizeContent(props.data)}
      </div>
    </span>
  );
}
