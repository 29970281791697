import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function BasicSearchSelect(props) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (props.value) {
      const __value = props.options.find((each) => each.value === props.value);
      setValue(__value);
    }
  }, [props.value, props.options]);

  const customFilter = (option, searchText) => {
    if (option.label.toLowerCase().includes(searchText.toLowerCase()) && (!props.systemId || !props.systemId.includes(option.value))) return true;
    else return false;
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      marginTop: 2,
    }),
    control: () => ({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      minHeight: 38,
      outline: "0 !important",
      position: "relative",
      boxSizing: "border-box",
      border: "1px solid rgb(228, 230, 239)",
      borderRadius: "0.42rem",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: "pointer",
      ":hover": {
        color: "#F64E60",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      ":active": {
        backgroundColor: "#ecf8ff",
        color: "#009ef7",
      },
      ":hover": {
        backgroundColor: "#ecf8ff",
        color: "#009ef7",
      },
    }),
  };

  const handleSelect = (e) => {
    setValue(e);
    if (e) {
      props.handleSelect(e.value, props.index);
    } else {
      props.handleSelect("", props.index);
    }
  };

  return (
    <Select
      onChange={(e) => handleSelect(e)}
      options={props.options}
      isSearchable={true}
      value={value}
      filterOption={customFilter}
      styles={customStyles}
      components={{
        DropdownIndicator: () => null,
        DownChevron: () => null,
        IndicatorSeparator: () => null,
      }}
      maxMenuHeight={220}
      menuPlacement="auto"
      isClearable={true}
      menuPortalTarget={document.body}
    />
  );
}
