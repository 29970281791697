import React, { useState, useEffect, useRef } from "react";
import InlineStyled from "../inline-styled/inline-styled";

const AutoTextArea = (props) => {
  const textAreaRef = useRef(null);
  const [text, setText] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [parentHeight, setParentHeight] = useState("auto");

  useEffect(() => {
    if (textAreaRef && textAreaRef.current && textAreaRef.current.scrollHeight <= 400) {
      setParentHeight(`${textAreaRef.current.scrollHeight}px`);
      setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }
  }, [textAreaRef, text]);

  const onChangeHandler = (event) => {
    if (textAreaRef.current.scrollHeight >= 400) {
      setParentHeight(`${400}px`);
      setTextAreaHeight(`${400}px`);
    } else {
      setParentHeight(`${textAreaRef.current.scrollHeight}px`);
      setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }
    setText(event.target.value);
    props.onChange(event);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const showOverFlow = () => {
    const afterReplace = textAreaHeight.replace("px", "");
    if (isNaN(afterReplace)) {
      return false;
    } else if (parseInt(afterReplace) >= 400) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <InlineStyled
      styles={{
        minHeight: parentHeight,
      }}
    >
      <InlineStyled
        as="textarea"
        {...props}
        ref={textAreaRef}
        className={`form-control form-control-solid h-400px-mx overflow-${showOverFlow() ? "auto" : "hidden"} resize-none`}
        rows={1}
        styles={{
          height: textAreaHeight,
        }}
        onChange={onChangeHandler}
      />
    </InlineStyled>
  );
};

export default AutoTextArea;
