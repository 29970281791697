//packages
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//actions
import * as activityListActions from "../../../../Activities/store/actions/activity_types_actions";
import * as TemplateActions from "../../../../campaign/store/actions/campaign_templates_actions";
import * as CompanyActions from "../../../../companies/store/actions/actions";
import { companyFieldsRequest } from "../../../../companies/store/actions/actions";
import { peopleFieldsRequest } from "../../../../customers/store/actions/actions";
import * as insightActions from "../../../../insights/actions";
import * as LeadActions from "../../../../leads/store/actions/actions";
import { leadFieldsRequest } from "../../../../leads/store/actions/actions";
import * as ProductActions from "../../../../products/store/actions/actions";
import * as CadenceActions from "../../store/actions/cadence_actions";
import * as EmailSettingsActions from "../../../../campaign/store/actions/campaign_email_settings_actions";
import * as syncSettingActions from "../../../../Settings/store/actions/profile-actions/sync-settings.actions";
import * as WhatsappSettingsActions from "../../.../../../../campaign/store/actions/campaign_whatsapp_setting_actions"

//components
import { BlueGradientButton } from "../../../../../components/button";
import ContentWrapper from "../../../../Wrapper";

//utils and constants
import { scheduleRawData, valueLabelMapping } from "../../constants";

//styles
import { isEmpty, sortBy } from "lodash";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import CadenceSubHeader from "../../components/cadence-header";
import CadenceActionsStep2 from "./steps/cadence-action-steps.step2";
import CadenceBreakupRuleStep4 from "./steps/cadence-breakup-rules.step4";
import CadenceGeneralSettingsStep5 from "./steps/cadence-general-settings.step5";
import CadenceNameStep1 from "./steps/cadence-name.step1";
import CadenceScheduleStep3 from "./steps/cadence-schedule.step3";
import "./styles.scss";
import CadenceStepStatsStep0 from "./steps/cadence-step-stats.step0";

export default function CreateCadence() {
  const tabName = "Builder";
  const [cadenceId, setCadenceId] = useState("");
  const [cadenceSaveLoading, setCadenceSaveLoading] = useState(false);

  // steps data
  const [name, setName] = useState("");
  const [schedule, setSchedule] = useState(scheduleRawData);
  const [breakUpRule, setBreakUpRule] = useState([]);
  const [steps, setSteps] = useState([]);

  // step 5 data
  const [daySubscriptionLimit, setDaySubscriptionLimit] = useState(0);
  const [makeExclusiveLead, setMakeExclusiveLead] = useState(false);
  const [priorityOfState, setPriorityOfState] = useState({ value: "EQUALLYDIVIDED", label: "Equally Divided" });
  const [priority, setPriority] = useState({ value: "LOW", label: "Low" });
  const [isUnsubscribeLink, setIsUnsubscribeLink] = useState(false);
  const [endAllCompanyLeads, setEndAllCompanyLeads] = useState(false);
  const [shareUsers, setShareUsers] = useState([]);
  const [syncedEmails, setSyncedEmails] = useState([]);
  const [syncedEmailsOptions, setSyncedEmailsOptions] = useState([]);
  const [isBusinessPlusUser, setIsBusinessPlusUser] = useState(false);

  // whataspp
  const [whatsappPhoneOptions, setWhatsappPhoneOptions] = useState([]);

  const getSelectedCadenceStore = useSelector((store) => store.cadence.selected);
  const userRole = useSelector((store) => store.insight.userRole);
  const syncSettingsStore = useSelector((state) => state.settings.profile.sync);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  //whatsapp
  const whatsappAccountStore = useSelector(state => state.campaign.settings.whatsapp.data);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const getSharingInvertInfo = useCallback(
    (_sharedUsers) => {
      const a = [];
      const _obj = {};
      if (Array.isArray(userRole.list.allUsers)) {
        userRole.list.allUsers.forEach((each) => {
          _obj[each.id] = { id: each.id, label: each.name };
        });
      }
      _sharedUsers.forEach((each) => {
        if (_obj[each]) {
          a.push(_obj[each]);
        }
      });
      return a;
    },
    [userRole.list.allUsers],
  );

  useEffect(() => {
    dispatch(leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));

    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
    dispatch(activityListActions.getListOfActiveActivityTypesRequest());
    dispatch(EmailSettingsActions.getListOfEmailDomainSettingsRequest({}));
    dispatch(syncSettingActions.getListOfSyncAccountsRequest());
    dispatch(WhatsappSettingsActions.getListOfWhatsappAccountsRequest())

  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(accountBillingStore.data)) {
      const { selectedPlan } = accountBillingStore.data;
      if (selectedPlan && selectedPlan.toLowerCase().includes("plus")) {
        setIsBusinessPlusUser(true);
      } else {
        setIsBusinessPlusUser(false);
      }
    }
  }, [accountBillingStore.data]);

  useEffect(() => {
    if (cadenceId) dispatch(CadenceActions.getCadenceByIdRequest({ id: cadenceId }));
  }, [dispatch, cadenceId]);

  useEffect(() => {
    if (params.cadenceId) {
      setCadenceId(params.cadenceId);
    }
  }, [dispatch, params.cadenceId]);

  useEffect(() => {
    if (!isEmpty(getSelectedCadenceStore.data)) {
      const data = getSelectedCadenceStore.data;
      // steps data
      setName(data.name);
      setSchedule(data.schedule);
      setBreakUpRule(data.breakUpRule);

      setSteps(sortBy(data.steps, ["executionOrder"]));

      // step 5 data
      setDaySubscriptionLimit(data.daySubscriptionLimit);
      setMakeExclusiveLead(data.makeExclusiveLead);
      setPriorityOfState({ value: data.priorityOfState, label: valueLabelMapping[data.priorityOfState] });
      setPriority({ value: data.priority, label: valueLabelMapping[data.priority] });
      setIsUnsubscribeLink(data.unsubscribeLink);
      setEndAllCompanyLeads(data.endAllCompanyLeads);
      if (data.sharedWith) {
        const a = getSharingInvertInfo(data.sharedWith.filter((b) => data.owner !== b));
        setShareUsers(a);
      } else {
        setShareUsers([]); //shareUsers
      }
      if (data.syncedEmails) {
        setSyncedEmails(data.syncedEmails.map((each) => ({ value: each, label: each })));
      } else {
        setSyncedEmails([]); //syncedEmails
      }
    }
  }, [getSelectedCadenceStore.data, getSharingInvertInfo]);

  useEffect(() => {
    const data = syncSettingsStore.data.map((each) => ({ value: each.syncedEmail, label: each.syncedEmail }));
    setSyncedEmailsOptions(data);
  }, [syncSettingsStore.data]);

  useEffect(() => {
    if (whatsappAccountStore.list.length) {
      const options = whatsappAccountStore.list.map((item) => {
        return { value: item.phoneId, label: item.displayPhoneNumber, integrationId: item._id }
      })
      setWhatsappPhoneOptions(options)
    }
  }, [whatsappAccountStore.list]);

  const handleAddStep = (step) => {
    const _steps = [...steps];
    _steps.push(step);
    setSteps(_steps);
  };

  const handleCreateNewCadence = () => {
    if (isEmpty(name)) {
      return dispatchSnackbarError("Cadence name cannot be empty!");
    } else if (daySubscriptionLimit > 2000 || daySubscriptionLimit === 0) {
      return dispatchSnackbarError("Subscribe contacts/day limit ranges from 1 to 2000. To make changes, please scroll to the General Settings section.");
    } else if (isEmpty(schedule.from) || isEmpty(schedule.to) || isEmpty(schedule.timezone) || isEmpty(schedule.weekDays)) {
      return dispatchSnackbarError("Error in schedule");
    }

    let isBusinessEmail = false;
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      if (step.channelType === "EMAIL" && step.emailContent && step.emailContent.emailSendType === "EMAIL_SYNC") {
        isBusinessEmail = true;
      }
      if (step.channelType === "EMAIL" && step.isAbTestingEnabled && !_.isEmpty(step.abTesting)) {
        for (const subStep of step.abTesting.emailContent) {
          if (subStep.emailSendType === "EMAIL_SYNC") {
            isBusinessEmail = true;
          }
        }
      }
    }
    if (_.isEmpty(syncedEmails) && isBusinessEmail) {
      return dispatchSnackbarError("Please select synced email");
    }

    // need to work on steps for validation
    setCadenceSaveLoading(true);
    const payload = {
      name: name,
      steps: steps,
      daySubscriptionLimit: daySubscriptionLimit,
      schedule: schedule,
      makeExclusiveLead: makeExclusiveLead,
      endAllCompanyLeads: endAllCompanyLeads,
      unsubscribeLink: isUnsubscribeLink,
      priority: priority.value,
      priorityOfState: priorityOfState.value,
      breakUpRule: breakUpRule,
      sharedWith: shareUsers.map((each) => each.id),
      syncedEmails: syncedEmails.map((each) => each.value),
    };

    if (cadenceId) {
      delete payload["steps"];

      // delete payload["sharedWith"];
      dispatch(
        CadenceActions.editCadenceRequest({ from: "cadence-builder", id: cadenceId, updateData: payload }, (data) => {
          setCadenceSaveLoading(false);
        }),
      );
    } else {
      dispatch(
        CadenceActions.createCadenceRequest(payload, (data) => {
          setCadenceSaveLoading(false);
          if (data.status) {
            history.push(`/automation/cadence/edit/${data.id}`);
          }
        }),
      );
    }
  };

  return (
    <ContentWrapper
      subHeader={
        <CadenceSubHeader tabName={tabName} cadenceId={cadenceId}>
          <Button
            className="mr-2"
            variant="secondary"
            size="sm"
            onClick={() => {
              history.push("/automation/cadence/list");
            }}
          >
            Cancel
          </Button>
          <BlueGradientButton disabled={cadenceSaveLoading} variant="success" size="sm" onClick={handleCreateNewCadence}>
            {cadenceSaveLoading && <Spinner animation="border" variant="link" className="mr-10px w-16px h-16px"></Spinner>}
            Save
          </BlueGradientButton>
        </CadenceSubHeader>
      }
    >
      {getSelectedCadenceStore.isLoading ? (
        <ContentWithCardLoading />
      ) : (
        <div className="h-vh-161px overflow-auto">
          <CadenceStepStatsStep0 steps={steps} />
          <CadenceNameStep1 name={name} setName={setName} />
          <CadenceActionsStep2 isBusinessPlusUser={isBusinessPlusUser} cadenceId={cadenceId} steps={steps} setSteps={setSteps} addStep={handleAddStep} whatsappPhoneOptions={whatsappPhoneOptions} />
          <CadenceScheduleStep3 schedule={schedule} setSchedule={setSchedule} />
          <CadenceBreakupRuleStep4 breakUpRule={breakUpRule} setBreakUpRule={setBreakUpRule} />
          <CadenceGeneralSettingsStep5
            isBusinessPlusUser={isBusinessPlusUser}
            daySubscriptionLimit={daySubscriptionLimit}
            priorityOfState={priorityOfState}
            makeExclusiveLead={makeExclusiveLead}
            priority={priority}
            isUnsubscribeLink={isUnsubscribeLink}
            endAllCompanyLeads={endAllCompanyLeads}
            shareUsers={shareUsers}
            syncedEmails={syncedEmails}
            syncedEmailsOptions={syncedEmailsOptions}
            setSyncedEmails={setSyncedEmails}
            setShareUsers={setShareUsers}
            setEndAllCompanyLeads={setEndAllCompanyLeads}
            setIsUnsubscribeLink={setIsUnsubscribeLink}
            setPriority={setPriority}
            setPriorityOfState={setPriorityOfState}
            setDaySubscriptionLimit={setDaySubscriptionLimit}
            setMakeExclusiveLead={setMakeExclusiveLead}
          />
        </div>
      )}
    </ContentWrapper>
  );
}
