import { getCadenceSubscriberAnalytics } from "../types/cadence-lead.types";
const initState = {
  data: [],
  stats: {},
  isLoading: false,
  error: false,
};

export default function subscriberStatsAnalyticsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCadenceSubscriberAnalytics.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getCadenceSubscriberAnalytics.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        stats: action.payload.stats,
      };
    case getCadenceSubscriberAnalytics.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
