import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./styles.scss";
import WhatsappTemplateList from "../../campaign-template/whatsapp-template";
import { useDispatch, useSelector } from "react-redux";
import { SearchSimpleDropdown, SimpleGroupDropdown } from "../../../../../components/dropdowns/simple-dropdown";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import WhatsappAddTemplatePreview from "../../campaign-template/whatsapp-template/add-whatsapp-template/preview.component";
import { FormHelperText } from "@material-ui/core";
import { uploadWhatsappMediaRequest } from "../../../store/actions/campaign_whatsapp_messaging_actions";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { getListOfWhatsappTemplatesBySearchRequest } from "../../../store/actions/campaign_whatsapp_template_actions";


export default function WhatsappEditor(props) {
    const [search, setSearch] = useState("");
    const [mapFieldOptions, setMapFieldOptions] = useState([])
    const [templateOptions, setTemplateOptions] = useState([]);
    const [showTemplateSelector, setShowTemplateSelector] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    // component wise variables

    const dispatch = useDispatch();

    const whatsappAccountStore = useSelector(state => state.campaign.settings.whatsapp.data);
    const whatsappTemplateStore = useSelector(state => state.campaign.whatsappTemplate);

    const peopleColumns = useSelector((state) => state.People.peopleFields.list);
    const companyColumns = useSelector((state) => state.Companies.companyFields.list);
    const leadColumns = useSelector((state) => state.Leads.leadFields.list);
    const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields.list);

    useEffect(() => {
        const options = [];
        const options1 = [];
        const options2 = [];
        const options3 = [];
        const options4 = [];

        peopleColumns.forEach((column) => {
            options.push({ label: `Peple - ${column.fieldName}`, value: `people.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        companyColumns.forEach((column) => {
            options1.push({ label: `Company - ${column.fieldName}`, value: `company.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        leadColumns.forEach((column) => {
            options2.push({ label: `Lead - ${column.fieldName}`, value: `lead.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })
        marketingColumns.forEach((column) => {
            options3.push({ label: `Marketing - ${column.fieldName}`, value: `marketing.${column.fieldGroup === "SYSTEM" ? column.apiKeyName : `_CF_${column.apiKeyName}`}` })
        })

        if (!_.isEmpty(props.customVariables)) {
            props.customVariables.forEach((column) => {
                options4.push({ label: `File - ${column}`, value: `${column}` })
            })
        }

        if (options4.length > 0) {
            setMapFieldOptions({
                "Lead Fields": options2,
                "People Fields": options,
                "Company Fields": options1,
                "Marketing Fields": options3,
                "File Fields": options4
            });
        }
        else {
            setMapFieldOptions({
                "Lead Fields": options2,
                "People Fields": options,
                "Company Fields": options1,
                "Marketing Fields": options3
            });
        }


    }, [peopleColumns, companyColumns, leadColumns, marketingColumns, props.customVariables])

    useEffect(() => {
        if (props.config.integrationId) {
            const payload = { page: 1, limit: 100, integrationId: props.config.integrationId, searchText: search }
            dispatch(getListOfWhatsappTemplatesBySearchRequest(payload))
        }
    }, [dispatch, props.config.integrationId, search]);
    useEffect(() => {
        if (whatsappTemplateStore.searchList.length > 0) {
            const options = whatsappTemplateStore.searchList
                .filter(each => each.status === "APPROVED")
                .map((item) => {
                    return { value: item._id, label: item.name, item: item }
                })
            setTemplateOptions(options)
        }
        else {
            setTemplateOptions([])
        }
    }, [whatsappAccountStore, whatsappTemplateStore]);

    const cancelSelectTemplate = () => {
        setShowTemplateSelector(false);
    };
    const handleUploadFile = (e, type) => {
        if (_.isEmpty(e.target.files)) return;
        const file = e.target.files[0];
        const size = file.size;
        const defaultSize = 52428800;
        if (size > defaultSize) {
            dispatchSnackbarError("File size must be less than 50mb");
            return;
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", props.config.integrationId);
        setFileUploadLoading(true)
        dispatch(uploadWhatsappMediaRequest(formData, (data) => {
            if (data.status) {
                const updatedValues = { ...props.templateVariable };
                updatedValues[props.templateVariable.requiredType] = data.url;
                const components = props.selectedTemplateData.components.map((each) => {
                    if (each.type === "HEADER") {
                        return {
                            ...each,
                            example: {
                                header_handle: [data.url]
                            }
                        }
                    }
                    return each
                })
                props.setSelectedTemplateData({
                    ...props.selectedTemplateData,
                    components: components
                })
                props.setTemplateVariable(updatedValues);
            }
            else dispatchSnackbarError(data.message)

            setFileUploadLoading(false);
        }));
    }

    const handleTemplateSelectionChange = (data) => {
        props.setTemplateId(data.value);
        props.setIsTemplate(true);
        props.setTemplate(data.value)
        const data1 = data.item.components.map((each) => {
            if (each.type === "HEADER" && each.format !== "TEXT") {
                return {
                    ...each,
                    example: {
                        header_handle: []
                    }
                }
            }
            return each
        })
        props.setSelectedTemplateData({ ...data.item, components: data1 });
    }
    return (
        <>
            <div>
                {<div className={props.smallScreen ? "whatsapp-editor-wrapper2" : "whatsapp-editor-wrapper"}>
                    <div className="whatsapp-add-template-section1">
                        <Form.Group className={props.smallScreen ? "p-0" : "p-4"}>
                            <Form.Label className="d-flex flex-stack">
                                <div>Select Whatsapp Template </div>
                                <Button size="sm" variant="link" onClick={() => setShowTemplateSelector(true)}>Template Builder</Button>
                            </Form.Label>
                            <SearchSimpleDropdown
                                search={search}
                                setSearch={setSearch}
                                value={props.templateId}
                                label={"Select Template"}
                                options={templateOptions}
                                handleSelect={handleTemplateSelectionChange}
                                showSearch
                            />
                        </Form.Group>
                        <div className={props.smallScreen ? "p-0" : "p-4"}>
                            {props.templateVariable.header.map((variable, index) => {
                                return <Row>
                                    <Form.Group className="col-md-6">
                                        <Form.Label>{`Variable for Header {{${variable.id}}}`}</Form.Label>
                                        <Form.Control type="text" maxLength={256} value={variable.value} onChange={(e) => {
                                            const updatedValues = { ...props.templateVariable };
                                            updatedValues.header[index].value = e.target.value;
                                            props.setTemplateVariable(updatedValues);
                                        }} />
                                    </Form.Group>
                                    <Form.Group className="col-md-6">
                                        <Form.Label>{`System Maping Field {{${variable.id}}}`}</Form.Label>
                                        <SimpleGroupDropdown
                                            value={props.templateVariable.header[index].mapField}
                                            label={"Select System Map Field"}
                                            options={mapFieldOptions}
                                            handleSelect={(value) => {
                                                const updatedValues = { ...props.templateVariable };
                                                updatedValues.header[index].mapField = value.value;
                                                props.setTemplateVariable(updatedValues);
                                            }}
                                            showSearch
                                        />
                                    </Form.Group>
                                </Row>
                            })}
                            {props.templateVariable.body.map((variable, index) => {
                                return <Row>
                                    <Form.Group className="col-md-6">
                                        <Form.Label>{`Variable for Body {{${variable.id}}}`}</Form.Label>
                                        <Form.Control type="text" maxLength={256} value={variable.value} onChange={(e) => {
                                            const updatedValues = { ...props.templateVariable };
                                            updatedValues.body[index].value = e.target.value;
                                            props.setTemplateVariable(updatedValues);
                                        }} />
                                    </Form.Group>
                                    <Form.Group className="col-md-6">
                                        <Form.Label>{`System Maping Field {{${variable.id}}}`}</Form.Label>
                                        <SimpleGroupDropdown
                                            value={props.templateVariable.body[index].mapField}
                                            label={"Select System Map Field"}
                                            options={mapFieldOptions}
                                            handleSelect={(value) => {
                                                const updatedValues = { ...props.templateVariable };
                                                updatedValues.body[index].mapField = value.value;
                                                props.setTemplateVariable(updatedValues);
                                            }}
                                            showSearch
                                        />
                                    </Form.Group>
                                </Row>
                            })}

                            {props.templateVariable.requiredType === "image" && <Form.Group controlId="formFileSm3">
                                <Form.Label className="d-flex align-items-center gap-2">Upload New Image
                                    {fileUploadLoading && (
                                        <span className="ml-3 text-419eff">
                                            <Spinner animation="border" size="sm" variant="primary" /> Uploading Image...
                                        </span>
                                    )}
                                </Form.Label>
                                <Form.Control type="file" size="sm" accept="image/jpeg,image/jpg,image/png" onChange={(e) => handleUploadFile(e, "img")} />
                                <FormHelperText>Upload an image for WhatsApp Message. Supported formats: <b>JPEG and PNG</b>.</FormHelperText>
                                {props.templateVariable.image && <img src={props.templateVariable.image} alt="preview" className="mt-2 w-150px h-150px border bg-white" />}
                            </Form.Group>}
                            {props.templateVariable.requiredType === "video" && <Form.Group controlId="formFileSm2">
                                <Form.Label>Upload New Video
                                    {fileUploadLoading && (
                                        <span className="ml-3 text-419eff">
                                            <Spinner animation="border" size="sm" variant="primary" /> Uploading Video...
                                        </span>
                                    )}
                                </Form.Label>
                                <Form.Control type="file" size="sm" accept=".mp4" onChange={(e) => handleUploadFile(e, "video")} />
                                {props.templateVariable.video && <video class="message-head-media-preview-video w-350px" controls>
                                    <source src={props.templateVariable.video} type="video/mp4" />
                                </video>}
                            </Form.Group>}
                            {props.templateVariable.requiredType === "document" && <Form.Group controlId="formFileSm3">
                                <Form.Label>Upload New Document
                                    {fileUploadLoading && (
                                        <span className="ml-3 text-419eff">
                                            <Spinner animation="border" size="sm" variant="primary" /> Uploading File...
                                        </span>
                                    )}
                                </Form.Label>
                                <Form.Control type="file" size="sm" accept="application/pdf" onChange={(e) => handleUploadFile(e, "pdf")} />
                                <FormHelperText>Upload an document for WhatsApp Message. Supported format: <b>PDF</b></FormHelperText>
                                {props.templateVariable.document && <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="preview" className="mt-2 w-150px h-150px border bg-white" />}
                            </Form.Group>}
                        </div>
                    </div>
                    <div className="whatsapp-add-template-section2">
                        <WhatsappAddTemplatePreview components={props.selectedTemplateData.components || []} integrationId={props.config.integrationId} />
                    </div>
                </div>}
            </div>

            <div onClick={(e) => e.stopPropagation()}>
                <WhatsappTemplateList
                    filter={{ integrationId: props.config.integrationId }}
                    show={showTemplateSelector}
                    channel={"whatsapp"}
                    showChoose={true}
                    onCloseClick={(data) => console.log(data)}
                    handleClose={cancelSelectTemplate} />

            </div>
        </>
    );
}
