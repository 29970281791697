import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as cadenceActions from "../actions/cadence_lead_actions";
import * as cadenceLeadTypes from "../types/cadence-lead.types";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* getCadenceAllLead(action) {
  try {
    const res = yield call(api.get, endpoints.GET_ALL_CADENCE_LEAD, action.payload);
    if (res.status === 200) {
      yield put(cadenceActions.getCadenceAllLeadSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.getCadenceAllLeadError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.getCadenceAllLeadError(e));
  }
}

function* addBulkLeadToCadence(action) {
  try {
    const res = yield call(api.patch, endpoints.ADD_BULK_LEAD_TO_CADENCE, action.payload);
    if (res.status === 200) {
      yield put(cadenceActions.addBulkLeadToCadenceSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.addBulkLeadToCadenceError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.addBulkLeadToCadenceError(e));
  }
}

function* addLeadToCadence(action) {
  try {
    const res = yield call(api.patch, endpoints.ADD_LEAD_TO_CADENCE, action.payload);
    if (res.status === 200) {
      yield put(cadenceActions.addLeadToCadenceSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.addLeadToCadenceError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.addLeadToCadenceError(e));
  }
}

function* getListOfCadenceByLeadId(action) {
  try {
    const res = yield call(api.get, endpoints.GET_CADENCE_BY_LEAD_ID, action.payload);
    if (res.status === 200) {
      yield put(cadenceActions.getListOfCadenceByLeadIdSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.getListOfCadenceByLeadIdError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.getListOfCadenceByLeadIdError(e));
  }
}

function* getCadenceSubscriberCount(action) {
  try {
    const res = yield call(api.post, endpoints.GET_CADENCE_SUBSCRIBER_COUNT, action.payload);
    if (res.status === 200) {
      yield put(cadenceActions.getCadenceSubscriberCountSuccess(res.data));
    } else {
      // dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.getCadenceSubscriberCountError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.getCadenceSubscriberCountError(e));
  }
}

function* bulkSubscribeToCadenceByQuery(action) {
  try {
    const res = yield call(api.patch, endpoints.BULK_SUBSCRIBE_TO_CADENCE_BY_QUERY, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceActions.bulkSubscribeToCadenceByQuerySuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.bulkSubscribeToCadenceByQueryError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.bulkSubscribeToCadenceByQueryError(e));
  }
}

function* getCadenceSubscriberAnalytics(action) {
  try {
    const res = yield call(api.get, `${endpoints.CADENCE_SUBSCRIBER_ANAYTICS_STATS}/${action.payload.cadenceId}/${action.payload.subscriberId}`);
    if (res.status === 200) {
      yield put(cadenceActions.getCadenceSubscriberAnalyticsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceActions.getCadenceSubscriberAnalyticsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceActions.getCadenceSubscriberAnalyticsError(e));
  }
}

export default function* cadenceLeadSaga() {
  yield takeLatest(cadenceLeadTypes.getCadenceAllLead.GET_CADENCE_ALL_LEAD_REQUEST, getCadenceAllLead);
  yield takeLatest(cadenceLeadTypes.addBulkLeadToCadence.ADD_BULK_LEAD_TO_CADENCE_REQUEST, addBulkLeadToCadence);
  yield takeLatest(cadenceLeadTypes.addLeadToCadence.ADD_LEAD_TO_CADENCE_REQUEST, addLeadToCadence);
  yield takeLatest(cadenceLeadTypes.getListOfCadenceByLeadId.GET_LIST_OF_CADENCE_BY_LEAD_ID_REQUEST, getListOfCadenceByLeadId);
  yield takeLatest(cadenceLeadTypes.getCadenceSubscriberCount.REQUEST, getCadenceSubscriberCount);
  yield takeLatest(cadenceLeadTypes.bulkSubscribeToCadenceByQuery.REQUEST, bulkSubscribeToCadenceByQuery);
  yield takeLatest(cadenceLeadTypes.getCadenceSubscriberAnalytics.REQUEST, getCadenceSubscriberAnalytics);
}
