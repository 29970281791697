import React from "react";
// import { Draggable } from 'react-beautiful-dnd';
// import AddIcon from '@material-ui/icons/Add';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ItemList from "./itemList";
import {
  Header,
  StageTitle,
  Body,
  //AddNew,StageFooter,
} from "./styled";
// import AddDealModal from '../common/dealModal/index';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { CardSkeleton } from "../../../components/skeleton/card-skeleton";
import InlineStyled from "../../../components/inline-styled/inline-styled";

class StageList extends React.Component {
  renderItemsList() {
    if (this.props.loading) {
      return <CardSkeleton />;
    }
    return (
      <ItemList
        stageId={this.props.stage._id}
        stageName={this.props.stage.name}
        listId={this.props.stage._id}
        index={this.props.index}
        key={this.props.stage._id}
        items={this.props.items}
        openComposer={this.props.openComposer}
        openScheduleActivity={this.props.openScheduleActivity}
        pipelineswithstages={this.props.pipelineswithstages}
        selectedPipeline={this.props.selectedPipeline}
        selectedPipelineId={this.props.selectedPipelineId}
        onAddDealModalSave={this.props.onAddDealModalSave}
        activityLostReasons={this.props.activityLostReasons}
        callActivityMADoneApi={this.props.callActivityMADoneApi}
        loading={this.props.loading}
        ownerId={this.props.ownerId}
        pipelinedropdownOptions={this.props.pipelinedropdownOptions}
        lostReasonStatus={this.props.lostReasonStatus}
        history={this.props.history}
      />
    );
  }

  render() {
    const { index, stage } = this.props;
    return (
      <div className={this.props.stagesLength >= 5 ? "stage width-330" : "stage width-100"}>
        <InlineStyled
          as={Header}
          className="bg-white w-300px-mn mt-0 mr-6px mb-6px ml-0px h-50px"
          styles={{
            borderTop: `3px solid ${stage.color}`,
          }}
        >
          <InlineStyled
            as={StageTitle}
            className="fw-500 font-size-16px text-000000a8"
            styles={{
              fontFamily: "Poppins, Helvetica, sans-serif",
            }}
          >
            <div className="d-flex flex-stack w-100">
              <div className="d-flex align-items-center">
                <OverlayTrigger placement="bottom" overlay={<Tooltip>{stage.name}</Tooltip>}>
                  <div className="overflow-hidden text-overflow-ellipsis text-nowrap w-160px">{stage.name}</div>
                </OverlayTrigger>
                <InlineStyled
                  as="span"
                  className="text-black fw-600 font-size-13px"
                  styles={{
                    fontFamily: "Poppins,Helvetica,sans-serif",
                  }}
                >
                  <span className="badge badge-square badge-light">{this.props.items && this.props.items.length > 0 ? this.props.items[0].deals.length : 0}</span>
                </InlineStyled>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                {this.props.items && this.props.items.length > 0 && (
                  <div>
                    <span className="beta-container badge badge-square badge-light mr-3 pr-2">
                      <span className="mr-4px">{this.props && this.props.items && this.props.items[0].currency}</span>
                      {this.props.items && parseInt(this.props.items[0].amount)}
                    </span>
                  </div>
                )}
                {index === this.props.stagesLength - 1 ? "" : <i className="bi bi-chevron-double-right mr-10px mt-4px text-4a4d4f"></i>}
              </div>
            </div>
          </InlineStyled>
        </InlineStyled>
        <Body>{this.renderItemsList()}</Body>
      </div>
      //     )}

      // </Draggable>
    );
  }
}

export default StageList;
