import { takeLatest, put, call } from "redux-saga/effects";
import * as activityTypes from "../types/activity.types";
import api from "../../../../network/apis/api";
import * as ActivitySaveFiltersActions from "../actions/activity_saved_filters_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";

function* getSavedFilters(action) {
  try {
    const response = yield call(api.get, "/api/crm/saved-filters/get?type=activity", action.payload);
    if (response.status === 200) {
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersError(response.data));
    } else {
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersError(e));
  }
}

function* addSavedFilters(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/create", action.payload);
    if (response.status === 200) {
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersRequest());
      yield put(ActivitySaveFiltersActions.createActivitySavedFilterSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivitySaveFiltersActions.createActivitySavedFilterError(response.data));
    } else {
      yield put(ActivitySaveFiltersActions.createActivitySavedFilterError("error"));
    }
    if (action.cb) action.cb(response.data);
  } catch (e) {
    yield put(ActivitySaveFiltersActions.createActivitySavedFilterError(e));
  }
}

function* editSavedFilter(action) {
  try {
    const response = yield call(api.patch, "/api/crm/saved-filters/edit", action.payload);
    if (response.status === 200) {
      yield put(ActivitySaveFiltersActions.editActivitySavedFilterSuccess(response.data));
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivitySaveFiltersActions.editActivitySavedFilterError(response.data));
    } else {
      yield put(ActivitySaveFiltersActions.editActivitySavedFilterError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivitySaveFiltersActions.editActivitySavedFilterError(e));
  }
}

function* deleteSavedFilter(action) {
  try {
    const response = yield call(api.delete, `/api/crm/saved-filters/delete/${action.payload}`);
    if (response.status === 200) {
      yield put(ActivitySaveFiltersActions.deleteActivitySavedFiltersSuccess(response.data));
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivitySaveFiltersActions.deleteActivitySavedFiltersError(response.data));
    } else {
      yield put(ActivitySaveFiltersActions.deleteActivitySavedFiltersError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivitySaveFiltersActions.deleteActivitySavedFiltersError(e));
  }
}

function* favoriteSavedFilter(action) {
  try {
    const response = yield call(api.post, "/api/crm/saved-filters/mark-as-favorite", action.payload);
    if (response.status === 200) {
      yield put(ActivitySaveFiltersActions.markAsFavoriteActivitySaveFilterSuccess(response.data));
      yield put(ActivitySaveFiltersActions.getListOfActivitySavedFiltersRequest());
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(ActivitySaveFiltersActions.markAsFavoriteActivitySaveFilterError(response.data));
    } else {
      yield put(ActivitySaveFiltersActions.markAsFavoriteActivitySaveFilterError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(ActivitySaveFiltersActions.markAsFavoriteActivitySaveFilterError(e));
  }
}

export default function* ActivitySaveFiltersSaga() {
  yield takeLatest(activityTypes.getListOfActivitySavedFilters.GET_LIST_OF_ACTIVITY_SAVED_FILTERS_REQUEST, getSavedFilters);
  yield takeLatest(activityTypes.createActivitySavedFilter.CREATE_ACTIVITY_SAVED_FILTER_REQUEST, addSavedFilters);
  yield takeLatest(activityTypes.editActivitySavedFilter.EDIT_ACTIVITY_SAVED_FILTER_REQUEST, editSavedFilter);
  yield takeLatest(activityTypes.deleteActivitySavedFilters.DELETE_ACTIVITY_SAVED_FILTER_REQUEST, deleteSavedFilter);
  yield takeLatest(activityTypes.markAsFavoriteActivitySaveFilter.MARK_AS_FAVORITE_ACTIVITY_SAVED_FILTER_REQUEST, favoriteSavedFilter);
}
