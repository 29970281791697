import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import FormInput from "../../../../components/Form-Field/index";
import countryCode from "../../../../_metronic/_assets/js/countryCode";
import Language from "../../../../_metronic/_assets/js/language";
import { timezonesList } from "../../../../_metronic/_assets/js/timezon";
import Currency from "../../../../_metronic/_assets/js/currency";
import * as actions from "../../actions/settings.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import InlineStyled from "../../../../components/inline-styled/inline-styled";

function Setting() {
  // custom style for Country Select
  const customStyles = {
    control: () => ({
      // background: '#F3F6F9',
      background: "#F3F6F9",
      height: "45px",
      padding: "7px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    indicatorsContainer: () => ({
      position: "absolute",
      top: "3px",
      right: 0,
      display: "flex",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
    placeholder: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
    menuList: (styles) => ({
      maxHeight: "150px",
      overflowY: "auto",
    }),
  };
  //dispatch function
  const dispatch = useDispatch();
  //accessing store
  // const store = useSelector(state => state.ProfileSettings)//ProfileSettings is rootreducer name for this headerfiles container and
  //you can get all the values defined in reducer file

  // state for country Select
  const [options, setOptions] = useState([]);
  // const [selectOption, setSelectOption] = useState();
  const [phoneError, setPhoneError] = useState("");
  //  state for language select
  const [langOptions, setlangOptions] = useState([]);
  // const [selectLangOption, setSelectLangOption] = useState();

  // state for timezon
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  // const [selecttimezoneOption, setSelecttimezoneOption] = useState();
  const [ProfilePic, setProfilePic] = useState("");

  // state for currency
  const [currencyOptions, setcurrencyOptions] = useState([]);
  const [currentEmail, setcurrentEmail] = useState("");
  const [currentCompany, setcurrentCompany] = useState("");
  // const [currentCompany,setcurrentCompany] =useState('');
  // const [selectcurrencyOption, setSelectcurrencyOption] = useState();

  // state for updatte profile
  const [updateProfile, setupdateProfile] = useState({
    fullName: "",
    company: "",
    phone: "",
    website: "",
    country: "",
    language: "English",
    timeZone: "",
    currency: "",
  });

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  useEffect(() => {
    if (userProfile.data) {
      setProfilePic(userProfile.data.avatar);
    }
  }, [userProfile.data]);

  useEffect(() => {
    setTimezoneOptions(
      timezonesList.map((each) => {
        return {
          value: each.utc[0],
          label: each.text,
        };
      }),
    );
  }, []);

  useEffect(() => {
    /**
     * For Country option
     * ***/
    let arr = countryCode.country_code;
    arr.filter((obj) => {
      obj.label = obj.name;
      obj.value = obj.code;
      return null;
    });
    setOptions(arr);

    /**
     * For Language option
     * ***/

    let lang = Language.language;

    setlangOptions(lang);

    /***
     * for Currency option
     * **/

    let curr = Currency.currency;
    setcurrencyOptions(curr);
  }, [options, langOptions, currencyOptions]);

  useEffect(() => {
    // for get Profile Details
    dispatch(
      actions.getUserProfileRequest((data) => {
        setProfilePic(data.data.avatar);
        setcurrentEmail(data.data.email);
        setcurrentCompany(data.data.company);
        // setcurrentCompany(data.data.company);
        setupdateProfile((prevState) => ({
          ...prevState,
          fullName: data.data.fullName,
          company: data.data.company,
          phone: data.data.phone,
          website: data.data.website,
          country: data.data.country,
          language: data.data.language,
          timeZone: data.data.timeZone,
          currency: data.data.currency,
        }));
      }),
    );
  }, [dispatch]);

  // dropdown for Country
  function dropChanges(selectedOption) {
    try {
      if (selectedOption !== null) {
        setupdateProfile((prevState) => ({
          ...prevState,
          country: selectedOption.label,
        }));
      } else {
        setupdateProfile((prevState) => ({
          ...prevState,
          country: "",
        }));
      }
      // setSelectOption(selectedOption.label);
    } catch (e) {}
  }

  // dropdown for language
  const dropLanguageChanges = (selectedOption) => {
    try {
      if (selectedOption !== null) {
        setupdateProfile((prevState) => ({
          ...prevState,
          language: selectedOption.value,
        }));
      } else {
        setupdateProfile((prevState) => ({
          ...prevState,
          language: "",
        }));
      }
      // setSelectLangOption(selectedOption.label)
    } catch (e) {}
  };

  // dropdown for timezone
  const dropTimezoneChanges = (selectedOption) => {
    try {
      if (selectedOption !== null) {
        setupdateProfile((prevState) => ({
          ...prevState,
          timeZone: selectedOption.value,
        }));
      } else {
        setupdateProfile((prevState) => ({
          ...prevState,
          timeZone: "",
        }));
      }
      // setSelecttimezoneOption(selectedOption.label)
    } catch (e) {}
  };

  // dropdown for currency
  const dropCurrencyChanges = (selectedOption) => {
    try {
      setupdateProfile((prevState) => ({
        ...prevState,
        currency: selectedOption.value,
      }));
      // setSelectcurrencyOption(selectedOption.value)
    } catch (e) {}
  };

  const validatephone = (phone) => {
    const re = /^[0-9]{10}$/;
    return re.test(Number(phone));
  };
  const validatephonenumber = (e) => {
    let isValid = true;
    if (!validatephone(e.target.value)) {
      isValid = false;
    }
    if (!isValid) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    const { name, value } = e.target;
    setupdateProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // fetching patch overview value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setupdateProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // API for patch overview

  const PatchOverView = () => {
    dispatch(
      actions.updateProfileRequest(updateProfile, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Profile Successfully Updated", "success");
          dispatch(actions.getUserProfileRequest(() => {}));
        }
      }),
    );
  };

  const ProfilePicFunction = (e) => {
    let formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    dispatch(
      actions.patchAvatarPicRequest(formData, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Image Successfully Uploaded", "success");
          dispatch(
            actions.getUserProfileRequest((data) => {
              setProfilePic(data.data.avatar);
              setcurrentEmail(data.data.email);
              setcurrentCompany(data.data.company);
              // setcurrentCompany(data.data.company);
              setupdateProfile((prevState) => ({
                ...prevState,
                fullName: data.data.fullName,
                company: data.data.company,
                phone: data.data.phone,
                website: data.data.website,
                country: data.data.country,
                language: data.data.language,
                timeZone: data.data.timeZone,
                currency: data.data.currency,
              }));
            }),
          );
        }
      }),
    );
  };

  // Example
  // const handleTestButtonClick = () => {
  //   dispatch(actions.testingRequest({ type: 'checking apis' }))
  // }

  return (
    <>
      {/*begin::Basic info*/}
      <div className="card mb-5 mb-xl-10 h-vh-160px">
        {/*begin::Card header*/}
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          {/*begin::Card title*/}
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Profile Setting</h3>
            {/* <button onClick={() => handleTestButtonClick()}>testing</button> */}
          </div>
          {/*end::Card title*/}
        </div>
        {/*begin::Card header*/}
        {/*begin::Content*/}
        <div id="kt_account_profile_details" className="collapse show">
          {/*begin::Form*/}
          <div id="kt_account_profile_details_form" className="form">
            {/*begin::Card body*/}
            <div className="card-body border-top p-9 overflow-y-auto h-vh-300px-mx">
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8">
                  {/*begin::Image input*/}
                  <InlineStyled className="image-input image-input-outline" data-kt-image-input="true" styles={{ backgroundImage: "url(assets/media/avatars/blank.png)" }}>
                    {/*begin::Preview existing avatar*/}
                    <InlineStyled className="image-input-wrapper w-125px h-125px" styles={{ backgroundImage: `url(${ProfilePic})` }} />
                    {/*end::Preview existing avatar*/}
                    {/*begin::Label*/}
                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                      <i className="bi bi-pencil-fill fs-7" />
                      {/*begin::Inputs*/}
                      <input onChange={(e) => ProfilePicFunction(e)} type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                      <input type="hidden" name="avatar_remove" />
                      {/*end::Inputs*/}
                    </label>
                    {/*end::Label*/}
                    {/*begin::Cancel*/}
                    {/* <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                      <i className="bi bi-x fs-2" />
                    </span> */}
                    {/*end::Cancel*/}
                    {/*begin::Remove*/}
                    {/* <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                      <i className="bi bi-x fs-2" />
                    </span> */}
                    {/*end::Remove*/}
                  </InlineStyled>
                  {/*end::Image input*/}
                  {/*begin::Hint*/}
                  <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                  {/*end::Hint*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">Full Name</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  {/* <input type="text" name="company" className="form-control form-control-lg form-control-solid" placeholder="Company name" defaultValue="Keenthemes" /> */}
                  <FormInput placeholder={"Enter your full name"} type={"text"} name={"fullName"} value={updateProfile.fullName} onChange={(e) => handleChange(e)} />
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}

              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">Email Address</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  {/* <input type="text" name="company" className="form-control form-control-lg form-control-solid" placeholder="Company name" defaultValue="Keenthemes" /> */}
                  <FormInput disabled={true} placeholder={"Enter your email address"} type={"email"} name={"email"} value={currentEmail} onChange={handleChange} />
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Contact Phone</span>
                  <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active" />
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  {/* <input type="tel" name="phone" className="form-control form-control-lg form-control-solid" placeholder="Phone number" defaultValue="044 3276 454 935" /> */}
                  <FormInput placeholder={"Enter your phone number"} type={"tel"} name={"phone"} value={updateProfile.phone} onChange={(e) => validatephonenumber(e)} />
                  {phoneError && <div className="text-red">{"Please Enter valid Number!!!"}</div>}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}

              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">Company</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  {/* <input type="text" name="company" className="form-control form-control-lg form-control-solid" placeholder="Company name" defaultValue="Keenthemes" /> */}
                  <FormInput placeholder={"Enter your company name"} type={"text"} name={"company"} value={currentCompany} onChange={(e) => handleChange(e)} disabled={true} />
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}

              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">Company Site</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  {/* <input type="text" name="website" className="form-control form-control-lg form-control-solid" placeholder="Company website" defaultValue="keenthemes.com" /> */}
                  <FormInput placeholder={"Enter your company website"} type={"text"} name={"website"} value={updateProfile.website} onChange={(e) => handleChange(e)} />
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Country</span>
                  <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Country of origination" />
                </label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  <Select
                    styles={customStyles}
                    placeholder=""
                    value={options.filter((option) => option.label === updateProfile.country)}
                    onChange={dropChanges}
                    options={options}
                    isClearable
                    noOptionsMessage={() => null}
                    isValidNewOption={() => false}
                  />
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">Language</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div className="col-lg-8 fv-row">
                  {/*begin::Input*/}

                  <Select
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    value={langOptions.filter((option) => option.label === updateProfile.language)}
                    placeholder=""
                    onChange={dropLanguageChanges}
                    options={langOptions}
                    isClearable
                    noOptionsMessage={() => null}
                    isValidNewOption={() => false}
                  />

                  {/*end::Input*/}
                  {/*begin::Hint*/}
                  <div className="form-text">Please select a preferred language, including date, time, and number formatting.</div>
                  {/*end::Hint*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6">
                {/*begin::Label*/}
                <label className="col-lg-4 col-form-label required fw-bold fs-6">Time Zone</label>
                {/*end::Label*/}
                {/*begin::Col*/}
                <div
                  className="col-lg-8 fv-row"
                  // style={{height: '160px'}}
                >
                  <Select
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    value={timezoneOptions.filter((option) => option.value === updateProfile.timeZone)}
                    placeholder=""
                    onChange={dropTimezoneChanges}
                    options={timezoneOptions}
                    isClearable
                    noOptionsMessage={() => null}
                    isValidNewOption={() => false}
                  />

                  {/* <select name="timezone" aria-label="Select a Timezone" data-control="select2" data-placeholder="Select a timezone.." className="form-select form-select-solid form-select-lg">
                    <option value>Select a Timezone..</option>
                    <option data-bs-offset={-39600} value="International Date Line West">(GMT-11:00) International Date Line West</option>
                    <option data-bs-offset={-39600} value="Midway Island">(GMT-11:00) Midway Island</option>
                    <option data-bs-offset={-39600} value="Samoa">(GMT-11:00) Samoa</option>
                    <option data-bs-offset={-36000} value="Hawaii">(GMT-10:00) Hawaii</option>
                    <option data-bs-offset={-28800} value="Alaska">(GMT-08:00) Alaska</option>
                    <option data-bs-offset={-25200} value="Pacific Time (US & Canada)">(GMT-07:00) Pacific Time (US &amp; Canada)</option>
                    <option data-bs-offset={-25200} value="Tijuana">(GMT-07:00) Tijuana</option>
                    <option data-bs-offset={-25200} value="Arizona">(GMT-07:00) Arizona</option>
                    <option data-bs-offset={-21600} value="Mountain Time (US & Canada)">(GMT-06:00) Mountain Time (US &amp; Canada)</option>
                    <option data-bs-offset={-21600} value="Chihuahua">(GMT-06:00) Chihuahua</option>
                    <option data-bs-offset={-21600} value="Mazatlan">(GMT-06:00) Mazatlan</option>
                    <option data-bs-offset={-21600} value="Saskatchewan">(GMT-06:00) Saskatchewan</option>
                    <option data-bs-offset={-21600} value="Central America">(GMT-06:00) Central America</option>
                    <option data-bs-offset={-18000} value="Central Time (US & Canada)">(GMT-05:00) Central Time (US &amp; Canada)</option>
                    <option data-bs-offset={-18000} value="Guadalajara">(GMT-05:00) Guadalajara</option>
                    <option data-bs-offset={-18000} value="Mexico City">(GMT-05:00) Mexico City</option>
                    <option data-bs-offset={-18000} value="Monterrey">(GMT-05:00) Monterrey</option>
                    <option data-bs-offset={-18000} value="Bogota">(GMT-05:00) Bogota</option>
                    <option data-bs-offset={-18000} value="Lima">(GMT-05:00) Lima</option>
                    <option data-bs-offset={-18000} value="Quito">(GMT-05:00) Quito</option>
                    <option data-bs-offset={-14400} value="Eastern Time (US & Canada)">(GMT-04:00) Eastern Time (US &amp; Canada)</option>
                    <option data-bs-offset={-14400} value="Indiana (East)">(GMT-04:00) Indiana (East)</option>
                    <option data-bs-offset={-14400} value="Caracas">(GMT-04:00) Caracas</option>
                    <option data-bs-offset={-14400} value="La Paz">(GMT-04:00) La Paz</option>
                    <option data-bs-offset={-14400} value="Georgetown">(GMT-04:00) Georgetown</option>
                    <option data-bs-offset={-10800} value="Atlantic Time (Canada)">(GMT-03:00) Atlantic Time (Canada)</option>
                    <option data-bs-offset={-10800} value="Santiago">(GMT-03:00) Santiago</option>
                    <option data-bs-offset={-10800} value="Brasilia">(GMT-03:00) Brasilia</option>
                    <option data-bs-offset={-10800} value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
                    <option data-bs-offset={-9000} value="Newfoundland">(GMT-02:30) Newfoundland</option>
                    <option data-bs-offset={-7200} value="Greenland">(GMT-02:00) Greenland</option>
                    <option data-bs-offset={-7200} value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                    <option data-bs-offset={-3600} value="Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
                    <option data-bs-offset={0} value="Azores">(GMT) Azores</option>
                    <option data-bs-offset={0} value="Monrovia">(GMT) Monrovia</option>
                    <option data-bs-offset={0} value="UTC">(GMT) UTC</option>
                    <option data-bs-offset={3600} value="Dublin">(GMT+01:00) Dublin</option>
                    <option data-bs-offset={3600} value="Edinburgh">(GMT+01:00) Edinburgh</option>
                    <option data-bs-offset={3600} value="Lisbon">(GMT+01:00) Lisbon</option>
                    <option data-bs-offset={3600} value="London">(GMT+01:00) London</option>
                    <option data-bs-offset={3600} value="Casablanca">(GMT+01:00) Casablanca</option>
                    <option data-bs-offset={3600} value="West Central Africa">(GMT+01:00) West Central Africa</option>
                    <option data-bs-offset={7200} value="Belgrade">(GMT+02:00) Belgrade</option>
                    <option data-bs-offset={7200} value="Bratislava">(GMT+02:00) Bratislava</option>
                    <option data-bs-offset={7200} value="Budapest">(GMT+02:00) Budapest</option>
                    <option data-bs-offset={7200} value="Ljubljana">(GMT+02:00) Ljubljana</option>
                    <option data-bs-offset={7200} value="Prague">(GMT+02:00) Prague</option>
                    <option data-bs-offset={7200} value="Sarajevo">(GMT+02:00) Sarajevo</option>
                    <option data-bs-offset={7200} value="Skopje">(GMT+02:00) Skopje</option>
                    <option data-bs-offset={7200} value="Warsaw">(GMT+02:00) Warsaw</option>
                    <option data-bs-offset={7200} value="Zagreb">(GMT+02:00) Zagreb</option>
                    <option data-bs-offset={7200} value="Brussels">(GMT+02:00) Brussels</option>
                    <option data-bs-offset={7200} value="Copenhagen">(GMT+02:00) Copenhagen</option>
                    <option data-bs-offset={7200} value="Madrid">(GMT+02:00) Madrid</option>
                    <option data-bs-offset={7200} value="Paris">(GMT+02:00) Paris</option>
                    <option data-bs-offset={7200} value="Amsterdam">(GMT+02:00) Amsterdam</option>
                    <option data-bs-offset={7200} value="Berlin">(GMT+02:00) Berlin</option>
                    <option data-bs-offset={7200} value="Bern">(GMT+02:00) Bern</option>
                    <option data-bs-offset={7200} value="Rome">(GMT+02:00) Rome</option>
                    <option data-bs-offset={7200} value="Stockholm">(GMT+02:00) Stockholm</option>
                    <option data-bs-offset={7200} value="Vienna">(GMT+02:00) Vienna</option>
                    <option data-bs-offset={7200} value="Cairo">(GMT+02:00) Cairo</option>
                    <option data-bs-offset={7200} value="Harare">(GMT+02:00) Harare</option>
                    <option data-bs-offset={7200} value="Pretoria">(GMT+02:00) Pretoria</option>
                    <option data-bs-offset={10800} value="Bucharest">(GMT+03:00) Bucharest</option>
                    <option data-bs-offset={10800} value="Helsinki">(GMT+03:00) Helsinki</option>
                    <option data-bs-offset={10800} value="Kiev">(GMT+03:00) Kiev</option>
                    <option data-bs-offset={10800} value="Kyiv">(GMT+03:00) Kyiv</option>
                    <option data-bs-offset={10800} value="Riga">(GMT+03:00) Riga</option>
                    <option data-bs-offset={10800} value="Sofia">(GMT+03:00) Sofia</option>
                    <option data-bs-offset={10800} value="Tallinn">(GMT+03:00) Tallinn</option>
                    <option data-bs-offset={10800} value="Vilnius">(GMT+03:00) Vilnius</option>
                    <option data-bs-offset={10800} value="Athens">(GMT+03:00) Athens</option>
                    <option data-bs-offset={10800} value="Istanbul">(GMT+03:00) Istanbul</option>
                    <option data-bs-offset={10800} value="Minsk">(GMT+03:00) Minsk</option>
                    <option data-bs-offset={10800} value="Jerusalem">(GMT+03:00) Jerusalem</option>
                    <option data-bs-offset={10800} value="Moscow">(GMT+03:00) Moscow</option>
                    <option data-bs-offset={10800} value="St. Petersburg">(GMT+03:00) St. Petersburg</option>
                    <option data-bs-offset={10800} value="Volgograd">(GMT+03:00) Volgograd</option>
                    <option data-bs-offset={10800} value="Kuwait">(GMT+03:00) Kuwait</option>
                    <option data-bs-offset={10800} value="Riyadh">(GMT+03:00) Riyadh</option>
                    <option data-bs-offset={10800} value="Nairobi">(GMT+03:00) Nairobi</option>
                    <option data-bs-offset={10800} value="Baghdad">(GMT+03:00) Baghdad</option>
                    <option data-bs-offset={14400} value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                    <option data-bs-offset={14400} value="Muscat">(GMT+04:00) Muscat</option>
                    <option data-bs-offset={14400} value="Baku">(GMT+04:00) Baku</option>
                    <option data-bs-offset={14400} value="Tbilisi">(GMT+04:00) Tbilisi</option>
                    <option data-bs-offset={14400} value="Yerevan">(GMT+04:00) Yerevan</option>
                    <option data-bs-offset={16200} value="Tehran">(GMT+04:30) Tehran</option>
                    <option data-bs-offset={16200} value="Kabul">(GMT+04:30) Kabul</option>
                    <option data-bs-offset={18000} value="Ekaterinburg">(GMT+05:00) Ekaterinburg</option>
                    <option data-bs-offset={18000} value="Islamabad">(GMT+05:00) Islamabad</option>
                    <option data-bs-offset={18000} value="Karachi">(GMT+05:00) Karachi</option>
                    <option data-bs-offset={18000} value="Tashkent">(GMT+05:00) Tashkent</option>
                    <option data-bs-offset={19800} value="Chennai">(GMT+05:30) Chennai</option>
                    <option data-bs-offset={19800} value="Kolkata">(GMT+05:30) Kolkata</option>
                    <option data-bs-offset={19800} value="Mumbai">(GMT+05:30) Mumbai</option>
                    <option data-bs-offset={19800} value="New Delhi">(GMT+05:30) New Delhi</option>
                    <option data-bs-offset={19800} value="Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura</option>
                    <option data-bs-offset={20700} value="Kathmandu">(GMT+05:45) Kathmandu</option>
                    <option data-bs-offset={21600} value="Astana">(GMT+06:00) Astana</option>
                    <option data-bs-offset={21600} value="Dhaka">(GMT+06:00) Dhaka</option>
                    <option data-bs-offset={21600} value="Almaty">(GMT+06:00) Almaty</option>
                    <option data-bs-offset={21600} value="Urumqi">(GMT+06:00) Urumqi</option>
                    <option data-bs-offset={23400} value="Rangoon">(GMT+06:30) Rangoon</option>
                    <option data-bs-offset={25200} value="Novosibirsk">(GMT+07:00) Novosibirsk</option>
                    <option data-bs-offset={25200} value="Bangkok">(GMT+07:00) Bangkok</option>
                    <option data-bs-offset={25200} value="Hanoi">(GMT+07:00) Hanoi</option>
                    <option data-bs-offset={25200} value="Jakarta">(GMT+07:00) Jakarta</option>
                    <option data-bs-offset={25200} value="Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                    <option data-bs-offset={28800} value="Beijing">(GMT+08:00) Beijing</option>
                    <option data-bs-offset={28800} value="Chongqing">(GMT+08:00) Chongqing</option>
                    <option data-bs-offset={28800} value="Hong Kong">(GMT+08:00) Hong Kong</option>
                    <option data-bs-offset={28800} value="Kuala Lumpur">(GMT+08:00) Kuala Lumpur</option>
                    <option data-bs-offset={28800} value="Singapore">(GMT+08:00) Singapore</option>
                    <option data-bs-offset={28800} value="Taipei">(GMT+08:00) Taipei</option>
                    <option data-bs-offset={28800} value="Perth">(GMT+08:00) Perth</option>
                    <option data-bs-offset={28800} value="Irkutsk">(GMT+08:00) Irkutsk</option>
                    <option data-bs-offset={28800} value="Ulaan Bataar">(GMT+08:00) Ulaan Bataar</option>
                    <option data-bs-offset={32400} value="Seoul">(GMT+09:00) Seoul</option>
                    <option data-bs-offset={32400} value="Osaka">(GMT+09:00) Osaka</option>
                    <option data-bs-offset={32400} value="Sapporo">(GMT+09:00) Sapporo</option>
                    <option data-bs-offset={32400} value="Tokyo">(GMT+09:00) Tokyo</option>
                    <option data-bs-offset={32400} value="Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option data-bs-offset={34200} value="Darwin">(GMT+09:30) Darwin</option>
                    <option data-bs-offset={34200} value="Adelaide">(GMT+09:30) Adelaide</option>
                    <option data-bs-offset={36000} value="Canberra">(GMT+10:00) Canberra</option>
                    <option data-bs-offset={36000} value="Melbourne">(GMT+10:00) Melbourne</option>
                    <option data-bs-offset={36000} value="Sydney">(GMT+10:00) Sydney</option>
                    <option data-bs-offset={36000} value="Brisbane">(GMT+10:00) Brisbane</option>
                    <option data-bs-offset={36000} value="Hobart">(GMT+10:00) Hobart</option>
                    <option data-bs-offset={36000} value="Vladivostok">(GMT+10:00) Vladivostok</option>
                    <option data-bs-offset={36000} value="Guam">(GMT+10:00) Guam</option>
                    <option data-bs-offset={36000} value="Port Moresby">(GMT+10:00) Port Moresby</option>
                    <option data-bs-offset={36000} value="Solomon Is.">(GMT+10:00) Solomon Is.</option>
                    <option data-bs-offset={39600} value="Magadan">(GMT+11:00) Magadan</option>
                    <option data-bs-offset={39600} value="New Caledonia">(GMT+11:00) New Caledonia</option>
                    <option data-bs-offset={43200} value="Fiji">(GMT+12:00) Fiji</option>
                    <option data-bs-offset={43200} value="Kamchatka">(GMT+12:00) Kamchatka</option>
                    <option data-bs-offset={43200} value="Marshall Is.">(GMT+12:00) Marshall Is.</option>
                    <option data-bs-offset={43200} value="Auckland">(GMT+12:00) Auckland</option>
                    <option data-bs-offset={43200} value="Wellington">(GMT+12:00) Wellington</option>
                    <option data-bs-offset={46800} value="Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
                  </select>
                */}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row mb-6 d-none">
                <label className="col-lg-4 col-form-label fw-bold fs-6">Currency</label>

                <div className="col-lg-8 fv-row">
                  <Select styles={customStyles} placeholder="" onChange={dropCurrencyChanges} options={currencyOptions} isClearable noOptionsMessage={() => null} isValidNewOption={() => false} />
                </div>
              </div>
              {/*end::Input group*/}
            </div>
            {/*end::Card body*/}
            {/*begin::Actions*/}
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              {/* <button type="reset" className="btn btn-white btn-active-light-primary me-2">Discard</button> */}
              <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={() => PatchOverView()}>
                Save Changes
              </button>
            </div>
            {/*end::Actions*/}
          </div>
          {/*end::Form*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Basic info*/}

      {/* <h1>dddddd</h1>
       <FormInput type={"text"} onChange={handleChange} />
        </> */}
    </>
  );
}

export default connect(null, actions)(Setting);
