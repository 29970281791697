import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { InputLimit } from "../../../../../constants";

//forms
import EmailConfigForm from "../Forms/email-config.form";
import SMSConfigForm from "../Forms/sms-config.form";
import AudienceStep2 from "./audience.step2";
import WhatsappConfigForm from "../Forms/whatsapp-config.form";

export default function EmailSetupStep1(props) {
  const renderForm = () => {
    switch (props.channel) {
      case "email":
        return <EmailConfigForm config={props.config} setConfig={props.setConfig} />;
      case "sms":
        return (
          <SMSConfigForm
            config={props.config}
            setConfig={props.setConfig}
            senderIdOptions={props.senderIdOptions}
            setIsTemplate={props.setIsTemplate}
            setTemplateId={props.setTemplateId}
            setTemplate={props.setTemplate}
          />
        );
      case "whatsapp":
        return <WhatsappConfigForm config={props.config} setConfig={props.setConfig} whatsappPhoneOptions={props.whatsappPhoneOptions} />
      case "rcs":
      case "voice":
      default:
        return <></>;
    }
  };

  return (
    <div className="setup-step1-wrapper">
      <Row>
        <Col>
          <Form.Group id="campaign-builder-steps-email-setup-step1-campaign-name">
            <Form.Label aria-label="campaign-name">Campaign Name</Form.Label>
            <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter Campaign Name" value={props.title} onChange={(e) => props.setTitle(e.target.value)} />
          </Form.Group>
        </Col>
        <Col>
          <AudienceStep2
            channel={props.channel}
            audience={props.audience}
            config={props.config}
            setAudience={props.setAudience}
            setAudienceCount={props.setAudienceCount}
            copyPasteData={props.copyPasteData}
            setCopyPasteData={props.setCopyPasteData}
            setUploadedFileVariables={props.setUploadedFileVariables}
          />
        </Col>
      </Row>
      {renderForm()}
    </div>
  );
}
