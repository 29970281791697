export const InputLimit = {
  EMAIL: 256,
  PASSWORD: 48,
  TEXT: 256,
  TEXTAREA: 1000,
  NUMBER: 160,
  URL: 500,
  TEL: 50,
};
export const LimitInputNumber = (value) => {
  try {
    if (value && value.length > InputLimit.NUMBER) {
      return value.substring(0, InputLimit.NUMBER);
    }
    return value;
  } catch (err) {
    return value;
  }
};

export const LimitInputNumber2 = (e) => {
  try {
    if (e.target.value && e.target.value.length > InputLimit.NUMBER) {
      e.target.value = e.target.value.substring(0, InputLimit.NUMBER);
    }
    return e;
  } catch (err) {
    return e;
  }
};
export const alphabetColorMapping = {
  A: { background: "#f6a6c2", color: "#000" },
  B: { background: "#ffe786", color: "#000" },
  C: { background: "#99e8a7", color: "#000" },
  D: { background: "#8babff", color: "#000" },
  E: { background: "#cc8aff", color: "#000" },

  F: { background: "#f6a6c2", color: "#000" },
  G: { background: "#ffe786", color: "#000" },
  H: { background: "#99e8a7", color: "#000" },
  I: { background: "#8babff", color: "#000" },
  J: { background: "#cc8aff", color: "#000" },

  K: { background: "#f6a6c2", color: "#000" },
  L: { background: "#ffe786", color: "#000" },
  M: { background: "#99e8a7", color: "#000" },
  N: { background: "#8babff", color: "#000" },
  O: { background: "#cc8aff", color: "#000" },

  P: { background: "#f6a6c2", color: "#000" },
  Q: { background: "#ffe786", color: "#000" },
  R: { background: "#99e8a7", color: "#000" },
  S: { background: "#8babff", color: "#000" },
  T: { background: "#cc8aff", color: "#000" },

  U: { background: "#f6a6c2", color: "#000" },
  V: { background: "#ffe786", color: "#000" },
  W: { background: "#99e8a7", color: "#000" },
  X: { background: "#8babff", color: "#000" },
  Y: { background: "#cc8aff", color: "#000" },

  Z: { background: "#f6a6c2", color: "#000" },
  "0": { background: "#ffe786", color: "#000" },
};

export const currencyIconMapping = {
  USD: "$",
  CAD: "CA$",
  EUR: "€",
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ARS: "AR$",
  AUD: "AU$",
  AZN: "AZN",
  BAM: "KM",
  BDT: "৳",
  BGN: "Лв.",
  BHD: "BD",
  BIF: "FBu",
  BND: "B$",
  BOB: "Bs",
  BRL: "R$",
  BWP: "P",
  BYN: "Br",
  BZD: "BZ$",
  CDF: "FC",
  CHF: "CHf",
  CLP: "CL$",
  CNY: "cn¥",
  COP: "CO$",
  CRC: "₡",
  CVE: "CV$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr.",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "E£",
  ERN: "Nfk",
  ETB: "Br",
  GBP: "£",
  GEL: "GEL",
  GHS: "GH¢",
  GNF: "FG",
  GTQ: "Q",
  HKD: "HK$",
  HNL: "L",
  HRK: "kn",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "Íkr",
  JMD: "J$",
  JOD: "د.ا",
  JPY: "¥",
  KES: "Ksh",
  KHR: "៛",
  KMF: "CF",
  KRW: "₩",
  KWD: "KD",
  KZT: "₸",
  LBP: "ل.ل.",
  LKR: "ரூ",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "Ar",
  MKD: "Ден",
  MMK: "K",
  MOP: "MOP$",
  MUR: "₨",
  MXN: "Mex$",
  MYR: "RM",
  MZN: "MT",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "Nkr",
  NPR: "Rs.",
  NZD: "NZ$",
  OMR: "ر.ع.",
  PAB: "B/.",
  PEN: "S/",
  PHP: "₱",
  PKR: "PKR",
  PLN: "zł",
  PYG: "₲",
  QAR: "QR",
  RON: "lei",
  RSD: "din",
  RUB: "₽",
  RWF: "FRw",
  SAR: "SR",
  SDG: "ج.س.",
  SEK: "Skr",
  SGD: "S$",
  SOS: "Sh.so.",
  SYP: "£S",
  THB: "฿",
  TND: "DT",
  TOP: "T$",
  TRY: "₺",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  UYU: "$U",
  UZS: "so'm",
  VEF: "Bs",
  VND: "₫",
  XAF: "FCFA",
  XOF: "CFA",
  YER: "﷼",
  ZAR: "R",
  ZMK: "ZK",
  ZWL: "Z$",
};
