import React, { useEffect, useState } from "react";

export default function CadenceStepStatsStep0(props) {
  const [steps, setSteps] = useState(0);
  const [days, setDays] = useState(0);
  const [automated, setAutomated] = useState(0);

  useEffect(() => {
    let automatedTasks = 0,
      delayInSec = 0;
    props.steps.forEach((step) => {
      delayInSec = delayInSec + (step.delay || 0);
      if (step.channelType === "SMS") {
        automatedTasks++;
      } else if (step.channelType === "EMAIL") {
        if (step.emailContent?.emailSendType === "MANUAL") {
        } else {
          automatedTasks++;
        }
      }
    });
    const _days = Math.floor(delayInSec / 86400);
    setSteps(props.steps.length);
    setDays(_days + 1);
    setAutomated((automatedTasks === 0 ? 0 : (automatedTasks / props.steps.length) * 100).toFixed(2));
  }, [props.steps]);

  return (
    <div className="d-flex gap-4 flex-center mb-3 rounded-1">
      <div className="card d-flex flex-center flex-row gap-4 px-4 py-2">
        <div className="d-flex flex-center">
          <i class="bi bi-check-circle text-dark fs-3"></i>
        </div>
        <div className="d-flex align-items-baseline gap-1">
          <div className="fs-3 fw-bold">{steps}</div>
          <div className="fs-9">{steps > 1 ? "Steps" : "Step"}</div>
        </div>
      </div>
      <div className="card d-flex flex-center flex-row gap-4 px-4 py-2">
        <div className="d-flex flex-center">
          <i class="bi bi-calendar4 text-dark fs-3"></i>
        </div>
        <div className="d-flex align-items-baseline gap-1">
          <div className="fs-3 fw-bold">{days}</div>
          <div className="fs-9">{days > 1 ? "Days" : "Day"}</div>
        </div>
      </div>
      <div className="card d-flex flex-center flex-row gap-4 px-4 py-2">
        <div className="d-flex flex-center">
          <i class="bi bi-gear text-dark fs-3"></i>
        </div>
        <div className="d-flex align-items-baseline gap-1">
          <div className="fs-3 fw-bold">{automated}%</div>
          <div className="fs-9">Automated</div>
        </div>
      </div>
    </div>
  );
}
