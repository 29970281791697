import React from "react";
import LOGO from "../../../../../images/logo/rectangle-logo/SVG/logo-rectangle.svg";
import InlineStyled from "../../../../../components/inline-styled/inline-styled";

export default function CommonWrapper(props) {
  return (
    <div className="login-form login-signin AuthLoginForm">
      <div className="d-flex flex-column flex-root">
        <InlineStyled
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        >
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <div className="mb-12">
              <img alt="Logo" src={LOGO} className="h-90px" />
            </div>
            <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 AuthLoginCard">{props.children}</div>
          </div>
        </InlineStyled>
      </div>
    </div>
  );
}
