import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  PRODUCTFIELDS_REQUEST,
  PRODUCTFIELDS_SUCCESS,
  PRODUCTFIELDS_ERROR,
  PRODUCTADDFIELDS_REQUEST,
  PRODUCTADDFIELDS_SUCCESS,
  PRODUCTADDFIELDS_ERROR,
  PRODUCTCATEGORIES_REQUEST,
  PRODUCTCATEGORIES_SUCCESS,
  PRODUCTCATEGORIES_ERROR,
  PRODUCTCATEGORIESADD_REQUEST,
  PRODUCTCATEGORIESADD_SUCCESS,
  PRODUCTCATEGORIESADD_ERROR,
  PRODUCTCATEGORIESEDIT_REQUEST,
  PRODUCTCATEGORIESEDIT_SUCCESS,
  PRODUCTCATEGORIESEDIT_ERROR,
  PRODUCTCATEGORIESDELETE_REQUEST,
  PRODUCTCATEGORIESDELETE_SUCCESS,
  PRODUCTCATEGORIESDELETE_ERROR,
  PRODUCTTAGS_REQUEST,
  PRODUCTTAGS_SUCCESS,
  PRODUCTTAGS_ERROR,
  PRODUCTTAGSADD_REQUEST,
  PRODUCTTAGSADD_SUCCESS,
  PRODUCTTAGSADD_ERROR,
  PRODUCTTAGSEDIT_REQUEST,
  PRODUCTTAGSEDIT_SUCCESS,
  PRODUCTTAGSEDIT_ERROR,
  PRODUCTTAGSDELETE_REQUEST,
  PRODUCTTAGSDELETE_SUCCESS,
  PRODUCTTAGSDELETE_ERROR,
  PRODUCTCELLEDIT_REQUEST,
  PRODUCTCELLEDIT_SUCCESS,
  PRODUCTCELLEDIT_ERROR,
  PRODUCTADD_REQUEST,
  PRODUCTADD_SUCCESS,
  PRODUCTADD_ERROR,
  PRODUCTBULKEDIT_REQUEST,
  PRODUCTBULKEDIT_SUCCESS,
  PRODUCTBULKEDIT_ERROR,
  PRODUCTDELETE_REQUEST,
  PRODUCTDELETE_SUCCESS,
  PRODUCTDELETE_ERROR,
  PRODUCTFILTERS_REQUEST,
  PRODUCTFILTERS_SUCCESS,
  PRODUCTFILTERS_ERROR,
  PRODUCTFILTERADD_REQUEST,
  PRODUCTFILTERADD_SUCCESS,
  PRODUCTFILTERADD_ERROR,
  PRODUCTFILTEREDIT_REQUEST,
  PRODUCTFILTEREDIT_SUCCESS,
  PRODUCTFILTEREDIT_ERROR,
  PRODUCTFILTERFAVORITE_REQUEST,
  PRODUCTFILTERFAVORITE_SUCCESS,
  PRODUCTFILTERFAVORITE_ERROR,
  PRODUCTFILTERDELETE_REQUEST,
  PRODUCTFILTERDELETE_SUCCESS,
  PRODUCTFILTERDELETE_ERROR,
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_ERROR,
  PRODUCTS_UPDATE_REQUEST,
  PRODUCTS_UPDATE_SUCCESS,
  PRODUCTS_UPDATE_ERROR,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  FIELD_SHOWHIDE_REQUEST,
  FIELD_SHOWHIDE_SUCCESS,
  FIELD_SHOWHIDE_ERROR,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_UPDATEPRIORITY_SUCCESS,
  FIELD_UPDATEPRIORITY_ERROR,
  LOADING_DATA_UPDATE,
  PRODUCTSMERGE_REQUEST,
  PRODUCTSMERGE_SUCCESS,
  PRODUCTSMERGE_ERROR,
  APPLYFILTER,
  CLEARFILTER,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_ERROR,
  PRODUCT_DROPDOWN_ADD_REQUEST,
  PRODUCT_DROPDOWN_ADD_SUCCESS,
  PRODUCT_DROPDOWN_ADD_ERROR,
  PRODUCT_DROPDOWN_ADD_CLEAR,
} from "../types/types";

//PRODUCT DROPDOWN ADD
export function productDropdownAddRequest(req) {
  return {
    type: PRODUCT_DROPDOWN_ADD_REQUEST,
    payload: req,
  };
}
export function productDropdownAddSuccess(res) {
  return {
    type: PRODUCT_DROPDOWN_ADD_SUCCESS,
    payload: res,
  };
}
export function productDropdownAddError(err) {
  return {
    type: PRODUCT_DROPDOWN_ADD_ERROR,
    paylod: err,
  };
}
export function productDropdownAddClear(req) {
  return {
    type: PRODUCT_DROPDOWN_ADD_CLEAR,
    payload: req,
  };
}

//product search
export function productSearchRequest(req) {
  return {
    type: PRODUCT_SEARCH_REQUEST,
    payload: req,
  };
}
export function productSearchSuccess(res) {
  return {
    type: PRODUCT_SEARCH_SUCCESS,
    payload: res,
  };
}
export function productSearchError(err) {
  return {
    type: PRODUCT_SEARCH_ERROR,
    paylod: err,
  };
}

// Getting product data
export function productsRequest(req) {
  return {
    type: PRODUCTS_REQUEST,
    payload: req,
  };
}

export function productsSuccess(res) {
  return {
    type: PRODUCTS_SUCCESS,
    payload: res,
  };
}

export function productsError(err) {
  return {
    type: PRODUCTS_ERROR,
    payload: err,
  };
}

// Getting product data without loading
export function productsUpdateRequest(req) {
  return {
    type: PRODUCTS_UPDATE_REQUEST,
    payload: req,
  };
}

export function productsUpdateSuccess(res) {
  return {
    type: PRODUCTS_UPDATE_SUCCESS,
    payload: res,
  };
}

export function productsUpdateError(err) {
  return {
    type: PRODUCTS_UPDATE_ERROR,
    payload: err,
  };
}

// Update loading data
export function loadingDataUpdateRequest(req) {
  return {
    type: LOADING_DATA_UPDATE,
    payload: req,
  };
}

// Update fields
export function fieldUpdateRequest(req) {
  return {
    type: FIELD_UPDATE_REQUEST,
    payload: req,
  };
}

export function fieldUpdateSuccess(res) {
  return {
    type: FIELD_UPDATE_SUCCESS,
    payload: res,
  };
}

export function fieldUpdateError(err) {
  return {
    type: FIELD_UPDATE_ERROR,
    payload: err,
  };
}

// show/hide field
export function fieldShowHideRequest(req) {
  return {
    type: FIELD_SHOWHIDE_REQUEST,
    payload: req,
  };
}

export function fieldShowHideSuccess(res) {
  return {
    type: FIELD_SHOWHIDE_SUCCESS,
    payload: res,
  };
}

export function fieldShowHideError(err) {
  return {
    type: FIELD_SHOWHIDE_ERROR,
    payload: err,
  };
}

// update field priority
export function fieldUpdatePriorityRequest(req) {
  return {
    type: FIELD_UPDATEPRIORITY_REQUEST,
    payload: req,
  };
}

export function fieldUpdatePrioritySuccess(res) {
  return {
    type: FIELD_UPDATEPRIORITY_SUCCESS,
    payload: res,
  };
}

export function fieldUpdatePriorityError(err) {
  return {
    type: FIELD_UPDATEPRIORITY_ERROR,
    payload: err,
  };
}

// Clearing error and success store
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  };
}

// Getting column details
export function productFieldsRequest(req) {
  return {
    type: PRODUCTFIELDS_REQUEST,
    payload: req,
  };
}

export function productFieldsSuccess(res) {
  return {
    type: PRODUCTFIELDS_SUCCESS,
    payload: res,
  };
}

export function productFieldsError(err) {
  return {
    type: PRODUCTFIELDS_ERROR,
    payload: err,
  };
}

// Getting Add column details
export function productAddFieldsRequest(req) {
  return {
    type: PRODUCTADDFIELDS_REQUEST,
    payload: req,
  };
}

export function productAddFieldsSuccess(res) {
  return {
    type: PRODUCTADDFIELDS_SUCCESS,
    payload: res,
  };
}

export function productAddFieldsError(err) {
  return {
    type: PRODUCTADDFIELDS_ERROR,
    payload: err,
  };
}

// Adding product
export function productAddRequest(req) {
  return {
    type: PRODUCTADD_REQUEST,
    payload: req,
  };
}

export function productAddSuccess(res) {
  return {
    type: PRODUCTADD_SUCCESS,
    payload: res,
  };
}

export function productAddError(err) {
  return {
    type: PRODUCTADD_ERROR,
    payload: err,
  };
}

// Bulk Editing product
export function productBulkEditRequest(req) {
  return {
    type: PRODUCTBULKEDIT_REQUEST,
    payload: req,
  };
}

export function productBulkEditSuccess(res) {
  return {
    type: PRODUCTBULKEDIT_SUCCESS,
    payload: res,
  };
}

export function productBulkEditError(err) {
  return {
    type: PRODUCTBULKEDIT_ERROR,
    payload: err,
  };
}

// Deleting product
export function productDeleteRequest(req) {
  return {
    type: PRODUCTDELETE_REQUEST,
    payload: req,
  };
}

export function productDeleteSuccess(res) {
  return {
    type: PRODUCTDELETE_SUCCESS,
    payload: res,
  };
}

export function productDeleteError(err) {
  return {
    type: PRODUCTDELETE_ERROR,
    payload: err,
  };
}

// Getting category details
export function productCategoriesRequest(req) {
  return {
    type: PRODUCTCATEGORIES_REQUEST,
    payload: req,
  };
}

export function productCategoriesSuccess(res) {
  return {
    type: PRODUCTCATEGORIES_SUCCESS,
    payload: res,
  };
}

export function productCategoriesError(err) {
  return {
    type: PRODUCTCATEGORIES_ERROR,
    payload: err,
  };
}

// Adding Category
export function productCategoriesAddRequest(req) {
  return {
    type: PRODUCTCATEGORIESADD_REQUEST,
    payload: req,
  };
}

export function productCategoriesAddSuccess(res) {
  return {
    type: PRODUCTCATEGORIESADD_SUCCESS,
    payload: res,
  };
}

export function productCategoriesAddError(err) {
  return {
    type: PRODUCTCATEGORIESADD_ERROR,
    payload: err,
  };
}

// Editing Category
export function productCategoriesEditRequest(req) {
  return {
    type: PRODUCTCATEGORIESEDIT_REQUEST,
    payload: req,
  };
}

export function productCategoriesEditSuccess(res) {
  return {
    type: PRODUCTCATEGORIESEDIT_SUCCESS,
    payload: res,
  };
}

export function productCategoriesEditError(err) {
  return {
    type: PRODUCTCATEGORIESEDIT_ERROR,
    payload: err,
  };
}

// Deleting Category
export function productCategoriesDeleteRequest(req) {
  return {
    type: PRODUCTCATEGORIESDELETE_REQUEST,
    payload: req,
  };
}

export function productCategoriesDeleteSuccess(res) {
  return {
    type: PRODUCTCATEGORIESDELETE_SUCCESS,
    payload: res,
  };
}

export function productCategoriesDeleteError(err) {
  return {
    type: PRODUCTCATEGORIESDELETE_ERROR,
    payload: err,
  };
}

// Getting tag details
export function productTagsRequest(req) {
  return {
    type: PRODUCTTAGS_REQUEST,
    payload: req,
  };
}

export function productTagsSuccess(res) {
  return {
    type: PRODUCTTAGS_SUCCESS,
    payload: res,
  };
}

export function productTagsError(err) {
  return {
    type: PRODUCTTAGS_ERROR,
    payload: err,
  };
}

// Adding Tag
export function productTagsAddRequest(req) {
  return {
    type: PRODUCTTAGSADD_REQUEST,
    payload: req,
  };
}

export function productTagsAddSuccess(res) {
  return {
    type: PRODUCTTAGSADD_SUCCESS,
    payload: res,
  };
}

export function productTagsAddError(err) {
  return {
    type: PRODUCTTAGSADD_ERROR,
    payload: err,
  };
}

// Editing Tag
export function productTagsEditRequest(req) {
  return {
    type: PRODUCTTAGSEDIT_REQUEST,
    payload: req,
  };
}

export function productTagsEditSuccess(res) {
  return {
    type: PRODUCTTAGSEDIT_SUCCESS,
    payload: res,
  };
}

export function productTagsEditError(err) {
  return {
    type: PRODUCTTAGSEDIT_ERROR,
    payload: err,
  };
}

// Deleting Tag
export function productTagsDeleteRequest(req) {
  return {
    type: PRODUCTTAGSDELETE_REQUEST,
    payload: req,
  };
}

export function productTagsDeleteSuccess(res) {
  return {
    type: PRODUCTTAGSDELETE_SUCCESS,
    payload: res,
  };
}

export function productTagsDeleteError(err) {
  return {
    type: PRODUCTTAGSDELETE_ERROR,
    payload: err,
  };
}

// Editing Cell
export function productCellEditRequest(req) {
  return {
    type: PRODUCTCELLEDIT_REQUEST,
    payload: req,
  };
}

export function productCellEditSuccess(res) {
  return {
    type: PRODUCTCELLEDIT_SUCCESS,
    payload: res,
  };
}

export function productCellEditError(err) {
  return {
    type: PRODUCTCELLEDIT_ERROR,
    payload: err,
  };
}

// Getting filter data
export function productFiltersRequest(req) {
  return {
    type: PRODUCTFILTERS_REQUEST,
    payload: req,
  };
}

export function productFiltersSuccess(res) {
  return {
    type: PRODUCTFILTERS_SUCCESS,
    payload: res,
  };
}

export function productFiltersError(err) {
  return {
    type: PRODUCTFILTERS_ERROR,
    payload: err,
  };
}

// Adding Filter
export function productFilterAddRequest(req, cb) {
  return {
    type: PRODUCTFILTERADD_REQUEST,
    payload: req,
    cb,
  };
}

export function productFilterAddSuccess(res) {
  return {
    type: PRODUCTFILTERADD_SUCCESS,
    payload: res,
  };
}

export function productFilterAddError(err) {
  return {
    type: PRODUCTFILTERADD_ERROR,
    payload: err,
  };
}

// Editing Filter
export function productFilterEditRequest(req, cb) {
  return {
    type: PRODUCTFILTEREDIT_REQUEST,
    payload: req,
    cb,
  };
}

export function productFilterEditSuccess(res) {
  return {
    type: PRODUCTFILTEREDIT_SUCCESS,
    payload: res,
  };
}

export function productFilterEditError(err) {
  return {
    type: PRODUCTFILTEREDIT_ERROR,
    payload: err,
  };
}

// Favoriting Filter
export function productFilterFavoriteRequest(req) {
  return {
    type: PRODUCTFILTERFAVORITE_REQUEST,
    payload: req,
  };
}

export function productFilterFavoriteSuccess(res) {
  return {
    type: PRODUCTFILTERFAVORITE_SUCCESS,
    payload: res,
  };
}

export function productFilterFavoriteError(err) {
  return {
    type: PRODUCTFILTERFAVORITE_ERROR,
    payload: err,
  };
}

// Deleting Filter
export function productFilterDeleteRequest(req) {
  return {
    type: PRODUCTFILTERDELETE_REQUEST,
    payload: req,
  };
}

export function productFilterDeleteSuccess(res) {
  return {
    type: PRODUCTFILTERDELETE_SUCCESS,
    payload: res,
  };
}

export function productFilterDeleteError(err) {
  return {
    type: PRODUCTFILTERDELETE_ERROR,
    payload: err,
  };
}

// Getting currency data
export function currencyRequest(req) {
  return {
    type: CURRENCY_REQUEST,
    payload: req,
  };
}

export function currencySuccess(res) {
  return {
    type: CURRENCY_SUCCESS,
    payload: res,
  };
}

export function currencyError(err) {
  return {
    type: CURRENCY_ERROR,
    payload: err,
  };
}

// Merging
export function productsMergeRequest(req) {
  return {
    type: PRODUCTSMERGE_REQUEST,
    payload: req,
  };
}

export function productsMergeSuccess(res) {
  return {
    type: PRODUCTSMERGE_SUCCESS,
    payload: res,
  };
}

export function productsMergeError(err) {
  return {
    type: PRODUCTSMERGE_ERROR,
    payload: err,
  };
}

export function applyFilter(res) {
  return {
    type: APPLYFILTER,
    payload: res,
  };
}

export function clearFilter(res) {
  return {
    type: CLEARFILTER,
    payload: res,
  };
}
