import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import * as shortLinkTypes from "../types/campaign_short_link_types";
import api from "../../../../network/apis/api";
import { endpoints } from "../endpoints";
import * as shortLinkActions from "../actions/campaign_short_link_actions";

function* getListOfShortLinks(action) {
  try {
    const res = yield call(api.get, endpoints.SHORT_LINK, action.payload);
    if (res.status === 200) {
      yield put(shortLinkActions.getListOfShortLinksSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(shortLinkActions.getListOfShortLinksError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(shortLinkActions.getListOfShortLinksError(e));
  }
}
function* createShortLink(action) {
  try {
    const res = yield call(api.post, endpoints.SHORT_LINK, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(shortLinkActions.createShortLinkSuccess(res.data));
      yield put(shortLinkActions.getListOfShortLinksRequest(action.payload.requestPayload));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(shortLinkActions.createShortLinkError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(shortLinkActions.createShortLinkError(e));
  }
}
function* editShortLink(action) {
  try {
    const res = yield call(api.patch, `${endpoints.SHORT_LINK}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(shortLinkActions.editShortLinkSuccess(res.data));
      yield put(shortLinkActions.getListOfShortLinksRequest(action.payload.requestPayload));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(shortLinkActions.editShortLinkError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(shortLinkActions.editShortLinkError(e));
  }
}
function* deleteShortLink(action) {
  try {
    const res = yield call(api.delete, `${endpoints.SHORT_LINK}/${action.payload.id}`);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(shortLinkActions.deleteShortLinkSuccess(res.data));
      yield put(shortLinkActions.getListOfShortLinksRequest(action.payload.requestPayload));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(shortLinkActions.deleteShortLinkError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(shortLinkActions.deleteShortLinkError(e));
  }
}
function* getShortLinkAnalytics(action) {
  try {
    const res = yield call(api.get, endpoints.SHORT_LINK_ANALYTICS, action.payload);
    if (res.status === 200) {
      yield put(shortLinkActions.getShortLinkAnalyticsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(shortLinkActions.getShortLinkAnalyticsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(shortLinkActions.getShortLinkAnalyticsError(e));
  }
}
function* getShortLinkReports(action) {
  try {
    const res = yield call(api.get, `${endpoints.SHORT_LINK_REPORTS}/${action.payload.id}`, action.payload.data);
    if (res.status === 200) {
      yield put(shortLinkActions.getShortLinkReportsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(shortLinkActions.getShortLinkReportsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(shortLinkActions.getShortLinkReportsError(e));
  }
}

export default function* campaignShortLinkSaga() {
  yield takeLatest(shortLinkTypes.getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_REQUEST, getListOfShortLinks);
  yield takeLatest(shortLinkTypes.createShortLink.CREATE_SHORT_LINK_REQUEST, createShortLink);
  yield takeLatest(shortLinkTypes.editShortLink.EDIT_SHORT_LINK_REQUEST, editShortLink);
  yield takeLatest(shortLinkTypes.deleteShortLink.DELETE_SHORT_LINK_REQUEST, deleteShortLink);
  yield takeLatest(shortLinkTypes.getShortLinkAnalytics.GET_SHORT_LINK_ANALYTICS_REQUEST, getShortLinkAnalytics);
  yield takeLatest(shortLinkTypes.getShortLinkReports.GET_SHORT_LINK_REPORTS_REQUEST, getShortLinkReports);
}
