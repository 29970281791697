import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import ContentWrapper from "../../../../Wrapper";
import CadenceSubHeader from "../../components/cadence-header";

import { useDispatch, useSelector } from "react-redux";
import { ContentWithCardLoading } from "../../../../../components/loading";
import StepwiseAnalyticsCard from "../../components/timeline-step/views/stepwise_analytics_card.view";
import { abTestLabelMapping } from "../../constants";
import { getCadenceAnalyticsStepWiseRequest } from "../../store/actions/cadence_analytics_stepwise_actions";
import { getDaysHoursMinutesOfStep } from "./utils/functions";

export default function CadenceAnalyticsStepWise() {
  const [stepData, setStepData] = useState([]);
  const tabName = "Stepwise Analytics";
  const history = useHistory();
  const dispatch = useDispatch();
  const { cadenceId } = useParams();
  const cadenceAnalyticsStepWise = useSelector((state) => state.cadence.analyticsStepWise);
  useEffect(() => {
    dispatch(
      getCadenceAnalyticsStepWiseRequest({
        id: cadenceId,
      }),
    );
  }, [dispatch, cadenceId]);

  useEffect(() => {
    if (!cadenceId) {
      history.push("/automation/cadence/list");
    }
  }, [cadenceId, history]);

  useEffect(() => {
    setStepData(_.sortBy(cadenceAnalyticsStepWise.data, ["executionOrder"]));
  }, [cadenceAnalyticsStepWise.data]);

  return (
    <ContentWrapper subHeader={<CadenceSubHeader tabName={tabName} cadenceId={cadenceId}></CadenceSubHeader>}>
      <div className="card">
        <div className="card-body">
          {cadenceAnalyticsStepWise.loading ? (
            <ContentWithCardLoading />
          ) : cadenceAnalyticsStepWise.data.length === 0 ? (
            "No steps"
          ) : (
            stepData.map((step, index) => {
              return (
                <div className="w-100 my-4" key={index}>
                  <div className="d-flex">
                    <span className="step-number text-primary mr-2">{`${step.channelType}`} - </span>
                    <span>Day {getDaysHoursMinutesOfStep(stepData, index).d + 1}</span>
                  </div>
                  {step.isAbTestingEnabled ? step.abTestStats.map((subStep, subStepIndex) => <StepwiseAnalyticsCard step={subStep} prefix={abTestLabelMapping[subStepIndex]} />) : <StepwiseAnalyticsCard {...{ step }} />}
                </div>
              );
            })
          )}
        </div>
      </div>
    </ContentWrapper>
  );
}
