import { combineReducers } from "redux";
import profileOverviewReducer from "./profile-overview.reducer";
import schedulingSettingsReducer from "./scheduling-settings.reducer";
import syncSettingsReducer from "./sync-settings.reducer";
// import signInSettingsReducer from "./signin-settings.reducer";
// import loginSessionReducer from "./login-sessions.reducer";
// import companyListReducer from "./company-list-settings.reducer";
// import emailSyncSettingsReducer from "./email-sync-settings.reducer";
// import calendarSyncReducer from "./calendar-sync-settings.reducer";
// import notificationReducer from "./notification-settings.reducer";

const profileSettingsReducer = combineReducers({
  overview: profileOverviewReducer,
  schedular: schedulingSettingsReducer,
  sync: syncSettingsReducer,
  // signin: signInSettingsReducer,
  // loginSession: loginSessionReducer,
  // companyList: companyListReducer,
  // emailSync: emailSyncSettingsReducer,
  // calendarSync: calendarSyncReducer,
  // notification: notificationReducer,
});

export default profileSettingsReducer;
