import { searchAllAssociations } from "../types/activity.types";

const initState = {
  association: {
    loading: true,
    data: {
      lead: [],
      people: [],
      deal: [],
      company: [],
    },
  },
};

export default function activitySearchReducer(state = initState, action = {}) {
  switch (action.type) {
    case searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_REQUEST:
      return {
        ...state,
        association: {
          ...state.association,
          loading: true,
        },
      };
    case searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        association: {
          ...state.association,
          loading: false,
          data: action.payload.data,
        },
      };
    case searchAllAssociations.SEARCH_ALL_ASSOCIATIONS_ERROR:
      return {
        ...state,
        association: {
          ...state.association,
          loading: false,
        },
      };
    default:
      return state;
  }
}
