export const scheduleRawData = {
  name: "Any Day, Any Time",
  weekDays: [1, 2, 3, 4, 5, 6, 7],
  from: "00:00",
  to: "23:59",
  timezone: "Asia/Kolkata",
};
export const emailSendTypeOptions = [
  { value: "EMAIL_SYNC", label: "Automated Business Email" },
  { value: "MANUAL", label: "Manual Business Email" },
  { value: "CAMPAIGN_API", label: "Automated Email via Campaign" },
];
export const smsSendTypeOptions = [
  // { value: "MANUAL", label: "Manual" },
  { value: "CAMPAIGN_API", label: "Campaign Email" },
];
export const priorityWithinCadenceOptions = [
  { value: "FIRSTSTEP", label: "First Step Emails" },
  { value: "FOLLOWUP", label: "Followup Emails" },
  { value: "EQUALLYDIVIDED", label: "Equally Divided" },
];
export const cadencePriorityOptions = [
  { value: "CRITICAL", label: "Critical" },
  { value: "HIGH", label: "High" },
  { value: "MEDIUM", label: "Medium" },
  { value: "LOW", label: "Low" },
];
export const winningPatternOptions = ["Manually", "Automated"];
export const winningPatternConditionOptions = [
  { value: "REPLIED", label: "Replied" },
  { value: "OPENED", label: "Opened" },
  { value: "CLICKED", label: "Clicked" },
];
export const CadenceResponseTypeOptions = [
  { label: "Sent", value: "SENT" },
  { label: "Opened", value: "OPENED" },
  { label: "Replied", value: "REPLIED" },
  { label: "Liked", value: "LIKED" },
  { label: "Shared", value: "SHARED" },
  { label: "Answered", value: "ANSWERED" },
  { label: "Declined", value: "DECLINED" },
  { label: "Errored", value: "ERRORED" },
];
export const CadenceConditionOptions = [
  { label: "Greater Than", value: "GREATER_THAN" },
  { label: "Equal To", value: "EQUALS" },
  // { label: "Less Than", value: "LESSER_THAN" },
];
export const valueLabelMapping = {
  GREATER_THAN: ">",
  EQUALS: "=",
  LESSER_THAN: "<",
  SENT: "Sent",
  OPENED: "Opened",
  LIKED: "Liked",
  SHARED: "Shared",
  ANSWERED: "Answered",
  DECLINED: "Declined",
  ERRORED: "Errored",
  REPLIED: "Replied",
  OPEN: "Open",
  CLICKED: "Clicked",
  CRITICAL: "Critical",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  FIRSTSTEP: "First Step Emails",
  FOLLOWUP: "Followup Emails",
  EQUALLYDIVIDED: "Equally Divided",
};
export const AddStepsMenu = [
  {
    name: "Email",
    icon: "bi bi-envelope",
    color: "#4791FF",
    bg: "#E0ECFF",
    channelType: "EMAIL",
  },
  {
    name: "SMS",
    icon: "bi bi-phone",
    color: "#4791FF",
    bg: "#E0ECFF",
    channelType: "SMS",
  },
    {
    name: "Whatsapp",
    icon: "bi bi-whatsapp",
    color: "#4791FF",
    bg: "#E0ECFF",
    channelType: "WHATSAPP",
  },
  // {
  //   name: "Activity",
  //   icon: "bi bi-calendar-check",
  //   color: "#02BC77",
  //   bg: "#D4F4E8",
  //   channelType: "TASK",
  // },
];

export const StepChannelColorMapping = {
  EMAIL: {
    icon: "bi bi-envelope",
    color: "text-4791FF",
    bg: "bg-e0ecff",
  },
  TASK: {
    icon: "bi bi-calendar-check",
    color: "text-02BC77",
    bg: "bg-d4f4e8",
  },
  SMS: {
    icon: "bi bi-phone",
    color: "text-4791FF",
    bg: "bg-e0ecff",
  },
  WHATSAPP: {
    icon: "bi bi-whatsapp",
    color: "text-4791FF",
    bg: "bg-e0ecff",
  },
};

export const sendTypeNameMapping = {
  MANUAL: "Manual",
  CAMPAIGN_API: "Campaign api",
  EMAIL_SYNC: "Email sync",
};

export const CadenceTemplateDummyList = [
  {
    _id: 1,
    name: "Email Sequence",
    steps: [
      {
        name: "Step 1",
        type: "EMAIL",
        delay: 10000,
      },
      {
        name: "Step 2",
        type: "EMAIL",
        delay: 10000,
      },
      {
        name: "Step 3",
        type: "ACTIVITY",
        delay: 10000,
      },
      {
        name: "Step 4",
        type: "ACTIVITY",
        delay: 10000,
      },
    ],
  },
  {
    _id: 2,
    name: "Email Sequence",
    steps: [
      {
        name: "Step 1",
        type: "EMAIL",
        delay: 10000,
      },
      {
        name: "Step 2",
        type: "EMAIL",
        delay: 10000,
      },
      {
        name: "Step 3",
        type: "ACTIVITY",
        delay: 10000,
      },
      {
        name: "Step 4",
        type: "ACTIVITY",
        delay: 10000,
      },
    ],
  },
];

export const cadenceDummySteps = [
  {
    channelType: "EMAIL",
    delay: 0,
    executionOrder: 1,
    isAutomatic: true,
    isNewThread: true,
    name: "Follow Up Email",
    template: {
      name: "Follow Up Email",
      content: "<p>Template step 1</p>",
      editorType: "tiny_cloud",
      subject: "Test Subject Line",
      templateId: null,
    },
  },
  {
    channelType: "EMAIL",
    delay: 0,
    executionOrder: 2,
    isAutomatic: true,
    isNewThread: true,
    name: "Follow Up Email",
    template: {
      name: "Follow Up Email",
      content: "<p>Template step 2</p>",
      editorType: "tiny_cloud",
      subject: "Test Subject Line",
      templateId: null,
    },
    abTesting: {
      templates: [
        {
          template: {
            name: "Follow Up Email A",
            content: "<p>Template step 1 - A</p>",
            editorType: "tiny_cloud",
            subject: "Test Subject Line",
            templateId: null,
          },
        },
        {
          template: {
            name: "Follow Up Email B",
            content: "<p>Template step 1 -B</p>",
            editorType: "tiny_cloud",
            subject: "Test Subject Line",
            templateId: null,
          },
        },
      ],
      isAutomate: true,
      condition: {
        stat: "SENT",
        type: "GREATER_THAN",
        value: 50,
      },
    },
  },
];

export const subscriberActionOptions = [
  { value: "PAUSE", label: "Pause" },
  { value: "RESUME", label: "Resume" },
  { value: "FINISHED", label: "Mark as finished" },
  { value: "CHANGE_STEP", label: "Change Step" },
  { value: "REMOVE", label: "Remove from cadence" },
  { value: "REPLIED", label: "Mark as replied" },
  { value: "RETRY", label: "Retry" },
];

export const colorsForIcons = {
  "bi bi-people-fill": {
    color: "",
    bg: "",
  },
  "bi bi-clock-fill": {
    color: "",
    bg: "",
  },
  "bi bi-flag-fill": {
    color: "",
    bg: "",
  },
  "bi bi-telephone-fill": {
    color: "",
    bg: "",
  },
  "fas fa-apple-alt": {
    color: "",
    bg: "",
  },
  "bi bi-calendar": {
    color: "",
    bg: "",
  },
  "bi bi-arrow-down-circle-fill": {
    color: "",
    bg: "",
  },
  "bi bi-folder-fill": {
    color: "",
    bg: "",
  },
  "bi bi-phone-fill": {
    color: "",
    bg: "",
  },
  "bi bi-camera-fill": {
    color: "",
    bg: "",
  },
  "bi bi-scissors": {
    color: "",
    bg: "",
  },
  "bi bi-tools": {
    color: "",
    bg: "",
  },
  "bi bi-chat-left-text-fill": {
    color: "",
    bg: "",
  },
  "bi bi-basket2-fill": {
    color: "",
    bg: "",
  },
  "bi bi-bookmark-star": {
    color: "",
    bg: "",
  },
  "bi bi-briefcase-fill": {
    color: "",
    bg: "",
  },
  "bi bi-bug": {
    color: "",
    bg: "",
  },
  "bi bi-cart-check-fill": {
    color: "",
    bg: "",
  },
  "bi bi-chat-right-text-fill": {
    color: "",
    bg: "",
  },
  "bi bi-chat-square-text-fill": {
    color: "",
    bg: "",
  },
  "bi bi-controller": {
    color: "",
    bg: "",
  },
  "bi bi-envelope-fill": {
    color: "",
    bg: "",
  },
  "bi bi-headphones": {
    color: "",
    bg: "",
  },
  "bi bi-gear": {
    color: "",
    bg: "",
  },
  "bi bi-stopwatch": {
    color: "",
    bg: "",
  },
  "bi-cup-straw": {
    color: "",
    bg: "",
  },
  "bi bi-calendar3": {
    color: "",
    bg: "",
  },
  "bi bi-check2-square": {
    color: "",
    bg: "",
  },
};

export const timeIntervalOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

export const abTestLabelMapping = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
};
