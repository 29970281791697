import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ActivityLossReason from "./lost-reason";
import DealLossReason from "./lost-reason-deals";
import LeadLossReason from "./lost-reason-leads";
import MarketingLossReason from "./lost-reason-marketing";

export default function OutcomesLossReasonRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route path={`${match.url}/activity`} component={ActivityLossReason} />
        <Route path={`${match.url}/lead`} component={LeadLossReason} />
        <Route path={`${match.url}/deal`} component={DealLossReason} />
        <Route path={`${match.url}/marketing`} component={MarketingLossReason} />
        <Redirect to={`${match.url}/activity`} />
      </Switch>
    </>
  );
}
