export const getListOfVideoCategories = {
  GET_LIST_OF_VIDEO_CATEGORIES_REQUEST: "GET_LIST_OF_VIDEO_CATEGORIES_REQUEST",
  GET_LIST_OF_VIDEO_CATEGORIES_SUCCESS: "GET_LIST_OF_VIDEO_CATEGORIES_SUCCESS",
  GET_LIST_OF_VIDEO_CATEGORIES_ERROR: "GET_LIST_OF_VIDEO_CATEGORIES_ERROR",
};

export const createVideoCategory = {
  CREATE_VIDEO_CATEGORY_REQUEST: "CREATE_VIDEO_CATEGORY_REQUEST",
  CREATE_VIDEO_CATEGORY_SUCCESS: "CREATE_VIDEO_CATEGORY_SUCCESS",
  CREATE_VIDEO_CATEGORY_ERROR: "CREATE_VIDEO_CATEGORY_ERROR",
};

export const editVideoCategory = {
  EDIT_VIDEO_CATEGORY_REQUEST: "EDIT_VIDEO_CATEGORY_REQUEST",
  EDIT_VIDEO_CATEGORY_SUCCESS: "EDIT_VIDEO_CATEGORY_SUCCESS",
  EDIT_VIDEO_CATEGORY_ERROR: "EDIT_VIDEO_CATEGORY_ERROR",
};

export const deleteVideoCategory = {
  DELETE_VIDEO_CATEGORY_REQUEST: "DELETE_VIDEO_CATEGORY_REQUEST",
  DELETE_VIDEO_CATEGORY_SUCCESS: "DELETE_VIDEO_CATEGORY_SUCCESS",
  DELETE_VIDEO_CATEGORY_ERROR: "DELETE_VIDEO_CATEGORY_ERROR",
};
