import React, { useState, useEffect, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { Accordion, Card, Button, Popover, Form, Overlay, Tooltip, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { ViewMore } from "../../custom-components/viewMore";
import PersonSelect from "../../../../components/custom_form/person";

//actions
import * as PeopleActions from "../../../../containers/customers/store/actions/actions";
import * as LeadActions from "../../../../containers/leads/store/actions/actions";
import { updateDealpopupDetailsRequest, changeDealPersonRequest } from "../../../../containers/deals/dealPipeline/actions";
import { GreenGradientButton } from "../../../button";
import CustomForm from "../../../custom_form";
import InlineStyled from "../../../inline-styled/inline-styled";

export function PersonDetails(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [cellEdit, setCellEdit] = useState({});
  const [showColumns, setShowColumns] = useState([]);
  const [viewLessColomns, setViewLessColomns] = useState([]);
  const [personShow, setPersonShow] = useState(false);
  const [peopleField, setPeopleField] = useState("");
  const [loadMoreFieldCount, setLoadMoreFieldCount] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addFormData, setAddFormData] = useState({});
  const [editing, setEditing] = useState(false);
  const [fieldList, setFieldList] = useState([]);
  const [personData, setPersonData] = useState({});

  const personTarget = useRef(null);

  const dispatch = useDispatch();

  const loadingStore = useSelector((state) => state.People.loadingData);
  const fieldStore = useSelector((state) => state.People.peopleFields);
  const peopleColumns = useSelector((state) => state.People.peopleAddFields);

  const leadLoadingStore = useSelector((state) => state.Leads.loadingData);

  const dealLoadingStore = useSelector((state) => state.DealPipeline.loadingData);

  // cell loadidng
  const leadCellLoading = useSelector((state) => state.Leads.leadPopup.cellLoading);
  const dealCellLoading = useSelector((store) => store.DealPipeline.dealPopup.cellLoading);
  const peopleCellLoading = useSelector((state) => state.People.peoplePopup.cellLoading);

  useEffect(() => {
    const _viewLess = [];
    const tempFieldStore = fieldStore.list
      .filter((a) => {
        if (a.apiKeyName === "name") {
          _viewLess[0] = a._id;
        } else if (a.apiKeyName === "email") {
          _viewLess[1] = a._id;
        } else if (a.apiKeyName === "phone") {
          _viewLess[2] = a._id;
        }
        return a.isEnabledForTable;
      })
      .map((each) => each._id);
    setShowColumns(tempFieldStore);
    setViewLessColomns(_viewLess);
    setLoadMoreFieldCount(tempFieldStore.length - _viewLess.length);
  }, [fieldStore]);

  useEffect(() => {
    if (!_.isEmpty(fieldStore.list)) {
      const updatedField = [];
      fieldStore.list.forEach((each) => {
        const newObj = { ...each };
        if (each.apiKeyName === "name" && props.isSecondaryModule) {
          if (personData && personData._id) {
            newObj.internalLink = `${props.basePath}/people/${personData._id}`;
            newObj.internalTooltip = "Open People";
          }
        }
        updatedField.push(newObj);
      });
      setFieldList(updatedField);
    } else {
      setFieldList([]);
    }
  }, [fieldStore.list, props.isSecondaryModule, props.basePath, personData]);

  useEffect(() => {
    if (props.moduleType === "deal") {
      if (props.sidePanelProps && _.isObject(props.sidePanelProps.person) && Object.keys(props.sidePanelProps.person).length > 1) setPersonData(props.sidePanelProps.person || {});
      else setPersonData({});
    } else if (props.moduleType === "people") {
      setPersonData(props.sidePanelProps.data || {});
    } else if (props.moduleType === "lead") {
      setPersonData(props.sidePanelProps.data || {});
    } else setPersonData({});
  }, [props.moduleType, props.sidePanelProps]);

  const handleCellEdit = (cellId, data) => {
    if (props.moduleType === "deal") {
      const updateData = {
        data: { ...data, ...cellEdit },
        dealId: props.dealId,
        filter: dealLoadingStore,
        cellId: cellId,
      };
      if (updateData.data.hasOwnProperty("name") || updateData.data.hasOwnProperty("firstName") || updateData.data.hasOwnProperty("lastName")) {
        updateData["pageForm"] = props.pageForm;
      }
      dispatch(updateDealpopupDetailsRequest(updateData));
    } else if (props.moduleType === "people") {
      const updateData = {
        tableLoadingData: loadingStore,
        cellId: cellId,
        loadingData: props.peopleId,
        editData: {
          ...data,
          ...cellEdit,
        },
      };
      dispatch(PeopleActions.peoplePopupEditRequest(updateData));
    } else if (props.moduleType === "lead") {
      const updateData = {
        tableLoadingData: leadLoadingStore,
        cellId: cellId,
        loadingData: props.leadId,
        editData: {
          ...data,
          ...cellEdit,
        },
      };
      dispatch(LeadActions.leadPopupEditRequest(updateData));
    }
  };

  const addTag = (name, colorCode) => {
    dispatch(PeopleActions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(PeopleActions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(PeopleActions.peopleTagsDeleteRequest(id));
  };

  const getPersonDetails = () => {
    if (props.moduleType === "deal") {
      if (props.sidePanelProps && _.isObject(props.sidePanelProps.person) && Object.keys(props.sidePanelProps.person).length > 1) {
        return props.sidePanelProps.person || {};
      }
      return {};
    } else if (props.moduleType === "people") {
      return props.sidePanelProps.data || {};
    } else if (props.moduleType === "lead") {
      return props.sidePanelProps.data || {};
    }
    return {};
  };

  const onANotherPersonorCompanyCancel = () => {
    setPersonShow(false);
    document.body.click();
    setPeopleField("");
  };

  const onanotherSelect = (id) => {
    setPersonShow(false);
    let data = {
      dealId: props.dealId,
      personId: id ? id : peopleField,
    };
    dispatch(changeDealPersonRequest(data));
    document.body.click();
    setPeopleField("");
  };

  const peopleFieldValue = (value, text) => {
    setPeopleField(value);
    if (text === "callApi") {
      let data = {
        dealId: props.dealId,
        personId: value,
      };
      dispatch(changeDealPersonRequest(data));
    }
  };

  const anotherPersonForm = (type) => {
    return (
      <Popover className="w-300px" id="another person and company" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">{"Change to Existing Person"} </Popover.Title>
        <Popover.Content>
          <Form.Group id="person-details-component-another-person-form-person-name" onClick={(e) => e.stopPropagation()}>
            <Form.Label aria-label="person">Person</Form.Label>
            <PersonSelect field={"test"} value={peopleField} setValue={(value) => peopleFieldValue(value, "")} dontShowAdd={true} />
          </Form.Group>
          <div className="d-flex justify-content-center flex-row gap-15px mt-15px">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onANotherPersonorCompanyCancel();
              }}
              size="sm"
            >
              Cancel
            </Button>
            <GreenGradientButton
              onClick={(e) => {
                e.stopPropagation();
                onanotherSelect();
              }}
              size="sm"
            >
              Save
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const handleAddNewPeopleClick = () => {
    setShowAddForm(true);
    dispatch(PeopleActions.peopleAddFieldsRequest({ fieldType: "PERSON" }));
  };

  const closeAllPopovers = () => {
    setLoading(false);
    setShowAddForm(false);
    setPersonShow(false);
    setPeopleField("");
  };

  const handleAdd = () => {
    setLoading(true);
    dispatch(
      PeopleActions.peopleAddRequest({ peopleData: addFormData, loadingData: loadingStore }, (data) => {
        if (data.leadId) {
          onanotherSelect(data.leadId);
        }
        setLoading(false);
        setShowAddForm(false);
      }),
    );
  };

  return (
    <div key={props.index}>
      <Draggable draggableId={"person-details"} index={props.index}>
        {(provided, snapshot) => (
          <Accordion
            activeKey={showAddForm || personShow || isOpen || editing ? "toggle-person" : ""}
            as={Card}
            defaultActiveKey={isOpen ? "toggle-company" : null}
            onClick={() => {
              if (!(showAddForm || personShow || editing)) setIsOpen(!isOpen);
            }}
            className={`mb-4 card-dashed1 ${snapshot.isDragging ? "box-shadow-4" : "box-shadow-none"}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style }}
          >
            <Accordion.Toggle eventKey={"toggle-person"} className="cursor-pointer p-14px bg-white h-0px-mn" as={Card.Header}>
              <div className="d-flex flex-stack w-100">
                <div className="d-flex align-items-center gap-2">
                  <i className="bi bi-people side-pannel-card-header-color"></i>
                  <span className="fs-6 m-0 side-pannel-card-header-color">Person</span>
                  <i className={`side-panel-data-icon fas fa-sm fa-chevron-${isOpen ? "up" : "down"} ml-2 side-pannel-card-header-color`}></i>
                </div>
                <div className="d-flex align-items-center gap-2" onClick={(e) => e.stopPropagation()}>
                  {props.isSecondaryModule && (
                    <div className="hide-dropdonw-icon" ref={personTarget}>
                      <Dropdown
                        onToggle={(nextShow) => {
                          if (nextShow) closeAllPopovers();
                        }}
                      >
                        <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle p-0" variant="transparent" id="dropdown-toggle-top">
                          <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddNewPeopleClick();
                            }}
                          >
                            Create Person
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              setPersonShow(true);
                            }}
                          >
                            Change to Existing Person
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  <i onClick={(e) => e.stopPropagation()} className="fa fa-bars fa-md" aria-hidden="true" {...provided.dragHandleProps}></i>
                  <Overlay target={personTarget.current} show={personShow} placement="right">
                    {(props1) => (
                      <Tooltip id="overlay-example" {...props1}>
                        {anotherPersonForm("person")}
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"toggle-person"}>
              <InlineStyled as={Card.Body} onClick={(e) => e.stopPropagation()} styles={{ padding: "1rem" }}>
                {!_.isEmpty(getPersonDetails()) ? (
                  <>
                    {" "}
                    <ViewMore
                      cellEdit={cellEdit}
                      setCellEdit={setCellEdit}
                      handleCellEdit={handleCellEdit}
                      addTag={addTag}
                      editTag={editTag}
                      deleteTag={deleteTag}
                      setEditingStatus={setEditing}
                      page={"People"}
                      pageSub={"people"}
                      showColumns={props.viewMore ? showColumns : viewLessColomns}
                      columns={fieldList}
                      idToIndex={fieldStore.idToIndex}
                      viewing={getPersonDetails()}
                      cellLoading={leadCellLoading || dealCellLoading || peopleCellLoading}
                      loadingData={loadingStore}
                    />
                    <div className="side-panel-data-item p-0 m-0">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setViewMore(!props.viewMore);
                        }}
                        variant="link"
                        className="p-0 pt-2 text-decoration-none"
                      >
                        {props.viewMore ? "View Less" : `See ${loadMoreFieldCount} More`}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div data-kt-search-element="empty" className="text-center">
                    <div className="pt-10 pb-10">
                      <span className="svg-icon svg-icon-4x opacity-50">
                        <i className="bi bi-people font-size-30px"></i>
                      </span>
                    </div>
                    <div className="pb-15 fw-bold">
                      <h3 className="text-gray-600 fs-5 mb-2">There are no person linked.</h3>
                      <div className="text-muted fs-7">
                        Person/Lead associated with the current deal will be displayed here. To link a lead/person to a deal, click on switch to another person or{" "}
                        <span
                          className="text-primary cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setPersonShow(true);
                          }}
                        >
                          here
                        </span>
                        .
                      </div>
                    </div>
                  </div>
                )}
              </InlineStyled>
            </Accordion.Collapse>
          </Accordion>
        )}
      </Draggable>
      <CustomForm
        show={showAddForm}
        setShow={setShowAddForm}
        handleAdd={handleAdd}
        addTag={addTag}
        editTag={editTag}
        deleteTag={deleteTag}
        page={"People"}
        pageSub={"people"}
        fields={peopleColumns.list}
        customFormData={addFormData}
        setCustomFormData={setAddFormData}
        fieldsLoading={peopleColumns.loading}
        propLoading={loading}
        cacheCompany={true}
      />
    </div>
  );
}
