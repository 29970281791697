export const LEADS_REQUEST = "LEADS_REQUEST";
export const LEADS_SUCCESS = "LEADS_SUCCESS";
export const LEADS_ERROR = "LEADS_ERROR";

export const LOADING_DATA_UPDATE = "LEAD_LOADING_DATA_UPDATE_REQUEST";

export const FIELD_UPDATE_REQUEST = "LEAD_FIELD_UPDATE_REQUEST";
export const FIELD_UPDATE_SUCCESS = "LEAD_FIELD_UPDATE_SUCCESS";
export const FIELD_UPDATE_ERROR = "LEAD_FIELD_UPDATE_ERROR";

export const FIELD_SHOWHIDE_REQUEST = "LEAD_FIELD_SHOWHIDE_REQUEST";
export const FIELD_SHOWHIDE_SUCCESS = "LEAD_FIELD_SHOWHIDE_SUCCESS";
export const FIELD_SHOWHIDE_ERROR = "LEAD_FIELD_SHOWHIDE_ERROR";

export const FIELD_UPDATEPRIORITY_REQUEST = "LEAD_FIELD_UPDATEPRIORITY_REQUEST";
export const FIELD_UPDATEPRIORITY_SUCCESS = "LEAD_FIELD_UPDATEPRIORITY_SUCCESS";
export const FIELD_UPDATEPRIORITY_ERROR = "LEAD_FIELD_UPDATEPRIORITY_ERROR";

export const LEAD_POPUP_REQUEST = "LEAD_POPUP_REQUEST";
export const LEAD_POPUP_SUCCESS = "LEAD_POPUP_SUCCESS";
export const LEAD_POPUP_ERROR = "LEAD_POPUP_ERROR";
export const LEAD_POPUP_REQUEST_AFTER = "LEAD_POPUP_REQUEST_AFTER";

export const LEAD_POPUP_EDIT_REQUEST = "LEAD_POPUP_EDIT_REQUEST";
export const LEAD_POPUP_EDIT_SUCCESS = "LEAD_POPUP_EDIT_SUCCESS";
export const LEAD_POPUP_EDIT_ERROR = "LEAD_POPUP_EDIT_ERROR";

export const LEAD_POPUP_UPDATE_REQUEST = "LEAD_POPUP_UPDATE_REQUEST";
export const LEAD_POPUP_UPDATE_SUCCESS = "LEAD_POPUP_UPDATE_SUCCESS";
export const LEAD_POPUP_UPDATE_ERROR = "LEAD_POPUP_UPDATE_ERROR";

export const LEAD_CONVERT_TO_JUNK_REQUEST = "LEAD_CONVERT_TO_JUNK_REQUEST";
export const LEAD_CONVERT_TO_JUNK_SUCCESS = "LEAD_CONVERT_TO_JUNK_SUCCESS";
export const LEAD_CONVERT_TO_JUNK_ERROR = "LEAD_CONVERT_TO_JUNK_ERROR";

export const LEAD_CONVERT_TO_ACTIVE_REQUEST = "LEAD_CONVERT_TO_ACTIVE_REQUEST";
export const LEAD_CONVERT_TO_ACTIVE_SUCCESS = "LEAD_CONVERT_TO_ACTIVE_SUCCESS";
export const LEAD_CONVERT_TO_ACTIVE_ERROR = "LEAD_CONVERT_TO_ACTIVE_ERROR";

export const LEAD_CONVERT_TO_PEOPLE_REQUEST = "LEAD_CONVERT_TO_PEOPLE_REQUEST";
export const LEAD_CONVERT_TO_PEOPLE_SUCCESS = "LEAD_CONVERT_TO_PEOPLE_SUCCESS";
export const LEAD_CONVERT_TO_PEOPLE_ERROR = "LEAD_CONVERT_TO_PEOPLE_ERROR";

export const LEADS_UPDATE_REQUEST = "LEADS_UPDATE_REQUEST";
export const LEADS_UPDATE_SUCCESS = "LEADS_UPDATE_SUCCESS";
export const LEADS_UPDATE_ERROR = "LEADS_UPDATE_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const LEADADD_REQUEST = "LEADADD_REQUEST";
export const LEADADD_SUCCESS = "LEADADD_SUCCESS";
export const LEADADD_ERROR = "LEADADD_ERROR";

export const LEADBULKEDIT_REQUEST = "LEADBULKEDIT_REQUEST";
export const LEADBULKEDIT_SUCCESS = "LEADBULKEDIT_SUCCESS";
export const LEADBULKEDIT_ERROR = "LEADBULKEDIT_ERROR";

export const LEADDELETE_REQUEST = "LEADDELETE_REQUEST";
export const LEADDELETE_SUCCESS = "LEADDELETE_SUCCESS";
export const LEADDELETE_ERROR = "LEADDELETE_ERROR";

export const LEADFIELDS_REQUEST = "LEADFIELDS_REQUEST";
export const LEADFIELDS_SUCCESS = "LEADFIELDS_SUCCESS";
export const LEADFIELDS_ERROR = "LEADFIELDS_ERROR";

export const LEADADDFIELDS_REQUEST = "LEADADDFIELDS_REQUEST";
export const LEADADDFIELDS_SUCCESS = "LEADADDFIELDS_SUCCESS";
export const LEADADDFIELDS_ERROR = "LEADADDFIELDS_ERROR";

export const LEADTAGS_REQUEST = "LEADTAGS_REQUEST";
export const LEADTAGS_SUCCESS = "LEADTAGS_SUCCESS";
export const LEADTAGS_ERROR = "LEADTAGS_ERROR";

export const LEADTAGSADD_REQUEST = "LEADTAGSADD_REQUEST";
export const LEADTAGSADD_SUCCESS = "LEADTAGSADD_SUCCESS";
export const LEADTAGSADD_ERROR = "LEADTAGSADD_ERROR";

export const LEADTAGSEDIT_REQUEST = "LEADTAGSEDIT_REQUEST";
export const LEADTAGSEDIT_SUCCESS = "LEADTAGSEDIT_SUCCESS";
export const LEADTAGSEDIT_ERROR = "LEADTAGSEDIT_ERROR";

export const LEADTAGSDELETE_REQUEST = "LEADTAGSDELETE_REQUEST";
export const LEADTAGSDELETE_SUCCESS = "LEADTAGSDELETE_SUCCESS";
export const LEADTAGSDELETE_ERROR = "LEADTAGSDELETE_ERROR";

export const LEADCELLEDIT_REQUEST = "LEADCELLEDIT_REQUEST";
export const LEADCELLEDIT_SUCCESS = "LEADCELLEDIT_SUCCESS";
export const LEADCELLEDIT_ERROR = "LEADCELLEDIT_ERROR";

export const LEADFILTERS_REQUEST = "LEADFILTERS_REQUEST";
export const LEADFILTERS_SUCCESS = "LEADFILTERS_SUCCESS";
export const LEADFILTERS_ERROR = "LEADFILTERS_ERROR";

export const LEADFILTERADD_REQUEST = "LEADFILTERADD_REQUEST";
export const LEADFILTERADD_SUCCESS = "LEADFILTERADD_SUCCESS";
export const LEADFILTERADD_ERROR = "LEADFILTERADD_ERROR";

export const LEADFILTEREDIT_REQUEST = "LEADFILTEREDIT_REQUEST";
export const LEADFILTEREDIT_SUCCESS = "LEADFILTEREDIT_SUCCESS";
export const LEADFILTEREDIT_ERROR = "LEADFILTEREDIT_ERROR";

export const LEADFILTERFAVORITE_REQUEST = "LEADFILTERFAVORITE_REQUEST";
export const LEADFILTERFAVORITE_SUCCESS = "LEADFILTERFAVORITE_SUCCESS";
export const LEADFILTERFAVORITE_ERROR = "LEADFILTERFAVORITE_ERROR";

export const LEADFILTERDELETE_REQUEST = "LEADFILTERDELETE_REQUEST";
export const LEADFILTERDELETE_SUCCESS = "LEADFILTERDELETE_SUCCESS";
export const LEADFILTERDELETE_ERROR = "LEADFILTERDELETE_ERROR";

export const LEADSMERGE_REQUEST = "LEADSMERGE_REQUEST";
export const LEADSMERGE_SUCCESS = "LEADSMERGE_SUCCESS";
export const LEADSMERGE_ERROR = "LEADSMERGE_ERROR";

export const APPLYFILTER = "APPLYLEADFILTER";
export const CLEARFILTER = "CLEARLEADFILTER";

export const SIGNATURE_GET_REQUEST = "SIGNATURE_GET_REQUEST";
export const SIGNATURE_GET_SUCCESS = "SIGNATURE_GET_SUCCESS";
export const SIGNATURE_GET_ERROR = "SIGNATURE_GET_ERROR";

export const SIGNATURE_ADD_REQUEST = "SIGNATURE_ADD_REQUEST";
export const SIGNATURE_ADD_SUCCESS = "SIGNATURE_ADD_SUCCESS";
export const SIGNATURE_ADD_ERROR = "SIGNATURE_ADD_ERROR";

export const SIGNATURE_EDIT_REQUEST = "SIGNATURE_EDIT_REQUEST";
export const SIGNATURE_EDIT_SUCCESS = "SIGNATURE_EDIT_SUCCESS";
export const SIGNATURE_EDIT_ERROR = "SIGNATURE_EDIT_ERROR";

export const SIGNATURE_DELETE_REQUEST = "SIGNATURE_DELETE_REQUEST";
export const SIGNATURE_DELETE_SUCCESS = "SIGNATURE_DELETE_SUCCESS";
export const SIGNATURE_DELETE_ERROR = "SIGNATURE_DELETE_ERROR";

export const CLEAR_SIGNATURE_STATUS = "CLEAR_SIGNATURE_STATUS";

export const NOTE_ADD_REQUEST = "NOTE_ADD_REQUEST";
export const NOTE_ADD_SUCCESS = "NOTE_ADD_SUCCESS";
export const NOTE_ADD_ERROR = "NOTE_ADD_ERROR";

export const CLEAR_NOTE_STATUS = "CLEAR_NOTE_STATUS";

export const SEARCH_ALL_REQUEST = "SEARCH_ALL_REQUEST";
export const SEARCH_ALL_SUCCESS = "SEARCH_ALL_SUCCESS";
export const SEARCH_ALL_ERROR = "SEARCH_ALL_ERROR";

export const GET_LEADLOSTREASONS_REQUEST = "GET_LEADLOSTREASONS_REQUEST";
export const GET_LEADLOSTREASONS_SUCCESS = "GET_LEADLOSTREASONS_SUCCESS";
export const GET_LEADLOSTREASONS_ERROR = "GET_LEADLOSTREASONS_ERROR";

export const bulkEditLeadV2 = {
  BULK_EDIT_LEAD_V2_REQUEST: "BULK_EDIT_LEAD_V2_REQUEST",
  BULK_EDIT_LEAD_V2_SUCCESS: "BULK_EDIT_LEAD_V2_SUCCESS",
  BULK_EDIT_LEAD_V2_ERROR: "BULK_EDIT_LEAD_V2_ERROR",
};
export const bulkDeleteLeadV2 = {
  BULK_DELETE_LEAD_V2_REQUEST: "BULK_DELETE_LEAD_V2_REQUEST",
  BULK_DELETE_LEAD_V2_SUCCESS: "BULK_DELETE_LEAD_V2_SUCCESS",
  BULK_DELETE_LEAD_V2_ERROR: "BULK_DELETE_LEAD_V2_ERROR",
};

export const generateLeadAIWFData = {
  GENERATE_LEAD_AIWF_DATA_REQUEST: "GENERATE_LEAD_AIWF_DATA_REQUEST",
  GENERATE_LEAD_AIWF_DATA_SUCCESS: "GENERATE_LEAD_AIWF_DATA_SUCCESS",
  GENERATE_LEAD_AIWF_DATA_ERROR: "GENERATE_LEAD_AIWF_DATA_ERROR"
}

export const getVariableSupportedData = {
  GET_VARIABLE_SUPPORTED_DATA_REQUEST: "GET_VARIABLE_SUPPORTED_DATA_REQUEST",
  GET_VARIABLE_SUPPORTED_DATA_SUCCESS: "GET_VARIABLE_SUPPORTED_DATA_SUCCESS",
  GET_VARIABLE_SUPPORTED_DATA_ERROR: "GET_VARIABLE_SUPPORTED_DATA_ERROR"
}