import React, { useEffect, useState } from "react";
import _ from "lodash";
import { DeleteIconButton, EditIconButton } from "../../../../../../components/button/icon-action-buttons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ABVarientSettings from "../../ab-varient-settings";
import CustomSwitch from "../../../../../../components/custom_form/switch";
import { abTestLabelMapping } from "../../../constants";

export default function EmailViewCard(props) {
  const [showAddAB, setShowAddAB] = useState(true);
  const [isABVarientStep, setIsABVarientStep] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(props.step)) {
      if (!_.isEmpty(props.step.abTesting) && props.step.abTesting.emailContent.length > 1) {
        setIsABVarientStep(true);
        if (props.step.abTesting.emailContent.length > 3) {
          setShowAddAB(false);
        }
      } else {
        setIsABVarientStep(false);
        setShowAddAB(true);
      }
    }
  }, [props.step]);

  const handleAbTestUpdate = (data) => {
    const stepClone = _.cloneDeep(props.step);
    let payload = { ...stepClone, abTesting: { ...stepClone.abTesting, ...data } };

    props.handleStepUpdate(props.index, payload);
  };

  const getPayloadForAbTestStatusUpdate = (status, index) => {
    const stepClone = _.cloneDeep(props.step);
    stepClone.abTesting.emailContent[index].isActive = status;

    return stepClone;
  };

  return (
    <div className="template-content-block w-100 d-flex flex-column">
      {!isABVarientStep ? (
        <div className={`card px-4 py-2 w-100 mt-4 ${props.step.isEnabled ? "" : "bg-light-light"}`}>
          <div className=" d-flex w-100 justify-content-between align-items-center">
            <div className="w-100-120px">
              {props.step.emailContent?.name} - {props.step.emailContent?.subject}
            </div>
            <div className="w-120px step-actions d-flex justify-content-end p-0 m-0">
              <EditIconButton onClick={() => props.onEditClick(props.index, props.step)} />
              <DeleteIconButton onClick={() => props.onDeleteClick(props.index)} />
            </div>
          </div>
        </div>
      ) : (
        props.step.abTesting &&
        props.step.abTesting.emailContent.map((subStep, subStepIndex) => (
          <div className="d-flex gap-2  w-100 flex-center mt-4 mb-2" key={subStepIndex}>
            <div className="w-30px h-30px d-flex flex-center bg-light-primary rounded-pill">{abTestLabelMapping[subStepIndex]}</div>
            <div className={`card px-4 py-2 w-100 ${props.step.isEnabled ? "" : "bg-light-light"}`}>
              <div className=" d-flex w-100 justify-content-between align-items-center">
                <div className="d-flex gap-3 w-100-120px">
                  <CustomSwitch
                    id={`sub-step-${props.step._id}-${subStepIndex}`}
                    label=""
                    checked={subStep.isActive}
                    handleChange={(e) => props.handleStepUpdate(props.index, getPayloadForAbTestStatusUpdate(e.target.checked, subStepIndex))}
                  />
                  {subStep.name} - {subStep.subject}
                </div>
                <div className="w-120px step-actions d-flex justify-content-end p-0 m-0">
                  <EditIconButton onClick={() => props.onSubStepEditClick(props.index, props.step, subStepIndex)} />
                  <DeleteIconButton onClick={() => props.onSubStepDeleteClick(props.index, subStepIndex)} />
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      <div className="d-flex w-100 gap-4 mt-2 mb-8">
        {showAddAB && props.isBusinessPlusUser && (
          <OverlayTrigger placement="right" overlay={<Tooltip>Split-test email variants to find the email subject line or body copy that gets the best response.</Tooltip>}>
            <Button disabled={props.step?.emailContent?.emailSendType === "MANUAL"} onClick={() => props.handleABVarientAddClick(props.index, props.step)} className="p-0 text-decoration-none" variant="link">
              + Add AB Variant
            </Button>
          </OverlayTrigger>
        )}
        {isABVarientStep && props.isBusinessPlusUser && <ABVarientSettings abTesting={props.step.abTesting} handleSave={(data) => handleAbTestUpdate(data, "settings")} />}
      </div>
    </div>
  );
}
