import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { getCadenceListRequest } from "../../../cadence/store/actions/cadence_actions";
import { GreenGradientButton } from "../../../../../components/button";
import _ from "lodash";
import { dispatchSnackbarError } from "../../../../../utils/toaster";

export default function SequenceFrom(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({});

  const cadenceListStore = useSelector((store) => store.cadence.list);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCadenceListRequest({ limit: 100, page: 1, searchText: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActionData) && props.selectedActionData.id) {
      setValue({ label: props.selectedActionData.name, value: props.selectedActionData.id });
    }
  }, [props.selectedActionData, props.isEdit]);

  useEffect(() => {
    if (cadenceListStore.data && Array.isArray(cadenceListStore.data)) {
      const _options = cadenceListStore.data.map((each) => {
        return {
          value: each._id,
          label: each.name,
        };
      });
      setOptions(_options);
    }
  }, [cadenceListStore.data]);

  const handleDropdownValue = (data) => {
    setValue(data);
  };

  const handleSave = () => {
    if (_.isEmpty(value)) {
      return dispatchSnackbarError("Cadence field can't be empty");
    }
    if (props.isEdit) props.handleEdit({ id: value.value, name: value.label });
    else props.handleSave({ id: value.value, name: value.label });
  };

  return (
    <Form.Group id="automation-forms-sequence-form">
      <Form.Group id="automation-forms-sequence-form-select-cadence">
        <Form.Label aria-label="cadence">Cadence</Form.Label>
        <SimpleDropdown value={value} label={"Select Cadence"} options={options} handleSelect={handleDropdownValue} />
      </Form.Group>
      <Form.Group id="automation-forms-sequence-form-save-button" className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={() => handleSave()} disabled={false}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </Form.Group>
  );
}
