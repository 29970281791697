import { getListOfEmailFinder, createEmailFinder, getEmailFinderPricing } from "../types/email-finder.types";

export const getListOfEmailFinderRequest = (payload,cb)=>({
    type: getListOfEmailFinder.REQUEST,
    payload,
    cb
})
export const getListOfEmailFinderSuccess = (payload)=>({
    type: getListOfEmailFinder.SUCCESS,
    payload
})
export const getListOfEmailFinderFailure = (payload)=>({
    type: getListOfEmailFinder.FAILURE,
    payload
})

export const createEmailFinderRequest = (payload,cb)=>({
    type: createEmailFinder.REQUEST,
    payload,
    cb
})
export const createEmailFinderSuccess = (payload)=>({
    type: createEmailFinder.SUCCESS,
    payload
})
export const createEmailFinderFailure = (payload)=>({
    type: createEmailFinder.FAILURE,
    payload
})


export const getEmailFinderPricingRequest = (payload,cb)=>({
    type: getEmailFinderPricing.REQUEST,
    payload,
    cb
})
export const getEmailFinderPricingSuccess = (payload)=>({
    type: getEmailFinderPricing.SUCCESS,
    payload
})
export const getEmailFinderPricingFailure = (payload)=>({
    type: getEmailFinderPricing.FAILURE,
    payload
})
