import {
  GET_BILLING_PRICES_REQUEST,
  GET_BILLING_PRICES_SUCCESS,
  GET_BILLING_PRICES_ERROR,
  BILLING_PLAN_CHECKOUT_REQUEST,
  BILLING_PLAN_CHECKOUT_SUCCESS,
  BILLING_PLAN_CHECKOUT_ERROR,
  BILLING_CREATE_CUSTOMER_REQUEST,
  BILLING_CREATE_CUSTOMER_SUCCESS,
  BILLING_CREATE_CUSTOMER_ERROR,
  BILLING_CUSTOMER_PORTAL_REQUEST,
  BILLING_CUSTOMER_PORTAL_SUCCESS,
  BILLING_CUSTOMER_PORTAL_ERROR,
  GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST,
  GET_BILLING_SUBSCRIPTIONDETAILS_SUCCESS,
  GET_BILLING_SUBSCRIPTIONDETAILS_ERROR,
  GET_ACCOUNT_BILLING_REQUEST,
  GET_ACCOUNT_BILLING_SUCCESS,
  GET_ACCOUNT_BILLING_ERROR,
  GET_ACCOUNT_BILLING_REQUEST_AFTER,
  GET_INVOICESLIST_REQUEST,
  GET_INVOICESLIST_SUCCESS,
  GET_INVOICESLIST_ERROR,
  GET_INVOICESLIST_REQUEST_AFTER,
  CANCEL_RENEW_SUBSCRIPTION_REQUEST,
  CANCEL_RENEW_SUBSCRIPTION_SUCCESS,
  CANCEL_RENEW_SUBSCRIPTION_ERROR,
  asssignFreePlan,
} from "../types/subscription_billing_types";

//Cncel or renew subscription

export function cancelRenewSubscriptionRequest(req) {
  return {
    type: CANCEL_RENEW_SUBSCRIPTION_REQUEST,
    payload: req,
  };
}
export function cancelRenewSubscriptionSuccess(res) {
  return {
    type: CANCEL_RENEW_SUBSCRIPTION_SUCCESS,
    payload: res,
  };
}
export function cancelRenewSubscriptionError(err) {
  return {
    type: CANCEL_RENEW_SUBSCRIPTION_ERROR,
    payload: err,
  };
}

//INVOICE LIST

export function getInvoiceListRequest(req) {
  return {
    type: GET_INVOICESLIST_REQUEST,
    payload: req,
  };
}
export function getInvoiceListRequestAfter(req) {
  return {
    type: GET_INVOICESLIST_REQUEST_AFTER,
    payload: req,
  };
}
export function getInvoiceListSuccess(res) {
  return {
    type: GET_INVOICESLIST_SUCCESS,
    payload: res,
  };
}
export function getInvoiceListError(err) {
  return {
    type: GET_INVOICESLIST_ERROR,
    payload: err,
  };
}

//GET ACCOUNT WISE BILLING

export function getAccountBillingRequest(cb) {
  return {
    type: GET_ACCOUNT_BILLING_REQUEST,
    // payload: req
    cb,
  };
}
export function getAccountBillingSuccess(res) {
  return {
    type: GET_ACCOUNT_BILLING_SUCCESS,
    payload: res,
  };
}
export function getAccountBillingError(err) {
  return {
    type: GET_ACCOUNT_BILLING_ERROR,
    payload: err,
  };
}
export function getAccountBillingRequestAfter(db) {
  return {
    type: GET_ACCOUNT_BILLING_REQUEST_AFTER,
    db,
  };
}

export function getBillingPricesRequest(req) {
  return {
    type: GET_BILLING_PRICES_REQUEST,
    payload: req,
  };
}

export function getBillingPricesSuccess(res) {
  return {
    type: GET_BILLING_PRICES_SUCCESS,
    payload: res,
  };
}

export function getBillingPricesError(err) {
  return {
    type: GET_BILLING_PRICES_ERROR,
    payload: err,
  };
}

export function billingPlanCheckoutRequest(req, cb) {
  return {
    type: BILLING_PLAN_CHECKOUT_REQUEST,
    payload: req,
    cb,
  };
}

export function billingPlanCheckoutSuccess(res) {
  return {
    type: BILLING_PLAN_CHECKOUT_SUCCESS,
    payload: res,
  };
}

export function billingPlanCheckoutError(err) {
  return {
    type: BILLING_PLAN_CHECKOUT_ERROR,
    payload: err,
  };
}

export function billingCreateCustomerRequest(req) {
  return {
    type: BILLING_CREATE_CUSTOMER_REQUEST,
    payload: req,
  };
}

export function billingCreateCustomerSuccess(res) {
  return {
    type: BILLING_CREATE_CUSTOMER_SUCCESS,
    payload: res,
  };
}

export function billingCreateCustomerError(err) {
  return {
    type: BILLING_CREATE_CUSTOMER_ERROR,
    payload: err,
  };
}

export function billingCustomerPortalRequest(cb) {
  return {
    type: BILLING_CUSTOMER_PORTAL_REQUEST,
    // payload: req,
    cb,
  };
}

export function billingCustomerPortalSuccess(res) {
  return {
    type: BILLING_CUSTOMER_PORTAL_SUCCESS,
    payload: res,
  };
}

export function billingCustomerPortalError(err) {
  return {
    type: BILLING_CUSTOMER_PORTAL_ERROR,
    payload: err,
  };
}

export function getBillingSubscriptionDetailsRequest(req) {
  return {
    type: GET_BILLING_SUBSCRIPTIONDETAILS_REQUEST,
    payload: req,
    // cb
  };
}

export function getBillingSubscriptionDetailsSuccess(res) {
  return {
    type: GET_BILLING_SUBSCRIPTIONDETAILS_SUCCESS,
    payload: res,
  };
}

export function getBillingSubscriptionDetailsError(err) {
  return {
    type: GET_BILLING_SUBSCRIPTIONDETAILS_ERROR,
    payload: err,
  };
}

export const asssignFreePlanRequest = (req, cb) => ({
  type: asssignFreePlan.ASSIGN_FREE_PLAN_REQUEST,
  payload: req,
  cb,
});

export const asssignFreePlanSuccess = (res) => ({
  type: asssignFreePlan.ASSIGN_FREE_PLAN_SUCCESS,
  payload: res,
});

export const asssignFreePlanError = (err) => ({
  type: asssignFreePlan.ASSIGN_FREE_PLAN_ERROR,
  payload: err,
});
