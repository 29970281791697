import React, { useEffect, useState } from "react";
import validator from "validator";
import { InputLimit } from "../../../../../../constants";
import AuthWrapper from "../../componets/auth-wrapper";
import * as actions from "../../../actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../../utils/toaster";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [showSubmission, setShowSubmission] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // forgot pass step 2
  const [isCodePresent, setIsCodePresent] = useState(false);
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      const codeParams = new URLSearchParams(location.search).get("token");
      if (codeParams) {
        setToken(codeParams);
        setIsCodePresent(true);
      }
    }
  }, [location]);

  const handleResetMyPasswordClick = () => {
    if (!validator.isEmail(email)) {
      return dispatchSnackbarError("Please enter valid email address");
    }
    dispatch(
      actions.ForgotPassRequest(email, (data) => {
        if (data["success"]) {
          setShowSubmission(true);
          setErrorMessage("");
        } else setErrorMessage(data.message);
      }),
    );
  };

  const onPasswordResetClick = () => {
    if (!password) {
      setErrorMessage("Please Enter Password");
    } else if (!/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(password)) {
      return setErrorMessage("Password should contains minimum eight characters, at least one letter and one number");
    } else if (!password1) {
      setErrorMessage("Please Enter Confirm Password");
    } else {
      if (password === password1) {
        dispatch(
          actions.forgetPassPostRequest({ token: token, password: password }, token, (data) => {
            if (data["success"]) {
              window.location.href = "/auth/login";
              dispatchSnackbarSuccess("Password Successfully Updated", "success");
            } else {
              if (typeof (data["message"] === "object")) {
                setErrorMessage("Your Token is Not Valid");
              } else {
                setErrorMessage(data["message"]);
              }
            }
          }),
        );
      } else {
        setErrorMessage("Password and Confirm Password are not Match");
      }
    }
  };

  return (
    <AuthWrapper>
      {!isCodePresent ? (
        <>
          <h5 class="text-black">Password recovery</h5>
          <div class="text-grey text-medium mb-30">Please enter the email address you used to sign up for Sales Simplify, and we’ll send you a link to reset your password.</div>
          <div class="w-form">
            {!showSubmission && (
              <div id="wf-form-Reset-Password-2" name="wf-form-Reset-Password-2" data-name="Reset Password" method="get" aria-label="Reset Password">
                <div class="form-input-group">
                  <label for="Reset-Password-Email" class="form-label grey">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="input white bordered w-input"
                    maxlength={InputLimit.EMAIL}
                    name="Reset-Password-Email"
                    data-name="Reset Password Email"
                    placeholder="Enter your email"
                    id="Reset-Password-Email"
                  />
                </div>
                {errorMessage && (
                  <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
                    <div>Oops! {errorMessage}.</div>
                  </div>
                )}
                <button onClick={handleResetMyPasswordClick} data-wait="Please wait..." class="button primary-invert medium block mb-40 _2 w-button">
                  Reset my password
                </button>
                <div class="text-black text-small">
                  <a href="/auth/login" class="link-primary-dark text-semi _1">
                    &lt; Back to login
                  </a>
                </div>
              </div>
            )}
            {showSubmission && (
              <div class="form-success light w-form-done" role="region" aria-label="Reset Password success">
                <div>We've sent you an email with a link to reset your password.</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <h5 class="text-black">Reset Password</h5>
          <div class="text-grey text-medium mb-30">To reset your password, kindly complete the required information.</div>
          <div class="w-form">
            {!showSubmission && (
              <div id="wf-form-Reset-Password-3" name="wf-form-Reset-Password-3" data-name="Reset Password" method="get" aria-label="Reset Password">
                <div class="form-input-group">
                  <label for="Reset-New-Password" class="form-label grey">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    class="input white bordered w-input"
                    maxlength={InputLimit.PASSWORD}
                    name="Reset-New-Password"
                    data-name="Reset New Password"
                    placeholder="Enter New Password"
                    id="Reset-New-Password"
                  />
                </div>
                <div class="form-input-group">
                  <label for="Reset-Confirm-Password" class="form-label grey">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    class="input white bordered w-input"
                    maxlength={InputLimit.PASSWORD}
                    name="Reset-Password-Confirm"
                    data-name="Reset Confirm Password"
                    placeholder="Enter Confirm Password"
                    id="Reset-Confirm-Password"
                  />
                </div>

                {errorMessage && (
                  <div class="error-message w-form-fail mb-7" role="region" aria-label="Reset Password failure">
                    <div>{errorMessage}.</div>
                  </div>
                )}
                <button onClick={onPasswordResetClick} data-wait="Please wait..." class="button primary-invert medium block mb-40 _2 w-button">
                  Reset Password
                </button>
                <div class="text-black text-small">
                  <a href="/auth/login" class="link-primary-dark text-semi _1">
                    &lt; Back to login
                  </a>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </AuthWrapper>
  );
}
