import { getListOfBlockContacts, createBlockContact, deleteBlockContact, editBlockContact } from "../types/campaign_block_contact_type";

export const getListOfBlockContactsRequest = (payload, cb) => ({
  type: getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_REQUEST,
  payload,
  cb,
});
export const getListOfBlockContactsSuccess = (payload) => ({
  type: getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_SUCCESS,
  payload,
});
export const getListOfBlockContactsError = (payload) => ({
  type: getListOfBlockContacts.GET_LIST_OF_BLOCK_CONTACTS_ERROR,
  payload,
});

export const createBlockContactRequest = (payload, cb) => ({
  type: createBlockContact.CREATE_BLOCK_CONTACT_REQUEST,
  payload,
  cb,
});
export const createBlockContactSuccess = (payload) => ({
  type: createBlockContact.CREATE_BLOCK_CONTACT_SUCCESS,
  payload,
});
export const createBlockContactError = (payload) => ({
  type: createBlockContact.CREATE_BLOCK_CONTACT_ERROR,
  payload,
});

export const editBlockContactRequest = (payload, cb) => ({
  type: editBlockContact.EDIT_BLOCK_CONTACT_REQUEST,
  payload,
  cb,
});
export const editBlockContactSuccess = (payload) => ({
  type: editBlockContact.EDIT_BLOCK_CONTACT_SUCCESS,
  payload,
});
export const editBlockContactError = (payload) => ({
  type: editBlockContact.EDIT_BLOCK_CONTACT_ERROR,
  payload,
});

export const deleteBlockContactRequest = (payload, cb) => ({
  type: deleteBlockContact.DELETE_BLOCK_CONTACT_REQUEST,
  payload,
  cb,
});
export const deleteBlockContactSuccess = (payload) => ({
  type: deleteBlockContact.DELETE_BLOCK_CONTACT_SUCCESS,
  payload,
});
export const deleteBlockContactError = (payload) => ({
  type: deleteBlockContact.DELETE_BLOCK_CONTACT_ERROR,
  payload,
});
