import _ from "lodash";
import React, { useState } from "react";
import { sendTypeNameMapping, StepChannelColorMapping } from "../../constants";

import "./styles.scss";

const secondsToDhm = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return { d: d, h: h, m: m };
};

export default function CadenceTemplateStep2(props) {
  const [showPreview, setShowPreview] = useState({ "0": true });

  const getDisplayDay = (index) => {
    if (_.isEmpty(props.selectedTemplate)) return 0;
    let seconds = 0;
    const steps = props.selectedTemplate.steps;
    for (let i = 0; i < steps.length; i++) {
      if (i <= index) {
        seconds = seconds + steps[i].delay;
      }
    }
    const { d } = secondsToDhm(seconds);
    return d + 1;
  };

  return (
    <div className="create-cadence-template-step2-wrapper">
      <div className="section-1">
        {props.list.map((each, index) => {
          return (
            <div className={props.selectedTemplate && props.selectedTemplate._id === each._id ? "selected section-1-list" : "section-1-list"} onClick={() => props.setSelectedTemplate(each)} key={index}>
              {_.capitalize(each.name)}
            </div>
          );
        })}
      </div>
      <div className="section-2">
        {props.selectedTemplate && (
          <>
            <div className="cadence-name"> {props.selectedTemplate.name}</div>
            <div className="summary-wrapper">
              <div>
                {props.selectedTemplate.steps.length} {props.selectedTemplate.steps.length > 1 ? "Steps" : "Step"}{" "}
              </div>
              <div>|</div>
              <div>
                {getDisplayDay(props.selectedTemplate.steps.length - 1)} {getDisplayDay(props.selectedTemplate.steps.length - 1) === 1 ? " Day in Sequence" : " Days in Sequence"}
              </div>
            </div>
            <div className="timeline details">
              {props.selectedTemplate.steps.map((each, index) => {
                const stepHeadingText = (() => {
                  const emailSendType = each.emailContent?.emailSendType || each.abTesting?.emailContent[0]?.emailSendType;
                  if (each.channelType === "EMAIL") return `${sendTypeNameMapping[emailSendType]} ${each.channelType} ${each.isAbTestingEnabled ? "with AB testing" : ""}`;
                  if (each.channelType === "SMS") return `${sendTypeNameMapping[each.smsContent.smsSendType]} ${each.channelType}`;
                  if (each.channelType === "TASK") return "Manual Activity";
                  return "";
                })();

                return (
                  <div className="timeline-item" key={index}>
                    <div className="timeline-line w-30px custom-timeline-line"></div>
                    <div className="timeline-icon symbol symbol-circle symbol-30px me-4">
                      <div className={`symbol-label ${StepChannelColorMapping[each.channelType].bg}`}>
                        <i className={`${StepChannelColorMapping[each.channelType].icon} ${StepChannelColorMapping[each.channelType].color} font-size-12px`}></i>
                      </div>
                    </div>
                    <div className="timeline-content mb-4 mt-n1">
                      <div className="automatic-preview-wrapper">
                        <div className="automatic-text">{stepHeadingText}</div>
                        <div className="preview-text" onClick={() => setShowPreview({ ...showPreview, [index]: !showPreview[index] })}>
                          {!showPreview[index] ? <i class="bi bi-chevron-down text-primary"></i> : <i class="bi bi-chevron-up text-primary"></i>}
                          Preview
                        </div>
                      </div>
                      <div className="day-wrapper">
                        {getDisplayDay(index)} {getDisplayDay(index) === 1 ? " Day" : " Days"}
                      </div>
                      {showPreview[index] && (
                        <div className="step-content">
                          {each.channelType === "EMAIL" ? (
                            <div className="d-flex flex-column gap-2">
                              {(each.isAbTestingEnabled ? each.abTesting.emailContent : [each.emailContent]).map((emailContent) => (
                                <div className="card p-4">
                                  <div className="subject-line">{emailContent.subject}</div>
                                  <div dangerouslySetInnerHTML={{ __html: emailContent.content }} />
                                </div>
                              ))}
                            </div>
                          ) : each.channelType === "SMS" ? (
                            <div className="card p-4">
                              {/* smsSendType */}
                              {/* emailSendType */}
                              <div className="subject-line">{each.name}</div>
                              <div>{each.smsContent.message}</div>
                            </div>
                          ) : each.channelType === "TASK" ? (
                            <div className="card p-4">
                              <div className="subject-line">{each.activityContent.activityTitle}</div>
                              <div>{each.activityContent.description}</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
