import React, { useState, useEffect, useCallback } from "react";
import { EditableTable } from "../../components/table";
import { Spinner } from "react-bootstrap";
import { ColumnFilter } from "../../components/column_filter";
import { DeleteModal, ConvertModal } from "../../components/modal";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton, GreenGradientButton } from "../../components/button";
import { NavLink, useHistory } from "react-router-dom";
import * as actions from "./store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "../../components/custom_form";
import BulkEditForm from "../../components/custom_form/bulkEdit";
// import { SubheaderNav } from "../../components/subheader/subheaderNav";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { MergeModal } from "../../components/merge";
import { useParams } from "react-router-dom";
import ContentWrapper from "../Wrapper";
import SmartViewFilter from "../../components/filter/smart-view-filter";
import OwnerFilter from "../../components/filter/owner-filter";
import TagSelect from "../../components/custom_form/tags";
import _ from "lodash";
import SidePannelDetailed from "../../components/side-pannel-detailed";

//actions
import * as LeadActions from "../leads/store/actions/actions";
import * as PeopleOperationActions from "./store/actions/people-operations.actions";
import * as CompanyActions from "../companies/store/actions/actions";
import * as ProductActions from "../products/store/actions/actions";
import * as insightActions from "../insights/actions";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { DeleteIconButton } from "../../components/button/icon-action-buttons";
import { SecondaryButton } from "../../components/button/common-buttons";
import * as TemplateActions from "../campaign/store/actions/campaign_templates_actions";
import OptionsDropdown from "../../components/filter/import-export-dropdown";
import SelectAll from "../../components/filter/select-all";
import AdvancedSubHeaderNav from "../../components/subheader/advancedSubHeaderNav";
import { SecondarySimpleDropdown } from "../../components/dropdowns/simple-dropdown";
import SubscribeToCadenceModal from "../../components/popup-forms/subscribe-to-cadence";
import EmailValidationModel from "../../components/popup-forms/email-validation";
import EmailFinderModel from "../../components/popup-forms/email-finder";

export default function Customers(props) {
  const sidePanelType = "person";
  const links = [
    {
      name: "Active Leads",
      to: "/prospects/leads/active",
      type: "split-dropdown",
      icon: "bi bi-bullseye",
      options: [
        { name: "Active Leads", to: "/prospects/leads/active", target: "_self", icon: "bi bi-bullseye" },
        { name: "Junk Leads", to: "/prospects/leads/junk", target: "_self", icon: "bi bi-recycle" },
      ],
      subTabNames: ["Active Leads", "Junk Leads"],
    },
    { name: "People", to: "/prospects/people", icon: "bi bi-person", type: "text" },
    { name: "Companies", to: "/prospects/companies", icon: "bi bi-building", type: "text" },
  ];
  const tabName = "People";
  const tabIcon = "bi bi-person";
  const [showColumns, setShowColumns] = useState([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const popupStore = useSelector((state) => state.People.peoplePopup);
  const fieldStore = useSelector((state) => state.People.peopleFields);
  const addFieldStore = useSelector((state) => state.People.peopleAddFields);
  const filterStore = useSelector((state) => state.People.peopleFilters);
  const activeFilterStore = useSelector((state) => state.People.peopleFilters.activeFilter);
  const listStore = useSelector((state) => state.People.peopleList);
  const errorStore = useSelector((state) => state.People.error);
  const successStore = useSelector((state) => state.People.success);
  const loadingStore = useSelector((state) => state.People.loadingData);
  const [addFormData, setAddFormData] = useState({});
  const [bulkEditData, setBulkEditData] = useState({});
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const page = "People";
  const pageSub = "people";
  const [showDelete, setShowDelete] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "people",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
  });
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [showConvertPopup, setShowConvertPopup] = useState(false);

  //filters
  const [virtualFilter, setVirtualFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");
  const [filterId, setFilterId] = useState("");

  //sidepannel states
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePannelPeopleId, setSidePannelPeopleId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [linkedModuleId, setLinkedModuleId] = useState("");
  const [linkedModuleName, setLinkedModuleName] = useState("");

  // delete all
  const [isAllSelected, setIsAllSelected] = useState(false);

  // email validation and email findr
  const [showEmailValidation, setShowEmailValidation] = useState(false);
  const [showEmailFinder, setShowEmailFinder] = useState(false);
  const [showSubscribeToCadence, setShowSubscribeToCadence] = useState(false);


  const params = useParams();
  const history = useHistory();

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      limit: limit,
      type: "people",
      tagId: tags,
      owner: owner,
      filterId: filterId,
      ...sortKeys(),
    };
    if (!_.isEmpty(virtualFilter)) {
      payload["filter"] = JSON.stringify(virtualFilter);
    }
    dispatch(actions.peoplesRequest(payload));
    dispatch(actions.loadingDataUpdateRequest(payload));
  }, [dispatch, pageNo, limit, tags, owner, virtualFilter, filterId, sortKeys]);

  useEffect(() => {
    dispatch(actions.peopleTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(actions.ownersRequest());
    dispatch(actions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(actions.peopleAddFieldsRequest({ fieldType: "PERSON" }));
    dispatch(actions.peopleFiltersRequest());
    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    //common api Calls
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!listStore.loading && listStore.list.length === 0 && listStore.count > 0) {
      setPageNo(1);
    }
  }, [listStore.loading, listStore.list, listStore.count, limit]);

  useEffect(() => {
    if (listStore.list) {
      const _data = listStore.list.map((each) => {
        return { module: "people", id: each._id };
      });
      setNextPreviousData(_data);
    }
  }, [listStore.list]);

  useEffect(() => {
    if (params.linkedId && params.moduleName) {
      setLinkedModuleId(params.linkedId);
      setLinkedModuleName(params.moduleName);
      setSidePannelPeopleId("");
      setOpenPanel(true);
    } else if (params.peopleId) {
      setSidePannelPeopleId(params.peopleId);
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(true);
    } else {
      setSidePannelPeopleId("");
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("peopleOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("peopleOwner", owner);
    else localStorage.removeItem("peopleOwner");
  }, [owner]);

  const updateFieldPriority = (data) => {
    dispatch(actions.fieldUpdatePriorityRequest({ ...data, fieldType: "PERSON" }));
  };

  const showHideField = (data) => {
    dispatch(actions.fieldShowHideRequest(data));
  };

  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["selectedColumns"] = showColumns;
      dispatch(
        actions.peopleFilterAddRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        actions.peopleFilterAddRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData({
      filterModule: "people",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
      temp["selectedColumns"] = showColumns;
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    if (temp["id"] && filterId === temp["id"]) dispatch(actions.clearFilter());
    dispatch(
      actions.peopleFilterEditRequest(temp, (data) => {
        if (data.status && temp.id === filterId) applyFilter(filterId);
      }),
    );
    setAddFilterFormData({
      filterModule: "people",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const favoriteFilter = (data) => {
    dispatch(actions.peopleFilterFavoriteRequest(data));
  };

  const deleteFilter = (id) => {
    if (activeFilterStore && filterId === id) dispatch(actions.clearFilter());
    dispatch(actions.peopleFilterDeleteRequest(id));
    setAddFilterFormData({
      filterModule: "people",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
  };
  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
    dispatch(actions.clearFilter());
  };

  const addTag = (name, colorCode) => {
    dispatch(actions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(actions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(actions.peopleTagsDeleteRequest(id));
  };

  const handleEditPeople = (combinedId, data) => {
    dispatch(actions.peopleCellEditRequest({ editData: data, loadingData: loadingStore, cellId: combinedId }));
  };

  const handleAddPeople = (temp) => {
    dispatch(actions.peopleAddRequest({ peopleData: temp, loadingData: loadingStore }));
    setShowAdd(false);
    setAddFormData({});
  };

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  const handleBulkEdit = (data) => {
    if (isAllSelected) {
      const payload = {
        peopleData: {
          updateData: data,
          ids: checked,
          isAllSelected: true,
          filter: loadingStore,
        },
        loadingData: loadingStore,
      };
      dispatch(PeopleOperationActions.bulkEditPeopleV2Request(payload));
      setShowBulkEdit(false);
      setBulkEditData({});
      setChecked([]);
      setIsAllSelected(false);
    } else {
      dispatch(actions.peopleBulkEditRequest({ peopleData: { ...data, ids: checked }, loadingData: loadingStore }));
      setShowBulkEdit(false);
      setBulkEditData({});
    }
  };

  const handleDeletePeoples = () => {
    if (params.peopleId) {
      dispatch(actions.peopleDeleteRequest({ peopleIds: { ids: [params.peopleId] }, loadingData: loadingStore }));
    } else if (isAllSelected && checked.length > 0) {
      dispatch(PeopleOperationActions.bulkDeletePeopleV2Request({ peopleIds: { ids: checked, filter: loadingStore, isAllSelected: true }, loadingData: loadingStore }));
      setChecked([]);
    } else {
      dispatch(actions.peopleDeleteRequest({ peopleIds: { ids: checked }, loadingData: loadingStore }));
      setChecked([]);
    }
    setOpenPanel(false);
    setSidePannelPeopleId("");
    setShowDelete(false);
  };

  const handleMerge = (merged) => {
    dispatch(actions.peopleMergeRequest({ data: merged, loadingData: loadingStore }));
    setChecked([]);
  };

  const handleConvert = () => {
    if (params.peopleId) {
      dispatch(actions.convertPeopleToLeadRequest({ id: { id: params.peopleId }, loadingData: loadingStore }));
    } else {
      dispatch(actions.convertPeopleToLeadRequest({ id: { id: checked[0] }, loadingData: loadingStore }));
      setChecked([]);
    }
    setOpenPanel(false);
    setSidePannelPeopleId("");
    setShowConvertPopup(false);
  };

  const tableProps = {
    columns: fieldStore.list,
    data: listStore.list,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: (a) => {
      setChecked(a);
      setIsAllSelected(false);
    },
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    idToIndex: fieldStore.idToIndex,
    handleCellEdit: handleEditPeople,
    page: page,
    pageSub: pageSub,
    cellLoading: listStore.cellLoading,
    sidePanelProps: popupStore.data,
    popupLoading: popupStore.loading,
    popupCellLoading: popupStore.cellLoading,
    sidePanelType: sidePanelType,
    handleDelete: () => setShowDelete(true),
    loading: listStore.loading,
    convertToLead: () => setShowConvertPopup(true),

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    filterPayload: loadingStore,

    //side pannel
    sidePannelSelectedId: sidePannelPeopleId,

    // selection
    isAllListSelected: isAllSelected,
  };

  const deleteModalProps = {
    show: showDelete,
    title: `Delete ${checked.length > 1 ? "Peoples" : "People"}`,
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeletePeoples,
  };

  const convertModalProps = {
    show: showConvertPopup,
    title: "Convert To Lead",
    handleClose: () => {
      setShowConvertPopup(false);
    },
    handleAccept: handleConvert,
    buttonType: "green",
  };

  useEffect(() => {
    let tempCol = [];
    var tempFieldStore = fieldStore.list;
    tempFieldStore.sort(function(a, b) {
      return a.tablePriority - b.tablePriority;
    });
    for (let i = 0; i < tempFieldStore.length; i++) if (tempFieldStore[i].isEnabledForTable !== false) tempCol.push(tempFieldStore[i]["_id"]);
    setShowColumns(tempCol);
  }, [fieldStore]);

  const handlePanelClose = () => {
    history.push(`/prospects/people`);
    setOpenPanel(false);
  };

  const handleActionDropdownClick = (value) => {
    if (value.value === "emailValidation") {
      setShowEmailValidation(true);
    } else if (value.value === "emailFinder") {
      setShowEmailFinder(true);
    } else if (value.value === "subscribeToCadence") {
      setShowSubscribeToCadence(true);
    }
  }

  return (
    <>
      {fieldStore.loading || (listStore.loading && listStore.count === 0) ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <>
          <CustomForm
            show={showAdd}
            setShow={setShowAdd}
            handleAdd={handleAddPeople}
            addTag={addTag}
            editTag={editTag}
            deleteTag={deleteTag}
            page={page}
            pageSub={pageSub}
            fields={addFieldStore.list}
            customFormData={addFormData}
            setCustomFormData={setAddFormData}
            fieldsLoading={addFieldStore.loading}
            cacheCompany={true}
          />
          <DeleteModal modalProps={deleteModalProps}>
            Are you sure you want to delete the {checked.length > 1 ? "peoples" : "people"}?
            {isAllSelected && (
              <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be call or
                activated.
              </span>
            )}
          </DeleteModal>
          {!listStore.loading && listStore.count === 0 && _.isEmpty(tags) && _.isEmpty(owner) && _.isEmpty(virtualFilter) && _.isEmpty(filterId) ? (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row flex-stack w-100">
                    <div>
                      <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                    </div>
                    <div className="d-flex flex-row align-items-center gap-12px">
                      <NavLink to="/import/people">
                        <BlueGradientButton size="sm">Import People</BlueGradientButton>
                      </NavLink>
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <CustomEmptyPage page="people" setShowAdd={setShowAdd} />
            </ContentWrapper>
          ) : (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row flex-stack w-100">
                    <div>
                      <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                    </div>
                    <div>
                      {checked.length !== 0 ? (
                        <div className="d-flex flex-row align-items-center gap-12px">
                          <SelectAll
                            checked={checked}
                            limit={limit}
                            count={listStore.count}
                            isAllSelected={isAllSelected}
                            selectAllClick={() => {
                              setIsAllSelected(true);
                              setChecked(listStore.list.map((each) => each._id));
                            }}
                            clearAllClick={() => {
                              setIsAllSelected(false);
                              setChecked([]);
                            }}
                          />
                          {checked.length === 2 ? (
                            <BlueGradientButton size="sm" onClick={() => setShowMergeModal(true)}>
                              Merge
                            </BlueGradientButton>
                          ) : null}
                          {checked.length === 1 ? (
                            <GreenGradientButton size="sm" onClick={() => setShowConvertPopup(true)}>
                              Convert To Lead
                            </GreenGradientButton>
                          ) : null}
                          <BulkEditForm
                            isAllListSelected={isAllSelected}
                            handleBulkEdit={handleBulkEdit}
                            noOfRows={isAllSelected ? listStore.count : checked.length}
                            showColumns={showColumns}
                            show={showBulkEdit}
                            setShow={setShowBulkEdit}
                            addTag={addTag}
                            editTag={editTag}
                            deleteTag={deleteTag}
                            page={page}
                            pageSub={pageSub}
                            fields={fieldStore.list}
                            customFormData={bulkEditData}
                            setCustomFormData={setBulkEditData}
                          />
                          {checked.length > 0 && (
                            <SecondarySimpleDropdown
                              value={{}}
                              label={"Actions"}
                              options={[
                                { value: "subscribeToCadence", label: "Subscribe To Cadence" },
                                { value: "emailValidation", label: "Email Validation" },
                                { value: "emailFinder", label: "Email Finder" },
                              ]}
                              handleSelect={handleActionDropdownClick}
                              disabled={false}
                            />
                          )}
                          <SecondaryButton onClick={() => setShowBulkEdit(true)}>Bulk Edit</SecondaryButton>
                          <DeleteIconButton onClick={() => setShowDelete(true)} />
                          <ColumnFilter
                            fieldType={"PERSON"}
                            showHideField={showHideField}
                            updateFieldPriority={updateFieldPriority}
                            idToIndex={fieldStore.idToIndex}
                            columns={fieldStore.list}
                            showColumns={showColumns}
                            setShowColumns={setShowColumns}
                          />
                        </div>
                      ) : (
                        <div className="d-flex flex-row align-items-center gap-12px">
                          <BlueGradientButton onClick={() => setShowAdd(true)} variant="success" size="sm">
                            + People
                          </BlueGradientButton>

                          <SmartViewFilter
                            pageSub={pageSub}
                            page={page}
                            activeFilter={filterStore.activeFilter}
                            customFilters={filterStore.list}
                            loading={filterStore.loading}
                            setAddFilterFormData={setAddFilterFormData}
                            virtualFilter={virtualFilter}
                            applyVirtualFilter={applyVirtualFilter}
                            selectedFilterId={filterId}
                            favoriteFilter={favoriteFilter}
                            applyFilter={applyFilter}
                            addFilterFormData={addFilterFormData}
                            addFilter={addFilter}
                            editFilter={editFilter}
                            deleteFilter={deleteFilter}
                          />
                          <div>
                            <TagSelect
                              page={page}
                              pageSub={pageSub}
                              addTag={addTag}
                              editTag={editTag}
                              deleteTag={deleteTag}
                              field={{ fieldName: "Tags", apiKeyName: "tags" }}
                              value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                              setValue={(value) => setValue(value)}
                              hideAddText={true}
                              isUsedAsFilter={true}
                              iconBasedFilter={true}
                            />
                          </div>
                          <OwnerFilter setValue={setOwner} value={owner} />
                          <OptionsDropdown
                            dataType={"people"}
                            importLabel={"Import People"}
                            exportLabel={"Export People"}
                            showColumns={showColumns}
                            showExportModal={showExportModal}
                            activeFilter={activeFilterStore}
                            appliedFilter={loadingStore}
                            setShowExportModal={setShowExportModal}
                          />
                          <ColumnFilter
                            fieldType={"PERSON"}
                            showHideField={showHideField}
                            updateFieldPriority={updateFieldPriority}
                            idToIndex={fieldStore.idToIndex}
                            columns={fieldStore.list}
                            showColumns={showColumns}
                            setShowColumns={setShowColumns}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <div className={`card card-custom`}>
                <div className="card-body py-3 px-3">
                  <EditableTable props={tableProps} />
                </div>
              </div>
              <ConvertModal modalProps={convertModalProps}>Are you sure you want to convert to lead?</ConvertModal>
              <MergeModal
                show={showMergeModal}
                handleMerge={handleMerge}
                setShow={setShowMergeModal}
                type={page}
                pageSub={pageSub}
                typeSub="Person"
                fields={fieldStore.list}
                items={checked.map((item) => listStore.list.find((listItem) => listItem["_id"] === item))}
              />
              <SidePannelDetailed
                show={openPanel}
                basePath={`/prospects/people`}
                onClose={handlePanelClose}
                moduleName={linkedModuleName ? linkedModuleName : "people"}
                moduleId={linkedModuleId ? linkedModuleId : sidePannelPeopleId}
                nextPreviousData={linkedModuleId ? [] : nextPreviousData}
                pageForm={"people"}
                filter={loadingStore}
                // actions
                convertToLead={() => setShowConvertPopup(true)}
                handleDelete={() => setShowDelete(true)}
              />
              <EmailValidationModel
                show={showEmailValidation}
                setShow={setShowEmailValidation}
                isAllSelected={isAllSelected}
                moduleName="people"
                moduleIds={checked}
                loadingStore={loadingStore}
                onSuccess={() => {
                  setChecked([]);
                  setIsAllSelected(false);
                }}
              />
              <EmailFinderModel
                show={showEmailFinder}
                setShow={setShowEmailFinder}
                isAllSelected={isAllSelected}
                moduleName="people"
                moduleIds={checked}
                loadingStore={loadingStore}
                onSuccess={() => {
                  setChecked([]);
                  setIsAllSelected(false);
                }}
              />
              <SubscribeToCadenceModal
                show={showSubscribeToCadence}
                setShow={setShowSubscribeToCadence}
                isAllSelected={isAllSelected}
                moduleName="people"
                moduleIds={checked}
                loadingStore={loadingStore}
                onSuccess={() => {
                  setChecked([]);
                  setIsAllSelected(false);
                }}
              />
            </ContentWrapper>
          )}
        </>
      )}
    </>
  );
}
