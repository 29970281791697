import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import RadioSelector from "../../../components/custom-inputs/radio-selector";
import { InputLimit } from "../../../constants";
import { ErrorPage6 } from "../../modules/ErrorsExamples/ErrorPage6";

import "./styles.scss";

export default function UnsubscribeCamapignService(props) {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [showUnsubscribeForm, setShowUnsubscribeForm] = useState(false);
  const [options, setOptions] = useState(["Other (fill in reason below)"]);
  const [loading, setLoading] = useState(false);

  // cadence
  const [subscriberId, setSubscriberId] = useState("");
  const [stepId, setStepId] = useState("");
  const [moduleName, setModuleName] = useState("");

  const params = useParams();
  const location = useLocation();
  console.log(company);

  const updateOptions = (accountId) => {
    try {
      setLoading(true);
      fetch(`/api/global/loss-reason/marketing/${accountId}`)
        .then((res) => res.json())
        .then((json) => {
          const _options = json.data.map((a) => a.reason);
          setOptions([..._options, "Other (fill in reason below)"]);
          setLoading(false);
        });
    } catch (err) {
      setOptions(["Other (fill in reason below)"]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.search && params.accountId && params.customerId) {
      const searchParams = new URLSearchParams(location.search);
      const _email = searchParams.get("email");
      const _company = searchParams.get("company");

      if (_email && _company) {
        setEmail(_email);
        setCompany(_company);
        setAccountId(params.accountId);
        setCustomerId(params.customerId);
        setShowUnsubscribeForm(true);
        updateOptions(params.accountId);
      }
    } else if (params.moduleName === "cadence") {
      setModuleName(params.moduleName);
      setAccountId(params.accountId);
      setSubscriberId(params.subscriberId);
      setStepId(params.stepId);
      setShowUnsubscribeForm(true);
      updateOptions(params.accountId);
    }
  }, [location, params]);

  const handleUnsubscribe = async () => {
    let reason = "";
    if (selectedReason === "Other (fill in reason below)") {
      reason = `Email - Other - ${otherReason}`;
    } else {
      reason = `Email - ${selectedReason}`;
    }

    if (moduleName === "cadence") {
      const payload = {
        accountId: accountId,
        subscriberId: subscriberId,
        stepId: stepId,
        reason: reason,
      };
      const url = "/cadence-api/v2/global/cadence/unsubscribe";
      await fetch(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      setUnsubscribed(true);
    } else {
      const payload = {
        accountId: accountId,
        customerId: customerId,
        email: email,
        channel: "email",
        reason: reason,
      };
      const url = "/campaign-manager/api/v1/email/unsubscribe-email";
      await fetch(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      setUnsubscribed(true);
    }
  };

  const isDisabled = () => {
    if (selectedReason === "Other (fill in reason below)" && !otherReason) {
      return true;
    } else if (selectedReason) {
      return false;
    } else return true;
  };

  if (!showUnsubscribeForm) return <ErrorPage6 />;

  return (
    <div className="w-100 h-100 bg-white">
      <div className="unsubscribe-container">
        <div className="unsubscribed-logo-wrapper">
          {/* <div className="unsubscribe-company-name">{company}</div> */}
          {/* <div class="row-content center row mt-20px mx-auto mb-40px w-500px-mx" style="box-sizing: border-box; text-align: center;">
          <img alt="" class="top-image w-100-percent-mx d-inline-block" sizes="150px" src="https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle.png" srcset="https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle-p-500.png 500w, https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle-p-800.png 800w, https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle.png 1024w" style="box-sizing: border-box; border: 0px; vertical-align: middle; margin-bottom: 20px;" width="150" />
          <h3 style="box-sizing: border-box; font-weight: bold; margin-bottom: 10px; font-size: 24px; line-height: 30px; margin-top: 0px;">You will no longer receive emails from us</h3>
        </div> */}
          <div className="d-flex flex-column justify-content-center align-items-center box-sizing-border-box text-center mt-20px mx-auto mb-15px w-600px-mx">
            <img
              alt=""
              className="align-middle border-0 d-inline-block top-image box-sizing-border-box w-150px mb-20px w-100-percent-mx"
              src="https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle.png"
              srcSet="https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle-p-500.png 500w, https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle-p-800.png 800w, https://uploads-ssl.webflow.com/633522b358783a1fde2af65d/633522b358783a735e2af6d8_FloatDoodle.png 1024w"
            />
            <h3 className="box-sizing-border-box mt-0 mb-10px fw-700 font-size-24px line-height-30px">You will no longer receive emails from us</h3>
          </div>
        </div>
        <div className="unsubscribe-impormation-wrapper box-sizing-border-box mb-40px p-20px border-style-solid border-radius-15px border-color-f5f5f5 border-width-2px">
          {!unsubscribed ? (
            <div>
              {/* <div className="unsubscribe-heading">Unsubscribe</div> */}
              <div className="unsubscribe-info-text">How we can improve? Could you take a moment to inform us of the reason for your unsubscription?</div>
              {loading ? (
                <div className="w-100 h-200px d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <Form>
                    <RadioSelector isVertical={true} setValue={setSelectedReason} value={selectedReason} options={options} />
                  </Form>
                  {selectedReason === "Other (fill in reason below)" && (
                    <Form.Group id="unauthscreens-unsubscribe-other-reason">
                      <Form.Control as="textarea" maxLength={InputLimit.TEXTAREA} rows={5} value={otherReason} onChange={(e) => setOtherReason(e.target.value)}></Form.Control>
                    </Form.Group>
                  )}

                  <Button variant="primary" size="sm" disabled={isDisabled()} onClick={() => handleUnsubscribe()}>
                    Unsubscribe
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div>
              <div className="unsubscribe-heading">Unsubscribe Successfully</div>
              {moduleName === "cadence" ? (
                <div className="unsubscribe-info-text">You have successfully unsubscribed from cadence.</div>
              ) : (
                <div className="unsubscribe-info-text">You have successfully unsubscribed from email campaigns.</div>
              )}
            </div>
          )}
        </div>
        <div className="mt-10">
          <div className="d-flex justify-content-center gap-1 fs-6 fw-500">
            <span className="text-gray-600">Crafted with ♥ using </span>
            <span>Sales Simplify</span>
            <span className="text-primary">
              <a href="https://www.salessimplify.com" rel="noopener noreferrer" target={"_blank"}>
                Email Marketing
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
