import React, { useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { BlueGradientButton } from "../../button";

import "./styles.scss";

export default function EmailTemplateBuilder(props) {
  const [showActions, setShowActions] = useState(false);
  const emailEditorRef = useRef(null);

  const onReady = () => {
    if (props.design) {
      emailEditorRef.current.editor.loadDesign(props.design);
    }
    setShowActions(true);
  };

  const handleSave = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      props.handleSave(html, design);
    });
  };
  return (
    <div className="w-100 h-100 position-relative email-builder">
      <EmailEditor
        className="w-100 h-vh-58px"
        ref={emailEditorRef}
        onReady={onReady}
        appearance={{
          loader: {
            html: '<div class="ss-custom-loader"></div>',
            css: `.ss-custom-loader {border: 16px solid #f3f3f3; border-top: 16px solid #3498db;border-radius: 50%;width: 120px;height: 120px;animation: spin 2s linear infinite;}
                          @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                          }`,
          },
        }}
      />
      <div className="h-50px email-footer-address gap-3">
        {showActions && (
          <BlueGradientButton size="sm" onClick={() => handleSave()}>
            Save
          </BlueGradientButton>
        )}
      </div>
    </div>
  );
}
