import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GreenGradientButton } from "../../../../../components/button";
import CurrencySelect from "../../../../../components/custom_form/currency";
import TagSelect from "../../../../../components/custom_form/tags";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { InputLimit } from "../../../../../constants";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { LeadStateWrapper, StateItem } from "../../../../deals/common/dealModal/styled";
import * as dealActions from "../../../../deals/dealPipeline/actions";
import { getDealActionByIdRequest } from "../../actions/deal_action";
import OwnerField from "../component/owner.field";
import { expectedCloseDateArray } from "../constants";

export default function CreateDealForm(props) {
  const [loading, setLoading] = useState(false);
  const [dealNameType, setDealNameType] = useState({ value: "automatic", label: "Automatically" });
  const [dealName, setDealName] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [dealValue, setDealValue] = useState(0);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [selectedPipelineStage, setSelectedPipelineStage] = useState({});
  const [expectedCloseDate, setExpectedCloseDate] = useState("");
  const [expectedCloseDateButton, setExpectedCloseDateButton] = useState("");
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState({ type: "automatic" });

  const [pipelineOption, setPipelineOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);

  const pipelineStore = useSelector((store) => store.DealPipeline.pipelineOptionsResponse);
  const profileStore = useSelector((store) => store.ProfileSettings.getProfileDetails.data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(profileStore) && profileStore.currency) {
      setSelectedCurrency(profileStore.currency.toUpperCase());
    }
  }, [profileStore]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.selectedActionData) && props.selectedActionData.id) {
      setLoading(true);
      dispatch(
        getDealActionByIdRequest({ id: props.selectedActionData.id }, (data) => {
          if (data.success) {
            const dealData = data.data;
            setDealValue(dealData.dealValue);
            setSelectedCurrency(dealData.dealCurrency);
            // setExpectedCloseDate(dealData.expectedCloseDate)
            setDealName(dealData.dealName);
            setOwner(dealData.owner);
            setTags(dealData.tags);
            setDealNameType({ value: dealData.selectionType, label: _.capitalize(dealData.selectionType) });

            for (let i = 0; i < pipelineStore.length; i++) {
              if (pipelineStore[i]._id === dealData.pipeline) {
                const _pipelineStageOp = [];
                setSelectedPipeline({ value: pipelineStore[i]._id, label: pipelineStore[i].name });
                pipelineStore[i].stages.forEach((each, index) => {
                  _pipelineStageOp.push({ value: each._id, label: each.name });
                  if (dealData.pipelineStage === each._id) {
                    setSelectedPipelineStage({ value: each._id, label: each.name });
                  }
                });
                setStageOptions(_pipelineStageOp);
              }
            }

            const time = new Date(dealData.expectedCloseDate).getTime();
            setExpectedCloseDate(time);
            expectedCloseDateArray.forEach((each) => {
              if (each.duration === time) {
                setExpectedCloseDateButton(each.key);
              }
            });
          }
          setLoading(false);
        }),
      );
    }
  }, [dispatch, props.selectedActionData, props.isEdit, pipelineStore]);

  useEffect(() => {
    if (Array.isArray(pipelineStore)) {
      const _pipelineOp = [];
      for (let i = 0; i < pipelineStore.length; i++) {
        _pipelineOp.push({ value: pipelineStore[i]._id, label: pipelineStore[i].name });
        if (pipelineStore[i].isDefault) {
          setSelectedPipeline({ value: pipelineStore[i]._id, label: pipelineStore[i].name });
          const _pipelineStageOp = [];
          pipelineStore[i].stages.forEach((each, index) => {
            _pipelineStageOp.push({ value: each._id, label: each.name });
            if (index === 0) {
              setSelectedPipelineStage({ value: each._id, label: each.name });
            }
          });
          setStageOptions(_pipelineStageOp);
        }
      }
      setPipelineOptions(_pipelineOp);
    }
  }, [pipelineStore]);

  const handlePipelineChange = (option) => {
    setSelectedPipeline(option);
    for (let i = 0; i < pipelineStore.length; i++) {
      if (pipelineStore[i]._id === option.value) {
        const _pipelineStageOp = [];
        pipelineStore[i].stages.forEach((each, index) => {
          _pipelineStageOp.push({ value: each._id, label: each.name });
          if (index === 0) {
            setSelectedPipelineStage({ value: each._id, label: each.name });
          }
        });
        setStageOptions(_pipelineStageOp);
      }
    }
  };

  const addTag = (name, colorCode) => {
    dispatch(dealActions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(dealActions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode, type: "deal" }));
  };

  const deleteTag = (id) => {
    dispatch(dealActions.deleteDealTagRequest(id, "deal"));
  };
  const findIndex = () => {
    let i = 0;
    stageOptions.forEach((value, index) => {
      if (value.value === selectedPipelineStage.value) {
        i = index;
      }
    });
    return i;
  };

  const validate = () => {
    if (dealNameType.value === "manual" && !dealName) {
      return "Deal Name cannot be empty";
    } else if (_.isEmpty(owner) || !["automatic", "roundRobbin", "single"].includes(owner.type)) {
      return dispatchSnackbarError("Select Owner Data!");
    } else if (["roundRobbin", "single"].includes(owner.type) && _.isEmpty(owner.id)) {
      return dispatchSnackbarError("Select owner from owner dropdown!!!");
    }
  };

  const handleSave = () => {
    const _validate = validate();
    if (_validate) {
      return dispatchSnackbarError(_validate);
    }
    const data = {
      module: {
        name: props.moduleName,
      },
      dealName: dealName,
      selectionType: dealNameType.value,
      dealValue: dealValue,
      dealCurrency: selectedCurrency,
      pipeline: selectedPipeline.value,
      pipelineStage: selectedPipelineStage.value,
      expectedCloseDate: expectedCloseDate ? expectedCloseDate : 0,
      tags: tags,
      owner: owner,
    };
    if (props.isEdit) props.handleEditSave(props.selectedActionData.id, data);
    else props.handleCreateSave(data);
  };

  const onExpecetdDateSelect = (each) => {
    setExpectedCloseDate(each.duration);
    setExpectedCloseDateButton(each.key);
  };

  if (loading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center h-vh-162px">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Form.Group id="automation-workflow-builder-create-deal-form">
      <Form.Group id="automation-workflow-builder-create-deal-form-deal-name" className="mb-5">
        <Form.Label aria-label="deal-name">Deal Name*</Form.Label>
        <Form.Group id="automation-workflow-builder-create-deal-form-dropdown" className="d-flex justify-content-between align-items-center mb-0">
          <Form.Group id="automation-workflow-builder-create-deal-form-deal-name-type" className="mb-0 w-48">
            <SimpleDropdown
              value={dealNameType}
              label={"Select Deal Name Type"}
              options={[
                { value: "automatic", label: "Automatically" },
                { value: "manual", label: "Manually" },
              ]}
              handleSelect={(a) => setDealNameType(a)}
            />
          </Form.Group>
          <Form.Group id="automation-workflow-builder-create-deal-form-deal-name-manual" className="mb-0 w-48">
            {dealNameType.value === "manual" && <Form.Control maxLength={InputLimit.TEXT} value={dealName} type="text" onChange={(e) => setDealName(e.target.value)} />}
          </Form.Group>
        </Form.Group>
      </Form.Group>
      <Form.Group id="automation-workflow-builder-create-deal-form-deal-value" className="mb-5">
        <Form.Label aria-label="deal-value">Deal Value</Form.Label>
        <Form.Group id="automation-workflow-builder-create-deal-form-select-currency" className="d-flex justify-content-between align-items-center w-100 mb-0">
          <div className="w-49">
            <CurrencySelect field={{ fieldName: "Currency" }} value={selectedCurrency} setValue={(value) => setSelectedCurrency(value)} />
          </div>
          <Form.Control className="mt-1 w-49" maxLength={InputLimit.EMAIL} type="number" disabled={false} value={dealValue} onChange={(e) => setDealValue(e.target.value)} />
        </Form.Group>
      </Form.Group>

      <Form.Group id="automation-workflow-builder-create-deal-form-pipeline" className="mb-5">
        <Form.Label aria-label="pipeline">Pipeline*</Form.Label>
        <SimpleDropdown value={selectedPipeline} label={"Select Pipeline"} options={pipelineOption} handleSelect={(a) => handlePipelineChange(a)} />
      </Form.Group>

      <Form.Group id="automation-workflow-builder-create-deal-form-pipeline-stages" className="mb-5">
        <Form.Label aria-label="pipeline-stages">Pipeline Stages*</Form.Label>
        <LeadStateWrapper>
          {stageOptions &&
            stageOptions.map((value, index) => {
              const currentIndex = findIndex();
              return (
                <StateItem key={index} past={index < currentIndex} active={index === currentIndex} onClick={() => setSelectedPipelineStage(value)} title={value.label}>
                  <div title={value.label}>{index < currentIndex}</div>
                </StateItem>
              );
            })}
        </LeadStateWrapper>
      </Form.Group>
      <Form.Group id="automation-workflow-builder-create-deal-form-expected-close-date" className="mb-5">
        <Form.Label aria-label="expected-close-date">Expected Close Date</Form.Label>
        {/* <Form.Control type="date" name="ecd" placeholder="Expected Close Date" value={expectedCloseDate} onChange={(e) => setExpectedCloseDate(e.target.value)} /> */}
        <div className="d-flex flex-row gap-5px">
          {expectedCloseDateArray.map((each) => {
            return (
              <Button
                className={expectedCloseDateButton === each.key ? "btn quick-scheduler-button-active shadow-sm active-time-button " : "btn quick-scheduler-button shadow-sm border color-black"}
                onClick={() => onExpecetdDateSelect(each)}
              >
                {each.name}
              </Button>
            );
          })}
        </div>
      </Form.Group>

      <Form.Group id="automation-workflow-builder-create-deal-form-tags" className="mb-5">
        <Form.Label aria-label="tag">Tag</Form.Label>
        <TagSelect
          page={"DealPipeline"}
          pageSub={"deal"}
          addTag={addTag}
          editTag={editTag}
          deleteTag={deleteTag}
          field={{
            fieldName: "Tags",
            apiKeyName: "tags",
          }}
          value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
          setValue={setTags}
        />
      </Form.Group>
      <Form.Group id="automation-workflow-builder-create-deal-form-owner-field">
        <OwnerField value={owner} setValue={setOwner} />
      </Form.Group>
      <Form.Group id="automation-workflow-builder-create-deal-form-save-button" className="d-flex justify-content-start">
        <GreenGradientButton size="sm" onClick={() => handleSave()} disabled={false}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
    </Form.Group>
  );
}
