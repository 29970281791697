import { getCadenceAnalyticsSummarize } from "../types/cadence_analytics_summarize_types";

export const getCadenceAnalyticsSummarizeRequest = (payload, cb) => ({
  type: getCadenceAnalyticsSummarize.GET_CADENCE_ANALYTICS_SUMMARIZE_REQUEST,
  payload,
  cb,
});
export const getCadenceAnalyticsSummarizeSuccess = (payload) => ({
  type: getCadenceAnalyticsSummarize.GET_CADENCE_ANALYTICS_SUMMARIZE_SUCCESS,
  payload,
});
export const getCadenceAnalyticsSummarizeError = (payload) => ({
  type: getCadenceAnalyticsSummarize.GET_CADENCE_ANALYTICS_SUMMARIZE_ERROR,
  payload,
});
