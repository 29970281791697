import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CustomEmptyPage } from "../../../../../components/empty_page/custom-empty-page";
import SidePannelDetailed from "../../../../../components/side-pannel-detailed";
import { NormalTable } from "../../../../../components/table/normalTable";
import ContentWrapper from "../../../../Wrapper";
import CadenceSubHeader from "../../components/cadence-header";
import * as cadenceLeadActions from "../../store/actions/cadence_lead_actions";
import * as CadenceSubscriberOperationsActions from "../../store/actions/cadence_subscriber_operation_actions";

//actions
import * as LeadActions from "../../../../leads/store/actions/actions";
import * as CustomerActions from "../../../../customers/store/actions/actions";
import * as CompanyActions from "../../../../companies/store/actions/actions";
import * as ProductActions from "../../../../products/store/actions/actions";
import * as insightActions from "../../../../insights/actions";
import * as TemplateActions from "../../../../campaign/store/actions/campaign_templates_actions";
import * as ActivityTypesActions from "../../../../Activities/store/actions/activity_types_actions";
import * as CadenceActions from "../../store/actions/cadence_actions";
import * as marketingListActions from "../../../../campaign/store/actions/campaign_marketing_list_actions";
import * as marketingDataFieldActions from "../../../../campaign/store/actions/marketing_data_field_actions";

import { PrimarySimpleDropdown } from "../../../../../components/dropdowns/simple-dropdown";
import { subscriberActionOptions } from "../../constants";
import { ConvertModal } from "../../../../../components/modal";
import ChangeStepActionForm from "./change-step-action-form";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import MarkAsRepliedActionForm from "./replied-step-action-form";
import AddSubscriberModal from "./add-subscribers.modal";
import { getConditionRequest } from "../../../workflow/actions/conditions_actions";
import CadenceSubscriberAnaytics from "./cadence-subscriber-analytics.pannel";
import CadenceSubscriberFilter from "./cadence-subscriber-filter";
import SelectAll from "../../../../../components/filter/select-all";

export default function CadenceSubscribers(props) {
  const tabName = "Subscribers";
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [cadenceId, setCadenceId] = useState("");
  const [checked, setChecked] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  // confirmation
  const [selectedAction, setSelecetdAction] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [title, setTitle] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [selectedStep, setSelectedStep] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState({});

  // side pannel
  const [showPannel, setShowPannel] = useState(false);
  const [sidePannelModule, setSidePannelModule] = useState("");
  const [sidePannelId, setSidePannelId] = useState("");

  // show add subscriber
  const [showAddSubscriber, setShowAddSubscriber] = useState(false);

  // filter
  const [showFilter, setShowFilter] = useState(false);
  const [initalFilterValue, setInitalFilterValue] = useState({
    status: "ALL",
    lastResponse: "ALL",
    nextStep: "ALL",
    addedDatefrom: null,
    addedDateto: null,
    lastActivityDatefrom: null,
    lastActivityDateto: null,
  });

  // cadence subscribers analytics
  const [showSubscriberStatsAnalytics, setShowSubscriberStatsAnalytics] = useState(false);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState("");

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const subscriberListStore = useSelector((store) => store.cadence.subscribers);

  useEffect(() => {
    if (cadenceId) dispatch(CadenceActions.getCadenceByIdRequest({ id: cadenceId }));
  }, [dispatch, cadenceId]);

  useEffect(() => {
    dispatch(getConditionRequest());

    //tags
    dispatch(LeadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(CustomerActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));
    dispatch(marketingListActions.getListOfMarketingListTagsRequest({ type: "marketing" }));

    //filters
    dispatch(LeadActions.leadFiltersRequest());
    dispatch(CustomerActions.peopleFiltersRequest());
    dispatch(marketingListActions.getSavedMarketingFiltersRequest({ type: "marketing" }));

    dispatch(CustomerActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(marketingDataFieldActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
  }, [dispatch]);

  useEffect(() => {
    if (params.cadenceId) {
      setCadenceId(params.cadenceId);
    } else {
      history.push("/automation/cadence/list");
    }
  }, [params.cadenceId, history]);

  useEffect(() => {
    if (params.moduleName && params.linkedId) {
      setShowPannel(true);
      setSidePannelModule(params.moduleName);
      setSidePannelId(params.linkedId);
    } else {
      setShowPannel(false);
      setSidePannelModule("");
      setSidePannelId("");
    }
  }, [params.linkedId, params.moduleName]);

  useEffect(() => {
    if (cadenceId && page && limit) {
      const payload = {
        cadenceId: cadenceId,
        page: page,
        limit: limit,
        filter: JSON.stringify(initalFilterValue),
      };
      dispatch(cadenceLeadActions.getCadenceAllLeadRequest(payload));
    }
  }, [dispatch, page, limit, cadenceId, initalFilterValue]);

  // dependent api calls
  useEffect(() => {
    dispatch(ActivityTypesActions.getListOfActiveActivityTypesRequest());
    dispatch(LeadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
    dispatch(CustomerActions.peopleTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(CustomerActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
  }, [dispatch]);

  const columns = [
    { apiKeyName: "customer", fieldInputType: "LEAD_POPOVER", fieldName: "Name", basePath: `/automation/cadence/subscribers/${cadenceId}` },
    // { apiKeyName: "stats", fieldInputType: "CADENCE_STATS", fieldName: "Stats" },
    { apiKeyName: "lastResponse", fieldInputType: "INPUT", fieldName: "Last Response" },
    { apiKeyName: "nextStep", fieldInputType: "INPUT", fieldName: "Next Steps" },
    { apiKeyName: "addedDate", fieldInputType: "DATE_TIME", fieldName: "Added Date" },
    { apiKeyName: "lastActivityDate", fieldInputType: "DATE_TIME", fieldName: "Last Activity Date" },
    { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
  ];

  const handleTableActions = (actionName, data) => {
    setShowSubscriberStatsAnalytics(true);
    setSelectedSubscriberId(data._id);
  };

  const tableProps = {
    columns: columns,
    data: subscriberListStore.data.map((each) => {
      each.customer = {
        _id: each.subscriberId,
        name: each.subscriberName,
        subscriberType: each.subscriberType,
      };
      each.lastResponse = each.lastResponse ? each.lastResponse : "-";
      each.nextStep = each.nextStep ? each.nextStep : "-";
      return each;
    }),
    pagination: true,
    count: subscriberListStore.count,
    limit: limit,
    pageNo: page,
    setLimit: setLimit,
    setPageNo: setPage,
    loading: subscriberListStore.isLoading,
    checked: checked,
    setChecked: setChecked,
    checkboxes: true,

    // table actions
    tableActions: ["SUBSCRIBER_ANALYTICS"],
    handleTableActions: handleTableActions,
  };

  const onCloseSidePannel = () => {
    history.goBack();
  };

  const handleActionDropdownSelect = (value) => {
    setSelecetdAction(value);
    if (value.value === "PAUSE") {
      setShowConfirmation(true);
      setTitle("Pause Cadence");
      setButtonText("Pause");
    } else if (value.value === "RESUME") {
      setShowConfirmation(true);
      setTitle("Resume Cadence");
      setButtonText("Resume");
    } else if (value.value === "FINISHED") {
      setShowConfirmation(true);
      setTitle("Mark As Finished Cadence");
      setButtonText("Mark as finished");
    } else if (value.value === "CHANGE_STEP") {
      setShowConfirmation(true);
      setTitle("Change Step");
      setButtonText("Update");
    } else if (value.value === "REMOVE") {
      setShowConfirmation(true);
      setTitle("Unsubscribe From Cadence");
      setButtonText("Unsubscribe");
    } else if (value.value === "REPLIED") {
      setShowConfirmation(true);
      setTitle("Mark as Replied");
      setButtonText("Mark as Replied");
    } else if (value.value === "RETRY") {
      setShowConfirmation(true);
      setTitle("Retry Step");
      setButtonText("Retry Step");
    }
  };

  const handleActionAccept = () => {
    if (checked.length === 0) return handleActionCancel();

    const payload = {
      from: "subscriber-list",
      data: {
        type: isAllSelected ? "ALL" : checked.length > 1 ? "MULTIPLE" : "SINGLE",
        cadenceId: cadenceId,
        cadenceSubscriberId: checked[0],
        cadenceSubscriberIds: checked,
        filter: JSON.stringify(initalFilterValue),
      },
      filter: {
        cadenceId: cadenceId,
        page: page,
        limit: limit,
        filter: JSON.stringify(initalFilterValue),
      },
    };
    if (selectedAction.value === "PAUSE") {
      dispatch(CadenceSubscriberOperationsActions.pauseCadenceSubscriberRequest(payload));
    } else if (selectedAction.value === "RESUME") {
      dispatch(CadenceSubscriberOperationsActions.resumeCadenceSubscriberRequest(payload));
    } else if (selectedAction.value === "FINISHED") {
      dispatch(CadenceSubscriberOperationsActions.markAsFinishedCadenceSubscriberRequest(payload));
    } else if (selectedAction.value === "REMOVE") {
      dispatch(CadenceSubscriberOperationsActions.removeFromCadenceSubscriberRequest(payload));
    } else if (selectedAction.value === "RETRY") {
      dispatch(CadenceSubscriberOperationsActions.retryCadenceSubscriberRequest(payload));
    } else if (selectedAction.value === "CHANGE_STEP") {
      if (_.isEmpty(selectedStep)) {
        return dispatchSnackbarError("Please select step.");
      }
      payload.data.stepId = selectedStep.value;
      dispatch(CadenceSubscriberOperationsActions.changeStepCadenceSubscriberRequest(payload));
    } else if (selectedAction.value === "REPLIED") {
      if (_.isEmpty(selectedStep)) {
        return dispatchSnackbarError("Please select step.");
      }
      payload.data.stepId = selectedStep.value;
      dispatch(CadenceSubscriberOperationsActions.markAsRepliedCadenceSubscriberRequest(payload));
    }

    handleActionCancel();
    setChecked([]);
    setIsAllSelected(false);
  };

  const handleActionCancel = () => {
    setShowConfirmation(false);
    setTitle("");
    setButtonText("");
    setSelecetdAction({});
    setSelectedStep({});
    setSelectedTemplate({});
  };

  const convertModalProps = {
    show: showConfirmation,
    title: title,
    buttonText: buttonText,
    handleClose: handleActionCancel,
    handleAccept: handleActionAccept,
    buttonType: "green",
  };

  const fetchSubscribers = () => {
    const payload = {
      cadenceId: cadenceId,
      page: page,
      limit: limit,
      filter: JSON.stringify(initalFilterValue),
    };
    dispatch(cadenceLeadActions.getCadenceAllLeadRequest(payload));
  };

  const handleApplyFilter = (filterValue) => {
    setInitalFilterValue(filterValue);
    setPage(1);
    setShowFilter(false);
  };

  const handleCancelFilter = () => {
    setShowFilter(false);
  };

  return (
    <ContentWrapper
      subHeader={
        <CadenceSubHeader tabName={tabName} cadenceId={cadenceId}>
          {checked.length > 0 ? (
            <div className="d-flex gap-3 align-items-center mr-2">
              <SelectAll
                checked={checked}
                count={subscriberListStore.count}
                limit={limit}
                isAllSelected={isAllSelected}
                selectAllClick={() => {
                  setIsAllSelected(true);
                  setChecked(subscriberListStore.data.map((each) => each._id));
                }}
                clearAllClick={() => {
                  setIsAllSelected(false);
                  setChecked([]);
                }}
              />
              <PrimarySimpleDropdown value={{}} label={"Actions"} options={subscriberActionOptions} handleSelect={handleActionDropdownSelect} disabled={false} />
            </div>
          ) : (
            <div className="d-flex gap-3 align-items-center mr-2">
              <Button size="sm" onClick={() => setShowAddSubscriber(true)}>
                Add Subscribers
              </Button>
            </div>
          )}
          <CadenceSubscriberFilter show={showFilter} setShow={setShowFilter} filterValue={initalFilterValue} handleApplyFilter={handleApplyFilter} handleCancelFilter={handleCancelFilter} />
        </CadenceSubHeader>
      }
    >
      {" "}
      {subscriberListStore.isLoading ? (
        <div className="card w-100 d-flex justify-content-center align-items-center h-vh-162px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : subscriberListStore.data.length === 0 ? (
        <CustomEmptyPage page="cadenceSubscribers" setShowAdd={() => setShowAddSubscriber(true)} />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <NormalTable props={tableProps} />
          </div>
        </div>
      )}
      <SidePannelDetailed
        show={showPannel}
        basePath={`/automation/cadence/subscribers/${cadenceId}`}
        onClose={onCloseSidePannel}
        moduleName={sidePannelModule}
        moduleId={sidePannelId}
        nextPreviousData={[]}
        pageForm={"cadenceSubscribers"}
        filter={{ cadenceId: cadenceId, page: page, limit: limit, filter: JSON.stringify(initalFilterValue) }}
      />
      <ConvertModal modalProps={convertModalProps}>
        {selectedAction.value === "PAUSE" && <>Are you sure, you want to pause cadence for selected subscribers.</>}
        {selectedAction.value === "RESUME" && <>Are you sure, you want to resume cadence for selected subscribers.</>}
        {selectedAction.value === "FINISHED" && <>Are you sure, you want to mark as finish cadence flow for selected subscribers.</>}
        {selectedAction.value === "CHANGE_STEP" && <ChangeStepActionForm type="CHANGE_STEP" selectedStep={selectedStep} setSelectedStep={setSelectedStep} />}
        {selectedAction.value === "REMOVE" && <>Are you sure, you want to unsubscribe selected subscribers from selected cadence.</>}
        {selectedAction.value === "REPLIED" && (
          <MarkAsRepliedActionForm type="REPLIED" selectedStep={selectedStep} setSelectedStep={setSelectedStep} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
        )}
        {selectedAction.value === "RETRY" && <>Are you sure, you want to retry cadence for selected subscribers.</>}
      </ConvertModal>
      {<CadenceSubscriberAnaytics cadenceId={cadenceId} subscriberId={selectedSubscriberId} show={showSubscriberStatsAnalytics} setShow={setShowSubscriberStatsAnalytics} />}
      <AddSubscriberModal show={showAddSubscriber} setShow={setShowAddSubscriber} cadenceId={cadenceId} fetchSubscribers={fetchSubscribers} />
    </ContentWrapper>
  );
}
