export const EXPORTHISTORY_REQUEST = "EXPORTHISTORY_REQUEST";
export const EXPORTHISTORY_SUCCESS = "EXPORTHISTORY_SUCCESS";
export const EXPORTHISTORY_ERROR = "EXPORTHISTORY_ERROR";
export const EXPORTHISTORY_REQUEST_WITHOUT_LOADING = "EXPORTHISTORY_REQUEST_WITHOUT_LOADING";

export const EXPORT_REQUEST = "EXPORT_REQUEST";
export const EXPORT_SUCCESS = "EXPORT_SUCCESS";
export const EXPORT_ERROR = "EXPORT_ERROR";

export const IMPORTHISTORY_REQUEST = "IMPORTHISTORY_REQUEST";
export const IMPORTHISTORY_SUCCESS = "IMPORTHISTORY_SUCCESS";
export const IMPORTHISTORY_ERROR = "IMPORTHISTORY_ERROR";
export const IMPORTHISTORY_REQUEST_WITHOUT_LOADING = "IMPORTHISTORY_REQUEST_WITHOUT_LOADING";

export const IMPORT_REQUEST = "IMPORT_REQUEST";
export const IMPORT_SUCCESS = "IMPORT_SUCCESS";
export const IMPORT_ERROR = "IMPORT_ERROR";

export const SAMPLEFILE_REQUEST = "SAMPLEFILE_REQUEST";
export const SAMPLEFILE_SUCCESS = "SAMPLEFILE_SUCCESS";
export const SAMPLEFILE_ERROR = "SAMPLEFILE_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const DELETE_IMPORT_HISTORY_REQUEST = "DELETE_IMPORT_HISTORY_REQUEST";
export const DELETE_IMPORT_HISTORY_SUCCESS = "DELETE_IMPORT_HISTORY_SUCCESS";
export const DELETE_IMPORT_HISTORY_ERROR = "DELETE_IMPORT_HISTORY_ERROR";
