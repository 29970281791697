import React, { useState, useEffect } from "react";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./style.css";
// import { useDispatch } from 'react-redux';

function CustomUserAndTeamDropdown(props) {
  const [key, setKey] = useState("Users");
  const [show, setShow] = useState(false);
  const [activeUser, setActiveUser] = useState("");

  const [userOptions] = useState(props.options.list);

  const handleSelect = (type, id, name) => {
    setShow(false);
    props.handleSelect(type, id, name);
  };

  useEffect(() => {
    if (userOptions.users && userOptions.users.length > 0) {
      setActiveUser(props.activeUser);
      userOptions.users.forEach((user) => {
        if (user.email === props.activeUser) {
          setActiveUser(user.name ? user.name : user.email);
        }
      });
    }
  }, [props.activeUser, userOptions]);

  return (
    <div className="user-team-dropdown-container">
      <Dropdown className="w-100-mn height-auto" drop="down" alignRight onToggle={() => setShow(!show)} show={show}>
        <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef text-333333 bg-white btn btn-sm dropdown-toggle w-100-mn text-left">{activeUser ? activeUser : "User / Team"}</Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu p-0 pt-2 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md w-100-mn">
          <Tab.Container defaultActiveKey={key}>
            <Nav as="ul" className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-2x nav-tabs-primary flex-grow-1 justify-content-center px-1" onSelect={(_key) => setKey(_key)}>
              <Nav.Item className="nav-item w-33" as="li">
                <Nav.Link eventKey="Users" className={`nav-link show justify-content-center m-0 ${key === "Users" ? "active" : ""}`}>
                  Users
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item w-33" as="li">
                <Nav.Link eventKey="Teams" className={`nav-link show justify-content-center m-0 ${key === "Teams" ? "active" : ""}`}>
                  Teams
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="Users">
                <PerfectScrollbar className="scroll position-relative h-35vh-mx" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                  {userOptions.users && userOptions.users.length > 1 && (
                    <Dropdown.Item className="dropdown-hover" key={"index"} onSelect={() => handleSelect("organization", "", "All Users")}>
                      <div className="w-100 d-flex flex-stack">{"All Users"}</div>
                    </Dropdown.Item>
                  )}
                  {userOptions.users &&
                    userOptions.users.map((user, index) => {
                      return (
                        <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => handleSelect("individual", user.id, user.email)}>
                          <div className="w-100 d-flex flex-stack">{user.name ? user.name : user.email}</div>
                        </Dropdown.Item>
                      );
                    })}
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Teams">
                <PerfectScrollbar className="scroll position-relative h-35vh-mx" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                  {userOptions.teams &&
                    userOptions.teams.map((team, index) => {
                      return (
                        <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => handleSelect("team", team.id, team.name)}>
                          <div className="w-100 d-flex flex-stack">{team.name}</div>
                        </Dropdown.Item>
                      );
                    })}
                </PerfectScrollbar>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default CustomUserAndTeamDropdown;
