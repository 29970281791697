import {
  COMPANIES_REQUEST,
  COMPANIES_SUCCESS,
  COMPANIES_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  COMPANIESDROPDOWN_REQUEST,
  COMPANIESDROPDOWN_SUCCESS,
  COMPANIESDROPDOWN_ERROR,
  COMPANIESDROPDOWNADD_REQUEST,
  COMPANIESDROPDOWNADD_SUCCESS,
  COMPANIESDROPDOWNADD_ERROR,
  COMPANYFIELDS_REQUEST,
  COMPANYFIELDS_SUCCESS,
  COMPANYFIELDS_ERROR,
  COMPANYADDFIELDS_REQUEST,
  COMPANYADDFIELDS_SUCCESS,
  COMPANYADDFIELDS_ERROR,
  COMPANYTAGS_REQUEST,
  COMPANYTAGS_SUCCESS,
  COMPANYTAGS_ERROR,
  COMPANYTAGSADD_REQUEST,
  COMPANYTAGSADD_SUCCESS,
  COMPANYTAGSADD_ERROR,
  COMPANYTAGSEDIT_REQUEST,
  COMPANYTAGSEDIT_SUCCESS,
  COMPANYTAGSEDIT_ERROR,
  COMPANYTAGSDELETE_REQUEST,
  COMPANYTAGSDELETE_SUCCESS,
  COMPANYTAGSDELETE_ERROR,
  COMPANYCELLEDIT_REQUEST,
  COMPANYCELLEDIT_SUCCESS,
  COMPANYCELLEDIT_ERROR,
  COMPANYADD_REQUEST,
  COMPANYADD_SUCCESS,
  COMPANYADD_ERROR,
  COMPANYBULKEDIT_REQUEST,
  COMPANYBULKEDIT_SUCCESS,
  COMPANYBULKEDIT_ERROR,
  COMPANYDELETE_REQUEST,
  COMPANYDELETE_SUCCESS,
  COMPANYDELETE_ERROR,
  COMPANYFILTERS_REQUEST,
  COMPANYFILTERS_SUCCESS,
  COMPANYFILTERS_ERROR,
  COMPANYFILTERADD_REQUEST,
  COMPANYFILTERADD_SUCCESS,
  COMPANYFILTERADD_ERROR,
  COMPANYFILTEREDIT_REQUEST,
  COMPANYFILTEREDIT_SUCCESS,
  COMPANYFILTEREDIT_ERROR,
  COMPANYFILTERFAVORITE_REQUEST,
  COMPANYFILTERFAVORITE_SUCCESS,
  COMPANYFILTERFAVORITE_ERROR,
  COMPANYFILTERDELETE_REQUEST,
  COMPANYFILTERDELETE_SUCCESS,
  COMPANYFILTERDELETE_ERROR,
  COMPANIES_UPDATE_REQUEST,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_UPDATE_ERROR,
  COMPANY_POPUP_REQUEST,
  COMPANY_POPUP_SUCCESS,
  COMPANY_POPUP_ERROR,
  COMPANY_POPUP_REQUEST_AFTER,
  COMPANY_POPUP_UPDATE_REQUEST,
  COMPANY_POPUP_UPDATE_SUCCESS,
  COMPANY_POPUP_UPDATE_ERROR,
  COMPANY_POPUP_EDIT_REQUEST,
  COMPANY_POPUP_EDIT_SUCCESS,
  COMPANY_POPUP_EDIT_ERROR,
  LOADING_DATA_UPDATE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  TIMEZONES_ERROR,
  TIMEZONES_SUCCESS,
  TIMEZONES_REQUEST,
  COMPANIESMERGE_REQUEST,
  COMPANIESMERGE_SUCCESS,
  COMPANIESMERGE_ERROR,
  COMPANIESDROPDOWNADD_CLEAR,
  APPLYFILTER,
  CLEARFILTER,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  FIELD_SHOWHIDE_REQUEST,
  FIELD_SHOWHIDE_SUCCESS,
  FIELD_SHOWHIDE_ERROR,
  FIELD_UPDATEPRIORITY_REQUEST,
  FIELD_UPDATEPRIORITY_SUCCESS,
  FIELD_UPDATEPRIORITY_ERROR,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_SUCCESS,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_ERROR,
  SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST,
  SEARCH_UNLINKEDDATA_FORCOMPANY_SUCCESS,
  SEARCH_UNLINKEDDATA_FORCOMPANY_ERROR,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_SUCCESS,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_ERROR,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_SUCCESS,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_ERROR,
  generateCompanyAIWFData
} from "../types/types";

//UNLINK LEAD DEAL PEOPLE FROM COMPANY
export function unlinkLeadDealPeopleFromCompanyRequest(req) {
  return {
    type: UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST,
    payload: req,
  };
}
export function unlinkLeadDealPeopleFromCompanySuccess(res) {
  return {
    type: UNLINK_LEADDEALPEOPLE_FROMCOMPANY_SUCCESS,
    payload: res,
  };
}
export function unlinkLeadDealPeopleFromCompanyError(err) {
  return {
    type: UNLINK_LEADDEALPEOPLE_FROMCOMPANY_ERROR,
    payload: err,
  };
}

//LINK LEAD DEAL PEOPLE WITH COMPANY
export function linkLeadDealPeopleWithCompanyRequest(req) {
  return {
    type: LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST,
    payload: req,
  };
}
export function linkLeadDealPeopleWithCompanySuccess(res) {
  return {
    type: LINK_LEADDEALPEOPLE_WITHCOMPANY_SUCCESS,
    payload: res,
  };
}
export function linkLeadDealPeopleWithCompanyError(err) {
  return {
    type: LINK_LEADDEALPEOPLE_WITHCOMPANY_ERROR,
    payload: err,
  };
}

//search unlinked data for comapny side panel
export function searchUnlinkedDataForCompanySidepanelRequest(req) {
  return {
    type: SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST,
    payload: req,
  };
}
export function searchUnlinkedDataForCompanySidepanelSuccess(res) {
  return {
    type: SEARCH_UNLINKEDDATA_FORCOMPANY_SUCCESS,
    payload: res,
  };
}
export function searchUnlinkedDataForCompanySidepanelError(err) {
  return {
    type: SEARCH_UNLINKEDDATA_FORCOMPANY_ERROR,
    payload: err,
  };
}

//load more linked data
export function loadMoreLinkedDataRequest(req, cb) {
  return {
    type: LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST,
    payload: req,
    cb,
  };
}
export function loadMoreLinkedDataSuccess(res) {
  return {
    type: LOADMORELINKEDDATA_FORSELECTEDCOMPANY_SUCCESS,
    payload: res,
  };
}
export function loadMoreLinkedDataError(err) {
  return {
    type: LOADMORELINKEDDATA_FORSELECTEDCOMPANY_ERROR,
    payload: err,
  };
}

// Getting company data
export function companiesRequest(req) {
  return {
    type: COMPANIES_REQUEST,
    payload: req,
  };
}

export function companiesSuccess(res) {
  return {
    type: COMPANIES_SUCCESS,
    payload: res,
  };
}

export function companiesError(err) {
  return {
    type: COMPANIES_ERROR,
    payload: err,
  };
}

// Getting company popup data
export function companyPopupRequest(req) {
  return {
    type: COMPANY_POPUP_REQUEST,
    payload: req,
  };
}

export function companyPopupRequestAfter(req) {
  return {
    type: COMPANY_POPUP_REQUEST_AFTER,
    payload: req,
  };
}

export function companyPopupSuccess(res) {
  return {
    type: COMPANY_POPUP_SUCCESS,
    payload: res,
  };
}

export function companyPopupError(err) {
  return {
    type: COMPANY_POPUP_ERROR,
    payload: err,
  };
}

// Editing company popup data
export function companyPopupEditRequest(req) {
  return {
    type: COMPANY_POPUP_EDIT_REQUEST,
    payload: req,
  };
}

export function companyPopupEditSuccess(res) {
  return {
    type: COMPANY_POPUP_EDIT_SUCCESS,
    payload: res,
  };
}

export function companyPopupEditError(err) {
  return {
    type: COMPANY_POPUP_EDIT_ERROR,
    payload: err,
  };
}

// Updating company popup data
export function companyPopupUpdateRequest(req) {
  return {
    type: COMPANY_POPUP_UPDATE_REQUEST,
    payload: req,
  };
}

export function companyPopupUpdateSuccess(res) {
  return {
    type: COMPANY_POPUP_UPDATE_SUCCESS,
    payload: res,
  };
}

export function companyPopupUpdateError(err) {
  return {
    type: COMPANY_POPUP_UPDATE_ERROR,
    payload: err,
  };
}

// Getting company data without loading
export function companiesUpdateRequest(req) {
  return {
    type: COMPANIES_UPDATE_REQUEST,
    payload: req,
  };
}

export function companiesUpdateSuccess(res) {
  return {
    type: COMPANIES_UPDATE_SUCCESS,
    payload: res,
  };
}

export function companiesUpdateError(err) {
  return {
    type: COMPANIES_UPDATE_ERROR,
    payload: err,
  };
}

// Update loading data
export function loadingDataUpdateRequest(req) {
  return {
    type: LOADING_DATA_UPDATE,
    payload: req,
  };
}

// Clearing error and success store
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  };
}

// Getting company dropdown data
export function companiesDropdownRequest(req) {
  return {
    type: COMPANIESDROPDOWN_REQUEST,
    payload: req,
  };
}

export function companiesDropdownSuccess(res) {
  return {
    type: COMPANIESDROPDOWN_SUCCESS,
    payload: res,
  };
}

export function companiesDropdownError(err) {
  return {
    type: COMPANIESDROPDOWN_ERROR,
    payload: err,
  };
}

// Getting column details
export function companyFieldsRequest(req) {
  return {
    type: COMPANYFIELDS_REQUEST,
    payload: req,
  };
}

export function companyFieldsSuccess(res) {
  return {
    type: COMPANYFIELDS_SUCCESS,
    payload: res,
  };
}

export function companyFieldsError(err) {
  return {
    type: COMPANYFIELDS_ERROR,
    payload: err,
  };
}

// Getting Add column details
export function companyAddFieldsRequest(req) {
  return {
    type: COMPANYADDFIELDS_REQUEST,
    payload: req,
  };
}

export function companyAddFieldsSuccess(res) {
  return {
    type: COMPANYADDFIELDS_SUCCESS,
    payload: res,
  };
}

export function companyAddFieldsError(err) {
  return {
    type: COMPANYADDFIELDS_ERROR,
    payload: err,
  };
}

// Adding company
export function companyAddRequest(req, cb) {
  return {
    type: COMPANYADD_REQUEST,
    payload: req,
    cb,
  };
}

export function companyAddSuccess(res) {
  return {
    type: COMPANYADD_SUCCESS,
    payload: res,
  };
}

// Dropdown add company
export function companiesDropdownAddRequest(req) {
  return {
    type: COMPANIESDROPDOWNADD_REQUEST,
    payload: req,
  };
}

export function companiesDropdownAddSuccess(res) {
  return {
    type: COMPANIESDROPDOWNADD_SUCCESS,
    payload: res,
  };
}

export function companiesDropdownAddError(err) {
  return {
    type: COMPANIESDROPDOWNADD_ERROR,
    payload: err,
  };
}

export function companiesDropdownAddClear(res) {
  return {
    type: COMPANIESDROPDOWNADD_CLEAR,
    payload: res,
  };
}

export function companyAddError(err) {
  return {
    type: COMPANYADD_ERROR,
    payload: err,
  };
}

// Bulk Editing company
export function companyBulkEditRequest(req) {
  return {
    type: COMPANYBULKEDIT_REQUEST,
    payload: req,
  };
}

export function companyBulkEditSuccess(res) {
  return {
    type: COMPANYBULKEDIT_SUCCESS,
    payload: res,
  };
}

export function companyBulkEditError(err) {
  return {
    type: COMPANYBULKEDIT_ERROR,
    payload: err,
  };
}

// Deleting company
export function companyDeleteRequest(req) {
  return {
    type: COMPANYDELETE_REQUEST,
    payload: req,
  };
}

export function companyDeleteSuccess(res) {
  return {
    type: COMPANYDELETE_SUCCESS,
    payload: res,
  };
}

export function companyDeleteError(err) {
  return {
    type: COMPANYDELETE_ERROR,
    payload: err,
  };
}

// Getting tag details
export function companyTagsRequest(req) {
  return {
    type: COMPANYTAGS_REQUEST,
    payload: req,
  };
}

export function companyTagsSuccess(res) {
  return {
    type: COMPANYTAGS_SUCCESS,
    payload: res,
  };
}

export function companyTagsError(err) {
  return {
    type: COMPANYTAGS_ERROR,
    payload: err,
  };
}

// Adding Tag
export function companyTagsAddRequest(req) {
  return {
    type: COMPANYTAGSADD_REQUEST,
    payload: req,
  };
}

export function companyTagsAddSuccess(res) {
  return {
    type: COMPANYTAGSADD_SUCCESS,
    payload: res,
  };
}

export function companyTagsAddError(err) {
  return {
    type: COMPANYTAGSADD_ERROR,
    payload: err,
  };
}

// Editing Tag
export function companyTagsEditRequest(req) {
  return {
    type: COMPANYTAGSEDIT_REQUEST,
    payload: req,
  };
}

export function companyTagsEditSuccess(res) {
  return {
    type: COMPANYTAGSEDIT_SUCCESS,
    payload: res,
  };
}

export function companyTagsEditError(err) {
  return {
    type: COMPANYTAGSEDIT_ERROR,
    payload: err,
  };
}

// Deleting Tag
export function companyTagsDeleteRequest(req) {
  return {
    type: COMPANYTAGSDELETE_REQUEST,
    payload: req,
  };
}

export function companyTagsDeleteSuccess(res) {
  return {
    type: COMPANYTAGSDELETE_SUCCESS,
    payload: res,
  };
}

export function companyTagsDeleteError(err) {
  return {
    type: COMPANYTAGSDELETE_ERROR,
    payload: err,
  };
}

// Editing Cell
export function companyCellEditRequest(req) {
  return {
    type: COMPANYCELLEDIT_REQUEST,
    payload: req,
  };
}

export function companyCellEditSuccess(res) {
  return {
    type: COMPANYCELLEDIT_SUCCESS,
    payload: res,
  };
}

export function companyCellEditError(err) {
  return {
    type: COMPANYCELLEDIT_ERROR,
    payload: err,
  };
}

// Getting filter data
export function companyFiltersRequest(req) {
  return {
    type: COMPANYFILTERS_REQUEST,
    payload: req,
  };
}

export function companyFiltersSuccess(res) {
  return {
    type: COMPANYFILTERS_SUCCESS,
    payload: res,
  };
}

export function companyFiltersError(err) {
  return {
    type: COMPANYFILTERS_ERROR,
    payload: err,
  };
}

// Adding Filter
export function companyFilterAddRequest(req, cb) {
  return {
    type: COMPANYFILTERADD_REQUEST,
    payload: req,
    cb,
  };
}

export function companyFilterAddSuccess(res) {
  return {
    type: COMPANYFILTERADD_SUCCESS,
    payload: res,
  };
}

export function companyFilterAddError(err) {
  return {
    type: COMPANYFILTERADD_ERROR,
    payload: err,
  };
}

// Editing Filter
export function companyFilterEditRequest(req, cb) {
  return {
    type: COMPANYFILTEREDIT_REQUEST,
    payload: req,
    cb,
  };
}

export function companyFilterEditSuccess(res) {
  return {
    type: COMPANYFILTEREDIT_SUCCESS,
    payload: res,
  };
}

export function companyFilterEditError(err) {
  return {
    type: COMPANYFILTEREDIT_ERROR,
    payload: err,
  };
}

// Favoriting Filter
export function companyFilterFavoriteRequest(req) {
  return {
    type: COMPANYFILTERFAVORITE_REQUEST,
    payload: req,
  };
}

export function companyFilterFavoriteSuccess(res) {
  return {
    type: COMPANYFILTERFAVORITE_SUCCESS,
    payload: res,
  };
}

export function companyFilterFavoriteError(err) {
  return {
    type: COMPANYFILTERFAVORITE_ERROR,
    payload: err,
  };
}

// Deleting Filter
export function companyFilterDeleteRequest(req) {
  return {
    type: COMPANYFILTERDELETE_REQUEST,
    payload: req,
  };
}

export function companyFilterDeleteSuccess(res) {
  return {
    type: COMPANYFILTERDELETE_SUCCESS,
    payload: res,
  };
}

export function companyFilterDeleteError(err) {
  return {
    type: COMPANYFILTERDELETE_ERROR,
    payload: err,
  };
}

// Getting country data
export function countriesRequest(req) {
  return {
    type: COUNTRIES_REQUEST,
    payload: req,
  };
}

export function countriesSuccess(res) {
  return {
    type: COUNTRIES_SUCCESS,
    payload: res,
  };
}

export function countriesError(err) {
  return {
    type: COUNTRIES_ERROR,
    payload: err,
  };
}

// Getting Timzone data
export function timezonesRequest(req) {
  return {
    type: TIMEZONES_REQUEST,
    payload: req,
  };
}

export function timezonesSuccess(res) {
  return {
    type: TIMEZONES_SUCCESS,
    payload: res,
  };
}

export function timezonesError(err) {
  return {
    type: TIMEZONES_ERROR,
    payload: err,
  };
}

// Merging
export function companiesMergeRequest(req) {
  return {
    type: COMPANIESMERGE_REQUEST,
    payload: req,
  };
}

export function companiesMergeSuccess(res) {
  return {
    type: COMPANIESMERGE_SUCCESS,
    payload: res,
  };
}

export function companiesMergeError(err) {
  return {
    type: COMPANIESMERGE_ERROR,
    payload: err,
  };
}

export function applyFilter(res) {
  return {
    type: APPLYFILTER,
    payload: res,
  };
}

export function clearFilter(res) {
  return {
    type: CLEARFILTER,
    payload: res,
  };
}

// Update fields
export function fieldUpdateRequest(req) {
  return {
    type: FIELD_UPDATE_REQUEST,
    payload: req,
  };
}

export function fieldUpdateSuccess(res) {
  return {
    type: FIELD_UPDATE_SUCCESS,
    payload: res,
  };
}

export function fieldUpdateError(err) {
  return {
    type: FIELD_UPDATE_ERROR,
    payload: err,
  };
}

// show/hide field
export function fieldShowHideRequest(req) {
  return {
    type: FIELD_SHOWHIDE_REQUEST,
    payload: req,
  };
}

export function fieldShowHideSuccess(res) {
  return {
    type: FIELD_SHOWHIDE_SUCCESS,
    payload: res,
  };
}

export function fieldShowHideError(err) {
  return {
    type: FIELD_SHOWHIDE_ERROR,
    payload: err,
  };
}

// update field priority
export function fieldUpdatePriorityRequest(req) {
  return {
    type: FIELD_UPDATEPRIORITY_REQUEST,
    payload: req,
  };
}

export function fieldUpdatePrioritySuccess(res) {
  return {
    type: FIELD_UPDATEPRIORITY_SUCCESS,
    payload: res,
  };
}

export function fieldUpdatePriorityError(err) {
  return {
    type: FIELD_UPDATEPRIORITY_ERROR,
    payload: err,
  };
}

export const generateCompanyAIWFDataRequest = (req) => ({
  type: generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_REQUEST,
  payload: req,
})
export const generateCompanyAIWFDataSuccess = (res) => ({
  type: generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_SUCCESS,
  payload: res,
})
export const generateCompanyAIWFDataError = (err) => ({
  type: generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_ERROR,
  payload: err,
})