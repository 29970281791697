import React from "react";
import StepDayPopover from "../day-popover";
import { StepChannelColorMapping } from "../../constants";
import CustomSwitch from "../../../../../components/custom_form/switch";

import "./timeline-step.scss";
import EmailViewCard from "./views/email.view";
import TaskViewCard from "./views/task.view";
import { IconButton2 } from "../../../../../components/button/icon-action-buttons";

export default function TimelineStep(props) {
  // const showSendAsNewThread = () => {
  //   let flag = false;
  //   props.steps.forEach((each, ind) => {
  //     if (ind < props.index && each.channelType === "EMAIL") {
  //       flag = true;
  //     }
  //   });
  //   if (flag && props.step.channelType === "EMAIL" && !props.step.abTesting) {
  //     return true;
  //   } else return false;
  // };

  const handleDisableStep = (step, value) => {
    props.handleStepUpdate(props.index, { ...step, isEnabled: value });
  };

  // const handleNewThread = (step, value) => {
  //   props.handleStepUpdate(props.index, { ...step, emailContent: { ...step.emailContent, isNewThread: value } });
  // };

  const handleStepOrderChange = (step, index, action) => {
    if (action === "up") {
      props.handleStepOrderChange(step, index, index - 1);
    } else {
      props.handleStepOrderChange(step, index, index + 1);
    }
  };

  return (
    <div className="timeline-item cadence-timeline-step-wrapper">
      <>
        <div className="timeline-line w-30px custom-timeline-line"></div>
        <div className="timeline-icon symbol symbol-circle symbol-30px me-4">
          <div className={`symbol-label ${StepChannelColorMapping[props.step.channelType].bg}`}>
            <i
              className={`
            ${StepChannelColorMapping[props.step.channelType].icon} 
            ${StepChannelColorMapping[props.step.channelType].color} 
            font-size-12px`}
            ></i>
          </div>
        </div>
      </>
      <div className="timeline-content mb-4 mt-n1">
        <div className="d-flex justify-content-between align-items-center">
          <div className="step-right-info">
            <div className="step-number mr-2">{`Step ${props.index + 1} - `}</div>
            <div className="step-number text-primary mr-2">{`${props.step.channelType} - `}</div>
            <StepDayPopover index={props.index} step={props.step} steps={props.steps} handleStepDelay={props.handleStepDelay} />
          </div>
          <div className="d-flex gap-4 align-items-center">
            {props.index !== 0 && (
              <IconButton2 className="cursor-pointer import-icon-container text-hover-primary bg-hover-light-primary" onClick={() => handleStepOrderChange(props.step, props.index, "up")} tooltip={"Move Step Up"}>
                <i class="bi bi-arrow-up-square text-black"></i>
              </IconButton2>
            )}
            {props.index !== props.steps.length - 1 && (
              <IconButton2 className="cursor-pointer import-icon-container text-hover-primary bg-hover-light-primary" onClick={() => handleStepOrderChange(props.step, props.index, "down")} tooltip={"Move Step Down"}>
                <i class="bi bi-arrow-down-square text-black"></i>
              </IconButton2>
            )}
            {/* {showSendAsNewThread() && (
              <div className="switch-button">
                {console.log("step", props.step)}
                <CustomSwitch id="switch-id" label="New thread" checked={props.step.emailContent && props.step.emailContent.isNewThread} handleChange={(e) => handleNewThread(props.step, e.target.checked)} />
              </div>
            )} */}
            {/* {props.step.channelType !== "TASK" && <CustomSwitch id={props.step._id} label="Automatic" checked={props.step.isAutomatic} handleChange={(e) => handleIsAutomaticStep(props.step, e.target.checked)} />} */}
            <CustomSwitch id={props.step._id} label="Enable/Disable" checked={props.step.isEnabled} handleChange={(e) => handleDisableStep(props.step, e.target.checked)} />
          </div>
        </div>
        {props.step.channelType === "EMAIL" ? (
          <EmailViewCard
            isBusinessPlusUser={props.isBusinessPlusUser}
            step={props.step}
            index={props.index}
            handleStepUpdate={props.handleStepUpdate}
            onEditClick={props.onEditClick}
            onDeleteClick={props.onDeleteClick}
            onSubStepEditClick={props.onSubStepEditClick}
            onSubStepDeleteClick={props.onSubStepDeleteClick}
            handleABVarientAddClick={props.handleABVarientAddClick}
          />
        ) : (
          <TaskViewCard step={props.step} index={props.index} onEditClick={props.onEditClick} onDeleteClick={props.onDeleteClick} />
        )}
      </div>
    </div>
  );
}
