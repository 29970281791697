import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import SMSTextEditor from "../../editors/sms-text.editor";
import Select from "react-select";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
};
export default function SMSTemplateForm(props) {
  const { content, setContent } = props;
  const { meta, setMeta } = props;
  const [senderIds, setSenderIds] = useState([]);

  useEffect(() => {
    const _senderIds = props.senderIdOptions.filter((each) => (meta.senderIds || []).indexOf(each.value) !== -1);
    console.log(_senderIds, meta.senderIds, "_senderids");
    setSenderIds(_senderIds);
  }, [meta.senderIds, props.senderIdOptions]);

  const handleSenderIdSelect = (e) => {
    if (e) {
      const countrySet = new Set();
      const values = [];
      e.forEach((each) => {
        values.push(each.value);
        const a = props.senderIdCountryMapping[each.value];
        if (a && _.isArray(a)) {
          a.forEach((iso) => countrySet.add(iso));
        } else {
          countrySet.add("ALL");
        }
      });

      setMeta({ ...meta, senderIds: values, countryIso: Array.from(countrySet) });
    } else {
      setMeta({ ...meta, senderIds: [], countryIso: [] });
    }

    // debugger
    // const a = props.senderIdCountryMapping[e.value];
    // console.log(a, props.senderIdCountryMapping, e, "a");
    // if (a && _.isArray(a)) {
    //   setMeta({ ...meta, senderIds: e.value, countryIso: a });
    // } else {
    //   setMeta({ ...meta, senderIds: e.value, countryIso: ["ALL"] });
    // }
  };

  console.log(meta, "meta");
  return (
    <>
      {/* <Form.Group id="templates-add-edit-template-visibility">
        <Form.Label aria-label="country">Sender ID*</Form.Label>
        <SimpleDropdown value={meta.senderId} label={"Select Sender Id"} options={props.senderIdOptions} handleSelect={handleSenderIdSelect} />
      </Form.Group> */}
      <Form.Group id="campaign-builder-forms-sms-config-form-select-sender-Id">
        <Form.Label aria-label="select-senderId">Sender ID*</Form.Label>
        <Select
          isMulti
          name="senderId"
          value={senderIds}
          styles={customStyles}
          options={props.senderIdOptions}
          className="h-300px-mx z-index-3"
          placeholder="Select Sender ID's"
          onChange={handleSenderIdSelect}
        />
      </Form.Group>
      {meta.countryIso && (meta.countryIso.includes("ALL") || meta.countryIso.includes("IN")) && (
        <Row>
          <Col>
            <Form.Group id="templates-add-edit-template-tag">
              <Form.Label aria-label="country">DLT Template ID</Form.Label>
              <Form.Control maxLength={32} type="text" placeholder="Enter DLT Template ID" value={meta.DLTTemplateId} onChange={(e) => setMeta({ ...meta, DLTTemplateId: e.target.value })} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="templates-add-edit-template-visibility">
              <Form.Label aria-label="country">DLT Entity ID</Form.Label>
              <Form.Control maxLength={32} type="text" placeholder="Enter DLT Sender ID" value={meta.DLTEntityId} onChange={(e) => setMeta({ ...meta, DLTEntityId: e.target.value })} />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Form.Group id="templates-add-edit-template-content">
        <Form.Label aria-label="content-designer-type">Message</Form.Label>
        <SMSTextEditor value={content} onChange={(value) => setContent(value)} defaultVariableValues={props.defaultVariableValues} setDefaultVariableValues={props.setDefaultVariableValues} />
      </Form.Group>
    </>
  );
}
