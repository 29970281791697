import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Button, Form } from "react-bootstrap";
import { CustomModal } from "../../../../../../components/modal";
import { InputLimit } from "../../../../../../constants";
import { dispatchSnackbarError } from "../../../../../../utils/toaster";
import { useDispatch } from "react-redux";
import { applyCouponCodeRequest } from "../../../store/actions/coupon_code_actions";

export default function CouponCodeButton(props) {
  const [show, setShow] = useState(false);
  const [couponCode, setCouponCode] = useState([""]);

  const dispatch = useDispatch();

  const handleInputChange = (value, index) => {
    const _couponCode = [...couponCode];
    _couponCode[index] = value;
    setCouponCode(_couponCode);
  };
  const handleAddMoreCode = () => {
    setCouponCode([...couponCode, ""]);
  };
  const handleRemoveCode = (index) => {
    setCouponCode(couponCode.filter((e, ind) => ind !== index));
  };

  const handleCancel = () => {
    setShow(false);
  };
  const handleAccept = () => {
    for (const code of couponCode) {
      if (isEmpty(code)) {
        return dispatchSnackbarError("Please enter valid coupon code");
      }
    }
    dispatch(applyCouponCodeRequest({ couponCodes: couponCode }));
    setShow(false);
  };
  const autoRechargeModelProps = {
    showButtons: true,
    show: show,
    title: "Coupon Code",
    size: "md",
    buttonText: "Apply",
    handleClose: handleCancel,
    handleAccept: handleAccept,
  };
  return (
    <React.Fragment>
      <div onClick={() => setShow(true)} className="text-primary bg-white bg-hover-light d-block btn cursor-pointer text-right">
        Have a coupon code?
      </div>
      <CustomModal modalProps={autoRechargeModelProps}>
        <Form.Group>
          <Form.Label>Coupon Code</Form.Label>
          {couponCode.map((each, index) => {
            return (
              <div className="d-flex flex-stack mb-2" key={index}>
                <Form.Control value={each} maxLength={InputLimit.TEXT} type="text" placeholder="Enter Coupon Code" onChange={(e) => handleInputChange(e.target.value, index)} />

                {couponCode.length > 1 && (
                  <Button className="pl-3 pr-0" onClick={() => handleRemoveCode(index)} size="sm" variant="transparent">
                    <i className="fa fa-minus-circle remove-condition-icon"></i>
                  </Button>
                )}
              </div>
            );
          })}
          {couponCode.length < 10 && (
            <Button onClick={handleAddMoreCode} className="text-decoration-none" variant="link">
              + Add Coupon Code
            </Button>
          )}
        </Form.Group>
      </CustomModal>
    </React.Fragment>
  );
}
