import React from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { customColorMapping } from "../../custom-tables/constants";
import "../display.css";

const loadingKeys = {
  proccessing: true,
  "in progress": true,
  // submitted: true,
  "in queue": true,
  sending: true,
};

export default function StatusDisplay(props) {
  const keyName = customColorMapping[props.data ? props.data.toLowerCase() : props.data];

  if (!props.data) return <></>;
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
      <span className={`w-250px-mx data-container overflow-hidden text-overflow-ellipsis ${!loadingKeys[props.data.toLowerCase()] ? `badge badge-dark bg-${keyName}` : `badge badge-dark bg-${keyName}`}`}>
        {loadingKeys[props.data.toLowerCase()] && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
        {props.data}
      </span>
    </OverlayTrigger>
  );
}
