export const GET_QUESTION_FIELDS_REQUEST = "GET_QUESTION_FIELDS_REQUEST";
export const GET_QUESTION_FIELDS_SUCCESS = "GET_QUESTION_FIELDS_SUCCESS";
export const GET_QUESTION_FIELDS_ERROR = "GET_QUESTION_FIELDS_ERROR";
/*types for getting the question fields*/

export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";
/*types for getting the tags*/

export const GET_OWNER_REQUEST = "GET_OWNER_REQUEST";
export const GET_OWNER_SUCCESS = "GET_OWNER_SUCCESS";
export const GET_OWNER_ERROR = "GET_OWNER_ERROR";

/*types for getting the leadbot data*/

export const GET_LEADBOT_DATA_REQUEST = "GET_LEADBOT_DATA_REQUEST";
export const GET_LEADBOT_DATA_SUCCESS = "GET_LEADBOT_DATA_SUCCESS";
export const GET_LEADBOT_DATA_ERROR = "GET_LEADBOT_DATA_ERROR";

/*types for editing the leadbot data*/

export const EDIT_LEADBOT_DATA_REQUEST = "EDIT_LEADBOT_DATA_REQUEST";
export const EDIT_LEADBOT_DATA_SUCCESS = "EDIT_LEADBOT_DATA_SUCCESS";
export const EDIT_LEADBOT_DATA_ERROR = "EDIT_LEADBOT_DATA_ERROR";

/*types for getting list of lead bots*/

export const GET_LEADBOTS_LIST_REQUEST = "GET_LEADBOTS_LIST_REQUEST";
export const GET_LEADBOTS_LIST_SUCCESS = "GET_LEADBOTS_LIST_SUCCESS";
export const GET_LEADBOTS_LIST_ERROR = "GET_LEADBOTS_LIST_ERROR";

/*create lead bot*/

export const CREATE_LEADBOT_REQUEST = "CREATE_LEADBOT_REQUEST";
export const CREATE_LEADBOT_SUCCESS = "CREATE_LEADBOT_SUCCESS";
export const CREATE_LEADBOT_ERROR = "CREATE_LEADBOT_ERROR";

/*delete lead bot*/

export const DELETE_LEADBOT_REQUEST = "DELETE_LEADBOT_REQUEST";
export const DELETE_LEADBOT_SUCCESS = "DELETE_LEADBOT_SUCCESS";
export const DELETE_LEADBOT_ERROR = "DELETE_LEADBOT_ERROR";

export const UPLOAD_LEADBOT_IMAGE_REQUEST = "UPLOAD_LEADBOT_IMAGE_REQUEST";
export const UPLOAD_LEADBOT_IMAGE_SUCCESS = "UPLOAD_LEADBOT_IMAGE_SUCCESS";
export const UPLOAD_LEADBOT_IMAGE_ERROR = "UPLOAD_LEADBOT_IMAGE_ERROR";
