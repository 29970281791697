import React from "react";

//styles
import "./styles.scss";

export default function ModuleStep(props) {
  const moduleList = [
    {
      name: "Lead",
      keyName: "lead",
      icon: "bi bi-bullseye",
    },
    {
      name: "People",
      keyName: "person",
      icon: "bi-people",
    },
    {
      name: "Company",
      keyName: "company",
      icon: "bi bi-building",
    },
    {
      name: "Deal",
      keyName: "deal",
      icon: "bi bi-basket2",
    },
  ];

  // checked={props.moduleName === each.keyName}
  return (
    <div className="card module-wrapper">
      <div className="module-header-text">Select Module</div>
      <div className="w-100 d-flex gap-4 flex-wrap ">
        {moduleList.map((each, index) => (
          <div
            className={`module-wrapper-card d-flex flex-center ${props.moduleName === each.keyName ? "active" : ""}`}
            key={index}
            onClick={() => {
              props.setModuleName(each.keyName);
            }}
          >
            <div className="icon-wrapper">
              {" "}
              <i className={`${each.icon}`}></i>
            </div>
            <span className="text-wrapper">{each.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
