import React, { useState } from "react";
import Papa from "papaparse";
import * as FileSaver from "file-saver";
import UploadCSVFile from "../../../../../components/file-upload/csv-upload";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import { getDataPresentFields } from "../../../../import/helper";
import { useDispatch } from "react-redux";
import { uploadTemporaryFileRequest } from "../../../store/actions/campaign_details_actions";
import { Button, Spinner } from "react-bootstrap";

export default function AudienceFileUpload({ file, setFile, totalAudience, setAudienceCount, type, setUploadedFileUrl, fileUploadLoading, setFileUploadLoading, setUploadedFileVariables }) {
  const dispatch = useDispatch();

  const [emailCsvData] = useState([{ email: "email@gmail.com" }]);
  const [phoneCsvData] = useState([{ phone: "919876543210" }]);

  const uploadFile = (files) => {
    const formData = new FormData();
    formData.append("file", files);
    setFileUploadLoading(true);
    dispatch(
      uploadTemporaryFileRequest(formData, (data) => {
        if (data.status) {
          setUploadedFileUrl(data.url);
        } else {
          dispatchSnackbarError(data.message || "Error while uploading file");
        }
        setFileUploadLoading(false);
      }),
    );
  };

  const handleFileChange = (_file) => {
    if (_file) {
      if (_file.name && _file.name.substr(_file.name.length - 3) === "csv") {
        if (_file.type !== "text/csv") {
          setFile();
          setAudienceCount(0);
          setUploadedFileUrl("");
          dispatchSnackbarError("We only accept comma-delimited CSV files. Please select a valid CSV file.");
        } else {
          Papa.parse(_file, {
            error: () => {
              dispatchSnackbarError("Could Not Parse The File!");
              setFile();
            },
            complete: (data) => {
              if (data && data.data && Array.isArray(data.data) && data.data.length > 1) {
                const validKeys = getDataPresentFields(data.data);
                if (validKeys.indexOf(type) === -1) {
                  dispatchSnackbarError("The uploaded file is missing the necessary header information!");
                  setFile();
                  setAudienceCount(0);
                  setUploadedFileUrl("");
                  setUploadedFileVariables(validKeys);
                } else {
                  setFile(_file);
                  uploadFile(_file);
                  setAudienceCount(data.data.length - 1);
                  setUploadedFileVariables(validKeys);
                }
              } else {
                dispatchSnackbarError("There is no data in the uploaded file to process!");
                setFile();
                setAudienceCount(0);
                setUploadedFileUrl("");
              }
            },
          });
        }
      } else {
        setFile();
        setAudienceCount(0);
        setUploadedFileUrl("");
        dispatchSnackbarError("Only .csv files are supported");
      }
    } else {
      setFile();
      setAudienceCount(0);
      setUploadedFileUrl("");
    }
  };

  const handleSampleCSV = () => {
    const csv = Papa.unparse(type === "email" ? emailCsvData : phoneCsvData, { newline: "\n" });
    const csvData1 = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(csvData1, "data.csv");
  };

  return (
    <div className="card mt-8 p-6">
      <UploadCSVFile file={file} setFile={handleFileChange} acceptedFiles={".csv"} />
      <div className="d-flex flex-stack mt-4">
        <div>
          <span>Total Audience: {totalAudience}</span>
          {fileUploadLoading && (
            <span className="ml-3 text-419eff">
              <Spinner animation="border" size="sm" variant="primary" /> Uploading File...
            </span>
          )}
        </div>
        <div className="d-flex gap-3">
          <Button variant="link" size="sm" onClick={() => handleSampleCSV()}>
            Download Sample File
          </Button>
        </div>
      </div>
    </div>
  );
}
