import {
  GET_LIST_OF_DASHBOARDS_REQUEST,
  GET_LIST_OF_DASHBOARDS_SUCCESS,
  GET_LIST_OF_DASHBOARDS_ERROR,
  CREATE_DASHBOARD_REQUEST,
  CREATE_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_ERROR,
  EDIT_DASHBOARD_REQUEST,
  EDIT_DASHBOARD_SUCCESS,
  EDIT_DASHBOARD_ERROR,
  DELETE_DASHBOARD_REQUEST,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_ERROR,
  EDIT_DASHBOARD_LAYOUT_REQUEST,
  EDIT_DASHBOARD_LAYOUT_SUCCESS,
  EDIT_DASHBOARD_LAYOUT_ERROR,
  GET_SELECTED_DASHBOARD_REQUEST,
  GET_SELECTED_DASHBOARD_SUCCESS,
  GET_SELECTED_DASHBOARD_ERROR,
  CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST,
  CREATE_INSIGHT_DASHBOARD_REPORT_SUCCESS,
  CREATE_INSIGHT_DASHBOARD_REPORT_ERROR,
  EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST,
  EDIT_INSIGHT_DASHBOARD_REPORT_SUCCESS,
  EDIT_INSIGHT_DASHBOARD_REPORT_ERROR,
  DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST,
  DELETE_INSIGHT_DASHBOARD_REPORT_SUCCESS,
  DELETE_INSIGHT_DASHBOARD_REPORT_ERROR,
  GET_INSIGHTS_GRAPH_REPORT_REQUEST,
  GET_INSIGHTS_GRAPH_REPORT_SUCCESS,
  GET_INSIGHTS_GRAPH_REPORT_ERROR,
  GET_INSIGHT_GOAL_REQUEST,
  GET_INSIGHT_GOAL_SUCCESS,
  GET_INSIGHT_GOAL_ERROR,
  GET_LIST_OF_PIPELINES_REQUEST,
  GET_LIST_OF_PIPELINES_SUCCESS,
  GET_LIST_OF_PIPELINES_ERROR,
  GET_LIST_OF_USERS_REQUEST,
  GET_LIST_OF_USERS_SUCCESS,
  GET_LIST_OF_USERS_ERROR,
  TYPE_OF_REPORT_REQUEST,
  TYPE_OF_REPORT_SUCCESS,
  TYPE_OF_REPORT_ERROR,
  USER_ROLE_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_ERROR,
} from "../../types";

//users and pipelines used here only used in insights page only
const initialState = {
  dashboard: {
    list: [],
    loading: false,
    error: "",
  },
  selectedDashboard: {
    data: {},
    reportDetails: [],
    loading: false,
    error: "",
  },
  goal: {
    list: [],
    loading: false,
    error: "",
  },
  users: {
    list: [],
    loading: false,
    error: "",
  },
  pipelines: {
    list: [],
    loading: false,
    error: "",
  },
  typeOfReport: {
    list: [],
    loading: false,
    error: "",
  },
  userRole: {
    list: {
      allUsers: [],
      teams: [],
      users: [],
    },
    loading: false,
    error: "",
  },
};

export function InsightReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_LIST_OF_DASHBOARDS_REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
        },
      };
    case GET_LIST_OF_DASHBOARDS_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          list: action.payload.data,
        },
      };
    case GET_LIST_OF_DASHBOARDS_ERROR:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_DASHBOARD_REQUEST:
      return {
        ...state,
      };
    case CREATE_DASHBOARD_SUCCESS:
      return {
        ...state,
      };
    case CREATE_DASHBOARD_ERROR:
      return {
        ...state,
      };
    case EDIT_DASHBOARD_REQUEST:
      return {
        ...state,
      };
    case EDIT_DASHBOARD_SUCCESS:
      return {
        ...state,
      };
    case EDIT_DASHBOARD_ERROR:
      return {
        ...state,
      };
    case DELETE_DASHBOARD_REQUEST:
      return {
        ...state,
      };
    case DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
      };
    case DELETE_DASHBOARD_ERROR:
      return {
        ...state,
      };
    case GET_SELECTED_DASHBOARD_REQUEST:
      return {
        ...state,
        selectedDashboard: {
          ...state.selectedDashboard,
          loading: true,
        },
      };
    case GET_SELECTED_DASHBOARD_SUCCESS:
      return {
        ...state,
        selectedDashboard: {
          ...state.selectedDashboard,
          loading: false,
          data: action.payload.data,
          reportDetails: action.payload.reportDetails,
        },
      };
    case GET_SELECTED_DASHBOARD_ERROR:
      return {
        ...state,
        selectedDashboard: {
          ...state.selectedDashboard,
          loading: false,
          error: action.payload,
        },
      };
    case EDIT_DASHBOARD_LAYOUT_REQUEST:
      return {
        ...state,
      };
    case EDIT_DASHBOARD_LAYOUT_SUCCESS:
      return {
        ...state,
      };
    case EDIT_DASHBOARD_LAYOUT_ERROR:
      return {
        ...state,
      };
    case CREATE_INSIGHT_DASHBOARD_REPORT_REQUEST:
      return {
        ...state,
      };
    case CREATE_INSIGHT_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
      };
    case CREATE_INSIGHT_DASHBOARD_REPORT_ERROR:
      return {
        ...state,
      };
    case EDIT_INSIGHT_DASHBOARD_REPORT_REQUEST:
      return {
        ...state,
      };
    case EDIT_INSIGHT_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
      };
    case EDIT_INSIGHT_DASHBOARD_REPORT_ERROR:
      return {
        ...state,
      };
    case DELETE_INSIGHT_DASHBOARD_REPORT_REQUEST:
      return {
        ...state,
      };
    case DELETE_INSIGHT_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_INSIGHT_DASHBOARD_REPORT_ERROR:
      return {
        ...state,
      };
    case GET_INSIGHTS_GRAPH_REPORT_REQUEST:
      return {
        ...state,
      };
    case GET_INSIGHTS_GRAPH_REPORT_SUCCESS:
      return {
        ...state,
      };
    case GET_INSIGHTS_GRAPH_REPORT_ERROR:
      return {
        ...state,
      };
    case GET_INSIGHT_GOAL_REQUEST:
      return {
        ...state,
        goal: {
          ...state.goal,
          loading: true,
        },
      };
    case GET_INSIGHT_GOAL_SUCCESS:
      return {
        ...state,
        goal: {
          ...state.goal,
          loading: false,
          list: action.payload.data,
        },
      };
    case GET_INSIGHT_GOAL_ERROR:
      return {
        ...state,
        goal: {
          ...state.goal,
          loading: false,
          error: action.payload,
        },
      };
    case GET_LIST_OF_PIPELINES_REQUEST:
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          loading: true,
        },
      };
    case GET_LIST_OF_PIPELINES_SUCCESS:
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          loading: false,
          list: action.payload.data,
        },
      };
    case GET_LIST_OF_PIPELINES_ERROR:
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          loading: false,
          error: action.payload,
        },
      };
    case GET_LIST_OF_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case GET_LIST_OF_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          list: action.payload.data,
        },
      };
    case GET_LIST_OF_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: action.payload,
        },
      };

    case TYPE_OF_REPORT_REQUEST:
      return {
        ...state,
        typeOfReport: {
          ...state.typeOfReport,
          loading: true,
        },
      };
    case TYPE_OF_REPORT_SUCCESS:
      return {
        ...state,
        typeOfReport: {
          ...state.typeOfReport,
          loading: false,
          list: action.payload.data,
        },
      };
    case TYPE_OF_REPORT_ERROR:
      return {
        ...state,
        typeOfReport: {
          ...state.typeOfReport,
          loading: false,
          error: action.payload,
        },
      };

    case USER_ROLE_REQUEST:
      return {
        ...state,
        userRole: {
          ...state.userRole,
          loading: true,
        },
      };
    case USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: {
          ...state.userRole,
          loading: false,
          list: action.payload.data,
        },
      };
    case USER_ROLE_ERROR:
      return {
        ...state,
        typeOfReport: {
          ...state.typeOfReport,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
