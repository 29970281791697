import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import EmailViewCard from "../components/email-view.card";
import AdavanceDataTable from "../../normal-table/advance-table";
import { count } from "sms-length";

export default function TemplateChannelView(props) {
  const [tagsData, setTagsData] = useState({});
  const tagsStore = useSelector((store) => store.campaign.templates.tags.data);
  const smsTableColoumns = [
    { title: "Template Name", apiKeyName: "name", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Message", apiKeyName: "content", fieldInputType: "INPUT", isAction: false, isFixed: false },
    // { title: "Type", apiKeyName: "encoding", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "SenderId", apiKeyName: "senderIds", fieldInputType: "INPUT", isAction: false, isFixed: false },
    // { title: "Country", apiKeyName: "country", fieldInputType: "INPUT", isAction: false, isFixed: false },
    { title: "Tags", apiKeyName: "tags", fieldInputType: "TAG_DISPLAY", isAction: false, isFixed: false },
    { title: "Created At", apiKeyName: "createdAt", fieldInputType: "DATE_TIME", isAction: false, isFixed: false },
    { title: "Actions", apiKeyName: "action", fieldInputType: "ACTION", isAction: true, isFixed: true, options: props.showChoose ? ["choose", "edit", "delete"] : ["edit", "delete"] },
  ];

  useEffect(() => {
    if (!_.isEmpty(tagsStore)) {
      const aa = {};
      for (const keys in tagsStore) {
        for (const _keys in tagsStore[keys]) {
          tagsStore[keys][_keys].forEach((each) => {
            aa[each._id] = each;
          });
        }
      }
      setTagsData(aa);
    } else {
      setTagsData({});
    }
  }, [tagsStore]);

  if (props.channel === "email") {
    return (
      <div className="row">
        {props.list.map((each, index) => {
          return (
            <EmailViewCard
              data={each}
              key={index}
              tagsData={tagsData}
              showChoose={props.showChoose}
              viewType={props.viewType}
              onCloseClick={props.onCloseClick}
              handleTemplateClone={props.handleTemplateClone}
              onEditTemplateClick={props.onEditTemplateClick}
              onDeleteTenplateClick={props.onDeleteTenplateClick}
            />
          );
        })}
      </div>
    );
  } else if (props.channel === "sms") {
    return (
      <div className="sms-template-table-wrapper py-10px px-10px border-radius-4px border-width-1px border-style-solid border-color-e0e0e0">
        <AdavanceDataTable
          columns={smsTableColoumns}
          data={props.list.map((content) => {
            return {
              ...content,
              _id: content._id,
              name: content.name,
              content: content.content,
              encoding: count(content.content).encoding,
              messageCount: count(content.content).messages,
              // country: content.meta && content.meta.country ? content.meta.country : "",
              createdAt: content.createdAt,
              tags: content.tags.map((each) => tagsData[each]).filter((a) => a),
              senderIds: content.meta && content.meta.senderIds ? content.meta.senderIds.map((each) => props.senderIdMapping[each]).join(", ") || "" : "",
            };
          })}
          limit={props.limit}
          pageNo={props.page}
          setLimit={props.setLimit}
          setPageNo={props.setPage}
          count={props.count}
          //actions
          handleEdit={props.onEditTemplateClick}
          handleDelete={(data) => props.onDeleteTenplateClick(data._id)}
          handleOnChoose={props.handleOnChoose}
        />
      </div>
    );
  } else if (props.channel === "whatsapp") {
    return <>Under Development</>;
  } else if (props.channel === "rcs") {
    return <>Under Development</>;
  }
  return <>Under Development</>;
}
