import { getListOfSMSSenderIds, getSMSSenderIdCountryList } from "../types/campaign_sms_settings_types";

const initState = {
  senderId: {
    data: [],
    loading: false,
    error: false,
  },
  senderCountryList: {
    data: [],
    loading: false,
    error: false,
  },
};

export default function campaignSMSSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_REQUEST:
      return {
        ...state,
        senderId: {
          ...state.senderId,
          loading: true,
        },
      };
    case getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_SUCCESS:
      return {
        ...state,
        senderId: {
          ...state.senderId,
          loading: false,
          data: action.payload.data,
        },
      };
    case getListOfSMSSenderIds.GET_LIST_OF_SMS_SENDER_ID_ERROR:
      return {
        ...state,
        senderId: {
          ...state.senderId,
          loading: false,
          error: action.payload,
        },
      };

    case getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        senderCountryList: {
          ...state.senderCountryList,
          loading: true,
        },
      };
    case getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        senderCountryList: {
          ...state.senderCountryList,
          loading: false,
          data: action.payload.data,
        },
      };
    case getSMSSenderIdCountryList.GET_SMS_SENDER_ID_COUNTRY_LIST_ERROR:
      return {
        ...state,
        senderCountryList: {
          ...state.senderCountryList,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
