import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { GreenGradientButton } from "../../../../../../../components/button";
import { EmailSignatureDropdown } from "../../../../../../../components/email-signature/email-signature-dropdown";
import RichTextEditor from "../../../../../../../components/RichTextEditor/tinymce-editor";
import BuilderComposer from "../../../../../../../components/saino-email-composer/hybrid-email-composer/builder-composer-view";
import { InputLimit } from "../../../../../../../constants";
import EmailConfigForm from "../../../../../../campaign/containers/campaign-builder/Forms/email-config.form";

export default function CampaignEmailForm(props) {
  const [config, setConfig] = useState({
    fromName: "",
    isReplyTo: false,
    replyToName: "",
    replyToEmail: "",
    subject: "",
    fromEmailPrefix: "",
    fromEmailSufix: "",
  });
  useEffect(() => {
    setConfig({
      fromName: props.fromName,
      isReplyTo: props.isReplyTo,
      replyToName: props.replyToName,
      replyToEmail: props.replyToEmail,
      subject: props.subject,
      fromEmailPrefix: props.fromEmailPrefix,
      fromEmailSufix: props.fromEmailSufix,
    });
  }, [props.fromName, props.fromEmail, props.isReplyTo, props.replyToName, props.replyToEmail, props.subject, props.fromEmailPrefix, props.fromEmailSufix]);

  const handleConfigChange = (__config) => {
    props.setFormName(__config.fromName);
    props.setIsReplyTo(__config.isReplyTo);
    props.setReplyToName(__config.replyToName);
    props.setReplyToEmail(__config.replyToEmail);
    props.setSubject(__config.subject);
    props.setFromEmailPrefix(__config.fromEmailPrefix);
    props.setFromEmailSufix(__config.fromEmailSufix);
  };

  return (
    <Form.Group>
      <Form.Label className="mb-8">
        To send emails using the campaign module, set up and test your messages before adding them. Also, keep in mind that the sending domain for your emails will be determined by your default domain, which can be
        configured in the Campaign Module Domain Settings.{" "}
      </Form.Label>
      <Form.Group>
        <Form.Label>Email Name</Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type={"text"} value={props.name} onChange={(e) => props.setName(e.target.value)} name="compose_name" placeholder="Email Name" />
      </Form.Group>
      <EmailConfigForm config={config} setConfig={handleConfigChange} />
      <Form.Label>Body</Form.Label>
      <div className="h-50vh composor-editor-wrapper border">
        {props.editorType === "unlayer" ? (
          <BuilderComposer handleEmailBuilderEdit={() => props.setShowTemplateManager(true)} content={props.builderBody} />
        ) : (
          <RichTextEditor defaultValue={props.defaultBody} value={props.body} onChange={props.setBody} toolbarLocation={"bottom"} />
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between py-2 px-4 border">
        <div className="d-flex align-items-center gap-3">
          <GreenGradientButton size="sm" onClick={() => props.handleSave()}>
            {"Save"}
          </GreenGradientButton>
          {props.editorType !== "unlayer" && <EmailSignatureDropdown setCurrentSignature={props.onSignitureSelection} />}
        </div>
        <div className="d-flex gap-20px align-self-flex-end">
          {props.editorType !== "unlayer" && (
            <div className="btn btn-color-primary btn-active-color-primary m-0 px-0" onClick={() => props.onUnsubsribeClick()}>
              Unsubscribe
            </div>
          )}
          <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => props.setShowAIWizardSidePanel(true)}>
            {" "}
            AI Wizard{" "}
          </button>
          {props.editorType !== "unlayer" && (
            <div className="btn btn-color-primary btn-active-color-primary m-0 px-0" onClick={() => props.setShowVariableOptions(true)}>
              Variables
            </div>
          )}
          <div onClick={(e) => e.stopPropagation()}>
            <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => props.setShowTemplateManager(true)}>
              Template
            </button>
          </div>
        </div>
      </div>
    </Form.Group>
  );
}
