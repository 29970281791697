import React, { useState } from "react";
import _ from "lodash";
import { Dropdown, Form } from "react-bootstrap";
import { DeleteModal } from "../../../../components/modal";
import { dispatchSnackbarError } from "../../../../utils/toaster";

export default function DeleteStageModal(props) {
  const [deleteAction, setDeleteAction] = useState({});
  const [deleteOption, setDeleteOption] = useState("");

  const onDeleteAccept = () => {
    if (_.isEmpty(deleteOption)) {
      return dispatchSnackbarError("Please select option to move ahead.");
    }
    if (deleteOption === "move" && _.isEmpty(deleteAction)) {
      return dispatchSnackbarError("Please select action to move ahead.");
    }
    const data = {
      option: deleteOption,
      action: deleteOption === "move" ? (deleteAction.name ? deleteAction._id : deleteAction.value) : null,
      id: props.selectedStage._id,
    };
    props.setShowDelete(false);
    props.setSelectedStage({});
    setDeleteAction({});
    setDeleteOption("");
    props.onRemoveStage(data);
  };

  const deleteModelProps = {
    show: props.showDelete,
    title: "Delete Pipeline Stage",
    handleClose: () => {
      props.setShowDelete(false);
      setDeleteAction({});
      setDeleteOption("");
    },
    handleAccept: onDeleteAccept,
  };

  return (
    <DeleteModal modalProps={deleteModelProps}>
      <div>
        <div>Choose an option for associated deals of the stage you've chosen.</div>
        <Form.Group
          id="deals-components-add-pipeline-model-pipeline-delete"
          className="m-0 d-flex"
          onClick={(e) => {
            setDeleteOption(e.target.name);
          }}
        >
          <div className="p-2 m-2 radio-container">
            <Form.Check type="radio" label={"Delete Associated Deals"} name={"delete"} checked={deleteOption === "delete"} id={1} />
          </div>
          <div className="p-2 m-2 radio-container">
            <Form.Check type="radio" label={"Move Associated Deals To"} name={"move"} checked={deleteOption === "move"} id={2} />
          </div>
        </Form.Group>
        {deleteOption === "move" && (
          <Form.Group id="deals-components-add-pipeline-model-pipeline-stages">
            <Dropdown className="mt-1 w-100">
              <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                {!_.isEmpty(deleteAction) ? (deleteAction.name ? deleteAction.name : deleteAction.label) : "Select Action"}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100-mn h-300px-mx overflow-y-scroll h-300px-mx">
                <Dropdown.Header>Pipeline Stages</Dropdown.Header>
                {props.stages.map((each, index) => {
                  if (props.selectedStage._id !== each._id)
                    return (
                      <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => setDeleteAction(each)}>
                        {each.name}
                      </Dropdown.Item>
                    );
                  else return <div key={index}></div>;
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        )}
      </div>
    </DeleteModal>
  );
}
