import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { InputLimit } from "../../../constants";
import SendTestEmailPopOver from "../../../containers/campaign/components/send-test-email-popover";
import { dispatchSnackbarError } from "../../../utils/toaster";
import { GreenGradientButton } from "../../button";
import TagSelect from "../../custom_form/tags";
import SimpleDropdown from "../../dropdowns/simple-dropdown";
import CustomSidePanel from "../../side-pannel-detailed/custom-components/custom-side-pannel";
import EmailTemplateBuilder from "../components/email-template.builder";
import { visibilityOptions } from "../constanst";
import EmailTemplateForm from "./forms/email.form";
import SMSTemplateForm from "./forms/sms.form";

export default function AddEditTemplate(props) {
  const [templateName, setTemplateName] = useState("");
  const [showEmailBuilder, setShowEmailBuilder] = useState(false);
  const [visibility, setVisibility] = useState({ value: "private", label: "Private" });
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([""]);
  const [selectedEditor, setSelectedEditor] = useState("");
  const [meta, setMeta] = useState({});
  const [defaultContent, setDefaultContent] = useState("");
  const [defaultVariableValues, setDefaultVariableValues] = useState({});

  useEffect(() => {
    if (!props.isEdit) {
      if (props.channel === "email") {
        setSelectedEditor("tiny_cloud");
      } else if (props.channel === "sms") {
        setSelectedEditor("textbox");
      }
    }
  }, [props.channel, props.isEdit]);

  useEffect(() => {
    if (props.isEdit && !_.isEmpty(props.ediableData)) {
      setTemplateName(props.ediableData.name);
      setShowEmailBuilder(false);
      setVisibility({ value: props.ediableData.visibility, label: _.capitalize(props.ediableData.visibility) });
      setContent(props.ediableData.content);
      setDefaultContent(props.ediableData.content);
      setTags(_.isEmpty(props.ediableData.tags) ? [""] : props.ediableData.tags.map((each) => (_.isString(each) ? each : each._id)));
      setSelectedEditor(props.ediableData.editorType);
      setMeta(props.ediableData.meta ? props.ediableData.meta : {});
      setDefaultVariableValues(props.ediableData.defaultVariableValues ? props.ediableData.defaultVariableValues : {});
    }
  }, [props.isEdit, props.ediableData]);

  const setEmptyAll = () => {
    setTemplateName("");
    setVisibility({ value: "public", label: "Public" });
    setContent("");
    setDefaultContent("");
    setTags([""]);
    setSelectedEditor("tiny_cloud");
    setMeta({});
  };

  const validator = () => {
    if (_.isEmpty(templateName)) {
      return "Template name cannot be empty!";
    }
    if (props.channel === "sms") {
      if (_.isEmpty(meta.senderIds)) return "Please Select Sender ID!";
      else if (meta.senderIds) {
        const _senderIds = props.senderIdOptions.filter((each) => (meta.senderIds || []).indexOf(each.value) !== -1);
        if (_.isEmpty(_senderIds)) {
          return "Please Select Sender ID!";
        }
      }
    }
    return "";
  };

  const handleSave = () => {
    const validate = validator();
    if (validate) {
      return dispatchSnackbarError(validate);
    }

    const payload = {
      name: templateName,
      tags: tags.filter((a) => a),
      channel: props.channel,
      category: props.category,
      editorType: selectedEditor,
      content: content,
      visibility: visibility.value,
      meta: meta,
      defaultVariableValues: defaultVariableValues,
    };

    if (props.isEdit) {
      props.editTemplate(props.ediableData._id, payload);
    } else {
      props.addTemplate(payload);
    }

    setEmptyAll();
  };

  const handleEditorSelector = (value) => {
    setSelectedEditor(value);
    setShowEmailBuilder(true);
  };

  const handleEmailEditorSave = (html, design) => {
    setMeta({ design: design });
    setContent(html);
    setShowEmailBuilder(false);
  };
  const handleEmailBuilderEdit = () => {
    setShowEmailBuilder(true);
  };

  return (
    <div>
      <Form.Group id="templates-add-edit-template-name">
        <Form.Label aria-label="template-name">Template Name</Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Enter Template Name" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
      </Form.Group>
      <Row>
        <Col>
          <Form.Group id="templates-add-edit-template-tag">
            <Form.Label aria-label="tag">Tag</Form.Label>
            <TagSelect
              page={"template"}
              pageSub={"template"}
              addTag={props.addTag}
              editTag={props.editTag}
              deleteTag={props.deleteTag}
              field={{ apiKeyName: "tags", fieldName: "Tags" }}
              value={tags}
              setValue={setTags}
              hideAddText={true}
              meta={{ channel: props.channel, category: props.category }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group id="templates-add-edit-template-visibility">
            <Form.Label aria-label="visibility">Visibility</Form.Label>
            <SimpleDropdown value={visibility} label={""} options={visibilityOptions} handleSelect={setVisibility} />
          </Form.Group>
        </Col>
      </Row>
      {props.channel === "sms" && (
        <SMSTemplateForm
          content={content}
          meta={meta}
          senderIdOptions={props.senderIdOptions}
          setMeta={setMeta}
          setContent={setContent}
          senderIdCountryMapping={props.senderIdCountryMapping}
          defaultVariableValues={defaultVariableValues}
          setDefaultVariableValues={setDefaultVariableValues}
        />
      )}
      {props.channel === "email" && (
        <EmailTemplateForm
          isEdit={props.isEdit}
          handleEditorSelector={handleEditorSelector}
          selectedEditor={selectedEditor}
          content={content}
          setContent={setContent}
          defaultContent={defaultContent}
          handleEmailBuilderEdit={handleEmailBuilderEdit}
          defaultVariableValues={defaultVariableValues}
          setDefaultVariableValues={setDefaultVariableValues}
        />
      )}
      <Form.Group id="templates-add-edit-template-save" className="d-flex justify-content-start gap-3">
        {props.isEdit && props.ediableData && props.ediableData._id && selectedEditor === "unlayer" && <SendTestEmailPopOver showAsButton={true} templateId={props.ediableData._id} />}
        <GreenGradientButton size="sm" onClick={handleSave}>
          {"Save"}
        </GreenGradientButton>
      </Form.Group>
      <CustomSidePanel
        show={showEmailBuilder}
        moduleName={"Email Builder"}
        handleClose={() => {
          if (!content) {
            setSelectedEditor("tiny_cloud");
          }
          setShowEmailBuilder(false);
        }}
      >
        {selectedEditor === "unlayer" && <EmailTemplateBuilder isEdit={props.isEdit} handleSave={handleEmailEditorSave} editId={props.ediableData ? props.ediableData._id : ""} content={content} design={meta.design} />}
      </CustomSidePanel>
    </div>
  );
}
