import { getListOfShortLinks, createShortLink, editShortLink, deleteShortLink, getShortLinkAnalytics, getShortLinkReports } from "../types/campaign_short_link_types";

export const getListOfShortLinksRequest = (payload) => ({
  type: getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_REQUEST,
  payload,
});
export const getListOfShortLinksSuccess = (payload) => ({
  type: getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_SUCCESS,
  payload,
});
export const getListOfShortLinksError = (payload) => ({
  type: getListOfShortLinks.GET_LIST_OF_SHORT_LINKS_ERROR,
  payload,
});

export const createShortLinkRequest = (payload, cb) => ({
  type: createShortLink.CREATE_SHORT_LINK_REQUEST,
  payload,
  cb,
});
export const createShortLinkSuccess = (payload) => ({
  type: createShortLink.CREATE_SHORT_LINK_SUCCESS,
  payload,
});
export const createShortLinkError = (payload) => ({
  type: createShortLink.CREATE_SHORT_LINK_ERROR,
  payload,
});

export const editShortLinkRequest = (payload, cb) => ({
  type: editShortLink.EDIT_SHORT_LINK_REQUEST,
  payload,
  cb,
});
export const editShortLinkSuccess = (payload) => ({
  type: editShortLink.EDIT_SHORT_LINK_SUCCESS,
  payload,
});
export const editShortLinkError = (payload) => ({
  type: editShortLink.EDIT_SHORT_LINK_ERROR,
  payload,
});

export const deleteShortLinkRequest = (payload) => ({
  type: deleteShortLink.DELETE_SHORT_LINK_REQUEST,
  payload,
});
export const deleteShortLinkSuccess = (payload) => ({
  type: deleteShortLink.DELETE_SHORT_LINK_SUCCESS,
  payload,
});
export const deleteShortLinkError = (payload) => ({
  type: deleteShortLink.DELETE_SHORT_LINK_ERROR,
  payload,
});

export const getShortLinkAnalyticsRequest = (payload) => ({
  type: getShortLinkAnalytics.GET_SHORT_LINK_ANALYTICS_REQUEST,
  payload,
});
export const getShortLinkAnalyticsSuccess = (payload) => ({
  type: getShortLinkAnalytics.GET_SHORT_LINK_ANALYTICS_SUCCESS,
  payload,
});
export const getShortLinkAnalyticsError = (payload) => ({
  type: getShortLinkAnalytics.GET_SHORT_LINK_ANALYTICS_ERROR,
  payload,
});

export const getShortLinkReportsRequest = (payload) => ({
  type: getShortLinkReports.GET_SHORT_LINK_REPORTS_REQUEST,
  payload,
});
export const getShortLinkReportsSuccess = (payload) => ({
  type: getShortLinkReports.GET_SHORT_LINK_REPORTS_SUCCESS,
  payload,
});
export const getShortLinkReportsError = (payload) => ({
  type: getShortLinkReports.GET_SHORT_LINK_REPORTS_ERROR,
  payload,
});
