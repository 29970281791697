import React from "react";
import { Form } from "react-bootstrap";
import { GreenGradientButton } from "../../../../../../../components/button";
import CustomSwitch from "../../../../../../../components/custom_form/switch";
import { EmailSignatureDropdown } from "../../../../../../../components/email-signature/email-signature-dropdown";
import RichTextEditor from "../../../../../../../components/RichTextEditor/tinymce-editor";
import BuilderComposer from "../../../../../../../components/saino-email-composer/hybrid-email-composer/builder-composer-view";
import { InputLimit } from "../../../../../../../constants";
// import NylasFromEmail from "../../../../../../../components/saino-email-composer/components/nylas-from-email";

export default function SyncEmailFrom(props) {
  return (
    <Form.Group id="automation-forms-email-notification-form" className="mb-0">
      <div className="saino-email-composer-container">
        <div className=" composor-editor-header">New Email</div>
        <div className="d-block">
          <div className="border-bottom">
            <input
              maxLength={InputLimit.TEXT}
              type={"text"}
              value={props.name}
              onChange={(e) => props.setName(e.target.value)}
              className="form-control border-0 px-8 min-h-45px"
              name="compose_name"
              placeholder="Email Name"
            />
          </div>
          {/* <div>
            <NylasFromEmail value={props.fromEmail} setValue={props.setFromEmail} typeOfSelection={"single"} isRequired={true}/>
          </div> */}
          {props.showNewThreadButton && props.sendType === "EMAIL_SYNC" && (
            <div className="border-bottom d-flex gap-4 px-7 py-4">
              {/* <CustomSwitch id="automatic-switch" label="Is Automatic" checked={isAutomatic} handleChange={(e) => setIsAutomatic(e.target.checked)} /> */}
              <CustomSwitch id="new-therea-switch" label="Send as a new thread" checked={props.isNewThread} handleChange={(e) => props.setIsNewThread(e.target.checked)} />
            </div>
          )}
          {!(!props.isNewThread && props.sendType === "EMAIL_SYNC") && (
            <div className="border-bottom">
              <input
                maxLength={InputLimit.TEXT}
                type={"text"}
                value={props.subject}
                onChange={(e) => props.setSubject(e.target.value)}
                className="form-control border-0 px-8 min-h-45px"
                name="compose_subject"
                placeholder="Subject"
              />
            </div>
          )}
          <div className="h-50vh composor-editor-wrapper  border-top-0 border-left-0 border-right-0">
            {props.editorType === "unlayer" ? (
              <BuilderComposer handleEmailBuilderEdit={() => props.setShowTemplateManager(true)} content={props.builderBody} />
            ) : (
              <RichTextEditor defaultValue={props.defaultBody} value={props.body} onChange={props.setBody} toolbarLocation={"bottom"} />
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between py-2 px-4 border-top">
            <div className="d-flex align-items-center gap-3">
              <GreenGradientButton size="sm" onClick={() => props.handleSave()}>
                {"Save"}
              </GreenGradientButton>
              {props.editorType !== "unlayer" && <EmailSignatureDropdown setCurrentSignature={props.onSignitureSelection} />}
            </div>
            <div className="d-flex gap-20px align-self-flex-end">
              {props.editorType !== "unlayer" && (
                <div className="btn btn-color-primary btn-active-color-primary m-0 px-0" onClick={() => props.onUnsubsribeClick()}>
                  Unsubscribe
                </div>
              )}
              <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => props.setShowAIWizardSidePanel(true)}>
                {" "}
                AI Wizard{" "}
              </button>
              {props.editorType !== "unlayer" && (
                <div className="btn btn-color-primary btn-active-color-primary m-0 px-0" onClick={() => props.setShowVariableOptions(true)}>
                  Variables
                </div>
              )}
              <div onClick={(e) => e.stopPropagation()}>
                <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => props.setShowTemplateManager(true)}>
                  Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form.Group>
  );
}
