import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CadenceAnalyticsStepWise from "./containers/cadence-analytics/cadence_analytics_stepwise";
import CadenceAnalyticsSummarize from "./containers/cadence-analytics/cadence_analytics_summarize";
import CreateCadence from "./containers/cadence-builder";
import CadenceList from "./containers/cadence-list";
import CadenceSubscribers from "./containers/cadence-subscribers";
import CadenceSettings from "./containers/cadence-settings";

export default function CadenceRoutes(props) {
  const { match } = props;

  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/list`} component={CadenceList} />
        <Route exact path={`${match.url}/create`} component={CreateCadence} />
        <Route exact path={`${match.url}/edit/:cadenceId`} component={CreateCadence} />
        <Route exact path={`${match.url}/analytics-stepwise/:cadenceId`} component={CadenceAnalyticsStepWise} />
        <Route exact path={`${match.url}/summary/:cadenceId`} component={CadenceAnalyticsSummarize} />
        <Route exact path={`${match.url}/subscribers/:cadenceId`} component={CadenceSubscribers} />
        <Route exact path={`${match.url}/subscribers/:cadenceId/:moduleName/:linkedId`} component={CadenceSubscribers} />
        <Route exact path={`${match.url}/settings`} component={CadenceSettings} />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </>
  );
}
