import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "./actions";
import {
  EXPORTHISTORY_REQUEST,
  EXPORT_REQUEST,
  IMPORTHISTORY_REQUEST,
  IMPORT_REQUEST,
  SAMPLEFILE_REQUEST,
  DELETE_IMPORT_HISTORY_REQUEST,
  EXPORTHISTORY_REQUEST_WITHOUT_LOADING,
  IMPORTHISTORY_REQUEST_WITHOUT_LOADING,
} from "./types";
import api from "../../network/apis/api";
import { dispatchSnackbarSuccess } from "../../utils/toaster";

function* exportHistoryFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/history/export-history", action.payload);
    if (response.status === 200) {
      yield put(actions.exportHistorySuccess(response.data));
    } else {
      yield put(actions.exportHistoryError({ message: "Could Not Get Export History" }));
    }
  } catch (e) {
    yield put(actions.exportHistoryError({ message: "Could Not Get Export History" }));
  }
}

function* exportFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/history/export", action.payload);
    if (response.status === 200) {
      yield put(actions.exportSuccess({ message: "Data Exported Successfully" }));
    } else {
      yield put(actions.exportError({ message: "Could Not Export Data" }));
    }
  } catch (e) {
    yield put(actions.exportError({ status: false, message: "Could Not Export Data" }));
  }
}

function* importHistoryFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/history/import-history", action.payload);
    if (response.status === 200) {
      yield put(actions.importHistorySuccess(response.data));
    } else {
      yield put(actions.importHistoryError({ message: "Could Not Get Import History" }));
    }
  } catch (e) {
    yield put(actions.importHistoryError({ message: "Could Not Get Import History" }));
  }
}

function* importFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/history/import", action.payload.data);
    if (response.status === 200) {
      yield put(actions.importSuccess({ message: "Data Imported Successfully" }));
      yield put(actions.importHistoryRequest(action.payload.loadingData));
    } else if (response.status === 400) {
      yield put(actions.importError({ message: response.data.message }));
    } else {
      yield put(actions.importError({ message: "Could Not Import Data" }));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(actions.importError({ status: false, message: "Could Not Import Data" }));
  }
}

function* sampleFileFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/history/template?" + action.payload);
    if (response.status === 200) {
      const filename = response.headers["content-disposition"].split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename.replace(new RegExp('"', "g"), ""));
      document.body.appendChild(link);
      link.click();
    } else {
      yield put(actions.sampleFileError({ message: "Could Not Get Sample File" }));
    }
  } catch (e) {
    yield put(actions.sampleFileError({ message: "Could Not Get Sample File" }));
  }
}

function* deleteImportHistory(action) {
  try {
    const res = yield call(api.delete, `/api/crm/history/import-history/${action.payload.id}`);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message, "success");
      yield put(actions.importHistoryRequest(action.payload.loadingData));
    }
  } catch (e) {
    yield put(actions.deleteImportHistoryError(e));
  }
}

export default function* ImportExportSaga() {
  yield takeLatest(EXPORTHISTORY_REQUEST, exportHistoryFunction);
  yield takeLatest(EXPORTHISTORY_REQUEST_WITHOUT_LOADING, exportHistoryFunction);
  yield takeLatest(EXPORT_REQUEST, exportFunction);
  yield takeLatest(IMPORTHISTORY_REQUEST, importHistoryFunction);
  yield takeLatest(IMPORTHISTORY_REQUEST_WITHOUT_LOADING, importHistoryFunction);
  yield takeLatest(IMPORT_REQUEST, importFunction);
  yield takeLatest(SAMPLEFILE_REQUEST, sampleFileFunction);
  yield takeLatest(DELETE_IMPORT_HISTORY_REQUEST, deleteImportHistory);
}
