import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as leadOperationsActions from "../actions/lead-operations.actions";
import * as leadActions from "../actions/actions";
import * as leadTypes from "../types/types";

const endpoints = {
  BULK_EDIT_V2: "/api/crm/lead/v2/bulk-edit",
  BULK_DELETE_V2: "/api/crm/lead/v2/delete",
};

function* bulkEditLeadV2(action) {
  try {
    const res = yield call(api.post, endpoints.BULK_EDIT_V2, action.payload.leadData);
    if (res.status === 200) {
      yield put(leadOperationsActions.bulkEditLeadV2Success({ message: res.data.message }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(leadOperationsActions.bulkEditLeadV2Error({ message: res.data.message }));
    } else {
      yield put(leadOperationsActions.bulkEditLeadV2Error({ message: "Could Not Edit Leads" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(leadOperationsActions.bulkEditLeadV2Error({ message: "Could Not Edit Leads" }));
  }
}

function* bulkDeleteLeadV2(action) {
  try {
    const res = yield call(api.patch, endpoints.BULK_DELETE_V2, action.payload.leadIds);
    if (res.status === 200) {
      yield put(leadOperationsActions.bulkDeleteLeadV2Success({ message: res.data.message }));
      yield put(leadActions.leadsRequest(action.payload.loadingData));
    } else if (res.status === 400) {
      yield put(leadOperationsActions.bulkDeleteLeadV2Error({ message: res.data.message }));
    } else {
      yield put(leadOperationsActions.bulkDeleteLeadV2Error({ message: "Could Not Delete Leads" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(leadOperationsActions.bulkDeleteLeadV2Error({ message: "Could Not Delete Lead" }));
  }
}

export default function* leadOperationSaga() {
  yield takeLatest(leadTypes.bulkDeleteLeadV2.BULK_DELETE_LEAD_V2_REQUEST, bulkDeleteLeadV2);
  yield takeLatest(leadTypes.bulkEditLeadV2.BULK_EDIT_LEAD_V2_REQUEST, bulkEditLeadV2);
}
