import styled from "styled-components";

export const ButtonGroup = styled.div`
  // background: rgba(0, 0, 0, 0.04);
  // border: 1px solid #cbcccd;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  height: 32px;
  background: white;

  > a,
  > div {
    width: 48px;
    box-shadow: inset 0 0 0 1px #cbcccd, 0 0 0 rgb(49 122 226 / 0%), inset 0 1px 2px rgb(49 122 226 / 16%);
    vertical-align: top;
    background: #fff;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
    margin: 0;
    height: 32px;
    min-width: 32px;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
    color: #26292c;
    transition: background 0.12s ease-in-out;
    box-sizing: border-box;
    padding: 10px;
    display: inline-flex;
    color: #cbcccd;
    font-weight: 500;
    border-radius: 2px 0 0 2px;

    i {
      color: #404346;
    }

    svg {
      color: #404346;
    }

    &.active {
      color: #317ae2;
      background: #eff5fd;
      box-shadow: inset 0 0 0 1px #317ae2, 0 0 0 rgb(49 122 226 / 0%), inset 0 1px 2px rgb(49 122 226 / 16%);

      i {
        color: #317ae2;
      }

      svg {
        color: #317ae2;
      }
    }

    &:last-of-type {
      border: none;
    }

    &:hover {
      // box-shadow: inset 1px 1px 0 0 #cbcccd, inset 1px -1px 0 0 #cbcccd, 0 1px 2px rgb(38 41 44 / 8%);
      background: #f7f7f7;
    }
  }
`;
