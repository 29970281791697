export const getListOfVideos = {
  GET_LIST_OF_VIDEOS_REQUEST: "GET_LIST_OF_VIDEOS_REQUEST",
  GET_LIST_OF_VIDEOS_SUCCESS: "GET_LIST_OF_VIDEOS_SUCCESS",
  GET_LIST_OF_VIDEOS_ERROR: "GET_LIST_OF_VIDEOS_ERROR",
};

export const getSelectedVideo = {
  GET_SELECTED_VIDEO_REQUEST: "GET_SELECTED_VIDEO_REQUEST",
  GET_SELECTED_VIDEO_SUCCESS: "GET_SELECTED_VIDEO_SUCCESS",
  GET_SELECTED_VIDEO_ERROR: "GET_SELECTED_VIDEO_ERROR",
};

export const createVideo = {
  CREATE_VIDEO_REQUEST: "CREATE_VIDEO_REQUEST",
  CREATE_VIDEO_SUCCESS: "CREATE_VIDEO_SUCCESS",
  CREATE_VIDEO_ERROR: "CREATE_VIDEO_ERROR",
};

export const editVideo = {
  EDIT_VIDEO_REQUEST: "EDIT_VIDEO_REQUEST",
  EDIT_VIDEO_SUCCESS: "EDIT_VIDEO_SUCCESS",
  EDIT_VIDEO_ERROR: "EDIT_VIDEO_ERROR",
};

export const deleteVideo = {
  DELETE_VIDEO_REQUEST: "DELETE_VIDEO_REQUEST",
  DELETE_VIDEO_SUCCESS: "DELETE_VIDEO_SUCCESS",
  DELETE_VIDEO_ERROR: "DELETE_VIDEO_ERROR",
};
