export const getListOfWhatsappAccounts = {
    REQUEST: "GET_LIST_OF_WHATSAPP_ACCOUNTS_REQUEST",
    SUCCESS: "GET_LIST_OF_WHATSAPP_ACCOUNTS_SUCCESS",
    FAILURE: "GET_LIST_OF_WHATSAPP_ACCOUNTS_FAILURE",
}

export const createWhatsappAccount = {
    REQUEST: "CREATE_WHATSAPP_ACCOUNT_REQUEST",
    SUCCESS: "CREATE_WHATSAPP_ACCOUNT_SUCCESS",
    FAILURE: "CREATE_WHATSAPP_ACCOUNT_FAILURE",
}

export const deleteWhatsappAccount = {
    REQUEST: "DELETE_WHATSAPP_ACCOUNT_REQUEST",
    SUCCESS: "DELETE_WHATSAPP_ACCOUNT_SUCCESS",
    FAILURE: "DELETE_WHATSAPP_ACCOUNT_FAILURE",
}

export const updateWhatsappAccountCredentials = {
    REQUEST: "UPDATE_WHATSAPP_ACCOUNT_CREDENTIALS_REQUEST",
    SUCCESS: "UPDATE_WHATSAPP_ACCOUNT_CREDENTIALS_SUCCESS",
    FAILURE: "UPDATE_WHATSAPP_ACCOUNT_CREDENTIALS_FAILURE"
}

export const getWhatsappNumberProfile = {
    REQUEST: "GET_WHATSAPP_NUMBER_PROFILE_REQUEST",
    SUCCESS: "GET_WHATSAPP_NUMBER_PROFILE_SUCCESS",
    FAILURE: "GET_WHATSAPP_NUMBER_PROFILE_FAILURE",
}

export const updateWhatsappNumberProfile = {
    REQUEST: "UPDATE_WHATSAPP_NUMBER_PROFILE_REQUEST",
    SUCCESS: "UPDATE_WHATSAPP_NUMBER_PROFILE_SUCCESS",
    FAILURE: "UPDATE_WHATSAPP_NUMBER_PROFILE_FAILURE",
}

export const uploadWhatsappNumberProfilePhoto = {
    REQUEST: "UPLOAD_WHATSAPP_NUMBER_PROFILE_PHOTO_REQUEST",
    SUCCESS: "UPLOAD_WHATSAPP_NUMBER_PROFILE_PHOTO_SUCCESS",
    FAILURE: "UPLOAD_WHATSAPP_NUMBER_PROFILE_PHOTO_FAILURE",
}

export const updateWhatsappPerDayLimit = {
    REQUEST: "UPDATE_WHATSAPP_PER_DAY_LIMIT_REQUEST",
    SUCCESS: "UPDATE_WHATSAPP_PER_DAY_LIMIT_SUCCESS",
    FAILURE: "UPDATE_WHATSAPP_PER_DAY_LIMIT_FAILURE",
}

export const getWhatsappConsumptionCount = {
    REQUEST: "GET_WHATSAPP_CONSUMPTION_COUNT_REQUEST",
    SUCCESS: "GET_WHATSAPP_CONSUMPTION_COUNT_SUCCESS",
    FAILURE: "GET_WHATSAPP_CONSUMPTION_COUNT_FAILURE"
}