import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

export default function AssociationDisplay(props) {
  const history = useHistory();

  const onSearchItemClick = (type, id, meta) => {
    if (type === "company") {
      history.push({ pathname: `/activity/activity-list/company/${id}`, state: { id: id } });
    } else if (type === "deal") {
      history.push({ pathname: `/activity/activity-list/deal/${id}`, state: { id: id } });
    } else if (type === "lead" && meta === "active_lead") {
      history.push({ pathname: `/activity/activity-list/lead/${id}`, state: { id: id } });
    } else if (type === "people") {
      history.push({ pathname: `/activity/activity-list/people/${id}`, state: { id: id } });
    } else if (type === "lead" && meta === "junk_lead") {
      history.push({ pathname: `/activity/activity-list/lead/${id}`, state: { id: id } });
    } else {
      history.push({ pathname: `/activity/activity-list/lead/${id}`, state: { id: id } });
    }
  };

  if (props.allData) {
    const { associated } = props.allData;
    if (!_.isEmpty(associated) && typeof associated === "object") {
      if (associated.type === "deal") {
        return (
          <span className="data-container cursor-pointer  d-flex align-items-center gap-2 " onClick={() => onSearchItemClick("deal", associated._id, associated.pipelineId)}>
            <i className="bi bi-coin "></i>
            {associated.title}
          </span>
        );
      } else if (associated.type === "lead") {
        return (
          <span className="data-container cursor-pointer d-flex align-items-center gap-2 " onClick={() => onSearchItemClick("lead", associated._id, associated.state)}>
            <i className="bi bi-bullseye "></i>
            {associated.name}
          </span>
        );
      } else if (associated.type === "people") {
        return (
          <span className="data-container cursor-pointer text-primary d-flex align-items-center gap-2 " onClick={() => onSearchItemClick("people", associated._id, "")}>
            <i className="bi bi-people "></i>

            {associated.name}
          </span>
        );
      } else if (associated.type === "company") {
        return (
          <span className="data-container cursor-pointer text-primary d-flex align-items-center gap-2 " onClick={() => onSearchItemClick("company", associated._id, "")}>
            <i className="bi bi-building "></i>

            {associated.name}
          </span>
        );
      }
    }
    return <span className="data-container"></span>;
  }
  return <span className="data-container"></span>;
}
