import React, { useEffect, useState } from "react";
import moment from "moment";
import SimpleDropdown from "../dropdowns/simple-dropdown";
import { timeOptions } from "../../containers/Activities/constants";

export default function MeetingDateSelect({ field, setValue, value }) {
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [fromTime, setFromTime] = useState({ label: "12:00 AM", value: 0 });

  useEffect(() => {
    if (value) {
      const start = moment(value).startOf("day");
      const duration = moment.duration(moment(value).diff(start));
      const hours = duration.asSeconds();
      setFromDate(moment(value).format("yyyy-MM-DD"));
      setFromTime({ label: moment(value).format("HH:mm A"), value: hours });
    }
  }, [value]);

  const onChangeDate = (e) => {
    const startTime = moment(e.target.value)
      .startOf("day")
      .add(fromTime.value, "second")
      .format();
    setFromDate(e.target.value);
    setValue(startTime, field.apiKeyName);
  };

  const onChangeTime = (option) => {
    const startTime = moment(fromDate)
      .startOf("day")
      .add(option.value, "second")
      .format();
    setFromTime(option);
    setValue(startTime, field.apiKeyName);
  };

  return (
    <div className="d-flex justify-content-center flex-column gap-2">
      <input className="form-control" type="date" value={fromDate} onChange={onChangeDate} />
      <SimpleDropdown value={fromTime} label={"Select From Time"} options={timeOptions} handleSelect={onChangeTime} />
    </div>
  );
}
