import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";

export default function AdvancedAccordionComponent({ title, children, isToggle, checked, onToggle, tooltipMessage, activeKey, setActiveKey }) {

    return (
        <Accordion activeKey={activeKey}>
            <Card>
                <Accordion.Toggle onClick={() => (activeKey === "1" ? setActiveKey("0") : setActiveKey("1"))} as={Card.Header} eventKey="0" className="bg-f1f1f1 p-0 card d-flex justify-content-center p-0 m-0">
                    <div className="d-flex justify-content-between align-items-center px-5">
                        <div>
                            <span className="fw-500 font-size-15px">{title}</span>
                        </div>
                        <div>
                            {isToggle && (
                                <div className="d-flex justify-content-between align-items-center toggle-group">
                                    <OverlayTrigger placement="right" overlay={<Tooltip>{tooltipMessage}</Tooltip>}>
                                        <i className="mr-2 bi bi-info-circle"></i>
                                    </OverlayTrigger>
                                    <div className="form-check form-switch form-check-custom form-check-solid mr-5 pr-5">
                                        <input
                                            className="form-check-input h-20px w-35px"
                                            type="checkbox"
                                            checked={checked}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                onToggle(e.target.checked);
                                            }}
                                            id="flexSwitch20x30"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>{children}</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
