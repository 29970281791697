import {
  TESTING_REQUEST,
  TESTING_SUCCESS,
  TESTING_ERROR,
  FACTOR2_SUCCESS,
  FACTOR2_REQUEST,
  FACTOR2_ERROR,
  CHANGEEMAIL_REQUEST,
  CHANGEEMAIL_SUCCESS,
  CHANGEEMAIL_ERROR,
  CALENDAR_GOOGLE_REQUEST,
  CALENDAR_GOOGLE_SUCCESS,
  CALENDAR_GOOGLE_ERROR,
  EMAIL_GOOGLE_REQUEST,
  EMAIL_GOOGLE_SUCCESS,
  EMAIL_GOOGLE_ERROR,
  CALENDAR_START_SYNCING_REQUEST,
  CALENDAR_START_SYNCING_SUCCESS,
  CALENDAR_START_SYNCING_ERROR,
  CALENDAR_SYNC_UPDATE_REQUEST,
  CALENDAR_SYNC_UPDATE_SUCCESS,
  CALENDAR_SYNC_UPDATE_ERROR,
  GETPROFILE_REQUEST,
  GETPROFILE_SUCCESS,
  GETPROFILE_ERROR,
  UPDATEPROFILE_REQUEST,
  UPDATEPROFILE_SUCCESS,
  UPDATEPROFILE_ERROR,
  LOGINSESSION_REQUEST,
  LOGINSESSION_SUCCESS,
  LOGINSESSION_ERROR,
  LOGINSESSIONPROFILE_REQUEST,
  LOGINSESSIONPROFILE_SUCCESS,
  LOGINSESSIONPROFILE_ERROR,
  GETNOTIFICATION_REQUEST,
  GETNOTIFICATION_SUCCESS,
  GETNOTIFICATION_ERROR,
  POSTNOTIFICATION_REQUEST,
  POSTNOTIFICATION_SUCCESS,
  POSTNOTIFICATION_ERROR,
  ADDWEBHOOK_REQUEST,
  ADDWEBHOOK_SUCCESS,
  ADDWEBHOOK_ERROR,
  GETWEBHOOK_REQUEST,
  GETWEBHOOK_SUCCESS,
  GETWEBHOOK_ERROR,
  PATCHWEBHOOK_REQUEST,
  PATCHWEBHOOK_SUCCESS,
  PATCHWEBHOOK_ERROR,
  DELETEWEBHOOK_REQUEST,
  DELETEWEBHOOK_SUCCESS,
  DELETEWEBHOOK_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  POSTCURRENCY_REQUEST,
  POSTCURRENCY_SUCCESS,
  POSTCURRENCY_ERROR,
  GETCURRENCY_REQUEST,
  GETCURRENCY_SUCCESS,
  GETCURRENCY_ERROR,
  PATCHCURRENCY_REQUEST,
  PATCHCURRENCY_SUCCESS,
  PATCHCURRENCY_ERROR,
  POSTLOSTREASON_REQUEST,
  POSTLOSTREASON_SUCCESS,
  POSTLOSTREASON_ERROR,
  GETLOSTREASON_REQUEST,
  GETLOSTREASON_SUCCESS,
  GETLOSTREASON_ERROR,
  PATCHLOSTREASON_REQUEST,
  PATCHLOSTREASON_SUCCESS,
  PATCHLOSTREASON_ERROR,
  DELETELOSTREASON_REQUEST,
  DELETELOSTREASON_SUCCESS,
  DELETELOSTREASON_ERROR,
  GETMANAGEUSER_REQUEST,
  GETMANAGEUSER_SUCCESS,
  GETMANAGEUSER_ERROR,
  PATCHMANAGEUSER_REQUEST,
  PATCHMANAGEUSER_SUCCESS,
  PATCHMANAGEUSER_ERROR,
  DELETEMANAGEUSER_REQUEST,
  DELETEMANAGEUSER_SUCCESS,
  DELETEMANAGEUSER_ERROR,
  POSTMANAGEUSER_REQUEST,
  POSTMANAGEUSER_SUCCESS,
  POSTMANAGEUSER_ERROR,
  PATCHMANAGEROLE_REQUEST,
  PATCHMANAGEROLE_SUCCESS,
  PATCHMANAGEROLE_ERROR,
  GETTEAMSDETAILS_REQUEST,
  GETTEAMSDETAILS_SUCCESS,
  GETTEAMSDETAILS_ERROR,
  POSTTEAM_REQUEST,
  POSTTEAM_SUCCESS,
  POSTTEAM_ERROR,
  GETTEAM_REQUEST,
  GETTEAM_SUCCESS,
  GETTEAM_ERROR,
  DELETETEAM_REQUEST,
  DELETETEAM_SUCCESS,
  DELETETEAM_ERROR,
  PATCHTEAM_REQUEST,
  PATCHTEAM_SUCCESS,
  PATCHTEAM_ERROR,
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_ERROR,
  EMAIL_START_SYNCING_REQUEST,
  EMAIL_START_SYNCING_SUCCESS,
  EMAIL_START_SYNCING_ERROR,
  GOOGLE_CALENDAR_CALLBACK_REQUEST,
  GOOGLE_CALENDAR_CALLBACK_SUCCESS,
  GOOGLE_CALENDAR_CALLBACK_ERROR,
  EMAIL_SYNC_UPDATE_REQUEST,
  EMAIL_SYNC_UPDATE_SUCCESS,
  EMAIL_SYNC_UPDATE_ERROR,
  GOOGLE_EMAIL_CALLBACK_REQUEST,
  GOOGLE_EMAIL_CALLBACK_SUCCESS,
  GOOGLE_EMAIL_CALLBACK_ERROR,
  GET_CALENDARSYNC_ACCOUNTS_REQUEST,
  GET_CALENDARSYNC_ACCOUNTS_SUCCESS,
  GET_CALENDARSYNC_ACCOUNTS_ERROR,
  GET_EMAILSYNC_ACCOUNTS_REQUEST,
  GET_EMAILSYNC_ACCOUNTS_SUCCESS,
  GET_EMAILSYNC_ACCOUNTS_ERROR,
  GET_CALENDAR_USERINFO_REQUEST,
  GET_CALENDAR_USERINFO_SUCCESS,
  GET_CALENDAR_USERINFO_ERROR,
  GET_EMAILSYNC_SYNCACCOUNT_REQUEST,
  GET_EMAILSYNC_SYNCACCOUNT_SUCCESS,
  GET_EMAILSYNC_SYNCACCOUNT_ERROR,
  POSTACTIVITY_REQUEST,
  POSTACTIVITY_SUCCESS,
  POSTACTIVITY_ERROR,
  GETACTIVITY_REQUEST,
  GETACTIVITY_SUCCESS,
  GETACTIVITY_ERROR,
  PATCHACTIVITY_REQUEST,
  PATCHACTIVITY_SUCCESS,
  PATCHACTIVITY_ERROR,
  DELETEACTIVITY_REQUEST,
  DELETEACTIVITY_SUCCESS,
  DELETEACTIVITY_ERROR,
  NYLAS_SYNC_REQUEST,
  NYLAS_SYNC_SUCCESS,
  NYLAS_SYNC_ERROR,
  NYLAS_SYNC_CALLBACK_REQUEST,
  NYLAS_SYNC_CALLBACK_SUCCESS,
  NYLAS_SYNC_CALLBACK_ERROR,
  AVATARPIC_REQUEST,
  AVATARPIC_SUCCESS,
  AVATARPIC_ERROR,
  GETPROFILEDETAILS_REQUEST,
  GETPROFILEDETAILS_SUCCESS,
  GETPROFILEDETAILS_ERROR,
  PATCHCUSTOMFIELD_REQUEST,
  PATCHCUSTOMFIELD_SUCCESS,
  PATCHCUSTOMFIELD_ERROR,
  POSTCUSTOMFIELD_REQUEST,
  POSTCUSTOMFIELD_SUCCESS,
  POSTCUSTOMFIELD_ERROR,
  GETCUSTOMFIELD_REQUEST,
  GETCUSTOMFIELD_SUCCESS,
  GETCUSTOMFIELD_ERROR,
  DELETECUSTOMFIELD_REQUEST,
  DELETECUSTOMFIELD_SUCCESS,
  DELETECUSTOMFIELD_ERROR,
  COMPANYLIST_REQUEST,
  COMPANYLIST_SUCCESS,
  COMPANYLIST_ERROR,
  TESTWEBHOOK_REQUEST,
  TESTWEBHOOK_SUCCESS,
  TESTWEBHOOK_ERROR,
  DISABLEWEBHOOK_REQUEST,
  DISABLEWEBHOOK_SUCCESS,
  DISABLEWEBHOOK_ERROR,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_ERROR,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
} from "../types/settings.types";

//RESEND invitation

export function resendInvitationRequest(req) {
  return {
    type: RESEND_INVITATION_REQUEST,
    payload: req,
  };
}
export function resendInvitationSuccess(res) {
  return {
    type: RESEND_INVITATION_SUCCESS,
    payload: res,
  };
}
export function resendInvitationError(err) {
  return {
    type: RESEND_INVITATION_ERROR,
    payload: err,
  };
}

//UPDATE TEAM

export function updateTeamRequest(req, cb) {
  return {
    type: UPDATE_TEAM_REQUEST,
    payload: req,
    cb,
  };
}
export function updateTeamSuccess(res) {
  return {
    type: UPDATE_TEAM_SUCCESS,
    payload: res,
  };
}
export function updateTeamError(err) {
  return {
    type: UPDATE_TEAM_ERROR,
    payload: err,
  };
}

export function testingRequest(req) {
  return {
    type: TESTING_REQUEST,
    payload: req,
  };
}

export function testingSuccess(res) {
  return {
    type: TESTING_SUCCESS,
    payload: res,
  };
}

export function testingError(err) {
  return {
    type: TESTING_ERROR,
    payload: err,
  };
}

export function Factor2Auth(req, cb) {
  return {
    type: FACTOR2_REQUEST,
    payload: req,
    cb,
  };
}

export function Factor2AuthSucess(res) {
  return {
    type: FACTOR2_SUCCESS,
    payload: res,
  };
}

export function Factor2AuthError(err) {
  return {
    type: FACTOR2_ERROR,
    payload: err,
  };
}

export function ChangeEmail(req, cb) {
  return {
    type: CHANGEEMAIL_REQUEST,
    payload: req,
    cb,
  };
}

export function ChangeSuccess(res) {
  return {
    type: CHANGEEMAIL_SUCCESS,
    payload: res,
  };
}

export function ChangeError(err) {
  return {
    type: CHANGEEMAIL_ERROR,
    payload: err,
  };
}

export function getProfileRequest(cb) {
  return {
    type: GETPROFILE_REQUEST,
    cb,
  };
}

export function getProfileSuccess(res) {
  return {
    type: GETPROFILE_SUCCESS,
    payload: res,
  };
}

export function getProfileError(err) {
  return {
    type: GETPROFILE_ERROR,
    payload: err,
  };
}

export function updateProfileRequest(req, cb) {
  return {
    type: UPDATEPROFILE_REQUEST,
    payload: req,
    cb,
  };
}

export function calendarGoogleRequest(cb) {
  return {
    type: CALENDAR_GOOGLE_REQUEST,
    // payload:req
    cb,
  };
}

export function calendarGoogleSuccess(res) {
  return {
    type: CALENDAR_GOOGLE_SUCCESS,
    payload: res,
  };
}

export function calendarGoogleError(err) {
  return {
    type: CALENDAR_GOOGLE_ERROR,
    payload: err,
  };
}

export function emailGoogleRequest(cb) {
  return {
    type: EMAIL_GOOGLE_REQUEST,
    // payload:req
    cb,
  };
}

export function emailGoogleSuccess(res) {
  return {
    type: EMAIL_GOOGLE_SUCCESS,
    payload: res,
  };
}

export function emailGoogleError(err) {
  return {
    type: EMAIL_GOOGLE_ERROR,
    payload: err,
  };
}

export function calendarStartSyncingRequest(req) {
  return {
    type: CALENDAR_START_SYNCING_REQUEST,
    payload: req,
  };
}

export function calendarStartSyncingSuccess(res) {
  return {
    type: CALENDAR_START_SYNCING_SUCCESS,
    payload: res,
  };
}

export function calendarStartSyncingError(err) {
  return {
    type: CALENDAR_START_SYNCING_ERROR,
    payload: err,
  };
}

export function calendarSyncUpdateRequest(req) {
  return {
    type: CALENDAR_SYNC_UPDATE_REQUEST,
    payload: req,
  };
}

export function calendarSyncUpdateSuccess(res) {
  return {
    type: CALENDAR_SYNC_UPDATE_SUCCESS,
    payload: res,
  };
}

export function calendarSyncUpdateError(err) {
  return {
    type: CALENDAR_SYNC_UPDATE_ERROR,
    payload: err,
  };
}
export function updateProfileSuccess(res) {
  return {
    type: UPDATEPROFILE_SUCCESS,
    payload: res,
  };
}

export function updateProfileError(err) {
  return {
    type: UPDATEPROFILE_ERROR,
    payload: err,
  };
}

export function getloginSessionRequest(params, currentPage, selectUser, cb) {
  return {
    type: LOGINSESSION_REQUEST,
    cb,
    params,
    currentPage,
    selectUser,
  };
}

export function getloginSessionSuccess(res) {
  return {
    type: LOGINSESSION_SUCCESS,
    payload: res,
  };
}

export function getloginSessionError(err) {
  return {
    type: LOGINSESSION_ERROR,
    payload: err,
  };
}

export function getloginSessionProfileRequest(currentPage, cb) {
  return {
    type: LOGINSESSIONPROFILE_REQUEST,
    cb,
    currentPage,
  };
}

export function getloginSessionProfileSuccess(res) {
  return {
    type: LOGINSESSIONPROFILE_SUCCESS,
    payload: res,
  };
}

export function getloginSessionProfileError(err) {
  return {
    type: LOGINSESSIONPROFILE_ERROR,
    payload: err,
  };
}

export function getNotificationRequest(cb) {
  return {
    type: GETNOTIFICATION_REQUEST,
    cb,
  };
}

export function getNotificationSuccess(res) {
  return {
    type: GETNOTIFICATION_SUCCESS,
    payload: res,
  };
}

export function getNotificationError(err) {
  return {
    type: GETNOTIFICATION_ERROR,
    payload: err,
  };
}

export function postNotificationRequest(req, cb) {
  return {
    type: POSTNOTIFICATION_REQUEST,
    payload: req,
    cb,
  };
}

export function postNotificationSuccess(res) {
  return {
    type: POSTNOTIFICATION_SUCCESS,
    payload: res,
  };
}

export function postNotificationError(err) {
  return {
    type: POSTNOTIFICATION_ERROR,
    payload: err,
  };
}

export function addWebHookRequest(req, cb) {
  return {
    type: ADDWEBHOOK_REQUEST,
    payload: req,
    cb,
  };
}

export function addWebHookSuccess(res) {
  return {
    type: ADDWEBHOOK_SUCCESS,
    payload: res,
  };
}

export function addWebHookError(err) {
  return {
    type: ADDWEBHOOK_ERROR,
    payload: err,
  };
}

export function getWebHookRequest(cb) {
  return {
    type: GETWEBHOOK_REQUEST,
    cb,
  };
}

export function getWebHookSuccess(res) {
  return {
    type: GETWEBHOOK_SUCCESS,
    payload: res,
  };
}

export function getWebHookError(err) {
  return {
    type: GETWEBHOOK_ERROR,
    payload: err,
  };
}

export function patchWebHookRequest(req, paramId, cb) {
  return {
    type: PATCHWEBHOOK_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchWebHookSuccess(res) {
  return {
    type: PATCHWEBHOOK_SUCCESS,
    payload: res,
  };
}

export function patchWebHookError(err) {
  return {
    type: PATCHWEBHOOK_ERROR,
    payload: err,
  };
}

export function deleteWebHookRequest(paramId, cb) {
  return {
    type: DELETEWEBHOOK_REQUEST,
    paramId,
    cb,
  };
}

export function deleteWebHookSuccess(res) {
  return {
    type: DELETEWEBHOOK_SUCCESS,
    payload: res,
  };
}

export function deleteWebHookError(err) {
  return {
    type: DELETEWEBHOOK_ERROR,
    payload: err,
  };
}

export function LogoutRequest(req, cb) {
  return {
    type: LOGOUT_REQUEST,
    payload: req,
    cb,
  };
}

export function LogoutSuccess(res) {
  return {
    type: LOGOUT_SUCCESS,
    payload: res,
  };
}

export function LogoutError(err) {
  return {
    type: LOGOUT_ERROR,
    payload: err,
  };
}

export function postCurrencyRequest(req, cb) {
  return {
    type: POSTCURRENCY_REQUEST,
    payload: req,
    cb,
  };
}

export function postCurrencySuccess(res) {
  return {
    type: POSTCURRENCY_SUCCESS,
    payload: res,
  };
}

export function postCurrencyError(err) {
  return {
    type: POSTCURRENCY_ERROR,
    payload: err,
  };
}

export function getCurrencyRequest(cb) {
  return {
    type: GETCURRENCY_REQUEST,
    cb,
  };
}

export function getCurrencySuccess(res) {
  return {
    type: GETCURRENCY_SUCCESS,
    payload: res,
  };
}

export function getCurrencyError(err) {
  return {
    type: GETCURRENCY_ERROR,
    payload: err,
  };
}

export function patchCurrencyRequest(req, paramId, cb) {
  return {
    type: PATCHCURRENCY_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchCurrencySuccess(res) {
  return {
    type: PATCHCURRENCY_SUCCESS,
    payload: res,
  };
}

export function patchCurrencyError(err) {
  return {
    type: PATCHCURRENCY_ERROR,
    payload: err,
  };
}

export function postLostReasonRequest(req, cb) {
  return {
    type: POSTLOSTREASON_REQUEST,
    payload: req,
    cb,
  };
}

export function postLostReasonSuccess(res) {
  return {
    type: POSTLOSTREASON_SUCCESS,
    payload: res,
  };
}

export function postLostReasonError(err) {
  return {
    type: POSTLOSTREASON_ERROR,
    payload: err,
  };
}

export function getLostReasonRequest(cb) {
  return {
    type: GETLOSTREASON_REQUEST,
    cb,
  };
}

export function getLostReasonSuccess(res) {
  return {
    type: GETLOSTREASON_SUCCESS,
    payload: res,
  };
}

export function getLostReasonError(err) {
  return {
    type: GETLOSTREASON_ERROR,
    payload: err,
  };
}

export function patchLostReasonRequest(req, paramId, cb) {
  return {
    type: PATCHLOSTREASON_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchLostReasonSuccess(res) {
  return {
    type: PATCHLOSTREASON_SUCCESS,
    payload: res,
  };
}

export function patchLostReasonError(err) {
  return {
    type: PATCHLOSTREASON_ERROR,
    payload: err,
  };
}

export function deleteLostReasonRequest(paramId, cb) {
  return {
    type: DELETELOSTREASON_REQUEST,
    paramId,
    cb,
  };
}

export function deleteLostReasonSuccess(res) {
  return {
    type: DELETELOSTREASON_SUCCESS,
    payload: res,
  };
}

export function deleteLostReasonError(err) {
  return {
    type: DELETELOSTREASON_ERROR,
    payload: err,
  };
}

export function getManageUserRequest(cb) {
  return {
    type: GETMANAGEUSER_REQUEST,
    cb,
  };
}

export function getManageUserSuccess(res) {
  return {
    type: GETMANAGEUSER_SUCCESS,
    payload: res,
  };
}

export function getManageUserError(err) {
  return {
    type: GETMANAGEUSER_ERROR,
    payload: err,
  };
}

export function getUserProfileRequest(cb) {
  return {
    type: GETPROFILEDETAILS_REQUEST,
    cb,
  };
}

export function getUserProfileSuccess(res) {
  return {
    type: GETPROFILEDETAILS_SUCCESS,
    payload: res,
  };
}

export function getUserProfileError(err) {
  return {
    type: GETPROFILEDETAILS_ERROR,
    payload: err,
  };
}

export function patchManageUserRequest(req, paramId, cb) {
  return {
    type: PATCHMANAGEUSER_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchManageUserSuccess(res) {
  return {
    type: PATCHMANAGEUSER_SUCCESS,
    payload: res,
  };
}

export function patchManageUserError(err) {
  return {
    type: PATCHMANAGEUSER_ERROR,
    payload: err,
  };
}

export function deleteManageUserRequest(payload, cb) {
  return {
    type: DELETEMANAGEUSER_REQUEST,
    payload,
    cb,
  };
}

export function deleteManageUserSuccess(res) {
  return {
    type: DELETEMANAGEUSER_SUCCESS,
    payload: res,
  };
}

export function deleteManageUserError(err) {
  return {
    type: DELETEMANAGEUSER_ERROR,
    payload: err,
  };
}

export function postManageUserRequest(req, cb) {
  return {
    type: POSTMANAGEUSER_REQUEST,
    payload: req,
    cb,
  };
}

export function postManageUserSuccess(res) {
  return {
    type: POSTMANAGEUSER_SUCCESS,
    payload: res,
  };
}

export function postManageUserError(err) {
  return {
    type: POSTMANAGEUSER_ERROR,
    payload: err,
  };
}

export function getTeamsDetailsRequest(cb) {
  return {
    type: GETTEAMSDETAILS_REQUEST,
    cb,
  };
}

export function getTeamsDetailsSuccess(res) {
  return {
    type: GETTEAMSDETAILS_SUCCESS,
    payload: res,
  };
}

export function getTeamsDetailsError(err) {
  return {
    type: GETTEAMSDETAILS_ERROR,
    payload: err,
  };
}

export function postTeamRequest(req, cb) {
  return {
    type: POSTTEAM_REQUEST,
    payload: req,
    cb,
  };
}

export function postTeamSuccess(res) {
  return {
    type: POSTTEAM_SUCCESS,
    payload: res,
  };
}

export function postTeamError(err) {
  return {
    type: POSTTEAM_ERROR,
    payload: err,
  };
}

export function getTeamsRequest(cb) {
  return {
    type: GETTEAM_REQUEST,
    cb,
  };
}

export function getTeamsSuccess(res) {
  return {
    type: GETTEAM_SUCCESS,
    payload: res,
  };
}

export function getTeamsError(err) {
  return {
    type: GETTEAM_ERROR,
    payload: err,
  };
}

export function deleteTeamsRequest(paramId, cb) {
  return {
    type: DELETETEAM_REQUEST,
    paramId,
    cb,
  };
}

export function deleteTeamsSuccess(res) {
  return {
    type: DELETETEAM_SUCCESS,
    payload: res,
  };
}

export function deleteTeamsError(err) {
  return {
    type: DELETETEAM_ERROR,
    payload: err,
  };
}

export function patchTeamsRequest(req, paramId, cb) {
  return {
    type: PATCHTEAM_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchTeamsSuccess(res) {
  return {
    type: PATCHTEAM_SUCCESS,
    payload: res,
  };
}

export function patchTeamsError(err) {
  return {
    type: PATCHTEAM_ERROR,
    payload: err,
  };
}

export function patchPasswordResetRequest(req, paramId, cb) {
  return {
    type: RESETPASSWORD_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchPasswordResetSuccess(res) {
  return {
    type: RESETPASSWORD_SUCCESS,
    payload: res,
  };
}

export function patchPasswordResetError(err) {
  return {
    type: RESETPASSWORD_ERROR,
    payload: err,
  };
}

export function emailStartSyncRequest(req) {
  return {
    type: EMAIL_START_SYNCING_REQUEST,
    payload: req,
    // paramId,
  };
}

export function postActivityRequest(req, cb) {
  return {
    type: POSTACTIVITY_REQUEST,
    payload: req,
    cb,
  };
}

export function emailStartSyncSuccess(res) {
  return {
    type: EMAIL_START_SYNCING_SUCCESS,
    payload: res,
  };
}

export function emailStartSyncError(err) {
  return {
    type: EMAIL_START_SYNCING_ERROR,
    payload: err,
  };
}

export function googleCalendarSyncCallbackRequest(req) {
  return {
    type: GOOGLE_CALENDAR_CALLBACK_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function googleCalendarSyncCallbackSuccess(res) {
  return {
    type: GOOGLE_CALENDAR_CALLBACK_SUCCESS,
    payload: res,
  };
}

export function googleCalendarSyncCallbackError(err) {
  return {
    type: GOOGLE_CALENDAR_CALLBACK_ERROR,
    payload: err,
  };
}

export function googleEmailSyncCallbackRequest(req) {
  return {
    type: GOOGLE_EMAIL_CALLBACK_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function googleEmailSyncCallbackSuccess(res) {
  return {
    type: GOOGLE_EMAIL_CALLBACK_SUCCESS,
    payload: res,
  };
}

export function postActivitySuccess(res) {
  return {
    type: POSTACTIVITY_SUCCESS,
    payload: res,
  };
}

export function googleEmailSyncCallbackError(err) {
  return {
    type: GOOGLE_EMAIL_CALLBACK_ERROR,
    payload: err,
  };
}

export function postActivityError(err) {
  return {
    type: POSTACTIVITY_ERROR,
    payload: err,
  };
}

export function emailSyncUpdateRequest(req) {
  return {
    type: EMAIL_SYNC_UPDATE_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function emailSyncUpdateSuccess(res) {
  return {
    type: EMAIL_SYNC_UPDATE_SUCCESS,
    payload: res,
  };
}

export function getActivityRequest(cb) {
  return {
    type: GETACTIVITY_REQUEST,
    cb,
  };
}

export function getActivitySuccess(res) {
  return {
    type: GETACTIVITY_SUCCESS,
    payload: res,
  };
}

export function emailSyncUpdateError(err) {
  return {
    type: EMAIL_SYNC_UPDATE_ERROR,
    payload: err,
  };
}

export function getActivityError(err) {
  return {
    type: GETACTIVITY_ERROR,
    payload: err,
  };
}

export function getCalendarSyncAccountsRequest(req) {
  return {
    type: GET_CALENDARSYNC_ACCOUNTS_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function getCalendarSyncAccountsSuccess(res) {
  return {
    type: GET_CALENDARSYNC_ACCOUNTS_SUCCESS,
    payload: res,
  };
}

export function patchActivityRequest(req, paramId, cb) {
  return {
    type: PATCHACTIVITY_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchActivitySuccess(res) {
  return {
    type: PATCHACTIVITY_SUCCESS,
    payload: res,
  };
}

export function getCalendarSyncAccountsError(err) {
  return {
    type: GET_CALENDARSYNC_ACCOUNTS_ERROR,
    payload: err,
  };
}

export function patchActivityError(err) {
  return {
    type: PATCHACTIVITY_ERROR,
    payload: err,
  };
}

export function getEmailSyncAccountsRequest(req) {
  return {
    type: GET_EMAILSYNC_ACCOUNTS_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function getEmailSyncAccountsSuccess(res) {
  return {
    type: GET_EMAILSYNC_ACCOUNTS_SUCCESS,
    payload: res,
  };
}
export function deleteActivityRequest(paramId, cb) {
  return {
    type: DELETEACTIVITY_REQUEST,
    paramId,
    cb,
  };
}

export function deleteActivitySuccess(res) {
  return {
    type: DELETEACTIVITY_SUCCESS,
    payload: res,
  };
}

export function getEmailSyncAccountsError(err) {
  return {
    type: GET_EMAILSYNC_ACCOUNTS_ERROR,
    payload: err,
  };
}

export function deleteActivityError(err) {
  return {
    type: DELETEACTIVITY_ERROR,
    payload: err,
  };
}

export function getCalendarUserInfoRequest(req) {
  return {
    type: GET_CALENDAR_USERINFO_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function getCalendarUserInfoSuccess(res) {
  return {
    type: GET_CALENDAR_USERINFO_SUCCESS,
    payload: res,
  };
}

export function getCalendarUserInfoError(err) {
  return {
    type: GET_CALENDAR_USERINFO_ERROR,
    payload: err,
  };
}

export function getEmailSyncConnectedAccountRequest(req) {
  return {
    type: GET_EMAILSYNC_SYNCACCOUNT_REQUEST,
    payload: req,
    // paramId,
    // cb
  };
}

export function getEmailSyncConnectedAccountSuccess(res) {
  return {
    type: GET_EMAILSYNC_SYNCACCOUNT_SUCCESS,
    payload: res,
  };
}

export function getEmailSyncConnectedAccountError(err) {
  return {
    type: GET_EMAILSYNC_SYNCACCOUNT_ERROR,
    payload: err,
  };
}

export function patchManageRoleRequest(req, paramId, cb) {
  return {
    type: PATCHMANAGEROLE_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}

export function patchManageRoleSuccess(res) {
  return {
    type: PATCHMANAGEROLE_SUCCESS,
    payload: res,
  };
}

export function patchManageRoleError(err) {
  return {
    type: PATCHMANAGEROLE_ERROR,
    payload: err,
  };
}

export function nylasSyncRequest(req, synctype, cb) {
  return {
    type: NYLAS_SYNC_REQUEST,
    payload: req,
    synctype,
    cb,
  };
}

export function nylasSyncSuccess(res) {
  return {
    type: NYLAS_SYNC_SUCCESS,
    payload: res,
  };
}

export function nylasSyncError(err) {
  return {
    type: NYLAS_SYNC_ERROR,
    payload: err,
  };
}

export function nylasSyncCallbackRequest(req, synctype, cb) {
  return {
    type: NYLAS_SYNC_CALLBACK_REQUEST,
    payload: req,
    synctype,
    cb,
  };
}

export function nylasSyncCallbackSuccess(res) {
  return {
    type: NYLAS_SYNC_CALLBACK_SUCCESS,
    payload: res,
  };
}

export function nylasSyncCallbackError(err) {
  return {
    type: NYLAS_SYNC_CALLBACK_ERROR,
    payload: err,
  };
}
export function patchAvatarPicRequest(req, cb) {
  return {
    type: AVATARPIC_REQUEST,
    payload: req,
    // paramId,
    cb,
  };
}

export function patchAvatarPicSuccess(res) {
  return {
    type: AVATARPIC_SUCCESS,
    payload: res,
  };
}

export function patchAvatarPicError(err) {
  return {
    type: AVATARPIC_ERROR,
    payload: err,
  };
}

export function postCustomFieldRequest(req, cb) {
  return {
    type: POSTCUSTOMFIELD_REQUEST,
    payload: req,
    cb,
  };
}

export function postCustomFieldSuccess(res) {
  return {
    type: POSTCUSTOMFIELD_SUCCESS,
    payload: res,
  };
}

export function postCustomFieldError(err) {
  return {
    type: POSTCUSTOMFIELD_ERROR,
    payload: err,
  };
}

export function patchCustomFieldRequest(req, cb) {
  return {
    type: PATCHCUSTOMFIELD_REQUEST,
    payload: req,
    // paramId,
    cb,
  };
}

export function patchCustomFieldSuccess(res) {
  return {
    type: PATCHCUSTOMFIELD_SUCCESS,
    payload: res,
  };
}

export function patchCustomFieldError(err) {
  return {
    type: PATCHCUSTOMFIELD_ERROR,
    payload: err,
  };
}

export function getCustomFieldRequest(params, cb) {
  return {
    type: GETCUSTOMFIELD_REQUEST,
    params,
    cb,
  };
}

export function getCustomFieldSuccess(res) {
  return {
    type: GETCUSTOMFIELD_SUCCESS,
    payload: res,
  };
}

export function getCustomFieldError(err) {
  return {
    type: GETCUSTOMFIELD_ERROR,
    payload: err,
  };
}

export function deleteCustomFieldRequest(paramId, cb) {
  return {
    type: DELETECUSTOMFIELD_REQUEST,
    paramId,
    cb,
  };
}

export function deleteCustomFieldSuccess(res) {
  return {
    type: DELETECUSTOMFIELD_SUCCESS,
    payload: res,
  };
}

export function deleteCustomFieldError(err) {
  return {
    type: DELETECUSTOMFIELD_ERROR,
    payload: err,
  };
}

export function companyListRequest(cb) {
  return {
    type: COMPANYLIST_REQUEST,
    // paramId,
    cb,
  };
}

export function companyListSuccess(res) {
  return {
    type: COMPANYLIST_SUCCESS,
    payload: res,
  };
}

export function companyListError(err) {
  return {
    type: COMPANYLIST_ERROR,
    payload: err,
  };
}

export function testWebhookRequest(req, cb) {
  return {
    type: TESTWEBHOOK_REQUEST,
    payload: req,
    cb,
  };
}

export function testWebhookSuccess(res) {
  return {
    type: TESTWEBHOOK_SUCCESS,
    payload: res,
  };
}

export function testWebhookError(err) {
  return {
    type: TESTWEBHOOK_ERROR,
    payload: err,
  };
}

export function disableWebhookRequest(req, cb) {
  return {
    type: DISABLEWEBHOOK_REQUEST,
    payload: req,
    cb,
  };
}

export function disableWebhookSuccess(res) {
  return {
    type: DISABLEWEBHOOK_SUCCESS,
    payload: res,
  };
}

export function disableWebhookError(err) {
  return {
    type: DISABLEWEBHOOK_ERROR,
    payload: err,
  };
}
