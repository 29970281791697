import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import * as campaignWhatsappSettingTypes from "../../types/campaign_whatsapp_settings_type";
import * as campaignWhatsappSettingsActions from "../../actions/campaign_whatsapp_setting_actions";
import { endpoints } from "../../endpoints";
import api from "../../../../../network/apis/api";

function* getListOfWhatsappAccounts(action) {
    try {
        const res = yield call(api.get, endpoints.LIST_OF_WHATSAPP_ACCOUNTS, action.payload);
        if (res.status === 200) {
            yield put(campaignWhatsappSettingsActions.getListOfWhatsappAccountsSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.getListOfWhatsappAccountsFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.getListOfWhatsappAccountsFailure(e));
    }
}
function* createWhatsappAccount(action) {
    try {
        const res = yield call(api.post, endpoints.CREATE_WHATSAPP_ACCOUNT, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappSettingsActions.createWhatsappAccountSuccess(res.data));
            yield put(campaignWhatsappSettingsActions.getListOfWhatsappAccountsRequest());
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.createWhatsappAccountFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.createWhatsappAccountFailure(e));
    }
}
function* deleteWhatsappAccount(action) {
    try {
        const res = yield call(api.delete, `${endpoints.DELETE_WHATSAPP_ACCOUNT}/${action.payload.integrationId}`);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappSettingsActions.deleteWhatsappAccountSuccess(res.data));
            yield put(campaignWhatsappSettingsActions.getListOfWhatsappAccountsRequest());
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.deleteWhatsappAccountFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.deleteWhatsappAccountFailure(e));
    }
}


function* updateWhatsappAccountCredentials(action) {
    try {
        const res = yield call(api.patch, endpoints.UPDATE_CREDENTIALS_WHATSAPP_ACCOUNT, action.payload.data);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappSettingsActions.updateWhatsappAccountCredentialsSuccess(res.data));
            yield put(campaignWhatsappSettingsActions.getWhatsappNumberProfileRequest({ id: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.updateWhatsappAccountCredentialsFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.updateWhatsappAccountCredentialsFailure(e));
    }
}

function* getWhatsappNumberProfile(action) {
    try {
        const res = yield call(api.get, endpoints.WHATSAPP_PHONE_PROFILE, action.payload);
        if (res.status === 200) {
            yield put(campaignWhatsappSettingsActions.getWhatsappNumberProfileSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.getWhatsappNumberProfileFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.getWhatsappNumberProfileFailure(e));
    }
}
function* updateWhatsappNumberProfile(action) {
    try {
        const res = yield call(api.patch, endpoints.WHATSAPP_PHONE_PROFILE, action.payload.data);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappSettingsActions.updateWhatsappNumberProfileSuccess(res.data));
            yield put(campaignWhatsappSettingsActions.getWhatsappNumberProfileRequest({ id: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.updateWhatsappNumberProfileFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.updateWhatsappNumberProfileFailure(e));
    }
}
function* uploadWhatsappNumberProfilePhoto(action) {
    try {
        const res = yield call(api.post, `${endpoints.UPLOAD_WHATSAPP_PROFILE_PHOTO}`, action.payload.data);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappSettingsActions.uploadWhatsappNumberProfilePhotoSuccess(res.data));
            yield put(campaignWhatsappSettingsActions.getWhatsappNumberProfileRequest({ id: action.payload.integrationId }));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.uploadWhatsappNumberProfilePhotoFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.uploadWhatsappNumberProfilePhotoFailure(e));
    }
}

function* updateWhatsappPerDayLimit(action) {
    try {
        const res = yield call(api.patch, endpoints.WHATSAPP_DAY_LIMIT, action.payload.data);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappSettingsActions.updateWhatsappPerDayLimitSuccess(res.data));
            yield put(campaignWhatsappSettingsActions.getListOfWhatsappAccountsRequest());
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.updateWhatsappPerDayLimitFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.updateWhatsappPerDayLimitFailure(e));
    }
}

function* getWhatsappConsumptionCount(action) {
    try {
        const res = yield call(api.get, `${endpoints.WHATSAPP_CONSUMPTION_COUNT}/${action.payload.integrationId}`);
        if (res.status === 200) {
            yield put(campaignWhatsappSettingsActions.getWhatsappConsumptionCountSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappSettingsActions.getWhatsappConsumptionCountFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappSettingsActions.getWhatsappConsumptionCountFailure(e));
    }
}

export default function* campaignWhatsappSettingsSaga() {
    yield takeLatest(campaignWhatsappSettingTypes.getListOfWhatsappAccounts.REQUEST, getListOfWhatsappAccounts);
    yield takeLatest(campaignWhatsappSettingTypes.createWhatsappAccount.REQUEST, createWhatsappAccount);
    yield takeLatest(campaignWhatsappSettingTypes.deleteWhatsappAccount.REQUEST, deleteWhatsappAccount);
    yield takeLatest(campaignWhatsappSettingTypes.updateWhatsappAccountCredentials.REQUEST, updateWhatsappAccountCredentials);
    yield takeLatest(campaignWhatsappSettingTypes.getWhatsappNumberProfile.REQUEST, getWhatsappNumberProfile);
    yield takeLatest(campaignWhatsappSettingTypes.updateWhatsappNumberProfile.REQUEST, updateWhatsappNumberProfile);
    yield takeLatest(campaignWhatsappSettingTypes.uploadWhatsappNumberProfilePhoto.REQUEST, uploadWhatsappNumberProfilePhoto);
    yield takeLatest(campaignWhatsappSettingTypes.updateWhatsappPerDayLimit.REQUEST, updateWhatsappPerDayLimit);
    yield takeLatest(campaignWhatsappSettingTypes.getWhatsappConsumptionCount.REQUEST, getWhatsappConsumptionCount);
}
