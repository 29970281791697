/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
// import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import CustomForm from "../../../../../components/custom_form";
import {
  ownersRequest,
  peopleAddFieldsRequest,
  peopleAddRequest,
  peopleTagsAddRequest,
  peopleTagsDeleteRequest,
  peopleTagsEditRequest,
  peopleTagsRequest,
} from "../../../../../containers/customers/store/actions/actions";
import { leadAddFieldsRequest, leadAddRequest, leadTagsAddRequest, leadTagsDeleteRequest, leadTagsEditRequest, leadTagsRequest } from "../../../../../containers/leads/store/actions/actions";
import {
  productAddRequest,
  productCategoriesAddRequest,
  productCategoriesDeleteRequest,
  productCategoriesEditRequest,
  productTagsAddRequest,
  productTagsDeleteRequest,
  productTagsEditRequest,
} from "../../../../../containers/products/store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { companyAddFieldsRequest, companyAddRequest, companyTagsAddRequest, companyTagsDeleteRequest, companyTagsEditRequest, companyTagsRequest } from "../../../../../containers/companies/store/actions/actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import { clearError as leadClearError, clearSuccess as leadClearSuccess } from "../../../../../containers/leads/store/actions/actions";
import { clearError as peopleClearError, clearSuccess as peopleClearSuccess } from "../../../../../containers/customers/store/actions/actions";
import { clearError as productClearError, clearSuccess as productClearSuccess } from "../../../../../containers/products/store/actions/actions";
import { clearError as companyClearError, clearSuccess as companyClearSuccess } from "../../../../../containers/companies/store/actions/actions";
import { AddDealModal } from "../../../../../containers/deals/common/dealModal/index";
import { getPipelineRequest, addDealRequest, getDealTagRequest } from "../../../../../containers/deals/dealPipeline/actions";

import ScheduleActivity from "../../../../../containers/Activities/Activity-List/add-activity/index";
import { createActivityRequest } from "../../../../../containers/Activities/store/actions/activitity_list_actions";

import * as ProductActions from "../../../../../containers/products/store/actions/actions";
import * as CompanyActions from "../../../../../containers/companies/store/actions/actions";
import * as insightActions from "../../../../../containers/insights/actions";
import { CreateCampaignModal } from "../../../../../containers/campaign/components/create-campaign-modal";

// dispatch(dealActions.getPipelineRequest())

export function QuickActionsDropdown() {
  // const bgImage = toAbsoluteUrl("/media/misc/bg-2.jpg");
  const [showAdd, setShowAdd] = useState(false);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState("");
  const [pageSub, setPageSub] = useState("");
  const uiService = useHtmlClassService();
  const [addFormData, setAddFormData] = useState({});
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const productColumns = useSelector((state) => state.Products.productAddFields);
  const peopleColumns = useSelector((state) => state.People.peopleAddFields);
  const leadColumns = useSelector((state) => state.Leads.leadAddFields);
  const companyColumns = useSelector((state) => state.Companies.companyAddFields);
  const productLoadingData = useSelector((state) => state.Products.loadingData);
  const dealPipelineLoadingData = useSelector((state) => state.DealPipeline.loadingData);
  const peopleLoadingData = useSelector((state) => state.People.loadingData);
  const leadLoadingData = useSelector((state) => state.Leads.loadingData);
  const companyLoadingData = useSelector((state) => state.Companies.loadingData);
  const productError = useSelector((state) => state.Products.error);
  const peopleError = useSelector((state) => state.People.error);
  const leadError = useSelector((state) => state.Leads.error);
  const companyError = useSelector((state) => state.Companies.error);
  const productSuccess = useSelector((state) => state.Products.success);
  const peopleSuccess = useSelector((state) => state.People.success);
  const leadSuccess = useSelector((state) => state.Leads.success);
  const companySuccess = useSelector((state) => state.Companies.success);
  const [fields, setFields] = useState({ loading: true, list: [] });
  const [loading, setLoading] = useState(false);
  const [openAddDealModal, setOpenAddDealModal] = useState(false);
  const [openAddActivityModal, setOpenAddActivityModal] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);

  const pipelineStore = useSelector((store) => store.DealPipeline);
  const dealTagStore = useSelector((state) => state.DealPipeline.dealTags.list);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const activityLoadingStore = useSelector((store) => store.activity.list.loadingData);

  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.quick-actions.layout") === "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    dispatch(getDealTagRequest({ request: { type: "deal" }, skipIfDataPresent: true }));

    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!showAdd) setType("");
    dispatch(getPipelineRequest());
  }, [showAdd, dispatch]);

  const handleSelectPeople = () => {
    setShowAdd(true);
    setShow(false);
    setType("customer");
    setPage("People");
    setPageSub("people");
    dispatch(ownersRequest());
    dispatch(peopleAddFieldsRequest({ fieldType: "PERSON" }));
    dispatch(peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));
    setFields(peopleColumns);
  };

  const handleSelectLeads = () => {
    setShowAdd(true);
    setShow(false);
    setType("lead");
    setPage("Leads");
    setPageSub("lead");
    dispatch(ownersRequest());
    dispatch(leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(leadAddFieldsRequest({ fieldType: "LEAD" }));
    setFields(leadColumns);
  };

  const handleSelectCompanies = () => {
    setShowAdd(true);
    setShow(false);
    setType("company");
    setPage("Companies");
    setPageSub("company");
    dispatch(ownersRequest());
    dispatch(companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(companyAddFieldsRequest({ fieldType: "COMPANY" }));
    setFields(companyColumns);
  };

  // const handleSelectProducts = () => {
  //   setShowAdd(true)
  //   setShow(false)
  //   setType("product")
  //   setPage("Products")
  //   setPageSub("product")
  //   dispatch(ownersRequest())
  //   dispatch(productCategoriesRequest())
  //   dispatch(productTagsRequest({ type: "product" }))
  //   dispatch(productAddFieldsRequest({ fieldType: "PRODUCT" }))
  //   setFields(productColumns)
  // }

  const handleSelectDeal = () => {
    setOpenAddDealModal(true);
  };

  const handleSelectActivity = () => {
    setOpenAddActivityModal(true);
  };
  const handleSelectCampaign = () => {
    setShowCreateCampaign(true);
  };

  useEffect(() => {
    if (type === "customer") setFields(peopleColumns);
    else if (type === "lead") setFields(leadColumns);
    else if (type === "product") setFields(productColumns);
    else if (type === "company") setFields(companyColumns);
  }, [companyColumns, productColumns, peopleColumns, leadColumns, type]);

  useEffect(() => {
    if (type !== "") {
      if (leadError.err) {
        dispatchSnackbarError(leadError.errMsg);
        dispatch(leadClearError());
        setShowAdd(false);
        setAddFormData({});
      } else if (peopleError.err) {
        dispatchSnackbarError(peopleError.errMsg);
        dispatch(peopleClearError());
        setShowAdd(false);
        setAddFormData({});
      } else if (productError.err) {
        dispatchSnackbarError(productError.errMsg);
        dispatch(productClearError());
        setShowAdd(false);
        setAddFormData({});
      } else if (companyError.err) {
        dispatchSnackbarError(companyError.errMsg);
        dispatch(companyClearError());
        setShowAdd(false);
        setAddFormData({});
      }
    }
    setLoading(false);
  }, [leadError, peopleError, productError, companyError, dispatch, type, showAdd]);

  useEffect(() => {
    if (type !== "") {
      if (leadSuccess.success) {
        dispatchSnackbarSuccess(leadSuccess.successMsg);
        dispatch(leadClearSuccess());
        if (leadSuccess.successMsg === "Lead added successfully.") {
          setShowAdd(false);
          setAddFormData({});
        }
      } else if (peopleSuccess.success) {
        dispatchSnackbarSuccess(peopleSuccess.successMsg);
        dispatch(peopleClearSuccess());
        if (peopleSuccess.successMsg === "People added successfully.") {
          setShowAdd(false);
          setAddFormData({});
        }
      } else if (productSuccess.success) {
        dispatchSnackbarSuccess(productSuccess.successMsg);
        dispatch(productClearSuccess());
        if (productSuccess.successMsg === "Product Added Successfully.") {
          setShowAdd(false);
          setAddFormData({});
        }
      } else if (companySuccess.success) {
        dispatchSnackbarSuccess(companySuccess.successMsg);
        dispatch(companyClearSuccess());
        if (companySuccess.successMsg === "Company added successfully.") {
          setShowAdd(false);
          setAddFormData({});
        }
      }
    }
    setLoading(false);
  }, [leadSuccess, peopleSuccess, productSuccess, companySuccess, dispatch, type, showAdd]);

  const handleAdd = () => {
    setLoading(true);
    if (type === "customer") dispatch(peopleAddRequest({ peopleData: addFormData, loadingData: peopleLoadingData }));
    else if (type === "lead") dispatch(leadAddRequest({ leadData: addFormData, loadingData: leadLoadingData }));
    else if (type === "product") dispatch(productAddRequest({ productData: addFormData, loadingData: productLoadingData }));
    else if (type === "company") dispatch(companyAddRequest({ companyData: addFormData, loadingData: companyLoadingData }));
  };

  const addTag = (name, colorCode) => {
    if (type === "customer") dispatch(peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    else if (type === "lead") dispatch(leadTagsAddRequest({ name: name, colorCode: colorCode, type: type }));
    else if (type === "product") dispatch(productTagsAddRequest({ name: name, colorCode: colorCode, type: type }));
    else if (type === "company") dispatch(companyTagsAddRequest({ name: name, colorCode: colorCode, type: type }));
  };

  const editTag = (id, name, colorCode) => {
    if (type === "customer") dispatch(peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    else if (type === "lead") dispatch(leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    else if (type === "product") dispatch(productTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    else if (type === "company") dispatch(companyTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    if (type === "customer") dispatch(peopleTagsDeleteRequest(id));
    else if (type === "lead") dispatch(leadTagsDeleteRequest(id));
    else if (type === "product") dispatch(productTagsDeleteRequest(id));
    else if (type === "company") dispatch(companyTagsDeleteRequest(id));
  };

  const addCategory = (name) => {
    dispatch(productCategoriesAddRequest({ name: name }));
  };

  const editCategory = (id, name) => {
    dispatch(productCategoriesEditRequest({ id: id, name: name }));
  };

  const deleteCategory = (id) => {
    dispatch(productCategoriesDeleteRequest(id));
  };

  const onAddDealModalClose = () => {
    setOpenAddDealModal(false);
  };

  const onAddDealModalSave = (data) => {
    dispatch(addDealRequest({ data, filter: dealPipelineLoadingData, pageForm: "dealPipeline" }));
    setOpenAddDealModal(false);
  };

  const getPipelineOptions = () => {
    let data = pipelineStore.pipelineOptionsResponse;
    let pipelineArray = [];
    data && data.length > 0 && data.map((pipeline) => pipelineArray.push({ name: pipeline.name, id: pipeline._id }));
    return pipelineArray;
  };

  const onCreateActivity = (data) => {
    dispatch(createActivityRequest({ activityData: data, loadingData: activityLoadingStore, from: "activity-list" }));
  };

  const closeScheduleActivity = (value) => {
    setOpenAddActivityModal(value);
  };

  return (
    <>
      <AddDealModal
        dealModalProps={{
          openAddDealModal: openAddDealModal,
          onAddDealModalClose: () => onAddDealModalClose(),
          onAddDealModalSave: (data) => onAddDealModalSave(data),
          pipelinedropdownOptions: getPipelineOptions(),
          pipelineswithstages: pipelineStore.pipelineOptionsResponse,
          selectedPipeline: pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0].name : "",
          selectedPipelineId: pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0]._id : "",
          tags: dealTagStore,
          ownerId: userProfile && userProfile.data && userProfile.data.userId,
        }}
      />
      <ScheduleActivity openAddActivityModal={openAddActivityModal} onCreateActivity={(data) => onCreateActivity(data)} closeScheduleActivity={(value) => closeScheduleActivity(value)} />
      <CustomForm
        show={showAdd}
        setShow={setShowAdd}
        handleAdd={handleAdd}
        addTag={addTag}
        editTag={editTag}
        deleteTag={deleteTag}
        addCategory={addCategory}
        editCategory={editCategory}
        deleteCategory={deleteCategory}
        page={page}
        pageSub={pageSub}
        fields={fields.list}
        customFormData={addFormData}
        setCustomFormData={setAddFormData}
        fieldsLoading={fields.loading}
        propLoading={loading}
        cacheCompany={true}
      />
      <CreateCampaignModal show={showCreateCampaign} setShow={setShowCreateCampaign} />
      {layoutProps.offcanvas && (
        <OverlayTrigger placement="left" overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}>
          <div className="topbar-item">
            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 svg-icon-div" id="kt_quick_actions_toggle">
              <span
                className="header-svg"
                // className="svg-icon svg-icon-xl svg-icon-primary"
              >
                <i className="fa fa-add"></i>
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown show={show} onToggle={() => setShow(!show)} drop="down" alignRight>
          <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_actions_panel_toggle">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}>
              <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 svg-icon-div">
                <span
                  className="header-svg"
                  // className="svg-icon svg-icon-xl svg-icon-primary"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
                  </svg>
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            <form>
              {/* begin: Head */}
              <div
                className="bg-121212 d-flex flex-column align-items-center justify-content-center pt-8 pb-8 bgi-size-cover bgi-no-repeat rounded-top blue-gradient"
                // style={{ background: 'linear-gradient( 90deg, rgba(0,172,255,1) 50%, rgba(2,150,230,1) 100%)' }}
              >
                <h3 className="text-white font-weight-bold font-size-5 m-0">Quick Actions</h3>
              </div>
              {/* end: Head */}
              <div className="row row-paddingless">
                <div onClick={() => handleSelectLeads()} className="col-6">
                  <div className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                    <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                      <i className="bi bi-bullseye mb-2 font-size-30px"></i>
                      <span className="d-block fw-bold text-start">
                        <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">Add Lead</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div onClick={() => handleSelectDeal()} className="col-6">
                  <div className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                    <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                      {/* <i className="bi bi-coin mb-2 font-size-30px" ></i> */}
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-coin  mb-2" viewBox="0 0 16 16">
                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                      </svg>
                      <span className="d-block fw-bold text-start">
                        <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">Add Deal</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row-paddingless">
                <div className="col-6">
                  <div onClick={() => handleSelectPeople()} className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center ">
                    <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                      <i className="bi bi-people mb-2 font-size-30px"></i>
                      <span className="d-block fw-bold text-start">
                        <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">Add People</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div onClick={() => handleSelectCompanies()} className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                    <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                      <i className="bi bi-building mb-2 font-size-30px"></i>
                      <span className="d-block fw-bold text-start">
                        <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">Add Company</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div onClick={() => handleSelectActivity()} className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                    <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                      <i className="bi bi-calendar-check mb-2 font-size-30px"></i>
                      <span className="d-block fw-bold text-start">
                        <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">Add Activity</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div onClick={() => handleSelectCampaign()} className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                    <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                      <i className="bi bi-megaphone mb-2 font-size-30px"></i>
                      <span className="d-block fw-bold text-start">
                        <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">Add Campaign</span>
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className="col-6">
                    <div onClick={() => handleSelectProducts()} className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" >
                      <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                        <i className="bi bi-basket2 mb-2 font-size-30px" ></i>
                        <span className="d-block fw-bold text-start">
                          <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                            Add Product
                          </span>
                        </span>
                      </div>
                    </div>
                  </div> */}
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
