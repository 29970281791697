// import MainActionBar from 'modules/boards/containers/MainActionBar';
import {
  // BoardContainer,
  // BoardContent,
  ScrolledContent,
} from "../styled";
import Calendar from "../common/calendar/calendar";
// import { IDateColumn } from 'modules/common/types';
// import { __ } from 'modules/common/utils';
// import Header from 'modules/layout/components/Header';
import React from "react";
import styled from "styled-components";
import DealColumn from "./dealColumn";
// import DealMainActionBar from '../DealMainActionBar';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const toKey = ({ year, month }) => {
  return year + "-" + month;
};

class DealForecastWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onColumnUpdated = (date) => {
    this.setState({ [toKey(date)]: new Date().toString() });
  };

  renderColumn = (date) => {
    const { queryParams } = this.props;
    const key = toKey(date);

    return (
      <DealColumn
        updatedAt={`${key}-${this.state[key]}`}
        date={date}
        queryParams={queryParams}
        onColumnUpdated={this.onColumnUpdated}
        // pipelineId={queryParams.pipelineId}
        pipelineId={"1234"}
      />
    );
  };

  // renderActionBar = (renderMiddleContent) => {
  //   return (
  //     <MainActionBar
  //       type="deal"
  //       component={DealMainActionBar}
  //       middleContent={renderMiddleContent}
  //     />
  //   );
  // };

  renderMonthView(renderMonths) {
    return <Container>{renderMonths()}</Container>;
  }

  renderContent = (renderMonths, renderMiddleContent) => {
    // const breadcrumb = [{ title: ('Deal') }];

    return (
      // <BoardContainer>
      //   <Header title={__('Deal')} breadcrumb={breadcrumb} />
      //   <BoardContent transparent={true}>
      //     {this.renderActionBar(renderMiddleContent)}
      <ScrolledContent>{this.renderMonthView(renderMonths)}</ScrolledContent>
      //   </BoardContent>
      // </BoardContainer>
    );
  };

  render() {
    return <Calendar renderContent={this.renderContent} renderColumn={this.renderColumn} currentDate={this.props.currentDate} />;
  }
}

export default DealForecastWrapper;
