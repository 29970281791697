import React from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import AuthorView from "./author-preview";

export default function VideoCard(props) {
  const getVideoId = (url) => {
    const _array = url.split("/");
    return _array[_array.length - 1];
  };
  return (
    <div className="video-card-wrapper card p-4 cursor-pointer" onClick={() => props.handleVideoCardClick(props.data)}>
      <div className="card-image-wrapper border-radius-10px">
        <img width="100%" height="100%" alt={props.data.title} src={`https://img.youtube.com/vi/${getVideoId(props.data.url)}/mqdefault.jpg`} />
      </div>
      <div className="d-flex justify-content-between">
        <div className="video-title webkit-box-2-line">
          <OverlayTrigger overlay={<Tooltip>{props.data.title}</Tooltip>}>
            <span> {props.data.title}</span>
          </OverlayTrigger>
        </div>
        {props.type === "Company" && (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown>
              <Dropdown.Toggle className="p-0 m-0 mt-2 w-25px" variant="transparent">
                <i className="bi bi-three-dots-vertical"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu w-100-mn">
                <Dropdown.Item className="dropdown-hover" onSelect={() => props.handleEditVideo(props.data)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" onSelect={() => props.handleDeleteVideo(props.data._id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="video-owner d-flex flex-stack align-items-center">
        <AuthorView name={props.data.author} />
        <div className="video-date text-right w-220px">{moment(props.data.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}
