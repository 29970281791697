import {
  getListOfSalesTarget,
  getSalesTargetGraph,
  getListOfSalesTargetLoadMore,
  // createSalesTarget, editSalesTarget, deleteSalesTarget
} from "../../store/types/sales-target.types";

const initialState = {
  list: {
    data: [],
    count: 0,
    loading: false,
    error: null,
    loadMoreLoading: false,
  },
  graph: {
    data: [],
    loading: false,
    error: null,
  },
};

export function SalesTargetReducer(state = initialState, action = {}) {
  switch (action.type) {
    case getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
          loadMoreLoading: false,
        },
      };
    case getListOfSalesTarget.GET_LIST_OF_SALES_TARGET_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
          loadMoreLoading: false,
        },
      };
    case getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loadMoreLoading: true,
        },
      };
    case getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: [...state.list.data, ...action.payload.data],
          count: action.payload.count,
          loadMoreLoading: false,
        },
      };
    case getListOfSalesTargetLoadMore.GET_LIST_OF_SALES_TARGET_LOAD_MORE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
          loadMoreLoading: false,
        },
      };
    case getSalesTargetGraph.GET_SALES_TARGET_GRAPH_REQUEST:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: true,
        },
      };
    case getSalesTargetGraph.GET_SALES_TARGET_GRAPH_SUCCESS:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: false,
          data: action.payload.data,
        },
      };
    case getSalesTargetGraph.GET_SALES_TARGET_GRAPH_ERROR:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
}
