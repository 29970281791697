import {
  GET_INTEGRATIONS_DETAILS_REQUEST,
  GET_INTEGRATIONS_DETAILS_SUCCESS,
  GET_INTEGRATIONS_DETAILS_ERROR,
  DELETE_INTEGRATION_REQUEST,
  DELETE_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_ERROR,
  GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST,
  GET_INTEGRATION_ACCOUNT_DETAILS_SUCCESS,
  GET_INTEGRATION_ACCOUNT_DETAILS_ERROR,
  GET_INTEGRATION_AUTH_URL_REQUEST,
  GET_INTEGRATION_AUTH_URL_SUCCESS,
  GET_INTEGRATION_AUTH_URL_ERROR,
  GET_INTEGRATION_CALLBACK_REQUEST,
  GET_INTEGRATION_CALLBACK_SUCCESS,
  GET_INTEGRATION_CALLBACK_ERROR,
} from "../types/integrations.types";

//INTEGRATION CALLBACK
export function getIntegrationCallbackRequest(req, cb) {
  return {
    type: GET_INTEGRATION_CALLBACK_REQUEST,
    payload: req,
    cb,
  };
}
export function getIntegrationCallbackSuccess(res) {
  return {
    type: GET_INTEGRATION_CALLBACK_SUCCESS,
    payload: res,
  };
}
export function getIntegrationCallbackError(err) {
  return {
    type: GET_INTEGRATION_CALLBACK_ERROR,
    payload: err,
  };
}

//INTEGRATION AUTH URL
export function getIntegrationAuthUrlRequest(req, cb) {
  return {
    type: GET_INTEGRATION_AUTH_URL_REQUEST,
    payload: req,
    cb,
  };
}
export function getIntegrationAuthUrlSuccess(res) {
  return {
    type: GET_INTEGRATION_AUTH_URL_SUCCESS,
    payload: res,
  };
}
export function getIntegrationAuthUrlError(err) {
  return {
    type: GET_INTEGRATION_AUTH_URL_ERROR,
    payload: err,
  };
}

//integration account details
export function getIntegrationAccountDetailsRequest(req) {
  return {
    type: GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST,
    payload: req,
  };
}
export function getIntegrationAccountDetailsSuccess(res) {
  return {
    type: GET_INTEGRATION_ACCOUNT_DETAILS_SUCCESS,
    payload: res,
  };
}
export function getIntegrationAccountDetailsError(err) {
  return {
    type: GET_INTEGRATION_ACCOUNT_DETAILS_ERROR,
    payload: err,
  };
}

//get integrations
export function getIntegrationDetailsRequest(req) {
  return {
    type: GET_INTEGRATIONS_DETAILS_REQUEST,
    payload: req,
  };
}
export function getIntegrationDetailsSuccess(res) {
  return {
    type: GET_INTEGRATIONS_DETAILS_SUCCESS,
    payload: res,
  };
}
export function getIntegrationDetailsError(err) {
  return {
    type: GET_INTEGRATIONS_DETAILS_ERROR,
    payload: err,
  };
}

//delete integration
export function deleteIntegrationRequest(req) {
  return {
    type: DELETE_INTEGRATION_REQUEST,
    payload: req,
  };
}
export function deleteIntegrationSuccess(res) {
  return {
    type: DELETE_INTEGRATION_SUCCESS,
    payload: res,
  };
}
export function deleteIntegrationError(err) {
  return {
    type: DELETE_INTEGRATION_ERROR,
    payload: err,
  };
}
