import { takeLatest, put, call } from "redux-saga/effects";
import * as emailValidationTypes from "../types/email-validation.types";
import * as emailValidationActions from "../actions/email-validation.actions";
import * as leadActions from "../../../leads/store/actions/actions";
import * as peopleActions from "../../../customers/store/actions/actions";
import * as marketingListActions from "../../../campaign/store/actions/campaign_marketing_list_actions";

import api from "../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";

const endpoints = {
    GET_LIST_EMAIL_VALIDATION: "/api/crm/tools/email-validation",
    GET_EMAIL_VALIDATION_PRICING: "/user-service/api/campaign/pricing",
    CREATE_EMAIL_VALIDATION: "/api/crm/tools/email-validation"
}

function* getListOfEmailValidation(action) {
    try {
        const res = yield call(api.get, `${endpoints.GET_LIST_EMAIL_VALIDATION}`, action.payload);
        if (res.status === 200 && res.data.status) {
            yield put(emailValidationActions.getListOfEmailValidationSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(emailValidationActions.getListOfEmailValidationFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(emailValidationActions.getListOfEmailValidationFailure(e));
    }
}

function* getEmailValidationPricing(action) {
    try {
        const res = yield call(api.get, `${endpoints.GET_EMAIL_VALIDATION_PRICING}/${action.payload}`);
        if (res.status === 200 && res.data.status) {
            yield put(emailValidationActions.getEmailValidationPricingSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(emailValidationActions.getEmailValidationPricingFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(emailValidationActions.getEmailValidationPricingFailure(e));
    }
}

function* createEmailValidation(action) {
    try {
        const res = yield call(api.post, `${endpoints.CREATE_EMAIL_VALIDATION}`, action.payload.data);
        // const res = yield new Promise((resolve) => setTimeout(() => resolve({ status: 200, data: { status: true, message: "Successfully Generated" } }), 1000))
        if (action.cb) action.cb(res.data);
        if (res.status === 200 && res.data.status) {
            dispatchSnackbarSuccess(res.data.message);
            yield put(emailValidationActions.createEmailValidationSuccess(res.data));
            if (action.payload.from === "lead") {
                yield new Promise((resolve) => setTimeout(() => resolve(), 2000))
                yield put(leadActions.leadsUpdateRequest({ loadingData: action.payload.filter, cellId: null }));
            }
            else if (action.payload.from === "people") {
                yield new Promise((resolve) => setTimeout(() => resolve(), 2000))
                yield put(peopleActions.peoplesUpdateRequest({ loadingData: action.payload.filter, cellId: null }));
            }
            else if (action.payload.from === "marketing_list") {
                yield new Promise((resolve) => setTimeout(() => resolve(), 2000))
                yield put(marketingListActions.marketingUpdateRequest({ loadingData: action.payload.filter, cellId: null }));
            }
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(emailValidationActions.createEmailValidationFailure(res.data));
        }
    } catch (e) {
        yield put(emailValidationActions.createEmailValidationFailure(e));
    }
}

export default function* emailValidationSaga() {
    yield takeLatest(emailValidationTypes.getListOfEmailValidation.REQUEST, getListOfEmailValidation);
    yield takeLatest(emailValidationTypes.getEmailValidationPricing.REQUEST, getEmailValidationPricing);
    yield takeLatest(emailValidationTypes.createEmailValidation.REQUEST, createEmailValidation);

}
