/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import _ from "lodash";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { menuListConstants } from "./constants";

export function AsideMenuList({ layoutProps }) {
  const profileStore = useSelector((store) => store.ProfileSettings.getProfileDetails.data);

  const history = useHistory();

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "aside-newtheme"} menu-item-open menu-item-not-hightlighted` : "";
  };

  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  const isSubscriptionExpired = () => {
    // if (accountBillingStore.loading) return false;
    if (_.isEmpty(accountBillingStore.data)) return false;
    const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
    if (isFreeTrialPeriod || isSubscribedForLifeTimeFree) return false;
    else if (["active", "past_due", "trialing"].includes(subscriptionStatus)) return false;
    else if (["none", "canceled", "paused"].includes(subscriptionStatus)) return true;
    return true;
  };
  console.log("aside", isSubscriptionExpired())
  return (
    <>
      {isSubscriptionExpired() ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className={`menu-item marb-10 menu-item-open menu-item-not-hightlighted`} aria-haspopup="true" data-menu-toggle="hover">
            <NavLink className="menu-link aside-newtheme d-flex flex-column justify-content-center px-4" to="/settings/billing/subscription">
              <span className="svg-icon menu-icon d-flex justify-content-center">
                <i className="bi bi-currency-dollar text-primary"></i>
              </span>
              <span className="menu-text d-block text-center">{"Billing"}</span>
            </NavLink>
          </li>
        </ul>
      ) : accountBillingStore && !accountBillingStore.loading && accountBillingStore.data.accountStatus === "DEACTIVATED" ? (
        <></>
      ) : (
        <>
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {menuListConstants.map((each, index) => {
              return (
                <li key={index} className={`menu-item marb-10 ${getMenuItemActive(each.parentPath, each.hasSubMenu)}`} aria-haspopup="true" data-menu-toggle="hover">
                  {each.subMenu ? (
                    <>
                      <a className="menu-link aside-newtheme d-flex flex-column justify-content-center px-4">
                        <OverlayTrigger placement="right" overlay={<Tooltip>{each.title}</Tooltip>}>
                          <span className="svg-icon menu-icon d-flex justify-content-center">{each.icon}</span>
                        </OverlayTrigger>
                        {/* <span className="menu-text d-block text-center">{each.title}</span> */}
                      </a>
                      {each.subMenu && (
                        <div className="menu-submenu left-10px">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            {each.subMenu.sectionA.map((_each, ind) => {
                              return (
                                <div key={ind} className={`menu-item`}>
                                  <div className="menu-link py-3 menu-title-gray-700 text-hover-primary" onClick={() => history.push(_each.to)}>
                                    <span className="menu-icon">
                                      <span className="svg-icon svg-icon-2">{_each.icon}</span>
                                    </span>
                                    <span className="  menu-title">{_each.title}</span>
                                  </div>
                                </div>
                              );
                            })}
                            <Dropdown.Divider></Dropdown.Divider>
                            {each.subMenu.sectionB.map((_each, ind) => {
                              if (_each.restricted && ["AGENT"].includes(profileStore.userType)) {
                                return <div key={ind}></div>;
                              }
                              return (
                                <div key={ind} className={`menu-item`}>
                                  <div className="menu-link py-3 menu-title-gray-700 text-hover-primary" onClick={() => history.push(_each.to)}>
                                    <span className="menu-icon">
                                      <span className="svg-icon svg-icon-2">{_each.img ? <img width={"20px"} height={"20px"} src={_each.img} alt={"Import / Export"} /> : _each.icon}</span>
                                    </span>
                                    <span className="  menu-title">{_each.title}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </>
                  ) : (
                    <NavLink className="menu-link aside-newtheme d-flex flex-column justify-content-center px-4" to={each.to}>
                      <OverlayTrigger placement="right" overlay={<Tooltip>{each.title}</Tooltip>}>
                        <span className="svg-icon menu-icon d-flex justify-content-center">{each.icon}</span>
                      </OverlayTrigger>
                      {/* <span className="menu-text d-block text-center">{each.title}</span> */}
                    </NavLink>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
}
