import React, { useState } from "react";
// import {Popover,OverlayTrigger,Pagination,Form} from 'react-bootstrap'
import { Pagination } from "react-bootstrap";

const CustomPagination = () => {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <div className="d-flex flex-row gap-20px mt-5px">
      <Pagination>{items}</Pagination>
    </div>
  );
};

export default function UserCard(props) {
  const [userDetails] = useState(props.details);
  const [currentTab, setcurrentTab] = useState("card");

  function HandleActive(id) {
    setcurrentTab(id);

    let a = ["card", "table"];

    let b = a.filter((res) => res !== id);

    for (let i = 0; i < b.length; i++) {
      var element = document.getElementById(b[i]);
      element.classList.remove("active");
    }

    var v = document.getElementById(id);
    v.className += " active";
  }
  return (
    <>
      <div className="d-flex justify-content-end pb-18px">
        <ul className="nav nav-pills me-6">
          <li className="nav-item m-0" onClick={() => HandleActive("card")}>
            <a className="btn btn-sm btn-icon btn-white btn-color-muted btn-active-primary me-3 active" id="card" data-bs-toggle="tab" href="#kt_project_users_card_pane">
              <span className="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <rect x={5} y={5} width={5} height={5} rx={1} fill="#000000" />
                    <rect x={14} y={5} width={5} height={5} rx={1} fill="#000000" opacity="0.3" />
                    <rect x={5} y={14} width={5} height={5} rx={1} fill="#000000" opacity="0.3" />
                    <rect x={14} y={14} width={5} height={5} rx={1} fill="#000000" opacity="0.3" />
                  </g>
                </svg>
              </span>
            </a>
          </li>

          <li className="nav-item m-0" onClick={() => HandleActive("table")}>
            <a className="btn btn-sm btn-icon btn-white btn-color-muted btn-active-primary" id="table" data-bs-toggle="tab" href="#kt_project_users_table_pane">
              {/*begin::Svg Icon | path: icons/stockholm/Layout/Layout-horizontal.svg*/}
              <span className="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <rect x={0} y={0} width={24} height={24} />
                    <rect fill="#000000" opacity="0.3" x={4} y={5} width={16} height={6} rx="1.5" />
                    <rect fill="#000000" x={4} y={13} width={16} height={6} rx="1.5" />
                  </g>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </div>
      {/*begin::Tab Content*/}
      {/* <div className="tab-content"> */}
      {/*begin::Tab pane*/}

      {currentTab === "card" ? (
        <div id="kt_project_users_card_pane" className="tab-pane fade show active">
          {/*begin::Row*/}
          <div className="row g-6 g-xl-9">
            {/*begin::Col*/}
            {/* <div className="col-md-6 col-xxl-4"> */}
            {/*begin::Card*/}
            {userDetails.map((users, index) => (
              <div className="col-md-6 col-xxl-4">
                <div className="card">
                  {/*begin::Card body*/}
                  <div className="card-body d-flex flex-center flex-column pt-12 p-9">
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-65px symbol-circle mb-5">
                      <span className="symbol-label fs-2x fw-bold text-primary bg-light-primary">{users.userName.split("")[0]}</span>
                      <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3" />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Name*/}
                    <a href="/#" className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">
                      {users.userName}
                    </a>
                    {/*end::Name*/}
                    {/*begin::Position*/}
                    <div className="fw-bold text-gray-400 mb-6">{users.email}</div>
                    {/*end::Position*/}
                    {/*begin::Info*/}
                    <div className="d-flex flex-wrap"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {CustomPagination()}
        </div>
      ) : (
        ""
      )}
      {/* ****************************************************************************************** */}

      {currentTab === "table" ? (
        <div className="card card-flush">
          {/*begin::Card body*/}
          <div className="card-body pt-0">
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">
                {/*begin::Head*/}
                <thead className="fs-7 text-gray-400 text-uppercase">
                  <tr>
                    <th className="min-w-250px">User</th>
                    <th className="min-w-150px">Permission Set</th>
                    <th className="min-w-90px">Last Login</th>
                  </tr>
                </thead>
                <tbody className="fs-6">
                  {userDetails.map((users, index) => (
                    <tr>
                      <td>
                        {/*begin::User*/}
                        <div className="d-flex align-items-center">
                          {/*begin::Wrapper*/}
                          <div className="me-5 position-relative">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-bold">{users.userName.split("")[0]}</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Online*/}
                            <div className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1" />
                            {/*end::Online*/}
                          </div>
                          {/*end::Wrapper*/}
                          {/*begin::Info*/}
                          <div className="d-flex flex-column justify-content-center">
                            <a href className="mb-1 text-gray-800 text-hover-primary">
                              {users.userName}
                            </a>
                            <div className="fw-bold fs-6 text-gray-400">{users.email}</div>
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::User*/}
                      </td>
                      <td>{users.sets}</td>
                    </tr>
                  ))}
                </tbody>
                {/*end::Body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
          </div>
          {/*end::Card body*/}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
