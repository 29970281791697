import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { InputLimit } from "../../../constants";
import InlineStyled from "../../../components/inline-styled/inline-styled";

/**
 * 
tags
setTags
assignee
setAssignee
 */
export default function GeneralSettings(props) {
  const [tagSelectedOption, setTagSelectedOption] = useState({});
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [ownersList, setOwnersList] = useState([]);
  const [tags, setTags] = useState([]);

  const tagsList = useSelector((store) => store.leadBot.tags.list);
  const owners = useSelector((store) => store.leadBot.owner.list.users);

  useEffect(() => {
    if (owners) setOwnersList(owners.map((item) => ({ value: item.id, label: item.name })));
  }, [owners]);
  useEffect(() => {
    if (tagsList) setTags(tagsList.map((item) => ({ value: item._id, label: item.name, colorCode: item.colorCode })));
  }, [tagsList]);

  useEffect(() => {
    if (props.tags && Array.isArray(props.tags) && props.tags.length > 0) {
      const selected = tags.find((a) => a.value === props.tags[0]);
      if (selected) {
        setTagSelectedOption(selected);
      }
    }
  }, [props.tags, tags]);

  useEffect(() => {
    if (props.assignee && Array.isArray(props.assignee) && props.assignee.length > 0) {
      const selected = ownersList.filter((a) => props.assignee.includes(a.value));
      if (selected) {
        setSelectedOwner(selected);
      }
    }
  }, [props.assignee, ownersList]);

  const onTagSelect = (option) => {
    props.setTags([option.value]);
  };
  const onSelectOwner = (option) => {
    props.setAssignee(option.map((each) => each.value));
  };
  return (
    <div className="m-5">
      <Form.Group id="leadbot-components-general-settings-builder-botname">
        <Form.Label aria-label="bot-name">Bot Name</Form.Label>
        <Form.Control maxLength={InputLimit.TEXT} type="text" value={props.name} onChange={(e) => props.setName(e.target.value)}></Form.Control>
      </Form.Group>
      {false && (
        <>
          <Form.Group id="leadbot-components-general-settings-builder-tag-selection">
            <Form.Label aria-label="select-tag">Select Tag</Form.Label>
            <Dropdown className="w-100 mt-1">
              <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 d-flex align-items-center text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                {tagSelectedOption.label ? (
                  <InlineStyled as="span" styles={{ backgroundColor: tagSelectedOption.colorCode }} className="text-333 badge badge-dark  tag-ellipsis rounded-pill m-0 overflow-hidden text-overflow-ellipsis">
                    {tagSelectedOption.label}
                  </InlineStyled>
                ) : (
                  "Select Tag"
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100-mn overflow-y-scroll h-300px">
                {tags.map((option, index) => (
                  <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => onTagSelect(option)}>
                    <InlineStyled as="span" styles={{ backgroundColor: option.colorCode }} className="text-333 badge badge-dark tag-ellipsis rounded-pill m-0 overflow-hidden text-overflow-ellipsis">
                      {option.label}
                    </InlineStyled>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group id="leadbot-components-general-settings-builder-owner-selection">
            <Form.Label aria-label="select-owner">Select Owner</Form.Label>
            <Select isMulti name="colors" value={selectedOwner} options={ownersList} className="h-300px-mx" classNamePrefix="select Owners" onChange={(e) => onSelectOwner(e)} />
          </Form.Group>
        </>
      )}
    </div>
  );
}
