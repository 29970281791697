import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as syncSettingActions from "../../../store/actions/profile-actions/sync-settings.actions";
import _ from "lodash";
import ScheduledGIF from "../../../../../images/gif/schedule-gif.gif";

export default function EmailSyncCallback() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const setLoadingText = (message) => {
    setLoading(false);
    setMessage(message);

    setTimeout(() => {
      window.location.href = `/settings/profile/sync-settings`;
    }, 2000);
  };

  useEffect(() => {
    if (history.location.search !== "") {
      const code = history.location.search.split("code=");
      dispatch(
        syncSettingActions.hostedAuthCallbackRequest(
          {
            code: code[1],
          },
          (data) => {
            setLoadingText(_.capitalize(data.message));
          },
        ),
      );
    }
  }, [history.location.search, dispatch]);

  return (
    <div className="card p-6 h-100 d-flex align-items-center">
      {loading ? (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 fs-20 mt-20">
          <div>
            <h4>Kindly hold on until we establish a connection with your email account.</h4>
          </div>
          <div className="fs-5 mt-4">Once the connection is established, you can use the Sales Simplify app to send and receive emails. Please wait while we connect it for you.</div>
          <div>
            <img src={ScheduledGIF} className="mx-auto h-100" alt="add" />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 fs-20 mt-20">
          <div>
            <h4>{message}</h4>
          </div>
          <div className="fs-5 mt-4">Once the connection is established, you can use the Sales Simplify app to send and receive emails. Please wait while we connect it for you.</div>
          <div>
            <img src={ScheduledGIF} className="mx-auto h-100" alt="add" />
          </div>
        </div>
      )}
    </div>
  );
}
