import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Overlay } from "react-bootstrap";
import Calendar from "./Calendar";

const locale = {
  direction: "ltr",
  format: "DD/MM/YYYY",
  separator: " - ",
  applyLabel: "Apply",
  cancelLabel: "Cancel",
  weekLabel: "W",
  customRangeLabel: "Custom Range",
  daysOfWeek: moment.weekdaysMin(),
  monthNames: moment.monthsShort(),
  firstDay: moment.localeData().firstDayOfWeek(),
};

const defaultRanges = [
  { status: "Today", range: [moment(), moment()] },
  { status: "Yesterday", range: [moment().subtract(1, "days"), moment().subtract(1, "days")] },
  { status: "Last 7 days", range: [moment().subtract(6, "days"), moment()] },
  { status: "Last 30 days", range: [moment().subtract(29, "days"), moment()] },
  { status: "This Month", range: [moment().startOf("month"), moment().endOf("month")] },
  {
    status: "Last Month",
    range: [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month"),
    ],
  },
];

export default function DateRangePickerInput({ defaultStartDate, defaultEndDate, ranges = defaultRanges, onDateRangeChange, placement = "bottom-start" }) {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [isFocusedOnInput, setIsFocusedOnInput] = useState(false);
  const previouslyAppliedDateRange = useRef({ startDate, endDate });

  const formatDateRangeInput = (startDateParam, endDateParam) => {
    if (!startDateParam) return "";
    return startDateParam?.format(locale.format) + locale.separator + (endDateParam?.format(locale.format) || "");
  };
  const [dateRangeInput, setDateRangeInput] = useState(formatDateRangeInput(startDate, endDate));
  const activeStatus = useMemo(() => {
    if (!startDate || !endDate) return "";
    const status = ranges.find(({ range }) => {
      return startDate.format(locale.format) === range[0].format(locale.format) && endDate.format(locale.format) === range[1].format(locale.format);
    });

    return status ? status.status : "Custom";
  }, [ranges, startDate, endDate]);
  const [showCalendar, setShowCalendar] = useState(false);
  const rangesRef = useRef(ranges);

  const [showOverlay, setShowOverlay] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (activeStatus === "Custom") {
      setShowCalendar(true);
    }
  }, [activeStatus]);

  const handleFocus = () => {
    setIsFocusedOnInput(true);
    setShowOverlay(true);
  };

  const handleDateRangeInputChange = (e) => {
    setDateRangeInput(e.target.value);

    const dateRangeInputValue = e.target.value;

    if (!dateRangeInputValue.length) return;

    let dateString = dateRangeInputValue.split(locale.separator),
      start = null,
      end = null;

    if (dateString.length === 2) {
      start = moment(dateString[0], locale.format);
      end = moment(dateString[1], locale.format);
    }

    if (start > end || start === null || end === null) {
      start = moment(dateRangeInputValue, locale.format);
      end = start;
    }
    if (!start.isValid() || !end.isValid()) return;

    setStartDate(start);
    setEndDate(end);
  };

  const triggerOnNewDateRangeApply = (startDate, endDate, status) => {
    onDateRangeChange({
      status,
      dates: {
        startDate,
        endDate,
      },
    });
  };

  const applyChanges = (startDate, endDate, status) => {
    previouslyAppliedDateRange.current = { startDate, endDate };
    setShowOverlay(false);
    setDateRangeInput(formatDateRangeInput(startDate, endDate));
    triggerOnNewDateRangeApply(startDate, endDate, status);
  };

  const discardChanges = () => {
    setShowOverlay(false);
    setStartDate(previouslyAppliedDateRange.current.startDate);
    setEndDate(previouslyAppliedDateRange.current.endDate);
    setDateRangeInput(formatDateRangeInput(previouslyAppliedDateRange.current.startDate, previouslyAppliedDateRange.current.endDate));
  };

  return (
    <div className="ss-daterangepicker-container">
      <Form.Group>
        <Form.Control
          value={dateRangeInput}
          onChange={handleDateRangeInputChange}
          ref={inputRef}
          type="text"
          onFocus={handleFocus}
          onBlur={() => setIsFocusedOnInput(false)}
          className="form-control"
          placeholder="Pick date range"
        />
      </Form.Group>
      <Overlay
        show={showOverlay}
        target={inputRef.current}
        placement={placement}
        rootClose={true}
        onHide={() => {
          if (showOverlay && !isFocusedOnInput) {
            if (endDate) return applyChanges(startDate, endDate, activeStatus);
            discardChanges();
          }
        }}
      >
        {(props) => (
          <div className="ss-daterangepicker ltr show-ranges show-calendar z-index-1051" style={{ ...props.style }} ref={props.ref}>
            <div className="ranges">
              <ul>
                {rangesRef.current.map(({ status, range }) => (
                  <li
                    key={status}
                    className={activeStatus === status ? "active" : ""}
                    onClick={() => {
                      setStartDate(range[0]);
                      setEndDate(range[1]);
                      setShowCalendar(false);
                      applyChanges(range[0], range[1], status);
                    }}
                  >
                    {status}
                  </li>
                ))}
                <li className={activeStatus === "Custom" ? "active" : ""} onClick={() => setShowCalendar(true)}>
                  Custom Range
                </li>
              </ul>
            </div>
            {showCalendar && (
              <>
                <Calendar locale={locale} {...{ startDate, endDate }} onStartDateSelect={setStartDate} onEndDateSelect={setEndDate} />
                <div className="drp-buttons">
                  <span className="drp-selected">
                    {startDate?.format(locale.format)} {locale.separator} {endDate?.format(locale.format)}
                  </span>
                  <button className="cancelBtn btn btn-sm btn-default" type="button" fdprocessedid="36q17a" onClick={discardChanges}>
                    Cancel
                  </button>
                  <button className="applyBtn btn btn-sm btn-primary" disabled={!endDate} onClick={() => applyChanges(startDate, endDate, activeStatus)} type="button" fdprocessedid="61glbh">
                    Apply
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </Overlay>
    </div>
  );
}
