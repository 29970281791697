export const elasticEmailCountryData = [
  { Name: "Afghanistan", CountryID: 1, Code: "AF", CallingCode: "93" },
  { Name: "Albania", CountryID: 2, Code: "AL", CallingCode: "355" },
  { Name: "Algeria", CountryID: 3, Code: "DZ", CallingCode: "213" },
  { Name: "American Samoa", CountryID: 4, Code: "AS", CallingCode: "1684" },
  { Name: "Andorra", CountryID: 5, Code: "AD", CallingCode: "376" },
  { Name: "Angola", CountryID: 6, Code: "AO", CallingCode: "244" },
  { Name: "Anguilla", CountryID: 7, Code: "AI", CallingCode: "1264" },
  { Name: "Antarctica", CountryID: 8, Code: "AQ", CallingCode: "672" },
  { Name: "Antigua And Barbuda", CountryID: 9, Code: "AG", CallingCode: "1268" },
  { Name: "Argentina", CountryID: 10, Code: "AR", CallingCode: "54" },
  { Name: "Armenia", CountryID: 11, Code: "AM", CallingCode: "374" },
  { Name: "Aruba", CountryID: 12, Code: "AW", CallingCode: "297" },
  { Name: "Australia", CountryID: 13, Code: "AU", CallingCode: "61" },
  { Name: "Austria", CountryID: 14, Code: "AT", CallingCode: "43" },
  { Name: "Azerbaijan", CountryID: 15, Code: "AZ", CallingCode: "994" },
  { Name: "Bahamas", CountryID: 16, Code: "BS", CallingCode: "1242" },
  { Name: "Bahrain", CountryID: 17, Code: "BH", CallingCode: "973" },
  { Name: "Bangladesh", CountryID: 18, Code: "BD", CallingCode: "880" },
  { Name: "Barbados", CountryID: 19, Code: "BB", CallingCode: "1246" },
  { Name: "Belarus", CountryID: 20, Code: "BY", CallingCode: "375" },
  { Name: "Belgium", CountryID: 21, Code: "BE", CallingCode: "32" },
  { Name: "Belize", CountryID: 22, Code: "BZ", CallingCode: "501" },
  { Name: "Benin", CountryID: 23, Code: "BJ", CallingCode: "229" },
  { Name: "Bermuda", CountryID: 24, Code: "BM", CallingCode: "1441" },
  { Name: "Bhutan", CountryID: 25, Code: "BT", CallingCode: "975" },
  { Name: "Bolivia", CountryID: 26, Code: "BO", CallingCode: "591" },
  { Name: "Bosnia And Herzegovina", CountryID: 27, Code: "BA", CallingCode: "387" },
  { Name: "Botswana", CountryID: 28, Code: "BW", CallingCode: "267" },
  { Name: "Bouvet Island", CountryID: 29, Code: "BV", CallingCode: "47" },
  { Name: "Brazil", CountryID: 30, Code: "BR", CallingCode: "55" },
  { Name: "British Indian Ocean Territory", CountryID: 31, Code: "IO", CallingCode: "246" },
  { Name: "Brunei Darussalam", CountryID: 32, Code: "BN", CallingCode: "673" },
  { Name: "Bulgaria", CountryID: 33, Code: "BG", CallingCode: "359" },
  { Name: "Burkina Faso", CountryID: 34, Code: "BF", CallingCode: "226" },
  { Name: "Burundi", CountryID: 35, Code: "BI", CallingCode: "257" },
  { Name: "Cambodia", CountryID: 36, Code: "KH", CallingCode: "855" },
  { Name: "Cameroon", CountryID: 37, Code: "CM", CallingCode: "237" },
  { Name: "Canada", CountryID: 38, Code: "CA", CallingCode: "1" },
  { Name: "Cape Verde", CountryID: 39, Code: "CV", CallingCode: "238" },
  { Name: "Cayman Islands", CountryID: 40, Code: "KY", CallingCode: "1345" },
  { Name: "Central African Republic", CountryID: 41, Code: "CF", CallingCode: "236" },
  { Name: "Chad", CountryID: 42, Code: "TD", CallingCode: "235" },
  { Name: "Chile", CountryID: 43, Code: "CL", CallingCode: "56" },
  { Name: "China", CountryID: 44, Code: "CN", CallingCode: "86" },
  { Name: "Christmas Island", CountryID: 45, Code: "CX", CallingCode: "53" },
  { Name: "Cocos (Keeling) Islands", CountryID: 46, Code: "CC", CallingCode: "61" },
  { Name: "Colombia", CountryID: 47, Code: "CO", CallingCode: "57" },
  { Name: "Comoros", CountryID: 48, Code: "KM", CallingCode: "269" },
  { Name: "Congo", CountryID: 49, Code: "CG", CallingCode: "242" },
  { Name: "Congo, The Democratic Republic Of The", CountryID: 50, Code: "CD", CallingCode: "243" },
  { Name: "Cook Islands", CountryID: 51, Code: "CK", CallingCode: "682" },
  { Name: "Costa Rica", CountryID: 52, Code: "CR", CallingCode: "506" },
  { Name: "Cote Divoire", CountryID: 53, Code: "CI", CallingCode: "225" },
  { Name: "Croatia", CountryID: 54, Code: "HR", CallingCode: "385" },
  { Name: "Cuba", CountryID: 55, Code: "CU", CallingCode: "53" },
  { Name: "Cyprus", CountryID: 56, Code: "CY", CallingCode: "357" },
  { Name: "Czech Republic", CountryID: 57, Code: "CZ", CallingCode: "420" },
  { Name: "Denmark", CountryID: 58, Code: "DK", CallingCode: "45" },
  { Name: "Djibouti", CountryID: 59, Code: "DJ", CallingCode: "253" },
  { Name: "Dominica", CountryID: 60, Code: "DM", CallingCode: "1767" },
  { Name: "Dominican Republic", CountryID: 61, Code: "DO", CallingCode: "1809" },
  { Name: "Ecuador", CountryID: 62, Code: "EC", CallingCode: "593" },
  { Name: "Egypt", CountryID: 63, Code: "EG", CallingCode: "20" },
  { Name: "El Salvador", CountryID: 64, Code: "SV", CallingCode: "503" },
  { Name: "Equatorial Guinea", CountryID: 65, Code: "GQ", CallingCode: "240" },
  { Name: "Eritrea", CountryID: 66, Code: "ER", CallingCode: "291" },
  { Name: "Estonia", CountryID: 67, Code: "EE", CallingCode: "" },
  { Name: "Ethiopia", CountryID: 68, Code: "ET", CallingCode: "251" },
  { Name: "Falkland Islands (Malvinas)", CountryID: 69, Code: "FK", CallingCode: "500" },
  { Name: "Faroe Islands", CountryID: 70, Code: "FO", CallingCode: "298" },
  { Name: "Fiji", CountryID: 71, Code: "FJ", CallingCode: "679" },
  { Name: "Finland", CountryID: 72, Code: "FI", CallingCode: "358" },
  { Name: "France", CountryID: 73, Code: "FR", CallingCode: "33" },
  { Name: "French Guiana", CountryID: 74, Code: "GF", CallingCode: "594" },
  { Name: "French Polynesia", CountryID: 75, Code: "PF", CallingCode: "689" },
  { Name: "French Southern Territories", CountryID: 76, Code: "TF", CallingCode: "262" },
  { Name: "Gabon", CountryID: 77, Code: "GA", CallingCode: "241" },
  { Name: "Gambia", CountryID: 78, Code: "GM", CallingCode: "220" },
  { Name: "Georgia", CountryID: 79, Code: "GE", CallingCode: "995" },
  { Name: "Germany", CountryID: 80, Code: "DE", CallingCode: "49" },
  { Name: "Ghana", CountryID: 81, Code: "GH", CallingCode: "233" },
  { Name: "Gibraltar", CountryID: 82, Code: "GI", CallingCode: "350" },
  { Name: "Greece", CountryID: 83, Code: "GR", CallingCode: "30" },
  { Name: "Greenland", CountryID: 84, Code: "GL", CallingCode: "299" },
  { Name: "Grenada", CountryID: 85, Code: "GD", CallingCode: "1473" },
  { Name: "Guadeloupe", CountryID: 86, Code: "GP", CallingCode: "590" },
  { Name: "Guam", CountryID: 87, Code: "GU", CallingCode: "1671" },
  { Name: "Guatemala", CountryID: 88, Code: "GT", CallingCode: "502" },
  { Name: "Guernsey", CountryID: 89, Code: "GG", CallingCode: "44" },
  { Name: "Guinea", CountryID: 90, Code: "GN", CallingCode: "224" },
  { Name: "Guinea-bissau", CountryID: 91, Code: "GW", CallingCode: "245" },
  { Name: "Guyana", CountryID: 92, Code: "GY", CallingCode: "592" },
  { Name: "Haiti", CountryID: 93, Code: "HT", CallingCode: "509" },
  { Name: "Heard Island And Mcdonald Islands", CountryID: 94, Code: "HM", CallingCode: "672" },
  { Name: "Holy See (Vatican City State)", CountryID: 95, Code: "VA", CallingCode: "39" },
  { Name: "Honduras", CountryID: 96, Code: "HN", CallingCode: "504" },
  { Name: "Hong Kong", CountryID: 97, Code: "HK", CallingCode: "852" },
  { Name: "Hungary", CountryID: 98, Code: "HU", CallingCode: "36" },
  { Name: "Iceland", CountryID: 99, Code: "IS", CallingCode: "354" },
  { Name: "India", CountryID: 100, Code: "IN", CallingCode: "91" },
  { Name: "Indonesia", CountryID: 101, Code: "ID", CallingCode: "62" },
  { Name: "Iran, Islamic Republic Of", CountryID: 102, Code: "IR", CallingCode: "98" },
  { Name: "Iraq", CountryID: 103, Code: "IQ", CallingCode: "964" },
  { Name: "Ireland", CountryID: 104, Code: "IE", CallingCode: "353" },
  { Name: "Isle Of Man", CountryID: 105, Code: "IM", CallingCode: "+44" },
  { Name: "Israel", CountryID: 106, Code: "IL", CallingCode: "972" },
  { Name: "Italy", CountryID: 107, Code: "IT", CallingCode: "39" },
  { Name: "Jamaica", CountryID: 108, Code: "JM", CallingCode: "1876" },
  { Name: "Japan", CountryID: 109, Code: "JP", CallingCode: "81" },
  { Name: "Jersey", CountryID: 110, Code: "JE", CallingCode: "44" },
  { Name: "Jordan", CountryID: 111, Code: "JO", CallingCode: "962" },
  { Name: "Kazakhstan", CountryID: 112, Code: "KZ", CallingCode: "7" },
  { Name: "Kenya", CountryID: 113, Code: "KE", CallingCode: "254" },
  { Name: "Kiribati", CountryID: 114, Code: "KI", CallingCode: "686" },
  { Name: "Korea, Democratic People Republic Of", CountryID: 115, Code: "KP", CallingCode: "850" },
  { Name: "Korea, Republic Of", CountryID: 116, Code: "KR", CallingCode: "82" },
  { Name: "Kuwait", CountryID: 117, Code: "KW", CallingCode: "965" },
  { Name: "Kyrgyzstan", CountryID: 118, Code: "KG", CallingCode: "996" },
  { Name: "Lao People Democratic Republic", CountryID: 119, Code: "LA", CallingCode: "856" },
  { Name: "Latvia", CountryID: 120, Code: "LV", CallingCode: "371" },
  { Name: "Lebanon", CountryID: 121, Code: "LB", CallingCode: "961" },
  { Name: "Lesotho", CountryID: 122, Code: "LS", CallingCode: "266" },
  { Name: "Liberia", CountryID: 123, Code: "LR", CallingCode: "231" },
  { Name: "Libyan Arab Jamahiriya", CountryID: 124, Code: "LY", CallingCode: "218" },
  { Name: "Liechtenstein", CountryID: 125, Code: "LI", CallingCode: "423" },
  { Name: "Lithuania", CountryID: 126, Code: "LT", CallingCode: "370" },
  { Name: "Luxembourg", CountryID: 127, Code: "LU", CallingCode: "352" },
  { Name: "Macao", CountryID: 128, Code: "MO", CallingCode: "853" },
  { Name: "Macedonia", CountryID: 129, Code: "MK", CallingCode: "389" },
  { Name: "Madagascar", CountryID: 130, Code: "MG", CallingCode: "261" },
  { Name: "Malawi", CountryID: 131, Code: "MW", CallingCode: "265" },
  { Name: "Malaysia", CountryID: 132, Code: "MY", CallingCode: "60" },
  { Name: "Maldives", CountryID: 133, Code: "MV", CallingCode: "960" },
  { Name: "Mali", CountryID: 134, Code: "ML", CallingCode: "223" },
  { Name: "Malta", CountryID: 135, Code: "MT", CallingCode: "356" },
  { Name: "Marshall Islands", CountryID: 136, Code: "MH", CallingCode: "692" },
  { Name: "Martinique", CountryID: 137, Code: "MQ", CallingCode: "596" },
  { Name: "Mauritania", CountryID: 138, Code: "MR", CallingCode: "222" },
  { Name: "Mauritius", CountryID: 139, Code: "MU", CallingCode: "230" },
  { Name: "Mayotte", CountryID: 140, Code: "YT", CallingCode: "269" },
  { Name: "Mexico", CountryID: 141, Code: "MX", CallingCode: "52" },
  { Name: "Micronesia, Federated States Of", CountryID: 142, Code: "FM", CallingCode: "961" },
  { Name: "Moldova, Republic Of", CountryID: 143, Code: "MD", CallingCode: "373" },
  { Name: "Monaco", CountryID: 144, Code: "MC", CallingCode: "377" },
  { Name: "Mongolia", CountryID: 145, Code: "MN", CallingCode: "976" },
  { Name: "Montenegro", CountryID: 146, Code: "ME", CallingCode: "382" },
  { Name: "Montserrat", CountryID: 147, Code: "MS", CallingCode: "1664" },
  { Name: "Morocco", CountryID: 148, Code: "MA", CallingCode: "212" },
  { Name: "Mozambique", CountryID: 149, Code: "MZ", CallingCode: "258" },
  { Name: "Myanmar", CountryID: 150, Code: "MM", CallingCode: "95" },
  { Name: "Namibia", CountryID: 151, Code: "NA", CallingCode: "264" },
  { Name: "Nauru", CountryID: 152, Code: "NR", CallingCode: "674" },
  { Name: "Nepal", CountryID: 153, Code: "NP", CallingCode: "977" },
  { Name: "Netherlands", CountryID: 154, Code: "NL", CallingCode: "31" },
  { Name: "Netherlands Antilles", CountryID: 155, Code: "NL", CallingCode: "31" },
  { Name: "New Caledonia", CountryID: 156, Code: "NC", CallingCode: "687" },
  { Name: "New Zealand", CountryID: 157, Code: "NZ", CallingCode: "64" },
  { Name: "Nicaragua", CountryID: 158, Code: "NI", CallingCode: "505" },
  { Name: "Niger", CountryID: 159, Code: "NE", CallingCode: "227" },
  { Name: "Nigeria", CountryID: 160, Code: "NG", CallingCode: "234" },
  { Name: "Niue", CountryID: 161, Code: "NU", CallingCode: "683" },
  { Name: "Norfolk Island", CountryID: 162, Code: "NF", CallingCode: "672" },
  { Name: "Northern Mariana Islands", CountryID: 163, Code: "MP", CallingCode: "1670" },
  { Name: "Norway", CountryID: 164, Code: "NO", CallingCode: "47" },
  { Name: "Oman", CountryID: 165, Code: "OM", CallingCode: "968" },
  { Name: "Pakistan", CountryID: 166, Code: "PK", CallingCode: "92" },
  { Name: "Palau", CountryID: 167, Code: "PW", CallingCode: "680" },
  { Name: "Palestinian Territory, Occupied", CountryID: 168, Code: "PS", CallingCode: "970" },
  { Name: "Panama", CountryID: 169, Code: "PA", CallingCode: "507" },
  { Name: "Papua New Guinea", CountryID: 170, Code: "PG", CallingCode: "675" },
  { Name: "Paraguay", CountryID: 171, Code: "PY", CallingCode: "595" },
  { Name: "Peru", CountryID: 172, Code: "PE", CallingCode: "51" },
  { Name: "Philippines", CountryID: 173, Code: "PH", CallingCode: "63" },
  { Name: "Pitcairn", CountryID: 174, Code: "PN", CallingCode: "64" },
  { Name: "Poland", CountryID: 175, Code: "PL", CallingCode: "48" },
  { Name: "Portugal", CountryID: 176, Code: "PT", CallingCode: "351" },
  { Name: "Puerto Rico", CountryID: 177, Code: "PR", CallingCode: "1787" },
  { Name: "Qatar", CountryID: 178, Code: "QA", CallingCode: "974" },
  { Name: "Reunion", CountryID: 179, Code: "RE", CallingCode: "262" },
  { Name: "Romania", CountryID: 180, Code: "RO", CallingCode: "40" },
  { Name: "Russian Federation", CountryID: 181, Code: "RU", CallingCode: "7" },
  { Name: "Rwanda", CountryID: 182, Code: "RW", CallingCode: "250" },
  { Name: "Saint Helena", CountryID: 183, Code: "SH", CallingCode: "290" },
  { Name: "Saint Kitts And Nevis", CountryID: 184, Code: "KN", CallingCode: "1869" },
  { Name: "Saint Lucia", CountryID: 185, Code: "LC", CallingCode: "1758" },
  { Name: "Saint Pierre And Miquelon", CountryID: 186, Code: "PM", CallingCode: "508" },
  { Name: "Saint Vincent And The Grenadines", CountryID: 187, Code: "VC", CallingCode: "1784" },
  { Name: "Samoa", CountryID: 188, Code: "WS", CallingCode: "685" },
  { Name: "San Marino", CountryID: 189, Code: "SM", CallingCode: "378" },
  { Name: "Sao Tome And Principe", CountryID: 190, Code: "ST", CallingCode: "239" },
  { Name: "Saudi Arabia", CountryID: 191, Code: "SA", CallingCode: "966" },
  { Name: "Senegal", CountryID: 192, Code: "SN", CallingCode: "221" },
  { Name: "Serbia", CountryID: 193, Code: "RS", CallingCode: "381" },
  { Name: "Seychelles", CountryID: 194, Code: "SC", CallingCode: "248" },
  { Name: "Sierra Leone", CountryID: 195, Code: "SL", CallingCode: "232" },
  { Name: "Singapore", CountryID: 196, Code: "SG", CallingCode: "65" },
  { Name: "Slovakia", CountryID: 197, Code: "SK", CallingCode: "421" },
  { Name: "Slovenia", CountryID: 198, Code: "SI", CallingCode: "386" },
  { Name: "Solomon Islands", CountryID: 199, Code: "SB", CallingCode: "677" },
  { Name: "Somalia", CountryID: 200, Code: "SO", CallingCode: "252" },
  { Name: "South Africa", CountryID: 201, Code: "ZA", CallingCode: "27" },
  { Name: "South Georgia And The South Sandwich Islands", CountryID: 202, Code: "GS", CallingCode: "500" },
  { Name: "Spain", CountryID: 203, Code: "ES", CallingCode: "34" },
  { Name: "Sri Lanka", CountryID: 204, Code: "LK", CallingCode: "94" },
  { Name: "Sudan", CountryID: 205, Code: "SD", CallingCode: "249" },
  { Name: "Suriname", CountryID: 206, Code: "SR", CallingCode: "597" },
  { Name: "Svalbard And Jan Mayen", CountryID: 207, Code: "SJ", CallingCode: "47" },
  { Name: "Swaziland", CountryID: 208, Code: "SZ", CallingCode: "268" },
  { Name: "Sweden", CountryID: 209, Code: "SE", CallingCode: "46" },
  { Name: "Switzerland", CountryID: 210, Code: "CH", CallingCode: "41" },
  { Name: "Syrian Arab Republic", CountryID: 211, Code: "SY", CallingCode: "963" },
  { Name: "Taiwan", CountryID: 212, Code: "TW", CallingCode: "886" },
  { Name: "Tajikistan", CountryID: 213, Code: "TJ", CallingCode: "992" },
  { Name: "Tanzania, United Republic Of", CountryID: 214, Code: "TZ", CallingCode: "255" },
  { Name: "Thailand", CountryID: 215, Code: "TH", CallingCode: "66" },
  { Name: "Timor-leste", CountryID: 216, Code: "TL", CallingCode: "670" },
  { Name: "Togo", CountryID: 217, Code: "TG", CallingCode: "228" },
  { Name: "Tokelau", CountryID: 218, Code: "TK", CallingCode: "690" },
  { Name: "Tonga", CountryID: 219, Code: "TO", CallingCode: "676" },
  { Name: "Trinidad And Tobago", CountryID: 220, Code: "TT", CallingCode: "1868" },
  { Name: "Tunisia", CountryID: 221, Code: "TN", CallingCode: "216" },
  { Name: "Turkey", CountryID: 222, Code: "TR", CallingCode: "90" },
  { Name: "Turkmenistan", CountryID: 223, Code: "TM", CallingCode: "993" },
  { Name: "Turks And Caicos Islands", CountryID: 224, Code: "TC", CallingCode: "1649" },
  { Name: "Tuvalu", CountryID: 225, Code: "TV", CallingCode: "688" },
  { Name: "Uganda", CountryID: 226, Code: "UG", CallingCode: "256" },
  { Name: "Ukraine", CountryID: 227, Code: "UA", CallingCode: "380" },
  { Name: "United Arab Emirates", CountryID: 228, Code: "AE", CallingCode: "971" },
  { Name: "United Kingdom", CountryID: 229, Code: "GB", CallingCode: "44" },
  { Name: "United States", CountryID: 230, Code: "US", CallingCode: "1" },
  { Name: "United States Minor Outlying Islands", CountryID: 231, Code: "UM", CallingCode: "1" },
  { Name: "Uruguay", CountryID: 232, Code: "UY", CallingCode: "598" },
  { Name: "Uzbekistan", CountryID: 233, Code: "UZ", CallingCode: "998" },
  { Name: "Vanuatu", CountryID: 234, Code: "VU", CallingCode: "678" },
  { Name: "Venezuela", CountryID: 235, Code: "VE", CallingCode: "58" },
  { Name: "Vietnam", CountryID: 236, Code: "VN", CallingCode: "84" },
  { Name: "Virgin Islands, British", CountryID: 237, Code: "VG", CallingCode: "1284" },
  { Name: "Virgin Islands, U.S.", CountryID: 238, Code: "VI", CallingCode: "1340" },
  { Name: "Wallis And Futuna", CountryID: 239, Code: "WF", CallingCode: "681" },
  { Name: "Western Sahara", CountryID: 240, Code: "EH", CallingCode: "212" },
  { Name: "Yemen", CountryID: 241, Code: "YE", CallingCode: "967" },
  { Name: "Zambia", CountryID: 242, Code: "ZM", CallingCode: "260" },
  { Name: "Zimbabwe", CountryID: 243, Code: "ZW", CallingCode: "263" },
  { Name: "Lland Islands", CountryID: 244, Code: "AX", CallingCode: "358" },
  { Name: "South Sudan", CountryID: 245, Code: "SS", CallingCode: "211" },
  { Name: "Kosovo", CountryID: 246, Code: "XK", CallingCode: "383" },
];
