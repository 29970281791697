import { getSettingActivity, getActivity } from "../../types/admin-settings.types";

const initState = {
  settingActivity: {
    list: [],
    loading: false,
  },
  getActivity: {
    loading: false,
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
};

export default function activitySettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getSettingActivity.GET_SETTING_ACTIVITY_REQUEST:
      return {
        ...state,
        settingActivity: { ...state.settingActivity, loading: true },
      };
    case getSettingActivity.GET_SETTING_ACTIVITY_SUCCESS:
      return {
        ...state,
        settingActivity: { loading: false, list: action.payload },
      };
    case getSettingActivity.GET_SETTING_ACTIVITY_ERROR:
      return {
        ...state,
        settingActivity: { ...state.settingActivity, loading: false },
        error: { err: true, errMsg: action.payload },
      };

    case getActivity.GET_ACTIVITY_REQUEST:
      return {
        ...state,
        getActivity: { ...state.getActivity, loading: true },
      };
    case getActivity.GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        getActivity: { loading: false, list: action.payload.data },
      };
    case getActivity.GET_ACTIVITY_ERROR:
      return {
        ...state,
        getActivity: { ...state.getActivity, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    default:
      return state;
  }
}
