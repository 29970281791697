import React, { useState, useEffect } from "react";
import FormInput from "../../../../components/Form-Field/index";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import countryCode from "../../../../_metronic/_assets/js/countryCode";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import validator from "validator";
import { getAccountBillingV2Request } from "../../Billing/store/actions/subscription_billing_v2_actions";
import { CurrencySelectForCompanySettings } from "../../../../components/custom_form/currency";
import * as ProductActions from "../../../products/store/actions/actions";
import * as CompanySettingsActions from "../../store/actions/admin-actions/company-settings.actions";
import _ from "lodash";

const customStyles = {
  control: () => ({
    background: "#F3F6F9",
    height: "45px",
    padding: "7px",
  }),
  indicatorsContainer: () => ({
    position: "absolute",
    top: "3px",
    right: 0,
    display: "flex",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
  placeholder: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
};

export default function AccountSettings() {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const [CompanyName, setCompanyName] = useState("");
  const [Country, setCountry] = useState("");
  const [WebSite, setWebSite] = useState("");
  const [AccountStatus, setAccountStatus] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState();

  const companyStore = useSelector((store) => store.settings.admin.company);

  useEffect(() => {
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanySettingsActions.getCompanySettingsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(companyStore.data)) {
      setCompanyName(companyStore.data.companyName);
      setCountry(companyStore.data.country);
      setWebSite(companyStore.data.website);
      setAccountStatus(companyStore.data.accountStatus);
      setSelectedCurrency(companyStore.data.currency);
    }
  }, [companyStore.data]);

  function handleChange(event) {
    if (event.target.name === "companyName") {
      setCompanyName(event.target.value);
    } else if (event.target.name === "website") {
      setWebSite(event.target.value);
    }
  }

  useEffect(() => {
    const arr = countryCode.country_code;
    arr.filter((obj) => {
      obj.label = obj.name;
      obj.value = obj.code;
      return null;
    });
    setOptions(arr);
  }, []);

  // dropdown for Country
  function dropChanges(selectedOption) {
    try {
      if (selectedOption !== null) setCountry(selectedOption.label);
      else setCountry("");
    } catch (e) {}
  }

  const editComp = () => {
    if (!CompanyName) {
      dispatchSnackbarError("Company name should not be blank", "error");
    } else if (!Country) {
      dispatchSnackbarError("Country name should not be blank", "error");
    } else if (!WebSite) {
      dispatchSnackbarError("Company website should not be blank", "error");
      setWebSite(WebSite);
    } else if (!selectedCurrency) {
      dispatchSnackbarError("Company currency should not be blank", "error");
    } else if (!validator.isURL(WebSite)) {
      dispatchSnackbarError("Please provide valid website url", "error");
    } else {
      const userDetails = {
        cName: CompanyName,
        website: WebSite,
        country: Country,
        currency: selectedCurrency,
      };
      dispatch(CompanySettingsActions.updateCompanySettingsRequest(userDetails));
    }
  };

  const AccountDeactivated = (name) => {
    if (name === "deactivate") {
      const ActiveDetails = { status: "DEACTIVATED" };
      dispatch(
        CompanySettingsActions.activeDeactiveCompanyStatusRequest(ActiveDetails, (data) => {
          if (data["status"]) {
            dispatchSnackbarSuccess("Account Successfully Deactivted", "success");
            dispatch(getAccountBillingV2Request(() => {}));
          }
        }),
      );
    } else {
      const ActiveDetails = { status: "ACTIVE" };
      dispatch(
        CompanySettingsActions.activeDeactiveCompanyStatusRequest(ActiveDetails, (data) => {
          if (data["status"]) {
            dispatchSnackbarSuccess("Account Successfully Activated", "success");
          }
        }),
      );
    }
  };

  const [show, setShow] = useState(false);
  const DeactivatePopUp = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {AccountStatus === "DEACTIVATED" ? (
        <></>
      ) : (
        <div className="h-100 overflow-y-auto">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Company</h3>
              </div>
            </div>
            <div id="kt_account_signin_method" className="collapse show">
              <div className="card-body border-top p-9 max-height-auto overflow-y-visible">
                <div className="d-flex flex-wrap align-items-center">
                  <div className="row mb-12 w-100">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Company Name</label>
                    <div className="col-lg-5 fv-row">
                      <FormInput placeholder={"Enter your company name"} type={"text"} name="companyName" value={CompanyName} onChange={handleChange} />
                    </div>
                  </div>

                  <br></br>

                  <div className="row mb-12 w-100">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Country Name</label>
                    <div className="col-lg-5 fv-row">
                      <Select
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        placeholder=""
                        value={options.filter((option) => option.label === Country)}
                        onChange={dropChanges}
                        options={options}
                        isClearable
                        noOptionsMessage={() => null}
                        isValidNewOption={() => false}
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="row mb-12 w-100">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Company Website</label>
                    <div className="col-lg-5 fv-row">
                      <FormInput placeholder={"Enter your company website"} type={"text"} name="website" value={WebSite} onChange={handleChange} />
                    </div>
                  </div>
                  <br></br>
                  <div className="row mb-12 w-100 overflow-visible">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Company Currency</label>
                    <div className="col-lg-5 fv-row">
                      <CurrencySelectForCompanySettings field={{ fieldName: "Currency" }} value={selectedCurrency} setValue={(value) => setSelectedCurrency(value)} />
                    </div>
                  </div>
                  <br></br>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={editComp}>
                  Save Changes
                </button>
              </div>
            </div>
          </div>
          <div className="card mb-5 mb-xl-10">
            <div className="bg-fff5f8 border-style-dotted border-color-f1416c notice d-flex warn rounded border-dashed p-6 mt-35px mx-35px mb-0">
              <span className="svg-icon svg-icon-2tx svg-icon-primary me-4 mt-4px">
                <i className="bi bi-exclamation-triangle font-size-38px text-red"></i>
              </span>

              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-bold">
                  <h4 className="text-gray-800 fw-bolder">Delete Your Account</h4>
                  <div className="fs-6 text-gray-600 pe-7">For Extra Security, This Require you to Confirm Once again Before Doing Anything</div>
                </div>

                <div className="form-check form-switch form-check-custom form-check-solid flex-direction-column">
                  {AccountStatus !== "DEACTIVATED" && (
                    <Button variant="danger" onClick={() => DeactivatePopUp()}>
                      {" "}
                      Deactivate
                    </Button>
                  )}
                  <label className="form-check-label ml-57px" htmlFor="favoriteSwitch"></label>
                </div>
              </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span className="font-size-16px">Your data will be lost and you won't be able to login in your account.</span>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" className="saveCommponbutton" onClick={(e) => AccountDeactivated("deactivate")}>
                  Deactivate Account
                </Button>
              </Modal.Footer>
            </Modal>
            <br></br>
            <br></br>
          </div>
        </div>
      )}
    </>
  );
}
