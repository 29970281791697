import React from "react";
import { Card } from "react-bootstrap";

export default function SalesTargetSummary(props) {
  const thead = ["Time", "Target", "Result", "Difference"];

  if (props.data.length === 0) return <></>;
  return (
    <Card className="sales-target-summary-wrapper">
      <Card.Header>Summary</Card.Header>
      <Card.Body>
        <table className="table align-middle table-row-dashed dataTable no-footer font-size-14px">
          <thead>
            <tr className="fw-bolder text-muted">
              {thead.map((each, index) => (
                <th className="cursor-pointer text-nowrap position-sticky top-0 pt-0 w-18 bg-white text-black" key={index}>
                  <div className="d-flex flex-row w-100 h-100 align-items-center gap-5px">{each}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((each, index) => {
              return (
                <tr key={index}>
                  <td className="text-nowrap">
                    <div className="d-flex flex-row flex-stack">{each.category}</div>
                  </td>
                  <td className="text-nowrap">
                    <div className="d-flex flex-row flex-stack">{each.target}</div>
                  </td>
                  <td className="text-nowrap">
                    <div className="d-flex flex-row flex-stack">{each.count}</div>
                  </td>
                  <td className="text-nowrap">
                    <div className="d-flex flex-row flex-stack">{each.count - each.target}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
}
