import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import TimeZoneSelect from "../../../../../components/custom_form/timezone";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import MaterialUiTimePicker from "../../../../../components/material-ui/time-picker";
import OnlyStepper from "../../../../../components/stepper/only-stepper";
import { InputLimit } from "../../../../../constants";
import { eventTimeHoursOptions, eventTimeMinutesOptions, eventTimeTypeOptions, futureEventTimeMonthsOptions, futureEventTimeTypeOptions, futuureEventDaysOptions, futuureEventWeekOptions } from "../constants";
import { useSelector } from "react-redux";

const days = [
  { day: "Sunday", value: "S" },
  { day: "Monday", value: "M" },
  { day: "Tuesday", value: "T" },
  { day: "Wednesday", value: "W" },
  { day: "Thusday", value: "R" },
  { day: "Friday", value: "F" },
  { day: "Saturday", value: "U" },
];

export default function SchedulingPageAddForm(props) {
  // first 3 questions
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [eventTimeType, setEventTimeType] = useState({ value: "minutes", label: "Minutes" });
  const [eventTime, setEventTime] = useState({ value: 60, label: "60" });

  // second 3 questions
  const [weekDays, setWeekDays] = useState(["M", "T", "W", "R", "F"]);
  const [from, setFrom] = useState("09:30");
  const [to, setTo] = useState("18:30");
  const [timezone, setTimezone] = useState("");
  const [futureEventTimeType, setFutureEventTimeType] = useState({ value: "days", label: "Days" });
  const [futureEventTime, setFutureEventTime] = useState({ value: 15, label: "15" });
  const [slug, setSlug] = useState(props.createData.slug);

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  useEffect(() => {
    if (!_.isEmpty(userProfile.data)) {
      setTimezone(userProfile.data.timezone);
    }
  }, [userProfile.data]);

  const handleWeekDaySelection = (checked, value) => {
    if (checked) {
      const a = [...weekDays, value];
      setWeekDays(a);
      props.setCreateData({ ...props.createData, weekDays: a });
    } else {
      const a = weekDays.filter((a) => a !== value);
      setWeekDays(a);
      props.setCreateData({ ...props.createData, weekDays: a });
    }
  };

  const handleEventTypeChange = (data) => {
    if (data.value === "hours") {
      setEventTimeType({ value: "hours", label: "Hours" });
      setEventTime({ value: 1, label: "1" });
      props.setCreateData({ ...props.createData, duration: 60, slug: `${props.suggestedSlug}-60-minutes` });
      setSlug(`${props.suggestedSlug}-60-minutes`);
    } else {
      setEventTimeType({ value: "minutes", label: "Minutes" });
      setEventTime({ value: 60, label: "60" });
      props.setCreateData({ ...props.createData, duration: 60, slug: `${props.suggestedSlug}-60-minutes` });
      setSlug(`${props.suggestedSlug}-60-minutes`);
    }
  };

  const handleFutureEventTypeChange = (data) => {
    if (data.value === "days") {
      setFutureEventTimeType({ value: "days", label: "Days" });
      setFutureEventTime({ value: 30, label: "30" });
      props.setCreateData({ ...props.createData, futureTime: 30 });
    } else if (data.value === "weeks") {
      setFutureEventTimeType({ value: "weeks", label: "Weeks" });
      setFutureEventTime({ value: 1, label: "1" });
      props.setCreateData({ ...props.createData, futureTime: 7 });
    } else {
      setFutureEventTimeType({ value: "months", label: "Months" });
      setFutureEventTime({ value: 1, label: "1" });
      props.setCreateData({ ...props.createData, futureTime: 30 });
    }
  };

  const handleNameChange = (value) => {
    setName(value);
    props.setCreateData({ ...props.createData, name: value });
  };
  const handleLocationChange = (value) => {
    setLocation(value);
    props.setCreateData({ ...props.createData, location: value });
  };

  return (
    <Form>
      <Form.Group>
        <OnlyStepper steps={["Event Info", "Availability"]} activeStep={props.activeStep} />
      </Form.Group>
      {props.activeStep === 1 && (
        <Form.Group className="card p-4">
          <Form.Group>
            <Form.Label>Event Name</Form.Label>
            <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={name} onChange={(e) => handleNameChange(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Event Location (You can add video meeting URL here eg. google meet)</Form.Label>
            <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={location} onChange={(e) => handleLocationChange(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Event Duration</Form.Label>
            <Row>
              <Col>
                <SimpleDropdown
                  value={eventTime}
                  label={"Select"}
                  options={eventTimeType.value === "hours" ? eventTimeHoursOptions : eventTimeMinutesOptions}
                  handleSelect={(value) => {
                    setEventTime(value);
                    props.setCreateData({ ...props.createData, duration: value.duration, slug: `${props.suggestedSlug}-${value.value}-${eventTimeType.value}` });
                    setSlug(`${props.suggestedSlug}-${value.value}-${eventTimeType.value}`);
                  }}
                />
              </Col>
              <Col>
                <SimpleDropdown value={eventTimeType} label={"Select"} options={eventTimeTypeOptions} handleSelect={handleEventTypeChange} />
              </Col>
            </Row>
          </Form.Group>
        </Form.Group>
      )}
      {props.activeStep === 2 && (
        <Form.Group className="card p-4">
          <Form.Group>
            <Form.Label>When can events be booked?</Form.Label>
            <div>
              {days.map((item) => (
                <Form.Check
                  inline
                  label={item.day}
                  id={item.day}
                  onChange={(e) => handleWeekDaySelection(e.target.checked, item.value)}
                  type="checkbox"
                  key={item.day}
                  size="sm"
                  className="mt-12px mr-12px"
                  name={item.day}
                  checked={weekDays.includes(item.value)}
                />
              ))}
            </div>
            <Form.Group className="mb-0 mt-4">
              <Form.Group className="d-flex gap-4 align-items-center mb-0">
                <Form.Group className="mb-0 w-32">
                  <MaterialUiTimePicker
                    label="From Time"
                    value={from}
                    setValue={(value) => {
                      setFrom(value);
                      props.setCreateData({ ...props.createData, from: value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-0 w-32">
                  <MaterialUiTimePicker
                    label="To Time"
                    value={to}
                    setValue={(value) => {
                      setTo(value);
                      props.setCreateData({ ...props.createData, to: value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-0 w-32">
                  <Form.Label aria-label="timezone">Timezone</Form.Label>
                  <TimeZoneSelect
                    clearable={false}
                    field={{ apiKeyName: "timezone", fieldName: "Timezone" }}
                    value={timezone}
                    setValue={(value) => {
                      setTimezone(value);
                      props.setCreateData({ ...props.createData, timezone: value });
                    }}
                  />
                </Form.Group>
              </Form.Group>
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>How far in the future can events be booked?</Form.Label>
            <Row>
              <Col>
                <SimpleDropdown
                  value={futureEventTime}
                  label={"Select"}
                  options={futureEventTimeType.value === "days" ? futuureEventDaysOptions : futureEventTimeType.value === "weeks" ? futuureEventWeekOptions : futureEventTimeMonthsOptions}
                  handleSelect={(value) => {
                    setFutureEventTime(value);
                    props.setCreateData({ ...props.createData, futureTime: value.duration / 60 / 24 });
                  }}
                />
              </Col>
              <Col>
                <SimpleDropdown value={futureEventTimeType} label={"Select"} options={futureEventTimeTypeOptions} handleSelect={handleFutureEventTypeChange} />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Customize your scheduler link</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">https://meeting.salessimplify.com/</InputGroup.Text>
              <Form.Control
                type="text"
                value={slug}
                placeholder=""
                onChange={(e) => {
                  setSlug(e.target.value);
                  props.setCreateData({ ...props.createData, slug: e.target.value });
                }}
              />
            </InputGroup>
          </Form.Group>
        </Form.Group>
      )}
    </Form>
  );
}
