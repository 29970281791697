import {
  COMPANIES_REQUEST,
  COMPANIES_SUCCESS,
  COMPANIES_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  COMPANIESDROPDOWN_REQUEST,
  COMPANIESDROPDOWN_SUCCESS,
  COMPANIESDROPDOWN_ERROR,
  COMPANYFIELDS_REQUEST,
  COMPANYFIELDS_SUCCESS,
  COMPANYFIELDS_ERROR,
  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_ERROR,
  COMPANYADDFIELDS_REQUEST,
  COMPANYADDFIELDS_SUCCESS,
  COMPANYADDFIELDS_ERROR,
  COMPANYTAGS_REQUEST,
  COMPANYTAGS_SUCCESS,
  COMPANYTAGS_ERROR,
  COMPANYTAGSADD_REQUEST,
  COMPANYTAGSADD_SUCCESS,
  COMPANYTAGSADD_ERROR,
  COMPANYTAGSEDIT_REQUEST,
  COMPANYTAGSEDIT_SUCCESS,
  COMPANYTAGSEDIT_ERROR,
  COMPANYTAGSDELETE_REQUEST,
  COMPANYTAGSDELETE_SUCCESS,
  COMPANYTAGSDELETE_ERROR,
  COMPANYCELLEDIT_REQUEST,
  COMPANYCELLEDIT_SUCCESS,
  COMPANYCELLEDIT_ERROR,
  COMPANYADD_REQUEST,
  COMPANYADD_SUCCESS,
  COMPANYADD_ERROR,
  COMPANYBULKEDIT_REQUEST,
  COMPANYBULKEDIT_SUCCESS,
  COMPANYBULKEDIT_ERROR,
  COMPANYDELETE_REQUEST,
  COMPANYDELETE_SUCCESS,
  COMPANYDELETE_ERROR,
  COMPANYFILTERS_REQUEST,
  COMPANYFILTERS_SUCCESS,
  COMPANYFILTERS_ERROR,
  COMPANYFILTERADD_REQUEST,
  COMPANYFILTERADD_SUCCESS,
  COMPANYFILTERADD_ERROR,
  COMPANYFILTEREDIT_REQUEST,
  COMPANYFILTEREDIT_SUCCESS,
  COMPANYFILTEREDIT_ERROR,
  COMPANYFILTERFAVORITE_REQUEST,
  COMPANYFILTERFAVORITE_SUCCESS,
  COMPANYFILTERFAVORITE_ERROR,
  COMPANYFILTERDELETE_REQUEST,
  COMPANYFILTERDELETE_SUCCESS,
  COMPANYFILTERDELETE_ERROR,
  COMPANIESDROPDOWNADD_REQUEST,
  COMPANIESDROPDOWNADD_SUCCESS,
  COMPANIESDROPDOWNADD_ERROR,
  COMPANIES_UPDATE_REQUEST,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_UPDATE_ERROR,
  COMPANY_POPUP_REQUEST,
  COMPANY_POPUP_REQUEST_AFTER,
  COMPANY_POPUP_SUCCESS,
  COMPANY_POPUP_ERROR,
  COMPANY_POPUP_UPDATE_REQUEST,
  COMPANY_POPUP_UPDATE_SUCCESS,
  COMPANY_POPUP_UPDATE_ERROR,
  COMPANY_POPUP_EDIT_REQUEST,
  COMPANY_POPUP_EDIT_SUCCESS,
  COMPANY_POPUP_EDIT_ERROR,
  LOADING_DATA_UPDATE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  TIMEZONES_REQUEST,
  TIMEZONES_SUCCESS,
  TIMEZONES_ERROR,
  COMPANIESMERGE_REQUEST,
  COMPANIESMERGE_SUCCESS,
  COMPANIESMERGE_ERROR,
  COMPANIESDROPDOWNADD_CLEAR,
  APPLYFILTER,
  CLEARFILTER,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_SUCCESS,
  LOADMORELINKEDDATA_FORSELECTEDCOMPANY_ERROR,
  SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST,
  SEARCH_UNLINKEDDATA_FORCOMPANY_SUCCESS,
  SEARCH_UNLINKEDDATA_FORCOMPANY_ERROR,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_SUCCESS,
  LINK_LEADDEALPEOPLE_WITHCOMPANY_ERROR,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_SUCCESS,
  UNLINK_LEADDEALPEOPLE_FROMCOMPANY_ERROR,
  generateCompanyAIWFData
} from "../types/types";
import { bulkDeleteCompanyV2, bulkEditCompanyV2 } from "../types/company-operation.types";

const initialState = {
  companyList: {
    loading: "",
    list: [],
    count: 0,
    cellLoading: false,
  },
  companyPopup: {
    loading: "",
    data: {},
    cellLoading: false,
  },
  companyDropdownList: {
    loading: "",
    list: [],
  },
  companyFields: {
    loading: "",
    list: [],
    idToIndex: {},
  },
  companyAddFields: {
    loading: "",
    list: [],
  },
  companyTags: {
    loading: "",
    list: [],
  },
  companyFilters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  error: {
    err: false,
    errMsg: "",
  },
  success: {
    success: false,
    successMsg: "",
  },
  loadingData: {
    pageNo: 1,
    limit: 50,
  },
  countries: {
    loading: "",
    list: [],
  },
  timezones: {
    loading: "",
    list: [],
  },
  newCompany: null,
  loadMoreData: {
    loading: false,
    data: [],
  },
  searchLinkedDataList: {
    loading: "",
    list: [],
  },
  // you can define required variables here
};

export function companiesReducer(state = initialState, action = {}) {
  switch (action.type) {
    //unlink deal lead people from company
    case UNLINK_LEADDEALPEOPLE_FROMCOMPANY_REQUEST:
      return {
        ...state,
      };
    case UNLINK_LEADDEALPEOPLE_FROMCOMPANY_SUCCESS:
      return {
        ...state,
      };
    case UNLINK_LEADDEALPEOPLE_FROMCOMPANY_ERROR:
      return {
        ...state,
      };
    //link deal lead people with company
    case LINK_LEADDEALPEOPLE_WITHCOMPANY_REQUEST:
      return {
        ...state,
      };
    case LINK_LEADDEALPEOPLE_WITHCOMPANY_SUCCESS:
      return {
        ...state,
      };
    case LINK_LEADDEALPEOPLE_WITHCOMPANY_ERROR:
      return {
        ...state,
      };
    //search unliked data for comapny sidepanel
    case SEARCH_UNLINKEDDATA_FORCOMPANY_REQUEST:
      return {
        ...state,
        searchLinkedDataList: { ...state.searchLinkedDataList, loading: true },
      };
    case SEARCH_UNLINKEDDATA_FORCOMPANY_SUCCESS:
      return {
        ...state,
        searchLinkedDataList: { loading: false, list: action.payload.data },
      };
    case SEARCH_UNLINKEDDATA_FORCOMPANY_ERROR:
      return {
        ...state,
      };
    //load more linked lists
    case LOADMORELINKEDDATA_FORSELECTEDCOMPANY_REQUEST:
      return {
        ...state,
        loadMoreData: { loading: false, data: [] },
      };
    case LOADMORELINKEDDATA_FORSELECTEDCOMPANY_SUCCESS:
      return {
        ...state,
        loadMoreData: { loading: true, data: action.payload },
      };
    case LOADMORELINKEDDATA_FORSELECTEDCOMPANY_ERROR:
      return {
        ...state,
        // loadMoreData: { loading: true, data:   }
      };
    case COMPANIES_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, loading: true },
      };
    case COMPANIES_SUCCESS:
      return {
        ...state,
        companyList: { loading: false, list: action.payload.data, count: action.payload.count },
      };
    case COMPANIES_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    // Getting Company Popup Data
    case COMPANY_POPUP_REQUEST:
      return {
        ...state,
        companyPopup: { loading: true, data: {} },
      };
    case COMPANY_POPUP_REQUEST_AFTER:
      return {
        ...state,
        // companyPopup:{loading: false}
      };
    case COMPANY_POPUP_SUCCESS:
      return {
        ...state,
        companyPopup: { loading: false, data: action.payload },
      };
    case COMPANY_POPUP_ERROR:
      return {
        ...state,
        companyPopup: { loading: false, data: {} },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Company Popup Cell
    case COMPANY_POPUP_EDIT_REQUEST:
      return {
        ...state,
        companyPopup: { ...state.companyPopup, loading: false, cellLoading: action.payload.cellId },
      };
    case COMPANY_POPUP_EDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANY_POPUP_EDIT_ERROR:
      return {
        ...state,
        companyPopup: { ...state.companyPopup, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Updating Company Popup Data
    case COMPANY_POPUP_UPDATE_REQUEST:
      return {
        ...state,
        companyPopup: { ...state.companyPopup, cellLoading: action.payload.cellId },
      };
    case COMPANY_POPUP_UPDATE_SUCCESS:
      return {
        ...state,
        companyPopup: { loading: false, data: action.payload.data, cellLoading: false },
      };
    case COMPANY_POPUP_UPDATE_ERROR:
      return {
        ...state,
        companyPopup: { ...state.companyPopup, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case COMPANIES_UPDATE_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, cellLoading: action.payload.cellId },
      };
    case COMPANIES_UPDATE_SUCCESS:
      return {
        ...state,
        companyList: { loading: false, list: action.payload.data, count: action.payload.count, cellLoading: false },
      };
    case COMPANIES_UPDATE_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, cellLoading: false, count: 0, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    //Update loading data
    case LOADING_DATA_UPDATE:
      return {
        ...state,
        loadingData: action.payload,
      };

    // Clear error or success
    case CLEAR_ERROR:
      return {
        ...state,
        error: { err: false, errMsg: "" },
      };

    case CLEAR_SUCCESS:
      return {
        ...state,
        success: { success: false, successMsg: "" },
      };

    case COMPANIESDROPDOWN_REQUEST:
      return {
        ...state,
        companyDropdownList: { ...state.companyDropdownList, loading: true },
      };
    case COMPANIESDROPDOWN_SUCCESS:
      return {
        ...state,
        companyDropdownList: { loading: false, list: action.payload.data },
      };
    case COMPANIESDROPDOWN_ERROR:
      return {
        ...state,
        companyDropdownList: { ...state.companyDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Company in dropdown
    case COMPANIESDROPDOWNADD_REQUEST:
      return {
        ...state,
        companyDropdownList: { ...state.companyDropdownList, loading: true },
      };
    case COMPANIESDROPDOWNADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
        newCompany: { id: action.payload.id, name: action.payload.name },
      };
    case COMPANIESDROPDOWNADD_ERROR:
      return {
        ...state,
        companyDropdownList: { ...state.companyDropdownList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case COMPANIESDROPDOWNADD_CLEAR:
      return {
        ...state,
        companyDropdownList: { ...state.companyDropdownList, loading: false },
        newCompany: null,
      };

    // Fields
    case COMPANYFIELDS_REQUEST:
      return {
        ...state,
        companyFields: { ...state.companyFields, loading: action.payload.skipIfDataPresent ? false : true },
      };
    case COMPANYFIELDS_SUCCESS:
      var tempObj = {};
      for (var i = 0; i < action.payload.length; i++) {
        tempObj[action.payload[i]["_id"]] = i;
      }
      return {
        ...state,
        companyFields: { loading: false, list: action.payload, idToIndex: tempObj },
      };
    case COMPANYFIELDS_ERROR:
      return {
        ...state,
        companyFields: { ...state.companyFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Update fields
    case FIELD_UPDATE_REQUEST:
      return {
        ...state,
      };
    case FIELD_UPDATE_SUCCESS:
      var tempUpdate = {};
      for (var j = 0; j < action.payload.length; j++) {
        tempUpdate[action.payload[j]["_id"]] = j;
      }
      return {
        ...state,
        productFields: { list: action.payload, idToIndex: tempUpdate },
      };
    case FIELD_UPDATE_ERROR:
      return {
        ...state,
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Fields
    case COMPANYADDFIELDS_REQUEST:
      return {
        ...state,
        companyAddFields: { ...state.companyAddFields, loading: true },
      };
    case COMPANYADDFIELDS_SUCCESS:
      return {
        ...state,
        companyAddFields: { loading: false, list: action.payload },
      };
    case COMPANYADDFIELDS_ERROR:
      return {
        ...state,
        companyAddFields: { ...state.companyAddFields, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Company
    case COMPANYADD_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, loading: true },
      };
    case COMPANYADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYADD_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Bulk Edit Company
    case COMPANYBULKEDIT_REQUEST:
    case bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, loading: true },
      };
    case COMPANYBULKEDIT_SUCCESS:
    case bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYBULKEDIT_ERROR:
    case bulkEditCompanyV2.BULK_EDIT_COMPANY_V2_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Company
    case COMPANYDELETE_REQUEST:
    case bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, loading: true },
      };
    case COMPANYDELETE_SUCCESS:
    case bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYDELETE_ERROR:
    case bulkDeleteCompanyV2.BULK_DELETE_COMPANY_V2_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // GET Tags
    case COMPANYTAGS_REQUEST:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: true },
      };
    case COMPANYTAGS_SUCCESS:
      return {
        ...state,
        companyTags: { loading: false, list: action.payload },
      };
    case COMPANYTAGS_ERROR:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Add Tag
    case COMPANYTAGSADD_REQUEST:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: true },
      };
    case COMPANYTAGSADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYTAGSADD_ERROR:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Tag
    case COMPANYTAGSEDIT_REQUEST:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: true },
      };
    case COMPANYTAGSEDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYTAGSEDIT_ERROR:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Delete Tag
    case COMPANYTAGSDELETE_REQUEST:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: true },
      };
    case COMPANYTAGSDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYTAGSDELETE_ERROR:
      return {
        ...state,
        companyTags: { ...state.companyTags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    // Edit Cell
    case COMPANYCELLEDIT_REQUEST:
    case generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false, cellLoading: action.payload.cellId },
        companyPopup: { ...state.companyPopup, cellLoading: action.payload.cellId },
      };
    case COMPANYCELLEDIT_SUCCESS:
    case generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
        companyPopup: { ...state.companyPopup, cellLoading: false },
      };
    case COMPANYCELLEDIT_ERROR:
    case generateCompanyAIWFData.GENERATE_COMPANY_AIWF_DATA_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false, cellLoading: false },
        error: { err: true, errMsg: action.payload.message },
        companyPopup: { ...state.companyPopup, cellLoading: false },

      };

    //Getting Filters
    case COMPANYFILTERS_REQUEST:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: true },
      };
    case COMPANYFILTERS_SUCCESS:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: false, list: action.payload.data },
      };
    case COMPANYFILTERS_ERROR:
      return {
        ...state,
        companyFilters: { ...state.companyList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Adding Filter
    case COMPANYFILTERADD_REQUEST:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: true },
      };
    case COMPANYFILTERADD_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYFILTERADD_ERROR:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Editing Filter
    case COMPANYFILTEREDIT_REQUEST:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: true },
      };
    case COMPANYFILTEREDIT_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYFILTEREDIT_ERROR:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Favoriting Filter
    case COMPANYFILTERFAVORITE_REQUEST:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: true },
      };
    case COMPANYFILTERFAVORITE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYFILTERFAVORITE_ERROR:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Deleting Filter
    case COMPANYFILTERDELETE_REQUEST:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: true },
      };
    case COMPANYFILTERDELETE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANYFILTERDELETE_ERROR:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Country Data
    case COUNTRIES_REQUEST:
      return {
        ...state,
        countries: { ...state.countries, loading: true },
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: { loading: false, list: action.payload.data },
      };
    case COUNTRIES_ERROR:
      return {
        ...state,
        countries: { loading: false, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    //Timezone Data
    case TIMEZONES_REQUEST:
      return {
        ...state,
        timezones: { ...state.timezones, loading: true },
      };
    case TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: { loading: false, list: action.payload.data },
      };
    case TIMEZONES_ERROR:
      return {
        ...state,
        timezones: { loading: false, list: [] },
        error: { err: true, errMsg: action.payload.message },
      };

    // Merge
    case COMPANIESMERGE_REQUEST:
      return {
        ...state,
        companyList: { ...state.companyList, loading: true },
      };
    case COMPANIESMERGE_SUCCESS:
      return {
        ...state,
        success: { success: true, successMsg: action.payload.message },
      };
    case COMPANIESMERGE_ERROR:
      return {
        ...state,
        companyList: { ...state.companyList, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    case APPLYFILTER:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, activeFilter: action.payload },
      };
    case CLEARFILTER:
      return {
        ...state,
        companyFilters: { ...state.companyFilters, activeFilter: null },
      };
    default:
      return state;
  }
}
