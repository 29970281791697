import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// import SchedullingPage from "./index";
import SchedulingCalendarList from "./containers/scheduling-calendar-list";
import SchedullingPageList from "./containers/scheduling-page-list";

export default function CadenceRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={SchedulingCalendarList} />
      <Route exact path={`${match.url}/:calendarSyncId`} component={SchedullingPageList} />
      <Route exact path={`${match.url}/:calendarSyncId/:schdulingId`} component={SchedullingPageList} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
}
