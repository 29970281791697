import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryCode from "../../../../_metronic/_assets/js/countryCode";
import {
  // BrowserRouter as Router,
  // Link,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../../utils/toaster";
import * as actions from "../actions";
import { getGeoLocation } from "./utils/geoLocation";
import { InputLimit } from "../../../../constants";
import InlineStyled from "../../../../components/inline-styled/inline-styled";
const { detect } = require("detect-browser");
const browser = detect();

export default function GoogleSIgnUp(props) {
  // const { userIP, city, region } = useGeoLocation();
  const dispatch = useDispatch();

  const search = useLocation().search;
  const codeParams = new URLSearchParams(search).get("code");
  const [CurrentStep, setCurrentStep] = useState();
  const [companyName, setCompanyName] = useState("");
  const [FullName, setFullName] = useState("");
  const [FullEmail, setFullEmail] = useState("");
  const [SizeCompany, setSizeCompany] = useState("");
  const [typeOfComp, settypeOfComp] = useState("");
  const [experience, setexperience] = useState("");

  // Props for component
  // let { values, next, handleChange } = props

  // custom style for Country Select
  const customStyles = {
    control: () => ({
      background: "#F3F6F9",
      height: "45px",
      padding: "7px",
    }),
    indicatorsContainer: () => ({
      position: "absolute",
      top: "3px",
      right: 0,
      display: "flex",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
    placeholder: () => ({
      color: "#5e6278",
      fontSize: "15px",
      fontWeight: 400,
    }),
  };

  // state for Country Code

  const [phoneCode, setPhoneCode] = useState("+91");

  /************************************************/

  const geoLoc = useRef();
  useEffect(() => {
    (async () => {
      const data = await getGeoLocation();
      geoLoc.current = data;
    })();
  }, []);

  // event on handleOnPhoneChange

  function handleOnPhoneChange(value) {
    setPhoneCode(value);

    // localStorage.setItem("phone", value)
  }

  /*************************************************/

  /************************************************/

  // state for country Select
  const [options, setOptions] = useState([]);
  const [selectOption, setSelectOption] = useState("");

  /**************************************************/

  useEffect(() => {
    let arr = countryCode.country_code;
    arr.filter((obj) => {
      obj.label = obj.name;
      obj.value = obj.code;
      return null;
    });
    setOptions(arr);
  }, [options]);

  // useEffect for country Select
  useEffect(() => {
    dispatch(
      actions.PostGoogleAPIRequest({ code: codeParams, page: "signup" }, (data) => {
        setFullName(data.name);
        setFullEmail(data.email);

        dispatch(
          actions.CheckEmailRequest({ email: data.email }, (data) => {
            if (data["emailTaken"]) {
              dispatchSnackbarError(data["message"], "error");
              window.location.href = "/auth/registration";
            } else {
              setCurrentStep(false);
            }
          }),
        );
      }),
    );
  }, [codeParams, dispatch]);

  /***options, codeParams,dispatch***********************************************/

  function dropChanges(selectedOption) {
    try {
      setSelectOption(selectedOption.label);
      setPhoneCode(`+${selectedOption.code}`);

      // setcountryName(selectedOption.label)
      // localStorage.setItem("country", selectedOption.label)
    } catch (e) {}
  }

  const handlechange = (e, nameClass) => {
    setSizeCompany(e.target.defaultValue);
    if (nameClass === "0") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.add("active");
      b.classList.remove("active");
      c.classList.remove("active");
      d.classList.remove("active");
    } else if (nameClass === "1") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.remove("active");
      b.classList.add("active");
      c.classList.remove("active");
      d.classList.remove("active");
    } else if (nameClass === "2") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.remove("active");
      b.classList.remove("active");
      c.classList.add("active");
      d.classList.remove("active");
    } else if (nameClass === "3") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.remove("active");
      b.classList.remove("active");
      c.classList.remove("active");
      d.classList.add("active");
    }
    // localStorage.setItem("size", e)
  };

  const handleCompanyChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleChangeCompanyType = (e) => {
    settypeOfComp(e.target.value);
    // localStorage.setItem("typeOfComp", e.target.value)
  };

  const handleChangeExperience = (e) => {
    setexperience(e.target.value);
    // localStorage.setItem("experience", e.target.value)
  };

  const nextStep = () => {
    if (companyName === "" || companyName === null) {
      dispatchSnackbarError("Please Enter Company Name", "success");
    } else if (selectOption === "" || selectOption === null || companyName === undefined) {
      dispatchSnackbarError("Please Select Country Name", "success");
    } else {
      let phoneObj = {
        fullName: FullName,
        email: FullEmail,
        // "password": password,
        // "subDomain": domain,
        cName: companyName,
        country: selectOption,
        phone: phoneCode,
        location: `${geoLoc.current.city} ${geoLoc.current.region}`,
        device: browser.os,
        platform: browser.name,
        IPAddr: geoLoc.current.ip,
        isGoogleAttempt: true,
        rememberLogin: true,
        loginMethod: "PASSWORD",
      };

      dispatch(
        actions.PatchPhoneRequest(phoneObj, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Account Successfully Created", "success");
            localStorage.setItem("access_token", data["token"]);
            // setStep(step+1)
            setCurrentStep(true);
          }
        }),
      );
    }
  };

  // const BackStep = () => {
  //   setCurrentStep(false)
  // }

  const SubmitDetails = () => {
    if (SizeCompany === "" || SizeCompany === null) {
      dispatchSnackbarError("Please select Company size", "error");
    } else if (typeOfComp === "" || typeOfComp === null) {
      dispatchSnackbarError("Please select type of company", "error");
    } else if (experience === "" || experience === null) {
      dispatchSnackbarError("Please select Your Experience", "error");
    } else {
      let userDetails = {
        // "subDomain": domain,
        cName: companyName,
        country: selectOption,
        size: SizeCompany,
        typeOfComp: typeOfComp,
        experience: experience,
      };

      dispatch(
        actions.UpdateAccountRequest(userDetails, "", (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Account Successfully Updated", "success");
            window.location.href = "/activity/activity-list";
          }
        }),
      );
    }
  };
  const CloseButton = () => {
    // props.setIsAuthorized(true);
    window.location.href = "/activity/activity-list";
  };
  /***************************************************/

  return (
    <>
      {/* {props.value} */}
      <InlineStyled
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      >
        {/*begin::Content*/}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/*begin::Logo*/}
          <a href="index.html" className="mb-12">
            <img alt="Logo" src={require("../../../../images/logo/rectangle-logo/SVG/logo-rectangle.svg")} className="h-90px" />
          </a>
        </div>

        <div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto mt-minus-62px">
          {CurrentStep === false && (
            <div className="login-form login-signin" id="kt_login_signin_form">
              <div className="text-center mb-10">
                {/*begin::Title*/}
                <h1 className="text-dark mb-3 fw-600">You're almost there!</h1>
                {/*end::Title*/}
                {/*begin::Sub-title*/}
                <div className="text-muted fw-bold fs-5 mb-5">Fill out a few details and you'll be on your way.</div>
                {/*end::Sub-title*/}
                {/*begin::Mobile no*/}
                {/* <div className="fw-bolder text-dark fs-3">On Your Email</div> */}
                {/*end::Mobile no*/}
              </div>

              <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">Company Name</label>
                <input
                  maxLength={InputLimit.TEXT}
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  name="companyName"
                  // value={values.Company}
                  onChange={(e) => {
                    handleCompanyChange(e);
                  }}
                  autoComplete="off"
                />
              </div>

              {/* <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Domain Name</label>
        <div className="input-group mb-3">
          <span className="bg-ccf3ff input-group-text" id="basic-addon1 fw-500">salessimplify.com</span>
          <input maxLength={InputLimit.TEXT} className="form-control form-control-lg form-control-solid" type="text" name="domainName"
            onChange={handleChange} autoComplete="off" />
        </div>
      </div> */}

              <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">Country Name</label>
                <Select
                  styles={customStyles}
                  placeholder=""
                  // value={options[7]}
                  onChange={dropChanges}
                  options={options}
                  isClearable
                />
              </div>

              <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">Phone Number</label>
                {/* <input maxLength={InputLimit.NUMBER} className="form-control form-control-lg form-control-solid" type="number" name="phoneNumber"
            value={values.phoneNo} onChange={handleChange} autoComplete="off" /> */}
                <ReactPhoneInput
                  // defaultCountry={"in"}
                  value={phoneCode}
                  onChange={handleOnPhoneChange}
                  inputClass="w-100"
                  inputStyle={{
                    height: "48px",
                    fontSize: "13px",
                    paddingLeft: "48px",
                    borderRadius: "5px",
                    backgroundColor: "#F3F6F9",
                    borderColor: "#F3F6F9",
                  }}
                />
              </div>

              {/*end::Input group*/}

              {/*begin::Input group*/}

              {/*end::Input group*/}
              {/*begin::Actions*/}
              <div className="text-center">
                <button type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary" onClick={nextStep}>
                  <span className="indicator-label">Next</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </button>
              </div>
              {/*end::Actions*/}
            </div>
          )}

          {CurrentStep === true && (
            <div data-kt-stepper-element="content">
              {/*begin::Wrapper*/}
              <div className="w-100">
                {/*begin::Heading*/}
                <div className="text-center mb-10">
                  {/*begin::Title*/}
                  <h1 className="text-dark mb-3 fw-600">Welcome Onboard!</h1>
                  {/*end::Title*/}
                  {/*begin::Sub-title*/}
                  {/* <div className="text-muted fw-bold fs-5 mb-5">We'll customize the CRM that fits your needs.</div> */}
                  {/*end::Sub-title*/}
                  {/*begin::Mobile no*/}
                  {/* <div className="fw-bolder text-dark fs-3">On Your Email</div> */}
                  {/*end::Mobile no*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Input group*/}
                <div className="mb-10 fv-row">
                  {/*begin::Label*/}
                  <label className="d-flex align-items-center form-label mb-3">How many people in the company will use Sales Simplify?</label>
                  {/*end::Label*/}
                  {/*begin::Row*/}
                  <div className="row mb-2" data-kt-buttons="true">
                    {/*begin::Col*/}
                    <div className="col">
                      {/*begin::Option*/}
                      {/* <Button variant="primary" onClick={()=>handlechange('1-1')}>1-1</Button> */}
                      <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                        <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "0")} name="account_team_size" defaultValue="1-1" />
                        <span className="fw-bolder fs-3">1-3</span>
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col">
                      {/*begin::Option*/}
                      {/* <Button variant="primary" onClick={()=>handlechange('50+')}>50+</Button> */}
                      <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                        <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "1")} name="account_team_size" defaultValue="2-10" />
                        <span className="fw-bolder fs-3">3-10</span>
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col">
                      {/*begin::Option*/}
                      {/* <Button variant="primary" onClick={()=>handlechange('10-50')}>10-50</Button> */}
                      <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                        <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "2")} name="account_team_size" defaultValue="10-50" />
                        <span className="fw-bolder fs-3">10-50</span>
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col">
                      {/*begin::Option*/}
                      {/* <Button variant="primary" onClick={()=>handlechange('50+')}>50+</Button> */}
                      <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                        <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "3")} name="account_team_size" defaultValue="50+" />
                        <span className="fw-bolder fs-3">50+</span>
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Row*/}
                  {/*begin::Hint*/}
                  {/*end::Hint*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="mb-10 fv-row">
                  {/*begin::Label*/}
                  <label className="form-label mb-3">Company Industry</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <select
                    name="country"
                    aria-label="Select a industry"
                    data-control="select2"
                    data-placeholder="Select a industry..."
                    className="form-select form-select-solid form-select-lg fw-bold"
                    onChange={(e) => handleChangeCompanyType(e)}
                  >
                    <option selected disabled hidden>
                      Choose an Option
                    </option>
                    <option value="Software and App Developement">Software and App Developement</option>
                    <option value="Health">Health</option>
                    <option value="Tech Startup">Tech Startup</option>
                    <option value="Education and Training">Education and Training</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Creative Agency (Web , Advertising , Video)">Creative Agency (Web , Advertising , Video)</option>
                    <option value="Financial and Credit Services">Financial and Credit Services</option>
                    <option value="News , Media and Publication">News , Media and Publication</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="It Services">It Services</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Construction">Construction</option>
                    <option value="Trade (Retail and Wholesale)">Trade (Retail and Wholesale)</option>
                    <option value="Others">Others</option>
                  </select>
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                <div className="mb-10 fv-row">
                  {/*begin::Label*/}
                  <label className="form-label mb-3">What Is Your Experience With Sales Tools?</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <select
                    name="country"
                    aria-label="Select a experience"
                    data-control="select2"
                    data-placeholder="Select a experience..."
                    className="form-select form-select-solid form-select-lg fw-bold"
                    onChange={(e) => handleChangeExperience(e)}
                  >
                    <option selected disabled hidden>
                      Choose an Option
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5+">5+</option>
                  </select>
                  {/*end::Input*/}
                </div>

                {/*begin::Submit*/}
                <div className="d-flex align-items-center justify-content-arround">
                  <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={CloseButton}>
                    <span className="indicator-label">Skip</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                  <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={SubmitDetails}>
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
                {/*end::Submit*/}
              </div>
              {/*end::Wrapper*/}
            </div>
          )}
        </div>
      </InlineStyled>
    </>
  );
}
