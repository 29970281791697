import { getListOfActiveActivityTypes } from "../types/activity.types";

export const getListOfActiveActivityTypesRequest = (req, cb) => ({
  type: getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_REQUEST,
  payload: req,
  cb,
});
export const getListOfActiveActivityTypesSuccess = (req) => ({
  type: getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_SUCCESS,
  payload: req,
});
export const getListOfActiveActivityTypesError = (req) => ({
  type: getListOfActiveActivityTypes.GET_LIST_OF_ACTIVE_ACTIVITY_TYPES_ERROR,
  payload: req,
});
