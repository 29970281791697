import React, { useState } from "react";
import AdvancedAccordionComponent from "../../../../../../components/accordion/advanced-accordion-component";
import { Form } from "react-bootstrap";
import { whatsappInputMaxLength } from "../constants";

export default function WhatsappAddTemplateFooter(props) {
    const [activeKey, setActiveKey] = useState("0");

    return <AdvancedAccordionComponent
        title="Footer (Optional)"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        tooltipMessage={"Add a short line of text to the bottom of your message template."}
    >
        <Form.Group>
            <Form.Label>Footer Text</Form.Label>
            <Form.Control type="text" maxLength={whatsappInputMaxLength.WHATSAPP_FOOTER_TEXT} value={props.footerText} onChange={e => props.setFooterText(e.target.value)} />
        </Form.Group>
    </AdvancedAccordionComponent>
}