import { getUserSalesTargetStaticstics } from "../types/staticstics.types";

export const getUserSalesTargetStaticsticsRequest = (req, cb) => ({
  type: getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_REQUEST,
  payload: req,
  cb,
});
export const getUserSalesTargetStaticsticsSuccess = (req) => ({
  type: getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_SUCCESS,
  payload: req,
});
export const getUserSalesTargetStaticsticsError = (req) => ({
  type: getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_ERROR,
  payload: req,
});
