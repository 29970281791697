import React, { useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
// import OnlyStepper from "../../../components/stepper/only-stepper";
import { InputLimit } from "../../../constants";

export default function AddDomainModal(props) {
  const [activeStep] = useState("Add Domain");
  const [records] = useState([
    {
      name: "SPF Record",
      type: "TXT record",
      host_name: "@",
      value: "v=spf1 a mx include:saino.io ~all",
    },
    {
      name: "DKIM Record",
      type: "TXT record",
      host_name: "api._domainkey",
      value:
        "k=rsa;t=s;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbmGbQMzYeMvxwtNQoXN0waGYaciuKx8mtMh5czguT4EZlJXuCt6V+l56mmt3t68FEX5JJ0q4ijG71BGoFRkl87uJi7LrQt1ZZmZCvrEII0YO4mp8sDLXC8g1aUAoi8TJgxq2MJqCaMyj5kAm3Fdy2tzftPCV/lbdiJqmBnWKjtwIDAQAB",
    },
    {
      name: "CNAME record",
      type: "CNAME record",
      host_name: "tracking",
      value: "tracking.saino.io",
    },
    {
      name: "DMRC Record",
      type: "TXT record",
      host_name: "_dmarc",
      value: "v=DMARC1; p=none; rua=mailto:dmarc-reports@yourdomain.com",
    },
  ]);
  const { show, handleClose, handleSave, setDomainName, domainName, loading } = props.modalProps;

  const handleNextClick = () => {
    handleSave();

    // if (activeStep === "Domain DNS Settings") {
    //   handleSave();
    // }
    // else {
    //   setActiveStep("Domain DNS Settings")
    // }
  };

  const handleBackClick = () => {
    handleClose();
    // if (activeStep === "Add Domain") {
    //   handleClose();
    // }
    // else {
    //   setActiveStep("Add Domain");
    // }
  };
  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{"Add Domain"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <OnlyStepper steps={["Add Domain", "Domain DNS Settings"]} activeStep={"Add Domain"} /> */}
        {activeStep === "Add Domain" && (
          <Form.Group id="campaign-components-add-domain-modal-popup">
            <Form.Label aria-label="domain-info">Add a sending domain you wish to validate. A few simple DNS configurations are required in order for your emails to be sent directly from your custom domains.</Form.Label>
            <br />
            <br />
            <Form.Label aria-label="domain (example-abc.com)">Domain (example-abc.com)</Form.Label>
            <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={domainName} onChange={(e) => setDomainName(e.target.value)}></Form.Control>
          </Form.Group>
        )}
        {activeStep === "Domain DNS Settings" && (
          <>
            <Form.Label>
              Fully brand your emails with your own domain name and improve your delivery with domain reputation. Below is the domain Authentication parameter. The customer has to add this to his domain DNS. Once you
              login to you domain DNS manager setup the below 4 records.
            </Form.Label>
            <div className="card p-4 bg-light-light">
              <table className="w-100 border border-secondary">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border border-secondary">Record Name</th>
                    <th className="px-4 py-2 border border-secondary">Type</th>
                    <th className="px-4 py-2 border border-secondary">Host/Name</th>
                    <th className="px-4 py-2 border border-secondary">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((each, index) => {
                    return (
                      <tr>
                        <td className="px-4 py-2 border border-secondary">{each.name}</td>
                        <td className="px-4 py-2 border border-secondary">{each.type}</td>
                        <td className="px-4 py-2 border border-secondary">{each.host_name}</td>
                        <td className="px-4 py-2 border border-secondary text-break">{each.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleBackClick}>
          {activeStep === "Domain DNS Settings" ? "Back" : "Cancel"}
        </Button>
        <Button variant="primary" className="btn btn-sm btn-primary my-1 btn  btn-primary" onClick={handleNextClick}>
          {loading && (
            <Spinner
              className="mr-2 fs-9 w-15px h-15px text-white"
              animation="border"
              variant=""
            />
          )}{" "}
          {activeStep === "Domain DNS Settings" ? "Save & Verify" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
