import { getCampaignList, repeatCampaign } from "../types/campaign_types";

const initState = {
  data: [],
  detailedData: [],
  count: 0,
  loading: false,
  error: false,
  repeat: {},
};

export default function campaignListReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCampaignList.GET_CAMPAIGN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getCampaignList.GET_CAMPAIGN_LIST_REQUEST_AFTER: {
      return {
        ...state,
        loading: false,
      };
    }
    case getCampaignList.GET_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        detailedData: action.payload.detailedData,
        count: action.payload.count,
      };
    case getCampaignList.GET_CAMPAIGN_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case repeatCampaign.REPEAT_CAMPAIGN_REQUEST:
      return {
        ...state,
        repeat: action.payload,
      };
    case repeatCampaign.CLEAR_REPEAT_CAMPAIGN_REQUEST:
      return {
        ...state,
        repeat: {},
      };
    default:
      return state;
  }
}
