import React, { useState, useEffect } from "react";
import _ from "lodash";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton } from "../../components/button";
import { NormalTable } from "../../components/table/normalTable";
import { ownersRequest } from "../customers/store/actions/actions";
import { Dropdown } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import * as actions from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { clearError as leadClearError, leadFieldsRequest, leadTagsRequest } from "../../containers/leads/store/actions/actions";
import { clearError as peopleClearError, peopleFieldsRequest, peopleTagsRequest } from "../../containers/customers/store/actions/actions";
import { clearError as companyClearError, companyFieldsRequest, companyTagsRequest } from "../../containers/companies/store/actions/actions";
import { clearError as productClearError, productFieldsRequest, productTagsRequest } from "../../containers/products/store/actions/actions";
import * as marketingListActions from "../campaign/store/actions/campaign_marketing_list_actions";
import * as marketingDataFieldActions from "../campaign/store/actions/marketing_data_field_actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import ContentWrapper from "../Wrapper";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import ImportModal from "./components/import.modal";

var timer;

export default function Import(props) {
  const [type, setType] = useState(
    props.match.params.type === "product" || props.match.params.type === "lead" || props.match.params.type === "people" || props.match.params.type === "company" ? props.match.params.type : "all",
  );
  const [showImportModal, setShowImportModal] = useState(false);
  const dispatch = useDispatch();
  const productError = useSelector((state) => state.Products.error);
  const peopleError = useSelector((state) => state.People.error);
  const companyError = useSelector((state) => state.Companies.error);
  const leadError = useSelector((state) => state.Leads.error);
  const errorStore = useSelector((state) => state.ImportExport.error);
  const successStore = useSelector((state) => state.ImportExport.success);
  const listStore = useSelector((state) => state.ImportExport.importHistoryList);
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const showDeleteAction = useState(true);

  const history = useHistory();

  useEffect(() => {
    clearInterval(timer);
    setType(["product", "lead", "people", "company", "marketing"].includes(props.match.params.type) ? props.match.params.type : "all");
    if (props.match.params.type === "deal") {
      history.push("/import/all");
    }
  }, [props.match.params.type, history]);

  useEffect(() => {
    dispatch(leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));
    dispatch(marketingDataFieldActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
    dispatch(productFieldsRequest({ fieldType: "PRODUCT" }));
    dispatch(ownersRequest());

    //tags
    dispatch(leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));
    dispatch(productTagsRequest({ type: "product" }));
    dispatch(marketingListActions.getListOfMarketingListTagsRequest({ type: "marketing" }));
  }, [dispatch]);

  useEffect(() => {
    if (leadError.err || productError.err || companyError.err || peopleError.err) {
      dispatchSnackbarError("Could Not Get Fields");
      dispatch(leadClearError());
      dispatch(productClearError());
      dispatch(companyClearError());
      dispatch(peopleClearError());
    }
  }, [leadError, peopleError, productError, companyError, dispatch]);

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  useEffect(() => {
    if (type) {
      dispatch(actions.importHistoryRequest({ pageNo: pageNo, limit: limit, type: type }));
    }
  }, [type, pageNo, limit, dispatch]);

  useEffect(() => {
    if (type) {
      clearInterval(timer);
      timer = setInterval(() => dispatch(actions.importHistoryRequestWithoutLoading({ pageNo: pageNo, limit: limit, type: type })), 3000);
    }
  }, [type, pageNo, limit, dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  const getToggleText = () => {
    if (type === "product") return <>Products</>;
    else if (type === "lead") return <>Leads</>;
    else if (type === "company") return <>Companies</>;
    else if (type === "people") return <>People</>;
    else if (type === "marketing") return <>Marketing</>;
    else return <>All</>;
  };

  const columns = [
    { apiKeyName: "createdAt", fieldInputType: "DATE", fieldName: "Date" },
    { apiKeyName: "dataType", fieldInputType: "INPUT", fieldName: "Data" },
    { apiKeyName: "fileFormat", fieldInputType: "INPUT", fieldName: "File Format" },
    { apiKeyName: "ownerId", fieldInputType: "OWNER_SELECT", fieldName: "Owner" },
    { apiKeyName: "percentage", fieldInputType: "INPUT", fieldName: "Percentage" },
    { apiKeyName: "total", fieldInputType: "INPUT", fieldName: "Total" },
    { apiKeyName: "failed", fieldInputType: "INPUT", fieldName: "Failed" },
    { apiKeyName: "success", fieldInputType: "INPUT", fieldName: "Success" },
    { apiKeyName: "status", fieldInputType: "STATUS_DISPLAY", fieldName: "Status" },
  ];

  const callDeleteImportDataApi = (data) => {
    dispatch(
      actions.deleteImportHistoryRequest({
        id: data._id,
        loadingData: { pageNo: pageNo, limit: limit, type: type },
      }),
    );
  };

  const tableProps = {
    columns: columns,
    data: listStore.list.map((each) => {
      each.percentage = `${_.parseInt(each.percentage)}%`;
      return each;
    }),
    pagination: true,
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    loading: listStore.loading,
    showDeleteAction: showDeleteAction,
    showLogOptions: true,
    callDeleteImportDataApi: callDeleteImportDataApi,
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="d-flex flex-row flex-stack w-100">
            <div>
              <div className="menu menu-xs-rounded menu-xs-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                <div className="menu-item me-lg-1">
                  <NavLink className="menu-link py-3 active" to={`/import/${type}`}>
                    <span className="menu-title">Import History</span>
                  </NavLink>
                </div>
                <div className="menu-item me-lg-1">
                  <NavLink className="menu-link py-3" to={`/export/${type}`}>
                    <span className="menu-title">Export History</span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex flex-row align-items-center gap-12px">
                <Dropdown>
                  <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle btn-secondary custom-secondory-cta">{getToggleText()}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <NavLink to="/import/lead">
                      <span className="dropdown-item dropdown-hover">Leads</span>
                    </NavLink>
                    <NavLink to="/import/people">
                      <span className="dropdown-item dropdown-hover">People</span>
                    </NavLink>
                    <NavLink to="/import/company">
                      <span className="dropdown-item dropdown-hover">Companies</span>
                    </NavLink>
                    <NavLink to="/import/product">
                      <span className="dropdown-item dropdown-hover">Products</span>
                    </NavLink>
                    <NavLink to="/import/marketing">
                      <span className="dropdown-item dropdown-hover">Marketing List</span>
                    </NavLink>
                    <NavLink to="/import/all">
                      <span className="dropdown-item dropdown-hover">All</span>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
                <BlueGradientButton variant="primary" size="sm" onClick={() => setShowImportModal(true)}>
                  Import From File
                </BlueGradientButton>
                <ImportModal showImportModal={showImportModal} setShowImportModal={setShowImportModal} loadingData={{ type: type, pageNo: pageNo, limit: limit }} />
              </div>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      {!listStore.loading && listStore.count === 0 ? (
        <CustomEmptyPage page="import" setShowAdd={() => setShowImportModal(true)} />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <NormalTable props={tableProps} />
          </div>
        </div>
      )}
    </ContentWrapper>
  );
}
