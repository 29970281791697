import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as cadenceSubscriberOperationActions from "../actions/cadence_subscriber_operation_actions";
import * as cadenceSubscriberOperationTypes from "../types/cadence_subscriber_operation_types";
import * as cadenceLeadActions from "../actions/cadence_lead_actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import { endpoints } from "../endpoints";

function* changeStepCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.CHANGE_STEP_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.changeStepCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.changeStepCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.changeStepCadenceSubscriberError(e));
  }
}

function* markAsFinishedCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.MARK_AS_FINISHED_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.markAsFinishedCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.markAsFinishedCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.markAsFinishedCadenceSubscriberError(e));
  }
}

function* markAsRepliedCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.MARK_AS_REPLIED_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.markAsRepliedCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.markAsRepliedCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.markAsRepliedCadenceSubscriberError(e));
  }
}

function* pauseCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.PAUSE_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.pauseCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.pauseCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.pauseCadenceSubscriberError(e));
  }
}

function* resumeCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.RESUME_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.resumeCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.resumeCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.resumeCadenceSubscriberError(e));
  }
}

function* removeFromCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.REMOVE_FROM_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.removeFromCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.removeFromCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.removeFromCadenceSubscriberError(e));
  }
}

function* retryCadenceSubscriber(action) {
  try {
    const res = yield call(api.post, endpoints.RETRY_CADENCE_SUBSCRIBER, action.payload.data);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message);
      yield put(cadenceSubscriberOperationActions.retryCadenceSubscriberSuccess(res.data));
      if (action.payload.from === "subscriber-list") yield put(cadenceLeadActions.getCadenceAllLeadRequest(action.payload.filter));
      else if (action.payload.from === "module-list") yield put(cadenceLeadActions.getListOfCadenceByLeadIdRequest(action.payload.filter));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSubscriberOperationActions.retryCadenceSubscriberError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSubscriberOperationActions.retryCadenceSubscriberError(e));
  }
}

export default function* cadenceSubscriberOperationsSaga() {
  yield takeLatest(cadenceSubscriberOperationTypes.changeStepCadenceSubscriber.REQUEST, changeStepCadenceSubscriber);
  yield takeLatest(cadenceSubscriberOperationTypes.markAsFinishedCadenceSubscriber.REQUEST, markAsFinishedCadenceSubscriber);
  yield takeLatest(cadenceSubscriberOperationTypes.markAsRepliedCadenceSubscriber.REQUEST, markAsRepliedCadenceSubscriber);
  yield takeLatest(cadenceSubscriberOperationTypes.pauseCadenceSubscriber.REQUEST, pauseCadenceSubscriber);
  yield takeLatest(cadenceSubscriberOperationTypes.resumeCadenceSubscriber.REQUEST, resumeCadenceSubscriber);
  yield takeLatest(cadenceSubscriberOperationTypes.removeFromCadenceSubscriber.REQUEST, removeFromCadenceSubscriber);
  yield takeLatest(cadenceSubscriberOperationTypes.retryCadenceSubscriber.REQUEST, retryCadenceSubscriber);
}
