import React from "react";
import { Dropdown } from "react-bootstrap";

export default function SalesForecastCard(props) {
  const getAssignee = (data) => {
    if (data.typeOfUser === "company") {
      return "Company";
    } else if (data.typeOfUser === "team") {
      const _assignee = props.teamOptions.find((each) => each.value === data.assignee);
      if (_assignee) return `${_assignee.label} (Team)`;
    } else if (data.typeOfUser === "user") {
      const _assignee = props.userOptions.find((each) => each.value === data.assignee);
      if (_assignee) return `${_assignee.label} (User)`;
    }
    return "NA";
  };

  const showActions = (data) => {
    if (props.userId === data.owner) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-3 mx-0 ${props.selectedId === props.data._id ? "active-forecast-card" : "inactive-forecast-card"}`}
      onClick={() => props.onSalesForecastClick(props.data)}
    >
      <div className="d-flex flex-stack mb-3">
        <div className="me-3 d-flex align-items-center gap-4">
          <i className={`bi bi-coin fs-2qx ms-n1 me-1`}></i>
          <span className="text-gray-800 fw-bolder">{props.data.title}</span>
        </div>
        <div className="m-0">
          {showActions(props.data) ? (
            <Dropdown onClick={(e) => e.stopPropagation()} className="mt-1 custom-dropdown-toggle">
              <Dropdown.Toggle className="m-0 p-0" variant="transparent">
                <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                  <span className="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"></rect>
                      <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
                      <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
                      <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
                    </svg>
                  </span>
                </button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => e.stopPropagation()} onSelect={() => props.handleEditClick(props.data)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => e.stopPropagation()} onSelect={() => props.handleDeleteClick(props.data)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="d-flex flex-stack">
        <span className="text-gray-400 fw-bolder">
          {" "}
          Assignee:<span className="text-gray-800 fw-bolder ml-2">{getAssignee(props.data)}</span>
        </span>
        <span></span>
      </div>
    </div>
  );
}
