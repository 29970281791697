import { companyList } from "../../types/profile-settings.types";

export function companyListRequest(cb) {
  return {
    type: companyList.COMPANY_LIST_REQUEST,
    cb,
  };
}
export function companyListSuccess(res) {
  return {
    type: companyList.COMPANY_LIST_SUCCESS,
    payload: res,
  };
}
export function companyListError(err) {
  return {
    type: companyList.COMPANY_LIST_ERROR,
    payload: err,
  };
}
