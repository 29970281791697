//svgs
import videoSvg from "../../images/illustrations/__video.svg";
import leadSvg from "../../images/illustrations/__New.svg";
import productSvg from "../../images/illustrations/__present.svg";
import peopleSvg from "../../images/illustrations/__File-Manager.svg";
import companySvg from "../../images/illustrations/__Real-Estate.svg";
import leadbotSvg from "../../images/illustrations/__Social-3.svg";
import workflowSvg from "../../images/illustrations/__Time.svg";
import cadenceSvg from "../../images/illustrations/__Time.svg";
import activitySvg from "../../images/illustrations/__Time.svg";
import dealSvg from "../../images/illustrations/__online-shop.svg";

import teamsSvg from "../../images/illustrations/__startap.svg";
import apiKeySvg from "../../images/illustrations/api_webhook.svg";
import webhookSvg from "../../images/illustrations/api_webhook.svg";

import emailSyncSvg from "../../images/illustrations/__New.svg";
import calendarSyncSvg from "../../images/illustrations/__Annual-Report.svg";

import importExportSvg from "../../images/illustrations/importexport.svg";
import dashboardSvg from "../../images/illustrations/__statistics.svg";
import noDataSvg from "../../images/illustrations/__there-is-nothing-here.svg";
import subscriptionSvg from "../../images/illustrations/cart.svg";

import salesForecastSvg from "../../images/illustrations/__Store-Analytics.svg";
import salesTargetSvg from "../../images/illustrations/__investment.svg";
import couponCodeSvg from "../../images/illustrations/extra/__tickets.svg";
import noTemplateSvg from "../../images/illustrations/__response.svg";

import lockSvg from "../../images/illustrations/__closed.svg";
import campaignSvg from "../../images/illustrations/__message.svg";
import campaignEmailDomainSvg from "../../images/illustrations/__to_add.svg";
import cadenceSubscribersSVG from "../../images/illustrations/__hands.svg";

import WhatsappAccountSVG from "../../images/illustrations/__Social.svg";

export const constants = {
  lead: {
    heading: "Lead",
    description: "Start adding leads to your sales funnel here.",
    image: leadSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Lead",
        type: "event",
      },
      {
        text: "Import Leads",
        type: "internal-link",
        link: "/import/lead",
      },
    ],
  },
  junkLead: {
    heading: "Junk Lead",
    description: "Convert active leads to junk to see them here.",
    image: leadSvg,
    theme: "white",
    buttons: [],
  },
  people: {
    heading: "People",
    description: "It appears that there is nobody here.",
    image: peopleSvg,
    theme: "white",
    buttons: [
      {
        text: "Import Peoples",
        type: "internal-link",
        link: "/import/people",
      },
      {
        text: "Add People",
        type: "event",
      },
    ],
  },
  company: {
    heading: "Companies",
    description: "Nothing here! Add companies you are working with.",
    image: companySvg,
    theme: "white",
    buttons: [
      {
        text: "Import Companies",
        type: "internal-link",
        link: "/import/company",
      },
      {
        text: "Add Company",
        type: "event",
      },
    ],
  },
  product: {
    heading: "Products",
    description: "Nothing here. Add the list of your products.",
    image: productSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Product",
        type: "event",
      },
    ],
  },
  leadbot: {
    heading: "Leadbot",
    description: "We took lead capture to a whole new level. You can embed it on your site or use it as the landing page.",
    image: leadbotSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Leadbot",
        type: "event",
      },
    ],
  },
  cadence: {
    heading: "Sales Cadence",
    description: "Accelerate your sales journey, automate follow-ups and tasks, generate leads, and carry out cold email outreach, and marketing automation journeys.",
    image: cadenceSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Cadence",
        type: "event",
      },
    ],
  },
  cadenceSubscribers: {
    heading: "Cadence Subscribers",
    description: "No subscribers associated with this cadence. Add subscribers here or go to the lead or contact details section to subscribe them to a cadence.",
    image: cadenceSubscribersSVG,
    theme: "white",
    buttons: [
      {
        text: "Add Subscribers",
        type: "event",
      },
    ],
  },
  workflow: {
    heading: "Workflow Automation",
    description: "Automation can save your salespeople time by automating repetitive tasks. Sales reps can focus on the most important thing, which is closing deals.",
    image: workflowSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Workflow",
        type: "event",
      },
    ],
  },
  "no-access-workflow": {
    heading: "Workflow",
    description: "Access to the worflow module is restricted. To get access, get in touch with your admin",
    image: lockSvg,
    theme: "white",
    buttons: [],
  },
  deal: {
    heading: "Deals",
    description: "Convert leads to deals or add them directly to your pipeline.",
    image: dealSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Deal",
        type: "event",
      },
    ],
  },
  pipeline: {
    heading: "Deals",
    description: "Convert leads to deals or add them directly to your pipeline.",
    image: dealSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Pipeline",
        type: "event",
      },
    ],
  },
  activity: {
    heading: "Activity",
    description: "Manage activities and stay updated on your upcoming activities. Track targets and achievements.",
    image: activitySvg,
    theme: "white",
    buttons: [
      {
        text: "Add Activity",
        type: "event",
      },
    ],
  },
  learn: {
    heading: "Learn & Grow through coaching and skills",
    description: "Salespeople must be well-versed in all aspects of the products, processes, or strategies that they are selling. Here, you can share all of this information via videos.",
    image: videoSvg,
    theme: "transparent",
    buttons: [
      {
        text: "Know More",
        type: "link",
        link: "https://salessimplify.com/help-center/learn",
      },
      {
        text: "Add Video",
        type: "event",
      },
    ],
  },
  team: {
    heading: "Teams",
    description: "Create teams from here and manage them efficiently by granting access to managers or agents.",
    image: teamsSvg,
    theme: "white",
    buttons: [],
  },
  apiKey: {
    heading: "API Keys",
    description: "Create an API and integrate it with your other apps for better collaboration.",
    image: apiKeySvg,
    theme: "white-sub-settings",
    buttons: [],
  },
  webhook: {
    heading: "Webhook",
    description: "Create an Webhook and integrate it with your other apps for better collaboration.",
    image: webhookSvg,
    theme: "white-sub-settings",
    buttons: [],
  },
  emailSync: {
    heading: "Email Sync",
    description: "Stay Organized. You can send email, track open and click, view contact related emails in their 360-degree view.",
    image: emailSyncSvg,
    theme: "white-without-sub",
    buttons: [],
  },
  calendarSync: {
    heading: "Calendar Sync",
    description: "Connect your calendar account and see all of your sync events in one place.",
    image: calendarSyncSvg,
    theme: "white-without-sub",
    buttons: [],
  },
  schedularCalendarConnect: {
    heading: "Scheduler",
    description: "Connect your calendar account to access scheduler functionality.",
    image: calendarSyncSvg,
    theme: "white-without-sub",
    buttons: [
      {
        text: "Go To Calendar Sync",
        type: "internal-link-primary",
        link: "/settings/profile/sync-settings",
      },
    ],
  },
  schedularEmpty: {
    heading: "Scheduler",
    description: "Connect your calendar account to access scheduler functionality.",
    image: calendarSyncSvg,
    theme: "white-sub-settings",
    buttons: [
      {
        text: "Create New Scheduler",
        type: "event",
      },
    ],
  },
  connectCalendar: {
    heading: "Calender",
    description: "Connect your calendar account and see all of your sync events in one place.",
    image: calendarSyncSvg,
    theme: "white",
    buttons: [
      {
        text: "Connect Calender",
        type: "internal-link-primary",
        link: "/settings/profile/sync-settings",
      },
    ],
  },
  connectEmail: {
    heading: "Email",
    description: "Stay Organized. You can send email, track open and click, view contact related emails in this area.",
    image: emailSyncSvg,
    theme: "white-sub-settings",
    buttons: [
      {
        text: "Connect Email Account",
        type: "internal-link-primary",
        link: "/settings/profile/email-sync",
      },
    ],
  },
  subscribeToCadence: {
    heading: "Sales Cadence",
    description: "Accelerate your sales cycle through automated follow-ups, activities and omnichannel engagement.",
    image: cadenceSvg,
    theme: "white-sub-settings",
    buttons: [
      {
        text: "Add to Cadence",
        type: "event",
      },
    ],
  },
  import: {
    heading: "Import History",
    description: "Import & Export data quickly.",
    image: importExportSvg,
    theme: "white",
    buttons: [
      {
        text: "Import Data",
        type: "event",
      },
    ],
  },
  export: {
    heading: "Export History",
    description: "There is export data to see here",
    image: importExportSvg,
    theme: "white",
    buttons: [],
  },
  report: {
    heading: "Powerful Analytics",
    description: "Filter, group, and oversee results, revealing winning patterns and bottlenecks to overcome and improve processes.",
    image: dashboardSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Report",
        type: "event",
      },
    ],
  },

  goal: {
    heading: "Reports",
    description: "Track your business performance over time.",
    image: dashboardSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Report",
        type: "event",
      },
    ],
  },
  dashboard: {
    heading: "Powerful Analytics",
    description: "Filter, group, and oversee results, revealing winning patterns and bottlenecks to overcome and improve processes.",
    image: dashboardSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Dashboard",
        type: "event",
      },
    ],
  },
  salesTarget: {
    heading: "Sales Goal & Targets",
    description: "Know how many activities your team is performing and how much business they will close during a given timeframe.",
    image: salesTargetSvg,
    theme: "white",
    buttons: [
      {
        text: "Create Sales Target",
        type: "event",
      },
    ],
  },
  salesForecast: {
    heading: "Sales Forecast",
    description: "Predict, how much a sales unit (salesperson, team, or company) is likely to sell in the next week, month, quarter, or year.",
    image: salesForecastSvg,
    theme: "white",
    buttons: [
      {
        text: "Create sales forecast",
        type: "event",
      },
    ],
  },
  emptyTable: {
    heading: "No Data",
    description: "No data to show for these filter conditions.",
    image: noDataSvg,
    theme: "custom-table",
    buttons: [],
  },
  emptyTimeline: {
    heading: "Timeline",
    description: "Everything related to contact activity will be displayed here. Nothing has happened yet.",
    image: noDataSvg,
    theme: "white",
    buttons: [],
  },
  subscriptionExpired: {
    heading: "Oops!",
    description: "Your subscription has expired.",
    image: subscriptionSvg,
    theme: "white",
    buttons: [
      {
        text: "Subscribe",
        type: "event",
      },
    ],
  },
  upgradeCalendar: {
    heading: "Oops!",
    description: "Your subscription has expired.",
    image: subscriptionSvg,
    theme: "white",
    buttons: [
      {
        text: "Upgrade Plan",
        type: "event",
      },
    ],
  },
  couponCode: {
    heading: "Coupon Code!",
    description: "Reedem coupon code for benifits.",
    image: couponCodeSvg,
    theme: "white",
    buttons: [
      {
        text: "Reedem Code",
        type: "event",
      },
    ],
  },
  noTemplates: {
    heading: "No Templates",
    description: "Click on add button to add new template.",
    image: noTemplateSvg,
    theme: "white-sub-settings",
    buttons: [
      {
        text: "Add Template",
        type: "event",
      },
    ],
  },
  "noTemplates-gallery": {
    heading: "No Templates",
    description: "Salessimplify team working on template to add in Gallery.",
    image: noTemplateSvg,
    theme: "transparent",
    buttons: [],
  },
  "email-domain": {
    heading: "Verify Domain",
    description:
      "Maximize your email marketing efforts by using a custom domain for your email address. This small detail helps create a professional image, builds trust with subscribers, and enhances email campaigns, verification links, and subscribe forms.",
    image: campaignEmailDomainSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Domain",
        type: "event",
      },
    ],
  },
  "add-domain": {
    heading: "Add & Verify Domain",
    description:
      "Maximize your email marketing efforts by using a custom domain for your email address. This small detail helps create a professional image, builds trust with subscribers, and enhances email campaigns, verification links, and subscribe forms.",
    image: campaignEmailDomainSvg,
    theme: "white",
    buttons: [
      {
        text: "Go To Domain Settings",
        type: "internal-link",
        link: "/campaign/settings/email/manage",
      },
    ],
  },
  "sms-sender-id": {
    heading: "Add Sender ID",
    description: "In order to send an sms campaign, add your sender ID.",
    image: campaignEmailDomainSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Sender ID",
        type: "event",
      },
    ],
  },
  campaign: {
    heading: "Omnichannel Campaigns",
    description: "Connect with customers through multiple channels, start your lead generation, engagement, and retention at scale from here.",
    image: campaignSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Campaign",
        type: "event",
      },
    ],
  },
  shortLink: {
    heading: "Short Link",
    description: "Start creating short link and use in campaign to track users.",
    image: campaignSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Short Link",
        type: "event",
      },
    ],
  },
  shortLinkAnalytics: {
    heading: "Short Link Analytics",
    description: "See your tracked users by short link here.",
    image: campaignSvg,
    theme: "white",
    buttons: [],
  },
  blockContact: {
    heading: "Block Contact",
    description: " Maintain a list of people as blocked contacts, These contacts will not receive any future communications.",
    image: campaignSvg,
    theme: "white",
    buttons: [
      {
        text: "Add Block Contact",
        type: "event",
      },
    ],
  },
  marketingList: {
    heading: "Marketing List",
    description: "Manage your subscribers or cold contacts list here. It supports tags, segments and custom fields. View all campaigns sent to each recipient here.",
    image: noDataSvg,
    theme: "white",
    buttons: [
      {
        text: "Import Marketing List",
        type: "internal-link",
        link: "/import/marketing",
      },
      {
        text: "Add Marketing List",
        type: "event",
      },
    ],
  },
  downloadCampaignReport: {
    heading: "Campaign Download Report",
    description: "Start downloading your campaign report.",
    image: dashboardSvg,
    theme: "white",
    buttons: [
      {
        text: "Download Report",
        type: "event",
      },
    ],
  },

  "no-access-campaign": {
    heading: "Omnichannel Campaigns",
    description: "Access to the campaign module is restricted. To get access, get in touch with your admin.",
    image: lockSvg,
    theme: "white",
    buttons: [],
  },
  "no-report": {
    heading: "Report Not Avaliable",
    description: "Start sending campaign to your customers to see report.",
    image: dashboardSvg,
    theme: "white",
    buttons: [],
  },

  emailValidation: {
    heading: "Email Validation",
    description: "Ensure the accuracy and deliverability of email addresses, minimizing bounce rates and maintaining a clean contact database.",
    image: leadbotSvg,
    theme: "white",
    buttons: [],
  },
  emailFinder: {
    heading: "Email Finder",
    description: "Discover email addresses associated with individuals or companies, simplifying contact acquisition and communication outreach.",
    image: leadbotSvg,
    theme: "white",
    buttons: [],
  },
  'whastapp-account': {
    heading: "WhatsApp Account",
    description: "Connect your WhatsApp account to send WhatsApp campaigns.",
    image: WhatsappAccountSVG,
    theme: "transparent",
    buttons: [
      {
        text: "Add Whatsapp",
        type: "event",
      },
    ],
  }
};

export const themes = {
  transparent: {
    background: "bg-transparent",
    heightClass: "h-vh-160px",
    imageHeightClass: "h-vh-450px",
  },
  white: {
    background: "bg-white",
    heightClass: "h-vh-160px",
    imageHeightClass: "h-vh-450px",
  },
  "white-sub-settings": {
    background: "bg-white",
    heightClass: "h-vh-250px",
    imageHeightClass: "h-vh-450px",
  },
  "white-without-sub": {
    background: "bg-white",
    heightClass: "h-vh-120px",
    imageHeightClass: "h-vh-450px",
  },
  "custom-table": {
    background: "bg-white",
    heightClass: "h-auto",
    imageHeightClass: "h-40vh",
  },
};
