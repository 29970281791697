import React from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ShareUserInput from "../../../../../../components/custom-inputs/share-user-input";
import CustomSwitch from "../../../../../../components/custom_form/switch";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { InputLimit } from "../../../../../../constants";
import { cadencePriorityOptions, priorityWithinCadenceOptions } from "../../../constants";
import "./styles.scss";
import Select from "react-select";

const customStyles = {
  control: () => ({
    // background: "#F3F6F9",
    // // height: '45px',
    // padding: "7px",
    width: "100%",
    border: "1px solid rgb(228, 230, 239)",
    textAlign: "left",
    padding: "3px 8px",
    borderRadius: "4px",
  }),
  indicatorsContainer: () => ({
    position: "absolute",
    top: "3px",
    right: 0,
    display: "flex",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
  placeholder: () => ({
    color: "#5e6278",
    fontSize: "15px",
    fontWeight: 400,
  }),
};

const infoTextMessages = {
  SUBSCRIBE_CONTACT_DAY: (
    <div>
      Maximum number of new subscribers engaged with the cadence per day. <br />
      Limit Range: 1 to 2000
    </div>
  ),
  SHARE: "Share this Cadence as a template to users in your organization.",
  PRIORITY_WITHIN_CADENCE: "When your account hits email cap, prioritize emails based on the selected category",
  CADENCE_PRIORITY: "Determines which cadence to prioritize for subscribers that are involved in multiple cadences",
  ACCOUNT_BASED: "If any subscriber from a company responds, stop communication with other subscribers from the same company.",
  EXCLUSIVE_CONTACT: "Until completion, active subscribers of this cadence cannot be added to any other cadence",
  UNSUBSCRIBE: "Determines the inclusion of an unsubscribe link in the emails",
  SYNC_ACCOUNT: "Select multiple business email accounts that you wish to utilize for sending the emails. If you remove or delete an email account, the subscribers will be put on pause.",
};
export default function CadenceGeneralSettingsStep5(props) {
  const { daySubscriptionLimit, setDaySubscriptionLimit } = props;
  const { makeExclusiveLead, setMakeExclusiveLead } = props;
  const { priorityOfState, setPriorityOfState } = props;
  const { priority, setPriority } = props;
  // const { isUnsubscribeLink, setIsUnsubscribeLink } = props;
  const { endAllCompanyLeads, setEndAllCompanyLeads, isBusinessPlusUser } = props;
  const { shareUsers, setShareUsers } = props;
  const { syncedEmails, setSyncedEmails, syncedEmailsOptions } = props;

  return (
    <div className="cadence-steps-container card">
      <label htmlFor="cadence-name" className="cadence-head-label">
        General Settings
      </label>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              Daily Subscribers Limit{" "}
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.SUBSCRIBE_CONTACT_DAY}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control maxLength={InputLimit.NUMBER} value={daySubscriptionLimit} onChange={(e) => setDaySubscriptionLimit(e.target.value)} type="number" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Business accounts to use (Required for Business Email)
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.SYNC_ACCOUNT}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <Select
              styles={customStyles}
              placeholder=""
              value={syncedEmails}
              onChange={setSyncedEmails}
              options={syncedEmailsOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              isMulti
              noOptionsMessage={() => null}
              isValidNewOption={() => false}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Priority within Cadence{" "}
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.PRIORITY_WITHIN_CADENCE}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <SimpleDropdown value={priorityOfState} label={"Select Priority"} options={priorityWithinCadenceOptions} handleSelect={(a) => setPriorityOfState(a)} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              Cadence Priority{" "}
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.CADENCE_PRIORITY}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <SimpleDropdown value={priority} label={"Select Priority"} options={cadencePriorityOptions} handleSelect={(a) => setPriority(a)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Share{" "}
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.SHARE}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <ShareUserInput onlyUsers={true} defaultValue={shareUsers} setValue={setShareUsers} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Exclusive Contacts
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.EXCLUSIVE_CONTACT}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <CustomSwitch label="" id={"exclusive-contacts"} checked={makeExclusiveLead} handleChange={(e) => setMakeExclusiveLead(e.target.checked)} />
          </Form.Group>
        </Col>
        <Col>
          {isBusinessPlusUser && (
            <Form.Group>
              <Form.Label>
                Account Based
                <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.ACCOUNT_BASED}</Tooltip>}>
                  <i className="ml-2 bi bi-info-circle"></i>
                </OverlayTrigger>
              </Form.Label>
              <CustomSwitch label="" id={"account-based"} checked={endAllCompanyLeads} handleChange={(e) => setEndAllCompanyLeads(e.target.checked)} />
            </Form.Group>
          )}
        </Col>
        {/* <Col>
          <Form.Group>
            <Form.Label>
              Unsubscribe Link{" "}
              <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.UNSUBSCRIBE}</Tooltip>}>
                <i className="ml-2 bi bi-info-circle"></i>
              </OverlayTrigger>
            </Form.Label>
            <CustomSwitch label="" id={"unsubscribe-link"} checked={isUnsubscribeLink} handleChange={(e) => setIsUnsubscribeLink(e.target.checked)} />
          </Form.Group>
        </Col> */}
      </Row>
    </div>
  );
}
