import { getListOfMarketingList, marketingUpdate, LOADING_DATA_UPDATE, getListOfMarketingListTags, getSelectedMarketingData, getSavedMarketingFilters, generateMarketingAIWFData } from "../types/campaign_marketing_list_types";

const initState = {
  list: {
    data: [],
    loading: false,
    error: "",
    count: 0,
    cellLoading: false,
  },
  loadingData: {
    pageNo: 1,
    limit: 100,
  },
  success: {
    success: false,
    successMsg: "",
  },
  filters: {
    loading: "",
    list: [],
    activeFilter: null,
  },
  tags: {
    list: [],
    loading: false,
  },
  error: {
    err: false,
    errMsg: "",
  },
  popup: {
    loading: false,
    data: {},
    cellLoading: false,
    cellId: "",
  },
};

export default function campaignMarketingReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      };
    case getListOfMarketingList.GET_LIST_OF_MARKETING_LIST_REQUEST_AFTER:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      };


    case marketingUpdate.MARKETIING_UPDATE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          cellLoading: action.payload.cellId,
        },
      };
    case marketingUpdate.MARKETIING_UPDATE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
          cellLoading: false,
        },
      };
    case marketingUpdate.MARKETIING_UPDATE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
          cellLoading: false,
        },
      };
    case generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cellLoading: action.payload.cellId,
        },
        popup: {
          ...state.popup,
          cellLoading: action.payload.cellId,
        },
      };
    case generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          cellLoading: false,
        },
        popup: {
          ...state.popup,
          cellLoading: false,
        },
      };
    case generateMarketingAIWFData.GENERATE_MARKETING_AIWF_DATA_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          error: action.payload,
          cellLoading: false,
        },
        popup: {
          ...state.popup,
          cellLoading: false,
        },
      };

    // Get Tag
    case getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_REQUEST:
      return { ...state, tags: { ...state.tags, loading: true } };
    case getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_SUCCESS:
      return {
        ...state,
        tags: { ...state.tags, loading: false, list: action.payload.data },
        success: { success: true, successMsg: action.payload.message },
      };
    case getListOfMarketingListTags.GET_LIST_OF_MARKETING_LIST_TAGS_ERROR:
      return {
        ...state,
        tags: { ...state.tags, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    //Getting Filters
    case getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_REQUEST:
      return {
        ...state,
        filters: { ...state.filters, loading: true },
      };
    case getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false,
          list: action.payload.data,
        },
      };
    case getSavedMarketingFilters.GET_SAVED_MARKETING_FILTERS_ERROR:
      return {
        ...state,
        filters: { ...state.filters, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case LOADING_DATA_UPDATE:
      return {
        ...state,
        loadingData: action.payload,
      };

    case getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_REQUEST:
      return { ...state, popup: { ...state.popup, loading: true } };

    case getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_SUCCESS:
      return { ...state, popup: { ...state.popup, loading: false, data: action.payload.data } };

    case getSelectedMarketingData.GET_SELECTE_MARKETING_DATA_ERROR:
      return { ...state, popup: { ...state.popup, loading: false } };

    default:
      return {
        ...state,
      };
  }
}
