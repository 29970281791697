import dayjs from "dayjs"; // { Dayjs }
// import { __ } from '.';
// import { IDateColumn } from '../types';

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

/**
 * Get columns of months
 * @param date - current date
 * @param range - range of months to get
 */
export const monthColumns = (date, range) => {
  let currentDate = date.clone(); // deep copy prevent mutability

  const months = [getMonthYear(currentDate)];

  for (let i = 0; i < range; i++) {
    currentDate = nextMonth(currentDate);
    months.push(getMonthYear(currentDate));
  }

  return months;
};

export const getCurrentDate = () => dayjs();

export const nextMonth = (date) => {
  return date.add(1, "month");
};

export const previousMonth = (date) => {
  return date.subtract(1, "month");
};

export const getMonthTitle = (month) => MONTHS[month];

export const getFullTitle = (date) => {
  const { month, year } = date;
  return MONTHS[month] + " - " + year;
};

export const getMonthYear = (date) => {
  const month = date.month();
  const year = date.year();

  return { month, year };
};
