import { takeLatest, put, call } from "redux-saga/effects";
import * as staticsticsTypes from "../types/staticstics.types";
import api from "../../../../network/apis/api";
import * as StaticsticsActions from "../actions/staticstics_actions";
import { dispatchSnackbarError } from "../../../../utils/toaster";

function* getSalesStaticstaics(action) {
  try {
    const response = yield call(api.get, `/api/crm/insight/staticstics`, action.payload);
    if (response.status === 200) {
      yield put(StaticsticsActions.getUserSalesTargetStaticsticsSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data["message"], "error");
      yield put(StaticsticsActions.getUserSalesTargetStaticsticsError(response));
    } else {
      yield put(StaticsticsActions.getUserSalesTargetStaticsticsError("error"));
    }
    if (action.cb) yield action.cb(response.data);
  } catch (e) {
    yield put(StaticsticsActions.getUserSalesTargetStaticsticsError(e));
  }
}

export default function* SalesStaticsticsSaga() {
  yield takeLatest(staticsticsTypes.getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_REQUEST, getSalesStaticstaics);
}
