import { getListOfWorkflows, getWorkflowDetailsByID, createWorkflow, editWorkflow, deleteWorkflow, updateWorkflowStatus, shareWorkflow, getSharedWorkflowUserList } from "../types";

export const getListOfWorkflowsRequest = (payload) => ({
  type: getListOfWorkflows.GET_LIST_OF_WORKFLOWS_REQUEST,
  payload,
});
export const getListOfWorkflowsSuccess = (payload) => ({
  type: getListOfWorkflows.GET_LIST_OF_WORKFLOWS_SUCCESS,
  payload,
});
export const getListOfWorkflowsError = (payload) => ({
  type: getListOfWorkflows.GET_LIST_OF_WORKFLOWS_ERROR,
  payload,
});

export const getListOfWorkflowsByIdRequest = (payload) => ({
  type: getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_REQUEST,
  payload,
});
export const getListOfWorkflowsByIdSuccess = (payload) => ({
  type: getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_SUCCESS,
  payload,
});
export const getListOfWorkflowsByIdError = (payload) => ({
  type: getWorkflowDetailsByID.GET_LIST_OF_WORKFLOW_BY_ID_ERROR,
  payload,
});

export const createWorkflowRequest = (payload, cb) => ({
  type: createWorkflow.CREATE_WORKFLOW_REQUEST,
  payload,
  cb,
});
export const createWorkflowSuccess = (payload) => ({
  type: createWorkflow.CREATE_WORKFLOW_SUCCESS,
  payload,
});
export const createWorkflowError = (payload) => ({
  type: createWorkflow.CREATE_WORKFLOW_ERROR,
  payload,
});

export const editWorkflowRequest = (payload) => ({
  type: editWorkflow.EDIT_WORKFLOW_REQUEST,
  payload,
});
export const editWorkflowSuccess = (payload) => ({
  type: editWorkflow.EDIT_WORKFLOW_SUCCESS,
  payload,
});
export const editWorkflowError = (payload) => ({
  type: editWorkflow.EDIT_WORKFLOW_ERROR,
  payload,
});

export const deleteWorkflowRequest = (payload) => ({
  type: deleteWorkflow.DELETE_WORKFLOW_REQUEST,
  payload,
});
export const deleteWorkflowSuccess = (payload) => ({
  type: deleteWorkflow.DELETE_WORKFLOW_SUCCESS,
  payload,
});
export const deleteWorkflowError = (payload) => ({
  type: deleteWorkflow.DELETE_WORKFLOW_ERROR,
  payload,
});

export const updateWorkflowStatusRequest = (payload) => ({
  type: updateWorkflowStatus.UPDATE_WORKFLOW_STATUS_REQUEST,
  payload,
});
export const updateWorkflowStatusSuccess = (payload) => ({
  type: updateWorkflowStatus.UPDATE_WORKFLOW_STATUS_SUCCESS,
  payload,
});
export const updateWorkflowStatusError = (payload) => ({
  type: updateWorkflowStatus.UPDATE_WORKFLOW_STATUS_ERROR,
  payload,
});

export const shareWorkflowRequest = (payload) => ({
  type: shareWorkflow.SHARE_WORKFLOW_REQUEST,
  payload,
});
export const shareWorkflowSuccess = (payload) => ({
  type: shareWorkflow.SHARE_WORKFLOW_SUCCESS,
  payload,
});
export const shareWorkflowError = (payload) => ({
  type: shareWorkflow.SHARE_WORKFLOW_ERROR,
  payload,
});

export const getShareWorkflowUserListRequest = (payload, cb) => ({
  type: getSharedWorkflowUserList.GET_SHARED_WORKFLOW_USER_LIST_REQUEST,
  payload,
  cb,
});
export const getShareWorkflowUserListSuccess = (payload) => ({
  type: getSharedWorkflowUserList.GET_SHARED_WORKFLOW_USER_LIST_SUCCESS,
  payload,
});
export const getShareWorkflowUserListError = (payload) => ({
  type: getSharedWorkflowUserList.GET_SHARED_WORKFLOW_USER_LIST_ERROR,
  payload,
});
