import React from "react";
import CouponCodeButton from "../coupon-code-button";

export default function CustomerPortalSection({ data }) {

  return (
    <div className="d-flex align-items-center gap-3">
      {(data.selectedPlan === "free" || data.isFreeTrialPeriod || data.isSubscribedForLifeTimeFree) && <CouponCodeButton />}
    </div>
  );
}
