import React from "react";
import Select from "react-select";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
    zIndex: 999999,
    // width:'30%'
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: () => ({
    maxHeight: "200px !important",
    overflowY: "auto !important",
    zIndex: 99999,
    // position: "fixed"
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

const companyIndustry = [
  { value: "Software and App Developement", label: "Software and App Developement" },
  { value: "Health", label: "Health" },
  { value: "Tech Startup", label: "Tech Startup" },
  { value: "Education and Training", label: "Education and Training" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Creative Agency (Web , Advertising , Video)", label: "Creative Agency (Web , Advertising , Video)" },
  { value: "Financial and Credit Services", label: "Financial and Credit Services" },
  { value: "News , Media and Publication", label: "News , Media and Publication" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "It Services", label: "It Services" },
  { value: "Consulting", label: "Consulting" },
  { value: "Construction", label: "Construction" },
  { value: "Trade (Retail and Wholesale)", label: "Trade (Retail and Wholesale)" },
  { value: "Others", label: "Others" },
];

const experienceOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5+", label: "5+" },
];

export default function AccountInfo(props) {
  let {
    // Back,
    submit,
    // CloseButton
  } = props;

  const handlechange = (e, nameClass) => {
    localStorage.setItem("size", e.target.defaultValue);

    if (nameClass === "0") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.add("active");
      b.classList.remove("active");
      c.classList.remove("active");
      d.classList.remove("active");
    } else if (nameClass === "1") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.remove("active");
      b.classList.add("active");
      c.classList.remove("active");
      d.classList.remove("active");
    } else if (nameClass === "2") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.remove("active");
      b.classList.remove("active");
      c.classList.add("active");
      d.classList.remove("active");
    } else if (nameClass === "3") {
      let a = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[0];
      let b = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[1];
      let c = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[2];
      let d = document.getElementsByClassName("btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4")[3];
      a.classList.remove("active");
      b.classList.remove("active");
      c.classList.remove("active");
      d.classList.add("active");
    }
  };

  const handleChangeCompanyType = (e) => {
    localStorage.setItem("typeOfComp", e.value);
  };

  const handleChangeExperience = (e) => {
    localStorage.setItem("experience", e.value);
  };

  return (
    <>
      <div data-kt-stepper-element="content">
        <div className="text-center mb-10">
          {/*begin::Title*/}
          <h1 className="text-dark mb-3 fw-600">Welcome Onboard!</h1>
          {/*end::Title*/}
          {/*begin::Sub-title*/}
          {/* <div className="text-muted fw-bold fs-5 mb-5">We'll customize the CRM that fits your needs.</div> */}
          {/*end::Sub-title*/}
          {/*begin::Mobile no*/}
          {/* <div className="fw-bolder text-dark fs-3">On Your Email</div> */}
          {/*end::Mobile no*/}
        </div>

        {/*begin::Wrapper*/}
        <div className="w-100">
          {/*begin::Heading*/}
          {/*end::Heading*/}
          {/*begin::Input group*/}
          <div className="mb-10 fv-row">
            {/*begin::Label*/}
            <label className="form-label fw-bolder text-dark fs-6">
              How many people in the company will use Sales Simplify?
              {/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide your team size to help us setup your billing" /> */}
            </label>
            {/*end::Label*/}
            {/*begin::Row*/}
            <div className="row mb-2" data-kt-buttons="true">
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Option*/}
                {/* <Button variant="primary" onClick={()=>handlechange('1-1')}>1-1</Button> */}
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "0")} name="account_team_size" defaultValue="1-3" />
                  <span className="fw-bolder fs-3">1-3</span>
                </label>
                {/*end::Option*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Option*/}
                {/* <Button variant="primary" onClick={()=>handlechange('50+')}>50+</Button> */}
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "1")} name="account_team_size" defaultValue="3-10" />
                  <span className="fw-bolder fs-3">3-10</span>
                </label>
                {/*end::Option*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Option*/}
                {/* <Button variant="primary" onClick={()=>handlechange('10-50')}>10-50</Button> */}
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "2")} name="account_team_size" defaultValue="10-50" />
                  <span className="fw-bolder fs-3">10-50</span>
                </label>
                {/*end::Option*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col">
                {/*begin::Option*/}
                {/* <Button variant="primary" onClick={()=>handlechange('50+')}>50+</Button> */}
                <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                  <input type="radio" className="btn-check" onChange={(e) => handlechange(e, "3")} name="account_team_size" defaultValue="50+" />
                  <span className="fw-bolder fs-3">50+</span>
                </label>
                {/*end::Option*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Hint*/}
            {/*end::Hint*/}
          </div>
          {/*end::Input group*/}
          {/*begin::Input group*/}
          <div className="mb-10 fv-row">
            {/*begin::Label*/}
            <label className="form-label fw-bolder text-dark fs-6">Company Industry</label>
            {/*end::Label*/}
            {/*begin::Input*/}
            {/* <select name="country" aria-label="Select a industry" data-control="select2" data-placeholder="Select a industry..." className="form-select form-select-solid form-select-lg fw-bold"
              onChange={(e) => handleChangeCompanyType(e)}
            >
              <option selected disabled hidden>Choose an Option</option>
              <option value="Software and App Developement">Software and App Developement</option>
              <option value="Health">Health</option>
              <option value="Tech Startup">Tech Startup</option>
              <option value="Education and Training">Education and Training</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Creative Agency (Web , Advertising , Video)">Creative Agency (Web , Advertising , Video)</option>
              <option value="Financial and Credit Services">Financial and Credit Services</option>
              <option value="News , Media and Publication">News , Media and Publication</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="It Services">It Services</option>
              <option value="Consulting">Consulting</option>
              <option value="Construction">Construction</option>
              <option value="Trade (Retail and Wholesale)">Trade (Retail and Wholesale)</option>
              <option value="Others">Others</option>
            </select> */}
            <Select
              menuPortalTarget={document.body}
              // className="form-select"
              aria-label="Default select example"
              options={companyIndustry}
              styles={customStyles}
              // defaultValue={this.state.probability}
              // name="probability"
              onChange={(e) => handleChangeCompanyType(e)}
            />
            {/*end::Input*/}
          </div>
          {/*end::Input group*/}
          <div className="mb-10 fv-row">
            {/*begin::Label*/}
            <label className="form-label fw-bolder text-dark fs-6">How many years of experience do you have with sales tools?</label>
            {/*end::Label*/}
            {/*begin::Input*/}
            {/* <select name="country" aria-label="Select a experience" data-control="select2" data-placeholder="Select a Experience..." className="form-select form-select-solid form-select-lg fw-bold"
              onChange={(e) => handleChangeExperience(e)} >
              <option selected disabled hidden>Choose an Option</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select> */}
            <Select
              menuPortalTarget={document.body}
              // className="form-select"
              aria-label="Default select example"
              options={experienceOptions}
              styles={customStyles}
              // defaultValue={this.state.probability}
              // name="probability"
              onChange={(e) => handleChangeExperience(e)}
            />
            {/*end::Input*/}
          </div>

          {/*begin::Submit*/}
          <div className="d-flex align-items-center justify-content-around">
            {/* <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={Back}>
              <span className="indicator-label">Back</span>
              <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
            </button> */}

            {/* <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder" onClick={CloseButton}>
              <span className="indicator-label">Skip</span>
              <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
            </button> */}

            <button type="button" id="kt_sing_in_two_steps_submit" className="w-30 btn btn-lg btn-primary fw-bolder" onClick={submit}>
              <span className="indicator-label">Submit</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
          {/*end::Submit*/}
        </div>
        {/*end::Wrapper*/}
      </div>
    </>
  );
}
