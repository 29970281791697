import React, { useState, useEffect } from "react";
import FormInput from "../../../../components/Form-Field/index";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import NormalTable from "../../../../components/normal-table/index";
import { useDispatch } from "react-redux";
import * as actions from "../../actions/settings.actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import CustomAction from "../../../../components/ActionColumn/index";
import * as ActivityLossReasonActions from "../../../Activities/store/actions/activity_loss_reasons_actions";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { DeleteModal } from "../../../../components/modal/index";
import { TabWithActions } from "../../../../components/Tabs/tab-with-actions";
function ButtonHeader(props) {
  const dispatch = useDispatch();

  const [Reason, setReason] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setReason("");
  };
  const handleShow = () => setShow(true);
  const lostReasonStatus = useSelector((store) => store.activity.lossReasons.settings.enabled);

  function handleChange(event) {
    setReason(event.target.value);
  }

  const handleLostReason = () => {
    if (!Reason) {
      dispatchSnackbarError("Please enter the Activity Outcome ", "error");
    } else {
      let addLostKeyObj = {
        type: "ACTIVITY",
        reason: Reason,
      };
      dispatch(
        actions.postLostReasonRequest(addLostKeyObj, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Activity Outcome Successfully Added", "success");
            dispatch(
              actions.getLostReasonRequest((data) => {
                let dummydata = [];
                for (let i = 0; i < data.data.length; i++) {
                  if (data.data[i].type === "ACTIVITY") {
                    dummydata.push(data.data[i]);
                  }
                }
                // setlostReasonTable(dummydata)
                // props.set(data.data)
                props.set(dummydata);
              }),
            );
            setShow(false);
            setReason("");
          }
        }),
      );
    }
  };

  const onLostReasonStatusChange = (e) => {
    dispatch(ActivityLossReasonActions.updateActivityLossReasonsSettingsRequest({ isActivityLostReasonEnabled: e.target.checked }));
  };

  return (
    <>
      <div className="d-flex flex-stack w-320px">
        <div className="d-flex gap-2 align-items-center">
          <div>Outcome Popup</div>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Activity Outcomes Enable/Disable</Tooltip>}>
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input h-20px w-35px"
                type="checkbox"
                onChange={(e) => {
                  onLostReasonStatusChange(e);
                }}
                checked={lostReasonStatus}
                name="ActDeact"
              />
            </div>
          </OverlayTrigger>
        </div>
        <div id="kt_signin_password_button" className="ms-auto" onClick={handleShow}>
          <button className="commonStyle btn btn-sm btn-primary my-1">+ Activity Outcome</button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Activity Outcome</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-16px">Activity Outcome* </span>
            <FormInput placeholder={"Enter activity outcome"} type={"text"} name={"lost"} value={Reason} onChange={(e) => handleChange(e)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => handleLostReason()}>
            Add Activity Outcome
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function LostReason() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [Reason, setReason] = useState("");
  const [LostReasonId, setLostReasonId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedData, setDeletedData] = useState();
  const handleClose = () => setShow(false);

  const [lostReasonTable, setlostReasonTable] = useState([]);

  const links = [
    { name: "Activity", to: "/settings/account/lost-reason/activity" },
    { name: "Deal", to: "/settings/account/lost-reason/deal" },
    { name: "Lead", to: "/settings/account/lost-reason/lead" },
    { name: "Marketing", to: "/settings/account/lost-reason/marketing" },
  ];

  const tabName = "Activity";

  useEffect(() => {
    setLoader(true);
    dispatch(
      actions.getLostReasonRequest((data) => {
        let dummydata = [];
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].type === "ACTIVITY") {
            dummydata.push(data.data[i]);
          }
        }
        setlostReasonTable(dummydata);
        setLoader(false);
      }),
    );
    dispatch(ActivityLossReasonActions.getActivityLossReasonsSettingsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);
  /*****
   * Edit Api
   * ************/
  function handleChange(event) {
    setReason(event.target.value);
  }

  const EditLostReason = (user) => {
    setShow(true);
    setReason(user.reason);
    setLostReasonId(user._id);
  };

  const handleLostReason = () => {
    if (!Reason) {
      dispatchSnackbarError("Activity outcome should not be empty!");
    } else {
      let editObj = {
        reason: Reason,
      };
      dispatch(
        actions.patchLostReasonRequest(editObj, LostReasonId, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Activity Outcome Successfully Updated", "success");

            dispatch(
              actions.getLostReasonRequest((data) => {
                let dummydata = [];
                for (let i = 0; i < data.data.length; i++) {
                  if (data.data[i].type === "ACTIVITY") {
                    dummydata.push(data.data[i]);
                  }
                }
                setlostReasonTable(dummydata);
                // setlostReasonTable(data.data)
              }),
            );

            setShow(false);
          }
        }),
      );
    }
  };

  /****
   * delete Api
   * ********/
  const DeleteManage = (data) => {
    setShowDeleteModal(true);
    setDeletedData(data);
    // dispatch(actions.deleteManageUserRequest(data._id, (data) => {
    //   if (data['success']) {
    //     dispatchSnackbarSuccess("Manageuser Successfully Deleted", "success")
    //     dispatch(actions.getManageUserRequest((data) => {
    //       setComments(data.data)
    //     }))
    //   }
    // }))
  };
  const deleteTemplateModalProps = {
    show: showDeleteModal,
    title: "Delete Activity Outcome",
    handleClose: () => {
      setShowDeleteModal(false);
      setDeletedData({});
    },
    handleAccept: () => deleteLostReason(),
  };
  const deleteLostReason = () => {
    dispatch(
      actions.deleteLostReasonRequest(deletedData._id, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Activity Outcome Successfully Deleted", "success");
          dispatch(
            actions.getLostReasonRequest((data) => {
              let dummydata = [];
              for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].type === "ACTIVITY") {
                  dummydata.push(data.data[i]);
                }
              }
              setDeletedData({});
              setShowDeleteModal(false);
              setlostReasonTable(dummydata);
              // setlostReasonTable(data.data)
            }),
          );
        }
      }),
    );
  };

  return (
    <>
      <TabWithActions links={links} active={tabName} actions={<ButtonHeader set={setlostReasonTable} />} />
      <NormalTable
        tableHeading={["Type", "", "Activity Outcomes", "", "Action "]}
        tableTitle={"Activity Outcomes"}
        description={"If activity outcome popup is enabled, users will see these reasons while completing an activity, which helps you to analyze performance"}
      >
        {!loader ? (
          lostReasonTable.length > 0 ? (
            lostReasonTable.map((user, id) => {
              return (
                // user.type === 'ACTIVITY' ?
                <tr key={id}>
                  <td>{user.type}</td>
                  <td></td>
                  <td>{user.reason}</td>
                  <td></td>
                  <td className="d-flex w-150px">
                    <CustomAction Edit={() => EditLostReason(user)} Delete={() => DeleteManage(user)} />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                <h6>No Data To Show</h6>
              </td>
            </tr>
          )
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        )}
      </NormalTable>
      <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete Activity outcome ?</DeleteModal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Activity Outcome</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-16px">Activity Outcome*</span>
            <FormInput placeholder={"Enter activity outcome"} type={"text"} name={"lost"} value={Reason} onChange={(e) => handleChange(e)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => handleLostReason()}>
            Edit Activity Outcome
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
