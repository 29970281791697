import {
  GET_INTEGRATIONS_DETAILS_REQUEST,
  GET_INTEGRATIONS_DETAILS_SUCCESS,
  GET_INTEGRATIONS_DETAILS_ERROR,
  DELETE_INTEGRATION_REQUEST,
  DELETE_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_ERROR,
  GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST,
  GET_INTEGRATION_ACCOUNT_DETAILS_SUCCESS,
  GET_INTEGRATION_ACCOUNT_DETAILS_ERROR,
  GET_INTEGRATION_AUTH_URL_REQUEST,
  GET_INTEGRATION_AUTH_URL_SUCCESS,
  GET_INTEGRATION_AUTH_URL_ERROR,
  GET_INTEGRATION_CALLBACK_REQUEST,
  GET_INTEGRATION_CALLBACK_SUCCESS,
  GET_INTEGRATION_CALLBACK_ERROR,
} from "../types/integrations.types";

const initialState = {
  integrationsDetails: { loading: true, data: [] },
  integrationAccountDetails: {},
  integrationUrl: {},
  callbackData: {},
};

export function IntegrationSettingsReducer(state = { initialState }, action = {}) {
  switch (action.type) {
    //integration call
    case GET_INTEGRATION_CALLBACK_REQUEST:
      return {
        ...state,
      };
    case GET_INTEGRATION_CALLBACK_SUCCESS:
      return {
        ...state,
        callbackData: action.payload,
      };
    case GET_INTEGRATION_CALLBACK_ERROR:
      return {
        ...state,
      };
    //integration url
    case GET_INTEGRATION_AUTH_URL_REQUEST:
      return {
        ...state,
      };
    case GET_INTEGRATION_AUTH_URL_SUCCESS:
      return {
        ...state,
        integrationUrl: action.payload,
      };
    case GET_INTEGRATION_AUTH_URL_ERROR:
      return {
        ...state,
      };
    //integration account details
    case GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        integrationAccountDetails: {},
      };
    case GET_INTEGRATION_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        integrationAccountDetails: action.payload,
      };
    case GET_INTEGRATION_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
      };
    //all integrations data
    case GET_INTEGRATIONS_DETAILS_REQUEST:
      return {
        ...state,
        integrationsDetails: { loading: true, data: [] },
      };
    case GET_INTEGRATIONS_DETAILS_SUCCESS:
      return {
        ...state,
        integrationsDetails: { loading: false, data: action.payload },
      };
    case GET_INTEGRATIONS_DETAILS_ERROR:
      return {
        ...state,
      };
    //delete integration
    case DELETE_INTEGRATION_REQUEST:
      return {
        ...state,
      };
    case DELETE_INTEGRATION_SUCCESS:
      return {
        ...state,
      };
    case DELETE_INTEGRATION_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
