import React, { useState, useEffect, useCallback } from "react";
import { EditableTable } from "../../components/table";
import { Dropdown, Spinner, Form, Button } from "react-bootstrap";
import { ColumnFilter } from "../../components/column_filter";
import { DeleteModal, ConvertModal } from "../../components/modal";
import SmartViewFilter from "../../components/filter/smart-view-filter";
import OwnerFilter from "../../components/filter/owner-filter";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton } from "../../components/button";
import TagSelect from "../../components/custom_form/tags";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "../../components/custom_form";
import BulkEditForm from "../../components/custom_form/bulkEdit";
// import { SubheaderNav } from "../../components/subheader/subheaderNav";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { MergeModal } from "../../components/merge";
import { AddDealModal } from "../deals/common/dealModal/index";
import { useHistory, useParams } from "react-router-dom";
import "./styles.css";
import _ from "lodash";
import ContentWrapper from "../Wrapper";
import SidePannelDetailed from "../../components/side-pannel-detailed";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { PrimarySimpleDropdown, SecondarySimpleDropdown } from "../../components/dropdowns/simple-dropdown";
import { DeleteIconButton } from "../../components/button/icon-action-buttons";
import { SecondaryButton } from "../../components/button/common-buttons";

//actions
import * as actions from "./store/actions/actions";
import * as LeadActions from "./store/actions/actions";
import * as LeadOperationAction from "./store/actions/lead-operations.actions";
import { ownersRequest } from "../customers/store/actions/actions";
import * as CompanyActions from "../companies/store/actions/actions";
import * as ProductActions from "../products/store/actions/actions";
import * as insightActions from "../insights/actions";
import * as TemplateActions from "../campaign/store/actions/campaign_templates_actions";
import { getPipelineRequest, addDealRequest } from "../deals/dealPipeline/actions";
import { getListOfActiveActivityTypesRequest } from "../Activities/store/actions/activity_types_actions";
import OptionsDropdown from "../../components/filter/import-export-dropdown";
import SelectAll from "../../components/filter/select-all";
import AdvancedSubHeaderNav from "../../components/subheader/advancedSubHeaderNav";
import EmailValidationModel from "../../components/popup-forms/email-validation";
import SubscribeToCadenceModal from "../../components/popup-forms/subscribe-to-cadence";
import EmailFinderModel from "../../components/popup-forms/email-finder";

export default function ActiveLeads(props) {
  const sidePanelType = "active_lead";
  const links = [
    {
      name: "Active Leads",
      to: "/prospects/leads/active",
      type: "split-dropdown",
      icon: "bi bi-bullseye",
      options: [
        { name: "Active Leads", to: "/prospects/leads/active", target: "_self", icon: "bi bi-bullseye" },
        { name: "Junk Leads", to: "/prospects/leads/junk", target: "_self", icon: "bi bi-recycle" },
      ],
      subTabNames: ["Active Leads", "Junk Leads"],
    },
    { name: "People", to: "/prospects/people", icon: "bi bi-person", type: "text" },
    { name: "Companies", to: "/prospects/companies", icon: "bi bi-building", type: "text" },
  ];
  const tabName = "Active Leads";
  const tabIcon = "bi bi-bullseye";
  const [showColumns, setShowColumns] = useState([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [checked, setChecked] = useState([]);
  const [leadName, setLeadName] = useState({});
  const dispatch = useDispatch();
  const popupStore = useSelector((state) => state.Leads.leadPopup);
  const fieldStore = useSelector((state) => state.Leads.leadFields);
  const addFieldStore = useSelector((state) => state.Leads.leadAddFields);
  const filterStore = useSelector((state) => state.Leads.leadFilters);
  const activeFilterStore = useSelector((state) => state.Leads.leadFilters.activeFilter);
  const listStore = useSelector((state) => state.Leads.leadList);
  const errorStore = useSelector((state) => state.Leads.error);
  const successStore = useSelector((state) => state.Leads.success);
  const loadingStore = useSelector((state) => state.Leads.loadingData);
  const lostReasons = useSelector((state) => state.Leads.lostReasons);
  const [addFormData, setAddFormData] = useState({});
  const [bulkEditData, setBulkEditData] = useState({});
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const page = "Leads";
  const pageSub = "lead";
  const leadType = "active";
  const [showDelete, setShowDelete] = useState(false);
  const [showConvert, setShowConvert] = useState(false);
  const [showConvertPeople, setShowConvertPeople] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "lead",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
    pattern: "",
  });
  const [showMergeModal, setShowMergeModal] = useState(false);

  const [openAddDealModal, setOpenAddDealModal] = useState(false);
  const pipelineStore = useSelector((store) => store.DealPipeline);
  const dealTagStore = useSelector((state) => state.DealPipeline.dealTags.list);
  const [selectedLostReason, setSelectedLostReason] = useState("");
  const [selectedLostReasonId, setSelectedLostReasonId] = useState("");
  const [lossLeadComment, setLossLeadComment] = useState("");
  const [sortConfig, setSortConfig] = useState(null);
  const [activeLeadId, setActiveLeadId] = useState("");
  const [isPopupLeadToDeal, setIsPopupLeadToDeal] = useState(false);

  //sidepannel states
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePannelLeadId, setSidePannelLeadId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [linkedModuleId, setLinkedModuleId] = useState("");
  const [linkedModuleName, setLinkedModuleName] = useState("");

  //filters
  const [virtualFilter, setVirtualFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");
  const [filterId, setFilterId] = useState("");

  // delete all
  const [isAllSelected, setIsAllSelected] = useState(false);

  // email validation and email findr
  const [showEmailValidation, setShowEmailValidation] = useState(false);
  const [showEmailFinder, setShowEmailFinder] = useState(false);
  const [showSubscribeToCadence, setShowSubscribeToCadence] = useState(false);

  const history = useHistory();
  const params = useParams();

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  const filterPayload = useCallback(() => {
    const payload = {
      pageNo: pageNo,
      limit: limit,
      type: "active_lead",
      tagId: tags,
      owner: owner,
      filterId: filterId,
      ...sortKeys(),
    };
    if (!_.isEmpty(virtualFilter)) {
      payload["filter"] = JSON.stringify(virtualFilter);
    }
    return payload;
  }, [pageNo, limit, tags, owner, virtualFilter, filterId, sortKeys]);

  useEffect(() => {
    const payload = filterPayload();
    dispatch(actions.leadsRequest(payload));
    dispatch(actions.loadingDataUpdateRequest(payload));
  }, [dispatch, filterPayload]);

  useEffect(() => {
    dispatch(actions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(ownersRequest());
    dispatch(actions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(actions.leadAddFieldsRequest({ fieldType: "LEAD" }));
    dispatch(actions.leadFiltersRequest());
    dispatch(getPipelineRequest());
    dispatch(actions.getLeadLostReasonsRequest());
    dispatch(getListOfActiveActivityTypesRequest());

    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    // common apis for all module
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));

    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!listStore.loading && listStore.list.length === 0 && listStore.count > 0) {
      setPageNo(1);
    }
  }, [listStore.loading, listStore.list, listStore.count, limit]);

  useEffect(() => {
    if (listStore.list) {
      const _data = listStore.list.map((each) => {
        return { module: "lead", id: each._id };
      });
      setNextPreviousData(_data);
    }
  }, [listStore.list]);

  useEffect(() => {
    if (params.linkedId && params.moduleName) {
      setLinkedModuleId(params.linkedId);
      setLinkedModuleName(params.moduleName);
      setSidePannelLeadId("");
      setOpenPanel(true);
    } else if (params.leadId) {
      setSidePannelLeadId(params.leadId);
      setActiveLeadId(params.leadId);
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(true);
    } else {
      setSidePannelLeadId("");
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("leadOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("leadOwner", owner);
    else localStorage.removeItem("leadOwner");
  }, [owner]);

  const updateFieldPriority = (data) => {
    dispatch(actions.fieldUpdatePriorityRequest({ ...data, fieldType: "LEAD" }));
  };

  const showHideField = (data) => {
    dispatch(actions.fieldShowHideRequest(data));
  };

  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
    dispatch(actions.clearFilter());
  };

  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["selectedColumns"] = showColumns;
      dispatch(
        actions.leadFilterAddRequest(temp, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    } else {
      dispatch(
        actions.leadFilterAddRequest(addFilterFormData, (data) => {
          if (data.status && data.id) applyFilter(data["id"]);
        }),
      );
    }
    setAddFilterFormData({
      filterModule: "lead",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
      temp["selectedColumns"] = showColumns;
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    if (temp["id"] && activeFilterStore && filterId === temp["id"]) dispatch(actions.clearFilter());
    dispatch(
      actions.leadFilterEditRequest(temp, (data) => {
        if (data.status && temp.id === filterId) applyFilter(filterId);
      }),
    );
    setAddFilterFormData({
      filterModule: "lead",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const favoriteFilter = (data) => {
    dispatch(actions.leadFilterFavoriteRequest(data));
  };

  const deleteFilter = (id) => {
    if (activeFilterStore && filterId === id) dispatch(actions.clearFilter());
    dispatch(actions.leadFilterDeleteRequest(id));
    setAddFilterFormData({
      filterModule: "lead",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
      pattern: "",
    });
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
  };
  const addTag = (name, colorCode) => {
    dispatch(actions.leadTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(actions.leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(actions.leadTagsDeleteRequest(id));
  };

  const handleEditLead = (combinedId, data) => {
    dispatch(actions.leadCellEditRequest({ editData: data, loadingData: loadingStore, cellId: combinedId }));
  };

  const handleAddLead = (temp) => {
    dispatch(actions.leadAddRequest({ leadData: temp, loadingData: loadingStore }));
    setShowAdd(false);
    // setAddFormData({})
  };

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  const handleBulkEdit = (data) => {
    if (isAllSelected) {
      const payload = {
        leadData: {
          updateData: data,
          ids: checked,
          isAllSelected: true,
          filter: loadingStore,
        },
        loadingData: loadingStore,
      };
      dispatch(LeadOperationAction.bulkEditLeadV2Request(payload));
      setShowBulkEdit(false);
      setBulkEditData({});
      setChecked([]);
      setIsAllSelected(false);
    } else {
      dispatch(actions.leadBulkEditRequest({ leadData: { ...data, ids: checked }, loadingData: loadingStore }));
      setShowBulkEdit(false);
      setBulkEditData({});
    }
  };

  const handleDeleteLeads = () => {
    if (params.leadId) {
      dispatch(actions.leadDeleteRequest({ leadIds: { ids: [params.leadId] }, loadingData: loadingStore }));
    } else if (isAllSelected && checked.length > 0) {
      dispatch(LeadOperationAction.bulkDeleteLeadV2Request({ leadIds: { ids: checked, filter: loadingStore, isAllSelected: true }, loadingData: loadingStore }));
      setChecked([]);
    } else {
      dispatch(actions.leadDeleteRequest({ leadIds: { ids: checked }, loadingData: loadingStore }));
      setChecked([]);
    }
    setShowDelete(false);
    setOpenPanel(false);
    setSidePannelLeadId("");
  };

  const handleConvert = () => {
    if (params.leadId) {
      if (!selectedLostReasonId) {
        dispatchSnackbarError("please select lead lost reason");
        setShowConvert(true);
      } else {
        dispatch(actions.convertToJunkRequest({ id: { id: params.leadId, reasonId: selectedLostReasonId, comment: lossLeadComment }, loadingData: loadingStore }));
        setShowConvert(false);
        history.push(`/prospects/leads/active`);
      }
    } else {
      if (!selectedLostReasonId) {
        dispatchSnackbarError("please select lead lost reason");
        setShowConvert(true);
      } else {
        dispatch(actions.convertToJunkRequest({ id: { id: checked[0], reasonId: selectedLostReasonId, comment: lossLeadComment }, loadingData: loadingStore }));
        setChecked([]);
        setShowConvert(false);
        setSelectedLostReason("");
        setSelectedLostReasonId("");
        setLossLeadComment("");
      }
    }

    setOpenPanel(false);
    setSidePannelLeadId("");
  };
  const handleConvertPeople = () => {
    if (params.leadId) {
      dispatch(actions.convertToPeopleRequest({ id: { id: params.leadId, reasonId: selectedLostReasonId, comment: lossLeadComment }, loadingData: loadingStore }));
      setShowConvertPeople(false);
      history.push(`/prospects/leads/active`);
    } else {
      dispatch(actions.convertToPeopleRequest({ id: { id: checked[0], reasonId: selectedLostReasonId, comment: lossLeadComment }, loadingData: loadingStore }));
      setChecked([]);
      setShowConvertPeople(false);
    }
    setOpenPanel(false);
    setSidePannelLeadId("");
  };

  const handleMerge = (merged) => {
    dispatch(actions.leadsMergeRequest({ data: merged, loadingData: loadingStore }));
    setChecked([]);
  };

  const tableProps = {
    columns: fieldStore.list,
    data: listStore.list,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: (a) => {
      setChecked(a);
      setIsAllSelected(false);
    },
    leadName: leadName,
    setLeadName: setLeadName,
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    idToIndex: fieldStore.idToIndex,
    handleCellEdit: handleEditLead,
    page: page,
    pageSub: pageSub,
    cellLoading: listStore.cellLoading,
    sidePanelProps: popupStore.data,
    popupLoading: popupStore.loading,
    popupCellLoading: popupStore.cellLoading,
    convertToJunk: () => setShowConvert(true),
    convertToDeal: () => {
      setIsPopupLeadToDeal(true);
      onConvertToDealClick();
    },
    sidePanelType: sidePanelType,
    handleDelete: () => setShowDelete(true),
    loading: listStore.loading,
    leadType: leadType,

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    //for filter
    filterPayload: loadingStore,

    //side pannel
    sidePannelSelectedId: sidePannelLeadId,

    // selection
    isAllListSelected: isAllSelected,
  };

  const deleteModalProps = {
    show: showDelete,
    title: `Delete ${checked.length > 1 ? "leads" : "lead"}`,
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeleteLeads,
  };

  const convertModalProps = {
    show: showConvert,
    title: "Convert To Junk",
    handleClose: () => {
      setShowConvert(false);
      setSelectedLostReasonId("");
      setSelectedLostReason("");
    },
    handleAccept: handleConvert,
  };

  const convertPeopleModalProps = {
    show: showConvertPeople,
    title: "Convert To People",
    handleClose: () => {
      setShowConvertPeople(false);
    },
    handleAccept: handleConvertPeople,
  };

  useEffect(() => {
    let tempCol = [];
    var tempFieldStore = fieldStore.list;
    tempFieldStore.sort(function (a, b) {
      return a.tablePriority - b.tablePriority;
    });
    for (let i = 0; i < tempFieldStore.length; i++) if (tempFieldStore[i].isEnabledForTable !== false) tempCol.push(tempFieldStore[i]["_id"]);
    setShowColumns(tempCol);
  }, [fieldStore]);

  const onConvertToDealClick = () => {
    setOpenAddDealModal(true);
  };

  const onAddDealModalClose = () => {
    setOpenAddDealModal(false);
    setIsPopupLeadToDeal(false);
  };

  const onAddDealModalSave = (data) => {
    dispatch(addDealRequest({ data }));
    setOpenAddDealModal(false);
    setChecked([]);
    setOpenPanel(false);
    setSidePannelLeadId("");
    history.push("/prospects/leads/active");
  };

  const getPipelineOptions = () => {
    let data = pipelineStore.pipelineOptionsResponse;
    let pipelineArray = [];
    data && data.length > 0 && data.map((pipeline) => pipelineArray.push({ name: pipeline.name, id: pipeline._id }));
    return pipelineArray;
  };

  const onLostReasonChange = (reason) => {
    setSelectedLostReason(reason.reason);
    setSelectedLostReasonId(reason._id);
  };

  const onLostReasonCommentsChange = (e) => {
    setLossLeadComment(e.target.value);
  };

  const handlePanelClose = () => {
    history.push(`/prospects/leads/active`);
    setOpenPanel(false);
  };

  const handleConvertDropdownClick = (value) => {
    if (value.value === "toPeople") {
      setShowConvertPeople(true);
    } else if (value.value === "toDeal") {
      onConvertToDealClick();
    } else if (value.value === "toJunk") {
      setShowConvert(true);
    }
  };

  const handleActionDropdownClick = (value) => {
    if (value.value === "emailValidation") {
      setShowEmailValidation(true);
    } else if (value.value === "emailFinder") {
      setShowEmailFinder(true);
    } else if (value.value === "subscribeToCadence") {
      setShowSubscribeToCadence(true);
    }
  }

  return (
    <>
      {fieldStore.loading || (listStore.loading && listStore.count === 0) ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <>
          <CustomForm
            show={showAdd}
            setShow={setShowAdd}
            handleAdd={handleAddLead}
            addTag={addTag}
            editTag={editTag}
            deleteTag={deleteTag}
            page={page}
            pageSub={pageSub}
            fields={addFieldStore.list}
            customFormData={addFormData}
            setCustomFormData={setAddFormData}
            fieldsLoading={addFieldStore.loading}
            cacheCompany={true}
          />
          {!listStore.loading && listStore.count === 0 && _.isEmpty(tags) && _.isEmpty(owner) && _.isEmpty(virtualFilter) && _.isEmpty(filterId) ? (
            <ContentWrapper
              subHeader={
                <CustomSubHeader>
                  <div className="d-flex flex-row flex-stack w-100">
                    <div>
                      <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                    </div>
                    <div className="d-flex flex-row align-items-center gap-12px">
                      <BlueGradientButton onClick={() => setShowAdd(true)} variant="success" size="sm">
                        + Lead
                      </BlueGradientButton>
                      <NavLink to="/import/lead">
                        <Button variant="secondary" size="sm">
                          Import Leads
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </CustomSubHeader>
              }
            >
              <CustomEmptyPage page="lead" setShowAdd={setShowAdd} />
            </ContentWrapper>
          ) : (
            <>
              <MergeModal
                show={showMergeModal}
                handleMerge={handleMerge}
                setShow={setShowMergeModal}
                type={page}
                pageSub={pageSub}
                typeSub="Lead"
                fields={fieldStore.list}
                items={checked.map((item) => listStore.list.find((listItem) => listItem["_id"] === item))}
              />
              <ContentWrapper
                subHeader={
                  <CustomSubHeader highIndex={true}>
                    <div className="d-flex flex-row flex-stack w-100">
                      <div>
                        <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                      </div>
                      <ConvertModal modalProps={convertModalProps}>
                        <Form.Group id="leads-active-leads-convert-to-junk-confirmation-question" required>
                          Are you sure you want to convert to junk?
                        </Form.Group>
                        <Form.Group id="leads-active-leads-lost-reason-selection" required>
                          <Form.Label aria-label="select-lost-reason">Select Lost Reason</Form.Label>
                          <Dropdown className="dropdown-inline mt-1 w-100">
                            <Dropdown.Toggle className="border-width-1px border-style-solid border-color-e4e6ef w-100 text-left py-0-point-65rem px-1-point-1rem" variant="transparent">
                              {selectedLostReason}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu w-100-mn">
                              {lostReasons &&
                                lostReasons.length > 0 &&
                                lostReasons.map((lostReason, index) => (
                                  <Dropdown.Item key={index} className="dropdown-hover" onClick={() => onLostReasonChange(lostReason)}>
                                    {lostReason.reason}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                        <div className="mb-3">
                          <label for="exampleFormControlTextarea1" className="form-label">
                            Comments
                          </label>
                          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => onLostReasonCommentsChange(e)}></textarea>
                        </div>
                      </ConvertModal>
                      <ConvertModal modalProps={convertPeopleModalProps}>Are you sure you want to convert to people?</ConvertModal>
                      <DeleteModal modalProps={deleteModalProps}>
                        Are you sure you want to delete the {checked.length > 1 ? "leads" : "lead"}?
                        {isAllSelected && (
                          <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                            In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be
                            call or activated.
                          </span>
                        )}
                      </DeleteModal>
                      <div>
                        {checked.length !== 0 ? (
                          <div className="d-flex flex-row align-items-center gap-12px">
                            <SelectAll
                              checked={checked}
                              limit={limit}
                              count={listStore.count}
                              isAllSelected={isAllSelected}
                              selectAllClick={() => {
                                setIsAllSelected(true);
                                setChecked(listStore.list.map((each) => each._id));
                              }}
                              clearAllClick={() => {
                                setIsAllSelected(false);
                                setChecked([]);
                              }}
                            />
                            {checked.length === 1 && (
                              <PrimarySimpleDropdown
                                value={{}}
                                label={"Convert"}
                                options={[
                                  { value: "toDeal", label: "Convert To Deal" },
                                  { value: "toJunk", label: "Convert To Junk" },
                                  { value: "toPeople", label: "Convert To People" },
                                ]}
                                handleSelect={handleConvertDropdownClick}
                                disabled={false}
                              />
                            )}
                            <BulkEditForm
                              isAllListSelected={isAllSelected}
                              handleBulkEdit={handleBulkEdit}
                              noOfRows={isAllSelected ? listStore.count : checked.length}
                              showColumns={showColumns}
                              show={showBulkEdit}
                              setShow={setShowBulkEdit}
                              addTag={addTag}
                              editTag={editTag}
                              deleteTag={deleteTag}
                              page={page}
                              pageSub={pageSub}
                              fields={fieldStore.list}
                              customFormData={bulkEditData}
                              setCustomFormData={setBulkEditData}
                            />
                            {checked.length === 2 ? (
                              <BlueGradientButton size="sm" onClick={() => setShowMergeModal(true)}>
                                Merge
                              </BlueGradientButton>
                            ) : null}
                            {
                              checked.length > 0 && (
                                <SecondarySimpleDropdown
                                  value={{}}
                                  label={"Actions"}
                                  options={[
                                    { value: "subscribeToCadence", label: "Subscribe To Cadence" },
                                    { value: "emailValidation", label: "Email Validation" },
                                    { value: "emailFinder", label: "Email Finder" },
                                  ]}
                                  handleSelect={handleActionDropdownClick}
                                  disabled={false}
                                />
                              )
                            }
                            <SecondaryButton onClick={() => setShowBulkEdit(true)}>Bulk Edit</SecondaryButton>
                            <DeleteIconButton onClick={() => setShowDelete(true)} />
                            <ColumnFilter
                              fieldType={"LEAD"}
                              showHideField={showHideField}
                              updateFieldPriority={updateFieldPriority}
                              idToIndex={fieldStore.idToIndex}
                              columns={fieldStore.list}
                              showColumns={showColumns}
                              setShowColumns={setShowColumns}
                            />
                          </div>
                        ) : (
                          <div className="d-flex flex-row align-items-center gap-12px">
                            <BlueGradientButton onClick={() => setShowAdd(true)} variant="success" size="sm">
                              + Lead
                            </BlueGradientButton>

                            <SmartViewFilter
                              pageSub={pageSub}
                              page={page}
                              activeFilter={filterStore.activeFilter}
                              customFilters={filterStore.list}
                              loading={filterStore.loading}
                              setAddFilterFormData={setAddFilterFormData}
                              virtualFilter={virtualFilter}
                              applyVirtualFilter={applyVirtualFilter}
                              selectedFilterId={filterId}
                              favoriteFilter={favoriteFilter}
                              applyFilter={applyFilter}
                              addFilterFormData={addFilterFormData}
                              addFilter={addFilter}
                              editFilter={editFilter}
                              deleteFilter={deleteFilter}
                            />
                            <div>
                              <TagSelect
                                page={page}
                                pageSub={pageSub}
                                addTag={addTag}
                                editTag={editTag}
                                deleteTag={deleteTag}
                                field={{ fieldName: "Tags", apiKeyName: "tags" }}
                                value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                                setValue={(value) => setValue(value)}
                                hideAddText={true}
                                isUsedAsFilter={true}
                                iconBasedFilter={true}
                              />
                            </div>
                            <OwnerFilter setValue={setOwner} value={owner} />
                            <OptionsDropdown
                              dataType={"lead"}
                              importLabel={"Import Leads"}
                              exportLabel={"Export Leads"}
                              showColumns={showColumns}
                              showExportModal={showExportModal}
                              activeFilter={activeFilterStore}
                              appliedFilter={loadingStore}
                              setShowExportModal={setShowExportModal}
                            />
                            <ColumnFilter
                              fieldType={"LEAD"}
                              showHideField={showHideField}
                              updateFieldPriority={updateFieldPriority}
                              idToIndex={fieldStore.idToIndex}
                              columns={fieldStore.list}
                              showColumns={showColumns}
                              setShowColumns={setShowColumns}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </CustomSubHeader>
                }
              >
                <div className={`card card-custom`}>
                  <div className="card-body py-3 px-3">
                    <EditableTable props={tableProps} />
                  </div>
                </div>
                <AddDealModal
                  dealModalProps={{
                    leadId: checked[0] || activeLeadId,
                    openAddDealModal: openAddDealModal,
                    onAddDealModalClose: () => onAddDealModalClose(),
                    onAddDealModalSave: (data) => onAddDealModalSave(data),
                    pipelinedropdownOptions: getPipelineOptions(),
                    pipelineswithstages: pipelineStore.pipelineOptionsResponse,
                    selectedPipeline: pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0].name : "",
                    selectedPipelineId: pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0]._id : "",
                    items: listStore.list.find((listItem) => listItem["_id"] === checked[0]),
                    tags: dealTagStore,
                    from: "leads",
                    popupStore: popupStore.data,
                    isPopupLeadToDeal: isPopupLeadToDeal,
                  }}
                />
                <SidePannelDetailed
                  show={openPanel}
                  basePath={`/prospects/leads/active`}
                  onClose={handlePanelClose}
                  moduleName={linkedModuleName ? linkedModuleName : "lead"}
                  moduleId={linkedModuleId ? linkedModuleId : sidePannelLeadId}
                  nextPreviousData={linkedModuleId ? [] : nextPreviousData}
                  pageForm={"activeLead"}
                  filter={loadingStore}
                  convertToJunk={() => setShowConvert(true)}
                  convertToDeal={() => {
                    setIsPopupLeadToDeal(true);
                    onConvertToDealClick();
                  }}
                  handleDelete={() => setShowDelete(true)}
                  handleConvertToPeople={() => setShowConvertPeople(true)}
                />
                <EmailValidationModel
                  show={showEmailValidation}
                  setShow={setShowEmailValidation}
                  isAllSelected={isAllSelected}
                  moduleName="lead"
                  moduleIds={checked}
                  loadingStore={loadingStore}
                  onSuccess={() => {
                    setChecked([]);
                    setIsAllSelected(false);
                  }}
                />
                <EmailFinderModel
                  show={showEmailFinder}
                  setShow={setShowEmailFinder}
                  isAllSelected={isAllSelected}
                  moduleName="lead"
                  moduleIds={checked}
                  loadingStore={loadingStore}
                  onSuccess={() => {
                    setChecked([]);
                    setIsAllSelected(false);
                  }}
                />
                <SubscribeToCadenceModal
                  show={showSubscribeToCadence}
                  setShow={setShowSubscribeToCadence}
                  isAllSelected={isAllSelected}
                  moduleName="lead"
                  moduleIds={checked}
                  loadingStore={loadingStore}
                  onSuccess={() => {
                    setChecked([]);
                    setIsAllSelected(false);
                  }}
                />
              </ContentWrapper>
            </>
          )}
        </>
      )}
    </>
  );
}
