import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ThreeDotHorizontalSVG } from "../../../custom-svgs";
import { isSameModuleAsUrl } from "../../components/helper";

const actions = [
  { title: "Convert to Deal", key: "convertToDeal", when: ["active_lead"] },
  { title: "Convert to Junk Lead", key: "convertToJunk", when: ["active_lead"] },
  { title: "Convert to People", key: "convertToPeople", when: ["active_lead"] },
  { title: "Convert to Active Lead", key: "convertToActive", when: ["junk_lead"] },
  { title: "Archive Deal", key: "archiveDeal", when: ["deal"] },
  { title: "Convert to Lead", key: "convertToLead", when: ["people", "deal"] },
  { title: "Delete", key: "delete", when: ["active_lead", "junk_lead", "people", "deal", "company", "marketing"] },
];

export default function TitleHead(props) {
  const [hideArchive, setHideArchive] = useState(false);

  const { lastContacted } = useSelector((state) => state.notes.upcomingActivity);

  useEffect(() => {
    if (props.sidePanelProps && props.sidePanelProps.deal && props.sidePanelProps.deal.status) {
      const status = props.sidePanelProps.deal.status;
      if (status === "archived" || status === "won" || status === "lost") setHideArchive(true);
      else setHideArchive(false);
    } else setHideArchive(false);
  }, [props.sidePanelProps]);

  const handleActionsClick = (key) => {
    if (key === "convertToJunk") {
      props.convertToJunk();
    } else if (key === "convertToDeal") {
      props.convertToDeal();
    } else if (key === "convertToLead") {
      props.convertToLead();
    } else if (key === "convertToActive") {
      props.convertToActive();
    } else if (key === "delete") {
      props.handleDelete();
    } else if (key === "archiveDeal") {
      props.handleArchiveDeal();
    } else if (key === "convertToPeople") {
      props.handleConvertToPeople();
    }
  };

  return (
    <div className="d-flex p-8 flex-stack z-index-1000 position-sticky top-0 bg-f9f9f9">
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.title ? _.capitalize(props.title) : ""}</Tooltip>}>
        <div className="d-flex align-items-start flex-column w-100-60px gap-5px">
          <h4 className="w-100 overflow-hidden text-overflow-ellipsis text-nowrap m-0px line-height-1-point-15">
            {props.loading ? (
              <div className="w-100 d-flex align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : props.title ? (
              _.capitalize(props.title)
            ) : (
              ""
            )}
          </h4>
          <div className="d-flex gap-2">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.39486 13.3332C10.6032 13.3332 13.2282 10.7082 13.2282 7.49987C13.2282 4.29153 10.6032 1.66653 7.39486 1.66653C4.18652 1.66653 1.56152 4.29153 1.56152 7.49987C1.56152 10.7082 4.18652 13.3332 7.39486 13.3332Z"
                stroke="#9197B3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M4.91602 7.49989L6.56685 9.15073L9.87435 5.84906" stroke="#9197B3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span className="font-size-12px text-9197b3">{lastContacted ? `Contacted at ${moment(lastContacted).fromNow()}` : "Not contacted yet"}</span>
          </div>
        </div>
      </OverlayTrigger>
      <div className="title-head-wrapper">
        {isSameModuleAsUrl(props.basePath, props.moduleType) && !props.hideMenu && (
          <Dropdown className="dropdown-inline">
            <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle p-5px" variant="transparent" id="dropdown-toggle-top">
              {/* <i className="fas fa-ellipsis-h"></i> */}
              {ThreeDotHorizontalSVG}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm overflow-y-auto h-50vh-mx">
              {actions.map((each, index) => {
                if (hideArchive && each.key === "archiveDeal") {
                  return <div key={index}></div>;
                } else if (each.when.includes(props.sidePanelType))
                  return (
                    <Dropdown.Item onSelect={() => handleActionsClick(each.key)} className="dropdown-hover">
                      {each.title}
                    </Dropdown.Item>
                  );
                else return <div key={index}></div>;
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
}
