// import MainActionBar from 'modules/boards/containers/MainActionBar';
import { ScrolledContent, FixedContent, ViewDivider } from "./styled";

// import { __ } from 'modules/common/utils';
// import { menuDeal } from 'modules/common/utils/menus';
import Stages from "./stages";
// import Header from 'modules/layout/components/Header';
import * as React from "react";
// import DealMainActionBar from '../DealMainActionBar';
// import { DealContent, FixedContent, ViewDivider } from './style';

class ConversionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pipelineId: "1234rfggtg",
    };
  }
  render() {
    const { queryParams } = this.props;
    const pipelineId = this.state.pipelineId;

    return (
      //<BoardContainer>
      // {/* <Header title={__('Deal')} submenu={menuDeal} /> */}
      // {/* <DealContent transparent={true}> */}
      //   {/* <MainActionBar type="deal" component={DealMainActionBar} /> */}
      <ScrolledContent>
        <FixedContent>
          <Stages type="brief" pipelineId={pipelineId} queryParams={queryParams} />

          <ViewDivider />

          <Stages type="more" pipelineId={pipelineId} queryParams={queryParams} />
        </FixedContent>
      </ScrolledContent>
      //   {/* </DealContent> */}
      // {/* </BoardContainer> */}
    );
  }
}

export default ConversionView;
