import React, { useState } from "react";
import moment from "moment";
import { Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { MeetingDetailsFields, MeetingDetailsFieldsShowColoums, MeetingDetailsFieldsIdToIndex } from "./constants";
import { ViewMore } from "../side-pannel-detailed/custom-components/viewMore";
import * as ActivityListActions from "../../containers/Activities/store/actions/activitity_list_actions";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSnackbarError } from "../../utils/toaster";
import { IconButton2 } from "../button/icon-action-buttons";

export default function ActivityTitleDisplay(props) {
  const [show, setShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [rootClose, setRootClose] = useState(true);

  const ActivityType = props.allData["activityType"];
  const [cellEdit, setCellEdit] = useState({});

  const dispatch = useDispatch();

  const loadingStore = useSelector((store) => store.activity.list.loadingData);
  const activityListStore = useSelector((store) => store.activity.list);

  const handleCellEdit = (combinedId, data) => {
    if (data.scheduleAt && props.allData.scheduleAtEnd) {
      const startTime = moment(data.scheduleAt).format();
      const endTime = moment(props.allData.scheduleAtEnd).format();
      if (startTime > endTime) {
        return dispatchSnackbarError("Start Date cannot be grether than end date");
      }
      dispatch(ActivityListActions.editActivityRequest({ editData: data, loadingData: loadingStore, type: "edit", cellId: combinedId }));
    } else if (data.scheduleAtEnd && props.allData.scheduleAt) {
      const startTime = moment(props.allData.scheduleAt).format();
      const endTime = moment(data.scheduleAtEnd).format();
      if (startTime > endTime) {
        return dispatchSnackbarError("Start Date cannot be grether than end date");
      }
      dispatch(ActivityListActions.editActivityRequest({ editData: data, loadingData: loadingStore, type: "edit", cellId: combinedId, from: "activity-list" }));
    } else {
      dispatch(ActivityListActions.editActivityRequest({ editData: data, loadingData: loadingStore, type: "edit", cellId: combinedId, from: "activity-list" }));
    }
  };

  const onClosePopupClick = () => {
    setShow(false);
    setShowIcon(false);
    document.body.click();
  };

  const onSetEditingStatus = (status) => {
    if (status) {
      setRootClose(false);
    } else {
      setRootClose(true);
    }
  };

  const popoverForm = () => (
    <Popover className="w-300px z-index-100">
      <Popover.Title className="d-flex flex-stack align-items-center" as="h3">
        <span>Edit Meeting Details</span>
        <span title="close" className="cursor-pointer" onClick={() => onClosePopupClick()}>
          <i className="bi bi-x color-black"></i>
        </span>
      </Popover.Title>
      <Popover.Content>
        <ViewMore
          setEditingStatus={onSetEditingStatus}
          cellEdit={cellEdit}
          setCellEdit={setCellEdit}
          handleCellEdit={handleCellEdit}
          addTag={() => {}}
          editTag={() => {}}
          deleteTag={() => {}}
          page={"Activity"}
          pageSub={"activity"}
          showColumns={MeetingDetailsFieldsShowColoums}
          columns={MeetingDetailsFields}
          idToIndex={MeetingDetailsFieldsIdToIndex}
          viewing={props.allData || {}}
          cellLoading={activityListStore.cellLoading}
        />
      </Popover.Content>
    </Popover>
  );

  const handleClosePopover = () => {
    setShow(!show);
  };

  return (
    <div className="w-100">
      {!_.isEmpty(ActivityType) && ActivityType.symbName ? (
        ActivityType.name === "Meeting" ? (
          <>
            <div className="d-flex justify-content-between w-100" onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
                <div className={`data-container text-hover-primary text-primary cursor-pointer position-relative overflow-hidden text-overflow-ellipsis w-250px-mx`} onClick={handleClosePopover}>
                  <span className="mr-2">
                    <i className={`${ActivityType.symbName} text-primary bi`}></i>
                  </span>
                  <span className={`${props.dataStatus === "completed" ? "text-decoration-line-through" : ""}`}> {props.data}</span>
                </div>
              </OverlayTrigger>
              <div onClick={(e) => e.stopPropagation()}>
                {showIcon ? (
                  <OverlayTrigger placement="auto" overlay={popoverForm()} rootClose={rootClose} onExit={() => {}} trigger="click" show={show} onToggle={() => setShow(!show)} id="popoverbutton">
                    <IconButton2 className="btn btn-icon btn-light h-25px w-25px" onClick={() => {}} tooltip="Edit Meeting Details">
                      <svg width="15" height="16" viewBox="0 0 15 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7895 2.52629V2.94739C11.7895 3.17985 11.6008 3.36848 11.3684 3.36848C11.1359 3.36848 10.9473 3.17985 10.9473 2.94739V2.52629H7.57894V2.94739C7.57894 3.17985 7.39045 3.36848 7.15784 3.36848C6.92537 3.36848 6.73689 3.17985 6.73689 2.94739V2.52629H3.36841V2.94739C3.36841 3.17985 3.17992 3.36848 2.94746 3.36848C2.71485 3.36848 2.52636 3.17985 2.52636 2.94739V2.52629H1.26322C1.03047 2.52629 0.84212 2.71478 0.84212 2.94739V4.21053H13.4736V2.94739C13.4736 2.71478 13.2854 2.52629 13.0526 2.52629H11.7895ZM11.7895 1.68424H13.0526C13.7506 1.68424 14.3158 2.24984 14.3158 2.94739V14.7369C14.3158 15.4344 13.7506 16 13.0526 16H1.26314C0.565167 16 0 15.4344 0 14.7369V2.94739C0 2.24984 0.565167 1.68424 1.26314 1.68424H2.52629V0.421096C2.52629 0.188627 2.71478 0 2.94739 0C3.17985 0 3.36834 0.188627 3.36834 0.421096V1.68424H6.73682V0.421096C6.73682 0.188627 6.9253 0 7.15777 0C7.39038 0 7.57887 0.188627 7.57887 0.421096V1.68424H10.9472V0.421096C10.9472 0.188627 11.1358 0 11.3683 0C11.6008 0 11.7894 0.188627 11.7894 0.421096L11.7895 1.68424ZM13.4736 5.05258H0.84212V14.7369C0.84212 14.9695 1.03047 15.1579 1.26322 15.1579H13.0527C13.2854 15.1579 13.4736 14.9695 13.4736 14.7369L13.4736 5.05258ZM4.23204 10.3932C4.25276 10.3311 4.28759 10.2747 4.33368 10.2285L8.54428 6.01789C8.70866 5.85351 8.97538 5.85351 9.13977 6.01789L10.824 7.70213C10.9884 7.86651 10.9884 8.13323 10.824 8.29761L6.61341 12.5082C6.56717 12.5543 6.51077 12.5891 6.44875 12.6099L3.92246 13.4519C3.59342 13.5617 3.28017 13.2485 3.38999 12.9194L4.23204 10.3932ZM4.45506 12.3868L6.08827 11.8424L9.93076 7.99989L8.842 6.91113L4.99951 10.7536L4.45506 12.3868Z" />
                      </svg>
                    </IconButton2>
                  </OverlayTrigger>
                ) : (
                  <div className="justify-self-end w-25px h-25px"></div>
                )}
              </div>
            </div>
          </>
        ) : (
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data}</Tooltip>} trigger={["hover", "focus"]}>
            <div className={`data-container overflow-hidden text-overflow-ellipsis w-250px-mx`}>
              <span className="mr-2">
                <i className={`${ActivityType.symbName} bi`}></i>
              </span>
              <span className={`${props.dataStatus === "completed" ? "text-decoration-line-through" : ""}`}> {props.data}</span>
            </div>
          </OverlayTrigger>
        )
      ) : (
        <span className={`data-container overflow-hidden text-overflow-ellipsis w-250px-mx`}>{props.data}</span>
      )}
    </div>
  );
}
