import React, { useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { Form, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//components
import BlankSidePanel from "../../../../../components/blank-side-pannel";
import { dispatchSnackbarError } from "../../../../../utils/toaster";
import AddActivityForm from "../forms/activity.form";

//constants
import { moduleToActionMapping, ActionNameMapping, actionTypeMapping } from "../constants";
import { reorderActions } from "../../../../deals/utils";

//forms
import FieldUpdateForm from "../forms/field-update.form";
import SequenceFrom from "../forms/sequence.form";
import CreateDealForm from "../forms/create-deal.form";
import LeadToJunkForm from "../forms/lead-to-junk.form";
import EmailNotificationForm from "../forms/email-notification.form";
import DealLossReasonForm from "../forms/deal-loss-reason.form";

//actions
import * as ActivityAction from "../../actions/activity_action";
import * as ConvertToJunkAction from "../../actions/convert_to_junk_action";
import * as DealAction from "../../actions/deal_action";
import * as DealLossReasonAction from "../../actions/deal_loss_reason_action";
import * as EmailNotificationAction from "../../actions/email_notification_actions";
import * as UpdateFieldAction from "../../actions/update_field_action";
import { DeleteModal } from "../../../../../components/modal";

//styles
import "./styles.scss";
import { CustomButtonGroupWithValue } from "../../../../../components/button/custom-group-button";
import { DeleteIconButton, EditIconButton } from "../../../../../components/button/icon-action-buttons";

export default function ActionStep(props) {
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActionData, setSelectedActionData] = useState({});
  const [showDeleteAction, setShowDeleteAction] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [isEmptyEdit, setIsEmptyEdit] = useState(false);

  const dispatch = useDispatch();

  const checkDuplication = (type) => {
    for (let i = 0; i < props.criteriaActions.length; i++) if (props.criteriaActions[i].type === type) return true;
    return false;
  };

  const onClickAddAction = (actionName) => {
    if (props.criteriaActions.length >= 10) {
      return dispatchSnackbarError("You can't add more than 10 actions in a workflow.");
    }

    if (actionTypeMapping.noDuplication.includes(actionName)) {
      const duplication = checkDuplication(actionName);
      if (duplication) return dispatchSnackbarError("Selected action already exist in actions.");
    }
    if (actionTypeMapping.noSubData.includes(actionName)) {
      const criteriaActions = [...props.criteriaActions];
      criteriaActions.push({
        type: actionName,
        executionOrder: criteriaActions.length + 1,
      });
      props.setCriteriaActions(criteriaActions);
    } else {
      // const actions = [
      //     "leadToJunkAction", - object
      //     "emailNotifications", -  array
      //     "fieldUpdates",- array
      //     "createActivityAction", -  object
      //     "createDealAction", - object
      //     "sequenceAction" - object
      // ]
      setShow(true);
      setSelectedAction(actionName);
    }
  };

  const handleSidePannelClose = () => {
    setShow(false);
    setIsEdit(false);
    setSelectedAction("");
    setSelectedActionData({});
  };

  const onSubActionEditClick = (actionName, index) => {
    if (actionTypeMapping.array.includes(actionName)) {
      const item = props.criteriaActions[index][actionName][0];
      setSelectedActionData(item);
    } else if (actionTypeMapping.object.includes(actionName)) {
      const item = props.criteriaActions[index][actionName];
      setSelectedActionData(item);
    }
    setShow(true);
    setIsEdit(true);
    setSelectedAction(actionName);
    setEditIndex(index);
  };
  const onSubActionDeleteClick = (actionName, index) => {
    if (actionTypeMapping.array.includes(actionName)) {
      const item = props.criteriaActions[index][actionName][0];
      setSelectedActionData(item);
    } else if (actionTypeMapping.object.includes(actionName)) {
      const item = props.criteriaActions[index][actionName];
      setSelectedActionData(item);
    }
    setShow(false);
    setIsEdit(false);
    setSelectedAction(actionName);
    setShowDeleteAction(true);
    setEditIndex(index);
    setIsEmptyEdit(false);
  };

  const updateCriteriaAction = (actionName, data) => {
    const criteriaActions = [...props.criteriaActions];
    if (isEmptyEdit) {
      const _criteriaActions = props.criteriaActions.map((each, index) => {
        if (editIndex === index) {
          return {
            type: each.type,
            executionOrder: each.executionOrder,
            [each.type]: data,
          };
        }
        return each;
      });
      props.setCriteriaActions(_criteriaActions);
    } else {
      criteriaActions.push({
        type: actionName,
        executionOrder: criteriaActions.length + 1,
        [actionName]: data,
      });
      props.setCriteriaActions(criteriaActions);
    }
  };

  const updateCriteriaName = (actionName, id, name, isArray) => {
    const criteriaActions = [...props.criteriaActions];
    const _criteriaActions = criteriaActions.map((each) => {
      if (each.type === actionName && isArray) {
        each[actionName] = each[actionName].map((eachItem) => {
          if (eachItem.id === id) {
            eachItem.name = name;
          }
          return eachItem;
        });
      } else if (each.type === actionName && !isArray) {
        each[actionName].name = name;
      }
      return each;
    });
    props.setCriteriaActions(_criteriaActions);
    props.softUpdateWorkflowData();
  };

  //save
  const handleFieldUpdateCreateSave = (data) => {
    dispatch(
      UpdateFieldAction.createFieldUpdateRequest(data, (payload) => {
        if (payload.success) {
          updateCriteriaAction("fieldUpdates", [{ id: payload.data.id, name: data.name }]);
          handleDeleteCancel();
        } else {
          dispatchSnackbarError(payload.message);
        }
      }),
    );
  };
  const handleFieldUpdateEditSave = (id, data) => {
    dispatch(
      UpdateFieldAction.editFieldUpdateRequest({ id, data }, (payload) => {
        if (payload.success) {
          handleDeleteCancel();
          updateCriteriaName("fieldUpdates", id, data.name, true);
        } else dispatchSnackbarError(payload.message);
      }),
    );
  };

  const handleActivityCreateSave = (data) => {
    dispatch(
      ActivityAction.createActivityActionRequest(data, (payload) => {
        if (payload.success) {
          updateCriteriaAction("createActivityAction", { id: payload.data.id, name: data.activityName });
          handleDeleteCancel();
        } else {
          dispatchSnackbarError(payload.message);
        }
      }),
    );
  };

  const handleActivityEditSave = (id, data) => {
    dispatch(
      ActivityAction.editActivityActionRequest({ id, data }, (payload) => {
        if (payload.success) {
          handleDeleteCancel();
          updateCriteriaName("createActivityAction", id, data.activityName, false);
        } else dispatchSnackbarError(payload.message);
      }),
    );
  };

  const handleEmailCreateSave = (data) => {
    dispatch(
      EmailNotificationAction.createEmailNotificationsRequest(data, (payload) => {
        if (payload.success) {
          updateCriteriaAction("emailNotifications", [{ id: payload.data.id, name: data.name }]);
          handleDeleteCancel();
        } else {
          dispatchSnackbarError(payload.message);
        }
      }),
    );
  };

  const handleEmailEditSave = (id, data) => {
    dispatch(
      EmailNotificationAction.editEmailNotificationsRequest({ id, data }, (payload) => {
        if (payload.success) {
          handleDeleteCancel();
          updateCriteriaName("emailNotifications", id, data.name, true);
        } else dispatchSnackbarError(payload.message);
      }),
    );
  };

  const handleDealCreateSave = (data) => {
    dispatch(
      DealAction.createDealActionRequest(data, (payload) => {
        if (payload.success) {
          updateCriteriaAction("createDealAction", { id: payload.data.id });
          handleDeleteCancel();
        } else {
          dispatchSnackbarError(payload.message);
        }
      }),
    );
  };

  const handleDealEditSave = (id, data) => {
    dispatch(
      DealAction.editDealActionRequest({ id, data }, (payload) => {
        if (payload.success) {
          handleDeleteCancel();
        } else dispatchSnackbarError(payload.message);
      }),
    );
  };

  const handleDealLossReasonAccept = (data) => {
    dispatch(
      DealLossReasonAction.createDealLossReasonActionRequest(data, (payload) => {
        if (payload.success) {
          updateCriteriaAction("dealLostReasonAction", { id: payload.data.id });
          handleDeleteCancel();
        } else {
          dispatchSnackbarError(payload.message);
        }
      }),
    );
  };
  const handleDealLossReasonEdit = (id, data) => {
    dispatch(
      DealLossReasonAction.editDealLossReasonActionRequest({ id, data }, (payload) => {
        if (payload.success) {
          handleDeleteCancel();
        } else dispatchSnackbarError(payload.message);
      }),
    );
  };

  const handleLeadToJunkAccept = (data) => {
    dispatch(
      ConvertToJunkAction.createConvertToJunkRequest(data, (payload) => {
        if (payload.success) {
          updateCriteriaAction("leadToJunkAction", { id: payload.data.id, name: "" });
          handleDeleteCancel();
        } else {
          dispatchSnackbarError(payload.message);
        }
      }),
    );
  };

  const handleLeadToJunkEdit = (id, data) => {
    dispatch(
      ConvertToJunkAction.editConvertToJunkRequest({ id, data }, (payload) => {
        if (payload.success) handleDeleteCancel();
        else dispatchSnackbarError(payload.message);
      }),
    );
  };

  const handleCadenceAccept = (data) => {
    updateCriteriaAction("sequenceAction", { id: data.id, name: data.name });
    handleDeleteCancel();
  };
  const handleCadenceEdit = (data) => {
    const criteriaActions = [...props.criteriaActions];
    const _criteriaActions = criteriaActions.map((each, index) => {
      if (each.type === "sequenceAction" && index === editIndex) {
        each["sequenceAction"].id = data.id;
        each["sequenceAction"].name = data.name;
      }
      return each;
    });
    props.setCriteriaActions(_criteriaActions);
    props.softUpdateWorkflowData();
    handleDeleteCancel();
  };

  const handleDeleteCancel = () => {
    setSelectedActionData({});
    setShow(false);
    setIsEdit(false);
    setSelectedAction("");
    setShowDeleteAction(false);
    setEditIndex(-1);
    setIsEmptyEdit(false);
  };
  const onAccepetDelete = () => {
    if (selectedAction === "createActivityAction") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "createActivityAction" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "fieldUpdates") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "fieldUpdates" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "leadToJunkAction") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "leadToJunkAction" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "emailNotifications") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "emailNotifications" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "createDealAction") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "createDealAction" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "sequenceAction") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "sequenceAction" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "dealToLeadAction") {
      const criteriaActions = props.criteriaActions.filter((each) => each.type !== "dealToLeadAction");
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "dealArchiveAction") {
      const criteriaActions = props.criteriaActions.filter((each) => each.type !== "dealArchiveAction");
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    } else if (selectedAction === "dealLostReasonAction") {
      const criteriaActions = props.criteriaActions.filter((each, index) => each.type !== "dealLostReasonAction" && index !== editIndex);
      props.setCriteriaActions(criteriaActions);
      props.softUpdateWorkflowData();
    }
    handleDeleteCancel();
  };

  const deleteLeadBotModelProps = {
    show: showDeleteAction,
    title: "Delete Action",
    handleClose: handleDeleteCancel,
    handleAccept: onAccepetDelete,
  };

  const getListItemNames = (each) => {
    if (actionTypeMapping.noName.includes(each.type)) {
      return ActionNameMapping[each.type];
    } else if (actionTypeMapping.array.includes(each.type)) {
      const _array = each[each.type];
      if (_.isEmpty(_array)) {
        return ActionNameMapping[each.type];
      } else if (each[each.type][0].name) {
        return `${ActionNameMapping[each.type]} - ${each[each.type][0].name}`;
      }
    } else if (actionTypeMapping.object.includes(each.type)) {
      const _obj = each[each.type];
      if (_.isEmpty(_obj)) {
        return ActionNameMapping[each.type];
      } else if (_obj.name) {
        return `${ActionNameMapping[each.type]} - ${each[each.type].name}`;
      }
    }
    return ActionNameMapping[each.type];
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorderActions(props.criteriaActions, result.source.index, result.destination.index);
    props.setCriteriaActions(items);
  };

  const isAddOrEdit = (each) => {
    if (actionTypeMapping.editNeeded.includes(each.type) && each.hasOwnProperty(each.type)) {
      return "edit";
    } else if (actionTypeMapping.editNeeded.includes(each.type) && !each.hasOwnProperty(each.type)) {
      return "add";
    }
    return "add";
  };

  const onEditEmptyActionClick = (type, index) => {
    onClickAddAction(type);
    setIsEmptyEdit(true);
    setEditIndex(index);
  };

  return (
    <>
      <div className="card action-wrapper">
        <div className="d-flex justify-content-between align-items-center mb-0">
          <div className="module-header-text">Actions</div>
        </div>
        <Form.Group id="automation-steps-action-step4-form" className="text-center">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {props.criteriaActions.map((each, index) => {
                    return (
                      <Draggable draggableId={index.toString()} index={index} key={index} type="TASK">
                        {(provided, snapshot) => (
                          <ListGroup.Item className="d-flex justify-content-between align-items-center my-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div>{getListItemNames(each)}</div>
                            <div>
                              {isAddOrEdit(each) === "add" ? (
                                <>
                                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Update Action Details"}</Tooltip>}>
                                    <span className="import-icon-container error" onClick={() => onEditEmptyActionClick(each.type, index)}>
                                      Please update action details
                                      <i className="pl-2 bi bi-plus text-53617c"></i>
                                    </span>
                                  </OverlayTrigger>
                                </>
                              ) : (
                                <>
                                  <EditIconButton onClick={() => onSubActionEditClick(each.type, index)} />
                                </>
                              )}
                              <DeleteIconButton onClick={() => onSubActionDeleteClick(each.type, index)} />
                            </div>
                          </ListGroup.Item>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* {props.criteriaActions.length === 0 && <Form.Label className="text-center">
                    No Actions to show. Click on "+ Add Action" to add action for workflow.
                </Form.Label>} */}

          {/* <Dropdown>
                    <Dropdown.Toggle variant="light" size="sm"><strong>+ Add Action</strong></Dropdown.Toggle>
                    <Dropdown.Menu>
                        {moduleToActionMapping[props.moduleName].map((each, index) => {
                            return <Dropdown.Item key={index} onClick={() => onClickAddAction(each.actionName)}>
                                {each.displayName}
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown> */}
        </Form.Group>

        <Form.Group id="automation-steps-action-step4-add-action">
          <div className="add-action-text w-100 text-center">Add Action</div>
          <CustomButtonGroupWithValue
            isCenter={true}
            options={moduleToActionMapping[props.moduleName].map((each) => ({
              value: each.actionName,
              label: each.displayName,
            }))}
            handleClick={onClickAddAction}
            active={""}
          />
        </Form.Group>
      </div>
      <BlankSidePanel show={show} title={isEdit ? "Edit Action" : "Add New Action"} handleClose={handleSidePannelClose}>
        <div className="p-0">
          {selectedAction === "createActivityAction" && (
            <AddActivityForm isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleCreateSave={handleActivityCreateSave} handleEditSave={handleActivityEditSave} />
          )}
          {selectedAction === "emailNotifications" && (
            <EmailNotificationForm isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleCreateSave={handleEmailCreateSave} handleEditSave={handleEmailEditSave} />
          )}
          {selectedAction === "createDealAction" && (
            <CreateDealForm isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleCreateSave={handleDealCreateSave} handleEditSave={handleDealEditSave} />
          )}
          {selectedAction === "fieldUpdates" && (
            <FieldUpdateForm isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleCreateSave={handleFieldUpdateCreateSave} handleEditSave={handleFieldUpdateEditSave} />
          )}
          {selectedAction === "leadToJunkAction" && (
            <LeadToJunkForm isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleSave={handleLeadToJunkAccept} handleEdit={handleLeadToJunkEdit} />
          )}
          {selectedAction === "sequenceAction" && <SequenceFrom isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleSave={handleCadenceAccept} handleEdit={handleCadenceEdit} />}
          {selectedAction === "dealLostReasonAction" && (
            <DealLossReasonForm isEdit={isEdit} moduleName={props.moduleName} selectedActionData={selectedActionData} handleCreateSave={handleDealLossReasonAccept} handleEditSave={handleDealLossReasonEdit} />
          )}
        </div>
      </BlankSidePanel>
      <DeleteModal modalProps={deleteLeadBotModelProps}>Are you sure want to delete action? After deleting action, you will not able to recover it.</DeleteModal>
    </>
  );
}
