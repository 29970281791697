import React, { useState, useEffect, useCallback } from "react";
import { EditableTable } from "../../components/table";
import { Spinner } from "react-bootstrap";
import { ColumnFilter } from "../../components/column_filter";
import { DeleteModal, ConvertModal } from "../../components/modal";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton, GreenGradientButton } from "../../components/button";
import * as actions from "./store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import BulkEditForm from "../../components/custom_form/bulkEdit";
// import { SubheaderNav } from "../../components/subheader/subheaderNav";
import { ownersRequest } from "../customers/store/actions/actions";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/toaster";
import { MergeModal } from "../../components/merge";
import { useParams, useHistory } from "react-router-dom";
import ContentWrapper from "../Wrapper";
import TagSelect from "../../components/custom_form/tags";
import OwnerFilter from "../../components/filter/owner-filter";
import SidePannelDetailed from "../../components/side-pannel-detailed";

//actions
import * as LeadActions from "../leads/store/actions/actions";
import * as LeadOperationAction from "../leads/store/actions/lead-operations.actions";
import * as CompanyActions from "../companies/store/actions/actions";
import * as ProductActions from "../products/store/actions/actions";
import * as insightActions from "../insights/actions";
import { CustomEmptyPage } from "../../components/empty_page/custom-empty-page";
import { DeleteIconButton } from "../../components/button/icon-action-buttons";
import { SecondaryButton } from "../../components/button/common-buttons";
import * as TemplateActions from "../campaign/store/actions/campaign_templates_actions";
import SelectAll from "../../components/filter/select-all";
import AdvancedSubHeaderNav from "../../components/subheader/advancedSubHeaderNav";

export default function JunkLeads(props) {
  const sidePanelType = "junk_lead";
  // const links = [
  //   { name: "Active Leads", to: "/prospects/leads/active", icon: "bi bi-bullseye" },
  //   { name: "Junk Leads", to: "/prospects/leads/junk", icon: "bi bi-recycle" },
  // ];
  // const tabName = "Junk Leads";

  const links = [
    {
      name: "Active Leads",
      to: "/prospects/leads/active",
      type: "split-dropdown",
      icon: "bi bi-bullseye",
      options: [
        { name: "Active Leads", to: "/prospects/leads/active", target: "_self", icon: "bi bi-bullseye" },
        { name: "Junk Leads", to: "/prospects/leads/junk", target: "_self", icon: "bi bi-recycle" },
      ],
      subTabNames: ["Active Leads", "Junk Leads"],
    },
    { name: "People", to: "/prospects/people", icon: "bi bi-person", type: "text" },
    { name: "Companies", to: "/prospects/companies", icon: "bi bi-building", type: "text" },
  ];
  const tabName = "Junk Leads";
  const tabIcon = "bi bi-recycle";
  const [showColumns, setShowColumns] = useState([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const popupStore = useSelector((state) => state.Leads.leadPopup);
  const fieldStore = useSelector((state) => state.Leads.leadFields);
  const listStore = useSelector((state) => state.Leads.leadList);
  const errorStore = useSelector((state) => state.Leads.error);
  const successStore = useSelector((state) => state.Leads.success);
  const loadingStore = useSelector((state) => state.Leads.loadingData);
  const [bulkEditData, setBulkEditData] = useState({});
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const page = "Leads";
  const pageSub = "lead";
  const leadType = "junk";
  const [showDelete, setShowDelete] = useState(false);
  const [showConvert, setShowConvert] = useState(false);
  // const [showExportModal, setShowExportModal] = useState(false)
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const params = useParams();
  const history = useHistory();

  //filters
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");

  // delete all
  const [isAllSelected, setIsAllSelected] = useState(false);

  //sidepannel states
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePannelLeadId, setSidePannelLeadId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [linkedModuleId, setLinkedModuleId] = useState("");
  const [linkedModuleName, setLinkedModuleName] = useState("");

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      limit: limit,
      type: "junk_lead",
      tagId: tags,
      owner: owner,
      ...sortKeys(),
    };
    dispatch(actions.leadsRequest(payload));
    dispatch(actions.loadingDataUpdateRequest(payload));
  }, [dispatch, pageNo, limit, owner, tags, sortKeys]);

  useEffect(() => {
    dispatch(actions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(ownersRequest());
    dispatch(actions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    // common apis for all module
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!listStore.loading && listStore.list.length === 0 && listStore.count > 0) {
      setPageNo(1);
    }
  }, [listStore.loading, listStore.list, listStore.count, limit]);

  useEffect(() => {
    if (listStore.list) {
      const _data = listStore.list.map((each) => {
        return { module: "lead", id: each._id };
      });
      setNextPreviousData(_data);
    }
  }, [listStore.list]);

  useEffect(() => {
    if (params.linkedId && params.moduleName) {
      setLinkedModuleId(params.linkedId);
      setLinkedModuleName(params.moduleName);
      setSidePannelLeadId("");
      setOpenPanel(true);
    } else if (params.leadId) {
      setSidePannelLeadId(params.leadId);
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(true);
    } else {
      setSidePannelLeadId("");
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("junkLeadOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("junkLeadOwner", owner);
    else localStorage.removeItem("junkLeadOwner");
  }, [owner]);

  const updateFieldPriority = (data) => {
    dispatch(actions.fieldUpdatePriorityRequest({ ...data, fieldType: "LEAD" }));
  };

  const showHideField = (data) => {
    dispatch(actions.fieldShowHideRequest(data));
  };

  const addTag = (name, colorCode) => {
    dispatch(actions.leadTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(actions.leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteTag = (id) => {
    dispatch(actions.leadTagsDeleteRequest(id));
  };

  const handleEditLead = (combinedId, data) => {
    dispatch(actions.leadCellEditRequest({ editData: data, loadingData: loadingStore, cellId: combinedId }));
  };

  useEffect(() => {
    if (errorStore.err) {
      dispatchSnackbarError(errorStore.errMsg);
      dispatch(actions.clearError());
    }
  }, [errorStore, dispatch]);

  useEffect(() => {
    if (successStore.success) {
      dispatchSnackbarSuccess(successStore.successMsg);
      dispatch(actions.clearSuccess());
    }
  }, [successStore, dispatch]);

  const handleBulkEdit = (data) => {
    if (isAllSelected) {
      const payload = {
        leadData: {
          updateData: data,
          ids: checked,
          isAllSelected: true,
          filter: loadingStore,
        },
        loadingData: loadingStore,
      };
      dispatch(LeadOperationAction.bulkEditLeadV2Request(payload));
      setShowBulkEdit(false);
      setBulkEditData({});
      setChecked([]);
      setIsAllSelected(false);
    } else {
      dispatch(actions.leadBulkEditRequest({ leadData: { ...data, ids: checked }, loadingData: loadingStore }));
      setShowBulkEdit(false);
      setBulkEditData({});
    }
  };

  const handleDeleteLeads = () => {
    if (params.leadId) {
      dispatch(actions.leadDeleteRequest({ leadIds: { ids: [params.leadId] }, loadingData: loadingStore }));
      history.push(`/prospects/leads/junk`);
    } else if (isAllSelected && checked.length > 0) {
      dispatch(LeadOperationAction.bulkDeleteLeadV2Request({ leadIds: { ids: checked, filter: loadingStore, isAllSelected: true }, loadingData: loadingStore }));
      setChecked([]);
    } else {
      dispatch(actions.leadDeleteRequest({ leadIds: { ids: checked }, loadingData: loadingStore }));
      setChecked([]);
    }

    setOpenPanel(false);
    setSidePannelLeadId("");
    setShowDelete(false);
  };

  const handleConvert = () => {
    if (params.leadId) {
      dispatch(actions.convertToActiveRequest({ id: { id: params.leadId }, loadingData: loadingStore }));
      history.push(`/prospects/leads/junk`);
    } else {
      dispatch(actions.convertToActiveRequest({ id: { id: checked[0] }, loadingData: loadingStore }));
      setChecked([]);
    }
    setOpenPanel(false);
    setSidePannelLeadId("");
    setShowConvert(false);
  };

  const handleMerge = (merged) => {
    dispatch(actions.leadsMergeRequest({ data: merged, loadingData: loadingStore }));
    setChecked([]);
  };

  const tableProps = {
    columns: fieldStore.list,
    data: listStore.list,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: (a) => {
      setChecked(a);
      setIsAllSelected(false);
    },
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    idToIndex: fieldStore.idToIndex,
    handleCellEdit: handleEditLead,
    page: page,
    pageSub: pageSub,
    cellLoading: listStore.cellLoading,
    sidePanelProps: popupStore.data,
    popupLoading: popupStore.loading,
    popupCellLoading: popupStore.cellLoading,
    convertToActive: () => setShowConvert(true),
    sidePanelType: sidePanelType,
    handleDelete: () => setShowDelete(true),
    loading: listStore.loading,
    fromtable: "junkLead",
    leadType: leadType,

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    //for filter
    filterPayload: loadingStore,

    //side pannel
    sidePannelSelectedId: sidePannelLeadId,
    // selection
    isAllListSelected: isAllSelected,
  };

  const deleteModalProps = {
    show: showDelete,
    title: "Delete Leads",
    handleClose: () => {
      setShowDelete(false);
    },
    handleAccept: handleDeleteLeads,
  };

  const convertModalProps = {
    show: showConvert,
    title: "Convert To Active",
    handleClose: () => {
      setShowConvert(false);
    },
    handleAccept: handleConvert,
    buttonType: "green",
  };

  useEffect(() => {
    let tempCol = [];
    var tempFieldStore = fieldStore.list;
    tempFieldStore.sort(function(a, b) {
      return a.tablePriority - b.tablePriority;
    });
    for (let i = 0; i < tempFieldStore.length; i++) if (tempFieldStore[i].isEnabledForTable !== false) tempCol.push(tempFieldStore[i]["_id"]);
    setShowColumns(tempCol);
  }, [fieldStore]);

  const setValue = (value) => {
    if (tags[0] === value[0]) {
      setTags([]);
    } else {
      setTags(value);
    }
  };
  const handlePanelClose = () => {
    history.push(`/prospects/leads/junk`);
    setOpenPanel(false);
  };

  return (
    <>
      {fieldStore.loading || listStore.loading ? (
        <ContentWrapper
          subHeader={
            <CustomSubHeader>
              <div className="w-100 d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            </CustomSubHeader>
          }
        >
          <div className={`card card-custom`}>
            <div className="card-body py-3 px-3">
              <div className="w-100 d-flex justify-content-center align-items-center h-vh-200px-mn">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </ContentWrapper>
      ) : (
        <>
          <ContentWrapper
            subHeader={
              <CustomSubHeader>
                <div className="d-flex flex-row flex-stack w-100">
                  <div>
                    <AdvancedSubHeaderNav links={links} active={tabName} activeIcon={tabIcon} />
                  </div>
                  <ConvertModal modalProps={convertModalProps}>Are you sure you want to convert to active?</ConvertModal>
                  <DeleteModal modalProps={deleteModalProps}>
                    Are you sure you want to delete the leads?
                    {isAllSelected && (
                      <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
                        In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be call or
                        activated.
                      </span>
                    )}
                  </DeleteModal>
                  <div>
                    {checked.length !== 0 ? (
                      <div className="d-flex flex-row align-items-center gap-12px">
                        <SelectAll
                          checked={checked}
                          count={listStore.count}
                          limit={limit}
                          isAllSelected={isAllSelected}
                          selectAllClick={() => {
                            setIsAllSelected(true);
                            setChecked(listStore.list.map((each) => each._id));
                          }}
                          clearAllClick={() => {
                            setIsAllSelected(false);
                            setChecked([]);
                          }}
                        />
                        <BulkEditForm
                          isAllListSelected={isAllSelected}
                          handleBulkEdit={handleBulkEdit}
                          noOfRows={isAllSelected ? listStore.count : checked.length}
                          showColumns={showColumns}
                          show={showBulkEdit}
                          setShow={setShowBulkEdit}
                          addTag={addTag}
                          editTag={editTag}
                          deleteTag={deleteTag}
                          page={page}
                          pageSub={pageSub}
                          fields={fieldStore.list}
                          customFormData={bulkEditData}
                          setCustomFormData={setBulkEditData}
                        />
                        {checked.length === 1 ? (
                          <GreenGradientButton size="sm" onClick={() => setShowConvert(true)}>
                            Convert To Active
                          </GreenGradientButton>
                        ) : null}
                        {checked.length === 2 ? (
                          <BlueGradientButton size="sm" onClick={() => setShowMergeModal(true)}>
                            Merge
                          </BlueGradientButton>
                        ) : null}
                        <SecondaryButton onClick={() => setShowBulkEdit(true)}>Bulk Edit</SecondaryButton>
                        <DeleteIconButton onClick={() => setShowDelete(true)} />
                        <ColumnFilter
                          fieldType={"LEAD"}
                          showHideField={showHideField}
                          updateFieldPriority={updateFieldPriority}
                          idToIndex={fieldStore.idToIndex}
                          columns={fieldStore.list}
                          showColumns={showColumns}
                          setShowColumns={setShowColumns}
                        />
                      </div>
                    ) : (
                      <div className="d-flex flex-row align-items-center gap-12px">
                        <div>
                          <TagSelect
                            page={page}
                            pageSub={pageSub}
                            addTag={addTag}
                            editTag={editTag}
                            deleteTag={deleteTag}
                            field={{ fieldName: "Tags", apiKeyName: "tags" }}
                            value={!tags || tags === "" || tags.length === 0 ? [""] : tags.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
                            setValue={(value) => setValue(value)}
                            hideAddText={true}
                            isUsedAsFilter={true}
                            iconBasedFilter={true}
                          />
                        </div>
                        <OwnerFilter setValue={setOwner} value={owner} />
                        <ColumnFilter
                          fieldType={"LEAD"}
                          showHideField={showHideField}
                          updateFieldPriority={updateFieldPriority}
                          idToIndex={fieldStore.idToIndex}
                          columns={fieldStore.list}
                          showColumns={showColumns}
                          setShowColumns={setShowColumns}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </CustomSubHeader>
            }
          >
            {!listStore.loading && listStore.count === 0 ? (
              <CustomEmptyPage page="junkLead" />
            ) : (
              <div className={`card card-custom`}>
                <div className="card-body py-3 px-3">
                  <EditableTable props={tableProps} />
                </div>
              </div>
            )}
            <MergeModal
              show={showMergeModal}
              handleMerge={handleMerge}
              setShow={setShowMergeModal}
              type={page}
              pageSub={pageSub}
              typeSub="Lead"
              fields={fieldStore.list}
              items={checked.map((item) => listStore.list.find((listItem) => listItem["_id"] === item))}
            />

            <SidePannelDetailed
              show={openPanel}
              basePath={`/prospects/leads/junk`}
              onClose={handlePanelClose}
              moduleName={linkedModuleName ? linkedModuleName : "lead"}
              moduleId={linkedModuleId ? linkedModuleId : sidePannelLeadId}
              nextPreviousData={linkedModuleId ? [] : nextPreviousData}
              pageForm={"junkLead"}
              filter={loadingStore}
              //actions
              convertToActive={() => setShowConvert(true)}
              handleDelete={() => setShowDelete(true)}
            />
          </ContentWrapper>
        </>
      )}
    </>
  );
}
