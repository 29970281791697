import React, { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip, Popover, Button } from "react-bootstrap";
import { GreenGradientButton } from "../../../../components/button";
import moment from "moment-timezone";
import { DateRangePicker } from "react-dates";
import { dispatchSnackbarError } from "../../../../utils/toaster";

/*
startDate={props.startDate}
setStartDate={props.setStartDate}
endDate={props.endDate}
setEndDate={props.setEndDate}
 */
export default function ActivityStatus(props) {
  const [show, setShow] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [focusedInput, setFocusedInput] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const handleStatusClick = (option) => {
    props.setStatus(option);
    props.setStartDate(null);
    props.setEndDate(null);
  };

  const handleTimeClick = (option) => {
    if (option === "Today") {
      props.setStatus(option);
      props.setStartDate(
        moment()
          .startOf("day")
          .valueOf(),
      );
      props.setEndDate(
        moment()
          .endOf("day")
          .valueOf(),
      );
    } else if (option === "Tomorrow") {
      props.setStatus(option);
      props.setStartDate(
        moment()
          .add(1, "day")
          .startOf("day")
          .valueOf(),
      );
      props.setEndDate(
        moment()
          .add(1, "day")
          .endOf("day")
          .valueOf(),
      );
    } else if (option === "This Week") {
      props.setStatus(option);
      props.setStartDate(
        moment()
          .startOf("week")
          .valueOf(),
      );
      props.setEndDate(
        moment()
          .endOf("week")
          .valueOf(),
      );
    } else if (option === "Next Week") {
      props.setStatus(option);
      props.setStartDate(
        moment()
          .add(1, "week")
          .startOf("week")
          .valueOf(),
      );
      props.setEndDate(
        moment()
          .add(1, "week")
          .endOf("week")
          .valueOf(),
      );
    }
  };

  const handleClose = () => {
    document.body.click();
    setEnd(null);
    setStart(null);
  };

  const handleSave = () => {
    if (!start) {
      return dispatchSnackbarError("Please select start date");
    } else if (!end) {
      return dispatchSnackbarError("Please select end date");
    }
    document.body.click();
    setShow(false);
    setIsCustom(false);
    props.setStatus("Custom");
    props.setStartDate(
      moment(start)
        .startOf("day")
        .valueOf(),
    );
    props.setEndDate(
      moment(end)
        .endOf("day")
        .valueOf(),
    );
  };

  const popoverForm = () => {
    return (
      <Popover className="w-310px w-310px-mx" size="md" id="popover-basic" onClick={(e) => e.stopPropagation()}>
        <Popover.Title as="h3">{"Custom Snooze Time"}</Popover.Title>
        <Popover.Content>
          <DateRangePicker
            startDate={start}
            startDateId="startDateId"
            endDate={end}
            endDateId="endDateId"
            onDatesChange={({ startDate, endDate }) => {
              setStart(startDate);
              setEnd(endDate);
            }}
            displayFormat={"YYYY-MM-DD"}
            small={true}
            isOutsideRange={() => false}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => {
              setFocusedInput(focusedInput);
            }}
          />
          <div className="d-flex flex-row justify-content-center gap-15px mt-15px">
            <Button variant="secondary" onClick={(e) => handleClose(e)} size="sm">
              Cancel
            </Button>
            <GreenGradientButton onClick={(e) => handleSave(e)} size="sm">
              Save
            </GreenGradientButton>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <Dropdown
      show={show}
      onToggle={() => {
        if (!isCustom) setShow(!show);
      }}
      className="dropdown-inline"
    >
      <Dropdown.Toggle className="px-2 py-0 hide-dropdonw-icon-inline text-decoration-none" variant={"link"}>
        {!props.status ? (
          <OverlayTrigger overlay={<Tooltip>Time Filter</Tooltip>}>
            <i class="bi bi-clock-history"></i>
          </OverlayTrigger>
        ) : (
          <div className="d-flex gap-2 align-items-center pl-3 pr-1 py-2 border rounded-2">
            <span>{props.status ? props.status : "Status"}</span>
            <i
              class="bi bi-x-circle-fill"
              onClick={(e) => {
                e.stopPropagation();
                props.handleClose();
              }}
            ></i>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="scroll navi navi-hover overflow-y-auto position-relative h-500px-mx">
          {[
            // "All",
            "Todo",
            "Upcoming",
            "Overdue",
            "Done",
          ].map((each, index) => {
            return (
              <Dropdown.Item key={index} onClick={() => handleStatusClick(each)}>
                {each}
              </Dropdown.Item>
            );
          })}
          {["Today", "Tomorrow", "This Week", "Next Week"].map((each, index) => {
            return (
              <Dropdown.Item key={index} onClick={() => handleTimeClick(each)}>
                {each}
              </Dropdown.Item>
            );
          })}
          <OverlayTrigger
            rootClose={true}
            onExit={() => {
              setIsCustom(false);
              setShow(false);
            }}
            onEnter={() => setIsCustom(true)}
            trigger="click"
            id="popoverbutton"
            placement="left"
            overlay={popoverForm()}
          >
            <div className="dropdown-item cursor-pointer d-flex flex-stack">
              <div>Custom</div>
            </div>
          </OverlayTrigger>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
