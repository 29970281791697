import { getMarketingDataFields, getMarketingAddForm, setMarketingFieldShowHide, setMarketingTablePriority } from "../types/marketing_data_field_types";

export function getMarketingDataFieldsRequest(req) {
  return {
    type: getMarketingDataFields.GET_MARKETING_DATA_FIELDS_REQUEST,
    payload: req,
  };
}
export function getMarketingDataFieldsSuccess(res) {
  return {
    type: getMarketingDataFields.GET_MARKETING_DATA_FIELDS_SUCCESS,
    payload: res,
  };
}
export function getMarketingDataFieldsError(err) {
  return {
    type: getMarketingDataFields.GET_MARKETING_DATA_FIELDS_ERROR,
    payload: err,
  };
}

export function getMarketingAddFormRequest(req) {
  return {
    type: getMarketingAddForm.GET_MARKETING_ADD_FORM_REQUEST,
    payload: req,
  };
}
export function getMarketingAddFormSuccess(res) {
  return {
    type: getMarketingAddForm.GET_MARKETING_ADD_FORM_SUCCESS,
    payload: res,
  };
}
export function getMarketingAddFormError(err) {
  return {
    type: getMarketingAddForm.GET_MARKETING_ADD_FORM_ERROR,
    payload: err,
  };
}

export function setMarketingTablePriorityRequest(req) {
  return {
    type: setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_REQUEST,
    payload: req,
  };
}
export function setMarketingTablePrioritySuccess(res) {
  return {
    type: setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_SUCCESS,
    payload: res,
  };
}
export function setMarketingTablePriorityError(err) {
  return {
    type: setMarketingTablePriority.SET_MARKETING_TABLE_PRIORITY_ERROR,
    payload: err,
  };
}

export function setMarketingFieldShowHideRequest(req) {
  return {
    type: setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_REQUEST,
    payload: req,
  };
}
export function setMarketingFieldShowHideSuccess(res) {
  return {
    type: setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_SUCCESS,
    payload: res,
  };
}
export function setMarketingFieldShowHideError(err) {
  return {
    type: setMarketingFieldShowHide.SET_MARKETING_FIELD_SHOW_HIDE_ERROR,
    payload: err,
  };
}
