import React, { useState, useEffect, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import NormalTable from "../../../../components/normal-table/index";
import FormInput from "../../../../components/Form-Field/index";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../users/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import { Spinner } from "react-bootstrap";
import Pagination from "../../../../components/normal-table/pagination";
import CustomAction, { EditActionColumn } from "../../../../components/ActionColumn/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//components
import EditSystemFields from "./components/edit-system-fields.custom-fields";
import ReorderAddForm from "./components/reorder-add-form.custom-field";
import { DeleteModal } from "../../../../components/modal";
import { getUsageRecordReportRequest } from "../../actions/usage.actions";
import { dispatchSnackbarWarning } from "../../../../utils/toaster";

//actions
import * as actions from "../../actions/settings.actions";
import * as customFieldActions from "../../actions/custom-fields.actions";
import * as LeadActions from "../../../leads/store/actions/actions";
import * as PeopleActions from "../../../customers/store/actions/actions";
import * as DealListActions from "../../../deals/dealList/actions";
import * as CompanyActions from "../../../companies/store/actions/actions";
import * as marketingDataFieldActions from "../../../campaign/store/actions/marketing_data_field_actions";
import AIWizardPromptField from "./components/ai-wizard-promt.custom-fields";

const validationMapping = {
  none: "NONE",
  URL_VALIDATION: "URL VALIDATION",
  PHONE_VALIDATION: "PHONE VALIDATION",
  EMAIL_VALIDATION: "EMAIL VALIDATION",
  PIN_CODE_VALIDATION: "PIN CODE VALIDATION",
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const unRemovableFieldsFromAddFrom = {
  LEAD: ["owner", "name", "phone", "email"],
  COMPANY: ["owner", "name"],
  PRODUCT: ["productName", "type", "pricing"],
  DEAL: ["owner"],
  ACTIVITY: ["owner"],
  MARKETING: ["email", "phone"],
};

const FieldTypeMapping = {
  INPUT: "TEXTAREA",
  TEXTAREA: "DESCRIPTION",
  CHECKBOX: "CHECKBOX",
  SELECT: "SELECT DROPDOWN",
  RADIO: "RADIO",
  TOGGLE_BUTTON: "TOGGLE BUTTON",
  NUMBER: "NUMBER",
  DATE: "DATE",
  DATE_TIME: "DATE TIME",
  DATE_RANGE: "DATE RANGE",
  DATE_TIME_RANGE: "DATE TIME RANGE",
  AI_WIZARD: "AI WIZARD",
  "Select Field Type": "Select Field Type",
};

/*******************************Custom Table************************************************/
const CustomTable = (props) => {
  //Edit UseState Custom Field
  const [CurrentId, setCurrentId] = useState("");
  const [popupTitle, setpopupTitle] = useState("");
  // const [action, setAction] = useState('');
  const [selectedActionOption, setselectedActionOption] = useState("Select Field Type");
  // const [validationAction, setvalidationAction] = useState('');
  const [selectedValidaionOption, setselectedValidaionOption] = useState("Select Validation");
  const [fieldValue, setfieldValue] = useState("");
  const [Isrequired, setIsrequired] = useState(false);
  const [IsrequiredField, setIsrequiredField] = useState(false);
  const [fieldOption, setfieldOption] = useState([]);
  const [showDeleteModel, setShowDeleteModal] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState({});
  // const [FieldName, setFieldName] = useState('')
  const [prompt, setPrompt] = useState("");


  const [systemFieldEditPopupShow, setSystemFieldEditPopupShow] = useState(false);
  const [selectedSystemField, setSelectedSystemField] = useState({});
  const [isSystemFieldRequiredInAddForm, setIsSystemFieldRequiredInAddForm] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  /*******
   * system field
   * **********/

  const [loader1, setLoader1] = useState(true);
  const [comments1, setComments1] = useState([]);
  const [totalItems1, setTotalItems1] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(1);
  const ITEMS_PER_PAGE1 = 10;

  useEffect(() => {
    if (props && props.tableData) {
      setComments(props.tableData.custom);
      setComments1(props.tableData.system);
      setLoader(false);
      setLoader1(false);
    }
  }, [props]);

  const commentsData = useMemo(() => {
    try {
      let computedComments = comments;
      setTotalItems(computedComments.length);
      // current page slice
      return computedComments.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
    } catch (e) { }
  }, [comments, currentPage]);

  // /////////////////////////////////////////////////////////

  const commentsData1 = useMemo(() => {
    let computedComments1 = comments1;
    setTotalItems1(computedComments1.length);
    // current page slice
    return computedComments1.slice((currentPage1 - 1) * ITEMS_PER_PAGE1, (currentPage1 - 1) * ITEMS_PER_PAGE1 + ITEMS_PER_PAGE1);
  }, [comments1, currentPage1]);

  /**********************Edit Custom Field*******************/

  const getRelatedCustomFields = (fieldName) => {
    if (fieldName === "LEAD" || fieldName === "PERSON") {
      dispatch(PeopleActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: false }));
      dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: false }));
    } else if (fieldName === "COMPANY") {
      dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: false }));
    } else if (fieldName === "DEAL") {
      dispatch(DealListActions.getDealListFieldsRequest({ skipIfDataPresent: false }));
    } else if (fieldName === "MARKETING") {
      dispatch(marketingDataFieldActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
    }
  };

  const EditAPi = (user) => {
    setCurrentId(user._id);
    setfieldOption(user.options);
    setselectedActionOption(user.fieldInputType);
    setselectedValidaionOption(user.validation);
    setfieldValue(user.fieldName);
    setIsrequiredField(user.isRequiredInAddForm);
    setIsrequired(user.isRequired);
    setpopupTitle(user.fieldType);
    setPrompt(user.prompt || "");
    setShow(true);
  };

  function handleChange(e) {
    if (e.target.value === "") {
      setfieldValue(e.target.value);
    } else {
      if (e.target.value.match(/^[a-zA-Z0-9\s]+$/)) {
        setfieldValue(e.target.value);
      } else {
        dispatchSnackbarError("Special characters not allowed", "error");
      }
    }
  }

  const isRequiredFieldFun = (e) => {
    if (e.target.checked) {
      setIsrequiredField(true);
    } else {
      setIsrequiredField(false);
    }
  };

  const isRequiredFun = (e) => {
    if (e.target.checked) {
      setIsrequired(true);
    } else {
      setIsrequired(false);
    }
  };

  const handleChangeAction = (e) => {
    setselectedActionOption(e);
  };

  const handleValidationAction = (e) => {
    setselectedValidaionOption(e);
  };

  const CustomFieldOption = (e) => {
    let a = [e.target.value];
    let optionsArray = a[0].split(",");
    let tempArray = [];
    for (let i = 0; i < optionsArray.length; i++) {
      tempArray.push(optionsArray[i].trim());
    }
    setfieldOption(tempArray);
  };

  const EditFieldFunction = () => {
    if (!fieldValue) {
      dispatchSnackbarError("Field name required!");
      setShow(true);
    } else if (!selectedActionOption) {
      dispatchSnackbarError("Field type required!");
      setShow(true);
    } else if ((selectedActionOption === "SELECT" || selectedActionOption === "CHECKBOX" || selectedActionOption === "RADIO") && fieldOption.length === 0) {
      dispatchSnackbarError("Field options are required!");
      setShow(true);
    } else if (selectedActionOption === "AI_WIZARD" && _.isEmpty(prompt)) {
      dispatchSnackbarError("Prompt message is required!");
      setShow(true);
    } else {
      let PatchObj = {
        _id: CurrentId,
        fieldName: fieldValue,
        fieldType: popupTitle,
        // "fieldInputType": action,
        fieldInputType: selectedActionOption,
        options: fieldOption,
        validation: selectedValidaionOption,
        // "validation":validationAction,
        isRequired: Isrequired,
        isRequiredInAddForm: selectedActionOption === "AI_WIZARD" ? false : IsrequiredField,
        prompt: prompt,
      };
      dispatch(
        actions.patchCustomFieldRequest(PatchObj, (data) => {
          if (data.status === true) {
            getRelatedCustomFields(popupTitle);
            dispatchSnackbarSuccess("Custom Field Successfully Updated", "success");
            setShow(false);
          }
          dispatch(
            actions.getCustomFieldRequest(props.set, (data) => {
              // setgetApikey(data.data)
              setLoader(false);
              setLoader1(false);
              setComments(data.data.custom);
              setComments1(data.data.system);
            }),
          );
        }),
      );
    }
  };

  /*********************************************************/

  const deleteAPI = (user) => {
    setShowDeleteModal(false);
    setSelectedCustomField({});
    dispatch(
      actions.deleteCustomFieldRequest(user._id, (data) => {
        if (data.status === true) {
          dispatchSnackbarSuccess("Custom Field Successfully Deleted", "success");
          setShow(false);
        }
        getRelatedCustomFields(user.fieldType);
        dispatch(
          actions.getCustomFieldRequest(props.set, (data) => {
            // setgetApikey(data.data)
            setLoader(false);
            setLoader1(false);
            setComments(data.data.custom);
            setComments1(data.data.system);
          }),
        );
      }),
    );
  };

  const onDeleteModalOpenClick = (user) => {
    setShowDeleteModal(true);
    setSelectedCustomField(user);
  };
  const onDeleteCustomFieldAccept = () => {
    deleteAPI(selectedCustomField);
  };
  const onDeleteCustomFieldCancel = () => {
    setShowDeleteModal(false);
    setSelectedCustomField({});
  };

  const onSystemFieldEdit = (fieldData) => {
    setSelectedSystemField(fieldData);
    setSystemFieldEditPopupShow(true);
    setIsSystemFieldRequiredInAddForm(fieldData.isRequiredInAddForm);
  };

  const handleSystemFieldCLoseModel = () => {
    setSelectedSystemField({});
    setSystemFieldEditPopupShow(false);
  };
  const handleSystemFieldSubmitModel = () => {
    dispatch(
      customFieldActions.editSystemFieldsRequest({
        _id: selectedSystemField._id,
        isAddInForm: isSystemFieldRequiredInAddForm,
      }),
    );
    const newTableData = comments1.map((each) => {
      if (each._id === selectedSystemField._id) {
        each.isRequiredInAddForm = isSystemFieldRequiredInAddForm;
      }
      return each;
    });
    setComments1(newTableData);
    setSelectedSystemField({});
    setSystemFieldEditPopupShow(false);
    dispatch(customFieldActions.getAddFormFieldsRequest({ fieldType: props.set }));
  };
  const handleIsSystemFieldRequiredInAddForm = (e) => {
    if (e.target.checked) {
      setIsSystemFieldRequiredInAddForm(true);
    } else {
      setIsSystemFieldRequiredInAddForm(false);
    }
  };

  const verifyUnEditableFields = (user) => {
    try {
      return !unRemovableFieldsFromAddFrom[props.set].includes(user.apiKeyName);
    } catch (err) {
      return false;
    }
  };
  return (
    <div>
      <div className="overflow-y-auto h-vh-260px-mx">
        <NormalTable tableHeading={["Custom Fields", "API Key Name", "Field Input Type", "Validation", "Action"]} tableTitle={"Custom Fields"}>
          {loader === true && (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}

          {commentsData.length === 0 && loader === false && (
            <tr>
              <td colSpan="5" className="text-center">
                <h6>No Data To Show</h6>
              </td>
            </tr>
          )}

          {commentsData.map((user, id) => {
            return loader === false ? (
              <tr key={id}>
                <td>{user.fieldName}</td>
                <td>{user.apiKeyName}</td>
                <td>{FieldTypeMapping[user.fieldInputType] ? FieldTypeMapping[user.fieldInputType] : user.fieldInputType}</td>
                <td>{user.validation}</td>
                <td className="d-flex w-150px">
                  <CustomAction Edit={() => EditAPi(user)} Delete={() => onDeleteModalOpenClick(user)} />
                </td>
              </tr>
            ) : (
              ""
            );
          })}
        </NormalTable>
        <div className="d-flex justify-content-end mt-minus-2-percent">
          <Pagination total={totalItems} itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
        </div>
        <br />
        <br /> <br />
        <NormalTable tableHeading={["Custom Fields", "API Key Name", "Field Input Type", "Validation", "Action"]} tableTitle={"System Fields"}>
          {loader1 === true && (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}

          {commentsData1.length === 0 && loader1 === false && (
            <tr>
              <td colSpan="5" className="text-center">
                <h6>No Data To Show</h6>
              </td>
            </tr>
          )}

          {commentsData1.map((user, id) => {
            return loader1 === false ? (
              <tr key={id}>
                <td>{user.fieldName}</td>
                <td>{user.apiKeyName}</td>
                <td>{user.fieldInputType}</td>
                <td>{user.validation}</td>
                <td>{user.isEditable && verifyUnEditableFields(user) && <EditActionColumn Edit={() => onSystemFieldEdit(user)} />}</td>
              </tr>
            ) : (
              ""
            );
          })}
        </NormalTable>
        <div className="d-flex justify-content-end mt-minus-2-percent">
          <Pagination total={totalItems1} itemsPerPage={ITEMS_PER_PAGE1} currentPage={currentPage1} onPageChange={(page) => setCurrentPage1(page)} />
        </div>
        {/* ***************************************************************** */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit {toTitleCase(popupTitle)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12 fv-row">
              <label className="mb-8px fs-6">Field Name*</label>
              <FormInput placeholder={"Enter field name"} type={"text"} name={"nameField"} value={fieldValue} onChange={handleChange} />
            </div>

            <br></br>

            <div className="col-lg-12 fv-row">
              <label className="mb-8px fs-6">Field Type*</label>

              <Dropdown drop="down" onSelect={(e) => handleChangeAction(e)}>
                <Dropdown.Toggle id="newSS" className="newDropDesign">
                  {FieldTypeMapping[selectedActionOption] ? FieldTypeMapping[selectedActionOption] : selectedActionOption}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {popupTitle !== "PRODUCT" && <Dropdown.Item className="dropdown-hover" eventKey="AI_WIZARD">
                    AI WIZARD
                  </Dropdown.Item>}
                  <Dropdown.Item className="dropdown-hover" eventKey="INPUT">
                    TEXTAREA
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="TEXTAREA">
                    DESCRIPTION
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="CHECKBOX">
                    CHECKBOX
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="SELECT">
                    SELECT DROPDOWN
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="RADIO">
                    RADIO
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="TOGGLE_BUTTON">
                    TOGGLE BUTTON
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="NUMBER">
                    NUMBER
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="DATE">
                    DATE
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="DATE_TIME">
                    DATE TIME
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="DATE_RANGE">
                    DATE RANGE
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="DATE_TIME_RANGE">
                    DATE TIME RANGE
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>

            {selectedActionOption === "AI_WIZARD" && <div className="col-lg-12 fv-row mt-4px">
              <label className="mb-8px fs-6">Write your prompt*</label>
              <AIWizardPromptField fieldType={popupTitle} prompt={prompt} setPrompt={setPrompt} fieldsData={props.tableData} />
            </div>}

            {(selectedActionOption === "SELECT" || selectedActionOption === "RADIO" || selectedActionOption === "CHECKBOX") && (
              <div className="col-lg-12 fv-row mt-10px">
                <label className="mb-8px fs-6">Options*</label>
                <FormInput placeholder={"a,b,c"} type={"text"} name={"Fieldoption"} value={fieldOption} onChange={(e) => CustomFieldOption(e)} />
              </div>
            )}

            <br></br>
            {(selectedActionOption === "INPUT" || selectedActionOption === "TEXTAREA" || selectedActionOption === "NUMBER") && (
              <div className="col-lg-12 fv-row">
                <label className="mb-8px fs-6">Validation</label>

                <Dropdown drop="down" onSelect={(e) => handleValidationAction(e)}>
                  <Dropdown.Toggle id="newSS" className="newDropDesign">
                    {selectedValidaionOption && validationMapping[selectedValidaionOption] ? validationMapping[selectedValidaionOption] : selectedValidaionOption}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    <Dropdown.Item className="dropdown-hover" eventKey="none">
                      NONE
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-hover" eventKey="URL_VALIDATION">
                      URL VALIDATION
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-hover" eventKey="PHONE_VALIDATION">
                      PHONE VALIDATION
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-hover" eventKey="EMAIL_VALIDATION">
                      EMAIL VALIDATION
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-hover" eventKey="PIN_CODE_VALIDATION">
                      PIN CODE VALIDATION
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <br></br>

            {selectedActionOption !== "AI_WIZARD" && <div className="d-flex justify-content-between w-100">
              <div className="col-lg-4 fv-row d-flex align-items-center">
                <label className="checkbox checkbox-primary fw-500 font-size-14px">
                  <input type="checkbox" checked={Isrequired} onChange={(e) => isRequiredFun(e)} name="Checkboxes5" />
                  <span></span>
                  &nbsp;&nbsp; Required
                </label>
                <span className="ml-10px">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>it will do require validation on current field if user try to add any data or try to save empty data</Tooltip>}>
                    <i className="bi bi-info-circle"></i>
                  </OverlayTrigger>
                </span>
              </div>
              <div className="col-lg-8 fv-row d-flex align-items-center">
                <label className="checkbox checkbox-primary fw-500 font-size-14px">
                  <input type="checkbox" checked={IsrequiredField} onChange={(e) => isRequiredFieldFun(e)} name="Checkboxes5" />
                  <span></span>
                  &nbsp;&nbsp; {`Add field in ${toTitleCase(popupTitle)} form`}
                </label>
                <span className="ml-10px">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{`it will add curent field in add ${toTitleCase(popupTitle)} form`}</Tooltip>}>
                    <i className="bi bi-info-circle"></i>
                  </OverlayTrigger>
                </span>
              </div>
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={EditFieldFunction}>
              Edit Field
            </Button>
          </Modal.Footer>
        </Modal>
        <DeleteModal
          modalProps={{
            show: showDeleteModel,
            title: "Delete Custom Field",
            handleClose: () => onDeleteCustomFieldCancel(),
            handleAccept: () => onDeleteCustomFieldAccept(),
          }}
        >
          Are you sure want to delete custom field?
        </DeleteModal>
        <EditSystemFields
          type={props.set}
          systemFieldEditPopupShow={systemFieldEditPopupShow}
          handleSystemFieldCLoseModel={handleSystemFieldCLoseModel}
          selectedSystemField={selectedSystemField}
          FieldTypeMapping={FieldTypeMapping}
          isSystemFieldRequiredInAddForm={isSystemFieldRequiredInAddForm}
          handleIsSystemFieldRequiredInAddForm={handleIsSystemFieldRequiredInAddForm}
          handleSystemFieldSubmitModel={handleSystemFieldSubmitModel}
        />
      </div>
    </div>
  );
};

export default function CustomField() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [currentTab, setcurrentTab] = useState("tab1");
  const [popupTitle, setpopupTitle] = useState("");
  const [action, setAction] = useState("");
  const [selectedActionOption, setselectedActionOption] = useState("Select Field Type");
  const [validationAction, setvalidationAction] = useState("");
  const [selectedValidaionOption, setselectedValidaionOption] = useState("Select Validation");
  const [fieldValue, setfieldValue] = useState("");
  const [Isrequired, setIsrequired] = useState(false);
  const [IsrequiredField, setIsrequiredField] = useState(false);
  const [fieldOption, setfieldOption] = useState([]);
  const [FieldName, setFieldName] = useState("");
  const [AppNamePara, setAppNamePara] = useState("LEAD");
  const [tableData, setTableData] = useState();
  const [prompt, setPrompt] = useState("");
  const usageData = useSelector((store) => store.usage.data);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);

  //reorder variables
  const [showReorderPopup, setShowReorderPopup] = useState(false);
  const AddFormFields = useSelector((state) => state.customFields.addForm);

  useEffect(() => {
    dispatch(
      actions.getCustomFieldRequest(AppNamePara, (data) => {
        setTableData(data.data);
      }),
    );

    dispatch(customFieldActions.getAddFormFieldsRequest({ fieldType: AppNamePara }));
  }, [dispatch, AppNamePara]);

  useEffect(() => {
    dispatch(getUsageRecordReportRequest());
  }, [dispatch]);

  const getCustomFieldCount = () => {
    const data = usageData.customFields;
    try {
      return data.companies + data.contacts + data.leads + data.products + (data.deals || 0);
    } catch (err) {
      return 0;
    }
  };

  const handleClose = () => {
    setShow(false);
    setfieldValue("");
    setselectedActionOption("Select Field Type");
    setselectedValidaionOption("Select Validation");
  };

  const handleShow = (name) => {
    if (accountBillingStore.data.servicesOffered.CUSTOM_FIELDS_LIMIT && accountBillingStore.data.servicesOffered.CUSTOM_FIELDS_LIMIT !== "unlimited") {
      if (getCustomFieldCount() < accountBillingStore.data.servicesOffered.CUSTOM_FIELDS_LIMIT) {
        if (name === "Lead") {
          setFieldName("LEAD");
        } else if (name === "Product") {
          setFieldName("PRODUCT");
        } else if (name === "Company") {
          setFieldName("COMPANY");
        } else if (name === "Deal") {
          setFieldName("DEAL");
        } else if (name === "Marketing") {
          setFieldName("MARKETING");
        }
        // getShowStatus(name);
        setShow(true);
        setpopupTitle(name);
      } else {
        dispatchSnackbarWarning("You have reached the max number of custom fields limit. Please upgrade your plan to create more custom fields.", "warning");
      }
    } else {
      if (name === "Lead") {
        setFieldName("LEAD");
      } else if (name === "Product") {
        setFieldName("PRODUCT");
      } else if (name === "Company") {
        setFieldName("COMPANY");
      } else if (name === "Deal") {
        setFieldName("DEAL");
      } else if (name === "Marketing") {
        setFieldName("MARKETING");
      }
      // getShowStatus(name);
      setShow(true);
      setpopupTitle(name);
    }
  };

  function handleChange(e) {
    if (e.target.value === "") {
      setfieldValue(e.target.value);
    } else {
      if (e.target.value.match(/^[a-zA-Z0-9\s]+$/)) {
        setfieldValue(e.target.value);
      } else {
        dispatchSnackbarError("Special characters not allowed", "error");
      }
    }
    // setfieldValue(e.target.value)
  }

  function HandleActive(id, appName) {
    setAppNamePara(appName);

    setcurrentTab(id);

    setTableData();

    let a = ["tab1", "tab3", "tab4", "tab5", "tab6"];

    let b = a.filter((res) => res !== id);

    for (let i = 0; i < b.length; i++) {
      var element = document.getElementById(b[i]);
      element.classList.remove("active");
    }

    var v = document.getElementById(id);
    v.className += " active";
  }

  const isRequiredFieldFun = (e) => {
    if (e.target.checked) {
      setIsrequiredField(true);
    } else {
      setIsrequiredField(false);
    }
  };

  const isRequiredFun = (e) => {
    if (e.target.checked) {
      setIsrequired(true);
    } else {
      setIsrequired(false);
    }
  };

  const handleChangeAction = (e) => {
    setselectedActionOption(e);
    setAction(e);
  };

  const handleValidationAction = (e) => {
    setselectedValidaionOption(e);
    setvalidationAction(e);
  };

  const CustomFieldOption = (e) => {
    let a = [e.target.value];
    let optionsArray = a[0].split(",");
    let tempArray = [];
    for (let i = 0; i < optionsArray.length; i++) {
      tempArray.push(optionsArray[i].trim());
    }
    setfieldOption(tempArray);
    // setfieldOption(a[0].split(','))
  };

  const makeEmptyData = () => {
    setfieldValue("");
    setFieldName("");
    setAction("");
    setvalidationAction("");
    setIsrequired(false);
    setIsrequiredField(false);
    setfieldOption([]);
    setPrompt("");
  };

  const getRelatedCustomFields = (fieldName) => {
    if (fieldName === "LEAD") {
      dispatch(PeopleActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: false }));
      dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: false }));
    } else if (fieldName === "COMPANY") {
      dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: false }));
    } else if (fieldName === "DEAL") {
      dispatch(DealListActions.getDealListFieldsRequest({ skipIfDataPresent: false }));
    } else if (fieldName === "MARKETING") {
      dispatch(marketingDataFieldActions.getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
    }
  };

  const AddFieldFunction = () => {
    if (!fieldValue) {
      dispatchSnackbarError("Field name required!");
      setShow(true);
    } else if (!action) {
      dispatchSnackbarError("Field type required!");
      setShow(true);
    } else if ((action === "SELECT" || action === "CHECKBOX" || action === "RADIO") && fieldOption.length === 0) {
      dispatchSnackbarError("Field options are required!");
      setShow(true);
    }
    else if (action === "AI_WIZARD" && _.isEmpty(prompt)) {
      dispatchSnackbarError("Prompt message is required!");
      setShow(true);
    }
    else {
      let addCustomFieldObj = {
        fieldName: fieldValue,
        fieldType: FieldName,
        fieldInputType: action,
        validation: validationAction,
        isRequired: Isrequired,
        isRequiredInAddForm: action === "AI_WIZARD" ? false : IsrequiredField,
        options: fieldOption,
        prompt: prompt,
      };
      dispatch(
        actions.postCustomFieldRequest(addCustomFieldObj, (data) => {
          if (data["status"]) {
            dispatchSnackbarSuccess("Custom Field Successfully Added", "success");
            setShow(false);
            getRelatedCustomFields(FieldName);
            makeEmptyData();
            setselectedActionOption("Select Field Type");
            dispatch(
              actions.getCustomFieldRequest(AppNamePara, (data) => {
                setTableData(data.data);
              }),
            );
          }
        }),
      );
    }
  };

  const handleReorderModelOpen = () => {
    setShowReorderPopup(true);
  };
  const handleReorderModelClose = () => {
    setShowReorderPopup(false);
  };
  const handleReorderModelAccept = (data) => {
    dispatch(customFieldActions.reorderAddFormFieldsRequest(data));
    setShowReorderPopup(false);
  };
  return (
    <>
      <div className="mb-5 mb-xl-10">
        {/* *************************************************************** */}
        <div className="z-index-1 position-absolute right-30px mt-13px">
          <div className="d-flex justify-content-between flex-row w-310px">
            {["tab1", "tab3", "tab4", "tab5", "tab6"].includes(currentTab) ? (
              <Button variant=" btn-sm" className="btn-secondary width-fit-content" onClick={handleReorderModelOpen}>
                Reorder Add Form
              </Button>
            ) : (
              <div></div>
            )}
            <Dropdown>
              <Dropdown.Toggle variant="success btn-sm" id="dropdown-basic" className="dropButton">
                + Custom Fields
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleShow("Lead")}>{"Lead & People Field"}</Dropdown.Item>
                <Dropdown.Item onClick={() => handleShow("Company")}>Company Field</Dropdown.Item>
                <Dropdown.Item onClick={() => handleShow("Product")}>Product Field</Dropdown.Item>
                <Dropdown.Item onClick={() => handleShow("Deal")}>Deal Field</Dropdown.Item>
                <Dropdown.Item onClick={() => handleShow("Marketing")}>Marketing Field</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* ************************************************************** */}
        {/*begin::Stepper*/}
        <div className=" card stepper stepper-links d-flex flex-column" id="kt_modal_create_project_stepper">
          {/*begin::Container*/}
          <div className="container">
            <div className="d-flex overflow-hidden h-55px">
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("tab1", "LEAD")}>
                  <div className="nav-link text-active-primary me-6 active" id="tab1">
                    {"Lead & People"}
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("tab3", "COMPANY")}>
                  <div className="nav-link text-active-primary me-6" id="tab3">
                    Company
                  </div>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("tab4", "PRODUCT")}>
                  <div className="nav-link text-active-primary me-6" id="tab4">
                    Product
                  </div>
                </li>
                {/*end::Nav item*/}
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("tab5", "DEAL")}>
                  <div className="nav-link text-active-primary me-6" id="tab5">
                    Deal
                  </div>
                </li>
                <li className="nav-item cursor-pointer" onClick={() => HandleActive("tab6", "MARKETING")}>
                  <div className="nav-link text-active-primary me-6" id="tab6">
                    Marketing
                  </div>
                </li>
              </ul>
            </div>

            {/*end::Nav*/}
          </div>
          {/*begin::Container*/}
        </div>
        {/*end::Stepper*/}

        <br></br>
        <br></br>
        {currentTab === "tab1" && <CustomTable set={AppNamePara} tableData={tableData} />}
        {currentTab === "tab3" && <CustomTable set={AppNamePara} tableData={tableData} />}
        {currentTab === "tab4" && <CustomTable set={AppNamePara} tableData={tableData} />}
        {currentTab === "tab5" && <CustomTable set={AppNamePara} tableData={tableData} />}
        {currentTab === "tab6" && <CustomTable set={AppNamePara} tableData={tableData} />}
      </div>

      {/* **************************************************************************** */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add {toTitleCase(popupTitle)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 fv-row">
            <label className="mb-8px fs-6">Field Name*</label>
            <FormInput placeholder={"Enter field name"} type={"text"} name={"nameField"} value={fieldValue} onChange={handleChange} />
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <label className="mb-8px fs-6">Field Type*</label>

            <Dropdown drop="down" onSelect={(e) => handleChangeAction(e)}>
              <Dropdown.Toggle id="newSS" className="newDropDesign">
                {FieldTypeMapping[selectedActionOption] ? FieldTypeMapping[selectedActionOption] : selectedActionOption}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {AppNamePara !== "PRODUCT" && <Dropdown.Item className="dropdown-hover" eventKey="AI_WIZARD">
                  AI WIZARD
                </Dropdown.Item>}
                <Dropdown.Item className="dropdown-hover" eventKey="INPUT">
                  TEXTAREA
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="TEXTAREA">
                  DESCRIPTION
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="CHECKBOX">
                  CHECKBOX
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="SELECT">
                  SELECT DROPDOWN
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="RADIO">
                  RADIO
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="TOGGLE_BUTTON">
                  TOGGLE BUTTON
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="NUMBER">
                  NUMBER
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="DATE">
                  DATE
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="DATE_TIME">
                  DATE TIME
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="DATE_RANGE">
                  DATE RANGE
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-hover" eventKey="DATE_TIME_RANGE">
                  DATE TIME RANGE
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
          </div>
          <br></br>
          {action === "AI_WIZARD" && <div className="col-lg-12 fv-row mt-4px">
            <label className="mb-8px fs-6">Write your prompt*</label>
            <AIWizardPromptField fieldType={AppNamePara} prompt={prompt} setPrompt={setPrompt} fieldsData={tableData} />
          </div>}

          {(action === "SELECT" || action === "CHECKBOX" || action === "RADIO") && (
            <div className="col-lg-12 fv-row mt-10px">
              <label className="mb-8px fs-6">Options*</label>
              <FormInput placeholder={"a,b,c"} type={"text"} name={"Fieldoption"} onChange={(e) => CustomFieldOption(e)} />
            </div>
          )}

          <br></br>
          {(action === "INPUT" || action === "TEXTAREA" || action === "NUMBER") && (
            <div className="col-lg-12 fv-row">
              <label className="mb-8px fs-6">Validation</label>

              <Dropdown drop="down" onSelect={(e) => handleValidationAction(e)}>
                <Dropdown.Toggle id="newSS" className="newDropDesign">
                  {selectedValidaionOption && validationMapping[selectedValidaionOption] ? validationMapping[selectedValidaionOption] : selectedValidaionOption}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  <Dropdown.Item className="dropdown-hover" eventKey="none">
                    NONE
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="URL_VALIDATION">
                    URL VALIDATION
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="PHONE_VALIDATION">
                    PHONE VALIDATION
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="EMAIL_VALIDATION">
                    EMAIL VALIDATION
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-hover" eventKey="PIN_CODE_VALIDATION">
                    PIN CODE VALIDATION
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          <br></br>

          {action !== "AI_WIZARD" && <div className="d-flex justify-content-between w-100">
            <div className="col-lg-4 fv-row d-flex align-items-center">
              <label className="checkbox checkbox-primary fw-500 font-size-14px">
                <input type="checkbox" onChange={(e) => isRequiredFun(e)} name="Checkboxes5" />
                <span></span>
                &nbsp;&nbsp; Required
              </label>
              <span className="ml-10px">
                <OverlayTrigger placement="bottom" overlay={<Tooltip>it will do require validation on current field if user try to add any data or try to save empty data</Tooltip>}>
                  <i className="bi bi-info-circle"></i>
                </OverlayTrigger>
              </span>
            </div>
            <div className="col-lg-8 fv-row d-flex align-items-center">
              <label className="checkbox checkbox-primary fw-500 font-size-14px">
                <input type="checkbox" onChange={(e) => isRequiredFieldFun(e)} name="Checkboxes5" />
                <span></span>
                &nbsp;&nbsp; {`Add field in ${toTitleCase(popupTitle)} form`}
              </label>
              <span className="ml-10px">
                <OverlayTrigger placement="bottom" overlay={<Tooltip>{`it will add curent field in add ${toTitleCase(AppNamePara)} form`}</Tooltip>}>
                  <i className="bi bi-info-circle"></i>
                </OverlayTrigger>
              </span>
            </div>
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={AddFieldFunction}>
            Add Field
          </Button>
        </Modal.Footer>
      </Modal>

      <ReorderAddForm AddFormFields={AddFormFields} show={showReorderPopup} type={AppNamePara} handleSubmit={handleReorderModelAccept} handleClose={handleReorderModelClose} />
    </>
  );
}
