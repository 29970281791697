import React, { useEffect, useState } from "react";
import { isEmpty, sortBy } from "lodash";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";

export default function MarkAsRepliedActionForm(props) {
  const [stepOptions, setStepOptions] = useState([]);
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const getSelectedCadenceStore = useSelector((store) => store.cadence.selected);

  useEffect(() => {
    if (!isEmpty(getSelectedCadenceStore.data)) {
      const steps = getSelectedCadenceStore.data.steps;
      const options = sortBy(steps, ["executionOrder"])
        .filter((each) => each.channelType === "EMAIL")
        .map((each) => ({
          value: each._id,
          label: `Step ${each.executionOrder} - ${each?.name} (${each.channelType})`,
          isAbTesting: each.isAbTestingEnabled,
          abTestOptions:
            each.abTesting && each.abTesting.emailContent && each.abTesting.emailContent.length > 1
              ? each.abTesting.emailContent.map((_each) => ({
                  value: _each.template._id,
                  label: _each.template?.name,
                }))
              : [],
          template: each.emailContent?._id,
        }));
      setStepOptions(options);
    }
  }, [getSelectedCadenceStore.data]);

  const handleStepSelect = (data) => {
    if (data.isAbTesting) {
      setShowTemplateDropdown(true);
      props.setSelectedStep(data);
      props.setSelectedTemplate({});
      setTemplateOptions(data.abTestOptions);
    } else {
      setShowTemplateDropdown(false);
      props.setSelectedStep(data);
      props.setSelectedTemplate({ value: data.template, label: data.template });
    }
  };

  return (
    <Form.Group>
      <Form.Group>
        <Form.Label>Cadence Step</Form.Label>
        <SimpleDropdown value={props.selectedStep} label={"Select"} options={stepOptions} handleSelect={handleStepSelect} />
      </Form.Group>
      {showTemplateDropdown && (
        <Form.Group>
          <Form.Label>Cadence AB Test Templates</Form.Label>
          <SimpleDropdown value={props.selectedTemplate} label={"Select"} options={templateOptions} handleSelect={props.setSelectedTemplate} />
        </Form.Group>
      )}
    </Form.Group>
  );
}
