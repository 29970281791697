import React, { useEffect, useState } from "react";
import "./landing-page-styles.scss";
import Widget from "./widget";

export default function LandingPagePreview(props) {
  const [chatHistory, setChatHistory] = useState(false);
  const [thankyouScreen, setThankyouScreen] = useState(false);
  const [welcomeScreen, setWelcomeScreen] = useState(true);
  const [tabsList, setTabList] = useState(["Welcome Screen", "Chat History", "Thank You Screen"]);
  const [activeTab, setActiveTab] = useState("Chat History");

  useEffect(() => {
    const menu = [];
    if (props.welcomeScreen.isEnabled) {
      menu.push("Welcome Screen");
    }
    menu.push("Chat History");
    if (props.thankYouScreen.isEnabled) {
      menu.push("Thank You Screen");
    }
    setActiveTab("Chat History");
    setTabList(menu);
  }, [props.welcomeScreen.isEnabled, props.thankYouScreen.isEnabled]);

  useEffect(() => {
    if (activeTab === "Welcome Screen") {
      setChatHistory(false);
      setThankyouScreen(false);
      setWelcomeScreen(true);
    } else if (activeTab === "Chat History") {
      setChatHistory(true);
      setThankyouScreen(false);
      setWelcomeScreen(false);
    } else if (activeTab === "Thank You Screen") {
      setChatHistory(false);
      setThankyouScreen(true);
      setWelcomeScreen(false);
    }
  }, [activeTab]);

  return (
    <>
      <div className="d-flex justify-content-center">
        {tabsList.map((tabItem, index) => (
          <div
            key={index}
            className={`${tabItem === activeTab ? "border-bottom-width-3px border-bottom-style-solid border-bottom-color-000000" : ""}`}
          >
            <button className={`btn tab-font ${tabItem === activeTab ? "fw-500" : ""}`} onClick={() => setActiveTab(tabItem)}>
              {tabItem}
            </button>
          </div>
        ))}
      </div>
      <div className="ss-landing-container">
        <Widget
          type="landingpage"
          showWelcomeScreen={welcomeScreen}
          showChatHistoryScreen={chatHistory}
          welcomeScreenData={props.welcomeScreen}
          thankYouScreenData={props.thankYouScreen}
          showThankYouScreen={thankyouScreen}
          customization={props.customization}
        />
      </div>
    </>
  );
}
