import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InlineStyled from "../../../../../../../components/inline-styled/inline-styled";

export default function UsageBarSection({ data }) {
  const [consumed, setConsumed] = useState(0);
  const [assigned, setAssigned] = useState(0);

  const usageDataV2 = useSelector((store) => store.usage.v2);

  useEffect(() => {
    if (!_.isEmpty(usageDataV2.data)) {
      const _assigned = usageDataV2.data.records.assigned.contacts;
      const _consumed = usageDataV2.data.records.consumed.contacts;
      setAssigned(_assigned);
      setConsumed(_consumed);
    }
  }, [usageDataV2]);

  const getProgressbarPercentage = () => {
    if (typeof assigned === "number" && assigned < 1000000) {
      const percentage = (consumed / assigned) * 100;
      return `${percentage}%`;
    } else {
      return "0%";
    }
  };

  const getRemainingUsers = () => {
    if (assigned === "unlimited") {
      return "You can add unlimited records in your current plan";
    } else {
      if (typeof assigned === "number" && assigned > 999999) {
        return "You can add unlimited records in your current plan";
      } else if (typeof assigned === "number") {
        const difference = assigned - consumed;
        return `${difference} Records remaining in your plan`;
      } else {
        return "0 Records remaining in your plan";
      }
    }
  };

  return (
    <>
      <div className="d-flex text-muted fw-bolder fs-5 mb-3">
        <span className="flex-grow-1 text-gray-800 d-flex align-items-center gap-2 ">Usage</span>
        <span className="text-gray-800">
          <span>{consumed}</span> of {assigned > 999999 ? "unlimited" : assigned}
        </span>
      </div>
      <div className="progress h-8px bg-light-primary mb-2">
        <InlineStyled className="progress-bar bg-primary" role="progressbar" styles={{ width: getProgressbarPercentage("usage") }} aria-valuenow={86} aria-valuemin={0} aria-valuemax={100} />
      </div>
      <div className="fs-6 text-gray-600 fw-bold mb-10 mb-lg-20">{getRemainingUsers()}</div>
    </>
  );
}
