import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import * as syncSettingActions from "../../actions/profile-actions/sync-settings.actions";
import * as syncSettingsTypes from "../../types/profile-settings/sync-settings.types";

const endpoints = {
  GET_HOSTED_AUTH: "/api/crm/sync-settings/hosted-auth",
  HOSTED_CALLBACK: "/api/crm/sync-settings/hosted-auth-callback",
  LIST_OF_SYNC_DETAILS: "/api/crm/sync-settings/get-account-details",
  UPDATE_SYNC_DETAILS: "/api/crm/sync-settings/update-settings",
  DELETE_SYNC_DETAILS: "/api/crm/sync-settings/delete-account",
  LIST_OF_CALENDARS: "/api/crm/sync-settings/calender-list",
  SEND_NYLAS_EMAIL: "/api/crm/sync-settings/nylas-send-email",
  UPLOAD_ATTACHMENTS: "/api/crm/sync-settings/upload-nylas-attachement",
  MARK_AS_DEFAULT: "/api/crm/sync-settings/mark-as-default",
};

function* getHostedAuthLink(action) {
  try {
    const res = yield call(api.get, endpoints.GET_HOSTED_AUTH, action.payload);
    if (res.status === 200) {
      yield put(syncSettingActions.getHostedAuthLinkSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.getHostedAuthLinkError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.getHostedAuthLinkError(e));
  }
}

function* hostedAuthCallback(action) {
  try {
    const res = yield call(api.post, endpoints.HOSTED_CALLBACK, action.payload);
    if (res.status === 200) {
      yield put(syncSettingActions.hostedAuthCallbackSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.hostedAuthCallbackError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.hostedAuthCallbackError(e));
  }
}

function* getListOfSyncAccounts(action) {
  try {
    const res = yield call(api.get, endpoints.LIST_OF_SYNC_DETAILS, action.payload);
    if (res.status === 200) {
      yield put(syncSettingActions.getListOfSyncAccountsSuccess(res.data));
    } else {
      // dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.getListOfSyncAccountsError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.getListOfSyncAccountsError(e));
  }
}

function* updateSyncAccountDetails(action) {
  try {
    const res = yield call(api.patch, `${endpoints.UPDATE_SYNC_DETAILS}/${action.payload.syncId}`, action.payload.data);
    if (res.status === 200) {
      yield put(syncSettingActions.updateSyncAccountDetailsSuccess(res.data));
      yield put(syncSettingActions.getListOfSyncAccountsSecondaryRequest());
      dispatchSnackbarSuccess(res.data["message"], "success");
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.updateSyncAccountDetailsError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.updateSyncAccountDetailsError(e));
  }
}

function* deleteSyncAccountDetails(action) {
  try {
    const res = yield call(api.delete, `${endpoints.DELETE_SYNC_DETAILS}/${action.payload.syncId}`, action.payload.data);
    if (res.status === 200) {
      yield put(syncSettingActions.deleteSyncAccountDetailsSuccess(res.data));
      yield put(syncSettingActions.getListOfSyncAccountsSecondaryRequest());
      dispatchSnackbarSuccess(res.data["message"], "success");
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.deleteSyncAccountDetailsError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.deleteSyncAccountDetailsError(e));
  }
}

function* getSyncCalendarList(action) {
  try {
    const res = yield call(api.get, `${endpoints.LIST_OF_CALENDARS}/${action.payload.syncId}`, action.payload);
    if (res.status === 200) {
      yield put(syncSettingActions.getSyncCalendarListSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.getSyncCalendarListError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.getSyncCalendarListError(e));
  }
}

function* sendNyalsSyncEmail(action) {
  try {
    const res = yield call(api.post, endpoints.SEND_NYLAS_EMAIL, action.payload);

    if (res.status === 200) {
      yield put(syncSettingActions.sendNyalsSyncEmailSuccess(res.data));
      dispatchSnackbarSuccess(res.data["message"], "success");
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.sendNyalsSyncEmailError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.sendNyalsSyncEmailError(e));
  }
}

function* uploadSyncNylasAttchment(action) {
  try {
    const res = yield call(api.post, endpoints.UPLOAD_ATTACHMENTS, action.payload);
    if (res.status === 200) {
      yield put(syncSettingActions.uploadSyncNylasAttchmentSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.uploadSyncNylasAttchmentError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.uploadSyncNylasAttchmentError(e));
  }
}

function* markAsDefaultSyncAccount(action) {
  try {
    const res = yield call(api.post, `${endpoints.MARK_AS_DEFAULT}/${action.payload.syncId}`, action.payload.data);
    if (res.status === 200) {
      yield put(syncSettingActions.markAsDefaultSyncAccountSuccess(res.data));
      yield put(syncSettingActions.getListOfSyncAccountsSecondaryRequest());
      dispatchSnackbarSuccess(res.data["message"], "success");
    } else {
      dispatchSnackbarError(res.data["message"], "error");
      yield put(syncSettingActions.markAsDefaultSyncAccountError("error"));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(syncSettingActions.markAsDefaultSyncAccountError(e));
  }
}

export default function* syncSettingsSaga() {
  yield takeLatest(syncSettingsTypes.getHostedAuthLink.REQUEST, getHostedAuthLink);
  yield takeLatest(syncSettingsTypes.hostedAuthCallback.REQUEST, hostedAuthCallback);
  yield takeLatest(syncSettingsTypes.getListOfSyncAccounts.REQUEST, getListOfSyncAccounts);
  yield takeLatest(syncSettingsTypes.getListOfSyncAccounts.SECONDARY_REQUEST, getListOfSyncAccounts);
  yield takeLatest(syncSettingsTypes.updateSyncAccountDetails.REQUEST, updateSyncAccountDetails);
  yield takeLatest(syncSettingsTypes.deleteSyncAccountDetails.REQUEST, deleteSyncAccountDetails);
  yield takeLatest(syncSettingsTypes.getSyncCalendarList.REQUEST, getSyncCalendarList);
  yield takeLatest(syncSettingsTypes.sendNyalsSyncEmail.REQUEST, sendNyalsSyncEmail);
  yield takeLatest(syncSettingsTypes.uploadSyncNylasAttchment.REQUEST, uploadSyncNylasAttchment);
  yield takeLatest(syncSettingsTypes.markAsDefaultSyncAccount.REQUEST, markAsDefaultSyncAccount);
}
