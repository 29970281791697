import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { CustomButtonGroupWithValue } from "../../../button/custom-group-button";
import { EditIconSVG } from "../../../custom-svgs";
import VariableOptionsPro from "../../../variable-options";
import { emailTemplateOptions } from "../../constanst";
import EmailTextEditor from "../../editors/email-text.editor";
import AIWizardSidePanel from "../../../ai-wizard/AIWizardSidePanel";

export default function EmailTemplateForm(props) {
  const [showVariableOptions, setShowVariableOptions] = useState(false);
  const [showAIWizardSidePanel, setShowAIWizardSidePanel] = useState(false);

  const { isEdit, handleEditorSelector, selectedEditor } = props;
  const { content, setContent, defaultContent } = props;
  const { handleEmailBuilderEdit } = props;

  const addVariable = (item, key, variable) => {
    window.tinyMCE.execCommand("mceInsertContent", false, variable);
  };
  return (
    <>
      <Form.Group id="templates-add-edit-template-content">
        <Form.Label aria-label="content-designer-type" className="d-flex flex-stack">
          <div>Content Designer Type</div>
          <div className="d-flex gap-4 align-items-center">
            <button
              className="btn btn-color-primary btn-active-color-primary m-0 px-0"
              variant="transparent"
              onClick={() => {
                setShowAIWizardSidePanel(true);
              }}
            >
              AI Wizard
            </button>
            {selectedEditor === "tiny_cloud" && (
              <button className="btn btn-color-primary btn-active-color-primary m-0 px-0" variant="transparent" onClick={() => setShowVariableOptions(true)}>
                {/* <i class="bi bi-plus"></i> */}
                <span> Variables</span>
              </button>
            )}
          </div>
        </Form.Label>
        <>
          {!isEdit && (
            <div className="mb-6">
              <CustomButtonGroupWithValue options={emailTemplateOptions} handleClick={handleEditorSelector} active={selectedEditor} />
            </div>
          )}
          {selectedEditor === "tiny_cloud" ? (
            <EmailTextEditor defaultValue={defaultContent} value={content} onChange={setContent} />
          ) : selectedEditor === "unlayer" && content ? (
            <div className="position-relative">
              <iframe title="Template editor" srcDoc={content} width="100%" height="500px" frameBorder="0" scrolling="0" />
              <OverlayTrigger overlay={<Tooltip>Edit Content</Tooltip>}>
                <div className="position-absolute top-0 right-0 p-2 bg-light-white bg-hover-light-light border rounded-1 m-1" onClick={() => handleEmailBuilderEdit()}>
                  {EditIconSVG}
                </div>
              </OverlayTrigger>
            </div>
          ) : null}
        </>
      </Form.Group>
      <div onClick={(e) => e.stopPropagation()}>
        <VariableOptionsPro
          show={showVariableOptions}
          setShow={setShowVariableOptions}
          addVariable={addVariable}
          defaultVariableValues={props.defaultVariableValues}
          setDefaultVariableValues={props.setDefaultVariableValues}
        />
        <AIWizardSidePanel
          show={showAIWizardSidePanel}
          handleClose={() => {
            setShowAIWizardSidePanel(false);
          }}
        />
      </div>
    </>
  );
}
