import React from "react";
import { Form } from "react-bootstrap";
import TimeZoneSelect from "../../../../../../components/custom_form/timezone";
import { CustomButtonGroupWithValue } from "../../../../../../components/button/custom-group-button";
import "./styles.scss";
import MaterialUiTimePicker from "../../../../../../components/material-ui/time-picker";

const options = [
  { label: "Any Day, Any Time", value: "Any Day, Any Time" },
  { label: "Specific Day & Time", value: "Specific Day, Time" },
];

const days = [
  { day: "Sunday", value: 1 },
  { day: "Monday", value: 2 },
  { day: "Tuesday", value: 3 },
  { day: "Wednesday", value: 4 },
  { day: "Thursday", value: 5 },
  { day: "Friday", value: 6 },
  { day: "Saturday", value: 7 },
];

export default function CadenceScheduleStep3(props) {
  const updateScheduleData = (key, value) => {
    props.setSchedule({ ...props.schedule, [key]: value });
  };

  const handleDayChange = (e, selectedOption) => {
    let arr = [];
    if (e.target.checked) {
      arr = [...props.schedule.weekDays, selectedOption];
    } else {
      arr = props.schedule.weekDays.filter((item) => item !== selectedOption);
    }
    updateScheduleData("weekDays", arr);
  };

  const handleScheduleTypeChange = (title) => {
    if (title === "Any Day, Any Time") {
      const _schedule = {
        name: "Any Day, Any Time",
        weekDays: [1, 2, 3, 4, 5, 6, 7],
        from: "00:00",
        to: "23:59",
        timezone: "Asia/Kolkata",
      };
      props.setSchedule({ ...props.schedule, ..._schedule });
    } else {
      const _schedule = {
        name: title,
        weekDays: [2, 3, 4, 5, 6],
        from: "00:00",
        to: "23:59",
      };
      props.setSchedule({ ...props.schedule, ..._schedule });
    }
  };

  return (
    <div className="cadence-steps-container card">
      <label className="cadence-head-label">Schedule Cadence</label>
      <CustomButtonGroupWithValue options={options} handleClick={handleScheduleTypeChange} active={props.schedule.name} />
      {props.schedule.name === "Specific Day, Time" && (
        <Form.Group id="automation-schedule-sequence-step3-specific-day-and-time">
          <Form.Group id="automation-schedule-sequence-step3-select-day" className="mt-4 ">
            <Form.Label aria-label="select-day">Select Day</Form.Label>
            <div>
              {days.map((item) => (
                <Form.Check
                  inline
                  label={item.day}
                  id={item.day}
                  onChange={(e) => handleDayChange(e, item.value)}
                  type="checkbox"
                  key={item.day}
                  size="sm"
                  className="mt-12px mr-12px"
                  checked={props.schedule.weekDays.includes(item.value)}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group id="automation-schedule-sequence-step3-time-details-filling" className="mb-0">
            <Form.Group id="automation-schedule-sequence-step3-time-details" className="d-flex justify-content-between align-items-center mb-0 w-100">
              <Form.Group id="automation-schedule-sequence-step3-from-time" className="mb-0 w-32">
                <MaterialUiTimePicker label="From Time" value={props.schedule.from} setValue={(value) => updateScheduleData("from", value)} />
              </Form.Group>
              <Form.Group id="automation-schedule-sequence-step3-to-time" className="mb-0 w-32">
                <MaterialUiTimePicker label="To Time" value={props.schedule.to} setValue={(value) => updateScheduleData("to", value)} />
              </Form.Group>
              <Form.Group id="automation-schedule-sequence-step3-time-zone" className="mb-0 w-32">
                <Form.Label aria-label="timezone">Timezone</Form.Label>
                <TimeZoneSelect clearable={false} field={{ apiKeyName: "timezone", fieldName: "Timezone" }} value={props.schedule.timezone} setValue={(value) => updateScheduleData("timezone", value)} />
              </Form.Group>
            </Form.Group>
          </Form.Group>
        </Form.Group>
      )}
    </div>
  );
}
