import React, { useState, useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function CurrencySelect({ field, setValue, value }) {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selected, setSelected] = useState();

  const currencyStore = useSelector((state) => state.Products.currencyList);

  useEffect(() => {
    if (Array.isArray(currencyStore.list)) {
      const newCurrency = currencyStore.list.filter((each) => !each.deactivated);
      setCurrencyOptions(newCurrency);
    }
  }, [currencyStore.list]);

  useEffect(() => {
    if (Array.isArray(currencyStore.list)) {
      let selectedCurrency = currencyStore.list.find((currency) => currency["code"] === value);
      setSelected(selectedCurrency);
    }
  }, [currencyStore.list, value]);

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected.name || value}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {currencyStore.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {currencyOptions.map((currency, index) => (
              <Dropdown.Item
                className="dropdown-hover"
                key={index}
                onSelect={() => {
                  setSelected(currency);
                  setValue(currency.code, field.apiKeyName);
                }}
              >
                {currency.code} - {currency.name}
              </Dropdown.Item>
            ))}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function CurrencySelectForCompanySettings({ field, setValue, value }) {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selected, setSelected] = useState();

  const currencyStore = useSelector((state) => state.Products.currencyList);

  useEffect(() => {
    if (Array.isArray(currencyStore.list)) {
      const newCurrency = currencyStore.list.filter((each) => !each.deactivated);
      setCurrencyOptions(newCurrency);
    }
  }, [currencyStore.list]);

  useEffect(() => {
    if (Array.isArray(currencyStore.list)) {
      let selectedCurrency = currencyStore.list.find((currency) => currency["code"] === value);
      setSelected(selectedCurrency);
    }
  }, [currencyStore.list, value]);

  // const [selected, setSelected] = useState(currencyStore.list.find(currency => currency["code"]===value))

  return (
    <Dropdown className="dropdown-inline mt-1 custom-currency-container w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected.name || value}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn mt-16px">
        {currencyStore.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative overflow-hidden" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {/* <div className="overflow-auto position-relative h-25vh-mx"> */}
            {currencyOptions.map((currency, index) => (
              <Dropdown.Item
                className="dropdown-hover"
                key={index}
                onSelect={() => {
                  setSelected(currency);
                  setValue(currency.code, field.apiKeyName);
                }}
              >
                {currency.code} - {currency.name}
              </Dropdown.Item>
            ))}
            {/* </div> */}
          </PerfectScrollbar>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
