import { getListOfVideos, getSelectedVideo, createVideo, deleteVideo, editVideo } from "../types/video.types";

export const getListOfVideosRequest = (req, cb) => ({
  type: getListOfVideos.GET_LIST_OF_VIDEOS_REQUEST,
  payload: req,
  cb,
});
export const getListOfVideosSuccess = (req) => ({
  type: getListOfVideos.GET_LIST_OF_VIDEOS_SUCCESS,
  payload: req,
});
export const getListOfVideosError = (req) => ({
  type: getListOfVideos.GET_LIST_OF_VIDEOS_ERROR,
  payload: req,
});

export const getSelectedVideoRequest = (req, cb) => ({
  type: getSelectedVideo.GET_SELECTED_VIDEO_REQUEST,
  payload: req,
  cb,
});
export const getSelectedVideoSuccess = (req) => ({
  type: getSelectedVideo.GET_SELECTED_VIDEO_SUCCESS,
  payload: req,
});
export const getSelectedVideoError = (req) => ({
  type: getSelectedVideo.GET_SELECTED_VIDEO_ERROR,
  payload: req,
});

export const createVideoRequest = (req, cb) => ({
  type: createVideo.CREATE_VIDEO_REQUEST,
  payload: req,
  cb,
});
export const createVideoSuccess = (req) => ({
  type: createVideo.CREATE_VIDEO_SUCCESS,
  payload: req,
});
export const createVideoError = (req) => ({
  type: createVideo.CREATE_VIDEO_ERROR,
  payload: req,
});

export const editVideoRequest = (req, cb) => ({
  type: editVideo.EDIT_VIDEO_REQUEST,
  payload: req,
  cb,
});
export const editVideoSuccess = (req) => ({
  type: editVideo.EDIT_VIDEO_SUCCESS,
  payload: req,
});
export const editVideoError = (req) => ({
  type: editVideo.EDIT_VIDEO_ERROR,
  payload: req,
});

export const deleteVideoRequest = (req, cb) => ({
  type: deleteVideo.DELETE_VIDEO_REQUEST,
  payload: req,
  cb,
});
export const deleteVideoSuccess = (req) => ({
  type: deleteVideo.DELETE_VIDEO_SUCCESS,
  payload: req,
});
export const deleteVideoError = (req) => ({
  type: deleteVideo.DELETE_VIDEO_ERROR,
  payload: req,
});
