const microServices = {
  // admin settings
  ADMIN_SETTINGS_TEAMS: "/api/crm",
  ADMIN_SETTINGS_COMPANY: "/user-service/api/billing",
  FORM_FIELDS: "/api/crm/data-fields",
  TAG_FUNCTION: "/api/crm/tag",
  MANAGE_USERS: "/user-service/api/user",

  // profile settings
  EMAIL_SYNC: "/api/crm/email-sync-settings",
  CALENDAR_SYNC: "/api/crm/calendar-sync-settings",
};

export const endpoints = {
  //manage users
  GET_USER: `${microServices.ADMIN_SETTINGS_TEAMS}/profile/all`,
  DELETE_USER: `${microServices.ADMIN_SETTINGS_TEAMS}/user/delete/`,
  POST_USER: `${microServices.MANAGE_USERS}/invite`,
  RESEND_INVITATION: `${microServices.MANAGE_USERS}/reinvite`,
  PATCH_USER_ROLE: `${microServices.ADMIN_SETTINGS_TEAMS}/user/role/`,

  // teams
  TEAMS: `${microServices.ADMIN_SETTINGS_TEAMS}/team`,
  UPDATE_TEAMS: `${microServices.ADMIN_SETTINGS_TEAMS}/team/update-team`,
  LIST_OF_USERS: `${microServices.ADMIN_SETTINGS_TEAMS}/profile/list`,
  USER_ROLES: `${microServices.ADMIN_SETTINGS_TEAMS}/profile/roles-wise-user-list`,

  // company
  ACCOUNT_BILLING: `${microServices.ADMIN_SETTINGS_COMPANY}/get-account-billing`,
  CURRENCY: `${microServices.ADMIN_SETTINGS_TEAMS}/currency`,

  // currency
  GET_CURRENCY: `${microServices.ADMIN_SETTINGS_TEAMS}/currency`,

  // developer
  ADD_WEBHOOK: `${microServices.ADMIN_SETTINGS_TEAMS}/webhook/v2`,
  TEST_WEBHOOK: `${microServices.ADMIN_SETTINGS_TEAMS}/webhook/v2/test`,
  DISABLE_WEBHOOK: `${microServices.ADMIN_SETTINGS_TEAMS}/webhook/v2/status`,
  DEVELOPER_KEY: `${microServices.ADMIN_SETTINGS_TEAMS}/key`,

  // custom field
  EDIT_FORM_FIELDS: `${microServices.FORM_FIELDS}/system-edit`,
  GET_FORM_FIELDS: `${microServices.FORM_FIELDS}/add-form-fields`,
  REORDER_FORM_FIELDS: `${microServices.FORM_FIELDS}/reorder-add-form`,
  USAGE_REPORTS: `${microServices.ADMIN_SETTINGS_TEAMS}/reports-usage`,
  PATCH_CUSTOM_FIELD: `${microServices.FORM_FIELDS}/custom-edit`,
  POST_CUSTOM_FIELD: `${microServices.FORM_FIELDS}/create-custom`,
  LEAD_FIELDS: `${microServices.FORM_FIELDS}/get`,

  // lost reasons
  LOST_REASONS: `${microServices.ADMIN_SETTINGS_TEAMS}/lost-reason`,
  ACTIVITY_LOSS_REASONS: `${microServices.ADMIN_SETTINGS_TEAMS}/account-settings/activity-lost-reason-settings`,

  // activity
  ACTIVITY: `${microServices.ADMIN_SETTINGS_TEAMS}/activity`,

  // tag settings
  GET_TAG: `${microServices.TAG_FUNCTION}/settings/get`,
  ADD_TAG: `${microServices.TAG_FUNCTION}/create`,
  EDIT_TAG: `${microServices.TAG_FUNCTION}/edit`,
  DELETE_TAG: `${microServices.TAG_FUNCTION}/delete`,
  BULK_DELETE_TAG: `${microServices.TAG_FUNCTION}/settings/bulk-delete`,

  // PROFILE SETTINGS

  // profile overview
  GET_USER_PROFILE: `${microServices.ADMIN_SETTINGS_TEAMS}/profile`,
  PROFILE_AVATAR: `${microServices.ADMIN_SETTINGS_TEAMS}/profile/avatar`,

  // sign in
  FACTOR_AUTH: `${microServices.MANAGE_USERS}`,
  RESET_PASSWORD: `${microServices.MANAGE_USERS}/password`,
  RESET_EMAIL: `${microServices.MANAGE_USERS}/email`,

  // company list
  COMPANY_LIST: `${microServices.MANAGE_USERS}/list-of-companies`,

  // email sync
  GOOGLE_EMAIL: `${microServices.EMAIL_SYNC}/google`,
  GET_EMAIL_SYNC: `${microServices.EMAIL_SYNC}/get-account-details`,
  UPDATE_EMAIL: `${microServices.EMAIL_SYNC}/update-settings`,
  ADD_EMAILIN_IGNORELIST: `${microServices.EMAIL_SYNC}/add-ignore-address`,
  REMOVE_EMAIL: `${microServices.EMAIL_SYNC}/remove-ignore-address`,
  HOSTED_EMAIL_AUTH: `${microServices.EMAIL_SYNC}/hosted-auth`,
  HOSTED_EMAIL_CALLBACK: `${microServices.EMAIL_SYNC}/hosted-auth-callback`,

  // calendar sync
  GET_CALENDAR_SYNC: `${microServices.CALENDAR_SYNC}/get-account-details`,
  CALENDAR_LIST: `${microServices.CALENDAR_SYNC}/calender-list`,
  DELETE_CALENDAR: `${microServices.CALENDAR_SYNC}/delete-account`,
  DISCONNECT_CALENDAR: `${microServices.CALENDAR_SYNC}/disconnect`,
  RECONNECT_CALENDAR: `${microServices.CALENDAR_SYNC}/reconnect`,
  UPDATE_CALENDAR: `${microServices.CALENDAR_SYNC}/update-settings`,
  HOSTED_CALENDAR: `${microServices.CALENDAR_SYNC}/hosted-auth`,
  HOSTED_CALENDAR_CALLBACK: `${microServices.CALENDAR_SYNC}/hosted-auth-callback`,

  // notification
  NOTIFICATION: `${microServices.ADMIN_SETTINGS_TEAMS}/notification`,
};
