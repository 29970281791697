import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as subscriptionV2Actions from "../../../store/actions/subscription_billing_v2_actions";

// components
import SubscriptionExpired from "../components/subscription-expired";
import { AgentNotAllowed } from "../components/agent-not-allowed";

import { ContentWithCardLoading } from "../../../../../../components/loading";
import ContentWrapper from "../../../../../Wrapper";
import BillingSubHeader from "../../../components/billing-sub-header";
import UsageDetailsV2 from "../components/usage-details";
import PlanNameSection from "../components/plan-sections/plan-name.section";
import CustomerPortalSection from "../components/plan-sections/customer-portal.section";
import UsersBarSection from "../components/plan-sections/users-bar.section";
import UsageBarSection from "../components/plan-sections/usage-bar.section";
import { GreenGradientButton } from "../../../../../../components/button";
import NextRenewalSection from "../components/plan-sections/next-renewal.section";
import { ConfirmationModal } from "../../../../../../components/modal";
import SubscriptionModal from "../subscription-modal";

export default function SubscriptionBillingV2() {
    const [userType, setUserType] = useState("");
    const [isBillingExpired, setIsBillingExpired] = useState(false);

    // plans modal
    const [showPlans, setShowPlans] = useState(false);
    const [plansPlanPage, setPlansPlanPage] = useState("");
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [subscriptionDetailedText, setSubscriptionDetailedText] = useState("");
    const [allPriceMapping, setAllPriceMapping] = useState({});

    const dispatch = useDispatch();

    const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
    const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
    const pricesStore = useSelector((state) => state.Billing.subscriptionv2.prices);

    useEffect(() => {
        dispatch(subscriptionV2Actions.getAccountBillingV2Request())
        dispatch(subscriptionV2Actions.getBillingPricesV2Request())
    }, [dispatch])

    useEffect(() => {
        if (userProfile.data.userType) setUserType(userProfile.data.userType)
    }, [userProfile.data]);

    useEffect(() => {
        if (!_.isEmpty(accountBillingStore.data)) {
            const { isFreeTrialPeriod, isSubscribedForLifeTimeFree, subscriptionStatus } = accountBillingStore.data;
            if (isFreeTrialPeriod || isSubscribedForLifeTimeFree || subscriptionStatus === "active" || subscriptionStatus === "trialing") {
                setIsBillingExpired(false)
            }
            else {
                setIsBillingExpired(true)
            }
        }
    }, [accountBillingStore.data])

    useEffect(() => {
        if (!_.isEmpty(accountBillingStore.data) && !_.isEmpty(allPriceMapping)) {
            // Monthly Subscription 58$ (2 * $29 Startup Plan + 1* 5$ Email Sync Addon)
            const { planCurrency, planInterval, planPrice, plans } = accountBillingStore.data;
            const planSymbol = planCurrency.toLowerCase() === "inr" ? "₹" : "$";

            let _text = "";
            if (planInterval === "monthly") _text += "Monthly Subscription ";
            else _text += "Annual Subscription ";

            _text += `${planSymbol} ${planPrice * 0.01} `;


            let _planText = "";
            for (let i = 0; i < plans.length; i++) {
                const plan = plans[i];
                const planData = allPriceMapping[plan.planId]
                if (planData === undefined) continue;
                const quantity = plan.quantity;
                const price = planData.prices[_.upperCase(planCurrency)]
                if (Number(price) === 0) continue;
                if (quantity === 1) {
                    _planText += `${planSymbol} ${price}  ${planData.planName} Plan + `;
                }
                else _planText += `${quantity} * ${planSymbol} ${price}  ${planData.planName} Plan + `;
            }

            _planText = _planText.slice(0, -3);
            _text += `(${_planText})`;

            setSubscriptionDetailedText(_text);
        }
    }, [accountBillingStore.data, allPriceMapping]);

    useEffect(() => {
        if (_.isEmpty(pricesStore.data)) return;
        const { addOns, plans } = pricesStore.data;
        const _allPriceMapping = {};
        const __aa = {
            "businessPlan": "Business",
            "businessPlusPlan": "Business Plus",
            "lifeTimeFree": "Life Time Free",
            "startUpPlan": "Startup",
            "emailSync": "Email Sync Addon",
        }
        for (const keys in plans) {
            const monthlyPlanId = plans[keys]["MONTHLY"].PLAN_ID;
            const annualPlanId = plans[keys]["YEARLY"].PLAN_ID;
            const monthlyPrices = plans[keys]["MONTHLY"].PRICES;
            const annualPrices = plans[keys]["YEARLY"].PRICES;
            _allPriceMapping[monthlyPlanId] = {
                planName: __aa[keys] || keys,
                prices: monthlyPrices,
            }
            _allPriceMapping[annualPlanId] = {
                planName: __aa[keys] || keys,
                prices: annualPrices,
            }
        }
        for (const keys in addOns) {
            const monthlyPlanId = addOns[keys]["MONTHLY"].PLAN_ID;
            const annualPlanId = addOns[keys]["YEARLY"].PLAN_ID;
            const monthlyPrices = addOns[keys]["MONTHLY"].PRICES;
            const annualPrices = addOns[keys]["YEARLY"].PRICES;
            _allPriceMapping[monthlyPlanId] = {
                planName: __aa[keys] || keys,
                prices: monthlyPrices,
            }
            _allPriceMapping[annualPlanId] = {
                planName: __aa[keys] || keys,
                prices: annualPrices,
            }
        }
        setAllPriceMapping(_allPriceMapping);
    }, [pricesStore.data])


    const onUpgradePlanClick = (e) => {
        setShowPlans(true);
        setPlansPlanPage(e);
    };

    const onRenewCancelSubscriptionClick = (type) => {
        // TODO: renew or cancel subscription
    };

    const getPlanPricingtText = () => {
        if (accountBillingStore.data.selectedPlan === "enterprise") {
            return (
                <div>
                    <span className="text-gray-800 fw-bolder me-1">
                        {accountBillingStore.data.planCurrency.toLowerCase() === "inr" ? "INR " : "$ "}
                        <span>{accountBillingStore.data.planPrice * 0.01}</span>
                    </span>
                    <span className="text-gray-600 fw-bold">
                        Per <span className="text-capitalize">{accountBillingStore.data.planInterval}</span>
                    </span>
                    <div className="fs-6 text-gray-600 fw-bold mb-6">It's enterprise account. You can contact your account manager or Support Team for billing-related queries and invoices.</div>
                </div>
            );
        } else if (accountBillingStore.data.isFreeTrialPeriod) {
            return (
                <div>
                    <span className="text-gray-800 fw-bolder me-1">     $0  <span className="ml-8px text-decoration-line-through">$29</span>
                    </span>
                    <span className="text-gray-600 fw-bold">
                        {" "}
                        <span> /month/user</span>
                    </span>
                    <div className="fs-6 text-gray-600 fw-bold mb-6">It's a free trial, unlimited users</div>
                </div>
            );
        } else if (accountBillingStore.data.selectedPlan === "free") {
            return (
                <div>
                    <span className="text-gray-800 fw-bolder me-1">$0</span>
                    <span className="text-gray-600 fw-bold">
                        {" "}
                        <span> /month/user</span>
                    </span>
                    <div className="fs-6 text-gray-600 fw-bold mb-6">It's a free account, 200 records and unlimited users</div>
                </div>
            );
        } else if (accountBillingStore.data.isSubscribedForLifeTimeFree) {
            if (accountBillingStore.data.subscriptionStatus === "active") {
                return <div>
                    <span className="text-gray-800 fw-bolder me-1">
                        {accountBillingStore.data.planCurrency.toLowerCase() === "inr" ? "INR " : "$ "}
                        <span>{accountBillingStore.data.planPrice * 0.01}</span>
                    </span>
                    <span className="text-gray-600 fw-bold">
                        Per <span className="text-capitalize">{accountBillingStore.data.planInterval}</span>
                    </span>
                    <div className="fs-6 text-gray-600 fw-bold mb-6">{subscriptionDetailedText}</div>
                </div>

            }
            return (
                <div>
                    <span className="text-gray-800 fw-bolder me-1">One time offer </span>
                    <div className="fs-6 text-gray-600 fw-bold mb-6">Limited business account, upgrade to next plan to get additional records and additional salessimplify functionalities</div>
                </div>
            );
        } else {
            return (
                <div>
                    <span className="text-gray-800 fw-bolder me-1">
                        {accountBillingStore.data.planCurrency.toLowerCase() === "inr" ? "INR " : "$ "}
                        <span>{accountBillingStore.data.planPrice * 0.01}</span>
                    </span>
                    <span className="text-gray-600 fw-bold">
                        Per <span className="text-capitalize">{accountBillingStore.data.planInterval}</span>
                    </span>
                    <div className="fs-6 text-gray-600 fw-bold mb-6">{subscriptionDetailedText}</div>
                </div>
            );
        }
    };


    return <ContentWrapper subHeader={<BillingSubHeader tabName="Subscription" />}>
        {accountBillingStore.loading || (_.isEmpty(accountBillingStore.data)) ? <ContentWithCardLoading /> :
            isBillingExpired ? <SubscriptionExpired setShowPlans={setShowPlans} /> :
                userType === "AGENT" ? <AgentNotAllowed />
                    : <div className="overflow-auto h-vh-162px">
                        <div className="post d-flex flex-column-fluid flex-direction-column" id="kt_post">
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-body">
                                    <div className={`notice d-flex align-items-center mb-12 mt-4 `}>
                                        <PlanNameSection data={accountBillingStore.data} />
                                        <CustomerPortalSection data={accountBillingStore.data} />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7 pr-10-percent">
                                            <div className="fs-5 mb-2 mb-lg-12">
                                                {getPlanPricingtText()}
                                                {
                                                    _.isEmpty(accountBillingStore.data.scheduledChange) ?
                                                        accountBillingStore.data.subscriptionStatus === "active" && ["startup", "business", "businessPlus", "lifeTimeFree"].includes(accountBillingStore.data.selectedPlan) ? (
                                                            <div>
                                                                <GreenGradientButton className="mr-8px" onClick={() => onUpgradePlanClick("plan")}>
                                                                    Update Subscription
                                                                </GreenGradientButton>
                                                            </div>
                                                        ) : accountBillingStore.data.selectedPlan !== "enterprise" ? (
                                                            <GreenGradientButton className="mr-8px" onClick={() => onUpgradePlanClick()}>
                                                                Subscribe
                                                            </GreenGradientButton>
                                                        ) : (
                                                            <div className="h-41px"></div>
                                                        ) : <div className="h-41px"></div>}
                                            </div>
                                            <NextRenewalSection data={accountBillingStore.data} setShowCancelConfirmation={setShowCancelConfirmation} onRenewCancelSubscriptionClick={onRenewCancelSubscriptionClick} />
                                        </div>
                                        <div className="col-lg-5">
                                            <UsersBarSection data={accountBillingStore.data} />
                                            <UsageBarSection data={accountBillingStore.data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <UsageDetailsV2 />
                    </div>

        }
        <ConfirmationModal
            show={showCancelConfirmation}
            onHide={() => setShowCancelConfirmation(false)}
            body={"Are you sure you want to cancel your billing subscription?"}
            onNoClick={() => setShowCancelConfirmation(false)}
            onYesClick={() => {
                onRenewCancelSubscriptionClick("cancel");
                setShowCancelConfirmation(false);
            }}
        />
        <SubscriptionModal showPlans={showPlans} setShowPlans={setShowPlans} plansPlanPage={plansPlanPage} />
    </ContentWrapper>

}