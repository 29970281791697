import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SimpleDropdown from "../../../../../../components/dropdowns/simple-dropdown";
import { futureEventTimeMonthsOptions, futureEventTimeTypeOptions, futuureEventDaysOptions, futuureEventWeekOptions, infoTextMessages } from "../../constants";

export default function FutureTimeEventDropdown(props) {
  // duration
  const [eventTimeType, setEventTimeType] = useState({ value: "days", label: "Days" });
  const [eventTime, setEventTime] = useState({ value: 60, label: "60" });

  useEffect(() => {
    if (props.value) {
      const newValue = props.value * 24 * 60;
      const data = futuureEventDaysOptions.find((each) => each.duration === newValue);
      if (data) {
        setEventTimeType({ value: "days", label: "Days" });
        setEventTime(data);
      } else {
        const data2 = futuureEventWeekOptions.find((each) => each.duration === newValue);
        if (data2) {
          setEventTimeType({ value: "weeks", label: "Weeks" });
          setEventTime(data2);
        } else {
          const data3 = futureEventTimeMonthsOptions.find((each) => each.duration === newValue);
          if (data3) {
            setEventTimeType({ value: "months", label: "Months" });
            setEventTime(data3);
          }
        }
      }
    }
  }, [props.value]);

  const handleEventTypeChange = (data) => {
    setEventTimeType(data);
    if (data.value === "days") {
      setEventTime({ value: 60, label: "60" });
      props.setValue(1);
    } else if (data.value === "weeks") {
      setEventTime({ value: 1, label: "1" });
      props.setValue(7);
    } else {
      setEventTime({ value: 1, label: "1" });
      props.setValue(30);
    }
  };
  const handlEventTimeSelection = (data) => {
    props.setValue(data.duration / 24 / 60);
  };
  return (
    <Form.Group>
      <Form.Label>
        Future Limit
        <OverlayTrigger placement="right" overlay={<Tooltip>{infoTextMessages.FUTURE_LIMIT}</Tooltip>}>
          <i className="ml-2 bi bi-info-circle"></i>
        </OverlayTrigger>
      </Form.Label>
      <Row>
        <Col>
          <SimpleDropdown
            value={eventTime}
            label={"Select"}
            options={eventTimeType.value === "days" ? futuureEventDaysOptions : eventTimeType.value === "weeks" ? futuureEventWeekOptions : futureEventTimeMonthsOptions}
            handleSelect={(data) => handlEventTimeSelection(data)}
          />
        </Col>
        <Col>
          <SimpleDropdown value={eventTimeType} label={"Select"} options={futureEventTimeTypeOptions} handleSelect={(a) => handleEventTypeChange(a)} />
        </Col>
      </Row>
    </Form.Group>
  );
}
