import React from "react";
import "./styles.scss";

export default function ContactSales(props) {
  return (
    <div className="contact-sales-container">
      <div className="inner-text">Need custom solution or have more users?</div>
      <a href="https://salessimplify.com/contact-sales" target="_blank" rel="noopener noreferrer" className="link inner-button">
        Contact Sales
        <br />
      </a>
    </div>
  );
}
