import React, { useState } from "react";
// import DealSubHeader from './dealSubheader';
import DealForecastWrapper from "./dealForecast/foreCast";
import { CustomSubHeader } from "../../components/subheader";
import { BlueGradientButton } from "../../components/button";
import { CustomScrollableModal } from "../../components/modal";
import { daysFilter } from "../../components/days-filter/daysFilter";
import { decreaseDate } from "../../components/days-filter/dayWiseFilter.js";
import DealTypesGroup from "./dealGroup/index";
// import DealPipelineWrapper from './dealPipeline/index';
import { AddDealModal } from "./common/dealModal/index";
import Stages from "./stages";
import { Dropdown, Form } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import dayjs from "dayjs";
import "./styles.css";
import { useSelector } from "react-redux";
import { InputLimit } from "../../constants";
import ContentWrapper from "../Wrapper";

export default function DealForeCast() {
  const [openAddDealModal, setOpenAddDealModal] = useState(false);
  const [pipeline, setPipeline] = useState("Pipeline");
  const [activePipeline, setActivePipeline] = useState(0);
  const [pipelineName, setPipelineName] = useState("");
  const [stages, setStages] = useState([{}]);
  const [pipelineModalOpen, setPipelineModalOpen] = useState(false);
  const [pipelineOptions, setPipelineOptions] = useState([
    { name: "pipeline-1", id: "1234" },
    { name: "pipleline-2", id: "4567" },
  ]);
  const [daysFilterOption, setDaysFilterOption] = useState("Last 7 Days");
  const [toDate, setToDate] = useState(new Date().getTime());
  const [fromDate, setFromDate] = useState(decreaseDate(7));
  const [displayDateFilter, setDisplayDateFilter] = useState(false);
  const [focusedInput, setFocusedInput] = useState("");
  const [currentDate, setCurrentDate] = useState(dayjs());
  const dealTagStore = useSelector((state) => state.DealPipeline.dealTags.list);

  const handleAddDeal = () => {
    setOpenAddDealModal(true);
  };

  const onPipelineChange = (pipeline, index) => {
    setPipeline(pipeline.name);
    setActivePipeline(index);
  };

  const onAddDealModalClose = () => {
    setOpenAddDealModal(false);
  };

  const onAddDealModalSave = () => {
    setOpenAddDealModal(false);
  };

  const onAddDealName = (value) => {};

  const onAddOrganization = (value) => {};

  const onAddDealValue = (value) => {};

  const onDealPipelineselect = (value) => {};

  const onExpectedCloseDateSelect = (value) => {
    //e.target.value
  };

  const onPipelineModalClose = () => {
    setPipelineName("");
    setStages([]);
    setPipelineModalOpen(false);
  };

  const onPipelineModalSave = () => {
    setStages([]);
    setPipelineModalOpen(false);
    setPipelineOptions(pipelineOptions);
  };

  const onPipelineNameChange = (e) => {
    setPipelineName(e.target.value);
  };

  const onPipelineNameBlur = () => {
    setPipelineOptions({ name: pipelineName, id: Math.random().toString() });
  };

  const onChangeStages = (stages) => {
    setStages(stages);
  };

  const onDaysFilterClick = (value) => {
    const data = daysFilter(value);
    if (data.filterType === "Custom") {
      data.start_time = fromDate;
      data.end_time = toDate;
      setDisplayDateFilter(true);
    } else {
      setDisplayDateFilter(false);
    }
    setDaysFilterOption(data.filterType);
    setFromDate(data.start_time);
    setToDate(data.end_time);
    // setShowRangepicker(false)
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment().endOf("day");
  };

  const onNewDatePickerRangeChange = (startDate, endDate) => {
    if (startDate) {
      setFromDate(new Date(startDate).getTime());
      if (focusedInput === "endDate" && new Date(startDate).getTime() <= new Date(endDate).getTime()) {
        setFromDate(new Date(startDate).getTime());
        setToDate(new Date(endDate).setHours(23, 59, 59, 999));
        //   setShowRangepicker(true)
        setDaysFilterOption("Custom");
      }
    }
  };

  const onPreviousClick = () => {
    const currentDate1 = previousMonth(currentDate);
    setCurrentDate(currentDate1);
  };

  const onNextClick = () => {
    const currentDate1 = nextMonth(currentDate);
    setCurrentDate(currentDate1);
  };

  const setCurrentTodayDate = () => {
    setCurrentDate(getCurrentDate);
  };

  const getCurrentDate = () => dayjs();

  const nextMonth = (date) => {
    return date.add(1, "month");
  };

  const previousMonth = (date) => {
    return date.subtract(1, "month");
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <div className="sub-header-wrapper">
            <div className="d-flex">
              <DealTypesGroup />
            </div>
            <div className="d-flex">
              <div className="ml-16px">
                <BlueGradientButton onClick={() => handleAddDeal()} variant="success" size="sm">
                  + Deal
                </BlueGradientButton>
              </div>
              <AddDealModal
                dealModalProps={{
                  openAddDealModal: openAddDealModal,
                  onAddDealModalClose: () => onAddDealModalClose(),
                  onAddDealModalSave: () => onAddDealModalSave(),
                  onAddDealName: (e) => onAddDealName(e),
                  onAddOrganization: (e) => onAddOrganization(e),
                  onAddDealValue: (e) => onAddDealValue(e),
                  onDealPipelineselect: (e) => onDealPipelineselect(e),
                  onExpectedCloseDateSelect: (e) => onExpectedCloseDateSelect(e),
                  tags: dealTagStore,
                }}
              />
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="pipeline-dropdown">
                  {pipeline}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {pipelineOptions.map((pipeline, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onClick={() => onPipelineChange(pipeline, index)}>
                      {pipeline.name}
                      {index === activePipeline ? (
                        <span className="ml-45px pt-2px float-right">
                          <i className="fa fa-check-circle" aria-hidden="true"></i>
                        </span>
                      ) : null}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  <Dropdown.Item className="dropdown-hover" onClick={() => setPipelineModalOpen(true)}>
                    + Add Pipeline
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="pipeline-edit">
                <i onClick={() => setPipelineModalOpen(true)} className="flaticon-edit icon-nm edit-icon ml-2px text-black"></i>
              </div>
              <CustomScrollableModal
                modalProps={{
                  show: pipelineModalOpen,
                  handleClose: () => onPipelineModalClose(),
                  title: "Add Pipeline",
                  showButtons: true,
                  buttonText: "Save",
                  handleAccept: () => onPipelineModalSave(),
                }}
              >
                <Form.Group id="deals-deal-fore-cast-pipeline-name">
                  <Form.Label aria-label="pipeline-name">Pipeline Name </Form.Label>
                  <Form.Control maxLength={InputLimit.TEXT} type="text" onChange={(e) => onPipelineNameChange(e)} onBlur={() => onPipelineNameBlur()} />
                </Form.Group>
                <Form.Label aria-label="stages">Stages</Form.Label>
                <Stages
                  // options={options}
                  // type={this.props.type}`
                  type={"deal"}
                  stages={stages}
                  onChangeStages={onChangeStages}
                />
              </CustomScrollableModal>
              <div className="ml-10px">
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => onPreviousClick()}>
                  <i className="bi bi-arrow-left"></i>
                </button>
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => setCurrentTodayDate()}>
                  today
                </button>
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => onNextClick()}>
                  <i className="bi bi-arrow-right"></i>
                </button>
              </div>
              <div className="d-flex ml-10px">
                <Dropdown className="dropdown-inline">
                  <Dropdown.Toggle className="btn  btn-sm font-weight-bolder dropdown-toggle" variant="secondary" id="dropdown-toggle-top">
                    {daysFilterOption}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key="today" className="dropdown-hover" onClick={() => onDaysFilterClick("today")}>
                      Today
                    </Dropdown.Item>
                    <Dropdown.Item key="this_week" className="dropdown-hover" onClick={() => onDaysFilterClick("this_week")}>
                      This Week
                    </Dropdown.Item>
                    <Dropdown.Item key="last_week" className="dropdown-hover" onClick={() => onDaysFilterClick("last_week")}>
                      Last Week
                    </Dropdown.Item>
                    <Dropdown.Item key="last_7_days" className="dropdown-hover" onClick={() => onDaysFilterClick("last_7_days")}>
                      Last 7 Days
                    </Dropdown.Item>
                    <Dropdown.Item key="past_30_days" className="dropdown-hover" onClick={() => onDaysFilterClick("past_30_days")}>
                      Last 30 Days
                    </Dropdown.Item>
                    <Dropdown.Item key="this_month" className="dropdown-hover" onClick={() => onDaysFilterClick("this_month")}>
                      This Month
                    </Dropdown.Item>
                    <Dropdown.Item key="last_month" className="dropdown-hover" onClick={() => onDaysFilterClick("last_month")}>
                      Last Month
                    </Dropdown.Item>
                    <Dropdown.Item key="custom" className="dropdown-hover" onClick={() => onDaysFilterClick("custom")}>
                      Custom
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="date-filter-custom">
                  {displayDateFilter ? (
                    <DateRangePicker
                      startDate={moment(fromDate)} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={moment(toDate)} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => onNewDatePickerRangeChange(startDate, endDate)} // PropTypes.func.isRequired,
                      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput);
                      }} // PropTypes.func.isRequired,
                      displayFormat={"YYYY-MM-DD"}
                      small={true}
                      isOutsideRange={disabledDate}
                      readOnly={true}
                      minimumNights={0}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </CustomSubHeader>
      }
    >
      <div>
        <DealForecastWrapper currentDate={currentDate} />
      </div>
    </ContentWrapper>
  );
}
