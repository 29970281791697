import React from "react";
import moment from "moment";
import { Form, Col, Row, ListGroup, Spinner, Button } from "react-bootstrap";
import { CustomCheckBox } from "../../../../../components/custom-inputs/checkbox-selector";
import TimeZoneSelect from "../../../../../components/custom_form/timezone";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import { TimeIntervalOptions } from "../../../constants";
import MessageStep3 from "./message.step3";
import { BlueGradientButton } from "../../../../../components/button";
import { bytesToSize } from "../../../../../utils/index.util";
import InlineStyled from "../../../../../components/inline-styled/inline-styled";

export default function ScheduleStep4(props) {
  return (
    <div className="schedule-step4-wrapper">
      <MessageStep3
        channel={props.channel}
        config={props.config}
        attachments={props.attachments}
        // template
        isTemplate={props.isTemplate}
        template={props.template}
        templateId={props.templateId}
        setIsTemplate={props.setIsTemplate}
        setTemplateId={props.setTemplateId}
        setTemplate={props.setTemplate}
        setConfig={props.setConfig}
        defaultVariableValues={props.defaultVariableValues}
        setDefaultVariableValues={props.setDefaultVariableValues}
        uploadedfileVariables={props.uploadedfileVariables}

        // whastapp
        selectedWhatsappTemplateData={props.selectedWhatsappTemplateData}
        setSelectedWhatsappTemplateData={props.setSelectedWhatsappTemplateData}
      />
      <Form.Group id="campaign-builder-steps-schedule-step4-checkbox" className="d-flex">
        <CustomCheckBox label={"Schedule"} isChecked={props.isScheduled} onClick={() => props.setIsScheduled(!props.isScheduled)} />
        <CustomCheckBox label={"Split Campaign"} isChecked={props.isSplitCampaign} onClick={() => props.setIsSplitCampaign(!props.isSplitCampaign)} />
        {["email"].includes(props.channel) && (
          <CustomCheckBox
            label={"Attachment"}
            isChecked={props.isAttachment}
            onClick={() => props.setIsAttachment(!props.isAttachment)}
            info={"Email attachments will be charged on per GB basis. Please check pricing for more information."}
          />
        )}
        {["email"].includes(props.channel) && (
          <CustomCheckBox
            label={"Track Open"}
            isChecked={props.trackOpen}
            onClick={() => props.setTrackOpen(!props.trackOpen)}
            info={"Find Out the exact number of times your recipients attention got captivated to open email communication you sent."}
          />
        )}
        {["email"].includes(props.channel) && (
          <CustomCheckBox
            label={"Track Click"}
            isChecked={props.trackClick}
            onClick={() => props.setTrackClick(!props.trackClick)}
            info={"Find out the recipients were interested enough not only to open your campaign but also to interect with them."}
          />
        )}
        {["sms"].includes(props.channel) && (
          <CustomCheckBox label={"Send Flash"} isChecked={props.config.isFlash} onClick={() => props.setConfig({ ...props.config, isFlash: !props.config.isFlash })} info={"Send message as flash message."} />
        )}
      </Form.Group>
      {props.isScheduled && (
        <Row>
          <Col>
            <Form.Group id="campaign-builder-steps-schedule-step4-date-and-time">
              <Form.Label aria-label="schedule-date&time">{"Schedule Date & Time"}</Form.Label>
              <Form.Control
                min={moment()
                  .add(10, "minute")
                  .format("YYYY-MM-DDTHH:ss")}
                max={moment()
                  .add(30, "days")
                  .format("YYYY-MM-DDTHH:ss")}
                type="datetime-local"
                value={props.scheduledDate}
                onChange={(e) => props.setScheduledDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-builder-steps-schedule-step4-time-zone" className="">
              <Form.Label aria-label="timezone">Timezone</Form.Label>
              <TimeZoneSelect field={{ apiKeyName: "timezone", fieldName: "Timezone" }} value={props.scheduledTimezone} setValue={props.setScheduledTimezone} />
            </Form.Group>
          </Col>
        </Row>
      )}
      {props.isSplitCampaign && (
        <Row>
          <Col>
            <Form.Group id="campaign-builder-steps-schedule-step4-contact-per-batch">
              <Form.Label aria-label="contact-per-batch">Contact Per Batch</Form.Label>
              <SimpleDropdown options={props.splitOptions} value={props.contactPerBatch} label={"Time Interval"} handleSelect={props.setContactPerBatch} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group id="campaign-builder-steps-schedule-step4-time-intervals">
              <Form.Label aria-label="time-intervals">Time Intervals</Form.Label>
              <SimpleDropdown options={TimeIntervalOptions} value={props.splitTimeInterval} label={"Time Interval"} handleSelect={props.setSplitTimeInterval} />
            </Form.Group>
          </Col>
        </Row>
      )}

      {props.isAttachment && props.attachments.length >= 0 && (
        <Form.Group id="campaign-builder-steps-schedule-step4-file-attachments">
          {props.isAttachment && (
            <Form.Group id="campaign-builder-steps-schedule-step4-upload" className=" my-0">
              <label htmlFor="file-attachment" className="mr-2 h-33px">
                <div className="btn btn-sm d-flex gap-3 text-primary  border border-primary">
                  {props.attachmentLoading ? (
                    <>
                      <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>
                    </>
                  ) : (
                    <i className="bi bi-upload text-primary"></i>
                  )}
                  Upload
                </div>
              </label>
              <input id="file-attachment" onChange={props.handleAttachmentUpload} className="invisible w-1px" type={"file"} />
            </Form.Group>
          )}
          <ListGroup>
            {props.attachments.map((each, index) => {
              return (
                <ListGroup.Item key={index}>
                  <div className="d-flex justify-content-between">
                    <InlineStyled as="span" className="custom-ellipsis w-50-50px" styles={{ customWidth: "calc(50% - 50px - 50px)" }}>
                      {" "}
                      {each.filename}
                    </InlineStyled>
                    <InlineStyled as="span" className="custom-ellipsis w-25" styles={{ customWidth: "calc(25% - 50px)" }}>
                      {each.contenttype}
                    </InlineStyled>
                    <InlineStyled as="span" className="custom-ellipsis w-25" styles={{ customWidth: "calc(25% - 50px)" }}>
                      {bytesToSize(each.size)}
                    </InlineStyled>
                    <span className="cursor-pointer w-50px">
                      <i className="bi bi-trash" varient="danger" onClick={() => props.handleRemoveAttachment(index)} />
                    </span>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Form.Group>
      )}
      <Form.Group id="campaign-builder-steps-schedule-step4-sending" className="d-flex mt-10 mb-0">
        <Form.Group id="campaign-builder-steps-schedule-step4-cancel-button" className="m-0">
          <Button className="mr-2" variant="secondary" size="sm" onClick={props.handleCancel}>
            Cancel
          </Button>
        </Form.Group>

        <Form.Group id="campaign-builder-steps-schedule-step4-send-campaign" className="m-0">
          <BlueGradientButton disabled={props.loading} variant="success" size="sm" onClick={props.handleSendCampaignClick}>
            {props.loading && <Spinner animation="border" variant="light" className="mr-10px w-16px h-16px"></Spinner>}
            Send Campaign
          </BlueGradientButton>
        </Form.Group>
      </Form.Group>
    </div>
  );
}
