import { getCurrency } from "../../types/admin-settings.types";

const initState = {
  getCurrency: {
    loading: false,
    data: {},
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
};

export default function currencySettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCurrency.GET_CURRENCY_REQUEST:
      return {
        ...state,
        getCurrency: { ...state.getCurrency, loading: true },
      };
    case getCurrency.GET_CURRENCY_SUCCESS:
      return {
        ...state,
        getCurrency: { loading: false, list: action.paylaod.data },
      };
    case getCurrency.GET_CURRENCY_ERROR:
      return {
        ...state,
        getCurrency: { ...state.getCurrency, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    default:
      return state;
  }
}
