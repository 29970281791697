import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Form, ListGroup, Row, Col, Button, Dropdown, Spinner } from "react-bootstrap";

import { InputLimit } from "../../../constants";
import CustomFilterInput from "./custom-input";

import { pageMapping } from "../../../containers/Automation/workflow/workflow-builder/constants";
import _ from "lodash";

const getFirstDropdownOptions = ({ moduleName, dealColumns, companyColumns, peopleColumns, leadColumns, marketingColumns }) => {
  var tempColumns = [];
  var loading = true;

  const unwantedFieldData = {
    deal: ["MULTI_OWNER_SELECT", "DEAL_PRODUCT_INPUT"],
    company: [],
    people: ["lastActivityDate", "activitiesToDo", "doneActivities", "totalActivities"],
    lead: ["lastActivityDate", "activitiesToDo", "doneActivities", "totalActivities"],
    activity: [],
    product: [],
    marketing: [],
  };
  if (moduleName === "deal") {
    tempColumns = dealColumns.list.filter((each) => each.fieldInputType !== "MULTI_OWNER_SELECT" && each.fieldInputType !== "DEAL_PRODUCT_INPUT");
    loading = dealColumns.loading;
  } else if (moduleName === "company") {
    tempColumns = companyColumns.list;
    loading = companyColumns.loading;
  } else if (moduleName === "people" || moduleName === "person") {
    tempColumns = peopleColumns.list.filter((each) => each.fieldInputType !== "ACTIVITY_SHOW" && !unwantedFieldData["people"].includes(each.apiKeyName));
    loading = peopleColumns.loading;
  } else if (moduleName === "lead") {
    tempColumns = leadColumns.list.filter((each) => each.fieldInputType !== "ACTIVITY_SHOW" && !unwantedFieldData["lead"].includes(each.apiKeyName));
    loading = leadColumns.loading;
  } else if (moduleName === "marketing") {
    loading = marketingColumns.loading;
    tempColumns = marketingColumns.list;
  } else return;

  return { columns: tempColumns, loading };
};

export const useFirstDropdownOptions = (moduleName) => {
  const peopleColumns = useSelector((state) => state.People.peopleFields);
  const companyColumns = useSelector((state) => state.Companies.companyFields);
  const leadColumns = useSelector((state) => state.Leads.leadFields);
  const dealColumns = useSelector((state) => state.DealList.dealFields);
  const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields);

  const { columns, loading } = useMemo(() => getFirstDropdownOptions({ moduleName, peopleColumns, companyColumns, leadColumns, dealColumns, marketingColumns }) || {}, [
    moduleName,
    peopleColumns,
    companyColumns,
    leadColumns,
    dealColumns,
    marketingColumns,
  ]);

  return { columns, loading };
};

const getConditionMappingObj = (conditions) => {
  return conditions.data.reduce((acc, each) => {
    const filteredArr = each.conditions.filter((cond) => cond.comparator !== "isDueInDays" && cond.comparator !== "isAgeInDays");
    return { ...acc, [each.dataType]: filteredArr };
  }, {});
};

export const useConditionMapping = () => {
  const conditionsStore = useSelector((store) => store.workflowReducer.conditions);
  const conditionMapping = useMemo(() => getConditionMappingObj(conditionsStore), [conditionsStore]);

  return conditionMapping;
};

export default function ConditionForm(props) {
  const [fieldSearchText, setFieldSearchText] = useState("");
  const [conditionMapping, setConditionMapping] = useState({});

  const peopleColumns = useSelector((state) => state.People.peopleFields);
  const companyColumns = useSelector((state) => state.Companies.companyFields);
  const leadColumns = useSelector((state) => state.Leads.leadFields);
  const dealColumns = useSelector((state) => state.DealList.dealFields);
  const marketingColumns = useSelector((state) => state.campaign.marketing.dataField.fields);

  useEffect(() => {
    const conditionObj = {};
    props.conditionsStore.data.forEach((each) => {
      conditionObj[each.dataType] = each.conditions.filter((cond) => cond.comparator !== "isDueInDays" && cond.comparator !== "isAgeInDays");
    });
    setConditionMapping(conditionObj);
  }, [props.conditionsStore.data]);

  const updatePattern = (_andMatchConditions) => {
    let pattern = "";
    if (_andMatchConditions.length > 1)
      _andMatchConditions.forEach((each, index) => {
        if (index + 1 !== _andMatchConditions.length) {
          if (each.operator === "or") {
            pattern = `( ${pattern ? pattern : index + 1} or ${index + 2} )`;
          } else {
            pattern = `( ${pattern ? pattern : index + 1} and ${index + 2} )`;
          }
        }
      });

    return pattern;
  };

  const handleAddAndCondition = () => {
    const _andMatchConditions = [...props.criteriaConditions];
    _andMatchConditions.push({
      comparator: "",
      comparatorLabel: "",
      index: props.criteriaConditions.length + 1,
      field: {},
      value: 0,
      valueLabel: "0",
      operator: "and",
    });
    const pattern = updatePattern(_andMatchConditions);
    props.setPattern(pattern);
    props.setCriteriaConditions(_andMatchConditions);
    props.scrollToBottom();
  };
  const handleFirstDropdown = (field, index, item) => {
    const temp = item;
    temp["field"] = {
      id: field._id,
      name: field.fieldName,
      fieldType: field.fieldInputType,
      apiKeyName: field.apiKeyName,
      fieldGroup: field.fieldGroup,
    };
    temp["comparator"] = "";
    temp["comparatorLabel"] = "";
    temp["value"] = "";
    temp["valueLabel"] = "";
    const _andMatchConditions = props.criteriaConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setCriteriaConditions(_andMatchConditions);
  };
  const handleSecondDropdown = (condition, index, item) => {
    const temp = item;
    temp["comparator"] = condition.comparator;
    temp["comparatorLabel"] = condition.comparatorLabel;
    temp["value"] = "";
    temp["valueLabel"] = "";
    const _andMatchConditions = props.criteriaConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setCriteriaConditions(_andMatchConditions);
  };

  const handleRemoveAndCondition = (index) => {
    const _andMatchConditions = props.criteriaConditions.filter((each, indx) => index !== indx);
    const __andMatchConditions = _andMatchConditions.map((each, index) => {
      each.index = index + 1;
      return each;
    });
    const pattern = updatePattern(__andMatchConditions);
    props.setPattern(pattern);
    props.setCriteriaConditions(__andMatchConditions);
  };
  const onOperatorChange = (index) => {
    const _andMatchConditions = props.criteriaConditions.map((each, ind) => {
      if (index === ind) {
        if (each.operator === "or") {
          each.operator = "and";
        } else {
          each.operator = "or";
        }
      }
      return each;
    });
    const pattern = updatePattern(_andMatchConditions);
    props.setCriteriaConditions(_andMatchConditions);
    props.setPattern(pattern);
  };
  const handleAndValue = (item, index, value) => {
    const temp = item;
    temp["value"] = item.field.fieldType === "NUMBER" ? parseInt(value) : value;
    temp["valueLabel"] = value;
    const _andMatchConditions = props.criteriaConditions.map((each, ind) => {
      if (index === ind) return temp;
      return each;
    });
    props.setCriteriaConditions(_andMatchConditions);
  };

  const getCustomTableFieldData = (type, field) => {
    if (type === "lead") {
      return leadColumns.list.find((each) => each._id === field.id);
    } else if (type === "person" || type === "people") {
      return peopleColumns.list.find((each) => each._id === field.id);
    } else if (type === "company") {
      return companyColumns.list.find((each) => each._id === field.id);
    } else if (type === "deal") {
      return dealColumns.list.find((each) => each._id === field.id);
    } else if (type === "marketing") {
      return marketingColumns.list.find((each) => each._id === field.id);
    }
    return {};
  };

  const getFirstDropdown = (index, item) => {
    const { columns, loading } = getFirstDropdownOptions({ moduleName: props.moduleName, dealColumns, companyColumns, peopleColumns, leadColumns, marketingColumns });
    return (
      <Dropdown className="mt-0 w-100">
        <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {_.isEmpty(item.field) ? "Select Field" : item.field.name}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100-mn">
          {loading ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="navi navi-hover">
              {
                <li className="navi-item py-20px px-24px">
                  <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Field" value={fieldSearchText} onChange={(e) => setFieldSearchText(e.target.value)} />
                </li>
              }
              <Dropdown.Divider className="m-0" />
              <div className="navi navi-hover h-25vh-mx position-relative overflow-y-auto">
                {columns.map((field, fieldIndex) => {
                  if (field.fieldName.toLowerCase().includes(fieldSearchText.toLowerCase()))
                    return (
                      <Dropdown.Item key={fieldIndex} className="dropdown-hover" onSelect={(e) => handleFirstDropdown(field, index, item)}>
                        {field.fieldName}
                      </Dropdown.Item>
                    );
                  return <div key={fieldIndex}></div>;
                })}
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getSecondDropdown = (index, item) => {
    if (!conditionMapping[item.field.fieldType]) return;
    return (
      <Dropdown className="mt-0 w-100">
        <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {item.comparatorLabel ? item.comparatorLabel : "Select Condition"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100-mn">
          <div className="h-25vh-mx position-relative overflow-y-auto">
            {conditionMapping[item.field.fieldType] &&
              conditionMapping[item.field.fieldType].map((option, optionIndex) => {
                return (
                  <Dropdown.Item key={optionIndex} className="dropdown-hover" onSelect={(e) => handleSecondDropdown(option, index, item)}>
                    {option.comparatorLabel}
                  </Dropdown.Item>
                );
              })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Form.Group id="filter-condition-form-dropdowns" className="mt-5">
      <Col className="pb-25px">
        {props.criteriaConditions.map((item, index) => (
          <Row className="and-condition-row" key={index}>
            <ListGroup.Item className="p-5 w-100">
              <Row className="align-items-center1">
                <Col xs={3} className="py-0 px-6px">
                  {getFirstDropdown(index, item)}
                </Col>
                <Col xs={3} className="py-0 px-6px">
                  {getSecondDropdown(index, item)}
                </Col>
                <Col xs={5} className="py-0 px-6px">
                  {props.moduleName && item.comparator && (
                    <CustomFilterInput
                      condition={item.comparator}
                      selectedPipelineId={""}
                      page={pageMapping[props.moduleName].page}
                      pageSub={pageMapping[props.moduleName].pageSub}
                      field={getCustomTableFieldData(props.moduleName, item.field)}
                      value={item.value}
                      allData={item}
                      setValue={(value) => handleAndValue(item, index, value)}
                    />
                  )}
                </Col>
                <Col xs={1} className="py-0 px-6px">
                  <span className="float-right">
                    <Button onClick={() => handleRemoveAndCondition(index)} className="p-0" variant="none">
                      <i className="fa fa-minus-circle remove-condition-icon"></i>
                    </Button>
                  </span>
                </Col>
                {props.criteriaConditions.length !== index + 1 && (
                  <span className="and-condition-list" onClick={() => onOperatorChange(index)}>
                    {item.operator ? item.operator : "and"}
                  </span>
                )}
              </Row>
            </ListGroup.Item>
          </Row>
        ))}
        <Row>
          <ListGroup.Item className="w-100 pl-10px">
            <Button onClick={handleAddAndCondition} className="p-0 text-decoration-none" variant="link">
              + Add condition
            </Button>
          </ListGroup.Item>
        </Row>
      </Col>
    </Form.Group>
  );
}
