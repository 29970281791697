import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BlankSidePanel from "../../../../../components/blank-side-pannel";
import { Timeline } from "../../../../../components/side-pannel-detailed/components/timeline.side-pannel";

export default function ActivityLogs() {
  const [showPanel, setShowPanel] = useState(false);
  return (
    <div>
      <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-actions-tooltip">Activity Logs</Tooltip>}>
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 svg-icon-div" id="kt_quick_actions_toggle">
            <span className="header-svg" onClick={() => setShowPanel(true)}>
              <i className="la la-history font-size-24px"></i>
              {/* <SVG id="kt_drawer_example_basic_button" src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")} onClick={() => setShowPanel(true)} /> */}
            </span>
          </div>
        </div>
      </OverlayTrigger>
      <BlankSidePanel show={showPanel} handleClose={() => setShowPanel(false)} title={"Activity Logs"}>
        <Timeline />
      </BlankSidePanel>
    </div>
  );
}
