import React from "react";
import { Button } from "react-bootstrap";

export const PrimaryButton = () => {};

export const SecondaryButton = ({ children, onClick }) => {
  return (
    <Button variant="secondary" className="ellipsis-150px bg-hover-secondary bg-f8f8f8 border-width-1px border-style-solid border-color-e4e6ef" size="sm" onClick={onClick}>
      {children}
    </Button>
  );
};
