export const getListOfCadencePrebuiltTemplate = {
  GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_REQUEST: "GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_REQUEST",
  GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_SUCCESS: "GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_SUCCESS",
  GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_ERROR: "GET_LIST_OF_CADENCE_PREBUILT_TEMPLATE_ERROR",
};

export const createCadenceByTemplate = {
  CREATE_CADENCE_BY_TEMPLATE_REQUEST: "CREATE_CADENCE_BY_TEMPLATE_REQUEST",
  CREATE_CADENCE_BY_TEMPLATE_SUCCESS: "CREATE_CADENCE_BY_TEMPLATE_SUCCESS",
  CREATE_CADENCE_BY_TEMPLATE_ERROR: "CREATE_CADENCE_BY_TEMPLATE_ERROR",
};
