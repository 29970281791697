import React, {
  // useRef,
  // useEffect,
  useState,
  useLayoutEffect,
} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { useDispatch } from 'react-redux';
// import * as actions from '../../actions';

am4core.useTheme(am4themes_animated);

function FunnelChart(props) {
  // const dispatch = useDispatch();
  const [graphData] = useState(props.won);

  useLayoutEffect(() => {
    let chart = am4core.create(props.chartId, am4charts.SlicedChart);

    chart.data = [];

    try {
      let b = Object.keys(graphData[0]["totalCount"]);

      b.map((data, key) => {
        let chartObj = {
          name: data,
          value2: graphData[0]["totalCount"][data][0]["won"],
        };
        chart.data.push(chartObj);
        return null;
      });
    } catch (e) {}

    // chart.data = [{
    //     "name": "Stage #1",
    //     "value1": 10,
    //     "value2": 450
    // }, {
    //     "name": "Stage #2",
    //     "value1": 10,
    //     "value2": 400
    // }, {
    //     "name": "Stage #3",
    //     "value1": 10,
    //     "value2": 290
    // }, {
    //     "name": "Stage #4",
    //     "value1": 10,
    //     "value2": 100
    // }, {
    //     "name": "Stage #5",
    //     "value1": 10,
    //     "value2": 50
    // }, {
    //     "name": "Stage #6",
    //     "value1": 10,
    //     "value2": 20
    // }, {
    //     "name": "Stage #7",
    //     "value1": 10,
    //     "value2": 10
    // }];

    let series1 = chart.series.push(new am4charts.FunnelSeries());
    series1.dataFields.value = "value2";
    series1.dataFields.category = "name";
    series1.labels.template.disabled = true;

    // let series2 = chart.series.push(new am4charts.PyramidSeries());
    // series2.dataFields.value = "value2";
    // series2.dataFields.category = "name";
    // series2.labels.template.disabled = true;
  }, [graphData, props.chartId]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default FunnelChart;
