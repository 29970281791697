import React, { useState, useEffect, useRef } from "react";
import { Form, Spinner, Col } from "react-bootstrap";
import CustomInput from "./inputs";
import CategorySelect from "./categories";
import TagSelect from "./tags";
import { dispatchSnackbarError } from "../../utils/toaster";
import { CustomScrollableModal } from "../modal";
import validator from "validator";
import InlineStyled from "../inline-styled/inline-styled";
// import PerfectScrollbar from "react-perfect-scrollbar";

export default function CustomForm({
  page,
  pageSub,
  show,
  setShow,
  addTag,
  editTag,
  deleteTag,
  addCategory,
  editCategory,
  deleteCategory,
  customFormData,
  setCustomFormData,
  fields,
  fieldsLoading,
  propLoading = false,
  handleAdd,
  dontClearData = false,
  cacheCompany = false,
}) {
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);
  const [loadingHeight, setLoadingHeight] = useState("50vh");

  useEffect(() => {
    if (!fieldsLoading) {
      var temp = {};
      for (var i = 0; i < fields.length; i++) {
        if (fields[i].isEditable)
          if (fields[i].fieldInputType === "TAG_MULTI_SELECT" || fields[i].fieldInputType === "PRICING_INPUT" || fields[i].fieldInputType === "MULTI_PHONE_INPUT" || fields[i].fieldInputType === "MULTI_EMAIL_INPUT")
            temp[fields[i].apiKeyName] = [];
          else if (fields[i].fieldInputType === "TOGGLE_BUTTON" || fields[i].fieldInputType === "CHECKBOX") temp[fields[i].apiKeyName] = false;
          else temp[fields[i].apiKeyName] = "";
      }
      setCustomFormData(temp);
      setLoading(false);
      if (ref.current) {
        setLoadingHeight(ref.current.clientHeight);
      }
    }
  }, [fields, setCustomFormData, fieldsLoading]);

  const handleSave = () => {
    var temp = customFormData;
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].isEditable && fields[i].isRequired) {
        if (
          (typeof temp[fields[i].apiKeyName] === "string" && temp[fields[i].apiKeyName].trim() === "") ||
          temp[fields[i].apiKeyName] === [] ||
          (Array.isArray(temp[fields[i].apiKeyName]) && temp[fields[i].apiKeyName].includes(""))
        ) {
          dispatchSnackbarError("Please fill all the mandatory fields");
          return;
        }
      }
    }
    if (fieldsLoading) {
      dispatchSnackbarError("Please wait for loading to complete!");
      return;
    } else {
      for (var i = 0; i < fields.length; i++) {
        if (fields[i].isEditable && fields[i].isRequired) {
          if (
            (typeof temp[fields[i].apiKeyName] === "string" && temp[fields[i].apiKeyName].trim() === "") ||
            temp[fields[i].apiKeyName] === [] ||
            (Array.isArray(temp[fields[i].apiKeyName]) && temp[fields[i].apiKeyName].includes(""))
          ) {
            dispatchSnackbarError(fields[i].fieldName + " can not be empty");
            return;
          }
        } else if (fields[i].isEditable && !fields[i].isRequired) {
          if ((typeof temp[fields[i].apiKeyName] === "string" && temp[fields[i].apiKeyName].trim() === "") || temp[fields[i].apiKeyName] === []) {
            delete temp[fields[i].apiKeyName];
            continue;
          }
        }
        if (fields[i].validation === "URL_VALIDATION") {
          if (temp[fields[i].apiKeyName] && typeof temp[fields[i].apiKeyName] === "string" && temp[fields[i].apiKeyName] !== "" && !validator.isURL(temp[fields[i].apiKeyName])) {
            dispatchSnackbarError(temp[fields[i].apiKeyName] + " is not a valid URL");
            return;
          }
        } else if (fields[i].validation === "PIN_CODE_VALIDATION") {
          // let pinCodeRegex = /[1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3}/;
          if (!validator.isNumeric(temp[fields[i].apiKeyName])) {
            dispatchSnackbarError(temp[fields[i].apiKeyName] + "is not a valid pincode");
            return;
          }
        } else if (fields[i].validation === "EMAIL_VALIDATION") {
          if (temp[fields[i].apiKeyName] && temp[fields[i].apiKeyName].length > 0) {
            if (fields[i].fieldGroup === "CUSTOM") {
              if (!validator.isEmail(temp[fields[i].apiKeyName])) {
                dispatchSnackbarError(temp[fields[i].apiKeyName] + " is not a valid Email");
                return;
              }
            } else {
              for (var j = 0; j < temp[fields[i].apiKeyName].length; j++) {
                if (!validator.isEmail(temp[fields[i].apiKeyName][j])) {
                  dispatchSnackbarError(temp[fields[i].apiKeyName][j] + " is not a valid Email");
                  return;
                }
              }
            }
          }
        } else if (fields[i].validation === "PHONE_VALIDATION") {
          if (temp[fields[i].apiKeyName] && temp[fields[i].apiKeyName].length > 0) {
            for (var k = 0; k < temp[fields[i].apiKeyName].length; k++) {
              if (!validator.isNumeric(temp[fields[i].apiKeyName][k].toString())) {
                dispatchSnackbarError(temp[fields[i].apiKeyName][k] + " is not a valid Phone Number");
                return;
              }
            }
          }
        }
        if (fields[i].fieldGroup === "CUSTOM") {
          temp[`customFields.${fields[i].apiKeyName}`] = temp[fields[i].apiKeyName];
          delete temp[fields[i].apiKeyName];
        }
      }
    }
    //phone number with interger

    const dummy = { ...temp };

    if (dummy.phone && Array.isArray(dummy.phone) && dummy.phone.length > 0) {
      let phoneData = dummy.phone;
      let dummyArray = [];
      for (let i = 0; i < phoneData.length; i++) {
        if (typeof phoneData[i] === "string") {
          dummyArray.push(parseInt(phoneData[i]));
        } else {
          dummyArray.push(dummy.phone[i]);
        }
      }
      dummy.phone = dummyArray;
    }

    handleAdd(dummy);
    setCustomFormDataAfterClose(false);
    // handleAdd(temp)
  };

  const setCustomFormDataAfterClose = (overide) => {
    if (!fieldsLoading && (overide || !dontClearData)) {
      var temp = {};
      for (var i = 0; i < fields.length; i++) {
        if (fields[i].isEditable)
          if (fields[i].fieldInputType === "TAG_MULTI_SELECT" || fields[i].fieldInputType === "PRICING_INPUT" || fields[i].fieldInputType === "MULTI_PHONE_INPUT" || fields[i].fieldInputType === "MULTI_EMAIL_INPUT")
            temp[fields[i].apiKeyName] = [];
          else if (fields[i].fieldInputType === "TOGGLE_BUTTON" || fields[i].fieldInputType === "CHECKBOX") temp[fields[i].apiKeyName] = false;
          else temp[fields[i].apiKeyName] = "";
      }
      setCustomFormData(temp);
      setLoading(false);
    }
  };

  const addModalProps = {
    show: show,
    title: "Add New " + pageSub.charAt(0).toUpperCase() + pageSub.slice(1),
    // title: page !== "Companies" ? "Add New " + pageSub.charAt(0).toUpperCase() + pageSub.slice(1) : "Add New Company",
    showButtons: true,
    buttonText: "Save",
    size: "lg",
    customMessageShow: true,
    type: page,
    handleClose: () => {
      setShow(false);
      // setCustomFormData({})
      setCustomFormDataAfterClose(true);
    },
    handleAccept: () => {
      handleSave();
    },
  };

  const setValue = (val, apiKeyName) => {
    setCustomFormData({ ...customFormData, [apiKeyName]: val });
  };

  return (
    <CustomScrollableModal modalProps={addModalProps}>
      {loading || fieldsLoading || propLoading || !show ? (
        <InlineStyled className="w-100 d-flex justify-content-center align-items-center" styles={{ minHeight: loadingHeight }}>
          <Spinner animation="border" variant="primary" />
        </InlineStyled>
      ) : (
        <Form ref={ref}>
          <div className="d-flex flex-row flex-wrap">
            <Col className="pt-0 pr-18px pb-0 pl-7px" xs={6}>
              {fields.map((field, index) => {
                return index % 2 === 0 ? (
                  field.fieldInputType === "CATEGORY_SELECT" ? (
                    <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`} className={field.isRequired ? "custom-form-required" : ""}>
                        {field.fieldName}
                      </Form.Label>
                      <CategorySelect addCategory={addCategory} editCategory={editCategory} deleteCategory={deleteCategory} field={field} value={customFormData[field.apiKeyName]} setValue={setValue} />
                    </Form.Group>
                  ) : field.fieldInputType === "TAG_MULTI_SELECT" ? (
                    <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`} className={field.isRequired ? "custom-form-required" : ""}>
                        {field.fieldName}
                      </Form.Label>
                      <TagSelect
                        page={page}
                        pageSub={pageSub}
                        addTag={addTag}
                        editTag={editTag}
                        deleteTag={deleteTag}
                        field={field}
                        value={
                          !customFormData[field.apiKeyName] || customFormData[field.apiKeyName] === "" || customFormData[field.apiKeyName].length === 0
                            ? [""]
                            : customFormData[field.apiKeyName].map((tag) => (tag["_id"] ? tag["_id"] : tag))
                        }
                        setValue={setValue}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`} className={field.isRequired ? "custom-form-required" : ""}>
                        {field.fieldName}
                      </Form.Label>
                      <CustomInput
                        isCustomPersonSelect={page === "Leads" && pageSub === "lead"}
                        page={page}
                        pageSub={pageSub}
                        field={field}
                        value={customFormData[field.apiKeyName]}
                        setCustomFormData={setCustomFormData}
                        customFormData={customFormData}
                        setValue={setValue}
                        allData={customFormData}
                        cacheCompany={cacheCompany}
                      />
                    </Form.Group>
                  )
                ) : null;
              })}
            </Col>
            <Col className="pt-0 pr-7px pb-0 pl-18px" xs={6}>
              {fields.map((field, index) => {
                return index % 2 === 1 ? (
                  field.fieldInputType === "CATEGORY_SELECT" ? (
                    <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`} className={field.isRequired ? "custom-form-required" : ""}>
                        {field.fieldName}
                      </Form.Label>
                      <CategorySelect addCategory={addCategory} editCategory={editCategory} deleteCategory={deleteCategory} field={field} value={customFormData[field.apiKeyName]} setValue={setValue} />
                    </Form.Group>
                  ) : field.fieldInputType === "TAG_MULTI_SELECT" ? (
                    <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`} className={field.isRequired ? "custom-form-required" : ""}>
                        {field.fieldName}
                      </Form.Label>
                      <TagSelect
                        page={page}
                        pageSub={pageSub}
                        addTag={addTag}
                        editTag={editTag}
                        deleteTag={deleteTag}
                        field={field}
                        value={
                          !customFormData[field.apiKeyName] || customFormData[field.apiKeyName] === "" || customFormData[field.apiKeyName].length === 0
                            ? [""]
                            : customFormData[field.apiKeyName].map((tag) => (tag["_id"] ? tag["_id"] : tag))
                        }
                        setValue={setValue}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`} className={field.isRequired ? "custom-form-required" : ""}>
                        {field.fieldName}
                      </Form.Label>
                      <CustomInput
                        clearable={true}
                        isCustomPersonSelect={page === "Leads" && pageSub === "lead"}
                        page={page}
                        pageSub={pageSub}
                        field={field}
                        value={customFormData[field.apiKeyName]}
                        setCustomFormData={setCustomFormData}
                        customFormData={customFormData}
                        setValue={setValue}
                        allData={customFormData}
                        cacheCompany={cacheCompany}
                      />
                    </Form.Group>
                  )
                ) : null;
              })}
            </Col>
          </div>
        </Form>
      )}
    </CustomScrollableModal>
  );
}
