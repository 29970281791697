import { getUserSalesTargetStaticstics } from "../types/staticstics.types";

const initState = {
  loading: false,
  activity: { todoToday: 0, todoTomorrow: 0, overdue: 0, doneToday: 0 },
  goalStats: [],
  summary: [],
};

export default function staticsticsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        activity: action.payload.data.activity,
        goalStats: action.payload.data.goalStats,
        summary: action.payload.data.summary,
      };
    case getUserSalesTargetStaticstics.GET_USER_SALES_TARGET_STATICSTICS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
