import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import SimpleDropdown from "../../../../../components/dropdowns/simple-dropdown";
import PopoverFilterWrapper from "../../../../../components/filter/popover-filter";
import { InputLimit } from "../../../../../constants";
import { campaignChannelsOptions, campaignTypeOptions } from "../../../constants";

export default function AdvancedLogFilter(props) {
  const [channel, setChannel] = useState({});
  const [type, setType] = useState({});
  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(7, "days")
      .valueOf(),
  );
  const [toDate, setToDate] = useState(moment().valueOf());
  const [focusedInput, setFocusedInput] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!_.isEmpty(props.filterValue)) {
      const { channel, type, fromDate, toDate, search } = props.filterValue;
      setSearch(search);
      setFromDate(fromDate);
      setToDate(toDate);
      const typeFind = campaignTypeOptions.find((a) => a.value === type);
      setType(typeFind);
      const channelFind = campaignChannelsOptions.find((a) => a.value === channel);
      setChannel(channelFind);
    }
  }, [props.filterValue]);

  const handleApplyFilter = () => {
    const payload = {
      channel: channel.value,
      type: type.value,
      fromDate: fromDate,
      toDate: toDate,
      search: search,
    };
    props.handleApplyFilter(payload);
  };

  const isOutsideRange = (day) => {
    const _startDate = fromDate ? moment(fromDate) : moment();
    return focusedInput === "endDate" && (day.isBefore(_startDate) || day.isAfter(_startDate.clone().add(1, "months")));
  };
  return (
    <PopoverFilterWrapper
      show={props.show}
      setShow={props.setShow}
      label={"Filter"}
      onSaveClick={handleApplyFilter}
      onCancelClick={props.handleCancelFilter}
      showCancel={true}
      showSave={true}
      saveButtonText={"Apply"}
      cancelButtonText={"Cancel"}
    >
      <Form className="w-350px">
        <Form.Group>
          <Form.Label className="fw-bold">Type</Form.Label>
          <SimpleDropdown value={type} label={"Type"} options={campaignTypeOptions} handleSelect={setType} />
        </Form.Group>
        <Form.Group>
          <Form.Label className="fw-bold">Channel</Form.Label>
          <SimpleDropdown value={channel} label={"Channel"} options={campaignChannelsOptions} handleSelect={setChannel} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Advance Search(Optional)</Form.Label>
          <Form.Control placeholder="Enter Number or Email" maxLength={InputLimit.TEXT} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label className="fw-bold">Duration</Form.Label>
          <DateRangePicker
            startDate={fromDate ? moment(fromDate) : null}
            startDateId="fromdate"
            endDate={toDate ? moment(toDate) : null}
            endDateId="enddate"
            onDatesChange={({ startDate, endDate }) => {
              const _startDate = startDate ? new Date(startDate).getTime() : null;
              const _endDate = endDate ? new Date(endDate).getTime() : null;
              setFromDate(_startDate);
              setToDate(_endDate);
            }}
            displayFormat={"YYYY-MM-DD"}
            small={true}
            isOutsideRange={isOutsideRange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => {
              setFocusedInput(focusedInput);
            }}
          />
        </Form.Group>
      </Form>
    </PopoverFilterWrapper>
  );
}
