/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

//packages
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//custom-components
import CustomSidePanel from "./custom-components/custom-side-pannel";

//actions
import * as dealActions from "../../containers/deals/dealPipeline/actions";
import * as leadActions from "../../containers/leads/store/actions/actions";
import * as peopleActions from "../../containers/customers/store/actions/actions";
import * as companyActions from "../../containers/companies/store/actions/actions";
import * as marketingListActions from "../../containers/campaign/store/actions/campaign_marketing_list_actions";

//styles
import "./styles.scss";

import ModuleDetails from "./module-details";
import { pageMapping } from "../../containers/Automation/workflow/workflow-builder/constants";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import MainSectionB from "./section-b-components/main.section-b";
import MarketingSectionB from "./section-b-components/marketing.section-b";

export default function SidePannelDetailed(props) {
  const [peopleId, setPeopleId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [dealId, setDealId] = useState("");
  const [marketingId, setMarketingId] = useState("");

  const [personData, setPersonData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [relatedAssociate, setRelatedAssociate] = useState({});

  // const [page, setPage] = useState("");
  const [pageSub, setPageSub] = useState("");

  const [nextButtonId, setNextButtonId] = useState(false);
  const [previosButtonId, setPreviosButtonId] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const dealSidepanelStore = useSelector((store) => store.DealPipeline.dealPopup);
  const leadPopupStore = useSelector((state) => state.Leads.leadPopup);
  const companyPopupStore = useSelector((state) => state.Companies.companyPopup);
  const peoplePopupStore = useSelector((state) => state.People.peoplePopup);
  const marketingDataPopupStore = useSelector((store) => store.campaign.marketing.marketingList.popup);

  useEffect(() => {
    if (props.moduleName === "lead" && props.moduleId) {
      setPeopleId("");
      setCompanyId("");
      setLeadId(props.moduleId);
      setDealId("");
      setMarketingId("");
      setPageSub(pageMapping["lead"].pageSub);
      dispatch(leadActions.leadPopupRequest({ id: props.moduleId }));
    } else if (props.moduleName === "deal" && props.moduleId) {
      setPeopleId("");
      setCompanyId("");
      setLeadId("");
      setDealId(props.moduleId);
      setMarketingId("");
      setPageSub(pageMapping["deal"].pageSub);
      dispatch(dealActions.getDealPopupDetailsRequest(props.moduleId));
    } else if (props.moduleName === "people" && props.moduleId) {
      setPeopleId(props.moduleId);
      setCompanyId("");
      setLeadId("");
      setDealId("");
      setMarketingId("");
      setPageSub(pageMapping["person"].pageSub);
      dispatch(peopleActions.peoplePopupRequest({ id: props.moduleId }));
    } else if (props.moduleName === "company" && props.moduleId) {
      setPeopleId("");
      setCompanyId(props.moduleId);
      setLeadId("");
      setDealId("");
      setMarketingId("");
      setPageSub(pageMapping["company"].pageSub);
      dispatch(companyActions.companyPopupRequest({ id: props.moduleId }));
    } else if (props.moduleName === "marketing" && props.moduleId) {
      setPeopleId("");
      setCompanyId("");
      setLeadId("");
      setDealId("");
      setMarketingId(props.moduleId);
      setPageSub(pageMapping["marketing"].pageSub);
      dispatch(marketingListActions.getSelectedMarketingDataRequest(props.moduleId));
    }
  }, [props.moduleName, props.moduleId, dispatch]);

  useEffect(() => {
    if (props.moduleName === "lead" && !_.isEmpty(leadPopupStore.data)) {
      setCompanyData(leadPopupStore.data.company);
      setPersonData(leadPopupStore.data.person);
      if (!_.isEmpty(leadPopupStore.data.data)) {
        setRelatedAssociate({
          _id: leadPopupStore.data.data._id,
          name: leadPopupStore.data.data?.name,
          type: "lead",
        });
      }
    } else if (props.moduleName === "deal" && !_.isEmpty(dealSidepanelStore.data)) {
      setCompanyData(dealSidepanelStore.data.company);
      setPersonData(dealSidepanelStore.data.person);
      if (!_.isEmpty(dealSidepanelStore.data.data)) {
        setRelatedAssociate({
          _id: dealSidepanelStore.data.data._id,
          name: dealSidepanelStore.data.data.title,
          type: "deal",
        });
      }
    } else if (props.moduleName === "people" && !_.isEmpty(peoplePopupStore.data)) {
      setCompanyData(peoplePopupStore.data.company);
      setPersonData(peoplePopupStore.data.person);
      if (!_.isEmpty(peoplePopupStore.data.data)) {
        setRelatedAssociate({
          _id: peoplePopupStore.data.data._id,
          name: peoplePopupStore.data.data?.name,
          type: "people",
        });
      }
    } else if (props.moduleName === "company" && !_.isEmpty(companyPopupStore.data)) {
      setCompanyData(companyPopupStore.data.data);
      setPersonData({});
      if (!_.isEmpty(companyPopupStore.data.data)) {
        setRelatedAssociate({
          _id: companyPopupStore.data.data._id,
          name: companyPopupStore.data.data?.name,
          type: "company",
        });
      }
    } else if (props.moduleName === "marketing") {
      setCompanyData({});
      setPersonData({});
      if (!_.isEmpty(marketingDataPopupStore.data)) {
        setRelatedAssociate({
          _id: companyPopupStore.data._id,
          name: companyPopupStore.data.firstName,
          type: "marketing",
        });
      }
    }
  }, [dealSidepanelStore.data, leadPopupStore.data, companyPopupStore.data, peoplePopupStore.data, marketingDataPopupStore.data, props.moduleName]);

  useEffect(() => {
    if (props.nextPreviousData.length === 0) {
      setNextButtonId("");
      setPreviosButtonId("");
    } else if (props.nextPreviousData.length === 1) {
      setNextButtonId("");
      setPreviosButtonId("");
    } else {
      if (props.pageForm === "activityList") {
        for (let i = 0; i < props.nextPreviousData.length; i++) {
          const activityIds = props.nextPreviousData[i].id.split("/");
          if (props.parentId === activityIds[0] && activityIds[2] === props.moduleId) {
            if (i === 0) {
              setNextButtonId(props.nextPreviousData[i + 1].id);
              setPreviosButtonId("");
            } else if (i === props.nextPreviousData.length - 1) {
              setNextButtonId("");
              setPreviosButtonId(props.nextPreviousData[i - 1].id);
            } else {
              setNextButtonId(props.nextPreviousData[i + 1].id);
              setPreviosButtonId(props.nextPreviousData[i - 1].id);
            }
          }
        }
      } else {
        for (let i = 0; i < props.nextPreviousData.length; i++) {
          if (props.nextPreviousData[i].id === props.moduleId) {
            if (i === 0) {
              setNextButtonId(props.nextPreviousData[i + 1].id);
              setPreviosButtonId("");
            } else if (i === props.nextPreviousData.length - 1) {
              setNextButtonId("");
              setPreviosButtonId(props.nextPreviousData[i - 1].id);
            } else {
              setNextButtonId(props.nextPreviousData[i + 1].id);
              setPreviosButtonId(props.nextPreviousData[i - 1].id);
            }
          }
        }
      }
    }
  }, [props.nextPreviousData, props.moduleId, props.parentId, props.pageForm]);

  const onNextClick = (id) => {
    if (props.isCustomNextPrevious) {
      props.onNextClick(id);
    } else {
      history.push(`${props.basePath}/${id}`);
    }
  };
  const onPreviousClick = (id) => {
    if (props.isCustomNextPrevious) {
      props.onPreviousClick(id);
    } else {
      history.push(`${props.basePath}/${id}`);
    }
  };

  const handleClose = () => {
    props.onClose();
    // if (history.action !== "POP") history.goBack();
    // else props.onClose();
  };

  const checkIsNoDataFound = () => {
    if (props.moduleName === "lead" && !leadPopupStore.loading && _.isEmpty(leadPopupStore.data)) {
      return true;
    } else if (props.moduleName === "company" && !companyPopupStore.loading && _.isEmpty(companyPopupStore.data)) {
      return true;
    } else if (props.moduleName === "deal" && !dealSidepanelStore.loading && _.isEmpty(dealSidepanelStore.data)) {
      return true;
    } else if (props.moduleName === "people" && !peoplePopupStore.loading && _.isEmpty(peoplePopupStore.data)) {
      return true;
    } else if (props.moduleName === "marketing" && !marketingDataPopupStore.loading && _.isEmpty(marketingDataPopupStore.data)) {
      return true;
    }
    return false;
  };

  const getModuleName = () => {
    if (props.moduleName === "lead" && props.moduleId) {
      return "Lead";
    } else if (props.moduleName === "deal" && props.moduleId) {
      return "Deal";
    } else if (props.moduleName === "people" && props.moduleId) {
      return "People";
    } else if (props.moduleName === "company" && props.moduleId) {
      return "Company";
    } else if (props.moduleName === "marketing" && props.moduleId) {
      return "Marketing";
    }
    return "";
  };

  return (
    <CustomSidePanel show={props.show} moduleName={getModuleName()} handleClose={handleClose} showNextButton={nextButtonId} showPreviosButton={previosButtonId} onNextClick={onNextClick} onPreviousClick={onPreviousClick}>
      {checkIsNoDataFound() ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <div data-kt-search-element="empty" className="text-center">
            <div className="pt-10 pb-10">
              <span className="svg-icon svg-icon-4x opacity-50">
                <i className="bi bi-clipboard2-data font-size-30px"></i>
              </span>
            </div>
            <div className="pb-15 fw-bold">
              <h3 className="text-gray-600 fs-5 mb-2">No Data Found.</h3>
              <div className="text-muted fs-7">There are no data found for selecetd {props.moduleName}.</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="side-pannel-detailed-container">
          <div className="spd-section-a">
            <ModuleDetails
              peopleId={peopleId}
              companyId={companyId}
              leadId={leadId}
              dealId={dealId}
              marketingId={marketingId}
              basePath={props.basePath}
              moduleType={props.moduleName}
              pageForm={props.pageForm}
              hideMenu={props.nextPreviousData.length === 0}
              //optional module wise
              convertToJunk={props.convertToJunk}
              convertToDeal={props.convertToDeal}
              convertToLead={props.convertToLead}
              convertToActive={props.convertToActive}
              handleDelete={props.handleDelete}
              handleArchiveDeal={props.handleArchiveDeal}
              handleConvertToPeople={props.handleConvertToPeople}
              filter={props.filter}
            />
          </div>
          <div className="spd-section-b m-0">
            {["lead", "deal", "people", "company"].includes(props.moduleName) ? (
              <MainSectionB
                peopleId={peopleId}
                companyId={companyId}
                leadId={leadId}
                dealId={dealId}
                moduleId={props.moduleId}
                basePath={props.basePath}
                moduleName={props.moduleName}
                pageSub={pageSub}
                companyData={companyData}
                personData={personData}
                relatedAssociate={relatedAssociate}
                pageForm={props.pageForm}
                filter={props.filter}
                autoUpdateActivityEvent={props.autoUpdateActivityEvent}
                activityEvent={props.activityEvent}
              />
            ) : props.moduleName === "marketing" ? (
              <MarketingSectionB marketingId={marketingId} filter={props.filter} />
            ) : (
              <>
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <div data-kt-search-element="empty" className="text-center">
                    <div className="pt-10 pb-10">
                      <span className="svg-icon svg-icon-4x opacity-50">
                        <i className="bi bi-clipboard2-data font-size-30px"></i>
                      </span>
                    </div>
                    <div className="pb-15 fw-bold">
                      <h3 className="text-gray-600 fs-5 mb-2">Marketing.</h3>
                      <div className="text-muted fs-7">Under Development.</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </CustomSidePanel>
  );
}
