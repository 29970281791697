import { getListOfVideoCategories, createVideoCategory, editVideoCategory, deleteVideoCategory } from "../types/category.types";

export const getListOfVideoCategoriesRequest = (req, cb) => ({
  type: getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_REQUEST,
  payload: req,
  cb,
});
export const getListOfVideoCategoriesSuccess = (req) => ({
  type: getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_SUCCESS,
  payload: req,
});
export const getListOfVideoCategoriesError = (req) => ({
  type: getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_ERROR,
  payload: req,
});

export const createVideoCategoryRequest = (req, cb) => ({
  type: createVideoCategory.CREATE_VIDEO_CATEGORY_REQUEST,
  payload: req,
  cb,
});
export const createVideoCategorySuccess = (req) => ({
  type: createVideoCategory.CREATE_VIDEO_CATEGORY_SUCCESS,
  payload: req,
});
export const createVideoCategoryError = (req) => ({
  type: createVideoCategory.CREATE_VIDEO_CATEGORY_ERROR,
  payload: req,
});

export const editVideoCategoryRequest = (req, cb) => ({
  type: editVideoCategory.EDIT_VIDEO_CATEGORY_REQUEST,
  payload: req,
  cb,
});
export const editVideoCategorySuccess = (req) => ({
  type: editVideoCategory.EDIT_VIDEO_CATEGORY_SUCCESS,
  payload: req,
});
export const editVideoCategoryError = (req) => ({
  type: editVideoCategory.EDIT_VIDEO_CATEGORY_ERROR,
  payload: req,
});

export const deleteVideoCategoryRequest = (req, cb) => ({
  type: deleteVideoCategory.DELETE_VIDEO_CATEGORY_REQUEST,
  payload: req,
  cb,
});
export const deleteVideoCategorySuccess = (req) => ({
  type: deleteVideoCategory.DELETE_VIDEO_CATEGORY_SUCCESS,
  payload: req,
});
export const deleteVideoCategoryError = (req) => ({
  type: deleteVideoCategory.DELETE_VIDEO_CATEGORY_ERROR,
  payload: req,
});
