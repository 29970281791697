import { takeLatest, put, call } from "redux-saga/effects";
import * as walletTypes from "../types/wallet_billing_types";
import * as walletActions from "../actions/wallet_billing_actions";
import api from "../../../../../network/apis/api";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";

const endpoints = {
  WALLET_INFO: "/user-service/api/wallet",
  UPDATE_WALLET_NOTIFY: "/user-service/api/wallet/nofity",
  CREATE_WALLET_SESSION: "/user-service/api/wallet/v2/wallet-payment-checkout-session",
};

function* getWalletInfo(action) {
  try {
    const res = yield call(api.get, endpoints.WALLET_INFO, action.payload);
    if (res.status === 200) {
      yield put(walletActions.getWalletInfoSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(walletActions.getWalletInfoError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(walletActions.getWalletInfoError(e));
  }
}

function* updateWalletNotify(action) {
  try {
    const res = yield call(api.patch, endpoints.UPDATE_WALLET_NOTIFY, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess("Successfully updated");
      yield put(walletActions.updateWalletNotifySuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(walletActions.updateWalletNotifyError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(walletActions.updateWalletNotifyError(e));
  }
}

function* createWalletPaymentSession(action) {
  try {
    const res = yield call(api.post, endpoints.CREATE_WALLET_SESSION, action.payload);
    if (res.status === 200) {
      yield put(walletActions.createWalletPaymentSessionSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(walletActions.createWalletPaymentSessionError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(walletActions.createWalletPaymentSessionError(e));
  }
}

export default function* () {
  yield takeLatest(walletTypes.getWalletInfo.GET_WALLET_INFO_REQUEST, getWalletInfo);
  yield takeLatest(walletTypes.updateWalletNotify.UPDATE_WALLET_NOTIFY_REQUEST, updateWalletNotify);
  yield takeLatest(walletTypes.createWalletPaymentSession.CREATE_WALLET_PAYMENT_SESSION_REQUEST, createWalletPaymentSession);
}
