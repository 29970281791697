import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

export default function UploadCSVFile({ file, setFile, acceptedFiles = ".csv" }) {
  const [fileTextMessage, setFileTextMessage] = useState("");

  useEffect(() => {
    if (acceptedFiles === ".csv") {
      setFileTextMessage("Only Comma-separated Values(.csv) files are supported ");
    } else if (acceptedFiles === ".csv,.xlsx") {
      setFileTextMessage("Only Comma-separated Values(.csv) and .xlsx files are supported ");
    } else {
      setFileTextMessage("Only Comma-separated Values(.csv) files are supported ");
    }
  }, [acceptedFiles]);

  return (
    <div className="w-100 px-2">
      <div className="fv-row">
        <div className="row my-10 position-relative">
          <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone">
                  <div className="dz-message needsclick align-items-center">
                    <i className={`bi bi-file-${!file ? "earmark-arrow-up" : "check"} text-primary fs-3x`}></i>
                    <input {...getInputProps()} accept={acceptedFiles} />
                    <div className="ms-4">
                      <h3 className="fs-5 fw-bolder text-gray-900 mb-1">{!file ? "Drop files here or click to upload." : file.name}</h3>
                      {!file && <span className="fs-5 mb-1">{fileTextMessage}</span>}
                      {file && file.type && (
                        <div>
                          <span className="fs-6 mb-1">{file.type} </span>
                        </div>
                      )}
                      {file && file.size && (
                        <div>
                          {" "}
                          <span className="fs-6 mb-1">{file.size / 1000} kB</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          {file && (
            <div className="position-absolute bg-white mr-8 mt-9 rounded-circle w-40px h-40px right-0 d-flex flex-center text-hover-danger cursor-pointer" onClick={() => setFile()}>
              <svg width="18" height="17" viewBox="0 0 18 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.40989 0.100476C6.42311 0.100476 5.61215 0.911264 5.61215 1.89821V2.49595H1.27158C0.938363 2.49461 0.667979 2.76515 0.669316 3.09821C0.670656 3.42955 0.940253 3.69715 1.27158 3.69595H2.34623L3.06102 14.2909C3.15691 15.6945 4.25166 16.8994 5.70945 16.8994H12.4103C13.8681 16.8994 14.9628 15.6944 15.0587 14.2909L15.7759 3.69595H16.8481C17.1794 3.69729 17.4492 3.4297 17.4504 3.09821C17.4517 2.765 17.1812 2.49461 16.8481 2.49595L12.5079 2.49582V1.89808C12.5079 0.911302 11.6971 0.100342 10.7101 0.100342L7.40989 0.100476ZM7.40989 1.30048H10.7099C11.0526 1.30048 11.3076 1.55534 11.3076 1.89821V2.49595H6.80763L6.80749 1.89808C6.80749 1.55535 7.06704 1.30034 7.40975 1.30034L7.40989 1.30048ZM3.54143 3.69578H14.5735L13.8633 14.2064C13.8045 15.0671 13.2221 15.6993 12.4102 15.6993H5.70973C4.89784 15.6993 4.3154 15.067 4.2566 14.2064L3.54143 3.69578Z" />
                <path d="M7.25936 6.09672C7.10012 6.09725 6.94771 6.16114 6.83562 6.27404C6.72339 6.38708 6.66084 6.53989 6.66138 6.69912V12.696C6.66084 12.8551 6.72339 13.0081 6.83562 13.1211C6.94772 13.234 7.10013 13.2978 7.25936 13.2983C7.41927 13.299 7.57288 13.2357 7.68591 13.1226C7.79908 13.0095 7.86229 12.8559 7.86162 12.696V6.69912C7.86229 6.5392 7.79908 6.38559 7.68591 6.27243C7.57287 6.15939 7.41926 6.09605 7.25936 6.09672V6.09672Z" />
                <path d="M10.8603 6.09672C10.7004 6.09605 10.5468 6.15939 10.4338 6.27243C10.3206 6.3856 10.2574 6.53921 10.2581 6.69912V12.696C10.2574 12.8559 10.3206 13.0096 10.4338 13.1226C10.5468 13.2357 10.7004 13.299 10.8603 13.2983C11.0196 13.2978 11.172 13.234 11.2842 13.1211C11.3963 13.0081 11.459 12.8551 11.4583 12.696V6.69912C11.459 6.53988 11.3963 6.38706 11.2842 6.27404C11.172 6.16114 11.0196 6.09726 10.8603 6.09672V6.09672Z" />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
