import { getManageUser } from "../../types/admin-settings.types";

const initState = {
  getManageUser: {
    list: [],
    loading: false,
  },
  error: {
    err: false,
    errMsg: "",
  },
};

export default function manageUsersSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getManageUser.GET_MANAGE_USER_REQUEST:
      return {
        ...state,
        getManageUser: {
          ...state.getManageUser,
          loading: true,
        },
      };
    case getManageUser.GET_MANAGE_USER_SUCCESS:
      return {
        ...state,
        getManageUser: { loading: false, list: action.payload },
      };
    case getManageUser.GET_MANAGE_USER_ERROR:
      return {
        ...state,
        getManageUser: { ...state.getManageUser, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    default:
      return state;
  }
}
