import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 2,
  }),
  control: () => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: 38,
    outline: "0 !important",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid rgb(228, 230, 239)",
    borderRadius: "0.42rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    ":active": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
    ":hover": {
      backgroundColor: "#ecf8ff",
      color: "#009ef7",
    },
  }),
};

export default function CalendarSettings({ selectedSyncData, activitiesOptions, calendarList, onSelectCalendarChange, onCalendarEventChange, onActivityChange, activities, onSyncTypeClick }) {
  const getSelectOptions = () => {
    return calendarList && calendarList.length > 0
      ? calendarList.map((a) => {
          return { value: a.id, label: a.name };
        })
      : [];
  };

  const getSelectValue = (id) => {
    return getSelectOptions().filter((a) => a.value === id);
  };

  return (
    <div className="p-2">
      <Form>
        <Form.Group id="settings-profile-platform-setting-calender-sync-date-selection">
          <Form.Label aria-label="select-calender">Select calendar {calendarList.length === 0 && <span className="text-d99209">(Sync is on progress... wait until it get completed)</span>}</Form.Label>
          <Select
            options={getSelectOptions()}
            value={selectedSyncData && selectedSyncData.calendarId ? getSelectValue(selectedSyncData.calendarId) : { value: "", label: "" }}
            aria-label="Default select example"
            styles={customStyles}
            noOptionsMessage={() => null}
            isValidNewOption={() => false}
            onChange={(e) => onSelectCalendarChange(e)}
          ></Select>
        </Form.Group>
        <Form.Group id="settings-profile-platform-setting-calender-sync-select-sync-type">
          <Form.Label aria-label="select-sync-type" className="d-flex align-items-center gap-3">
            Select sync type
            <a href="https://salessimplify.com/help-center/calendar-sync" target={"_blank"} rel="noopener noreferrer" className="cursor-pointer text-hover-primary">
              <i class="bi bi-box-arrow-up-right"></i>
            </a>
          </Form.Label>
          <label className="option">
            <span className="option-control">
              <span className="radio">
                <input type="radio" name="m_option_1" value="two_way" checked={selectedSyncData.syncType === "two_way"} onClick={(e) => onSyncTypeClick("two_way")} />
                <span></span>
              </span>
            </span>
            <span className="option-label">
              <span className="option-head">
                <span className="option-title">Two-way Sync</span>
              </span>
              <span>
                <span className="option-body">SalesSimplify activities sync to calendar</span>
                <span className="option-body">Calendar events sync to SalesSimplify as activities, accessible to other users in your company</span>
              </span>
            </span>
          </label>
          <label className="option">
            <span className="option-control">
              <span className="radio">
                <input type="radio" name="m_option_1" value="one_way" checked={selectedSyncData.syncType === "one_way"} onClick={(e) => onSyncTypeClick("one_way")} />
                <span></span>
              </span>
            </span>
            <span className="option-label">
              <span className="option-head">
                <span className="option-title">One-way Sync</span>
              </span>
              <span>
                <span className="option-body">SalesSimplify activities sync to calendar</span>
                <span className="option-body">Only edits to SalesSimplify activities will sync back to SalesSimplify</span>
              </span>
            </span>
          </label>
        </Form.Group>
        <Form.Group id="settings-profile-platform-setting-calender-sync-calender-event-change">
          <Form.Label aria-label="calender">{"All of your calendar events will synchronize to Sales Simplify with the name picked from here. Calendar > Sales Simplify."}</Form.Label>
          <Select
            options={activitiesOptions}
            styles={customStyles}
            noOptionsMessage={() => null}
            isValidNewOption={() => false}
            value={activitiesOptions.filter((a) => selectedSyncData && selectedSyncData.saveEvent === a.value)}
            onChange={(e) => onCalendarEventChange(e)}
          />
        </Form.Group>
        <Form.Group id="settings-profile-platform-setting-calender-sync-activity-checkbox">
          <Form.Label aria-label="synchronize-the-event">{"All of your Sales Simplify events will synchronize to your calendar with the name picked from here. Sales Simplify > Calendar."}</Form.Label>
          <div className="checkbox-list ml-20px">
            {activities.map((each, index) => {
              return (
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="Checkboxes1"
                    checked={selectedSyncData && selectedSyncData.savedSyncActivities && selectedSyncData.savedSyncActivities.includes(each._id)}
                    onClick={(e) => onActivityChange(e, each._id)}
                  />
                  <span></span>
                  <div className="calendar-activities-list">
                    <i className={`bi ${each.symbName} calendar-activities-list-icon`}></i> {each.name}
                  </div>
                </label>
              );
            })}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}
