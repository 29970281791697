// import { getUserAvatar } from 'modules/common/utils';
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  > img {
    border-radius: 14px;
    float: left;
    margin-left: 2px;
  }
`;

function Assignees(props) {
  const getFullName = (user) => {
    return user.details ? user.details.fullName : "Unknown";
  };

  const { users = [], limit = 3 } = props;

  return (
    <Wrapper>
      {users.slice(0, limit).map((user) => (
        <img
          alt={getFullName(user)}
          title={getFullName(user)}
          key={user._id}
          //   src={getUserAvatar(user)}
          width={28}
          height={28}
        />
      ))}
    </Wrapper>
  );
}

export default Assignees;
