import { getLostReason, getActivityLossReasonsSettings } from "../../types/admin-settings.types";

const initState = {
  getLostReason: {
    data: [],
    message: "",
    success: false,
    list: [],
  },
  settings: {
    loading: false,
    enabled: true,
  },
  error: {
    err: false,
    errMsg: "",
  },
};

export default function outcomesSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getLostReason.GET_LOST_REASON_REQUEST:
      return {
        ...state,
        getLostReason: { ...state.getLostReason, loading: true },
      };
    case getLostReason.GET_LOST_REASON_SUCCESS:
      return {
        ...state,
        getLostReason: { loading: false, list: action.payload },
      };
    case getLostReason.GET_LOST_REASON_ERROR:
      return {
        ...state,
        getLostReason: { ...state.getLostReason, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_REQUEST:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: true,
        },
      };
    case getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          enabled: action.payload.isActivityLostReasonEnabled,
        },
      };
    case getActivityLossReasonsSettings.GET_ACTIVITY_LOSS_REASONS_SETTINGS_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
        },
      };
    default:
      return state;
  }
}
