import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

export default function ShareUserInput(props) {
  const userRole = useSelector((store) => store.insight.userRole);

  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);

  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ownedId, setOwnedId] = useState("");

  useEffect(() => {
    if (props.defaultValue && Array.isArray(props.defaultValue)) {
      setSelected(props.defaultValue);
      setSelectedIds(props.defaultValue.map((each) => each.id));
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (!_.isEmpty(userProfile.data)) {
      setOwnedId(userProfile.data.userId);
    }
  }, [userProfile.data]);

  const setValue = (eachUser, type) => {
    if (type === "all") {
      setSelected([{ id: "all", label: "All" }]);
      setSelectedIds("all");
      props.setValue([{ id: "all", label: "All" }]);
    } else if (type === "user") {
      const a = selected.filter((b) => b.id !== "all");
      a.push({ id: eachUser.id, label: eachUser.name });
      setSelected(a);
      setSelectedIds(a.map((b) => b.id));
      props.setValue(a);
    } else if (type === "team") {
      const a = selected.filter((b) => b.id !== "all");
      a.push({ id: eachUser.id, label: eachUser.name });
      setSelected(a);
      setSelectedIds(a.map((b) => b.id));
      props.setValue(a);
    }
  };

  const handleOnRemove = (id) => {
    const a = selected.filter((b) => b.id !== id);
    setSelected(a);
    setSelectedIds(a.map((b) => b.id));
    props.setValue(a);
  };

  return (
    <div>
      <Dropdown className="dropdown-inline mt-1 w-100">
        <Dropdown.Toggle className="bg-white w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {"Select"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu w-100-mn">
          {userRole.loading ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <PerfectScrollbar className="scroll navi navi-hover position-relative h-25vh-mx" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {!props.onlyUsers && (
                <Dropdown.Item className="dropdown-hover" key={-1} onSelect={() => setValue("", "all")} disabled={selectedIds.includes("all")}>
                  All
                </Dropdown.Item>
              )}
              {userRole.list.users && userRole.list.users.length > 0 && (
                <>
                  <Dropdown.Header className="bg-f1f1f1">Users</Dropdown.Header>
                  {userRole.list.allUsers.map((eachUser, index) => {
                    if (eachUser.id === ownedId) return <div key={index}></div>;
                    return (
                      <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => setValue(eachUser, "user")} disabled={selectedIds.includes(eachUser.id)}>
                        {eachUser.name ? eachUser.name : eachUser.email}
                      </Dropdown.Item>
                    );
                  })}
                </>
              )}
              {!props.onlyUsers && userRole.list.teams && userRole.list.teams.length > 0 && (
                <>
                  <Dropdown.Header className="bg-f1f1f1">Teams</Dropdown.Header>
                  {userRole.list.teams.map((eachUser, index) => {
                    return (
                      <Dropdown.Item className="dropdown-hover" key={index} onSelect={() => setValue(eachUser, "team")} disabled={selectedIds.includes(eachUser.id)}>
                        {eachUser.name}
                      </Dropdown.Item>
                    );
                  })}
                </>
              )}
            </PerfectScrollbar>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-flex gap-4 flex-wrap mt-4">
        {selected.map((each, index) => {
          return (
            <div className="badge badge-light-dark fs-7 d-flex gap-2 align-items-center" key={index}>
              <span>{each.label}</span>
              <i className="bi bi-x cursor-pointer text-gray-900 text-hover-danger" onClick={() => handleOnRemove(each.id)}></i>
            </div>
          );
        })}
      </div>
    </div>
  );
}
