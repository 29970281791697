import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

export default function ActivityShowDisplay(props) {
  const [displayValue, setDisplayValue] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (props.data && props.data.value) {
      const date1 = moment(new Date(props.data.value)).valueOf();
      const date2 = moment(new Date(props.data.value))
        .startOf("day")
        .valueOf();
      const todaysEndDate = moment()
        .startOf("day")
        .valueOf();

      if (date1 === date2) {
        setDisplayValue(moment(new Date(props.data.value)).format("DD MMM YYYY"));
      } else {
        setDisplayValue(moment(new Date(props.data.value)).format("MMM DD, h:mm a"));
      }

      if (todaysEndDate > date1) {
        setStatus("overdue");
      } else {
        setStatus("upcoming");
      }
    } else {
      setDisplayValue("");
      setStatus("");
    }
  }, [props.data]);

  if (!props.data) return <span className="data-container"></span>;
  const { activitySymbol } = props.data;
  return (
    <span className={status === "upcoming" ? "data-container text-success" : status === "overdue" ? "data-container text-danger " : "data-container"}>
      {displayValue ? (
        <span>
          <i
            className={`${activitySymbol} pr-10px ${status === "upcoming" ? "text-1BC5BD" : "text-F64E60"}`}
          ></i>
          <span>{displayValue}</span>
        </span>
      ) : (
        "-"
      )}
    </span>
  );
}
