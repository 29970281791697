import {
  // tags settings page
  GET_TAGS_IN_SETTINGS_REQUEST,
  GET_TAGS_IN_SETTINGS_SUCCESS,
  GET_TAGS_IN_SETTINGS_ERROR,
  ADD_TAGS_IN_SETTINGS_REQUEST,
  ADD_TAGS_IN_SETTINGS_SUCCESS,
  ADD_TAGS_IN_SETTINGS_ERROR,
  DELETE_TAGS_IN_SETTINGS_REQUEST,
  DELETE_TAGS_IN_SETTINGS_SUCCESS,
  DELETE_TAGS_IN_SETTINGS_ERROR,
  BULK_DELETE_TAGS_IN_SETTINGS_REQUEST,
  BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS,
  BULK_DELETE_TAGS_IN_SETTINGS_ERROR,
  EDIT_TAGS_IN_SETTINGS_REQUEST,
  EDIT_TAGS_IN_SETTINGS_SUCCESS,
  EDIT_TAGS_IN_SETTINGS_ERROR,
} from "../types/admin-settings.types";

export function getTagsInSettingRequest(req) {
  return {
    type: GET_TAGS_IN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function getTagsInSettingSuccess(req) {
  return {
    type: GET_TAGS_IN_SETTINGS_SUCCESS,
    payload: req,
  };
}
export function getTagsInSettingError(req) {
  return {
    type: GET_TAGS_IN_SETTINGS_ERROR,
    payload: req,
  };
}

export function addTagsInSettingRequest(req) {
  return {
    type: ADD_TAGS_IN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function addTagsInSettingSuccess(req) {
  return {
    type: ADD_TAGS_IN_SETTINGS_SUCCESS,
    payload: req,
  };
}
export function addTagsInSettingError(req) {
  return {
    type: ADD_TAGS_IN_SETTINGS_ERROR,
    payload: req,
  };
}

export function editTagsInSettingRequest(req) {
  return {
    type: EDIT_TAGS_IN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function editTagsInSettingSuccess(req) {
  return {
    type: EDIT_TAGS_IN_SETTINGS_SUCCESS,
    payload: req,
  };
}
export function editTagsInSettingError(req) {
  return {
    type: EDIT_TAGS_IN_SETTINGS_ERROR,
    payload: req,
  };
}

export function deleteTagsInSettingRequest(req) {
  return {
    type: DELETE_TAGS_IN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function deleteTagsInSettingSuccess(req) {
  return {
    type: DELETE_TAGS_IN_SETTINGS_SUCCESS,
    payload: req,
  };
}
export function deleteTagsInSettingError(req) {
  return {
    type: DELETE_TAGS_IN_SETTINGS_ERROR,
    payload: req,
  };
}

export function bulkDeleteTagsInSettingRequest(req) {
  return {
    type: BULK_DELETE_TAGS_IN_SETTINGS_REQUEST,
    payload: req,
  };
}
export function bulkDeleteTagsInSettingSuccess(req) {
  return {
    type: BULK_DELETE_TAGS_IN_SETTINGS_SUCCESS,
    payload: req,
  };
}
export function bulkDeleteTagsInSettingError(req) {
  return {
    type: BULK_DELETE_TAGS_IN_SETTINGS_ERROR,
    payload: req,
  };
}
