// import { useState, useEffect } from "react";

export const getGeoLocation = async () => {
  try {
    const data = await fetch("https://ipapi.co/json").then((data) => data.json());
    return data;
  } catch (err) {
    return {
      ip: "",
      version: "",
      city: "",
      region: "",
      region_code: "",
      country: "",
      country_name: "",
      country_code: "",
      country_code_iso3: "",
      country_capital: "",
      country_tld: "",
      continent_code: "",
      in_eu: false,
      postal: "",
      latitude: 0,
      longitude: 0,
      timezone: "",
      utc_offset: "",
      country_calling_code: "",
      currency: "",
      currency_name: "",
      languages: "",
      country_area: 0,
      country_population: 0,
      asn: "",
      org: "",
    };
  }
};
