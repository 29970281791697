import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import CustomInput from "./inputs";
import CategorySelect from "./categories";
import TagSelect from "./tags";
import { dispatchSnackbarError } from "../../utils/toaster";
import { CustomScrollableModal } from "../modal";
import validator from "validator";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function BulkEditForm({
  page,
  pageSub,
  showColumns,
  show,
  setShow,
  addTag,
  editTag,
  deleteTag,
  addCategory,
  editCategory,
  deleteCategory,
  customFormData,
  setCustomFormData,
  fields,
  handleBulkEdit,
  noOfRows,
  setApiFormat,
  isAllListSelected,
}) {
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState({});
  const [tempFields, setTempFields] = useState([]);
  // const [newFormatforApi,setnewFormatforApi] = useState([])
  useEffect(() => {
    var temp = {};
    var tempCol = [];
    if (fields && fields.length > 0) {
      for (var i = 0; i < fields.length; i++)
        if (fields[i].isEditable && showColumns.includes(fields[i]["_id"])) {
          tempCol.push(fields[i]);
          temp[i] = false;
        }
      setEditing(temp);
      setTempFields(tempCol);
      setLoading(false);
    }
  }, [fields, showColumns]);

  const handleSubmit = () => {
    if (Object.keys(customFormData).length === 0) {
      setShow(false);
      return;
    } else {
      var temp = customFormData;
      for (const editKey in customFormData) {
        const tempField = fields.find((field) => (field.fieldGroup === "CUSTOM" ? `customFields.${field["apiKeyName"]}` === editKey : field["apiKeyName"] === editKey));
        if (tempField && tempField.isRequired) {
          if (
            (typeof customFormData[editKey] === "string" && customFormData[editKey].trim() === "") ||
            customFormData[editKey] === [] ||
            (Array.isArray(customFormData[editKey]) && customFormData[editKey].includes(""))
          ) {
            dispatchSnackbarError(tempField.fieldName + " can not be empty");
            return;
          }
        }
        if (customFormData[editKey] !== "" && tempField.validation === "URL_VALIDATION") {
          if (customFormData[editKey] && typeof customFormData[editKey] === "string" && customFormData[editKey] !== "" && !validator.isURL(customFormData[editKey])) {
            dispatchSnackbarError(customFormData[editKey] + " is not a valid URL");
            return;
          }
        } else if (tempField.validation === "PIN_CODE_VALIDATION") {
          // let pinCodeRegex = /[1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3}/;
          if (!validator.isNumeric(customFormData[editKey])) {
            dispatchSnackbarError(customFormData[editKey] + "is not a valid pincode");
            return;
          }
        } else if (tempField.validation === "EMAIL_VALIDATION") {
          if (customFormData[editKey].length > 0) {
            if (tempField.fieldGroup === "CUSTOM") {
              if (!validator.isEmail(customFormData[editKey])) {
                dispatchSnackbarError(customFormData[editKey] + " is not a valid Email");
                return;
              }
            } else {
              for (var j = 0; j < customFormData[editKey].length; j++) {
                if (!validator.isEmail(customFormData[editKey][j])) {
                  dispatchSnackbarError(customFormData[editKey][j] + " is not a valid Email");
                  return;
                }
              }
            }
          }
        } else if (tempField.validation === "PHONE_VALIDATION") {
          if (customFormData[editKey].length > 0) {
            if (Array.isArray(customFormData[editKey]))
              for (var k = 0; k < customFormData[editKey].length; k++) {
                if (!validator.isNumeric(customFormData[editKey][k].toString())) {
                  dispatchSnackbarError(customFormData[editKey][k] + " is not a valid Phone Number");
                  return;
                }
              }
            else if (!validator.isNumeric(customFormData[editKey].toString())) {
              dispatchSnackbarError(customFormData[editKey] + " is not a valid Phone Number");
              return;
            }
          }
        }
        // if (tempField.fieldGroup === "CUSTOM") {
        //   temp[`customFields.${editKey}`] = temp[editKey]
        //   delete temp[editKey]
        // }
      }
      // setApiFormat(newFormatforApi)
      handleBulkEdit(temp);
    }
  };

  const bulkEditModalProps = {
    show: show,
    title: "Bulk Edit " + noOfRows + " rows",
    showButtons: true,
    buttonText: "Save",
    handleClose: () => {
      setShow(false);
      setCustomFormData({});
    },
    handleAccept: handleSubmit,
  };

  const setValue = (val, apiKeyName, field) => {
    const tempField = fields.find((field) => field["apiKeyName"] === apiKeyName);
    if (tempField.fieldGroup === "CUSTOM") setCustomFormData({ ...customFormData, [`customFields.${apiKeyName}`]: val });
    else setCustomFormData({ ...customFormData, [apiKeyName]: val });
  };

  const handleCheck = (val, index) => {
    setEditing({ ...editing, [index]: val });
    if (val) {
      if (
        tempFields[index].fieldInputType === "TAG_MULTI_SELECT" ||
        tempFields[index].fieldInputType === "PRICING_INPUT" ||
        tempFields[index].fieldInputType === "MULTI_PHONE_INPUT" ||
        tempFields[index].fieldInputType === "MULTI_EMAIL_INPUT"
      )
        setValue([], tempFields[index]["apiKeyName"]);
      else if (tempFields[index].fieldInputType.fieldInputType === "TOGGLE_BUTTON") setValue(false, tempFields[index]["apiKeyName"]);
      else setValue("", tempFields[index]["apiKeyName"]);
    } else {
      const tempField = fields.find((field) => field["apiKeyName"] === tempFields[index]["apiKeyName"]);
      var temp = customFormData;
      delete temp[tempField.fieldGroup === "CUSTOM" ? `customFields.${tempFields[index]["apiKeyName"]}` : tempFields[index]["apiKeyName"]];
      setCustomFormData(temp);
    }
  };

  return (
    <CustomScrollableModal modalProps={bulkEditModalProps}>
      {loading || !show ? (
        <div className="w-100 d-flex justify-content-center align-items-center h-50vh-mn">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <PerfectScrollbar className="scroll h-60vh-mx position-relative" options={{ wheelSpeed: 1, wheelPropagation: false }}>
          {isAllListSelected && (
            <span className="card p-4 border-dashed border-warning bg-light-warning text-warning mb-4">
              In "Select All" bulk operation, the data will only be updated on this table only and no changes will be transmitted to Cadence and Automation. Furthermore, no API's or integrations will be call or
              activated.
            </span>
          )}
          <Form>
            {tempFields.map((field, index) => {
              if (field.isEditable) {
                return field.fieldInputType === "CATEGORY_SELECT" ? (
                  <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                    <div className="d-flex flex-stack">
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`}>{field.fieldName}</Form.Label>
                      <Form.Group id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`} className="mb-0" controlId={`checkbox-${index}`}>
                        <Form.Check inline={true} type="switch" label=" " id={`check-${index}`} value={editing[index]} checked={editing[index]} onChange={(e) => handleCheck(e.target.checked, index)} />
                      </Form.Group>
                    </div>
                    {editing[index] ? (
                      <CategorySelect addCategory={addCategory} editCategory={editCategory} deleteCategory={deleteCategory} field={field} value={customFormData[field.apiKeyName]} setValue={setValue} />
                    ) : null}
                  </Form.Group>
                ) : field.fieldInputType === "TAG_MULTI_SELECT" ? (
                  <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                    <div className="d-flex flex-stack">
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`}>{field.fieldName}</Form.Label>
                      <Form.Group id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`} className="mb-0" controlId={`checkbox-${index}`}>
                        <Form.Check inline={true} type="switch" label=" " id={`check-${index}`} value={editing[index]} checked={editing[index]} onChange={(e) => handleCheck(e.target.checked, index)} />
                      </Form.Group>
                    </div>
                    {editing[index] ? (
                      <TagSelect
                        page={page}
                        pageSub={pageSub}
                        addTag={addTag}
                        editTag={editTag}
                        deleteTag={deleteTag}
                        field={field}
                        value={
                          !customFormData[field.apiKeyName] || customFormData[field.apiKeyName] === "" || customFormData[field.apiKeyName].length === 0
                            ? [""]
                            : customFormData[field.apiKeyName].map((tag) => (tag["_id"] ? tag["_id"] : tag))
                        }
                        setValue={setValue}
                      />
                    ) : null}
                  </Form.Group>
                ) : (
                  <Form.Group key={index} id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`}>
                    <div className="d-flex flex-stack">
                      <Form.Label aria-label={`${pageSub}-${field.fieldName}`}>{field.fieldName}</Form.Label>
                      <Form.Group id={`${pageSub}-${field.fieldName}`} aria-label={`${pageSub}-${field.fieldName}`} className="mb-0" controlId={`checkbox-${index}`}>
                        <Form.Check inline={true} type="switch" label=" " id={`check-${index}`} value={editing[index]} checked={editing[index]} onChange={(e) => handleCheck(e.target.checked, index)} />
                      </Form.Group>
                    </div>
                    {editing[index] ? (
                      <CustomInput
                        clearable={true}
                        page={page}
                        pageSub={pageSub}
                        field={field}
                        value={customFormData[field.fieldGroup === "CUSTOM" ? `customFields.${field.apiKeyName}` : field.apiKeyName]}
                        setValue={setValue}
                        allData={customFormData}
                      />
                    ) : null}
                  </Form.Group>
                );
              } else return null;
            })}
          </Form>
        </PerfectScrollbar>
      )}
    </CustomScrollableModal>
  );
}
