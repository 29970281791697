import { GET_USAGE_RECORD_REPORTS_REQUEST, GET_USAGE_RECORD_REPORTS_SUCCESS, GET_USAGE_RECORD_REPORTS_ERROR, getUsageRecordsV2 } from "../types/usage.types";

const initialState = {
  data: {},
  status: false,
  error: "",
  v2: {
    data: {},
    status: false,
    error: "",
  },
};

export function usageRecordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USAGE_RECORD_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USAGE_RECORD_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_USAGE_RECORD_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case getUsageRecordsV2.GET_USAGE_RECORDS_V2_REQUEST:
      return {
        ...state,
        v2: {
          ...state.v2,
          loading: true,
        },
      };
    case getUsageRecordsV2.GET_USAGE_RECORDS_V2_SUCCESS:
      return {
        ...state,
        v2: {
          ...state.v2,
          loading: false,
          data: action.payload,
        },
      };
    case getUsageRecordsV2.GET_USAGE_RECORDS_V2_ERROR:
      return {
        ...state,
        v2: {
          ...state.v2,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
}
