import { getCompanySettings, getAccountBilling } from "../../types/admin-settings.types";

const initState = {
  data: {},
  error: {
    err: false,
    errMsg: "",
  },
  accountWiseBilling: { data: {}, loading: false },
  currentSubscriptionStatus: {
    current: null,
    shouldBe: null,
  },
};

export default function companySettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCompanySettings.GET_COMPANY_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case getCompanySettings.GET_COMPANY_SETTINGS_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case getCompanySettings.GET_COMPANY_SETTINGS_ERROR:
      return { ...state, loading: false };

    case getAccountBilling.GET_ACCOUNT_BILLING_REQUEST:
      return {
        ...state,
        accountWiseBilling: {
          ...state.accountWiseBilling,
          loading: true,
        },
      };
    case getAccountBilling.GET_ACCOUNT_BILLING_SUCCESS:
      return {
        ...state,
        accountWiseBilling: { ...state.accountWiseBilling, data: action.payload, loading: false },
        currentSubscriptionStatus: {
          ...state.currentSubscriptionStatus,
          current: action.payload.isSubscriptionPartialCancelled,
        },
      };
    case getAccountBilling.GET_ACCOUNT_BILLING_ERROR:
      return {
        ...state,
        accountWiseBilling: { ...state.accountWiseBilling, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };
    default:
      return state;
  }
}
