import { getCadenceSettings, getCadenceAllUsersLimit } from "../types/cadence_settings_types";

const initState = {
  data: [],
  loading: false,
  error: null,
  allUserLimit: {
    data: [],
    loading: false,
    error: null,
  },
};

export default function cadenceSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCadenceSettings.GET_CADENCE_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case getCadenceSettings.GET_CADENCE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getCadenceSettings.GET_CADENCE_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case getCadenceAllUsersLimit.REQUEST:
      return {
        ...state,
        allUserLimit: {
          ...state.allUserLimit,
          loading: true,
          error: null,
        },
      };
    case getCadenceAllUsersLimit.SUCCESS:
      return {
        ...state,
        allUserLimit: {
          ...state.allUserLimit,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case getCadenceAllUsersLimit.FAILURE:
      return {
        ...state,
        allUserLimit: {
          ...state.allUserLimit,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
