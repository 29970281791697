import store from "../redux/store";
import { showSnackbarAction } from "../redux/snackbarAction";

export function dispatchSnackbarSuccess(message) {
  store.dispatch(showSnackbarAction(message, "success"));
}

// To show error message that returned from backend
export function dispatchSnackbarError(message) {
  store.dispatch(showSnackbarAction(message, "error"));
}

// To show error message that returned from backend
export function dispatchSnackbarWarning(message) {
  store.dispatch(showSnackbarAction(message, "warning"));
}
