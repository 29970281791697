import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BlankSidePanel from "../../../../../components/blank-side-pannel";
import { BlueGradientButton } from "../../../../../components/button";
import { ContentWithCardLoading } from "../../../../../components/loading";
import { InputLimit } from "../../../../../constants";
import { editCadenceSettingsRequest, getCadenceSettingsRequest } from "../../store/actions/cadence_settings_actions";

export default function CadenceSettingsSidePannel(props) {
  const [maxNylasEmails, setMaxNylasEmails] = useState();
  const [delayBetweenEmails, setDelayBetweenEmails] = useState();

  const cadenceSettings = useSelector((store) => store.cadence.settings);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCadenceSettingsRequest());
  }, [dispatch]);

  useEffect(() => {
    setMaxNylasEmails(cadenceSettings.data.maxNylasEmails);
    setDelayBetweenEmails(cadenceSettings.data.delayBetweenEmails);
  }, [cadenceSettings]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editCadenceSettingsRequest({ maxNylasEmails, delayBetweenEmails }));
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <BlankSidePanel
        show={props.show}
        title={"Cadence Settings"}
        handleClose={() => {
          props.setShow(false);
        }}
      >
        {cadenceSettings.loading ? (
          <ContentWithCardLoading />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Max Emails Per Day</Form.Label>
              <Form.Control maxLength={InputLimit.NUMBER} value={maxNylasEmails} onChange={(e) => setMaxNylasEmails(e.target.value)} type="number" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Delay Between Emails (In minutes)</Form.Label>
              <Form.Control maxLength={InputLimit.NUMBER} value={delayBetweenEmails} onChange={(e) => setDelayBetweenEmails(e.target.value)} type="number" />
            </Form.Group>
            <div className="mb-4">
              <span>Monthly subscriber usages: </span>
              <span>
                <span className="fw-bold">{cadenceSettings.data.montlySubscriberUsage}</span> used out of <span className="fw-bold">{cadenceSettings.data.montlySubscriberLimit}</span>
              </span>
            </div>
            <BlueGradientButton type="submit">Update</BlueGradientButton>
          </Form>
        )}
      </BlankSidePanel>
    </div>
  );
}
