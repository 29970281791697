import { getListOfVideoCategories } from "../types/category.types";

const initState = {
  loading: false,
  list: [],
  count: 0,
};

export default function videoCategoryReducer(state = initState, action = {}) {
  switch (action.type) {
    case getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        list: [],
      };
    case getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        count: action.payload.count,
      };
    case getListOfVideoCategories.GET_LIST_OF_VIDEO_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
