import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { peopleDropdownRequest } from "../../containers/customers/store/actions/actions";
import { dispatchSnackbarError } from "../../utils/toaster";
import SearchSelect from "./searchSelect";
import { Dropdown, Spinner, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { InputLimit } from "../../constants";

export default function PersonSelect({ field, value, setValue, customFormData, setCustomFormData, dontShowAdd }) {
  const [newPersonName, setNewPersonName] = useState("");
  const personStore = useSelector((state) => state.People.peopleDropdownList);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  // const [isNew, setIsNew] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(value)) {
      if (_.isObject(value) && value.name && value._id) {
        setSelected({ label: value.name, value: value._id });
      } else if (_.isString(value)) {
        const find = personStore.list.find((person) => person["_id"] === value);
        if (find) {
          setSelected({ label: find.name, value: value });
        } else if (field && field.fieldType === "DEAL") {
          setSelected({ label: value, value: value });
        } else {
          setSelected({ label: null, value: value });
        }
      } else {
        setSelected({
          label: personStore.list.find((company) => company["_id"] === value) ? personStore.list.find((company) => company["_id"] === value).name : null,
          value: value,
        });
      }
    }
  }, [personStore, value, dontShowAdd, field]);

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < personStore.list.length; i++)
      temp.push({
        value: personStore.list[i]["_id"],
        label: personStore.list[i]["name"],
        email: personStore.list[i]["email"],
        phone: personStore.list[i]["phone"],
        company: personStore.list[i]["company"] ? personStore.list[i]["company"]["_id"] : undefined,
      });
    !dontShowAdd && temp.push({ value: "new", label: "+ Add " + newPersonName });
    setOptions(temp);
  }, [personStore, newPersonName, dontShowAdd]);

  const handleSave = () => {
    if (newPersonName.trim() === "") {
      dispatchSnackbarError("Name can not be blank!");
      return;
    }
    setSelected({ label: newPersonName, value: newPersonName });
    if (setCustomFormData) {
      const temp = { phone: undefined, company: undefined, email: undefined, name: newPersonName };
      setCustomFormData({ ...customFormData, ...temp });
    } else {
      setValue(newPersonName, field.apiKeyName);
    }
  };

  return (
    <SearchSelect
      type={"person"}
      customFormData={customFormData}
      setCustomFormData={setCustomFormData}
      options={options}
      selected={selected}
      setValue={setValue}
      handleSave={handleSave}
      setSearch={setNewPersonName}
      field={field}
      loading={personStore.loading}
      request={peopleDropdownRequest}
    />
  );
}

export function PersonSelectDropdown({ field, setValue, value }) {
  const Store = useSelector((state) => state.People.peopleDropdownList);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    var temp = [];
    for (var i = 0; i < Store.list.length; i++) temp.push({ _id: Store.list[i]["_id"], name: Store.list[i]["name"] });
    setOptions(temp);
  }, [Store]);

  const onSearch = (value) => {
    dispatch(peopleDropdownRequest(value));
    setSearch(value);
  };

  const [selected, setSelected] = useState(value);

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || !selected || value === "" ? "Select " + field.fieldName : selected.name}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu w-100-mn">
        {false ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="navi navi-hover">
            {
              <li className="navi-item py-20px px-24px">
                <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search People" value={search} onChange={(e) => onSearch(e.target.value)} />
              </li>
            }
            <Dropdown.Divider className="m-0" />
            <PerfectScrollbar className="scroll navi navi-hover h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {options && options.length === 0 ? (
                <div className="w-100 d-flex flex-center h-50px-mn">
                  {" "}
                  <span> No People </span>{" "}
                </div>
              ) : (
                options.map((each, index) => {
                  return (
                    <Dropdown.Item
                      className="dropdown-hover"
                      key={index}
                      onSelect={() => {
                        setSelected(each);
                        setValue(each, field.apiKeyName);
                      }}
                    >
                      {each.name}
                    </Dropdown.Item>
                  );
                })
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
