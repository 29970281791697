import React, { useEffect, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function PieChart(props) {
  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll('[shape-rendering="auto"]').forEach((e) => e.remove());
    }, 1000);
  });

  useLayoutEffect(() => {
    let chart = am4core.create(props.chartId, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    chart.legend = new am4charts.Legend();

    chart.data = props.won;

    chart.innerRadius = 70;

    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = props.value;
    series.dataFields.category = props.category;
    if (props.colorList) {
      series.colors.list = props.colorList.map((each) => am4core.color(each));
    }

    // returned function will be called on component unmount
    return () => {
      chart.dispose();
    };
  }, [props.category, props.value, props.won, props.chartId, props.colorList]);

  return <div id={props.chartId} className="width-inherit height-inherit"></div>;
}

export default PieChart;
