import { takeLatest, put, call } from "redux-saga/effects";
import * as profileActions from "../actions/settings.actions";
//import required action types below
// import { TESTING_REQUEST,FACTOR2_REQUEST,CHANGEEMAIL_REQUEST,CALENDAR_GOOGLE_REQUEST,EMAIL_GOOGLE_REQUEST,CALENDAR_START_SYNCING_REQUEST,CALENDAR_SYNC_UPDATE_REQUEST
import {
  TESTING_REQUEST,
  FACTOR2_REQUEST,
  CHANGEEMAIL_REQUEST,
  GETPROFILE_REQUEST,
  UPDATEPROFILE_REQUEST,
  LOGINSESSION_REQUEST,
  GETNOTIFICATION_REQUEST,
  POSTNOTIFICATION_REQUEST,
  LOGINSESSIONPROFILE_REQUEST,
  ADDWEBHOOK_REQUEST,
  GETWEBHOOK_REQUEST,
  PATCHWEBHOOK_REQUEST,
  DELETEWEBHOOK_REQUEST,
  LOGOUT_REQUEST,
  POSTCURRENCY_REQUEST,
  GETCURRENCY_REQUEST,
  PATCHCURRENCY_REQUEST,
  POSTLOSTREASON_REQUEST,
  GETLOSTREASON_REQUEST,
  PATCHLOSTREASON_REQUEST,
  DELETELOSTREASON_REQUEST,
  CALENDAR_GOOGLE_REQUEST,
  EMAIL_GOOGLE_REQUEST,
  CALENDAR_START_SYNCING_REQUEST,
  CALENDAR_SYNC_UPDATE_REQUEST,
  EMAIL_START_SYNCING_REQUEST,
  GOOGLE_CALENDAR_CALLBACK_REQUEST,
  EMAIL_SYNC_UPDATE_REQUEST,
  GOOGLE_EMAIL_CALLBACK_REQUEST,
  GET_CALENDARSYNC_ACCOUNTS_REQUEST,
  GET_CALENDAR_USERINFO_REQUEST,
  GET_EMAILSYNC_ACCOUNTS_REQUEST,
  GET_EMAILSYNC_SYNCACCOUNT_REQUEST,
  GETMANAGEUSER_REQUEST,
  PATCHMANAGEUSER_REQUEST,
  DELETEMANAGEUSER_REQUEST,
  POSTMANAGEUSER_REQUEST,
  PATCHMANAGEROLE_REQUEST,
  GETTEAMSDETAILS_REQUEST,
  POSTTEAM_REQUEST,
  GETTEAM_REQUEST,
  DELETETEAM_REQUEST,
  PATCHTEAM_REQUEST,
  RESETPASSWORD_REQUEST,
  POSTACTIVITY_REQUEST,
  GETACTIVITY_REQUEST,
  PATCHACTIVITY_REQUEST,
  DELETEACTIVITY_REQUEST,
  NYLAS_SYNC_REQUEST,
  NYLAS_SYNC_CALLBACK_REQUEST,
  AVATARPIC_REQUEST,
  GETPROFILEDETAILS_REQUEST,
  POSTCUSTOMFIELD_REQUEST,
  PATCHCUSTOMFIELD_REQUEST,
  GETCUSTOMFIELD_REQUEST,
  DELETECUSTOMFIELD_REQUEST,
  COMPANYLIST_REQUEST,
  TESTWEBHOOK_REQUEST,
  DISABLEWEBHOOK_REQUEST,
  UPDATE_TEAM_REQUEST,
  RESEND_INVITATION_REQUEST,
  //other required types
} from "../types/settings.types";
// import api from '../../../network/apis/api';
import api from "../../../network/apis/api";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../../utils/toaster";
import * as dealListActions from "../../deals/dealList/actions";
// import axios from 'axios';

// function apicall(URL, METHOD){
//     switch(METHOD){
//         case 'get':
//             return  axios.get(URL);
//         default:
//             throw "method not found"
//     }

// }

function* testingFunction(action) {
  try {
    // const res = yield apicall('https://dev10.salessimplify.com/user-service/api/test', 'get')
    const response = yield call(api.get, "/user-service/api/test-token");
    // some condition{}
    if (response.status === 200) {
      dispatchSnackbarSuccess("example text", "success");
      yield put(profileActions.testingSuccess(response.data));
    } else {
      dispatchSnackbarError("error example", "error");
      yield put(profileActions.testingError("error"));
    }
  } catch (e) {
    yield put(profileActions.testingError(e));
  }
}

function* twoFactAuthFunction(action) {
  try {
    // const res = yield apicall('https://dev10.salessimplify.com/user-service/api/test', 'get')
    const response = yield call(api.patch, "/user-service/api/user", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.Factor2AuthSucess(response.data));
    } else {
      yield put(profileActions.Factor2AuthError("error"));
    }
  } catch (e) {
    yield put(profileActions.Factor2AuthError(e));
  }
}

function* changeEmailFunction(action) {
  try {
    // const res = yield apicall('https://dev10.salessimplify.com/user-service/api/test', 'get')
    const response = yield call(api.patch, "/user-service/api/user/email", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.ChangeSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(profileActions.ChangeError("error"));
    } else {
      yield put(profileActions.ChangeError("error"));
    }
  } catch (e) {
    yield put(profileActions.ChangeError(e));
  }
}

function* getProfileFunction(action) {
  try {
    const response = yield call(api.get, "/user-service/api/user");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getProfileSuccess(response.data));
    } else {
      yield put(profileActions.getProfileError("error"));
    }
  } catch (e) {
    yield put(profileActions.getProfileError(e));
  }
}

function* updateProfileFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/profile", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.updateProfileSuccess(response.data));
    } else {
      yield put(profileActions.updateProfileError("error"));
    }
  } catch (e) {
    //
    yield put(profileActions.updateProfileError(e));
  }
}

function* getLoginSessionFunction(action) {
  try {
    const response = yield call(api.get, `/user-service/api/session-details/all?id=${action.selectUser === null ? "all" : action.selectUser}&limit=10&page=${action.currentPage}`);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getloginSessionSuccess(response.data));
    } else {
      yield put(profileActions.getloginSessionError("error"));
    }
  } catch (e) {
    yield put(profileActions.getloginSessionError(e));
  }
}

function* getLoginSessionProfileFunction(action) {
  try {
    const response = yield call(api.get, `/user-service/api/session-details?limit=10&page=${action.currentPage}`);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getloginSessionSuccess(response.data));
    } else {
      yield put(profileActions.getloginSessionError("error"));
    }
  } catch (e) {
    yield put(profileActions.getloginSessionError(e));
  }
}

function* getNotificationFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/notification");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getNotificationSuccess(response.data));
    } else {
      yield put(profileActions.getNotificationError("error"));
    }
  } catch (e) {
    yield put(profileActions.getNotificationError(e));
  }
}

function* postNotificationFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/notification", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postNotificationSuccess(response.data));
    } else {
      yield put(profileActions.postNotificationError("error"));
    }
  } catch (e) {
    yield put(profileActions.postNotificationError(e));
  }
}

function* addWebhookFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/webhook/v2", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.addWebHookSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.addWebHookSuccess(response.data));
    } else {
      yield put(profileActions.addWebHookError("error"));
    }
  } catch (e) {
    yield put(profileActions.addWebHookError(e));
  }
}

function* getWebhookFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/webhook/v2");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getWebHookSuccess(response.data));
    } else {
      yield put(profileActions.getWebHookError("error"));
    }
  } catch (e) {
    yield put(profileActions.getWebHookError(e));
  }
}

function* patchWebhookFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/webhook/v2`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchWebHookSuccess(response.data));
    } else {
      yield put(profileActions.patchWebHookError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchWebHookError(e));
  }
}

function* deleteWebhookFunction(action) {
  try {
    const response = yield call(api.delete, `/api/crm/webhook/v2/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.deleteWebHookSuccess(response.data));
    } else {
      yield put(profileActions.deleteWebHookError("error"));
    }
  } catch (e) {
    yield put(profileActions.deleteWebHookError(e));
  }
}

function* signUpFunction(action) {
  try {
    const response = yield call(api.post, `/user-service/api/logout`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.LogoutSuccess(response.data));
    } else {
      yield put(profileActions.LogoutError("error"));
    }
  } catch (e) {
    yield put(profileActions.LogoutError(e));
  }
}

function* postCurrencyFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/currency", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postCurrencySuccess(response.data));
    } else {
      yield put(profileActions.postCurrencyError("error"));
    }
  } catch (e) {
    yield put(profileActions.postCurrencyError(e));
  }
}

function* getCurrencyFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/currency");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getCurrencySuccess(response.data));
    } else {
      yield put(profileActions.getCurrencyError("error"));
    }
  } catch (e) {
    yield put(profileActions.getCurrencyError(e));
  }
}

function* patchCurrencyFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/currency/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchCurrencySuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message, "error");
    } else {
      yield put(profileActions.patchCurrencyError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchCurrencyError(e));
  }
}

function* postLostReasonFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/lost-reason", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postLostReasonSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.postLostReasonSuccess(response.data));
    } else {
      yield put(profileActions.postLostReasonError("error"));
    }
  } catch (e) {
    yield put(profileActions.postLostReasonError(e));
  }
}

function* getLostReasonFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/lost-reason");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getLostReasonSuccess(response.data));
    } else {
      yield put(profileActions.getLostReasonError("error"));
    }
  } catch (e) {
    yield put(profileActions.getLostReasonError(e));
  }
}

function* patchLostReasonFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/lost-reason/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchLostReasonSuccess(response.data));
    } else {
      yield put(profileActions.patchLostReasonError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchLostReasonError(e));
  }
}

function* deleteLostReasonFunction(action) {
  try {
    const response = yield call(api.delete, `/api/crm/lost-reason/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.deleteLostReasonSuccess(response.data));
    } else {
      yield put(profileActions.deleteLostReasonError("error"));
    }
  } catch (e) {
    yield put(profileActions.deleteLostReasonError(e));
  }
}

function* googleCalendarFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/calendar-sync-settings/google");

    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.calendarGoogleSuccess(response.data));
    }
  } catch (e) {
    yield put(profileActions.calendarGoogleError(e));
  }
}

function* googleEmailFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/email-sync-settings/google");
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.calendarGoogleSuccess(response.data));
    }
  } catch (e) {
    yield put(profileActions.emailGoogleError(e));
  }
}

function* calendarStartSync(action) {
  try {
    // const res = yield call(api.post,"/api/crm/calendar-sync-settings/create",action.payload)
  } catch (e) {
    yield put(profileActions.calendarStartSyncingError(e));
  }
}

function* calendarSyncUpdate(action) {
  try {
    // const res = yield call(api.post,"/api/crm/calendar-sync-settings/update/1234",action.payload)
  } catch (e) {
    yield put(profileActions.calendarSyncUpdateError(e));
  }
}

function* getManageUserFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/profile/all");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getManageUserSuccess(response.data));
    } else {
      yield put(profileActions.getManageUserError("error"));
    }
  } catch (e) {
    yield put(profileActions.getManageUserError(e));
  }
}

function* patchManageUserFunction(action) {
  try {
    // const response = yield call(api.patch, `/api/crm/user/${action.paramId}`, action.payload);
    const response = yield call(api.patch, action.payload.from === "edit-teams" ? `/api/crm/user/${action.paramId}` : "/user-service/api/user/activate-deactivate", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield put(profileActions.patchManageUserSuccess(response.data));
      yield action.cb(response.data);
    } else {
      yield put(profileActions.patchManageUserError("error"));
      yield action.cb(response.data);
    }
  } catch (e) {
    yield put(profileActions.patchManageUserError(e));
  }
}

function* deleteManageUserFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/user/delete/${action.payload.id}`, action.payload.body);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.deleteManageUserSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError("User can not be deleted", "error");
    } else {
      yield put(profileActions.deleteManageUserError("error"));
    }
  } catch (e) {
    yield put(profileActions.deleteManageUserError(e));
  }
}

function* postManageUserFunction(action) {
  try {
    const response = yield call(api.post, "/user-service/api/user/invite", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postManageUserSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.postManageUserSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message, "error");
    } else {
      yield put(profileActions.postManageUserError("error"));
    }
  } catch (e) {
    yield put(profileActions.postManageUserError(e));
  }
}

function* emailStartSync(action) {
  try {
    // const res = yield call(api.get,'/api/crm/email-sync-settings/create',action.payload);
  } catch (e) {
    yield put(profileActions.emailStartSyncError(e));
  }
}

function* getTeamsDetailsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/team/details", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getTeamsDetailsSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.getTeamsDetailsSuccess(response.data));
    } else {
      yield put(profileActions.getTeamsDetailsError("error"));
    }
  } catch (e) {
    yield put(profileActions.getTeamsDetailsError(e));
  }
}

function* postTeamFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/team/", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postTeamSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.postTeamSuccess(response.data));
    } else {
      yield put(profileActions.postTeamError("error"));
    }
  } catch (e) {
    yield put(profileActions.postTeamError(e));
  }
}

function* getTeamsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/team/", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getTeamsSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.getTeamsSuccess(response.data));
    } else {
      yield put(profileActions.getTeamsError("error"));
    }
  } catch (e) {
    yield put(profileActions.getTeamsError(e));
  }
}

function* deleteTeamsFunction(action) {
  try {
    const response = yield call(api.delete, `/api/crm/team/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.deleteTeamsSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError("Team can not be deleted", "error");
    } else {
      yield put(profileActions.deleteTeamsError("error"));
    }
  } catch (e) {
    yield put(profileActions.deleteTeamsError(e));
  }
}

function* patchTeamsFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/team/agent/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchTeamsSuccess(response.data));
    } else {
      yield put(profileActions.patchTeamsError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchTeamsError(e));
  }
}

function* patchResetPassFunction(action) {
  try {
    const response = yield call(api.patch, `/user-service/api/user/password`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchPasswordResetSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message);
      yield put(profileActions.patchPasswordResetError("error"));
    } else {
      yield put(profileActions.patchPasswordResetError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchPasswordResetError(e));
  }
}

function* googleCalendarCallbackFunction(action) {
  try {
    // const res = yield call(api.get, `/api/crm/calendar-sync-settings/callback`)
  } catch (e) {
    yield put(profileActions.googleCalendarSyncCallbackError(e));
  }
}

function* googleEmailCallbackFunction(action) {
  try {
    // const res = yield call(api.post, `/api/crm/email-sync-settings/google/callback?code=${action.payload.code}`)
  } catch (e) {
    yield put(profileActions.googleEmailSyncCallbackError(e));
  }
}

function* emailSyncUpdate(action) {
  try {
    // const res = yield call(api.post, "/api/crm/email-sync-settings/update/1234", action.payload)
  } catch (e) {
    yield put(profileActions.emailSyncUpdateError(e));
  }
}

function* getCalendarAccounts(action) {
  try {
    // const res = yield call(api.get,'api/crm/calendars-sync-settings/get-calendars/cqwjflx74jya4srhlf9woxzmg')
  } catch (e) {
    yield put(profileActions.getCalendarSyncAccountsError);
  }
}

function* getEmailAccounts(action) {
  try {
    // const res = yield call(api.get,'/api/crm/email-sync-settings/get-accounts')
  } catch (e) {
    yield put(profileActions.getEmailSyncAccountsError);
  }
}

function* getCalendarUserInfo(action) {
  try {
    // const res = yield call(api.get,'/api/crm/calendar-sync-settings/get-user-info/1234')
  } catch (e) {
    yield put(profileActions.getCalendarUserInfoRequest);
  }
}

function* getEmailSyncedAccount(action) {
  try {
    // const res = yield call(api.get,'/api/crm/calendar-sync-settings/start-sync/1234')
  } catch {
    yield put(profileActions.getEmailSyncConnectedAccountError);
  }
}

function* postActivityFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/activity", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postActivitySuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.postActivitySuccess(response.data));
    } else {
      yield put(profileActions.postActivityError("error"));
    }
  } catch (e) {
    yield put(profileActions.postActivityError(e));
  }
}

function* getActivityFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/activity", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getActivitySuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.getActivitySuccess(response.data));
    } else {
      yield put(profileActions.getActivityError("error"));
    }
  } catch (e) {
    yield put(profileActions.getActivityError(e));
  }
}

function* patchActivityFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/activity/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchActivitySuccess(response.data));
    } else {
      yield put(profileActions.patchActivityError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchActivityError(e));
  }
}

function* deleteActivityFunction(action) {
  try {
    const response = yield call(api.delete, `/api/crm/activity/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.deleteActivitySuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError("Activity can not be deleted", "error");
    } else {
      yield put(profileActions.deleteActivityError("error"));
    }
  } catch (e) {
    yield put(profileActions.deleteActivityError(e));
  }
}

function* patchManageRoleFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/user/role/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchManageRoleSuccess(response.data));
    } else {
      yield put(profileActions.patchManageRoleError("error"));
    }
  } catch (e) {
    //
    yield put(profileActions.patchManageRoleError(e));
  }
}

function* AvatarPicFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/profile/avatar", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchAvatarPicSuccess(response.data));
      yield put(profileActions.getUserProfileRequest(() => {}));
    } else {
      yield put(profileActions.patchAvatarPicError("error"));
    }
  } catch (e) {
    //
    yield put(profileActions.patchAvatarPicError(e));
  }
}

function* getProfileDetailsFunction(action) {
  try {
    const response = yield call(api.get, "/api/crm/profile");
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getUserProfileSuccess(response.data));
    } else {
      yield put(profileActions.getUserProfileError("error"));
    }
  } catch (e) {
    yield put(profileActions.getUserProfileError(e));
  }
}

function* postCustomFieldFunction(action) {
  try {
    const response = yield call(api.post, "/api/crm/data-fields/create-custom", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.postCustomFieldSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.postCustomFieldSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError(response.data.message, "error");
    } else {
      yield put(profileActions.postCustomFieldError("error"));
    }
  } catch (e) {
    yield put(profileActions.postCustomFieldError(e));
  }
}

function* patchCustomFieldFunction(action) {
  try {
    const response = yield call(api.patch, "/api/crm/data-fields/custom-edit", action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.patchCustomFieldSuccess(response.data));
    } else if (response.status === 201) {
      yield action.cb(response.data);
      yield put(profileActions.patchCustomFieldSuccess(response.data));
    } else {
      yield put(profileActions.patchCustomFieldError("error"));
    }
  } catch (e) {
    yield put(profileActions.patchCustomFieldError(e));
  }
}

function* getCustomFieldFunction(action) {
  try {
    const response = yield call(api.get, `/api/crm/data-fields/list?fieldType=${action.params}`);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.getCustomFieldSuccess(response.data));
      if (action.params === "DEAL") yield put(dealListActions.getDealAddFormFieldsRequest({ request: { fieldType: "DEAL" }, skipIfDataPresent: false }));
    } else {
      yield put(profileActions.getCustomFieldError("error"));
    }
  } catch (e) {
    //
    yield put(profileActions.getCustomFieldError(e));
  }
}

function* deleteCustomFieldFunction(action) {
  try {
    const response = yield call(api.delete, `/api/crm/data-fields/${action.paramId}`, action.payload);
    // some condition{}
    if (response.status === 200) {
      yield action.cb(response.data);
      yield put(profileActions.deleteCustomFieldSuccess(response.data));
    } else if (response.status === 400) {
      dispatchSnackbarError("Date field can not be deleted", "error");
    } else {
      yield put(profileActions.deleteCustomFieldError("error"));
    }
  } catch (e) {
    yield put(profileActions.deleteCustomFieldError(e));
  }
}

function* nylasSyncFunction(action) {
  try {
    const response = yield call(api.get, `/api/crm/${action.synctype}/hostedAuth`, action.payload);

    yield put(profileActions.nylasSyncCallbackSuccess(response.data));
    yield action.cb(response.data);
  } catch (e) {
    yield put(profileActions.nylasSyncError(e));
  }
}

function* nylasSyncCallbackFunction(action) {
  try {
    // const res = yield call(api.get,`/api/crm/calendar-sync-settings/hostedAuthCallback?code=${action.payload.code}`,action.payload)
  } catch (e) {
    yield put(profileActions.nylasSyncCallbackError(e));
  }
}

function* companyListFunction(action) {
  try {
    const response = yield call(api.get, `/user-service/api/user/list-of-companies`, {});

    yield put(profileActions.companyListSuccess(response.data));
    yield action.cb(response.data);
  } catch (e) {
    yield put(profileActions.companyListError(e));
  }
}

function* testWebhookFunction(action) {
  try {
    const response = yield call(api.post, `/api/crm/webhook/v2/test`, action.payload);
    yield put(profileActions.testWebhookSuccess(response.data));
    yield action.cb(response.data);
  } catch (e) {
    yield put(profileActions.testWebhookError(e));
  }
}

function* disableWebhookFunction(action) {
  try {
    const response = yield call(api.patch, `/api/crm/webhook/v2/status`, action.payload);
    yield put(profileActions.disableWebhookSuccess(response.data));
    yield action.cb(response.data);
  } catch (e) {
    yield put(profileActions.disableWebhookError(e));
  }
}

function* updateTeamFunction(action) {
  try {
    const res = yield call(api.patch, "/api/crm/team/update-team", action.payload);
    if (res.status === 200) {
      yield action.cb(res.data);
    }
  } catch (e) {
    yield put(profileActions.updateTeamError(e));
  }
}

function* resendInvitationfunction(action) {
  try {
    const res = yield call(api.post, "/user-service/api/user/reinvite", action.payload);
    if (res.status === 200) {
      yield put(profileActions.resendInvitationSuccess(res.data));
      dispatchSnackbarSuccess(res.data.message, "success");
    }
  } catch (e) {
    yield put(profileActions.resendInvitationError(e));
  }
}

export default function*() {
  yield takeLatest(TESTING_REQUEST, testingFunction); // similarly add required functions
  yield takeLatest(FACTOR2_REQUEST, twoFactAuthFunction);
  yield takeLatest(CHANGEEMAIL_REQUEST, changeEmailFunction);
  yield takeLatest(GETPROFILE_REQUEST, getProfileFunction);
  yield takeLatest(UPDATEPROFILE_REQUEST, updateProfileFunction);

  yield takeLatest(LOGINSESSION_REQUEST, getLoginSessionFunction);
  yield takeLatest(LOGINSESSIONPROFILE_REQUEST, getLoginSessionProfileFunction);

  yield takeLatest(GETNOTIFICATION_REQUEST, getNotificationFunction);
  yield takeLatest(POSTNOTIFICATION_REQUEST, postNotificationFunction);

  yield takeLatest(ADDWEBHOOK_REQUEST, addWebhookFunction);
  yield takeLatest(GETWEBHOOK_REQUEST, getWebhookFunction);
  yield takeLatest(PATCHWEBHOOK_REQUEST, patchWebhookFunction);
  yield takeLatest(DELETEWEBHOOK_REQUEST, deleteWebhookFunction);

  yield takeLatest(LOGOUT_REQUEST, signUpFunction);

  yield takeLatest(POSTCURRENCY_REQUEST, postCurrencyFunction);
  yield takeLatest(GETCURRENCY_REQUEST, getCurrencyFunction);
  yield takeLatest(PATCHCURRENCY_REQUEST, patchCurrencyFunction);

  yield takeLatest(POSTLOSTREASON_REQUEST, postLostReasonFunction);
  yield takeLatest(GETLOSTREASON_REQUEST, getLostReasonFunction);
  yield takeLatest(PATCHLOSTREASON_REQUEST, patchLostReasonFunction);
  yield takeLatest(DELETELOSTREASON_REQUEST, deleteLostReasonFunction);

  yield takeLatest(GETMANAGEUSER_REQUEST, getManageUserFunction);
  yield takeLatest(PATCHMANAGEUSER_REQUEST, patchManageUserFunction);
  yield takeLatest(DELETEMANAGEUSER_REQUEST, deleteManageUserFunction);
  yield takeLatest(POSTMANAGEUSER_REQUEST, postManageUserFunction);
  yield takeLatest(PATCHMANAGEROLE_REQUEST, patchManageRoleFunction);

  yield takeLatest(CALENDAR_GOOGLE_REQUEST, googleCalendarFunction);
  yield takeLatest(EMAIL_GOOGLE_REQUEST, googleEmailFunction);
  yield takeLatest(CALENDAR_START_SYNCING_REQUEST, calendarStartSync);
  yield takeLatest(CALENDAR_SYNC_UPDATE_REQUEST, calendarSyncUpdate);

  yield takeLatest(GETTEAMSDETAILS_REQUEST, getTeamsDetailsFunction);
  yield takeLatest(POSTTEAM_REQUEST, postTeamFunction);
  yield takeLatest(GETTEAM_REQUEST, getTeamsFunction);
  yield takeLatest(DELETETEAM_REQUEST, deleteTeamsFunction);
  yield takeLatest(PATCHTEAM_REQUEST, patchTeamsFunction);

  yield takeLatest(EMAIL_START_SYNCING_REQUEST, emailStartSync);
  yield takeLatest(GOOGLE_CALENDAR_CALLBACK_REQUEST, googleCalendarCallbackFunction);
  yield takeLatest(GOOGLE_EMAIL_CALLBACK_REQUEST, googleEmailCallbackFunction);
  yield takeLatest(EMAIL_SYNC_UPDATE_REQUEST, emailSyncUpdate);
  yield takeLatest(GET_CALENDARSYNC_ACCOUNTS_REQUEST, getCalendarAccounts);
  yield takeLatest(GET_EMAILSYNC_ACCOUNTS_REQUEST, getEmailAccounts);
  yield takeLatest(GET_CALENDAR_USERINFO_REQUEST, getCalendarUserInfo);
  yield takeLatest(GET_EMAILSYNC_SYNCACCOUNT_REQUEST, getEmailSyncedAccount);
  yield takeLatest(RESETPASSWORD_REQUEST, patchResetPassFunction);

  yield takeLatest(POSTACTIVITY_REQUEST, postActivityFunction);
  yield takeLatest(GETACTIVITY_REQUEST, getActivityFunction);
  yield takeLatest(PATCHACTIVITY_REQUEST, patchActivityFunction);
  yield takeLatest(DELETEACTIVITY_REQUEST, deleteActivityFunction);

  yield takeLatest(NYLAS_SYNC_REQUEST, nylasSyncFunction);
  yield takeLatest(NYLAS_SYNC_CALLBACK_REQUEST, nylasSyncCallbackFunction);

  yield takeLatest(AVATARPIC_REQUEST, AvatarPicFunction);

  yield takeLatest(GETPROFILEDETAILS_REQUEST, getProfileDetailsFunction);

  yield takeLatest(POSTCUSTOMFIELD_REQUEST, postCustomFieldFunction);
  yield takeLatest(PATCHCUSTOMFIELD_REQUEST, patchCustomFieldFunction);
  yield takeLatest(GETCUSTOMFIELD_REQUEST, getCustomFieldFunction);
  yield takeLatest(DELETECUSTOMFIELD_REQUEST, deleteCustomFieldFunction);

  yield takeLatest(COMPANYLIST_REQUEST, companyListFunction);

  yield takeLatest(TESTWEBHOOK_REQUEST, testWebhookFunction);
  yield takeLatest(DISABLEWEBHOOK_REQUEST, disableWebhookFunction);
  yield takeLatest(UPDATE_TEAM_REQUEST, updateTeamFunction);
  yield takeLatest(RESEND_INVITATION_REQUEST, resendInvitationfunction);
}
