// settings
export const getConditions = {
  GET_LIST_OF_CONDITION_REQUEST: "GET_LIST_OF_CONDITION_REQUEST",
  GET_LIST_OF_CONDITION_SUCCESS: "GET_LIST_OF_CONDITION_SUCCESS",
  GET_LIST_OF_CONDITION_ERROR: "GET_LIST_OF_CONDITION_ERROR",
};

export const getActionList = {
  GET_LIST_OF_ACTIONS_REQUEST: "GET_LIST_OF_ACTIONS_REQUEST",
  GET_LIST_OF_ACTIONS_SUCCESS: "GET_LIST_OF_ACTIONS_SUCCESS",
  GET_LIST_OF_ACTIONS_ERROR: "GET_LIST_OF_ACTIONS_ERROR",
};

export const createEmailNotifications = {
  CREATE_EMAIL_NOTIFICATION_REQUEST: "CREATE_EMAIL_NOTIFICATION_REQUEST",
  CREATE_EMAIL_NOTIFICATION_SUCCESS: "CREATE_EMAIL_NOTIFICATION_SUCCESS",
  CREATE_EMAIL_NOTIFICATION_ERROR: "CREATE_EMAIL_NOTIFICATION_ERROR",
};
export const editEmailNotifications = {
  EDIT_EMAIL_NOTIFICATION_REQUEST: "EDIT_EMAIL_NOTIFICATION_REQUEST",
  EDIT_EMAIL_NOTIFICATION_SUCCESS: "EDIT_EMAIL_NOTIFICATION_SUCCESS",
  EDIT_EMAIL_NOTIFICATION_ERROR: "EDIT_EMAIL_NOTIFICATION_ERROR",
};
export const getEmailNotificationsByID = {
  GET_EMAIL_NOTIFICATION_BY_ID_REQUEST: "GET_EMAIL_NOTIFICATION_BY_ID_REQUEST",
  GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS: "GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS",
  GET_EMAIL_NOTIFICATION_BY_ID_ERROR: "GET_EMAIL_NOTIFICATION_BY_ID_ERROR",
};

export const createFieldUpdate = {
  CREATE_FIELD_UPDATE_ACTION_REQUEST: "CREATE_FIELD_UPDATE_ACTION_REQUEST",
  CREATE_FIELD_UPDATE_ACTION_SUCCESS: "CREATE_FIELD_UPDATE_ACTION_SUCCESS",
  CREATE_FIELD_UPDATE_ACTION_ERROR: "CREATE_FIELD_UPDATE_ACTION_ERROR",
};
export const editFieldUpdate = {
  EDIT_FIELD_UPDATE_ACTION_REQUEST: "EDIT_FIELD_UPDATE_ACTION_REQUEST",
  EDIT_FIELD_UPDATE_ACTION_SUCCESS: "EDIT_FIELD_UPDATE_ACTION_SUCCESS",
  EDIT_FIELD_UPDATE_ACTION_ERROR: "EDIT_FIELD_UPDATE_ACTION_ERROR",
};
export const getFieldUpdateByID = {
  GET_FIELD_UPDATE_BY_ID_ACTION_REQUEST: "GET_FIELD_UPDATE_BY_ID_ACTION_REQUEST",
  GET_FIELD_UPDATE_BY_ID_ACTION_SUCCESS: "GET_FIELD_UPDATE_BY_ID_ACTION_SUCCESS",
  GET_FIELD_UPDATE_BY_ID_ACTION_ERROR: "GET_FIELD_UPDATE_BY_ID_ACTION_ERROR",
};

export const createDealAction = {
  CREATE_DEAL_ACTION_REQUEST: "CREATE_DEAL_ACTION_REQUEST",
  CREATE_DEAL_ACTION_SUCCESS: "CREATE_DEAL_ACTION_SUCCESS",
  CREATE_DEAL_ACTION_ERROR: "CREATE_DEAL_ACTION_ERROR",
};
export const editDealAction = {
  EDIT_DEAL_ACTION_REQUEST: "EDIT_DEAL_ACTION_REQUEST",
  EDIT_DEAL_ACTION_SUCCESS: "EDIT_DEAL_ACTION_SUCCESS",
  EDIT_DEAL_ACTION_ERROR: "EDIT_DEAL_ACTION_ERROR",
};
export const getDealActionById = {
  GET_DEAL_ACTION_BY_ID_REQUEST: "GET_DEAL_ACTION_BY_ID_REQUEST",
  GET_DEAL_ACTION_BY_ID_SUCCESS: "GET_DEAL_ACTION_BY_ID_SUCCESS",
  GET_DEAL_ACTION_BY_ID_ERROR: "GET_DEAL_ACTION_BY_ID_ERROR",
};

export const addConvertToJunk = {
  ADD_CONVERT_TO_JUNK_ACTION_REQUEST: "ADD_CONVERT_TO_JUNK_ACTION_REQUEST",
  ADD_CONVERT_TO_JUNK_ACTION_SUCCESS: "ADD_CONVERT_TO_JUNK_ACTION_SUCCESS",
  ADD_CONVERT_TO_JUNK_ACTION_ERROR: "ADD_CONVERT_TO_JUNK_ACTION_ERROR",
};
export const editConvertToJunk = {
  EDIT_CONVERT_TO_JUNK_ACTION_REQUEST: "EDIT_CONVERT_TO_JUNK_ACTION_REQUEST",
  EDIT_CONVERT_TO_JUNK_ACTION_SUCCESS: "EDIT_CONVERT_TO_JUNK_ACTION_SUCCESS",
  EDIT_CONVERT_TO_JUNK_ACTION_ERROR: "EDIT_CONVERT_TO_JUNK_ACTION_ERROR",
};
export const getConvertToJunkById = {
  GET_CONVERT_TO_JUNK_ACTION_BY_ID_REQUEST: "GET_CONVERT_TO_JUNK_ACTION_BY_ID_REQUEST",
  GET_CONVERT_TO_JUNK_ACTION_BY_ID_SUCCESS: "GET_CONVERT_TO_JUNK_ACTION_BY_ID_SUCCESS",
  GET_CONVERT_TO_JUNK_ACTION_BY_ID_ERROR: "GET_CONVERT_TO_JUNK_ACTION_BY_ID_ERROR",
};

export const createActivityAction = {
  CREATE_ACTIVITY_ACTION_REQUEST: "CREATE_ACTIVITY_ACTION_REQUEST",
  CREATE_ACTIVITY_ACTION_SUCCESS: "CREATE_ACTIVITY_ACTION_SUCCESS",
  CREATE_ACTIVITY_ACTION_ERROR: "CREATE_ACTIVITY_ACTION_ERROR",
};
export const editActivityAction = {
  EDIT_ACTIVITY_ACTION_REQUEST: "EDIT_ACTIVITY_ACTION_REQUEST",
  EDIT_ACTIVITY_ACTION_SUCCESS: "EDIT_ACTIVITY_ACTION_SUCCESS",
  EDIT_ACTIVITY_ACTION_ERROR: "EDIT_ACTIVITY_ACTION_ERROR",
};
export const getActivityActionById = {
  GET_ACTIVITY_ACTION_BY_ID_REQUEST: "GET_ACTIVITY_ACTION_BY_ID_REQUEST",
  GET_ACTIVITY_ACTION_BY_ID_SUCCESS: "GET_ACTIVITY_ACTION_BY_ID_SUCCESS",
  GET_ACTIVITY_ACTION_BY_ID_ERROR: "GET_ACTIVITY_ACTION_BY_ID_ERROR",
};

export const createDealLossReasonAction = {
  CREATE_DEAL_LOSS_REASON_ACTION_REQUEST: "CREATE_DEAL_LOSS_REASON_ACTION_REQUEST",
  CREATE_DEAL_LOSS_REASON_ACTION_SUCCESS: "CREATE_DEAL_LOSS_REASON_ACTION_SUCCESS",
  CREATE_DEAL_LOSS_REASON_ACTION_ERROR: "CREATE_DEAL_LOSS_REASON_ACTION_ERROR",
};
export const editDealLossReasonAction = {
  EDIT_DEAL_LOSS_REASON_ACTION_REQUEST: "EDIT_DEAL_LOSS_REASON_ACTION_REQUEST",
  EDIT_DEAL_LOSS_REASON_ACTION_SUCCESS: "EDIT_DEAL_LOSS_REASON_ACTION_SUCCESS",
  EDIT_DEAL_LOSS_REASON_ACTION_ERROR: "EDIT_DEAL_LOSS_REASON_ACTION_ERROR",
};
export const getDealLossReasonActionById = {
  GET_DEAL_LOSS_REASON_BY_ID_ACTION_REQUEST: "GET_DEAL_LOSS_REASON_BY_ID_ACTION_REQUEST",
  GET_DEAL_LOSS_REASON_BY_ID_ACTION_SUCCESS: "GET_DEAL_LOSS_REASON_BY_ID_ACTION_SUCCESS",
  GET_DEAL_LOSS_REASON_BY_ID_ACTION_ERROR: "GET_DEAL_LOSS_REASON_BY_ID_ACTION_ERROR",
};

export const createWhatsappTemplateAction = {
  CREATE_WHATSAPP_TEMPLATE_ACTION_REQUEST: "CREATE_WHATSAPP_TEMPLATE_ACTION_REQUEST",
  CREATE_WHATSAPP_TEMPLATE_ACTION_SUCCESS: "CREATE_WHATSAPP_TEMPLATE_ACTION_SUCCESS",
  CREATE_WHATSAPP_TEMPLATE_ACTION_ERROR: "CREATE_WHATSAPP_TEMPLATE_ACTION_ERROR",
};
export const editWhatsappTemplateAction = {
  EDIT_WHATSAPP_TEMPLATE_ACTION_REQUEST: "EDIT_WHATSAPP_TEMPLATE_ACTION_REQUEST",
  EDIT_WHATSAPP_TEMPLATE_ACTION_SUCCESS: "EDIT_WHATSAPP_TEMPLATE_ACTION_SUCCESS",
  EDIT_WHATSAPP_TEMPLATE_ACTION_ERROR: "EDIT_WHATSAPP_TEMPLATE_ACTION_ERROR",
};
export const getWhatsappTemplateByIdAction = {
  GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_REQUEST: "GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_REQUEST",
  GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_SUCCESS: "GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_SUCCESS",
  GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_ERROR: "GET_WHATSAPP_TEMPLATE_BY_ID_ACTION_ERROR",
};

//workflow
export const getListOfWorkflows = {
  GET_LIST_OF_WORKFLOWS_REQUEST: "GET_LIST_OF_WORKFLOWS_REQUEST",
  GET_LIST_OF_WORKFLOWS_SUCCESS: "GET_LIST_OF_WORKFLOWS_SUCCESS",
  GET_LIST_OF_WORKFLOWS_ERROR: "GET_LIST_OF_WORKFLOWS_ERROR",
};
export const getWorkflowDetailsByID = {
  GET_LIST_OF_WORKFLOW_BY_ID_REQUEST: "GET_LIST_OF_WORKFLOW_BY_ID_REQUEST",
  GET_LIST_OF_WORKFLOW_BY_ID_SUCCESS: "GET_LIST_OF_WORKFLOW_BY_ID_SUCCESS",
  GET_LIST_OF_WORKFLOW_BY_ID_ERROR: "GET_LIST_OF_WORKFLOW_BY_ID_ERROR",
};
export const createWorkflow = {
  CREATE_WORKFLOW_REQUEST: "CREATE_WORKFLOW_REQUEST",
  CREATE_WORKFLOW_SUCCESS: "CREATE_WORKFLOW_SUCCESS",
  CREATE_WORKFLOW_ERROR: "CREATE_WORKFLOW_ERROR",
};
export const editWorkflow = {
  EDIT_WORKFLOW_REQUEST: "EDIT_WORKFLOW_REQUEST",
  EDIT_WORKFLOW_SUCCESS: "EDIT_WORKFLOW_SUCCESS",
  EDIT_WORKFLOW_ERROR: "EDIT_WORKFLOW_ERROR",
};
export const deleteWorkflow = {
  DELETE_WORKFLOW_REQUEST: "DELETE_WORKFLOW_REQUEST",
  DELETE_WORKFLOW_SUCCESS: "DELETE_WORKFLOW_SUCCESS",
  DELETE_WORKFLOW_ERROR: "DELETE_WORKFLOW_ERROR",
};

export const updateWorkflowStatus = {
  UPDATE_WORKFLOW_STATUS_REQUEST: "UPDATE_WORKFLOW_STATUS_REQUEST",
  UPDATE_WORKFLOW_STATUS_SUCCESS: "UPDATE_WORKFLOW_STATUS_SUCCESS",
  UPDATE_WORKFLOW_STATUS_ERROR: "UPDATE_WORKFLOW_STATUS_ERROR",
};

export const shareWorkflow = {
  SHARE_WORKFLOW_REQUEST: "SHARE_WORKFLOW_REQUEST",
  SHARE_WORKFLOW_SUCCESS: "SHARE_WORKFLOW_SUCCESS",
  SHARE_WORKFLOW_ERROR: "SHARE_WORKFLOW_ERROR",
};

export const getSharedWorkflowUserList = {
  GET_SHARED_WORKFLOW_USER_LIST_REQUEST: "GET_SHARED_WORKFLOW_USER_LIST_REQUEST",
  GET_SHARED_WORKFLOW_USER_LIST_SUCCESS: "GET_SHARED_WORKFLOW_USER_LIST_SUCCESS",
  GET_SHARED_WORKFLOW_USER_LIST_ERROR: "GET_SHARED_WORKFLOW_USER_LIST_ERROR",
};

// workflow templates
export const getListOfWorkflowTemplates = {
  GET_LIST_OF_WORKFLOW_TEMPLATES_REQUEST: "GET_LIST_OF_WORKFLOW_TEMPLATES_REQUEST",
  GET_LIST_OF_WORKFLOW_TEMPLATES_SUCCESS: "GET_LIST_OF_WORKFLOW_TEMPLATES_SUCCESS",
  GET_LIST_OF_WORKFLOW_TEMPLATES_ERROR: "GET_LIST_OF_WORKFLOW_TEMPLATES_ERROR",
};
export const getWorkflowTemplateByID = {
  GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_REQUEST: "GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_REQUEST",
  GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_SUCCESS: "GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_SUCCESS",
  GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_ERROR: "GET_LIST_OF_WORKFLOW_TEMPLATE_BY_ID_ERROR",
};
export const createWorkflowTemplate = {
  CREATE_WORKFLOW_TEMPLATE_REQUEST: "CREATE_WORKFLOW_TEMPLATE_REQUEST",
  CREATE_WORKFLOW_TEMPLATE_SUCCESS: "CREATE_WORKFLOW_TEMPLATE_SUCCESS",
  CREATE_WORKFLOW_TEMPLATE_ERROR: "CREATE_WORKFLOW_TEMPLATE_ERROR",
};
export const editWorkflowTemplate = {
  EDIT_WORKFLOW_TEMPLATE_REQUEST: "EDIT_WORKFLOW_TEMPLATE_REQUEST",
  EDIT_WORKFLOW_TEMPLATE_SUCCESS: "EDIT_WORKFLOW_TEMPLATE_SUCCESS",
  EDIT_WORKFLOW_TEMPLATE_ERROR: "EDIT_WORKFLOW_TEMPLATE_ERROR",
};
