import React, { useState, useEffect, useMemo } from "react";
import FormInput from "../../../../components/Form-Field/index";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import NormalTable from "../../../../components/normal-table/index";
import { useDispatch } from "react-redux";
import * as actions from "../../actions/settings.actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
  // dispatchSnackbarError
} from "../../../../utils/toaster";
import Pagination from "../../../../components/normal-table/pagination";
import "./style.css";
import { Spinner } from "react-bootstrap";
import CustomAction from "../../../../components/ActionColumn/index";
import { DeleteModal } from "../../../../components/modal/index";

function ButtonHeader(props) {
  const dispatch = useDispatch();

  const [Activity, setActivity] = useState("");
  const [iconName, seticonName] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    seticonName("");
    setActivity("");
  };
  const handleShow = () => setShow(true);

  function handleChange(event) {
    setActivity(event.target.value);
  }

  const handleIcon = (name) => {
    seticonName(name);
  };

  const handleLostReason = () => {
    if (!iconName) {
      dispatchSnackbarError("Please select icon");
      setShow(true);
    } else if (!Activity) {
      dispatchSnackbarError("Please enter name");
      setShow(true);
    } else {
      let addActivityKeyObj = {
        name: Activity,
        symbName: iconName,
      };
      dispatch(
        actions.postActivityRequest(addActivityKeyObj, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Activity Successfully Added", "success");
            dispatch(
              actions.getActivityRequest((data) => {
                props.set(data.data);
              }),
            );
            setShow(false);
            seticonName("");
            setActivity("");
          }
        }),
      );
    }
  };

  return (
    <>
      <div id="kt_signin_password_button" className="ms-auto" onClick={handleShow}>
        <button className="commonStyle btn btn-sm btn-primary my-1">+ Activity</button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Button onClick={() => handleIcon("bi bi-clock-fill")} className={`default ${iconName === "bi bi-clock-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-clock-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-people-fill")} className={`default ${iconName === "bi bi-people-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-people-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-flag-fill")} className={`default ${iconName === "bi bi-flag-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-flag-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-telephone-fill")} className={`default ${iconName === "bi bi-telephone-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-telephone-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("fas fa-apple-alt")} className={`default ${iconName === "bi bi-apple-alt" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="fas fa-apple-alt"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-calendar")} className={`default ${iconName === "bi bi-calendar" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-calendar"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-arrow-down-circle-fill")} className={`default ${iconName === "bi bi-arrow-down-circle-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-arrow-down-circle-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-folder-fill")} className={`default ${iconName === "bi bi-folder-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-folder-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-phone-fill")} className={`default ${iconName === "bi bi-phone-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-phone-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-camera-fill")} className={`default ${iconName === "bi bi-camera-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-camera-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-scissors")} className={`default ${iconName === "bi bi-scissors" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-scissors"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-tools")} className={`default ${iconName === "bi bi-tools" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-tools"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-chat-right-text-fill")} className={`default ${iconName === "bi bi-chat-right-text-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-chat-right-text-fill"></i>
            </Button>

            <Button onClick={() => handleIcon("bi bi-basket2-fill")} className={`default ${iconName === "bi bi-basket2-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-basket2-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-bookmark-star")} className={`default ${iconName === "bi bi-bookmark-star" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-bookmark-star"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-briefcase-fill")} className={`default ${iconName === "bi bi-briefcase-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-briefcase-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-bug")} className={`default ${iconName === "bi bi-bug" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-bug"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-cart-check-fill")} className={`default ${iconName === "bi bi-cart-check-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-cart-check-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-chat-square-text-fill")} className={`default ${iconName === "bi bi-chat-square-text-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-chat-square-text-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-controller")} className={`default ${iconName === "bi bi-controller" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-controller"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-envelope-fill")} className={`default ${iconName === "bi bi-envelope-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-envelope-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-gear")} className={`default ${iconName === "bi bi-gear" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-gear"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-headphones")} className={`default ${iconName === "bi bi-headphones" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-headphones"></i>
            </Button>
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-16px">Name* </span>
            <FormInput placeholder={"Enter activity name"} type={"text"} name={"activity"} value={Activity} onChange={(e) => handleChange(e)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1 btn btn-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1 btn btn-primary" onClick={() => handleLostReason()}>
            Add Activity
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function LostReason() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  // const [Reason, setReason] = useState('')
  const [activityId, setActivityId] = useState();

  const [Activity, setActivity] = useState("");
  const [iconName, seticonName] = useState("");
  const [IsDeactivate, setIsDeactivate] = useState("");

  const handleClose = () => setShow(false);

  // const [lostReasonTable, setlostReasonTable] = useState([])

  /*********************Table Pagination**********************************/
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeletedData] = useState();

  useEffect(() => {
    setLoader(true);
    dispatch(
      actions.getActivityRequest((data) => {
        setLoader(false);
        setComments(data.data);
      }),
    );
  }, [dispatch]);

  const commentsData = useMemo(() => {
    let computedComments = comments;
    setTotalItems(computedComments.length);
    // current page slice
    return computedComments.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
  }, [comments, currentPage]);

  /*****
   * Handle Changes
   * *********/

  function handleChange(event) {
    setActivity(event.target.value);
  }

  const handleIcon = (name) => {
    seticonName(name);
  };

  /********************************************/

  /*****
   * Edit Api
   * ************/

  const EditLostReason = (user) => {
    setShow(true);
    setActivity(user.name);
    seticonName(user.symbName);
    setIsDeactivate(user.isDeactivated);
    setActivityId(user._id);
  };

  const handleLostReason = () => {
    if (!iconName) {
      dispatchSnackbarError("Icon should not be empty");
      setShow(true);
    } else if (!Activity) {
      dispatchSnackbarError("Name should not be empty");
      setShow(true);
    } else {
      let editObj = {
        name: Activity,
        symbName: iconName,
        isDeactivated: IsDeactivate,
      };

      dispatch(
        actions.patchActivityRequest(editObj, activityId, (data) => {
          if (data["success"]) {
            dispatchSnackbarSuccess("Activity Successfully Updated", "success");

            dispatch(
              actions.getActivityRequest((data) => {
                setComments(data.data);
              }),
            );

            setShow(false);
          }
        }),
      );
    }
  };

  const ActDecAct = (user, e) => {
    let editObj = {
      name: user.name,
      symbName: user.symbName,
      isDeactivated: !e.target.checked,
    };

    dispatch(
      actions.patchActivityRequest(editObj, user._id, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Activity Successfully Updated", "success");

          dispatch(
            actions.getActivityRequest((data) => {
              setComments(data.data);
            }),
          );

          setShow(false);
        }
      }),
    );
  };

  /****
   * delete Api
   * ********/

  const deleteLostReason = (data) => {
    setShowDeleteModal(true);
    setDeletedData(data);
    // dispatch(actions.deleteActivityRequest(data._id, (data) => {
    //   if (data['success']) {
    //     dispatchSnackbarSuccess("Activity Successfully Deleted", "success")

    //     dispatch(actions.getActivityRequest((data) => {
    //       setComments(data.data)
    //     }))

    //   }
    // }))
  };

  const deleteLostReasonAccept = () => {
    dispatch(
      actions.deleteActivityRequest(deleteData._id, (data) => {
        if (data["success"]) {
          dispatchSnackbarSuccess("Activity Successfully Deleted", "success");

          dispatch(
            actions.getActivityRequest((data) => {
              setComments(data.data);
            }),
          );
        }
      }),
    );
    setShowDeleteModal(false);
    setDeletedData();
  };

  return (
    <>
      <NormalTable tableHeading={["Activity Name", "Symbol", "Deactivate/Activate", "Action "]} tableTitle={"Activity"} buttonHead={<ButtonHeader set={setComments} />}>
        {loader === true && (
          <tr>
            <td>
              <Spinner className="position-absolute mt-15-percent ml-44-percent" animation="border" variant="primary" />
            </td>
          </tr>
        )}

        {commentsData.length === 0 && loader === false && (
          <tr>
            <td colSpan="4" className="text-center">
              <h6>No Data To Show</h6>
            </td>
          </tr>
        )}

        {commentsData.map((user, id) => {
          return (
            <tr key={id}>
              <td>{user.name}</td>
              <td className="w-30">
                <i className={`${user.symbName} ml-15px`}></i>
              </td>

              <td className="w-30">
                <div className="col-2 text-right">
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input h-20px w-35px"
                      type="checkbox"
                      onChange={(e) => {
                        ActDecAct(user, e);
                      }}
                      checked={!user.isDeactivated}
                      name="ActDeact"
                    />
                    <label className="form-check-label" htmlFor="favoriteSwitch">
                      {/* Favorite */}
                    </label>
                  </div>
                </div>
              </td>

              <td>{!user.isRestricted && <CustomAction Edit={() => EditLostReason(user)} Delete={() => deleteLostReason(user)} />}</td>
            </tr>
          );
        })}
      </NormalTable>

      <div className="position-absolute right-20px mt-minus-21px">
        <Pagination total={totalItems} itemsPerPage={ITEMS_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
      </div>

      {/*end::Action*/}

      {/* ******
       *Edit Lost Reason
       ****** */}

      <DeleteModal
        modalProps={{
          show: showDeleteModal,
          title: "Delete Activity",
          handleClose: () => {
            setShowDeleteModal(false);
            setDeletedData();
          },
          handleAccept: () => deleteLostReasonAccept(),
        }}
      >
        Are you sure you want to delete Activity ?
      </DeleteModal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div>
            <Button onClick={() => handleIcon("bi bi-clock-fill")} className="default" variant="outline-dark"><i className="bi bi-clock-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-people-fill")} className="default" variant="outline-dark"><i className="bi bi-people-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-flag-fill")} className="default" variant="outline-dark"><i className="bi bi-flag-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-telephone-fill")} className="default" variant="outline-dark"><i className="bi bi-telephone-fill"></i></Button>
            <Button onClick={() => handleIcon("fas fa-apple-alt")} className="default" variant="outline-dark"><i className="fas fa-apple-alt"></i></Button>
            <Button onClick={() => handleIcon("bi bi-calendar")} className="default" variant="outline-dark"><i className="bi bi-calendar"></i></Button>
            <Button onClick={() => handleIcon("bi bi-arrow-down-circle-fill")} className="default" variant="outline-dark"><i className="bi bi-arrow-down-circle-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-folder-fill")} className="default" variant="outline-dark"><i className="bi bi-folder-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-phone-fill")} className="default" variant="outline-dark"><i className="bi bi-phone-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-camera-fill")} className="default" variant="outline-dark"><i className="bi bi-camera-fill"></i></Button>
            <Button onClick={() => handleIcon("bi bi-scissors")} className="default" variant="outline-dark"><i className="bi bi-scissors"></i></Button>
            <Button onClick={() => handleIcon("bi bi-tools")} className="default" variant="outline-dark"><i className="bi bi-tools"></i></Button>
            <Button onClick={() => handleIcon("bi bi-chat-right-text-fill")} className="default" variant="outline-dark"><i className="bi bi-chat-right-text-fill"></i></Button>
          </div> */}
          <div>
            <Button onClick={() => handleIcon("bi bi-clock-fill")} className={`default ${iconName === "bi bi-clock-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-clock-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-people-fill")} className={`default ${iconName === "bi bi-people-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-people-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-flag-fill")} className={`default ${iconName === "bi bi-flag-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-flag-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-telephone-fill")} className={`default ${iconName === "bi bi-telephone-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-telephone-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("fas fa-apple-alt")} className={`default ${iconName === "bi bi-apple-alt" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="fas fa-apple-alt"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-calendar")} className={`default ${iconName === "bi bi-calendar" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-calendar"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-arrow-down-circle-fill")} className={`default ${iconName === "bi bi-arrow-down-circle-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-arrow-down-circle-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-folder-fill")} className={`default ${iconName === "bi bi-folder-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-folder-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-phone-fill")} className={`default ${iconName === "bi bi-phone-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-phone-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-camera-fill")} className={`default ${iconName === "bi bi-camera-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-camera-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-scissors")} className={`default ${iconName === "bi bi-scissors" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-scissors"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-tools")} className={`default ${iconName === "bi bi-tools" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-tools"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-chat-right-text-fill")} className={`default ${iconName === "bi bi-chat-right-text-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-chat-right-text-fill"></i>
            </Button>

            <Button onClick={() => handleIcon("bi bi-basket2-fill")} className={`default ${iconName === "bi bi-basket2-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-basket2-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-bookmark-star")} className={`default ${iconName === "bi bi-bookmark-star" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-bookmark-star"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-briefcase-fill")} className={`default ${iconName === "bi bi-briefcase-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-briefcase-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-bug")} className={`default ${iconName === "bi bi-bug" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-bug"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-cart-check-fill")} className={`default ${iconName === "bi bi-cart-check-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-cart-check-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-chat-square-text-fill")} className={`default ${iconName === "bi bi-chat-square-text-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-chat-square-text-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-controller")} className={`default ${iconName === "bi bi-controller" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-controller"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-envelope-fill")} className={`default ${iconName === "bi bi-envelope-fill" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-envelope-fill"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-gear")} className={`default ${iconName === "bi bi-gear" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-gear"></i>
            </Button>
            <Button onClick={() => handleIcon("bi bi-headphones")} className={`default ${iconName === "bi bi-headphones" ? "bg-4badfe" : ""}`} variant="outline-dark">
              <i className="bi bi-headphones"></i>
            </Button>
          </div>

          <br></br>

          <div className="col-lg-12 fv-row">
            <span className="fw-500 font-size-16px">Name*</span>
            <FormInput placeholder={"Enter activity name"} type={"text"} name={"activity"} value={Activity} onChange={(e) => handleChange(e)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-sm btn-primary my-1" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="saveCommponbutton btn btn-sm btn-primary my-1" onClick={() => handleLostReason()}>
            Edit Activity
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
