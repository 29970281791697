import { getCampaignPricing } from "../types/campaign_common_settings_type";
import { getSMSCampaignPricing } from "../types/campaign_sms_settings_types";

const initState = {
  email: {
    data: [],
    loading: false,
    error: false,
    count: 0,
  },
  sms: {
    data: [],
    loading: false,
    error: false,
    count: 0,
  },
};

export default function campaignPricingReducer(state = initState, action = {}) {
  switch (action.type) {
    case getCampaignPricing.GET_CAMPIAGN_PRICING_REQUEST:
      return {
        ...state,
        email: {
          ...state.email,
          loading: true,
        },
      };
    case getCampaignPricing.GET_CAMPIAGN_PRICING_SUCCESS:
      return {
        ...state,
        email: {
          ...state.email,
          loading: false,
          data: action.payload.data,
          count: action.payload.data.length,
        },
      };
    case getCampaignPricing.GET_CAMPIAGN_PRICING_ERROR:
      return {
        ...state,
        email: {
          ...state.email,
          loading: false,
          error: action.payload,
        },
      };
    case getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_REQUEST:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: true,
        },
      };
    case getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_SUCCESS:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: false,
          data: action.payload.data,
          count: action.payload.data.length,
        },
      };
    case getSMSCampaignPricing.GET_SMS_CAMPAIGN_PRICING_ERROR:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
