import { call, put, takeLatest } from "redux-saga/effects";
import { endpoints } from "../endpoints";
import * as cadenceSettingsActions from "../actions/cadence_settings_actions";
import * as cadenceSettingsTypes from "../types/cadence_settings_types";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import api from "../../../../../network/apis/api";

function* getCadenceSettings(action) {
  try {
    const res = yield call(api.get, endpoints.CADENCE_SETTINGS);
    if (res.status === 200) {
      yield put(cadenceSettingsActions.getCadenceSettingsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSettingsActions.getCadenceSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSettingsActions.getCadenceSettingsError(e));
  }
}

function* editCadenceSettings(action) {
  try {
    const res = yield call(api.patch, `${endpoints.CADENCE_SETTINGS}`, action.payload);
    if (res.status === 200) {
      yield put(cadenceSettingsActions.editCadenceSettingsSuccess(res.data));
      dispatchSnackbarSuccess(res.data.message);
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSettingsActions.editCadenceSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSettingsActions.editCadenceSettingsError(e));
  }
}

function* getCadenceAllUsersLimit(action) {
  try {
    const res = yield call(api.get, endpoints.CADENCE_ALL_USERS_LIMIT);
    if (res.status === 200) {
      yield put(cadenceSettingsActions.getCadenceAllUsersLimitSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(cadenceSettingsActions.getCadenceAllUsersLimitFailure(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(cadenceSettingsActions.getCadenceAllUsersLimitFailure(e));
  }
}

export default function* cadenceSettingsSaga() {
  yield takeLatest(cadenceSettingsTypes.getCadenceSettings.GET_CADENCE_SETTINGS_REQUEST, getCadenceSettings);
  yield takeLatest(cadenceSettingsTypes.editCadenceSettings.EDIT_CADENCE_SETTINGS_REQUEST, editCadenceSettings);
  yield takeLatest(cadenceSettingsTypes.getCadenceAllUsersLimit.REQUEST, getCadenceAllUsersLimit);
}
