import { ItemIndicator } from "./styled";
import React from "react";
import styled from "styled-components";

const Indicator = styled(ItemIndicator)`
  margin: 0 5px 0 0;
`;

const FullBackgrounded = styled.span`
  background-color: red;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 300px;
  color: white;
  font-weight: 450;
  font-size: 12px;
`;

export default (props) => {
  const findColor = () => {
    switch (props.value) {
      case "Critical":
      case "Awareness":
        return "red";
      case "High":
      case "Acquisition":
        return "yellow";
      case "Normal":
      case "Activation":
        return "blue";
      case "Revenue":
        return "pink";
      case "Retention":
        return "green";

      default:
        return "lightgray";
    }
  };

  if (props.isFullBackground) {
    return <FullBackgrounded color={findColor()}>{props.value}</FullBackgrounded>;
  }

  return <Indicator color={findColor()} />;
};
