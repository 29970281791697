import { getWebHook, getKey } from "../../types/admin-settings.types";

const initState = {
  getWebhook: {
    loading: false,
    list: [],
  },
  error: {
    err: false,
    errMsg: "",
  },
  getkey: {
    loading: false,
    list: [],
  },
};

export default function developerSettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case getWebHook.GET_WEB_HOOK_REQUEST:
      return {
        ...state,
        getWebhook: { ...state.getWebhook, loading: true },
      };
    case getWebHook.GET_WEB_HOOK_SUCCESS:
      return {
        ...state,
        getWebhook: { loading: false, list: action.payload.data },
      };
    case getWebHook.GET_WEB_HOOK_ERROR:
      return {
        ...state,
        getWebhook: { ...state.getWebhook, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    case getKey.GET_KEY_REQUEST:
      return {
        ...state,
        getkey: { ...state.getkey, loading: true },
      };
    case getKey.GET_KEY_SUCCESS:
      return {
        ...state,
        getkey: { loading: false, list: action.payload.data },
      };
    case getKey.GET_KEY_ERROR:
      return {
        ...state,
        getkey: { ...state.getkey, loading: false },
        error: { err: true, errMsg: action.payload.message },
      };

    default:
      return state;
  }
}
