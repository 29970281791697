export const GET_INTEGRATIONS_DETAILS_REQUEST = "GET_INTEGRATIONS_DETAILS_REQUEST";
export const GET_INTEGRATIONS_DETAILS_SUCCESS = "GET_INTEGRATIONS_DETAILS_SUCCESS";
export const GET_INTEGRATIONS_DETAILS_ERROR = "GET_INTEGRATIONS_DETAILS_ERROR";

export const DELETE_INTEGRATION_REQUEST = "DELETE_INTEGRATION_REQUEST";
export const DELETE_INTEGRATION_SUCCESS = "DELETE_INTEGRATION_SUCCESS";
export const DELETE_INTEGRATION_ERROR = "DELETE_INTEGRATION_ERROR";

export const GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST = "GET_INTEGRATION_ACCOUNT_DETAILS_REQUEST";
export const GET_INTEGRATION_ACCOUNT_DETAILS_SUCCESS = "GET_INTEGRATION_ACCOUNT_DETAILS_SUCCESS";
export const GET_INTEGRATION_ACCOUNT_DETAILS_ERROR = "GET_INTEGRATION_ACCOUNT_DETAILS_ERROR";

export const GET_INTEGRATION_AUTH_URL_REQUEST = "GET_INTEGRATION_AUTH_URL_REQUEST";
export const GET_INTEGRATION_AUTH_URL_SUCCESS = "GET_INTEGRATION_AUTH_URL_SUCCESS";
export const GET_INTEGRATION_AUTH_URL_ERROR = "GET_INTEGRATION_AUTH_URL_ERROR";

export const GET_INTEGRATION_CALLBACK_REQUEST = "GET_INTEGRATION_CALLBACK_REQUEST";
export const GET_INTEGRATION_CALLBACK_SUCCESS = "GET_INTEGRATION_CALLBACK_SUCCESS";
export const GET_INTEGRATION_CALLBACK_ERROR = "GET_INTEGRATION_CALLBACK_ERROR";
