import React from "react";
import { Button, Modal } from "react-bootstrap";
import DynamicNormalTable from "../../../../components/normal-table/dynamicNormalTable";

export default function TableViewModel({ showTableViewModel, handleTableViewClose }) {
  return (
    <Modal size={"lg"} show={showTableViewModel} onHide={handleTableViewClose}>
      <Modal.Body>
        <DynamicNormalTable tableTitle="Table Title" tableHeading={["Head 1", "Head 2", "Head 3", "Head 4", "Head 5"]} data={["", "", "", "", ""]} total={10} currentPage={10} itemsPerPage={10} onPageChange={() => {}} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-sm btn-primary my-1" variant="secondary" onClick={handleTableViewClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
