import { addConvertToJunk, getConvertToJunkById, editConvertToJunk } from "../types";

export const getConvertToJunkByIdRequest = (payload, cb) => ({
  type: getConvertToJunkById.GET_CONVERT_TO_JUNK_ACTION_BY_ID_REQUEST,
  payload,
  cb,
});
export const getConvertToJunkByIdSuccess = (payload) => ({
  type: getConvertToJunkById.GET_CONVERT_TO_JUNK_ACTION_BY_ID_SUCCESS,
  payload,
});
export const getConvertToJunkByIdError = (payload) => ({
  type: getConvertToJunkById.GET_CONVERT_TO_JUNK_ACTION_BY_ID_ERROR,
  payload,
});

export const editConvertToJunkRequest = (payload, cb) => ({
  type: editConvertToJunk.EDIT_CONVERT_TO_JUNK_ACTION_REQUEST,
  payload,
  cb,
});
export const editConvertToJunkSuccess = (payload) => ({
  type: editConvertToJunk.EDIT_CONVERT_TO_JUNK_ACTION_SUCCESS,
  payload,
});
export const editConvertToJunkError = (payload) => ({
  type: editConvertToJunk.EDIT_CONVERT_TO_JUNK_ACTION_ERROR,
  payload,
});

export const createConvertToJunkRequest = (payload, cb) => ({
  type: addConvertToJunk.ADD_CONVERT_TO_JUNK_ACTION_REQUEST,
  payload,
  cb,
});
export const createConvertToJunkSuccess = (payload) => ({
  type: addConvertToJunk.ADD_CONVERT_TO_JUNK_ACTION_SUCCESS,
  payload,
});
export const createConvertToJunkError = (payload) => ({
  type: addConvertToJunk.ADD_CONVERT_TO_JUNK_ACTION_ERROR,
  payload,
});
