import React, { useEffect } from "react";
import _ from "lodash";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as whatsappSettingsAction from "../../../../../store/actions/campaign_whatsapp_setting_actions";
import { dispatchSnackbarError } from "../../../../../../../utils/toaster";

export default function WhatsappAPIConfiguration(props) {
    const [businessAccountId, setBusinessAccountId] = React.useState("");
    const [phoneNumberId, setPhoneNumberId] = React.useState("");
    const [accessToken, setAccessToken] = React.useState("");

    const dispatch = useDispatch();

    const whatsappProfileStore = useSelector(state => state.campaign.settings.whatsapp.selected.apiData);

    useEffect(() => {
        if (!_.isEmpty(whatsappProfileStore)) {
            setBusinessAccountId(whatsappProfileStore.clientId);
            setPhoneNumberId(whatsappProfileStore.channelid);
            setAccessToken(whatsappProfileStore.apiKey);
        }
    }, [whatsappProfileStore])

    const handleSave = () => {
        if (!businessAccountId || !phoneNumberId || !accessToken) return dispatchSnackbarError("Please fill all the fields");
        const payload = {
            data: {
                "integrationId": props.integrationId,
                "clientId": businessAccountId,
                "channelId": phoneNumberId,
                "accessToken": accessToken
            },
            integrationId: props.integrationId
        }
        dispatch(whatsappSettingsAction.updateWhatsappAccountCredentialsRequest(payload))
    }
    return (
        <div className="p-8">
            <p>Enter your WhatsApp Cloud API details below. If you set this up earlier but are <b>not able to send or receive messages</b>, you might have accidentally missed some steps during setup. Click on the <b>Setup API again</b> button on right top to setup WhatsApp Cloud API again.</p>
            <Form.Group>
                <Form.Label>Whatsapp Business Account ID</Form.Label>
                <Form.Control type="text" value={businessAccountId} onChange={e => setBusinessAccountId(e.target.value)} maxLength={20} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Phone Number ID</Form.Label>
                <Form.Control type="text" value={phoneNumberId} onChange={e => setPhoneNumberId(e.target.value)} maxLength={20} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Access Token</Form.Label>
                <Form.Control as="textarea" rows={4} type="text" value={accessToken} onChange={e => setAccessToken(e.target.value)} maxLength={512} />
            </Form.Group>
            <Form.Group>
                <Button variant="primary" size="sm" onClick={handleSave}>Save</Button>
            </Form.Group>
        </div>
    )
}