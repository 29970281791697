import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { InputLimit } from "../../constants";

export default function CountrySelect({ field, value, setValue }) {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(value);
  const countryStore = useSelector((state) => state.Companies.countries);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Dropdown className="dropdown-inline mt-1 w-100">
      <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
        {!value || value === "" ? "Select " + field.fieldName : selected}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-xl w-100-mn">
        <div className="navi navi-hover">
          <li className="navi-item py-12px px-24px">
            <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Countries" value={search} onChange={(e) => setSearch(e.target.value)} />
          </li>
          <Dropdown.Divider className="m-0" />
          <PerfectScrollbar className="scroll h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
            {countryStore.list.map((country, index) => {
              return country.label && country.label.toLowerCase().includes(search.toLowerCase()) ? (
                <Dropdown.Item
                  className="dropdown-hover"
                  key={index}
                  onSelect={() => {
                    setValue(country.value, field.apiKeyName, country.code);
                  }}
                >
                  {country.label}
                </Dropdown.Item>
              ) : null;
            })}
          </PerfectScrollbar>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
