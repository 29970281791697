import React, { useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import CustomInput from "../../custom_form/inputs";
import { DateRangePicker } from "react-dates";
import SelectableContext from "react-bootstrap/SelectableContext";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import * as companyAction from "../../../containers/companies/store/actions/actions";
import * as dealActions from "../../../containers/deals/dealPipeline/actions";
import * as PeopleActions from "../../../containers/customers/store/actions/actions";
import * as leadActions from "../../../containers/leads/store/actions/actions";

export default function CustomFilterInput(props) {
  const [focusedInput, setFocusedInput] = useState("");
  const dispatch = useDispatch();

  const NoInputNeededOptions = [
    "",
    "is empty",
    "is not empty",
    "empty",
    "not empty",
    "true",
    "false",
    "today",
    "tomorrow",
    "yesterday",
    "last month",
    "this month",
    "next month",
    "last week",
    "this week",
    "next week",
    "isEmpty",
    "isNotEmpty",
    "isTrue",
    "isFalse",
    "isToday",
    "isTomorrow",
    "isYesterday",
    "isLastMonth",
    "isNextMonth",
    "isLastWeek",
    "isThisWeek",
    "isNextWeek",
    "hasChanged",
    "hasNotChanged",
  ];
  if (NoInputNeededOptions.includes(props.condition)) return <></>;
  else if (!props.field) return <></>;
  else if (["between", "not between", "isNotBetween", "isBetween"].includes(props.condition)) {
    const value = props.value;
    let fromDate = null,
      toDate = null;
    if (Array.isArray(value) && value.length === 2) {
      fromDate = value[0] ? moment(value[0]) : null;
      toDate = value[1] ? moment(value[1]) : null;
    }
    return (
      <DateRangePicker
        startDate={fromDate}
        startDateId="your_unique_start_date_id"
        endDate={toDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          const _startDate = startDate ? new Date(startDate).getTime() : null;
          const _endDate = endDate ? new Date(endDate).getTime() : null;
          props.setValue([_startDate, _endDate]);
        }}
        displayFormat={"YYYY-MM-DD"}
        small={true}
        isOutsideRange={() => false}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput);
        }}
      />
    );
  }

  const addTag = (name, colorCode) => {
    if (props.pageSub === "lead") {
      dispatch(leadActions.leadTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    } else if (props.pageSub === "people") {
      dispatch(PeopleActions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
    } else if (props.pageSub === "company") {
      dispatch(companyAction.companyTagsAddRequest({ name: name, colorCode: colorCode, type: "company" }));
    } else if (props.pageSub === "deal") {
      dispatch(dealActions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
    }
  };

  const editTag = (id, name, colorCode) => {
    if (props.pageSub === "lead") {
      dispatch(leadActions.leadTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (props.pageSub === "people") {
      dispatch(PeopleActions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (props.pageSub === "company") {
      dispatch(companyAction.companyTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
    } else if (props.pageSub === "deal") {
      dispatch(dealActions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode }));
    }
  };

  const deleteTag = (id) => {
    if (props.pageSub === "lead") {
      dispatch(leadActions.leadTagsDeleteRequest(id));
    } else if (props.pageSub === "people") {
      dispatch(PeopleActions.peopleTagsDeleteRequest(id));
    } else if (props.pageSub === "company") {
      dispatch(companyAction.companyTagsDeleteRequest(id));
    } else if (props.pageSub === "deal") {
      dispatch(dealActions.deleteDealTagRequest(id));
    }
  };

  if (props.page === "Leads" && props.field && props.field.apiKeyName === "source" && props.field.fieldGroup === "SYSTEM" && props.field.fieldType === "LEAD") {
    // const options = ["Manually created", "Leadbooster", "Import", "Website visitors", "Live chat", "Web forms", "Lead Bot", "Prospector", "Deal", "LinkedIn", "API"];
    const options = ["Manually created", "Leadbooster", "Import", "Website visitors", "Live chat", "Web forms", "Lead Bot", "Prospector", "Deal", "LinkedIn", "API", "Pabbly", "Zapier", "Meeting Scheduler"];
    return (
      <SelectableContext.Provider value={false}>
        <Dropdown className="mt-0 w-100">
          <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
            {props.value === "" ? "Select " + props.field.fieldName : props.value}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100-mn">
            <PerfectScrollbar className="scroll navi navi-hover h-200px-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {options.map((option, index) => (
                <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => props.setValue(option, props.field.apiKeyName)}>
                  {option}
                </Dropdown.Item>
              ))}
            </PerfectScrollbar>
          </Dropdown.Menu>
        </Dropdown>
      </SelectableContext.Provider>
    );
  }

  if (props.page === "Activity" && props.field && props.field.apiKeyName === "source" && props.field.fieldGroup === "SYSTEM" && props.field.fieldType === "ACTIVITY") {
    const options = ["Manually created", "Cadence", "Workflow", "API"];
    return (
      <SelectableContext.Provider value={false}>
        <Dropdown className="mt-0 w-100">
          <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
            {props.value === "" ? "Select " + props.field.fieldName : props.value}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100-mn">
            <PerfectScrollbar className="scroll navi navi-hover h-200px-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {options.map((option, index) => (
                <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => props.setValue(option, props.field.apiKeyName)}>
                  {option}
                </Dropdown.Item>
              ))}
            </PerfectScrollbar>
          </Dropdown.Menu>
        </Dropdown>
      </SelectableContext.Provider>
    );
  }

  return (
    <CustomInput
      selectedPipelineId={props.selectedPipelineId}
      dontShowAdd={true}
      clearable={false}
      page={props.page}
      pageSub={props.pageSub}
      field={props.field}
      value={props.value}
      setValue={props.setValue}
      allData={props.allData}
      hideAddText={false}
      addTag={addTag}
      editTag={editTag}
      deleteTag={deleteTag}
      isFilter={true}
    />
  );
}
