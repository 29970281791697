import { Factor2Auth, getProfile, resetPassword, changeEmail } from "../../types/profile-settings.types";

export function Factor2AuthRequest(req, cb) {
  return {
    type: Factor2Auth.FACTOR2_AUTH_REQUEST,
    payload: req,
    cb,
  };
}
export function Factor2AuthSucess(res) {
  return {
    type: Factor2Auth.FACTOR2_AUTH_SUCCESS,
    payload: res,
  };
}
export function Factor2AuthError(err) {
  return {
    type: Factor2Auth.FACTOR2_AUTH_ERROR,
    payload: err,
  };
}

export function getProfileRequest(cb) {
  return {
    type: getProfile.GET_PROFILE_REQUEST,
    cb,
  };
}
export function getProfileSuccess(res) {
  return {
    type: getProfile.GET_PROFILE_SUCCESS,
    payload: res,
  };
}
export function getProfileError(err) {
  return {
    type: getProfile.GET_PROFILE_ERROR,
    payload: err,
  };
}

export function passwordResetRequest(req, paramId, cb) {
  return {
    type: resetPassword.RESET_PASSWORD_REQUEST,
    payload: req,
    paramId,
    cb,
  };
}
export function passwordResetSuccess(res) {
  return {
    type: resetPassword.RESET_PASSWORD_SUCCESS,
    payload: res,
  };
}
export function passwordResetError(err) {
  return {
    type: resetPassword.RESET_PASSWORD_ERROR,
    payload: err,
  };
}

export function changeEmailRequest(req, cb) {
  return {
    type: changeEmail.CHANGE_EMAIL_REQUEST,
    payload: req,
    cb,
  };
}
export function changeEmailSuccess(res) {
  return {
    type: changeEmail.CHANGE_EMAIL_SUCCESS,
    payload: res,
  };
}
export function changeEmailError(err) {
  return {
    type: changeEmail.CHANGE_EMAIL_ERROR,
    payload: err,
  };
}
