import React from "react";
import { Pagination } from "react-bootstrap";

//**(pageNo, setPageNo, Math.ceil(count / limit), () => { }, limit, (e) => { setLimit(e); setPageNo(1); }) */
/**
 * activePage - PageNo
 * setActivePage - setPageNo
 * pages - total Pages based on limit
 * setChecked  - () => { },
 * limit - limit
 * setLimit- setLimit
 */
export default function CustomPagination(activePage, setActivePage, pages, setChecked, limit, setLimit) {
  var items = [];
  if (pages <= 7) {
    for (let number = 1; number <= pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => {
            setActivePage(number);
            setChecked([]);
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
  } else {
    items.push(
      <Pagination.First
        key={0}
        onClick={() => {
          setActivePage(1);
          setChecked([]);
        }}
      />,
    );
    items.push(
      <Pagination.Prev
        onClick={() => {
          if (activePage !== 1) setActivePage(activePage - 1);
        }}
      />,
    );
    if (activePage === 1 || activePage === 2) {
      items.push(
        <Pagination.Item
          key={1}
          active={activePage === 1}
          onClick={() => {
            setActivePage(1);
            setChecked([]);
          }}
        >
          1
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={2}
          active={activePage === 2}
          onClick={() => {
            setActivePage(2);
            setChecked([]);
          }}
        >
          2
        </Pagination.Item>,
      );
      // items.push(<Pagination.Item key={3} active={false} onClick={() => { setActive(3); setChecked([]) }}>3</Pagination.Item>)
      // items.push(<Pagination.Item key={4} active={false} onClick={() => { setActive(4); setChecked([]) }}>4</Pagination.Item>)
    } else {
      // items.push(<Pagination.Ellipsis />)
      if (activePage === pages) {
        // items.push(<Pagination.Item key={active - 3} active={false} onClick={() => { setActive(active - 3); setChecked([]) }}>{active - 3}</Pagination.Item>)
        items.push(
          <Pagination.Item
            key={activePage - 2}
            active={false}
            onClick={() => {
              setActivePage(activePage - 2);
              setChecked([]);
            }}
          >
            {activePage - 2}
          </Pagination.Item>,
        );
      } else if (activePage === pages - 1)
        items.push(
          <Pagination.Item
            key={activePage - 2}
            active={false}
            onClick={() => {
              setActivePage(activePage - 2);
              setChecked([]);
            }}
          >
            {activePage - 2}
          </Pagination.Item>,
        );
      items.push(
        <Pagination.Item
          key={activePage - 1}
          active={false}
          onClick={() => {
            setActivePage(activePage - 1);
            setChecked([]);
          }}
        >
          {activePage - 1}
        </Pagination.Item>,
      );
      items.push(
        <Pagination.Item
          key={activePage}
          active={true}
          onClick={() => {
            setActivePage(activePage);
            setChecked([]);
          }}
        >
          {activePage}
        </Pagination.Item>,
      );
      if (activePage !== pages) {
        items.push(
          <Pagination.Item
            key={activePage + 1}
            active={false}
            onClick={() => {
              setActivePage(activePage + 1);
              setChecked([]);
            }}
          >
            {activePage + 1}
          </Pagination.Item>,
        );
      }
    }
    if (activePage !== pages - 1 && activePage !== pages)
      // items.push(<Pagination.Ellipsis />)
      items.push(
        <Pagination.Next
          onClick={() => {
            if (activePage !== pages) setActivePage(activePage + 1);
          }}
        />,
      );
    items.push(
      <Pagination.Last
        key={pages + 1}
        onClick={() => {
          setActivePage(pages);
          setChecked([]);
        }}
      />,
    );
  }
  return (
    <div className="d-flex flex-row align-items-center gap-15px mt-5px">
      {pages === 1 ? null : (
        <ul id="table_pagination" className="border-radius-7px d-flex flex-row m-0 pl-0 z-index-0 list-unstyled">
          {items}
        </ul>
      )}
    </div>
  );
}
