export const defaultCustomisation = {
  answer: "#000000",
  background: "#EFF0F8",
  backgroundImage: "",
  button: "#00acff",
  introBubble: "Hey 👋, How can I help you? ",
  isBrandingEnabled: true,
  logo: "https://example.com/logo.svg",
  question: "#121212",
};
export const defaultWelcomeScreen = {
  isEnabled: true,
  mediaUrl: "",
  descrption:
    '<h2 style="text-align: center;"><strong>Discover how we can help</strong></h2>\n<p style="text-align: center;">&nbsp;</p>\n<h3 style="text-align: center;"><span style="font-weight: 400;">Take your sales to the next level with industry-leading, intuitive tools. We&rsquo;ve made sales fun&mdash;not just for the salesperson, but for everyone.</span></h3>\n<p style="text-align: center;">&nbsp;</p>',
  buttonText: "Tell me more",
};

export const defaultThankYouScreen = {
  isEnabled: true,
  mediaUrl: "",
  descrption: '<h3 style="text-align: center;">Thank you for contacting us! We will be in touch as soon as we can.</h3>',
};
export const defaultQuestions = [];

export const defaultOneQuestion = {
  question: "",
  fieldId: "",
  isMandatory: false,
  isHidden: false,
  defaultAnswer: "",
};
