import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../utils/toaster";
import * as emailSettingsTypes from "../types/campaign_email_settings_types";
import api from "../../../../network/apis/api";
import { endpoints } from "../endpoints";
import * as emailSettingsActions from "../actions/campaign_email_settings_actions";

function* updateEmailProfileSettings(action) {
  try {
    const res = yield call(api.patch, endpoints.UPDATE_EMAIL_PROFILE_SETTINGS, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message || res.data.error || "Successfully updated profile settings!");
      yield put(emailSettingsActions.getEmailProfileDetailsRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.updateEmailProfileSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.updateEmailProfileSettingsError(e));
  }
}

function* getEmailAccountReputations(action) {
  try {
    const res = yield call(api.get, endpoints.GET_EMAIL_ACCOUNT_REPUTATIONS, action.payload);
    if (res.status === 200) {
      if (res.data.status) {
        yield put(emailSettingsActions.getEmailAccountReputationsSuccess(res.data));
      } else {
        yield put(emailSettingsActions.getEmailAccountReputationsError(res.data));
      }
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.getEmailAccountReputationsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.getEmailAccountReputationsError(e));
  }
}

function* getEmailAccountStatus(action) {
  try {
    const res = yield call(api.get, endpoints.GET_EMAIL_ACCOUNT_STATUS, action.payload);
    if (res.status === 200) {
      yield put(emailSettingsActions.getEmailAccountStatusSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.getEmailAccountStatusError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.getEmailAccountStatusError(e));
  }
}
function* getEmailProfileDetails(action) {
  try {
    const res = yield call(api.get, endpoints.GET_EMAIL_PROFILE_DETAILS, action.payload);
    if (res.status === 200) {
      yield put(emailSettingsActions.getEmailProfileDetailsSuccess(res.data));
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.getEmailProfileDetailsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.getEmailProfileDetailsError(e));
  }
}

function* getListOfEmailDomainSettings(action) {
  try {
    const res = yield call(api.get, endpoints.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS, action.payload);
    if (res.status === 200) {
      yield put(emailSettingsActions.getListOfEmailDomainSettingsSuccess(res.data));
    } else {
      // dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.getListOfEmailDomainSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.getListOfEmailDomainSettingsError(e));
  }
}

function* addDomainSettings(action) {
  try {
    const res = yield call(api.post, endpoints.ADD_DOMAIN_SETTINGS, action.payload);
    if (res.status === 200) {
      if (res.data.status) {
        dispatchSnackbarSuccess(res.data.message || res.data.error || "Successfully added domain!");
        yield put(emailSettingsActions.addDomainSettingsSuccess(res.data));
        yield put(emailSettingsActions.getListOfEmailDomainSettingsRequest(action.payload.loadingData));
      } else {
        dispatchSnackbarError(res.data.error || res.data.message);
        yield put(emailSettingsActions.addDomainSettingsError(res.data));
        yield put(emailSettingsActions.getListOfEmailDomainSettingsRequest(action.payload.loadingData));
      }
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.addDomainSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.addDomainSettingsError(e));
  }
}

function* deleteEmailDomainSettings(action) {
  try {
    const res = yield call(api.delete, endpoints.DELETE_EMAIL_DOMAIN_SETTINGS, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.message || res.data.error || "Successfully deleted domain!");
      yield put(emailSettingsActions.deleteEmailDomainSettingsSuccess(res.data));
      yield put(emailSettingsActions.getListOfEmailDomainSettingsRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.deleteEmailDomainSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.deleteEmailDomainSettingsError(e));
  }
}

function* addEmailToDomainSettings(action) {
  try {
    const res = yield call(api.patch, endpoints.ADD_EMAIL_TO_DOMAIN_SETTINGS, action.payload);
    if (res.status === 200) {
      dispatchSnackbarSuccess(res.data.error || "Successfully added domain email!");
      yield put(emailSettingsActions.addEmailToDomainSettingsSuccess(res.data));
      yield put(emailSettingsActions.getListOfEmailDomainSettingsRequest());
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.addEmailToDomainSettingsError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.addEmailToDomainSettingsError(e));
  }
}

function* verifyDKIM(action) {
  try {
    const res = yield call(api.patch, endpoints.VERIFY_DKIM, action.payload);
    if (res.status === 200) {
      if (res.data.status) {
        yield put(emailSettingsActions.verifyDKIMSuccess(res.data));
      } else {
        dispatchSnackbarError(res.data.message);
        yield put(emailSettingsActions.verifyDKIMError(res.data));
      }
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.verifyDKIMError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.verifyDKIMError(e));
  }
}

function* verifyDMARC(action) {
  try {
    const res = yield call(api.patch, endpoints.VERIFY_DMARC, action.payload);
    if (res.status === 200) {
      if (res.data.status) {
        yield put(emailSettingsActions.verifyDMARCSuccess(res.data));
      } else {
        dispatchSnackbarError(res.data.message);
        yield put(emailSettingsActions.verifyDMARCError(res.data));
      }
    } else if (res.status === 201) {
      dispatchSnackbarSuccess("Account Verified", "success");
    } else if (res.status === 400) {
      dispatchSnackbarError("Invalid Account", "error");
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.verifyDMARCError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.verifyDMARCError(e));
  }
}

function* verifyTracking(action) {
  try {
    const res = yield call(api.patch, endpoints.VERIFY_TRACKING, action.payload);
    if (res.status === 200) {
      if (res.data.status) {
        yield put(emailSettingsActions.verifyTrackingSuccess(res.data));
      } else {
        dispatchSnackbarError(res.data.message);
        yield put(emailSettingsActions.verifyTrackingError(res.data));
      }
    } else if (res.status === 201) {
      dispatchSnackbarSuccess("Account Verified", "success");
    } else if (res.status === 400) {
      dispatchSnackbarError("Invalid Account", "error");
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.verifyTrackingError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.verifyTrackingError(e));
  }
}

function* verifySPF(action) {
  try {
    const res = yield call(api.patch, endpoints.VERIFY_SPF, action.payload);
    if (res.status === 200) {
      if (res.data.status) {
        yield put(emailSettingsActions.verifySPFSuccess(res.data));
      } else {
        dispatchSnackbarError(res.data.message);
        yield put(emailSettingsActions.verifySPFError(res.data));
      }
    } else if (res.status === 201) {
      dispatchSnackbarSuccess("Account Verified", "success");
    } else if (res.status === 400) {
      dispatchSnackbarError("Invalid Account", "error");
    } else {
      dispatchSnackbarError(res.data.message);
      yield put(emailSettingsActions.verifySPFError(res.data));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(emailSettingsActions.verifySPFError(e));
  }
}

export default function* campaignEmailSettingsSaga() {
  yield takeLatest(emailSettingsTypes.getEmailProfileDetails.GET_EMAIL_PROFILE_DETAILS_REQUEST, getEmailProfileDetails);
  yield takeLatest(emailSettingsTypes.updateEmailProfileSettings.UPDATE_EMAIL_PROFILE_SETTINGS_REQUEST, updateEmailProfileSettings);
  yield takeLatest(emailSettingsTypes.getEmailAccountReputations.GET_EMAIL_ACCOUNT_REPUTATIONS_REQUEST, getEmailAccountReputations);
  yield takeLatest(emailSettingsTypes.getEmailAccountStatus.GET_EMAIL_ACCOUNT_STATUS_REQUEST, getEmailAccountStatus);
  yield takeLatest(emailSettingsTypes.getListOfEmailDomainSettings.GET_LIST_OF_EMAIL_DOMAIN_SETTINGS_REQUEST, getListOfEmailDomainSettings);
  yield takeLatest(emailSettingsTypes.addDomainSettings.ADD_DOMAIN_SETTINGS_REQUEST, addDomainSettings);
  yield takeLatest(emailSettingsTypes.deleteEmailDomainSettings.DELETE_EMAIL_DOMAIN_SETTINGS_REQUEST, deleteEmailDomainSettings);
  yield takeLatest(emailSettingsTypes.addEmailToDomainSettings.ADD_EMAIL_TO_DOMAIN_SETTINGS_REQUEST, addEmailToDomainSettings);
  yield takeLatest(emailSettingsTypes.verifyDKIM.VERIFY_DKIM_REQUEST, verifyDKIM);
  yield takeLatest(emailSettingsTypes.verifyDMARC.VERIFY_DMARC_REQUEST, verifyDMARC);
  yield takeLatest(emailSettingsTypes.verifyTracking.VERIFY_TRACKING_REQUEST, verifyTracking);
  yield takeLatest(emailSettingsTypes.verifySPF.VERIFY_SPF_REQUEST, verifySPF);
}
