import { getVariableSupportedData } from "../types/types";

const initialState = {
    variableSupported: {
        loading: false,
        error: null,
        data: {},
        moduleId: null,
    },
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_REQUEST:
            return {
                ...state,
                variableSupported: {
                    ...state.variableSupported,
                    loading: true,
                    error: null,
                    moduleId: action.payload.moduleId,
                },
            };
        case getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_SUCCESS:
            return {
                ...state,
                variableSupported: {
                    ...state.variableSupported,
                    loading: false,
                    error: null,
                    data: action.payload.data,
                },
            };
        case getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_ERROR:
            return {
                ...state,
                variableSupported: {
                    ...state.variableSupported,
                    loading: false,
                    data: {},
                    moduleId: null,
                    error: action.payload,
                },
            };
        default:
            return state;

    }
}