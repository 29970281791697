import React, { useState, useEffect } from "react";
import { InputLimit } from "../../../../../constants";
import { Dropdown, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { searchAllAssociationsRequest } from "../../../../../containers/Activities/store/actions/activity_operations_actions";
import { useHistory } from "react-router-dom";

const CustomItemList = ({ key, title, icon, id, handleItemClick, _module, item }) => {
  return (
    <div key={key} className="d-flex flex-stack flex-grow-1 mb-2">
      <div className="d-flex align-items-center flex-grow-1 mb-2">
        <div className="symbol symbol-45 symbol-circle flex-shrink-0">
          <div className="symbol-label">
            <i className={`${icon} text-primary`}></i>
          </div>
        </div>
        <div className="d-flex flex-column ml-3 mt-2 mb-2">
          <div onClick={() => handleItemClick(id)} className={`font-weight-bold text-dark text-hover-primary cursor-pointer ${item.lock ? "pointer-events-none" : ""}`}>
            {title}
            {item.state && item.state === "junk_lead" && <span className="badge badge-light-danger ml-2">Junk Lead</span>}
          </div>
          <span className="font-size-sm font-weight-bold text-muted">
            {["lead", "people"].includes(_module) && item.company && item.company.name && <span className="text-00a6bc">{item.company.name} · </span>}
            {<span> by {item.owner ? <span>{item.owner && item.owner.name ? item.owner.name : item.owner.email}</span> : <span>Deleted User</span>}</span>}
          </span>
        </div>
      </div>
      {item.lock && (
        <div className="mr-25px">
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">You don't have permissions to access this information, please reach out to the admin for more details.</Tooltip>}>
            <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <i className="bi bi-lock-fill"></i>
              </span>
            </span>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
};

export function HeaderCenterMenu(props) {
  const [type, setType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const headMenuOptions = ["All", "Deal", "Lead", "People", "Company"];

  const dispatch = useDispatch();
  const history = useHistory();

  const assocaistionStore = useSelector((store) => store.activity.search.association);

  useEffect(() => {
    dispatch(searchAllAssociationsRequest({ searchText: searchText, module: type.toLowerCase() }));
  }, [dispatch, type, searchText]);

  const getTitle = (items, type) => {
    if (type === "deal") return items.title;
    if (type === "product") return items.productName;
    if (type === "lead" || type === "people") {
      if (items.name) return items.name;
      else if (items.email && Array.isArray(items.email) && items.email.length > 0) return items.email.join(", ");
      else if (items.phone && Array.isArray(items.phone) && items.phone.length > 0) return items.phone.join(", ");
      else return "NA";
    } else return items.name;
  };

  const onSearchItemClick = (type, data) => {
    setSearchText("");
    if (type === "product") {
      history.push("/products");
    } else if (type === "company") {
      history.push({ pathname: `/prospects/companies/${data._id}`, state: { id: data._id } });
    } else if (type === "deal") {
      history.push({ pathname: `/deal/pipeline/${data.pipelineId}/${data._id}`, state: { id: data._id } });
    } else if (type === "lead" && data.state === "active_lead") {
      history.push({ pathname: `/prospects/leads/active/${data._id}`, state: { id: data._id } });
    } else if (type === "people") {
      history.push({ pathname: `/prospects/people/${data._id}`, state: { id: data._id } });
    } else if (type === "lead" && data.state === "junk_lead") {
      history.push({ pathname: `/prospects/leads/junk/${data._id}`, state: { id: data._id } });
    } else {
      history.push("/activity/activity-list");
    }
  };

  const isNoDataPresent = () => {
    if (assocaistionStore.data["deal"].length > 0) {
      return false;
    }
    if (assocaistionStore.data["people"].length > 0) {
      return false;
    }
    if (assocaistionStore.data["lead"].length > 0) {
      return false;
    }
    if (assocaistionStore.data["company"].length > 0) {
      return false;
    }
    // if (assocaistionStore.data["product"] && assocaistionStore.data["product"].length > 0) {
    //     return false;
    // }
    return true;
  };

  return (
    <div className="header-center-menu-wrapper">
      <div className="border-width-1px border-style-solid border-color-eeeeee border-radius-50px d-flex position-relative justify-content-start w-493px">
        <div className={`input-group-prepend`}>
          <span className="input-group-wrapper first-icon">
            <span className="svg-icon svg-icon-lg">
              <i className="bi bi-search"></i>
            </span>
          </span>
        </div>
        <input
          autoFocus={true}
          className="custom-input"
          maxLength={InputLimit.TEXT}
          type="text"
          value={searchText}
          placeholder="Search..."
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <div className={`input-group-append`}>
          <span className="input-group-wrapper second-icon cursor-pointer">
            {searchText ? (
              <i
                onClick={() => {
                  setSearchText("");
                }}
                className="quick-search-close ki ki-close icon-sm text-muted"
              />
            ) : (
              <span className="w-10px"></span>
            )}
          </span>
        </div>
        {searchText && (
          <div className="card header-result-list-wrapper p-0 m-0 shadow">
            <div className="d-flex flex-column gap-2 align-content-center justify-content-center p-4">
              <div className="d-flex overflow-auto position-relative">
                {headMenuOptions.map((each, index) => {
                  return (
                    <span key={index} className={`btn btn-light-primary rounded-pill ${type === each ? "active" : ""} me-1 py-2 px-4`} onClick={() => setType(each)}>
                      {each}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="separator border-gray-200 my-0"></div>
            {isNoDataPresent() ? (
              <div data-kt-search-element="empty" className="text-center">
                <div className="pt-10 pb-10">
                  <span className="svg-icon svg-icon-4x opacity-50">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                      <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                      <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                      <path
                        d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="pb-15 fw-bold">
                  <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
                  <div className="text-muted fs-7">Please try again with a different query</div>
                </div>
              </div>
            ) : (
              <div data-kt-search-element="results" className="px-4">
                {assocaistionStore.loading ? (
                  <div className="w-100 scroll-y pt-10 pb-10 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <div className="scroll-y mh-200px mh-lg-300px my-2 me-n2 pe-2">
                    {assocaistionStore.data["deal"] && assocaistionStore.data["deal"].length > 0 && (
                      <>
                        <Dropdown.Header>Deals</Dropdown.Header>
                        {assocaistionStore.data["deal"]
                          // .filter((items) => !items.lock)
                          .map((eachList, ind) => (
                            <CustomItemList
                              key={ind}
                              item={eachList}
                              _module={"deal"}
                              handleItemClick={(id) => onSearchItemClick("deal", eachList)}
                              title={getTitle(eachList, "deal")}
                              icon={"bi bi-coin"}
                              id={eachList._id}
                            />
                          ))}
                      </>
                    )}
                    {assocaistionStore.data["lead"] && assocaistionStore.data["lead"].length > 0 && (
                      <>
                        <Dropdown.Header>Leads</Dropdown.Header>
                        {assocaistionStore.data["lead"]
                          // .filter((items) => !items.lock)
                          .map((eachList, ind) => (
                            <CustomItemList
                              key={ind}
                              item={eachList}
                              _module={"lead"}
                              handleItemClick={(id) => onSearchItemClick("lead", eachList)}
                              title={getTitle(eachList, "lead")}
                              icon={"bi bi-bullseye"}
                              id={eachList._id}
                            />
                          ))}
                      </>
                    )}
                    {assocaistionStore.data["people"] && assocaistionStore.data["people"].length > 0 && (
                      <>
                        <Dropdown.Header>Peoples</Dropdown.Header>
                        {assocaistionStore.data["people"]
                          // .filter((items) => !items.lock)
                          .map((eachList, ind) => (
                            <CustomItemList
                              key={ind}
                              item={eachList}
                              _module={"people"}
                              handleItemClick={(id) => onSearchItemClick("people", eachList)}
                              title={getTitle(eachList, "people")}
                              icon={"bi bi-people"}
                              id={eachList._id}
                            />
                          ))}
                      </>
                    )}
                    {assocaistionStore.data["company"] && assocaistionStore.data["company"].length > 0 && (
                      <>
                        <Dropdown.Header>Companies</Dropdown.Header>
                        {assocaistionStore.data["company"]
                          // .filter((items) => !items.lock)
                          .map((eachList, ind) => (
                            <CustomItemList
                              key={ind}
                              item={eachList}
                              _module={"company"}
                              handleItemClick={(id) => onSearchItemClick("company", eachList)}
                              title={getTitle(eachList, "company")}
                              icon={"bi bi-building"}
                              id={eachList._id}
                            />
                          ))}
                      </>
                    )}
                    {/* {assocaistionStore.data["product"] && assocaistionStore.data["product"].filter(items => !items.lock).length > 0 && <>
                                    <Dropdown.Header>Companies</Dropdown.Header>
                                    {assocaistionStore.data["product"].filter(items => !items.lock).map((eachList, ind) => (
                                        <CustomItemList key={ind} item={eachList} _module={"product"} handleItemClick={(id) => onSearchItemClick("product", eachList)} title={getTitle(eachList, "product")} icon={"bi bi-basket"} id={eachList._id} />
                                    ))}
                                </>} */}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
