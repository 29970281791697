import React from "react";
import { Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import TagSelect from "./tags";
import CategorySelect from "./categories";
import Pricing from "./pricing";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomEmail from "./email";
import CustomPhone from "./phone";
import OwnerSelect from "./owners";
import CurrencySelect from "./currency";
import CompanySelect, { CompanySelectDropdown } from "./organization";
import PersonSelect, { PersonSelectDropdown } from "./person";
import CountrySelect from "./countries";
import TimeZoneSelect from "./timezone";
import DateSelect, { DateTimeSelect } from "./date";
import DateRangeSelect, { DateTimeRangeSelect } from "./dateRange";
import RadioGroupSelect from "./radio";
import CheckBoxGroupSelect from "./checkBox";
import CustomPerson from "./customPerson";
import SelectableContext from "react-bootstrap/SelectableContext";
import PipelineSelect, { PipelineStageSelect } from "./pipeline";
import ActivityLostReasonSelect from "./lostReasons";
import ActivityTypeSelect from "./activityType";
import { LeadSelectDropdown } from "./lead";
import { DealSelectDropdown } from "./deal";
import { InputLimit, LimitInputNumber } from "../../constants";
import _ from "lodash";
import Association from "./association";
import MeetingDateSelect from "./meetingDateTime";
import MultiOptOut, { MultiOptOutReason } from "./multiOptOut";
import AIWizardField from "./ai-wizard-field";

/**
 *  [
      "OWNER_SELECT", "PRICING_INPUT", "TAG_MULTI_SELECT", "CATEGORY_SELECT", "INPUT",
      "TEXTAREA", "CHECKBOX", "SELECT", "RADIO", "TOGGLE_BUTTON", "NUMBER", "DATE",
      "DATE_RANGE", "ORG_SELECT", "CURRENCY_SELECT", "COUNTRY_SELECT", "MULTI_EMAIL_INPUT", "MULTI_PHONE_INPUT",
      "TIMEZONE_SELECT", "PERSON_SELECT", 
      
      "PIPELINE_STAGE_SELECT", "PIPELINE_SELECT", "MULTI_OWNER_SELECT", 
      "DEAL_PRODUCT_INPUT", "ACTIVITY_TYPE_SELECT", "ACTIVITY_LOST_REASON_SELECT", "ACTIVITY_SHOW"
    ],
            
 */
export default function CustomInput(props) {
  const getInputField = (type, required, options = []) => {
    switch (type) {
      case "INPUT":
        return <Form.Control type={"text"} maxLength={InputLimit.TEXT} value={props.value} onChange={(e) => props.setValue(e.target.value, props.field.apiKeyName, props.field)}></Form.Control>;
      case "NUMBER":
        return <Form.Control type="number" value={props.value} onChange={(e) => props.setValue(LimitInputNumber(e.target.value), props.field.apiKeyName)}></Form.Control>;
      case "TEXTAREA":
        return <Form.Control as="textarea" maxLength={InputLimit.TEXTAREA} rows={3} value={props.value} onChange={(e) => props.setValue(e.target.value, props.field.apiKeyName)}></Form.Control>;
      case "SELECT":
        return (
          <SelectableContext.Provider value={false}>
            <Dropdown className="mt-0 w-100">
              <Dropdown.Toggle className="w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
                {props.value === "" ? "Select " + props.field.fieldName : props.value}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100-mn">
                <PerfectScrollbar className="scroll navi navi-hover h-200px-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
                  {options.map((option, index) => (
                    <Dropdown.Item className="dropdown-hover" key={index} onClick={(e) => e.stopPropagation()} onSelect={() => props.setValue(option, props.field.apiKeyName)}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </PerfectScrollbar>
              </Dropdown.Menu>
            </Dropdown>
          </SelectableContext.Provider>
        );
      case "OWNER_SELECT":
        return <OwnerSelect clearable={props.clearable} field={props.field} value={props.value} setValue={props.setValue} />;
      case "CURRENCY_SELECT":
        return <CurrencySelect field={props.field} value={props.value} setValue={props.setValue} />;
      case "COUNTRY_SELECT":
        return <CountrySelect field={props.field} value={props.value} setValue={props.setValue} />;
      case "TIMEZONE_SELECT":
        return <TimeZoneSelect clearable={props.clearable} field={props.field} value={props.value} setValue={props.setValue} />;
      case "TOGGLE_BUTTON":
        return (
          <div className="form-check form-switch form-check-custom form-check-solid my-4">
            <input
              className="form-check-input h-20px w-35px"
              type="checkbox"
              checked={typeof props.value !== "undefined" ? props.value : false}
              onChange={(e) => props.setValue(e.target.checked, props.field.apiKeyName)}
              id="flexSwitch20x30"
            />
          </div>
        );

      case "TAG_MULTI_SELECT":
        return (
          <TagSelect
            hideAddText={props.hideAddText}
            page={props.page}
            pageSub={props.pageSub}
            tags={props.tags}
            getTags={props.getTags}
            addTag={props.addTag}
            editTag={props.editTag}
            deleteTag={props.deleteTag}
            field={props.field}
            value={typeof props.value === "string" || !props.value || props.value === "" || props.value.length === 0 ? [""] : props.value.map((tag) => (tag["_id"] ? tag["_id"] : tag))}
            setValue={props.setValue}
          />
        );
      case "CATEGORY_SELECT":
        return (
          <CategorySelect
            categories={props.categories}
            getCategories={props.getCategories}
            addCategory={props.addCategory}
            editCategory={props.editCategory}
            deleteCategory={props.deleteCategory}
            field={props.field}
            value={props.value}
            setValue={props.setValue}
          />
        );
      case "ORG_SELECT":
        return (
          <CompanySelect
            dontShowAdd={props.dontShowAdd}
            cacheCompany={props.cacheCompany}
            field={props.field}
            value={_.isObject(props.value) ? (props.value["_id"] ? props.value["_id"] : props.value) : props.value}
            valueLabel={_.isObject(props.value) && props.value["name"] ? props.value["name"] : ""}
            setValue={props.setValue}
          />
        );
      case "PERSON_SELECT":
        if (props.isCustomPersonSelect) return <CustomPerson customFormData={props.customFormData} setCustomFormData={props.setCustomFormData} field={props.field} value={props.value} setValue={props.setValue} />;
        else return <PersonSelect dontShowAdd={props.dontShowAdd} customFormData={props.customFormData} setCustomFormData={props.setCustomFormData} field={props.field} value={props.value} setValue={props.setValue} />;
      case "PRICING_INPUT":
        return <Pricing value={!props.value || props.value === "" || props.value.length === 0 ? [{ unitPrice: "", currency: "" }] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "MULTI_PHONE_INPUT":
        return <CustomPhone value={_.isEmpty(props.value) ? [""] : typeof props.value === "string" ? [props.value] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "MULTI_EMAIL_INPUT":
        return <CustomEmail value={_.isEmpty(props.value) ? [""] : typeof props.value === "string" ? [props.value] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "DATE":
        return <DateSelect isFilter={props.isFilter} value={!props.value || props.value === "" || props.value.length === 0 ? [""] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "DATE_TIME":
        return <DateTimeSelect isFilter={props.isFilter} value={!props.value || props.value === "" || props.value.length === 0 ? [""] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "DATE_RANGE":
        return <DateRangeSelect isFilter={props.isFilter} value={!props.value || props.value === "" || props.value.length === 0 ? [""] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "DATE_TIME_RANGE":
        return <DateTimeRangeSelect isFilter={props.isFilter} value={!props.value || props.value === "" || props.value.length === 0 ? [""] : props.value} apiKeyName={props.field.apiKeyName} setValue={props.setValue} />;
      case "CHECKBOX":
        return (
          <CheckBoxGroupSelect value={!props.value || props.value === "" || props.value.length === 0 ? [] : props.value} apiKeyName={props.field.apiKeyName} options={props.field.options} setValue={props.setValue} />
        );
      case "RADIO":
        return <RadioGroupSelect value={!props.value || props.value === "" || props.value.length === 0 ? "" : props.value} apiKeyName={props.field.apiKeyName} options={props.field.options} setValue={props.setValue} />;
      case "ACTIVITY_SHOW":
        return <></>;
      case "PIPELINE_SELECT":
        return <PipelineSelect field={props.field} value={props.value} setValue={props.setValue} />;
      case "PIPELINE_STAGE_SELECT":
        return <PipelineStageSelect field={props.field} value={props.value} pipelineId={props.selectedPipelineId} allData={props.allData} setValue={props.setValue} />;
      case "MULTI_OWNER_SELECT":
        return <>Coming Soon</>;
      case "DEAL_PRODUCT_INPUT":
        return (
          <>
            Update Form Deal Popup
            {/* <DealProductEditor field={props.field} value={props.value} setValue={props.setValue} /> */}
          </>
        );
      case "ACTIVITY_TYPE_SELECT":
        return (
          <>
            <ActivityTypeSelect field={props.field} value={props.value} setValue={props.setValue} />
          </>
        );
      case "ACTIVITY_LOST_REASON_SELECT":
        return (
          <>
            <ActivityLostReasonSelect field={props.field} value={props.value} setValue={props.setValue} type="ACTIVITY" />
          </>
        );
      case "DEAL_LOST_REASON_SELECT":
        return (
          <>
            <ActivityLostReasonSelect field={props.field} value={props.value} setValue={props.setValue} type="DEAL" />
          </>
        );
      case "LEAD_LOST_REASON_SELECT":
        return (
          <>
            <ActivityLostReasonSelect field={props.field} value={props.value} setValue={props.setValue} type="LEAD" />
          </>
        );
      case "PEOPLE_SELECT":
        return (
          <>
            <PersonSelectDropdown field={props.field} value={props.value} setValue={props.setValue} />
          </>
        );
      case "COMPANY_SELECT":
        return <CompanySelectDropdown field={props.field} value={props.value} setValue={props.setValue} />;
      case "LEAD_SELECT":
        return <LeadSelectDropdown field={props.field} value={props.value} setValue={props.setValue} />;
      case "DEAL_SELECT":
        return <DealSelectDropdown field={props.field} value={props.value} setValue={props.setValue} />;
      case "ASSOCIATION":
        return <Association field={props.field} value={props.value} setValue={props.setValue} allData={props.allData} />;
      case "MEETING_DATE_TIME":
        return <MeetingDateSelect field={props.field} value={props.value} setValue={props.setValue} />;
      case "MULTI_OPT_OUT":
        return <MultiOptOut field={props.field} value={Array.isArray(props.value) && props.value.length > 0 ? props.value : [""]} setValue={props.setValue} />;
      case "MULTI_OPT_OUT_REASON":
        return <MultiOptOutReason field={props.field} value={Array.isArray(props.value) && props.value.length > 0 ? props.value : [""]} setValue={props.setValue} />;
      case "AI_WIZARD":
        return <AIWizardField field={props.field} value={props.value} setValue={props.setValue} />;
      default:
        return <>undefined</>;
    }
  };

  return <>{getInputField(props.field.fieldInputType, props.field.isRequired, props.field.options)}</>;
}
