import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Spinner } from "react-bootstrap";
import CustomSidePanel from "../side-pannel-detailed/custom-components/custom-side-pannel";
import { SidebarTabs } from "../side-pannel-detailed/custom-components/tabs";

import * as TemplateActions from "../../containers/campaign/store/actions/campaign_templates_actions";

import "./styles.scss";
import ViewTypeSelector from "./components/view-type-selector";
import TemplateMainActions from "./components/template-main-actions";
import BlankSidePanel from "../blank-side-pannel";
import AddEditTemplate from "./containers/add-edit-template";
import { CustomEmptyPage } from "../empty_page/custom-empty-page";
import { DeleteModal } from "../modal";
import TemplateChannelView from "./containers/template-channel-view";
import CustomPagination from "../pagination/custom-pagination";
//actins
import * as SMSSettingsActions from "../../containers/campaign/store/actions/campaign_sms_settings_actions";
import { peopleFieldsRequest } from "../../containers/customers/store/actions/actions";
import { leadFieldsRequest } from "../../containers/leads/store/actions/actions";
import { companyFieldsRequest } from "../../containers/companies/store/actions/actions";
import UploadSMSTemplatesModal from "./components/upload-sms-templates.modal";
import { getMarketingDataFieldsRequest } from "../../containers/campaign/store/actions/marketing_data_field_actions";

const routeNameMapping = {
  promotional: "Promotional",
  transactional: "Transactional",
  otp: "OTP",
};
export default function TemplateManager(props) {
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [viewType, setViewType] = useState("org");
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState(null);
  const [filter, setFilter] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [ediableData, setEditableData] = useState(null);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [delatableId, setDelatableId] = useState("");
  const [showDeleteTemplate, setShowDeleteTemplate] = useState(false);

  //sms
  const [senderIdOptions, setSenderIdOptions] = useState([]);
  const [senderIdMapping, setSenderIdMapping] = useState({});
  const [senderIdCountryMapping, setSenderIdCountryMapping] = useState({});
  const [showUpload, setShowUpload] = useState(false);

  const templateCategoryStore = useSelector((store) => store.campaign.templates.categories);
  const templateDataStore = useSelector((store) => store.campaign.templates.data);
  const senderIdStore = useSelector((store) => store.campaign.settings.sms.senderId);

  useEffect(() => {
    if (props.channel === "sms") {
      dispatch(SMSSettingsActions.getListOfSMSSenderIdsRequest());
    }
  }, [dispatch, props.channel]);

  useEffect(() => {
    dispatch(peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));
    dispatch(companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));
    dispatch(getMarketingDataFieldsRequest({ fieldType: "MARKETING" }));
  }, [dispatch]);

  useEffect(() => {
    if (props.channel && !_.isEmpty(selectedCategory) && viewType) {
      const payload = {
        channel: props.channel,
        page: page,
        limit: limit,
        category: selectedCategory,
        type: viewType,
      };
      if (!_.isEmpty(tags.filter((each) => each))) payload["tag"] = tags[0];
      if (owner) payload["owner"] = owner;

      if (props.smsFilter && !_.isEmpty(props.smsFilter.senderIds)) {
        payload["customFilter"] = JSON.stringify({
          "meta.senderIds": props.smsFilter.senderIds.map((each) => each.value),
        });
      }

      dispatch(TemplateActions.getTemplatetListRequest(payload));
      setFilter(payload);
    }
  }, [dispatch, props.channel, page, limit, selectedCategory, tags, owner, viewType, props.smsFilter]);

  useEffect(() => {
    if (Array.isArray(templateCategoryStore.list) && templateCategoryStore.list.length > 0) {
      const findcategory = templateCategoryStore.list.find((a) => a.value === props.category);
      setCategoryList(templateCategoryStore.list);
      if (_.isEmpty(findcategory)) {
        setSelectedTab(templateCategoryStore.list[0].label);
        setSelectedCategory(templateCategoryStore.list[0].value);
      } else {
        setSelectedTab(findcategory.label);
        setSelectedCategory(findcategory.value);
      }
    } else {
      setCategoryList([]);
      setSelectedTab("");
      setSelectedCategory("");
    }
  }, [templateCategoryStore.list, props.category]);

  useEffect(() => {
    const mapping = {};
    const _map = {};
    setSenderIdOptions(
      senderIdStore.data.map((each) => {
        mapping[each._id] = each.senderId;
        if (each.country) {
          if (each.country.isAllSelected) {
            _map[each._id] = ["ALL"];
          } else {
            _map[each._id] = each.country.iso;
          }
        }
        return { value: each._id, label: `${each.senderId} (${routeNameMapping[each.routeName]})` };
      }),
    );
    setSenderIdMapping(mapping);
    setSenderIdCountryMapping(_map);
  }, [senderIdStore.data]);

  const handleCategoryChange = (_category) => {
    const findcategory = templateCategoryStore.list.find((a) => a.label === _category);
    setSelectedCategory(findcategory.value);
    setSelectedTab(_category);
    setPage(1);
    setViewType("org");
    setTags([]);
    setOwner(null);
  };

  const addTag = (name, colorCode) => {
    const payload = {
      name: name,
      channel: props.channel,
      category: selectedCategory,
      colorCode: colorCode,
    };
    dispatch(TemplateActions.createTemplateTagRequest(payload));
  };

  const editTag = (id, name, colorCode) => {
    dispatch(TemplateActions.editTemplateTagRequest({ id: id, body: { name: name, colorCode: colorCode } }));
  };

  const deleteTag = (id) => {
    dispatch(TemplateActions.deleteTemaplateTagRequest({ id: id }));
  };

  const addTemplate = (data) => {
    dispatch(TemplateActions.createTemplateRequest({ data: data, filter: filter }));
    setShowAddTemplate(false);
  };
  const editTemplate = (id, data) => {
    dispatch(TemplateActions.editTemplateRequest({ id: id, data: data, filter: filter }));
    setShowAddTemplate(false);
  };
  const deleteTemplate = (id) => {
    dispatch(TemplateActions.deleteTemplateRequest({ id: id, filter: filter }));
  };
  const cloneTemplate = (id) => {
    dispatch(TemplateActions.cloneTemplateRequest({ id: id, data: {}, filter: filter }));
  };

  const handleTagSelect = (value) => {
    tags[0] === value[0] ? setTags([]) : setTags(value);
  };

  const onDeleteTenplateClick = (id) => {
    setDelatableId(id);
    setShowDeleteTemplate(true);
  };
  const handleDeleteTemplateCancel = () => {
    setDelatableId("");
    setShowDeleteTemplate(false);
  };
  const handleDeleteTemplateAccept = () => {
    deleteTemplate(delatableId);
    setDelatableId("");
    setShowDeleteTemplate(false);
  };
  const deleteTemplateModalProps = {
    show: showDeleteTemplate,
    title: "Delete Report",
    handleClose: () => handleDeleteTemplateCancel(),
    handleAccept: () => handleDeleteTemplateAccept(),
  };

  const onEditTemplateClick = (data) => {
    setIsEdit(true);
    setEditableData(data);
    setShowAddTemplate(true);
  };

  const handleAddTemplateClose = () => {
    setShowAddTemplate(false);
    setIsEdit(false);
    setEditableData(null);
  };
  const handleTemplateClone = (id) => {
    cloneTemplate(id);
  };

  return (
    <CustomSidePanel show={props.show} moduleName={`${props.channel === "sms" ? "SMS" : _.capitalize(props.channel)} Templates`} handleClose={props.handleClose}>
      <div className="template-main-wrapper scroll hover-scroll m-0">
        {templateCategoryStore.loading ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Tab.Container defaultActiveKey={selectedTab}>
            <SidebarTabs selectedTab={selectedTab} setSelectedTab={handleCategoryChange} options={categoryList.map((each) => each.label)} />
            <div>
              {templateDataStore.loading ? (
                <div className="w-100 d-flex justify-content-center align-items-center h-vh-100px-mn">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="p-0">
                  <div className="d-flex justify-content-between align-items-center template-sub-header px-8">
                    <ViewTypeSelector
                      value={viewType}
                      onClick={(value) => {
                        setViewType(value);
                        setPage(1);
                        setTags([]);
                        setOwner(null);
                      }}
                    />
                    {viewType === "org" && (
                      <TemplateMainActions
                        channel={props.channel}
                        category={selectedCategory}
                        tags={tags}
                        setTags={handleTagSelect}
                        addTag={addTag}
                        editTag={editTag}
                        deleteTag={deleteTag}
                        onUploadClick={() => setShowUpload(true)}
                        handleAddTemplate={() => {
                          setShowAddTemplate(true);
                          setIsEdit(false);
                          setEditableData(null);
                        }}
                      />
                    )}
                  </div>

                  <div className="template-container px-8 pb-8 pt-6">
                    {templateDataStore.list.length === 0 && viewType === "global" ? (
                      <CustomEmptyPage page={"noTemplates-gallery"} hideBorder={true} />
                    ) : templateDataStore.list.length === 0 && viewType === "org" ? (
                      <CustomEmptyPage
                        page={"noTemplates"}
                        hideBorder={true}
                        setShowAdd={() => {
                          setShowAddTemplate(true);
                          setIsEdit(false);
                          setEditableData(null);
                        }}
                      />
                    ) : (
                      <TemplateChannelView
                        channel={props.channel}
                        viewType={viewType}
                        list={templateDataStore.list}
                        showChoose={props.showChoose}
                        onCloseClick={props.onCloseClick}
                        handleTemplateClone={handleTemplateClone}
                        onEditTemplateClick={onEditTemplateClick}
                        onDeleteTenplateClick={onDeleteTenplateClick}
                        handleOnChoose={props.onCloseClick}
                        //sms
                        senderIdMapping={senderIdMapping}
                        limit={limit}
                        page={page}
                        setLimit={setLimit}
                        setPage={setPage}
                        count={templateDataStore.count}
                      />
                    )}
                  </div>
                  {templateDataStore.count > 0 && props.channel !== "sms" && (
                    <div className="d-flex align-items-center flex-row justify-content-end gap-5 px-5">
                      <p className="mt-5px mb-0 font-size-14px">
                        Showing {(page - 1) * limit + 1}-{templateDataStore.count > page * limit ? page * limit : templateDataStore.count} of {templateDataStore.count}
                      </p>
                      {CustomPagination(page, setPage, Math.ceil(templateDataStore.count / limit), () => { }, limit, setLimit, templateDataStore.count)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Tab.Container>
        )}
        <BlankSidePanel show={showAddTemplate} title={isEdit ? "Edit Template" : "Add Template"} handleClose={handleAddTemplateClose}>
          <AddEditTemplate
            channel={props.channel}
            category={selectedCategory}
            isEdit={isEdit}
            ediableData={ediableData}
            addTag={addTag}
            editTag={editTag}
            deleteTag={deleteTag}
            addTemplate={addTemplate}
            editTemplate={editTemplate}
            deleteTemplate={deleteTemplate}
            //sms
            senderIdOptions={senderIdOptions}
            senderIdCountryMapping={senderIdCountryMapping}
          />
        </BlankSidePanel>
        <DeleteModal modalProps={deleteTemplateModalProps}>Are you sure you want to delete the Template? Once you delete,You won't able to recover it.</DeleteModal>
      </div>
      <UploadSMSTemplatesModal setShow={setShowUpload} show={showUpload} filter={filter} category={selectedCategory} />
    </CustomSidePanel>
  );
}
