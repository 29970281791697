export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

export const PRODUCTS_UPDATE_REQUEST = "PRODUCTS_UPDATE_REQUEST";
export const PRODUCTS_UPDATE_SUCCESS = "PRODUCTS_UPDATE_SUCCESS";
export const PRODUCTS_UPDATE_ERROR = "PRODUCTS_UPDATE_ERROR";

export const LOADING_DATA_UPDATE = "PRODUCT_LOADING_DATA_UPDATE_REQUEST";

export const FIELD_UPDATE_REQUEST = "PRODUCT_FIELD_UPDATE_REQUEST";
export const FIELD_UPDATE_SUCCESS = "PRODUCT_FIELD_UPDATE_SUCCESS";
export const FIELD_UPDATE_ERROR = "PRODUCT_FIELD_UPDATE_ERROR";

export const FIELD_SHOWHIDE_REQUEST = "PRODUCT_FIELD_SHOWHIDE_REQUEST";
export const FIELD_SHOWHIDE_SUCCESS = "PRODUCT_FIELD_SHOWHIDE_SUCCESS";
export const FIELD_SHOWHIDE_ERROR = "PRODUCT_FIELD_SHOWHIDE_ERROR";

export const FIELD_UPDATEPRIORITY_REQUEST = "PRODUCT_FIELD_UPDATEPRIORITY_REQUEST";
export const FIELD_UPDATEPRIORITY_SUCCESS = "PRODUCT_FIELD_UPDATEPRIORITY_SUCCESS";
export const FIELD_UPDATEPRIORITY_ERROR = "PRODUCT_FIELD_UPDATEPRIORITY_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const PRODUCTADD_REQUEST = "PRODUCTADD_REQUEST";
export const PRODUCTADD_SUCCESS = "PRODUCTADD_SUCCESS";
export const PRODUCTADD_ERROR = "PRODUCTADD_ERROR";

export const PRODUCTBULKEDIT_REQUEST = "PRODUCTBULKEDIT_REQUEST";
export const PRODUCTBULKEDIT_SUCCESS = "PRODUCTBULKEDIT_SUCCESS";
export const PRODUCTBULKEDIT_ERROR = "PRODUCTBULKEDIT_ERROR";

export const PRODUCTDELETE_REQUEST = "PRODUCTDELETE_REQUEST";
export const PRODUCTDELETE_SUCCESS = "PRODUCTDELETE_SUCCESS";
export const PRODUCTDELETE_ERROR = "PRODUCTDELETE_ERROR";

export const PRODUCTFIELDS_REQUEST = "PRODUCTFIELDS_REQUEST";
export const PRODUCTFIELDS_SUCCESS = "PRODUCTFIELDS_SUCCESS";
export const PRODUCTFIELDS_ERROR = "PRODUCTFIELDS_ERROR";

export const PRODUCTADDFIELDS_REQUEST = "PRODUCTADDFIELDS_REQUEST";
export const PRODUCTADDFIELDS_SUCCESS = "PRODUCTADDFIELDS_SUCCESS";
export const PRODUCTADDFIELDS_ERROR = "PRODUCTADDFIELDS_ERROR";

export const PRODUCTCATEGORIES_REQUEST = "PRODUCTCATEGORIES_REQUEST";
export const PRODUCTCATEGORIES_SUCCESS = "PRODUCTCATEGORIES_SUCCESS";
export const PRODUCTCATEGORIES_ERROR = "PRODUCTCATEGORIES_ERROR";

export const PRODUCTCATEGORIESADD_REQUEST = "PRODUCTCATEGORIESADD_REQUEST";
export const PRODUCTCATEGORIESADD_SUCCESS = "PRODUCTCATEGORIESADD_SUCCESS";
export const PRODUCTCATEGORIESADD_ERROR = "PRODUCTCATEGORIESADD_ERROR";

export const PRODUCTCATEGORIESEDIT_REQUEST = "PRODUCTCATEGORIESEDIT_REQUEST";
export const PRODUCTCATEGORIESEDIT_SUCCESS = "PRODUCTCATEGORIESEDIT_SUCCESS";
export const PRODUCTCATEGORIESEDIT_ERROR = "PRODUCTCATEGORIESEDIT_ERROR";

export const PRODUCTCATEGORIESDELETE_REQUEST = "PRODUCTCATEGORIESDELETE_REQUEST";
export const PRODUCTCATEGORIESDELETE_SUCCESS = "PRODUCTCATEGORIESDELETE_SUCCESS";
export const PRODUCTCATEGORIESDELETE_ERROR = "PRODUCTCATEGORIESDELETE_ERROR";

export const PRODUCTTAGS_REQUEST = "PRODUCTTAGS_REQUEST";
export const PRODUCTTAGS_SUCCESS = "PRODUCTTAGS_SUCCESS";
export const PRODUCTTAGS_ERROR = "PRODUCTTAGS_ERROR";

export const PRODUCTTAGSADD_REQUEST = "PRODUCTTAGSADD_REQUEST";
export const PRODUCTTAGSADD_SUCCESS = "PRODUCTTAGSADD_SUCCESS";
export const PRODUCTTAGSADD_ERROR = "PRODUCTTAGSADD_ERROR";

export const PRODUCTTAGSEDIT_REQUEST = "PRODUCTTAGSEDIT_REQUEST";
export const PRODUCTTAGSEDIT_SUCCESS = "PRODUCTTAGSEDIT_SUCCESS";
export const PRODUCTTAGSEDIT_ERROR = "PRODUCTTAGSEDIT_ERROR";

export const PRODUCTTAGSDELETE_REQUEST = "PRODUCTTAGSDELETE_REQUEST";
export const PRODUCTTAGSDELETE_SUCCESS = "PRODUCTTAGSDELETE_SUCCESS";
export const PRODUCTTAGSDELETE_ERROR = "PRODUCTTAGSDELETE_ERROR";

export const PRODUCTCELLEDIT_REQUEST = "PRODUCTCELLEDIT_REQUEST";
export const PRODUCTCELLEDIT_SUCCESS = "PRODUCTCELLEDIT_SUCCESS";
export const PRODUCTCELLEDIT_ERROR = "PRODUCTCELLEDIT_ERROR";

export const PRODUCTFILTERS_REQUEST = "PRODUCTFILTERS_REQUEST";
export const PRODUCTFILTERS_SUCCESS = "PRODUCTFILTERS_SUCCESS";
export const PRODUCTFILTERS_ERROR = "PRODUCTFILTERS_ERROR";

export const PRODUCTFILTERADD_REQUEST = "PRODUCTFILTERADD_REQUEST";
export const PRODUCTFILTERADD_SUCCESS = "PRODUCTFILTERADD_SUCCESS";
export const PRODUCTFILTERADD_ERROR = "PRODUCTFILTERADD_ERROR";

export const PRODUCTFILTEREDIT_REQUEST = "PRODUCTFILTEREDIT_REQUEST";
export const PRODUCTFILTEREDIT_SUCCESS = "PRODUCTFILTEREDIT_SUCCESS";
export const PRODUCTFILTEREDIT_ERROR = "PRODUCTFILTEREDIT_ERROR";

export const PRODUCTFILTERFAVORITE_REQUEST = "PRODUCTFILTERFAVORITE_REQUEST";
export const PRODUCTFILTERFAVORITE_SUCCESS = "PRODUCTFILTERFAVORITE_SUCCESS";
export const PRODUCTFILTERFAVORITE_ERROR = "PRODUCTFILTERFAVORITE_ERROR";

export const PRODUCTFILTERDELETE_REQUEST = "PRODUCTFILTERDELETE_REQUEST";
export const PRODUCTFILTERDELETE_SUCCESS = "PRODUCTFILTERDELETE_SUCCESS";
export const PRODUCTFILTERDELETE_ERROR = "PRODUCTFILTERDELETE_ERROR";

export const CURRENCY_REQUEST = "CURRENCY_REQUEST";
export const CURRENCY_SUCCESS = "CURRENCY_SUCCESS";
export const CURRENCY_ERROR = "CURRENCY_ERROR";

export const PRODUCTSMERGE_REQUEST = "PRODUCTSMERGE_REQUEST";
export const PRODUCTSMERGE_SUCCESS = "PRODUCTSMERGE_SUCCESS";
export const PRODUCTSMERGE_ERROR = "PRODUCTSMERGE_ERROR";

export const APPLYFILTER = "APPLYPRODUCTFILTER";
export const CLEARFILTER = "CLEARPRODUCTFILTER";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_ERROR = "PRODUCT_SEARCH_ERROR";

export const PRODUCT_DROPDOWN_ADD_REQUEST = "PRODUCT_DROPDOWN_ADD_REQUEST";
export const PRODUCT_DROPDOWN_ADD_SUCCESS = "PRODUCT_DROPDOWN_ADD_SUCCESS";
export const PRODUCT_DROPDOWN_ADD_ERROR = "PRODUCT_DROPDOWN_ADD_ERROR";

export const PRODUCT_DROPDOWN_ADD_CLEAR = "PRODUCT_DROPDOWN_ADD_CLEAR";
