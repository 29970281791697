import { takeLatest, put, call } from "redux-saga/effects";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../../../../utils/toaster";
import * as campaignWhatsappMessagingTypes from "../../types/campaign_whatsapp_messaging_types";
import * as campaignWhatsappMessagingActions from "../../actions/campaign_whatsapp_messaging_actions";
import { endpoints } from "../../endpoints";
import api from "../../../../../network/apis/api";

function* getWhatsappMedia(action) {
    try {
        const res = yield call(api.get, endpoints.WHATSAPP_MEDIA, action.payload);
        if (res.status === 200) {
            yield put(campaignWhatsappMessagingActions.getWhatsappMediaSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappMessagingActions.getWhatsappMediaFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappMessagingActions.getWhatsappMediaFailure(e));
    }
}
function* uploadWhatsappMedia(action) {
    try {
        const res = yield call(api.post, endpoints.UPLOAD_WHATSAPP_MEDIA, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message || "Media uploaded successfully")
            yield put(campaignWhatsappMessagingActions.uploadWhatsappMediaSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappMessagingActions.uploadWhatsappMediaFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappMessagingActions.uploadWhatsappMediaFailure(e));
    }
}
function* uploadWhatsappSessionMedia(action) {
    try {
        const res = yield call(api.post, endpoints.UPLOAD_WHATSAPP_SESSION_MEDIA, action.payload);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message || "Media uploaded successfully")
            yield put(campaignWhatsappMessagingActions.uploadWhatsappSessionMediaSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappMessagingActions.uploadWhatsappSessionMediaFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappMessagingActions.uploadWhatsappSessionMediaFailure(e));
    }
}
function* deleteWhatsappMedia(action) {
    try {
        const res = yield call(api.delete, `${endpoints.WHATSAPP_MEDIA}/${action.payload.integrationId}/${action.mediaId}`);
        if (res.status === 200) {
            dispatchSnackbarSuccess(res.data.message)
            yield put(campaignWhatsappMessagingActions.deleteWhatsappMediaSuccess(res.data));
        } else {
            dispatchSnackbarError(res.data.message);
            yield put(campaignWhatsappMessagingActions.deleteWhatsappMediaFailure(res.data));
        }
        if (action.cb) action.cb(res.data);
    } catch (e) {
        yield put(campaignWhatsappMessagingActions.deleteWhatsappMediaFailure(e));
    }
}
export default function* campaignWhatsappMessaginSaga() {
    yield takeLatest(campaignWhatsappMessagingTypes.getWhatsappMedia.REQUEST, getWhatsappMedia)
    yield takeLatest(campaignWhatsappMessagingTypes.uploadWhatsappMedia.REQUEST, uploadWhatsappMedia)
    yield takeLatest(campaignWhatsappMessagingTypes.uploadWhatsappSessionMedia.REQUEST, uploadWhatsappSessionMedia)
    yield takeLatest(campaignWhatsappMessagingTypes.deleteWhatsappMedia.REQUEST, deleteWhatsappMedia);
}
