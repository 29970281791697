export const getMarketingDataFields = {
  GET_MARKETING_DATA_FIELDS_REQUEST: "GET_MARKETING_DATA_FIELDS_REQUEST",
  GET_MARKETING_DATA_FIELDS_SUCCESS: "GET_MARKETING_DATA_FIELDS_SUCCESS",
  GET_MARKETING_DATA_FIELDS_ERROR: "GET_MARKETING_DATA_FIELDS_ERROR",
};

export const getMarketingAddForm = {
  GET_MARKETING_ADD_FORM_REQUEST: "GET_MARKETING_ADD_FORM_REQUEST",
  GET_MARKETING_ADD_FORM_SUCCESS: "GET_MARKETING_ADD_FORM_SUCCESS",
  GET_MARKETING_ADD_FORM_ERROR: "GET_MARKETING_ADD_FORM_ERROR",
};

export const setMarketingTablePriority = {
  SET_MARKETING_TABLE_PRIORITY_REQUEST: "SET_MARKETING_TABLE_PRIORITY_REQUEST",
  SET_MARKETING_TABLE_PRIORITY_SUCCESS: "SET_MARKETING_TABLE_PRIORITY_SUCCESS",
  SET_MARKETING_TABLE_PRIORITY_ERROR: "SET_MARKETING_TABLE_PRIORITY_ERROR",
};

export const setMarketingFieldShowHide = {
  SET_MARKETING_FIELD_SHOW_HIDE_REQUEST: "SET_MARKETING_FIELD_SHOW_HIDE_REQUEST",
  SET_MARKETING_FIELD_SHOW_HIDE_SUCCESS: "SET_MARKETING_FIELD_SHOW_HIDE_SUCCESS",
  SET_MARKETING_FIELD_SHOW_HIDE_ERROR: "SET_MARKETING_FIELD_SHOW_HIDE_ERROR",
};
