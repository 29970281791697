import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CustomSubHeader } from "../../../components/subheader";
import * as dealActions from "../dealPipeline/actions";
import * as dealListActions from "./actions";
import { ColumnFilter } from "../../../components/column_filter";
import { EditableTable } from "../../../components/table";
import { dispatchSnackbarWarning } from "../../../utils/toaster";
import ContentWrapper from "../../Wrapper";
import DealSubHeader from "../components/deal-common.subheader";
import { getActivtyDataFieldsRequest } from "../../Activities/store/actions/activity_data_field_actions";
import { AddDealModal } from "../common/dealModal/index";
import { DeleteModal } from "../../../components/modal";
import MoveDealToModel from "../components/move-deal-to.model";
import MarkAsLostModel from "../components/mark-as-lost.model";
import { productSearchRequest } from "../../products/store/actions/actions";
import SidePannelDetailed from "../../../components/side-pannel-detailed";

import * as LeadActions from "../../leads/store/actions/actions";
import * as PeopleActions from "../../customers/store/actions/actions";
import * as CompanyActions from "../../companies/store/actions/actions";
import * as ProductActions from "../../products/store/actions/actions";
import * as insightActions from "../../insights/actions";
import { CustomEmptyPage } from "../../../components/empty_page/custom-empty-page";
import { GridViewSVG, ListViewSVG } from "../../../components/custom-svgs/sub-header-svg";
import * as TemplateActions from "../../campaign/store/actions/campaign_templates_actions";
import AddEditPipelineModelV2 from "../components/add-pipeline";
import OptionsDropdown from "../../../components/filter/import-export-dropdown";

export default function DealListWrapper() {
  const page = "Deals";
  const pageSub = "deal";
  const tabName = "List";
  const [links, setLinks] = useState([
    { name: "Pipeline View", to: "/deal/pipeline", icon: "bi bi-distribute-horizontal", svg: GridViewSVG },
    { name: "List View", to: "/deal/deal-list", icon: "bi bi-list", svg: ListViewSVG },
  ]);
  const [openAddDealModal, setOpenAddDealModal] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState("Choose pipeline");
  const [showDelete, setShowDelete] = useState(false);
  const [selectedPipelineId, setSelectedPipelineId] = useState("");
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [openMoveToModal, setOpenMoveToModal] = useState(false);
  const [openMoveToLossModal, setOpenMoveToLossModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [dealDeleteShow, setDealDeleteShow] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const [addFilterFormData, setAddFilterFormData] = useState({
    filterModule: "deal",
    filterName: "",
    visibility: "Private",
    isSelectedColumnsSave: false,
    selectedColumns: [],
    isFavorite: false,
    andMatchConditions: [],
    orMatchConditions: [],
  });
  const [showColumns, setShowColumns] = useState([]);
  const [checked, setChecked] = useState([]);
  const [limit, setLimit] = useState(30);
  const [pageNo, setPageNo] = useState(1);
  const [cellEdit, setCellEdit] = useState({ id: "" });
  const [sortConfig, setSortConfig] = useState(null);

  //filters
  const [virtualFilter, setVirtualFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [owner, setOwner] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterOption, setFilterOption] = useState("Open");

  //side panel
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePannelDealId, setSidePannelDealId] = useState("");
  const [nextPreviousData, setNextPreviousData] = useState([]);
  const [linkedModuleId, setLinkedModuleId] = useState("");
  const [linkedModuleName, setLinkedModuleName] = useState("");

  // add and edit pipeline
  const [showAddPipelineModal, setShowAddPipelineModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editPipelineId, setEditPipelineId] = useState("");

  //dispatch hook
  const dispatch = useDispatch();
  const params = useParams();

  //use history hook
  const history = useHistory();

  //store
  const pipelineStore = useSelector((store) => store.DealPipeline);
  const userProfile = useSelector((state) => state.ProfileSettings.getProfileDetails);
  const dealTagStore = useSelector((state) => state.DealPipeline.dealTags.list);
  const accountBillingStore = useSelector((state) => state.Billing.subscriptionv2.billing);
  const fieldStore = useSelector((state) => state.DealList.dealFields);
  const listStore = useSelector((state) => state.DealList.dealList);
  const loadingStore = useSelector((state) => state.DealList.loadingData);
  const filterStore = useSelector((state) => state.DealPipeline.dealFilters);
  const activeFilterStore = useSelector((state) => state.DealPipeline.dealFilters.activeFilter);
  const pipelineStageStore = useSelector((store) => store.DealPipeline.stagesData.data);

  const sortKeys = useCallback(() => {
    if (sortConfig) return { sortkey: sortConfig.key, sortType: sortConfig.direction };
    else return { sortkey: "", sortType: "" };
  }, [sortConfig]);

  useEffect(() => {
    if (selectedPipelineId) {
      const payload = {
        pageNo: pageNo,
        limit: limit,
        pipelineId: selectedPipelineId,
        tagId: tags,
        state: filterOption,
        owner: owner,
        filterId: filterId,
        ...sortKeys(),
      };
      if (!_.isEmpty(virtualFilter)) {
        payload["customFilter"] = JSON.stringify(virtualFilter);
      }
      dispatch(dealListActions.getDealListRequest(payload));
      dispatch(dealListActions.updateDealListLoadingData(payload));
    }
  }, [dispatch, selectedPipelineId, pageNo, limit, tags, filterOption, owner, filterId, virtualFilter, sortKeys]);

  useEffect(() => {
    if (params.linkedId && params.moduleName) {
      setLinkedModuleId(params.linkedId);
      setLinkedModuleName(params.moduleName);
      setSidePannelDealId("");
      setOpenPanel(true);
    } else if (params.pipelineId && params.dealId) {
      setSidePannelDealId(params.dealId);
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(true);
    } else {
      setSidePannelDealId("");
      setLinkedModuleId("");
      setLinkedModuleName("");
      setOpenPanel(false);
    }
  }, [params]);

  useEffect(() => {
    if (params.pipelineId) {
      setLinks([
        { name: "Pipeline View", to: `/deal/pipeline/${params.pipelineId}`, icon: "bi bi-distribute-horizontal", svg: GridViewSVG },
        { name: "List View", to: `/deal/deal-list/${params.pipelineId}`, icon: "bi bi-list", svg: ListViewSVG },
      ]);
    }
  }, [params.pipelineId]);

  useEffect(() => {
    dispatch(dealActions.getPipelineRequest());
    dispatch(dealActions.getSavedFiltersRequest());
    dispatch(dealActions.getLostReasonsRequest());
    dispatch(dealActions.dealSearchRequest(""));
    dispatch(productSearchRequest(""));
    dispatch(dealListActions.getDealListFieldsRequest({ skipIfDataPresent: true }));
    dispatch(getActivtyDataFieldsRequest({ skipIfDataPresent: true }));

    //data field apis (Need to remove Redendent APIs)
    dispatch(LeadActions.leadTagsRequest({ request: { type: "lead" }, skipIfDataPresent: true }));
    dispatch(LeadActions.leadFieldsRequest({ request: { fieldType: "LEAD" }, skipIfDataPresent: true }));

    dispatch(PeopleActions.peopleFieldsRequest({ request: { fieldType: "PERSON" }, skipIfDataPresent: true }));
    dispatch(PeopleActions.peopleTagsRequest({ request: { type: "customer" }, skipIfDataPresent: true }));

    dispatch(CompanyActions.companyTagsRequest({ request: { type: "company" }, skipIfDataPresent: true }));
    dispatch(CompanyActions.companyFieldsRequest({ request: { fieldType: "COMPANY" }, skipIfDataPresent: true }));

    // common apis for all module
    dispatch(LeadActions.signatureRequest({ skipIfDataPresent: true }));
    dispatch(ProductActions.currencyRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.countriesRequest({ skipIfDataPresent: true }));
    dispatch(CompanyActions.timezonesRequest({ skipIfDataPresent: true }));
    dispatch(insightActions.getUserRoleRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateCategoryRequest({ skipIfDataPresent: true }));
    dispatch(TemplateActions.getTemplateTagsRequest({ skipIfDataPresent: true }));
  }, [dispatch]);

  useEffect(() => {
    if (listStore.list) {
      const _data = listStore.list.map((each) => {
        return { module: "deal", id: each._id };
      });
      setNextPreviousData(_data);
    }
  }, [listStore.list]);

  //setting pipeline options
  useEffect(() => {
    setPipelineOptions(pipelineStore.pipelineOptionsResponse);
  }, [pipelineStore]);

  useEffect(() => {
    if (!_.isEmpty(pipelineStageStore)) {
      // setSelectedPipeline(pipelineStageStore.name);
      setSelectedStages(pipelineStageStore.stages);
    }
  }, [pipelineStageStore]);

  useEffect(() => {
    const localOnwer = localStorage.getItem("dealOwner");
    if (localOnwer) setOwner(localOnwer);
  }, []);

  useEffect(() => {
    if (owner) localStorage.setItem("dealOwner", owner);
    else localStorage.removeItem("dealOwner");
  }, [owner]);

  const addDealTag = (name, colorCode) => {
    dispatch(dealActions.addDealTagRequest({ name: name, colorCode: colorCode, type: "deal" }));
  };

  const editDealTag = (id, name, colorCode) => {
    dispatch(dealActions.updateDealTagRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deleteDealTag = (id) => {
    dispatch(dealActions.deleteDealTagRequest(id));
  };

  const addPeopleTag = (name, colorCode) => {
    dispatch(PeopleActions.peopleTagsAddRequest({ name: name, colorCode: colorCode, type: "lead" }));
  };

  const editPeopleTag = (id, name, colorCode) => {
    dispatch(PeopleActions.peopleTagsEditRequest({ id: id, name: name, colorCode: colorCode }));
  };

  const deletePeopleTag = (id) => {
    dispatch(PeopleActions.peopleTagsDeleteRequest(id));
  };

  const handleEditDealList = (combinedId, data) => {
    dispatch(
      dealListActions.dealCellEditRequest({
        editData: data,
        loadingData: loadingStore,
        cellId: combinedId,
      }),
    );
  };

  useEffect(() => {
    if (params.pipelineId) {
      const _selectedPipeline = pipelineOptions.find((a) => a._id === params.pipelineId);
      if (_selectedPipeline) {
        setSelectedPipeline(_selectedPipeline.name);
        setSelectedPipelineId(_selectedPipeline._id);
        setSelectedStages(_selectedPipeline.stages);
        dispatch(dealActions.getStagesRequest({ pipelineId: _selectedPipeline._id }));
        dispatch(dealActions.getDealRequest({ pipelineId: _selectedPipeline._id }));
      }
    } else {
      const defaultPipeline = pipelineOptions.find((a) => a.isDefault);
      if (defaultPipeline) {
        history.push(`/deal/deal-list/${defaultPipeline._id}`);
      }
    }
  }, [dispatch, params, pipelineOptions, history]);

  //add deal button click function
  const handleAddDeal = () => {
    setOpenAddDealModal(true);
  };

  //add deal modal close function
  const onAddDealModalClose = () => {
    setOpenAddDealModal(false);
  };

  //add deal modal save function
  const onAddDealModalSave = (data) => {
    dispatch(dealActions.addDealRequest({ data, filter: loadingStore, pageForm: "dealList" }));
    setOpenAddDealModal(false);
  };

  //getting list of pipeline options
  const getPipelineOptions = () => {
    let data = pipelineStore.pipelineOptionsResponse;
    let pipelineArray = [];
    data && data.length > 0 && data.map((pipeline) => pipelineArray.push({ name: pipeline.name, id: pipeline._id, isDefault: pipeline.isDefault }));
    return pipelineArray;
  };

  //on pipeline options select
  const onPipelineChange = (pipeline, index) => {
    setSelectedPipeline(pipeline.name);
    setSelectedPipelineId(pipeline.id);

    history.push(`/deal/deal-list/${pipeline.id}`);

    dispatch(
      dealActions.getStagesRequest({
        pipelineId: pipeline.id,
      }),
    );
  };

  //on add pipeline button click
  const onAddPipelineClick = () => {
    if (accountBillingStore.data.servicesOffered.PIPELINES && accountBillingStore.data.servicesOffered.PIPELINES !== "unlimited") {
      if (pipelineStore.pipelineOptionsResponse.length < accountBillingStore.data.servicesOffered.PIPELINES) {
        setShowAddPipelineModal(true);
        setIsEdit(false);
        setEditPipelineId("");
      } else {
        dispatchSnackbarWarning("You have reached the max number of pipeline limit. Please upgrade your plan to create more pipelines.", "warning");
      }
    } else {
      setShowAddPipelineModal(true);
      setIsEdit(false);
      setEditPipelineId("");
    }
  };

  //on edit pipeline option click
  const onPipelineEditIconClick = (data) => {
    setShowAddPipelineModal(true);
    setIsEdit(true);
    setEditPipelineId(data.id);
  };

  //on delete pipeline icon click
  const onPipelineDeletePipeline = () => {
    dispatch(
      dealActions.deletePipelineRequest({
        pipelineId: selectedPipelineId,
        newPipelineId: pipelineOptions[0]._id,
      }),
    );
    setSelectedPipeline(pipelineOptions[0].name);
    setSelectedPipelineId(pipelineOptions[0]._id);
    setSelectedStages(pipelineOptions[0].stages);
    setShowDelete(false);
  };

  //setting up the default pipeline
  const setAsDefault = (pipeline) => {
    dispatch(dealActions.markAsDefaultRequest({ id: pipeline.id }));
  };

  const applyFilter = (data) => {
    setFilterId(data);
    // setTags([]);
    // setOwner("");
    setVirtualFilter({});
    // setFilterOption("All Deals");
  };

  const applyVirtualFilter = (data) => {
    setVirtualFilter(data);
    setFilterId("");
    // setTags([]);
    // setOwner("");
    // setFilterOption("All Deals");
  };

  const favoriteFilter = (data) => {
    dispatch(dealActions.favoriteSavedFiltersRequest(data));
  };

  const addFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      dispatch(dealActions.addSavedFiltersRequest(temp));
    } else {
      dispatch(dealActions.addSavedFiltersRequest(addFilterFormData));
    }
    setAddFilterFormData({
      filterModule: "deal",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
    });
  };

  const editFilter = () => {
    var temp = {};
    if (addFilterFormData["isSelectedColumnsSave"]) {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    } else {
      temp = addFilterFormData;
      temp["id"] = temp["_id"];
    }
    dispatch(dealActions.editSavedFiltersRequest(temp));
    setAddFilterFormData({
      filterModule: "deal",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
    });
  };

  const deleteFilter = (id) => {
    if (activeFilterStore && activeFilterStore["id"] === id) dispatch(dealActions.clearFilter());
    dispatch(dealActions.deleteSavedFiltersRequest(id));
    setAddFilterFormData({
      filterModule: "deal",
      filterName: "",
      visibility: "Private",
      isSelectedColumnsSave: false,
      selectedColumns: [],
      isFavorite: false,
      andMatchConditions: [],
      orMatchConditions: [],
    });
  };

  const onTagFilterSelection = (_tag) => {
    if (tags[0] === _tag[0]) {
      setTags([]);
    } else {
      setTags(_tag);
    }
    dispatch(dealActions.clearFilter());
  };

  const showHideField = (data) => {
    dispatch(dealListActions.showOrHideDealListColumnFieldsRequest(data));
  };

  const updateFieldPriority = (data) => {
    dispatch(dealListActions.updateColumnFieldsPriorityRequest({ ...data, fieldType: "DEAL" }));
  };

  const tableProps = {
    columns: fieldStore.list,
    data: listStore.list,
    checkboxes: true,
    pagination: true,
    showColumns: showColumns,
    checked: checked,
    setChecked: setChecked,
    count: listStore.count,
    limit: limit,
    pageNo: pageNo,
    setLimit: setLimit,
    setPageNo: setPageNo,
    cellEdit: cellEdit,
    setCellEdit: setCellEdit,

    addTag: addDealTag,
    editTag: editDealTag,
    deleteTag: deleteDealTag,

    addPeopleTag: addPeopleTag,
    editPeopleTag: editPeopleTag,
    deletePeopleTag: deletePeopleTag,

    idToIndex: fieldStore.idToIndex,
    handleCellEdit: handleEditDealList,
    hidePanel: false,
    page: page,
    pageSub: pageSub,
    cellLoading: listStore.cellLoading,
    loading: listStore.loading,
    onDealListRowClick: (data) => onDealListRowClick(data),
    selectedStages: selectedStages,
    //for side panel:
    // sidePanelProps: popupStore.data,
    // popupLoading: popupStore.loading,
    // popupCellLoading: popupStore.cellLoading,
    // sidePanelType: sidePanelType,
    // handleDelete: () => setShowDelete(true),

    //for sorting;
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    isApiSort: true,

    //for filter
    filterPayload: loadingStore,
  };

  const onDealListRowClick = (tableRow) => {
    history.push(`/deal/deal-list/${selectedPipelineId}/${tableRow._id}`);
  };

  const handlePanelClose = (value) => {
    history.push(`/deal/deal-list/${selectedPipelineId}`);
    setOpenPanel(value);
  };

  const callMoveStagesApi = (data) => {
    dispatch(
      dealActions.moveDealInStagesRequest({
        ...data,
        filter: loadingStore,
        pageForm: "dealList",
      }),
    );
  };

  const callDealOperationApi = (data) => {
    dispatch(
      dealActions.dealOperationRequest({
        ...data,
        filter: loadingStore,
        pageForm: "dealList",
      }),
    );
  };

  useEffect(() => {
    let tempCol = [];
    var tempFieldStore = fieldStore.list;
    tempFieldStore.sort(function(a, b) {
      return a.tablePriority - b.tablePriority;
    });
    for (let i = 0; i < tempFieldStore.length; i++) if (tempFieldStore[i].isEnabledForTable !== false) tempCol.push(tempFieldStore[i]["_id"]);
    setShowColumns(tempCol);
  }, [fieldStore]);

  const onPipelineStateFilterClick = (value) => {
    setFilterOption(value);
  };

  const onMarkAsArchive = () => {
    const payload = {
      data: { dealId: params.dealId ? params.dealId : checked[0] },
      type: "archive",
      pipelineId: selectedPipelineId,
      filter: loadingStore,
      pageForm: "dealList",
    };
    dispatch(dealActions.dealOperationRequest(payload));
    setChecked([]);
    setOpenPanel(false);
    setSidePannelDealId("");
  };
  const onMarkAsWon = () => {
    const payload = {
      data: { dealId: checked[0] },
      type: "mark-as-won",
      pipelineId: selectedPipelineId,
      filter: loadingStore,
      pageForm: "dealList",
    };
    dispatch(dealActions.dealOperationRequest(payload));
    setChecked([]);
  };
  const onMarkAsLost = () => {
    const selectedDealId = checked[0];
    const selectedDeal = listStore.list.find((deal) => deal._id === selectedDealId);
    if (selectedDeal) {
      setOpenMoveToLossModal(true);
      setDeletedItem({
        ...selectedDeal,
        users: [],
      });
    }
  };
  const onMove = () => {
    const selectedDealId = checked[0];
    const selectedDeal = listStore.list.find((deal) => deal._id === selectedDealId);
    if (selectedDeal) {
      setOpenMoveToModal(true);
      setDeletedItem({
        ...selectedDeal,
        users: [],
      });
    }
  };
  const onDelete = () => {
    setDealDeleteShow(true);
  };

  const onDeleteAcceptClick = () => {
    callDealOperationApi({
      data: { dealIds: params.dealId ? [params.dealId] : checked },
      type: "bulk-delete",
      pipelineId: selectedPipelineId,
    });
    setDealDeleteShow(false);
    setChecked([]);
    setOpenPanel(false);
    setSidePannelDealId("");
  };
  const onConvertToLeadClick = () => {
    callDealOperationApi({
      data: { dealId: params.dealId ? params.dealId : checked },
      type: "deal-to-lead",
      pipelineId: selectedPipelineId,
      from: "deal",
    });
    setChecked([]);
    setOpenPanel(false);
    setSidePannelDealId("");
  };

  const isEmpty = () => {
    if (listStore.list.length === 0 && _.isEmpty(virtualFilter) && _.isEmpty(owner) && _.isEmpty(tags) && _.isEmpty(filterId)) {
      return true;
    }
    return false;
  };

  return (
    <ContentWrapper
      subHeader={
        <CustomSubHeader>
          <DealSubHeader
            links={links}
            page={page}
            pageSub={pageSub}
            tabName={tabName}
            tags={tags}
            addTag={addDealTag}
            editTag={editDealTag}
            deleteTag={deleteDealTag}
            filterOption={filterOption}
            handleAddDeal={handleAddDeal}
            onTagFilterSelection={onTagFilterSelection}
            onPipelineStateFilterClick={onPipelineStateFilterClick}
            //owner dropdown
            owner={owner}
            setOwner={setOwner}
            //smart views
            activeFilter={filterStore.activeFilter}
            customFilters={filterStore.list}
            loading={filterStore.loading}
            setAddFilterFormData={setAddFilterFormData}
            virtualFilter={virtualFilter}
            applyVirtualFilter={applyVirtualFilter}
            selectedFilterId={filterId}
            favoriteFilter={favoriteFilter}
            applyFilter={applyFilter}
            addFilterFormData={addFilterFormData}
            addFilter={addFilter}
            editFilter={editFilter}
            deleteFilter={deleteFilter}
            selectedPipelineId={selectedPipelineId}
            //pipeline select
            show={show}
            setShow={setShow}
            selectedPipeline={selectedPipeline}
            options={getPipelineOptions()}
            onPipelineChange={onPipelineChange}
            setAsDefault={setAsDefault}
            onAddPipelineClick={onAddPipelineClick}
            onPipelineEditIconClick={onPipelineEditIconClick}
            //actions
            checked={checked}
            onMarkAsArchive={onMarkAsArchive}
            onMarkAsWon={onMarkAsWon}
            onMarkAsLost={onMarkAsLost}
            onMove={onMove}
            onDelete={onDelete}
          >
            <div className="d-flex gap-12px">
              <OptionsDropdown
                dataType={"deal"}
                importLabel={"Import Deals"}
                exportLabel={"Export Deals"}
                showColumns={showColumns}
                showExportModal={showExportModal}
                activeFilter={null}
                appliedFilter={loadingStore}
                setShowExportModal={setShowExportModal}
              />
              <ColumnFilter
                fieldType={"DEAL"}
                showHideField={showHideField}
                updateFieldPriority={updateFieldPriority}
                idToIndex={fieldStore.idToIndex}
                columns={fieldStore.list}
                showColumns={showColumns}
                setShowColumns={setShowColumns}
              />
            </div>
          </DealSubHeader>
        </CustomSubHeader>
      }
    >
      {isEmpty() ? (
        <CustomEmptyPage page="deal" setShowAdd={handleAddDeal} />
      ) : (
        <div className={`card card-custom`}>
          <div className="card-body py-3 px-3">
            <EditableTable props={tableProps} />
          </div>
        </div>
      )}
      <SidePannelDetailed
        show={openPanel}
        basePath={`/deal/deal-list/${selectedPipelineId}`}
        onClose={() => handlePanelClose(false)}
        moduleName={linkedModuleName ? linkedModuleName : "deal"}
        moduleId={linkedModuleId ? linkedModuleId : sidePannelDealId}
        nextPreviousData={linkedModuleId ? [] : nextPreviousData}
        pageForm={"dealList"}
        filter={loadingStore}
        //actions
        convertToLead={onConvertToLeadClick}
        handleDelete={onDelete}
        handleArchiveDeal={onMarkAsArchive}
      />
      <AddEditPipelineModelV2
        page={"deal-list"}
        isEdit={isEdit}
        editPipelineId={editPipelineId}
        showAddPipelineModal={showAddPipelineModal}
        setIsEdit={setIsEdit}
        setEditPipelineId={setEditPipelineId}
        setShowAddPipelineModal={setShowAddPipelineModal}
      />
      <AddDealModal
        dealModalProps={{
          openAddDealModal: openAddDealModal,
          onAddDealModalClose: () => onAddDealModalClose(),
          onAddDealModalSave: (data) => onAddDealModalSave(data),
          pipelinedropdownOptions: getPipelineOptions(),
          pipelineswithstages: pipelineStore.pipelineOptionsResponse,
          selectedPipeline: selectedPipeline ? selectedPipeline : pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0].name : "",
          selectedPipelineId: selectedPipelineId ? selectedPipelineId : pipelineStore.pipelineOptionsResponse && pipelineStore.pipelineOptionsResponse.length > 0 ? pipelineStore.pipelineOptionsResponse[0]._id : "",
          ownerId: userProfile && userProfile.data && userProfile.data.userId,
          tags: dealTagStore,
        }}
      />
      <DeleteModal
        modalProps={{
          show: showDelete,
          title: "Delete Pipeline",
          handleClose: () => {
            setShowDelete(false);
          },
          handleAccept: onPipelineDeletePipeline,
        }}
      >
        Are you sure you want to delete the pipeline?
      </DeleteModal>
      <MoveDealToModel
        selectedDeal={deletedItem}
        show={openMoveToModal}
        isMultiDeal={false}
        checked={checked}
        setShow={setOpenMoveToModal}
        selectedPipelineId={selectedPipelineId}
        pipelinedropdownOptions={getPipelineOptions()}
        callMoveStagesApi={callMoveStagesApi}
        callDealOperationApi={callDealOperationApi}
      />
      <MarkAsLostModel
        selectedDeal={deletedItem}
        show={openMoveToLossModal}
        isMultiDeal={false}
        checked={checked}
        selectedPipelineId={selectedPipelineId}
        setShow={(status) => {
          setOpenMoveToLossModal(status);
          setChecked([]);
        }}
        callDealOperationApi={callDealOperationApi}
      />
      <DeleteModal
        modalProps={{
          show: dealDeleteShow,
          title: "Delete Deal",
          handleClose: () => {
            setDealDeleteShow(false);
          },
          handleAccept: onDeleteAcceptClick,
        }}
      >
        Do you want to delete selected deals? Once the deal is deleted, all of the information associated with it will be lost. That includes notes, activities, emails etc.
      </DeleteModal>
    </ContentWrapper>
  );
}
