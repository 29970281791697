import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../network/apis/api";
import * as commonActions from "../actions/common.actions";
import * as leadTypes from "../types/types";

const endpoints = {
  VARIABLE_SUPPORTED_DATA: "/api/crm/common/get-variable-supported-data",
};

function* getVariableSupportedData(action) {
  try {
    const res = yield call(api.get, endpoints.VARIABLE_SUPPORTED_DATA, action.payload);
    if (res.status === 200) {
      yield put(commonActions.getVariableSupportedDataSuccess(res.data));
    } else if (res.status === 400) {
      yield put(commonActions.getVariableSupportedDataError({ message: res.data.message }));
    } else {
      yield put(commonActions.getVariableSupportedDataError({ message: "Could Not Edit Leads" }));
    }
    if (action.cb) action.cb(res.data);
  } catch (e) {
    yield put(commonActions.getVariableSupportedDataError({ message: "Could Not Edit Leads" }));
  }
}


export default function* commonOperationSaga() {
  yield takeLatest(leadTypes.getVariableSupportedData.GET_VARIABLE_SUPPORTED_DATA_REQUEST, getVariableSupportedData);
}
