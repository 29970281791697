import { takeLatest, put, call } from "redux-saga/effects";
import api from "../../../../../network/apis/api";
import * as notificationActions from "../../actions/profile-actions/notification-settings.actions";
import * as notificationTypes from "../../types/profile-settings.types";
import { endpoints } from "../../endpoint";

function* getNotificationFunction(action) {
  try {
    const res = yield call(api.get, endpoints.NOTIFICATION);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(notificationActions.getNotificationSuccess(res.data));
    } else {
      yield put(notificationActions.getNotificationError("error"));
    }
  } catch (e) {
    yield put(notificationActions.getNotificationError(e));
  }
}

function* postNotificationFunction(action) {
  try {
    const res = yield call(api.post, endpoints.NOTIFICATION, action.payload);
    // some condition{}
    if (res.status === 200) {
      yield action.cb(res.data);
      yield put(notificationActions.postNotificationSuccess(res.data));
    } else {
      yield put(notificationActions.postNotificationError("error"));
    }
  } catch (e) {
    yield put(notificationActions.postNotificationError(e));
  }
}

export default function* notificationSaga() {
  yield takeLatest(notificationTypes.getNotification.GET_NOTIFICATION_REQUEST, getNotificationFunction);
  yield takeLatest(notificationTypes.postNotification.POST_NOTIFICATION_REQUEST, postNotificationFunction);
}
