import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./styles.scss";

export default function AddWorkflowModel(props) {
  const [step, setStep] = useState(1);
  const history = useHistory();

  const onTemplateButtonClick = () => {
    setStep(2);
  };

  const onTemplateClick = (each) => {
    history.push({ pathname: `/automation/workflow/template/create/${each._id}`, state: { data: each } });
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setStep(1);
        props.onClose();
      }}
      size={"lg"}
      close
    >
      <Modal.Header closeButton>
        <Modal.Title>{step === 1 ? "Add Workflow" : "Templates"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <div className="d-flex p-8">
            <div
              onClick={props.onCreateByScratchClick}
              className="card p-4 py-8 mr-4 d-flex justify-content-center align-items-center add-workflow-card btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column  p-3 w-50prc"
            >
              <div className="pt-10 pb-6">
                <i className="bi bi-file-earmark-text fs-4x"></i>
              </div>
              <div className="text-center fs-3 font-weight-bold py-3">From Scratch</div>
              <div className="text-center">Create workflow from scratch. Specify trigger, conditions and actions.</div>
            </div>
            <div
              onClick={onTemplateButtonClick}
              className="card p-4 py-8 d-flex justify-content-center align-items-center add-workflow-card btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column  p-3 w-50prc"
            >
              <div className="pt-10 pb-6">
                <i className="bi bi-journal-plus fs-4x"></i>
              </div>
              <div className="text-center fs-3 font-weight-bold py-3">From Template</div>
              <div className="text-center">Create workflow from the template. You can team or sales simplify templates. </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="">
            <div className="workflow-template-wrp">
              {props.templateList.data.map((each, index) => {
                return (
                  <div
                    onClick={() => {
                      onTemplateClick(each);
                    }}
                    className="card wf-template-card  btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column  p-3"
                    key={index}
                  >
                    {each.name}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {step === 2 && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              setStep(1);
            }}
          >
            Back
          </Button>
        )}
        {step === 1 && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              setStep(1);
              props.onClose();
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
