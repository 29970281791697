import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Dropdown, Form, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { InputLimit } from "../../constants";

export default function TimeZoneSelect({ field, value, setValue, clearable = false }) {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(value);
  const timezoneStore = useSelector((state) => state.Companies.timezones);

  const [showClear, setShowClear] = useState(false);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (clearable && field && !field.isRequired) {
      setShowClear(true);
    } else {
      setShowClear(false);
    }
  }, [field, clearable]);

  const clearClick = () => {
    setSelected({});
    setValue(null, field.apiKeyName);
  };

  return (
    <div className="d-flex gap-2 flex-center">
      <Dropdown className="dropdown-inline mt-1 w-100">
        <Dropdown.Toggle className="bg-white w-100 text-left py-0-point-65rem px-1-point-1rem border-width-1px border-style-solid border-color-e4e6ef" variant="transparent">
          {!value || value === "" ? "Select " + field.fieldName : selected}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-xl w-100-mn">
          <div className="navi navi-hover">
            <li className="navi-item py-12px px-24px">
              <Form.Control maxLength={InputLimit.TEXT} type="text" placeholder="Search Time Zone" value={search} onChange={(e) => setSearch(e.target.value)} />
            </li>
            <Dropdown.Divider className="m-0" />
            <PerfectScrollbar className="scroll h-25vh-mx position-relative" options={{ wheelSpeed: 0.4, wheelPropagation: false }}>
              {timezoneStore.list.map((timezone, index) => {
                return timezone.label && timezone.label.toLowerCase().includes(search.toLowerCase()) ? (
                  <Dropdown.Item
                    className="dropdown-hover"
                    key={index}
                    onSelect={() => {
                      setSelected(timezone.value);
                      setValue(timezone.value, field.apiKeyName);
                    }}
                  >
                    {timezone.label}
                  </Dropdown.Item>
                ) : null;
              })}
            </PerfectScrollbar>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {showClear && !_.isEmpty(selected) && (
        <div>
          <Button className="pl-3 pr-0" onClick={() => clearClick()} size="sm" variant="transparent">
            <i className="fa fa-minus-circle remove-condition-icon"></i>
          </Button>
        </div>
      )}
    </div>
  );
}
