import React, { useEffect, useState } from "react";
import CustomSidePanel from "../../../../../../../components/side-pannel-detailed/custom-components/custom-side-pannel";
import { Tab } from "react-bootstrap";
import { SidebarTabs } from "../../../../../../../components/side-pannel-detailed/custom-components/tabs";
import WhatsappProfile from "./whatsapp-profile.component";
import WhatsappAPIConfiguration from "./whatsapp-api-configuration";
import { useDispatch } from "react-redux";
import * as whatsappSettingsAction from "../../../../../store/actions/campaign_whatsapp_setting_actions";
import WhatsappWebhookConfiguration from "./whatsapp-webhook-configuration";
import WhatsappMessagingLimit from "./whatsapp-messaging-limit";

export default function OpenWhatsappCloudApiSettings(props) {
    const [selectedTab, setSelectedTab] = useState("Whatsapp Profile");
    const [categoryList] = useState(["Whatsapp Profile", "API Configuration", "Webhook Configuration", "Whatsapp Message Limit"]);


    const dispatch = useDispatch();

    useEffect(() => {
        if (props.integrationId)
            dispatch(whatsappSettingsAction.getWhatsappNumberProfileRequest({ id: props.integrationId }))
    }, [dispatch, props.integrationId])

    return (
        <CustomSidePanel
            show={props.show}
            moduleName={"Whatsapp Settings"}
            handleClose={() => props.setShow(false)}
            showNextButton={false}
            showPreviosButton={false}
            customStyles={{
                width: "80vw",
                right: "81vw",
            }}
        >
            <Tab.Container defaultActiveKey={selectedTab}>
                <SidebarTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={categoryList} />
                <div className="h-vh-73px overflow-auto">
                    {selectedTab === "Whatsapp Profile" && <WhatsappProfile integrationId={props.integrationId} data={props.data} />}
                    {selectedTab === "API Configuration" && <WhatsappAPIConfiguration integrationId={props.integrationId} data={props.data} />}
                    {selectedTab === "Webhook Configuration" && <WhatsappWebhookConfiguration integrationId={props.integrationId} />}
                    {selectedTab === "Whatsapp Message Limit" && <WhatsappMessagingLimit integrationId={props.integrationId} />}
                </div>
            </Tab.Container>
        </CustomSidePanel>
    )
}